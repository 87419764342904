export default function updateTranslationsWithVotes (translations, bestTranslationId, upvotedTranslationIds, downvotedTranslationIds, userId) {
    translations?.forEach(translation => {
        // Default values
        if (translation.netVotes === undefined) translation.netVotes = 1;
        if (translation.bestVotes === undefined) translation.bestVotes = 0;

        // Upvote logic
        if (upvotedTranslationIds.includes(translation.id)) {
            translation.upvotedBy = [...new Set([...(translation.upvotedBy || []), userId])];
            translation.netVotes++;
        }

        // Downvote logic
        if (downvotedTranslationIds.includes(translation.id)) {
            translation.downvotedBy = [...new Set([...(translation.downvotedBy || []), userId])];
            translation.netVotes--;
        }

        // Best translation logic
        if (translation.id === bestTranslationId) {
            translation.bestBy = [...new Set([...(translation.bestBy || []), userId])];
            translation.bestVotes++;
        }
    });
    return translations;
};