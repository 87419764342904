
export const HOME_PATH = '/';
export const SIGN_UP_PATH = '/sign-up';
export const SIGN_IN_PATH = '/sign-in';
export const PROFILE_PATH = '/profile';
export const QUIZ_PATH = '/quiz/:id';
export const REVIEW_PATH = '/review/:id';
export const PHRASES_PATH = '/phrases';
export const SENTENCES_PATH = '/sentences';
export const ROLES_PATH = '/roles';
export const CATCH_ALL_PATH = '*';

export const LOGGED_IN_PATHS = [PROFILE_PATH];
export const LOGGED_OUT_PATHS = [SIGN_UP_PATH, SIGN_IN_PATH];
export const ALWAYS_AVAILABLE_PATHS = [HOME_PATH, PHRASES_PATH, SENTENCES_PATH];