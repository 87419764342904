import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Paper, Typography } from '@mui/material';
import { GoogleLoginButton } from 'react-social-login-buttons';

import { isNewUser } from '../utils/isNewUser';
import updateUniqueDisplayName from '../utils/updateUniqueDisplayNames';
import { HOME_PATH, PROFILE_PATH } from '../data/paths';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null); // New state for error message
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(HOME_PATH);
    } catch (error) {
      console.error('Error signing in:', error.message);
      setErrorMessage(error.message); // Set error message
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      if (isNewUser(result)) await updateUniqueDisplayName(result.user);
      navigate(isNewUser(result) ? PROFILE_PATH : HOME_PATH);
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
      setErrorMessage(error.message); // Set error message
    }
  };
  
  return (
    <Paper elevation={3} style={{ padding: '2em', width: '300px' }}>
      <Typography variant="h5" gutterBottom>Sign In</Typography>

      

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField 
            label="Email" 
            value={email} 
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField 
            label="Password"
            type="password" 
            value={password} 
            onChange={e => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>

      {errorMessage && (
        <Grid item>
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
        </Grid>
      )}

        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSignIn} fullWidth>
            Sign In
          </Button>
        </Grid>
        <Grid item>
          <GoogleLoginButton onClick={handleGoogleSignIn} fullWidth />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default SignIn;
