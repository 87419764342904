import { ChatGPTAPI } from 'chatgpt'


export const api = new ChatGPTAPI({
  apiKey: process.env.REACT_APP_CHATGPT_KEY ?? "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",
   // workaround for https://github.com/transitive-bullshit/chatgpt-api/issues/592
   fetch: window.fetch.bind(window),
   completionParams: { model: 'gpt-4' },
   maxModelTokens: 8100 // not 8192 because we're leaving some buffer room
})
console.log('api', process.env.REACT_APP_CHATGPT_KEY, api);