import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';  // Adjust based on your import structure
import { Paper, Typography, Grid, TextField, Button } from '@mui/material';
import updateUniqueDisplayName from '../utils/updateUniqueDisplayNames';
import { isDisplayNameUnique } from '../utils/isDisplayNameUnique';

function Profile() {
  const [displayName, setDisplayName] = useState('');
  const [newDisplayName, setNewDisplayName] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      setDisplayName(auth.currentUser.displayName);
    }
  }, [auth.currentUser]);

  const updateDisplayName = async () => {
    if (newDisplayName) {
        if (! await isDisplayNameUnique(newDisplayName)) {
            const errorText = 'Display name is already taken.';
            console.error(errorText);
            setErrorMessage(errorText);
            setSuccessMessage('');
            return;
        }

      try {
        await updateUniqueDisplayName(auth.currentUser, {
            existingDisplayName: displayName,
            newDisplayName: newDisplayName,
        })

        setDisplayName(newDisplayName);
        setSuccessMessage(`Display name successfully updated.`);
        setErrorMessage('')
      } catch (error) {
        console.error('Error updating display name:', error.message);
        setErrorMessage(error.message);
        setSuccessMessage('')
      }
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '2em', width: '300px' }}>
      <Typography variant="h5" gutterBottom>Profile</Typography>

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField 
            label="Current Display Name"
            value={displayName}
            disabled 
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField 
            label="New Display Name"
            value={newDisplayName}
            onChange={e => setNewDisplayName(e.target.value)}
            fullWidth
          />
        </Grid>
        
        {errorMessage && (
          <Grid item>
            <Typography variant="body2" color="error" gutterBottom>
              {errorMessage}
            </Typography>
          </Grid>
        )}

        {successMessage && (
          <Grid item>
            <Typography variant="body1" style={{ color: 'green' }} gutterBottom>
              {successMessage}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Button variant="contained" color="primary" onClick={updateDisplayName} fullWidth>
            Update Display Name
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Profile;
