import React, { useState } from 'react';
import { auth } from '../firebase';
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Paper, Typography } from '@mui/material';
import { isDisplayNameUnique } from '../utils/isDisplayNameUnique';
import { isNewUser } from '../utils/isNewUser';
import updateUniqueDisplayName from '../utils/updateUniqueDisplayNames';
import GoogleSignUpButton from '../auth/GoogleSignUpButton';
import { HOME_PATH, PROFILE_PATH } from '../data/paths';

function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null); // New state for error message
    const navigate = useNavigate();

    const handleSignUp = async () => {
        if (await isDisplayNameUnique(displayName)) {
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                
                console.log('credentials', {
                    displayName,
                    userCredential
                })
                if (isNewUser(userCredential)) await updateUniqueDisplayName(userCredential.user, {
                    newDisplayName: displayName 
                });

                navigate(isNewUser(userCredential) ? PROFILE_PATH : HOME_PATH);
            } catch (error) {
                console.error('Error signing up:', error.message);
                setErrorMessage(error.message); // Set error message
            }
        } else {
            const errorText = 'Display name is already taken.';
            console.error(errorText);
            setErrorMessage(errorText); // Set error message
        }
    };

    const handleGoogleSignUp = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const userCredential = await signInWithPopup(auth, provider);
            if (isNewUser(userCredential)) await updateUniqueDisplayName(userCredential.user);

            navigate(isNewUser(userCredential) ? PROFILE_PATH : HOME_PATH);
        } catch (error) {
            console.error('Error signing up with Google:', error.message);
            setErrorMessage(error.message); // Set error message
        }
    };

    return (
        <Paper elevation={3} style={{ padding: '2em', width: '300px' }}>
            <Typography variant="h5" gutterBottom>Sign Up</Typography>

            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        label="Display Name"
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        fullWidth
                    />
                </Grid>
                
                {errorMessage && (
                    <Grid item>
                        <Typography variant="body2" color="error" gutterBottom>
                            {errorMessage}
                        </Typography>
                    </Grid>
                )}
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleSignUp} fullWidth>
                        Sign Up
                    </Button>
                </Grid>
                <Grid item>
                    <GoogleSignUpButton onClick={handleGoogleSignUp} fullWidth />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default SignUp;
