import { isDisplayNameUnique } from "./isDisplayNameUnique";

const NUMBER_OF_SAME_DISPLAY_NAMES = 100;
const generateRandomNumber = () => {
    // Note: Could potentially be inneficent if we end up having loads of users that all want the same name
    // But we will cross that bridge of popularity if we ever get to it
    // Math keeps the number between 2 and NUMBER_OF_SAME_DISPLAY_NAMES
    return Math.floor(Math.random() * (NUMBER_OF_SAME_DISPLAY_NAMES - 1)) + 2;
}

export default async function generateUniqueDisplayName(originalName) {
    const nameParts = originalName.split(' ');
    const firstName = nameParts[0] || '';
    const lastNameInitial = nameParts[1] ? nameParts[1].charAt(0) : '';
    let modifiedDisplayName = `${firstName} ${lastNameInitial}`.trim();
  
    // Check if the display name is unique, if not, append a random number
    while (!(await isDisplayNameUnique(modifiedDisplayName))) {
        const trimmedName = `${firstName} ${lastNameInitial}`?.trim();
        modifiedDisplayName = `${trimmedName}${generateRandomNumber()}`;
    }
  
    return modifiedDisplayName;
  }
  