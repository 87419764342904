import { useEffect, useState } from 'react';
import { onAuthStateChanged, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { auth } from '../firebase';
import { isNewUser } from '../utils/isNewUser';
import updateUniqueDisplayName from '../utils/updateUniqueDisplayNames';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_PATH, PROFILE_PATH } from '../data/paths';

export default function useFirebaseAuth () {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initializedOneTap, setInitializedOneTap] = useState(false);
    const location = useLocation();
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        setLoading(false);
      });
      return () => unsubscribe();
    }, []);
  
    useEffect(() => {
      if (!loading && !user && !initializedOneTap) {
        window.google.accounts.id.initialize({
          client_id: '885634361151-0sfkk8jm7p8fori4ga3gks2quargpgrs.apps.googleusercontent.com',
          callback: handleCredentialResponse,
        });

        if (location.pathname === HOME_PATH) {
            window.google.accounts.id.prompt((notification) => {
  
                if (notification.isSkippedMoment() && notification.getSkippedReason() === 'user_cancel') {
                  const date = new Date();
                  date.setTime(date.getTime() + (1 * 60 * 60 * 1000)); // 1 hour in the future
                  const expires = `expires=${date.toUTCString()}`;
                  document.cookie = `g_state=;path=/;${expires}`;
              }
            });
        }
  
        setInitializedOneTap(true);
      }
  
      function handleCredentialResponse(response) {
        const credential = GoogleAuthProvider.credential(response.credential);
        signInWithFirebase(credential);
      }
    }, [user, initializedOneTap, loading]);
  
    const signInWithFirebase = async (credential) => {
      signInWithCredential(auth, credential)
        .then(async (result) => {
          if (isNewUser(result)) await updateUniqueDisplayName(result.user);
          navigate(isNewUser(result) ? PROFILE_PATH : HOME_PATH)
          
        })
        .catch((error) => {
          console.error('Error signing in with Google One Tap:', error.message);
        });
    };
  
    return { user, loading, setUser };
  }