import React, { useState } from 'react';
import { Drawer, IconButton, Divider, Button, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { auth } from "../firebase";
import { navbarHeight } from './Navbar';
import { DrawerHeader, commentDrawerWidth } from './CommentsDrawer';
import { englishToLatinSubstitutions, latinToEnglishSubstitutions } from '../data/substitutions';
import { englishToLatinGameMenus } from '../data/english-to-latin-game-menus';
import { useApp } from '../utils/AppContext';

// Allows commenting on a resource (sentence, phrase, etc.)
const GameMenuSearchDrawer = () => {
    const { isSearchDrawerOpen, setIsSearchDrawerOpen } = useApp()
    const allSubstitutions = {...englishToLatinSubstitutions, ...englishToLatinGameMenus};
    const [searchText, setSearchText] = useState('');

    const matches = (searchText.length > 1 && Object.entries(allSubstitutions).filter(([substitution]) => substitution.toLowerCase().includes(searchText.toLowerCase()))) || [];
    matches.sort(([a], [b]) => a.length - b.length)

    return (
        <Drawer
        sx={{
            width: { xs: '100vw', sm: commentDrawerWidth }, // 100% width for screens smaller than 768px
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: { xs: '100vw', sm: commentDrawerWidth }, // 100% width for screens smaller than 768px
                top: navbarHeight,
                left: { xs: 0, sm: 'auto'},
                bottom: 0
            },
        }}
            variant="persistent"
            position="fixed"
            anchor="right"
            open={isSearchDrawerOpen}
        >
            <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Search Game Menu Translations</Typography>
                <IconButton onClick={() => setIsSearchDrawerOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DrawerHeader>

            <div style={{ padding: '0.25rem 1rem 1rem' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    label="Search for translation"
                    multiline
                />
            </div>
            <Divider />
            <div style={{ padding: '0.25rem 1rem 1rem', marginBottom: '4rem' }}>
            {matches.map(([match, translations]) => (
                <div key={match}>
                    <h4>{match}</h4>
                    <ul>
                        {(translations ?? []).map(translation => (
                            <li key={translation}>
                                {translation}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            </div>
        </Drawer>
    );
};

export default GameMenuSearchDrawer;
