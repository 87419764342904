export default function sortTranslations (translations, bestTranslationId, editorsChosenTranslationId) {
    if (!translations) return translations;
    
    return [...translations]?.sort((a, b) => {
        if (a.id === editorsChosenTranslationId) return -1;
        if (a.id === bestTranslationId) return -1;

        const aVotes = a.netVotes + (a.bestVotes * 1.5);
        const bVotes = b.netVotes + (b.bestVotes * 1.5);

        return bVotes - aVotes;
    });
};