import { doc, updateDoc } from "@firebase/firestore";
import { firestore } from "../../firebase";
import { COLLECTIONS } from "../../data/collections";

export default async function updateTranslations (sentenceId, translations, userId, respondedBy) {
    const sentenceDocRef = doc(firestore, COLLECTIONS.sentences, sentenceId);
    // console.log('translations', translations)
    await updateDoc(sentenceDocRef, { 
        respondedBy: [...new Set([...(respondedBy || []), userId])],
        translations,
     });
};