import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// import { collection, query, orderBy, getDocs, startAfter, writeBatch } from "firebase/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  writeBatch,
  startAfter
} from '@firebase/firestore'
import { auth, firestore } from '../firebase'
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import algoliasearch from 'algoliasearch/lite'
import { styled } from 'styled-components'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Stars } from '@mui/icons-material'
import useUserRoles from '../utils/firebase/useUserRoles'
import { COLLECTIONS } from '../data/collections'

const tagsArray = [
  'main 01 - Unbound',
  'main 02 - Before the Storm',
  'main 03 - Bleak Falls Barrow',
  'main 04 - dragon rising',
  'main 05 - The way of the voice',
  'main 06 - The Horn of Jurgen Windcaller',
  'main 07 - A Blade in the Dark',
  'main 08 - Diplomatic Immunity',
  'main 09 - A Cornered Rat',
  'main 10 - Find the Thalmor Assassin (optional)',
  "main 11 - Alduin's Wall",
  'main 12 - The Throat of the World',
  'main 13 - Elder Knowledge',
  "main 14 - Alduin's Bane",
  'main 15 - The Fallen',
  'main 16 - Season Unending (optional)',
  'main 17 - Paarthurnax (optional)',
  "main 18 - The World-Eater's Eyrie",
  'main 19 - Sovngarde',
  'main 20 - Dragonslayer'
]

const ALGOLIA_APP_ID = 'P97O9O7ONR'
// TODO: Update algolia for a sentences search
const ALGOLIA_INDEX_NAME = 'sentences_index'
const searchClient = algoliasearch(
  ALGOLIA_APP_ID,
  '55dbef8cda326d24614013556b0b0f47'
)
const index = searchClient.initIndex(ALGOLIA_INDEX_NAME)

export const Title = styled.h1`
  color: white;
  margin-bottom: 0.5rem;
  margin-top: 0;
`

export const CallToAction = styled.h2`
  color: white;
  margin-top: 0;
  margin-bottom: 0rem;
`

// Custom hook for select filters with local storage
function useSelectFilterWithLocalStorage (key, defaultValue) {
  // Get the initial value from local storage or use the default
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key)
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue
  })

  // Update local storage whenever the value changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

// const firestore = getFirestore(); // Initialize this with your Firestore instance
const sentencesRef = collection(firestore, COLLECTIONS.sentences)

async function updateBatch (batchSize) {
  let q = query(sentencesRef, orderBy('__name__'), limit(batchSize))
  let documentSnapshots

  do {
    documentSnapshots = await getDocs(q)
    const batch = writeBatch(firestore)

    documentSnapshots.docs.forEach(doc => {
      if (!doc.data().respondedBy) {
        const sentenceRef = doc.ref
        batch.update(sentenceRef, { respondedBy: [] })
      }
    })

    await batch.commit()

    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1]
    if (lastVisible) {
      q = query(
        sentencesRef,
        orderBy('__name__'),
        startAfter(lastVisible),
        limit(batchSize)
      )
    }
  } while (documentSnapshots && documentSnapshots.docs.length >= batchSize)
}

const AllSentencesList = () => {
  const [sentences, setSentences] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const { userRoles } = useUserRoles()
//   const [selectedTag, setSelectedTag] = useState(null)
  const [respondedSentences, setRespondedSentences] = useState([])
  const [isShowingResponded, setIsShowingResponded] = useState(false)

  // Use the custom hook for select filters
  const [playedFilter, setPlayedFilter] = useSelectFilterWithLocalStorage(
    'playedFilter',
    'Unplayed'
  )
  const [reviewedByEditorFilter, setReviewedByEditorFilter] =
    useSelectFilterWithLocalStorage('reviewedByEditorFilter', 'No')

    const [selectedTag, setSelectedTag] = useSelectFilterWithLocalStorage(
        'selectedTag',
        '' // Default value for the tag is an empty string
      );

  const userId = auth.currentUser ? auth.currentUser.uid : null
  console.log('userId', userId)
  useEffect(() => {
    const fetchRespondedSentences = async () => {
      if (userId) {
        const responsesCollectionRef = collection(firestore, 'responses')
        const userResponseQuery = query(
          responsesCollectionRef,
          where('userId', '==', userId)
        )

        const querySnapshot = await getDocs(userResponseQuery)
        const userResponses = querySnapshot.docs.map(
          doc => doc.data().sentenceId
        )
        setRespondedSentences(userResponses)
      }
    }

    fetchRespondedSentences()
  }, [])

  useEffect(() => {
    const fetchSentences = async () => {
      const sentencesRef = collection(firestore, COLLECTIONS.sentences)

      const queryArgs = []

      if (reviewedByEditorFilter === 'Yes') {
        queryArgs.push(where('hasEditorChosenTranslation', '==', true));
      } else  if (reviewedByEditorFilter === 'No') {
        queryArgs.push(where('hasEditorChosenTranslation', '==', false));
      }

      if (playedFilter === 'Unplayed') {
        queryArgs.push(where('respondedBy', 'not-in', [[userId]]));
    } else if (playedFilter === 'Played') {
        queryArgs.push(where('respondedBy', 'in', [[userId]]));
      }

    //   where('hasEditorChosenTranslation', '==', false),
        // where('respondedBy', 'not-in', [[userId]])

      // Base query without ordering by 'respondedBy'
      let q = query(
        sentencesRef,
        // Exclude sentences the current user has responded to
        ...queryArgs,
        // where('hasEditorChosenTranslation', '==', false),
        // where('respondedBy', 'not-in', [[userId]]),
        // Commented out the ordering by 'respondedBy' as it's not supported with 'not-in'
        // orderBy('respondedBy'),
        // orderBy('frequency', 'desc'),
        // orderBy('textLength', 'asc'),
        limit(20)
      )

      if (selectedTag) {
        // Add tag filter to the query
        q = query(q, where('tags', 'array-contains', selectedTag))
      }

      try {
        const querySnapshot = await getDocs(q)
        let fetchedSentences = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        // Optionally sort the sentences in the client after fetching
        fetchedSentences = fetchedSentences.sort((a, b) => {
          // Replace this logic with your own sorting logic
          return (
            a.hasEditorChosenTranslation - b.hasEditorChosenTranslation ||
            b.frequency - a.frequency ||
            a.textLength - b.textLength
          )
        })

        // Update the state with the fetched sentences
        setSentences(fetchedSentences)
      } catch (error) {
        console.error('Error fetching sentences: ', error)
        // Handle the error appropriately
      }
    }

    fetchSentences()
  }, [selectedTag, playedFilter, reviewedByEditorFilter])

  const handleSearch = async e => {
    e.preventDefault() // prevent default form submission behavior
    const { hits } = await index.search(`${selectedTag ?? ''} ${searchTerm}`)
    setSentences(hits)
  }

  return (
    <div style={{ maxWidth: '600px' }}>
      <Box
        bgcolor='rgba(0, 0, 0, 0.9)'
        borderRadius={3}
        p={2}
        style={{ textAlign: 'center', marginTop: '1rem' }}
      >
        <Title>Ludus Translationis</Title>
        <CallToAction>Help Translate Games into Latin</CallToAction>
        {/* <button onClick={async () => {
                    try {
                        console.log('update batch 500')
                        await updateBatch(500)
                    } catch(error) {
                        console.error(error)
                    }
                }}>Add respondedBy array</button> */}
        <form onSubmit={handleSearch}>
          <TextField
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            label='Search sentences'
            variant='outlined'
            fullWidth
            margin='normal'
          />
          <FormControl
            variant='outlined'
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            <InputLabel id='tag-select-label'>Select Tag</InputLabel>
            <Select
              id='tag-select'
              value={selectedTag}
              onChange={e => setSelectedTag(e.target.value)}
              label='Select Tag'
            >
              <MenuItem value=''>All Tags</MenuItem>
              {tagsArray.map(tag => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant='outlined'
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            <InputLabel id='played-select-label'>Played?</InputLabel>
            <Select
              id='played-select'
              value={playedFilter}
              onChange={e => setPlayedFilter(e.target.value)}
              label='Played?'
            >
              <MenuItem value='Played'>Played</MenuItem>
              <MenuItem value='Unplayed'>Unplayed</MenuItem>
              <MenuItem value='All'>All</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant='outlined'
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            <InputLabel id='reviewed-select-label'>
              Reviewed by Editor?
            </InputLabel>
            <Select
              id='reviewed-select'
              value={reviewedByEditorFilter}
              onChange={e => setReviewedByEditorFilter(e.target.value)}
              label='Reviewed by Editor?'
            >
              <MenuItem value='No'>No</MenuItem>
              <MenuItem value='Yes'>Yes</MenuItem>
              <MenuItem value='All'>All</MenuItem>
            </Select>
          </FormControl>
          <Button fullWidth variant='outlined' color='primary' type='submit'>
            Search
          </Button>
        </form>
      </Box>

      <div style={{ marginTop: '20px' }}>
        {sentences.map(sentence => (
          <Card
            key={sentence.id}
            style={{
              marginBottom: '15px',
              backgroundColor: 'rgba(28, 28, 28, 0.9)'
            }}
          >
            <CardContent
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {sentence.hasEditorChosenTranslation && (
                <Tooltip
                  placement='top'
                  title={'Reviewed by professional editor'}
                >
                  <Stars color='primary' style={{ marginRight: '10px' }} />
                </Tooltip>
              )}
              {respondedSentences.includes(sentence.id) && (
                <Tooltip placement='top' title={'Played by user'}>
                  <CheckCircleIcon
                    color='secondary'
                    style={{ marginRight: '10px' }}
                  />
                </Tooltip>
              )}

              <span style={{ flexGrow: 1, textAlign: 'center' }}>
                <Typography display='inline' variant='body1' color='primary'>
                  {sentence.text}
                </Typography>
                {sentence.frequency > 1 && (
                  <Tooltip
                    placement='top'
                    title={`This sentence is in ${sentence.frequency} subtitles`}
                  >
                    <Typography
                      display='inline'
                      variant='body1'
                      color='secondary'
                    >
                      &nbsp;({sentence.frequency})
                    </Typography>
                  </Tooltip>
                )}
              </span>

              {/* Buttons Container */}
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                style={{ marginLeft: '1rem' }}
              >
                {/* Play Button */}
                {userRoles && (
                  <Button
                    component={RouterLink}
                    to={`/quiz/${sentence.id}`}
                    variant='contained'
                    color='primary'
                    style={{ marginBottom: '5px', width: '80px' }}
                  >
                    {respondedSentences.includes(sentence.id) ? 'View' : 'Play'}
                  </Button>
                )}

                {/* Review Button */}
                {userRoles?.editor && (
                  <Button
                    component={RouterLink}
                    to={`/review/${sentence.id}`}
                    variant='outlined'
                    color='primary'
                    style={{ width: '80px' }}
                  >
                    Review
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default AllSentencesList
