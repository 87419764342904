import { styled } from "styled-components";
import { Paper as MuiPaper, Typography as MuiTypography, Grid } from '@mui/material';
import { navbarHeight } from "../Navbar";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;   // Add this to allow the container to grow
    padding-top: 1rem;
    max-width: 1032px;
`;


export const CenteredDiv = styled.div`
    padding: 0;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButton = styled.button`
    width: 100%;
    color: #FFFFFF;
    background-color: transparent;
    border: none;
    font-size: ${props => props.fontSize || '2rem'};
    cursor: pointer;
    transition: 0.3s background-color ease-in-out;
    padding: 1rem;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

export const PromptTypography = styled(MuiTypography)`
    font-weight: 400;
    text-align: center;
    color: #e0e0e0;                     // Light gray for subtle contrast
    margin-bottom: 1rem;
    letter-spacing: 0.1em;
`;

export const StyledTypography = styled(MuiTypography)`
    font-size: ${props => props.fontSize || '2rem'}!important;
    text-align: center;
    font-weight: 600;
    color: #FFF;                        // Pure white for main text
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);   // A bit more pronounced shadow
    padding: 0.5em;
    margin-top: 0.5rem;
`;

export const StyledPaper = styled(MuiPaper)`
    padding: 1em;
    border-radius: 2rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);    // Deeper shadow for depth in dark theme
    // background: linear-gradient(90deg, #1f005c, #2e2065, #3e3c6e, #4d5975, #5c767c, #6a9283, #7baf8a, #8bcb91);
    background: black!important;
    max-width: 90%;
    margin: 0;
    max-width: 1032px;
`;

export const AnswersContainer = styled.div`
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // flex-grow: 1;  // Add this to make the container grow
    // max-width: ${1280}px;
    // margin-top: 2rem;
`;

export const ActionButtonsContainer = styled.div`
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // flex-grow: 1;  // Add this to make the container grow
    // max-width: ${1280}px;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const StyledGridItem = styled(Grid)`
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    & > div {
        & > button {
            color: ${props => props.color};
        }
        // background-color: ${props => props.color};
        background-color: #121212;
        border: 0.25rem solid ${props => props.color};
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
    }
    overflow-wrap: break-word;
    hyphens: auto;
    min-width: 300px;
`;

export const TranslationsGrid = styled(Grid)`
flex: 1;
`;
