// Home.js
import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { QUIZ_PATH } from '../data/paths';
import AllSentencesList from './AllSentencesList';
import useUserRoles from '../utils/firebase/useUserRoles';
import useFirebaseAuth from '../utils/useFirebaseAuth';

export const Title = styled.h1`
  color: white;
  margin-bottom: 0.5rem;
`;

export const CallToAction = styled.h2`
  color: white;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const Home = () => {
  return (
    <>
      <Title>Ludus Translationis</Title>
      <CallToAction>Help Translate Games into Latin</CallToAction>
      <AllSentencesList/>
      </>
  );
};
