import { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import useFirebaseAuth from '../../utils/useFirebaseAuth';

const useUserRoles = () => {
    const [userRoles, setuserRoles] = useState(null);
    const [loading, setLoading] = useState(true);
    const {user} = useFirebaseAuth()

    useEffect(() => {
        if (auth.currentUser) {
            auth.currentUser.getIdTokenResult().then(idTokenResult => {
                setuserRoles(idTokenResult.claims);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [auth.currentUser]);

    useEffect(() => {
        if (!user) setuserRoles(null);
    }, [user])

    return { userRoles: userRoles, loading };
};

export default useUserRoles;
