export const englishToLatinSubstitutions = {
    'Dark Brotherhood': ['Fraternitas Obscura'],
    'Dawnguard': ['Aurorae Custodes'],
    'Dawnstar': ['Lucifer'],
    'Dragonborn': ['Draconigena'],
    'General Tullius': ['Imperator Marcus Tullius Cicero'],
    'Hermeaus': ['Hermes'],
    'Jarl': ['Comes'],
    'Lydia': ['Atalanta'],
    'Markarth': ['Markartia'],
    'Meridia': ['Ceres'],
    'Mora': ['Trismegisthus'],
    'Ravenrock': ['Corvisaxum'],
    'Riften': ['Hiatu'],
    'Skyrim': ['Caelifinis'],
    'Solitude': ['Solitudo'],
    'Stormcloak': ['Sturmmantellus'],
    'Thane': ['Decemvir'],
    'The Companions': ['Contubernales'],
    'Thieves Guild': ['Sodalitas Furum'],
    'Ulfric': ['Vercingetorix'],
    'Vaermina': ['Morpheus'],
    'Whiterun': ['Albacursus'],
    'Windhelm': ['Ventigalea'],
    'Winterhold': ['Hiemiterra']
    }
    
export const latinToEnglishSubstitutions = {
        'Fraternitas Obscura': ['Dark Brotherhood'],
        'Aurorae Custodes': ['Dawnguard'],
        'Lucifer': ['Dawnstar'],
        'Draconigena': ['Dragonborn'],
        'Imperator Marcus Tullius Cicero': ['General Tullius'],
        'Hermes': ['Hermeaus'],
        'Herma': ['Hermeaus'],
        'Comes': ['Jarl'],
        'Atalanta': ['Lydia'],
        'Markartia': ['Markarth'],
        'Ceres': ['Meridia'],
        'Trismegisthus': ['Mora'],
        'Corvisaxum': ['Ravenrock'],
        'Hiatu': ['Riften'],
        'Caelifinis': ['Skyrim'],
        'Solitudo': ['Solitude'],
        'Sturmmantellus': ['Stormcloak'],
        'Sturmmantel': ['Stormcloak'],
        'Decemvir': ['Thane'],
        'Contubernales': ['The Companions'],
        'Sodalitas Furum': ['Thieves Guild'],
        'Vercingetorix': ['Ulfric'],
        'Morpheus': ['Vaermina'],
        'Albacursus': ['Whiterun'],
        'Ventigalea': ['Windhelm'],
        'Hiemiterra': ['Winterhold']
      }