import React from 'react';
import { accessibleColors } from '../../data/theme';
import { Grid } from '@mui/material';
import { ActionButtonsContainer, StyledButton, StyledGridItem } from './styled';

const ActionButtons = ({ fontSize, isCommentsDrawerOpen, hasResponded, hasShownResults, onCustomTranslationNeeded, onNotSure, onShowResults, onNextTranslation, isEditor }) => {
    return (
        <ActionButtonsContainer>
            <Grid container spacing={2}>
                {(
                        <StyledGridItem 
                            item xs={12} 
                            sm={(isCommentsDrawerOpen || isEditor) ? 12 : 6} 
                            md={isEditor ? 12 : 6}
                            
                            color='#007BFF'>
                            <div onClick={onCustomTranslationNeeded}>
                                <StyledButton fontSize={fontSize}>
                                    {isEditor ? 'Add Custom Translation' : 'Custom Translation Needed'}
                                </StyledButton>
                            </div>
                        </StyledGridItem>
                )}
                {(!hasResponded) && (
                    <>
                        <StyledGridItem 
                        item xs={12} 
                        sm={isCommentsDrawerOpen ? 12 : 6} 
                        md={6}
                        color='orange'>
                            <div onClick={onNotSure}>
                                <StyledButton fontSize={fontSize}>
                                    Skip
                                </StyledButton>
                            </div>
                        </StyledGridItem>
                    </>
                )}
                {hasResponded && !hasShownResults && (
                    <StyledGridItem item xs={12} color={accessibleColors.at(-1)}>
                        <div onClick={onShowResults}>
                            <StyledButton fontSize={fontSize}>
                                Show Results
                            </StyledButton>
                        </div>
                    </StyledGridItem>
                )}
                {hasShownResults && (
                    <StyledGridItem item xs={12} color={accessibleColors.at(-1)}>
                        <div onClick={onNextTranslation}>
                            <StyledButton fontSize={fontSize}>
                                Next Translation
                            </StyledButton>
                        </div>
                    </StyledGridItem>
                )}
            </Grid>
        </ActionButtonsContainer>
    );
};


export default ActionButtons;
