import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    // add any other customizations for light mode here
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // add any other customizations for dark mode here
  },
});

export const accessibleColors = [
    '#32CD32',  // Lime Green
    '#FF6347',  // Tomato
    '#FF1493',  // Deep Pink (different shade)
    '#9370DB',  // Medium Purple
    '#EE82EE',  // Violet
    '#D32F2F',  // Deep Red
    '#7B1FA2',  // Deep Purple
    '#C2185B',  // Deep Pink
    '#388E3C',  // Dark Green
];

export { lightTheme, darkTheme };
