import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import LatinComposer from './LatinComposer'; // Import your LatinComposer component

function LatinComposerModal({ isOpen, setIsOpen, initialPhrase }) {
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen) return;

  return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {/* <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}> */}
          {/* <h2 id="modal-title">Latin Composer</h2> */}
          {/* <div id="modal-description"> */}
            {/* Render your LatinComposer component inside the modal */}
            <LatinComposer initialPhrase={initialPhrase} />
          {/* </div> */}
        {/* </div> */}
      </Modal>
  );
}

export default LatinComposerModal;
