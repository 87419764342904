import React, { useState } from 'react';
import styled from 'styled-components';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import { Navbar, navbarHeight } from './components/Navbar';
import { Home } from './pages/Home';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import useFirebaseAuth from './utils/useFirebaseAuth';
import Profile from './pages/Profile';
import { CATCH_ALL_PATH, HOME_PATH, LOGGED_IN_PATHS, LOGGED_OUT_PATHS, PHRASES_PATH, PROFILE_PATH, QUIZ_PATH, REVIEW_PATH, ROLES_PATH, SIGN_IN_PATH, SIGN_UP_PATH } from './data/paths';
import Quiz from './pages/Quiz';

import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from './data/theme';
import AllSentencesList from './pages/AllSentencesList';
import Roles from './pages/Roles';
import useUserRoles from './utils/firebase/useUserRoles';
import { AppContext } from './utils/AppContext';
import { Buffer } from 'buffer';
import LatinComposer from './pages/LatinComposer';
import GameMenuSearchDrawer from './components/GameMenuSearchDrawer';

// @ts-ignore
window.Buffer = Buffer;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
  min-height: calc(100vh - ${navbarHeight});
`;

function App() {
  // Gets the active user if already signed in, shows google one tap if not
  const { user, loading } = useFirebaseAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState(false);
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);
  const [hasShownResults, setHasShownResults] = useState(false);
  const { userRoles, loading: isRolesLoading } = useUserRoles()

  if (isRolesLoading || loading) return "Loading";
  
  const toggleCommentsDrawer = () => setIsCommentsDrawerOpen(!isCommentsDrawerOpen);
  const closeDrawer = () => setIsCommentsDrawerOpen(false);
  const openCommentsDrawer = () => setIsCommentsDrawerOpen(true);
  const path = location.pathname;
  // Lock down logged in vs logged out pages
  if (user && LOGGED_OUT_PATHS.includes(path)) navigate(HOME_PATH)
  if (!user && LOGGED_IN_PATHS.includes(path)) navigate(HOME_PATH)
  
  if (!user && (path.startsWith('/quiz') || path.startsWith('/review'))) {
    navigate(HOME_PATH)
  }
  if (userRoles && !userRoles?.editor && path.startsWith('/review')) {
    navigate(HOME_PATH)
  }
  if (userRoles && !userRoles?.admin && path.startsWith(ROLES_PATH)) navigate(HOME_PATH)

  const quizProps = {
    setHasShownResults: setHasShownResults,
    hasShownResults: hasShownResults,
    isCommentsDrawerOpen: isCommentsDrawerOpen, 
    closeDrawer: closeDrawer, 
    openCommentsDrawer: openCommentsDrawer,
    userRoles
  }

  const appContextValue = {
    isCommentsDrawerOpen,
    isSearchDrawerOpen,
    setIsCommentsDrawerOpen,
    setIsSearchDrawerOpen,
  }
  
  return (
    <ThemeProvider theme={darkTheme}>
      <AppContext.Provider value={appContextValue}>
      <Navbar 
      user={user} 
      isCommentsDrawerOpen={isCommentsDrawerOpen} 
      toggleCommentsDrawer={toggleCommentsDrawer} 
      hasShownResults={hasShownResults}
      />
      <AppContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <Routes>
            <Route path={HOME_PATH} element={<AllSentencesList />} />
            <Route path={SIGN_IN_PATH} element={<SignIn />} />
            <Route path={SIGN_UP_PATH} element={<SignUp />} />
            <Route path={PROFILE_PATH} element={<Profile />} />
            <Route path={ROLES_PATH} element={<Roles />} />
            <Route path={QUIZ_PATH} element={<Quiz {...quizProps} />} />
            <Route path={REVIEW_PATH} element={<Quiz {...quizProps} isEditor={userRoles?.editor} />} />
            <Route path={CATCH_ALL_PATH} element={<AllSentencesList />} />
            <Route path={'/test'} element={<LatinComposer />} />
            
          </Routes>
        )}

{isSearchDrawerOpen &&
                <GameMenuSearchDrawer
                    />}
      </AppContainer>
      </AppContext.Provider>
      </ThemeProvider>
  );
}

export default App;