import React, { useState, useEffect } from 'react';
import { auth, functions } from '../firebase';
import { Paper, Typography, Grid, Select, MenuItem, InputLabel, FormControl, Button, TextField, Chip } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import useUserRoles from '../utils/firebase/useUserRoles';
const setRoleFunction = httpsCallable(functions, 'setCustomUserRole');


function Roles() {
    const { userRoles } = useUserRoles();
    const [userIdToSet, setUserIdToSet] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);

    const updateRole = async () => {
        try {
            const result = await setRoleFunction({ uid: userIdToSet, roles: selectedRoles });
            console.log('Roles updated:', result.data.message);
        } catch (error) {
            console.error('Error updating roles:', error);
        }
    };

    console.log('userRoles', userRoles)

    if (!userRoles?.admin) {
        return <Typography variant="h6">Access Denied</Typography>;
    }

    return (
        <Paper elevation={3} style={{ padding: '2em', width: '300px' }}>
            <Typography variant="h6" gutterBottom>Set User Roles</Typography>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        label="User ID"
                        value={userIdToSet}
                        onChange={e => setUserIdToSet(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="roles-select-label">Roles</InputLabel>
                        <Select
                            labelId="roles-select-label"
                            multiple
                            value={selectedRoles}
                            onChange={e => setSelectedRoles(e.target.value)}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </div>
                            )}
                        >
                            <MenuItem value={"editor"}>Editor</MenuItem>
                            <MenuItem value={"moderator"}>Moderator</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={updateRole} fullWidth>
                        Update User Roles
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Roles;
