import React, { useState } from 'react';
import { Button, AppBar, Toolbar, Menu, MenuItem, IconButton, useTheme, useMediaQuery } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle'; // This is the user icon
import styled from 'styled-components';
import { auth } from '../firebase';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { HOME_PATH, PROFILE_PATH, ROLES_PATH, SIGN_IN_PATH, SIGN_UP_PATH } from '../data/paths';
import CommentIcon from '@mui/icons-material/Comment';
import SearchIcon from '@mui/icons-material/Search';
import useUserRoles from '../utils/firebase/useUserRoles';
import useFirebaseAuth from '../utils/useFirebaseAuth';
import { useApp } from '../utils/AppContext';


const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const navbarHeight = '64px';

export const Navbar = ({ user, isCommentsDrawerOpen, toggleCommentsDrawer, hasShownResults }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isQuizPage = user && (location.pathname?.startsWith('/quiz') || location.pathname?.startsWith('/review'));
  const [anchorEl, setAnchorEl] = useState(null); // for menu
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { userRoles } = useUserRoles();
  const { setUser } = useFirebaseAuth();
  const { isSearchDrawerOpen, setIsSearchDrawerOpen, setIsCommentsDrawerOpen } = useApp();


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loggedOutOptions = (
    <>
      <NavLink to={SIGN_IN_PATH}><Button>Sign In</Button></NavLink>
      <NavLink to={SIGN_UP_PATH}><Button>Sign Up</Button></NavLink>
    </>
  );

  const loggedInOptions = (
    <>
      <div variant="body1" style={{ marginRight: '8px' }}>
        👋 {`Salve, ${user?.displayName?.trim() ?? 'Stranger'}!`}
      </div>
      
      {isQuizPage && hasShownResults && (
        <IconButton onClick={() => {
          toggleCommentsDrawer();
          setIsSearchDrawerOpen(false);
          }}>
            <CommentIcon color={!isCommentsDrawerOpen ? "primary" : "secondary"}/>
        </IconButton>
      )}
      { (
        <IconButton onClick={() => {
          setIsSearchDrawerOpen(!isSearchDrawerOpen);
          setIsCommentsDrawerOpen(false);
          }}>
            <SearchIcon color={!isSearchDrawerOpen ? "primary" : "secondary"}/>
        </IconButton>
      )}

      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!isSmUp && (
          <MenuItem onClick={() => {
            handleClose();
            navigate(HOME_PATH);
          }}>Home</MenuItem>
        )}
        <MenuItem onClick={() => {
          handleClose();
          navigate(PROFILE_PATH);
        }}>Profile</MenuItem>
        {/* TODO: lockdown to admins */}
        {userRoles?.admin && (
          <MenuItem onClick={() => {
            handleClose();
            navigate(ROLES_PATH);
          }}>Roles</MenuItem>
        )}
        <MenuItem onClick={() => {
          navigate(HOME_PATH);
          handleClose();
          setUser(null);
          // Make sure we are back on the home page before signing out
          // Avoids a firebase error about not having permissions
          setTimeout(() => auth.signOut(), 300);
        }}>Sign Out</MenuItem>
      </Menu>
      </>
  );

  return (
    <AppBar position="static" style={{ height: navbarHeight }}>
      <StyledToolbar>
        <span>
          <Button onClick={() => navigate(HOME_PATH)}>Ludus Translationis</Button>
          {isSmUp && <Button onClick={() => navigate(HOME_PATH)}>Home</Button>}
        </span>
        <FlexContainer>
          {!user && loggedOutOptions}
          {user && loggedInOptions}
        </FlexContainer>
      </StyledToolbar>
    </AppBar>
  );
};
