import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyC6j4riLIV9RNXopBX7_qCtPIzJYbe-8p0",
    authDomain: "ludus-translationis.firebaseapp.com",
    projectId: "ludus-translationis",
    storageBucket: "ludus-translationis.appspot.com",
    messagingSenderId: "885634361151",
    appId: "1:885634361151:web:4593242c4befc60682d58a",
    measurementId: "G-P465ERPBZN"
  };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const firestore = getFirestore();  // Initialize full firestore
console.log('app', {
    app,
    db
});
export const functions = getFunctions(app);

// export { app, auth, db };