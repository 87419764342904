import React from 'react';
import { IconButton, Grid, Tooltip } from '@mui/material';
import { StyledButton, StyledGridItem } from './styled';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stars } from '@mui/icons-material';

import { accessibleColors } from '../../data/theme';
import useUserRoles from '../../utils/firebase/useUserRoles';

const TranslationCard = ({ 
    isCommentsDrawerOpen,
    fontSize,
    item, 
    index, 
    bestTranslationId, 
    editorsChosenTranslationId,
    upvotedTranslationIds, 
    downvotedTranslationIds, 
    onAnswerClicked, 
    onUpvote, 
    onDownvote,
    hasShownResults,
    hasResponded,
    isEditor
}) => {
    const { userRoles } = useUserRoles();
    const getColor = () => {
        if (item.id === editorsChosenTranslationId) return 'gold';
        if (item.id === bestTranslationId) return '#cd7f32';

        return accessibleColors[index % accessibleColors.length];
    }
    const blue = '#007BFF';
    // console.log({
    //     'item.id': item.id,
    //     editorsChosenTranslationId
    // })


    return (
        <StyledGridItem
            item xs={12} 
            sm={isCommentsDrawerOpen ? 12 : 6} 
            md={6}
            key={index}
            color={getColor()}
            onClick={() => onAnswerClicked(item.id)}
            lang='la'
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {hasResponded && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '5px', width: '80px', paddingLeft: '0.5rem', paddingBottom: '0.5rem' }}>
                    <Tooltip 
                        placement="top"
                        title={"Upvote this translation"}
                        >
                        <IconButton
                            onClick={(e) => onUpvote(e, item.id)}
                            style={{ color: upvotedTranslationIds.includes(item.id) ? 'orange' : '#FFFFFF' }}
                            disabled={item.id === bestTranslationId}
                        >
                            <ThumbUpIcon style={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip 
                        placement="top"
                        title={"Net votes"}
                        >
                    <span style={{ color: '#FFFFFF', alignSelf: 'center' }}>  
                        {hasShownResults && (item.netVotes ?? '?')}
                    </span>
                    </Tooltip>
                    <Tooltip 
                        placement="top"
                        title={"Downvote this translation"}
                        >
                    <IconButton
                        onClick={(e) => onDownvote(e, item.id)}
                        style={{ color: downvotedTranslationIds.includes(item.id) ? '#007BFF' : '#FFFFFF' }}
                        disabled={item.id === bestTranslationId}
                    >
                        <ThumbDownIcon style={{ fontSize: '1.2rem' }} />
                    </IconButton>
                    </Tooltip>

                    {(item.id === editorsChosenTranslationId && hasShownResults) && (
                        <div style={{ gridColumn: '1 / span 1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip 
                        placement="top"
                        title="Selected by professional editor"
                        >
                            <CheckCircleIcon style={{ color: item.id === editorsChosenTranslationId ? 'gold' : 'white', fontSize: '1.2rem', fontWeight: '900' }} />
                            </Tooltip>
                        </div>
                    )}
                    {hasShownResults && (
                        <div style={{ gridColumn: '2 / span 1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip 
                        placement="top"
                        title={"Best translation votes"}
                        >
                            <span style={{ color: '#FFFFFF', marginRight: '5px' }}>
                                {item.bestVotes || 0}
                            </span>
                            </Tooltip>
                        </div>
                    )}
                    {(item.id === bestTranslationId || hasShownResults) && (
                        <div style={{ gridColumn: '3 / span 1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip 
                        placement="top"
                        title={item.id === bestTranslationId ? "Selected as best translation" : 'Best translation (not selected)'}
                        >
                            <StarIcon style={{ color: item.id === bestTranslationId ? 'gold' : 'white', fontSize: '1.2rem' }} />
                            </Tooltip>
                        </div>
                    )}
                </div>
                )}
                <StyledButton disabled={!isEditor && item.id === bestTranslationId} fontSize={fontSize} style={{ marginLeft: '10px' }}>
                    {item.text}
                </StyledButton>
            </div>
        </StyledGridItem>
    );
};

export default TranslationCard;
