// Breakpoints as per Material-UI's default values
export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
};

export const determineFontSize = (text, containerWidth) => {
    if (!text) return '1rem';

    const length = text.length;
    // console.log('determineFontSize', { length, containerWidth })

    if (containerWidth <= breakpoints.sm) {
        // if (length <= 50) return '1.125rem';
        // if (length <= 100) return '1.5rem';
        return '1.125rem';
    }

    if (containerWidth <= breakpoints.md) {
        if (length <= 50) return '2rem';
        if (length <= 100) return '1.5rem';
        return '1.25rem';
    }

    if (containerWidth <= breakpoints.lg) {
        // Adjust font sizes if necessary for the lg breakpoint
        if (length <= 50) return '2rem';
        if (length <= 100) return '1.5rem';
        return '1.25rem';
    }

    if (containerWidth <= breakpoints.xl) {
        // Adjust font sizes if necessary for the xl breakpoint
        if (length <= 50) return '2rem';
        if (length <= 100) return '1.5rem';
        return '1.25rem';
    }

    return '2.25rem';  // Default or for screens larger than 'xl'
};