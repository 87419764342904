import { deleteDoc, doc, setDoc } from "@firebase/firestore";
import generateUniqueDisplayName from "./generateUniqueDisplayNames";
import { firestore } from "../firebase";
import { updateProfile } from "firebase/auth";

export default async function updateUniqueDisplayName(user, { existingDisplayName, newDisplayName } = {}) {
    // The newDisplayName has already had its uniqueness checked. 
    // Otherwise, if the displayName comes from Google, we need to generate a unique display name
    const uniqueDisplayName = newDisplayName ? newDisplayName : await generateUniqueDisplayName(user.displayName);
    // console.log('uniqueDisplayName', uniqueDisplayName);

    // Store user data in Firestore
    await setDoc(doc(firestore, 'displayNames', uniqueDisplayName), { uid: user.uid });
    await updateProfile(user, { displayName: uniqueDisplayName });

    // Free up the display name to be used by someone else
    if (existingDisplayName) {
        const existingDisplayNameDoc = doc(firestore, 'displayNames', existingDisplayName);
        await deleteDoc(existingDisplayNameDoc);
    }
  }
  