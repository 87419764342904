import React, { useState, useEffect, useRef } from 'react';
import { Grid, LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import { auth, firestore } from '../firebase';
import { arrayUnion, collection, doc, getDocs, increment, query, serverTimestamp, updateDoc, where } from '@firebase/firestore';
import CommentsDrawer from '../components/CommentsDrawer';
import { useNavigate, useParams } from 'react-router-dom';
import { determineFontSize } from '../utils/determineFontSize';
import shuffle from '../utils/shuffle';
import fetchQuizData from '../utils/firebase/fetchQuizData';
import updateTranslationsWithVotes from '../utils/quiz/updateTranslationsWithVotes';
import updateTranslations from '../utils/firebase/updateTranslations';
import addResponse from '../utils/firebase/addResponse';
import sortTranslations from '../utils/quiz/sortTranslations';
import { AnswersContainer, Container, StyledTypography, TranslationsGrid } from '../components/quiz/styled';
import PromptCard from '../components/quiz/PromptCard';
import TranslationCard from '../components/quiz/TranslationCard';
import ActionButtons from '../components/quiz/ActionButtons';
import getPrompt from '../utils/quiz/getPrompt';
import { HOME_PATH } from '../data/paths';
import { v4 as uuidv4 } from 'uuid';
import skipSentence from '../utils/firebase/skipSentence';
import { COLLECTIONS } from '../data/collections';
import GameMenuSearchDrawer from '../components/GameMenuSearchDrawer';
import { useApp } from '../utils/AppContext';
import LatinComposerModal from './LatinComposerModal';
import LatinComposer, { fetchSuggestedTranslations } from './LatinComposer';
import { generateUniqueId } from '../utils/generateUniqueId';

function Quiz({ isCommentsDrawerOpen, closeDrawer, openCommentsDrawer, hasShownResults, setHasShownResults, isEditor }) {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const { isSearchDrawerOpen } = useApp()
    const [isLoading, setIsLoading] = useState(false);
    const [loadingPrompt, setLoadingPrompt] = useState("Loading...");

    let { id: sentenceId } = useParams();
    const [quizData, setQuizData] = useState(null);
    const [fontSize, setFontSize] = useState('2rem');
    const [shownTranslations, setShownTranslations] = useState(isEditor);
    const [hasResponded, setHasResponded] = useState(isEditor);
    const [hasNewTranslation, setHasNewTranslation] = useState(false);
    const [hasRequestedNewTranslation, setHasRequestedNewTranslation] = useState(false);
    const [hasSkippedTranslation, setHasSkippedTranslation] = useState(false);
    const [bestTranslationId, setBestTranslationId] = useState(null);
    const [editorsChosenTranslationId, setEditorsChosenTranslationId] = useState(null);
    const [upvotedTranslationIds, setUpvotedTranslationIds] = useState([]);
    const [downvotedTranslationIds, setDownvotedTranslationIds] = useState([]);
    const [isLatinComposerOpen, setIsLatinComposerOpen] = useState(false);

    const containerRef = useRef(null);
    const userId = auth.currentUser ? auth.currentUser.uid : null;

    useEffect(() => {
        const handleResize = () => {
            if (quizData && containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const newFontSize = determineFontSize(quizData.text, containerWidth);
                setFontSize(newFontSize);
            }
        };

        // Wrap the handleResize in a requestAnimationFrame to delay its execution
        // until after the browser's next paint.
        const handleResizeAfterPaint = () => {
            requestAnimationFrame(() => {
                handleResize();
            });
        };

        // Call this function initially and whenever quizData or the drawer state changes
        handleResizeAfterPaint();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [quizData, isCommentsDrawerOpen]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const data = await fetchQuizData(sentenceId);
                console.log('data', data)
                if (data) {
                    if (!data.translations?.length) {
                        setLoadingPrompt(`Generating translations for "${data.text
                        }"`)
                        console.log(`Note: '${data.text}' does not currently have any translations`)
                        const newSuggestions = await fetchSuggestedTranslations(data.text, 4, [])
                        const newTranslations = newSuggestions.map(suggestion => ({ 
                            // add uuid id
                            bestVotes: 0,
                            id: generateUniqueId(suggestion),
                            netVotes: 1,
                            text: suggestion, 
                        }))

                        data.translations = newTranslations;
                        console.log('Generated new translations', newTranslations)

                        // Saving new translations
                        const sentenceRef = doc(firestore, COLLECTIONS.sentences, sentenceId);
                        await updateDoc(sentenceRef, {
                            translations: newTranslations,
                        });

                    }
                    setQuizData(data);
                    setShownTranslations(shuffle(data.translations ?? []));
                    setEditorsChosenTranslationId(data.editorsChosenTranslationId);
                    
    
                    // Check for existing response
                    if (userId) {
                        const responsesCollectionRef = collection(firestore, 'responses');
                        const userResponseQuery = query(responsesCollectionRef, where('userId', '==', userId), where('sentenceId', '==', sentenceId));

                        const querySnapshot = await getDocs(userResponseQuery);
                        if (!querySnapshot.empty) {
                            setHasResponded(true);
                            setHasShownResults(true);
                            const userResponse = querySnapshot.docs[0].data();
                            setBestTranslationId(userResponse.bestTranslationId);
                            setUpvotedTranslationIds(userResponse.upvotedTranslationIds || []);
                            setDownvotedTranslationIds(userResponse.downvotedTranslationIds || []);
                            if (isMdUp) openCommentsDrawer();
                        } else {
                            setHasShownResults(isEditor);
                        }
                    }

                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching quiz data:", error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [sentenceId]);

    useEffect(() => {
        if (hasShownResults && quizData) {
            const sortedTranslations = sortTranslations(quizData.translations, bestTranslationId, editorsChosenTranslationId);
    
            setShownTranslations(sortedTranslations);
        }
    }, [hasShownResults, quizData]);
    

    useEffect(() => {
        if (quizData && containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const newFontSize = determineFontSize(quizData.text, containerWidth);
            setFontSize(newFontSize);
        }
    }, [quizData, containerRef]);

    useEffect(() => {
        if (quizData) {
            document.title = `Ludus - ${quizData.text}`;
        }

        // Cleanup function to reset the title
        return () => {
            document.title = "Ludus Translationis"; // Replace "Default Title" with your desired default title
        };
    }, [quizData]);

    useEffect(() => closeDrawer(), []);

    if (!quizData) return (
    <div>
        <StyledTypography>{loadingPrompt}</StyledTypography>;
        <LinearProgress />
        </div>
        );

    const handleAnswerClicked = async (translationId) => {
        if (isEditor && translationId) {
            const sentenceRef = doc(firestore, COLLECTIONS.sentences, sentenceId);
            await updateDoc(sentenceRef, {
                editorsChosenTranslationId: translationId,
                hasEditorChosenTranslation: true,
            });
            setEditorsChosenTranslationId(translationId)
            return;
        }

        if (hasShownResults || hasResponded) return;

        setBestTranslationId(translationId);
        setUpvotedTranslationIds(prev => [...prev, translationId]);
        setHasResponded(true);

        const sortedTranslations = sortTranslations(quizData.translations, translationId, editorsChosenTranslationId);
        setShownTranslations(sortedTranslations);
    };

    const handleTranslationSelected = async (customTranslation) => {
        console.log('handleTranlsatoinSelected', customTranslation)
        // const customTranslation = prompt("(Optional) Enter a new custom translation:");
  
        if (customTranslation) {
            const newTranslationId = uuidv4();
            const newTranslation = {
            id: newTranslationId,
            text: customTranslation,
            bestVotes: 0,
            netVotes: 1,
            upvotedBy: [userId],
            bestBy: [userId]
            };


            console.log({
                'isEditor ? newTranslationId : editorsChosenTranslationId': isEditor ? newTranslationId : editorsChosenTranslationId,
                isEditor,
                newTranslationId,
                editorsChosenTranslationId
            })
            // Update the Firestore document
            const sentenceRef = doc(firestore, COLLECTIONS.sentences, sentenceId);
            await updateDoc(sentenceRef, {
                translations: arrayUnion(newTranslation),
                customTranslationNeededVotes: increment(1),
                customTranslationNeededBy: arrayUnion(userId),
                editorsChosenTranslationId: isEditor ? newTranslationId : (editorsChosenTranslationId || null),
                hasEditorChosenTranslation: isEditor ? true : !!editorsChosenTranslationId,
            });
            setBestTranslationId(newTranslationId)
            if (isEditor) setEditorsChosenTranslationId(newTranslationId)

            setQuizData(prevData => {
                // Update local state
                const updatedUnsortedTranslations = [...(prevData.translations ?? []), newTranslation, ];
               
                const sortedTranslations = sortTranslations(updatedUnsortedTranslations, newTranslationId, editorsChosenTranslationId);
                setShownTranslations(sortedTranslations);

                return {
                ...prevData,
                translations: updatedUnsortedTranslations
                }
            });

            setUpvotedTranslationIds(prev => [...prev, newTranslationId]);
            setHasNewTranslation(true);
        }

        setHasRequestedNewTranslation(true);
        setHasResponded(true);
        setIsLatinComposerOpen(false);
    };

    const handleNotSure = async () => {
        await skipSentence(sentenceId, userId);
        // TODO: Adding a response for skipping the sentence is currently what makes sure user's
        // can't edit a sentence they have skipped before.
        await addResponse({
            createdAt: serverTimestamp(),
            userId,
            sentenceId,
            hasSkippedTranslation: true
        });
        navigate(HOME_PATH);
    }
    
    const handleShowResults = async () => {
        if (isMdUp) openCommentsDrawer();
        setHasShownResults(true);
    
        if (!quizData) {
            console.error("Quiz data not loaded yet!");
            return;
        }

        if (!quizData.translations) {
            console.warn('no translations exist')
            return;
        }
    
        console.log('quizData.translations', quizData.translations)
        const updatedTranslations = updateTranslationsWithVotes(quizData.translations, bestTranslationId, upvotedTranslationIds, downvotedTranslationIds, auth.currentUser.uid);
        await updateTranslations(sentenceId, updatedTranslations, auth.currentUser.uid, quizData.respondedBy);
        await addResponse({
            bestTranslationId,
            createdAt: serverTimestamp(),
            userId: auth.currentUser.uid,
            sentenceId,
            upvotedTranslationIds,
            downvotedTranslationIds,
            isCustomTranslationNeeded: hasRequestedNewTranslation,
            hasSkippedTranslation
        });
    };
    
    const handleUpvote = (e, translationId) => {
        e.stopPropagation();
        if (hasShownResults || translationId === bestTranslationId) return;

        if (!upvotedTranslationIds.includes(translationId)) {
            setUpvotedTranslationIds(prev => [...prev, translationId]);
            setDownvotedTranslationIds(prev => prev.filter(id => id !== translationId));
        }
    };

    const handleDownvote = (e, translationId) => {
        e.stopPropagation();
        if (hasShownResults) return;

        if (!downvotedTranslationIds.includes(translationId)) {
            setDownvotedTranslationIds(prev => [...prev, translationId]);
            setUpvotedTranslationIds(prev => prev.filter(id => id !== translationId));
        }
    };

    const handleNextTranslation = () => {
        navigate(HOME_PATH);
    }

    const answersElements = (
        <AnswersContainer>
                    <TranslationsGrid container spacing={2}>
                        {(shownTranslations ?? [])?.map((item, index) => (
                            <TranslationCard 
                            isCommentsDrawerOpen={isCommentsDrawerOpen}
                                key={item.id}
                                fontSize={fontSize}
                                item={item} 
                                hasShownResults={hasShownResults}
                                index={index} 
                                bestTranslationId={bestTranslationId} 
                                upvotedTranslationIds={upvotedTranslationIds} 
                                downvotedTranslationIds={downvotedTranslationIds}
                                onAnswerClicked={handleAnswerClicked}
                                onUpvote={handleUpvote}
                                onDownvote={handleDownvote}
                                hasResponded={hasResponded}
                                editorsChosenTranslationId={editorsChosenTranslationId}
                                isEditor
                            />
                        ))}
                    </TranslationsGrid>
                    <ActionButtons 
                    isCommentsDrawerOpen={isCommentsDrawerOpen}
                        fontSize={fontSize}
                        hasResponded={hasResponded} 
                        hasShownResults={hasShownResults}
                        onCustomTranslationNeeded={() => setIsLatinComposerOpen(true)}
                        onNotSure={handleNotSure}
                        onShowResults={handleShowResults}
                        onNextTranslation={handleNextTranslation}
                        isEditor={isEditor}
                    />
                </AnswersContainer>
    );

    console.log('quizData', quizData.translations)

    return (
        <div style={{ display: 'flex', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
            <Container maxWidth="sm" ref={containerRef}>
                <PromptCard prompt={getPrompt(hasResponded, hasShownResults, hasNewTranslation, isEditor)} text={quizData.text} fontSize={fontSize} frequency={quizData.frequency} />
                
                {!isLatinComposerOpen ? answersElements : (
                    <LatinComposer 
                    initialPhrase={quizData.text} 
                    onTranslationSelected={handleTranslationSelected}
                    translations={quizData?.translations?.map(({ text}) => text)}
                    onCancel={() => setIsLatinComposerOpen
                    (false)} />
                )}
                
                
            </Container>
            {isCommentsDrawerOpen &&
                <CommentsDrawer
                    isDrawerOpen={isCommentsDrawerOpen}
                    closeDrawer={closeDrawer}
                    resourceId={sentenceId} />}
            {/* {isSearchDrawerOpen &&
                <GameMenuSearchDrawer
                    />} */}
                    {/* <LatinComposerModal isOpen={isLatinComposerOpen} setIsOpen={setIsLatinComposerOpen} initialPhrase={quizData.text} /> */}
        </div>
    );
}

export default Quiz;
