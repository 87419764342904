import React, { useState, useEffect, useRef } from "react";
import { auth } from "../firebase"; // Adjust based on your import structure
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  LinearProgress,
} from "@mui/material";
import updateUniqueDisplayName from "../utils/updateUniqueDisplayNames";
import { isDisplayNameUnique } from "../utils/isDisplayNameUnique";
import { api } from "../chatgpt";
import Popover from '@mui/material/Popover';
import Pagination from '@mui/material/Pagination';
import { getAllMatchesAndSubstitutions, processText } from "../components/quiz/PromptCard";

export const fetchSuggestedTranslations = async (phrase, numberOfTranslations, existingTranslations) => {
  if (!phrase) return;

  const matchesAndSubstitutions = getAllMatchesAndSubstitutions(phrase, true);
  const prefix = matchesAndSubstitutions?.length > 0 ? `The following is a glossary of english words or phrases that show up in the video game Skyrim and how you could translate them into Latin. Prefer Latin translations when possible over English. Note you will likely need to change the suffixes:\n${matchesAndSubstitutions}\n\n` : '';
  const res = await api.sendMessage(
    `${prefix}Please provide a JavaScript array with up to ${numberOfTranslations} translations of the sentence \"${phrase}\" into beginner friendly Latin preferring common latin vocab or vocab from the book LLPSI. Sort the translations the most beginner friendly first. Please try to retain word order when possible. Your reply will be used in a react program, so only respond with the array itself. Don't talk to me or explain your process.`
  );
  const responsesArrStr = res.text.slice(
    res.text.indexOf("["),
    res.text.lastIndexOf("]") + 1
  );
  console.log(res.text);
  const suggestedTranslations = JSON.parse(responsesArrStr)?.filter(suggestion => !existingTranslations?.includes
    (suggestion));
    const uniqueSuggestions = [...new Set(suggestedTranslations)];
    return uniqueSuggestions;
}
function LatinComposer({ 
  isEditingEnabled = false,
   initialPhrase = "Yes, the Dark Brotherhood in skyrim hates General Tullius, we're off to Bleak Falls barrow after windhelm, winterhold, and solitude, also stormcloak and stormcloaks, what are you doing, Storm", 
  //  initialPhrase = "We're not rebels", 
  //  initialPhrase = "I need you here working on ways to defend the city against these dragons", 
  onTranslationSelected,
  translations,
  onCancel,
  }) {
  const [prompt, setPrompt] = useState("(Optional) Enter a custom translation below");
  const [phrase, setPhrase] = useState(initialPhrase);
  const [latinTranslation, setLatinTranslation] = useState("");
  const [review, setReview] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  const [lastReviewedTranslation, setLastReviewedTranslation] = useState("");

  const openPopover = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedSuggestionIndex(index);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const handleSelectSuggestion = () => {
    const selectedSuggestion = suggestions[selectedSuggestionIndex];
    handleSuggestionClicked(selectedSuggestion);
    closePopover();
  };

  const handleChangePage = (event, newPage) => {
    setSelectedSuggestionIndex(newPage - 1);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'translation-popover' : undefined;

  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(!isEditingEnabled);
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  const [isEditing, setIsEditing] = useState(isEditingEnabled);
  const [suggestions, setSuggestions] = useState(null);
  const phraseWithTooltips = React.useMemo(
    () => processText(phrase, () => console.log("info clicked")),
    [isEditing]
  );

  const handleLoadSuggestions = async () => {
    // setIsLoadingSuggestions(true);
    try {
      fetchData(2);
    } catch (error) {
      console.error(error)
    }
    try {
      fetchData(4);
    } catch (error) {
      console.error(error)
    }
    try {
      fetchData(6);
    } catch (error) {
      console.error(error)
    }
  };

  const handleEdit = () => setIsEditing(true);

  const updateReview = async (translation = latinTranslation) => {
    

    setReview('');
    setIsLoadingReview(true);
    const res =
      await api.sendMessage(`Given the original English text "${phrase}" and its Latin translation "${translation}", assess the accuracy of the translation. Additionally, evaluate how beginner-friendly the Latin version is, considering common vocabulary and structures. Suggest improvements to make it more beginner friendly without losing any meaning or context from the original english. Be brief, the explanation can be 1 short paragraph. Include your final suggestion at the end separated from the explanation with a blank line above it.
        `);
    console.log(res.text);
    setReview(res.text);
    setIsLoadingReview(false);
    setLastReviewedTranslation(translation);
  };

  const handleSuggestionClicked = async (suggestion) => {
    setLatinTranslation(suggestion);
    updateReview(suggestion);
  };

  const handleReviewTranslation = async () => {
    updateReview();
  };

  // const handleSelectTranslation = async () => {
    
  //   alert(latinTranslation + ' selected');
  // };


  async function fetchData(numberOfTranslations) {
    console.log('aghhhh', { isLoadingSuggestions, phrase })
    if (!phrase) return;
    const newSuggestions = await fetchSuggestedTranslations(phrase, numberOfTranslations, translations)
    setSuggestions(prevSuggestions => [...new Set([...(prevSuggestions ?? []), ...newSuggestions, ])]);
    setIsLoadingSuggestions(false);
    setIsEditing(false);
  }

  const isMounted = useRef(false);

  useEffect(() => {
    // Check if the component has already been mounted
    if (!isMounted.current) {
      // Component is mounting (first render)
      isMounted.current = true;
      // console.log('Component is mounting'); // Debugging message
      if (!isEditingEnabled) {
        handleLoadSuggestions();
      }
    } 
  }, [isEditingEnabled, handleLoadSuggestions]);

  const suggestedTranslations = suggestions?.length > 0 && (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon="🚀"
        style={{ marginLeft: '1rem'}}
        onClick={(e) => openPopover(e, 0)}
      >
        {suggestions?.length} Suggested Translations
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '1rem' }}>
          <Typography variant="h6">Suggested Translation</Typography>
          <Typography style={{ paddingTop: '0.25rem'}}>{suggestions[selectedSuggestionIndex]}</Typography>
          <Pagination
            count={suggestions.length}
            page={selectedSuggestionIndex + 1}
            onChange={handleChangePage}
            style={{ margin: '1rem 0'}}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectSuggestion}
            fullWidth
          >
            Select
          </Button>
          
        </div>
      </Popover>
    </>
  );

  console.log('translations', translations)

  return (
    <Paper elevation={3} style={{ padding: "2em", width: "100%", maxWidth: '960px' }}>
      <Typography variant="h5" gutterBottom textAlign="center">
        {prompt}
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {/* {!isEditing && <p>{phraseWithTooltips}</p>} */}
          {isEditing && (
            <TextField
              label="English Sentence"
              value={phrase}
              onChange={(e) => setPhrase(e.target.value)}
              fullWidth
              disabled={!isEditing}
            />
          )}
        </Grid>

        <Grid item>
          {!isLoadingSuggestions && isEditing && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadSuggestions}
              fullWidth
            >
              Translate Sentence
            </Button>
          )}
          {!isLoadingSuggestions && !isEditing && isEditingEnabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              fullWidth
            >
              Edit
            </Button>
          )}
          
        </Grid>

        {/* {suggestions?.length > 0 && (
          <Grid item textAlign='center'>
            <h2>Translations</h2>
          </Grid>
        )}
        <Grid container textAlign='center'>
            {suggestions?.map((suggestion) => (
            <Grid xs={4} item onClick={() => handleSuggestionClicked(suggestion)} marginBottom='1rem'>
                {suggestion}
            </Grid>
            ))}
        </Grid> */}
        {/* {suggestedTranslations} */}
        

        {!isEditing && (
          <>
            <Grid item>
              <TextField
                label="Latin Translation"
                value={latinTranslation}
                onChange={(e) => setLatinTranslation(e.target.value)}
                fullWidth
                style={{ marginBottom: '1rem' }}
              />
              
            </Grid>
            <Grid container>
            {(
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReviewTranslation}
                  style={{ marginLeft: '1rem'}}
                  // fullWidth
                  disabled={!latinTranslation || isLoadingReview || latinTranslation === lastReviewedTranslation}
                >
                  Review Translation
                </Button>
              </Grid>
              
            )}

              <Grid item>
                {isLoadingSuggestions && (
                  <>
                  <Typography variant="h6" gutterBottom textAlign="center" style={{ marginLeft: '1rem'}}>
                    Loading Suggested Translations...
                  </Typography>
                  <LinearProgress  style={{ marginLeft: '1rem'}} />
                  </>
                )}
                {suggestedTranslations}
                
              </Grid>
              
            </Grid>

            
            <Grid item>
                {isLoadingReview && (
                  <>
                  <Typography variant="h5" gutterBottom textAlign="center">
                    Reviewing the translation with chat gpt...
                  </Typography>
                  <LinearProgress  style={{marginBottom: '1rem' }}/>
                  </>
                )}
            </Grid>
            

            {review && (
              <>
              <h6 style={{ marginLeft: '1rem'}}>'{lastReviewedTranslation}' Chat GPT Review</h6>
                <blockquote dangerouslySetInnerHTML={{ __html: review.replaceAll('\n', '<br>') }}>
                    
                </blockquote>
                </>
            )}

            
            {(
              <Grid container style={{ paddingLeft: '1rem'}}>

              <Grid item style={{ marginRight: '1rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => await onTranslationSelected(latinTranslation)}
                  fullWidth
                  disabled={!latinTranslation || translations.includes(latinTranslation)}
                  
                  >
                  Add Custom Translation
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
                  
                  </Grid>
              
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default LatinComposer;
