import { doc, updateDoc, arrayUnion, increment } from "@firebase/firestore";
import { firestore } from "../../firebase";
import { COLLECTIONS } from "../../data/collections";

export default async function skipSentence(sentenceId, userId) {
    const sentenceDocRef = doc(firestore, COLLECTIONS.sentences, sentenceId);
    
    const updateData = {
        skippedCount: increment(1),
        skippedBy: arrayUnion(userId)
    };

    await updateDoc(sentenceDocRef, updateData);
};
