import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, Divider, Button, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { collection, query, where, onSnapshot, orderBy, addDoc, doc, updateDoc, arrayUnion, arrayRemove, getDocs, getDoc, deleteDoc } from "@firebase/firestore";
import { firestore, auth } from "../firebase";
import { navbarHeight } from './Navbar';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const DrawerHeader = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem 0',
    justifyContent: 'flex-start',
}));

export const commentDrawerWidth = '25rem';

const fetchDisplayName = async (userId) => {
    const displayNamesCollection = collection(firestore, 'displayNames');
    const q = query(displayNamesCollection, where("uid", "==", userId));
    const querySnapshot = await getDocs(q);
    let displayName = 'Anonymous';

    querySnapshot.forEach(doc => {
        displayName = doc.id; // Get the ID of the document which is the display name
    });

    return displayName;
};

const formatTimestamp = (timestamp) => {
    return moment(timestamp.toDate()).fromNow();
};

// TODO: I could probably reduce reads by adding comments to the phrase object
const fetchComments = (resourceId, setComments) => {
    const commentsRef = collection(firestore, 'comments');
    const q = query(
        commentsRef,
        where("resourceID", "==", resourceId),
        orderBy("netVotes", "desc")
    );

    const unsubscribe = onSnapshot(q,
        async (querySnapshot) => {
            let allComments = [];

            const commentsDataPromises = querySnapshot.docs.map(async doc => {
                let comment = doc.data();
                comment.id = doc.id;
                comment.displayName = await fetchDisplayName(comment.userID); // Fetching displayName here for all comments
                return comment;
            });

            allComments = await Promise.all(commentsDataPromises);

            let topLevelComments = allComments.filter(comment => !comment.parentCommentID);
            let nestedComments = allComments.filter(comment => comment.parentCommentID);

            topLevelComments.forEach(topComment => {
                recursivelyAttachReplies(topComment, nestedComments);
            });

            setComments(topLevelComments);
        },
        (error) => {
            console.error("Error fetching comments: ", error);
        }
    );

    return () => {
        unsubscribe();  // This is the cleanup function
    };
};



const recursivelyAttachReplies = (comment, allComments) => {
    comment.replies = allComments.filter(reply => reply.parentCommentID === comment.id);
    comment.replies.forEach(reply => recursivelyAttachReplies(reply, allComments));
};




const handleUpvote = async (comment, userId) => {
    const commentRef = doc(firestore, 'comments', comment.id);
    if (comment.downvotedBy.includes(userId)) {
        await updateDoc(commentRef, {
            downvotedBy: arrayRemove(userId),
            upvotedBy: arrayUnion(userId),
            netVotes: comment.netVotes + 2 // Remove a downvote and add an upvote
        });
    } else if (!comment.upvotedBy.includes(userId)) {
        await updateDoc(commentRef, {
            upvotedBy: arrayUnion(userId),
            netVotes: comment.netVotes + 1 // Just add an upvote
        });
    }
};

const handleDownvote = async (comment, userId) => {
    const commentRef = doc(firestore, 'comments', comment.id);
    if (comment.upvotedBy.includes(userId)) {
        await updateDoc(commentRef, {
            upvotedBy: arrayRemove(userId),
            downvotedBy: arrayUnion(userId),
            netVotes: comment.netVotes - 2 // Remove an upvote and add a downvote
        });
    } else if (!comment.downvotedBy.includes(userId)) {
        await updateDoc(commentRef, {
            downvotedBy: arrayUnion(userId),
            netVotes: comment.netVotes - 1 // Just add a downvote
        });
    }
};


const Comment = ({ comment, depth = 0, userId }) => {
    const [showReplyInput, setShowReplyInput] = useState(false);
    const [replyContent, setReplyContent] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedComment, setEditedComment] = useState(comment.content);
    const [anchorEl, setAnchorEl] = useState(null);


    if (depth > 8) return null;

    // Determine button colors
    const upvoteColor = comment.upvotedBy.includes(userId) ? '#007BFF' : 'inherit';
    const downvoteColor = comment.downvotedBy.includes(userId) ? 'orange' : 'inherit';

    const netUpvotes = comment.netVotes;

    const handleAddReply = async (parentComment) => {
        if (replyContent.trim() === '') return;

        const newComment = {
            userID: userId,
            resourceID: parentComment.resourceID,
            content: replyContent,
            timestamp: new Date(),
            upvotedBy: [],
            downvotedBy: [],
            parentCommentID: parentComment.id,
            netVotes: 1,
        };

        await addDoc(collection(firestore, 'comments'), newComment);
        setReplyContent('');
        setShowReplyInput(false);
    };

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleEditSave = async () => {
        const commentRef = doc(firestore, 'comments', comment.id);
        await updateDoc(commentRef, {
            content: editedComment,
            lastEdited: new Date()
        });
        setIsEditing(false);
    };

    const deleteCommentAndReplies = async (commentId) => {
        // First, fetch the replies of the comment
        const commentsRef = collection(firestore, 'comments');
        const q = query(commentsRef, where("parentCommentID", "==", commentId));

        const querySnapshot = await getDocs(q);
        const replyIds = querySnapshot.docs.map(doc => doc.id);

        // Recursively delete each reply
        for (const replyId of replyIds) {
            await deleteCommentAndReplies(replyId);
        }

        // Once all replies are deleted, delete the comment itself
        const commentRef = doc(firestore, 'comments', commentId);
        await deleteDoc(commentRef);
    };



    return (
        <div style={{ marginLeft: `${depth * 16}px`, marginBottom: '1.5rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5rem' }}>
                <strong>{comment.displayName}</strong>
                <span>
                    {formatTimestamp(comment.timestamp)}
                    {comment.lastEdited && <span style={{ marginLeft: '5px', fontStyle: 'italic' }}>(Edited: {formatTimestamp(comment.lastEdited)})</span>}
                </span>

            </div>
            {isEditing ? (
                <div>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={editedComment}
                        onChange={e => setEditedComment(e.target.value)}
                        label="Edit comment"
                        multiline
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEditSave}
                        style={{ marginTop: '0.5rem' }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setIsEditing(false)}
                        style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}
                    >
                        Cancel
                    </Button>
                </div>
            ) : (
                <p style={{ margin: '0.25rem 0' }}>{comment.content}</p>
            )}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.25rem', marginBottom: '0.5rem', flexWrap: 'wrap' }}>
                <IconButton
                    onClick={() => handleUpvote(comment, userId)}
                    style={{ color: upvoteColor }}
                >
                    <ThumbUpIcon />
                </IconButton>
                {netUpvotes}
                <IconButton
                    onClick={() => handleDownvote(comment, userId)}
                    style={{ color: downvoteColor }}
                >
                    <ThumbDownIcon />
                </IconButton>
                <Button
                    size="small"
                    onClick={() => setShowReplyInput(!showReplyInput)}
                    style={{ marginLeft: '0.5rem' }}
                    startIcon={<CommentIcon />}
                >
                    Reply
                </Button>


                <div style={{ display: 'flex', flexShrink: 0 }}>
                    {comment.replies && comment.replies.length > 0 && (
                        <Button size="small" onClick={() => setIsCollapsed(!isCollapsed)} style={{ marginLeft: '0.5rem', cursor: 'pointer' }}>
                            {(isCollapsed ?
                                `View ${comment.replies.length} replies` :
                                `Hide replies`)
                            }
                        </Button>
                    )}

                    {userId === comment.userID && (
                        <>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleMoreClick}
                                style={{ marginLeft: '0.5rem' }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {!isEditing && (
                                    <MenuItem onClick={() => { setIsEditing(true); handleMenuClose(); }}>
                                        <EditIcon style={{ marginRight: '0.5rem' }} />
                                        Edit
                                    </MenuItem>
                                )}
                                <MenuItem onClick={() => { deleteCommentAndReplies(comment.id); handleMenuClose(); }}>
                                    <DeleteIcon style={{ marginRight: '0.5rem', color: 'red' }} />
                                    Delete
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </div>


            </div>

            {showReplyInput && (
                <div style={{ marginLeft: '20px' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={replyContent}
                        onChange={e => setReplyContent(e.target.value)}
                        label="Your reply"
                        multiline
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddReply(comment)}
                        style={{ marginTop: '0.5rem', marginBottom: '0.25rem' }}
                    >
                        Post Reply
                    </Button>
                </div>
            )}
            <div>
                {!isCollapsed && comment.replies && comment.replies.map(reply =>
                    <Comment comment={reply} depth={depth + 1} key={reply.id} userId={userId} />
                )}
            </div>
        </div>
    );
};

// Allows commenting on a resource (sentence, phrase, etc.)
const CommentsDrawer = ({ isDrawerOpen, closeDrawer, resourceId }) => {
    const [comments, setComments] = useState([]);
    const [commentContent, setCommentContent] = useState('');
    const userId = auth.currentUser?.uid || null;

    useEffect(() => {
        return fetchComments(resourceId, setComments);
    }, [resourceId]);

    const handleAddComment = async () => {
        if (commentContent.trim() === '') return;

        const newComment = {
            userID: userId,
            resourceID: resourceId,
            content: commentContent,
            timestamp: new Date(),
            upvotedBy: [],
            downvotedBy: [],
            parentCommentID: null,
            netVotes: 1, // Start at 1 like reddit
        };

        await addDoc(collection(firestore, 'comments'), newComment);
        setCommentContent('');
    };

    return (
        <Drawer
        sx={{
            width: { xs: '100vw', sm: commentDrawerWidth }, // 100% width for screens smaller than 768px
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: { xs: '100vw', sm: commentDrawerWidth }, // 100% width for screens smaller than 768px
                top: navbarHeight,
                left: { xs: 0, sm: 'auto'},
                bottom: 0
            },
        }}
            variant="persistent"
            position="fixed"
            anchor="right"
            open={isDrawerOpen}
        >
            <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Comments</Typography>
                <IconButton onClick={closeDrawer}>
                    <CloseIcon />
                </IconButton>
            </DrawerHeader>

            <div style={{ padding: '0.25rem 1rem 1rem' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={commentContent}
                    onChange={e => setCommentContent(e.target.value)}
                    label="Add a comment"
                    multiline
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddComment}
                    style={{ marginTop: '1rem' }}
                    fullWidth
                >
                    Comment
                </Button>
            </div>
            <Divider />

            <div style={{ margin: '1rem' }}>
                {comments.map(comment =>
                    !comment.parentCommentID && <Comment comment={comment} key={comment.id} userId={userId} />
                )}
            </div>
        </Drawer>
    );
};

export default CommentsDrawer;
