import React, { useEffect, useState } from 'react';
import { CenteredDiv, PromptTypography, StyledPaper, StyledTypography } from './styled';
import { englishToLatinSubstitutions, latinToEnglishSubstitutions } from '../../data/substitutions';
import { englishToLatinGameMenus } from '../../data/english-to-latin-game-menus';
import { Tooltip, Modal, IconButton, Box, Backdrop, Paper, Link, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

const LargeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '1rem',
        padding: '0.5rem',
      maxWidth: 'none',
    },
  });

const ModalContainer = styled(Paper)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    backgroundColor: 'black',
    color: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '0 1rem',
    fontSize: '1.5rem',
    textAlign: 'center',
    p: 4,
});

class TrieNode {
    constructor() {
        this.children = {};
        this.isEndOfWord = false;
        this.word = null;
    }
}

const buildTrie = (words) => {
    const root = new TrieNode();
    for (let word of words) {
        let node = root;
        for (let char of word.toLowerCase()) {
            if (!node.children[char]) {
                node.children[char] = new TrieNode();
            }
            node = node.children[char];
        }
        node.isEndOfWord = true;
        node.word = word; // Store original word for reference
    }
    return root;
};

const getAllSubstitutions = () => {
    const allSubstitutionsDirty = {...englishToLatinSubstitutions, ...latinToEnglishSubstitutions, ...englishToLatinGameMenus};
    const allSubstitutions = { ...allSubstitutionsDirty };

    Object.keys(allSubstitutionsDirty).forEach(substitution => {
        if (!containsWhitespace(substitution)) {
                allSubstitutions[`${substitution}s`] = allSubstitutions[substitution]
            }
        })

    return allSubstitutions;
}

const containsWhitespace = (str) => {
    return /\s/.test(str);
  };
/**
 * Ex input: The Dark Brotherhood in skyrim hates General Tullius
 * Ex output:  ['The Dark Brotherhood', 'Dark Brotherhood', 'Brotherhood', 'in', 'Skyrim', 'General Tullius', 'Tullius']
 * @returns an array of all matches for `text` as strings
 */
const getAllMatches = (text) => {
    const allSubstitutions = getAllSubstitutions()
    const trieRoot = buildTrie(Object.keys(allSubstitutions));

    let currentIndex = 0;
    let matches = []; // Array to store matching words/phrases

    while (currentIndex < text.length) {
        let node = trieRoot;
        let tempIndex = currentIndex;
        let longestMatchedWord = null;

        while (tempIndex < text.length && node.children[text[tempIndex].toLowerCase()]) {
            node = node.children[text[tempIndex].toLowerCase()];
            if (node.isEndOfWord) {
                // Check if the match is a valid word (flanked by word boundaries)
                const isStartBoundary = currentIndex === 0 || /\s|[.,!?;]/.test(text[currentIndex - 1]);
                const isEndBoundary = tempIndex + 1 === text.length || !/[a-z]/i.test(text[tempIndex + 1]) || (text[tempIndex].toLowerCase() === 's' && (tempIndex + 2 === text.length || !/[a-z]/i.test(text[tempIndex + 2])));
                if (isStartBoundary && isEndBoundary) {
                    longestMatchedWord = node.word;
                }
            }
            tempIndex++;
        }

        if (longestMatchedWord) {
            matches.push(longestMatchedWord);
            currentIndex++; // Just increment by one to capture nested matches
        } else {
            currentIndex++;
        }
    }

    // console.log('matches', matches); // Log the array of matches

    return matches;
};

export const getAllMatchesAndSubstitutions = (text) => {
    if (!text) return [];

    const allSubstitutions = getAllSubstitutions();

    return getAllMatches(text)
        .filter(match => match.at(-1).toLowerCase() !== 's' || !allSubstitutions[match.slice(0, match.length - 1)])
        .map(match => `${match}\t${allSubstitutions[match].join(' | ')}`)
        .join('\n');
}

export const processText = (text, onIconClick) => {
    const allSubstitutions = getAllSubstitutions()
    const trieRoot = buildTrie(Object.keys(allSubstitutions));

    let result = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
        let substitutionMatches = [];
        
        if (currentIndex === 0 || text[currentIndex - 1] === ' ') {  // Start trie traversal only at word boundaries
            let node = trieRoot;
            let tempIndex = currentIndex;
            while (tempIndex < text.length && node.children[text[tempIndex].toLowerCase()]) {
                node = node.children[text[tempIndex].toLowerCase()];
                if (node.isEndOfWord && (tempIndex + 1 === text.length || /\s|[.,!?;]/.test(text[tempIndex + 1]) || (text[tempIndex].toLowerCase() === 's' && (tempIndex + 2 === text.length || /\s|[.,!?;]/.test(text[tempIndex + 2]))))) {
                    substitutionMatches.push(node.word);
                }
                tempIndex++;
            }
        }

        if (substitutionMatches.length > 0) {
            substitutionMatches.sort((a, b) => b.length - a.length); // Sort by length in descending order
            const longestMatch = substitutionMatches[0];
            const nestedMatches = getAllMatches(text).filter(match => longestMatch.toLowerCase().includes(match.toLowerCase()))
            nestedMatches.sort((a, b) => b.length - a.length); // Sort by length in descending order
            // console.log('nestedMatches', nestedMatches)

            const toSubstitutionsString = (match, substitutions) => {
                // console.log('substitutions', substitutions)
                // substitutions.sort((a, b) => {
                //     console.log({
                //         a, b
                //     })
                //     if (a.toLowerCase() === 'Bleak Falls Barrow'.toLowerCase()) {
                //         console.log({
                //             a,
                //             b,
                //             allSubstitutionsA: allSubstitutions[a]
                //         })
                //     }
                //     if (a === match) return 1;
                //     if (b === match) return -1;

                //    return   a.length - b.length;
                // })

                return substitutions.join(' | ')
            }

            const tooltipContent = (
                <>
                    {nestedMatches.map((match, i) => (
                        <div key={match} style={{ marginBottom: '4px' }}>
                            <span style={{fontSize: '1rem'}}>{match} → {toSubstitutionsString(match, allSubstitutions[match])}
                            
                            {nestedMatches.length - 1 === i  && (
                                <IconButton size="small" onClick={onIconClick} style={{ marginLeft: '8px' }}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                            )}
                            </span>
                        </div>
                    ))}
                    
                </>
            );
            result.push(
                <LargeTooltip key={currentIndex} title={tooltipContent} placement="top" arrow>
                    <span style={{borderBottom: '2px dashed orange', cursor: 'pointer'}}>{text.substring(currentIndex, currentIndex + longestMatch.length)}</span>
                </LargeTooltip>
            );
            currentIndex += longestMatch.length;
        } else {
            result.push(text[currentIndex]);
            currentIndex++;
        }

        if (currentIndex < text.length && text[currentIndex] === ' ') {
            result.push(' ');
            currentIndex++;
        }
    }

    return result;
};

const PromptCard = ({ prompt, text, fontSize }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const explanation = <>
        <p>The name substitutions are necessary for <Link href='https://www.nexusmods.com/skyrimspecialedition/mods/27433' target='_blank' rel="noreferrer">Project Caelifinis</Link>. </p>
        <p>Without them, <Link href='https://www.nexusmods.com/skyrimspecialedition/mods/93928' target='_blank' rel="noreferrer">the subtitles mod</Link> won't match game menus or dialogue choices, 
        leading to confusion.</p>
        <p> In the future, these changes might not be required.</p>
    </>;



  const textWithTooltips = React.useMemo(() => processText(text, () => setModalOpen(true)), [text]);
    
  console.log('matchesAndSubstitutions', getAllMatchesAndSubstitutions(text))
    return (
        <CenteredDiv elevation={3}>
            <StyledPaper>
                <PromptTypography variant="h6">
                    {prompt}
                </PromptTypography>
                <StyledTypography variant="h2" fontSize={fontSize}>
                    {textWithTooltips}
                </StyledTypography>
            </StyledPaper>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}

            >
                <ModalContainer>
                    {explanation}
                </ModalContainer>
            </Modal>
        </CenteredDiv>
    );
};

export default PromptCard;
