export const englishToLatinGameMenus = 
 {
  "0": [
    "Torsten"
  ],
  "4": [
    "4"
  ],
  "200": [
    "CC"
  ],
  "word": [
    "vocābulum -ī n",
    "verbum -ī n",
    "vōx, vōcis",
    "fātus, fātūs",
    "verbum, verbī",
    "vōx, vōcis (f.)",
    "verbum, verbī (n)"
  ],
  "one": [
    "ūnus -a -um",
    "alter -era -erum",
    "singulī -ae -a",
    "ūnī -ae -a",
    "ille -a -ud",
    "ūnus, ūna, ūnum"
  ],
  "only": [
    "ūnus -a -um",
    "tantum",
    "sōlum",
    "tantus -a -um",
    "modo",
    "dēmum",
    "dumtaxat",
    "tantummodo",
    "tantum (adv.)",
    "sōlus, sōla, sōlum",
    "ūnicus, ūnica, ūnicum"
  ],
  "where": [
    "ubi",
    "quō adv",
    "Quō"
  ],
  "three": [
    "trēs tria",
    "ternī -ae -a",
    "trīnī -ae -a",
    "trēs, tria"
  ],
  "third": [
    "tertius -a -um",
    "tertius",
    "triens, trientis"
  ],
  "syllable": [
    "syllaba -ae f"
  ],
  "six": [
    "sex",
    "sēnī -ae -a"
  ],
  "but": [
    "at (conj)",
    "sed",
    "autem",
    "at",
    "nisi",
    "vērō",
    "vērum"
  ],
  "second": [
    "secundus -a -um",
    "alter -era -erum",
    "secundus, alter",
    "alter, altera, alterum",
    "secundus, secunda, secundum"
  ],
  "favorable": [
    "secundus -a -um",
    "aequus, aequa, aequum",
    "faustus, fausta, faustum",
    "benevolus, benevola, benevolum",
    "propitius, propitia, propitium"
  ],
  "roman": [
    "rōmānus -a -um",
    "Rōmānus, Rōmānī",
    "rōmānus, rōmāna, rōmānum",
    "Rōmānus (m), Rōmāna (f), Rōmānum"
  ],
  "also": [
    "et",
    "quoque",
    "etiam",
    "item",
    "itidem"
  ],
  "too": [
    "quoque",
    "nimis",
    "nimis or nimium",
    "nimis or nimium (adv.)"
  ],
  "what": [
    "quid",
    "quis? quae? quid",
    "quī, quae, quod",
    "quī quae quod",
    "Quid dicis",
    "quī quae quod (…?)"
  ],
  "anything": [
    "quid",
    "quidquam",
    "quis, quid, after sī, nīsī, num, nē"
  ],
  "province": [
    "prōvincia -ae f",
    "pāgus, pāgī"
  ],
  "first": [
    "prīmus -a -um",
    "prīmum",
    "prior, prior, prius",
    "prius",
    "prīmus, -a, -um",
    "prīmōris, prīmōre",
    "primum non nocere",
    "prīmus, prīma, prīmum",
    "prīncipālis, prīncipāle"
  ],
  "task": [
    "pēnsum -ī n",
    "officium -ī n",
    "opus, operis"
  ],
  "few": [
    "paucī -ae -a",
    "paucī, paucae, pauca",
    "paucī (m), paucae (f), pauca",
    "nōnnūllus, nōnnūlla, nōnnūllum"
  ],
  "a few": [
    "paucī -ae -a",
    "paucī, paucae, pauca",
    "paucī (m), paucae (f), pauca",
    "nōnnūllus, nōnnūlla, nōnnūllum"
  ],
  "little": [
    "parvus -a -um",
    "paulum",
    "parvulus -a -um",
    "paulō post",
    "paulō (+ comp)",
    "paullum, paulli",
    "perbrevis, perbreve",
    "parvus, parva, parvum",
    "paulus, paula, paulum",
    "minūtus, minūta, minūtum",
    "aliquantulus, aliquantula, aliquantulum"
  ],
  "small": [
    "parvus -a -um",
    "exiguus -a -um",
    "Parvus",
    "brevis, breve",
    "exīlis, exīle",
    "parvus, parva, parvum",
    "paulus, paula, paulum",
    "minūtus, minūta, minūtum",
    "aliquantulus, aliquantula, aliquantulum"
  ],
  "town": [
    "oppidum -ī n",
    "patria -ae f",
    "mūnicipium, mūnicipiī or mūnicipī"
  ],
  "ocean": [
    "ōceanus -ī m"
  ],
  "number": [
    "numerus -ī m",
    "numerus, numerī",
    "numerus, numerī (m)"
  ],
  "if": [
    "-ne",
    "num",
    "sī"
  ],
  "whether": [
    "-ne",
    "num",
    "utrum...an"
  ],
  "not": [
    "nōn",
    "neque / nec",
    "nōnne",
    "nē",
    "nē (adv. and conj. with subjunct. of command and purpose)"
  ],
  "many": [
    "multī -ae -a",
    "plūrēs",
    "multa, multae",
    "multus, multa, multum"
  ],
  "a great many": [
    "multī -ae -a",
    "plūrimī -ae -a sup"
  ],
  "thousand": [
    "mīlle"
  ],
  "big": [
    "magnus -a -um"
  ],
  "large": [
    "magnus -a -um",
    "Magnus",
    "magna, magnae",
    "capāx, capācis",
    "grandis, grande",
    "amplus, ampla, amplum",
    "magnus, magna, magnum"
  ],
  "great": [
    "magnus -a -um",
    "Optime",
    "magna, magnae",
    "grandis, grande",
    "magnus, magna, magnum"
  ],
  "letter": [
    "littera -ae f",
    "epistula -ae f",
    "Epistula",
    "epistola, epistolae",
    "litterae, litterārum (f)"
  ],
  "latin": [
    "latīnus -a -um",
    "latīnus, latīna, latīnum"
  ],
  "island": [
    "īnsula -ae f",
    "īnsula, īnsulae",
    "īnsula, īnsulae (f)"
  ],
  "in": [
    "in prp +abl",
    "in",
    "in (+abl.)"
  ],
  "on": [
    "in prp +abl",
    "super",
    "in",
    "secus",
    "ACTIVATUM",
    "in (+abl.)",
    "super (+abl)",
    "super (+acc)",
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "at": [
    "in prp +abl",
    "ad prp +acc",
    "adiaceō, adiacēre, adiacuī"
  ],
  "command": [
    "imperium -ī n",
    "imperō, imperāre, imperāvī, imperātus",
    "Impera",
    "iussus, iussūs",
    "ducātus, ducātūs",
    "imperium, imperiī",
    "imperātum, imperātī",
    "imperium, imperiī (n)",
    "praeceptum, praeceptī",
    "iubeō, iubēre, iussī, iussum",
    "mandō, mandāre, mandāvī, mandātum",
    "imperō, imperāre, imperāvī, imperātum + dat"
  ],
  "empire": [
    "imperium -ī n"
  ],
  "grammar": [
    "grammatica -ae f"
  ],
  "greek": [
    "graecus -a -um",
    "Graecus, Graecī (m)",
    "graecus, graeca, graecum"
  ],
  "river": [
    "fluvius -ī m",
    "flūmen -inis n",
    "amnis -is",
    "amnis -is (m)",
    "flūmen, flūminis"
  ],
  "example": [
    "exemplum -ī n",
    "exemplar, exemplāris",
    "praeiūdicium, praeiūdiciī"
  ],
  "model": [
    "exemplum -ī n",
    "exemplar, exemplāris"
  ],
  "and": [
    "et",
    "-que",
    "atque/ac",
    "ac",
    "et...et"
  ],
  "to be": [
    "sum, esse, fuī, futūrum"
  ],
  "chapter": [
    "capitulum -ī n",
    "caput, capitis"
  ],
  "who": [
    "quis? quae? quid",
    "quī, quae",
    "Quem",
    "murcus, murcī",
    "quī, quae, quod"
  ],
  "man": [
    "vir -ī m",
    "vir, virī",
    "ad hominem",
    "homō, hominis",
    "vir, virī (m)",
    "homō, hominis (m)"
  ],
  "husband": [
    "vir -ī m",
    "marītus -ī m"
  ],
  "your": [
    "tuus -a -um",
    "vester -tra -trum",
    "Tuus",
    "tua, tuae",
    "tuus, tua, tuum",
    "vester, vestra, vestrum"
  ],
  "yours": [
    "tuus -a -um",
    "vester -tra -trum"
  ],
  "title": [
    "titulus -ī m"
  ],
  "slave": [
    "servus -ī m",
    "Slave",
    "servīlis, servīle",
    "mancipium, mancipiī",
    "servus, servī and serva, servae",
    "serviō, servīre, servīvī, servītus",
    "servus, servī (m) and serva, servae (f)"
  ],
  "servant": [
    "servus -ī m",
    "ancilla -ae f",
    "minister -trī m",
    "cacus, cacī"
  ],
  "how many": [
    "quot",
    "quot (indēcl)"
  ],
  "as": [
    "quot",
    "tam",
    "quam",
    "quantus -a -um",
    "ut",
    "cum",
    "quandō",
    "as, assis",
    "sīcut",
    "quoniam",
    "tamquam",
    "velut",
    "quasi",
    "quantum -ī n",
    "quamdiū",
    "prō",
    "sicut",
    "sīcutī",
    "perinde",
    "tanquam",
    "qualiter",
    "tam...quam",
    "quot (indēcl)",
    "prō (prep. + abl.)",
    "ut (conj. + indic.)"
  ],
  "boy": [
    "puer -erī m",
    "puer, puerī",
    "puer, puerī (m)"
  ],
  "girl": [
    "puella -ae f",
    "puella, puellae",
    "puella, puellae (f)"
  ],
  "father": [
    "pater -tris m",
    "pāpa, pāpae",
    "pater, patris",
    "sator, satōris",
    "pater, patris (m)",
    "genitor, genitōris",
    "sator, satōris (m.)",
    "generō, generāre, generāvī, generātum"
  ],
  "page": [
    "pāgina -ae f"
  ],
  "new": [
    "novus -a -um",
    "recēns, recentis",
    "novus, nova, novum",
    "inaudītus, inaudīta, inaudītum"
  ],
  "my": [
    "meus -a -um",
    "mī",
    "mea, meae",
    "meus, mea, meum"
  ],
  "mine": [
    "Fodi",
    "meus -a -um",
    "mī",
    "effodiō, effodere, effōdī, effossum"
  ],
  "mother": [
    "māter -tris f",
    "māter, mātris",
    "māter, mātris (f)",
    "genetrīx, genetrīcis"
  ],
  "children": [
    "līberī -ōrum",
    "puer, puerī",
    "puer, puerī (m)",
    "orbitās, orbitātis",
    "līberī -ōrum (m pl)"
  ],
  "book": [
    "liber -brī m",
    "Liber",
    "liber, librī",
    "cōdex, cōdicis",
    "liber, librī (m)",
    "volūmen, volūminis"
  ],
  "son": [
    "fīlius -ī m",
    "nātus, natī",
    "fīlius, fīliī",
    "fīlius, fīliī (m)"
  ],
  "daughter": [
    "fīlia -ae f",
    "nāta, nātae",
    "fīlia, fīliae",
    "nāta, nātae (f.)",
    "fīlia, fīliae (f)"
  ],
  "female": [
    "fēmina -ae f",
    "Feminina",
    "muliebris, muliebre"
  ],
  "woman": [
    "fēmina -ae f",
    "mulier -eris f",
    "fēmina, fēminae",
    "mulier, mulieris",
    "fēmina, fēminae (f)",
    "mulier, mulieris (f.)"
  ],
  "domestic staff": [
    "familia -ae f"
  ],
  "family": [
    "familia -ae f",
    "familia, familiae",
    "prōgeniēs, prōgeniēī",
    "familia, familiae (f)"
  ],
  "see": [
    "ecce",
    "videō, vidēre, vīdī, vīsus",
    "ēn",
    "ēia",
    "videō, vidēre, vīdī, vīsum",
    "spectō, spectāre, spectāvī, spectātum",
    "vīsitō, vīsitāre, vīsitāvī, vīsitātum",
    "cōnspicor, cōnspicārī, cōnspicātus sum"
  ],
  "look": [
    "ecce",
    "aspiciō, aspicere, aspexī, aspectum",
    "ēn",
    "Agedum",
    "aspectus, aspectūs"
  ],
  "here is": [
    "ecce",
    "ēn"
  ],
  "two": [
    "duo -ae -o",
    "bīnī -ae -a",
    "duo, duae, duo"
  ],
  "master": [
    "dominus -ī m",
    "erus -ī m",
    "rabbī",
    "Dominus",
    "magister, magistrī",
    "magister, magistrī (m)",
    "dominor, dominārī, dominātus sum",
    "dominus, dominī and domina, dominae",
    "dominus, dominī (m.) and domina, dominae (f.)"
  ],
  "mistress": [
    "domina -ae f",
    "nympha, nymphae",
    "paelex, paelicis",
    "magistra, magistrae",
    "magistra, magistrae (f)",
    "dominus, dominī and domina, dominae",
    "dominus, dominī (m.) and domina, dominae (f.)"
  ],
  "the other": [
    "cēterī -ae -a",
    "alter -era -erum",
    "alter, altera, alterum",
    "cēterī, cēterae, cētera",
    "cēterī, cēterae, cētera (pl.)"
  ],
  "the rest": [
    "cēterī -ae -a",
    "cēterī, cēterae, cētera",
    "cēterī, cēterae, cētera (pl.)"
  ],
  "a hundred": [
    "centum"
  ],
  "old": [
    "antīquus -a -um",
    "vetus -eris",
    "senex, gen. senis",
    "vetus, gen. veteris",
    "annōsus, annōsa, annōsum",
    "vetustus, vetusta, vetustum",
    "obsolētus, obsolēta, obsolētum",
    "veterānus, veterāna, veterānum",
    "senex, gen. senis (adj and noun)",
    "grandaevus, grandaeva, grandaevum"
  ],
  "ancient": [
    "antīquus -a -um",
    "antīqua, antīquae",
    "pervetus, perveteris",
    "cascus, casca, cascum",
    "antīquus, antīqua, antīquum",
    "vetustus, vetusta, vetustum",
    "longaevus, longaeva, longaevum",
    "prīstinus, prīstina, prīstinum"
  ],
  "former": [
    "antīquus -a -um",
    "prior, prior, prius",
    "ille, illa, illud",
    "prīscus, prīsca, prīscum",
    "prīstinus, prīstina, prīstinum"
  ],
  "female slave": [
    "ancilla -ae f"
  ],
  "ten": [
    "decem",
    "dēnī -ae -a"
  ],
  "twenty": [
    "vīgintī"
  ],
  "call": [
    "vocō, vocāre, vocāvī, vocātus",
    "dīcō, dīcere, dīxī, dictum",
    "nōminō, nōmināre, nōmināvī, nōminātus",
    "appellō, appellāre, appellāvī, appellātus",
    "dīco, dīcere, dīxī, dictum",
    "vocō, vocāre, vocāvī, vocā",
    "calō, calāre, calāvī, calātum",
    "advocō, advocāre, advocāvī, advocātum",
    "appellō, appellāre, appellāvī, appellātum"
  ],
  "invite": [
    "vocō, vocāre, vocāvī, vocātus",
    "invītō, invītāre, invītāvī, invītātum"
  ],
  "seem": [
    "videō, vidēre, vīdī, vīsus"
  ],
  "verb": [
    "verbum -ī n"
  ],
  "beat": [
    "verberō, verberāre, verberāvī, verberātus",
    "modus -ī m",
    "palpitō, palpitāre, palpitāvī, palpitātus",
    "caedō, caedere, cecidī, caesum",
    "pulsus, pulsūs",
    "occīdō, occīdere, occīdī, occīsum",
    "plangō, plangere, plānxī, plānctum",
    "afflīgō, afflīgere, afflīxī, afflīctum",
    "contundō, contundere, contudī, contūsum"
  ],
  "flog": [
    "verberō, verberāre, verberāvī, verberātus",
    "flagellō, flagellāre, flagellāvī, flagellātum"
  ],
  "come": [
    "veniō, venīre, vēnī, ventum",
    "congruō, congruere, congruī",
    "prōgredior, prōgredī, prōgressus sum",
    "accēdō, accēdere, accessī, accessum (ad + cēdo)"
  ],
  "scene": [
    "scaena -ae f"
  ],
  "stage": [
    "scaena -ae f"
  ],
  "laugh": [
    "rīdeō, rīdēre, rīsī, rīsum",
    "rīsus -ūs m",
    "cachinnus -ī m"
  ],
  "make fun of": [
    "rīdeō, rīdēre, rīsī, rīsum",
    "dērīdeō, dērīdēre, dērīsī, dērīsus"
  ],
  "to answer": [
    "rēspondeō, rēspondēre, rēspondī, rēsponsum",
    "respondeō, respondēre, respondī, respōnsum"
  ],
  "because": [
    "quia",
    "quod",
    "quod (conj)"
  ],
  "which": [
    "quī, quae",
    "quī, quae, quod",
    "quī quae quod",
    "uter, utra, utrum",
    "quī quae quod (…?)",
    "quotus, quota, quotum"
  ],
  "he who": [
    "quī, quae"
  ],
  "strike": [
    "pulsō, pulsāre, pulsāvī, pulsātus",
    "percutiō, percutere, percussī, percussus",
    "laedō, laedere, laesī, laesus",
    "Feri",
    "Ictus",
    "obtingō, obtingere, obtigī",
    "pellō, pellere, pepulī, pulsum",
    "plangō, plangere, plānxī, plānctum",
    "attingō, attingere, attigī, attāctum",
    "impingō, impingere, impēgī, impāctum",
    "afflīgō, afflīgere, afflīxī, afflīctum",
    "afflīctō, afflīctāre, afflīctāvī, afflīctātum"
  ],
  "hit": [
    "pulsō, pulsāre, pulsāvī, pulsātus",
    "percutiō, percutere, percussī, percussus",
    "Ictus",
    "offendō, offendere, offendī, offēnsum"
  ],
  "knock": [
    "pulsō, pulsāre, pulsāvī, pulsātus",
    "offēnsus, offēnsūs"
  ],
  "good": [
    "probus -a -um",
    "bonus -a -um",
    "bonum -ī n",
    "bonus, bona, bonum",
    "benignus, benigna, benignum"
  ],
  "honest": [
    "probus -a -um",
    "rectus"
  ],
  "proper": [
    "probus -a -um",
    "proprius -a -um",
    "rectus",
    "habilis, habile",
    "vērus, vēra, vērum",
    "decōrus, decōra, decōrum",
    "cōnsentāneus, cōnsentānea, cōnsentāneum"
  ],
  "cry": [
    "plōrō, plōrāre, plōrāvī, plōrātum",
    "fleō, flēre, flēvī, flētum",
    "vōciferātiō, vōciferātiōnis"
  ],
  "mask": [
    "persōna -ae f"
  ],
  "role": [
    "persōna -ae f"
  ],
  "character": [
    "persōna -ae f",
    "ingenium -ī",
    "mōrēs, mōrum",
    "habitus, habitūs",
    "mōrēs, mōrum (m)",
    "virtūs, virtūtis",
    "cōnstitūtiō, cōnstitūtiōnis"
  ],
  "person": [
    "persōna -ae f",
    "homō -inis m"
  ],
  "o": [
    "ō"
  ],
  "and not": [
    "neque / nec",
    "nēve",
    "neu",
    "neque, nec"
  ],
  "but not": [
    "neque / nec",
    "neque/nec vērō"
  ],
  "nor": [
    "neque / nec",
    "neque ….neque",
    "nēve",
    "neu",
    "neque, nec",
    "neque...neque or nec...nec"
  ],
  "mommy": [
    "mamma -ae f"
  ],
  "glad": [
    "laetus -a -um",
    "serēnus, serēna, serēnum",
    "gaudeō, gaudēre, gāvīsus sum",
    "laetor, laetārī, laetātus sum"
  ],
  "happy": [
    "laetus -a -um",
    "beātus -a -um",
    "fēlīx, gen. fēlīcis",
    "beātus, beāta, beātum",
    "fortūnātus, fortūnāta, fortūnātum"
  ],
  "angry": [
    "īrātus -a -um",
    "īrātus, īrāta, īrātum",
    "īrācundus, īrācunda, īrācundum",
    "stomachor, stomachārī, stomachātus sum",
    "suscēnseō, suscēnsēre, suscēnsuī, suscēnsum"
  ],
  "ask": [
    "interrogō, interrogāre, interrogāvī, interrogātus",
    "quaerō, quaerere, quaesīvī, quaesītum",
    "postulō, postulāre, postulāvī, postulātus",
    "rogitō, rogitāre, rogitāvī, rogitātum",
    "rogō, rogāre, rogāvī, rogātum",
    "requīrō, requīrere, requīsīvī, requīsītum",
    "scīscitor, scīscitārī, scīscitātus sum",
    "perquīrō, perquīrere, perquīsīvī, perquīsītum"
  ],
  "question": [
    "interrogō, interrogāre, interrogāvī, interrogātus",
    "scīscitor, scīscitārī, scīscitātus sum"
  ],
  "bad": [
    "improbus -a -um",
    "malus -a -um",
    "nēquam",
    "malus, mala, malum"
  ],
  "wicked": [
    "improbus -a -um",
    "malus -a -um",
    "scelestus -a -um",
    "malitia, malitiae",
    "malus, mala, malum",
    "malignus, maligna, malignum",
    "maleficus, malefica, maleficum",
    "malitiōsus, malitiōsa, malitiōsum"
  ],
  "flagrant": [
    "improbus -a -um"
  ],
  "morally unsound": [
    "improbus -a -um"
  ],
  "disloyal": [
    "improbus -a -um",
    "impius, impia, impium"
  ],
  "shameless": [
    "improbus -a -um",
    "procāx, procācis",
    "impudēns, impudentis",
    "impudīcus, impudīca, impudīcum"
  ],
  "now": [
    "nunc",
    "iam",
    "age -ite",
    "age -ite (+inf)"
  ],
  "already": [
    "iam"
  ],
  "here": [
    "Hic",
    "hīc",
    "Habe",
    "Hic habes",
    "hīc (adv.)",
    "Hic id habes"
  ],
  "i": [
    "ego, mē, mihi/mī",
    "ego, meī"
  ],
  "me": [
    "ego, mē, mihi/mī",
    "mē",
    "Ego"
  ],
  "myself": [
    "ego, mē, mihi/mī",
    "mē",
    "ipse, ipsa, ipsum"
  ],
  "sleep": [
    "dormiō, dormīre, dormīvī, dormītus",
    "somnus -ī m",
    "Dormi",
    "somnus, somnī",
    "sopor, sopōris",
    "somnus, somnī (m)",
    "dormiō, dormīre, dormīvī, dormītum"
  ],
  "why": [
    "Cur",
    "cūr",
    "quamobrem",
    "quid",
    "quārē",
    "quāpropter",
    "quapropter",
    "quid? (adv)"
  ],
  "sing": [
    "cantō, cantāre, cantāvī, cantātus",
    "canō, canere, cecinī, cantus",
    "concinō, concinere, concinuī"
  ],
  "hear": [
    "audiō, audīre, audīvī, audītus",
    "exaudiō, exaudīre, exaudīvī, exaudītus",
    "audiō, audīre, audīvī, audītum",
    "inaudiō, inaudīre, inaudīvī, inauditum",
    "auscultō, auscultāre, auscultāvī, auscultātum"
  ],
  "listen": [
    "audiō, audīre, audīvī, audītus",
    "auscultō, auscultāre, auscultāvī, auscultātum"
  ],
  "empty": [
    "vacuus -a -um",
    "exhauriō, exhaurīre, exhausī, exhaustus",
    "Vacuus",
    "inānis, ināne",
    "vānus, vāna, vānum",
    "cassus, cassa, cassum",
    "vastus, vasta, vastum",
    "vacō, vacāre, vacāvī, vacātum"
  ],
  "so much": [
    "tantum",
    "tantus, tanta, tantum"
  ],
  "so far": [
    "tantum",
    "adhūc"
  ],
  "hardly": [
    "tantum",
    "vix"
  ],
  "be silent": [
    "taceō, tacēre, tacuī, tacitus",
    "sileō, silēre, siluī, -----",
    "reticeō, reticēre, reticuī",
    "taceō, tacēre, tacuī, tacitum"
  ],
  "his": [
    "suus -a -um",
    "eius"
  ],
  "her": [
    "suus -a -um",
    "eius"
  ],
  "their": [
    "suus -a -um"
  ],
  "take": [
    "sūmō, sūmere, sūmpsī, sūmptum",
    "capiō, capere, cēpī, captum",
    "Cape",
    "Sume",
    "accipiō, accipere, accēpī, acceptum",
    "excipiō, excipere, excēpī, exceptum",
    "exprōmō, exprōmere, exprōmpsī, exprōmptum"
  ],
  "seven": [
    "septem"
  ],
  "hello": [
    "Salve",
    "salvē -ēte",
    "salvē",
    "salvēte",
    "salvē, salvēte"
  ],
  "good morning": [
    "salvē -ēte"
  ],
  "greet": [
    "salūtō, salūtāre, salūtāvī, salūtātus",
    "salūtem dīcere +dat",
    "salvēre iubēre"
  ],
  "purse": [
    "sacculus -ī m"
  ],
  "again": [
    "rūrsus",
    "iterum",
    "dēnuō",
    "vicissim",
    "re- or red-",
    "iterum (adv.)",
    "re- or (before words beginning with d) red-"
  ],
  "that which": [
    "quī, quae, quod"
  ],
  "five": [
    "quīnque",
    "quīnī -ae -a"
  ],
  "four": [
    "quattuor",
    "quaternī -ae -a"
  ],
  "put": [
    "pōnō, pōnere, posuī, positum",
    "impōnō, impōnere, imposuī, impositum",
    "adiciō, adicere, adiēcī, adiectus",
    "locō, locāre, locāvī, locātum",
    "subdō, subdere, subdidī, subditum",
    "subpōnō, subpōnere, subposuī, subpositum",
    "suppōnō, suppōnere, supposuī, suppositum",
    "interpōnō, interpōnere, interposuī, interpositum"
  ],
  "lay down": [
    "pōnō, pōnere, posuī, positum"
  ],
  "place": [
    "pōnō, pōnere, posuī, positum",
    "impōnō, impōnere, imposuī, impositum",
    "appōnō, appōnere, apposuī, appositus",
    "locus -ī m",
    "Pone",
    "Depone",
    "Ponendum",
    "locus, locī",
    "locus, locī (m)",
    "subdō, subdere, subdidī, subditum",
    "dēpōnō, dēpōnere, dēposuī, dēpositum",
    "dēstituō, dēstituere, dēstituī, dēstitūtum"
  ],
  "money": [
    "pecūnia -ae f",
    "aes, aeris",
    "pecūnia, pecūniae",
    "pecūnia, pecūniae (f)",
    "nummārius, nummāria, nummārium",
    "pecūniārius, pecūniāria, pecūniārium"
  ],
  "obey": [
    "pāreō, pārēre, pāruī, pāritum",
    "oboediō, oboedīre, oboedīvī, oboedītus",
    "pāreō pārēre, pāruī + dat",
    "obtemperō, obtemperāre, obtemperāvī, obtemperātum"
  ],
  "eight": [
    "octō"
  ],
  "coin": [
    "nummus -ī m"
  ],
  "sesterce": [
    "nummus -ī m",
    "sēstertius -ī m"
  ],
  "count": [
    "numerō, numerāre, numerāvī, numerātus",
    "recēnseō, recēnsēre, recēnsuī, recēnsum",
    "dīnumerō, dīnumerāre, dīnumerāvī, dīnumerātum"
  ],
  "no": [
    "Minime",
    "nūllus -a -um",
    "immō",
    "nūllus, nūlla, nūllum"
  ],
  "nine": [
    "novem"
  ],
  "table": [
    "mēnsa -ae f",
    "Mensa",
    "mēnsa, mēnsae",
    "mēnsa, mēnsae (f)"
  ],
  "he": [
    "is ea id",
    "ille -a -ud",
    "is",
    "is, ea, id",
    "ille, illa, illud"
  ],
  "she": [
    "is ea id",
    "ea",
    "is, ea, id",
    "ille, illa, illud"
  ],
  "it": [
    "is ea id",
    "is, ea, id",
    "ille, illa, illud"
  ],
  "have": [
    "habeō, habēre, habuī, habitum",
    "obtineō, obtinēre, obtinuī, obtentum"
  ],
  "hold": [
    "habeō, habēre, habuī, habitum",
    "teneō, tenēre, tenuī, tentum",
    "Cape"
  ],
  "consider": [
    "habeō, habēre, habuī, habitum",
    "dūcō, dūcere, dūxī, ductum",
    "meditor, meditārī, meditātus sum",
    "pēnsō, pēnsāre, pēnsāvī, pēnsātum",
    "cōgitō, cōgitāre, cōgitāvī, cōgitātum",
    "respiciō, respicere, respexī, respectum",
    "cōnsultō, cōnsultāre, cōnsultāvī, cōnsultātum",
    "dēlīberō, dēlīberāre, dēlīberāvī, dēlīberātum",
    "cōnsīderō, cōnsīderāre, cōnsīderāvī, cōnsīderātum",
    "contemplō, contemplāre, contemplāvī, contemplātum"
  ],
  "go away": [
    "discēdō, discēdere, discessī, discessum",
    "abeō, abīre, abiī, abitūrus",
    "abeō, abīre, abiī, abitum",
    "dīgredior, dīgredī, dīgressus sum"
  ],
  "depart": [
    "discēdō, discēdere, discessī, discessum",
    "proficīscor, proficīscī, profectus sum",
    "abeō, abīre, abiī, abitum",
    "absistō, absistere, abstitī",
    "ēgredior, ēgredī, ēgressus sum",
    "dīgredior, dīgredī, dīgressus sum",
    "excēdō, excēdere, excessī, excessum",
    "abscēdō, abscēdere, abscessī, abscessum"
  ],
  "stick": [
    "baculum -ī n",
    "tālea, tāleae",
    "haereō, haerēre, haesī, haesum",
    "inhaereō, inhaerēre, inhaesī, inhaesum"
  ],
  "be present": [
    "adsum, adesse, affuī, affutūrus"
  ],
  "stand by": [
    "adsum, adesse, affuī, affutūrus"
  ],
  "accuse": [
    "accūsō, accūsāre, accūsāvī, accūsātus",
    "incūsō, incūsāre, incūsāvī, incūsātum",
    "crīminō, crīmināre, crīmināvī, crīminātum",
    "īnsimulō, īnsimulāre, īnsimulāvī, īnsimulātum"
  ],
  "absent": [
    "absum, abesse, āfuisse, āfutūrus",
    "absēns -entis",
    "absēns, gen. absentis"
  ],
  "away": [
    "absum, abesse, āfuisse, āfutūrus",
    "absēns, gen. absentis"
  ],
  "distant": [
    "absum, abesse, āfuisse, āfutūrus",
    "longinquus, -a, -um",
    "distō, distāre, distitī"
  ],
  "order": [
    "imperō, imperāre, imperāvī, imperātus",
    "iubeō, iubēre, iussī, iussum",
    "ōrdō -inis m",
    "iussus, iussūs",
    "dēcrētum, dēcrētī",
    "imperātum, imperātī",
    "mandō, mandāre, mandāvī, mandātum"
  ],
  "rule": [
    "imperō, imperāre, imperāvī, imperātus",
    "regō, regere, rēxī, rēctum",
    "rēgnō, rēgnāre, rēgnāvī, rēgnātum",
    "praeceptum, praeceptī",
    "dominātiō, dominātiōnis",
    "principātus, principātūs"
  ],
  "country house": [
    "vīlla -ae f"
  ],
  "villa": [
    "vīlla -ae f"
  ],
  "alone": [
    "sōlus -a -um",
    "sōlum",
    "ūnus, ūna, ūnum",
    "sōlus, sōla, sōlum",
    "singulāris, singulāre"
  ],
  "lonely": [
    "sōlus -a -um"
  ],
  "without": [
    "sine",
    "absque",
    "sine (+abl)",
    "sine (prp +abl)",
    "expers, expertis",
    "careō, carēre, caruī, caritum",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "rose": [
    "rosa -ae f",
    "rosa, rosae",
    "rosa, rosae (f)"
  ],
  "beautiful": [
    "pulcher -chra -chrum",
    "fōrmōsus -a -um",
    "pulcher, pulchra, pulchrum",
    "candidus, candida, candidum",
    "speciōsus, speciōsa, speciōsum"
  ],
  "fine": [
    "Bene",
    "pulcher -chra -chrum",
    "Fine",
    "subtīlis, subtīle",
    "ēlegāns, ēlegantis",
    "facētus, facēta, facētum",
    "pulcher, pulchra, pulchrum"
  ],
  "peristyle": [
    "peristylum -ī n"
  ],
  "the part of a building enclosing a courtyard with columns on the inside": [
    "peristylum -ī n"
  ],
  "door": [
    "Ianuam",
    "ōstium -ī n",
    "iānua -ae f",
    "foris -is f",
    "iānua, iānuae",
    "iānua, iānuae (f.)"
  ],
  "entrance": [
    "ōstium -ī n",
    "porta, portae",
    "porta, portae (f)",
    "introitus, introitūs"
  ],
  "nose": [
    "nāsus -ī m",
    "nāsus, nāsī",
    "nāris, nāris",
    "nāsus, nāsī (m.)"
  ],
  "lily": [
    "līlium -ī n"
  ],
  "water basin": [
    "impluvium -ī n"
  ],
  "garden": [
    "Hortus",
    "hortus -ī m",
    "hortēnsis, hortēnse"
  ],
  "dwell": [
    "habitō, habitāre, habitāvī, habitātus",
    "versor, versārī, versātus sum"
  ],
  "live": [
    "habitō, habitāre, habitāvī, habitātus",
    "vīvo, vīvere, vīxī, vīctus",
    "versor, versārī, versātus sum",
    "LIVE",
    "Vivus",
    "vīvō, vīvere, vīxī, vīctum"
  ],
  "ugly": [
    "foedus -a -um",
    "turpis -e",
    "turpis, turpe",
    "dēfōrmis, dēfōrme"
  ],
  "hideous": [
    "foedus -a -um"
  ],
  "window": [
    "fenestra -ae f",
    "fenestra, fenestrae",
    "fenestra, fenestrae (f)"
  ],
  "out of": [
    "ex/ē",
    "ex / ē",
    "ex, ē",
    "ex, ē (+ abl)",
    "ex/ē (prp +abl)"
  ],
  "from": [
    "ex/ē",
    "ā/ab/abs prp +abl",
    "ex / ē",
    "dē prp +abl",
    "abs v. ā/ab/abs",
    "dē",
    "ab, ā",
    "ex, ē",
    "dē (+abl.)",
    "ex, ē (+ abl)",
    "ex/ē (prp +abl)",
    "ab, ā (prep + abl)"
  ],
  "of": [
    "ex/ē",
    "ā/ab/abs prp +abl",
    "dē prp +abl",
    "de",
    "ex, ē",
    "ex, ē (+ abl)",
    "ex/ē (prp +abl)",
    "līberālis, līberāle",
    "sīdereus, sīderea, sīdereum",
    "ferrārius, ferrāria, ferrārium",
    "marmoreus, marmorea, marmoreum"
  ],
  "since": [
    "ex/ē",
    "ā/ab/abs prp +abl",
    "postquam",
    "quoniam",
    "sīquidem",
    "cum",
    "abhinc",
    "quandōquidem",
    "ex/ē (prp +abl)",
    "quoniam (conj.)",
    "cum (+ subjunct. or + indic.)"
  ],
  "even": [
    "etiam",
    "et",
    "usque",
    "quidem",
    "usque (adv.)",
    "aequus, aequa, aequum",
    "quidem (postpositive adv.)",
    "campester, campestris, campestre"
  ],
  "yet": [
    "etiam",
    "tamen",
    "atquī",
    "etiamnum"
  ],
  "delight": [
    "dēlectō, dēlectāre, dēlectāvī, dēlectātus",
    "iuvō, iuvāre, iūvī, iūtum",
    "gaudium -ī n",
    "dēliciae -ārum f pl",
    "frūctus frūctūs",
    "iūcunditās, iūcunditātis",
    "dēlectātiō, dēlectātiōnis",
    "oblectātiō, oblectātiōnis",
    "dēlectātiō, dēlectātiōnis (f)",
    "dēlectō, dēlectāre, dēlectāvī, dēlectātum",
    "oblectō, oblectāre, oblectāvī, oblectātum"
  ],
  "please": [
    "dēlectō, dēlectāre, dēlectāvī, dēlectātus",
    "placeō, placēre, placuī, placitus",
    "Quaeso",
    "obsecrō",
    "amābō tē",
    "iuvō, iuvāre, iūvī, iūtum",
    "placeō, placēre, placuī, placitum + dat",
    "dēlectō, dēlectāre, dēlectāvī, dēlectātum",
    "oblectō, oblectāre, oblectāvī, oblectātum"
  ],
  "with": [
    "cum prp +abl",
    "cum",
    "cum (prep. + abl)"
  ],
  "bedroom": [
    "Cubiculum",
    "cubiculum -ī n",
    "Suppelices cubiculi"
  ],
  "gather": [
    "carpō, carpere, carpsī, carptum",
    "relegō, relegere, relēgī, relēctum"
  ],
  "pick": [
    "carpō, carpere, carpsī, carptum"
  ],
  "crop": [
    "carpō, carpere, carpsī, carptum",
    "frūx, frūgis"
  ],
  "main room": [
    "ātrium -ī n"
  ],
  "hall": [
    "ātrium -ī n"
  ],
  "water": [
    "aqua -ae f",
    "aqua, aquae",
    "unda, undae",
    "latex, laticis",
    "aqua, aquae (f)"
  ],
  "love": [
    "amō, amāre, amāvī, amātus",
    "amor -ōris m",
    "dīligō, dīligere, dīlēxī, dīlēctum",
    "amor, amōris",
    "charitās, charitātis",
    "dīlēctiō, dīlēctiōnis",
    "amō, amāre, amāvī, amātum"
  ],
  "drive": [
    "agō, agere, ēgī, āctus",
    "pellō, pellere, pepulī, pulsum",
    "urgeō, urgēre, ursī",
    "agō, agere, ēgī, āctum",
    "agitō, agitāre, agitāvī, agitātum",
    "prōiciō, prōicere, prōiēcī, prōiectum",
    "prōpellō, prōpellere, prōpulī, prōpulsum",
    "praetervehor, praetervehī, praetervectus sum"
  ],
  "do": [
    "agō, agere, ēgī, āctus",
    "faciō, facere, fēcī, factum",
    "gerō, gerere, gessī, gestum",
    "agō, agere, ēgī, āctum",
    "facessō, facessere, facessī, facessītum",
    "committō, committere, commīsī, commissum"
  ],
  "perform": [
    "agō, agere, ēgī, āctus",
    "fungor, fungī, fūnctus sum",
    "gerō, gerere, gessī, gestum",
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum",
    "facessō, facessere, facessī, facessītum",
    "committō, committere, commīsī, commissum"
  ],
  "come on! well": [
    "age -ite",
    "age -ite (+inf)"
  ],
  "ninety": [
    "nōnāgintā"
  ],
  "road": [
    "via -ae f",
    "via, viae",
    "via, viae (f)",
    "iter, itineris"
  ],
  "way": [
    "via -ae f",
    "modus -ī m",
    "iter itineris n",
    "via, viae",
    "modus, modī",
    "via, viae (f)",
    "modus, modī (m)"
  ],
  "street": [
    "via -ae f",
    "via, viae",
    "vīcus, vīcī",
    "via, viae (f)",
    "platēa, platēae"
  ],
  "bear": [
    "vehō, vehere, vēxī, vectus",
    "ferō, ferre, tulī, lātum",
    "gerō, gerere, gessī, gestum",
    "patior, patī, passus sum",
    "Bear",
    "Ursa",
    "ēnītor, ēnītī, ēnixus",
    "nītor, nītī, nīxus sum",
    "gīgnō gīgnere genuī genitum",
    "efferō, efferre, extulī, ēlātum",
    "gestō, gestāre, gestāvī, gestātum",
    "vectō, vectāre, vectāvī, vectātum",
    "aggerō, aggerere, aggessī, aggestum",
    "tolerō, tolerāre, tolerāvī, tolerātum",
    "asportō, asportāre, asportāvī, asportātum"
  ],
  "carry": [
    "vehō, vehere, vēxī, vectus",
    "portō, portāre, portāvī, portātus",
    "ferō, ferre, tulī, lātum",
    "gerō, gerere, gessī, gestum",
    "advehō, advehere, advēxī, advectus",
    "ēgerō, ēgerere, ēgessī, ēgestum",
    "gestō, gestāre, gestāvī, gestātum",
    "vectō, vectāre, vectāvī, vectātum",
    "dēvehō, dēvehere, dēvexi, dēvectum",
    "aggerō, aggerere, aggessī, aggestum",
    "ingerō, ingerere, ingessī, ingestum",
    "convehō, convehere, convexī, convectum",
    "perdūcō, perdūcere, perdūxī, perductum",
    "comportō, comportāre, comportāvī, comportātum",
    "supportō, supportāre, supportāvī, supportātum",
    "circumferō, circumferre, circumtulī, circumlātum"
  ],
  "convey": [
    "vehō, vehere, vēxī, vectus",
    "advehō, advehere, advēxī, advectus",
    "dēferō, dēferre, dētulī, dēlātum",
    "vectō, vectāre, vectāvī, vectātum",
    "dēvehō, dēvehere, dēvexi, dēvectum",
    "perdūcō, perdūcere, perdūxī, perductum"
  ],
  "pass": [
    "vehō, vehere, vēxī, vectus",
    "trānseō, trānsīre, trānsiī, trānsitum",
    "agō, agere, ēgī, āctum",
    "praetereō, praeterīre, praeteriī, praeteritus",
    "trānscendō, trānscendere, trānscendī, trānscēnsum"
  ],
  "ride": [
    "vehō, vehere, vēxī, vectus",
    "Conscende",
    "equitō, equitāre, equitāvī, equitātum"
  ],
  "sail": [
    "vehō, vehere, vēxī, vectus",
    "vēlum -ī n",
    "nāvigō, nāvigāre, nāvigāvī, nāvigātus",
    "nāvigō, nāvigāre, nāvigāvī, nāvigātum"
  ],
  "from where": [
    "unde"
  ],
  "shoulder": [
    "umerus -ī m",
    "armus, armī"
  ],
  "fear": [
    "timeō, timēre, timuī, --",
    "timor -ōris m",
    "metus -ūs m",
    "metuō, metuere, metuī, ------",
    "vereor, verērī, veritus sum",
    "Fear",
    "Timor",
    "metus, metūs",
    "timor, timōris",
    "metus, metūs (m)",
    "timeō, timēre, timuī",
    "metuō, metuere, metuī"
  ],
  "be afraid": [
    "timeō, timēre, timuī, --",
    "timeō, timēre, timuī",
    "trepidō, trepidāre, trepidāvī, trepidātum"
  ],
  "so": [
    "tam",
    "ergō",
    "igitur",
    "ita",
    "sīc",
    "adeō",
    "Ergo",
    "tam...quam",
    "tam (adv. with adjs. and advs.)",
    "sīc (adv. most commonly used with verbs)",
    "ita (adv. used with adj., verbs, and advs.)"
  ],
  "sack": [
    "saccus -ī m",
    "Saccus",
    "dēpopulor, dēpopulārī, dēpopulāvī",
    "dēpopulō, dēpopulāre, dēpopulāvī, dēpopulātum"
  ],
  "how": [
    "Quomodo",
    "Quommodo",
    "quam",
    "quōmodo",
    "quam (adv)",
    "quemadmodum"
  ],
  "than": [
    "quam",
    "ac",
    "quam (conj. after comparatives)"
  ],
  "near": [
    "apud",
    "prope prp +acc, adv",
    "sub",
    "propinquus -a -um",
    "sub (+abl)",
    "vīcīnus, vīcīna, vīcīnum",
    "appositus, apposita, appositum"
  ],
  "nearly": [
    "prope prp +acc, adv",
    "paene",
    "fermē",
    "propius",
    "propemodum"
  ],
  "far": [
    "procul",
    "longē"
  ],
  "far away": [
    "procul"
  ],
  "behind": [
    "post",
    "retrō",
    "post (+ acc)"
  ],
  "after": [
    "post",
    "postquam",
    "posteāquam",
    "post (+ acc)"
  ],
  "later": [
    "post",
    "posterior -ius comp",
    "posteā"
  ],
  "gate": [
    "porta -ae f",
    "Porta",
    "porta, portae",
    "porta, portae (f)",
    "claustrum, claustrī"
  ],
  "through": [
    "per prp +acc",
    "per",
    "per (prep + acc)"
  ],
  "by": [
    "apud",
    "ad prp +acc",
    "per prp +acc",
    "ā/ab/abs prp +abl",
    "per",
    "ab, ā",
    "secus",
    "per (prep + acc)",
    "ab, ā (prep + abl)"
  ],
  "during": [
    "inter",
    "per prp +acc"
  ],
  "pace": [
    "passus -ūs m"
  ],
  "for": [
    "nam",
    "enim",
    "prō",
    "prae",
    "namque",
    "etenim",
    "pro",
    "prō (prep. + abl.)",
    "enim (postpositive conj)"
  ],
  "wall": [
    "mūrus -ī m",
    "vāllum, vāllī",
    "ōbex, ōbicis",
    "mūrālis, mūrāle"
  ],
  "evil": [
    "malus -a -um",
    "malum -ī n",
    "malus, mala, malum"
  ],
  "long": [
    "longus -a -um",
    "diū",
    "diū (adv)",
    "longus, longa, longum",
    "longinquus, longinqua, longinquum"
  ],
  "litter": [
    "lectīca -ae f",
    "ferētrum, ferētrī",
    "strāmentum, strāmentī"
  ],
  "sedan": [
    "lectīca -ae f"
  ],
  "therefore": [
    "itaque",
    "ergō",
    "igitur",
    "proptereā",
    "ergo",
    "quārē",
    "itaque (adv)"
  ],
  "go": [
    "eō, īre, īvī, ītus",
    "cēdō, cēdere, cessī, cessum",
    "sē cōnferre",
    "eō, īre, iī, itum",
    "vādō, vādere, vāsī",
    "congredior, congredī, congressus sum",
    "prōgredior, prōgredī, prōgressus sum",
    "circumeō, circumīre, circumiī, circumitum"
  ],
  "enter": [
    "intrō, intrāre, intrāvī, intrātus",
    "ineō, inīre, iniī, initum",
    "ingredior, ingredī, ingressus sum",
    "invādō, invādere, invāsī, invāsum",
    "introeō, introīre, introiī, introitum",
    "penētrō, penētrāre, penētrāvī, penētrātum"
  ],
  "between": [
    "inter",
    "inter (prep + acc)",
    "intersum, interesse, interfuī, interfutūrus"
  ],
  "among": [
    "apud",
    "inter",
    "apud (prep. + acc)",
    "inter (prep + acc)"
  ],
  "enemy": [
    "inimīcus -ī m",
    "hostis -is m",
    "hostēs, hostium (m)",
    "adversārius, adversāriī"
  ],
  "tired": [
    "fessus -a -um",
    "lassus, lassa, lassum"
  ],
  "weary": [
    "fessus -a -um",
    "fatīgō, fatīgāre, fatīgāvī, fatīgātus",
    "lassus, lassa, lassum",
    "lassō, lassāre, lassāvī, lassātum",
    "fatīgō, fatīgāre, fatīgāvī, fatīgātum"
  ],
  "horse": [
    "Equus",
    "equus -ī m",
    "equus, equī",
    "equus, equī (m)",
    "armentum, armentī"
  ],
  "twelve": [
    "duodecim"
  ],
  "round": [
    "circum",
    "rotundus, rotunda, rotundum",
    "ambeō, ambīre, ambiī, ambitum",
    "ambiō, ambīre, ambīvī, ambītum"
  ],
  "however": [
    "autem",
    "vērō",
    "cēterum",
    "utcumque",
    "quamlibet",
    "vērumtamen",
    "vēruntamen",
    "vērō (adv.)"
  ],
  "moreover": [
    "autem"
  ],
  "beside": [
    "apud",
    "iūxtā",
    "secus"
  ],
  "at the house of": [
    "apud",
    "apud (prep. + acc)"
  ],
  "in front of": [
    "ante prp +acc, adv",
    "prō",
    "ante",
    "prae",
    "ante (prep + acc)",
    "prae (prep + abl.)",
    "prō (prep. + abl.)"
  ],
  "before": [
    "ante prp +acc, adv",
    "antequam",
    "anteā",
    "prius",
    "prae",
    "priusquam",
    "prō",
    "ante",
    "ante (prep + acc)",
    "prae (prep + abl.)",
    "prō (prep. + abl.)",
    "obdūcō, obdūcere, obdūxī, obductum"
  ],
  "friend": [
    "amīcus -ī m",
    "amīca, amīcae",
    "amīcus, amīcī",
    "amīca, amīcae (f)",
    "amīcus, amīcī (m)"
  ],
  "girlfriend": [
    "amīca -ae f"
  ],
  "walk": [
    "ambulō, ambulāre, ambulāvī, ambulātus",
    "incessus, incessūs",
    "vādō, vādere, vāsī",
    "ambulātiō, ambulātiōnis",
    "gradior, gradī, gressus sum",
    "ambulō, ambulāre, ambulāvī, ambulātum"
  ],
  "to": [
    "ad prp +acc",
    "in",
    "ut",
    "ad",
    "in (+acc)",
    "ad (+ acc)",
    "ut (+ coni)",
    "ut (conj. + subjunct.)"
  ],
  "toward": [
    "ad prp +acc",
    "ergā",
    "adversus",
    "versus: ad…versus",
    "in",
    "in (+ acc)",
    "ergā (prep. + acc.)"
  ],
  "till": [
    "ad prp +acc",
    "dum",
    "exarō, exarāre, exarāvī, exarātum"
  ],
  "turn": [
    "vertō, vertere, vertī, versum",
    "convertō, convertere, conversī, conversum",
    "flectō, flectere, flexī, flexum",
    "Verte",
    "vergō, vergere",
    "rotō, rotāre, rotāvī, rotātum",
    "āvocō, āvocāre, āvocāvī, āvocātum",
    "volūtō, volūtāre, volūtāvī, volūtātum",
    "retorqueō, retorquēre, retorsī, retortum",
    "inclīnō, inclīnāre, inclīnāvī, inclīnātum",
    "contorqueō, contorquēre, contorsī, contortum"
  ],
  "change": [
    "vertō, vertere, vertī, versum",
    "mūtō, mūtāre, mūtāvī, mūtātus",
    "īnflectō, īnflectere, īnflēxī, īnflexus",
    "alterātiō, alterātiōnis",
    "immūtātiō, immūtātiōnis",
    "commūtātiō, commūtātiōnis",
    "permūtātiō, permūtātiōnis",
    "reformatio, reformationis",
    "vicissitūdō, vicissitūdinis",
    "mūtō, mūtāre, mūtāvī, mūtātum",
    "variō, variāre, variāvī, variātum",
    "alterō, alterāre, alterāvī, alterātum",
    "immūtō, immūtāre, immūtāvī, immūtātum"
  ],
  "wipe": [
    "tergeō, tergēre, tersī, tersus"
  ],
  "keep": [
    "teneō, tenēre, tenuī, tentum",
    "servō, servāre, servāvī, servātum",
    "obtineō, obtinēre, obtinuī, obtentum",
    "percipiō, percipere, percēpī, perceptum",
    "contineō, continēre, continuī, contentum",
    "prohibeō, prohibēre, prohibuī, prohibitum"
  ],
  "mirror": [
    "speculum -ī n",
    "Speculum",
    "speculum, speculī",
    "speculum, speculī (n.)"
  ],
  "just": [
    "sōlum",
    "modo",
    "iūstus -a -um",
    "dumtaxat",
    "pius, pia, pium",
    "aequus, aequa, aequum",
    "iūstus, iūsta, iūstum"
  ],
  "merely": [
    "sōlum",
    "tantummodo"
  ],
  "barely": [
    "sōlum",
    "vixdum"
  ],
  "himself": [
    "sē sibi",
    "ipse -a -um",
    "sēsē",
    "suī",
    "ipse",
    "ipse, ipsa, ipsum"
  ],
  "full": [
    "plēnus -a -um",
    "plēnus, plēna, plēnum",
    "satur, satura, saturum",
    "plēnus -a -um (+gen/abl)"
  ],
  "pear": [
    "pirum -ī n"
  ],
  "door-keeper": [
    "ōstiārius -ī m"
  ],
  "porter": [
    "ōstiārius -ī m"
  ],
  "kiss": [
    "ōsculum -ī n",
    "ōsculor, ōsculārī, ōsculātus sum",
    "bāsium -ī n",
    "bāsium, bāsiī",
    "ōsculum, ōsculī",
    "bāsium, bāsiī (n)",
    "ōsculum, ōsculī (n.)"
  ],
  "eye": [
    "oculus -ī m",
    "ocellus -ī m",
    "Oculus",
    "oculus, oculī",
    "oculus, oculī (m)"
  ],
  "neither": [
    "neque ….neque",
    "neuter -tra -trum",
    "neuter, neutra, neutrum",
    "neque...neque or nec...nec"
  ],
  "sea": [
    "mare -is n",
    "mare, maris",
    "pelagus, pelagī",
    "aequor, aequoris",
    "maritimus -a -um"
  ],
  "apple": [
    "mālum -ī n"
  ],
  "shed tears": [
    "lacrimō, lacrimāre, lacrimāvī, lacrimātus"
  ],
  "weep": [
    "lacrimō, lacrimāre, lacrimāvī, lacrimātus",
    "fleō, flēre, flēvī, flētum"
  ],
  "tear": [
    "lacrima -ae f",
    "scindō, scindere, scidī, scissum",
    "Tear",
    "lacrima, lacrimae",
    "accipitrō, accipitrāre",
    "lacrima, lacrimae (f.)",
    "ēvellō, ēvellere, ēvellī, ēvulsum",
    "abrumpō, abrumpere, abrūpī, abruptum"
  ],
  "be": [
    "inesse",
    "versor, versārī, versātus sum",
    "sum, esse, fuī, futūrum",
    "exsistō, exsistere, exstitī"
  ],
  "into": [
    "in",
    "sub",
    "in (+acc)",
    "in (+ acc)",
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "against": [
    "in",
    "contrā prp +acc",
    "adversus",
    "adversus -a -um",
    "contrā",
    "in (+acc)",
    "in (+ acc)",
    "contrā (prep + acc)",
    "obsum, obesse, obfuī, obfutūrus",
    "īnferō, īnferre, intulī, illātum",
    "abdīcō, abdīcere, abdīxī, abdictum"
  ],
  "on the contrary": [
    "immō"
  ],
  "there": [
    "illīc",
    "ibi",
    "illūc",
    "eō",
    "illāc"
  ],
  "this": [
    "hic haec hoc",
    "iste -a -ud",
    "ad hoc",
    "is, ea, id",
    "hic, haec, hoc"
  ],
  "wait": [
    "Exspecta",
    "exspectō, exspectāre, exspectāvī, exspectātum",
    "opperior, opperīrī, opperītus sum",
    "Expecta"
  ],
  "expect": [
    "exspectō, exspectāre, exspectāvī, exspectātum"
  ],
  "go out": [
    "exeō, exīre, exiī, exitum",
    "ēgredior, ēgredī, ēgressus sum",
    "excēdō, excēdere, excessī, excessum"
  ],
  "both…and": [
    "et…et"
  ],
  "give": [
    "dō, dare, dedī, datum",
    "reddō, reddere, reddidī, redditum",
    "dōnō, dōnāre, dōnāvī, dōnātus",
    "Da",
    "sufficiō, sufficere, suffēcī, suffectum",
    "perhibeō, perhibēre, perhibuī, perhibitum",
    "subministrō, subministrāre, subministrāvī, subministrātum"
  ],
  "run": [
    "currō, currere, cucurrī, cursum",
    "Currere"
  ],
  "shut": [
    "claudō, claudere, clausī, clausum",
    "clausus -a -um",
    "arceō, arcēre, arcuī, arcitum"
  ],
  "close": [
    "claudō, claudere, clausī, clausum",
    "propinquus -a -um",
    "artus",
    "Claude",
    "clausula, clausulae",
    "arctus, arcta, arctum",
    "dēnsus, dēnsa, dēnsum",
    "spissus, spissa, spissum"
  ],
  "open": [
    "aperiō, aperīre, aperuī, apertum",
    "apertus -a -um",
    "Aperi",
    "patēns",
    "pateō, patēre, patuī",
    "patulus, patula, patulum",
    "pateō, patēre, patuī, ----",
    "vacō, vacāre, vacāvī, vacātum",
    "reclūdō, reclūdere, reclūsī, reclūsum",
    "relaxō, relaxāre, relaxāvī, relaxātum",
    "reserō, reserāre, reserāvī, reserātum",
    "patefaciō, patefacere, patefēcī, patefactum"
  ],
  "disclose": [
    "aperiō, aperīre, aperuī, apertum",
    "patefaciō, patefacere, patefēcī, patefactum"
  ],
  "arrive": [
    "adveniō, advenīre, advēnī, adventum",
    "dēveniō, dēvenīre, dēvēnī, dēventum"
  ],
  "go to": [
    "adeō, adīre, adīvī, adītus",
    "adeō, adīre, adiī, aditum",
    "adeō , adīre, adīvī, adītus"
  ],
  "approach": [
    "adeō, adīre, adīvī, adītus",
    "accēdō, accēdere, accessī, accessum,",
    "appropinquō, appropinquāre, appropinquāvī, appropinquātus",
    "obitus, obitūs",
    "accessus, accessūs",
    "accessiō, accessiōnis",
    "adeō, adīre, adiī, aditum",
    "adeō , adīre, adīvī, adītus",
    "aggredior, aggredī, aggressus sum",
    "accēdō, accēdere, accessī, accessum",
    "congredior, congredī, congressus sum",
    "adventō, adventāre, adventāvī, adventātum",
    "proximō, proximāre, proximāvī, proximātum",
    "accēdō, accēdere, accessī, accessum (ad + cēdo)"
  ],
  "sell": [
    "vēndō, vēndere, vēndidī, vēnditum"
  ],
  "so big": [
    "tantus -a -um"
  ],
  "so great": [
    "tantus -a -um",
    "tantus, tanta, tantum"
  ],
  "shopkeeper": [
    "tabernārius -ī m",
    "īnstitor, īnstitōris"
  ],
  "shop": [
    "taberna -ae f"
  ],
  "stall": [
    "taberna -ae f",
    "stabulum -ī n"
  ],
  "enough": [
    "satis",
    "sufficienter"
  ],
  "rather": [
    "satis",
    "potius"
  ],
  "fourth": [
    "quārtus -a -um",
    "quārta pars",
    "quārtus",
    "quārtānus, quārtāna, quārtānum"
  ],
  "how large": [
    "quantus -a -um",
    "quantus, quanta, quantum"
  ],
  "price": [
    "pretium -ī n"
  ],
  "value": [
    "pretium -ī n",
    "exīstimō, exīstimāre, exīstimāvī, exīstimātum",
    "aestimō, aestimāre, aestimāvī, aestimātus",
    "Pretium"
  ],
  "wealthy": [
    "pecūniōsus -a -um",
    "dīves, dīvitis",
    "dīves, gen. dīvitis or dītis",
    "opulentus, opulenta, opulentum"
  ],
  "show": [
    "ostendō, ostendere, ostendī, ostentus",
    "mōnstrō, mōnstrāre, mōnstrāvī, mōnstrātus",
    "dēmōnstrō, dēmōnstrāre, dēmōnstrāvī, dēmōnstrātum",
    "Demonstra",
    "spectāculum, spectāculī",
    "arguō, arguere, arguī, argūtum",
    "offerō, offerre, obtulī, oblātum",
    "exhibeō, exhibēre, exhibuī, exhibitum",
    "indicō, indicāre, indicāvī, indicātum",
    "ostendō, ostendere, ostendī, ostentum",
    "revēlō, revēlāre, revēlāvī, revēlātum",
    "ostentō, ostentāre, ostentāvī, ostentātum",
    "praestō, praestāre, praestitī, praestitum"
  ],
  "equip": [
    "ōrnō, ōrnāre, ōrnāvī, ōrnātus",
    "armō, armāre, armāvī, armātus",
    "ōrnō, ōrnāre, ōrnāvī, ōrnātum",
    "adōrnō, adōrnāre, adōrnāvī, adōrnātum",
    "apparō, apparāre, apparāvī, apparātum",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus",
    "subōrnō, subōrnāre, subornāvī, subornātum",
    "īnstituō, īnstituere, īnstituī, īnstitūtum"
  ],
  "adorn": [
    "ōrnō, ōrnāre, ōrnāvī, ōrnātus",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātum",
    "ōrnō, ōrnāre, ōrnāvī, ōrnātum",
    "decorō, decorāre, decorāvī, decorātum",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus",
    "subōrnō, subōrnāre, subornāvī, subornātum"
  ],
  "ornament": [
    "ōrnāmentum -ī n",
    "decus, decoris"
  ],
  "jewel": [
    "gemma -ae f",
    "ōrnāmentum -ī n"
  ],
  "eighty": [
    "octōgintā"
  ],
  "too much": [
    "nimis",
    "nimium",
    "nimis or nimium",
    "nimis or nimium (adv.)"
  ],
  "point out": [
    "mōnstrō, mōnstrāre, mōnstrāvī, mōnstrātus",
    "dēmōnstrō, dēmōnstrāre, dēmōnstrāvī, dēmōnstrātum",
    "indicō, indicāre, indicāvī, indicātum",
    "portendō, portendere, portendī, portentum"
  ],
  "mid": [
    "medius -a -um",
    "Mid"
  ],
  "middle": [
    "medium -ī n",
    "medius -a -um",
    "medius, media, medium"
  ],
  "center": [
    "medium -ī n",
    "centrum, centrī"
  ],
  "pearl": [
    "margarīta -ae f",
    "Margarita"
  ],
  "string": [
    "līnea -ae f"
  ],
  "line": [
    "līnea -ae f",
    "versus -ūs m",
    "fūnis, fūnis",
    "rudēns, rudentis",
    "līneāmentum, līneāmentī"
  ],
  "that": [
    "ille -a -ud",
    "quod",
    "iste -a -ud",
    "nē",
    "ut",
    "is, ea, id",
    "ut (+ coni)",
    "quī, quae, quod",
    "ille, illa, illud",
    "iste, ista, istud",
    "ut (conj. + subjunct.)"
  ],
  "the one": [
    "ille -a -ud"
  ],
  "set with a jewel": [
    "gemmātus -a -um"
  ],
  "precious stone": [
    "gemma -ae f"
  ],
  "buy": [
    "emō, emere, ēmī, ēmptum"
  ],
  "finger": [
    "digitus -ī m",
    "dactylus, -ī",
    "digitus, digitī",
    "dactylus, -ī (m)",
    "digitus, digitī (m.)"
  ],
  "fit": [
    "conveniō, convenīre, convēnī, conventus",
    "idōneus, idōnea, idōneum",
    "habilis, habile",
    "aptus, apta, aptum",
    "pābulāris, pābulāre",
    "artō, artāre, artāvī, artātum",
    "accommodātus, accommodāta, accommodātum",
    "cōnsentāneus, cōnsentānea, cōnsentāneum"
  ],
  "be fitting": [
    "conveniō, convenīre, convēnī, conventus",
    "decet: decēre"
  ],
  "come together": [
    "conveniō, convenīre, convēnī, conventus",
    "coeō, coīre, coiī, coitum",
    "competō, competere, competīvī, competītum"
  ],
  "meet": [
    "conveniō, convenīre, convēnī, conventus",
    "occurrō, occurrere, occurrī, occursum",
    "coeō, coīre, coiī, coitum",
    "obeō, obīre, obiī, obitum",
    "cōnfluō, cōnfluere, cōnfluxī",
    "obveniō, obvenīre, obvēnī, obventum",
    "offendō, offendere, offendī, offēnsum",
    "competō, competere, competīvī, competītum",
    "occurrō, occurrere, occurrī, occursum (+ dat)"
  ],
  "consist of": [
    "cōnstāre ex",
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus"
  ],
  "to stand at": [
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus"
  ],
  "stand firm": [
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus",
    "cōnsistō, cōnsistere, cōnstitī, cōnstitus",
    "restō, restāre, restitī, —",
    "restō, restāre, restitī, restātum"
  ],
  "be fixed": [
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus"
  ],
  "cost": [
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus",
    "sūmptus, sūmptūs",
    "impensa, impensae",
    "dispendium, dispendiī"
  ],
  "stop": [
    "cōnsistō, cōnsistere, cōnstitī, cōnstitus",
    "dēsinō, dēsinere, dēsiī, dēsitus",
    "cōnsīdō, cōnsīdere, cōnsēdī, cōnsessus",
    "commoror, commorārī, commorātus sum",
    "inhibeō, inhibēre, inhibuī, inhibitum",
    "intermittō, intermittere, intermisī, intermissum"
  ],
  "halt": [
    "cōnsistō, cōnsistere, cōnstitī, cōnstitus"
  ],
  "neck": [
    "collum -ī n",
    "cervīx, cervīcis",
    "guttur, gutturis"
  ],
  "shout": [
    "clāmō, clāmāre, clāmāvī, clāmātus",
    "clāmor, clāmōris",
    "vōciferor, vōciferārī, vōciferātus sum"
  ],
  "or": [
    "aut",
    "vel",
    "an",
    "sī-ve/seu",
    "-ve",
    "seu",
    "aut...aut",
    "utrum...an",
    "-ve (conj. suffixed to a word)"
  ],
  "look at": [
    "aspiciō, aspicere, aspexī, aspectum",
    "spectō, spectāre, spectāvī, spectātus",
    "intueor, intuērī, intuitus sum",
    "spectō, spectāre, spectāvī, spectātum",
    "īnspectō, īnspectāre, īnspectāvī, īnspectātum",
    "intrōspiciō, intrōspicere, intrōspexī, intrōspectum"
  ],
  "ring": [
    "ānulus -ī m",
    "personō, personāre, personuī, personātum"
  ],
  "another": [
    "alius -a -ud",
    "Alia",
    "alius, alia, aliud"
  ],
  "other": [
    "alius -a -ud",
    "cēterī -ae -a",
    "alter -era -erum",
    "alius, alia, aliud",
    "alter, altera, alterum",
    "cēterī, cēterae, cētera (pl.)"
  ],
  "receive": [
    "accipiō, accipere, accēpī, acceptus",
    "recipiō, recipere, recēpī, receptus",
    "accipiō, accipere, accēpī, acceptum",
    "excipiō, excipere, excēpī, exceptum",
    "recipiō, recipere, recēpī, receptum",
    "admittō, admittere, admīsī, admissum",
    "adsūmō, adsūmere, adsūmpsī, adsūmptum"
  ],
  "our": [
    "noster -tra -trum",
    "noster, nostra, nostrum"
  ],
  "ours": [
    "noster -tra -trum",
    "noster, nostra, nostrum"
  ],
  "footprint": [
    "vestīgium -ī n"
  ],
  "trace": [
    "vestīgium -ī n",
    "dūcō, dūcere, dūxī, ductum",
    "indāgō, indāgāre, indāgāvī, indāgātum"
  ],
  "valley": [
    "vallis -is",
    "cumba, cumbae"
  ],
  "like": [
    "ut",
    "tamquam",
    "quasi",
    "similis -e",
    "sicut",
    "pār, gen. paris",
    "parilis, parile",
    "similis, simile",
    "aequālis, aequāle",
    "aequābilis, aequābile",
    "pār, gen. paris (+ dat.)",
    "amō, amāre, amāvī, amātum",
    "similis, simile (+ gen. or dat.)"
  ],
  "ninety-nine": [
    "ūndēcentum"
  ],
  "shade": [
    "umbra -ae f",
    "Shade",
    "Umbra",
    "umbrāculum, umbrāculī"
  ],
  "shadow": [
    "umbra -ae f"
  ],
  "howl": [
    "ululō, ululāre, ululāvī, ululātus"
  ],
  "earth": [
    "terra -ae f",
    "humus -ī",
    "Terra",
    "humus, humī",
    "humus -ī (f!)",
    "terra, terrae",
    "tellus tellūris",
    "tellūs, tellūris",
    "terra, terrae (f)"
  ],
  "ground": [
    "terra -ae f",
    "humus -ī",
    "solum, solī",
    "humus, humī",
    "humus -ī (f!)",
    "terra, terrae",
    "tellūs, tellūris",
    "terra, terrae (f)",
    "crepīdō, crepīdinis"
  ],
  "country": [
    "terra -ae f",
    "rūs, rūris",
    "terra, terrae",
    "patria, patriae",
    "terra, terrae (f)",
    "patria, patriae (f)"
  ],
  "above": [
    "suprā prp +acc, adv",
    "super",
    "sursum",
    "super (+acc)",
    "superus, supera, superum",
    "superō, superāre, superāvī, superātum",
    "superō, superāre, superāvī, superātus"
  ],
  "under": [
    "sub",
    "sub (+abl)",
    "sub (+ abl/acc)",
    "subsum, subesse, subfuī, subfutūrus",
    "substituō, substituere, substituī, substitutum",
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "sun": [
    "sōl -is m",
    "Sol",
    "sōl, sōlis"
  ],
  "wood": [
    "silva -ae f",
    "lignum -ī n",
    "LIGNEUS",
    "lāmina, lāminae"
  ],
  "forest": [
    "silva -ae f",
    "nemus, nemoris"
  ],
  "brook": [
    "rīvus -ī m"
  ],
  "find": [
    "reperiō, reperīre, repperī, repertum",
    "inveniō, invenīre, invēnī, inventus",
    "inveniō, invenīre, invēnī, inventum"
  ],
  "leave": [
    "relinquō, relinquere, relīquī, relictum",
    "dēserō, dēserere, dēseruī, dēsertum",
    "Exi",
    "abeō, abīre, abiī, abitum",
    "dēscīscō, dēscīscere, dēscīvī, dēscītum"
  ],
  "leave behind": [
    "relinquō, relinquere, relīquī, relictum"
  ],
  "look for": [
    "quaerō, quaerere, quaesīvī, quaesītum",
    "exspectō, exspectāre, exspectāvī, exspectātum"
  ],
  "seek": [
    "petō, petere, petīvī, petītum",
    "quaerō, quaerere, quaesīvī, quaesītum",
    "requīrō, requīrere, requīsīvī, requīsītum",
    "Invenire",
    "conquaerō, conquaerere, conquaesīvī, conquaesītum"
  ],
  "make for": [
    "petō, petere, petīvī, petītum"
  ],
  "aim at": [
    "petō, petere, petīvī, petītum"
  ],
  "attack": [
    "petō, petere, petīvī, petītum",
    "impetus -ūs m",
    "oppugnō, oppugnāre, oppugnāvī, oppugnātus",
    "Oppugna",
    "adorior, adorīrī, adortus sum",
    "īnsequor, īnsequī, īnsecūtus sum",
    "attingō, attingere, attigī, attāctum",
    "impetō, impetere, impetīvī, impetītum",
    "impugnō, impugnāre, impugnāvī, impugnātum",
    "incursō, incursāre, incursāvī, incursātum",
    "lacessō, lacessere, lacessīvī, lacessītum",
    "oppugnō, oppugnāre, oppugnāvī, oppugnātum"
  ],
  "ask for": [
    "petō, petere, petīvī, petītum",
    "rogō, rogāre, rogāvī, rogātum",
    "requīrō, requīrere, requīsīvī, requīsītum"
  ],
  "request": [
    "petō, petere, petīvī, petītum",
    "Roga",
    "rogātus, rogātūs",
    "postulātiō, postulātiōnis",
    "efflāgitō, efflāgitāre, efflāgitāvī, efflāgitātum"
  ],
  "shepherd": [
    "pāstor -ōris m",
    "pastōrālis, pastōrāle"
  ],
  "bread": [
    "pānis -is m",
    "Panis"
  ],
  "loaf": [
    "pānis -is m"
  ],
  "sheep": [
    "ovis -is f",
    "pecuārius, pecuāria, pecuārium"
  ],
  "cloud": [
    "nūbēs -is f",
    "nūbēs, nūbis"
  ],
  "black": [
    "niger -gra -grum",
    "āter -tra -trum",
    "Black",
    "fuscus, fusca, fuscum"
  ],
  "mountain": [
    "mōns montis",
    "mōns, montis",
    "mōns montis (m)"
  ],
  "mode": [
    "modus -ī m",
    "modus, modī",
    "modus, modī (m)"
  ],
  "manner": [
    "modus -ī m",
    "mōs, mōris",
    "modus, modī",
    "modus, modī (m)",
    "ratiō, ratiōnis"
  ],
  "method": [
    "modus -ī m",
    "modus, modī",
    "modus, modī (m)",
    "ratiō, ratiōnis",
    "methodus, methodī"
  ],
  "rhythm": [
    "modus -ī m",
    "rhythmus, rhythmī"
  ],
  "measure": [
    "modus -ī m",
    "modus, modī",
    "metrum, metrī",
    "modus, modī (m)",
    "mēnsūra, mēnsūrae",
    "mēnsūrō, mēnsūrāre, mēnsūrāvī, mēnsūrātum"
  ],
  "size": [
    "modus -ī m",
    "quantitās -ātis f",
    "magnitūdō, magnitūdinis"
  ],
  "bound": [
    "modus -ī m",
    "modus, modī",
    "modus, modī (m)",
    "adiunctus, adiuncta, adiunctum",
    "dēfīniō, dēfīnīre, dēfīnīvī, dēfīnītum",
    "terminō, termināre, termināvī, terminātum"
  ],
  "limit": [
    "modus -ī m",
    "fīnis -is m",
    "mēta, mētae",
    "modus, modī",
    "fīnis, fīnis",
    "līmes, līmitis",
    "modus, modī (m)",
    "fīnis, fīnis (m)",
    "mēta, mētae (f.)",
    "terminus, terminī",
    "fīniō, fīnīre, fīnīvī, fīnītus",
    "dēfīniō, dēfīnīre, dēfīnīvī, dēfīnītum",
    "terminō, termināre, termināvī, terminātum"
  ],
  "wolf": [
    "lupus, lupī",
    "Wolf"
  ],
  "shine": [
    "lūceō, lūcēre, lūxī, -----",
    "niteō, nitēre, nituī",
    "fulgeō, fulgēre, fulsī",
    "relūceō, relūcēre, reluxī",
    "splendeō, splendēre, splenduī"
  ],
  "bark": [
    "lātrō, lātrāre, lātrāvī, lātrātus"
  ],
  "lie": [
    "iaceō, iacēre, iacuī, iacitum",
    "cubō, cubāre, cubuī, cubitum",
    "mentior, mentīrī, mentītus sum",
    "mendācium, mendāciī",
    "falsitās, falsitātis",
    "iaceō, iacēre, iacuī"
  ],
  "grass": [
    "herba -ae f",
    "grāmen, grāminis"
  ],
  "herb": [
    "herba -ae f",
    "arōma, arōmatis"
  ],
  "eat": [
    "ēdō, ēsse, ēdī, ēsum",
    "Comede",
    "vēscor, vēscī",
    "prandeō, prandēre, prandī, prānsum",
    "mandūcō, mandūcāre, mandūcāvī, mandūcātum"
  ],
  "wander": [
    "errō, errāre, errāvī, errātus",
    "vagō, vagāre, vagāvī",
    "vagor, vagārī, vagātus sum",
    "errō, errāre, errāvī, errātum",
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "stray": [
    "errō, errāre, errāvī, errātus",
    "aberrō, aberrāre, aberrāvī, -----"
  ],
  "while": [
    "dum"
  ],
  "as long as": [
    "dum",
    "dōnec",
    "quoad"
  ],
  "guide": [
    "dūcō, dūcere, dūxī, ductum",
    "regō, regere, rēxī, rēctum",
    "dux, ducis",
    "dux, ducis (m)",
    "rēctor, rēctōris",
    "dērigō, dērigere, dērexī, dērectum"
  ],
  "lead": [
    "dūcō, dūcere, dūxī, ductum",
    "plumbum, plumbī",
    "agō, agere, ēgī, āctum",
    "obdūcō, obdūcere, obdūxī, obductum",
    "condūcō, condūcere, condūxī, conductum",
    "perdūcō, perdūcere, perdūxī, perductum",
    "trādūcō, trādūcere, trādūxī, trāductum",
    "praedūcō, praedūcere, praedūxī, praeductum"
  ],
  "draw": [
    "dūcō, dūcere, dūxī, ductum",
    "hauriō, haurīre, hausī, haustum",
    "trahō, trahere, trāxī, tractum",
    "attrahō, attrahere, attraxī, attractum",
    "subdūcō, subdūcere, subdūxī, subductum",
    "distrahō, distrahere, distraxī, distractum",
    "pertrahō, pertrahere, pertraxī, pertractum",
    "praedūcō, praedūcere, praedūxī, praeductum"
  ],
  "tooth": [
    "dēns dentis m"
  ],
  "decline": [
    "dēclīnō, dēclīnāre, dēclīnāvī, dēclīnātus",
    "senium, seniī",
    "recūsō, recūsāre, recūsāvī, recūsātum"
  ],
  "inflect": [
    "dēclīnō, dēclīnāre, dēclīnāvī, dēclīnātus",
    "īnflectō, īnflectere, īnflēxī, īnflexus"
  ],
  "hill": [
    "collis -is m",
    "clīvus, clīvī"
  ],
  "shouting": [
    "clāmor, clāmōris"
  ],
  "food": [
    "cibus -ī m",
    "CIBUS",
    "ēsca, ēscae",
    "alimentum, alimentī"
  ],
  "dog": [
    "canis -is m/f",
    "Dog",
    "Canis Mortis"
  ],
  "plain": [
    "campus -ī m",
    "plānus -a -um",
    "aequor, aequoris",
    "ēvidēns, ēvidentis",
    "plānitiēs, plānitiēī",
    "austērus, austēra, austērum",
    "manifestus, manifesta, manifestum"
  ],
  "level ground": [
    "campus -ī m"
  ],
  "sky": [
    "caelum -ī n",
    "Caelum",
    "caelum, caelī",
    "caelus, caelī",
    "coelus, coelī",
    "aethra, aethrae",
    "aethēr, aetheris",
    "caelum, caelī (n)",
    "aetherius, aetheria, aetherium"
  ],
  "heaven": [
    "caelum -ī n",
    "caelum, caelī",
    "caelus, caelī",
    "coelus, coelī",
    "aethēr, aetheris",
    "caelum, caelī (n)"
  ],
  "drink": [
    "bibō, bibere, bibī, ------",
    "pōtō, pōtāre, pōtāvī, pōtātus",
    "pōtiō -ōnis f",
    "pōtus, pōtūs",
    "bibō, bibere, bibī"
  ],
  "bleat": [
    "bālō, bālāre, bālāvī, -----",
    "bālātus, bālātūs"
  ],
  "tree": [
    "arbor -oris f",
    "tīgnum, tīgnī",
    "arbor, arboris",
    "arboreus, arborea, arboreum"
  ],
  "white": [
    "albus -a -um",
    "candidus -a -um",
    "ALBUS",
    "White",
    "candidus, candida, candidum"
  ],
  "to run to": [
    "accurrō, accurrere, accurrī, accursus"
  ],
  "come running": [
    "accurrō, accurrere, accurrī, accursus"
  ],
  "come back": [
    "reveniō, revenīre, revēnī, reventum",
    "revertor, revertī, reversus sum"
  ],
  "bite": [
    "mordeō, mordēre, momordī, morsum"
  ],
  "voice": [
    "vōx vōcis f",
    "Vox",
    "vōx, vōcis",
    "vōx, vōcis (f.)"
  ],
  "fly": [
    "volō, volāre, volāvī, volātus",
    "musca, muscae"
  ],
  "living": [
    "vīvus -a -um",
    "vīctus, vīctūs",
    "animālis, animāle",
    "vīvus, vīva, vīvum"
  ],
  "alive": [
    "vīvus -a -um",
    "vīvus, vīva, vīvum",
    "vīvidus, vīvida, vīvidum",
    "vīvo, vīvere, vīxī, vīctus"
  ],
  "be alive": [
    "vīvo, vīvere, vīxī, vīctus"
  ],
  "want": [
    "volō, velle, voluī, ----",
    "aerumna, aerumnae",
    "egeō, egēre, eguī",
    "pēnūria, pēnūriae",
    "egestās, egestātis",
    "volō, velle, voluī",
    "indigentia, indigentiae",
    "indigeō, indigēre, indiguī",
    "careō, carēre, caruī, caritūrum",
    "egeō, egēre, eguī (+ abl. or gen.)",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "be willing": [
    "volō, velle, voluī, ----",
    "volō, velle, voluī"
  ],
  "thin": [
    "tenuis -e",
    "exīlis, exīle",
    "gracilis, gracile",
    "subtīlis, subtīle",
    "leptus, lepta, leptum",
    "vēscus, vēsca, vēscum",
    "attenuō, attenuāre, attenuāvī, attenuātum",
    "extenuō, extenuāre, extenuāvī, extenuātum"
  ],
  "support": [
    "sustineō, sustinēre, sustinuī, sustentus",
    "faveō, favēre, fāvī, fautūrus",
    "subsidium, subsidiī",
    "adiumentum, adiumentī",
    "adiūtōrium, adiūtōriī",
    "adminiculum, adminiculī",
    "alō, alere, aluī, altum",
    "firmāmentum, firmāmentī",
    "fulciō, fulcīre, fulsī, fultum",
    "subveniō, subvenīre, subvēnī, subventum",
    "stabiliō, stabilīre, stabilīvī, stabilītum",
    "sustentō, sustentāre, sustentāvī, sustentātum",
    "adminiculō, adminiculāre, adminiculāvī, adminiculātum"
  ],
  "sustain": [
    "sustineō, sustinēre, sustinuī, sustentus",
    "alō, alere, aluī, altum",
    "sustentō, sustentāre, sustentāvī, sustentātum"
  ],
  "endure": [
    "sustineō, sustinēre, sustinuī, sustentus",
    "perferō, perferre, pertulī, perlātus",
    "ferō, ferre, tulī, lātum",
    "patior, patī, passus sum",
    "perpetior, perpetī, perpessus sum",
    "tolerō, tolerāre, tolerāvī, tolerātum",
    "permaneō, permanēre, permansī, permansum"
  ],
  "breathe": [
    "spīrō, spīrāre, spīrāvī, spīrātus"
  ],
  "branch": [
    "rāmus -ī m"
  ],
  "bough": [
    "rāmus -ī m"
  ],
  "lung": [
    "pulmō -ōnis m"
  ],
  "young": [
    "pullus -ī m",
    "Iuvenis",
    "mustus, musta, mustum",
    "iuvencus, iuvenca, iuvencum"
  ],
  "chick": [
    "pullus -ī m"
  ],
  "be able": [
    "possum, posse, potuī",
    "queō, quīre, quiī / quīvī, quitum"
  ],
  "fish": [
    "piscis -is m"
  ],
  "ball": [
    "pila -ae f",
    "Pila",
    "sphaera, sphaerae"
  ],
  "hat": [
    "petasus -ī m",
    "Petasus"
  ],
  "foot": [
    "pēs pedis m",
    "pēs, pedis",
    "basis, basis"
  ],
  "terrified": [
    "perterritus -a -um",
    "pavidus, pavida, pavidum"
  ],
  "give birth to": [
    "pariō, parere, peperī, partum",
    "gignō, gignere, genuī, genitum",
    "pariō, parere, peperī, partum (paritum)"
  ],
  "lay": [
    "pariō, parere, peperī, partum",
    "Recumbe",
    "dēpōnō, dēpōnere, dēposuī, dēpositum",
    "pariō, parere, peperī, partum (paritum)",
    "accommodō, accommodāre, accommodāvī, accommodātum"
  ],
  "egg": [
    "ōvum -ī n"
  ],
  "hide": [
    "occultō, occultāre, occultāvī, occultātus",
    "lateō, latēre, latuī, -----",
    "RENO",
    "corium, coriī",
    "pellis, pellis",
    "abdō, abdere, abdidī, abditum",
    "cēlō, cēlāre, cēlāvī, cēlātum",
    "dēlitēscō, dēlitēscere, dēlituī",
    "condō, condere, condidī, conditum",
    "occulō, occulere, occuluī, occultum",
    "abscondō, abscondere, abscondī, absconditum",
    "dissimulō, dissimulāre, dissimulāvī, dissimulātum"
  ],
  "messenger": [
    "nūntius -ī m"
  ],
  "message": [
    "nūntius -ī m"
  ],
  "nest": [
    "nīdus -ī m",
    "Nidus"
  ],
  "no one": [
    "nēmō, nēminem, nūllīus, nēminī, nūllō / nūllā",
    "nēmō, nūllīus, nēminī, nēminem, nūllō or nūllā",
    "nēmō, nūllīus, nēminī, nēminem, nūllō or nūllā (m. or f.)"
  ],
  "nobody": [
    "nēmō, nēminem, nūllīus, nēminī, nūllō / nūllā",
    "Mimime",
    "nēmō, nūllīus, nēminī, nēminem, nūllō or nūllā",
    "nēmō, nūllīus, nēminī, nēminem, nūllō or nūllā (m. or f.)"
  ],
  "it is necessary": [
    "necesse est"
  ],
  "swim": [
    "natō, natāre, natāvī, natātus"
  ],
  "move": [
    "moveō, movēre, mōvī, mōtum",
    "migrātiō, migrātiōnis",
    "āmoveō, āmovēre, āmōvī, āmōtum",
    "admoveō, admovēre, admōvī, admōtum"
  ],
  "stir": [
    "moveō, movēre, mōvī, mōtum",
    "afficiō, afficere, affēcī, affectum",
    "sollicitō, sollicitāre, sollicitāvī, sollicitātum"
  ],
  "dead": [
    "mortuus -a -um",
    "Mortuus",
    "exanimis, exanime",
    "Īnferī, Īnferōrum",
    "mortuus, mortua, mortuum"
  ],
  "merchant": [
    "mercātor -ōris m",
    "Mercator",
    "negōtiātor, negōtiātōris"
  ],
  "play": [
    "lūdō, lūdere, lūsī, lūsum",
    "canō, canere, cecinī, cantus",
    "lūdus -ī",
    "fābula -ae f",
    "lūsus, lūsūs",
    "fābula, fābulae",
    "fābella, fābellae",
    "fābula, fābulae (f)",
    "hospitō, hospitāre, hospitāvī, hospitātum"
  ],
  "lion": [
    "leō -ōnis m"
  ],
  "bed": [
    "lectus -ī m",
    "lectulus -ī m",
    "Lectum",
    "Lectus",
    "cubīle, cubīlis"
  ],
  "couch": [
    "lectus -ī m"
  ],
  "human being": [
    "homō -inis m",
    "homō, hominis",
    "homō, hominis (m)"
  ],
  "leaf": [
    "folium -ī n",
    "lāmina, lāminae"
  ],
  "< facere": [
    "-ficiō -ficere -fēcisse -fectum"
  ],
  "wild": [
    "ferus -a -um",
    "agrestis, agreste"
  ],
  "wild animal": [
    "fera -ae f"
  ],
  "make": [
    "faciō, facere, fēcī, factum",
    "efficiō, efficere, effēcī, effectum",
    "cōnficiō, cōnficere, cōnfēcī, cōnfectus"
  ],
  "cause": [
    "faciō, facere, fēcī, factum",
    "causa -ae f",
    "causa, causae",
    "causa, causae (f)",
    "importō, importāre, importāvī, importātum"
  ],
  "god": [
    "deus -ī m, pl deī/diī/dī",
    "deus, deī",
    "deus, deī (m)",
    "dīvus, dīva, dīvum",
    "caelicola, caelicolae"
  ],
  "when": [
    "quandō",
    "cum",
    "ut",
    "ubi",
    "ut (conj. + indic.)",
    "ut (conj. + subjunct.)",
    "cum (+ subjunct. or + indic.)"
  ],
  "thick": [
    "crassus -a -um",
    "laena, laenae",
    "arctus, arcta, arctum",
    "crēber, crēbra, crēbrum",
    "spissus, spissa, spissum"
  ],
  "fat": [
    "crassus -a -um",
    "adeps, adipis",
    "pinguis, pingue",
    "opīmus, opīma, opīmum"
  ],
  "tail": [
    "cauda -ae f",
    "pēnis, pēnis"
  ],
  "catch": [
    "Cape",
    "capiō, capere, cēpī, captum",
    "dēcipiō, dēcipere, dēcēpī, dēceptum"
  ],
  "capture": [
    "capiō, capere, cēpī, captum",
    "excipiō, excipere, excēpī, exceptum",
    "opprimō, opprimere, oppressī, oppressum"
  ],
  "crow": [
    "canō, canere, cecinī, cantus",
    "cornīx, cornīcis"
  ],
  "fall": [
    "cadō, cadere, cecidī, cāsum",
    "lābor, lābī, lāpsus sum",
    "occidō, occidere, occidī, occāsum",
    "cāsus -ūs m",
    "Autumnus",
    "lābēs, lābis",
    "cāsurus, cāsurūs",
    "cadō, cadere, cecidī, cāsūrum",
    "dēlābor, dēlābī, dēlapsus sum",
    "lāpsō, lāpsāre, lāpsāvī, lāpsātum",
    "succumbō, succumbere, succubuī, succubitum"
  ],
  "beast": [
    "bēstia -ae f",
    "bēlua, bēluae"
  ],
  "animal": [
    "bēstia -ae f",
    "animal -ālis n",
    "Animal",
    "animal, animālis"
  ],
  "bird": [
    "avis -is f",
    "volucris, volucris"
  ],
  "dare": [
    "audeō, audēre, ausus sum"
  ],
  "venture": [
    "audeō, audēre, ausus sum"
  ],
  "ass": [
    "asinus -ī m"
  ],
  "donkey": [
    "asinus -ī m"
  ],
  "climb": [
    "ascendō, ascendere, ascendī, ascēnsus",
    "scandō, scandere, scandī, scānsum",
    "succēdō, succēdere, successī, successum",
    "trānscendō, trānscendere, trānscendī, trānscēnsum"
  ],
  "go up": [
    "ascendō, ascendere, ascendī, ascēnsus"
  ],
  "mount": [
    "ascendō, ascendere, ascendī, ascēnsus",
    "cōnscendō, cōnscendere, cōnscendī, cōnscēnsus",
    "nītor, nītī, nīxus sum",
    "scandō, scandere, scandī, scānsum"
  ],
  "eagle": [
    "aquila -ae f"
  ],
  "living being": [
    "animal -ālis n"
  ],
  "breath": [
    "anima -ae f",
    "anima, animae",
    "hālitus, hālitūs",
    "anima, animae (f.)",
    "spīritus, spīritūs",
    "spīritus, spīritūs (m)"
  ],
  "life": [
    "anima -ae f",
    "vīta -ae f",
    "Vita",
    "vīta, vītae",
    "aetās, aetātis",
    "caput, capitis",
    "vīta, vītae (f)",
    "spīritus, spīritūs"
  ],
  "soul": [
    "anima -ae f",
    "animus -ī m",
    "Animus",
    "anima, animae",
    "animus, animī",
    "animus, animī (m)",
    "anima, animae (f.)",
    "spīritus, spīritūs",
    "spīritus, spīritūs (m)"
  ],
  "some…others": [
    "aliī…aliī"
  ],
  "wing": [
    "āla -ae f",
    "Ala"
  ],
  "air": [
    "āēr, āeris",
    "Aer",
    "anima, animae",
    "aethra, aethrae",
    "anima, animae (f.)"
  ],
  "chirp": [
    "pīpiō, pīpiāre, pīpiāvī, pīpiātus"
  ],
  "internal organs": [
    "viscera -um n pl"
  ],
  "belly": [
    "venter -tris m"
  ],
  "stomach": [
    "venter -tris m"
  ],
  "vein": [
    "vēna -ae f"
  ],
  "touch": [
    "tangō, tangere, tetigī, tāctum",
    "Tange",
    "tāctus, tāctūs",
    "contāctus, contāctūs",
    "contāgiō, contāgiōnis",
    "obtingō, obtingere, obtigī",
    "tentō, tentāre, tentāvī, tentātum",
    "contingō, contingere, contigī, contāctum",
    "contāminō, contāmināre, contāmināvī, contāminātum"
  ],
  "stupid": [
    "stultus -a -um",
    "excors, excordis",
    "brūtus, brūta, brūtum",
    "stolidus, stolida, stolidum"
  ],
  "foolish": [
    "stultus -a -um",
    "dēmēns, dēmentis",
    "fatuus, fatua, fatuum",
    "īnsipiēns, īnsipientis",
    "stultus, stulta, stultum",
    "stolidus, stolida, stolidum"
  ],
  "stand": [
    "stō, stāre, stetī, status",
    "Sta",
    "stō, stāre, stetī, statum",
    "īnsistō, īnsistere, īnstitī"
  ],
  "watch": [
    "spectō, spectāre, spectāvī, spectātus",
    "intueor, intuērī, intuitus sum",
    "excubia, excubiae",
    "speculor, speculārī, speculātus sum",
    "aspectō, aspectāre, aspectāvī, aspectātum"
  ],
  "feel": [
    "sentiō, sentīre, sēnsī, sēnsum"
  ],
  "sense": [
    "sentiō, sentīre, sēnsī, sēnsum",
    "significātiō -ōnis f",
    "sēnsus, sēnsūs",
    "sēnsus, sēnsūs (m)"
  ],
  "think": [
    "putō, putāre, putāvī, putātus",
    "sentiō, sentīre, sēnsī, sēnsum",
    "cōgitō, cōgitāre, cōgitāvī, cōgitātus",
    "arbitror, arbitrārī, arbitrātus sum",
    "cēnseō, cēnsēre, cēnsuī, cēnsum",
    "reor, rērī, ratus sum",
    "putō, putāre, putāvī, putātum",
    "cōgitō, cōgitāre, cōgitāvī, cōgitātum"
  ],
  "sit": [
    "sedeō, sedēre, sēdī, sessum",
    "Conside",
    "resīdō, resīdere, resēdī, resēssum",
    "obsideō, obsidēre, obsēdī, obsessum"
  ],
  "healthy": [
    "sānus -a -um",
    "sānus, sāna, sānum",
    "salūtāris, salūtāre",
    "salūber, salūbris, salūbre"
  ],
  "well": [
    "bene",
    "sānus -a -um",
    "puteus, puteī",
    "salveō, salvēre",
    "valeō, valēre, valuī, valitūrum",
    "valeō, valēre, valuī, valitūrus"
  ],
  "blood": [
    "sanguis, sanguinis",
    "cruor -ōris m"
  ],
  "heal": [
    "sānō, sānāre, sānāvī, sānātus",
    "Sana",
    "medeor, medērī",
    "cūrō, cūrāre, cūrāvī, cūrātum",
    "medicō, medicāre, medicāvī, medicātum"
  ],
  "cure": [
    "sānō, sānāre, sānāvī, sānātus",
    "medeor, medērī",
    "remedium, remediī",
    "medicīna, medicīnae",
    "remedium, remediī (n)",
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum",
    "medicō, medicāre, medicāvī, medicātum"
  ],
  "red": [
    "ruber, rubra, rubrum",
    "Red",
    "rūfus, rūfa, rūfum",
    "rutilus, rutila, rutilum",
    "rubeō, rubēre, -----, -----"
  ],
  "quiet": [
    "quiētus -a -um",
    "placidus, placida, placidum",
    "pācō, pācāre, pācāvī, pācātum",
    "taciturnus, taciturna, taciturnum",
    "quiētō, quiētāre, quiētāvī, quiētātum"
  ],
  "suppose": [
    "putō, putāre, putāvī, putātus",
    "arbitror, arbitrārī, arbitrātus sum",
    "reor, rērī, ratus sum",
    "opīnor, opīnārī, opīnātus sum",
    "putō, putāre, putāvī, putātum",
    "suspicor, suspicārī, suspicātus sum"
  ],
  "cup": [
    "pōculum -ī n",
    "Poculum",
    "calix, calicis",
    "scyphus, scyphī"
  ],
  "glass": [
    "pōculum -ī n",
    "Vitrum",
    "vitrum, vitrī"
  ],
  "breast": [
    "pectus -oris n",
    "pectus, pectoris",
    "pectus, pectoris (n.)"
  ],
  "throb": [
    "palpitō, palpitāre, palpitāvī, palpitātus"
  ],
  "mouth": [
    "ōs ōris n",
    "Os",
    "ōs, ōris"
  ],
  "limb": [
    "membrum -ī n"
  ],
  "physician": [
    "medicus -ī m",
    "Medicus",
    "medicus, medicī and medica, medicae",
    "medicus, medicī and&nbsp,medica, medicae"
  ],
  "doctor": [
    "medicus -ī m",
    "rabbī",
    "medicus, medicī and medica, medicae",
    "medicus, medicī and&nbsp,medica, medicae"
  ],
  "hand": [
    "manus -ūs f",
    "Manus",
    "manus, manūs"
  ],
  "badly": [
    "male adv"
  ],
  "ill": [
    "male adv",
    "aeger -gra -grum",
    "saucius, saucia, saucium",
    "aegrōtō, aegrōtāre, aegrōtāvī, aegrōtātus"
  ],
  "tongue": [
    "lingua -ae f",
    "lingua, linguae",
    "lingua, linguae (f)"
  ],
  "language": [
    "lingua -ae f",
    "lingua, linguae",
    "lingua, linguae (f)"
  ],
  "lip": [
    "labrum -ī n",
    "labium, labiī",
    "lābrum, lābrī"
  ],
  "tell": [
    "iubeō, iubēre, iussī, iussum",
    "nārrō, nārrāre, nārrāvī, nārrātus",
    "memorō, memorāre, memorāvī, memorātus",
    "dīco, dīcere, dīxī, dictum",
    "loquor, loquī, locūtus sum",
    "narrō, nārrāre, nārrāvī, nārrātum",
    "intimō, intimāre, intimāvī, intimātum"
  ],
  "below": [
    "īnfrā",
    "subter",
    "subtus"
  ],
  "liver": [
    "iecur -oris n"
  ],
  "human": [
    "hūmānus -a -um",
    "hūmānus, hūmāna, hūmānum"
  ],
  "cheek": [
    "gena -ae f"
  ],
  "be glad": [
    "gaudeō, gaudēre, gāvīsus sum",
    "laetor, laetārī, laetātus sum"
  ],
  "be pleased": [
    "gaudeō, gaudēre, gāvīsus sum"
  ],
  "forehead": [
    "frōns -ontis f"
  ],
  "flow": [
    "fluō, fluere, flūxī, flūxum",
    "fluentum, fluentī",
    "affluō, affluere, affluxī, affluxum"
  ],
  "hurt": [
    "doleō, dolēre, doluī, dolitūrus",
    "laedō, laedere, laesī, laesus",
    "noxa, noxae",
    "saucius, saucia, saucium",
    "doleō, dolēre, doluī, dolitūrum"
  ],
  "feel pain": [
    "doleō, dolēre, doluī, dolitūrus"
  ],
  "grieve": [
    "doleō, dolēre, doluī, dolitūrus",
    "maereō, maerēre, --, --",
    "doleō, dolēre, doluī, dolitūrum"
  ],
  "say": [
    "dīcō, dīcere, dīxī, dictum",
    "āiō",
    "āiō (ais, ait, āiunt)",
    "ferō, ferre, tulī, lātum",
    "dīco, dīcere, dīxī, dictum",
    "loquor, loquī, locūtus sum",
    "ēnuntiō, ēnuntiāre, ēnuntiāvī, ēnuntiātum"
  ],
  "speak": [
    "dīcō, dīcere, dīxī, dictum",
    "loquor, loquī, locūtus sum",
    "for, fārī, fātus sum",
    "Adloquere",
    "ōrō, ōrāre, ōrāvī, ōrātum",
    "dīco, dīcere, dīxī, dictum"
  ],
  "wipe off": [
    "dētergeō, dētergēre, dētersī, dētersum"
  ],
  "about": [
    "dē prp +abl",
    "circiter",
    "super",
    "ferē",
    "dē",
    "dē (+abl.)",
    "super (+abl)"
  ],
  "knife": [
    "culter -trī m",
    "Culter"
  ],
  "leg": [
    "crūs -ūris n",
    "Crurem"
  ],
  "body": [
    "corpus -oris n",
    "Corpus",
    "corpus, corporis",
    "corpus, corporis (n)"
  ],
  "heart": [
    "cor cordis n",
    "Cor",
    "pectus, pectoris",
    "pectus, pectoris (n.)"
  ],
  "color": [
    "color -ōris m",
    "colōrō, colōrāre, colōrāvī, colōrātum"
  ],
  "brain": [
    "cerebrum -ī n",
    "Cerebrum"
  ],
  "head": [
    "Caput",
    "Head",
    "caput -itis n",
    "spīca, spīcae",
    "caput, capitis"
  ],
  "chief": [
    "caput -itis n",
    "dux ducis m",
    "prīnceps, prīncipis",
    "prīmus, prīma, prīmum",
    "prīnceps, gen. prīncipis",
    "prīmārius, prīmāria, prīmārium"
  ],
  "capital": [
    "caput -itis n"
  ],
  "hair": [
    "capillus -ī m",
    "villus",
    "Capillus",
    "coma, comae",
    "pilus, pilī"
  ],
  "arm": [
    "bracchium -ī n",
    "lacertus -ī m",
    "armō, armāre, armāvī, armātus"
  ],
  "ear": [
    "auris -is f",
    "auris, auris",
    "spīca, spīcae"
  ],
  "as well": [
    "atque/ac"
  ],
  "send for": [
    "arcessō, arcessere, arcessīvī, arcessītum"
  ],
  "fetch": [
    "arcessō, arcessere, arcessīvī, arcessītum",
    "acciō, accīre, accīvī, accītum",
    "dēprōmō, dēprōmere, dēprompsī, dēpromptum",
    "exprōmō, exprōmere, exprōmpsī, exprōmptum"
  ],
  "serve": [
    "appōnō, appōnere, apposuī, appositus",
    "serviō, servīre, servīvī, servītus",
    "serviō, servīre, servīvī, servītum + dat",
    "administrō, administrāre, administrāvī, administrātum"
  ],
  "be ill": [
    "aegrōtō, aegrōtāre, aegrōtāvī, aegrōtātus"
  ],
  "sick": [
    "aeger -gra -grum",
    "aegrōtus -a -um",
    "saucius, saucia, saucium",
    "vomō, vomere, vomuī, vomitum"
  ],
  "twelfth": [
    "duodecimus, duodecima, duodecimum"
  ],
  "gift": [
    "dōnum -ī n",
    "mūnus -eris n",
    "Donum",
    "dōnum, dōnī",
    "datus, datūs",
    "stips, stipis",
    "dōnum, dōnī (n)",
    "dōnātiō, dōnātiōnis",
    "oblātiō, oblātiōnis"
  ],
  "present": [
    "dōnum -ī n",
    "praesēns -entis",
    "ubiquitous",
    "dōnum, dōnī",
    "dōnum, dōnī (n)",
    "adsum, adesse, affuī, affutūrus",
    "offerō, offerre, obtulī, oblātum",
    "ōbiciō, ōbicere, ōbiēcī, ōbiectum",
    "exhibeō, exhibēre, exhibuī, exhibitum",
    "prōpōnō, prōpōnere, prōposuī, prōpositum",
    "ostentō, ostentāre, ostentāvī, ostentātum",
    "perhibeō, perhibēre, perhibuī, perhibitum"
  ],
  "in the evening": [
    "vesperī"
  ],
  "verse": [
    "versus -ūs m"
  ],
  "rampart": [
    "vāllum, vāllī",
    "agger, aggeris",
    "mūnīmentum, mūnīmentī",
    "prōpugnāculum, prōpugnāculī"
  ],
  "line of stakes": [
    "vāllum, vāllī"
  ],
  "sad": [
    "trīstis -e",
    "maestus -a -um",
    "trīstis, trīste",
    "miserābilis, miserābile",
    "luctuōsus, luctuōsa, luctuōsum"
  ],
  "sister": [
    "soror -ōris f",
    "soror, sorōris",
    "soror, sorōris (f)"
  ],
  "shield": [
    "scūtum -ī n",
    "clipeus, clipeī"
  ],
  "arrow": [
    "sagitta -ae f"
  ],
  "fist": [
    "pugnus -ī m"
  ],
  "fight": [
    "Pugna",
    "pugnō, pugnāre, pugnāvī, pugnātus",
    "pugna -ae f",
    "concurrō, concurrere, concurrī, concursus",
    "certāmen -inis",
    "certō, certāre, certāvī, certātus",
    "dīmicātiō, dīmicātiōnis",
    "luctor, luctārī, luctātus sum",
    "pugnō, pugnāre, pugnāvī, pugnātum",
    "proelior, proeliārī, proeliātus sum",
    "dīmicō, dīmicāre, dīmicāvī, dīmicātum",
    "dēcertō, dēcertāre, dēcertāvī, dēcertātum",
    "dēpugnō, dēpugnāre, dēpugnāvi, dēpugnātum"
  ],
  "first name": [
    "praenōmen -inis n"
  ],
  "spear": [
    "pīlum -ī n",
    "hasta -ae f",
    "tēlum, tēlī",
    "hasta, hastae",
    "hasta, hastae (f)",
    "spīculum, spīculī"
  ],
  "javelin": [
    "pīlum -ī n",
    "iaculum, iaculī",
    "trāgula, trāgulae"
  ],
  "foot-soldier": [
    "pedes -itis m"
  ],
  "native country": [
    "patria -ae f"
  ],
  "part": [
    "pars -rtis f",
    "pars, partis",
    "portiō, portiōnis",
    "dīgredior, dīgredī, dīgressus sum",
    "dīdūcō, dīdūcere, dīdūxī, dīductum",
    "partiō, partīre, partīvī, partītum",
    "discernō, discernere, discrēvī, discrētum"
  ],
  "direction": [
    "pars -rtis f",
    "pars, partis",
    "dīrēctiō, dīrēctiōnis",
    "dispositiō, dispositiōnis"
  ],
  "name": [
    "nōmen -inis n",
    "nōminō, nōmināre, nōmināvī, nōminātus",
    "Nomen",
    "nōmen, nōminis",
    "nōmen, nōminis (n)",
    "dīco, dīcere, dīxī, dictum",
    "appellō, appellāre, appellāvī, appellātum",
    "nūncupō, nūncupāre, nūncupāvī, nūncupātum",
    "dēnōminō, dēnōmināre, dēnōmināvī, dēnōminātum"
  ],
  "second name": [
    "nōmen -inis n"
  ],
  "gens name": [
    "nōmen -inis n"
  ],
  "serve as a soldier": [
    "mīlitō, mīlitāre, mīlitāvī, mīlitātus"
  ],
  "soldier": [
    "mīles -itis m",
    "Miles",
    "mīles, mīlitis",
    "mīles, mīlitis (m)",
    "bellātor, bellātōris"
  ],
  "light": [
    "levis -e",
    "lūx lūcis f",
    "accendō, accendere, &nbsp,accendī, accēnsum",
    "Lux",
    "Levis",
    "lūx, lūcis",
    "levis, leve",
    "agilis, agile",
    "iubar, iubaris",
    "accendō, accendere, accendī, accēnsum"
  ],
  "slight": [
    "levis -e",
    "levis, leve"
  ],
  "broad": [
    "lātus -a -um",
    "patera, paterae"
  ],
  "wide": [
    "lātus -a -um",
    "capāx, capācis",
    "laxus, laxa, laxum"
  ],
  "inhabit": [
    "incolō, incolere, incoluī, -----",
    "habitō, habitāre, habitāvī, habitātum"
  ],
  "charge": [
    "impetus -ūs m",
    "concurrō, concurrere, concurrī, concursus",
    "prōcurrō, prōcurrere, prōcurrī, prōcursum",
    "sūmptus, sūmptūs",
    "dēcurrō, dēcurrere, dēcurrī, dēcursum",
    "imputō, imputāre, imputāvī, imputātum",
    "īnsimulō, īnsimulāre, īnsimulāvī, īnsimulātum"
  ],
  "throw": [
    "iaciō, iacere, iēcī, iactum",
    "iactō, iactāre, iactāvī, iactātus",
    "mittō, mittere, mīsī, missum",
    "prōiciō, prōicere, prōiēcī, prōiectum",
    "dēiectus, dēiectūs",
    "dēiciō, dēicere, dēiēcī, dēiectum",
    "cōniciō, cōnicere, cōniēcī, coniectum",
    "coniectō, coniectāre, coniectāvī, coniectātum"
  ],
  "hurl": [
    "iaciō, iacere, iēcī, iactum",
    "ēmittō, ēmittere, ēmīsī, ēmissum",
    "prōpellō, prōpellere, prōpulī, prōpulsum"
  ],
  "lance": [
    "hasta -ae f"
  ],
  "heavy": [
    "gravis -e",
    "Gravis",
    "gravis, grave",
    "brūtus, brūta, brūtum",
    "onerōsus, onerōsa, onerōsum"
  ],
  "severe": [
    "gravis -e",
    "sevērus -a -um",
    "gravis, grave",
    "trīstis, trīste",
    "ācer, ācris, ācre"
  ],
  "grave": [
    "gravis -e",
    "sepulcrum, sepulcrī",
    "sepulchrum, sepulchrī"
  ],
  "sword": [
    "gladius -ī m",
    "ēnsis -is m",
    "Gladius",
    "ferrum, ferrī",
    "ferrum, ferrī (n)"
  ],
  "run away": [
    "fugiō, fugere, fūgī, fugitūrum",
    "effugiō, effugere, effūgī, effugitum",
    "aufugiō, aufugere, aufūgī, ------",
    "refugiō, refugere, refūgī"
  ],
  "flee": [
    "fugiō, fugere, fūgī, fugitūrum",
    "Effuge",
    "prōfugiō, prōfugere, prōfūgī",
    "confugiō, confugere, confūgī, ----",
    "dēfugiō, dēfugere, dēfūgī, dēfugitum",
    "cōnfugiō, cōnfugere, cōnfūgī, cōnfugitum"
  ],
  "brother": [
    "frāter -tris m",
    "frāter, frātris",
    "frāter, frātris (m)"
  ],
  "ditch": [
    "fossa -ae f",
    "scrobis, scrobis"
  ],
  "trench": [
    "fossa -ae f",
    "scrobis, scrobis"
  ],
  "strong": [
    "fortis -e",
    "validus -a -um",
    "fortis, forte",
    "potēns, potentis",
    "potēns, gen potentis",
    "firmus, firma, firmum",
    "fīrmus, fīrma, fīrmum",
    "valeō, valēre, valuī, valitūrum",
    "valeō, valēre, valuī, valitūrus"
  ],
  "brave": [
    "fortis -e",
    "fortis, forte",
    "magnanimus, magnanima, magnanimum"
  ],
  "boundary": [
    "fīnis -is m",
    "mēta, mētae",
    "fīnis, fīnis",
    "fīnis, fīnis (m)",
    "mēta, mētae (f.)",
    "terminus, terminī",
    "dēterminātiō, dēterminātiōnis"
  ],
  "end": [
    "fīnis -is m",
    "dēsinō, dēsinere, dēsiī, dēsitus",
    "exitus, exitūs",
    "fīniō, fīnīre, fīnīvī, fīnītus",
    "fīnis, fīnis",
    "fīnis, fīnis (m)",
    "terminus, terminī",
    "clausula, clausulae"
  ],
  "bring": [
    "ferō, ferre, tulī, lātum",
    "afferō, afferre, attulī, allātum",
    "apportō, apportāre, apportāvī, apportātum",
    "offerō, offerre, obtulī, oblātum",
    "admoveō, admovēre, admōvī, admōtum",
    "trādūcō, trādūcere, trādūxī, trāductum",
    "dēprōmō, dēprōmere, dēprompsī, dēpromptum",
    "importō, importāre, importāvī, importātum",
    "cōnstruō, cōnstruere, cōnstruxī, cōnstructum",
    "supportō, supportāre, supportāvī, supportātum"
  ],
  "conquer": [
    "expugnō, expugnāre, expugnāvī, expugnātus",
    "vincō, vincere, vīcī, victum",
    "superō, superāre, superāvī, superātus",
    "dēbellō, dēbellāre, dēbellāvī, dēbellātum"
  ],
  "army": [
    "exercitus -ūs m",
    "exercitus, exercitūs",
    "exercitus, exercitūs (m.)"
  ],
  "cavalry": [
    "equitātus -ūs m"
  ],
  "horseman": [
    "eques -itis m"
  ],
  "leader": [
    "dux ducis m",
    "prīnceps, prīncipis",
    "dux, ducis",
    "caput, capitis",
    "dux, ducis (m)",
    "ductor, ductōris",
    "rēctor, rēctōris",
    "archidux, archiducis",
    "praepositus, praepositī",
    "prīnceps, gen. prīncipis"
  ],
  "general": [
    "dux ducis m",
    "imperātor -ōris m",
    "dux, ducis",
    "dux, ducis (m)",
    "commūnis, commūne",
    "generālis, generāle",
    "imperātor, imperātōris",
    "imperātor, imperātōris (m)"
  ],
  "separate": [
    "dīvidō, dīvidere, dīvīsī, dīvīsum",
    "disiungō, disiungere, disiunxī, disiunctum",
    "seorsus, seorsa, seorsum",
    "findō, findere, fidī, fissum",
    "āvocō, āvocāre, āvocāvī, āvocātum",
    "dīgredior, dīgredī, dīgressus sum",
    "distinctus, distincta, distinctum",
    "spernō, spernere, sprēvī, sprētum",
    "dīdūcō, dīdūcere, dīdūxī, dīductum",
    "dīgerō, dīgerere, dīgessī, dīgestum",
    "dirimō, dirimere, dirēmī, diremptum",
    "dīvertō, dīvertere, dīvertī, dīversum",
    "sēcernō, sēcernere, sēcrēvī, sēcrētum",
    "sēiungō, sēiungere, sēiunxī, sēiunctus",
    "distineō, distinēre, distinuī, distentum",
    "discernō, discernere, discrēvī, discrētum",
    "praecīdō, praecīdere, praecīdī, praecīsum",
    "sēgregō, sēgregāre, sēgregāvī, sēgregātum",
    "dissolvō, dissolvere, dissolvī, dissolutum",
    "distinguō, distinguere, distinxī, distinctum",
    "abaliēnō, abaliēnāre, abaliēnāvī, abaliēnātum"
  ],
  "divide": [
    "dīvidō, dīvidere, dīvīsī, dīvīsum",
    "partior, partīrī, partītus sum",
    "findō, findere, fidī, fissum",
    "dīdūcō, dīdūcere, dīdūxī, dīductum",
    "partiō, partīre, partīvī, partītum",
    "dīgerō, dīgerere, dīgessī, dīgestum",
    "dirimō, dirimere, dirēmī, diremptum",
    "disiciō, disicere, disiēcī, disiectum",
    "sēparō, sēparāre, sēparāvī, sēparātum",
    "diffindō, diffindere, diffidī, diffissum",
    "distineō, distinēre, distinuī, distentum",
    "discernō, discernere, discrēvī, discrētum",
    "distrahō, distrahere, distraxī, distractum",
    "discrībō, discrībere, discrīpsi, discrīptum",
    "dispertiō, dispertīre, dispertīvī, dispertītum"
  ],
  "defend": [
    "dēfendō, dēfendere, dēfendī, dēfēnsum",
    "prōtegō, prōtegere, prōtēxī, prōtēctum",
    "dēfensō, dēfensāre, dēfensāvī, dēfensātum",
    "prōpugnō, prōpugnāre, prōpugnāvī, prōpugnātum"
  ],
  "third name": [
    "cognōmen -inis n"
  ],
  "camp": [
    "castra, castrōrum"
  ],
  "short": [
    "brevis -e",
    "brevis, breve"
  ],
  "war": [
    "bellum -ī n",
    "bellum, bellī",
    "duellum, duellī",
    "bellum, bellī (n)"
  ],
  "foreign": [
    "barbarus -a -um",
    "peregrē",
    "externus, externa, externum",
    "extrāneus, extrānea, extrāneum",
    "arcessītus, arcessīta, arcessītum",
    "peregrīnus, peregrīna, peregrīnum",
    "adventīcius, adventīcia, adventīcium",
    "aliēnigenus, aliēnigena, aliēnigenum"
  ],
  "barbarian": [
    "barbarus -a -um",
    "Barbarian"
  ],
  "uncle": [
    "avunculus -ī m"
  ],
  "armed": [
    "armātus -a -um",
    "armifer, armifera, armiferum",
    "armiger, armigera, armigerum"
  ],
  "arms": [
    "arma -ōrum n pl",
    "arma, armōrum",
    "arma, armōrum (n. pl.)"
  ],
  "bow": [
    "arcus -ūs m",
    "curvō, curvāre, curvāvī, curvātum",
    "īnflectō, īnflectere, īnflēxī, īnflexus",
    "incurvō, incurvāre, incurvāvī, incurvātum"
  ],
  "high": [
    "altus -a -um",
    "Altus",
    "sublīmis, sublīme",
    "altē, altius, altissimē",
    "prōcērus, prōcēra, prōcērum"
  ],
  "tall": [
    "altus -a -um",
    "celsus -a -um",
    "prōcērus, prōcēra, prōcērum"
  ],
  "deep": [
    "altus -a -um",
    "pontus, pontī",
    "profundus, profunda, profundum"
  ],
  "farewell": [
    "valē -ēte",
    "valē, valēte"
  ],
  "goodbye": [
    "Vale",
    "valē -ēte",
    "valē",
    "valēte",
    "valē, valēte"
  ],
  "thousands": [
    "mīlia -ium",
    "mīlia, mīlium",
    "mīlia, mīlium (n, i-stem)"
  ],
  "evening": [
    "vesper -erī m",
    "nox",
    "vespera, vesperae",
    "crepusculum, crepusculī",
    "vesper, vesperis or vesperī",
    "vesper, vesperis or vesperī (m)",
    "vespertīnus, vespertīna, vespertīnum"
  ],
  "spring": [
    "vēr vēris n",
    "Ver",
    "fōns, fontis",
    "vernus, verna, vernum",
    "exsiliō, exsilīre, exsiluī",
    "īnsultō, īnsultāre, īnsultāvī, īnsultātum"
  ],
  "city": [
    "urbs -bis f",
    "urbs, urbis"
  ],
  "twenty-nine": [
    "ūndētrīgintā"
  ],
  "eleventh": [
    "ūndecimus -a -um"
  ],
  "eleven": [
    "ūndecim"
  ],
  "then": [
    "tunc",
    "igitur",
    "deinde / dein",
    "tum",
    "exin",
    "dehinc",
    "deinde",
    "exindē"
  ],
  "thirty": [
    "trīgintā"
  ],
  "three hundred": [
    "trecentī -ae -a"
  ],
  "the whole of": [
    "tōtus -a -um",
    "ūniversus, ūniversa, ūniversum"
  ],
  "all": [
    "tōtus -a -um",
    "omnis -e",
    "OMNIA",
    "omnis, omne"
  ],
  "time": [
    "tempus -oris n",
    "Tempus",
    "hōra, hōrae",
    "aetās, aetātis",
    "hōra, hōrae (f)",
    "tempus, temporis",
    "tempus, temporis (n)"
  ],
  "star": [
    "stēlla -ae f",
    "astrum, astrī",
    "sīdus, sīderis",
    "stēlla, stēllae",
    "stēlla, stēllae (f)"
  ],
  "sixth": [
    "sextus -a -um",
    "sextus"
  ],
  "august": [
    "augustus -ī",
    "sextīlis -is",
    "Augustus -ī (mēnsis)",
    "Sextīlis -is (mēnsis)",
    "venerābilis, venerābile",
    "augustus, augusta, augustum"
  ],
  "sixty": [
    "sexāgintā"
  ],
  "seventh": [
    "septimus -a -um",
    "septimus"
  ],
  "september": [
    "september -bris",
    "September -bris (mēnsis)"
  ],
  "century": [
    "saeculum -ī n"
  ],
  "fifth": [
    "quīntus -a -um",
    "quīntus"
  ],
  "july": [
    "iūlius -ī",
    "quīntīlis -is",
    "Iūlius -ī (mēnsis)",
    "Quīntīlis -is (mēnsis)"
  ],
  "quarter": [
    "quārta pars",
    "vīcus, vīcī",
    "plaga, plagae",
    "quadrāns, quadrantis"
  ],
  "last": [
    "postrēmus -a -um",
    "ultimus -a -um",
    "Ultimus",
    "ultimus, ultima, ultimum",
    "postumus, postuma, postumum"
  ],
  "cover": [
    "operiō, operīre, operuī, opertum",
    "vēlāmen, vēlāminis",
    "operculum, operculī",
    "tegumentum, tegumentī",
    "operīmentum, operīmentī",
    "tegō, tegere, tēxī, tēctum",
    "nūbō, nūbere, nūpsī, nūptum",
    "cēlō, cēlāre, cēlāvī, cēlātum",
    "integō, integere, intēxī, intēctum",
    "occulō, occulere, occuluī, occultum",
    "prōtegō, prōtegere, prōtēxī, prōtēctum",
    "adumbrō, adumbrāre, adumbrāvī, adumbrātum",
    "abscondō, abscondere, abscondī, absconditum"
  ],
  "october": [
    "octōber -bris",
    "Octōber -bris (mēnsis)"
  ],
  "eighth": [
    "octāvus -a -um",
    "octāvus"
  ],
  "dark": [
    "obscūrus -a -um",
    "āter -tra -trum",
    "tenebricōsus -a -um",
    "fuscus, fusca, fuscum",
    "tenebrōsus, tenebrōsa, tenebrōsum"
  ],
  "night": [
    "nox, noctis",
    "nox"
  ],
  "november": [
    "november -bris",
    "November -bris (mēnsis)"
  ],
  "ninth": [
    "nōnus -a -um",
    "nōnus"
  ],
  "5th": [
    "nōnae, nōnārum"
  ],
  "7th": [
    "nōnae, nōnārum"
  ],
  "snow": [
    "nix nivis f",
    "Nivis"
  ],
  "midday": [
    "merīdiēs -ēī m",
    "merīdiānus, merīdiāna, merīdiānum"
  ],
  "noon": [
    "merīdiēs -ēī m",
    "sexta",
    "merīdiānus, merīdiāna, merīdiānum"
  ],
  "south": [
    "merīdiēs -ēī m"
  ],
  "month": [
    "mēnsis -is m"
  ],
  "march": [
    "mārtius -ī",
    "iter itineris n",
    "Mārtius -ī (mēnsis)",
    "incēdō, incēdere, incessī, incessum"
  ],
  "morning": [
    "māne",
    "mane",
    "mātūtīnus, mātūtīna, mātūtīnum"
  ],
  "in the morning": [
    "māne"
  ],
  "may": [
    "māius -ī",
    "Māius -ī (mēnsis)"
  ],
  "daylight": [
    "lūx lūcis f"
  ],
  "moon": [
    "lūna -ae f",
    "lūna, lūnae",
    "lūna, lūnae (f.)"
  ],
  "lake": [
    "lacus -ūs m"
  ],
  "calendar": [
    "kalendārium -ī n"
  ],
  "the 1st": [
    "kalendae -ārum f pl"
  ],
  "june": [
    "iūnius -ī",
    "Iūnius -ī (mēnsis)"
  ],
  "likewise": [
    "item",
    "itidem"
  ],
  "beginning": [
    "initium -ī n",
    "prīncipium -ī n",
    "caput, capitis",
    "initium, initiī",
    "orīgō, orīginis",
    "coeptus, coeptūs",
    "exordium, exordiī",
    "inceptum, inceptī",
    "initium, initiī (n.)",
    "prīncipium, prīncipiī",
    "prīncipium, prīncipiī (n)"
  ],
  "begin": [
    "incipiō, incipere, coepī, coeptum",
    "ineō, inīre, iniī, initum",
    "ōrdior, ōrdīrī, ōrsus sum",
    "coepiō, coepere, coepī, coeptum",
    "incipiō, incipere, incēpī, inceptum",
    "occipiō, occipere, occēpī, occeptum",
    "incohō, incohāre, incohāvī, incohātum",
    "initiō, initiāre, initiāvī, initiātum"
  ],
  "rain": [
    "imber -bris m",
    "pluvia, pluviae"
  ],
  "shower": [
    "imber -bris m"
  ],
  "illuminate": [
    "illūstrō, illūstrāre, illūstrāvī, illūstrātum",
    "illūminō, illūmināre, illūmināvī, illūminātum"
  ],
  "make clear": [
    "illūstrō, illūstrāre, illūstrāvī, illūstrātum",
    "arguō, arguere, arguī, argūtum",
    "serēnō, serēnāre, serēnāvī, serēnātum"
  ],
  "13th": [
    "īdūs -uum",
    "īdūs -uum ( f pl)"
  ],
  "15th": [
    "īdūs -uum",
    "īdūs -uum ( f pl)"
  ],
  "january": [
    "iānuārius -ī",
    "Iānuārius -ī (mēnsis)"
  ],
  "hour": [
    "hōra -ae f",
    "hora",
    "hōra, hōrae",
    "hōra, hōrae (f)"
  ],
  "winter": [
    "hiems -mis f",
    "Hiems",
    "hiemō, hiemāre, hiemāvī, hiemātum",
    "hībernō, hībernāre, hībernāvī, hībernātum"
  ],
  "ice": [
    "glaciēs -ēī f",
    "Glacies"
  ],
  "cold": [
    "frīgidus -a -um",
    "frīgus -oris n",
    "Frigidus",
    "algidus, algida, algidum",
    "frīgeō, frīgēre, -----, -----"
  ],
  "chilly": [
    "frīgidus -a -um"
  ],
  "cool": [
    "frīgidus -a -um",
    "refrīgēscō, refrīgēscere, refrīxī"
  ],
  "form": [
    "fōrma -ae f",
    "efficiō, efficere, effēcī, effectum",
    "fōrma, fōrmae",
    "īdōlum, īdōlī",
    "figūra, figūrae",
    "fōrma, fōrmae (f)",
    "conformātiō, conformātiōnis",
    "effingō, effingere, effinxī, effictum",
    "figūrō, figūrāre, figūrāvī, figūrātum",
    "cōnfingō, cōnfingere, cōnfinxī, cōnfictum",
    "dēfōrmō, dēfōrmāre, dēfōrmāvī, dēfōrmātum",
    "īnfōrmō, īnfōrmāre, īnfōrmāvī, īnfōrmātum",
    "cōnfōrmō, cōnfōrmāre, cōnfōrmāvī, cōnfōrmātum"
  ],
  "shape": [
    "fōrma -ae f",
    "fōrma, fōrmae",
    "figūra, figūrae",
    "fōrma, fōrmae (f)",
    "fōrmula, fōrmulae",
    "conformātiō, conformātiōnis",
    "fingō, fingere, finxī, fictum",
    "figūrō, figūrāre, figūrāvī, figūrātum",
    "fabricō, fabricāre, fabricāvī, fabricātum"
  ],
  "figure": [
    "fōrma -ae f",
    "figūra, figūrae"
  ],
  "february": [
    "februārius -ī"
  ],
  "face": [
    "faciēs -ēī f",
    "vultus -ūs m",
    "Facies",
    "ōs, ōris",
    "vultus, vultūs",
    "vultus, vultūs (m.)"
  ],
  "scanty": [
    "exiguus -a -um"
  ],
  "twenty-eight": [
    "duodētrīgintā"
  ],
  "two hundred": [
    "ducentī -ae -a"
  ],
  "half": [
    "dīmidius dīmidia dīmidium",
    "sēmis, sēmissis",
    "dīmidium, dīmidiī or dīmidī"
  ],
  "day": [
    "diēs -ēī",
    "Dies",
    "diēs, diēī",
    "diēs, diēī (m)",
    "diēs -ēī (m or f)"
  ],
  "date": [
    "diēs -ēī",
    "diēs -ēī (m or f)"
  ],
  "tenth": [
    "decimus -a -um",
    "decimus",
    "decuma, decumae",
    "decumānus, decumāna, decumānum"
  ],
  "december": [
    "december -bris"
  ],
  "bright": [
    "clārus -a -um",
    "serēnus, serēna, serēnum",
    "candidus -a -um",
    "aethra, aethrae",
    "illūstris, illūstre",
    "clārus, clāra, clārum",
    "glaucus, glauca, glaucum",
    "candidus, candida, candidum",
    "dīlūcidus, dīlūcida, dīlūcidum",
    "lūculentus, lūculenta, lūculentum",
    "praeclārus, praeclāra, praeclārum",
    "splendidus, splendida, splendidum"
  ],
  "clear": [
    "clārus -a -um",
    "serēnus, serēna, serēnum",
    "plānus -a -um",
    "Vacua",
    "aethra, aethrae",
    "ēvidēns, ēvidentis",
    "clārus, clāra, clārum",
    "dīlūcidus, dīlūcida, dīlūcidum",
    "perspicuus, perspicua, perspicuum"
  ],
  "loud": [
    "clārus -a -um"
  ],
  "remaining": [
    "cēterus -a -um",
    "reliquus, reliqua, reliquum",
    "mānsiō, mānsiōnis",
    "residuus, residua, residuum",
    "cēterī, cēterae, cētera (pl.)"
  ],
  "warm": [
    "calidus -a -um",
    "foveō, fovēre, fōvī, fōtum"
  ],
  "hot": [
    "calidus -a -um",
    "calēns, calentis"
  ],
  "autumn": [
    "autumnus -ī m"
  ],
  "april": [
    "aprīlis -is",
    "Aprīlis -is (mēnsis)"
  ],
  "year": [
    "annus -ī m",
    "annus, annī",
    "annus, annī (m)"
  ],
  "summer": [
    "aestās -ātis f",
    "Aestas",
    "aestās, aestātis",
    "aestās, aestātis (f.)",
    "aestīvus, aestīva, aestīvum"
  ],
  "equal": [
    "aequus -a -um",
    "pār paris",
    "pār, gen. paris",
    "parilis, parile",
    "aequālis, aequāle",
    "aequābilis, aequābile",
    "aequus, aequa, aequum",
    "pār, gen. paris (+ dat.)"
  ],
  "calm": [
    "aequus -a -um",
    "tranquillus -a -um",
    "Calm",
    "Molliorem Fac",
    "aequus, aequa, aequum",
    "modestus, modesta, modestum",
    "placidus, placida, placidum",
    "sēdō, sēdāre, sēdāvī, sēdātum",
    "sēdō, sēdāre, sēdāvī, sēdātus",
    "quiētō, quiētāre, quiētāvī, quiētātum"
  ],
  "equinox": [
    "aequinoctium -ī n"
  ],
  "be awake": [
    "vigilō, vigilāre, vigilāvī, vigilātus"
  ],
  "dress": [
    "vestiō, vestīre, vestīvī, vestītus"
  ],
  "garment": [
    "vestīmentum -ī n",
    "indūmentum, indūmentī"
  ],
  "clothing": [
    "vestīmentum -ī n",
    "vestis -is f",
    "vestītus, vestitūs"
  ],
  "be strong": [
    "valeō, valēre, valuī, valitūrus",
    "valeō, valēre, valuī, valitūrum"
  ],
  "be well": [
    "valeō, valēre, valuī, valitūrus",
    "salveō, salvēre",
    "valeō, valēre, valuī, valitūrum"
  ],
  "each of the two": [
    "uterque utraque utrumque"
  ],
  "both": [
    "uterque utraque utrumque",
    "et...et",
    "ambō, ambae, ambō"
  ],
  "either": [
    "uter, utra, utrum",
    "aut...aut",
    "alteruter, alterutra, alterutrum"
  ],
  "tunic": [
    "tunica -ae f",
    "cotta, cottae"
  ],
  "you": [
    "tū tē tuī tibi tē",
    "vōs vōbīs",
    "tē",
    "istuc",
    "tū, tuī"
  ],
  "yourself": [
    "tū tē tuī tibi tē",
    "tē",
    "ipse, ipsa, ipsum"
  ],
  "wearing the toga": [
    "togātus -a -um"
  ],
  "toga": [
    "toga -ae f"
  ],
  "writing-tablet": [
    "tabula -ae f",
    "tabella -ae f"
  ],
  "rise": [
    "surgō, surgere, surrēxī, -----",
    "orior, orīrī, ortus sum",
    "clīvus, clīvī",
    "excellō, excellere, excelluī, excelsum"
  ],
  "get up": [
    "surgō, surgere, surrēxī, -----",
    "Surge",
    "surgō, surgere, surrēxī, surrēctum"
  ],
  "stylus": [
    "stilus -ī m"
  ],
  "dirty": [
    "sordidus -a -um",
    "impūrus, impūra, impūrum",
    "spurcus, spurca, spurcum",
    "immundus, immunda, immundum"
  ],
  "mean": [
    "sordidus -a -um",
    "significō, significāre, significāvī, significātus",
    "illīberālis, illīberāle"
  ],
  "base": [
    "sordidus -a -um",
    "basis, basis",
    "turpis, turpe"
  ],
  "be accustomed": [
    "soleō, solēre, solitus sum"
  ],
  "left": [
    "sinister -tra -trum",
    "laevus -a -um",
    "reliquus, reliqua, reliquum",
    "Left",
    "laevus",
    "sinister",
    "restō, restāre, restitī, —",
    "sinister, sinistra, sinistrum",
    "supersum, superesse, superfuī, ------"
  ],
  "f the left": [
    "sinister -tra -trum"
  ],
  "thing": [
    "rēs reī f",
    "rēs, reī",
    "rēs, reī (f)"
  ],
  "matter": [
    "rēs reī f",
    "rēs, reī",
    "rēs, reī (f)"
  ],
  "affair": [
    "rēs reī f",
    "rēs, reī",
    "rēs, reī (f)"
  ],
  "ruler": [
    "rēgula -ae f",
    "moderātor, moderātōris"
  ],
  "in what way": [
    "quōmodo"
  ],
  "clean": [
    "pūrus -a -um",
    "mundus -a -um",
    "sincērus, sincēra, sincērum",
    "pūrgō, pūrgāre, pūrgāvī, pūrgātum"
  ],
  "pure": [
    "pūrus -a -um",
    "merus -a -um",
    "merus, mera, merum",
    "pudīcus, pudīca, pudīcum",
    "sincērus, sincēra, sincērum"
  ],
  "past": [
    "praeter",
    "praeteritus -a -um",
    "praeter (+ acc.)"
  ],
  "besides": [
    "praeter",
    "praetereā",
    "cēterum",
    "praeterquam",
    "praeter (+ acc.)"
  ],
  "except": [
    "praeter",
    "nisi",
    "nisi (conj)",
    "praeter (+ acc.)",
    "excipiō, excipere, excēpī, exceptum"
  ],
  "demand": [
    "pōscō, pōscere, popōscī, ----",
    "postulō, postulāre, postulāvī, postulātus",
    "dēposcō, dēposcere, dēpoposcī",
    "repetō, repetere, repetīvī, repetītum",
    "flāgitō, flāgitāre, flāgitāvī, flāgitātum",
    "efflāgitō, efflāgitāre, efflāgitāvī, efflāgitātum"
  ],
  "call for": [
    "pōscō, pōscere, popōscī, ----",
    "acciō, accīre, accīvī, accītum"
  ],
  "parents": [
    "parentēs -um m pl"
  ],
  "every": [
    "omnis -e",
    "omnis, omne"
  ],
  "naked": [
    "nūdus -a -um"
  ],
  "nothing": [
    "nihil",
    "nihil / nīl",
    "nihildum",
    "nihil (n. indeclinable)"
  ],
  "much": [
    "multum -ī",
    "multō +comp",
    "multa, multae",
    "multus, multa, multum"
  ],
  "dip": [
    "mergō, mergere, mersī, mersum",
    "imbuō, imbuere, imbuī, imbūtum"
  ],
  "plunge": [
    "mergō, mergere, mersī, mersum"
  ],
  "sink": [
    "mergō, mergere, mersī, mersum",
    "occidō, occidere, occidī, occāsum",
    "submergō, submergere, submersī, submersus",
    "dēlābor, dēlābī, dēlapsus sum",
    "succumbō, succumbere, succubuī, succubitum"
  ],
  "wash": [
    "lavō, lavāre, lāvī, lautum"
  ],
  "bathe": [
    "lavō, lavāre, lāvī, lautum"
  ],
  "says": [
    "inquit -iunt"
  ],
  "said": [
    "inquit -iunt"
  ],
  "put on": [
    "induō, induere, induī, indūtum"
  ],
  "today": [
    "hodiē"
  ],
  "wear": [
    "gerō, gerere, gessī, gestum"
  ],
  "carry on": [
    "gerō, gerere, gessī, gestum",
    "exerceō, exercēre, exercuī, exercitum"
  ],
  "manage": [
    "gerō, gerere, gessī, gestum",
    "afficiō, afficere, affēcī, affectum",
    "prōcūrō, prōcūrāre, prōcūrāvī, prōcūrātum",
    "administrō, administrāre, administrāvī, administrātum"
  ],
  "govern": [
    "gerō, gerere, gessī, gestum",
    "gubernō, gubernāre, gubernāvī, gubernātus",
    "regō, regere, rēxī, rēctum"
  ],
  "cock": [
    "gallus -ī m"
  ],
  "rooster": [
    "gallus -ī m"
  ],
  "be cold": [
    "frīgeō, frīgēre, -----, -----"
  ],
  "wake up": [
    "excitō, excitāre, excitāvī, excitātum",
    "suscitō, suscitāre, suscitāvī, suscitātus",
    "ēvigilō, ēvigilāre, ēvigilāvī, ēvigilātum"
  ],
  "arouse": [
    "excitō, excitāre, excitāvī, excitātum",
    "moveō, movēre, mōvī, mōtum"
  ],
  "right": [
    "dexter -tra -trum",
    "iūs iūris n",
    "Bene",
    "Right",
    "dexter",
    "rectus",
    "iūs, iūris",
    "Recte dicis",
    "iūstus, iūsta, iūstum",
    "dexter, dextra, dextrum",
    "oportet, oportēre, oportuit,"
  ],
  "f the right": [
    "dexter -tra -trum"
  ],
  "afterward": [
    "deinde / dein",
    "posteā"
  ],
  "next": [
    "deinde / dein",
    "posterus -a -um",
    "dehinc",
    "deinde",
    "Proximum"
  ],
  "closed": [
    "clausus -a -um"
  ],
  "shoe": [
    "calceus -ī m",
    "calciamentum, calciamentī"
  ],
  "till now": [
    "adhūc"
  ],
  "still": [
    "adhūc",
    "tranquillus -a -um",
    "etiamnunc",
    "atquī",
    "tamen",
    "etiamnum",
    "placidus, placida, placidum",
    "sēdō, sēdāre, sēdāvī, sēdātum",
    "sēdō, sēdāre, sēdāvī, sēdātus"
  ],
  "yourselves": [
    "vōs vōbīs"
  ],
  "rod": [
    "virga -ae f",
    "tālea, tāleae"
  ],
  "true": [
    "vērus -a -um",
    "vērum",
    "Verum",
    "vērus, vēra, vērum",
    "firmus, firma, firmum"
  ],
  "n truth": [
    "vērus -a -um"
  ],
  "back": [
    "tergum -ī n",
    "dorsum -ī n",
    "retrō",
    "Reveni",
    "re- or red-",
    "re- or (before words beginning with d) red-"
  ],
  "silent": [
    "tacitus -a -um",
    "mūtus -a -um",
    "reticeō, reticēre, reticuī",
    "sileō, silēre, siluī, -----",
    "taceō, tacēre, tacuī, tacitum",
    "taceō, tacēre, tacuī, tacitus",
    "taciturnus, taciturna, taciturnum"
  ],
  "at once": [
    "statim",
    "prōtinus"
  ],
  "stern": [
    "sevērus -a -um",
    "puppis -is f",
    "dūrus, dūra, dūrum",
    "torvus, torva, torvum"
  ],
  "stool": [
    "sella -ae f",
    "scamnum -ī n"
  ],
  "chair": [
    "sella -ae f",
    "Sella",
    "Sellam",
    "sēdēs, sēdis",
    "solium, solii"
  ],
  "go back": [
    "redeō, redīre, rediī, reditum",
    "revertor, revertī, reversus sum",
    "recēdō, recēdere, recessī, recessus"
  ],
  "return": [
    "redeō, redīre, rediī, reditum",
    "revertor, revertī, reversus sum",
    "referō, referre, rettulī, relātus",
    "Reveni",
    "reditus, reditūs",
    "regressus, regressūs",
    "reversiō, reversiōnis",
    "revolvō, revolvere, revolvī, revolūtum"
  ],
  "give back": [
    "reddō, reddere, reddidī, redditum",
    "retribuō, retribuere, retribuī, retribūtum"
  ],
  "read aloud": [
    "recitō, recitāre, recitāvī, recitātus",
    "recitō, recitāre, recitāvī, recitātum"
  ],
  "punish": [
    "pūniō, pūnīre, pūnīvī, pūnītus",
    "castrō, castrāre, castrāvī, castrātum"
  ],
  "front-": [
    "prior, prior, prius"
  ],
  "back-": [
    "posterior -ius comp"
  ],
  "hind-": [
    "posterior -ius comp"
  ],
  "we": [
    "nōs nōbīs",
    "Nos"
  ],
  "us": [
    "nōs nōbīs"
  ],
  "ourselves": [
    "nōs nōbīs"
  ],
  "not yet": [
    "Nondum",
    "nōndum",
    "hauddum",
    "Non dum"
  ],
  "if not": [
    "nisi"
  ],
  "unless": [
    "nisi",
    "nisi (conj)"
  ],
  "trouble": [
    "malum -ī n",
    "Difficultas",
    "molestia, molestiae",
    "trībulātiō, trībulātiōnis",
    "difficultās, difficultātis"
  ],
  "harm": [
    "malum -ī n",
    "noxa, noxae",
    "nocumentum, nocumentī",
    "calamitās, calamitātis",
    "dētrīmentum, dētrīmentī",
    "noceō, nocēre, nocuī, nocitum + dat"
  ],
  "schoolmaster": [
    "magister magistrī",
    "magister, magistrī",
    "magister, magistrī (m)"
  ],
  "teacher": [
    "magister magistrī",
    "magister, magistrī",
    "magistra, magistrae",
    "ērudītor, ērudītōris",
    "magister, magistrī (m)",
    "magistra, magistrae (f)",
    "praeceptor, praeceptōris"
  ],
  "game": [
    "lūdus -ī",
    "lūdus, lūdī",
    "lūsus, lūsūs",
    "lūdus, lūdī (m)"
  ],
  "school": [
    "lūdus -ī",
    "lūdus, lūdī",
    "lūdus, lūdī (m)"
  ],
  "it is allowed": [
    "licēre: licet +dat"
  ],
  "one may": [
    "licēre: licet +dat",
    "licet, licēre, licuit,",
    "licet, licēre, licuit, (impers., + dat. + inf.)"
  ],
  "lower": [
    "īnferior -ius",
    "īnferus -a -um"
  ],
  "inferior": [
    "īnferior -ius"
  ],
  "cry out": [
    "exclāmō, exclāmāre, exclāmāvī, exclāmātus",
    "vōciferor, vōciferārī, vōciferātus sum",
    "reclāmō, reclāmāre, reclāmāvī, reclāmātum"
  ],
  "exclaim": [
    "exclāmō, exclāmāre, exclāmāvī, exclāmātus",
    "vōciferor, vōciferārī, vōciferātus sum",
    "reclāmō, reclāmāre, reclāmāvī, reclāmātum"
  ],
  "at home": [
    "domī"
  ],
  "pupil": [
    "discipulus -ī m",
    "cora, corae",
    "discipula, discipulae",
    "discipulus, discipulī",
    "discipula, discipulae (f)",
    "discipulus, discipulī (m)"
  ],
  "disciple": [
    "discipulus -ī m"
  ],
  "finish": [
    "dēsinō, dēsinere, dēsiī, dēsitus",
    "fīniō, fīnīre, fīnīvī, fīnītus",
    "conclūdō, conclūdere, conclūsī, conclūsum"
  ],
  "sit down": [
    "cōnsīdō, cōnsīdere, cōnsēdī, cōnsessus",
    "īnsideō, īnsidēre, īnsēdī, īnsessum"
  ],
  "be seated": [
    "cōnsīdō, cōnsīdere, cōnsēdī, cōnsessus"
  ],
  "encamp": [
    "cōnsīdō, cōnsīdere, cōnsēdī, cōnsessus"
  ],
  "stay": [
    "cōnsīdō, cōnsīdere, cōnsēdī, cōnsessus",
    "maneō, manēre, mānsī, mānsum",
    "pālus, pāli",
    "mānsiō, mānsiōnis",
    "adminiculum, adminiculī",
    "firmāmentum, firmāmentī",
    "obsideō, obsidēre, obsēdī, obsessum",
    "remaneō, remanēre, remānsī, remānsum",
    "stabiliō, stabilīre, stabilīvī, stabilītum"
  ],
  "really": [
    "vērō",
    "rēapse",
    "Re vera",
    "realiter"
  ],
  "dread": [
    "vereor, verērī, veritus sum",
    "metus, metūs",
    "metus, metūs (m)",
    "metuō, metuere, metuī",
    "exhorreō, exhorrēre, exhorruī",
    "horreō, horrēre, horruī, horruitum",
    "reformīdō, reformīdāre, reformīdāvī, reformīdātum"
  ],
  "respect": [
    "vereor, verērī, veritus sum",
    "revereor, reverēri, reveritus sum"
  ],
  "revere": [
    "vereor, verērī, veritus sum",
    "veneror, venerārī, venerātus sum",
    "revereor, reverēri, reveritus sum"
  ],
  "wind": [
    "ventus -ī m",
    "Ventus",
    "ventus, ventī",
    "ventus, ventī (m.)",
    "sinuō, sinuāre, sinuāvī, sinuātum"
  ],
  "agitated": [
    "turbidus -a -um",
    "trepidus, trepida, trepidum"
  ],
  "stormy": [
    "turbidus -a -um",
    "hiemō, hiemāre, hiemāvī, hiemātum",
    "turbulentus, turbulenta, turbulentum"
  ],
  "stir up": [
    "turbō, turbāre, turbāvī, turbātus",
    "misceō, miscēre, miscuī, mixtum",
    "cōnfundō, cōnfundere, cōnfūdī, cōnfūsum"
  ],
  "agitate": [
    "turbō, turbāre, turbāvī, turbātus",
    "quatiō, quatere, ----, quassum",
    "vibrō, vibrāre, vibrāvī, vibrātum",
    "sollicitō, sollicitāre, sollicitāvī, sollicitātum"
  ],
  "thunder": [
    "tonitrus -ūs m",
    "tonitruum, tonitruī",
    "tonō, tonāre, tonuī, tonitum",
    "fulmineus, fulminea, fulmineum",
    "intonō, intonāre, intonuī, intonātum"
  ],
  "storm": [
    "tempestās -ātis f",
    "Tempestas",
    "procella, procellae",
    "tempestās, tempestātis"
  ],
  "upper": [
    "superus -a -um",
    "superior -ius comp",
    "aethra, aethrae",
    "superus, supera, superum",
    "supernus, superna, supernum"
  ],
  "or if": [
    "sī-ve/seu",
    "seu"
  ],
  "situated": [
    "situs -a -um"
  ],
  "together": [
    "simul",
    "ūnā adv",
    "commūniter, commūnius, commūnissimē"
  ],
  "at the same time": [
    "simul"
  ],
  "preserve": [
    "servō, servāre, servāvī, servātus",
    "servō, servāre, servāvī, servātum",
    "salūtō, salūtāre, salūtāvī, salūtātum",
    "cōnservō, cōnservāre, cōnservāvī, cōnservātum"
  ],
  "save": [
    "servō, servāre, servāvī, servātus",
    "salvō, salvāre, salvāvī, salvātum",
    "Tueri",
    "servō, servāre, servāvī, servātum",
    "reservō, reservāre, reservāvī, reservātum"
  ],
  "fair": [
    "serēnus, serēna, serēnum",
    "iūstus -a -um"
  ],
  "cloudless": [
    "serēnus, serēna, serēnum"
  ],
  "tranquil": [
    "serēnus, serēna, serēnum",
    "sēcūrus, sēcūra, sēcūrum"
  ],
  "serene": [
    "serēnus, serēna, serēnum"
  ],
  "cheerful": [
    "serēnus, serēna, serēnum",
    "hilaris, hilare",
    "alacer, alacris, alacre"
  ],
  "follow": [
    "sequor, sequī, secūtus sum",
    "cōnsequor, cōnsequī, cōnsecūtus sum",
    "persequor, persequī, persecūtus sum",
    "Sequere",
    "īnsequor, īnsequī, īnsecūtus sum",
    "comitō, comitāre, comitāvī, comitātum"
  ],
  "north": [
    "septentriōnēs -um m pl"
  ],
  "the seven oxen": [
    "septentriōnēs -um m pl"
  ],
  "the plough": [
    "septentriōnēs -um m pl"
  ],
  "always": [
    "semper",
    "usque",
    "usque (adv.)"
  ],
  "poop": [
    "puppis -is f"
  ],
  "because of": [
    "propter",
    "propter (+acc.)"
  ],
  "set out": [
    "proficīscor, proficīscī, profectus sum"
  ],
  "harbor": [
    "portus -ūs m"
  ],
  "a little": [
    "paulō",
    "paulum",
    "paulō post",
    "paulō (+ comp)",
    "paullum, paulli"
  ],
  "appear": [
    "orior, orīrī, ortus sum",
    "appāreō, appārēre, appāruī, appāritūrus",
    "videor, vidērī, vīsus sum",
    "oborior, oborīrī, obortus sum",
    "compāreō, compārēre, compāruī, compāritum"
  ],
  "east": [
    "oriēns -entis m",
    "eurus, eurī"
  ],
  "border": [
    "ōra -ae f",
    "līmes, līmitis",
    "margō, marginis",
    "fimbria, fimbriae",
    "līmitāneus, līmitānea, līmitāneum",
    "praetexō, praetexere, praetexuī, praetextum"
  ],
  "coast": [
    "ōra -ae f",
    "lītus, lītoris",
    "lītus, lītoris (n)"
  ],
  "await": [
    "opperior, opperīrī, opperītus sum",
    "exspectō, exspectāre, exspectāvī, exspectātum",
    "opperior, opperirī, opperītus sum, oppertus sum"
  ],
  "set": [
    "occidō, occidere, occidī, occāsum",
    "pōnō, pōnere, posuī, positum",
    "indūrēscō, indūrēscere, indūruī",
    "dēpōnō, dēpōnere, dēposuī, dēpositum",
    "plantō, plantāre, plantāvī, plantātum"
  ],
  "west": [
    "occidēns -entis m"
  ],
  "ship": [
    "nāvis -is f",
    "nāvis, nāvis",
    "nāvigium, nāvigiī",
    "vehiculum, vehiculī"
  ],
  "sailor": [
    "nauta -ae m",
    "Nauta",
    "nauta, nautae",
    "nāvita, nāvitae",
    "nauta, nautae (m)"
  ],
  "by far": [
    "multō +comp",
    "longē"
  ],
  "commodity": [
    "merx -rcis f"
  ],
  "pl goods": [
    "merx -rcis f"
  ],
  "sea-": [
    "maritimus -a -um"
  ],
  "coastal": [
    "maritimus -a -um"
  ],
  "talk": [
    "loquor, loquī, locūtus sum",
    "sermō, sermōnis",
    "colloquor, colloquī, collocūtus sum",
    "fābulor, fābulārī, fābulātus sum",
    "Loquere",
    "Adloquere",
    "dictiō, dictiōnis",
    "for, fārī, fātus sum"
  ],
  "rejoice": [
    "laetor, laetārī, laetātus sum",
    "gaudeō, gaudēre, gāvīsus sum"
  ],
  "slip": [
    "lābor, lābī, lāpsus sum",
    "lāpsō, lāpsāre, lāpsāvī, lāpsātum"
  ],
  "drop": [
    "lābor, lābī, lāpsus sum",
    "gutta, guttae",
    "dēcidō, dēcidere, dēcidī, dēcāsum"
  ],
  "a second time": [
    "iterum",
    "iterum (adv.)"
  ],
  "call upon": [
    "invocō, invocāre, invocāvī, invocātus",
    "inclāmō, inclāmāre, inclāmāvī, inclāmātum"
  ],
  "invoke": [
    "invocō, invocāre, invocāvī, invocātus",
    "advocō, advocāre, advocāvī, advocātum",
    "inclāmō, inclāmāre, inclāmāvī, inclāmātum"
  ],
  "be between": [
    "intersum, interesse, interfuī, interfutūrus"
  ],
  "flow into": [
    "īnfluō, īnfluere, īnfluxī, īnfluxus"
  ],
  "fill": [
    "impleō, implēre, implēvī, implētum",
    "compleō, complēre, complēvī, complētum",
    "Comple",
    "pleō, plēre, plēvī, plētum",
    "expleō, explēre, explēvī, explētum",
    "occupō, occupāre, occupāvī, occupātum"
  ],
  "complete": [
    "impleō, implēre, implēvī, implētum",
    "efficiō, efficere, effēcī, effectum",
    "perficiō, perficere, perfēcī, perfectum",
    "compleō, complēre, complēvī, complētum",
    "complētus, complēta, complētum",
    "exigō, exigere, exēgī, exāctum",
    "expleō, explēre, explēvī, explētum",
    "suppleō, supplēre, supplēvī, supplētum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "toss about": [
    "iactō, iactāre, iactāvī, iactātus"
  ],
  "bail": [
    "hauriō, haurīre, hausī, haustum"
  ],
  "steersman": [
    "gubernātor -ōris m"
  ],
  "steer": [
    "gubernō, gubernāre, gubernāvī, gubernātus",
    "dērigō, dērigere, dērexī, dērectum"
  ],
  "flash of lightning": [
    "fulgur -uris n"
  ],
  "wave": [
    "flūctus -ūs",
    "unda, undae",
    "fluctus, fluctūs"
  ],
  "blow": [
    "flō, flāre, flāvī, flātus",
    "īctus, īctūs",
    "offēnsus, offēnsūs",
    "petītiō, petītiōnis"
  ],
  "be made": [
    "fiō, fierī, factum esse",
    "fīō, fierī, factus sum",
    "fiō, fierī, factum esse (fiat, fiunt)"
  ],
  "be done": [
    "fiō, fierī, factum esse",
    "fīō, fierī, factus sum",
    "fiō, fierī, factum esse (fiat, fiunt)"
  ],
  "become": [
    "fiō, fierī, factum esse",
    "decet: decēre",
    "fīō, fierī, factus sum",
    "fiō, fierī, factum esse (fiat, fiunt)"
  ],
  "happen": [
    "fiō, fierī, factum esse",
    "accidō, accidere, accidī, ----",
    "fīō, fierī, factus sum",
    "incidō incidere incidī",
    "ēveniō, ēvenīre, ēvēnī, ēventum",
    "fiō, fierī, factum esse (fiat, fiunt)"
  ],
  "opposite": [
    "contrārius -a -um",
    "adversus -a -um",
    "adversus, adversa, adversum"
  ],
  "contrary": [
    "contrārius -a -um",
    "adversus -a -um"
  ],
  "comfort": [
    "cōnsōlor, cōnsōlārī, cōnsōlātus sum",
    "sōlācium, sōlāciī",
    "sōlācium, sōlāciī (n)",
    "foveō, fovēre, fōvī, fōtum",
    "sōlor, sōlārī, sōlātus sum",
    "cōnsōlō, cōnsōlāre, cōnsōlāvī, cōnsōlātum"
  ],
  "console": [
    "cōnsōlor, cōnsōlārī, cōnsōlātus sum",
    "sōlor, sōlārī, sōlātus sum",
    "cōnsōlō, cōnsōlāre, cōnsōlāvī, cōnsōlātum"
  ],
  "board": [
    "cōnscendō, cōnscendere, cōnscendī, cōnscēnsus"
  ],
  "attempt": [
    "cōnor, cōnārī, cōnātus sum",
    "cōnātus, cōnātūs"
  ],
  "try": [
    "cōnor, cōnārī, cōnātus sum",
    "experior, experīrī, expertus sum",
    "perīclitor, perīclitārī, perīclitātus sum",
    "pertemptō, pertemptāre, pertemptāvī, pertemptātum"
  ],
  "embrace": [
    "complector, complectī, complexus sum",
    "amplexus, amplexūs",
    "amplexor, amplexārī, amplexātus sum",
    "comprehensō, comprehensāre, comprehensāvī, comprehensātum"
  ],
  "hug": [
    "complector, complectī, complexus sum"
  ],
  "seize": [
    "complector, complectī, complexus sum",
    "prehendō, prehendere, prehendī, prehēnsum",
    "apprehendō, apprehendere, apprehendī, apprehēnsum",
    "capiō, capere, cēpī, captum",
    "rapiō, rapere, rapuī, raptum",
    "carpō, carpere, carpsī, carptum",
    "capessō, capessere, capessīvī, capessitum",
    "pūblicō, pūblicāre, pūblicāvī, pūblicātum",
    "dēprehendō, dēprehendere, dēprehendī, dēprehēnsum"
  ],
  "grasp": [
    "complector, complectī, complexus sum",
    "prehendō, prehendere, prehendī, prehēnsum",
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "discern": [
    "cernō, cernere, crēvī, crētus",
    "cernō, cernere, crēvī, crētum"
  ],
  "perceive": [
    "cernō, cernere, crēvī, crētus",
    "cernō, cernere, crēvī, crētum",
    "sentiō, sentīre, sēnsī, sēnsum",
    "observō, observāre, observāvī, observātum"
  ],
  "address": [
    "appellō, appellāre, appellāvī, appellātus",
    "congredior, congredī, congressus sum",
    "appellō, appellāre, appellāvī, appellātum"
  ],
  "the open sea": [
    "altum -ī n",
    "pontus, pontī"
  ],
  "all the way": [
    "ūsque",
    "usque",
    "usque (adv.)"
  ],
  "all the time": [
    "ūsque"
  ],
  "continuously": [
    "ūsque",
    "usque",
    "usque (adv.)"
  ],
  "at every point": [
    "ūsque"
  ],
  "nineteen": [
    "ūndēvīgintī"
  ],
  "thirteen": [
    "trēdecim",
    "tredecim"
  ],
  "so many": [
    "tot",
    "tot, tot ... quot",
    "tot (indecl. adj.), tot ... quot"
  ],
  "raise": [
    "tollō, tollere, sustulī, sublātum",
    "levō, levāre, levāvī, levātus",
    "altō, altāre",
    "ērigō, ērigere, ērexī, ērectum",
    "relevō, relevāre, relevāvī, relevātum",
    "subdūcō, subdūcere, subdūxī, subductum"
  ],
  "lift": [
    "tollō, tollere, sustulī, sublātum",
    "levō, levāre, levāvī, levātus"
  ],
  "pick up": [
    "tollō, tollere, sustulī, sublātum",
    "Sume"
  ],
  "remove": [
    "tollō, tollere, sustulī, sublātum",
    "removeō, removēre, remōvī, remōtus",
    "dēmō, dēmere, dēmpsī, dēmptus",
    "Remove",
    "efferō, efferre, extulī, ēlātum",
    "āvocō, āvocāre, āvocāvī, āvocātum",
    "spernō, spernere, sprēvī, sprētum",
    "dēmoveō, dēmovēre, dēmōvī, dēmōtum",
    "āmandō, āmandāre, āmandāvī, āmandātum",
    "dērogō, dērogāre, dērogāvī, dērogātum",
    "exclūdō, exclūdere, exclūsī, exclūsum",
    "commoveō, commovēre, commōvī, commōtum",
    "extrahō, extrahere, extraxī, extractum",
    "summoveō, summovēre, summōvī, summōtum",
    "asportō, asportāre, asportāvī, asportātum",
    "abaliēnō, abaliēnāre, abaliēnāvī, abaliēnātum"
  ],
  "take away": [
    "tollō, tollere, sustulī, sublātum",
    "auferō, auferre, abstulī, ablātum",
    "abdūcō, abdūcere, abdūxī, abductus",
    "adimō, adimere, adēmī, ademptum",
    "efferō, efferre, extulī, ēlātum",
    "ēripiō, ēripere, ēripuī, ēreptum",
    "abripiō, abripere, abripuī, abreptum",
    "absūmō, absūmere, absūmpsī, absūmptum",
    "dērogō, dērogāre, dērogāvī, dērogātum",
    "ēripiō, ēripere, ēripuī, ēreptum (ē + rapiō)",
    "dēprehendō, dēprehendere, dēprehendī, dēprehēnsum"
  ],
  "six hundred": [
    "sescentī -ae -a"
  ],
  "seventy": [
    "septuāgintā"
  ],
  "seven hundred": [
    "septingentī -ae -a"
  ],
  "seventeen": [
    "septen-decim",
    "septendecim"
  ],
  "sixteen": [
    "sēdecim"
  ],
  "know": [
    "sciō, scīre, scīvī, scītum",
    "nōvisse",
    "nōvisse (< nōscere)",
    "gnōscō, gnōscere, gnōvī, gnōtum",
    "cognōscō, cognōscere, cognōvī, cognitum"
  ],
  "answer": [
    "respōnsum -ī n",
    "respōnsiō, respōnsiōnis",
    "referō, referre, rettulī, relātum",
    "respondeō, respondēre, respondī, respōnsum",
    "rēspondeō, rēspondēre, rēspondī, rēsponsum"
  ],
  "blame": [
    "reprehendō, reprehendere, reprehendī, reprehēnsus",
    "culpa, culpae",
    "culpa, culpae (f)",
    "reprehensiō, reprehensiōnis",
    "culpō, culpāre, culpāvī, culpātum",
    "incūsō, incūsāre, incūsāvī, incūsātum",
    "īnsimulō, īnsimulāre, īnsimulāvī, īnsimulātum"
  ],
  "censure": [
    "reprehendō, reprehendere, reprehendī, reprehēnsus",
    "culpō, culpāre, culpāvī, culpātum",
    "vituperō, vituperāre, vituperāvī, vituperātum"
  ],
  "hold back": [
    "reprehendō, reprehendere, reprehendī, reprehēnsus",
    "retineō, retinēre, retinuī, retentum"
  ],
  "restrain": [
    "reprehendō, reprehendere, reprehendī, reprehēnsus",
    "teneō, tenēre, tenuī, tentum",
    "inhibeō, inhibēre, inhibuī, inhibitum",
    "contineō, continēre, continuī, contentum",
    "cōnfūtō, cōnfūtāre, cōnfūtāvī, cōnfūtātum",
    "prohibeō, prohibēre, prohibuī, prohibitum"
  ],
  "put back": [
    "repōnō, repōnere, reposuī, repositus"
  ],
  "straight": [
    "rēctus -a -um",
    "rēctā",
    "rēctā viā",
    "prorsus, prorsa, prorsum"
  ],
  "correct": [
    "rēctus -a -um",
    "corrigō, corrigere, corrēxī, corrēctum",
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum",
    "corrigō, corrigere, corrēxī , corrēctum"
  ],
  "each": [
    "quisque quaeque quodque",
    "singulī -ae -a"
  ],
  "each one": [
    "quisque quaeque quodque",
    "quisque, quidque,",
    "quisque, quidque, (gen: cuiusque; dat. cuique)"
  ],
  "everyone": [
    "quisque quaeque quodque"
  ],
  "whoever": [
    "quisque quaeque quodque",
    "quisquis",
    "quisquis, quidquid",
    "quīcumque quaecumque quodcumque"
  ],
  "whatever": [
    "quisque quaeque quodque",
    "quidquid",
    "Non curo",
    "quisquis, quidquid",
    "quīvīs, quaevīs, quodvīs",
    "quīcumque quaecumque quodcumque"
  ],
  "fifty": [
    "quīnquāgintā"
  ],
  "five hundred": [
    "quīngentī -ae -a"
  ],
  "fifteen": [
    "quīndecim"
  ],
  "fourteen": [
    "quattuordecim"
  ],
  "although": [
    "quamquam",
    "etsī",
    "cum",
    "etiamsī",
    "quamvīs",
    "tametsī",
    "etsī (et + sī)",
    "cum (+ subjunct. or + indic.)"
  ],
  "four hundred": [
    "quadringentī -ae -a"
  ],
  "forty": [
    "quadrāgintā"
  ],
  "prudent": [
    "prūdēns -entis adi"
  ],
  "clever": [
    "prūdēns -entis adi",
    "Callide factu",
    "catus, cata, catum",
    "sollers, sollertis",
    "perītus, perīta, perītum",
    "concinnus, concinna, concinnum",
    "ingeniōsus, ingeniosa, ingeniōsum"
  ],
  "produce": [
    "prōmō, prōmere, prōmpsī, prōmptum",
    "frūgēs, frūgum",
    "prōferō, prōferre, prōtulī, prōlātus",
    "frūx, frūgis",
    "creō, creāre, creāvī, creātum",
    "efferō, efferre, extulī, ēlātum",
    "dēprōmō, dēprōmere, dēprompsī, dēpromptum",
    "perhibeō, perhibēre, perhibuī, perhibitum",
    "prōcreō, prōcreāre, prōcreāvī, prōcreātum"
  ],
  "bring out": [
    "prōmō, prōmere, prōmpsī, prōmptum",
    "ēdūcō, ēdūcere, ēdūxī, ēductus"
  ],
  "take out": [
    "prōmō, prōmere, prōmpsī, prōmptum",
    "efferō, efferre, extulī, ēlātum",
    "excipiō, excipere, excēpī, exceptum"
  ],
  "display": [
    "prōmō, prōmere, prōmpsī, prōmptum",
    "ostentātiō, ostentātiōnis",
    "ostendō, ostendere, ostendī, ostentum"
  ],
  "faulty": [
    "prāvus -a -um",
    "perperus, perpera, perperum",
    "vitiōsus, vitiōsa, vitiōsum"
  ],
  "wrong": [
    "prāvus -a -um",
    "iniūria -ae f",
    "iniūria, iniūriae",
    "iniūria, iniūriae (f.)",
    "perperus, perpera, perperum"
  ],
  "finally": [
    "postrēmō adv",
    "dēnique",
    "postrēmum",
    "dēnique (adv.)"
  ],
  "lazy": [
    "piger -gra -grum",
    "sēgnis, sēgne",
    "īgnāvus, īgnāva, īgnāvum"
  ],
  "share": [
    "partior, partīrī, partītus sum",
    "pars, partis",
    "portiō, portiōnis",
    "partiō, partīre, partīvī, partītum",
    "impertiō, impertīre, impertīvī, impertītum",
    "commūnicō, commūnicāre, commūnicāvī, commūnicātum"
  ],
  "it is right": [
    "oportēre: oportet"
  ],
  "you should": [
    "oportēre: oportet"
  ],
  "eight hundred": [
    "octingentī, octingentae, octingenta"
  ],
  "never": [
    "numquam",
    "Never"
  ],
  "nine hundred": [
    "nōngentī -ae -a"
  ],
  "not know": [
    "nesciō, nescīre, nescīvī, -----",
    "ignōrō, ignōrāre, ignōrāvī, ignōrātum",
    "nesciō, nescīre, nescīvī, nescītum",
    "īgnōrō, īgnōrāre, īgnōrāvī, īgnōrātum"
  ],
  "to praise": [
    "laudō, laudāre, laudāvī, laudātus",
    "laudō, laudāre, laudāvī, laudātum"
  ],
  "generous": [
    "largus -a -um",
    "līberālis, līberāle",
    "plēnus, plēna, plēnum",
    "mūnificus, mūnifica, mūnificum"
  ],
  "lavish": [
    "largus -a -um",
    "prōdigus, prōdiga, prōdigum",
    "profūsus, profūsa, profūsum"
  ],
  "plentiful": [
    "largus -a -um",
    "cōpiōsus, cōpiōsa, cōpiōsum"
  ],
  "give generously": [
    "largior, largīrī, largītus sum"
  ],
  "interrupt": [
    "interpellō, interpellāre, interpellāvī, interpellātum",
    "suspendō, suspendere, suspendī, suspēnsum",
    "intermittō, intermittere, intermisī, intermissum"
  ],
  "unfriendly": [
    "inimīcus -a -um"
  ],
  "industrious": [
    "industrius -a -um",
    "impiger, impigra, impigrum",
    "sēdulus, sēdula, sēdulum"
  ],
  "ignorant": [
    "indoctus -a -um",
    "ignārus -a -um",
    "īnscius, īnscia, īnscium",
    "nescius, nescia, nescium",
    "īnscītus, īnscīta, īnscītum",
    "nesciō, nescīre, nescīvī, nescītum"
  ],
  "uncertain": [
    "incertus -a -um",
    "incertus, incerta, incertum"
  ],
  "easy": [
    "facilis -e",
    "levis, leve",
    "facilis, facile"
  ],
  "eighteen": [
    "duodēvīgintī",
    "duodēvigintī"
  ],
  "learned": [
    "doctus -a -um",
    "doctus, docta, doctum",
    "litterātus, litterāta, litterātum"
  ],
  "skilled": [
    "doctus -a -um",
    "sollers, sollertis",
    "doctus, docta, doctum",
    "perītus, perīta, perītum"
  ],
  "teach": [
    "doceō, docēre, docuī, doctum",
    "ēdoceō, ēdocēre, ēdocuī, ēdoctum",
    "trādo, trādere, trādidī, trāditum",
    "trādo, trādere, trādidī, trāditum (trāns + dō)"
  ],
  "instruct": [
    "doceō, docēre, docuī, doctum",
    "ērudiō, ērudīre, ērudīvī, ērudītum"
  ],
  "learn": [
    "discō, discere, didicī, ----",
    "Disce",
    "discō, discere, didicī",
    "reperiō, reperīre, repperī, repertum",
    "inaudiō, inaudīre, inaudīvī, inauditum",
    "cognōscō, cognōscere, cognōvī, cognitum",
    "cōgnōscō, cōgnōscere, cōgnōvī, cōgnitum",
    "comperiō, comperīre, comperī, compertum"
  ],
  "difficult": [
    "difficilis, difficile",
    "arduus -a -um",
    "dūrus, dūra, dūrum",
    "mōrōsus, mōrōsa, mōrōsum"
  ],
  "hard": [
    "difficilis, difficile",
    "dūrus -a -um",
    "arduus -a -um",
    "dūrus, dūra, dūrum",
    "rigidus, rigida, rigidum"
  ],
  "denarius": [
    "dēnārius -ī m"
  ],
  "calculate": [
    "computō, computāre, computāvī, computātus"
  ],
  "reckon": [
    "computō, computāre, computāvī, computātus",
    "exīstimō, exīstimāre, exīstimāvī, exīstimātum",
    "putō, putāre, putāvī, putātum",
    "imputō, imputāre, imputāvī, imputātum",
    "adnumerō, adnumerāre, adnumerāvī, adnumerātum"
  ],
  "certain": [
    "certus -a -um",
    "certus, certa, certum",
    "quīdam, quaedam, quoddam"
  ],
  "sure": [
    "Certe",
    "certus -a -um",
    "Sic",
    "Sure",
    "Certea",
    "vērō (adv.)",
    "stabilis, stabile",
    "certus, certa, certum"
  ],
  "hundredth": [
    "centēsimus -a -um",
    "centēsima, centēsimae"
  ],
  "friendly": [
    "amīcus -a -um",
    "amīcus, amīca, amīcum",
    "socius, socia, socium",
    "benignus, benigna, benignum",
    "benevolus, benevola, benevolum"
  ],
  "equally": [
    "aequē",
    "pariter",
    "perinde",
    "aequāliter, aequālius, aequālissimē",
    "aequābiliter, aequābilius, aequābilissimē"
  ],
  "west wind": [
    "zephyrus -ī m"
  ],
  "able to speak": [
    "vōcālis -e"
  ],
  "having a voice": [
    "vōcālis -e"
  ],
  "tuneful": [
    "vōcālis -e"
  ],
  "vowel": [
    "vōcālis -e"
  ],
  "varied": [
    "varius -a -um"
  ],
  "different": [
    "varius -a -um",
    "dispār, disparis",
    "dissimilis, dissimile",
    "dīversus, dīversa, dīversum",
    "aliēnigenus, aliēnigena, aliēnigenum"
  ],
  "foul": [
    "turpis -e",
    "impūrus, impūra, impūrum",
    "pūtidus, pūtida, pūtidum",
    "spurcus, spurca, spurcum",
    "immundus, immunda, immundum",
    "polluō, polluere, polluī, pollūtum"
  ],
  "so many times": [
    "totiēs",
    "totiēns"
  ],
  "three times": [
    "ter"
  ],
  "such": [
    "tālis -e",
    "hūiusmodī",
    "istiusmodi",
    "tālis, tāle",
    "iste, ista, istud"
  ],
  "be left": [
    "supersum, superesse, superfuī, ------",
    "restō, restāre, restitī, —"
  ],
  "be in excess": [
    "supersum, superesse, superfuī, ------"
  ],
  "signify": [
    "significō, significāre, significāvī, significātus"
  ],
  "show by signs": [
    "significō, significāre, significāvī, significātus"
  ],
  "indicate": [
    "significō, significāre, significāvī, significātus",
    "portendō, portendere, portendī, portentum"
  ],
  "mark": [
    "signō, signāre, signāvī, signātus",
    "nota -ae f",
    "marcus, marcī",
    "specimen, speciminis",
    "signāculum, signāculī",
    "līneāmentum, līneāmentī",
    "notō, notāre, notāvī, notātum",
    "dēsignō, dēsignāre, dēsignāvi, dēsignātum",
    "īnsigniō, īnsignīre, īnsignīvī, īnsignītum"
  ],
  "seal": [
    "signō, signāre, signāvī, signātus",
    "signum -ī n",
    "phōca, phōcae",
    "signum, signī",
    "signum, signī (n)"
  ],
  "in this way": [
    "sīc"
  ],
  "thus": [
    "sīc",
    "ita",
    "sīc (adv. most commonly used with verbs)",
    "ita (adv. used with adj., verbs, and advs.)"
  ],
  "six times": [
    "sexiēs"
  ],
  "opinion": [
    "sententia -ae f",
    "iūdicium, iūdiciī",
    "iūdicium, iūdiciī (n)",
    "sententia, sententiae",
    "sententia, sententiae (f)",
    "existimātiō, existimātiōnis"
  ],
  "feeling": [
    "sententia -ae f",
    "affectus -ūs m",
    "sēnsus, sēnsūs",
    "sēnsus, sēnsūs (m)",
    "sententia, sententiae",
    "sententia, sententiae (f)"
  ],
  "thought": [
    "sententia -ae f",
    "mēns, mentis",
    "sententia, sententiae",
    "sententia, sententiae (f)"
  ],
  "sentence": [
    "sententia -ae f",
    "sententia, sententiae",
    "sententia, sententiae (f)",
    "condemnō, condemnāre, condemnāvī, condemnātum"
  ],
  "once": [
    "semel",
    "ōlim",
    "aliquandō",
    "quondam",
    "semel (adv.)",
    "quondam (adv.)"
  ],
  "write": [
    "scrībō, scrībere, scrīpsī, scrīptum"
  ],
  "rare": [
    "rārus -a -um",
    "īnfrequēns, īnfrequentis"
  ],
  "how many times": [
    "quotiēs"
  ],
  "five times": [
    "quīnquiēs"
  ],
  "four times": [
    "quater"
  ],
  "what sort of? what kind of": [
    "quālis -e"
  ],
  "press": [
    "premō, premere, pressī, pressum",
    "imprimō, imprimere, impressī, impressum",
    "Preme",
    "prēlum, prēlī",
    "urgeō, urgēre, ursī",
    "torcular, torculāris",
    "pressō, pressāre, pressāvī, pressātum",
    "tribulō, tribulāre, tribulāvī, tribulātum"
  ],
  "papyrus": [
    "papyrus -ī f"
  ],
  "soft": [
    "mollis -e",
    "lēnis, lēne"
  ],
  "wage": [
    "mercēs, mercēdis",
    "gerō, gerere, gessī, gestum"
  ],
  "fee": [
    "mercēs, mercēdis"
  ],
  "rent": [
    "mercēs, mercēdis"
  ],
  "mistake": [
    "mendum -ī n",
    "error, errōris"
  ],
  "error": [
    "mendum -ī n",
    "error, errōris",
    "peccātum, peccātī"
  ],
  "material": [
    "māteria -ae f",
    "māteriālis, māteriāle",
    "substantia, substantiae"
  ],
  "substance": [
    "māteria -ae f",
    "substantia, substantiae"
  ],
  "read": [
    "legō, legere, lēgī, lēctum",
    "Lege"
  ],
  "join": [
    "iungō, iungere, iūnxī, iūnctum",
    "cōpulō, cōpulāre, cōpulāvī, cōpulātus",
    "adnectō, adnectere, adnexuī, adnexum",
    "coniungō, coniungere, coniunxī, coniunctum",
    "iungō, iungere, iunxī, iūnctum",
    "sociō, sociāre, sociāvī, sociātum",
    "innectō, innectere, innexuī, innexum",
    "committō, committere, commīsī, commissum",
    "continuō, continuāre, continuāvī, continuātum"
  ],
  "combine": [
    "iungō, iungere, iūnxī, iūnctum",
    "congruō, congruere, congruī",
    "admisceō, admiscēre, admiscuī, admixtum",
    "commisceō, commiscēre, commiscuī, commixtum",
    "permisceō, permiscēre, permiscuī, permixtum, permistum"
  ],
  "in such a way": [
    "ita"
  ],
  "understand": [
    "intellegō, intellegere, intellēxī, intellēctus",
    "Intellegere",
    "videō, vidēre, vīdī, vīsum",
    "intellegō, intellegere, intellēxī, intellēctum",
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "realize": [
    "intellegō, intellegere, intellēxī, intellēctus"
  ],
  "active": [
    "impiger, impigra, impigrum",
    "ācer -cris -cre",
    "strēnuus, -a, -um",
    "nāvus, nāva, nāvum",
    "āctīvus, āctīva, āctīvum",
    "strēnuus, strēnua, strēnuum"
  ],
  "the same": [
    "īdem eadem idem",
    "īdem, eadem, idem"
  ],
  "numerous": [
    "frequēns -entis",
    "crēber, crēbra, crēbrum",
    "celeber, celebris, celebre",
    "numerōsus, numerōsa, numerōsum"
  ],
  "frequent": [
    "frequēns -entis",
    "saepis, saepe",
    "dēnsus, dēnsa, dēnsum",
    "crēber, crēbra, crēbrum",
    "celeber, celebris, celebre",
    "commeō, commeāre, commeāvī, commeātum",
    "celebrō, celebrāre, celebrāvī, celebrātum",
    "circitō, circitāre, circitāvi, circitātum",
    "frequentō, frequentāre, frequentāvī, frequentātum"
  ],
  "iron": [
    "ferrum -ī n",
    "FERRI",
    "ferrum, ferrī",
    "ferrum, ferrī (n)"
  ],
  "steel": [
    "ferrum -ī n",
    "ADAMANTEUM"
  ],
  "make out": [
    "efficiō, efficere, effēcī, effectum"
  ],
  "work out": [
    "efficiō, efficere, effēcī, effectum"
  ],
  "hence": [
    "efficiō, efficere, effēcī, effectum",
    "hinc",
    "ignōscō, ignōscere, ignōvī, ignōtus",
    "dehinc"
  ],
  "to bring to pass": [
    "efficiō, efficere, effēcī, effectum"
  ],
  "to effect": [
    "efficiō, efficere, effēcī, effectum"
  ],
  "execute": [
    "efficiō, efficere, effēcī, effectum",
    "fungor, fungī, fūnctus sum",
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "accomplish": [
    "efficiō, efficere, effēcī, effectum",
    "cōnficiō, cōnficere, cōnfēcī, cōnfectus",
    "perficiō, perficere, perfēcī, perfectum",
    "faciō, facere, fēcī, factum",
    "gerō, gerere, gessī, gestum",
    "patrō, patrāre, patrāvī, patrātum",
    "impetrō, impetrāre, impetrāvī, impetrātum"
  ],
  "dictate": [
    "dictō, dictāre, dictāvī, dictātus"
  ],
  "delete": [
    "dēleō, dēlēre, dēlēvī, dēlētum"
  ],
  "efface": [
    "dēleō, dēlēre, dēlēvī, dēlētum"
  ],
  "be missing": [
    "dēsum, dēesse, dēfuī, -----"
  ],
  "fail": [
    "dēsum, dēesse, dēfuī, -----",
    "deficiō deficere defēcī defectum",
    "offendō, offendere, offendī, offēnsum"
  ],
  "ten times": [
    "deciēs",
    "deciēns"
  ],
  "consonant": [
    "cōnsonāns, cōnsonantis"
  ],
  "compare": [
    "comparō, comparāre, comparāvī, comparātus",
    "aequō, aequāre, aequāvī, aequātum",
    "cōnferō, cōnferre, contulī, collātum",
    "contendō, contendere, contendī, contentum"
  ],
  "paper": [
    "charta -ae f"
  ],
  "certainly": [
    "certē",
    "profectō",
    "māximē",
    "quidem",
    "sānē",
    "quippe",
    "utique",
    "quidem (postpositive adv.)"
  ],
  "at any rate": [
    "certē"
  ],
  "wax": [
    "cēra -ae f"
  ],
  "reed": [
    "calamus -ī m",
    "canna, cannae",
    "iuncus, iuncī",
    "harundō, harundinis"
  ],
  "pen": [
    "calamus -ī m"
  ],
  "twice": [
    "bis"
  ],
  "small animal": [
    "bēstiola -ae f"
  ],
  "insect": [
    "bēstiola -ae f"
  ],
  "either…or": [
    "aut…aut"
  ],
  "bee": [
    "apis -is f",
    "Apem"
  ],
  "notice": [
    "animadvertō, animadvertere, animadvertī, animadversus",
    "Nuntium",
    "nōtitia, nōtitiae",
    "animadversiō, animadversiōnis"
  ],
  "add": [
    "addō, addere, addidī, additus",
    "adiciō, adicere, adiēcī, adiectus",
    "adiungō, adiungere, adiūnxī, adiūnctum",
    "Adde"
  ],
  "maiden": [
    "virgō virginis",
    "virgō, virginis",
    "virgō, virginis (f)"
  ],
  "young girl": [
    "virgō virginis"
  ],
  "wife": [
    "uxor -ōris f",
    "uxor, uxōris",
    "uxor, uxōris (f)"
  ],
  "and no": [
    "nec/neque ūllus"
  ],
  "any": [
    "ūllus -a -um",
    "aliquī, aliqua, aliquod",
    "ūllus, ūlla, ūllum"
  ],
  "temple": [
    "templum -ī n",
    "fānum, fānī",
    "dēlūbrum, dēlūbrī",
    "Templum Reclamationis"
  ],
  "roof": [
    "tēctum -ī n"
  ],
  "nevertheless": [
    "tamen",
    "attamen",
    "vērumtamen",
    "vēruntamen",
    "nihilōminus"
  ],
  "sign": [
    "signum -ī n",
    "nota -ae f",
    "signum, signī",
    "signum, signī (n)",
    "specimen, speciminis",
    "signāculum, signāculī",
    "innuō, innuere, innuī, innūtum"
  ],
  "statue": [
    "signum -ī n",
    "Statua",
    "statua, statuae"
  ],
  "send back": [
    "remittō, remittere, remīsī, remissum"
  ],
  "beauty": [
    "pulchritūdō -inis f",
    "fōrma, fōrmae",
    "decus, decoris",
    "fōrma, fōrmae (f)",
    "venustās, venustātis"
  ],
  "possess": [
    "possideō, possidēre, possēdī, possessus",
    "teneō, tenēre, tenuī, tentum",
    "habeō, habēre, habuī, habitum"
  ],
  "own": [
    "possideō, possidēre, possēdī, possessus",
    "proprius -a -um",
    "prōprius, prōpria, prōprium"
  ],
  "more": [
    "plūrēs",
    "plūs plūris",
    "magis",
    "plūs plūris (n noun and adj)"
  ],
  "most": [
    "plūrimī -ae -a sup",
    "māximē",
    "plērīque, plēraeque, plēraque",
    "plērus, plēra, plērum",
    "plūrimus, plūrima, plūrimum"
  ],
  "several": [
    "plūrēs",
    "complūrēs, complūra",
    "nōnnūllī -ae -a",
    "aliquot",
    "nōnnūllus, nōnnūlla, nōnnūllum"
  ],
  "worst": [
    "pessimus -a -um"
  ],
  "worse": [
    "pēior -ius",
    "dēterius"
  ],
  "poor": [
    "pauper -eris",
    "misellus -a -um",
    "inops, inopis",
    "pauper, gen. pauperis"
  ],
  "it is needed": [
    "opus est"
  ],
  "best": [
    "optimus -a -um"
  ],
  "very good": [
    "optimus -a -um"
  ],
  "send": [
    "mittō, mittere, mīsī, missum",
    "dēlēgō, dēlēgāre, dēlēgāvī, dēlēgātum"
  ],
  "unhappy": [
    "miser -era -erum",
    "īnfēlīx -īcis"
  ],
  "miserable": [
    "miser -era -erum",
    "miser, misera, miserum",
    "miserābilis, miserābile"
  ],
  "less": [
    "minus",
    "setīus"
  ],
  "not so well": [
    "minus"
  ],
  "diminish": [
    "minuō, minuere, minuī, minūtum",
    "absūmō, absūmere, absūmpsī, absūmptum",
    "dērogō, dērogāre, dērogāvī, dērogātum",
    "dēminuō, dēminuere, dēminuī, dēminūtum",
    "extenuō, extenuāre, extenuāvī, extenuātum"
  ],
  "reduce": [
    "minuō, minuere, minuī, minūtum",
    "attenuō, attenuāre, attenuāvī, attenuātum",
    "extenuō, extenuāre, extenuāvī, extenuātum"
  ],
  "smaller": [
    "minor, minus"
  ],
  "younger": [
    "minor, minus"
  ],
  "smallest": [
    "minimus -a -um sup"
  ],
  "youngest": [
    "minimus -a -um sup"
  ],
  "better": [
    "melior -ius"
  ],
  "biggest": [
    "māximus -a -um"
  ],
  "greatest": [
    "māximus -a -um",
    "summus -a -um"
  ],
  "oldest": [
    "māximus -a -um"
  ],
  "married woman": [
    "mātrōna -ae f"
  ],
  "bigger": [
    "māior, māior, māius"
  ],
  "older": [
    "māior, māior, māius",
    "maior, maius"
  ],
  "magnificent": [
    "magnificus -a -um"
  ],
  "splendid": [
    "magnificus -a -um",
    "lūculentus, lūculenta, lūculentum",
    "splendidus, splendida, splendidum"
  ],
  "slender": [
    "gracilis -e",
    "exīlis, exīle",
    "gracilis, gracile",
    "subtīlis, subtīle",
    "leptus, lepta, leptum"
  ],
  "an open space": [
    "forum -ī"
  ],
  "public place": [
    "forum -ī"
  ],
  "court": [
    "forum -ī",
    "cūria, cūriae",
    "cohors -rtis f"
  ],
  "market-place": [
    "forum -ī"
  ],
  "flower": [
    "flōs -ōris",
    "flōs -ōris (m)",
    "flōreō, flōrēre, flōruī"
  ],
  "house": [
    "domus, domūs",
    "HOUSE",
    "casa, casae",
    "casa, casae (f)",
    "domus, domūs (domī)"
  ],
  "home": [
    "domus, domūs",
    "domum",
    "domum (adv)",
    "penās, penātis",
    "domus, domūs (domī)",
    "domicilium, domiciliī"
  ],
  "rich": [
    "dīves, dīvitis",
    "opīmus, opīma, opīmum",
    "dīves, gen. dīvitis or dītis",
    "opulentus, opulenta, opulentum"
  ],
  "worthy": [
    "dignus -a -um",
    "dignus, digna, dignum",
    "dignus, digna, dignum (+ abl.)"
  ],
  "goddess": [
    "dea -ae f",
    "dea, deae",
    "Fidēs, Fidēī",
    "dea, deae (f)",
    "caelicola, caelicolae"
  ],
  "every day": [
    "cotīdiē",
    "cōtīdiē",
    "cōtīdiē (adv.)"
  ],
  "spouse": [
    "coniūnx -iugis m/f"
  ],
  "column": [
    "columna -ae f",
    "columen, columinis"
  ],
  "increase": [
    "augeō, augēre, auxī, auctum",
    "auctiō, auctiōnis",
    "augmentum, augmentī",
    "prōfectus, prōfectūs",
    "prōventus, prōventūs",
    "alō, alere, aluī, altum",
    "incrēmentum, incrēmentī",
    "crēscō, crēscere, crēvī, crētum",
    "adaugeō, adaugēre, adauxī, adauctum",
    "ampliō, ampliāre, ampliāvī, ampliātum",
    "amplificō, amplificāre, amplificāvī, amplificātum",
    "multiplicō, multiplicāre, multiplicāvī, multiplicātum"
  ],
  "lover": [
    "amāns -antis m",
    "Amator"
  ],
  "young man": [
    "adulēscēns -entis m",
    "iuvenis -is m",
    "adulescentulus, adulescentulī"
  ],
  "twentieth": [
    "vīcēsimus -a -um"
  ],
  "in the direction of": [
    "versus: ad…versus"
  ],
  "wail": [
    "vāgiō, vāgīre, vagiī, -----"
  ],
  "squall": [
    "vāgiō, vāgīre, vagiī, -----"
  ],
  "wet": [
    "ūmidus -a -um",
    "aquōsus, aquōsa, aquōsum",
    "madidus, madida, madidum",
    "imbuō, imbuere, imbuī, imbūtum",
    "madefactus, madefacta, madefactum"
  ],
  "moist": [
    "ūmidus -a -um",
    "madidus, madida, madidum",
    "rōrō, rōrāre, rōrāvī, rōrātum"
  ],
  "daddy": [
    "tata -ae m"
  ],
  "whether…or": [
    "sīve . . . sīve"
  ],
  "silence": [
    "silentium -ī n",
    "Silence",
    "Silentium",
    "taciturnitās, taciturnitātis"
  ],
  "conversation": [
    "colloquium -ī n",
    "sermō, sermōnis",
    "dialogus, dialogī"
  ],
  "turn back": [
    "revertor, revertī, reversus sum",
    "revertō, revertere, revertī, reversum",
    "reversō, reversāre, reversāvī, reversātum"
  ],
  "rarely": [
    "rārō"
  ],
  "seldom": [
    "rārō",
    "īnfrequēns, īnfrequentis"
  ],
  "indeed": [
    "profectō",
    "quidem",
    "equidem",
    "quīn",
    "vērō",
    "nempe",
    "quīppe",
    "vērō (adv.)",
    "quidem (postpositive adv.)"
  ],
  "require": [
    "postulō, postulāre, postulāvī, postulātus",
    "indigeō, indigēre, indiguī",
    "requīrō, requīrere, requīsīvī, requīsītum"
  ],
  "proceed": [
    "pergō, pergere, perrēxī, perrēctus",
    "Procede",
    "excēdō, excēdere, excessī, excessum",
    "prōgredior, prōgredī, prōgressus sum"
  ],
  "go on": [
    "Perge",
    "pergō, pergere, perrēxī, perrēctus"
  ],
  "tiny": [
    "parvulus -a -um"
  ],
  "duty": [
    "officium -ī n",
    "officium, officiī",
    "portōrium, portōriī",
    "officium, officiī (n)"
  ],
  "come to mind": [
    "occurrō, occurrere, occurrī, occursum",
    "occurrō, occurrere, occurrī, occursum (+ dat)"
  ],
  "occur": [
    "occurrō, occurrere, occurrī, occursum",
    "accidō, accidere, accidī, ----",
    "fīō, fierī, factus sum",
    "oborior, oborīrī, obortus sum",
    "occurrō, occurrere, occurrī, occursum (+ dat)"
  ],
  "wet nurse": [
    "nūtrīx -īcis f"
  ],
  "nurse": [
    "nūtrīx -īcis f"
  ],
  "be unwilling": [
    "nōlō, nōlle, nōluī, -----",
    "nōlō, nōlle, nōluī"
  ],
  "not want": [
    "nōlō, nōlle, nōluī, -----"
  ],
  "don’t…": [
    "nōlī -īte +īnf"
  ],
  "necessary": [
    "necessārius -a -um",
    "necesse",
    "oportet, oportēre, oportuit,",
    "necesse (indecl. adj. used as nom. or acc.)"
  ],
  "soon": [
    "mox",
    "brevī",
    "iam"
  ],
  "by no means": [
    "minimē",
    "nūllō modō",
    "haud",
    "nēdum",
    "nēquāquam",
    "neutiquam"
  ],
  "not at all": [
    "minimē",
    "haud",
    "Omnino non",
    "haudquāquam"
  ],
  "remain": [
    "maneō, manēre, mānsī, mānsum",
    "remaneō, remanēre, remānsī, —",
    "restō, restāre, restitī, —",
    "restō, restāre, restitī, restātum",
    "obsideō, obsidēre, obsēdī, obsessum",
    "remaneō, remanēre, remānsī, remānsum"
  ],
  "milk": [
    "lac lactis n"
  ],
  "little child": [
    "īnfāns -antis m/f"
  ],
  "baby": [
    "īnfāns -antis m/f"
  ],
  "step": [
    "gradus -ūs m",
    "gradior, gradī, gressus sum"
  ],
  "degree": [
    "gradus -ūs m"
  ],
  "future": [
    "futūrus -a -um",
    "tempus futūrum"
  ],
  "little son": [
    "fīliolus, fīliolī"
  ],
  "little daughter": [
    "fīliola -ae f"
  ],
  "be fond of": [
    "dīligō, dīligere, dīlēxī, dīlēctum"
  ],
  "owe": [
    "dēbeō, dēbēre, dēbuī, dēbitus",
    "dēbeō, dēbēre, dēbuī, dēbitum"
  ],
  "be obliged": [
    "dēbeō, dēbēre, dēbuī, dēbitus"
  ],
  "care for": [
    "cūrō, cūrāre, cūrāvī, cūrātus",
    "cūrō, cūrāre, cūrāvī, cūrātum"
  ],
  "look after": [
    "cūrō, cūrāre, cūrāvī, cūrātus"
  ],
  "take care": [
    "cūrō, cūrāre, cūrāvī, cūrātus",
    "cūrō, cūrāre, cūrāvī, cūrātum"
  ],
  "cradle": [
    "cūnae -ārum f pl",
    "cūnābulum, cūnābulī"
  ],
  "tomorrow": [
    "crās",
    "crāstinus, crāstina, crāstinum"
  ],
  "converse": [
    "colloquor, colloquī, collocūtus sum",
    "fābulor, fābulārī, fābulātus sum"
  ],
  "be without": [
    "careō, carēre, caruī, caritum",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "lack": [
    "careō, carēre, caruī, caritum",
    "inopia -ae f",
    "egeō, egēre, eguī",
    "indigentia, indigentiae",
    "indigeō, indigēre, indiguī",
    "egeō, egēre, eguī, egitūrus",
    "careō, carēre, caruī, caritūrum",
    "egeō, egēre, eguī (+ abl. or gen.)",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "someone else’s": [
    "aliēnus -a -um"
  ],
  "feed": [
    "Vescere",
    "alō, alere, aluī, altum",
    "pāscō, pāscere, pāvī, pāstum",
    "cibō, cibāre, cibāvī, cibātum",
    "pābulor, pābulārī, pābulātus sum"
  ],
  "narrow": [
    "angustus -a -um",
    "artus",
    "arctus, arcta, arctum",
    "contractus, contracta, contractum"
  ],
  "nature": [
    "nātūra -ae f",
    "ingenium -ī",
    "nātūra, nātūrae",
    "indolēs, indolis",
    "ingenium, ingeniī",
    "nātūra, nātūrae (f)",
    "ingenium, ingeniī (n)",
    "cōnstitūtiō, cōnstitūtiōnis"
  ],
  "wine": [
    "vīnum -ī n",
    "Vinum",
    "vīnum, vīnī",
    "vīnum, vīnī (n.)"
  ],
  "drain": [
    "exhauriō, exhaurīre, exhausī, exhaustus",
    "Absorbe",
    "siccō, siccāre, siccāvī, siccātum"
  ],
  "mind": [
    "mēns mentis f",
    "animus -ī m",
    "Mens",
    "mēns, mentis",
    "animus, animī",
    "animus, animī (m)"
  ],
  "philosopher": [
    "philosophus -ī",
    "philosophus -ī (m)",
    "sapiēns, gen. sapientis",
    "philosophus, philosophī and philosopha, philosophae",
    "philosophus, philosophī (m.) and philosopha, philosophae (f.)"
  ],
  "atom": [
    "atomus -ī",
    "atomus -ī (f!)"
  ],
  "defeat": [
    "vincō, vincere, vīcī, victum",
    "Superare",
    "superō, superāre, superāvī, superātum"
  ],
  "overcome": [
    "vincō, vincere, vīcī, victum",
    "superō, superāre, superāvī, superātum",
    "superō, superāre, superāvī, superātus",
    "perrumpō, perrumpere, perrūpī, perruptum"
  ],
  "win": [
    "vincō, vincere, vīcī, victum"
  ],
  "dirt": [
    "sordēs -ium f pl",
    "Lutum",
    "lutum, lutī",
    "caenum, caenī",
    "sordes, sordis"
  ],
  "floor": [
    "solum, solī"
  ],
  "lowest part": [
    "solum, solī",
    "fundus -ī m"
  ],
  "soil": [
    "humus -ī",
    "solum, solī",
    "humus, humī",
    "lutum, lutī",
    "humus -ī (f!)",
    "polluō, polluere, polluī, pollūtum"
  ],
  "pig": [
    "porcus -ī m"
  ],
  "relate": [
    "nārrō, nārrāre, nārrāvī, nārrātus",
    "intimō, intimāre, intimāvī, intimātum",
    "nūntiō, nūnitāre, nūntiāvī, nūntiātum",
    "adnūntiō, adnūntiāre, adnūntiāvī, adnūntiātum"
  ],
  "exchange": [
    "mūtō, mūtāre, mūtāvī, mūtātus",
    "Commerciare",
    "commercium, commerciī",
    "mūtō, mūtāre, mūtāvī, mūtātum"
  ],
  "neat": [
    "mundus -a -um",
    "merus -a -um",
    "concinnus, concinna, concinnum"
  ],
  "meanwhile": [
    "interim",
    "intereā"
  ],
  "unworthy": [
    "indignus -a -um"
  ],
  "shameful": [
    "indignus -a -um",
    "turpis, turpe",
    "inhonestus, inhonesta, inhonestum",
    "flāgitiōsus, flāgitiōsa, flāgitiōsum"
  ],
  "on the ground": [
    "humī",
    "humī (loc)"
  ],
  "knee": [
    "genū -ūs n",
    "genū, genūs",
    "genū, genūs (n)"
  ],
  "false": [
    "falsus -a -um",
    "mendāx, mendācis",
    "vānus, vāna, vānum",
    "perfidus, perfida, perfidum",
    "reprobus, reproba, reprobum"
  ],
  "deceive": [
    "fallō, fallere, fefellī, falsum",
    "Decipere",
    "mentior, mentīrī, mentītus sum",
    "dēcipiō, dēcipere, dēcēpī, dēceptum",
    "frūstrō, frūstrāre, frūstrāvī, frūstrātum"
  ],
  "excuse": [
    "excūsō, excūsāre, excūsāvī, excūsātus",
    "ignōscō, ignōscere, ignōvī, ignōtus",
    "excūsātiō, excūsātiōnis"
  ],
  "doubt": [
    "dubitō, dubitāre, dubitāvī, dubitātus",
    "dubitātiō, dubitātiōnis",
    "dubitō, dubitāre, dubitāvī, dubitātum"
  ],
  "as soon as": [
    "cum prīmum",
    "simul atque",
    "ubi prīmum",
    "ac",
    "simulac",
    "quandōcumque"
  ],
  "gore": [
    "cruor -ōris m"
  ],
  "believe": [
    "crēdō, crēdere, crēdidī, crēditum",
    "arbitror, arbitrārī, arbitrātus sum",
    "cōnfīdō, cōnfīdere, cōnfīsus sum",
    "crēdo, crēdere, crēdidī, crēditum (+ acc)"
  ],
  "trust": [
    "crēdō, crēdere, crēdidī, crēditum",
    "fidēs -eī f",
    "fīdō, fīdere, fīsus sum",
    "cōnfīdō, cōnfīdere, cōnfīsus sum",
    "Fides",
    "fidēs, fideī",
    "fidēs, fideī (f)",
    "fīdūcia, fīdūciae",
    "crēdo, crēdere, crēdidī, crēditum",
    "crēdo, crēdere, crēdidī, crēditum (+ acc)"
  ],
  "entrust": [
    "crēdō, crēdere, crēdidī, crēditum",
    "mandō, mandāre, mandāvī, mandātum",
    "committō, committere, commīsī, commissum"
  ],
  "horn": [
    "cornū -ūs n",
    "cornū, cornūs",
    "cornum, cornī",
    "cornū, cornūs (n)"
  ],
  "look at attentively": [
    "cōnspiciō, cōnspicere, cōnspexī, cōnspectus",
    "aspectō, aspectāre, aspectāvī, aspectātum"
  ],
  "catch sight of": [
    "cōnspiciō, cōnspicere, cōnspexī, cōnspectus"
  ],
  "get to know": [
    "cognōscō, cognōscere, cognōvī, cognitum",
    "nōscō, nōscere, nōvī, nōtus"
  ],
  "recognize": [
    "cognōscō, cognōscere, cognōvī, cognitum",
    "recōgnōscō, recōgnōscere, recōgnōvī, recōgnitus",
    "gnōscō, gnōscere, gnōvī, gnōtum",
    "agnōscō, agnōscere, agnōvī, agnitum",
    "ascīscō, ascīscere, ascīvī, ascītum",
    "cōgnōscō, cōgnōscere, cōgnōvī, cōgnitum",
    "recognōscō, recognōscere, recognōvī, recognitum"
  ],
  "reason": [
    "causa -ae f",
    "ratiō -ōnis f",
    "causa, causae",
    "ratiō, ratiōnis",
    "causa, causae (f)",
    "argūmentor, argūmentārī, argūmentātus sum"
  ],
  "ox": [
    "bōs bovis m/f"
  ],
  "someone": [
    "aliquis aliquid",
    "quīdam, quaedam, quoddam",
    "aliquis, aliquid",
    "quīdam, quaedam, quiddam or quoddam",
    "quis, quid, after sī, nīsī, num, nē",
    "quīdam, quaedam, quiddam (pron.) or quoddam (adj.)"
  ],
  "something": [
    "aliquis aliquid",
    "quīdam, quaedam, quoddam",
    "aliquis, aliquid",
    "quīdam, quaedam, quiddam or quoddam",
    "quis, quid, after sī, nīsī, num, nē",
    "quīdam, quaedam, quiddam (pron.) or quoddam (adj.)"
  ],
  "really? did you say": [
    "ain'"
  ],
  "hey! hello": [
    "heus"
  ],
  "tie": [
    "vinciō, vincīre, vinxī, vinctum",
    "vinculum, vinculī",
    "innectō, innectere, innexuī, innexum",
    "alligō, alligāre, alligāvī, alligātum"
  ],
  "tremble": [
    "tremō, tremere, tremuī, -----",
    "intremō, intremere, intremuī",
    "horreō, horrēre, horruī, horruitum",
    "trepidō, trepidāre, trepidāvī, trepidātum"
  ],
  "frighten": [
    "terreō, terrēre, terruī, territum",
    "exterreō, exterrēre, exterruī, exterritum"
  ],
  "at length": [
    "tandem",
    "cōpiōsē, cōpiōsius, cōpiōsissimē"
  ],
  "at last": [
    "tandem",
    "dēnique",
    "dēmum",
    "dēnique (adv.)"
  ],
  "letter-carrier": [
    "tabellārius -ī m"
  ],
  "untie": [
    "solvō, solvere, solvī, solūtum",
    "absolvō, absolvere, absolvī, absolūtum"
  ],
  "discharge": [
    "solvō, solvere, solvī, solūtum",
    "fungor, fungī, fūnctus sum",
    "vomō, vomere, vomuī, vomitum",
    "exonerō, exonerāre, exonerāvī, exonerātum"
  ],
  "pay": [
    "solvō, solvere, solvī, solūtum",
    "Poenas dare",
    "ērogō, ērogāre, ērogāvī, ērogātum"
  ],
  "let": [
    "sinō, sinere, sīvī, situm"
  ],
  "allow": [
    "sinō, sinere, sīvī, situm",
    "permittō, permittere, permīsī, permissus",
    "adnuō, adnuere, adnuī, adnūtum"
  ],
  "just as": [
    "sīcut",
    "velut",
    "ut",
    "sicut",
    "sīcutī",
    "perinde",
    "tanquam",
    "ut (conj. + indic.)"
  ],
  "in the same way": [
    "sīcut"
  ],
  "tear up": [
    "scindō, scindere, scidī, scissum"
  ],
  "of course": [
    "scīlicet",
    "Certe"
  ],
  "jump": [
    "saliō, salīre, saluī, saltus",
    "Salta",
    "saltus, saltūs",
    "saltō, saltāre, saltāvī, saltātum",
    "saltō, saltāre, saltāvī, saltātus"
  ],
  "break": [
    "rumpō, rumpere, rūpī, ruptum",
    "frangō, frangere, frēgī, frāctum",
    "quatiō, quatere, ----, quassum",
    "domō, domāre, domuī, domitum",
    "īnfringō, īnfringere, īnfrēgī, īnfractum"
  ],
  "destroy": [
    "rumpō, rumpere, rūpī, ruptum",
    "perdō, perdere, perdī, perditum",
    "ēlīdō, ēlīdere, ēlīsī, ēlīsum",
    "dēleō, dēlēre, dēlēvī, dēlētum",
    "dīruō, dīruere, dīruī, dīrutum",
    "tollō, tollere, sustulī, sublātum",
    "perimō, perimere, perēmī, peremptum",
    "contundō, contundere, contudī, contūsum",
    "corrumpō, corrumpere, corrūpī, corruptum",
    "dēstruō, dēstruere, dēstruxī, dēstructum"
  ],
  "stand back": [
    "resistō, resistere, restitī, -----"
  ],
  "stay back": [
    "resistō, resistere, restitī, -----"
  ],
  "stay still": [
    "resistō, resistere, restitī, -----"
  ],
  "retire": [
    "recēdō, recēdere, recessī, recessus",
    "excēdō, excēdere, excessī, excessum"
  ],
  "if anyone": [
    "sī quis, sī quid"
  ],
  "if anything": [
    "sī quis, sī quid"
  ],
  "why not": [
    "quidni",
    "quīn",
    "quidnī"
  ],
  "do…": [
    "quīn"
  ],
  "go forward": [
    "prōcēdō, prōcēdere, prōcessī, ------",
    "prōgredior, prōgredī, prōgressus sum",
    "ingredior, ingredī, ingressus sum"
  ],
  "advance": [
    "prōcēdō, prōcēdere, prōcessī, ------",
    "prōgredior, prōgredī, prōgressus sum",
    "prōcessus, prōcessūs",
    "prōfectus, prōfectūs",
    "nītor, nītī, nīxus sum",
    "prōcessiō, prōcessiōnis",
    "ingredior, ingredī, ingressus sum",
    "excēdō, excēdere, excessī, excessum",
    "incēdō, incēdere, incessī, incessum"
  ],
  "earlier": [
    "prius"
  ],
  "previously": [
    "prius",
    "ante",
    "ante (prep + acc)"
  ],
  "push": [
    "pellō, pellere, pepulī, pulsum",
    "Pelle",
    "Trusa",
    "urgeō, urgēre, ursī",
    "impressiō, impressiōnis",
    "impingō, impingere, impēgī, impāctum"
  ],
  "cloak": [
    "pallium -ī n",
    "amictus, amictūs",
    "amiculum, amiculī"
  ],
  "mantle": [
    "pallium -ī n",
    "amictus, amictūs",
    "amiculum, amiculī"
  ],
  "recently": [
    "nūper",
    "nūper (adv)"
  ],
  "remind": [
    "moneō, monēre, monuī, monitum",
    "memorō, memorāre, memorāvī, memorātus",
    "commoneō, commonēre, commonuī, commonitum"
  ],
  "advise": [
    "moneō, monēre, monuī, monitum",
    "suādeō, suādēre, suāsī, suāsus",
    "hortor, hortārī, hortātus sum",
    "admoneō, admonēre, admonuī, admonitum",
    "cōnsilior, cōnsiliārī, cōnsiliātus sum",
    "praecipiō, praecipere, praecēpī, praeceptum"
  ],
  "warn": [
    "moneō, monēre, monuī, monitum",
    "praecipiō, praecipere, praecēpī, praeceptum"
  ],
  "threshold": [
    "līmen, līminis"
  ],
  "wooden": [
    "ligneus -a -um",
    "arboreus, arborea, arboreum"
  ],
  "inside": [
    "intrā",
    "intus adv"
  ],
  "within": [
    "intrā"
  ],
  "picture": [
    "imāgō -inis f"
  ],
  "doorkeeper": [
    "iānitor -ōris m",
    "cancellārius, cancellāriī"
  ],
  "growl": [
    "fremō, fremere, fremuī, -----"
  ],
  "outside": [
    "extrā",
    "forīs",
    "extra",
    "forīs (adv.)"
  ],
  "out of doors": [
    "forās",
    "forīs",
    "forīs (adv.)"
  ],
  "out through the doors": [
    "forās"
  ],
  "forth": [
    "forās"
  ],
  "out": [
    "forās",
    "offundō, offundere, offudī, offusum"
  ],
  "of iron": [
    "ferreus -a -um"
  ],
  "iron-": [
    "ferreus -a -um"
  ],
  "fierce": [
    "ferōx -ōcis",
    "saevus -a -um",
    "ācer -cris -cre",
    "atrōx, atrōcis",
    "ācer, ācris, ācre",
    "ferōx, gen. ferōcis",
    "torvus, torva, torvum",
    "rabidus, rabida, rabidum",
    "bellicōsus, bellicōsa, bellicōsum"
  ],
  "ferocious": [
    "ferōx -ōcis"
  ],
  "artisan": [
    "faber, fabrī",
    "opifex, opificis"
  ],
  "smith": [
    "faber, fabrī"
  ],
  "laugh at": [
    "dērīdeō, dērīdēre, dērīsī, dērīsus",
    "rīdeō, rīdēre, rīsī, rīsum",
    "inrīdeō, inrīdēre, inrīsī, inrīsum"
  ],
  "guard": [
    "cūstōdiō, cūstōdīre, cūstōdīvī, cūstōdītus",
    "tueor, tuērī, tūtus sum",
    "Guard",
    "excubia, excubiae",
    "praeses, praesidis",
    "satelles, satellitis",
    "servō, servāre, servāvī, servātum",
    "custōdiō, custōdīre, custōdīvī, custōdītum"
  ],
  "withdraw": [
    "cēdō, cēdere, cessī, cessum",
    "absistō, absistere, abstitī",
    "āvocō, āvocāre, āvocāvī, āvocātum",
    "dēcēdō, dēcēdere, dēcessī, dēcessum",
    "dēficiō, dēficere, dēfēcī, dēfectum",
    "excēdō, excēdere, excessī, excessum",
    "sēcēdō, sēcēdere, sēcessī, sēcessum",
    "dērogō, dērogāre, dērogāvī, dērogātum",
    "concēdō, concēdere, concessī, concessum",
    "dēscīscō, dēscīscere, dēscīvī, dēscītum"
  ],
  "beware": [
    "caveō, cavēre, cāvī, cautum"
  ],
  "chain": [
    "catēna -ae f",
    "Catena",
    "vinculum, vinculī",
    "vinculum, vinculī (n.)"
  ],
  "door pivot": [
    "cardō -inis m"
  ],
  "hinge": [
    "cardō -inis m",
    "Hinge"
  ],
  "gold": [
    "aurum -ī n",
    "Aurum"
  ],
  "gold-": [
    "aureus -a -um"
  ],
  "m gold piece": [
    "aureus -a -um"
  ],
  "formerly": [
    "anteā",
    "antehāc",
    "ōlim",
    "quondam",
    "antīquitus",
    "quondam (adv.)"
  ],
  "let in": [
    "admittō, admittere, admīsī, admissum"
  ],
  "admit": [
    "admittō, admittere, admīsī, admissum",
    "fateor, fatērī, fassus sum",
    "recipiō, recipere, recēpī, receptus",
    "recipiō, recipere, recēpī, receptum"
  ],
  "come near": [
    "accēdō, accēdere, accessī, accessum,",
    "appropinquō, appropinquāre, appropinquāvī, appropinquātus"
  ],
  "agree with": [
    "accēdō, accēdere, accessī, accessum,",
    "assentior, assentīrī, assēnsus sum",
    "assentor, assentārī, assentātus sum"
  ],
  "leaf of a door": [
    "foris -is f"
  ],
  "bridge": [
    "pōns pontis",
    "pōns pontis (m)"
  ],
  "countenance": [
    "vultus -ūs m",
    "vultus, vultūs",
    "vultus, vultūs (m.)"
  ],
  "lashes": [
    "verbera, verberum"
  ],
  "flogging": [
    "verbera, verberum"
  ],
  "and never": [
    "nec umquam / neque umquam"
  ],
  "ever": [
    "umquam",
    "Numquam",
    "ecquandō",
    "umquam (adv.)"
  ],
  "hand over": [
    "trādō, trādere, trādidī, trāditum",
    "dēdō, dēdere, dēdidī, dēditum"
  ],
  "deliver": [
    "trādō, trādere, trādidī, trāditum"
  ],
  "higher": [
    "superior -ius comp",
    "citius altius fortius"
  ],
  "superior": [
    "superior -ius comp",
    "Melior",
    "praestō, praestāre, praestitī, praestatus",
    "praevaleō, praevalēre, praevaluī, praevalitum"
  ],
  "be red": [
    "rubeō, rubēre, -----, -----"
  ],
  "blush": [
    "rubeō, rubēre, -----, -----",
    "ērubēscō, ērubēscere, ērubuī, -----",
    "rubor, rubōris"
  ],
  "who ever": [
    "quidnam",
    "quisnam"
  ],
  "what ever": [
    "quidnam",
    "quisnam"
  ],
  "shame": [
    "pudor -ōris m",
    "rubor, rubōris",
    "probrum, probrī",
    "dēdecus, dēdecoris"
  ],
  "it shames me / i am ashamed": [
    "pudeō, pudēre,"
  ],
  "promise": [
    "prōmissum, prōmissī",
    "prōmittō, prōmittere, prōmīsī, prōmissum",
    "polliceor, pollicērī, pollicitus sum",
    "Promitto",
    "fidēs, fideī",
    "fidēs, fideī (f)",
    "promissiō, promissiōnis",
    "stipulātiō, stipulātiōnis",
    "voveō, vovēre, vōvī, vōtum",
    "pollicitātiō, pollicitātiōnis",
    "dēvoveō, dēvovēre, dēvōvī, dēvōtum",
    "dēspondeō, dēspondēre, dēspondī, dēspōnsum"
  ],
  "after this": [
    "posthāc"
  ],
  "from now on": [
    "posthāc"
  ],
  "hereafter": [
    "posthāc"
  ],
  "plainly": [
    "plānē",
    "simpliciter, simplicius, simplicissimē"
  ],
  "clearly": [
    "plānē",
    "manifestō, manifestius, manifestissimē",
    "praeclārē, praeclārius, praeclārissimē"
  ],
  "ruin": [
    "perdō, perdere, perdī, perditum",
    "ruīna, ruīnae",
    "exitium, exitiī",
    "exitium, exitiī (n)",
    "interitus, interitūs",
    "perniciēs, perniciēī",
    "perditiō, perditiōnis",
    "corrumpō, corrumpere, corrūpī, corruptum",
    "dēstruō, dēstruere, dēstruxī, dēstructum",
    "pessimō, pessimāre, pessimāvī, pessimātum"
  ],
  "waste": [
    "perdō, perdere, perdī, perditum",
    "vastitās, vastitātis"
  ],
  "lose": [
    "perdō, perdere, perdī, perditum",
    "āmittō, āmittere, āmīsī, āmissus",
    "āmittō, āmittere, āmīsī, āmissum"
  ],
  "pale": [
    "pallidus -a -um",
    "Pale",
    "the Pale",
    "pālus, pāli",
    "palleō, pallēre, palluī, -----"
  ],
  "be pale": [
    "palleō, pallēre, palluī, -----"
  ],
  "on account of": [
    "ob",
    "causā",
    "ex, ē",
    "propter",
    "ex, ē (+ abl)",
    "propter (+acc.)"
  ],
  "deny": [
    "negō, negāre, negāvī, negātus",
    "negō, negāre, negāvī, negātum",
    "abnuō, abnuere, abnuī, abnuitum",
    "abdīcō, abdīcere, abdīxī, abdictum",
    "īnfitior, īnfitiārī, īnfitiātus sum",
    "abdicō, abdicāre, abdicāvī, abdicātum",
    "dēnegō, dēnegāre, dēnegāvī, dēnegātum"
  ],
  "say that…not": [
    "negō, negāre, negāvī, negātus"
  ],
  "…ever": [
    "-nam",
    "-nam. (e.g. quisnam? quidnam?)"
  ],
  "earn": [
    "mereō, merēre, meruī, meritum",
    "percipiō, percipere, percēpī, perceptum"
  ],
  "deserve": [
    "mereō, merēre, meruī, meritum"
  ],
  "praise": [
    "laus laudis f",
    "laus, laudis",
    "laudātiō, laudātiōnis",
    "laudō, laudāre, laudāvī, laudātum",
    "laudō, laudāre, laudāvī, laudātus"
  ],
  "one another": [
    "inter sē"
  ],
  "undamaged": [
    "integer intēgra intēgrum"
  ],
  "intact": [
    "integer intēgra intēgrum",
    "intactus, intacta, intactum"
  ],
  "write on": [
    "īnscrībō, īnscrībere, īnscrīpsī, īnscrīptus"
  ],
  "inscribe": [
    "īnscrībō, īnscrībere, īnscrīpsī, īnscrīptus"
  ],
  "shut in": [
    "inclūdō, inclūdere, inclūsī, inclūsum"
  ],
  "imprison": [
    "inclūdō, inclūdere, inclūsī, inclūsum"
  ],
  "enclose": [
    "inclūdō, inclūdere, inclūsī, inclūsum",
    "circumdō, circumdare, circumdedī, circumdatum",
    "saepiō, saepīre, saepsī, saeptum",
    "contineō, continēre, continuī, contentum"
  ],
  "from there": [
    "illinc",
    "inde"
  ],
  "thence": [
    "illinc",
    "inde",
    "exin"
  ],
  "from here": [
    "hinc",
    "dehinc"
  ],
  "yesterday": [
    "herī",
    "heri"
  ],
  "perhaps": [
    "fortasse",
    "forsitan",
    "forsan",
    "fōrsitan",
    "fōrtassis",
    "fortasse (adv.)"
  ],
  "maybe": [
    "fortasse",
    "forsitan"
  ],
  "confess": [
    "fateor, fatērī, fassus sum",
    "cōnfiteor, cōnfitērī, cōnfessus sum"
  ],
  "deed": [
    "factum -ī n",
    "opus, operis",
    "factum, factī",
    "factum, factī (n)",
    "facinus, facinoris"
  ],
  "act": [
    "factum -ī n",
    "factum, factī",
    "factum, factī (n)",
    "agō, agere, ēgī, āctum",
    "cieō, ciēre, cīvī, citum"
  ],
  "easily": [
    "facile",
    "molliter, mollius, mollissimē"
  ],
  "send away": [
    "dīmittō, dīmittere, dīmīsī, dīmissus",
    "āmittō, āmittere, āmīsī, āmissus",
    "āmittō, āmittere, āmīsī, āmissum",
    "absentō, absentāre, absentāvī, absentātum"
  ],
  "dismiss": [
    "dīmittō, dīmittere, dīmīsī, dīmissus"
  ],
  "contain": [
    "contineō, continēre, continuī, contentum",
    "cohibeō, cohibēre, cohibuī, cohibitum"
  ],
  "accompany": [
    "comitor, comitārī, comitātus sum",
    "exsequor, exsequī, exsecūtus sum",
    "comitō, comitāre, comitāvī, comitātum"
  ],
  "companion": [
    "comes, comitis",
    "Companion",
    "sodālis, sodālis",
    "collēga, collēgae"
  ],
  "key": [
    "clāvis -is f"
  ],
  "turn aside": [
    "āvertō, āvertere, āvertī, āversum",
    "dēvertō, dēvertere, dēvertī, dēversum"
  ],
  "avert": [
    "āvertō, āvertere, āvertī, āversum",
    "aspernor, aspernārī, aspernātus sum",
    "retorqueō, retorquēre, retorsī, retortum"
  ],
  "before this time": [
    "antehāc"
  ],
  "greek letter alpha": [
    "alpha"
  ],
  "greek letter beta": [
    "bēta"
  ],
  "greek letter gamma": [
    "gamma"
  ],
  "strongly": [
    "valdē",
    "ācriter, ācrius, ācerrimē",
    "firmiter, firmius, firmissimē",
    "fortiter, fortius, fortissimē",
    "vehementer, vehementius, vehementissimē"
  ],
  "very": [
    "valdē",
    "per",
    "nimis or nimium",
    "per (prep + acc)",
    "ipse, ipsa, ipsum",
    "nimis or nimium (adv.)"
  ],
  "uproar": [
    "tumultus, tumultūs",
    "turba, turbae",
    "turba, turbae (f)"
  ],
  "sudden": [
    "subitus -a -um",
    "repentīnus, repentīna, repentīnum",
    "praeproperus, praepropera, praeproperum"
  ],
  "suddenly": [
    "subitō",
    "repente",
    "raptim",
    "cōnfestim",
    "subitō (adv.)"
  ],
  "noise": [
    "sonus -ī m",
    "strepitus -ūs m",
    "clangor, clangōris"
  ],
  "din": [
    "strepitus -ūs m"
  ],
  "sound": [
    "sonus -ī m",
    "sānus, sāna, sānum",
    "salvus, salva, salvum",
    "īnsonō, īnsonāre, īnsonuī",
    "sincērus, sincēra, sincērum",
    "sonō, sonāre, sonuī, sonitum",
    "obstrepō, obstrepere, obstrepuī, obstrepitum",
    "circumsonō, circumsonāre, circumsonuī, circumsonātum"
  ],
  "often": [
    "saepe"
  ],
  "lie down": [
    "recumbō, recumbere, recubuī, ----",
    "discumbō, discumbere, discubuī, discubitum"
  ],
  "recline": [
    "recumbō, recumbere, recubuī, ----"
  ],
  "settle back": [
    "recumbō, recumbere, recubuī, ----"
  ],
  "recline at table": [
    "recumbō, recumbere, recubuī, ----",
    "accubō, accubāre, accubāvī, accubātum"
  ],
  "at first": [
    "prīmō",
    "prīmitus"
  ],
  "struck": [
    "percussum"
  ],
  "suffer": [
    "patior, patī, passus sum",
    "perferō, perferre, pertulī, perlātus",
    "Pati",
    "ferō, ferre, tulī, lātum",
    "doleō, dolēre, doluī, dolitūrum"
  ],
  "undergo": [
    "patior, patī, passus sum",
    "subeō, subīre, subiī"
  ],
  "bone": [
    "os ossis n"
  ],
  "perf know": [
    "nōscō, nōscere, nōvī, nōtus"
  ],
  "wonder": [
    "mīror, mīrārī, mīrātus sum",
    "admīrātiō -ōnis f",
    "mīrāculum, mīrāculī",
    "dēmīror, dēmīrārī, dēmīrātus sum"
  ],
  "be surprised": [
    "mīror, mīrārī, mīrātus sum"
  ],
  "side": [
    "latus -eris n"
  ],
  "flank": [
    "latus -eris n"
  ],
  "next to": [
    "iūxtā"
  ],
  "unequal": [
    "impār, imparis",
    "impar, imparis",
    "dispār, disparis"
  ],
  "shatter": [
    "frangō, frangere, frēgī, frāctum",
    "ēlīdō, ēlīdere, ēlīsī, ēlīsum",
    "dīminuō, dīminuere, dīminuī, dīminutum",
    "convellō, convellere, convellī, convulsum"
  ],
  "even if": [
    "etsī",
    "etiamsī",
    "etsī (et + sī)"
  ],
  "pain": [
    "dolor -ōris m",
    "dolor, dolōris"
  ],
  "grief": [
    "dolor -ōris m",
    "dolor, dolōris",
    "maeror, maerōris",
    "maestitia, maestitiae",
    "contrītiō, contrītiōnis"
  ],
  "anew": [
    "dēnuō"
  ],
  "desire": [
    "cupiō, cupere, cupīvī, cupītum",
    "cupiditās -ātis f",
    "cupīdō, cupīdinis",
    "lubīdō, lubīdinis",
    "dēsīderium, dēsīderiī",
    "cupiditās, cupiditātis",
    "cupiditās, cupiditātis (f)",
    "concupiscentia, concupiscentiae",
    "expetō, expetere, expetīvī, expetītum",
    "dēsīderō, dēsīderāre, dēsīderāvī, dēsīderātum",
    "concupīscō, concupīscere, concupīvī, concupītum"
  ],
  "blood-stained": [
    "cruentus -a -um"
  ],
  "bloody": [
    "cruentus -a -um",
    "atrōx, atrōcis",
    "crūdus, crūda, crūdum",
    "sanguinolentus, sanguinolenta, sanguinolentum"
  ],
  "immediately": [
    "continuō",
    "īlicō",
    "statim",
    "extemplō",
    "prōtinus",
    "cōnfestim",
    "statim (stat)",
    "prōtinus (adv.)",
    "celeriter, celerius, celerrimē"
  ],
  "for certain": [
    "certō adv"
  ],
  "otherwise": [
    "aliter",
    "aliōquī"
  ],
  "some": [
    "aliquī, aliqua, aliquod",
    "nōnnūllī -ae -a",
    "aliquot",
    "aliī . . . aliī",
    "nōnnūllus, nōnnūlla, nōnnūllum",
    "quīdam, quaedam, quiddam or quoddam",
    "quīdam, quaedam, quiddam (pron.) or quoddam (adj.)"
  ],
  "smoke": [
    "fūmus -i",
    "fūmus, fūmī",
    "fūmus -i (m)",
    "fūmō, fūmāre, fūmāvī, fūmātum"
  ],
  "fire": [
    "ignis -is",
    "ignis -is m",
    "Fire",
    "Ignis",
    "ignis, ignis",
    "ignis -is (m)",
    "ardor, ardōris",
    "flamma, flammae",
    "incendium, incendiī"
  ],
  "burn": [
    "ārdeō, ārdēre, ārsī, ārsus",
    "ūrō, ūrere, ūssī, ūstum",
    "Ure",
    "flagrō, flagrāre, flagrāvī",
    "exūrō, exūrere, exussī, exustum",
    "inūrō, inūrere, inussī, inustum",
    "torreō, torrēre, torruī, tostum",
    "cremō, cremāre, cremāvī, cremātum",
    "exardeō, exardēre, exarsī, exarsum",
    "flammō, flammāre, flammāvī, flammātum",
    "incendō, incendere, incendī, incēnsum",
    "cōnflagrō, cōnflagrāre, cōnflagrāvī, cōnflagrātum"
  ],
  "to run together": [
    "concurrō, concurrere, concurrī, concursus"
  ],
  "assemble": [
    "concurrō, concurrere, concurrī, concursus",
    "coeō, coīre, coiī, coitum",
    "condūcō, condūcere, condūxī, conductum",
    "collocō, collocāre, collocāvī, collocātum"
  ],
  "flock together": [
    "concurrō, concurrere, concurrī, concursus"
  ],
  "engage in battle": [
    "concurrō, concurrere, concurrī, concursus"
  ],
  "agree": [
    "concurrō, concurrere, concurrī, concursus",
    "Consentire",
    "cōnsentiō, cōnsentīre, cōnsēnsī, cōnsēnsum",
    "concordō, concordāre, concordāvī, concordātum"
  ],
  "concur": [
    "concurrō, concurrere, concurrī, concursus"
  ],
  "swallow": [
    "vorō, vorāre, vorāvī, vorātum",
    "hirundō, hirundinis"
  ],
  "devour": [
    "vorō, vorāre, vorāvī, vorātum",
    "dēvorō, dēvorāre, dēvorāvī, dēvorātus",
    "absorbeō, absorbēre, absorbuī, absorptum"
  ],
  "drag": [
    "trahō, trahere, trāxī, trāctum",
    "trahō, trahere, trāxī, tractum",
    "tractō, tractāre, tractāvī, tractātum",
    "extrahō, extrahere, extraxī, extractum",
    "pertrahō, pertrahere, pertraxī, pertractum",
    "prōtrahō, prōtrahere, prōtraxī, prōtractum"
  ],
  "pull": [
    "trahō, trahere, trāxī, trāctum",
    "Trahe",
    "dēcerpō, dēcerpere, dēcerpsī, dēcerptum",
    "prōtrahō, prōtrahere, prōtraxī, prōtractum"
  ],
  "fearful": [
    "timidus -a -um",
    "pavidus, pavida, pavidum",
    "horribilis, horribile, horribilior"
  ],
  "timid": [
    "timidus -a -um"
  ],
  "terrible": [
    "terribilis, terribile"
  ],
  "bull": [
    "taurus -ī m"
  ],
  "cast off": [
    "nāvem solvere",
    "aspernor, aspernārī, aspernātus sum",
    "excutiō, excutere, excussī, excussum",
    "repudiō, repudiāre, repudiāvī, repudiātum"
  ],
  "set sail": [
    "nāvem solvere"
  ],
  "rock": [
    "saxum -ī n",
    "saxum, saxī",
    "rūpēs, rūpis",
    "petra, petrae",
    "saxum, saxī (n.)"
  ],
  "cruel": [
    "saevus -a -um",
    "crūdēlis -e",
    "immītis, immīte"
  ],
  "king": [
    "rēx rēgis m",
    "Rex",
    "rēx, rēgis",
    "rēx, rēgis (m)"
  ],
  "direct": [
    "regō, regere, rēxī, rēctum",
    "prorsus, prorsa, prorsum",
    "dērigō, dērigere, dērexī, dērectum",
    "dīrigō, dīrigere, dīrexī, dīrectum",
    "administrō, administrāre, administrāvī, administrātum"
  ],
  "every year": [
    "quotannīs"
  ],
  "look out": [
    "prōspiciō, prōspicere, prōspexī, prōspectus"
  ],
  "look ahead": [
    "prōspiciō, prōspicere, prōspexī, prōspectus"
  ],
  "be open": [
    "pateō, patēre, patuī, ----",
    "pateō, patēre, patuī"
  ],
  "ready": [
    "parātus -a -um",
    "Paratus",
    "libēns, libentis",
    "obvius, obvia, obvium",
    "prōpensus, prōpensa, prōpensum"
  ],
  "long ago": [
    "ōlim",
    "prīdem",
    "iamdūdum",
    "iampridem"
  ],
  "kill": [
    "necō, necāre, necāvī, necātus",
    "occīdō, occīdere, occīdī, occīsum",
    "interficiō, interficere, interfēcī, interfectus",
    "caedō, caedere, cecidī, caesum",
    "Neca",
    "necō, necāre, necāvī, necātum",
    "dēiciō, dēicere, dēiēcī, dēiectum",
    "iugulō, iugulāre, iugulāvī, iugulātum",
    "extinguō, extinguere, extīnxī, extīnctum",
    "interemō, interemere, interemī, interemptum",
    "obtruncō, obtruncāre, obtruncāvī, obtruncātum",
    "interficiō, interficere, interfēcī, interfectum"
  ],
  "forget": [
    "oblīvīscor, oblīvīscī, oblītus sum",
    "dēdiscō, dēdiscere, dēdidicī"
  ],
  "killing": [
    "nex necis f",
    "caedēs -is f",
    "interfectiō, interfectiōnis"
  ],
  "murder": [
    "nex necis f",
    "Nex",
    "occīsiō, occīsiōnis",
    "interfectiō, interfectiōnis",
    "necō, necāre, necāvī, necātum",
    "homicīdium, homicīdiī or homicīdī",
    "interficiō, interficere, interfēcī, interfectum"
  ],
  "narrative": [
    "nārrātiō -ōnis f"
  ],
  "death": [
    "mors mortis f",
    "fātum -ī n",
    "Mors",
    "fātum, fātī",
    "mors, mortis",
    "fūnus, fūneris",
    "fātum, fātī (n)"
  ],
  "delay": [
    "mora, morae",
    "morārī",
    "mora, morae (f)",
    "dīlātiō, dīlātiōnis",
    "cūnctātiō, cūnctātiōnis",
    "cunctō, cunctāre, cunctāvī, cunctātum",
    "retardō, retardāre, retardāvī, retardātum"
  ],
  "monster": [
    "mōnstrum -ī n",
    "bēlua, bēluae"
  ],
  "walls": [
    "moenia -ium n pl"
  ],
  "marvelous": [
    "mīrābilis -e"
  ],
  "wonderful": [
    "mīrābilis -e",
    "mīrus -a -um",
    "Optime",
    "mīrāculum, mīrāculī",
    "admīrābilis, admīrābile",
    "mīrificus, mīrifica, mīrificum"
  ],
  "beach": [
    "lītus -oris n",
    "acta, actae"
  ],
  "shore": [
    "lītus -oris n",
    "lītus, lītoris",
    "lītus, lītoris (n)",
    "lītoreus, lītorea, lītoreum"
  ],
  "labyrinth": [
    "labyrinthus -ī m"
  ],
  "thither": [
    "illūc",
    "istuc"
  ],
  "to that place": [
    "illūc",
    "eō"
  ],
  "low": [
    "humilis -e",
    "Parvum",
    "dēmissus, dēmissa, dēmissum"
  ],
  "hither": [
    "hūc"
  ],
  "to this place": [
    "hūc"
  ],
  "glory": [
    "glōria -ae f",
    "laus, laudis",
    "decus, decoris",
    "glōria, glōriae",
    "glōria, glōriae (f)",
    "illūminātiō, illūminātiōnis",
    "glōrior, glōriārī, glōriātus sum"
  ],
  "by chance": [
    "forte",
    "fortē",
    "temerē"
  ],
  "thread": [
    "fīlum -ī n"
  ],
  "story": [
    "fābula -ae f",
    "fābula, fābulae",
    "fābella, fābellae",
    "fābula, fābulae (f)",
    "contīgnātiō, contīgnātiōnis"
  ],
  "fable": [
    "fābula -ae f",
    "fābella, fābellae"
  ],
  "conquest": [
    "expugnātiō -ōnis f"
  ],
  "exit": [
    "exitus, exitūs",
    "Exi",
    "exeō, exīre, exiī, exitum"
  ],
  "way out": [
    "exitus, exitūs"
  ],
  "desert": [
    "dēserō, dēserere, dēseruī, dēsertum",
    "vastitās, vastitātis",
    "dēscīscō, dēscīscere, dēscīvī, dēscītum",
    "relinquō, relinquere, relīquī, relictum",
    "trānsfugiō, trānsfugere, trānsfūgī, trānsfugitum"
  ],
  "go down": [
    "dēscendō, dēscendere, dēscendī, dēscensus"
  ],
  "descend": [
    "dēscendō, dēscendere, dēscendī, dēscensus",
    "dēlābor, dēlābī, dēlapsus sum"
  ],
  "chariot": [
    "currus -ūs m",
    "axis, axis",
    "bīga, bīgae",
    "essedum, essedī",
    "carpentum, carpentī"
  ],
  "desirous": [
    "cupidus -a -um",
    "cupidus -a -um (+gen)",
    "cupidus, cupida, cupidum"
  ],
  "eager": [
    "cupidus -a -um",
    "libēns, libentis",
    "ācer, ācris, ācre",
    "cupidus -a -um (+gen)",
    "alacer, alacris, alacre",
    "cupidus, cupida, cupidum",
    "ārdeō, ārdēre, ārsī, ārsum",
    "intēnsus, intēnsa, intēnsum",
    "prōpensus, prōpensa, prōpensum",
    "gestiō, gestīre, gestīvī, gestitum"
  ],
  "fix": [
    "cōnstituō, cōnstituere, cōnstituī, cōnstitūtus",
    "fīgō, fīgere, fīxī, fīxus",
    "statuō, statuere, statuī, statūtum",
    "īnfīgō, īnfīgere, īnfīxī, īnfīxum"
  ],
  "decide": [
    "cōnstituō, cōnstituere, cōnstituī, cōnstitūtus",
    "dēcernō, dēcernere, dēcrēvī, dēcrētum",
    "iūdicō, iūdicāre, iūdicāvī, iūdicātum"
  ],
  "sight": [
    "cōnspectus -ūs",
    "speciēs, speciēī",
    "aspectus, aspectūs",
    "prospectus, prospectūs",
    "vīsitātiō, vīsitātiōnis"
  ],
  "view": [
    "cōnspectus -ūs",
    "prospectus, prospectūs"
  ],
  "citizen": [
    "cīvis -is m/f",
    "Citizen",
    "cīvis, cīvis",
    "cīvis, cīvis (m and f)"
  ],
  "countryman": [
    "cīvis -is m/f"
  ],
  "in a short time": [
    "brevī"
  ],
  "shortly": [
    "brevī",
    "propediem"
  ],
  "help": [
    "auxilium -ī n",
    "iuvō, iuvāre, iūvī, iūtum",
    "adiuvō, adiuvāre, adiuvāvī, adiuvātus",
    "Adiuva",
    "ops, opis",
    "ops, opis (f.)",
    "auxilium, auxiliī",
    "subsidium, subsidiī",
    "adiumentum, adiumentī",
    "adiūtōrium, adiūtōriī",
    "auxilium, auxiliī (n.)",
    "auxilior, auxiliārī, auxiliātus sum",
    "succurrō, succurrere, succurrī, succursum"
  ],
  "assistance": [
    "auxilium -ī n",
    "adiumentum, adiumentī",
    "adiūtōrium, adiūtōriī"
  ],
  "lamb": [
    "agnus -ī m"
  ],
  "building": [
    "aedificium -ī n",
    "tīgnum, tīgnī",
    "aedificium, aedificiī",
    "īnstructiō, īnstructiōnis",
    "aedificium, aedificiī (n.)"
  ],
  "build": [
    "aedificō, aedificāre, aedificāvī, aedificātum",
    "mōliō, mōlīre",
    "mōlior, mōlīrī, mōlītus sum",
    "condō, condere, condidī, conditum",
    "compōnō, compōnere, composuī, compositum",
    "fabricō, fabricāre, fabricāvī, fabricātum"
  ],
  "in truth": [
    "vērum",
    "vērō",
    "vērō (adv.)"
  ],
  "across": [
    "trāns"
  ],
  "over": [
    "trāns"
  ],
  "reckless": [
    "audāx -ācis",
    "temerārius -a -um",
    "dēmēns, dēmentis"
  ],
  "up": [
    "sūrsum",
    "Up",
    "usque",
    "Ascende",
    "usque (adv.)"
  ],
  "upward": [
    "sūrsum"
  ],
  "highest": [
    "summus -a -um"
  ],
  "interested": [
    "studiōsus -a -um",
    "Tibine interest",
    "studiōsus -a -um (+gen)"
  ],
  "but if": [
    "sīn",
    "quodsī"
  ],
  "call back": [
    "revocō, revocāre, revocāvī, revocātus"
  ],
  "revoke": [
    "revocō, revocāre, revocāvī, revocātus"
  ],
  "and no one": [
    "neque/nec quisquam"
  ],
  "anyone": [
    "quisquam",
    "quīvīs, quaevīs, quodvīs",
    "quis, quid, after sī, nīsī, num, nē"
  ],
  "shake": [
    "quatiō, quatere, ----, quassum",
    "intremō, intremere, intremuī",
    "vibrō, vibrāre, vibrāvī, vibrātum"
  ],
  "brandish": [
    "quatiō, quatere, ----, quassum",
    "Brandish",
    "rotō, rotāre, rotāvī, rotātum",
    "vibrō, vibrāre, vibrāvī, vibrātum",
    "coruscō, coruscāre, coruscāvī, coruscātum"
  ],
  "batter": [
    "quatiō, quatere, ----, quassum",
    "convellō, convellere, convellī, convulsum"
  ],
  "": [
    "quatiō, quatere, ----, quassum",
    "pecus -oris n",
    "utique",
    "corōna, corōnae",
    "īnsidiae, īnsidiārum",
    "incūnābulum, incūnābulī",
    "bibulus, bibula, bibulum",
    "dīrectus, dīrecta, dīrectum",
    "prīstinus, prīstina, prīstinum",
    "signifer, signifera, signiferum",
    "dēferō, dēferre, dētulī, dēlātum",
    "ōbiciō, ōbicere, ōbiēcī, ōbiectum",
    "excēdō, excēdere, excessī, excessum",
    "dēmittō, dēmittere, dēmīsī, dēmissum",
    "aliēnō, aliēnāre, aliēnāvī, aliēnātum",
    "prōiciō, prōicere, prōiēcī, prōiectum",
    "prōvideō, prōvidēre, prōvīdī, prōvīsum",
    "dēprimō, dēprimere, dēpressī, dēpressum",
    "convenienter, convenientius, convenientissimē",
    "exercitō, exercitāre, exercitāvī, exercitātum",
    "praetereō, praeterīre, praeteriī, praeteritum"
  ],
  "pursue": [
    "persequor, persequī, persecūtus sum",
    "vēnor, vēnārī, vēnātus sum",
    "premō, premere, pressī, pressum",
    "adsequor, adsequī, adsecūtus sum",
    "exsequor, exsequī, exsecūtus sum",
    "īnsequor, īnsequī, īnsecūtus sum",
    "īnsector, īnsectārī, īnsectātus sum"
  ],
  "do thoroughly": [
    "perficiō, perficere, perfēcī, perfectum"
  ],
  "feather": [
    "penna -ae f",
    "plūma, plūmae"
  ],
  "peninsula": [
    "paenīnsula -ae f"
  ],
  "almost": [
    "paene",
    "ferē",
    "propemodum"
  ],
  "the world": [
    "orbis terrārum"
  ],
  "circle": [
    "orbis -is m",
    "circus -ī m",
    "circulus, circulī",
    "circumscriptiō, circumscriptiōnis"
  ],
  "orbit": [
    "orbis -is m",
    "circus -ī m",
    "ambitus, ambitūs"
  ],
  "work": [
    "opus, operis",
    "labor -ōris m",
    "labōrō, labōrāre, labōrāvī, labōrātum",
    "labor, labōris",
    "labor, labōris (m)",
    "opificium, opificiī",
    "operō, operāre, operāvī, operātum"
  ],
  "large number": [
    "multitūdō -inis f"
  ],
  "multitude": [
    "multitūdō -inis f",
    "turba, turbae",
    "turba, turbae (f)",
    "frequentia, frequentiae",
    "celēbritās, celēbritātis"
  ],
  "make soft": [
    "molliō, mollīre, mollīvī, mollītus",
    "mītigō, mītigāre, mītigāvī, mītigātum"
  ],
  "soften": [
    "molliō, mollīre, mollīvī, mollītus",
    "lēniō, lēnīre, lēnīvī, lēnītum",
    "molliō, mollīre, mollīvī, mollītum",
    "ēmolliō, ēmollīre, ēmollīvī, ēmollitum"
  ],
  "very much": [
    "māximē",
    "permultus, permulta, permultum"
  ],
  "especially": [
    "māximē",
    "praecipuē",
    "utique",
    "praesertim"
  ],
  "freedom": [
    "lībertās -ātis f",
    "licentia, licentiae",
    "vacātiō, vacātiōnis"
  ],
  "liberty": [
    "lībertās -ātis f",
    "licentia, licentiae",
    "lībertās, lībertātis",
    "lībertās, lībertātis (f)"
  ],
  "free": [
    "līber -era -erum",
    "līberō, līberāre, līberāvī, līberātum",
    "līber, lībera, līberum",
    "grātuītus, grātuīta, grātuītum",
    "exsolvō, exsolvere, exsolvī, exsolūtum"
  ],
  "huge": [
    "ingēns -entis",
    "immānis, immāne",
    "ingēns -entis (adi)",
    "ingēnsgen. ingentis"
  ],
  "vast": [
    "ingēns -entis",
    "immānis, immāne",
    "ingēns -entis (adi)",
    "effūsus, effūsa, effūsum",
    "immēnsus, immēnsa, immēnsum"
  ],
  "lowest": [
    "īnfimus -a -um",
    "īmus -a -um",
    "humillimus, humillima, humillimum"
  ],
  "unripe": [
    "immātūrus, immātūra, immātūrum"
  ],
  "immature": [
    "immātūrus, immātūra, immātūrum"
  ],
  "untimely": [
    "immātūrus, immātūra, immātūrum",
    "intempestus, intempesta, intempestum",
    "intempestīvus, intempestīva, intempestīvum"
  ],
  "imitate": [
    "imitor, imitārī, imitātus sum",
    "imitō, imitāre, imitāvī, imitātum",
    "simulō, simulāre, simulāvī, simulātum"
  ],
  "escape": [
    "fuga -ae f",
    "effugiō, effugere, effūgī, effugitum",
    "aufugiō, aufugere, aufūgī, ------",
    "effluō, effluere, effluxī",
    "fugiō, fugere, fūgī, fugitūrum",
    "dēfugiō, dēfugere, dēfūgī, dēfugitum"
  ],
  "flight": [
    "fuga -ae f",
    "effugium, effugiī"
  ],
  "fasten": [
    "fīgō, fīgere, fīxī, fīxus",
    "īnfīgō, īnfīgere, īnfīxī, īnfīxum",
    "alligō, alligāre, alligāvī, alligātum",
    "dēstinō, dēstināre, dēstināvī, dēstinātum"
  ],
  "think out": [
    "excōgitō, excōgitāre, excōgitāvī, excōgitātum"
  ],
  "devise": [
    "excōgitō, excōgitāre, excōgitāvī, excōgitātum",
    "māchinor, māchinārī, māchinātus sum"
  ],
  "fly out": [
    "ēvolō, ēvolāre, ēvolāvī, ēvolātus"
  ],
  "look down": [
    "dēspiciō, dēspicere, dēspexī, dēspectus",
    "dēspectō, dēspectāre, dēspectāvī, dēspectātus"
  ],
  "despise": [
    "dēspiciō, dēspicere, dēspexī, dēspectus",
    "contemnō, contemnere, contempsī, contemptum",
    "fastīdiō, fastīdīre, fastīdīvī, fastīditum"
  ],
  "down": [
    "deorsum",
    "Down",
    "Descende",
    "prōiciō, prōicere, prōiēcī, prōiectum"
  ],
  "spend": [
    "cōnsūmō, cōnsūmere, cōnsūmpsī, cōnsūmptus",
    "agō, agere, ēgī, āctum"
  ],
  "consume": [
    "cōnsūmō, cōnsūmere, cōnsūmpsī, cōnsūmptus",
    "cōnsūmō, cōnsūmere, cōnsūmpsī, cōnsūmptum"
  ],
  "plan": [
    "cōnsilium -ī n",
    "cōnsilium, cōnsiliī",
    "cōnsilium, cōnsiliī (n)",
    "mōlior, mōlīrī, mōlītus sum",
    "cōnsuō, cōnsuere, cōnsuī, cōnsūtum",
    "cōgitō, cōgitāre, cōgitāvī, cōgitātum",
    "cōnsulō, cōnsulere, cōnsuluī, cōnsultum"
  ],
  "consultation": [
    "cōnsilium -ī n",
    "cōnsultātiō, cōnsultātiōnis",
    "dēlīberātiō, dēlīberātiōnis"
  ],
  "counsel": [
    "cōnsilium -ī n",
    "cōnsilium, cōnsiliī",
    "advocātiō, advocātiōnis",
    "cōnsilium, cōnsiliī (n)"
  ],
  "overtake": [
    "cōnsequor, cōnsequī, cōnsecūtus sum",
    "adipīscor, adipīscī, adeptus sum",
    "superveniō, supervenīre, supervēnī, superventum"
  ],
  "do throughly": [
    "cōnficiō, cōnficere, cōnfēcī, cōnfectus"
  ],
  "swift": [
    "celer celeris celere",
    "vēlōx -ōcis",
    "fugāx, fugācis",
    "citus, cita, citum",
    "celer, celeris, celere",
    "festīnus, festīna, festīnum",
    "āctuārius, āctuāria, āctuārium"
  ],
  "quick": [
    "celer celeris celere",
    "ēia",
    "CELER",
    "strēnuus, -a, -um",
    "celer, celeris, celere",
    "festīnus, festīna, festīnum",
    "properus, propera, properum",
    "strēnuus, strēnua, strēnuum"
  ],
  "cautious": [
    "cautus -a -um",
    "prōvideō, prōvidēre, prōvīdī, prōvīsum"
  ],
  "for the sake of": [
    "causā",
    "grātiā",
    "prō",
    "(gen+) grātiā",
    "prō (prep. + abl.)"
  ],
  "case": [
    "cāsus -ūs m",
    "causa, causae",
    "causa, causae (f)"
  ],
  "prison": [
    "carcer -eris m",
    "Carcer"
  ],
  "bold": [
    "audāx -ācis",
    "procāx, procācis",
    "animōsus, animōsa, animōsum",
    "magnanimus, magnanima, magnanimum"
  ],
  "audacious": [
    "audāx -ācis"
  ],
  "art": [
    "ars artis",
    "ars, artis",
    "ars, artis (f)",
    "artificium, artificiī"
  ],
  "skill": [
    "ars artis",
    "ars, artis",
    "prūdentia -ae",
    "ars, artis (f)",
    "prūdentia -ae (f)",
    "sollertia, sollertiae",
    "calliditās, calliditātis"
  ],
  "wander away": [
    "aberrō, aberrāre, aberrāvī, -----"
  ],
  "vine": [
    "vītis -is f"
  ],
  "vineyard": [
    "vīnea -ae f",
    "vīnētum, vīnētī"
  ],
  "grape": [
    "ūva -ae f",
    "bōtrus, bōtrī"
  ],
  "use": [
    "ūtor, ūtī, ūsus sum",
    "Utere",
    "ūsus, ūsūs",
    "ūsūra, ūsūrae",
    "ūtor, ūtī, ūsus sum (+ abl.)",
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "enjoy": [
    "ūtor, ūtī, ūsus sum",
    "fruor, fruī, frūctus sum",
    "ūtor, ūtī, ūsus sum (+ abl.)",
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "in order that": [
    "ut",
    "ut (+ coni)",
    "ut (conj. + subjunct.)"
  ],
  "of the city": [
    "urbānus -a -um",
    "urbānus, urbāna, urbānum"
  ],
  "urban": [
    "urbānus -a -um",
    "urbānus, urbāna, urbānum"
  ],
  "thirtieth": [
    "trīcēsimus -a -um"
  ],
  "twice as much": [
    "alterum tantum"
  ],
  "near the city": [
    "suburbānus -a -um"
  ],
  "scatter": [
    "spargō, spargere, sparsī, sparsum",
    "sternō, sternere, strāvī, strātum",
    "aspergō, aspergere, aspersī, aspersum",
    "dīdō, dīdere, dīdidī, dīditum",
    "differō, differre, distulī, dīlātum",
    "disiciō, disicere, disiēcī, disiectum",
    "diffugiō, diffugere, diffūgī, diffūgitum",
    "dissipō, dissipāre, dissipāvī, dissipātum",
    "distrahō, distrahere, distraxī, distractum",
    "disiectō, disiectāre, disiectāvī, disiectātum",
    "substernō, substernere, substrāvī, substrātum"
  ],
  "dry": [
    "siccus -a -um",
    "torridus, torrida, torridum",
    "siccō, siccāre, siccāvī, siccātum"
  ],
  "sow": [
    "serō, serere, sēvī, satus",
    "porca, porcae",
    "asserō, asserere, assēvī, assitum",
    "sēminō, sēmināre, sēmināvī, sēminātum"
  ],
  "plant": [
    "serō, serere, sēvī, satus",
    "cicūta, cicūtae",
    "planta, plantae",
    "plantō, plantāre, plantāvī, plantātum",
    "sēminō, sēmināre, sēmināvī, sēminātum"
  ],
  "seed": [
    "sēmen -inis n",
    "Semen"
  ],
  "rural": [
    "rūsticus -a -um",
    "agrestis, agreste"
  ],
  "rustic": [
    "rūsticus -a -um",
    "agrestis, agreste",
    "rūsticānus, rūsticāna, rūsticānum"
  ],
  "farm-": [
    "rūsticus -a -um"
  ],
  "the country": [
    "rūs, rūris"
  ],
  "in the country": [
    "rūrī"
  ],
  "crude": [
    "rudis -e",
    "iners, inertis"
  ],
  "rude": [
    "rudis -e"
  ],
  "irrigate": [
    "rigō, rigāre, rigāvī, rigātus"
  ],
  "region": [
    "regiō -ōnis f",
    "pāgus, pāgī",
    "loca, locōrum",
    "plaga, plagae",
    "loca, locōrum (n)"
  ],
  "district": [
    "regiō -ōnis f",
    "pāgus, pāgī"
  ],
  "tear away": [
    "rapiō, rapere, rapuī, raptum"
  ],
  "carry off": [
    "rapiō, rapere, rapuī, raptum",
    "auferō, auferre, abstulī, ablātum",
    "abdūcō, abdūcere, abdūxī, abductus"
  ],
  "rest": [
    "quiēscō, quiēscere, quiēvī, quiētum",
    "requiēscō, requiēscere, requiēvī, requiētus",
    "cēterī -ae -a",
    "cessātiō, cessātiōnis",
    "cēterī, cēterae, cētera (pl.)",
    "acquiēscō, acquiēscere, acquiēvī, acquiētum",
    "requiēscō, requiēscere, requiēvī, requiētum",
    "conquiēscō, conquiēscere, conquiēvī, conquiētum"
  ],
  "and nothing": [
    "neque/nec quidquam"
  ],
  "not even": [
    "nē",
    "nē…quidem"
  ],
  "one of the": [
    "quīdam, quaedam, quoddam"
  ],
  "a certain": [
    "quīdam, quaedam, quoddam"
  ],
  "as…as possible": [
    "quam +sup"
  ],
  "keep off": [
    "prohibeō, prohibēre, prohibuī, prohibitum",
    "arceō, arcēre, arcuī, arcitum"
  ],
  "prevent": [
    "prohibeō, prohibēre, prohibuī, prohibitum",
    "exclūdō, exclūdere, exclūsī, exclūsum",
    "impediō, impedīre, impedīvī, impedītum"
  ],
  "be useful": [
    "prōsum, prōdesse, prōfuī"
  ],
  "do good": [
    "prōsum, prōdesse, prōfuī",
    "benefaciō, benefacere, benefēcī, benefactum"
  ],
  "instead of": [
    "prō",
    "prō (prep. + abl.)"
  ],
  "prayers": [
    "precēs -um f pl"
  ],
  "estate": [
    "praedium -ī",
    "fundus -ī m",
    "praedium -ī (n)"
  ],
  "livestock": [
    "pecus -oris n"
  ],
  "forbearance": [
    "patientia -ae f"
  ],
  "patience": [
    "patientia -ae f",
    "patientia, patientiae",
    "patientia, patientiae (f)"
  ],
  "patient": [
    "patiēns, patientis"
  ],
  "pasture": [
    "pāscō, pāscere, pāvī, pāstum",
    "pāstus, pāstūs"
  ],
  "feast": [
    "pāscō, pāscere, pāvī, pāstum",
    "dominium, dominiī",
    "epulor, epulārī, epulātus sum"
  ],
  "too little": [
    "parum"
  ],
  "not quite": [
    "parum",
    "neutiquam"
  ],
  "fodder": [
    "pābulum -ī n"
  ],
  "leisure": [
    "ōtium -ī n",
    "ōtium, ōtiī"
  ],
  "pray": [
    "ōrō, ōrāre, ōrāvī, ōrātus",
    "precor, precārī, precātus sum",
    "Ora",
    "ōrō, ōrāre, ōrāvī, ōrātum"
  ],
  "beg": [
    "ōrō, ōrāre, ōrāvī, ōrātus",
    "Ora",
    "ōrō, ōrāre, ōrāvī, ōrātum",
    "exposcō, exposcere, expoposcī",
    "petō, petere, petīvī, petītum"
  ],
  "to do harm": [
    "noceō, nocēre, nocuī, nocitūrus"
  ],
  "inflict injury": [
    "noceō, nocēre, nocuī, nocitūrus"
  ],
  "do hurt": [
    "noceō, nocēre, nocuī, nocitūrus"
  ],
  "worthless": [
    "nēquam"
  ],
  "business": [
    "negōtium -ī n",
    "rēs, reī",
    "rēs, reī (f)",
    "negōtiālis, negōtiāle"
  ],
  "activity": [
    "negōtium -ī n",
    "mōbilitas, mōbilitātis",
    "mōbilitās, mōbilitātis"
  ],
  "neglect": [
    "neglegō, neglegere, neglēxī, neglēctum",
    "neglegentia, neglegentiae",
    "praetereō, praeterīre, praeteriī, praeteritum"
  ],
  "careless": [
    "neglegēns -entis adi",
    "sēcūrus, sēcūra, sēcūrum",
    "incūriōsus, incūriōsa, incūriōsum"
  ],
  "that not": [
    "nē",
    "quōminus"
  ],
  "lest": [
    "nē",
    "nēquandō"
  ],
  "reap": [
    "metō, metere, messuī, messum"
  ],
  "mow": [
    "metō, metere, messuī, messum"
  ],
  "harvest": [
    "metō, metere, messuī, messum",
    "Cape",
    "Sume",
    "Carpe",
    "messis, messis",
    "carpō, carpere, carpsī, carptum"
  ],
  "ripe": [
    "mātūrus -a -um",
    "mītis, mīte",
    "mītigō, mītigāre, mītigāvī, mītigātum"
  ],
  "regions": [
    "loca, locōrum"
  ],
  "parts": [
    "loca, locōrum"
  ],
  "wool": [
    "lāna -ae f",
    "vellus, velleris"
  ],
  "toil": [
    "labor -ōris m",
    "labōrō, labōrāre, labōrāvī, labōrātum",
    "labor, labōris",
    "labor, labōris (m)"
  ],
  "take trouble": [
    "labōrō, labōrāre, labōrāvī, labōrātum"
  ],
  "be in distress": [
    "labōrō, labōrāre, labōrāvī, labōrātum"
  ],
  "carry in": [
    "invehō, invehere, invēxī, invectus"
  ],
  "bring in": [
    "invehō, invehere, invēxī, invectus"
  ],
  "import": [
    "invehō, invehere, invēxī, invectus"
  ],
  "tool": [
    "īnstrūmentum, īnstrūmentī",
    "organum, organī"
  ],
  "instrument": [
    "īnstrūmentum, īnstrūmentī",
    "organum, organī"
  ],
  "inhuman": [
    "inhūmānus -a -um"
  ],
  "flock": [
    "grex, grēgis",
    "grex, grēgis (m)",
    "concurrō, concurrere, concurrī, concursum"
  ],
  "herd": [
    "grex, grēgis",
    "grex, grēgis (m)"
  ],
  "band": [
    "grex, grēgis",
    "manus, manūs",
    "vitta, vittae",
    "grex, grēgis (m)",
    "caterva, catervae",
    "coniūrō, coniūrāre, coniūrāvī, coniūrātum"
  ],
  "pregnant": [
    "gravida adi f",
    "fētus, fēta, fētum",
    "gravidus, gravida, gravidum"
  ],
  "corn": [
    "frūmentum -ī n",
    "frūmentārius, frūmentāria, frūmentārium"
  ],
  "grain": [
    "frūmentum -ī n",
    "grānum, grānī",
    "frūmentārius, frūmentāria, frūmentārium"
  ],
  "fruit": [
    "frūgēs, frūgum",
    "bāca, bācae",
    "pōmum, pōmī",
    "frūx, frūgis",
    "frūctus frūctūs",
    "frūctus, frūctūs",
    "frūctus, frūctūs (m)"
  ],
  "crops": [
    "frūgēs, frūgum",
    "sata, satōrum",
    "frūctus frūctūs"
  ],
  "fertile": [
    "fertilis -e"
  ],
  "sickle": [
    "falx -cis f"
  ],
  "estimate": [
    "exīstimō, exīstimāre, exīstimāvī, exīstimātum",
    "aestimō, aestimāre, aestimāvī, aestimātus",
    "disputō, disputāre, disputāvī, disputātum"
  ],
  "esteem": [
    "exīstimō, exīstimāre, exīstimāvī, exīstimātum",
    "honor, honōris",
    "dīligō, dīligere, dīlēxī, dīlēctum"
  ],
  "care": [
    "cūra -ae f",
    "cūra, cūrae",
    "cūra, cūrae (f)",
    "dīligentia, dīligentiae"
  ],
  "anxiety": [
    "cūra -ae f",
    "cūra, cūrae",
    "metus, metūs",
    "cūra, cūrae (f)",
    "metus, metūs (m)",
    "ānxietās, ānxietātis",
    "sollicitūdō, sollicitūdinis"
  ],
  "grow": [
    "crēscō, crēscere, crēvī, crētum",
    "vivat, crescat, floreat"
  ],
  "abundance": [
    "cōpia -ae f",
    "cōpia, cōpiae",
    "cōpia, cōpiae (f)",
    "ūbertās, ūbertātis",
    "satietās, satietātis",
    "plēnitūdō, plēnitūdinis",
    "fertilitas, fertilitātis"
  ],
  "lot": [
    "cōpia -ae f",
    "sors, sortis"
  ],
  "farmer": [
    "colōnus -ī m",
    "agricola -ae m",
    "Farmer",
    "Agricola",
    "agricola, agricolae",
    "redemptor, redemptōris",
    "agricola, agricolae (m)"
  ],
  "cultivate": [
    "colō, colere, coluī, cultum",
    "exarō, exarāre, exarāvī, exarātum",
    "ērudiō, ērudīre, ērudīvī, ērudītum"
  ],
  "around": [
    "circā",
    "circa"
  ],
  "surround": [
    "cingō, cingere, cīnxī, cīnctum",
    "circumdō, circumdare, circumdedī, circumdatum",
    "saepiō, saepīre, saepsī, saeptum",
    "amplector, amplectī, amplexus sum",
    "coerceō, coercēre, coercuī, coercitum",
    "circumsedeō, circumsedēre, circumsēdī, circumsessum",
    "circumveniō, circumvenīre, circumvēnī, circumventum"
  ],
  "rate": [
    "cēnseō, cēnsēre, cēnsuī, cēnsum"
  ],
  "assess": [
    "cēnseō, cēnsēre, cēnsuī, cēnsum"
  ],
  "warmth": [
    "calor -ōris m"
  ],
  "heat": [
    "calor -ōris m",
    "ardor, ardōris",
    "incendium, incendiī"
  ],
  "plough": [
    "arātrum -ī n",
    "septentriōnēs -um m pl",
    "arō, arāre, arāvī, arātum",
    "exarō, exarāre, exarāvī, exarātum",
    "sulcō, sulcāre, sulcāvī, sulcātum"
  ],
  "plowman": [
    "arātor, arātōris"
  ],
  "to plough": [
    "arō, arāre, arāvī, arātum"
  ],
  "lovely": [
    "amoenus -a -um",
    "bellus -a -um"
  ],
  "pleasant": [
    "amoenus -a -um",
    "iūcundus -a -um",
    "dulcis, dulce",
    "suāvis, suāve",
    "blandus, blanda, blandum",
    "lepidus, lepida, lepidum",
    "benignus, benigna, benignum",
    "iūcundus, iūcunda, iūcundum",
    "voluptārius, voluptāria, voluptārium"
  ],
  "peasant": [
    "agricola -ae m"
  ],
  "field": [
    "ager -grī m",
    "ager, agrī",
    "arvum, arvī",
    "ager, agrī (m)"
  ],
  "away from": [
    "abs v. ā/ab/abs",
    "ab, ā",
    "ab, ā (prep + abl)"
  ],
  "abyss": [
    "vorāgō, vorāginis",
    "specus, specūs",
    "profundum, profundī"
  ],
  "whirlpool": [
    "vorāgō, vorāginis",
    "gurges, gurgitis",
    "vertex, verticis"
  ],
  "avoid": [
    "vītō, vītāre, vītāvī, vītātum",
    "fugiō, fugere, fūgī, fugitūrum",
    "dēvītō, dēvītāre, dēvītāvī, dēvītātum"
  ],
  "night watch": [
    "vigilia -ae f"
  ],
  "move about": [
    "versor, versārī, versātus sum"
  ],
  "even as": [
    "velut"
  ],
  "like as": [
    "velut"
  ],
  "…or…? whether…or": [
    "utrum…an"
  ],
  "entire": [
    "ūniversus, ūniversa, ūniversum",
    "tōtus, tōta, tōtum"
  ],
  "safe": [
    "salvus -a -um",
    "tūtus, tūta, tūtum",
    "incolumis -e",
    "Arca Secura",
    "salvus, salva, salvum",
    "Depositum Syngrapharum"
  ],
  "throng": [
    "turba -ae f",
    "frequentia, frequentiae",
    "celebrō, celebrāre, celebrāvī, celebrātum"
  ],
  "crowd": [
    "turba -ae f",
    "turba, turbae",
    "exāmen, exāminis",
    "caterva, catervae",
    "turba, turbae (f)",
    "frequentia, frequentiae",
    "celēbritās, celēbritātis",
    "celebrō, celebrāre, celebrāvī, celebrātum"
  ],
  "make an uproar": [
    "tumultuor, tumultuārī, tumultuātus sum"
  ],
  "calmness": [
    "tranquillitās tranquillitātis"
  ],
  "flute-player": [
    "tībīcen, tībīcinis"
  ],
  "flute": [
    "tībiae -ārum f pl",
    "Tibia",
    "Syrinx"
  ],
  "rouse": [
    "suscitō, suscitāre, suscitāvī, suscitātus",
    "adhortor, adhortārī, adhortātus sum"
  ],
  "deaf": [
    "surdus -a -um"
  ],
  "hope": [
    "spērō, spērāre, spērāvī, spērātum",
    "spēs -eī f",
    "spēs, speī",
    "vōtum, vōtī",
    "spēs, speī (f)"
  ],
  "be a slave": [
    "serviō, servīre, servīvī, servītus"
  ],
  "unharmed": [
    "salvus -a -um",
    "incolumis -e",
    "illaesus, illaesa, illaesum"
  ],
  "reign": [
    "rēgnō, rēgnāre, rēgnāvī, rēgnātum",
    "rēgnum, rēgnī"
  ],
  "fortieth": [
    "quadrāgēsimus -a -um"
  ],
  "robber": [
    "praedō, praedōnis",
    "lātrunculus, lātrunculī"
  ],
  "pirate": [
    "praedō, praedōnis",
    "pīrāta -ae m",
    "Pirata"
  ],
  "power": [
    "potestās -ātis f",
    "vīs, acc vim, abl vī",
    "Potestas",
    "vīs, vīs",
    "opēs, opum",
    "rēgnum, rēgnī",
    "diciō, diciōnis",
    "opēs, opum (f. pl.)",
    "potentia, potentiae"
  ],
  "ghost": [
    "phantasma, phantasmatis",
    "Phantasma"
  ],
  "apparition": [
    "phantasma, phantasmatis"
  ],
  "get to": [
    "perveniō, pervenīre, pervēnī, perventus",
    "īnsinuō, īnsinuāre, īnsinuāvī, īnsinuātum"
  ],
  "reach": [
    "the Reach",
    "perveniō, pervenīre, pervēnī, perventus",
    "adipīscor, adipīscī, adeptus sum"
  ],
  "persuade": [
    "persuādeō, persuādēre, persuāsī, persuāsus",
    "Persuade",
    "persuādeō, persuādēre, persuāsī, persuāsum + dat"
  ],
  "convince": [
    "persuādeō, persuādēre, persuāsī, persuāsus",
    "convincō, convincere, convīcī, convictum",
    "persuādeō, persuādēre, persuāsī, persuāsum + dat"
  ],
  "perish": [
    "pereō, perīre, periī, peritum",
    "dispereō, disperīre, disperiī"
  ],
  "be lost": [
    "pereō, perīre, periī, peritum"
  ],
  "danger": [
    "perīculum -ī n",
    "perīculum, perīculī",
    "perīculum, perīculī (n)"
  ],
  "peril": [
    "perīculum -ī n"
  ],
  "perilous": [
    "perīculōsus -a -um"
  ],
  "dangerous": [
    "perīculōsus -a -um",
    "īnfēstus -a -um",
    "īnfestus, īnfesta, īnfestum"
  ],
  "money given to slaves": [
    "pecūlium -ī n"
  ],
  "boat": [
    "nāvicula -ae f",
    "nāvis, nāvis",
    "nāvigium, nāvigiī"
  ],
  "born": [
    "nātus, nāta, nātum",
    "nāta, nātae",
    "nāscī, nātus sum",
    "nāscor, nāscī, -",
    "nāscor, nāscī, nātus sum"
  ],
  "be born": [
    "nāscī, nātus sum",
    "nāscor, nāscī, -",
    "nāscor, nāscī, nātus sum"
  ],
  "dumb": [
    "mūtus -a -um"
  ],
  "mute": [
    "mūtus -a -um"
  ],
  "speechless": [
    "mūtus -a -um"
  ],
  "world": [
    "mundus -ī m",
    "mundus, mundī",
    "orbis terrārum",
    "mundus, mundī (m)"
  ],
  "universe": [
    "mundus -ī m",
    "mundus, mundī",
    "mundus, mundī (m)"
  ],
  "mortal": [
    "mortālis -e",
    "Mortalis",
    "lētālis, lētāle",
    "mortālis, mortāle"
  ],
  "die": [
    "morior, morī, mortuus sum",
    "ālea, āleae",
    "obeō, obīre, obiī, obitum",
    "occidō, occidere, occidī, occāsum",
    "morior, morī, mortuus sum (fut. act. partic. moritūrus)"
  ],
  "remember": [
    "memorō, memorāre, memorāvī, memorātus",
    "meminī, meminisse",
    "reminīscō, reminīscere, rementum",
    "recordor, recordārī, recordātus sum"
  ],
  "prefer": [
    "mālō, mālle, māluī",
    "optō, optāre, optāvī, optātus",
    "praeferō, praeferre, praetulī, praelātum",
    "antepōnō, antepōnere anteposuī, antepositum"
  ],
  "little book": [
    "libellus -ī m",
    "libellus, libellī",
    "libellus, libellī (m)"
  ],
  "the underworld": [
    "īnferī, īnferōrum"
  ],
  "the dead": [
    "īnferī, īnferōrum"
  ],
  "hang over": [
    "impendeō, impendēre, ----, ----"
  ],
  "threaten": [
    "impendeō, impendēre, ----, ----",
    "minor, minārī, minātus sum",
    "minitor, minitārī, minitātus sum"
  ],
  "immortal": [
    "immortālis, immortāle"
  ],
  "strait": [
    "fretum -ī n"
  ],
  "rumor": [
    "fāma -ae f",
    "rūmor -ōris m",
    "fāma, fāmae",
    "rūmor, rūmōris",
    "fāma, fāmae (f)",
    "rūmor, rūmōris (m.)"
  ],
  "reputation": [
    "fāma -ae f",
    "fāma, fāmae",
    "fāma, fāmae (f)",
    "opīniō, opīniōnis",
    "dīgnitās dīgnitātis"
  ],
  "fame": [
    "fāma -ae f",
    "fāma, fāmae",
    "laus, laudis",
    "fāma, fāmae (f)",
    "glōria, glōriae",
    "nōtitia, nōtitiae",
    "glōria, glōriae (f)",
    "nōbilitās, nōbilitātis"
  ],
  "ill repute": [
    "fāma -ae f"
  ],
  "stretch out": [
    "extendō, extendere, extendī, extentum",
    "intendō, intendere, intendī, intentum",
    "distendō, distendere, distendī, distentum"
  ],
  "extend": [
    "extendō, extendere, extendī, extentum",
    "laxō, laxāre, laxāvī, laxātum",
    "pandō, pandere, pandī, passum",
    "pertineō, pertinēre, pertinuī",
    "tendō, tendere, tendī, tentus",
    "ampliō, ampliāre, ampliāvī, ampliātum",
    "dīlātō, dīlātāre, dīlātāvī, dīlātātum",
    "porrigō, porrigere, porrexī, porrectum",
    "distendō, distendere, distendī, distentum",
    "perhibeō, perhibēre, perhibuī, perhibitum",
    "prōlātō, prōlātāre, prōlātāvī, prōlātātum",
    "praetendō, praetendere, praetetendī, praetentum",
    "amplificō, amplificāre, amplificāvī, amplificātum"
  ],
  "unroll": [
    "ēvolvō, ēvolvere, ēvolvī, ēvolūtum",
    "revolvō, revolvere, revolvī, revolūtum"
  ],
  "throw out": [
    "ēiciō, ēicere, ēiēcī, ēiectum",
    "ēiectō, ēiectāre, ēiectāvī, ēiectātum"
  ],
  "saying": [
    "dictum, dictī",
    "fātus, fātūs",
    "dictiō, dictiōnis",
    "prōverbium, prōverbiī"
  ],
  "words": [
    "dictum, dictī"
  ],
  "steady": [
    "cōnstāns, cōnstantis",
    "stabilis, stabile"
  ],
  "firm": [
    "cōnstāns, cōnstantis",
    "stabilis, stabile",
    "firmus, firma, firmum",
    "fīrmus, fīrma, fīrmum"
  ],
  "lame": [
    "claudus -a -um",
    "claudeō, claudēre"
  ],
  "leave off": [
    "cessō, cessāre, cessāvī, cessātum",
    "dēsistō, dēsistere, dēstitī, dēstitus"
  ],
  "cease": [
    "cessō, cessāre, cessāvī, cessātum",
    "dēsistō, dēsistere, dēstitī, dēstitus"
  ],
  "blind": [
    "caecus -a -um",
    "Blind",
    "caecus, caeca, caecum",
    "excaecō, excaecāre, excaecāvī, excaecātum"
  ],
  "attentive": [
    "attentus -a -um",
    "intentus, intenta, intentum",
    "officiōsus, officiōsa, officiōsum"
  ],
  "worship": [
    "adōrō, adōrāre, adōrāvī, adōrātum",
    "veneror, venerārī, venerātus sum"
  ],
  "adore": [
    "adōrō, adōrāre, adōrāvī, adōrātum",
    "veneror, venerārī, venerātus sum"
  ],
  "admire": [
    "admīror, admīrārī, admīrātus sum",
    "mīror, mīrārī, mīrātus sum"
  ],
  "wonder at": [
    "admīror, admīrārī, admīrātus sum"
  ],
  "< iacere": [
    "-iciō, -icere, -iēcī, -iectum"
  ],
  "of you": [
    "vestrum"
  ],
  "rapid": [
    "vēlōx -ōcis",
    "rapidus -a -um",
    "celer, celeris, celere",
    "festīnus, festīna, festīnum"
  ],
  "tyrant": [
    "tyrannus -ī m",
    "tyrannus, tyrannī",
    "tyrannus, tyrannī (m)"
  ],
  "sadness": [
    "trīstitia -ae f",
    "maestitia, maestitiae"
  ],
  "look up": [
    "suspiciō, suspicere, suspexī, suspectus"
  ],
  "steal": [
    "surripiō, surripere, surripuī, surreptum",
    "Furare",
    "Furandum",
    "fūror, fūrārī, fūrātus sum",
    "dīripiō, dīripere, dīripuī, dīreptum"
  ],
  "take secretly": [
    "surripiō, surripere, surripuī, surreptum"
  ],
  "go under": [
    "subeō, subīre, subiī"
  ],
  "be stunned": [
    "stupeō, stupēre, stupuī, ----"
  ],
  "be aghast": [
    "stupeō, stupēre, stupuī, ----"
  ],
  "be amazed": [
    "stupeō, stupēre, stupuī, ----"
  ],
  "cut": [
    "secō, secāre, secuī, sectus",
    "caedō, caedere, cecidī, caesum"
  ],
  "health": [
    "salūs -ūtis f",
    "valētūdō -inis f",
    "Salus",
    "Health",
    "salūs, salūtis",
    "sānitās, sānitātis"
  ],
  "safety": [
    "salūs -ūtis f",
    "salūs, salūtis",
    "sēcūritās, sēcūritātis"
  ],
  "well-being": [
    "salūs -ūtis f"
  ],
  "oar": [
    "rēmus -ī m"
  ],
  "stay behind": [
    "remaneō, remanēre, remānsī, —",
    "maneō, manēre, mānsī, mānsum",
    "remaneō, remanēre, remānsī, remānsum"
  ],
  "lead back": [
    "redūcō, redūcere, redūxī, reductus"
  ],
  "bring back": [
    "redūcō, redūcere, redūxī, reductus",
    "retrahō, retrahere, retrāxī, retrāctus",
    "referō, referre, rettulī, relātus",
    "referō, referre, rettulī, relātum",
    "reconciliō, reconciliāre, reconciliāvī, reconciliātum"
  ],
  "rushing": [
    "rapidus -a -um"
  ],
  "complain lament": [
    "queror, querī, questus sum"
  ],
  "bewail": [
    "queror, querī, questus sum"
  ],
  "express grief": [
    "queror, querī, questus sum"
  ],
  "grumble": [
    "queror, querī, questus sum"
  ],
  "as if": [
    "quasi",
    "tamquam",
    "quasi (adv. or conj.)"
  ],
  "precious": [
    "pretiōsus -a -um"
  ],
  "fisherman": [
    "piscātor -ōris m",
    "Piscator"
  ],
  "thoroughly disturb": [
    "perturbō, perturbāre, perturbāvī, perturbātus"
  ],
  "upset": [
    "perturbō, perturbāre, perturbāvī, perturbātus",
    "subvertō, subvertere, subvertī, subversum"
  ],
  "confuse": [
    "perturbō, perturbāre, perturbāvī, perturbātus"
  ],
  "move deeply": [
    "permoveō, permovēre, permōvī, permōtus"
  ],
  "permit": [
    "permittō, permittere, permīsī, permissus",
    "patior, patī, passus sum"
  ],
  "spare": [
    "parcō, parcere, pepercī, parsus",
    "parcō, parcere, pepercī, parsūrum + dat"
  ],
  "known": [
    "nōtus -a -um",
    "obvius, obvia, obvium"
  ],
  "famous": [
    "nōbilis -e",
    "nōtus -a -um",
    "ille, illa, illud",
    "clārus, clāra, clārum",
    "fāmōsus, fāmōsa, fāmōsum",
    "inclitus, inclita, inclitum",
    "praeclārus, praeclāra, praeclārum"
  ],
  "notorious": [
    "nōbilis -e",
    "nōtus -a -um",
    "īnfāmis, īnfāme"
  ],
  "of us": [
    "nostrum"
  ],
  "sometimes": [
    "nōnnumquam",
    "interdum",
    "nonnunquam"
  ],
  "well known": [
    "nōbilis -e"
  ],
  "high-born": [
    "nōbilis -e"
  ],
  "noble": [
    "nōbilis -e",
    "Nobilis",
    "procer, proceris",
    "sublīmis, sublīme",
    "generōsus, generōsa, generōsum",
    "pātricius, pātricia, pātricium",
    "magnanimus, magnanima, magnanimum"
  ],
  "excellent": [
    "nōbilis -e",
    "ēgregius -a -um",
    "prīmārius, prīmāria, prīmārium",
    "praestō, praestāre, praestitī, praestatus"
  ],
  "sailing": [
    "nāvigātiō -ōnis f"
  ],
  "voyage": [
    "nāvigātiō -ōnis f"
  ],
  "surprising": [
    "mīrus -a -um"
  ],
  "strange": [
    "mīrus -a -um",
    "novus, nova, novum",
    "externus, externa, externum",
    "adventīcius, adventīcia, adventīcium",
    "prōdigiōsus, prōdigiōsa, prōdigiōsum"
  ],
  "evil deed": [
    "maleficium -ī n",
    "scelus, sceleris"
  ],
  "crime": [
    "maleficium -ī n",
    "scelus -eris n",
    "vitium, vitiī",
    "scelus, sceleris",
    "dēlictum, dēlictī",
    "piāculum, piāculī",
    "vitium, vitiī (n)",
    "facinus, facinoris"
  ],
  "sorrowful": [
    "maestus -a -um",
    "trīstis, trīste",
    "luctuōsus, luctuōsa, luctuōsum"
  ],
  "profit": [
    "lucrum -ī n",
    "frūctus, frūctūs",
    "quaestus, quaestūs",
    "frūctus, frūctūs (m)",
    "prōfectus, prōfectūs",
    "lucror, lucrārī, lucrātus sum"
  ],
  "gain": [
    "lucrum -ī n",
    "fēnus, fēnoris",
    "quaestus, quaestūs",
    "lucror, lucrārī, lucrātus sum"
  ],
  "joy": [
    "laetitia -ae f",
    "gaudium -ī n",
    "fēstīvitās, fēstīvitātis",
    "grātulātiō, grātulātiōnis"
  ],
  "envy": [
    "invidia -ae f",
    "invideō, invidēre, invīdī, invīsum",
    "invidia, invidiae",
    "invidia, invidiae (f.)",
    "invidentia, invidentiae",
    "malevolentia, malevolentiae",
    "invideō, invidēre, invīdī, invisus"
  ],
  "grudge": [
    "invideō, invidēre, invīdī, invīsum",
    "invideō, invidēre, invīdī, invisus"
  ],
  "unknown": [
    "ignōtus -a -um",
    "Inscitus",
    "ignōbilis, ignōbile",
    "incognitus, incognita, incognitum",
    "invīsitātus, invīsitāta, invīsitātum"
  ],
  "unaware": [
    "ignārus -a -um",
    "inopīnāns, inopīnantis",
    "īnscius, īnscia, īnscium",
    "necopīnans, necopīnantis",
    "nescius, nescia, nescium"
  ],
  "throwing away": [
    "iactūra -ae f",
    "iactura, iacturae"
  ],
  "loss": [
    "iactūra -ae f",
    "āmissiō, āmissiōnis",
    "calamitās, calamitātis",
    "dētrīmentum, dētrīmentī"
  ],
  "o! alas": [
    "heu"
  ],
  "theft": [
    "fūrtum -ī n",
    "Furtum"
  ],
  "thief": [
    "fūr -is m",
    "Fur"
  ],
  "bottom": [
    "fundus -ī m",
    "funditus"
  ],
  "foundation": [
    "fundus -ī m",
    "basis, basis",
    "crepīdō, crepīdinis",
    "fundāmentum, fundāmentī"
  ],
  "farm": [
    "fundus -ī m",
    "ager, agrī",
    "ager, agrī (m)"
  ],
  "piece of land": [
    "fundus -ī m"
  ],
  "in vain": [
    "frūstrā",
    "nēquīquam"
  ],
  "fortune": [
    "fortūna -ae f",
    "fortūna, fortūnae",
    "fortūna, fortūnae (f)"
  ],
  "to limit": [
    "fīniō, fīnīre, fīnīvī, fīnītus"
  ],
  "to define": [
    "fīniō, fīnīre, fīnīvī, fīnītus"
  ],
  "to mark out boundaries": [
    "fīniō, fīnīre, fīnīvī, fīnītus"
  ],
  "lyre-player": [
    "fidicen -inis m"
  ],
  "lyre": [
    "fidēs -ium f pl",
    "lyra, lyrae",
    "cinyra, cinyrae",
    "cithara, citharae"
  ],
  "fortunate": [
    "fēlīx -īcis",
    "fēlīx, gen. fēlīcis",
    "beātus, beāta, beātum",
    "fortūnātus, fortūnāta, fortūnātum"
  ],
  "lucky": [
    "fēlīx -īcis",
    "fēlīx, gen. fēlīcis",
    "faustus, fausta, faustum",
    "fortūnātus, fortūnāta, fortūnātum"
  ],
  "good fortune": [
    "fēlīcitās -ātis f"
  ],
  "luck": [
    "fēlīcitās -ātis f",
    "fōrs, fōrtis",
    "fortūna, fortūnae",
    "fortūna, fortūnae (f)"
  ],
  "deceitful": [
    "fallāx -ācis",
    "mendāx, mendācis",
    "vānus, vāna, vānum",
    "dolōsus, dolōsa, dolōsum",
    "perfidus, perfida, perfidum",
    "īnsidiōsus, īnsidiōsa, īnsidiōsum",
    "fraudulentus, fraudulenta, fraudulentum"
  ],
  "put out": [
    "expōnō, expōnere, exposuī, expositus",
    "restinguō, restinguere, restinxī, restinctum"
  ],
  "ashore": [
    "expōnō, expōnere, exposuī, expositus"
  ],
  "expose": [
    "expōnō, expōnere, exposuī, expositus",
    "expōnō, expōnere, exposuī, expositum",
    "apertō, apertāre, apertāvī, apertātus",
    "patefaciō, patefacere, patefēcī, patefactum"
  ],
  "snatch away": [
    "ēripiō, ēripere, ēripuī, ēreptum",
    "ēripiō, ēripere, ēripuī, ēreptum (ē + rapiō)"
  ],
  "take by force": [
    "ēripiō, ēripere, ēripuī, ēreptum"
  ],
  "rescue": [
    "ēripiō, ēripere, ēripuī, ēreptum",
    "Subventio",
    "ēripiō, ēripere, ēripuī, ēreptum (ē + rapiō)"
  ],
  "present with": [
    "dōnō, dōnāre, dōnāvī, dōnātus"
  ],
  "riches": [
    "dīvitiae -ārum f pl",
    "dīvitiae, dīvitiārum",
    "dīvitiae, dīvitiārum (f)"
  ],
  "pull off": [
    "dētrahō, dētrahere, dētrāxī, dētrāctus"
  ],
  "deter": [
    "dēterreō, dēterrēre, dēterruī, dēterritus",
    "absterreō, absterrēre, absterruī, absterritum"
  ],
  "lose hope": [
    "dēspērō, dēspērāre, dēspērāvī, dēspērātus"
  ],
  "despair": [
    "dēspērō, dēspērāre, dēspērāvī, dēspērātus",
    "dēspērātiō, dēspērātiōnis"
  ],
  "jump down": [
    "dēsiliō, dēsilīre, dēsiluī, dēsultus"
  ],
  "dolphin": [
    "delphīnus -ī m"
  ],
  "song": [
    "carmen -inis n",
    "carmen, carminis",
    "canticum, canticī",
    "carmen, carminis (n)"
  ],
  "poem": [
    "carmen -inis n",
    "poēma, poēmatis",
    "carmen, carminis",
    "carmen, carminis (n)"
  ],
  "singing": [
    "cantus -ūs m",
    "concentus, concentūs"
  ],
  "music": [
    "cantus -ūs m",
    "Musica",
    "mūsica, mūsicae"
  ],
  "benefit": [
    "beneficium -ī n",
    "Beneficium",
    "frūctus, frūctūs",
    "frūctus, frūctūs (m)",
    "beneficium, beneficiī",
    "beneficium, beneficiī (n)"
  ],
  "favor": [
    "beneficium -ī n",
    "grātia -ae f",
    "faveō, favēre, fāvī, fautūrus",
    "beneficium, beneficiī",
    "beneficium, beneficiī (n)"
  ],
  "attract": [
    "alliciō, allicere, allexī, allectum"
  ],
  "affect": [
    "afficiō, afficere, affēcī, affectum",
    "moveō, movēre, mōvī, mōtum"
  ],
  "to throw to": [
    "adiciō, adicere, adiēcī, adiectus"
  ],
  "cast to": [
    "adiciō, adicere, adiēcī, adiectus"
  ],
  "fling at": [
    "adiciō, adicere, adiēcī, adiectus"
  ],
  "put to": [
    "adiciō, adicere, adiēcī, adiectus"
  ],
  "set near": [
    "adiciō, adicere, adiēcī, adiectus"
  ],
  "keep away from": [
    "abstineō, abstinēre, abstinuī, abstentus"
  ],
  "keep off of": [
    "abstineō, abstinēre, abstinuī, abstentus"
  ],
  "abstain from": [
    "abstineō, abstinēre, abstinuī, abstentus"
  ],
  "throw away": [
    "abiciō, abicere, abiēcī, abiectus"
  ],
  "to look askance at": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "to cast an evil eye upon": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "to be prejudiced against": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "to envy": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "go and see": [
    "vīsō, vīsere, vīsī, vīsus"
  ],
  "visit": [
    "vīsō, vīsere, vīsī, vīsus",
    "obitus, obitūs",
    "invīsō, invīsere, invīsī, invīsum",
    "congredior, congredī, congressus sum"
  ],
  "vessel": [
    "vās vāsis n, pl -a -ōrum",
    "Calparem",
    "vāsum, vāsī",
    "amphora, amphorae",
    "nāvigium, nāvigiī"
  ],
  "bowl": [
    "vās vāsis n, pl -a -ōrum",
    "Patera",
    "catīnum, catīnī"
  ],
  "dining-room": [
    "trīclīnium -ī n"
  ],
  "slow": [
    "tardus -a -um",
    "Slow",
    "Lentus",
    "Lentior",
    "sēgnis, sēgne"
  ],
  "late": [
    "tardus -a -um",
    "sērus, sēra, sērum",
    "sērōtinus, sērōtina, sērōtinum"
  ],
  "spread": [
    "sternō, sternere, strāvī, strātum",
    "tendō, tendere, tendī, tentus"
  ],
  "lay out": [
    "sternō, sternere, strāvī, strātum"
  ],
  "thirst": [
    "sitis -is f"
  ],
  "quite": [
    "sānē",
    "bene"
  ],
  "salt": [
    "sāl salis m",
    "sāl, salis"
  ],
  "wit": [
    "sāl salis m",
    "vidēlicet",
    "sāl, salis",
    "facētia, facētiae"
  ],
  "bring forth": [
    "efferō, efferre, extulī, ēlātum",
    "prōferō, prōferre, prōtulī, prōlātus",
    "gīgnō gīgnere genuī genitum"
  ],
  "be in charge": [
    "praesum, praeesse, praefuī, praefutūrus"
  ],
  "carry through": [
    "perferō, perferre, pertulī, perlātus"
  ],
  "bear to the end": [
    "perferō, perferre, pertulī, perlātus"
  ],
  "for a short time": [
    "paulisper"
  ],
  "prepare": [
    "parō, parāre, parāvī, parātus",
    "parō, parāre, parāvī, parātum",
    "praeparō, praeparāre, praeparāvī, praeparātum",
    "accommodō, accommodāre, accommodāvī, accommodātum"
  ],
  "make ready": [
    "parō, parāre, parāvī, parātus"
  ],
  "obtain": [
    "parō, parāre, parāvī, parātus",
    "parō, parāre, parāvī, parātum"
  ],
  "nut": [
    "nux nucis f",
    "glāns, glandis"
  ],
  "announce": [
    "nūntiō, nūntiāre, nūntiāvī, nūntiātum",
    "nūntiō, nūnitāre, nūntiāvī, nūntiātum",
    "adnūntiō, adnūntiāre, adnūntiāvī, adnūntiātum",
    "dēnuntiō, dēnuntiāre, dēnuntiāvī, dēnuntiātum",
    "prōnūntiō, prōnūntiāre, prōnūntiāvī, prōnūntiātum"
  ],
  "report": [
    "nūntiō, nūntiāre, nūntiāvī, nūntiātum",
    "fāma, fāmae",
    "fāma, fāmae (f)",
    "opīniō, opīniōnis",
    "ferō, ferre, tulī, lātum",
    "narrō, nārrāre, nārrāvī, nārrātum",
    "referō, referre, rettulī, relātum",
    "nūntiō, nūnitāre, nūntiāvī, nūntiātum",
    "ēnuntiō, ēnuntiāre, ēnuntiāvī, ēnuntiātum",
    "adnūntiō, adnūntiāre, adnūntiāvī, adnūntiātum"
  ],
  "annoying": [
    "molestus -a -um"
  ],
  "troublesome": [
    "molestus -a -um",
    "īnfēstus -a -um",
    "difficilis, difficile",
    "incommodus, incommoda, incommodum"
  ],
  "tiresome": [
    "molestus -a -um"
  ],
  "mix": [
    "misceō, miscēre, miscuī, mixtum"
  ],
  "undiluted": [
    "merus -a -um",
    "merus, mera, merum"
  ],
  "neat wine": [
    "merum -ī n"
  ],
  "second course": [
    "mēnsa secunda"
  ],
  "at which much wine was drunk": [
    "mēnsa secunda"
  ],
  "honey": [
    "mel mellis n",
    "Mel"
  ],
  "freedman": [
    "lībertīnus -ī m",
    "lībertus, lībertī"
  ],
  "set free": [
    "līberō, līberāre, līberāvī, līberātum"
  ],
  "delightful": [
    "iūcundus -a -um",
    "dēlectābilis, dēlectābile",
    "iūcundus, iūcunda, iūcundum",
    "dēlicātus, dēlicāta, dēlicātum",
    "periūcundus, periūcunda, periūcundum",
    "voluptārius, voluptāria, voluptārium"
  ],
  "journey": [
    "iter itineris n",
    "cursus -ūs m",
    "iter, itineris",
    "profectiō, profectiōnis"
  ],
  "unexpected": [
    "inexspectātus -a -um",
    "incautus, incauta, incautum",
    "imprōvīsus, imprōvīsa, imprōvīsum",
    "inopīnātus, inopīnāta, inopīnātum",
    "īnspērātus, īnspērāta, īnspērātum",
    "repentīnus, repentīna, repentīnum",
    "necopīnātus, necopīnāta, necopīnātum"
  ],
  "dressed in": [
    "indūtus",
    "indūtus (+abl)"
  ],
  "guest": [
    "hospes -itis m",
    "convīva -ae m/f",
    "parasītus, parasītī"
  ],
  "guest-friend": [
    "hospes -itis m"
  ],
  "vegetable": [
    "holus -eris n"
  ],
  "taste": [
    "gustō, gustāre, gustāvī, gustātum",
    "sapor, sapōris",
    "ēlegantia, ēlegantiae",
    "lībō, lībāre, lībāvī, lībātum",
    "dēgustō, dēgustāre, dēgustāvī, dēgustātum"
  ],
  "glorious": [
    "glōriōsus -a -um"
  ],
  "boastful": [
    "glōriōsus -a -um"
  ],
  "kind": [
    "genus -eris n",
    "cōmis, cōme",
    "genus, generis",
    "bonus, bona, bonum",
    "hūmānus, hūmāna, hūmānum",
    "benignus, benigna, benignum",
    "benevolus, benevola, benevolum",
    "propitius, propitia, propitium"
  ],
  "sort": [
    "genus -eris n",
    "speciēs -ēī f",
    "Organiza",
    "genus, generis"
  ],
  "pour": [
    "fundō, fundere, fūdī, fūsum",
    "cōnfundō, cōnfundere, cōnfūdī, cōnfūsum"
  ],
  "shed": [
    "fundō, fundere, fūdī, fūsum",
    "effundō, effundere, effūdī, effūsus",
    "casa -ae f",
    "testūdō, testūdinis",
    "mānō, mānāre, mānāvī, mānātum"
  ],
  "hunger": [
    "famēs -is f",
    "aviditās, aviditātis"
  ],
  "famine": [
    "famēs -is f"
  ],
  "decorate": [
    "exōrnō, exōrnāre, exōrnāvī, exōrnātum",
    "decorō, decorāre, decorāvī, decorātum",
    "honōrō, honōrāre, honōrāvī, honōrātum"
  ],
  "for my part": [
    "equidem"
  ],
  "choose": [
    "ēligō, ēligere, ēlēgī, ēlēctum",
    "optō, optāre, optāvī, optātus",
    "Elige",
    "legō, legere, lēgī, lēctum",
    "creō, creāre, creāvī, creātum",
    "dēligō, dēligere, dēlēgī, dēlēctum",
    "adoptō, adoptāre, adoptāvī, adoptātum",
    "cooptō, cooptāre, cooptāvī, cooptātum",
    "excerpō, excerpere, excerpsī, excerptum"
  ],
  "select": [
    "ēligō, ēligere, ēlēgī, ēlēctum",
    "optō, optāre, optāvī, optātus",
    "Elige",
    "eximius, eximia, eximium",
    "sēligō, sēligere, sēlēgī, sēlēctum",
    "adoptō, adoptāre, adoptāvī, adoptātum",
    "excerpō, excerpere, excerpsī, excerptum"
  ],
  "sweet": [
    "dulcis -e",
    "mītis, mīte",
    "dulcis, dulce",
    "suāvis, suāve"
  ],
  "careful": [
    "dīligēns -entis adi",
    "dīligēns, gen. dīligentis",
    "cūriōsus, cūriōsa, cūriōsum"
  ],
  "diligent": [
    "dīligēns -entis adi",
    "nāvus, nāva, nāvum",
    "cōnficiēns, cōnficiēntis",
    "sēdulus, sēdula, sēdulum",
    "dīligēns, gen. dīligentis",
    "cūriōsus, cūriōsa, cūriōsum"
  ],
  "kitchen": [
    "culīna -ae f",
    "Culina",
    "Kitchen"
  ],
  "cook": [
    "cocus -ī m",
    "coquō, coquere, coxī, coctum"
  ],
  "dinner-party": [
    "convīvium -ī n"
  ],
  "draw together": [
    "contrahō, contrahere, contrāxī, contrāctum"
  ],
  "wrinkle": [
    "contrahō, contrahere, contrāxī, contrāctum",
    "rūga, rūgae"
  ],
  "dine": [
    "cēnō, cēnāre, cēnāvī, cēnātus",
    "cēnō, cēnāre, cēnāvī, cēnātum"
  ],
  "have dinner": [
    "cēnō, cēnāre, cēnāvī, cēnātus"
  ],
  "dinner": [
    "cēna -ae f",
    "cēna, cēnae",
    "cēna, cēnae (f)"
  ],
  "flesh": [
    "carō carnis f",
    "Pellis"
  ],
  "meat": [
    "carō carnis f"
  ],
  "blessing": [
    "bonum -ī n",
    "benedictiō, benedictiōnis"
  ],
  "bath": [
    "balneum -ī n"
  ],
  "bathroom": [
    "balneum -ī n"
  ],
  "sprinkle": [
    "aspergō, aspergere, aspersī, aspersum",
    "lībō, lībāre, lībāvī, lībātum",
    "cōnspergō, cōnspergere, cōnspersī, cōnspersum"
  ],
  "silver": [
    "argentum -ī n"
  ],
  "silver-": [
    "argenteus -a -um"
  ],
  "of silver": [
    "argenteus -a -um"
  ],
  "sharp": [
    "acūtus -a -um",
    "ācer -cris -cre",
    "ācer, ācris, ācre"
  ],
  "sour": [
    "acerbus -a -um"
  ],
  "bitter": [
    "acerbus -a -um",
    "ācer -cris -cre",
    "amārus, amāra, amārum",
    "acerbus, acerba, acerbum"
  ],
  "lie down at table": [
    "accumbō, accumbere, accubuī, accubitum"
  ],
  "forbid": [
    "vetō, vetāre, vetāvī, vetātus",
    "abdīcō, abdīcere, abdīxī, abdictum",
    "interdīcō, interdīcere, interdīxī, interdīctum"
  ],
  "punishment": [
    "poena -ae f",
    "supplicium -ī",
    "poena, poenae",
    "poena, poenae (f)",
    "supplicium -ī (n)",
    "castīgātiō, castīgātiōnis"
  ],
  "determine": [
    "statuō, statuere, statuī, statūtum",
    "dēcernō, dēcernere, dēcrēvī, dēcrētum",
    "cōnscīscō, cōnscīscere, cōnscīvī, cōnscītum"
  ],
  "old man": [
    "senex senis m",
    "senex, gen. senis",
    "senex, gen. senis (adj and noun)"
  ],
  "criminal": [
    "scelestus -a -um",
    "Criminalis",
    "crīmen, crīminis",
    "incestus, incesta, incestum",
    "iniūriōsus, iniūriōsa, iniūriōsum"
  ],
  "wise": [
    "sapiēns -entis",
    "sapiō, sapere, sapīvī",
    "sapiēns, gen. sapientis",
    "sapiō, sapere, sapīvī, ----"
  ],
  "pull back": [
    "retrahō, retrahere, retrāxī, retrāctus",
    "resupīnō, resupīnāre, resupīnāvī, resupīnātum"
  ],
  "anyone who": [
    "quisquis"
  ],
  "anything that": [
    "quidquid"
  ],
  "how much": [
    "quantum -ī n",
    "Quantum",
    "quantus, quanta, quantum"
  ],
  "reward": [
    "praemium -ī n",
    "praemium, praemiī",
    "praemium, praemiī (n.)",
    "mactō, mactāre, mactāvī, mactātum",
    "remūnerō, remūnerāre, remūnerāvī, remūnerātum"
  ],
  "prize": [
    "praemium -ī n",
    "praemium, praemiī",
    "praemium, praemiī (n.)",
    "magnificō, magnificāre, magnificāvī, magnificātum"
  ],
  "drinking": [
    "pōtiō -ōnis f"
  ],
  "penalty": [
    "poena -ae f",
    "poena, poenae",
    "poena, poenae (f)"
  ],
  "parricide": [
    "parricīda -ae m",
    "parricīdium, parricīdiī or parricīdī"
  ],
  "wish": [
    "optō, optāre, optāvī, optātus",
    "volō, velle, voluī",
    "dēsīderium, dēsīderiī",
    "cupiō, cupere, cupīvī, cupītum"
  ],
  "hate": [
    "ōdī, ōdisse",
    "ōdī, ōdisse, ōsum"
  ],
  "idle talk": [
    "nūgae -ārum"
  ],
  "rubbish": [
    "nūgae -ārum"
  ],
  "ninetieth": [
    "nōnāgēsimus -a -um"
  ],
  "too big": [
    "nimius -a -um"
  ],
  "custom": [
    "mōs mōris m",
    "mōs, mōris",
    "cōnsuētūdō, cōnsuētūdinis"
  ],
  "usage": [
    "mōs mōris m"
  ],
  "memory": [
    "memoria -ae f",
    "memoria, memoriae",
    "memoria, memoriae (f)",
    "sapientia, sapientiae"
  ],
  "law": [
    "lēx lēgis f",
    "iūs, iūris",
    "lēx, lēgis",
    "sānctiō, sānctiōnis"
  ],
  "be hidden": [
    "lateō, latēre, latuī, -----"
  ],
  "justice": [
    "iūs iūris n",
    "iūs, iūris",
    "iūstitia, iūstitiae",
    "iūridiciālis, iūridiciāle"
  ],
  "infirm": [
    "invalidus -a -um"
  ],
  "weak": [
    "dēbilis -e",
    "invalidus -a -um",
    "langueō, languēre",
    "impotēns, impotentis",
    "imbēcillis, imbēcille",
    "īnfirmus, īnfirma, īnfirmum",
    "īnfīrmus, īnfīrma, īnfīrmum",
    "languēscō, languēscere, languī",
    "languidus, languida, languidum",
    "imbēcillus, imbēcilla, imbēcillum"
  ],
  "inscription": [
    "īnscrīptiō -ōnis f"
  ],
  "unjust": [
    "iniūstus -a -um",
    "inīquus, inīqua, inīquum"
  ],
  "unfair": [
    "iniūstus -a -um",
    "inīquus, inīqua, inīquum"
  ],
  "injury": [
    "iniūria -ae f",
    "noxa, noxae",
    "damnum, damnī"
  ],
  "injustice": [
    "iniūria -ae f",
    "iniūria, iniūriae",
    "iniūria, iniūriae (f.)",
    "iniūstitia, iniūstitiae"
  ],
  "insult": [
    "iniūria -ae f",
    "maledictum, maledictī",
    "contumēlia, contumēliae"
  ],
  "faithless": [
    "īnfīdus -a -um",
    "perfidus, perfida, perfidum"
  ],
  "unlucky": [
    "īnfēlīx -īcis",
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "unfortunate": [
    "īnfēlīx -īcis",
    "miser, misera, miserum",
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "impatient": [
    "impatiēns -entis"
  ],
  "forgivefrom lewis and short:in-gnosco": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "nosco": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "lit.": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "not to wish to know": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "not to search into": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "with esp. reference to a fault or crime": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "to pardon": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "forgive": [
    "ignōscō, ignōscere, ignōvī, ignōtus",
    "ignōscō, ignōscere ignōvī, ignōtum + dat"
  ],
  "overlook": [
    "ignōscō, ignōscere, ignōvī, ignōtus",
    "praetermittō, praetermittere, praetermīsī, praetermissum"
  ],
  "for that reason": [
    "ideō"
  ],
  "to the bottom": [
    "funditus"
  ],
  "utterly": [
    "funditus",
    "undique",
    "simpliciter, simplicius, simplicissimē"
  ],
  "runaway": [
    "fugitīvus -a -um"
  ],
  "faithful": [
    "fīdus -a -um",
    "fidēlis, fidēle",
    "firmus, firma, firmum"
  ],
  "reliable": [
    "fīdus -a -um",
    "certus, certa, certum",
    "fīrmus, fīrma, fīrmum"
  ],
  "faith": [
    "fidēs -eī f",
    "fidēs, fideī",
    "fidēs, fideī (f)"
  ],
  "confidence": [
    "fidēs -eī f",
    "Confidentia",
    "fīdūcia, fīdūciae",
    "cōnfīdentia, cōnfīdentiae"
  ],
  "belief": [
    "fidēs -eī f"
  ],
  "loyalty": [
    "fidēs -eī f",
    "Fidelitas"
  ],
  "rely on": [
    "fīdō, fīdere, fīsus sum"
  ],
  "chat": [
    "fābulor, fābulārī, fābulātus sum"
  ],
  "tell a story": [
    "fābulor, fābulārī, fābulātus sum"
  ],
  "make up a fable": [
    "fābulor, fābulārī, fābulātus sum"
  ],
  "bring up": [
    "ēducō, ēducāre, ēducāvī, ēducātus",
    "ēducō, ēducāre, ēducāvī, ēducātum"
  ],
  "rear": [
    "ēducō, ēducāre, ēducāvī, ēducātus"
  ],
  "train": [
    "ēducō, ēducāre, ēducāvī, ēducātus",
    "exerceō, exercēre, exercuī, exercitum",
    "asportō, asportāre, asportāvī, asportātum"
  ],
  "educate": [
    "ēducō, ēducāre, ēducāvī, ēducātus",
    "ēducō, ēducāre, ēducāvī, ēducātum",
    "ērudiō, ērudīre, ērudīvī, ērudītum"
  ],
  "drunk": [
    "ēbrius -a -um"
  ],
  "cross": [
    "crux -ucis f",
    "trānseō, trānsīre, trānsiī, trānsitum",
    "trānsgredior, trānsgredī, trānsgressus sum"
  ],
  "torture": [
    "cruciō, cruciāre, cruciāvī, cruciātus",
    "excruciō, excruciāre, excruciāvī, excruciātus",
    "cruciātus, cruciātūs"
  ],
  "torment": [
    "cruciō, cruciāre, cruciāvī, cruciātus",
    "excruciō, excruciāre, excruciāvī, excruciātus"
  ],
  "in the presence of": [
    "cōram",
    "apud",
    "cōram (+abl)",
    "apud (prep. + acc)"
  ],
  "mild": [
    "clēmēns -entis adi",
    "mītis, mīte",
    "modestus, modesta, modestum",
    "placidus, placida, placidum",
    "temperātus, temperāta, temperātum"
  ],
  "lenient": [
    "clēmēns -entis adi"
  ],
  "greedy": [
    "avārus -a -um",
    "edāx, edācis",
    "avārus, avāra, avārum",
    "avidus, avida, avidum",
    "avārus (m), avāra (f), avārum"
  ],
  "avaricious": [
    "avārus -a -um",
    "avārus, avāra, avārum",
    "avidus, avida, avidum",
    "avārus (m), avāra (f), avārum"
  ],
  "ass’s": [
    "asinīnus -a -um"
  ],
  "a good deal": [
    "aliquantum"
  ],
  "will": [
    "voluntās -ātis f",
    "volō, velle, voluī",
    "testāmentum, testāmentī"
  ],
  "force": [
    "vīs, acc vim, abl vī",
    "cōgō, cōgere, coēgī, coāctum",
    "Vis",
    "vīs, vīs",
    "potentia, potentiae",
    "urgeō, urgēre, ursī",
    "fortitūdō, fortitūdinis",
    "coactō, coactāre, coactāvī, coactātum",
    "cōgō, cōgere, coēgī, coāctum (cum + agō)"
  ],
  "violence": [
    "vīs, acc vim, abl vī",
    "vīs, vīs",
    "violentia, violentiae"
  ],
  "strength": [
    "vīrēs -ium f pl",
    "Fortitudo",
    "vīrēs, vīrium",
    "rōbur, rōboris",
    "vīrēs, vīrium (f, pl)",
    "firmitūdō, firmitūdinis",
    "fortitūdō, fortitūdinis"
  ],
  "cheap": [
    "vīlis -e"
  ],
  "victory": [
    "victōria -ae f",
    "ad victoriam",
    "victōria, victōriae",
    "victōria, victōriae (f)"
  ],
  "conqueror": [
    "victor -ōris",
    "victor -ōris (m)"
  ],
  "victorious": [
    "victor -ōris",
    "victor -ōris (m)"
  ],
  "i wish that": [
    "utinam"
  ],
  "if only…": [
    "utinam"
  ],
  "everywhere": [
    "ubīque",
    "passim"
  ],
  "protect": [
    "tueor, tuērī, tūtus sum",
    "integō, integere, intēxī, intēctum",
    "dēfendō, dēfendere, dēfendī, dēfēnsum",
    "prōtegō, prōtegere, prōtēxī, prōtēctum"
  ],
  "talent": [
    "talentum -ī n"
  ],
  "haughty": [
    "superbus -a -um",
    "superbus, superba, superbum"
  ],
  "proud": [
    "superbus -a -um",
    "superbus, superba, superbum"
  ],
  "slavery": [
    "servitūs -ūtis f",
    "servitium, servitiī",
    "servitūs, servitūtis",
    "famulitium, famulitiī",
    "servitūs, servitūtis (f)"
  ],
  "fight back": [
    "repugnō, repugnāre, repugnāvī, repugnātus"
  ],
  "resist": [
    "repugnō, repugnāre, repugnāvī, repugnātus",
    "restō, restāre, restitī, —"
  ],
  "recollect": [
    "meminī, meminisse",
    "reminīscor, reminīscī, ------",
    "recordor, recordārī, recordātus sum",
    "repetō, repetere, repetīvī, repetītum",
    "recognōscō, recognōscere, recognōvī, recognitum"
  ],
  "row": [
    "rēmigō, rēmigāre, rēmigāvī, rēmigātus",
    "ōrdō -inis m",
    "seriēs, seriēī"
  ],
  "buy back": [
    "redimō, redimere, redēmī, redēmptum"
  ],
  "ransom": [
    "redimō, redimere, redēmī, redēmptum"
  ],
  "nearest": [
    "proximus -a -um"
  ],
  "put before": [
    "praepōnō, praepōnere, praeposuī, praepositus",
    "antepōnō, antepōnere anteposuī, antepositum"
  ],
  "put in charge of": [
    "praepōnō, praepōnere, praeposuī, praepositus"
  ],
  "carry in front": [
    "praeferō, praeferre, praetulī, praelātum"
  ],
  "people": [
    "populus -ī m",
    "gēns gentis f",
    "gēns, gentis",
    "populus, populī (m)"
  ],
  "nation": [
    "populus -ī m",
    "gēns gentis f",
    "rēspūblica",
    "gēns, gentis",
    "populus, populī (m)"
  ],
  "poet": [
    "poēta -ae m/f",
    "vātēs, vātis",
    "poēta, poētae",
    "poēta, poētae (m)"
  ],
  "run over": [
    "percurrō, percurrere, percurrī, percursum",
    "superfluō, superfluere, superfluxī"
  ],
  "pass over": [
    "percurrō, percurrere, percurrī, percursum",
    "trānseō, trānsīre, trānsiī, trānsitum"
  ],
  "travel through": [
    "percurrō, percurrere, percurrī, percursum"
  ],
  "move quickly": [
    "percurrō, percurrere, percurrī, percursum",
    "currō, currere, cucurrī, cursum"
  ],
  "offer": [
    "offerō, offerre, obtulī, oblātum",
    "dō, dare, dedī, datum",
    "dēvoveō, dēvovēre, dēvōvī, dēvōtum",
    "sufferō, sufferre, sustulī, sublātum",
    "praebeō, praebēre, praebuī, praebitum",
    "praebeō, praebēre, praebuī, praebitus",
    "praestō, praestāre, praestitī, praestitum"
  ],
  "cloudy": [
    "nūbilus -a -um",
    "cālīginōsus, cālīginōsa, cālīginōsum"
  ],
  "lend": [
    "mūtuum dare"
  ],
  "give a loan": [
    "mūtuum dare"
  ],
  "on loan": [
    "mūtuus -a -um"
  ],
  "merchant-": [
    "mercātōrius -a -um"
  ],
  "keep in mind": [
    "meminī, meminisse",
    "memento"
  ],
  "justly": [
    "iūre"
  ],
  "rightly": [
    "iūre",
    "rēctē"
  ],
  "inner": [
    "internus -a -um",
    "interior, interius",
    "penitus, penita, penitum"
  ],
  "internal": [
    "internus -a -um",
    "intestīnus, intestīna, intestīnum"
  ],
  "scarcity": [
    "inopia -ae f",
    "pēnūria, pēnūriae",
    "paucitās, paucitātis"
  ],
  "unsafe": [
    "īnfēstus -a -um",
    "īnfestus, īnfesta, īnfestum"
  ],
  "infested": [
    "īnfēstus -a -um"
  ],
  "hostile": [
    "īnfēstus -a -um",
    "hostīlis, hostīle",
    "īnfēnsus, īnfēnsa, īnfēnsum",
    "invidiōsus, invidiōsa, invidiōsum"
  ],
  "unarmed": [
    "inermis -e"
  ],
  "inhabitant": [
    "incola -ae m/f"
  ],
  "pleasing": [
    "grātus -a -um",
    "grātus, grāta, grātum",
    "iūcundus, iūcunda, iūcundum",
    "periūcundus, periūcunda, periūcundum"
  ],
  "grateful": [
    "grātus -a -um",
    "grātiam habēre",
    "grātus, grāta, grātum"
  ],
  "thank": [
    "grātiās agere"
  ],
  "be grateful": [
    "grātiam habēre"
  ],
  "gratitude": [
    "grātia -ae f"
  ],
  "tribe": [
    "gēns gentis f"
  ],
  "clan": [
    "gēns gentis f",
    "gēns, gentis"
  ],
  "bend": [
    "flectō, flectere, flexī, flexum",
    "īnflectō, īnflectere, īnflēxī, īnflexus",
    "vergō, vergere",
    "curvō, curvāre, curvāvī, curvātum",
    "sinuō, sinuāre, sinuāvī, sinuātum",
    "inclīnō, inclīnāre, inclīnāvī, inclīnātum",
    "resupīnō, resupīnāre, resupīnāvī, resupīnātum"
  ],
  "even now": [
    "etiamnunc",
    "etiamnum"
  ],
  "outstanding": [
    "ēgregius -a -um"
  ],
  "draw out": [
    "ēdūcō, ēdūcere, ēdūxī, ēductus",
    "prōrogō, prōrogāre, prōrogāvī, prōrogātum",
    "dēstringō, dēstringere, dēstrinxī, dēstrictum"
  ],
  "advise not to": [
    "dissuādeō, dissuādēre, dissuāsī, dissuāsus"
  ],
  "race": [
    "cursus -ūs m",
    "Gens",
    "gēns, gentis",
    "cursus, cursūs",
    "cursus, cursūs (m.)",
    "prōgeniēs, prōgeniēī",
    "curriculum, curriculī"
  ],
  "course": [
    "cursus -ūs m",
    "epula, epulae",
    "mēnsa, mēnsae",
    "cursus, cursūs",
    "tenor, tenōris",
    "mēnsa, mēnsae (f)",
    "cursus, cursūs (m.)",
    "prōcessus, prōcessūs"
  ],
  "whole": [
    "cūnctus -a -um",
    "tōtus, tōta, tōtum"
  ],
  "pl all": [
    "cūnctus -a -um"
  ],
  "scorn": [
    "contemnō, contemnere, contempsī, contemptum",
    "dērīsus, dērīsūs",
    "contemptiō, contemptiōnis"
  ],
  "condition": [
    "condiciō -ōnis f",
    "status, statūs",
    "habitūdō, habitūdinis"
  ],
  "common": [
    "commūnis -e",
    "Communis",
    "commūnis, commūne",
    "vulgāris, vulgāre",
    "cōnsors, cōnsortis"
  ],
  "fleet": [
    "classis -is f"
  ],
  "dear": [
    "cārus -a -um",
    "cārus, cāra, cārum"
  ],
  "boldness": [
    "audācia -ae f"
  ],
  "audacity": [
    "audācia -ae f"
  ],
  "amphitheater": [
    "amphitheātrum -ī n"
  ],
  "friendship": [
    "amīcitia -ae f",
    "amīcitia, amīcitiae",
    "amīcitia, amīcitiae (f)",
    "necessitūdō, necessitūdinis"
  ],
  "unfavorable": [
    "adversus -a -um",
    "laevus",
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "towards": [
    "adversus -a -um",
    "ōbiciō, ōbicere, ōbiēcī, ōbiectum",
    "obvertō, obvertere, obvertī, obversum"
  ],
  "borrow": [
    "mūtuum sūmere",
    "mūtuor, mūtuārī, mūtuātus sum"
  ],
  "take a loan": [
    "mūtuum sūmere"
  ],
  "for my sake": [
    "meā grātiā"
  ],
  "leisured": [
    "ōtiōsus -a -um"
  ],
  "idle": [
    "ōtiōsus -a -um",
    "Idle"
  ],
  "20 years old": [
    "xx annōs nātus"
  ],
  "wound": [
    "vulnus -eris n",
    "vulnerō, vulnerāre, vulnerāvī, vulnerātus",
    "laedō, laedere, laesī, laesus",
    "ulcus, ulceris",
    "vulnus, vulneris",
    "convulnerō, convulnerāre, convulnerāvī, convulnerātum"
  ],
  "valor": [
    "virtūs -ūtis f",
    "Virtus"
  ],
  "courage": [
    "virtūs -ūtis f",
    "Virtus",
    "animī, animōrum",
    "virtūs, virtūtis",
    "animī, animōrum (m)"
  ],
  "beyond": [
    "ultrā",
    "praeter",
    "praeterquam",
    "praeter (+ acc.)",
    "ultrā ( prp +acc )",
    "ultrā (adv. and prep. + acc)"
  ],
  "farther": [
    "ulterior -ius"
  ],
  "more distant": [
    "ulterior -ius"
  ],
  "transfer": [
    "trānsferō, trānsferre, trānstulī, trānslātum",
    "datiō, datiōnis"
  ],
  "transport": [
    "trānsferō, trānsferre, trānstulī, trānslātum",
    "dēvehō, dēvehere, dēvexi, dēvectum",
    "asportō, asportāre, asportāvī, asportātum",
    "trānsportō, trānsportāre, trānsportāvī, trānsportātum"
  ],
  "so long": [
    "tamdiū"
  ],
  "as long": [
    "tamdiū"
  ],
  "interest": [
    "studium -ī n"
  ],
  "study": [
    "studium -ī n",
    "studium, studiī",
    "studium, studiī (n)",
    "studeō, studēre, studuī + dat"
  ],
  "devote oneself to": [
    "studeō, studēre, studuī, ------"
  ],
  "soldier's pay": [
    "stipendium -ī n"
  ],
  "service": [
    "stipendium -ī n",
    "officium, officiī",
    "officium, officiī (n)"
  ],
  "along": [
    "secundum :",
    "secus",
    "secundum (prp +acc) :"
  ],
  "bank": [
    "rīpa -ae f"
  ],
  "ridiculous": [
    "rīdiculus -a -um",
    "Ridiculus",
    "rīdiculus, rīdicula, rīdiculum"
  ],
  "raft": [
    "ratis -is f"
  ],
  "how long": [
    "quamdiū",
    "quātenus",
    "usquequo"
  ],
  "public": [
    "pūblicus -a -um"
  ],
  "state-": [
    "pūblicus -a -um"
  ],
  "hurry": [
    "properō, properāre, properāvī, properātus",
    "festīnātiō, festīnātiōnis",
    "contendō, contendere, contendī, contentum",
    "festīnō, festīnāre, festīnāvī, festīnātum"
  ],
  "battle": [
    "proelium -ī n",
    "Proelium",
    "dīmicātiō, dīmicātiōnis"
  ],
  "run forward": [
    "prōcurrō, prōcurrere, prōcurrī, prōcursum"
  ],
  "private": [
    "prīvātus -a -um",
    "pecūliāris, pecūliāre",
    "arcānus, arcāna, arcānum"
  ],
  "the day before": [
    "prīdiē"
  ],
  "to stand out": [
    "praestō, praestāre, praestitī, praestatus"
  ],
  "stand before": [
    "praestō, praestāre, praestitī, praestatus",
    "obstō, obstāre, obstitī, obstātum"
  ],
  "be superior": [
    "praestō, praestāre, praestitī, praestatus",
    "praevaleō, praevalēre, praevaluī, praevalitum"
  ],
  "excel": [
    "praestō, praestāre, praestitī, praestatus",
    "praestō",
    "praestō, praestāre, praestitī, praestitum",
    "exsuperō, exsuperāre, exsuperāvī, exsuperātum"
  ],
  "surpass": [
    "praestō, praestāre, praestitī, praestatus",
    "superō, superāre, superāvī, superātum",
    "superō, superāre, superāvī, superātus",
    "praecellō, praecellere, praeculī, praecelsum",
    "praecurrō, praecurrere, praecucurrī, praecursum"
  ],
  "exceed": [
    "praestō, praestāre, praestitī, praestatus"
  ],
  "be excellent": [
    "praestō, praestāre, praestitī, praestatus"
  ],
  "play part": [
    "praestō, praestāre, praestitī, praestatus"
  ],
  "make good": [
    "praestō, praestāre, praestitī, praestatus",
    "suppleō, supplēre, supplēvī, supplētum"
  ],
  "keep word": [
    "praestō, praestāre, praestitī, praestatus"
  ],
  "above all": [
    "praecipuē"
  ],
  "following": [
    "posterus -a -um",
    "sequāx, sequācis",
    "cōnsequus, cōnsequa, cōnsequum"
  ],
  "most people": [
    "plērīque, plēraeque, plēraque"
  ],
  "peace": [
    "pāx pācis f",
    "Pax",
    "pāx, pācis",
    "ōtium, ōtiī",
    "pāx, pācis (f)"
  ],
  "rank": [
    "ōrdō -inis m",
    "luxuriōsus, luxuriōsa, luxuriōsum",
    "luxuriō, luxuriāre, luxuriāvī, luxuriātum"
  ],
  "fortify": [
    "mūniō, mūnīre, mūnīvī, mūnītus",
    "Fortifica",
    "firmō, firmāre, firmāvī, firmātum",
    "corrōborō, corrōborāre, corrōborāvī, corrōborātum"
  ],
  "military": [
    "mīlitāris -e",
    "bellicus, bellica, bellicum"
  ],
  "legionary": [
    "legiōnārius -a -um"
  ],
  "of a legion": [
    "legiōnārius -a -um"
  ],
  "legion": [
    "legiō -ōnis f"
  ],
  "envoy": [
    "lēgātus -ī m"
  ],
  "delegate": [
    "lēgātus -ī m",
    "dēlēgō, dēlēgāre, dēlēgāvī, dēlēgātum"
  ],
  "insert": [
    "īnstruō, īnstruere, īnstrūxī, īnstrūctus"
  ],
  "build in": [
    "īnstruō, īnstruere, īnstrūxī, īnstrūctus"
  ],
  "draw up": [
    "īnstruō, īnstruere, īnstrūxī, īnstrūctus"
  ],
  "arrange": [
    "īnstruō, īnstruere, īnstrūxī, īnstrūctus",
    "ōrdinō, ōrdināre, ōrdināvī, ōrdinātus",
    "struō, struere, struxī, structum",
    "compōnō, compōnere, composuī, compositum"
  ],
  "suitable": [
    "idōneus, idōnea, idōneum",
    "habilis, habile",
    "aptus, apta, aptum",
    "decōrus, decōra, decōrum",
    "commodus, commoda, commodum",
    "opportūnus, opportūna, opportūnum",
    "accommodātus, accommodāta, accommodātum"
  ],
  "encourage": [
    "hortor, hortārī, hortātus sum",
    "adhortor, adhortārī, adhortātus sum",
    "exhortor, exhortārī, exhortātus sum",
    "allectō, allectāre, allectāvī, allectātum",
    "cohortō, cohortāre, cohortāvī, cohortātum",
    "cōnfirmō, cōnfirmāre, cōnfirmāvī, cōnfirmātum"
  ],
  "urge": [
    "hortor, hortārī, hortātus sum",
    "urgeō, urgēre, ursī"
  ],
  "dreadful": [
    "dīrus -a -um",
    "horrendus -a -um",
    "horribilis, horribile, horribilior"
  ],
  "tire out": [
    "fatīgō, fatīgāre, fatīgāvī, fatīgātus",
    "fatīgō, fatīgāre, fatīgāvī, fatīgātum"
  ],
  "run out": [
    "excurrō, excurrere, &nbsp,excurrī, excursum",
    "excurrō, excurrere, excurrī, excursum"
  ],
  "rush out": [
    "excurrō, excurrere, &nbsp,excurrī, excursum",
    "excurrō, excurrere, excurrī, excursum"
  ],
  "again and again": [
    "etiam atque etiam"
  ],
  "and indeed": [
    "etenim"
  ],
  "break out": [
    "ērumpō, ērumpere, ērūpī, ēruptus"
  ],
  "burst out": [
    "ērumpō, ērumpere, ērūpī, ēruptus"
  ],
  "pour out": [
    "effundō, effundere, effūdī, effūsus",
    "circumfundō, circumfundere, circumfudī, circumfusum"
  ],
  "long for": [
    "dēsīderō, dēsīderāre, dēsīderāvī, dēsīderātus",
    "cupiō, cupere, cupīvī, cupītum",
    "exoptō, exoptāre, exoptāvī, exoptātum",
    "concupiō, concupere, concupivī, concupitum",
    "dēsīderō, dēsīderāre, dēsīderāvī, dēsīderātum"
  ],
  "miss": [
    "dēsīderō, dēsīderāre, dēsīderāvī, dēsīderātus",
    "requīrō, requīrere, requīsīvī, requīsītum",
    "dēsīderō, dēsīderāre, dēsīderāvī, dēsīderātum"
  ],
  "connect": [
    "cōpulō, cōpulāre, cōpulāvī, cōpulātus",
    "adnectō, adnectere, adnexuī, adnexum",
    "coniungō, coniungere, coniunxī, coniunctum",
    "cōnectō, cōnectere, cōnexuī, cōnexum",
    "coniectō, coniectāre, coniectāvī, coniectātum",
    "continuō, continuāre, continuāvī, continuātum"
  ],
  "call together": [
    "convocō, convocāre, convocāvī, convocātus"
  ],
  "mention": [
    "commemorō, commemorāre, commemorāvī, commemorātus",
    "mentiō -ōnis f"
  ],
  "bring to mind": [
    "commemorō, commemorāre, commemorāvī, commemorātus"
  ],
  "speak of": [
    "commemorō, commemorāre, commemorāvī, commemorātus"
  ],
  "place on record": [
    "commemorō, commemorāre, commemorāvī, commemorātus"
  ],
  "a court": [
    "cohors -rtis f"
  ],
  "enclosure": [
    "cohors -rtis f",
    "saeptum, saeptī",
    "claustrum, claustrī"
  ],
  "pen or cattle-yard": [
    "cohors -rtis f"
  ],
  "a cohort": [
    "cohors -rtis f"
  ],
  "the tenth part of a legion": [
    "cohors -rtis f"
  ],
  "six centuries or around 360 men": [
    "cohors -rtis f"
  ],
  "compel": [
    "cōgō, cōgere, coēgī, coāctum",
    "coactō, coactāre, coactāvī, coactātum",
    "cōgō, cōgere, coēgī, coāctum (cum + agō)",
    "compellō, compellere, compulī, compulsum"
  ],
  "on this side of": [
    "citrā",
    "cis"
  ],
  "nearer": [
    "citerior -ius",
    "interior, interius"
  ],
  "build around": [
    "circumdō, circumdare, circumdedī, circumdatum"
  ],
  "a cutting-down": [
    "caedēs -is f"
  ],
  "slaughter": [
    "caedēs -is f",
    "occīsiō, occīsiōnis",
    "interneciō, interneciōnis",
    "trucīdō, trucīdāre, trucīdāvī, trucīdātum",
    "obtruncō, obtruncāre, obtruncāvī, obtruncātum"
  ],
  "to cut": [
    "caedō, caedere, cecidī, caesum"
  ],
  "hew": [
    "caedō, caedere, cecidī, caesum"
  ],
  "fell": [
    "caedō, caedere, cecidī, caesum",
    "occīdō, occīdere, occīdī, occīsum"
  ],
  "auxiliary forces": [
    "auxilia -ōrum n pl"
  ],
  "steep": [
    "arduus -a -um",
    "abscīsus, abscīsa, abscīsum"
  ],
  "arduous": [
    "arduus -a -um"
  ],
  "army on the march": [
    "agmen -inis n"
  ],
  "file": [
    "agmen -inis n"
  ],
  "age": [
    "aetās -ātis f",
    "Age",
    "aevum, aevī",
    "aetās, aetātis",
    "senēscō, senēscere, senuī",
    "veterō, veterāre, veterāvī, veterātum",
    "inveterō, inveterāre, inveterāvī, inveterātum"
  ],
  "period of life": [
    "aetās -ātis f",
    "aetās, aetātis"
  ],
  "join to": [
    "adiungō, adiungere, adiūnxī, adiūnctum"
  ],
  "a sharp edge": [
    "aciēs -ēī f"
  ],
  "cutting part": [
    "aciēs -ēī f"
  ],
  "line of battle": [
    "aciēs -ēī f"
  ],
  "for a longer time": [
    "diūtius"
  ],
  "short verse": [
    "versiculus -ī m"
  ],
  "charming": [
    "venustus -a -um",
    "bellus, bella, bellum",
    "lepidus, lepida, lepidum",
    "dēlicātus, dēlicāta, dēlicātum"
  ],
  "most distant": [
    "ultimus -a -um"
  ],
  "swollen": [
    "turgidus -a -um",
    "turgidulus -a -um",
    "tumeō, tumēre",
    "tumidus, tumida, tumidum"
  ],
  "theater": [
    "theātrum -ī n"
  ],
  "witness": [
    "testis -is m/f",
    "arbiter, arbitrī"
  ],
  "darkness": [
    "tenebrae -ārum f pl",
    "obscūritās, obscūritātis"
  ],
  "spectator": [
    "spectātor -ōris m",
    "arbiter, arbitrī"
  ],
  "fold": [
    "sinus -ūs m",
    "stabulum -ī n",
    "saeptum, saeptī"
  ],
  "serious": [
    "sērius -a -um",
    "gravis, grave"
  ],
  "scalpel": [
    "scalpellum -ī n"
  ],
  "surgical knife": [
    "scalpellum -ī n"
  ],
  "theatrical": [
    "scaenicus -a -um"
  ],
  "to taste of": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "to have a sense of taste": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "to be wise": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "to have sense": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "laughter": [
    "rīsus -ūs m"
  ],
  "net": [
    "rēte -is n",
    "rēticulum, rēticulī"
  ],
  "spring forth": [
    "prōsiliō, prōsilīre, prōsiluī, ------",
    "nāscor, nāscī, nātus sum"
  ],
  "poetical": [
    "poēticus -a -um"
  ],
  "mostly": [
    "plērumque"
  ],
  "frequently": [
    "plērumque"
  ],
  "commonly": [
    "plērumque"
  ],
  "clap": [
    "plaudō, plaudere, plausī, plausus"
  ],
  "applaud": [
    "plaudō, plaudere, plausī, plausus"
  ],
  "continuous": [
    "perpetuus -a -um",
    "continuus, continua, continuum",
    "perpetuus, perpetua, perpetuum"
  ],
  "permanent": [
    "perpetuus -a -um"
  ],
  "sparrow": [
    "passer -eris m"
  ],
  "palm": [
    "palma -ae f"
  ],
  "palm tree": [
    "palma -ae f"
  ],
  "palm of victory": [
    "palma -ae f"
  ],
  "gape": [
    "ōscitō, ōscitāre, ōscitāvī, ōscitātus"
  ],
  "yawn": [
    "ōscitō, ōscitāre, ōscitāvī, ōscitātus"
  ],
  "resources": [
    "opēs -um f pl",
    "opēs, opum",
    "opēs, opum (f. pl.)"
  ],
  "wealth": [
    "opēs -um f pl",
    "opēs, opum",
    "opēs, opum (f. pl.)",
    "dīvitiae, dīvitiārum",
    "opulentia, opulentiae",
    "dīvitiae, dīvitiārum (f)"
  ],
  "effort": [
    "opera -ae",
    "cōnātus, cōnātūs",
    "ēmolumentum, ēmolumentī"
  ],
  "pains": [
    "opera -ae"
  ],
  "hatred": [
    "odium -ī n",
    "odium, odiī",
    "odium, odiī (n.)",
    "invidia, invidiae",
    "invidia, invidiae (f.)",
    "malevolentia, malevolentiae"
  ],
  "marry": [
    "uxōrem dūcere",
    "nūbō, nūbere, nūpsī, nūptum"
  ],
  "snow-white": [
    "niveus -a -um"
  ],
  "now…now": [
    "modo…modo"
  ],
  "wretched": [
    "misellus -a -um",
    "miser, misera, miserum",
    "miserābilis, miserābile"
  ],
  "honey-sweet": [
    "mellītus -a -um"
  ],
  "mourn": [
    "lūgeō, lūgēre, lūxī, lūctus"
  ],
  "lamp": [
    "lucerna -ae f",
    "lampas, lampadis"
  ],
  "it pleases": [
    "libet"
  ],
  "injure": [
    "laedō, laedere, laesī, laesus",
    "peccō, peccāre, peccāvī, peccātum",
    "noceō, nocēre, nocuī, nocitum + dat"
  ],
  "humorous": [
    "iocōsus -a -um"
  ],
  "funny": [
    "iocōsus -a -um"
  ],
  "now and then": [
    "interdum"
  ],
  "i say": [
    "inquam"
  ],
  "enfold": [
    "implicō, implicāre, implicāvī, implicātus"
  ],
  "lap": [
    "gremium -ī n",
    "lambō, lambere, lambī, lambitum"
  ],
  "gladiatorial": [
    "gladiātōrius -a -um"
  ],
  "gladiator": [
    "gladiātor -ōris m"
  ],
  "twin": [
    "geminus -a -um",
    "gemellus, gemellī",
    "geminus, gemina, geminum"
  ],
  "fate": [
    "fātum -ī n",
    "fātum, fātī",
    "sors, sortis",
    "fātum, fātī (n)"
  ],
  "destiny": [
    "fātum -ī n",
    "sors, sortis"
  ],
  "epigram": [
    "epigramma -atis n"
  ],
  "strike out": [
    "ēlīdō, ēlīdere, ēlīsī, ēlīsum"
  ],
  "omit": [
    "ēlīdō, ēlīdere, ēlīsī, ēlīsum",
    "omittō, omittere, omīsī, omissum",
    "intermittō, intermittere, intermisī, intermissum"
  ],
  "elide": [
    "ēlīdō, ēlīdere, ēlīsī, ēlīsum"
  ],
  "provided that": [
    "dummodo",
    "dummodo,",
    "dummodo, (conj. + subjunct.)"
  ],
  "if only": [
    "dummodo",
    "sīquidem"
  ],
  "undecided": [
    "dubius -a -um"
  ],
  "doubtful": [
    "dubius -a -um",
    "incertus, incerta, incertum"
  ],
  "swallow up": [
    "dēvorō, dēvorāre, dēvorāvī, dēvorātus"
  ],
  "pet": [
    "dēliciae -ārum f pl"
  ],
  "a poetic foot": [
    "dactylus, -ī",
    "dactylus, -ī (m)"
  ],
  "mix up": [
    "conturbō, conturbāre, conturbāvī, conturbātus",
    "misceō, miscēre, miscuī, mixtum"
  ],
  "confound": [
    "conturbō, conturbāre, conturbāvī, conturbātus"
  ],
  "comedy": [
    "cōmoedia -ae f",
    "cōmicus, cōmica, cōmicum"
  ],
  "circus": [
    "circus -ī m"
  ],
  "hop about": [
    "circumsiliō, circumsilīre, ----,-----"
  ],
  "of the circus": [
    "circēnsis -e"
  ],
  "games in the circus": [
    "circēnsēs -ium m pl"
  ],
  "contend": [
    "certō, certāre, certāvī, certātus",
    "contendō, contendere, contendī, contentum",
    "dēcertō, dēcertāre, dēcertāvī, dēcertātum",
    "cōnfligō, cōnfligere, cōnflixī, cōnflictum"
  ],
  "contest": [
    "certāmen -inis",
    "rixa, rixae"
  ],
  "guffaw": [
    "cachinnus -ī m"
  ],
  "pretty": [
    "bellus -a -um",
    "bellus, bella, bellum"
  ],
  "charioteer": [
    "aurīga -ae",
    "aurīga -ae (m!)"
  ],
  "driver": [
    "aurīga -ae",
    "aurīga -ae (m!)"
  ],
  "spider": [
    "arānea -ae f"
  ],
  "cobweb": [
    "arānea -ae f"
  ],
  "old woman": [
    "anus -ūs f"
  ],
  "assert": [
    "affirmō, affirmāre, affirmāvī, affirmātus",
    "āit, āiunt",
    "āit, āiunt (defective verb)",
    "arguō, arguere, arguī, argūtum"
  ],
  "affirm": [
    "affirmō, affirmāre, affirmāvī, affirmātus"
  ],
  "keen": [
    "ācer -cris -cre",
    "ācer, ācris, ācre"
  ],
  "inflame": [
    "accendō, accendere, &nbsp,accendī, accēnsum",
    "accendō, accendere, accendī, accēnsum",
    "īnflammō, īnflammāre, īnflammāvī, īnflammātum"
  ],
  "just as much": [
    "tantundem"
  ],
  "appearance": [
    "speciēs -ēī f",
    "speciēs, speciēī",
    "habitūdō, habitūdinis",
    "phaenomenon, phaenomenī",
    "vīsitātiō, vīsitātiōnis",
    "compārentia, compārentiae"
  ],
  "aspect": [
    "speciēs -ēī f",
    "speciēs, speciēī"
  ],
  "if indeed": [
    "sīquidem"
  ],
  "since indeed": [
    "sīquidem"
  ],
  "seeing that": [
    "sīquidem",
    "quoniam",
    "quandōquidem"
  ],
  "similar": [
    "similis -e",
    "similis, simile",
    "aequābilis, aequābile",
    "similis, simile (+ gen. or dat.)"
  ],
  "meaning": [
    "significātiō -ōnis f"
  ],
  "priest": [
    "sacerdōs sacerdōtis m/f",
    "Priest",
    "flāmen, flāminis",
    "sacerdōs, sacerdōtis",
    "sacerdōs, sacerdōtis (m)"
  ],
  "priestess": [
    "sacerdōs sacerdōtis m/f"
  ],
  "quantity": [
    "quantitās -ātis f",
    "Quantitas"
  ],
  "quality": [
    "quālitās -ātis f",
    "prōprietās, prōprietātis"
  ],
  "for this reason": [
    "proptereā"
  ],
  "individual": [
    "proprius -a -um",
    "prōprius, prōpria, prōprium"
  ],
  "special": [
    "proprius -a -um",
    "eximius, eximia, eximium",
    "praecipuus, praecipua, praecipuum"
  ],
  "characteristic": [
    "proprius -a -um"
  ],
  "regulate": [
    "ōrdinō, ōrdināre, ōrdināvī, ōrdinātus"
  ],
  "speech": [
    "ōrātiō -ōnis f",
    "Loquacitas",
    "ōrātiō, ōrātiōnis"
  ],
  "oration": [
    "ōrātiō -ōnis f"
  ],
  "eloquence": [
    "ōrātiō -ōnis f"
  ],
  "prayer": [
    "ōrātiō -ōnis f",
    "prex, precis"
  ],
  "muse": [
    "mūsa -ae f"
  ],
  "wrestle": [
    "luctor, luctārī, luctātus sum"
  ],
  "anger": [
    "īra -ae f",
    "Anger",
    "īra, īrae",
    "īra, īrae (f)"
  ],
  "unpolished": [
    "inconditus -a -um",
    "impolītus, impolīta, impolītum"
  ],
  "rough": [
    "inconditus -a -um",
    "cautēs, cautis",
    "immītis, immīte",
    "dūrus, dūra, dūrum",
    "asper, aspera, asperum",
    "scaber, scabra, scabrum",
    "hīrsūtus, hīrsūta, hīrsūtum",
    "horridus, horrida, horridum",
    "impolītus, impolīta, impolītum",
    "squālidus, squālida, squālidum",
    "cōnfragōsus, cōnfragōsa, cōnfragōsum"
  ],
  "introduced to fill up a vacuum": [
    "explētīvus, -a, -um"
  ],
  "making up the numbers": [
    "explētīvus, -a, -um"
  ],
  "explanatory": [
    "explētīvus, -a, -um",
    "dēfīnītīvus, dēfīnītīva, dēfīnītīvum"
  ],
  "explain": [
    "explānō, explānāre, explānāvī, explānātus",
    "ēdō, ēdere, ēdidī, ēditum",
    "expōnō, expōnere, exposuī, expositum",
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "see!": [
    "ēn",
    "ēia"
  ],
  "look!": [
    "ēn"
  ],
  "precisely": [
    "dumtaxat",
    "accūrātē, accūrātius, accūrātissimē"
  ],
  "separating": [
    "disiūnctīvus, -a, -um",
    "dīgressiō, dīgressiōnis"
  ],
  "contrasting": [
    "disiūnctīvus, -a, -um"
  ],
  "causal": [
    "causālis, -e"
  ],
  "mood": [
    "affectus -ūs m"
  ],
  "tie on": [
    "adnectō, adnectere, adnexuī, adnexum"
  ],
  "bind to": [
    "adnectō, adnectere, adnexuī, adnexum",
    "alligō, alligāre, alligāvī, alligātum"
  ],
  "attach": [
    "adnectō, adnectere, adnexuī, adnexum",
    "accommodō, accommodāre, accommodāvī, accommodātum"
  ],
  "admiration": [
    "admīrātiō -ōnis f"
  ],
  "curve": [
    "īnflectō, īnflectere, īnflēxī, īnflexus",
    "curvō, curvāre, curvāvī, curvātum",
    "sinuō, sinuāre, sinuāvī, sinuātum",
    "incurvō, incurvāre, incurvāvī, incurvātum"
  ],
  "ha!": [
    "ēia"
  ],
  "rational": [
    "ratiōnālis -e"
  ],
  "theoretical": [
    "ratiōnālis -e"
  ],
  "of accounts": [
    "ratiōnālis -e"
  ],
  "small house": [
    "casa -ae f"
  ],
  "cottage": [
    "casa -ae f",
    "casa, casae",
    "casa, casae (f)"
  ],
  "hut": [
    "casa -ae f",
    "casa, casae",
    "cella, cellae",
    "casa, casae (f)"
  ],
  "cabin": [
    "casa -ae f"
  ],
  "slope": [
    "clīvus -ī m",
    "clīvus, clīvī"
  ],
  "part of the temple of saturn at rome": [
    "aerārium -ī n"
  ],
  "in which the public treasure was kept": [
    "aerārium -ī n"
  ],
  "the treasury": [
    "aerārium -ī n"
  ],
  "how do you say x": [
    "quōmodo dīcitur x"
  ],
  "what does x mean": [
    "quid significat x"
  ],
  "how are you": [
    "ut vales"
  ],
  "i'm well": [
    "bene"
  ],
  "okay": [
    "Sic",
    "sat bene"
  ],
  "not well": [
    "nōn valeō"
  ],
  "thank you": [
    "Gratias tibi ago",
    "grātiās tibi agō",
    "grātiās vōbīs agō"
  ],
  "you're welcome": [
    "lībenter",
    "Nihil est"
  ],
  "what is your name": [
    "quid est nōmen tibi"
  ],
  "my name is x": [
    "nomen mihi est x"
  ],
  "do you understand": [
    "intellegisne",
    "intellegistisne"
  ],
  "i understand": [
    "Intellego",
    "intellegō",
    "(Nōn) Intellegō"
  ],
  "yes": [
    "Sic",
    "ita",
    "Suc",
    "Sici"
  ],
  "are you a teacher": [
    "esne magister",
    "esne magistra"
  ],
  "i am a student": [
    "discipula sum",
    "discipulus sum"
  ],
  "where do you live": [
    "ubi habitās"
  ],
  "i live in x": [
    "in x habito"
  ],
  "what is this": [
    "Quid est hic",
    "quid est hoc"
  ],
  "to where": [
    "quō"
  ],
  "how's the weather": [
    "quāle est caelum"
  ],
  "how are you doing": [
    "quōmodo te habes"
  ],
  "i am an auditor today": [
    "hodie auditor sum"
  ],
  "to love": [
    "amō, amāre, amāvī, amātum"
  ],
  "to think": [
    "cōgitō, cōgitāre, cōgitāvī, cōgitātum"
  ],
  "ponder": [
    "meditor, meditārī, meditātus sum",
    "pēnsō, pēnsāre, pēnsāvī, pēnsātum",
    "cōgitō, cōgitāre, cōgitāvī, cōgitātum"
  ],
  "to owe": [
    "dēbeō, dēbēre, dēbuī, dēbitum"
  ],
  "ought": [
    "dēbeō, dēbēre, dēbuī, dēbitum"
  ],
  "must": [
    "dēbeō, dēbēre, dēbuī, dēbitum"
  ],
  "should": [
    "dēbeō, dēbēre, dēbuī, dēbitum"
  ],
  "to give": [
    "dō, dare, dedī, datum"
  ],
  "to wander": [
    "errō, errāre, errāvī, errātum"
  ],
  "err": [
    "errō, errāre, errāvī, errātum"
  ],
  "go astray": [
    "errō, errāre, errāvī, errātum"
  ],
  "make a mistake": [
    "errō, errāre, errāvī, errātum"
  ],
  "be mistaken": [
    "errō, errāre, errāvī, errātum"
  ],
  "to remind": [
    "moneō, monēre, monuī, monitum"
  ],
  "to be well": [
    "salveō, salvēre"
  ],
  "be in good health": [
    "salveō, salvēre"
  ],
  "greetings": [
    "Salve",
    "salvē, salvēte"
  ],
  "to preserve": [
    "servō, servāre, servāvī, servātum",
    "cōnservō, cōnservāre, cōnservāvī, cōnservātum"
  ],
  "conserve": [
    "cōnservō, cōnservāre, cōnservāvī, cōnservātum"
  ],
  "maintain": [
    "dictitō, dictitāre, dictitāvī, dictitātum",
    "cōnservō, cōnservāre, cōnservāvī, cōnservātum"
  ],
  "to frighten": [
    "terreō, terrēre, terruī, territum"
  ],
  "terrify": [
    "terreō, terrēre, terruī, territum",
    "exterreō, exterrēre, exterruī, exterritum"
  ],
  "to be strong": [
    "valeō, valēre, valuī, valitūrum"
  ],
  "have power": [
    "possum, posse, potuī",
    "valeō, valēre, valuī, valitūrum"
  ],
  "to see": [
    "videō, vidēre, vīdī, vīsum"
  ],
  "observe": [
    "fungor, fungī, fūnctus sum",
    "videō, vidēre, vīdī, vīsum",
    "speculor, speculārī, speculātus sum",
    "cōnspicor, cōnspicārī, cōnspicātus sum",
    "aspectō, aspectāre, aspectāvī, aspectātum",
    "observō, observāre, observāvī, observātum",
    "īnspectō, īnspectāre, īnspectāvī, īnspectātum",
    "contemplō, contemplāre, contemplāvī, contemplātum"
  ],
  "to call": [
    "vocō, vocāre, vocāvī, vocā"
  ],
  "summon": [
    "vocō, vocāre, vocāvī, vocā",
    "acciō, accīre, accīvī, accītum",
    "exciō, excīre, excīvī, excitum",
    "invītō, invītāre, invītāvī, invītātum"
  ],
  "longing": [
    "lubīdō, lubīdinis",
    "aviditās, aviditātis",
    "dēsīderium, dēsīderiī",
    "cupiditās, cupiditātis",
    "cupiditās, cupiditātis (f)",
    "concupiscentia, concupiscentiae"
  ],
  "passion": [
    "cupīdō, cupīdinis",
    "libīdō, libīdinis",
    "cupiditās, cupiditātis",
    "cupiditās, cupiditātis (f)"
  ],
  "cupidity": [
    "cupiditās, cupiditātis",
    "cupiditās, cupiditātis (f)"
  ],
  "avarice": [
    "avāritia, avāritiae",
    "aviditās, aviditātis",
    "cupiditās, cupiditātis",
    "cupiditās, cupiditātis (f)"
  ],
  "old age": [
    "senectūs, senectūtis",
    "vetustās, vetustātis"
  ],
  "truth": [
    "vēritās, vēritātis",
    "vēritās, vēritātis (f)"
  ],
  "pleasure": [
    "voluptās, voluptātis",
    "voluptās, voluptātis (f)",
    "dēlectātiō, dēlectātiōnis",
    "dēlectātiō, dēlectātiōnis (f)"
  ],
  "blessed": [
    "beātus, beāta, beātum",
    "fortūnātus, fortūnāta, fortūnātum"
  ],
  "inasmuch as": [
    "quoniam",
    "quoniam (conj.)"
  ],
  "to hear": [
    "audiō, audīre, audīvī, audītum"
  ],
  "listen to": [
    "audiō, audīre, audīvī, audītum"
  ],
  "to take": [
    "capiō, capere, cēpī, captum",
    "accipiō, accipere, accēpī, acceptum"
  ],
  "get": [
    "capiō, capere, cēpī, captum",
    "parō, parāre, parāvī, parātum",
    "reperiō, reperīre, repperī, repertum"
  ],
  "to say": [
    "dīco, dīcere, dīxī, dictum",
    "loquor, loquī, locūtus sum"
  ],
  "to make": [
    "faciō, facere, fēcī, factum"
  ],
  "to flee": [
    "fugiō, fugere, fūgī, fugitūrum"
  ],
  "hurry away": [
    "fugiō, fugere, fūgī, fugitūrum"
  ],
  "go into exile": [
    "fugiō, fugere, fūgī, fugitūrum"
  ],
  "shun": [
    "vītō, vītāre, vītāvī, vītātum",
    "fugiō, fugere, fūgī, fugitūrum"
  ],
  "to come": [
    "veniō, venīre, vēnī, ventum",
    "accēdō, accēdere, accessī, accessum",
    "accēdō, accēdere, accessī, accessum (ad + cēdo)"
  ],
  "to come upon": [
    "inveniō, invenīre, invēnī, inventum"
  ],
  "to live": [
    "vīvō, vīvere, vīxī, vīctum"
  ],
  "heading": [
    "caput, capitis"
  ],
  "consul": [
    "cōnsul, cōnsulis"
  ],
  "satisfactorily": [
    "bene"
  ],
  "to understand": [
    "intellegō, intellegere, intellēxī, intellēctum"
  ],
  "to send": [
    "mittō, mittere, mīsī, missum"
  ],
  "let go": [
    "mittō, mittere, mīsī, missum",
    "āmittō, āmittere, āmīsī, āmissum",
    "omittō, omittere, omīsī, omissum"
  ],
  "to feel": [
    "sentiō, sentīre, sēnsī, sēnsum"
  ],
  "experience": [
    "ūsus, ūsūs",
    "perītia, perītiae",
    "ūtor, ūtī, ūsus sum",
    "ūtor, ūtī, ūsus sum (+ abl.)",
    "sentiō, sentīre, sēnsī, sēnsum",
    "experior, experīrī, expertus sum"
  ],
  "young man or woman": [
    "adulēscēns, adulēscentis",
    "adulēscēns, adulēscentis (m. and f.)"
  ],
  "asia": [
    "asia, asiae",
    "Asia, Asiae (f)"
  ],
  "caesar": [
    "caesar, caesaris",
    "Caesar, Caesaris (m)"
  ],
  "suffering": [
    "patientia, patientiae",
    "patientia, patientiae (f)"
  ],
  "endurance": [
    "patientia, patientiae",
    "patientia, patientiae (f)"
  ],
  "harsh": [
    "immītis, immīte",
    "dūrus, dūra, dūrum",
    "strīdor, strīdōris",
    "torvus, torva, torvum",
    "asper, aspera, asperum",
    "acerbus, acerba, acerbum",
    "absurdus, absurda, absurdum"
  ],
  "grievous": [
    "gravis, grave",
    "acerbus, acerba, acerbum"
  ],
  "on behalf of": [
    "prō",
    "prō (prep. + abl.)"
  ],
  "in return for": [
    "prō",
    "prō (prep. + abl.)"
  ],
  "for a long time": [
    "diū",
    "diū (adv)"
  ],
  "to send away": [
    "āmittō, āmittere, āmīsī, āmissum"
  ],
  "to fall": [
    "cadō, cadere, cecidī, cāsūrum",
    "dēlābor, dēlābī, dēlapsus sum"
  ],
  "to create": [
    "creō, creāre, creāvī, creātum"
  ],
  "achievement": [
    "factum, factī",
    "factum, factī (n)"
  ],
  "signal": [
    "signum, signī",
    "signum, signī (n)",
    "conclāmō, conclāmāre, conclāmāvī, conclāmātum"
  ],
  "indication": [
    "signum, signī",
    "signum, signī (n)",
    "specimen, speciminis",
    "dēnuntiātiō, dēnuntiātiōnis",
    "dēmōnstrātiō, dēmōnstrātiōnis"
  ],
  "herself": [
    "suī",
    "ipsa",
    "ipse, ipsa, ipsum"
  ],
  "itself": [
    "suī",
    "ipse, ipsa, ipsum"
  ],
  "the very": [
    "ipse, ipsa, ipsum"
  ],
  "the actual": [
    "ipse, ipsa, ipsum"
  ],
  "each person": [
    "quisque, quidque,",
    "quisque, quidque, (gen: cuiusque; dat. cuique)"
  ],
  "each thing": [
    "quisque, quidque,",
    "quisque, quidque, (gen: cuiusque; dat. cuique)"
  ],
  "themselves": [
    "suī"
  ],
  "taught": [
    "doctus, docta, doctum"
  ],
  "his own": [
    "suus, sua, suum"
  ],
  "her own": [
    "suus, sua, suum"
  ],
  "its own": [
    "suus, sua, suum"
  ],
  "their own": [
    "suus, sua, suum"
  ],
  "as a prefix": [
    "per",
    "per (prep + acc)"
  ],
  "thoroughly": [
    "per",
    "per (prep + acc)"
  ],
  "completely": [
    "per",
    "per (prep + acc)",
    "absolūtē, absolūtius, absolūtissimē"
  ],
  "some day": [
    "ōlim"
  ],
  "in the future": [
    "ōlim"
  ],
  "to nourish": [
    "alō, alere, aluī, altum"
  ],
  "cherish": [
    "alō, alere, aluī, altum",
    "foveō, fovēre, fōvī, fōtum"
  ],
  "to esteem": [
    "dīligō, dīligere, dīlēxī, dīlēctum"
  ],
  "to join": [
    "iungō, iungere, iunxī, iūnctum"
  ],
  "to stand": [
    "stō, stāre, stetī, statum"
  ],
  "stand still or firm": [
    "stō, stāre, stetī, statum"
  ],
  "a living creature": [
    "animal, animālis"
  ],
  "rome": [
    "rōma, rōmae",
    "Rōma, Rōmae (f)",
    "rōma, rōmae, locative rōmae"
  ],
  "disturbance": [
    "turba, turbae",
    "turba, turbae (f)"
  ],
  "mob": [
    "turba, turbae",
    "vulgus, vulgī",
    "turba, turbae (f)",
    "vulgus, vulgī (n., sometimes m.)"
  ],
  "accross": [
    "trāns",
    "trāns (prep. + acc.)"
  ],
  "to speak to": [
    "appellō, appellāre, appellāvī, appellātum"
  ],
  "to run": [
    "currō, currere, cucurrī, cursum"
  ],
  "rush": [
    "iuncus, iuncī",
    "vādō, vādere, vāsī",
    "currō, currere, cucurrī, cursum",
    "concitō, concitāre, concitāvī, concitātum"
  ],
  "to change": [
    "mūtō, mūtāre, mūtāvī, mūtātum"
  ],
  "alter": [
    "mūtō, mūtāre, mūtāvī, mūtātum",
    "variō, variāre, variāvī, variātum",
    "alterō, alterāre, alterāvī, alterātum",
    "immūtō, immūtāre, immūtāvī, immūtātum"
  ],
  "to hold": [
    "teneō, tenēre, tenuī, tentum"
  ],
  "to avoid": [
    "vītō, vītāre, vītāvī, vītātum"
  ],
  "italy": [
    "italia, italiae",
    "bonōnia, bonōniae",
    "Italia, Italiae (f)"
  ],
  "recollection": [
    "memoria, memoriae",
    "memoria, memoriae (f)",
    "recordātiō, recordātiōnis"
  ],
  "period of time": [
    "tempestās, tempestātis"
  ],
  "season": [
    "tempestās, tempestātis",
    "condiō, condīre, condīvī, condītum"
  ],
  "weather": [
    "tempestās, tempestātis"
  ],
  "and so": [
    "itaque",
    "itaque (adv)"
  ],
  "to entrust": [
    "committō, committere, commīsī, commissum"
  ],
  "commit": [
    "committō, committere, commīsī, commissum"
  ],
  "to look for": [
    "exspectō, exspectāre, exspectāvī, exspectātum"
  ],
  "to throw": [
    "iaciō, iacere, iēcī, iactum",
    "dēiciō, dēicere, dēiēcī, dēiectum",
    "cōniciō, cōnicere, cōniēcī, coniectum"
  ],
  "to fear": [
    "timeō, timēre, timuī",
    "metuō, metuere, metuī"
  ],
  "an age": [
    "aetās, aetātis"
  ],
  "hearer": [
    "audītor, audītōris",
    "audītor, audītōris (m)"
  ],
  "listener": [
    "Auditor",
    "arbiter, arbitrī",
    "audītor, audītōris",
    "audītor, audītōris (m)"
  ],
  "member of an audience": [
    "audītor, audītōris",
    "audītor, audītōris (m)"
  ],
  "mildness": [
    "clēmentia, clēmentiae",
    "clēmentia, clēmentiae (f)"
  ],
  "gentleness": [
    "clēmentia, clēmentiae",
    "clēmentia, clēmentiae (f)"
  ],
  "mercy": [
    "clēmentia, clēmentiae",
    "clēmentia, clēmentiae (f)"
  ],
  "intention": [
    "mēns, mentis"
  ],
  "satire": [
    "satura, saturae",
    "satura, saturae (f)"
  ],
  "brief": [
    "brevis, breve",
    "commentārius, commentāriī"
  ],
  "agreeable": [
    "dulcis, dulce",
    "facilis, facile",
    "grātus, grāta, grātum",
    "blandus, blanda, blandum",
    "dēlectābilis, dēlectābile",
    "iūcundus, iūcunda, iūcundum",
    "grātiōsus, grātiōsa, grātiōsum",
    "periūcundus, periūcunda, periūcundum",
    "voluptārius, voluptāria, voluptārium"
  ],
  "able": [
    "potis pote",
    "potēns, potentis",
    "possum, posse, potuī",
    "potēns, gen potentis",
    "queō, quīre, quiī / quīvī, quitum"
  ],
  "powerful": [
    "potis pote",
    "potēns, potentis",
    "efficāx, efficācis",
    "potēns, gen potentis",
    "imperiōsus, imperiōsa, imperiōsum"
  ],
  "mighty": [
    "potēns, potentis",
    "potēns, gen potentis",
    "imperiōsus, imperiōsa, imperiōsum"
  ],
  "aged": [
    "senīlis, senīle",
    "senex, gen. senis",
    "pervetus, perveteris",
    "annōsus, annōsa, annōsum",
    "senectus, senecta, senectum",
    "vetustus, vetusta, vetustum",
    "longaevus, longaeva, longaevum",
    "senex, gen. senis (adj and noun)",
    "grandaevus, grandaeva, grandaevum"
  ],
  "to rule": [
    "regō, regere, rēxī, rēctum"
  ],
  "trivial": [
    "levis, leve"
  ],
  "quickly": [
    "cito",
    "cursim",
    "celeriter, celerius, celerrimē"
  ],
  "to admit": [
    "admittō, admittere, admīsī, admissum"
  ],
  "began": [
    "coepī, coepisse, coeptum"
  ],
  "to desire": [
    "cupiō, cupere, cupīvī, cupītum",
    "expetō, expetere, expetīvī, expetītum",
    "dēsīderō, dēsīderāre, dēsīderāvī, dēsīderātum"
  ],
  "to destroy": [
    "dēleō, dēlēre, dēlēvī, dēlētum"
  ],
  "wipe out": [
    "dēleō, dēlēre, dēlēvī, dēlētum"
  ],
  "erase": [
    "dēleō, dēlēre, dēlēvī, dēlētum"
  ],
  "to begin": [
    "ineō, inīre, iniī, initum",
    "incipiō, incipere, incēpī, inceptum"
  ],
  "to sail": [
    "nāvigō, nāvigāre, nāvigāvī, nāvigātum"
  ],
  "navigate": [
    "nāvigō, nāvigāre, nāvigāvī, nāvigātum"
  ],
  "to neglect": [
    "neglegō, neglegere, neglēxī, neglēctum",
    "praetereō, praeterīre, praeteriī, praeteritum"
  ],
  "disregard": [
    "neglegō, neglegere, neglēxī, neglēctum"
  ],
  "to read aloud": [
    "recitō, recitāre, recitāvī, recitātum"
  ],
  "recite": [
    "recitō, recitāre, recitāvī, recitātum"
  ],
  "origin": [
    "genus, generis"
  ],
  "type": [
    "genus, generis"
  ],
  "class": [
    "genus, generis"
  ],
  "an enemy": [
    "hostis, hostis"
  ],
  "sport": [
    "lūdus, lūdī",
    "lūsus, lūsūs",
    "lūdus, lūdī (m)"
  ],
  "the enemy": [
    "hostēs, hostium",
    "hostēs, hostium (m)"
  ],
  "uprightness": [
    "probitās, probitātis",
    "probitās, probitātis (f)"
  ],
  "honesty": [
    "honestās, honestātis",
    "probitās, probitātis",
    "probitās, probitātis (f)"
  ],
  "knowledge": [
    "prūdentia -ae",
    "prūdentia -ae (f)",
    "scientia, scientiae"
  ],
  "renowned": [
    "clārus, clāra, clārum",
    "fāmōsus, fāmōsa, fāmōsum"
  ],
  "illustrious": [
    "illūstris, illūstre",
    "clārus, clāra, clārum",
    "inclitus, inclita, inclitum"
  ],
  "therepon": [
    "deinde"
  ],
  "to flow": [
    "fluō, fluere, flūxī, flūxum"
  ],
  "to pick out": [
    "legō, legere, lēgī, lēctum"
  ],
  "to mix up": [
    "misceō, miscēre, miscuī, mixtum"
  ],
  "disturb": [
    "misceō, miscēre, miscuī, mixtum",
    "disturbō, disturbāre, disturbāvī, disturbātum",
    "sollicitō, sollicitāre, sollicitāvī, sollicitātum"
  ],
  "to move": [
    "moveō, movēre, mōvī, mōtum"
  ],
  "pass. of videō": [
    "videor, vidērī, vīsus sum"
  ],
  "to be seen": [
    "videor, vidērī, vīsus sum"
  ],
  "proof": [
    "argūmentum, argūmentī",
    "argūmentum, argūmentī (n)",
    "experientia, experientiae"
  ],
  "evidence": [
    "indicium, indiciī",
    "argūmentum, argūmentī",
    "testimōnium, testimōniī",
    "argūmentum, argūmentī (n)"
  ],
  "argument": [
    "argūmentum, argūmentī",
    "argūmentum, argūmentī (n)"
  ],
  "increaser": [
    "auctor, auctōris",
    "auctor, auctōris (m)"
  ],
  "author": [
    "auctor, auctōris",
    "creātor, creātōris",
    "auctor, auctōris (m)",
    "condītor, condītōris",
    "inventor, inventōris",
    "repertor, repertōris",
    "scrīptor, scrīptōris",
    "scrīptor, scrīptōris (m)"
  ],
  "originator": [
    "auctor, auctōris",
    "auctor, auctōris (m)"
  ],
  "kindness": [
    "venia, veniae",
    "beneficium, beneficiī",
    "benignitās, benignitātis",
    "beneficium, beneficiī (n)",
    "beneficentia, beneficentiae",
    "benevolentia, benevolentiae"
  ],
  "household": [
    "familia, familiae",
    "familia, familiae (f)"
  ],
  "greece": [
    "graecia, graeciae",
    "Graecia, Graeciae (f)"
  ],
  "judge": [
    "iūdex, iūdicis",
    "iūdex, iūdicis (m)",
    "disceptātor, disceptātōris",
    "putō, putāre, putāvī, putātum",
    "arbitror, arbitrārī, arbitrātus sum",
    "iūdicō, iūdicāre, iūdicāvī, iūdicātum"
  ],
  "juror": [
    "iūdex, iūdicis",
    "iūdex, iūdicis (m)"
  ],
  "judgement": [
    "ratiō, ratiōnis",
    "iūdicium, iūdiciī",
    "cōnsilium, cōnsiliī",
    "iūdicium, iūdiciī (n)",
    "cōnsilium, cōnsiliī (n)",
    "existimātiō, existimātiōnis"
  ],
  "decision": [
    "dēcrētum, dēcrētī",
    "iūdicium, iūdiciī",
    "dēcīsio, dēcīsiōnis",
    "iūdicium, iūdiciī (n)"
  ],
  "trial": [
    "iūdicium, iūdiciī",
    "iūdicium, iūdiciī (n)",
    "temptatiō, temptatiōnis",
    "experientia, experientiae",
    "experīmentum, experīmentī"
  ],
  "sin": [
    "scelus, sceleris",
    "peccātum, peccātī",
    "peccō, peccāre, peccāvī, peccātum"
  ],
  "wickedness": [
    "nefās",
    "scelus, sceleris",
    "nēquitia, nēquitiae",
    "improbitās, improbitātis"
  ],
  "who? whose? whom? what": [
    "quis? quid"
  ],
  "what? which? what kind": [
    "quī? quae? quod"
  ],
  "definite": [
    "certus, certa, certum"
  ],
  "weighty": [
    "gravis, grave"
  ],
  "important": [
    "gravis, grave",
    "magna, magnae",
    "magnus, magna, magnum"
  ],
  "not subject to death": [
    "immortālis, immortāle"
  ],
  "mind you": [
    "at",
    "at (conj)"
  ],
  "you say": [
    "at",
    "at (conj)"
  ],
  "to delight": [
    "dēlectō, dēlectāre, dēlectāvī, dēlectātum"
  ],
  "charm": [
    "Blanditia",
    "lepor, lepōris",
    "lepōs, lepōris",
    "venustās, venustātis",
    "amoenitās, amoenitātis",
    "iūcunditās, iūcunditātis",
    "dēlectō, dēlectāre, dēlectāvī, dēlectātum"
  ],
  "to free": [
    "līberō, līberāre, līberāvī, līberātum"
  ],
  "liberate": [
    "līberō, līberāre, līberāvī, līberātum"
  ],
  "to prepare": [
    "parō, parāre, parāvī, parātum"
  ],
  "provide": [
    "praestō",
    "parō, parāre, parāvī, parātum",
    "adōrnō, adōrnāre, adōrnāvī, adōrnātum",
    "apparō, apparāre, apparāvī, apparātum",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus",
    "praebeō, praebēre, praebuī, praebitum",
    "sufficiō, sufficere, suffēcī, suffectum",
    "subōrnō, subōrnāre, subornāvī, subornātum",
    "accommodō, accommodāre, accommodāvī, accommodātum"
  ],
  "ire": [
    "īra, īrae",
    "īra, īrae (f)"
  ],
  "fatherland": [
    "patria, patriae",
    "patria, patriae (f)"
  ],
  "native land": [
    "patria, patriae",
    "patria, patriae (f)"
  ],
  "philosophy": [
    "philosophia, philosophiae",
    "philosophia, philosophiae (f)"
  ],
  "to pay the penalty": [
    "poenās dare"
  ],
  "vote": [
    "sententia, sententiae",
    "sententia, sententiae (f)"
  ],
  "mode of life": [
    "vīta, vītae",
    "vīta, vītae (f)"
  ],
  "old-time": [
    "antīqua, antīquae",
    "antīquus, antīqua, antīquum"
  ],
  "o! oh": [
    "ō"
  ],
  "is": [
    "est"
  ],
  "conspirators": [
    "coniūrātī, coniūrātōrum",
    "coniūrātī, coniūrātōrum (m, pl)"
  ],
  "enjoyment": [
    "ūsūra, ūsūrae",
    "frūctus frūctūs",
    "frūctus, frūctūs",
    "fruitiō, fruitiōnis",
    "frūctus, frūctūs (m)",
    "voluptās, voluptātis",
    "iūcunditās, iūcunditātis",
    "dēlectātiō, dēlectātiōnis",
    "dēlectātiō, dēlectātiōnis (f)"
  ],
  "handwriting": [
    "manus, manūs"
  ],
  "senate": [
    "cūria, cūriae",
    "senātus, senātūs",
    "senātus, senātūs (m)"
  ],
  "servitude": [
    "servitium, servitiī",
    "servitūs, servitūtis",
    "famulitium, famulitiī",
    "servitūs, servitūtis (f)"
  ],
  "breathing": [
    "flātus, flātūs",
    "spīritus, spīritūs",
    "spīritus, spīritūs (m)"
  ],
  "spirit": [
    "Animus",
    "anima, animae",
    "animus, animī",
    "animus, animī (m)",
    "anima, animae (f.)",
    "spīritus, spīritūs",
    "spīritus, spīritūs (m)"
  ],
  "line of verse": [
    "versus, versūs",
    "versus, versūs (m)"
  ],
  "of the community": [
    "commūnis, commūne"
  ],
  "for the community": [
    "commūnis, commūne"
  ],
  "right-hand": [
    "dexter, dextra, dextrum"
  ],
  "left-hand": [
    "sinister, sinistra, sinistrum"
  ],
  "harmful": [
    "noxius, noxia, noxium",
    "sinister, sinistra, sinistrum"
  ],
  "ill-omened": [
    "sinister, sinistra, sinistrum"
  ],
  "to be without": [
    "careō, carēre, caruī, caritūrum",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "be deprived of": [
    "careō, carēre, caruī, caritūrum",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "be free from": [
    "careō, carēre, caruī, caritūrum",
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "to ward off": [
    "dēfendō, dēfendere, dēfendī, dēfēnsum"
  ],
  "to go away": [
    "abeō, abīre, abiī, abitum",
    "discēdō, discēdere, discessī, discessum"
  ],
  "to hate": [
    "ōdī, ōdisse, ōsum"
  ],
  "to keep": [
    "prohibeō, prohibēre, prohibuī, prohibitum"
  ],
  "hinder": [
    "tardō, tardāre, tardāvī, tardātum",
    "exclūdō, exclūdere, exclūsī, exclūsum",
    "impediō, impedīre, impedīvī, impedītum",
    "prohibeō, prohibēre, prohibuī, prohibitum",
    "retardō, retardāre, retardāvī, retardātum",
    "interclūdō, interclūdere, interclūsī, interclūsum"
  ],
  "prohibit": [
    "prohibeō, prohibēre, prohibuī, prohibitum",
    "interdīcō, interdīcere, interdīxī, interdīctum"
  ],
  "to proclaim": [
    "prōnūntiō, prōnūntiāre, prōnūntiāvī, prōnūntiātum"
  ],
  "declaim": [
    "dēclāmō, dēclāmāre, dēclāmāvī, dēclāmātum",
    "prōnūntiō, prōnūntiāre, prōnūntiāvī, prōnūntiātum"
  ],
  "pronounce": [
    "prōnūntiō, prōnūntiāre, prōnūntiāvī, prōnūntiātum"
  ],
  "situation": [
    "Problema",
    "causa, causae",
    "causa, causae (f)"
  ],
  "purpose": [
    "fīnis, fīnis",
    "fīnis, fīnis (m)",
    "cōnsilium, cōnsiliī",
    "cōnsilium, cōnsiliī (n)"
  ],
  "boundaries": [
    "fīnēs, fīnium",
    "fīnēs, fīnium (m)"
  ],
  "territory": [
    "fīnēs, fīnium",
    "fīnēs, fīnium (m)",
    "territōrium, territōriī"
  ],
  "greeting": [
    "salūs, salūtis",
    "salūtātiō, salūtātiōnis"
  ],
  "troy": [
    "trōia, trōiae",
    "Trōia, Trōiae (f)"
  ],
  "neighbor": [
    "accola, accolae",
    "vīcīnus, vīcīnī and vīcīna, vīcīnae",
    "vīcīnus, vīcīnī (m) and vīcīna, vīcīnae (f)"
  ],
  "the common people": [
    "plēbs, plēbis",
    "vulgus, vulgī",
    "plēbs, plēbis (f.)",
    "vulgus, vulgī (n., sometimes m.)"
  ],
  "rabble": [
    "vulgus, vulgī",
    "vulgus, vulgī (n., sometimes m.)"
  ],
  "and also": [
    "ac",
    "necnon",
    "atque or ac"
  ],
  "and even": [
    "atque or ac"
  ],
  "and in fact": [
    "atque or ac"
  ],
  "to hold together": [
    "contineō, continēre, continuī, contentum"
  ],
  "to bid": [
    "iubeō, iubēre, iussī, iussum"
  ],
  "to labor": [
    "labōrō, labōrāre, labōrāvī, labōrātum"
  ],
  "to seize": [
    "rapiō, rapere, rapuī, raptum"
  ],
  "snatch": [
    "rapiō, rapere, rapuī, raptum",
    "abripiō, abripere, abripuī, abreptum",
    "capessō, capessere, capessīvī, capessitum",
    "praeripiō, praeripere, praeripuī, praereptum",
    "dēprehendō, dēprehendere, dēprehendī, dēprehēnsum"
  ],
  "carry away": [
    "rapiō, rapere, rapuī, raptum"
  ],
  "to leave behind": [
    "relinquō, relinquere, relīquī, relictum"
  ],
  "abandon": [
    "dēcēdō, dēcēdere, dēcessī, dēcessum",
    "relinquō, relinquere, relīquī, relictum",
    "abiectō, abiectāre, abiectāvī, abiectātum",
    "dērelinquō, dērelinquere, dēreliquī, dērelictum"
  ],
  "to know": [
    "sciō, scīre, scīvī, scītum",
    "gnōscō, gnōscere, gnōvī, gnōtum"
  ],
  "to touch": [
    "tangō, tangere, tetigī, tāctum",
    "tentō, tentāre, tentāvī, tentātum"
  ],
  "trustworthiness": [
    "fidēs, fideī",
    "fidēs, fideī (f)"
  ],
  "fidelity": [
    "fidēs, fideī",
    "fidēs, fideī (f)"
  ],
  "guarantee": [
    "fidēs, fideī",
    "fidēs, fideī (f)",
    "reprōmittō, reprōmittere, reprōmīsī, reprōmissum"
  ],
  "protection": [
    "fidēs, fideī",
    "fidēs, fideī (f)",
    "custōdia, custōdiae",
    "dēfēnsiō, dēfēnsiōnis",
    "patrōcinium, patrōciniī",
    "prōtectiō, prōtectiōnis",
    "custōdia, custōdiae (f.)",
    "cōnservātiō, cōnservātiōnis",
    "dēfensa, dēfensae), first declension (late latin",
    "dēfensa, dēfensae); first declension (Late Latin"
  ],
  "property": [
    "rēs, reī",
    "rēs, reī (f)",
    "mancipium, mancipiī",
    "prōprietās, prōprietātis"
  ],
  "state": [
    "rēspūblica",
    "status, statūs",
    "polītīa, polītīae",
    "cīvitās, cīvitātis",
    "cīvitās, cīvitātis (f)",
    "ēdō, ēdere, ēdidī, ēditum",
    "rēs, pūblica, reī, pūblicae",
    "rēs, pūblica, reī, pūblicae (f)"
  ],
  "commonwealth": [
    "rēs, pūblica, reī, pūblicae",
    "rēs, pūblica, reī, pūblicae (f)"
  ],
  "republic": [
    "rēs, pūblica, reī, pūblicae",
    "rēs, pūblica, reī, pūblicae (f)"
  ],
  "level": [
    "Gradus",
    "aequus, aequa, aequum",
    "campester, campestris, campestre"
  ],
  "unsure": [
    "incertus, incerta, incertum"
  ],
  "middle of": [
    "medius, media, medium"
  ],
  "on the other side of": [
    "ultrā",
    "ultrā (adv. and prep. + acc)"
  ],
  "to distinguish": [
    "cernō, cernere, crēvī, crētum"
  ],
  "to snatch away": [
    "ēripiō, ēripere, ēripuī, ēreptum",
    "ēripiō, ēripere, ēripuī, ēreptum (ē + rapiō)"
  ],
  "he says or said": [
    "inquit",
    "inquit (defective verb)"
  ],
  "to raise": [
    "tollō, tollere, sustulī, sublātum"
  ],
  "lift up": [
    "Ascende",
    "Ascensorem",
    "tollō, tollere, sustulī, sublātum",
    "relevō, relevāre, relevāvī, relevātum"
  ],
  "citadel": [
    "arx, arcis",
    "castellum, castellī"
  ],
  "stronghold": [
    "arx, arcis"
  ],
  "commander": [
    "dux, ducis",
    "dux, ducis (m)",
    "mandator, mandatōris",
    "antesignānus, antesignānī"
  ],
  "orator": [
    "ōrātor, ōrātōris",
    "ōrātor, ōrātōris (m)"
  ],
  "speaker": [
    "ōrātor, ōrātōris",
    "ōrātor, ōrātōris (m)"
  ],
  "somebody": [
    "aliquis, aliquid"
  ],
  "great-hearted": [
    "magnanimus, magnanima, magnanimum"
  ],
  "magnanimous": [
    "magnanimus, magnanima, magnanimum"
  ],
  "at any time": [
    "umquam",
    "umquam (adv.)"
  ],
  "to bring up": [
    "ēducō, ēducāre, ēducāvī, ēducātum"
  ],
  "to be glad": [
    "gaudeō, gaudēre, gāvīsus sum"
  ],
  "to exhibit": [
    "ostendō, ostendere, ostendī, ostentum"
  ],
  "to seek": [
    "petō, petere, petīvī, petītum",
    "quaerō, quaerere, quaesīvī, quaesītum",
    "requīrō, requīrere, requīsīvī, requīsītum"
  ],
  "beseech": [
    "ōrō, ōrāre, ōrāvī, ōrātum",
    "petō, petere, petīvī, petītum",
    "obsecrō, obsecrāre, obsecrāvī, obsecrātum"
  ],
  "to press": [
    "premō, premere, pressī, pressum"
  ],
  "press hard": [
    "premō, premere, pressī, pressum"
  ],
  "to suppress": [
    "opprimō, opprimere, oppressī, oppressum"
  ],
  "overwhelm": [
    "opprimō, opprimere, oppressī, oppressum",
    "cooperiō, cooperīre, cooperuī, coopertum"
  ],
  "overpower": [
    "opprimō, opprimere, oppressī, oppressum"
  ],
  "check": [
    "dētineō, dētinēre, dētinuī, dētentum",
    "inhibeō, inhibēre, inhibuī, inhibitum",
    "opprimō, opprimere, oppressī, oppressum",
    "reprimō, reprimere, repressī, repressum",
    "cōnfūtō, cōnfūtāre, cōnfūtāvī, cōnfūtātum"
  ],
  "to turn": [
    "vertō, vertere, vertī, versum"
  ],
  "to turn away": [
    "āvertō, āvertere, āvertī, āversum"
  ],
  "to turn back": [
    "revertō, revertere, revertī, reversum"
  ],
  "carthage": [
    "carthāgō, carthāginis"
  ],
  "tale": [
    "fābula, fābulae",
    "fābula, fābulae (f)"
  ],
  "commander-in-chief": [
    "imperātor, imperātōris",
    "imperātor, imperātōris (m)"
  ],
  "emperor": [
    "Imperator",
    "imperātor, imperātōris",
    "prīnceps, gen. prīncipis",
    "imperātor, imperātōris (m)"
  ],
  "power to command": [
    "imperium, imperiī",
    "imperium, imperiī (n)"
  ],
  "supreme power": [
    "imperium, imperiī",
    "imperium, imperiī (n)"
  ],
  "authority": [
    "diciō, diciōnis",
    "imperium, imperiī",
    "imperium, imperiī (n)",
    "auctōritās, auctōritātis"
  ],
  "control": [
    "rēgnum, rēgnī",
    "diciō, diciōnis",
    "imperium, imperiī",
    "regimen, regiminis",
    "imperium, imperiī (n)"
  ],
  "refuge": [
    "refugium, refugiī",
    "latibulum, latibulī",
    "perfugium, perfugiī",
    "perfugium, perfugiī (n)"
  ],
  "shelter": [
    "perfugium, perfugiī",
    "testūdō, testūdinis",
    "umbrāculum, umbrāculī",
    "perfugium, perfugiī (n)",
    "prōtectiō, prōtectiōnis"
  ],
  "relief": [
    "sōlācium, sōlāciī",
    "subsidium, subsidiī",
    "sōlācium, sōlāciī (n)",
    "aberrātiō, aberrātiōnis"
  ],
  "afterwards": [
    "posteā",
    "postmodo"
  ],
  "accept": [
    "Accipe",
    "accipiō, accipere, accēpī, acceptum",
    "ascīscō, ascīscere, ascīvī, ascītum"
  ],
  "to take out": [
    "excipiō, excipere, excēpī, exceptum"
  ],
  "to take back": [
    "recipiō, recipere, recēpī, receptum"
  ],
  "regain": [
    "recipiō, recipere, recēpī, receptum",
    "reciperō, reciperāre, reciperāvī, reciperātum"
  ],
  "to strike": [
    "pellō, pellere, pepulī, pulsum"
  ],
  "drive out": [
    "exigō, exigere, exēgī, exāctum",
    "pellō, pellere, pepulī, pulsum",
    "dēiciō, dēicere, dēiēcī, dēiectum",
    "excutiō, excutere, excussī, excussum",
    "expellō, expellere, expulī, expulsum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "banish": [
    "Repelle Daedram",
    "pellō, pellere, pepulī, pulsum",
    "expellō, expellere, expulī, expulsum",
    "sēpōnō, sēpōnere, sēposuī, sēpositum",
    "summoveō, summovēre, summōvī, summōtum"
  ],
  "to drive out": [
    "exigō, exigere, exēgī, exāctum",
    "dēiciō, dēicere, dēiēcī, dēiectum",
    "expellō, expellere, expulī, expulsum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "expel": [
    "expellō, expellere, expulī, expulsum",
    "dētrūdō, dētrūdere, dētrūsī, dētrūsum",
    "exterminō, extermināre, extermināvī, exterminātum"
  ],
  "to tell": [
    "narrō, nārrāre, nārrāvī, nārrātum"
  ],
  "narrate": [
    "narrō, narrāre, narrāvī, narrātum",
    "narrō, nārrāre, nārrāvī, nārrātum",
    "intimō, intimāre, intimāvī, intimātum"
  ],
  "strive for": [
    "quaerō, quaerere, quaesīvī, quaesītum"
  ],
  "inquire": [
    "quaerō, quaerere, quaesīvī, quaesītum",
    "percontor, percontārī, percontātus sum",
    "scīscitor, scīscitārī, scīscitātus sum"
  ],
  "inquire into": [
    "quaerō, quaerere, quaesīvī, quaesītum"
  ],
  "to laugh": [
    "rīdeō, rīdēre, rīsī, rīsum"
  ],
  "savage": [
    "atrōx, atrōcis",
    "ferōx, gen. ferōcis",
    "torvus, torva, torvum",
    "rabidus, rabida, rabidum"
  ],
  "loyal": [
    "Fidelis",
    "fidēlis, fidēle"
  ],
  "judicious": [
    "sapiēns, gen. sapientis"
  ],
  "a wise man": [
    "sapiēns, gen. sapientis"
  ],
  "a wise woman": [
    "sapiēns, gen. sapientis"
  ],
  "farthest": [
    "ultimus, ultima, ultimum",
    "extrēmus, extrēma, extrēmum"
  ],
  "extreme": [
    "ultimus, ultima, ultimum",
    "extrēmus, extrēma, extrēmum"
  ],
  "final": [
    "ultimus, ultima, ultimum"
  ],
  "he says": [
    "āit, āiunt",
    "āit, āiunt (defective verb)"
  ],
  "they say": [
    "āit, āiunt",
    "āit, āiunt (defective verb)"
  ],
  "to believe": [
    "crēdo, crēdere, crēdidī, crēditum",
    "crēdo, crēdere, crēdidī, crēditum (+ acc)"
  ],
  "to lie": [
    "iaceō, iacēre, iacuī"
  ],
  "lie prostrate": [
    "iaceō, iacēre, iacuī"
  ],
  "lie dead": [
    "iaceō, iacēre, iacuī"
  ],
  "to deny": [
    "negō, negāre, negāvī, negātum"
  ],
  "not say that": [
    "negō, negāre, negāvī, negātum"
  ],
  "to not know": [
    "nesciō, nescīre, nescīvī, nescītum"
  ],
  "be ignorant": [
    "nesciō, nescīre, nescīvī, nescītum"
  ],
  "to announce": [
    "nūntiō, nūnitāre, nūntiāvī, nūntiātum"
  ],
  "to make open": [
    "patefaciō, patefacere, patefēcī, patefactum"
  ],
  "to reckon": [
    "putō, putāre, putāvī, putātum"
  ],
  "imagine": [
    "reor, rērī, ratus sum",
    "opīnor, opīnārī, opīnātus sum",
    "putō, putāre, putāvī, putātum",
    "imāginor, imāginārī, imāginātus sum"
  ],
  "to hope for": [
    "spērō, spērāre, spērāvī, spērātum"
  ],
  "to undertake": [
    "suscipiō, suscipere, suscēpī, susceptum",
    "suscipiō, suscipere, suscēpī, susceptum (sub + capiō)"
  ],
  "dining": [
    "mēnsa, mēnsae",
    "mēnsa, mēnsae (f)"
  ],
  "dish": [
    "vāsum, vāsī",
    "epula, epulae",
    "mēnsa, mēnsae",
    "mēnsa, mēnsae (f)"
  ],
  "dessert": [
    "mēnsa secunda"
  ],
  "as pronoun: a certain one or thing": [
    "quīdam, quaedam, quiddam or quoddam",
    "quīdam, quaedam, quiddam (pron.) or quoddam (adj.)"
  ],
  "as adj: a certain": [
    "quīdam, quaedam, quiddam or quoddam",
    "quīdam, quaedam, quiddam (pron.) or quoddam (adj.)"
  ],
  "modest": [
    "pudīcus, pudīca, pudīcum",
    "pudibundus, pudibunda, pudibundum",
    "verēcundus, verēcunda, verēcundum"
  ],
  "chaste": [
    "pudīcus, pudīca, pudīcum"
  ],
  "arrogant": [
    "iactans, iactantis",
    "arrogāns, arrogantis",
    "superbus, superba, superbum"
  ],
  "overbearing": [
    "arrogāns, arrogantis",
    "superbus, superba, superbum"
  ],
  "joyless": [
    "trīstis, trīste"
  ],
  "grim": [
    "trīstis, trīste"
  ],
  "disgraceful": [
    "turpis, turpe",
    "inhonestus, inhonesta, inhonestum",
    "flāgitiōsus, flāgitiōsa, flāgitiōsum"
  ],
  "urbane": [
    "urbānus, urbāna, urbānum"
  ],
  "elegant": [
    "ēlegāns, ēlegantis",
    "facētus, facēta, facētum",
    "urbānus, urbāna, urbānum",
    "concinnus, concinna, concinnum"
  ],
  "adv. before superlatives: as": [
    "quam"
  ],
  "as possible": [
    "quam"
  ],
  "to entertain": [
    "invītō, invītāre, invītāvī, invītātum"
  ],
  "marketplace": [
    "forum -ī",
    "forum, forī",
    "forum, forī (n)"
  ],
  "forum": [
    "forum -ī",
    "forum, forī",
    "forum, forī (n)"
  ],
  "statute": [
    "lēx, lēgis"
  ],
  "grandson": [
    "nepōs, nepōtis",
    "nepōs, nepōtis (m)"
  ],
  "descendant": [
    "nepōs, nepōtis",
    "nepōs, nepōtis (m)"
  ],
  "unlike": [
    "dispār, disparis",
    "absimilis, absimile",
    "dissimilis, dissimile"
  ],
  "lowly": [
    "humilis, humile"
  ],
  "humble": [
    "humilis, humile",
    "humiliō, humiliāre, humiliāvī, humiliātum"
  ],
  "greater": [
    "Maior",
    "maior, maius"
  ],
  "ancestors": [
    "maiōrēs, maiōrum",
    "maiōrēs, maiōrum (m. pl.)"
  ],
  "foremost": [
    "prīmōris, prīmōre",
    "prīmus, prīma, prīmum",
    "prīnceps, gen. prīncipis"
  ],
  "principal": [
    "prīmus, prīma, prīmum",
    "prīmārius, prīmāria, prīmārium"
  ],
  "as many as": [
    "quot",
    "tot, tot ... quot"
  ],
  "resembling": [
    "similis, simile",
    "similis, simile (+ gen. or dat.)"
  ],
  "the gods": [
    "superī, superōrum",
    "caelestis, caeleste",
    "superī, superōrum (m. pl.)"
  ],
  "useful": [
    "ūtilis, ūtile",
    "prōsum, prōdesse, prōfuī"
  ],
  "advantageous": [
    "ūtilis, ūtile",
    "opportūnus, opportūna, opportūnum",
    "quaestuōsus, quaestuōsa, quaestuōsum"
  ],
  "to put": [
    "pōnō, pōnere, posuī, positum"
  ],
  "to approve": [
    "probō, probāre, probāvī, probātum"
  ],
  "recommend": [
    "probō, probāre, probāvī, probātum",
    "commendō, commendāre, commendāvī, commendātum",
    "conciliō, conciliāre, conciliāvī, conciliātum"
  ],
  "test": [
    "test",
    "experīmentum, experīmentī",
    "experior, experīrī, expertus sum",
    "probō, probāre, probāvī, probātum",
    "tentō, tentāre, tentāvī, tentātum",
    "explōrō, explōrāre, explōrāvī, explōrātum",
    "pertemptō, pertemptāre, pertemptāvī, pertemptātum"
  ],
  "weapons": [
    "ARMA",
    "arma, armōrum",
    "arma, armōrum (n. pl.)"
  ],
  "running": [
    "cursus, cursūs",
    "cursus, cursūs (m.)"
  ],
  "occasion": [
    "tempus, temporis",
    "occāsiō, occāsiōnis",
    "tempus, temporis (n)",
    "occāsiō, occāsiōnis (f)"
  ],
  "opportunity": [
    "tempus, temporis",
    "occāsiō, occāsiōnis",
    "tempus, temporis (n)",
    "occāsiō, occāsiōnis (f)",
    "opportūnitās, opportūnitātis"
  ],
  "parent": [
    "parēns, parentis",
    "genitor, genitōris",
    "parēns, parentis (m/f)"
  ],
  "planet": [
    "stēlla, stēllae",
    "planēta, planētae",
    "stēlla, stēllae (f)"
  ],
  "evening star": [
    "December",
    "vesper, vesperis or vesperī",
    "vesper, vesperis or vesperī (m)"
  ],
  "so that": [
    "ut",
    "ut (conj. + subjunct.)"
  ],
  "in order to": [
    "ut",
    "ut (conj. + subjunct.)"
  ],
  "so as to": [
    "ut",
    "ut (conj. + subjunct.)"
  ],
  ": as": [
    "ut",
    "ut (conj. + subjunct.)"
  ],
  "in order not to": [
    "nē",
    "nē (adv. and conj. with subjunct. of command and purpose)"
  ],
  "to go": [
    "sē cōnferre",
    "eō, īre, iī, itum",
    "cēdō, cēdere, cessī, cessum"
  ],
  "yield to": [
    "cēdō, cēdere, cessī, cessum",
    "obsequor, obsequī, obsecūtus sum"
  ],
  "grant": [
    "cēdō, cēdere, cessī, cessum",
    "largior, largīrī, largītus sum",
    "tribuō, tribuere, tribuī, tribūtum",
    "perhibeō, perhibēre, perhibuī, perhibitum"
  ],
  "submit": [
    "cēdō, cēdere, cessī, cessum",
    "obsequor, obsequī, obsecūtus sum",
    "sūbiciō, sūbicere, sūbiēcī, sūbiectum"
  ],
  "to dedicate": [
    "dēdicō, dēdicāre, dēdicāvī, dēdicātum"
  ],
  "to need": [
    "egeō, egēre, eguī",
    "egeō, egēre, eguī (+ abl. or gen.)"
  ],
  "to fill": [
    "pleō, plēre, plēvī, plētum",
    "expleō, explēre, explēvī, explētum"
  ],
  "fill up": [
    "expleō, explēre, explēvī, explētum",
    "suppleō, supplēre, supplēvī, supplētum",
    "adimpleō, adimplēre, adimplēvī, adimplētum"
  ],
  "to excel": [
    "praestō, praestāre, praestitī, praestitum"
  ],
  "exhibit": [
    "praestō",
    "exhibeō, exhibēre, exhibuī, exhibitum",
    "ostendō, ostendere, ostendī, ostentum",
    "ostentō, ostentāre, ostentāvī, ostentātum",
    "praestō, praestāre, praestitī, praestitum"
  ],
  "supply": [
    "praestō",
    "cōpia, cōpiae",
    "cōpia, cōpiae (f)",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus",
    "praebeō, praebēre, praebuī, praebitum",
    "suppleō, supplēre, supplēvī, supplētum",
    "sufficiō, sufficere, suffēcī, suffectum",
    "praestō, praestāre, praestitī, praestitum",
    "subministrō, subministrāre, subministrāvī, subministrātum"
  ],
  "furnish": [
    "ōrnō, ōrnāre, ōrnāvī, ōrnātum",
    "adōrnō, adōrnāre, adōrnāvī, adōrnātum",
    "apparō, apparāre, apparāvī, apparātum",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus",
    "praebeō, praebēre, praebuī, praebitum",
    "sufficiō, sufficere, suffēcī, suffectum",
    "praestō, praestāre, praestitī, praestitum",
    "subōrnō, subōrnāre, subornāvī, subornātum",
    "subministrō, subministrāre, subministrāvī, subministrātum"
  ],
  "to be silent": [
    "taceō, tacēre, tacuī, tacitum"
  ],
  "leave unmentioned": [
    "taceō, tacēre, tacuī, tacitum"
  ],
  "innate talent": [
    "ingenium, ingeniī",
    "ingenium, ingeniī (n)"
  ],
  "walls of a city": [
    "moenia, moenium",
    "moenia, moenium (n. pl.)"
  ],
  "constellation": [
    "astrum, astrī",
    "sīdus, sīderis"
  ],
  "worthy of": [
    "dignus, digna, dignum",
    "dignus, digna, dignum (+ abl.)"
  ],
  "unfeeling": [
    "dūrus, dūra, dūrum"
  ],
  "hardy": [
    "dūrus, dūra, dūrum"
  ],
  "so large": [
    "tantus, tanta, tantum"
  ],
  "of such a size": [
    "tantus, tanta, tantum"
  ],
  "lastly": [
    "dēnique",
    "dēnique (adv.)"
  ],
  "at least": [
    "quidem",
    "utique",
    "quidem (postpositive adv.)"
  ],
  "to such a degree": [
    "tam",
    "adeō",
    "tam (adv. with adjs. and advs.)"
  ],
  "as it were": [
    "quasi",
    "tamquam",
    "quasi (adv. or conj.)"
  ],
  "so to speak": [
    "tamquam"
  ],
  "to be sure": [
    "vērō",
    "vērō (adv.)"
  ],
  "to put together or into": [
    "condō, condere, condidī, conditum"
  ],
  "store": [
    "condō, condere, condidī, conditum"
  ],
  "found": [
    "condō, condere, condidī, conditum"
  ],
  "establish": [
    "condō, condere, condidī, conditum",
    "cōnfirmō, cōnfirmāre, cōnfirmāvī, cōnfirmātum"
  ],
  "to strive": [
    "contendō, contendere, contendī, contentum"
  ],
  "struggle": [
    "contentiō, contentiōnis",
    "luctor, luctārī, luctātus sum",
    "contendō, contendere, contendī, contentum"
  ],
  "hasten": [
    "contendō, contendere, contendī, contentum",
    "festīnō, festīnāre, festīnāvī, festīnātum",
    "accelerō, accelerāre, accelerāvī, accelerātum"
  ],
  "to soften": [
    "molliō, mollīre, mollīvī, mollītum"
  ],
  "make calm or less hostile": [
    "molliō, mollīre, mollīvī, mollītum"
  ],
  "to fight": [
    "pugnō, pugnāre, pugnāvī, pugnātum",
    "dēpugnō, dēpugnāre, dēpugnāvi, dēpugnātum"
  ],
  "to get up": [
    "surgō, surgere, surrēxī, surrēctum"
  ],
  "arise": [
    "nāscor, nāscī, nātus sum",
    "oborior, oborīrī, obortus sum",
    "surgō, surgere, surrēxī, surrēctum",
    "prōveniō, prōvenīre, prōvēnī, prōventum"
  ],
  "daughters": [
    "fīliābus"
  ],
  "the people": [
    "populus, populī",
    "populus, populī (m)"
  ],
  "a people": [
    "populus, populī",
    "populus, populī (m)"
  ],
  "a nation": [
    "populus, populī",
    "populus, populī (m)"
  ],
  "boys": [
    "puer, puerī",
    "puer, puerī (m)"
  ],
  "wisdom": [
    "cōnsilium, cōnsiliī",
    "sapientia, sapientiae",
    "cōnsilium, cōnsiliī (n)",
    "sapientia, sapientiae (f)"
  ],
  "hero": [
    "Heroos",
    "vir, virī",
    "hērōs, hērōis",
    "vir, virī (m)"
  ],
  "down from": [
    "dē",
    "dē (+abl.)"
  ],
  "concerning": [
    "dē",
    "dē (+abl.)"
  ],
  "to have": [
    "habeō, habēre, habuī, habitum",
    "obtineō, obtinēre, obtinuī, obtentum"
  ],
  "regard": [
    "dūcō, dūcere, dūxī, ductum",
    "habeō, habēre, habuī, habitum",
    "revereor, reverēri, reveritus sum",
    "respiciō, respicere, respexī, respectum",
    "aspectō, aspectāre, aspectāvī, aspectātum",
    "observō, observāre, observāvī, observātum"
  ],
  "to satisfy": [
    "satiō, satiāre, satiāvī, satiātum"
  ],
  "sate": [
    "satiō, satiāre, satiāvī, satiātum",
    "saturō, saturāre, saturāvī, saturātum"
  ],
  "honor": [
    "decus, decoris",
    "honor, honōris",
    "dīgnitās dīgnitātis",
    "mactō, mactāre, mactāvī, mactātum",
    "revereor, reverēri, reveritus sum",
    "honōrō, honōrāre, honōrāvī, honōrātum",
    "honestō, honestāre, honestāvī, honestātum"
  ],
  "public office": [
    "honor, honōris"
  ],
  "the remaining": [
    "cēterī, cēterae, cētera",
    "cēterī, cēterae, cētera (pl.)"
  ],
  "all the others": [
    "cēterī, cēterae, cētera",
    "cēterī, cēterae, cētera (pl.)"
  ],
  "how great": [
    "quantus, quanta, quantum"
  ],
  "laughable": [
    "rīdiculus, rīdicula, rīdiculum"
  ],
  "stealthily": [
    "fūrtim",
    "fūrtim (adv.)"
  ],
  "secretly": [
    "fūrtim",
    "fūrtim (adv.)"
  ],
  "at the beginning": [
    "prīmō"
  ],
  "whence": [
    "unde"
  ],
  "from what or which place": [
    "unde"
  ],
  "from which": [
    "unde"
  ],
  "from whom": [
    "unde"
  ],
  "to drink": [
    "bibō, bibere, bibī"
  ],
  "to become acquainted with": [
    "cognōscō, cognōscere, cognōvī, cognitum"
  ],
  "in perf. tenses": [
    "cognōscō, cognōscere, cognōvī, cognitum"
  ],
  "to grasp": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "sieze": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "arrest": [
    "comprehensiō, comprehensiōnis",
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "comprehend": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "to consume": [
    "cōnsūmō, cōnsūmere, cōnsūmpsī, cōnsūmptum"
  ],
  "use up": [
    "abūtor, abūtī, abūsus sum",
    "cōnsūmō, cōnsūmere, cōnsūmpsī, cōnsūmptum"
  ],
  "to doubt": [
    "dubitō, dubitāre, dubitāvī, dubitātum"
  ],
  "hesitate": [
    "tardō, tardāre, tardāvī, tardātum",
    "cunctō, cunctāre, cunctāvī, cunctātum",
    "dubitō, dubitāre, dubitāvī, dubitātum"
  ],
  "to set forth": [
    "expōnō, expōnere, exposuī, expositum"
  ],
  "to lessen": [
    "minuō, minuere, minuī, minūtum"
  ],
  "to ask": [
    "rogō, rogāre, rogāvī, rogātum"
  ],
  "an as": [
    "as, assis"
  ],
  "a small copper coin comparable to a penny": [
    "as, assis"
  ],
  "aid": [
    "ops, opis",
    "ops, opis (f.)",
    "auxilium, auxiliī",
    "adiūtōrium, adiūtōriī",
    "auxilium, auxiliī (n.)",
    "iuvō, iuvāre, iūvī, iūtum",
    "succurrō, succurrere, succurrī, succursum"
  ],
  "toe": [
    "digitus, digitī",
    "digitus, digitī (m.)"
  ],
  "elephant": [
    "elephantus, elephantī",
    "elephantus, elephantī (m and f)"
  ],
  "exile": [
    "exsilium, exsiliī",
    "exsilium, exsiliī (n.)"
  ],
  "banishment": [
    "exsilium, exsiliī",
    "exsilium, exsiliī (n.)"
  ],
  "jealousy": [
    "invidia, invidiae",
    "zēlotypia, zēlotypiae",
    "invidia, invidiae (f.)",
    "invidentia, invidentiae"
  ],
  "gossip": [
    "rūmor, rūmōris",
    "rūmor, rūmōris (m.)"
  ],
  "ordinary": [
    "mediocris, mediocre",
    "ōrdinārius, ōrdināria, ōrdinārium",
    "cottīdiānus, cottīdiāna, cottīdiānum"
  ],
  "moderate": [
    "mediocris, mediocre",
    "modicus, modica, modicum",
    "modestus, modesta, modestum",
    "moderatus, moderata, moderatum"
  ],
  "mediocre": [
    "mediocris, mediocre"
  ],
  "a single time": [
    "semel",
    "semel (adv.)"
  ],
  "once and for all": [
    "semel",
    "semel (adv.)"
  ],
  "simultaneously": [
    "semel",
    "semel (adv.)"
  ],
  "to grieve": [
    "doleō, dolēre, doluī, dolitūrum"
  ],
  "give pain": [
    "doleō, dolēre, doluī, dolitūrum"
  ],
  "to sleep": [
    "dormiō, dormīre, dormīvī, dormītum"
  ],
  "to bring": [
    "ferō, ferre, tulī, lātum"
  ],
  "tolerate": [
    "ferō, ferre, tulī, lātum",
    "tolerō, tolerāre, tolerāvī, tolerātum"
  ],
  "to bring to": [
    "adferō, adferre, attulī, allātum"
  ],
  "to bring together": [
    "cōnferō, cōnferre, contulī, collātum"
  ],
  "confer": [
    "cōnferō, cōnferre, contulī, collātum"
  ],
  "bestow": [
    "cōnferō, cōnferre, contulī, collātum",
    "perhibeō, perhibēre, perhibuī, perhibitum",
    "impertiō, impertīre, impertīvī, impertītum"
  ],
  "to take oneself": [
    "sē cōnferre"
  ],
  "to betake oneself": [
    "sē cōnferre"
  ],
  "to offer": [
    "offerō, offerre, obtulī, oblātum",
    "praebeō, praebēre, praebuī, praebitum"
  ],
  "to carry back": [
    "referō, referre, rettulī, relātum"
  ],
  "repeat": [
    "iterō, iterāre, iterāvī, iterātum",
    "referō, referre, rettulī, relātum",
    "repetō, repetere, repetīvī, repetītum",
    "dictitō, dictitāre, dictitāvī, dictitātum",
    "duplicō, duplicāre, duplicāvī, duplicātum",
    "ingeminō, ingemināre, ingemināvī, ingeminātum",
    "īnstaurō, īnstaurāre, īnstaurāvī, īnstaurātum"
  ],
  "to be envious": [
    "invideō, invidēre, invīdī, invīsum"
  ],
  "to look at with envy": [
    "invideō, invidēre, invīdī, invīsum"
  ],
  "be jealous of": [
    "invideō, invidēre, invīdī, invīsum"
  ],
  "to fall down": [
    "occidō, occidere, occidī, occāsum"
  ],
  "custody": [
    "custōdia, custōdiae",
    "custōdia, custōdiae (f.)"
  ],
  "pl. guards": [
    "custōdia, custōdiae",
    "custōdia, custōdiae (f.)"
  ],
  "poverty": [
    "egestās, egestātis",
    "paupertās, paupertātis",
    "paupertās, paupertātis (f.)"
  ],
  "humble circumstances": [
    "paupertās, paupertātis",
    "paupertās, paupertātis (f.)"
  ],
  "of small means": [
    "pauper, gen. pauperis"
  ],
  "so long as": [
    "dummodo,",
    "dummodo, (conj. + subjunct.)"
  ],
  "to want more": [
    "mālō, mālle, māluī"
  ],
  "instead": [
    "mālō, mālle, māluī"
  ],
  "not wish": [
    "nōlō, nōlle, nōluī"
  ],
  "to be open": [
    "pateō, patēre, patuī"
  ],
  "lie open": [
    "pateō, patēre, patuī"
  ],
  "be accessible": [
    "pateō, patēre, patuī"
  ],
  "be evident": [
    "pateō, patēre, patuī"
  ],
  "to send forth": [
    "prōmittō, prōmittere, prōmīsī, prōmissum"
  ],
  "to wish": [
    "volō, velle, voluī"
  ],
  "commencement": [
    "initium, initiī",
    "exordium, exordiī",
    "initium, initiī (n.)",
    "praescriptiō, praescriptiōnis"
  ],
  "populace": [
    "plēbs, plēbis",
    "plēbs, plēbis (f.)"
  ],
  "plebeians": [
    "plēbs, plēbis",
    "plēbs, plēbis (f.)"
  ],
  "shining": [
    "illūstris, illūstre",
    "nitidus, nitida, nitidum",
    "candidus, candida, candidum"
  ],
  "ah!": [
    "heu"
  ],
  "alas": [
    "heu",
    "vae",
    "ēheu",
    "heu (interj.)"
  ],
  "to refuse": [
    "recūsō, recūsāre, recūsāvī, recūsātum"
  ],
  "to give over": [
    "trādo, trādere, trādidī, trāditum",
    "trādo, trādere, trādidī, trāditum (trāns + dō)"
  ],
  "surrender": [
    "dēditiō, dēditiōnis",
    "trāditiō, trāditiōnis",
    "dēdō, dēdere, dēdidī, dēditum",
    "trādo, trādere, trādidī, trāditum",
    "trādo, trādere, trādidī, trāditum (trāns + dō)"
  ],
  "hand down": [
    "prōdō, prōdere, prōdidī, prōditum",
    "trādo, trādere, trādidī, trāditum",
    "trādo, trādere, trādidī, trāditum (trāns + dō)"
  ],
  "transmit": [
    "trādo, trādere, trādidī, trāditum",
    "trādo, trādere, trādidī, trāditum (trāns + dō)",
    "trānsmittō, trānsmittere, trānsmīsī, trānsmissum"
  ],
  "letting go": [
    "remissiō, remissiōnis",
    "remissiō, remissiōnis (f.)"
  ],
  "release": [
    "Libera",
    "remissiō, remissiōnis",
    "remissiō, remissiōnis (f.)",
    "exsolvō, exsolvere, exsolvī, exsolūtum",
    "resolvō, resolvere, resolvī, resolūtum"
  ],
  "relaxation": [
    "cessātiō, cessātiōnis",
    "laxāmentum, laxāmentī",
    "remissiō, remissiōnis",
    "remissiō, remissiōnis (f.)"
  ],
  "adverse": [
    "adversus, adversa, adversum"
  ],
  "of such a sort": [
    "tālis, tāle"
  ],
  "woe to": [
    "vae"
  ],
  "to judge": [
    "arbitror, arbitrārī, arbitrātus sum"
  ],
  "to try": [
    "cōnor, cōnārī, cōnātus sum",
    "experior, experīrī, expertus sum"
  ],
  "to increase": [
    "crēscō, crēscere, crēvī, crētum"
  ],
  "to go out": [
    "exeō, exīre, exiī, exitum",
    "ēgredior, ēgredī, ēgressus sum"
  ],
  "to confess": [
    "fateor, fatērī, fassus sum"
  ],
  "to encourage": [
    "hortor, hortārī, hortātus sum",
    "cohortō, cohortāre, cohortāvī, cohortātum"
  ],
  "to work at": [
    "mōlior, mōlīrī, mōlītus sum"
  ],
  "undertake": [
    "mōlior, mōlīrī, mōlītus sum",
    "īnstituō, īnstituere, īnstituī, īnstitūtum",
    "suscipiō, suscipere, suscēpī, susceptum (sub + capiō)"
  ],
  "to die": [
    "morior, morī, mortuus sum",
    "morior, morī, mortuus sum (fut. act. partic. moritūrus)"
  ],
  "to be born": [
    "nāscor, nāscī, nātus sum"
  ],
  "to suffer": [
    "patior, patī, passus sum"
  ],
  "to set out": [
    "proficīscor, proficīscī, profectus sum"
  ],
  "start": [
    "occipiō, occipere, occēpī, occeptum",
    "proficīscor, proficīscī, profectus sum",
    "īnstaurō, īnstaurāre, īnstaurāvī, īnstaurātum"
  ],
  "to live in the country": [
    "rūsticor, rūsticārī, rūsticātus sum"
  ],
  "to sit": [
    "sedeō, sedēre, sēdī, sessum"
  ],
  "to follow": [
    "sequor, sequī, secūtus sum",
    "īnsequor, īnsequī, īnsecūtus sum"
  ],
  "to look at": [
    "spectō, spectāre, spectāvī, spectātum"
  ],
  "to use": [
    "ūtor, ūtī, ūsus sum",
    "ūtor, ūtī, ūsus sum (+ abl.)"
  ],
  "to put before": [
    "antepōnō, antepōnere anteposuī, antepositum"
  ],
  "to comfort": [
    "foveō, fovēre, fōvī, fōtum"
  ],
  "nurture": [
    "foveō, fovēre, fōvī, fōtum"
  ],
  "to grant pardon to": [
    "ignōscō, ignōscere ignōvī, ignōtum + dat"
  ],
  "to give orders to": [
    "imperō, imperāre, imperāvī, imperātum + dat"
  ],
  "to marvel at": [
    "mīror, mīrārī, mīrātus sum"
  ],
  "to do harm to": [
    "noceō, nocēre, nocuī, nocitum + dat"
  ],
  "to cover": [
    "nūbō, nūbere, nūpsī, nūptum"
  ],
  "veil": [
    "nūbō, nūbere, nūpsī, nūptum"
  ],
  "+ dat to be married to": [
    "nūbō, nūbere, nūpsī, nūptum"
  ],
  "to be leniant to": [
    "parcō, parcere, pepercī, parsūrum + dat"
  ],
  "to be obedient to": [
    "pāreō pārēre, pāruī + dat"
  ],
  "to succeed in urging": [
    "persuādeō, persuādēre, persuāsī, persuāsum + dat"
  ],
  "to be pleasing to": [
    "placeō, placēre, placuī, placitum + dat"
  ],
  "to have good taste": [
    "sapiō, sapere, sapīvī"
  ],
  "have good sense": [
    "sapiō, sapere, sapīvī"
  ],
  "be wise": [
    "sapiō, sapere, sapīvī",
    "sapiō, sapere, sapīvī, ----"
  ],
  "to be a slave to": [
    "serviō, servīre, servīvī, servītum + dat"
  ],
  "to direct one's zeal to": [
    "studeō, studēre, studuī + dat"
  ],
  "be eager for": [
    "studeō, studēre, studuī + dat"
  ],
  "to smile upon": [
    "subrīdeō, subrīdēre, subrīsī, subrīsum"
  ],
  "reader": [
    "lēctor, lēctōris",
    "lēctor, lēctōris and lēctrīx, lēctrīcis",
    "lēctor, lēctōris (m.) and lēctrīx, lēctrīcis (f.)"
  ],
  "bond": [
    "vinculum, vinculī",
    "vinculum, vinculī (n.)",
    "obligātiō, obligātiōnis"
  ],
  "fetter": [
    "vinculum, vinculī",
    "vinculum, vinculī (n.)",
    "alligō, alligāre, alligāvī, alligātum"
  ],
  "daily": [
    "cōtīdiē",
    "cōtīdiē (adv.)",
    "cotīdiānus, cotīdiāna, cotīdiānum",
    "cottīdiānus, cottīdiāna, cottīdiānum"
  ],
  "to harvest": [
    "carpō, carpere, carpsī, carptum"
  ],
  "pluck": [
    "carpō, carpere, carpsī, carptum",
    "dēcerpō, dēcerpere, dēcerpsī, dēcerptum"
  ],
  "to drive or bring together": [
    "cōgō, cōgere, coēgī, coāctum",
    "cōgō, cōgere, coēgī, coāctum (cum + agō)"
  ],
  "to despise": [
    "contemnō, contemnere, contempsī, contemptum"
  ],
  "to beat": [
    "contundō, contundere, contudī, contūsum"
  ],
  "crush": [
    "occīdō, occīdere, occīdī, occīsum",
    "contundō, contundere, contudī, contūsum",
    "opprimō, opprimere, oppressī, oppressum"
  ],
  "bruise": [
    "līvor, līvōris",
    "cicātrīx, cicātrīcis",
    "obterō, obterere, obtrīvī, obtrītum",
    "contundō, contundere, contudī, contūsum"
  ],
  "to care for": [
    "cūrō, cūrāre, cūrāvī, cūrātum"
  ],
  "attend to": [
    "cūrō, cūrāre, cūrāvī, cūrātum"
  ],
  "to decide": [
    "dēcernō, dēcernere, dēcrēvī, dēcrētum"
  ],
  "settle": [
    "sēdō, sēdāre, sēdāvī, sēdātum",
    "sēdō, sēdāre, sēdāvī, sēdātus",
    "dēcernō, dēcernere, dēcrēvī, dēcrētum",
    "collocō, collocāre, collocāvī, collocātum"
  ],
  "decree": [
    "iussus, iussūs",
    "dēcrētum, dēcrētī",
    "sānctiō, sānctiōnis",
    "dēcernō, dēcernere, dēcrēvī, dēcrētum",
    "cōnscīscō, cōnscīscere, cōnscīvī, cōnscītum"
  ],
  "force out": [
    "exigō, exigere, exēgī, exāctum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "exact": [
    "exigō, exigere, exēgī, exāctum",
    "repetō, repetere, repetīvī, repetītum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "drive through": [
    "exigō, exigere, exēgī, exāctum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "perfect": [
    "exigō, exigere, exēgī, exāctum",
    "exigō, exigere, exēgī, exāctum (ex + agō)"
  ],
  "to occur": [
    "fīō, fierī, factus sum"
  ],
  "to please": [
    "oblectō, oblectāre, oblectāvī, oblectātum"
  ],
  "amuse": [
    "oblectō, oblectāre, oblectāvī, oblectātum"
  ],
  "pass time pleasantly": [
    "oblectō, oblectāre, oblectāvī, oblectātum"
  ],
  "to speak": [
    "for, fārī, fātus sum",
    "ōrō, ōrāre, ōrāvī, ōrātum"
  ],
  "plead": [
    "ōrō, ōrāre, ōrāvī, ōrātum",
    "causor, causārī, causātus sum"
  ],
  "entreat": [
    "ōrō, ōrāre, ōrāvī, ōrātum",
    "exposcō, exposcere, expoposcī",
    "implōrō, implōrāre, implōrāvī, implōrātum",
    "obsecrō, obsecrāre, obsecrāvī, obsecrātum"
  ],
  "to restore": [
    "recreō, recreāre, recreāvī, recreātum"
  ],
  "revive": [
    "recreō, recreāre, recreāvī, recreātum",
    "restituō, restituere, restituī, restitūtum"
  ],
  "refresh": [
    "novō, novāre, novāvī, novātum",
    "animō, animāre, animāvī, animātum",
    "recreō, recreāre, recreāvī, recreātum"
  ],
  "cheer": [
    "iūbilō, iūbilāre, iūbilāvī, iūbilātum",
    "recreō, recreāre, recreāvī, recreātum",
    "cōnsōlō, cōnsōlāre, cōnsōlāvī, cōnsōlātum"
  ],
  "need": [
    "aerumna, aerumnae",
    "pēnūria, pēnūriae",
    "egestās, egestātis",
    "paupertās, paupertātis",
    "indigentia, indigentiae",
    "necessitās, necessitātis",
    "indigeō, indigēre, indiguī",
    "egeō, egēre, eguī, egitūrus",
    "necessitūdō, necessitūdinis",
    "egeō, egēre, eguī (+ abl. or gen.)",
    "requīrō, requīrere, requīsīvī, requīsītum"
  ],
  "to make clear": [
    "serēnō, serēnāre, serēnāvī, serēnātum"
  ],
  "brighten": [
    "serēnō, serēnāre, serēnāvī, serēnātum",
    "illūminō, illūmināre, illūmināvī, illūminātum"
  ],
  "cheer up": [
    "serēnō, serēnāre, serēnāvī, serēnātum"
  ],
  "soothe": [
    "pācō, pācāre, pācāvī, pācātum",
    "lēniō, lēnīre, lēnīvī, lēnītum",
    "serēnō, serēnāre, serēnāvī, serēnātum"
  ],
  "athens": [
    "athēnae, athēnārum",
    "Athēnae, Athēnārum (f. pl.)"
  ],
  "route": [
    "iter, itineris"
  ],
  "countryside": [
    "rūs, rūris"
  ],
  "syracuse": [
    "syrācūsae, syrācūsārum",
    "Syrācūsae, Syrācūsārum (f. pl.)"
  ],
  "appropriate": [
    "idōneus, idōnea, idōneum",
    "accommodātus, accommodāta, accommodātum"
  ],
  "unmoved": [
    "immōtus, immōta, immōtum"
  ],
  "unchanged": [
    "immōtus, immōta, immōtum"
  ],
  "unrelenting": [
    "immōtus, immōta, immōtum"
  ],
  "to go to": [
    "adeō, adīre, adiī, aditum"
  ],
  "to go in": [
    "ineō, inīre, iniī, initum"
  ],
  "enter into": [
    "ineō, inīre, iniī, initum"
  ],
  "to go up against": [
    "obeō, obīre, obiī, obitum"
  ],
  "to pass away": [
    "pereō, perīre, periī, peritum"
  ],
  "be destroyed": [
    "pereō, perīre, periī, peritum"
  ],
  "to go back": [
    "redeō, redīre, rediī, reditum"
  ],
  "to kill": [
    "dēiciō, dēicere, dēiēcī, dēiectum",
    "interficiō, interficere, interfēcī, interfectum"
  ],
  "it is permitted": [
    "licet, licēre, licuit,",
    "licet, licēre, licuit, (impers., + dat. + inf.)"
  ],
  "to travel abroad": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "to rest": [
    "requiēscō, requiēscere, requiēvī, requiētum"
  ],
  "to be accustomed": [
    "soleō, solēre, solitus sum"
  ],
  "merit": [
    "dignitās, dignitātis",
    "dignitās, dignitātis (f.)"
  ],
  "prestige": [
    "dignitās, dignitātis",
    "māiestās, māiestātis",
    "dignitās, dignitātis (f.)"
  ],
  "dignity": [
    "dignitās, dignitātis",
    "māiestās, māiestātis",
    "dignitās, dignitātis (f.)"
  ],
  "a work": [
    "opus, operis",
    "labor, labōris",
    "labor, labōris (m)"
  ],
  "accomplishment": [
    "opus, operis",
    "effectus, effectūs"
  ],
  "lower leg": [
    "pēs, pedis"
  ],
  "sower": [
    "sator, satōris",
    "sator, satōris (m.)"
  ],
  "planter": [
    "Sator",
    "sator, satōris",
    "cultor, cultōris",
    "sator, satōris (m.)"
  ],
  "begetter": [
    "sator, satōris",
    "sator, satōris (m.)"
  ],
  "founder": [
    "sator, satōris",
    "auctor, auctōris",
    "creātor, creātōris",
    "sator, satōris (m.)",
    "condītor, condītōris"
  ],
  "not strong": [
    "īnfīrmus, īnfīrma, īnfīrmum"
  ],
  "feeble": [
    "impotēns, impotentis",
    "imbēcillis, imbēcille",
    "īnfirmus, īnfirma, īnfirmum",
    "īnfīrmus, īnfīrma, īnfīrmum",
    "imbēcillus, imbēcilla, imbēcillum"
  ],
  "amazing": [
    "mīrābilis, mīrābile",
    "mīrificus, mīrifica, mīrificum"
  ],
  "wondrous": [
    "mīrābilis, mīrābile"
  ],
  "remarkable": [
    "īnsignis, īnsigne",
    "mīrābilis, mīrābile",
    "notābilis, notābile"
  ],
  "previous": [
    "Anterior",
    "prīstinus, prīstina, prīstinum"
  ],
  "elevated": [
    "sublīmis, sublīme",
    "ēditus, ēdita, ēditum",
    "excelsus, excelsa, excelsum"
  ],
  "lofty": [
    "sublīmis, sublīme",
    "excelsus, excelsa, excelsum",
    "prōcērus, prōcēra, prōcērum"
  ],
  "heroic": [
    "sublīmis, sublīme",
    "hērōus, hērōa, hērōum",
    "hērōicus, hērōica, hērōicum"
  ],
  "with pleasure": [
    "libenter",
    "libenter (adv.)"
  ],
  "gladly": [
    "libenter",
    "libenter (adv.)",
    "grātē, grātius, grātissimē"
  ],
  "to impede": [
    "impediō, impedīre, impedīvī, impedītum"
  ],
  "be afraid for": [
    "metuō, metuere, metuī"
  ],
  "to complain": [
    "queror, querī, questus sum"
  ],
  "lament": [
    "conquestiō, conquestiōnis",
    "queror, querī, questus sum",
    "miseror, miserārī, miserātus sum",
    "dēfleō, dēflēre, dēflēvī, dēflētum"
  ],
  "to recognize": [
    "recognōscō, recognōscere, recognōvī, recognitum"
  ],
  "to hang up": [
    "suspendō, suspendere, suspendī, suspēnsum"
  ],
  "suspend": [
    "pendō, pendere, pependī, pēnsum",
    "suspendō, suspendere, suspendī, suspēnsum"
  ],
  "to sell": [
    "vēndō, vēndere, vēndidī, vēnditum"
  ],
  "structure": [
    "aedificium, aedificiī",
    "aedificium, aedificiī (n.)"
  ],
  "passing over": [
    "trānsitus, trānsitūs",
    "trānsitus, trānsitūs (m.)"
  ],
  "transit": [
    "trānsitus, trānsitūs",
    "trānsitiō, trānsitiōnis",
    "trānsitus, trānsitūs (m.)"
  ],
  "transition": [
    "trānsitus, trānsitūs",
    "trānsitus, trānsitūs (m.)"
  ],
  "fond": [
    "cupidus, cupida, cupidum"
  ],
  "+ gen": [
    "cupidus, cupida, cupidum"
  ],
  "desirous of": [
    "cupidus, cupida, cupidum"
  ],
  "eager for": [
    "cupidus, cupida, cupidum",
    "studeō, studēre, studuī + dat"
  ],
  "relating to a free person": [
    "līberālis, līberāle"
  ],
  "worthy of a free man": [
    "līberālis, līberāle"
  ],
  "decent": [
    "līberālis, līberāle"
  ],
  "liberal": [
    "līberālis, līberāle",
    "mūnificus, mūnifica, mūnificum"
  ],
  "inevitable": [
    "necesse",
    "necesse (indecl. adj. used as nom. or acc.)"
  ],
  "to walk": [
    "ambulō, ambulāre, ambulāvī, ambulātum"
  ],
  "to pour a libation of": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "pour ritually": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "sip": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "touch gently": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "it is proper": [
    "oportet, oportēre, oportuit,"
  ],
  "to fight against": [
    "oppugnō, oppugnāre, oppugnāvī, oppugnātum"
  ],
  "assault": [
    "Oppugna",
    "Oppugnum",
    "incursus, incursūs",
    "appetītus, appetītūs",
    "impressiō, impressiōnis",
    "oppugnātiō, oppugnātiōnis",
    "adorior, adorīrī, adortus sum",
    "incursō, incursāre, incursāvī, incursātum",
    "oppugnō, oppugnāre, oppugnāvī, oppugnātum",
    "incessō, incessere, incessīvī, incessuī, incessī, incessum"
  ],
  "assail": [
    "adorior, adorīrī, adortus sum",
    "īnstō, īnstāre, īnstitī, īnstātum",
    "impetō, impetere, impetīvī, impetītum",
    "oppugnō, oppugnāre, oppugnāvī, oppugnātum",
    "cōnflictō, cōnflictāre, cōnflictāvī, cōnflictātum"
  ],
  "to equip": [
    "ōrnō, ōrnāre, ōrnāvī, ōrnātum"
  ],
  "to spend or occupy the night": [
    "pernoctō, pernoctāre, pernoctāvī, pernoctātum"
  ],
  "to go accross": [
    "trānseō, trānsīre, trānsiī, trānsitum"
  ],
  "ignore": [
    "Ignosce",
    "trānseō, trānsīre, trānsiī, trānsitum"
  ],
  "advice": [
    "suāsiō, suāsiōnis",
    "cōnsilium, cōnsiliī",
    "cōnsilium, cōnsiliī (n)"
  ],
  "attention": [
    "cūra, cūrae",
    "cūra, cūrae (f)"
  ],
  "caution": [
    "cūra, cūrae",
    "cūra, cūrae (f)",
    "cautiō, cautiōnis"
  ],
  "destruction": [
    "Destructio",
    "exitium, exitiī",
    "excidium, excidiī",
    "exitium, exitiī (n)",
    "perditiō, perditiōnis",
    "dissolūtiō, dissolūtiōnis"
  ],
  "schoolmistress": [
    "magistra, magistrae",
    "magistra, magistrae (f)"
  ],
  "risk": [
    "perīculum, perīculī",
    "perīculum, perīculī (n)"
  ],
  "remedy": [
    "medeor, medērī",
    "remedium, remediī",
    "medicīna, medicīnae",
    "remedium, remediī (n)",
    "medicāmen, medicāminis",
    "medicāmentum, medicāmentī",
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum"
  ],
  "handsome": [
    "ēlegāns, ēlegantis",
    "bellus, bella, bellum",
    "pulcher, pulchra, pulchrum",
    "speciōsus, speciōsa, speciōsum"
  ],
  "pertaining to man": [
    "hūmānus, hūmāna, hūmānum"
  ],
  "humane": [
    "hūmānus, hūmāna, hūmānum"
  ],
  "refined": [
    "hūmānus, hūmāna, hūmānum"
  ],
  "cultivated": [
    "hūmānus, hūmāna, hūmānum"
  ],
  "a fool": [
    "stultus, stultī",
    "stultus, stultī (m)"
  ],
  "real": [
    "vērus, vēra, vērum"
  ],
  "to help": [
    "iuvō, iuvāre, iūvī, iūtum"
  ],
  "assist": [
    "iuvō, iuvāre, iūvī, iūtum",
    "subveniō, subvenīre, subvēnī, subventum",
    "administrō, administrāre, administrāvī, administrātum"
  ],
  "exist": [
    "sum, esse, fuī, futūrum",
    "exsistō, exsistere, exstitī"
  ],
  "bronze": [
    "aes, aeris",
    "aēnus, aēna, aēnum",
    "aēneus, aēnea, aēneum",
    "aereus, aerea, aereum",
    "aerārius, aerāria, aerārium"
  ],
  "lord": [
    "dominor, dominārī, dominātus sum",
    "dominus, dominī and domina, dominae",
    "dominus, dominī (m.) and domina, dominae (f.)"
  ],
  "lady": [
    "dominus, dominī and domina, dominae",
    "dominus, dominī (m.) and domina, dominae (f.)"
  ],
  "turning point": [
    "mēta, mētae",
    "mēta, mētae (f.)"
  ],
  "goal": [
    "mēta, mētae",
    "mēta, mētae (f.)"
  ],
  "monument": [
    "monumentum, monumentī",
    "monumentum, monumentī (n.)"
  ],
  "stone": [
    "Stone",
    "saxum, saxī",
    "petra, petrae",
    "lapis, lapidis",
    "silex, silicis",
    "saxum, saxī (n.)",
    "lapideus, lapidea, lapideum",
    "lapidō, lapidāre, lapidāvī, lapidātum"
  ],
  "as many": [
    "tot, tot ... quot",
    "tot (indecl. adj.), tot ... quot"
  ],
  "interrog. adv. which introduces questions expecting the answer \"yes\"": [
    "nōnne"
  ],
  "interrog. adv.: introduces questions which expect the answer \"no\"": [
    "num"
  ],
  "introduces indirect questions and means whether": [
    "num"
  ],
  "wholly": [
    "omnīnō"
  ],
  "entirely": [
    "fermē",
    "omnīnō"
  ],
  "altogether": [
    "omnīnō"
  ],
  "with negatives at all": [
    "omnīnō"
  ],
  "after all": [
    "postrēmum"
  ],
  "for the last time": [
    "postrēmum"
  ],
  "in fact": [
    "enim",
    "quīn",
    "rēapse",
    "rēvērā",
    "enim (postpositive conj)"
  ],
  "furthermore": [
    "quīn"
  ],
  "to unfold": [
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "spread out": [
    "dīlātō, dīlātāre, dīlātāvī, dīlātātum",
    "expandō, expandere, expandī, expānsum",
    "porrigō, porrigere, porrexī, porrectum",
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "deploy": [
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "to weary": [
    "fatīgō, fatīgāre, fatīgāvī, fatīgātum"
  ],
  "foretell": [
    "for, fārī, fātus sum",
    "augurō, augurāre, augurāvī, augurātum",
    "dīvīnō, dīvīnāre, dīvīnāvī, dīvīnātum",
    "vāticinor, vāticinārī, vāticinātus sum",
    "praedīcō, praedīcere, praedīxī, praedictum"
  ],
  "to suppose": [
    "opīnor, opīnārī, opīnātus sum"
  ],
  "to find": [
    "reperiō, reperīre, repperī, repertum"
  ],
  "discover": [
    "reperiō, reperīre, repperī, repertum",
    "comperiō, comperīre, comperī, compertum"
  ],
  "to show reverence for": [
    "vereor, verērī, veritus sum"
  ],
  "be afraid of": [
    "vereor, verērī, veritus sum"
  ],
  "youth": [
    "ephēbus, ephēbī",
    "iuventa, iuventae",
    "pueritia, pueritiae",
    "iuventūs, iuventūtis",
    "adulēscentia, adulēscentiae",
    "adulēscentia, adulēscentiae (f)"
  ],
  "young manhood": [
    "adulēscentia, adulēscentiae",
    "adulēscentia, adulēscentiae (f)"
  ],
  "youthfulness": [
    "adulēscentia, adulēscentiae",
    "adulēscentia, adulēscentiae (f)"
  ],
  "high spirits": [
    "animī, animōrum",
    "animī, animōrum (m)"
  ],
  "pride": [
    "animī, animōrum",
    "animī, animōrum (m)",
    "superbia, superbiae"
  ],
  "fault": [
    "culpa, culpae",
    "vitium, vitiī",
    "culpa, culpae (f)",
    "dēlictum, dēlictī",
    "peccātum, peccātī",
    "vitium, vitiī (n)"
  ],
  "sane": [
    "sānus, sāna, sānum"
  ],
  "consequently": [
    "igitur"
  ],
  "interrogative suffix attached to the first word of a sentence": [
    "-ne"
  ],
  "typically the verb or another word on which the question hinges": [
    "-ne"
  ],
  "to introduce a question whose answer is uncertain": [
    "-ne"
  ],
  "if ever": [
    "sī quandō"
  ],
  "sufficient": [
    "satis"
  ],
  "at that time": [
    "tum"
  ],
  "thereupon": [
    "tum",
    "subinde"
  ],
  "in the next place": [
    "tum"
  ],
  "to dine": [
    "cēnō, cēnāre, cēnāvī, cēnātum"
  ],
  "to blame": [
    "culpō, culpāre, culpāvī, culpātum"
  ],
  "to remain": [
    "maneō, manēre, mānsī, mānsum",
    "restō, restāre, restitī, restātum",
    "remaneō, remanēre, remānsī, remānsum"
  ],
  "abide": [
    "maneō, manēre, mānsī, mānsum",
    "obsideō, obsidēre, obsēdī, obsessum",
    "resideō, residēre, resēdī, resessum",
    "remaneō, remanēre, remānsī, remānsum"
  ],
  "continue": [
    "Perge",
    "maneō, manēre, mānsī, mānsum",
    "remaneō, remanēre, remānsī, remānsum"
  ],
  "to be above": [
    "superō, superāre, superāvī, superātum"
  ],
  "have the upper hand": [
    "superō, superāre, superāvī, superātum"
  ],
  "overcome conquer": [
    "superō, superāre, superāvī, superātum"
  ],
  "learner": [
    "discipula, discipulae",
    "discipulus, discipulī",
    "discipula, discipulae (f)",
    "discipulus, discipulī (m)"
  ],
  "student": [
    "Student",
    "discipula, discipulae",
    "discipulus, discipulī",
    "discipula, discipulae (f)",
    "discipulus, discipulī (m)"
  ],
  "ambush": [
    "īnsidiae, īnsidiārum",
    "īnsidiae, īnsidiārum (f)",
    "īnsidior, īnsidiārī, īnsidiātus sum"
  ],
  "plot": [
    "īnsidiae, īnsidiārum",
    "īnsidiae, īnsidiārum (f)",
    "īnsidior, īnsidiārī, īnsidiātus sum"
  ],
  "treachery": [
    "perfidia, perfidiae",
    "īnsidiae, īnsidiārum",
    "īnsidiae, īnsidiārum (f)"
  ],
  "absolute ruler": [
    "tyrannus, tyrannī",
    "tyrannus, tyrannī (m)"
  ],
  "vice": [
    "vitium, vitiī",
    "vitium, vitiī (n)"
  ],
  "a greek": [
    "graecus, graecī",
    "Graecus, Graecī (m)"
  ],
  "perpetual": [
    "perpetuus, perpetua, perpetuum",
    "sempiternus, sempiterna, sempiternum"
  ],
  "lasting": [
    "vetustus, vetusta, vetustum",
    "perpetuus, perpetua, perpetuum"
  ],
  "uninterrupted": [
    "continuus, continua, continuum",
    "perpetuus, perpetua, perpetuum"
  ],
  "abundant": [
    "plēnus, plēna, plēnum",
    "cōpiōsus, cōpiōsa, cōpiōsum",
    "numerōsus, numerōsa, numerōsum"
  ],
  "favourable": [
    "secundus, secunda, secundum"
  ],
  "at present": [
    "nunc"
  ],
  "because of which thing": [
    "quārē"
  ],
  "wherefore": [
    "quārē",
    "quōcircā",
    "quapropter"
  ],
  "to be able": [
    "possum, posse, potuī"
  ],
  "can": [
    "possum, posse, potuī"
  ],
  "could": [
    "possum, posse, potuī"
  ],
  "to bear": [
    "tolerō, tolerāre, tolerāvī, tolerātum"
  ],
  "citizenship": [
    "cīvitās, cīvitātis",
    "cīvitās, cīvitātis (f)"
  ],
  "labour": [
    "labor, labōris",
    "labor, labōris (m)"
  ],
  "production": [
    "labor, labōris",
    "labor, labōris (m)"
  ],
  "a letter of the alphabet": [
    "littera, litterae",
    "littera, litterae (f)"
  ],
  "a letter": [
    "epistola, epistolae",
    "litterae, litterārum",
    "litterae, litterārum (f)"
  ],
  "literature": [
    "litterae, litterārum",
    "litterae, litterārum (f)"
  ],
  "habit": [
    "mōs, mōris",
    "habitus, habitūs",
    "habitūdō, habitūdinis",
    "cōnsuētūdō, cōnsuētūdinis"
  ],
  "habits": [
    "mōrēs, mōrum",
    "mōrēs, mōrum (m)"
  ],
  "morals": [
    "mōrēs, mōrum",
    "mōrēs, mōrum (m)"
  ],
  "queen": [
    "rēgīna, rēgīnae",
    "rēgīna, rēgīnae (f)"
  ],
  "land": [
    "terra, terrae",
    "terra, terrae (f)"
  ],
  "virgin": [
    "virgō, virginis",
    "virgō, virginis (f)",
    "virgineus, virginea, virgineum"
  ],
  "manliness": [
    "virtūs, virtūtis"
  ],
  "excellence": [
    "virtūs, virtūtis",
    "bonitās, bonitātis",
    "excellentia, excellentiae",
    "praestantia, praestantiae"
  ],
  "worth": [
    "virtūs, virtūtis",
    "dīgnitās dīgnitātis"
  ],
  "virtue": [
    "virtūs, virtūtis",
    "pudīcitia, pudīcitiae"
  ],
  "up under": [
    "sub",
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "close to": [
    "sub",
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "down to": [
    "sub",
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "to the foot": [
    "sub"
  ],
  "at the foot": [
    "sub"
  ],
  "to dare": [
    "audeō, audēre, ausus sum"
  ],
  "to murder": [
    "necō, necāre, necāvī, necātum"
  ],
  "cicero": [
    "Cicero",
    "cicerō, cicerōnis",
    "Cicerō, Cicerōnis (m)"
  ],
  "supplies": [
    "cōpiae, cōpiārum",
    "commeātus, commeātūs",
    "cōpiae, cōpiārum (f)"
  ],
  "troops": [
    "cōpiae, cōpiārum",
    "cōpiae, cōpiārum (f)"
  ],
  "forces": [
    "cōpiae, cōpiārum",
    "cōpiae, cōpiārum (f)"
  ],
  "reckoning": [
    "ratiō, ratiōnis"
  ],
  "account": [
    "ratiō, ratiōnis"
  ],
  "consideration": [
    "ratiō, ratiōnis",
    "cōnsultātiō, cōnsultātiōnis",
    "dēlīberātiō, dēlīberātiōnis"
  ],
  "system": [
    "ratiō, ratiōnis"
  ],
  "writer": [
    "scrība, scrībae",
    "scrīptor, scrīptōris",
    "scrīptor, scrīptōris (m)"
  ],
  "at the same time that": [
    "dum"
  ],
  "until": [
    "dum",
    "quoad",
    "Antequam"
  ],
  "up to": [
    "ad",
    "tenus",
    "ad (+ acc)"
  ],
  "near to": [
    "ad",
    "ad (+ acc)"
  ],
  "from within": [
    "ex, ē",
    "ex, ē (+ abl)"
  ],
  "by reason of": [
    "ex, ē",
    "ex, ē (+ abl)"
  ],
  "to drive": [
    "agō, agere, ēgī, āctum"
  ],
  "to thank someone": [
    "grātiās agere",
    "grātiās agere (+dat)"
  ],
  "to give thanks to": [
    "grātiās agere",
    "grātiās agere (+dat)"
  ],
  "to point out": [
    "dēmōnstrō, dēmōnstrāre, dēmōnstrāvī, dēmōnstrātum"
  ],
  "demonstrate": [
    "dēmōnstrō, dēmōnstrāre, dēmōnstrāvī, dēmōnstrātum"
  ],
  "to learn": [
    "discō, discere, didicī"
  ],
  "to teach": [
    "doceō, docēre, docuī, doctum"
  ],
  "to lead": [
    "dūcō, dūcere, dūxī, ductum"
  ],
  "prolong": [
    "dūcō, dūcere, dūxī, ductum",
    "prōrogō, prōrogāre, prōrogāvī, prōrogātum"
  ],
  "to carry": [
    "gerō, gerere, gessī, gestum"
  ],
  "conduct": [
    "gerō, gerere, gessī, gestum",
    "admoveō, admovēre, admōvī, admōtum",
    "obdūcō, obdūcere, obdūxī, obductum",
    "perdūcō, perdūcere, perdūxī, perductum"
  ],
  "to write": [
    "scrībō, scrībere, scrīpsī, scrīptum"
  ],
  "compose": [
    "struō, struere, struxī, structum",
    "scrībō, scrībere, scrīpsī, scrīptum",
    "cōnscrībō, cōnscrībere, cōnscrīpsī, cōnscrīptum"
  ],
  "to draw": [
    "trahō, trahere, trāxī, tractum"
  ],
  "derive": [
    "trahō, trahere, trāxī, tractum"
  ],
  "acquire": [
    "lucror, lucrārī, lucrātus sum",
    "trahō, trahere, trāxī, tractum",
    "praedor, praedārī, praedātus sum"
  ],
  "to conquer": [
    "vincō, vincere, vīcī, victum"
  ],
  "passage in literature": [
    "locus, locī",
    "locus, locī (m)"
  ],
  "places": [
    "loca, locōrum",
    "loca, locōrum (n)"
  ],
  "passages in literature": [
    "locī, locōrum",
    "locī, locōrum (m)"
  ],
  "disease": [
    "Morbus",
    "morbus, morbī",
    "pestis, pestis",
    "morbus, morbī (m)",
    "aegrōtātiō, aegrōtātiōnis"
  ],
  "sickness": [
    "morbus, morbī",
    "morbus, morbī (m)",
    "aegritūdō, aegritūdinis",
    "aegrōtātiō, aegrōtātiōnis"
  ],
  "eagerness": [
    "studium, studiī",
    "cupīdō, cupīdinis",
    "studium, studiī (n)"
  ],
  "zeal": [
    "zēlus, zēlī",
    "studium, studiī",
    "studium, studiī (n)",
    "sēdulitās, sēdulitātis"
  ],
  "pursuit": [
    "Persecutio",
    "studium, studiī",
    "studium, studiī (n)",
    "persecūtiō, persecūtiōnis"
  ],
  "the latter": [
    "hic, haec, hoc"
  ],
  "the former": [
    "ille, illa, illud"
  ],
  "the famous": [
    "ille, illa, illud"
  ],
  "they": [
    "ille, illa, illud"
  ],
  "that of yours": [
    "iste, ista, istud"
  ],
  "sometimes with contemptuous force": [
    "iste, ista, istud"
  ],
  "e.g.": [
    "iste, ista, istud"
  ],
  "that dispicable": [
    "iste, ista, istud"
  ],
  "that wretched": [
    "iste, ista, istud"
  ],
  "others": [
    "aliī . . . aliī"
  ],
  "not any": [
    "nūllus, nūlla, nūllum"
  ],
  "none": [
    "Nihil",
    "Nullum",
    "nūllus, nūlla, nūllum"
  ],
  "the only": [
    "sōlus, sōla, sōlum"
  ],
  "not only . . . but also": [
    "nōn sōlum . . . sed etiam"
  ],
  "single": [
    "ūnus, ūna, ūnum",
    "caelebs, caelibis",
    "simplex, simplicis",
    "singulāris, singulāre",
    "ūnicus, ūnica, ūnicum",
    "singulus, singula, singulum"
  ],
  "truly": [
    "enim",
    "vērē",
    "nempe",
    "vērāciter",
    "enim (postpositive conj)"
  ],
  "excessively": [
    "nimis or nimium",
    "nimis or nimium (adv.)"
  ],
  "in a positive sense": [
    "nimis or nimium",
    "nimis or nimium (adv.)"
  ],
  "esp. with adjectives and adverbs": [
    "nimis or nimium",
    "nimis or nimium (adv.)"
  ],
  "exceedingly": [
    "perquam",
    "nimis or nimium",
    "nimis or nimium (adv.)",
    "magnoperē, magis, maximē"
  ],
  "spin": [
    "neō, nēre, nēvī, nētum"
  ],
  "weave": [
    "neō, nēre, nēvī, nētum",
    "plectō, plectere, plexī, plexum",
    "contexō, contexere, contexuī, contextum"
  ],
  "arrage": [
    "locō, locāre, locāvī, locātum"
  ],
  "defense": [
    "Praesidium",
    "praesidium, praesidiī",
    "dēfensa, dēfensae), first declension (late latin",
    "dēfensa, dēfensae); first declension (Late Latin"
  ],
  "strain": [
    "intentiō, intentiōnis",
    "intendō, intendere, intendī, intentum",
    "contendō, contendere, contendī, contentum"
  ],
  "consult": [
    "cōnsilior, cōnsiliārī, cōnsiliātus sum",
    "scīscitor, scīscitārī, scīscitātus sum",
    "cōnsulō, cōnsulere, cōnsuluī, cōnsultum"
  ],
  "take counsel": [
    "cōnsilior, cōnsiliārī, cōnsiliātus sum"
  ],
  "go around as hawker": [
    "circitō, circitāre, circitāvi, circitātum"
  ],
  "occupy": [
    "obtineō, obtinēre, obtinuī, obtentum",
    "occupō, occupāre, occupāvī, occupātum",
    "percipiō, percipere, percēpī, perceptum"
  ],
  "take up": [
    "adsūmō, adsūmere, adsūmpsī, adsūmptum",
    "occupō, occupāre, occupāvī, occupātum",
    "suscipiō, suscipere, suscēpī, susceptum"
  ],
  "tower": [
    "Turris",
    "turris, turris"
  ],
  "frighten or terrify": [
    "territō, territāre, territāvī, territātum",
    "perterreō, perterrēre, perterruī, perterritum"
  ],
  "the senate or parliament": [
    "senātus, senātūs"
  ],
  "impediment": [
    "impedīmentum, impedīmentī"
  ],
  "hindrance": [
    "impedīmentum, impedīmentī",
    "offendiculum, offendiculī"
  ],
  "battle or attack": [
    "dīmicō, dīmicāre, dīmicāvī, dīmicātum"
  ],
  "reinforcement": [
    "subsidium, subsidiī"
  ],
  "reserve": [
    "subsidium, subsidiī",
    "subsidiārius, subsidiāriī",
    "reservō, reservāre, reservāvī, reservātum"
  ],
  "ability": [
    "Abilitas",
    "facultās, facultātis",
    "habilitās, habilitātis"
  ],
  "skill opportunity": [
    "facultās, facultātis"
  ],
  "chance": [
    "fors",
    "fōrs, fōrtis",
    "facultās, facultātis",
    "temeritās, temeritātis",
    "opportūnitās, opportūnitātis"
  ],
  "centurion": [
    "centuriō, centuriāre, centuriāvī, centuriātum"
  ],
  "set in order": [
    "collocō, collocāre, collocāvī, collocātum"
  ],
  "assign": [
    "cōniciō, cōnicere, cōniēcī, coniectum",
    "dēlēgō, dēlēgāre, dēlēgāvī, dēlēgātum",
    "adsignō, adsignāre, adsignāvī, adsignātum",
    "collocō, collocāre, collocāvī, collocātum",
    "discrībō, discrībere, discrīpsi, discrīptum"
  ],
  "castle": [
    "arx, arcis",
    "castrum, castrī",
    "castellum, castellī"
  ],
  "fort": [
    "castrum, castrī",
    "castellum, castellī"
  ],
  "fortify with a rampart": [
    "vallō, vallāre, vallāvī, vallātum"
  ],
  "dispose": [
    "dispōnō, dispōnere, disposuī, dispositum"
  ],
  "distribute or arrange": [
    "dispōnō, dispōnere, disposuī, dispositum"
  ],
  "make firm": [
    "firmō, firmāre, firmāvī, firmātum",
    "stabiliō, stabilīre, stabilīvī, stabilītum",
    "cōnfirmō, cōnfirmāre, cōnfirmāvī, cōnfirmātum"
  ],
  "dig": [
    "fodiō, fodere, fōdī, fossum",
    "cōnfodiō, cōnfodere, cōnfōdī, cōnfossum"
  ],
  "bury": [
    "fodiō, fodere, fōdī, fossum",
    "humō, humāre, humāvī, humātum",
    "sepeliō, sepelīre, sepelīvī, sepultum"
  ],
  "go for a walk": [
    "spatior, spatiārī, spatiātus sum"
  ],
  "set on fire": [
    "flammō, flammāre, flammāvī, flammātum",
    "incendō, incendere, incendī, incēnsum"
  ],
  "kindle": [
    "adūrō, adūrere, adussī, adustum",
    "ārdēscō, ārdēscere, ārsī, ārsum",
    "cōnflō, cōnflāre, cōnflāvī, cōnflātum",
    "incendō, incendere, incendī, incēnsum"
  ],
  "provisions goods convoy": [
    "commeātus, commeātūs"
  ],
  "caravan furlough": [
    "commeātus, commeātūs"
  ],
  "leave of absence": [
    "commeātus, commeātūs"
  ],
  "meet with": [
    "nancīscor, nancīscī, nactus sum"
  ],
  "stumble on": [
    "nancīscor, nancīscī, nactus sum"
  ],
  "encounter something": [
    "nancīscor, nancīscī, nactus sum"
  ],
  "bordering upon": [
    "fīnitimus, fīnitima, fīnitimum"
  ],
  "adjoining": [
    "confīnis, confīne",
    "fīnitimus, fīnitima, fīnitimum",
    "coniunctus, coniuncta, coniunctum"
  ],
  "adjacent": [
    "appositus, apposita, appositum",
    "fīnitimus, fīnitima, fīnitimum"
  ],
  "bring forward": [
    "expediō, expedīre, expedīvī, expedītum"
  ],
  "dispatch": [
    "festīnātiō, festīnātiōnis",
    "lēgō, lēgāre, lēgāvī, lēgātum",
    "dēlēgō, dēlēgāre, dēlēgāvī, dēlēgātum",
    "expediō, expedīre, expedīvī, expedītum"
  ],
  "expedite": [
    "expediō, expedīre, expedīvī, expedītum"
  ],
  "run with others": [
    "concurrō, concurrere, concurrī, concursum"
  ],
  "summit": [
    "arx, arcis",
    "apex, apicis",
    "summa, summae",
    "summum, summī",
    "cacūmen, cacūminis",
    "fastīgium, fastīgiī",
    "summitās, summitātis"
  ],
  "the main thing": [
    "summa, summae"
  ],
  "from all sides": [
    "undique"
  ],
  "take charge of": [
    "administrō, administrāre, administrāvī, administrātum"
  ],
  "travel or march around": [
    "circumeō, circumīre, circumiī, circumitum"
  ],
  "circulate skirt surround": [
    "circumeō, circumīre, circumiī, circumitum"
  ],
  "encircle": [
    "amplexor, amplexārī, amplexātus sum",
    "redimiō, redimīre, redimīvī, redimitum",
    "circumeō, circumīre, circumiī, circumitum"
  ],
  "enclose or encompass": [
    "circumeō, circumīre, circumiī, circumitum"
  ],
  "crowded with": [
    "crēber, crēbra, crēbrum"
  ],
  "quickness": [
    "celeritās, celeritātis",
    "mōbilitas, mōbilitātis",
    "mōbilitās, mōbilitātis"
  ],
  "swiftness": [
    "celeritās, celeritātis",
    "vēlōcitās, vēlōcitātis"
  ],
  "haste": [
    "celeritās, celeritātis",
    "festīnātiō, festīnātiōnis"
  ],
  "clothe. protect": [
    "tegō, tegere, tēxī, tēctum"
  ],
  "townspeople": [
    "oppidānus, oppidāna, oppidānum"
  ],
  "townsfolk": [
    "oppidānus, oppidāna, oppidānum"
  ],
  "damage": [
    "Vulnus",
    "damnum, damnī",
    "calamitās, calamitātis",
    "dētrīmentum, dētrīmentī",
    "vitiō, vitiāre, vitiāvī, vitiātum"
  ],
  "lead or bring forth": [
    "prōdūcō, prōdūcere, prōdūxī, prōductum"
  ],
  "forward or out": [
    "prōdūcō, prōdūcere, prōdūxī, prōductum"
  ],
  "attack siege": [
    "oppugnātiō, oppugnātiōnis"
  ],
  "send forward or ahead": [
    "praemittō, praemittere, praemīsī, praemissum"
  ],
  "royal power": [
    "rēgnum, rēgnī"
  ],
  "kingdom": [
    "rēgnum, rēgnī"
  ],
  "belong": [
    "pertineō, pertinēre, pertinuī"
  ],
  "set in rapid motion": [
    "incitō, incitāre, incitāvī, incitātum"
  ],
  "accelerate": [
    "incitō, incitāre, incitāvī, incitātum",
    "accelerō, accelerāre, accelerāvī, accelerātum"
  ],
  "carry down or away": [
    "dēferō, dēferre, dētulī, dēlātum"
  ],
  "tame": [
    "domō, domāre, domuī, domitum",
    "mānsuētus, mansuēta, mānsuētum",
    "domitō, domitāre, domitāvī, domitātum"
  ],
  "conque": [
    "domō, domāre, domuī, domitum"
  ],
  "wintry": [
    "hībernus, hīberna, hībernum"
  ],
  "of or pertaining to winter": [
    "hībernus, hīberna, hībernum"
  ],
  "suitable for getting through the winter": [
    "hībernus, hīberna, hībernum"
  ],
  "height": [
    "suggestus, suggestūs",
    "summitās, summitātis",
    "altitūdō, altitūdinis",
    "celsitūdō, celsitūdinis",
    "sublīmitas, sublīmitātis"
  ],
  "commision": [
    "mandō, mandāre, mandāvī, mandātum"
  ],
  "sew together": [
    "cōnsuō, cōnsuere, cōnsuī, cōnsūtum"
  ],
  "sew up": [
    "cōnsuō, cōnsuere, cōnsuī, cōnsūtum"
  ],
  "stitch": [
    "cōnsuō, cōnsuere, cōnsuī, cōnsūtum"
  ],
  "join. patch up. devise": [
    "cōnsuō, cōnsuere, cōnsuī, cōnsūtum"
  ],
  "follow close after or immediately": [
    "subsequor, subsequī, subsecūtus sum"
  ],
  "ensue": [
    "subsequor, subsequī, subsecūtus sum"
  ],
  "lure or entice": [
    "ēvocō, ēvocāre, ēvocāvī, ēvocātum"
  ],
  "summon or evoke": [
    "ēvocō, ēvocāre, ēvocāvī, ēvocātum"
  ],
  "shut out or off": [
    "interclūdō, interclūdere, interclūsī, interclūsum"
  ],
  "stop or block": [
    "interclūdō, interclūdere, interclūsī, interclūsum"
  ],
  "blockade": [
    "obsidiō, obsidiōnis",
    "obsessiō, obsessiōnis",
    "conclūsiō, conclūsiōnis",
    "interclūdō, interclūdere, interclūsī, interclūsum"
  ],
  "mane": [
    "iuba, iubae"
  ],
  "hair of the head": [
    "iuba, iubae",
    "crīnis, crīnis"
  ],
  "an engine for hurling missiles": [
    "tormentum, tormentī"
  ],
  "capitulation": [
    "dēditiō, dēditiōnis"
  ],
  "inconvenient": [
    "incommodus, incommoda, incommodum",
    "intempestīvus, intempestīva, intempestīvum"
  ],
  "unsuitable": [
    "ineptus, inepta, ineptum",
    "inconveniēns, inconvenientis",
    "incommodus, incommoda, incommodum"
  ],
  "unfit": [
    "incommodus, incommoda, incommodum"
  ],
  "unseasonable": [
    "incommodus, incommoda, incommodum"
  ],
  "disagreeable": [
    "ingrātus, ingrāta, ingrātum",
    "incommodus, incommoda, incommodum"
  ],
  "run away or escape": [
    "prōfugiō, prōfugere, prōfūgī"
  ],
  "rise above": [
    "excēdō, excēdere, excessī, excessum"
  ],
  "go beyond": [
    "excēdō, excēdere, excessī, excessum"
  ],
  "swamp": [
    "palūs, palūdis",
    "stagnum, stagnī"
  ],
  "marsh": [
    "palūs, palūdis"
  ],
  "busy": [
    "nāvus, nāva, nāvum"
  ],
  "difficulty": [
    "Difficultas",
    "difficultās, difficultātis"
  ],
  "distress": [
    "trībulātiō, trībulātiōnis",
    "difficultās, difficultātis"
  ],
  "a captive": [
    "captīvus, captīvī"
  ],
  "a prisoner": [
    "captīvus, captīvī"
  ],
  "make happy": [
    "fortūnō, fortūnāre, fortūnāvī, fortūnātum"
  ],
  "prosper": [
    "fortūnō, fortūnāre, fortūnāvī, fortūnātum"
  ],
  "bless": [
    "fortūnō, fortūnāre, fortūnāvī, fortūnātum"
  ],
  "make over": [
    "reficiō, reficere, refēcī, refectum"
  ],
  "make anew": [
    "reficiō, reficere, refēcī, refectum"
  ],
  "rebuild": [
    "reficiō, reficere, refēcī, refectum",
    "restituō, restituere, restituī, restitūtum",
    "resuscitō, resuscitāre, resuscitāvī, resuscitātum"
  ],
  "recruit": [
    "tīrō, tīrōnis",
    "reficiō, reficere, refēcī, refectum"
  ],
  "reinforce": [
    "reficiō, reficere, refēcī, refectum",
    "corrōborō, corrōborāre, corrōborāvī, corrōborātum"
  ],
  "hasty": [
    "festīnus, festīna, festīnum",
    "properus, propera, properum",
    "praeruptus, praerupta, praeruptum",
    "repentīnus, repentīna, repentīnum"
  ],
  "bereave": [
    "prīvō, prīvāre, prīvāvī, prīvātum"
  ],
  "deprive": [
    "prīvō, prīvāre, prīvāvī, prīvātum",
    "viduō, viduāre, viduāvī, viduātum"
  ],
  "knight": [
    "equester, equestris, equestre"
  ],
  "drive back": [
    "redigō, redigere, redēgī, redactum",
    "repellō, repellere, reppulī, repulsum",
    "retundō, retundere, rettudī, retunsum, retūsum"
  ],
  "push back": [
    "repellō, repellere, reppulī, repulsum"
  ],
  "reject": [
    "abnuō, abnuere, abnuī, abnuitum",
    "abdīcō, abdīcere, abdīxī, abdictum",
    "abdicō, abdicāre, abdicāvī, abdicātum",
    "repellō, repellere, reppulī, repulsum",
    "repudiō, repudiāre, repudiāvī, repudiātum",
    "renuntiō, renuntiāre, renuntiāvī, renuntiātum"
  ],
  "repulse": [
    "repellō, repellere, reppulī, repulsum"
  ],
  "burden": [
    "Onus",
    "onus, oneris",
    "sarcina, sarcinae",
    "onerārius, onerāria, onerārium",
    "gravō, gravāre, gravāvī, gravātum",
    "onerō, onerāre, onerāvī, onerātum"
  ],
  "treat": [
    "afficiō, afficere, affēcī, affectum"
  ],
  "veteran": [
    "emeritus",
    "veterānus, veterāna, veterānum"
  ],
  "drive or thrust out or away": [
    "expellō, expellere, expulī, expulsum",
    "exturbō, exturbāre, exturbāvī, exturbātum"
  ],
  "eject": [
    "expellō, expellere, expulī, expulsum"
  ],
  "draft animal": [
    "armentum, armentī",
    "iūmentum, iūmentī"
  ],
  "pass the winter. keep to a winter encampment": [
    "hībernō, hībernāre, hībernāvī, hībernātum"
  ],
  "see through something. perceive or discern clearly": [
    "perspiciō, perspicere, perspexī, perspectum"
  ],
  "place or lay between or among insert": [
    "interpōnō, interpōnere, interposuī, interpositum"
  ],
  "interpose or introduce pledge": [
    "interpōnō, interpōnere, interposuī, interpositum"
  ],
  "flee or desert take refuge": [
    "perfugiō, perfugere, perfūgī"
  ],
  "nothingness": [
    "nihilum, nihilī",
    "vānitās, vānitātis"
  ],
  "a quarrel": [
    "iūrgium, iūrgiī",
    "contrōversia, contrōversiae"
  ],
  "dispute": [
    "rixa, rixae",
    "iūrgium, iūrgiī",
    "altercātiō, altercātiōnis",
    "concertātiō, concertātiōnis",
    "contrōversia, contrōversiae",
    "disceptātiō, disceptātiōnis",
    "causor, causārī, causātus sum",
    "lītigō, lītigāre, lītigāvī, lītigātum",
    "disceptō, disceptāre, disceptāvī, disceptātum"
  ],
  "debate lawsuit contradiction": [
    "contrōversia, contrōversiae"
  ],
  "memorandum": [
    "commentārius, commentāriī"
  ],
  "notebook": [
    "cōdex, cōdicis",
    "commentārius, commentāriī"
  ],
  "a brief": [
    "commentārius, commentāriī"
  ],
  "obtain or acquire": [
    "potior, potīrī, potītus sum"
  ],
  "survive": [
    "permaneō, permanēre, permansī, permansum"
  ],
  "ambush artifice": [
    "īnsidiae, īnsidiārum"
  ],
  "snare": [
    "īnsidiae, īnsidiārum"
  ],
  "associate": [
    "sociō, sociāre, sociāvī, sociātum",
    "attribuō, attribuere, attribuī, attribūtum"
  ],
  "add or join to": [
    "attribuō, attribuere, attribuī, attribūtum"
  ],
  "succeed obtain": [
    "impetrō, impetrāre, impetrāvī, impetrātum"
  ],
  "procure": [
    "impetrō, impetrāre, impetrāvī, impetrātum"
  ],
  "bent outwards": [
    "vārus, vāra, vārum"
  ],
  "pass the winter. am wintry": [
    "hiemō, hiemāre, hiemāvī, hiemātum"
  ],
  "frozen. freeze": [
    "hiemō, hiemāre, hiemāvī, hiemātum"
  ],
  "turn to ice": [
    "hiemō, hiemāre, hiemāvī, hiemātum"
  ],
  "a troop": [
    "turma, turmae"
  ],
  "squadron of cavalry": [
    "turma, turmae"
  ],
  "team": [
    "turma, turmae"
  ],
  "mount or ascend": [
    "succēdō, succēdere, successī, successum"
  ],
  "archer": [
    "sagittārius, sagittāria, sagittārium"
  ],
  "bowman fletcher": [
    "sagittārius, sagittāria, sagittārium"
  ],
  "arrow-maker": [
    "sagittārius, sagittāria, sagittārium"
  ],
  "a scout a spy": [
    "explōrātor, explōrātōris"
  ],
  "precede": [
    "anteeō, anteīre, anteiī, anteitum",
    "antecēdō, antecēdere, antecēssī, antecēssum",
    "praecēdō, praecēdere, praecessī, praecessum",
    "praecurrō, praecurrere, praecucurrī, praecursum"
  ],
  "excel or surpass": [
    "antecēdō, antecēdere, antecēssī, antecēssum"
  ],
  "gradually": [
    "sēnsim",
    "gradātim",
    "paulātim",
    "pedetemptim, pedetemptius, pedetemptissimē"
  ],
  "little by little": [
    "paulātim"
  ],
  "bit by bit": [
    "paulātim"
  ],
  "divide or distribute assign": [
    "distribuō, distribuere, distribuī, distribūtum"
  ],
  "divert or turn away": [
    "dīvertō, dīvertere, dīvertī, dīversum"
  ],
  "oppose": [
    "restō, restāre, restitī, —",
    "dīvertō, dīvertere, dīvertī, dīversum",
    "obiectō, obiectāre, obiectāvī, obiectātum",
    "contrādīcō, contrādīcere, contrādīxī, contrādictum"
  ],
  "divorce": [
    "dīscidium, dīscidiī",
    "dīvertō, dīvertere, dīvertī, dīversum"
  ],
  "of or belonging to the night": [
    "nocturnus, nocturna, nocturnum"
  ],
  "nocturnal": [
    "Nocturnal",
    "nocturnus, nocturna, nocturnum"
  ],
  "put or place aside or away": [
    "dēpōnō, dēpōnere, dēposuī, dēpositum"
  ],
  "deposit": [
    "dēpōnō, dēpōnere, dēposuī, dēpositum"
  ],
  "clash or collide": [
    "cōnfligō, cōnfligere, cōnflixī, cōnflictum"
  ],
  "combat": [
    "Pugna",
    "certātiō, certātiōnis",
    "cōnfligō, cōnfligere, cōnflixī, cōnflictum"
  ],
  "engage argue or disagree": [
    "cōnfligō, cōnfligere, cōnflixī, cōnflictum"
  ],
  "paucity": [
    "paucitās, paucitātis"
  ],
  "an elephant. ivory": [
    "elephantus, elephantī"
  ],
  "labienus": [
    "labiēnus, labiēnī"
  ],
  "interpose": [
    "praedūcō, praedūcere, praedūxī, praeductum",
    "interiaciō, interiacere, interiecī, interiectum"
  ],
  "intermingle": [
    "immisceō, immiscēre, immiscuī, immixtum",
    "commisceō, commiscēre, commiscuī, commixtum",
    "interiaciō, interiacere, interiecī, interiectum",
    "permisceō, permiscēre, permiscuī, permixtum, permistum"
  ],
  "a meeting": [
    "cōntiō, cōntiōnis",
    "concilium, conciliī"
  ],
  "assembly": [
    "cōntiō, cōntiōnis"
  ],
  "stay or remain": [
    "commoror, commorārī, commorātus sum"
  ],
  "continuing for two days": [
    "biduus, bidua, biduum"
  ],
  "beginner": [
    "tīrō, tīrōnis"
  ],
  "novice": [
    "Novitius",
    "tīrō, tīrōnis"
  ],
  "carry across": [
    "trānsportō, trānsportāre, trānsportāvī, trānsportātum"
  ],
  "accord": [
    "convenientia, convenientiae",
    "cōnsentiō, cōnsentīre, cōnsēnsī, cōnsēnsum"
  ],
  "guide or bring through or to a place": [
    "perdūcō, perdūcere, perdūxī, perductum"
  ],
  "state property": [
    "pūblicum, pūblicī"
  ],
  "foresee": [
    "dīvīnō, dīvīnāre, dīvīnāvī, dīvīnātum",
    "prōvideō, prōvidēre, prōvīdī, prōvīsum"
  ],
  "be cautious": [
    "prōvideō, prōvidēre, prōvīdī, prōvīsum"
  ],
  "act with foresight": [
    "prōvideō, prōvidēre, prōvīdī, prōvīsum"
  ],
  "collect": [
    "exigō, exigere, exēgī, exāctum",
    "colligō, colligere, collēgī, collēctum",
    "condūcō, condūcere, condūxī, conductum",
    "comportō, comportāre, comportāvī, comportātum",
    "conquaerō, conquaerere, conquaesīvī, conquaesītum"
  ],
  "proclaim": [
    "adnūntiō, adnūntiāre, adnūntiāvī, adnūntiātum",
    "praedicō, praedicāre, praedicāvī, praedicātum",
    "prōscrībō, prōscrībere, prōscrīpsī, prōscrīptum",
    "prōnūntiō, prōnūntiāre, prōnūntiāvī, prōnūntiātum"
  ],
  "declare": [
    "arguō, arguere, arguī, argūtum",
    "ēdīcō, ēdīcere, ēdīxī, ēdictum",
    "ēnuntiō, ēnuntiāre, ēnuntiāvī, ēnuntiātum",
    "prōnūntiō, prōnūntiāre, prōnūntiāvī, prōnūntiātum"
  ],
  "explore": [
    "rīmor, rīmārī, rīmātus sum",
    "explōrō, explōrāre, explōrāvī, explōrātum"
  ],
  "investigate": [
    "Investiga",
    "percontor, percontārī, percontātus sum",
    "explōrō, explōrāre, explōrāvī, explōrātum",
    "inquīrō, inquīrere, inquīsīvī, inquīsītum",
    "investīgō, investīgāre, investīgāvī, investīgātum"
  ],
  "do business. act as banker. trade": [
    "negōtior, negōtiārī, negōtiātus sum"
  ],
  "traffic": [
    "commercium, commerciī",
    "negōtior, negōtiārī, negōtiātus sum"
  ],
  "assurance": [
    "fīdūcia, fīdūciae",
    "cōnfīdentia, cōnfīdentiae"
  ],
  "space within the vallum of a camp. interval": [
    "intervallum, intervallī"
  ],
  "tear apart": [
    "dīripiō, dīripere, dīripuī, dīreptum"
  ],
  "tear to pieces. lay waste. loot": [
    "dīripiō, dīripere, dīripuī, dīreptum"
  ],
  "rob. whip out . run after": [
    "dīripiō, dīripere, dīripuī, dīreptum"
  ],
  "compete for the company of": [
    "dīripiō, dīripere, dīripuī, dīreptum"
  ],
  "accustom": [
    "cōnsuēscō, cōnsuēscere, cōnsuēvī, cōnsuētum"
  ],
  "inure or habituate": [
    "cōnsuēscō, cōnsuēscere, cōnsuēvī, cōnsuētum"
  ],
  "tend to": [
    "cōnsuēscō, cōnsuēscere, cōnsuēvī, cōnsuētum"
  ],
  "have sexual intercourse with": [
    "cōnsuēscō, cōnsuēscere, cōnsuēvī, cōnsuētum"
  ],
  "come forth": [
    "prōdeō, prōdīre, prōdiī, prōditum",
    "prōveniō, prōvenīre, prōvēnī, prōventum",
    "pullulō, pullulāre, pullulāvī, pullulātum"
  ],
  "appear advance": [
    "prōdeō, prōdīre, prōdiī, prōditum"
  ],
  "make new": [
    "novō, novāre, novāvī, novātum"
  ],
  "renew": [
    "novō, novāre, novāvī, novātum",
    "innovō, innovāre, innovāvī, innovātum",
    "renovō, renovāre, renovāvī, renovātum",
    "īnstaurō, īnstaurāre, īnstaurāvī, īnstaurātum"
  ],
  "convey or bring to or towards a place": [
    "aggerō, aggerere, aggessī, aggestum"
  ],
  "accost": [
    "adorior, adorīrī, adortus sum",
    "congredior, congredī, congressus sum"
  ],
  "a light boat": [
    "scapha, scaphae"
  ],
  "skiff": [
    "scapha, scaphae"
  ],
  "necessity": [
    "necessitās, necessitātis",
    "necessitūdō, necessitūdinis"
  ],
  "unavoidableness": [
    "necessitās, necessitātis"
  ],
  "compulsion": [
    "coactus, coactūs",
    "necessitās, necessitātis"
  ],
  "exigency": [
    "necessitās, necessitātis"
  ],
  "wickerwork": [
    "crātis, crātis"
  ],
  "bundle of brush": [
    "crātis, crātis"
  ],
  "fascine": [
    "crātis, crātis",
    "fascis, fascis"
  ],
  "sharply": [
    "ācriter, ācrius, ācerrimē"
  ],
  "vehemently": [
    "ācriter, ācrius, ācerrimē",
    "asperē, asperius, asperrimē",
    "violenter, violentius, violentissimē"
  ],
  "zealously": [
    "ācriter, ācrius, ācerrimē",
    "ēnīxē, ēnīxius, ēnīxissimē"
  ],
  "slinger": [
    "funditor, funditōris"
  ],
  "a hair": [
    "pilus, pilī"
  ],
  "place underneath": [
    "summittō, summittere, summīsī, summissum"
  ],
  "lie in wait": [
    "īnsidior, īnsidiārī, īnsidiātus sum"
  ],
  "lurk": [
    "īnsidior, īnsidiārī, īnsidiātus sum"
  ],
  "check or retard": [
    "tardō, tardāre, tardāvī, tardātum"
  ],
  "impede or delay": [
    "tardō, tardāre, tardāvī, tardātum"
  ],
  "come or meet with someone": [
    "congredior, congredī, congressus sum"
  ],
  "set against. oppose": [
    "oppōnō, oppōnere, opposuī, oppositum"
  ],
  "refuse": [
    "Recusa",
    "abnuō, abnuere, abnuī, abnuitum",
    "abdīcō, abdīcere, abdīxī, abdictum",
    "recūsō, recūsāre, recūsāvī, recūsātum",
    "dētractō, dētractāre, dētractāvī, dētractātum"
  ],
  "neighbourhood": [
    "vīcus, vīcī",
    "vīcīnia, vīcīniae"
  ],
  "rescue. snatch away. escape": [
    "ēripiō, ēripere, ēripuī, ēreptum"
  ],
  "intervene": [
    "intercēdō, intercedere, intercessī, intercessum",
    "interveniō, intervenīre, intervēnī, interventum"
  ],
  "intercede": [
    "intercēdō, intercedere, intercessī, intercessum"
  ],
  "interrupt or hinder": [
    "intercēdō, intercedere, intercessī, intercessum"
  ],
  "veto": [
    "intercēdō, intercedere, intercessī, intercessum"
  ],
  "devastate": [
    "vastō, vastāre, vastāvī, vastātum"
  ],
  "ravage or lay waste": [
    "vastō, vastāre, vastāvī, vastātum"
  ],
  "departure": [
    "excessus, excessūs",
    "dēcessiō, dēcessiōnis",
    "dīgressiō, dīgressiōnis",
    "profectiō, profectiōnis"
  ],
  "original": [
    "orīginālis, orīgināle",
    "prīncipālis, prīncipāle",
    "prīstinus, prīstina, prīstinum"
  ],
  "primitive": [
    "prīstinus, prīstina, prīstinum",
    "prīmitīvus, prīmitīva, prīmitīvum"
  ],
  "pristine": [
    "cascus, casca, cascum",
    "prīstinus, prīstina, prīstinum"
  ],
  "traditional": [
    "prīstinus, prīstina, prīstinum"
  ],
  "a fire": [
    "incendium, incendiī"
  ],
  "inferno": [
    "Infernus",
    "incendium, incendiī"
  ],
  "conflagration": [
    "incendium, incendiī",
    "īnflammātiō, īnflammātiōnis"
  ],
  "torch": [
    "Torch",
    "fax, facis",
    "incendium, incendiī"
  ],
  "disagreement": [
    "discordia, discordiae",
    "dissēntiō, dissentiōnis"
  ],
  "quarrel dissension": [
    "dissēntiō, dissentiōnis"
  ],
  "conflict": [
    "dissēntiō, dissentiōnis"
  ],
  "ascertain": [
    "comperiō, comperīre, comperī, compertum"
  ],
  "contumely": [
    "contumēlia, contumēliae"
  ],
  "abuse": [
    "iūrgium, iūrgiī",
    "contumēlia, contumēliae"
  ],
  "dispute or argue": [
    "dēcertō, dēcertāre, dēcertāvī, dēcertātum"
  ],
  "swim to or towards": [
    "adnatō, adnatāre, adnatāvī, adnatātum"
  ],
  "mistrust or suspect": [
    "suspicor, suspicārī, suspicātus sum"
  ],
  "on or from both sides or ends": [
    "ūtrimque"
  ],
  "length": [
    "longitūdō, longitūdinis",
    "longinquitās, longinquitātis"
  ],
  "longitude": [
    "longitūdō, longitūdinis"
  ],
  "longness. a duration": [
    "longitūdō, longitūdinis"
  ],
  "middling": [
    "mediocris, mediocre"
  ],
  "normal": [
    "Normalis",
    "mediocris, mediocre"
  ],
  "indifferent": [
    "mediocris, mediocre",
    "indifferēns, indifferentis"
  ],
  "unique": [
    "singulāris, singulāre",
    "monōdicus, monōdica, monōdicum"
  ],
  "singular": [
    "singulāris, singulāre"
  ],
  "unusual": [
    "īnsolēns, īnsolentis",
    "singulāris, singulāre",
    "īnsuētus, īnsuēta, īnsuētum",
    "inūsitātus, inūsitāta, inūsitātum"
  ],
  "send or bring down": [
    "dēmittō, dēmittere, dēmīsī, dēmissum"
  ],
  "cause to hang or fall down": [
    "dēmittō, dēmittere, dēmīsī, dēmissum"
  ],
  "eat fodder": [
    "pābulor, pābulārī, pābulātus sum"
  ],
  "graze. seek fodder": [
    "pābulor, pābulārī, pābulātus sum"
  ],
  "food or subsistence": [
    "pābulor, pābulārī, pābulātus sum"
  ],
  "forage. manure": [
    "pābulor, pābulārī, pābulātus sum"
  ],
  "nourish": [
    "alō, alere, aluī, altum",
    "pābulor, pābulārī, pābulātus sum"
  ],
  "report or announce": [
    "renuntiō, renuntiāre, renuntiāvī, renuntiātum"
  ],
  "retract": [
    "renuntiō, renuntiāre, renuntiāvī, renuntiātum"
  ],
  "renounce or revoke": [
    "renuntiō, renuntiāre, renuntiāvī, renuntiātum"
  ],
  "hunt": [
    "Venari",
    "Venatio",
    "vēnātus, vēnātūs",
    "vēnor, vēnārī, vēnātus sum",
    "conquaerō, conquaerere, conquaesīvī, conquaesītum"
  ],
  "anchor": [
    "ancora, ancorae"
  ],
  "strengthen": [
    "firmō, firmāre, firmāvī, firmātum",
    "rōborō, rōborāre, rōborāvī, rōborātum",
    "commūniō, commūnīre, commūnīvī, commūnītum",
    "corrōborō, corrōborāre, corrōborāvī, corrōborātum"
  ],
  "harden": [
    "dūrō, dūrāre, dūrāvī, dūrātum",
    "indūrēscō, indūrēscere, indūruī",
    "obdūrēscō, obdūrēscere, obdūruī",
    "firmō, firmāre, firmāvī, firmātum",
    "indūrō, indūrāre, indūrāvī, indūrātum"
  ],
  "push against": [
    "impellō, impellere, impulī, impulsum"
  ],
  "assail. impel": [
    "impellō, impellere, impulī, impulsum"
  ],
  "shout or cry out": [
    "conclāmō, conclāmāre, conclāmāvī, conclāmātum"
  ],
  "come in contact with": [
    "attingō, attingere, attigī, attāctum"
  ],
  "touch. assault": [
    "attingō, attingere, attigī, attāctum"
  ],
  "stable": [
    "Catabulum",
    "stabulum -ī n",
    "stabilis, stabile",
    "firmus, firma, firmum"
  ],
  "steadfast": [
    "stabilis, stabile",
    "firmus, firma, firmum"
  ],
  "send into": [
    "immittō, immittere, immisī, immissum"
  ],
  "trick": [
    "dolus, dolī",
    "frūstrātiō, frūstrātiōnis",
    "frūstrō, frūstrāre, frūstrāvī, frūstrātum"
  ],
  "cheat": [
    "dēcipiō, dēcipere, dēcēpī, dēceptum",
    "fraudō, fraudāre, fraudāvī, fraudātum",
    "frūstrō, frūstrāre, frūstrāvī, frūstrātum"
  ],
  "strip": [
    "nūdō, nūdāre, nūdāvī, nūdātum",
    "spoliō, spoliāre, spoliāvī, spoliātum"
  ],
  "bare": [
    "nūdō, nūdāre, nūdāvī, nūdātum",
    "retegō, retegere, retēxī, retēctum"
  ],
  "make naked": [
    "nūdō, nūdāre, nūdāvī, nūdātum"
  ],
  "apply the mind": [
    "adversō, adversāre, adversāvī, adversātum"
  ],
  "to long for": [
    "cupiō, cupere, cupīvī, cupītum"
  ],
  "escort": [
    "prōsequor, prōsequī, prōsecūtus sum"
  ],
  "accompany. pursue": [
    "prōsequor, prōsequī, prōsecūtus sum"
  ],
  "lay hold of something on all sides": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "mindful": [
    "memor, memoris",
    "intentus, intenta, intentum"
  ],
  "remembering": [
    "memor, memoris"
  ],
  "mound": [
    "tumulus, tumulī"
  ],
  "hill. grave": [
    "tumulus, tumulī"
  ],
  "tumulus": [
    "tumulus, tumulī"
  ],
  "shovel": [
    "Rutrum",
    "pāla, pālae"
  ],
  "spade": [
    "pāla, pālae"
  ],
  "press down": [
    "dēprimō, dēprimere, dēpressī, dēpressum",
    "opprimō, opprimere, oppressī, oppressum"
  ],
  "depress": [
    "dēprimō, dēprimere, dēpressī, dēpressum",
    "contrīstō, contrīstāre, contrīstāvī, contrīstātum"
  ],
  "smooth": [
    "lēnis, lēne",
    "expoliō, expolīre, expolīvī, expolītum"
  ],
  "gentle": [
    "lēnis, lēne",
    "placidus, placida, placidum"
  ],
  "make narrow": [
    "angustiō, angustiāre, angustiāvī, angustiātum"
  ],
  "request entreaty": [
    "prex, precis"
  ],
  "intercept": [
    "intercipiō, intercipere, intercēpī, interceptum"
  ],
  "interrupt or cut off": [
    "intercipiō, intercipere, intercēpī, interceptum"
  ],
  "rob or steal": [
    "intercipiō, intercipere, intercēpī, interceptum"
  ],
  "take refuge": [
    "confugiō, confugere, confūgī, ----",
    "cōnfugiō, cōnfugere, cōnfūgī, cōnfugitum"
  ],
  "appeal to": [
    "cōnfugiō, cōnfugere, cōnfūgī, cōnfugitum"
  ],
  "begotten arise": [
    "nāscor, nāscī, -"
  ],
  "proceed grow": [
    "nāscor, nāscī, -"
  ],
  "siege": [
    "obsidiō, obsidiōnis",
    "obsessiō, obsessiōnis",
    "conclūsiō, conclūsiōnis"
  ],
  "greatly": [
    "magnoperē, magis, maximē"
  ],
  "not anticipating": [
    "imprōvidē"
  ],
  "not foreseeing": [
    "imprōvidē"
  ],
  "small mouse": [
    "mūsculus, mūsculī"
  ],
  "muscle": [
    "nervus, nervī",
    "mūsculus, mūsculī"
  ],
  "mantelet": [
    "mūsculus, mūsculī"
  ],
  "shielding": [
    "mūsculus, mūsculī"
  ],
  "ascent": [
    "ascensus, ascensūs"
  ],
  "scaling": [
    "ascensus, ascensūs"
  ],
  "fetch water for drinking": [
    "aquor, aquārī, aquātus sum"
  ],
  "one who defends": [
    "dēfēnsor, dēfēnsōris"
  ],
  "give out": [
    "dīdō, dīdere, dīdidī, dīditum",
    "mānō, mānāre, mānāvī, mānātum",
    "deficiō deficere defēcī defectum"
  ],
  "pour forth": [
    "mānō, mānāre, mānāvī, mānātum",
    "prōfundō, prōfundere, prōfudī, prōfusum"
  ],
  "convenience": [
    "opportūnitās, opportūnitātis"
  ],
  "root": [
    "rādīx, rādīcis"
  ],
  "in close combat": [
    "comminus"
  ],
  "in close contest": [
    "comminus"
  ],
  "hand-to-hand": [
    "comminus"
  ],
  "township": [
    "mūnicipium, mūnicipiī or mūnicipī"
  ],
  "municipality": [
    "mūnicipium, mūnicipiī or mūnicipī"
  ],
  "timber": [
    "trabs, trabis"
  ],
  "beam": [
    "trabs, trabis"
  ],
  "excite": [
    "irrītō, irrītāre, irrītāvī, irrītātum",
    "lacessō, lacessere, lacessīvī, lacessītum"
  ],
  "provoke": [
    "lacessō, lacessere, lacessīvī, lacessītum",
    "exacerbō, exacerbāre, exacerbāvī, exacerbātum"
  ],
  "breadth": [
    "lātitūdō, lātitūdinis",
    "amplitūdō, amplitūdinis"
  ],
  "width": [
    "lātitūdō, lātitūdinis",
    "amplitūdō, amplitūdinis"
  ],
  "latitude": [
    "lātitūdō, lātitūdinis"
  ],
  "obtain grain": [
    "frūmentor, frūmentārī, frūmentātus sum"
  ],
  "forage": [
    "frūmentor, frūmentārī, frūmentātus sum"
  ],
  "drive. hurl": [
    "adigō, adigere, adēgī, adactum"
  ],
  "power to help": [
    "ops, opis"
  ],
  "defection": [
    "dēfectiō, dēfectiōnis"
  ],
  "desertion rebellion": [
    "dēfectiō, dēfectiōnis"
  ],
  "revolt": [
    "dēfectiō, dēfectiōnis",
    "rebelliō, rebelliōnis"
  ],
  "fight or contend for": [
    "prōpugnō, prōpugnāre, prōpugnāvī, prōpugnātum"
  ],
  "convey or transport": [
    "supportō, supportāre, supportāvī, supportātum"
  ],
  "unfold": [
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "unfurl": [
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "uncoil": [
    "explicō, explicāre, explicāvī, explicātum"
  ],
  "by accident": [
    "temerē"
  ],
  "at random": [
    "temerē"
  ],
  "ignorant of": [
    "imprūdēns, imprūdentis"
  ],
  "scrupulousness": [
    "religiō, religiōnis"
  ],
  "piety": [
    "religiō, religiōnis"
  ],
  "unfairness": [
    "inīquitās, inīquitātis",
    "iniūstitia, iniūstitiae"
  ],
  "inequality": [
    "prāvitās, prāvitātis",
    "inīquitās, inīquitātis",
    "inaequālitās, inaequālitātis"
  ],
  "unbend": [
    "retendō, retendere, retendī, retēnsum"
  ],
  "slacken": [
    "relaxō, relaxāre, relaxāvī, relaxātum",
    "retendō, retendere, retendī, retēnsum"
  ],
  "relax": [
    "Mollis esto",
    "retendō, retendere, retendī, retēnsum"
  ],
  "dishonour disgrace": [
    "ignōminia, ignōminiae"
  ],
  "ignominy": [
    "ignōminia, ignōminiae"
  ],
  "draw from under or below": [
    "subdūcō, subdūcere, subdūxī, subductum"
  ],
  "lift or pull up": [
    "subdūcō, subdūcere, subdūxī, subductum"
  ],
  "a roman": [
    "rōmānus, rōmānī"
  ],
  "unbelievable": [
    "incrēdibilis, incrēdibile"
  ],
  "incredible": [
    "īnfīdus, īnfīda, īnfīdum",
    "incrēdibilis, incrēdibile"
  ],
  "reddish": [
    "rūfus, rūfa, rūfum"
  ],
  "ruddy": [
    "rūfus, rūfa, rūfum"
  ],
  "praetorian": [
    "praetōrius, praetōria, praetōrium"
  ],
  "senator": [
    "senātor, senātōris"
  ],
  "member of the roman senate": [
    "senātor, senātōris"
  ],
  "the space of three days": [
    "trīduum, trīduī"
  ],
  "three days": [
    "trīduum, trīduī"
  ],
  "alteration": [
    "Mutatio",
    "ALTERATIO",
    "alterātiō, alterātiōnis",
    "immūtātiō, immūtātiōnis",
    "commūtātiō, commūtātiōnis",
    "permūtātiō, permūtātiōnis"
  ],
  "reversal": [
    "commūtātiō, commūtātiōnis"
  ],
  "a pasture. the action of collecting fodder or food": [
    "pābulātiō, pābulātiōnis"
  ],
  "foraging": [
    "pābulātiō, pābulātiōnis",
    "frūmentātiō, frūmentātiōnis"
  ],
  "first place": [
    "principātus, principātūs"
  ],
  "leadership": [
    "ducātus, ducātūs",
    "principātus, principātūs"
  ],
  "supremacy": [
    "principātus, principātūs"
  ],
  "advance or go to or towards": [
    "aggredior, aggredī, aggressus sum"
  ],
  "in the same manner": [
    "perinde",
    "proindē"
  ],
  "strew": [
    "cōnsternō, cōnsternere, cōnstrāvī, cōnstrātum",
    "substernō, substernere, substrāvī, substrātum"
  ],
  "thatch": [
    "cōnsternō, cōnsternere, cōnstrāvī, cōnstrātum"
  ],
  "a fight": [
    "dīmicātiō, dīmicātiōnis"
  ],
  "send forth": [
    "ēmittō, ēmittere, ēmīsī, ēmissum",
    "prōmittō, prōmittere, prōmīsī, prōmissum"
  ],
  "emit": [
    "ēmittō, ēmittere, ēmīsī, ēmissum"
  ],
  "avert by prayer": [
    "dēprecor, dēprecārī, dēprecātus sum"
  ],
  "deprecate": [
    "dēprecor, dēprecārī, dēprecātus sum"
  ],
  "nearness": [
    "propinquitās, propinquitātis"
  ],
  "propinquity": [
    "propinquitās, propinquitātis"
  ],
  "proximity": [
    "propinquitās, propinquitātis"
  ],
  "customer": [
    "cliēns, clientis"
  ],
  "client": [
    "cliēns, clientis"
  ],
  "feigning": [
    "simulātiō, simulātiōnis"
  ],
  "pretence": [
    "simulātiō, simulātiōnis"
  ],
  "implore": [
    "exposcō, exposcere, expoposcī",
    "implōrō, implōrāre, implōrāvī, implōrātum",
    "obsecrō, obsecrāre, obsecrāvī, obsecrātum"
  ],
  "supplicate": [
    "obsecrō, obsecrāre, obsecrāvī, obsecrātum"
  ],
  "change or alter entirely": [
    "commūtō, commūtāre, commūtāvī, commūtātum"
  ],
  "conceal": [
    "abdō, abdere, abdidī, abditum",
    "cēlō, cēlāre, cēlāvī, cēlātum",
    "condō, condere, condidī, conditum",
    "occulō, occulere, occuluī, occultum",
    "contegō, contegere, contēxī, contēctum",
    "abscondō, abscondere, abscondī, absconditum",
    "dissimulō, dissimulāre, dissimulāvī, dissimulātum"
  ],
  "keep secret": [
    "abdō, abdere, abdidī, abditum"
  ],
  "wage or carry out war": [
    "bellor, bellāri, bellātus sum"
  ],
  "tire out or exhaust": [
    "dēfatīgō, dēfatīgāre, dēfatīgāvī, dēfatīgātum"
  ],
  "wounded": [
    "saucius, saucia, saucium"
  ],
  "flee to the enemy": [
    "trānsfugiō, trānsfugere, trānsfūgī, trānsfugitum"
  ],
  "oarsman": [
    "rēmex, rēmigis"
  ],
  "rower": [
    "rēmex, rēmigis"
  ],
  "to fill completely": [
    "cōnferciō, cōnfercīre, cōnfersī, cōnfertus"
  ],
  "run down": [
    "dēcurrō, dēcurrere, dēcurrī, dēcursum"
  ],
  "that which is necessary or indispensable": [
    "necessāria, necessāriōrum"
  ],
  "recover revive": [
    "reciperō, reciperāre, reciperāvī, reciperātum"
  ],
  "restore": [
    "Refice",
    "innovō, innovāre, innovāvī, innovātum",
    "recreō, recreāre, recreāvī, recreātum",
    "restituō, restituere, restituī, restitūtum",
    "reciperō, reciperāre, reciperāvī, reciperātum"
  ],
  "break off": [
    "dīrumpō, dīrumpere, dīrūpī, dīruptum",
    "praerumpō, praerumpere, praerūpī, praeruptum"
  ],
  "tear to pieces": [
    "laniō, laniāre, laniāvī, laniātum",
    "lacerō, lacerāre, lacerāvī, lacerātum",
    "praerumpō, praerumpere, praerūpī, praeruptum"
  ],
  "canine": [
    "canīnus, canīna, canīnum"
  ],
  "of or pertaining to a dog": [
    "canīnus, canīna, canīnum"
  ],
  "a wagon": [
    "carrus, carrī"
  ],
  "a four-wheeled baggage cart": [
    "carrus, carrī"
  ],
  "inexperienced": [
    "īnscītus, īnscīta, īnscītum",
    "īnsuētus, īnsuēta, īnsuētum",
    "imperītus, imperīta, imperītum"
  ],
  "unskilled": [
    "iners, inertis",
    "īnscius, īnscia, īnscium",
    "imperītus, imperīta, imperītum"
  ],
  "unfamiliar": [
    "imperītus, imperīta, imperītum"
  ],
  "fond of drinking": [
    "bibulus, bibula, bibulum"
  ],
  "bibulous": [
    "bibulus, bibula, bibulum"
  ],
  "thirsty.": [
    "bibulus, bibula, bibulum"
  ],
  "a council": [
    "concilium, conciliī"
  ],
  "triple": [
    "triplex, triplicis",
    "trīnus, trīna, trīnum"
  ],
  "threefold": [
    "triplex, triplicis",
    "tripertītus, tripertīta, tripertītum"
  ],
  "pile": [
    "struēs, struis",
    "acervus, acervī",
    "cumulus, cumulī",
    "congeriēs, congeriēī",
    "exstruō, exstruere, exstruxī, exstructum"
  ],
  "heap or build up": [
    "exstruō, exstruere, exstruxī, exstructum"
  ],
  "stand or gather around": [
    "circumsistō, circumsistere, circumstitī, circumstatum"
  ],
  "surround or beset": [
    "circumsistō, circumsistere, circumstitī, circumstatum"
  ],
  "hold or keep apart": [
    "distineō, distinēre, distinuī, distentum"
  ],
  "split": [
    "findō, findere, fidī, fissum",
    "dīdūcō, dīdūcere, dīdūxī, dīductum",
    "distineō, distinēre, distinuī, distentum"
  ],
  "drive out or away": [
    "dēpellō, dēpellere, dēpulī, dēpulsum",
    "exagitō, exagitāre, exagitāvī, exagitātum"
  ],
  "civic": [
    "cīvīlis, cīvīle"
  ],
  "political": [
    "cīvīlis, cīvīle",
    "polīticus, polītica, polīticum"
  ],
  "allow or permit to pass": [
    "praetermittō, praetermittere, praetermīsī, praetermissum"
  ],
  "omit or neglect": [
    "praetermittō, praetermittere, praetermīsī, praetermissum"
  ],
  "trusting to": [
    "frētus, frēta, frētum"
  ],
  "relying on": [
    "frētus, frēta, frētum"
  ],
  "the act of taking possession of": [
    "possessiō, possessiōnis"
  ],
  "seizing": [
    "captus, captūs",
    "occupātiō, occupātiōnis",
    "possessiō, possessiōnis",
    "comprehensiō, comprehensiōnis"
  ],
  "occupying": [
    "occupātiō, occupātiōnis",
    "possessiō, possessiōnis"
  ],
  "taking": [
    "captus, captūs",
    "assūmptiō, assūmptiōnis",
    "perceptiō, perceptiōnis",
    "possessiō, possessiōnis"
  ],
  "lay straight": [
    "dīrigō, dīrigere, dīrexī, dīrectum"
  ],
  "arrange in lines": [
    "dīrigō, dīrigere, dīrexī, dīrectum"
  ],
  "a hospitable reception": [
    "hospitium, hospitiī"
  ],
  "entertainment": [
    "hospitium, hospitiī"
  ],
  "hospitality": [
    "hospitium, hospitiī"
  ],
  "make smaller": [
    "dēminuō, dēminuere, dēminuī, dēminūtum"
  ],
  "step beyond": [
    "trānsgredior, trānsgredī, trānsgressus sum"
  ],
  "across or over": [
    "trānsgredior, trānsgredī, trānsgressus sum"
  ],
  "climb or pass over": [
    "trānsgredior, trānsgredī, trānsgressus sum"
  ],
  "respect clothe with honor": [
    "honōrō, honōrāre, honōrāvī, honōrātum"
  ],
  "unwilling": [
    "nōlō, nōlle, nōluī",
    "invītus, invīta, invītum",
    "nōlō, nōlle, nōluī, -----"
  ],
  "reluctant": [
    "invītus, invīta, invītum"
  ],
  "apportion": [
    "partiō, partīre, partīvī, partītum",
    "discrībō, discrībere, discrīpsi, discrīptum"
  ],
  "distribute": [
    "dīdō, dīdere, dīdidī, dīditum",
    "partiō, partīre, partīvī, partītum",
    "dīgerō, dīgerere, dīgessī, dīgestum",
    "discrībō, discrībere, discrīpsi, discrīptum",
    "dispēnsō, dispēnsāre, dispēnsāvī, dispēnsātum"
  ],
  "distrust": [
    "diffīdentia, diffīdentiae",
    "diffīdō, diffīdere, diffīsus sum"
  ],
  "announce solemnly": [
    "calō, calāre, calāvī, calātum"
  ],
  "call out": [
    "calō, calāre, calāvī, calātum"
  ],
  "twofold": [
    "duplex, duplicis",
    "bīnus, bīna, bīnum"
  ],
  "double": [
    "duplex, duplicis",
    "bīnus, bīna, bīnum",
    "duplus, dupla, duplum",
    "geminō, gemināre, gemināvī, geminātum",
    "duplicō, duplicāre, duplicāvī, duplicātum"
  ],
  "hold off": [
    "dētineō, dētinēre, dētinuī, dētentum"
  ],
  "detain": [
    "dētineō, dētinēre, dētinuī, dētentum",
    "retardō, retardāre, retardāvī, retardātum"
  ],
  "interior": [
    "interior, interius"
  ],
  "put forth hairs": [
    "pilō, pilāre, pilāvī, pilātum"
  ],
  "grow hairy": [
    "pilō, pilāre, pilāvī, pilātum"
  ],
  "ravine": [
    "convallis, convallis"
  ],
  "narrow enclosed valley": [
    "convallis, convallis"
  ],
  "grown up": [
    "grandis, grande"
  ],
  "grand": [
    "Maximus",
    "grandis, grande"
  ],
  "seize or occupy beforehand: preoccupy": [
    "praeoccupō, praeoccupāre, praeoccupāvī, praeoccupātum"
  ],
  "anticipate or prevent": [
    "praeoccupō, praeoccupāre, praeoccupāvī, praeoccupātum"
  ],
  "granting": [
    "largītiō, largītiōnis"
  ],
  "bestowing": [
    "largītiō, largītiōnis"
  ],
  "lead in or into somewhere": [
    "indūcō, indūcere, indūxī, inductum"
  ],
  "stand apart": [
    "distō, distāre, distitī"
  ],
  "be distant": [
    "distō, distāre, distitī"
  ],
  "make like": [
    "simulō, simulāre, simulāvī, simulātum"
  ],
  "simulate": [
    "simulō, simulāre, simulāvī, simulātum"
  ],
  "quadrireme": [
    "quādrirēmis, quādrirēmis"
  ],
  "though": [
    "tametsī"
  ],
  "naval": [
    "nāvālis, nāvāle",
    "nauticus, nautica, nauticum"
  ],
  "abide by": [
    "persevērō, persevērāre, persevērāvī, persevērātum"
  ],
  "persevere": [
    "persevērō, persevērāre, persevērāvī, persevērātum"
  ],
  "invade": [
    "introeō, introīre, introiī, introitum"
  ],
  "in succession": [
    "deinceps, deincipis"
  ],
  "successively": [
    "deinceps, deincipis"
  ],
  "throw across": [
    "trāiciō, trāicere, trāiēcī, trāiectum"
  ],
  "native": [
    "genuīnus, genuīna, genuīnum",
    "indigenus, indigena, indigenum",
    "domesticus, domestica, domesticum",
    "vernāculus, vernācula, vernāculum"
  ],
  "domestic": [
    "domesticus, domestica, domesticum",
    "vernāculus, vernācula, vernāculum"
  ],
  "indigenous": [
    "ingenuus, ingenua, ingenuum",
    "indigenus, indigena, indigenum",
    "vernāculus, vernācula, vernāculum"
  ],
  "vernacular": [
    "vernāculus, vernācula, vernāculum"
  ],
  "information": [
    "indicium, indiciī"
  ],
  "discovery": [
    "indicium, indiciī",
    "inventiō, inventiōnis",
    "prōditiō, prōditiōnis"
  ],
  "witty": [
    "lepidus, lepida, lepidum"
  ],
  "effeminate": [
    "lepidus, lepida, lepidum"
  ],
  "declare publicly": [
    "prōfiteor, prōfitērī, prōfessus sum",
    "praedicō, praedicāre, praedicāvī, praedicātum"
  ],
  "peak": [
    "culmen, culminis",
    "cacūmen, cacūminis",
    "fastīgium, fastīgiī",
    "prōmunturium, prōmunturiī"
  ],
  "undercoat": [
    "cotta, cottae"
  ],
  "wall up or around": [
    "circummūniō, circummūnire, circummūnīvī, circummūnītum"
  ],
  "dam": [
    "agger, aggeris"
  ],
  "dyke": [
    "agger, aggeris"
  ],
  "collision": [
    "incursus, incursūs",
    "offēnsus, offēnsūs",
    "cōnflictus, cōnflictūs",
    "conflictiō, conflictiōnis"
  ],
  "break or force through": [
    "perrumpō, perrumpere, perrūpī, perruptum"
  ],
  "intrude. invade": [
    "irrumpō, irrumpere, irrūpī, irruptum"
  ],
  "extensive": [
    "longinquus, longinqua, longinquum"
  ],
  "be available": [
    "suppetō, suppetere, suppetīvī, suppetītum"
  ],
  "present or at hand": [
    "suppetō, suppetere, suppetīvī, suppetītum"
  ],
  "package": [
    "fascis, fascis",
    "sarcina, sarcinae"
  ],
  "pack": [
    "Grex",
    "sarcina, sarcinae"
  ],
  "on the following": [
    "postrīdiē"
  ],
  "next day": [
    "postrīdiē"
  ],
  "ungird": [
    "recingō, recingere, recinxī, recinctum"
  ],
  "loosen": [
    "dīdūcō, dīdūcere, dīdūxī, dīductum",
    "relaxō, relaxāre, relaxāvī, relaxātum",
    "recingō, recingere, recinxī, recinctum",
    "resolvō, resolvere, resolvī, resolūtum"
  ],
  "undo gird again": [
    "recingō, recingere, recinxī, recinctum"
  ],
  "refasten": [
    "recingō, recingere, recinxī, recinctum"
  ],
  "pity": [
    "miserātiō, miserātiōnis",
    "misericordia, misericordiae",
    "misereor, miserērī, miseritus sum"
  ],
  "compassion": [
    "miserātiō, miserātiōnis",
    "misericordia, misericordiae"
  ],
  "misery": [
    "misericordia, misericordiae"
  ],
  "tent. tabernacle": [
    "tabernāculum, tabernāculī"
  ],
  "faithlessness": [
    "perfidia, perfidiae",
    "īnfidēlitās, īnfidēlitātis"
  ],
  "dishonesty": [
    "perfidia, perfidiae",
    "improbitās, improbitātis"
  ],
  "falsehood": [
    "mendācium, mendāciī",
    "perfidia, perfidiae",
    "falsitās, falsitātis"
  ],
  "perfidy": [
    "perfidia, perfidiae"
  ],
  "tortoise": [
    "testūdō, testūdinis"
  ],
  "turtle covering": [
    "testūdō, testūdinis"
  ],
  "overflow": [
    "refluō, refluere, refluxī",
    "superfluō, superfluere, superfluxī",
    "abundō, abundāre, abundāvī, abundātum",
    "inundō, inundāre, inundāvī, inundātum",
    "redundō, redundāre, redundāvī, redundātum"
  ],
  "flow over or down": [
    "abundō, abundāre, abundāvī, abundātum"
  ],
  "flooring": [
    "tabulātum, tabulātī"
  ],
  "storey": [
    "tabulātum, tabulātī"
  ],
  "just as many": [
    "totidem"
  ],
  "broadcast": [
    "vulgō, vulgāre, vulgāvī, vulgātum"
  ],
  "publish": [
    "prōdō, prōdere, prōdidī, prōditum",
    "vulgō, vulgāre, vulgāvī, vulgātum",
    "differō, differre, distulī, dīlātum",
    "dīvulgō, dīvulgāre, dīvulgāvī, dīvulgātum",
    "pūblicō, pūblicāre, pūblicāvī, pūblicātum",
    "prōmulgō, prōmulgāre, prōmulgāvī, prōmulgātum"
  ],
  "cheapen": [
    "vulgō, vulgāre, vulgāvī, vulgātum"
  ],
  "prove or test": [
    "perīclitor, perīclitārī, perīclitātus sum"
  ],
  "very little": [
    "pusillus, pusilla, pusillum",
    "perpaucus, perpauca, perpaucum"
  ],
  "very few": [
    "perpaucus, perpauca, perpaucum"
  ],
  "ladder": [
    "Scala",
    "scāla, scālae"
  ],
  "stairs": [
    "scāla, scālae"
  ],
  "make known": [
    "indicō, indicāre, indicāvī, indicātum",
    "adnūntiō, adnūntiāre, adnūntiāvī, adnūntiātum",
    "nōbilitō, nōbilitāre, nōbilitāvī, nōbilitātum"
  ],
  "make famous. make infamous. ennoble": [
    "nōbilitō, nōbilitāre, nōbilitāvī, nōbilitātum"
  ],
  "improve": [
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum",
    "nōbilitō, nōbilitāre, nōbilitāvī, nōbilitātum"
  ],
  "throw under. subdue. subject": [
    "sūbiciō, sūbicere, sūbiēcī, sūbiectum"
  ],
  "a union for a common purpose": [
    "societās, societātis"
  ],
  "society": [
    "societās, societātis",
    "congregātiō, congregātiōnis"
  ],
  "fellowship": [
    "societās, societātis",
    "cōnsortiō, cōnsortiōnis"
  ],
  "partnership": [
    "societās, societātis",
    "cōnsortiō, cōnsortiōnis"
  ],
  "bring or draw together": [
    "condūcō, condūcere, condūxī, conductum"
  ],
  "robbery": [
    "rapīna, rapīnae"
  ],
  "plundering": [
    "rapīna, rapīnae",
    "dīreptiō, dīreptiōnis",
    "spoliātiō, spoliātiōnis",
    "dēpopulātiō, dēpopulātiōnis"
  ],
  "rapine": [
    "rapīna, rapīnae"
  ],
  "in the way": [
    "obvius, obvia, obvium"
  ],
  "exposed": [
    "apparēns, apparentis",
    "obvius, obvia, obvium"
  ],
  "useless": [
    "inūtilis, inūtile",
    "supervacuus, supervacua, supervacuum"
  ],
  "unserviceable": [
    "inūtilis, inūtile"
  ],
  "unprofitable": [
    "inūtilis, inūtile"
  ],
  "hurtful": [
    "inūtilis, inūtile",
    "noxius, noxia, noxium",
    "nocīvus, nocīva, nocīvum"
  ],
  "injurious": [
    "inūtilis, inūtile",
    "noxius, noxia, noxium",
    "nocīvus, nocīva, nocīvum",
    "damnōsus, damnōsa, damnōsum"
  ],
  "comply with": [
    "obsequor, obsequī, obsecūtus sum",
    "obtemperō, obtemperāre, obtemperāvī, obtemperātum"
  ],
  "conform to": [
    "obtemperō, obtemperāre, obtemperāvī, obtemperātum"
  ],
  "mortar": [
    "pīla, pīlae",
    "pīsō, pīsōnis",
    "mortārium, mortāriī"
  ],
  "change the nature of a person or thing into something else": [
    "aliēnō, aliēnāre, aliēnāvī, aliēnātum"
  ],
  "break apart": [
    "interrumpō, interrumpere, interrūpī, interruptum"
  ],
  "expectation": [
    "expectātiō, expectātiōnis",
    "exspectātiō, exspectātiōnis"
  ],
  "waiting suspense": [
    "exspectātiō, exspectātiōnis"
  ],
  "lift up or raise": [
    "sublevō, sublevāre, sublevāvī, sublevātum"
  ],
  "a political faction": [
    "factiō, factiōnis"
  ],
  "a form of protective shed or breastwork": [
    "pluteus, pluteī"
  ],
  "intelligent": [
    "catus, cata, catum",
    "sēnsātus, sēnsāta, sēnsātum"
  ],
  "sagacious": [
    "catus, cata, catum",
    "astūtus, astūta, astūtum"
  ],
  "stand out": [
    "ēmineō, ēminēre, ēminuī",
    "praestō, praestāre, praestitī, praestatus"
  ],
  "project": [
    "ēmineō, ēminēre, ēminuī",
    "prōmineō, prōminēre, prōminuī",
    "immineō, imminēre, imminuī, imminitum"
  ],
  "protrude": [
    "ēmineō, ēminēre, ēminuī"
  ],
  "on that account": [
    "idcirco"
  ],
  "seek or pursue": [
    "cōnsector, cōnsectārī, cōnsectātus sum"
  ],
  "forward": [
    "Forward",
    "procāx, procācis",
    "prōpellō, prōpellere, prōpulī, prōpulsum"
  ],
  "propel": [
    "prōpellō, prōpellere, prōpulī, prōpulsum",
    "prōpulsō, prōpulsāre, prōpulsāvī, prōpulsātum"
  ],
  "lade": [
    "onerō, onerāre, onerāvī, onerātum"
  ],
  "load": [
    "onus, oneris",
    "onerō, onerāre, onerāvī, onerātum",
    "accumulō, accumulāre, accumulāvī, accumulātum"
  ],
  "stuff": [
    "tīgnum, tīgnī"
  ],
  "move forward": [
    "prōmoveō, prōmovēre, prōmōvī, prōmōtum"
  ],
  "clientship": [
    "clientēla, clientēlae"
  ],
  "patronage clients": [
    "clientēla, clientēlae"
  ],
  "deserter": [
    "perfuga, perfugae",
    "trānsfuga, trānsfugae"
  ],
  "carry or convey into": [
    "importō, importāre, importāvī, importātum"
  ],
  "bring in from abroad": [
    "importō, importāre, importāvī, importātum"
  ],
  "import introduce": [
    "importō, importāre, importāvī, importātum"
  ],
  "bring about": [
    "patrō, patrāre, patrāvī, patrātum",
    "importō, importāre, importāvī, importātum"
  ],
  "deprive of air or wind": [
    "exanimō, exanimāre, exanimāvī, exanimātum"
  ],
  "deflate": [
    "exanimō, exanimāre, exanimāvī, exanimātum"
  ],
  "weaken": [
    "exanimō, exanimāre, exanimāvī, exanimātum",
    "īnfirmō, īnfirmāre, īnfirmāvī, īnfirmātum",
    "labefactō, labefactāre, labefactāvī, labefactātum"
  ],
  "exhaust": [
    "abūtor, abūtī, abūsus sum",
    "siccō, siccāre, siccāvī, siccātum",
    "exanimō, exanimāre, exanimāvī, exanimātum"
  ],
  "rabbit": [
    "Rabbit",
    "Cunicularius",
    "cunīculus, cunīculī"
  ],
  "a habitation": [
    "domicilium, domiciliī"
  ],
  "dwelling": [
    "stabulum, stabulī",
    "domicilium, domiciliī",
    "habitāculum, habitāculī",
    "habitātiō, habitātiōnis"
  ],
  "domicile": [
    "domicilium, domiciliī"
  ],
  "abode": [
    "domicilium, domiciliī"
  ],
  "close or block": [
    "praeclūdō, praeclūdere, praeclūsī, praeclūsum"
  ],
  "forbid access to": [
    "praeclūdō, praeclūdere, praeclūsī, praeclūsum"
  ],
  "speed": [
    "mōbilitas, mōbilitātis",
    "mōbilitās, mōbilitātis",
    "vēlōcitās, vēlōcitātis",
    "festīnātiō, festīnātiōnis"
  ],
  "velocity": [
    "vēlōcitās, vēlōcitātis"
  ],
  "rapidity": [
    "mōbilitas, mōbilitātis",
    "mōbilitās, mōbilitātis",
    "vēlōcitās, vēlōcitātis"
  ],
  "fleetness": [
    "vēlōcitās, vēlōcitātis"
  ],
  "particular": [
    "particulāris, particulāre",
    "praecipuus, praecipua, praecipuum"
  ],
  "distinguished": [
    "īnsignis, īnsigne",
    "ēminēns, ēminentis",
    "excellēns, excellentis",
    "praecipuus, praecipua, praecipuum"
  ],
  "preeminent": [
    "praecipuus, praecipua, praecipuum"
  ],
  "run before": [
    "praecurrō, praecurrere, praecucurrī, praecursum"
  ],
  "loot": [
    "praedor, praedārī, praedātus sum"
  ],
  "such as through robbery or war": [
    "praedor, praedārī, praedātus sum"
  ],
  "throw or put to": [
    "ōbiciō, ōbicere, ōbiēcī, ōbiectum"
  ],
  "in front of or before": [
    "ōbiciō, ōbicere, ōbiēcī, ōbiectum"
  ],
  "exercise": [
    "exercitātiō, exercitātiōnis",
    "exerceō, exercēre, exercuī, exercitum",
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum",
    "exercitō, exercitāre, exercitāvī, exercitātum"
  ],
  "practice or train": [
    "exercitō, exercitāre, exercitāvī, exercitātum"
  ],
  "serve in the army": [
    "exercitō, exercitāre, exercitāvī, exercitātum"
  ],
  "call someone as a witness": [
    "obtestor, obtestārī, obtestātus sum"
  ],
  "make equal to": [
    "adaequō, adaequāre, adaequāvī, adaequātum"
  ],
  "equalize": [
    "adaequō, adaequāre, adaequāvī, adaequātum",
    "exaequō, exaequāre, exaequāvī, exaequātum"
  ],
  "level with": [
    "adaequō, adaequāre, adaequāvī, adaequātum"
  ],
  "military service for pay": [
    "latrōcinium, latrōciniī"
  ],
  "of or pertaining to a father": [
    "paternus, paterna, paternum"
  ],
  "paternal": [
    "patrius, patria, patrium",
    "paternus, paterna, paternum"
  ],
  "fatherly": [
    "patrius, patria, patrium",
    "paternus, paterna, paternum"
  ],
  "retain": [
    "reservō, reservāre, reservāvī, reservātum"
  ],
  "long duration": [
    "diūturnitas, diūturnitātis"
  ],
  "durability": [
    "firmitūdō, firmitūdinis",
    "diūturnitas, diūturnitātis"
  ],
  "hopelessness": [
    "dēspērātiō, dēspērātiōnis"
  ],
  "desperation": [
    "dēspērātiō, dēspērātiōnis"
  ],
  "beak": [
    "rōstrum, rōstrī"
  ],
  "a sum of money deposited in pledge by two individuals involved in a suit": [
    "sacrāmentum, sacrāmentī"
  ],
  "insurrection": [
    "sēditiō, sēditiōnis"
  ],
  "dissension": [
    "sēditiō, sēditiōnis",
    "discordia, discordiae"
  ],
  "mutiny": [
    "sēditiō, sēditiōnis"
  ],
  "sedition": [
    "sēditiō, sēditiōnis"
  ],
  "rebellion": [
    "sēditiō, sēditiōnis",
    "rebelliō, rebelliōnis"
  ],
  "boundless": [
    "immēnsus, immēnsa, immēnsum",
    "īnfīnītus, īnfīnīta, īnfīnītum"
  ],
  "unlimited": [
    "īnfīnītus, īnfīnīta, īnfīnītum"
  ],
  "endless": [
    "immēnsus, immēnsa, immēnsum",
    "īnfīnītus, īnfīnīta, īnfīnītum"
  ],
  "infinite": [
    "īnfīnītus, īnfīnīta, īnfīnītum"
  ],
  "defence": [
    "dēfēnsiō, dēfēnsiōnis",
    "mūnīmentum, mūnīmentī",
    "patrōcinium, patrōciniī"
  ],
  "diligence": [
    "dīligentia, dīligentiae"
  ],
  "attentiveness": [
    "dīligentia, dīligentiae"
  ],
  "training": [
    "Discens",
    "disciplīna, disciplīnae",
    "exercitātiō, exercitātiōnis"
  ],
  "practice discipline": [
    "exercitātiō, exercitātiōnis"
  ],
  "of freedom": [
    "līberālis, līberāle"
  ],
  "dignified": [
    "līberālis, līberāle"
  ],
  "honorable": [
    "līberālis, līberāle",
    "honestus, honesta, honestum"
  ],
  "befitting a freedman": [
    "līberālis, līberāle"
  ],
  "beseech. appeal to": [
    "implōrō, implōrāre, implōrāvī, implōrātum"
  ],
  "pray to": [
    "implōrō, implōrāre, implōrāvī, implōrātum"
  ],
  "measure or estimate": [
    "mētior, mētīrī, mēnsus sum"
  ],
  "lend or hire": [
    "commodō, commodāre, commodāvī, commodātum"
  ],
  "provide or bestow": [
    "commodō, commodāre, commodāvī, commodātum"
  ],
  "adapt or accommodate": [
    "commodō, commodāre, commodāvī, commodātum"
  ],
  "halt or stop": [
    "subsistō, subsistere, substitī"
  ],
  "avenge": [
    "ulcīscor, ulcīscī, ultus sum",
    "ulciscō, ulciscere, ulciscī, ultum",
    "vindicō, vindicāre, vindicāvī, vindicātum"
  ],
  "vindicate": [
    "vindicō, vindicāre, vindicāvī, vindicātum"
  ],
  "multiply by two": [
    "duplicō, duplicāre, duplicāvī, duplicātum"
  ],
  "duplicate": [
    "duplicō, duplicāre, duplicāvī, duplicātum"
  ],
  "stick or thrust": [
    "dēfīgō, dēfīgere, dēfīxī, dēfīxum"
  ],
  "poison": [
    "Venenum",
    "īnficiō, īnficere, īnfēcī, īnfectum"
  ],
  "taint": [
    "īnficiō, īnficere, īnfēcī, īnfectum"
  ],
  "corrupt": [
    "īnficiō, īnficere, īnfēcī, īnfectum",
    "adulterō, adulterāre, adulterāvī, adulterātum"
  ],
  "uneven": [
    "impar, imparis",
    "asper, aspera, asperum",
    "inīquus, inīqua, inīquum",
    "cōnfragōsus, cōnfragōsa, cōnfragōsum"
  ],
  "coarse": [
    "asper, aspera, asperum"
  ],
  "ripen": [
    "mātūrō, mātūrāre, mātūrāvī, mātūrātum"
  ],
  "make ripe": [
    "mātūrō, mātūrāre, mātūrāvī, mātūrātum"
  ],
  "bring to maturity": [
    "mātūrō, mātūrāre, mātūrāvī, mātūrātum"
  ],
  "surround with liquid": [
    "circumfundō, circumfundere, circumfudī, circumfusum"
  ],
  "be under": [
    "subsum, subesse, subfuī, subfutūrus"
  ],
  "among or behind": [
    "subsum, subesse, subfuī, subfutūrus"
  ],
  "of this sort": [
    "eiusmodi"
  ],
  "perseverance": [
    "cōnstantia, cōnstantiae",
    "pertinācia, pertināciae"
  ],
  "constance": [
    "pertinācia, pertināciae"
  ],
  "stubbornness": [
    "pertinācia, pertināciae"
  ],
  "pelt": [
    "pellis, pellis"
  ],
  "animal skin": [
    "pellis, pellis"
  ],
  "opportune": [
    "opportūnus, opportūna, opportūnum",
    "tempestīvus, tempestīva, tempestīvum"
  ],
  "defeat decisively": [
    "dēvincō, dēvincere, dēvicī, dēvictum"
  ],
  "conquer subdue": [
    "dēvincō, dēvincere, dēvicī, dēvictum"
  ],
  "restore or renew": [
    "redintegrō, redintegrāre, redintegrāvī, redintegrātum"
  ],
  "refresh or revive": [
    "redintegrō, redintegrāre, redintegrāvī, redintegrātum"
  ],
  "a partaker in guilt": [
    "correus, correī"
  ],
  "a joint criminal": [
    "correus, correī"
  ],
  "rafters floor": [
    "contīgnātiō, contīgnātiōnis"
  ],
  "rope": [
    "fūnis, fūnis",
    "rudēns, rudentis"
  ],
  "cord": [
    "fūnis, fūnis",
    "rudēns, rudentis",
    "fūniculus, fūniculī"
  ],
  "shrewdness": [
    "sollertia, sollertiae",
    "calliditās, calliditātis"
  ],
  "ingenuity": [
    "sollertia, sollertiae"
  ],
  "admonish. suggest": [
    "admoneō, admonēre, admonuī, admonitum"
  ],
  "uncertainty wavering": [
    "dubitātiō, dubitātiōnis"
  ],
  "hesitation questioning": [
    "dubitātiō, dubitātiōnis"
  ],
  "betrayal": [
    "prōditiō, prōditiōnis"
  ],
  "treason": [
    "prōditiō, prōditiōnis",
    "perduelliō, perduelliōnis"
  ],
  "of the house": [
    "domesticus, domestica, domesticum"
  ],
  "familiar": [
    "Familiaris",
    "familiāris, familiāre",
    "domesticus, domestica, domesticum"
  ],
  "firmly": [
    "firmiter, firmius, firmissimē",
    "cōnstanter, cōnstantius, cōnstantissimē"
  ],
  "block": [
    "Interpone",
    "officiō, officere, offēcī, offectum",
    "obstruō, obstruere, obstruxī, obstructum"
  ],
  "barricade": [
    "obstruō, obstruere, obstruxī, obstructum",
    "commūniō, commūnīre, commūnīvī, commūnītum"
  ],
  "obstruct": [
    "officiō, officere, offēcī, offectum",
    "obstruō, obstruere, obstruxī, obstructum"
  ],
  "so as": [
    "sīcutī"
  ],
  "bind": [
    "Deligere",
    "innectō, innectere, innexuī, innexum",
    "religō, religāre, religāvī, religātum",
    "dēstinō, dēstināre, dēstināvī, dēstinātum",
    "obstringō, obstringere, obstrinxī, obstrictum"
  ],
  "be conscious of. know well": [
    "cōnsciō, cōnscīre, cōnscīvī, cōnscītum"
  ],
  "cruelty": [
    "crūdēlitās, crūdēlitātis"
  ],
  "severity": [
    "sevēritās, sevēritātis",
    "crūdēlitās, crūdēlitātis"
  ],
  "a license": [
    "licentia, licentiae"
  ],
  "unite": [
    "congruō, congruere, congruī",
    "sociō, sociāre, sociāvī, sociātum",
    "cōniciō, cōnicere, cōniēcī, coniectum",
    "coniūrō, coniūrāre, coniūrāvī, coniūrātum",
    "conciliō, conciliāre, conciliāvī, conciliātum",
    "continuō, continuāre, continuāvī, continuātum",
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "for some time": [
    "aliquamdiū"
  ],
  "for some distance": [
    "aliquamdiū"
  ],
  "for a while": [
    "aliquamdiū"
  ],
  "hap": [
    "temeritās, temeritātis"
  ],
  "temerity": [
    "temeritās, temeritātis"
  ],
  "uniformity": [
    "aequitās, aequitātis",
    "aequālitās, aequālitātis",
    "aequābilitās, aequābilitātis"
  ],
  "fairness": [
    "iūstitia, iūstitiae",
    "aequitās, aequitātis"
  ],
  "praise or extol": [
    "collaudō, collaudāre, collaudāvī, collaudātum"
  ],
  "shrine": [
    "fānum, fānī",
    "adytum, adytī",
    "dēlūbrum, dēlūbrī",
    "sacrārium, sacrāriī"
  ],
  "sanctuary": [
    "Fanum",
    "fānum, fānī",
    "sacrārium, sacrāriī"
  ],
  "lead in": [
    "intrōdūcō, intrōdūcere, intrōdūxī, intrōductum"
  ],
  "conduct into. introduce": [
    "intrōdūcō, intrōdūcere, intrōdūxī, intrōductum"
  ],
  "pierce or transfix": [
    "peragō, peragere, perēgī, peractum"
  ],
  "movement": [
    "Movement",
    "mōtus, mōtūs",
    "mōmentum, mōmentī",
    "agitātiō, agitātiōnis",
    "commōtiō, commōtiōnis"
  ],
  "motion": [
    "mōtus, mōtūs",
    "mōmentum, mōmentī",
    "agitātiō, agitātiōnis",
    "commōtiō, commōtiōnis"
  ],
  "impulse": [
    "pulsus, pulsūs",
    "mōmentum, mōmentī",
    "impulsus, impulsūs"
  ],
  "by name": [
    "nominātim"
  ],
  "appear or originate": [
    "coorior, coorīrī, coortus sum"
  ],
  "twig": [
    "vīmen, vīminis",
    "surculus, surculī"
  ],
  "shoot": [
    "vīmen, vīminis",
    "germen, germinis",
    "subolēs, subolis",
    "surculus, surculī",
    "asparagus, asparagī",
    "sarmentum, sarmentī"
  ],
  "entwine or braid": [
    "contexō, contexere, contexuī, contextum"
  ],
  "in the daytime": [
    "interdiū"
  ],
  "by day": [
    "interdiū"
  ],
  "softness": [
    "lēnitās, lēnitātis"
  ],
  "smoothness": [
    "lēnitās, lēnitātis"
  ],
  "apart": [
    "seorsus, seorsa, seorsum",
    "sēparātim, sēparātius, sēparātissimē"
  ],
  "separately": [
    "sēparātim, sēparātius, sēparātissimē"
  ],
  "asunder": [
    "sēparātim, sēparātius, sēparātissimē"
  ],
  "write in front": [
    "praescrībō, praescrībere, praescrīpsī, praescrīptum"
  ],
  "prescribe": [
    "praefiniō, praefinīre, praefinīvī, praefinītum",
    "praescrībō, praescrībere, praescrīpsī, praescrīptum"
  ],
  "throw stones at": [
    "lapidō, lapidāre, lapidāvī, lapidātum"
  ],
  "hide or cover up": [
    "contegō, contegere, contēxī, contēctum"
  ],
  "fall forward": [
    "prōcumbō, prōcumbere, prōcubuī, prōcubitum"
  ],
  "prostrate": [
    "prōcumbō, prōcumbere, prōcubuī, prōcubitum"
  ],
  "lie or sleep outdoors": [
    "excubō, excubāre, excubuī, excubitum"
  ],
  "invite or summon someone to a place": [
    "advocō, advocāre, advocāvī, advocātum"
  ],
  "nefarious": [
    "nefārius, nefāria, nefārium"
  ],
  "execrable": [
    "dētestābilis, dētestābile",
    "exsēcrābilis, exsēcrābile",
    "nefārius, nefāria, nefārium"
  ],
  "abominable": [
    "abōminābilis, abōminābile",
    "dētestābilis, dētestābile",
    "īnfandus, īnfanda, īnfandum",
    "nefārius, nefāria, nefārium"
  ],
  "ballast a vessel": [
    "saburrō, saburrāre, saburrāvī, saburrātum"
  ],
  "cram full": [
    "saburrō, saburrāre, saburrāvī, saburrātum"
  ],
  "stuff full": [
    "saburrō, saburrāre, saburrāvī, saburrātum"
  ],
  "lively": [
    "hilaris, hilare",
    "alacer, alacris, alacre",
    "fēstīvus, fēstīva, fēstīvum"
  ],
  "uphold": [
    "sustentō, sustentāre, sustentāvī, sustentātum"
  ],
  "coat of arms": [
    "īnsigne, īnsignis"
  ],
  "ensign": [
    "īnsigne, īnsignis"
  ],
  "mercenary": [
    "Mercenarius",
    "lātrō, lātrōnis",
    "mercēnnārius, mercēnnāria, mercēnnārium",
    "stipendiārius, stipendiāria, stipendiārium"
  ],
  "tax": [
    "portōrium, portōriī",
    "vectīgal, vectīgālis",
    "vectīgālis, vectīgāle"
  ],
  "subject to tax": [
    "vectīgālis, vectīgāle"
  ],
  "reindeer skin": [
    "rēnō, rēnōnis"
  ],
  "a friendly land": [
    "pācātum, pācātī"
  ],
  "plunder": [
    "praeda, -ae f",
    "fūror, fūrārī, fūrātus sum",
    "dēpopulor, dēpopulārī, dēpopulāvī",
    "expīlō, expīlāre, expīlāvī, expīlātum",
    "dēpopulō, dēpopulāre, dēpopulāvī, dēpopulātum"
  ],
  "pillage": [
    "dēpopulor, dēpopulārī, dēpopulāvī",
    "expīlō, expīlāre, expīlāvī, expīlātum",
    "dēpopulō, dēpopulāre, dēpopulāvī, dēpopulātum"
  ],
  "despoil": [
    "dēpopulor, dēpopulārī, dēpopulāvī",
    "expīlō, expīlāre, expīlāvī, expīlātum",
    "dēpopulō, dēpopulāre, dēpopulāvī, dēpopulātum"
  ],
  "in three parts": [
    "tripertītō"
  ],
  "construct": [
    "struō, struere, struxī, structum",
    "compōnō, compōnere, composuī, compositum",
    "fabricō, fabricāre, fabricāvī, fabricātum"
  ],
  "build ready": [
    "struō, struere, struxī, structum"
  ],
  "prepare place": [
    "struō, struere, struxī, structum"
  ],
  "make four-cornered": [
    "quadrō, quadrāre, quadrāvī, quadrātum"
  ],
  "square": [
    "quadrō, quadrāre, quadrāvī, quadrātum"
  ],
  "make square": [
    "quadrō, quadrāre, quadrāvī, quadrātum"
  ],
  "copious": [
    "cōpiōsus, cōpiōsa, cōpiōsum"
  ],
  "prolific": [
    "cōpiōsus, cōpiōsa, cōpiōsum"
  ],
  "eloquent": [
    "argūtus, argūta, argūtum",
    "cōpiōsus, cōpiōsa, cōpiōsum",
    "disertus, diserta, disertum",
    "fācundus, fācunda, fācundum"
  ],
  "contrivance": [
    "māchinātiō, māchinātiōnis"
  ],
  "machine": [
    "māchina, māchinae",
    "māchinātiō, māchinātiōnis"
  ],
  "engine machination": [
    "māchinātiō, māchinātiōnis"
  ],
  "artifice": [
    "dolus, dolī",
    "calumnia, calumniae",
    "māchinātiō, māchinātiōnis"
  ],
  "keep a secret": [
    "cēlō, cēlāre, cēlāvī, cēlātum"
  ],
  "ride around": [
    "circumvehor, circumvehī, circumvectus"
  ],
  "turn across or away": [
    "trānsvertō, trānsvertere, trānsvertī, trānsversum"
  ],
  "grind or mill": [
    "molō, molere, moluī, molitum"
  ],
  "of or pertaining to the earth or land": [
    "terrestris, terrestre"
  ],
  "terrestrial": [
    "terrestris, terrestre"
  ],
  "earthly": [
    "terrestris, terrestre",
    "terrēnus, terrēna, terrēnum"
  ],
  "persevering": [
    "pertinax, pertinācis"
  ],
  "obstinate": [
    "contumāx, contumācis",
    "pertinax, pertinācis"
  ],
  "waver": [
    "trepidō, trepidāre, trepidāvī, trepidātum"
  ],
  "congratulation rejoicing": [
    "grātulātiō, grātulātiōnis"
  ],
  "usefulness": [
    "ūtilitās, ūtilitātis"
  ],
  "utility expediency advantage": [
    "ūtilitās, ūtilitātis"
  ],
  "successive": [
    "alternus, alterna, alternum",
    "continuus, continua, continuum"
  ],
  "strive or grasp after": [
    "appetō, appetere, appetīvī, appetītum"
  ],
  "lustrous": [
    "illūstris, illūstre"
  ],
  "flag": [
    "Vexillum",
    "vexillum, vexillī"
  ],
  "banner": [
    "vexillum, vexillī"
  ],
  "having three banks of oars": [
    "trirēmis, trirēme"
  ],
  "trireme": [
    "trirēmis, trirēme"
  ],
  "limp": [
    "claudeō, claudēre",
    "claudicō, claudicāre, claudicāvī, claudicātum"
  ],
  "be lame": [
    "claudeō, claudēre"
  ],
  "inflate": [
    "īnflō, īnflāre, īnflāvī, īnflātum"
  ],
  "blow into. play a wind instrument": [
    "īnflō, īnflāre, īnflāvī, īnflātum"
  ],
  "suppliant": [
    "supplex, supplicis"
  ],
  "exhibition": [
    "expositiō, expositiōnis",
    "ostentātiō, ostentātiōnis"
  ],
  "showing off": [
    "ostentātiō, ostentātiōnis"
  ],
  "enmity": [
    "inimīcitia, inimīcitiae"
  ],
  "hostility": [
    "inimīcitia, inimīcitiae"
  ],
  "ill will": [
    "inimīcitia, inimīcitiae"
  ],
  "defile": [
    "foedō, foedāre, foedāvī, foedātum",
    "polluō, polluere, polluī, pollūtum",
    "collinō, collinere, collēvī, collitum",
    "incestō, incestāre, incestāvī, incestātum",
    "inquinō, inquināre, inquināvī, inquinātum",
    "scelerō, scelerāre, scelerāvī, scelerātum",
    "adulterō, adulterāre, adulterāvī, adulterātum",
    "coinquinō, coinquināre, coinquināvī, coinquinātum"
  ],
  "write out": [
    "dēscrībō, dēscrībere, dēscrīpsī, dēscrīptum",
    "perscrībō, perscrībere, perscrīpsī, perscrīptum"
  ],
  "write in full or in detail": [
    "perscrībō, perscrībere, perscrīpsī, perscrīptum"
  ],
  "purge": [
    "pūrgō, pūrgāre, pūrgāvī, pūrgātum",
    "castrō, castrāre, castrāvī, castrātum"
  ],
  "disaster": [
    "perniciēs, perniciēī"
  ],
  "corner": [
    "angulus, angulī"
  ],
  "angle": [
    "angulus, angulī"
  ],
  "a leap": [
    "saltus, saltūs"
  ],
  "a leaping": [
    "saltus, saltūs",
    "tripudium, tripudiī or tripudī"
  ],
  "also forrest": [
    "saltus, saltūs"
  ],
  "dale": [
    "saltus, saltūs"
  ],
  "a forager": [
    "pābulātor, pābulātōris"
  ],
  "unaccustomed": [
    "īnsuētus, īnsuēta, īnsuētum",
    "īnsolitus, īnsolita, īnsolitum"
  ],
  "strip off": [
    "dēstringō, dēstringere, dēstrinxī, dēstrictum"
  ],
  "scrape": [
    "dēstringō, dēstringere, dēstrinxī, dēstrictum"
  ],
  "harass": [
    "afflīctō, afflīctāre, afflīctāvī, afflīctātum",
    "cōnflictō, cōnflictāre, cōnflictāvī, cōnflictātum"
  ],
  "distress or torment": [
    "cōnflictō, cōnflictāre, cōnflictāvī, cōnflictātum"
  ],
  "dart": [
    "Tela",
    "iaculum, iaculī",
    "trāgula, trāgulae"
  ],
  "craft": [
    "Fac",
    "artificium, artificiī"
  ],
  "trade": [
    "artificium, artificiī",
    "commercium, commerciī",
    "mercātūra, mercātūrae"
  ],
  "transcend": [
    "trānscendō, trānscendere, trānscendī, trānscēnsum"
  ],
  "fly to or toward": [
    "advolō, advolāre, advolāvī, advolātum"
  ],
  "a magistrate": [
    "magistrātus, magistrātūs"
  ],
  "official a magisterial office": [
    "magistrātus, magistrātūs"
  ],
  "civil office": [
    "magistrātus, magistrātūs"
  ],
  "magistracy": [
    "magistrātus, magistrātūs"
  ],
  "uncommon": [
    "inūsitātus, inūsitāta, inūsitātum"
  ],
  "extraordinary": [
    "notābilis, notābile",
    "inūsitātus, inūsitāta, inūsitātum",
    "extrāordinārius, extrāordināria, extrāordinārium"
  ],
  "overhead": [
    "īnsuper"
  ],
  "thrust": [
    "petītiō, petītiōnis",
    "impressiō, impressiōnis",
    "trūdō, trūdere, trūsī, trūsum",
    "prōiciō, prōicere, prōiēcī, prōiectum"
  ],
  "fling or hurl forth": [
    "prōiciō, prōicere, prōiēcī, prōiectum"
  ],
  "away or out": [
    "prōiciō, prōicere, prōiēcī, prōiectum"
  ],
  "fall together": [
    "concidō, concidere, concidī"
  ],
  "collapse": [
    "ruīna, ruīnae",
    "concidō, concidere, concidī",
    "prōcidō, prōcidere, prōcidī",
    "succidō, succidere, succidī",
    "dēcidō, dēcidere, dēcidī, dēcāsum",
    "corruō, corruere, corruī, corrutum"
  ],
  "contempt": [
    "contemptiō, contemptiōnis"
  ],
  "disdain": [
    "contemptiō, contemptiōnis",
    "indignātiō, indignātiōnis"
  ],
  "dust": [
    "pulvis, pulveris"
  ],
  "powder": [
    "pulvis, pulveris"
  ],
  "newness": [
    "novitās, novitātis"
  ],
  "novelty": [
    "novitās, novitātis",
    "īnsolentia, īnsolentiae"
  ],
  "a fagot": [
    "fascis, fascis"
  ],
  "bundle": [
    "fascis, fascis"
  ],
  "packet": [
    "fascis, fascis"
  ],
  "parcel": [
    "fascis, fascis"
  ],
  "roll": [
    "volūmen, volūminis",
    "rotō, rotāre, rotāvī, rotātum",
    "volvō, volvere, volvī, volūtum",
    "volūtō, volūtāre, volūtāvī, volūtātum"
  ],
  "tumble": [
    "volvō, volvere, volvī, volūtum"
  ],
  "bring together": [
    "cōnferō, cōnferre, contulī, collātum",
    "congerō, congerere, congessī, congestum"
  ],
  "collect or accumulate compile heap or lavish": [
    "congerō, congerere, congessī, congestum"
  ],
  "faintness": [
    "languor, languōris",
    "lassitūdō, lassitūdinis"
  ],
  "weariness": [
    "taedium, taediī",
    "lassitūdō, lassitūdinis",
    "dēfatīgātiō, dēfatīgātiōnis"
  ],
  "exhaustion": [
    "lassitūdō, lassitūdinis"
  ],
  "lassitude": [
    "lassitūdō, lassitūdinis"
  ],
  "dash": [
    "afflīgō, afflīgere, afflīxī, afflīctum"
  ],
  "the sea": [
    "salum, salī",
    "pelagus, pelagī"
  ],
  "a long trumpet over 1 meter in length. tube": [
    "tuba, tubae"
  ],
  "a corpse": [
    "cadāver, cadāveris"
  ],
  "cadaver": [
    "cadāver, cadāveris"
  ],
  "carcass": [
    "cadāver, cadāveris"
  ],
  "tithe": [
    "decima, decimae"
  ],
  "repress": [
    "reprimō, reprimere, repressī, repressum"
  ],
  "prevent or restrain": [
    "reprimō, reprimere, repressī, repressum"
  ],
  "restore or revive": [
    "renovō, renovāre, renovāvī, renovātum"
  ],
  "instruct or inform thoroughly": [
    "ēdoceō, ēdocēre, ēdocuī, ēdoctum"
  ],
  "afford": [
    "sufficiō, sufficere, suffēcī, suffectum",
    "perhibeō, perhibēre, perhibuī, perhibitum",
    "subministrō, subministrāre, subministrāvī, subministrātum"
  ],
  "constant": [
    "assiduus, assidua, assiduum"
  ],
  "regular": [
    "assiduus, assidua, assiduum",
    "compositus, composita, compositum",
    "ōrdinārius, ōrdināria, ōrdinārium"
  ],
  "dense": [
    "dēnsus, dēnsa, dēnsum",
    "spissus, spissa, spissum"
  ],
  "compact": [
    "dēnsus, dēnsa, dēnsum",
    "spissus, spissa, spissum"
  ],
  "crowded": [
    "dēnsus, dēnsa, dēnsum",
    "spissus, spissa, spissum"
  ],
  "clothe in a cuirass": [
    "lōrīcō, lōrīcāre, lōrīcāvī, lōrīcātum"
  ],
  "breastplate": [
    "lōrīcō, lōrīcāre, lōrīcāvī, lōrīcātum"
  ],
  "watch. guard": [
    "observō, observāre, observāvī, observātum"
  ],
  "keep watch over. heed": [
    "observō, observāre, observāvī, observātum"
  ],
  "respect. pay attention to. notice": [
    "observō, observāre, observāvī, observātum"
  ],
  "send or let in or into": [
    "intrōmittō, intrōmittere, intrōmīsī, intrōmissum"
  ],
  "admit introduce": [
    "intrōmittō, intrōmittere, intrōmīsī, intrōmissum"
  ],
  "extract": [
    "exuō, exuere, exuī, exūtum",
    "extrahō, extrahere, extraxī, extractum",
    "tribulō, tribulāre, tribulāvī, tribulātum"
  ],
  "take out. free. take off": [
    "exuō, exuere, exuī, exūtum"
  ],
  "doff. refuse": [
    "exuō, exuere, exuī, exūtum"
  ],
  "an onrush": [
    "incursiō, incursiōnis"
  ],
  "an attack": [
    "petītiō, petītiōnis",
    "appetītus, appetītūs",
    "incursiō, incursiōnis"
  ],
  "rug or garment made from goat hair": [
    "cilicium, ciliciī"
  ],
  "a falling down": [
    "ruīna, ruīnae"
  ],
  "destruction ruins": [
    "ruīna, ruīnae"
  ],
  "debris": [
    "Debris",
    "ruīna, ruīnae"
  ],
  "carry under": [
    "suggerō, suggerere, suggessī, suggestum"
  ],
  "suggest": [
    "suggerō, suggerere, suggessī, suggestum"
  ],
  "pit": [
    "Arena",
    "puteus, puteī",
    "lacūna, lacūnae"
  ],
  "dungeon": [
    "puteus, puteī"
  ],
  "pull or draw forth or out": [
    "extrahō, extrahere, extraxī, extractum"
  ],
  "natural by birth": [
    "nātūrālis, nātūrāle"
  ],
  "legitimate": [
    "nātūrālis, nātūrāle",
    "lēgitimus, lēgitima, lēgitimum"
  ],
  "of or pertaining to nature": [
    "nātūrālis, nātūrāle"
  ],
  "prisoner of war captive": [
    "dēditīcius, dēditīcia, dēditīcium"
  ],
  "unbroken": [
    "continenter"
  ],
  "uninterruptedly": [
    "continenter"
  ],
  "sanity": [
    "sānitās, sānitātis"
  ],
  "faint": [
    "lassus, lassa, lassum",
    "languidus, languida, languidum"
  ],
  "dull": [
    "hebes, hebetis",
    "languidus, languida, languidum",
    "hebetō, hebetāre, hebetāvī, hebetātum"
  ],
  "languid": [
    "languidus, languida, languidum"
  ],
  "incautious": [
    "incautus, incauta, incautum"
  ],
  "unforeseen": [
    "incautus, incauta, incautum",
    "imprōvīsus, imprōvīsa, imprōvīsum"
  ],
  "testament": [
    "testāmentum, testāmentī"
  ],
  "mutinous": [
    "sēditiōsus, sēditiōsa, sēditiōsum"
  ],
  "quarrelsome": [
    "lītigiōsus, lītigiōsa, lītigiōsum",
    "sēditiōsus, sēditiōsa, sēditiōsum"
  ],
  "factious": [
    "factiōsus, factiōsa, factiōsum",
    "sēditiōsus, sēditiōsa, sēditiōsum"
  ],
  "troubled": [
    "ānxius, ānxia, ānxium",
    "sēditiōsus, sēditiōsa, sēditiōsum"
  ],
  "enclose on all sides": [
    "coerceō, coercēre, coercuī, coercitum"
  ],
  "encompass": [
    "coerceō, coercēre, coercuī, coercitum",
    "complexō, complexāre, complexāvī, complexātum",
    "circumsedeō, circumsedēre, circumsēdī, circumsessum"
  ],
  "disreputable": [
    "īnfāmis, īnfāme"
  ],
  "infamous": [
    "īnfāmis, īnfāme"
  ],
  "outward": [
    "externus, externa, externum"
  ],
  "external": [
    "externus, externa, externum"
  ],
  "alien": [
    "advena, advenae",
    "externus, externa, externum",
    "peregrīnus, peregrīna, peregrīnum",
    "aliēnigenus, aliēnigena, aliēnigenum"
  ],
  "throat": [
    "faucēs, faucium",
    "guttur, gutturis"
  ],
  "throw back": [
    "rēiciō, rēicere, rēiēcī, rēiectum"
  ],
  "be deprived of parents": [
    "orbō, orbāre, orbāvī, orbātum"
  ],
  "remains": [
    "Reliquiae",
    "rēliquiae, rēliquiārum"
  ],
  "relics": [
    "rēliquiae, rēliquiārum"
  ],
  "yard": [
    "antemna, antemnae",
    "antenna, antennae"
  ],
  "pole": [
    "polus, polī",
    "tēmō, tēmōnis",
    "antenna, antennae"
  ],
  "retreat": [
    "latēbra, latēbrae",
    "receptus, receptūs",
    "resiliō, resilīre, resiluī, resultum"
  ],
  "mutually": [
    "mūtuō"
  ],
  "in return": [
    "mūtuō"
  ],
  "or dissimulate a state of mind": [
    "dissimulō, dissimulāre, dissimulāvī, dissimulātum"
  ],
  "frankness": [
    "līberālitās, līberālitātis"
  ],
  "affability generosity": [
    "līberālitās, līberālitātis"
  ],
  "liberality": [
    "līberālitās, līberālitātis",
    "mūnificentia, mūnificentiae"
  ],
  "tribunal": [
    "tribūnal, tribūnālis"
  ],
  "judgment seat": [
    "tribūnal, tribūnālis"
  ],
  "concern": [
    "sollicitūdō, sollicitūdinis"
  ],
  "solicitude": [
    "sollicitūdō, sollicitūdinis"
  ],
  "seize or snatch up": [
    "corripiō, corripere, corripuī, correptum"
  ],
  "hew into shape": [
    "dolō, dolāre, dolāvī, dolātum"
  ],
  "throw open": [
    "patefaciō, patefacere, patefēcī, patefactum"
  ],
  "tribune": [
    "tribūnus, tribūnī",
    "tribūnīcius, tribūnīcia, tribūnīcium"
  ],
  "tetrarch": [
    "tetrarchēs, tetrarchae"
  ],
  "distinguished by a mark": [
    "īnsignis, īnsigne"
  ],
  "ignorance": [
    "inertia, inertiae",
    "īnscītia, īnscītiae",
    "ignōrātiō, ignōrātiōnis",
    "īnscientia, īnscientiae",
    "imprūdentia, imprūdentiae"
  ],
  "imprudence": [
    "imprūdentia, imprūdentiae"
  ],
  "administer": [
    "fungor, fungī, fūnctus sum",
    "prōcūrō, prōcūrāre, prōcūrāvī, prōcūrātum"
  ],
  "void": [
    "inānis, ināne",
    "irritus, irrita, irritum"
  ],
  "unprepared": [
    "imparātus, imparāta, imparātum"
  ],
  "unready": [
    "imparātus, imparāta, imparātum"
  ],
  "relationship": [
    "necessitūdō, necessitūdinis"
  ],
  "come before or in the way of": [
    "obveniō, obvenīre, obvēnī, obventum"
  ],
  "deliberate": [
    "commentor, commentārī, commentātus sum",
    "dēlīberō, dēlīberāre, dēlīberāvī, dēlīberātum"
  ],
  "send around": [
    "circummittō, circummittere, circummīsī, circummissum"
  ],
  "outflank": [
    "circummittō, circummittere, circummīsī, circummissum"
  ],
  "dissent": [
    "dissentiō, dissentīre, dissēnsī, dissēnsum"
  ],
  "disagree or differ": [
    "dissentiō, dissentīre, dissēnsī, dissēnsum"
  ],
  "turn or twist forwards or around": [
    "praetorqueō, praetorquēre, praetortum"
  ],
  "build or erect": [
    "ērigō, ērigere, ērexī, ērectum"
  ],
  "go apart or asunder": [
    "dīgredior, dīgredī, dīgressus sum"
  ],
  "arrangement": [
    "locātiō, locātiōnis",
    "collocātiō, collocātiōnis",
    "compositiō, compositiōnis",
    "dispositiō, dispositiōnis",
    "īnstitūtiō, īnstitūtiōnis"
  ],
  "combination": [
    "complexiō, complexiōnis",
    "comparātiō, comparātiōnis",
    "compositiō, compositiōnis"
  ],
  "clash": [
    "cōnflictus, cōnflictūs",
    "concursō, concursāre, concursāvī, concursātum"
  ],
  "lead or draw around": [
    "circumdūcō, circumdūcere, circumdūxī, circumductum"
  ],
  "show around": [
    "circumdūcō, circumdūcere, circumdūxī, circumductum"
  ],
  "strike with terror": [
    "exterreō, exterrēre, exterruī, exterritum"
  ],
  "affright": [
    "exterreō, exterrēre, exterruī, exterritum"
  ],
  "scare": [
    "exterreō, exterrēre, exterruī, exterritum"
  ],
  "plateau": [
    "plānitiēs, plānitiēī"
  ],
  "skirmisher leader": [
    "antesignānus, antesignānī"
  ],
  "patch": [
    "sarciō, sarcīre, sarsī, sartum"
  ],
  "repair": [
    "Refice",
    "REPAIR",
    "sarciō, sarcīre, sarsī, sartum",
    "expiō, expiāre, expiāvī, expiātum"
  ],
  "or march forth": [
    "prōgredior, prōgredī, prōgressus sum"
  ],
  "forward or on": [
    "prōgredior, prōgredī, prōgressus sum"
  ],
  "bear under": [
    "sufferō, sufferre, sustulī, sublātum"
  ],
  "expense": [
    "sūmptus, sūmptūs",
    "impensa, impensae",
    "impendium, impendiī",
    "dispendium, dispendiī"
  ],
  "grown or used for fodder": [
    "pābulāris, pābulāre"
  ],
  "a gaulish or britannic charioteer": [
    "essedārius, essedariī"
  ],
  "a descent": [
    "dēscensus, dēscensūs"
  ],
  "of or relating to a thing held in trust": [
    "fīdūciārius, fīdūciāria, fīdūciārium"
  ],
  "fiduciary": [
    "fīdūciārius, fīdūciāria, fīdūciārium"
  ],
  "a defending": [
    "mūnītiō, mūnītiōnis"
  ],
  "fortifying": [
    "mūnītiō, mūnītiōnis"
  ],
  "protecting": [
    "mūnītiō, mūnītiōnis"
  ],
  "swiftly": [
    "cursim",
    "celeriter, celerius, celerrimē",
    "vēlōciter, vēlōcius, vēlōcissimē"
  ],
  "pluck out": [
    "vellō, vellere, vulsī, vellī, vulsum"
  ],
  "standing grain": [
    "sata, satōrum"
  ],
  "armor": [
    "Lorica",
    "LORICAE",
    "armātūra, armātūrae"
  ],
  "equipment of soldiers": [
    "armātūra, armātūrae"
  ],
  "gangway": [
    "forus, forī"
  ],
  "greatness": [
    "magnitūdō, magnitūdinis",
    "magnificentia, magnificentiae"
  ],
  "bulk": [
    "magnitūdō, magnitūdinis"
  ],
  "magnitude": [
    "Magnitudo",
    "magnitūdō, magnitūdinis"
  ],
  "vastness": [
    "immānitās, immānitātis",
    "magnitūdō, magnitūdinis"
  ],
  "extent": [
    "magnitūdō, magnitūdinis",
    "longinquitās, longinquitātis"
  ],
  "almost all": [
    "plērus, plēra, plērum"
  ],
  "an eruption": [
    "ēruptiō, ēruptiōnis"
  ],
  "contracted": [
    "adductus, adducta, adductum"
  ],
  "compressed": [
    "adductus, adducta, adductum"
  ],
  "african": [
    "āfricus, āfrica, āfricum",
    "āfricānus, āfricāna, āfricānum"
  ],
  "progress": [
    "prōcessus, prōcessūs",
    "prōfectus, prōfectūs"
  ],
  "effect": [
    "prōfectus, prōfectūs",
    "efficiō, efficere, effēcī, effectum"
  ],
  "growth": [
    "augmentum, augmentī",
    "prōfectus, prōfectūs",
    "prōventus, prōventūs",
    "incrēmentum, incrēmentī"
  ],
  "success": [
    "Successum",
    "prōfectus, prōfectūs",
    "prosperitās, prosperitātis"
  ],
  "with itself": [
    "sēcum"
  ],
  "with himself": [
    "sēcum"
  ],
  "with herself": [
    "sēcum"
  ],
  "with themselves": [
    "sēcum"
  ],
  "transport or conduct across or over something": [
    "trādūcō, trādūcere, trādūxī, trāductum"
  ],
  "by night": [
    "noctū"
  ],
  "confide in": [
    "cōnfīdō, cōnfīdere, cōnfīsus sum"
  ],
  "do for the third time": [
    "tertiō, tertiāre, tertiāvī, tertiātum"
  ],
  "thrice": [
    "tertiō, tertiāre, tertiāvī, tertiātum"
  ],
  "a portion of land granted by the state": [
    "accepta, acceptae"
  ],
  "palisade": [
    "vallus, vallī"
  ],
  "adjust": [
    "secundō, secundāre, secundāvī, secundātum"
  ],
  "adapt": [
    "secundō, secundāre, secundāvī, secundātum"
  ],
  "accommodate": [
    "secundō, secundāre, secundāvī, secundātum"
  ],
  "narrowness": [
    "angustia, angustiae",
    "brevitās, brevitātis"
  ],
  "straitness": [
    "angustia, angustiae"
  ],
  "heavily": [
    "graviter, gravius, gravissimē"
  ],
  "weightily": [
    "graviter, gravius, gravissimē"
  ],
  "ponderously": [
    "graviter, gravius, gravissimē"
  ],
  "one of the three original tribes of rome: ramnes": [
    "tribus, tribūs"
  ],
  "tities": [
    "tribus, tribūs"
  ],
  "luceres. tribe": [
    "tribus, tribūs"
  ],
  "place in command": [
    "praeficiō, praeficere, praefēcī, praefectum"
  ],
  "draw to oneself": [
    "addūcō, addūcere, addūxī, adductum"
  ],
  "officer": [
    "praefectus, praefectī",
    "officiārius, officiāriī"
  ],
  "violently": [
    "atrōciter, atrōcius, atrōcissimē",
    "violenter, violentius, violentissimē",
    "vehementer, vehementius, vehementissimē"
  ],
  "forcefully": [
    "potenter, potentius, potentissimē",
    "vehementer, vehementius, vehementissimē"
  ],
  "loudly": [
    "vehementer, vehementius, vehementissimē"
  ],
  "that which is commanded": [
    "imperātum, imperātī"
  ],
  "a command": [
    "imperātum, imperātī"
  ],
  "limiting": [
    "continēns, continentis"
  ],
  "enclosing": [
    "continēns, continentis"
  ],
  "powerfully": [
    "fortiter, fortius, fortissimē",
    "potenter, potentius, potentissimē"
  ],
  "meeting": [
    "coetus, coetūs",
    "coitiō, coitiōnis",
    "cōntiō, cōntiōnis",
    "occursus, occursūs",
    "concilium, conciliī",
    "conventus, conventūs",
    "cōnstitūtus, cōnstitūtūs",
    "congressiō, congressiōnis"
  ],
  "gathering": [
    "conventus, conventūs",
    "perceptiō, perceptiōnis"
  ],
  "congregation": [
    "synagōga, synagōgae",
    "conventus, conventūs"
  ],
  "judicial assembly": [
    "conventus, conventūs"
  ],
  "patrol": [
    "circuitus, circuitūs"
  ],
  "strive to seize": [
    "captō, captāre, captāvī, captātum"
  ],
  "catch or grasp at": [
    "captō, captāre, captāvī, captātum"
  ],
  "reside": [
    "resideō, residēre, resēdī, resessum",
    "habitō, habitāre, habitāvī, habitātum"
  ],
  "elated": [
    "sublātus, sublāta, sublātum"
  ],
  "lay bare": [
    "apertō, apertāre, apertāvī, apertātus"
  ],
  "fart": [
    "pēditum, pēditī"
  ],
  "give up": [
    "dēdō, dēdere, dēdidī, dēditum",
    "omittō, omittere, omīsī, omissum"
  ],
  "consign": [
    "dēdō, dēdere, dēdidī, dēditum"
  ],
  "gallic": [
    "gallicus, gallica, gallicum"
  ],
  "gaulish": [
    "gallicus, gallica, gallicum"
  ],
  "flee or escape": [
    "refugiō, refugere, refūgī"
  ],
  "the spirits of dead ancestors": [
    "mānēs, mānium"
  ],
  "deified shades": [
    "mānēs, mānium"
  ],
  "safely": [
    "tūtē, tūtius, tūtissimē",
    "tūtō, tūtius, tūtissimē"
  ],
  "securely": [
    "tūtē, tūtius, tūtissimē",
    "tūtō, tūtius, tūtissimē"
  ],
  "in safety": [
    "tūtē, tūtius, tūtissimē",
    "tūtō, tūtius, tūtissimē"
  ],
  "right hand": [
    "dextera",
    "dextra, dextrae"
  ],
  "make peaceful": [
    "pācō, pācāre, pācāvī, pācātum"
  ],
  "pacify": [
    "Pax",
    "Pacify",
    "pācō, pācāre, pācāvī, pācātum"
  ],
  "subdue": [
    "pācō, pācāre, pācāvī, pācātum",
    "dēbellō, dēbellāre, dēbellāvī, dēbellātum"
  ],
  "selection": [
    "dēlectus, dēlectūs",
    "dīlēctus, dīlēctūs",
    "ēlēctiō, ēlēctiōnis",
    "sēlēctiō, sēlēctiōnis"
  ],
  "choice": [
    "optiō, optiōnis",
    "dēlectus, dēlectūs",
    "dīlēctus, dīlēctūs",
    "ēlēctiō, ēlēctiōnis",
    "sēlēctiō, sēlēctiōnis"
  ],
  "distinction": [
    "dēlectus, dēlectūs",
    "dīlēctus, dīlēctūs",
    "discrīmen, discrīminis",
    "distinctiō, distinctiōnis"
  ],
  "somewhat": [
    "paullo",
    "aliquid"
  ],
  "to some extent": [
    "aliquid",
    "aliquātenus"
  ],
  "walking": [
    "pedester, pedestris, pedestre"
  ],
  "pedestrian": [
    "pedester, pedestris, pedestre"
  ],
  "on foot": [
    "pedester, pedestris, pedestre"
  ],
  "be eager": [
    "ārdeō, ārdēre, ārsī, ārsum",
    "gestiō, gestīre, gestīvī, gestitum"
  ],
  "exult": [
    "gestiō, gestīre, gestīvī, gestitum"
  ],
  "want more or instead": [
    "mālō, mālle, māluī"
  ],
  "a movement": [
    "mōtus, mōtūs"
  ],
  "boldly": [
    "audacter, audacius, audacissimē",
    "audāciter, audācius, audācissimē",
    "cōnfīdenter, cōnfīdentius, cōnfīdentissimē"
  ],
  "audaciously": [
    "audacter, audacius, audacissimē",
    "audāciter, audācius, audācissimē"
  ],
  "fearlessly": [
    "audacter, audacius, audacissimē"
  ],
  "exhausted": [
    "effētus, effēta, effētum",
    "dēfessus, dēfessa, dēfessum"
  ],
  "suspicion": [
    "suspīciō, suspīciōnis"
  ],
  "connected or compound word or proposition": [
    "coniunctum, coniunctī"
  ],
  "mass": [
    "mōlēs, mōlis",
    "acervus, acervī",
    "congeriēs, congeriēī"
  ],
  "freely": [
    "sponte",
    "līberē, līberius, līberissimē",
    "expedītē, expedītius, expedītissimē"
  ],
  "willingly": [
    "sponte",
    "grātē, grātius, grātissimē",
    "lubenter, lubentius, lubentissimē"
  ],
  "voluntarily": [
    "sponte"
  ],
  "spontaneously": [
    "sponte"
  ],
  "acquaintance": [
    "nōtiō, nōtiōnis",
    "cognitus, cognitūs"
  ],
  "require or order": [
    "oporteō, oportēre, oportuī, oportuitum"
  ],
  "test the strength": [
    "temptō, temptāre, temptāvī, temptātum"
  ],
  "make an attack upon": [
    "temptō, temptāre, temptāvī, temptātum"
  ],
  "constrain": [
    "coactō, coactāre, coactāvī, coactātum"
  ],
  "event": [
    "ēventus, ēventūs"
  ],
  "occurrence": [
    "ēventus, ēventūs"
  ],
  "reality": [
    "ēventus, ēventūs"
  ],
  "binary": [
    "bīnus, bīna, bīnum"
  ],
  "contiguous": [
    "confīnis, confīne",
    "coniunctus, coniuncta, coniunctum"
  ],
  "linked": [
    "coniunctus, coniuncta, coniunctum"
  ],
  "consensus": [
    "cōnsēnsus, cōnsēnsūs"
  ],
  "agreement": [
    "foedus, foederis",
    "pactiō, pactiōnis",
    "dēcīsio, dēcīsiōnis",
    "cōnsēnsus, cōnsēnsūs",
    "adsensiō, adsensiōnis",
    "cōnsēnsiō, cōnsēnsiōnis",
    "convenientia, convenientiae"
  ],
  "accordance": [
    "cōnsēnsus, cōnsēnsūs"
  ],
  "unanimity": [
    "cōnsēnsus, cōnsēnsūs",
    "cōnspīrātiō, cōnspīrātiōnis"
  ],
  "concord": [
    "cōnsēnsus, cōnsēnsūs",
    "concordia, concordiae",
    "cōnspīrātiō, cōnspīrātiōnis"
  ],
  "way of life": [
    "vīctus, vīctūs",
    "conversātiō, conversātiōnis"
  ],
  "celebrity": [
    "nōbilitās, nōbilitātis"
  ],
  "renown": [
    "nōbilitās, nōbilitātis"
  ],
  "deponent": [
    "dēpōnēns, dēpōnentis"
  ],
  "broadly": [
    "lātē, lātius, lātissimē"
  ],
  "widely": [
    "lātē, lātius, lātissimē"
  ],
  "spy": [
    "ēmissārius, ēmissāriī",
    "speculātor, speculātōris"
  ],
  "scout": [
    "Scout",
    "ēmissārius, ēmissāriī",
    "antecursor, antecursōris",
    "speculātor, speculātōris"
  ],
  "foot soldier": [
    "pedes -itis m",
    "peditātus, peditātūs"
  ],
  "infantry": [
    "peditātus, peditātūs"
  ],
  "flame": [
    "Flame",
    "ardor, ardōris",
    "flamma, flammae"
  ],
  "most or very remote": [
    "remōtus, remōta, remōtum"
  ],
  "setting": [
    "occāsus, occāsūs"
  ],
  "arbitration": [
    "arbitrātus, arbitrātūs"
  ],
  "arrival": [
    "accessus, accessūs"
  ],
  "a dart": [
    "iaculum, iaculī"
  ],
  "a javelin": [
    "iaculum, iaculī"
  ],
  "reduce or diminish": [
    "minōrō, minōrāre, minōrāvī, minōrātum"
  ],
  "turned or facing away": [
    "āversus, āversa, āversum"
  ],
  "somehow": [
    "aliquā"
  ],
  "rash": [
    "praeruptus, praerupta, praeruptum",
    "incōnsultus, incōnsulta, incōnsultum"
  ],
  "precipitate": [
    "praeruptus, praerupta, praeruptum",
    "praeproperus, praepropera, praeproperum"
  ],
  "truce": [
    "indūtia, indūtiae"
  ],
  "armistice": [
    "indūtia, indūtiae"
  ],
  "cessation": [
    "indūtia, indūtiae"
  ],
  "respite": [
    "Respitium",
    "indūtia, indūtiae",
    "cessātiō, cessātiōnis",
    "laxāmentum, laxāmentī"
  ],
  "sloping": [
    "dēclīvis, dēclīve"
  ],
  "descending": [
    "dēclīvis, dēclīve"
  ],
  "passage": [
    "Commeatus",
    "trānsitus, trānsitūs",
    "trānsitiō, trānsitiōnis"
  ],
  "crossing": [
    "trānsitus, trānsitūs"
  ],
  "low-lying": [
    "dēmissus, dēmissa, dēmissum"
  ],
  "scatter or disperse": [
    "dispergō, dispergere, dispersī, dispersum"
  ],
  "a throwing away": [
    "iactura, iacturae"
  ],
  "ramble": [
    "vagor, vagārī, vagātus sum"
  ],
  "stroll about": [
    "vagor, vagārī, vagātus sum"
  ],
  "somewhere": [
    "aliquō",
    "alicubi"
  ],
  "anywhere": [
    "aliquō",
    "uspiam",
    "alicubi",
    "quālibet"
  ],
  "turn around or over": [
    "conversō, conversāre, conversāvī, conversātum"
  ],
  "contribution": [
    "stips, stipis",
    "collēcta, collēctae"
  ],
  "collection": [
    "collēcta, collēctae",
    "collēctiō, collēctiōnis"
  ],
  "a decision": [
    "dēcrētum, dēcrētī"
  ],
  "ordinance": [
    "iussus, iussūs",
    "dēcrētum, dēcrētī"
  ],
  "heightened": [
    "ēditus, ēdita, ēditum"
  ],
  "set forth": [
    "ēditus, ēdita, ēditum",
    "expōnō, expōnere, exposuī, expositum"
  ],
  "of a forest or wood": [
    "silvestris, silvestre"
  ],
  "veteran called again to service": [
    "ēvocātus, evocatī"
  ],
  "examine closely": [
    "perspectō, perspectāre, perspectāvī, perspectātum"
  ],
  "throw in or on": [
    "iniciō, inicere, iniēcī, iniectum"
  ],
  "armour": [
    "tegumentum, tegumentī"
  ],
  "a swearing together": [
    "coniūrātiō, coniūrātiōnis"
  ],
  "the wall of a house or room": [
    "pariēs, parietis"
  ],
  "pulse": [
    "pulsus, pulsūs"
  ],
  "stroke": [
    "īctus, īctūs",
    "pulsus, pulsūs",
    "līneāmentum, līneāmentī",
    "mulceō, mulcēre, mulsī, mulsum",
    "palpō, palpāre, palpāvī, palpātum",
    "permulceō, permulcēre, permulsī, permulsum"
  ],
  "draw or pull out or forth": [
    "ēliciō, ēlicere, ēlicuī, ēlicitum"
  ],
  "desperate": [
    "dēspērātus, dēspērāta, dēspērātum"
  ],
  "hopeless": [
    "dēspērātus, dēspērāta, dēspērātum"
  ],
  "clemency": [
    "clēmentia, clēmentiae"
  ],
  "tribute": [
    "vectīgal, vectīgālis"
  ],
  "revenue": [
    "vectīgal, vectīgālis"
  ],
  "sharpened": [
    "praeacūtus, praeacūta, praeacūtum"
  ],
  "pointed": [
    "praeacūtus, praeacūta, praeacūtum"
  ],
  "flow or leak through": [
    "permānō, permānāre, permānāvī, permānātum"
  ],
  "permeate": [
    "permānō, permānāre, permānāvī, permānātum"
  ],
  "seek out": [
    "exquīrō, exquīrere, exquīsīvī, exquīsītum",
    "conquīrō, conquīrere, conquīsīvī, conquīsītus"
  ],
  "firmness": [
    "cōnstantia, cōnstantiae",
    "firmitūdō, firmitūdinis"
  ],
  "steadiness": [
    "cōnstantia, cōnstantiae",
    "stabilitās, stabilitātis"
  ],
  "constancy": [
    "cōnstantia, cōnstantiae",
    "persevērantia, persevērantiae"
  ],
  "make a mark": [
    "notō, notāre, notāvī, notātum"
  ],
  "undertaking": [
    "coeptus, coeptūs"
  ],
  "repulsively": [
    "turpiter, turpius, turpissimē"
  ],
  "knowledge shared with others": [
    "cōnscientia, cōnscientiae"
  ],
  "being in the know or privy to": [
    "cōnscientia, cōnscientiae"
  ],
  "joint knowledge": [
    "cōnscientia, cōnscientiae"
  ],
  "complicity": [
    "cōnscientia, cōnscientiae"
  ],
  "assisting": [
    "auxiliāris, auxiliāre"
  ],
  "helping": [
    "auxiliāris, auxiliāre"
  ],
  "aiding": [
    "auxiliāris, auxiliāre"
  ],
  "incline": [
    "vergō, vergere",
    "inclīnō, inclīnāre, inclīnāvī, inclīnātum"
  ],
  "nobly": [
    "līberāliter"
  ],
  "courteously": [
    "līberāliter",
    "blanditer, blandius, blandissimē"
  ],
  "graciously": [
    "līberāliter"
  ],
  "salty": [
    "salsus, salsa, salsum"
  ],
  "shut out": [
    "exclūdō, exclūdere, exclūsī, exclūsum"
  ],
  "exclude": [
    "exclūdō, exclūdere, exclūsī, exclūsum"
  ],
  "cut off": [
    "recīdō, recīdere, recīdī, recīsum",
    "abscīdō, abscīdere, abscīdī, abscīsum",
    "amputō, amputāre, amputāvī, amputātum",
    "exclūdō, exclūdere, exclūsī, exclūsum",
    "rescindō, rescindere, rescidī, rescissum"
  ],
  "separate from something": [
    "exclūdō, exclūdere, exclūsī, exclūsum"
  ],
  "an assuming": [
    "arrogantia, arrogantiae"
  ],
  "presumption": [
    "arrogantia, arrogantiae"
  ],
  "arrogance": [
    "superbia, superbiae",
    "arrogantia, arrogantiae"
  ],
  "conceitedness": [
    "arrogantia, arrogantiae"
  ],
  "cast down": [
    "praecipitō, praecipitāre, praecipitāvī, praecipitātum"
  ],
  "throw headlong": [
    "praecipitō, praecipitāre, praecipitāvī, praecipitātum"
  ],
  "proconsul": [
    "prōcōnsul, prōcōnsulis"
  ],
  "a man who became governor of a province or a military commander following a term as consul": [
    "prōcōnsul, prōcōnsulis"
  ],
  "flat": [
    "campester, campestris, campestre"
  ],
  "at long range": [
    "ēminus"
  ],
  "beyond reach": [
    "ēminus"
  ],
  "head first": [
    "praeceps, praecipitis"
  ],
  "headlong": [
    "praeceps, praecipitis"
  ],
  "prospect": [
    "dēspectus, dēspectūs",
    "prospectus, prospectūs"
  ],
  "ford": [
    "vadum, vadī",
    "vadō, vadāre, vadāvī, vadātum"
  ],
  "wade through": [
    "vadō, vadāre, vadāvī, vadātum"
  ],
  "apparel": [
    "VESTIMENTA",
    "vestītus, vestitūs"
  ],
  "raiment": [
    "vestītus, vestitūs"
  ],
  "garland": [
    "corōna -ae",
    "corōna -ae (f)",
    "corōna, corōnae"
  ],
  "chaplet": [
    "corōna, corōnae",
    "torquis, torquis"
  ],
  "laurel": [
    "corōna, corōnae",
    "laureus, laurea, laureum"
  ],
  "or wreath": [
    "corōna, corōnae"
  ],
  "shock": [
    "Shock",
    "Electricitatis",
    "impulsus, impulsūs"
  ],
  "impact": [
    "Ictus",
    "impulsus, impulsūs"
  ],
  "bad reputation or repute": [
    "īnfāmia, īnfāmiae"
  ],
  "ill fame": [
    "īnfāmia, īnfāmiae"
  ],
  "dishonor": [
    "stuprum, stuprī",
    "īnfāmia, īnfāmiae",
    "dēdecus, dēdecoris",
    "īnfāmō, īnfāmāre, īnfāmāvī, īnfāmātum"
  ],
  "disgrace": [
    "rubor, rubōris",
    "probrum, probrī",
    "stuprum, stuprī",
    "īnfāmia, īnfāmiae",
    "dēdecus, dēdecoris",
    "īnfāmō, īnfāmāre, īnfāmāvī, īnfāmātum"
  ],
  "infamy": [
    "īnfāmia, īnfāmiae",
    "dēdecus, dēdecoris"
  ],
  "reproach": [
    "īnfāmia, īnfāmiae",
    "opprobrium, opprobriī",
    "blasphēmō, blasphēmāre",
    "exprōbrō, exprōbrāre, exprōbrāvī, exprōbrātum"
  ],
  "derived": [
    "dēdux, dēducis"
  ],
  "roughly": [
    "dūriter, dūrius, dūrissimē",
    "asperē, asperius, asperrimē"
  ],
  "coarsely": [
    "dūriter, dūrius, dūrissimē",
    "asperē, asperius, asperrimē"
  ],
  "patron": [
    "fautor, fautōris",
    "patrōnus, patrōnī"
  ],
  "protector": [
    "tūtor, tūtōris",
    "fautor, fautōris",
    "patrōnus, patrōnī",
    "prōtector, prōtectōris"
  ],
  "defender": [
    "tūtor, tūtōris",
    "praeses, praesidis",
    "cōnservātor, cōnservātōris"
  ],
  "of or pertaining to water": [
    "aquārius, aquāria, aquārium"
  ],
  "acorn": [
    "glāns, glandis"
  ],
  "any acorn-shaped fruit": [
    "glāns, glandis"
  ],
  "beechnut": [
    "glāns, glandis"
  ],
  "chestnut": [
    "Chestnut",
    "glāns, glandis"
  ],
  "contend or combat hard": [
    "dēpugnō, dēpugnāre, dēpugnāvi, dēpugnātum"
  ],
  "oath": [
    "Ius iurandi",
    "iūrandum, iūrandī",
    "iūrāmentum, iūrāmentī"
  ],
  "encourage or exhort": [
    "cohortor, cohortārī, cohortātus sum"
  ],
  "equity": [
    "iūstitia, iūstitiae"
  ],
  "reckoned as the sixth hour of daylight": [
    "sexta"
  ],
  "copper": [
    "aes, aeris",
    "aēnus, aēna, aēnum",
    "aēneus, aēnea, aēneum",
    "aereus, aerea, aereum",
    "aerārius, aerāria, aerārium"
  ],
  "helper": [
    "adiūtor, adiūtōris",
    "adiūtrīx, adiūtrīcis"
  ],
  "assistant": [
    "adiūtor, adiūtōris",
    "adiūtrīx, adiūtrīcis"
  ],
  "yearly produce": [
    "annōna, annōnae"
  ],
  "yearly income": [
    "annōna, annōnae"
  ],
  "annual output": [
    "annōna, annōnae"
  ],
  "wearing a lōrīca": [
    "lōrīcātus, lōrīcāta, lōrīcātum"
  ],
  "harnessed": [
    "lōrīcātus, lōrīcāta, lōrīcātum"
  ],
  "clad in mail": [
    "lōrīcātus, lōrīcāta, lōrīcātum"
  ],
  "skin": [
    "corium, coriī",
    "scortum, scortī",
    "membrāna, membrānae"
  ],
  "inwards": [
    "intrōrsum"
  ],
  "strike after eagerly": [
    "petessō, petessere, petessīvī, petessitum"
  ],
  "slay": [
    "iugulō, iugulāre, iugulāvī, iugulātum"
  ],
  "slit the throat of": [
    "iugulō, iugulāre, iugulāvī, iugulātum"
  ],
  "ram": [
    "ariēs, arietis",
    "ariēs, arietis m"
  ],
  "a scorpion": [
    "scorpius, scorpiī",
    "scorpiō, scorpiōnis"
  ],
  "of or pertaining to the wings of an army": [
    "ālārius, ālāria, ālārium"
  ],
  "that is upon the wing": [
    "ālārius, ālāria, ālārium"
  ],
  "something made sacred or given to a deity": [
    "sacrificium, sacrificiī"
  ],
  "sacrifice": [
    "Sacrificium",
    "hostia, hostiae",
    "sacrificium, sacrificiī",
    "sacrificātus, sacrificātūs",
    "immolō, immolāre, immolāvī, immolātum",
    "sacrificō, sacrificāre, sacrificāvī, sacrificātum"
  ],
  "consanguineous": [
    "cōnsanguineus, cōnsanguinea, cōnsanguineum"
  ],
  "maniple": [
    "manipulus, manipulī",
    "manipulāris, manipulāre"
  ],
  "a double company of soldiers": [
    "manipulus, manipulī"
  ],
  "make into a body": [
    "corporō, corporāre, corporāvī, corporātum"
  ],
  "seeking": [
    "quaesītus, quaesītūs",
    "quaestiō, quaestiōnis"
  ],
  "swear together": [
    "coniūrō, coniūrāre, coniūrāvī, coniūrātum"
  ],
  "combine or join together by oath": [
    "coniūrō, coniūrāre, coniūrāvī, coniūrātum"
  ],
  "an image": [
    "simulācrum, simulācrī"
  ],
  "likeness": [
    "īnstar",
    "parabola, parabolae",
    "simulācrum, simulācrī",
    "similitūdō, similitūdinis",
    "cōnfōrmitās, cōnfōrmitātis"
  ],
  "injure severely": [
    "convulnerō, convulnerāre, convulnerāvī, convulnerātum"
  ],
  "align": [
    "dērigō, dērigere, dērexī, dērectum"
  ],
  "point": [
    "punctum, punctī",
    "cuspis, cuspidis",
    "dērigō, dērigere, dērexī, dērectum"
  ],
  "prepare or make ready for something": [
    "apparō, apparāre, apparāvī, apparātum"
  ],
  "put in order": [
    "apparō, apparāre, apparāvī, apparātum"
  ],
  "organize": [
    "apparō, apparāre, apparāvī, apparātum"
  ],
  "despised": [
    "contemptus, contempta, contemptum"
  ],
  "despicable": [
    "contemptus, contempta, contemptum"
  ],
  "brick": [
    "laterīcius, laterīcia, laterīcium"
  ],
  "brickwork": [
    "laterīcius, laterīcia, laterīcium"
  ],
  "a woodpecker": [
    "pīcus, pīcī"
  ],
  "a long pole": [
    "longurius, longuriī"
  ],
  "turf": [
    "grāmen, grāminis",
    "caespes, caespitis"
  ],
  "sod": [
    "caespes, caespitis"
  ],
  "a going in or into": [
    "introitus, introitūs"
  ],
  "entering": [
    "introitus, introitūs"
  ],
  "take a siesta": [
    "merīdiō, merīdiāre, merīdiāvī, merīdiātum"
  ],
  "equipment": [
    "armāmentum, armāmentī",
    "ornāmentum, ornāmenti"
  ],
  "apparatus": [
    "ornāmentum, ornāmenti"
  ],
  "furniture": [
    "SUPPELECTILES",
    "ornāmentum, ornāmenti",
    "supellex, supellectilis"
  ],
  "seat": [
    "sēdēs, sēdis",
    "solium, solii"
  ],
  "barley": [
    "hordeum, hordeī"
  ],
  "praetorship": [
    "praetūra, praetūrae"
  ],
  "preparation": [
    "apparātus, apparātūs",
    "praeparātiō, praeparātiōnis"
  ],
  "a getting ready": [
    "apparātus, apparātūs"
  ],
  "flow back": [
    "remānō, remānāre, remānāvī, remānātum"
  ],
  "grit": [
    "saburra, saburrae"
  ],
  "sand": [
    "Arena",
    "harēna, harēnae",
    "saburra, saburrae"
  ],
  "a shallow": [
    "vadum, vadī"
  ],
  "shoal": [
    "vadum, vadī"
  ],
  "take apart": [
    "dirimō, dirimere, dirēmī, diremptum"
  ],
  "beholder": [
    "arbiter, arbitrī"
  ],
  "one placed in command: a commander": [
    "praepositus, praepositī"
  ],
  "a leader": [
    "praepositus, praepositī"
  ],
  "loaded": [
    "onustus, onusta, onustum"
  ],
  "laden": [
    "onustus, onusta, onustum"
  ],
  "burdened": [
    "onustus, onusta, onustum"
  ],
  "declared": [
    "indictus, indicta, indictum"
  ],
  "elevated place made of materials poured out": [
    "suggestus, suggestūs"
  ],
  "raised place": [
    "suggestus, suggestūs"
  ],
  "elevation": [
    "levātiō, levātiōnis",
    "suggestus, suggestūs"
  ],
  "one hundred": [
    "centēnus, centēna, centēnum"
  ],
  "squeeze out": [
    "exprimō, exprimere, expressī, expressum"
  ],
  "disperse or dispel": [
    "diffugiō, diffugere, diffūgī, diffūgitum"
  ],
  "take out or up": [
    "exceptō, exceptāre, exceptāvī, exceptātum"
  ],
  "offense": [
    "dēlictum, dēlictī",
    "offēnsiō, offēnsiōnis"
  ],
  "disperse": [
    "disiciō, disicere, disiēcī, disiectum",
    "dissipō, dissipāre, dissipāvī, dissipātum",
    "disiectō, disiectāre, disiectāvī, disiectātum"
  ],
  "dissipate or squander": [
    "dissipō, dissipāre, dissipāvī, dissipātum"
  ],
  "so small": [
    "tantulus, tantula, tantulum"
  ],
  "status": [
    "status, statūs"
  ],
  "a freedman": [
    "lībertus, lībertī"
  ],
  "an emancipated person": [
    "lībertus, lībertī"
  ],
  "make faulty": [
    "vitiō, vitiāre, vitiāvī, vitiātum"
  ],
  "spoil": [
    "vitiō, vitiāre, vitiāvī, vitiātum"
  ],
  "come from": [
    "dēveniō, dēvenīre, dēvēnī, dēventum"
  ],
  "bring or convey": [
    "dēportō, dēportāre, dēportāvī, dēportātum"
  ],
  "insignificance": [
    "humilitās, humilitātis"
  ],
  "unimportance": [
    "humilitās, humilitātis"
  ],
  "from beyond the sea": [
    "trānsmarīnus, trānsmarīna, trānsmarīnum"
  ],
  "transmarine": [
    "trānsmarīnus, trānsmarīna, trānsmarīnum"
  ],
  "sweat": [
    "sūdor, sūdōris",
    "sūdō, sūdāre, sūdāvī, sūdātum"
  ],
  "perspire": [
    "sūdō, sūdāre, sūdāvī, sūdātum"
  ],
  "driest": [
    "āridus, ārida, āridum"
  ],
  "most or very dry or arid": [
    "āridus, ārida, āridum"
  ],
  "tub": [
    "linter, lintris"
  ],
  "trough": [
    "linter, lintris"
  ],
  "weeping": [
    "flētus, flētūs",
    "lāmentum, lāmentī",
    "plōrātus, plōrātūs",
    "lāmentātiō, lāmentātiōnis",
    "lacrimōsus, lacrimōsa, lacrimōsum"
  ],
  "majesty": [
    "māiestās, māiestātis"
  ],
  "look around": [
    "circumspiciō, circumspicere, circumspexī, circumspectum",
    "circumspectō, circumspectāre, circumspectāvī, circumspectātum"
  ],
  "over or for": [
    "circumspiciō, circumspicere, circumspexī, circumspectum"
  ],
  "strike or dash to the ground": [
    "prōflīgō, prōflīgāre, prōflīgāvī, prōflīgātum"
  ],
  "cut or break down or back": [
    "rescindō, rescindere, rescidī, rescissum"
  ],
  "cut or tear open or loose": [
    "rescindō, rescindere, rescidī, rescissum"
  ],
  "fast": [
    "Fast",
    "Nunc ipse",
    "iēiūnium, iēiūniī",
    "religō, religāre, religāvī, religātum"
  ],
  "complaint": [
    "questus, questūs",
    "querēla, querēlae",
    "querella, querellae",
    "accūsātiō, accūsātiōnis",
    "querimōnia, querimōniae",
    "conquestiō, conquestiōnis",
    "crīminātiō, crīminātiōnis"
  ],
  "flooring made of planks": [
    "contabulātiō, contabulātiōnis"
  ],
  "put forth": [
    "ēdō, ēdere, ēdidī, ēditum",
    "pullō, pullāre, pullāvī, pullātum",
    "prōpōnō, prōpōnere, prōposuī, prōpositum",
    "pullulō, pullulāre, pullulāvī, pullulātum"
  ],
  "sprout out": [
    "pullō, pullāre, pullāvī, pullātum",
    "pullulō, pullulāre, pullulāvī, pullulātum"
  ],
  "germinate": [
    "pullō, pullāre, pullāvī, pullātum"
  ],
  "cave": [
    "Spelunca",
    "antrum, antrī",
    "specus, specūs",
    "caverna, cavernae",
    "spēlunca, spēluncae"
  ],
  "chasm": [
    "specus, specūs"
  ],
  "eminent": [
    "ēminēns, ēminentis"
  ],
  "notable": [
    "ēminēns, ēminentis",
    "notābilis, notābile"
  ],
  "lacking in": [
    "expers, expertis"
  ],
  "straw": [
    "Stramen",
    "strāmentum, strāmentī"
  ],
  "phalanx": [
    "phalanx, phalangis"
  ],
  "battalion": [
    "phalanx, phalangis"
  ],
  "implement": [
    "armāmentum, armāmentī"
  ],
  "utensil": [
    "armāmentum, armāmentī"
  ],
  "rigging": [
    "armāmentum, armāmentī"
  ],
  "set bounds to": [
    "dēfīniō, dēfīnīre, dēfīnīvī, dēfīnītum",
    "terminō, termināre, termināvī, terminātum"
  ],
  "nod": [
    "nūtus, nūtūs"
  ],
  "nodding": [
    "nūtus, nūtūs"
  ],
  "full of bushes or thickets": [
    "virgultus, virgulta, virgultum"
  ],
  "shrubby": [
    "virgultus, virgulta, virgultum"
  ],
  "long-lasting": [
    "diūtinus, diūtina, diūtinum",
    "diūturnus, diūturna, diūturnum"
  ],
  "image": [
    "īnstar",
    "īdōlum, īdōlī",
    "imāginārius, imāgināria, imāginārium"
  ],
  "resemblance": [
    "īnstar",
    "similitūdō, similitūdinis"
  ],
  "seize or snatch": [
    "adripiō, adripere, adripuī, adreptum",
    "arripiō, arripere, arripuī, arreptum"
  ],
  "hang": [
    "pendō, pendere, pependī, pēnsum"
  ],
  "a prospect": [
    "dēspectus, dēspectūs"
  ],
  "panorama": [
    "dēspectus, dēspectūs"
  ],
  "divide into decuriae": [
    "decuriō, decuriāre, decuriāvī, decuriātum"
  ],
  "by kinds": [
    "generātim"
  ],
  "species": [
    "generātim"
  ],
  "classes or divisions": [
    "generātim"
  ],
  "enriched": [
    "auctus, aucta, auctum"
  ],
  "privately": [
    "prīvātim",
    "pecūliāriter"
  ],
  "as a private individual": [
    "prīvātim"
  ],
  "expedition": [
    "expedītiō, expedītiōnis"
  ],
  "campaign": [
    "expedītiō, expedītiōnis"
  ],
  "heap or pile up": [
    "accumulō, accumulāre, accumulāvī, accumulātum",
    "coacervō, coacervāre, coacervāvī, coacervātum"
  ],
  "intervention": [
    "interventus, interventūs",
    "intercessiō, intercessiōnis",
    "oppositus, opposita, oppositum"
  ],
  "a deserter": [
    "trānsfuga, trānsfugae"
  ],
  "a harsh": [
    "strīdor, strīdōris"
  ],
  "shrill": [
    "strīdor, strīdōris"
  ],
  "hissing": [
    "sībilus, sībilī",
    "strīdor, strīdōris"
  ],
  "grating": [
    "strīdor, strīdōris"
  ],
  "or creaking sound": [
    "strīdor, strīdōris"
  ],
  "an elephant": [
    "elephāns, elephantis"
  ],
  "castigate": [
    "castīgō, castīgāre, castīgāvī, castīgātum"
  ],
  "chastise": [
    "castīgō, castīgāre, castīgāvī, castīgātum"
  ],
  "reprove": [
    "castīgō, castīgāre, castīgāvī, castīgātum"
  ],
  "wheel": [
    "Rota",
    "rota, rotae",
    "rotō, rotāre, rotāvī, rotātum"
  ],
  "beget": [
    "gīgnō gīgnere genuī genitum",
    "gignō, gignere, genuī, genitum",
    "generō, generāre, generāvī, generātum",
    "prōcreō, prōcreāre, prōcreāvī, prōcreātum"
  ],
  "procreate": [
    "generō, generāre, generāvī, generātum",
    "prōcreō, prōcreāre, prōcreāvī, prōcreātum"
  ],
  "weaving": [
    "textūra, textūrae",
    "contextus, contextūs"
  ],
  "steam": [
    "fūmus, fūmī",
    "fūmō, fūmāre, fūmāvī, fūmātum"
  ],
  "double-headed": [
    "anceps, ancipitis",
    "biceps, bicipitis"
  ],
  "having two heads": [
    "anceps, ancipitis",
    "biceps, bicipitis"
  ],
  "artificial port": [
    "cōthō, cōthōnis"
  ],
  "discredit": [
    "dēdecus, dēdecoris"
  ],
  "highest part of a mountain chain": [
    "prōmunturium, prōmunturiī"
  ],
  "provincial": [
    "prōvinciālis, prōvinciāle"
  ],
  "military service": [
    "mīlitia, mīlitiae"
  ],
  "pertaining to": [
    "cibārius, cibāria, cibārium",
    "sīdereus, sīderea, sīdereum",
    "marmoreus, marmorea, marmoreum"
  ],
  "or suitable for food": [
    "cibārius, cibāria, cibārium"
  ],
  "something that fills up or makes up the numbers": [
    "supplēmentum, supplēmentī"
  ],
  "supplement": [
    "supplēmentum, supplēmentī",
    "suppleō, supplēre, supplēvī, supplētum"
  ],
  "asiatic": [
    "āsiāticus, āsiātica, āsiāticum"
  ],
  "cast or bring together": [
    "coniectō, coniectāre, coniectāvī, coniectātum"
  ],
  "wooden stake": [
    "sublica, sublicae"
  ],
  "pile or similar support": [
    "sublica, sublicae"
  ],
  "as for a bridge or building": [
    "sublica, sublicae"
  ],
  "running forth": [
    "excursiō, excursiōnis"
  ],
  "carefully": [
    "dīligenter",
    "accūrātē, accūrātius, accūrātissimē"
  ],
  "exactly": [
    "accūrātē, accūrātius, accūrātissimē"
  ],
  "the decision of an arbiter": [
    "arbitrium, arbitriī"
  ],
  "intent on": [
    "intentus, intenta, intentum"
  ],
  "humanity": [
    "hūmānitās, hūmānitātis"
  ],
  "human nature": [
    "hūmānitās, hūmānitātis"
  ],
  "upset or topple": [
    "dēturbō, dēturbāre, dēturbāvī, dēturbātum"
  ],
  "unworthiness": [
    "indignitās, indignitātis"
  ],
  "similarity": [
    "analogia, analogiae",
    "aequālitās, aequālitātis",
    "similitūdō, similitūdinis",
    "cōnfōrmitās, cōnfōrmitātis"
  ],
  "imitation": [
    "effigiēs, effigiēī",
    "imitātiō, imitātiōnis",
    "similitūdō, similitūdinis"
  ],
  "the south wind": [
    "auster, austrī"
  ],
  "a greedy desire for possessions or gain": [
    "avāritia, avāritiae"
  ],
  "greediness": [
    "avāritia, avāritiae"
  ],
  "covetousness": [
    "avāritia, avāritiae",
    "aviditās, aviditātis"
  ],
  "rapacity": [
    "avāritia, avāritiae"
  ],
  "soundness": [
    "integritās, integritātis",
    "incolumitās, incolumitātis"
  ],
  "not done": [
    "īnfectus, īnfecta, īnfectum"
  ],
  "unfinished": [
    "īnfectus, īnfecta, īnfectum",
    "imperfectus, imperfecta, imperfectum"
  ],
  "olive grove": [
    "olīvētum, olīvētī"
  ],
  "senatorial": [
    "senātōrius, senātōria, senātōrium"
  ],
  "of or pertaining to a member of the roman senate": [
    "senātōrius, senātōria, senātōrium"
  ],
  "mountainous": [
    "montuōsus, montuōsa, montuōsum"
  ],
  "full of mountains": [
    "montuōsus, montuōsa, montuōsum"
  ],
  "nautical": [
    "nauticus, nautica, nauticum"
  ],
  "an angry murmur": [
    "fremitus, fremitūs"
  ],
  "humming": [
    "fremitus, fremitūs"
  ],
  "biennium": [
    "biennium, bienniī or biennī"
  ],
  "stationary": [
    "stabilis, stabile",
    "statīvus, statīva, statīvum"
  ],
  "feel seasick": [
    "nauseō, nauseāre, nauseāvī, nauseātum"
  ],
  "very small": [
    "perbrevis, perbreve",
    "pusillus, pusilla, pusillum",
    "perexiguus, perexigua, perexiguum"
  ],
  "colonnade": [
    "porticus, porticūs"
  ],
  "arcade": [
    "porticus, porticūs"
  ],
  "lesson": [
    "documentum, documentī"
  ],
  "swampy": [
    "palūster, palūstris, palūstre"
  ],
  "marshy": [
    "līmōsus, līmōsa, līmōsum",
    "palūster, palūstris, palūstre"
  ],
  "boggy": [
    "palūster, palūstris, palūstre"
  ],
  "victoress": [
    "victrīx, victrīcis"
  ],
  "conqueress": [
    "victrīx, victrīcis"
  ],
  "female conqueror": [
    "victrīx, victrīcis"
  ],
  "towrope": [
    "remulcum, remulcī"
  ],
  "annual": [
    "annuus, annua, annuum",
    "anniversārius, anniversāria, anniversārium"
  ],
  "yearly": [
    "sollemnis, sollemne",
    "annuus, annua, annuum",
    "anniversārius, anniversāria, anniversārium"
  ],
  "lasting a year": [
    "annuus, annua, annuum"
  ],
  "carry or bring back": [
    "reportō, reportāre, reportāvī, reportātum"
  ],
  "deplore": [
    "miseror, miserārī, miserātus sum",
    "dēfleō, dēflēre, dēflēvī, dēflētum"
  ],
  "have pity for": [
    "miseror, miserārī, miserātus sum"
  ],
  "drive forth or away": [
    "prōterō, prōterere, prōtrīvī, prōtrītum"
  ],
  "a helmet": [
    "galea, galeae"
  ],
  "especially of children born following death of the father": [
    "postumus, postuma, postumum"
  ],
  "dissimilar": [
    "dispār, disparis",
    "absimilis, absimile",
    "dissimilis, dissimile"
  ],
  "sheer": [
    "abscīsus, abscīsa, abscīsum"
  ],
  "precipitous": [
    "abscīsus, abscīsa, abscīsum"
  ],
  "how often": [
    "quotiēns"
  ],
  "sign-bearing": [
    "signifer, signifera, signiferum"
  ],
  "image-bearing": [
    "signifer, signifera, signiferum"
  ],
  "seize or take on": [
    "percipiō, percipere, percēpī, perceptum"
  ],
  "assume": [
    "adoptō, adoptāre, adoptāvī, adoptātum",
    "percipiō, percipere, percēpī, perceptum"
  ],
  "carriage": [
    "Raeda",
    "Plaustrum",
    "gestus, gestūs",
    "carpentum, carpentī",
    "vehiculum, vehiculī"
  ],
  "posture": [
    "gestus, gestūs"
  ],
  "attitude": [
    "gestus, gestūs"
  ],
  "promulgate": [
    "prōmulgō, prōmulgāre, prōmulgāvī, prōmulgātum"
  ],
  "make high": [
    "altō, altāre"
  ],
  "elevate": [
    "altō, altāre",
    "extollō, extollere",
    "excellō, excellere, excelluī, excelsum"
  ],
  "of this kind": [
    "hūiusmodī"
  ],
  "perish or die": [
    "dēpereō, dēperīre, dēperiī, dēperitum"
  ],
  "italic": [
    "ītalicus, ītalica, ītalicum"
  ],
  "approve wholly of something": [
    "comprobō, comprobāre, comprobāvī, comprobātum"
  ],
  "assent to": [
    "assentior, assentīrī, assēnsus sum",
    "comprobō, comprobāre, comprobāvī, comprobātum"
  ],
  "sanction": [
    "sānctiō, sānctiōnis",
    "comprobō, comprobāre, comprobāvī, comprobātum"
  ],
  "or acknowledge": [
    "comprobō, comprobāre, comprobāvī, comprobātum"
  ],
  "skilfully": [
    "scienter, scientius, scientissimē"
  ],
  "expertly": [
    "scienter, scientius, scientissimē"
  ],
  "wheat": [
    "Triticum",
    "trīticum, trīticī"
  ],
  "a kind of grain": [
    "trīticum, trīticī"
  ],
  "swim or float in or upon": [
    "innatō, innatāre, innatāvī, innatātum"
  ],
  "haughtily": [
    "īnflātē, īnflātius, īnflātissimē"
  ],
  "proudly": [
    "īnflātē, īnflātius, īnflātissimē"
  ],
  "pompously": [
    "īnflātē, īnflātius, īnflātissimē"
  ],
  "by squadrons": [
    "turmātim"
  ],
  "troops or turma": [
    "turmātim"
  ],
  "luxury": [
    "luxuria, luxuriae"
  ],
  "a cheating": [
    "fraus, fraudis"
  ],
  "deceit": [
    "fraus, fraudis",
    "captiō, captiōnis",
    "fallācia, fallāciae",
    "dēceptiō, dēceptiōnis"
  ],
  "guile": [
    "fraus, fraudis"
  ],
  "fraud": [
    "fraus, fraudis",
    "captiō, captiōnis"
  ],
  "dealer": [
    "Coccio",
    "negōtiātor, negōtiātōris"
  ],
  "trader": [
    "negōtiātor, negōtiātōris"
  ],
  "convulse": [
    "convellō, convellere, convellī, convulsum"
  ],
  "look over": [
    "dēspectō, dēspectāre, dēspectāvī, dēspectātus"
  ],
  "survey": [
    "perlegō, perlegere, perlēgī, perlēctum",
    "dēspectō, dēspectāre, dēspectāvī, dēspectātus",
    "contemplō, contemplāre, contemplāvī, contemplātum"
  ],
  "a strong pole or bar used for leverage": [
    "vectis, vectis"
  ],
  "lever": [
    "Ansa",
    "Fulcrum",
    "vectis, vectis"
  ],
  "crowbar": [
    "vectis, vectis"
  ],
  "handspike": [
    "vectis, vectis"
  ],
  "lead or carry someone or something to or toward": [
    "admoveō, admovēre, admōvī, admōtum"
  ],
  "apply to": [
    "admoveō, admovēre, admōvī, admōtum"
  ],
  "to consecrate or dedicate": [
    "cōnsecrō, cōnsecrāre, cōnsecrāvī, cōnsecrātum"
  ],
  "two feet long or wide": [
    "bipedālis, bipedāle"
  ],
  "break or shatter": [
    "perfringō, perfringere, perfrēgī, perfractum"
  ],
  "be unable": [
    "nequeō, nequīre, nequiī, nequitum"
  ],
  "wandering": [
    "error, errōris",
    "vagus, vaga, vagum",
    "errāticus, errātica, errāticum",
    "errābundus, errābunda, errābundum"
  ],
  "a garment of several pieces sewed together": [
    "centō, centōnis"
  ],
  "a patchwork": [
    "centō, centōnis"
  ],
  "free or exempt from taxes or public service": [
    "immūnis, immūne"
  ],
  "mire": [
    "lutum, lutī",
    "caenum, caenī"
  ],
  "mud": [
    "lutum, lutī",
    "caenum, caenī"
  ],
  "curly": [
    "crispus, crispa, crispum"
  ],
  "crimped": [
    "crispus, crispa, crispum"
  ],
  "a place to keep things in": [
    "receptāculum, receptāculī"
  ],
  "reservoir": [
    "receptāculum, receptāculī"
  ],
  "receptacle": [
    "receptāculum, receptāculī"
  ],
  "repository": [
    "receptāculum, receptāculī"
  ],
  "container": [
    "receptāculum, receptāculī"
  ],
  "mobility": [
    "mōbilitas, mōbilitātis",
    "mōbilitās, mōbilitātis"
  ],
  "a plumed metal helmet": [
    "cassis, cassidis"
  ],
  "alternate": [
    "alternus, alterna, alternum"
  ],
  "throwing": [
    "iactus, iactūs"
  ],
  "hurling": [
    "iactus, iactūs"
  ],
  "casting": [
    "iactus, iactūs"
  ],
  "feebleness": [
    "languor, languōris",
    "īnfirmitās, īnfirmitātis",
    "imbēcillitas, imbēcillitātis",
    "imbēcillitās, imbēcillitātis"
  ],
  "languor": [
    "languor, languōris"
  ],
  "apathy": [
    "languor, languōris"
  ],
  "triennium": [
    "triennium, trienniī"
  ],
  "fit a bridle": [
    "frēnō, frēnāre, frēnāvī, frēnātum"
  ],
  "a bridle": [
    "frēnum, frēnī"
  ],
  "harness": [
    "frēnum, frēnī"
  ],
  "curb": [
    "frēnum, frēnī",
    "inhibeō, inhibēre, inhibuī, inhibitum"
  ],
  "bit": [
    "frēnum, frēnī",
    "frustum, frustī"
  ],
  "visit or travel to": [
    "commeō, commeāre, commeāvī, commeātum"
  ],
  "run or hasten back": [
    "recurrō, recurrere, recurrī, recursum"
  ],
  "overthrow": [
    "cāsurus, cāsurūs",
    "strāges, strāgis",
    "dīruō, dīruere, dīruī, dīrutum",
    "pervertō, pervertere, pervertī, perversum",
    "subvertō, subvertere, subvertī, subversum"
  ],
  "demolish": [
    "dīruō, dīruere, dīruī, dīrutum",
    "dēmōliō, dēmōlīre, dēmōlīvī, dēmōlitum",
    "dēstruō, dēstruere, dēstruxī, dēstructum"
  ],
  "considerable": [
    "aliquantus, aliquanta, aliquantum"
  ],
  "shake or jolt violently": [
    "vēxō, vēxāre, vēxāvī, vēxātum"
  ],
  "an instance of hastening or hurrying": [
    "festīnātiō, festīnātiōnis"
  ],
  "the good quality of something": [
    "bonitās, bonitātis"
  ],
  "goodness": [
    "bonitās, bonitātis"
  ],
  "to quiet": [
    "quiētō, quiētāre, quiētāvī, quiētātum"
  ],
  "water carrier or bearer": [
    "aquātor, aquātōris"
  ],
  "reflect": [
    "refulgeō, refulgēre, refulsī",
    "cōnsultō, cōnsultāre, cōnsultāvī, cōnsultātum"
  ],
  "cliff": [
    "rūpēs, rūpis"
  ],
  "cut up to pieces": [
    "concīdō, concīdere, concīdī, concīsum"
  ],
  "break up": [
    "findō, findere, fidī, fissum",
    "concīdō, concīdere, concīdī, concīsum",
    "disiciō, disicere, disiēcī, disiectum",
    "corrumpō, corrumpere, corrūpī, corruptum"
  ],
  "olive oil": [
    "oleum, oleī"
  ],
  "have a presentiment": [
    "praesentiō, praesentīre, praesēnsī, praesēnsum"
  ],
  "massacre": [
    "occīdiō, occīdiōnis",
    "occīsiō, occīsiōnis",
    "interneciō, interneciōnis"
  ],
  "carnage": [
    "interneciō, interneciōnis"
  ],
  "moderation": [
    "modestia, modestiae",
    "moderātiō, moderātiōnis",
    "temperantia, temperantiae",
    "contingentia, contingentiae"
  ],
  "insist": [
    "īnstō, īnstāre, īnstitī, īnstātum",
    "efflāgitō, efflāgitāre, efflāgitāvī, efflāgitātum"
  ],
  "continue standing": [
    "perstō, perstāre, perstitī, perstātum"
  ],
  "free oneself": [
    "dēscīscō, dēscīscere, dēscīvī, dēscītum"
  ],
  "defect": [
    "dēscīscō, dēscīscere, dēscīvī, dēscītum"
  ],
  "revolt from": [
    "deficiō deficere defēcī defectum",
    "dēscīscō, dēscīscere, dēscīvī, dēscītum"
  ],
  "grappling hook": [
    "harpagō, harpagōnis"
  ],
  "grappling iron": [
    "harpagō, harpagōnis"
  ],
  "a course": [
    "prōcessus, prōcessūs"
  ],
  "progression": [
    "prōcessus, prōcessūs",
    "prōgressiō, prōgressiōnis"
  ],
  "process": [
    "prōcessus, prōcessūs"
  ],
  "modius": [
    "modius, modiī"
  ],
  "a unit of dry measure of about a peck or 9l": [
    "modius, modiī"
  ],
  "onslaught": [
    "impressiō, impressiōnis"
  ],
  "fight against": [
    "impugnō, impugnāre, impugnāvī, impugnātum",
    "oppugnō, oppugnāre, oppugnāvī, oppugnātum"
  ],
  "proboscis": [
    "proboscis, proboscidis"
  ],
  "diversify": [
    "variō, variāre, variāvī, variātum"
  ],
  "variegate": [
    "variō, variāre, variāvī, variātum"
  ],
  "transform": [
    "variō, variāre, variāvī, variātum",
    "immūtō, immūtāre, immūtāvī, immūtātum",
    "permūtō, permūtāre, permūtāvī, permūtātum",
    "trānsfōrmō, trānsfōrmāre, trānsfōrmāvī, trānsfōrmātum"
  ],
  "make different or various": [
    "variō, variāre, variāvī, variātum"
  ],
  "vary": [
    "variō, variāre, variāvī, variātum"
  ],
  "interchange": [
    "vicissitūdō, vicissitūdinis",
    "variō, variāre, variāvī, variātum"
  ],
  "confusion": [
    "trepidātiō, trepidātiōnis",
    "perturbātiō, perturbātiōnis"
  ],
  "pyre": [
    "pyra, pyrae"
  ],
  "consume or destroy by fire": [
    "cremō, cremāre, cremāvī, cremātum"
  ],
  "of or pertaining to a mountain": [
    "montānus, montāna, montānum"
  ],
  "especially—situated among": [
    "montānus, montāna, montānum"
  ],
  "or built in": [
    "montānus, montāna, montānum"
  ],
  "the mountainsdwelling in the mountains": [
    "montānus, montāna, montānum"
  ],
  "mountaineermountainous": [
    "montānus, montāna, montānum"
  ],
  "a nail": [
    "clāvus, clāvī"
  ],
  "that suckles or nurses": [
    "nūtrīcius, nūtrīcia, nūtrīcium"
  ],
  "very easy": [
    "perfacilis, perfacile"
  ],
  "arguing": [
    "disputātiō, disputātiōnis"
  ],
  "reasoning": [
    "disputātiō, disputātiōnis",
    "ratiōcinātiō, ratiōcinātiōnis"
  ],
  "discussing": [
    "disputātiō, disputātiōnis"
  ],
  "debating": [
    "disputātiō, disputātiōnis"
  ],
  "sourness of taste": [
    "acerbitās, acerbitātis"
  ],
  "with bitterness and astringency": [
    "acerbitās, acerbitātis"
  ],
  "like that of unripe fruit": [
    "acerbitās, acerbitātis"
  ],
  "post": [
    "pālus, pāli",
    "postis, postis",
    "stīpes, stīpitis"
  ],
  "tree trunk": [
    "cōdex, cōdicis",
    "stīpes, stīpitis"
  ],
  "make or put before or in front": [
    "praedūcō, praedūcere, praedūxī, praeductum"
  ],
  "unknowing": [
    "īnsciēns, īnscientis"
  ],
  "present circumstance": [
    "praesente, praesentis"
  ],
  "administration": [
    "prōcūrātiō, prōcūrātiōnis",
    "dispensātiō, dispensātiōnis",
    "administrātiō, administrātiōnis"
  ],
  "management": [
    "tractātiō, tractātiōnis",
    "dispositiō, dispositiōnis",
    "prōcūrātiō, prōcūrātiōnis",
    "dispensātiō, dispensātiōnis",
    "administrātiō, administrātiōnis"
  ],
  "committal": [
    "commendātiō, commendātiōnis"
  ],
  "forerunner": [
    "antecursor, antecursōris"
  ],
  "vanguard": [
    "antecursor, antecursōris"
  ],
  "pull out from under": [
    "subtrahō, subtrahere, subtraxī, subtractum"
  ],
  "ride sail etc. by": [
    "praetervehor, praetervehī, praetervectus sum"
  ],
  "with whom": [
    "quācum",
    "quōcum",
    "quibuscum"
  ],
  "workshop": [
    "officīna, officīnae"
  ],
  "manufactory": [
    "officīna, officīnae"
  ],
  "innumerable": [
    "innumerābilis, innumerābile",
    "innumerus, innumera, innumerum"
  ],
  "countless": [
    "innumerābilis, innumerābile",
    "innumerus, innumera, innumerum"
  ],
  "alike": [
    "cōnsimilis, cōnsimile"
  ],
  "very similar": [
    "cōnsimilis, cōnsimile"
  ],
  "a danewort": [
    "actē, actēs"
  ],
  "dwarf-elder": [
    "actē, actēs"
  ],
  "weigh down": [
    "gravō, gravāre, gravāvī, gravātum"
  ],
  "oppress": [
    "gravō, gravāre, gravāvī, gravātum",
    "contrīstō, contrīstāre, contrīstāvī, contrīstātum"
  ],
  "the act of going aside": [
    "sēcessiō, sēcessiōnis"
  ],
  "withdrawal": [
    "sēcessus, sēcessūs",
    "remōtiō, remōtiōnis",
    "sēcessiō, sēcessiōnis",
    "dētractiō, dētractiōnis",
    "discessio, discessiōnis"
  ],
  "set foot": [
    "īnsistō, īnsistere, īnstitī"
  ],
  "tread or press on or upon something": [
    "īnsistō, īnsistere, īnstitī"
  ],
  "rising": [
    "acclīvis, acclīve"
  ],
  "ascending": [
    "acclīvis, acclīve"
  ],
  "hesitation": [
    "cūnctātiō, cūnctātiōnis"
  ],
  "divine": [
    "dīvus, dīva, dīvum",
    "dīvīnus, dīvīna, dīvīnum",
    "dīvīnō, dīvīnāre, dīvīnāvī, dīvīnātum",
    "inaugurō, inaugurāre, inaugurāvī, inaugurātum"
  ],
  "thanksgiving": [
    "grātēs, grātium",
    "supplicātiō, supplicātiōnis"
  ],
  "singly": [
    "singillātim"
  ],
  "one by one": [
    "singillātim"
  ],
  "seriousness": [
    "sevēritās, sevēritātis"
  ],
  "gravity": [
    "sevēritās, sevēritātis"
  ],
  "sternness": [
    "sevēritās, sevēritātis"
  ],
  "strictness": [
    "sevēritās, sevēritātis"
  ],
  "of or pertaining to a consul": [
    "cōnsulāris, cōnsulāre"
  ],
  "consular": [
    "cōnsulāris, cōnsulāre"
  ],
  "possessing large": [
    "locuplēs, locuplētis"
  ],
  "landed property": [
    "locuplēs, locuplētis"
  ],
  "a renewal of war": [
    "rebelliō, rebelliōnis"
  ],
  "insurgency": [
    "rebelliō, rebelliōnis"
  ],
  "acquisition": [
    "quaestus, quaestūs"
  ],
  "advantage": [
    "quaestus, quaestūs",
    "ēmolumentum, ēmolumentī"
  ],
  "projecting points": [
    "minae, minārum"
  ],
  "pinnacles": [
    "minae, minārum"
  ],
  "battlements": [
    "minae, minārum"
  ],
  "parapets": [
    "minae, minārum"
  ],
  "clamour": [
    "convīcium, convīciī",
    "vōciferātiō, vōciferātiōnis"
  ],
  "outcry": [
    "convīcium, convīciī"
  ],
  "destroyer": [
    "perditor, perditōris"
  ],
  "ruiner": [
    "perditor, perditōris"
  ],
  "roar": [
    "rudō, rudere, rudīvī, rudītum",
    "rūgiō, rūgīre, rūgīvī, rūgītus"
  ],
  "to no purpose": [
    "nēquīquam"
  ],
  "fruitlessly": [
    "nēquīquam"
  ],
  "without ground or reason": [
    "nēquīquam"
  ],
  "hunting": [
    "vēnātus, vēnātūs",
    "vēnātiō, vēnātiōnis"
  ],
  "the chase": [
    "vēnātiō, vēnātiōnis"
  ],
  "venery": [
    "vēnātiō, vēnātiōnis"
  ],
  "lictor": [
    "līctor, līctōris"
  ],
  "come often": [
    "ventitō, ventitāre, ventitāvī, ventitātum"
  ],
  "keep coming": [
    "ventitō, ventitāre, ventitāvī, ventitātum"
  ],
  "resort": [
    "ventitō, ventitāre, ventitāvī, ventitātum"
  ],
  "migrate or emigrate": [
    "dēmigrō, dēmigrāre, dēmigrāvī, dēmigrātum"
  ],
  "kindred": [
    "adfīnis, adfīne",
    "affīnis, affīne",
    "cognātiō, cognātiōnis",
    "cognātus, cognāta, cognātum"
  ],
  "conscious": [
    "cōnscius, cōnscia, cōnscium"
  ],
  "downward": [
    "prōclīvis, prōclīve"
  ],
  "downhill": [
    "prōclīvis, prōclīve"
  ],
  "the act of seeing or looking at something": [
    "aspectus, aspectūs"
  ],
  "vision": [
    "somnium, somniī",
    "aspectus, aspectūs"
  ],
  "purify by means of a propitiatory sacrifice": [
    "lustrō, lustrāre, lustrāvī, lustrātum"
  ],
  "a coat of mail": [
    "lōrīca, lōrīcae"
  ],
  "public revenue": [
    "pūblicānus, pūblicāna, pūblicānum"
  ],
  "present to": [
    "condōnō, condōnāre, condōnāvī, condōnātum"
  ],
  "epistle": [
    "epistola, epistolae"
  ],
  "incense": [
    "incēnsus, incēnsūs"
  ],
  "inheritance": [
    "Inheritance",
    "hērēditās, hērēditātis",
    "pātrimōnium, pātrimōniī"
  ],
  "voting tablet": [
    "suffrāgium, suffrāgiī"
  ],
  "immolate": [
    "Immolate",
    "immolō, immolāre, immolāvī, immolātum"
  ],
  "an office of priests": [
    "sacerdōtium, sacerdōtiī"
  ],
  "priesthood": [
    "sacerdōtium, sacerdōtiī"
  ],
  "agriculture": [
    "agricultūra, agricultūrae"
  ],
  "farming": [
    "agricultūra, agricultūrae"
  ],
  "neighborhood": [
    "vīcīnitas, vīcīnitātis",
    "vīcīnitās, vīcīnitātis"
  ],
  "clandestine": [
    "clandestīnus, clandestīna, clandestīnum"
  ],
  "secret": [
    "arcānus, arcāna, arcānum",
    "absconsus, absconsa, absconsum",
    "absconditus, abscondita, absconditum",
    "clandestīnus, clandestīna, clandestīnum"
  ],
  "concealed": [
    "absconsus, absconsa, absconsum",
    "clandestīnus, clandestīna, clandestīnum"
  ],
  "spend or expend": [
    "impendō, impendere, impendī, impēnsum",
    "īnsūmō, īnsūmere, īnsūmpsī, īnsūmptum"
  ],
  "destructive": [
    "damnōsus, damnōsa, damnōsum",
    "exitiōsus, exitiōsa, exitiōsum",
    "calamitōsus, calamitōsa, calamitōsum",
    "perniciōsus, perniciōsa, perniciōsum"
  ],
  "ruinous": [
    "adflīctus, adflīcta, adflīctum",
    "calamitōsus, calamitōsa, calamitōsum",
    "perniciōsus, perniciōsa, perniciōsum"
  ],
  "baleful": [
    "perniciōsus, perniciōsa, perniciōsum"
  ],
  "pernicious": [
    "exitiōsus, exitiōsa, exitiōsum",
    "perniciōsus, perniciōsa, perniciōsum"
  ],
  "nowhere": [
    "nūsquam"
  ],
  "sit around": [
    "circumsedeō, circumsedēre, circumsēdī, circumsessum"
  ],
  "pact": [
    "pactiō, pactiōnis"
  ],
  "unfaithful": [
    "īnfidēlis, īnfidēle, īnfidēlior"
  ],
  "steadfastness": [
    "persevērantia, persevērantiae"
  ],
  "infectious or contagious disease": [
    "pestilentia, pestilentiae"
  ],
  "plague": [
    "plāga, plāgae",
    "pestis, pestis",
    "pestilentia, pestilentiae"
  ],
  "pestilence": [
    "pestilentia, pestilentiae"
  ],
  "volunteer": [
    "voluntārius, voluntāriī"
  ],
  "skilfulness": [
    "calliditās, calliditātis"
  ],
  "readiness": [
    "facilitās, facilitātis",
    "calliditās, calliditātis",
    "dexteritās, dexteritātis"
  ],
  "aptness": [
    "calliditās, calliditātis",
    "dexteritās, dexteritātis"
  ],
  "release or discharge": [
    "persolvō, persolvere, persolvī, persolūtum"
  ],
  "draw away from": [
    "abstrahō, abstrahere, abstraxī, abstractum"
  ],
  "drag or pull away": [
    "abstrahō, abstrahere, abstraxī, abstractum"
  ],
  "with difficulty": [
    "aegrē, aegrius, aegrissimē",
    "difficiliter, difficilius, difficillime",
    "difficulter, difficulius, difficulissimē"
  ],
  "wash underneath": [
    "subluō, subluere, subluī, sublūtum"
  ],
  "refusal": [
    "negātiō, negātiōnis",
    "recūsātiō, recūsātiōnis"
  ],
  "declining": [
    "recūsātiō, recūsātiōnis"
  ],
  "naturally": [
    "in natura",
    "nātūrāliter"
  ],
  "syrian": [
    "syrus, syra, syrum",
    "syriacus, syriaca, syriacum"
  ],
  "apply": [
    "adhibeō, adhibēre, adhibuī, adhibitum",
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum",
    "applicō, applicāre, applicāvī, applicātum"
  ],
  "hostile encounter of two persons or parties": [
    "simultās, simultātis"
  ],
  "copy off or transcribe something from the original": [
    "dēscrībō, dēscrībere, dēscrīpsī, dēscrīptum"
  ],
  "write down": [
    "dēscrībō, dēscrībere, dēscrīpsī, dēscrīptum"
  ],
  "join or connect": [
    "coagmentō, coagmentāre, coagmentāvī, coagmentātum"
  ],
  "carry before": [
    "antefero, anteferre, antetulī, antelatum",
    "anteferō, anteferre, antetulī, antelatum"
  ],
  "having two banks of oars": [
    "birēmis, birēme"
  ],
  "a squad of soldiers sharing a single tent": [
    "contubernium, contuberniī"
  ],
  "usually 6–8 men": [
    "contubernium, contuberniī"
  ],
  "drawing": [
    "tractus, tractūs",
    "dēductiō, dēductiōnis"
  ],
  "dragging": [
    "tractus, tractūs"
  ],
  "hauling": [
    "tractus, tractūs"
  ],
  "pulling": [
    "tractus, tractūs"
  ],
  "sidelong": [
    "līmus, līma, līmum",
    "oblīquus, oblīqua, oblīquum"
  ],
  "slanting": [
    "oblīquus, oblīqua, oblīquum"
  ],
  "awry": [
    "oblīquus, oblīqua, oblīquum"
  ],
  "oblique": [
    "oblīquus, oblīqua, oblīquum"
  ],
  "sow or plant with or in": [
    "cōnserō, cōnserere, cōnsēvī, cōnsatum"
  ],
  "of or pertaining to war": [
    "bellicus, bellica, bellicum"
  ],
  "consolation": [
    "cōnsōlātiō, cōnsōlātiōnis"
  ],
  "transfer of property": [
    "aliēnātiō, aliēnātiōnis"
  ],
  "hold together": [
    "cohibeō, cohibēre, cohibuī, cohibitum",
    "contineō, continēre, continuī, contentum"
  ],
  "confine": [
    "cohibeō, cohibēre, cohibuī, cohibitum"
  ],
  "comprise": [
    "cohibeō, cohibēre, cohibuī, cohibitum"
  ],
  "go bird-catching or fowling": [
    "aucupor, aucupārī, aucupātus sum"
  ],
  "stretch": [
    "tendō, tendere, tendī, tentus",
    "porrigō, porrigere, porrexī, porrectum"
  ],
  "running or flowing down": [
    "dēcursiō, dēcursōnis"
  ],
  "inland": [
    "mediterrāneus, mediterrānea, mediterrāneum"
  ],
  "precedent": [
    "praeiūdicium, praeiūdiciī"
  ],
  "or not": [
    "necne"
  ],
  "out of one's mind or senses": [
    "dēmēns, dēmentis"
  ],
  "mad": [
    "dēmēns, dēmentis",
    "vēcors, vēcordis",
    "īnsānus, īnsāna, īnsānum",
    "vēsānus, vēsāna, vēsānum"
  ],
  "raving": [
    "dēmēns, dēmentis",
    "rabidus, rabida, rabidum"
  ],
  "insane": [
    "dēmēns, dēmentis",
    "vēcors, vēcordis",
    "īnsānus, īnsāna, īnsānum",
    "vēsānus, vēsāna, vēsānum",
    "īnsāniō, īnsānīre, īnsānīvī, īnsānītum"
  ],
  "a taking forth": [
    "prōmptus, prōmptūs"
  ],
  "seashore": [
    "acta, actae"
  ],
  "cunning": [
    "astūtia, astūtiae",
    "vafer, vafra, vafrum",
    "dolōsus, dolōsa, dolōsum",
    "callidus, callida, callidum",
    "īnsidiōsus, īnsidiōsa, īnsidiōsum"
  ],
  "insidious": [
    "īnsidiōsus, īnsidiōsa, īnsidiōsum"
  ],
  "repose": [
    "acquiēscō, acquiēscere, acquiēvī, acquiētum"
  ],
  "be greatly frightened": [
    "extimēscō, extimēscere, extimuī"
  ],
  "love ardently or zealously": [
    "zēlō, zēlāre, zēlāvī, zēlātum"
  ],
  "very dense": [
    "condēnsus, condēnsa, condēnsum"
  ],
  "set apart": [
    "discernō, discernere, discrēvī, discrētum"
  ],
  "realize or discern": [
    "agnōscō, agnōscere, agnōvī, agnitum"
  ],
  "a protector": [
    "patrōnus, patrōnī"
  ],
  "to write back in reply": [
    "rescrībō, rescrībere, rescrīpsī, rescrīptum"
  ],
  "cause to repent": [
    "paeniteō, paenitēre, paenituit"
  ],
  "unfaithfulness": [
    "īnfidēlitās, īnfidēlitātis"
  ],
  "infidelity": [
    "īnfidēlitās, īnfidēlitātis"
  ],
  "that nowhere": [
    "nēcubi"
  ],
  "satisfy": [
    "satiō, satiāre, satiāvī, satiātum",
    "saturō, saturāre, saturāvī, saturātum",
    "satisfaciō, satisfacere, satisfēcī, satisfactum"
  ],
  "content": [
    "satisfaciō, satisfacere, satisfēcī, satisfactum"
  ],
  "give birth": [
    "ēnītor, ēnītī, ēnixus"
  ],
  "or relating to iron": [
    "ferrārius, ferrāria, ferrārium"
  ],
  "repeatedly": [
    "īdentidem",
    "saepenumerō"
  ],
  "deception": [
    "captiō, captiōnis",
    "fallācia, fallāciae",
    "dēceptiō, dēceptiōnis",
    "frūstrātiō, frūstrātiōnis"
  ],
  "salt pan": [
    "salīna, salīnae"
  ],
  "salt works": [
    "salīna, salīnae"
  ],
  "intermediary": [
    "internūntius, internūntia, internūntium"
  ],
  "the skin or hide of an animal stripped off": [
    "spolium, spoliī"
  ],
  "am acquainted": [
    "cōgnōscō, cōgnōscere, cōgnōvī, cōgnitum"
  ],
  "commence": [
    "coepiō, coepere, coepī, coeptum",
    "occipiō, occipere, occēpī, occeptum",
    "incohō, incohāre, incohāvī, incohātum"
  ],
  "initiate": [
    "coepiō, coepere, coepī, coeptum"
  ],
  "correctly": [
    "rēctē"
  ],
  "forthwith": [
    "extemplō",
    "quamprīmum"
  ],
  "with me": [
    "mēcum"
  ],
  "with you": [
    "tēcum"
  ],
  "with thee": [
    "tēcum"
  ],
  "popular": [
    "populāris, populāre",
    "grātiōsus, grātiōsa, grātiōsum"
  ],
  "tit": [
    "parus, pari"
  ],
  "a plaintiff or complainant": [
    "quaerēns, quaerentis"
  ],
  "philosophical": [
    "philosophus, philosopha, philosophum",
    "philosophicus, philosophica, philosophicum"
  ],
  "discernment": [
    "sapientia, sapientiae"
  ],
  "diligently": [
    "impigrē",
    "dīligenter"
  ],
  "a proof": [
    "argūmentum, argūmentī"
  ],
  "weight": [
    "Pondus",
    "pondus, ponderis",
    "gravitās, gravitātis"
  ],
  "heaviness": [
    "gravitās, gravitātis"
  ],
  "wonder or marvel": [
    "mīrō, mīrāre, mīrāvī, mīrātum"
  ],
  "productive": [
    "cōnficiēns, cōnficiēntis",
    "fructuōsus, fructuōsa, fructuōsum",
    "quaestuōsus, quaestuōsa, quaestuōsum"
  ],
  "a returning": [
    "reditus, reditūs"
  ],
  "turning back": [
    "reditus, reditūs"
  ],
  "going back": [
    "reditus, reditūs"
  ],
  "thinking": [
    "cōgitātiō, cōgitātiōnis"
  ],
  "meditation": [
    "cōgitātiō, cōgitātiōnis",
    "commentātiō, commentātiōnis"
  ],
  "reflection": [
    "cōgitātiō, cōgitātiōnis"
  ],
  "openly in the presence of someone": [
    "palam"
  ],
  "openly before someone": [
    "palam"
  ],
  "punic": [
    "pūnicus, pūnica, pūnicum"
  ],
  "carthaginian": [
    "pūnicus, pūnica, pūnicum"
  ],
  "reminder": [
    "monumentum, monumentī",
    "admonitiō, admonitiōnis"
  ],
  "memorial": [
    "memoriālis, memoriāle",
    "monumentum, monumentī"
  ],
  "the indus river": [
    "indus, indī"
  ],
  "prosperous": [
    "prosperus, prospera, prosperum",
    "fortūnātus, fortūnāta, fortūnātum"
  ],
  "successful": [
    "prosperus, prospera, prosperum"
  ],
  "difference": [
    "varietās, varietātis",
    "variātiō, variātiōnis",
    "discrīmen, discrīminis",
    "differentia, differentiae",
    "distinctiō, distinctiōnis",
    "dissimilitūdō, dissimilitūdinis"
  ],
  "being able": [
    "potēns, potentis"
  ],
  "potent": [
    "potēns, potentis"
  ],
  "teaching": [
    "doctrīna, doctrīnae",
    "disciplīna, disciplīnae"
  ],
  "instruction": [
    "Lectiones",
    "doctrīna, doctrīnae"
  ],
  "an apple tree": [
    "mālus, mālī"
  ],
  "troublesomeness": [
    "molestia, molestiae"
  ],
  "irksomeness": [
    "molestia, molestiae"
  ],
  "illness": [
    "aegritūdō, aegritūdinis",
    "aegrōtātiō, aegrōtātiōnis"
  ],
  "obedient": [
    "pārēns, pārentis"
  ],
  "recount": [
    "narrō, narrāre, narrāvī, narrātum",
    "intimō, intimāre, intimāvī, intimātum"
  ],
  "albeit": [
    "etiamsī"
  ],
  "hardness": [
    "rōbur, rōboris",
    "dūritia, dūritiae"
  ],
  "whelp": [
    "catulus, catulī"
  ],
  "puppy": [
    "catulus, catulī"
  ],
  "examination": [
    "cognitiō, cognitiōnis",
    "inquīsītiō, inquīsītiōnis",
    "interrogātiō, interrogātiōnis"
  ],
  "inquiry": [
    "cognitiō, cognitiōnis",
    "inquīsītiō, inquīsītiōnis",
    "percontatiō, percontatiōnis",
    "interrogātiō, interrogātiōnis",
    "investīgātiō, investīgātiōnis"
  ],
  "investigation": [
    "cognitiō, cognitiōnis",
    "inquīsītiō, inquīsītiōnis",
    "investīgātiō, investīgātiōnis"
  ],
  "an inquiry or proposal to the people for passing a law or decree": [
    "rogātiō, rogātiōnis"
  ],
  "a proposed law": [
    "rogātiō, rogātiōnis"
  ],
  "decree or bill": [
    "rogātiō, rogātiōnis"
  ],
  "honour": [
    "honestās, honestātis",
    "honōrificō, honōrificāre, honōrificāvī, honōrificātum"
  ],
  "integrity": [
    "honestās, honestātis"
  ],
  "surely": [
    "quippe",
    "quīppe"
  ],
  "by all means": [
    "quippe"
  ],
  "declare or announce": [
    "dēclārō, dēclārāre, dēclārāvī, dēclārātum"
  ],
  "the act of tilling or cultivating": [
    "cultus, cultūs"
  ],
  "aedile": [
    "aedīlis, aedīlis",
    "aedīlīcius, aedīlīcia, aedīlīcium"
  ],
  "commissioner of works": [
    "aedīlis, aedīlis"
  ],
  "too late": [
    "sērus, sēra, sērum"
  ],
  "transparent": [
    "diaphanus, diaphana, diaphanum",
    "perlucidus, perlucida, perlucidum",
    "perspicuus, perspicua, perspicuum"
  ],
  "spaniard": [
    "hispānus, hispānī"
  ],
  "patrician": [
    "pātricius, pātricia, pātricium"
  ],
  "perchance": [
    "forsan",
    "fōrsitan"
  ],
  "of a deity": [
    "dīvīnus, dīvīna, dīvīnum"
  ],
  "superhuman": [
    "dīvīnus, dīvīna, dīvīnum"
  ],
  "supernatural": [
    "dīvīnus, dīvīna, dīvīnum"
  ],
  "omen": [
    "prōdigium, prōdigiī"
  ],
  "portent": [
    "ostentum, ostentī",
    "prōdigium, prōdigiī"
  ],
  "prophetic sign": [
    "prōdigium, prōdigiī"
  ],
  "grandfather": [
    "avus, avī",
    "avītus, avīta, avītum"
  ],
  "stammering": [
    "balbus, balba, balbum",
    "blaesus, blaesa, blaesum"
  ],
  "stuttering": [
    "balbus, balba, balbum"
  ],
  "an agreement together": [
    "concordia, concordiae"
  ],
  "union": [
    "coetus, coetūs",
    "coniugium, coniugiī",
    "concordia, concordiae",
    "cōnfūsiō, cōnfūsiōnis",
    "adiunctiō, adiunctiōnis",
    "conciliātiō, conciliātiōnis",
    "cōnspīrātiō, cōnspīrātiōnis"
  ],
  "harmony": [
    "Harmonia",
    "harmonia, harmoniae",
    "concordia, concordiae",
    "cōnspīrātiō, cōnspīrātiōnis",
    "convenientia, convenientiae"
  ],
  "harmless": [
    "innocēns, innocentis",
    "innocuus, innocua, innocuum",
    "innoxius, innoxia, innoxium"
  ],
  "inoffensive": [
    "innocēns, innocentis"
  ],
  "innoxious": [
    "innocēns, innocentis"
  ],
  "further": [
    "porrō, porrius, porrissimē",
    "prosperō, prosperāre, prosperāvī, prosperātum"
  ],
  "bring or hold to or near": [
    "attineō, attinēre, attinuī, attentum"
  ],
  "but anyhow": [
    "atquī"
  ],
  "but anyway": [
    "atquī"
  ],
  "prove": [
    "arguō, arguere, arguī, argūtum",
    "probō, probāre, probāvī, probātum",
    "argūmentor, argūmentārī, argūmentātus sum"
  ],
  "athenian": [
    "athēniēnsis, athēniēnse"
  ],
  "plebeian": [
    "plēbēius, plēbēia, plēbēium"
  ],
  "diversity": [
    "varietās, varietātis"
  ],
  "variety": [
    "varietās, varietātis"
  ],
  "an accusation": [
    "accūsātiō, accūsātiōnis"
  ],
  "indictment": [
    "accūsātiō, accūsātiōnis"
  ],
  "a pointer": [
    "index, indicis"
  ],
  "indicator": [
    "index, indicis"
  ],
  "rootstock": [
    "stirps, stirpis"
  ],
  "the lowest part of the trunk of a plant": [
    "stirps, stirpis"
  ],
  "including the roots": [
    "stirps, stirpis"
  ],
  "a swelling": [
    "torus, torī"
  ],
  "protuberance": [
    "torus, torī"
  ],
  "bulge": [
    "torus, torī"
  ],
  "knot": [
    "nōdus, nōdī",
    "torus, torī",
    "articulus, articulī"
  ],
  "spectacle": [
    "spectāculum, spectāculī"
  ],
  "tribuneship": [
    "tribūnātus, tribūnātūs"
  ],
  "ugliness": [
    "turpitūdō, turpitūdinis",
    "dēfōrmitās, dēfōrmitātis"
  ],
  "unsightliness": [
    "turpitūdō, turpitūdinis"
  ],
  "foulness": [
    "foeditās, foeditātis",
    "turpitūdō, turpitūdinis"
  ],
  "deformity": [
    "prāvitās, prāvitātis",
    "turpitūdō, turpitūdinis",
    "dēfōrmitās, dēfōrmitātis"
  ],
  "in the way of": [
    "obstō, obstāre, obstitī, obstātum"
  ],
  "dearness": [
    "cāritās, cāritātis"
  ],
  "undutiful": [
    "impius, impia, impium"
  ],
  "so often": [
    "totiēns"
  ],
  "a disease": [
    "pestis, pestis"
  ],
  "cling": [
    "haereō, haerēre, haesī, haesum",
    "cohaereō, cohaerēre, cohaesī, cohaesum"
  ],
  "cleave": [
    "findō, findere, fidī, fissum",
    "haereō, haerēre, haesī, haesum",
    "inhaereō, inhaerēre, inhaesī, inhaesum"
  ],
  "adhere": [
    "haereō, haerēre, haesī, haesum",
    "cohaereō, cohaerēre, cohaesī, cohaesum"
  ],
  "divination": [
    "auspicium, auspiciī",
    "dīvīnātiō, dīvīnātiōnis"
  ],
  "prophecy": [
    "prophētīa, prophētīae",
    "dīvīnātiō, dīvīnātiōnis",
    "vāticinātiō, vāticinātiōnis"
  ],
  "misfortune": [
    "plāga, plāgae",
    "miseria, miseriae",
    "īnfortūnium, īnfortūniī",
    "īnfēlīcitās, īnfēlīcitātis"
  ],
  "bloom": [
    "flōreō, flōrēre, flōruī"
  ],
  "blossom": [
    "flōreō, flōrēre, flōruī"
  ],
  "dream": [
    "somnium, somniī",
    "somniō, somniāre, somniāvī, somniātum"
  ],
  "lightning": [
    "Tonitrus",
    "fulmen, fulminis",
    "fulmineus, fulminea, fulmineum"
  ],
  "a disgraceful action": [
    "flāgitium, flāgitiī"
  ],
  "shameful crime": [
    "flāgitium, flāgitiī"
  ],
  "of or pertaining to land": [
    "agrestis, agreste"
  ],
  "fields or the countryside": [
    "agrestis, agreste"
  ],
  "endowed": [
    "praeditus, praedita, praeditum"
  ],
  "gifted": [
    "praeditus, praedita, praeditum"
  ],
  "very many": [
    "permultus, permulta, permultum"
  ],
  "father's": [
    "patrius, patria, patrium"
  ],
  "look up at or to": [
    "suspiciō, suspicere, suspexī, suspectum"
  ],
  "intimacy": [
    "familiāritās, familiāritātis"
  ],
  "scatter or sow seed": [
    "disserō, disserere, dissēvī, dissitum"
  ],
  "a potion": [
    "venēnum, venēnī"
  ],
  "juice": [
    "sūcus, sūcī",
    "venēnum, venēnī"
  ],
  "inward": [
    "penitus, penita, penitum",
    "intestīnus, intestīna, intestīnum"
  ],
  "particularly": [
    "utique",
    "praesertim",
    "pecūliāriter",
    "singulariter"
  ],
  "offering": [
    "hostia, hostiae",
    "oblātiō, oblātiōnis",
    "anathema, anathematis"
  ],
  "everlasting": [
    "aeternus, aeterna, aeternum",
    "sempiternus, sempiterna, sempiternum"
  ],
  "eternal": [
    "aeternus, aeterna, aeternum",
    "sempiternus, sempiterna, sempiternum"
  ],
  "everyday": [
    "cotīdiānus, cotīdiāna, cotīdiānum"
  ],
  "quotidian": [
    "cotīdiānus, cotīdiāna, cotīdiānum"
  ],
  "straightforward": [
    "prorsus, prorsa, prorsum"
  ],
  "right onwards": [
    "prorsus, prorsa, prorsum"
  ],
  "a wave": [
    "fluctus, fluctūs"
  ],
  "billow": [
    "fluctus, fluctūs"
  ],
  "unbound": [
    "Solutus",
    "solūtus, solūta, solūtum"
  ],
  "released": [
    "solūtus, solūta, solūtum"
  ],
  "of the market or forum": [
    "forēnsis, forēnse"
  ],
  "variance": [
    "discordia, discordiae"
  ],
  "discord": [
    "discordia, discordiae"
  ],
  "diviner who reads from the intestines of sacrificial animals": [
    "haruspex, haruspicis"
  ],
  "one who practices haruspicy": [
    "haruspex, haruspicis"
  ],
  "an instance of being alone": [
    "sōlitūdō, sōlitūdinis"
  ],
  "loneliness": [
    "sōlitūdō, sōlitūdinis"
  ],
  "solitariness": [
    "sōlitūdō, sōlitūdinis"
  ],
  "solitude": [
    "Solitudo",
    "Solitude",
    "Solitudini",
    "Solitudinem",
    "sēcessus, sēcessūs",
    "sōlitūdō, sōlitūdinis"
  ],
  "videlicet: namely": [
    "vidēlicet"
  ],
  "to wit": [
    "vidēlicet"
  ],
  "that is to say": [
    "vidēlicet"
  ],
  "thoroughly moved": [
    "sollicitus, sollicita, sollicitum"
  ],
  "agitated or disturbed": [
    "sollicitus, sollicita, sollicitum"
  ],
  "restless": [
    "inquiētus, inquiēta, inquiētum",
    "sollicitus, sollicita, sollicitum",
    "tumultuōsus, tumultuōsa, tumultuōsum"
  ],
  "unceasing": [
    "sollicitus, sollicita, sollicitum"
  ],
  "keep safe": [
    "salūtō, salūtāre, salūtāvī, salūtātum"
  ],
  "put or place in front of": [
    "antepōnō, antepōnere, anteposuī, antepositum"
  ],
  "irritability": [
    "īrācundia, īrācundiae"
  ],
  "a proneness to anger": [
    "īrācundia, īrācundiae"
  ],
  "hastiness of temper": [
    "īrācundia, īrācundiae"
  ],
  "irascibility": [
    "īrācundia, īrācundiae"
  ],
  "sheen": [
    "splendor, splendōris"
  ],
  "brightness": [
    "Lumenositas",
    "candor, candōris",
    "clāritās, clāritātis",
    "splendor, splendōris"
  ],
  "brilliance": [
    "splendor, splendōris"
  ],
  "lustre": [
    "splendor, splendōris"
  ],
  "splendor": [
    "iubar, iubaris",
    "splendor, splendōris"
  ],
  "lightly": [
    "leviter, levius, levissimē"
  ],
  "not heavily": [
    "leviter, levius, levissimē"
  ],
  "condemned": [
    "damnātus, damnāta, damnātum"
  ],
  "condemn": [
    "damnō, damnāre, damnāvī, damnātum",
    "abdīcō, abdīcere, abdīxī, abdictum",
    "condemnō, condemnāre, condemnāvī, condemnātum"
  ],
  "convict": [
    "condemnō, condemnāre, condemnāvī, condemnātum"
  ],
  "poise": [
    "lībrō, lībrāre, lībrāvī, lībrātum",
    "compēnsō, compēnsāre, compēnsāvī, compēnsātum"
  ],
  "balance": [
    "Trutina",
    "statēra, statērae",
    "lībrō, lībrāre, lībrāvī, lībrātum",
    "compēnsō, compēnsāre, compēnsāvī, compēnsātum"
  ],
  "by weight": [
    "pondō"
  ],
  "in weight": [
    "pondō"
  ],
  "bride": [
    "nūpta, nūptae",
    "nympha, nymphae",
    "spōnsa, spōnsae"
  ],
  "by hercules": [
    "mehercule"
  ],
  "a listening": [
    "audītus, audītūs",
    "audītiō, audītiōnis"
  ],
  "hearing": [
    "audītus, audītūs",
    "audītiō, audītiōnis"
  ],
  "skilled in speaking": [
    "disertus, diserta, disertum"
  ],
  "a furnishing": [
    "ornātus, ornātūs"
  ],
  "preparing": [
    "ornātus, ornātūs",
    "cōnfectiō, cōnfectiōnis"
  ],
  "a constitution": [
    "cōnstitūtiō, cōnstitūtiōnis"
  ],
  "disposition": [
    "habitus, habitūs",
    "locātiō, locātiōnis",
    "īnstitūtiō, īnstitūtiōnis",
    "cōnstitūtiō, cōnstitūtiōnis"
  ],
  "surname": [
    "cōgnōmen, cōgnōminis"
  ],
  "folly": [
    "ineptia, ineptiae",
    "sōcordia, sōcordiae",
    "stultitia, stultitiae"
  ],
  "stupidity": [
    "stultitia, stultitiae"
  ],
  "foolishness": [
    "stultitia, stultitiae",
    "īnsipientia, īnsipientiae"
  ],
  "simplicity": [
    "stultitia, stultitiae",
    "simplicitās, simplicitātis"
  ],
  "silliness": [
    "ineptia, ineptiae",
    "sōcordia, sōcordiae",
    "stultitia, stultitiae"
  ],
  "fatuity": [
    "stultitia, stultitiae"
  ],
  "helpless": [
    "inops, inopis",
    "impotēns, impotentis"
  ],
  "destitute": [
    "inops, inopis"
  ],
  "indigent": [
    "inops, inopis",
    "mendīcus, mendīca, mendīcum"
  ],
  "to you": [
    "istuc"
  ],
  "in that direction": [
    "illāc",
    "istuc"
  ],
  "haughtiness": [
    "superbia, superbiae"
  ],
  "father-in-law": [
    "socer, socerī"
  ],
  "a beginning": [
    "orīgō, orīginis"
  ],
  "an origin": [
    "orīgō, orīginis"
  ],
  "apparent": [
    "ēvidēns, ēvidentis",
    "manifestus, manifesta, manifestum"
  ],
  "palpable": [
    "manifestus, manifesta, manifestum"
  ],
  "manifest": [
    "ēvidēns, ēvidentis",
    "manifestus, manifesta, manifestum"
  ],
  "evident": [
    "ēvidēns, ēvidentis",
    "pateō, patēre, patuī",
    "manifestus, manifesta, manifestum"
  ],
  "annually": [
    "sollemnis, sollemne"
  ],
  "an agent between two parties": [
    "interpres, interpretis"
  ],
  "broker": [
    "īnstitor, īnstitōris",
    "interpres, interpretis"
  ],
  "negotiator": [
    "interpres, interpretis"
  ],
  "factor": [
    "interpres, interpretis"
  ],
  "a taking": [
    "captus, captūs",
    "perceptiō, perceptiōnis"
  ],
  "solace": [
    "sōlācium, sōlāciī",
    "sōlor, sōlārī, sōlātus sum"
  ],
  "pay attention": [
    "attendō, attendere, attendī, attentum"
  ],
  "attend ": [
    "attendō, attendere, attendī, attentum"
  ],
  "wisely": [
    "prūdenter, prūdentius, prūdentissimē",
    "sapienter, sapientius, sapientissimē"
  ],
  "sensibly": [
    "sapienter, sapientius, sapientissimē"
  ],
  "a grandson": [
    "nepōs, nepōtis"
  ],
  "seize and carry off": [
    "raptō, raptāre, raptāvī, raptātum"
  ],
  "amusement": [
    "dēlectātiō, dēlectātiōnis"
  ],
  "likely": [
    "probābilis, probābile"
  ],
  "credible": [
    "probābilis, probābile"
  ],
  "probable": [
    "probābilis, probābile"
  ],
  "plausible": [
    "probābilis, probābile"
  ],
  "of or pertaining to a chariot": [
    "curūlis, curūle"
  ],
  "groan": [
    "gemitus, gemitūs",
    "gemō, gemere, gemuī, gemitum",
    "ingemō, ingemere, ingemuī, ingemitum"
  ],
  "sigh": [
    "gemitus, gemitūs",
    "gemō, gemere, gemuī, gemitum",
    "suspīrō, suspīrāre, suspīrāvī, suspīrātum"
  ],
  "lamentation": [
    "gemitus, gemitūs",
    "maeror, maerōris",
    "lāmentātiō, lāmentātiōnis"
  ],
  "cause to rejoice": [
    "laetō, laetāre, laetāvī, laetātum"
  ],
  "definition": [
    "dēfīnītiō, dēfīnītiōnis"
  ],
  "precise description": [
    "dēfīnītiō, dēfīnītiōnis"
  ],
  "argumentation": [
    "argūmentātiō, argūmentātiōnis"
  ],
  "going two ways": [
    "ambiguus, ambigua, ambiguum"
  ],
  "hither and thither": [
    "ambiguus, ambigua, ambiguum"
  ],
  "moving from side-to-side": [
    "ambiguus, ambigua, ambiguum"
  ],
  "set in motion": [
    "mōtō, mōtare, mōtāvī, mōtātum"
  ],
  "levity": [
    "levitās, levitātis"
  ],
  "lightness": [
    "levitās, levitātis"
  ],
  "the act of laying waste or plundering": [
    "populātiō, populātiōnis"
  ],
  "sticky": [
    "lentus, lenta, lentum"
  ],
  "tenacious": [
    "lentus, lenta, lentum"
  ],
  "placid": [
    "placidus, placida, placidum"
  ],
  "peaceful": [
    "placidus, placida, placidum"
  ],
  "improbity": [
    "improbitās, improbitātis"
  ],
  "disputation": [
    "disceptātiō, disceptātiōnis"
  ],
  "debate": [
    "altercātiō, altercātiōnis",
    "disceptātiō, disceptātiōnis",
    "causor, causārī, causātus sum",
    "disputō, disputāre, disputāvī, disputātum"
  ],
  "discussion": [
    "dialogus, dialogī",
    "disceptātiō, disceptātiōnis"
  ],
  "fireplace": [
    "Focus",
    "focus, focī"
  ],
  "hearth": [
    "focus, focī",
    "penās, penātis"
  ],
  "pledge": [
    "pignus, pignoris",
    "pignerō, pignerāre, pignerāvī, pignerātum",
    "dēspondeō, dēspondēre, dēspondī, dēspōnsum"
  ],
  "mortgage": [
    "pignus, pignoris",
    "pignerō, pignerāre, pignerāvī, pignerātum"
  ],
  "artist": [
    "opifex, opificis",
    "artifex, artificis"
  ],
  "actor": [
    "artifex, artificis"
  ],
  "commendation": [
    "laudātiō, laudātiōnis"
  ],
  "a kind of little worm or larva": [
    "galba, galbae"
  ],
  "hastily": [
    "cursim",
    "raptim"
  ],
  "speedily": [
    "cursim",
    "raptim"
  ],
  "hurriedly": [
    "raptim"
  ],
  "history": [
    "historia, historiae"
  ],
  "call to mind": [
    "recordor, recordārī, recordātus sum",
    "repetō, repetere, repetīvī, repetītum"
  ],
  "mourning": [
    "maeror, maerōris"
  ],
  "the state of being out of one's senses": [
    "āmentia, āmentiae"
  ],
  "madness": [
    "āmentia, āmentiae",
    "īnsānia, īnsāniae",
    "dēmentia, dēmentiae"
  ],
  "insanity": [
    "āmentia, āmentiae",
    "īnsānia, īnsāniae",
    "dēmentia, dēmentiae"
  ],
  "sweetness": [
    "dulcēdō, dulcēdinis",
    "suāvitās, suāvitātis"
  ],
  "melodiousness": [
    "suāvitās, suāvitātis"
  ],
  "attractiveness": [
    "suāvitās, suāvitātis",
    "venustās, venustātis"
  ],
  "turn upside down": [
    "ēvertō, ēvertere, ēvertī, ēversum"
  ],
  "overturn": [
    "ēvertō, ēvertere, ēvertī, ēversum",
    "subvertō, subvertere, subvertī, subversum"
  ],
  "reverse": [
    "ēvertō, ēvertere, ēvertī, ēversum"
  ],
  "overwhelm or overthrow": [
    "obruō, obruere, obruī, obrutum"
  ],
  "bog": [
    "lustrum, lustrī"
  ],
  "morass": [
    "lustrum, lustrī"
  ],
  "place where boars and swine wallow": [
    "lustrum, lustrī"
  ],
  "ice-cold": [
    "gelidus, gelida, gelidum"
  ],
  "icy": [
    "glaciālis, glaciāle",
    "gelidus, gelida, gelidum"
  ],
  "frosty": [
    "gelidus, gelida, gelidum"
  ],
  "closest to the front": [
    "prīmōris, prīmōre"
  ],
  "with us": [
    "nōbīscum"
  ],
  "a statue": [
    "statua, statuae"
  ],
  "especially one made of metal": [
    "statua, statuae"
  ],
  "any internal organ of the body": [
    "viscus, visceris"
  ],
  "circuit": [
    "ambāgēs, ambāgis",
    "ambitus, ambitūs"
  ],
  "bribery": [
    "Largitium",
    "ambitus, ambitūs",
    "corruptēla, corruptēlae"
  ],
  "fortification": [
    "mūnīmentum, mūnīmentī"
  ],
  "lawful": [
    "lēgitimus, lēgitima, lēgitimum"
  ],
  "legal": [
    "lēgālis, lēgāle",
    "lēgitimus, lēgitima, lēgitimum"
  ],
  "very old": [
    "pervetus, perveteris",
    "senectus, senecta, senectum"
  ],
  "cause someone to be absent": [
    "absentō, absentāre, absentāvī, absentātum"
  ],
  "herald": [
    "praecō, praecōnis"
  ],
  "crier": [
    "praecō, praecōnis"
  ],
  "backwards": [
    "retrō",
    "retrōrsum"
  ],
  "to whatever place": [
    "quōnam"
  ],
  "whither": [
    "quōnam",
    "quorsum"
  ],
  "stand out or project": [
    "exstō, exstāre"
  ],
  "a bearing": [
    "partus, partūs"
  ],
  "bringing forth": [
    "partus, partūs"
  ],
  "copy": [
    "effigiēs, effigiēī",
    "aemulor, aemulārī, aemulātus sum",
    "imitō, imitāre, imitāvī, imitātum"
  ],
  "mimic": [
    "hypocrita, hypocritae",
    "imitō, imitāre, imitāvī, imitātum"
  ],
  "enclosed space": [
    "clausum, clausī"
  ],
  "carelessness": [
    "neglegentia, neglegentiae"
  ],
  "heedlessness": [
    "neglegentia, neglegentiae"
  ],
  "negligence": [
    "nēquitia, nēquitiae",
    "neglegentia, neglegentiae"
  ],
  "accessible": [
    "patēns",
    "pateō, patēre, patuī"
  ],
  "a joke": [
    "iocus, iocī"
  ],
  "jest": [
    "iocus, iocī",
    "facētia, facētiae",
    "iocor, iocārī, iocātus sum"
  ],
  "aristocrat": [
    "optimās, optimātis",
    "optumās, optumātis"
  ],
  "be against": [
    "obsum, obesse, obfuī, obfutūrus",
    "abdīcō, abdīcere, abdīxī, abdictum"
  ],
  "do harm to": [
    "obsum, obesse, obfuī, obfutūrus",
    "noceō, nocēre, nocuī, nocitum + dat"
  ],
  "sprawling": [
    "effūsus, effūsa, effūsum"
  ],
  "without skill": [
    "iners, inertis"
  ],
  "unskillful": [
    "iners, inertis"
  ],
  "incompetent": [
    "iners, inertis"
  ],
  "thus far": [
    "hāctenus"
  ],
  "as far as this": [
    "hāctenus"
  ],
  "sly": [
    "vafer, vafra, vafrum",
    "callidus, callida, callidum"
  ],
  "crafty": [
    "sibus, siba, sibum",
    "vafer, vafra, vafrum",
    "dolōsus, dolōsa, dolōsum",
    "callidus, callida, callidum"
  ],
  "pious": [
    "pius, pia, pium",
    "religiōsus, religiōsa, religiōsum"
  ],
  "devout": [
    "religiōsus, religiōsa, religiōsum"
  ],
  "religious": [
    "religiōsus, religiōsa, religiōsum"
  ],
  "without command": [
    "iniussu"
  ],
  "sobriety": [
    "sōbrietās, sōbrietātis",
    "temperantia, temperantiae"
  ],
  "temperance": [
    "frūgālitās, frūgālitātis",
    "temperantia, temperantiae",
    "contingentia, contingentiae"
  ],
  "self control": [
    "temperantia, temperantiae"
  ],
  "sole": [
    "ūnicus, ūnica, ūnicum"
  ],
  "receive or catch": [
    "concipiō, concipere, concēpī, conceptum"
  ],
  "agrarian": [
    "āgrārius, āgrāria, āgrārium"
  ],
  "natural": [
    "genuīnus, genuīna, genuīnum",
    "ingenuus, ingenua, ingenuum",
    "physicus, physica, physicum"
  ],
  "nervous": [
    "trepidus, trepida, trepidum"
  ],
  "jumpy": [
    "trepidus, trepida, trepidum"
  ],
  "flamen": [
    "flāmen, flāminis"
  ],
  "charcoal": [
    "Carbo",
    "carbō, carbōnis"
  ],
  "coal": [
    "carbō, carbōnis"
  ],
  "a going around or about": [
    "ambitiō, ambitiōnis"
  ],
  "that which surrounds": [
    "ambitiō, ambitiōnis"
  ],
  "incite": [
    "irrītō, irrītāre, irrītāvī, irrītātum",
    "īnstīgō, īnstīgāre, īnstīgāvī, īnstīgātum"
  ],
  "irritate": [
    "irrītō, irrītāre, irrītāvī, irrītātum",
    "exacerbō, exacerbāre, exacerbāvī, exacerbātum"
  ],
  "grievance": [
    "querēla, querēlae"
  ],
  "liquid": [
    "hūmor, hūmōris",
    "fūsilis, fūsile",
    "fluidus, fluida, fluidum",
    "liquidus, liquida, liquidum"
  ],
  "fluid": [
    "hūmor, hūmōris",
    "fūsilis, fūsile",
    "liqueō, liquēre, licuī",
    "fluidus, fluida, fluidum",
    "liquidus, liquida, liquidum"
  ],
  "flowing": [
    "fluidus, fluida, fluidum",
    "liquidus, liquida, liquidum"
  ],
  "conclude": [
    "conclūdō, conclūdere, conclūsī, conclūsum"
  ],
  "restricted": [
    "contractus, contracta, contractum"
  ],
  "pinched": [
    "contractus, contracta, contractum"
  ],
  "cramped": [
    "contractus, contracta, contractum"
  ],
  "lock of hair": [
    "crīnis, crīnis"
  ],
  "plume": [
    "plūma, plūmae",
    "crīnis, crīnis"
  ],
  "a rough": [
    "cautēs, cautis"
  ],
  "pointed rock": [
    "cautēs, cautis"
  ],
  "valuation": [
    "aestimātiō, aestimātiōnis"
  ],
  "estimation according to its worth": [
    "aestimātiō, aestimātiōnis"
  ],
  "the act of doing": [
    "effectus, effectūs"
  ],
  "making or effecting": [
    "effectus, effectūs"
  ],
  "execution": [
    "effectus, effectūs",
    "fūnctiō, fūnctiōnis"
  ],
  "completion": [
    "effectus, effectūs",
    "perfectiō, perfectiōnis"
  ],
  "performance": [
    "effectus, effectūs",
    "fūnctiō, fūnctiōnis"
  ],
  "sharing": [
    "particeps, participis",
    "commūnicātiō, commūnicātiōnis"
  ],
  "a sinew": [
    "nervus, nervī"
  ],
  "tendon": [
    "nervus, nervī"
  ],
  "nerve": [
    "nervus, nervī"
  ],
  "chronicle": [
    "annālis, annālis"
  ],
  "annal": [
    "annālis, annālis"
  ],
  "the keel of a ship": [
    "carīna, carīnae"
  ],
  "an orator": [
    "ōrāns, ōrantis"
  ],
  "defective": [
    "perperus, perpera, perperum",
    "vitiōsus, vitiōsa, vitiōsum"
  ],
  "a juger": [
    "iūgerum"
  ],
  "a roman unit of area": [
    "iūgerum"
  ],
  "inner room": [
    "thalamus, thalamī"
  ],
  "apartment of a house": [
    "thalamus, thalamī"
  ],
  "run into or towards": [
    "incurrō, incurrere, incurrī, incursum"
  ],
  "benevolence": [
    "benignitās, benignitātis"
  ],
  "friendliness": [
    "benignitās, benignitātis"
  ],
  "courtesy": [
    "cōmitās, cōmitātis",
    "benignitās, benignitātis"
  ],
  "immense": [
    "immānis, immāne",
    "immēnsus, immēnsa, immēnsum"
  ],
  "loosen from": [
    "absolvō, absolvere, absolvī, absolūtum"
  ],
  "make loose": [
    "absolvō, absolvere, absolvī, absolūtum"
  ],
  "detach": [
    "absolvō, absolvere, absolvī, absolūtum"
  ],
  "without doubt": [
    "nīmīrum",
    "proculdubiō"
  ],
  "tutelage": [
    "tūtēla, tūtēlae"
  ],
  "guardianship": [
    "tūtēla, tūtēlae"
  ],
  "opposing": [
    "oppositiō, oppositiōnis",
    "oppositus, opposita, oppositum"
  ],
  "conveniently": [
    "commodē, commodius, commodissimē",
    "convenienter, convenientius, convenientissimē"
  ],
  "boar": [
    "Boar Skin",
    "verrēs, verris"
  ],
  "male swine": [
    "verrēs, verris"
  ],
  "paternal uncle": [
    "patruus, patruī"
  ],
  "a father's brother": [
    "patruus, patruī"
  ],
  "college": [
    "collēgium, collēgiī",
    "conlēgium, conlēgiī"
  ],
  "related by blood": [
    "cognātus, cognāta, cognātum"
  ],
  "agitation": [
    "agitātiō, agitātiōnis",
    "iactātiō, iactātiōnis",
    "trepidātiō, trepidātiōnis"
  ],
  "trepidation": [
    "trepidātiō, trepidātiōnis"
  ],
  "medicine": [
    "medicīna, medicīnae",
    "medicāmen, medicāminis",
    "medicāmentum, medicāmentī"
  ],
  "happening by chance": [
    "fōrtuītus, fōrtuīta, fōrtuītum"
  ],
  "accidental": [
    "fōrtuītus, fōrtuīta, fōrtuītum"
  ],
  "fortuitous": [
    "fōrtuītus, fōrtuīta, fōrtuītum"
  ],
  "something memorable or remarkable": [
    "memorābilis, memorābile"
  ],
  "unforgettable": [
    "memorābilis, memorābile"
  ],
  "of or pertaining to latium": [
    "latius, latia, latium"
  ],
  "that stands firm": [
    "stabilis, stabile"
  ],
  "become very frightened": [
    "expavēscō, expavēscere, expāvī",
    "pertimēscō, pertimēscere, pertimuī"
  ],
  "deliberation": [
    "cōnsultātiō, cōnsultātiōnis",
    "dēlīberātiō, dēlīberātiōnis"
  ],
  "olive": [
    "olea, oleae"
  ],
  "hinderer": [
    "offex, officis"
  ],
  "praiseworthy": [
    "laudābilis, laudābile"
  ],
  "laudable": [
    "laudābilis, laudābile"
  ],
  "prince": [
    "procer, proceris"
  ],
  "established": [
    "vetustus, vetusta, vetustum"
  ],
  "a cornfield": [
    "seges, segetis"
  ],
  "recoverer": [
    "reciperātor, reciperātōris"
  ],
  "of fate": [
    "fātālis, fātāle"
  ],
  "destined": [
    "fātālis, fātāle"
  ],
  "predestined": [
    "fātālis, fātāle"
  ],
  "a temple": [
    "dēlūbrum, dēlūbrī"
  ],
  "shortness": [
    "brevitās, brevitātis"
  ],
  "the office of a censor": [
    "cēnsūra, cēnsūrae"
  ],
  "censorship": [
    "cēnsūra, cēnsūrae"
  ],
  "interaction": [
    "coetus, coetūs"
  ],
  "academy": [
    "acadēmīa, acadēmīae"
  ],
  "academe": [
    "acadēmīa, acadēmīae"
  ],
  "covetous": [
    "avidus, avida, avidum"
  ],
  "spice": [
    "arōma, arōmatis",
    "condīmentum, condīmentī",
    "condiō, condīre, condīvī, condītum"
  ],
  "make savory": [
    "condiō, condīre, condīvī, condītum"
  ],
  "doer": [
    "āctor, āctōris"
  ],
  "wide extent": [
    "amplitūdō, amplitūdinis"
  ],
  "amplitude": [
    "amplitūdō, amplitūdinis"
  ],
  "purchase": [
    "vēnus, vēnūs",
    "ēmptiō, ēmptiōnis",
    "mancipium, mancipiī"
  ],
  "purple": [
    "purpureus, purpurea, purpureum"
  ],
  "including reddish": [
    "purpureus, purpurea, purpureum"
  ],
  "violet": [
    "ianthinus, ianthina, ianthinum",
    "purpureus, purpurea, purpureum"
  ],
  "and brownish": [
    "purpureus, purpurea, purpureum"
  ],
  "pitiable": [
    "miserābilis, miserābile"
  ],
  "deplorable": [
    "miserābilis, miserābile"
  ],
  "lamentable": [
    "miserābilis, miserābile"
  ],
  "whatsoever": [
    "haudquāquam"
  ],
  "saved": [
    "saltem"
  ],
  "reserved": [
    "saltem"
  ],
  "fall out collapse": [
    "excidō, excidere, excidī"
  ],
  "break down": [
    "excidō, excidere, excidī"
  ],
  "a large earthenware vessel": [
    "dōlium, dōliī"
  ],
  "hogshead": [
    "dōlium, dōliī"
  ],
  "yesterday's": [
    "hesternus, hesterna, hesternum"
  ],
  "seal or ratify an agreement or treaty": [
    "foederō, foederāre, foederāvī, foederātum"
  ],
  "the act of sharing or parting": [
    "partītiō, partītiōnis"
  ],
  "partition": [
    "partītiō, partītiōnis"
  ],
  "division": [
    "sexus, sexūs",
    "dīvīsiō, dīvīsiōnis",
    "partītiō, partītiōnis",
    "dīscriptiō, dīscriptiōnis",
    "distribūtiō, distribūtiōnis"
  ],
  "distribution": [
    "partītiō, partītiōnis",
    "dīscriptiō, dīscriptiōnis",
    "distribūtiō, distribūtiōnis"
  ],
  "on high": [
    "altē, altius, altissimē"
  ],
  "from on high": [
    "altē, altius, altissimē"
  ],
  "from above": [
    "altē, altius, altissimē"
  ],
  "remove or extract": [
    "eximō, eximere, exēmī, exemptum"
  ],
  "solid": [
    "solidus, solida, solidum"
  ],
  "envious": [
    "invidus, invida, invidum",
    "malevolus, malevola, malevolum",
    "invidiōsus, invidiōsa, invidiōsum",
    "invideō, invidēre, invīdī, invīsum"
  ],
  "invidious": [
    "invidiōsus, invidiōsa, invidiōsum"
  ],
  "to wherever": [
    "quōquō"
  ],
  "whithersoever": [
    "quōquō",
    "quōquam",
    "quōlibet"
  ],
  "admirable": [
    "admīrābilis, admīrābile",
    "admīrandus, admīranda, admīrandum"
  ],
  "worthy of admiration": [
    "admīrābilis, admīrābile"
  ],
  "displease": [
    "paeniteō, paenitēre, paenituī, —",
    "displiceō, displicēre, displicuī, displicuitum"
  ],
  "dressed in white": [
    "candidātus, candidāta, candidātum"
  ],
  "a serpent": [
    "serpēns, serpentis"
  ],
  "snake": [
    "coluber, colubrī",
    "serpēns, serpentis"
  ],
  "explain or expound": [
    "interpretor, interpretārī, interpretātus sum"
  ],
  "brotherly": [
    "frāternus, frāterna, frāternum"
  ],
  "fraternal": [
    "frāternus, frāterna, frāternum"
  ],
  "the moray eel or the lamprey": [
    "murēna, murēnae"
  ],
  "eaten as food": [
    "murēna, murēnae"
  ],
  "a loan": [
    "crēditum, crēditī"
  ],
  "of august": [
    "sextīlis, sextīle"
  ],
  "the sixth month of the roman calendar or eighth month of the gregorian calendar": [
    "sextīlis, sextīle"
  ],
  "shake out": [
    "excutiō, excutere, excussī, excussum"
  ],
  "shake off": [
    "excutiō, excutere, excussī, excussum"
  ],
  "elicit": [
    "excutiō, excutere, excussī, excussum"
  ],
  "knock out": [
    "excutiō, excutere, excussī, excussum"
  ],
  "according to religious usage": [
    "rīte"
  ],
  "solemnly": [
    "rīte"
  ],
  "duly": [
    "rīte"
  ],
  "searching": [
    "quaesītus, quaesītūs"
  ],
  "displeasure": [
    "indignātiō, indignātiōnis"
  ],
  "indignation": [
    "indignātiō, indignātiōnis"
  ],
  "offspring": [
    "prōlēs, prōlis"
  ],
  "orphan": [
    "pūpilla, pūpillae",
    "pūpillus, pūpillī"
  ],
  "ward": [
    "pūpilla, pūpillae",
    "pūpillus, pūpillī"
  ],
  "a small room": [
    "cella, cellae"
  ],
  "a hut": [
    "cella, cellae"
  ],
  "barn": [
    "cella, cellae"
  ],
  "granary": [
    "cella, cellae"
  ],
  "sire": [
    "genitor, genitōris"
  ],
  "erect": [
    "mōliō, mōlīre"
  ],
  "misdeed": [
    "dēlictum, dēlictī"
  ],
  "transgression": [
    "dēlictum, dēlictī"
  ],
  "mate": [
    "sodālis, sodālis"
  ],
  "fellow": [
    "sodālis, sodālis",
    "collēga, collēgae"
  ],
  "intimate": [
    "sodālis, sodālis",
    "perfamiliāris, perfamiliāre",
    "intimō, intimāre, intimāvī, intimātum"
  ],
  "comrade": [
    "sodālis, sodālis",
    "commīlitō, commīlitōnis",
    "contubernālis, contubernālis"
  ],
  "crony": [
    "sodālis, sodālis"
  ],
  "bearable": [
    "tolerābilis, tolerābile"
  ],
  "supportable": [
    "tolerābilis, tolerābile"
  ],
  "endurable": [
    "tolerābilis, tolerābile"
  ],
  "passable": [
    "tolerābilis, tolerābile",
    "pervius, pervia, pervium"
  ],
  "tolerable": [
    "tolerābilis, tolerābile"
  ],
  "a petty king": [
    "rēgulus, rēgulī"
  ],
  "kinglet": [
    "rēgulus, rēgulī"
  ],
  "a lock of wool": [
    "mallus, mallī"
  ],
  "dictatorship": [
    "dictātūra, dictātūrae"
  ],
  "office of a dictator": [
    "dictātūra, dictātūrae"
  ],
  "condemnation": [
    "damnātiō, damnātiōnis"
  ],
  "cause to lean": [
    "inclīnō, inclīnāre, inclīnāvī, inclīnātum"
  ],
  "tilt": [
    "inclīnō, inclīnāre, inclīnāvī, inclīnātum"
  ],
  "congratulate": [
    "grātulor, grātulārī, grātulātus sum"
  ],
  "put forth leaves": [
    "frondeō, frondēre, fronduī"
  ],
  "cruelly": [
    "atrōciter, atrōcius, atrōcissimē",
    "crūdēliter, crūdēlius, crūdēlissimē"
  ],
  "construction": [
    "comparātiō, comparātiōnis"
  ],
  "comparison": [
    "parabola, parabolae",
    "comparātiō, comparātiōnis"
  ],
  "debt": [
    "dēbitum, dēbitī"
  ],
  "chase away": [
    "fugō, fugāre, fugāvī, fugātum"
  ],
  "put to flight": [
    "fugō, fugāre, fugāvī, fugātum"
  ],
  "go towards": [
    "obeō, obīre, obiī, obitum"
  ],
  "go to meet": [
    "obeō, obīre, obiī, obitum",
    "oppetō, oppetere, oppetīvī, oppetītum"
  ],
  "slowly": [
    "sēnsim",
    "Leniter",
    "lentē, lentius, lentissimē",
    "segniter, segnius, segnissimē",
    "cunctanter, cunctantius, cunctantissimē"
  ],
  "lazily": [
    "segniter, segnius, segnissimē"
  ],
  "bend or lean toward or over": [
    "immineō, imminēre, imminuī, imminitum"
  ],
  "overhang": [
    "prōmineō, prōminēre, prōminuī",
    "immineō, imminēre, imminuī, imminitum"
  ],
  "hateful": [
    "odiōsus, odiōsa, odiōsum"
  ],
  "odious": [
    "odiōsus, odiōsa, odiōsum"
  ],
  "abhor": [
    "abhorreō, abhorrēre, abhorruī"
  ],
  "shudder at": [
    "abhorreō, abhorrēre, abhorruī"
  ],
  "recoil or shrink back from": [
    "abhorreō, abhorrēre, abhorruī"
  ],
  "why?": [
    "quapropter"
  ],
  "a shipwreck": [
    "naufragium, naufragiī"
  ],
  "demigod": [
    "hērōs, hērōis"
  ],
  "uplifted": [
    "sublīmis, sublīme"
  ],
  "exalted": [
    "sublīmis, sublīme"
  ],
  "deadly": [
    "lētālis, lētāle",
    "fūnestus, fūnesta, fūnestum",
    "lētifer, lētifera, lētiferum",
    "exitiōsus, exitiōsa, exitiōsum",
    "mortifer, mortifera, mortiferum"
  ],
  "fatal": [
    "lētālis, lētāle",
    "fūnestus, fūnesta, fūnestum",
    "lētifer, lētifera, lētiferum",
    "mortifer, mortifera, mortiferum"
  ],
  "religious ceremony": [
    "caerimōnia, caerimōniae"
  ],
  "ritual": [
    "caerimōnia, caerimōniae"
  ],
  "daughter-in-law": [
    "nurus, nurūs"
  ],
  "similarly": [
    "itidem",
    "similiter"
  ],
  "solemn promise": [
    "vōtum, vōtī",
    "spōnsiō, spōnsiōnis"
  ],
  "bet": [
    "spōnsiō, spōnsiōnis"
  ],
  "or agreement": [
    "spōnsiō, spōnsiōnis"
  ],
  "outline": [
    "fōrmula, fōrmulae"
  ],
  "follower": [
    "successor, successōris"
  ],
  "successor": [
    "successor, successōris"
  ],
  "stiff": [
    "rigidus, rigida, rigidum",
    "squālidus, squālida, squālidum"
  ],
  "rigid": [
    "rigidus, rigida, rigidum"
  ],
  "inflexible": [
    "rigidus, rigida, rigidum"
  ],
  "restrained": [
    "temperāns, temperantis",
    "modestus, modesta, modestum",
    "moderatus, moderata, moderatum"
  ],
  "temperate": [
    "moderatus, moderata, moderatum",
    "temperātus, temperāta, temperātum"
  ],
  "sober": [
    "sōbrius, sōbria, sōbrium",
    "moderatus, moderata, moderatum"
  ],
  "be fully supplied": [
    "suppeditō, suppeditāre, suppeditāvī, suppeditātum"
  ],
  "be in abundance": [
    "suppeditō, suppeditāre, suppeditāvī, suppeditātum"
  ],
  "chastity": [
    "pudīcitia, pudīcitiae"
  ],
  "shamefacedness": [
    "pudīcitia, pudīcitiae"
  ],
  "modesty": [
    "rubor, rubōris",
    "pudīcitia, pudīcitiae",
    "verēcundia, verēcundiae"
  ],
  "green": [
    "Green",
    "viridis, viride"
  ],
  "fit or adapt something to something else": [
    "accommodō, accommodāre, accommodāvī, accommodātum"
  ],
  "put or hang on": [
    "accommodō, accommodāre, accommodāvī, accommodātum"
  ],
  "teacher of rhetoric": [
    "rhētor, rhētoris"
  ],
  "distinguish": [
    "cernō, cernere, crēvī, crētum",
    "distinguō, distinguere, distinxī, distinctum"
  ],
  "divide or part": [
    "distinguō, distinguere, distinxī, distinctum"
  ],
  "of december": [
    "december, decembris, decembre"
  ],
  "aedileship": [
    "aedīlitās, aedīlitātis"
  ],
  "a bad moral quality": [
    "nēquitia, nēquitiae"
  ],
  "idleness": [
    "dēsidia, dēsidiae",
    "ignāvia, ignāviae",
    "nēquitia, nēquitiae"
  ],
  "inactivity": [
    "ignāvia, ignāviae",
    "nēquitia, nēquitiae",
    "segnitia, segnitiae"
  ],
  "remissness": [
    "nēquitia, nēquitiae"
  ],
  "worthlessness": [
    "nēquitia, nēquitiae"
  ],
  "vileness": [
    "nēquitia, nēquitiae"
  ],
  "depravity": [
    "nēquitia, nēquitiae",
    "dēprāvātiō, dēprāvātiōnis"
  ],
  "unpunished": [
    "impūnis, impūne",
    "impūnītus, impūnīta, impūnītum"
  ],
  "thrive": [
    "vigeō, vigēre, viguī"
  ],
  "flourish": [
    "vigeō, vigēre, viguī",
    "vireō, virēre, viruī",
    "vivat, crescat, floreat"
  ],
  "a hearer": [
    "audītor, audītōris"
  ],
  "watcher": [
    "tūtor, tūtōris",
    "servātor, servātōris"
  ],
  "slavish": [
    "servīlis, servīle"
  ],
  "a bad quality": [
    "malitia, malitiae"
  ],
  "badness": [
    "malitia, malitiae"
  ],
  "oratorical": [
    "ōrātōrius, ōrātōria, ōrātōrium"
  ],
  "quite as": [
    "perinde"
  ],
  "a confession": [
    "cōnfessiō, cōnfessiōnis"
  ],
  "acknowledgment": [
    "cōnfessiō, cōnfessiōnis"
  ],
  "ensnare": [
    "dēcipiō, dēcipere, dēcēpī, dēceptum"
  ],
  "entrap": [
    "dēcipiō, dēcipere, dēcēpī, dēceptum"
  ],
  "mislead": [
    "dēcipiō, dēcipere, dēcēpī, dēceptum"
  ],
  "beguile": [
    "dēcipiō, dēcipere, dēcēpī, dēceptum"
  ],
  "elude": [
    "dēcipiō, dēcipere, dēcēpī, dēceptum"
  ],
  "today's": [
    "hodiernus, hodierna, hodiernum"
  ],
  "turbulent": [
    "tumultuōsus, tumultuōsa, tumultuōsum",
    "turbulentus, turbulenta, turbulentum"
  ],
  "dominion": [
    "dominātiō, dominātiōnis"
  ],
  "restraint": [
    "continentia, continentiae",
    "contingentia, contingentiae"
  ],
  "self-control": [
    "continentia, continentiae"
  ],
  "very great": [
    "permagnus, permagna, permagnum"
  ],
  "encircle or entwine": [
    "amplector, amplectī, amplexus sum"
  ],
  "fiercely": [
    "atrōciter, atrōcius, atrōcissimē",
    "ferōciter, ferōcius, ferōcissimē"
  ],
  "ferociously": [
    "ferōciter, ferōcius, ferōcissimē"
  ],
  "savagely": [
    "ferōciter, ferōcius, ferōcissimē"
  ],
  "a funeral pile": [
    "rogus, rogī"
  ],
  "of brothers or sisters": [
    "germānus, germāna, germānum"
  ],
  "fingernail": [
    "unguis, unguis"
  ],
  "toenail": [
    "unguis, unguis"
  ],
  "accustomed": [
    "soleō, solēre, solitus sum",
    "adsuētus, adsuēta, adsuētum"
  ],
  "customary": [
    "adsuētus, adsuēta, adsuētum",
    "ōrdinārius, ōrdināria, ōrdinārium"
  ],
  "usual": [
    "vulgāris, vulgāre",
    "adsuētus, adsuēta, adsuētum",
    "ōrdinārius, ōrdināria, ōrdinārium"
  ],
  "rattle": [
    "increpō, increpāre, increpuī, increpitum"
  ],
  "twang or make a similar noise": [
    "increpō, increpāre, increpuī, increpitum"
  ],
  "future time": [
    "posteritās, posteritātis"
  ],
  "manager": [
    "moderātor, moderātōris",
    "prōcūrātor, prōcūrātoris"
  ],
  "overseer": [
    "antistes, antistitis",
    "prōcūrātor, prōcūrātoris"
  ],
  "superintendent": [
    "prōcūrātor, prōcūrātoris"
  ],
  "dialectical": [
    "dialecticus, dialectica, dialecticum"
  ],
  "beneficent": [
    "beneficus, benefica, beneficum"
  ],
  "search for or hunt up": [
    "exquīrō, exquīrere, exquīsīvī, exquīsītum"
  ],
  "unloose": [
    "relaxō, relaxāre, relaxāvī, relaxātum",
    "exsolvō, exsolvere, exsolvī, exsolūtum",
    "dissolvō, dissolvere, dissolvī, dissolutum"
  ],
  "disunite": [
    "dissolvō, dissolvere, dissolvī, dissolutum"
  ],
  "dissolve or destroy": [
    "dissolvō, dissolvere, dissolvī, dissolutum"
  ],
  "superior in intellect": [
    "ingeniōsus, ingeniosa, ingeniōsum"
  ],
  "gifted with genius": [
    "ingeniōsus, ingeniosa, ingeniōsum"
  ],
  "intellectual": [
    "intellectuālis, intellectuāle",
    "ingeniōsus, ingeniosa, ingeniōsum"
  ],
  "ingenious": [
    "ingeniōsus, ingeniosa, ingeniōsum",
    "artificiōsus, artificiōsa, artificiōsum"
  ],
  "natal": [
    "nātālis, nātāle"
  ],
  "avenger": [
    "ultor, ultōris",
    "ultrīx, ultrīcis"
  ],
  "punisher": [
    "ultor, ultōris",
    "ultrīx, ultrīcis"
  ],
  "be insane": [
    "īnsāniō, īnsānīre, īnsānīvī, īnsānītum"
  ],
  "etruscan": [
    "tuscus, tusca, tuscum"
  ],
  "censorial": [
    "cēnsōrius, cēnsōria, censōrium"
  ],
  "cavern": [
    "antrum, antrī",
    "caverna, cavernae",
    "spēlunca, spēluncae"
  ],
  "gale": [
    "procella, procellae"
  ],
  "reprimand": [
    "reprehensiō, reprehensiōnis"
  ],
  "criticism": [
    "reprehensiō, reprehensiōnis"
  ],
  "prudently": [
    "prūdenter, prūdentius, prūdentissimē"
  ],
  "fall down or off": [
    "dēcidō, dēcidere, dēcidī, dēcāsum"
  ],
  "hang down": [
    "dēpendeō, dēpendēre, dēpendī",
    "dēcidō, dēcidere, dēcidī, dēcāsum",
    "prōpendeō, prōpendēre, prōpendī, prōpēnsum"
  ],
  "beaver": [
    "castor, castoris"
  ],
  "flattery": [
    "blanditia, blanditiae"
  ],
  "compliment": [
    "blanditia, blanditiae"
  ],
  "caress": [
    "blanditia, blanditiae",
    "permulceō, permulcēre, permulsī, permulsum"
  ],
  "in or upon": [
    "īnsideō, īnsidēre, īnsēdī, īnsessum"
  ],
  "am seated or rest in or upon": [
    "īnsideō, īnsidēre, īnsēdī, īnsessum"
  ],
  "conspirator": [
    "coniūrātus, coniūrātī"
  ],
  "plotter": [
    "coniūrātus, coniūrātī"
  ],
  "eddy": [
    "vertex, verticis"
  ],
  "vortex": [
    "Vortex",
    "vertex, verticis"
  ],
  "sufficiency": [
    "satietās, satietātis"
  ],
  "adulterous": [
    "adulter, adultera, adulterum",
    "adulterīnus, adulterīna, adulterīnum"
  ],
  "unchaste": [
    "adulter, adultera, adulterum",
    "adulterīnus, adulterīna, adulterīnum"
  ],
  "absolute power": [
    "dominātus, dominātūs"
  ],
  "a divine announcement": [
    "ōrāculum, ōrāculī"
  ],
  "oracle": [
    "sors, sortis",
    "ōrāculum, ōrāculī"
  ],
  "a whirlpool": [
    "gurges, gurgitis"
  ],
  "scribe": [
    "scrība, scrībae"
  ],
  "doorpost": [
    "postis, postis"
  ],
  "shamelessness": [
    "impudentia, impudentiae"
  ],
  "a saying": [
    "dictiō, dictiōnis"
  ],
  "speaking": [
    "dictiō, dictiōnis"
  ],
  "oratory": [
    "dictiō, dictiōnis"
  ],
  "finely": [
    "subtīliter, subtīlius, subtīlissimē"
  ],
  "heap": [
    "struēs, struis",
    "acervus, acervī",
    "cumulus, cumulī",
    "congeriēs, congeriēī",
    "cumulō, cumulāre, cumulāvī, cumulātum",
    "cōnstruō, cōnstruere, cōnstruxī, cōnstructum"
  ],
  "pile up": [
    "cumulō, cumulāre, cumulāvī, cumulātum"
  ],
  "tip": [
    "cuspis, cuspidis"
  ],
  "jointly": [
    "commūniter, commūnius, commūnissimē",
    "coniunctim, coniunctius, coniunctissimē"
  ],
  "in common": [
    "commūniter, commūnius, commūnissimē",
    "coniunctim, coniunctius, coniunctissimē"
  ],
  "intestinal": [
    "intestīnus, intestīna, intestīnum"
  ],
  "disagree": [
    "discrepō, discrepāre, discrepāvi, discrepātum"
  ],
  "differ or vary": [
    "discrepō, discrepāre, discrepāvi, discrepātum"
  ],
  "full of madness or rage": [
    "furiōsus, furiōsa, furiōsum"
  ],
  "furious": [
    "furiālis, furiāle",
    "vehemēns, vehementis",
    "rabidus, rabida, rabidum",
    "furiōsus, furiōsa, furiōsum"
  ],
  "raging": [
    "saevitia, saevitiae",
    "furiōsus, furiōsa, furiōsum"
  ],
  "glittering": [
    "nitidus, nitida, nitidum",
    "splendidus, splendida, splendidum"
  ],
  "head or life": [
    "capitālis, capitāle"
  ],
  "bring or get": [
    "subigō, subigere, subēgī, subāctum"
  ],
  "someone who bestows care or labor upon something": [
    "cultor, cultōris"
  ],
  "cultivator": [
    "cultor, cultōris"
  ],
  "tiller": [
    "cultor, cultōris"
  ],
  "husbandman": [
    "cultor, cultōris"
  ],
  "grower": [
    "cultor, cultōris"
  ],
  "disprove": [
    "refellō, refellere, refellī, refellum",
    "redarguō, redarguere, redarguī, redargūtum"
  ],
  "rebut": [
    "refellō, refellere, refellī, refellum"
  ],
  "confute": [
    "refellō, refellere, refellī, refellum",
    "redarguō, redarguere, redarguī, redargūtum"
  ],
  "refute": [
    "refellō, refellere, refellī, refellum",
    "coarguō, coarguere, coarguī, coargūtum",
    "redarguō, redarguere, redarguī, redargūtum"
  ],
  "challenge": [
    "prōvocātiō, prōvocātiōnis",
    "refellō, refellere, refellī, refellum"
  ],
  "taunt": [
    "Objectatio",
    "maledictum, maledictī",
    "opprobrium, opprobriī"
  ],
  "be sold": [
    "vēneō, vēnīre, vēniī, vēnitum"
  ],
  "boyhood": [
    "pueritia, pueritiae"
  ],
  "childhood": [
    "pueritia, pueritiae"
  ],
  "elsewhere": [
    "alibi",
    "alibī"
  ],
  "clasp": [
    "fībula, fībulae",
    "amplexus, amplexūs",
    "amplexor, amplexārī, amplexātus sum"
  ],
  "examine or inspect": [
    "īnspiciō, īnspicere, īnspexī, īnspectum"
  ],
  "allay": [
    "sēdō, sēdāre, sēdāvī, sēdātum"
  ],
  "assuage or appease": [
    "sēdō, sēdāre, sēdāvī, sēdātum"
  ],
  "antiquity": [
    "antīquitās, antīquitātis"
  ],
  "tarry": [
    "resideō, residēre, resēdī, resessum"
  ],
  "linger": [
    "morārī",
    "resideō, residēre, resēdī, resessum"
  ],
  "patrimony": [
    "pātrimōnium, pātrimōniī"
  ],
  "manly": [
    "virīlis, virīle, virīlior"
  ],
  "mature": [
    "virīlis, virīle, virīlior"
  ],
  "masculine": [
    "virīlis, virīle, virīlior",
    "masculus, mascula, masculum",
    "masculīnus, masculīna, masculīnum"
  ],
  "discordant": [
    "discors, discordis",
    "absurdus, absurda, absurdum",
    "dissonus, dissona, dissonum"
  ],
  "tepid": [
    "tepidus, tepida, tepidum"
  ],
  "moderately warm": [
    "tepidus, tepida, tepidum"
  ],
  "a grove sacred to a deity": [
    "lūcus, lūcī"
  ],
  "outlay": [
    "impensa, impensae"
  ],
  "fear greatly": [
    "pertimeō, pertimēre, pertimuī"
  ],
  "am very timid": [
    "pertimeō, pertimēre, pertimuī"
  ],
  "an asking": [
    "rogātus, rogātūs"
  ],
  "requesting": [
    "rogātus, rogātūs"
  ],
  "suit": [
    "rogātus, rogātūs"
  ],
  "entreaty": [
    "rogātus, rogātūs",
    "obsecrātiō, obsecrātiōnis"
  ],
  "neighbouring": [
    "adfīnis, adfīne",
    "affīnis, affīne"
  ],
  "allied": [
    "adfīnis, adfīne",
    "socius, socia, socium"
  ],
  "lute": [
    "Cithara",
    "lyra, lyrae",
    "cithara, citharae"
  ],
  "inauspicious": [
    "obscēnus, obscēna, obscēnum",
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "ominous": [
    "obscēnus, obscēna, obscēnum"
  ],
  "portentous": [
    "obscēnus, obscēna, obscēnum"
  ],
  "nod assent": [
    "adnuō, adnuere, adnuī, adnūtum"
  ],
  "approve": [
    "adnuō, adnuere, adnuī, adnūtum",
    "probō, probāre, probāvī, probātum",
    "assentior, assentīrī, assēnsus sum",
    "ascīscō, ascīscere, ascīvī, ascītum",
    "assentor, assentārī, assentātus sum",
    "approbō, approbāre, approbāvī, approbātum"
  ],
  "consent": [
    "adnuō, adnuere, adnuī, adnūtum"
  ],
  "tedium": [
    "taedium, taediī"
  ],
  "boredom": [
    "taedium, taediī"
  ],
  "ennui": [
    "taedium, taediī"
  ],
  "a lie": [
    "mendācium, mendāciī"
  ],
  "untruth": [
    "mendācium, mendāciī",
    "falsitās, falsitātis"
  ],
  "fiction": [
    "mendācium, mendāciī"
  ],
  "polished": [
    "nitidus, nitida, nitidum"
  ],
  "that may be thrown": [
    "missilis, missile"
  ],
  "intelligence": [
    "prūdentia -ae",
    "prūdentia -ae (f)",
    "intellegentia, intellegentiae"
  ],
  "the power of discernment": [
    "intellegentia, intellegentiae"
  ],
  "augury": [
    "augurium, auguriī"
  ],
  "crag": [
    "scopulus, scopulī"
  ],
  "an axletree": [
    "axis, axis"
  ],
  "wagon": [
    "axis, axis",
    "carrus, carrī",
    "plaustrum, plaustrī",
    "vehiculum, vehiculī"
  ],
  "car": [
    "axis, axis"
  ],
  "impious": [
    "nefandus, nefanda, nefandum"
  ],
  "refuse by a nod": [
    "abnuō, abnuere, abnuī, abnuitum"
  ],
  "hiding place": [
    "latēbra, latēbrae",
    "latibulum, latibulī"
  ],
  "lair": [
    "stabulum -ī n",
    "latēbra, latēbrae"
  ],
  "a concubine of a married man": [
    "paelex, paelicis"
  ],
  "evade": [
    "dētractō, dētractāre, dētractāvī, dētractātum"
  ],
  "bristly": [
    "hīrsūtus, hīrsūta, hīrsūtum",
    "horridus, horrida, horridum",
    "saetiger, saetigera, saetigerum"
  ],
  "shaggy": [
    "hīrtus, hīrta, hīrtum",
    "hīrsūtus, hīrsūta, hīrsūtum",
    "horridus, horrida, horridum"
  ],
  "traitor": [
    "Proditor",
    "prōditōr, prōditōris"
  ],
  "betrayer": [
    "prōditōr, prōditōris"
  ],
  "an enclosed place": [
    "saeptum, saeptī"
  ],
  "wedding": [
    "Nuptiae",
    "nūptiae, nūptiārum"
  ],
  "marriage": [
    "cōnūbium, cōnūbiī",
    "nūptiae, nūptiārum",
    "mātrimōnium, mātrimōniī"
  ],
  "nuptials": [
    "nūptiae, nūptiārum"
  ],
  "turn away": [
    "āvertō, āvertere, āvertī, āversum",
    "aspernor, aspernārī, aspernātus sum",
    "dēvertō, dēvertere, dēvertī, dēversum"
  ],
  "repel": [
    "aspernor, aspernārī, aspernātus sum"
  ],
  "having many folds": [
    "multiplex, multiplicis"
  ],
  "unconquered": [
    "invictus",
    "invictus, invicta, invictum"
  ],
  "unsubdued": [
    "invictus, invicta, invictum",
    "indomitus, indomita, indomitum"
  ],
  "hidden": [
    "arcānus, arcāna, arcānum",
    "lateō, latēre, latuī, -----",
    "absconsus, absconsa, absconsum"
  ],
  "mysterious": [
    "arcānus, arcāna, arcānum"
  ],
  "full of young": [
    "fētus, fēta, fētum"
  ],
  "marvel": [
    "mīrāculum, mīrāculī"
  ],
  "miracle": [
    "mīrāculum, mīrāculī"
  ],
  "a wonderful": [
    "mīrāculum, mīrāculī"
  ],
  "strange or marvellous thing": [
    "mīrāculum, mīrāculī"
  ],
  "quaestorship": [
    "quaestūra, quaestūrae"
  ],
  "interregnum": [
    "interrēgnum, interrēgnī"
  ],
  "indivisible": [
    "atomus, atoma, atomum",
    "indīviduus, indīvidua, indīviduum"
  ],
  "procession": [
    "pompa, pompae"
  ],
  "knowing one's place": [
    "verēcundia, verēcundiae"
  ],
  "regarded as a virtue": [
    "verēcundia, verēcundiae"
  ],
  "coyness": [
    "verēcundia, verēcundiae"
  ],
  "remembrance": [
    "recordātiō, recordātiōnis",
    "commemorātiō, commemorātiōnis"
  ],
  "without restraint or hindrance": [
    "līberē, līberius, līberissimē"
  ],
  "go off or away": [
    "abscēdō, abscēdere, abscessī, abscessum"
  ],
  "anxious": [
    "ānxius, ānxia, ānxium"
  ],
  "uneasy": [
    "ānxius, ānxia, ānxium"
  ],
  "academic": [
    "acadēmicus, acadēmica, acadēmicum"
  ],
  "of or pertaining to the greek academy and platonism": [
    "acadēmicus, acadēmica, acadēmicum"
  ],
  "as opposed to stoic": [
    "acadēmicus, acadēmica, acadēmicum"
  ],
  "pond": [
    "stagnum, stagnī",
    "piscīna, piscīnae"
  ],
  "fen": [
    "stagnum, stagnī"
  ],
  "any piece of standing water": [
    "stagnum, stagnī"
  ],
  "buyer": [
    "ēmptor, ēmptōris"
  ],
  "purchaser": [
    "ēmptor, ēmptōris",
    "manceps, mancipis"
  ],
  "pestilential": [
    "pestilēns, pestilentis",
    "pestifer, pestifera, pestiferum"
  ],
  "calamitous": [
    "calamitōsus, calamitōsa, calamitōsum"
  ],
  "hired": [
    "meritōrius, meritōria, meritōrium",
    "mercēnnārius, mercēnnāria, mercēnnārium"
  ],
  "an axe": [
    "Securis",
    "secūris, secūris"
  ],
  "hatchet with a broad edge": [
    "secūris, secūris"
  ],
  "someone with a large or pointed nose": [
    "nāsīca, nāsīcae"
  ],
  "very often": [
    "persaepe"
  ],
  "of or pertaining to the sea": [
    "aequoreus, aequorea, aequoreum",
    "caeruleus, caerulea, caeruleum"
  ],
  "recovery": [
    "Recuperans",
    "repetunda, repetundae"
  ],
  "quench or extinguish": [
    "exstinguō, exstinguere, exstinxī, exstinctum",
    "restinguō, restinguere, restinxī, restinctum"
  ],
  "a promise secured by bail": [
    "vadimōnium, vadimōniī"
  ],
  "dilate": [
    "dīlātō, dīlātāre, dīlātāvī, dīlātātum"
  ],
  "gently": [
    "sēnsim",
    "molliter, mollius, mollissimē"
  ],
  "agreeing or according with": [
    "cōnsentāneus, cōnsentānea, cōnsentāneum"
  ],
  "suited to": [
    "cōnsentāneus, cōnsentānea, cōnsentāneum"
  ],
  "becoming": [
    "decōrus, decōra, decōrum",
    "cōnsentāneus, cōnsentānea, cōnsentāneum"
  ],
  "commemoration": [
    "commemorātiō, commemorātiōnis"
  ],
  "untilled": [
    "incultus, inculta, incultum"
  ],
  "uncultivated": [
    "incultus, inculta, incultum"
  ],
  "demented": [
    "īnsānus, īnsāna, īnsānum"
  ],
  "unclean": [
    "impūrus, impūra, impūrum",
    "spurcus, spurca, spurcum",
    "immundus, immunda, immundum",
    "incestus, incesta, incestum"
  ],
  "filthy": [
    "impūrus, impūra, impūrum",
    "immundus, immunda, immundum"
  ],
  "ministry": [
    "ministerium, ministeriī"
  ],
  "eternity": [
    "Aeternitas",
    "ad aeternum",
    "aevum, aevī",
    "aeternitās, aeternitātis"
  ],
  "a smell": [
    "odor, odōris"
  ],
  "perfume": [
    "odor, odōris",
    "unguentum, unguentī",
    "odōrō, odōrāre, odōrāvī, odōrātum"
  ],
  "stench": [
    "odor, odōris"
  ],
  "ward off": [
    "dēfendō, dēfendere, dēfendī, dēfēnsum",
    "dēfensō, dēfensāre, dēfensāvī, dēfensātum"
  ],
  "yield": [
    "Yield",
    "tribuō, tribuere, tribuī, tribūtum",
    "dēcēdō, dēcēdere, dēcessī, dēcessum",
    "concēdō, concēdere, concessī, concessum",
    "sufficiō, sufficere, suffēcī, suffectum"
  ],
  "wedlock": [
    "cōnūbium, cōnūbiī",
    "mātrimōnium, mātrimōniī"
  ],
  "immeasurable": [
    "immēnsus, immēnsa, immēnsum",
    "immoderātus, immoderāta, immoderātum"
  ],
  "a roman college of priests who dealt with enemies": [
    "fētiāles, fētiālis"
  ],
  "tardy": [
    "sēgnis, sēgne"
  ],
  "torpid": [
    "sēgnis, sēgne"
  ],
  "inactive": [
    "sēgnis, sēgne",
    "īgnāvus, īgnāva, īgnāvum"
  ],
  "unenergetic": [
    "sēgnis, sēgne"
  ],
  "departing physically from the correct shape": [
    "dēfōrmis, dēfōrme"
  ],
  "deformed": [
    "dēfōrmis, dēfōrme"
  ],
  "misshapen": [
    "dēfōrmis, dēfōrme"
  ],
  "malformed": [
    "dēfōrmis, dēfōrme"
  ],
  "during the roman monarchy": [
    "interrēx, interrēgis"
  ],
  "the regent holding the royal power between the death of one king and the election of another": [
    "interrēx, interrēgis"
  ],
  "squeeze": [
    "pressō, pressāre, pressāvī, pressātum",
    "tribulō, tribulāre, tribulāvī, tribulātum"
  ],
  "filth": [
    "caenum, caenī",
    "sordes, sordis"
  ],
  "squalor": [
    "sordes, sordis"
  ],
  "untouched": [
    "intactus, intacta, intactum"
  ],
  "state in writing": [
    "ādscrībō, ādscrībere, ādscrīpsī, ādscrīptum"
  ],
  "actively": [
    "impigrē"
  ],
  "energetically": [
    "impigrē"
  ],
  "a boundary": [
    "terminus, terminī"
  ],
  "a sharpened point": [
    "acūmen, acūminis"
  ],
  "innocence": [
    "innocentia, innocentiae"
  ],
  "sceptre": [
    "scēptrum, scēptrī"
  ],
  "pleasantness": [
    "lepor, lepōris",
    "lepōs, lepōris",
    "amoenitās, amoenitātis",
    "iūcunditās, iūcunditātis"
  ],
  "gliding": [
    "lāpsus, lāpsūs"
  ],
  "sliding": [
    "lāpsus, lāpsūs"
  ],
  "slipping": [
    "lāpsus, lāpsūs"
  ],
  "falling": [
    "lāpsus, lāpsūs",
    "cadūcus, cadūca, cadūcum"
  ],
  "to feel annoyance or reluctance at": [
    "pigeō, pigēre, piguī, pigitum"
  ],
  "to repent of": [
    "pigeō, pigēre, piguī, pigitum"
  ],
  "briefly": [
    "parumper",
    "summātim",
    "breviter, brevius, brevissimē"
  ],
  "attendant": [
    "Cooperator",
    "satelles, satellitis",
    "dispensātor, dispensātōris"
  ],
  "noxious": [
    "noxius, noxia, noxium",
    "nocīvus, nocīva, nocīvum"
  ],
  "stronger": [
    "fortior, fortius",
    "citius altius fortius"
  ],
  "rather strong": [
    "fortior, fortius"
  ],
  "agreeableness": [
    "amoenitās, amoenitātis",
    "iūcunditās, iūcunditātis"
  ],
  "pleasurableness": [
    "amoenitās, amoenitātis",
    "iūcunditās, iūcunditātis"
  ],
  "a holy day": [
    "fēriae, fēriārum"
  ],
  "a festival": [
    "fēriae, fēriārum"
  ],
  "a holiday": [
    "fēriae, fēriārum"
  ],
  "a greek dry measure similar to a bushel": [
    "medimnum, medimnī"
  ],
  "triumph ": [
    "triumphō, triumphāre, triumphāvī, triumphātum"
  ],
  "extremity": [
    "cacūmen, cacūminis"
  ],
  "top": [
    "summum, summī",
    "cacūmen, cacūminis",
    "summitās, summitātis",
    "superficiēs, superficieī"
  ],
  "serving": [
    "famulus, famula, famulum"
  ],
  "servile": [
    "famulus, famula, famulum"
  ],
  "unlikeness": [
    "dissimilitūdō, dissimilitūdinis"
  ],
  "dissimilitude": [
    "dissimilitūdō, dissimilitūdinis"
  ],
  "a false oath": [
    "periūrium, periūriī"
  ],
  "perjury": [
    "periūrium, periūriī"
  ],
  "a sacrificial or solemn feast": [
    "daps, dapis"
  ],
  "religious banquet": [
    "daps, dapis"
  ],
  "harshly": [
    "asperē, asperius, asperrimē",
    "atrōciter, atrōcius, atrōcissimē"
  ],
  "severely": [
    "asperē, asperius, asperrimē"
  ],
  "perennial": [
    "perennis, perenne"
  ],
  "of or pertaining to a mother": [
    "māternus, māterna, māternum"
  ],
  "maternal": [
    "māternus, māterna, māternum"
  ],
  "brisk": [
    "strēnuus, -a, -um",
    "strēnuus, strēnua, strēnuum"
  ],
  "nimble": [
    "strēnuus, -a, -um",
    "strēnuus, strēnua, strēnuum"
  ],
  "prompt": [
    "strēnuus, -a, -um",
    "strēnuus, strēnua, strēnuum"
  ],
  "assassin": [
    "Assassin",
    "Sicarius",
    "sīcārius, sīcāriī"
  ],
  "murderer": [
    "sīcārius, sīcāriī",
    "occīsor, occīsōris",
    "homicīda, homicīdae",
    "interfector, interfectōris"
  ],
  "regal": [
    "rēgālis, rēgāle"
  ],
  "royal": [
    "rēgālis, rēgāle",
    "rēgius, rēgia, rēgium"
  ],
  "plump": [
    "pinguis, pingue",
    "opīmus, opīma, opīmum"
  ],
  "corpulent": [
    "opīmus, opīma, opīmum"
  ],
  "rend": [
    "accipitrō, accipitrāre",
    "laniō, laniāre, laniāvī, laniātum",
    "abrumpō, abrumpere, abrūpī, abruptum",
    "lacerō, lacerāre, lacerāvī, lacerātum"
  ],
  "inconstancy": [
    "inconstantia, inconstantiae"
  ],
  "changeableness": [
    "inconstantia, inconstantiae"
  ],
  "fickleness": [
    "inconstantia, inconstantiae"
  ],
  "superstition": [
    "superstitiō, superstitiōnis"
  ],
  "argon": [
    "ārgon, ārgī"
  ],
  "a draft animal": [
    "armentum, armentī"
  ],
  "a large domestic animal suitable for drawing a plow: an ox": [
    "armentum, armentī"
  ],
  "a horse": [
    "armentum, armentī"
  ],
  "take pains or endeavor": [
    "ēlabōrō, ēlabōrāre, ēlabōrāvī, ēlabōrātum"
  ],
  "a kind of owl": [
    "otus, otī"
  ],
  "pass over or flee": [
    "ēvādō, ēvādere, ēvāsī, ēvāsum"
  ],
  "the state of being poor": [
    "paupertās, paupertātis"
  ],
  "indigence": [
    "paupertās, paupertātis",
    "indigentia, indigentiae",
    "mendīcitās, mendīcitātis"
  ],
  "a champion or defender ": [
    "prōpugnātor, prōpugnātōris"
  ],
  "a block or piece of marble": [
    "marmor, marmoris"
  ],
  "with you all": [
    "vōbīscum"
  ],
  "with ye": [
    "vōbīscum"
  ],
  "of october": [
    "octōber, octōbris, octōbre"
  ],
  "reddish-yellow": [
    "fulvus, fulva, fulvum"
  ],
  "tawny": [
    "fulvus, fulva, fulvum"
  ],
  "amber-coloured": [
    "fulvus, fulva, fulvum"
  ],
  "fulvous": [
    "fulvus, fulva, fulvum"
  ],
  "speedy": [
    "properus, propera, properum"
  ],
  "make hollow": [
    "cavō, cavāre, cavāvī, cavātum"
  ],
  "hollow out": [
    "cavō, cavāre, cavāvī, cavātum",
    "effodiō, effodere, effōdī, effossum"
  ],
  "excavate": [
    "cavō, cavāre, cavāvī, cavātum",
    "effodiō, effodere, effōdī, effossum"
  ],
  "of the day": [
    "diurnus, diurna, diurnum"
  ],
  "manifold": [
    "numerōsus, numerōsa, numerōsum",
    "multifārius, multifāria, multifārium"
  ],
  "multiple": [
    "numerōsus, numerōsa, numerōsum"
  ],
  "propitiatory sacrifice": [
    "piāculum, piāculī"
  ],
  "a low seat or bench": [
    "subsellium, subselliī"
  ],
  "an ex-praetor sent as praetor to a province where there was no army": [
    "prōpraetor, prōpraetōris"
  ],
  "urge on": [
    "stimulō, stimulāre, stimulāvī, stimulātum"
  ],
  "goad on": [
    "stimulō, stimulāre, stimulāvī, stimulātum"
  ],
  "stimulate": [
    "īnstīgō, īnstīgāre, īnstīgāvī, īnstīgātum",
    "stimulō, stimulāre, stimulāvī, stimulātum"
  ],
  "rouse up": [
    "stimulō, stimulāre, stimulāvī, stimulātum"
  ],
  "fix or set in position": [
    "dēstituō, dēstituere, dēstituī, dēstitūtum"
  ],
  "noose": [
    "laqueus, laqueī"
  ],
  "trap": [
    "Decipula",
    "laqueus, laqueī"
  ],
  "matrimony": [
    "mātrimōnium, mātrimōniī"
  ],
  "thumb": [
    "pollex, pollicis"
  ],
  "sever": [
    "spernō, spernere, sprēvī, sprētum",
    "dīdūcō, dīdūcere, dīdūxī, dīductum",
    "abrumpō, abrumpere, abrūpī, abruptum",
    "praecīdō, praecīdere, praecīdī, praecīsum"
  ],
  "punishable": [
    "obnoxius, obnoxia, obnoxium"
  ],
  "liable": [
    "obnoxius, obnoxia, obnoxium"
  ],
  "guilty": [
    "sōns, sontis",
    "reus, rea, reum",
    "obnoxius, obnoxia, obnoxium"
  ],
  "rage": [
    "Insanitas",
    "furia, furiae",
    "furor, furōris",
    "rabiēs, rabiēī",
    "saevitia, saevitiae",
    "saeviō, saevīre, saeviī, saevītum",
    "aestuō, aestuāre, aestuāvī, aestuātum"
  ],
  "fury": [
    "Insanitas",
    "Saevitudo",
    "furia, furiae",
    "furor, furōris",
    "saevitia, saevitiae"
  ],
  "frenzy": [
    "Insanitas",
    "furia, furiae"
  ],
  "immortality": [
    "immortālitās, immortālitātis"
  ],
  "contact": [
    "Dum Tangis",
    "contāgium, contāgiī",
    "contāctus, contāctūs",
    "contāgiō, contāgiōnis"
  ],
  "pebble": [
    "silex, silicis",
    "lapillus, lapillī"
  ],
  "flint": [
    "silex, silicis"
  ],
  "smear or coat with wax": [
    "cērō, cērāre, cērāvī, cērātum"
  ],
  "a period of five years": [
    "quīnquennium, quīnquenniī"
  ],
  "enfeeble": [
    "īnfirmō, īnfirmāre, īnfirmāvī, īnfirmātum"
  ],
  "deprive of strength": [
    "īnfirmō, īnfirmāre, īnfirmāvī, īnfirmātum"
  ],
  "warning": [
    "Admonitum",
    "monitus, monitūs"
  ],
  "without danger": [
    "tūtē, tūtius, tūtissimē"
  ],
  "abound": [
    "redundō, redundāre, redundāvī, redundātum"
  ],
  "bind in obligation": [
    "obligō, obligāre, obligāvī, obligātum"
  ],
  "make liable": [
    "obligō, obligāre, obligāvī, obligātum"
  ],
  "a censuring": [
    "vituperātiō, vituperātiōnis"
  ],
  "blaming": [
    "vituperātiō, vituperātiōnis"
  ],
  "a throwing together": [
    "coniectus, coniectūs"
  ],
  "forgetful": [
    "immemor, immemoris",
    "inmemor, inmemoris"
  ],
  "the bark of a tree": [
    "cortex, corticis"
  ],
  "the bark of a cork tree": [
    "cortex, corticis"
  ],
  "cork": [
    "cortex, corticis"
  ],
  "from without": [
    "forīnsecus",
    "extrīnsecus"
  ],
  "from abroad": [
    "extrīnsecus"
  ],
  "yellow": [
    "Yellow",
    "flāvus, flāva, flāvum"
  ],
  "golden": [
    "flāvus, flāva, flāvum",
    "aurātus, aurāta, aurātum"
  ],
  "ease": [
    "facilitās, facilitātis"
  ],
  "easiness": [
    "facilitās, facilitātis"
  ],
  "facility": [
    "facilitās, facilitātis"
  ],
  "zealous": [
    "sēdulus, sēdula, sēdulum"
  ],
  "unremitting": [
    "sēdulus, sēdula, sēdulum"
  ],
  "solicitous": [
    "sēdulus, sēdula, sēdulum"
  ],
  "assiduous": [
    "sēdulus, sēdula, sēdulum"
  ],
  "sedulous": [
    "sēdulus, sēdula, sēdulum"
  ],
  "ray": [
    "radius, radiī"
  ],
  "honorific": [
    "honōrificus, honōrifica, honōrificum"
  ],
  "made or consisting of marble": [
    "marmoreus, marmorea, marmoreum"
  ],
  "wash away": [
    "dīluō, dīluere, dīluī, dīlūtum"
  ],
  "judging": [
    "iūdicātiō, iūdicātiōnis"
  ],
  "ambitious": [
    "ambitiōsus, ambitiōsa, ambitiōsum"
  ],
  "salubrious": [
    "salūber, salūbris, salūbre"
  ],
  "favourably": [
    "opportūnē, opportūnius, opportūnissimē"
  ],
  "seasonably": [
    "opportūnē, opportūnius, opportūnissimē"
  ],
  "opportunely": [
    "opportūnē, opportūnius, opportūnissimē"
  ],
  "turned forward": [
    "prōnus, prōna, prōnum"
  ],
  "bent over": [
    "prōnus, prōna, prōnum"
  ],
  "inclined": [
    "prōnus, prōna, prōnum"
  ],
  "leaning": [
    "prōnus, prōna, prōnum"
  ],
  "hanging": [
    "prōnus, prōna, prōnum"
  ],
  "stooping": [
    "prōnus, prōna, prōnum"
  ],
  "bending": [
    "prōnus, prōna, prōnum",
    "anfractus, anfracta, anfractum"
  ],
  "prone": [
    "prōnus, prōna, prōnum"
  ],
  "face down": [
    "prōnus, prōna, prōnum"
  ],
  "emerge": [
    "ēmergō, ēmergere, ēmersī, ēmersum"
  ],
  "meadow": [
    "prātum, prātī"
  ],
  "also irrīdeō": [
    "inrīdeō, inrīdēre, inrīsī, inrīsum"
  ],
  "extort": [
    "extorqueō, extorquēre, extorsī, extortum"
  ],
  "that falls or has fallen": [
    "cadūcus, cadūca, cadūcum"
  ],
  "collapsing": [
    "cadūcus, cadūca, cadūcum"
  ],
  "drooping": [
    "cadūcus, cadūca, cadūcum"
  ],
  "advertisement": [
    "prōscrīptiō, prōscrīptiōnis"
  ],
  "that breaks his promise": [
    "perfidus, perfida, perfidum"
  ],
  "dishonest": [
    "perfidus, perfida, perfidum"
  ],
  "treacherous": [
    "perfidus, perfida, perfidum",
    "perfidiōsus, perfidiōsa, perfidiōsum"
  ],
  "perfidious": [
    "perfidus, perfida, perfidum",
    "perfidiōsus, perfidiōsa, perfidiōsum"
  ],
  "a cunning device": [
    "calumnia, calumniae"
  ],
  "trickery": [
    "calumnia, calumniae"
  ],
  "sophistry": [
    "argūtia, argūtiae",
    "calumnia, calumniae"
  ],
  "chicanery": [
    "calumnia, calumniae"
  ],
  "fasting": [
    "iēiūnium, iēiūniī",
    "iēiūnus, iēiūna, iēiūnum"
  ],
  "abstinent": [
    "iēiūnus, iēiūna, iēiūnum"
  ],
  "hungry": [
    "iēiūnus, iēiūna, iēiūnum"
  ],
  "be angry": [
    "suscēnseō, suscēnsēre, suscēnsuī, suscēnsum"
  ],
  "slothful": [
    "īgnāvus, īgnāva, īgnāvum"
  ],
  "sluggish": [
    "īgnāvus, īgnāva, īgnāvum"
  ],
  "am luxuriant": [
    "luxuriō, luxuriāre, luxuriāvī, luxuriātum"
  ],
  "abounding to excess": [
    "luxuriō, luxuriāre, luxuriāvī, luxuriātum"
  ],
  "pillaging": [
    "dīreptiō, dīreptiōnis",
    "dēpopulātiō, dēpopulātiōnis"
  ],
  "moan or sigh": [
    "ingemō, ingemere, ingemuī, ingemitum"
  ],
  "heedlessly": [
    "neglegenter, neglegentius, neglegentissimē"
  ],
  "neglectfully": [
    "neglegenter, neglegentius, neglegentissimē"
  ],
  "carelessly": [
    "neglegenter, neglegentius, neglegentissimē"
  ],
  "swelling": [
    "torus, torī",
    "tumidus, tumida, tumidum"
  ],
  "rising high": [
    "tumidus, tumida, tumidum"
  ],
  "protuberant": [
    "tumidus, tumida, tumidum"
  ],
  "tumid": [
    "tumidus, tumida, tumidum"
  ],
  "dilated": [
    "tumidus, tumida, tumidum"
  ],
  "bulging": [
    "tumidus, tumida, tumidum"
  ],
  "cut to pieces": [
    "trucīdō, trucīdāre, trucīdāvī, trucīdātum"
  ],
  "kill in a cruel way": [
    "trucīdō, trucīdāre, trucīdāvī, trucīdātum"
  ],
  "nourishment": [
    "alimentum, alimentī"
  ],
  "sustenance": [
    "alimentum, alimentī"
  ],
  "humour": [
    "hūmor, hūmōris",
    "facētia, facētiae"
  ],
  "run to and from": [
    "discurrō, discurrere, discurrī, discursus"
  ],
  "roam": [
    "discurrō, discurrere, discurrī, discursus",
    "peregrīnor, peregrīnārī, peregrīnātus sum",
    "perambulō, perambulāre, perambulāvī, perambulātum"
  ],
  "thrust through": [
    "trānsigō, trānsigere, trānsēgī, trānsactum",
    "trānsfīgō, trānsfīgere, trānsfīxī, trānsfīxum"
  ],
  "pierce": [
    "forō, forāre, forāvī, forātum",
    "trānsigō, trānsigere, trānsēgī, trānsactum"
  ],
  "stab": [
    "trānsigō, trānsigere, trānsēgī, trānsactum"
  ],
  "an actor": [
    "histriō, histriōnis"
  ],
  "player": [
    "lūsor, lūsōris",
    "histriō, histriōnis"
  ],
  "intolerable": [
    "intolerābilis, intolerābile",
    "intolerandus, intoleranda, intolerandum"
  ],
  "insupportable": [
    "intolerābilis, intolerābile",
    "intolerandus, intoleranda, intolerandum"
  ],
  "thong": [
    "lōrum, lōrī",
    "habēna, habēnae"
  ],
  "weakness": [
    "dēbilitās, dēbilitātis",
    "īnfirmitās, īnfirmitātis",
    "imbēcillitas, imbēcillitātis",
    "imbēcillitās, imbēcillitātis"
  ],
  "infirmity": [
    "īnfirmitās, īnfirmitātis",
    "aegrōtātiō, aegrōtātiōnis"
  ],
  "mockery": [
    "dērīsus, dērīsūs",
    "lūdībrium, lūdībriī"
  ],
  "derision": [
    "dērīsus, dērīsūs",
    "lūdībrium, lūdībriī"
  ],
  "offensive": [
    "taeter, taetra, taetrum"
  ],
  "curia": [
    "cūriātus, cūriāta, cūriātum"
  ],
  "spread or open": [
    "pandō, pandere, pandī, passum"
  ],
  "take care of": [
    "accūrō, accūrāre, accūrāvī, accūrātum"
  ],
  "do something with care": [
    "accūrō, accūrāre, accūrāvī, accūrātum"
  ],
  "attend or give attention to": [
    "accūrō, accūrāre, accūrāvī, accūrātum"
  ],
  "beat down": [
    "percellō, percellere, perculī, perculsum"
  ],
  "remark": [
    "observantia, observantiae"
  ],
  "note": [
    "Epistula",
    "observantia, observantiae"
  ],
  "observation": [
    "observātus, observātūs",
    "observantia, observantiae",
    "animadversiō, animadversiōnis"
  ],
  "a rival": [
    "competītor, competītōris"
  ],
  "competitor": [
    "competītor, competītōris"
  ],
  "adversary": [
    "adversārius, adversāriī",
    "competītor, competītōris"
  ],
  "opponent": [
    "adversārius, adversāriī",
    "competītor, competītōris"
  ],
  "plaintiff": [
    "competītor, competītōris"
  ],
  "an exception or objection in law": [
    "exceptiō, exceptiōnis"
  ],
  "recess": [
    "recessus, recessūs"
  ],
  "cheerfulness": [
    "alacritās, alacritātis",
    "hilaritās, hilaritātis"
  ],
  "liveliness": [
    "alacritās, alacritātis"
  ],
  "gaiety": [
    "alacritās, alacritātis"
  ],
  "animation": [
    "alacritās, alacritātis"
  ],
  "treasure": [
    "thēsaurus, thēsaurī"
  ],
  "hoard": [
    "thēsaurus, thēsaurī"
  ],
  "the act of forgetting": [
    "oblīviō, oblīviōnis"
  ],
  "forgetfulness": [
    "oblīvium, oblīviī",
    "oblīviō, oblīviōnis"
  ],
  "from heaven": [
    "dīvīnitus"
  ],
  "from a deity": [
    "dīvīnitus"
  ],
  "by inspiration": [
    "dīvīnitus"
  ],
  "the act of turning round or revolving": [
    "conversiō, conversiōnis"
  ],
  "revolution": [
    "conversiō, conversiōnis"
  ],
  "egyptian": [
    "aegyptius, aegyptia, aegyptium"
  ],
  "of or pertaining to egypt": [
    "aegyptius, aegyptia, aegyptium"
  ],
  "the act of approving": [
    "approbātiō, approbātiōnis"
  ],
  "approval": [
    "approbātiō, approbātiōnis"
  ],
  "approbation": [
    "approbātiō, approbātiōnis"
  ],
  "acquiescence": [
    "approbātiō, approbātiōnis"
  ],
  "of or pertaining to natural philosophy or physics": [
    "physicus, physica, physicum"
  ],
  "physical": [
    "corporālis, corporāle",
    "physicus, physica, physicum"
  ],
  "confirmation": [
    "cōnfirmātiō, cōnfirmātiōnis"
  ],
  "verification": [
    "cōnfirmātiō, cōnfirmātiōnis"
  ],
  "tameness": [
    "mānsuētūdō, mānsuētūdinis"
  ],
  "one of the first": [
    "prīmārius, prīmāria, prīmārium"
  ],
  "or first rank": [
    "prīmārius, prīmāria, prīmārium"
  ],
  "remarkable.[1] it was used as the title of a position in universities": [
    "prīmārius, prīmāria, prīmārium"
  ],
  "moderately": [
    "modestē, modestius, modestissimē",
    "mediōcriter, mediōcrius, mediōcrissimē"
  ],
  "ordinarily": [
    "mediōcriter, mediōcrius, mediōcrissimē"
  ],
  "count out or over": [
    "ēnumerō, ēnumerāre, ēnumeravī, ēnumerātum"
  ],
  "creditor": [
    "crēditor, crēditōris"
  ],
  "lender": [
    "crēditor, crēditōris"
  ],
  "scorn or resent": [
    "indignor, indignārī, indignātus sum"
  ],
  "cause to shake or totter": [
    "labefactō, labefactāre, labefactāvī, labefactātum"
  ],
  "cut off in front or through": [
    "praecīdō, praecīdere, praecīdī, praecīsum"
  ],
  "shorten": [
    "breviō, breviāre, breviāvī, breviātum",
    "praecīdō, praecīdere, praecīdī, praecīsum"
  ],
  "dark-coloured": [
    "aquilus, aquila, aquilum"
  ],
  "swarthy": [
    "aquilus, aquila, aquilum"
  ],
  "plenty": [
    "ūbertās, ūbertātis"
  ],
  "copiousness": [
    "ūbertās, ūbertātis"
  ],
  "the roof of the mouth": [
    "palātum, palātī"
  ],
  "palate": [
    "palātum, palātī"
  ],
  "crook": [
    "curvō, curvāre, curvāvī, curvātum"
  ],
  "fellow tribesman": [
    "tribūlis, tribūlis"
  ],
  "torrent": [
    "torrēns, torrentis"
  ],
  "of or pertaining to the ether": [
    "aetherius, aetheria, aetherium"
  ],
  "the sky": [
    "aetherius, aetheria, aetherium"
  ],
  "or the air or upper air": [
    "aetherius, aetheria, aetherium"
  ],
  "ethereal": [
    "Etherealis",
    "aetherius, aetheria, aetherium"
  ],
  "aloft": [
    "aetherius, aetheria, aetherium"
  ],
  "redness": [
    "rubor, rubōris"
  ],
  "tire": [
    "lassō, lassāre, lassāvī, lassātum"
  ],
  "fatigue": [
    "dēfatīgātiō, dēfatīgātiōnis",
    "lassō, lassāre, lassāvī, lassātum"
  ],
  "render faint": [
    "lassō, lassāre, lassāvī, lassātum"
  ],
  "a short time ago": [
    "dūdum"
  ],
  "abolish": [
    "abrogō, abrogāre, abrogāvī, abrogātum",
    "interemō, interemere, interemī, interemptum"
  ],
  "abrogate": [
    "abrogō, abrogāre, abrogāvī, abrogātum"
  ],
  "annul": [
    "abrogō, abrogāre, abrogāvī, abrogātum"
  ],
  "recall": [
    "abrogō, abrogāre, abrogāvī, abrogātum"
  ],
  "live in exile": [
    "exsulō, exsulāre, exsulāvī, exsulātum"
  ],
  "be banished": [
    "exsulō, exsulāre, exsulāvī, exsulātum"
  ],
  "ignite": [
    "Igni",
    "īnflammō, īnflammāre, īnflammāvī, īnflammātum"
  ],
  "wrestling school": [
    "palaestra, palaestrae"
  ],
  "palaestra": [
    "palaestra, palaestrae"
  ],
  "place of exercise": [
    "palaestra, palaestrae"
  ],
  "gymnasium": [
    "gymnasium, gymnasiī",
    "palaestra, palaestrae"
  ],
  "light-bringing": [
    "lūcifer, lūcifera, lūciferum"
  ],
  "first line of the pre-marian roman army": [
    "hastātī, hastātōrum"
  ],
  "compliance": [
    "obsequium, obsequiī"
  ],
  "deference": [
    "obsequium, obsequiī"
  ],
  "allegiance": [
    "Devotio",
    "obsequium, obsequiī"
  ],
  "obsequiousness": [
    "obsequium, obsequiī"
  ],
  "a nostril": [
    "nāris, nāris"
  ],
  "disapprove": [
    "improbō, improbāre, improbāvī, improbātum",
    "reprobō, reprobāre, reprobāvī, reprobātum"
  ],
  "in turn": [
    "vicissim"
  ],
  "compress": [
    "artō, artāre, artāvī, artātum",
    "stīpō, stīpāre, stīpāvī, stīpātum",
    "comprimō, comprimere, compressī, compressum"
  ],
  "an oak": [
    "quercus, quercūs"
  ],
  "oak-tree": [
    "quercus, quercūs"
  ],
  "especially the italian oak": [
    "quercus, quercūs"
  ],
  "for sale": [
    "vēnālis, vēnāle"
  ],
  "dedicate": [
    "dēvoveō, dēvovēre, dēvōvī, dēvōtum",
    "dēdicō, dēdicāre, dēdicāvī, dēdicātum"
  ],
  "innate or inborn quality": [
    "indolēs, indolis"
  ],
  "very pleasing etc": [
    "pergrātus, pergrāta, pergrātum"
  ],
  "lie or break down": [
    "succumbō, succumbere, succubuī, succubitum"
  ],
  "which?": [
    "quotus, quota, quotum"
  ],
  "what number": [
    "quotus, quota, quotum"
  ],
  "lying": [
    "mendāx, mendācis"
  ],
  "deceptive": [
    "mendāx, mendācis",
    "captiōsus, captiōsa, captiōsum"
  ],
  "untruthful": [
    "mendāx, mendācis"
  ],
  "mendacious": [
    "mendāx, mendācis"
  ],
  "feigned": [
    "mendāx, mendācis"
  ],
  "not real": [
    "mendāx, mendācis"
  ],
  "a pine wood": [
    "taeda, taedae"
  ],
  "pray or supplicate": [
    "supplicō, supplicāre, supplicāvī, supplicātum"
  ],
  "a sweet taste": [
    "dulcēdō, dulcēdinis"
  ],
  "leniency": [
    "indulgentia, indulgentiae"
  ],
  "concession": [
    "indulgentia, indulgentiae"
  ],
  "pardon": [
    "indulgentia, indulgentiae",
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "make purple": [
    "purpurō, purpurāre, purpurāvī, purpurātum"
  ],
  "hooked": [
    "aduncus, adunca, aduncum",
    "hāmātus, hāmāta, hāmātum",
    "falcātus, falcāta, falcātum"
  ],
  "bent": [
    "curvus, curva, curvum",
    "aduncus, adunca, aduncum"
  ],
  "curved": [
    "curvus, curva, curvum",
    "aduncus, adunca, aduncum",
    "falcātus, falcāta, falcātum"
  ],
  "stiffness": [
    "rigor, rigōris",
    "squālor, squālōris"
  ],
  "roughness": [
    "scabiēs, scabiēī",
    "squālor, squālōris",
    "asperitās, asperitātis"
  ],
  "secular": [
    "profānus, profāna, profānum"
  ],
  "profane": [
    "profānus, profāna, profānum"
  ],
  "loveliness": [
    "venustās, venustātis",
    "amoenitās, amoenitātis"
  ],
  "comeliness": [
    "venustās, venustātis"
  ],
  "grace": [
    "Gratia",
    "decus, decoris",
    "venustās, venustātis",
    "ēlegantia, ēlegantiae",
    "decorō, decorāre, decorāvī, decorātum"
  ],
  "elegance": [
    "venustās, venustātis",
    "ēlegantia, ēlegantiae"
  ],
  "horrible": [
    "Terribilis",
    "horribilis, horribile, horribilior"
  ],
  "slowness": [
    "segnitia, segnitiae",
    "tarditās, tarditātis"
  ],
  "tardiness": [
    "segnitia, segnitiae",
    "tarditās, tarditātis"
  ],
  "the act of looking up or upwards": [
    "suspectus, suspectūs"
  ],
  "wherever": [
    "quōquō",
    "ubīcumque"
  ],
  "wheresoever": [
    "ubīcumque"
  ],
  "in whatever place": [
    "ubīcumque"
  ],
  "in any place": [
    "ubīcumque"
  ],
  "atrocity": [
    "atrōcitās, atrōcitātis"
  ],
  "adultery": [
    "adulterium, adulteriī"
  ],
  "a gymnasium": [
    "gymnasium, gymnasiī"
  ],
  "that which flees": [
    "profugus, profuga, profugum"
  ],
  "has fled": [
    "profugus, profuga, profugum"
  ],
  "fugitive": [
    "Fugitivus",
    "profugus, profuga, profugum"
  ],
  "unavenged": [
    "inultus, inulta, inultum"
  ],
  "shipwrecked": [
    "naufragus, naufraga, naufragum"
  ],
  "wrecked": [
    "naufragus, naufraga, naufragum"
  ],
  "powerless": [
    "impotēns, impotentis"
  ],
  "impotent": [
    "impotēns, impotentis"
  ],
  "puny": [
    "impotēns, impotentis"
  ],
  "contriver": [
    "inventor, inventōris"
  ],
  "discoverer": [
    "inventor, inventōris",
    "repertor, repertōris"
  ],
  "inventor": [
    "opifex, opificis",
    "inventor, inventōris",
    "repertor, repertōris"
  ],
  "drive forward": [
    "prōpulsō, prōpulsāre, prōpulsāvī, prōpulsātum"
  ],
  "thanks rendered": [
    "grātēs, grātium"
  ],
  "fog": [
    "Nebula",
    "nebula, nebulae",
    "cālīgō, cālīginis"
  ],
  "mist": [
    "cālīgō, cālīginis"
  ],
  "vapor": [
    "cālīgō, cālīginis"
  ],
  "several times": [
    "aliquotiēns"
  ],
  "timeliness": [
    "commoditās, commoditātis"
  ],
  "gullet": [
    "guttur, gutturis",
    "stomachus, stomachi"
  ],
  "fertile or fruitful": [
    "fēcundus, fēcunda, fēcundum"
  ],
  "unevenness": [
    "asperitās, asperitātis"
  ],
  "defraud": [
    "fraudō, fraudāre, fraudāvī, fraudātum"
  ],
  "swindle": [
    "fraudō, fraudāre, fraudāvī, fraudātum"
  ],
  "perception": [
    "animadversiō, animadversiōnis"
  ],
  "progeny": [
    "prōgeniēs, prōgeniēī"
  ],
  "wain": [
    "plaustrum, plaustrī"
  ],
  "cart": [
    "plaustrum, plaustrī",
    "vehiculum, vehiculī"
  ],
  "with favourable auspices": [
    "auspicātō"
  ],
  "very little or small": [
    "paululus, paulula, paululum"
  ],
  "obscure": [
    "ignōbilis, ignōbile",
    "obscūrō, obscūrāre, obscūrāvī, obscūrātum"
  ],
  "mingle or mix together": [
    "cōnfundō, cōnfundere, cōnfūdī, cōnfūsum"
  ],
  "alimentary canal": [
    "stomachus, stomachi"
  ],
  "make the print or mark of the palm of the hand": [
    "palmō, palmāre, palmāvī, palmātum"
  ],
  "strife": [
    "rixa, rixae",
    "iūrgium, iūrgiī",
    "certātiō, certātiōnis"
  ],
  "altercation": [
    "iūrgium, iūrgiī"
  ],
  "contention": [
    "rixa, rixae",
    "iūrgium, iūrgiī"
  ],
  "invective": [
    "iūrgium, iūrgiī"
  ],
  "empty space": [
    "vacuitās, vacuitātis"
  ],
  "vacancy": [
    "vacuitās, vacuitātis"
  ],
  "vacuity": [
    "vacuitās, vacuitātis"
  ],
  "lay oneself upon": [
    "incumbō, incumbere, incumbuī"
  ],
  "lean or recline on something": [
    "incumbō, incumbere, incumbuī"
  ],
  "a dragon": [
    "dracō, dracōnis"
  ],
  "a kind of snake or serpent": [
    "dracō, dracōnis"
  ],
  "keep going": [
    "prōrogō, prōrogāre, prōrogāvī, prōrogātum"
  ],
  "used other than with a figurative or idiomatic meaning: see satis": [
    "satisne"
  ],
  "‎ -ne. introducing questions": [
    "satisne"
  ],
  "keep warm": [
    "foveō, fovēre, fōvī, fōtum"
  ],
  "collarbone": [
    "iugulum, iugulī"
  ],
  "precaution": [
    "cautiō, cautiōnis"
  ],
  "propriety": [
    "ēlegantia, ēlegantiae"
  ],
  "refinement": [
    "ēlegantia, ēlegantiae"
  ],
  "be propitious to": [
    "addīcō, addīcere, addīxī, addictum"
  ],
  "favour": [
    "addīcō, addīcere, addīxī, addictum"
  ],
  "speak favourably ": [
    "addīcō, addīcere, addīxī, addictum"
  ],
  "womanly": [
    "muliebris, muliebre",
    "fēmineus, fēminea, fēmineum"
  ],
  "feminine": [
    "muliebris, muliebre",
    "fēmineus, fēminea, fēmineum"
  ],
  "relationship by marriage": [
    "adfīnitās, adfīnitātis"
  ],
  "lose remembrance of": [
    "oblīviscor, oblīviscī, oblītus sum"
  ],
  "forget ": [
    "oblīviscor, oblīviscī, oblītus sum"
  ],
  "marrow": [
    "medulla, medullae"
  ],
  "satyr": [
    "satyrus, satyrī"
  ],
  "faun": [
    "satyrus, satyrī"
  ],
  "nourished": [
    "alumnus, alumna, alumnum"
  ],
  "fostered": [
    "alumnus, alumna, alumnum"
  ],
  "etc": [
    "lāmina, lāminae",
    "lānūgō, lānūginis",
    "alumnus, alumna, alumnum",
    "tantusdem, tantadem, tantundem"
  ],
  "a place where sacred objects are kept": [
    "sacrārium, sacrāriī"
  ],
  "sacrarium": [
    "sacrārium, sacrāriī"
  ],
  "sacristy": [
    "sacrārium, sacrāriī"
  ],
  "the act of buying or purchasing": [
    "ēmptiō, ēmptiōnis"
  ],
  "especially cabbage": [
    "brassica, brassicae"
  ],
  "but including cauliflower and other varieties of brassica oleracea": [
    "brassica, brassicae"
  ],
  "encouragement": [
    "cohortātiō, cohortātiōnis"
  ],
  "exhortation": [
    "adhortātiō, adhortātiōnis",
    "cohortātiō, cohortātiōnis",
    "exhortātiō, exhortātiōnis"
  ],
  "description": [
    "dēscrīptiō, dēscrīptiōnis",
    "dēmōnstrātiō, dēmōnstrātiōnis"
  ],
  "wildness": [
    "feritās, feritātis"
  ],
  "path": [
    "callis, callis",
    "līmes, līmitis"
  ],
  "finish play": [
    "ēlūdō, ēlūdere, ēlūsī, ēlūsum"
  ],
  "cease to sport": [
    "ēlūdō, ēlūdere, ēlūsī, ēlūsum"
  ],
  "laurel tree": [
    "laurus, laurī"
  ],
  "slide": [
    "lāpsō, lāpsāre, lāpsāvī, lāpsātum"
  ],
  "stumble": [
    "lāpsō, lāpsāre, lāpsāvī, lāpsātum"
  ],
  "company or troop of soldiers": [
    "comitātus, comitātūs"
  ],
  "proposition": [
    "prōpositiō, prōpositiōnis"
  ],
  "representation": [
    "prōpositiō, prōpositiōnis",
    "repraesentātiō, repraesentātiōnis"
  ],
  "trembling": [
    "tremor, tremōris",
    "pavidus, pavida, pavidum",
    "coruscus, corusca, coruscum",
    "tremulus, tremula, tremulum"
  ],
  "quaking": [
    "pavor, pavōris",
    "tremor, tremōris",
    "pavidus, pavida, pavidum"
  ],
  "alarmed": [
    "pavidus, pavida, pavidum"
  ],
  "a rectangular piece of cloth worn by ladies in ancient rome and fastened with brooches": [
    "palla, pallae"
  ],
  "a dazzling or glossy whiteness": [
    "candor, candōris"
  ],
  "clearness": [
    "candor, candōris"
  ],
  "radiance": [
    "candor, candōris"
  ],
  "bring or draw out or forth": [
    "exprōmō, exprōmere, exprōmpsī, exprōmptum"
  ],
  "sorrow": [
    "maestitia, maestitiae"
  ],
  "dejection": [
    "maestitia, maestitiae"
  ],
  "melancholy": [
    "maestitia, maestitiae"
  ],
  "creep": [
    "serpō, serpere, serpsī, serptum"
  ],
  "crawl": [
    "serpō, serpere, serpsī, serptum"
  ],
  "move slowly": [
    "serpō, serpere, serpsī, serptum"
  ],
  "numberless": [
    "innumerus, innumera, innumerum"
  ],
  "demolition": [
    "excidium, excidiī"
  ],
  "of or pertaining to companionship": [
    "sociālis, sociāle"
  ],
  "companionable": [
    "sociālis, sociāle"
  ],
  "sociable": [
    "sociālis, sociāle"
  ],
  "social": [
    "sociālis, sociāle"
  ],
  "bailiff": [
    "vīlicus, vīlicī"
  ],
  "steward": [
    "vīlicus, vīlicī",
    "dispensātor, dispensātōris"
  ],
  "much less": [
    "nēdum"
  ],
  "not to speak of": [
    "nēdum"
  ],
  "make or offer a sacrifice": [
    "sacrificō, sacrificāre, sacrificāvī, sacrificātum"
  ],
  "ivory": [
    "ebur, eboris"
  ],
  "make hard": [
    "dūrō, dūrāre, dūrāvī, dūrātum"
  ],
  "make haste": [
    "festīnō, festīnāre, festīnāvī, festīnātum"
  ],
  "pass swiftly": [
    "festīnō, festīnāre, festīnāvī, festīnātum"
  ],
  "deprived of blood": [
    "exsanguis, exsangue"
  ],
  "without or lacking in blood": [
    "exsanguis, exsangue"
  ],
  "bloodless": [
    "exsanguis, exsangue"
  ],
  "up or around something": [
    "alligō, alligāre, alligāvī, alligātum"
  ],
  "bandage": [
    "alligō, alligāre, alligāvī, alligātum"
  ],
  "leisure time given to learning": [
    "schola, scholae"
  ],
  "schooltime": [
    "schola, scholae"
  ],
  "classtime": [
    "schola, scholae"
  ],
  "well-born": [
    "generōsus, generōsa, generōsum"
  ],
  "pull or pluck out": [
    "ēvellō, ēvellere, ēvellī, ēvulsum"
  ],
  "lie in or on": [
    "incubō, incubāre, incubuī, incubitum",
    "occubō, occubāre, occubuī, occubitum"
  ],
  "commerce": [
    "commercium, commerciī",
    "mercātūra, mercātūrae"
  ],
  "take into use": [
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "make use of": [
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "employ": [
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "practise": [
    "ūsūrpō, ūsūrpāre, ūsūrpāvī, ūsūrpātum"
  ],
  "volume": [
    "volūmen, volūminis"
  ],
  "scroll": [
    "Codex",
    "volūmen, volūminis"
  ],
  "well-disposed": [
    "propitius, propitia, propitium"
  ],
  "maim or mutilate by cutting off pieces": [
    "truncō, truncāre, truncāvī, truncātum"
  ],
  "connection": [
    "coniugium, coniugiī",
    "complexiō, complexiōnis",
    "conciliātiō, conciliātiōnis"
  ],
  "whenever": [
    "utcumque",
    "quandōcumque",
    "quotiēnscumque"
  ],
  "one way or another": [
    "utcumque"
  ],
  "of july": [
    "iūlius, iūlia, iūlium"
  ],
  "the fifth month of the roman calendar": [
    "iūlius, iūlia, iūlium"
  ],
  "be lord": [
    "dominor, dominārī, dominātus sum"
  ],
  "be master": [
    "dominor, dominārī, dominātus sum"
  ],
  "have dominion": [
    "dominor, dominārī, dominātus sum"
  ],
  "domineer": [
    "dominor, dominārī, dominātus sum"
  ],
  "translation": [
    "trānslātiō, trānslātiōnis"
  ],
  "in the broadest sense: the process of transferring or carrying something over from one thing to another": [
    "trānslātiō, trānslātiōnis"
  ],
  "in particular:translation of text from one language to another": [
    "trānslātiō, trānslātiōnis"
  ],
  "claimant": [
    "vindex, vindicis"
  ],
  "vindicator": [
    "vindex, vindicis"
  ],
  "outsider": [
    "advena, advenae"
  ],
  "foreigner": [
    "advena, advenae"
  ],
  "stranger": [
    "advena, advenae"
  ],
  "familiarly": [
    "familiāriter"
  ],
  "on friendly terms": [
    "familiāriter"
  ],
  "a warding off or averting by prayer": [
    "dēprecātiō, dēprecātiōnis"
  ],
  "deprecation": [
    "dēprecātiō, dēprecātiōnis"
  ],
  "invocation": [
    "dēprecātiō, dēprecātiōnis"
  ],
  "obstinately": [
    "contumāciter, contumācius, contumācissimē",
    "pertināciter, pertinācius, pertinācissimē"
  ],
  "undefiled": [
    "pudīcus, pudīca, pudīcum"
  ],
  "think or reflect upon": [
    "meditor, meditārī, meditātus sum"
  ],
  "contemplate": [
    "meditor, meditārī, meditātus sum",
    "contemplō, contemplāre, contemplāvī, contemplātum"
  ],
  "meditate": [
    "meditor, meditārī, meditātus sum",
    "versō, versāre, versāvī, versātum"
  ],
  "intend": [
    "meditor, meditārī, meditātus sum"
  ],
  "wearing a toga": [
    "praetextātus, praetextāta, praetextātum"
  ],
  "make pointed": [
    "acuō, acuere, acuī, acūtum"
  ],
  "sharpen": [
    "acuō, acuere, acuī, acūtum",
    "līmō, līmāre, līmāvī, līmātum",
    "exacuō, exacuere, exacuī, exacutum"
  ],
  "whet": [
    "acuō, acuere, acuī, acūtum"
  ],
  "physics": [
    "physica, physicae"
  ],
  "sow or plant": [
    "īnserō, īnserere, īnsēvī, īnsitum"
  ],
  "appoint": [
    "adsignō, adsignāre, adsignāvī, adsignātum"
  ],
  "a playing": [
    "lūsus, lūsūs"
  ],
  "quaestor": [
    "quaestor, quaestōris",
    "quaestōrius, quaestōria, quaestōrium"
  ],
  "blaze": [
    "relūceō, relūcēre, reluxī",
    "ārdeō, ārdēre, ārsī, ārsum",
    "flagrō, flagrāre, flagrāvī"
  ],
  "or tender": [
    "mītigō, mītigāre, mītigāvī, mītigātum"
  ],
  "a burial": [
    "sepultūra, sepultūrae"
  ],
  "interment": [
    "sepultūra, sepultūrae"
  ],
  "a sepulchering": [
    "sepultūra, sepultūrae"
  ],
  "rein": [
    "habēna, habēnae"
  ],
  "lash": [
    "Lash",
    "habēna, habēnae",
    "flagellum, flagellī"
  ],
  "bridle": [
    "frēnum, frēnī",
    "habēna, habēnae"
  ],
  "go away from": [
    "absistō, absistere, abstitī"
  ],
  "lean": [
    "macer, macra, macrum"
  ],
  "meager": [
    "macer, macra, macrum"
  ],
  "womb": [
    "uterus, uterī"
  ],
  "uterus": [
    "uterus, uterī"
  ],
  "petition": [
    "postulātiō, postulātiōnis"
  ],
  "vow": [
    "vōtum, vōtī",
    "voveō, vovēre, vōvī, vōtum",
    "dēvoveō, dēvovēre, dēvōvī, dēvōtum"
  ],
  "devote": [
    "dēvoveō, dēvovēre, dēvōvī, dēvōtum"
  ],
  "support with stakes": [
    "pālō, pālāre, pālāvī, pālātum"
  ],
  "pales or props": [
    "pālō, pālāre, pālāvī, pālātum"
  ],
  "prop up": [
    "pālō, pālāre, pālāvī, pālātum",
    "fulciō, fulcīre, fulsī, fultum",
    "adminiculō, adminiculāre, adminiculāvī, adminiculātum"
  ],
  "be evident or apparent": [
    "compāreō, compārēre, compāruī, compāritum"
  ],
  "be visible": [
    "compāreō, compārēre, compāruī, compāritum"
  ],
  "in that very place": [
    "ibīdem"
  ],
  "having curly hair or ringlets": [
    "cincinnātus, cincinnāta, cincinnātum"
  ],
  "sit apart": [
    "dissideō, dissidēre, dissēdī"
  ],
  "am divided": [
    "dissideō, dissidēre, dissēdī"
  ],
  "separate or remote from": [
    "dissideō, dissidēre, dissēdī"
  ],
  "be sick": [
    "vomō, vomere, vomuī, vomitum"
  ],
  "vomit": [
    "vomō, vomere, vomuī, vomitum"
  ],
  "puke": [
    "vomō, vomere, vomuī, vomitum"
  ],
  "throw up": [
    "vomō, vomere, vomuī, vomitum"
  ],
  "impart": [
    "impertiō, impertīre, impertīvī, impertītum"
  ],
  "assignment": [
    "dīscriptiō, dīscriptiōnis"
  ],
  "apportionment": [
    "dīscriptiō, dīscriptiōnis"
  ],
  "sit before or in front of": [
    "praesideō, praesidēre, praesēdī, praesessum"
  ],
  "not separate or distinct": [
    "prōmiscuus, prōmiscua, prōmiscuum"
  ],
  "mixed": [
    "admixtus, admixta, admixtum",
    "prōmiscuus, prōmiscua, prōmiscuum"
  ],
  "mutual": [
    "prōmiscuus, prōmiscua, prōmiscuum"
  ],
  "shared": [
    "cōnsors, cōnsortis",
    "prōmiscuus, prōmiscua, prōmiscuum"
  ],
  "feeling shame": [
    "verēcundus, verēcunda, verēcundum"
  ],
  "shamefaced": [
    "pudibundus, pudibunda, pudibundum",
    "verēcundus, verēcunda, verēcundum"
  ],
  "bashful": [
    "pudibundus, pudibunda, pudibundum",
    "verēcundus, verēcunda, verēcundum"
  ],
  "shy": [
    "verēcundus, verēcunda, verēcundum"
  ],
  "of september": [
    "september, septembris, septembre"
  ],
  "shamelessly": [
    "impudenter"
  ],
  "how far": [
    "quātenus"
  ],
  "unspoiled": [
    "incorruptus, incorrupta, incorruptum"
  ],
  "uninjured": [
    "inviolātus, inviolāta, inviolātum",
    "incorruptus, incorrupta, incorruptum"
  ],
  "uncorrupted": [
    "incorruptus, incorrupta, incorruptum"
  ],
  "a quiver": [
    "pharetra, pharetrae"
  ],
  "shaded": [
    "opācus, opāca, opācum"
  ],
  "darkened": [
    "opācus, opāca, opācum"
  ],
  "in the shade": [
    "opācus, opāca, opācum"
  ],
  "libidinous": [
    "libīdinōsus, libīdinōsa, libīdinōsum"
  ],
  "licentious": [
    "libīdinōsus, libīdinōsa, libīdinōsum"
  ],
  "covet": [
    "adamō, adamāre, adamāvī, adamātum",
    "concupiō, concupere, concupivī, concupitum",
    "concupīscō, concupīscere, concupīvī, concupītum"
  ],
  "spoils of war": [
    "manubia, manubiae"
  ],
  "prize money": [
    "manubia, manubiae"
  ],
  "shamefully": [
    "foedē, foedius, foedissimē"
  ],
  "disgracefully": [
    "foedē, foedius, foedissimē"
  ],
  "ignominiously": [
    "foedē, foedius, foedissimē"
  ],
  "sacrificial victim": [
    "victima, victimae"
  ],
  "a drop": [
    "gutta, guttae"
  ],
  "linen": [
    "linteus, lintea, linteum"
  ],
  "give assent or approval": [
    "assentior, assentīrī, assēnsus sum"
  ],
  "soaked": [
    "madidus, madida, madidum",
    "madefactus, madefacta, madefactum"
  ],
  "drenched": [
    "madidus, madida, madidum"
  ],
  "immensity": [
    "immānitās, immānitātis"
  ],
  "excess": [
    "immānitās, immānitātis"
  ],
  "a roof-tile": [
    "tēgula, tēgulae"
  ],
  "dedicate or devote to a deity": [
    "voveō, vovēre, vōvī, vōtum"
  ],
  "hold forth": [
    "exhibeō, exhibēre, exhibuī, exhibitum",
    "praebeō, praebēre, praebuī, praebitus"
  ],
  "reveal": [
    "exhibeō, exhibēre, exhibuī, exhibitum"
  ],
  "untrustworthy": [
    "īnfīdus, īnfīda, īnfīdum"
  ],
  "predict": [
    "augurō, augurāre, augurāvī, augurātum",
    "praedīcō, praedīcere, praedīxī, praedictum"
  ],
  "a laying under": [
    "subiectus, subiectūs"
  ],
  "the act of sitting together with": [
    "cōnsessus, cōnsessūs"
  ],
  "to decimate": [
    "decimō, decimāre, decimāvī, decimātum"
  ],
  "possessor": [
    "possessor, possessōris"
  ],
  "owner": [
    "Ad Possessorem",
    "possessor, possessōris"
  ],
  "occupier": [
    "possessor, possessōris"
  ],
  "promissory note": [
    "Epistula Debiti",
    "syngrapha, syngraphae"
  ],
  "iou": [
    "syngrapha, syngraphae"
  ],
  "a rodent": [
    "rōdēns, rōdēntis"
  ],
  "dressed in a military cloak or cape": [
    "palūdātus, palūdāta, palūdātum"
  ],
  "inimically": [
    "hostīliter, hostīlius, hostīlissimē"
  ],
  "like an enemy": [
    "hostīliter, hostīlius, hostīlissimē"
  ],
  "before long": [
    "propediem"
  ],
  "spit or spew out": [
    "respuō, respuere, respuī, respūtum"
  ],
  "bolt": [
    "Bolt",
    "claustrum, claustrī",
    "clausūra, clausūrae"
  ],
  "flash": [
    "fulgeō, fulgēre, fulsī"
  ],
  "lighten": [
    "fulgeō, fulgēre, fulsī"
  ],
  "glitter": [
    "ēlūceō, ēlūcēre, ēluxī",
    "fulgeō, fulgēre, fulsī",
    "refulgeō, refulgēre, refulsī",
    "splendeō, splendēre, splenduī"
  ],
  "gleam": [
    "fulgeō, fulgēre, fulsī",
    "splendeō, splendēre, splenduī"
  ],
  "glare": [
    "fulgeō, fulgēre, fulsī"
  ],
  "glisten": [
    "fulgeō, fulgēre, fulsī",
    "refulgeō, refulgēre, refulsī",
    "splendeō, splendēre, splenduī"
  ],
  "furnishings": [
    "supellex, supellectilis"
  ],
  "utensils": [
    "supellex, supellectilis"
  ],
  "a length": [
    "longinquitās, longinquitātis"
  ],
  "distance": [
    "distantia, distantiae",
    "longinquitās, longinquitātis"
  ],
  "remoteness": [
    "distantia, distantiae",
    "longinquitās, longinquitātis"
  ],
  "explanation": [
    "explānātiō, explānātiōnis",
    "interpretātiō, interpretātiōnis"
  ],
  "exposition": [
    "expositiō, expositiōnis",
    "dēclārātiō, dēclārātiōnis",
    "interpretātiō, interpretātiōnis"
  ],
  "interpretation": [
    "explānātiō, explānātiōnis",
    "interpretātiō, interpretātiōnis"
  ],
  "ripeness": [
    "mātūritās, mātūritātis"
  ],
  "maturity": [
    "mātūritās, mātūritātis"
  ],
  "pollute": [
    "foedō, foedāre, foedāvī, foedātum",
    "polluō, polluere, polluī, pollūtum",
    "collinō, collinere, collēvī, collitum",
    "incestō, incestāre, incestāvī, incestātum",
    "inquinō, inquināre, inquināvī, inquinātum",
    "adulterō, adulterāre, adulterāvī, adulterātum"
  ],
  "stain": [
    "polluō, polluere, polluī, pollūtum",
    "maculō, maculāre, maculāvī, maculātum",
    "inquinō, inquināre, inquināvī, inquinātum"
  ],
  "innocuous": [
    "innoxius, innoxia, innoxium"
  ],
  "a middle state": [
    "mediocritās, mediocritātis"
  ],
  "a burial mound": [
    "būstum, būstī"
  ],
  "tomb": [
    "Tomb",
    "būstum, būstī",
    "capulus, capulī",
    "sepulcrum, sepulcrī",
    "sepulchrum, sepulchrī"
  ],
  "cow": [
    "Vacca",
    "vacca, vaccae"
  ],
  "ordered": [
    "compositus, composita, compositum"
  ],
  "arranged": [
    "compositus, composita, compositum"
  ],
  "matching": [
    "compositus, composita, compositum"
  ],
  "come between": [
    "interveniō, intervenīre, intervēnī, interventum"
  ],
  "north wind": [
    "boreās, boreae",
    "aquilō, aquilōnis",
    "Ventus Septentrionalis"
  ],
  "become angry": [
    "irō, irāre, irāvī, irātum"
  ],
  "fly into a rage": [
    "irō, irāre, irāvī, irātum"
  ],
  "wonderfully": [
    "mīrābiliter, mīrābilius, mīrābilissimē"
  ],
  "marvellously": [
    "mīrābiliter, mīrābilius, mīrābilissimē"
  ],
  "extraordinarily": [
    "īnsigniter",
    "mīrābiliter, mīrābilius, mīrābilissimē"
  ],
  "admirably": [
    "mīrābiliter, mīrābilius, mīrābilissimē",
    "admīrābiliter, admīrābilius, admīrābilissimē"
  ],
  "small or young ass or donkey": [
    "asellus, asellī"
  ],
  "great grandfather": [
    "proavus, proavī"
  ],
  "fresh": [
    "recēns, recentis",
    "mustus, musta, mustum"
  ],
  "irritable": [
    "īrācundus, īrācunda, īrācundum"
  ],
  "irascible": [
    "īrāscibilis, īrāscibile",
    "īrācundus, īrācunda, īrācundum"
  ],
  "passionate": [
    "īrācundus, īrācunda, īrācundum"
  ],
  "be kind or courteous": [
    "indulgeō, indulgēre, indulsī, indultum"
  ],
  "am inclined": [
    "indulgeō, indulgēre, indulsī, indultum"
  ],
  "give free rein ": [
    "indulgeō, indulgēre, indulsī, indultum"
  ],
  "a young cow": [
    "vitula, vitulae",
    "iuvenca, iuvencae"
  ],
  "heifer": [
    "vitula, vitulae",
    "iuvenca, iuvencae"
  ],
  "strike or push violently": [
    "perpellō, perpellere, perpulī, perpulsum"
  ],
  "jurisdiction": [
    "iūrisdictiō, iūrisdictiōnis"
  ],
  "sportive": [
    "lūdicer, lūdicra, lūdicrum"
  ],
  "that serves for sport": [
    "lūdicer, lūdicra, lūdicrum"
  ],
  "timely": [
    "tempestīvus, tempestīva, tempestīvum"
  ],
  "fitting": [
    "decet: decēre",
    "decōrus, decōra, decōrum",
    "tempestīvus, tempestīva, tempestīvum",
    "conveniō, convenīre, convēnī, conventus"
  ],
  "a goad": [
    "stimulus, stimulī"
  ],
  "prick": [
    "stimulus, stimulī",
    "pungō, pungere, pupugī, punctum"
  ],
  "merciful": [
    "misericors, misericordis"
  ],
  "compassionate": [
    "misericors, misericordis"
  ],
  "run or meet together with someone": [
    "congruō, congruere, congruī"
  ],
  "advocate": [
    "suāsor, suāsōris",
    "cognitor, cognitōris"
  ],
  "attorney": [
    "cognitor, cognitōris"
  ],
  "the watery part that flows out in pressing olives": [
    "amurca, amurcae"
  ],
  "chide": [
    "obiūrgō, obiūrgāre, obiūrgāvī, obiūrgātum"
  ],
  "scold": [
    "obiūrgō, obiūrgāre, obiūrgāvī, obiūrgātum"
  ],
  "rebuke": [
    "increpātiō, increpātiōnis",
    "obiurgātiō, obiurgātiōnis",
    "obiūrgō, obiūrgāre, obiūrgāvī, obiūrgātum"
  ],
  "deer": [
    "Deer",
    "Cervus",
    "cervus, cervī"
  ],
  "stag": [
    "cervus, cervī"
  ],
  "a strengthening": [
    "firmāmentum, firmāmentī"
  ],
  "prop": [
    "pālus, pāli",
    "adminiculum, adminiculī",
    "firmāmentum, firmāmentī"
  ],
  "stand around": [
    "circumstō, circumstāre, circumstetī, circumstātum"
  ],
  "or in a circle": [
    "circumstō, circumstāre, circumstetī, circumstātum"
  ],
  "dove": [
    "columba, columbae"
  ],
  "pigeon": [
    "columba, columbae"
  ],
  "invention": [
    "commentum, commentī",
    "inventiō, inventiōnis",
    "adinventiō, adinventiōnis"
  ],
  "widening": [
    "amplificātiō, amplificātiōnis"
  ],
  "butcher": [
    "Lanius",
    "carnifex, carnificis"
  ],
  "knacker [1]": [
    "carnifex, carnificis"
  ],
  "little sharp point or sting": [
    "spīculum, spīculī"
  ],
  "descended from": [
    "oriundus, oriunda, oriundum"
  ],
  "someone who does work": [
    "opifex, opificis"
  ],
  "worker": [
    "opifex, opificis"
  ],
  "maker": [
    "opifex, opificis"
  ],
  "framer": [
    "opifex, opificis"
  ],
  "fabricator": [
    "opifex, opificis"
  ],
  "workman": [
    "opifex, opificis"
  ],
  "mechanic": [
    "opifex, opificis"
  ],
  "artificer": [
    "opifex, opificis"
  ],
  "craftsman": [
    "opifex, opificis"
  ],
  "regard as good": [
    "approbō, approbāre, approbāvī, approbātum"
  ],
  "favor or favour": [
    "approbō, approbāre, approbāvī, approbātum"
  ],
  "commend": [
    "approbō, approbāre, approbāvī, approbātum",
    "benedīcō, benedīcere, benedīxī, benedictum",
    "commendō, commendāre, commendāvī, commendātum"
  ],
  "endorse": [
    "approbō, approbāre, approbāvī, approbātum"
  ],
  "studiously": [
    "studiōsē, studiōsius, studiōsissimē"
  ],
  "wedge": [
    "cuneus, cuneī"
  ],
  "wedge shape": [
    "cuneus, cuneī"
  ],
  "saving": [
    "Tuens",
    "sospes, sospitis",
    "compendium, compendiī"
  ],
  "delivering": [
    "sospes, sospitis"
  ],
  "chorus": [
    "chorus, chorī"
  ],
  "inviolability": [
    "sānctitās, sānctitātis"
  ],
  "sacredness": [
    "sānctitās, sānctitātis"
  ],
  "sanctity": [
    "sānctitās, sānctitātis",
    "sanctimōnia, sanctimōniae"
  ],
  "bind fast": [
    "dēvinciō, dēvincīre, dēvinxī, dēvinctum",
    "praestringō, praestringere, praestrinxī, praestrictum"
  ],
  "tie up": [
    "dēvinciō, dēvincīre, dēvinxī, dēvinctum",
    "praestringō, praestringere, praestrinxī, praestrictum"
  ],
  "consume ": [
    "abūtor, abūtī, abūsus sum"
  ],
  "crooked": [
    "curvus, curva, curvum",
    "hāmātus, hāmāta, hāmātum"
  ],
  "globe": [
    "globus, globī",
    "sphaera, sphaerae"
  ],
  "sphere": [
    "globus, globī",
    "sphaera, sphaerae"
  ],
  "eyebrow": [
    "supercilium, superciliī"
  ],
  "mark off": [
    "terminō, termināre, termināvī, terminātum"
  ],
  "cripple or maim": [
    "dēbilitō, dēbilitāre, dēbilitāvī, dēbilitātum"
  ],
  "fall down": [
    "occidō, occidere, occidī, occāsum",
    "corruō, corruere, corruī, corrutum"
  ],
  "totter": [
    "corruō, corruere, corruī, corrutum"
  ],
  "rostrate or beaky: beaked": [
    "rostrātus, rostrāta, rostrātus"
  ],
  "beak-like": [
    "rostrātus, rostrāta, rostrātus"
  ],
  "covering": [
    "vēlāmen, vēlāminis",
    "operculum, operculī",
    "vēlāmentum, vēlāmentī",
    "operīmentum, operīmentī",
    "strāgulus, strāgula, strāgulum"
  ],
  "utterly subdue": [
    "contundō, contundere, contudī, contūsum"
  ],
  "pour in": [
    "īnfundō, īnfundere, īnfūdī, īnfūsum"
  ],
  "upon or into": [
    "īnfundō, īnfundere, īnfūdī, īnfūsum"
  ],
  "of or pertaining to books": [
    "librārius, librāria, librārium"
  ],
  "diaphragm": [
    "praecordia, praecordiae"
  ],
  "midriff": [
    "praecordia, praecordiae"
  ],
  "intemperateness": [
    "intemperantia, intemperantiae"
  ],
  "inclemency": [
    "intemperantia, intemperantiae"
  ],
  "absurdity": [
    "ad absurdum",
    "ineptia, ineptiae"
  ],
  "enumeration": [
    "ēnumerātiō, ēnumerātiōnis"
  ],
  "listing": [
    "ēnumerātiō, ēnumerātiōnis"
  ],
  "colicky": [
    "īliacus, īliaca, īliacum"
  ],
  "rainstorm": [
    "nimbus, nimbī"
  ],
  "rain shower": [
    "nimbus, nimbī"
  ],
  "prostitute": [
    "scortum, scortī",
    "meretrīx, meretrīcis"
  ],
  "dwell by or near": [
    "accolō, accolere, accoluī, accultum"
  ],
  "four days": [
    "quadriduum, quadriduī"
  ],
  "constant presense with someone": [
    "adsiduitās, adsiduitātis"
  ],
  "duration": [
    "Duratio",
    "tenor, tenōris",
    "dūrātiō, dūrātiōnis",
    "adsiduitās, adsiduitātis"
  ],
  "unwarlike": [
    "imbellis, imbelle"
  ],
  "from another place": [
    "aliunde"
  ],
  "offer for sale": [
    "vēnditō, vēnditāre, vēnditāvī, vēnditātum"
  ],
  "put in motion": [
    "cieō, ciēre, cīvī, citum"
  ],
  "tragedy": [
    "tragoedia, tragoediae"
  ],
  "call by name": [
    "nūncupō, nūncupāre, nūncupāvī, nūncupātum"
  ],
  "painstaking": [
    "operōsus, operōsa, operōsum"
  ],
  "striving to equal or excel": [
    "aemulus, aemula, aemulum"
  ],
  "rivaling": [
    "aemulus, aemula, aemulum"
  ],
  "female prostitute": [
    "meretrīx, meretrīcis"
  ],
  "preeminence": [
    "praestantia, praestantiae"
  ],
  "superiority": [
    "excellentia, excellentiae",
    "praestantia, praestantiae"
  ],
  "set or lay under": [
    "subdō, subdere, subdidī, subditum"
  ],
  "set to or apply under": [
    "subdō, subdere, subdidī, subditum"
  ],
  "in full view": [
    "prōpalam"
  ],
  "openly": [
    "prōpalam",
    "manifestō, manifestius, manifestissimē"
  ],
  "publicly": [
    "prōpalam"
  ],
  "notoriously": [
    "prōpalam"
  ],
  "manifestly": [
    "prōpalam",
    "ēvidenter, ēvidentius, ēvidentissimē",
    "manifestō, manifestius, manifestissimē"
  ],
  "disorderly": [
    "incompositus, incomposita, incompositum"
  ],
  "that steals sacred things or robs a temple": [
    "sacrilegus, sacrilega, sacrilegum"
  ],
  "sacrilegious": [
    "sacrilegus, sacrilega, sacrilegum"
  ],
  "loathe": [
    "fastīdiō, fastīdīre, fastīdīvī, fastīditum"
  ],
  "dislike": [
    "malevolentia, malevolentiae",
    "fastīdiō, fastīdīre, fastīdīvī, fastīditum"
  ],
  "sense of touch": [
    "tactus, tactūs"
  ],
  "dung": [
    "fimum, fimī",
    "stercus, stercoris"
  ],
  "excrement": [
    "fimum, fimī",
    "stercus, stercoris"
  ],
  "ordure": [
    "fimum, fimī",
    "stercus, stercoris"
  ],
  "fall silent": [
    "conticēscō, conticēscere, conticuī"
  ],
  "debtor": [
    "dēbitor, dēbitōris"
  ],
  "sagum": [
    "sagum, sagī"
  ],
  "a military cloak": [
    "sagum, sagī"
  ],
  "disapprove of": [
    "abdīcō, abdīcere, abdīxī, abdictum"
  ],
  "disallow": [
    "abdīcō, abdīcere, abdīxī, abdictum"
  ],
  "belonging to the courts of justice": [
    "iūdiciālis, iūdiciāle"
  ],
  "judicial": [
    "iūdiciālis, iūdiciāle",
    "iūdiciārius, iūdiciāria, iūdiciārium"
  ],
  "interest on capital": [
    "fēnus, fēnoris"
  ],
  "of june": [
    "iūnius, iūnia, iūnium"
  ],
  "intimation": [
    "dēnuntiātiō, dēnuntiātiōnis"
  ],
  "announcement": [
    "dēnuntiātiō, dēnuntiātiōnis"
  ],
  "declaration": [
    "professiō, professiōnis",
    "affirmātiō, affirmātiōnis",
    "dēclārātiō, dēclārātiōnis",
    "dēnuntiātiō, dēnuntiātiōnis",
    "prōnuntiātiō, prōnuntiātiōnis"
  ],
  "proverb": [
    "prōverbium, prōverbiī"
  ],
  "saw": [
    "Serra",
    "prōverbium, prōverbiī"
  ],
  "maxim": [
    "prōverbium, prōverbiī"
  ],
  "adage": [
    "prōverbium, prōverbiī"
  ],
  "untamed": [
    "indomitus, indomita, indomitum"
  ],
  "ungoverned": [
    "indomitus, indomita, indomitum"
  ],
  "unrestrained": [
    "intemperāns, intemperantis",
    "indomitus, indomita, indomitum",
    "effrēnātus, effrēnāta, effrēnātum"
  ],
  "stick or inhere in": [
    "inhaereō, inhaerēre, inhaesī, inhaesum"
  ],
  "hang or adhere to": [
    "inhaereō, inhaerēre, inhaesī, inhaesum"
  ],
  "hold on to": [
    "inhaereō, inhaerēre, inhaesī, inhaesum"
  ],
  "forcible": [
    "violentus, violenta, violentum"
  ],
  "violent": [
    "violēns, violentis",
    "protervus, proterva, protervum",
    "violentus, violenta, violentum"
  ],
  "vehement": [
    "protervus, proterva, protervum",
    "violentus, violenta, violentum"
  ],
  "recover": [
    "convalēscō, convalēscere, convaluī",
    "relegō, relegere, relēgī, relēctum",
    "reparō, reparāre, reparāvī, reparātum"
  ],
  "gain strength": [
    "convalēscō, convalēscere, convaluī"
  ],
  "the wool shorn from a sheep": [
    "vellus, velleris"
  ],
  "fleece": [
    "vellus, veleris",
    "vellus, velleris",
    "vellus, veleris (n)"
  ],
  "assent": [
    "adsensiō, adsensiōnis",
    "assentātiō, assentātiōnis",
    "assentor, assentārī, assentātus sum"
  ],
  "a holding on": [
    "tenor, tenōris"
  ],
  "continuance": [
    "tenor, tenōris",
    "mānsiō, mānsiōnis"
  ],
  "career": [
    "tenor, tenōris"
  ],
  "to place under": [
    "subpōnō, subpōnere, subposuī, subpositum",
    "suppōnō, suppōnere, supposuī, suppositum"
  ],
  "neapolitan": [
    "neāpolītānus, neāpolītāna, neāpolītānum"
  ],
  "of or belonging to naples": [
    "neāpolītānus, neāpolītāna, neāpolītānum"
  ],
  "a liberator": [
    "līberātor, līberātoris"
  ],
  "deliverer": [
    "līberātor, līberātoris"
  ],
  "catkin": [
    "iūlus, iūlī"
  ],
  "transalpine": [
    "trānsalpīnus, trānsalpīna, trānsalpīnum"
  ],
  "storehouse": [
    "horreum, horreī"
  ],
  "allowed": [
    "fastus, fasta, fastum"
  ],
  "free from faults": [
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum"
  ],
  "amend": [
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum"
  ],
  "revise": [
    "ēmendō, ēmendāre, ēmendāvī, ēmendātum"
  ],
  "uncondemned": [
    "indemnātus, indemnāta, indemnātum"
  ],
  "unsentenced": [
    "indemnātus, indemnāta, indemnātum"
  ],
  "merriment": [
    "hilaritās, hilaritātis",
    "fēstīvitās, fēstīvitātis"
  ],
  "good humor": [
    "hilaritās, hilaritātis"
  ],
  "fly about": [
    "volitō, volitāre, volitāvī, volitātus"
  ],
  "ploughing": [
    "arātiō, arātiōnis"
  ],
  "hoarse": [
    "raucus, rauca, raucum"
  ],
  "of or pertaining to old people": [
    "senīlis, senīle"
  ],
  "senile": [
    "senīlis, senīle"
  ],
  "separation": [
    "dīscidium, dīscidiī",
    "dīvortium, dīvortiī",
    "sēparātiō, sēparātiōnis",
    "abstractiō, abstractiōnis"
  ],
  "lay hold of": [
    "inhibeō, inhibēre, inhibuī, inhibitum"
  ],
  "keep back": [
    "inhibeō, inhibēre, inhibuī, inhibitum"
  ],
  "forebode": [
    "augurō, augurāre, augurāvī, augurātum"
  ],
  "seal up": [
    "obsignō, obsignāre, obsignāvī, obsignātum"
  ],
  "affix a seal to": [
    "obsignō, obsignāre, obsignāvī, obsignātum"
  ],
  "shine in": [
    "inlūceō, inlūcēre, inluxī"
  ],
  "shine on": [
    "inlūceō, inlūcēre, inluxī"
  ],
  "track or trace out": [
    "investīgō, investīgāre, investīgāvī, investīgātum"
  ],
  "search into": [
    "investīgō, investīgāre, investīgāvī, investīgātum"
  ],
  "a grain": [
    "grānum, grānī"
  ],
  "seed or small kernel": [
    "grānum, grānī"
  ],
  "born in another country": [
    "aliēnigenus, aliēnigena, aliēnigenum"
  ],
  "good-looking": [
    "speciōsus, speciōsa, speciōsum"
  ],
  "pine tree": [
    "pīnus"
  ],
  "laziness": [
    "ignāvia, ignāviae",
    "pigritia, pigritiae"
  ],
  "sloth": [
    "ignāvia, ignāviae",
    "pigritia, pigritiae",
    "segnitia, segnitiae"
  ],
  "listlessness": [
    "ignāvia, ignāviae"
  ],
  "the act of unfolding or unrolling": [
    "explicātiō, explicātiōnis"
  ],
  "a deep sleep": [
    "sopor, sopōris"
  ],
  "sopor": [
    "sopor, sopōris"
  ],
  "catalepsy": [
    "sopor, sopōris"
  ],
  "looking back": [
    "respectus, respectūs"
  ],
  "token": [
    "symbolum, symbolī",
    "specimen, speciminis"
  ],
  "squat": [
    "subsīdō, subsīdere, subsēdī, subsessum"
  ],
  "full of skill": [
    "artificiōsus, artificiōsa, artificiōsum"
  ],
  "skilful": [
    "sollers, sollertis",
    "artificiōsus, artificiōsa, artificiōsum"
  ],
  "artful": [
    "vafer, vafra, vafrum",
    "artificiōsus, artificiōsa, artificiōsum"
  ],
  "dutiful": [
    "pius, pia, pium",
    "officiōsus, officiōsa, officiōsum"
  ],
  "obliging": [
    "cōmis, cōme",
    "officiōsus, officiōsa, officiōsum"
  ],
  "leap up": [
    "exsultō, exsultāre, exsultāvī, exsultātum"
  ],
  "scar": [
    "cicātrīx, cicātrīcis"
  ],
  "incision": [
    "cicātrīx, cicātrīcis"
  ],
  "carrying or bringing back": [
    "relātiō, relātiōnis"
  ],
  "oppose or resist": [
    "obsistō, obsistere, obstitī, obstitum"
  ],
  "stringed instrument": [
    "fidis, fidis"
  ],
  "come out or forth": [
    "exorior, exorīrī, exortus sum"
  ],
  "prophet": [
    "Vastes",
    "prophēta, prophētae"
  ],
  "soothsayer": [
    "prophēta, prophētae",
    "coniector, coniectōris"
  ],
  "speak well of someone": [
    "benedīcō, benedīcere, benedīxī, benedictum"
  ],
  "psalm": [
    "psalmus, psalmī"
  ],
  "a notice sent to a higher tribunal or judge": [
    "apostolus, apostolī"
  ],
  "altar": [
    "Ara",
    "altare",
    "āra, -ae",
    "āra, -ae (f)",
    "altar, altāris",
    "altārium, altāriī"
  ],
  "fitting for burnt offerings": [
    "altare"
  ],
  "bodily": [
    "corporālis, corporāle"
  ],
  "happiness": [
    "beātitūdō, beātitūdinis"
  ],
  "blessedness": [
    "beātitūdō, beātitūdinis"
  ],
  "felicity": [
    "beātitūdō, beātitūdinis"
  ],
  "beatitude": [
    "beātitūdō, beātitūdinis"
  ],
  "blissfulness": [
    "beātitūdō, beātitūdinis"
  ],
  "simply": [
    "simpliciter, simplicius, simplicissimē"
  ],
  "straightforwardly": [
    "simpliciter, simplicius, simplicissimē"
  ],
  "directly": [
    "simpliciter, simplicius, simplicissimē"
  ],
  "especially a spectre": [
    "īdōlum, īdōlī"
  ],
  "apparition or ghost": [
    "īdōlum, īdōlī"
  ],
  "dweller": [
    "habitātor, habitātōris"
  ],
  "forsake": [
    "dērelinquō, dērelinquere, dēreliquī, dērelictum"
  ],
  "discard or desert": [
    "dērelinquō, dērelinquere, dēreliquī, dērelictum"
  ],
  "a creating": [
    "creātiō, creātiōnis"
  ],
  "producing": [
    "creātiō, creātiōnis",
    "cōnfectiō, cōnfectiōnis"
  ],
  "creation": [
    "creātiō, creātiōnis"
  ],
  "theological": [
    "theologicus, theologica, theologicum"
  ],
  "consecrate": [
    "sānctificō, sānctificāre, sānctificāvī, sānctificātum"
  ],
  "make holy": [
    "sānctificō, sānctificāre, sānctificāvī, sānctificātum"
  ],
  "set aside for sacred or ceremonial use": [
    "sānctificō, sānctificāre, sānctificāvī, sānctificātum"
  ],
  "of or pertaining to angels": [
    "angelicus, angelica, angelicum"
  ],
  "angelic": [
    "angelicus, angelica, angelicum"
  ],
  "chew": [
    "mandūcō, mandūcāre, mandūcāvī, mandūcātum"
  ],
  "tribulation": [
    "trībulātiō, trībulātiōnis"
  ],
  "prince's lockbox": [
    "sanctuārium, sanctuāriī"
  ],
  "a genius loci": [
    "daemon, daemōnis"
  ],
  "a lar": [
    "daemon, daemōnis"
  ],
  "the protective spirit or godling of a place or household": [
    "daemon, daemōnis"
  ],
  "peacemaking": [
    "pācificus, pācifica, pācificum"
  ],
  "pacific": [
    "pācificus, pācifica, pācificum"
  ],
  "peaceable": [
    "pācificus, pācifica, pācificum"
  ],
  "a burnt offering wholly consumed by fire": [
    "holocaustum, holocaustī"
  ],
  "sinner": [
    "peccātor, peccātōris"
  ],
  "captivity": [
    "captīvitās, captīvitātis"
  ],
  "bondage": [
    "captīvitās, captīvitātis"
  ],
  "exalt or elevate": [
    "exaltō, exaltāre, exaltāvī, exaltātum"
  ],
  "the number three": [
    "trīnitās, trīnitātis"
  ],
  "vigilant": [
    "Vigilans",
    "vigilāns, vigilantis"
  ],
  "watchful": [
    "vigilāns, vigilantis"
  ],
  "repentance": [
    "Paenitentia",
    "paenitentia, paenitentiae"
  ],
  "penitence": [
    "paenitentia, paenitentiae"
  ],
  "regret": [
    "paenitentia, paenitentiae",
    "paeniteō, paenitēre, paenituī, —"
  ],
  "act of leaning": [
    "inclīnātiō, inclīnātiōnis"
  ],
  "a blessed person": [
    "benedictus, benedictī"
  ],
  "plight": [
    "habitūdō, habitūdinis"
  ],
  "a throne": [
    "thronus, thronī"
  ],
  "prophesy": [
    "vāticinor, vāticinārī, vāticinātus sum",
    "prophētō, prophētāre, prophētāvī, prophētātum"
  ],
  "foretell or predict": [
    "prophētō, prophētāre, prophētāvī, prophētātum"
  ],
  "aversion": [
    "abōminātiō, abōminātiōnis"
  ],
  "detestation": [
    "abōminātiō, abōminātiōnis"
  ],
  "loathing": [
    "fastīdium, fastīdiī",
    "abōminātiō, abōminātiōnis"
  ],
  "fatherly feeling or care": [
    "paternitās, paternitātis"
  ],
  "fatherhood": [
    "paternitās, paternitātis"
  ],
  "paternity": [
    "paternitās, paternitātis"
  ],
  "morally pure": [
    "castus, casta, castum"
  ],
  "guiltless": [
    "castus, casta, castum"
  ],
  "destroy or ruin": [
    "disperdō, disperdere, disperdidī, disperditum"
  ],
  "go or pass through": [
    "pertrānseō, pertrānsīre, pertrānsiī, pertrānsitum"
  ],
  "slander": [
    "maledīcō, maledīcere, maledīxī, maledictum"
  ],
  "speak ill of": [
    "maledīcō, maledīcere, maledīxī, maledictum"
  ],
  "prolonging": [
    "prōductiō, prōductiōnis"
  ],
  "lengthening": [
    "prōductiō, prōductiōnis"
  ],
  "extension": [
    "extēnsiō, extēnsiōnis",
    "prōductiō, prōductiōnis",
    "continuitās, continuitātis"
  ],
  "sensitive": [
    "sēnsitīvus, sēnsitīva, sēnsitīvum"
  ],
  "shekel": [
    "siclus, siclī"
  ],
  "a jet of water": [
    "tullius, tulliī"
  ],
  "a waterfall": [
    "tullius, tulliī"
  ],
  "abase": [
    "humiliō, humiliāre, humiliāvī, humiliātum"
  ],
  "a buck": [
    "hircus, hircī"
  ],
  "male goat": [
    "hircus, hircī"
  ],
  "cherub": [
    "cherub, cherub"
  ],
  "fineness": [
    "minūtia, minūtiae",
    "tenuitas, tenuitātis",
    "subtīlitās, subtīlitātis"
  ],
  "thinness": [
    "maciēs, maciēī",
    "rāritās, rāritātis",
    "tenuitas, tenuitātis",
    "subtīlitās, subtīlitātis"
  ],
  "slenderness": [
    "subtīlitās, subtīlitātis"
  ],
  "a comparison": [
    "parabola, parabolae"
  ],
  "illustration": [
    "parabola, parabolae"
  ],
  "theologian": [
    "theologus, theologī"
  ],
  "become stronger": [
    "cōnfortō, cōnfortāre, cōnfortāvī, cōnfortātum"
  ],
  "absolutely": [
    "absolūtē, absolūtius, absolūtissimē"
  ],
  "fully": [
    "cōpiōsē, cōpiōsius, cōpiōsissimē",
    "absolūtē, absolūtius, absolūtissimē",
    "abundanter, abundantius, abundantissimē"
  ],
  "irreverence": [
    "impietās, impietātis"
  ],
  "ungodliness": [
    "impietās, impietātis"
  ],
  "impiety": [
    "nefās",
    "impietās, impietātis"
  ],
  "covert": [
    "absconditus, abscondita, absconditum"
  ],
  "a camel": [
    "camēlus, camēlī"
  ],
  "sanctification": [
    "sānctificātiō, sānctificātiōnis"
  ],
  "lard": [
    "adeps, adipis"
  ],
  "grease": [
    "adeps, adipis"
  ],
  "desirable": [
    "optābilis, optābile",
    "dēsīderābilis, dēsīderābile",
    "concupiscibilis, concupiscibile"
  ],
  "falsity": [
    "falsitās, falsitātis"
  ],
  "fornication": [
    "fornicātiō, fornicātiōnis"
  ],
  "pause": [
    "Pausa",
    "diapsalma, diapsalmatis"
  ],
  "concupiscence": [
    "concupiscentia, concupiscentiae"
  ],
  "choleric": [
    "īrāscibilis, īrāscibile"
  ],
  "of or pertaining to the essence or substance": [
    "substantiālis, substantiāle"
  ],
  "substantial": [
    "reālis, reāle",
    "substantiālis, substantiāle"
  ],
  "essential": [
    "essentiālis, essentiāle",
    "substantiālis, substantiāle"
  ],
  "an abyss": [
    "abyssus, abyssī"
  ],
  "relative": [
    "relātīvus, relātīva, relātīvum"
  ],
  "not absolute": [
    "relātīvus, relātīva, relātīvum"
  ],
  "having relation to or dependence on something else": [
    "relātīvus, relātīva, relātīvum"
  ],
  "dwell in": [
    "inhabitō, inhabitāre, inhabitāvī, inhabitātum"
  ],
  "occupy or inhabit": [
    "inhabitō, inhabitāre, inhabitāvī, inhabitātum"
  ],
  "possibly": [
    "fōrtassis"
  ],
  "probably": [
    "fōrtassis",
    "probābiliter, probābilius, probābilissimē"
  ],
  "glorify": [
    "glōrificō, glōrificāre, glōrificāvī, glōrificātum"
  ],
  "personal": [
    "persōnālis, persōnāle"
  ],
  "of or belonging to a person": [
    "persōnālis, persōnāle"
  ],
  "clean or cleanse": [
    "mundō, mundāre, mundāvī, mundātum"
  ],
  "devil": [
    "diabolus, diabolī"
  ],
  "resurrection": [
    "resurrectiō, resurrectiōnis"
  ],
  "more than needed": [
    "supervacuus, supervacua, supervacuum"
  ],
  "needless": [
    "supervacuus, supervacua, supervacuum"
  ],
  "unnecessary": [
    "supervacuus, supervacua, supervacuum"
  ],
  "superfluous": [
    "supervacuus, supervacua, supervacuum"
  ],
  "redundant": [
    "supervacuus, supervacua, supervacuum"
  ],
  "real actual": [
    "reālis, reāle"
  ],
  "that actually exists": [
    "reālis, reāle"
  ],
  "solemnity": [
    "sollemnitās, sollemnitātis"
  ],
  "an inhabiting": [
    "habitātiō, habitātiōnis"
  ],
  "beating or striking especially of ones breasts": [
    "plānctus, plānctūs"
  ],
  "persuasion": [
    "Persuasio",
    "suāsiō, suāsiōnis",
    "persuāsiō, persuāsiōnis"
  ],
  "sum up": [
    "cōnsummō, cōnsummāre, cōnsummāvī, cōnsummātum"
  ],
  "esteem highly": [
    "magnificō, magnificāre, magnificāvī, magnificātum"
  ],
  "that which gives light": [
    "lūmināre, lūmināris"
  ],
  "enlightener": [
    "lūmināre, lūmināris"
  ],
  "leprosy": [
    "lepra, leprae"
  ],
  "declamation": [
    "dēclāmātiō, dēclāmātiōnis"
  ],
  "of or belonging to time": [
    "temporālis, temporāle"
  ],
  "lasting but for a time": [
    "temporālis, temporāle"
  ],
  "temporary": [
    "Temporalis",
    "temporālis, temporāle"
  ],
  "temporal": [
    "temporālis, temporāle"
  ],
  "curse": [
    "Curse",
    "Maledictum",
    "exsēcrātiō, exsēcrātiōnis",
    "maledictiō, maledictiōnis"
  ],
  "evil speech": [
    "maledictiō, maledictiōnis"
  ],
  "univocal": [
    "ūnivocus, ūnivoca, ūnivocum"
  ],
  "unambiguous": [
    "ūnivocus, ūnivoca, ūnivocum"
  ],
  "internally": [
    "intrīnsecus"
  ],
  "be very able or more able": [
    "praevaleō, praevalēre, praevaluī, praevalitum"
  ],
  "prevail": [
    "polleō, pollēre",
    "praevaleō, praevalēre, praevaluī, praevalitum"
  ],
  "formal": [
    "fōrmālis, fōrmāle"
  ],
  "vivify": [
    "vīvificō, vīvificāre, vīvificāvī, vīvificātum"
  ],
  "eventide": [
    "vespera, vesperae"
  ],
  "notional": [
    "nōtionālis, nōtionāle"
  ],
  "conceptional": [
    "nōtionālis, nōtionāle"
  ],
  "participation": [
    "participātiō, participātiōnis"
  ],
  "one-year-old": [
    "anniculus, annicula, anniculum"
  ],
  "act justly": [
    "iūstificō, iūstificāre, iūstificāvī, iūstificātum"
  ],
  "revelation": [
    "revēlātiō, revēlātiōnis"
  ],
  "exultation": [
    "exultatiō, exultatiōnis"
  ],
  "corruptible": [
    "corruptibilis, corruptibile"
  ],
  "perishable": [
    "corruptibilis, corruptibile"
  ],
  "demon": [
    "daemonium, daemoniī"
  ],
  "the tribe or century to which it fell by lot to vote first in the comitia": [
    "praerogātīva, praerogātīvae"
  ],
  "singer": [
    "cantor, cantōris"
  ],
  "contrition": [
    "contrītiō, contrītiōnis"
  ],
  "metaphoric": [
    "metaphoricus, metaphorica, metaphoricum"
  ],
  "metaphorical": [
    "metaphoricus, metaphorica, metaphoricum"
  ],
  "presuppose": [
    "praesuppōnō, praesuppōnere, praesupposuī, praesuppositum"
  ],
  "a denying": [
    "negātiō, negātiōnis"
  ],
  "denial": [
    "negātiō, negātiōnis"
  ],
  "negation": [
    "negātiō, negātiōnis"
  ],
  "plurality": [
    "plūrālitās, plūrālitātis"
  ],
  "fullness": [
    "saturitas, saturitātis",
    "plēnitūdō, plēnitūdinis"
  ],
  "plenitude": [
    "plēnitūdō, plēnitūdinis"
  ],
  "come upon": [
    "inveniō, invenīre, invēnī, inventum",
    "superveniō, supervenīre, supervēnī, superventum"
  ],
  "habitation": [
    "stabulum, stabulī",
    "domicilium, domiciliī",
    "habitāculum, habitāculī"
  ],
  "proclamation": [
    "prōmulgātiō, prōmulgātiōnis",
    "prōnuntiātiō, prōnuntiātiōnis"
  ],
  "utterance": [
    "ēlocutiō, ēlocutiōnis"
  ],
  "expression": [
    "ēlocutiō, ēlocutiōnis"
  ],
  "carved": [
    "sculptilis, sculptile"
  ],
  "engraved": [
    "sculptilis, sculptile"
  ],
  "sculpted": [
    "sculptilis, sculptile"
  ],
  "of or belonging to the church": [
    "ecclēsiasticus, ecclēsiastica, ecclēsiasticum"
  ],
  "ecclesiastical": [
    "ecclēsiasticus, ecclēsiastica, ecclēsiasticum"
  ],
  "justification": [
    "iustificātiō, iustificātiōnis"
  ],
  "speak or allege against": [
    "contrādīcō, contrādīcere, contrādīxī, contrādictum"
  ],
  "contradict": [
    "īnfitior, īnfitiārī, īnfitiātus sum",
    "redarguō, redarguere, redarguī, redargūtum",
    "contrādīcō, contrādīcere, contrādīxī, contrādictum"
  ],
  "gainsay": [
    "contrādīcō, contrādīcere, contrādīxī, contrādictum"
  ],
  "sprout": [
    "germen, germinis",
    "surculus, surculī",
    "germinō, germināre, germināvī, germinātum"
  ],
  "loin": [
    "lumbus, lumbī"
  ],
  "blaspheme": [
    "blasphēmō, blasphēmāre"
  ],
  "revile": [
    "blasphēmō, blasphēmāre"
  ],
  "bitterness": [
    "amāritūdō, amāritūdinis"
  ],
  "park": [
    "paradīsus, paradīsī"
  ],
  "orchard": [
    "paradīsus, paradīsī"
  ],
  "speculative": [
    "speculātīvus, speculātīva, speculātīvum",
    "contemplātīvus, contemplātīva, contemplātīvum"
  ],
  "predestine or predetermine": [
    "praedestinō, praedestināre, praedestināvī, praedestinātum"
  ],
  "baptize": [
    "baptīzō, baptīzāre, baptīzāvī, baptīzātum"
  ],
  "emigration": [
    "trānsmigrātiō, trānsmigrātiōnis"
  ],
  "till or cultivate again": [
    "recolō, recolere, recoluī, recultum"
  ],
  "ambiguity": [
    "ambiguitās, ambiguitātis"
  ],
  "uncertainty": [
    "ambiguitās, ambiguitātis"
  ],
  "equivocalness": [
    "ambiguitās, ambiguitātis"
  ],
  "truthfully": [
    "vērāciter"
  ],
  "understandable": [
    "Intellegibilis",
    "intellegibilis, intellegibile"
  ],
  "intelligible": [
    "intellegibilis, intellegibile"
  ],
  "originally": [
    "prīmitus"
  ],
  "millet": [
    "milium, miliī"
  ],
  "a being near": [
    "īnstantia, īnstantiae"
  ],
  "presence": [
    "īnstantia, īnstantiae"
  ],
  "rhodium": [
    "rhodium, rhodiī"
  ],
  "experiment": [
    "Experimentum",
    "experientia, experientiae",
    "experīmentum, experīmentī"
  ],
  "search": [
    "Quaere",
    "Investiga",
    "inquīsītiō, inquīsītiōnis",
    "rīmor, rīmārī, rīmātus sum"
  ],
  "of a woman": [
    "muliebris, muliebre"
  ],
  "a trial": [
    "experientia, experientiae"
  ],
  "counselling": [
    "suāsiō, suāsiōnis",
    "cōnsiliārius, cōnsiliāria, cōnsiliārium"
  ],
  "advising": [
    "cōnsiliārius, cōnsiliāria, cōnsiliārium"
  ],
  "wooer": [
    "procus, procī"
  ],
  "suitor": [
    "procus, procī"
  ],
  "axiom": [
    "axiōma, axiōmatis"
  ],
  "nonetheless": [
    "nihilōminus"
  ],
  "notwithstanding": [
    "vērumtamen",
    "vēruntamen",
    "nihilōminus"
  ],
  "a making": [
    "factūra, factūrae",
    "conditiō, conditiōnis"
  ],
  "creating": [
    "creātiō, creātiōnis",
    "conditiō, conditiōnis"
  ],
  "actually": [
    "rēapse",
    "rēvērā"
  ],
  "watching": [
    "excubia, excubiae",
    "observātiō, observātiōnis"
  ],
  "observing": [
    "observātiō, observātiōnis"
  ],
  "magnet": [
    "magnēs, magnētis"
  ],
  "lodestone": [
    "magnēs, magnētis"
  ],
  "metal": [
    "metallum, metallī"
  ],
  "a certain amount": [
    "nonnihil"
  ],
  "contemplation": [
    "contemplātiō, contemplātiōnis"
  ],
  "the state": [
    "rēspūblica"
  ],
  "seek after": [
    "exsequor, exsequī, exsecūtus sum",
    "inquīrō, inquīrere, inquīsīvī, inquīsītum"
  ],
  "search for": [
    "inquīrō, inquīrere, inquīsīvī, inquīsītum"
  ],
  "inquire into something": [
    "inquīrō, inquīrere, inquīsīvī, inquīsītum"
  ],
  "pry": [
    "inquīrō, inquīrere, inquīsīvī, inquīsītum"
  ],
  "examine": [
    "Examina",
    "scīscitor, scīscitārī, scīscitātus sum",
    "inquīrō, inquīrere, inquīsīvī, inquīsītum",
    "cōnsīderō, cōnsīderāre, cōnsīderāvī, cōnsīderātum"
  ],
  "from africa": [
    "āfer, āfra, āfrum"
  ],
  "spread abroad": [
    "dīdō, dīdere, dīdidī, dīditum"
  ],
  "disseminate": [
    "dīdō, dīdere, dīdidī, dīditum"
  ],
  "a measuring": [
    "mēnsūra, mēnsūrae"
  ],
  "touching": [
    "contāgium, contāgiī",
    "tractātus, tractātūs"
  ],
  "handling": [
    "tractātus, tractātūs",
    "tractātiō, tractātiōnis"
  ],
  "working": [
    "tractātus, tractātūs",
    "operārius, operāria, operārium"
  ],
  "designation": [
    "dēmōnstrātiō, dēmōnstrātiōnis"
  ],
  "identification": [
    "dēmōnstrātiō, dēmōnstrātiōnis"
  ],
  "a plant": [
    "cicūta, cicūtae",
    "planta, plantae"
  ],
  "introduction": [
    "prooemium, prooemiī",
    "inductiō, inductiōnis",
    "praescriptiō, praescriptiōnis"
  ],
  "admission": [
    "inductiō, inductiōnis"
  ],
  "generic": [
    "generālis, generāle"
  ],
  "immediately after": [
    "subinde"
  ],
  "any round object": [
    "globus, globī"
  ],
  "a sphere": [
    "globus, globī"
  ],
  "a globe": [
    "globus, globī"
  ],
  "thoughtful": [
    "cūriōsus, cūriōsa, cūriōsum"
  ],
  "devoted": [
    "pius, pia, pium",
    "cūriōsus, cūriōsa, cūriōsum"
  ],
  "nourishing": [
    "almus, alma, almum"
  ],
  "portion": [
    "portiō, portiōnis"
  ],
  "a knot": [
    "nōdus, nōdī"
  ],
  "namely": [
    "utpote"
  ],
  "operation": [
    "operātiō, operātiōnis"
  ],
  "the act of loosening or unfastening someone or something": [
    "solūtiō, solūtiōnis"
  ],
  "dissolution": [
    "solūtiō, solūtiōnis",
    "dissolūtiō, dissolūtiōnis"
  ],
  "tension": [
    "tēnsūra, tēnsūrae",
    "intentiō, intentiōnis",
    "contentiō, contentiōnis"
  ],
  "renew war": [
    "rebellō, rebellāre, rebellāvi, rebellātūm"
  ],
  "wage war again": [
    "rebellō, rebellāre, rebellāvi, rebellātūm"
  ],
  "the ability to see something in advance": [
    "prōvidentia, prōvidentiae"
  ],
  "foresight": [
    "prōvidentia, prōvidentiae"
  ],
  "foreknowledge": [
    "prōvidentia, prōvidentiae",
    "praescientia, praescientiae"
  ],
  "full of courage": [
    "animōsus, animōsa, animōsum"
  ],
  "spirited": [
    "animōsus, animōsa, animōsum"
  ],
  "undaunted": [
    "animōsus, animōsa, animōsum"
  ],
  "commonly known": [
    "vulgāris, vulgāre"
  ],
  "describe": [
    "intimō, intimāre, intimāvī, intimātum",
    "dēfōrmō, dēfōrmāre, dēfōrmāvī, dēfōrmātum"
  ],
  "steering": [
    "regimen, regiminis",
    "gubernātiō, gubernātiōnis"
  ],
  "prow": [
    "prōra, prōrae"
  ],
  "hang up or suspend": [
    "suspendō, suspendere, suspendī, suspēnsum"
  ],
  "movable": [
    "mōbilis, mōbile"
  ],
  "loose": [
    "mōbilis, mōbile",
    "dissolutus, dissoluta, dissolutum"
  ],
  "limestone": [
    "calx, calcis"
  ],
  "become numb or stupefied": [
    "obstipēscō, obstipēscere, obstipuī"
  ],
  "bishop": [
    "pāpa, pāpae"
  ],
  "pop": [
    "pāpa, pāpae"
  ],
  "east wind": [
    "eurus, eurī"
  ],
  "to be strong or powerful": [
    "polleō, pollēre"
  ],
  "to prevail": [
    "polleō, pollēre"
  ],
  "emptiness": [
    "vānitās, vānitātis"
  ],
  "and or but not yet": [
    "necdum"
  ],
  "omnipotent": [
    "omnipotēns, omnipotentis"
  ],
  "all-powerful": [
    "omnipotēns, omnipotentis"
  ],
  "almighty": [
    "omnipotēns, omnipotentis"
  ],
  "a sharp point": [
    "mucrō, mucrōnis"
  ],
  "especially the point of a sword": [
    "mucrō, mucrōnis"
  ],
  "elm": [
    "ulmus, ulmī",
    "ulmeus, ulmea, ulmeum"
  ],
  "augment": [
    "adaugeō, adaugēre, adauxī, adauctum",
    "ampliō, ampliāre, ampliāvī, ampliātum",
    "multiplicō, multiplicāre, multiplicāvī, multiplicātum"
  ],
  "civil": [
    "polīticus, polītica, polīticum"
  ],
  "of the state": [
    "polīticus, polītica, polīticum"
  ],
  "relating to civil polity": [
    "polīticus, polītica, polīticum"
  ],
  "raw": [
    "crūdus, crūda, crūdum"
  ],
  "bleeding": [
    "Bleeding",
    "Desanguinatio",
    "crūdus, crūda, crūdum"
  ],
  "a cave": [
    "spēlunca, spēluncae"
  ],
  "clinging": [
    "tenāx, tenācis"
  ],
  "a basin or bowl for water or for mixing": [
    "crātēr, crātēris"
  ],
  "long since": [
    "iamdūdum",
    "iampridem"
  ],
  "long before": [
    "iamdūdum"
  ],
  "in nowise": [
    "neutiquam"
  ],
  "not altogether": [
    "neutiquam"
  ],
  "not exactly": [
    "neutiquam",
    "Non exacte"
  ],
  "a point connecting various parts of the body": [
    "articulus, articulī"
  ],
  "joint": [
    "artus, arta, artum",
    "articulus, articulī"
  ],
  "knuckle": [
    "articulus, articulī"
  ],
  "maimed": [
    "truncus, trunca, truncum"
  ],
  "mangled": [
    "truncus, trunca, truncum"
  ],
  "mutilated": [
    "truncus, trunca, truncum"
  ],
  "opening": [
    "hiātus, hiātūs",
    "forāmen, forāminis"
  ],
  "aperture": [
    "hiātus, hiātūs",
    "forāmen, forāminis"
  ],
  "hole": [
    "lacūna, lacūnae",
    "forāmen, forāminis"
  ],
  "touchable": [
    "tangibilis, tangibile"
  ],
  "tangible": [
    "tangibilis, tangibile"
  ],
  "able to be touched or sensed": [
    "tangibilis, tangibile"
  ],
  "essence": [
    "Essence",
    "essentia, essentiae",
    "substantia, substantiae"
  ],
  "contents": [
    "substantia, substantiae"
  ],
  "a generation": [
    "generātiō, generātiōnis"
  ],
  "sketch": [
    "īnfōrmātiō, īnfōrmātiōnis"
  ],
  "idea": [
    "phantasia, phantasiae",
    "conceptiō, conceptiōnis",
    "īnfōrmātiō, īnfōrmātiōnis"
  ],
  "conception": [
    "conceptiō, conceptiōnis",
    "īnfōrmātiō, īnfōrmātiōnis"
  ],
  "a trophy": [
    "tropaeum, tropaeī"
  ],
  "victory memorial": [
    "tropaeum, tropaeī"
  ],
  "unspeakable": [
    "īnfandus, īnfanda, īnfandum"
  ],
  "unutterable": [
    "īnfandus, īnfanda, īnfandum"
  ],
  "unnatural": [
    "īnfandus, īnfanda, īnfandum",
    "prōdigiōsus, prōdigiōsa, prōdigiōsum"
  ],
  "shocking": [
    "īnfandus, īnfanda, īnfandum"
  ],
  "count or calculate again": [
    "reputō, reputāre, reputāvī, reputātum"
  ],
  "alluring": [
    "dēlicātus, dēlicāta, dēlicātum"
  ],
  "voluptuous": [
    "dēlicātus, dēlicāta, dēlicātum"
  ],
  "barren": [
    "sterilis, sterile"
  ],
  "sterile": [
    "sterilis, sterile"
  ],
  "simpleton": [
    "bārō, bārōnis"
  ],
  "dunce": [
    "bārō, bārōnis"
  ],
  "an overseer": [
    "episcopus, episcopī"
  ],
  "supervisor": [
    "episcopus, episcopī"
  ],
  "bishop in a christian church who governs a diocese": [
    "episcopus, episcopī"
  ],
  "unoccupied": [
    "vastus, vasta, vastum",
    "vacō, vacāre, vacāvī, vacātum"
  ],
  "a race": [
    "curriculum, curriculī"
  ],
  "a broad": [
    "patera, paterae"
  ],
  "flat dish or saucer": [
    "patera, paterae"
  ],
  "used especially for libations": [
    "patera, paterae"
  ],
  "mix in": [
    "immisceō, immiscēre, immiscuī, immixtum"
  ],
  "blend": [
    "admisceō, admiscēre, admiscuī, admixtum",
    "immisceō, immiscēre, immiscuī, immixtum"
  ],
  "intermix": [
    "immisceō, immiscēre, immiscuī, immixtum"
  ],
  "a row": [
    "seriēs, seriēī"
  ],
  "propagate": [
    "prōpāgō, prōpāgāre, prōpāgāvī, prōpāgātum"
  ],
  "tyranny": [
    "tyrannis, tyrannidis"
  ],
  "arbitrary or despotic rule": [
    "tyrannis, tyrannidis"
  ],
  "fever": [
    "febris, febris"
  ],
  "fragile": [
    "fragilis, fragile"
  ],
  "brittle": [
    "fragilis, fragile"
  ],
  "easily broken": [
    "fragilis, fragile"
  ],
  "created": [
    "Facti",
    "nātīvus, nātīva, nātīvum"
  ],
  "made": [
    "fīō, fierī, factus sum",
    "nātīvus, nātīva, nātīvum",
    "fiō, fierī, factum esse (fiat, fiunt)"
  ],
  "a breaking": [
    "fragor, fragōris"
  ],
  "shattering": [
    "fragor, fragōris"
  ],
  "a she-goat or kid": [
    "capella, capellae"
  ],
  "failure": [
    "Defectio",
    "dēfectus, dēfectūs"
  ],
  "rise or stand up": [
    "exsurgō, exsurgere, exsurrexī, exsurrectum",
    "cōnsurgō, cōnsurgere, cōnsurrexī, cōnsurrectum"
  ],
  "pour over or through": [
    "perfundō, perfundere, perfūdī, perfūsum"
  ],
  "perfuse": [
    "perfundō, perfundere, perfūdī, perfūsum"
  ],
  "a die": [
    "ālea, āleae"
  ],
  "timidity": [
    "timiditās, timiditātis",
    "reverentia, reverentiae"
  ],
  "shyness": [
    "reverentia, reverentiae"
  ],
  "logical consequence": [
    "cōnsequentia, cōnsequentiae"
  ],
  "of oak": [
    "rōbustus, rōbusta, rōbustum"
  ],
  "oaken": [
    "rōbustus, rōbusta, rōbustum"
  ],
  "florid or figurative speech": [
    "schēmatismos, schēmatismī"
  ],
  "fiber": [
    "fibra, fibrae"
  ],
  "filament": [
    "fibra, fibrae"
  ],
  "a bubble": [
    "bulla, bullae"
  ],
  "denied": [
    "negātīvus, negātīva, negātīvum"
  ],
  "negative": [
    "negātīvus, negātīva, negātīvum"
  ],
  "that which denies": [
    "negātīvus, negātīva, negātīvum"
  ],
  "darken": [
    "obscūrō, obscūrāre, obscūrāvī, obscūrātum"
  ],
  "cast or draw lots": [
    "sortior, sortīrī, sortītus sum"
  ],
  "not divided": [
    "indīviduus, indīvidua, indīviduum"
  ],
  "slippery": [
    "lūbricus, lūbrica, lūbricum"
  ],
  "slimy": [
    "līmōsus, līmōsa, līmōsum",
    "lūbricus, lūbrica, lūbricum"
  ],
  "church": [
    "ecclēsia, ecclēsiae"
  ],
  "flow out": [
    "ēmānō, ēmānāre, ēmānāvī, ēmānātum"
  ],
  "arise or emanate": [
    "ēmānō, ēmānāre, ēmānāvī, ēmānātum"
  ],
  "berry": [
    "bāca, bācae"
  ],
  "security": [
    "sēcūritās, sēcūritātis"
  ],
  "visible": [
    "ēvidēns, ēvidentis",
    "vīsibilis, vīsibile",
    "spectābilis, spectābile",
    "conspicuus, conspicua, conspicuum",
    "compāreō, compārēre, compāruī, compāritum"
  ],
  "holm oak": [
    "īlex, īlicis"
  ],
  "depths": [
    "profundum, profundī"
  ],
  "continuation": [
    "continuitās, continuitātis",
    "continuātiō, continuātiōnis"
  ],
  "draw together or up": [
    "perstringō, perstringere, perstrinxī, perstrictum"
  ],
  "expand": [
    "laxō, laxāre, laxāvī, laxātum"
  ],
  "hail": [
    "grandō, grandinis"
  ],
  "hailstorm": [
    "grandō, grandinis"
  ],
  "declination": [
    "dēclīnātiō, dēclīnātiōnis"
  ],
  "pull or drag to or towards with force": [
    "attrahō, attrahere, attraxī, attractum"
  ],
  "earnestly": [
    "certātim",
    "ēnīxē, ēnīxius, ēnīxissimē",
    "adamō, adamāre, adamāvī, adamātum"
  ],
  "eagerly": [
    "certātim",
    "impensē, impensius, impensissimē",
    "ārdenter, ārdentius, ārdentissimē",
    "lubenter, lubentius, lubentissimē"
  ],
  "of or pertaining to the air": [
    "āerius, āeria, āerium"
  ],
  "aerial": [
    "āerius, āeria, āerium"
  ],
  "cast or throw out": [
    "ēruō, ēruere, ēruī, ērutum"
  ],
  "of or pertaining to mathematics": [
    "mathēmaticus, mathēmatica, mathēmaticum"
  ],
  "mathematical": [
    "mathēmaticus, mathēmatica, mathēmaticum"
  ],
  "mathematic": [
    "mathēmaticus, mathēmatica, mathēmaticum"
  ],
  "alternation": [
    "vicissitūdō, vicissitūdinis"
  ],
  "vicissitude": [
    "vicissitūdō, vicissitūdinis"
  ],
  "a conservation": [
    "cōnservātiō, cōnservātiōnis"
  ],
  "make spotted": [
    "maculō, maculāre, maculāvī, maculātum"
  ],
  "graze": [
    "mulceō, mulcēre, mulsī, mulsum"
  ],
  "touch or move lightly or gently": [
    "mulceō, mulcēre, mulsī, mulsum"
  ],
  "draining or leading off or forth": [
    "dēductiō, dēductiōnis"
  ],
  "a nuptial song": [
    "hymenaeus, hymenaeī"
  ],
  "drug": [
    "medicāmen, medicāminis",
    "medicāmentum, medicāmentī"
  ],
  "be serviceable": [
    "īnserviō, īnservīre, īnservīvī, īnservitum"
  ],
  "finishing": [
    "expolītiō, expolītiōnis",
    "perfectiō, perfectiōnis"
  ],
  "excessive": [
    "profūsus, profūsa, profūsum",
    "immodicus, immodica, immodicum"
  ],
  "beyond measure": [
    "immodicus, immodica, immodicum"
  ],
  "thorn": [
    "spīna, spīnae",
    "aculeus, aculeī"
  ],
  "spine": [
    "spīna, spīnae",
    "aculeus, aculeī"
  ],
  "prickle": [
    "spīna, spīnae"
  ],
  "luxuriant": [
    "luxuriōsus, luxuriōsa, luxuriōsum"
  ],
  "exuberant": [
    "luxuriōsus, luxuriōsa, luxuriōsum"
  ],
  "shake violently": [
    "concutiō, concutere, concussī, concussum"
  ],
  "degenerate": [
    "dēgenerō, dēgenerāre, dēgenerāvī, dēgenerātum"
  ],
  "deteriorate": [
    "dēgenerō, dēgenerāre, dēgenerāvī, dēgenerātum"
  ],
  "furrow": [
    "sulcus, sulcī",
    "sulcō, sulcāre, sulcāvī, sulcātum"
  ],
  "large earthenware jar": [
    "sēria, sēriae"
  ],
  "put away": [
    "dēmoveō, dēmovēre, dēmōvī, dēmōtum",
    "recondō, recondere, recondidī, reconditum"
  ],
  "that is thrown": [
    "iaculus, iacula, iaculum"
  ],
  "fashion": [
    "effingō, effingere, effinxī, effictum",
    "figūrō, figūrāre, figūrāvī, figūrātum",
    "cōnfingō, cōnfingere, cōnfinxī, cōnfictum",
    "dēfōrmō, dēfōrmāre, dēfōrmāvī, dēfōrmātum",
    "fabricō, fabricāre, fabricāvī, fabricātum",
    "īnfōrmō, īnfōrmāre, īnfōrmāvī, īnfōrmātum"
  ],
  "forge": [
    "Fabrica",
    "camīnus, camīnī",
    "fabricō, fabricāre, fabricāvī, fabricātum"
  ],
  "beneath": [
    "subter",
    "subtus"
  ],
  "underneath": [
    "subter",
    "subtus"
  ],
  "presidency": [
    "praefectūra, praefectūrae"
  ],
  "superintendence": [
    "praefectūra, praefectūrae"
  ],
  "prefecture": [
    "praefectūra, praefectūrae"
  ],
  "pipe": [
    "canālis, canālis",
    "fistula, fistulae"
  ],
  "tube": [
    "fistula, fistulae"
  ],
  "especially a water pipe": [
    "fistula, fistulae"
  ],
  "sound or ring again": [
    "resonō, resonāre, resonāvī, resonātum"
  ],
  "resound": [
    "īnsonō, īnsonāre, īnsonuī",
    "cōnsonō, cōnsonāre, cōnsonuī",
    "sonō, sonāre, sonuī, sonitum",
    "resonō, resonāre, resonāvī, resonātum",
    "personō, personāre, personuī, personātum",
    "obstrepō, obstrepere, obstrepuī, obstrepitum"
  ],
  "reecho": [
    "cōnsonō, cōnsonāre, cōnsonuī",
    "resonō, resonāre, resonāvī, resonātum"
  ],
  "call repeatedly": [
    "resonō, resonāre, resonāvī, resonātum"
  ],
  "that makes war anew": [
    "rebellis, rebelle"
  ],
  "waging war again": [
    "rebellis, rebelle"
  ],
  "insurgent": [
    "rebellis, rebelle"
  ],
  "rebellious": [
    "rebellis, rebelle"
  ],
  "fall back": [
    "recidō, recidere, reccidī, recāsum"
  ],
  "return ": [
    "recidō, recidere, reccidī, recāsum"
  ],
  "british": [
    "britannus, britanna, britannum"
  ],
  "lengthen": [
    "prōlātō, prōlātāre, prōlātāvī, prōlātātum"
  ],
  "enlarge": [
    "ampliō, ampliāre, ampliāvī, ampliātum",
    "prōlātō, prōlātāre, prōlātāvī, prōlātātum",
    "amplificō, amplificāre, amplificāvī, amplificātum"
  ],
  "of or pertaining to a general": [
    "imperātōrius, imperātōria, imperātōrium"
  ],
  "embellish": [
    "decorō, decorāre, decorāvī, decorātum",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus"
  ],
  "beautify": [
    "decorō, decorāre, decorāvī, decorātum"
  ],
  "a roman unit of measure": [
    "lībra, lībrae"
  ],
  "equal to twelve ounces": [
    "lībra, lībrae"
  ],
  "a pound": [
    "lībra, lībrae"
  ],
  "blow or breathe back": [
    "respīrō, respīrāre, respīrāvī, respīrātum"
  ],
  "breathe out": [
    "efflō, efflāre, efflāvī, efflātum",
    "exhālō, exhālāre, exhālāvī, exhālātum",
    "respīrō, respīrāre, respīrāvī, respīrātum"
  ],
  "exhale": [
    "efflō, efflāre, efflāvī, efflātum",
    "exhālō, exhālāre, exhālāvī, exhālātum",
    "exspīrō, exspīrāre, exspīrāvī, exspīrātum",
    "respīrō, respīrāre, respīrāvī, respīrātum"
  ],
  "fluidity": [
    "liquor, liquōris"
  ],
  "liquidity": [
    "liquor, liquōris"
  ],
  "a palace": [
    "palātium, palātiī"
  ],
  "large residence": [
    "palātium, palātiī"
  ],
  "assail or attack": [
    "ingruō, ingruere, ingruī",
    "incessō, incessere, incessīvī, incessuī, incessī, incessum"
  ],
  "rend or tear apart": [
    "dīvellō, dīvellere, dīvellī, dīvulsum"
  ],
  "disgust": [
    "fastīdium, fastīdiī"
  ],
  "a law or bill concerning a specific individual": [
    "prīvilēgium, prīvilēgiī"
  ],
  "libyan": [
    "libycus, libyca, libycum"
  ],
  "diffuse": [
    "diffundō, diffundere, diffūdī, diffūsum"
  ],
  "development": [
    "incrēmentum, incrēmentī"
  ],
  "augmentation": [
    "augmentum, augmentī",
    "incrēmentum, incrēmentī"
  ],
  "one of four elements that composed the world": [
    "elementum, elementi"
  ],
  "seduction": [
    "corruptēla, corruptēlae"
  ],
  "corruption": [
    "tābes, tābis",
    "corruptēla, corruptēlae",
    "corruptiō, corruptiōnis",
    "dēprāvātiō, dēprāvātiōnis"
  ],
  "faithfully": [
    "fidēliter, fidēlius, fidēlissimē"
  ],
  "honestly": [
    "fidēliter, fidēlius, fidēlissimē"
  ],
  "the state of being deformed": [
    "dēfōrmitās, dēfōrmitātis"
  ],
  "disfigurement": [
    "dēfōrmitās, dēfōrmitātis"
  ],
  "a dissembling": [
    "dissimulātiō, dissimulātiōnis"
  ],
  "concealing": [
    "dissimulātiō, dissimulātiōnis"
  ],
  "disguising": [
    "dissimulātiō, dissimulātiōnis"
  ],
  "dissimulation": [
    "dissimulātiō, dissimulātiōnis"
  ],
  "succession": [
    "successiō, successiōnis"
  ],
  "talkative": [
    "loquāx, loquācis",
    "garrulus, garrula, garrulum"
  ],
  "malicious": [
    "malignus, maligna, malignum",
    "malitiōsus, malitiōsa, malitiōsum"
  ],
  "a cyclops": [
    "cyclōps, cyclōpis"
  ],
  "a one-eyed giant from greek and roman mythology": [
    "cyclōps, cyclōpis"
  ],
  "groin": [
    "inguen, inguinis"
  ],
  "swarm of bees": [
    "exāmen, exāminis"
  ],
  "ointment": [
    "unguentum, unguentī"
  ],
  "unguent": [
    "unguentum, unguentī"
  ],
  "distinct": [
    "distinctus, distincta, distinctum"
  ],
  "plant or set near something": [
    "asserō, asserere, assēvī, assitum"
  ],
  "unequal etc": [
    "inaequālis, inaequāle"
  ],
  "painting": [
    "pictūra, pictūrae"
  ],
  "the art of painting": [
    "pictūra, pictūrae"
  ],
  "engineer": [
    "mēchanicus, mēchanicī"
  ],
  "sparing": [
    "parcus, parca, parcum"
  ],
  "frugal": [
    "parcus, parca, parcum"
  ],
  "laborious": [
    "labōriōsus, labōriōsa, labōriōsum"
  ],
  "toilsome": [
    "labōriōsus, labōriōsa, labōriōsum"
  ],
  "demanding": [
    "labōriōsus, labōriōsa, labōriōsum"
  ],
  "the act of binding": [
    "nexus, nexūs"
  ],
  "tying or fastening together": [
    "nexus, nexūs"
  ],
  "flow or run down": [
    "dēfluō, dēfluere, dēfluxī, dēfluxum"
  ],
  "a taste": [
    "sapor, sapōris"
  ],
  "flavor": [
    "sapor, sapōris"
  ],
  "savor": [
    "sapor, sapōris"
  ],
  "politeness": [
    "cōmitās, cōmitātis"
  ],
  "uncover": [
    "dētegō, dētegere, dētēxī, dētēctum",
    "retegō, retegere, retēxī, retēctum"
  ],
  "take off": [
    "dētegō, dētegere, dētēxī, dētēctum"
  ],
  "quench": [
    "extinguō, extinguere, extīnxī, extīnctum"
  ],
  "extinguish": [
    "perimō, perimere, perēmī, peremptum",
    "extinguō, extinguere, extīnxī, extīnctum"
  ],
  "clod": [
    "glaeba, glaebae"
  ],
  "innocent": [
    "īnsōns, īnsontis"
  ],
  "severing": [
    "sēparātiō, sēparātiōnis"
  ],
  "association": [
    "complexiō, complexiōnis",
    "cōnsortiō, cōnsortiōnis",
    "congregātiō, congregātiōnis"
  ],
  "strike on or against": [
    "incutiō, incutere, incussī, incussum"
  ],
  "an instance of living in the city": [
    "urbānitās, urbānitātis"
  ],
  "city life": [
    "urbānitās, urbānitātis"
  ],
  "copiously": [
    "cōpiōsē, cōpiōsius, cōpiōsissimē"
  ],
  "willow": [
    "salix, salicis"
  ],
  "mould": [
    "īnfōrmō, īnfōrmāre, īnfōrmāvī, īnfōrmātum"
  ],
  "give shape to": [
    "īnfōrmō, īnfōrmāre, īnfōrmāvī, īnfōrmātum"
  ],
  "travel": [
    "Peregrinari",
    "peregrīnātiō, peregrīnātiōnis"
  ],
  "travel abroad": [
    "peregrīnātiō, peregrīnātiōnis",
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "flax": [
    "līnum, līnī"
  ],
  "stretch out or widen again": [
    "relaxō, relaxāre, relaxāvī, relaxātum"
  ],
  "abate": [
    "relaxō, relaxāre, relaxāvī, relaxātum"
  ],
  "awn": [
    "arista, aristae"
  ],
  "decorate or embellish": [
    "pingō, pingere, pinxī, pictum"
  ],
  "abusive": [
    "contumēliōsus, contumēliōsa, contumēliōsum"
  ],
  "insolent": [
    "procāx, procācis",
    "arrogāns, arrogantis",
    "contumāx, contumācis",
    "contumēliōsus, contumēliōsa, contumēliōsum"
  ],
  "young goat": [
    "haedus -ī",
    "haedus -ī (m)",
    "haedus, haedī"
  ],
  "kid": [
    "haedus -ī",
    "hedus, hedī",
    "haedus -ī (m)",
    "haedus, haedī"
  ],
  "unseen": [
    "invīsus, invīsa, invīsum",
    "invīsitātus, invīsitāta, invīsitātum"
  ],
  "pert": [
    "procāx, procācis",
    "petulāns, petulantis"
  ],
  "saucy": [
    "petulāns, petulantis"
  ],
  "impudent": [
    "procāx, procācis",
    "petulāns, petulantis",
    "impudīcus, impudīca, impudīcum"
  ],
  "wanton": [
    "procāx, procācis",
    "petulāns, petulantis",
    "lascīvus, lascīva, lascīvum"
  ],
  "skirmisher": [
    "vēles, vēlitis"
  ],
  "javelineer": [
    "vēles, vēlitis"
  ],
  "light-armed footsoldier": [
    "vēles, vēlitis"
  ],
  "a furnace": [
    "fornāx, fornācis"
  ],
  "oven": [
    "Furnus",
    "fornāx, fornācis"
  ],
  "kiln": [
    "fornāx, fornācis"
  ],
  "cut down": [
    "obtruncō, obtruncāre, obtruncāvī, obtruncātum"
  ],
  "impede or retard": [
    "retardō, retardāre, retardāvī, retardātum"
  ],
  "ribbon": [
    "vitta, vittae"
  ],
  "bring together again": [
    "reconciliō, reconciliāre, reconciliāvī, reconciliātum"
  ],
  "reunite": [
    "reconciliō, reconciliāre, reconciliāvī, reconciliātum"
  ],
  "reconcile": [
    "plācō, plācāre, plācāvī, plācātum",
    "reconciliō, reconciliāre, reconciliāvī, reconciliātum"
  ],
  "conciliate": [
    "reconciliō, reconciliāre, reconciliāvī, reconciliātum"
  ],
  "pressure": [
    "nīsus, nīsūs",
    "pressūra, pressūrae"
  ],
  "of or pertaining to hercules": [
    "herculeus, herculea, herculeum"
  ],
  "adroit": [
    "sollers, sollertis",
    "versūtus, versūta, versūtum"
  ],
  "dexterous": [
    "sollers, sollertis",
    "versūtus, versūta, versūtum"
  ],
  "versatile": [
    "versūtus, versūta, versūtum"
  ],
  "ivy": [
    "hedera, hederae"
  ],
  "and yet": [
    "necnon"
  ],
  "preserver": [
    "condītor, condītōris",
    "cōnservātor, cōnservātōris"
  ],
  "composer": [
    "condītor, condītōris"
  ],
  "creator": [
    "creātor, creātōris",
    "condītor, condītōris"
  ],
  "melt": [
    "tābeō, tābēre, tābuī"
  ],
  "melt down or away": [
    "tābeō, tābēre, tābuī"
  ],
  "waste or rot away": [
    "tābeō, tābēre, tābuī"
  ],
  "draw or press close together": [
    "artō, artāre, artāvī, artātum"
  ],
  "contract": [
    "Foedus",
    "foedus, foederis",
    "artō, artāre, artāvī, artātum",
    "astringō, astringere, astrīnxī, astrictum"
  ],
  "tighten": [
    "artō, artāre, artāvī, artātum",
    "astringō, astringere, astrīnxī, astrictum"
  ],
  "turn back or away": [
    "reflectō, reflectere, reflexī, reflexum"
  ],
  "payment": [
    "pensiō, pensiōnis",
    "impendium, impendiī"
  ],
  "willing": [
    "libēns, libentis",
    "volō, velle, voluī",
    "volō, velle, voluī, ----",
    "prōpensus, prōpensa, prōpensum"
  ],
  "unbind": [
    "resolvō, resolvere, resolvī, resolūtum"
  ],
  "a reader": [
    "lēctor, lēctōris"
  ],
  "narrow way": [
    "sēmita, sēmitae"
  ],
  "footpath": [
    "sēmita, sēmitae",
    "trāmes, trāmitis"
  ],
  "barrier": [
    "ōbex, ōbicis"
  ],
  "leanness": [
    "maciēs, maciēī"
  ],
  "meagerness": [
    "maciēs, maciēī"
  ],
  "inexperience": [
    "īnscītia, īnscītiae",
    "imperītia, imperītiae"
  ],
  "unskilfulness": [
    "īnscītia, īnscītiae"
  ],
  "apparently true": [
    "vērisimilis, vērisimile"
  ],
  "bend or turn downwards or away": [
    "dēflectō, dēflectere, dēflexī, dēflexum"
  ],
  "deflect": [
    "dēflectō, dēflectere, dēflexī, dēflexum",
    "dētorqueō, dētorquēre, dētorsī, dētortum",
    "retorqueō, retorquēre, retorsī, retortum"
  ],
  "crescent-shaped": [
    "crēscentus, crēscenta, crēscentum"
  ],
  "cleave or stick to": [
    "adhaereō, adhaerēre, adhaesī, adhaesum"
  ],
  "break off something": [
    "abrumpō, abrumpere, abrūpī, abruptum"
  ],
  "of or pertaining to a host or guest": [
    "hospitālis, hospitāle"
  ],
  "shaft of a spear or javelin": [
    "hastīle, hastīlis"
  ],
  "strenuously": [
    "ēnīxē, ēnīxius, ēnīxissimē"
  ],
  "assiduously": [
    "ēnīxē, ēnīxius, ēnīxissimē"
  ],
  "a pale color": [
    "pallor, pallōris"
  ],
  "paleness": [
    "pallor, pallōris"
  ],
  "wanness": [
    "pallor, pallōris"
  ],
  "pallor": [
    "pallor, pallōris"
  ],
  "stack": [
    "acervus, acervī"
  ],
  "sting": [
    "aculeus, aculeī",
    "pungō, pungere, pupugī, punctum"
  ],
  "a military servant": [
    "cālō, cālōnis"
  ],
  "press down or under": [
    "supprimō, supprimere, suppressī, suppressum"
  ],
  "fill again": [
    "repleō, replēre, replēvī, replētum"
  ],
  "refill": [
    "repleō, replēre, replēvī, replētum"
  ],
  "replenish": [
    "repleō, replēre, replēvī, replētum"
  ],
  "mystery": [
    "mystērium, mystēriī"
  ],
  "a bull calf": [
    "vitulus, vitulī"
  ],
  "the steering-oar": [
    "gubernāculum, gubernāculī"
  ],
  "helm": [
    "gubernāculum, gubernāculī"
  ],
  "rudder": [
    "gubernāculum, gubernāculī"
  ],
  "sparkling": [
    "glaucus, glauca, glaucum"
  ],
  "gleaming": [
    "glaucus, glauca, glaucum"
  ],
  "grayish": [
    "glaucus, glauca, glaucum"
  ],
  "just as great or large": [
    "tantusdem, tantadem, tantundem"
  ],
  "just as much or many": [
    "tantusdem, tantadem, tantundem"
  ],
  "just as extensive or imposing": [
    "tantusdem, tantadem, tantundem"
  ],
  "just as grand or important": [
    "tantusdem, tantadem, tantundem"
  ],
  "foam": [
    "spūma, spūmae",
    "spūmō, spūmāre, spūmāvī, spūmātum"
  ],
  "froth": [
    "spūma, spūmae",
    "spūmō, spūmāre, spūmāvī, spūmātum"
  ],
  "be covered in foam": [
    "spūmō, spūmāre, spūmāvī, spūmātum"
  ],
  "a kind of earthen vase used in sacrifices": [
    "cernos, cernī"
  ],
  "pass time or spend time": [
    "dēgō, dēgere, dēgī"
  ],
  "of or belonging to a number": [
    "numerius, numeria, numerium"
  ],
  "numeral": [
    "numerius, numeria, numerium"
  ],
  "a sanctuary dedicated to a deity": [
    "sacellum, sacellī"
  ],
  "usually open to the sky": [
    "sacellum, sacellī"
  ],
  "sediment": [
    "faex, faecis"
  ],
  "dregs": [
    "faex, faecis"
  ],
  "of the lower regions": [
    "īnfernus, īnferna, īnfernum"
  ],
  "praiser": [
    "laudātor, laudātōris"
  ],
  "eulogizer": [
    "laudātor, laudātōris"
  ],
  "panegyrist": [
    "laudātor, laudātōris"
  ],
  "immovable": [
    "immōbilis, immōbile",
    "immōtus, immōta, immōtum"
  ],
  "motionless": [
    "immōtus, immōta, immōtum"
  ],
  "universal": [
    "ūniversālis, ūniversāle"
  ],
  "girdle": [
    "zōna, zōnae",
    "cinctus, cinctūs"
  ],
  "belt": [
    "zōna, zōnae",
    "balteus, balteī",
    "cinctus, cinctūs",
    "cingulum, cingulī"
  ],
  "fume": [
    "fūmō, fūmāre, fūmāvī, fūmātum"
  ],
  "grind or crush to pieces": [
    "conterō, conterere, contrīvī, contrītum"
  ],
  "a painter": [
    "pictor, pictōris"
  ],
  "embroider": [
    "pictor, pictōris"
  ],
  "act as host": [
    "hospitō, hospitāre, hospitāvī, hospitātum"
  ],
  "entertain": [
    "invītō, invītāre, invītāvī, invītātum",
    "hospitō, hospitāre, hospitāvī, hospitātum",
    "oblectō, oblectāre, oblectāvī, oblectātum"
  ],
  "a lover ": [
    "amātor, amātōris"
  ],
  "suspicious": [
    "suspiciosus, suspiciosa, suspiciosum"
  ],
  "shaking": [
    "vexātiō, vexātiōnis",
    "iactātiō, iactātiōnis",
    "coruscus, corusca, coruscum"
  ],
  "tuft of hair": [
    "villus"
  ],
  "one of two": [
    "alteruter, alterutra, alterutrum"
  ],
  "one or the other": [
    "alteruter, alterutra, alterutrum"
  ],
  "accusation": [
    "crīmen, crīminis",
    "dēlātiō, dēlātiōnis",
    "crīminātiō, crīminātiōnis"
  ],
  "of or pertaining to mourning": [
    "lūgubris, lūgubre"
  ],
  "a bondsman": [
    "spōnsor, spōnsōris"
  ],
  "surety": [
    "spōnsor, spōnsōris"
  ],
  "adjournment": [
    "dīlātiō, dīlātiōnis"
  ],
  "postponement": [
    "dīlātiō, dīlātiōnis"
  ],
  "agreeing": [
    "concors, concordis"
  ],
  "of one mind": [
    "concors, concordis"
  ],
  "concordant": [
    "concors, concordis"
  ],
  "a debt slave": [
    "addictus, addictī"
  ],
  "a person who has been bound as a slave to his creditor": [
    "addictus, addictī"
  ],
  "measureless": [
    "immoderātus, immoderāta, immoderātum"
  ],
  "softly": [
    "molliter, mollius, mollissimē"
  ],
  "agreeably": [
    "molliter, mollius, mollissimē"
  ],
  "gather into a flock": [
    "congregō, congregāre, congregāvī, congregātum"
  ],
  "be wet or moist": [
    "madeō, madēre, maduī"
  ],
  "drip or flow ": [
    "madeō, madēre, maduī"
  ],
  "mediator": [
    "intercessor, intercessōris"
  ],
  "intercessor": [
    "dēprecātor, dēprecātōris",
    "intercessor, intercessōris"
  ],
  "daub over": [
    "oblinō, oblinere, oblēvī, oblitum"
  ],
  "besmear": [
    "oblinō, oblinere, oblēvī, oblitum",
    "collinō, collinere, collēvī, collitum"
  ],
  "want of art or skill": [
    "inertia, inertiae"
  ],
  "unskillfulness": [
    "inertia, inertiae"
  ],
  "advertise": [
    "prōscrībō, prōscrībere, prōscrīpsī, prōscrīptum"
  ],
  "high stature": [
    "celsitūdō, celsitūdinis"
  ],
  "tallness": [
    "celsitūdō, celsitūdinis"
  ],
  "stepmother": [
    "noverca -ae f",
    "noverca, novercae"
  ],
  "make up the numbers": [
    "suppleō, supplēre, supplēvī, supplētum"
  ],
  "generate": [
    "prōcreō, prōcreāre, prōcreāvī, prōcreātum"
  ],
  "a pedestal": [
    "basis, basis"
  ],
  "basis": [
    "basis, basis",
    "crepīdō, crepīdinis"
  ],
  "fence in": [
    "saepiō, saepīre, saepsī, saeptum"
  ],
  "smearing": [
    "litūra, litūrae"
  ],
  "anointing": [
    "litūra, litūrae"
  ],
  "frost": [
    "Glacies",
    "Congelatio",
    "gelū, gelūs"
  ],
  "of or pertaining to an old woman": [
    "anīlis, anīle"
  ],
  "the office of a public crier or auctioneer": [
    "praecōnium, praecōniī"
  ],
  "perjured": [
    "periūrus, periūra, periūrum"
  ],
  "fail in duty": [
    "dēlinquō, dēlinquere, dēliquī, dēlictum"
  ],
  "originate": [
    "initiō, initiāre, initiāvī, initiātum",
    "prōveniō, prōvenīre, prōvēnī, prōventum"
  ],
  "abbreviate": [
    "breviō, breviāre, breviāvī, breviātum"
  ],
  "abridge ": [
    "breviō, breviāre, breviāvī, breviātum"
  ],
  "stand over or upon": [
    "superstō, superstāre, superstetī, superstātum"
  ],
  "indulgence": [
    "venia, veniae"
  ],
  "howling": [
    "ululātus, ululātūs"
  ],
  "wailing": [
    "lāmentum, lāmentī",
    "plōrātus, plōrātūs",
    "ululātus, ululātūs",
    "lāmentātiō, lāmentātiōnis"
  ],
  "if anywhere": [
    "sīcubi"
  ],
  "changeable": [
    "mūtābilis, mūtābile",
    "incōnstāns, incōnstantis"
  ],
  "mutable": [
    "mūtābilis, mūtābile"
  ],
  "inconstant": [
    "mūtābilis, mūtābile",
    "incōnstāns, incōnstantis"
  ],
  "cessation of public business": [
    "iūstitium, iūstitiī"
  ],
  "pull or drag asunder": [
    "distrahō, distrahere, distraxī, distractum"
  ],
  "tear in pieces": [
    "distrahō, distrahere, distraxī, distractum"
  ],
  "separate forcibly": [
    "distrahō, distrahere, distraxī, distractum"
  ],
  "is it possible": [
    "numquid"
  ],
  "sending": [
    "missiō, missiōnis"
  ],
  "mission": [
    "missiō, missiōnis"
  ],
  "violent snatching": [
    "raptus, raptūs"
  ],
  "prickly": [
    "hīrsūtus, hīrsūta, hīrsūtum"
  ],
  "abstinence": [
    "abstinentia, abstinentiae"
  ],
  "self-restraint": [
    "abstinentia, abstinentiae"
  ],
  "manufacture": [
    "factūra, factūrae"
  ],
  "be disgusted or offended": [
    "taedeō, taedēre, taeduī, taesum"
  ],
  "frugality": [
    "parsimōnia, parsimōniae",
    "frūgālitās, frūgālitātis"
  ],
  "thrift": [
    "parsimōnia, parsimōniae",
    "frūgālitās, frūgālitātis"
  ],
  "parsimony": [
    "parsimōnia, parsimōniae"
  ],
  "labouring": [
    "operārius, operāria, operārium"
  ],
  "appear suddenly": [
    "ēmicō, ēmicāre, ēmicuī, ēmicātum"
  ],
  "a keeper": [
    "cōnservātor, cōnservātōris"
  ],
  "strike down": [
    "discutiō, discutere, discussī, discussum"
  ],
  "tremble or shudder greatly": [
    "perhorrēscō, perhorrēscere, perhorruī"
  ],
  "bent or turned upward": [
    "licinus, licina, licinum"
  ],
  "throw or drive asunder": [
    "disiciō, disicere, disiēcī, disiectum"
  ],
  "dishevel": [
    "disiciō, disicere, disiēcī, disiectum"
  ],
  "puncture": [
    "pungō, pungere, pupugī, punctum"
  ],
  "plausibly": [
    "probābiliter, probābilius, probābilissimē"
  ],
  "pirate chief": [
    "archipīrāta, archipīrātae"
  ],
  "pontic": [
    "ponticus, pontica, ponticum"
  ],
  "squeezing": [
    "compressiō, compressiōnis"
  ],
  "compression": [
    "compressiō, compressiōnis"
  ],
  "crack": [
    "rīma -ae",
    "rīma, rīmae",
    "rīma -ae (f)"
  ],
  "fissure": [
    "rīma -ae",
    "rīma, rīmae",
    "rīma -ae (f)"
  ],
  "follow to the end": [
    "exsequor, exsequī, exsecūtus sum"
  ],
  "follow after": [
    "exsequor, exsequī, exsecūtus sum",
    "īnsequor, īnsequī, īnsecūtus sum"
  ],
  "go after": [
    "exsequor, exsequī, exsecūtus sum"
  ],
  "wander or travel through or over": [
    "peragrō, peragrāre, peragrāvī, peragrātum"
  ],
  "pass through": [
    "peragrō, peragrāre, peragrāvī, peragrātum"
  ],
  "traverse": [
    "peragrō, peragrāre, peragrāvī, peragrātum",
    "perambulō, perambulāre, perambulāvī, perambulātum"
  ],
  "spread through": [
    "peragrō, peragrāre, peragrāvī, peragrātum"
  ],
  "the endeavor to be equal to or match another in something": [
    "aemulātiō, aemulātiōnis"
  ],
  "emulation": [
    "zēlus, zēlī",
    "aemulātiō, aemulātiōnis"
  ],
  "ambition": [
    "aemulātiō, aemulātiōnis"
  ],
  "rivalry": [
    "aemulātiō, aemulātiōnis"
  ],
  "competition": [
    "aemulātiō, aemulātiōnis",
    "contentiō, contentiōnis"
  ],
  "take by choice": [
    "adoptō, adoptāre, adoptāvī, adoptātum"
  ],
  "adopt": [
    "ascīscō, ascīscere, ascīvī, ascītum",
    "adoptō, adoptāre, adoptāvī, adoptātum"
  ],
  "sever or disunite": [
    "sēiungō, sēiungere, sēiunxī, sēiunctus"
  ],
  "of ivory": [
    "eburneus, eburnea, eburneum"
  ],
  "impunity": [
    "impūnitas, impūnitātis",
    "impūnitās, impūnitātis"
  ],
  "uniformly": [
    "aequāliter, aequālius, aequālissimē",
    "aequābiliter, aequābilius, aequābilissimē"
  ],
  "in like manner": [
    "aequāliter, aequālius, aequālissimē",
    "aequābiliter, aequābilius, aequābilissimē"
  ],
  "bulwark": [
    "Propugnaculum",
    "prōpugnāculum, prōpugnāculī"
  ],
  "fortress": [
    "prōpugnāculum, prōpugnāculī"
  ],
  "tragic": [
    "tragicus, tragica, tragicum"
  ],
  "weigh or consider": [
    "exāminō, exāmināre, exāmināvī, exāminātum"
  ],
  "unbearable": [
    "intolerandus, intoleranda, intolerandum"
  ],
  "cheese": [
    "cāseus, cāseī"
  ],
  "bivalve": [
    "concha, conchae"
  ],
  "mussel": [
    "concha, conchae"
  ],
  "mollusk": [
    "concha, conchae"
  ],
  "a place for walking": [
    "ambulācrum, ambulācrī"
  ],
  "a walk or promenade planted with trees ": [
    "ambulācrum, ambulācrī"
  ],
  "gird on or about": [
    "accingō, accingere, accinxī, accinctum"
  ],
  "go round": [
    "ambeō, ambīre, ambiī, ambitum",
    "ambiō, ambīre, ambīvī, ambītum"
  ],
  "pass around": [
    "ambeō, ambīre, ambiī, ambitum",
    "ambiō, ambīre, ambīvī, ambītum"
  ],
  "skirt": [
    "ambeō, ambīre, ambiī, ambitum",
    "ambiō, ambīre, ambīvī, ambītum"
  ],
  "a picking": [
    "lēctiō, lēctiōnis"
  ],
  "selecting": [
    "lēctiō, lēctiōnis"
  ],
  "dedication": [
    "dēdicātiō, dēdicātiōnis",
    "cōnsecrātiō, cōnsecrātiōnis"
  ],
  "consecration": [
    "dēdicātiō, dēdicātiōnis"
  ],
  "six each": [
    "sēnus, sēna, sēnum"
  ],
  "usefully": [
    "ūtiliter, ūtilius, ūtilissimē"
  ],
  "profitably": [
    "ūtiliter, ūtilius, ūtilissimē"
  ],
  "very suitable": [
    "peropportūnus, peropportūna, peropportūnum"
  ],
  "blowing": [
    "flātus, flātūs"
  ],
  "snorting": [
    "flātus, flātūs"
  ],
  "a chamber in its various senses": [
    "camera, camerae"
  ],
  "including:a room": [
    "camera, camerae"
  ],
  "especially a vaulted room": [
    "camera, camerae"
  ],
  "a vault.a deliberative body": [
    "camera, camerae"
  ],
  "that easily believes a thing": [
    "crēdulus, crēdula, crēdulum"
  ],
  "credulous": [
    "crēdulus, crēdula, crēdulum"
  ],
  "easy of belief": [
    "crēdulus, crēdula, crēdulum"
  ],
  "shady": [
    "res dubia",
    "umbrōsus, umbrōsa, umbrōsum"
  ],
  "shadowy": [
    "umbrōsus, umbrōsa, umbrōsum"
  ],
  "leap or jump at or upon": [
    "īnsultō, īnsultāre, īnsultāvī, īnsultātum"
  ],
  "warlike": [
    "armifer, armifera, armiferum",
    "armiger, armigera, armigerum",
    "bellicōsus, bellicōsa, bellicōsum"
  ],
  "bellicose": [
    "bellicōsus, bellicōsa, bellicōsum"
  ],
  "vicious": [
    "maleficus, malefica, maleficum"
  ],
  "scorch": [
    "torreō, torrēre, torruī, tostum",
    "extorreō, extorrēre, extorruī, extostum"
  ],
  "parch": [
    "torreō, torrēre, torruī, tostum",
    "extorreō, extorrēre, extorruī, extostum"
  ],
  "grotto": [
    "caverna, cavernae"
  ],
  "commiseration": [
    "miserātiō, miserātiōnis"
  ],
  "receiving": [
    "assūmptiō, assūmptiōnis",
    "perceptiō, perceptiōnis"
  ],
  "assumption": [
    "assūmptiō, assūmptiōnis"
  ],
  "causing damage": [
    "damnōsus, damnōsa, damnōsum"
  ],
  "rowing": [
    "rēmigium, rēmigiī"
  ],
  "oarage": [
    "rēmigium, rēmigiī"
  ],
  "vestibule": [
    "vestibulum, vestibulī"
  ],
  "forecourt": [
    "vestibulum, vestibulī"
  ],
  "an enclosed area at the front of a house": [
    "vestibulum, vestibulī"
  ],
  "permission": [
    "concessiō, concessiōnis"
  ],
  "hollow": [
    "alveus, alveī",
    "cavea, caveae",
    "cavus, cava, cavum",
    "concavus, concava, concavum"
  ],
  "concave": [
    "cavus, cava, cavum",
    "concavus, concava, concavum"
  ],
  "reinstatement": [
    "restitūtiō, restitūtiōnis",
    "reconciliātiō, reconciliātiōnis"
  ],
  "rebuilding": [
    "restitūtiō, restitūtiōnis"
  ],
  "restoration": [
    "RESTORATIO",
    "Magica Reficiendi",
    "restitūtiō, restitūtiōnis",
    "reconciliātiō, reconciliātiōnis"
  ],
  "be swollen": [
    "tumeō, tumēre"
  ],
  "turgid": [
    "tumeō, tumēre"
  ],
  "distended": [
    "tumeō, tumēre"
  ],
  "puffed out or inflated": [
    "tumeō, tumēre"
  ],
  "swell": [
    "tumeō, tumēre",
    "fluctuō, fluctuāre, fluctuāvī, fluctuātum"
  ],
  "penetrate": [
    "īnsinuō, īnsinuāre, īnsinuāvī, īnsinuātum"
  ],
  "insinuate oneself": [
    "īnsinuō, īnsinuāre, īnsinuāvī, īnsinuātum"
  ],
  "thyrsus": [
    "thyrsus, thyrsī"
  ],
  "trample": [
    "calcō, calcāre, calcāvī, calcātum"
  ],
  "tread on": [
    "calcō, calcāre, calcāvī, calcātum"
  ],
  "keep silent": [
    "reticeō, reticēre, reticuī"
  ],
  "public proclamation or publication": [
    "praedicātiō, praedicātiōnis"
  ],
  "immobile": [
    "immōbilis, immōbile"
  ],
  "mingling": [
    "cōnfūsiō, cōnfūsiōnis"
  ],
  "mixing": [
    "mistūra, mistūrae",
    "cōnfūsiō, cōnfūsiōnis"
  ],
  "blending": [
    "cōnfūsiō, cōnfūsiōnis"
  ],
  "mixture": [
    "cōnfūsiō, cōnfūsiōnis"
  ],
  "numbness": [
    "stupor, stupōris",
    "torpor, torpōris"
  ],
  "torpor": [
    "stupor, stupōris"
  ],
  "the act of trembling": [
    "pavor, pavōris"
  ],
  "throbbing or panting with fear": [
    "pavor, pavōris"
  ],
  "create again or anew": [
    "recreō, recreāre, recreāvī, recreātum"
  ],
  "lovingly": [
    "amanter, amantius, amantissimē",
    "amābiliter, amābilius, amābilissimē"
  ],
  "affectionately": [
    "amanter, amantius, amantissimē"
  ],
  "deem worthy": [
    "dignō, dignāre, dignāvī, dignātum"
  ],
  "mime": [
    "mīmus, mīmī"
  ],
  "shun or avoid": [
    "ēvītō, ēvītāre, ēvītāvī, ēvītātum"
  ],
  "sweetly": [
    "dulciter, dulcius, dulcissimē",
    "suāviter, suāvius, suāvissimē"
  ],
  "melodiously": [
    "dulciter, dulcius, dulcissimē"
  ],
  "affirming": [
    "affirmātīvus, affirmātīva, affirmātīvum"
  ],
  "affirmative": [
    "affirmātīvus, affirmātīva, affirmātīvum"
  ],
  "a gatekeeper": [
    "appāritor, appāritōris"
  ],
  "amplify": [
    "ampliō, ampliāre, ampliāvī, ampliātum",
    "amplificō, amplificāre, amplificāvī, amplificātum"
  ],
  "magnify": [
    "adaugeō, adaugēre, adauxī, adauctum",
    "ampliō, ampliāre, ampliāvī, ampliātum",
    "amplificō, amplificāre, amplificāvī, amplificātum"
  ],
  "develop": [
    "amplificō, amplificāre, amplificāvī, amplificātum"
  ],
  "infrequent": [
    "īnfrequēns, īnfrequentis"
  ],
  "relationship or alliance by marriage": [
    "affīnitās, affīnitātis"
  ],
  "call or bring out or forth": [
    "exciō, excīre, excīvī, excitum"
  ],
  "tent": [
    "tentōrium, tentōrīi"
  ],
  "killer": [
    "interfector, interfectōris"
  ],
  "slayer": [
    "occīsor, occīsōris",
    "interfector, interfectōris"
  ],
  "wide open": [
    "patulus, patula, patulum"
  ],
  "gaping": [
    "patulus, patula, patulum"
  ],
  "pour or throw in or into": [
    "ingerō, ingerere, ingessī, ingestum"
  ],
  "exemption": [
    "vacātiō, vacātiōnis"
  ],
  "immunity": [
    "vacātiō, vacātiōnis"
  ],
  "sulfur": [
    "sulphur, sulphuris"
  ],
  "brimstone": [
    "sulphur, sulphuris"
  ],
  "very expensive or costly": [
    "sumptuōsus, sumptuōsa, sumptuōsum"
  ],
  "unitedly": [
    "coniunctim, coniunctius, coniunctissimē"
  ],
  "moaning": [
    "lāmentum, lāmentī",
    "lāmentātiō, lāmentātiōnis"
  ],
  "profit or gain": [
    "compendium, compendiī"
  ],
  "especially made by saving": [
    "compendium, compendiī"
  ],
  "docile": [
    "docilis, docile"
  ],
  "teachable": [
    "docilis, docile"
  ],
  "shabby": [
    "sordidātus, sordidāta, sordidātum"
  ],
  "that can contain or hold much": [
    "capāx, capācis"
  ],
  "spacious": [
    "capāx, capācis",
    "laxus, laxa, laxum",
    "amplus, ampla, amplum",
    "spatiōsus, spatiōsa, spatiōsum"
  ],
  "capacious": [
    "capāx, capācis"
  ],
  "roomy": [
    "capāx, capācis",
    "laxus, laxa, laxum"
  ],
  "especially in the grave": [
    "occubō, occubāre, occubuī, occubitum"
  ],
  "ratiocination": [
    "ratiōcinātiō, ratiōcinātiōnis"
  ],
  "embezzlement of public money or property": [
    "pecūlātus, pecūlātūs"
  ],
  "peculation": [
    "pecūlātus, pecūlātūs"
  ],
  "quicken": [
    "accelerō, accelerāre, accelerāvī, accelerātum"
  ],
  "of or belonging to conjecture or guessing": [
    "coniectūrālis, coniectūrāle"
  ],
  "conjectural": [
    "opīnābilis, opīnābile",
    "coniectūrālis, coniectūrāle"
  ],
  "defame": [
    "īnfāmō, īnfāmāre, īnfāmāvī, īnfāmātum"
  ],
  "bring to ill repute": [
    "īnfāmō, īnfāmāre, īnfāmāvī, īnfāmātum"
  ],
  "feebleness of age": [
    "senium, seniī"
  ],
  "debility": [
    "senium, seniī"
  ],
  "delightfulness": [
    "amoenitās, amoenitātis"
  ],
  "very powerful": [
    "praepotēns, praepotentis"
  ],
  "dish prepared for the table": [
    "ēsca, ēscae"
  ],
  "animating": [
    "vegetābilis, vegetābile"
  ],
  "enlivening": [
    "vegetābilis, vegetābile"
  ],
  "vivifying": [
    "vegetābilis, vegetābile"
  ],
  "conclusion": [
    "epilogus, epilogī",
    "clausula, clausulae",
    "cōnsummātiō, cōnsummātiōnis"
  ],
  "avowal": [
    "professiō, professiōnis"
  ],
  "profession": [
    "professiō, professiōnis"
  ],
  "equality": [
    "aequālitās, aequālitātis",
    "aequābilitās, aequābilitātis"
  ],
  "equability": [
    "aequābilitās, aequābilitātis"
  ],
  "made or covered with brass or bronze": [
    "aerātus, aerāta, aerātum"
  ],
  "a hook": [
    "hāmus, hāmī"
  ],
  "virginity": [
    "virginitās, virginitātis"
  ],
  "maidenhood": [
    "virginitās, virginitātis"
  ],
  "a hollow": [
    "alveus, alveī"
  ],
  "cavity": [
    "alveus, alveī",
    "cavea, caveae"
  ],
  "crookedness": [
    "prāvitās, prāvitātis"
  ],
  "irregularity": [
    "prāvitās, prāvitātis"
  ],
  "full of light": [
    "lūculentus, lūculenta, lūculentum"
  ],
  "bountifulness": [
    "mūnificentia, mūnificentiae"
  ],
  "munificence": [
    "mūnificentia, mūnificentiae"
  ],
  "generosity": [
    "mūnificentia, mūnificentiae"
  ],
  "largesse": [
    "mūnificentia, mūnificentiae"
  ],
  "critic": [
    "obtrectātor, obtrectātōris"
  ],
  "detractor": [
    "obtrectātor, obtrectātōris"
  ],
  "traducer": [
    "obtrectātor, obtrectātōris"
  ],
  "disparager": [
    "obtrectātor, obtrectātōris"
  ],
  "measuring": [
    "mēnsūra, mēnsūrae",
    "dīmensiō, dīmensiōnis"
  ],
  "unhoped-for": [
    "īnspērātus, īnspērāta, īnspērātum"
  ],
  "arch": [
    "fornix, fornicis"
  ],
  "vault": [
    "fornix, fornicis"
  ],
  "treatment": [
    "tractātiō, tractātiōnis"
  ],
  "ill-advised": [
    "incōnsultus, incōnsulta, incōnsultum"
  ],
  "invented": [
    "commenticius, commenticia, commenticium"
  ],
  "devised": [
    "commenticius, commenticia, commenticium"
  ],
  "fabricated": [
    "commenticius, commenticia, commenticium"
  ],
  "rapidly": [
    "vēlōciter, vēlōcius, vēlōcissimē"
  ],
  "a slope": [
    "clīvus, clīvī"
  ],
  "freely given": [
    "grātuītus, grātuīta, grātuītum"
  ],
  "persistently": [
    "impensē, impensius, impensissimē"
  ],
  "a walk": [
    "ambulātiō, ambulātiōnis"
  ],
  "stroll": [
    "ambulātiō, ambulātiōnis"
  ],
  "projecting": [
    "mināx, minācis"
  ],
  "overhanging": [
    "mināx, minācis"
  ],
  "in a certain manner": [
    "quōdammodo"
  ],
  "expert": [
    "Expers",
    "sollers, sollertis",
    "astūtus, astūta, astūtum"
  ],
  "pattern": [
    "exemplar, exemplāris"
  ],
  "original or ideal": [
    "exemplar, exemplāris"
  ],
  "a vessel": [
    "amphora, amphorae"
  ],
  "usually made of clay": [
    "amphora, amphorae"
  ],
  "with two handles or ears for liquids": [
    "amphora, amphorae"
  ],
  "amphora": [
    "amphora, amphorae"
  ],
  "flagon": [
    "Lagoena",
    "amphora, amphorae"
  ],
  "pitcher": [
    "urceus, urceī",
    "amphora, amphorae"
  ],
  "flask": [
    "amphora, amphorae"
  ],
  "bottle": [
    "amphora, amphorae"
  ],
  "jar": [
    "amphora, amphorae"
  ],
  "hurry or rush into": [
    "inruō, inruere, inruī, inrutum"
  ],
  "make something blunt": [
    "hebetō, hebetāre, hebetāvī, hebetātum"
  ],
  "inaugurated or consecrated with religious ceremonies": [
    "sacrōsānctus, sacrōsāncta, sacrōsānctum"
  ],
  "cause dew": [
    "rōrō, rōrāre, rōrāvī, rōrātum"
  ],
  "drip": [
    "rōrō, rōrāre, rōrāvī, rōrātum"
  ],
  "be moist": [
    "rōrō, rōrāre, rōrāvī, rōrātum"
  ],
  "grasping": [
    "rapāx, rapācis"
  ],
  "greedy of plunder": [
    "rapāx, rapācis"
  ],
  "rapacious": [
    "edāx, edācis",
    "rapāx, rapācis"
  ],
  "containing a multitude": [
    "celeber, celebris, celebre"
  ],
  "detraction": [
    "obtrectātiō, obtrectātiōnis"
  ],
  "the relation or disposition towards something produced in a person": [
    "affectiō, affectiōnis"
  ],
  "playful": [
    "lascīvus, lascīva, lascīvum"
  ],
  "frisky": [
    "lascīvus, lascīva, lascīvum"
  ],
  "traveller": [
    "viātor, viātōris"
  ],
  "wayfarer": [
    "viātor, viātōris"
  ],
  "lax": [
    "dissolutus, dissoluta, dissolutum"
  ],
  "negligent": [
    "dissolutus, dissoluta, dissolutum",
    "incūriōsus, incūriōsa, incūriōsum"
  ],
  "hoof": [
    "ungula, ungulae"
  ],
  "claw": [
    "ungula, ungulae"
  ],
  "pontifical": [
    "pontificālis, pontificāle",
    "pontificius, pontificia, pontificium"
  ],
  "breathe or blow upon": [
    "aspīrō, aspīrāre, aspīrāvī, aspīrātum"
  ],
  "furnish with a strap or thong": [
    "āmentō, āmentāre, āmentāvī, āmentātum"
  ],
  "complain about": [
    "dēplōrō, dēplōrāre, dēplōrāvī, dēplōrātum"
  ],
  "bemoan": [
    "dēplōrō, dēplōrāre, dēplōrāvī, dēplōrātum"
  ],
  "majestic": [
    "augustus, augusta, augustum"
  ],
  "venerable": [
    "vīvāx, vīvācis",
    "venerābilis, venerābile",
    "augustus, augusta, augustum"
  ],
  "cithara": [
    "cithara, citharae"
  ],
  "guitar": [
    "cithara, citharae"
  ],
  "of or pertaining to the alps": [
    "alpīnus, alpīna, alpīnum"
  ],
  "spur": [
    "calcar, calcāris"
  ],
  "samian": [
    "samius, samia, samium"
  ],
  "expulsion": [
    "ēversiō, ēversiōnis",
    "exāctiō, exāctiōnis"
  ],
  "wander through": [
    "pererrō, pererrāre, pererrāvī, pererrātum"
  ],
  "roam or ramble over": [
    "pererrō, pererrāre, pererrāvī, pererrātum"
  ],
  "an acquittal": [
    "absolūtiō, absolūtiōnis"
  ],
  "absolving": [
    "absolūtiō, absolūtiōnis"
  ],
  "in or by turns": [
    "invicem"
  ],
  "alternately": [
    "invicem"
  ],
  "a tribe of hispania tarraconensis which dwelt north of the course of the iberus": [
    "ilergētēs, ilergētum"
  ],
  "alcyone": [
    "alcyonē, alcyonēs"
  ],
  "crease": [
    "rūga, rūgae"
  ],
  "moisten": [
    "imbuō, imbuere, imbuī, imbūtum"
  ],
  "adviser": [
    "suāsor, suāsōris"
  ],
  "supporter": [
    "suāsor, suāsōris"
  ],
  "one of the cyclades and birthplace of archilocus": [
    "paros, parī"
  ],
  "a surrender": [
    "trāditiō, trāditiōnis"
  ],
  "delivering up": [
    "trāditiō, trāditiōnis"
  ],
  "testify": [
    "testificor, testificārī, testificātum"
  ],
  "bear witness": [
    "testificor, testificārī, testificātum"
  ],
  "repelling": [
    "dēpulsiō, dēpulsiōnis"
  ],
  "reopen": [
    "reclūdō, reclūdere, reclūsī, reclūsum"
  ],
  "am struck with fear": [
    "paveō, pavēre, pāvī"
  ],
  "am afraid or terrified": [
    "paveō, pavēre, pāvī"
  ],
  "tremble or quake with fear": [
    "paveō, pavēre, pāvī"
  ],
  "double or folding door": [
    "valva, valvae"
  ],
  "a rope": [
    "rudēns, rudentis"
  ],
  "magnetic": [
    "magnēticus, magnētica, magnēticum"
  ],
  "unusually": [
    "īnsolenter, īnsolentius, īnsolentissimē"
  ],
  "a seizing": [
    "comprehensiō, comprehensiōnis"
  ],
  "taking hold of": [
    "comprehensiō, comprehensiōnis"
  ],
  "catching": [
    "comprehensiō, comprehensiōnis"
  ],
  "apprehension": [
    "comprehensiō, comprehensiōnis"
  ],
  "a centaur": [
    "centaurus, centaurī"
  ],
  "lifeless": [
    "exanimis, exanime",
    "inanimus, inanima, inanimum"
  ],
  "inanimate": [
    "inanimus, inanima, inanimum",
    "inanimātus, inanimāta, inanimātum"
  ],
  "an ant": [
    "formīca, formīcae"
  ],
  "thereafter": [
    "exin",
    "exindē"
  ],
  "of or for pasture": [
    "pāscuus, pāscua, pāscuum"
  ],
  "grazing": [
    "pāscuus, pāscua, pāscuum"
  ],
  "a lowing": [
    "mūgītus, mūgītūs"
  ],
  "mooing": [
    "mūgītus, mūgītūs"
  ],
  "bellowing": [
    "mūgītus, mūgītūs"
  ],
  "overhasty": [
    "praeproperus, praepropera, praeproperum"
  ],
  "the winter solstice": [
    "brūma, brūmae"
  ],
  "a kind of porter": [
    "cancellārius, cancellāriī"
  ],
  "arrive at": [
    "adipīscor, adipīscī, adeptus sum",
    "adventō, adventāre, adventāvī, adventātum"
  ],
  "catch up with": [
    "adipīscor, adipīscī, adeptus sum"
  ],
  "sowing": [
    "satus",
    "sēmentis, sēmentis"
  ],
  "planting": [
    "satus",
    "sēmentis, sēmentis",
    "plantātiō, plantātiōnis"
  ],
  "turnip": [
    "rāpum, rāpī"
  ],
  "drag or snatch forth": [
    "prōripiō, prōripere, prōripuī, prōreptum"
  ],
  "speak at length": [
    "perōrō, perōrāre, perōrāvī, perōrātum"
  ],
  "harangue": [
    "perōrō, perōrāre, perōrāvī, perōrātum"
  ],
  "feeding ground": [
    "pāstus, pāstūs"
  ],
  "logical": [
    "logicus, logica, logicum"
  ],
  "venerate": [
    "veneror, venerārī, venerātus sum"
  ],
  "divinity": [
    "deitās, deitātis",
    "dīvīnitās, dīvīnitātis"
  ],
  "godhead": [
    "dīvīnitās, dīvīnitātis"
  ],
  "godhood": [
    "dīvīnitās, dīvīnitātis"
  ],
  "taciturnity": [
    "taciturnitās, taciturnitātis"
  ],
  "abundantly": [
    "abundē, abundius, abundissimē",
    "abundanter, abundantius, abundantissimē"
  ],
  "amply": [
    "abundē, abundius, abundissimē"
  ],
  "dance": [
    "saltō, saltāre, saltāvī, saltātum",
    "saltō, saltāre, saltāvī, saltātus"
  ],
  "dying": [
    "Dying",
    "moribundus, moribunda, moribundum"
  ],
  "moribund": [
    "moribundus, moribunda, moribundum"
  ],
  "bearing": [
    "partus, partūs",
    "incessus, incessūs"
  ],
  "become strong": [
    "invalēscō, invalēscere, invaluī"
  ],
  "impregnable": [
    "inexpugnābilis, inexpugnābile"
  ],
  "inexpugnable": [
    "inexpugnābilis, inexpugnābile"
  ],
  "a rib": [
    "costa, costae"
  ],
  "give a nod": [
    "innuō, innuere, innuī, innūtum"
  ],
  "hint with a gesture": [
    "innuō, innuere, innuī, innūtum"
  ],
  "sound through and through": [
    "personō, personāre, personuī, personātum"
  ],
  "pluck or pull out": [
    "revellō, revellere, revellī, revolsum"
  ],
  "impure": [
    "immundus, immunda, immundum",
    "incestus, incesta, incestum"
  ],
  "polluted": [
    "incestus, incesta, incestum"
  ],
  "defiled": [
    "incestus, incesta, incestum"
  ],
  "sinful": [
    "incestus, incesta, incestum"
  ],
  "unrighteous": [
    "incestus, incesta, incestum"
  ],
  "one who lives near a place": [
    "accola, accolae"
  ],
  "a neighbor": [
    "accola, accolae"
  ],
  "mix or mingle together thoroughly": [
    "permisceō, permiscēre, permiscuī, permixtum, permistum"
  ],
  "commingle": [
    "permisceō, permiscēre, permiscuī, permixtum, permistum"
  ],
  "a floor composed of small stones beaten down": [
    "pavīmentum, pavīmentī"
  ],
  "out of the way": [
    "dēvius, dēvia, dēvium"
  ],
  "festive": [
    "fēstīvus, fēstīva, fēstīvum"
  ],
  "joyous": [
    "fēstīvus, fēstīva, fēstīvum"
  ],
  "gay": [
    "fēstīvus, fēstīva, fēstīvum"
  ],
  "merry": [
    "fēstīvus, fēstīva, fēstīvum"
  ],
  "rime": [
    "pruīna, pruīnae"
  ],
  "hoarfrost": [
    "Pruina",
    "pruīna, pruīnae"
  ],
  "am faint": [
    "langueō, languēre"
  ],
  "mint": [
    "monēta, monētae"
  ],
  "a place for coining money": [
    "monēta, monētae"
  ],
  "an ancient city in latium": [
    "cora, corae",
    "sētia, sētiae",
    "gabiī, gabiōrum"
  ],
  "on the road from rome to praeneste": [
    "gabiī, gabiōrum"
  ],
  "am in agitation or violent commotion": [
    "aestuō, aestuāre, aestuāvī, aestuātum"
  ],
  "move to and fro": [
    "aestuō, aestuāre, aestuāvī, aestuātum"
  ],
  "writhe": [
    "aestuō, aestuāre, aestuāvī, aestuātum"
  ],
  "toss": [
    "aestuō, aestuāre, aestuāvī, aestuātum",
    "ventilō, ventilāre, ventilāvī, ventilātum"
  ],
  "boil up": [
    "aestuō, aestuāre, aestuāvī, aestuātum",
    "exaestuō, exaestuāre, exaestuāvī, exaestuātum"
  ],
  "heave": [
    "aestuō, aestuāre, aestuāvī, aestuātum"
  ],
  "falsify": [
    "falsō, falsāre, falsāvī, falsātum"
  ],
  "beating": [
    "plangor, plangōris",
    "percussiō, percussiōnis"
  ],
  "striking": [
    "plangor, plangōris",
    "percussiō, percussiōnis"
  ],
  "percussion": [
    "percussiō, percussiōnis"
  ],
  "a person lying in ambush": [
    "īnsidiātor, īnsidiātōris"
  ],
  "a contraction": [
    "contractiō, contractiōnis"
  ],
  "drawing together": [
    "contractiō, contractiōnis"
  ],
  "decemvir": [
    "decemvir, decemvirī"
  ],
  "enumerate": [
    "recēnseō, recēnsēre, recēnsuī, recēnsum",
    "percēnseō, percēnsēre, percēnsuī, percēnsum"
  ],
  "reckon or survey": [
    "recēnseō, recēnsēre, recēnsuī, recēnsum"
  ],
  "tessera": [
    "tessera, tesserae"
  ],
  "elegantly": [
    "eleganter"
  ],
  "disaffected": [
    "malevolus, malevola, malevolum"
  ],
  "spiteful": [
    "malevolus, malevola, malevolum"
  ],
  "malevolent": [
    "malevolus, malevola, malevolum"
  ],
  "bristling": [
    "horror, horrōris"
  ],
  "hiss": [
    "sībilō, sībilāre, sībilāvī, sībilātum"
  ],
  "crown": [
    "corōna -ae",
    "corōna -ae (f)",
    "diadēma, diadēmatis",
    "corōnō, corōnāre, corōnāvī, corōnātum",
    "redimiō, redimīre, redimīvī, redimitum"
  ],
  "wreathe ": [
    "corōnō, corōnāre, corōnāvī, corōnātum"
  ],
  "a questioning": [
    "interrogātiō, interrogātiōnis"
  ],
  "interrogation": [
    "interrogātiō, interrogātiōnis"
  ],
  "heterogeneous": [
    "heterogeneus, heterogenea, heterogeneum"
  ],
  "combative": [
    "pugnāx, pugnācis"
  ],
  "fond of fighting": [
    "pugnāx, pugnācis"
  ],
  "honourably": [
    "honōrificē, honōrificentius, honōrificentissimē"
  ],
  "quadriga": [
    "quādrīga, quādrīgae"
  ],
  "thigh": [
    "femur, feminis or femoris"
  ],
  "stiff-necked": [
    "contumāx, contumācis"
  ],
  "selling": [
    "vēnditiō, vēnditiōnis"
  ],
  "vending": [
    "vēnditiō, vēnditiōnis"
  ],
  "sale": [
    "vēnus, vēnūs",
    "vēnditiō, vēnditiōnis"
  ],
  "unseasonable inopportune": [
    "intempestīvus, intempestīva, intempestīvum"
  ],
  "send in": [
    "inmittō, inmittere, inmīsī, inmissum"
  ],
  "into or against": [
    "inmittō, inmittere, inmīsī, inmissum"
  ],
  "economy": [
    "frūgālitās, frūgālitātis"
  ],
  "unusualness": [
    "īnsolentia, īnsolentiae"
  ],
  "unfamiliarity": [
    "īnsolentia, īnsolentiae"
  ],
  "fashion or shape": [
    "cōnfōrmō, cōnfōrmāre, cōnfōrmāvī, cōnfōrmātum"
  ],
  "unmerited": [
    "immeritus, immerita, immeritum"
  ],
  "unearned": [
    "immeritus, immerita, immeritum"
  ],
  "undeserved": [
    "immeritus, immerita, immeritum"
  ],
  "benevolent": [
    "benevolus, benevola, benevolum",
    "mūnificus, mūnifica, mūnificum"
  ],
  "puissant": [
    "imperiōsus, imperiōsa, imperiōsum"
  ],
  "commanding": [
    "imperiōsus, imperiōsa, imperiōsum"
  ],
  "view all over": [
    "perlegō, perlegere, perlēgī, perlēctum"
  ],
  "examine thoroughly": [
    "perlegō, perlegere, perlēgī, perlēctum"
  ],
  "scan": [
    "perlegō, perlegere, perlēgī, perlēctum"
  ],
  "flow or run together": [
    "cōnfluō, cōnfluere, cōnfluxī"
  ],
  "grow warm": [
    "incalēscō, incalēscere, incaluī"
  ],
  "a mushroom": [
    "fungus, fungī"
  ],
  "a fungus": [
    "fungus, fungī"
  ],
  "draw or bind together": [
    "cōnstringō, cōnstringere, cōnstrīnxī, cōnstrictum"
  ],
  "one's handwriting": [
    "chīrographum, chīrographī"
  ],
  "autograph": [
    "chīrographum, chīrographī"
  ],
  "infected": [
    "pestilēns, pestilentis",
    "pūtidus, pūtida, pūtidum"
  ],
  "unhealthy": [
    "pestilēns, pestilentis"
  ],
  "assiduity": [
    "sēdulitās, sēdulitātis"
  ],
  "sedulity": [
    "sēdulitās, sēdulitātis"
  ],
  "period of four years": [
    "quādriennium, quādrienniī"
  ],
  "the act of making clear": [
    "dēclārātiō, dēclārātiōnis"
  ],
  "a disclosure": [
    "dēclārātiō, dēclārātiōnis"
  ],
  "of what kind": [
    "cūiusmodi",
    "cuicuimodi"
  ],
  "emporium": [
    "emporium, emporiī"
  ],
  "living skin": [
    "cutis, cutis"
  ],
  "call off or away": [
    "āvocō, āvocāre, āvocāvī, āvocātum"
  ],
  "divert": [
    "āvocō, āvocāre, āvocāvī, āvocātum",
    "dēmoveō, dēmovēre, dēmōvī, dēmōtum"
  ],
  "abounding in water": [
    "aquōsus, aquōsa, aquōsum"
  ],
  "well-watered": [
    "aquōsus, aquōsa, aquōsum"
  ],
  "contractor": [
    "redemptor, redemptōris",
    "susceptor, susceptōris"
  ],
  "undertaker": [
    "redemptor, redemptōris",
    "susceptor, susceptōris"
  ],
  "purveyor": [
    "redemptor, redemptōris"
  ],
  "suckle": [
    "lactō, lactāre, lactāvī, lactātum",
    "nūtriō, nūtrīre, nūtrīvī, nūtrītum"
  ],
  "a small sharp or pointed stone": [
    "scrūpulus, scrūpulī"
  ],
  "stalk": [
    "culmus, culmī",
    "stipula, stipulae"
  ],
  "shine out or forth": [
    "ēlūceō, ēlūcēre, ēluxī"
  ],
  "sucker": [
    "subolēs, subolis"
  ],
  "check or repress": [
    "refūtō, refūtāre, refūtāvī, refūtātum"
  ],
  "bear or move around or about": [
    "circumferō, circumferre, circumtulī, circumlātum"
  ],
  "carry or move around in a circle": [
    "circumferō, circumferre, circumtulī, circumlātum"
  ],
  "a crowd": [
    "caterva, catervae"
  ],
  "a band": [
    "caterva, catervae"
  ],
  "suppliantly": [
    "suppliciter"
  ],
  "hold out": [
    "restō, restāre, restitī, —",
    "perhibeō, perhibēre, perhibuī, perhibitum"
  ],
  "flow or run forth or out": [
    "effluō, effluere, effluxī"
  ],
  "weakening": [
    "infirmātiō, infirmātiōnis"
  ],
  "invalidating": [
    "infirmātiō, infirmātiōnis"
  ],
  "peculiarity": [
    "prōprietās, prōprietātis"
  ],
  "glorified": [
    "mactus, macta, mactum"
  ],
  "worshiped": [
    "mactus, macta, mactum"
  ],
  "honored": [
    "mactus, macta, mactum"
  ],
  "adored": [
    "mactus, macta, mactum"
  ],
  "frenzied": [
    "furiālis, furiāle",
    "vēsānus, vēsāna, vēsānum",
    "furibundus, furibunda, furibundum"
  ],
  "malevolence": [
    "malevolentia, malevolentiae"
  ],
  "bring or conduct forcibly to a place": [
    "pertrahō, pertrahere, pertraxī, pertractum"
  ],
  "cover over": [
    "collinō, collinere, collēvī, collitum",
    "cooperiō, cooperīre, cooperuī, coopertum"
  ],
  "a carpenter’s square": [
    "nōrma, nōrmae"
  ],
  "handle": [
    "Ansam",
    "Fulcrum",
    "cūpa, cūpae",
    "tractō, tractāre, tractāvī, tractātum"
  ],
  "axle": [
    "cūpa, cūpae"
  ],
  "shrewd": [
    "astūtus, astūta, astūtum"
  ],
  "astute": [
    "astūtus, astūta, astūtum"
  ],
  "captain": [
    "nauarchus, nauarchī"
  ],
  "skipper": [
    "nauarchus, nauarchī"
  ],
  "make amends or atonement for a crime or a criminal": [
    "expiō, expiāre, expiāvī, expiātum"
  ],
  "atone for": [
    "expiō, expiāre, expiāvī, expiātum"
  ],
  "expiate": [
    "expiō, expiāre, expiāvī, expiātum"
  ],
  "purge by sacrifice": [
    "expiō, expiāre, expiāvī, expiātum"
  ],
  "appease": [
    "expiō, expiāre, expiāvī, expiātum",
    "plācō, plācāre, plācāvī, plācātum"
  ],
  "a herdsman": [
    "bubulcus, bubulcī"
  ],
  "ox-driver": [
    "bubulcus, bubulcī"
  ],
  "predominance": [
    "praedominantia, praedominantiae"
  ],
  "diminutive of calx": [
    "calculus, calculī"
  ],
  "bereave ": [
    "viduō, viduāre, viduāvī, viduātum"
  ],
  "find fault with": [
    "incūsō, incūsāre, incūsāvī, incūsātum"
  ],
  "a donation": [
    "dōnātiō, dōnātiōnis"
  ],
  "of or pertaining to a door hinge": [
    "cardinālis, cardināle"
  ],
  "love truly": [
    "adamō, adamāre, adamāvī, adamātum"
  ],
  "deeply or greatly": [
    "adamō, adamāre, adamāvī, adamātum"
  ],
  "persuade or win over": [
    "exōrō, exōrāre, exōrāvī, exōrātum"
  ],
  "credulity": [
    "crēdulitās, crēdulitātis"
  ],
  "easiness of belief": [
    "crēdulitās, crēdulitātis"
  ],
  "ceremonial staff used in manumission": [
    "vindicta, vindictae"
  ],
  "mix or mingle with": [
    "admisceō, admiscēre, admiscuī, admixtum"
  ],
  "in or together": [
    "admisceō, admiscēre, admiscuī, admixtum"
  ],
  "admix": [
    "admisceō, admiscēre, admiscuī, admixtum"
  ],
  "add to": [
    "admisceō, admiscēre, admiscuī, admixtum"
  ],
  "historical": [
    "historicus, historica, historicum"
  ],
  "chub": [
    "cephalus, cephalī"
  ],
  "chevin": [
    "cephalus, cephalī"
  ],
  "pollard": [
    "cephalus, cephalī"
  ],
  "an instance of poisoning": [
    "venēficium, venēficiī"
  ],
  "poisonous substance": [
    "venēficium, venēficiī"
  ],
  "cut to the ground": [
    "occīdō, occīdere, occīdī, occīsum"
  ],
  "smash": [
    "occīdō, occīdere, occīdī, occīsum"
  ],
  "trend": [
    "rotō, rotāre, rotāvī, rotātum"
  ],
  "swing about": [
    "rotō, rotāre, rotāvī, rotātum"
  ],
  "whirl": [
    "rotō, rotāre, rotāvī, rotātum"
  ],
  "rotate": [
    "rotō, rotāre, rotāvī, rotātum"
  ],
  "extravagant": [
    "profūsus, profūsa, profūsum"
  ],
  "of or pertaining to melody": [
    "canōrus, canōra, canōrum"
  ],
  "melodious": [
    "canōrus, canōra, canōrum"
  ],
  "harmonious": [
    "canōrus, canōra, canōrum"
  ],
  "euphonious": [
    "canōrus, canōra, canōrum"
  ],
  "of or relating to order": [
    "ōrdinārius, ōrdināria, ōrdinārium"
  ],
  "orderly": [
    "ōrdinārius, ōrdināria, ōrdinārium"
  ],
  "quarrel": [
    "rixa, rixae",
    "iūrgium, iūrgiī",
    "contrōversia, contrōversiae",
    "lītigō, lītigāre, lītigāvī, lītigātum"
  ],
  "brawl": [
    "rixa, rixae",
    "Oppugna Manibus"
  ],
  "or perform anew or afresh": [
    "īnstaurō, īnstaurāre, īnstaurāvī, īnstaurātum"
  ],
  "resume": [
    "īnstaurō, īnstaurāre, īnstaurāvī, īnstaurātum"
  ],
  "of or belonging to a body": [
    "corporeus, corporea, corporeum"
  ],
  "corporeal": [
    "corporeus, corporea, corporeum"
  ],
  "the act of grasping at or reaching after something": [
    "appetītiō, appetītiōnis"
  ],
  "educated": [
    "litterātus, litterāta, litterātum"
  ],
  "scholarly": [
    "litterātus, litterāta, litterātum"
  ],
  "literate": [
    "litterātus, litterāta, litterātum"
  ],
  "marking": [
    "notātiō, notātiōnis"
  ],
  "noting": [
    "notātiō, notātiōnis"
  ],
  "a city of argolis and dwelling of the mythical king agamemnon": [
    "mycēnae, mycēnārum"
  ],
  "bladder": [
    "vēsīca, vēsīcae"
  ],
  "urinary bladder": [
    "vēsīca, vēsīcae"
  ],
  "get used to": [
    "adsuēscō, adsuēscere, adsuēvī, adsuētum"
  ],
  "become accustomed with": [
    "adsuēscō, adsuēscere, adsuēvī, adsuētum"
  ],
  "a she-bear": [
    "ursa, ursae"
  ],
  "female bear": [
    "ursa, ursae"
  ],
  "in a friendly manner": [
    "amīcē, amīcius, amīcissimē"
  ],
  "amicably": [
    "amīcē, amīcius, amīcissimē"
  ],
  "room": [
    "conclāve, conclāvis"
  ],
  "chamber": [
    "conclāve, conclāvis"
  ],
  "rotation": [
    "rotātiō, rotātiōnis"
  ],
  "a sergeant": [
    "serviēns, servientis"
  ],
  "devote to": [
    "dēspondeō, dēspondēre, dēspondī, dēspōnsum"
  ],
  "awesome": [
    "Awesome",
    "verendus, verenda, verendum"
  ],
  "awe-inspiring": [
    "verendus, verenda, verendum"
  ],
  "sandbank": [
    "syrtis, syrtis"
  ],
  "of or pertaining to life": [
    "vītālis, vītāle"
  ],
  "vital": [
    "vītālis, vītāle"
  ],
  "life-giving": [
    "vītālis, vītāle"
  ],
  "profligate": [
    "prōflīgātus, prōflīgāta, prōflīgātum"
  ],
  "am hot": [
    "ferveō, fervēre, ferbuī, fervitum"
  ],
  "scale": [
    "squāma, squāmae"
  ],
  "bereavement of parents": [
    "orbitās, orbitātis"
  ],
  "a husband or other dear person": [
    "orbitās, orbitātis"
  ],
  "sink or slip down": [
    "dēlābor, dēlābī, dēlapsus sum"
  ],
  "panting": [
    "anhēlitus, anhēlitūs"
  ],
  "puffing": [
    "anhēlitus, anhēlitūs"
  ],
  "fruitful": [
    "ferax, feracis",
    "fructuōsus, fructuōsa, fructuōsum"
  ],
  "entangled": [
    "perplexus, perplexa, perplexum"
  ],
  "involved": [
    "perplexus, perplexa, perplexum"
  ],
  "intricate": [
    "perplexus, perplexa, perplexum"
  ],
  "confused": [
    "dissonus, dissona, dissonum",
    "perplexus, perplexa, perplexum"
  ],
  "a library": [
    "bibliothēca, bibliothēcae"
  ],
  "detestable": [
    "abōminābilis, abōminābile",
    "dētestābilis, dētestābile",
    "exsēcrābilis, exsēcrābile"
  ],
  "vanish or disappear": [
    "ēvānēscō, ēvānēscere, ēvānuī"
  ],
  "oil-": [
    "oleārius, oleāria, oleārium"
  ],
  "or or pertaining to olive oil": [
    "oleārius, oleāria, oleārium"
  ],
  "skillful": [
    "dexter",
    "daedalus, daedala, daedalum"
  ],
  "artfully constructed": [
    "daedalus, daedala, daedalum"
  ],
  "clothe": [
    "amiciō, amicīre, amicuī, amixī, amictum"
  ],
  "cover or dress": [
    "amiciō, amicīre, amicuī, amixī, amictum"
  ],
  "lose my speech": [
    "obmūtēscō, obmūtēscere, obmūtuī"
  ],
  "become dumb": [
    "obmūtēscō, obmūtēscere, obmūtuī"
  ],
  "into or upon": [
    "intrōspiciō, intrōspicere, intrōspexī, intrōspectum"
  ],
  "milky": [
    "lactēns, lactentis",
    "lacteus, lactea, lacteum"
  ],
  "step by step": [
    "gradātim"
  ],
  "architect": [
    "architectus, architectī"
  ],
  "master builder": [
    "architectus, architectī"
  ],
  "buckle": [
    "fībula, fībulae"
  ],
  "brooch": [
    "fībula, fībulae"
  ],
  "call forth": [
    "prōvocō, prōvocāre, prōvocāvī, prōvocātum"
  ],
  "set or planted with trees": [
    "arbustus, arbusta, arbustum"
  ],
  "saffron": [
    "crocum, crocī",
    "croceus, crocea, croceum"
  ],
  "hairy": [
    "hīrtus, hīrta, hīrtum",
    "crīnītus, crīnīta, crīnītum"
  ],
  "join or fasten": [
    "iniungō, iniungere, iniunxī, iniunctum"
  ],
  "sated": [
    "satur, satura, saturum"
  ],
  "strangulation": [
    "Strangulatio",
    "angor, angōris"
  ],
  "make alien from someone": [
    "abaliēnō, abaliēnāre, abaliēnāvī, abaliēnātum"
  ],
  "alienate": [
    "abaliēnō, abaliēnāre, abaliēnāvī, abaliēnātum"
  ],
  "estrange": [
    "abaliēnō, abaliēnāre, abaliēnāvī, abaliēnātum"
  ],
  "make hostile": [
    "abaliēnō, abaliēnāre, abaliēnāvī, abaliēnātum"
  ],
  "geometry": [
    "geōmetria, geōmetriae"
  ],
  "of or pertaining to a brook": [
    "rīvālis, rīvāle"
  ],
  "annihilate": [
    "perimō, perimere, perēmī, peremptum"
  ],
  "immerse": [
    "mersō, mersāre, mersāvī, mersātum"
  ],
  "rake": [
    "rāstrum, rāstrī"
  ],
  "hoe": [
    "rāstrum, rāstrī"
  ],
  "mattock": [
    "rāstrum, rāstrī"
  ],
  "write underneath or below": [
    "subscrībō, subscrībere, subscrīpsi, subscrīptum"
  ],
  "append": [
    "subscrībō, subscrībere, subscrīpsi, subscrīptum"
  ],
  "a cognomen famously held by:marcus livius salinator": [
    "salīnātor, salīnātōris"
  ],
  "a roman consul": [
    "salīnātor, salīnātōris"
  ],
  "imparting": [
    "commūnicātiō, commūnicātiōnis"
  ],
  "raider": [
    "praedātor, praedātōris"
  ],
  "plunderer": [
    "praedātor, praedātōris"
  ],
  "pillager": [
    "praedātor, praedātōris"
  ],
  "hunter": [
    "Venatrix",
    "praedātor, praedātōris"
  ],
  "accommodate myself to the will of another person": [
    "obsequor, obsequī, obsecūtus sum"
  ],
  "gratify": [
    "obsequor, obsequī, obsecūtus sum",
    "grātificor, grātificārī, grātificātus sum"
  ],
  "oblige": [
    "obsequor, obsequī, obsecūtus sum",
    "grātificor, grātificārī, grātificātus sum"
  ],
  "humor": [
    "obsequor, obsequī, obsecūtus sum"
  ],
  "of or pertaining to grammar": [
    "grammaticus, grammatica, grammaticum"
  ],
  "grammatical": [
    "grammaticus, grammatica, grammaticum"
  ],
  "curse or execrate": [
    "dētestor, dētestārī, dētestātus sum",
    "exsecror, exsecrārī, exsecrātus sum"
  ],
  "divided into three parts": [
    "tripertītus, tripertīta, tripertītum"
  ],
  "divisible by three": [
    "tripertītus, tripertīta, tripertītum"
  ],
  "weigh": [
    "expendō, expendere, expendī, expēnsum",
    "ponderō, ponderāre, ponderāvī, ponderātum",
    "compēnsō, compēnsāre, compēnsāvī, compēnsātum"
  ],
  "drought": [
    "siccitas, siccitātis"
  ],
  "dryness": [
    "siccitas, siccitātis"
  ],
  "send as ambassador": [
    "lēgō, lēgāre, lēgāvī, lēgātum"
  ],
  "survivor": [
    "superstes, superstitis"
  ],
  "an ancient city of latium": [
    "sōra, sōrae",
    "pōmētia, pōmētiae",
    "collātia, collātiae"
  ],
  "situated in the valley of the liris": [
    "sōra, sōrae"
  ],
  "now sora": [
    "sōra, sōrae"
  ],
  "stability": [
    "Stabilitas",
    "stabilitās, stabilitātis"
  ],
  "immovability": [
    "stabilitās, stabilitātis"
  ],
  "ballista": [
    "ballista, ballistae"
  ],
  "a community": [
    "commūnitās, commūnitātis"
  ],
  "related to entreaty or petition": [
    "precārius, precāria, precārium"
  ],
  "gluttonous": [
    "edāx, edācis"
  ],
  "voracious": [
    "edāx, edācis"
  ],
  "consuming": [
    "edāx, edācis"
  ],
  "devourer": [
    "edāx, edācis"
  ],
  "a bristle": [
    "saeta, saetae"
  ],
  "hair on an animal": [
    "saeta, saetae"
  ],
  "conformation": [
    "conformātiō, conformātiōnis"
  ],
  "accusatory": [
    "crīminōsus, crīminōsa, crīminōsum",
    "accūsātōrius, accūsātōria, accūsātōrium"
  ],
  "reproachful": [
    "crīminōsus, crīminōsa, crīminōsum"
  ],
  "appeal": [
    "appellātiō, appellātiōnis"
  ],
  "enrich": [
    "locuplētō, locuplētāre, locuplētāvī, locuplētātum"
  ],
  "make wealthy": [
    "locuplētō, locuplētāre, locuplētāvī, locuplētātum"
  ],
  "pink": [
    "roseus, rosea, roseum"
  ],
  "rose-colored": [
    "roseus, rosea, roseum"
  ],
  "rosy": [
    "roseus, rosea, roseum"
  ],
  "or grow cold": [
    "refrīgēscō, refrīgēscere, refrīxī"
  ],
  "olive oil mill": [
    "trapētus, trapētī"
  ],
  "collecting": [
    "collātiō, collātiōnis",
    "perceptiō, perceptiōnis"
  ],
  "a military trumpet": [
    "lituus, lituī"
  ],
  "refuse to acknowledge": [
    "abdicō, abdicāre, abdicāvī, abdicātum"
  ],
  "taking or receiving in advance": [
    "praeceptiō, praeceptiōnis"
  ],
  "anticipation": [
    "praeceptiō, praeceptiōnis"
  ],
  "very high": [
    "praealtus, praealta, praealtum"
  ],
  "hastening": [
    "concitātiō, concitātiōnis"
  ],
  "ground corn": [
    "farīna, farīnae"
  ],
  "flour": [
    "farīna, farīnae"
  ],
  "meal": [
    "farīna, farīnae"
  ],
  "pitch": [
    "piceus, picea, piceum"
  ],
  "foretelling": [
    "praedictiō, praedictiōnis"
  ],
  "prediction": [
    "prophētīa, prophētīae",
    "praedictiō, praedictiōnis",
    "vāticinātiō, vāticinātiōnis"
  ],
  "cleave or stick cling together": [
    "cohaereō, cohaerēre, cohaesī, cohaesum"
  ],
  "am united": [
    "cohaereō, cohaerēre, cohaesī, cohaesum"
  ],
  "cohere": [
    "cohaereō, cohaerēre, cohaesī, cohaesum"
  ],
  "cone": [
    "mēta -ae",
    "cōnus, cōnī",
    "mēta, mētae",
    "mēta -ae (f)"
  ],
  "pyramid": [
    "mēta -ae",
    "mēta, mētae",
    "mēta -ae (f)"
  ],
  "execration": [
    "exsēcrātiō, exsēcrātiōnis"
  ],
  "malediction": [
    "exsēcrātiō, exsēcrātiōnis"
  ],
  "vernal": [
    "vernus, verna, vernum"
  ],
  "funereal": [
    "fūnebris, fūnebre",
    "fūnereus, fūnerea, fūnereum"
  ],
  "divider": [
    "dīvīsor, dīvīsōris"
  ],
  "a mule": [
    "mūlus, mūlī"
  ],
  "of or relating to the aegean sea or the regions bordering it": [
    "aegaeus, aegaea, aegaeum"
  ],
  "lead or draw apart": [
    "dīdūcō, dīdūcere, dīdūxī, dīductum"
  ],
  "sunder": [
    "dīdūcō, dīdūcere, dīdūxī, dīductum"
  ],
  "open out": [
    "dīdūcō, dīdūcere, dīdūxī, dīductum"
  ],
  "a hiatus": [
    "hiātus, hiātūs"
  ],
  "gap": [
    "hiātus, hiātūs"
  ],
  "cleft": [
    "rīma -ae",
    "rīma -ae (f)",
    "hiātus, hiātūs"
  ],
  "bountiful": [
    "mūnificus, mūnifica, mūnificum"
  ],
  "munificent": [
    "mūnificus, mūnifica, mūnificum"
  ],
  "glomerate": [
    "glomerō, glomerāre, glomerāvī, glomerātum"
  ],
  "make into a ball": [
    "glomerō, glomerāre, glomerāvī, glomerātum"
  ],
  "pile in a heap": [
    "glomerō, glomerāre, glomerāvī, glomerātum"
  ],
  "pimp": [
    "lēnō, lēnōnis"
  ],
  "unsalted": [
    "īnsulsus, īnsulsa, īnsulsum"
  ],
  "flavorless": [
    "īnsulsus, īnsulsa, īnsulsum"
  ],
  "stone quarry": [
    "Lis Lapidis",
    "lautumia, lautumiae"
  ],
  "especially one used as a prison": [
    "lautumia, lautumiae"
  ],
  "a complete sentence": [
    "periodus, periodī"
  ],
  "period": [
    "periodus, periodī"
  ],
  "strew in front of": [
    "prōsternō, prōsternere, prōstrāvī, prōstrātum"
  ],
  "a turning around": [
    "versūra, versūrae"
  ],
  "undertake again": [
    "retractō, retractāre, retractāvī, retractātum"
  ],
  "of or pertaining to the veneti": [
    "venetus, veneta, venetum"
  ],
  "venetian": [
    "venetus, veneta, venetum"
  ],
  "send or drive off or away": [
    "summoveō, summovēre, summōvī, summōtum"
  ],
  "dispel": [
    "summoveō, summovēre, summōvī, summōtum"
  ],
  "an olive": [
    "olīva, olīvae"
  ],
  "goose": [
    "ānser, ānseris"
  ],
  "lantern": [
    "Lucerna",
    "lampas, lampadis"
  ],
  "sore": [
    "ulcus, ulceris"
  ],
  "ulcer": [
    "ulcus, ulceris"
  ],
  "sacrifice in honour of the dead": [
    "īnferia, īnferiae"
  ],
  "the funeral rites": [
    "īnferia, īnferiae"
  ],
  "be callous or thickskinned": [
    "calleō, callēre, calluī"
  ],
  "tempering": [
    "temperātiō, temperātiōnis"
  ],
  "confirm": [
    "stabiliō, stabilīre, stabilīvī, stabilītum"
  ],
  "hold still": [
    "stabiliō, stabilīre, stabilīvī, stabilītum"
  ],
  "stabilize": [
    "stabiliō, stabilīre, stabilīvī, stabilītum"
  ],
  "cloth": [
    "pannus, pannī"
  ],
  "of or pertaining to music": [
    "mūsicus, mūsica, mūsicum"
  ],
  "musical": [
    "mūsicus, mūsica, mūsicum"
  ],
  "feed upon": [
    "vēscor, vēscī"
  ],
  "inspiration": [
    "īnstinctus, īnstinctūs"
  ],
  "ascend": [
    "scandō, scandere, scandī, scānsum"
  ],
  "horses": [
    "Horses",
    "equīnus, equīna, equīnum"
  ],
  "equine": [
    "equīnus, equīna, equīnum"
  ],
  "chiding": [
    "increpātiō, increpātiōnis",
    "obiurgātiō, obiurgātiōnis"
  ],
  "reproving": [
    "obiurgātiō, obiurgātiōnis"
  ],
  "reproof": [
    "increpātiō, increpātiōnis",
    "obiurgātiō, obiurgātiōnis"
  ],
  "reprehension": [
    "obiurgātiō, obiurgātiōnis"
  ],
  "stretch forth or forward": [
    "praetendō, praetendere, praetetendī, praetentum"
  ],
  "of or pertaining to manners": [
    "mōrālis, mōrāle"
  ],
  "morals or ethics": [
    "mōrālis, mōrāle"
  ],
  "moral": [
    "mōrālis, mōrāle"
  ],
  "inter": [
    "sepeliō, sepelīre, sepelīvī, sepultum"
  ],
  "diminutive of canis": [
    "canīcula, canīculae"
  ],
  "lead astray": [
    "sēdūcō, sēdūcere, sēdūxī, sēductum"
  ],
  "shake or quiver": [
    "coruscō, coruscāre, coruscāvī, coruscātum"
  ],
  "the act of renewing": [
    "īnstaurātiō, īnstaurātiōnis"
  ],
  "renewal": [
    "īnstaurātiō, īnstaurātiōnis",
    "reconciliātiō, reconciliātiōnis"
  ],
  "repetition": [
    "repetītiō, repetītiōnis",
    "īnstaurātiō, īnstaurātiōnis"
  ],
  "immediate": [
    "immediātus, immediāta, immediātum"
  ],
  "hay": [
    "faenum, faenī"
  ],
  "disgraced": [
    "ignōminiōsus, ignōminiōsa, ignōminiōsum"
  ],
  "a blending of voices in harmony": [
    "concentus, concentūs"
  ],
  "track": [
    "trāmes, trāmitis",
    "indāgō, indāgāre, indāgāvī, indāgātum"
  ],
  "tamer": [
    "domitor, domitōris"
  ],
  "breaker": [
    "ruptor, ruptōris",
    "domitor, domitōris"
  ],
  "encounter": [
    "obitus, obitūs",
    "oppetō, oppetere, oppetīvī, oppetītum"
  ],
  "yell": [
    "vōciferātiō, vōciferātiōnis",
    "clāmitō, clāmitāre, clāmitāvī, clāmitātum"
  ],
  "the evergreen box tree": [
    "buxus, buxī"
  ],
  "spark": [
    "scintilla, scintillae",
    "igniculus, igniculī), second declension (diminutive of: ignis",
    "igniculus, igniculī); second declension (Diminutive of: ignis"
  ],
  "drum": [
    "Typanum",
    "Tympanum",
    "tympanum, tympanī"
  ],
  "timbrel": [
    "tympanum, tympanī"
  ],
  "tambour": [
    "tympanum, tympanī"
  ],
  "tambourine": [
    "tympanum, tympanī"
  ],
  "implacable": [
    "implācābilis, implācābile",
    "inexpiābilis, inexpiābile"
  ],
  "cast out": [
    "ēiectō, ēiectāre, ēiectāvī, ēiectātum"
  ],
  "western": [
    "occidentālis, occidentāle"
  ],
  "westerly": [
    "occidentālis, occidentāle"
  ],
  "fructiferous": [
    "frūgifer, frūgifera, frūgiferum",
    "fructifer, fructifera, fructiferum"
  ],
  "expansion": [
    "expansiō, expansiōnis"
  ],
  "he who interprets": [
    "coniector, coniectōris"
  ],
  "explains or divines something": [
    "coniector, coniectōris"
  ],
  "an interpreter": [
    "coniector, coniectōris"
  ],
  "diviner": [
    "coniector, coniectōris"
  ],
  "seer": [
    "coniector, coniectōris"
  ],
  "vent anger": [
    "saeviō, saevīre, saeviī, saevītum"
  ],
  "am furious": [
    "saeviō, saevīre, saeviī, saevītum"
  ],
  "rigidity": [
    "rigor, rigōris",
    "dūritia, dūritiae"
  ],
  "sluggishness": [
    "pigritia, pigritiae",
    "segnitia, segnitiae"
  ],
  "indolence": [
    "pigritia, pigritiae"
  ],
  "humid": [
    "humidus, humida, humidum"
  ],
  "bulb": [
    "bulbus, bulbī"
  ],
  "notary": [
    "notārius, notāriī"
  ],
  "a measured stamping": [
    "tripudium, tripudiī or tripudī"
  ],
  "jumping": [
    "tripudium, tripudiī or tripudī"
  ],
  "dancing in religious solemnities": [
    "tripudium, tripudiī or tripudī"
  ],
  "a solemn religious dance": [
    "tripudium, tripudiī or tripudī"
  ],
  "renter": [
    "manceps, mancipis"
  ],
  "planetree": [
    "platanus, platanī"
  ],
  "sycamore": [
    "platanus, platanī"
  ],
  "a reed": [
    "canna, cannae"
  ],
  "cane": [
    "canna, cannae"
  ],
  "upbraid or reprove": [
    "exprōbrō, exprōbrāre, exprōbrāvī, exprōbrātum"
  ],
  "offset": [
    "compēnsō, compēnsāre, compēnsāvī, compēnsātum"
  ],
  "a two-pronged fork": [
    "furca, furcae"
  ],
  "pitchfork": [
    "Bidens",
    "furca, furcae"
  ],
  "turn up": [
    "sulcō, sulcāre, sulcāvī, sulcātum"
  ],
  "conical": [
    "conicus, conica, conicum"
  ],
  "of or pertaining to wine": [
    "vīnārius, vīnāria, vīnārium"
  ],
  "banker": [
    "argentārius, argentāriī"
  ],
  "money changer": [
    "argentārius, argentāriī"
  ],
  "bordering on": [
    "confīnis, confīne"
  ],
  "yoked together": [
    "iugālis, iugāle"
  ],
  "invalid": [
    "irritus, irrita, irritum"
  ],
  "fancy": [
    "phantasia, phantasiae"
  ],
  "notion": [
    "phantasia, phantasiae",
    "conceptiō, conceptiōnis"
  ],
  "fantasy": [
    "phantasia, phantasiae"
  ],
  "community": [
    "cōnsortiō, cōnsortiōnis",
    "commūnitās, commūnitātis"
  ],
  "behold": [
    "contemplō, contemplāre, contemplāvī, contemplātum",
    "prospectō, prospectāre, prospectāvī, prospectātum"
  ],
  "touch or rub gently": [
    "permulceō, permulcēre, permulsī, permulsum"
  ],
  "winding": [
    "tortus, tortūs",
    "anfractus, anfracta, anfractum"
  ],
  "dig out or up": [
    "effodiō, effodere, effōdī, effossum"
  ],
  "so very": [
    "tantopere"
  ],
  "to such a great degree": [
    "tantopere"
  ],
  "very sharp or penetrating": [
    "peracūtus, peracūta, peracūtum"
  ],
  "am warm or hot": [
    "caleō, calēre, caluī, calitūrus"
  ],
  "glow": [
    "Glow",
    "relūceō, relūcēre, reluxī",
    "ārdeō, ārdēre, ārsī, ārsum",
    "caleō, calēre, caluī, calitūrus"
  ],
  "the ham of the knee": [
    "poples, poplitis"
  ],
  "the hough": [
    "poples, poplitis"
  ],
  "the hock": [
    "poples, poplitis"
  ],
  "thrower": [
    "iaculātor, iaculātōris"
  ],
  "hurler": [
    "iaculātor, iaculātōris"
  ],
  "studying": [
    "commentātiō, commentātiōnis"
  ],
  "contemptuously": [
    "contemptim, contemptius, contemptissimē"
  ],
  "scornfully": [
    "contemptim, contemptius, contemptissimē"
  ],
  "drag or tear off or away": [
    "abripiō, abripere, abripuī, abreptum"
  ],
  "decide or settle": [
    "dīiūdicō, dīiūdicāre, dīiūdicāvī, dīiūdicātum"
  ],
  "margin": [
    "margō, marginis"
  ],
  "edge": [
    "margō, marginis",
    "fimbria, fimbriae",
    "praetexō, praetexere, praetexuī, praetextum"
  ],
  "laying waste": [
    "populābundus, populābunda, populābundum"
  ],
  "ravaging": [
    "vastātiō, vastātiōnis",
    "populābundus, populābunda, populābundum"
  ],
  "peony": [
    "paeōnia, paeōniae"
  ],
  "fringe": [
    "fimbria, fimbriae",
    "praetexō, praetexere, praetexuī, praetextum"
  ],
  "pure water": [
    "lympha, lymphae"
  ],
  "spring water": [
    "lympha, lymphae"
  ],
  "a small bundle or package": [
    "fasciculus, fasciculī"
  ],
  "a backward flow": [
    "refluxus, refluxī"
  ],
  "am stiff": [
    "torpeō, torpēre, torpuī"
  ],
  "numb": [
    "torpeō, torpēre, torpuī"
  ],
  "torpid or motionless": [
    "torpeō, torpēre, torpuī"
  ],
  "a nymph of the greek mythology": [
    "arethūsa, arethūsae"
  ],
  "peisistratus": [
    "pīsistratus, pīsistratī"
  ],
  "tyrant of athens": [
    "pīsistratus, pīsistratī"
  ],
  "flying": [
    "volātus, volātūs",
    "volātilis, volātile",
    "volucer, volucris, volucre"
  ],
  "ionia": [
    "iōnia, iōniae"
  ],
  "isthmus": [
    "isthmus, isthmī"
  ],
  "beat back": [
    "retundō, retundere, rettudī, retunsum, retūsum"
  ],
  "blindness": [
    "caecitās, caecitātis"
  ],
  "a male given name cognate to robert": [
    "robertus, robertī"
  ],
  "crotone": [
    "crotōn, crotōnis"
  ],
  "abolition": [
    "dissolūtiō, dissolūtiōnis"
  ],
  "starry": [
    "stellātus, stellāta, stellātum"
  ],
  "plumbline": [
    "perpendiculum, perpendiculī"
  ],
  "plummet": [
    "perpendiculum, perpendiculī"
  ],
  "lying down with face upwards": [
    "supīnus, supīna, supīnum"
  ],
  "supine": [
    "supīnus, supīna, supīnum"
  ],
  "a city in hispania tarraconensis": [
    "ilerda, ilerdae"
  ],
  "now lleida": [
    "ilerda, ilerdae"
  ],
  "a groom": [
    "spōnsus, spōnsī"
  ],
  "clay": [
    "Lutum",
    "Argilla",
    "fictilis, fictile"
  ],
  "weasel": [
    "mūstēla, mūstēlae"
  ],
  "scabrous": [
    "scaber, scabra, scabrum"
  ],
  "vine branch": [
    "trādux, trāducis"
  ],
  "rotten": [
    "puter, putris, putre",
    "pūtidus, pūtida, pūtidum",
    "putridus, putrida, putridum"
  ],
  "decaying": [
    "puter, putris, putre",
    "pūtidus, pūtida, pūtidum"
  ],
  "of or pertaining to a triumph": [
    "triumphālis, triumphāle"
  ],
  "sepulchre": [
    "capulus, capulī",
    "sepulchrum, sepulchrī"
  ],
  "pliability": [
    "mollitia, mollitiae",
    "mollitiēs, mollitiēī"
  ],
  "flexibility": [
    "mollitia, mollitiae",
    "mollitiēs, mollitiēī"
  ],
  "suppleness": [
    "mollitia, mollitiae",
    "mollitiēs, mollitiēī"
  ],
  "a disorderly motion": [
    "concursātiō, concursātiōnis"
  ],
  "convergence": [
    "concursātiō, concursātiōnis"
  ],
  "or meeting": [
    "concursātiō, concursātiōnis"
  ],
  "especially of people": [
    "concursātiō, concursātiōnis"
  ],
  "casting of lots": [
    "sortītiō, sortītiōnis"
  ],
  "unsteady": [
    "īnstabilis, īnstabile"
  ],
  "unstable": [
    "īnstabilis, īnstabile"
  ],
  "shaky": [
    "īnstabilis, īnstabile"
  ],
  "light-hearted": [
    "hilaris, hilare"
  ],
  "hunt by tracking": [
    "indāgō, indāgāre, indāgāvī, indāgātum"
  ],
  "cheapness": [
    "vīlitās, vīlitātis"
  ],
  "inexpensiveness": [
    "vīlitās, vīlitātis"
  ],
  "tree-like": [
    "arboreus, arborea, arboreum"
  ],
  "celebrated": [
    "fāmōsus, fāmōsa, fāmōsum",
    "conclāmātus, conclāmāta, conclāmātum"
  ],
  "well-known": [
    "conclāmātus, conclāmāta, conclāmātum"
  ],
  "comitial": [
    "comitiālis, comitiāle"
  ],
  "a female deer": [
    "cerva, cervae"
  ],
  "doe": [
    "cerva, cervae"
  ],
  "hind": [
    "cerva, cervae"
  ],
  "the gaping of a mouth": [
    "rictus, rictūs"
  ],
  "as when laughing or yawning": [
    "rictus, rictūs"
  ],
  "set fire to": [
    "adūrō, adūrere, adussī, adustum",
    "incendō, incendere, incendī, incēnsum"
  ],
  "parchment": [
    "pergamēnum, pergamēnī"
  ],
  "moss": [
    "mūscus, mūscī"
  ],
  "reversed": [
    "praeposterus, praepostera, praeposterum"
  ],
  "inverted": [
    "praeposterus, praepostera, praeposterum"
  ],
  "solitary": [
    "sōlitārius, sōlitāria, sōlitārium"
  ],
  "bearded": [
    "barbātus, barbāta, barbātum"
  ],
  "making": [
    "factūra, factūrae",
    "conditiō, conditiōnis",
    "cōnfectiō, cōnfectiōnis"
  ],
  "arranging": [
    "cōnfectiō, cōnfectiōnis",
    "ordinātiō, ordinātiōnis"
  ],
  "composing": [
    "cōnfectiō, cōnfectiōnis"
  ],
  "completing": [
    "cōnfectiō, cōnfectiōnis"
  ],
  "half-alive": [
    "sēmianimis, sēmianime"
  ],
  "beneficence": [
    "beneficentia, beneficentiae"
  ],
  "stolen": [
    "fūrtīvus, fūrtīva, fūrtīvum"
  ],
  "horned": [
    "corniger, cornigera, cornigerum"
  ],
  "having horns": [
    "corniger, cornigera, cornigerum"
  ],
  "raise or elevate": [
    "ēlevō, ēlevāre, ēlevāvī, ēlevātum"
  ],
  "plough or dig up": [
    "exarō, exarāre, exarāvī, exarātum"
  ],
  "of or pertaining to a month": [
    "mēnstruus, mēnstrua, mēnstruum"
  ],
  "feel pity": [
    "misereor, miserērī, miseritus sum"
  ],
  "roar or resound at": [
    "obstrepō, obstrepere, obstrepuī, obstrepitum"
  ],
  "from or on": [
    "dēpendeō, dēpendēre, dēpendī"
  ],
  "a town of thessaly situated on a tributary of the peneus": [
    "gomphī, gomphōrum"
  ],
  "wool-bearing": [
    "lāniger, lānigera, lānigerum"
  ],
  "fleecy": [
    "lāniger, lānigera, lānigerum"
  ],
  "yardarm": [
    "antemna, antemnae"
  ],
  "circumference": [
    "circumscriptiō, circumscriptiōnis"
  ],
  "cypress": [
    "cupressus, cupressī"
  ],
  "an elegant or skillful joining of several things": [
    "concinnitās, concinnitātis"
  ],
  "beauty of style": [
    "concinnitās, concinnitātis"
  ],
  "unlock": [
    "Aperi",
    "reserō, reserāre, reserāvī, reserātum"
  ],
  "the land around or within the boundaries of a town": [
    "territōrium, territōriī"
  ],
  "look through or about": [
    "dispiciō, dispicere, dispexī, dispectum"
  ],
  "pecuniary": [
    "pecūniārius, pecūniāria, pecūniārium"
  ],
  "astrologer": [
    "astrologus, astrologī"
  ],
  "a hand-sling )": [
    "funda, fundae"
  ],
  "push or shove": [
    "trūdō, trūdere, trūsī, trūsum"
  ],
  "galloping": [
    "quadrupēs, quadrupedis"
  ],
  "inequity": [
    "iniūstitia, iniūstitiae"
  ],
  "the ankle or anklebone": [
    "tālus, tālī"
  ],
  "talus": [
    "tālus, tālī"
  ],
  "knucklebone": [
    "tālus, tālī"
  ],
  "chaff": [
    "palea, paleae"
  ],
  "of quick perception": [
    "sagāx, sagācis"
  ],
  "having acute senses": [
    "sagāx, sagācis"
  ],
  "keen-scented": [
    "sagāx, sagācis"
  ],
  "unhappily": [
    "īnfēlīciter, īnfēlīcius, īnfēlīcissimē"
  ],
  "adoption": [
    "adoptiō, adoptiōnis"
  ],
  "seminary": [
    "sēminārium, sēmināriī"
  ],
  "detract from": [
    "dērogō, dērogāre, dērogāvī, dērogātum"
  ],
  "shake repeatedly": [
    "quassō, quassāre, quassāvī, quassātum"
  ],
  "quake": [
    "intremō, intremere, intremuī",
    "quassō, quassāre, quassāvī, quassātum"
  ],
  "a person who averts by praying": [
    "dēprecātor, dēprecātōris"
  ],
  "interceder": [
    "dēprecātor, dēprecātōris"
  ],
  "become swollen": [
    "intumēscō, intumēscere, intumuī"
  ],
  "intermission": [
    "intermissiō, intermissiōnis"
  ],
  "interruption": [
    "intermissiō, intermissiōnis",
    "interpellātiō, interpellātiōnis"
  ],
  "only just": [
    "vixdum",
    "sōlummodo"
  ],
  "sheath": [
    "vāgīna, vāgīnae"
  ],
  "scabbard": [
    "vāgīna, vāgīnae"
  ],
  "small pipe or tube": [
    "tubulus, tubulī), second declension (diminutive of: tubus",
    "tubulus, tubulī); second declension (Diminutive of: tubus"
  ],
  "ember": [
    "favilla, favillae"
  ],
  "cinder": [
    "favilla, favillae"
  ],
  "ash": [
    "favilla, favillae"
  ],
  "seeker": [
    "Quaestor",
    "quaesītor, quaesītōris"
  ],
  "searcher": [
    "quaesītor, quaesītōris"
  ],
  "grassy": [
    "herbidus, herbida, herbidum",
    "herbōsus, herbōsa, herbōsum",
    "grāmineus, grāminea, grāmineum"
  ],
  "fasten together": [
    "cōnectō, cōnectere, cōnexuī, cōnexum",
    "cōnfīgō, cōnfīgere, cōnfīxī, cōnfīxum"
  ],
  "the state of being swollen": [
    "tumor, tumōris"
  ],
  "creative": [
    "effectīvus, effectīva, effectīvum",
    "operātīvus, operātīva, operātīvum"
  ],
  "formative": [
    "operātīvus, operātīva, operātīvum"
  ],
  "fill to repletion": [
    "saturō, saturāre, saturāvī, saturātum"
  ],
  "icarus": [
    "īcarus, īcarī"
  ],
  "of every kind": [
    "omnigenus, omnigena, omnigenum"
  ],
  "surface": [
    "superficiēs, superficieī"
  ],
  "a shellfish used as a source of the dye tyrian purple": [
    "mūrex, mūricis"
  ],
  "the purple-fish": [
    "mūrex, mūricis"
  ],
  "wicker basket": [
    "calathus, calathī",
    "canistrum, canistrī"
  ],
  "flexible": [
    "flexibilis, flexibile"
  ],
  "pliant": [
    "flexibilis, flexibile"
  ],
  "able to be bent": [
    "flexibilis, flexibile"
  ],
  "circular": [
    "rotundus, rotunda, rotundum"
  ],
  "rabid": [
    "rabidus, rabida, rabidum"
  ],
  "bush": [
    "dūmus, dūmī"
  ],
  "shrub": [
    "dūmus, dūmī"
  ],
  "a fourth part of something": [
    "quadrāns, quadrantis"
  ],
  "farthing": [
    "quadrāns, quadrantis"
  ],
  "a bruise": [
    "līvor, līvōris"
  ],
  "an act or instance of staying": [
    "mānsiō, mānsiōnis"
  ],
  "cut back": [
    "recīdō, recīdere, recīdī, recīsum"
  ],
  "from the same place": [
    "indidem"
  ],
  "thing or matter": [
    "indidem"
  ],
  "arbitrator": [
    "disceptātor, disceptātōris"
  ],
  "umpire": [
    "disceptātor, disceptātōris"
  ],
  "fall between": [
    "intercidō, intercidere, intercidī"
  ],
  "lycaonia": [
    "lycāonia, lycāoniae"
  ],
  "an oar": [
    "tōnsa, tōnsae"
  ],
  "become known or noted": [
    "innōtēscō, innōtēscere, innōtuī"
  ],
  "tenacious of life": [
    "vīvāx, vīvācis"
  ],
  "long-lived": [
    "vīvāx, vīvācis"
  ],
  "vivacious": [
    "vīvāx, vīvācis"
  ],
  "infula": [
    "infula, infulae"
  ],
  "diadem": [
    "diadēma, diadēmatis"
  ],
  "unruly": [
    "effrēnātus, effrēnāta, effrēnātum"
  ],
  "or consisting of a stellar object or objectsof or belonging to the starsfull of stars": [
    "sīdereus, sīderea, sīdereum"
  ],
  "starryof or belonging to the stellar constellationsof or belonging to the sun": [
    "sīdereus, sīderea, sīdereum"
  ],
  "or hair": [
    "caesariēs, caesariēī"
  ],
  "become aged": [
    "senēscō, senēscere, senuī"
  ],
  "grow old": [
    "senēscō, senēscere, senuī"
  ],
  "prophetic": [
    "prōvidus, prōvida, prōvidum",
    "fātidicus, fātidica, fātidicum"
  ],
  "a female snake": [
    "colubra, colubrae"
  ],
  "serpent": [
    "dracō, dracōnis",
    "coluber, colubrī",
    "colubra, colubrae",
    "serpēns, serpentis",
    "dracō, dracōnis (m)"
  ],
  "thrust or drive out": [
    "extrūdō, extrūdere, extrūsī, extrūsum"
  ],
  "letter of recommendation": [
    "dīplōma, dīplōmatis"
  ],
  "grandmother": [
    "avia, aviae"
  ],
  "having a passage through": [
    "pervius, pervia, pervium"
  ],
  "traversable": [
    "pervius, pervia, pervium"
  ],
  "penetrable": [
    "pervius, pervia, pervium"
  ],
  "windy": [
    "ventōsus, ventōsa, ventōsum"
  ],
  "a raven": [
    "corvus, corvī"
  ],
  "a bird associated with prophecy and sacred to apollo": [
    "corvus, corvī"
  ],
  "rub or chafe again": [
    "refricō, refricāre, refricuī, refricātum"
  ],
  "funeral rites": [
    "exequia, exequiae",
    "īnferia, īnferiae",
    "exsequiae, exsequiārum"
  ],
  "rattling": [
    "crepitus, crepitūs"
  ],
  "creaking": [
    "crepitus, crepitūs"
  ],
  "rustling": [
    "crepitus, crepitūs"
  ],
  "clattering": [
    "crepitus, crepitūs"
  ],
  "gloriously": [
    "glōriōsē, glōriōsius, glōriōsissimē"
  ],
  "magnificently": [
    "glōriōsē, glōriōsius, glōriōsissimē"
  ],
  "gentle warmth": [
    "tepor, tepōris"
  ],
  "tepidity": [
    "tepor, tepōris"
  ],
  "frantic": [
    "furibundus, furibunda, furibundum"
  ],
  "of or pertaining to a vow": [
    "vōtīvus, vōtīva, vōtīvum"
  ],
  "promised by a vow": [
    "vōtīvus, vōtīva, vōtīvum"
  ],
  "given in consequence of a vow": [
    "vōtīvus, vōtīva, vōtīvum"
  ],
  "vowed": [
    "vōtīvus, vōtīva, vōtīvum"
  ],
  "votive": [
    "vōtīvus, vōtīva, vōtīvum"
  ],
  "despiser": [
    "contemptor, contemptōris"
  ],
  "contemner": [
    "contemptor, contemptōris"
  ],
  "tear or snip off": [
    "dēcerpō, dēcerpere, dēcerpsī, dēcerptum"
  ],
  "glassy": [
    "vitreus, vitrea, vitreum"
  ],
  "translucent": [
    "vitreus, vitrea, vitreum"
  ],
  "exclusive": [
    "exclūsīvus, exclūsīva, exclūsīvum"
  ],
  "granddaughter": [
    "neptis, neptis"
  ],
  "drive away": [
    "abigō, abigere, abēgī, abāctum",
    "absterreō, absterrēre, absterruī, absterritum"
  ],
  "eat or chew up": [
    "comedō, comedere, comēsse, comēdī, comēsum"
  ],
  "carrying weapons": [
    "armifer, armifera, armiferum",
    "armiger, armigera, armigerum"
  ],
  "carrying armor": [
    "armiger, armigera, armigerum"
  ],
  "carrying armour": [
    "armiger, armigera, armigerum"
  ],
  "comic": [
    "cōmicus, cōmica, cōmicum",
    "cōmoedus, cōmoeda, cōmoedum"
  ],
  "stepfather": [
    "vītricus, vītricī"
  ],
  "alesia": [
    "alesia, alesiae"
  ],
  "rascal": [
    "nebulō, nebulōnis"
  ],
  "scoundrel": [
    "nebulō, nebulōnis"
  ],
  "matuta": [
    "mātūta, mātūtae"
  ],
  "the goddess of morning or dawn": [
    "mātūta, mātūtae"
  ],
  "sundered": [
    "seorsus, seorsa, seorsum"
  ],
  "small hammer": [
    "malleolus, malleolī), second declension (diminutive of: malleus",
    "malleolus, malleolī); second declension (Diminutive of: malleus"
  ],
  "figurine": [
    "sigillum, sigillī"
  ],
  "remove from": [
    "āmoveō, āmovēre, āmōvī, āmōtum"
  ],
  "put or take away": [
    "āmoveō, āmovēre, āmōvī, āmōtum"
  ],
  "dining room": [
    "triclīnium, triclīniī"
  ],
  "where three couches are laid out for dining around a small serving table": [
    "triclīnium, triclīniī"
  ],
  "of what sort": [
    "cuicuimodi"
  ],
  "soever": [
    "cuicuimodi"
  ],
  "crying": [
    "plōrātus, plōrātūs"
  ],
  "lamenting": [
    "plōrātus, plōrātūs"
  ],
  "opulence": [
    "opulentia, opulentiae"
  ],
  "potter": [
    "figulus, figulī"
  ],
  "triply": [
    "trifāriam",
    "tripliciter"
  ],
  "relating to the winter solstice": [
    "brūmālis, brūmāle"
  ],
  "happily": [
    "beātē, beātius, beātissimē"
  ],
  "a small stone": [
    "lapillus, lapillī"
  ],
  "underground": [
    "subterrāneus, subterrānea, subterrāneum"
  ],
  "subterranean": [
    "subterrāneus, subterrānea, subterrāneum"
  ],
  "monarchy": [
    "monarchia, monarchiae"
  ],
  "unappeasable": [
    "implācābilis, implācābile"
  ],
  "archaic form of sulphur": [
    "sulpur, sulpuris"
  ],
  "ferocity": [
    "ferōcia, ferōciae",
    "saevitia, saevitiae",
    "ferōcitās, ferōcitātis"
  ],
  "retrieve": [
    "reparō, reparāre, reparāvī, reparātum"
  ],
  "a roman god who protected flocks": [
    "lupercus, lupercī"
  ],
  "in some sources identified with faunus in the aspect of innus": [
    "lupercus, lupercī"
  ],
  "and by extension as the equivalent of the greek πὰν λυκαῖος": [
    "lupercus, lupercī"
  ],
  "silly": [
    "excors, excordis",
    "fatuus, fatua, fatuum"
  ],
  "simple": [
    "Simplex",
    "fatuus, fatua, fatuum"
  ],
  "instructor": [
    "ērudītor, ērudītōris",
    "praeceptor, praeceptōris"
  ],
  "preceptor": [
    "monitor, monitōris",
    "praeceptor, praeceptōris"
  ],
  "slime": [
    "Slime",
    "spūma, spūmae"
  ],
  "a panther": [
    "panthēra, panthērae"
  ],
  "first attempt": [
    "rudīmentum, rudīmentī"
  ],
  "filthiness": [
    "foeditās, foeditātis"
  ],
  "incitement": [
    "incitātiō, incitātiōnis"
  ],
  "crowned with laurels": [
    "laureātus, laureāta, laureātum"
  ],
  "laureate": [
    "laureātus, laureāta, laureātum"
  ],
  "having been crowned with laurels": [
    "laureātus, laureāta, laureātum"
  ],
  "place next to": [
    "substituō, substituere, substituī, substitutum"
  ],
  "or instead of": [
    "substituō, substituere, substituī, substitutum"
  ],
  "hoar": [
    "cānitiēs, cānitiēī"
  ],
  "hoariness": [
    "cānitiēs, cānitiēī"
  ],
  "take or receive something with knowledge": [
    "ascīscō, ascīscere, ascīvī, ascītum"
  ],
  "condensation": [
    "condensātiō, condensātiōnis"
  ],
  "of or pertaining to prostitutes": [
    "meretrīcius, meretrīcia, meretrīcium"
  ],
  "meretricious": [
    "meretrīcius, meretrīcia, meretrīcium"
  ],
  "a burning coal": [
    "prūna, prūnae"
  ],
  "live coal": [
    "prūna, prūnae"
  ],
  "glowing charcoal": [
    "prūna, prūnae"
  ],
  "break up or open": [
    "refringō, refringere, refrēgī, refractum"
  ],
  "the sixth part of a measure or weight": [
    "sextārius, sextāriī or sextārī"
  ],
  "an ancient city of umbria": [
    "ameria, ameriae"
  ],
  "now amelia": [
    "ameria, ameriae"
  ],
  "thick-footed": [
    "crassipēs, crassipedis"
  ],
  "an iambus": [
    "iambus, iambī"
  ],
  "bring or draw towards": [
    "obdūcō, obdūcere, obdūxī, obductum"
  ],
  "forward or against": [
    "obdūcō, obdūcere, obdūxī, obductum"
  ],
  "choosing": [
    "optiō, optiōnis"
  ],
  "preference": [
    "optiō, optiōnis"
  ],
  "option": [
    "optiō, optiōnis"
  ],
  "a couch for the gods": [
    "pulvīnar, pulvīnaris"
  ],
  "a lip": [
    "lābellum, lābellī"
  ],
  "mercifully": [
    "clēmenter, clēmentius, clēmentissimē"
  ],
  "a fitting together": [
    "structūra, structūrae"
  ],
  "adaptation": [
    "structūra, structūrae"
  ],
  "adjustment": [
    "structūra, structūrae"
  ],
  "supplication": [
    "obsecrātiō, obsecrātiōnis"
  ],
  "bald": [
    "calvus, calva, calvum"
  ],
  "hairless": [
    "calvus, calva, calvum"
  ],
  "a type of boxing glove made from a strap of bull's hide loaded with iron or lead balls": [
    "caestus, caestūs"
  ],
  "the art of magic": [
    "magicē, magicēs"
  ],
  "sorcery": [
    "magīa, magīae",
    "magicē, magicēs"
  ],
  "magic": [
    "Magic",
    "magīa, magīae",
    "magicē, magicēs",
    "magicus, magica, magicum"
  ],
  "from above or overhead": [
    "dēsuper"
  ],
  "full of years": [
    "annōsus, annōsa, annōsum"
  ],
  "unhurt": [
    "inviolātus, inviolāta, inviolātum"
  ],
  "diminutive of globus": [
    "globulus, globulī"
  ],
  "am destroyed or ruined": [
    "dēperdō, dēperdere, dēperdidī, dēperditum"
  ],
  "gergovia": [
    "gergovia, gergoviae"
  ],
  "look all around": [
    "circumspectō, circumspectāre, circumspectāvī, circumspectātum"
  ],
  "a city of thessaly and legendary residence of admetus and eumelus": [
    "pherae, pherārum"
  ],
  "from memory": [
    "memoriter"
  ],
  "by heart": [
    "memoriter"
  ],
  "inability to speak": [
    "īnfantia, īnfantiae"
  ],
  "muteness": [
    "īnfantia, īnfantiae"
  ],
  "speechlessness": [
    "īnfantia, īnfantiae"
  ],
  "want of eloquence": [
    "īnfantia, īnfantiae"
  ],
  "ineloquence": [
    "īnfantia, īnfantiae"
  ],
  "the act of drawing or draining": [
    "haustus, haustūs"
  ],
  "a long or slender piece of wood or metal": [
    "tālea, tāleae"
  ],
  "stake": [
    "pālus, pāli",
    "sudis, sudis",
    "tālea, tāleae"
  ],
  "bar": [
    "tālea, tāleae",
    "clausūra, clausūrae"
  ],
  "rhetorical": [
    "rhētoricus, rhētorica, rhētoricum, rhētoricōteros"
  ],
  "or or pertaining to rhetoric or a rhetoritician": [
    "rhētoricus, rhētorica, rhētoricum, rhētoricōteros"
  ],
  "a metal disc or boss": [
    "phalerae, phalerārum"
  ],
  "especially worn as a military ornament on the breast": [
    "phalerae, phalerārum"
  ],
  "a female given name": [
    "fidēs, fidēī",
    "marīa, marīae",
    "eurydicē, eurydicēs"
  ],
  "warm or heat": [
    "calefaciō, calefacere, calefēcī, calefactum"
  ],
  "charybdis": [
    "charybdis, charybdis"
  ],
  "chaos": [
    "chaos, chaī"
  ],
  "hereditary": [
    "hērēditārius, hērēditāria, hērēditārium"
  ],
  "inherited": [
    "hērēditārius, hērēditāria, hērēditārium"
  ],
  "late-born": [
    "chordus, chorda, chordum"
  ],
  "palatine": [
    "palātīnus, palātīna, palātīnum"
  ],
  "the simois": [
    "simoīs, simoentis or simoentos"
  ],
  "a river of the trojan plain": [
    "simoīs, simoentis or simoentos"
  ],
  "a cousin-german": [
    "patruēlis, patruēlis"
  ],
  "first cousin on the father's side": [
    "patruēlis, patruēlis"
  ],
  "the child of one's father's brother": [
    "patruēlis, patruēlis"
  ],
  "a city of thrace situated on the river schoenus and famous for its wine": [
    "marōnēa, marōnēae"
  ],
  "count to or out to": [
    "adnumerō, adnumerāre, adnumerāvī, adnumerātum"
  ],
  "put into a person's account": [
    "adnumerō, adnumerāre, adnumerāvī, adnumerātum"
  ],
  "efficacious": [
    "efficāx, efficācis"
  ],
  "effectual": [
    "efficāx, efficācis"
  ],
  "efficient": [
    "efficāx, efficācis"
  ],
  "of or pertaining to young animals": [
    "pullārius, pullāria, pullārium"
  ],
  "anvil": [
    "Incus",
    "incūs, incūdis"
  ],
  "a thick": [
    "laena, laenae"
  ],
  "often richly decorated woolen cloak worn over a toga or pallium": [
    "laena, laenae"
  ],
  "usually fastened by a pin": [
    "laena, laenae"
  ],
  "on two accounts": [
    "dupliciter"
  ],
  "in two ways or parts": [
    "dupliciter"
  ],
  "that turns itself around": [
    "volūbilis, volūbile"
  ],
  "turning": [
    "volūbilis, volūbile"
  ],
  "spinning": [
    "volūbilis, volūbile"
  ],
  "whirling": [
    "volūbilis, volūbile"
  ],
  "rolling": [
    "volūbilis, volūbile"
  ],
  "revolving": [
    "volūbilis, volūbile"
  ],
  "peevish": [
    "mōrōsus, mōrōsa, mōrōsum",
    "stomachor, stomachārī, stomachātus sum"
  ],
  "wayward": [
    "mōrōsus, mōrōsa, mōrōsum"
  ],
  "capricious": [
    "incōnstāns, incōnstantis",
    "mōrōsus, mōrōsa, mōrōsum"
  ],
  "of or pertaining to lethe": [
    "lēthaeus, lēthaea, lēthaeum"
  ],
  "lethean": [
    "lēthaeus, lēthaea, lēthaeum"
  ],
  "two-pronged": [
    "bidēns, bidentis"
  ],
  "equipoise": [
    "exāminātiō, exāminātiōnis"
  ],
  "equilibrium": [
    "Equilibrium",
    "exāminātiō, exāminātiōnis"
  ],
  "an important city of aetolia famous for its temple of apollo": [
    "thermum, thermī"
  ],
  "send forth or away": [
    "āmandō, āmandāre, āmandāvī, āmandātum"
  ],
  "bull or ox": [
    "taureus, taurea, taureum"
  ],
  "gainful": [
    "quaestuōsus, quaestuōsa, quaestuōsum"
  ],
  "profitable": [
    "quaestuōsus, quaestuōsa, quaestuōsum"
  ],
  "lucrative": [
    "quaestuōsus, quaestuōsa, quaestuōsum"
  ],
  "of or pertaining to marriage": [
    "geniālis, geniāle"
  ],
  "nuptial": [
    "geniālis, geniāle",
    "nūptiālis, nūptiāle"
  ],
  "genial": [
    "geniālis, geniāle"
  ],
  "germanic": [
    "germānicus, germānica, germānicum"
  ],
  "that can be easily moved": [
    "agilis, agile"
  ],
  "easily movable": [
    "agilis, agile"
  ],
  "glowing": [
    "fervidus, fervida, fervidum"
  ],
  "boiling hot": [
    "fervidus, fervida, fervidum"
  ],
  "fiery": [
    "pyrius, pyria, pyrium",
    "fervidus, fervida, fervidum",
    "flammeus, flammea, flammeum"
  ],
  "on the spot": [
    "īlicō"
  ],
  "propagation": [
    "prōpāgātiō, prōpāgātiōnis"
  ],
  "askew": [
    "līmus, līma, līmum"
  ],
  "askance": [
    "līmus, līma, līmum"
  ],
  "sideways": [
    "līmus, līma, līmum"
  ],
  "transfer or sell": [
    "mancipō, mancipāre, mancipāvī, mancipātum"
  ],
  "the capital of messenia": [
    "messēnē, messēnēs"
  ],
  "built under the direction of epaminondas": [
    "messēnē, messēnēs"
  ],
  "crocus": [
    "crocum, crocī"
  ],
  "chlamys": [
    "chlamys, chlamydos or chlamydis"
  ],
  "bounds": [
    "pōmērium, pōmēriī"
  ],
  "limits": [
    "pōmērium, pōmēriī"
  ],
  "especially the space either side of town walls left free of buildings": [
    "pōmērium, pōmēriī"
  ],
  "a proposition that follows necessarily": [
    "cōnexum, cōnexī"
  ],
  "a necessary consequence": [
    "cōnexum, cōnexī"
  ],
  "a logical entailment": [
    "cōnexum, cōnexī"
  ],
  "an inevitable inference": [
    "cōnexum, cōnexī"
  ],
  "perpetuity": [
    "perpetuitās, perpetuitātis"
  ],
  "continuity": [
    "perpetuitās, perpetuitātis"
  ],
  "place of assembly": [
    "conciliābulum, conciliābulī"
  ],
  "good health": [
    "salūbritās, salūbritātis"
  ],
  "suffrage": [
    "suffrāgātiō, suffrāgātiōnis"
  ],
  "voting": [
    "suffrāgātiō, suffrāgātiōnis"
  ],
  "a male given name cognate to louis": [
    "lūdovīcus, lūdovīcī"
  ],
  "incredibly": [
    "incrēdibiliter"
  ],
  "a pumice stone": [
    "pūmex, pūmicis"
  ],
  "twist": [
    "tortus, tortūs",
    "volūtō, volūtāre, volūtāvī, volūtātum",
    "intorqueō, intorquēre, intorsī, intortum",
    "retorqueō, retorquēre, retorsī, retortum",
    "contorqueō, contorquēre, contorsī, contortum"
  ],
  "swing or whirl": [
    "contorqueō, contorquēre, contorsī, contortum"
  ],
  "contort": [
    "intorqueō, intorquēre, intorsī, intortum",
    "contorqueō, contorquēre, contorsī, contortum"
  ],
  "tigris": [
    "tigris, tigridis"
  ],
  "lectisternium": [
    "lectisternium, lectisterniī"
  ],
  "syllogism": [
    "syllogismus, syllogismī"
  ],
  "a small pirate galley": [
    "myoparō, myoparōnis"
  ],
  "gore or similar putrid": [
    "tābum, tābī"
  ],
  "viscous fluid": [
    "tābum, tābī"
  ],
  "a sort of hawk or falcon": [
    "būteō, būteōnis"
  ],
  "practical": [
    "practicus, practica, practicum"
  ],
  "cattle": [
    "pecuārius, pecuāria, pecuārium"
  ],
  "unshaven": [
    "intōnsus, intōnsa, intōnsum"
  ],
  "unshorn": [
    "intōnsus, intōnsa, intōnsum"
  ],
  "carry through or accomplish": [
    "perpetrō, perpetrāre, perpetrāvī, perpetrātum"
  ],
  "analogy": [
    "analogia, analogiae"
  ],
  "temptation": [
    "scandalum, scandalī",
    "temptatiō, temptatiōnis"
  ],
  "climbing": [
    "camerārius, camerāria, camerārium"
  ],
  "creeping": [
    "rēptilis, rēptile",
    "camerārius, camerāria, camerārium"
  ],
  "generally": [
    "vulgō",
    "generāliter"
  ],
  "in general": [
    "generāliter"
  ],
  "despatch": [
    "facessō, facessere, facessī, facessītum"
  ],
  "execute or accomplish eagerly": [
    "facessō, facessere, facessī, facessītum"
  ],
  "memorable": [
    "notābilis, notābile"
  ],
  "the act of putting together or setting up": [
    "collocātiō, collocātiōnis"
  ],
  "placing": [
    "collocātiō, collocātiōnis"
  ],
  "collocation": [
    "collocātiō, collocātiōnis"
  ],
  "boiling heat": [
    "fervor, fervōris"
  ],
  "a tribe who lived in a mountainous district of thessaly": [
    "dolopēs, dolopum"
  ],
  "a tribe of gallia belgica": [
    "ubiī, ubiōrum",
    "morinī, morinōrum"
  ],
  "which dwelt on the rhine": [
    "ubiī, ubiōrum"
  ],
  "myrtle": [
    "myrtum, myrtī",
    "myrtus, myrtī"
  ],
  "chastisement": [
    "castīgātiō, castīgātiōnis"
  ],
  "correction": [
    "corrēctiō, corrēctiōnis",
    "ēmendātiō, ēmendātiōnis",
    "castīgātiō, castīgātiōnis"
  ],
  "revisit": [
    "revīsō, revīsere, revīsī, revīsum",
    "repetō, repetere, repetīvī, repetītum"
  ],
  "garrulous": [
    "garrulus, garrula, garrulum"
  ],
  "rains": [
    "pluō, pluere, pluī, plūvī"
  ],
  "pool": [
    "piscīna, piscīnae"
  ],
  "rotting": [
    "pūtrefactiō, pūtrefactiōnis"
  ],
  "putrefaction": [
    "tābes, tābis",
    "pūtrēdō, pūtrēdinis",
    "pūtrefactiō, pūtrefactiōnis"
  ],
  "move or turn away": [
    "dēmoveō, dēmovēre, dēmōvī, dēmōtum"
  ],
  "dislodge": [
    "dēmoveō, dēmovēre, dēmōvī, dēmōtum"
  ],
  "a head": [
    "spīca, spīcae"
  ],
  "spike": [
    "spīca, spīcae"
  ],
  "modern": [
    "modernus, moderna, modernum"
  ],
  "a relaxation": [
    "laxāmentum, laxāmentī"
  ],
  "alleviation": [
    "laxāmentum, laxāmentī"
  ],
  "retard": [
    "aboleō, abolēre, abolēvī, abolitum"
  ],
  "check the growth of": [
    "aboleō, abolēre, abolēvī, abolitum"
  ],
  "a crab": [
    "cancer, cancrī"
  ],
  "especially:unseasonable": [
    "intempestus, intempesta, intempestum"
  ],
  "particularly unseasonably stormy weatherunpropitious": [
    "intempestus, intempesta, intempestum"
  ],
  "very pleasant": [
    "periūcundus, periūcunda, periūcundum"
  ],
  "the river loire in france": [
    "liger, ligeris"
  ],
  "stupefaction": [
    "torpor, torpōris"
  ],
  "as much as possible": [
    "perquam"
  ],
  "extremely": [
    "perquam"
  ],
  "serenity": [
    "serēnitās, serēnitātis"
  ],
  "fine weather": [
    "serēnitās, serēnitātis"
  ],
  "carry out": [
    "efferō, efferre, extulī, ēlātum",
    "exportō, exportāre, exportāvī, exportātum"
  ],
  "export": [
    "exportō, exportāre, exportāvī, exportātum"
  ],
  "symmetry": [
    "convenientia, convenientiae"
  ],
  "conformity": [
    "convenientia, convenientiae"
  ],
  "an italic tribe who inhabited a region on the coast of abruzzo": [
    "marrūcīni, marrūcīnōrum"
  ],
  "wasteful": [
    "prōdigus, prōdiga, prōdigum"
  ],
  "prodigal": [
    "prōdigus, prōdiga, prōdigum"
  ],
  "change or alter throughout or completely": [
    "permūtō, permūtāre, permūtāvī, permūtātum"
  ],
  "supported by": [
    "subnixus, subnixa, subnixum"
  ],
  "bring into contact": [
    "contāminō, contāmināre, contāmināvī, contāminātum"
  ],
  "prophetically": [
    "dīvīnē, dīvīnius, dīvīnissimē"
  ],
  "by divine inspiration": [
    "dīvīnē, dīvīnius, dīvīnissimē"
  ],
  "sprig": [
    "surculus, surculī"
  ],
  "pertaining to the highest class of citizen": [
    "classicus, classica, classicum"
  ],
  "of or pertaining to mars": [
    "martiālis, martiāle"
  ],
  "current": [
    "fluentum, fluentī"
  ],
  "metaponto": [
    "metapontum, metapontī"
  ],
  "perceptible": [
    "sēnsibilis, sēnsibile"
  ],
  "sensible": [
    "sēnsibilis, sēnsibile",
    "sēnsātus, sēnsāta, sēnsātum"
  ],
  "a river of thrace": [
    "hebrus, hebrī"
  ],
  "now the maritsa": [
    "hebrus, hebrī"
  ],
  "tangent": [
    "tangēns, tangēntis"
  ],
  "placable": [
    "plācābilis, plācābile"
  ],
  "a type of wart": [
    "thymus, thymī"
  ],
  "mime actress": [
    "mīma, mīmae"
  ],
  "croesus": [
    "croesus, croesī"
  ],
  "duties": [
    "mūnia, mūniōrum"
  ],
  "functions": [
    "mūnia, mūniōrum"
  ],
  "deliberative": [
    "dēlīberātīvus, dēlīberātīva, dēlīberātīvum"
  ],
  "become faint": [
    "languēscō, languēscere, languī"
  ],
  "languid or sick": [
    "languēscō, languēscere, languī"
  ],
  "dignify": [
    "honestō, honestāre, honestāvī, honestātum"
  ],
  "clothe with honor": [
    "honestō, honestāre, honestāvī, honestātum"
  ],
  "branch of a plant": [
    "scōpa, scōpae"
  ],
  "equipped with or wearing a quiver": [
    "pharetrātus, pharetrāta, pharetrātum"
  ],
  "quivered": [
    "pharetrātus, pharetrāta, pharetrātum"
  ],
  "she-wolf": [
    "lupa, lupae"
  ],
  "draw or bring forth or out ": [
    "prōtrahō, prōtrahere, prōtraxī, prōtractum"
  ],
  "fragrant": [
    "odōrātus, odōrāta, odōrātum"
  ],
  "unsworn": [
    "iniūrātus, iniūrāta, iniūrātum"
  ],
  "miry": [
    "līmōsus, līmōsa, līmōsum"
  ],
  "muddy": [
    "līmōsus, līmōsa, līmōsum"
  ],
  "settlement": [
    "colōnia, colōniae",
    "dēcīsio, dēcīsiōnis"
  ],
  "a massacre": [
    "occīdiō, occīdiōnis"
  ],
  "utter destruction": [
    "occīdiō, occīdiōnis"
  ],
  "extermination": [
    "occīdiō, occīdiōnis"
  ],
  "solstice": [
    "sōlstitium, sōlstitiī"
  ],
  "the act of touching": [
    "contāctus, contāctūs"
  ],
  "dawn": [
    "aurōra, aurōrae"
  ],
  "sunrise": [
    "oriens solis",
    "aurōra, aurōrae"
  ],
  "ephesian": [
    "ephesius, ephesia, ephesium"
  ],
  "subscriber": [
    "subscrīptor, subscrīptōris"
  ],
  "a female doctor": [
    "medica, medicae"
  ],
  "healer": [
    "medica, medicae"
  ],
  "crowd or press together": [
    "stīpō, stīpāre, stīpāvī, stīpātum"
  ],
  "dash against": [
    "impingō, impingere, impēgī, impāctum"
  ],
  "unanimous": [
    "cōnsentiens, cōnsentientis"
  ],
  "that which removes one from ignorance": [
    "ērudītiō, ērudītiōnis"
  ],
  "whetherinstruction": [
    "ērudītiō, ērudītiōnis"
  ],
  "educationerudition": [
    "ērudītiō, ērudītiōnis"
  ],
  "learning": [
    "ērudītiō, ērudītiōnis"
  ],
  "knowledge1756": [
    "ērudītiō, ērudītiōnis"
  ],
  "johann matthias gesner:primae lineae isagoges in eruditionem universalemintroductions of a first line into universal knowledge": [
    "ērudītiō, ērudītiōnis"
  ],
  "an englishman": [
    "anglicus, anglicī"
  ],
  "burn up": [
    "combūrō, combūrere, combussī, combustum"
  ],
  "twilight": [
    "crepusculum, crepusculī"
  ],
  "dusk": [
    "crepusculum, crepusculī"
  ],
  "swallow down": [
    "absorbeō, absorbēre, absorbuī, absorptum"
  ],
  "make thin": [
    "tenuō, tenuāre, tenuāvi, tenuātum",
    "attenuō, attenuāre, attenuāvī, attenuātum"
  ],
  "kindly": [
    "benigniter, benignius, benignissimē"
  ],
  "benignly": [
    "benigniter, benignius, benignissimē"
  ],
  "popularly": [
    "populāriter"
  ],
  "populistically": [
    "populāriter"
  ],
  "mosaic": [
    "emblēma, emblēmatis"
  ],
  "worn out": [
    "effētus, effēta, effētum",
    "obsolētus, obsolēta, obsolētum"
  ],
  "thrown off": [
    "obsolētus, obsolēta, obsolētum"
  ],
  "make wider": [
    "ampliō, ampliāre, ampliāvī, ampliātum"
  ],
  "the egyptian lotus flower": [
    "lōtos, lōtī"
  ],
  "nymphaea caerulea": [
    "lōtos, lōtī"
  ],
  "the act of approaching or going toward": [
    "obitus, obitūs"
  ],
  "neptunium": [
    "neptūnium, neptūniī"
  ],
  "diminution": [
    "dēminūtiō, dēminūtiōnis"
  ],
  "decrease": [
    "dēminūtiō, dēminūtiōnis",
    "dēcrēscō, dēcrēscere, dēcrēvī, dēcrētum"
  ],
  "lessening": [
    "dēminūtiō, dēminūtiōnis"
  ],
  "abatement": [
    "dēminūtiō, dēminūtiōnis"
  ],
  "a means of transport": [
    "vehiculum, vehiculī"
  ],
  "vehicle": [
    "vehiculum, vehiculī"
  ],
  "conveyance": [
    "vehiculum, vehiculī"
  ],
  "inundate": [
    "inundō, inundāre, inundāvī, inundātum"
  ],
  "flood": [
    "dīluvium, dīluviī",
    "inundātiō, inundātiōnis",
    "inundō, inundāre, inundāvī, inundātum"
  ],
  "expansive": [
    "expansivus, expansiva, expansivum"
  ],
  "an implement": [
    "organum, organī"
  ],
  "make a noise": [
    "strepō, strepere, strepuī, strepitum"
  ],
  "befoul": [
    "inquinō, inquināre, inquināvī, inquinātum"
  ],
  "treasury": [
    "gaza, gazae",
    "aerārium -ī n"
  ],
  "drink in": [
    "imbibō, imbibere, imbibī, imbitum"
  ],
  "imbibe": [
    "imbibō, imbibere, imbibī, imbitum"
  ],
  "cluster or bunch of grapes": [
    "racēmus, racēmi"
  ],
  "berries or similar fruits": [
    "racēmus, racēmi"
  ],
  "the act of breeding": [
    "ēducātiō, ēducātiōnis"
  ],
  "rearing or bringing up": [
    "ēducātiō, ēducātiōnis"
  ],
  "make greater by adding to": [
    "adaugeō, adaugēre, adauxī, adauctum"
  ],
  "bend backwards": [
    "recurvō, recurvāre, recurvāvī, recurvātum"
  ],
  "make a sound in or on": [
    "īnsonō, īnsonāre, īnsonuī"
  ],
  "sound loudly": [
    "īnsonō, īnsonāre, īnsonuī"
  ],
  "of or pertaining to an accuser": [
    "accūsātōrius, accūsātōria, accūsātōrium"
  ],
  "collect again": [
    "relegō, relegere, relēgī, relēctum"
  ],
  "bier": [
    "capulus, capulī",
    "ferētrum, ferētrī"
  ],
  "homogeneous": [
    "homogeneus, homogenea, homogeneum"
  ],
  "fierceness": [
    "saevitia, saevitiae",
    "ferōcitās, ferōcitātis"
  ],
  "prune": [
    "amputō, amputāre, amputāvī, amputātum",
    "castrō, castrāre, castrāvī, castrātum",
    "dēputō, dēputāre, dēputāvī, dēputātum"
  ],
  "small etc": [
    "perbrevis, perbreve"
  ],
  "sutler": [
    "lixa, lixae"
  ],
  "camp follower": [
    "lixa, lixae"
  ],
  "of or pertaining to a viper or snake": [
    "vīpereus, vīperea, vīpereum"
  ],
  "snaky": [
    "vīpereus, vīperea, vīpereum"
  ],
  "pore": [
    "porus, porī"
  ],
  "passage in the body": [
    "porus, porī"
  ],
  "pilotage": [
    "gubernātiō, gubernātiōnis"
  ],
  "a river of gallia transpadana that flows into the ollius": [
    "clūsius, clūsiī"
  ],
  "now the chiese": [
    "clūsius, clūsiī"
  ],
  "a thin piece or sheet of metal": [
    "lāmina, lāminae"
  ],
  "marble": [
    "lāmina, lāminae"
  ],
  "etc.": [
    "lāmina, lāminae"
  ],
  "a plate": [
    "lāmina, lāminae"
  ],
  "layer": [
    "lāmina, lāminae"
  ],
  "draught": [
    "pōtus, pōtūs"
  ],
  "very rare or uncommon": [
    "perrārus, perrāra, perrārum"
  ],
  "vicarious": [
    "vicārius, vicāria, vicārium"
  ],
  "substituted": [
    "vicārius, vicāria, vicārium"
  ],
  "joining": [
    "iunctura",
    "adiunctiō, adiunctiōnis"
  ],
  "conjunction": [
    "adiunctiō, adiunctiōnis"
  ],
  "centesimate": [
    "centēsimō, centēsimāre, centēsimāvī, centēsimātum"
  ],
  "a city of illyricum": [
    "lissus, lissī"
  ],
  "now lezhë or alessio": [
    "lissus, lissī"
  ],
  "ichor": [
    "saniēs, sanieī"
  ],
  "pus": [
    "saniēs, sanieī"
  ],
  "sanies": [
    "saniēs, sanieī"
  ],
  "grow or become old or grey together": [
    "cōnsenēscō, cōnsenēscere, cōnsenuī"
  ],
  "maternal aunt": [
    "mātertera, māterterae"
  ],
  "chalice": [
    "calix, calicis"
  ],
  "an effect": [
    "cōnsecūtiō, cōnsecūtiōnis"
  ],
  "consequence": [
    "cōnsecūtiō, cōnsecūtiōnis"
  ],
  "vengeance": [
    "ultiō, ultiōnis"
  ],
  "revenge": [
    "ultiō, ultiōnis"
  ],
  "creep up": [
    "obrēpō, obrēpere, obrēpsī, obrēptum"
  ],
  "a dagger": [
    "pūgiō, pūgiōnis"
  ],
  "a piece": [
    "frustum, frustī",
    "fragmentum, fragmentī"
  ],
  "remnant": [
    "fragmentum, fragmentī"
  ],
  "fragment": [
    "fragmentum, fragmentī"
  ],
  "of or belonging to crossroad temples": [
    "trivius, trivia, trivium"
  ],
  "a prop": [
    "adminiculum, adminiculī"
  ],
  "make a bargain": [
    "pāciscor, pāciscī, pāctus sum"
  ],
  "contract or agreement with": [
    "pāciscor, pāciscī, pāctus sum"
  ],
  "salamis": [
    "salamis, salamīnis"
  ],
  "pellucid": [
    "perlucidus, perlucida, perlucidum"
  ],
  "send off or away ": [
    "ablēgō, ablēgāre, ablēgāvī, ablēgātum"
  ],
  "amiably": [
    "amābiliter, amābilius, amābilissimē"
  ],
  "or go into hiding": [
    "dēlitēscō, dēlitēscere, dēlituī"
  ],
  "a she-goat": [
    "capra, caprae"
  ],
  "princely": [
    "aulicus, aulica, aulicum"
  ],
  "search or examine thoroughly": [
    "scrūtor, scrūtārī, scrūtātus sum"
  ],
  "probe": [
    "rīmor, rīmārī, rīmātus sum",
    "scrūtor, scrūtārī, scrūtātus sum"
  ],
  "investigate or scrutinize": [
    "scrūtor, scrūtārī, scrūtātus sum"
  ],
  "late breakfast": [
    "prandium, prandiī"
  ],
  "am exiled": [
    "exulō, exulāre, exulāvī, exulātum"
  ],
  "banished": [
    "exulō, exulāre, exulāvī, exulātum",
    "exsulō, exsulāre, exsulāvī, exsulātum"
  ],
  "make bloody": [
    "cruentō, cruentāre, cruentāvī, cruentātum"
  ],
  "stain with blood": [
    "cruentō, cruentāre, cruentāvī, cruentātum"
  ],
  "wondering": [
    "mīrābundus, mīrābunda, mīrābundum"
  ],
  "astonished": [
    "mīrābundus, mīrābunda, mīrābundum"
  ],
  "full of wonder or astonishment": [
    "mīrābundus, mīrābunda, mīrābundum"
  ],
  "rise up ": [
    "īnsurgō, īnsurgere, īnsurrexī, īnsurrectum"
  ],
  "accomplice": [
    "adiūtrīx, adiūtrīcis"
  ],
  "holy of holies": [
    "adytum, adytī"
  ],
  "enjoy thoroughly": [
    "perfruor, perfruī, perfructus sum"
  ],
  "weigh or examine carefully or exactly": [
    "perpendō, perpendere, perpependī, perpēnsum"
  ],
  "draw close": [
    "astringō, astringere, astrīnxī, astrictum"
  ],
  "bind or tie together": [
    "astringō, astringere, astrīnxī, astrictum"
  ],
  "male": [
    "Mas",
    "masculus, mascula, masculum",
    "masculīnus, masculīna, masculīnum"
  ],
  "etesian wind": [
    "etēsia, etēsiae"
  ],
  "pressing down": [
    "oppressiō, oppressiōnis"
  ],
  "rocky": [
    "saxeus, saxea, saxeum"
  ],
  "stony": [
    "saxeus, saxea, saxeum"
  ],
  "gird": [
    "redimiō, redimīre, redimīvī, redimitum"
  ],
  "a sending out": [
    "ēmissiō, ēmissiōnis"
  ],
  "joke": [
    "iocus, iocī",
    "iocor, iocārī, iocātus sum"
  ],
  "carry or take off or away": [
    "asportō, asportāre, asportāvī, asportātum"
  ],
  "wash out or away": [
    "ēluō, ēluere, ēluī, ēluum"
  ],
  "rinse": [
    "ēluō, ēluere, ēluī, ēluum"
  ],
  "keeping watch": [
    "excubia, excubiae"
  ],
  "turn or bend aside": [
    "dētorqueō, dētorquēre, dētorsī, dētortum"
  ],
  "off or away": [
    "dētorqueō, dētorquēre, dētorsī, dētortum"
  ],
  "courteous": [
    "cōmis, cōme",
    "prōlixus, prōlixa, prōlixum"
  ],
  "dine sumptuously": [
    "epulor, epulārī, epulātus sum"
  ],
  "pure lively red": [
    "pūniceus, pūnicea, pūniceum"
  ],
  "scarlet": [
    "pūniceus, pūnicea, pūniceum"
  ],
  "draw a line around": [
    "circumscrībō, circumscrībere, circumscrīpsi, circumscrīptum"
  ],
  "enclose in a circle": [
    "circumscrībō, circumscrībere, circumscrīpsi, circumscrīptum"
  ],
  "circumscribe": [
    "circumscrībō, circumscrībere, circumscrīpsi, circumscrīptum"
  ],
  "honeycomb": [
    "Cera",
    "favus, favī"
  ],
  "reiterate": [
    "ingeminō, ingemināre, ingemināvī, ingeminātum"
  ],
  "put forth shoots": [
    "fruticō, fruticāre, fruticāvī, fruticātum"
  ],
  "unburied": [
    "inhumātus, inhumāta, inhumātum",
    "īnsepultus, īnsepulta, īnsepultum"
  ],
  "a club": [
    "clāva, clāvae"
  ],
  "laughably": [
    "rīdiculē, rīdiculius, rīdiculissimē"
  ],
  "amusingly": [
    "rīdiculē, rīdiculius, rīdiculissimē"
  ],
  "resistance": [
    "repugnantia, repugnantiae"
  ],
  "opposition": [
    "obiectiō, obiectiōnis",
    "oppositiō, oppositiōnis",
    "repugnantia, repugnantiae",
    "contrārietās, contrārietātis"
  ],
  "seller": [
    "vēnditor, vēnditōris"
  ],
  "vendor": [
    "vēnditor, vēnditōris"
  ],
  "miletus": [
    "mīlētus, mīlētī"
  ],
  "chios": [
    "chios, chiī"
  ],
  "adduce arguments or proof of something": [
    "argūmentor, argūmentārī, argūmentātus sum"
  ],
  "a small measure or interval": [
    "modulus, modulī"
  ],
  "unbecoming": [
    "indecōrus, indecōra, indecōrum"
  ],
  "unseemly": [
    "indecōrus, indecōra, indecōrum"
  ],
  "indecorous": [
    "indecōrus, indecōra, indecōrum"
  ],
  "beautifully": [
    "pulchrē, pulchrius, pulcherrimē"
  ],
  "corsica": [
    "corsica, corsicae"
  ],
  "disown": [
    "īnfitior, īnfitiārī, īnfitiātus sum"
  ],
  "an important city of etruria and capital of the faliscans": [
    "faleriī, falerōrum"
  ],
  "wall or enclosure made of brick or clay": [
    "māceria, māceriae"
  ],
  "a nereid": [
    "nērēis, nērēidis"
  ],
  "represent": [
    "repraesentō, repraesentāre, repraesentāvī, repraesentātum"
  ],
  "depict": [
    "dēpingō, dēpingere, dēpinxī, dēpictum",
    "repraesentō, repraesentāre, repraesentāvī, repraesentātum"
  ],
  "pursuing": [
    "sequāx, sequācis"
  ],
  "sequacious": [
    "sequāx, sequācis"
  ],
  "a town on the sea coast of campania": [
    "līternum, līternī"
  ],
  "situated between the mouth of the vulturnus and cumae": [
    "līternum, līternī"
  ],
  "centre": [
    "centrum, centrī"
  ],
  "diminutive of cōdex": [
    "cōdicillus, cōdicillī"
  ],
  "the chief river of laconia": [
    "eurōtās, eurōtae"
  ],
  "scholastic": [
    "scholasticus, scholastica, scholasticum"
  ],
  "io": [
    "īō, īūs"
  ],
  "daughter of inachus": [
    "īō, īūs"
  ],
  "a mountain of arcadia": [
    "lycaeus, lycaeī"
  ],
  "where zeus and pan where worshipped": [
    "lycaeus, lycaeī"
  ],
  "the mythical wife of haemus": [
    "rhodopē, rhodopēs"
  ],
  "the apparatus of the cradle": [
    "incūnābulum, incūnābulī"
  ],
  "partake of": [
    "participō, participāre, participāvī, participātum"
  ],
  "burdensome": [
    "onerōsus, onerōsa, onerōsum"
  ],
  "oppressive": [
    "onerōsus, onerōsa, onerōsum"
  ],
  "rough stone from the quarry": [
    "caementum, caementī"
  ],
  "levy": [
    "conquīsītiō, conquīsītiōnis"
  ],
  "levying": [
    "conquīsītiō, conquīsītiōnis"
  ],
  "poisonous": [
    "venēficus, venēfica, venēficum"
  ],
  "a sophist": [
    "sophistēs, sophistae"
  ],
  "an escape": [
    "effugium, effugiī"
  ],
  "the cretans": [
    "crētes, crētum"
  ],
  "governor": [
    "paedagōgus, paedagōgī",
    "moderātor, moderātōris"
  ],
  "director": [
    "mandator, mandatōris",
    "moderātor, moderātōris"
  ],
  "a reduction of must in ancient roman cuisine": [
    "sapa, sapae"
  ],
  "made by boiling down grape juice or must in large kettles until reduced to a third of the original volume": [
    "sapa, sapae"
  ],
  "exclusion": [
    "exclūsiō, exclūsiōnis"
  ],
  "the nurse of æneas": [
    "cāiēta, cāiētae"
  ],
  "fabricate": [
    "cōnfingō, cōnfingere, cōnfinxī, cōnfictum"
  ],
  "a latin surname": [
    "crista, cristae"
  ],
  "basilica": [
    "basilica, basilicae"
  ],
  "of wax": [
    "cēreus, cērea, cēreum"
  ],
  "waxen": [
    "cēreus, cērea, cēreum"
  ],
  "live again": [
    "revīvō, revīvere, revīxī, revīctum"
  ],
  "cinnamon": [
    "cassia, cassiae"
  ],
  "ostensive": [
    "ostēnsīvus, ostēnsīva, ostēnsīvum"
  ],
  "adjuring": [
    "obtestātiō, obtestātiōnis"
  ],
  "conjuring": [
    "obtestātiō, obtestātiōnis"
  ],
  "binding": [
    "compāges, compāgis",
    "obligātiō, obligātiōnis"
  ],
  "fastening": [
    "compāges, compāgis"
  ],
  "a small box in which was kept the incense used in sacrifices": [
    "acerra, acerrae"
  ],
  "turn or spin around": [
    "intorqueō, intorquēre, intorsī, intortum"
  ],
  "wrench": [
    "intorqueō, intorquēre, intorsī, intortum"
  ],
  "sprain": [
    "intorqueō, intorquēre, intorsī, intortum"
  ],
  "crossroads": [
    "compitum, compitī"
  ],
  "redemption": [
    "redemptiō, redemptiōnis"
  ],
  "redeeming": [
    "redemptiō, redemptiōnis"
  ],
  "pervert": [
    "dēprāvō, dēprāvāre, dēprāvāvī, dēprāvātum"
  ],
  "distort or disfigure": [
    "dēprāvō, dēprāvāre, dēprāvāvī, dēprāvātum"
  ],
  "inglorious": [
    "inglōrius, inglōria, inglōrium"
  ],
  "konya": [
    "īconium, īconiī"
  ],
  "dewy": [
    "rōscidus, rōscida, rōscidum"
  ],
  "make thick": [
    "dēnsō, dēnsāre, dēnsāvī, dēnsātum"
  ],
  "thicken": [
    "dēnsō, dēnsāre, dēnsāvī, dēnsātum",
    "concrēscō, concrēscere, concrēvī, concrētum"
  ],
  "condense": [
    "dēnsō, dēnsāre, dēnsāvī, dēnsātum",
    "concrēscō, concrēscere, concrēvī, concrētum",
    "condēnsō, condēnsāre, condēnsāvī, condēnsātum"
  ],
  "oxford": [
    "oxonia, oxoniae"
  ],
  "england": [
    "oxonia, oxoniae"
  ],
  "obscurity": [
    "ignōbilitās, ignōbilitātis"
  ],
  "bipartite": [
    "bipertitus, bipertita, bipertitum"
  ],
  "for such time": [
    "tantisper"
  ],
  "for as long": [
    "tantisper"
  ],
  "various": [
    "multifārius, multifāria, multifārium"
  ],
  "multifarious": [
    "multifārius, multifāria, multifārium"
  ],
  "diminutive of sagumsmall military cloak": [
    "sagulum, sagulī"
  ],
  "wrestler": [
    "āthlēta, āthlētae"
  ],
  "athlete": [
    "āthlēta, āthlētae"
  ],
  "shroud": [
    "abscondō, abscondere, abscondī, absconditum"
  ],
  "tinder": [
    "fōmes, fōmitis"
  ],
  "kindling": [
    "fōmes, fōmitis"
  ],
  "a festival of the winter solstice originally celebrated for three days beginning december 17th": [
    "sāturnālia, sāturnālium"
  ],
  "but later extended to seven days": [
    "sāturnālia, sāturnālium"
  ],
  "individually": [
    "virītim"
  ],
  "an entrance through a narrow or crooked way": [
    "īnsinuātiō, īnsinuātiōnis"
  ],
  "roll to or upon something": [
    "involvō, involvere, involvī, involūtum"
  ],
  "comb": [
    "pecten, pectinis"
  ],
  "a created thing": [
    "creātūra, creātūrae), first declension (late latin",
    "creātūra, creātūrae); first declension (Late Latin"
  ],
  "creature": [
    "creātūra, creātūrae), first declension (late latin",
    "creātūra, creātūrae); first declension (Late Latin"
  ],
  "peroration": [
    "epilogus, epilogī"
  ],
  "epilogue": [
    "epilogus, epilogī"
  ],
  "consider thoroughly": [
    "commentor, commentārī, commentātus sum"
  ],
  "think over": [
    "commentor, commentārī, commentātus sum"
  ],
  "discuss": [
    "Discute",
    "commentor, commentārī, commentātus sum",
    "disputō, disputāre, disputāvī, disputātum"
  ],
  "write upon": [
    "commentor, commentārī, commentātus sum"
  ],
  "the highest part of something": [
    "summitās, summitātis"
  ],
  "parched": [
    "torridus, torrida, torridum"
  ],
  "stretching": [
    "tēnsūra, tēnsūrae",
    "extēnsiō, extēnsiōnis"
  ],
  "straining": [
    "tēnsūra, tēnsūrae"
  ],
  "looseness": [
    "rāritās, rāritātis"
  ],
  "the state of being loose": [
    "rāritās, rāritātis"
  ],
  "not dense": [
    "rāritās, rāritātis"
  ],
  "log": [
    "sudis, sudis"
  ],
  "elect": [
    "creō, creāre, creāvī, creātum",
    "cooptō, cooptāre, cooptāvī, cooptātum"
  ],
  "outpouring": [
    "effūsiō, effūsiōnis"
  ],
  "aydın": [
    "trallēs, trallium"
  ],
  "promiser": [
    "prōmissor, prōmissōris"
  ],
  "lesbos": [
    "lesbos, lesbī"
  ],
  "concordance of sounds": [
    "harmonia, harmoniae"
  ],
  "design": [
    "dēfōrmō, dēfōrmāre, dēfōrmāvī, dēfōrmātum"
  ],
  "delineate": [
    "dēfōrmō, dēfōrmāre, dēfōrmāvī, dēfōrmātum"
  ],
  "flee secretly or by stealth": [
    "subterfugiō, subterfugere, subterfūgī"
  ],
  "cothurnus": [
    "cothurnus, cothurnī"
  ],
  "restrain or check": [
    "compescō, compescere, compescuī"
  ],
  "acclamation": [
    "acclāmātiō, acclāmātiōnis"
  ],
  "a kind of duck": [
    "pēnelops, pēnelopis"
  ],
  "the island off the coast of troy": [
    "tenedus, tenedī"
  ],
  "fickle": [
    "incōnstāns, incōnstantis"
  ],
  "inconsistent": [
    "incōnstāns, incōnstantis"
  ],
  "sink or submerge": [
    "dēmergō, dēmergere, dēmersī, dēmersum"
  ],
  "fig tree": [
    "fīcus"
  ],
  "diver": [
    "mergus, mergī"
  ],
  "a kind of waterfowl": [
    "mergus, mergī"
  ],
  "pillar": [
    "Columna",
    "columen, columinis"
  ],
  "am irritated": [
    "stomachor, stomachārī, stomachātus sum"
  ],
  "vexed": [
    "stomachor, stomachārī, stomachātus sum"
  ],
  "precede or lead the way": [
    "praeeō, praeīre, praeiī, praeitum"
  ],
  "striving": [
    "certātiō, certātiōnis"
  ],
  "am or live in foreign parts": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "am abroad or a stranger": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "go abroad": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "travel about": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "rove": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "sojourn abroad": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "peregrinate": [
    "peregrīnor, peregrīnārī, peregrīnātus sum"
  ],
  "crumb": [
    "frustum, frustī"
  ],
  "morsel": [
    "frustum, frustī"
  ],
  "scrap of food": [
    "frustum, frustī"
  ],
  "a plant known as bear's-foot ": [
    "acanthus, acanthī"
  ],
  "a small garden": [
    "hortulus, hortulī"
  ],
  "introducing questions": [
    "satin"
  ],
  "an ash tree": [
    "ornus, ornī",
    "fraxinus, fraxinī"
  ],
  "northern": [
    "septentriōnālis, septentriōnāle"
  ],
  "dissonant": [
    "dissonus, dissona, dissonum"
  ],
  "a river of argolis": [
    "īnachus, īnachī"
  ],
  "the chief river of thessaly": [
    "pēnēus, pēnēī"
  ],
  "which rises in mount pindus and flows into the gulf of therma": [
    "pēnēus, pēnēī"
  ],
  "fly through or about": [
    "pervolō, pervolāre, pervolāvī, pervolātum"
  ],
  "drainage ditch": [
    "ēlix, ēlicis"
  ],
  "knotty": [
    "nōdōsus, nōdōsa, nōdōsum"
  ],
  "knobby": [
    "nōdōsus, nōdōsa, nōdōsum"
  ],
  "hewer": [
    "caesor, caesōris"
  ],
  "chopper": [
    "caesor, caesōris"
  ],
  "cutter": [
    "caesor, caesōris"
  ],
  "fawning": [
    "adūlātiō, adūlātiōnis"
  ],
  "shackles": [
    "Catenae",
    "compēs, compedis"
  ],
  "fetters": [
    "compēs, compedis"
  ],
  "delimit": [
    "dēterminō, dētermināre, dētermināvī, dēterminātum"
  ],
  "small present": [
    "mūnusculum, mūnusculī), second declension (diminutive of: mūnus",
    "mūnusculum, mūnusculī); second declension (Diminutive of: mūnus"
  ],
  "service or favour": [
    "mūnusculum, mūnusculī), second declension (diminutive of: mūnus",
    "mūnusculum, mūnusculī); second declension (Diminutive of: mūnus"
  ],
  "comparative": [
    "comparātīvus, comparātīva, comparātīvum"
  ],
  "multiple ancient cities in africa including:leptis magna leptis parva": [
    "leptis, leptis"
  ],
  "shine or flash back": [
    "refulgeō, refulgēre, refulsī"
  ],
  "young vine shoot or sprig": [
    "palmes, palmitis"
  ],
  "a king of thrace": [
    "haemus, haemī"
  ],
  "the son of boreas": [
    "haemus, haemī"
  ],
  "who was vain and haughty and compared himself and his wife to zeus and hera": [
    "haemus, haemī"
  ],
  "bologna": [
    "bonōnia, bonōniae"
  ],
  "dispense": [
    "dispēnsō, dispēnsāre, dispēnsāvī, dispēnsātum"
  ],
  "goblet": [
    "Poculum",
    "scyphus, scyphī"
  ],
  "warrior": [
    "Warrior",
    "bellātor, bellātōris"
  ],
  "fighter": [
    "bellātor, bellātōris"
  ],
  "bramble": [
    "batus, batī",
    "rubus, rubī",
    "veprēs, vepris"
  ],
  "blackberry bush": [
    "rubus, rubī"
  ],
  "epic": [
    "Epicum",
    "hērōicus, hērōica, hērōicum"
  ],
  "roving": [
    "errāticus, errātica, errāticum"
  ],
  "insatiable": [
    "īnsatiābilis, īnsatiābile"
  ],
  "unload": [
    "exonerō, exonerāre, exonerāvī, exonerātum"
  ],
  "from this place forth": [
    "dehinc"
  ],
  "rejection": [
    "rēiectiō, rēiectiōnis",
    "reprobatiō, reprobatiōnis"
  ],
  "ache": [
    "indolēscō, indolēscere, indoluī"
  ],
  "proportion": [
    "prōportiō, prōportiōnis"
  ],
  "lioness": [
    "leaena, leaenae"
  ],
  "cling to": [
    "amplexor, amplexārī, amplexātus sum"
  ],
  "wicker basket or hamper": [
    "quālum, quālī"
  ],
  "artificial": [
    "artificiālis, artificiāle"
  ],
  "penitentiary": [
    "ergastulum, ergastulī"
  ],
  "workhouse": [
    "ergastulum, ergastulī"
  ],
  "slip or glide away": [
    "ēlābor, ēlābī, ēlāpsus"
  ],
  "full or consisting of vine leaves": [
    "pampineus, pampinea, pampineum"
  ],
  "tendrils or foliage": [
    "pampineus, pampinea, pampineum"
  ],
  "an important city of hispania tarraconensis": [
    "emporiae, emporiārum"
  ],
  "now ampurias": [
    "emporiae, emporiārum"
  ],
  "berber": [
    "barbaricus, barbarica, barbaricum"
  ],
  "spite": [
    "malignitās, malignitātis"
  ],
  "malice": [
    "malignitās, malignitātis"
  ],
  "malignity": [
    "malignitās, malignitātis"
  ],
  "roll back": [
    "revolvō, revolvere, revolvī, revolūtum"
  ],
  "unwind": [
    "revolvō, revolvere, revolvī, revolūtum"
  ],
  "revolve": [
    "revolvō, revolvere, revolvī, revolūtum"
  ],
  "having two forms": [
    "bifōrmis, bifōrme"
  ],
  "variant of eiusmodi": [
    "ejusmodi"
  ],
  "until when": [
    "quousque"
  ],
  "concealment": [
    "occultātiō, occultātiōnis"
  ],
  "a feaster": [
    "epulō, epulōnis"
  ],
  "guest at a feast": [
    "epulō, epulōnis"
  ],
  "emissary": [
    "ēmissārius, ēmissāriī"
  ],
  "utter with a sigh": [
    "suspīrō, suspīrāre, suspīrāvī, suspīrātum"
  ],
  "wakening": [
    "excitatiō, excitatiōnis"
  ],
  "white color": [
    "albēdō, albēdinis"
  ],
  "whiteness": [
    "albēdō, albēdinis"
  ],
  "suitably": [
    "cōnsequenter",
    "accommodātē, accommodātius, accommodātissimē",
    "convenienter, convenientius, convenientissimē"
  ],
  "fitly": [
    "convenienter, convenientius, convenientissimē"
  ],
  "comfortably": [
    "convenienter, convenientius, convenientissimē"
  ],
  "consistently": [
    "convenienter, convenientius, convenientissimē"
  ],
  "seditious": [
    "factiōsus, factiōsa, factiōsum"
  ],
  "lisping": [
    "blaesus, blaesa, blaesum"
  ],
  "am on fire or in flames": [
    "cōnflagrō, cōnflagrāre, cōnflagrāvī, cōnflagrātum"
  ],
  "am consumed": [
    "cōnflagrō, cōnflagrāre, cōnflagrāvī, cōnflagrātum"
  ],
  "paint": [
    "fūcō, fūcāre, fūcāvī, fūcātum",
    "dēpingō, dēpingere, dēpinxī, dēpictum"
  ],
  "portray": [
    "dēpingō, dēpingere, dēpinxī, dēpictum"
  ],
  "represent by painting": [
    "dēpingō, dēpingere, dēpinxī, dēpictum"
  ],
  "chariot which bore the images of the gods": [
    "tēnsa, tēnsae"
  ],
  "colchis": [
    "colchis, colchidis"
  ],
  "a privy councillor": [
    "sēcrētārius, sēcrētāriī), second declension (medieval latin",
    "sēcrētārius, sēcrētāriī); second declension (Medieval Latin"
  ],
  "carry or conduct forward or along": [
    "prōvehō, prōvehere, prōvexī, prōvectum"
  ],
  "forth or forward": [
    "prōpendeō, prōpendēre, prōpendī, prōpēnsum"
  ],
  "crush or break to pieces": [
    "obterō, obterere, obtrīvī, obtrītum"
  ],
  "implement or tool of or pointed with iron": [
    "ferrāmentum, ferrāmentī"
  ],
  "pertaining to traveling or a journey": [
    "viāticus, viātica, viāticum"
  ],
  "full of blood": [
    "sanguinolentus, sanguinolenta, sanguinolentum"
  ],
  "sanguinary": [
    "sanguinolentus, sanguinolenta, sanguinolentum"
  ],
  "a comet": [
    "comētēs, comētae"
  ],
  "meteor": [
    "comētēs, comētae"
  ],
  "shooting star": [
    "comētēs, comētae"
  ],
  "a sewer or underground drain": [
    "cloāca, cloācae"
  ],
  "ago": [
    "abhinc"
  ],
  "made of maple": [
    "acernus, acerna, acernum"
  ],
  "a first cousin": [
    "cōnsōbrīnus, cōnsobrīnī"
  ],
  "cousin-german": [
    "patruēlis, patruēlis",
    "cōnsōbrīnus, cōnsobrīnī"
  ],
  "do a second time": [
    "iterō, iterāre, iterāvī, iterātum"
  ],
  "bearably": [
    "tolerābiliter, tolerābilius, tolerābilissimē"
  ],
  "passably": [
    "tolerābiliter, tolerābilius, tolerābilissimē"
  ],
  "tolerably": [
    "tolerābiliter, tolerābilius, tolerābilissimē"
  ],
  "laid straight": [
    "dīrectus, dīrecta, dīrectum"
  ],
  "arranged in lines": [
    "dīrectus, dīrecta, dīrectum"
  ],
  "having been arranged in lines": [
    "dīrectus, dīrecta, dīrectum"
  ],
  "to prepare or make ready": [
    "concinnō, concinnāre, concinnāvī, concinnātum"
  ],
  "a dalmatian tribe mentioned by pliny": [
    "dassaretiī, dassaretiōrum"
  ],
  "a low cringing flatterer": [
    "adūlātor, adūlātōris"
  ],
  "a sycophant": [
    "adūlātor, adūlātōris"
  ],
  "a small staff or wand": [
    "bacillum, bacillī"
  ],
  "cautiously": [
    "pedetemptim, pedetemptius, pedetemptissimē"
  ],
  "step-by-step": [
    "pedetemptim, pedetemptius, pedetemptissimē"
  ],
  "impudence": [
    "petulantia, petulantiae"
  ],
  "a river of gallia": [
    "isara, isarae"
  ],
  "now isère": [
    "isara, isarae"
  ],
  "assent or approve": [
    "assentiō, assentīre, assensī, assensum"
  ],
  "a press": [
    "prēlum, prēlī"
  ],
  "winepress": [
    "prēlum, prēlī"
  ],
  "oilpress": [
    "prēlum, prēlī"
  ],
  "fallacious": [
    "captiōsus, captiōsa, captiōsum"
  ],
  "situated between norba and privernum": [
    "sētia, sētiae"
  ],
  "now sezze": [
    "sētia, sētiae"
  ],
  "necklace": [
    "monīle, monīlis"
  ],
  "collar": [
    "monīle, monīlis"
  ],
  "expeller": [
    "exactor, exactōris"
  ],
  "slightly": [
    "summātim"
  ],
  "summarily": [
    "summātim"
  ],
  "cursorily": [
    "summātim"
  ],
  "to draw or lead off": [
    "dērīvō, dērīvāre, dērīvāvī, dērīvātum"
  ],
  "rise again": [
    "resurgō, resurgere, resurrexī, resurrectum"
  ],
  "a flat cake": [
    "placenta, placentae"
  ],
  "immoderate": [
    "inmodicus, inmodica, inmodicum"
  ],
  "putting back": [
    "remōtiō, remōtiōnis"
  ],
  "of horn": [
    "corneus, cornea, corneum"
  ],
  "dishonorable": [
    "inhonestus, inhonesta, inhonestum"
  ],
  "extending": [
    "dīlātātio, dīlātātiōnis"
  ],
  "enlarging": [
    "dīlātātio, dīlātātiōnis"
  ],
  "cymbal": [
    "cymbalum, cymbalī"
  ],
  "broken": [
    "cōnfragōsus, cōnfragōsa, cōnfragōsum"
  ],
  "the goddess of shepherds": [
    "palēs, palis"
  ],
  "flocks and livestock": [
    "palēs, palis"
  ],
  "pass or spread through": [
    "pervādō, pervādere, pervasī, pervasum"
  ],
  "pervade": [
    "pervādō, pervādere, pervasī, pervasum"
  ],
  "sarcophagus": [
    "Sarcophagus",
    "capulus, capulī"
  ],
  "dancer": [
    "saltātor, saltātōris"
  ],
  "a pipe": [
    "canālis, canālis"
  ],
  "spout": [
    "canālis, canālis"
  ],
  "a roman market day": [
    "nūndina, nūndinae"
  ],
  "of or pertaining to a wolf": [
    "lupīnus, lupīna, lupīnum"
  ],
  "thoughtless": [
    "incōnsīderātus, incōnsīderāta, incōnsīderātum"
  ],
  "heedless": [
    "incōnsīderātus, incōnsīderāta, incōnsīderātum"
  ],
  "inconsiderate": [
    "incōnsīderātus, incōnsīderāta, incōnsīderātum"
  ],
  "frighten away": [
    "absterreō, absterrēre, absterruī, absterritum"
  ],
  "discourage": [
    "absterreō, absterrēre, absterruī, absterritum",
    "contrīstō, contrīstāre, contrīstāvī, contrīstātum"
  ],
  "bring a shadow over something": [
    "adumbrō, adumbrāre, adumbrāvī, adumbrātum"
  ],
  "cast a shadow on": [
    "adumbrō, adumbrāre, adumbrāvī, adumbrātum"
  ],
  "shade or overshadow something": [
    "adumbrō, adumbrāre, adumbrāvī, adumbrātum"
  ],
  "screen": [
    "adumbrō, adumbrāre, adumbrāvī, adumbrātum"
  ],
  "come on": [
    "agedum"
  ],
  "tear or snatch off or away": [
    "dēripiō, dēripere, dēripuī, dēreptum"
  ],
  "pull or tear down": [
    "dēripiō, dēripere, dēripuī, dēreptum"
  ],
  "turn to": [
    "obvertō, obvertere, obvertī, obversum"
  ],
  "turn towards": [
    "advertō, advertere, advertī, adversum",
    "obvertō, obvertere, obvertī, obversum"
  ],
  "tear off or away": [
    "āvellō, āvellere, āvulsī, āvulsum"
  ],
  "wrest": [
    "āvellō, āvellere, āvulsī, āvulsum"
  ],
  "lovable": [
    "amābilis, amābile"
  ],
  "worthy of love": [
    "amābilis, amābile"
  ],
  "the food of the gods": [
    "ambrosia, ambrosiae"
  ],
  "ambrosia": [
    "ambrosia, ambrosiae"
  ],
  "lemnos": [
    "lēmnos, lēmnī"
  ],
  "inhumanity": [
    "inhūmānitās, inhūmānitātis"
  ],
  "knock or strike against": [
    "īnflīgō, īnflīgere, īnflīxī, īnflīctum"
  ],
  "choke": [
    "suffōcō, suffōcāre, suffōcāvī, suffōcātum"
  ],
  "stifle": [
    "suffōcō, suffōcāre, suffōcāvī, suffōcātum"
  ],
  "strangle or suffocate": [
    "suffōcō, suffōcāre, suffōcāvī, suffōcātum"
  ],
  "allure": [
    "Blanditia",
    "allectō, allectāre, allectāvī, allectātum"
  ],
  "entice": [
    "allectō, allectāre, allectāvī, allectātum"
  ],
  "sieve": [
    "crībrum, crībrī"
  ],
  "theology": [
    "theologia, theologiae"
  ],
  "hypsipyle": [
    "hypsipylē, hypsipylēs"
  ],
  "wantonness": [
    "lascīvia, lascīviae"
  ],
  "an ancient city of attica": [
    "eleusis, eleusīnis"
  ],
  "famous for it mysteries of demeter and persephone": [
    "eleusis, eleusīnis"
  ],
  "jug": [
    "Guttus",
    "urceus, urceī",
    "hydria, hydriae"
  ],
  "ewer": [
    "urceus, urceī",
    "hydria, hydriae"
  ],
  "sickle-shaped": [
    "falcātus, falcāta, falcātum"
  ],
  "fruitfulness": [
    "fēcunditās, fēcunditātis",
    "fertilitas, fertilitātis"
  ],
  "fertility": [
    "fēcunditās, fēcunditātis",
    "fertilitas, fertilitātis"
  ],
  "grow less or shorter": [
    "dēcrēscō, dēcrēscere, dēcrēvī, dēcrētum"
  ],
  "wane": [
    "dēcrēscō, dēcrēscere, dēcrēvī, dēcrētum"
  ],
  "dwindle": [
    "dēcrēscō, dēcrēscere, dēcrēvī, dēcrētum"
  ],
  "judiciary": [
    "iūdiciārius, iūdiciāria, iūdiciārium"
  ],
  "maronite": [
    "maronitus, maronita, maronitum"
  ],
  "the act of choosing out or selecting": [
    "sēlēctiō, sēlēctiōnis"
  ],
  "radiance of celestial bodies": [
    "iubar, iubaris"
  ],
  "sunshine": [
    "iubar, iubaris"
  ],
  "cut or sever": [
    "secor, secī, secutus sum"
  ],
  "hating": [
    "exōsus, exōsa, exōsum"
  ],
  "detesting": [
    "exōsus, exōsa, exōsum"
  ],
  "full of grass or herbs": [
    "herbōsus, herbōsa, herbōsum"
  ],
  "bend or cast back": [
    "retorqueō, retorquēre, retorsī, retortum"
  ],
  "learn by heart": [
    "ēdiscō, ēdiscere, ēdidicī"
  ],
  "smoking": [
    "fūmōsus, fūmōsa, fūmōsum"
  ],
  "smoky": [
    "fūmōsus, fūmōsa, fūmōsum"
  ],
  "smoked": [
    "fūmōsus, fūmōsa, fūmōsum"
  ],
  "hermione": [
    "hermionē, hermionēs"
  ],
  "a catapult": [
    "catapulta, catapultae"
  ],
  "bulls": [
    "taurīnus, taurīna, taurīnum"
  ],
  "oxen": [
    "taurīnus, taurīna, taurīnum"
  ],
  "taurine": [
    "taurīnus, taurīna, taurīnum"
  ],
  "basket": [
    "Canistrum",
    "fiscus, fiscī",
    "corbis, corbis"
  ],
  "narcissus": [
    "narcissus, narcissī"
  ],
  "fall forwards": [
    "prōcidō, prōcidere, prōcidī"
  ],
  "down or flat": [
    "prōcidō, prōcidere, prōcidī"
  ],
  "vaulted": [
    "convexus, convexa, convexum"
  ],
  "arched": [
    "convexus, convexa, convexum"
  ],
  "rounded": [
    "convexus, convexa, convexum"
  ],
  "piercing": [
    "penētrālis, penētrāle",
    "penetrātiō, penetrātiōnis"
  ],
  "penetration": [
    "penetrātiō, penetrātiōnis"
  ],
  "speculation": [
    "theōria, theōriae"
  ],
  "theory": [
    "theōria, theōriae"
  ],
  "small wicker basket": [
    "fiscina, fiscinae"
  ],
  "punnet": [
    "fiscina, fiscinae"
  ],
  "very difficult etc": [
    "perdifficilis, perdifficile"
  ],
  "eretria": [
    "eretria, eretriae"
  ],
  "irony": [
    "īrōnīa, īrōnīae"
  ],
  "stepson": [
    "prīvignus, prīvignī"
  ],
  "thickness": [
    "dēnsitās, dēnsitātis",
    "crassitūdō, crassitūdinis"
  ],
  "allis shad": [
    "clupea, clupeae"
  ],
  "jewish": [
    "iūdaeus, iūdaea, iūdaeum",
    "iūdaicus, iūdaica, iūdaicum"
  ],
  "of or pertaining to the jewish people": [
    "iūdaeus, iūdaea, iūdaeum"
  ],
  "engaging": [
    "obligātiō, obligātiōnis"
  ],
  "pledging": [
    "obligātiō, obligātiōnis"
  ],
  "obligation": [
    "obligātiō, obligātiōnis"
  ],
  "urine": [
    "ūrīna, ūrīnae",
    "lōtium, lōtiī or lōtī"
  ],
  "acheron": [
    "acherōn, acherontis"
  ],
  "a river in the underworld": [
    "acherōn, acherontis"
  ],
  "legal assistance": [
    "advocātiō, advocātiōnis"
  ],
  "unite together": [
    "coalēscō, coalēscere, coaluī, coalitum"
  ],
  "coalesce": [
    "coalēscō, coalēscere, coaluī, coalitum"
  ],
  "turning away or back": [
    "reflexiō, reflexiōnis"
  ],
  "a river that flows near rome": [
    "cremera, cremerae"
  ],
  "famous for its battle": [
    "munda, mundae",
    "cremera, cremerae"
  ],
  "satisfaction": [
    "expiātiō, expiātiōnis"
  ],
  "atonement": [
    "expiātiō, expiātiōnis",
    "propitiātiō, propitiātiōnis"
  ],
  "expiation": [
    "expiātiō, expiātiōnis"
  ],
  "purification": [
    "expiātiō, expiātiōnis",
    "pūrgātiō, pūrgātiōnis",
    "pūrificātiō, pūrificātiōnis"
  ],
  "deviser": [
    "repertor, repertōris"
  ],
  "the ticino river": [
    "tīcīnus, tīcīnī"
  ],
  "corsican": [
    "corsus, corsa, corsum"
  ],
  "donation": [
    "stips, stipis",
    "dōnātiō, dōnātiōnis",
    "condōnātiō, condōnātiōnis"
  ],
  "hesitantly": [
    "cunctanter, cunctantius, cunctantissimē"
  ],
  "amber": [
    "AMBER",
    "ēlectrum, ēlectrī"
  ],
  "controversy": [
    "concertātiō, concertātiōnis"
  ],
  "absence": [
    "absentia, absentiae"
  ],
  "graft": [
    "īnsitiō, īnsitiōnis"
  ],
  "preconception": [
    "anticipātiō, anticipātiōnis"
  ],
  "disparage": [
    "obtrectō, obtrectāre, obtrectāvī, obtrectātum"
  ],
  "underrate": [
    "obtrectō, obtrectāre, obtrectāvī, obtrectātum"
  ],
  "decry": [
    "obtrectō, obtrectāre, obtrectāvī, obtrectātum"
  ],
  "untalkative": [
    "taciturnus, taciturna, taciturnum"
  ],
  "taciturn": [
    "taciturnus, taciturna, taciturnum"
  ],
  "sulmona": [
    "sulmō, sulmōnis"
  ],
  "eunuch": [
    "eunūchus, eunūchī"
  ],
  "gaze": [
    "cōnsīderātiō, cōnsīderātiōnis"
  ],
  "inspection": [
    "cōnsīderātiō, cōnsīderātiōnis"
  ],
  "two-edged": [
    "bipennis, bipenne"
  ],
  "the act of closing up by building": [
    "obstructiō, obstructiōnis"
  ],
  "a blocking up": [
    "obstructiō, obstructiōnis"
  ],
  "pay out": [
    "ērogō, ērogāre, ērogāvī, ērogātum"
  ],
  "expend": [
    "ērogō, ērogāre, ērogāvī, ērogātum"
  ],
  "disburse": [
    "ērogō, ērogāre, ērogāvī, ērogātum"
  ],
  "a knobbed stick": [
    "fūstis, fūstis"
  ],
  "a cudgel": [
    "fūstis, fūstis"
  ],
  "staff": [
    "Rudis",
    "fūstis, fūstis"
  ],
  "club": [
    "clāva, clāvae",
    "fūstis, fūstis"
  ],
  "lethal": [
    "lētālis, lētāle"
  ],
  "become stronger or more frequent": [
    "increbrēscō, increbrēscere, increbruī"
  ],
  "melt away": [
    "dīlābor, dīlābī, dīlapsus sum"
  ],
  "dissolve": [
    "dīlābor, dīlābī, dīlapsus sum"
  ],
  "roast": [
    "frīgō, frīgere, frīxī, frīctum"
  ],
  "fry": [
    "frīgō, frīgere, frīxī, frīctum"
  ],
  "wound or hurt": [
    "sauciō, sauciāre, sauciāvī, sauciātum"
  ],
  "gash or stab": [
    "sauciō, sauciāre, sauciāvī, sauciātum"
  ],
  "a greeting": [
    "salūtātiō, salūtātiōnis"
  ],
  "salutation": [
    "salūtātiō, salūtātiōnis"
  ],
  "a navel": [
    "umbilīcus, umbilīcī"
  ],
  "make very dense": [
    "condēnsō, condēnsāre, condēnsāvī, condēnsātum"
  ],
  "press close together": [
    "condēnsō, condēnsāre, condēnsāvī, condēnsātum"
  ],
  "subscription": [
    "subscrīptiō, subscrīptiōnis"
  ],
  "first-born": [
    "prīmōgenitus, prīmōgenita, prīmōgenitum"
  ],
  "a city of bruttium": [
    "vibō, vibōnis"
  ],
  "now vibo valentia": [
    "vibō, vibōnis"
  ],
  "lie beside": [
    "adiaceō, adiacēre, adiacuī"
  ],
  "near or next to": [
    "adiaceō, adiacēre, adiacuī"
  ],
  "am adjacent to": [
    "adiaceō, adiacēre, adiacuī"
  ],
  "am continuous to": [
    "adiaceō, adiacēre, adiacuī"
  ],
  "adjoin": [
    "adiaceō, adiacēre, adiacuī"
  ],
  "border upon": [
    "adiaceō, adiacēre, adiacuī"
  ],
  "a sundial": [
    "hōrologium, hōrologiī"
  ],
  "a thing that is coiled": [
    "spīra, spīrae"
  ],
  "twisted": [
    "spīra, spīrae"
  ],
  "or wound": [
    "spīra, spīrae"
  ],
  "the director or manager of a wrestling-school": [
    "palaestrīta, palaestrītae"
  ],
  "cleanness": [
    "munditia, munditiae"
  ],
  "cleanliness": [
    "munditia, munditiae"
  ],
  "whose position is unknown": [
    "pōmētia, pōmētiae"
  ],
  "talkativeness": [
    "loquācitās, loquācitātis"
  ],
  "loquacity": [
    "loquācitās, loquācitātis"
  ],
  "an ancient town in hispania baetica": [
    "munda, mundae"
  ],
  "winter quarters": [
    "hībernāculum, hībernāculī"
  ],
  "a winter residence": [
    "hībernāculum, hībernāculī"
  ],
  "definitive": [
    "dēfīnītīvus, dēfīnītīva, dēfīnītīvum"
  ],
  "through each tribe": [
    "tribūtim"
  ],
  "a small or shallow pan or dish": [
    "patella, patellae"
  ],
  "crane": [
    "grūs, gruis"
  ],
  "a bird also eaten as food": [
    "grūs, gruis"
  ],
  "inn": [
    "dēversōrium, dēversōriī"
  ],
  "lodging house": [
    "dēversōrium, dēversōriī"
  ],
  "first fruits": [
    "prīmitia, prīmitiae"
  ],
  "an athenian tyrant and statesman": [
    "thērāmenēs, thērāmenis"
  ],
  "basketful": [
    "corbis, corbis"
  ],
  "lean or rest upon": [
    "adnītor, adnītī, adnīsus sum",
    "innītor, innītī, innixus sum"
  ],
  "clang": [
    "clangor, clangōris"
  ],
  "incomplete": [
    "imperfectus, imperfecta, imperfectum"
  ],
  "wavy": [
    "undāns, undantis"
  ],
  "undulating": [
    "undāns, undantis"
  ],
  "frighten or terrify greatly": [
    "conterreō, conterrēre, conterruī, conterritum"
  ],
  "cylinder": [
    "cylindrus, cylindrī"
  ],
  "vibrating": [
    "coruscus, corusca, coruscum"
  ],
  "waving": [
    "coruscus, corusca, coruscum"
  ],
  "a city of macedonia situated on the left bank of the river strymon": [
    "amphipolis, amphipolis"
  ],
  "transformation": [
    "trānsfōrmātiō, trānsfōrmātiōnis"
  ],
  "a mede": [
    "mēdus, mēdī"
  ],
  "piratical": [
    "pīrāticus, pīrātica, pīrāticum"
  ],
  "phaedra": [
    "phaedra, phaedrae"
  ],
  "put in mind": [
    "commoneō, commonēre, commonuī, commonitum"
  ],
  "impress upon": [
    "commoneō, commonēre, commonuī, commonitum"
  ],
  "bring to recollection": [
    "commoneō, commonēre, commonuī, commonitum"
  ],
  "that is located above": [
    "supernus, superna, supernum"
  ],
  "a raging": [
    "saevitia, saevitiae"
  ],
  "unpropitious": [
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "luckless": [
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "ill-fated": [
    "īnfaustus, īnfausta, īnfaustum"
  ],
  "whip": [
    "flagellum, flagellī",
    "flagellō, flagellāre, flagellāvī, flagellātum"
  ],
  "scourge": [
    "flagellum, flagellī"
  ],
  "creak or grate": [
    "concrepō, concrepāre, concrepuī, concrepitum"
  ],
  "give out light": [
    "relūceō, relūcēre, reluxī"
  ],
  "stewardship": [
    "dispensātiō, dispensātiōnis"
  ],
  "a philosophic tenet": [
    "dogma, dogmatis"
  ],
  "doctrine": [
    "dogma, dogmatis"
  ],
  "dogma": [
    "dogma, dogmatis"
  ],
  "questioning": [
    "percontatiō, percontatiōnis",
    "interrogātiō, interrogātiōnis"
  ],
  "clarity": [
    "clāritās, clāritātis",
    "perspicuitās, perspicuitātis"
  ],
  "leafy": [
    "frondōsus, frondōsa, frondōsum"
  ],
  "full of leaves": [
    "frondōsus, frondōsa, frondōsum"
  ],
  "bracelet": [
    "armilla, -ae f",
    "armilla, armillae"
  ],
  "armlet": [
    "armilla, -ae f",
    "armilla, armillae"
  ],
  "serve zealously": [
    "dēserviō, dēservīre, dēservivī, dēservitum"
  ],
  "the aegisof zeus or jupiterof athena or minerva": [
    "aegis, aegidos or aegidis"
  ],
  "trainer": [
    "lanista, lanistae"
  ],
  "or manager of a team of gladiators": [
    "lanista, lanistae"
  ],
  "unweave or unravel": [
    "retexō, retexere, retexuī, retextum"
  ],
  "a samnite tribe who inhabited a region in south italy": [
    "hirpīnī, hirpīnōrum"
  ],
  "leap upon or in": [
    "īnsiliō, īnsilīre, īnsiluī"
  ],
  "a goddess": [
    "fidēs, fidēī"
  ],
  "a woodnymph": [
    "dryas, dryadis"
  ],
  "a dryad": [
    "dryas, dryadis"
  ],
  "sway to and fro": [
    "vacillō, vacillāre, vacillāvī, vacillātum"
  ],
  "become frequent": [
    "incrēbēscō, incrēbēscere, incrēbuī"
  ],
  "look or gaze out at": [
    "prospectō, prospectāre, prospectāvī, prospectātum"
  ],
  "frowardness": [
    "perversitās, perversitātis"
  ],
  "untowardness": [
    "perversitās, perversitātis"
  ],
  "perversity": [
    "perversitās, perversitātis"
  ],
  "hostile pursuit": [
    "īnsectātiō, īnsectātiōnis"
  ],
  "fly or flock together with others": [
    "convolō, convolāre, convolāvī, convolātum"
  ],
  "ripen or mature": [
    "mātūrēscō, mātūrēscere, mātūruī"
  ],
  "hedge": [
    "sēpēs, sēpis"
  ],
  "fence": [
    "sēpēs, sēpis",
    "Vende Omnibus"
  ],
  "threateningly": [
    "mināciter, minācius, minācissimē"
  ],
  "menacingly": [
    "mināciter, minācius, minācissimē"
  ],
  "premonition": [
    "praesāgium, praesāgiī"
  ],
  "presentiment": [
    "praesāgium, praesāgiī"
  ],
  "foreboding": [
    "praesāgium, praesāgiī"
  ],
  "exhalation": [
    "hālitus, hālitūs"
  ],
  "cisalpine": [
    "cisalpīnus, cisalpīna, cisalpīnum"
  ],
  "employer": [
    "conductor, conductōris"
  ],
  "entrepreneur": [
    "conductor, conductōris"
  ],
  "refutation": [
    "redargūtiō, redargūtiōnis"
  ],
  "bilgewater": [
    "sentīna, sentīnae"
  ],
  "beggary": [
    "mendīcitās, mendīcitātis"
  ],
  "mendicity": [
    "mendīcitās, mendīcitātis"
  ],
  "pauperism": [
    "mendīcitās, mendīcitātis"
  ],
  "cremona": [
    "cremōna, cremōnae"
  ],
  "curdle": [
    "concrēscō, concrēscere, concrēvī, concrētum"
  ],
  "stiffen": [
    "concrēscō, concrēscere, concrēvī, concrētum"
  ],
  "congeal": [
    "concrēscō, concrēscere, concrēvī, concrētum"
  ],
  "a designating": [
    "dēsignātiō, dēsignātiōnis"
  ],
  "describing": [
    "dēsignātiō, dēsignātiōnis"
  ],
  "marking out": [
    "dēsignātiō, dēsignātiōnis"
  ],
  "specification": [
    "dēsignātiō, dēsignātiōnis"
  ],
  "determine in advance": [
    "praestituō, praestituere, praestituī, praestitutum"
  ],
  "maltese": [
    "melitensis, melitense"
  ],
  "austere": [
    "austērus, austēra, austērum"
  ],
  "diversion": [
    "aberrātiō, aberrātiōnis"
  ],
  "fulmineous": [
    "fulmineus, fulminea, fulmineum"
  ],
  "polishing": [
    "expolītiō, expolītiōnis"
  ],
  "a mountain of thessaly": [
    "oeta, oetae"
  ],
  "where hercules ascended the funeral pile": [
    "oeta, oetae"
  ],
  "inquire or search diligently": [
    "perquīrō, perquīrere, perquīsīvī, perquīsītum"
  ],
  "a sponge": [
    "spongia, spongiae"
  ],
  "foam up": [
    "exaestuō, exaestuāre, exaestuāvī, exaestuātum"
  ],
  "or ferment": [
    "exaestuō, exaestuāre, exaestuāvī, exaestuātum"
  ],
  "arsenal": [
    "armāmentārium, armāmentāriī"
  ],
  "an inhabitant of heaven": [
    "caelicola, caelicolae"
  ],
  "deity": [
    "nūmen, nūminis",
    "deitās, deitātis",
    "caelicola, caelicolae"
  ],
  "situated between norba and velitrae": [
    "cora, corae"
  ],
  "now cori": [
    "cora, corae"
  ],
  "preface": [
    "prooemium, prooemiī",
    "praefātiō, praefātiōnis",
    "praescriptiō, praescriptiōnis"
  ],
  "with pain or sorrow": [
    "dolenter, dolentius, dolentissimē"
  ],
  "painfully": [
    "Crucabunde",
    "dolenter, dolentius, dolentissimē"
  ],
  "deep breath": [
    "suspirium, suspiriī"
  ],
  "euripides": [
    "eurīpidēs"
  ],
  "celebrated athenian tragic poet": [
    "eurīpidēs"
  ],
  "cleansing": [
    "pūrgātiō, pūrgātiōnis"
  ],
  "purging": [
    "pūrgātiō, pūrgātiōnis"
  ],
  "young slave": [
    "servolus, servolī",
    "servulus, servulī"
  ],
  "of illegitimate birth": [
    "spurius, spuria, spurium"
  ],
  "a celtic tribe of gallia belgica": [
    "lingonēs, lingonum",
    "suessiōnēs, suessiōnum"
  ],
  "which dwelt near the sources of the marne and seine": [
    "lingonēs, lingonum"
  ],
  "unskilful": [
    "īnscītus, īnscīta, īnscītum"
  ],
  "ho!": [
    "hui"
  ],
  "my word": [
    "hui"
  ],
  "join or add to": [
    "subiungō, subiungere, subiunxī, subiunctum"
  ],
  "polychrest": [
    "polychrestus, polychresta, polychrestum"
  ],
  "am terrified": [
    "exhorreō, exhorrēre, exhorruī"
  ],
  "tremble or shudder ": [
    "exhorreō, exhorrēre, exhorruī"
  ],
  "three-tined": [
    "tridēns, tridentis"
  ],
  "having three prongs or teeth": [
    "tridēns, tridentis"
  ],
  "incline to doubt": [
    "addubitō, addubitāre, addubitāvī, addubitātum"
  ],
  "begin to doubt": [
    "addubitō, addubitāre, addubitāvī, addubitātum"
  ],
  "feel doubt": [
    "addubitō, addubitāre, addubitāvī, addubitātum"
  ],
  "am uncertain": [
    "addubitō, addubitāre, addubitāvī, addubitātum"
  ],
  "scepticism": [
    "acatalēpsia, acatalēpsiae"
  ],
  "acatalepsy": [
    "acatalēpsia, acatalēpsiae"
  ],
  "mucus": [
    "mūcus, mūcī"
  ],
  "telescope": [
    "perspicillum, perspicillī"
  ],
  "glutton": [
    "helluō, helluōnis"
  ],
  "squanderer": [
    "helluō, helluōnis"
  ],
  "sistrum": [
    "sīstrum, sīstrī"
  ],
  "sharpness": [
    "ācrimōnia, ācrimōniae"
  ],
  "pungency": [
    "ācrimōnia, ācrimōniae"
  ],
  "that may be seen": [
    "vīsibilis, vīsibile"
  ],
  "limb by limb": [
    "membrātim"
  ],
  "very necessary": [
    "pernecessārius, pernecessāria, pernecessārium"
  ],
  "stoning": [
    "lapidātiō, lapidātiōnis"
  ],
  "an inland city of apulia": [
    "herdōnea, herdōneae"
  ],
  "on the way from canusium to beneventum": [
    "herdōnea, herdōneae"
  ],
  "now the town of ordona": [
    "herdōnea, herdōneae"
  ],
  "breathe into": [
    "īnspīrō, īnspīrāre, īnspīrāvī, īnspīrātum"
  ],
  "blow upon": [
    "īnspīrō, īnspīrāre, īnspīrāvī, īnspīrātum"
  ],
  "an ancient city in sicily": [
    "segesta, segestae",
    "tyndaris, tyndaridis"
  ],
  "disinherit": [
    "exhērēdō, exhērēdāre, exhērēdāvī, exhērēdātum"
  ],
  "denunciation": [
    "dēlātiō, dēlātiōnis"
  ],
  "shave": [
    "tondeō, tondēre, totondī, tōnsum"
  ],
  "shear": [
    "tondeō, tondēre, totondī, tōnsum"
  ],
  "clip": [
    "tondeō, tondēre, totondī, tōnsum"
  ],
  "a belt": [
    "balteus, balteī"
  ],
  "girdlea sub-cinctorium": [
    "balteus, balteī"
  ],
  "a papal garment": [
    "balteus, balteī"
  ],
  "wrongful": [
    "iniūriōsus, iniūriōsa, iniūriōsum"
  ],
  "spotted": [
    "maculōsus, maculōsa, maculōsum"
  ],
  "speckled": [
    "maculōsus, maculōsa, maculōsum"
  ],
  "dappled": [
    "maculōsus, maculōsa, maculōsum"
  ],
  "mottled": [
    "maculōsus, maculōsa, maculōsum"
  ],
  "variegated": [
    "maculōsus, maculōsa, maculōsum"
  ],
  "an ancient city of pieria situated on the coast": [
    "pydna, pydnae"
  ],
  "a kind of bean with an edible pod": [
    "phasēlus, phasēlī"
  ],
  "french bean": [
    "phasēlus, phasēlī"
  ],
  "kidney bean": [
    "phasēlus, phasēlī"
  ],
  "phasel": [
    "phasēlus, phasēlī"
  ],
  "whisper": [
    "susurrus, susurrī"
  ],
  "very familiar": [
    "perfamiliāris, perfamiliāre"
  ],
  "friendly etc": [
    "perfamiliāris, perfamiliāre"
  ],
  "a regular disposition": [
    "dispositiō, dispositiōnis"
  ],
  "a sacrificing": [
    "sacrificātus, sacrificātūs"
  ],
  "marauding": [
    "dēpopulātiō, dēpopulātiōnis"
  ],
  "sacking": [
    "dēpopulātiō, dēpopulātiōnis"
  ],
  "the sacred shield said to have fallen from heaven in the reign of numa. it was the palladium of rome": [
    "ancīle, ancīlis"
  ],
  "without impediment": [
    "expedītē, expedītius, expedītissimē"
  ],
  "rather less": [
    "minusculus, minuscula, minusculum"
  ],
  "cut short": [
    "abscīdō, abscīdere, abscīdī, abscīsum"
  ],
  "up to this point": [
    "hucusque"
  ],
  "until now": [
    "hucusque",
    "Adhuc nunc"
  ],
  "parting": [
    "dīgressiō, dīgressiōnis"
  ],
  "departing": [
    "dīgressiō, dīgressiōnis"
  ],
  "exile or banish": [
    "exterminō, extermināre, extermināvī, exterminātum"
  ],
  "a roman colony situated on the left bank of the liris": [
    "interamna, interamnae"
  ],
  "oats": [
    "avēna, avēnae"
  ],
  "concurrence": [
    "concursiō, concursiōnis"
  ],
  "juxtaposition": [
    "concursiō, concursiōnis"
  ],
  "a considerable town of umbria": [
    "pisaurum, pisaurī"
  ],
  "situated on the adriatic coast": [
    "pisaurum, pisaurī"
  ],
  "now the town of pesaro": [
    "pisaurum, pisaurī"
  ],
  "am warm": [
    "tepeō, tepēre, tepuī"
  ],
  "lukewarm or tepid": [
    "tepeō, tepēre, tepuī"
  ],
  "aigio": [
    "aegium, aegiī, locative aegiī"
  ],
  "protest or shout against": [
    "reclāmō, reclāmāre, reclāmāvī, reclāmātum"
  ],
  "contradict loudly": [
    "reclāmō, reclāmāre, reclāmāvī, reclāmātum"
  ],
  "stem": [
    "truncus, -ī",
    "culmus, culmī",
    "truncus, -ī (m)"
  ],
  "southern": [
    "austrālis, austrāle"
  ],
  "congius": [
    "congius, congii"
  ],
  "a unit of volume and weight": [
    "congius, congii"
  ],
  "rent paid for a stall": [
    "locārium, locāriī or locārī"
  ],
  "stall-money": [
    "locārium, locāriī or locārī"
  ],
  "stallage": [
    "locārium, locāriī or locārī"
  ],
  "catholic": [
    "catholicus, catholica, catholicum"
  ],
  "pertaining to all kinds of people and their range of tastes and proclivities": [
    "catholicus, catholica, catholicum"
  ],
  "swim over": [
    "trānō, trānāre, trānāvī, trānātum"
  ],
  "across or through": [
    "trānō, trānāre, trānāvī, trānātum"
  ],
  "a small brook": [
    "rīvulus, rīvulī"
  ],
  "rill": [
    "rīvulus, rīvulī"
  ],
  "rivulet": [
    "rīvulus, rīvulī"
  ],
  "a city of thessaly where caesar defeated pompey": [
    "pharsālus, pharsālī"
  ],
  "imagination": [
    "opīniō, opīniōnis",
    "imāginātiō, imāginātiōnis"
  ],
  "a preface": [
    "prooemium, prooemiī"
  ],
  "prelude": [
    "prooemium, prooemiī"
  ],
  "magnanimity": [
    "magnanimitās, magnanimitātis"
  ],
  "invent": [
    "fingō, fingere, finxī, fictum",
    "māchinor, māchinārī, māchinātus sum"
  ],
  "a tribe of aquitania mentioned by pliny": [
    "rutēnī, rutēnōrum"
  ],
  "bellows": [
    "Infremitor",
    "follis, follis"
  ],
  "complaining": [
    "querulus, querula, querulum"
  ],
  "querulous": [
    "querulus, querula, querulum"
  ],
  "violator": [
    "ruptor, ruptōris"
  ],
  "cretan": [
    "creticus, cretica, creticum"
  ],
  "a gladiator bearing thracian equipment": [
    "thraex, thraecis"
  ],
  "pine cone": [
    "pīneum, pīneī"
  ],
  "keenness": [
    "sagācitās, sagācitātis"
  ],
  "acuteness": [
    "sagācitās, sagācitātis"
  ],
  "a measure of half a sextary": [
    "hēmīna, hēmīnae"
  ],
  "break or burst forth or out": [
    "prōrumpō, prōrumpere, prōrūpī, prōruptum"
  ],
  "humanly": [
    "hūmāniter, hūmānius, hūmānissimē"
  ],
  "in a human manner": [
    "hūmāniter, hūmānius, hūmānissimē"
  ],
  "wooded": [
    "nemorōsus, nemorōsa, nemorōsum"
  ],
  "wash off or away": [
    "abluō, abluere, abluī, ablūtum"
  ],
  "cleanse": [
    "abluō, abluere, abluī, ablūtum"
  ],
  "purify": [
    "abluō, abluere, abluī, ablūtum"
  ],
  "count over": [
    "percēnseō, percēnsēre, percēnsuī, percēnsum"
  ],
  "reckon up": [
    "percēnseō, percēnsēre, percēnsuī, percēnsum"
  ],
  "go through": [
    "percēnseō, percēnsēre, percēnsuī, percēnsum",
    "perambulō, perambulāre, perambulāvī, perambulātum"
  ],
  "go over": [
    "percēnseō, percēnsēre, percēnsuī, percēnsum"
  ],
  "disordered": [
    "inordinātus, inordināta, inordinātum"
  ],
  "irregular": [
    "inordinātus, inordināta, inordinātum"
  ],
  "dyrrhachium": [
    "dyrrhachium, dyrrhachiī"
  ],
  "tract": [
    "plaga, plagae"
  ],
  "zone": [
    "plaga, plagae",
    "cingulum, cingulī"
  ],
  "burst asunder": [
    "dissiliō, dissilīre, dissiluī, dissilitum"
  ],
  "fly apart": [
    "dissiliō, dissilīre, dissiluī, dissilitum"
  ],
  "colour": [
    "fūcō, fūcāre, fūcāvī, fūcātum"
  ],
  "dye": [
    "fūcō, fūcāre, fūcāvī, fūcātum"
  ],
  "a water-snake": [
    "hydrus, hydrī"
  ],
  "attempt thoroughly": [
    "pertemptō, pertemptāre, pertemptāvī, pertemptātum"
  ],
  "affirmation": [
    "affirmātiō, affirmātiōnis"
  ],
  "assertion": [
    "affirmātiō, affirmātiōnis"
  ],
  "the act of turning back": [
    "reversiō, reversiōnis"
  ],
  "scantiness": [
    "exiguitās, exiguitātis"
  ],
  "implant": [
    "īnfīgō, īnfīgere, īnfīxī, īnfīxum"
  ],
  "break or crumble into small pieces": [
    "comminuō, comminuere, comminuī, comminutum"
  ],
  "pulverize or comminute": [
    "comminuō, comminuere, comminuī, comminutum"
  ],
  "festivity": [
    "fēstīvitās, fēstīvitātis"
  ],
  "mirth": [
    "fēstīvitās, fēstīvitātis"
  ],
  "unlearn": [
    "dēdiscō, dēdiscere, dēdidicī"
  ],
  "close association": [
    "sodālitās, sodālitātis"
  ],
  "an astronomer": [
    "astronomus, astronomī"
  ],
  "an augur": [
    "auspex, auspicis"
  ],
  "announce beforehand": [
    "praenūntiō, praenūntiāre, praenūntiāvī, praenūntiātum"
  ],
  "a city on the northern coast of sicily": [
    "hīmera, hīmerae",
    "solūs, solūntis"
  ],
  "situated near panormus": [
    "solūs, solūntis"
  ],
  "now solanto": [
    "solūs, solūntis"
  ],
  "squeamish": [
    "fastīdiōsus, fastīdiōsa, fastīdiōsum"
  ],
  "unmanageable": [
    "inhabilis, inhabile"
  ],
  "unwieldy": [
    "inhabilis, inhabile"
  ],
  "rashly": [
    "imprūdenter, imprūdentius, imprūdentissimē"
  ],
  "unwisely": [
    "imprūdenter, imprūdentius, imprūdentissimē"
  ],
  "weep over": [
    "dēfleō, dēflēre, dēflēvī, dēflētum"
  ],
  "cry for": [
    "dēfleō, dēflēre, dēflēvī, dēflētum"
  ],
  "proportioned": [
    "prōportiōnātus, prōportiōnāta, prōportiōnātum"
  ],
  "proportionate": [
    "prōportiōnātus, prōportiōnāta, prōportiōnātum"
  ],
  "the roman auxiliaries' short javelin": [
    "lancea, lanceae"
  ],
  "a light spear or lance": [
    "lancea, lanceae"
  ],
  "a hissing": [
    "sībilus, sībilī"
  ],
  "whistling": [
    "sībilus, sībilī"
  ],
  "a four horse team": [
    "quadrīgae, quadrīgārum"
  ],
  "especially used for chariot racing": [
    "quadrīgae, quadrīgārum"
  ],
  "make worse": [
    "pēiōrō, pēiōrāre, pēiōrāvī, pēiōrātum"
  ],
  "aggravate": [
    "pēiōrō, pēiōrāre, pēiōrāvī, pēiōrātum"
  ],
  "amendment": [
    "corrēctiō, corrēctiōnis"
  ],
  "deprive of feeling": [
    "sōpiō, sōpīre, sōpīvī, sōpītum"
  ],
  "cushion": [
    "pulvīnus, pulvīnī"
  ],
  "pillow": [
    "pulvīnus, pulvīnī"
  ],
  "bolster": [
    "pulvīnus, pulvīnī"
  ],
  "a bowl": [
    "catīnum, catīnī"
  ],
  "senseless": [
    "excors, excordis",
    "stupidus, stupida, stupidum"
  ],
  "of or pertaining to the sun": [
    "sōlāris, sōlāre"
  ],
  "solar": [
    "sōlāris, sōlāre"
  ],
  "accounts": [
    "ratiōnālis, ratiōnāle"
  ],
  "of the east": [
    "orientālis, orientāle"
  ],
  "for spying or scouting": [
    "speculātōrius, speculātōria, speculātōrium"
  ],
  "a ligurian tribe of italy": [
    "apuānī, apuānōrum",
    "ingaunī, ingaunōrum"
  ],
  "hen": [
    "gallīna, gallīnae"
  ],
  "incarceration": [
    "incarcerātiō, incarcerātiōnis"
  ],
  "imprisonment": [
    "incarcerātiō, incarcerātiōnis"
  ],
  "a hazel or filbert shrub": [
    "corylus, corylī"
  ],
  "the getting or fetching of water": [
    "aquātiō, aquātiōnis"
  ],
  "remarkably": [
    "īnsigniter"
  ],
  "prominently": [
    "īnsigniter"
  ],
  "enticement": [
    "illecebra, illecebrae"
  ],
  "lure": [
    "illecebra, illecebrae"
  ],
  "a white or purple toga": [
    "trabea, trabeae"
  ],
  "or possibly mantle": [
    "trabea, trabeae"
  ],
  "ornamented with red or purple stripes": [
    "trabea, trabeae"
  ],
  "associated with the equestrian class": [
    "trabea, trabeae"
  ],
  "woolly substance": [
    "lānūgō, lānūginis"
  ],
  "the down of plants": [
    "lānūgō, lānūginis"
  ],
  "of youthful cheeks": [
    "lānūgō, lānūginis"
  ],
  "settle in a place": [
    "resīdō, resīdere, resēdī, resēssum"
  ],
  "situated between panormus and cephaloedium": [
    "hīmera, hīmerae"
  ],
  "persephone": [
    "persephonē, persephonēs or persephonae"
  ],
  "untried": [
    "inexpertus, inexperta, inexpertum"
  ],
  "connect or fasten to": [
    "innectō, innectere, innexuī, innexum"
  ],
  "together or about": [
    "innectō, innectere, innexuī, innexum"
  ],
  "turn up or loosen the soil around a tree —ablaqueate": [
    "ablaqueō, ablaqueāre, ablaqueāvī, ablaqueātum"
  ],
  "deceptively": [
    "fallāciter, fallācius, fallācissimē"
  ],
  "deceitfully": [
    "fallāciter, fallācius, fallācissimē"
  ],
  "exhort": [
    "hortor, hortārī, hortātus sum",
    "exhortor, exhortārī, exhortātus sum"
  ],
  "crossbeam": [
    "trānstrum, trānstrī"
  ],
  "transom": [
    "trānstrum, trānstrī"
  ],
  "to be fluid or liquid": [
    "līquor, līquī"
  ],
  "changeably": [
    "incōnstanter, incōnstantius, incōnstantissimē"
  ],
  "capriciously etc": [
    "incōnstanter, incōnstantius, incōnstantissimē"
  ],
  "the robbing of a temple": [
    "sacrilegium, sacrilegiī"
  ],
  "stealing of sacred objects": [
    "sacrilegium, sacrilegiī"
  ],
  "sacrilege": [
    "sacrilegium, sacrilegiī"
  ],
  "a district in macedonia": [
    "pīeria, pīeriae"
  ],
  "a hired servant": [
    "metellus, metellī"
  ],
  "flaming": [
    "flammeus, flammea, flammeum"
  ],
  "that way": [
    "illāc"
  ],
  "that side": [
    "illāc"
  ],
  "along that path": [
    "illāc"
  ],
  "over there": [
    "illāc"
  ],
  "unafraid": [
    "interritus, interrita, interritum"
  ],
  "a celtic tribe of gallia lugdunensis": [
    "aulercī, aulercōrum",
    "parīsiī, parīsiōrum"
  ],
  "a mark": [
    "līneāmentum, līneāmentī"
  ],
  "sit upon or above": [
    "supersedeō, supersedēre, supersēdī, supersessum"
  ],
  "preside over": [
    "supersedeō, supersedēre, supersēdī, supersessum"
  ],
  "government": [
    "polītīa, polītīae"
  ],
  "being": [
    "essentia, essentiae"
  ],
  "famed": [
    "fāmōsus, fāmōsa, fāmōsum"
  ],
  "noted": [
    "fāmōsus, fāmōsa, fāmōsum"
  ],
  "caesium": [
    "caesium, caesiī"
  ],
  "bag": [
    "pēra, pērae"
  ],
  "satchel": [
    "Cibisin",
    "Cibisis",
    "pēra, pērae"
  ],
  "a grape-gathering": [
    "vīndēmia, vīndēmiae"
  ],
  "vintage": [
    "vīndēmia, vīndēmiae",
    "vindēmiātiō, vindēmiātiōnis"
  ],
  "sobbing": [
    "singultus, singultūs"
  ],
  "speech interrupted by sobs": [
    "singultus, singultūs"
  ],
  "tongs": [
    "Forpices",
    "forceps, forcipis"
  ],
  "pincers": [
    "forceps, forcipis"
  ],
  "forceps": [
    "forceps, forcipis"
  ],
  "runny nose": [
    "distillatiō, distillatiōnis"
  ],
  "marriageable": [
    "nūbilis, nūbile"
  ],
  "diminutive of columna: a small column or pillar": [
    "columella, columellae"
  ],
  "shallow bowl or basin": [
    "pelvis, pelvis"
  ],
  "a river of greece forming the boundary between acarnania and aetolia": [
    "achelōus, achelōī"
  ],
  "a tribe of hispania baetica who lived near the turduli": [
    "turdētānī, turdētānōrum"
  ],
  "on two sides": [
    "bifāriam"
  ],
  "in two parts or places": [
    "bifāriam"
  ],
  "fasten before": [
    "praefīgō, praefīgere, praefīxī, praefīxum"
  ],
  "attach in front": [
    "praefīgō, praefīgere, praefīxī, praefīxum"
  ],
  "ctesiphon": [
    "ctēsiphōn, ctēsiphōntis"
  ],
  "uniform": [
    "similāris, similāre"
  ],
  "a dance in a ring": [
    "chorēa, chorēae"
  ],
  "round dance": [
    "chorēa, chorēae"
  ],
  "polish": [
    "expoliō, expolīre, expolīvī, expolītum"
  ],
  "remove the nerves and sinews": [
    "ēnervō, ēnervāre, ēnervāvī, ēnervātum"
  ],
  "asparagus": [
    "asparagus, asparagī"
  ],
  "sprout-like asparagus": [
    "asparagus, asparagī"
  ],
  "samphire": [
    "asparagus, asparagī"
  ],
  "ally": [
    "sociō, sociāre, sociāvī, sociātum"
  ],
  "am bright": [
    "splendeō, splendēre, splenduī"
  ],
  "mistrust": [
    "diffīdentia, diffīdentiae"
  ],
  "a fallow deer": [
    "damma, dammae"
  ],
  "a dissolute man": [
    "asōtus, asōtī"
  ],
  "debauchee": [
    "asōtus, asōtī"
  ],
  "affable": [
    "cōmis, cōme"
  ],
  "gracious": [
    "cōmis, cōme"
  ],
  "reecho or resound with something on every side": [
    "circumsonō, circumsonāre, circumsonuī, circumsonātum"
  ],
  "am filled with a sound": [
    "circumsonō, circumsonāre, circumsonuī, circumsonātum"
  ],
  "chant etc. together": [
    "concinō, concinere, concinuī"
  ],
  "unadorned": [
    "incomptus, incompta, incomptum",
    "inōrnātus, inōrnāta, inōrnātum"
  ],
  "university": [
    "ūniversitās, ūniversitātis"
  ],
  "lowliest": [
    "humillimus, humillima, humillimum"
  ],
  "humblest": [
    "humillimus, humillima, humillimum"
  ],
  "most or very low": [
    "humillimus, humillima, humillimum"
  ],
  "lowly or humble": [
    "humillimus, humillima, humillimum"
  ],
  "calming": [
    "sēdātiō, sēdātiōnis"
  ],
  "boil or seethe together": [
    "concoquō, concoquere, concoxī, concoctum"
  ],
  "cook thoroughly": [
    "concoquō, concoquere, concoxī, concoctum"
  ],
  "concoct": [
    "concoquō, concoquere, concoxī, concoctum"
  ],
  "situated between gabii and the river anio": [
    "collātia, collātiae"
  ],
  "a ringing": [
    "tinnītus, tinnītūs"
  ],
  "jingling": [
    "tinnītus, tinnītūs"
  ],
  "tinkling": [
    "tinnītus, tinnītūs"
  ],
  "adamant": [
    "adamās, adamantis"
  ],
  "the hardest steel or iron": [
    "adamās, adamantis"
  ],
  "diamond": [
    "Adamanteum",
    "adamās, adamantis"
  ],
  "an object made of adamant": [
    "adamās, adamantis"
  ],
  "defecate": [
    "stercorō, stercorāre, stercorāvī, stercorātum"
  ],
  "municipal": [
    "mūnicipālis, mūnicipāle"
  ],
  "hinderance": [
    "interpellātiō, interpellātiōnis"
  ],
  "am dainty or fond of luxuries": [
    "ligūriō, ligūrīre, ligūrīvī, ligūritum"
  ],
  "king of syracuse and son of hiero": [
    "gelō, gelōnis"
  ],
  "the egadi islands": [
    "aegātēs, aegātium"
  ],
  "of or relating to a hero": [
    "hērōus, hērōa, hērōum"
  ],
  "see first or beforehand": [
    "praevideō, praevidēre, praevīdī, praevīsum"
  ],
  "undecorated": [
    "inōrnātus, inōrnāta, inōrnātum"
  ],
  "loving": [
    "amātōrius, amātōria, amātōrium"
  ],
  "amorous": [
    "amātōrius, amātōria, amātōrium"
  ],
  "relating to love": [
    "amātōrius, amātōria, amātōrium"
  ],
  "amatory": [
    "amātōrius, amātōria, amātōrium"
  ],
  "hide or conceal myself": [
    "latēscō, latēscere, latī"
  ],
  "enter into the account": [
    "imputō, imputāre, imputāvī, imputātum"
  ],
  "how little": [
    "quantulus, quantula, quantulum"
  ],
  "small or trifling": [
    "quantulus, quantula, quantulum"
  ],
  "the act of unfolding": [
    "explicātus, explicātūs"
  ],
  "unfurling or stretching apart": [
    "explicātus, explicātūs"
  ],
  "am in labour": [
    "parturiō, parturīre, parturīvī, parturītum"
  ],
  "endow": [
    "dōtō, dōtāre, dōtāvī, dōtātum"
  ],
  "call aside or away": [
    "sēvocō, sēvocāre, sēvocāvī, sēvocātum"
  ],
  "practice augury": [
    "inaugurō, inaugurāre, inaugurāvī, inaugurātum"
  ],
  "take omens from the flight of birds": [
    "inaugurō, inaugurāre, inaugurāvī, inaugurātum"
  ],
  "an ancient greek given name": [
    "hērāclītus, hērāclītī"
  ],
  "decayed": [
    "putridus, putrida, putridum"
  ],
  "bad luck": [
    "īnfēlīcitās, īnfēlīcitātis"
  ],
  "conduct or behaviour": [
    "conversātiō, conversātiōnis"
  ],
  "animate": [
    "animālis, animāle",
    "animō, animāre, animāvī, animātum"
  ],
  "a boxer": [
    "pugil, pugilis"
  ],
  "pugilist": [
    "pugil, pugilis"
  ],
  "an alder": [
    "alnus, alnī"
  ],
  "poison hemlock": [
    "cicūta, cicūtae"
  ],
  "probably either conium maculatum or cicuta virosa": [
    "cicūta, cicūtae"
  ],
  "kindle or set on fire": [
    "succendō, succendere, succendī, succēnsum"
  ],
  "a celtiberian tribe settled on the plains of the central duero valley": [
    "vaccaeī, vaccaeōrum"
  ],
  "sidon": [
    "sīdōn, sīdōnis"
  ],
  "burn down": [
    "dēflagrō, dēflagrāre, dēflagrāvī, dēflagrātum"
  ],
  "schoolmate": [
    "condiscipulus, condiscipulī"
  ],
  "of or pertaining to corduba": [
    "cordubēnsis, cordubēnse"
  ],
  "call down": [
    "dēvocō, dēvocāre, dēvocāvī, dēvocātum"
  ],
  "princess": [
    "prīncipissa, prīncipissae"
  ],
  "come continually nearer to a point": [
    "adventō, adventāre, adventāvī, adventātum"
  ],
  "press forward": [
    "adventō, adventāre, adventāvī, adventātum"
  ],
  "march on": [
    "adventō, adventāre, adventāvī, adventātum"
  ],
  "come to": [
    "adventō, adventāre, adventāvī, adventātum"
  ],
  "draw near": [
    "adventō, adventāre, adventāvī, adventātum"
  ],
  "a town of achaia situated on the coast between the rivers selinus and cerynites": [
    "helicē, helicēs"
  ],
  "a pre-roman tribe of the interior of sardinia": [
    "iliēnsēs, iliēnsium"
  ],
  "of or belonging to the same family or gēns": [
    "gentīlis, gentīle"
  ],
  "reply": [
    "respōnsiō, respōnsiōnis"
  ],
  "response": [
    "respōnsiō, respōnsiōnis"
  ],
  "cutting off or up": [
    "sectiō, sectiōnis"
  ],
  "determine or fix beforehand": [
    "praefiniō, praefinīre, praefinīvī, praefinītum"
  ],
  "am white": [
    "albeō, albēre"
  ],
  "lock": [
    "Clausura",
    "clausūra, clausūrae"
  ],
  "robbing": [
    "spoliātiō, spoliātiōnis"
  ],
  "contradiction": [
    "dīversitās, dīversitātis"
  ],
  "the juniper tree": [
    "cedrus, cedrī"
  ],
  "juniperus oxycedrus": [
    "cedrus, cedrī"
  ],
  "sever or rupture": [
    "dīrumpō, dīrumpere, dīrūpī, dīruptum"
  ],
  "who dwelt at the foot of the maritime alps": [
    "ingaunī, ingaunōrum"
  ],
  "am revived": [
    "renāscor, renāscī, renātus sum"
  ],
  "renewed": [
    "renāscor, renāscī, renātus sum"
  ],
  "interrogate": [
    "scīscitor, scīscitārī, scīscitātus sum"
  ],
  "animated": [
    "vīvidus, vīvida, vīvidum"
  ],
  "grant or award something to someone as a judge": [
    "adiūdicō, adiūdicāre, adiūdicāvī, adiūdicātum"
  ],
  "adjudge": [
    "adiūdicō, adiūdicāre, adiūdicāvī, adiūdicātum"
  ],
  "stand or jut out": [
    "prōmineō, prōminēre, prōminuī"
  ],
  "am prominent": [
    "prōmineō, prōminēre, prōminuī"
  ],
  "make strong": [
    "corrōborō, corrōborāre, corrōborāvī, corrōborātum"
  ],
  "invigorate": [
    "corrōborō, corrōborāre, corrōborāvī, corrōborātum"
  ],
  "corroborate": [
    "corrōborō, corrōborāre, corrōborāvī, corrōborātum"
  ],
  "a trumpeter especially in an army at war but also at sacrifices or funerals": [
    "tubicen, tubicinis"
  ],
  "executioner": [
    "tortor, tortōris"
  ],
  "torturer": [
    "Tormentor",
    "tortor, tortōris"
  ],
  "the act of putting": [
    "cōnstructiō, cōnstructiōnis"
  ],
  "placing or joining together": [
    "cōnstructiō, cōnstructiōnis"
  ],
  "a town of samnium": [
    "satīcula, satīculae"
  ],
  "situated near the frontiers with campania": [
    "satīcula, satīculae"
  ],
  "stubbornly": [
    "contumāciter, contumācius, contumācissimē"
  ],
  "inflexibly": [
    "contumāciter, contumācius, contumācissimē"
  ],
  "half-demolished": [
    "sēmirutus, sēmiruta, sēmirutum"
  ],
  "mantua": [
    "mantua, mantuae"
  ],
  "vomit up": [
    "ēvomō, ēvomere, ēvomuī, ēvomitum"
  ],
  "a half": [
    "sēmis, sēmissis"
  ],
  "a half-unit": [
    "sēmis, sēmissis"
  ],
  "on the way": [
    "obiter"
  ],
  "intent": [
    "intēnsus, intēnsa, intēnsum"
  ],
  "lick": [
    "lambō, lambere, lambī, lambitum"
  ],
  "unrestrainedly": [
    "intemperanter, intemperantius, intemperantissimē"
  ],
  "eightfold": [
    "octuplus, octupla, octuplum"
  ],
  "magical": [
    "magicus, magica, magicum"
  ],
  "the act of carrying out": [
    "ēlātiō, ēlātiōnis"
  ],
  "carrying to a grave": [
    "ēlātiō, ēlātiōnis"
  ],
  "burial": [
    "ēlātiō, ēlātiōnis",
    "sepultūra, sepultūrae"
  ],
  "a branched candlestick": [
    "candēlābrum, candēlābrī"
  ],
  "chandelier": [
    "candēlābrum, candēlābrī"
  ],
  "candelabrum": [
    "candēlābrum, candēlābrī"
  ],
  "lampstand": [
    "candēlābrum, candēlābrī"
  ],
  "become hard": [
    "indūrēscō, indūrēscere, indūruī"
  ],
  "a city of apulia separated from the coast of adriatic by a saltwater lake": [
    "salapia, salapiae"
  ],
  "now salpi": [
    "salapia, salapiae"
  ],
  "basely": [
    "humiliter, humilius, humilissimē"
  ],
  "meanly": [
    "humiliter, humilius, humilissimē"
  ],
  "a plectrum": [
    "plēctrum, plēctrī"
  ],
  "tool for playing a stringed instrument": [
    "plēctrum, plēctrī"
  ],
  "specific": [
    "speciālis, speciāle"
  ],
  "vetch": [
    "vicia, viciae"
  ],
  "two-way": [
    "bivius, bivia, bivium"
  ],
  "containing or full of sand": [
    "harēnōsus, harēnōsa, harēnōsum"
  ],
  "sandy": [
    "harēnōsus, harēnōsa, harēnōsum"
  ],
  "run to or against": [
    "incursō, incursāre, incursāvī, incursātum"
  ],
  "dash or strike against": [
    "incursō, incursāre, incursāvī, incursātum"
  ],
  "charge at": [
    "incursō, incursāre, incursāvī, incursātum"
  ],
  "the father of phoebe and hilaira": [
    "leucippus, leucippī"
  ],
  "who were carried off by castor and pollux": [
    "leucippus, leucippī"
  ],
  "of or pertaining to milk": [
    "lacteus, lactea, lacteum"
  ],
  "an athenian statesman and general": [
    "cīmōn, cīmōnis"
  ],
  "son of miltiades": [
    "cīmōn, cīmōnis"
  ],
  "slanderous": [
    "maledicus, maledica, maledicum"
  ],
  "or tumble about": [
    "volūtō, volūtāre, volūtāvī, volūtātum"
  ],
  "buffoonlike": [
    "scurrīlis, scurrīle"
  ],
  "scurrilous": [
    "scurrīlis, scurrīle"
  ],
  "counselor": [
    "monitor, monitōris"
  ],
  "a sheepfold": [
    "ovīle, ovīlis"
  ],
  "andros": [
    "andros, andrī"
  ],
  "pilot fish": [
    "pompilus, pompilī"
  ],
  "the upper": [
    "aethra, aethrae"
  ],
  "pure air": [
    "aethra, aethrae"
  ],
  "the bright": [
    "aethra, aethrae"
  ],
  "serene sky": [
    "aethra, aethrae"
  ],
  "the air": [
    "aethra, aethrae"
  ],
  "heavens": [
    "aethra, aethrae"
  ],
  "a girdle which encircles the hips": [
    "cingulum, cingulī"
  ],
  "sword-belt": [
    "cingulum, cingulī"
  ],
  "sash": [
    "cingulum, cingulī"
  ],
  "habitable": [
    "habitābilis, habitābile"
  ],
  "dry up": [
    "exārēscō, exārēscere, exaruī"
  ],
  "take again": [
    "resūmō, resūmere, resūmpsī, resūmptum"
  ],
  "take back": [
    "recipiō, recipere, recēpī, receptum",
    "resūmō, resūmere, resūmpsī, resūmptum"
  ],
  "hammer": [
    "Malleus",
    "malleus, malleī"
  ],
  "mallet": [
    "malleus, malleī"
  ],
  "a city of the hernici in latium situated between anagnia and frusino": [
    "ferentīnum, ferentīnī"
  ],
  "now ferentino": [
    "ferentīnum, ferentīnī"
  ],
  "spherical": [
    "globōsus, globōsa, globōsum"
  ],
  "globular": [
    "globōsus, globōsa, globōsum"
  ],
  "two-horned": [
    "bicornis, bicorne"
  ],
  "twisting": [
    "tortuōsus, tortuōsa, tortuōsum"
  ],
  "tortuous": [
    "tortuōsus, tortuōsa, tortuōsum"
  ],
  "become dry": [
    "ārēscō, ārēscere, āruī"
  ],
  "experimental": [
    "experimentalis, experimentale"
  ],
  "prescient": [
    "praescius, praescia, praescium"
  ],
  "wear out": [
    "terō, terere, trīvī, trītum",
    "obsolēscō, obsolēscere, obsolēvī, obsolētum"
  ],
  "unmarried": [
    "caelebs, caelibis",
    "innuptus, innupta, innuptum"
  ],
  "linden or lime tree": [
    "tilia, tiliae"
  ],
  "the southwestern region of thessaly": [
    "dolopia, dolopiae"
  ],
  "urn": [
    "Urna",
    "Urnam",
    "hydria, hydriae"
  ],
  "atlantic": [
    "ātlanticus, ātlantica, ātlanticum"
  ],
  "link": [
    "cōnectō, cōnectere, cōnexuī, cōnexum"
  ],
  "hardly yet": [
    "vixdum"
  ],
  "drop in": [
    "īnstillō, īnstillāre, īnstillāvī, īnstillātum"
  ],
  "in debt": [
    "obaerātus, obaerāta, obaerātum"
  ],
  "fabric": [
    "textile, textilis"
  ],
  "textile": [
    "textile, textilis"
  ],
  "canvas": [
    "textile, textilis"
  ],
  "piece of cloth": [
    "textile, textilis"
  ],
  "mix or mingle together": [
    "commisceō, commiscēre, commiscuī, commixtum"
  ],
  "passionately": [
    "ārdenter, ārdentius, ārdentissimē"
  ],
  "ardently": [
    "ārdenter, ārdentius, ārdentissimē"
  ],
  "lessen or diminish": [
    "imminuō, imminuere, imminuī, imminūtum"
  ],
  "rifle": [
    "sclopētum, sclopētī"
  ],
  "a stone of which precious vessels were made": [
    "murra, murrae"
  ],
  "emit an odor": [
    "adoleō, adolēre"
  ],
  "smell": [
    "odor, odōris",
    "adoleō, adolēre"
  ],
  "bearer of the sacred casket": [
    "cistophorus, cistophorī"
  ],
  "am liquid": [
    "liqueō, liquēre, licuī"
  ],
  "approve of": [
    "cōnscīscō, cōnscīscere, cōnscīvī, cōnscītum"
  ],
  "ordain": [
    "cōnscīscō, cōnscīscere, cōnscīvī, cōnscītum"
  ],
  "enact": [
    "cōnscīscō, cōnscīscere, cōnscīvī, cōnscītum"
  ],
  "determine or resolve upon something": [
    "cōnscīscō, cōnscīscere, cōnscīvī, cōnscītum"
  ],
  "hunting-spear": [
    "vēnābulum, vēnābulī"
  ],
  "borrowing": [
    "mūtuātiō, mūtuātiōnis"
  ],
  "club-bearing": [
    "clāviger, clāvigera, clāvigerum"
  ],
  "tinge": [
    "colōrō, colōrāre, colōrāvī, colōrātum"
  ],
  "imbue": [
    "colōrō, colōrāre, colōrāvī, colōrātum"
  ],
  "leave it in its ancient state": [
    "antīquō, antīquāre, antīquāvī, antīquātum"
  ],
  "restore something to its former condition": [
    "antīquō, antīquāre, antīquāvī, antīquātum"
  ],
  "a mock sale or purchase": [
    "coemptiō, coemptiōnis"
  ],
  "especially one used to free a wife from marriage obligations": [
    "coemptiō, coemptiōnis"
  ],
  "priapus": [
    "priāpus, priāpī"
  ],
  "god of procreation": [
    "priāpus, priāpī"
  ],
  "whose": [
    "cūius, cūia, cūium"
  ],
  "infusion": [
    "īnfūsiō, īnfūsiōnis"
  ],
  "gael": [
    "scōtus, scōtī"
  ],
  "a person from ireland or scotland": [
    "scōtus, scōtī"
  ],
  "port city near rome": [
    "lāvīnium, lāvīniī"
  ],
  "founded by aeneas": [
    "lāvīnium, lāvīniī"
  ],
  "learn in addition to or further": [
    "addiscō, addiscere, addidicī"
  ],
  "set on": [
    "īnstīgō, īnstīgāre, īnstīgāvī, īnstīgātum"
  ],
  "rouse or urge": [
    "īnstīgō, īnstīgāre, īnstīgāvī, īnstīgātum"
  ],
  "throw or hurl": [
    "iaculor, iaculārī, iaculātus sum"
  ],
  "exorable": [
    "exōrābilis, exōrābile"
  ],
  "nuisance": [
    "nocumentum, nocumentī"
  ],
  "vertical": [
    "verticitās, verticitātis"
  ],
  "reddish brown": [
    "badius, badia, badium"
  ],
  "chestnut colored": [
    "badius, badia, badium"
  ],
  "bay": [
    "badius, badia, badium"
  ],
  "ferryman": [
    "Portitor",
    "portitor, portitōris"
  ],
  "is it unseemly or unsuitable": [
    "dēdecet, dēdecēre, dēdecuit"
  ],
  "it is unbecoming": [
    "dēdecet, dēdecēre, dēdecuit"
  ],
  "a kind of clover": [
    "cytisus, cytisī"
  ],
  "the mountain athos": [
    "athōs, athō"
  ],
  "melting": [
    "tābes, tābis"
  ],
  "dwindling": [
    "tābes, tābis"
  ],
  "consumption": [
    "tābes, tābis"
  ],
  "the river velino": [
    "velīnus, velīnī"
  ],
  "cool )": [
    "refrīgerō, refrīgerāre, refrīgerāvī, refrīgerātum"
  ],
  "small ladle or scoop": [
    "trulla, trullae"
  ],
  "am in hiding": [
    "latitō, latitāre, latitāvī, latitātum"
  ],
  "spend the night": [
    "pernoctō, pernoctāre, pernoctāvī, pernoctātum"
  ],
  "bibracte": [
    "bibracte, bibractis"
  ],
  "eat greedily": [
    "lurcō, lurcāre, lurcāvī, lurcātum"
  ],
  "guzzle": [
    "lurcō, lurcāre, lurcāvī, lurcātum"
  ],
  "towered": [
    "turrītus, turrīta, turrītum"
  ],
  "turreted": [
    "turrītus, turrīta, turrītum"
  ],
  "a deception": [
    "frūstrātiō, frūstrātiōnis"
  ],
  "melt or liquefy": [
    "liquefaciō, liquefacere, liquefēcī, liquefactum"
  ],
  "dialogue": [
    "dialogus, dialogī"
  ],
  "accursed": [
    "exsēcrābilis, exsēcrābile"
  ],
  "interview": [
    "congressiō, congressiōnis"
  ],
  "congress": [
    "congressiō, congressiōnis"
  ],
  "observer": [
    "servātor, servātōris"
  ],
  "pettifogger": [
    "calumniātor, calumniātōris"
  ],
  "from somewhere": [
    "alicunde"
  ],
  "from any place": [
    "alicunde"
  ],
  "a male given name equivalent to isidore": [
    "isidorus, isidorī"
  ],
  "lacerate": [
    "accipitrō, accipitrāre"
  ],
  "lattice": [
    "cancellus, cancelli"
  ],
  "grate": [
    "Clathrum",
    "cancellus, cancelli"
  ],
  "grid": [
    "cancellus, cancelli"
  ],
  "dictatorial": [
    "dictātōrius, dictātōria, dictātōrium"
  ],
  "pale yellow": [
    "lūridus, lūrida, lūridum"
  ],
  "wan": [
    "lūridus, lūrida, lūridum"
  ],
  "sallow": [
    "lūridus, lūrida, lūridum"
  ],
  "lurid": [
    "lūridus, lūrida, lūridum"
  ],
  "phylarch": [
    "phylarchus, phylarchī"
  ],
  "confidently": [
    "audāciter, audācius, audācissimē"
  ],
  "jump over": [
    "trānsiliō, trānsilīre, trānsiluī"
  ],
  "leap across": [
    "trānsiliō, trānsilīre, trānsiluī"
  ],
  "add to a heap": [
    "accumulō, accumulāre, accumulāvī, accumulātum"
  ],
  "accumulate": [
    "accumulō, accumulāre, accumulāvī, accumulātum"
  ],
  "amass": [
    "accumulō, accumulāre, accumulāvī, accumulātum"
  ],
  "surge": [
    "fluctuō, fluctuāre, fluctuāvī, fluctuātum"
  ],
  "undulate": [
    "fluctuō, fluctuāre, fluctuāvī, fluctuātum"
  ],
  "hawk": [
    "Accipiter",
    "accipiter, accipitris"
  ],
  "merlin": [
    "accipiter, accipitris"
  ],
  "blooming": [
    "flōridus, flōrida, flōridum"
  ],
  "contrite": [
    "contrītus, contrīta, contrītum"
  ],
  "penitent": [
    "contrītus, contrīta, contrītum"
  ],
  "half-asleep": [
    "sēmisomnus, sēmisomna, sēmisomnum"
  ],
  "drowsy": [
    "sēmisomnus, sēmisomna, sēmisomnum",
    "dormītō, dormītāre, dormītāvī, dormītātum"
  ],
  "a chestnut tree": [
    "castanea, castaneae"
  ],
  "confluence": [
    "cōnfluēns, cōnfluentis"
  ],
  "of a hill": [
    "collīnus, collīna, collīnum"
  ],
  "growing on a hill": [
    "collīnus, collīna, collīnum"
  ],
  "close or screw up the eyes": [
    "cōnīveō, cōnīvēre, cōnīvī"
  ],
  "blink": [
    "cōnīveō, cōnīvēre, cōnīvī"
  ],
  "wink": [
    "cōnīveō, cōnīvēre, cōnīvī"
  ],
  "a festival held in honor of the goddess minerva": [
    "quīnquātrūs, quīnquātruum"
  ],
  "held on the fifth day after the ides": [
    "quīnquātrūs, quīnquātruum"
  ],
  "a small ladle": [
    "cyathus, cyathī"
  ],
  "a town of phocis situated on a peninsula on a bay of the corinthian gulf": [
    "anticyra, anticyrae"
  ],
  "emit a scent or odor": [
    "redoleō, redolēre, redoluī"
  ],
  "a cupboard": [
    "armārium, armāriī"
  ],
  "closet": [
    "armārium, armāriī"
  ],
  "chest": [
    "Arcam",
    "Arca",
    "arca -ae",
    "arca -ae (f)",
    "armārium, armāriī"
  ],
  "especially one in the sacristy of a church where vestments are kept": [
    "armārium, armāriī"
  ],
  "impetuously": [
    "violenter, violentius, violentissimē"
  ],
  "a spice": [
    "condīmentum, condīmentī"
  ],
  "seasoning": [
    "condīmentum, condīmentī"
  ],
  "suck in": [
    "sorbeō, sorbēre, sorbuī, sorbitum"
  ],
  "drink up": [
    "sorbeō, sorbēre, sorbuī, sorbitum"
  ],
  "a small box": [
    "pyxis, pyxidis"
  ],
  "for holding medicines or toiletries": [
    "pyxis, pyxidis"
  ],
  "female mule": [
    "mūla, mūlae"
  ],
  "she-mule": [
    "mūla, mūlae"
  ],
  "sarcastic": [
    "dicāx, dicācis"
  ],
  "frozen": [
    "glaciālis, glaciāle"
  ],
  "glacial": [
    "glaciālis, glaciāle"
  ],
  "rust": [
    "rōbīgō, rōbīginis",
    "ferrūgō, ferrūginis"
  ],
  "specifically iron-rust or its colour": [
    "ferrūgō, ferrūginis"
  ],
  "a hole": [
    "lacūna, lacūnae"
  ],
  "lying together": [
    "concubitus, concubitūs"
  ],
  "winged": [
    "volātilis, volātile"
  ],
  "gambler": [
    "Braviator",
    "āleātor, āleātōris"
  ],
  "gamester": [
    "āleātor, āleātōris"
  ],
  "bugle": [
    "būcina, būcinae"
  ],
  "cry out to": [
    "inclāmō, inclāmāre, inclāmāvī, inclāmātum"
  ],
  "a gift": [
    "stips, stipis"
  ],
  "laurentum": [
    "laurentum, laurentī"
  ],
  "a maritime town in latium between ostia and lavinium ": [
    "laurentum, laurentī"
  ],
  "of casīnum": [
    "casīnus, casīna, casīnum"
  ],
  "small fire or flame": [
    "igniculus, igniculī), second declension (diminutive of: ignis",
    "igniculus, igniculī); second declension (Diminutive of: ignis"
  ],
  "an eagerness for something": [
    "aviditās, aviditātis"
  ],
  "avidity": [
    "aviditās, aviditātis"
  ],
  "greed": [
    "aviditās, aviditātis"
  ],
  "gluttony": [
    "aviditās, aviditātis"
  ],
  "remain fixed in place": [
    "haesitō, haesitāre, haesitāvī, haesitātum"
  ],
  "two years old": [
    "bīmus, bīma, bīmum"
  ],
  "pawn": [
    "pignerō, pignerāre, pignerāvī, pignerātum"
  ],
  "spread out or under": [
    "substernō, substernere, substrāvī, substrātum"
  ],
  "triumvir": [
    "triumvir, triumvirī"
  ],
  "whether a member of an official three-man commission or a member of a three-man junta or other group": [
    "triumvir, triumvirī"
  ],
  "a river of gallia narbonensis": [
    "tecum, tecī"
  ],
  "substitution": [
    "suppositiō, suppositiōnis",
    "substitūtiō, substitūtiōnis"
  ],
  "of or pertaining to rain": [
    "pluviālis, pluviāle"
  ],
  "an appellation of the etruscan princes and priests": [
    "lucumō, lucumōnis"
  ],
  "acquire or obtain": [
    "acquīrō, acquīrere, acquīsīvī, acquīsītum"
  ],
  "ithaca": [
    "ithaca, ithacae"
  ],
  "an island of the ionian sea": [
    "dūlichium, dūlichiī"
  ],
  "situated near ithaca": [
    "dūlichium, dūlichiī"
  ],
  "surrounding": [
    "circumstantia, circumstantiae",
    "circumiectus, circumiecta, circumiectum"
  ],
  "wildfowling": [
    "aucupium, aucupiī"
  ],
  "nugatory": [
    "nūgātōrius, nūgātōria, nūgātōrium"
  ],
  "trifling": [
    "nūgātōrius, nūgātōria, nūgātōrium"
  ],
  "flow or run back or off": [
    "refluō, refluere, refluxī"
  ],
  "ebb": [
    "refluō, refluere, refluxī"
  ],
  "a bleat": [
    "bālātus, bālātūs"
  ],
  "bleating": [
    "bālātus, bālātūs"
  ],
  "scurf": [
    "scabiēs, scabiēī"
  ],
  "saucer": [
    "phiala, phialae"
  ],
  "professorial": [
    "professōrius, professōria, professōrium"
  ],
  "a constructing": [
    "īnstructiō, īnstructiōnis"
  ],
  "erecting": [
    "īnstructiō, īnstructiōnis"
  ],
  "insertion": [
    "īnstructiō, īnstructiōnis"
  ],
  "spread among the people": [
    "dīvulgō, dīvulgāre, dīvulgāvī, dīvulgātum"
  ],
  "make common": [
    "dīvulgō, dīvulgāre, dīvulgāvī, dīvulgātum"
  ],
  "divulge": [
    "differō, differre, distulī, dīlātum",
    "dīvulgō, dīvulgāre, dīvulgāvī, dīvulgātum"
  ],
  "walk or pace up and down or to and fro": [
    "inambulō, inambulāre, inambulāvī"
  ],
  "nod ": [
    "nūtō, nūtāre, nūtāvī, nūtātum"
  ],
  "privation": [
    "prīvātiō, prīvātiōnis"
  ],
  "deprivation": [
    "prīvātiō, prīvātiōnis"
  ],
  "fall or sink down": [
    "occumbō, occumbere, occumbuī, occumbitum"
  ],
  "sloping or inclining downwards": [
    "dēvexus, dēvexa, dēvexum"
  ],
  "get ready": [
    "adōrnō, adōrnāre, adōrnāvī, adōrnātum"
  ],
  "fit out": [
    "adōrnō, adōrnāre, adōrnāvī, adōrnātum",
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus"
  ],
  "consistence": [
    "cōnsistentia, cōnsistentiae"
  ],
  "innate": [
    "genuīnus, genuīna, genuīnum"
  ],
  "stop up": [
    "obtūrō, obtūrāre, obtūrāvī, obtūrātum"
  ],
  "block up": [
    "obtūrō, obtūrāre, obtūrāvī, obtūrātum"
  ],
  "the act of devoting or consecrating": [
    "dēvōtiō, dēvōtiōnis"
  ],
  "ploughshare": [
    "vōmer, vōmeris"
  ],
  "invitation": [
    "invitātiō, invitātiōnis"
  ],
  "a river of gallia cisalpina flowing into the adriatic sea not far from ravenna": [
    "utis, utis"
  ],
  "now the montone": [
    "utis, utis"
  ],
  "invisible": [
    "invīsibilis, invīsibile"
  ],
  "a male given name equivalent to george": [
    "geōrgius, geōrgiī"
  ],
  "honorary": [
    "honōrārius, honōrāria, honōrārium"
  ],
  "of or belonging to wood": [
    "lignārius, lignāria, lignārium"
  ],
  "begin to swell": [
    "tumēscō, tumēscere, tumuī"
  ],
  "become distended or inflated": [
    "tumēscō, tumēscere, tumuī"
  ],
  "swell up": [
    "tumēscō, tumēscere, tumuī"
  ],
  "smallness": [
    "minūtia, minūtiae"
  ],
  "minuteness": [
    "minūtia, minūtiae"
  ],
  "discoloured": [
    "dēcolor, dēcolōris"
  ],
  "drab-coloured": [
    "dēcolor, dēcolōris"
  ],
  "unheard of": [
    "inaudītus, inaudīta, inaudītum"
  ],
  "novel": [
    "inaudītus, inaudīta, inaudītum"
  ],
  "master of the house or family": [
    "herus, herī"
  ],
  "a slender rope": [
    "fūniculus, fūniculī"
  ],
  "bore or pierce through": [
    "perforō, perforāre, perforāvī, perforātum"
  ],
  "perforate": [
    "perforō, perforāre, perforāvī, perforātum"
  ],
  "throw before": [
    "obiectō, obiectāre, obiectāvī, obiectātum"
  ],
  "set against": [
    "obiectō, obiectāre, obiectāvī, obiectātum"
  ],
  "lustration": [
    "lustrātiō, lustrātiōnis"
  ],
  "impassable": [
    "invius, invia, invium"
  ],
  "a coward": [
    "murcus, murcī"
  ],
  "to escape military service": [
    "murcus, murcī"
  ],
  "cuts off his thumb": [
    "murcus, murcī"
  ],
  "having a clasped stalk or stem": [
    "caulis, caule"
  ],
  "a fortuneteller": [
    "hariolus, hariolī"
  ],
  "boil": [
    "excoquō, excoquere, excoxī, excoctum"
  ],
  "whose chief town was lutetia": [
    "parīsiī, parīsiōrum"
  ],
  "cease boiling or raging": [
    "dēfervescō, dēfervescere, dēfervī"
  ],
  "bristle-bearing": [
    "saetiger, saetigera, saetigerum"
  ],
  "having coarse hair or bristles": [
    "saetiger, saetigera, saetigerum"
  ],
  "setaceous": [
    "saetiger, saetigera, saetigerum"
  ],
  "forbidden": [
    "illicitus, illicita, illicitum"
  ],
  "unlawful": [
    "illicitus, illicita, illicitum"
  ],
  "illegal": [
    "illicitus, illicita, illicitum"
  ],
  "illicit": [
    "illicitus, illicita, illicitum"
  ],
  "ashamed": [
    "pudibundus, pudibunda, pudibundum"
  ],
  "blushing": [
    "pudibundus, pudibunda, pudibundum"
  ],
  "a male given name": [
    "adrianus, adrianī"
  ],
  "equivalent to adrian": [
    "adrianus, adrianī"
  ],
  "graze or pasture": [
    "dēpāscō, dēpāscere, dēpāvī, dēpāstum"
  ],
  "a light made of wax or tallow": [
    "candēla, candēlae"
  ],
  "tallow candle or taper": [
    "candēla, candēlae"
  ],
  "remain awake all night": [
    "pervigilō, pervigilāre, pervigilāvī, pervigilātum"
  ],
  "keep watch all night": [
    "pervigilō, pervigilāre, pervigilāvī, pervigilātum"
  ],
  "keep a religious vigil": [
    "pervigilō, pervigilāre, pervigilāvī, pervigilātum"
  ],
  "a city in sicily": [
    "helōrum, helōrī"
  ],
  "situated near syracusae and the banks of the river helorus": [
    "helōrum, helōrī"
  ],
  "in abundance": [
    "largiter, largius, largissimē"
  ],
  "resin": [
    "rēsīna, rēsīnae"
  ],
  "mentana": [
    "nōmentum, nōmentī"
  ],
  "stunned": [
    "stupidus, stupida, stupidum",
    "stupeō, stupēre, stupuī, ----"
  ],
  "amazed": [
    "stupidus, stupida, stupidum",
    "stupeō, stupēre, stupuī, ----"
  ],
  "trample in": [
    "inculcō, inculcāre, inculcāvī, inculcātum"
  ],
  "tread down": [
    "inculcō, inculcāre, inculcāvī, inculcātum"
  ],
  "singed": [
    "sēmustus, sēmusta, sēmustum"
  ],
  "walk up to or close to": [
    "obambulō, obambulāre, obambulāvī, obambulātum"
  ],
  "very troublesome etc": [
    "permolestus, permolesta, permolestum"
  ],
  "formless": [
    "īnfōrmis, īnfōrme"
  ],
  "shapeless": [
    "īnfōrmis, īnfōrme"
  ],
  "testifying": [
    "testificātiō, testificātiōnis"
  ],
  "testification": [
    "testificātiō, testificātiōnis"
  ],
  "an unknown plant": [
    "clymenos, clymenī"
  ],
  "maybe a kind of honeysuckle": [
    "clymenos, clymenī"
  ],
  "a bird-catcher": [
    "auceps, aucupis"
  ],
  "fowler": [
    "auceps, aucupis"
  ],
  "perversion": [
    "dēprāvātiō, dēprāvātiōnis"
  ],
  "distortion": [
    "dēprāvātiō, dēprāvātiōnis"
  ],
  "summing up": [
    "cōnsummātiō, cōnsummātiōnis"
  ],
  "summary": [
    "cōnsummātiō, cōnsummātiōnis"
  ],
  "impossible": [
    "Impossiblis",
    "impossibilis, impossibile"
  ],
  "accusant": [
    "accūsāns, accūsantis"
  ],
  "the star arcturus": [
    "arctūrus, arctūrī"
  ],
  "geometer": [
    "geōmētrēs, geōmētrae",
    "geōmetricus, geōmetricī"
  ],
  "nourisher": [
    "altrīx, altrīcis"
  ],
  "cherisher": [
    "altrīx, altrīcis"
  ],
  "sustainer": [
    "altrīx, altrīcis"
  ],
  "of or pertaining to a temple": [
    "fānāticus, fānātica, fānāticum"
  ],
  "suppress": [
    "opprimō, opprimere, oppressī, oppressum",
    "cōnfūtō, cōnfūtāre, cōnfūtāvī, cōnfūtātum"
  ],
  "reform": [
    "reformatio, reformationis"
  ],
  "sympathy": [
    "sympathīa, sympathīae"
  ],
  "a maiden beloved by pyramus": [
    "thisbē, thisbēs"
  ],
  "knead": [
    "depsō, depsere, depsuī, depstum"
  ],
  "black liquid": [
    "ātrāmentum, ātrāmentī"
  ],
  "draw or cover over": [
    "superindūcō, superindūcere, superindūxī, superinductum"
  ],
  "grow up": [
    "adolēscō, adolēscere, adolēvī, adoluī, adultum"
  ],
  "an owl": [
    "būbō, būbōnis"
  ],
  "especially the eurasian eagle owl": [
    "būbō, būbōnis"
  ],
  "bubo bubo": [
    "būbō, būbōnis"
  ],
  "the act of dancing": [
    "saltātiō, saltātiōnis"
  ],
  "promulgation": [
    "prōmulgātiō, prōmulgātiōnis"
  ],
  "amomum": [
    "amōmum, amōmī"
  ],
  "heathen": [
    "ethnicus, ethnica, ethnicum"
  ],
  "begin to bristle up": [
    "horrēscō, horrēscere, horruī"
  ],
  "my hair rises on end": [
    "horrēscō, horrēscere, horruī"
  ],
  "peacock": [
    "pāvō, pāvōnis"
  ],
  "peafowl": [
    "pāvō, pāvōnis"
  ],
  "a bird associated with argus and sacred to hera": [
    "pāvō, pāvōnis"
  ],
  "eaten as a delicacy": [
    "pāvō, pāvōnis"
  ],
  "of or pertaining to dice": [
    "tālārius, tālāria, tālārium"
  ],
  "seaweed": [
    "alga, algae",
    "fūcus, fūcī"
  ],
  "plants that grow in freshwater": [
    "alga, algae"
  ],
  "an ancient city of the sabines situated near the course of the velinus river": [
    "reāte, reātis"
  ],
  "now the town of rieti": [
    "reāte, reātis"
  ],
  "astronomy": [
    "astrologia, astrologiae"
  ],
  "of a great age": [
    "longaevus, longaeva, longaevum"
  ],
  "longevitous": [
    "longaevus, longaeva, longaevum"
  ],
  "hospitably": [
    "hospitāliter"
  ],
  "full of tears": [
    "lacrimōsus, lacrimōsa, lacrimōsum"
  ],
  "become pubescent": [
    "pūbēscō, pūbēscere, pūbuī"
  ],
  "clergy": [
    "clērus, clērī"
  ],
  "little old woman": [
    "anicula, aniculae"
  ],
  "perugia": [
    "perusia, perusiae"
  ],
  "orchil": [
    "fūcus, fūcī"
  ],
  "pandering": [
    "lēnōcinium, lēnōciniī"
  ],
  "pimping": [
    "lēnōcinium, lēnōciniī"
  ],
  "prostitution": [
    "lēnōcinium, lēnōciniī"
  ],
  "leap or spring back": [
    "resiliō, resilīre, resiluī, resultum"
  ],
  "rebound": [
    "resiliō, resilīre, resiluī, resultum"
  ],
  "recoil": [
    "resiliō, resilīre, resiluī, resultum"
  ],
  "spoiled": [
    "pūtidus, pūtida, pūtidum"
  ],
  "fetid festering": [
    "pūtidus, pūtida, pūtidum"
  ],
  "purulent": [
    "pūtidus, pūtida, pūtidum"
  ],
  "suppurating stinking": [
    "pūtidus, pūtida, pūtidum"
  ],
  "reeking": [
    "pūtidus, pūtida, pūtidum"
  ],
  "mephitic": [
    "pūtidus, pūtida, pūtidum"
  ],
  "tradesman": [
    "caupō, caupōnis"
  ],
  "inexplicable": [
    "inexplicābilis, inexplicābile"
  ],
  "a feminine praenomen": [
    "marcia, marciae"
  ],
  "nicaea": [
    "nīcaea, nīcaeae"
  ],
  "hellenic city in northwestern anatolia": [
    "nīcaea, nīcaeae"
  ],
  "the act of collecting together": [
    "collēctiō, collēctiōnis"
  ],
  "accumulation": [
    "collēctiō, collēctiōnis"
  ],
  "public enemy": [
    "perduellis, perduellis"
  ],
  "fork-shaped yoke or gibbet": [
    "patibulum, patibulī"
  ],
  "spartan": [
    "lacōnicus, lacōnica, lacōnicum"
  ],
  "a fracture": [
    "fragmen, fragminis"
  ],
  "piece of a rock": [
    "fragmen, fragminis"
  ],
  "cover over or up": [
    "obtegō, obtegere, obtexī, obtectum"
  ],
  "separate by culling out": [
    "sēligō, sēligere, sēlēgī, sēlēctum"
  ],
  "pick or choose out": [
    "sēligō, sēligere, sēlēgī, sēlēctum"
  ],
  "cull": [
    "sēligō, sēligere, sēlēgī, sēlēctum"
  ],
  "pastoral": [
    "pastōrālis, pastōrāle"
  ],
  "a married woman who attended a bride": [
    "prōnuba, prōnubae"
  ],
  "bearer": [
    "vector, vectōris"
  ],
  "carrier": [
    "vector, vectōris"
  ],
  "of or relating to generation or birth": [
    "genitālis, genitāle"
  ],
  "that which has a tuft or crest—tufted": [
    "cristātus, cristāta, cristātum"
  ],
  "crested": [
    "cristātus, cristāta, cristātum"
  ],
  "plumed": [
    "cristātus, cristāta, cristātum"
  ],
  "a kind of sleeveless cloak or mantle with an opening for the head": [
    "paenula, paenulae"
  ],
  "worn on journeys or in rainy weather": [
    "paenula, paenulae"
  ],
  "troubler": [
    "vexātor, vexātōris"
  ],
  "harasser": [
    "vexātor, vexātōris"
  ],
  "abuser": [
    "vexātor, vexātōris"
  ],
  "vexer": [
    "vexātor, vexātōris"
  ],
  "palladium": [
    "palladium, palladiī"
  ],
  "one of perhaps several statues of athena": [
    "palladium, palladiī"
  ],
  "as in the iliad and aeneid": [
    "palladium, palladiī"
  ],
  "believed to safeguard troy": [
    "palladium, palladiī"
  ],
  "and later": [
    "palladium, palladiī"
  ],
  "various italian cities": [
    "palladium, palladiī"
  ],
  "gyration": [
    "vertīgō, vertīginis"
  ],
  "giddiness": [
    "vertīgō, vertīginis"
  ],
  "dizziness": [
    "vertīgō, vertīginis"
  ],
  "tamarisk": [
    "myrīcē, myrīcēs"
  ],
  "groan or moan at or over": [
    "ingemēscō, ingemēscere, ingemuī"
  ],
  "superficially": [
    "strictim"
  ],
  "ignoble": [
    "illīberālis, illīberāle"
  ],
  "ungenerous": [
    "illīberālis, illīberāle"
  ],
  "sordid": [
    "illīberālis, illīberāle"
  ],
  "disobliging": [
    "illīberālis, illīberāle"
  ],
  "a town of gallia cisalpina situated near the borders with liguria": [
    "clastidium, clastidiī"
  ],
  "now casteggio": [
    "clastidium, clastidiī"
  ],
  "soporific": [
    "sopōrifer, sopōrifera, sopōriferum"
  ],
  "causing deep sleep": [
    "sopōrifer, sopōrifera, sopōriferum"
  ],
  "to age": [
    "veterō, veterāre, veterāvī, veterātum"
  ],
  "make old": [
    "veterō, veterāre, veterāvī, veterātum"
  ],
  "founded by the elymian": [
    "segesta, segestae"
  ],
  "fall under something": [
    "succidō, succidere, succidī"
  ],
  "dexterity": [
    "dexteritās, dexteritātis"
  ],
  "angel": [
    "angelus, angelī"
  ],
  "pleasantly": [
    "suāviter, suāvius, suāvissimē"
  ],
  "return or repay": [
    "retribuō, retribuere, retribuī, retribūtum"
  ],
  "birth": [
    "nātīvitās, nātīvitātis"
  ],
  "nativity": [
    "nātīvitās, nātīvitātis"
  ],
  "drinking plate": [
    "fiala, fialae"
  ],
  "superstitious": [
    "superstitiōsus, superstitiōsa, superstitiōsum"
  ],
  "iris": [
    "hyacinthus, hyacinthī",
    "hyacinthinus, hyacinthina, hyacinthinum"
  ],
  "incorruptible": [
    "incorruptibilis, incorruptibile"
  ],
  "imperishable": [
    "incorruptibilis, incorruptibile"
  ],
  "make a bowstring twang to send forth an arrow": [
    "psallō, psallere, psallī"
  ],
  "a stabber": [
    "percussor, percussōris"
  ],
  "striker": [
    "percussor, percussōris"
  ],
  "shooter": [
    "percussor, percussōris"
  ],
  "explain in detail": [
    "ēnārrō, ēnārrāre, ēnārrāvī, ēnārrātum"
  ],
  "expound": [
    "ēnārrō, ēnārrāre, ēnārrāvī, ēnārrātum"
  ],
  "trample down": [
    "conculcō, conculcāre, conculcāvī, conculcātum"
  ],
  "recognizable": [
    "cognoscibilis, cognoscibile"
  ],
  "discernible": [
    "cognoscibilis, cognoscibile"
  ],
  "incorporeal": [
    "incorporeus, incorporea, incorporeum"
  ],
  "violation of duty": [
    "praevāricātiō, praevāricātiōnis"
  ],
  "penetrating": [
    "penētrālis, penētrāle"
  ],
  "essentially": [
    "essentiāliter, essentiālius, essentiālissimē"
  ],
  "libation": [
    "lībāmen, lībāminis"
  ],
  "offering to the gods": [
    "lībāmen, lībāminis"
  ],
  "seizing or laying hold of": [
    "apprehensiō, apprehensiōnis"
  ],
  "circumcision": [
    "circumcīsiō, circumcīsiōnis"
  ],
  "a cutting around": [
    "circumcīsiō, circumcīsiōnis"
  ],
  "duumvir": [
    "duumvir, duumviri"
  ],
  "lie beneath or near": [
    "subiaceō, subiacēre, subiacuī"
  ],
  "hymn": [
    "hymnus, hymnī"
  ],
  "a song of praise": [
    "hymnus, hymnī"
  ],
  "in want or need of": [
    "egēnus, egēna, egēnum"
  ],
  "destitute of": [
    "egēnus, egēna, egēnum"
  ],
  "bud": [
    "germen, germinis"
  ],
  "heretical": [
    "haereticus, haeretica, haereticum"
  ],
  "the nature of a god": [
    "deitās, deitātis"
  ],
  "blasphemy": [
    "blasphēmia, blasphēmiae), first declension (ecclesiastical latin",
    "blasphēmia, blasphēmiae); first declension (Ecclesiastical Latin"
  ],
  "most or very offensive etc": [
    "taeterrimus, taeterrima, taeterrimum"
  ],
  "a figurative use of a word": [
    "tropus, tropī"
  ],
  "a trope": [
    "tropus, tropī"
  ],
  "a preface to a play": [
    "prōlogus, prōlogī"
  ],
  "a prologue": [
    "prōlogus, prōlogī"
  ],
  "a sowing": [
    "satus"
  ],
  "unchangeable": [
    "immūtābilis, immūtābile"
  ],
  "unalterable": [
    "immūtābilis, immūtābile"
  ],
  "locust": [
    "lucusta, lucustae"
  ],
  "grasshopper": [
    "lucusta, lucustae"
  ],
  "sadden": [
    "contrīstō, contrīstāre, contrīstāvī, contrīstātum"
  ],
  "essentials": [
    "essentiāl, essentiālis"
  ],
  "saviour": [
    "salvātor, salvātōris"
  ],
  "crucify": [
    "crucifīgō, crucifīgere, crucifīxī, crucifixum"
  ],
  "prepuce": [
    "praepūtium, praepūtiī or praepūtī"
  ],
  "foreskin": [
    "praepūtium, praepūtiī or praepūtī"
  ],
  "empty gut": [
    "hīra, hīrae"
  ],
  "drunkenness": [
    "ēbrietās, ēbrietātis"
  ],
  "concubine": [
    "amāsia, amāsiae",
    "concubīna, concubīnae"
  ],
  "faliscan": [
    "faliscus, falisca, faliscum"
  ],
  "of or pertaining to breathing": [
    "spīritālis, spīritāle"
  ],
  "the wind or air": [
    "spīritālis, spīritāle"
  ],
  "make or become hard": [
    "indūrō, indūrāre, indūrāvī, indūrātum"
  ],
  "the annointed one": [
    "christus, christī"
  ],
  "diverse mode": [
    "dīversimodus, dīversimodī"
  ],
  "equivocal": [
    "aequivocus, aequivoca, aequivocum"
  ],
  "ambiguous": [
    "aequivocus, aequivoca, aequivocum"
  ],
  "a murderer": [
    "homicīda, homicīdae"
  ],
  "a slayer of men": [
    "homicīda, homicīdae"
  ],
  "imperfection": [
    "imperfectiō, imperfectiōnis"
  ],
  "uncircumcised": [
    "incircumcīsus, incircumcīsa, incircumcīsum"
  ],
  "turn around": [
    "reversō, reversāre, reversāvī, reversātum"
  ],
  "cause to rise": [
    "fermentō, fermentāre, fermentāvī, fermentātum"
  ],
  "ferment or leaven": [
    "fermentō, fermentāre, fermentāvī, fermentātum"
  ],
  "addition": [
    "additiō, additiōnis",
    "adiectiō, adiectiōnis"
  ],
  "most or very different": [
    "dissimillimus, dissimillima, dissimillimum"
  ],
  "homicide": [
    "homicīdium, homicīdiī or homicīdī"
  ],
  "manslaughter": [
    "homicīdium, homicīdiī or homicīdī"
  ],
  "am hungry": [
    "ēsuriō, ēsurīre, ēsurīvī, ēsurītum"
  ],
  "hunger for something": [
    "ēsuriō, ēsurīre, ēsurīvī, ēsurītum"
  ],
  "lute or psaltery": [
    "psaltērium, psaltēriī"
  ],
  "indian": [
    "indicus, indica, indicum"
  ],
  "indic": [
    "indicus, indica, indicum"
  ],
  "swear to": [
    "adiūrō, adiūrāre, adiūrāvī, adiūrātum"
  ],
  "confirm by an oath": [
    "adiūrō, adiūrāre, adiūrāvī, adiūrātum"
  ],
  "swear in addition to": [
    "adiūrō, adiūrāre, adiūrāvī, adiūrātum"
  ],
  "recompense": [
    "retribūtiō, retribūtiōnis",
    "remūnerō, remūnerāre, remūnerāvī, remūnerātum"
  ],
  "repayment": [
    "retribūtiō, retribūtiōnis"
  ],
  "exalt above others": [
    "superexaltō, superexaltāre, superexaltāvī, superexaltātum"
  ],
  "personally": [
    "pecūliāriter",
    "persōnāliter"
  ],
  "cedar": [
    "cēdrinus, cēdrina, cēdrinum"
  ],
  "intoxicate": [
    "inēbriō, inēbriāre, inēbriāvī, inēbriātum"
  ],
  "rusticity": [
    "rūsticitās, rūsticitātis"
  ],
  "scream": [
    "vōciferātiō, vōciferātiōnis",
    "vōciferor, vōciferārī, vōciferātus sum"
  ],
  "a small vilage or hamlet": [
    "vīculus, vīculī"
  ],
  "wanted": [
    "dēsīderābilis, dēsīderābile"
  ],
  "giant": [
    "Giant",
    "Gigas",
    "gigās, gigantis"
  ],
  "cistern": [
    "cisterna, cisternae"
  ],
  "tank": [
    "cisterna, cisternae"
  ],
  "prologue": [
    "prōlogus, prōlogī",
    "praefātiō, praefātiōnis"
  ],
  "the janiculum hill in rome": [
    "iāniculum, iāniculī"
  ],
  "of november": [
    "november, novembris, novembre"
  ],
  "allied to": [
    "affīnis, affīne"
  ],
  "scythian": [
    "scythicus, scythica, scythicum"
  ],
  "a flood": [
    "dīluvium, dīluviī"
  ],
  "certainty": [
    "certitūdō, certitūdinis"
  ],
  "certitude": [
    "certitūdō, certitūdinis"
  ],
  "baptism": [
    "baptismum, baptismī"
  ],
  "preach the gospel": [
    "ēvangelīzō, ēvangelīzāre, ēvangelīzāvī, ēvangelīzātum"
  ],
  "evangelize": [
    "ēvangelīzō, ēvangelīzāre, ēvangelīzāvī, ēvangelīzātum"
  ],
  "desolation": [
    "dēsōlātiō, dēsōlātiōnis"
  ],
  "shittim": [
    "setthim"
  ],
  "inherit": [
    "hērēditō, hērēditāre, hērēditāvī, hērēditātum"
  ],
  "endeavour to equal or excel someone": [
    "aemulor, aemulārī, aemulātus sum"
  ],
  "rival": [
    "adversārius, adversāriī",
    "competītor, competītōris",
    "aemulor, aemulārī, aemulātus sum"
  ],
  "vie with": [
    "aemulor, aemulārī, aemulātus sum"
  ],
  "emulate": [
    "aemulor, aemulārī, aemulātus sum"
  ],
  "variant of periculum": [
    "perīclum, perīclī"
  ],
  "light up": [
    "illūminō, illūmināre, illūmināvī, illūminātum"
  ],
  "tour": [
    "perambulō, perambulāre, perambulāvī, perambulātum"
  ],
  "perambulate": [
    "perambulō, perambulāre, perambulāvī, perambulātum"
  ],
  "utterable": [
    "ēnuntiābilis, ēnuntiābile"
  ],
  "of or pertaining to boundaries": [
    "fīnālis, fīnāle"
  ],
  "hebrew": [
    "hebraicus, hebraica, hebraicum"
  ],
  "crawling": [
    "rēptilis, rēptile"
  ],
  "cause to stumble": [
    "scandalizō, scandalizāre, scandalizāvī, scandalizātum"
  ],
  "wine or water skin or bottle": [
    "ūter, ūtris"
  ],
  "a wholesale business": [
    "negōtiātiō, negōtiātiōnis"
  ],
  "murmur": [
    "murmurō, murmurāre, murmurāvī, murmurātum"
  ],
  "mutter": [
    "murmurō, murmurāre, murmurāvī, murmurātum"
  ],
  "loftiness": [
    "sublīmitas, sublīmitātis"
  ],
  "multiplication": [
    "multiplicātiō, multiplicātiōnis"
  ],
  "daringly": [
    "cōnfīdenter, cōnfīdentius, cōnfīdentissimē"
  ],
  "censer": [
    "tūribulum, tūribulī"
  ],
  "thurible": [
    "tūribulum, tūribulī"
  ],
  "chase": [
    "vēnātiō, vēnātiōnis",
    "persecūtiō, persecūtiōnis",
    "vēnor, vēnārī, vēnātus sum",
    "īnsequor, īnsequī, īnsecūtus sum"
  ],
  "a worm": [
    "vermis, vermis"
  ],
  "an elder": [
    "presbyter, presbyterī"
  ],
  "presbyter": [
    "presbyter, presbyterī"
  ],
  "gradually melt or dissolve": [
    "tābēscō, tābēscere, tābuī"
  ],
  "abstemiousness": [
    "contingentia, contingentiae"
  ],
  "continence": [
    "contingentia, contingentiae"
  ],
  "a creator": [
    "creātor, creātōris"
  ],
  "alms": [
    "elemosyna, elemosynae"
  ],
  "seven times": [
    "septiēs"
  ],
  "beggarly": [
    "mendīcus, mendīca, mendīcum"
  ],
  "needy": [
    "mendīcus, mendīca, mendīcum"
  ],
  "a type of declamatio that is deliberative or suasory": [
    "suāsōria, suāsōriae"
  ],
  "steelyard": [
    "statēra, statērae"
  ],
  "scales": [
    "statēra, statērae"
  ],
  "fleshy": [
    "carnālis, carnāle"
  ],
  "appropriately": [
    "cōnsequenter"
  ],
  "in accordance": [
    "cōnsequenter"
  ],
  "stirred": [
    "mōtivus, mōtiva, mōtivum"
  ],
  "continually": [
    "iūgiter, iūgius, iūgissimē"
  ],
  "perpetually": [
    "iūgiter, iūgius, iūgissimē"
  ],
  "a satrap": [
    "satrapa, satrapae"
  ],
  "a governor of a province": [
    "satrapa, satrapae"
  ],
  "a viceroy among the persians": [
    "satrapa, satrapae"
  ],
  "a scarlet berry of various plants": [
    "coccum, coccī"
  ],
  "conceive": [
    "imāginor, imāginārī, imāginātus sum"
  ],
  "designate": [
    "dēnōminō, dēnōmināre, dēnōmināvī, dēnōminātum"
  ],
  "nominate": [
    "dēnōminō, dēnōmināre, dēnōmināvī, dēnōminātum"
  ],
  "fill full": [
    "adimpleō, adimplēre, adimplēvī, adimplētum"
  ],
  "self-existent": [
    "substantīvus, substantīva, substantīvum"
  ],
  "substantive": [
    "substantīvus, substantīva, substantīvum"
  ],
  "nitrogen": [
    "azōtum, azōtī"
  ],
  "unbelieving": [
    "incrēdulus, incrēdula, incrēdulum"
  ],
  "disbelieving": [
    "incrēdulus, incrēdula, incrēdulum"
  ],
  "incredulous": [
    "incrēdulus, incrēdula, incrēdulum"
  ],
  "blunt": [
    "hebes, hebetis"
  ],
  "not sharp or pointed": [
    "hebes, hebetis"
  ],
  "days ago": [
    "nudius"
  ],
  "literally \"it is now the ...th day since\"": [
    "nudius"
  ],
  "gloomy": [
    "tenebrōsus, tenebrōsa, tenebrōsum"
  ],
  "formally": [
    "formāliter"
  ],
  "symbol": [
    "symbolum, symbolī"
  ],
  "monk": [
    "Monk",
    "monachus, monachī"
  ],
  "swing": [
    "ventilō, ventilāre, ventilāvī, ventilātum"
  ],
  "brandish in the air": [
    "ventilō, ventilāre, ventilāvī, ventilātum"
  ],
  "anticipate": [
    "praecipiō, praecipere, praecēpī, praeceptum",
    "praeveniō, praevenīre, praevēnī, praeventum"
  ],
  "depreciate": [
    "calumnior, calumniārī, calumniātus sum"
  ],
  "misrepresent": [
    "calumnior, calumniārī, calumniātus sum"
  ],
  "cavil at": [
    "calumnior, calumniārī, calumniātus sum"
  ],
  "calumniate": [
    "calumnior, calumniārī, calumniātus sum"
  ],
  "blame unjustly": [
    "calumnior, calumniārī, calumniātus sum"
  ],
  "blackmail": [
    "calumnior, calumniārī, calumniātus sum"
  ],
  "rack in a manger": [
    "falisca, faliscae"
  ],
  "lid": [
    "operīmentum, operīmentī"
  ],
  "jaw": [
    "maxilla, maxillae"
  ],
  "jawbone": [
    "maxilla, maxillae"
  ],
  "bone of the upper jaw": [
    "maxilla, maxillae"
  ],
  "a roe deer": [
    "caprea, capreae"
  ],
  "running over": [
    "superfluus, superflua, superfluum"
  ],
  "overflowing": [
    "superfluus, superflua, superfluum"
  ],
  "incommunicable": [
    "incommūnicābilis, incommūnicābile"
  ],
  "divisible": [
    "dīvīsibilis, dīvīsibile"
  ],
  "and especially rabbi": [
    "rabbī"
  ],
  "sameness": [
    "īdentitās, īdentitātis"
  ],
  "identity": [
    "īdentitās, īdentitātis"
  ],
  "only begotten": [
    "ūnigenitus, ūnigenita, ūnigenitum"
  ],
  "make illustrious or famous": [
    "clārificō, clārificāre, clārificāvī, clārificātum"
  ],
  "mighty work or deed": [
    "magnāle, magnālis"
  ],
  "a concubine": [
    "amāsia, amāsiae"
  ],
  "moabite man": [
    "mōabitēs, mōabitae), first declension (sometimes capitalised",
    "mōabitēs, mōabitae); first declension (Sometimes capitalised"
  ],
  "gifted with sense": [
    "sēnsātus, sēnsāta, sēnsātum"
  ],
  "leprous": [
    "leprōsus, leprōsa, leprōsum"
  ],
  "ephah": [
    "oephi"
  ],
  "roughen": [
    "exasperō, exasperāre, exasperāvī, exasperātum"
  ],
  "variant of impar": [
    "inpar, inparis"
  ],
  "a fly": [
    "musca, muscae"
  ],
  "am named": [
    "cognōminō, cognōmināre, cognōmināvī, cognōminātum"
  ],
  "called": [
    "cognōminō, cognōmināre, cognōmināvī, cognōminātum"
  ],
  "have a surname": [
    "cognōminō, cognōmināre, cognōmināvī, cognōminātum"
  ],
  "the external part of the ear": [
    "auricula, auriculae"
  ],
  "ear-lap": [
    "auricula, auriculae"
  ],
  "harvest grapes": [
    "vīndēmiō, vīndēmiāre, vīndēmiāvī, vīndēmiātum"
  ],
  "gather the vintage": [
    "vīndēmiō, vīndēmiāre, vīndēmiāvī, vīndēmiātum"
  ],
  "counter-promise": [
    "reprōmissiō, reprōmissiōnis"
  ],
  "acceptance": [
    "acceptiō, acceptiōnis"
  ],
  "bend inwards": [
    "incurvō, incurvāre, incurvāvī, incurvātum"
  ],
  "emanation": [
    "ēmānātiō, ēmānātiōnis"
  ],
  "of or pertaining to priests": [
    "sacerdōtālis, sacerdōtāle"
  ],
  "priestly": [
    "sacerdōtālis, sacerdōtāle"
  ],
  "sacerdotal": [
    "sacerdōtālis, sacerdōtāle"
  ],
  "jubilee": [
    "iobeleus, iobeleī"
  ],
  "of or pertaining to an apostle": [
    "apostolicus, apostolica, apostolicum"
  ],
  "apostolic": [
    "apostolicus, apostolica, apostolicum"
  ],
  "brandished": [
    "contortus, contorta, contortum"
  ],
  "repletion": [
    "saturitas, saturitātis"
  ],
  "satiety": [
    "saturitas, saturitātis"
  ],
  "banquet": [
    "dominium, dominiī"
  ],
  "plait": [
    "plectō, plectere, plexī, plexum"
  ],
  "braid": [
    "plectō, plectere, plexī, plexum"
  ],
  "thornbush": [
    "veprēs, vepris"
  ],
  "furnace": [
    "camīnus, camīnī",
    "fornāx, fornācis"
  ],
  "that which is predicated": [
    "praedicāmentum, praedicāmentī"
  ],
  "a predicament": [
    "praedicāmentum, praedicāmentī"
  ],
  "category": [
    "praedicāmentum, praedicāmentī"
  ],
  "enjoyable": [
    "dēlectābilis, dēlectābile"
  ],
  "delectable": [
    "dēlectābilis, dēlectābile"
  ],
  "armchair": [
    "cathedra, cathedrae"
  ],
  "stumbling block": [
    "offendiculum, offendiculī"
  ],
  "obstacle": [
    "offendiculum, offendiculī"
  ],
  "levitical": [
    "lēvīticus, lēvītica, lēvīticum"
  ],
  "cast": [
    "cōnflātilis, cōnflātile"
  ],
  "practise speaking": [
    "dēclāmō, dēclāmāre, dēclāmāvī, dēclāmātum"
  ],
  "make speeches": [
    "dēclāmō, dēclāmāre, dēclāmāvī, dēclāmātum"
  ],
  "deprecate ": [
    "abōminor, abōminārī, abōminātus sum"
  ],
  "turtle dove": [
    "turtur, turturis"
  ],
  "cauldron": [
    "cortīna, cortīnae"
  ],
  "kettle": [
    "Ahenum",
    "cortīna, cortīnae"
  ],
  "metaphysics": [
    "metaphysica, metaphysicae"
  ],
  "unchangeableness": [
    "immūtābilitās, immūtābilitātis"
  ],
  "immutability": [
    "immūtābilitās, immūtābilitātis"
  ],
  "leave alone": [
    "dēsōlō, dēsōlāre, dēsōlāvī, dēsōlātum"
  ],
  "make lonely": [
    "dēsōlō, dēsōlāre, dēsōlāvī, dēsōlātum"
  ],
  "lay waste": [
    "dēsōlō, dēsōlāre, dēsōlāvī, dēsōlātum"
  ],
  "desolate": [
    "dēsōlō, dēsōlāre, dēsōlāvī, dēsōlātum"
  ],
  "transplanting": [
    "plantātiō, plantātiōnis"
  ],
  "sing or shout joyfully": [
    "iūbilō, iūbilāre, iūbilāvī, iūbilātum"
  ],
  ": reality": [
    "reālitās, reālitātis"
  ],
  "collect or gather together": [
    "cōnstruō, cōnstruere, cōnstruxī, cōnstructum"
  ],
  "brotherhood": [
    "Fraternitas",
    "Ad Fraternitatem",
    "frāternitās, frāternitātis"
  ],
  "a renunciation": [
    "abdicātiō, abdicātiōnis"
  ],
  "fashioning": [
    "fictiō, fictiōnis"
  ],
  "forming": [
    "fictiō, fictiōnis"
  ],
  "formation": [
    "fictiō, fictiōnis",
    "figmentum, figmentī"
  ],
  "reaper": [
    "Messor",
    "messor, messōris"
  ],
  "fornicate": [
    "fornicor, fornicārī, fornicātus sum"
  ],
  "commit fornication": [
    "fornicor, fornicārī, fornicātus sum"
  ],
  "sufficiently": [
    "sufficienter"
  ],
  "manifestation": [
    "manifestātiō, manifestātiōnis"
  ],
  "a manifesting": [
    "manifestātiō, manifestātiōnis"
  ],
  "regulating": [
    "ordinātiō, ordinātiōnis"
  ],
  "nazarene": [
    "nazarēnus, nazarēna, nazarēnum"
  ],
  "disbelief": [
    "incrēdulitas, incrēdulitātis"
  ],
  "incredulity": [
    "incrēdulitas, incrēdulitātis"
  ],
  "ephod": [
    "superumerale, superumeralis"
  ],
  "enumerate or calculate": [
    "dīnumerō, dīnumerāre, dīnumerāvī, dīnumerātum"
  ],
  "brothel": [
    "stabulum -ī n",
    "lupānar, lupānāris"
  ],
  "stand in awe": [
    "revereor, reverēri, reveritus sum"
  ],
  "reverence": [
    "venerātiō, venerātiōnis",
    "revereor, reverēri, reveritus sum"
  ],
  "triangular": [
    "triangulus, triangula, triangulum"
  ],
  "three-cornered": [
    "triangulus, triangula, triangulum"
  ],
  "three-sided": [
    "triangulus, triangula, triangulum"
  ],
  "harmonise": [
    "concordō, concordāre, concordāvī, concordātum"
  ],
  "a person who violates his duty": [
    "praevāricātor, praevāricātōris"
  ],
  "prevaricator": [
    "praevāricātor, praevāricātōris"
  ],
  "attenuated": [
    "vēscus, vēsca, vēscum"
  ],
  "gild": [
    "aurō, aurāre, aurāvī, aurātum",
    "inaurō, inaurāre, inaurāvī, inaurātum"
  ],
  "overlay with gold": [
    "inaurō, inaurāre, inaurāvī, inaurātum"
  ],
  "truthful": [
    "vērāx, vērācis"
  ],
  "turban": [
    "mītra, mītrae"
  ],
  "idiot": [
    "idiōta, idiōtae"
  ],
  "a destructive insect larva that attacks household items such as books or clothing": [
    "tinea, tineae"
  ],
  "larva": [
    "tinea, tineae",
    "vermiculus, vermiculī"
  ],
  "maggot": [
    "tinea, tineae"
  ],
  "caterpillar": [
    "tinea, tineae"
  ],
  "constituent": [
    "constitūtivus, constitūtiva, constitūtivum"
  ],
  "component": [
    "constitūtivus, constitūtiva, constitūtivum"
  ],
  "prolongation": [
    "continuitās, continuitātis"
  ],
  "empty out": [
    "ēvacuō, ēvacuāre, ēvacuāvī, ēvacuātum"
  ],
  "evacuate": [
    "ēvacuō, ēvacuāre, ēvacuāvī, ēvacuātum"
  ],
  "a person who ascends": [
    "ascēnsor, ascēnsōris"
  ],
  "small mortar": [
    "mortāriolum, mortāriolī"
  ],
  "objective": [
    "objectīvus, objectīva, objectīvum"
  ],
  "uselessly": [
    "inūtiliter, inūtilius, inūtilissimē"
  ],
  "unprofitably": [
    "inūtiliter, inūtilius, inūtilissimē"
  ],
  "move house": [
    "trānsmigrō, trānsmigrāre, trānsmigrāvī, trānsmigrātum"
  ],
  "purity": [
    "Puritas",
    "pūritās, pūritātis",
    "castitas, castitātis"
  ],
  "dig up or over": [
    "cōnfodiō, cōnfodere, cōnfōdī, cōnfossum"
  ],
  "dig round about": [
    "cōnfodiō, cōnfodere, cōnfōdī, cōnfossum"
  ],
  "turn over": [
    "cōnfodiō, cōnfodere, cōnfōdī, cōnfossum"
  ],
  "prepare by digging": [
    "cōnfodiō, cōnfodere, cōnfōdī, cōnfossum"
  ],
  "adverb": [
    "adverbium, adverbiī"
  ],
  "geometrician": [
    "geōmētrēs, geōmētrae"
  ],
  "drunken": [
    "tēmulentus, tēmulenta, tēmulentum"
  ],
  "intoxicated": [
    "tēmulentus, tēmulenta, tēmulentum"
  ],
  "a riddle": [
    "aenigma, aenigmatis"
  ],
  "enigma": [
    "aenigma, aenigmatis"
  ],
  "rather slow": [
    "lentulus, lentula, lentulum"
  ],
  "evidently": [
    "ēvidenter, ēvidentius, ēvidentissimē"
  ],
  "foreknow": [
    "praesciō, praescīre, praescīvī, praescītum"
  ],
  "awaken": [
    "ēvigilō, ēvigilāre, ēvigilāvī, ēvigilātum"
  ],
  "manfully": [
    "virīliter, virīlius, virīlissimē"
  ],
  "prescience": [
    "praescientia, praescientiae"
  ],
  "monastery": [
    "monastērium, monastēriī"
  ],
  "archbishop": [
    "archiepiscopus, archiepiscopī"
  ],
  "a bishop who heads or presides over a group of dioceses called a province": [
    "archiepiscopus, archiepiscopī"
  ],
  "mandator": [
    "mandator, mandatōris"
  ],
  "expenditure": [
    "impendium, impendiī"
  ],
  "veneration": [
    "venerātiō, venerātiōnis"
  ],
  "a measure": [
    "metrum, metrī"
  ],
  "a washing": [
    "lōtus, lōtūs"
  ],
  "bathing": [
    "lōtus, lōtūs"
  ],
  "acute": [
    "sibus, siba, sibum"
  ],
  "a litigant": [
    "lītigāns, lītigantis"
  ],
  "quarrelsome person": [
    "lītigāns, lītigantis"
  ],
  "clever use of words": [
    "argūtia, argūtiae"
  ],
  "verbal trickery": [
    "argūtia, argūtiae"
  ],
  "imitator": [
    "imitātor, imitātōris",
    "aemulātor, aemulātōris"
  ],
  "a metaphor": [
    "metaphora, metaphorae"
  ],
  "a shallow cup for vinegar": [
    "acētābulum, acētābulī"
  ],
  "acetabulum": [
    "acētābulum, acētābulī"
  ],
  "yielding": [
    "Yielding",
    "permissiō, permissiōnis"
  ],
  "ceding": [
    "permissiō, permissiōnis"
  ],
  "an edge or flap of a garment": [
    "lacinia, laciniae"
  ],
  "denude": [
    "dēnūdō, dēnūdāre, dēnūdāvī, dēnūdātum"
  ],
  "bean": [
    "faba, fabae"
  ],
  "viper": [
    "vīpera, vīperae"
  ],
  "irrational": [
    "irratiōnālis, irratiōnāle",
    "īnsēnsātus, īnsēnsāta, īnsēnsātum"
  ],
  "bellow": [
    "rūgiō, rūgīre, rūgīvī, rūgītus"
  ],
  "rumble": [
    "rūgiō, rūgīre, rūgīvī, rūgītus"
  ],
  "pollute or contaminate all over": [
    "coinquinō, coinquināre, coinquināvī, coinquinātum"
  ],
  ":a means of reconciliation": [
    "propitiātōrium, propitiātōriī"
  ],
  "an atonement": [
    "propitiātōrium, propitiātōriī"
  ],
  "propitiationa place of atonement": [
    "propitiātōrium, propitiātōriī"
  ],
  "grub": [
    "vermiculus, vermiculī"
  ],
  "small head": [
    "capitellum, capitellī"
  ],
  "variant of dumtaxat": [
    "duntaxat"
  ],
  "incurable": [
    "īnsānābilis, īnsānābile"
  ],
  "feel sleepy": [
    "dormītō, dormītāre, dormītāvī, dormītātum"
  ],
  "homeric": [
    "homēricus, homērica, homēricum"
  ],
  "of or pertaining to the greek poet homer": [
    "homēricus, homērica, homēricum"
  ],
  "lash or scourge": [
    "flagellō, flagellāre, flagellāvī, flagellātum"
  ],
  "practical knowledge": [
    "perītia, perītiae"
  ],
  "expertise": [
    "perītia, perītiae"
  ],
  "transposition": [
    "trānsgressiō, trānsgressiōnis"
  ],
  "sterility": [
    "sterilitās, sterilitātis"
  ],
  "barrenness": [
    "sterilitās, sterilitātis"
  ],
  "allegory": [
    "allēgoria, allēgoriae"
  ],
  "appear before": [
    "obversor, obversārī, obversātus sum"
  ],
  "laugh or smile at": [
    "arrīdeō, arrīdēre, arrīsī, arrīsum"
  ],
  "walled": [
    "mūrātus, mūrāta, mūrātum"
  ],
  "very few or little": [
    "pauculus, paucula, pauculum"
  ],
  "public dancer who dances in leaps": [
    "praesul, praesulis"
  ],
  "saffron yellow": [
    "lūteus, lūtea, lūteum"
  ],
  "a net": [
    "rēticulum, rēticulī"
  ],
  "give food to animals": [
    "cibō, cibāre, cibāvī, cibātum"
  ],
  "singularity": [
    "singulāritās, singulāritātis"
  ],
  "universally": [
    "ūniversāliter"
  ],
  "objection": [
    "obiectiō, obiectiōnis"
  ],
  "martyr": [
    "martyr, martyris"
  ],
  "especially a christian martyr": [
    "martyr, martyris"
  ],
  "break into small pieces": [
    "dīminuō, dīminuere, dīminuī, dīminutum"
  ],
  "locally": [
    "locāliter"
  ],
  "the hinnom valley": [
    "gehenna, gehennae"
  ],
  "mock or deride": [
    "subsannō, subsannāre, subsannāvī, subsannātum"
  ],
  "am destroyed": [
    "dispereō, disperīre, disperiī"
  ],
  "go completely to ruin": [
    "dispereō, disperīre, disperiī"
  ],
  "am lost or undone": [
    "dispereō, disperīre, disperiī"
  ],
  "hurrah": [
    "euge"
  ],
  "baptizer": [
    "baptista, baptistae"
  ],
  "baptist": [
    "baptista, baptistae"
  ],
  "detain or hold up": [
    "dēmoror, dēmorārī, dēmorātus sum"
  ],
  "healthiest": [
    "salūberrimus, salūberrima, salūberrimum"
  ],
  "most of very salubrious etc": [
    "salūberrimus, salūberrima, salūberrimum"
  ],
  "heap up": [
    "exaggerō, exaggerāre, exaggerāvī, exaggerātum"
  ],
  "fabled": [
    "fābulōsus, fābulōsa, fābulōsum"
  ],
  "fabulous": [
    "fābulōsus, fābulōsa, fābulōsum"
  ],
  "legendary": [
    "Legendum",
    "fābulōsus, fābulōsa, fābulōsum"
  ],
  "presume": [
    "praesūmō, praesūmere, praesūmpsī, praesūmptum"
  ],
  "in its separate senses:take before or firstanticipatepresupposetake for granted": [
    "praesūmō, praesūmere, praesūmpsī, praesūmptum"
  ],
  "fatten": [
    "sagīnō, sagīnāre, sagīnāvī, sagīnātum"
  ],
  "profound": [
    "profundus, profunda, profundum"
  ],
  "exclamation": [
    "exclāmātiō, exclāmātiōnis"
  ],
  "bound or leap out": [
    "exsiliō, exsilīre, exsiluī"
  ],
  "up or forth": [
    "exsiliō, exsilīre, exsiluī"
  ],
  "pronoun": [
    "prōnōmen, prōnōminis"
  ],
  "take or eat breakfast or luncheon": [
    "prandeō, prandēre, prandī, prānsum"
  ],
  "breakfast": [
    "prandeō, prandēre, prandī, prānsum"
  ],
  "indifferently": [
    "indifferenter"
  ],
  "egress": [
    "ēgressiō, ēgressiōnis"
  ],
  "disembarkation": [
    "ēgressiō, ēgressiōnis"
  ],
  "curium": [
    "curium, curiī"
  ],
  "wordy": [
    "verbōsus, verbōsa, verbōsum"
  ],
  "verbose": [
    "verbōsus, verbōsa, verbōsum"
  ],
  "prolix": [
    "verbōsus, verbōsa, verbōsum"
  ],
  "intestate": [
    "intestātus, intestāta, intestātum"
  ],
  "spurious": [
    "nothus, notha, nothum",
    "reprobus, reproba, reprobum"
  ],
  "desolating": [
    "vastātiō, vastātiōnis"
  ],
  "devastation": [
    "vastātiō, vastātiōnis"
  ],
  "anapestic": [
    "anapaestus, anapaesta, anapaestum"
  ],
  "exasperate": [
    "exacerbō, exacerbāre, exacerbāvī, exacerbātum"
  ],
  "divergence": [
    "variātiō, variātiōnis"
  ],
  "variation": [
    "variātiō, variātiōnis"
  ],
  "application": [
    "applicātiō, applicātiōnis"
  ],
  "inclination": [
    "applicātiō, applicātiōnis"
  ],
  "commit adultery": [
    "adulterō, adulterāre, adulterāvī, adulterātum"
  ],
  "adulterate": [
    "adulterō, adulterāre, adulterāvī, adulterātum"
  ],
  "fly off or away": [
    "āvolō, āvolāre, āvolāvī, āvolātum"
  ],
  "flee away": [
    "āvolō, āvolāre, āvolāvī, āvolātum"
  ],
  "emulator": [
    "aemulātor, aemulātōris"
  ],
  "tyrannous": [
    "tyrannicus, tyrannica, tyrannicum"
  ],
  "tyrannical": [
    "tyrannicus, tyrannica, tyrannicum"
  ],
  "touch softly": [
    "palpō, palpāre, palpāvī, palpātum"
  ],
  "pat": [
    "palpō, palpāre, palpāvī, palpātum"
  ],
  "aptitude": [
    "habilitās, habilitātis"
  ],
  "obedience": [
    "oboedientia, oboedientiae"
  ],
  "peg": [
    "paxillus, paxillī"
  ],
  "pin": [
    "paxillus, paxillī"
  ],
  "small stake": [
    "paxillus, paxillī"
  ],
  "loss of hair": [
    "calvitium, calvitiī"
  ],
  "baldness": [
    "calvitium, calvitiī"
  ],
  "the part of a temple where votive offerings were made": [
    "dōnārium, dōnāriī"
  ],
  "manna": [
    "manna, mannae"
  ],
  "of or belonging to secret rites or mysteries": [
    "mysticus, mystica, mysticum"
  ],
  "am haughty or proud": [
    "superbiō, superbīre, superbīvī"
  ],
  "take pride in": [
    "superbiō, superbīre, superbīvī"
  ],
  "contain or give milk": [
    "lactō, lactāre, lactāvī, lactātum"
  ],
  "the black mulberry tree": [
    "mōrus, mōrī"
  ],
  "nurture or rear": [
    "ēnūtriō, ēnūtrīre, ēnūtrīvī, ēnūtritum"
  ],
  "canon": [
    "canonicus, canonici"
  ],
  "conditional": [
    "conditiōnālis, conditiōnāle"
  ],
  "root out": [
    "ērādīcō, ērādīcāre, ērādīcāvī, ērādīcātum"
  ],
  "arrant fool": [
    "mōriō, mōriōnis"
  ],
  "unsheathe": [
    "ēvāgīnō, ēvāgīnāre, ēvāgīnāvī, ēvāgīnātum"
  ],
  "rottenness": [
    "pūtrēdō, pūtrēdinis"
  ],
  "propitiate": [
    "propitiō, propitiāre, propitiāvī, propitiātum"
  ],
  "a joining": [
    "iunctura"
  ],
  "uniting": [
    "iunctura"
  ],
  "an association": [
    "iunctura"
  ],
  "soothingly": [
    "blanditer, blandius, blandissimē"
  ],
  "flatteringly": [
    "blanditer, blandius, blandissimē"
  ],
  "belonging to a wife": [
    "uxōrius, uxōria, uxōrium"
  ],
  "a small flower": [
    "flosculus, flosculī), second declension (diminutive of: flos",
    "flosculus, flosculī); second declension (Diminutive of: flos"
  ],
  "indescribable": [
    "inēnarrābilis, inēnārrabile"
  ],
  "a twist": [
    "tortus, tortūs"
  ],
  "a winding": [
    "tortus, tortūs"
  ],
  "a pedagogue": [
    "paedagōgus, paedagōgī"
  ],
  "transparency": [
    "perspicuitās, perspicuitātis"
  ],
  "specifically": [
    "speciāliter"
  ],
  "temperately": [
    "modestē, modestius, modestissimē"
  ],
  "discreetly": [
    "modestē, modestius, modestissimē"
  ],
  "modestly": [
    "modestē, modestius, modestissimē"
  ],
  "made of fir or deal": [
    "abiēgnus, abiēgna, abiēgnum"
  ],
  "overshadow": [
    "obumbrō, obumbrāre, obumbrāvī, obumbrātum"
  ],
  "strew or spatter": [
    "cōnspergō, cōnspergere, cōnspersī, cōnspersum"
  ],
  "laying": [
    "subiectiō, subiectiōnis"
  ],
  "putting or placing under": [
    "subiectiō, subiectiōnis"
  ],
  "prohibition": [
    "prohibitiō, prohibitiōnis"
  ],
  "prevention": [
    "prohibitiō, prohibitiōnis"
  ],
  "turning away": [
    "āversiō, āversiōnis"
  ],
  "highwayman": [
    "lātrunculus, lātrunculī"
  ],
  "attenuate": [
    "attenuō, attenuāre, attenuāvī, attenuātum"
  ],
  "flatter": [
    "assentor, assentārī, assentātus sum"
  ],
  "call to witness": [
    "contestor, contestārī, contestātus sum"
  ],
  "asp": [
    "aspis, aspidis"
  ],
  "of or pertaining to the earning of money": [
    "meritōrius, meritōria, meritōrium"
  ],
  "by which money is earned": [
    "meritōrius, meritōria, meritōrium"
  ],
  "for which money is paid": [
    "meritōrius, meritōria, meritōrium"
  ],
  "meritorious": [
    "meritōrius, meritōria, meritōrium"
  ],
  "a female calf": [
    "vitula, vitulae"
  ],
  "a heifer": [
    "vitula, vitulae"
  ],
  "of or belonging to a lord or master": [
    "dominicus, dominica, dominicum"
  ],
  "containing an objection or contradiction": [
    "contrādictōrius, contrādictōria, contrādictōrium"
  ],
  "contradictory": [
    "contrādictōrius, contrādictōria, contrādictōrium"
  ],
  "existence": [
    "existentia, existentiae"
  ],
  "contrariety": [
    "contrārietās, contrārietātis"
  ],
  "cross a strait": [
    "trānsfretō, trānsfretāre, trānsfretāvī, trānsfretātum"
  ],
  "preordain": [
    "praeordinō, praeordināre, praeordināvī, praeordinātum"
  ],
  "penal": [
    "poenālis, poenāle"
  ],
  "of or pertaining to punishment": [
    "poenālis, poenāle"
  ],
  "galilean": [
    "galīlaeus, galīlaea, galīlaeum"
  ],
  "paralyzed": [
    "paralyticus, paralytica, paralyticum"
  ],
  "paralytic": [
    "paralyticus, paralytica, paralyticum"
  ],
  "samaritan": [
    "samarītānus, samarītāna, samarītānum"
  ],
  "gather or lay up treasure": [
    "thēsaurizō, thēsaurizāre, thēsaurizāvī, thēsaurizātum"
  ],
  "appeasement": [
    "propitiātiō, propitiātiōnis"
  ],
  "propitiation": [
    "propitiātiō, propitiātiōnis"
  ],
  "a pressing": [
    "pressūra, pressūrae"
  ],
  "voluntary": [
    "spontāneus, spontānea, spontāneum"
  ],
  "spontaneous": [
    "spontāneus, spontānea, spontāneum"
  ],
  "henceforth": [
    "āmodo"
  ],
  "of or pertaining to memory or remembrance": [
    "memoriālis, memoriāle"
  ],
  "a great man": [
    "magnās, magnātis"
  ],
  "important person": [
    "magnās, magnātis"
  ],
  "magnate": [
    "magnās, magnātis"
  ],
  "evangelical": [
    "ēvangelicus, ēvangelica, ēvangelicum"
  ],
  "franc": [
    "francus, francī"
  ],
  "of or pertaining to a door": [
    "ostiārius, ostiāria, ostiārium"
  ],
  "carry or convey upwards": [
    "subvehō, subvehere, subvexī, subvectum"
  ],
  "or upriver": [
    "subvehō, subvehere, subvexī, subvectum"
  ],
  "the giving of security or bail": [
    "satisfactiō, satisfactiōnis"
  ],
  "put aside": [
    "dēpōnō, dēpōnere, dēposuī, dēpositum",
    "sēpōnō, sēpōnere, sēposuī, sēpositum"
  ],
  "a solemn procession": [
    "trānslātus, trānslātūs"
  ],
  "sit by or near someone or something": [
    "assideō, assidēre, assēdī, assessum"
  ],
  "the act of throwing up or vomiting": [
    "vomitus, vomitūs"
  ],
  "as much as you please": [
    "quantumlibet"
  ],
  "remunerate": [
    "remūnerō, remūnerāre, remūnerāvī, remūnerātum"
  ],
  "conjugal": [
    "coniugālis, coniugāle"
  ],
  "prelate": [
    "praelātus, praelātūs"
  ],
  "of comedy": [
    "cōmoedus, cōmoeda, cōmoedum"
  ],
  "the act of working by night": [
    "lūcubrātiō, lūcubrātiōnis"
  ],
  "candlelight or lamplight": [
    "lūcubrātiō, lūcubrātiōnis"
  ],
  "lucubration": [
    "lūcubrātiō, lūcubrātiōnis"
  ],
  "nocturnal study": [
    "lūcubrātiō, lūcubrātiōnis"
  ],
  "night work": [
    "lūcubrātiō, lūcubrātiōnis"
  ],
  "eyelid": [
    "palpebra, palpebrae"
  ],
  "a publishing": [
    "vulgātus, vulgātūs"
  ],
  "divulging": [
    "vulgātus, vulgātūs"
  ],
  "broadcasting": [
    "vulgātus, vulgātūs"
  ],
  "elbow": [
    "ulna, ulnae"
  ],
  "sound at the same time or together": [
    "cōnsonō, cōnsonāre, cōnsonuī"
  ],
  "sound aloud": [
    "cōnsonō, cōnsonāre, cōnsonuī"
  ],
  "miller": [
    "pistor, pistōris"
  ],
  "waterfall": [
    "tullius, tulliī",
    "cataracta, cataractae"
  ],
  "the act of giving": [
    "datiō, datiōnis"
  ],
  "allotting or distributing": [
    "datiō, datiōnis"
  ],
  "inundation": [
    "inundātiō, inundātiōnis"
  ],
  "an overflowing": [
    "inundātiō, inundātiōnis"
  ],
  "flare or blaze up": [
    "exardēscō, exardēscere, exarsī, exarsum"
  ],
  "wild ass": [
    "onager, onagrī"
  ],
  "onager": [
    "onager, onagrī"
  ],
  "an ape": [
    "sīmia, sīmiae"
  ],
  "monkey": [
    "sīmia, sīmiae"
  ],
  "run or float to": [
    "affluō, affluere, affluxī, affluxum"
  ],
  "towards or in": [
    "affluō, affluere, affluxī, affluxum"
  ],
  "the formation of solid material": [
    "concrētiō, concrētiōnis"
  ],
  "full of mist": [
    "cālīginōsus, cālīginōsa, cālīginōsum"
  ],
  "covered with mist": [
    "cālīginōsus, cālīginōsa, cālīginōsum"
  ],
  "misty": [
    "cālīginōsus, cālīginōsa, cālīginōsum"
  ],
  "foggy": [
    "cālīginōsus, cālīginōsa, cālīginōsum"
  ],
  "to put forth": [
    "pullulō, pullulāre, pullulāvī, pullulātum"
  ],
  "railing": [
    "caulae, caulārum"
  ],
  "of all or every sort or kind": [
    "omnimodus, omnimoda, omnimodum"
  ],
  "the act of receiving": [
    "receptiō, receptiōnis"
  ],
  "reception": [
    "receptiō, receptiōnis"
  ],
  "clean or cleanse thoroughly": [
    "ēmundō, ēmundāre, ēmundāvī, ēmundātum"
  ],
  "robe": [
    "indūmentum, indūmentī"
  ],
  "eminently": [
    "ēminenter, ēminentius, ēminentissimē"
  ],
  "discursive": [
    "discursīvus, discursīva, discursīvum"
  ],
  "contemplative": [
    "contemplātīvus, contemplātīva, contemplātīvum"
  ],
  "a measuring line": [
    "canōn, canōnis"
  ],
  "elementary": [
    "elementāris, elementāre"
  ],
  "rudimentary": [
    "elementāris, elementāre"
  ],
  "earthquake": [
    "terraemotus, terraemotūs"
  ],
  "made of byssus": [
    "byssinus, byssina, byssinum"
  ],
  "a small portion of meat or fish eaten as a starter to a meal": [
    "pulmentum, pulmentī"
  ],
  "please or delight": [
    "iūcundō, iūcundāre, iūcundāvī, iūcundātum"
  ],
  "earthenware or iron vessel for cooking bread": [
    "clībanus, clībanī"
  ],
  "aqueduct": [
    "aquaeductus, aquaeductūs"
  ],
  "conduit": [
    "aquaeductus, aquaeductūs"
  ],
  "at whatever time": [
    "quandōcumque"
  ],
  "as often as": [
    "quandōcumque"
  ],
  "at what time soever": [
    "quandōcumque"
  ],
  "rush upon": [
    "impetō, impetere, impetīvī, impetītum"
  ],
  "fourfold": [
    "quadruplex, quadruplicis"
  ],
  "quadruple": [
    "quadruplex, quadruplicis"
  ],
  "a coming between": [
    "interventus, interventūs"
  ],
  "molten": [
    "fūsilis, fūsile"
  ],
  "impetuous": [
    "vēsānus, vēsāna, vēsānum"
  ],
  "overloading of the stomach": [
    "crūditās, crūditātis"
  ],
  "bear or bring out or away": [
    "ēgerō, ēgerere, ēgessī, ēgestum"
  ],
  "cover wholly": [
    "cooperiō, cooperīre, cooperuī, coopertum"
  ],
  "peculiarly": [
    "pecūliāriter"
  ],
  "a doubling": [
    "geminātiō, geminātiōnis"
  ],
  "ablative": [
    "ablātīvus, ablātīva, ablātīvum"
  ],
  "uninformed": [
    "inērudītus, inērudīta, inērudītum"
  ],
  "uneducated": [
    "inērudītus, inērudīta, inērudītum"
  ],
  "exhaling": [
    "rēspīrātiō, rēspīrātiōnis"
  ],
  "fraudulent": [
    "fraudulentus, fraudulenta, fraudulentum"
  ],
  "a worker in gold": [
    "aurifex, aurificis"
  ],
  "goldsmith": [
    "aurifex, aurificis"
  ],
  "mole": [
    "talpa, talpae"
  ],
  "wage war": [
    "belligerō, belligerāre, belligerāvī, belligerātum"
  ],
  "pour or spread over": [
    "offundō, offundere, offudī, offusum"
  ],
  "down etc": [
    "offundō, offundere, offudī, offusum"
  ],
  "a laying claim to": [
    "vindicia, vindiciae"
  ],
  "blackberry": [
    "batus, batī"
  ],
  "a particle": [
    "corpusculum, corpusculī"
  ],
  "especially an atom": [
    "corpusculum, corpusculī"
  ],
  "yes man": [
    "assentātor, assentātōris"
  ],
  "small villa": [
    "villula, villulae"
  ],
  "stade": [
    "stadium, stadiī"
  ],
  "treaty": [
    "foedus, foederis"
  ],
  "and in addition": [
    "ac"
  ],
  "simul ac": [
    "ac"
  ],
  "→ atque": [
    "ac"
  ],
  "level surface": [
    "aequor, aequoris"
  ],
  "pure upper air": [
    "aethēr, aetheris"
  ],
  "ether": [
    "aethēr, aetheris"
  ],
  "lifetime": [
    "aevum, aevī"
  ],
  "ploughed land": [
    "arvum, arvī"
  ],
  "influence": [
    "auctōritās, auctōritātis"
  ],
  "clout": [
    "auctōritās, auctōritātis"
  ],
  "breeze": [
    "aura, aurae"
  ],
  "from or of heaven": [
    "caelestis, caeleste"
  ],
  "caelestēs": [
    "caelestis, caeleste"
  ],
  "ashes": [
    "cinis, cineris"
  ],
  "embers": [
    "cinis, cineris"
  ],
  "gather together": [
    "colligō, colligere, collēgī, collēctum"
  ],
  "tresses": [
    "coma, comae"
  ],
  "entrust to": [
    "committō, committere, commīsī, commissum"
  ],
  "store up": [
    "condō, condere, condidī, conditum"
  ],
  "consider the interests of": [
    "cōnsulō, cōnsulere, cōnsuluī, cōnsultum"
  ],
  "be contiguous to": [
    "contingō, contingere, contigī, contāctum"
  ],
  "create": [
    "Crea",
    "creō, creāre, creāvī, creātum"
  ],
  "verdict": [
    "crīmen, crīminis"
  ],
  "a criminal": [
    "crīmen, crīminis"
  ],
  "guilt": [
    "Culpa",
    "culpa, culpae"
  ],
  "craving": [
    "cupīdō, cupīdinis"
  ],
  "guardian": [
    "custōs, custōdis"
  ],
  "launch": [
    "dēdūcō, dēdūcere, dēdūxī, dēductum"
  ],
  "lead away": [
    "dēdūcō, dēdūcere, dēdūxī, dēductum"
  ],
  "be lacking": [
    "dēsum, dēesse, dēfuī, dēfutūrus"
  ],
  "differ": [
    "differō, differre, distulī, dīlātum"
  ],
  "defer": [
    "differō, differre, distulī, dīlātum"
  ],
  "postpone": [
    "differō, differre, distulī, dīlātum"
  ],
  "diverse": [
    "dīversus, dīversa, dīversum"
  ],
  "godlike": [
    "dīvus, dīva, dīvum"
  ],
  "a god": [
    "dīvus, dīva, dīvum"
  ],
  "device": [
    "dolus, dolī",
    "Mechanismum"
  ],
  "stride out": [
    "ēgredior, ēgredī, ēgressus sum"
  ],
  "disembark from": [
    "ēgredior, ēgredī, ēgressus sum"
  ],
  "go forth": [
    "exeō, exīre, exiī, exitum"
  ],
  "try thoroughly": [
    "experior, experīrī, expertus sum"
  ],
  "situated at the end or tip": [
    "extrēmus, extrēma, extrēmum"
  ],
  "luckily": [
    "fēlīciter, fēlīcius, fēlīcissimē"
  ],
  "fountain": [
    "fōns, fontis"
  ],
  "funeral": [
    "fūnus, fūneris"
  ],
  "dead body": [
    "fūnus, fūneris"
  ],
  "urge strongly": [
    "hortor, hortārī, hortātus sum"
  ],
  "fall upon": [
    "incidō incidere incidī"
  ],
  "fall into": [
    "incidō incidere incidī"
  ],
  "bring upon": [
    "īnferō, īnferre, intulī, illātum"
  ],
  "bellum īnferre: make war on": [
    "īnferō, īnferre, intulī, illātum"
  ],
  "unpleasant": [
    "ingrātus, ingrāta, ingrātum"
  ],
  "step in": [
    "ingredior, ingredī, ingressus sum"
  ],
  "grow angry": [
    "īrāscor, īrāscī, īrātus sum"
  ],
  "judgment": [
    "iūdicium, iūdiciī"
  ],
  "yoke": [
    "iugum, iugī"
  ],
  "ridge": [
    "iugum, iugī"
  ],
  "chain of hills": [
    "iugum, iugī"
  ],
  "take an oath": [
    "iūrō, iūrāre, iūrāvī, iūrātum"
  ],
  "swear": [
    "iūrō, iūrāre, iūrāvī, iūrātum"
  ],
  "lust": [
    "libīdō, libīdinis"
  ],
  "grove": [
    "nemus, nemoris"
  ],
  "divine will": [
    "nūmen, nūminis"
  ],
  "run to meet": [
    "occurrō, occurrere, occurrī, occursum"
  ],
  "come into one’s mind": [
    "occurrō, occurrere, occurrī, occursum"
  ],
  "commit a wrong": [
    "peccō, peccāre, peccāvī, peccātum"
  ],
  "sense of duty": [
    "pietās, pietātis"
  ],
  "devotion": [
    "pietās, pietātis"
  ],
  "esp. between parents and children": [
    "pietās, pietātis"
  ],
  "the deep": [
    "pontus, pontī"
  ],
  "render": [
    "praebeō, praebēre, praebuī, praebitum"
  ],
  "precept": [
    "praeceptum, praeceptī"
  ],
  "booty": [
    "praeda, -ae f",
    "praeda, praedae"
  ],
  "prey": [
    "Prey",
    "praeda, praedae"
  ],
  "praetor": [
    "praetor, praetōris"
  ],
  "one of the chief roman magistrates": [
    "praetor, praetōris"
  ],
  "convince one of a thing": [
    "probō, probāre, probāvī, probātum"
  ],
  "give over": [
    "prōdō, prōdere, prōdidī, prōditum",
    "trādo, trādere, trādidī, trāditum (trāns + dō)"
  ],
  "betray": [
    "prōdō, prōdere, prōdidī, prōditum"
  ],
  "propose": [
    "prōpōnō, prōpōnere, prōposuī, prōpositum"
  ],
  "however you like": [
    "quamvīs"
  ],
  "in what manner": [
    "quemadmodum"
  ],
  "kingly": [
    "rēgius, rēgia, rēgium"
  ],
  "deem": [
    "reor, rērī, ratus sum"
  ],
  "look back": [
    "respiciō, respicere, respexī, respectum"
  ],
  "defendant": [
    "reus, reī"
  ],
  "holy": [
    "sacer, sacra, sacrum"
  ],
  "sacred": [
    "sacer, sacra, sacrum"
  ],
  "free from care": [
    "sēcūrus, sēcūra, sēcūrum"
  ],
  "emotion": [
    "sēnsus, sēnsūs"
  ],
  "place of burial": [
    "sepulcrum, sepulcrī"
  ],
  "space": [
    "spatium, spatiī"
  ],
  "those above": [
    "superī -ōrum"
  ],
  "i.e. the gods": [
    "superī -ōrum"
  ],
  "missile": [
    "tēlum, tēlī"
  ],
  "weapon": [
    "Armum",
    "tēlum, tēlī"
  ],
  "tender": [
    "tener, tenera, tenerum",
    "praebeō, praebēre, praebuī, praebitus"
  ],
  "title of various roman officials": [
    "tribūnus, tribūnī"
  ],
  "flowing water": [
    "unda, undae"
  ],
  "be empty": [
    "vacō, vacāre, vacāvī, vacātum"
  ],
  "shifting": [
    "vagus, vaga, vagum"
  ],
  "bard": [
    "Bard",
    "Bardus",
    "vātēs, vātis"
  ],
  "neighboring": [
    "vīcīnus, vīcīna, vīcīnum"
  ],
  "flaw": [
    "vitium, vitiī"
  ],
  "usually": [
    "vulgō"
  ],
  "magician": [
    "magus, magī"
  ],
  "sorcerer": [
    "Magus",
    "Sorcerer",
    "magus, magī"
  ],
  "trickster": [
    "magus, magī"
  ],
  "that happens often": [
    "saepis, saepe"
  ],
  "son-in-law": [
    "gener, generī"
  ],
  "confiscate": [
    "pūblicō, pūblicāre, pūblicāvī, pūblicātum"
  ],
  "make public": [
    "pūblicō, pūblicāre, pūblicāvī, pūblicātum"
  ],
  "to prune": [
    "castrō, castrāre, castrāvī, castrātum"
  ],
  "amputate": [
    "castrō, castrāre, castrāvī, castrātum"
  ],
  "castrate": [
    "castrō, castrāre, castrāvī, castrātum"
  ],
  "to make progress": [
    "prōficiō, prōficere, prōfēcī, prōfectum"
  ],
  "to fill with air": [
    "animō, animāre, animāvī, animātum"
  ],
  "to animate": [
    "animō, animāre, animāvī, animātum"
  ],
  "to refresh": [
    "animō, animāre, animāvī, animātum"
  ],
  "to bring about": [
    "patrō, patrāre, patrāvī, patrātum"
  ],
  "to taste": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "spill": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "fulfill": [
    "pleō, plēre, plēvī, plētum"
  ],
  "a joining together": [
    "armus, armī"
  ],
  "to accomplish with zeal": [
    "nāvō, nāvāre, nāvāvī, nāvātum"
  ],
  "to turn often": [
    "versō, versāre, versāvī, versātum"
  ],
  "to meditate": [
    "versō, versāre, versāvī, versātum"
  ],
  "strict": [
    "artus"
  ],
  "a joint": [
    "artus, arta, artum"
  ],
  "to appease": [
    "plācō, plācāre, plācāvī, plācātum"
  ],
  "placate": [
    "plācō, plācāre, plācāvī, plācātum"
  ],
  "a place in the forum where comitia were held": [
    "comitium, comitiī"
  ],
  "to happen": [
    "ēveniō, ēvenīre, ēvēnī, ēventum"
  ],
  "ardent": [
    "vehemēns, vehementis"
  ],
  "of servants": [
    "familiāris, familiāre"
  ],
  "of a family": [
    "familiāris, familiāre"
  ],
  "an elected chief magistrate": [
    "dictātor, dictātōris"
  ],
  "colleague": [
    "collēga, collēgae"
  ],
  "opnion": [
    "opīniō, opīniōnis"
  ],
  "whoever you will": [
    "quīvīs, quaevīs, quodvīs"
  ],
  "to make equal": [
    "aequō, aequāre, aequāvī, aequātum"
  ],
  "to compare": [
    "aequō, aequāre, aequāvī, aequātum"
  ],
  "to capture": [
    "opprimō, opprimere, oppressī, oppressum"
  ],
  "to barricade": [
    "commūniō, commūnīre, commūnīvī, commūnītum"
  ],
  "to strengthen": [
    "commūniō, commūnīre, commūnīvī, commūnītum"
  ],
  "to grant": [
    "tribuō, tribuere, tribuī, tribūtum"
  ],
  "to yield": [
    "tribuō, tribuere, tribuī, tribūtum"
  ],
  "to hinder": [
    "impediō, impedīre, impedīvī, impedītum"
  ],
  "to boast": [
    "glōrior, glōriārī, glōriātus sum"
  ],
  "brag": [
    "glōrior, glōriārī, glōriātus sum"
  ],
  "to glory": [
    "glōrior, glōriārī, glōriātus sum"
  ],
  "commodious": [
    "commodus, commoda, commodum"
  ],
  "convenient": [
    "commodus, commoda, commodum"
  ],
  "the consulate": [
    "cōnsulātus, cōnsulātūs"
  ],
  "consulship": [
    "cōnsulātus, cōnsulātūs"
  ],
  "to write together in a list": [
    "cōnscrībō, cōnscrībere, cōnscrīpsī, cōnscrīptum"
  ],
  "to compose": [
    "cōnscrībō, cōnscrībere, cōnscrīpsī, cōnscrīptum"
  ],
  "to enter": [
    "ineō, inīre, iniī, initum"
  ],
  "very clear": [
    "praeclārus, praeclāra, praeclārum"
  ],
  "to throw together": [
    "cōniciō, cōnicere, cōniēcī, coniectum"
  ],
  "to assign": [
    "cōniciō, cōnicere, cōniēcī, coniectum"
  ],
  "to sweep": [
    "verrō, verrere, verrī, versum"
  ],
  "to replace": [
    "restituō, restituere, restituī, restitūtum"
  ],
  "terror": [
    "Terror",
    "terror, terrōris"
  ],
  "go about": [
    "circō, circāre, circāvī, circātum"
  ],
  "to bore": [
    "forō, forāre, forāvī, forātum"
  ],
  "to pierce": [
    "forō, forāre, forāvī, forātum"
  ],
  "to go by": [
    "praetereō, praeterīre, praeteriī, praeteritum",
    "praetereō, praeterīre, praeteriī, praeteritus"
  ],
  "to make sacred": [
    "sanciō, sancīre, sānxī, sānctum"
  ],
  "to declare as sacred": [
    "sacrō, sacrāre, sacrāvī, sacrātum"
  ],
  "to obstruct": [
    "officiō, officere, offēcī, offectum"
  ],
  "to block": [
    "officiō, officere, offēcī, offectum"
  ],
  "to let go": [
    "omittō, omittere, omīsī, omissum"
  ],
  "to give up": [
    "omittō, omittere, omīsī, omissum"
  ],
  "to omit": [
    "omittō, omittere, omīsī, omissum"
  ],
  "testimony": [
    "testimōnium, testimōniī"
  ],
  "to test": [
    "tentō, tentāre, tentāvī, tentātum"
  ],
  "destruction f": [
    "clādes, clādis"
  ],
  "embassy f": [
    "lēgātiō, lēgātiōnis"
  ],
  "give a pledge as bondsman": [
    "auctōrō, auctōrāre, auctōrāvī, auctōrātum"
  ],
  "education": [
    "disciplīna, disciplīnae"
  ],
  "to sprout green growth": [
    "vireō, virēre, viruī"
  ],
  "to flourish": [
    "vireō, virēre, viruī"
  ],
  "compute": [
    "disputō, disputāre, disputāvī, disputātum"
  ],
  "to move violently": [
    "commoveō, commovēre, commōvī, commōtum"
  ],
  "to remove": [
    "commoveō, commovēre, commōvī, commōtum"
  ],
  "censor": [
    "cēnsor, cēnsōris"
  ],
  "to accompany": [
    "comitō, comitāre, comitāvī, comitātum"
  ],
  "attend": [
    "comitō, comitāre, comitāvī, comitātum"
  ],
  "to rub": [
    "terō, terere, trīvī, trītum"
  ],
  "tread": [
    "terō, terere, trīvī, trītum"
  ],
  "to furnish with feet": [
    "pedō, pedāre, pedāvī, pedātum"
  ],
  "to linger": [
    "morārī"
  ],
  "to delay": [
    "morārī"
  ],
  "action": [
    "āctiō, āctiōnis"
  ],
  "to take away": [
    "adimō, adimere, adēmī, ademptum"
  ],
  "to unite": [
    "conciliō, conciliāre, conciliāvī, conciliātum",
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "to recommend": [
    "commendō, commendāre, commendāvī, commendātum",
    "conciliō, conciliāre, conciliāvī, conciliātum"
  ],
  "accuser": [
    "accūsātor, accūsātōris"
  ],
  "denouncer": [
    "accūsātor, accūsātōris"
  ],
  "to stand firm": [
    "restō, restāre, restitī, restātum"
  ],
  "augury by watching birds": [
    "auspicium, auspiciī"
  ],
  "a high priest": [
    "pontifex, pontificis"
  ],
  "outpost": [
    "statiō, statiōnis"
  ],
  "station": [
    "statiō, statiōnis"
  ],
  "triumph": [
    "triumphus, triumphī"
  ],
  "celebration": [
    "triumphus, triumphī"
  ],
  "to shut": [
    "arceō, arcēre, arcuī, arcitum"
  ],
  "to keep off": [
    "arceō, arcēre, arcuī, arcitum"
  ],
  "to aspire to": [
    "expetō, expetere, expetīvī, expetītum"
  ],
  "to strip": [
    "spoliō, spoliāre, spoliāvī, spoliātum"
  ],
  "to unclothe": [
    "spoliō, spoliāre, spoliāvī, spoliātum"
  ],
  "to disarm": [
    "spoliō, spoliāre, spoliāvī, spoliātum"
  ],
  "to pluck off": [
    "dēligō, dēligere, dēlēgī, dēlēctum"
  ],
  "to choose": [
    "dēligō, dēligere, dēlēgī, dēlēctum"
  ],
  "to carve": [
    "caelō, caelāre, caelāvī, caelātum"
  ],
  "to engrave": [
    "caelō, caelāre, caelāvī, caelātum"
  ],
  "to go among": [
    "intereō, interīre, interiī, interitum"
  ],
  "to be ruined": [
    "intereō, interīre, interiī, interitum"
  ],
  "deprived of by death – orphaned": [
    "orbus, orba, orbum"
  ],
  "widowed": [
    "orbus, orba, orbum"
  ],
  "to elevate": [
    "excellō, excellere, excelluī, excelsum"
  ],
  "to rise": [
    "excellō, excellere, excelluī, excelsum"
  ],
  "colony": [
    "colōnia, colōniae"
  ],
  "howsoever": [
    "cumque"
  ],
  "whensoever": [
    "cumque"
  ],
  "to hit": [
    "offendō, offendere, offendī, offēnsum"
  ],
  "to meet": [
    "offendō, offendere, offendī, offēnsum"
  ],
  "to fail": [
    "offendō, offendere, offendī, offēnsum"
  ],
  "to defile": [
    "foedō, foedāre, foedāvī, foedātum"
  ],
  "deform": [
    "foedō, foedāre, foedāvī, foedātum"
  ],
  "to stand upon": [
    "īnstō, īnstāre, īnstitī, īnstātum"
  ],
  "to assail": [
    "īnstō, īnstāre, īnstitī, īnstātum"
  ],
  "to insist": [
    "īnstō, īnstāre, īnstitī, īnstātum"
  ],
  "to gild": [
    "aurō, aurāre, aurāvī, aurātum"
  ],
  "to drag": [
    "tractō, tractāre, tractāvī, tractātum"
  ],
  "to handle": [
    "tractō, tractāre, tractāvī, tractātum"
  ],
  "to commend": [
    "commendō, commendāre, commendāvī, commendātum"
  ],
  "to examine": [
    "cōnsīderō, cōnsīderāre, cōnsīderāvī, cōnsīderātum"
  ],
  "to consider": [
    "cōnsīderō, cōnsīderāre, cōnsīderāvī, cōnsīderātum"
  ],
  "to withdraw": [
    "dēcēdō, dēcēdere, dēcessī, dēcessum"
  ],
  "to change nature of someone or somebody": [
    "aliēnō, aliēnāre, aliēnāvī, aliēnātum"
  ],
  "to make foreign": [
    "aliēnō, aliēnāre, aliēnāvī, aliēnātum"
  ],
  "to treat with violence": [
    "violō, violāre, violāvī, violātum"
  ],
  "to violate": [
    "violō, violāre, violāvī, violātum"
  ],
  "be above": [
    "superō, superāre, superāvī, superātum",
    "superō, superāre, superāvī, superātus"
  ],
  "to bind together": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "to sieze": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "to apprehend": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "to comprehend": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "take in": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "to lay away": [
    "dēpōnō, dēpōnere, dēposuī, dēpositum"
  ],
  "set down": [
    "dēpōnō, dēpōnere, dēposuī, dēpositum"
  ],
  "vigorous": [
    "strēnuus, -a, -um"
  ],
  "strenuous": [
    "strēnuus, -a, -um"
  ],
  "go into": [
    "ingredior, ingredī, ingressus sum"
  ],
  "bring to rest": [
    "sēdō, sēdāre, sēdāvī, sēdātus"
  ],
  "to lead to": [
    "addūcō, addūcere, addūxī, adductum"
  ],
  "send out": [
    "ēmittō, ēmittere, ēmīsī, ēmissum"
  ],
  "aim weapon": [
    "tendō, tendere, tendī, tentus"
  ],
  "direct glance": [
    "tendō, tendere, tendī, tentus"
  ],
  "direct steps": [
    "tendō, tendere, tendī, tentus"
  ],
  "frog": [
    "rāna -ae f"
  ],
  "come next": [
    "īnsequor, īnsequī, īnsecūtus sum"
  ],
  "a ram": [
    "ariēs, arietis m"
  ],
  "to hold forth": [
    "praebeō, praebēre, praebuī, praebitus"
  ],
  "reach out": [
    "praebeō, praebēre, praebuī, praebitus"
  ],
  "proffer": [
    "praebeō, praebēre, praebuī, praebitus"
  ],
  "sit in": [
    "īnsideō, īnsidēre, īnsēdī, —"
  ],
  "sit on": [
    "īnsideō, īnsidēre, īnsēdī, —"
  ],
  "lie in ambush": [
    "īnsideō, īnsidēre, īnsēdī, —"
  ],
  "far removed": [
    "longinquus, -a, -um"
  ],
  "far off": [
    "longinquus, -a, -um"
  ],
  "remote": [
    "longinquus, -a, -um"
  ],
  "slip down": [
    "dēlābor, dēlābī, dēlapsus sum"
  ],
  "glide down": [
    "dēlābor, dēlābī, dēlapsus sum"
  ],
  "wool shorn off": [
    "vellus, veleris",
    "vellus, veleris (n)"
  ],
  "sheepskin": [
    "vellus, veleris",
    "vellus, veleris (n)"
  ],
  "dragon": [
    "Draco",
    "dracō, dracōnis",
    "dracō, dracōnis (m)"
  ],
  "trunk": [
    "truncus, -ī",
    "truncus, -ī (m)"
  ],
  "oak tree": [
    "quercus -ūs",
    "quercus -ūs (f)"
  ],
  "to cause to revolve": [
    "volvō, volvere, volvī, volūtum"
  ],
  "turn about": [
    "volvō, volvere, volvī, volūtum"
  ],
  "turn round": [
    "volvō, volvere, volvī, volūtum"
  ],
  "she-goat": [
    "capra -ae",
    "capra -ae (f)",
    "capra, caprae"
  ],
  "portray or represent in a dance": [
    "saltō, saltāre, saltāvī, saltātus"
  ],
  "structure for sacrifice": [
    "āra, -ae",
    "āra, -ae (f)"
  ],
  "box": [
    "arca -ae",
    "arca -ae (f)"
  ],
  "place for safe-keeping": [
    "arca -ae",
    "arca -ae (f)"
  ],
  "chink": [
    "rīma -ae",
    "rīma -ae (f)"
  ],
  "to fit out": [
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus"
  ],
  "deck": [
    "exōrnō, exōrnāre, exōrnāvī, exōrnātus"
  ],
  "raven": [
    "corvus -ī",
    "corvus -ī (m)",
    "corvus, corvī"
  ],
  "repent": [
    "paeniteō, paenitēre, paenituī, —"
  ],
  "trumpet": [
    "tuba -ae",
    "tuba -ae (f)"
  ],
  "war-trumpet": [
    "tuba -ae",
    "tuba -ae (f)"
  ],
  "wreath": [
    "corōna -ae",
    "corōna -ae (f)"
  ],
  "halo": [
    "corōna -ae",
    "corōna -ae (f)"
  ],
  "turning-point": [
    "mēta -ae",
    "mēta -ae (f)"
  ],
  "go past": [
    "praetereō, praeterīre, praeteriī, praeteritus"
  ],
  "pass by": [
    "praetereō, praeterīre, praeteriī, praeteritus"
  ],
  "to withstand": [
    "restō, restāre, restitī, —"
  ],
  "not yield": [
    "restō, restāre, restitī, —"
  ],
  "to be left": [
    "restō, restāre, restitī, —"
  ],
  "run for succor": [
    "confugiō, confugere, confūgī, ----"
  ],
  "statesmanship": [
    "prūdentia -ae",
    "prūdentia -ae (f)"
  ],
  "sagacity": [
    "prūdentia -ae",
    "prūdentia -ae (f)"
  ],
  "good sense": [
    "prūdentia -ae",
    "prūdentia -ae (f)"
  ],
  "prudence": [
    "prūdentia -ae",
    "prūdentia -ae (f)"
  ],
  "from the latter": [
    "a posteriori"
  ],
  "from what comes before": [
    "a priori"
  ],
  "deeds": [
    "acta non verba"
  ],
  "not words": [
    "acta non verba"
  ],
  "to this": [
    "ad hoc"
  ],
  "to the man": [
    "ad hominem"
  ],
  "for honor": [
    "ad honorem"
  ],
  "to infinity": [
    "ad infinitum"
  ],
  "used to describe an argument that has been taking place to the point of nausea": [
    "ad nauseam"
  ],
  "to victory": [
    "ad victoriam"
  ],
  "the die has been cast": [
    "alea iacta est"
  ],
  "at another time": [
    "alias"
  ],
  "nourishing mother": [
    "alma mater"
  ],
  "love of one's country": [
    "amor patriae"
  ],
  "love conquers all": [
    "amor vincit omnia"
  ],
  "before noon": [
    "ante meridiem"
  ],
  "water of life in france)": [
    "aqua vitae"
  ],
  "to dare is to do": [
    "audere est facere"
  ],
  "golden mean": [
    "aurea mediocritas"
  ],
  "either kill or be killed": [
    "aut neca aut necare"
  ],
  "i will either find a way or make one": [
    "aut viam inveniam aut faciam"
  ],
  "a beard doesn't make one a philosopher": [
    "barba non facit philosophum"
  ],
  "war of all against all": [
    "bellum omnium contra omnes"
  ],
  "good faith": [
    "bona fide"
  ],
  "seize the day": [
    "carpe diem"
  ],
  "or approximately": [
    "circa"
  ],
  "faster": [
    "citius altius fortius"
  ],
  "i think therefore i am": [
    "cogito ergo sum"
  ],
  "body of christ": [
    "corpus christi"
  ],
  "take care of your own self": [
    "cura te ipsum"
  ],
  "the course of one's life": [
    "curriculum vitae"
  ],
  "from the fact": [
    "de facto"
  ],
  "god willing": [
    "deo volente"
  ],
  "and others": [
    "et alii"
  ],
  "and the others": [
    "et cetera"
  ],
  "from the heart": [
    "ex animo"
  ],
  "from the library of": [
    "ex libris"
  ],
  "out of nothing": [
    "ex nihilo"
  ],
  "make alike": [
    "fac simile"
  ],
  "this is war": [
    "hoc est bellum"
  ],
  "enemy of the human race": [
    "hostis humani generis"
  ],
  "in the absence": [
    "in absentia"
  ],
  "in flaming crime": [
    "in flagrante delicto"
  ],
  "into the memory": [
    "in memoriam"
  ],
  "ready for anything": [
    "in omnia paratus"
  ],
  "in position": [
    "in situ"
  ],
  "in all or entirely": [
    "in toto"
  ],
  "in the womb": [
    "in utero"
  ],
  "in glass": [
    "in vitro"
  ],
  "hard work conquers all": [
    "labor omnia vincit"
  ],
  "with great praise": [
    "magna cum laude"
  ],
  "my fault": [
    "mea culpa"
  ],
  "remember that [you will] die": [
    "memento mori"
  ],
  "remember to live": [
    "memento vivere"
  ],
  "method of operating": [
    "modus operandi"
  ],
  "i am not led": [
    "non ducor, duco"
  ],
  "i lead": [
    "non ducor, duco"
  ],
  "not talk but action": [
    "non loqui sed facere"
  ],
  "it does not follow": [
    "non sequitur"
  ],
  "know thyself": [
    "nosce te ipsum"
  ],
  "bread and circuses": [
    "panem et circenses"
  ],
  "through difficulties to greatness": [
    "per angusta ad augusta"
  ],
  "by the year": [
    "per annum"
  ],
  "by the person": [
    "per capita"
  ],
  "by the day": [
    "per diem"
  ],
  "through itself": [
    "per se"
  ],
  "not pleasing person": [
    "persona non grata"
  ],
  "afternoon": [
    "post meridiem"
  ],
  "p.m": [
    "post meridiem"
  ],
  "after death": [
    "post mortem"
  ],
  "thing having been written afterward": [
    "postscriptum"
  ],
  "for the good": [
    "pro bono"
  ],
  "for the rate": [
    "pro rata"
  ],
  "as if or as though": [
    "quasi"
  ],
  "he who wants everything loses everything": [
    "qui totum vult totum perdit"
  ],
  "what's going on": [
    "Quid fit",
    "quid agis",
    "Quid agitur",
    "Quid accidit"
  ],
  "let him rest in peace": [
    "requiescat in pace"
  ],
  "stiffness of death": [
    "rigor mortis"
  ],
  "knowledge through hard work": [
    "scientia ac labore"
  ],
  "knowledge itself is power": [
    "scientia ipsa potentia est"
  ],
  "always brave": [
    "semper fortis"
  ],
  "always prepared": [
    "semper paratus"
  ],
  "always virile": [
    "semper virilis"
  ],
  "when you are strong": [
    "si vales, valeo"
  ],
  "i am strong": [
    "si vales, valeo"
  ],
  "if you want peace": [
    "si vis pacem, para bellum"
  ],
  "prepare for war": [
    "si vis pacem, para bellum"
  ],
  "thus is life": [
    "sic vita est"
  ],
  "by faith alone": [
    "sola fide"
  ],
  "good hope": [
    "spes bona"
  ],
  "the situation in which or current condition": [
    "status quo"
  ],
  "under penalty": [
    "subpoena"
  ],
  "i am what you will be": [
    "sum quod eris"
  ],
  "with highest praise": [
    "summa cum laude"
  ],
  "the supreme good": [
    "summum bonum"
  ],
  "to each his own": [
    "suum cuique"
  ],
  "scraped tablet": [
    "tabula rasa"
  ],
  "time flees": [
    "tempus fugit"
  ],
  "firm ground": [
    "terra firma"
  ],
  "unknown land": [
    "terra incognita"
  ],
  "i came": [
    "veni vidi vici"
  ],
  "i saw": [
    "veni vidi vici"
  ],
  "i conquered": [
    "veni vidi vici"
  ],
  "repeat exactly": [
    "verbatim"
  ],
  "versus": [
    "against"
  ],
  "i forbid": [
    "veto"
  ],
  "to change or turn around": [
    "vice versa"
  ],
  "he conquers who endures": [
    "vincit qui patitur"
  ],
  "he conquers who conquers himself": [
    "vincit qui se vincit"
  ],
  "act in a manly way": [
    "viriliter age"
  ],
  "to live is to conquer": [
    "vivere est vincere"
  ],
  "to live is to fight": [
    "vivere militare est"
  ],
  "voice of the people": [
    "vox populi"
  ],
  "to the stars": [
    "ad astra"
  ],
  "through hardship to the stars": [
    "per aspera ad astra"
  ],
  "knowledge is power": [
    "scientia est potentia"
  ],
  "appearing": [
    "ubiquitous"
  ],
  "or found everywhere": [
    "ubiquitous"
  ],
  "i will be what you are": [
    "ero quod es"
  ],
  "improper": [
    "sinister"
  ],
  "dare to think for yourself": [
    "sapere aude"
  ],
  "if you had been silent": [
    "si tacuisses, philosophus mansisses"
  ],
  "you would have remained a philosopher": [
    "si tacuisses, philosophus mansisses"
  ],
  "the pushing": [
    "agens"
  ],
  "acting": [
    "agens"
  ],
  "other self": [
    "alter ego"
  ],
  "art of loving": [
    "ars amatoria"
  ],
  "from the depths": [
    "de profundis"
  ],
  "lovers are lunatics": [
    "amantes amentes [sunt]"
  ],
  "in eternal thought": [
    "in perpetuam memoriam"
  ],
  "under a rose - symbol for secrecy": [
    "sub rosa"
  ],
  "for many years": [
    "ad multos annos"
  ],
  "very intimate friend": [
    "intimus",
    "intimus (intima)"
  ],
  "from one's own capacities": [
    "ex propriis"
  ],
  "with fire and iron - to eradicate an enemy": [
    "igni et ferro"
  ],
  "one for all": [
    "unus pro multis"
  ],
  "stay away": [
    "absit"
  ],
  "stay far": [
    "absit"
  ],
  "first person in a family to serve the roman senate": [
    "homo novus"
  ],
  "first accomplished or rich family member": [
    "homo novus"
  ],
  "a day teaches the next one": [
    "dies diem docet"
  ],
  "i have spoken": [
    "dixi"
  ],
  "what was to be proven": [
    "quod erat demonstrandum",
    "Quod erat demonstrandum (Q.E.D.)"
  ],
  "this has been demonstrated": [
    "quod erat demonstrandum",
    "Quod erat demonstrandum (Q.E.D.)"
  ],
  "what still is to be proven": [
    "quod esset demonstrandum"
  ],
  "seize the night": [
    "carpe noctem"
  ],
  "use the night": [
    "carpe noctem"
  ],
  "from nothing comes nothing": [
    "de nihilo nihil"
  ],
  "god from a machine - a story or plot resolved by an unlikely occurrence": [
    "deus ex machina"
  ],
  "something beyond the usual": [
    "extra"
  ],
  "something free": [
    "gratis"
  ],
  "with no cost": [
    "gratis"
  ],
  "name is a sign": [
    "nomen est omen"
  ],
  "term of experts": [
    "terminus technicus"
  ],
  "vocabulary of experts": [
    "terminus technicus"
  ],
  "an object of crime": [
    "corpus delicti"
  ],
  "it does not stink": [
    "[pecunia] non olet"
  ],
  "higher force": [
    "vis major"
  ],
  "the entire world is to be ruled by austria": [
    "a.e.i.o.u. - austriae est imperare orbi universo"
  ],
  "a white raven": [
    "corvus albus"
  ],
  "somebody different": [
    "corvus albus"
  ],
  "man [is a] wolf to man": [
    "homo homini lupus"
  ],
  "horror of the emptiness": [
    "horror vacui"
  ],
  "a public thing": [
    "res publica"
  ],
  "common sense": [
    "sensus communis"
  ],
  "without time": [
    "sine / cum tempore"
  ],
  "with time": [
    "sine / cum tempore"
  ],
  "you too": [
    "et tu, brute",
    "Et tu, Brute? (Tu quoque, Brute?)"
  ],
  "brutus": [
    "et tu, brute",
    "Et tu, Brute? (Tu quoque, Brute?)"
  ],
  "let there be light": [
    "fiat lux"
  ],
  "cause of death": [
    "causa mortis"
  ],
  "or because of death": [
    "causa mortis"
  ],
  "while i breathe": [
    "dum spiro, spero"
  ],
  "i hope": [
    "dum spiro, spero"
  ],
  "a healthy mind in a healthy body": [
    "mens sana in corpore sano"
  ],
  "death is certain": [
    "mors certa, hora incerta"
  ],
  "the hour of death is not": [
    "mors certa, hora incerta"
  ],
  "do not harm": [
    "primum non nocere"
  ],
  "i know many but not all things": [
    "res multas non omnes scio"
  ],
  "may he live": [
    "vivat, crescat, floreat"
  ],
  "to absurdity": [
    "ad absurdum"
  ],
  "putting something to the files": [
    "ad acta"
  ],
  "archive": [
    "ad acta"
  ],
  "putting something aside": [
    "ad acta"
  ],
  "leaving it": [
    "ad acta"
  ],
  "as desired": [
    "ad libitum"
  ],
  "to eternity": [
    "ad aeternum"
  ],
  "year of the lord": [
    "anno domini",
    "anno domini (AD)"
  ],
  "in nature": [
    "in natura"
  ],
  "in a nut": [
    "in nuce"
  ],
  "it's allowed": [
    "licet"
  ],
  "something to be remembered": [
    "memorandum"
  ],
  "an object for remembrance": [
    "memorandum"
  ],
  "mode of living": [
    "modus vivendi"
  ],
  "way of living": [
    "modus vivendi"
  ],
  "nothing is in the way": [
    "nihil obstat"
  ],
  "not wanting-wantingly": [
    "nolens volens"
  ],
  "a must independent of own desires": [
    "nolens volens"
  ],
  "peace with you": [
    "pax vobiscum"
  ],
  "\"it likes\" okay": [
    "placet"
  ],
  "in agreement": [
    "placet"
  ],
  "for and against": [
    "pro et contra"
  ],
  "doubtful thing": [
    "res dubia"
  ],
  "truth is available to all": [
    "patet omnibus veritas"
  ],
  "my light": [
    "lux mea"
  ],
  "my sparrow": [
    "passerculus, passercula"
  ],
  "my flame": [
    "flamma, flamma mea",
    "flamma; flamma mea"
  ],
  "my fire": [
    "flamma, flamma mea",
    "flamma; flamma mea"
  ],
  "you should remember": [
    "memento"
  ],
  "where [there is] no accuser": [
    "ubi non accusator [est], ibi non iudex [est]"
  ],
  "there [is] no judge": [
    "ubi non accusator [est], ibi non iudex [est]"
  ],
  "i remain unvanquished": [
    "invictus maneo"
  ],
  "through struggle to the stars": [
    "per ardua ad astra"
  ],
  "goat": [
    "Caper",
    "Capra"
  ],
  "summons a frost atronach permanently": [
    "Vocat Atronachum Glaciei Sempiternum",
    "Atronachum glaciei sempiterniter vocat"
  ],
  "summons a storm atronach for <dur> seconds wherever the caster is pointing": [
    "Atronachum electricitatis <dur> secundas quo demononstras vocat",
    "Vocat Atronachum Tempestatis <dur> secundas ubi vocator demonstrat"
  ],
  "[...]": [
    "[...]"
  ],
  "summons a frost atronach for <dur> seconds wherever the caster is pointing": [
    "Atronachum glaciei <dur> secundas quo demononstras vocat",
    "Vocat Atronachum Glaciei <dur> secondas ubi vocator monstrat"
  ],
  "summons a flame atronach permanently": [
    "Vocat Atronachum Flammarum Sempiternum",
    "Atronachum flammarum sempiternite vocat"
  ],
  "summons a flame atronach for <dur> seconds wherever the caster is pointing": [
    "Atronachum flammarum <dur> secundas quo demononstras vocat",
    "Vocat Atronachum Flammarum <dur> secundas ubi vocator demonstrat"
  ],
  "conjure frost thrall": [
    "Voca Servum Glaciei"
  ],
  "conjure flame atronach": [
    "Voca Atronachum Flammarum"
  ],
  "conjure storm atronach": [
    "Voca Atronachum Tempestatis"
  ],
  "conjure frost atronach": [
    "Voca Atronachum Glaciei",
    "Vocat Atronachum Glaciei"
  ],
  "conjure flame thrall": [
    "Voca Servum Flammarum"
  ],
  "summons a storm atronach permanently": [
    "Vocat Atronachum Tempestatis Sempiternum",
    "Atronachum electricitatis sempiterniter vocat"
  ],
  "conjure storm thrall": [
    "Voca Servum Tempestatis",
    "Voca Dominum Electricitatis"
  ],
  "kinect, list of commands": [
    "KINECT, List of Commands"
  ],
  "kinect, using shouts": [
    "KINECT, Using Shouts"
  ],
  "your follower is too far to trade with": [
    "Secutor tuus nimis procul est abs te tradendi causa"
  ],
  "the kinect has failed to initialize": [
    "The Kinect has failed to initialise"
  ],
  "kinect has failed to initialize": [
    "The Kinect has failed to initialise"
  ],
  "ensure that your kinect is connected and updated": [
    "Ensure that your Kinect is connected and updated"
  ],
  "kinect commands have been disabled": [
    "Kinect commands have been disabled"
  ],
  "your kinect has not been calibrated": [
    "Your Kinect has not been calibrated"
  ],
  "voice commands have been disabled": [
    "Voice commands have been disabled"
  ],
  "please use the kinect tuner to calibrate your kinect's audio": [
    "Please use the Kinect Tuner to calibrate your Kinect's audio"
  ],
  "saddle": [
    "Saddle",
    "Clitellae"
  ],
  "bouncer": [
    "Dissultor"
  ],
  "ancient knowledge": [
    "Scientia Antiqua"
  ],
  "white phial effect": [
    "Effectum Atrophorios Albi"
  ],
  "legendary skills": [
    "Artes Legendariae"
  ],
  "are you sure you want to make this skill legendary": [
    "Esne certus te hanc artem Legendariam facere velle"
  ],
  "making a skill legendary will set it to 15 and return its perks": [
    "Artem legendariam facere diminuit hanc artem ad valorem 15 et redde beneficia"
  ],
  "are you sure": [
    "Visne pergere"
  ],
  "once a skill reaches 100, you can make it legendary. the skill is reset to 15, and all perks are converted to perk points. making a skill legendary allows you to level your character almost indefinitely": [
    "Arte ad valorem 100 adventa, eam Legendariam facere potes. Hic artem ad valorem 15 ponit, et omnia beneficia removet. Omnia puncta beneficiorum recuperas. Artem Legendariam facere te gradum personae tuae plus quam normale augere sinet"
  ],
  "blades boots": [
    "Ensium Caligae"
  ],
  "talk to the jarl of whiterun": [
    "Adloquere ad Comitem Albacursus"
  ],
  "banded iron armor": [
    "Ferro Adligata Lorica"
  ],
  "return the bust of the gray fox to delvin": [
    "Refer Statuam Vulpis Cani Delphino"
  ],
  "ebony dagger": [
    "Pugio Ebeni"
  ],
  "get access to hjerim": [
    "Intra Hjerim"
  ],
  "staff of magnus": [
    "Rudis Magni"
  ],
  "mzulft aedrome": [
    "Mzulft Aerodrome"
  ],
  "meet karliah at the ragged flagon": [
    "Conveni cum Karlia ad Poculum Fragosum"
  ],
  "learn the word of power from einarth": [
    "Disce Verbum Potestatis ab Einarth"
  ],
  "thalmor embassy": [
    "Thalmoris Embassy",
    "Visigothi Embassy"
  ],
  "you have a task for me": [
    "Habesne munus mihi"
  ],
  "forsworn gauntlets": [
    "Abiuratus Manicae"
  ],
  "morvunskar": [
    "Morvunskar"
  ],
  "khajiit": [
    "Khajitus"
  ],
  "frostmere crypt": [
    "Crypta Frostmeris"
  ],
  "can you train me to better use heavy armor": [
    "Doce me plus de lorica gravi, quaeso",
    "Potesne me Lorica Gravi melius uti docere"
  ],
  "penitus oculatus armor": [
    "Penitorum Oculatorum Lorica"
  ],
  "return <alias=questitem> to <alias.shortname=questgiver>": [
    "Refer <Alias=QuestItem> ad <Alias.ShortName=QuestGiver>"
  ],
  "regenerate magicka": [
    "Recupera Magicam"
  ],
  "i can do that": [
    "Id possum facere"
  ],
  "i'd like to make an investment in your business": [
    "Volo negotium tuum fenerare"
  ],
  "call storm": [
    "Voca Tempestatem"
  ],
  "honeyside": [
    "Honeyside",
    "Melilatus"
  ],
  "sunderstone gorge": [
    "Greywater Gorge",
    "Saxifracti Ancra"
  ],
  "smithing": [
    "Faciendi",
    "Ferrarii"
  ],
  "dark elf": [
    "Atralfus",
    "Dunmeris"
  ],
  "torso": [
    "Torso",
    "Thorax"
  ],
  "escape the thalmor embassy": [
    "Effuge Legationem Thalmorem",
    "Effuge Legationem Visigotham"
  ],
  "return the ship model to delvin": [
    "Redde Exemplar Navis ad Delvin"
  ],
  "breton": [
    "Britannus",
    "Brittanus"
  ],
  "falmer gauntlets": [
    "Falmeris Manicae",
    "Falmeria Manubria"
  ],
  "matching set": [
    "Lorica Par",
    "Lorica Concinans"
  ],
  "speak to karliah": [
    "Adloqeure Karliam",
    "Adloquere Karliam"
  ],
  "the way of the voice": [
    "Via Vocis"
  ],
  "way of the voice": [
    "Via Vocis"
  ],
  "retrieve the pommel of mehrunes' razor": [
    "Accipe lapidem capuli Novaculae Croni"
  ],
  "deflect arrows": [
    "Repelle Sagittas"
  ],
  "what is it you want me to do": [
    "Quid vis me facere"
  ],
  "retrieve the blade shards of mehrunes' razor": [
    "Accipe partes ensis Novaculae Croni"
  ],
  "light fingers": [
    "Digiti Levi"
  ],
  "limbsplitter": [
    "Artufractor",
    "Mebrifissor",
    "Membrafissor"
  ],
  "intimidate <alias=brute> in <alias=brutetown>": [
    "Intimida <Alias=Brute> in <Alias=BruteTown>"
  ],
  "high elf": [
    "Altalfus",
    "Atralfus",
    "Snow Elf"
  ],
  "guild master's hood": [
    "Magistri Sodalitatis Capucium"
  ],
  "water explosion": [
    "Water Explosion"
  ],
  "barkeep": [
    "Caupo"
  ],
  "guide meridia's light through the temple": [
    "Duc Lucem Cereris per Templum"
  ],
  "stormcloak officer boots": [
    "Sturmmantelorum Centurionis Caligae"
  ],
  "find karliah": [
    "Inveni Karliam"
  ],
  "guild master's boots": [
    "Magistri Sodalitatis Caligae"
  ],
  "rjorn's drum": [
    "Rjornis Tympanum",
    "Tympanum Rjornis"
  ],
  "defeat the wolf spirit": [
    "Supera Animum Lupi"
  ],
  "put witch head into fire": [
    "Pone caput sage in ignem"
  ],
  "speak to kodlak": [
    "Adloquere Kodlak"
  ],
  "go to ysgramor's tomb with the circle": [
    "Ii ad Tumulum Ysgramorisis cum Circulo"
  ],
  "meet the circle": [
    "Conveni cum Circulo"
  ],
  "give the final fragment to eorlund": [
    "Fer Fragmentum Ultimum Eorlundi"
  ],
  "retrieve kodlak's fragment": [
    "Refer Fragmentum Kodlakis"
  ],
  "attend kodlak's funeral": [
    "Ii ad Funus Kodlakis"
  ],
  "speak with saadia": [
    "Adloquere Saadiam"
  ],
  "lead saadia to the whiterun stables": [
    "Duc Saadiam ad Catabulum Albacursus"
  ],
  "return to saadia": [
    "Reveni ad Saadiam"
  ],
  "kill kematu": [
    "Neca Kematu"
  ],
  "kill kematu or convince saadia to meet kematu": [
    "Neca Kematu VEL Saadiae Persuade ut cum Kematu Conveniat"
  ],
  "enter the alik'r hideout": [
    "Intra Locum Secretum Alik'ris"
  ],
  "ebony blade": [
    "Enses Ebeni"
  ],
  "find a way to enter the alik'r hideout": [
    "Inveni Viam Secretam ad Cubile Alik'ris"
  ],
  "talk to the alik'r prisoner": [
    "Adloquere captivum Alikris"
  ],
  "inform the alik'r of saadia's location": [
    "Indica locum Saadiae Alik'ribis"
  ],
  "find the redguard woman": [
    "Inveni Feminam Rubricustodem"
  ],
  "db07astridcicerodeadyeschoice": [
    "DB07AstridCiceroDeadYesChoice"
  ],
  "the ragged flagon - cistern": [
    "Poculum Fragosum - Cisterna"
  ],
  "ragged flagon - cistern": [
    "Poculum Fragosum - Cisterna"
  ],
  "skyrim": [
    "Caelifinis"
  ],
  "return to brynjolf": [
    "Refer te ad Brynjolf"
  ],
  "(optional) smash bersi's prized dwarven urn": [
    "(Optio) Frange Urnam Dwemerem Bersi"
  ],
  "collect bersi honey-hand's debt": [
    "Collige Debitum Bersi Melli-Manus"
  ],
  "collect keerava's debt": [
    "Collige Debitum Keeravae"
  ],
  "locate brynjolf at the ragged flagon": [
    "Inveni Brynjolf ad Poculum Fragosum"
  ],
  "talk to <alias=authorityfigure>": [
    "Adloquere <Alias=AuthorityFigure>"
  ],
  "catch the murderer": [
    "Cape Necatorem"
  ],
  "patrol the streets of the stone quarter at night": [
    "Investiga Noctu Vias Areae Lapidis"
  ],
  "investigate hjerim with viola": [
    "Investiga Hjerim cum Violam"
  ],
  "meet viola outside of hjerim": [
    "Conveni cum Violam foris Hjerimis"
  ],
  "follow up on the clues from hjerim": [
    "Sequere Evidentiam in Hjerimi Inventam"
  ],
  "look for clues": [
    "Quaere Informata"
  ],
  "talk to helgird": [
    "Adloquere Helgird"
  ],
  "examine the crime scene": [
    "Investiga Scaenam Criminis"
  ],
  "report to the guard": [
    "Refer Evidentiam Custodibus"
  ],
  "question the witnesses": [
    "Interroga Testes"
  ],
  "get assistance from jorleif": [
    "Accipe auxilium e Jorleif"
  ],
  "return to maven black-briar": [
    "Reveni ad Maven Atri-Dumum"
  ],
  "return to sabjorn": [
    "Reveni ad Sabjornem"
  ],
  "poison the honningbrew vat": [
    "Pone Venenum in Dogam",
    "Pone Venenum in Dogam Honningbrevis"
  ],
  "poison the nest": [
    "Pone Venenum in Nidum"
  ],
  "speak to sabjorn": [
    "Adloquere Sabjornem",
    "Sabjornem Adloquere"
  ],
  "dampened spirits": [
    "Animi Madidi"
  ],
  "retrieve arondil's journals (<global=ffriften21jfound>/<global=ffriften21jtotal>)": [
    "Refer Ephemerides Arondilis (<Global=FFRiftenJFound>/<Global=FFRiften21JTotal>)",
    "Refer Ephemerides Arondilis (<Global=FFRiften21JFound>/<Global=FFRiften21JTotal>)"
  ],
  "enter snow veil sanctum": [
    "Intra Fanum Nivi Velatum",
    "Intra Sanctum Velatum Nivis"
  ],
  "meet mercer frey outside snow veil sanctum": [
    "Conveni Mercer Frey foris Fano Nivi Velato",
    "Conveni cum Mercer Frey foris Santo Velato Nivis"
  ],
  "speak to enthir": [
    "Adloquere Enthirem"
  ],
  "return to enthir": [
    "Refer te ad Enthirem"
  ],
  "duplicate the writing on calcelmo's stone": [
    "Duplica Scriptum in Lapide Calcelmonis"
  ],
  "obtain calcelmo's falmer translating guide": [
    "Inveni Rectorem Linguae Falmeris Calcelmonis"
  ],
  "gain entry to calcelmo's museum": [
    "Intra Museion Calcelmonis"
  ],
  "speak with enthir": [
    "Adloquere Enthirem"
  ],
  "speak to brynjolf": [
    "Adloquere Brynjolf",
    "Adloquere Brynjolfem"
  ],
  "(optional) speak to vex about vald": [
    "(Optio) Adloquere Vex de Vald"
  ],
  "(optional) shoot the mechanism to lower the ramp": [
    "(Optio) Sagitta mechanismum ut aggerem descendat"
  ],
  "follow karliah": [
    "Sequere Karliam"
  ],
  "the pursuit": [
    "Persecutio"
  ],
  "wabbajack": [
    "Vabbajack",
    "Wabbajack"
  ],
  "claim mehrunes' razor": [
    "Accipe Novaculam Croni"
  ],
  "speak to mehrunes dagon": [
    "Adloquere Cronum"
  ],
  "kill silus": [
    "Neca Silum"
  ],
  "meet silus at the shrine of mehrunes dagon": [
    "Adiunge cum Silo ad Aram Croni"
  ],
  "bring the blade shards to silus": [
    "Fer partes ensium ad Silum"
  ],
  "speak to silus inside his house": [
    "Silus in Villa Eius Adloquere"
  ],
  "pieces of the past": [
    "Partes Praeteriti"
  ],
  "return to <alias.shortname=questgiver>": [
    "Reveni ad <Alias.ShortName=Questgiver>",
    "Redi ad <Alias.ShortName=QuestGiver>"
  ],
  "hired muscle": [
    "Musculus Mercenarius"
  ],
  "return to falk firebeard": [
    "Redi ad Falconem Ignibarbam"
  ],
  "retrieve potema's remains": [
    "Refer Reliquias Potemae"
  ],
  "defeat potema": [
    "Potemam Vince"
  ],
  "fetch me that book": [
    "Fer mihi illum librum"
  ],
  "kill the hunters": [
    "Neca Venatores"
  ],
  "speak with hircine": [
    "Adloquere Hecaten"
  ],
  "skin sinding": [
    "Remove Pellem Sindingis"
  ],
  "kill sinding": [
    "Neca Sinding"
  ],
  "join the hunt": [
    "Adiunge venationem"
  ],
  "kill the great beast": [
    "Neca Bestiam Magnam"
  ],
  "ill met by moonlight": [
    "Dire Sub Luna Occursus"
  ],
  "new add-ons have been added. you will need to restart to use this content. all unsaved progress will be lost": [
    "Nova addita sunt. Incipe ludum iterum ut his uti possis"
  ],
  "geirmund's hall": [
    "Geirmund's Hall",
    "Atrium Geirmundis"
  ],
  "search morvunskar for sam and the staff": [
    "Quare Samuelem et Rudim in Morvunskar"
  ],
  "head to morvunskar": [
    "Pete Morvunskar"
  ],
  "return the wedding ring to ysolda in whiterun": [
    "Redde Anulum Nuptialem Ysoldae Albacursus"
  ],
  "talk to ysolda in whiterun about the staff": [
    "Adloquere Ysoldam Albacursus de rudi"
  ],
  "ask about sam and the staff in rorikstead": [
    "De Samuele et Rude Rorikidomi Roga"
  ],
  "apologize to the priestesses of dibella": [
    "Veniam Pete ab Flaminibus Idunnis",
    "Veniam Pete ab Flaminibus Dibellae"
  ],
  "help clean up the temple of dibella": [
    "Munda Templum Idunnis",
    "Munda Templum Dibellae"
  ],
  "find sam guevenne": [
    "Inveni Samuelem Guevenne"
  ],
  "speak to arngeir for further training": [
    "Arngeirem Adloquere ut Plus de Via Vocis Discas"
  ],
  "demonstrate your \"whirlwind sprint\" shout": [
    "Demonstra clamationem \"Cursus Turbinis\" tuam"
  ],
  "learn the word of power from borri": [
    "Disce Verbum Potestatis a Borri"
  ],
  "demonstrate your \"unrelenting force\" shout (<global=mq105targetshit>/<global=mq105targetstotal>)": [
    "Demonstra clamationem \"Vis Interminabilis\" tuam (<Global=MQ105TargetsHit>/<Global=MQ105TargetsTotal>)"
  ],
  "speak to arngeir": [
    "Arngeirem Adloquere"
  ],
  "demonstrate your \"unrelenting force\" shout": [
    "Demonstra clamationem \"Vis Interminabilis\" tuam"
  ],
  "speak to the greybeards": [
    "Canabarbas Adloquere"
  ],
  "find out how to defeat alduin": [
    "Inveni Debilitatem Alduini"
  ],
  "clear skies": [
    "Caelum Amoenum"
  ],
  "steal, thugs hunt player": [
    "Steal, Thugs hunt player"
  ],
  "talk to delphine": [
    "Adloquere Delphinam"
  ],
  "search the torture chamber": [
    "Investiga Conclave Tormentis"
  ],
  "search for information about the dragons returning": [
    "Quaere Informata de Draconibus Revenientibus"
  ],
  "(optional) retrieve your gear": [
    "(Optio) Recipe res tuas"
  ],
  "create a distraction and get away from the party": [
    "Fac Disctractionem ut Epulam Effugias"
  ],
  "give malborn the equipment": [
    "Da Impedimenta Malborni"
  ],
  "meet malborn in solitude": [
    "Adiunge Malborn Solitudini"
  ],
  "meet delphine in riverwood": [
    "Adiunge Delphinum Lignirivi"
  ],
  "diplomatic immunity": [
    "Immunitas Diplomatica"
  ],
  "master wi quest": [
    "Master WI quest"
  ],
  "talk to esbern": [
    "Adloquere Esbernem"
  ],
  "find esbern in the ratway warrens": [
    "Inveni Esbern Labyrintho Via Murum"
  ],
  "generic dialogue": [
    "Generic dialogue"
  ],
  "bash disarm": [
    "Bash Disarm"
  ],
  "fortify smithing": [
    "Adde ad Ferrarium"
  ],
  "read madanach's note": [
    "Lege Epistulam Madanachis"
  ],
  "search madanach's body": [
    "Corpus Madanachis Investiga"
  ],
  "kill madanach": [
    "Neca Madanachem"
  ],
  "kill grisvar the unlucky": [
    "Neca Grisvarem Infelicam",
    "Neca Grisvarem Infelicem"
  ],
  "return to madanach": [
    "Redi ad Madanachem"
  ],
  "talk to madanach": [
    "Madanachem Adloquere"
  ],
  "no one escapes cidhna mine": [
    "Nemo effugit e Metallo Cidnae",
    "Nemo effugit ab Metallo Cidnae"
  ],
  "bersi honey-hand": [
    "Bersi Melli-Manus"
  ],
  "interrogate odahviing": [
    "Interroga Odahviing"
  ],
  "call odahviing to dragonsreach": [
    "Voca Odahviing ad Draconicarcerem"
  ],
  "prepare trap for odahviing": [
    "Para Insidias Odahviingi"
  ],
  "learn shout to call odahviing": [
    "Disce Clamationem quae Odahviing Vocationem Discat"
  ],
  "talk to arngeir": [
    "Adloqere Arngeirem"
  ],
  "talk to paarthurnax": [
    "Adloquere Paarthurnacem"
  ],
  "negotiate a truce": [
    "Fac Foedus"
  ],
  "talk to ulfric stormcloak": [
    "Loquere ad Vercingetorigem Temepstatamiculum"
  ],
  "talk to general tullius": [
    "Adloquere ad Imperatori Tullio"
  ],
  "season unending": [
    "Tempus Interminans"
  ],
  "talk to the heroes of sovngarde": [
    "Heroes Valhallae Adloquere"
  ],
  "gain admittance to the hall of valor": [
    "Intra Aulam Valoris"
  ],
  "sovngarde": [
    "Valhalla"
  ],
  "bring aela her shield": [
    "Fer Aelae Scutum Eius"
  ],
  "train with vilkas": [
    "Disce ab Vilkas"
  ],
  "speak to kodlak whitemane": [
    "Adloquere ad Kodlak Jubacanam"
  ],
  "return the eye of the falmer to delvin": [
    "Redde Oculum Falmeris ad Delvin"
  ],
  "return the dwemer puzzle cube to delvin": [
    "Redde Cubum Aenigmatum Dwemere ad Delvin"
  ],
  "return the east empire shipping map to delvin": [
    "Redde Chartam Negotii Euri Imperii ad Delvin"
  ],
  "return the honningbrew decanter to delvin": [
    "Redde Obbam Honningbrevis ad Delvin"
  ],
  "return the queen bee statue to delvin": [
    "Redde Statuam Reginae Apium ad Delvin"
  ],
  "the litany of larceny": [
    "Litania Furti"
  ],
  "litany of larceny": [
    "Litania Furti"
  ],
  "speak to boethiah's conduit": [
    "Adloquere ad Animiductum Boethis",
    "Adloquere ad Animiductum Nemesis"
  ],
  "slay the jarl of solitude": [
    "Neca Comitem Solitudinis"
  ],
  "slay the other cultists": [
    "Alios secutores neca"
  ],
  "find the cult of boethiah": [
    "Inveni Secutores Boethis",
    "Inveni Secutores Nemesis"
  ],
  "find the shrine of boethiah": [
    "Inveni Aram Boethis",
    "Inveni Aram Nemesis"
  ],
  "boethiah's calling": [
    "Vocatio Boethis",
    "Vocatio Nemesis"
  ],
  "retrieve dawnbreaker": [
    "Accipe Azurafractor",
    "Accipe Aurorafractor"
  ],
  "destroy malkoran": [
    "Neca Malkoran"
  ],
  "the break of dawn": [
    "Aurora Fracta"
  ],
  "break of dawn": [
    "Aurora Fracta"
  ],
  "return to quintus navale": [
    "Reveni ad Quintum Navale"
  ],
  "find some mammoth tusk powder": [
    "Inveni Pulvum Dentis Elephantis"
  ],
  "retrieve the unmelting snow": [
    "Inveni Nivem Sempiternam"
  ],
  "hard answers": [
    "Responsa Dificilia"
  ],
  "shadowmere": [
    "Umbramera"
  ],
  "return to jorrvaskr": [
    "Reveni ad Jorrvaskr"
  ],
  "retrieve the fragment": [
    "Refer Fragmentum"
  ],
  "speak to <alias=observer>": [
    "Adloquere ad <Alias=Observer>"
  ],
  "speak to skjor": [
    "Adloquere ad Skjor"
  ],
  "proving honor": [
    "Honorem Adprobare"
  ],
  "follow <alias=questgiver>": [
    "Sequere <Alias=Questgiver>"
  ],
  "(optional) wipe out the silver hand": [
    "(Optio) Expugna Manum Argenteam"
  ],
  "retrieve the fragments of wuuthrad": [
    "Refer Fragmenta Wuuthradis"
  ],
  "purity of revenge": [
    "Candor Ultionis"
  ],
  "deliver moon sugar to ri'saad": [
    "Fer Saccharum Lunae ad Riaad"
  ],
  "escaped criminal": [
    "Damnatus Fugitivus"
  ],
  "deliver the soul gem to arniel": [
    "Redde Gemmanimam Arnieli"
  ],
  "heat the convector": [
    "Caleface Convectorem"
  ],
  "arniel's endeavor": [
    "Opus Arnielis"
  ],
  "clear out wolfskull cave": [
    "Vacua Cavam Calvarialupi"
  ],
  "ask falk firebeard about wolfskull cave": [
    "Roga Falconem Ignibarbam de Cava Calvarialupi"
  ],
  "return to vex": [
    "Reveni ad Vex"
  ],
  "recover the crown of barenziah": [
    "Inveni Coronam Barenziae"
  ],
  "speak to vex": [
    "Vex Adloquere"
  ],
  "recover the stones of barenziah (<global=tgcrowngemsfound>/<global=tgcrowngemstotal>)": [
    "Inveni Lapides Barenziae (<Global=TGCrownGemsFound>/<Global=TGCrownGemsTotal>)"
  ],
  "become a full member of the thieves guild": [
    "Esto pars verum Sodalitatis Furum"
  ],
  "bounty: dragon": [
    "Vectigal: Draco"
  ],
  "encounter combat dialogue": [
    "Encounter Combat Dialogue"
  ],
  "thieves guild hood": [
    "Sodalitatis Furum Capucium"
  ],
  "collect bounty from <alias=steward>": [
    "Accipe Praemium ab <Alias=Steward>"
  ],
  "kill the bandit leader located at <alias=bountylocation>": [
    "Neca Ducem in <Alias=BountyLocation>"
  ],
  "bounty: bandit boss": [
    "Vectigal: Dux Latronis"
  ],
  "yes, cicero is dead": [
    "Sic, Cicero mortuus est"
  ],
  "my reasons are my own": [
    "Argumenta mea sunt"
  ],
  "kill the leader of <alias=bountylocation>": [
    "Neca Ducem ad <Alias=BountyLocation>"
  ],
  "bounty: forsworn": [
    "Largitia: Abiuratus"
  ],
  "kill the giant located at <alias=bountylocation>": [
    "Neca Gigantem in <Alias=BountyLocation>"
  ],
  "bounty: giant": [
    "Vectigal: Gigas"
  ],
  "scroll of frost thrall": [
    "Codex Servus Glaciei"
  ],
  "bring a dragon scale and a dragon bone to esbern": [
    "Fer Squamam et Os Draconis Esberni"
  ],
  "onmund's request": [
    "Rogatum Onmundis"
  ],
  "find <alias=questitem> inside <alias=dungeon>": [
    "Inveni <Alias=QuestItem> in <Alias=Dungeon>"
  ],
  "enter yngvild barrow": [
    "Intra Pabillum Yngolis"
  ],
  "toying with the dead": [
    "Cum Mortuis Ludens"
  ],
  "thieves guild gloves": [
    "Sodalitatis Furum Manubria"
  ],
  "no stone unturned": [
    "Nullus Lapis Inversus"
  ],
  "thalmor vs player": [
    "Thalmoris vs Player",
    "Visigothi vs Player"
  ],
  "stormcloak officer helmet": [
    "Sturmmantelorum Centurionis Galea"
  ],
  "bounty hunters vs player": [
    "Bounty hunters vs Player"
  ],
  "thieves guild fence handler": [
    "Sodalitatis Furum Fence Handler"
  ],
  "return to tonilia": [
    "Redi ad Toniliam"
  ],
  "thieves guild caravan fence quest": [
    "Sodalitatis Furum Caravan Fence Quest"
  ],
  "find finn's lute": [
    "Inveni Citharam Finni"
  ],
  "return pantea's flute": [
    "Redde Tibiam Panteae"
  ],
  "pantea's flute": [
    "Tibia Panteae"
  ],
  "find rjorn's drum": [
    "Inveni Rjornis Tympanum"
  ],
  "take the totem to the underforge": [
    "Fer Totemnam ad Subfabricam"
  ],
  "totems of hircine": [
    "Totemna Hecatis"
  ],
  "dragon race": [
    "Dragon Race"
  ],
  "about that message from the general": [
    "De illo nuntio ab Imperatore"
  ],
  "repairing the phial": [
    "Atrophorion Reficiendi"
  ],
  "bleeding damage": [
    "Vulnus Desanguinationis"
  ],
  "give potema's remains to styrr": [
    "Da Reliquias Potemae ad Styrr"
  ],
  "oghma infinium": [
    "Oghma Infinium"
  ],
  "take your seat": [
    "Cape Sellam"
  ],
  "reeking cave": [
    "Odorativa Spelunca"
  ],
  "esbern's potion": [
    "Potio Esbernis"
  ],
  "japhet's folly towers": [
    "Turres Fatuitatis Japhetis"
  ],
  "shout - guard says stop": [
    "Shout - Guard says stop"
  ],
  "receive a reading from olava the feeble": [
    "Accipe Auraculum ab Olava Debili"
  ],
  "talk to brynjolf": [
    "Adloquere Brynjolf"
  ],
  "give vilkas's sword to eorlund": [
    "Da Gladium Vilkas Eorlundi"
  ],
  "left weapon rack": [
    "Armifer Laevus"
  ],
  "thieves guild boots": [
    "Sodalitatis Furum Caligae"
  ],
  "i submit": [
    "Me trado",
    "Me tibi trado",
    "Tibi succumbo"
  ],
  "take me to jail": [
    "Fer me ad carcerem"
  ],
  "whiterun": [
    "Albacursus",
    "Whiterun"
  ],
  "finn's lute": [
    "Cithara Finnis"
  ],
  "imperial": [
    "Romanus"
  ],
  "mathies": [
    "Mathies"
  ],
  "get past borkul the beast": [
    "Transi Borkul Bestiam"
  ],
  "japhet's folly": [
    "Fatuitas Japhetis"
  ],
  "the mind of a madman": [
    "Mens Insani",
    "The Mind Of A Madman"
  ],
  "mind of a madman": [
    "Mens Insani",
    "The Mind Of A Madman"
  ],
  "who is aventus": [
    "Quis est Aventus"
  ],
  "kill the dragon located at <alias=bountylocation>": [
    "Neca Draconem in <Alias=BountyLocation>"
  ],
  "come on, get the truth off your chest": [
    "Agedum, leva veritatem e pectore"
  ],
  "(persuade)": [
    "(Persuade)"
  ],
  "begin the hunt": [
    "Incipe Venationem"
  ],
  "giant stomp": [
    "Giant Stomp"
  ],
  "follow madanach": [
    "Sequere Madanachem"
  ],
  "about that message from the jarl": [
    "De illo nuntio ab Comite"
  ],
  "i have an important message from general tullius": [
    "Nuntium magni momenti habeo ab Imperatore Tullio"
  ],
  "tell me about maven black-briar": [
    "Dic mihi de Maven Atri-Dumi"
  ],
  "blades helmet": [
    "Ensium Galea"
  ],
  "i have the materials you requested": [
    "Materia rogata tibi habeo"
  ],
  "take your leave of <alias=victim>": [
    "Relinque <Alias=Victim>"
  ],
  "i recovered your flute": [
    "Tibiam retuli"
  ],
  "stormcloak officer bracers": [
    "Sturmmantelorum Centurionis Bracchialia"
  ],
  "ask a prisoner about madanach": [
    "Roga Captivum De Madanacho"
  ],
  "i have an important message for you": [
    "Nuntium magni momenti tibi habeo"
  ],
  "regenerate health": [
    "Recupera Salutem"
  ],
  "a cornered rat": [
    "Mus in Angulo"
  ],
  "cornered rat": [
    "Mus in Angulo"
  ],
  "louis letrush vs thug": [
    "Louis Letrush vs Thug"
  ],
  "deliver the staff to enthir": [
    "Fer Rudem Enthiri"
  ],
  "proudspire manor": [
    "Villa Superba Pinnae",
    "Villa Cochleae Superbae"
  ],
  "jorrvaskr": [
    "Jorrvaskr"
  ],
  "thalmor embassy, barracks": [
    "Thalmoris Legatio, Tabernacula",
    "Visigothi Legatio, Tabernacula"
  ],
  "retrieve the hilt of mehrunes' razor": [
    "Accipe capulum Novaculae Croni"
  ],
  "(optional) obtain the key to calcelmo's museum": [
    "(Optio) Inveni clavem ad Museion Calcelmonis"
  ],
  "breezehome": [
    "Zephyridomus",
    "Breezehome"
  ],
  "glad you're so happy": [
    "Gaudeo te tantum gaudere"
  ],
  "falmer armor": [
    "Falmeris Lorica"
  ],
  "discover evidence of mercer's location": [
    "Inveni Evidentiam de loco Merceris"
  ],
  "find gleda the goat": [
    "Inveni Gledam Capram"
  ],
  "rescue <alias=victim> from <alias=lair>": [
    "Serva <Alias=Victim> ab <Alias=Lair>"
  ],
  "bring the pommel stone to silus": [
    "Fer lapidem capuli ad Silum"
  ],
  "dead treasure hunter with instructions": [
    "Dead Treasure Hunter with instructions"
  ],
  "largashbur longhouse": [
    "Largashbur Domilongus"
  ],
  "bring gleda the goat back to ennis in rorikstead": [
    "Refer Capram Gledam Enni Rorikidomi"
  ],
  "effects\\fxemptyobject.nif": [
    "Effects\\ExplosionSplash.NIF"
  ],
  "thieves guild radiant shell": [
    "Sodalitatis Furum Radiant Shell"
  ],
  "studded imperial armor": [
    "Romana Lorica Ornata"
  ],
  "senna": [
    "Senna"
  ],
  "imperial helmet": [
    "Romana Galea"
  ],
  "speak to sinding": [
    "Adloquere Sinding"
  ],
  "regarding what, my lady": [
    "De qua, domina"
  ],
  "speaking with silence": [
    "Loquens Silentio",
    "Silentio Loquens"
  ],
  "find the wedding ring in witchmist grove": [
    "Inveni Anulum Nuptialem Nemore Saganebulae"
  ],
  "view available add-ons": [
    "Vide Electabilia"
  ],
  "argonian": [
    "Argonius"
  ],
  "blood on the ice": [
    "Sanguis in Glaciei"
  ],
  "dismaying shout": [
    "Dismaying Shout"
  ],
  "(optional) steal haelga's statue of dibella": [
    "(Optio) Furare Statuam Idunnis Haelgae",
    "(Optio) Furare Statuam Dibellae Haelgae"
  ],
  "speak to mallus maccius": [
    "Adloquere Mallum Maccium",
    "Mallum Maccium Adloquere"
  ],
  "give the fragments of wuuthrad to eorlund": [
    "Da Fragmenta Wuuthradis Eorlundi"
  ],
  "replace the beacon": [
    "Repone Signum"
  ],
  "speak with calcelmo": [
    "Adloquere Calcelmonem"
  ],
  "wood elf": [
    "Bosmer",
    "Bosmeris",
    "Boscalfus"
  ],
  "collect haelga's debt": [
    "Collige Debitum Haelgae"
  ],
  "bring meridia's beacon to mount kilkreath": [
    "Adfer Signum Cereris ad Montem Kilkreath"
  ],
  "penitus oculatus bracers": [
    "Penitorum Oculatorum Bracchialia"
  ],
  "take up arms": [
    "Sume Arma"
  ],
  "speak to styrr": [
    "Adloquere Styrr"
  ],
  "infiltrate mercer's house": [
    "Insinua in Domum Merceris"
  ],
  "ysolda": [
    "Ysolda"
  ],
  "find the staff": [
    "Inveni Rudem"
  ],
  "i would be honored to accept": [
    "Honor mihi erit accipere"
  ],
  "fortify magicka": [
    "Adde ad Magicam",
    "Auge Magicam"
  ],
  "karliah": [
    "Karlia"
  ],
  "here, now tell me the truth": [
    "Agedum, nunc dic mihi verum"
  ],
  "(<bribecost> gold)": [
    "(<BribeCost> auri)",
    "(<BribeCost> gold)"
  ],
  "weakness to sunlight": [
    "Debilitas Luci Solis",
    "Weakness to Sunlight"
  ],
  "bring the hilt to silus": [
    "Fer capulum ad Silum"
  ],
  "attend the tasting ceremony": [
    "Pete Caerimoniam Sapiendi"
  ],
  "redguard": [
    "Rubricustos"
  ],
  "nightcaller temple": [
    "Nightcaller Temple",
    "Templum Noctivocatoris"
  ],
  "on second thought, perhaps i'll invest another time": [
    "Ut iterum cogito, perhaps alio tempore pecuniam tibi dabo"
  ],
  "take the wedding ring": [
    "Cape Anulum Nuptialem"
  ],
  "louis letrush": [
    "Louis Letrush"
  ],
  "forsaken crypt": [
    "Abandonatum Hypogaeum"
  ],
  "i'll take it": [
    "Id capiam",
    "Id accipiam"
  ],
  "a night to remember": [
    "Nox Recordanda"
  ],
  "night to remember": [
    "Nox Recordanda"
  ],
  "blades armor": [
    "Ensium Lorica"
  ],
  "dialogueriftengoodbyes": [
    "DialogueHiatiGoodbyes",
    "DialogueRiftenGoodbyes"
  ],
  "(optional) use talen-jei to get to keerava": [
    "(Optio) Uti Talen-Jei ut Keeravam petas"
  ],
  "let's see what you can do with this": [
    "Videamus quid cum hoc facere possis"
  ],
  "(<global=investamount> gold)": [
    "(<Global=InvestAmount> auri)"
  ],
  "wait, you want me to go do this alone": [
    "Expecta, visne me hoc solum facere"
  ],
  "find the staff for enthir": [
    "Inveni Rudem pro Enthire"
  ],
  "and now that potema has returned": [
    "Et nunc, Potema reventa"
  ],
  "falk sent me to talk to you about potema": [
    "Falco mihi misit ut tecum de Potema adloquerer"
  ],
  "stormcloak officer armor": [
    "Sturmmantelorum Centurionis Lorica"
  ],
  "i have this token for you": [
    "Hoc signum tibi habeo"
  ],
  "i was told you could see my future": [
    "Audivi te futurum meum videre posse"
  ],
  "is there anything else": [
    "Estne quid aliud",
    "Estne quid alium"
  ],
  "return to vekel": [
    "Redde ad Vekel"
  ],
  "find meridia's beacon": [
    "Inveni Signum Cereris"
  ],
  "hjerim": [
    "Hjerim"
  ],
  "escape cidhna mine": [
    "Effuge e Metallo Cidhnae"
  ],
  "folgunthur crypt": [
    "Folgunthur Hypogaeum"
  ],
  "the man who cried wolf": [
    "Vir Qui Lupum Clamavit"
  ],
  "man who cried wolf": [
    "Vir Qui Lupum Clamavit"
  ],
  "place the warped soul gem into a convector": [
    "Pone Gemmanimam Flexam in Convectorem"
  ],
  "slay everyone at <alias=dungeon> stealthily": [
    "Neca Omnes in <Alias=Dungeon> clam"
  ],
  "about that message from ulfric": [
    "De illo nuntio de Vercingetorigi"
  ],
  "escort <alias=victim> back to <alias=victimhome>": [
    "Refer <Alias=Victim> ad <Alias=VictimHome>"
  ],
  "fortify health": [
    "Auge Salutem",
    "Adde ad Salutem"
  ],
  "kill the escaped criminal": [
    "Neca Fugitivum Damnatum"
  ],
  "guild master's armor": [
    "Magistri Sodalitatis Lorica"
  ],
  "thieves guild armor": [
    "Sodalitatis Furum Lorica"
  ],
  "whiterunolavareadingtopic1": [
    "WhiterunOlavaReadingTopic1"
  ],
  "identify sabjorn's silent partner": [
    "Disce Nomen Socii Silentis Sabjornis"
  ],
  "falmer boots": [
    "Falmeres Caligae",
    "Falmeris Caligae"
  ],
  "palace of the kings": [
    "Aula Regum",
    "Palace of the Kings"
  ],
  "retrieve and equip the ebony mail": [
    "Accipe et indue Ebeni Loricam"
  ],
  "penitus oculatus boots": [
    "Penitorum Oculatorum Caligae"
  ],
  "palace of the kings upstairs": [
    "Aula Regum Superior",
    "Aula Regum Pars Superior"
  ],
  "nord": [
    "Germanus",
    "Nord"
  ],
  "i'd like training in alchemy": [
    "Volo de Alchemia discere",
    "Doce mihi de Alchemia, quaeso"
  ],
  "the wolf queen awakened": [
    "Regina Luporum Suscitata"
  ],
  "wolf queen awakened": [
    "Regina Luporum Suscitata"
  ],
  "hear braig's story": [
    "Fabulam Braigis Audi"
  ],
  "scroll of storm thrall": [
    "Codex Servi Tempestatis"
  ],
  "talk to sinding": [
    "Adloquere Sinding"
  ],
  "lisbet": [
    "Lisbet"
  ],
  "orc": [
    "Orcus",
    "Orsimer"
  ],
  "take a forsworn heart": [
    "Cape Cor Abiurati"
  ],
  "blade of sacrifice": [
    "Enses Sacrificii"
  ],
  "is there anything i can help riften with": [
    "Estne quid cum quo Hiatum adiuvare possim"
  ],
  "get greybeards' help in negotiating a truce": [
    "Accipe auxilium Canabarbarum in foedus agendo"
  ],
  "windhelm": [
    "Ventigalea",
    "Windhelm"
  ],
  "kill kematu or talk to him": [
    "Neca Kematu Vel eum Adloquere"
  ],
  "taking care of business": [
    "Negotium Agens"
  ],
  "summon ghost": [
    "Summon Ghost"
  ],
  "defeat and trap odahviing": [
    "Supera et Cape Odahviing"
  ],
  "paralyze": [
    "Paralyticum"
  ],
  "follow farkas to your quarters": [
    "Sequere Farkas ad Cubicula"
  ],
  "talen-jei": [
    "Talen-Jei"
  ],
  "falmer shield": [
    "Falmeris Scutum"
  ],
  "in order to use the add-ons associated with this profile, you will need to restart": [
    "In order to use downloaded content associated with this profile, you will need to restart"
  ],
  "i have slain <alias=deadfriend> to gain your favor": [
    "Necavi <Alias=DeadFriend> ut favorem tuam accipiam"
  ],
  "find the copy of <alias=book>": [
    "Inveni Exemplar <Alias=Book>"
  ],
  "the game must restart now because add-on content has been removed": [
    "Ludum reincipere debes quod res ex rete repromptae emotae sunt"
  ],
  "game must restart now because add-on content has been removed": [
    "Ludum reincipere debes quod res ex rete repromptae emotae sunt"
  ],
  "collect the bounty from <alias=steward>": [
    "Accipe praemium ab <Alias=Steward>"
  ],
  "talk to <alias=victim>": [
    "Adloquere <Alias=Victim>"
  ],
  "talk to malborn": [
    "Adloquere Malborn"
  ],
  "retrieve the soul gem": [
    "Cape Gemmanimam"
  ],
  "pit wolf": [
    "Lupus Amphitheatri"
  ],
  "blades shield": [
    "Ensium Scutum"
  ],
  "forsworn headdress": [
    "Abiuratus Corona"
  ],
  "fortify alchemy": [
    "Adde ad Alchemiam"
  ],
  "revenge, hired thugs": [
    "Revenge, Hired Thugs"
  ],
  "speak to <alias=questgiver>": [
    "Adloquere ad <Alias=Questgiver>"
  ],
  "dead men's respite": [
    "Dead Menis Respite",
    "Intervallum Mortuorum"
  ],
  "dragon research": [
    "Investigatio Draconum"
  ],
  "give finn's lute to inge": [
    "Da Citharam Finnis Ingi"
  ],
  "elven gilded armor": [
    "Alfa Lorica Aurata"
  ],
  "deliver the amulet to onmund": [
    "Redde Monile Onmundi"
  ],
  "return the book to urag gro-shub": [
    "Refer Librum ad Urag gro-Shub"
  ],
  "penitus oculatus helmet": [
    "Penitorum Oculatorum Galea"
  ],
  "staff of magnus effect": [
    "Rudis Magnus Effect"
  ],
  "jarl ulfric stormcloak asked me to deliver this axe to you": [
    "Comes Vercingetorix Temepstatamiculus mihi imperavit ut hanc tibi adferrem"
  ],
  "meet delphine at the stables": [
    "Adiunge Delphinam ad Stabulum"
  ],
  "blades gauntlets": [
    "Ensium Manicae"
  ],
  "right weapon rack": [
    "Armifer Rectus"
  ],
  "reforge mehrunes' razor": [
    "Refice Novaculam Croni"
  ],
  "ennis": [
    "Ennis"
  ],
  "speak to falk firebeard": [
    "Falconem Ignibarbam Adloquere"
  ],
  "headsman's axe": [
    "Securis Carnificis"
  ],
  "thalmor embassy, elenwen's solar": [
    "Thalmoris Legatio, Elenvenis Solar",
    "Visigothi Legatio, Elenvenis Solar"
  ],
  "call of the wild": [
    "Call of Wild",
    "Vocatio Ferarum"
  ],
  "dungeon delving (jarl - caves)": [
    "Oubliettes Explorans (Jarl - Caves)"
  ],
  "glory of the dead": [
    "Gloria Mortuorum"
  ],
  "guild master's gloves": [
    "Magistri Sodalitatis Manubria"
  ],
  "hall of attainment": [
    "Atrium Discendi"
  ],
  "twilight sepulcher": [
    "Twilight Sepulcher",
    "Sepulchrum Crepusculum"
  ],
  "vald": [
    "Vald"
  ],
  "the fallen": [
    "Cadentes"
  ],
  "fallen": [
    "Cadentes"
  ],
  "regenerate stamina": [
    "Recupera Staminam",
    "Staminam Recupera",
    "Recuperatio Staminae"
  ],
  "scroll of flame thrall": [
    "Codex Servi Flammarum"
  ],
  "the ragged flagon": [
    "Poculum Fragosum",
    "The Ragged Flagon"
  ],
  "ragged flagon": [
    "Poculum Fragosum",
    "The Ragged Flagon"
  ],
  "fortify stamina": [
    "Auge Staminam",
    "Adde ad Staminam"
  ],
  "give rjorn's drum to giraud": [
    "Da Tympanum Rjornis ad Giraud"
  ],
  "you look young for a blacksmith": [
    "Iuvenis pro ferrario videris"
  ],
  "quick reflexes": [
    "Rapide Responde"
  ],
  "bring the unusual gem to vex in the thieves guild": [
    "Fer Gemmam Mirabilem ad Vex in Sodalitate Furum"
  ],
  "thieves guild dialogue": [
    "Sodalitatis Furum Dialogue"
  ],
  "forsworn armor": [
    "Abiuratus Lorica"
  ],
  "skill boosts": [
    "Augenda Artium"
  ],
  "speak to maven black-briar": [
    "Adloquere Maven Atri-Dumum",
    "Maven Atri-Dumum Adloquere"
  ],
  "speak to quintus navale": [
    "Adloquere Quintum Navale"
  ],
  "i've purchased honeyside": [
    "Melilatum emi"
  ],
  "in my time of need": [
    "Tempore Egestatis"
  ],
  "volunruud": [
    "Volunruud"
  ],
  "find pantea's flute": [
    "Inveni Tibiam Panteae"
  ],
  "falmer helmet": [
    "Falmeris Galea"
  ],
  "lead someone to become trapped by the shrine and slay them": [
    "Duc aliquem ut ab columna capiatur et eum neca"
  ],
  "rescue mission": [
    "Munus Servandi"
  ],
  "forsworn boots": [
    "Abiuratus Caligae"
  ],
  "search the ratway for esbern's hideout": [
    "Investiga Viam Murum ut cubile Esbernis invenias"
  ],
  "retrieve the totem of hircine": [
    "Refer Totemnum Hecatis"
  ],
  "get to the burial chamber": [
    "Ii ad Conclave Sepulchri"
  ],
  "return wuuthrad to ysgramor": [
    "Refer Wuuthrad Ysgramorisi"
  ],
  "gauntlets of the old gods": [
    "Manicae Deorum Antiquorum"
  ],
  "louis": [
    "Louis"
  ],
  "helmet of the old gods": [
    "Galea Deorum Antiquorum"
  ],
  "armor of the old gods": [
    "Lorica Deorum Antiquorum"
  ],
  "boots of the old gods": [
    "Caligae Deorum Antiquorum"
  ],
  "cannibalism": [
    "Cannibalismum"
  ],
  "collect bounty from <alias=jarl>": [
    "Accipe Praemium ab <Alias=Jarl>"
  ],
  "collect the bounty from <alias=jarl>": [
    "Accipe Praemium ab <Alias=Jarl>"
  ],
  "clean up that murderer's mess": [
    "Munda scruta illius homicidae"
  ],
  "(<global=hdwindhelmkiller> gold)": [
    "(<Global=HDWindhelmKiller> auri)",
    "(<Global=HDVentigaleaKiller> auri)"
  ],
  "follow vilkas": [
    "Sequere Vilkas"
  ],
  "the white phial (full)": [
    "Atrophorion Album (Plenum)"
  ],
  "white phial (full)": [
    "Atrophorion Album (Plenum)"
  ],
  "<alias=animal> is waiting for you": [
    "<Alias=Animal> te exspectat"
  ],
  "<alias=follower> is waiting for you": [
    "<Alias=Follower> te exspectat"
  ],
  "falkreath jail": [
    "Falkreath Jail",
    "Carcer Falkretiae"
  ],
  "snakeblood": [
    "Serpentisanguinis"
  ],
  "dark brotherhood assassin sent to kill the player": [
    "Fraternitas Obscura Assassin Sent to Kill Player"
  ],
  "warmaster knockdown": [
    "Warmaster Knockdown"
  ],
  "courier delivering an important message": [
    "Courier Delivering Important Message"
  ],
  "place the warpred soul gem into a final convector": [
    "Pone Gemmanimam Flexam in Ultimam Convectorem"
  ],
  "place the warped soul gem into another convector": [
    "Pone Gemmanimam Flexam in Aliam Convectorem"
  ],
  "there are those who believe that when night descends upon skyrim, werewolves descend upon the unwary": [
    "Sunt qui credunt post noctem in Caelifinem descendere, versipelles improvidis vesci"
  ],
  "those with the blood of a werewolf can never gain resting bonuses from sleeping": [
    "Qui sanguinem versipellis habent numquam somno fruuntur",
    "Qui sanguinem versipellis habent numquam somnio fruuntur"
  ],
  "elven boots of eminent stamina": [
    "Caligae Alfae Maximae Staminae"
  ],
  "increases your health by <mag> points": [
    "Salus tua <mag> punctis augetur"
  ],
  "magicka regenerates <mag>% faster": [
    "Magica recuperat <mag>% rapidius",
    "Magicka <mag>% celerius recuperat",
    "Magica <mag>% celerius recuperator"
  ],
  "stamina regenerates <mag>% faster": [
    "Stamina <mag>% celerius recuperat",
    "Stamina recuperator <mag>% celerius"
  ],
  "health regenerates <mag>% faster": [
    "Salus <mag>% celerius recuperat"
  ],
  "weapons and armor can be improved <mag>% better": [
    "Arma loricaeque augentur <mag>% melius"
  ],
  "increases your magicka by <mag> points": [
    "Magica tua <mag> punctis augetur"
  ],
  "created potions are <mag>% more powerful": [
    "Potiones <mag>% potentiores sunt"
  ],
  "increases your stamina by <mag> points": [
    "Stamina tua <mag> punctis augetur"
  ],
  "grants you the dragon infusion perk": [
    "Tibi Infusionem Draconis dat"
  ],
  "absorb <mag> points of health per second from the target": [
    "Absorbe <mag> salutis singulis secundis ab inimico"
  ],
  "a blast from wabbajack with an unpredictable effect": [
    "Displosio Wabbajack - effectum ignotum"
  ],
  "blast from wabbajack with an unpredictable effect": [
    "Displosio Wabbajack - effectum ignotum"
  ],
  "character menu is not available": [
    "Character menu not available to werewolves"
  ],
  "absorb <mag> points of magicka per second from the target": [
    "Absorbe <mag> magicae singulis secundis ab inimico",
    "Absorbe <mag> magickae singulis secundis ab inimico"
  ],
  "materialwaterpuddle": [
    "MaterialWaterPuddle"
  ],
  "summons a dremora for <dur> seconds": [
    "Dremora <dur> secundas vocat"
  ],
  "summon dremora": [
    "Voca Dremoram"
  ],
  "calls a storm for <dur> seconds with lightning that strikes friend and foe alike": [
    "Tempestatem vocat <dur> secundas fulmen cuius et amicos et inimicos icit"
  ],
  "remove all furniture from entryway and remodel it to match the main hall": [
    "Remove all furniture from entryway and remodel it to match the Atrium"
  ],
  "greenhouse, roof": [
    "Greenhouse, Roof"
  ],
  "main hall b": [
    "Atrium B"
  ],
  "remodel entryway - a": [
    "Remodel Entryway - A"
  ],
  "kitchen, doors": [
    "Kitchen, Doors"
  ],
  "goat horns": [
    "Cornua Capris"
  ],
  "main hall, door": [
    "Atrium, Door"
  ],
  "potato soup": [
    "Ius Malae Terrae"
  ],
  "addition - east wing - kitchen": [
    "Addition - East Wing - Kitchen"
  ],
  "alchemy laboratory, floor": [
    "Alchemy Laboratory, Floor"
  ],
  "main hall, foundation": [
    "Atrium, Foundation"
  ],
  "enchanter's tower, roof": [
    "Enchanter's Tower, Roof"
  ],
  "trophy room, doors": [
    "Trophy Room, Doors"
  ],
  "enchanter's tower, floor": [
    "Enchanter's Tower, Floor"
  ],
  "addition - west wing - greenhouse": [
    "Addition - West Wing - Greenhouse"
  ],
  "apple dumpling": [
    "Adipatum Mali"
  ],
  "storage room, wall supports": [
    "Storage Room, Wall Supports"
  ],
  "small house layout": [
    "Small House Layout"
  ],
  "main hall, wall framing": [
    "Atrium, Wall Framing"
  ],
  "alchemy laboratory, second floor walls": [
    "Alchemy Laboratory, Second Floor Walls"
  ],
  "addition - north wing - trophy room": [
    "Addition - North Wing - Trophy Room"
  ],
  "lakeview manor": [
    "Villa Lacivisus"
  ],
  "alchemy laboratory, doors": [
    "Alchemy Laboratory, Doors"
  ],
  "remodel house into entryway": [
    "Remodel House into Entryway"
  ],
  "main hall, first level walls": [
    "Atrium, First Level Walls"
  ],
  "bedrooms, wall supports": [
    "Bedrooms, Wall Supports"
  ],
  "main hall, second level walls": [
    "Atrium, Second Level Walls"
  ],
  "dummy object": [
    "Dummy Object"
  ],
  "what did you need": [
    "Quid voluisti"
  ],
  "i'm a bit short of money right now": [
    "Pecunia careo"
  ],
  "(<global=byohhpcost> gold)": [
    "(<Global=BYOHHPCost> Auri)"
  ],
  "butter": [
    "Buturum"
  ],
  "addition - north wing - alchemy laboratory": [
    "Addition - North Wing - Alchemy Laboratory"
  ],
  "addition - east wing - library": [
    "Addition - East Wing - Library"
  ],
  "trophy room, floor": [
    "Trophy Room, Floor"
  ],
  "(<global=byohhpcostcarriage> gold)": [
    "(<Global=BYOHHPCostCarriage> Auri)"
  ],
  "entryway - remove workbench": [
    "Ingressus - Remove Subsellium Laboris"
  ],
  "i'll have to think about it": [
    "De eo cogitare debeo",
    "Opus est mihi de hac re cogitare"
  ],
  "main hall - style c": [
    "Atrium - Style C"
  ],
  "addition - west wing - enchanter's tower": [
    "Addition - West Wing - Enchanter's Tower"
  ],
  "storage room, foundation": [
    "Storage Room, Foundation"
  ],
  "trophy room, roof": [
    "Trophy Room, Roof"
  ],
  "greenhouse, fireplace": [
    "Greenhouse, Fireplace"
  ],
  "greenhouse, foundation": [
    "Greenhouse, Foundation"
  ],
  "quarried stone": [
    "Quarried Stone"
  ],
  "bedrooms, roof": [
    "Bedrooms, Roof"
  ],
  "(<global=byohhpcostcow> gold)": [
    "(<Global=BYOHHPCostCow> Auri)"
  ],
  "main hall layout c": [
    "Atrium Layout C"
  ],
  "main hall - remove workbenches": [
    "Atrium - Remove Workbenches"
  ],
  "visit your new property in <alias=hold> hold": [
    "Visita domum novum in <Alias=Hold>"
  ],
  "i'll take <global=byohhpamountlogs> logs (<global=byohhpcostlogs> gold)": [
    "Da mihi <Global=BYOHHPAmountLogs> trabes (<Global=BYOHHPCostLogs> Auri)"
  ],
  "salmon roe": [
    "Ova Salmonis"
  ],
  "greenhouse, floor": [
    "Greenhouse, Floor"
  ],
  "house, roof framing": [
    "House, Roof Framing"
  ],
  "potato bread": [
    "Panis Malae Terrae"
  ],
  "house, wall framing": [
    "House, Wall Framing"
  ],
  "addition - north wing - storage room": [
    "Addition - North Wing - Storage Room"
  ],
  "carpenter's workbench": [
    "Subsellium Dendrofori"
  ],
  "main hall, roof framing": [
    "Atrium, Roof Framing"
  ],
  "addition - west wing - bedrooms": [
    "Addition - West Wing - Bedrooms"
  ],
  "surilie brothers wine": [
    "Vinum Fratrum Suriliorum"
  ],
  "sawn log": [
    "Sawn Log"
  ],
  "never mind": [
    "Ignosce",
    "Ignosce mihi"
  ],
  "main hall, floor": [
    "Atrium, Floor"
  ],
  "main hall, second floor supports": [
    "Atrium, Second Floor Supports"
  ],
  "mudcrab legs": [
    "Crura Luticanceris"
  ],
  "alchemy laboratory, second floor supports": [
    "Alchemy Laboratory, Second Floor Supports"
  ],
  "main hall - stone": [
    "Atrium - Stone"
  ],
  "main hall, roof": [
    "Atrium, Roof"
  ],
  "salmon steak": [
    "Esiciae Caro",
    "Carnis Esiciae"
  ],
  "alchemy laboratory, roof": [
    "Alchemy Laboratory, Roof"
  ],
  "main hall c": [
    "Atrium C"
  ],
  "addition - east wing - armory": [
    "Addition - East Wing - Armoury"
  ],
  "can i just cut the lumber myself": [
    "Nonne pro me trabes secare possum"
  ],
  "single bed": [
    "Lectus Unius"
  ],
  "i'd like to buy some lumber": [
    "Lignum emere volo"
  ],
  "enchanter's tower, first floor walls": [
    "Enchanter's Tower, First Floor Walls"
  ],
  "is that land you mentioned still available": [
    "Estne terra quam commemoravisti iam aperta"
  ],
  "storage room, roof": [
    "Storage Room, Roof"
  ],
  "i got your letter about the land for sale": [
    "Epistulam tuam accepi"
  ],
  "is it still available": [
    "Estne terra quam commemoravisti iam aperta"
  ],
  "storage room, floor": [
    "Storage Room, Floor"
  ],
  "i'd like to purchase a house": [
    "Domum emere volo"
  ],
  "nails": [
    "Clavi"
  ],
  "main hall a": [
    "Atrium A"
  ],
  "workbench": [
    "Subsellium Laboris"
  ],
  "i'd be honored to have you as my steward": [
    "Gaudebo te sicut dispensatorem habere"
  ],
  "enchanter's tower, second floor walls": [
    "Enchanter's Tower, Second Floor Walls"
  ],
  "i'd like you to make some improvements to the house": [
    "Auge domum meam, quaeso"
  ],
  "jazbay crostata": [
    "Crostata Uvarum Jazbae"
  ],
  "a large addition in the riften style": [
    "Additum magnum modo Hiati"
  ],
  "large addition in the riften style": [
    "Additum magnum modo Hiati"
  ],
  "greenhouse, roof framing": [
    "Greenhouse, Roof Framing"
  ],
  "trophy room, foundation": [
    "Trophy Room, Foundation"
  ],
  "house, roof": [
    "House, Roof"
  ],
  "alchemy laboratory, first floor walls": [
    "Alchemy Laboratory, First Floor Walls"
  ],
  "main hall layout a": [
    "Atrium Layout A"
  ],
  "steamed mudcrab legs": [
    "Crura Luticanceris Fumigata"
  ],
  "argonian bloodwine": [
    "Sanguivinum Argonium"
  ],
  "sack of flour": [
    "Saccus Farinae"
  ],
  "snowberry crostata": [
    "Crostata Nivibaccae"
  ],
  "clam chowder": [
    "Ius Peloris"
  ],
  "enchanter's tower, doors": [
    "Enchanter's Tower, Doors"
  ],
  "trophy room, wall supports": [
    "Trophy Room, Wall Supports"
  ],
  "main hall": [
    "Atrium"
  ],
  "trophy room, walls": [
    "Trophy Room, Walls"
  ],
  "storage room, doors": [
    "Storage Room, Doors"
  ],
  "enchanter's tower, foundation": [
    "Enchanter's Tower, Foundation"
  ],
  "greenhouse, doors": [
    "Greenhouse, Doors"
  ],
  "a large addition in the whiterun style": [
    "Additum magnum modo Hiati Albacursus"
  ],
  "large addition in the whiterun style": [
    "Additum magnum modo Hiati Albacursus"
  ],
  "library, doors": [
    "Library, Doors"
  ],
  "bedrooms, floor": [
    "Bedrooms, Floor"
  ],
  "armory, doors": [
    "Armoury, Doors"
  ],
  "enchanter's tower, second floor supports": [
    "Enchanter's Tower, Second Floor Supports"
  ],
  "i need a steward for my house. are you interested": [
    "Gaudebo te sicut dispensatorem habere"
  ],
  "house, walls": [
    "House, Walls"
  ],
  "letter from the steward of <alias=holdcity>": [
    "Epistula e Dispenatore <Alias=HoldCity>"
  ],
  "house, foundation": [
    "House, Foundation"
  ],
  "main hall - workbenches": [
    "Atrium - Workbenches"
  ],
  "bedrooms, doors": [
    "Bedrooms, Doors"
  ],
  "greenhouse, walls": [
    "Greenhouse, Walls"
  ],
  "a large addition in the solitude style": [
    "Additum magnum modo Solitudinis"
  ],
  "large addition in the solitude style": [
    "Additum magnum modo Solitudinis"
  ],
  "juniper berry crostata": [
    "Crostata Baccarum Juniperorum"
  ],
  "trophy room, roof framing": [
    "Trophy Room, Roof Framing"
  ],
  "main hall layout b": [
    "Atrium Layout B"
  ],
  "a tidy, one-room house": [
    "Domum unius conclavis"
  ],
  "tidy, one-room house": [
    "Domum unius conclavis"
  ],
  "enchanter's tower, main supports": [
    "Enchanter's Tower, Main Supports"
  ],
  "alchemy laboratory, main supports": [
    "Alchemy Laboratory, Main Supports"
  ],
  "greenhouse, wall supports": [
    "Greenhouse, Wall Supports"
  ],
  "jug of milk": [
    "Guttus Lactis"
  ],
  "entryway - workbench": [
    "Ingressus - Subsellium Laboris"
  ],
  "house, door": [
    "House, Door"
  ],
  "gunjar": [
    "Gunjar"
  ],
  "house - workbench": [
    "House - Workbench"
  ],
  "house, floor": [
    "House, Floor"
  ],
  "storage room, walls": [
    "Storage Room, Walls"
  ],
  "clay deposit": [
    "Depositum Cretae"
  ],
  "bedrooms, foundation": [
    "Bedrooms, Foundation"
  ],
  "house - remove workbench": [
    "House - Remove Workbench"
  ],
  "alchemy laboratory, foundation": [
    "Alchemy Laboratory, Foundation"
  ],
  "iron fittings": [
    "Iron Fittings"
  ],
  "bedrooms, walls": [
    "Bedrooms, Walls"
  ],
  "weapon racks (3)": [
    "Weapon Racks (3)"
  ],
  "girl's grey dress": [
    "Puellae Cana Stola"
  ],
  "i said it was": [
    "Ut dixi"
  ],
  "armory": [
    "Armifer",
    "Armarium",
    "Conclave Armorum"
  ],
  "(<global=byohhpcostarmory> gold)": [
    "(<Global=BYOHHPCostArmory> Auri)"
  ],
  "display case on low table": [
    "Loculamentum in Mensa Parva"
  ],
  "rayya": [
    "Rayya"
  ],
  "display case and small wardrobe": [
    "Loculamentum et Armarium Parvum"
  ],
  "small shelf": [
    "Small Shelf",
    "Armarium Parvum"
  ],
  "use the drafting table to begin building your house": [
    "Use the Drafting Table to begin building your house"
  ],
  "armory, floor": [
    "Armoury, Floor"
  ],
  "nord mead (5)": [
    "Hydromellum Germanicum (5)"
  ],
  "grindstone": [
    "Acutator",
    "Molinula"
  ],
  "cupboard": [
    "Armarium",
    "Caliclarium",
    "armārium, armāriī"
  ],
  "lamp stand": [
    "Lamp Stand"
  ],
  "bedrooms - workbench": [
    "Bedrooms - Workbench"
  ],
  "stop pestering me": [
    "Noli me vexare"
  ],
  "shrine of talos": [
    "Ara Alexandros",
    "Shrine of Talos"
  ],
  "corner shelf": [
    "Bancus Quadratus"
  ],
  "room 2 workbench": [
    "Room 2 Workbench"
  ],
  "cellar": [
    "Cellarium"
  ],
  "end table": [
    "Mensa Lectilata",
    "Mensa ad Latus Lecti"
  ],
  "what's the hurry": [
    "Cur festinas"
  ],
  "display case": [
    "Loculamentum",
    "Loculamentum Exhibitionis"
  ],
  "time to go home, <alias.shortname=child1>": [
    "Tempus aptum est ad domum revenienum, <Alias.ShortName=Child1>"
  ],
  "library, second floor supports": [
    "Library, Second Floor Supports"
  ],
  "room 7 layout": [
    "Room 7 Layout"
  ],
  "room 1 workbench disable": [
    "Room 1 Workbench Disable"
  ],
  "trophy room - workbench": [
    "Trophy Room - Workbench"
  ],
  "hawk's egg": [
    "Ovum Accipitris"
  ],
  "wall sconce": [
    "Wall Sconce"
  ],
  "mounted horker head": [
    "Mounted Horker Head"
  ],
  "adopted": [
    "Adopted"
  ],
  "how's <alias.shortname=child1> doing": [
    "Quid agitur cum <Alias.ShortName=Child1>"
  ],
  "library, floor": [
    "Library, Floor"
  ],
  "small planter with cupboard": [
    "Small Planter with Cupboard"
  ],
  "bookshelf": [
    "Pegma"
  ],
  "child's clothes": [
    "Vestimenta Pueri",
    "Vestimenta Puerorum",
    "Skaal Child Vestimenta"
  ],
  "namira's rot": [
    "Putrefacio Namirae",
    "Putrefactio Namirae"
  ],
  "place a workbench in your house for crafting furniture": [
    "Place a workbench in your house for crafting furniture"
  ],
  "wall sconces (2)": [
    "Wall Sconces (2)"
  ],
  "room 1b workbench": [
    "Room 1B Workbench"
  ],
  "end tables (2)": [
    "Mensae ad Latus Lecti (2)"
  ],
  "kitchen - remove workbench": [
    "Kitchen - Remove Workbench"
  ],
  "tall bookshelf": [
    "Tall Bookshelf"
  ],
  "archery targets (2)": [
    "Scopi Saggitandi (2)"
  ],
  "enchanting table": [
    "Enchanting Table"
  ],
  "dragon bridge": [
    "Dracinpons",
    "Dragon Bridge"
  ],
  "dragon skull": [
    "Calvaria Draconis"
  ],
  "room 6 workbench enable": [
    "Room 6 Workbench Enable"
  ],
  "room 5 layout": [
    "Room 5 Layout"
  ],
  "trophy base, large": [
    "Trophy Base, Large"
  ],
  "place a workbench in the kitchen": [
    "Place a workbench in the Kitchen"
  ],
  "room 7 workbench": [
    "Room 7 Workbench"
  ],
  "fish barrel": [
    "Cuppa Piscis",
    "Cuppa Piscium",
    "Cuppam Piscium"
  ],
  "room 10 workbench": [
    "Room 10 Workbench"
  ],
  "where are your parents, kid": [
    "Ubi sunt patres, puer"
  ],
  "i've got something for you, <alias.shortname=child2>": [
    "Aliquid tibi habeo, <Alias.ShortName=Child2>"
  ],
  "washbasin on stand": [
    "Washbasin on Stand"
  ],
  "round table and chairs": [
    "Round Table and Chairs"
  ],
  "sure, whatever you want": [
    "Sic, quidquid vis"
  ],
  "1000 gold": [
    "M Auri"
  ],
  "trophy room - remove workbench": [
    "Trophy Room - Remove Workbench"
  ],
  "actually, maybe this isn't the best time": [
    "Fortasse tempus aptum non est"
  ],
  "it's time for bed, <alias.shortname=child2>": [
    "Tempus est ut lectum petas, <Alias.ShortName=Child2>"
  ],
  "weapon racks": [
    "WEAPON RACKS"
  ],
  "juniper": [
    "Junipera",
    "Juniperus"
  ],
  "safe (5)": [
    "Safe (5)"
  ],
  "wall shelves (4)": [
    "Wall Shelves (4)"
  ],
  "table with display cases": [
    "Table with Display Cases"
  ],
  "weapon plaque": [
    "Armifer",
    "Weapon Plaque"
  ],
  "mounted bear head": [
    "Mounted Bear Head"
  ],
  "how do you like the farm": [
    "Fundusne tibi placet"
  ],
  "display case and cupboard": [
    "Loculamentum Armariumque"
  ],
  "why are you begging": [
    "Cur mendicaris"
  ],
  "swamp fungal pod": [
    "Folliculus Fungi Paludis",
    "Folliculus Boleti Paludis"
  ],
  "room 12 workbench": [
    "Room 12 Workbench"
  ],
  "yes, i've got a house in falkreath": [
    "Sic, domum Falkretiae habeo"
  ],
  "sorry": [
    "Ignosce"
  ],
  "for what": [
    "Cui"
  ],
  "room 11 workbench disable": [
    "Room 11 Workbench Disable"
  ],
  "time to go home, <alias.shortname=child2>": [
    "Tempus est domum revenire, <Alias.ShortName=Child2>"
  ],
  "windstad manor": [
    "Villa Ventistas"
  ],
  "letter from jarl <alias=jarl>": [
    "Epistula a Comite <Alias=Jarl>"
  ],
  "what was your story again": [
    "Dic mihi fabulam tuam, quaeso"
  ],
  "mounted elk antlers": [
    "Mounted Elk Antlers"
  ],
  "i'm a thief": [
    "Fur sum"
  ],
  "slaughterfish": [
    "Caesipiscish",
    "Slaughterfish"
  ],
  "how are the kids": [
    "Quid agit cum pueris"
  ],
  "wardrobe": [
    "Vestiarium"
  ],
  "kitchen, roof": [
    "Kitchen, Roof"
  ],
  "deathbell": [
    "Tintinamors",
    "Tintinnamors"
  ],
  "thistle": [
    "Carduum",
    "Carduus"
  ],
  "karthwasten": [
    "Karthwasten"
  ],
  "i've got something for you, <alias.shortname=child1>": [
    "Aliquid tibi habeo, <Alias.ShortName=Child1>"
  ],
  "sorry, no, i've changed my mind": [
    "Ignosce, animum meum mutavi"
  ],
  "urchin, met": [
    "Urchin, met"
  ],
  "remove the workbench from the kitchen": [
    "Remove the workbench from the Kitchen"
  ],
  "no problem. here's 500 gold": [
    "Nihil est. Habe D Auri"
  ],
  "square table": [
    "Square Table"
  ],
  "proudspire manor, my house in solitude": [
    "Vila Superbispira, Domus mea Solitudini",
    "Villa Superbispirae, domus mea Solitudini",
    "Villa Superbispiri, domus mea Solitudinis"
  ],
  "mudcrab": [
    "MudCrab",
    "Cancerilutum",
    "Cancerilutus"
  ],
  "barrels": [
    "Cuppae"
  ],
  "glowing mushroom": [
    "Boletum Lucifer"
  ],
  "vlindrel hall, my house in markarth": [
    "Aula Vlindrel, Domus mea Markariae",
    "Vlindrel Hall, my house in Markarth",
    "Aula Vlindrelis, casa mea Markartiae est"
  ],
  "alchemy laboratory": [
    "Laboratorium Alchemiae",
    "Alchemy laboratory",
    "Laboratorium Alchemia"
  ],
  "(<global=hdmarkarthalchemy> gold)": [
    "(<Global=HDMarkarthAlchemy> Auri)"
  ],
  "windstad manor, my house in hjaalmarch": [
    "Villa Ventistad, domus mea Hjaalmarcis"
  ],
  "aetherium": [
    "AETHERIUM"
  ],
  "dwarven sphere": [
    "Dwemer Sphere",
    "Dwemeris Sphera",
    "Sphera Dvemeris",
    "Sphera Dwemeris"
  ],
  "do you know where i can buy materials like glass or straw": [
    "Ubi materias ut vitrum et stramen invenire possum"
  ],
  "room 8 workbench": [
    "Room 8 Workbench"
  ],
  "room 5": [
    "Room 5"
  ],
  "at my house in markarth": [
    "Domi meae Markartiae"
  ],
  "shrine of akatosh": [
    "Ara Akatoshis",
    "Ara Alexandros"
  ],
  "vix": [
    "Vix"
  ],
  "alesan": [
    "Alesan"
  ],
  "add a tower ready to be turned into a comfortable library": [
    "Add a tower ready to be turned into a comfortable library"
  ],
  "children's bedroom": [
    "Cubiculum Puero",
    "Cubiculum pueris"
  ],
  "(<global=hdmarkarthchildroom> gold)": [
    "(<Global=HDMarkarthChildRoom> Auri)"
  ],
  "use the carpenter's workbench to build the foundation of your house": [
    "Use the Carpenter's Workbench to build the foundation of your house"
  ],
  "alchemy laboratory - workbench": [
    "Alchemy Laboratory - Workbench"
  ],
  "tall shelf": [
    "Tall Shelf"
  ],
  "low table": [
    "Low Table"
  ],
  "a stone platform suitable for placing shrines to the divines of your choice": [
    "A stone platform suitable for placing shrines to the Divines of your choice"
  ],
  "stone platform suitable for placing shrines to the divines of your choice": [
    "A stone platform suitable for placing shrines to the Divines of your choice"
  ],
  "<alias.shortname=child1>": [
    "<Alias.ShortName=Child1>"
  ],
  "bits": [
    "BITS"
  ],
  "room 11 workbench": [
    "Room 11 Workbench"
  ],
  "it's time for bed, <alias.shortname=child1>": [
    "Tempus aptum dormiendi est, <Alias.ShortName=Child1>"
  ],
  "animal pen": [
    "Hara Animali"
  ],
  "place a workbench in the library": [
    "Place a workbench in the Library"
  ],
  "let's play a game": [
    "Ludo ludamus"
  ],
  "<alias=home> furnishings": [
    "<Alias=Home> Suppilectiles"
  ],
  "at my house in hjaalmarch": [
    "Domi meae Hjaalmarcis"
  ],
  "building materials": [
    "MATERIA AEDIFICIIS"
  ],
  "shrine of stendarr": [
    "Ara Baldris",
    "Ara Stendarris"
  ],
  "remove the workbench from the trophy room": [
    "Remove the workbench from the Trophy Room"
  ],
  "lumber": [
    "Lignum"
  ],
  "(<global=byohhpamountlogs> for <global=byohhpcostlogs> gold)": [
    "(<Global=BYOHHPAmountLogs> pro <Global=BYOHHPCostLogs> Auri)"
  ],
  "bench": [
    "Subsellium"
  ],
  "potato": [
    "Malum Terrae"
  ],
  "at my house in falkreath": [
    "Domi meae Falkretiae"
  ],
  "adoptable, not met": [
    "Adoptable, not met"
  ],
  "cellar - workbench": [
    "Cellarium - Subsellium Laboris"
  ],
  "no, i don't": [
    "Minime"
  ],
  "giant lichen": [
    "Lichen Ingens"
  ],
  "actually, let's just stay here": [
    "Ut iterum cogito, hic manebimus"
  ],
  "add a kitchen that can be fitted out with cooking stations, food containers and the like": [
    "Add a kitchen that can be fitted out with cooking stations, food containers and the like"
  ],
  "what is it": [
    "Quid est",
    "Quid fit",
    "Quid nunc",
    "Quid agitur"
  ],
  "mother's love": [
    "Amor Matris"
  ],
  "nirnroot": [
    "Nirniradix"
  ],
  "room 5 workbench": [
    "Room 5 Workbench"
  ],
  "brazier": [
    "Ahenum",
    "Foculus"
  ],
  "shor's stone": [
    "Saxum Shoris",
    "Shoris Lapis"
  ],
  "wall shelves (3)": [
    "Wall Shelves (3)"
  ],
  "wardrobe, small": [
    "Wardrobe, Small"
  ],
  "add a greenhouse with customizable planters": [
    "Add a greenhouse with customisable planters"
  ],
  "at my house in the pale": [
    "Domi meae in Regione Pallido"
  ],
  "mounted elk head": [
    "Mounted Elk Head"
  ],
  "armory, wall supports": [
    "Armoury, Wall Supports"
  ],
  "small trophy base": [
    "Bas Parvum Trophaeo"
  ],
  "a grand soul gem filled with a grand soul is required to build the arcane enchanter": [
    "A Grand Soul gem filled with a Grand Soul is required to build the Arcane Enchanter"
  ],
  "grand soul gem filled with a grand soul is required to build the arcane enchanter": [
    "A Grand Soul gem filled with a Grand Soul is required to build the Arcane Enchanter"
  ],
  "boy's blue tunic": [
    "Pueri Tunica Caerulea"
  ],
  "hagraven": [
    "Hagraven",
    "Corvisaga"
  ],
  "could i adopt one of your children": [
    "Possumne filium tuum adoptare"
  ],
  "mounted mudcrab": [
    "Mounted Mudcrab"
  ],
  "trap door": [
    "Trap Door",
    "Porta Secreta"
  ],
  "we could live in my house in hjaalmarch": [
    "Domi meae Hjaalmarcis vivere possumus"
  ],
  "byohrelationshipadoptableorphanable_confirm": [
    "BYOHRelationshipAdoptableOrphanable_Confirm"
  ],
  "shrine of mara": [
    "Ara Marae",
    "Ara Freyjae"
  ],
  "not right now": [
    "Non nunc"
  ],
  "old hroldan": [
    "Old Hroldan"
  ],
  "girl's green dress": [
    "Puellae Viridis Stola"
  ],
  "thane of falkreath": [
    "Decemvir Falkretiae"
  ],
  "i'd like you to buy some building materials for me": [
    "Eme res aedificandi mihi, quaeso"
  ],
  "isn't it past your bedtime": [
    "Nonne iam lectum petere debuisti"
  ],
  "weapon racks (4)": [
    "Weapon Racks (4)"
  ],
  "kitchen, foundation": [
    "Kitchen, Foundation"
  ],
  "add a storage area which can be equipped with a variety of containers and weapon racks": [
    "Add a storage area which can be equipped with a variety of containers and weapon racks"
  ],
  "includes an exterior porch": [
    "Includes an exterior porch"
  ],
  "i need a ride": [
    "Vehi volo"
  ],
  "tall wardrobe": [
    "Tall Wardrobe"
  ],
  "dawnstar": [
    "Lucifer",
    "Luciver",
    "Dawnstar"
  ],
  "girl's yellow dress": [
    "Puellae Flava Stola"
  ],
  "armory, foundation": [
    "Armoury, Foundation"
  ],
  "room 4 workbench": [
    "Room 4 Workbench"
  ],
  "wooden sword": [
    "Gladius Ligneus"
  ],
  "remove the workbench from the alchemy laboratory": [
    "Remove the workbench from the Alchemy Laboratory"
  ],
  "room 10 layout": [
    "Room 10 Layout"
  ],
  "how about my house in falkreath": [
    "Quidni domi meae Falkretiae"
  ],
  "storage room": [
    "Storage Room"
  ],
  "(<global=byohhpcoststoragerm> gold)": [
    "(<Global=BYOHHPCostStorageRm> Auri)"
  ],
  "replace my enchanting lab with a child's room": [
    "Replace my Enchanting Lab with a Child's Room"
  ],
  "(<global=hdriftenchildroom> gold)": [
    "(<Global=HDRiftenChildRoom> Auri)"
  ],
  "orphanage, generic, no matron": [
    "Orphanage, generic, no matron"
  ],
  "room 3 workbench disable": [
    "Room 3 Workbench Disable"
  ],
  "i'm the dragonborn": [
    "Draconigena sum"
  ],
  "room 2 workbench disable": [
    "Room 2 Workbench Disable"
  ],
  "replace my alchemy lab with a child's room": [
    "Replace my Alchemy Lab with a Child's Room"
  ],
  "(<global=hdwhiterunchildroom> gold)": [
    "(<Global=HDWhiterunChildRoom> Auri)"
  ],
  "lakeview manor, my house in falkreath": [
    "Villa Lacuvisus, domus mea Falkretiae"
  ],
  "sofie": [
    "Sofia"
  ],
  "yes, i've got a house in the pale": [
    "Sic, domum in Regione Pallido habeo"
  ],
  "trophy base, small": [
    "Trophy Base, Small"
  ],
  "place a workbench in the armory": [
    "Place a workbench in the Armoury"
  ],
  "go play outside, <alias.shortname=child2>": [
    "Foris lude, quaeso, <Alias.ShortName=Child2>"
  ],
  "stonehills": [
    "Stonehills"
  ],
  "library - workbench": [
    "Library - Workbench"
  ],
  "cabbage": [
    "Brassica"
  ],
  "room 1b workbench enable": [
    "Room 1B Workbench Enable"
  ],
  "carrot": [
    "Carota",
    "Carrota"
  ],
  "shrine of zenithar": [
    "Ara Loki",
    "Ara Zenitharis"
  ],
  "room 6 workbench disable": [
    "Room 6 Workbench Disable"
  ],
  "room 3 layout": [
    "Room 3 Layout"
  ],
  "boy's grey tunic": [
    "Pueri Tunica Cana"
  ],
  "alchemy lab": [
    "Laboratorium Alchemiae"
  ],
  "(<global=byohhouselogcount>)": [
    "(<Global=BYOHHouseLogCount>)"
  ],
  "i don't need anything else": [
    "Nihil aliud quaero"
  ],
  "armory, walls": [
    "Armoury, Walls"
  ],
  "tall shelf with display case": [
    "Tall Shelf with Display Case"
  ],
  "remove the workbench from the small house": [
    "Remove the workbench from the small house"
  ],
  "library, first floor walls": [
    "Library, First Floor Walls"
  ],
  "room 1b workbench disable": [
    "Room 1B Workbench Disable"
  ],
  "spriggan": [
    "Spriggan",
    "Sprigganis"
  ],
  "library, roof canopy": [
    "Library, Roof Canopy"
  ],
  "i have a house, but not room for you": [
    "Domum habeo, sed nullum cubiculum tibi"
  ],
  "remove the workbench from the greenhouse": [
    "Remove the workbench from the Greenhouse"
  ],
  "boy's red tunic": [
    "Pueri Tunica Rubra"
  ],
  "room 1 workbench": [
    "Room 1 Workbench"
  ],
  "room4": [
    "Room4"
  ],
  "enchanter's tower - remove workbench": [
    "Enchanter's Tower - Remove Workbench"
  ],
  "adoptable, met": [
    "Adoptable, met"
  ],
  "yes, i've got a house in whiterun": [
    "Sic, domum habeo Whiterun"
  ],
  "room 10 workbench disable": [
    "Room 10 Workbench Disable"
  ],
  "armor mannequin": [
    "Pupa Loricae"
  ],
  "let's play tag": [
    "Let's play tag",
    "Tactum ludamus"
  ],
  "imp stool": [
    "Sellula Impi"
  ],
  "shrine of arkay": [
    "Ara Hellae",
    "Ara Arkayae"
  ],
  "breezehome, my house in whiterun": [
    "Zephyridomus, domus mea Albacursus",
    "Zephyridomus, villa mea Albacursus",
    "Zephyridomus, Domus Meus Albacursus"
  ],
  "biter": [
    "Biter"
  ],
  "riverwood": [
    "Riverwood",
    "Lignirivus"
  ],
  "arcane enchanter": [
    "Incantator Arcanus"
  ],
  "room 11": [
    "Room 11"
  ],
  "let's play hide-and-seek": [
    "Ludamus 'Cela et Inveni.'"
  ],
  "not this time": [
    "Non nunc in tempore"
  ],
  "girl's red dress": [
    "Puellae Rubra Stola"
  ],
  "tundra cotton": [
    "Byssum Tundrae"
  ],
  "yes, i'm sure... son": [
    "Yes, I'm sure... son"
  ],
  "child's chest": [
    "Pueri Arca"
  ],
  "<alias.shortname=child2>": [
    "<Alias.ShortName=Child2>"
  ],
  "what have you got for sale": [
    "Quae vendenda habes",
    "Quas merces habes"
  ],
  "shrine of julianos": [
    "Ara Julianis",
    "Ara Heimdalis"
  ],
  "enchanter's tower": [
    "Enchanter's Tower"
  ],
  "(<global=byohhpcostenchanting> gold)": [
    "(<Global=BYOHHPCostEnchanting> Auri)"
  ],
  "alchemy laboratory - remove workbench": [
    "Alchemy Laboratory - Remove Workbench"
  ],
  "room 11 workbench enable": [
    "Room 11 Workbench Enable"
  ],
  "go play outside, <alias.shortname=child1>": [
    "Foris lude, <Alias.ShortName=Child1>"
  ],
  "consider adoption": [
    "Cogita de Adoptione"
  ],
  "child's doll": [
    "Pupa Pueri"
  ],
  "add a tower ready to be fitted out as an alchemy laboratory": [
    "Add a tower ready to be fitted out as an alchemy laboratory"
  ],
  "i'm an assassin": [
    "Sicarius sum"
  ],
  "library, roof": [
    "Library, Roof"
  ],
  "lavender": [
    "Lavandula"
  ],
  "armory, roof": [
    "Armoury, Roof"
  ],
  "mead barrels (2)": [
    "Mead Barrels (2)"
  ],
  "room 9 workbench disable": [
    "Room 9 Workbench Disable"
  ],
  "yes, i've got a house in hjaalmarch": [
    "Sic, domum habeo Hjaalmarch"
  ],
  "frostbite spider": [
    "Frostbite Spider",
    "Aranea Adustus Nivibus"
  ],
  "sorry, not right now": [
    "Ignosce, non nunc"
  ],
  "go do your chores, <alias.shortname=child2>": [
    "Fac laborem tuum, <Alias.ShortName=Child2>"
  ],
  "chandelier, large": [
    "Lampadarium, Magnum"
  ],
  "tanning rack": [
    "Tanning Rack",
    "Sustentaculum Coriarii"
  ],
  "large planter": [
    "Large Planter"
  ],
  "library - remove workbench": [
    "Library - Remove Workbench"
  ],
  "runa": [
    "Runa"
  ],
  "mora tapinella": [
    "Mora Tapinella"
  ],
  "place a workbench in the enchanter's tower": [
    "Place a workbench in the Enchanter's Tower"
  ],
  "engar": [
    "Engar"
  ],
  "girl's blue dress": [
    "Puellae Caerulea Stola"
  ],
  "room 3": [
    "Room 3"
  ],
  "room 6 workbench": [
    "Room 6 Workbench"
  ],
  "i could adopt you, if you want": [
    "Te adoptare possum, si vis"
  ],
  "hjerim, my house in windhelm": [
    "Hjerim, domus mea Ventigaleae"
  ],
  "heljarchen hall, my house in the pale": [
    "Heljarchen Aula, domus mea in Pallido"
  ],
  "out of my way, kid": [
    "Emove te, puer"
  ],
  "library": [
    "Library",
    "bibliothēca, bibliothēcae"
  ],
  "(<global=byohhpcostlibrary> gold)": [
    "(<Global=BYOHHPCostLibrary> Auri)"
  ],
  "who are you": [
    "Quis es",
    "Quis es tu",
    "Who are you"
  ],
  "where are your parents": [
    "Where are your parents"
  ],
  "i'm arch-mage of the college of winterhold": [
    "Archimagus Collegii Hiemiterrae sum"
  ],
  "lucia": [
    "Lucia"
  ],
  "i... hadn't thought of that": [
    "De illo non cogitavi"
  ],
  "firepit": [
    "Focus Humi"
  ],
  "kitchen, wall supports": [
    "Kitchen, Wall Supports"
  ],
  "go do your chores, <alias.shortname=child1>": [
    "Fac laborem tuum, <Alias.ShortName=Child1>"
  ],
  "place a workbench in the cellar": [
    "Place a workbench in the Cellar"
  ],
  "add a residential wing with master bedroom and childrens' bedroom": [
    "Add a residential wing with master bedroom and childrens' bedroom"
  ],
  "wall shelf": [
    "Wall Shelf"
  ],
  "coffin": [
    "Sepulchrum"
  ],
  "yes, i'm sure... daughter": [
    "Yes, I'm sure... daughter"
  ],
  "skeleton": [
    "Larva",
    "Skeleton",
    "Corpus Osseum",
    "Ossa Ambulantia"
  ],
  "winterhold": [
    "Hiemiterra",
    "Winterhold"
  ],
  "room 2 workbench enable": [
    "Room 2 Workbench Enable"
  ],
  "exterior": [
    "EXTERIOR"
  ],
  "room 1 workbench enable": [
    "Room 1 Workbench Enable"
  ],
  "chicken dumpling": [
    "Adipatum Pulli"
  ],
  "mounted wolf head": [
    "Mounted Wolf Head"
  ],
  "(<global=byohhpcostkitchen> gold)": [
    "(<Global=BYOHHPCostKitchen> Auri)"
  ],
  "kitchen, walls": [
    "Kitchen, Walls"
  ],
  "white cap": [
    "Petasus Albus"
  ],
  "boy's green tunic": [
    "Pueri Tunica Viridis"
  ],
  "library, foundation": [
    "Library, Foundation"
  ],
  "tell me about yourself": [
    "Dic mihi de te"
  ],
  "creep cluster": [
    "Botrus Repens",
    "Gregem Repens",
    "Acervus Repenti"
  ],
  "room 9": [
    "Room 9"
  ],
  "room 3 workbench": [
    "Room 3 Workbench"
  ],
  "orphanage, generic": [
    "Orphanage, generic"
  ],
  "mounted goat head": [
    "Mounted Goat Head"
  ],
  "here's 10 gold": [
    "Hic habes X Auri"
  ],
  "kitchen, fireplace": [
    "Kitchen, Fireplace"
  ],
  "you can adopt up to two children, if you own a home with room for them to stay in": [
    "Unum vel duos pueros adoptare potes. Opus est tibi domum cum cubiculo apto pueris habere"
  ],
  "speak with constance michel about adoption": [
    "Adloquere Constance Michel de Adoptione"
  ],
  "hanging rack": [
    "Hanging Rack"
  ],
  "at my house in solitude": [
    "Domi meae Solitudinis"
  ],
  "fill bottles from": [
    "Comple Lagoenas"
  ],
  "swap my child's room with an enchanting lab": [
    "Muta Cubiculum Pueri cum Laboratorio Incantandi"
  ],
  "(<global=hdriftenenchanting> gold)": [
    "(<Global=HDRiftenEnchanting> Auri)"
  ],
  "snow bear": [
    "Snov Bear",
    "Ursa Nivosa"
  ],
  "riften": [
    "Hiati",
    "Riften"
  ],
  "father's love": [
    "Amor Patris"
  ],
  "well... all right": [
    "Ergo...Bene"
  ],
  "knapsack": [
    "Saccus",
    "Cibisin",
    "Cibisis",
    "Sacciperium Dorsuale"
  ],
  "library, second floor walls": [
    "Library, Second Floor Walls"
  ],
  "arcane enchanter recipe": [
    "Arcane Enchanter Recipe"
  ],
  "greenhouse": [
    "Greenhouse"
  ],
  "(<global=byohhpcostgreenhouse> gold)": [
    "(<Global=BYOHHPCostGreenhouse> Auri)"
  ],
  "red mountain flower": [
    "Flos Ruber Montis"
  ],
  "draugr": [
    "Draugr"
  ],
  "(<global=byohhpamountclay> for <global=byohhpcostclay> gold)": [
    "(<Global=BYOHHPAmountClay> pro <Global=BYOHHPCostClay> Auri)"
  ],
  "what do you have": [
    "Quid habes"
  ],
  "i'm a mercenary": [
    "Mercenarius sum"
  ],
  "blue mountain flower": [
    "Flos Caerulea Montis",
    "Flos Caeruleus Montis"
  ],
  "mounted slaughterfish": [
    "Mounted Slaughterfish"
  ],
  "desk": [
    "Mensa"
  ],
  "replace my child's room with an alchemy lab": [
    "Replace my Child's Room with an Alchemy Lab"
  ],
  "(<global=hdwhiterunalchemy> gold)": [
    "(<Global=HDWhiterunAlchemy> Auri)"
  ],
  "room 8 layout": [
    "Room 8 Layout"
  ],
  "honeyside, my house in riften": [
    "Melilatus, domus mea Hiati"
  ],
  "remove the workbench from the armory": [
    "Remove the workbench from the Armoury"
  ],
  "smelter": [
    "Auricoctor"
  ],
  "blaise": [
    "Blaise"
  ],
  "mead barrel": [
    "Cuppa Hydromelli"
  ],
  "do nothing": [
    "Nihil Fac"
  ],
  "shrine base": [
    "Shrine Base"
  ],
  "gourds": [
    "Cucurbita"
  ],
  "(<global=hdsolitudechildroom> gold)": [
    "(<Global=HDSolitudeChildRoom> Auri)"
  ],
  "friends of the jarls in falkreath, hjaalmarch and the pale may buy land in those holds and build their own home on it": [
    "Amici Comitum Falkretae, Hjaalmarcis, et Pallidi terram in illis regionibus emere possunt et villas suas in ea facere"
  ],
  "rorikstead": [
    "Rorikstead",
    "Rorikidomus"
  ],
  "table with benches": [
    "Table with Benches"
  ],
  "horker": [
    "Horker"
  ],
  "leek": [
    "Porrus"
  ],
  "lavender dumpling": [
    "Adipatum Lavandae"
  ],
  "chicken": [
    "Chicken"
  ],
  "(<global=byohhpcostchicken> gold)": [
    "(<Global=BYOHHPCostChicken> Auri)"
  ],
  "what's the matter": [
    "Quid agit",
    "Quid te vexat",
    "Quid est difficultas"
  ],
  "(<global=byohhpcostbedroom> gold)": [
    "(<Global=BYOHHPCostBedroom> Auri)"
  ],
  "<alias=player>": [
    "<Alias=Player>"
  ],
  "kitchen, floor": [
    "Kitchen, Floor"
  ],
  "strong box": [
    "Arca Secura",
    "Arcam Fortem"
  ],
  "large trophy base": [
    "Bas Magnum Trophaeo"
  ],
  "markus": [
    "Markus"
  ],
  "relationshipadoption_fgreceivegifttopic": [
    "RelationshipAdoption_FGReceiveGiftTopic"
  ],
  "clear planter": [
    "Cultorem munda"
  ],
  "gregor": [
    "Gregor"
  ],
  "kitchen - workbench": [
    "Kitchen - Workbench"
  ],
  "armory - remove workbench": [
    "Armoury - Remove Workbench"
  ],
  "yes, i've got a house in markarth": [
    "Sic, domum habeo Markarth"
  ],
  "remove the workbench from the cellar": [
    "Remove the workbench from the Cellar"
  ],
  "parent's touch": [
    "Tactus Parentis"
  ],
  "add a cellar beneath the main hall": [
    "Add a cellar beneath the Atrium"
  ],
  "orphanage, standard, no matron": [
    "Orphanage, standard, no matron"
  ],
  "dresser": [
    "Armarium"
  ],
  "blacksmith's forge": [
    "Fabrica Ferarii"
  ],
  "dining table and chairs": [
    "Mensa Triclinii et Sellae"
  ],
  "markarth": [
    "Markartia",
    "Markarth"
  ],
  "anga's mill": [
    "Mola Angae",
    "Anga's Mill"
  ],
  "enchanter's tower, roof canopy": [
    "Enchanter's Tower, Roof Canopy"
  ],
  "apiary": [
    "Apiarium"
  ],
  "at my house in whiterun": [
    "Domi meae Albacursus"
  ],
  "buy lumber for building your house": [
    "Eme lignum ut domum facias"
  ],
  "remove the workbench from the storage room": [
    "Remove the workbench from the Storage Room"
  ],
  "visit breezehome, your new house": [
    "Visita Zephyridomum, domum novam tuam",
    "Visita Zephyridomum, Villam Novam Tuam"
  ],
  "kitchen, roof framing": [
    "Kitchen, Roof Framing"
  ],
  "boy's yellow tunic": [
    "Pueri Tunica Flava"
  ],
  "room 6 layout": [
    "Room 6 Layout"
  ],
  "i have a house in the pale": [
    "Domum Pallidi habeo"
  ],
  "add a trophy room that can be equipped with display cases, animal trophies, and weapon racks": [
    "Add a trophy room that can be equipped with display cases, animal trophies, and weapon racks"
  ],
  "(<global=hdwindhelmchildroom> gold)": [
    "(<Global=HDWindhelmChildRoom> Auri)"
  ],
  "containers": [
    "ARCAE",
    "Containers"
  ],
  "room 7": [
    "Room 7"
  ],
  "room 5 workbench disable": [
    "Room 5 Workbench Disable"
  ],
  "weapon racks (5)": [
    "Weapon Racks (5)"
  ],
  "i need to trade some things with you": [
    "Res tibi tradendas habeo",
    "Opus est mihi res tecum tradere",
    "Nonnulas res tibi tradendas habeo",
    "Nonnullas res tecum communicare debeo"
  ],
  "round table": [
    "Round Table"
  ],
  "yes, i've got a big house in solitude": [
    "Yes, I've got a big house in Solitude"
  ],
  "weapon racks (2)": [
    "Weapon Racks (2)"
  ],
  "drafting table": [
    "Mensa Architecti"
  ],
  "francois": [
    "Francois"
  ],
  "purple mountain flower": [
    "Flos Purpureus Montis"
  ],
  "dragon's tongue": [
    "Lingua Draconis"
  ],
  "room 4 layout": [
    "Room 4 Layout"
  ],
  "shelves": [
    "SHELVES"
  ],
  "armory - workbench": [
    "Armoury - Workbench"
  ],
  "shrine of kynareth": [
    "Ara Thoris",
    "Ara Kynarethis"
  ],
  "i'm harbinger of the companions": [
    "Harbinger Contubernalium sum"
  ],
  "bleeding crown": [
    "Corona Exsanguinantium",
    "Corona Exsanguinationis"
  ],
  "room 11 layout": [
    "Room 11 Layout"
  ],
  "room 8 workbench disable": [
    "Room 8 Workbench Disable"
  ],
  "relationshipadoption000": [
    "RelationshipAdoption000"
  ],
  "wall shelves (2)": [
    "Wall Shelves (2)"
  ],
  "at my house in riften": [
    "Domi meae Hiati"
  ],
  "falmer": [
    "Falmer",
    "FALMERIS"
  ],
  "snowberry": [
    "Nivibacca",
    "Bacca Nivis"
  ],
  "cellar - remove workbench": [
    "Cellarium - Remove Subsellium Laboris"
  ],
  "place a workbench in the bedrooms": [
    "Place a workbench in the Bedrooms"
  ],
  "kit": [
    "Kit"
  ],
  "canis root": [
    "Radix Canis"
  ],
  "(<global=byohhpamountstone> for <global=byohhpcoststone> gold)": [
    "(<Global=BYOHHPAmountStone> for <Global=BYOHHPCostStone> Auri)"
  ],
  "library, main supports": [
    "Library, Main Supports"
  ],
  "armorer workbench": [
    "Armourer Workbench"
  ],
  "sabre cat": [
    "Sabre Cat",
    "Felix Mucronis"
  ],
  "add a tower that can be turned into a fully-equipped enchanting laboratory": [
    "Add a tower that can be turned into a fully-equipped enchanting laboratory"
  ],
  "room 9 workbench enable": [
    "Room 9 Workbench Enable"
  ],
  "scaly pholiota": [
    "Pholiota Squamata"
  ],
  "blisterwort": [
    "Tuberculiverruca",
    "Verrucatuburculum"
  ],
  "room 4 workbench enable": [
    "Room 4 Workbench Enable"
  ],
  "remove the workbench from the entryway": [
    "Remove the workbench from the entryway"
  ],
  "what do i need to do": [
    "Quid facere debeo",
    "Quid mihi faciendum est"
  ],
  "alchemy laboratory, roof canopy": [
    "Alchemy Laboratory, Roof Canopy"
  ],
  "remove the workbench from the enchanter's tower": [
    "Remove the workbench from the Enchanter's Tower"
  ],
  "add a room that can be equipped with smithing stations and weapon storage": [
    "Add a room that can be equipped with smithing stations and weapon storage"
  ],
  "room 7workbench enable": [
    "Room 7Workbench Enable"
  ],
  "place workbenches in the main hall for crafting furniture": [
    "Place workbenches in the Atrium for crafting furniture"
  ],
  "remove the workbench from the library": [
    "Remove the workbench from the Library"
  ],
  "orphanage, generic, not yet adoptable": [
    "Orphanage, generic, not yet adoptable"
  ],
  "fly amanita": [
    "Amanita Volans",
    "Boletum Amanitum Dipterae"
  ],
  "room 4 workbench disable": [
    "Room 4 Workbench Disable"
  ],
  "ice wolf": [
    "Lupus Glaciei"
  ],
  "greenhouse - remove workbench": [
    "Greenhouse - Remove Workbench"
  ],
  "room 3 workbench enable": [
    "Room 3 Workbench Enable"
  ],
  "remove the workbenches from the main hall": [
    "Remove the workbenches from the Atrium"
  ],
  "nightshade": [
    "Trychnos"
  ],
  "mounted sabre cat head": [
    "Mounted Sabre Cat Head"
  ],
  "square table and chairs": [
    "Square Table and Chairs"
  ],
  "room 9 workbench": [
    "Room 9 Workbench"
  ],
  "remove the workbench from the bedrooms": [
    "Remove the workbench from the Bedrooms"
  ],
  "chest, long": [
    "Arca Longa"
  ],
  "yes, i've got a house in riften": [
    "Sic, domum Hiati habeo"
  ],
  "armor mannequin with cupboard": [
    "Armour Mannequin with Cupboard"
  ],
  "bedrooms - remove workbench": [
    "Bedrooms - Remove Workbench"
  ],
  "byohrelationshipadoptableorphanable_sharedinfos": [
    "BYOHRelationshipAdoptableOrphanable_SharedInfos"
  ],
  "i'm an adventurer": [
    "Advenator sum"
  ],
  "weapon plaques (2)": [
    "Weapon Plaques (2)"
  ],
  "room 8 workbench enable": [
    "Room 8 Workbench Enable"
  ],
  "display case and shelf": [
    "Loculamentum et Bancus"
  ],
  "greenhouse - workbench": [
    "Greenhouse - Workbench"
  ],
  "enchanter's tower - workbench": [
    "Enchanter's Tower - Workbench"
  ],
  "place a workbench in the trophy room": [
    "Place a workbench in the Trophy Room"
  ],
  "mounted snowy sabre cat head": [
    "Mounted Snowy Sabre Cat Head"
  ],
  "place a workbench in the alchemy laboratory": [
    "Place a workbench in the Alchemy Laboratory"
  ],
  "darkwater crossing": [
    "Vadum Aquobscurae",
    "Darkwater Crossing"
  ],
  "frost troll": [
    "Trellis Glaciei"
  ],
  "room 10 workbench enable": [
    "Room 10 Workbench Enable"
  ],
  "ivarstead": [
    "Ivarstead"
  ],
  "orphanage, standard": [
    "Orphanage, standard"
  ],
  "place a workbench in the entryway for crafting furniture": [
    "Place a workbench in the entryway for crafting furniture"
  ],
  "heartwood mill": [
    "Mola Corligni",
    "Heartwood Mill"
  ],
  "here's... 5 gold": [
    "Habe V Auri"
  ],
  "storage room - remove workbench": [
    "Storage Room - Remove Workbench"
  ],
  "chaurus": [
    "Chaurus"
  ],
  "spiky grass": [
    "Gramen Acre",
    "Gramen Hamatum",
    "Gramen Cortutum"
  ],
  "double bed": [
    "Lectus Duplex"
  ],
  "dwarven spider": [
    "Aranea Dvemeris",
    "Aranea Dwemeris",
    "Dvemeris Aranea"
  ],
  "chandelier, small": [
    "Lampadarium, Parvum"
  ],
  "i'd like you to buy furnishings for the house": [
    "Eme suppelices domo, quaeso"
  ],
  "room 7 workbench disable": [
    "Room 7 Workbench Disable"
  ],
  "place a workbench in the greenhouse": [
    "Place a workbench in the Greenhouse"
  ],
  "room 6": [
    "Room 6"
  ],
  "room 5 workbench enable": [
    "Room 5 Workbench Enable"
  ],
  "(<global=byohhpcostmainhall> gold)": [
    "(<Global=BYOHHPCostMainHall> Auri)"
  ],
  "pincer": [
    "Vellicator"
  ],
  "entry room": [
    "Atrium"
  ],
  "(<global=byohhpcostentryroom> gold)": [
    "(<Global=BYOHHPCostEntryRoom> Auri)"
  ],
  "room 10": [
    "Room 10"
  ],
  "blacksmith anvil": [
    "Incus Ferarii"
  ],
  "place a workbench in the storage room": [
    "Place a workbench in the Storage Room"
  ],
  "falkreath": [
    "Falkreath",
    "Falkretia",
    "Falkretiae"
  ],
  "morthal": [
    "Morthal"
  ],
  "yes, i've got a big house in windhelm": [
    "Sic, domum magnum Ventigaleae habeo"
  ],
  "go back to bed": [
    "Domum repete"
  ],
  "storage room - workbench": [
    "Storage Room - Workbench"
  ],
  "hawks' nest": [
    "Nidus Accipitris"
  ],
  "room 8": [
    "Room 8"
  ],
  "kynesgrove": [
    "Kynenemus",
    "Kynesgrove"
  ],
  "(<global=horsecost> gold)": [
    "(<Global=HorseCost> Auri)"
  ],
  "room 12 workbench enable": [
    "Room 12 Workbench Enable"
  ],
  "location for things that need a location": [
    "Location for things that need location",
    "Location for things that need a location"
  ],
  "relationshipadoption_sharedinfos": [
    "RelationshipAdoption_SharedInfos"
  ],
  "yes, i did": [
    "Sic, id feci"
  ],
  "sofie's flowers": [
    "Fores Sofiae"
  ],
  "skeever": [
    "Skeever"
  ],
  "cotton": [
    "Byssum"
  ],
  "trophy room": [
    "Trophy Room"
  ],
  "(<global=byohhpcosttrophyrm> gold)": [
    "(<Global=BYOHHPCostTrophyRm> Auri)"
  ],
  "alchemy": [
    "Alchemy",
    "Alchemia"
  ],
  "(<global=byohhpcostalchemy> gold)": [
    "(<Global=BYOHHPCostAlchemy> Auri)"
  ],
  "small house (<global=byohhpcostentryroom> gold)": [
    "Domum Parvam (<Global=BYOHHPCostEntryRoom> Auri)"
  ],
  "night tables (2)": [
    "Night Tables (2)"
  ],
  "how about 100 gold": [
    "Quidni C Auri"
  ],
  "i've purchased a home and furnished it": [
    "Domum emi et suppelices in eam posui"
  ],
  "can i adopt a child now": [
    "Possumne nunc filium adoptare"
  ],
  "sorry, i can't afford it right now": [
    "Ignosce, id nunc non emere possum"
  ],
  "room 12 workbench disable": [
    "Room 12 Workbench Disable"
  ],
  "troll": [
    "Troll",
    "Trellis"
  ],
  "here's 25 gold": [
    "Habe XXV Auri"
  ],
  "i own a house, but i don't have a room for them": [
    "Domum habeo sed cubiculum ei non habeo"
  ],
  "jazbay": [
    "Jazbay"
  ],
  "archery target": [
    "Scopus Saggitandi"
  ],
  "bobs": [
    "BOBS"
  ],
  "fertile soil": [
    "Lutum Fertile",
    "Lutus Fertilis"
  ],
  "valdimar": [
    "Valdimar"
  ],
  "room 9 layout": [
    "Room 9 Layout"
  ],
  "large table with chest": [
    "Large Table with Chest"
  ],
  "urchin, not met": [
    "Urchin, not met"
  ],
  "lakeview manor charter": [
    "Emptio Villae Lacivisus"
  ],
  "knud": [
    "Knud"
  ],
  "windstad manor charter": [
    "Emptio Villae Ventistas"
  ],
  "when i don't need him, he can stay with you": [
    "When I don't need him, he can stay with you"
  ],
  "you can find, make, or buy a variety of gifts for your children, including toys, clothing, and sweets": [
    "Invenire, facere, vel emere dona varia pueris. Haec dona sunt Crepundiae et Pupae, vestimenta, et Dulcia"
  ],
  "i got your letter": [
    "Epistulam tuam accepi"
  ],
  "i'm <alias=player>": [
    "Sum <Alias=Player>"
  ],
  "heljarchen hall": [
    "Heljarchen Aula"
  ],
  "where can i learn more about magic": [
    "Ubi plus de magica discere possum"
  ],
  "gray pine goods": [
    "Bona Pinacana",
    "Pina-Cana Bona"
  ],
  "heljarchen hall charter": [
    "Emptio Aulae Heljarchen"
  ],
  "a... mudcrab": [
    "... cancerilutum"
  ],
  "heljarchen hall, cellar": [
    "Aula Heljarchen, Cellarium"
  ],
  "now get it out of here": [
    "Nunc discede"
  ],
  "lakeview manor, cellar": [
    "Villa Lacivisus, Cellarium"
  ],
  "windstad manor, cellar": [
    "Villa Ventistas, Cellarium"
  ],
  "give me everything you're carrying, dog": [
    "Da mihi omnia quae portas, canis",
    "Da mihi omnia quae portes, catulle"
  ],
  "a rabbit": [
    "Cunicularius"
  ],
  "it's time for us to move": [
    "Tempus est nobis movendi"
  ],
  "where would i get that": [
    "Ubi illud inveniam"
  ],
  "mill": [
    "Mola"
  ],
  "child's practice dummy": [
    "Pupa Practicandi Pueri"
  ],
  "addvar's house": [
    "Domus Advaris",
    "Domus Addvaris"
  ],
  "my house in markarth would be perfect": [
    "Domus mea Markartiae optima sit",
    "Domus mea Markartiae optima erit",
    "Domus mea Markartiae optima esset"
  ],
  "do you have any work you need done": [
    "Habesne munera facienda"
  ],
  "a... skeever": [
    "... skeever"
  ],
  "letter from jarl <alias=jarl> of <alias=holdcity>": [
    "Epistula a Comite <Alias=Jarl> <Alias=HoldCity>"
  ],
  "speak to the jarl of falkreath": [
    "Adloquere Comitem Falkretiae"
  ],
  "absolutely not": [
    "Minime"
  ],
  "no pets": [
    "Nullae deliciae",
    "Nullas delicias"
  ],
  "if you would like to adopt an orphan from the orphanage, speak to constance michel": [
    "Si orphanum e Orphanotrophio adoptare velis, adloquere Constance Michel"
  ],
  "the beginner's guide to homesteading": [
    "Monstrator Primus de Villis"
  ],
  "beginner's guide to homesteading": [
    "Monstrator Primus de Villis"
  ],
  "all right. but you're responsible for him": [
    "Bene, sed responsabilis ei es"
  ],
  "what would you like": [
    "Quid vis"
  ],
  "at my house in windhelm": [
    "Domi meae Ventigaleae"
  ],
  "your old place would suit us just fine": [
    "Locus vetus tuus satis nobis sit",
    "Locus vetus tuus nobis sufficeret",
    "Your old place would suit us just fine"
  ],
  "a fox": [
    "Vulpis"
  ],
  "fox": [
    "Vulpis"
  ],
  "here's your money. she'd better be safe. (give <global=byohhba2ransom> gold)": [
    "Hic habes pecuniam. Illam tutam esse debet (Da <Global=BYOHHBA2Ransom> Auri)"
  ],
  "how about some instrumental music": [
    "Quidni musica instrumentalis"
  ],
  "not allowed": [
    "Not Allowed"
  ],
  "cut it out, both of you": [
    "Desiste ab eo, vos ambos"
  ],
  "allowed (aventus only)": [
    "Allowed (Aventus Only)"
  ],
  "let's go": [
    "Eamus"
  ],
  "i don't have the money": [
    "Pecuniam non habeo"
  ],
  "can't you just let him go": [
    "Nonne eum gratis liberare potes"
  ],
  "i don't need your services any more": [
    "Muneribus tuis non careo"
  ],
  "read the bandit's ransom note for <alias=spouse>": [
    "Lege epistulam latronis pro <Alias=Spouse>"
  ],
  "rescue <alias=spouse> from <alias=banditdungeon>": [
    "Serva <Alias=Spouse> a <Alias=BanditDungeon>"
  ],
  "sonir": [
    "Sonir"
  ],
  "katla's farm": [
    "Katla's Farm",
    "Fundus Katlae"
  ],
  "how about you just let her go and nobody has to get hurt": [
    "Quidni eam liberes et nemo noceatur"
  ],
  "rochelle the red": [
    "Rochelle the Red"
  ],
  "what did you do to my husband": [
    "Quid cum marito meo fecisti"
  ],
  "<alias.shortname=child2>, go to your room": [
    "<Alias.ShortName=Child2>, cubiculum pete"
  ],
  "what did you do to my wife": [
    "Quid cum uxore mea fecisti"
  ],
  "<alias.shortname=child1>, go to your room": [
    "<Alias.ShortName=Child1>, cubiculum pete"
  ],
  "c'mon boy, come with me": [
    "Veni catulle, veni mecum"
  ],
  "i'd like to make a request": [
    "Carmen audire volo"
  ],
  "fish hatchery": [
    "Fertificator Piscium"
  ],
  "can you take a break": [
    "Pausarene potes",
    "Potesne pausare"
  ],
  "child's bed": [
    "Pueri Lectus"
  ],
  "i'd like you to play a different instrument": [
    "Muta instrumentum canendum, quaeso"
  ],
  "llewellyn the nightingale": [
    "Llewellyn Philomela"
  ],
  "(<global=byohhpcostbard> gold)": [
    "(<Global=BYOHHPCostBard> Auri)"
  ],
  "oriella": [
    "Oriella"
  ],
  "i've changed my mind": [
    "Animum mutavi"
  ],
  "i'd like you to stay": [
    "Mane, quaeso"
  ],
  "ransom note": [
    "Epistula Lytrorum"
  ],
  "can't you just let her go": [
    "Nonne eam gratis liberare potes"
  ],
  "you're dead": [
    "Mortuus es"
  ],
  "how about you just let him go and nobody has to get hurt": [
    "Quidni eum liberes et nemo noceatur"
  ],
  "what do you want": [
    "Quid vis"
  ],
  "here's your money": [
    "Hic habes pecuniam"
  ],
  "he'd better be safe": [
    "Illum tutam esse debet"
  ],
  "(give <global=byohhba2ransom> gold)": [
    "(Da <Global=BYOHHBA2Ransom> Auri)"
  ],
  "llewellyn": [
    "Llewellyn"
  ],
  "money's not going to do you much good after i've killed you": [
    "Pecunia nihi tibi faciet post mortem tuum"
  ],
  "let's get out of here": [
    "Discedamus"
  ],
  "i've heard enough": [
    "Satis audivi"
  ],
  "allowed (all)": [
    "Allowed (All)"
  ],
  "default": [
    "Default"
  ],
  "log pile": [
    "Cumulus Ligni"
  ],
  "no, never mind": [
    "Minime, Ignosce"
  ],
  "not yet. i'll be back for you": [
    "Nondum, pro te reveniam"
  ],
  "garlic bread": [
    "Panis Alii"
  ],
  "braided bread": [
    "Panis Contextus"
  ],
  "bleeding crown (3)": [
    "Corona Exsanguinationis (3)"
  ],
  "white cap (2)": [
    "White Cap (2)"
  ],
  "canis root (4)": [
    "Radix Canis (4)"
  ],
  "thistle branch (3)": [
    "Thistle Branch (3)"
  ],
  "mora tapinella (3)": [
    "Mora Tapinella (3)"
  ],
  "potato (3)": [
    "Malum Terrae (3)"
  ],
  "creep cluster (5)": [
    "Caterva Repens (5)"
  ],
  "shrine of dibella": [
    "Ara Idunnis",
    "Ara Dibellae"
  ],
  "scaly pholiota (5)": [
    "Pholiota Squamata (5)"
  ],
  "lavender (3)": [
    "Lavandula (3)"
  ],
  "blisterwort (4)": [
    "Tuburuloverruca (4)"
  ],
  "tundra cotton (3)": [
    "Tundra Cotton (3)"
  ],
  "wheat (4)": [
    "Wheat (4)"
  ],
  "fly amanita (4)": [
    "Amanita Diptera (4)"
  ],
  "gourd (3)": [
    "Cucurbitum (3)"
  ],
  "carrots (4)": [
    "Carrotae (4)"
  ],
  "blue mountain flower (3)": [
    "Flos Montis Caerulei (3)"
  ],
  "red mountain flower (3)": [
    "Red Mountain Flower (3)"
  ],
  "grass pod (3)": [
    "Folliculus Graminis (3)"
  ],
  "snowberries (4)": [
    "Nivibaccae (4)"
  ],
  "nightshade (3)": [
    "Umbranox (3)"
  ],
  "deathbell (3)": [
    "Tintinnamors (3)"
  ],
  "leek (2)": [
    "Porrus (2)"
  ],
  "cabbage (2)": [
    "Brassica (2)"
  ],
  "imp stool (4)": [
    "Scamnum Impi (4)"
  ],
  "giant lichen (4)": [
    "Lichen Ingens (4)"
  ],
  "jazbay grapes (3)": [
    "Jazbay Uvae (3)"
  ],
  "glowing mushroom (5)": [
    "Boletum Lucifer (5)"
  ],
  "purple mountain flower (3)": [
    "Flos Purpureus Montis (3)"
  ],
  "night table": [
    "Night Table"
  ],
  "juniper berries (4)": [
    "Junipera Bocca(4)"
  ],
  "namira's rot (3)": [
    "Putrefactio Namirae (3)"
  ],
  "dragon's tongue (4)": [
    "Lingua Draconis (4)"
  ],
  "swamp fungal pod (3)": [
    "Folicula Fungi Paludis (3)"
  ],
  "take butter from": [
    "Remove Buturum"
  ],
  "speak to the steward of falkreath about the land for sale": [
    "Adloquere Dispensatorem Falkretiae de terra vendenda"
  ],
  "speak to the steward of morthal about the land for sale": [
    "Adloquere Dispensatorem Morthalis de terra vendenda"
  ],
  "speak to the jarl of dawnstar about the land for sale": [
    "Adloquere Comitem Luciferis de terra vendenda"
  ],
  "churn": [
    "Premisce"
  ],
  "vampiric blood rested": [
    "Vampiric Blood Rested"
  ],
  "frodnar": [
    "Frodnar"
  ],
  "samuel": [
    "Samuel",
    "Sammael"
  ],
  "carriage system dialogue": [
    "Carriage system dialogue"
  ],
  "\"accidentally dropped this\"": [
    "\"Accidentally dropped this\""
  ],
  "follow me": [
    "Sequere me"
  ],
  "riverwood test scene": [
    "Riverwood test scene"
  ],
  "jarl's longhouse": [
    "Comitis Longhouse",
    "Domilonga Comitis"
  ],
  "i'd like to decorate my home": [
    "Domum meam ornare volo",
    "Volo domum meam ornare"
  ],
  "niranye services": [
    "Niranye Services"
  ],
  "hrefna": [
    "Hrefna"
  ],
  "whiterun braith lars scene 1": [
    "Whiterun Braith Lars Scene 1"
  ],
  "whiterun braith lars scene 3": [
    "Whiterun Braith Lars Scene 3"
  ],
  "dialoguedragonbridgescene01": [
    "DialogueDragonBridgeScene01"
  ],
  "visit hjerim, your new house": [
    "Hjerim, domum novam tuam",
    "Visita Hjerim, Villam Novam Tuam"
  ],
  "visit vlindrel hall, your new house": [
    "Visita Aulam Vlindrelis, domum novam tuam",
    "Visita Aulam Vlindrelem, Villam Novam Tuam"
  ],
  "visit proudspire manor, your new house": [
    "Visita Villam Superbaspiram, domum novam tuam",
    "Visita Villam Superbispirae, Villam Novam Tuam"
  ],
  "<alias=playerisit> is it": [
    "<Alias=PlayerIsIt> es id",
    "<Alias=PlayerIsIt> est id"
  ],
  "keep away from <alias=playerisit.pronounobj>": [
    "Evita <Alias=PlayerIsIt.PronounObj>"
  ],
  "tag, you're it": [
    "Tag, es id"
  ],
  "goldenrock mine": [
    "Goldenrock Mine",
    "Metallum Aurisaxum"
  ],
  "quicksilver mine": [
    "Metallum Mercurii",
    "QuickArgentei Mine"
  ],
  "riverwood trader": [
    "Mercator Lignirivi"
  ],
  "hide from <alias=playerisit.pronounobj>": [
    "Evita <Alias=PlayerIsIt.PronounObj>",
    "Late ab <Alias=PlayerIsIt.PronounObj>"
  ],
  "hide and seek": [
    "Cela et Inveni",
    "Late et Inveni"
  ],
  "kid games": [
    "Kid Games",
    "Ludus Pueris"
  ],
  "runa fair-shield": [
    "Runa Aequa-Scuti",
    "Runa Scuta-Candida"
  ],
  "thane of hjaalmarch": [
    "Decemvir Hjaalmarcis"
  ],
  "thane of the pale": [
    "Decemvir Pallidi"
  ],
  "you're out in the middle of nowhere, aren't you": [
    "In centro nusquamae es, nonne",
    "You're out in the middle of nowhere, aren't you"
  ],
  "bedroom furnishings": [
    "Suppelices Cubiculo",
    "Suppelices cubiculi",
    "Supellices Cubiculi",
    "Suppilictiles Cubiculo"
  ],
  "(<global=hdriftenbedroom> gold)": [
    "(<Global=HDRiftenBedroom> Auri)"
  ],
  "bits and pieces": [
    "Haec et Illa",
    "Bits and Pieces"
  ],
  "(<global=hdwindhelmbedroom> gold)": [
    "(<Global=HDWindhelmBedroom> Auri)"
  ],
  "(<global=hdwindhelmarmory> gold)": [
    "(<Global=HDWindhelmArmory> Auri)"
  ],
  "pactur": [
    "Pactur"
  ],
  "it's time for us to part ways": [
    "Tempus est ut disiugemus",
    "Tempus est ut separatim eamus",
    "Tempus est ut seperatim eamus",
    "Tempus est ut nos separatim ire"
  ],
  "solitude sawmill": [
    "Mola Ligni Solitudinis",
    "Solitudinis Mola Serris"
  ],
  "(<global=hdsolitudebedroom> gold)": [
    "(<Global=HDSolitudeBedroom> Auri)"
  ],
  "living room": [
    "Atrium",
    "Living room"
  ],
  "(<global=hdsolitudeliving> gold)": [
    "(<Global=HDSolitudeLiving> Auri)"
  ],
  "gralnach": [
    "Gralnach"
  ],
  "you're it": [
    "Es id"
  ],
  "tag someone": [
    "Cape aliquem",
    "Tange aliquem"
  ],
  "dorthe": [
    "Dorthe"
  ],
  "francois beaufort": [
    "Francois Beaufort"
  ],
  "visit honeyside, your new house": [
    "Visita Melilatum, domum novam tuam",
    "Visita Melilatum, Villam Novam Tuam"
  ],
  "clinton lylvieve": [
    "Clinton Lylvieve"
  ],
  "pawned prawn": [
    "Squilla Venta",
    "Squila Pignorata",
    "The Pawned Prawn"
  ],
  "sure, tag sounds fun": [
    "Certe, tag faciamus",
    "Ita vero, gaudeo tag ludere"
  ],
  "living with you would be perfect": [
    "Tecum vivere optimum sit",
    "Tecum vivere perfectum erit",
    "Mihi valde placebit tecum vivere"
  ],
  "arnleif and sons trading company": [
    "Arnleif and Sons Trading Company",
    "Negotium Tradendi Arnleif Filiorumque"
  ],
  "how about my house in windhelm": [
    "Quid de domu mea Ventigaleae",
    "Quidni domi meae Ventigaleae",
    "Quidni domus mea Ventigaleae"
  ],
  "skuli": [
    "Skuli"
  ],
  "i'd like us to move somewhere else": [
    "Volo domum nostram movere",
    "Volo ad alium locum movere",
    "Ad alium locum domum nostrum movere volo"
  ],
  "let's live in my house in solitude": [
    "Domi Solitudini Vivamus",
    "Vivamus domi mea Solitudini",
    "Vivamus domi meae Solitudinis"
  ],
  "i have a house in riften": [
    "Domum Hiati habeo"
  ],
  "daighre's house": [
    "Domus Daighris",
    "Daighre's House"
  ],
  "(<global=hdwhiterunbedroom> gold)": [
    "(<Global=HDWhiterunBedroom> Auri)"
  ],
  "having trouble": [
    "Difficultatemne habes",
    "Difficultatesne pateris"
  ],
  "erith": [
    "Erith"
  ],
  "(invisible continues linked to self)": [
    "(Invisible Continues Linked to Self)"
  ],
  "temple of mara": [
    "Templum Marae",
    "Templum Veneris"
  ],
  "i need your help": [
    "Auxilium tuum quaero"
  ],
  "relationshipmarriagepostweddingloveinterestblockingtopic": [
    "RelationshipMarriagePostWeddingLoveInterestBlockingTopic",
    "RelationshipMarriagePostWeddingLoveInterestInterponendiTopic"
  ],
  "eirid": [
    "Eirid"
  ],
  "i've helped your people": [
    "Tuos adiuvi",
    "Cives tuos adiuvi",
    "Tuis auxilium dedi"
  ],
  "half-moon mill": [
    "Half-moon Mill",
    "Media-Luna Mola",
    "Mola Media-Luna"
  ],
  "the white hall": [
    "Aula Alba",
    "The White Hall"
  ],
  "white hall": [
    "Aula Alba",
    "The White Hall"
  ],
  "(<global=hdriftengarden> gold)": [
    "(<Global=HDRiftenGarden> Auri)"
  ],
  "minette vinius": [
    "Minette Vinius"
  ],
  "find someone": [
    "Inveni aliquem"
  ],
  "candlehearth hall": [
    "Aula Facifocus",
    "Candlehearth Hall"
  ],
  "you need to stop bullying lars battle-born": [
    "Noli Lars Proeli-Natum vexare",
    "A Lars Proeli-Natu desistere debes"
  ],
  "you're awfully... mature for your age": [
    "Valde... matura es pro aetate tua",
    "You're awfully... mature for your age"
  ],
  "lylvieve family's house": [
    "Lylvievis Familae Domus",
    "Vill Familiae Lylvievis"
  ],
  "house of clan battle-born": [
    "Domus Gentis Proeli-Natus",
    "House of Clan Battle-Born"
  ],
  "the winking skeever": [
    "Skeever Adnictans",
    "The Winking Skeever"
  ],
  "winking skeever": [
    "Skeever Adnictans",
    "The Winking Skeever"
  ],
  "aventus aretino": [
    "Aventus Aretino",
    "Aventus Aretinus"
  ],
  "floor decorations": [
    "Ornamenta Solo",
    "Ornamenta Pavimento"
  ],
  "(400 gold)": [
    "(400 auri)",
    "(CCCC Auri)"
  ],
  "braith": [
    "Braith"
  ],
  "svari": [
    "Svari"
  ],
  "belethor's general goods": [
    "Belethor's General Goods",
    "Bona Communia Belethoris"
  ],
  "birna's oddments": [
    "Birnae Baroca",
    "Mirabilia Birnae"
  ],
  "lemkil's farmhouse": [
    "Lemkil's Farmhouse",
    "Villa Rustica Lemkilis"
  ],
  "do you have any more work": [
    "Habesne plus laboris",
    "Habesne plurima munera"
  ],
  "how about hide-and-seek": [
    "Quid de late-et-inveni",
    "Quidni 'cela et inveni?'"
  ],
  "hroar": [
    "Hroar"
  ],
  "i need you to do something": [
    "Fac aliquid mihi",
    "Volo te aliquid facere",
    "Fac aliquid mihi, quaeso"
  ],
  "alvor and sigrid's house": [
    "Alvor and Sigrid's House",
    "Domus Alvoris Sigridisque"
  ],
  "old hroldan inn": [
    "Old Hroldan Inn",
    "Diversorium Hroldan Veteris"
  ],
  "vlindrel hall": [
    "Vlindrel Hall",
    "Aula Vlindrelis"
  ],
  "we could live in my house in whiterun": [
    "Domi mea Albacursus vivere possumus",
    "Domi meo Albacursus vivere possimus",
    "Domi meae Albacursus vivere possumus"
  ],
  "dialoguegenerichello": [
    "DialogueGenericHello"
  ],
  "actually, i can't stop to play games right now": [
    "Em, nunc ludere non possum",
    "Tempus non habeo ut ludis ludam",
    "Re vera, non nunc consistere possum ut ludos ludam"
  ],
  "sissel": [
    "Sissel"
  ],
  "grain mill": [
    "Mola Tritici",
    "Mola Frumenti"
  ],
  "amren's house": [
    "Amrenis House",
    "Domus Amrenis"
  ],
  "hod and gerdur's house": [
    "Domus Hod Gerdurisque",
    "Hod and Gerdur's House"
  ],
  "the bannered mare": [
    "Caballa Vexillata",
    "The Bannered Mare"
  ],
  "bannered mare": [
    "Caballa Vexillata",
    "The Bannered Mare"
  ],
  "britte": [
    "Britte"
  ],
  "honorhall orphanage": [
    "Honorhall Orphanage",
    "Orphanotrophium Aulae Honoris"
  ],
  "enchanting laboratory": [
    "Laboratorium Incantantionum",
    "Enchanting laboratory",
    "Laboratorium Incantandi"
  ],
  "sadri's used wares": [
    "Sadri Used Wares",
    "Manu Secunda Sadri"
  ],
  "birna services": [
    "Birna Services"
  ],
  "windpeak inn": [
    "Windpeak Inn",
    "Diversorium Summiventum"
  ],
  "whiterun braith lars scene 2": [
    "Whiterun Braith Lars Scene 2"
  ],
  "wait here": [
    "Hic exspecta",
    "Hic expecta",
    "Exspecta hic"
  ],
  "i've killed the leader of <alias=banditcamp>": [
    "Ducem <Alias=BanditCamp> necavi",
    "Necavi ducem <Alias=BanditCamp>",
    "Ducem ad <Alias=BanditCamp> necavi"
  ],
  "iron-breaker mine": [
    "Iron-Breaker Mine",
    "Metallum Ferri-Fractor"
  ],
  "highmoon hall": [
    "Aula Altaluna",
    "Highmoon Hall"
  ],
  "the frozen hearth": [
    "Focus Congelatus",
    "The Frozen Hearth"
  ],
  "frozen hearth": [
    "Focus Congelatus",
    "The Frozen Hearth"
  ],
  "clinton": [
    "Clinton"
  ],
  "minette": [
    "Minette"
  ],
  "trapdoor": [
    "Trapdoor",
    "Porta Secreta"
  ],
  "aventus": [
    "Aventus"
  ],
  "apple cabbage stew": [
    "Ius Mali Brassicaeque",
    "Mali Brassicaeque Minutal"
  ],
  "windhelm home decorating guide": [
    "Monstrator Domuus Ventigalleae Ornandi",
    "Ventigaleae Lectionarium Domus Ornantis"
  ],
  "elsweyr fondue": [
    "Elsveyr Caseus Coctus",
    "Caseus Fondutus Elsweyr"
  ],
  "venison stew": [
    "Ius Cervinae",
    "Cervinae Minutal"
  ],
  "whiterun home decorating guide": [
    "Rector Domuum Albacursorum",
    "Monstrator Domuus Albacursus Ornandi"
  ],
  "markarth home decorating guide": [
    "Monstrator Domuus Markartie Ornandi",
    "Markartiae Lectionarium Domus Ornantis"
  ],
  "solitude home decorating guide": [
    "Monstrator Domuus Solitudinem Ornandi",
    "Solitudinis Lectionarium Domus Ornantis"
  ],
  "visit your spouse's house": [
    "Visita Domum Mariti",
    "Visita domum mariti/ae"
  ],
  "beef stew": [
    "Carnis Minutal",
    "Ius Carnis Vaccae"
  ],
  "tomato soup": [
    "Ius Lycopersici",
    "Lycopersicum Ius"
  ],
  "riften home decorating guide": [
    "Monstrator Domuus Hiatum Ornandi",
    "Hiati Lectionarium Domus Ornantis"
  ],
  "cabbage potato soup": [
    "Ius Brassicae et Mali Terrae",
    "Ius Mali Terrae Brassicaeque"
  ],
  "horker stew": [
    "Ius Horker",
    "Minutal Horkeris"
  ],
  "vegetable soup": [
    "Ius Holerum"
  ],
  "salmon": [
    "Esicia",
    "Salmonem"
  ],
  "auriel's bow": [
    "Arcus Aurorae"
  ],
  "crossbow mount": [
    "Crossbow Mount"
  ],
  "vale deer": [
    "Cerva Vallis"
  ],
  "transform into the vampire lord": [
    "Muta in Dominum Vampirorum"
  ],
  "quick test cell": [
    "Quick Test Cell"
  ],
  "vampire lord": [
    "Dominus Vampirorum"
  ],
  "trap sunlight": [
    "Trap Sunlight"
  ],
  "sorine": [
    "Sorine"
  ],
  "durnehviir": [
    "Durnehviir"
  ],
  "albino": [
    "Albino"
  ],
  "vampire lord abilities": [
    "Vampire Lord Abilities"
  ],
  "summon durnehviir": [
    "Voca Durnehviir"
  ],
  "d6": [
    "D6"
  ],
  "fort dawnguard": [
    "Castellum Aurorae Custodum"
  ],
  "neh": [
    "Neh"
  ],
  "hestla": [
    "Hestla"
  ],
  "falmer staff": [
    "Rudis Falmeris"
  ],
  "vigilant of stendarr": [
    "Vigilans Shoris",
    "Vigilans Stendarris"
  ],
  "hakar": [
    "Hakar"
  ],
  "arkngthamz": [
    "Arkngthamz"
  ],
  "auriel's shield": [
    "Aurorae Scutum"
  ],
  "serana": [
    "Serana"
  ],
  "dawnguard novice hakar": [
    "Aurorae Custodum Novitius Hakar"
  ],
  "volkihar keep": [
    "Volkihar Keep"
  ],
  "castle volkihar": [
    "Castellum Volkihar"
  ],
  "feran sadri": [
    "Feran Sadri"
  ],
  "florentius baenius": [
    "Florentius Baenius"
  ],
  "ronthil": [
    "Ronthil"
  ],
  "browsmalesnowelf": [
    "BrowsMaleSnowElf"
  ],
  "frost cloak": [
    "Amiculum Glaciei"
  ],
  "dawnguard novice (06 / whiterun caches)": [
    "Dawnguard Novice (06 / Whiterun Caches)"
  ],
  "v3r": [
    "V3r"
  ],
  "whiterun cache tracking": [
    "Whiterun Cache Tracking"
  ],
  "dawnguard cache": [
    "Arcam Aurorae Custodum"
  ],
  "beyond death": [
    "Post Mortem"
  ],
  "summon a dragon from the soul cairn to fight for you": [
    "Draconem e Terra Animorum vocat"
  ],
  "unseen visions": [
    "Visus Invisi"
  ],
  "falmer heavy boots": [
    "Falmeris Caligae Graves"
  ],
  "touching the sky": [
    "Caelum Tangens"
  ],
  "bats": [
    "Vespertiliones",
    "Vespertilliones"
  ],
  "moldering ruins": [
    "Putrescentes Ruinae"
  ],
  "dawnguard vampire hunters": [
    "Dawnguard Vampire Hunters"
  ],
  "hjaalmarch/pale/winterhold cache tracking": [
    "Hjaalmarch/Pale/Winterhold Cache Tracking"
  ],
  "vampire ash pile": [
    "Cumulus Cineris Vampiri"
  ],
  "reach/haafingar cache tracking": [
    "Reach/Haafingar Cache Tracking"
  ],
  "dawnguard outrider (07 / northlands caches)": [
    "Dawnguard Outrider (07 / Northlands Caches)"
  ],
  "reduced health": [
    "Salus Diminuta",
    "Salus Diminutus"
  ],
  "dawnguard orders - hakar": [
    "Mandata Aurorum - Hakar"
  ],
  "rargal": [
    "Rargal"
  ],
  "flames": [
    "Flammae"
  ],
  "dimhollow cavern": [
    "Obscuracava Caverna"
  ],
  "dayspring canyon": [
    "Vallis Diei Orientis"
  ],
  "sanguinare vampiris": [
    "Sanguinare Vampiris"
  ],
  "any idea why the vampires attacked the vigilants": [
    "Scisne quamobrem Vampiri Vigilantes oppugnaverint"
  ],
  "vampire assassin": [
    "Sicarius Vampirus"
  ],
  "vampire masquerade": [
    "Vampire Masquerade"
  ],
  "garan": [
    "Garan"
  ],
  "vingalmo": [
    "Vingalmo"
  ],
  "hairlinemalesnowelf01": [
    "HairLineMaleSnowElf01"
  ],
  "trap linker": [
    "Trap Linker"
  ],
  "falmer heavy helm": [
    "Falmeris Gravis Helm"
  ],
  "dawnguard orders - lynoit": [
    "Mandata Aurorum - Lynoit"
  ],
  "sorine jurard": [
    "Sorine Jurard"
  ],
  "dawnguard orders - saliah": [
    "Mandata Aurorum - Saliah"
  ],
  "hunting bow": [
    "Arcus Venandi",
    "Venandi Arcus"
  ],
  "its baaaaaack": [
    "Its baaaaaack"
  ],
  "feran": [
    "Feran"
  ],
  "agmaer": [
    "Agmaer"
  ],
  "orthjolf": [
    "Orthjolf"
  ],
  "soul cairn": [
    "Terra Animorum"
  ],
  "forgotten vale": [
    "Vallis Oblita"
  ],
  "writ of dawn": [
    "Mantata Aurorae Custodum"
  ],
  "vigilants pursuing vampire": [
    "Vigilants pursuing Vampire"
  ],
  "maleheadhighelfsnow": [
    "MaleHeadHighElfSnow"
  ],
  "vampire hunter": [
    "Venator Vampirorum Aurorae Custodum"
  ],
  "swindler's den": [
    "Swindler's Den",
    "Latronis Cubile"
  ],
  "reduces health by <mag>": [
    "Diminua salutem <mag> punctis",
    "Salutis diminuitur <mag> punctis"
  ],
  "progresses to vampirism": [
    "Progreditur ad Vampirismum",
    "Sine remedio, vampirum fies"
  ],
  "glacial crevice": [
    "Glacialis Hiatus"
  ],
  "falmer heavy armor": [
    "Falmeris Lorica Gravis"
  ],
  "scent of blood": [
    "Olor Sanguis",
    "Olor Sanguinis"
  ],
  "volkihar undercroft": [
    "Volkiharis Catacumbae"
  ],
  "volkihar ruins": [
    "Volkiharis Ruinae"
  ],
  "dawnguard cache key": [
    "Clavis Arcae Aurorae Custodibus"
  ],
  "nightmaster vampire": [
    "Vampira Noctidomina"
  ],
  "maleeyessnowelf": [
    "MaleEyesSnowElf"
  ],
  "garan marethi": [
    "Garan Marethi"
  ],
  "stalf": [
    "Stalf"
  ],
  "salonia": [
    "Salonia"
  ],
  "salonia caelia": [
    "Salonia Caelia"
  ],
  "isran": [
    "Isran"
  ],
  "falmer heavy gauntlets": [
    "Falmeris Manubria Gravia"
  ],
  "gunmar": [
    "Gunmar"
  ],
  "florentius": [
    "Florentius"
  ],
  "vampire court member": [
    "Appendix Aulae Vampirus"
  ],
  "tolan": [
    "Tolan"
  ],
  "vigilant tolan": [
    "Vigilans Tolan"
  ],
  "arch-curate vyrthur": [
    "Archicuratus Vyrthur"
  ],
  "steel bolt": [
    "Sagittula Adamantea"
  ],
  "drain life": [
    "Hauri Vitam"
  ],
  "fura bloodmouth": [
    "Fura Sanguinos"
  ],
  "fura": [
    "Fura"
  ],
  "nightlord vampire": [
    "Vampira Noctidomina"
  ],
  "glow deer": [
    "Glow Deer"
  ],
  "holding cell": [
    "holding cell"
  ],
  "dimhollow crypt": [
    "Obscuracava Hypogaeum"
  ],
  "vampire touch": [
    "Vampire Touch",
    "Tactus Vampiri"
  ],
  "crossbow": [
    "Manuballista"
  ],
  "hairmalesnowelf": [
    "HairMaleSnowElf"
  ],
  "vampire lord form": [
    "Forma Dominorum Vampirorum"
  ],
  "rargal thrallmaster": [
    "Rargal Animivilicus"
  ],
  "return to <alias=questgiver>": [
    "Reveni ad <Alias=QuestGiver>"
  ],
  "i heard you might need help": [
    "Audivi te, fortasse, auxilio carere"
  ],
  "missing crest": [
    "Crusta Amissa"
  ],
  "know anything about a moth priest visiting dragon bridge": [
    "Scisne quid de Sacerdote Papilionis qui Draconipontem visitat"
  ],
  "enhanced crossbow schematic": [
    "Diagramma Manuballistae Melioris"
  ],
  "how did you end up here": [
    "Quomodo hic venisti"
  ],
  "recruit sorine jurard": [
    "Invita Sorine Jurardem"
  ],
  "how do i turn her into a vampire": [
    "Quomodo eam in vampiram mutare possum"
  ],
  "ash vine": [
    "Vinea Cineris"
  ],
  "dwarven crossbow of stunning": [
    "Dvemeris Manuballista Pausandi"
  ],
  "you're right": [
    "Recte dicis"
  ],
  "let me think about it": [
    "De eo cogitabo",
    "Sine me de eo cogitare"
  ],
  "a jarl's justice": [
    "Ius Comitis"
  ],
  "jarl's justice": [
    "Ius Comitis"
  ],
  "kill someone out in the streets of <alias=city>": [
    "Neca aliquem in viis <Alias=City>"
  ],
  "you can find us at fort dawnguard, near stendarr's beacon": [
    "Cum nobis apud Castellum Aurorae Custodum, Prope Lumen Shoris conveni"
  ],
  "boneman": [
    "Ossivir"
  ],
  "you're staying here": [
    "Hic manebis"
  ],
  "supernatural reflexes": [
    "Reflexiones Supernaturales"
  ],
  "icewater jetty": [
    "Glaciaquae Navale"
  ],
  "then you may begin your reading": [
    "Deinde legere incipe"
  ],
  "conjure gargoyle": [
    "Voca Gargulion"
  ],
  "werewolf beastmaster": [
    "Versipellis Bestiadominus",
    "Versipellis Bestimagister"
  ],
  "where do you want to go": [
    "Quo ire vis"
  ],
  "rings of blood magic": [
    "Anulae Magicae Sanguinis"
  ],
  "exploding dwarven bolt of shock": [
    "Sagittula Dvemeris Electrisplodens"
  ],
  "i don't need you to do anything": [
    "Nihil facere debes",
    "Nihil e te nunc volo"
  ],
  "drain vitality": [
    "Hauri Vitalitatem"
  ],
  "reverent dwarven crossbow": [
    "Reverens Dvemeris Manuballista"
  ],
  "gargoyle abilities": [
    "Gargoyle Abilities"
  ],
  "blessed crossbow": [
    "Manuballista Sanctificata"
  ],
  "defeat stalf and salonia": [
    "Supera Stalf Saloniamque"
  ],
  "protecting the bloodline": [
    "Servans Lineam Sanguinis"
  ],
  "how can we get in without your father noticing": [
    "Quomodo intrare possum e visu patris"
  ],
  "the ingredients are in the vessel": [
    "Ingredientia in burrancio sunt"
  ],
  "ingredients are in the vessel": [
    "Ingredientia in burrancio sunt"
  ],
  "(optional) choose a high profile victim": [
    "(Optio) Elige victimam nobilem"
  ],
  "speak with garan marethi": [
    "Adloquere Garan Marethi"
  ],
  "reach the summit of arkngthamz": [
    "Veni ad summum Arkngthamz"
  ],
  "fire ball": [
    "Ignipila"
  ],
  "why are we speaking": [
    "Cur loquimur"
  ],
  "dwarven crossbow": [
    "Dvemeris Manuballista"
  ],
  "i've collected all the rings of blood magic": [
    "Omnes Anulos Magicae Sanguinis inveni"
  ],
  "dwarven centurion abilities": [
    "Dvemer centurion abilities",
    "Dwemeris Centurio abilities"
  ],
  "dwarven crossbow of malediction": [
    "Dvemeris Manuballista Maledicionis"
  ],
  "speak to valerica": [
    "Adloquere Valericam"
  ],
  "dlc01 soul cairn boneman fx": [
    "DLC01 Soul Cairn Boneman FX"
  ],
  "i've brought the elder scrolls": [
    "Codices Veterrimos tuli"
  ],
  "magicka": [
    "Magicae",
    "Magicka"
  ],
  "night shrine": [
    "Ara Noctis"
  ],
  "femaleheadnordvampire": [
    "FemaleHeadNordVampire"
  ],
  "explore castle volkihar's courtyard": [
    "Investiga Aream Castelli Volkiharis"
  ],
  "what do we do now": [
    "Quid nunc faciamus",
    "Quid nunc faciemus"
  ],
  "perhaps you care to explain this letter then": [
    "Fortasse hanc epistulam explicare vis"
  ],
  "in castle volkihar": [
    "In Castello Volkiharis"
  ],
  "finished": [
    "Factumst"
  ],
  "traveling bard": [
    "Cantator Peregrinans"
  ],
  "ruunvald excavation": [
    "Ruunvald Excavation"
  ],
  "why am i able to bestow the gift of harkon's blood": [
    "Cur donum Sanguinis Harkonis donare possum"
  ],
  "troll armor": [
    "Troll Armour"
  ],
  "zombie": [
    "Zombie"
  ],
  "sun bane": [
    "Sol Inimicus"
  ],
  "speak to the innkeeper in solitude": [
    "Adloquere Tabernarium Solitudini"
  ],
  "snow elves": [
    "Alfi Nivosi"
  ],
  "you're a falmer": [
    "Falmeris es"
  ],
  "drain effect to look for targets": [
    "Drain Effect To Look For Targets"
  ],
  "why did you come here": [
    "Cur hic venisti"
  ],
  "you said you didn't know where she went": [
    "Dixisti te nescire quo eam ivisse"
  ],
  "auriel's shield knockback": [
    "Auriel's Shield Knockback"
  ],
  "dlc1vq04hellos": [
    "DLC1VQ04Hellos"
  ],
  "vampire claws": [
    "Ungues Vampirici"
  ],
  "deceiving the herd": [
    "Decipiens Pecu"
  ],
  "it sounds like she was pretty selfish": [
    "Mihi videtur illam egotisticam fuisse"
  ],
  "glowing crystal shard": [
    "Fragmentum Crystalli Fulgens"
  ],
  "poison talons": [
    "Ungues Venenati"
  ],
  "just get to it": [
    "Tandem Aliquand, modo dic"
  ],
  "skyforge steel dagger": [
    "Fabricaeli Pugio",
    "Fabricaeli Pugio Adamanteus"
  ],
  "sun blast": [
    "Sun Blast"
  ],
  "troll, follow me": [
    "Trellis, sequere me"
  ],
  "exploding steel bolt of ice": [
    "Sagittula Adamantea Gelidisplodens"
  ],
  "dlc1redwaterskoomascripteffect": [
    "DLC1RedwaterSkoomaScriptEffect"
  ],
  "vampire's thrall": [
    "Servus Vampiri"
  ],
  "why were you locked away": [
    "Cur hoc modo incarceratus es"
  ],
  "conjure dwarven sphere": [
    "Voca Spheram Dvemerem"
  ],
  "kill the <alias=vampire> at <alias=dungeon>": [
    "Neca <Alias=Vampire> in <Alias=Dungeon>"
  ],
  "summon werewolves 3": [
    "Summon Werewolves 3"
  ],
  "zephyr": [
    "Zephyrus"
  ],
  "etherealize": [
    "Etherealise"
  ],
  "make me a healer": [
    "Make Me A Healer"
  ],
  "those are my only options": [
    "Haec sunt optiones solae"
  ],
  "r3": [
    "R3"
  ],
  "so... what now": [
    "Sic... quid nunc"
  ],
  "can anything be done to help you": [
    "Estne quid faciendum pro morbo tuo"
  ],
  "heard anything about a moth priest passing through": [
    "Audivistine de Sacerdote Papilionis per urbem progrediente"
  ],
  "investigate the scene of the attack": [
    "Investiga scaenam opugnationis"
  ],
  "incriminating letter": [
    "Epistula Criminalis"
  ],
  "animal vigor": [
    "Vigor Animalis"
  ],
  "stendarr's aura": [
    "Aura Shoris"
  ],
  "speak to durnehviir at his lair in the soul cairn": [
    "Adloquere Durnehviir in Cubile in Terra Animorum"
  ],
  "vampiric drain": [
    "Sanguinem Bibere",
    "Exhaustum Vampiricum"
  ],
  "what do you mean": [
    "Quid dicis",
    "De quo loqueris",
    "De qua re loqueris"
  ],
  "draw knife": [
    "Culter Sumendi"
  ],
  "soul embers": [
    "Cinera Animorum"
  ],
  "a new order": [
    "Novus Ordo"
  ],
  "new order": [
    "Novus Ordo"
  ],
  "hiding with the dawnguard": [
    "Cum Aurorae Custodibus Celans"
  ],
  "i've been keeping her safe": [
    "Eam tuitus sum"
  ],
  "dwarven crossbow of binding": [
    "Dvemeris Manuballista Capiendi"
  ],
  "investigate the ruins of arkngthamz": [
    "Investiga ruinas Arkngthamz"
  ],
  "dwarven crossbow of depleting": [
    "Dvemeris Manuballista Deplendi"
  ],
  "you were hunting": [
    "Venabaris"
  ],
  "undead targets takes <mag> points of sun damage,": [
    "Amortui <mag> punctas vulneris solis accipiunt,"
  ],
  "chaurus hunter": [
    "Chaurus Venator"
  ],
  "this is your home": [
    "Haec est domus tua"
  ],
  "what exactly are we looking for": [
    "Quid, exacte, quaerimus"
  ],
  "i don't suppose there's a cure": [
    "Estne, fortasse, remedium"
  ],
  "slow effect": [
    "Effectum Lentum"
  ],
  "who were your people": [
    "Qui erant homines tui"
  ],
  "amulet of bats": [
    "Monile Vespertilionum"
  ],
  "where is he now": [
    "Ubi est ille nunc"
  ],
  "throne": [
    "Solium",
    "thronus, thronī"
  ],
  "so where is this elder scroll": [
    "Ergo ubi est hic Codex Veterrimus"
  ],
  "enter the soul cairn": [
    "Intra Terram Animorum"
  ],
  "hallowed dwarven crossbow": [
    "Hallowed Dvemeris Manuballista"
  ],
  "i have more pressing matters at the moment": [
    "Res maioris momenti hoc in tempore habeo"
  ],
  "do you have any ideas": [
    "Habesne quas sententias"
  ],
  "as a werewolf, the inventory button will bring up the werewolf perk tree": [
    "Ut versipellis, boto inventorii Arborem Peritiorum Versipellis aperit"
  ],
  "perks are earned by eating corpses": [
    "Peritia mortes consumendo augentur"
  ],
  "prelate nirilor": [
    "Prelatus Nirilor"
  ],
  "ring of vampiric grip": [
    "Anulus Tensurae Consumendi"
  ],
  "what can i do to help": [
    "Quomodo adiuvare possim",
    "Quale auxilium tibi dare possum"
  ],
  "dwemer exploding ice bolt schematic": [
    "Diagrammae Sagittularum Dvemerium Glacisplosium"
  ],
  "we could use your help in tamriel": [
    "Auxilio tuo in Tamriele uti possumus"
  ],
  "that hurt": [
    "Mihi nocuit"
  ],
  "i command you to travel to castle volkihar": [
    "Tibi impero ut ad Castllum Volkiharem iter facias"
  ],
  "dragon bite": [
    "Dragon Bite",
    "Morsus Draconis"
  ],
  "vampire lord armor": [
    "Vampiri Dominus Lorica"
  ],
  "vampire's note": [
    "Epistula Vampiri"
  ],
  "keeper": [
    "Clavicarius",
    "cōnservātor, cōnservātōris"
  ],
  "i don't know what she was thinking": [
    "Nescio de qua cogitaverit"
  ],
  "ice barrier for snow elf fight": [
    "Ice Barrier for Snow Elf Fight"
  ],
  "my business is my own": [
    "Negotium est meum"
  ],
  "just tell me what i need to know": [
    "Dic mihi quid scire velim"
  ],
  "ancient technology": [
    "Scientia Antiqua"
  ],
  "empty wine bottle": [
    "Lagoena Vacua Vini",
    "Vacua Vini Lagoena"
  ],
  "(optional) visit the college of winterhold to ask about the moth priest": [
    "(Optio) Visita Collegium Hiemiterrae ut roges de Sacerdote Papilionis"
  ],
  "can you train me in restoration": [
    "Potsene mihi plus de Magica Salutis docere"
  ],
  "i'm just here for the elder scroll": [
    "Modo hic sum Codice Veterrimo"
  ],
  "werewolf howls calling for companions": [
    "Ululatio quae amicos vocat",
    "Ululatio Versipelium quae amicos vocat"
  ],
  "aetherial staff": [
    "Rudis Aetherius"
  ],
  "gargoyle": [
    "Gargoyle"
  ],
  "elder scroll (dragon)": [
    "Codex Veterrimus (Draco)"
  ],
  "hunting the monster": [
    "Monstruum Venans"
  ],
  "femaleheadwoodelfvampire": [
    "FemaleHeadWoodElfVampire"
  ],
  "dawnguard boots": [
    "Aurorae Custodum Caligae"
  ],
  "i'll get rough with you if i have to": [
    "Res ad pugna venire possunt, si vis"
  ],
  "babette on the road near a body": [
    "Babette on the Road Near a Body"
  ],
  "vale sabre cat": [
    "Felis Mucronis Vallis"
  ],
  "and if harkon failed": [
    "Etsi Harkon Falleret"
  ],
  "soul cairn horse quest": [
    "Soul Cairn Horse Quest"
  ],
  "search along the road that leads south from dragon bridge": [
    "Investiga viam ad australem Pontis Draconis"
  ],
  "ruunvald temple": [
    "Ruunvaldis Templum"
  ],
  "follow valerica": [
    "Sequere Valericam"
  ],
  "dwemer exploding shock bolt schematic": [
    "Diagrammae Sagittularum Dvemerium Electricorum"
  ],
  "moth priest blindfold": [
    "Sacerdotis Papilionis Adabatus"
  ],
  "footwraps": [
    "Emporeticus Pedis",
    "Pedum Emporeticus"
  ],
  "bestial strength": [
    "Vis Bestiae"
  ],
  "volk's journal": [
    "Ephemeris Volkis"
  ],
  "ancestor moth": [
    "Papilio Maiorum"
  ],
  "where would a moth priest actually go": [
    "Quo vaderet Sacerdos Papilions"
  ],
  "slay durnehviir": [
    "Neca Durnehviir"
  ],
  "i was sent to retrieve something from this cave": [
    "Missus sum ut aliquid de hac cava acciperem"
  ],
  "crossbow of arcing": [
    "Manuballista Arcuum"
  ],
  "audiotemplatechaurushunter": [
    "AudioTemplateChaurusHunter"
  ],
  "only equipped in the left hand": [
    "Solum manu laeva",
    "Modo sinistra manu"
  ],
  "what do they resemble": [
    "Cui similis videntur"
  ],
  "become a vampire": [
    "Muta in vampirum"
  ],
  "drain vitality - health": [
    "Remove Vitalitatem - Salus"
  ],
  "then we're finished": [
    "Ergo actum est de nobis"
  ],
  "raise dead visual": [
    "Raise Dead Visual"
  ],
  "drain health": [
    "Drain Health",
    "Absorbe Salutem"
  ],
  "ask people in dragon bridge if they saw the moth priest": [
    "Roga homines Draconi Ponti an Sacerdotem Papilionis viderint"
  ],
  "anything you can tell me about the soul cairn": [
    "Estne quid quod mihi de Terra Animorum narrare potes"
  ],
  "does it work": [
    "Fungiturne"
  ],
  "gargoyle brute": [
    "Gurgulio Brutum"
  ],
  "yes, i have them": [
    "Sic, ea habeo"
  ],
  "what now": [
    "Quid nunc"
  ],
  "i've destroyed the <alias=vampire> at <alias=dungeon>": [
    "Necavi <Alias=Vampire> ad <Alias=Dungeon>"
  ],
  "as a vampire, people may comment on your unusual appearance, but they don't ever attack you or shun you like they do the werewolf": [
    "Ut vampirus, homines fortasse de visu tuo commemorent, sed te non oppugnent ut versipellem"
  ],
  "the tyranny of the sun requires serana's blood": [
    "Tyrannia Solis sanguinem Seranae postulat"
  ],
  "tyranny of the sun requires serana's blood": [
    "Tyrannia Solis sanguinem Seranae postulat"
  ],
  "no, you're right": [
    "Recte dicis",
    "Minime, recte dicis"
  ],
  "we just have to convince the others you're on our side": [
    "Modo aliis suadere debemus te in grege nostra esse"
  ],
  "what do you do": [
    "Quid facis"
  ],
  "retrieve the <alias=remains> from <alias=dungeon>": [
    "Refer <Alias=Remains> ab <Alias=Dungeon>"
  ],
  "do you trust harkon at all": [
    "Num Harkoni confidis"
  ],
  "i'll meet you back there": [
    "Tecum illic conveniam"
  ],
  "crossbow of soul snares": [
    "Manuballista Animicapti"
  ],
  "i killed them both": [
    "Ambos necavi"
  ],
  "sabre cat glow fx": [
    "Sabre Cat Glow FX"
  ],
  "speak to serana": [
    "Adloquere Seranam"
  ],
  "sounds like she was being cautious": [
    "Mihi videtur eam cautiosam fuisse"
  ],
  "aetherium shard": [
    "Fragmentum Aetherii"
  ],
  "withered tree": [
    "Arbor Debilis"
  ],
  "morven stroud": [
    "Morven Stroud"
  ],
  "it's impressive": [
    "Speciosum est"
  ],
  "follow garan": [
    "Sequere Garan"
  ],
  "valerica's study": [
    "Tablinum Valericae"
  ],
  "just call your name in tamriel": [
    "Modo nomen tuum in Tamriel dicam"
  ],
  "that's it": [
    "Hoc est omne",
    "Suntne omnia"
  ],
  "femaleheaddarkelfvampire": [
    "FemaleHeadDarkElfVampire"
  ],
  "i'm looking for a vampire": [
    "Vampirum quaero"
  ],
  "for <dur> seconds, undead in melee range take <10> points sun damage per second": [
    "<dur> secundas, amortui prope te <10> puncta vulneris solis singulis secundis accipiunt"
  ],
  "aetherium crest": [
    "Signum Aetherium"
  ],
  "plant letter on <alias=victim>'s corpse": [
    "Pone epistulam in corpore <Alias=Victim>"
  ],
  "what are you talking about": [
    "De qua re loqueris"
  ],
  "what's on your mind": [
    "Quid tibi in animo est"
  ],
  "what's all this have to do with the dawnguard": [
    "Quid agunt haec omnia cum Aurorae Custodibus"
  ],
  "discreetly kill <alias=target>": [
    "Neca <Alias=Target> Clam"
  ],
  "i am": [
    "Sum",
    "Volo"
  ],
  "i will accept your gift and become a vampire": [
    "Donum tuum accipiam et vampirus fiam"
  ],
  "salvatore's test cell": [
    "Salvatore's Test Cell"
  ],
  "portal vessel": [
    "Doga Portae"
  ],
  "gather purified void salts": [
    "Collige salia chaos purificata"
  ],
  "vortex effect": [
    "Vortex Effect"
  ],
  "snowy ice vine": [
    "Vinea Glaciei Nivosa"
  ],
  "(optional) make it a public kill while wearing dawnguard armor": [
    "(Optio) fac ut nex publicum sit dum loricam Aurorae Custodes induens"
  ],
  "it sounds like she did everything for your sake": [
    "Mihi videtur illam omni pro te fecisse"
  ],
  "this should not be visible": [
    "This should not be visible"
  ],
  "femaleheadorcvampire": [
    "FemaleHeadOrcVampire"
  ],
  "summons a dwarven spider for <dur> seconds wherever the caster is pointing": [
    "Araneam Dvemerem <dur> secundas ubi demonstras vocat"
  ],
  "it is done": [
    "Factumst"
  ],
  "i've slain someone on the streets of <alias=city>": [
    "Aliqum in viis <Alias=City> necavi"
  ],
  "turn <alias=candidate> into a vampire": [
    "Muta <Alias=Candidate> in vampirum"
  ],
  "dlc1 vampire base scene 5": [
    "DLC1 Vampire Base Scene 5"
  ],
  "volkihar balcony": [
    "Ectheta Volkiharis"
  ],
  "summons a dwarven sphere for <dur> seconds wherever the caster is pointing": [
    "Sphera Dvemerem <dur> secundas ubi demonstras vocat"
  ],
  "i'd like to buy an armored troll": [
    "Trellem loricatam emere volo"
  ],
  "(<global=dlc1trollcost> gold)": [
    "(<Global=DLC1TrollCost> auri)"
  ],
  "i think i could use your help": [
    "Credo me auxilio tuo uti posse"
  ],
  "rescue the moth priest": [
    "Adiuva Sacerdotem Papilionis"
  ],
  "legendary dragon": [
    "Draco Legendarius"
  ],
  "so i need to fill this at each wayshrine": [
    "Ergo hoc ad omnes aras viarum complere debeo"
  ],
  "we're up against vampires": [
    "Contra vampiros pugnamus"
  ],
  "what happened to this place": [
    "Quid ad hunc locum accidit"
  ],
  "meet tolan at stendarr's beacon": [
    "Conveni Tolan ad Signum Shoris"
  ],
  "savage feeding": [
    "Saeve Vescens"
  ],
  "someone you don't want to see": [
    "Estne quis quem videre non vis"
  ],
  "locate arch-curate vyrthur": [
    "Inveni Archicuratum Vyrthur"
  ],
  "search along the road south of dragon bridge": [
    "Quaere viam ad Australem Pontis Draconis"
  ],
  "absorb health": [
    "Absorbe Salutem"
  ],
  "where'd you say this vampire was hiding": [
    "Ubi vampirum se celaret dixisti"
  ],
  "deer glow fx": [
    "Deer Glow FX"
  ],
  "dawnguard armor": [
    "Aurorae Custodum Lorica"
  ],
  "sealed away like you were": [
    "Incarcerata ut tu"
  ],
  "imperial deserter": [
    "Defector Romanus"
  ],
  "name tbd": [
    "Name TBD"
  ],
  "maleheadhighelfvampire": [
    "MaleHeadHighElfVampire"
  ],
  "auriels bow beam effects": [
    "Auriels Bow Beam Effects"
  ],
  "ring of reanimate corpse": [
    "Anulus Corporis Reanimandi"
  ],
  "dwarven crossbow of the blaze": [
    "Dvemeris Manuballista Conflagrandi"
  ],
  "what will happen to you now": [
    "Quid tibi nunc accidet"
  ],
  "vampiric grip": [
    "Prehensus Vampiricus"
  ],
  "sharpslope cave": [
    "Acerclivis Spelunca"
  ],
  "do you have the elder scroll with you": [
    "Habesne Codicem Veterrimum tecum"
  ],
  "please, we need your help": [
    "Auxilium tuum quaerimus, quaeso"
  ],
  "what sort of attention": [
    "Qualis animadversio"
  ],
  "they tried to kill me": [
    "Me necare temptaverunt"
  ],
  "where can i find the people we need": [
    "Ubi homines necessarios invenire possumus"
  ],
  "gleamblossom": [
    "Luciflorem"
  ],
  "zol": [
    "Zol"
  ],
  "ancestor glade": [
    "Nemus Maiorum"
  ],
  "retrieve the <alias=item2> from <alias=location2>": [
    "Refer <Alias=Item2> ab <Alias=Location2>"
  ],
  "crossbow of embers": [
    "Manuballista Cinerum"
  ],
  "power of the grave": [
    "Potestas Sepulchri"
  ],
  "it's important that i find him right away. (persuade)": [
    "Eum statim invenire magni momenti est. (Persuade)"
  ],
  "inner sanctum": [
    "Cella Interior",
    "Sanctum Interius"
  ],
  "dlc1 vampire base scene 2": [
    "DLC1 Vampire Base Scene 2"
  ],
  "we expected some kind of artifact": [
    "De aliquo artefacto cogitabamus"
  ],
  "are you saying that i need to become a vampire": [
    "Dicisne me vampirum fiere debere"
  ],
  "fools": [
    "Stulti"
  ],
  "why do you say that": [
    "Quamobrem"
  ],
  "didn't you try to kill me": [
    "Nonne me necare temptavisti"
  ],
  "dwarven crossbow schematic": [
    "Diagrammae Manuballistae Dvemeris"
  ],
  "ragged trousers": [
    "Bracae Fragosae",
    "Braccae Fragosae"
  ],
  "your name sounds familiar": [
    "Nomen tuum familiare videtur"
  ],
  "reflecting shield": [
    "Scutum Repulsus"
  ],
  "i guess we'll have to keep looking for that moth priest, then": [
    "Credo nos Sacerdotem Papilionis iam quaerere debere"
  ],
  "preemptive strike": [
    "Ictus Anterior"
  ],
  "l4": [
    "L4"
  ],
  "how did you become imprisoned here": [
    "Quomodo hic incarcerata es"
  ],
  "volkihar north tower": [
    "Volkiharis Turris Septentrionalis"
  ],
  "what do these amulets do": [
    "Quid faciunt haec monilia"
  ],
  "are there other vigilants out there": [
    "Suntne alii Vigilantes foris"
  ],
  "if she made it there, we'll find her": [
    "Si illic advenit, eam inveniams"
  ],
  "i am married": [
    "Maritus sum"
  ],
  "death hound abilities": [
    "Death Hound abilities"
  ],
  "what was the bargain": [
    "Quid erat pactum"
  ],
  "ancient vampire hands": [
    "Manus Vampiri Antiqui"
  ],
  "the betrayed weren't to blame": [
    "Culpa Proditorum non erat"
  ],
  "betrayed weren't to blame": [
    "Culpa Proditorum non erat"
  ],
  "necro skeleton ability": [
    "Necro Skeleton Ability"
  ],
  "serana underwent this ritual willingly": [
    "Serana hanc religionem sua sponte passa est"
  ],
  "femaleheadhighelfvampire": [
    "FemaleHeadHighElfVampire"
  ],
  "what makes you say that": [
    "Cur hoc dicis"
  ],
  "darkfall passage": [
    "Commeatus Obscuracasus"
  ],
  "become partially soul trapped": [
    "Relinque partem animi"
  ],
  "you wanted to speak with me": [
    "Mecum loqui voluisti"
  ],
  "i don't trust things that try to kill me": [
    "Rebus quae me necare temptant non credo"
  ],
  "wrathman": [
    "Vir Iracundiae"
  ],
  "use your vampire's seduction power on the moth priest": [
    "Uti potestate Seductionis Vampiri in Sacerdotem Papilionis"
  ],
  "light beam": [
    "Light Beam",
    "Radius Lucis"
  ],
  "speak to isran": [
    "Adloquere Isran"
  ],
  "soul visuals": [
    "Soul Visuals"
  ],
  "is that why you're free now": [
    "Hancobrem nunc libertus es"
  ],
  "for <dur> seconds, opponents in melee range take <mag> points frost damage and stamina damage per second": [
    "<dur> secundas, inimici prope te accipiunt <mag> vulneris et glaciei et staminae singulis secundis",
    "For <dur> seconds, opponents in melee range take <mag> points frost damage and stamina damage per second"
  ],
  "he simply said \"it is time.\"": [
    "Modo dixit \"Tempus est.\""
  ],
  "valerica": [
    "Valerica"
  ],
  "blood healing": [
    "Recuperans Sanguine"
  ],
  "what are you doing": [
    "Quid facis"
  ],
  "are you able to give us the scroll now": [
    "Potesne codicem nobis nunc dare"
  ],
  "<mag> points of damage for <dur> seconds": [
    "<mag> vulneris <dur> secundas",
    "<mag> puncta vulneris <dur> secundas"
  ],
  "ghost visuals": [
    "Ghost Visuals"
  ],
  "warn <alias=jarl> about the vampire threat": [
    "Mone <Alias=Jarl> de comminatione vampiri"
  ],
  "ice storm": [
    "Tempestas Glaciei"
  ],
  "dead vine": [
    "Vinea Mortua"
  ],
  "kill the <alias=vampire>": [
    "Neca <Alias=Vampire>"
  ],
  "auriels bow sky bolt": [
    "Auriels Bow Sky Bolt"
  ],
  "maleheadredguardvampire": [
    "MaleHeadRedguardVampire"
  ],
  "dead drop falls": [
    "Morticasus Casum"
  ],
  "no tile": [
    "No Tile"
  ],
  "frozen falmer": [
    "Falmeris Congelatus"
  ],
  "why was your mother so fascinated by it": [
    "Cur mater tantum ei studuit"
  ],
  "bloodline": [
    "Linea Sanguinis"
  ],
  "fire breath": [
    "Fire breath",
    "Flatus Ignis",
    "Exhalatio Ignis",
    "Flatus Flammarum"
  ],
  "you can meet me at fort dawnguard": [
    "Mecum apud Castellum Aurorae Custodum convenire potes"
  ],
  "frost breath": [
    "Exhalatio Glaciei",
    "Frigiflatus",
    "Frost breath"
  ],
  "we're not sure, but isran is concerned": [
    "Nescimus, sed Isran sollicitur"
  ],
  "bloodstone chalice": [
    "Calix Sanguilapidis"
  ],
  "chaurusflyer": [
    "Chaurus Volator"
  ],
  "you are in danger": [
    "In perico es",
    "In periculo es"
  ],
  "a vampire has infiltrated your court": [
    "Vampirus in aula tua latet",
    "Vampirus aulam tuam clam latet"
  ],
  "vampire has infiltrated your court": [
    "Vampirus in aula tua latet",
    "Vampirus aulam tuam clam latet"
  ],
  "(give letter)": [
    "(Da epistulam)"
  ],
  "isran and i still need your help": [
    "Isran et ego iam auxilium tuum quaerimus"
  ],
  "feral falmer": [
    "Falmeris Ferus"
  ],
  "frost cloak - death hound": [
    "Frost Cloak - Death Hound"
  ],
  "dlc1 vampire intro quest enemy faction": [
    "DLC1 Vampire Intro Quest Enemy Faction"
  ],
  "speak with <alias=currentquestgiver> for an assignment": [
    "Adloquere <Alias=CurrentQuestGiver> pro munere"
  ],
  "forget me, who are you": [
    "Obliviscere mei, quis es tu"
  ],
  "soul cairnkeeperhead fx": [
    "Soul CairnKeeperHead FX"
  ],
  "they tricked you": [
    "Ludos tibi fecerunt"
  ],
  "exploding dwarven bolt of fire": [
    "Sagittula Dvemeris Displodens"
  ],
  "enhanced dwarven crossbow schematic": [
    "Diagramma Manuballistae Dvemeris Melioris"
  ],
  "vampire boots": [
    "Vampiri Caligae"
  ],
  "no bolts equipped": [
    "Nullas Sagittulas habes"
  ],
  "kill the <alias=boss> at <alias=dungeon>": [
    "Neca <Alias=Boss> in <Alias=Dungeon>"
  ],
  "gather canticle tree bark": [
    "Cape Corticam Arboris Canticlis"
  ],
  "are you alright": [
    "Esne bene"
  ],
  "retrieve the <alias=item1> from <alias=location1>": [
    "Refer <Alias=Item1> a <Alias=Location1>"
  ],
  "retrieve the elder scroll": [
    "Refer Codicem Veterrimum"
  ],
  "detect life - predator combat": [
    "Detect Life - Predator Combat"
  ],
  "how long were you in there": [
    "Quamdiu illic fuisti"
  ],
  "summon ice wolves 2": [
    "Summon Ice Wolves 2"
  ],
  "dragonsreach": [
    "Draconicarcer"
  ],
  "frost atronach": [
    "Atronachum Glaciei",
    "Atronachum Glacierum"
  ],
  "let me show you what i need": [
    "Sine me te monstrare quo caream",
    "Sine me demonstrare id quod quaero"
  ],
  "creates a hovering light that lasts for <dur> seconds": [
    "Lux volans quae <dur> secundas manet",
    "Creates a hovering light that lasts for <dur> seconds"
  ],
  "what happened to the dragon": [
    "Quid draconi accidit"
  ],
  "(optional) retrieve katria's journal": [
    "(Option) Refer Ephemeridem Katriae"
  ],
  "summon arvak": [
    "Voca Arvak"
  ],
  "and that's any of your business because... ": [
    "Et cum te agitur quod"
  ],
  "steel shin boots": [
    "Cruris Adamantei Caligae",
    "Caligae Crurales Adamanteae"
  ],
  "speak to gelebor": [
    "Adloquere Gelebor"
  ],
  "maleheadnordvampire": [
    "MaleHeadNordVampire"
  ],
  "ring of corpse curse": [
    "Anulus Maledictionis Corporis"
  ],
  "here, i've got a dwarven gyro": [
    "Agedum, Choragium Dvemere habeo"
  ],
  "take it": [
    "Cape id",
    "Cape eam",
    "Cape eum"
  ],
  "crossbow of burning": [
    "Manuballista Cremandi"
  ],
  "yes, i've been aiding tolan": [
    "Sic, Tolani auxilium dedi"
  ],
  "raise dead script effect": [
    "Raise Dead Script Effect"
  ],
  "what's wrong": [
    "Quid est",
    "Quid fit",
    "Quid agit",
    "Quid accidit"
  ],
  "make this easier for you, tell me what i want to know. (persuade)": [
    "Fac hoc tibi facilius. Dic mihi quid scire velim. (Persuade)"
  ],
  "tell me about your home": [
    "Dic mihi de domo tua"
  ],
  "what exactly are the ideal masters": [
    "Qui sunt Domini Ideales"
  ],
  "troll, get lost": [
    "Trellis, effuge"
  ],
  "is there anything i can do to help": [
    "Estne quid cum quo tibi auxilium dare possim"
  ],
  "ice vine": [
    "Vinea Glaciei"
  ],
  "crossbow of diminishing": [
    "Manuballista Diminuendi"
  ],
  "explain this \"ritual.\"": [
    "Exlica hanc \"religionem.\""
  ],
  "redwater skooma": [
    "Skooma Rubraquae"
  ],
  "so there's no way in, then": [
    "Ergo nulla via est"
  ],
  "poisoned talons do <mag> points of damage for <dur> seconds": [
    "Ungues Venenati <mag> punctas vulneris <dur> secundas faciunt"
  ],
  "find the elder scroll (blood)": [
    "Inveni Codicem Veterrimum (Sanguis)"
  ],
  "gargoyle statue": [
    "Statua Gargoylis"
  ],
  "i thought you were dead": [
    "Credidi te mortuum esse"
  ],
  "nearby living creatures, but not undead or daedra, can be seen through walls": [
    "Inimici viventes, nec amortui nec daedrae, per parietes videntur",
    "Viventes prope te, nec amortui nec daedrae, per parietes videri possunt"
  ],
  "dawnguard rune hammer": [
    "Aurorae Custodum Runatus Malleus"
  ],
  "null enchantment": [
    "Null Enchantment"
  ],
  "i don't think he knew much of anything": [
    "Credo eum fere nihil scivisse"
  ],
  "dexion": [
    "Dexion"
  ],
  "chaurus poison": [
    "Chaurus Poison",
    "Venenum Chauri"
  ],
  "you had a falling out": [
    "Inter vos vehementer dissensistis"
  ],
  "locate a moth priest": [
    "Inveni Sacerdotem Papilionis"
  ],
  "vampire mesmerize": [
    "Vampire Mesmerise"
  ],
  "they aren't going to let us use the front door": [
    "Illi nos ianua uti non sinent"
  ],
  "redwater spring": [
    "Aquae Rubrae Rivulus"
  ],
  "can you teach me to be a better marksman": [
    "Potsene mihi plus de Saggitatione docere"
  ],
  "and serana's opinion in this": [
    "Et opinio Seranae de hoc"
  ],
  "ancient falmer cuirass": [
    "Lorica Antiqua Falmeris"
  ],
  "so you're just going to give up": [
    "Ergo te modo succumbes"
  ],
  "dawnguard gauntlets": [
    "Aurorae Custodum Manubria"
  ],
  "ancient vampire leg": [
    "Crus Vampiri Antiqui"
  ],
  "dlc sun attacks self spell": [
    "DLC Sun Attacks Self Spell"
  ],
  "reanimate": [
    "Revoca",
    "Reanima"
  ],
  "runehammer ability": [
    "Runehammer Ability"
  ],
  "exploding dwarven bolt of ice": [
    "Sagittula Dvemeris Glacisplodens"
  ],
  "avoid death": [
    "Evita Mortem"
  ],
  "enter the column of light and read the elder scroll (blood)": [
    "Intra columnum lucis et lege Codicem Veterrimum (Sanguis)"
  ],
  "castle volkihar balcony": [
    "Castelli Volkiharis Ektheta"
  ],
  "what's stopping you": [
    "Quid te sistit"
  ],
  "darkfall cave": [
    "Obscuracasus Spelunca"
  ],
  "dlc1 vampire base scene 3": [
    "DLC1 Vampire Base Scene 3"
  ],
  "dlc01 soul cairn necro skeleton fx": [
    "DLC01 Soul Cairn necro skeleton fx"
  ],
  "reanimate corpse": [
    "Revoca Corpus",
    "Reanimate Corpse"
  ],
  "how did you know": [
    "Quomodo scivisti"
  ],
  "withered shrub": [
    "Frutex Debilis"
  ],
  "read the vampire's note": [
    "Lege Epistulam Vampiri"
  ],
  "boneyard key": [
    "Clavis Necropoli"
  ],
  "dwemer exploding fire bolt schematic": [
    "Diagrammae Sagittularum Dvemerium Ignisplosium"
  ],
  "just tell me how much it will cost": [
    "Modo dic mihi quot constet"
  ],
  "chillwind depths": [
    "Ima Ventifrigoris",
    "Imus Frigidiventi"
  ],
  "how long will that take": [
    "Quantum temporis consumet"
  ],
  "i can handle myself": [
    "Pugnare possum",
    "Me tegere possum"
  ],
  "take the bloodstone chalice to redwater spring": [
    "Adfer Calicem Sanguilapidis ad Rivulum Rubraquae"
  ],
  "runeshield ability": [
    "RuneShield Ability"
  ],
  "speak with isran": [
    "Adloquere Isran"
  ],
  "vigilant remains (08 / western caches)": [
    "Vigilant Remains (08 / Western Caches)"
  ],
  "search for the aetherium shards (<global=dlc1ld_shardsfound>/<global=dlc1ld_shardstotal>)": [
    "Quaere Fragmenta Aetherii (<Global=DLC1LD_ShardsFound>/<Global=DLC1LD_ShardsTotal>)"
  ],
  "health regenerates <mag>% faster for <dur> seconds": [
    "Salus <mag>% celerius recuperat <dur> secundas",
    "Salus <mag>% celerius recuperatur <dur> secundas"
  ],
  "sunhallowed elven arrow": [
    "Sagitta Alfa Aurorae"
  ],
  "unearthly will": [
    "Voluntas Supernalis"
  ],
  "vampire base dialogue": [
    "Vampire Base Dialogue"
  ],
  "dlc1 vampire base scene 6": [
    "DLC1 Vampire Base Scene 6"
  ],
  "soul gem shards": [
    "Fragmenta Gemmanimorum"
  ],
  "stone monolith": [
    "Monolithos Lapideus"
  ],
  "speak to the moth priest": [
    "Adloquere Sacerdotem Papilionis"
  ],
  "aetherium forge": [
    "Aetherii Fabrica",
    "The Aetherium Forge"
  ],
  "speak with katria": [
    "Adloquere Katriam"
  ],
  "isran asked me to find you": [
    "Isran me oravit ut tibi invenirem"
  ],
  "turn into vampire": [
    "In Vampirum muta"
  ],
  "redwater den backrooms key": [
    "Calvis Conclavia ad Tergum Cubilis Rubraquae"
  ],
  "pack member": [
    "Pars Gregis"
  ],
  "elder scroll (blood)": [
    "Codex Vestustissimus (Sanguis)"
  ],
  "vampire royal armor": [
    "Vampiri Regalis Lorica"
  ],
  "return to isran": [
    "Reveni ad Isran"
  ],
  "we have to do something": [
    "Aliquid nobis faciendum est"
  ],
  "find the aetherium forge": [
    "Inveni Fabricam Aetherii"
  ],
  "why weren't the snow elves here affected": [
    "Quamobrem Alfi Nivosi hic non affecti sunt"
  ],
  "amulet of mist form": [
    "Monile Formae Nexus"
  ],
  "move to boneyard": [
    "Move ad Necropolin"
  ],
  "what are you doing here": [
    "Quid hic facis"
  ],
  "that's not really your business": [
    "Nihil tecum agitur"
  ],
  "<alias=spouse> has been welcomed into the family": [
    "<Alias=Spouse> pars familiae nunc est"
  ],
  "dawnguard heavy armor": [
    "Aurorae Custodum Gravis Lorica"
  ],
  "what did she research": [
    "In qua re operam dedit"
  ],
  "can i ask you something first": [
    "Possumne quid a te primum rogare"
  ],
  "give the rueful axe to clavicus vile or kill barbas with the rueful axe": [
    "Da Securem Rutae Clavico vel Barbam neca eo",
    "Da Securem Rutae Clavico VEL nec barbam Securi Rutae"
  ],
  "cleansing light": [
    "Lux Expurgans"
  ],
  "rope release": [
    "Solutum Funeris"
  ],
  "the player made me into a vampire": [
    "The player made me into a vampire"
  ],
  "player made me into a vampire": [
    "The player made me into a vampire"
  ],
  "if target dies within <dur> seconds, fills a soul gem": [
    "Si inimicum intra <dur> secundas moritur, gemmanima completur",
    "Si inimicus inter <dur> secondus morietur, gemmanimam complebit"
  ],
  "why do you call me \"qahnaarin?\"": [
    "Cur me \"Qahnaarin\" apellas"
  ],
  "virtuous dwarven crossbow": [
    "Virtutis Dvemeris Manuballista"
  ],
  "amulet of the empath": [
    "Monile Empathiae"
  ],
  "conjure dwarven spider": [
    "Voca Araneam Dvemerem"
  ],
  "retrieve auriel's bow": [
    "Refer Arcum Aurorae"
  ],
  "rogen": [
    "Rogen"
  ],
  "soul cairn dragon fight scenes": [
    "Soul Cairn Dragon Fight Scenes"
  ],
  "report your success to isran": [
    "Adfer nuntium sucessus Israni"
  ],
  "dragonplate armor": [
    "Draconilaminata Lorica"
  ],
  "dwarven crossbow of diminishing": [
    "Dvemeris Manuballista Diminuendi"
  ],
  "bloody tankard": [
    "Poculum Sanguinosum"
  ],
  "redwater den": [
    "Aquae Rubrae Cubile"
  ],
  "find the elder scroll (dragon)": [
    "Inveni Codicem Veterrimum (Draco)"
  ],
  "were you and valerica close": [
    "Fuistis tu Valerica propinqui"
  ],
  "claim the treasure of arkngthamz": [
    "Cape gazam Arkngthamz"
  ],
  "harkon": [
    "Harkon"
  ],
  "soul tear": [
    "Animifractor"
  ],
  "traveling pilgrim": [
    "Peregrinator"
  ],
  "what happened to the castle courtyard": [
    "Quid ad Peristylium Castelli accidit"
  ],
  "dlc1 vampire base scene 1": [
    "DLC1 Vampire Base Scene 1"
  ],
  "maleheaddarkelfvampire": [
    "MaleHeadDarkElfVampire"
  ],
  "then how are you sure they even exist": [
    "Quomode eos etiam exstare scis"
  ],
  "lost to the ages": [
    "Perditum Aetatibus"
  ],
  "raise dead": [
    "Revoca Mortuos"
  ],
  "identify the crystal shard": [
    "Disce de Fragmentum Crystalli"
  ],
  "you mean... the earthquake": [
    "De tremore loqueris"
  ],
  "why don't you come back with us": [
    "Quidni nobiscum revenias"
  ],
  "sunlight explosion that does <mag> points of damage in a 15 foot radius to undead": [
    "Diplosio Lucis Solis quae <mag> vulneris amortuis in area XV Pedum"
  ],
  "i'm the arch-mage": [
    "Archi-Magus sum"
  ],
  "it's 2,000 coins": [
    "MM aurei est"
  ],
  "v1z": [
    "V1z"
  ],
  "check for heal": [
    "Check For Heal"
  ],
  "desperate vampire seeking shelter from the sun": [
    "Desperate Vampire Seeking Shelter From the Sun"
  ],
  "moth priest's tools": [
    "Instrumentum Sacerdotis Papilionum"
  ],
  "yes, i'd like you to change my appearance": [
    "Sic, muta faciem meam"
  ],
  "(<global=dlc1surgerycost> gold)": [
    "(<Global=DLC1SurgeryCost> auri)"
  ],
  "she'll help us": [
    "Nos adiuvabit"
  ],
  "so you prefer living in the shadows": [
    "Ergo apud umbras vivere mavis"
  ],
  "bring evidence to <alias=jarl>": [
    "Fer evidentiam ad <Alias=Jarl>"
  ],
  "chaurus spit": [
    "Chaurus Spit"
  ],
  "factions init quest": [
    "Factions Init Quest"
  ],
  "mist form": [
    "Forma Nebulosa"
  ],
  "that's why we're headed there": [
    "Hac de causa illic petimus"
  ],
  "testrsears": [
    "TestRSears"
  ],
  "i've killed the dawnguard masquerading as a <alias=target>": [
    "Aurorae Custodem qui se <Alias=Target> esse finxit necavi"
  ],
  "talk to the face sculptor in the ragged flagon": [
    "Adloquere sculptorem facierum in Poculo Fragoso"
  ],
  "rune shield spell": [
    "Rune Shield Spell"
  ],
  "dwarven crossbow of fire": [
    "Dvemeris Manuballista Ignis"
  ],
  "do i have a choice": [
    "Habeone optionem"
  ],
  "aetherial shield": [
    "Scutum Aetheriale"
  ],
  "corpse curse": [
    "Maledictio Corporum"
  ],
  "soul rend damage": [
    "Soul Rend Damage"
  ],
  "soul cairn faction": [
    "Soul Cairn Faction"
  ],
  "why wouldn't you be safe there": [
    "Cur tu non tuta illic esses"
  ],
  "darkfall grotto": [
    "Obscuracasus Grotto"
  ],
  "what's this \"soul cairn\" that she mentions": [
    "Quid est \"Terra Animorum\" quem commemoravit"
  ],
  "the aetherium wars": [
    "Bella Aetherii"
  ],
  "aetherium wars": [
    "Bella Aetherii"
  ],
  "troll, wait": [
    "Trellis, exspecta"
  ],
  "dark chasm": [
    "Obscura Hiatus"
  ],
  "ancient vampire head": [
    "Caput Vampiri Antiqui"
  ],
  "you became trapped": [
    "Incarcerata facta es"
  ],
  "coldharbour": [
    "Fridiginavale"
  ],
  "ae soul drain": [
    "AE Soul Drain"
  ],
  "femaleheadimperialvampire": [
    "FemaleHeadImperialVampire"
  ],
  "what type of assistance do you need": [
    "Qaule auxilium vis"
  ],
  "testdlc1plantworld": [
    "TestDLC1PlantWorld"
  ],
  "drain blood": [
    "Sanguinem Bibe"
  ],
  "find evidence of the vampire's whereabouts": [
    "Inveni evidentiam loci vampiri"
  ],
  "lead serana to her home": [
    "Duc Serana domum"
  ],
  "dawnguard hunter": [
    "Venator Aurorae Custodum"
  ],
  "serana believes in me, why won't you": [
    "Serana mihi confidit, quid ni tu"
  ],
  "i've saved her from your foolish plan": [
    "Eam e consilio tuo stulto servavi"
  ],
  "a man named isran": [
    "Homo nomine Isran"
  ],
  "man named isran": [
    "Homo nomine Isran"
  ],
  "understood": [
    "Intellego"
  ],
  "blood shrine": [
    "Ara Sanguinis"
  ],
  "enhanced crossbow": [
    "Melior Manuballista"
  ],
  "detect life - empath combat interior": [
    "Detect Life - Empath Combat Interior"
  ],
  "death hound attack": [
    "Death Hound Attack"
  ],
  "they didn't tell you she was immortal": [
    "Tibi non dixerunt eam immortalem esse"
  ],
  "femaleheadbretonvampire": [
    "FemaleHeadBretonVampire"
  ],
  "help gunmar defeat the bear": [
    "Adiuva Gunmar ursum superare"
  ],
  "the college of winterhold seems like a good place to start": [
    "Collegium Hiemiterrae locus aptus incipiendo mihi videtur"
  ],
  "college of winterhold seems like a good place to start": [
    "Collegium Hiemiterrae locus aptus incipiendo mihi videtur"
  ],
  "initiate's ewer": [
    "Aqualis Novitii"
  ],
  "maleeyeshumanvampire01": [
    "MaleEyesHumanVampire01"
  ],
  "locate auriel's bow": [
    "Inveni Arcum Aurorae"
  ],
  "we'll return soon": [
    "Mox reveniemus"
  ],
  "you said \"even if i could.\" why": [
    "Dixisti \"etiamsi possem.\" Cur"
  ],
  "test bolt": [
    "Test Bolt"
  ],
  "redwater wellspring key": [
    "Clavis Fonti Rubraquae"
  ],
  "maleheadorcvampire": [
    "MaleHeadOrcVampire"
  ],
  "i would never allow that to happen": [
    "Id numquam accidere sinam"
  ],
  "slows target": [
    "Inimicum Lentius Facit"
  ],
  "culling the beast": [
    "Bestiam Decarpens"
  ],
  "revered dragon": [
    "Draco Augustus"
  ],
  "retrieve the <alias=item3> from <alias=location3>": [
    "Refer <Alias=Item3> a <Alias=Location3>"
  ],
  "i understand the concern": [
    "Sollicitudinem intellego"
  ],
  "summon ice wolves 3": [
    "Summon Ice Wolves 3"
  ],
  "vampire sleep perk": [
    "Peritia Vampiri Dormientis"
  ],
  "dlc1 vampire base scene 4": [
    "DLC1 Vampire Base Scene 4"
  ],
  "dwarven centurion master": [
    "Centurio Dvemeris Dominus",
    "Dvemeris Centurio Dominus"
  ],
  "how did you end up in the soul cairn": [
    "Quomodo ad Terram Animorum advenisti"
  ],
  "knight-paladin gelebor": [
    "Eques-Crucesignatus Gelebor"
  ],
  "dlc1ld quest holding cell": [
    "DLC1LD Quest Holding Cell"
  ],
  "have my companions made you feel welcome": [
    "Sodalesne mei te gratum fecerunt"
  ],
  "destroy the vampire masquerading as a <alias=vampire>": [
    "Neca vampirum qui fingit <Alias=Vampire> esse"
  ],
  "visiting advisor": [
    "Advisor Visitans"
  ],
  "we may never return": [
    "Fortasse numquam reveniemus"
  ],
  "it was": [
    "Erat"
  ],
  "what happened": [
    "Quid accidit",
    "Quid addicit"
  ],
  "rescue <alias=prisoner> from <alias=dungeon>": [
    "Serva <Alias=Prisoner> e <Alias=Dungeon>"
  ],
  "crossbow of draining": [
    "Manuballista Hauriendi"
  ],
  "where did these amulets come from": [
    "Unde venerunt haec monilia"
  ],
  "are you sure you know what you're doing": [
    "Esne certus te scire quid faciendum sit"
  ],
  "why did she care where used souls went": [
    "Quamobrem destinatum animorum curae ei erat"
  ],
  "i need more time to prepare myself": [
    "Tempore careo ut me parem"
  ],
  "empath": [
    "Empatheia"
  ],
  "command the moth priest to read the elder scroll": [
    "Impera Sacerdoti Papilionis ut Codicem Veterrimum Legat"
  ],
  "rune hammer spell": [
    "Rune Hammer Spell"
  ],
  "prelate athring": [
    "Prelatus Athring"
  ],
  "what do you make of all this": [
    "Quid de his omnibes censes"
  ],
  "fellow dovah": [
    "Dovah amicus"
  ],
  "i am not a dragon": [
    "Draco non sum"
  ],
  "storm atronach": [
    "Storm Atronach",
    "Atronachumum Tempestatis",
    "Atronachum Electricitatis"
  ],
  "and the crystals": [
    "Et crystallea"
  ],
  "absorb <mag> points of health, magicka, and stamina per second from the target": [
    "Absorbe <mag> salutis, magickae, staminaeque singulis secundis ab inimico"
  ],
  "ice form for snowelf": [
    "Ice Form For Snow Elf"
  ],
  "solve the tonal lock": [
    "Solva Clausuram Sonantium"
  ],
  "ruunvald key": [
    "Clavis Ruunvald"
  ],
  "can't it wait": [
    "Nonne exspectare potest"
  ],
  "ghost ability": [
    "Ghost Ability"
  ],
  "mistman": [
    "Nebulavir"
  ],
  "you try my patience, <alias=questgiver>": [
    "Me vexas, <Alias=QuestGiver>"
  ],
  "detect life - empath combat exterior": [
    "Detect Life - Empath Combat Exterior"
  ],
  "that's why i'm here for the elder scroll": [
    "Hancobrem hic sum, ut Codice Veterrimo adipisicar"
  ],
  "what sort of prophecy": [
    "Qualis prophetia"
  ],
  "retrieve the <alias=item> from <alias=dungeon>": [
    "Refer <Alias=Item> e <Alias=Dungeon>"
  ],
  "summon werewolves 1": [
    "Summon Werewolves 1"
  ],
  "ring of the gargoyle": [
    "Anulus Gurgulionis"
  ],
  "reanimate a dead body to fight for you for <dur> seconds": [
    "Revocat corpus ut pro te pugnet <dur> secundas",
    "Revoca corpus mortuum ut pro te pugnet <dur> secundas"
  ],
  "ancient power": [
    "Vis Antiqua"
  ],
  "can you train me in smithing": [
    "Potsene mihi plus de arma loricasque faciendo docere"
  ],
  "why haven't you pursued the prophecy": [
    "Quamobrem prophetiam non secuta es"
  ],
  "dexion evicus": [
    "Dexion Evicus"
  ],
  "what's so special about the moondial": [
    "Quid tam speciale est de Lunari Horologio"
  ],
  "defeat the guardians of the forge": [
    "Supera Custodes Fabricae"
  ],
  "isran needs your help": [
    "Isran auxilium tuum quaerit"
  ],
  "dragon armor": [
    "Dragon Armour",
    "Lorica Draconis"
  ],
  "half moon crest": [
    "Crista Mediae Lunae"
  ],
  "gain entry to the inner sanctum": [
    "Intra Fanum Interior"
  ],
  "kill the boneyard keepers (<global=dlc1vq05keeperskilled>/<global=dlc1vq05keeperstotal>)": [
    "Neca Curatores Necropolis (<Global=DLC1VQ05KeepersKilled>/<Global=DLC1VQ05KeepersTotal>)"
  ],
  "i'll need your help": [
    "Auxilio tuo carebo"
  ],
  "dragonplate boots": [
    "Draconilaminata Caligae",
    "Draconilaminatae Caligae"
  ],
  "summon werewolves 2": [
    "Summon Werewolves 2"
  ],
  "speak to harkon": [
    "Adloquere Harkon"
  ],
  "dlc1ld holding cell": [
    "DLC1LD Holding Cell"
  ],
  "arvak": [
    "Arvak"
  ],
  "confront arch-curate vyrthur": [
    "Adloquere ArchiCuratum Vyrthur"
  ],
  "soul trap": [
    "Soul Trap",
    "Animum Cape"
  ],
  "femaleheadredguardvampire": [
    "FemaleHeadRedguardVampire"
  ],
  "are you saying you want to learn more about me": [
    "Dicisne te plus de me discere velle"
  ],
  "new allegiances": [
    "Novae Societates"
  ],
  "death hound": [
    "Canis Mortis"
  ],
  "i wish to regain the gift of beast blood": [
    "Donum sanguinis bestiae iterum habere volo"
  ],
  "katria": [
    "Katria"
  ],
  "your mother maintained quite a laboratory": [
    "Mater tua tantum laboratorium tenuit"
  ],
  "why did you call me \"qahnaarin?\"": [
    "Cur mihi \"Qahnaarin\" apellas"
  ],
  "reflecting shield effect": [
    "Reflecting Shield Effect"
  ],
  "crossbow of shocks": [
    "Manuballista Electricitatis"
  ],
  "another time, maybe": [
    "Alio tempore, fortasse",
    "Tempus alterum, fortasse"
  ],
  "gets dialogue pointing player to radiant quests": [
    "Gets dialogue pointing player to radiant quests"
  ],
  "testl": [
    "testl"
  ],
  "why didn't you say something earlier": [
    "Quidni aliquid antea dixeris"
  ],
  "you're blind": [
    "Caecus es"
  ],
  "fly grab": [
    "Fly Grab"
  ],
  "what do the masters provide in return": [
    "Quid reddunt Domini"
  ],
  "use the aetherium forge": [
    "Uti Fabrica Aetherii"
  ],
  "vampire lord perks are earned by killing enemies with the drain life spell, or with the bite attack in melee mode": [
    "Ut Dominus Vampirus Peritia morte inimicorum vel morsu vel magica 'Bibe Vitam' augentur"
  ],
  "each perk takes more feedings to earn than the previous one": [
    "Omnia peritia plus quam anterius constat",
    "Omnia peritia plus quam anterius constant"
  ],
  "i have a new task for you, thrall": [
    "Novum iussum tibi habeo, serve"
  ],
  "dwarven crossbow of scorching": [
    "Dvemeris Manuballista Conflagrandi"
  ],
  "galathil": [
    "Galathil"
  ],
  "keeper soul drain": [
    "Keeper Soul Drain"
  ],
  "have you tried getting into the inner sanctum": [
    "Umquamne in Sanctum Interius intrare temptavisti"
  ],
  "dawnguard rune shield": [
    "Aurorae Custodum Scutum Runatum"
  ],
  "addict": [
    "Addicta"
  ],
  "stamina": [
    "Stamina"
  ],
  "g1n": [
    "G1n"
  ],
  "temp": [
    "TEMP"
  ],
  "about to change back": [
    "Mutaturus",
    "Mutaturus es"
  ],
  "rigor mortis": [
    "Rigor Mortis"
  ],
  "doorman": [
    "Ianitor"
  ],
  "redwater lookout": [
    "Panoram Rubraquae"
  ],
  "torture tool": [
    "Tormentum"
  ],
  "mysterious woman": [
    "Femina Mirabilis"
  ],
  "amulets of night power": [
    "Monilia Potestatis Noctis"
  ],
  "so what exactly am i doing to it": [
    "Ergo quid eo faciam"
  ],
  "amulet of supernatural reflexes": [
    "Monile Reflexionum Supernaturalium"
  ],
  "skyrim coast": [
    "Ora Caelifinis"
  ],
  "the aetherium forge": [
    "The Aetherium Forge"
  ],
  "nobleman": [
    "Nobilis",
    "Vir Nobilis"
  ],
  "\"parent\" quest for radiant content": [
    "\"Parent\" quest for Radiant Content"
  ],
  "return to garan marethi": [
    "Reveni ad Garan Marethi"
  ],
  "werewolf": [
    "Werewolf",
    "Versipellis"
  ],
  "armored troll": [
    "Trellis Loricatus"
  ],
  "summon ice wolves 1": [
    "Summon Ice Wolves 1"
  ],
  "night cloak": [
    "Amiculum Noctis"
  ],
  "i have killed the <alias=vampire> at <alias=dungeon>": [
    "<Alias=Vampire> ad <Alias=Dungeon> necavi"
  ],
  "h1s": [
    "H1s"
  ],
  "detect all creatures": [
    "Omnes Inimicos Inveni"
  ],
  "totem of terror": [
    "Totemna Terroris"
  ],
  "totem of the predator": [
    "Totemna Predatoris"
  ],
  "this cave is a temple to auriel": [
    "Haec cava est Templum Aurorae"
  ],
  "totem of ice brothers": [
    "Totemna Fratrum Glaciei"
  ],
  "totem of the moon": [
    "Totemna Lunae"
  ],
  "i need training for two-handed weapons": [
    "Doce me de armis Duabus Manubus"
  ],
  "forget it, i'll come back": [
    "Obliviscere, reveniam"
  ],
  "aetherial crown": [
    "Corona Aetherialis"
  ],
  "werewolf perks are earned by eating fresh corpses": [
    "Peritia Versipelis corpora mortua consumendo augentur"
  ],
  "nothing is gained by eating the bodies of the undead": [
    "Nihil coporibus amortuorum additur"
  ],
  "as a vampire lord, the inventory button will bring up the vampire lord perk tree": [
    "Ut Dominus Vampirus, boto inventorii Arborem Peritiorum Vampiri Domini aperit"
  ],
  "perks are earned by killing enemies with the drain life spell, or with the bite attack in melee mode": [
    "Peritia morte inimicorum vel morsu vel magica 'Bibe Vitam' augentur"
  ],
  "the vampire lord uses melee attacks when he is walking and magic attacks when he is floating": [
    "Dominus Vampirus ictus manibus utitur dum ambulet et ictus magicales dum levitet"
  ],
  "vampire lord uses melee attacks when he is walking and magic attacks when he is floating": [
    "Dominus Vampirus ictus manibus utitur dum ambulet et ictus magicales dum levitet"
  ],
  "use the sneak button to switch from one to the other": [
    "Uti botone rependi ut mutes"
  ],
  "thaer": [
    "Thaer"
  ],
  "gargoyles are magical creatures that can form a stony skin when stationary, making it easy to mistake them for a statue": [
    "Gurguliones inimici magici sunt qui pellem lapideum faciunt dum stent, ergo facile pro statuis confunduntur"
  ],
  "their claws are able to absorb health from their victims": [
    "Ungues salutem e victimis bibere possunt"
  ],
  "place the ingredients in the vessel": [
    "Pone ingredientia in dogam"
  ],
  "gather finely ground bone meal": [
    "Collige Ossa bene pulsata"
  ],
  "gather soul gem shards": [
    "Collige Fragmanta Gemmanimorum"
  ],
  "explore castle volkihar's ruined tower": [
    "Investiga Turrem Rutum Castelli Volkiharis"
  ],
  "let's get that portal open": [
    "Aperiamus portam"
  ],
  "how so": [
    "Quomodo"
  ],
  "return to tamriel": [
    "Reveni ad Tamrielem"
  ],
  "glass war axe of consuming": [
    "Bellisecuris Vitreus Consumendi",
    "Vitreus Bellisecuris Consumendi"
  ],
  "locate valerica": [
    "Inveni Valerica"
  ],
  "blacksmith forge": [
    "Fabrica Ferrarii"
  ],
  "her name's serana": [
    "Nomen eius est Serana"
  ],
  "it was her that the vampires were seeking": [
    "Vampiri eam quaesiverunt"
  ],
  "recruit florentius baenius": [
    "Induce Florentium Baenium in Aurorae Custodes"
  ],
  "can't anyone become a vampire if bitten by one": [
    "Nonne omnes a vampiro morsi in unum mutare possunt"
  ],
  "i need training in speech": [
    "Potesne me de Arte Loquendi docere"
  ],
  "what do you want from me": [
    "Quid a me vis"
  ],
  "that's why i'm here": [
    "Pro illo hic sum",
    "Hancobrem hic sum"
  ],
  "you wanted to speak to me": [
    "Mecum loqui voluisti"
  ],
  "gather bark from a canticle tree": [
    "Cape Corticem ex Arbore Canticle"
  ],
  "slay arch-curate vyrthur": [
    "Neca Archicuratum Vyrthur"
  ],
  "fill the initiate's ewer (<global=dlc1vq07ewerfills>/<global=dlc1vq07ewertotal>)": [
    "Comple Aqualem Novitii (<Global=DLC1VQ07EwerFills>/<Global=DLC1VQ07EwerTotal>)"
  ],
  "survive darkfall passage": [
    "Superesto Commeatum Obscuricasus"
  ],
  "i don't think so": [
    "Non credo",
    "Puto ut non"
  ],
  "in fact, i'm going to help her get home": [
    "Enimvero, eam ad domum venire adiuvabo"
  ],
  "speak to the mysterious woman": [
    "Adloquere feminam mirabilem"
  ],
  "find out what the vampires are seeking": [
    "Disce quid vampiri in Hypogaeo Obscurivacuo quaerant"
  ],
  "you'll need to trust me": [
    "Mihi credere debes"
  ],
  "the family is safer this way": [
    "Familia tutior erit"
  ],
  "family is safer this way": [
    "Familia tutior erit"
  ],
  "then he just walled it off": [
    "Deinde id parietibus circumdedit"
  ],
  "awakening": [
    "Resurgens"
  ],
  "auriel's bow effect": [
    "Auriel's Bow Effect"
  ],
  "kill <alias=target>": [
    "Neca <Alias=Target>"
  ],
  "the hunt": [
    "Venatio"
  ],
  "command the moth priest to read the scroll": [
    "Impera Sacerdoti Papilionis ut Codicem Legat"
  ],
  "report your success to harkon": [
    "Refer nuntium successus tui ad Dominum Harkon"
  ],
  "listen to harkon's speech": [
    "Ausculta Orationem Domini Harkonis"
  ],
  "wayshrine chest": [
    "Arcam Araviae"
  ],
  "deer glow spell": [
    "Deer Glow Spell"
  ],
  "place the aetherium shards": [
    "Depone Fragmenta Aetherii"
  ],
  "the gift": [
    "Donum"
  ],
  "crossbow of sparks": [
    "Manuballista Scintillarum"
  ],
  "i'll kill harkon": [
    "Harkonem necabo"
  ],
  "soul ability": [
    "Soul Ability"
  ],
  "soul tear trap": [
    "Soul Tear Trap"
  ],
  "dwarven spider worker": [
    "Dvemeris Aranea Faber",
    "Aranea Dvemeris Operarius"
  ],
  "dawnguard warhammer": [
    "Aurorae Custodum Bellimalleus"
  ],
  "dlc sun attacks area spell": [
    "DLC Sun Attacks Area Spell"
  ],
  "frozen vampire falmer": [
    "Falmeris Vampirus Congelatus"
  ],
  "boneyard": [
    "Necropolis"
  ],
  "the bloodstone chalice": [
    "Calix Sanguilapidis"
  ],
  "dwarven crossbow of shocks": [
    "Dvemeris Manuballista Electricitatis"
  ],
  "absorb <mag> points of health from the target": [
    "Absorbe <mag> salutis ab inimico",
    "Absorbe <mag> salutis singulis secundis ab inimico"
  ],
  "traveling merchant": [
    "Mercator Peregrinans"
  ],
  "sun damage": [
    "Vulnus Solis"
  ],
  "i have made the moth priest my thrall": [
    "Sacerdotem Papilionis servum meum feci"
  ],
  "healer check effect": [
    "Healer Check Effect"
  ],
  "find evidence linked to the vampire": [
    "Inveni evidentiam de vampiro"
  ],
  "sanctified crossbow": [
    "Sanctificata Manuballista"
  ],
  "how does serana fit in": [
    "Quae est pars Seranae"
  ],
  "crossbow of binding": [
    "Manuballista Capiendi"
  ],
  "so you sought the soul cairn for answers": [
    "Ergo terram animorum petivisti ut responsa quaereres"
  ],
  "ice cloak freeze": [
    "Ice Cloak Freeze"
  ],
  "drain vitality - magicka": [
    "Remove Vitalitatem - Magica"
  ],
  "i've rescued <alias=prisoner> from <alias=dungeon>": [
    "Servavi <Alias=Prisoner> a <Alias=Dungeon>"
  ],
  "what do you know about elder scrolls": [
    "Quid de Codicibus Veterrimis scis"
  ],
  "i have the bow": [
    "Arcum habeo"
  ],
  "soul cairn crystal drain": [
    "Soul Cairn Crystal Drain"
  ],
  "ancient vampire arm": [
    "Bracchium Vampiri Antiqui"
  ],
  "and if i refuse your gift": [
    "Etsi donum tuum recusem"
  ],
  "i don't have any gyros on me": [
    "Nulla Choriagia Dvemeria habeo"
  ],
  "there has to be another way": [
    "Aliam viam esse debet"
  ],
  "frost giant": [
    "Gigas Glaciei",
    "Gigans Glaciei"
  ],
  "what was this place": [
    "Quid erat hic locus"
  ],
  "moth priest sandals": [
    "Sacerdotis Papilionis Solea"
  ],
  "i have a message from harkon": [
    "Nuntium a Harkone habeo"
  ],
  "hide bracers": [
    "Bracchialia Pellis",
    "Renonis Bracchialia"
  ],
  "recruit gunmar": [
    "Invita Gunmar"
  ],
  "people and creatures in the spell's area of effect will attack anyone nearby for <dur> seconds": [
    "Homines et Animalia in area effectus omnes prope se oppugnabunt <dur> secundas",
    "Homines animaliaque in area effectus magicae quidquid prope eum forte sit oppugnabunt <dur> secundas"
  ],
  "what kind of a favor": [
    "Qualis favor"
  ],
  "unequip spells in melee mode": [
    "Unequip Spells In Melee Mode"
  ],
  "soul cloak": [
    "Soul Cloak"
  ],
  "i don't want to become a vampire": [
    "Nolo vampirum esse"
  ],
  "i refuse your gift": [
    "Donum tuum recuso"
  ],
  "i'm ready": [
    "Paratus sum"
  ],
  "testkid": [
    "TestKid"
  ],
  "lord harkon": [
    "Dominus Harkon"
  ],
  "what is this bloodspring": [
    "Quid est sanguinirivus"
  ],
  "i've found your mother's notes": [
    "Adnotationes Matris tuae inveni"
  ],
  "what's happened": [
    "Quid accidit"
  ],
  "sorine's satchel": [
    "Cibisin Sorinis"
  ],
  "chasing echoes": [
    "Echoas Persequens"
  ],
  "exploding steel bolt of fire": [
    "Sagittula Adamantea Displodens"
  ],
  "why didn't you tell me it was so huge": [
    "Quidni mihi diceres tam magnum esse"
  ],
  "pulls a living creature to you from a distance": [
    "Accit vivum ad te ab distatia"
  ],
  "crescent moon crest": [
    "Crista Lunae Corniculatae"
  ],
  "snow troll": [
    "Snow Troll"
  ],
  "enhanced dwarven crossbow": [
    "Melior Dvemeris Manuballista"
  ],
  "didn't you fight back": [
    "Nonne repugnavisti"
  ],
  "you don't even know if he's alive": [
    "Non etiam scis num vivat"
  ],
  "withered trees": [
    "Arbores Debiles"
  ],
  "how did you survive the vampires' attack": [
    "Quomodo impetum vampirorum superfuisti"
  ],
  "elder scroll (sun)": [
    "Codex Veterrimus (Sun)"
  ],
  "vampires threaten all of skyrim": [
    "Vampiri totam Caelifinem comminantur"
  ],
  "we need your help": [
    "Auxilium tuum quaerimus"
  ],
  "let's just get it over with": [
    "Tandem aliquando finem ei faciamus"
  ],
  "you want us to help you": [
    "Nos te adiuvare vis"
  ],
  "(brawl)": [
    "(Pugna Manibus)",
    "(Oppugna Manibus)"
  ],
  "ancient falmer boots": [
    "Falmeres Caligae Antiquae"
  ],
  "where did these rings come from": [
    "Unde venerunt hi anuli"
  ],
  "i found you equally worthy": [
    "Te tam dignum puto"
  ],
  "vampire's bane": [
    "Imimicus Vampiri"
  ],
  "human changing into werewolf": [
    "Human changing into werewolf"
  ],
  "are you saying harkon means to kill her": [
    "Dicisne Harkon eam necare velit"
  ],
  "dwarven crossbow of enervating": [
    "Dvemeris Manuballista Enervandi"
  ],
  "you must be the face sculptor i've heard about": [
    "Sculptor notus facierum esse debes"
  ],
  "detect life - predator normal": [
    "Detect Life - Predator Normal"
  ],
  "were you a part of all that": [
    "Fuistine pars horum omnium"
  ],
  "what can you tell me about dimhollow crypt": [
    "Quid mihi de Hypogaeo Cavobscura narrare potes"
  ],
  "i need to find a moth priest": [
    "Sacerdotem Papilionis invenire debeo"
  ],
  "vampire feeding on the corpse of a vigilant": [
    "Vampire Feeding on the Corpse of a Vigilant"
  ],
  "i'll be off then": [
    "Vale"
  ],
  "what do we need": [
    "Quo caremus"
  ],
  "bloodcursed elven arrow": [
    "Sagitta Alfa Sanguine Maledicta"
  ],
  "darkfall cave note": [
    "Epistula Speluncae Obscuricasus"
  ],
  "kindred judgment": [
    "Iudicamentum Familiare"
  ],
  "soulcairn keep head with helmet": [
    "SoulCairn Keep Head with Helmet"
  ],
  "maleheadbretonvampire": [
    "MaleHeadBretonVampire"
  ],
  "hunter base dialogue": [
    "Hunter Base Dialogue"
  ],
  "exploding steel bolt of shock": [
    "Sagittula Adamantea Electrisplodens"
  ],
  "ancient vampire ribcage": [
    "Costae Vampiri Antiqui"
  ],
  "you've been imprisoned here for all this time": [
    "Hic incarcerata es totum hoc tempus"
  ],
  "armored frost troll": [
    "Trellis Glaciei Loricatus"
  ],
  "i need training in heavy armor": [
    "Potsene mihi plus de Lorica Gravi docere"
  ],
  "dwarven bolt": [
    "Dwemer Bolt",
    "Sagittula Dvemeris"
  ],
  "locate the face sculptor in riften": [
    "Inveni sculptorem facierum Hiati"
  ],
  "you're vampires": [
    "Vampiri estis"
  ],
  "how will vampires gain power over the sun": [
    "Quomodo vampiri solem vincent"
  ],
  "reverent crossbow": [
    "Reverens Manuballista"
  ],
  "letter from the vampire": [
    "Epistula a Vampiro"
  ],
  "(intimidate)": [
    "(Intimida)",
    "(Intimidate)"
  ],
  "crossbow of scorching": [
    "Manuballista Conflagrandi"
  ],
  "ancient falmer gauntlets": [
    "Manubria Antiqua Falmeria"
  ],
  "stendarr's aura light": [
    "Shoris Aura Light"
  ],
  "valerica's journal": [
    "Ephemeris Valericae"
  ],
  "maleheadwoodelfvampire": [
    "MaleHeadWoodElfVampire"
  ],
  "the book of life and service": [
    "Liber Vitae Munerisque"
  ],
  "book of life and service": [
    "Liber Vitae Munerisque"
  ],
  "are you trying to get my attention": [
    "Temptasne animadversionem meam accipere"
  ],
  "ahlam": [
    "Ahlam"
  ],
  "ideas": [
    "Cogitationes"
  ],
  "because she's one of them": [
    "Quod unam eorum est"
  ],
  "wayshrine": [
    "Aravia",
    "Ara viarum"
  ],
  "the <alias=boss> at <alias=dungeon> has been dealt with": [
    "<Alias=Boss> ad <Alias=Dungeon> necatus est"
  ],
  "<alias=boss> at <alias=dungeon> has been dealt with": [
    "<Alias=Boss> ad <Alias=Dungeon> necatus est"
  ],
  "dwarven haul": [
    "Gaza Dvemeris"
  ],
  "maleheadimperialvampire": [
    "MaleHeadImperialVampire"
  ],
  "healer effect to look for targets": [
    "Healer Effect To Look For Targets"
  ],
  "i don't see another way": [
    "Nullam aliam viam video"
  ],
  "i'll become a vampire": [
    "Vampirus fiam"
  ],
  "mesmerize and turn <alias=spouse> into a vampire": [
    "Hypnotiza et muta <Alias=Spouse> in vampirum"
  ],
  "eclipse blast": [
    "Eclipse Blast"
  ],
  "ghostly voice": [
    "Ghostly Voice"
  ],
  "you hate him that much": [
    "Eum tanto in odio habes"
  ],
  "then we have no further need of you": [
    "Ergo nobis non iam vales"
  ],
  "the dawnguard has armored battle trolls that they use when fighting vampires": [
    "Aurorae Custodes Trelles Belli habent quibus utuntur dum contra vampiros pugnant"
  ],
  "dawnguard has armored battle trolls that they use when fighting vampires": [
    "Aurorae Custodes Trelles Belli habent quibus utuntur dum contra vampiros pugnant"
  ],
  "so you always got along": [
    "Ergo inter vos semper concorditer congruebatis"
  ],
  "lightning cloak drain": [
    "Lightning Cloak Drain"
  ],
  "the <alias=boss> at <alias=dungeon> has been destroyed": [
    "<Alias=Boss> ad <Alias=Dungeon> necatus est"
  ],
  "<alias=boss> at <alias=dungeon> has been destroyed": [
    "<Alias=Boss> ad <Alias=Dungeon> necatus est"
  ],
  "because we're going to need her help": [
    "Quod auxilium eius habere debebimus"
  ],
  "dueling giant spiders": [
    "Duelling Giant Spiders"
  ],
  "creaturedialoguechaurusinsect": [
    "CreatureDialogueChaurusInsect"
  ],
  "are you going to help": [
    "Adiuvabisne"
  ],
  "perhaps some coin will jog your memory": [
    "Fortasse nummi memoriam tuam adiuvent"
  ],
  "sparks": [
    "Scintillae",
    "Sparks"
  ],
  "we won't know until we find her": [
    "Donec eam invenerimus, nesciemus"
  ],
  "i understand you know how to change someone's appearance": [
    "Intellego te facies mutare posse"
  ],
  "drain vitality - stamina": [
    "Remove Vitalitatem - Stamina"
  ],
  "dwarven crossbow of animus": [
    "Dvemeris Manuballista Animi"
  ],
  "prelate celegriath": [
    "Prelatus Celegriath"
  ],
  "does he have the scroll": [
    "Habetne codicem"
  ],
  "is everything ready": [
    "Suntne omnia parata"
  ],
  "kill your brother, why": [
    "Fratrem necare, quamobrem"
  ],
  "glad you made it here safely, dexion": [
    "Gaudeo te huc tutum venisse"
  ],
  "that covering on your eyes": [
    "Ille drapus super oculos"
  ],
  "are you-": [
    "Esne...caecus"
  ],
  "isn't that pretty risky, staying around the castle": [
    "Nonne periculosum est, prope castellum manere"
  ],
  "ancestor moth wing": [
    "Ala Papilionis Maiorum"
  ],
  "dwarven crossbow of immobilizing": [
    "Dvemeris Manuballista Conligandi"
  ],
  "dawnguard war axe": [
    "Aurorae Custodum Bellisecuris"
  ],
  "forgotten vale cave": [
    "Oblitus Campus Spelunca"
  ],
  "poison bloom": [
    "Venenum Florescens"
  ],
  "fill the chalice with vampire blood": [
    "Comple Calicem sanguine vampirorum"
  ],
  "i'm back from <alias=dungeon> with the <alias=item>": [
    "Reveni a <Alias=Dungeon> cum <Alias=Item>"
  ],
  "what do we need to do": [
    "Quid facere debemus"
  ],
  "what is your decision": [
    "Quid eligis"
  ],
  "dwarven crossbow of thunderbolts": [
    "Dvemeris Manuballista Tonitruum"
  ],
  "summons arvak in the soul cairn or tamriel wilderness to act as your steed": [
    "Arvak vocat ut equum tuum"
  ],
  "werewolf skinwalker": [
    "Versipellis Ambulator"
  ],
  "ancient falmer crown": [
    "Corona Antiqua Falmeris"
  ],
  "mistform": [
    "Nebulaforma"
  ],
  "what is the chantry of auri-el": [
    "Quid est Chantry Aurorae"
  ],
  "i've killed the vampire masquerading as a <alias=target>": [
    "Vampirus qui se <Alias=Target> esse finxit necavit"
  ],
  "i've destroyed the vampire in the jarl of <alias=capital>'s court": [
    "Necavi vampirum in aula Comitis <Alias=Capital>"
  ],
  "we're not sure, but they have an elder scroll": [
    "Nescimus, sed illi Codicem Veterrimum habent"
  ],
  "bring both elder scrolls to dexion": [
    "Fer Ambos Codices Dexioni"
  ],
  "script ai effect": [
    "Script AI Effect"
  ],
  "werewolves": [
    "Werewolves"
  ],
  "they have an elder scroll": [
    "Codicem Veterrimum habent"
  ],
  "gelebor": [
    "Gelebor"
  ],
  "speak with the leader of the dawnguard": [
    "Inveni Castellum Aurorae Custodes ducem adloquere"
  ],
  "how many more wayshrines are there": [
    "Quot plurimae Arae Viarum sunt"
  ],
  "we're called the dawnguard, and we need you to read an elder scroll": [
    "Aurorae Custodes sumus, et tibi suasimus ut Codicem Veterrimum legas"
  ],
  "you must read an elder scroll": [
    "Codicem Veterrimum legere debes"
  ],
  "dwarven sphere guardian": [
    "Dvemeris Sphere Custos",
    "Sphera Dvemeris Custos"
  ],
  "locate the aetherium forge": [
    "Inveni Fabricam Aetherii"
  ],
  "yes, he said to meet him at fort dawnguard": [
    "Sic, dixit nos cum eo ad Castellum Aurorae Custodum adiungere debere"
  ],
  "for <dur> seconds, opponents in melee range take <mag> points shock damage and magicka damage per second": [
    "<dur> secundas, inimici prope te accipiunt <mag> vulneris et electricitatis et magicae singulis secundis",
    "For <dur> seconds, opponents in melee range take <mag> points shock damage and magicka damage per second"
  ],
  "these caves must be massive": [
    "Hae cavae ingentes esse debent"
  ],
  "jiub": [
    "Jiub"
  ],
  "purified void salts": [
    "Salia Hiati Pura"
  ],
  "soul tear damage": [
    "Soul Tear Damage"
  ],
  "what about it": [
    "Quid de eo"
  ],
  "crossbow of souls": [
    "Manuballista Animorum"
  ],
  "what do these rings do": [
    "Quid faciunt hi anuli"
  ],
  "wayshrine basin": [
    "Labellum Araviarum"
  ],
  "full moon crest": [
    "Crista Lunae Plenae"
  ],
  "notes on dimhollow crypt, vol. 3": [
    "Epistulae de Hypogaeo Obscurivacuo, Vol. III"
  ],
  "there's a spider on your shoulder": [
    "There's a spider on your shoulder"
  ],
  "what do you mean by allaying fear through vampiric seduction": [
    "Quid sibi vult timorem diminuere per seductionem vampiricam"
  ],
  "femaleeyeshumanvampire01": [
    "FemaleEyesHumanVampire01"
  ],
  "orders": [
    "Mandata"
  ],
  "how could i help": [
    "Quomodo eum possim"
  ],
  "creaturedialoguegargoyle": [
    "CreatureDialogueGargoyle"
  ],
  "i just walked in here": [
    "Modo huic intravi"
  ],
  "dragonplate gauntlets": [
    "Draconilaminata Manubria",
    "Draconilaminatae Manicae"
  ],
  "you share her blood": [
    "Sanguinem eius communicas"
  ],
  "off the northern coast of skyrim, due west of solitude": [
    "Ad septentrionem Caelifinis, ad occidentem Solitudinis"
  ],
  "sabre cat glow spell": [
    "Sabre Cat Glow Spell"
  ],
  "i've collected all the amulets of night power": [
    "Omnia Monilia Potestatis Noctis inveni"
  ],
  "will i be able to keep these amulets": [
    "Possumne haec monilia habere"
  ],
  "do you know where these other scrolls are": [
    "Scisne ubi sint alii codices"
  ],
  "will i be able to keep these rings": [
    "Possumne hos anulos habere"
  ],
  "<alias=candidate> has been welcomed to the family": [
    "<Alias=Candidate> pars familiae nunc est"
  ],
  "are you prepared to read the elder scroll": [
    "Esne paratus Codicem Veterrimum legere"
  ],
  "how do we do that": [
    "Quomodo id facere possumus"
  ],
  "investigate the moondial": [
    "Investiga Lunarium"
  ],
  "fill the chalice from the spring": [
    "Comple Calicem e rivulo"
  ],
  "any advice": [
    "Habesne quid consilium"
  ],
  "any dangers i should be aware of": [
    "Suntne pericula quae scire debeo"
  ],
  "no thanks": [
    "Minime, gratias",
    "Minime"
  ],
  "lightning that does <mag> points of shock damage to health and magicka per second": [
    "Electricitas quae <mag> puncta vulneris singulis secundis Saluti Magicaeque facit",
    "Lightning that does <mag> points of shock damage to Health and Magicka per second",
    "Scintillae quae <mag> vulneris electricitatis saluti magicaeque singulis secundis faciunt",
    "Electricitas quae <mag> puncta vulneris electricitatis salutis magicaeque singulis secundis facit"
  ],
  "to what end": [
    "Quem ad finem"
  ],
  "what end": [
    "Quem ad finem"
  ],
  "i wasn't told where to take you": [
    "Nemo mihi dixit quo te trahere debere"
  ],
  "in that case, i'm glad you're the one doing it": [
    "Ergo, gaudeo quod tu id facis"
  ],
  "volkihar courtyard": [
    "Area Volkiharis"
  ],
  "perhaps her death is destined": [
    "Fortasse mors eius est fatum"
  ],
  "what will you do if we find your mother": [
    "Quid facies si matrem tuam invenerimus"
  ],
  "but then it all changed": [
    "Sed deinde omnia mutaverunt"
  ],
  "we'll return for you when we can": [
    "Pro te reveniemus cum occasio sit"
  ],
  "vampire sleep": [
    "Vampire Sleep"
  ],
  "vampires grip": [
    "Prehensus Vampiricus"
  ],
  "dwarven crossbow of lightning": [
    "Dvemeris Manuballista Fulminis"
  ],
  "we're called the dawnguard": [
    "Aurorae Custodes sumus"
  ],
  "we're fighting a vampire conspiracy": [
    "Contra coniurationem vampirorum pugnamus"
  ],
  "master's gaze": [
    "Visus Domini"
  ],
  "i have returned from <alias=dungeon> with the <alias=remains>": [
    "Reveni a <Alias=Dungeon> cum <Alias=Remains>"
  ],
  "finely ground bone meal": [
    "Ossa Omninus Pulvata"
  ],
  "we're meeting at fort dawnguard": [
    "Ad Castellum Aurorae Custodum convenimus"
  ],
  "there's no time": [
    "Tempus fugit"
  ],
  "you need to get moving now": [
    "Nunc discedere debes"
  ],
  "rowboat": [
    "Scapham"
  ],
  "who are the ideal masters": [
    "Qui sunt Domini Ideales"
  ],
  "don't worry about that": [
    "Noli de eo sollicitari"
  ],
  "just tell me": [
    "Modo dic mihi"
  ],
  "stand clear": [
    "Cave"
  ],
  "we template": [
    "WE Template"
  ],
  "i want to buy back the elder scroll i sold you": [
    "Volo Codicem Veterrimum quod tibi vendidi iterum emere"
  ],
  "why would they require sustenance": [
    "Quamobrem alimentum postulant"
  ],
  "read the elder scroll (blood)": [
    "Lege Codicem Veterrimum (Sanguis)"
  ],
  "get out of my way": [
    "Remove te",
    "Remove te e via mea"
  ],
  "raise souls": [
    "Raise Souls"
  ],
  "can you teach me more about archery": [
    "Potsene mihi plus de arte Saggitationis docere"
  ],
  "how do i turn him into a vampire": [
    "Quomodo eum in vampiram mutare possum"
  ],
  "soul atronach abilities": [
    "Soul Atronach abilities"
  ],
  "prelate edhelbor": [
    "Prelatus Edhelbor"
  ],
  "script ai flying effect": [
    "Script AI Flying Effect"
  ],
  "moth priest robes": [
    "Sacerdotis Papilionis Peplum"
  ],
  "fine, here you are": [
    "Bene, hic id habes"
  ],
  "(4000 gold)": [
    "(MMMM auri)"
  ],
  "make it 3,000 and you have a deal": [
    "Fac id MMM et pactum habebis"
  ],
  "dlc1aurielsbow exp01": [
    "DLC1AurielsBow Exp01"
  ],
  "gorging": [
    "Farciens"
  ],
  "giant spider": [
    "Giant Spider"
  ],
  "durnehviir misc objective handler": [
    "Durnehviir Misc Objective Handler"
  ],
  "steel armor": [
    "Adamantea Lorica",
    "Lorica Adamantea"
  ],
  "vampire's gaze": [
    "Visus Vampiri"
  ],
  "confront harkon with auriel's bow": [
    "Oppugna contra Harkon Arcu Aurorae"
  ],
  "i can handle myself, and there are still unanswered questions": [
    "Me tegere possum, et interrogata respondenda iam sunt"
  ],
  "seeking disclosure": [
    "Verum Quaerens"
  ],
  "crossbow of sapping": [
    "Manuballista Bibendi"
  ],
  "lingering fire damage": [
    "Lingering Fire Damage",
    "Vulnus Ignis Persistens"
  ],
  "werewolf kills loved one": [
    "Werewolf Kills Loved One"
  ],
  "jiub's opus (page 9)": [
    "Jiubis Opus (Page 9)"
  ],
  "tainted blood withdrawals": [
    "Tainted Blood Withdrawals"
  ],
  "why can't orthjolf be trusted": [
    "Quamobrem Orthjolf non credi potest"
  ],
  "forgemaster": [
    "Dominifabricae",
    "Faciendimagister"
  ],
  "a swarm that does <mag> poison damage for <dur> seconds": [
    "A swarm that does <mag> poison damage for <dur> seconds",
    "Exercitus apium qui facit <mag> veneni vulneris <dur> secundas"
  ],
  "swarm that does <mag> poison damage for <dur> seconds": [
    "A swarm that does <mag> poison damage for <dur> seconds",
    "Exercitus apium qui facit <mag> veneni vulneris <dur> secundas"
  ],
  "confessions of a khajiit fur trader": [
    "Confessiones Mercenarii Rhenonum Khajiti"
  ],
  "the forgemaster": [
    "Dominifabricae"
  ],
  "so we're pinning the murder on the dawnguard": [
    "Ergo culpam mortis in Aurorae Custodes conferemus"
  ],
  "how many pages did you lose": [
    "Quot pagellas amisisti"
  ],
  "falmer poison": [
    "Falmer Poison",
    "Falmer Venenum"
  ],
  "dragonbone arrow": [
    "Sagitta Draconossis"
  ],
  "actually, now that you mention it...(attack)": [
    "Em, ut verum dicam...(Oppugna)"
  ],
  "what will you do then": [
    "Quid deinde facies"
  ],
  "have we angered the ideal masters": [
    "Vexavimusne Dominos Ideales"
  ],
  "gort": [
    "Gort"
  ],
  "vampire float artholder": [
    "Vampire Float ArtHolder"
  ],
  "who threw you in here": [
    "Quis te huc iniecisti"
  ],
  "portal to temple balcony": [
    "Portam ad Ecthetam Templi"
  ],
  "spectral assassin": [
    "Sicarius Spectralis"
  ],
  "broken dwarven sphere": [
    "Sphera Dvemeris Fracta"
  ],
  "activation blocker": [
    "Activation Blocker"
  ],
  "vampire armor": [
    "Vampiri Lorica"
  ],
  "forgemaster fire breath": [
    "Forgemaster Fire Breath"
  ],
  "durak": [
    "Durak"
  ],
  "that sounds pretty lonely": [
    "Tempus solitarium mihi videtur"
  ],
  "i want to show you something": [
    "Volo tibi aliquid demonstrare"
  ],
  "it's important": [
    "Aliquid magni momenti"
  ],
  "you really must come": [
    "Re vera venire debes"
  ],
  "aetherial shield bash spell": [
    "Aetherial Shield Bash Spell"
  ],
  "increment updater": [
    "Increment Updater"
  ],
  "vampire cattle": [
    "Pecus Vampiri"
  ],
  "how did you die": [
    "Quomodo mortuus es"
  ],
  "lost relic": [
    "Reliquum Perditum"
  ],
  "armored husky": [
    "Husky Loricatus"
  ],
  "any clues about the other elder scroll": [
    "Alia informata de Codice Veterrimo"
  ],
  "what is the vampire doing": [
    "Quid facit vampirus"
  ],
  "dlc1 hunter base scene 8": [
    "DLC1 Hunter Base Scene 8"
  ],
  "summon a dwarven spider or sphere for <60> seconds wherever the caster is pointing": [
    "Vocat Araneam vel Spheram Dvemerem <60> secundas ubi demonstras"
  ],
  "what did this area used to be": [
    "Quid fuit haec area"
  ],
  "how did you join the dawnguard": [
    "Quomodo Aurorae Custodes adiunxisti"
  ],
  "i don't suppose there's a way to free you": [
    "Estne, fortasse, viam ad te liberandum"
  ],
  "does isran have a problem with him": [
    "Isranne ab eo vexatur"
  ],
  "she doesn't seem too fond of him": [
    "Ea non amicissimam ei mihi videtur"
  ],
  "massive fire damage": [
    "Massive Fire Damage"
  ],
  "what turned your people into the betrayed": [
    "Quid tuos in Proditos mutavit"
  ],
  "i don't have time for this": [
    "Tempus huic non habeo",
    "Tempus huic mihi non est"
  ],
  "crystal drain visual": [
    "Crystal Drain Visual"
  ],
  "were you successful": [
    "Vicistine"
  ],
  "adventurer": [
    "Advenator",
    "Adventator",
    "Peregrinatrix"
  ],
  "windhelm docks": [
    "Navale Ventigaleae"
  ],
  "when a chaurus reaches the end of its life, its body undergoes a dramatic transformation": [
    "Ad finem vitae Chauri, corpus valde mutat"
  ],
  "after days of death-like stillness, the chaurus hunter bursts free from the husk of the chaurus": [
    "Post dies tam immobilis ut cadaver, Venator Chauri erumpit e folliculo Chauri"
  ],
  "is that why you had retained your sight": [
    "Hancobrem visum iam habuisti"
  ],
  "elven dagger": [
    "Alfus Pugio",
    "Pugio Alfus"
  ],
  "how did you earn your nicknames": [
    "Quomodo agnomina accepistis"
  ],
  "(<global=dlc1ferrycostsmall> gold)": [
    "(<Global=DLC1FerryCostSmall> auri)"
  ],
  "could i help": [
    "Could I help"
  ],
  "nope, never heard of you": [
    "Minime, numquam de te audivi"
  ],
  "dragonbone battleaxe": [
    "Draconiossis Bellisecuris"
  ],
  "protect soul": [
    "Obtege Animum"
  ],
  "dlc1 hunter base scene 4": [
    "DLC1 Hunter Base Scene 4"
  ],
  "a dremora captured you": [
    "Dremora te cepit"
  ],
  "dremora captured you": [
    "Dremora te cepit"
  ],
  "i've never heard of it": [
    "Numquam de eo audivi"
  ],
  "then i've contributed to your kind's extinction": [
    "Ergo ad finem tuorum gentium auxilium dedi"
  ],
  "what will happen to the chantry now": [
    "Quid ad Chantry nunc accidet"
  ],
  "venarus vulpin's research": [
    "Inquisitio Venari Vulpis"
  ],
  "imperial military camp": [
    "Casta Imperialia",
    "Imperial Military Camp"
  ],
  "forgemaster fireball": [
    "Ignipila Fabricadomini"
  ],
  "ferry seat": [
    "Sedes Pontonis"
  ],
  "why did you join the dawnguard": [
    "Cur Aurorae Custodes adiunxisti"
  ],
  "blood portal": [
    "Porta Sanguinis"
  ],
  "dlc1vq07attack": [
    "DLC1VQ07Attack"
  ],
  "where can i find him": [
    "Ubi possum find him",
    "Ubi eum invenire possum"
  ],
  "never mind, i'll look somewhere else": [
    "Ignosce, in alio loco quaeram"
  ],
  "forgemaster resistances": [
    "Forgemaster Resistances"
  ],
  "dlc1 hunter base scene 2": [
    "DLC1 Hunter Base Scene 2"
  ],
  "(optional) make it a discreet kill": [
    "(Optio) Eum clam neca"
  ],
  "<mag> points of sun damage": [
    "<mag> vulneris solis"
  ],
  "undead targets take triple damage": [
    "Amortui triplex vulneris accipiunt"
  ],
  "taron dreth's robes": [
    "Taron Drethis Peplum"
  ],
  "sunlight explosion that does <mag> points of damage in a 15 foot radius to undead, and half that to living targets": [
    "Diplosio Lucis Solis quae <mag> vulneris amortuis in area XV Pedum ed dimidii vivis"
  ],
  "what will you do now": [
    "Quid nunc facias",
    "Quid nunc facies"
  ],
  "so now what": [
    "Ergo quid nunc"
  ],
  "what do you mean by \"discreet.\"": [
    "Quid sibi vult \"discretum.\""
  ],
  "vampire": [
    "Vampira",
    "Vampire",
    "Vampirus"
  ],
  "forgemaster flame cloak": [
    "Forgemaster Flame Cloak"
  ],
  "vampire armor of recharging": [
    "Vampiri Lorica Recuperandi Magicae"
  ],
  "drain magicka": [
    "Hauri Magicam",
    "Absorbe Magickam"
  ],
  "you're no pilgrim": [
    "Nullus peregrinator es"
  ],
  "vampire armor of resurgence": [
    "Vampiri Lorica Resurgendi"
  ],
  "i have five soul husks here": [
    "Quinque Animorum Follicula hic habeo"
  ],
  "do you think this would help": [
    "Censes hoc te adiuvet"
  ],
  "(give journal)": [
    "(Da Ephemeridem)",
    "(Da Ephemerem)"
  ],
  "it appears the nords won": [
    "Mihi videtur Germani vicisse"
  ],
  "snowelf": [
    "Snow Elf"
  ],
  "it's ironic that we're \"helping\" these priests": [
    "Ironice, hos sacerdotes adiuvamus"
  ],
  "i won't give up that easily": [
    "Non tam faculter succumbam"
  ],
  "there are always human cattle in castle volkihar that any vampire can feed on. feeding reduces sun damage, but also weakens your vampiric powers": [
    "Semper pecus humane ad Castellum Volkihare sunt cuius sanguinem omnes vampiri bibere possunt"
  ],
  "ball of light that lasts <dur> seconds and sticks where it strikes": [
    "Lumen lucis quod <dur> secondas manet et haeret ubi icit",
    "Ball of light that lasts <dur> seconds and sticks where it strikes"
  ],
  "do you know anything about this place": [
    "Scisne quid de hoc loco"
  ],
  "summons a gargoyle for <dur> seconds": [
    "Vocat Gargoyle <dur> secundas"
  ],
  "soul tear reanimate": [
    "Soul Tear Reanimate"
  ],
  "blood of the ancients": [
    "Sanguis Antiquorum"
  ],
  "why would a necromancer want to deal with them": [
    "Quamobrem necromanticus cum eis agere velit"
  ],
  "follow me, or you'll regret it": [
    "Sequere me, vel poenas dabis"
  ],
  "return to the shrine of clavicus vile with barbas and the rueful axe": [
    "Repete Aram Clavici cum Barbas et Securis Rutae",
    "Reveni ad Aram Clavici cum Barba et Securi Rutae"
  ],
  "can you make me some sunhallowed arrows": [
    "Potesne mihi Saggitas Sole Benedictas facere"
  ],
  "what do i do with the knife": [
    "Quid hoc cultro faciam"
  ],
  "portal to shrine of sight": [
    "Portam ad Aram Visus"
  ],
  "steam damage / restoration": [
    "Steam Damage / Restoration"
  ],
  "dwemer schematics": [
    "Diagrammae Dvemeres"
  ],
  "soul husk extract": [
    "Extractum Animorum"
  ],
  "jolf": [
    "Jolf"
  ],
  "what is this artifact": [
    "Quid est hoc opus antiquum"
  ],
  "big light thing": [
    "big light thing"
  ],
  "impatience of a saint post quest": [
    "Impatience of a Saint Post Quest"
  ],
  "hern": [
    "Hern"
  ],
  "a vampire that is using his vampire's seduction power can feed on any person that is not in combat, even if they are awake": [
    "Vampiri Seductione Vampiri in homines non pugnantes uti possunt"
  ],
  "vampire that is using his vampire's seduction power can feed on any person that is not in combat, even if they are awake": [
    "Vampiri Seductione Vampiri in homines non pugnantes uti possunt"
  ],
  "feeding reduces sun damage, but also reduces your vampiric powers": [
    "Bibe ut vulnus solis diminuatur sed cave potestatem vampiricam reductam"
  ],
  "i heard you mention serana's mother": [
    "Audivi te matrem Seranae commemorare"
  ],
  "what happened to her": [
    "Quid ei accidit"
  ],
  "can't we inform the jarl's men of the threat": [
    "Nonne homines Comitis de comminatione certiores facere possumus"
  ],
  "portal": [
    "Portam"
  ],
  "jiub's opus (page 4)": [
    "Jiubis Opus (Page 4)"
  ],
  "come with me": [
    "Veni mecum"
  ],
  "dragonbone sword": [
    "Draconiossis Gladius"
  ],
  "what do you mean by a \"high profile\" target": [
    "Quid sibi vult \"magni momenti\""
  ],
  "touching the sky post quest handler": [
    "Touching the Sky Post Quest Handler"
  ],
  "dark souls": [
    "Animi Obscuri"
  ],
  "please, it's very important that i find him": [
    "Eum invenire maximi momenti est, quaeso"
  ],
  "what if you're wrong": [
    "Quid accidet si erras"
  ],
  "vampire armor of peerless conjuration": [
    "Vampiri Lorica Maximi Vocantis"
  ],
  "learn the first word of durnehviir's shout": [
    "Disce verbum primum clamoris Durnehviris"
  ],
  "how'd you get caught": [
    "Quomodo captus es"
  ],
  "(optional) ask carriage drivers about the moth priest": [
    "(Optio) roga conductores raedarum de Sacerdote Papilionis"
  ],
  "to which shrine are you headed, \"pilgrim.\"": [
    "Quem aram petis, \"peregrinator.\""
  ],
  "which shrine are you headed, \"pilgrim.\"": [
    "Quem aram petis, \"peregrinator.\""
  ],
  "reanimate a more powerful dead body to fight for you for <dur> seconds": [
    "Reanima corpus potentius ut pro te pugnet <dur> secundas",
    "Revoca corpus etiam potentius mortuum ut pro te pugnet <dur> secundas"
  ],
  "you're no bard": [
    "Nullus cantator es"
  ],
  "how about a sample": [
    "Quidni exemplar mihi"
  ],
  "dlc1ld: taron dreth": [
    "DLC1LD: Taron Dreth"
  ],
  "heavy armor": [
    "Lorica Gravis"
  ],
  "(25 husks)": [
    "(XXV Follicula)"
  ],
  "dlc1 hunter base scene 1": [
    "DLC1 Hunter Base Scene 1"
  ],
  "player can turn this actor into a vampire": [
    "Player can turn this actor into a vampire"
  ],
  "are you sure valerica has the elder scroll": [
    "Esne certus Valericam Codicem Veterrimum habuisse"
  ],
  "why would your wife betray you": [
    "Quamobram uxor tua tibi prodidisti"
  ],
  "portal to shrine of learning": [
    "Portam ad Aram Discendi"
  ],
  "lava damage": [
    "Lava Damage"
  ],
  "never mind that... it worked": [
    "Ignosce illi... fungitur"
  ],
  "didn't work out that way i assume": [
    "Non ut putavi accidit"
  ],
  "spriggan variant swarm spray": [
    "Spriggan Variant Swarm Spray"
  ],
  "prelate's mace": [
    "Maca Praelatis"
  ],
  "interrupts the soul drain effect in the soul cairn": [
    "Interrupit effectum animicapti Terrae Animorum"
  ],
  "lingering steam damage": [
    "Lingering Steam Damage"
  ],
  "a battleaxe": [
    "Bellisecuris"
  ],
  "battleaxe": [
    "Bellisecuris"
  ],
  "no, i don't think so": [
    "Minime, non credo"
  ],
  "jiub's opus (page 2)": [
    "Jiubis Opus (Page 2)"
  ],
  "vampire armor of quickening": [
    "Vampiri Lorica Rapiditatis Magicae"
  ],
  "kagrenzel": [
    "Kagrenzel"
  ],
  "auriel's arrow": [
    "Sagitta Solis"
  ],
  "blocking lines for castle vampires": [
    "Blocking lines for Castle Vampires"
  ],
  "why did you ever agree to her plan": [
    "Cur partem consilii eius esse consensisti"
  ],
  "creatures and people up to level <mag> won't fight for <dur> seconds": [
    "Inimica ad gradum <mag> non pugnabunt <dur> secundas",
    "Animalia hominesque ad gradum <mag> non pugnabunt <dur> secundas",
    "Homines et animalia ad gradus <mag> non pugnabunt <dur> secundas"
  ],
  "darkfall passage note ii": [
    "Epistula Commeatus Obscuricasus II"
  ],
  "and that story led you here": [
    "Et illa fabula quae te huc duxit"
  ],
  "that was... a bit before my time": [
    "Fuit... paulum ante tempus meum"
  ],
  "rival assassin": [
    "Sicarius rivalis"
  ],
  "tainted blood of the ancients": [
    "Sanguis Corruptus Antiquorum"
  ],
  "come with me, \"merchant.\"": [
    "Veni mecum, \"mercator.\""
  ],
  "drain stamina": [
    "Drain Stamina",
    "Absorbe Staminam"
  ],
  "do you know this place": [
    "Hoc loco novisti"
  ],
  "the crossbows used by the dawnguard fire a deadly first shot": [
    "Manubalistae quae ab Aurorae Custodibus usurpantur primam sagittulam potessimam saggitant"
  ],
  "crossbows used by the dawnguard fire a deadly first shot": [
    "Manubalistae quae ab Aurorae Custodibus usurpantur primam sagittulam potessimam saggitant"
  ],
  "however, they are slow to reload": [
    "Sed multum tempus consumitur ante proximam sagittulam"
  ],
  "you mean the betrayed": [
    "De Proditis loqueris"
  ],
  "opus": [
    "Opus"
  ],
  "detect life - predator searching": [
    "Detect Life - Predator Searching"
  ],
  "maybe a shorter title is in order": [
    "Fortasse titulum brevius postulat"
  ],
  "vampire armor of peerless destruction": [
    "Vampiri Lorica Maximi Destructionis"
  ],
  "where were you captured": [
    "Ubi captus es"
  ],
  "follow isran": [
    "Sequere Isran"
  ],
  "dead drop mine": [
    "Morticasus Metallum"
  ],
  "locket of saint jiub": [
    "Monile Sancti Jiub"
  ],
  "bloodspring": [
    "Sanguinirivulus"
  ],
  "any idea what i'll be up against": [
    "Scisne contra quem oppugnaturus sum"
  ],
  "impatience of a saint": [
    "Impatientia Sancti"
  ],
  "what is this place": [
    "Quid est hic locus",
    "Quid est hoc locus"
  ],
  "most snow elves": [
    "Plurimi Alfi Nivosi"
  ],
  "why was dagon able to do this": [
    "Cur Dagon hoc facere potuit"
  ],
  "do you think there's more aetherium somewhere": [
    "Putasne plus Aetherii esse in alio loco"
  ],
  "i don't see you with any better ideas": [
    "Mihi videtur te consilia meliora non habere"
  ],
  "gargoyle unarmed damage": [
    "Gargoyle Unarmed Damage"
  ],
  "spriggan spray": [
    "Spriggan Spray"
  ],
  "sounds powerful": [
    "Fortis videtur"
  ],
  "null effect": [
    "Null Effect"
  ],
  "i'll try my luck elsewhere": [
    "In alio loco conabor"
  ],
  "are all of those ingredients here": [
    "Suntne hic omnia ingredientia"
  ],
  "revered and legendary dragons are more deadly than ancient dragons": [
    "Dracones Augusti et Legendarii letiferiores sunt quam dracones antiqui"
  ],
  "they use a shout that can drain health, magicka and stamina from its victims": [
    "Clamatione utuntur quae salutem, magicam, et staminam e victimis bibit"
  ],
  "an island near the border to highrock": [
    "Insula prope finem Altasaxi"
  ],
  "frozen chaurus": [
    "Chaurus Congelatus"
  ],
  "spriggan earth mother abilities": [
    "Spriggan Earth Mother abilities"
  ],
  "eclipse attack: gargoyles": [
    "Eclipse Attack: Gargoyles"
  ],
  "summon gargoyle": [
    "Voca Gargulion"
  ],
  "jiub's opus (page 5)": [
    "Jiubis Opus (Page 5)"
  ],
  "she just wants to go home": [
    "Illa modo domum revenire vult"
  ],
  "monk robes": [
    "Monachi Peplum"
  ],
  "finding that moth priest is worth a lot to me": [
    "Sacerdotem Papilionis mihi multi valet"
  ],
  "you mean the soul cairn": [
    "De Terra Animorum loqueris"
  ],
  "you must be crazy": [
    "Insanam esse debes"
  ],
  "dlc1 hunter base scene 3": [
    "DLC1 Hunter Base Scene 3"
  ],
  "dawnguard shield": [
    "Aurorae Custodum Scutum"
  ],
  "and if i want to remain a werewolf": [
    "Etsi versipellem remanere velim"
  ],
  "did you spend a lot of time down here": [
    "Multus tempus hic consumpsisti"
  ],
  "does an additional <mag> points of damage": [
    "<mag> plus vulneris addit"
  ],
  "i've retrieved the <alias=item> from <alias=dungeon>": [
    "Extraxi <Alias=Item> a <Alias=Dungeon>"
  ],
  "and if harkon were slain": [
    "Etsi Harkon necaretur"
  ],
  "this is no ruse": [
    "Non te deludo"
  ],
  "i want to keep her safe": [
    "Eam tueri volo"
  ],
  "have you seen a moth priest": [
    "Vidistine Sacerdotem Papilionis"
  ],
  "dlc1 hunter base scene 7": [
    "DLC1 Hunter Base Scene 7"
  ],
  "extra damage": [
    "Extra Damage"
  ],
  "you're no merchant": [
    "Nullus mercator es"
  ],
  "winged menace": [
    "Inimicus Alatus"
  ],
  "vampire armor of eminent conjuration": [
    "Vampiri Lorica Eminent Vocantis"
  ],
  "soul cairn husk merchant": [
    "Soul Cairn Husk Merchant"
  ],
  "dragonplate shield": [
    "Draconilaminatum Scutum"
  ],
  "vampires vs bandits": [
    "Vampires vs Bandits"
  ],
  "zephyr null enchantment": [
    "Zephyr Null Enchantment"
  ],
  "you've worked with isran before": [
    "Cum Isran antea laboravisti"
  ],
  "i'm already a vampire": [
    "Iam vampirus sum"
  ],
  "what more can you offer me": [
    "Quid plus mihi offerre potes"
  ],
  "you can make dragonbone weapons at a forge that are more deadly than daedric weapons, but weigh more": [
    "Arma Draconiossium facere potest. Haec arma letiferiora sunt quam arma daedrica, sud plus ponderosa sunt"
  ],
  "celann": [
    "Celann"
  ],
  "you said you were in prison": [
    "Dixisti te in carcere fuisse"
  ],
  "dragonbone war axe": [
    "Draconiossis Bellisecuris"
  ],
  "how are you feeling after talking to your mother": [
    "Quomodo te habes post matrem tuam adlocuta es"
  ],
  "why is that": [
    "Quamobrem"
  ],
  "i have two of the other shards already": [
    "Dua alia fragmenta iam habeo"
  ],
  "harlaug": [
    "Harlaug"
  ],
  "eclipse attack: vampire and thralls": [
    "Eclipse Attack: Vampire and thralls"
  ],
  "what did you do": [
    "Quid fecisti"
  ],
  "vampire armor of replenishing": [
    "Vampiri Lorica Replendi"
  ],
  "dragonbone greatsword": [
    "Draconiossis Spatha"
  ],
  "aetherial shield perk": [
    "Scutum Aetheriale"
  ],
  "what do you think of this place": [
    "Quid de hoc loco censes"
  ],
  "jiub's opus (page 10)": [
    "Jiubis Opus (Page 10)"
  ],
  "purified void salt": [
    "Salia Hiati Pura"
  ],
  "portal to shrine of resolution": [
    "Portam ad Aram Resolutionis"
  ],
  "you shouldn't blame yourself": [
    "Te culpare non debes"
  ],
  "you were in the companions": [
    "Contubernalis fuisti"
  ],
  "ferry system dialogue": [
    "Ferry system dialogue"
  ],
  "any idea how to get out of here": [
    "Scisne quomodo nos exire possimus"
  ],
  "damn moths": [
    "Damnabiles Papiliones"
  ],
  "why are they doing that": [
    "Cur illud faciunt"
  ],
  "what were you looking for": [
    "Quid quaesivisti"
  ],
  "you sound like you were a weird little kid": [
    "Ut parvulus mirabilis videris"
  ],
  "join barbas outside haemar's shame": [
    "Adiunge Barbam foris Pudoris Haemaris",
    "Conveni cum Barba foris Pudoris Haemaris"
  ],
  "i already have another shard": [
    "Iam alium fragmentum habeo"
  ],
  "now, what were you saying": [
    "Quid dicebas"
  ],
  "got anything left": [
    "Quidquam iam habes"
  ],
  "how can i avoid trouble with the law": [
    "Quomodo difficultatem cum lege evitare possim"
  ],
  "everyone couldn't have possibly agreed to that": [
    "Certe omnes non consenserunt"
  ],
  "what about you": [
    "Quid de te"
  ],
  "it's in a place called darkfall cave": [
    "In loco nomine 'Cava Obscuracasus' est"
  ],
  "storm call lightning bolt": [
    "Storm Call Lightning Bolt"
  ],
  "darkfall passage note i": [
    "Epistula Commeatus Obscuricasus I"
  ],
  "chaurus hunter antennae": [
    "Antennae Chauri Venatoris"
  ],
  "maybe this will put your mind at ease": [
    "Fortasse hoc tibi suadeat"
  ],
  "what does it have to do with the sun": [
    "Quid mecum agitur"
  ],
  "forge something": [
    "Aliquid facere"
  ],
  "with what": [
    "Quo"
  ],
  "dragonbone warhammer": [
    "Draconiossis Bellimalleus"
  ],
  "venarus vulpin": [
    "Venarus Vulpin"
  ],
  "red eagle redoubt": [
    "Red Eagle Redoubt",
    "Redubitum Aquilae Rubrae"
  ],
  "reduced damage 0.35": [
    "Reduced Damage 0.35"
  ],
  "death hounds are used as guardians by vampires": [
    "Canibus Infernalibus sicut custodes a vampiris usurpantur"
  ],
  "how they came to be is unknown, but their bite is as cold as the grave": [
    "Ortus eorum inscitus est, sed morsus tam frigidus est quam tumulum"
  ],
  "it sounds like they were both being selfish": [
    "Mihi videtur ambos egotisticos fuisse"
  ],
  "shimmermist cave": [
    "Shimmermist Cave"
  ],
  "traitor's post": [
    "Traitor's Post"
  ],
  "abandoned shack": [
    "Abandoned Shack"
  ],
  "got anything left to sell": [
    "Quidquam vendendum iam habes"
  ],
  "eastmarch": [
    "Eastmarch"
  ],
  "the rift": [
    "the Rift"
  ],
  "rift": [
    "the Rift"
  ],
  "haafingar": [
    "Haafingar"
  ],
  "learn the final word of durnehviir's shout": [
    "Disce verbum ultimum clamoris Durnehviris"
  ],
  "beleval": [
    "Beleval"
  ],
  "that was interesting": [
    "Mihi interest"
  ],
  "bthalft": [
    "Bthalft"
  ],
  "i don't know, but she must be connected": [
    "Nescio, sed pars eius esse debet"
  ],
  "i'd like to hire your boat": [
    "Navem tuam conducere volo"
  ],
  "(optional) ask innkeepers in cities about the moth priest": [
    "(Optio) Roga caupones de Sacerdote Papilionis"
  ],
  "dlc1 item holding cell": [
    "DLC1 Item Holding Cell"
  ],
  "aetherial staff explosion": [
    "Aetherial Staff Explosion"
  ],
  "werewolves vs vampires": [
    "Werewolves VS Vampires"
  ],
  "locate valerica's journal": [
    "Inveni Ephemeridem Valericae"
  ],
  "summon durnehviir in tamriel": [
    "Voca Durnehviir in Tamrielem"
  ],
  "return to jiub": [
    "Reveni ad Jiub"
  ],
  "how do you serve lord harkon": [
    "Quomodo servis Dominum Harkonem"
  ],
  "(optional) search for crafting materials": [
    "(Option) Queaere Materia Faciendi"
  ],
  "(optional) shut off the steam": [
    "(Optio) Claude Vaporem"
  ],
  "and you were caught in the middle": [
    "Et tu in medio fuisti"
  ],
  "retrieve the aetherium crest": [
    "Refer Cristam Aetherii"
  ],
  "show me your wares, \"merchant.\"": [
    "Demonstra mihi merces tuas , \"mercator.\""
  ],
  "sure, i'd like the help": [
    "Certe, auxilium volo"
  ],
  "find florentius": [
    "Inveni Florentium"
  ],
  "now what": [
    "Quid nunc"
  ],
  "bolstering the ranks": [
    "Addens Comitatum"
  ],
  "find pages from saint jiub's opus (<global=dlc1vqsaintcount>/<global=dlc1vqsainttotal>)": [
    "Inveni pegellas e Opere Sancti Jiubis (<Global=DLC1VQSaintCount>/<Global=DLC1VQSaintTotal>)"
  ],
  "super mage light exp": [
    "Super Mage Light Exp"
  ],
  "dawnguard vampire hunting": [
    "Aurorae Custodum Vampire Hunting"
  ],
  "beyond death post quest handler": [
    "Beyond Death Post Quest Handler"
  ],
  "soul cairn dialogue quest": [
    "Soul Cairn Dialogue Quest"
  ],
  "i'll take you to the monster you're hunting": [
    "Te ad monstrum quod venaris ferre possum"
  ],
  "dlc1 hunter base scene 5": [
    "DLC1 Hunter Base Scene 5"
  ],
  "dlc1 hunter base scene 6": [
    "DLC1 Hunter Base Scene 6"
  ],
  "touching the sky combat dialogue": [
    "Touching the Sky Combat Dialogue"
  ],
  "eclipse attack: death hounds": [
    "Eclipse Attack: Death Hounds"
  ],
  "eclipse attack: vampires and death hounds": [
    "Eclipse Attack: Vampires and Death Hounds"
  ],
  "eclipse attack: vampires, gargoyles and death hounds (oh, my)": [
    "Eclipse Attack: Vampires, Gargoyles and Death Hounds (Oh, my)"
  ],
  "death hounds": [
    "Death Hounds"
  ],
  "buy back the elder scroll (dragon) from urag gro-shub": [
    "Eme Codicem Veterrimum (Draco) E Uragi gro-Shub"
  ],
  "discover the location of the elder scroll (dragon)": [
    "Inveni locum Codicis Veterrimi (Draco)"
  ],
  "scroll scouting": [
    "Codices Quaerens"
  ],
  "castle thrall dialogue": [
    "Castle Thrall Dialogue"
  ],
  "test vorpal sword": [
    "Test Vorpal Gladius"
  ],
  "i already found all of the ingredients": [
    "Omnia ingredientia iam inveni"
  ],
  "enchantment does additional sun damage based on the number of undead killed <global=dlc1undeadkilled> undead killed": [
    "Vulnus solis augetur numeris amortuorum necatorum <Global=DLC1UndeadKilled>"
  ],
  "and the wayshrines": [
    "Et Arae Viarum"
  ],
  "vampire armor of minor conjuration": [
    "Vampiri Lorica Minoris Vocantis"
  ],
  "dlcwerewolf fear howl 1": [
    "DLCWerewolf Fear Howl 1"
  ],
  "it probably didn't have anything to do with you": [
    "Probabiliter nihil cum te agitur"
  ],
  "i need the elder scroll that speaks of dragons": [
    "Codice Veterrimo qui de draconibus loquitur careo"
  ],
  "what's the matter with you": [
    "Quid est tecum",
    "Quid agis tecum"
  ],
  "lightning bolt call storm": [
    "Lightning Bolt Call Storm"
  ],
  "the scrolls gave me its exact location": [
    "Codices mihi locum exactum dederunt"
  ],
  "scrolls gave me its exact location": [
    "Codices mihi locum exactum dederunt"
  ],
  "they were trying to free a woman trapped in a crypt": [
    "Feminam in Hypogaeo liberare conabantur"
  ],
  "taron dreth": [
    "Taron Dreth"
  ],
  "that's right": [
    "Ita vero",
    "Recte dicis"
  ],
  "you've seen him": [
    "Eumne vidisti"
  ],
  "dlc1vampirelordcape": [
    "DLC1VampiriPeplumCape"
  ],
  "it sounds like you already didn't like him much": [
    "Mihi videtur te eum iam in odio habuisse"
  ],
  "burns the target <mag> points per second": [
    "Inimicum urit <mag> punctis singulis secundis",
    "Inimicos <mag> puncta vulneris singulis secundis accipiunt"
  ],
  "targets on fire take extra damage": [
    "Qui ardent plus vulneris accipiunt",
    "Qui ardet plus vulneris accipit",
    "Targets on fire take extra damage"
  ],
  "how do the necromancers communicate with them": [
    "Quomodo necromantici cum eis loquuntur"
  ],
  "vampire armor of extreme conjuration": [
    "Vampiri Lorica Extremae Vocantis"
  ],
  "did your mother keep gargoyles here": [
    "Materne tua Gurgulios hic habuit"
  ],
  "your armored troll leaves your service": [
    "Trellis Loricatus e munere tuo discedit"
  ],
  "gargoyles": [
    "Gargoyles"
  ],
  "hasn't everyone": [
    "Nonne omnes"
  ],
  "how many do you need": [
    "Quot vis"
  ],
  "when you greeted serana, it sounded like you hadn't seen her in a long time": [
    "Cum Seranam salutavisti, mihi videtur te eam abhinc multos annos vidisse"
  ],
  "that felt strange": [
    "Mirabilis mihi erat"
  ],
  "you're talking about a book": [
    "De libro loqueris"
  ],
  "what can it do": [
    "Quid facere potes"
  ],
  "so that's how you got here": [
    "Ergo hac via huc venisti"
  ],
  "a sword": [
    "Gladius"
  ],
  "eclipse attack: vampires and gargoyles": [
    "Eclipse Attack: Vampires and Gargoyles"
  ],
  "i had some help": [
    "Auxilium habui"
  ],
  "from katria": [
    "A Katria"
  ],
  "light armor": [
    "Lorica Levis"
  ],
  "take me near the castle to the west": [
    "Fer me ad castellum ad occidentem"
  ],
  "(<global=dlc1ferrycostlarge> gold)": [
    "(<Global=DLC1FerryCostLarge> auri)"
  ],
  "where do the thralls come from": [
    "Unde venerunt servi"
  ],
  "dwemer mechanism": [
    "Mechanismum Dvemere"
  ],
  "soul rend stagger push": [
    "Soul Rend Stagger Push"
  ],
  "vampire armor of major conjuration": [
    "Vampiri Lorica Maioris Vocantis"
  ],
  "nothing right now": [
    "Nihil nunc"
  ],
  "does anything live here": [
    "Quidquid hic vivit"
  ],
  "dlc werewolf fear howl 3": [
    "DLC Werewolf Fear Howl 3"
  ],
  "vyrthur said something about using blood": [
    "Vyrthur aliquid de sanguine dixit"
  ],
  "wounds": [
    "Wounds"
  ],
  "what ended up happening": [
    "Quid in fine accidit"
  ],
  "emitter steam blast": [
    "Emitter Steam Blast"
  ],
  "sprigganearthmotherfx ability": [
    "SprigganEarthMotherFX Ability"
  ],
  "audiorepeateractivator01": [
    "AudioRepeaterActivator01"
  ],
  "warped dwarven sphere": [
    "Sphera Dvemeris Telata"
  ],
  "jiub's opus (page 1)": [
    "Jiubis Opus (Page 1)"
  ],
  "warped dwarven spider": [
    "Aranea Dvemeris Telata"
  ],
  "let's just start at the beginning": [
    "Incipiamus ab initio"
  ],
  "pelagius wing": [
    "Ala Pelagii",
    "Pelagius Ala"
  ],
  "i already have the other three shards": [
    "Tria alia fragmenta iam habeo"
  ],
  "frozen shaman": [
    "Falmeris Flamen Congelatus"
  ],
  "mzinchaleft": [
    "Mzinchaleft"
  ],
  "dawnguard blocking lines": [
    "Aurorae Custodum blocking lines"
  ],
  "scroll of telekinesis": [
    "Codex Telekinesis"
  ],
  "i guess we should head towards your family's home, then": [
    "Credo nos domum familiarem tuuam petere debere"
  ],
  "i'm ready to be turned": [
    "Paratus sum mutari"
  ],
  "summon them you mean": [
    "Eos vocare"
  ],
  "you mentioned a treasure": [
    "Gazam commemoravisti"
  ],
  "jiub's opus (page 8)": [
    "Jiubis Opus (Page 8)"
  ],
  "frozen falmer shaman": [
    "Falmeris Flamen Congelatus"
  ],
  "portal to shrine of radiance": [
    "Portam ad Aram Lucis"
  ],
  "aetherial summons": [
    "Amici Aetheriales"
  ],
  "vampire thrall faction": [
    "Vampire Thrall Faction"
  ],
  "vampire armor of conjuration": [
    "Vampiri Lorica Vocantis"
  ],
  "steam damage": [
    "Steam Damage"
  ],
  "steam valve": [
    "Cymbalon Vaporis"
  ],
  "come with me, \"pilgrim.\"": [
    "Veni mecum, \"peregrinator.\""
  ],
  "twin souls": [
    "Animi Gemini"
  ],
  "i need to find someone named florentius": [
    "Aliquem nomine Florentius quaero"
  ],
  "black mage hood": [
    "Ater Magi Cucullus",
    "Magi Capucium Atrum"
  ],
  "how do i obtain sunhallowed arrows": [
    "Quomodo Sagittas Sole Benedictas accipiam"
  ],
  "got any ideas about the knife": [
    "Habesne cogitationes de hoc cultro"
  ],
  "what do you know about auriel's bow": [
    "Quid scis de Arcu Aurorae"
  ],
  "torch bright": [
    "Torch Bright"
  ],
  "reanimate a powerful dead body to fight for you for <dur> seconds": [
    "Corpus potens tibi revocat <dur> secundas",
    "Revoca corpus potens mortuum ut pro te pugnet <dur> secundas"
  ],
  "who are the dawnguard": [
    "Qui sunt Aurorae Custodes"
  ],
  "sun damage - blessed": [
    "Vulnus Solis - Benedictum"
  ],
  "are you all right": [
    "Esne bene"
  ],
  "soul husk": [
    "Restites Animorum"
  ],
  "falmer warmonger": [
    "Falmer Bellator"
  ],
  "and if she doesn't": [
    "Etsi illa non velit"
  ],
  "soul wait for death fx": [
    "Soul Wait for Death FX"
  ],
  "i think we should part ways": [
    "Credo nos divergere debere"
  ],
  "vampire armor of major destruction": [
    "Vampiri Lorica Maioris Destructionis"
  ],
  "play me a song, \"bard.\"": [
    "Cane mihi carmen, \"cantator.\""
  ],
  "broken dwarven spider": [
    "Aranea Dvemeris Fracta"
  ],
  "you're worried about what we're up against": [
    "Times inimicos nostros"
  ],
  "journal fragment": [
    "Fragmentum Ephemeridis"
  ],
  "venarus vulpin's journal": [
    "Ephemeris Venari Vulpis"
  ],
  "jiub's opus (page 6)": [
    "Jiubis Opus (Page 6)"
  ],
  "what was your campaign against them": [
    "Quid erat pugna contra eis"
  ],
  "what is it now": [
    "Quid nunc"
  ],
  "detect life - predator searcing": [
    "Detect Life - Predator Searcing"
  ],
  "jiub's opus (page 3)": [
    "Jiubis Opus (Page 3)"
  ],
  "how is your work going so far": [
    "Quo fit cum labore tuo"
  ],
  "ingjard": [
    "Ingjard"
  ],
  "is that where you were attacked": [
    "Illicne oppugnatus es"
  ],
  "dragonbone mace": [
    "Draconiossis Maca"
  ],
  "steam construct healing": [
    "Steam Construct Healing"
  ],
  "why are they collecting these souls": [
    "Quamobrem animos colligunt"
  ],
  "dawnguard": [
    "Aurorae Custodes",
    "Venator Vampirorum Aurorae Custodum"
  ],
  "ferry system vendors": [
    "Ferry System Vendors"
  ],
  "you're a \"bard\" / \"merchant\" / \"pilgrim\" ": [
    "Tu es \"cantator\" / \"mercator\" / \"peregrinator\" "
  ],
  "i doubt it": [
    "Id dubito"
  ],
  "super magelight": [
    "Super Magelight"
  ],
  "dlc werewolf fear howl 2": [
    "DLC Werewolf Fear Howl 2"
  ],
  "what do you mean by \"don't draw attention?\"": [
    "Quid sibi vult \"Noli animadversionem trahere?\""
  ],
  "targets that fail to resist are paralyzed for <dur> seconds": [
    "Qui resistere fallunt paralytici sunt <dur> secundas",
    "Targets that fail to resist are paralysed <dur> secundas",
    "Inimici qui non resistunt paralytici fiunt <dur> secundas"
  ],
  "what exactly does it do": [
    "Quid, exacte, facit"
  ],
  "jiub's opus (page 7)": [
    "Jiubis Opus (Page 7)"
  ],
  "your vampiric drain spell absorbs magicka and stamina from your victims for 1 day, but at the cost of <mag> less health": [
    "Magica Vampirica Bibendi absorbet et Magicam et Staminam e victimis I diem, sed pretii <mag> minoris salutis"
  ],
  "why would a dremora attack you": [
    "Quamobrem dremoram te oppugnet"
  ],
  "i do, but you can't leave that way": [
    "Sic, sed hac via discedere non potes"
  ],
  "souless shambler": [
    "Souless Shambler"
  ],
  "volkihar east tower": [
    "Volkiharis Turris Eura"
  ],
  "harkon is dead": [
    "Harkon mortuus est"
  ],
  "calcelmo's tower": [
    "Turris Calcelmi",
    "Calcelmonis Turris"
  ],
  "for <dur> seconds, opponents in melee range take <8> points fire damage per second. targets on fire take extra damage": [
    "<dur> secundas, inimici prope te <8> vulnus ignissingulis secundis accipiunt"
  ],
  "damage health regen": [
    "Damage Health Regen"
  ],
  "your soul was trapped": [
    "Animus tuus captus est"
  ],
  "dawnguard rune axe": [
    "Aurorae Custodum Runatus Securis"
  ],
  "what treasure": [
    "Quae gaza"
  ],
  "do you think we'll meet the ideal masters": [
    "Censesne nos cum Dominis Idealibus conveniamus"
  ],
  "what is so special about your skooma": [
    "Quid est tam speciale de Skooma tua"
  ],
  "do you know where i can find him": [
    "Scisne ubi eum invenire possim"
  ],
  "i need to go there anyway": [
    "Illic tamen petere iam debeo"
  ],
  "who is florentius": [
    "Quis est Florentius"
  ],
  "why not hide it in the soul cairn and then return": [
    "Quidni eum in Terra Animorum celaveris deinde reveneris"
  ],
  "mossy glen cave": [
    "Mossy Glen Spelunca"
  ],
  "dragonbone dagger": [
    "Draconiossis Pugio"
  ],
  "vampire armor of extreme destruction": [
    "Vampiri Lorica Extremae Destructionis"
  ],
  "no, i haven't seen any": [
    "Minime, nullas vidi"
  ],
  "what can you tell me about auriel's bow": [
    "Quid mihi de Arcu Aurorae narrare potes"
  ],
  "any soul gem": [
    "Quaequid gemmanima"
  ],
  "player is turning me into a vampire": [
    "Player is turning me into a vampire"
  ],
  "i made it": [
    "Adveni"
  ],
  "dragonbone bow": [
    "Draconiossis Arcus"
  ],
  "when will you return to tamriel": [
    "Ubi ad Tamrielem revenies"
  ],
  "you're no bard/merchant/pilgrim": [
    "Nullus cantator/mercator/peregrinator es"
  ],
  "the wayshrines are part of the chantry then": [
    "Ergo Arae Viarum partes Chantry sunt"
  ],
  "wayshrines are part of the chantry then": [
    "Ergo Arae Viarum partes Chantry sunt"
  ],
  "what about the scroll": [
    "Quid de codice"
  ],
  "so what ended up happening to you": [
    "Ergo quid tibi accidit"
  ],
  "vampire armor of destruction": [
    "Vampiri Lorica Destructionis"
  ],
  "come with me, \"bard.\"": [
    "Veni mecum, \"cantator.\""
  ],
  "the warrens": [
    "The Warrens"
  ],
  "warrens": [
    "The Warrens"
  ],
  "who can": [
    "Quis potest"
  ],
  "i could use help with the crystals around here": [
    "Auxilium cum crystaleis hic uti possum"
  ],
  "holding cell safe": [
    "Arcam Cellae"
  ],
  "howl of terror": [
    "Ululatio Terroris"
  ],
  "you don't trust vingalmo": [
    "Vingalmoni non credis"
  ],
  "what exactly are you doing": [
    "Quid, exacte, facis"
  ],
  "learn the second word of durnehviir's shout": [
    "Disce verbum secundum clamoris Durnehviris"
  ],
  "how many more": [
    "Quot plurimi"
  ],
  "vampire armor of eminent destruction": [
    "Vampiri Lorica Eminent Destructionis"
  ],
  "withdrawals from the tainted bloodspring lower your magicka and stamina regeneration by 40% for 2 days": [
    "Withdrawals from the tainted Bloodspring lower your Magicka and Stamina regeneration by 40% for 2 days"
  ],
  "vampire armor of minor destruction": [
    "Vampiri Lorica Minoris Destructionis"
  ],
  "ferry drop off marker": [
    "Ferry Drop off Marker"
  ],
  "your vampiric drain spell absorbs magicka and stamina from your victims for <mag> days": [
    "Magica Vampirica Bibendi absorbet et Magicam et Staminam e victimis I diem"
  ],
  "there are others here like you": [
    "Alii ut tu hic sunt"
  ],
  "health damage": [
    "Vulnus Salutis"
  ],
  "here's all of the pages": [
    "Hic habes omnes pagellas"
  ],
  "boots": [
    "Caligae",
    "Dremorae Caligae"
  ],
  "i'm a werewolf": [
    "Versipellis sum"
  ],
  "what will happen if i accept your gift": [
    "Quid accidat"
  ],
  "don't worry, i'm fine": [
    "Noli sollicitari, bene me habeo"
  ],
  "ancient frost atronach": [
    "Atronachum Antiquum Glaciei"
  ],
  "i've heard of you": [
    "De te audivi"
  ],
  "come with me \"bard/merchant/pilgrim\"": [
    "Veni mecum \"cantator/mercator/peregrinator\""
  ],
  "have you been to winterhold before": [
    "Umquamne Ventigaleam petivisti"
  ],
  "vampire and death hound": [
    "Vampire and Death Hound"
  ],
  "spriggan earth mother": [
    "Mater Terrae Sprigganis"
  ],
  "what do you do here": [
    "Quid hic facis"
  ],
  "then what": [
    "Quid postea"
  ],
  "d3l": [
    "D3l"
  ],
  "then how do i even stand a chance": [
    "Quomodo superesse possim"
  ],
  "congratulations": [
    "Tibi gratulor"
  ],
  "and... thanks, katria": [
    "Et... gratias tibi do, Katriae"
  ],
  "emerald paragon": [
    "Paragon Smaragdum"
  ],
  "auriel's arrows blocker": [
    "Auriel's Arrows Blocker"
  ],
  "the daedric princes disgust me": [
    "Principes daedrici mihi displicent"
  ],
  "daedric princes disgust me": [
    "Principes daedrici mihi displicent"
  ],
  "keeper abilities": [
    "Keeper Abilities"
  ],
  "how has it affected your family": [
    "Quomodo familiam tuam affecit"
  ],
  "calls undead from soul cairn for <dur> seconds": [
    "Vocat amortuos a Terra Animorum <dur> secundas"
  ],
  "discovering ruunvald, vol. iii": [
    "Ruunvald Inveniens, Vol. III"
  ],
  "you made a pact with molag bal": [
    "Pactum cum Molag Bol fecisti"
  ],
  "i don't have 25 husks": [
    "XXV Fragmenta Animorum non habeo"
  ],
  "she's asking me to take her home, to some castle": [
    "Mihi sausit ut eam domum, castellum aliquod, ferrem"
  ],
  "potion of blood": [
    "Potio Sanguinis"
  ],
  "i'm not ready yet": [
    "Nondum paratus sum",
    "Non iam paratus sum"
  ],
  "give me more time": [
    "Da mihi plus temporis"
  ],
  "minorne": [
    "Minorne"
  ],
  "alvide": [
    "Alvide"
  ],
  "carefully gather the bark": [
    "Caute corticem capere"
  ],
  "retrieve the rueful axe with barbas": [
    "Inveni Securem Rutae cum Barba",
    "Refer Securem Rutae cum Barbas"
  ],
  "bone hawk amulet": [
    "Accipitris Ossis Monile"
  ],
  "join barbas outside rimerock burrow": [
    "Conveni cum Barba foris Cavernae Lapidirimi",
    "Adiunge Barbam foris Cavarnae Pruinaelapidis"
  ],
  "does that mean you can take us to it now": [
    "Ergo nos ad eam nunc portare potes"
  ],
  "barknar": [
    "Barknar"
  ],
  "a daedra's best friend": [
    "Amicissimus Daedrae"
  ],
  "daedra's best friend": [
    "Amicissimus Daedrae"
  ],
  "discovering ruunvald, vol. ii": [
    "Ruunvald Inveniens, Vol. II"
  ],
  "forgotten vale books quest": [
    "Forgotten Vale Books Quest"
  ],
  "soul cairn summon": [
    "Vocatio e Terra Animorum"
  ],
  "drains <mag> points of magicka per second for <dur> seconds": [
    "Removit <mag> Magicamsingulis secundis <dur> secundas"
  ],
  "canticle bark": [
    "Cortex Canticle"
  ],
  "boss fightweather on": [
    "Boss fightWeather On"
  ],
  "reaper's robes": [
    "Saturni Peplum"
  ],
  "dlc1soulcairnmerhcanthello": [
    "DLC1SoulCairnMerhcantHello"
  ],
  "fair enough": [
    "Satis bene mihi videtur"
  ],
  "damage health": [
    "Noce Saluti",
    "Damage Health",
    "Vulnus Salutis"
  ],
  "diary of faire agarwen": [
    "Ephemeris Agarwenis Pulchrae"
  ],
  "spare me the details": [
    "Parce mihi. Noli per singula ini"
  ],
  "you said something happened to the hall of vigilants": [
    "Dixisti aliquid ad Aulam Vigilantium accidisse"
  ],
  "dialogueferrysystemidle": [
    "DialogueFerrySystemIdle"
  ],
  "jayri": [
    "Jayri"
  ],
  "you become invulnerable, but unable to attack": [
    "Invulnerabilis es, sed oppugnare non potes",
    "Invulnerabilis fis, sed oppugnare non potes"
  ],
  "however you recover magicka and stamina": [
    "Magicam tamen et staminam recuperas",
    "Magicam tamen staminamque recuperas"
  ],
  "i would never use serana like that": [
    "Numquam Serana hoc in modo utar"
  ],
  "tolan said you're a vampire hunter": [
    "Tolan dixit te venatorem vampirorum esse"
  ],
  "katria's journal": [
    "Ephemeris Katriae"
  ],
  "unknown book, vol. iv": [
    "Liber Ignotus, Vol. IV"
  ],
  "extra damage 2": [
    "Extra Damage 2"
  ],
  "shut off the steam": [
    "Claude Vaporem"
  ],
  "vampire lords start with a powerful drain life spell in their right hand, a reanimate corpse spell in their left hand": [
    "Dominus Vampirus incipit cum magica 'Bibe Vitam' manu recta et redde ad vitam manu laeva"
  ],
  "the power button transforms him into a cloud of bats that reforms in the distance": [
    "Boto potestatis te in nubem vespertilionum mutat"
  ],
  "power button transforms him into a cloud of bats that reforms in the distance": [
    "Boto potestatis te in nubem vespertilionum mutat"
  ],
  "reduced stamina regen": [
    "Reduced Stamina Regen",
    "Recuperatio Staminae Diminuta"
  ],
  "unknown book, vol. i": [
    "Liber Ignotus, Vol. I"
  ],
  "vampire castle controller": [
    "Vampire Castle Controller"
  ],
  "extra damage 1.5": [
    "Extra Damage 1.5"
  ],
  "dlc1 soulcairn ghost ability": [
    "DLC1 SoulCairn Ghost Ability"
  ],
  "sorry, maybe next time": [
    "Ignosce, fortasse tempore proximo"
  ],
  "maybe your mother didn't trust you, either": [
    "Fortasse mater tibi quoque non confisa est"
  ],
  "we should check back with isran": [
    "Isran adloqui debemus"
  ],
  "paragon socket": [
    "Foramen Paragoni"
  ],
  "what do you have against the vigilants": [
    "Quid contra Vigilantes habes"
  ],
  "tranform into the vampire lord": [
    "Muta in Vampirum Dominum"
  ],
  "isran's at fort dawnguard": [
    "Isran in Castello Aurorae Custodum est"
  ],
  "wounded frostbite spider": [
    "Aranea Adustus Nivibus Ingens",
    "Adustus Nivibus Aranea Vulneratus"
  ],
  "does harkon even care about you anymore": [
    "Harkonne iam de te curat"
  ],
  "i admire your devotion": [
    "Devotionem tuam admiror"
  ],
  "slows time for <dur> seconds": [
    "Tempus lentius it <dur> secundas",
    "Tempus lentius est <dur> secundas"
  ],
  "sceolang": [
    "Sceolang"
  ],
  "feed on the moth priest to make him your thrall": [
    "Vescere Sacerdote Papilionis ut eum servum tuum facias"
  ],
  "find a moth priest's knife": [
    "Inveni Cultrum Sacerdotis Papilionis"
  ],
  "mist man": [
    "Nebulavir"
  ],
  "nice castle you've got here": [
    "Castellum Pulchrum hic habes"
  ],
  "then we kill him, before he kills us": [
    "Ergo eum necabimus, ante nos necaverit"
  ],
  "pour water": [
    "Funde Aquam"
  ],
  "the bow belongs to me now": [
    "Arcus meus nunc est"
  ],
  "bow belongs to me now": [
    "Arcus meus nunc est"
  ],
  "invisible chair marker": [
    "Invisible Chair Marker"
  ],
  "we have the bow, he'll listen to us": [
    "Arcum habemus, nos auscultabit"
  ],
  "your kind is a blight on this world": [
    "Homines tui generis venenum mundo sunt"
  ],
  "bats effect": [
    "Bats Effect"
  ],
  "ready for some bloodshed, beast": [
    "Paratusne es desanguinationis, bestia"
  ],
  "dlc sun eclipse self spell": [
    "DLC Sun Eclipse Self Spell"
  ],
  "unknown book, vol. iii": [
    "Liber Ignotus, Vol. III"
  ],
  "moths barely make a sound, let alone speak": [
    "Papiliones fere nullum sonitum faciunt. Certe non loquuntur"
  ],
  "i'm ready to learn about my new powers": [
    "Paratus sum ad de novis potestatibus discendum"
  ],
  "moric sidrey": [
    "Moric Sidrey"
  ],
  "reaperr's hood": [
    "Saturni Cucullus"
  ],
  "any interest in ancient falmer tomes": [
    "Tibine Libri Falmeres Antiqui intersunt"
  ],
  "dlc1soulcairn ghost visuals": [
    "DLC1SoulCairn Ghost Visuals"
  ],
  "what went wrong": [
    "Quid peccavit",
    "Quid prave accidit"
  ],
  "dawnguard helmet": [
    "Aurorae Custodum Galea"
  ],
  "drains <mag> points of health per second for <dur> seconds": [
    "Removit <mag> Salutemsingulis secundis <dur> secundas"
  ],
  "draugr faction": [
    "Draugr Faction"
  ],
  "there must be something you want": [
    "Aliquid velle debes"
  ],
  "vampire gauntlets": [
    "Vampiri Manubria"
  ],
  "it sounds like you weren't thinking clearly": [
    "Mihi videtur te non clare cogitavisse"
  ],
  "vamplordcapemesh": [
    "vamplordcapemesh"
  ],
  "depends how much you're paying": [
    "In pretio tuo pendet"
  ],
  "skeleton horse leg": [
    "Crus Equi Osseosi"
  ],
  "hack and slash": [
    "Edole et Seca",
    "Edole et Scinde",
    "Contrunce et Seca"
  ],
  "sern": [
    "Sern"
  ],
  "ragnvald temple": [
    "Ragnvald Templum",
    "Templum Ragnvaldis"
  ],
  "i am ready to receive the ancient blood": [
    "Paratus sum sanguinem antiquum accipiendo"
  ],
  "if we do, he'll have to die": [
    "Si id facimus, ille moriendus est"
  ],
  "summon undead from soul cairn": [
    "Voca Amortuos e Terra Animorum"
  ],
  "saint jiub's kiss": [
    "Basium Sancti Jiubis"
  ],
  "a spell tome": [
    "Liber Magicae"
  ],
  "spell tome": [
    "Liber Magicae"
  ],
  "do you ever think about curing yourself": [
    "De te curando umquam censes"
  ],
  "then let's face him, together": [
    "Ergo eum confrontemus, coram"
  ],
  "is there any more aetherium": [
    "Estne plus Aetherii"
  ],
  "i would guess it came from a daedric lord": [
    "A domino daedrico, ut puto"
  ],
  "equivalent to feeding on human blood for vampires": [
    "Ut sanguinem humanum bibens"
  ],
  "shellbug": [
    "Folliculicimex"
  ],
  "z1m": [
    "Z1m"
  ],
  "are there other vigilants still out there": [
    "Suntne alii Vigilantes iam foris"
  ],
  "what now, isran": [
    "Quid nunc, Isran"
  ],
  "what is it between you two": [
    "Quid inter vos est"
  ],
  "i would never harm serana": [
    "Numquam Seranad noceam"
  ],
  "she's too important to me": [
    "Nimis magni momenti mihi est"
  ],
  "staff of ruunvald": [
    "Rudis Ruunvaldis"
  ],
  "travel to": [
    "Ii ad"
  ],
  "you're an insult to our kind": [
    "Malidictum hominibus ut nobis es"
  ],
  "i trust her to do the right thing": [
    "Ei confido ut rem fas faciat"
  ],
  "maybe we can reason with him": [
    "Fortasse cum eo argumentari possimus"
  ],
  "enter melee mode": [
    "Modum Pugnorum Intra"
  ],
  "deal": [
    "Consentio",
    "Consentior"
  ],
  "(give book)": [
    "(Da Librum)"
  ],
  "draw water": [
    "Sume Aquam"
  ],
  "how did i get here": [
    "Quomod huc veni"
  ],
  "sapphire paragon": [
    "Paragon Sapphirum"
  ],
  "i don't know much about it": [
    "Multum de eo nescio"
  ],
  "discovering ruunvald, vol. i": [
    "Ruunvald Inveniens, Vol. I"
  ],
  "reaper bracers": [
    "Saturni Bracers"
  ],
  "i have another ancient falmer tome": [
    "Alium librum Falmerem tibi habeo"
  ],
  "bran": [
    "Bran"
  ],
  "vampire robes": [
    "Vampiri Peplum"
  ],
  "let's run as far from him as we can": [
    "Tam procul ab eo curramus ut possumus"
  ],
  "there are some things i want to ask you about": [
    "De aliis rebus te rogare volo"
  ],
  "whispering spirit": [
    "Animus Sussurans"
  ],
  "undead": [
    "Undead"
  ],
  "qoth": [
    "Qoth"
  ],
  "ruby": [
    "Rubinus"
  ],
  "emerald": [
    "Smaragdus"
  ],
  "sapphire": [
    "Sapphira",
    "Sapphirus"
  ],
  "extra damage 2.5": [
    "Extra Damage 2.5"
  ],
  "multiple summon": [
    "Multiple Summon"
  ],
  "the betrayed": [
    "Proditi"
  ],
  "betrayed": [
    "Proditi"
  ],
  "we can't waste time on cryptic clues": [
    "Tempus in aenigmata consumere non possumus"
  ],
  "blocking dialogue for transformed werewolves": [
    "Blocking dialogue for transformed werewolves"
  ],
  "attract ancestor moth swarms (<global=dlc1vq06mothsfound>/<global=dlc1vq06mothstotal>)": [
    "Voca Greges Papilionum Antiquorum (<Global=DLC1VQ06MothsFound>/<Global=DLC1VQ06MothsTotal>)"
  ],
  "command the moth priest to go to volkihar castle": [
    "Impera Sacerdoti Papilionis ut Castellum Volkiharis petat"
  ],
  "power of the blood": [
    "Vis Sanguinis"
  ],
  "refugee dialogue": [
    "Refugee Dialogue"
  ],
  "destroying the dawnguard": [
    "Delens Aurorae Custodes"
  ],
  "arrows and crossbow bolts can be created at smithing forges": [
    "Sagittas et Sagittulas ad fabricas facere potes"
  ],
  "bring ancient falmer tome to urag-gro shub": [
    "Fer Librum Falmerem Antiquum Uragi gro-Shub"
  ],
  "hunter relocation program": [
    "Hunter Relocation Program"
  ],
  "dog hire quest": [
    "Dog Hire Quest"
  ],
  "i have news, but i wouldn't call it good": [
    "Nuntium habeo, sed bonum"
  ],
  "you want to go hunt some vampires": [
    "Visne vampiros venari"
  ],
  "let's sate our hunger together": [
    "Iuncti esuriem satisfaciamus"
  ],
  "silence, cur": [
    "Silentium, canis"
  ],
  "no meat for you today": [
    "Nullus carnis tibi hodie"
  ],
  "garmr": [
    "Garmr"
  ],
  "husky": [
    "Husky"
  ],
  "`": [
    "`"
  ],
  "extra damage 3": [
    "Extra Damage 3"
  ],
  "cusith": [
    "CuSith"
  ],
  "do you regret becoming a vampire": [
    "Te paenitet vampirum esse"
  ],
  "moric": [
    "Moric"
  ],
  "candlestick": [
    "Ceroferarium"
  ],
  "how about right here, in the castle": [
    "Quidni hic, in castello ipso"
  ],
  "watchman": [
    "Custos"
  ],
  "elder scroll quest item handler": [
    "Codex Veterrimus Quest Item Handler"
  ],
  "olda": [
    "Olda"
  ],
  "elder scroll": [
    "Codex Veterrimus",
    "Codex Vestustissimus"
  ],
  "you never intended to let me live": [
    "Numquam in animo habuiste me vivere sinere"
  ],
  "how's a moth related to the elder scrolls": [
    "Quid agit papilio cum Codicibus Veterrimis"
  ],
  "blood ingestion": [
    "Blood Ingestion"
  ],
  "that's all i wanted to ask": [
    "Illa sunt omnia rogata"
  ],
  "saliah": [
    "Saliah"
  ],
  "were you always a vampire": [
    "Semperne vampira fuisti"
  ],
  "summon wolves 3": [
    "Summon Wolves 3"
  ],
  "it seems they were looking for a woman who was sealed in a crypt": [
    "Mihi videtur eos feminam in hypogaeo quaesivisse"
  ],
  "how does that help the moth priest": [
    "Quomodo auxilium Sacerdoti Papilionis dat"
  ],
  "dawnstar docks": [
    "Luciferis Navale"
  ],
  "they also have an elder scroll": [
    "Illi quoque Codicem Veterrimum habent"
  ],
  "the woman was a vampire, and they wanted her back": [
    "Femina erat vampira, et ille eam recipere volebant"
  ],
  "woman was a vampire, and they wanted her back": [
    "Femina erat vampira, et ille eam recipere volebant"
  ],
  "undead take <mag> points of sun damage": [
    "Amortuos <mag> vulnus solis accipiunt"
  ],
  "other targets take half of that": [
    "Alii inimici dimidiam partem accipiunt"
  ],
  "kill the dawnguard leaders": [
    "Neca duces Aurorae Custodum"
  ],
  "hireable dog/deathhound": [
    "Hireable Husky/Death Hound"
  ],
  "unknown book, vol. ii": [
    "Liber Ignotus, Vol. II"
  ],
  "i want to hear it": [
    "Volo id audire"
  ],
  "vampire hood": [
    "Vampire Hood",
    "Vampiri Cucullus"
  ],
  "molag bal created the first vampire": [
    "Molag Bol primum vampirum fecit"
  ],
  "reduced magicka regen": [
    "Reduced Magicka Regen",
    "Recuperatio Magicae Diminuta"
  ],
  "lynoit": [
    "Lynoit"
  ],
  "vampire gloves": [
    "Vampiri Digitabula"
  ],
  "scrap of paper": [
    "Fragmentum Chartae"
  ],
  "arvak's skull": [
    "Calvaria Arvakis"
  ],
  "vigilant charmed effect": [
    "Vigilant Charmed Effect"
  ],
  "basin": [
    "Labellum"
  ],
  "this is about more than killing vampires": [
    "Hoc de pluribus quam vampiris necando agitur"
  ],
  "hatred born of your neglect": [
    "Ira e neglegentia nata"
  ],
  "come on boy, let's go": [
    "Veni cane, eamus"
  ],
  "enough of this": [
    "Satis"
  ],
  "how did you actually become a vampire, then": [
    "Ergo quomodo in vampiram mutata es"
  ],
  "we should honor our agreement with him": [
    "Foedus cum eo honorare debemus"
  ],
  "assassin's writ": [
    "Mandata Sicarii"
  ],
  "ruby paragon": [
    "Paragon Rubini"
  ],
  "soulmote": [
    "Soulmote"
  ],
  "drains <mag> points of stamina per second for <dur> seconds": [
    "Removit <mag> Staminam singulis secundis <dur> secundas"
  ],
  "discovering ruunvald, vol. iv": [
    "Ruunvald Inveniens, Vol. IV"
  ],
  "katria weaknesses": [
    "Katria Weaknesses"
  ],
  "speak to gunmar": [
    "Adloquere Gunmarem"
  ],
  "i've killed the dawnguard leaders": [
    "Duces Aurorae Custodum necavi"
  ],
  "i'm only here to kill you": [
    "Modo hic sum ut te necam"
  ],
  "i'm a follower of molag bal, myself": [
    "Cultor Molag Bol sum"
  ],
  "dawnguard vampire hunter": [
    "Venator Vampirorum Aurorae Custodum"
  ],
  "vigilant vampire hunter": [
    "Vigilans Venator Vampirorum"
  ],
  "we need your help, isran": [
    "Auxilium tuum quaerimus, Isran"
  ],
  "shellbug chitin": [
    "Cimiconcae Conca"
  ],
  "journal of mirtil angoth": [
    "Ephemeris Mirtilis Angothis"
  ],
  "i have auriel's bow": [
    "Habeo Arcum Aurorae"
  ],
  "volk": [
    "Volk"
  ],
  "i'm going to hold onto it": [
    "Hoc in tempore eum in manu retineam"
  ],
  "is it normal for moth priests to go blind": [
    "Estne normalis Sacerdotibus Papiliones caecos fieri"
  ],
  "who are the spectral prelates you mentioned": [
    "Qui sunt Prelati spectrales quos commemoravisti"
  ],
  "large sack": [
    "Saccus Magnus",
    "Follicula Magna"
  ],
  "all dawnguard weapons and armor": [
    "All Dawnguard Weapons and Armour"
  ],
  "you're not a vampire anymore": [
    "Non iam vampira es"
  ],
  "can you give me bloodcursed arrows": [
    "Potesne mihi Sagitta Sanguine Maledicta dare"
  ],
  "witchlight abilities": [
    "Witchlight abilities"
  ],
  "give me everything you are carrying": [
    "Da mihi omnia quae portas"
  ],
  "spell tome: vampire's bane": [
    "Liber Magicae: Inimicus Vampiri"
  ],
  "i'm sure": [
    "Certe",
    "Certus sum"
  ],
  "witchlight": [
    "Witchlight"
  ],
  "harmless lightning bolt": [
    "Harmless Lightning Bolt"
  ],
  "vyrthur was saying something about vampire blood and the bow": [
    "Vyrthur aliquid de sanguine vampirico et de arcu"
  ],
  "conjure mistman": [
    "Voca Nebulavirum"
  ],
  "can you teach me how to make a crossbow": [
    "Potesne me artem Manuballitas faciendi docere"
  ],
  "i imagine you know why we're here": [
    "Credo te scire quamobrem nos hic esse"
  ],
  "mothpriest thrall effect 1": [
    "Mothpriest Thrall Effect 1"
  ],
  "we're not close anymore": [
    "Non iam familiares sumus"
  ],
  "i'm used to a little more quiet on my journeys": [
    "Soleo plus silentii in itineribus habeo"
  ],
  "mountain flower": [
    "Flos Montis"
  ],
  "audiotemplatehorseflames": [
    "AudioTemplateHorseFlames"
  ],
  "look what it did to your family": [
    "Cogita de vulnere quod ad familiam tuam fecit"
  ],
  "volkihar cathedral": [
    "Volkiharis Cathedrale"
  ],
  "defeat the enthralled moth priest": [
    "Supera Sacerdotem Papilionis Imperatum"
  ],
  "calm down": [
    "Compone te",
    "Mollis esto"
  ],
  "who's arvak": [
    "Quis est Arvak"
  ],
  "ball of sunlight that does <mag> points of damage to undead": [
    "Circulus lucis solis quod <mag> puncta vulneris ad amortuos facit"
  ],
  "sun fire": [
    "Sun Fire"
  ],
  "the sultry argonian bard, v1": [
    "Cantator Argonius Voluptuus, vI"
  ],
  "sultry argonian bard, v1": [
    "Cantator Argonius Voluptuus, vI"
  ],
  "i'm married": [
    "Uxorem habeo"
  ],
  "(lie)": [
    "(Mentire)"
  ],
  "hairlinemalesnowelf03": [
    "HairLineMaleSnowElf03"
  ],
  "charmed vigilant": [
    "Vigilans Imperatus"
  ],
  "bats knockdown": [
    "Bats Knockdown"
  ],
  "are you ready to do this": [
    "Esne paratus hoc facere"
  ],
  "revert to human": [
    "Reverte ad Hominem"
  ],
  "the empire supports elisif, but there are many in skyrim loyal to ulfric": [
    "Imperium Elisifi favebat, sed multi sunt in Caelifine qui Vercingetorigi favent"
  ],
  "empire supports elisif, but there are many in skyrim loyal to ulfric": [
    "Imperium Elisifi favebat, sed multi sunt in Caelifine qui Vercingetorigi favent"
  ],
  "weystone source": [
    "Fons Weystone"
  ],
  "harkon's invulnerability shield": [
    "Harkon's Invulnerability Shield"
  ],
  "we're very close": [
    "Proximi sumus"
  ],
  "i can't wait to see them again": [
    "Utinam eos mox videam"
  ],
  "harkon's invulnerability": [
    "Harkon's Invulnerability"
  ],
  "revert form": [
    "Reverte Formam"
  ],
  "conjure boneman": [
    "Voca Sagittarium Osseosum"
  ],
  "the empire supported elisif, but ulfric now controls skyrim": [
    "Imperium Elisifi favebat, sed Vercingetorix nunc Caelifini imperat"
  ],
  "empire supported elisif, but ulfric now controls skyrim": [
    "Imperium Elisifi favebat, sed Vercingetorix nunc Caelifini imperat"
  ],
  "traveler": [
    "Peregrinator"
  ],
  "malkus": [
    "Malkus"
  ],
  "you mean, do i get lonely": [
    "Rogas num soleterius sentiam"
  ],
  "for <dur> seconds nearby opponents take <8> points of shock damage and half magicka damage": [
    "<dur> secundas, inimici prope te <8> vulnus electricitatis singulis secundis accipiunt",
    "<dur> secundas inimici prope te accipiunt <8> of vulneris electricitatis et dimidium magicae vulneris"
  ],
  "were you close with them": [
    "Fuisti propinqua cum eis"
  ],
  "deactivate the magic barrier": [
    "Deactiva praesidium magicum"
  ],
  "i need you to do something for me": [
    "Fac aliquid mihi, catule"
  ],
  "font absorb health": [
    "Font Absorb Health"
  ],
  "shield charge": [
    "Cursus Scuti",
    "Shield Charge"
  ],
  "can you tell me more about the old dawnguard": [
    "Potesne mihi plus de Aurorae Custodibus veteribus docere"
  ],
  "nevermind, i don't need you to do anything right now": [
    "Ignosce, nihil abs te nunc rogare debe"
  ],
  "lightning apparatus": [
    "Apparatus Tonitrus"
  ],
  "you could be your own person again": [
    "Homo tuo ipso iterum esse posses"
  ],
  "it's fine": [
    "Satis"
  ],
  "so you'll help us": [
    "Sic, nos adiuvabis"
  ],
  "armor - wisp cloak": [
    "Armour - Wisp Cloak"
  ],
  "font absorb stamina": [
    "Font Absorb Stamina"
  ],
  "each day spent as a vampire without feeding increases your powers": [
    "Omnes dies sine bibendo ut vampirus potestates vampiricas diminuunt"
  ],
  "sunlight reduces, or even eliminates your ability to regenerate health, magicka or stamina": [
    "Lux solis diminuit abilitatem salutem, magicam, staminamque recuperandi"
  ],
  "feed on a sleeping victim, or someone under your seduction spell to reduce sun damage": [
    "Bibe sanguinem e dormiente vel e aliquo sub magica tua seductionis"
  ],
  "gargoyle visual fx": [
    "Gargoyle Visual FX"
  ],
  "vanik": [
    "Vanik"
  ],
  "sprint hammer": [
    "Sprint Hammer"
  ],
  "lightning attractor": [
    "Attractore Fulminis"
  ],
  "capture the moth priest": [
    "Cape Sacerdotem Papilionis"
  ],
  "font absorb magicka": [
    "Font Absorb Magicka"
  ],
  "spell tome: conjure boneman": [
    "Liber Magicae: Voca Ossavirum"
  ],
  "the... empire. from cyrodiil": [
    "Imperium... A Cyrodiil"
  ],
  "sorry, i'll drop it": [
    "Ignosce, de eo non iam loquar"
  ],
  "conjure wrathman": [
    "Hominem Iracundiae Voca"
  ],
  "slay harkon": [
    "Neca Harkon"
  ],
  "spell tome: conjure mistman": [
    "Liber Magicae: Voca Nebulavir"
  ],
  "enthralled mothpriestfx 1": [
    "Enthralled MothpriestFX 1"
  ],
  "reaper shard receptacle": [
    "Fragment Messoris Receptaculum"
  ],
  "hairfemalevalerica": [
    "HairFemaleValerica"
  ],
  "keepersoulspell": [
    "KeeperSoulSpell"
  ],
  "gargoylemeleeartholder": [
    "GargoyleMeleeArtHolder"
  ],
  "you took a big risk coming here": [
    "Huc venire periculum magnum tibi fuit"
  ],
  "dlc1 hunter base scene 9": [
    "DLC1 Hunter Base Scene 9"
  ],
  "dlc1 hunter base scene 10": [
    "DLC1 Hunter Base Scene 10"
  ],
  "slow cloak": [
    "Slow Cloak"
  ],
  "forebears' holdout": [
    "Refugitum Maiorum"
  ],
  "enemy scouts patrolling location": [
    "Enemy Scouts Patrolling Location"
  ],
  "special attack: vampires vs player dawnguard": [
    "SPECIAL ATTACK: Vampires vs Player Aurorae Custodum"
  ],
  "special attack: dawnguard vs player vampire": [
    "SPECIAL ATTACK: Aurorae Custodes vs Player Vampire"
  ],
  "disguised vampire lord - change location": [
    "Disguised Vampire Lord - Change Location"
  ],
  "deathhound fx": [
    "DeathHound FX"
  ],
  "can the betrayed ever be cured": [
    "Possuntne Proditi umquam remedium accipere"
  ],
  "elisif of solitude will be high queen": [
    "Elisif Solitudinis regina alta erit"
  ],
  "(dismiss current animal)": [
    "(Dismitte animal alterum)"
  ],
  "hairmalesnowelf06": [
    "HairMaleSnowElf06"
  ],
  "ulfric stormcloak is the true high king": [
    "Vercingetorix Tempestatamiculus est Rex Altus Verus"
  ],
  "bone hawk": [
    "Accipiter Ossium"
  ],
  "no, i'm here to prevent our annihilation": [
    "Minime, hic sum ut adnihilationem nostram negem"
  ],
  "weystone focus": [
    "Focus Weystone"
  ],
  "i'm glad you're with me": [
    "Gaudeo quod tu mecum es"
  ],
  "i've already got an animal with me, maybe next time": [
    "Animal mecum iam habeo, fortasse proxima occasione"
  ],
  "bed roll ground": [
    "Lecus Humi",
    "Bed Roll Ground"
  ],
  "bone hawk feathers": [
    "Pennae Accipitris Ossium"
  ],
  "testspell": [
    "TestSpell"
  ],
  "hairmalesnowelf03": [
    "HairMaleSnowElf03"
  ],
  "we didn't really get along": [
    "Res inter nos bonae non erant"
  ],
  "best that i haven't seen them in a while": [
    "Melius mihi est eis non videre"
  ],
  "i think i'd feel more comfortable": [
    "Credo me commodorium esse"
  ],
  "harkon is invulnerable while casting this spell": [
    "Harkon is invulnerable while casting this spell"
  ],
  "does it bother you that we're working with your father": [
    "Nonne te vexat quod cum patre tuo laboramus"
  ],
  "chaurus effect": [
    "Chaurus effect"
  ],
  "reaper's lair": [
    "Saturni Cubile"
  ],
  "then there's no hope for them": [
    "Ergo nulla spes ei est"
  ],
  "feral vampire": [
    "Vampira Fera"
  ],
  "time for you to head home": [
    "Tempus est ut domum petas, catule"
  ],
  "all dawnguard misc or other items": [
    "All Dawnguard Misc or Other Items"
  ],
  "prelate sidanyis": [
    "Prelatus Sidanyis"
  ],
  "summons a mistman from the soul cairn for <dur> seconds wherever the caster is pointing": [
    "Nebulavirum e Terra Animorum vocat <dur> secundas ubi demonstras vocat"
  ],
  "what do you mean... \"lost himself\"": [
    "Quid sibi vult... \"Se amisit\""
  ],
  "i'd love to have you along": [
    "Spero ut te mecum venias"
  ],
  "i'm not going to take your blood right now": [
    "Sanguinem tuum non accipere nolo"
  ],
  "we have a happy life together": [
    "Vita felix una habemus"
  ],
  "slow time effect": [
    "Tempus Lentum",
    "Slow Time Effect"
  ],
  "will i be able to keep the ring": [
    "Possumne anulum habere"
  ],
  "you talk about being lonely a lot": [
    "De solitudine saepe loqueris"
  ],
  "yellow mountain flower": [
    "Flos Flavus Montis"
  ],
  "dawnguard scout": [
    "Explorator Aurorae Custodum"
  ],
  "that's a good way to be thinking": [
    "Modus operandi bonus mihi videtur"
  ],
  "where do i begin": [
    "Ubi incipiam",
    "Ubi incipimus"
  ],
  "i don't think i can help you with this": [
    "Credo me te adiuvare non posse"
  ],
  "there's my wife": [
    "Illic est uxor"
  ],
  "bats damage": [
    "Bats Damage"
  ],
  "this isn't going to end well, is it": [
    "Finis huius rei pulcra non erit"
  ],
  "where did the dawnguard come from": [
    "Unde venerunt Aurorae Custodes"
  ],
  "as long as you're ready to kill him, that's all that matters": [
    "Si paratus es eum necare, satis mihi est"
  ],
  "soul fissure": [
    "Fossa Animorum"
  ],
  "it sounds like you don't like either of them very much": [
    "Mihi videtur eos ambos in odio habuisse"
  ],
  "soul cairn soul wisp fx": [
    "Soul Cairn Soul Wisp FX"
  ],
  "saint jiub's opus": [
    "Saint Jiubis Opus"
  ],
  "mothpriest thrall effect 2": [
    "Mothpriest Thrall Effect 2"
  ],
  "i want to come with you": [
    "Volo tecum ire"
  ],
  "do you know anything about the old dawnguard": [
    "Scisne quid de Aurorae Custodibus veteribus"
  ],
  "slow cloak effect": [
    "Slow Cloak Effect"
  ],
  "that's too bad": [
    "Pro dolore",
    "Me paenitet"
  ],
  "the empire supports elisif, and killed ulfric for his rebellion": [
    "Imperium Elisifi favebat, et Vercingetorigem pro rebus novis eius necavit"
  ],
  "empire supports elisif, and killed ulfric for his rebellion": [
    "Imperium Elisifi favebat, et Vercingetorigem pro rebus novis eius necavit"
  ],
  "hairmalesnowelf02": [
    "HairMaleSnowElf02"
  ],
  "can i use your blood, then": [
    "Ergo, possumne sanguine tuo uti"
  ],
  "i was wondering when i'd see you again": [
    "Miratus sum quando me te iterum visurum esse"
  ],
  "visuals": [
    "Visuals"
  ],
  "vampire scout": [
    "Explorator Vampirus"
  ],
  "harkon's sword": [
    "Gladius Harkonis"
  ],
  "i don't think they'll involve you": [
    "Non tecum aguntur"
  ],
  "audiotemplatetrollfollower": [
    "AudioTemplateTrollFollower"
  ],
  "your parents didn't make it easy on you": [
    "Patres res tibi faciles non fecerunt"
  ],
  "have you ever seen anything like this": [
    "Umquamne quid hoc simile vidisti"
  ],
  "i want you to turn me into a vampire": [
    "Volo vampirum esse"
  ],
  "this matters to me": [
    "Mihi interest"
  ],
  "do you still feel that way": [
    "Iam hoc modo sentis"
  ],
  "what about me": [
    "Quid de me"
  ],
  "find arvak's skull in the soul cairn": [
    "Inveni Calvariam Arvakis in Terra Animorum"
  ],
  "increased speed": [
    "Increased Speed"
  ],
  "talk with barbas": [
    "Adloquere Barbam"
  ],
  "that's actually a matter for debate": [
    "Disputabilis est"
  ],
  "if you want to cure yourself, i'll support you": [
    "Si te curare vis, tibi auxilium dabo"
  ],
  "tell me about your family": [
    "Dic mihi de familia tua"
  ],
  "summons a boneman archer from the soul cairn for <dur> seconds wherever the caster is pointing": [
    "Sagittarium Osseosum e Terra Animorum <dur> secundas vocat"
  ],
  "they were good people": [
    "Boni erant"
  ],
  "i miss them": [
    "Eos desidero"
  ],
  "constant fx for death hounds": [
    "Constant fx for Death Hounds"
  ],
  "maleeyessnowelfvampire": [
    "MaleEyesSnowElfVampire"
  ],
  "i try": [
    "Conor"
  ],
  "chaurus hunter ambush fx": [
    "Chaurus Hunter Ambush FX"
  ],
  "do you still feel lonely": [
    "Iam desertus sentis"
  ],
  "i'd like to treat some arrows with your blood": [
    "Volo nonnullas sagittas in sanguinem tuum ponere"
  ],
  "no, but we're all better off this way": [
    "Minime, sed hac via melior nos omnibus est"
  ],
  "harkon font absorb": [
    "Harkon Font Absorb"
  ],
  "i never knew them": [
    "Eos numquam novi"
  ],
  "i grew up alone": [
    "Solus iuveni"
  ],
  "summons a wrathman from the soul cairn for <dur> seconds wherever the caster is pointing": [
    "Hominem Iracundiae e Terra Animorum <dur> secundas vocat"
  ],
  "you think he'll try to kill us": [
    "Censes eum nos necare temptaturum esse"
  ],
  "he'll want to use it": [
    "Eo uti vult"
  ],
  "we'll celebrate our victory": [
    "Victoriam nostram celebrabimus"
  ],
  "you're a daughter of coldharbour": [
    "Filia Frigidinavalis es"
  ],
  "why would it matter more to me": [
    "Quamobrem maioris momenti mihi esset"
  ],
  "so what do we do": [
    "Ergo quid faciemus"
  ],
  "have you thought about getting cured of vampirism": [
    "Umquamne de cura vampirismi putavisti"
  ],
  "will it be hard for you if we have to kill him": [
    "Eritne difficile tibi si eum necare debebimus"
  ],
  "spell tome: stendarr's aura": [
    "Liber Magicae: Shoris Aura"
  ],
  "i shouldn't have brought it up again": [
    "Eius iterum commemorare non debui"
  ],
  "how can i help him": [
    "Quomodo eum adiuvare possim"
  ],
  "hairlinemalesnowelf02": [
    "HairLineMaleSnowElf02"
  ],
  "lava imagespace": [
    "Lava Imagespace"
  ],
  "deathhound": [
    "Death Hound Companion"
  ],
  "dawnguard full helmet": [
    "Aurorae Custodum Galea Plena"
  ],
  "spell tome: sun fire": [
    "Liber Magicae: Iginis Solis"
  ],
  "reaper gem fragment": [
    "Fragmentum Gemmae Messoris"
  ],
  "spell tome: conjure wrathman": [
    "Liber Magicae: Voca Iravirum"
  ],
  "then we'll have to take care of him": [
    "Ergo eum 'curare' debemus"
  ],
  "what exactly did the betrayed do": [
    "Quid, exacte, fecerunt Proditi"
  ],
  "hairlinemalesnowelf06": [
    "HairLineMaleSnowElf06"
  ],
  "absorb <mag> points of health from the target with bloodcursed arrows": [
    "Absorbe <mag> salutis ab inimico sagittis sanuine maledictis"
  ],
  "good, they should be able to help us": [
    "Bene, nos adiuvare debent"
  ],
  "chaurus effects": [
    "Chaurus effects"
  ],
  "we should keep moving": [
    "Pergere debemus"
  ],
  "howl of the pack": [
    "Ululatio Gregis"
  ],
  "wisp cloak": [
    "Wisp Cloak"
  ],
  "chaurus ambush goo fx": [
    "Chaurus Ambush Goo FX"
  ],
  "gargoylemeleeartholdergreen": [
    "GargoyleMeleeArtHolderGreen"
  ],
  "button": [
    "Boto"
  ],
  "chaurus hunter fledgling": [
    "Chaurus Venator Infans"
  ],
  "place soul gem": [
    "Pone Gemmanimum"
  ],
  "help sorine with her project to improve the crossbow": [
    "Adiuva Sorine ut manuballistas meliores faciat"
  ],
  "there's my husband": [
    "Illic est maritus"
  ],
  "bone hawk skull": [
    "Calvaria Accipitris Ossium"
  ],
  "i've proven my value to him": [
    "Is me bene nunc aestimat"
  ],
  "enthralled mothpriestfx 2": [
    "Enthralled MothpriestFX 2"
  ],
  "let me know if you need anything": [
    "Dic mihi si quo cares"
  ],
  "he was a vampire": [
    "Vampirus erat"
  ],
  "he controlled them": [
    "Eis imperavit"
  ],
  "nobody hears from them much anymore": [
    "Nemo de eis saepe audiunt"
  ],
  "i have lots of friends": [
    "Mulos amicos habeo"
  ],
  "will i be able to keep this amulet": [
    "Possumne hoc monile habere"
  ],
  "i try not to rely on others": [
    "Soleo in alios non niti"
  ],
  "does it bother you that we're working against your father": [
    "Displicetne tibi contra patrem tuum laborare"
  ],
  "death hound fx": [
    "Death Hound fx"
  ],
  "good luck": [
    "Bona fortuna",
    "Bona fortuna tibi exopto"
  ],
  "femaleeyeshumanvampire": [
    "FemaleEyesHumanVampire"
  ],
  "heals the undead target <mag> points, but not the living, atronachs or machines": [
    "Amortuum sanat <mag>, nec vivos, atronacha, machinas"
  ],
  "spell tome: necromantic healing": [
    "Liber Magicae: Sanare Necromanticum"
  ],
  "auriel's shield knockback low": [
    "Auriel's Shield Knockback Low"
  ],
  "serana hood": [
    "Seranae Cucullus"
  ],
  "wouldn't that kill me": [
    "Nonne me necaret"
  ],
  "weakened soul": [
    "Animus Debilis"
  ],
  "vampiric drain(shade)": [
    "Vampiric Drain(Shade)"
  ],
  "blood cloak effect": [
    "Blood Cloak Effect"
  ],
  "vampire mage": [
    "Vampire Mage"
  ],
  "restore health to undead": [
    "Redde Salutem Amortuis"
  ],
  "find out about the dawnguard": [
    "De Aurorae Custodibus Disce"
  ],
  "i'm here to join the dawnguard": [
    "Hic sum ut Aurorae Custodes adiungam"
  ],
  "drain claws": [
    "Ungues Bibendi"
  ],
  "maleeyeshumanvampire": [
    "MaleEyesHumanVampire"
  ],
  "for <dur> seconds, opponents in melee range are drained of <10> life": [
    "For <dur> seconds, opponents in melee range are drained of <10> life"
  ],
  "enter the underforge": [
    "Intra Subfabricam"
  ],
  "auriel shrine spell": [
    "Auriel Shrine Spell"
  ],
  "soul trap me": [
    "Cape animum meum"
  ],
  "i won't feel right as a vampire": [
    "Nolo vampirum esse"
  ],
  "auriel's shield knockback mid": [
    "Auriel's Shield Knockback Mid"
  ],
  "i trust you completely": [
    "Tibi omnino confido"
  ],
  "dlc1hairlinefemalevalerica": [
    "DLC1HairLineFemaleValerica"
  ],
  "bane of the undead": [
    "Bane of the Undead",
    "Exitium Amortuorum"
  ],
  "heals the undead target <mag> points per second, but not the living, atronachs or machines": [
    "Amortuum sanat <mag> singulis secundis, nec vivos, atronacha, machinas"
  ],
  "dlc1hairfemaleseranahuman": [
    "DLC1HairFemaleSeranaHuman"
  ],
  "spell tome: heal undead": [
    "Liber Magicae: Sana Amortuos"
  ],
  "corrupted shade": [
    "Umbra Corrupta",
    "Phantasma Corrupta"
  ],
  "what was the hall of the vigilants": [
    "Quid est Aula Vigilantium"
  ],
  "good, i could use all the help i can get": [
    "Bene, omni auxilio uti possum"
  ],
  "can you help get my soul back": [
    "Potesne mihi adiuvare in animum recipiendo"
  ],
  "beyond death soul gem handler": [
    "Beyond Death Soul Gem Handler"
  ],
  "stores the energy of blocked attacks": [
    "Vis ictuum interpositorum continet"
  ],
  "performing a power bash will release the stored energy": [
    "Oppugnatum potestatis vim exsolvet"
  ],
  "auriel's shield knockback high": [
    "Auriel's Shield Knockback High"
  ],
  "blood cloak": [
    "Blood Cloak"
  ],
  "amulet of draining grip": [
    "Monile Tensurae Consumendi"
  ],
  "bloody claws": [
    "Ungues Sanguinosae"
  ],
  "i haven't noticed any vampire menace": [
    "Nullam comminationem vampirorum vidi"
  ],
  "dlc1hairfemaleserana": [
    "DLC1HairFemaleSerana"
  ],
  "don't worry about it": [
    "Noli de eo sollicitari"
  ],
  "let's just get this over with": [
    "Tandem aliquando finem huic faciamus"
  ],
  "i'm ready to be soul trapped": [
    "Paratus sum animum perdere"
  ],
  "vigilants": [
    "Vigilantes"
  ],
  "killing vampires": [
    "Vampiros necans"
  ],
  "where do i sign up": [
    "Ubi nomen pono",
    "Ubi nomen meum ponam"
  ],
  "any idea where it could be": [
    "Scisne ubi esse possit"
  ],
  "sorry, i'm not interested": [
    "Ignosce, Mihi non interest"
  ],
  "falmer hardened armor": [
    "Falmeris Lorica Durior"
  ],
  "bloody grip cloak effect": [
    "Bloody Grip Cloak Effect"
  ],
  "falmer hardened boots": [
    "Falmeris Caligae Duriores"
  ],
  "magicka draining claws": [
    "Magicka Draining Claws"
  ],
  "soul essence gem (full)": [
    "Gemma Essentiae Animi (Plena)"
  ],
  "retrieve the soul essence gem": [
    "Refer Gemmam Animi Essentiae"
  ],
  "i heard you were looking for vampire hunters": [
    "Audivi te venatores vampirorum quaerere"
  ],
  "what's the dawnguard": [
    "Qui sunt Aurorae Custodes"
  ],
  "harkon conjuration delay": [
    "Harkon Conjuration Delay"
  ],
  "take the oghma infinium": [
    "Cape Oghmam Infinium",
    "Accipe Oghmam Infinium"
  ],
  "bring blood to septimus": [
    "Fer sanguinem Septimo",
    "Adfer Sanguinem Septimo"
  ],
  "harvest orc blood": [
    "Cape Sanguinem Orci",
    "Collige Sanguinem Orci"
  ],
  "i was just looking around": [
    "Modo circumspectavi"
  ],
  "harvest falmer blood": [
    "Cape Sanguinem Falmerem",
    "Cape Sanguinem Falmeris",
    "Collige Sanguinem Falmeris"
  ],
  "harvest dark elf blood": [
    "Cape Sanguinem Atralfi",
    "Cape Sanguinem Dunmeris",
    "Collige Sanguinem Dunmeris"
  ],
  "harvest wood elf blood": [
    "Cape Sanguinem Boscalfi",
    "Cape Sanguinem Bosmeris",
    "Collige Sanguinem Boscalfi"
  ],
  "give the lexicon to septimus": [
    "Da Lexicon Septimo"
  ],
  "ring of draining claws": [
    "Anulus Unguinum Bibendi"
  ],
  "transcribe the lexicon": [
    "Transcribe Lexicon"
  ],
  "dlc1hairlinefemaleserana": [
    "DLC1HairLineFemaleSerana"
  ],
  "falmer hardened gauntlets": [
    "Falmeris Manubria Durioria"
  ],
  "wayshrine casting effect": [
    "Wayshrine Casting Effect"
  ],
  "blood plague": [
    "Pestilentia Sanguinis"
  ],
  "discerning the transmundane": [
    "Transmundanum Discens"
  ],
  "bloody grip": [
    "Bloody Grip"
  ],
  "necromantic healing": [
    "Necromantic Healing"
  ],
  "unrelenting force - fus ro": [
    "Unrelenting Force - Fus Ro"
  ],
  "ring of the erudite": [
    "Anulus Eruditi"
  ],
  "heal undead": [
    "Heal Undead"
  ],
  "falmer hardened helm": [
    "Falmeris Galea Durior"
  ],
  "ring of the beast": [
    "Anulus Bestiae"
  ],
  "draining claws": [
    "Ungues Haurientes"
  ],
  "vampirism": [
    "Vampirism"
  ],
  "are you ready to go": [
    "Esne paratus",
    "Esne paratus discedere"
  ],
  "poison gas": [
    "Poison Gas",
    "Nebula Veneni"
  ],
  "any advice for a new vampire hunter": [
    "Habesne consilium pro venatore novo vampirorum"
  ],
  "the dawnguard would want me to kill you": [
    "Aurorae Custodes mihi suadeant ut te necam"
  ],
  "dawnguard would want me to kill you": [
    "Aurorae Custodes mihi suadeant ut te necam"
  ],
  "vampire abilities 1": [
    "Vampire Abilities 1"
  ],
  "do you have any insights about your elder scroll reading": [
    "Habesne quas sententias de Codicum Veterrimum legendo"
  ],
  "will i be able to safely revisit the soul cairn": [
    "Possimne iterum Terram Animorum visitare"
  ],
  "vampire regen": [
    "Vampire Regen"
  ],
  "do you still want to come with me": [
    "Iam mecum ire vis"
  ],
  "frenzy poison recipe": [
    "Dictamen - Venenum Insanitatis"
  ],
  "you heard the scroll reading": [
    "Lectionem Codicis audivisti audivisti"
  ],
  "now do you believe serana": [
    "Nunc Seranae credis"
  ],
  "amulet of the gargoyle": [
    "Monile Gurgulionis"
  ],
  "serana's soul trap": [
    "Animicaptum Seranae"
  ],
  "forget i said anything": [
    "Obliviscere verbum meorum"
  ],
  "harkon faux reanimate": [
    "Harkon Faux Reanimate"
  ],
  "no, a group called the \"dawnguard\" sent me here": [
    "Minime, grex nomini \"Aurorae Custodes\" mihi miserunt"
  ],
  "portal to darkfall passage": [
    "Portam ad Commeatum Obscuricasus"
  ],
  "what do you mean, \"like you\"": [
    "Quid sibi vult, \"quam tu\""
  ],
  "dlc1thrallhellos": [
    "DLC1ThrallHellos"
  ],
  "creaturedialoguemistman": [
    "CreatureDialogueMistman"
  ],
  "modhna": [
    "Modhna"
  ],
  "are you a": [
    "Esne"
  ],
  "i don't really care what you are": [
    "Nihil de te curo"
  ],
  "that's not your concern": [
    "Noli de eo sollicitari"
  ],
  "mogrul": [
    "Mogrul"
  ],
  "damage health regeneration": [
    "Damage Health Regeneration",
    "Vulnus Salutis Recuperandi"
  ],
  "staff of firebolts": [
    "Rudis Sagitignium",
    "Rudis Falmeris Sagitignium"
  ],
  "lokil": [
    "Lokil"
  ],
  "i'm lucky i made it out alive": [
    "Felix sum quod integer effugi"
  ],
  "is it fragile": [
    "Estne fragilis"
  ],
  "do we need to be careful with it": [
    "Debemusne cum eo cavere"
  ],
  "amulet drain magicka": [
    "Monile Magicam Bibens"
  ],
  "damage magicka regeneration": [
    "Damage Magicka Regeneration",
    "Vulnues Magicae Recuperandi"
  ],
  "she says she lives in a castle near solitude": [
    "Dicit se in castello prope urbem Solitudinem vivere"
  ],
  "harkon's ashes": [
    "Cinera Harkonis"
  ],
  "portal to darkfall cave": [
    "Portam ad Speluncam Obscuricasus"
  ],
  "creaturedialoguedogdeathhound": [
    "CreatureDialogueDogDeathHound"
  ],
  "why were you locked away like this": [
    "Cur hoc modo incarceratus es"
  ],
  "adalvald": [
    "Adalvald"
  ],
  "ollrod": [
    "Ollrod"
  ],
  "who were you expecting": [
    "Quem exspectavisti"
  ],
  "vyrthur": [
    "Vyrthur"
  ],
  "as long as you don't get in my way": [
    "Nisi me obstes"
  ],
  "i'm pretty sure she's a vampire, actually": [
    "Credo eam esse vampiram"
  ],
  "you first": [
    "Tu primum"
  ],
  "i don't know who she is": [
    "Nescio quis sit"
  ],
  "staff of the frost atronach": [
    "Rudis Atronachi Gelidi",
    "Rudis Falmeris Atronachi Gelidi"
  ],
  "bone hawk ring": [
    "Accipitris Ossis Anulus"
  ],
  "eject player push": [
    "Eject Player Push"
  ],
  "blessing of auriel": [
    "Benedictio Aurorae"
  ],
  "she wanted to go home, so i took her to her castle": [
    "Domum revenire voluit, ergo eam domum tuli"
  ],
  "fortify marksman": [
    "Adde ad Saggitationem",
    "Adde ad Artem Sagittationis"
  ],
  "why wouldn't isran like me asking about florentius": [
    "Quamobrem Isran me de Florentio rogare nolit"
  ],
  "a gout of fire that does <mag> points per second": [
    "Saltus ignis qui facit <mag> singulis secundis",
    "A gout of fire that does <mag> points per second",
    "Rivus ignis quod <mag> punctas singulas secundas facit"
  ],
  "gout of fire that does <mag> points per second": [
    "Saltus ignis qui facit <mag> singulis secundis",
    "A gout of fire that does <mag> points per second",
    "Rivus ignis quod <mag> punctas singulas secundas facit"
  ],
  "escape from irkngthand": [
    "Effuge Irkngthand",
    "Effuge ab Irkngthand"
  ],
  "raise zombie": [
    "Raise Zombie",
    "Voca Amortuum"
  ],
  "audiotemplatefalmer": [
    "AudioTemplateFalmer"
  ],
  "sorine thought we'd need his help": [
    "Sorine putabat nos auxilio eius carere"
  ],
  "where do you need to go": [
    "Quo ire debes"
  ],
  "absorb <mag> points of stamina per second from the target": [
    "Absorbe <mag> staminae singulis secundis ab inimico"
  ],
  "staff of fireballs": [
    "Rudis Ignipilarum",
    "Rudis Falmeris Ignipilarum"
  ],
  "vampire abilities 3": [
    "Vampire Abilities 3"
  ],
  "letter from harkon": [
    "Epistula a Harkone"
  ],
  "werewolf gorging health restore": [
    "Werewolf Gorging Health Restore"
  ],
  "vale deer hide": [
    "Reno Cervi Vallis"
  ],
  "tolan was right. and he's dead. the vampires killed him": [
    "Tolan recte dixit. Et mortuus est. Vampiri eum necaverunt"
  ],
  "bats cloak": [
    "Amiculum Vespertillionum"
  ],
  "is that an elder scroll": [
    "Esne ille Codex Veterrimus"
  ],
  "slay mercer frey": [
    "Neca Mercer Frey"
  ],
  "staff of ice storms": [
    "Rudis Tempestatis Glaciei",
    "Rudis Falmeris Tempestatum Glacierum"
  ],
  "what exactly are you planning to do to these vampires": [
    "Quid, exacte, ad hos vampiros facere vis"
  ],
  "staff of ice spikes": [
    "Rudis Hami Glaciei",
    "Rudis Falmeris Hamorum Glacierum"
  ],
  "damage stamina regeneration": [
    "Damage Stamina Regeneration",
    "Vulnus Staminam Recuperandi"
  ],
  "damage stamina": [
    "Damage Stamina",
    "Diminua Staminam"
  ],
  "staff of lightning bolts": [
    "Rudis Tonitruum",
    "Rudis Falmeris Fulminum"
  ],
  "voslaarum": [
    "Voslaarum"
  ],
  "locate mercer frey": [
    "Inveni Mercer Frey"
  ],
  "return arvak's skull": [
    "Redde Calvariam Arvakis"
  ],
  "fx vamp skeleton": [
    "FX Vamp Skeleton"
  ],
  "do i get a reward for finding your daughter": [
    "Accipiamne recompensum quod filiam tuam inveni"
  ],
  "vampire death bleeding": [
    "Vampire Death Bleeding"
  ],
  "for <dur> seconds, opponents in melee range take <mag> points of damage per second": [
    "<dur> secundas, inimici prope te <8> vulnus singulis secundis accipiunt"
  ],
  "need crossbow": [
    "Need Crossbow"
  ],
  "blindsighted": [
    "Caecivisus",
    "Visus Caecus"
  ],
  "amulet of drain magicka": [
    "Monile Magicam Consumendi"
  ],
  "portal to shrine of illumination": [
    "Portam ad Aram Illuminationis"
  ],
  "bone hawk claw": [
    "Ungues Accipitris Ossium"
  ],
  "vale sabre cat hide": [
    "Reno Felis Mucronis Vallis"
  ],
  "let's go to the castle's secret entrance": [
    "Ad ingressum secretum castelli eamus"
  ],
  "death hound collar": [
    "Cingula Canis Mortui"
  ],
  "staff of the flame atronach": [
    "Rudis Atronachi Flammae",
    "Rudis Falmeris Atronachi Flammarum"
  ],
  "retrieve arvak's skull": [
    "Refer Calvariam Arvakis"
  ],
  "werewolf brute": [
    "Versipellis Opera",
    "Versipellis Brutum"
  ],
  "the vampires were looking for a woman trapped in dimhollow": [
    "Vampiri feminam in Cavobscura incarcerata quaesiverunt"
  ],
  "vampires were looking for a woman trapped in dimhollow": [
    "Vampiri feminam in Cavobscura incarcerata quaesiverunt"
  ],
  "vampire abilities 4": [
    "Vampire Abilities 4"
  ],
  "vampire abilities 5": [
    "Vampire Abilities 5"
  ],
  "retrieve the skeleton key": [
    "Sume Clavem Adulteram",
    "Accipe Clavem Adulterum"
  ],
  "naaslaarum": [
    "Naaslaarum"
  ],
  "who is your father": [
    "Quis est pater tuus"
  ],
  "i have some preparations to make, first": [
    "Nonnullas res antea facere debeo"
  ],
  "vigilant adalvald": [
    "Vigilans Adalvald"
  ],
  "namasur": [
    "Namasur"
  ],
  "your health, magicka, and stamina recover more slowly while inside the soul cairn": [
    "Salus, Magica, et Stamina lentius recuperant dum in Terra Animorum es"
  ],
  "vampire abilities 2": [
    "Vampire Abilities 2"
  ],
  "she didn't want to follow me here": [
    "Illa me sequi noluit"
  ],
  "but she wants me to take her home": [
    "Sed mihi suasit ut eam domum ferrem"
  ],
  "you are <mag>% more effective with missile weapons": [
    "<mag>% efficacior es sagittis"
  ],
  "vq03 calm mothpriest": [
    "VQ03 Calm Mothpriest"
  ],
  "restore health area": [
    "Restore Health Area",
    "Redde Salutem Propinquis",
    "Redde Salutem Ad Omnes Prope Te"
  ],
  "dwarven centurion fx": [
    "Dvemer Centurion FX",
    "Dwemeris Centurio FX"
  ],
  "creaturedialoguedoghusky": [
    "CreatureDialogueDogHusky"
  ],
  "i'm ready to carry out this task": [
    "Paratus sum ad hoc faciendum"
  ],
  "grand healing": [
    "Grand Healing"
  ],
  "actually, i have some things to do first": [
    "Em, tempus hic nunc facere debeo"
  ],
  "what must i know about being a vampire": [
    "Quid de vita vampirica scire debeo"
  ],
  "dialogue faction for exterior dawnguard npcs": [
    "Dialogue faction for exterior Dawnguard NPCs"
  ],
  "vampire thrall": [
    "Vampire Thrall"
  ],
  "shrine of auriel": [
    "Ara Aurorae"
  ],
  "i never got the chance": [
    "Numquam potui"
  ],
  "tell me again about being a vampire lord": [
    "Dic mihi iterum de Dominis Vampiris"
  ],
  "stendarr's aura - isran": [
    "Shoris Aura - Isran"
  ],
  "yes, the place was crawling with vampires": [
    "Sic, ille locus plenus vampirorum erat"
  ],
  "you're a vampire": [
    "Vampira es"
  ],
  "audiotemplatevampirelord": [
    "AudioTemplateVampireLord"
  ],
  "what happens now": [
    "Quid nunc accidat",
    "Quid nunc accidet"
  ],
  "tilde": [
    "Tilde"
  ],
  "you're a reclusive cannibal cult": [
    "Estis grex hermeticus sarcophagorum"
  ],
  "who's your father": [
    "Quis est pater tuus"
  ],
  "she's the daughter of a powerful vampire lord": [
    "Filia Domini Vampirorum Fortissimi est"
  ],
  "what's that you're shooting with": [
    "Qua re sagittas"
  ],
  "staff of chain lightning": [
    "Rudis Tonitrus Catenati",
    "Rudis Falmeris Fulminis Catenati"
  ],
  "bats control": [
    "Bats Control"
  ],
  "how did you know i was a vampire": [
    "Quomodo scivisti me vampirum esse"
  ],
  "portal to forgotten vale": [
    "Portam ad Vallem Oblitam"
  ],
  "damage magicka": [
    "Damage Magicka",
    "Vulnus Magicae"
  ],
  "you sure": [
    "Certusne es"
  ],
  "they might prove useful": [
    "Utiles esse possunt"
  ],
  "audiotemplateskeletonmistman": [
    "AudioTemplateSkeletonMistman"
  ],
  "there were too many of them, and only one of me": [
    "Tot erant, et ego, unus"
  ],
  "crossbow bonus": [
    "Crossbow bonus"
  ],
  "durnehvir stats boost": [
    "Durnehvir Stats Boost"
  ],
  "i don't care": [
    "Nihil curo"
  ],
  "do as you like": [
    "Fac ut vis"
  ],
  "6000 gold, and they're yours": [
    "MMMMMM Auri et tua sunt"
  ],
  "they're bound to bring me fortune and glory": [
    "Mihi fortam gloriamque ferre debent"
  ],
  "i'd be happy for you to have them": [
    "Gaudeo eos tibi dare"
  ],
  "mist form muffle": [
    "Mist Form Muffle"
  ],
  "you're the one who matters to me": [
    "Tu maximi momenti mihi es"
  ],
  "vori": [
    "Vori"
  ],
  "i hadn't really thought about it": [
    "De eo nondum putavi"
  ],
  "when do you plan to start trusting me": [
    "Quando mihi conficere incipies"
  ],
  "portal to paragon platform": [
    "Portam ad Constratum Paragonis"
  ],
  "stop wasting my time, garan": [
    "Noli tempus meum perde, Garan"
  ],
  "auriel's chapel": [
    "Capella Aurorae"
  ],
  "amethyst": [
    "Amethystum",
    "Amethystus"
  ],
  "you don't trust her, fine": [
    "Ei non confidis, bene"
  ],
  "trust me": [
    "Crede mihi",
    "Mihi crede",
    "Confide mihi"
  ],
  "i believe her": [
    "Ei credo"
  ],
  "set your hatred aside and try to see the larger picture, isran": [
    "Depone odium et imaginem maiorem videre conare, Isran"
  ],
  "portal to glacial crevice": [
    "Portam ad Hiatum Glaciarii"
  ],
  "storm the castle extender": [
    "Storm the Castle Extender"
  ],
  "portal to darkfall grotto": [
    "Portam ad Specum Obscuricasus"
  ],
  "portal to forgotten vale forest": [
    "Portam ad Silvam Vallis Oblitae"
  ],
  "portal to forgotten vale overlook": [
    "Portam ad Panoramam Vallis Oblitae"
  ],
  "portal to inner sanctum": [
    "Portam ad Fanum Interiori"
  ],
  "shellbug helmet": [
    "Cimiconcae Galea"
  ],
  "what do you know about the elder scrolls": [
    "Quid scis de Codice Veterrimo"
  ],
  "armor boost": [
    "Plus Loricae"
  ],
  "dexion lore topics": [
    "Dexion Lore Topics"
  ],
  "stagger push": [
    "Stagger Push"
  ],
  "what do moth priests do, exactly": [
    "Quid, exacte, faciunt Sacerdotes Papilionis"
  ],
  "the dawnguard were there": [
    "Aurorae Custodes illic erant"
  ],
  "dawnguard were there": [
    "Aurorae Custodes illic erant"
  ],
  "they fought well, but not well enough": [
    "Bene pugnaverunt, sed non satis bene"
  ],
  "vampire change stagger": [
    "Vampire Change Stagger"
  ],
  "do i need to read the scrolls in any particular order": [
    "Debeone codices in ordine legere"
  ],
  "very well": [
    "Bene"
  ],
  "(give auriel's bow)": [
    "(Da Arcum Solis)"
  ],
  "have you ever thought about marriage": [
    "Umquamne de nuptiis cogitavisti"
  ],
  "frost giant club": [
    "Frost Giant Club"
  ],
  "dog armor": [
    "Dog Armour"
  ],
  "i suspected as much": [
    "Ut suspicatus sum"
  ],
  "the elder scrolls have served me well, dexion": [
    "Codices Veterrimi mihi bene serviverunt, Dexion"
  ],
  "elder scrolls have served me well, dexion": [
    "Codices Veterrimi mihi bene serviverunt, Dexion"
  ],
  "i was just curious": [
    "Modo scire volui",
    "Modo de eo cogitabam"
  ],
  "i shouldn't have brought it up": [
    "Eius commemorare debui"
  ],
  "why else would she risk her life to come here": [
    "Qua alia ratione vitam eius in periculum ponat"
  ],
  "see to it at once": [
    "Fac id semper"
  ],
  "amethyst paragon": [
    "Paragon Amythystum"
  ],
  "why do you have an elder scroll": [
    "Cur Codicem Veterrimum habes"
  ],
  "did you have something in mind": [
    "Aliquidne in animo habuisti"
  ],
  "preloader for night cloak": [
    "Preloader for Night Cloak"
  ],
  "vampires heal <mag> points of health": [
    "Vampire <mag> puncta salutis accipiunt"
  ],
  "i'm going to hold on to them for now": [
    "Hoc in tempore eos in manu retineam"
  ],
  "the dawnguard tried to stop me, but they posed no threat": [
    "Aurorae Custodes me sistere conati sunt, sed me vincere non potuerunt"
  ],
  "dawnguard tried to stop me, but they posed no threat": [
    "Aurorae Custodes me sistere conati sunt, sed me vincere non potuerunt"
  ],
  "do you still want the elder scrolls": [
    "Iam Codicem Veterrimum vis"
  ],
  "they're indestructible, so they'd make good armor": [
    "Invulnerabiles sunt, ergo lorica bona fieri possunt"
  ],
  "diamond paragon": [
    "Paragon Adamanteum"
  ],
  "restore health": [
    "Redde Salutem"
  ],
  "i was hoping you might want to talk about it": [
    "Speravi te de eo loqui velle"
  ],
  "the white-gold tower": [
    "Turris Albi-Auri"
  ],
  "white-gold tower": [
    "Turris Albi-Auri",
    "Turris Cryselephantinus"
  ],
  "the dawnguard were protecting him, and they nearly killed me": [
    "Aurorae Custodes eum tegebant, et me fere necaverunt"
  ],
  "dawnguard were protecting him, and they nearly killed me": [
    "Aurorae Custodes eum tegebant, et me fere necaverunt"
  ],
  "something on your mind, garan": [
    "Aliquid in animo est, Garan"
  ],
  "i know why they needed you, because we need you for the same purpose": [
    "Scio quamobrem illos te voluisse, quod tibi eidem rei quaerimus"
  ],
  "i'll explain later": [
    "Postea tibi explicabo"
  ],
  "right now, we need your help and time is short": [
    "Nunc, auxilium tuum queaerimus et tempus fugit"
  ],
  "frozen creature's abilities": [
    "Frozen Creature's abilities"
  ],
  "don't translate": [
    "Don't Translate"
  ],
  "gargoyle sentinel abilities": [
    "Gargoyle Sentinel Abilities"
  ],
  "vampire seduction will always work for people in this faction per perk": [
    "Vampire Seduction will always work for people in this faction per perk"
  ],
  "akaviri sword": [
    "Enses Akavirus"
  ],
  "gargoyle sentinel": [
    "Gurgulio Custos"
  ],
  "frost atronach abilities": [
    "Frost Atronach abilities"
  ],
  "silver platter": [
    "Ferculum Argenteum"
  ],
  "silver jug": [
    "Guttus Argenteus"
  ],
  "he died by my hand": [
    "A manu mea mortuus est"
  ],
  "if i leave the soul cairn, can i safely return": [
    "Si e Terra Animorum exiverim, huc tutum revenire possim"
  ],
  "silver goblet": [
    "Poculum Argenteum"
  ],
  "load dummy": [
    "Load Dummy"
  ],
  "spriggan heal": [
    "Spriggan Heal"
  ],
  "vampire lord enhancements": [
    "Vampire Lord Enhancements"
  ],
  "dawnguard heavy gauntlets": [
    "Aurorae Custodum Manubria Gravia"
  ],
  "gunmar faction": [
    "Gunmar Faction"
  ],
  "dawnguard heavy boots": [
    "Aurorae Custodum Caligae Graves"
  ],
  "speak to erandur": [
    "Adloquere Erandur"
  ],
  "script effect": [
    "Script Effect"
  ],
  "ice spike": [
    "Hamus Glaciei"
  ],
  "slow - not dragons": [
    "Slow - not dragons"
  ],
  "harkon turning immunity": [
    "Harkon Turning Immunity"
  ],
  "skeleton abilities": [
    "Skeleton Abilities"
  ],
  "meet with skjor at night": [
    "Skjor Noctu Conveni",
    "Conveni cum Skjor noctu"
  ],
  "the silver hand": [
    "Manus Argentea"
  ],
  "silver hand": [
    "Manus Argentea"
  ],
  "participate in the blood ritual": [
    "Age religionem sanguinis",
    "Esto pars Ritualis Sanguinis"
  ],
  "raldbthar deep market": [
    "Raldbtharis Imum Atrium",
    "Raldbthar Forum Profundum"
  ],
  "take the skull of corruption": [
    "Cape Calvariam Corruptionis",
    "Cape Calvariam Corrumptionis"
  ],
  "defeat veren and thorek": [
    "Supera Veren et Shorek",
    "Supera Veren et Thorek"
  ],
  "disable the magical barrier": [
    "Erade Parietem Magicum",
    "Frange Praesidium Magicale"
  ],
  "release the miasma": [
    "Libera Nebulam"
  ],
  "drink vaermina's torpor": [
    "Bibe Torporem Morphei",
    "Hauri Torporem Morphei"
  ],
  "locate \"the dreamstride\"": [
    "Inveni \"Somnambulum\"",
    "Inveni \"Somnambulationem\""
  ],
  "follow erandur to the library": [
    "Erandur ad Bibliothecam Sequere",
    "Sequere Erandur ad Bibliothecam"
  ],
  "follow erandur to nightcaller temple": [
    "Erandur Sequere ad Templum Noctivocatoris",
    "Sequere Erandur ad Templum Noctivocatoris"
  ],
  "waking nightmare": [
    "Supressio Vigilans",
    "Suppressio Vigilabilis"
  ],
  "shock cloak": [
    "Amiculum Electricitatis"
  ],
  "deep folk crossing": [
    "Deep Folk Crossing"
  ],
  "hob's fall cave": [
    "Hob's Fall Cave",
    "Spelunca Hobis Casus",
    "Spelunca Caesis Hobis"
  ],
  "speaking with silence end scene": [
    "Speaking With Silence End Scene"
  ],
  "speak to falion": [
    "Adloquere Falion",
    "Adloquere Falionem"
  ],
  "bring a filled black soul gem to morthal": [
    "Fer Gemmanimum Atrum Morthali",
    "Refer Gemmanimam Atram Morthalem"
  ],
  "rising at dawn": [
    "Aurora Surgens",
    "Ad Auroram Surgens"
  ],
  "werewolf abilities": [
    "Werewolf Abilities"
  ],
  "travel to irkngthand": [
    "Ii ad Irkngthand",
    "Iter Fac ad Irkngthand"
  ],
  "he's dead": [
    "Mortuus est"
  ],
  "cronvangr broodlair": [
    "Cronvangr Cubilisanguis",
    "Cronvangr Prolestabulum"
  ],
  "valindor's house": [
    "Valindor's House"
  ],
  "warmaster": [
    "Bellidomini",
    "Bellidominus"
  ],
  "i'm looking for an elder scroll": [
    "Codicem Veterrimum quaero"
  ],
  "tolvald's crossing": [
    "Tolvaldis Vadum",
    "Tolvaldis Transitus"
  ],
  "necromancy": [
    "Necromancticus"
  ],
  "elven arrow": [
    "Sagitta Alfa"
  ],
  "dialogue for when player is a vampire": [
    "Dialogue for when Player est Vampire",
    "Dialogue for when Player is a Vampire"
  ],
  "misty grove": [
    "Nexosus Nemus",
    "Nemus Nebulosum"
  ],
  "travel with barbas to the shrine of clavicus vile": [
    "Iter cum Barba fac ad Aram Clavici",
    "Peregrinare cum Barba ad Aram Clavici"
  ],
  "dwarven storeroom": [
    "Dvemeris Horreum",
    "Dvemere Promptuarium"
  ],
  "meet falion at dawn": [
    "Conveni cum Falione ad Azuram",
    "Adiunge Falion Tempore Aurorae"
  ],
  "the arcanaeum": [
    "Arcanaeum",
    "The Arcanaeum"
  ],
  "arcanaeum": [
    "Arcanaeum",
    "The Arcanaeum"
  ],
  "follow erandur": [
    "Erandur Sequere",
    "Sequere Erandur"
  ],
  "summon wolves 1": [
    "Summon Wolves 1"
  ],
  "glass arrow": [
    "Sagitta Vitrea"
  ],
  "what brings you to this remote place": [
    "Cur huic loco remoto venisti",
    "Quid te ad hunc locum remotum fert"
  ],
  "harvest high elf blood": [
    "Cape Sanguinem Altalfi",
    "Collige Sanguinem Altalfi"
  ],
  "laelette the vampire": [
    "Laeletta Vampira",
    "Laelette Vampira"
  ],
  "darklight tower": [
    "Darklight Tower",
    "Obscuralux Turris"
  ],
  "let's get this over with": [
    "Finem huic faciamus",
    "Finem ad hanc rem faciamus"
  ],
  "wait for falion to complete the ritual": [
    "Exspecta Finem Ritualis",
    "Expecta ut Falion religionem compleat"
  ],
  "volkihar vampire": [
    "Vampira Volkiharis",
    "Vampirus Volkiharis"
  ],
  "steel arrow": [
    "Sagitta Adamantea"
  ],
  "what about the dragonborn": [
    "Quid de Draconigena",
    "Quid de Draconinata"
  ],
  "werewolf savage": [
    "Versipellis Saevus"
  ],
  "vampire's seduction": [
    "Seductio Vampirica",
    "Vampire's Seduction"
  ],
  "orcish arrow": [
    "Sagitta Orci"
  ],
  "murder erandur": [
    "Neca Erandur"
  ],
  "ask urag about the insane book": [
    "Roga Urag de libro insano",
    "Interroga Uragem de Libro Amenti",
    "Roga Urag de libro insano \"Ruminationes\""
  ],
  "duskglow crevice": [
    "Duskglow Crevice",
    "Crepusculux Hiatus"
  ],
  "allow erandur to complete his ritual": [
    "Sine Erandur religionem complere"
  ],
  "cold rock pass": [
    "Commeatus Frigidi Saxi",
    "Commeatus Saxi Frigidi"
  ],
  "talk to skjor": [
    "Adloquere Skjor",
    "Adloquere ad Skjor"
  ],
  "werewolf fear howl 1": [
    "Werewolf Fear Howl 1"
  ],
  "find septimus signus": [
    "Inveni Septimum Signum"
  ],
  "resist frost": [
    "Resiste Glaciei"
  ],
  "sigaar": [
    "Sigaar"
  ],
  "fireball": [
    "Ignipila"
  ],
  "hert": [
    "Hert"
  ],
  "summon wolves 2": [
    "Summon Wolves 2"
  ],
  "warehouse furniture": [
    "Horreum Furniture",
    "WareDomus Furniture"
  ],
  "flame cloak": [
    "Amiculum Flammarum"
  ],
  "detect life": [
    "Inveni Vivos"
  ],
  "iron arrow": [
    "Sagitta Ferri",
    "Sagitta Ferrata"
  ],
  "hall of the vigilant": [
    "Atrium Vigilium",
    "Hall of the Vigilant"
  ],
  "dwemer bolt": [
    "Sagittula Dvemeris"
  ],
  "follow erandur to the laboratory": [
    "Erandur Sequere ad Laboratorium",
    "Sequere Erandur ad Laboratorium"
  ],
  "shoutendshort": [
    "ShoutEndShort"
  ],
  "i seek the knowledge of the elder scrolls": [
    "Scientiam Codicum Veterrimorum quaero",
    "Scientiam Codicium Veterrimorum quaero"
  ],
  "what does it mean to be a werewolf": [
    "Quid sibi vult versipellem esse"
  ],
  "warehouse traps": [
    "Horreum Traps",
    "WareDomus Traps"
  ],
  "dwarven spider fx": [
    "Dvemer Spider FX",
    "Dwemeris Spider FX"
  ],
  "shock damage": [
    "Vulnus Electricitatis"
  ],
  "ebony arrow": [
    "Sagitta Ebeni"
  ],
  "werewolf fear howl 3": [
    "Werewolf Fear Howl 3"
  ],
  "pushroda": [
    "PushRoDa"
  ],
  "shoutstartlong": [
    "ShoutStartLong"
  ],
  "wait for erandur to dispel the barrier": [
    "Expecta ut Erandur parietem removeat",
    "Exspecta Erandur ut praesidium frangas"
  ],
  "locate vaermina's torpor": [
    "Inveni Torporem Morphei"
  ],
  "werewolf vargr": [
    "Versipellis Vargr"
  ],
  "kill the werewolf hunters": [
    "Neca venatores versipellium"
  ],
  "tolvald's cave": [
    "Tolvald's Cave",
    "Tolvaldis Spelunca"
  ],
  "bleak falls barrow": [
    "Bleak Falls Barrow",
    "Pabillum Casus Obscuri"
  ],
  "dwemer museum": [
    "Dwemer Museum",
    "Museion Dvemere",
    "Dvemere Mouseion"
  ],
  "talk to aela": [
    "Adloquere Aelam",
    "Adloquere ad Aelam"
  ],
  "frostflow abyss": [
    "Abyssum Glaciflux",
    "Gelidusfluxus Abyssus"
  ],
  "mzinchaleft depths": [
    "Mzinchaleft Ima",
    "Mzinchaleftis Ima"
  ],
  "alftand cathedral": [
    "Alftand Cathedral",
    "Cathedra Alfaterrae"
  ],
  "werewolf fear howl 2": [
    "Werewolf Fear Howl 2"
  ],
  "liar's retreat": [
    "Liar's Retreat",
    "Periuri Refugium"
  ],
  "where did he go": [
    "Quo ivit"
  ],
  "daedric arrow": [
    "Sagitta Daedrica"
  ],
  "raldbthar": [
    "Raldbthar"
  ],
  "editor smoke test cell": [
    "Editor Smoke Test Cell"
  ],
  "tolvald's gap": [
    "Tolvaldis Hiatus"
  ],
  "dustman's cairn": [
    "Dustmanis Cairn",
    "Pulviviri Monolithos"
  ],
  "ilinalta's deep": [
    "Imum Ilinaltae",
    "Ilinalta's Deep"
  ],
  "fort snowhawk": [
    "Fort Snowhawk",
    "Castellum Niviaccipiter"
  ],
  "hag's end": [
    "Finis Sagae",
    "Sagae Finis"
  ],
  "stamina damage": [
    "Vulnus Staminae"
  ],
  "alftand animonculory": [
    "Alftand Animunculorium",
    "Alfterrae Animonculorium"
  ],
  "warehouse ambushes": [
    "Horreum Ambushes",
    "WareDomus Ambushes"
  ],
  "the frozen hearth cellar": [
    "Cellarium Foci Gelidi",
    "Congelatum Focus Cellarium"
  ],
  "frozen hearth cellar": [
    "Cellarium Foci Gelidi",
    "Congelatum Focus Cellarium"
  ],
  "honeystrand grove": [
    "Honeystrand Grove"
  ],
  "irkngthand slave pens": [
    "Irkngthand Harae Servorum"
  ],
  "froki's shack": [
    "Casa Froki",
    "Froki Shack"
  ],
  "bloodlet throne": [
    "Bloodlet Throne",
    "Solium Desanguinationis"
  ],
  "sybille stentor": [
    "Sybille Stentor"
  ],
  "whiterun catacombs": [
    "Albacursus Catacumbae",
    "Subterranea Albacursus"
  ],
  "sanuarach mine": [
    "Sanuarach Metallum",
    "Metallum Sanuarachis"
  ],
  "testtony": [
    "TestTony"
  ],
  "rannveig's fast": [
    "Rannveig's Fast",
    "Rannveigis Castellum"
  ],
  "blackreach": [
    "Atrahensus",
    "Blackreach"
  ],
  "cronvangr cave": [
    "Cronvangr Spelunca",
    "Spelunca Cronvangris"
  ],
  "thirst for blood": [
    "Sitis Sanguini",
    "Sitis Sanguinis"
  ],
  "marker storage unit": [
    "Marker Storage Unit"
  ],
  "irkngthand grand cavern": [
    "Irkngthand Caverna Magna"
  ],
  "dwarven arrow": [
    "Sagitta Dvemeris"
  ],
  "fellglow keep": [
    "Fellglow Keep",
    "Felllux Castellum"
  ],
  "follow erandur to the skull of corruption": [
    "Sequere Erandur ad Calvariam Corruptionis",
    "Sequere Erandur ad Calvariam Corrumptionis"
  ],
  "fort neugrad": [
    "Fort Neugrad",
    "Castellum Neugrad"
  ],
  "reachcliff cave": [
    "Reachcliff Cave",
    "Spelunca Prehensirupis"
  ],
  "the reach": [
    "the Reach"
  ],
  "bards college": [
    "The Bards College",
    "Collegium Bardorum",
    "Collegium Poetarum",
    "Collegium Cantatorum"
  ],
  "shoutstartshort": [
    "ShoutStartShort"
  ],
  "sybille": [
    "Sybille"
  ],
  "vampire fledgling": [
    "Vampirus Minor",
    "Vampira Iuvenis"
  ],
  "werewolf feed": [
    "Werewolf Feed"
  ],
  "arcwind point": [
    "Arcwind Point",
    "Arciventus Apex"
  ],
  "return to septimus": [
    "Reveni ad Septimum",
    "Refer te ad Septimum"
  ],
  "werewolf victim effect": [
    "Werewolf Victim Effect"
  ],
  "blooded vampire": [
    "Vampira Sanguinosa"
  ],
  "vampire nightstalker": [
    "Vampira Noctifur",
    "Vampira Furuncula"
  ],
  "vampire mistwalker": [
    "Vampira Nexambulator",
    "Vampira Nebulambulator"
  ],
  "ancient vampire": [
    "Vampira Antiqua",
    "Vampirus Antiquus"
  ],
  "speak to clavicus vile": [
    "Adloquere Clavicum"
  ],
  "werewolf claws": [
    "Ungues Versipellis"
  ],
  "scholars assume that generations of living underground rendered the falmer blind": [
    "Scholares credunt aetates sub terra Falmeres caecos fecerunt"
  ],
  "over time their other senses have become more acute as a result": [
    "Hoc tempore alii sensus augebantur"
  ],
  "summons the ghost of the legendary assassin lucien lachance to fight by your side, until he's defeated": [
    "Imaginem sicarii notissimi Lucien Lachance ut pro te pugnet",
    "Imaginem sicarii notissimi Lucien Lachance vocat ut tecum oppugnet, ad tempus in quo vincetur"
  ],
  "for <dur> seconds, opponents in melee range take <8> points frost damage and stamina damage per second": [
    "<dur> secundas, inimici prope te <8> vulnus glaciei singulis secundis accipiunt",
    "<dur> secundas, inimici prope te accipiunt <8> vulneris et glaciei et staminae singulis secundis",
    "<dur> secundas, inimici prope te <8> puncta vulneris glaciei staminaeque singulis secundis accipiunt"
  ],
  "weakness to fire": [
    "Debilitas Igni"
  ],
  "creaturedialoguewerewolf": [
    "CreatureDialogueWerewolf"
  ],
  "tel mithryn": [
    "Tel Mithryn"
  ],
  "ashfallow citadel": [
    "Arx Novalicineris"
  ],
  "fort frostmoth": [
    "Fort Frostmoth"
  ],
  "gyldenhul barrow": [
    "Pabillus Gyldenhulis"
  ],
  "vahlok's tomb": [
    "Vahlok's Tomb"
  ],
  "beast stone": [
    "Beast Stone"
  ],
  "benkongerike": [
    "Benkongerike"
  ],
  "temple of miraak": [
    "Templum Miraakis"
  ],
  "hrodulf's house": [
    "Domus Hrodulfi"
  ],
  "raven rock": [
    "Raven Rock"
  ],
  "tosses a jumping poison spider on the ground that will launch itself at nearby enemies and explode if it lands near them": [
    "Tosses a Jumping Poison spider on the ground that will launch itself at nearby enemies and explode if it lands near them"
  ],
  "castle karstaag ruins": [
    "Castle Karstaag Ruins"
  ],
  "castle karstaag caverns": [
    "Cavernae Castelli Karstaag"
  ],
  "fahlbtharz": [
    "Fahlbtharz"
  ],
  "kagrumez": [
    "Kagrumez"
  ],
  "earth stone": [
    "Earth Stone"
  ],
  "water stone": [
    "Water Stone"
  ],
  "exploding spider": [
    "Aranea Displodens"
  ],
  "jumping poisonous spider": [
    "Aranea Saltans Veneni"
  ],
  "nchardak": [
    "Nchardak"
  ],
  "horker island": [
    "Horker Island"
  ],
  "apocrypha": [
    "Apocrypha"
  ],
  "highpoint tower": [
    "Turris Altipunctus"
  ],
  "bujold's retreat": [
    "Refugitum Bujoldis"
  ],
  "white ridge barrow": [
    "Pabillus Jugi Albi"
  ],
  "raven rock mine": [
    "Metallum Corvisaxi"
  ],
  "kolbjorn barrow": [
    "Pabillus Kolbjornis"
  ],
  "altar of thrond": [
    "Altar of Thrond"
  ],
  "jumping flame spider": [
    "Aranea Saltans Flammarum"
  ],
  "wind stone": [
    "Lapis Venti"
  ],
  "frostmoon crag": [
    "Frostmoon Crag"
  ],
  "abandoned lodge": [
    "Abandoned Lodge"
  ],
  "broken tusk mine": [
    "Broken Tusk Mine"
  ],
  "haknir's shoal": [
    "Haknir's Shoal"
  ],
  "northshore landing": [
    "Northshore Landing"
  ],
  "tosses a jumping fire spider on the ground that will launch itself at nearby enemies and explode if it lands near them": [
    "Araneam Flammarum Amiculatam in solum iacit quae se ad inimico iacit"
  ],
  "wreck of the strident squall": [
    "Naufragium Clamoris Stridentis"
  ],
  "bloodskal barrow": [
    "Pabillio Sanguiskalis"
  ],
  "strident squall": [
    "Strident Squall"
  ],
  "you know what you have to do": [
    "Scis quid faciendum esse"
  ],
  "flame spider": [
    "Flame Spider"
  ],
  "spider poison": [
    "Spider Poison"
  ],
  "skaal village": [
    "Skaal Village"
  ],
  "moesring pass": [
    "Commeatus Moesring"
  ],
  "frossel": [
    "Frossel"
  ],
  "brodir grove": [
    "Brodir Grove"
  ],
  "imbued spider": [
    "Aranea Imbuta"
  ],
  "thirsk": [
    "Thirsk"
  ],
  "lurker": [
    "Exspectator"
  ],
  "fire hazard": [
    "Fire Hazard"
  ],
  "sun stone": [
    "Sun Stone"
  ],
  "poison hazard": [
    "Poison Hazard"
  ],
  "thirsk mead hall": [
    "Hydromellium Thirsk"
  ],
  "jumping poison spider": [
    "Aranea Saltans Veneni"
  ],
  "solstheim": [
    "Solstheim"
  ],
  "zombie by spider": [
    "Zombie by Spider"
  ],
  "flame cloaked spider": [
    "Aranea Flammarum Amiculata"
  ],
  "control pedestal": [
    "Bas Machinae"
  ],
  "fire spider flame trail": [
    "Fire Spider Flame Trail"
  ],
  "ulves": [
    "Ulves"
  ],
  "chance to paralyze the target for <dur> seconds": [
    "Casus ut inimicus paralyticus sit <dur> secundas",
    "Casus est ut inimicum paralyticum facias <dur> secundas"
  ],
  "flame spider abilities": [
    "Flame Spider Abilities"
  ],
  "ash shell": [
    "Qui non resistunt in cinere duro erunt <dur> secundas"
  ],
  "nchardak aqueduct": [
    "Nchardak Aquaeductum"
  ],
  "damaged albino spider pod": [
    "Folliculus Araneae Albae Nocita"
  ],
  "conjure ash guardian": [
    "Voca Custodem Cineris"
  ],
  "nchardak great chamber": [
    "Nchardak Camera Magna"
  ],
  "black book: epistolary acumen": [
    "Liber Ater: Acumen Epistolarium"
  ],
  "a mysterious note": [
    "Epistula Arcana"
  ],
  "mysterious note": [
    "Epistula Arcana",
    "Epistula Mirabilis"
  ],
  "creates an ash guardian that guards that location until destroyed. consumes a heart stone from your inventory, without which it will be hostile": [
    "Facit Custodem Cineris qui hunc locum custodiet"
  ],
  "creatures and people that this spider attaches to will fight for you for 30 seconds": [
    "Inimicos quos haec aranea oppugnaverit pro te XXX secundas oppugnabit"
  ],
  "tosses a dead spider on the ground which explodes when an enemy gets too close": [
    "Araneam in solum iacit quod displodet cum inimicus nimis proximus eat"
  ],
  "drovas relvi": [
    "Drovas Relvi"
  ],
  "dlc2ralis": [
    "Ralis"
  ],
  "npc ward absorb": [
    "NPC Ward Absorb"
  ],
  "frea": [
    "Frea"
  ],
  "elynea mothren": [
    "Elynea Mothren"
  ],
  "imbuing chamber": [
    "Cameram Imbuendi"
  ],
  "neloth services": [
    "Neloth Services"
  ],
  "uses the nearby cruxis point to flings the caster into the air": [
    "Uses the nearby Cruxis point to flings the caster into the air"
  ],
  "ashen grass pod": [
    "Folliculus Graminis Cineris"
  ],
  "ash rune": [
    "Runus Cineris"
  ],
  "storn": [
    "Storn"
  ],
  "albino spider pod": [
    "Folliculus Araneae Albae"
  ],
  "storn crag-strider": [
    "Storn Derupta-Cursor"
  ],
  "npc necromage": [
    "NPC Necromage"
  ],
  "opponents in melee range have a chance to be flung away": [
    "Inimici prope te forte emmituntur"
  ],
  "whirlwind cloak damage": [
    "Vulnus Amiculi Turbinis"
  ],
  "neloth": [
    "Neloth"
  ],
  "frenzy rune": [
    "Runus Insanitatis"
  ],
  "tel mithryn kitchen": [
    "Tel Mithryn Culina"
  ],
  "ash guardian faction": [
    "Ash Guardian Faction"
  ],
  "waterwalking": [
    "Aquambulans",
    "Super Aquam Ambulare"
  ],
  "drovas": [
    "Drovas"
  ],
  "spell tome: frenzy rune": [
    "Liber Magicae: Runus Insanitatis"
  ],
  "elynea": [
    "Elynea"
  ],
  "targets that fail to resist are immobilized in hardened ash for <dur> seconds": [
    "Qui non resistunt in cinere duro erunt <dur> secundas"
  ],
  "riekling spear": [
    "Tela Riekling"
  ],
  "npc mage armor": [
    "NPC Mage Armour"
  ],
  "tel mithryn faction": [
    "Tel Mithryn Faction"
  ],
  "talvas fathryon": [
    "Talvas Fathryon"
  ],
  "glacial cave": [
    "Glacial Cave"
  ],
  "for <dur> seconds, opponents in melee range have a chance of being flung away": [
    "<dur> secundas, inimici prope te forte eijiuntur"
  ],
  "creatures and people up to level <mag> will attack anything nearby for <dur> seconds": [
    "Inimica ad gradum <mag> omnia prope se oppugnabunt <dur> secundas",
    "Animalia hominesque ad gradum <mag> quidquid prope eius forte sit oppugnant <dur> secundas"
  ],
  "talvas services": [
    "Talvas Services"
  ],
  "npc summoner": [
    "NPC Summoner"
  ],
  "fling": [
    "Fling"
  ],
  "whirlwind cloak": [
    "Amiculum Turbinis"
  ],
  "staff enchanting mult": [
    "Staff Enchanting Mult"
  ],
  "npc augmented frost": [
    "NPC Augmented Frost"
  ],
  "ash guardian": [
    "Custos Cineris"
  ],
  "npcaugmented shock": [
    "NPCAugmented Shock"
  ],
  "paralyze 100%": [
    "Paralyticum 100%"
  ],
  "npc recovery": [
    "NPC Recovery"
  ],
  "spell tome: whirlwind cloak": [
    "Liber Magicae: Amiculum Turbonis"
  ],
  "control cube": [
    "Cubus Dwemere"
  ],
  "mind control spider": [
    "Aranea Mentis Regendi"
  ],
  "init quest": [
    "Init quest"
  ],
  "the path of knowledge": [
    "Via Scientiae"
  ],
  "path of knowledge": [
    "Via Scientiae"
  ],
  "talvas": [
    "Talvas"
  ],
  "enchanter": [
    "Enchanter",
    "Incantator"
  ],
  "cast on a nearby surface, it explodes when enemies are nearby, immobilizing them in hardened ash for <dur> seconds": [
    "Fac in solum, displodit cum inimicos appropinquent, et eos in cinere tegit <dur> secundas"
  ],
  "heart stone": [
    "Corlapis"
  ],
  "spider visuals fire": [
    "Spider Visuals Fire"
  ],
  "nchardak workshop": [
    "Nchardak Fabrica"
  ],
  "tel mithryn apothecary": [
    "Tel Mithryn Apothecarium"
  ],
  "elynea services": [
    "Elynea Services"
  ],
  "black book: untold legends": [
    "Liber Ater: Legenda Innarranda"
  ],
  "tel mithryn steward's house": [
    "Tel Mithryn Domus Dispensatoris"
  ],
  "spider experiment notes": [
    "Adnotationes Eperimenti Araneae"
  ],
  "albino spider": [
    "Aranea Alba"
  ],
  "staff enchanter": [
    "Incantatore Rudium"
  ],
  "ildari sarothril": [
    "Ildari Sarothril"
  ],
  "whirlwind damage": [
    "Whirlwind Damage"
  ],
  "exploding poison spider": [
    "Aranea Displodens Veneni"
  ],
  "ralis": [
    "Ralis"
  ],
  "a fiery explosion for <mag> points of damage in a 15 foot radius": [
    "Displosio <mag> puncta vulneris in radio 15 pedum",
    "Displosio ignifera quae <mag> vulneris inter 15 pedibus"
  ],
  "fiery explosion for <mag> points of damage in a 15 foot radius": [
    "Displosio <mag> puncta vulneris in radio 15 pedum",
    "Displosio ignifera quae <mag> vulneris inter 15 pedibus"
  ],
  "imbuing switch": [
    "Fulcrum Imbuendi"
  ],
  "spell tome: ash shell": [
    "Liber Magicae: Lorica Cineris"
  ],
  "ralis sedarys": [
    "Ralis Sedarys"
  ],
  "spell tome: ash rune": [
    "Liber Magicae: Runus Cineris"
  ],
  "exploding spider faction": [
    "Exploding Spider Faction"
  ],
  "trama root": [
    "Radicem Tramae"
  ],
  "black book: waking dreams": [
    "Liber Ater: Somnia Excitata"
  ],
  "varona": [
    "Varona"
  ],
  "cube storage chest": [
    "Arcam Cubi"
  ],
  "varona nelas": [
    "Varona Nelas"
  ],
  "targets up to level <20> that fail to resist are frenzied for <30> seconds": [
    "Inimici ad gradum <20> qui non resistunt insani erunt <30> secundas"
  ],
  "creatures and people will attack enemies nearby for 10 seconds": [
    "Inimici alios prope se oppugnabunt 10 secundas"
  ],
  "ulves romoran": [
    "Ulves Romoran"
  ],
  "aphia velothi": [
    "Aphia Velothi"
  ],
  "krosulhah": [
    "Krosulhah"
  ],
  "severin manor": [
    "Domus Severinis"
  ],
  "hrothmund's barrow": [
    "Hrothmund's Barrow"
  ],
  "gol": [
    "Gol"
  ],
  "h4": [
    "H4"
  ],
  "veleth": [
    "Veleth"
  ],
  "varona went to raven rock on an errand": [
    "Varona Corvisaxum petit ut aliquam mihi faceret"
  ],
  "raven rock ebony anvil faction": [
    "Raven Rock Ebony Anvil Faction"
  ],
  "know anyone that might take the job": [
    "Scisne quem qui laborem accipiat"
  ],
  "ienth farmhouse key": [
    "Ienthis Villa Rustica Clavis"
  ],
  "milore": [
    "Milore"
  ],
  "ienth farm": [
    "Ienth Farm"
  ],
  "captain veleth": [
    "Nauarchos Veleth"
  ],
  "tilisu": [
    "Tilisu"
  ],
  "glover mallory's house key": [
    "Glover Mallory Domus Clavis"
  ],
  "tilisu severin": [
    "Tilisu Severin"
  ],
  "retching netch key": [
    "Netch Vomitans Sodalicii Anguli Clavis"
  ],
  "the journal of ralis sedarys - volume 20": [
    "Ephemerides Ralis Sedarys - Volumen XX"
  ],
  "journal of ralis sedarys - volume 20": [
    "Ephemerides Ralis Sedarys - Volumen XX"
  ],
  "tame dragon controller": [
    "Tame Dragon controller"
  ],
  "raven rock severin manor faction": [
    "Raven Rock Severin Manor Faction"
  ],
  "vendil & tilisu shared faction": [
    "Vendil & Tilisu Shared Faction"
  ],
  "raven rock temple key": [
    "Templum Reclamationium Clavis"
  ],
  "bend will": [
    "Mentem Rege"
  ],
  "glover mallory": [
    "Glover Mallory"
  ],
  "dov": [
    "Dov"
  ],
  "dravynea": [
    "Dravynea"
  ],
  "crescius caerellius": [
    "Crescius Caerellius"
  ],
  "morvayn manor key": [
    "Morvayn Domus Clavis"
  ],
  "raven rock crescius caerellius's house faction": [
    "Raven Rock Crescius Caerellius' House Faction"
  ],
  "you seem awfully eager": [
    "Valde cupidus videris"
  ],
  "dreyla": [
    "Dreyla"
  ],
  "aphia": [
    "Aphia"
  ],
  "alor house key": [
    "Alor Domus Clavis"
  ],
  "boarriekling": [
    "Boar Riekling"
  ],
  "the journal of ralis sedarys - volume 23": [
    "Ephemerides Ralis Sedarys - Volumen XXIII"
  ],
  "journal of ralis sedarys - volume 23": [
    "Ephemerides Ralis Sedarys - Volumen XXIII"
  ],
  "raven rock temple faction": [
    "Temple of the Reclamations Faction"
  ],
  "fethis": [
    "Fethis"
  ],
  "dravynea the stoneweaver": [
    "Dravynea Lapiditexor",
    "Dravynea the Lapiditexor"
  ],
  "scathecraw": [
    "Scathecraw"
  ],
  "i found varona dead on the road to raven rock": [
    "Varonam mortuam in via Corvisaxum inveni"
  ],
  "report back to neloth": [
    "Refer te ad Neloth"
  ],
  "shaman's hut": [
    "Casa Magi"
  ],
  "garyn": [
    "Garyn"
  ],
  "find a new steward": [
    "Inveni Dispensatorem Novum"
  ],
  "tell neloth that varona is dead": [
    "Dic Neloth Varonam mortuam esse"
  ],
  "find varona": [
    "Inveni Varonam"
  ],
  "garyn ienth": [
    "Garyn Ienth"
  ],
  "raven rock ienth farm faction": [
    "Raven Rock Ienth Farm Faction"
  ],
  "caerellius house key": [
    "Caerellii Domus Clavis"
  ],
  "geldis sadri": [
    "Geldis Sadri"
  ],
  "vendil": [
    "Vendil"
  ],
  "lleril morvayn": [
    "Lleril Morvayn"
  ],
  "the bulwark": [
    "Propugnaculum"
  ],
  "the journal of ralis sedarys - volume 22": [
    "Ephemerides Ralis Sedarys - Volumen XXII"
  ],
  "journal of ralis sedarys - volume 22": [
    "Ephemerides Ralis Sedarys - Volumen XXII"
  ],
  "a new debt": [
    "Debitum Novum"
  ],
  "new debt": [
    "Debitum Novum"
  ],
  "riekling hut": [
    "Casam Riekling"
  ],
  "varona is dead": [
    "Varona mortua est"
  ],
  "elder othreloth": [
    "Elder Othreloth"
  ],
  "pile of gold": [
    "Cumulum Auri"
  ],
  "morvayn manor": [
    "Villa Morvaynis"
  ],
  "dreyla alor": [
    "Dreyla Alor"
  ],
  "do you want to be the new steward of tel mithryn": [
    "Visne dispensatorem novum Tel Mithryn esse"
  ],
  "fling stone trigger": [
    "Fling Stone Trigger"
  ],
  "galdrus": [
    "Galdrus"
  ],
  "milore ienth": [
    "Milore Ienth"
  ],
  "adril arano": [
    "Adril Arano"
  ],
  "i've found you a new steward": [
    "Dispensatorem novum tibi inveni"
  ],
  "severin manor key": [
    "Severini Villa Clavis"
  ],
  "galdrus hlervu": [
    "Galdrus Hlervu"
  ],
  "caerellius house": [
    "Domus Caerellii"
  ],
  "geldis": [
    "Geldis"
  ],
  "lleril": [
    "Lleril"
  ],
  "test guardian": [
    "Test Guardian"
  ],
  "mounted riekling": [
    "Riekling in Apro"
  ],
  "tame dragon": [
    "Tame Dragon"
  ],
  "glover": [
    "Glover"
  ],
  "have you seen varona": [
    "Vidistine Varonam"
  ],
  "the journal of ralis sedarys - volume 21": [
    "Ephemerides Ralis Sedarys - Volumen XXI"
  ],
  "journal of ralis sedarys - volume 21": [
    "Ephemerides Ralis Sedarys - Volumen XXI"
  ],
  "othreloth": [
    "Othreloth"
  ],
  "what do you have for sale": [
    "Quae vendenda habes"
  ],
  "fethis alor": [
    "Fethis Alor"
  ],
  "raven rock retching netch faction": [
    "Raven Rock Retching Netch Cornerclub Faction"
  ],
  "crescius": [
    "Crescius"
  ],
  "raven rock bulwark faction": [
    "Raven Rock Bulwark Faction"
  ],
  "reluctant steward": [
    "Dispensator Invitus"
  ],
  "cindiri": [
    "Cindiri"
  ],
  "vendil severin": [
    "Vendil Severin"
  ],
  "mirri": [
    "Mirri"
  ],
  "mirri severin": [
    "Mirri Severin"
  ],
  "no, i haven't seen varona": [
    "Minime, Varonam non vidi"
  ],
  "gjalund": [
    "Gjalund"
  ],
  "crescius & aphia shared faction": [
    "Crescius & Aphia Shared Faction"
  ],
  "adril": [
    "Adril"
  ],
  "gjalund salt-sage": [
    "Nauarchos Gjalund Sali-Saga"
  ],
  "raven rock morvayn manor faction": [
    "Raven Rock Morvayn Manor Faction"
  ],
  "alor house": [
    "Domus Aloris"
  ],
  "bend to will effect - stagger": [
    "Bend To Will Effect - Stagger"
  ],
  "the retching netch": [
    "Netch Vomitans",
    "Sodalicium Anguli Netch Vomitans"
  ],
  "retching netch": [
    "Netch Vomitans",
    "Sodalicium Anguli Netch Vomitans"
  ],
  "adril and cindiri arano's shared faction": [
    "Adril and Cindiri Arano's Shared Faction"
  ],
  "cindiri arano": [
    "Cindiri Arano"
  ],
  "glover mallory's house": [
    "Domus Glover Mallory"
  ],
  "morvayn manor chambers": [
    "Convlavia Villae Morvaynis"
  ],
  "fethis and dreyla alor's shared faction": [
    "Fethis and Dreyla Alor's Shared Faction"
  ],
  "the journal of ralis sedarys - volume 19": [
    "Ephemerides Ralis Sedarys - Volumen XIX"
  ],
  "journal of ralis sedarys - volume 19": [
    "Ephemerides Ralis Sedarys - Volumen XIX"
  ],
  "potion of vigorous well-being": [
    "Potio Bene Essendi Vigorosi"
  ],
  "detect ash source": [
    "Inveni Fontem Cineris"
  ],
  "old attius farm": [
    "Latifundus Vetus Attii"
  ],
  "elmus": [
    "Elmus"
  ],
  "to plant the taproot": [
    "Sparge Tapiradicem"
  ],
  "plant the taproot": [
    "Sparge Tapiradicem"
  ],
  "a new passage to what": [
    "Commeatus novus ad quid"
  ],
  "new passage to what": [
    "Commeatus novus ad quid"
  ],
  "imperial bracers": [
    "Romana Bracchialia"
  ],
  "cyclone": [
    "Turbo"
  ],
  "raven rock guard faction": [
    "Raven Rock Guard Faction"
  ],
  "edla's house key": [
    "Edlae Domus Clavis"
  ],
  "imperial armor": [
    "Romana Lorica"
  ],
  "5 gold": [
    "5 Gold"
  ],
  "raven rock ienth farm scene 02": [
    "Raven Rock Ienth Farm Scene 02"
  ],
  "slitter": [
    "Slitter"
  ],
  "talk to mogrul": [
    "Adloquere Mogrul"
  ],
  "25 gold": [
    "25 Gold"
  ],
  "10 gold": [
    "10 Gold"
  ],
  "test your luck": [
    "Test Your Luck"
  ],
  "you need to add gold": [
    "You need to add gold"
  ],
  "finna": [
    "Finna"
  ],
  "raven rock crescius's house scene 01": [
    "Raven Rock Crescius' House Scene 01"
  ],
  "castle karstaag courtyard": [
    "Castelli Karstaag Peristylum"
  ],
  "abandoned building": [
    "Abandoned Building"
  ],
  "crescius's house": [
    "Crescius' House"
  ],
  "thirsk mead hall dialogue": [
    "Thirsk Mead Hall Dialogue"
  ],
  "temple location": [
    "Temple Location"
  ],
  "the bulwark barracks": [
    "The Bulwark Barracks"
  ],
  "bulwark barracks": [
    "The Bulwark Barracks"
  ],
  "blessing of the fox": [
    "Benedictio Vulpis"
  ],
  "the bulwark jail": [
    "The Bulwark Jail"
  ],
  "bulwark jail": [
    "The Bulwark Jail"
  ],
  "saering's watch": [
    "Saering's Watch"
  ],
  "spine1": [
    "Spine1"
  ],
  "karstaag's throne": [
    "Solium Karstaagis"
  ],
  "learn word of power": [
    "Dice Verbum Potestatis ad Visum Saeringis"
  ],
  "i've destroyed the ash guardian": [
    "Custodem Cineris vici"
  ],
  "actually, i'll come back later": [
    "Re vere, postea reveniam"
  ],
  "oslaf's house key": [
    "Oslafis Domus Clavis"
  ],
  "use the \"bend will\" shout to tame sahrotaar": [
    "Uti Clamatione \"Animum Regere\" ut Sahrotaari imperes"
  ],
  "wait for storn to read the book": [
    "Expecta Storn"
  ],
  "at the summit of apocrypha": [
    "Ad Summum Apocryphae"
  ],
  "read \"waking dreams\"": [
    "Lege \"Somnia Vigilia\""
  ],
  "talk to storn": [
    "Adloquere Storn"
  ],
  "what can you tell me about the harstrad headwaters": [
    "Quid mihi de capite rivi Harstradis narrare potes"
  ],
  "the gardener of men": [
    "Hortulanus Hominum"
  ],
  "gardener of men": [
    "Hortulanus Hominum"
  ],
  "it was in ildari sarothril's grave": [
    "In sepulcro Ildari Sarothrilis erat"
  ],
  "champion's cudgel": [
    "Fustis Victoris"
  ],
  "riekling chief": [
    "Riekling Princeps"
  ],
  "approach thirsk mead hall": [
    "Appropinqua Aulam Hydomelli Thirsk"
  ],
  "this heart stone is the source of the attacks": [
    "Hoc corsaxum est fons impetuum"
  ],
  "skaal village dialogue": [
    "Skaal Village Dialogue"
  ],
  "defeat the lurker": [
    "Exspectatorem Vince"
  ],
  "benkongerike great hall": [
    "Benkongerike Aula Magna"
  ],
  "talk to storn crag-strider": [
    "Adloquere Storn Aegi-Cursor"
  ],
  "raven rock any location scene 06": [
    "Raven Rock Any Location Scene 06"
  ],
  "oslaf": [
    "Oslaf"
  ],
  "raven rock any location scene 12": [
    "Raven Rock Any Location Scene 12"
  ],
  "pay or kill mogrul": [
    "Solve Pecuniam Vel Neca Mogrul"
  ],
  "pay mogrul": [
    "Solve Pecuniam ad Mogrul"
  ],
  "morwen": [
    "Morwen"
  ],
  "raven rock dialogue": [
    "Raven Rock Dialogue"
  ],
  "rirns": [
    "Rirns"
  ],
  "return to captain veleth": [
    "Redi ad Nauarchon Veleth"
  ],
  "kill general falx carius": [
    "Neca Imperatorem Falcem Carium"
  ],
  "speak to captain veleth": [
    "Adloquere Nauarchon Veleth"
  ],
  "fanari strong-voice": [
    "Fanari Fortivox"
  ],
  "exploding flame spider": [
    "Aranea Flammarum Displodens"
  ],
  "bralsa": [
    "Bralsa"
  ],
  "kill the ash spawn attacking captain veleth": [
    "Neca filios cineris qui Nauarchon Veleth oppugnant"
  ],
  "kill ildari": [
    "Neca Ildaren"
  ],
  "talk to neloth": [
    "Adloquere Neloth"
  ],
  "raven rock morvayn manor scene 04": [
    "Raven Rock Morvayn Manor Scene 04"
  ],
  "old friends": [
    "Amici Antiqui"
  ],
  "raven rock morvayn manor scene 02": [
    "Raven Rock Morvayn Manor Scene 02"
  ],
  "find the source of the attacks": [
    "Inveni Fontem Oppugnata"
  ],
  "baldor": [
    "Baldor"
  ],
  "raven rock morvayn manor scene 05": [
    "Raven Rock Morvayn Manor Scene 05"
  ],
  "raven rock morvayn manor scene 06": [
    "Raven Rock Morvayn Manor Scene 06"
  ],
  "scribskin": [
    "ScribSkin"
  ],
  "raven rock temple scene 02": [
    "Raven Rock Temple Scene 02"
  ],
  "raven rock any location scene 11": [
    "Raven Rock Any Location Scene 11"
  ],
  "raven rock swing & scoop scene 02": [
    "Raven Rock Swing & Scoop Scene 02"
  ],
  "nikulas": [
    "Nikulas"
  ],
  "greathall": [
    "Skaalis Vicus Aula Magna"
  ],
  "except now he expects me to pay him": [
    "Sed nunc credit me ei pecuniam daturum esse"
  ],
  "baldor's house key": [
    "Baldoris Domus Clavis"
  ],
  "raven rock crescius's house scene 02": [
    "Raven Rock Crescius' House Scene 02"
  ],
  "raven rock severin manor scene 01": [
    "Raven Rock Severin Manor Scene 01"
  ],
  "raven rock severin manor scene 02": [
    "Raven Rock Severin Manor Scene 02"
  ],
  "skaal village oslaf's house faction": [
    "Skaal Village Oslaf's House Faction"
  ],
  "raven rock any location scene 15": [
    "Raven Rock Any Location Scene 15"
  ],
  "raven rock netch scene 01": [
    "Raven Rock Netch Scene 01"
  ],
  "raven rock netch scene 02": [
    "Raven Rock Netch Scene 02"
  ],
  "raven rock netch scene 04": [
    "Raven Rock Netch Scene 04"
  ],
  "bujold the intrepid": [
    "Bujold Intrepidus"
  ],
  "raven rock netch scene 05": [
    "Raven Rock Netch Scene 05"
  ],
  "raven rock netch scene 06": [
    "Raven Rock Netch Scene 06"
  ],
  "raven rock netch scene 07": [
    "Raven Rock Netch Scene 07"
  ],
  "explore vahlok's tomb": [
    "Explora Tumulum Vahlokis"
  ],
  "talk to tharstan": [
    "Tharstan Adloquere"
  ],
  "travel to vahlok's tomb": [
    "Peregrina ad Tumulum Vahlokis"
  ],
  "read the black book": [
    "Lege Epistulam Atram"
  ],
  "morwen's house key": [
    "Morwenis Domus Clavis"
  ],
  "raven rock any location scene 01": [
    "Raven Rock Any Location Scene 01"
  ],
  "raven rock any location scene 02": [
    "Raven Rock Any Location Scene 02"
  ],
  "raven rock any location scene 03": [
    "Raven Rock Any Location Scene 03"
  ],
  "raven rock any location scene 05": [
    "Raven Rock Any Location Scene 05"
  ],
  "accompany frea to skaal village": [
    "Ii cum Frea ad vicum Skaal"
  ],
  "raven rock temple scene 03": [
    "Raven Rock Temple Scene 03"
  ],
  "raven rock any location scene 09": [
    "Raven Rock Any Location Scene 09"
  ],
  "raven rock any location scene 10": [
    "Raven Rock Any Location Scene 10"
  ],
  "raven rock beggar scenes": [
    "Raven Rock Beggar Scenes"
  ],
  "retaking thirsk": [
    "Thirsk Recipiens"
  ],
  "deor's house key": [
    "Deoris Domus Clavis"
  ],
  "talk to talvas": [
    "Talvan Adloquere"
  ],
  "from the ashes": [
    "E Cineribus"
  ],
  "aren't you coming to highpoint tower": [
    "Nonne ad Turrem Altapunctum venis"
  ],
  "talk to elynea": [
    "Elyneam Adloquere"
  ],
  "soak the taproots": [
    "Fac Tapiradices Madidas"
  ],
  "why don't you do this": [
    "Quidni tu hoc non facias"
  ],
  "why me": [
    "Ego, cur",
    "Quamobrem ego"
  ],
  "what's the problem": [
    "Quid est problema",
    "Quid est difficultas"
  ],
  "raven rock any location scene 07": [
    "Raven Rock Any Location Scene 07"
  ],
  "master neloth": [
    "Dominus Neloth"
  ],
  "what did he do": [
    "Quid fecit",
    "Quid fecit ille"
  ],
  "do you owe mogrul money": [
    "Esne in Aere Alieno Mogruli"
  ],
  "temple of miraak sanctum": [
    "Fanum Templi Miraakis"
  ],
  "fanari": [
    "Fanari"
  ],
  "baldor iron-shaper's house": [
    "Domus Baldoris Ferri-Factoris"
  ],
  "dlc2tt2nelothstarttopic": [
    "DLC2TT2NelothStartTopic"
  ],
  "someone is attacking you": [
    "Aliquis te oppugnat"
  ],
  "deor woodcutter's house": [
    "Casa Deoris Boscatoris"
  ],
  "raven rock beggar faction": [
    "Raven Rock Beggar Faction"
  ],
  "headwaters": [
    "Caput Rivi"
  ],
  "break-it downer": [
    "Break-It Downer"
  ],
  "i hear you know where to find black books": [
    "Audivi te artem Libros Atros inveniendi scire"
  ],
  "talvas summoned an ash guardian outside": [
    "Talvas Custorem Cineris foris vocavit"
  ],
  "did you know about the ash guardian outside": [
    "Covistine de Custode Cineris foris"
  ],
  "what went wrong with the ash guardian spell": [
    "Quid erat problam cum magica Custodem Cineris vocandi"
  ],
  "the dwemer": [
    "Dvemeris"
  ],
  "dwemer": [
    "Dvemeris"
  ],
  "what do they have to do with this": [
    "Quid cum hoc aguntur"
  ],
  "what do you need to fix the tower": [
    "Quid quaeris ut turrem reficias"
  ],
  "caster is invisible for <dur> seconds": [
    "Caster is invisible <dur> secundas"
  ],
  "activating an object or attacking will break the spell": [
    "Activating an object or attacking will break the spell"
  ],
  "why don't you just pay him": [
    "Quidni ei modo pecuniam solvas"
  ],
  "spell tome: conjure ash guardian": [
    "Liber Magicae: Voca Custodem Cineris"
  ],
  "creates an ash guardian that guards that location until destroyed. consumes a heart stone from your inventory": [
    "Facit Custodem Cineris qui hunc locum custodiet"
  ],
  "where can i get taproot": [
    "Ubi tapiradicem invenire possum"
  ],
  "arcane converter - input": [
    "Convertorem Arcanum - Impone"
  ],
  "learn the hidden knowledge of \"epistolary acumen\"": [
    "Disce Scientiam Celatam in \"Epistolarium Acumen\""
  ],
  "i have some right here": [
    "Paulum hic habeo"
  ],
  "i have the three soaked taproots": [
    "Tres tapiradices madidas tibi habeo"
  ],
  "elynea said you needed these taproots": [
    "Elynea dixit te his tapiradicibis carere"
  ],
  "detection target": [
    "Detection Target"
  ],
  "imperial boots": [
    "Caligae Romanae",
    "Romanae Caligae"
  ],
  "baldor iron-shaper": [
    "Baldor Ferri-Factor"
  ],
  "rirns llervu": [
    "Rirns Llervu"
  ],
  "the soaked taproot is planted": [
    "Nirniradix madida soli est"
  ],
  "soaked taproot is planted": [
    "Nirniradix madida soli est"
  ],
  "karstaag": [
    "Karstaag"
  ],
  "i have to know what miraak knows if i want to stop him": [
    "Scientiam Miraakis habere debeo ut eum vincam"
  ],
  "skaal greathall key": [
    "Skaal Vici Aula Magna Clavis"
  ],
  "staff maker faction": [
    "Staff Maker Faction"
  ],
  "what's going on here": [
    "Quid hic accidit"
  ],
  "that sounds like fun": [
    "Ludibundum mihi videtur"
  ],
  "i don't think i can help you right now": [
    "Credo me nunc tibi auxilium dare non posse"
  ],
  "aeta": [
    "Aeta"
  ],
  "edla's house": [
    "Domus Edlae"
  ],
  "halbarn iron-fur": [
    "Halbarn Ferri-Rheno"
  ],
  "get three taproots (<global=dlc2ttf2taprootsacquired>/3)": [
    "Collige Tres Tapiradices (<global=DLC2TTF2TaprootsAcquired>/3)"
  ],
  "bloodscythe": [
    "Sanguifalx"
  ],
  "loose rubble": [
    "Saxa Laxa"
  ],
  "miraak": [
    "Miraak"
  ],
  "wulf wild-blood": [
    "Wulf Saevi-Sanguis"
  ],
  "ildari is dead": [
    "Ildari mortua est"
  ],
  "i'd like to book passage to solstheim": [
    "Volo ad Solstheim ire"
  ],
  "defeat miraak": [
    "Miraak Vince"
  ],
  "raven rock morvayn manor scene 01": [
    "Raven Rock Morvayn Manor Scene 01"
  ],
  "deor woodcutter": [
    "Deor Boscator"
  ],
  "yrsa": [
    "Yrsa"
  ],
  "invisibility": [
    "Invisibilitas"
  ],
  "potion of ultimate well-being": [
    "Potio Bene Essendi Ultimi"
  ],
  "coin slot": [
    "Locus Aptus Nummis"
  ],
  "i can destroy it for you": [
    "Id pro te delere possum"
  ],
  "what kind of situation": [
    "Quale Problema"
  ],
  "use the \"bend will\" shout on the wind stone": [
    "Uti clamatione \"Mentem Rege\" in Lapidem Venti"
  ],
  "destroy the ash guardian": [
    "Dele Custodem Cineris"
  ],
  "whale breath": [
    "Whale Breath"
  ],
  "march of the dead": [
    "Iter Mortuorum"
  ],
  "raven rock guard dialogue": [
    "Raven Rock Guard Dialogue"
  ],
  "i found a black book": [
    "Librum Atrum inveni"
  ],
  "i need to find more": [
    "Plurimos invenire debeo"
  ],
  "benkongerike key": [
    "Benkongerike Clavis"
  ],
  "raven rock morvayn manor scene 03": [
    "Raven Rock Morvayn Manor Scene 03"
  ],
  "how close do i have to be": [
    "Quam prope id esse debeo"
  ],
  "and if i don't pay you": [
    "Quid accicet si te non solvam"
  ],
  "wulf wild-blood's house": [
    "Domus Wulf Saevi-Sanguinis"
  ],
  "raven rock temple scene 01": [
    "Raven Rock Temple Scene 01"
  ],
  "arcane converter - output": [
    "Converterem Arcanum - Expone"
  ],
  "edla": [
    "Edla"
  ],
  "gyldenhul barrow key": [
    "Gyldenhul Pabillum Clavis"
  ],
  "potion of plentiful well-being": [
    "Potio Bene Essendi Magni"
  ],
  "there wasn't a body in the grave. just the heart stone": [
    "Nullum corpus in sepulcro erat, modo corsaxum"
  ],
  "raven rock any location scene 04": [
    "Raven Rock Any Location Scene 04"
  ],
  "does the guild know anyone here that might": [
    "Sodalitasne scit de aliquo hic qui fortasse sciat"
  ],
  "hilund": [
    "Hilund"
  ],
  "potion of well-being": [
    "Potio Bene Essendi"
  ],
  "kill the rieklings (<global=dlc2mh01rieklingskilled>/<global=dlc2mh01rieklingstotal>)": [
    "Neca Rieklinges (<Global=DLC2MH01RieklingsKilled>/<Global=DLC2MH01RieklingsTotal>)"
  ],
  "tharstan": [
    "Tharstan"
  ],
  "reaver lord": [
    "Praeditor Dominus"
  ],
  "lost legacy": [
    "Legatum Perditum"
  ],
  "oslaf's house": [
    "Domus Oslafis"
  ],
  "the fate of the skaal": [
    "Fatum Skaal"
  ],
  "fate of the skaal": [
    "Fatum Skaal"
  ],
  "where are we headed": [
    "Quo imus"
  ],
  "i'm so sorry": [
    "Ignosce mihi"
  ],
  "here, take the money": [
    "Hic, habe pecuniam"
  ],
  "healing a house": [
    "Domum Sanans"
  ],
  "halbarn": [
    "Halbarn"
  ],
  "raven rock severin manor scene 03": [
    "Raven Rock Severin Manor Scene 03"
  ],
  "neloth's ring of tracking": [
    "Nelothis Anulus Inveniendi"
  ],
  "about that money drovas owes you": [
    "De pecunia quae Drovas tibi debet"
  ],
  "tosses a flame cloaked spider on the ground that will cause fire damage to enemies that get too close": [
    "Araneam Flammarum Amiculatam in solum iacit quae vulnus ignis inimicis prope se faciet"
  ],
  "morwen's house": [
    "Domus Morwenis"
  ],
  "wulf": [
    "Wulf"
  ],
  "haknir death-brand": [
    "Haknir Mortibrandis"
  ],
  "hay pile": [
    "Cumulum Culmi",
    "Cumulum Faeni"
  ],
  "raven rock swing & scoop scene 01": [
    "Raven Rock Swing & Scoop Scene 01"
  ],
  "potion of minor well-being": [
    "Potio Bene Essendi Minoris"
  ],
  "deor": [
    "Deor"
  ],
  "unlock your \"bend will\" shout": [
    "Habilita Clamationem \"Animum Regere\""
  ],
  "skaal village citizen faction": [
    "Skaal Village Citizen Faction"
  ],
  "soaked taproot": [
    "Tapiradix Madida"
  ],
  "bralsa drel": [
    "Bralsa Drel"
  ],
  "raven rock netch scene 03": [
    "Raven Rock Netch Scene 03"
  ],
  "wulf's house key": [
    "Wulfi Domus Clavis"
  ],
  "redoran guard": [
    "Custos Redoranis"
  ],
  "blessing of the fox invisibility": [
    "Blessing Of The Fox Invisibility"
  ],
  "or i could just kill you now": [
    "Vel te nunc necare possum"
  ],
  "raven rock any location scene 08": [
    "Raven Rock Any Location Scene 08"
  ],
  "please be patient": [
    "Exspecta, quaeso"
  ],
  "i'll get you your money": [
    "Pecunia tua potiar"
  ],
  "general falx carius": [
    "Imperator Falx Carius"
  ],
  "blessing of the hunt": [
    "Sacramentum Venationis"
  ],
  "a cyclone that does <mag> points of damage per second and flings victims into the air": [
    "Turbo quod <mag> puncta vulneris facit it inimicos in aerem sumit"
  ],
  "cyclone that does <mag> points of damage per second and flings victims into the air": [
    "Turbo quod <mag> puncta vulneris facit it inimicos in aerem sumit"
  ],
  "kuvar": [
    "Kuvar"
  ],
  "skaal village deor woodcutter's house faction": [
    "Skaal Village Deor Woodcutter's House Faction"
  ],
  "payout": [
    "Praemium"
  ],
  "how do you know you are under attack": [
    "Quomodo scis te oppugnari"
  ],
  "how does the ring work": [
    "Quomodo fungitur hic anulus"
  ],
  "i suppose you want me to kill them": [
    "Visne me eos necare"
  ],
  "fires a spectral arrow at the target doing <mag> points of damage and staggering the target": [
    "Fires Spectral Arrow at target doing <mag> vulneris et staggering target",
    "Fires a Spectral Arrow at the target doing <mag> points of damage and staggering the target"
  ],
  "potion of extreme well-being": [
    "Potio Bene Essendi Extremi"
  ],
  "join the assault on thirsk mead hall": [
    "Adiunge oppugnam in Aulam Hydomelli Thirsk"
  ],
  "sorry, i don't have any": [
    "Ignosce, nullam habeo"
  ],
  "what did you do to her": [
    "Quid ad eam fecisti"
  ],
  "here you go": [
    "Hic id habes"
  ],
  "(<global=dlc2costtosail> gold)": [
    "(<Global=DLC2CostToSail> Auri)"
  ],
  "moth juice": [
    "Moth Juice"
  ],
  "i'm not sure the dragons are about you": [
    "Fortasse dracones nihil cum te aguntur"
  ],
  "ash hopper": [
    "Saltator Cineris"
  ],
  "horker youngling": [
    "Horker Iuvenis"
  ],
  "bloodskal enchantment": [
    "Bloodskal Enchantment"
  ],
  "lost knowledge": [
    "Scientia Amissa"
  ],
  "lurker slime": [
    "Lurker Slime"
  ],
  "neloth's health boost": [
    "Neloth's Health Boost"
  ],
  "ash spawn skirmisher": [
    "Filius Cineris Veles"
  ],
  "i have the black book": [
    "Librum atrum habeo"
  ],
  "can i help with your research": [
    "Possumne tibi auxilium in experimentis dare"
  ],
  "ash spawn sample": [
    "Exemplar Filii Cineris"
  ],
  "reach the temple of miraak": [
    "Inveni Templum Miraakis"
  ],
  "bristleback cave": [
    "Spelunca Apris"
  ],
  "search for information about miraak": [
    "Quaere informatum de Miraak"
  ],
  "bloodskal strike": [
    "Bloodskal Strike"
  ],
  "dragonborn": [
    "Draconigena"
  ],
  "tel mithryn apothecary key": [
    "Tel Mithryn Apothecary Clavis"
  ],
  "ash spawn abilities": [
    "Ash Spawn Abilities"
  ],
  "riekling hunter": [
    "Riekling Venator"
  ],
  "ash spawn": [
    "Filius Cineris"
  ],
  "your spell made me weaker once i got wet": [
    "Magica me debiliorem feci postquam tactus sum aqua"
  ],
  "fishmangoo": [
    "FishmanGoo"
  ],
  "ash ghoul ambush cell location": [
    "Ash Ghoul Ambush Cell Location"
  ],
  "i'm looking for someone named miraak": [
    "Aliquem nomine Miraak quaero"
  ],
  "lurker guardian": [
    "Axspectator Custos"
  ],
  "bloodskal blade": [
    "Bloodskal Blade"
  ],
  "what were those things": [
    "Quae erant ista"
  ],
  "travel to solstheim": [
    "Petere Solstheim",
    "Iter fac ad Solstheim"
  ],
  "experimental subject": [
    "Subiectum Experimenti"
  ],
  "betty netch": [
    "Betty Netch"
  ],
  "bloodskal firing weapon": [
    "Bloodskal Firing Weapon"
  ],
  "lurker sentinel": [
    "Expectator Excubitor"
  ],
  "retrieve the black book": [
    "Refer Librum Atrum"
  ],
  "what kind of experiment do you want to do on me": [
    "Quale experimentum in me facere vis"
  ],
  "telvanni research": [
    "Inquisitio Telvanni"
  ],
  "have you found any other black books": [
    "Invenistine alios Libros Atros"
  ],
  "you said you can show me my true power": [
    "Dixiste te mihi potestatem veram meam demonstare posse"
  ],
  "relax, everyone is safe": [
    "Mollis esto, omnes tuti sunt"
  ],
  "scrib": [
    "Scrib"
  ],
  "azra's staffs": [
    "Rudes Azrae"
  ],
  "how do we get the book out of its case": [
    "Quomodo librum liberamus"
  ],
  "tel mithryn kitchen key": [
    "Tel Mithryn Culina Clavis"
  ],
  "burnt spriggan": [
    "Spriggan Usta"
  ],
  "lurker vindicator": [
    "Exspetator Vindicator"
  ],
  "ash spawn immolator": [
    "Filius Cineris Immolator"
  ],
  "health is permanently lowered <mag> points after swimming or being in the rain": [
    "Salus semper diminuitur <mag> post nates vel sub pluvium sis"
  ],
  "who was azra nightwielder": [
    "Quis erat Azra Nocticaptor"
  ],
  "that sounds like a bad idea": [
    "Consilium malum mihi videtur"
  ],
  "storn sent me to find you": [
    "Storn me misit ut te peterem"
  ],
  "i'd be glad to help": [
    "Adiuvare volo",
    "Gaudebo adiuvare",
    "Gaudeo auxilium dare"
  ],
  "heart stone deposit": [
    "Faex Lapidis Cordis"
  ],
  "nordic carved helmet": [
    "Germanica Galea Cavata"
  ],
  "bonemold gauntlets": [
    "Ossea Manubria"
  ],
  "dragon aspect": [
    "Aspectum Draconis"
  ],
  "neloth's <mag> point health boost": [
    "Neloth's <mag> point health boost"
  ],
  "nordic carved armor": [
    "Germanica Lorica Cavata"
  ],
  "remove tree": [
    "Remove Tree"
  ],
  "i don't need your help": [
    "Auxilio tuo non careo",
    "Auxilium tuum non requiro"
  ],
  "i'll learn miraak's secrets on my own": [
    "Secreta Miraakis solus discam"
  ],
  "cloaking flame spider": [
    "Aranea Flammarum Amiculata"
  ],
  "poison rune": [
    "Runus Veneni"
  ],
  "who's missing": [
    "Quis abest"
  ],
  "stalhrim warhammer": [
    "Stalhrimis Bellimalleus"
  ],
  "what do you want me to do": [
    "Quid vis me facere"
  ],
  "he wants the \"secrets of the skaal\" in exchange for teaching me the third word": [
    "\"Secreta Skaal\" vult ut mihi Verbum tertium doceat"
  ],
  "i talked to hermaeus mora": [
    "Hermaean Moram adlocutus sum"
  ],
  "did you come here from morrowind": [
    "Hucne venisti a Morrovento"
  ],
  "stalhrim greatsword": [
    "Stalhrimis Spatha"
  ],
  "targets that fail to resist take <mag> points of poison damage per second for <dur> seconds": [
    "Targets that fail to resist take <mag> points of poison damage per second <dur> secundas"
  ],
  "summons an ash spawn for <dur> seconds wherever the caster is pointing": [
    "Filium Cineris <dur> secundas vocat"
  ],
  "poison spider": [
    "Aranea Veneni"
  ],
  "azra's <basename>": [
    "Azrae <BaseName>"
  ],
  "yes, i'll lend a hand": [
    "Sic, auxilium dabo"
  ],
  "cultist robes": [
    "Draconicultor Peplum"
  ],
  "nordic carved boots": [
    "Germanicae Caligae Cavatae"
  ],
  "hmdaedra": [
    "HMDaedra"
  ],
  "reaver highwayman": [
    "Praeditor Viarum"
  ],
  "he must have been someone important": [
    "Certe aliquis magni momenti fuit"
  ],
  "i'm not really sure": [
    "Nescio"
  ],
  "i saw miraak on a dragon": [
    "Ad alium locum transportatus sum... et Miraak vidi"
  ],
  "rage of the ancients": [
    "Saevitudo Antiquorum"
  ],
  "companion's insight": [
    "Scientia Sociorum"
  ],
  "the swords true power can only be unlocked by the thuum of the dohvakiin": [
    "The swords true power can only be unlocked by the Thuum of the Dohvakiin"
  ],
  "swords true power can only be unlocked by the thuum of the dohvakiin": [
    "The swords true power can only be unlocked by the Thuum of the Dohvakiin"
  ],
  "rage of ancients active: <global=dlc2dbdragonweapons>": [
    "Rage Of Ancients Active: <Global=dlc2DBDragonWeapons>"
  ],
  "jumping shock spider": [
    "Aranea Saltans Electricitatis"
  ],
  "receive an additional <mag> health and stamina while bow of the stag prince is equipped": [
    "Accipe <mag> plus salutis staminaeque dum Arcus Principis Cervi manu teneas"
  ],
  "unrelenting force strong": [
    "Unrelenting Force Strong"
  ],
  "skaal village greathall": [
    "Skaal Village Greathall"
  ],
  "ancarion": [
    "Ancarion"
  ],
  "recipe - poison cloaked spider": [
    "Dictamen - Aranea Veneno Amicta"
  ],
  "ahzidal's ring of necromancy": [
    "Ahzidalis Anulus Necromanticus"
  ],
  "blood ritual": [
    "Blood Ritual"
  ],
  "this miraak tried to have me killed": [
    "Hic Miraak consilium cepit ut me necaretur"
  ],
  "i examined the briarheart warrior": [
    "Bellatorem Cordumum investigavi"
  ],
  "recipe - jumping flame spider": [
    "Dictamen - Aranea Ignis Saltans"
  ],
  "skaal villager's outfit": [
    "Skaal Villager Outfit"
  ],
  "for <dur> seconds, opponents in melee range take <mag> points fire damage per second": [
    "<dur> secundas, inimici prope te <mag> puncta ignis singulis secundis accipiunt",
    "<dur> secundas, inimici prope te accipiunt <mag> vulneris ignis singulis secundis"
  ],
  "chitin heavy helmet": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis"
  ],
  "bound dagger": [
    "Pugio Vinctus"
  ],
  "secret of arcana": [
    "Secretum Arcanae"
  ],
  "increase stat": [
    "Auge Peritum"
  ],
  "stalhrim helmet": [
    "Stalhrimis Galea"
  ],
  "chitin helmet": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea"
  ],
  "where can i find a briarheart warrior": [
    "Ubi Cordumum invenire possum"
  ],
  "skaal village wulf wildblood's house": [
    "Skaal Village Wulf Wildblood's House"
  ],
  "why do you think the attacks are from the attius farm": [
    "Quamobrem putas impetus e Fundo Attio venire"
  ],
  "standing stone - tree": [
    "Lapis Stans - Arbor"
  ],
  "declaration of war": [
    "Declaratio Belli"
  ],
  "meet bujold at hrothmund's barrow": [
    "Conveni cum Bujold ad Pabillum Hrothmundis"
  ],
  "poisonous cloaked spider": [
    "Aranea Veneno Amiculata"
  ],
  "find the main burial chamber": [
    "Inveni Cameram Sepelendi"
  ],
  "stalhrim war axe": [
    "Stalhrimis Bellisecuris"
  ],
  "bonemold boots": [
    "Osseae Caligae"
  ],
  "ahzidal's boots of waterwalking": [
    "Caligae Ahzidalis Super Aquae Ambulandi"
  ],
  "ashland": [
    "Ashland"
  ],
  "oil spider": [
    "Aranea Olei"
  ],
  "+<mag>% resistance to fire and frost for <dur> seconds": [
    "+<mag>% Resistentiae Igni et Glaciei <dur> secundas"
  ],
  "what do briarhearts have to do with heart stones": [
    "Quid est nexus inter Cordumos et Corsaxa"
  ],
  "dunmer outfit": [
    "Capuciumed Dunmer Outfit, Brown"
  ],
  "cancel": [
    "Nega"
  ],
  "what are the \"secrets of the skaal\"": [
    "Quae sunt \"secreta Skaal\""
  ],
  "scholar's insight": [
    "Scientia Scholaris"
  ],
  "are you hurt badly": [
    "Esne valde vulneratus"
  ],
  "do you need healing": [
    "Remediumne quaeris"
  ],
  "fire/frost resist": [
    "Fire/Frost Resist"
  ],
  "spawn ash": [
    "Filius Cinis"
  ],
  "a new source of stalhrim": [
    "Nova Fons Stalhrimis"
  ],
  "new source of stalhrim": [
    "Nova Fons Stalhrimis"
  ],
  "blessing of the stag": [
    "Benedictio Cervi"
  ],
  "how is drovas working out": [
    "Quid fit cum Drovas"
  ],
  "lover's insight": [
    "Scientia Amatoris"
  ],
  "i can't let you lead thirsk": [
    "Te Thirsk ducere sinere non possum"
  ],
  "telvanni robes": [
    "Telvanni Peplum"
  ],
  "the book was right where you said it would be": [
    "Liver erat ubi dixisti"
  ],
  "book was right where you said it would be": [
    "Liver erat ubi dixisti"
  ],
  "stalhrim battleaxe": [
    "Stalhrimis Bellisecuris"
  ],
  "opponents in melee range take <mag> points poison damage per second": [
    "Inimici prope te <mag> puncta vulneris venenis singulis secundis accipiunt"
  ],
  "reaver thug": [
    "Praeditor Opera"
  ],
  "merilar rendas": [
    "Merilar Rendas"
  ],
  "spells cost no magicka for <dur> seconds": [
    "Incantamenta nihil Magicae constant <dur> secundas"
  ],
  "what's next": [
    "Quid proximum"
  ],
  "skaal village deor's house": [
    "Skaal Village Deor's House"
  ],
  "nordic carved gauntlets": [
    "Germanica Manubria Cavata"
  ],
  "all spells cost <75>% less to cast for <dur> seconds": [
    "Omnia Incantamenta Magicae <75>% minus constant <dur> secundas"
  ],
  "single use, then must be reacquired at the tree stone": [
    "Uti semel deinde iterum ab Lapide Arboris accipe"
  ],
  "heart stones": [
    "Corsaxa"
  ],
  "beast form": [
    "Forma Bestiae"
  ],
  "what do you want of me": [
    "Quid mecum vis"
  ],
  "mul": [
    "Mul"
  ],
  "d3v": [
    "D3v"
  ],
  "reaver outlaw": [
    "Praeditor Utlagatus"
  ],
  "stalhrim source map": [
    "Charta Geographica Fontis Stalhrimis"
  ],
  "netch jelly": [
    "Cilon Netch"
  ],
  "i could lend you a hand... for a price": [
    "Auxilium tibi dare possum... pro pretio digno"
  ],
  "i discovered this note on one of the ash spawn": [
    "Hanc epistulam in filio cineris inveni"
  ],
  "secret of strength": [
    "Secretum Fortitudinis"
  ],
  "stalhrim sword": [
    "Stalhrimis Gladius"
  ],
  "ask deor about baldor's disappearance": [
    "Roga Deor de Absentia Baldoris"
  ],
  "buy perk": [
    "Buy Perk"
  ],
  "summons a werebear for <dur> seconds wherever the caster is pointing": [
    "Vocat Werebear <dur>"
  ],
  "single use, then must be reacquired at the beast stone": [
    "Utere semel deinde iterum accipe ad Saxum Bestiae"
  ],
  "spells, no magicka": [
    "Spells, no magicka"
  ],
  "why don't you go on ahead": [
    "Quidni tu pergas"
  ],
  "netch poison": [
    "Venenum Netch"
  ],
  "shout1b": [
    "Shout1b"
  ],
  "poison cloaked spider": [
    "Aranea Veneno Amiculata"
  ],
  "power attacks do <25>% more damage, +<mag> damage resistance for <dur> seconds": [
    "Ictus Potestatis <25>% plus vulneris faciunt, +<mag> Resistentiae Vulneri <dur> secundas"
  ],
  "werebear form": [
    "Werebear Form"
  ],
  "nordic dagger": [
    "Germanicus Pugio"
  ],
  "i'd be glad to lend you a hand": [
    "Gaudeo auxilium dare"
  ],
  "seeker aspirant": [
    "Quaestor Adspirans"
  ],
  "chitin heavy armor": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis"
  ],
  "causes <mag> points of poison damage for <dur> seconds": [
    "<mag> vulnus veneni <dur> secundas",
    "Facit <mag> puncta vulneris veneni <dur> secundas"
  ],
  "mirrak's robes": [
    "Miraakis Peplum"
  ],
  "reaver": [
    "Praeditor"
  ],
  "examine a briarheart warrior": [
    "Investiga Cordumum"
  ],
  "what are you going to do now": [
    "Quid nunc facies"
  ],
  "the temple of miraak": [
    "Templum Miraakis"
  ],
  "spell tome: bound dagger": [
    "Liber Magicae: Pugio Vinctus"
  ],
  "buy health": [
    "Eme Salutem"
  ],
  "tell me what you want, my lord, and i will bring it to you": [
    "Dic mihi quid velis domine, et id tibi feram"
  ],
  "buy stamina": [
    "Eme Staminam"
  ],
  "recipe - jumping poison spider": [
    "Dictamen - Aranea Veneni Saltans"
  ],
  "you take half damage for <dur> seconds from all physical attacks and most magical attacks": [
    "Dimidium vulneris <dur> secundas accipie"
  ],
  "that was a little more than i expected": [
    "Facilius erat quam putavi"
  ],
  "buy magicka": [
    "Eme Magicam"
  ],
  "chitin armor": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica"
  ],
  "conjure ash spawn": [
    "Voca Filium Cineris"
  ],
  "buy perk point": [
    "Eme Punctum Peritum"
  ],
  "nordic mace": [
    "Germanica Maca"
  ],
  "no heart stones, ash guardian is uncontrolled": [
    "Nullae lapides cordis, custos cineris liber est"
  ],
  "attius farm location": [
    "Attius Farm Location"
  ],
  "extraction perk": [
    "Extraction Perk"
  ],
  "tosses a poison cloaked spider on the ground that will poison enemies that get too close": [
    "Araneam Veneni Amiculatam in solum iacit quae se ad inimico iacit"
  ],
  "help neloth with his research": [
    "Adiuva Neloth cum experimento"
  ],
  "i'm not interested": [
    "Mihi non interest",
    "Nihil curo",
    "Mihi non iterest"
  ],
  "bujold the unworthy": [
    "Bujold Indignus"
  ],
  "recipe - flame cloaked spider 2x": [
    "Dictamen - Aranea Igni Amicta 2x"
  ],
  "tel mithryn tower": [
    "Tel Mithryn Tower"
  ],
  "you were saying something about heart stones": [
    "Aliquid de Corsaxis dixisti"
  ],
  "search for baldor iron-shaper": [
    "Quaere Baldorem Ferri-Factorem"
  ],
  "caster ignores <80>% of all physical damage for <dur> seconds": [
    "Ignora <80>% omnis vulneris physicalis <dur> secundas",
    "Caster <80>% vulneris physicalis ignorat <dur> secundas"
  ],
  "single use, then must be reacquired at the earth stone": [
    "Semel usurpatur deinde id iterum ad Saxum Terrae accipere debebis"
  ],
  "how often do they attack": [
    "Quoties oppugnant"
  ],
  "tel mithryn steward": [
    "Tel Mithryn Steward"
  ],
  "skaal village baldor iron-shaper's house": [
    "Skaal Village Baldor Iron-shaper's House"
  ],
  "recipe books": [
    "Libros Dictaminis"
  ],
  "skaal village morwen's house": [
    "Skaal Village Morwen's House"
  ],
  "ahzidal's helm of vision": [
    "Ahzidalis Galea Videndi"
  ],
  "blessing of the stag prince": [
    "Benedictio Principis Cervorum"
  ],
  "skaal village edla's house": [
    "Skaal Village Edla's House"
  ],
  "poi 5": [
    "POI 5"
  ],
  "where were they headed": [
    "Quo iverunt"
  ],
  "ahzidal's gauntlets of warding": [
    "Ahzidalis Manubria Tegendi"
  ],
  "briarheart harvest": [
    "Carpe Cordumum"
  ],
  "extract ash": [
    "Extrahe Cinerem"
  ],
  "reaver marauder": [
    "Praeditor Depopulator"
  ],
  "damage resistance": [
    "Resistentia Vulneri"
  ],
  "dragon aspect - head": [
    "Dragon Aspect - Head"
  ],
  "standing stone - earth": [
    "Lapis Sans - Terra"
  ],
  "that was easy": [
    "Facile erat"
  ],
  "q4": [
    "Q4"
  ],
  "power attacks, no stamina": [
    "Power attacks, no stamina"
  ],
  "no damage": [
    "No damage"
  ],
  "ash hopper jelly": [
    "Cilon Saltatoris Cineris"
  ],
  "perk activator": [
    "Activator Peritiae"
  ],
  "chitin heavy gauntlets": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia"
  ],
  "stalhrim light helmet": [
    "Stalhrimis Galea Levis"
  ],
  "talk to frea": [
    "Adloquere Fream"
  ],
  "approach miraak": [
    "Appropinqua Miraaki"
  ],
  "how were rieklings able to overpower you": [
    "Quomodo Rieklings vos superare potuerunt"
  ],
  "blackout": [
    "Silentium"
  ],
  "explore the temple": [
    "Explora Templum"
  ],
  "spider visuals poison": [
    "Spider Visuals Poison"
  ],
  "i think i'll pass for now": [
    "Fortasse postea"
  ],
  "find frea": [
    "Inveni Fream"
  ],
  "omen of warding": [
    "Omen Tegendi"
  ],
  "search the attius farm for clues": [
    "Quaere Fundum Atii pro evidentia"
  ],
  "what are these \"ash spawn?\"": [
    "Qui sunt hi \"filii cineris?\""
  ],
  "defeat bujold": [
    "Vince Bujold"
  ],
  "commune with hrothmund's spirit": [
    "Communica cum Animo Hrothmundis"
  ],
  "speak to bujold": [
    "Adloquere Bujold"
  ],
  "neloth's memory spell": [
    "Magica Memoriae Nelothis"
  ],
  "neloth's health spell": [
    "Magica Salutis Nelothis"
  ],
  "recipe - mind control spider": [
    "Dictamen - Aranea Menti Imperandi"
  ],
  "get the staff": [
    "Accipe Rudem"
  ],
  "hrothmund": [
    "Hrothmund"
  ],
  "retrieve the stalhrim source map": [
    "Refer Chartam Fontis Stalhrimis"
  ],
  "raven rock quest monitor": [
    "Raven Rock Quest Monitor"
  ],
  "what do you want of me, my lord": [
    "Quid mecum vis, domine"
  ],
  "nordic greatsword": [
    "Germanica Spatha"
  ],
  "recipe - exploding flame spider 2x": [
    "Dictamen - Aranea Ignis Displodens 2x"
  ],
  "briarheart necropsy": [
    "Necropsia Cordumi"
  ],
  "what do you want of me this time": [
    "Quid mecum nunc vis"
  ],
  "get a heart stone": [
    "Accipe Corsaxum"
  ],
  "talk to baldor iron-shaper": [
    "Adloquere Baldorem Ferri-Factor"
  ],
  "so what's your price for the final word of power": [
    "Quid est pretium tuum pro ultimo Verbo Potestatis"
  ],
  "+<mag> damage resistance for <dur> seconds": [
    "+<mag> Resistentiae Vulneri <dur> secundas"
  ],
  "yes, and i've brought you the map to the stalhrim source": [
    "Sic, et tibi chartam geographicam ad fontem Stalhrimis tuli"
  ],
  "get the wind and sand book": [
    "Inveni Librum Venti et Arenae"
  ],
  "ghost giant": [
    "Ghost Giant"
  ],
  "wind and sand": [
    "Ventus et Arena"
  ],
  "krag's journal": [
    "Ephemeris Kragis",
    "Ephemerides Kragis"
  ],
  "restore the steam supply to the dwemer reading room": [
    "Redde vaporem ad Bibliothecam Dwemerem"
  ],
  "how are you feeling about that": [
    "Quomodo de eo sentis"
  ],
  "stalhrim": [
    "Stalhrim"
  ],
  "what's that": [
    "Quid est",
    "Ubi est",
    "Quid est illa",
    "Quid est illud",
    "Quid est istud",
    "Quid sibi vult"
  ],
  "what brings you out here": [
    "Quid te huc fert"
  ],
  "remove water": [
    "Remove Water"
  ],
  "old salty": [
    "Sal Vetus"
  ],
  "where can i find this ancarion": [
    "Ubi hunc Ancarion invenire possum"
  ],
  "nordic war axe": [
    "Germanicus Bellisecuris"
  ],
  "temple priest robes": [
    "Temple Reclamations Priest Peplum"
  ],
  "reaver plunderer": [
    "Praeditor Despoliator"
  ],
  "what do you need": [
    "Quid vis"
  ],
  "poison spider abilities": [
    "Poison Spider Abilities"
  ],
  "yes, i came here to learn miraak's secrets": [
    "Sic, huc veni ut secreta Miraakis discerem"
  ],
  "stalhrim light armor": [
    "Stalhrimis Lorica Levis"
  ],
  "somehow, reading this book took me to where he was": [
    "Fortasse. Postquam librum legi, locum eius scivi"
  ],
  "bandit": [
    "Latro",
    "Bandit"
  ],
  "riekling": [
    "Riekling"
  ],
  "skill book points": [
    "Skill book points"
  ],
  "secret of protection": [
    "Secretum Praesidii"
  ],
  "dragon aspect - arms": [
    "Aspectus Draconis - Arma"
  ],
  "amethyst claw, left half": [
    "Ungues Amethysteus, Pars Laeva"
  ],
  "fahlbtharz grand hall": [
    "Fahlbtharz Aula Magna"
  ],
  "i have a heart stone": [
    "Corsaxum habeo"
  ],
  "are you still having issues with the ash spawn": [
    "Filii Cineris iam te molestant"
  ],
  "stalhrim mace": [
    "Stalhrimis Maca"
  ],
  "dragon sword effect": [
    "Dragon Sword Effect"
  ],
  "i found myself in another place": [
    "In alio loco eram"
  ],
  "i don't know yet what he wanted": [
    "Nescio quid voluerit"
  ],
  "i left apocrypha before i found out": [
    "E Apocrypha effugi ante didici"
  ],
  "watch for side effects": [
    "Cave effecta ignota"
  ],
  "what is this building": [
    "Quid est hoc aedificium"
  ],
  "recipe - jumping poison spider 2x": [
    "Dictamen - Aranea Veneni Saltans 2x"
  ],
  "it's done": [
    "Factumst",
    "Factum est"
  ],
  "you suspect there's a motivation to their attacks": [
    "Credisne eos motivum habere"
  ],
  "waters of life": [
    "Aquae Vivae"
  ],
  "i will do as you command, my lord": [
    "Ut imperas faciam, domine"
  ],
  "i learned the second word of the bend will shout": [
    "Verbum secundum clamationis 'Impera Animo' didici"
  ],
  "find the source of miraak's power": [
    "Inveni fontem potestatis Miraakis"
  ],
  "root of power": [
    "Radix Potestatis"
  ],
  "i'd be honored": [
    "Honor mihi erit"
  ],
  "do 10% more damage and get 10% better prices from people of the opposite sex": [
    "Fac 10% plus vulneris ad et accipe pretia 10% meliora ab alio sexu"
  ],
  "giant fishman weapon": [
    "Giant Fishman Weapon"
  ],
  "remove wind": [
    "Remove Wind"
  ],
  "chitin heavy boots": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Graves"
  ],
  "stalhrim light bracers": [
    "Stalhrimis Manubria Levia"
  ],
  "standing stones - tree": [
    "Lapis Stans - Arbor"
  ],
  "recipe - exploding poison spider 2x": [
    "Dictamen - Aranea Veneni Displodens 2x"
  ],
  "your what": [
    "Quid tuum"
  ],
  "conjure werebear": [
    "Voca Werebear"
  ],
  "wall of flames": [
    "Wall Flammarum",
    "Wall Of Flames",
    "Paries of Flammarum"
  ],
  "sun flare": [
    "Sol Exardescens"
  ],
  "did anything unusual happen around the time of his disappearance": [
    "Aliquidne mirum accidit eodem tempore quam evanuit"
  ],
  "what if you had one extra warrior": [
    "Fortasse bellator alius vobis adivet"
  ],
  "you'll go against hrothmund's wishes": [
    "Contra voluntatem Hrothmundis eas"
  ],
  "is there a problem": [
    "Estne difficultas"
  ],
  "food bonus": [
    "Food bonus"
  ],
  "poison damage": [
    "Vulnus Veneni"
  ],
  "i suppose so": [
    "Id faciam"
  ],
  "recipe - poison cloaked spider 2x": [
    "Dictamen - Aranea Veneno Amicta 2x"
  ],
  "that sounds a little too risky": [
    "Periculosum mihi videtur"
  ],
  "i think you owe me for that spell": [
    "Credo te pro illa magica mihi iam debere"
  ],
  "skaal village oslaf's house": [
    "Skaal Village Oslaf's House"
  ],
  "nordic battleaxe": [
    "Germanicus Bellisecuris"
  ],
  "zahkrii do dovahkiin": [
    "Zahkrii Do Dovahkiin"
  ],
  "recipe - jumping flame spider 2x": [
    "Dictamen - Aranea Ignis Saltans 2x"
  ],
  "your attacks, shouts, and destruction spells do no damage to your followers when in combat": [
    "Oppugna, Clamationes, et Magica Destructionis nullum vulnus ad socios facit"
  ],
  "i have the ash sample": [
    "Exemplar cineris habeo"
  ],
  "wielder receives an increasingly powerful blessing for every 20 animals killed by the bow": [
    "Accipies benecitionem potentiorem post XX animalia necaveris"
  ],
  "<global=dlc2merchanimalskilled> animals killed": [
    "<Global=dlc2MerchAnimalsKilled> animalia necata"
  ],
  "shout2": [
    "Shout2"
  ],
  "chitin boots": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae"
  ],
  "stag prince increment effect": [
    "Stag Prince Increment Effect"
  ],
  "what if the skaal refuse to give up their secrets": [
    "Quid accidet si Skaal secreta eorum dare recusaverint"
  ],
  "hermaeus mora": [
    "Hermes Trismegistus",
    "Hermes Trismegisthus"
  ],
  "creates a magic dagger for <dur> seconds. sheathe it to dispel": [
    "Pugionem magicum <dur> secundas facit"
  ],
  "no falling damage": [
    "Nullum Vulus Cadendi"
  ],
  "i hope you know what you're doing": [
    "Spero te scire quid facias"
  ],
  "bones of the earth": [
    "Ossa Terrae"
  ],
  "mystic dagger": [
    "Mystic Dagger"
  ],
  "i've got azra's staff": [
    "Rudem Azrae habeo"
  ],
  "stalhrim gauntlets": [
    "Stalhrimis Manubria"
  ],
  "remove fire": [
    "Remove Fire"
  ],
  "time between shouts is reduced <mag>%": [
    "Tempus inter clamationes 20% reducitur"
  ],
  "bonemold armor": [
    "Ossea Lorica"
  ],
  "recipe - flame cloaked spider": [
    "Dictamen - Aranea Igni Amicta"
  ],
  "skaal village shaman's hut": [
    "Skaal Village Shaman's Hut"
  ],
  "targets that fail to resist are coated in solidified as for <dur> seconds": [
    "Targets that fail to resist are coated in solidified as <dur> secundas"
  ],
  "telvanni": [
    "Telvanni"
  ],
  "what are the telvanni": [
    "Qui sunt Telvanni"
  ],
  "boar tusk": [
    "Dens Apri"
  ],
  "target moves in complete silence for <dur> seconds, with a side effect of shrinking the target": [
    "Move omnnino tacite <dur> secundas, et diminua"
  ],
  "release the book from the dwemer contraption": [
    "Libera librum e mechanismo Dwemeri"
  ],
  "poison cloak": [
    "Poison Cloak",
    "Amiculum Veneni"
  ],
  "stalhrim light boots": [
    "Stalhrimis Caligulae"
  ],
  "remove earth": [
    "Remove Earth"
  ],
  "harvest briarheart": [
    "Carpe Cordumum"
  ],
  "chitin bracers": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria"
  ],
  "i won't serve you, monster": [
    "Te non serviam, monstruum"
  ],
  "i just want to defeat miraak": [
    "Modo Miraakem vincere volo"
  ],
  "ralis knockback effect": [
    "Ralis Knockback Effect"
  ],
  "stalhrim armor": [
    "Stalhrimis Lorica"
  ],
  "ahzidal's armor of retribution": [
    "Ahzidalis Lorica Retributionis"
  ],
  "what about them": [
    "Quid de eis"
  ],
  "amethyst claw, right half": [
    "Ungues Amethysteus, Pars Recta"
  ],
  "altris' blood ritual": [
    "Altris' Blood Ritual"
  ],
  "save them from what": [
    "Servare eos a quo"
  ],
  "recipe - exploding flame spider": [
    "Dictamen - Aranea Ignis Displodens"
  ],
  "ahzidal's ring of arcana": [
    "Ahzidalis Anulus Arcanus"
  ],
  "wyrm": [
    "Vermis"
  ],
  "collect ash from an ash spawn": [
    "Collige cinerem e filio cineris"
  ],
  "i will talk to the skaal and bring you their secrets": [
    "Skaal adloquar et tibi secretorum eorum feram"
  ],
  "netch leather": [
    "Pellis Netch"
  ],
  "nordic warhammer": [
    "Germanicus Bellimalleus"
  ],
  "mora's boon": [
    "Donum Morae"
  ],
  "reading skill books gives you an extra skill point": [
    "Libros Peritii legens tibi Secundum Punctum Peritii dabit"
  ],
  "i'll keep this quiet": [
    "De hoc nemini loquar"
  ],
  "majni": [
    "Majni"
  ],
  "what is a heart stone": [
    "Quid est Corsaxa"
  ],
  "shout timer": [
    "Tempus Clamationum"
  ],
  "fahlbtharz boilery": [
    "Fahlbtharz Fervatorium"
  ],
  "recipe - exploding poison spider": [
    "Dictamen - Aranea Veneni Displodens"
  ],
  "how did that bring you to this old farm": [
    "Quomodo illud te ad hunc fundum traxisti"
  ],
  "travel to nchardak with neloth": [
    "Ii ad Nchardak cum Neloth"
  ],
  "burnt spriggan wood": [
    "Lignum Combustum Sprigganis"
  ],
  "servos rendas": [
    "Servos Rendas"
  ],
  "jumping frost spider": [
    "Aranea Saltans Glaciei"
  ],
  "where are you going": [
    "Quo is"
  ],
  "summoned ash guardian faction": [
    "Summoned Ash Guardian Faction"
  ],
  "stalhrim boots": [
    "Stalhrimis Caligae"
  ],
  "nordic sword": [
    "Germanicus Gladius"
  ],
  "ash extractor": [
    "Extractor Cineris"
  ],
  "seems like a waste of time to me": [
    "Usum malum temporis mihi videtur"
  ],
  "bonemold helmet": [
    "Ossea Galea"
  ],
  "take on the form of the bear": [
    "In formam ursae muta"
  ],
  "glass bow of the stag prince": [
    "Arcus Vitreus Principis Cervorum"
  ],
  "don't worry, i was just leaving": [
    "Noli sollicitare, modo exiebam"
  ],
  "what did you learn from the briarheart": [
    "Quid de Cordumo didicisti"
  ],
  "ash creep cluster": [
    "Grex Rependi Cinis"
  ],
  "you take no falling damage for <dur> seconds": [
    "Nullum vulneris cadendi accipies <dur> secundas"
  ],
  "stalhrim dagger": [
    "Stalhrimis Pugio"
  ],
  "power attacks cost no stamina for <dur> seconds": [
    "Ictus Poteastatis nihil constant <dur> secundas"
  ],
  "ulen ancestral tomb": [
    "Tumulum Maiorum Ulenis"
  ],
  "black book: filament and filigree": [
    "Liber Ater: Fila et Ornamenta"
  ],
  "bilgemuck is an animal of some kind": [
    "Bilgemuck est animal aliquid"
  ],
  "and you want it back": [
    "Et eum recipere vis"
  ],
  "how did vilur get caught": [
    "Quomodo Vilur capitur"
  ],
  "recover evidence from severin manor": [
    "Inveni evidentiam in Villa Severini"
  ],
  "why haven't you acted on the information": [
    "Quidni aliquid cum hoc informato fecisti"
  ],
  "adril arano said you might be able to help me": [
    "Adril Arano dixit te me auxilium dare posse"
  ],
  "so go search their house": [
    "Ergo i et domum eorum investiga"
  ],
  "discover the identity of the tomb's visitor": [
    "Disce quem tumulum visistat"
  ],
  "akar": [
    "Akar"
  ],
  "i've found a hagraven claw": [
    "Unguem Corvicisagae inveni"
  ],
  "i've have some vampire dust": [
    "Pulvem Vampiri habeo"
  ],
  "mora's agony": [
    "Agonia Morae"
  ],
  "why haven't you told adril about this": [
    "Quidni Adrili de hoc non narravisti"
  ],
  "i have a briarheart": [
    "Cordumum habeo"
  ],
  "i have some void salts": [
    "Sal Chaos habeo"
  ],
  "summon dremora merchant": [
    "Voca Mercatorem Dremoram"
  ],
  "you sound paranoid": [
    "Mihi videtur morbum paranoiae te afficere"
  ],
  "rakel": [
    "Rakel"
  ],
  "do you need any help": [
    "Visne auxilium",
    "Auxiliumne quaeris"
  ],
  "of course, master neloth": [
    "Certe, Domine Neloth"
  ],
  "as you command": [
    "Ut imperas"
  ],
  "seeker of shadows": [
    "Quaestor Umbrarum"
  ],
  "writhing effect": [
    "Writhing Effect"
  ],
  "are you saying you want my help": [
    "Rogasne auxilium meum"
  ],
  "fire wyrm": [
    "Vermis Ignis"
  ],
  "cast on a nearby surface, it explodes for and freezes the target in place for <dur> seconds": [
    "Fac in solum, displodit et inimicos in glaciem ponit <dur> secundas"
  ],
  "i have some things to sell": [
    "Res vendendas habeo"
  ],
  "ice wraiths fighting bandits": [
    "Ice Wraiths Fighting Bandits"
  ],
  "you feel an attack on the councilor is imminent": [
    "Censes impetum contra conciliatorem statim venturum esse"
  ],
  "bring a daedra heart to elynea": [
    "Fer Cor Daedrae ad Elyneam"
  ],
  "black book: the sallow regent": [
    "Liber Ater: Rex Pallidus"
  ],
  "vilur did this alone": [
    "Vilur hoc solum fecit"
  ],
  "are you one of neloth's apprentices": [
    "Esne discipula Nelothis"
  ],
  "i've decided to help you protect councilor morvayn": [
    "Tibi auxilium dare decrevi. Tegamus Conciliator Morvayn"
  ],
  "why don't you just make your own tea": [
    "Quidni tu theam pro te facias"
  ],
  "what happened to house hlaalu as a result": [
    "Quid postea Genti Hlaalui accidit"
  ],
  "black book: the winds of change": [
    "Liber Ater: Venti Mutationis"
  ],
  "creaturedialoguescribx": [
    "CreatureDialogueScribx"
  ],
  "sprayed on the ground, it creates a wall of frost that does <50> points of frost damage per second": [
    "In terra effusus, parietem glaciei vocat quae <50> gelidus vulneris singulis secundis facit",
    "In solum sparsum, facit parietem glaciei quae <50> puncta vulneris glaciei singulis secundis facit"
  ],
  "unlimited stamina": [
    "Unlimited Stamina"
  ],
  "you're lying": [
    "Mentiris"
  ],
  "you're from house hlaalu": [
    "E Gente Hlaalu venis"
  ],
  "can you tell me more about the severin family": [
    "Potesne mihi plus de familia Severini docere"
  ],
  "i'll let you get to it then": [
    "Ergo perge"
  ],
  "what kind of a person should i look for": [
    "Qualem hominem quarere debeo"
  ],
  "thanks for the tip": [
    "Gratias pro informato"
  ],
  "severin family safe": [
    "Arcam Familiae Severini"
  ],
  "hunter and dog in the wilderness": [
    "Hunter et Dog in Wilderness",
    "Hunter and Dog in the Wilderness"
  ],
  "stop rune": [
    "Stop Rune"
  ],
  "i presume that was their downfall": [
    "Credo illud caedem eorum fuisse"
  ],
  "tell me why you're really here": [
    "Dic mihi cur hic re vera sis"
  ],
  "what is going on here": [
    "Quid hic accidit"
  ],
  "drunken master": [
    "Dominus Ebrius"
  ],
  "nothing unusual about them at all": [
    "Nulla mirabilia de eis"
  ],
  "dremora prices": [
    "Dremora Prices"
  ],
  "report back to adril arano": [
    "Refer te ad Adril Arano"
  ],
  "wait inside ulen ancestral tomb": [
    "Expecta in Tumulo Maiorum Ulenis"
  ],
  "served cold": [
    "Frigide Dapinatum"
  ],
  "who else lives here": [
    "Quis praeter te hic vivit"
  ],
  "how can i help": [
    "Quomodo adiuvare possum"
  ],
  "writhing wall": [
    "Writhing Wall"
  ],
  "bring some void salts to elynea": [
    "Fer Sal Chiasmi ad Elyneam"
  ],
  "unrelenting force - fus ro da": [
    "Unrelenting Force - Fus Ro Da"
  ],
  "tree stone": [
    "Lapis Arborum"
  ],
  "i'd prefer not to get involved": [
    "Malo non interponere"
  ],
  "bring some vampire dust to elynea": [
    "Fer Pulvem Vampiri ad Elyneam"
  ],
  "i suspect tilisu severin is the culprit": [
    "Credo culpam Tilisu Severinis esse"
  ],
  "why do dunmer leave ash yams in their tombs": [
    "Quamobrem Dunmeri mala terrae dulcia cineris in tumula eorum ponunt"
  ],
  "hunting and gathering": [
    "Venerans et Carpens"
  ],
  "hunters chasing": [
    "Hunters chasing"
  ],
  "animals attacking bandit": [
    "Animals attacking bandit"
  ],
  "end the threat to councilor morvayn": [
    "Finem fac ad comminationem Conciliatori Morvayn"
  ],
  "i'm sorry, this is all just too strange for me": [
    "Igosce mihi. Hoc nimis mirabile mihi est"
  ],
  "black book: the hidden twilight": [
    "Liber Ater: Crepusculum Latentem"
  ],
  "how do i get them to do that": [
    "Quomodo eis suadeam ut illud faciant"
  ],
  "why did councilor morvayn execute an ulen": [
    "Quamobrem Conciliator Morvayn Uleni supplicium dedit"
  ],
  "he ordered the execution": [
    "Supplicio imperavit"
  ],
  "it's on his head... not yours": [
    "Culpa in caput eiut iacet... non tuum"
  ],
  "he's in danger of being killed? how": [
    "In periculo mortis est? Quomodo"
  ],
  "you can't just order me around": [
    "Mihi imperare non potes"
  ],
  "bring a briarheart to elynea": [
    "Fer Cordumum ad Elynea"
  ],
  "what does it mean to be a member of your house": [
    "Quid sibi vult partem gentis tuae esse"
  ],
  "black market": [
    "Forum Secretum"
  ],
  "severin safe key": [
    "Severini Arca Clavis"
  ],
  "dragonborn force": [
    "Vis Draconigena"
  ],
  "dremora merchant": [
    "Dremora Mercator"
  ],
  "why would the ulens want him dead": [
    "Quamobrem Uleni eum mortuum ese volunt"
  ],
  "your concern seems justified": [
    "Sollicitudo tua iusta mihi videtur"
  ],
  "you mentioned your \"sources?\"": [
    "Commemoravisti \"fontes\" tuas"
  ],
  "speak to geldis sadri": [
    "Aloquere Geldis Sadri"
  ],
  "what will you do": [
    "Quid facies"
  ],
  "well, good luck with that": [
    "Bene. Bonam fortunam tibi"
  ],
  "bardic knowledge": [
    "Scientia Bardica"
  ],
  "writhing wall slow effect": [
    "Writhing Wall Slow Effect"
  ],
  "audiotemplateashhopper": [
    "AudioTemplateAshHopper"
  ],
  "i've got a giant's toe": [
    "Digitum Gigantis habeo"
  ],
  "you aren't an ulen": [
    "Non es Ulen"
  ],
  "fort frostmoth key": [
    "Castellum Tineaglacis Clavis"
  ],
  "mora's grasp": [
    "Amplectum Morae"
  ],
  "spectral dragon": [
    "Draco Spectralis"
  ],
  "the ulen matter": [
    "Res Ulenis"
  ],
  "ulen matter": [
    "Res Ulenis"
  ],
  "summons a dremora merchant for <dur> seconds": [
    "Marcatorem Dremoram <dur> secundas vocat"
  ],
  "dragonborn flame": [
    "Flamma Draconigena"
  ],
  "you have a better idea": [
    "Consilium melius habes"
  ],
  "unlimited magicka": [
    "Unlimited Magicka"
  ],
  "i did what had to be done": [
    "Feci quid faciendum erat",
    "Feci id quod faciendum erat"
  ],
  "dragonborn frost": [
    "Glacies Draconigena"
  ],
  "hjordis": [
    "Hjordis"
  ],
  "how did you learn to talk": [
    "Quomodo loqui didicisti"
  ],
  "secret servant": [
    "Servus Secretus"
  ],
  "dremora holding cell": [
    "Dremora Holding Cell"
  ],
  "i've got a daedra heart": [
    "Cor Daedrae habeo"
  ],
  "summon spectral dragon": [
    "Voca Draconem Spectralem"
  ],
  "why don't you ask them to investigate": [
    "Quidni eis saudas ut investigent"
  ],
  "what can you tell me about house hlaalu": [
    "Quid mihi de Gente Hlaalu narrare potes"
  ],
  "return to adril arano": [
    "Redi ad Adril Arano"
  ],
  "bring a giant's toe to elynea": [
    "Fer Digitum Gigantis ad Elyneam"
  ],
  "bend will 01": [
    "Bend Will 01"
  ],
  "i saw her in the ulen tomb": [
    "Eam in sepulcro Ulen vidi"
  ],
  "what if they try to stop me": [
    "Quid faciam si me sistere conati erunt"
  ],
  "a benefit": [
    "Beneficium"
  ],
  "bring a hagraven's claw to elynea": [
    "Fer Unguem Corvicisagae ad Elyneam"
  ],
  "he's no longer a threat": [
    "Sic, et ille non iam comminatio est"
  ],
  "seeker of sorcery": [
    "Quaestor Magicae"
  ],
  "i found what you wanted": [
    "Inveni id quod voluisti"
  ],
  "speak to adril arano": [
    "Adloquere Adril Arano"
  ],
  "seeker of might": [
    "Quaestor Fortitudinis"
  ],
  "you think he's mistaken": [
    "Censesne eum erravisse"
  ],
  "hey, i'm not your servant": [
    "Heus, servus tuus non sum"
  ],
  "firebolt": [
    "Firebolt",
    "Ingnipila"
  ],
  "(optional) read gratian caerellius's journal": [
    "(Optio) Lege Ephemeridem Gratiani Caerelli"
  ],
  "hmdaedra fx": [
    "HMDaedra FX"
  ],
  "power attacks/damage resistance": [
    "Power Attacks/Resistentiae Vulneri"
  ],
  "you were right all along": [
    "Semper recte dixisti"
  ],
  "tome": [
    "Librum"
  ],
  "glover wants his ancient nordic pickaxe back": [
    "Glover Dolabram Antiquam Germanicam eius recipere vult"
  ],
  "tosses a jumping shock spider on the ground that will launch itself at enemies and explode if it lands near them": [
    "Tosses a Jumping Shock spider on the ground that will launch itself at enemies and explode if it lands near them"
  ],
  "return to crescius caerellius": [
    "Redi ad Crescium Caerellium"
  ],
  "tell me anything you can": [
    "Dic mihi quidquid"
  ],
  "then where's his great-grandfather": [
    "Ergo ubi est proavus"
  ],
  "scrib poison": [
    "Scrib Poison"
  ],
  "why are you telling me all of this": [
    "Quamobrem mihi haec omnia narras"
  ],
  "where are you from originally": [
    "Unde venis"
  ],
  "is magic involved": [
    "Estne magica pars huius rei"
  ],
  "note from mogrul": [
    "Epistula a Mogrul"
  ],
  "resonance gem": [
    "Gemma Resonatiae"
  ],
  "perhaps you should return to morrowind": [
    "Fortass ad Morriventum redire debes"
  ],
  "exploding frost spider": [
    "Aranea Glaciei Displodens"
  ],
  "what can i do to help the skaal": [
    "Quale auxilium ad Skaal dare possum"
  ],
  "lift down": [
    "Descende",
    "Descensum"
  ],
  "a cyclone that does <mag> points of damage per second and may fling victims into the air": [
    "Turbo quod <mag> puncta vulneris singulas secundas et fortiter inimicos in aerem sumat"
  ],
  "cyclone that does <mag> points of damage per second and may fling victims into the air": [
    "Turbo quod <mag> puncta vulneris singulas secundas et fortiter inimicos in aerem sumat"
  ],
  "distintegrate": [
    "Distintegra"
  ],
  "busy doing what": [
    "Occupatus in quo"
  ],
  "it doesn't belong to you": [
    "Tibi non est"
  ],
  "ancient nordic pickaxe": [
    "Dolabra Germanica Antiqua"
  ],
  "spider visuals frost": [
    "Spider Visuals Frost"
  ],
  "i admit, this does sound suspicious": [
    "Fateor hoc suspiciosum mihi videri"
  ],
  "kagrumez resonance gem": [
    "Gemma Resonantiae Kagrumez"
  ],
  "ildari's sarcophagus": [
    "Sarcophagus Ildari"
  ],
  "slow down here": [
    "Lentius, quaeso"
  ],
  "i don't want to hurt you": [
    "Nolo tibi nocere"
  ],
  "ash yam": [
    "Malum Terrae Cineris"
  ],
  "what does it mean if we're both dragonborn": [
    "Quid sibi vult si nos ambos Draconigena sumus"
  ],
  "i'm guessing you don't agree with crescius's story": [
    "Credo te cum fabula Crescii non consentire"
  ],
  "how long have you been studying the skaal": [
    "Quamdiu operam in Skaal dedisti"
  ],
  "i want to be the new chief": [
    "Principem novum esse volo"
  ],
  "raven rock holding cell": [
    "Raven Rock Holding Cell"
  ],
  "now i get it": [
    "Nunc intellego"
  ],
  "battle fury": [
    "Saevitudo Proelii"
  ],
  "ash gout": [
    "Ash Gout"
  ],
  "where is everyone": [
    "Ubi sunt omnes"
  ],
  "talk to the chief": [
    "Principem Aloquere"
  ],
  "why have such elite soldiers on this island": [
    "Quamobrem milites tam exerciti in hac insula sunt"
  ],
  "immolation": [
    "Immolation"
  ],
  "is master neloth a good teacher": [
    "Estne Dominus Neloth magister bonus"
  ],
  "ancient dragonborn are summoned if health falls below <50>%": [
    "Draconigena Antiqui vocabuntur si salus sub <50>% ceciderit"
  ],
  "creaturedialoguefishman": [
    "CreatureDialogueFishman"
  ],
  "but you said redoran leads the council": [
    "Sed dixisti Redoranem Councilium ducere"
  ],
  "calm dragon": [
    "Calm Dragon"
  ],
  "have you always served as a ferryman": [
    "Semper portitor (ut Charon) fuisti"
  ],
  "how did you meet crescius": [
    "Quomod Crescium novisti"
  ],
  "where did you learn your alchemy skills": [
    "Ubi artem alchemiae didicisti"
  ],
  "emperor parasol moss": [
    "Mucus Umbellae Imperatoris"
  ],
  "what kinds of game do you hunt": [
    "Qaulia animilia venaris"
  ],
  "miraak steals a dragon soul": [
    "Miraak Steals a Dragon Soul"
  ],
  "you're a priest at the temple": [
    "Sacerdos ad Templum es"
  ],
  "dragonrend effect - prevent flying": [
    "Dragonrend Effect - Prevent Flying"
  ],
  "why didn't he move somewhere else to find work": [
    "Quid ni ad alium locum se moveret ut laborem quaereret"
  ],
  "is he somewhere else on solstheim": [
    "Estne ille in alio loco in Solstheim"
  ],
  "unarmed damge": [
    "Unarmed Damge"
  ],
  "burnt spriggan abilities": [
    "Burnt Spriggan abilities"
  ],
  "council": [
    "Councilium",
    "concilium, conciliī"
  ],
  "you mean there's no jarl": [
    "Nullus comes est"
  ],
  "the nords are dead": [
    "Germani Mortui sunt"
  ],
  "nords are dead": [
    "Germani Mortui sunt"
  ],
  "frost spider abilities": [
    "Frost Spider Abilities"
  ],
  "i'm here about the stalhrim map": [
    "Hic sum ut Chartam Geographicam Stalhrimis inveniam"
  ],
  "bring 10 scathecraw to the chief": [
    "Carpe 10 Scathecraw pro Principe"
  ],
  "miraak has absorbed the dragon soul": [
    "Miraak animum draconis absorbuit"
  ],
  "it sounds like quite a sight": [
    "Mirabile visu"
  ],
  "your inn has an unusual name": [
    "Sodalitas tua nomem mirabile habet"
  ],
  "who was your great-grandfather": [
    "Quis erat proavus tuus"
  ],
  "dukaan": [
    "Dukaanis Persona"
  ],
  "what exactly is \"ash-grown food?\"": [
    "Quid est \"cibus cinere-cultus?\""
  ],
  "are sales that bad out here": [
    "Estne negotium tam malum hic"
  ],
  "what exactly do you sell here": [
    "Quid hic vendis"
  ],
  "tosses a shock cloaked spider on the ground that will apply shock damage to enemies that get too close": [
    "Tosses a Shock Cloaked spider on the ground that will apply Shock Damage to enemies that get too close"
  ],
  "ahzidal": [
    "Ahzidalis Persona"
  ],
  "who do you trade with": [
    "Cum quibus commercium facis"
  ],
  "inspire": [
    "Inspira"
  ],
  "you worry much on your husband's behalf": [
    "Multum de marito sollicitaris"
  ],
  "dremora shop location": [
    "Dremora Shop Location"
  ],
  "ash breath": [
    "Ash breath"
  ],
  "doesn't sound unusual": [
    "Non tam mirabilis mihi videtur"
  ],
  "can you train me in conjuration": [
    "Potesne mihi de arte Vocationis docere"
  ],
  "what exactly are the \"reclamations?\"": [
    "Quae sunt \"Reclamationes?\""
  ],
  "herma-mora": [
    "Herma-Mora"
  ],
  "immune to bend will shout": [
    "Immune to Bend Will shout"
  ],
  "doesn't sound that impressive": [
    "Non valde commovet"
  ],
  "deathbrand ferocity": [
    "Deathbrand Ferocity"
  ],
  "what is this \"redgrass\"": [
    "Quid est \"Rubrum Gramen\""
  ],
  "zahkriisos": [
    "Zahkriisos' Persona"
  ],
  "frost cloaked spider": [
    "Aranea Glaciei Amiculata"
  ],
  "drains <mag> points from carry weight": [
    "Diminuit <mag> puncta ab pondere portabili"
  ],
  "that's not enough. i need to stop miraak now": [
    "Bene incepimus, sed Miraakem sistere debeo"
  ],
  "sh1n": [
    "Sh1n"
  ],
  "what sort of a secret would be hidden here": [
    "Quale secretum hic celatur"
  ],
  "the final descent": [
    "Descensus Ultimus"
  ],
  "final descent": [
    "Descensus Ultimus"
  ],
  "i brought back your animal": [
    "Animal tuum rettuli"
  ],
  "shock cloaked spider": [
    "Aranea Electricitatis Amiculata"
  ],
  "damage carry weight": [
    "Vulnera Pondus Portabile"
  ],
  "i don't know about this": [
    "De hoc nescio"
  ],
  "who or what is the all-maker": [
    "Quis vel Quid est Omni-Factor"
  ],
  "which is it? yes or no": [
    "Quid est? Sic vel non"
  ],
  "who taught you that": [
    "Quis tibi illud didicit"
  ],
  "the smith said he'd teach me how to make stalhrim weapons": [
    "Ferrarius dixit se me de arte telorum Stalhrimium faciendi docturum esse"
  ],
  "smith said he'd teach me how to make stalhrim weapons": [
    "Ferrarius dixit se me de arte telorum Stalhrimium faciendi docturum esse"
  ],
  "do you know him or not": [
    "Eum novisti vel non"
  ],
  "ice form": [
    "Forma Glaciei"
  ],
  "spell tome: poison rune": [
    "Liber Magicae: Runus Veneni"
  ],
  "poi 13": [
    "POI 13"
  ],
  "sahrotaar": [
    "Sahrotaar"
  ],
  "this is where you met": [
    "Hic cum eo convenisti"
  ],
  "ash hopper abilities": [
    "Ash Hopper abilities"
  ],
  "what do we need to do now": [
    "Quid nunc facere debemus"
  ],
  "the blacksmith won't talk": [
    "Ferrarius non loquetur"
  ],
  "blacksmith won't talk": [
    "Ferrarius non loquetur"
  ],
  "you're wasting your time (persuade)": [
    "Tempus perdis (Persuade)"
  ],
  "how did you end up in raven rock": [
    "Quomodo huc Corvisaxi venisti"
  ],
  "how did your mother die": [
    "Quomodo mortua est mater tua"
  ],
  "what did you do in skyrim, before you came back here": [
    "Quid in Caelifine fecisti ante huc revenisti"
  ],
  "can you tell me anything about him": [
    "Potesne mihi quidquid de illo narrare"
  ],
  "are there any other imperials in raven rock": [
    "Suntne alii Imperiales Corivisaxi"
  ],
  "kill the nords (<global=dlc2mh02nordskilled>/<global=dlc2mh02nordstotal>)": [
    "Neca Germanos (<Global=DLC2MH02NordsKilled>/<Global=DLC2MH02NordsTotal>)"
  ],
  "what's an ancient nordic pickaxe": [
    "Quid est Dolabra Antiqua Germanica"
  ],
  "what does that mean": [
    "Quid sibi vult",
    "Quid significat illud"
  ],
  "why did you leave morthal": [
    "Quamobrem Morthali discedisti"
  ],
  "tosses a jumping frost spider on the ground that will launch itself at nearby enemies and explode if it lands near them": [
    "Araneam Glaciei Amiculatam in solum iacit quae se ad inimico iacit"
  ],
  "tell me more about your people, the skaal": [
    "Dic mihi plus de hominibus tuis, Skaal"
  ],
  "bend will follower faction": [
    "Bend Will Follower Faction"
  ],
  "are you friends with councilor morvayn": [
    "Esne amicus cum Consuasore Morvayn"
  ],
  "i've returned": [
    "Reveni"
  ],
  "tell me more about this book, then": [
    "Potesne mihi plus de hoc Libro Atro docere? (Demonstra Librum)"
  ],
  "puzzle controller": [
    "Moderator Aenigmae"
  ],
  "miraak did claim to be dragonborn": [
    "Miraak dixit se Draconigenam esse"
  ],
  "escape bloodskal barrow": [
    "Effuge e Pabillo Sanguiskali"
  ],
  "equipped weapon of all allies swing faster for <dur> seconds": [
    "Arma Omnium Sociorum celerius vibrantur <dur> secundas"
  ],
  "deathbrand haul": [
    "Deathbrand Haul"
  ],
  "netch": [
    "Netch"
  ],
  "calm all": [
    "Omnes Pacifica"
  ],
  "ongoing poison damage of <mag> points per second": [
    "Venenum pergens quod <mag> puncta vulneris singulis secundis facit"
  ],
  "can't you just get the guard to find crescius": [
    "Quidni custodes mittas ut Crescium inveniant"
  ],
  "ancient chest": [
    "Arcam Antiquam"
  ],
  "let me think about this": [
    "Sine me de hoc cogitare"
  ],
  "retrieve gratian caerellius's journal": [
    "Inveni Ephemeridem Gratiani Caerelli"
  ],
  "bound sword": [
    "Gladius Vinctus"
  ],
  "you want me to do something else": [
    "Vis me aliquid aliud facere"
  ],
  "i am indeed dragonborn": [
    "Draconigena quidem sum"
  ],
  "where did you get all these items": [
    "Unde haec omnia invenisti"
  ],
  "you have friends at the east empire company": [
    "Amici ad Negodium Euri Imperii Habes"
  ],
  "i have your ancient nordic pickaxe": [
    "Dolabram Antiquam Germanicam tuam tibi habeo"
  ],
  "how did you become the leader of the village": [
    "Quomodo dux vici factus es"
  ],
  "i read a book in miraak's temple, and went somewhere. miraak was there": [
    "Librum Atrum in Templo Miraakis legi. Deinde ad alium locum teleportatus sum. Miraak illic fuit"
  ],
  "deathbrand gauntlets": [
    "Mortibrandis Manubria"
  ],
  "gratian's letter": [
    "Epistula Gratianis"
  ],
  "join the assault": [
    "Adiunce Oppugnum"
  ],
  "why all the fuss over a simple pickaxe": [
    "Cur tanta turba de dolabra simplici"
  ],
  "this is hardly proof of anything": [
    "Haec evidentia nihil est"
  ],
  "you're not one of the skaal": [
    "Unus ex Skaal non es"
  ],
  "an axe to find": [
    "Securis Quaerendus"
  ],
  "sujamma": [
    "Sujamma"
  ],
  "i saw a dragon once": [
    "Draconem olim vidi"
  ],
  "tosses a frost cloaked spider on the ground that will apply ice damage to enemies that get too close": [
    "Araneam Glaciei Amiculatam in solum iacit quae vulnus glaciei inimicis prope se faciet"
  ],
  "bilgemuck": [
    "Bilgemuck"
  ],
  "you must owe him quite a bit": [
    "Ei multa certe debes"
  ],
  "how did he die": [
    "Quomodo mortuus est"
  ],
  "target is paralyzed for <dur> seconds": [
    "Inimicum paralyticum est <dur> secundas",
    "Inimicus paralyticus est <dur> secundas"
  ],
  "who are the redoran guard": [
    "Qui sunt custodes Redoran"
  ],
  "is he someone here in raven rock": [
    "Estne ille quis hic Corvisaxi"
  ],
  "i'm just exploring": [
    "Modo exploro"
  ],
  "perhaps i could help": [
    "Fortasse adiuavare possum"
  ],
  "your people are free": [
    "Tui liberti sunt"
  ],
  "fire damage": [
    "Vulnus Ignis"
  ],
  "try to remember": [
    "Meminisse tempta"
  ],
  "i brought your scathecraw": [
    "Scathecraw tibi rettuli"
  ],
  "leave this island now, or your life is forfeit": [
    "E hac insula statim effuge vel actum est de te"
  ],
  "what's your proof": [
    "Quid est evidentia tua"
  ],
  "lower carry weight": [
    "Lower Carry Weight"
  ],
  "what's the unusual armor you're wearing": [
    "Qualem loricam mirabilem induis"
  ],
  "any idea of where to start": [
    "Ubi incipere possum"
  ],
  "i assume it's rare": [
    "Reor eum rarum esse"
  ],
  "yes, otherwise he'll teach me nothing": [
    "Sic, vel ille mihi nihil docebit"
  ],
  "you can trust me": [
    "Mihi confidere potes"
  ],
  "dremora butler": [
    "Dremora Promus"
  ],
  "you want me to kill people": [
    "Mihi suades ut homines necem"
  ],
  "fire storm 10": [
    "Fire Storm 10"
  ],
  "ven": [
    "Ven"
  ],
  "unleash": [
    "Libera"
  ],
  "exploding shock spider": [
    "Aranea Displodens Electricitatis"
  ],
  "can't you make them stop": [
    "Quidni eos sistas"
  ],
  "nos": [
    "Nos"
  ],
  "v6": [
    "V6"
  ],
  "what is \"oneness with the land?\"": [
    "Quid sibi vult \"una cum terra?\""
  ],
  "fire damage 10": [
    "Fire Damage 10"
  ],
  "east empire company": [
    "East Empire Company",
    "Negotium Euri Imperii"
  ],
  "summon dremora butler": [
    "Voca Promum Dremoram"
  ],
  "return ancient nordic pickaxe to glover mallory": [
    "Redde Dolabrum Antiquum Germanicum ad Glover Mallory"
  ],
  "good luck with that": [
    "Bona fortuna cum eo",
    "Bonam fortunam cum eo",
    "Bona fortuna cum eo tibi exopto"
  ],
  "i'd like you to carry some things": [
    "Porta nonullas res mihi, quaeso"
  ],
  "ash hopper fx": [
    "Ash Hopper FX"
  ],
  "what else does a first hunter do": [
    "Quid aliud facit Venator Primus"
  ],
  "where have you heard his name": [
    "Ubi nomen audivisti"
  ],
  "please try and think": [
    "Conare ut cogites"
  ],
  "wizard": [
    "Wizard",
    "Thaumatorgos"
  ],
  "pirate captain": [
    "Nauarchos Piratarum"
  ],
  "tell glover mallory lie about the ancient nordic pickaxe": [
    "Dic falsum ad Glover Mallory de Dolabro Antiquo Germanico"
  ],
  "so you do need my help": [
    "Ergo auxilio meo cares"
  ],
  "g1r": [
    "G1r"
  ],
  "it looks like an ash spawn attack": [
    "Impetus filiorum cineris mihi videtur"
  ],
  "you want stalhrim weapons, and i think i can help you get them": [
    "Arma Stalhrimis vis. Tibi in eo auxilium dare possum"
  ],
  "varona is dead. i found her body on the road": [
    "Varona Mortua est. Corpus eius in via inveni"
  ],
  "seeker remains": [
    "Vestigiae Quaestoris"
  ],
  "do you rule all of solstheim": [
    "Regisne omnia Solstheimis"
  ],
  "netchskin": [
    "Pellis Netch"
  ],
  "do you work with neloth": [
    "Iam cum Nelothi laboras"
  ],
  "the false tribunal": [
    "Tribunal falsum"
  ],
  "false tribunal": [
    "Tribunal falsum"
  ],
  "after all that, why": [
    "Post haec omnia, cur"
  ],
  "gift of flight": [
    "Gift of flight"
  ],
  "what can you tell me about ildari sarothril": [
    "Quid mihi de Ildari Sarothril narrare potes"
  ],
  "deathbrand breath": [
    "Deathbrand Breath"
  ],
  "i still think you're crazy": [
    "Iam credo te insanum esse"
  ],
  "cyclone03": [
    "Cyclone03"
  ],
  "bend will 02": [
    "Bend Will 02"
  ],
  "the key is to the locked section": [
    "Clavis est ad partem clausam"
  ],
  "key is to the locked section": [
    "Clavis est ad partem clausam"
  ],
  "who's your husband": [
    "Quis est maritus tuus"
  ],
  "what wisdom can you share about hunting": [
    "Quam scientiam de arte venandi mecum communicare potes"
  ],
  "audiotemplate lurker": [
    "AudioTemplate Lurker"
  ],
  "bristleback": [
    "Saetaper"
  ],
  "what did the letter say": [
    "Quid dixit epistula"
  ],
  "sounds rather complicated": [
    "Nimis complicatum mihi videtur"
  ],
  "what is house redoran": [
    "Quid est Gens Redoran"
  ],
  "defeat the chief": [
    "Vince Principem"
  ],
  "fine, you keep it": [
    "Bene, habe eum"
  ],
  "gratian's journal": [
    "Ephemerides Gratianis"
  ],
  "how has raven rock needed your help": [
    "Quale auxilum Corvisaxum abs te rogavit"
  ],
  "black heart blight": [
    "Morbus Cordis Atris"
  ],
  "become ethereal": [
    "Become Ethereal",
    "Esto Aetherialis"
  ],
  "what does this all mean": [
    "Quid sibi volunt haec omnia"
  ],
  "gratian caerellius": [
    "Gratian Caerellius"
  ],
  "speak to the chief": [
    "Principem Adloquere"
  ],
  "millius": [
    "Millius"
  ],
  "why are you so suspicious of visitors": [
    "Quamobrem tam timidus alienorum es"
  ],
  "do you know someone called miraak": [
    "Aliquemne nomine Miraak novisti"
  ],
  "you haven't told him how you feel": [
    "Eum certiorem de sententiis tuis non fecisti"
  ],
  "raven rock the only settlement on the island": [
    "Corvicisaxum est urbs sola in insula"
  ],
  "return bilgemuck to thirsk": [
    "Redde Bilgemuck ad haram foris Aulam Hydomelli Thirsk"
  ],
  "raven rock mine key": [
    "Corvisaxi Metalli Clavis"
  ],
  "is stalhrim a type of ore": [
    "Estne Stalhrim quodam minerale"
  ],
  "give bilgemuck some meat": [
    "Da paulum carnis ad Bilgemuck"
  ],
  "it's dangerous here for an old man": [
    "Periculosum hic est pro seni"
  ],
  "how do the dunmer bury their dead": [
    "Quomodo Atralfi mortuos suos sepeliunt"
  ],
  "how long have you been a sea captain": [
    "Quod annos navarchos fuisti"
  ],
  "has security been an issue": [
    "Fueruntne difficultates cum securitate"
  ],
  "deathbrand endurance": [
    "Deathbrand Endurance"
  ],
  "flin": [
    "Flin"
  ],
  "skull": [
    "Calvaria"
  ],
  "summons a dremora butler for <dur> seconds to carry your excess items": [
    "Vocat Promum Dremoram <dur> secundas quod res superfluas tuas portat"
  ],
  "what did the netch do": [
    "Quid fecit Netch"
  ],
  "retrieve the ancient nordic pickaxe": [
    "Inveni Dolabrum Antiquum Germanicum a Crescio Caerellio"
  ],
  "the chief of thirsk hall": [
    "Princeps Aulae Thirsk"
  ],
  "chief of thirsk hall": [
    "Princeps Aulae Thirsk"
  ],
  "petty distractions": [
    "Nugae"
  ],
  "raven rock tomb key": [
    "Corvisaxi Tumulum Clavis"
  ],
  "castle karstaag key": [
    "Castelli Karstaagis Clavis"
  ],
  "raven rock milore services faction": [
    "Raven Rock Milore Services Faction"
  ],
  "you need to know the truth": [
    "Verum scire debes"
  ],
  "she was rejected by hrothmund": [
    "Negata est a Hrothmund"
  ],
  "they'll show up eventually i suppose": [
    "Tandem aliquando venient, ut puto"
  ],
  "why can't you use the tomb": [
    "Quamobrem tumulo uti non potes"
  ],
  "what did he want": [
    "Quid voluit"
  ],
  "tell me about your dream": [
    "Dic mihi de somnio tuo"
  ],
  "dukaan's fury": [
    "Insania Dukaanis"
  ],
  "i found the emberbrand wine stash": [
    "Latebra Vini Cineribrandis inveni"
  ],
  "snowclad ruins": [
    "Ruinae Nivosae"
  ],
  "still buying east empire pendants": [
    "Iam monilia Euri Imperii emis"
  ],
  "let her back in, or you answer to me": [
    "Sine eam revenire vel poenas dabis"
  ],
  "raven rock geldis services faction": [
    "Raven Rock Geldis Services Faction"
  ],
  "recipe for distraction": [
    "Dictamen Distractionis"
  ],
  "report the stash of emberbrand wine to captain veleth": [
    "Refer Vinum Cinibrandis Coracisaxi ad Nauarchum Veleth"
  ],
  "raven rock temple scene 04": [
    "Raven Rock Temple Scene 04"
  ],
  "shein": [
    "Shein"
  ],
  "ahzidal's rage": [
    "Insanitas Ahzidalis"
  ],
  "is there anything you can tell me": [
    "Postsne mihi quidquid dicere"
  ],
  "matze": [
    "Mazte"
  ],
  "what are these people doing": [
    "Quid faciunt hi homines"
  ],
  "locate the hidden stash of emberbrand wine in raven rock": [
    "Inveni Vinum Cinibrandis Coracisaxi"
  ],
  "the black books have something to do with hermaeus mora": [
    "Libri Atri aliquid cum Hermaea Mora aguntur"
  ],
  "black books have something to do with hermaeus mora": [
    "Libri Atri aliquid cum Hermaea Mora aguntur"
  ],
  "she attacked me and i had to defend myself": [
    "Illa me oppugnavit et me defendi"
  ],
  "who is hermaeus mora": [
    "Quis est Hermes Trismegisthus"
  ],
  "i don't know what happened to her": [
    "Nescio quid ei accideret"
  ],
  "you're selling quite a few exotic ingredients": [
    "Multa ingredientia mirabilia vendis"
  ],
  "i'll keep out of your way": [
    "Foris viae tuae manebo"
  ],
  "brand-shei's strongbox key": [
    "Brand-Shei Clavis Arcae",
    "Clavis ad Arcam Brand-Shei"
  ],
  "it's all in the taste": [
    "Omnia in Sapore Sunt"
  ],
  "temple ancestral tomb": [
    "Tumulum Maiorum Templi"
  ],
  "i could gather some for you": [
    "Paulum tibi carpere possum"
  ],
  "deathbrand boots": [
    "Mortibrandis Caligae"
  ],
  "do you sell any special mining gear": [
    "Vendisne instrumenta specialia apta ad fodiendum"
  ],
  "what sort of dream": [
    "Quale somnium"
  ],
  "keyhole": [
    "Claustellum",
    "Foramen Clavi"
  ],
  "fetch the netch": [
    "Cape Netch"
  ],
  "what are these black books": [
    "Qui sunt hi Libri Atri"
  ],
  "where do they come from": [
    "Unde veniunt"
  ],
  "sadri's sujamma": [
    "Sujamma Sadri"
  ],
  "i could track the stash down for you": [
    "Gazam tibi invenire possum"
  ],
  "i could talk to him for you": [
    "Eum pro te adloqui possum"
  ],
  "convince geldis sadri to admit bralsa drel to the inn": [
    "Convince Geldis Sadri ut Bralsa Drel in Diversorium addmitat"
  ],
  "it'll turn up sooner or later": [
    "Mox apparebit"
  ],
  "all dragonborn keys": [
    "All Dragonborn Keys"
  ],
  "tell bralsa drel she's welcome at the retching netch": [
    "Dic Bralsae Drel illam liberam esse ad Netch Vomitans"
  ],
  "barrel": [
    "Cuppa",
    "Cuppam"
  ],
  "raven rock glover services faction": [
    "Raven Rock Glover Services Faction"
  ],
  "i'll sell you some of mine": [
    "Tibi mea vendam"
  ],
  "(give east empire pendants)": [
    "(Da Monilia Euri Imperii)"
  ],
  "absorb <mag> health, magicka, and stamina for <dur> seconds": [
    "Absorb <mag> salutis, magickae, staminaeque <dur> secundas"
  ],
  "bring the netch jelly to milore ienth": [
    "Refer Cilon Netch Jelly ad Milore Ienth"
  ],
  "health/magicka damage": [
    "Health/Magicka Damage"
  ],
  "what's an east empire pendant": [
    "Quid est Monile Euri Imperii"
  ],
  "you're welcome at the retching netch anytime": [
    "Semper salvus es ad Netch Vomitantem"
  ],
  "is it difficult commanding the redoran guard": [
    "Estne tibi difficile Custodibus Redoran imperare"
  ],
  "clean sweep": [
    "Munde Verre"
  ],
  "i'll cleanse the tomb for you": [
    "Sepulchrum tibi mundabo"
  ],
  "tell elder othreloth that the tomb has been cleansed": [
    "Dic Vetus Othreloth Tumulum Mundum Esse"
  ],
  "consider it done": [
    "Puta eum factum",
    "Puta id iam factum esse"
  ],
  "we're going to fight his ghost": [
    "Phantasmam eius oppugnabimus"
  ],
  "maybe this will change your mind": [
    "Fortasse hoc mentem tuam mutabit"
  ],
  "creaturedialoguenetch": [
    "Creature Dialogue Netch"
  ],
  "sounds risky": [
    "Periculosum mihi videtur"
  ],
  "i have some netch jelly right here": [
    "Quilon Netch hic habeo"
  ],
  "(give 5 netch jelly)": [
    "(Da V Quilon Netch)"
  ],
  "is he someone you know, or not": [
    "Illumne noveris vel non"
  ],
  "why did you ban bralsa drel from your inn": [
    "Quamobrem Bralsam Drel expulisti"
  ],
  "tell geldis sadri you've distributed his liquor samples": [
    "Dic Geldis Sadri Sujammam eius distribuisti"
  ],
  "what's going to happen in there": [
    "Quid illic accidet"
  ],
  "zahkriisos' ire": [
    "Ira Zahkriisos"
  ],
  "the temple's tomb has been cleansed": [
    "Tumulum Templi mundum est"
  ],
  "temple's tomb has been cleansed": [
    "Tumulum Templi mundum est"
  ],
  "retrieve netch jelly for milore ienth (<global=dlc2rrf02jellycount>/<global=dlc2rrf02jellytotal>)": [
    "Refer Cilon Netch pro Milore Ienth (<Global=DLC2RRF02JellyCount>/<Global=DLC2RRF02JellyTotal>)"
  ],
  "raven rock fethis services faction": [
    "Raven Rock Fethis Services Faction"
  ],
  "distribute sadri's sujamma (<global=dlc2rrf04drinkcount>/<global=dlc2rrf04drinktotal>)": [
    "Da Sujammam Sadri ad (<Global=DLC2RRF04DrinkCount>/<Global=DLC2RRF04DrinkTotal>) homines"
  ],
  "locate the east empire pendants for fethis alor in raven rock": [
    "Locate the East Empire Pendants for Fethis Alor in Raven Rock"
  ],
  "pain in the necklace": [
    "Pain in the Necklace"
  ],
  "feeding the addiction": [
    "Narcomania Pascens"
  ],
  "raven rock any location scene 13": [
    "Raven Rock Any Location Scene 13"
  ],
  "tell me whatever you can": [
    "Dic mihi quidquid"
  ],
  "raven rock any location scene 16": [
    "Raven Rock Any Location Scene 16"
  ],
  "seeker drain": [
    "Seeker Drain"
  ],
  "why should i pity you": [
    "Quamobrem tibi misereri debeo"
  ],
  "so what's the specialty of the house": [
    "Ergo quid bonum hic est"
  ],
  "black book": [
    "Liber Ater"
  ],
  "she has a right to do what she pleases": [
    "Illa quidquid vult facere potest"
  ],
  "just tell me where the book is and i'll go get it": [
    "Modo dic mihi ubi sit liber et eum inveniam"
  ],
  "east empire company strongbox": [
    "Arcam Negotii Euri Imperii"
  ],
  "it was an honor to stand with her": [
    "Honor erat mihi cum ea stare"
  ],
  "have you tried to stop this": [
    "Temptavistine Finem ad hoc facere"
  ],
  "i'm all out of sadri's sujamma": [
    "Nihil plus Sujammae Sadris habeo"
  ],
  "i'm back": [
    "Reveni"
  ],
  "geldis sadri wants you to try his new drink": [
    "Geldis Sadri tibi sausit ut novum bibitum eius probares"
  ],
  "any idea where i can begin": [
    "Ubi incipere possum"
  ],
  "east empire pendant": [
    "Euri Imperii Amuletum"
  ],
  "raven rock elder othreloth preaching scene": [
    "Raven Rock Elder Othreloth Preaching Scene"
  ],
  "i could help spread the word": [
    "Rumores eius ferre possum"
  ],
  "deathbrand armor": [
    "Mortibrandis Lorica"
  ],
  "raven rock any location scene 14": [
    "Raven Rock Any Location Scene 14"
  ],
  "i've given out the sadri's sujamma as requested": [
    "Sujamma Sadris distribui"
  ],
  "used to": [
    "Non nunc"
  ],
  "knowledge drain": [
    "Knowledge Drain"
  ],
  "sorry to hear that": [
    "Me paenitet id audire",
    "Me paenitite de illo audire"
  ],
  "what do the black books have to do with hermaeus mora": [
    "Quid aguntur Libri Atri cum Hermaeo Mora"
  ],
  "karstaag's resistances": [
    "Karstaag's Resistances"
  ],
  "what are the ancestral tombs": [
    "Quae sunt Tumula Maiorum"
  ],
  "clear the temple's tomb of ash spawn": [
    "Emove Filios Cineris e Tumulo Templi"
  ],
  "i have that netch jelly for you": [
    "Quilon Netch tibi habeo"
  ],
  "hrothmund said the next thirsk chief is a long way off": [
    "Hrothmund dixit proximum principem Thirsk longe abesse"
  ],
  "it's not that simple": [
    "Non est tam simplex"
  ],
  "bujold was rejected by hrothmund": [
    "Bujold necatus est a Hrothmund"
  ],
  "deathbrand helm": [
    "Mortibrandis Galea"
  ],
  "karstaag's skull": [
    "Calvaria Karstaagis"
  ],
  "where can you find them": [
    "Ubi eos invenire possum"
  ],
  "why do we need these cubes": [
    "Quamobrem haec cuba quaerimus"
  ],
  "raven rock garyn services faction": [
    "Raven Rock Garyn Services Faction"
  ],
  "virtuous nordic bow": [
    "Germanicus Arcus Virtutis"
  ],
  "stalhrim helmet of eminent restoration": [
    "Stalhrimis Galea Levis Eminentis Recreandi"
  ],
  "stalhrim war axe of harvesting": [
    "Stalhrimis Bellisecuris Saturni"
  ],
  "nordic mace of fire": [
    "Germanica Maca Ignis"
  ],
  "chitin bracers of eminent archery": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Eminentis Sagittationis"
  ],
  "chitin helmet of eminent magicka": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Eminentis Magicae"
  ],
  "nordic helmet of eminent restoration": [
    "Germanica Galea Cavata Eminentis Recreandi"
  ],
  "stalhrim armor of eminent restoration": [
    "Stalhrimis Lorica Levis Eminentis Recreandi"
  ],
  "ildari's journal, vol. iii": [
    "Ephemerides Ildaris, Vol. III"
  ],
  "stalhrim armor of extreme conjuring": [
    "Stalhrimis Lorica Levis Extremis Vocandi"
  ],
  "nordic armor of major health": [
    "Germanica Lorica Cavata Maioris Salutis"
  ],
  "stalhrim gauntlets of eminent smithing": [
    "Stalhrimis Manubria Eminentis Faciendi"
  ],
  "netch abilities": [
    "Netch abilities"
  ],
  "electrified": [
    "Electrified"
  ],
  "stalhrim dagger of extreme chaos": [
    "Stalhrimis Pugio Chaos Extremis"
  ],
  "nordic warhammer of reaping": [
    "Germanicus Bellimalleus Decerpendi"
  ],
  "nordic boots of fire abatement": [
    "Germanicae Caligae Cavatae Subsidentiae Ignis"
  ],
  "stalhrim warhammer of dread": [
    "Stalhrimis Bellimalleus Phobiae"
  ],
  "you should pay me what you can": [
    "Solve mihi id quod potes"
  ],
  "nordic greatsword of diminishing": [
    "Germanica Spatha Dinimuendi"
  ],
  "so what's this treasure you were after": [
    "Ergo quam gazam quaeris"
  ],
  "summons karstaag to fight for you for <dur> seconds": [
    "Vocat Karstaag <dur> secundas"
  ],
  "chitin boots of major sneaking": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Maioris Rependi"
  ],
  "nordic shield of shock suppression": [
    "Germanicum Scutum Cavum Suppressionis Electricitatis"
  ],
  "stalhrim boots of dwindling frost": [
    "Stalhrimis Caligulae Glaciei Diminuendi"
  ],
  "nordic warhammer of banishing": [
    "Germanicus Bellimalleus Dimittendi"
  ],
  "ahzidal's retribution": [
    "Ahzidalis Retributio"
  ],
  "nordic greatsword of despair": [
    "Germanica Spatha Timoris"
  ],
  "nordic armor of major destruction": [
    "Germanica Lorica Cavata Maioris Destructionis"
  ],
  "chitin shield of waning magic": [
    "Chitonos Scutum Magicae Diminuentis"
  ],
  "bonemold gauntlets of minor wielding": [
    "Ossea Manubria Minoris Dexteritatis"
  ],
  "stalhrim war axe of debilitation": [
    "Stalhrimis Bellisecuris Deminuendi"
  ],
  "chitin bracers of major smithing": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Maioris Faciendi"
  ],
  "hallowed stalhrim greatsword": [
    "Stalhrimis Spatha Sacra"
  ],
  "nordic arrow": [
    "Sagitta Germanica"
  ],
  "nordic greatsword of torpor": [
    "Germanica Spatha Torporis"
  ],
  "stalhrim gauntlets of major alchemy": [
    "Stalhrimis Manubria Levia Maioris Alchemiae"
  ],
  "cast on the ground, it creates an area of lightning that does <50> points of shock damage per second": [
    "Fac in solum, facit parietem electricitatis quod <50> puncta vulneris electricitatis singulas secundas facit"
  ],
  "chitin boots of waning frost": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Glaciei Diminuentis"
  ],
  "stalhrim bow of damnation": [
    "Stalhrimis Arcus Damnationis"
  ],
  "stalhrim gauntlets of eminent sure grip": [
    "Stalhrimis Manubria Eminentis Ambidexteritatis"
  ],
  "chitin boots of dwindling shock": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Electricitatis Diminuendi"
  ],
  "nordic shield of fire suppression": [
    "Germanicum Scutum Cavum Suppressionis Ignis"
  ],
  "bonemold gauntlets of alteration": [
    "Ossea Manubria Alterationis"
  ],
  "bonemold helmet of major illusion": [
    "Ossea Galea Maioris Inlusionis"
  ],
  "stalhrim battleaxe of ultimate chaos": [
    "Stalhrimis Bellisecuris Chaos Ultimi"
  ],
  "as a matter of fact, i do": [
    "Si verum dicam, sic"
  ],
  "(5000 gold)": [
    "(MMMMM Auri)"
  ],
  "stalhrim sword of devouring": [
    "Stalhrimis Gladius Consumendi"
  ],
  "conjure ice wraith": [
    "Voca Phantasmam Glaciei"
  ],
  "nordic sword of freezing": [
    "Germanicus Gladius Glaciei"
  ],
  "chitin armor of destruction": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Destructionis"
  ],
  "stalhrim helmet of peerless alteration": [
    "Stalhrimis Galea Optimae Alterationis"
  ],
  "nordic warhammer of scorching": [
    "Germanicus Bellimalleus Incendendi"
  ],
  "chitin boots of frost suppression": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Contra Vim Frigidum"
  ],
  "stalhrim sword of damnation": [
    "Stalhrimis Gladius Damnationis"
  ],
  "bonemold gauntlets of minor deft hands": [
    "Ossea Manubria Minoris Furis"
  ],
  "droops": [
    "Droops"
  ],
  "shall we do some business": [
    "Negotiumne agamus"
  ],
  "stalhrim sword of harvesting": [
    "Stalhrimis Gladius Saturni"
  ],
  "stomp": [
    "Stomp"
  ],
  "nordic sword of lightning": [
    "Germanicus Gladius Fulminis"
  ],
  "stalhrim gauntlets of major archery": [
    "Stalhrimis Manubria Levia Maioris Sagittationis"
  ],
  "slain by the morag tong": [
    "A Morag Tong necata"
  ],
  "damphall mine": [
    "Metallum Madidatrium"
  ],
  "chitin armor of eminent conjuring": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Eminentis Vocandi"
  ],
  "chitin boots of major stamina": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Maioris Staminae"
  ],
  "bonemold boots of dwindling shock": [
    "Osseae Caligae Electricitatis Diminuendi"
  ],
  "nordic mace of stunning": [
    "Germanica Maca Pausandi"
  ],
  "will you ever go back to hrothmund and try again": [
    "Umquam ad Hrothmund revenies et iterum conaberis"
  ],
  "chitin armor of illusion": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Inlusionis"
  ],
  "nordic war axe of lightning": [
    "Germanicus Bellisecuris Fulminis"
  ],
  "temple of miraak key": [
    "Templum Miraakis Clavis"
  ],
  "recipe - exploding shock spider 2x": [
    "Dictamen - Aranea Elextricitatis Displodens 2x"
  ],
  "nordic sword of immobilizing": [
    "Germanicus Gladius Conligandi"
  ],
  "where were you when the dragon broke": [
    "Ubi fuisti cum Draco Frangeretur"
  ],
  "chitin armor of major conjuring": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Maioris Vocandi"
  ],
  "stalhrim armor of peerless alteration": [
    "Stalhrimis Lorica Optimae Alterationis"
  ],
  "benkum": [
    "Benkum"
  ],
  "bonemold armor of the squire": [
    "Ossea Lorica Armigeris"
  ],
  "reverent nordic sword": [
    "Reverens Germanicus Gladius"
  ],
  "nordic sword of binding": [
    "Germanicus Gladius Deligandi"
  ],
  "bonemold boots of waning shock": [
    "Osseae Caligae Electricitatis Diminuentis"
  ],
  "nordic dagger of freezing": [
    "Germanicus Pugio Glaciei"
  ],
  "stalhrim greatsword of stunning": [
    "Stalhrimis Spatha Pausandi"
  ],
  "nordic gauntlets of major alchemy": [
    "Germanica Manubria Cavata Maioris Alchemiae"
  ],
  "stalhrim battleaxe of the sorcerer": [
    "Stalhrimis Bellisecuris Thaumaturgos"
  ],
  "absorb magicka": [
    "Absorbe Magicam"
  ],
  "nordic mace of despair": [
    "Germanica Maca Timoris"
  ],
  "dlc2 warehouse ambushes": [
    "DLC2 Warehouse Ambushes"
  ],
  "nordic armor of major illusion": [
    "Germanica Lorica Cavata Maioris Inlusionis"
  ],
  "stalhrim helmet of extreme alchemy": [
    "Stalhrimis Galea Extremis Alchemiae"
  ],
  "virtuous stalhrim battleaxe": [
    "Stalhrimis Bellisecuris Virtutis"
  ],
  "your unrelenting force shout does more damage and using all three words may disintegrate enemies": [
    "Vis Interminabilis plus vulneris facit et omnia tria verba fortasse inimicos disintegrum faciunt"
  ],
  "stalhrim helmet of eminent magicka": [
    "Stalhrimis Galea Eminentis Magicae"
  ],
  "werebear": [
    "Werebear"
  ],
  "why is he exploring the mine": [
    "Quamobrem metallum explorat"
  ],
  "fake protection": [
    "Fake Protection"
  ],
  "stalhrim battleaxe of blizzards": [
    "Stalhrimis Bellisecuris Hiemis"
  ],
  "stalhrim armor of revival": [
    "Stalhrimis Lorica Renovandi"
  ],
  "nordic armor of extreme illusion": [
    "Germanica Lorica Cavata Extremis Inlusionis"
  ],
  "stalhrim boots of fire abatement": [
    "Stalhrimis Caligae Subsidentiae Ignis"
  ],
  "stalhrim helmet of extreme restoration": [
    "Stalhrimis Galea Extremis Recreandi"
  ],
  "stalhrim bow of expelling": [
    "Stalhrimis Arcus Expellendi"
  ],
  "nordic mace of binding": [
    "Germanica Maca Deligandi"
  ],
  "ash spawn began to appear on solstheim after the massive eruptions of red mountain early in the fourth era covered the southern part of the island in ash": [
    "Filii Cineris appareband post eruptionem Montis Rubri ad adinium Aetiatis Quartae"
  ],
  "their exact origins are unknown": [
    "Nemo originem eorum scit"
  ],
  "journal of a madman": [
    "Ephemerides Insani"
  ],
  "nordic helmet of major archery": [
    "Germanica Galea Cavata Maioris Sagittationis"
  ],
  "chitin helmet of waterbreathing": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Sub Aqua Respirandi"
  ],
  "stalhrim warhammer of nullifying": [
    "Stalhrimis Bellimalleus Nullificandi"
  ],
  "hallowed nordic warhammer": [
    "Sanctificatus Germanicus Bellimalleus"
  ],
  "stalhrim gauntlets of extreme wielding": [
    "Stalhrimis Manubria Extremis Dexteritatis"
  ],
  "bonemold boots of resist fire": [
    "Osseae Caligae Ignem Resistendi"
  ],
  "stalhrim war axe of freezing": [
    "Stalhrimis Bellisecuris Glaciei"
  ],
  "chitin helmet of eminent alteration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Eminentis Alterationis"
  ],
  "stalhrim bow of animus": [
    "Stalhrimis Arcus Animi"
  ],
  "bonemold helmet of major magicka": [
    "Ossea Galea Maioris Magicae"
  ],
  "nordic sword of despair": [
    "Germanicus Gladius Timoris"
  ],
  "chitin helmet of archery": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Sagittationis"
  ],
  "bonemold helmet of minor alchemy": [
    "Ossea Galea Minoris Alchemiae"
  ],
  "stalhrim mace of lethargy": [
    "Stalhrimis Maca Dormientis"
  ],
  "stalhrim dagger of evoking": [
    "Stalhrimis Pugio Evocandi"
  ],
  "blizzard": [
    "Hiems"
  ],
  "chitin boots of dwindling flames": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Graves Flammarum Diminuentium"
  ],
  "stalhrim mace of debilitation": [
    "Stalhrimis Maca Deminuendi"
  ],
  "chitin armor of alteration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Alterationis"
  ],
  "increases your enchanting skill by <mag> points": [
    "Peritia Incantandi <mag> puncta augetur"
  ],
  "stalhrim gauntlets of extreme smithing": [
    "Stalhrimis Manubria Extremis Faciendi"
  ],
  "stalhrim bow of winter": [
    "Stalhrimis Arcus Hiemis"
  ],
  "stalhrim battleaxe of winnowing": [
    "Stalhrimis Bellisecuris Evanescendi"
  ],
  "stalhrim dagger of lightning": [
    "Stalhrimis Pugio Fulminis"
  ],
  "bonemold gauntlets of major smithing": [
    "Ossea Manubria Maioris Faciendi"
  ],
  "spawns a spider that secretes a flammable oil when it feels threatened": [
    "Araneam vocat quod oleum flammabile fundit"
  ],
  "chitin helmet of major conjuring": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Maioris Vocandi"
  ],
  "stalhrim warhammer of the sorcerer": [
    "Stalhrimis Bellimalleus Thaumaturgos"
  ],
  "chitin helmet of major restoration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Maioris Recreandi"
  ],
  "stalhrim dagger of stunning": [
    "Stalhrimis Pugio Pausandi"
  ],
  "chitin boots of dwindling frost": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Graves Glaciei Diminuendi"
  ],
  "stalhrim boots of major sneaking": [
    "Stalhrimis Caligulae Maioris Rependi"
  ],
  "chitin bracers of archery": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Sagittationis"
  ],
  "bonemold gauntlets of major alchemy": [
    "Ossea Manubria Maioris Alchemiae"
  ],
  "when hit, this reanimated creature will explode for <50> frost damage": [
    "When hit, this reanimated creature will explode for <50> frost damage"
  ],
  "chitin armor of eminent health": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Eminentis Salutis"
  ],
  "stalhrim gauntlets of eminent wielding": [
    "Stalhrimis Manubria Eminentis Dexteritatis"
  ],
  "stalhrim war axe of nullifying": [
    "Stalhrimis Bellisecuris Nullificandi"
  ],
  "scribbles of a madman": [
    "Scripta Insani"
  ],
  "chitin armor of the eminent knight": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Eminentis Equitis"
  ],
  "nordic sword of the blaze": [
    "Germanicus Gladius Flagrantiae"
  ],
  "bonemold gauntlets of wielding": [
    "Ossea Manubria Dexteritatis"
  ],
  "nordic boots of eminent stamina": [
    "Germanicae Caligae Cavatae Eminentis Staminae"
  ],
  "fenzy rune - master of the mind": [
    "Fenzy Rune - Master of the Mind"
  ],
  "stalhrim armor of extreme health": [
    "Stalhrimis Lorica Levis Extremis Salutis"
  ],
  "chitin boots of hauling": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Hauling"
  ],
  "nordic dagger of stunning": [
    "Germanicus Pugio Pausandi"
  ],
  "nordic dagger of lethargy": [
    "Germanicus Pugio Dormientis"
  ],
  "what was left of him": [
    "Reliquiae eius"
  ],
  "seeker abilities": [
    "Seeker Abilities"
  ],
  "i found this letter": [
    "Hanc epistulam inveni"
  ],
  "nordic war axe of harrowing": [
    "Germanicus Bellisecuris Occandi"
  ],
  "chitin helmet of major alchemy": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Maioris Alchemiae"
  ],
  "nordic greatsword of depleting": [
    "Germanica Spatha Deplendi"
  ],
  "you wanted to speak to me, councilor": [
    "Me adloqui voluisti, concilliator"
  ],
  "hallowed stalhrim sword": [
    "Stalhrimis Gladius Sacer"
  ],
  "nordic dagger of scorching": [
    "Germanicus Pugio Incendendi"
  ],
  "stalhrim helmet of extreme alteration": [
    "Stalhrimis Galea Levis Extremis Alterationis"
  ],
  "i've defeated the draugr and located the missing miners": [
    "Draugr vici et cunicularios amissos inveni"
  ],
  "they're dead": [
    "Mortui sunt"
  ],
  "i know that name... i found this near an old shrine": [
    "Illud nomen scio... hoc prope aram antiquam inveni"
  ],
  "(give journal.)": [
    "(Da ephemeridem)"
  ],
  "miraak teleport": [
    "Miraak Teleport"
  ],
  "stalhrim armor of extreme destruction": [
    "Stalhrimis Lorica Levis Extremis Destructionis"
  ],
  "nordic shield": [
    "Germanicum Scutum Cavum"
  ],
  "nordic war axe of dread": [
    "Germanicus Bellisecuris Phobiae"
  ],
  "nordic war axe of lethargy": [
    "Germanicus Bellisecuris Dormientis"
  ],
  "shock touch": [
    "Shock Touch"
  ],
  "nordic dagger of reaping": [
    "Germanicus Pugio Decerpendi"
  ],
  "nordic greatsword of animus": [
    "Germanica Spatha Animi"
  ],
  "chitin shield of frost suppression": [
    "Chitonos Scutum Contra Vim Frigidum"
  ],
  "summon karstaag": [
    "Voca Karstaag"
  ],
  "stalhrim sword of nullifying": [
    "Stalhrimis Gladius Nullificandi"
  ],
  "stalhrim dagger of dread": [
    "Stalhrimis Pugio Phobiae"
  ],
  "bonemold gauntlets of minor alchemy": [
    "Ossea Manubria Minoris Alchemiae"
  ],
  "nordic bow of banishing": [
    "Germanicus Arcus Dimittendi"
  ],
  "alright then": [
    "Bene"
  ],
  "where did you learn these secrets": [
    "Ubi has secretas didicisti"
  ],
  "stalhrim greatsword of the sorcerer": [
    "Stalhrimis Spatha Thaumaturgos"
  ],
  "chitin helmet of major alteration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Maioris Alterationis"
  ],
  "stalhrim greatsword of immobilizing": [
    "Stalhrimis Spatha Conligandi"
  ],
  "nordic greatsword of scorching": [
    "Germanica Spatha Incendendi"
  ],
  "stalhrim armor of major health": [
    "Stalhrimis Lorica Levis Maioris Salutis"
  ],
  "nordic helmet of eminent alchemy": [
    "Germanica Galea Cavata Eminentis Alchemiae"
  ],
  "bonemold gauntlets of minor sure grip": [
    "Ossea Manubria Minoris Ambidexteritatis"
  ],
  "horksbane": [
    "Horksbane"
  ],
  "could you repeat that inscription": [
    "Potesne inscriptionem iterum reddere"
  ],
  "the doors of the spirit": [
    "Portae Animi"
  ],
  "doors of the spirit": [
    "Portae Animi"
  ],
  "nordic gauntlets of eminent archery": [
    "Germanica Manubria Cavata Eminentis Sagittationis"
  ],
  "chitin bracers of major lockpicking": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Maioris Clavicarii"
  ],
  "stalhrim boots of brawn": [
    "Stalhrimis Caligae Fortitudinis"
  ],
  "bonemold shield of resist shock": [
    "Osseum Scutum Electricitatem Resistendi"
  ],
  "holy stalhrim battleaxe": [
    "Sanctus Stalhrimis Bellisecuris"
  ],
  "where do you get your raw materials": [
    "Ubi materia tua invenis"
  ],
  "stalhrim boots of shock suppression": [
    "Stalhrimis Caligae Suppressionis Electricitatis"
  ],
  "nordic gauntlets of eminent alchemy": [
    "Germanica Manubria Cavata Eminentis Alchemiae"
  ],
  "virtuous stalhrim greatsword": [
    "Stalhrimis Spatha Virtutis"
  ],
  "stalhrim mace of animus": [
    "Stalhrimis Maca Animi"
  ],
  "bonemold shield of minor blocking": [
    "Osseum Scutum Minoris Interponendi"
  ],
  "stalhrim bow of nullifying": [
    "Stalhrimis Arcus Nullificandi"
  ],
  "bonemold boots of waning fire": [
    "Osseae Caligae Ignis Diminuentis"
  ],
  "chitin boots of shock suppression": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Suppressionis Electricitatis"
  ],
  "stalhrim helmet of major archery": [
    "Stalhrimis Galea Levis Maioris Sagittationis"
  ],
  "teleports mirrak to the player": [
    "Teleports Miraak to the Player"
  ],
  "chitin armor of major alteration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Maioris Alterationis"
  ],
  "nordic mace of debilitation": [
    "Germanica Maca Deminuendi"
  ],
  "stalhrim dagger of depleting": [
    "Stalhrimis Pugio Deplendi"
  ],
  "stalhrim armor of extreme illusion": [
    "Stalhrimis Lorica Extremis Inlusionis"
  ],
  "stalhrim greatsword of debilitation": [
    "Stalhrimis Spatha Deminuendi"
  ],
  "mireli": [
    "Mireli"
  ],
  "stalhrim battleaxe of lightning": [
    "Stalhrimis Bellisecuris Fulminis"
  ],
  "did he say what it was about": [
    "Dixitne de qua re ageretur"
  ],
  "give the note to captain veleth": [
    "Da epistulam Nauarchi Veleth"
  ],
  "bard holding cell": [
    "Bard Holding Cell"
  ],
  "nordic bow of stunning": [
    "Germanicus Arcus Pausandi"
  ],
  "bonemold shield of resist frost": [
    "Osseum Scutum Glaciem Resistendi"
  ],
  "recipe - exploding shock spider": [
    "Dictamen - Aranea Electricitatis Displodens"
  ],
  "reverent nordic dagger": [
    "Reverens Germanicus Pugio"
  ],
  "stalhrim dagger of subsuming": [
    "Stalhrimis Pugio Subsumendi"
  ],
  "nerevar at red mountain": [
    "Nerevar ad Montem Rubrum"
  ],
  "stalhrim helmet of extreme magicka": [
    "Stalhrimis Galea Extremis Magicae"
  ],
  "stalhrim armor of major illusion": [
    "Stalhrimis Lorica Levis Maioris Inlusionis"
  ],
  "bonemold helmet of major alchemy": [
    "Ossea Galea Maioris Alchemiae"
  ],
  "nordic mace of freezing": [
    "Germanica Maca Glaciei"
  ],
  "stalhrim bow of storms": [
    "Stalhrimis Arcus Tempestatium"
  ],
  "stalhrim war axe of immobilizing": [
    "Stalhrimis Bellisecuris Conligandi"
  ],
  "locate ralis": [
    "Inveni Ralem"
  ],
  "stalhrim shield of fire abatement": [
    "Stalhrimis Scutum Subsidentiae Ignis"
  ],
  "all dragonborn scrolls": [
    "All Dragonborn Scrolls"
  ],
  "stalhrim battleaxe of banishing": [
    "Stalhrimis Bellisecuris Dimittendi"
  ],
  "you want... 5000 septims": [
    "Vis... MMMMM Auri"
  ],
  "bonemold boots of lifting": [
    "Osseae Caligae Sumendi"
  ],
  "bonemold shield of waning magic": [
    "Osseum Scutum Magicae Diminuentis"
  ],
  "how are you getting along here": [
    "Quid agit tecum hic"
  ],
  "the poison song, book vii": [
    "Carmen Veneni, Liber VII"
  ],
  "poison song, book vii": [
    "Carmen Veneni, Liber VII"
  ],
  "nordic war axe of reaping": [
    "Germanicus Bellisecuris Decerpendi"
  ],
  "nordic warhammer of enervating": [
    "Germanicus Bellimalleus Emoliendi"
  ],
  "stalhrim dagger of nullifying": [
    "Stalhrimis Pugio Nullificandi"
  ],
  "nordic battleaxe of garnering": [
    "Germanicus Bellisecuris Bibendi"
  ],
  "for <dur> seconds, opponents in melee range take <15> points frost damage and stamina damage per second": [
    "<dur> secundas, inimici prope te <15> puncta vulneris glaciei staminaeque singulis secundis accipiunt"
  ],
  "netch calf": [
    "Netch Calf"
  ],
  "chitin helmet of illusion": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Inlusionis"
  ],
  "stalhrim battleaxe of winter": [
    "Stalhrimis Bellisecuris Hiemis"
  ],
  "vendil ulen's chest": [
    "Arcam Vendilis Ulenis"
  ],
  "tell me more about thirsk": [
    "Dic mihi plus de Thirsk"
  ],
  "chitin boots of waning shock": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Electricitatis Diminuentis"
  ],
  "nordic warhammer of diminishing": [
    "Germanicus Bellimalleus Dinimuendi"
  ],
  "chitin shield of shock suppression": [
    "Chitonos Scutum Suppressionis Electricitatis"
  ],
  "stalhrim war axe of winnowing": [
    "Stalhrimis Bellisecuris Evanescendi"
  ],
  "nordic warhammer of fear": [
    "Germanicus Bellimalleus Timoris"
  ],
  "skaal shaman": [
    "Skaal Shaman"
  ],
  "chitin boots of eminent sneaking": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Eminentis Rependi"
  ],
  "freeze": [
    "Glaciare",
    "Congelasce"
  ],
  "nordic war axe of malediction": [
    "Germanicus Bellisecuris Maledictionis"
  ],
  "nordic armor of eminent destruction": [
    "Germanica Lorica Cavata Eminentis Destructionis"
  ],
  "stalhrim shield of dwindling fire": [
    "Stalhrimis Scutum Flammarum Diminuentium"
  ],
  "stalhrim greatsword of thunderbolts": [
    "Stalhrimis Spatha Tonitruum"
  ],
  "stalhrim war axe of storms": [
    "Stalhrimis Bellisecuris Tempestatium"
  ],
  "have you known bujold a while": [
    "Multumne tempus Bujold novisti"
  ],
  "a blast of flame that sets the target on fire, doing <mag> damage per second for <15> seconds": [
    "Displosio flammarum quod inimicum ardere cogit et <mag> vulneris singulas secundas facit <15> secundas"
  ],
  "blast of flame that sets the target on fire, doing <mag> damage per second for <15> seconds": [
    "Displosio flammarum quod inimicum ardere cogit et <mag> vulneris singulas secundas facit <15> secundas"
  ],
  "nordic greatsword of immobilizing": [
    "Germanica Spatha Conligandi"
  ],
  "chitin armor of health": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Salutis"
  ],
  "stalhrim armor of eminent alteration": [
    "Stalhrimis Lorica Levis Eminentis Alterationis"
  ],
  "nordic boots of fire suppression": [
    "Germanicae Caligae Cavatae Suppressionis Ignis"
  ],
  "bonemold helmet of major destruction": [
    "Ossea Galea Maioris Destructionis"
  ],
  "stalhrim war axe of dread": [
    "Stalhrimis Bellisecuris Phobiae"
  ],
  "nordic greatsword of malediction": [
    "Germanica Spatha Maledictionis"
  ],
  "nordic warhammer of ice": [
    "Germanicus Bellimalleus Glaciei"
  ],
  "stalhrim sword of thunderbolts": [
    "Stalhrimis Gladius Tonitruum"
  ],
  "reverent nordic war axe": [
    "Reverens Germanicus Bellisecuris"
  ],
  "whoa": [
    "Whoa"
  ],
  "not so close": [
    "Non tam prope me"
  ],
  "bonemold boots of waning frost": [
    "Osseae Caligae Glaciei Diminuentis"
  ],
  "stalhrim mace of stunning": [
    "Stalhrimis Maca Pausandi"
  ],
  "stalhrim war axe of annihilating": [
    "Stalhrimis Bellisecuris Annihilandi"
  ],
  "nordic battleaxe of dread": [
    "Germanicus Bellisecuris Phobiae"
  ],
  "nordic sword of depleting": [
    "Germanicus Gladius Deplendi"
  ],
  "nordic gauntlets of eminent wielding": [
    "Germanica Manubria Cavata Eminentis Dexteritatis"
  ],
  "bonemold gauntlets of minor conjuring": [
    "Ossea Manubria Minoris Vocandi"
  ],
  "stalhrim boots of eminent stamina": [
    "Stalhrimis Caligulae Eminentis Staminae"
  ],
  "absorb stamina": [
    "Absorbe Staminam"
  ],
  "stalhrim helmet of eminent alchemy": [
    "Stalhrimis Galea Eminentis Alchemiae"
  ],
  "stalhrim boots of dwindling shock": [
    "Stalhrimis Caligulae Electricitatis Diminuendi"
  ],
  "stalhrim bow of depleting": [
    "Stalhrimis Arcus Deplendi"
  ],
  "chitin armor of major illusion": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Maioris Inlusionis"
  ],
  "nordic mace of animus": [
    "Germanica Maca Animi"
  ],
  "bonemold boots of minor sneaking": [
    "Osseae Caligae Minoris Rependi"
  ],
  "speak to ralis": [
    "Adloquere Rali"
  ],
  "chitin bracers of major alchemy": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Maioris Alchemiae"
  ],
  "stalhrim sword of dread": [
    "Stalhrimis Gladius Phobiae"
  ],
  "bonemold gauntlets of major deft hands": [
    "Ossea Manubria Maioris Furis"
  ],
  "nordic dagger of consuming": [
    "Germanicus Pugio Consumendi"
  ],
  "nordic bow of dread": [
    "Germanicus Arcus Phobiae"
  ],
  "stalhrim warhammer of damnation": [
    "Stalhrimis Bellimalleus Damnationis"
  ],
  "ildari's journal, vol. i": [
    "Ephemerides Ildaris, Vol. I"
  ],
  "chitin bracers of major sure grip": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Maioris Ambidexteritatis"
  ],
  "nordic boots of frost abatement": [
    "Germanicae Caligae Cavatae Minoris Frigoris"
  ],
  "nordic sword of torpor": [
    "Germanicus Gladius Torporis"
  ],
  "stalhrim battleaxe of lethargy": [
    "Stalhrimis Bellisecuris Dormientis"
  ],
  "hrodulf's journal": [
    "Ephemerides Hrodulfis"
  ],
  "high seeker": [
    "Quaestor Altus"
  ],
  "chitin boots of eminent stamina": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Eminentis Staminae"
  ],
  "stalhrim warhammer of debilitation": [
    "Stalhrimis Bellimalleus Deminuendi"
  ],
  "nordic sword of shocks": [
    "Germanicus Gladius Electricitatis"
  ],
  "nordic battleaxe of the blaze": [
    "Germanicus Bellisecuris Flagrantiae"
  ],
  "the true noble's code": [
    "Ius Verum Nobilium"
  ],
  "true noble's code": [
    "Ius Verum Nobilium"
  ],
  "nordic armor of eminent conjuring": [
    "Germanica Lorica Cavata Eminentis Vocandi"
  ],
  "nordic war axe of freezing": [
    "Germanicus Bellisecuris Glaciei"
  ],
  "stalhrim armor of major conjuring": [
    "Stalhrimis Lorica Levis Maioris Vocandi"
  ],
  "find a way deeper into the barrow": [
    "Inveni viam ad partes altiores Pabillionis"
  ],
  "stalhrim gauntlets of major smithing": [
    "Stalhrimis Manubria Levia Maioris Faciendi"
  ],
  "fund the excavation of kolbjorn barrow (1000 gold)": [
    "Pecuniam ad excavatum Pabillo Kolbjornis solve (M Auri)"
  ],
  "ildari's journal": [
    "Ephemerids Ildaris"
  ],
  "stalhrim sword of immobilizing": [
    "Stalhrimis Gladius Conligandi"
  ],
  "why not hire some of the miners out of raven rock": [
    "Quidni cunicularios ex urbe Corvisaxo conducas"
  ],
  "stalhrim boots of the mammoth": [
    "Stalhrimis Caligae Elephantis"
  ],
  "stalhrim battleaxe of animus": [
    "Stalhrimis Bellisecuris Animi"
  ],
  "chitin armor of conjuring": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Vocandi"
  ],
  "stalhrim dagger of blizzards": [
    "Stalhrimis Pugio Hiemis"
  ],
  "stalhrim sword of malediction": [
    "Stalhrimis Gladius Maledictionis"
  ],
  "confessions of a dunmer skooma eater": [
    "Confessiones Consumptoris Skoomae Atralfi"
  ],
  "bonemold boots of strength": [
    "Osseae Caligae Fortitudinis"
  ],
  "chitin armor of the major knight": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Maioris Equitis"
  ],
  "nordic warhammer of devouring": [
    "Germanicus Bellimalleus Consumendi"
  ],
  "while charging, releases two spectral dragons from the player": [
    "While charging, releases two Spectral Dragons from the player"
  ],
  "releases an additional spectral dragon for every 5 dragon souls": [
    "Releases an additional Spectral Dragon for every 5 Dragon Souls"
  ],
  "all dragonborn misc items": [
    "All Dragonborn Misc Items"
  ],
  "stalhrim shield of dwindling shock": [
    "Stalhrimis Scutum Electricitatis Diminuendi"
  ],
  "nordic mace of harvesting": [
    "Germanica Maca Saturni"
  ],
  "chitin bracers of eminent alchemy": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Eminentis Alchemiae"
  ],
  "nordic battleaxe of freezing": [
    "Germanicus Bellisecuris Glaciei"
  ],
  "stalhrim bow of thunderbolts": [
    "Stalhrimis Arcus Tonitruum"
  ],
  "nordic sword of evoking": [
    "Germanicus Gladius Evocandi"
  ],
  "nordic war axe of thunderbolts": [
    "Germanicus Bellisecuris Tonitruum"
  ],
  "stalhrim greatsword of malediction": [
    "Stalhrimis Spatha Maledictionis"
  ],
  "nordic sword of dread": [
    "Germanicus Gladius Phobiae"
  ],
  "stalhrim warhammer of thunderbolts": [
    "Stalhrimis Bellimalleus Tonitruum"
  ],
  "bonemold gauntlets of major wielding": [
    "Ossea Manubria Maioris Dexteritatis"
  ],
  "stalhrim armor of remedy": [
    "Stalhrimis Lorica Levis Remedy"
  ],
  "stalhrim mace of devouring": [
    "Stalhrimis Maca Consumendi"
  ],
  "stalhrim dagger of the sorcerer": [
    "Stalhrimis Pugio Thaumaturgos"
  ],
  "stalhrim mace of damnation": [
    "Stalhrimis Maca Damnationis"
  ],
  "opponents in melee range take <mag> points frost damage per second": [
    "Inimici prope te <mag> puncta vulneris glaciei singulis secundis accipiunt"
  ],
  "nordic helmet of major magicka": [
    "Germanica Galea Cavata Maioris Magicae"
  ],
  "glowing spider": [
    "Aranea Lucis"
  ],
  "lord tusk": [
    "Dominus Dens"
  ],
  "nordic gauntlets of extreme smithing": [
    "Germanica Manubria Cavata Extremis Faciendi"
  ],
  "stalhrim armor of extreme restoration": [
    "Stalhrimis Lorica Extremis Recreandi"
  ],
  "look for wulf's brother torkild": [
    "Quaere Fratrem Wulfis, Torkild"
  ],
  "nordic shield of eminent blocking": [
    "Germanicum Scutum Cavum Eminentis Interponendi"
  ],
  "sapphire geode": [
    "Geodes Sapphiri"
  ],
  "stalhrim battleaxe of dread": [
    "Stalhrimis Bellisecuris Phobiae"
  ],
  "stalhrim armor of regeneration": [
    "Stalhrimis Lorica Regenerationis"
  ],
  "nordic boots of frost suppression": [
    "Germanicae Caligae Cavatae Contra Vim Frigidum"
  ],
  "nordic gauntlets of eminent smithing": [
    "Germanica Manubria Cavata Eminentis Faciendi"
  ],
  "chitin boots of stamina": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Staminae"
  ],
  "centurion dynamo core required": [
    "Cor Dynamis Centorionis poscitur"
  ],
  "stalhrim sword of garnering": [
    "Stalhrimis Gladius Bibendi"
  ],
  "nordic armor of eminent alteration": [
    "Germanica Lorica Cavata Eminentis Alterationis"
  ],
  "stalhrim helmet of peerless conjuring": [
    "Stalhrimis Galea Optimi Vocandi"
  ],
  "stalhrim armor of peerless health": [
    "Stalhrimis Lorica Optimae Salutis"
  ],
  "stalhrim war axe of garnering": [
    "Stalhrimis Bellisecuris Bibendi"
  ],
  "nordic warhammer of leeching": [
    "Germanicus Bellimalleus Sanguisuginis"
  ],
  "i have a letter from your brother": [
    "Epistulam a fratre tuo scriptam habeo"
  ],
  "nordic war axe of despair": [
    "Germanicus Bellisecuris Timoris"
  ],
  "stalhrim mace of ultimate chaos": [
    "Stalhrimis Maca Chaos Ultimi"
  ],
  "nordic shield of magic abatement": [
    "Germanicum Scutum Cavum Magicae Subsidentis"
  ],
  "stalhrim greatsword of exhaustion": [
    "Stalhrimis Spatha Exhaustionis"
  ],
  "stalhrim mace of evoking": [
    "Stalhrimis Maca Evocandi"
  ],
  "stalhrim war axe of the vampire": [
    "Stalhrimis Bellisecuris Vampiri"
  ],
  "chitin shield of waning frost": [
    "Chitonos Scutum Glaciei Diminuentis"
  ],
  "all dragonborn ingredients": [
    "All Dragonborn Ingredients"
  ],
  "nordic warhammer of binding": [
    "Germanicus Bellimalleus Deligandi"
  ],
  "i've got some money": [
    "Paulum pecuniae habeo"
  ],
  "are you looking for a partner": [
    "Patronumne quaeris"
  ],
  "nordic sword of harvesting": [
    "Germanicus Gladius Saturni"
  ],
  "nordic mace of the blaze": [
    "Germanica Maca Flagrantiae"
  ],
  "nordic greatsword of enervating": [
    "Germanica Spatha Emoliendi"
  ],
  "etched tablet": [
    "Tabella Inscripta",
    "Tabellum Inscriptum"
  ],
  "nordic warhammer of evoking": [
    "Germanicus Bellimalleus Evocandi"
  ],
  "nordic greatsword of shocks": [
    "Germanica Spatha Electricitatis"
  ],
  "kolbjorn guard": [
    "Kolbjorn Custos"
  ],
  "nordic armor of extreme restoration": [
    "Germanica Lorica Cavata Extremis Recreandi"
  ],
  "nordic battleaxe of depleting": [
    "Germanicus Bellisecuris Deplendi"
  ],
  "nordic boots of shock suppression": [
    "Germanicae Caligae Cavatae Suppressionis Electricitatis"
  ],
  "stalhrim war axe of damnation": [
    "Stalhrimis Bellisecuris Damnationis"
  ],
  "the five far stars": [
    "Quinque Stellae Remotae"
  ],
  "five far stars": [
    "Quinque Stellae Remotae"
  ],
  "stalhrim bow of immobilizing": [
    "Stalhrimis Arcus Conligandi"
  ],
  "hallowed nordic battleaxe": [
    "Sanctificatus Germanicus Bellisecuris"
  ],
  "nordic sword of fire": [
    "Germanicus Gladius Ignis"
  ],
  "stalhrim dagger of ultimate chaos": [
    "Stalhrimis Pugio Chaos Ultimi"
  ],
  "spawns a spider that can be used to haul items. only one can be spawned at once": [
    "Araneam vocat quae res tuas portare potest"
  ],
  "chitin bracers of major wielding": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Maioris Dexteritatis"
  ],
  "stalhrim dagger of malediction": [
    "Stalhrimis Pugio Maledictionis"
  ],
  "stalhrim source": [
    "Fons Stalhrimis"
  ],
  "nordic bow of freezing": [
    "Germanicus Arcus Glaciei"
  ],
  "tempered sphere": [
    "Sphera Temperata"
  ],
  "holy stalhrim warhammer": [
    "Sanctus Stalhrimis Bellimalleus"
  ],
  "virtuous stalhrim dagger": [
    "Stalhrimis Pugio Virtutis"
  ],
  "nordic mace of devouring": [
    "Germanica Maca Consumendi"
  ],
  "nordic dagger of garnering": [
    "Germanicus Pugio Bibendi"
  ],
  "nordic mace of lightning": [
    "Germanica Maca Fulminis"
  ],
  "stalhrim boots of frost abatement": [
    "Stalhrimis Caligae Minoris Frigoris"
  ],
  "nordic dagger of banishing": [
    "Germanicus Pugio Dimittendi"
  ],
  "nordic mace of banishing": [
    "Germanica Maca Dimittendi"
  ],
  "nordic gauntlets of extreme archery": [
    "Germanica Manubria Cavata Extremis Sagittationis"
  ],
  "amethyst geode": [
    "Geodes Amethysti"
  ],
  "nordic greatsword of binding": [
    "Germanica Spatha Deligandi"
  ],
  "stalhrim shield": [
    "Stalhrimis Scutum"
  ],
  "chitin helmet of eminent archery": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Eminentis Sagittationis"
  ],
  "all dragonborn armor": [
    "All Dragonborn Armour"
  ],
  "nordic war axe of debilitation": [
    "Germanicus Bellisecuris Deminuendi"
  ],
  "cast on the ground, it creates an area of fire that does <50> points of fire damage per second": [
    "Fac in solum, facit parietem ignis quod <50> puncta vulneris electricitatis singulas secundas facit"
  ],
  "nordic battleaxe of thunderbolts": [
    "Germanicus Bellisecuris Tonitruum"
  ],
  "hallowed nordic mace": [
    "Sanctificata Germanica Maca"
  ],
  "chitin helmet of eminent alchemy": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Eminentis Alchemiae"
  ],
  "nordic bow of shocks": [
    "Germanicus Arcus Electricitatis"
  ],
  "stalhrim gauntlets of peerless alchemy": [
    "Stalhrimis Manubria Optimae Alchemiae"
  ],
  "nordic helmet of major conjuring": [
    "Germanica Galea Cavata Maioris Vocandi"
  ],
  "whiterun jail key": [
    "Albacursus Carcer Clavis",
    "Clavis ad Carcerem Albacursus"
  ],
  "stalhrim arrow": [
    "Sagitta Stalhrimis"
  ],
  "nordic armor of eminent health": [
    "Germanica Lorica Cavata Eminentis Salutis"
  ],
  "nordic dagger of fire": [
    "Germanicus Pugio Ignis"
  ],
  "virtuous nordic dagger": [
    "Germanicus Pugio Virtutis"
  ],
  "netchcalfskin": [
    "Pellis Vitulae Netch"
  ],
  "the anticipations": [
    "Anticipationes"
  ],
  "anticipations": [
    "Anticipationes"
  ],
  "stalhrim warhammer of enervating": [
    "Stalhrimis Bellimalleus Emoliendi"
  ],
  "one-handed and two-handed weapon damage is <mag>% lower": [
    "Arma manualia <mag>% minus vulneris faciunt"
  ],
  "he lives in a giant mushroom": [
    "In boleto magno vivit"
  ],
  "stalhrim warhammer of blizzards": [
    "Stalhrimis Bellimalleus Hiemis"
  ],
  "all the draugr are dead": [
    "Omnes Draugr mortui sunt"
  ],
  "stalhrim enchanting": [
    "Incantamenta Stalhrimis"
  ],
  "stalhrim sword of ultimate chaos": [
    "Stalhrimis Gladius Chaos Ultimi"
  ],
  "stalhrim boots of eminent sneaking": [
    "Stalhrimis Caligulae Eminentis Rependi"
  ],
  "stalhrim mace of exhaustion": [
    "Stalhrimis Maca Exhaustionis"
  ],
  "pack spider": [
    "Aranea Portandi"
  ],
  "i found a way past the dead end": [
    "Viam trans partem clausam inveni"
  ],
  "stalhrim armor of eminent destruction": [
    "Stalhrimis Lorica Levis Eminentis Destructionis"
  ],
  "chitin helmet of major destruction": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Maioris Destructionis"
  ],
  "stalhrim dagger of storms": [
    "Stalhrimis Pugio Tempestatium"
  ],
  "return to kolbjorn barrow": [
    "Reveni ad Pabilionem Kolbjornis"
  ],
  "nordic mace of enervating": [
    "Germanica Maca Emoliendi"
  ],
  "stalhrim helmet of peerless restoration": [
    "Stalhrimis Galea Optimi Recreandi"
  ],
  "stalhrim mace of harvesting": [
    "Stalhrimis Maca Saturni"
  ],
  "shock barrier": [
    "Paries Electricitatis"
  ],
  "nordic dagger of animus": [
    "Germanicus Pugio Animi"
  ],
  "nordic bow of fear": [
    "Germanicus Arcus Timoris"
  ],
  "fund the next phase of the excavation (3000 gold)": [
    "Pecuniam solve pro parte proxima excavati (MMM Auri)"
  ],
  "nordic battleaxe of harvesting": [
    "Germanicus Bellisecuris Saturni"
  ],
  "nordic gauntlets of extreme sure grip": [
    "Germanica Manubria Cavata Extremis Ambidexteritatis"
  ],
  "nordic dagger of fear": [
    "Germanicus Pugio Timoris"
  ],
  "thunderbolt": [
    "Fulmen",
    "Tonitrus"
  ],
  "bradyn": [
    "Bradyn"
  ],
  "stalhrim dagger of winter": [
    "Stalhrimis Pugio Hiemis"
  ],
  "stalhrim warhammer of extreme chaos": [
    "Stalhrimis Bellimalleus Chaos Extremis"
  ],
  "chitin armor of eminent illusion": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Eminentis Inlusionis"
  ],
  "stalhrim dagger of garnering": [
    "Stalhrimis Pugio Bibendi"
  ],
  "nordic helmet of eminent conjuring": [
    "Germanica Galea Cavata Eminentis Vocandi"
  ],
  "hallowed nordic dagger": [
    "Sanctificatus Germanicus Pugio"
  ],
  "stalhrim gauntlets of peerless sure grip": [
    "Stalhrimis Manubria Optimae Ambidexteritatis"
  ],
  "nordic battleaxe of binding": [
    "Germanicus Bellisecuris Deligandi"
  ],
  "stalhrim boots of fire suppression": [
    "Stalhrimis Caligulae Suppressionis Ignis"
  ],
  "stalhrim helmet of extreme illusion": [
    "Stalhrimis Galea Levis Extremis Inlusionis"
  ],
  "stalhrim armor of recuperation": [
    "Stalhrimis Lorica Levis Recuperation"
  ],
  "steamed": [
    "Steamed"
  ],
  "stalhrim gauntlets of extreme sure grip": [
    "Stalhrimis Manubria Extremis Ambidexteritatis"
  ],
  "stalhrim greatsword of lethargy": [
    "Stalhrimis Spatha Dormientis"
  ],
  "stalhrim bow": [
    "Stalhrimis Arcus"
  ],
  "nordic war axe of depleting": [
    "Germanicus Bellisecuris Deplendi"
  ],
  "nordic helmet of extreme archery": [
    "Germanica Galea Cavata Extremis Sagittationis"
  ],
  "what happened to your brother": [
    "Quid fratri tuo accidit"
  ],
  "bonemold boots of dwindling flames": [
    "Osseae Caligae Flammarum Diminuentium"
  ],
  "ring of the moon": [
    "Anulus Lunae"
  ],
  "chitin bracers of major deft hands": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Maioris Furis"
  ],
  "stalhrim mace of petrifying": [
    "Stalhrimis Maca Lapidefaciendi"
  ],
  "torkild's letter to wulf": [
    "Epistula Torkildis ad Wulf"
  ],
  "stalhrim armor of the eminent knight": [
    "Stalhrimis Lorica Eminentis Equitis"
  ],
  "nordic battleaxe of animus": [
    "Germanicus Bellisecuris Animi"
  ],
  "stalhrim boots of frost suppression": [
    "Stalhrimis Caligae Contra Vim Frigidum"
  ],
  "the bandits won't be a problem anymore": [
    "Latrones problema non iam erint"
  ],
  "bandits won't be a problem anymore": [
    "Latrones problema non iam erint"
  ],
  "bonemold gauntlets of smithing": [
    "Ossea Manubria Faciendi"
  ],
  "chitin bracers of sure grip": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Ambidexteritatis"
  ],
  "stalhrim dagger of the vampire": [
    "Stalhrimis Pugio Vampiri"
  ],
  "ahzidal's necromancy": [
    "Ahzidalis Necromanticum"
  ],
  "nordic mace of blizzards": [
    "Germanica Maca Hiemis"
  ],
  "nordic dagger of diminishing": [
    "Germanicus Pugio Dinimuendi"
  ],
  "bonemold gauntlets of deft hands": [
    "Ossea Manubria Furis"
  ],
  "bonemold gauntlets of lockpicking": [
    "Ossea Manubria Clavicarii"
  ],
  "stalhrim battleaxe of high chaos": [
    "Stalhrimis Bellisecuris Chaos Magni"
  ],
  "nordic warhammer of lethargy": [
    "Germanicus Bellimalleus Dormientis"
  ],
  "stalhrim dagger of terror": [
    "Stalhrimis Pugio Terroris"
  ],
  "nordic bow of thunderbolts": [
    "Germanicus Arcus Tonitruum"
  ],
  "stalhrim bow of freezing": [
    "Stalhrimis Arcus Glaciei"
  ],
  "varieties of faith in the empire": [
    "Varietates Fidei in Imperio"
  ],
  "stalhrim helmet of eminent illusion": [
    "Stalhrimis Galea Eminentis Inlusionis"
  ],
  "i'll head there immediately": [
    "Illic statim petam"
  ],
  "enemies who strike you with a melee attack have a small chance of being paralyzed": [
    "Inimici quos te manu oppugnant forte paralyticum fiant"
  ],
  "a spike of ice that does <mag> points of frost damage to health and stamina and slows the target for <15> seconds": [
    "Hamus Glaciei quod <mag> puncta vulneris glaciei Saluti Staminaeque facit it inimico <15> secundas obstat"
  ],
  "spike of ice that does <mag> points of frost damage to health and stamina and slows the target for <15> seconds": [
    "Hamus Glaciei quod <mag> puncta vulneris glaciei Saluti Staminaeque facit it inimico <15> secundas obstat"
  ],
  "nordic bow of fire": [
    "Germanicus Arcus Ignis"
  ],
  "nordic dagger of the blaze": [
    "Germanicus Pugio Flagrantiae"
  ],
  "slow down there": [
    "Lentius, quaeso"
  ],
  "what are you going on about": [
    "De qua re loqueris"
  ],
  "stalhrim greatsword of harvesting": [
    "Stalhrimis Spatha Saturni"
  ],
  "nordic warhammer of consuming": [
    "Germanicus Bellimalleus Consumendi"
  ],
  "stalhrim warhammer of storms": [
    "Stalhrimis Bellimalleus Tempestatium"
  ],
  "all dragonborn potions": [
    "All Dragonborn Potions"
  ],
  "search general carius's remains": [
    "Investiga vestigia Imperatoris Carii"
  ],
  "storm flow": [
    "Storm Flow"
  ],
  "your frost breath shout encases foes in ice for <15> seconds": [
    "Exhalatio Glaciei inimicos glacie tegit <15> seconds"
  ],
  "chitin armor of eminent alteration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Eminentis Alterationis"
  ],
  "aggro cloak dmg": [
    "Aggro Cloak DMG"
  ],
  "nordic war axe of shocks": [
    "Germanicus Bellisecuris Electricitatis"
  ],
  "nordic mace of torpor": [
    "Germanica Maca Torporis"
  ],
  "saden": [
    "Saden"
  ],
  "chitin helmet of alteration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Alterationis"
  ],
  "chitin helmet of major archery": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Maioris Sagittationis"
  ],
  "nordic mace of ice": [
    "Germanica Maca Glaciei"
  ],
  "nordic bow": [
    "Germanicus Arcus"
  ],
  "ice wraith fx ability": [
    "Ice Wraith FX Ars",
    "Ice Wraith FX Ability"
  ],
  "recipe - pack spider": [
    "Dictamen - Aranea Portandi"
  ],
  "stalhrim dagger of harvesting": [
    "Stalhrimis Pugio Saturni"
  ],
  "nordic greatsword of reaping": [
    "Germanica Spatha Decerpendi"
  ],
  "stalhrim bow of dread": [
    "Stalhrimis Arcus Phobiae"
  ],
  "stalhrim mace of malediction": [
    "Stalhrimis Maca Maledictionis"
  ],
  "returns miraak": [
    "Returns Miraak"
  ],
  "(2000 gold)": [
    "(MM Auri)"
  ],
  "what were you arguing with crescius about": [
    "De qua re cum Crescio argutus es"
  ],
  "miner": [
    "Miner",
    "Cunicularia"
  ],
  "nordic battleaxe of diminishing": [
    "Germanicus Bellisecuris Dinimuendi"
  ],
  "nordic mace of reaping": [
    "Germanica Maca Decerpendi"
  ],
  "stalhrim sword of enervating": [
    "Stalhrimis Gladius Emoliendi"
  ],
  "chitin shield of waning fire": [
    "Chitonos Scutum Ignis Diminuentis"
  ],
  "clear the draugr from kolbjorn barrow": [
    "Vacua Draugr e Pabilione Kolbjornis"
  ],
  "stalhrim battleaxe of storms": [
    "Stalhrimis Bellisecuris Tempestatium"
  ],
  "lurkers are thought to be native to the murky waters of hermaeus mora's realm of apocrypha": [
    "Spectators incolae aquarum mundi Hermaei Morae, Apocryphae sunt"
  ],
  "nordic shield of shock abatement": [
    "Germanicum Scutum Cavum Subsidentiae Electricitatis"
  ],
  "chitin bracers of eminent sure grip": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Eminentis Ambidexteritatis"
  ],
  "chitin armor of the knight": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Equitis"
  ],
  "nordic armor of the major knight": [
    "Germanica Lorica Cavata Maioris Equitis"
  ],
  "ash spray": [
    "Ash Spray"
  ],
  "nordic battleaxe of ice": [
    "Germanicus Bellisecuris Glaciei"
  ],
  "chitin helmet of destruction": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Destructionis"
  ],
  "stalhrim greatsword of animus": [
    "Stalhrimis Spatha Animi"
  ],
  "the house of troubles": [
    "Domus Difficultatium"
  ],
  "house of troubles": [
    "Domus Difficultatium"
  ],
  "just tell me if you know him": [
    "Modo dic mihi si eum novisse"
  ],
  "aren't you his apprentice": [
    "Nonne discipulus eius es"
  ],
  "he must have taught you his secrets": [
    "Ille certe secreta sua docuit"
  ],
  "frost strike": [
    "Frost Strike"
  ],
  "stalhrim greatsword of terror": [
    "Stalhrimis Spatha Terroris"
  ],
  "karstaag's frost cloak": [
    "Karstaag's Frost Cloak"
  ],
  "stalhrim warhammer of lightning": [
    "Stalhrimis Bellimalleus Fulminis"
  ],
  "stalhrim gauntlets of major sure grip": [
    "Stalhrimis Manubria Levia Maioris Ambidexteritatis"
  ],
  "nordic boots of shock abatement": [
    "Germanicae Caligae Cavatae Subsidentiae Electricitatis"
  ],
  "virtuous stalhrim warhammer": [
    "Stalhrimis Bellimalleus Virtutis"
  ],
  "chitin shield of blocking": [
    "Chitonos Scutum Interponendi"
  ],
  "nordic bow of despair": [
    "Germanicus Arcus Timoris"
  ],
  "nordic warhammer of winnowing": [
    "Germanicus Bellimalleus Evanescendi"
  ],
  "i have some information about fort frostmoth": [
    "Informatum de Castello Glacipapilionis habeo"
  ],
  "stalhrim war axe of evoking": [
    "Stalhrimis Bellisecuris Evocandi"
  ],
  "recipe - jumping shock spider 2x": [
    "Dictamen - Aranea Electricitatis Saltans 2x"
  ],
  "nordic sword of winnowing": [
    "Germanicus Gladius Evanescendi"
  ],
  "dragonstormbodyartholder": [
    "DragonStormBodyArtHolder"
  ],
  "morag tong assassin": [
    "Morag Tong Sicarius"
  ],
  "nordic shield of magic suppression": [
    "Germanicum Scutum Cavum Suppressionis Magicaeis"
  ],
  "stalhrim sword of lightning": [
    "Stalhrimis Gladius Fulminis"
  ],
  "nordic battleaxe of fear": [
    "Germanicus Bellisecuris Timoris"
  ],
  "recipe - exploding frost spider 2x": [
    "Dictamen - Aranea Glaciei Displodens 2x"
  ],
  "hallowed stalhrim war axe": [
    "Stalhrimis Bellisecuris Sacer"
  ],
  "recipe - shock cloaked spider 2x": [
    "Dictamen - Aranea Electricitati Amicta 2x"
  ],
  "nordic warhammer of depleting": [
    "Germanicus Bellimalleus Deplendi"
  ],
  "stalhrim boots of the ox": [
    "Stalhrimis Caligae Bovis"
  ],
  "nordic mace of expelling": [
    "Germanica Maca Expellendi"
  ],
  "stalhrim war axe of expelling": [
    "Stalhrimis Bellisecuris Expellendi"
  ],
  "nordic greatsword of banishing": [
    "Germanica Spatha Dimittendi"
  ],
  "stalhrim sword of lethargy": [
    "Stalhrimis Gladius Dormientis"
  ],
  "nordic mace of harrowing": [
    "Germanica Maca Occandi"
  ],
  "stalhrim helmet of replenishing": [
    "Stalhrimis Galea Levis Replendi"
  ],
  "stalhrim armor of peerless illusion": [
    "Stalhrimis Lorica Optimae Inlusionis"
  ],
  "nordic gauntlets of major smithing": [
    "Germanica Manubria Cavata Maioris Faciendi"
  ],
  "stalhrim boots of shock abatement": [
    "Stalhrimis Caligae Subsidentiae Electricitatis"
  ],
  "hallowed nordic war axe": [
    "Sanctificatus Germanicus Bellisecuris"
  ],
  "nordic shield of extreme blocking": [
    "Germanicum Scutum Cavum Extremis Interponendi"
  ],
  "albino spider egg": [
    "Ova Araneae Albinae"
  ],
  "nordic warhammer of the blaze": [
    "Germanicus Bellimalleus Flagrantiae"
  ],
  "nordic dagger of enervating": [
    "Germanicus Pugio Emoliendi"
  ],
  "nordic sword of diminishing": [
    "Germanicus Gladius Dinimuendi"
  ],
  "nordic battleaxe of despair": [
    "Germanicus Bellisecuris Timoris"
  ],
  "nordic sword of garnering": [
    "Germanicus Gladius Bibendi"
  ],
  "oh": [
    "O",
    "Oh"
  ],
  "nordic greatsword of stunning": [
    "Germanica Spatha Pausandi"
  ],
  "fire barrier": [
    "Paries Ignis"
  ],
  "nordic warhammer of animus": [
    "Germanicus Bellimalleus Animi"
  ],
  "did you need something from me": [
    "Aliquidne ex me vis"
  ],
  "bonemold shield of waning fire": [
    "Osseum Scutum Ignis Diminuentis"
  ],
  "ahzidal's genius": [
    "Ahzidalis Ingenium"
  ],
  "stalhrim dagger of petrifying": [
    "Stalhrimis Pugio Lapidefaciendi"
  ],
  "are you the one who makes the mead": [
    "Esne ille qui hydromellum facit"
  ],
  "morag tong boots": [
    "Morag Tong Caligae"
  ],
  "nordic war axe of fire": [
    "Germanicus Bellisecuris Ignis"
  ],
  "stalhrim shield of shock suppression": [
    "Stalhrimis Scutum Suppressionis Electricitatis"
  ],
  "are you a warrior": [
    "Esne bellator"
  ],
  "nordic sword of scorching": [
    "Germanicus Gladius Incendendi"
  ],
  "spider fire": [
    "Spider Fire"
  ],
  "nordic boots of muffling": [
    "Germanicae Caligae Cavatae Silentii"
  ],
  "stalhrim shield of frost suppression": [
    "Stalhrimis Scutum Contra Vim Frigidum"
  ],
  "nordic war axe of the blaze": [
    "Germanicus Bellisecuris Flagrantiae"
  ],
  "stalhrim battleaxe of thunderbolts": [
    "Stalhrimis Bellisecuris Tonitruum"
  ],
  "treasure room key": [
    "Thesaurus Clavis"
  ],
  "stalhrim boots of warmth": [
    "Stalhrimis Caligae Caloris"
  ],
  "stalhrim warhammer of immobilizing": [
    "Stalhrimis Bellimalleus Conligandi"
  ],
  "stalhrim warhammer of winter": [
    "Stalhrimis Bellimalleus Hiemis"
  ],
  "chitin helmet of eminent illusion": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Eminentis Inlusionis"
  ],
  "stalhrim dagger of despair": [
    "Stalhrimis Pugio Timoris"
  ],
  "stalhrim greatsword of blizzards": [
    "Stalhrimis Spatha Hiemis"
  ],
  "summons an ice wraith for <dur> seconds wherever the caster is pointing": [
    "Phantasmam Glaciei <dur> secundas vocat"
  ],
  "bonemold boots of major sneaking": [
    "Osseae Caligae Maioris Rependi"
  ],
  "nordic gauntlets of extreme alchemy": [
    "Germanica Manubria Cavata Extremis Alchemiae"
  ],
  "chitin boots of waning fire": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Graves Ignis Diminuentis"
  ],
  "nordic boots of dwindling shock": [
    "Germanicae Caligae Cavatae Electricitatis Diminuendi"
  ],
  "target takes <mag> points of fire damage per second to health": [
    "Inimicum <mag> puncta vulneris ignis singulis secundis accipit"
  ],
  "stalhrim gauntlets of extreme archery": [
    "Stalhrimis Manubria Levia Extremis Sagittationis"
  ],
  "stalhrim shield of fire suppression": [
    "Stalhrimis Scutum Suppressionis Ignis"
  ],
  "apocrypha tome": [
    "Liber Apocryphae"
  ],
  "stalhrim mace of depleting": [
    "Stalhrimis Maca Deplendi"
  ],
  "chitin helmet of eminent destruction": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Eminentis Destructionis"
  ],
  "what's in your head": [
    "Quid in capite tuo est"
  ],
  "nordic war axe of fear": [
    "Germanicus Bellisecuris Timoris"
  ],
  "unearthed": [
    "Effosum"
  ],
  "nordic warhammer of malediction": [
    "Germanicus Bellimalleus Maledictionis"
  ],
  "nordic sword of reaping": [
    "Germanicus Gladius Decerpendi"
  ],
  "stalhrim mace of extreme chaos": [
    "Stalhrimis Maca Chaos Extremis"
  ],
  "stalhrim armor of renewal": [
    "Stalhrimis Lorica Renovationis"
  ],
  "holy stalhrim bow": [
    "Sanctus Stalhrimis Arcus"
  ],
  "bonemold shield of dwindling shock": [
    "Osseum Scutum Electricitatis Diminuendi"
  ],
  "stalhrim armor of extreme alteration": [
    "Stalhrimis Lorica Levis Extremis Alterationis"
  ],
  "nordic warhammer of immobilizing": [
    "Germanicus Bellimalleus Conligandi"
  ],
  "nordic greatsword of blizzards": [
    "Germanica Spatha Hiemis"
  ],
  "stalhrim mace of lightning": [
    "Stalhrimis Maca Fulminis"
  ],
  "chitin helmet of major illusion": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Maioris Inlusionis"
  ],
  "nordic dagger of expelling": [
    "Germanicus Pugio Expellendi"
  ],
  "nordic warhammer of blizzards": [
    "Germanicus Bellimalleus Hiemis"
  ],
  "do you have any insights to share about this puzzle": [
    "Habesne auxilium mihi de hoc aenigmate"
  ],
  "bonemold helmet of magicka": [
    "Ossea Galea Magicae"
  ],
  "chitin shield of dwindling shock": [
    "Chitonos Scutum Electricitatis Diminuendi"
  ],
  "rieklings may be small in stature, but they attack in swarms and can be fearsome in melee combat": [
    "Rieklings fortasse parvi sunt, sed in gregibus oppugnant et in pugna manuali saeviter pugnant"
  ],
  "they are even known to tame and ride boars into battle": [
    "Nonnumquam apros mansuefaciunt ut ab eis in proelio vehantur"
  ],
  "recipe - shock cloaked spider": [
    "Dictamen - Aranea Electricitati Amicta"
  ],
  "holy stalhrim war axe": [
    "Sanctus Stalhrimis Bellisecuris"
  ],
  "i found the missing miners. both dead": [
    "Cunicularios amissos inveni... ambos mortuos"
  ],
  "nordic sword of blizzards": [
    "Germanicus Gladius Hiemis"
  ],
  "how about you take half": [
    "Quidni tu dimiam partem accipias"
  ],
  "stalhrim helmet of extreme archery": [
    "Stalhrimis Galea Levis Extremis Sagittationis"
  ],
  "nordic bow of binding": [
    "Germanicus Arcus Deligandi"
  ],
  "audiotemplatemammoth": [
    "AudioTemplateMammoth"
  ],
  "shock spider abilities": [
    "Shock Spider Abilities"
  ],
  "stalhrim sword of debilitation": [
    "Stalhrimis Gladius Deminuendi"
  ],
  "stalhrim helmet of peerless archery": [
    "Stalhrimis Galea Optimae Sagittationis"
  ],
  "nordic war axe of ice": [
    "Germanicus Bellisecuris Glaciei"
  ],
  "werebears": [
    "Werebears"
  ],
  "stalhrim dagger of high chaos": [
    "Stalhrimis Pugio Chaos Magni"
  ],
  "stalhrim gauntlets of eminent archery": [
    "Stalhrimis Manubria Eminentis Sagittationis"
  ],
  "reverent nordic battleaxe": [
    "Reverens Germanicus Bellisecuris"
  ],
  "frost flow": [
    "Frost Flow"
  ],
  "stalhrim warhammer of garnering": [
    "Stalhrimis Bellimalleus Bibendi"
  ],
  "bonemold shield of dwindling magic": [
    "Osseum Scutum Diminuentis Magicae"
  ],
  "bonemold helmet of minor alteration": [
    "Ossea Galea Minoris Alterationis"
  ],
  "raven rock rumors": [
    "Raven Rock Rumours"
  ],
  "karstaag's summons": [
    "Karstaag's Summons"
  ],
  "nordic battleaxe of scorching": [
    "Germanicus Bellisecuris Incendendi"
  ],
  "stalhrim war axe of ultimate chaos": [
    "Stalhrimis Bellisecuris Chaos Ultimi"
  ],
  "stalhrim war axe of exhaustion": [
    "Stalhrimis Bellisecuris Exhaustionis"
  ],
  "stalhrim warhammer of devouring": [
    "Stalhrimis Bellimalleus Consumendi"
  ],
  "the steel smithing perk allows you to forge bonemold armor": [
    "Ars Adamanteum Faciendi tibi loricam Ossaformam facere sinet"
  ],
  "steel smithing perk allows you to forge bonemold armor": [
    "Ars Adamanteum Faciendi tibi loricam Ossaformam facere sinet"
  ],
  "elven smithing allows you to forge chitin armor": [
    "Ars Loricam Alfam Faciendi tibi loricam Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ facere sinet"
  ],
  "advanced armors allows you to forge nordic armor and weapons": [
    "Loricae Meliores tibi loricam Germanicam facere sinet"
  ],
  "ebony smithing allows you to forge stalhrim armor and weapons": [
    "Ars Loricam Ebeni faciendi tibi loricam Stalhrimam facere sinet"
  ],
  "stalhrim greatsword of extreme chaos": [
    "Stalhrimis Spatha Chaos Extremis"
  ],
  "frost enchantments are more potent when placed on stalhrim weapons or armor": [
    "Incantamenta glaciei fortiora sunt in Lorica et Arma Stalhrimi facta"
  ],
  "bonemold armor is a type of dunmer armor crafted out of bone and resins": [
    "Lorica Ossaforma est lorica Atralfa ossibus et resinis facta"
  ],
  "are you sure gratian's death wasn't an accident": [
    "Esne certus mors Gratianis casus non erat"
  ],
  "stalhrim dagger of expelling": [
    "Stalhrimis Pugio Expellendi"
  ],
  "stalhrim helmet of extreme conjuring": [
    "Stalhrimis Galea Extremis Vocandi"
  ],
  "seekers are servants of hermaeus mora": [
    "Quaetores servi Hermaei Morae sunt"
  ],
  "they are rarely seen outside his oblivion realm of apocrypha": [
    "Rare forus Apocryphae inveniuntur"
  ],
  "stalhrim warhammer of lethargy": [
    "Stalhrimis Bellimalleus Dormientis"
  ],
  "bonemold shield of waning frost": [
    "Osseum Scutum Glaciei Diminuentis"
  ],
  "miraak's staff": [
    "Rudis Miraakis"
  ],
  "bonemold helmet of alteration": [
    "Ossea Galea Alterationis"
  ],
  "confront ralis": [
    "Confronta Ralem"
  ],
  "(1000 gold)": [
    "(M auri)"
  ],
  "defeat ahzidal": [
    "Ahzidal Vince"
  ],
  "frost cloak freeze": [
    "Frost Cloak Freeze"
  ],
  "nordic boots of brawn": [
    "Germanicae Caligae Cavatae Fortitudinis"
  ],
  "nordic gauntlets of major wielding": [
    "Germanica Manubria Cavata Maioris Dexteritatis"
  ],
  "i can't let you go unpunished": [
    "Poenas dare debes"
  ],
  "holy stalhrim mace": [
    "Sancta Stalhrimis Maca"
  ],
  "i just got out of there as fast as i could": [
    "Effugi tam celeriter ut potui"
  ],
  "nordic battleaxe of shocks": [
    "Germanicus Bellisecuris Electricitatis"
  ],
  "stalhrim dagger of freezing": [
    "Stalhrimis Pugio Glaciei"
  ],
  "nordic warhammer of despair": [
    "Germanicus Bellimalleus Timoris"
  ],
  "filial bonds": [
    "Vincula Filiorum"
  ],
  "bonemold helmet of minor conjuration": [
    "Ossea Galea Minoris Vocandi"
  ],
  "stalhrim sword of extreme chaos": [
    "Stalhrimis Gladius Chaos Extremis"
  ],
  "targets take <mag> points of frost damage for <dur> seconds, plus stamina damage": [
    "Inimici <mag> puncta gelidi vulneris <dur> secundas accipiunt, et staminae vulneris",
    "Inimici <mag> puncta vulneris Glaciei <dur> secundas, plus vulneris Staminae, accipiunt"
  ],
  "nordic war axe of animus": [
    "Germanicus Bellisecuris Animi"
  ],
  "the poison song, book iv": [
    "Carmen Veneni, Liber IV"
  ],
  "poison song, book iv": [
    "Carmen Veneni, Liber IV"
  ],
  "kill ralis": [
    "Neca Ralem"
  ],
  "nordic greatsword of thunderbolts": [
    "Germanica Spatha Tonitruum"
  ],
  "dragon storm": [
    "Tempestas Draconis"
  ],
  "stalhrim helmet of extreme destruction": [
    "Stalhrimis Galea Extremis Destructionis"
  ],
  "stalhrim dagger of winnowing": [
    "Stalhrimis Pugio Evanescendi"
  ],
  "stalhrim armor of rejuvenation": [
    "Stalhrimis Lorica Recuperandi"
  ],
  "nordic battleaxe of consuming": [
    "Germanicus Bellisecuris Consumendi"
  ],
  "searing embers": [
    "Cinera Urientia"
  ],
  "nordic dagger of harvesting": [
    "Germanicus Pugio Saturni"
  ],
  "glowing spider abilities": [
    "Glowing Spider Abilities"
  ],
  "chitin armor of restoration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Recreandi"
  ],
  "chitin helmet of magicka": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Magicae"
  ],
  "nordic mace of dread": [
    "Germanica Maca Phobiae"
  ],
  "nordic shield of frost suppression": [
    "Germanicum Scutum Cavum Contra Vim Frigidum"
  ],
  "stalhrim bow of enervating": [
    "Stalhrimis Arcus Emoliendi"
  ],
  "bring the note to a guard in raven rock": [
    "Fer epistulam ad custodem Corvisaxi"
  ],
  "read the note": [
    "Lege epistulam"
  ],
  "nordic sword of leeching": [
    "Germanicus Gladius Sanguisuginis"
  ],
  "i'm still working on it": [
    "Iam in eo operam do"
  ],
  "lives of the saints": [
    "Vitae Sanctorum"
  ],
  "stalhrim mace of the sorcerer": [
    "Stalhrimis Maca Thaumaturgos"
  ],
  "stalhrim greatsword of winnowing": [
    "Stalhrimis Spatha Evanescendi"
  ],
  "follow adril arano": [
    "Sequere Adril Arano"
  ],
  "stalhrim armor of mending": [
    "Stalhrimis Lorica Levis Reficiendi"
  ],
  "stalhrim battleaxe of exhaustion": [
    "Stalhrimis Bellisecuris Exhaustionis"
  ],
  "stalhrim helmet of major alteration": [
    "Stalhrimis Galea Levis Maioris Alterationis"
  ],
  "stalhrim sword of despair": [
    "Stalhrimis Gladius Timoris"
  ],
  "nordic helmet of extreme conjuring": [
    "Germanica Galea Cavata Extremis Vocandi"
  ],
  "stalhrim mace of leeching": [
    "Stalhrimis Maca Sanguisuginis"
  ],
  "defeat vahlok": [
    "Vahlokem Vince"
  ],
  "chitin helmet of alchemy": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Alchemiae"
  ],
  "bonemold shield of waning shock": [
    "Osseum Scutum Electricitatis Diminuentis"
  ],
  "nordic greatsword of devouring": [
    "Germanica Spatha Consumendi"
  ],
  "how do you feel about bujold": [
    "Quomodo sentis de Bujold"
  ],
  "nordic dagger of devouring": [
    "Germanicus Pugio Consumendi"
  ],
  "speak to ralis sedarys at kolbjorn barrow": [
    "Adloquere Rali Sedarys in Pabillo Kolbjornis"
  ],
  "bonemold armor of the major squire": [
    "Ossea Lorica Maioris Armigeris"
  ],
  "kolbjorn barrow misc objectives": [
    "Kolbjorn Barrow Misc Objectives"
  ],
  "nordic bow of ice": [
    "Germanicus Arcus Glaciei"
  ],
  "stalhrim warhammer of subsuming": [
    "Stalhrimis Bellimalleus Subsumendi"
  ],
  "kolbjorn barrow phase 1 tracking": [
    "Kolbjorn Barrow Phase 1 Tracking"
  ],
  "kolbjorn barrow phase 2 tracking": [
    "Kolbjorn Barrow Phase 2 Tracking"
  ],
  "you don't agree with crescius": [
    "Cum Crescio non consentis"
  ],
  "nordic dagger of binding": [
    "Germanicus Pugio Deligandi"
  ],
  "stalhrim gauntlets of eminent lockpicking": [
    "Stalhrimis Manubria Levia Eminentis Clavicarii"
  ],
  "nordic mace of depleting": [
    "Germanica Maca Deplendi"
  ],
  "morag tong competition": [
    "Morag Tong Competition"
  ],
  "stalhrim helmet of major alchemy": [
    "Stalhrimis Galea Levis Maioris Alchemiae"
  ],
  "stalhrim sword of winter": [
    "Stalhrimis Gladius Hiemis"
  ],
  "who was crescius's great-grandfather": [
    "Quis erat proavus Crescii"
  ],
  "reverent nordic bow": [
    "Reverens Germanicus Arcus"
  ],
  "stalhrim bow of debilitation": [
    "Stalhrimis Arcus Deminuendi"
  ],
  "chitin bracers of eminent smithing": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Eminentis Faciendi"
  ],
  "virtuous nordic warhammer": [
    "Germanicus Bellimalleus Virtutis"
  ],
  "aggro cloak": [
    "Aggro Cloak"
  ],
  "stalhrim helmet of major destruction": [
    "Stalhrimis Galea Levis Maioris Destructionis"
  ],
  "nordic battleaxe of harrowing": [
    "Germanicus Bellisecuris Occandi"
  ],
  "stalhrim battleaxe of nullifying": [
    "Stalhrimis Bellisecuris Nullificandi"
  ],
  "chitin armor of the squire": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Armigeris"
  ],
  "stalhrim helmet of resurgence": [
    "Stalhrimis Galea Resurgentiae"
  ],
  "stalhrim armor of eminent illusion": [
    "Stalhrimis Lorica Levis Eminentis Inlusionis"
  ],
  "stalhrim shield of magic abatement": [
    "Stalhrimis Scutum Magicae Subsidentis"
  ],
  "nordic bow of lethargy": [
    "Germanicus Arcus Dormientis"
  ],
  "where can i find a heart stone": [
    "Ubi corsaxum invenire possum"
  ],
  "it's not my problem": [
    "Problema meum non est"
  ],
  "nordic boots of extreme stamina": [
    "Germanicae Caligae Cavatae Extremis Staminae"
  ],
  "nordic greatsword of fear": [
    "Germanica Spatha Timoris"
  ],
  "stalhrim greatsword of depleting": [
    "Stalhrimis Spatha Deplendi"
  ],
  "nordic boots of the ox": [
    "Germanicae Caligae Cavatae Bovis"
  ],
  "nordic armor of the eminent knight": [
    "Germanica Lorica Cavata Eminentis Equitis"
  ],
  "bonemold gauntlets of alchemy": [
    "Ossea Manubria Alchemiae"
  ],
  "nordic dagger of lightning": [
    "Germanicus Pugio Fulminis"
  ],
  "bonemold armor of minor health": [
    "Ossea Lorica Minor Salutis"
  ],
  "stalhrim dagger of enervating": [
    "Stalhrimis Pugio Emoliendi"
  ],
  "demoralize": [
    "Demoralise"
  ],
  "did i overhear that someone is missing": [
    "Audivine aliquem amissum esse"
  ],
  "nordic war axe of harvesting": [
    "Germanicus Bellisecuris Saturni"
  ],
  "ring of instinct": [
    "Anulus Instincti"
  ],
  "where did you find this black book": [
    "Ubi hunc Librum Atrum invenisti"
  ],
  "stalhrim greatsword of storms": [
    "Stalhrimis Spatha Tempestatium"
  ],
  "stalhrim bow of blizzards": [
    "Stalhrimis Arcus Hiemis"
  ],
  "bonemold helmet of conjuration": [
    "Ossea Galea Vocandi"
  ],
  "magical instruments": [
    "Magical Instruments"
  ],
  "bonemold gauntlets of major archery": [
    "Ossea Manubria Maioris Sagittationis"
  ],
  "nordic mace of scorching": [
    "Germanica Maca Incendendi"
  ],
  "stalhrim helmet of major illusion": [
    "Stalhrimis Galea Levis Maioris Inlusionis"
  ],
  "stalhrim bow of malediction": [
    "Stalhrimis Arcus Maledictionis"
  ],
  "i found evidence proving the severins's guilt": [
    "Evidentiam culpae Severinorum inveni"
  ],
  "nordic bow of blizzards": [
    "Germanicus Arcus Hiemis"
  ],
  "haven't i done enough": [
    "Nonne satis feci"
  ],
  "key to abandoned lodge": [
    "Clavis Domo Derelictae"
  ],
  "nordic mace of garnering": [
    "Germanica Maca Bibendi"
  ],
  "the poison song, book ii": [
    "Carmen Veneni, Liber II"
  ],
  "poison song, book ii": [
    "Carmen Veneni, Liber II"
  ],
  "chitin bracers of alchemy": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Alchemiae"
  ],
  "chitin helmet of eminent conjuring": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Eminentis Vocandi"
  ],
  "stalhrim sword of exhaustion": [
    "Stalhrimis Gladius Exhaustionis"
  ],
  "nordic battleaxe of expelling": [
    "Germanicus Bellisecuris Expellendi"
  ],
  "thank you, councilor": [
    "Gratias tibi ago, conciliator"
  ],
  "nordic mace of leeching": [
    "Germanica Maca Sanguisuginis"
  ],
  "recipe - jumping frost spider": [
    "Dictamen - Aranea Glaciei Saltans"
  ],
  "nordic battleaxe of immobilizing": [
    "Germanicus Bellisecuris Conligandi"
  ],
  "chitin armor of the major squire": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Maioris Armigeris"
  ],
  "ahzidal's arcana": [
    "Ahzidalis Arcana"
  ],
  "servos' journal": [
    "Ephemerides Servos"
  ],
  "what's in it for me": [
    "Quid mihi interest"
  ],
  "stalhrim gauntlets of peerless wielding": [
    "Stalhrimis Manubria Optimae Dexteritatis"
  ],
  "fountain of knowledge": [
    "Fontem Scientiae"
  ],
  "when your fire breath shout kills an enemy, a fire wyrm emerges from their corpse to fight for you for 60 seconds": [
    "Dum clamatio ignis inimicum neces, vermis ignis e corpore emergat et pro te 60 secundas pugnet"
  ],
  "stalhrim battleaxe of subsuming": [
    "Stalhrimis Bellisecuris Subsumendi"
  ],
  "stalhrim battleaxe of devouring": [
    "Stalhrimis Bellisecuris Consumendi"
  ],
  "stalhrim helmet of peerless alchemy": [
    "Stalhrimis Galea Optimae Alchemiae"
  ],
  "bonemold helmet of illusion": [
    "Ossea Galea Inlusionis"
  ],
  "stalhrim armor of eminent conjuring": [
    "Stalhrimis Lorica Levis Eminentis Vocandi"
  ],
  "stalhrim gauntlets of extreme deft hands": [
    "Stalhrimis Manubria Levia Extremis Furis"
  ],
  "bonemold gauntlets of major sure grip": [
    "Ossea Manubria Maioris Ambidexteritatis"
  ],
  "nordic sword of lethargy": [
    "Germanicus Gladius Dormientis"
  ],
  "stalhrim bow of lethargy": [
    "Stalhrimis Arcus Dormientis"
  ],
  "chitin armor of the eminent squire": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Eminentis Armigeris"
  ],
  "hallowed stalhrim bow": [
    "Stalhrimis Arcus Sacer"
  ],
  "stalhrim helmet of major restoration": [
    "Stalhrimis Galea Levis Maioris Recreandi"
  ],
  "virtuous nordic war axe": [
    "Germanicus Bellisecuris Virtutis"
  ],
  "stalhrim battleaxe of extreme chaos": [
    "Stalhrimis Bellisecuris Chaos Extremis"
  ],
  "nordic helmet of eminent illusion": [
    "Germanica Galea Cavata Eminentis Inlusionis"
  ],
  "chitin bracers of wielding": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Gravia Dexteritatis"
  ],
  "stalhrim war axe of blizzards": [
    "Stalhrimis Bellisecuris Hiemis"
  ],
  "locate": [
    "Locate"
  ],
  "nordic greatsword of harrowing": [
    "Germanica Spatha Occandi"
  ],
  "are you one of the warriors from thirsk": [
    "Esne bellator e Thirsk"
  ],
  "stalhrim battleaxe of damnation": [
    "Stalhrimis Bellisecuris Damnationis"
  ],
  "stalhrim helmet of eminent conjuring": [
    "Stalhrimis Galea Levis Eminentis Vocandi"
  ],
  "fund the next phase of the excavation (2000 gold)": [
    "Pecuniam solve pro parte proxima excavati (MM Auri)"
  ],
  "nordic armor of eminent illusion": [
    "Germanica Lorica Cavata Eminentis Inlusionis"
  ],
  "ancient dragonborn": [
    "Draconigena Antiquus"
  ],
  "what happened to him": [
    "Quid ei accidit"
  ],
  "is this the map you're referring to": [
    "Loquerisne de hac charta geographica"
  ],
  "stalhrim greatsword of evoking": [
    "Stalhrimis Spatha Evocandi"
  ],
  "nordic bow of lightning": [
    "Germanicus Arcus Fulminis"
  ],
  "stalhrim battleaxe of stunning": [
    "Stalhrimis Bellisecuris Pausandi"
  ],
  "chitin bracers of major archery": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Maioris Sagittationis"
  ],
  "chitin armor of major restoration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Maioris Recreandi"
  ],
  "nordic battleaxe of evoking": [
    "Germanicus Bellisecuris Evocandi"
  ],
  "chitin boots of flame suppression": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Suppressionis Ignis"
  ],
  "nordic war axe of devouring": [
    "Germanicus Bellisecuris Consumendi"
  ],
  "ildari": [
    "Ildari"
  ],
  "what can you tell me about her": [
    "Quid mihi de ea narrare potes"
  ],
  "what did she do with the prisoners": [
    "Quid fecit illa cum captivis"
  ],
  "where will you go now": [
    "Quo nunc ibis"
  ],
  "people seem pretty tense around here": [
    "Homines hic solliciti videntur"
  ],
  "bonemold boots of resist shock": [
    "Osseae Caligae Electricitatem Resistendi"
  ],
  "reduced weapon damage": [
    "Vulnus Armorum Reductum"
  ],
  "bonemold shield of resist magic": [
    "Osseum Scutum Magicam Resistendi"
  ],
  "the poison song, book v": [
    "Carmen Veneni, Liber V"
  ],
  "poison song, book v": [
    "Carmen Veneni, Liber V"
  ],
  "mireli's letter to mother": [
    "Epistula Mirelis ad Matrem"
  ],
  "stalhrim warhammer of banishing": [
    "Stalhrimis Bellimalleus Dimittendi"
  ],
  "nordic battleaxe of debilitation": [
    "Germanicus Bellisecuris Deminuendi"
  ],
  "stalhrim battleaxe of enervating": [
    "Stalhrimis Bellisecuris Emoliendi"
  ],
  "nordic warhammer of freezing": [
    "Germanicus Bellimalleus Glaciei"
  ],
  "dlc2 warehousespiders": [
    "DLC2 WarehouseSpiders"
  ],
  "ummm... are you alright": [
    "Ummm... esne bene"
  ],
  "nordic bow of diminishing": [
    "Germanicus Arcus Dinimuendi"
  ],
  "stalhrim helmet of eminent destruction": [
    "Stalhrimis Galea Eminentis Destructionis"
  ],
  "bone, part ii": [
    "Os, Pars II"
  ],
  "you're ralis": [
    "Ralis es"
  ],
  "i found your camp outside kolbjorn barrow": [
    "Catra tua foris Pabillo Kolbjorn inveni"
  ],
  "secrets": [
    "Secreta"
  ],
  "what kind of secrets": [
    "Qualia secreta"
  ],
  "chitin bracers of smithing": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Faciendi"
  ],
  "i can see this is going nowhere": [
    "Certe nos ad consensum numquam veniemus"
  ],
  "good luck with all that": [
    "Vale"
  ],
  "chitin armor of major destruction": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Maioris Destructionis"
  ],
  "nordic sword of thunderbolts": [
    "Germanicus Gladius Tonitruum"
  ],
  "stalhrim greatsword of banishing": [
    "Stalhrimis Spatha Dimittendi"
  ],
  "madman": [
    "Insanus"
  ],
  "chitin boots of muffling": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Graves Silentii"
  ],
  "virtuous stalhrim bow": [
    "Stalhrimis Arcus Virtutis"
  ],
  "morag tong faction": [
    "Morag Tong Faction"
  ],
  "nordic shield of dwindling shock": [
    "Germanicum Scutum Cavum Electricitatis Diminuendi"
  ],
  "would i be able to collect a bounty": [
    "Vectigal colligere possum"
  ],
  "nordic battleaxe of leeching": [
    "Germanicus Bellisecuris Sanguisuginis"
  ],
  "stalhrim greatsword of garnering": [
    "Stalhrimis Spatha Bibendi"
  ],
  "is there a bounty on her then": [
    "Estne largitio pro eis"
  ],
  "ebony warrior": [
    "Bellator Ebeni"
  ],
  "stalhrim mace of the vampire": [
    "Stalhrimis Maca Vampiri"
  ],
  "stalhrim armor of invigoration": [
    "Stalhrimis Lorica Vigoris"
  ],
  "nordic gauntlets of major sure grip": [
    "Germanica Manubria Cavata Maioris Ambidexteritatis"
  ],
  "speak to councilor morvayn": [
    "Adloquere Conciliatorem Morvayn"
  ],
  "stalhrim armor of peerless restoration": [
    "Stalhrimis Lorica Optimi Recreandi"
  ],
  "i need the \"secrets of the skaal\" in order to defeat miraak": [
    "\"Secretis Skaal\" careo. Necesse est mihi eos habere ut Miraak vincam"
  ],
  "nordic helmet of extreme destruction": [
    "Germanica Galea Cavata Extremis Destructionis"
  ],
  "do you plan to stick around here, then": [
    "Ergo in animo habes hic manere"
  ],
  "bull netch": [
    "Bull Netch"
  ],
  "you were able to get the forge working again": [
    "Fabricam refecisti"
  ],
  "ash guardian fx": [
    "Ash Guardian FX"
  ],
  "virtuous nordic greatsword": [
    "Germanica Spatha Virtutis"
  ],
  "stalhrim greatsword of damnation": [
    "Stalhrimis Spatha Damnationis"
  ],
  "how much mead is left": [
    "Quantum Hydromelli manet"
  ],
  "stalhrim battleaxe of annihilating": [
    "Stalhrimis Bellisecuris Annihilandi"
  ],
  "i heard you were starting an expedition": [
    "Audivi te expeditionem facturum esse"
  ],
  "stalhrim bow of extreme chaos": [
    "Stalhrimis Arcus Chaos Extremis"
  ],
  "an infestation of what": [
    "Infestatum quorum"
  ],
  "bonemold boots of sneaking": [
    "Osseae Caligae Rependi"
  ],
  "nordic sword of banishing": [
    "Germanicus Gladius Dimittendi"
  ],
  "recipe - oil spider": [
    "Dictamen - Aranea Olei"
  ],
  "you can tell me": [
    "Dic mihi",
    "Id mihi narrare potes"
  ],
  "come on, what's the harm": [
    "Agedum, quid nocet"
  ],
  "nordic mace of winnowing": [
    "Germanica Maca Evanescendi"
  ],
  "bonemold armor of major health": [
    "Ossea Lorica Maioris Salutis"
  ],
  "cast on the ground, it creates an area of frost that does <50> points of frost damage per second": [
    "Fac in solum, facit parietem glaciei quod <50> puncta vulneris electricitatis singulas secundas facit"
  ],
  "here's a little gold for your tale": [
    "Hic habes paulum auri pro fabula"
  ],
  "chitin shield": [
    "Chitonos Scutum"
  ],
  "you're short on gold": [
    "You're short on gold"
  ],
  "maybe this will help a bit": [
    "Maybe this will help a bit"
  ],
  "now talk": [
    "Now talk"
  ],
  "stalhrim war axe of subsuming": [
    "Stalhrimis Bellisecuris Subsumendi"
  ],
  "stalhrim war axe of enervating": [
    "Stalhrimis Bellisecuris Emoliendi"
  ],
  "stalhrim dagger of exhaustion": [
    "Stalhrimis Pugio Exhaustionis"
  ],
  "stalhrim helmet of eminent archery": [
    "Stalhrimis Galea Levis Eminentis Sagittationis"
  ],
  "stalhrim boots of muffling": [
    "Stalhrimis Caligae Silentii"
  ],
  "bonemold gauntlets of minor lockpicking": [
    "Ossea Manubria Minoris Clavicarii"
  ],
  "stormfang": [
    "Stormfang"
  ],
  "nordic sword of expelling": [
    "Germanicus Gladius Expellendi"
  ],
  "stalhrim dagger of leeching": [
    "Stalhrimis Pugio Sanguisuginis"
  ],
  "i suppose i could lend a hand": [
    "Fortasse adiuvare possum",
    "Credo me te adiuvare posse"
  ],
  "cyclone02": [
    "Cyclone02"
  ],
  "nordic boots of dwindling frost": [
    "Germanicae Caligae Cavatae Glaciei Diminuendi"
  ],
  "hallowed stalhrim warhammer": [
    "Stalhrimis Bellimalleus Sacer"
  ],
  "i've dealt with the draugr and found a way past the dead end": [
    "Draugr vici et viam trans locum clausum inveni"
  ],
  "magical instruments ab": [
    "Magical Instruments AB"
  ],
  "stalhrim mace of despair": [
    "Stalhrimis Maca Timoris"
  ],
  "stalhrim sword of petrifying": [
    "Stalhrimis Gladius Lapidefaciendi"
  ],
  "stalhrim mace of annihilating": [
    "Stalhrimis Maca Annihilandi"
  ],
  "stalhrim gauntlets of peerless smithing": [
    "Stalhrimis Manubria Optimi Faciendi"
  ],
  "stalhrim greatsword of leeching": [
    "Stalhrimis Spatha Sanguisuginis"
  ],
  "nordic boots of strength": [
    "Germanicae Caligae Cavatae Fortitudinis"
  ],
  "no, not yet": [
    "Minime, nondum"
  ],
  "nordic war axe of diminishing": [
    "Germanicus Bellisecuris Dinimuendi"
  ],
  "i can't afford it right now": [
    "Satis pecuniae nunc non habeo"
  ],
  "bonemold helmet of minor magicka": [
    "Ossea Galea Minoris Magicae"
  ],
  "stalhrim sword of banishing": [
    "Stalhrimis Gladius Dimittendi"
  ],
  "what is it this time": [
    "Quid nunc"
  ],
  "nordic helmet of eminent magicka": [
    "Germanica Galea Cavata Eminentis Magicae"
  ],
  "stalhrim mace of expelling": [
    "Stalhrimis Maca Expellendi"
  ],
  "what's a werebear": [
    "Quid est werebear"
  ],
  "i've dealt with the draugr": [
    "Draugr vici"
  ],
  "this had better be worth it": [
    "Spero id dignum pretium esse"
  ],
  "(3000 gold)": [
    "(MMM Auri)"
  ],
  "stalhrim boots of major stamina": [
    "Stalhrimis Caligulae Maioris Staminae"
  ],
  "bonemold gauntlets of minor destruction": [
    "Ossea Manubria Minoris Destructionis"
  ],
  "stalhrim helmet of recharging": [
    "Stalhrimis Galea Levis Magicam Recuperandi"
  ],
  "nordic war axe of scorching": [
    "Germanicus Bellisecuris Incendendi"
  ],
  "do you still need my help": [
    "Iamne auxilium meum quaeris"
  ],
  "nordic greatsword of dread": [
    "Germanica Spatha Phobiae"
  ],
  "it looks like at least some of the miners survived this time": [
    "Mihi videtur nonnullos cunicularios superfuisse"
  ],
  "nordic warhammer of stunning": [
    "Germanicus Bellimalleus Pausandi"
  ],
  "stalhrim sword of animus": [
    "Stalhrimis Gladius Animi"
  ],
  "nordic sword of consuming": [
    "Germanicus Gladius Consumendi"
  ],
  "ebonywarrior class": [
    "Ebony Warrior Class"
  ],
  "stalhrim warhammer of terror": [
    "Stalhrimis Bellimalleus Terroris"
  ],
  "virtuous stalhrim sword": [
    "Stalhrimis Gladius Virtutis"
  ],
  "no, no i don't": [
    "Minime"
  ],
  "recipe - jumping shock spider": [
    "Dictamen - Aranea Electricitatis Saltans"
  ],
  "nordic sword of stunning": [
    "Germanicus Gladius Pausandi"
  ],
  "did you... kill the miners": [
    "Necavistine cunicularios"
  ],
  "fire flow": [
    "Fire Flow"
  ],
  "i believe you": [
    "Tibi credo"
  ],
  "just... don't come back here": [
    "Modo noli revenire"
  ],
  "nordic warhammer of thunderbolts": [
    "Germanicus Bellimalleus Tonitruum"
  ],
  "ruby geode": [
    "Geodes Rubini"
  ],
  "nordic war axe of banishing": [
    "Germanicus Bellisecuris Dimittendi"
  ],
  "nordic greatsword of garnering": [
    "Germanica Spatha Bibendi"
  ],
  "i don't think they'll give up willingly": [
    "Non credo illos libenter se tradituros esse"
  ],
  "nordic mace of fear": [
    "Germanica Maca Timoris"
  ],
  "stalhrim warhammer of harvesting": [
    "Stalhrimis Bellimalleus Saturni"
  ],
  "nordic helmet of eminent archery": [
    "Germanica Galea Cavata Eminentis Sagittationis"
  ],
  "bonemold gauntlets of conjuring": [
    "Ossea Manubria Vocandi"
  ],
  "chitin bracers of eminent deft hands": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Eminentis Furis"
  ],
  "reverent nordic mace": [
    "Reverens Germanica Maca"
  ],
  "nordic greatsword of lightning": [
    "Germanica Spatha Fulminis"
  ],
  "stalhrim shield of dwindling magic": [
    "Stalhrimis Scutum Diminuentis Magicae"
  ],
  "stalhrim mace of winter": [
    "Stalhrimis Maca Hiemis"
  ],
  "nordic war axe of enervating": [
    "Germanicus Bellisecuris Emoliendi"
  ],
  "you're here by yourself": [
    "Hic solus es"
  ],
  "nordic sword of ice": [
    "Germanicus Gladius Glaciei"
  ],
  "what is the tree stone": [
    "Quid est Saxa Arboris"
  ],
  "stalhrim boots of grounding": [
    "Stalhrimis Caligae Diminuendi Electricitatis"
  ],
  "stalhrim mace of terror": [
    "Stalhrimis Maca Terroris"
  ],
  "of course, the village to the north": [
    "Certe, vices ad septentrionem"
  ],
  "nordic warhammer of harrowing": [
    "Germanicus Bellimalleus Occandi"
  ],
  "nordic warhammer of dread": [
    "Germanicus Bellimalleus Phobiae"
  ],
  "how long have you been down here": [
    "Quamqui hic fuisti"
  ],
  "stalhrim gauntlets of major wielding": [
    "Stalhrimis Manubria Levia Maioris Dexteritatis"
  ],
  "opponents in melee range take <mag> points shock damage per second": [
    "Inimici prope te <mag> puncta vulneris electricitatis singulis secundis accipiunt"
  ],
  "stalhrim boots of the firewalker": [
    "Stalhrimis Caligae Ignambulatoris"
  ],
  "stalhrim bow of terror": [
    "Stalhrimis Arcus Terroris"
  ],
  "hallowed nordic bow": [
    "Sanctificatus Germanicus Arcus"
  ],
  "morag tong bracers": [
    "Bracchialia"
  ],
  "bonemold gauntlets of minor archery": [
    "Ossea Manubria Minoris Sagittationis"
  ],
  "stalhrim greatsword of nullifying": [
    "Stalhrimis Spatha Nullificandi"
  ],
  "recipe - frost cloaked spider": [
    "Dictamen - Aranea Glacie Amicta"
  ],
  "i killed general carius": [
    "Imperatorem Carium necavi"
  ],
  "let me show you": [
    "Tibi demonstrabo",
    "Sine me tibi demonstrare"
  ],
  "i think i've solved your ash spawn problems": [
    "Credo me difficultates tuas cum filiis cineris persolvisse"
  ],
  "stalhrim warhammer of freezing": [
    "Stalhrimis Bellimalleus Glaciei"
  ],
  "stalhrim mace of freezing": [
    "Stalhrimis Maca Glaciei"
  ],
  "a blast of cold that does <mag> points of damage per second to health and stamina": [
    "Rivus frigoris quod <mag> puncta singulis secundis facit Saluti Staminaeque",
    "Displosio frigidis quae facit <mag> vulneris singulis secundis saluti et staminae",
    "Displsio Glaciei quod <mag> puncta vulneris Saluti Staminaeque singulas secundas facit"
  ],
  "blast of cold that does <mag> points of damage per second to health and stamina": [
    "Rivus frigoris quod <mag> puncta singulis secundis facit Saluti Staminaeque",
    "Displosio frigidis quae facit <mag> vulneris singulis secundis saluti et staminae",
    "Displsio Glaciei quod <mag> puncta vulneris Saluti Staminaeque singulas secundas facit"
  ],
  "nordic armor of major conjuring": [
    "Germanica Lorica Cavata Maioris Vocandi"
  ],
  "hallowed stalhrim battleaxe": [
    "Stalhrimis Bellisecuris Sacer"
  ],
  "nordic armor of major restoration": [
    "Germanica Lorica Cavata Maioris Recreandi"
  ],
  "stalhrim dagger of thunderbolts": [
    "Stalhrimis Pugio Tonitruum"
  ],
  "nordic bow of enervating": [
    "Germanicus Arcus Emoliendi"
  ],
  "virtuous nordic sword": [
    "Germanicus Gladius Virtutis"
  ],
  "the poison song, book i": [
    "Carmen Veneni, Liber I"
  ],
  "poison song, book i": [
    "Carmen Veneni, Liber I"
  ],
  "hallowed nordic sword": [
    "Sanctificatus Germanicus Gladius"
  ],
  "bonemold armor of the minor squire": [
    "Ossea Lorica Minor Armigeris"
  ],
  "nordic helmet of major destruction": [
    "Germanica Galea Cavata Maioris Destructionis"
  ],
  "chitin shield of dwindling fire": [
    "Chitonos Scutum Flammarum Diminuentium"
  ],
  "chitin armor of major health": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Maioris Salutis"
  ],
  "nordic warhammer of harvesting": [
    "Germanicus Bellimalleus Saturni"
  ],
  "stalhrim greatsword of annihilating": [
    "Stalhrimis Spatha Annihilandi"
  ],
  "stalhrim boots of peerless stamina": [
    "Stalhrimis Caligae Optimae Staminae"
  ],
  "chitin helmet of major magicka": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Maioris Magicae"
  ],
  "stalhrim warhammer of depleting": [
    "Stalhrimis Bellimalleus Deplendi"
  ],
  "stalhrim armor of the noble knight": [
    "Stalhrimis Lorica Levis Nobilis Equitis"
  ],
  "stalhrim warhammer of stunning": [
    "Stalhrimis Bellimalleus Pausandi"
  ],
  "nordic bow of animus": [
    "Germanicus Arcus Animi"
  ],
  "bonemold helmet of alchemy": [
    "Ossea Galea Alchemiae"
  ],
  "stalhrim dagger of devouring": [
    "Stalhrimis Pugio Consumendi"
  ],
  "why would someone want to kidnap baldor": [
    "Quamobrem aliquis Baldorem abripuit"
  ],
  "stalhrim battleaxe of petrifying": [
    "Stalhrimis Bellisecuris Lapidefaciendi"
  ],
  "nordic boots of dwindling flames": [
    "Germanicae Caligae Cavatae Flammarum Diminuentium"
  ],
  "steam hazard": [
    "Steam Hazard"
  ],
  "i'll talk to storn": [
    "Storn adloquar"
  ],
  "stalhrim helmet of eminent alteration": [
    "Stalhrimis Galea Levis Eminentis Alterationis"
  ],
  "fund the next phase of the excavation (5000 gold)": [
    "Pecuniam solve pro parte proxima excavati (MMMMM Auri)"
  ],
  "visit the kolbjorn barrow excavation": [
    "Visita Excavatum Pabilionis Kolbjornis"
  ],
  "stalhrim battleaxe of the vampire": [
    "Stalhrimis Bellisecuris Vampiri"
  ],
  "what is thirsk": [
    "Quid est Thirsk"
  ],
  "nordic helmet of extreme restoration": [
    "Germanica Galea Cavata Extremis Recreandi"
  ],
  "nordic shield of major blocking": [
    "Germanicum Scutum Cavum Maioris Interponendi"
  ],
  "stalhrim war axe of thunderbolts": [
    "Stalhrimis Bellisecuris Tonitruum"
  ],
  "nordic greatsword of evoking": [
    "Germanica Spatha Evocandi"
  ],
  "return to wulf": [
    "Redde ad Wulf"
  ],
  "what is the skaal": [
    "Qui sunt Skaal"
  ],
  "have you been exiled": [
    "Expulsus es"
  ],
  "bonemold gauntlets of archery": [
    "Ossea Manubria Sagittationis"
  ],
  "recipe - frost cloaked spider 2x": [
    "Dictamen - Aranea Glacie Amicta 2x"
  ],
  "recipe - exploding frost spider": [
    "Dictamen - Aranea Glaciei Displodens"
  ],
  "do you have any idea where he might have gone": [
    "Scisne quo ivit"
  ],
  "he had become a werebear": [
    "Mutatus est in werebear"
  ],
  "hard to be sure": [
    "Diffilis est scire"
  ],
  "he put up a strong fight": [
    "Fortiter pugnavit"
  ],
  "stalhrim greatsword of dread": [
    "Stalhrimis Spatha Phobiae"
  ],
  "stalhrim mace of high chaos": [
    "Stalhrimis Maca Chaos Magni"
  ],
  "why do i need this word of power to defeat miraak": [
    "Quamobrem necesse est hoc Verbum Potestatis habere ut Miraakem vincam"
  ],
  "chitin helmet of eminent restoration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Eminentis Recreandi"
  ],
  "vendil ulen's chest key": [
    "Vendil Ulenis Arca Clavis"
  ],
  "nordic dagger of dread": [
    "Germanicus Pugio Phobiae"
  ],
  "nordic helmet of eminent destruction": [
    "Germanica Galea Cavata Eminentis Destructionis"
  ],
  "mogrul faction": [
    "Mogrul Faction"
  ],
  "bonemold helmet of minor destruction": [
    "Ossea Galea Minoris Destructionis"
  ],
  "stalhrim sword of depleting": [
    "Stalhrimis Gladius Deplendi"
  ],
  "stalhrim warhammer of malediction": [
    "Stalhrimis Bellimalleus Maledictionis"
  ],
  "stalhrim battleaxe of expelling": [
    "Stalhrimis Bellisecuris Expellendi"
  ],
  "bonemold helmet of minor illusion": [
    "Ossea Galea Minoris Inlusionis"
  ],
  "emerald geode": [
    "Geodes Smaragdi"
  ],
  "stalhrim mace of nullifying": [
    "Stalhrimis Maca Nullificandi"
  ],
  "nchunak's fire and faith": [
    "Ignis et Fides Nchunakis"
  ],
  "nordic battleaxe of enervating": [
    "Germanicus Bellisecuris Emoliendi"
  ],
  "nordic war axe of stunning": [
    "Germanicus Bellisecuris Pausandi"
  ],
  "chitin boots of strength": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Strength"
  ],
  "reverent nordic warhammer": [
    "Reverens Germanicus Bellimalleus"
  ],
  "chitin armor of eminent restoration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Eminentis Recreandi"
  ],
  "chitin boots of sneaking": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Rependi"
  ],
  "chitin bracers of eminent wielding": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Eminentis Dexteritatis"
  ],
  "ice wraith": [
    "Ice Wraith",
    "Phantasma Glaciei"
  ],
  "who's her husband": [
    "Quis est maritus eius"
  ],
  "stalhrim gauntlets of extreme alchemy": [
    "Stalhrimis Manubria Levia Extremis Alchemiae"
  ],
  "chitin armor of eminent destruction": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lorica Gravis Eminentis Destructionis"
  ],
  "stalhrim greatsword of subsuming": [
    "Stalhrimis Spatha Subsumendi"
  ],
  "search for the missing miners": [
    "Quaere Cunicularios Amissos"
  ],
  "nordic mace of thunderbolts": [
    "Germanica Maca Tonitruum"
  ],
  "ash guardian abilities": [
    "Ash Guardian abilities"
  ],
  "bye": [
    "Vale"
  ],
  "stalhrim mace of storms": [
    "Stalhrimis Maca Tempestatium"
  ],
  "nordic warhammer of torpor": [
    "Germanicus Bellimalleus Torporis"
  ],
  "nordic warhammer of lightning": [
    "Germanicus Bellimalleus Fulminis"
  ],
  "stalhrim gauntlets of peerless archery": [
    "Stalhrimis Manubria Optimae Sagittationis"
  ],
  "nordic dagger of depleting": [
    "Germanicus Pugio Deplendi"
  ],
  "acid": [
    "Acid"
  ],
  "stalhrim shield of eminent blocking": [
    "Stalhrimis Scutum Eminentis Interponendi"
  ],
  "nordic bow of the blaze": [
    "Germanicus Arcus Flagrantiae"
  ],
  "aggro dmg effect": [
    "Aggro DMG Effect"
  ],
  "stalhrim mace of enervating": [
    "Stalhrimis Maca Emoliendi"
  ],
  "nordic dagger of torpor": [
    "Germanicus Pugio Torporis"
  ],
  "nordic dagger of shocks": [
    "Germanicus Pugio Electricitatis"
  ],
  "stalhrim warhammer of exhaustion": [
    "Stalhrimis Bellimalleus Exhaustionis"
  ],
  "east empire company amulet": [
    "Euri Imperii Negotium Monile"
  ],
  "nordic armor of the noble knight": [
    "Germanica Lorica Cavata Nobilis Equitis"
  ],
  "what did the letters say": [
    "Quid dixerunt epistula"
  ],
  "chitin shield of waning shock": [
    "Chitonos Scutum Electricitatis Diminuentis"
  ],
  "stalhrim dagger of banishing": [
    "Stalhrimis Pugio Dimittendi"
  ],
  "stalhrim war axe of malediction": [
    "Stalhrimis Bellisecuris Maledictionis"
  ],
  "dragon breath": [
    "Dragon breath",
    "Exhalatio Draconis"
  ],
  "nordic helmet of major restoration": [
    "Germanica Galea Cavata Maioris Recreandi"
  ],
  "bonemold shield of resist fire": [
    "Osseum Scutum Ignem Resistendi"
  ],
  "stalhrim helmet of major conjuring": [
    "Stalhrimis Galea Levis Maioris Vocandi"
  ],
  "stalhrim warhammer of winnowing": [
    "Stalhrimis Bellimalleus Evanescendi"
  ],
  "stalhrim battleaxe of freezing": [
    "Stalhrimis Bellisecuris Glaciei"
  ],
  "hallowed nordic greatsword": [
    "Sanctificata Germanica Spatha"
  ],
  "nordic dagger of evoking": [
    "Germanicus Pugio Evocandi"
  ],
  "stalhrim greatsword of petrifying": [
    "Stalhrimis Spatha Lapidefaciendi"
  ],
  "stalhrim sword of storms": [
    "Stalhrimis Gladius Tempestatium"
  ],
  "nordic greatsword of the blaze": [
    "Germanica Spatha Flagrantiae"
  ],
  "karstaag's will": [
    "Karstaag's Will"
  ],
  "virtuous stalhrim war axe": [
    "Stalhrimis Bellisecuris Virtutis"
  ],
  "stalhrim shield of dwindling frost": [
    "Stalhrimis Scutum Glaciei Diminuendi"
  ],
  "nordic greatsword of consuming": [
    "Germanica Spatha Consumendi"
  ],
  "bonemold helmet of major conjuration": [
    "Ossea Galea Maioris Vocandi"
  ],
  "all dragonborn weapons": [
    "All Dragonborn Weapons"
  ],
  "nordic war axe of binding": [
    "Germanicus Bellisecuris Deligandi"
  ],
  "nordic dagger of ice": [
    "Germanicus Pugio Glaciei"
  ],
  "stalhrim bow of annihilating": [
    "Stalhrimis Arcus Annihilandi"
  ],
  "chitin shield of eminent blocking": [
    "Chitonos Scutum Eminentis Interponendi"
  ],
  "nordic greatsword of expelling": [
    "Germanica Spatha Expellendi"
  ],
  "nordic gauntlets of major archery": [
    "Germanica Manubria Cavata Maioris Sagittationis"
  ],
  "nordic bow of expelling": [
    "Germanicus Arcus Expellendi"
  ],
  "nordic shield of dwindling fire": [
    "Germanicum Scutum Cavum Flammarum Diminuentium"
  ],
  "stalhrim mace of dread": [
    "Stalhrimis Maca Phobiae"
  ],
  "nordic war axe of winnowing": [
    "Germanicus Bellisecuris Evanescendi"
  ],
  "nordic gauntlets of eminent sure grip": [
    "Germanica Manubria Cavata Eminentis Ambidexteritatis"
  ],
  "nordic bow of torpor": [
    "Germanicus Arcus Torporis"
  ],
  "ring of the hunt": [
    "Anulus Venandi"
  ],
  "nordic shield of dwindling magic": [
    "Germanicum Scutum Cavum Diminuentis Magicae"
  ],
  "nordic helmet of eminent alteration": [
    "Germanica Galea Cavata Eminentis Alterationis"
  ],
  "stalhrim armor of major restoration": [
    "Stalhrimis Lorica Levis Maioris Recreandi"
  ],
  "chitin bracers of lockpicking": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Clavicarii"
  ],
  "captain veleth sent me": [
    "Captain Veleth mihi misit"
  ],
  "nordic sword of harrowing": [
    "Germanicus Gladius Occandi"
  ],
  "nordic gauntlets of extreme wielding": [
    "Germanica Manubria Cavata Extremis Dexteritatis"
  ],
  "stalhrim sword of high chaos": [
    "Stalhrimis Gladius Chaos Magni"
  ],
  "stalhrim boots of strength": [
    "Stalhrimis Caligulae Fotitudinis"
  ],
  "spawns a glowing spider that will follow you around. only one can be spawned at a time": [
    "Araneam Lucentem vocat quae te sequetur"
  ],
  "stalhrim war axe of stunning": [
    "Stalhrimis Bellisecuris Pausandi"
  ],
  "liesl": [
    "Liesl"
  ],
  "morag tong armor": [
    "Morag Tong Lorica"
  ],
  "nordic greatsword of lethargy": [
    "Germanica Spatha Dormientis"
  ],
  "stalhrim battleaxe of malediction": [
    "Stalhrimis Bellisecuris Maledictionis"
  ],
  "stalhrim warhammer of petrifying": [
    "Stalhrimis Bellimalleus Lapidefaciendi"
  ],
  "find the black book in <alias=dungeon>": [
    "Inveni Librum Atrum in <Alias=Dungeon>"
  ],
  "stalhrim armor of the major squire": [
    "Stalhrimis Lorica Levis Maioris Armigeris"
  ],
  "stalhrim boots of extreme stamina": [
    "Stalhrimis Caligae Extremis Staminae"
  ],
  "saden's journal": [
    "Ephemerides Sadenis"
  ],
  "nordic battleaxe of torpor": [
    "Germanicus Bellisecuris Torporis"
  ],
  "stalhrim armor of eminent health": [
    "Stalhrimis Lorica Eminentis Salutis"
  ],
  "bonemold boots of resist frost": [
    "Osseae Caligae Glaciem Resistendi"
  ],
  "nordic armor of major alteration": [
    "Germanica Lorica Cavata Maioris Alterationis"
  ],
  "nordic armor of extreme destruction": [
    "Germanica Lorica Cavata Extremis Destructionis"
  ],
  "stalhrim bow of despair": [
    "Stalhrimis Arcus Timoris"
  ],
  "icewraith fx abilities": [
    "IceWraith fx abilities",
    "Ice Wraith FX Abilities"
  ],
  "nordic bow of debilitation": [
    "Germanicus Arcus Deminuendi"
  ],
  "stalhrim bow of banishing": [
    "Stalhrimis Arcus Dimittendi"
  ],
  "stalhrim warhammer of expelling": [
    "Stalhrimis Bellimalleus Expellendi"
  ],
  "ahzidal's warding": [
    "Praesidium Ahzidalis"
  ],
  "are you going to be able to lead this place, still": [
    "Hoc locum iam ducere poteris"
  ],
  "nordic helmet of major illusion": [
    "Germanica Galea Cavata Maioris Inlusionis"
  ],
  "stalhrim warhammer of the vampire": [
    "Stalhrimis Bellimalleus Vampiri"
  ],
  "nordic dagger of harrowing": [
    "Germanicus Pugio Occandi"
  ],
  "stalhrim war axe of lethargy": [
    "Stalhrimis Bellisecuris Dormientis"
  ],
  "stalhrim war axe of animus": [
    "Stalhrimis Bellisecuris Animi"
  ],
  "stalhrim war axe of winter": [
    "Stalhrimis Bellisecuris Hiemis"
  ],
  "stalhrim helmet of peerless illusion": [
    "Stalhrimis Galea Optimae Inlusionis"
  ],
  "bonemold gauntlets of major destruction": [
    "Ossea Manubria Maioris Destructionis"
  ],
  "nordic greatsword of freezing": [
    "Germanica Spatha Glaciei"
  ],
  "chitin shield of flame suppression": [
    "Chitonos Scutum Suppressionis Ignis"
  ],
  "holy stalhrim greatsword": [
    "Sancta Stalhrimis Spatha"
  ],
  "stalhrim war axe of devouring": [
    "Stalhrimis Bellisecuris Consumendi"
  ],
  "electrified water hazard": [
    "Electrified Water Hazard"
  ],
  "hjalfar": [
    "Hjalfar"
  ],
  "he'll no longer send ash spawn to raven rock": [
    "Non iam filios cineris Corvicisaxum mittere potest"
  ],
  "bonemold gauntlets of major alteration": [
    "Ossea Manubria Maioris Alterationis"
  ],
  "stalhrim sword of subsuming": [
    "Stalhrimis Gladius Subsumendi"
  ],
  "virtuous nordic battleaxe": [
    "Germanicus Bellisecuris Virtutis"
  ],
  "bonemold gauntlets of sure grip": [
    "Ossea Manubria Ambidexteritatis"
  ],
  "all dragonborn books": [
    "All Dragonborn Books"
  ],
  "dynamo actuator": [
    "Actuator Dynamis"
  ],
  "stalhrim battleaxe of evoking": [
    "Stalhrimis Bellisecuris Evocandi"
  ],
  "stalhrim helmet of waterbreathing": [
    "Stalhrimis Galea Sub Aqua Respirandi"
  ],
  "stalhrim sword of leeching": [
    "Stalhrimis Gladius Sanguisuginis"
  ],
  "miraak teleport return": [
    "Miraak Teleport Return"
  ],
  "stalhrim shield of extreme blocking": [
    "Stalhrimis Scutum Extremis Interponendi"
  ],
  "stalhrim armor of major alteration": [
    "Stalhrimis Lorica Levis Maioris Alterationis"
  ],
  "stalhrim mace of winnowing": [
    "Stalhrimis Maca Evanescendi"
  ],
  "the poison song, book iii": [
    "Carmen Veneni, Liber III"
  ],
  "poison song, book iii": [
    "Carmen Veneni, Liber III"
  ],
  "merilar's journal": [
    "Ephemerides Merilaris"
  ],
  "stalhrim greatsword of despair": [
    "Stalhrimis Spatha Timoris"
  ],
  "stalhrim armor of major destruction": [
    "Stalhrimis Lorica Levis Maioris Destructionis"
  ],
  "stalhrim sword of stunning": [
    "Stalhrimis Gladius Pausandi"
  ],
  "stalhrim armor of the peerless knight": [
    "Stalhrimis Lorica Optimi Equitis"
  ],
  "chitin bracers of eminent lockpicking": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Eminentis Clavicarii"
  ],
  "stalhrim armor of peerless destruction": [
    "Stalhrimis Lorica Optimae Destructionis"
  ],
  "holy stalhrim dagger": [
    "Sanctus Stalhrimis Pugio"
  ],
  "nordic shield of dwindling frost": [
    "Germanicum Scutum Cavum Glaciei Diminuendi"
  ],
  "stalhrim helmet of peerless destruction": [
    "Stalhrimis Galea Optimae Destructionis"
  ],
  "stalhrim warhammer of despair": [
    "Stalhrimis Bellimalleus Timoris"
  ],
  "chitin shield of dwindling magic": [
    "Chitonos Scutum Diminuentis Magicae"
  ],
  "nordic helmet of extreme alteration": [
    "Germanica Galea Cavata Extremis Alterationis"
  ],
  "stalhrim bow of exhaustion": [
    "Stalhrimis Arcus Exhaustionis"
  ],
  "bonemold shield of dwindling frost": [
    "Osseum Scutum Glaciei Diminuendi"
  ],
  "control switch": [
    "Fulcrum Machinae"
  ],
  "bonemold boots of dwindling frost": [
    "Osseae Caligae Glaciei Diminuendi"
  ],
  "nordic war axe of garnering": [
    "Germanicus Bellisecuris Bibendi"
  ],
  "stalhrim greatsword of high chaos": [
    "Stalhrimis Spatha Chaos Magni"
  ],
  "nordic armor of extreme conjuring": [
    "Germanica Lorica Cavata Extremis Vocandi"
  ],
  "nordic helmet of waterbreathing": [
    "Germanica Galea Cavata Sub Aqua Respirandi"
  ],
  "nordic armor of eminent restoration": [
    "Germanica Lorica Cavata Eminentis Recreandi"
  ],
  "nordic helmet of extreme magicka": [
    "Germanica Galea Cavata Extremis Magicae"
  ],
  "nordic armor of extreme health": [
    "Germanica Lorica Cavata Extremis Salutis"
  ],
  "all dragonborn ammo": [
    "All Dragonborn Ammo"
  ],
  "writ of execution: <alias=player>": [
    "Declaratio Supplicii: <alias=player>"
  ],
  "bonemold armor of health": [
    "Ossea Lorica Salutis"
  ],
  "bonemold shield": [
    "Osseum Scutum"
  ],
  "niyya": [
    "Niyya"
  ],
  "chitin shield of magic suppression": [
    "Chitonos Scutum Suppressionis Magicaeis"
  ],
  "stalhrim greatsword of expelling": [
    "Stalhrimis Spatha Expellendi"
  ],
  "stalhrim greatsword of enervating": [
    "Stalhrimis Spatha Emoliendi"
  ],
  "nordic warhammer of debilitation": [
    "Germanicus Bellimalleus Deminuendi"
  ],
  "stalhrim gauntlets of eminent alchemy": [
    "Stalhrimis Manubria Levia Eminentis Alchemiae"
  ],
  "nordic dagger of winnowing": [
    "Germanicus Pugio Evanescendi"
  ],
  "nordic war axe of expelling": [
    "Germanicus Bellisecuris Expellendi"
  ],
  "nordic dagger of malediction": [
    "Germanicus Pugio Maledictionis"
  ],
  "chitin helmet of restoration": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Recreandi"
  ],
  "bring the evidence to adril arano": [
    "Fer evidentiam ad Adril Arano"
  ],
  "unenchanted restoration staff": [
    "Rudis Restorationis Parata"
  ],
  "nordic armor of extreme alteration": [
    "Germanica Lorica Cavata Extremis Alterationis"
  ],
  "nordic greatsword of harvesting": [
    "Germanica Spatha Saturni"
  ],
  "the axe man": [
    "Homo 'Securis'"
  ],
  "axe man": [
    "Homo 'Securis'"
  ],
  "nordic battleaxe of stunning": [
    "Germanicus Bellisecuris Pausandi"
  ],
  "spider visuals shock": [
    "Spider Visuals Shock"
  ],
  "nordic warhammer of garnering": [
    "Germanicus Bellimalleus Bibendi"
  ],
  "recipe - jumping frost spider 2x": [
    "Dictamen - Aranea Glaciei Saltans 2x"
  ],
  "all dragonborn spell tomes": [
    "All Dragonborn Spell Tomes"
  ],
  "black book reward 3 exclude": [
    "Black Book Reward 3 Exclude"
  ],
  "stalhrim mace of blizzards": [
    "Stalhrimis Maca Hiemis"
  ],
  "stalhrim war axe of extreme chaos": [
    "Stalhrimis Bellisecuris Chaos Extremis"
  ],
  "stalhrim battleaxe of despair": [
    "Stalhrimis Bellisecuris Timoris"
  ],
  "stalhrim battleaxe of terror": [
    "Stalhrimis Bellisecuris Terroris"
  ],
  "chitin helmet of conjuring": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Galea Gravis Vocandi"
  ],
  "stalhrim battleaxe of harvesting": [
    "Stalhrimis Bellisecuris Saturni"
  ],
  "stalhrim battleaxe of garnering": [
    "Stalhrimis Bellisecuris Bibendi"
  ],
  "bonemold shield of major blocking": [
    "Osseum Scutum Maioris Interponendi"
  ],
  "stalhrim shield of major blocking": [
    "Stalhrimis Scutum Maioris Interponendi"
  ],
  "stalhrim battleaxe of debilitation": [
    "Stalhrimis Bellisecuris Deminuendi"
  ],
  "stalhrim warhammer of annihilating": [
    "Stalhrimis Bellimalleus Annihilandi"
  ],
  "words of the wind": [
    "Verba Venti"
  ],
  "nordic dagger of blizzards": [
    "Germanicus Pugio Hiemis"
  ],
  "stalhrim bow of stunning": [
    "Stalhrimis Arcus Pausandi"
  ],
  "halbarn services": [
    "Halbarn Services"
  ],
  "lurker fx": [
    "Lurker FX"
  ],
  "nordic mace of shocks": [
    "Germanica Maca Electricitatis"
  ],
  "recipe - glowing spider": [
    "Dictamen - Aranea Lucifer"
  ],
  "stalhrim dagger of annihilating": [
    "Stalhrimis Pugio Annihilandi"
  ],
  "stalhrim dagger of animus": [
    "Stalhrimis Pugio Animi"
  ],
  "stalhrim dagger of debilitation": [
    "Stalhrimis Pugio Deminuendi"
  ],
  "stalhrim dagger of lethargy": [
    "Stalhrimis Pugio Dormientis"
  ],
  "stalhrim greatsword of the vampire": [
    "Stalhrimis Spatha Vampiri"
  ],
  "bonemold boots of hauling": [
    "Osseae Caligae Portandi"
  ],
  "sell stalhrim armor and weapons to ancarion": [
    "Vende Loricam et Arma Stalhrimi ad Ancarion"
  ],
  "stalhrim greatsword of lightning": [
    "Stalhrimis Spatha Fulminis"
  ],
  "nordic bow of malediction": [
    "Germanicus Arcus Maledictionis"
  ],
  "stalhrim war axe of despair": [
    "Stalhrimis Bellisecuris Timoris"
  ],
  "reverent nordic greatsword": [
    "Reverens Germanica Spatha"
  ],
  "stalhrim shield of shock abatement": [
    "Stalhrimis Scutum Subsidentiae Electricitatis"
  ],
  "stalhrim war axe of banishing": [
    "Stalhrimis Bellisecuris Dimittendi"
  ],
  "bonemold gauntlets of minor alteration": [
    "Ossea Manubria Minoris Alterationis"
  ],
  "stalhrim war axe of petrifying": [
    "Stalhrimis Bellisecuris Lapidefaciendi"
  ],
  "nordic helmet of extreme alchemy": [
    "Germanica Galea Cavata Extremis Alchemiae"
  ],
  "stalhrim greatsword of ultimate chaos": [
    "Stalhrimis Spatha Chaos Ultimi"
  ],
  "nordic shield of frost abatement": [
    "Germanicum Scutum Cavum Minoris Frigoris"
  ],
  "stalhrim battleaxe of leeching": [
    "Stalhrimis Bellisecuris Sanguisuginis"
  ],
  "nordic dagger of thunderbolts": [
    "Germanicus Pugio Tonitruum"
  ],
  "he's no longer commanding the ash spawn": [
    "Non iam filiis cineris imperat"
  ],
  "nordic boots of major stamina": [
    "Germanicae Caligae Cavatae Maioris Staminae"
  ],
  "bonemold gauntlets of minor smithing": [
    "Ossea Manubria Minoris Faciendi"
  ],
  "stalhrim mace of thunderbolts": [
    "Stalhrimis Maca Tonitruum"
  ],
  "nordic mace of consuming": [
    "Germanica Maca Consumendi"
  ],
  "nordic battleaxe of fire": [
    "Germanicus Bellisecuris Ignis"
  ],
  "stalhrim bow of petrifying": [
    "Stalhrimis Arcus Lapidefaciendi"
  ],
  "stalhrim sword of evoking": [
    "Stalhrimis Gladius Evocandi"
  ],
  "stalhrim sword of the sorcerer": [
    "Stalhrimis Gladius Thaumaturgos"
  ],
  "stalhrim mace of immobilizing": [
    "Stalhrimis Maca Conligandi"
  ],
  "stalhrim mace of subsuming": [
    "Stalhrimis Maca Subsumendi"
  ],
  "stalhrim warhammer of evoking": [
    "Stalhrimis Bellimalleus Evocandi"
  ],
  "stalhrim sword of blizzards": [
    "Stalhrimis Gladius Hiemis"
  ],
  "stalhrim shield of frost abatement": [
    "Stalhrimis Scutum Minoris Frigoris"
  ],
  "stalhrim war axe of leeching": [
    "Stalhrimis Bellisecuris Sanguisuginis"
  ],
  "stalhrim war axe of depleting": [
    "Stalhrimis Bellisecuris Deplendi"
  ],
  "stalhrim dagger of immobilizing": [
    "Stalhrimis Pugio Conligandi"
  ],
  "stalhrim war axe of high chaos": [
    "Stalhrimis Bellisecuris Chaos Magni"
  ],
  "stalhrim war axe of terror": [
    "Stalhrimis Bellisecuris Terroris"
  ],
  "stalhrim sword of annihilating": [
    "Stalhrimis Gladius Annihilandi"
  ],
  "nordic sword of debilitation": [
    "Germanicus Gladius Deminuendi"
  ],
  "stalhrim war axe of lightning": [
    "Stalhrimis Bellisecuris Fulminis"
  ],
  "virtuous stalhrim mace": [
    "Stalhrimis Maca Virtutis"
  ],
  "dragons breath": [
    "Exhalatio Draconis"
  ],
  "stalhrim warhammer of leeching": [
    "Stalhrimis Bellimalleus Sanguisuginis"
  ],
  "stalhrim gauntlets of major lockpicking": [
    "Stalhrimis Manubria Levia Maioris Clavicarii"
  ],
  "nordic bow of scorching": [
    "Germanicus Arcus Incendendi"
  ],
  "stalhrim bow of high chaos": [
    "Stalhrimis Arcus Chaos Magni"
  ],
  "palevius lex": [
    "Palevius Lex"
  ],
  "lurker abilitites": [
    "Lurker Abilitites"
  ],
  "stalhrim warhammer of high chaos": [
    "Stalhrimis Bellimalleus Chaos Magni"
  ],
  "nordic warhammer of expelling": [
    "Germanicus Bellimalleus Expellendi"
  ],
  "stalhrim warhammer of ultimate chaos": [
    "Stalhrimis Bellimalleus Chaos Ultimi"
  ],
  "stalhrim greatsword of winter": [
    "Stalhrimis Spatha Hiemis"
  ],
  "nordic war axe of evoking": [
    "Germanicus Bellisecuris Evocandi"
  ],
  "stalhrim shield of magic suppression": [
    "Stalhrimis Scutum Suppressionis Magicaeis"
  ],
  "nordic battleaxe of winnowing": [
    "Germanicus Bellisecuris Evanescendi"
  ],
  "nordic bow of depleting": [
    "Germanicus Arcus Deplendi"
  ],
  "stalhrim gauntlets of major deft hands": [
    "Stalhrimis Manubria Levia Maioris Furis"
  ],
  "stalhrim bow of lightning": [
    "Stalhrimis Arcus Fulminis"
  ],
  "nordic battleaxe of blizzards": [
    "Germanicus Bellisecuris Hiemis"
  ],
  "stalhrim war axe of the sorcerer": [
    "Stalhrimis Bellisecuris Thaumaturgos"
  ],
  "stalhrim greatsword of freezing": [
    "Stalhrimis Spatha Glaciei"
  ],
  "nordic battleaxe of lightning": [
    "Germanicus Bellisecuris Fulminis"
  ],
  "nordic sword of animus": [
    "Germanicus Gladius Animi"
  ],
  "nordic battleaxe of malediction": [
    "Germanicus Bellisecuris Maledictionis"
  ],
  "stalhrim sword of expelling": [
    "Stalhrimis Gladius Expellendi"
  ],
  "nordic battleaxe of lethargy": [
    "Germanicus Bellisecuris Dormientis"
  ],
  "chitin shield of dwindling frost": [
    "Chitonos Scutum Glaciei Diminuendi"
  ],
  "nordic battleaxe of banishing": [
    "Germanicus Bellisecuris Dimittendi"
  ],
  "ring of bloodlust": [
    "Anulus Sanguinis Amantis"
  ],
  "morag tong hood": [
    "Morag Tong Capucium"
  ],
  "stalhrim bow of ultimate chaos": [
    "Stalhrimis Arcus Chaos Ultimi"
  ],
  "stalhrim helmet of recovery": [
    "Stalhrimis Galea Recuperandi"
  ],
  "nordic dagger of leeching": [
    "Germanicus Pugio Sanguisuginis"
  ],
  "spare me the walk": [
    "Nolo ambulare"
  ],
  "stalhrim battleaxe of depleting": [
    "Stalhrimis Bellisecuris Deplendi"
  ],
  "stalhrim mace of banishing": [
    "Stalhrimis Maca Dimittendi"
  ],
  "stalhrim gauntlets of extreme lockpicking": [
    "Stalhrimis Manubria Levia Extremis Clavicarii"
  ],
  "stalhrim gauntlets of eminent deft hands": [
    "Stalhrimis Manubria Levia Eminentis Furis"
  ],
  "hallowed stalhrim dagger": [
    "Stalhrimis Pugio Sacer"
  ],
  "nordic dagger of immobilizing": [
    "Germanicus Pugio Conligandi"
  ],
  "nordic sword of malediction": [
    "Germanicus Gladius Maledictionis"
  ],
  "nordic helmet of extreme illusion": [
    "Germanica Galea Cavata Extremis Inlusionis"
  ],
  "nordic dagger of debilitation": [
    "Germanicus Pugio Deminuendi"
  ],
  "holy stalhrim sword": [
    "Sanctus Stalhrimis Gladius"
  ],
  "spider visuals glowing": [
    "Spider Visuals Glowing"
  ],
  "nordic greatsword of leeching": [
    "Germanica Spatha Sanguisuginis"
  ],
  "club pound": [
    "Club Pound"
  ],
  "chitin boots of brawn": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Caligae Fortitudinis"
  ],
  "nordic greatsword of fire": [
    "Germanica Spatha Ignis"
  ],
  "nordic mace of evoking": [
    "Germanica Maca Evocandi"
  ],
  "nordic greatsword of ice": [
    "Germanica Spatha Glaciei"
  ],
  "stalhrim mace of garnering": [
    "Stalhrimis Maca Bibendi"
  ],
  "nordic war axe of leeching": [
    "Germanicus Bellisecuris Sanguisuginis"
  ],
  "nordic battleaxe of reaping": [
    "Germanicus Bellisecuris Decerpendi"
  ],
  "nordic greatsword of debilitation": [
    "Germanica Spatha Deminuendi"
  ],
  "bonemold shield of blocking": [
    "Osseum Scutum Interponendi"
  ],
  "bonemold gauntlets of major lockpicking": [
    "Ossea Manubria Maioris Clavicarii"
  ],
  "power of apocrypha": [
    "Vis Apocryphae"
  ],
  "nordic mace of diminishing": [
    "Germanica Maca Dinimuendi"
  ],
  "nordic mace of immobilizing": [
    "Germanica Maca Conligandi"
  ],
  "nordic greatsword of winnowing": [
    "Germanica Spatha Evanescendi"
  ],
  "magic resistance": [
    "Magic Resistance",
    "Resistentia Magicae"
  ],
  "virtuous nordic mace": [
    "Germanica Maca Virtutis"
  ],
  "nordic sword of devouring": [
    "Germanicus Gladius Consumendi"
  ],
  "bonemold helmet of destruction": [
    "Ossea Galea Destructionis"
  ],
  "nordic sword of enervating": [
    "Germanicus Gladius Emoliendi"
  ],
  "hallowed stalhrim mace": [
    "Stalhrimis Maca Sacra"
  ],
  "stalhrim battleaxe of immobilizing": [
    "Stalhrimis Bellisecuris Conligandi"
  ],
  "nordic war axe of consuming": [
    "Germanicus Bellisecuris Consumendi"
  ],
  "nordic battleaxe of devouring": [
    "Germanicus Bellisecuris Consumendi"
  ],
  "nordic helmet of major alchemy": [
    "Germanica Galea Cavata Maioris Alchemiae"
  ],
  "changed ones": [
    "Mutati"
  ],
  "frost barrier": [
    "Paries Glaciei"
  ],
  "nordic war axe of immobilizing": [
    "Germanicus Bellisecuris Conligandi"
  ],
  "stalhrim boots of extreme sneaking": [
    "Stalhrimis Caligulae Extremis Rependi"
  ],
  "nordic war axe of torpor": [
    "Germanicus Bellisecuris Torporis"
  ],
  "nordic dagger of despair": [
    "Germanicus Pugio Timoris"
  ],
  "nordic mace of lethargy": [
    "Germanica Maca Dormientis"
  ],
  "nordic warhammer of fire": [
    "Germanicus Bellimalleus Ignis"
  ],
  "stalhrim sword of winnowing": [
    "Stalhrimis Gladius Evanescendi"
  ],
  "bonemold helmet of major alteration": [
    "Ossea Galea Maioris Alterationis"
  ],
  "nordic warhammer of shocks": [
    "Germanicus Bellimalleus Electricitatis"
  ],
  "ildari's journal, vol. ii": [
    "Ephemerides Ildaris, Vol. II"
  ],
  "stalhrim warhammer of animus": [
    "Stalhrimis Bellimalleus Animi"
  ],
  "nordic helmet of major alteration": [
    "Germanica Galea Cavata Maioris Alterationis"
  ],
  "nordic bow of immobilizing": [
    "Germanicus Arcus Conligandi"
  ],
  "stalhrim boots of dwindling flames": [
    "Stalhrimis Caligulae Flammarum Diminuentium"
  ],
  "stalhrim sword of the vampire": [
    "Stalhrimis Gladius Vampiri"
  ],
  "castor the puzzlemaker's corpse": [
    "Castor Aenigmator"
  ],
  "stalhrim sword of terror": [
    "Stalhrimis Gladius Terroris"
  ],
  "merilar's cage door key": [
    "Merilaris Capsi Clavis"
  ],
  "bone, part i": [
    "Os, Pars I"
  ],
  "stalhrim sword of freezing": [
    "Stalhrimis Gladius Glaciei"
  ],
  "chitin bracers of deft hands": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Manubria Furis"
  ],
  "letter to imperial city": [
    "Epistula ad Urbem Imperialem"
  ],
  "nordic shield of fire abatement": [
    "Germanicum Scutum Cavum Subsidentiae Ignis"
  ],
  "nordic war axe of blizzards": [
    "Germanicus Bellisecuris Hiemis"
  ],
  "stalhrim armor of peerless conjuring": [
    "Stalhrimis Lorica Optimi Vocandi"
  ],
  "nordic sword of fear": [
    "Germanicus Gladius Timoris"
  ],
  "bonemold gauntlets of major conjuring": [
    "Ossea Manubria Maioris Vocandi"
  ],
  "stalhrim dagger of damnation": [
    "Stalhrimis Pugio Damnationis"
  ],
  "solstheim mudcrab": [
    "Luticanceris Solstheim"
  ],
  "nordic mace of malediction": [
    "Germanica Maca Maledictionis"
  ],
  "bonemold gauntlets of destruction": [
    "Ossea Manubria Destructionis"
  ],
  "bonemold shield of dwindling flames": [
    "Osseum Scutum Flammarum Diminuentium"
  ],
  "chitin shield of major blocking": [
    "Chitonos Scutum Maioris Interponendi"
  ],
  "the poison song, book vi": [
    "Carmen Veneni, Liber VI"
  ],
  "poison song, book vi": [
    "Carmen Veneni, Liber VI"
  ],
  "stalhrim helmet of major magicka": [
    "Stalhrimis Galea Levis Maioris Magicae"
  ],
  "he's dead, but i at least found the formula": [
    "Mortuus est, sed formulam inveni"
  ],
  "horker king": [
    "Horker King"
  ],
  "what do you know about miraak": [
    "Quid de Miraak scis"
  ],
  "blackguard's armor": [
    "Obscuricustodis Lorica"
  ],
  "riekling (melee 1)": [
    "Riekling (Melee 1)"
  ],
  "waterdamageshallow": [
    "WaterDamageShallow"
  ],
  "what if she's right and i don't find anything": [
    "Quid accidet si illa recte dixit et nihil invenero"
  ],
  "blackguard's boots": [
    "Obscuricustodis Caligae"
  ],
  "ash spawn 3": [
    "Ash Spawn 3"
  ],
  "glover's guild key": [
    "Glover Clavis Sodalitatis"
  ],
  "what did you make of that stone structure everyone was building": [
    "Quid intellexisti de illo aedificio lapideo quod omnes fecerunt"
  ],
  "seeker 2 (mirror)": [
    "Seeker 2 (Mirror)"
  ],
  "fire death": [
    "Fire Death"
  ],
  "esmond": [
    "Esmond"
  ],
  "bonemold formula": [
    "Formula Ossaformae"
  ],
  "waterdamage": [
    "WaterDamage"
  ],
  "spawns the selected npc": [
    "Spawns the Selected NPC"
  ],
  "summons spectral instruments that play for you for <dur> seconds": [
    "Voca instrumanta spectralia quod pro te canunt <dur> secundas"
  ],
  "hulking draugr": [
    "Draugr Ingens"
  ],
  "a daedra": [
    "Daedra"
  ],
  "daedra": [
    "Daedra"
  ],
  "chapter iii": [
    "Capitulum III"
  ],
  "i need passage to solstheim": [
    "Commeatum ad Solstheim quaero"
  ],
  "benthic lurker faction": [
    "Benthic Lurker Faction"
  ],
  "boiler control pedestal": [
    "Bas Machinae Fervendi"
  ],
  "not now": [
    "Non nunc"
  ],
  "mudcrab boss (old salty)": [
    "Mudcrab Boss (Old Salty)"
  ],
  "glover's letter": [
    "Epistula Gloveris"
  ],
  "bjornolfr": [
    "Bjornolfr"
  ],
  "blackguard's hood": [
    "Obscuricustodis Capucium"
  ],
  "and your parents": [
    "Et patres tui"
  ],
  "why sail there at all then": [
    "Quamobrem, ergo, illic umquam navigas"
  ],
  "riekling (missile 1)": [
    "Riekling (Missile 1)"
  ],
  "chapter iv": [
    "Capitulum IV"
  ],
  "stalhrim greatsword of devouring": [
    "Stalhrimis Spatha Consumendi"
  ],
  "blackguard's nimble fingers": [
    "Blackguard's Nimble Fingers"
  ],
  "benthic lurker": [
    "Benthic Lurker"
  ],
  "bring the bonemold formula to glover mallory": [
    "Fer Formulam Ossformae ad Glover Mallory"
  ],
  "seeker 2": [
    "Seeker 2"
  ],
  "and you've been alone ever since": [
    "Et ab illo tempore solus fuisti"
  ],
  "why do i need to learn a word of power": [
    "Quamobrem Verbum Potestatis discere debeo"
  ],
  "and the skaal don't mind being studied": [
    "Et Skaal de studio non curant"
  ],
  "raven rock arrival scene": [
    "Raven Rock Arrival Scene"
  ],
  "esmond tyne": [
    "Esmond Tyne"
  ],
  "riekling (boar)": [
    "Riekling (Boar)"
  ],
  "where's esmond tyne now": [
    "Ubi est Esmond Tyne nunc"
  ],
  "seeker 3 (mirror)": [
    "Seeker 3 (Mirror)"
  ],
  "seeker 3": [
    "Seeker 3"
  ],
  "seeker 1 (mirror)": [
    "Seeker 1 (Mirror)"
  ],
  "summons a spectral dragon for <dur> seconds": [
    "Draconem Spectralem <dur> secundas vocat"
  ],
  "chapter v": [
    "Capitulum V"
  ],
  "seeker 1": [
    "Seeker 1"
  ],
  "he stole something from you, right": [
    "Aliquid abs te furatus est, nonne"
  ],
  "chapter ii": [
    "Capitulum II"
  ],
  "kruziikrel": [
    "Kruziikrel"
  ],
  "relonikiv": [
    "Relonikiv"
  ],
  "bloodstained letter": [
    "Epistula Sanguinosa"
  ],
  "ash spawn 1": [
    "Ash Spawn 1"
  ],
  "dwarven ballista test": [
    "Dwemer Ballista Test"
  ],
  "ash spawn 2": [
    "Ash Spawn 2"
  ],
  "thaekyd": [
    "Thaekyd"
  ],
  "miraak cultist 2": [
    "Miraak Cultist 2"
  ],
  "miraak cultist 1": [
    "Miraak Cultist 1"
  ],
  "i'll get that formula back for you": [
    "Illam formulam tibi feram"
  ],
  "centurion boss": [
    "Centurion Boss"
  ],
  "centurion fight 3": [
    "Centurion Fight 3"
  ],
  "dwarven sphere 2": [
    "Dwemer Sphere 2"
  ],
  "dwarven sphere 1": [
    "Dwemer Sphere 1"
  ],
  "then it dried up": [
    "Deinde siccus factus est"
  ],
  "albino poison": [
    "Albino Poison"
  ],
  "blackguard's sly of hand": [
    "Blackguard's Sly of Hand"
  ],
  "blackguard's voice": [
    "Blackguard's Voice"
  ],
  "how much will it cost me": [
    "Quot nummis mihi constabit"
  ],
  "why were you arguing with aphia": [
    "Quamobrem cum Aphia argutus es"
  ],
  "what do you mean, 'free your people from control?'": [
    "Quid sibi vult, 'tuos liberare?'"
  ],
  "wraith": [
    "Wraith"
  ],
  "tenticle explosion": [
    "Tenticle explosion"
  ],
  "miraak's gloves": [
    "Miraakis Manubria"
  ],
  "lurker spray": [
    "Lurker Spray"
  ],
  "blackguard's hidden pockets": [
    "Blackguard's Hidden Pockets"
  ],
  "dragonbreathbodyartholder": [
    "DragonBreathBodyArtHolder"
  ],
  "recover the bonemold formula for glover mallory": [
    "Recipe Formulam Ossaformae pro Glover Mallory"
  ],
  "paid in full": [
    "Omnino Solutum"
  ],
  "the thieves guild has a problem out here": [
    "Sodalitas Furum problema hic habet"
  ],
  "thieves guild has a problem out here": [
    "Sodalitas Furum problema hic habet"
  ],
  "riekling (chief)": [
    "Riekling (Chief)"
  ],
  "acid spit": [
    "Acidum Sputa"
  ],
  "scrye": [
    "Scrye"
  ],
  "conjure spectral dragon": [
    "Voca Draconem Spectralem"
  ],
  "nothing at all": [
    "Nihil"
  ],
  "chitin plate": [
    "Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ Lamina"
  ],
  "chapter i": [
    "Capitulum I"
  ],
  "he's well": [
    "Bene se habet"
  ],
  "miraak's robes": [
    "Miraakis Peplum"
  ],
  "blackguard's gloves": [
    "Obscuricustodis Manubria"
  ],
  "spriggan (burnt)": [
    "Spriggan (Burnt)"
  ],
  "leveled cultist": [
    "Levelled Cultist"
  ],
  "waterdamageswimming": [
    "WaterDamageSwimming"
  ],
  "i noticed the shadowmark by your door": [
    "Vidi Signum Umbrae prope ianuam tuam"
  ],
  "why did your mother leave the village": [
    "Quamobrem mater tua e villa discedit"
  ],
  "riekling (missile 2)": [
    "Riekling (Missile 2)"
  ],
  "who is building that shrine": [
    "Quis illam aram facit"
  ],
  "vahlok the jailer": [
    "Vahlok Incarcerator"
  ],
  "netch (cow)": [
    "Netch (Cow)"
  ],
  "frost damage": [
    "Vulnus Glaciei"
  ],
  "netch (calf)": [
    "Netch (Calf)"
  ],
  "apothecary's satchel": [
    "Cibisin Apothecarii",
    "Cibisis Apothecarii"
  ],
  "tell me about the shrine outside of town": [
    "Dic mihi de ara foris oppidi"
  ],
  "netch (bull)": [
    "Netch (Bull)"
  ],
  "riekling (missile 3)": [
    "Riekling (Missile 3)"
  ],
  "no, what is it": [
    "Minime, quid est"
  ],
  "you mentioned that you lost something": [
    "Commemoravisti te aliquid amisisse"
  ],
  "lusty argonian maid folio": [
    "Folio Virginis Lacerti Amorosae"
  ],
  "fortify stamina rate": [
    "Auge Recuperationem Staminae"
  ],
  "chaos damage": [
    "Chaos Damage"
  ],
  "nordic battleaxe of extreme chaos": [
    "Germanicus Bellisecuris Chaos Extremis"
  ],
  "riekling (melee 2)": [
    "Riekling (Melee 2)"
  ],
  "target lock": [
    "Mane in Inimicum"
  ],
  "why do you think this neloth can help me find black books": [
    "Quamobrem censes iste Neloth mihi auxilium in Libros Atros inveniendo dare posse"
  ],
  "switch targets": [
    "Muta Inimicum"
  ],
  "winterhold college": [
    "Winterhold College",
    "Collegium Hiemiterrae"
  ],
  "who taught you to talk": [
    "Quis tibi artem loquendi didicit"
  ],
  "how do you know": [
    "Quomodo scis"
  ],
  "nordic greatsword of high chaos": [
    "Germanica Spatha Chaos Magni"
  ],
  "you caught me": [
    "Me cepisti"
  ],
  "i'll pay off my bounty": [
    "Vectical meum solvam",
    "Vectigal meum solvam"
  ],
  "(<crimegold> gold)": [
    "(<CrimeGold> auri)",
    "(<CrimeGold> aurum)"
  ],
  "why aren't you affected like everyone else": [
    "Quamobrem non afficeris sicut alii"
  ],
  "fortify speech": [
    "Auge Loquacitatem"
  ],
  "investigate thirsk mead hall and the exiled nords": [
    "Investigate Aulam Hydromelli Thirsk et Germanos Germani Exsules"
  ],
  "locate cindiri's folio from the wreck of the strident squall": [
    "Inveni Folium Cindiri in naufragio Venti Stridentis"
  ],
  "first edition": [
    "Prima Editio"
  ],
  "what do you know about these black books": [
    "Quid de his Libris Atris scis"
  ],
  "dlc2 generic services dialogue": [
    "DLC2 Generic Services Dialogue"
  ],
  "nordic war axe of chaos": [
    "Germanicus Bellisecuris Chaos"
  ],
  "i'll see if i can find the book for you": [
    "Librum tibi quaeram"
  ],
  "how do you survive out here": [
    "Quomodo hic superes"
  ],
  "constant fx for ash spawn": [
    "Constant FX for Ash Spawn"
  ],
  "what were you saying before": [
    "Quid antea dicebas"
  ],
  "nordic war axe of extreme chaos": [
    "Germanicus Bellisecuris Chaos Extremis"
  ],
  "what'll you give me for these": [
    "Quid mihi dabis pro his",
    "Quid mihi pro his dabis"
  ],
  "serpentine dragon": [
    "Draco Serpentinus"
  ],
  "what is your role among the skaal": [
    "Quid est negotium tuum apud Skaal"
  ],
  "nordic mace of high chaos": [
    "Germanica Maca Chaos Magni"
  ],
  "enough about this": [
    "Satis de hoc"
  ],
  "she wants this placed at her mother's grave": [
    "Hoc ad sepulcrum matris poni vult"
  ],
  "come on, boy, i've got something for you": [
    "Veni, catule, aliquid tibi habeo"
  ],
  "thirsk rumors": [
    "Rumours Thirski"
  ],
  "she's not exactly young anymore": [
    "Non iam iuvenis est"
  ],
  "you're making a mistake": [
    "Erras",
    "Valde erras"
  ],
  "nordic dagger of chaos": [
    "Germanicus Pugio Chaos"
  ],
  "50% chance for each element of fire, frost and shock to do <mag> points of damage": [
    "50% casus singulis ut <mag> puncta vulneris ignis, glaciei, et electricitatis facias"
  ],
  "combat dialogue for dragons allied to miraak": [
    "Combat dialogue for dragons allied to Miraak"
  ],
  "i've cleansed the other four stones": [
    "Omnia Saxa mundavi"
  ],
  "runil agreed to your request": [
    "Runil consensit"
  ],
  "nordic mace of extreme chaos": [
    "Germanica Maca Chaos Extremis"
  ],
  "can you train me in enchanting": [
    "Potesne mihi de incantamento docere",
    "Potesne mihi de arte Incantamenti docere"
  ],
  "i'd rather die than go to prison": [
    "Malim mori quam carcerem petere",
    "Noli me ad carcerem ferre. Mori malo"
  ],
  "nordic war axe of high chaos": [
    "Germanicus Bellisecuris Chaos Magni"
  ],
  "a sting that does <mag> points of shock damage to health and half that to magicka": [
    "A sting that Facit <mag> puncta shock damage to Health and half that to Magicka"
  ],
  "sting that does <mag> points of shock damage to health and half that to magicka": [
    "A sting that Facit <mag> puncta shock damage to Health and half that to Magicka"
  ],
  "how long has the farm been abandoned": [
    "Quot annos fundus vacuus fuit"
  ],
  "nordic bow of chaos": [
    "Germanicus Arcus Chaos"
  ],
  "why is there a shrine being built by the water": [
    "Quamobrem ara prope aqua facitur"
  ],
  "ashspawn body fx": [
    "Ash Spawn Body FX"
  ],
  "where are you taking me": [
    "Quo me fers"
  ],
  "you can trust me. i saved your life, remember? (persuade)": [
    "Mihi confidere potes. Vitam tuam servavi. Nonne recordaris? (Persuade)"
  ],
  "why are they so rare": [
    "Quamobram tam rara sunt"
  ],
  "what were they used for": [
    "Pro quo usurpabantur"
  ],
  "actually, i already have it": [
    "Re vera, eum iam habeo"
  ],
  "(give folio)": [
    "(De Folionem)"
  ],
  "nordic greatsword of chaos": [
    "Germanica Spatha Chaos"
  ],
  "we make a good team": [
    "Grex bona sumus"
  ],
  "why not join me for a while": [
    "Quidni mecum breviter convenies"
  ],
  "deathbrand treasure map": [
    "Charta Gazae Mortibrandis"
  ],
  "miraak's faction - just for him": [
    "Miraak's faction - just for him"
  ],
  "i'll make it worth your while": [
    "Tempus dignum tibi erit",
    "Certum faciam ut tempus tuum valebit"
  ],
  "any chance i could talk you into overlooking this": [
    "Potesne eius obilvisci",
    "Estne casus pro quo hanc ignorare possis"
  ],
  "how about i pay you some gold and you look the other way": [
    "Quidni te paulum auri solvam ut id ignores ",
    "Quidni tibi paulum dabo auri et mihi ignosces"
  ],
  "nordic dagger of high chaos": [
    "Germanicus Pugio Chaos Magni"
  ],
  "nordic warhammer of high chaos": [
    "Germanicus Bellimalleus Chaos Magni"
  ],
  "return the folio to cindiri arano in raven rock": [
    "Redde Folium ad Cindiri Arano Corvisaxi"
  ],
  "bera's necklace": [
    "Monile Berae"
  ],
  "nordic sword of high chaos": [
    "Germanicus Gladius Chaos Magni"
  ],
  "i'm with the guild": [
    "Cum sodalitate sum"
  ],
  "how about you look the other way": [
    "Quidni hoc ignores",
    "Potesne crimen ignorare"
  ],
  "nordic battleaxe of chaos": [
    "Germanicus Bellisecuris Chaos"
  ],
  "nordic mace of chaos": [
    "Germanica Maca Chaos"
  ],
  "soul stealer arrow": [
    "Soul Stealer Arrow"
  ],
  "what is the city of blacklight": [
    "Quid est urbs Obscuralux"
  ],
  "about silt striders": [
    "De Cursoribus Adluviei"
  ],
  "nordic sword of chaos": [
    "Germanicus Gladius Chaos"
  ],
  "follow me, i need your help": [
    "Sequere me, auxilio tuo careo"
  ],
  "what happened when i shouted at the wind stone": [
    "Quid accidit post Voce in Saxum Venti usus sum"
  ],
  "what's being built outside of town": [
    "Quid foris oppido aedificatur"
  ],
  "other stones": [
    "Saxa Alia"
  ],
  "can you tell me about the shrine outside of town": [
    "Potesne mihi quidquid de ara foris oppido narrare"
  ],
  "i don't have time for this. do you": [
    "Tempus huic mihi non est. Tibine est"
  ],
  "sorry, can't help you with that": [
    "Ignosce, te cum eo adiuvare non possum"
  ],
  "was it aboard a ship": [
    "In navi erat"
  ],
  "come with me, tribe-kin": [
    "Veni mecum, familaris-tribus"
  ],
  "revus sarvani": [
    "Revus Sarvani"
  ],
  "i've found your missing folio": [
    "Folionem amissum tibi inveni"
  ],
  "ash spawn body effect": [
    "Ash Spawn Body Effect"
  ],
  "morwen of skaal village sent me to ask a favor of you": [
    "Morwen e Vico Skaal me misit ut favorem abs te peterem"
  ],
  "nordic greatsword of extreme chaos": [
    "Germanica Spatha Chaos Extremis"
  ],
  "pod": [
    "Folliculum"
  ],
  "nordic warhammer of chaos": [
    "Germanicus Bellimalleus Chaos"
  ],
  "nordic battleaxe of high chaos": [
    "Germanicus Bellisecuris Chaos Magni"
  ],
  "riekling (melee 3)": [
    "Riekling (Melee 3)"
  ],
  "ashspawn spell": [
    "Ashspawn Spell"
  ],
  "nordic bow of high chaos": [
    "Germanicus Arcus Chaos Magni"
  ],
  "nordic sword of extreme chaos": [
    "Germanicus Gladius Chaos Extremis"
  ],
  "nordic bow of extreme chaos": [
    "Germanicus Arcus Chaos Extremis"
  ],
  "allied apocrypha creatures": [
    "allied apocrypha creatures"
  ],
  "nordic dagger of extreme chaos": [
    "Germanicus Pugio Chaos Extremis"
  ],
  "dwarven black bow of fate": [
    "Atrarcus Dwemeris Fati"
  ],
  "nordic warhammer of extreme chaos": [
    "Germanicus Bellimalleus Chaos Extremis"
  ],
  "maximian's knapsack": [
    "Sacciperium Maximiani"
  ],
  "a letter to selina iii": [
    "Epistula ad Selinam III"
  ],
  "letter to selina iii": [
    "Epistula ad Selinam III"
  ],
  "meet the ebony warrior at the last vigil": [
    "Conveni cum Bellatore Ebeni apud Ultimum Vigillium"
  ],
  "fire stream": [
    "Fire Stream"
  ],
  "a letter to selina iv": [
    "Epistula ad Selinam IV"
  ],
  "letter to selina iv": [
    "Epistula ad Selinam IV"
  ],
  "lightning storm": [
    "Tempestas Tonitruum",
    "Tempestas Electricitatis"
  ],
  "cultist": [
    "Draconicultor"
  ],
  "a letter to selina i": [
    "Epistula ad Selinam I"
  ],
  "letter to selina i": [
    "Epistula ad Selinam I"
  ],
  "conjure seeker": [
    "Voca Quaestorem"
  ],
  "reflect attack": [
    "Oppugnum Reflectit"
  ],
  "arch cultist": [
    "Archidraconicultor"
  ],
  "it has": [
    "Re vera"
  ],
  "last vigil": [
    "Vigilium Ultimum"
  ],
  "summons a high seeker for <dur> seconds wherever the caster is pointing": [
    "Quaestorem Altum vocat <dur> secundas"
  ],
  "enemy power attacks will stagger the attacker": [
    "Ictus Potestatis Inimicorum Oppugnatorem labi cogunt"
  ],
  "frea combat style": [
    "Frea Combat Style"
  ],
  "talk to the ebony warrior": [
    "Adloquere Bellatorem Ebeni"
  ],
  "ascendant cultist": [
    "Draconicultor Ascendens"
  ],
  "summons a seeker for <dur> seconds wherever the caster is pointing": [
    "Quaestorem vocat <dur> secundas"
  ],
  "a letter to selina ii": [
    "Epistula ad Selinam II"
  ],
  "letter to selina ii": [
    "Epistula ad Selinam II"
  ],
  "stagger": [
    "Stagger"
  ],
  "dragon riding": [
    "Dracones Conscendens"
  ],
  "ash spawn war axe": [
    "Bellisecuris Filii Cineris"
  ],
  "conjure high seeker": [
    "Voca Quaestorem Altum"
  ],
  "frost stream": [
    "Frost Stream"
  ],
  "cultist adept": [
    "Draconicultor Adeptus"
  ],
  "maximian axius": [
    "Maximian Axius"
  ],
  "target takes <mag> points of shock damage per second to health, and half that to magicka": [
    "Inimicum <mag> puncta vulneris Saluti et dimidia Magicae accipit",
    "Inimicus accipit <mag> vulneris electricitatis singulis secundis to Health, et dimidium magicae"
  ],
  "the ebony warrior": [
    "Bellator Ebeni"
  ],
  "master cultist": [
    "Draconicultor Dominus"
  ],
  "conjure ancients": [
    "Voca Antiquos"
  ],
  "miraak's sparks": [
    "Miraak's Sparks"
  ],
  "targets are frozen between oblivion and tamriel for <dur> seconds, and immune to all damage": [
    "Inimicum inter Oblivionem Tamrielemque <dur> secundas erit, et nullum vulneris accipiet"
  ],
  "ash spawn battle axe": [
    "Bellisecuris Filii Cineris"
  ],
  "conjureancientsbodyartholder": [
    "ConjureAncientsBodyArtHolder"
  ],
  "summons dragonborn from the past to fight by the caster's side for a limited time": [
    "Draconigenam Antiquum vocat"
  ],
  "each additional 5 souls summons another warrior": [
    "Quinque Animi Plurimi alium bellatorem vocant"
  ],
  "summon ancients": [
    "Antiquos Voca"
  ],
  "why do the ulens have a tomb in raven rock": [
    "Quamobrem Uleni tumulum Corvisaxi habent"
  ],
  "target takes <mag> points of damage per second to health and magicka": [
    "Target takes <mag> points of damage per second to Health and Magicka",
    "Inimicus accipit <mag> vulneris singulis secundis to Health et magicae"
  ],
  "falas selvayn": [
    "Falas Selvayn"
  ],
  "scrawled note": [
    "Epistula Celeriter Scripta"
  ],
  "why do the dunmer hate the empire": [
    "Quamobrem Atralfi Imperium in odio habere"
  ],
  "meet up with the redoran guard": [
    "Conveni cum custodibus Redoranis"
  ],
  "but you told me house hlaalu was disliked": [
    "Sed mihi dixisti Gentem Hlaalu in odio haberi"
  ],
  "take bera's necklace to runil in falkreath": [
    "Fer Monile Berae ad Runilem Falkretiae"
  ],
  "the kagrumez gauntlet": [
    "Manubria Kagrumez"
  ],
  "kagrumez gauntlet": [
    "Manubria Kagrumez"
  ],
  "how did the dunmer survive this at all": [
    "Quomodo Atralifi per haec omnia superfuerunt"
  ],
  "return to morwen in skaal village": [
    "Redi ad Morwen in Vico Skaalis"
  ],
  "you should follow me": [
    "Me sequere debes"
  ],
  "fire wyrm attack": [
    "Fire Wyrm Attack"
  ],
  "investigate the shrine": [
    "Investigata Lapitem Terrae prope Corvisaxum"
  ],
  "learn the black book's hidden knowledge": [
    "Disce secretum Libri Atri"
  ],
  "steadfast dwarven sphere": [
    "Sphera Dwemeris Constans"
  ],
  "crescius lost your ancient nordic pickaxe": [
    "Crescius Dolabrum Antiquum Germanicum tuum amisit"
  ],
  "you need something": [
    "Aliquid vis"
  ],
  "you seem friendly": [
    "Amicum videris"
  ],
  "you don't like it on solstheim": [
    "Solstheim tibi non placet"
  ],
  "emberbrand wine": [
    "Vinum Cineribrandis"
  ],
  "why not return to morrowind": [
    "Quidni ad Morriventum revenias"
  ],
  "i don't need some bucket of scrap metal following me around": [
    "Nolo automaton inutile me sequens et"
  ],
  "steadfast dwarven spider": [
    "Aranea Dwemeris Constans"
  ],
  "does <mag> points of damage to health": [
    "Facit <mag> puncta vulneris ad salutem"
  ],
  "reading one of hermaeus mora's black books may transport you into his realm": [
    "Librum Atrum Hermaei Morae legere te ad Mundum eius potare potest"
  ],
  "read it again to escape": [
    "Eum iterum lege ut effugias"
  ],
  "apocrypha is the realm of hermaeus mora, the daedric prince of fate and knowledge": [
    "Apocrypha est domus Hermaei Morae, Princeps Daedricus Fati et Scientiae"
  ],
  "many who come here seeking forbidden knowledge remain forever": [
    "Multi qui huc veniunt ut scientiam vetatam inveniant semper maneant"
  ],
  "word chest": [
    "Word Chest"
  ],
  "vahlok the jailor": [
    "Vahlok Custodiarius"
  ],
  "does <mag> points of frost damage": [
    "Facit <mag> puncta Vulneris Glaciei"
  ],
  "does <mag> points of fire damage": [
    "Facit <mag> puncta Vulneris Ignis"
  ],
  "does <mag> points of shock damage": [
    "Facit <mag> puncta Vulneris Electricitatis"
  ],
  "ash pile": [
    "Cumulum Cineris"
  ],
  "why is a shrine being built near town": [
    "Quamobrem aram foris oppidi facis"
  ],
  "why build a shrine outside of town": [
    "Quamobrem aram foris oppidi facis"
  ],
  "attack target": [
    "Oppugna"
  ],
  "speak to crescius caerellius": [
    "Adloquere Crescium Caerellium"
  ],
  "why are you building a shrine near town": [
    "Quamobrem aram prope oppidum facis"
  ],
  "shock spider resist": [
    "Shock Spider Resist"
  ],
  "flame spider resist": [
    "Flame Spider Resist"
  ],
  "can you tell me more about the shrine near town": [
    "Potesne mihi plus de ara prope oppidum narrare"
  ],
  "frost spider resist": [
    "Frost Spider Resist"
  ],
  "can you tell me more about the shrine outside of town": [
    "Potesne mihi plus de ara prope oppidum narrare"
  ],
  "cultists' orders": [
    "Mandata Draconicultorium"
  ],
  "chapter viii": [
    "Capitulum VIII"
  ],
  "hand-written note": [
    "Epistula Manu-scripta"
  ],
  "dwarven ballista master": [
    "Dwemer Ballista Dominus"
  ],
  "the world is dangerous, and you're not ready for it": [
    "Mundus est periculosus et pro eo paratus non es"
  ],
  "world is dangerous, and you're not ready for it": [
    "Mundus est periculosus et pro eo paratus non es"
  ],
  "chapter vi": [
    "Capitulum VI"
  ],
  "chapter vii": [
    "Capitulum VII"
  ],
  "chapter ix": [
    "Capitulum IX"
  ],
  "find out who sent the cultists": [
    "Disce quem Draconicultores mississe"
  ],
  "your mother tells me that you want to leave the village": [
    "Mater mihi dixit te e vico discedere velle"
  ],
  "i have business to discuss with your leader": [
    "Negotium cum duce tuo habeo"
  ],
  "convince nikulas to stay in skaal village": [
    "Convince Nikulas ut in vico Skaal maneat"
  ],
  "search the <alias=actor1>": [
    "Quaere <Alias=Actor1>"
  ],
  "cultists vs player": [
    "Cultists vs Player"
  ],
  "slay the seeker in bloodskal barrow": [
    "Neca Quaestorem in Pabillo Sanguiskali"
  ],
  "the final descent priest": [
    "The Final Descent Priest"
  ],
  "final descent priest": [
    "The Final Descent Priest"
  ],
  "the final decent mine tracker": [
    "The Final Decent Mine Tracker"
  ],
  "final decent mine tracker": [
    "The Final Decent Mine Tracker"
  ],
  "raven rock mine change location": [
    "Raven Rock Mine Change Location"
  ],
  "why would the thalmor abduct you": [
    "Quamobrem Visigothi te abduxerunt"
  ],
  "ulyn's journal": [
    "Ephemerides Ulynis"
  ],
  "you will pay for your crimes against the skaal": [
    "Poenas dabis pro criminibus contra"
  ],
  "prepare to die": [
    "Para ut moriaris"
  ],
  "take this": [
    "Habe hoc"
  ],
  "just give your mother some more time": [
    "Modo matrem plus temporis da"
  ],
  "speak to edla": [
    "Adloquere Edlam"
  ],
  "stalhrim deposit": [
    "Faex Stalhrimis"
  ],
  "i do understand": [
    "Ingelleo"
  ],
  "in fact, i'm willing to help you get started": [
    "Tibi etiam auxilium dare volo"
  ],
  "you'd abandon your mother when she needs you most": [
    "Mater relinquas cum illa te maxime desideret"
  ],
  "ulyn": [
    "Ulyn"
  ],
  "dwarven ballista guardian": [
    "Dwemer Ballista Custos"
  ],
  "who's your son": [
    "Quis est filius tuus"
  ],
  "i could talk to him": [
    "Eum adloqui possum"
  ],
  "dwarven ballista": [
    "Dwemeris Ballista"
  ],
  "i helped a man in need, that's all": [
    "Modo homini in egestate auxilium dedi"
  ],
  "tel mithryn steward's key": [
    "Tel Mithryn Dipensator Clavis"
  ],
  "do you think this place has any connection with the skaal": [
    "Putasne hunc locum aliquid cum Skaal agi"
  ],
  "what have you learned about this place": [
    "Quid de hoc loco didicisti"
  ],
  "audiotemplateashghoul": [
    "AudioTemplateAshGhoul"
  ],
  "let me guess, you need me to do something dangerous": [
    "Sine me conicere, vis me aliquid periculosum facere"
  ],
  "why all the traps and puzzles": [
    "Cur tot decipulae et aenigmata"
  ],
  "where did you hear the name": [
    "Ubi hoc nomen audivisti"
  ],
  "ash and embers cause <mag> points of damage": [
    "Ash and embers cause <mag> points of damage"
  ],
  "wait for a message from ralis": [
    "Exspecta nuntium ab Rali"
  ],
  "dented iron shield": [
    "Scutum Ferratum Rasum"
  ],
  "ballista bolt": [
    "Ballista Bolt"
  ],
  "when close enough, identifies the source of the ash spawn attacks on tel mithryn": [
    "Dum satis proximus, fontem oppugnata in Tel Mithryn discernere potest"
  ],
  "ahzidal's descent": [
    "Descensio Ahzidalis"
  ],
  "stagger attack": [
    "Stagger Attack"
  ],
  "letterstorage": [
    "LetterStorage"
  ],
  "torkild (wb01)": [
    "Torkild (WB01)"
  ],
  "a note": [
    "Epistula"
  ],
  "the argonians invaded morrowind": [
    "Chameleones Morriventum invaserunt"
  ],
  "argonians invaded morrowind": [
    "Chameleones Morriventum invaserunt"
  ],
  "examine torkild": [
    "Investiga Torkild"
  ],
  "spell tome: conjure seeker": [
    "Liber Magicae: Voca Quaestorem"
  ],
  "how long have you been first hunter": [
    "Quamdiu venator primus fuisti"
  ],
  "what do your titles mean": [
    "Quid sibi volunt titula tua"
  ],
  "creaturedialogueashghoul": [
    "CreatureDialogueAshGhoul"
  ],
  "torkild": [
    "Torkild"
  ],
  "your brother is looking for you": [
    "Frater tuus te quaerit"
  ],
  "gatekeeper": [
    "Ianitor",
    "appāritor, appāritōris"
  ],
  "all spells cost <10>% less magicka": [
    "Omnia Incantamenta Magicae <10>% minus magicae constant"
  ],
  "enchantments are <10>% more powerful": [
    "Incantamenta <10>% potentiora sunt"
  ],
  "increases stamina regeneration by <50>%": [
    "Auget Recuperationem Staminae <50>%"
  ],
  "combat skills are all <10>% more effective": [
    "Peritia Pugnandi <10>% Efficaciora sunt"
  ],
  "summons a field of writhing tentacles that lasts <30> seconds and poisons foes who enter it": [
    "In solum sparsum, facit tentacula <30> secundas quae venenum in inimicos eos ponunt"
  ],
  "embers and heated ash cause <mag> points per second": [
    "Cinere faciunt <mag> puncta vulneris singulis secundis"
  ],
  "target is frozen between oblivion and tamriel for <dur> seconds, and immune to all damage": [
    "Inimicum inter Oblivionem Tamrielemque <dur> secundas erit, et nullum vulneris accipiet"
  ],
  "miraak fake shout": [
    "Miraak Fake Shout"
  ],
  "stealth skills are all <10>% more effective": [
    "Peritia Furti <10>% efficaciora sunt"
  ],
  "chapter i footnote": [
    "Capitulum I Subscriptum"
  ],
  "raven rock jail key": [
    "Corvisaxi Carceris Clavis"
  ],
  "marked for death - krii": [
    "Marked for Death - Krii"
  ],
  "i don't know what you're talking about": [
    "Nescio de qua re loquaris"
  ],
  "where do you get your goods": [
    "Quo bona tua invenis",
    "Ubi bona tua accipis"
  ],
  "ramshackle trading post": [
    "Ramshackle Trading Post"
  ],
  "read cultists' orders": [
    "Lega mandata Draconicultorum"
  ],
  "the greybeards seem to think so": [
    "Greybarbae credunt"
  ],
  "greybeards seem to think so": [
    "Greybarbae credunt"
  ],
  "cleanse the sun stone": [
    "Munda Lapidem Solis"
  ],
  "you appear to be in dire straits": [
    "In angustis esse videris"
  ],
  "how are things now that the mines are open again": [
    "Quid agit nunc quod metalla aperta sunt"
  ],
  "cleansing the stones": [
    "Lapides Mundans"
  ],
  "audiotemplateseeker": [
    "AudioTemplateSeeker"
  ],
  "yes, i am dragonborn": [
    "Sic, Draconigena sum"
  ],
  "cleanse the earth stone": [
    "Munda Lapidem Terrae"
  ],
  "(walk away)": [
    "(Exi)",
    "(Walk away)"
  ],
  "usha": [
    "Usha"
  ],
  "nchardak reading room": [
    "Nchardak Bibliotheca"
  ],
  "why don't you destroy it": [
    "Quid ni id frangas"
  ],
  "shady merchant's dialogue": [
    "Shady Merchant's Dialogue"
  ],
  "depends": [
    "Nescio"
  ],
  "who's asking": [
    "Quis rogat"
  ],
  "letter to usha": [
    "Epistula ad Usham"
  ],
  "it's your mess. you clean it up": [
    "Id fregisti, id refice"
  ],
  "cleanse the beast stone": [
    "Munda Lapidem Bestiae"
  ],
  "cleanse the water stone": [
    "Munda Lapidem Aquae"
  ],
  "cultist faction": [
    "Cultist Faction"
  ],
  "how did the ash guardian get loose": [
    "Quomodo solutus est Custos Cineris"
  ],
  "have a gold piece": [
    "Habe nummum",
    "Solidum accipe"
  ],
  "(1 gold)": [
    "(I Auri)"
  ],
  "disintegrate": [
    "Disintegra"
  ],
  "elmus services": [
    "Elmus Services"
  ],
  "false summon ash guardian": [
    "False Summon Ash Guardian"
  ],
  "dremora": [
    "Dremora"
  ],
  "absorb": [
    "Absorbe"
  ],
  "why do you think your brother became a werebear": [
    "Quamobrem censes fratrem tuum in werebear mutatum esse"
  ],
  "whirlwind sprint": [
    "Cursus Turbinis",
    "Whirlwind Sprint"
  ],
  "telvanni shoes": [
    "Telvanni Calcei"
  ],
  "are you practicing a spell": [
    "Magicamne exerces"
  ],
  "find resonance gems across solstheim to complete the kagrumez trials": [
    "Inveni Gemmas Resonantiae in Solstheim ut Pericula Kagrumez compleas"
  ],
  "raleth eldri": [
    "Raleth Eldri"
  ],
  "ballista penetrated armor": [
    "Ballista penetrated armour"
  ],
  "50% chance for each attribute to absorb <mag> points of health, stamina and/or magicka": [
    "50% casus singulis ut absorbas <mag> puncta Salutis, Staminae et/vel Magickae"
  ],
  "strange vessel": [
    "Atrophorion Mirum"
  ],
  "ashfire mead": [
    "Hydromellum Cinignis"
  ],
  "raleth": [
    "Raleth"
  ],
  "frostmoon hunt": [
    "Frostmoon Hunt"
  ],
  "what do you have to trade": [
    "Quae tradenda habes"
  ],
  "have you ever thought about moving to a city": [
    "Umqauam de ad urbem movendo cogitavisti"
  ],
  "frostmoon bloodlust": [
    "Frostmoon Bloodlust"
  ],
  "are there other werewolves on solstheim": [
    "Suntne alii versipelles in Solstheim"
  ],
  "are you part of this pack, too": [
    "Esne pars huius gregis quoque"
  ],
  "frostmoon howl": [
    "Uluatio Glacilunae"
  ],
  "do you live out here": [
    "Hicne vivis"
  ],
  "frostmoon instinct": [
    "Frostmoon Instinct"
  ],
  "do you have anything to trade": [
    "Habesne quidquid tradendum"
  ],
  "frostmoon predator": [
    "Frostmoon Predator"
  ],
  "while in beast form, your attacks do <50>% more damage, but you also take <50>% more damage": [
    "In Forma Bestiali <50>% plus vulneris facis sed <50>% plus vulneris accipis"
  ],
  "increases the duration of your howls by <25>%": [
    "Tempus Ululationum <25>% augentur"
  ],
  "while in beast form, your health regenerates": [
    "Sum in Forma Beastali, Salus recuperatur"
  ],
  "when you enter beast form, the world around you seems to slow for <20> seconds": [
    "When you enter Beast Form, the world around you seems to slow for <20> seconds"
  ],
  "you hunt": [
    "Venaris"
  ],
  "what do you hunt": [
    "Quid venaris"
  ],
  "are you all werewolves": [
    "Estisne vos omnes versipelles"
  ],
  "why do you call him that": [
    "Quamobrem illi hoc nomen das"
  ],
  "i spoke to hermaeus mora": [
    "Hermaean Moram Adloqutus sum"
  ],
  "he asked for the \"secrets of the skaal.\"": [
    "Ille \"secreta Skaal\" postulavit"
  ],
  "are you ready to trade your secrets to hermaeus mora": [
    "Esne paratus secreta Hermaeo Morae tradere"
  ],
  "i'll restore the remaining stones if that will help": [
    "Alia Saxa refiam si audiuvet"
  ],
  "you mean you'll give him what he wants": [
    "Ei dabis ea quae vult"
  ],
  "ebony warrior's camp": [
    "Ebony Warrior's Camp"
  ],
  "i've dealt with hermaeus mora before": [
    "Antea cum Hermaeo Mora tractatus sum"
  ],
  "i've read the oghma infinium": [
    "Oghmam Infinium legi"
  ],
  "why would hermaeus mora want your secrets": [
    "Quamobrem Hermaeus secreta tua quaerit"
  ],
  "he said it's the only way he'll teach me the final word of miraak's shout": [
    "Dixit hanc solam viam esse ut verbum ultimum Clamationis Miraakis mihi doceret"
  ],
  "did you grow this tower": [
    "Huncne turrem e boleto colavisti"
  ],
  "you must be almost as powerful as savros aren of winterhold": [
    "Fere tam potentem ut Savros Aren Hiemiterrae esse debes"
  ],
  "how do i know i can trust you, demon": [
    "Quomodo scire me tibi credere posse, Daemonion"
  ],
  "morvayn ancestral tomb": [
    "Tumulus Maiorum Morvaynium"
  ],
  "deathbrand": [
    "Mortibrandis"
  ],
  "i've heard of the house telvanni of morrowind": [
    "De Gente Telvanni Morriventi audivi"
  ],
  "letter from ralis sedarys 4": [
    "Epistula a Ralis Sedarys 4"
  ],
  "letter from ralis sedarys": [
    "Epistula a Ralis Sedarys"
  ],
  "letter from ralis sedarys 2": [
    "Epistula a Ralis Sedarys 2"
  ],
  "take on the form of the wolf": [
    "In lupum muta",
    "Muta in formam lupi"
  ],
  "hvitkald peak": [
    "Summum Hvitkaldis"
  ],
  "as the archmage of winterhold, you and i should share our secrets": [
    "Quoniam Archmagus Hiemiterrae sunt, tu et ego secreta nostra communicare debemus"
  ],
  "dragon vs player": [
    "Dragon vs Player"
  ],
  "search for the treasure of haknir death-brand": [
    "Quare Gazam Hakniris Death-Brand"
  ],
  "visit the hunters at frostmoon crag": [
    "Visita venatores apud Rupes Glacilunae"
  ],
  "what do you do for master neloth": [
    "Quid pro Domine Nelothi facis"
  ],
  "letter from ralis sedarys 3": [
    "Epistula a Ralis Sedarys 3"
  ],
  "you must be a very powerful wizard": [
    "Thaumatorgon potentissmum esse debes"
  ],
  "morgrul attack": [
    "Morgrul Attack"
  ],
  "dunmer shoes": [
    "Atralfi Calcei"
  ],
  "augmented shock": [
    "Electricitas Aucta",
    "Electricitatis Aucta"
  ],
  "forget it": [
    "Eius Obliviscere",
    "Obliviscere de eo"
  ],
  "maybe you should let him go": [
    "Fortasse eum liberare debes"
  ],
  "augmented flames": [
    "Flammae Auctae"
  ],
  "nikulas has agreed to stay": [
    "Nikulas manere consensit"
  ],
  "soulrender": [
    "Animiscissor"
  ],
  "flame target visuals": [
    "Flame Target Visuals"
  ],
  "altar of thrond cave": [
    "Cava Arae Throndis"
  ],
  "isobel": [
    "Isobel"
  ],
  "ahzidal's vision": [
    "Visio Ahzidalis"
  ],
  "frost spells do <25>% more damage": [
    "Magica Glaciei <25>% Plus Vulneris Facit"
  ],
  "mogrul's orders": [
    "Mandata Mogrulis"
  ],
  "augmented frost": [
    "Glacies Aucta"
  ],
  "reach miraak's temple": [
    "Adveni ad Templum Miraakis"
  ],
  "fire spells do <25>% more damage": [
    "Magica Ignis <25>% plus vulneris facit"
  ],
  "shock spells do <25>% more damage": [
    "Maciga electricitatis <25>% plus vulneris faciunt"
  ],
  "records memories of your experiences for neloth": [
    "Memoria Recentia annotat Nelothi"
  ],
  "i need you to come with me": [
    "Mecum veni"
  ],
  "hrothmund's spirit": [
    "Animus Hrothmundis"
  ],
  "mogrul's thug": [
    "Opera Mogrulis"
  ],
  "one of the most dangerous steam animunculi is the dwarven ballista, which fires a bolt that can penetrate even the thickest armor": [
    "Unus ex periculosissimis Animunculorum Vaporis est Ballista Dvemeris quae sagittulam sagittat qaue etiam loricam densissimam transfigit"
  ],
  "your wards are <25>% less effective, but absorb <50>% of the magicka from incoming spells": [
    "Scuta magica tua <25>% minus efficacia sunt, sed <50>% magicae inimicorum bibunt"
  ],
  "fallaise": [
    "Fallaise"
  ],
  "your conjuration and rune spells cost <25>% more, but can be cast at greater range": [
    "Magicae Conjurationis Runique <25>% plus constant, sed ad distantiam magnam usurpari possunt"
  ],
  "memory recorder": [
    "Recorder Memoriae"
  ],
  "ettiene": [
    "Ettiene"
  ],
  "frostbite": [
    "Adustum Nivibus"
  ],
  "treasure hunter": [
    "Gazam Venator",
    "Venator Gazae"
  ],
  "northern maiden faction": [
    "Northern Maiden Faction"
  ],
  "cultist gloves": [
    "Draconicultor Manubria"
  ],
  "pirate mage": [
    "Maga Piratarum"
  ],
  "raleth eldri's notes on kagrumez": [
    "Adnotationes Raleth Eldris de Kagrumez"
  ],
  "lygrleid": [
    "Lygrleid"
  ],
  "gold necklace": [
    "Auri Monile",
    "Monile Corlapidis"
  ],
  "torn note": [
    "Epistula Scissa"
  ],
  "you may have noticed he's trying to return to solstheim": [
    "Fortasse scis eum ad Solstheim revenire temptare"
  ],
  "bonemold pauldron armor": [
    "Ossea Lorica Pauldronis"
  ],
  "sogrlaf": [
    "Sogrlaf"
  ],
  "fine boots": [
    "Nobiles Calcei",
    "Ornatae Caligae"
  ],
  "general carius' armor": [
    "Lorica Imperatoris Carii"
  ],
  "tell me of your people, the skaal": [
    "Dic mihi de hominibus tuis, Skaal"
  ],
  "well, this is your lucky day. (give formula)": [
    "Bene, dies felix tibi est. (Da formulam)"
  ],
  "do you need help with your research": [
    "Auxiliumne in experimentis tuis quaeris"
  ],
  "cultist boots": [
    "Draconicultor Caligae"
  ],
  "dlc2expspiderquesdetectfrienddie": [
    "DLC2ExpSpiderQuesDetectFriendDie"
  ],
  "cultist mask": [
    "Draconicultor Persona"
  ],
  "fine clothes": [
    "Nobilia Vestimenta",
    "Vestimenta Nobilia"
  ],
  "hrodulf's madness": [
    "Insania Hrodulfis"
  ],
  "black robes": [
    "Peplum Atrum",
    "Dremorae Peplum Atrum"
  ],
  "add apo water damage": [
    "Add Apo Water Damage"
  ],
  "bonemold guard armor": [
    "Ossea Lorica Custodis"
  ],
  "elemental fury": [
    "Insanitas Elementalis",
    "Saevitudo Elementalis"
  ],
  "haknir's crew": [
    "Grex Hakniris"
  ],
  "garuk windrime": [
    "Garuk Windrime"
  ],
  "learn more about the buried treasure": [
    "Disce plus de gaza sepulta"
  ],
  "explore gyldenhul barrow": [
    "Explora Pabillum Gyldenhulis"
  ],
  "thalin ebonhand": [
    "Thalin Ebonhand"
  ],
  "defeat haknir death-brand": [
    "Supera Haknir Mortibrandem"
  ],
  "improve sneak": [
    "Improve Sneak"
  ],
  "use the deathbrand treasure map to find the treasure": [
    "Uti Charta Mortibrandis ut gazam invenias"
  ],
  "bloodscythe armor reduction": [
    "Bloodscythe Armour Reduction"
  ],
  "soulrender dispel": [
    "Soulrender Dispel"
  ],
  "pearl oyster": [
    "Ostream Margaritae"
  ],
  "wood chopping block": [
    "Talea Bosci",
    "Latero Aptus Ligni Diffindi"
  ],
  "hrodulf": [
    "Hrodulf"
  ],
  "storn didn't die for nothing. miraak will never threaten solstheim again": [
    "Storn non frustra mortuus est. Miraak numquam iterum Solstheim comminetur"
  ],
  "(0 gold)": [
    "(0 Auri)"
  ],
  "miraak is behind what's happening to your people": [
    "Miraak est causa difficultatium tuorum"
  ],
  "death fx for ash spawn": [
    "Death FX for Ash Spawn"
  ],
  "why won't the book you have here help me": [
    "Quamobrem liber tuus mihi auxilium non dabit"
  ],
  "do you know where i can find another black book": [
    "Scisne ubi Librum Atrum alium invenire possim"
  ],
  "dlc2 hireling quest": [
    "DLC2 Hireling Quest"
  ],
  "so you know where to find this black book": [
    "Scisne ubi Librum Atrum invenire possim"
  ],
  "the one connected with miraak": [
    "Ille qui de Miraak agitur"
  ],
  "one connected with miraak": [
    "Ille qui de Miraak agitur"
  ],
  "hermaeus mora wouldn't have helped me without storn's sacrifice": [
    "Hermes Trismegisthus mihi auxilium non daret sine sacrificio Stornis"
  ],
  "i know": [
    "Scio"
  ],
  "consider yourself hired": [
    "Conduceris",
    "Puta te conductum esse"
  ],
  "(<global=hirelinggold> gold)": [
    "(<global=hirelinggold> auri)"
  ],
  "spellsword": [
    "Spellsword",
    "Magigladiator"
  ],
  "isn't miraak your ally? why help me defeat him": [
    "Nonne Miraak socius tuus est? Cur mihi auxilium dares ut eam vincerem"
  ],
  "ash spawn death effect": [
    "Ash Spawn Death Effect"
  ],
  "i've seen miraak": [
    "Miraakem vidi"
  ],
  "i killed miraak": [
    "Miraak necavi"
  ],
  "but hermaeus mora is still out there": [
    "Sed Hermes Trismegisthus iam manet"
  ],
  "ashspawn death fx": [
    "Ash Spawn Death FX"
  ],
  "it's over": [
    "Factumst",
    "Actum est"
  ],
  "miraak is dead": [
    "Miraak mortuus est"
  ],
  "hermaeus mora killed storn, remember. not miraak": [
    "Memento Hermaeum Moram Storn necavisse, non Miraak"
  ],
  "i couldn't have defeated miraak without storn's help": [
    "Sine auxilio Stornis, Miraak vincere non possem"
  ],
  "teldryn sero": [
    "Teldryn Sero"
  ],
  "you have a black book": [
    "Librum Atrum habes"
  ],
  "bring <global=dlc2thirskhilundspearcount> riekling spears to hilund": [
    "Cape <Global=DLC2ThirskHilundSpearCount> Hastas Riekling ad Hilund"
  ],
  "fahlbtharz corridor": [
    "Fahlbtharz Andron"
  ],
  "bring <global=dlc2thirskhalbarnstalhrimcount> stalhrim ore and <global=dlc2thirskhalbarnebonycount> ebony ingots to halbarn": [
    "Fer <Global=DLC2ThirskHalbarnStalhrimCount> Minerale Stalhrimis et <Global=DLC2ThirskHalbarnEbonyCount> Minerale Ebeni ad Halbarn"
  ],
  "horker meat": [
    "Horker Caro",
    "Carnem Horker"
  ],
  "red glow": [
    "Red Glow"
  ],
  "no. never mind": [
    "Minime, ignosce"
  ],
  "rieklings to the rescue": [
    "Rieklings to the Rescue"
  ],
  "elmus favor quest": [
    "Elmus Favour Quest"
  ],
  "i don't need your advice": [
    "Consilio tuo non careo"
  ],
  "halbarn favor quest": [
    "Halbarn Favour Quest"
  ],
  "i've brought the materials you wanted": [
    "Tibi res postulatas tuli"
  ],
  "(give stalhrim and ebony.)": [
    "(Da Stalhrimem et Ebenum.)"
  ],
  "don't you want to know what happened with miraak": [
    "Visne scire quid cum Miraak accidisse"
  ],
  "how is the hunting going": [
    "Quo it venatio"
  ],
  "i've got a lot of riekling spears": [
    "Multas hastas Riekling habeo"
  ],
  "i hope it's enough": [
    "Spero numerum satis magnum esse"
  ],
  "(give spears.)": [
    "(Da hastas.)"
  ],
  "hilund favor quest": [
    "Hilund Favour Quest"
  ],
  "is there anything that would make being down here more bearable": [
    "Estne quid quod hic vivere mollius faciat"
  ],
  "how is the mead": [
    "Quale est Hydromellum"
  ],
  "what are you looking for": [
    "Quid quaeris"
  ],
  "breath of nchuak": [
    "Exhalatio Nchuak"
  ],
  "i found some ashfire mead": [
    "Hydromellum Cinerigni inveni"
  ],
  "(give mead.)": [
    "(Da Hydromellum.)"
  ],
  "i've got some juniper berries": [
    "Bacae juniperae tibi habeo"
  ],
  "(give berries.)": [
    "(Da baccas.)"
  ],
  "you don't need to worry about me": [
    "De me sollicitari non debes"
  ],
  "sirkjorg": [
    "Sirkjorg"
  ],
  "bring elmus some ashfire mead from thirsk mead hall": [
    "Fer Hydromellum Cinignis Elmo"
  ],
  "steam breath": [
    "Steam breath"
  ],
  "bring elmus some juniper berries": [
    "Fer Juniperem Elmo"
  ],
  "you don't seem very grateful": [
    "Gratum mihi non videris"
  ],
  "no, nothing interesting": [
    "Minime, nihil magni momenti"
  ],
  "i just killed miraak and saved solstheim": [
    "Modo Miraakem necavit et Solstheim servavi"
  ],
  "potion of waterwalking": [
    "Potio Super Aquam Ambulandi"
  ],
  "herkja": [
    "Herkja"
  ],
  "visage of mzund": [
    "Person Mzundis"
  ],
  "sure, what is it": [
    "Certe, quid est"
  ],
  "are you getting back to work": [
    "Renvies ne ad laborem"
  ],
  "walk on the surface of water for <dur> seconds": [
    "Walk on the surface of water <dur> secundas"
  ],
  "bloodskal red glow": [
    "Bloodskal Red Glow"
  ],
  "dragon priest": [
    "Dragon Priest",
    "Draconis Sacerdos"
  ],
  "dragon priest abilities": [
    "Dragon Priest abilities"
  ],
  "skaal coat": [
    "Skaal Amiculum"
  ],
  "skaal gloves": [
    "Skaal Manubria"
  ],
  "skaal boots": [
    "Skaal Caligae"
  ],
  "raven rock is purely a dunmer settlement": [
    "Corvicisaxum est urbs omnino Atralforum"
  ],
  "acolyte dragon priest fx": [
    "Acolyte Dragon Priest FX"
  ],
  "skaal hat": [
    "Skaal Petasus"
  ],
  "sounds fun": [
    "Ludibundum mihi videtur"
  ],
  "i'll join your hunt": [
    "Tecum venar"
  ],
  "miraak's sword": [
    "Gladius Miraakis"
  ],
  "i'd like to learn the ash guardian spell": [
    "Volo magicam Custodis Ignis discere"
  ],
  "sooner or later, death comes for us all": [
    "Mox vel postea, mors omnibus venit"
  ],
  "i'm looking for miraak": [
    "Miraak quaero"
  ],
  "do you know him": [
    "De eo scis"
  ],
  "but what": [
    "Sed quid"
  ],
  "just looking for work": [
    "Modo laborem quaero"
  ],
  "wall of storms": [
    "Wall Of Storms",
    "Paries Temestatium",
    "Paries Tempestatium"
  ],
  "how did you end up on solstheim": [
    "Quomodo ad Solstheim venisti"
  ],
  "i'll just take the staff": [
    "Modo rudi potiar"
  ],
  "why did you work for him then": [
    "Erge quamobrem laborem eius pro eo fecisti"
  ],
  "what happened to the mine": [
    "Quid metallo accidit"
  ],
  "that could still happen, couldn't it": [
    "Iam accidere potest, nonne"
  ],
  "what's a heart stone": [
    "Quid est Corsaxum"
  ],
  "coldcinder cave": [
    "Cava Frigidicineris"
  ],
  "frostmoon invitation": [
    "Frostmoon Invitation"
  ],
  "arguing about haknir": [
    "Arguing about Haknir"
  ],
  "netch hunting party": [
    "Netch Hunting Party"
  ],
  "none of your business": [
    "Nihil cum te agitur",
    "Negotium tuum non est"
  ],
  "are you sure you're ready to be shaman of the skaal": [
    "Esne paratus Sagam Skaal esse"
  ],
  "tentacle explosion": [
    "Tentacle Explosion"
  ],
  "a netch": [
    "Netch"
  ],
  "think you'll win the fight": [
    "Censesne te pugnam victurum esse"
  ],
  "you know who miraak is": [
    "Scisne quem Miraakem esse"
  ],
  "frossel bandit": [
    "Frossel Bandit"
  ],
  "then why stay here": [
    "Quamobrem hic manes"
  ],
  "why not live somewhere safer": [
    "Quidni in loco tutiore"
  ],
  "absorb <mag> points of stamina": [
    "Absorbe <mag> staminae",
    "Adsorbe <mag> puncta staminae"
  ],
  "where is your mother": [
    "Ubi est mater tua"
  ],
  "i've got it": [
    "Intellego"
  ],
  "don't cast ash guardian without a heart stone": [
    "Noli magicam Custodis Cineris facere sine Corsaxo"
  ],
  "sprayed on the ground, it creates a wall of lightning that does <50> points of shock damage per second": [
    "In terra effusus, parietem fulminis vocat quae <50> gelidus vulneris singulis secundis facit",
    "In solum sparsum, facit parietem electricitatis quae <50> puncta vulneris electricitatissingulis secundis facit"
  ],
  "for a shaman, you fight pretty well": [
    "Etiamsi Saga esses, manubus bene pugnas"
  ],
  "who trained you": [
    "Quis te exercuit"
  ],
  "evul seloth": [
    "Evul Seloth"
  ],
  "tolenos": [
    "Tolenos"
  ],
  "can i dig for ebony in raven rock mine": [
    "Possumne ebenem in Metallo Corvisaxi effodire"
  ],
  "naris": [
    "Naris"
  ],
  "meden": [
    "Meden"
  ],
  "tentacle word burn": [
    "Tentacle Word Burn"
  ],
  "naris mavani": [
    "Naris Mavani"
  ],
  "benthic scream": [
    "Benthic Scream"
  ],
  "meden maren": [
    "Meden Maren"
  ],
  "tolenos omoren": [
    "Tolenos Omoren"
  ],
  "fus": [
    "Fus"
  ],
  "skein of magnus": [
    "Skein Magni",
    "Skein of Magnus"
  ],
  "evul": [
    "Evul"
  ],
  "ice wraith abilities": [
    "Ice Wraith abilities"
  ],
  "tentacle damage": [
    "Tentacle Damage"
  ],
  "explosion damage": [
    "Explosion Damage"
  ],
  "scream for mora": [
    "Scream For Mora"
  ],
  "miraak mask shout": [
    "Miraak Mask Shout"
  ],
  "conjuration perks": [
    "Peritia Conjurationis"
  ],
  "destruction perks": [
    "Peritia Destructionis"
  ],
  "telmithryn teleport": [
    "TelMithryn Teleport"
  ],
  "i think this belongs to you": [
    "Credo hanc tuam esse"
  ],
  "(give glover's letter)": [
    "(Da Epistula Gloveris)"
  ],
  "one-handed perks": [
    "Peritia Singuli Manus"
  ],
  "heavy armor perks": [
    "Peritia Loricae Gravis"
  ],
  "smithing perks": [
    "Peritia Ferrarii"
  ],
  "you think someone is out to get you": [
    "Credisne aliquem tibi nocere velle"
  ],
  "skaal amulet": [
    "Skaal Monile"
  ],
  "are you the captain of the northern maiden": [
    "Esne nauarchos Virginis Septentrionalis"
  ],
  "enchanting perks": [
    "Peritia Incantamenti"
  ],
  "block perks": [
    "Peritia Scuti"
  ],
  "where's that": [
    "Ubi est"
  ],
  "exquisite sapphire": [
    "Sapphirum Exquisitum"
  ],
  "you owe me": [
    "Mihi debes"
  ],
  "those cultists you brought here tried to kill me": [
    "Illi draconicultores quos huc tulisti me necare temptaverunt"
  ],
  "two-handed perks": [
    "Peritia Duorum Manuum"
  ],
  "what can you tell me about morrowind": [
    "Quid mihi de Morroventi narrare potes"
  ],
  "illusion perks": [
    "Peritia Inlusionis"
  ],
  "yes, you are": [
    "Sic, id facies"
  ],
  "you're taking me to solstheim": [
    "Me ad Solstheim portas"
  ],
  "alteration perks": [
    "Peritia Alterationis"
  ],
  "restoration perks": [
    "Peritia Magicae Salutis"
  ],
  "how can you not know how you got here": [
    "Quomodo nescire potest viam tuam huc"
  ],
  "you sailed here, right": [
    "Huc in navi venisti, certe"
  ],
  "headwaters of harstrad": [
    "Headwaters of Harstrad"
  ],
  "why won't you go back": [
    "Quidni revenias"
  ],
  "archery perks": [
    "Peritia Saggitationis"
  ],
  "sneak perks": [
    "Peritia Rependi"
  ],
  "light armor perks": [
    "Peritia Loricae Levis"
  ],
  "i'll pay you double your usual rate": [
    "Tibi duplicem solvam"
  ],
  "(give <global=dlc2costtosailx2> gold)": [
    "(Da <Global=DLC2CostToSailx2> auri)"
  ],
  "alchemy perks": [
    "Peritia Alchemiae"
  ],
  "lockpicking perks": [
    "Peritia Artis Clavicandi"
  ],
  "i'll be back": [
    "Reveniam"
  ],
  "harstrad cave": [
    "Cava Harstradis"
  ],
  "shrine of boethiah": [
    "Ara Boethis"
  ],
  "pickpocket perks": [
    "Peritia Furti"
  ],
  "speech perks": [
    "Peritia Artis Loquendi"
  ],
  "people are trying to kill me": [
    "Homines me necare temptant"
  ],
  "i'm not taking no for an answer": [
    "Noli me negare"
  ],
  "i was attacked by some cultists who came here on your ship": [
    "Oppugnatus sum a draconicultoribus qui huc in navi tua venerunt"
  ],
  "delving pincers": [
    "Vellicatores Explorantes"
  ],
  "visit the werewolves at frostmoon crag": [
    "Visita versipelles apud Rupes Glacilunae"
  ],
  "second letter from eec": [
    "Epistula Secunda ab NEI"
  ],
  "i found this heart stone with the ring": [
    "Hoc corsaxum cum anulo inveni"
  ],
  "float up": [
    "Ascende"
  ],
  "remove \"delving pincers\"": [
    "Remove \"Velicatores Explorantes\""
  ],
  "third letter from eec": [
    "Epistula Tertia ab NEI"
  ],
  "remove \"prying orbs\"": [
    "Remove \"Orbes Circumspectantes\""
  ],
  "where is master neloth": [
    "Ubi est Dominus Neloth"
  ],
  "boneless limbs": [
    "Mebra Sine Ossibus"
  ],
  "on apocrypha: delving pincers": [
    "De Apocrypha: Vellicatores Quaerentes"
  ],
  "prying orbs": [
    "Orbes Circumvidentes"
  ],
  "on apocrypha: gnashing blades": [
    "De Apocrypha: Enses Stridentes"
  ],
  "remove \"boneless limbs\"": [
    "Remove \"Membra Sine Ossibus\""
  ],
  "i'd like passage back to skyrim": [
    "Volo Caelifinem revenire"
  ],
  "remove \"gnashing blades\"": [
    "Remove \"Enses Stridentes\""
  ],
  "mount moesring": [
    "Mons Moesring"
  ],
  "you can't be serious": [
    "Serione dicis tu"
  ],
  "colcinder cave": [
    "Colcinder Cave"
  ],
  "each of solstheim's sacred stones grants a power which can be used only once": [
    "Sacra saxa Solstheimis potesatatem dat quae modo semper usurpatur"
  ],
  "return to the stone to reacquire the power": [
    "Redi ad saxum ut potestatem iterum utaris"
  ],
  "first letter from eec": [
    "Prima Epistula a NEI"
  ],
  "on apocrypha: boneless limbs": [
    "De Apocrypha: Membra Sine Ossibus"
  ],
  "on apocrypha: prying orbs": [
    "De Apocrypha: Orbes Circumvidentes"
  ],
  "mortrag peak": [
    "Summum Mortragis"
  ],
  "gnashing blades": [
    "Enses Stridentes"
  ],
  "he appeared mad": [
    "Iratus mihi videbatur"
  ],
  "i had to kill him to defend myself": [
    "Eum necavi debui ut me defenderem"
  ],
  "sprays writhing tentacles on the ground that stagger and poison foes that enter them": [
    "In solum sparsum, facit tentacula quae inimicos supplantant et venenum in eos ponunt"
  ],
  "did she ever return here": [
    "Umquamne huc reveni"
  ],
  "pedestal": [
    "Bas",
    "basis, basis"
  ],
  "have the dragons made life more difficult for the skaal": [
    "Draconesne vitam difficilorem pro Skaal fecerunt"
  ],
  "frykte peak": [
    "Summum Frykte"
  ],
  "where did you live before you were here": [
    "Ubi ante hic vixisti"
  ],
  "why did you take over thirsk": [
    "Quamobrem Thirskem cepistis"
  ],
  "posted notice": [
    "Nuntium Postatum"
  ],
  "skaal village, near the eastern coast of solstheim, is home to an ancient offshoot tribe of nords known as the skaal": [
    "Vicus Skaal, prope oram orientalem Solstheimis, est domus tribuum antiquorum Germanorum nomine Skaal"
  ],
  "shrine of mephala": [
    "Ara Mephalis"
  ],
  "once i've got stalhrim, how do i forge it into something useful": [
    "Postquam Stalhrim potitus ero, quomodo eum in rem utiliam faciam"
  ],
  "benthic scream removed": [
    "Benthica Clamatio Remota"
  ],
  "float down": [
    "Descende"
  ],
  "strange flora native to morrowind have sprouted up in the ash that blankets the southern reaches of solstheim": [
    "Flora mira e Morrivento in cinera ad Australem Solsthemis crescunt"
  ],
  "the ashfall from red mountain has driven much of solstheim's native wildlife into the northern half of the island": [
    "Caedis cineres e Monte Rubra faunam natibam ad partem septentrionalem insulae impulit"
  ],
  "ashfall from red mountain has driven much of solstheim's native wildlife into the northern half of the island": [
    "Caedis cineres e Monte Rubra faunam natibam ad partem septentrionalem insulae impulit"
  ],
  "the ancient nords of solstheim sealed their tombs with stalhrim, a form of enchanted ice as hard as stone": [
    "Germani antiqui Solstheimis tumula eorum cum Stalhrim, Glacie tam dura ut lapis"
  ],
  "ancient nords of solstheim sealed their tombs with stalhrim, a form of enchanted ice as hard as stone": [
    "Germani antiqui Solstheimis tumula eorum cum Stalhrim, Glacie tam dura ut lapis"
  ],
  "the skaal are the only ones who know the secret of crafting armor and weapons out of stalhrim": [
    "Skaal soli sunt qui secretum arma loricasque Stalhrimis faciendi sciunt"
  ],
  "skaal are the only ones who know the secret of crafting armor and weapons out of stalhrim": [
    "Skaal soli sunt qui secretum arma loricasque Stalhrimis faciendi sciunt"
  ],
  "tell me about forging stalhlrim": [
    "Dic mihi de Stalhlrim faciendo"
  ],
  "follow me. i need your help": [
    "Sequere me, auxilio tuo careo"
  ],
  "\"...the people of solstheim, the heirs of the guardian, must remain wary, lest the dark influence of herma-mora, or even the traitor himself, return someday.\" --excerpt from \"the guardian and the traitor\"": [
    "\"...homines Solstheimis, haeredes Custodis, cavere debent, ne vis Hermae-Morae, vel etiam Proditor ipse reveniat.\" -- excerptum a \"Custos et Proditor\""
  ],
  "to milore from nilara": [
    "Ad Milorem a Nilara"
  ],
  "milore from nilara": [
    "Ad Milorem a Nilara"
  ],
  "dearest dinya": [
    "Carissima Dinya"
  ],
  "solstheim was part of skyrim until the high king gave it to morrowind in 4e 16 to serve as a refuge for the dunmer after the events of the red year": [
    "Solstheim erat pars Caelifini ante Rex Altus eam Morrivento dedi in 4E XVI ut refugitum Atralfis esset post eventus Anni Rubri"
  ],
  "the dunmer of morrowind construct chitin armor out of the shells of native insects layered with resinous glues": [
    "Atralfi Morriventi faciunt Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ loricas ex folliculis bestiolis"
  ],
  "dunmer of morrowind construct chitin armor out of the shells of native insects layered with resinous glues": [
    "Atralfi Morriventi faciunt Ï‡á¿Ï„á¿¶Î½Î¿Ï‚ loricas ex folliculis bestiolis"
  ],
  "adril's survey results": [
    "Circumspectus Adrilis"
  ],
  "rieklings have little culture of their own, but scavenge what relics they can from around solstheim and form strange attachments to what they find": [
    "Rieklings paulum culturae suae habent, sed reliquas aliorum inveniunt et religiones mirabilem ab eis faciunt"
  ],
  "where can i obtain stalhrim": [
    "Ubi Stalhrim invenire possum"
  ],
  "hawala faaaakara": [
    "Hawala faaaakara"
  ],
  "baaaa rakhee kaloo": [
    "Baaaa rakhee kaloo"
  ],
  "pooja kan faroo kee jaa": [
    "Pooja kan faroo kee jaa"
  ],
  "goora": [
    "Goora"
  ],
  "--riekling godspeak song": [
    "-- Carmen Deiloquentis Riekling"
  ],
  "benthic scream equipped": [
    "Benthica Clamatio Parata"
  ],
  "the master wizards of house telvanni traditionally grow their homes from fungal spores, using secret methods known only to themselves": [
    "Domini Thaumatorgoi Gentis Telvanni domos e botellis crescunt"
  ],
  "master wizards of house telvanni traditionally grow their homes from fungal spores, using secret methods known only to themselves": [
    "Domini Thaumatorgoi Gentis Telvanni domos e botellis crescunt"
  ],
  "the island of solstheim is located to the north-east of skyrim": [
    "Insula Solstheimis ad Carbam Caelifinis invenitur"
  ],
  "island of solstheim is located to the north-east of skyrim": [
    "Insula Solstheimis ad Carbam Caelifinis invenitur"
  ],
  "the \"northern maiden\" out of windhelm is known to occasionally take passengers back and forth": [
    "\"Virgo Septentrionalis\" e Ventigalea nonnumquam vectores ad insulam fert"
  ],
  "\"northern maiden\" out of windhelm is known to occasionally take passengers back and forth": [
    "\"Virgo Septentrionalis\" e Ventigalea nonnumquam vectores ad insulam fert"
  ],
  "house redoran's reply": [
    "Responsum Domus Redoranis"
  ],
  "do not delete me - needed for export to work": [
    "Do Not Delete Me - needed for export to work"
  ],
  "some tales tell of ancient nord heroes whose voices were powerful enough to tame and ride dragons": [
    "Fabulae de Heroibus Antiquis narrant. Voces eorum tam potentes erant ut dracones mansuefacere et vehi possent"
  ],
  "thirsk mead hall was founded by hrothmund, who originally left the skaal in order to live the lifestyle of ancient nord warriors in the wilderness": [
    "Aula Hydromelli Thirsk factum est Hrothmundis, qui e Skaal discedit ut vitam Bellatorum Antiquorum Germanorem viveret"
  ],
  "raven rock, solstheim's main settlement, is firmly under the control of house redoran, the most powerful of the great houses of morrowind": [
    "Corvisaxum, oppidum Solstheimis, a Gente Redoran, gente potissima Morriveni, firme adnminstrantur"
  ],
  "netch are often domesticated on the morrowind mainland. wild netch were first sighted on solstheim several decades ago, perhaps drawn by the familiar flora that has grown up in the southern ashlands": [
    "Netch saepe domesticati sunt in terra Morroventis. Netch saevi visi sunt in Solstheim abhinc nonullos decades. Fortasse flora silmilis eos traxit"
  ],
  "shrine of azura": [
    "Ara Azurae",
    "Shrine of Azura"
  ],
  "improved bonemold armor": [
    "Melior Lorica Ossea"
  ],
  "improved bonemold shield": [
    "Melius Scutum Osseum"
  ],
  "improved bonemold boots": [
    "Meliores Caligae Ossea"
  ],
  "children of the all-maker": [
    "Filii Omnifactoris"
  ],
  "improved bonemold gauntlets": [
    "Meliora Manubria Ossea"
  ],
  "although the east empire company originally founded raven rock, they turned the failing mining colony over to the dark elves at the beginning of the fourth era": [
    "Etsi Negotium Euri Imperii Corvisaxum condidit, coloniam fallens Atralfis dedit ad intitum Quarti Aetatis"
  ],
  "most of raven rock's original structures were leveled when the red mountain erupted in 4e 5": [
    "Aedificia antiqua Corvisaxi fracti sunt cum Mons Ruber erumperet in 4E 5"
  ],
  "the dunmer have largely rebuilt the town over the past two hundred years": [
    "Atralfi oppidum ducenti annos refecerunt"
  ],
  "dunmer have largely rebuilt the town over the past two hundred years": [
    "Atralfi oppidum ducenti annos refecerunt"
  ],
  "draws upon stamina to release a scorching blast of steam that deals <mag> points of damage per second": [
    "Staminam poscit ut flumen vaporis facias quod <MAG> puncta vulneris singulis secundis facit"
  ],
  "raven rock mine, once one of the most abundant sources of ebony in morrowind, was shut down in 4e 170": [
    "Metallum Corvisaxi, semel fons abundantissima ebeni in Morrivento occlusum est in 4E 170"
  ],
  "this lead to a mass exodus from the town, cutting the population in half": [
    "Dimidum hominum ex oppido discesserunt"
  ],
  "thief cache": [
    "Foramen Furis",
    "Depositum Furis"
  ],
  "improved bonemold helmet": [
    "Melior Galea Ossea"
  ],
  "audiotemplatebristleback": [
    "AudioTemplateBristleback"
  ],
  "history of raven rock, vol. iii": [
    "Historia Corvisaxi, Vol. III"
  ],
  "stamina reduction": [
    "Stamina reduction"
  ],
  "the guardian and the traitor": [
    "Custos et Proditor"
  ],
  "guardian and the traitor": [
    "Custos et Proditor"
  ],
  "history of raven rock, vol. ii": [
    "Historia Corvisaxi, Vol. II"
  ],
  "history of raven rock, vol. i": [
    "Historia Corvisaxi, Vol. I"
  ],
  "heals the caster <mag> points": [
    "Te Sanat <mag> Puncta",
    "Salus tua augetur <mag> punctis"
  ],
  "lars battle-born": [
    "Lars Proeli-Natus"
  ],
  "thirsk, a revised history": [
    "Thirsk, Historia Revisa"
  ],
  "mora's curse": [
    "Maledictionem Morae"
  ],
  "the red year, vol. ii": [
    "Annus Ruber, Vol. II"
  ],
  "red year, vol. ii": [
    "Annus Ruber, Vol. II"
  ],
  "\"untithed to any thane or hold, and self-governed, with free worship, with no compensation to skyrim or the empire except as writ in the armistice of old wheresoever those might still apply, and henceforth let no man or mer say that the sons and daughters of kyne are without mercy or honor.\" -skyrim's offer of solstheim to morrowind, 4e 16": [
    "\"A nullo comiti nec regione possesa, et a se gubernatam cum religione aperto, cum nullo vectical nec Caelifini nec Imperio preater illa in Pacto Veterrimo quae iam manent, et dehinc nullus homo nec alfus Filios et Filias Kynis sine miseracordia nec honore dicere potest.\" - Offertum Caelifinis Solstheimis ad Morriventum, 4E 16"
  ],
  "temple priest hood": [
    "Temple Reclamations Priest Capucium"
  ],
  "the red year, vol. i": [
    "Annus Ruber, Vol. I"
  ],
  "red year, vol. i": [
    "Annus Ruber, Vol. I"
  ],
  "eydis's journal": [
    "Ephemeridis Eydis"
  ],
  "audiotemplatesprigganburnt": [
    "AudioTemplateSprigganBurnt"
  ],
  "the reclamations": [
    "Reclamationes"
  ],
  "reclamations": [
    "Reclamationes"
  ],
  "temple priest boots": [
    "Temple Reclamations Priest Caligae"
  ],
  "deathbrand instinct": [
    "Instinctum Mortibrandis"
  ],
  "does <mag> damage over <dur> seconds": [
    "Facit <mag> vulneris <dur> secundis"
  ],
  "additional opponents in melee range have a chance to be infected": [
    "Alii inimici prope inimicum corrumpi possunt"
  ],
  "eydis": [
    "Eydis"
  ],
  "for <dur> seconds, opponents in melee range have a chance to be infected": [
    "<dur> secundas, casus est ut inimici prope te aegri fiant"
  ],
  "fortify one-handed": [
    "Fortifica Singuli-Manu",
    "Adde ad Artem Singuli-Manu"
  ],
  "blessing of boethiah": [
    "Benedictio Boethis"
  ],
  "miraak's mask": [
    "Miraakis Persona"
  ],
  "blessing of mephala": [
    "Benedictio Mephalis"
  ],
  "one-handed weapons do <mag>% more damage": [
    "Arma Singuli Manu <mag>% plus vulneris faciunt"
  ],
  "resist <mag>% of magic": [
    "Resiste <mag>% magicae"
  ],
  "prices are <mag>% better": [
    "Pretia <mag>% meliora sunt"
  ],
  "float": [
    "Vola"
  ],
  "fortify barter": [
    "Auga Loquacitatem",
    "Adde ad Mercationem"
  ],
  "blessing of azura": [
    "Benedictio Azurae"
  ],
  "bandit archer": [
    "Bandit Archer"
  ],
  "critical hit": [
    "Ictus Criticus"
  ],
  "boar meat": [
    "Carnis Apri"
  ],
  "ash hopper meat": [
    "Carnis Saltatoris Cineris"
  ],
  "riekling spear thrower": [
    "Riekling Spear Thrower"
  ],
  "resist magic": [
    "Resiste Magicae",
    "Magicae Resistendi"
  ],
  "sacred stones only confer powers once a day": [
    "Saxa Sacra potestatem modo semel in die dant"
  ],
  "audiotemplateashguardian": [
    "AudioTemplateAshGuardian"
  ],
  "fortify sneak": [
    "Auge Furti",
    "Adde ad Artem Rependi"
  ],
  "white ridge sanctum": [
    "Sanctum Albi Iugi"
  ],
  "shoes": [
    "Caligulae",
    "Skaal Child Calcei"
  ],
  "atronach": [
    "Atronach",
    "Atronachum"
  ],
  "riekling warrior": [
    "Riekling Bellator"
  ],
  "felsaad tern": [
    "Felsaad Osprey"
  ],
  "arrow defense": [
    "Arrow Defence"
  ],
  "horker and ash yam stew": [
    "Ius Horker et Mali Terrae Cineris"
  ],
  "waterdamageshaders": [
    "WaterDamageShaders"
  ],
  "felsaad tern feathers": [
    "Alae Osprey Felsaad"
  ],
  "riekling scout": [
    "Riekling Explorator"
  ],
  "lurker abilities": [
    "Lurker Abilities"
  ],
  "sprintfx spell": [
    "Sprint FX Spell"
  ],
  "firewood": [
    "Cremia",
    "Cremium"
  ],
  "lurker stagger area attack": [
    "Lurker Stagger Area Attack"
  ],
  "cooked boar meat": [
    "Carnis Apri Coctus"
  ],
  "dlc2miraaksprintfx": [
    "Miraak Sprint FX"
  ],
  "where is ildari": [
    "Ubi est Ildari"
  ],
  "riekling charger": [
    "Riekling Rutor"
  ],
  "riekling courser": [
    "Riekling Cursor"
  ],
  "riekling abilities": [
    "Riekling Abilities"
  ],
  "set waterdamage global": [
    "Set WaterDamage Global"
  ],
  "riekling rider": [
    "Riekling Eques"
  ],
  "ash hopper leg": [
    "Crus Saltatoris Cineris"
  ],
  "i'd like training in restoration magic": [
    "Volo de Magica Recreationis discere",
    "Doce me de magica restuarandi, quaeso"
  ],
  "targets take <mag> points of frost damage for <dur> seconds": [
    "Inimici <mag> puncta vulneris Glaciei <dur> secundas accipiunt",
    "Inimici <mag> puncta vulneris Glaciei <dur> secundas, plus vulneris Staminae, accipiunt"
  ],
  "single use, then must be reacquired at the wind stone": [
    "Utere semel deinde iterum ad Saxum Venti accipe"
  ],
  "a <mag> point fiery explosion centered on the caster": [
    "Displosio <mag> vulneris ignis circum te",
    "Displosio <mag> punctorum vulneris prope te"
  ],
  "<mag> point fiery explosion centered on the caster": [
    "Displosio <mag> vulneris ignis circum te",
    "Displosio <mag> punctorum vulneris prope te"
  ],
  "does more damage to closer targets": [
    "Plus vulneris ad propinquiores facit",
    "Plus vulneris ad inimicos propinquiores facit"
  ],
  "mayhem": [
    "Mahemium"
  ],
  "fire storm 40": [
    "Fire Storm 40"
  ],
  "firestorm": [
    "FireStorm"
  ],
  "investigate kolbjorn barrow": [
    "Investiga Pabillum Kolbjornis"
  ],
  "rally secondary": [
    "Rally Secondary"
  ],
  "creatures and people up to level <mag> will attack anyone nearby for <dur> seconds": [
    "Inimica ad gradum <mag> omnes prope se oppugnabunt <dur> secundas",
    "Animalia hominesque ad gradum <mag> quidquid prope eum forte sit oppugnabunt <dur> secundas"
  ],
  "staff enchanter key": [
    "Incantatorem Rudium Clavis"
  ],
  "check water": [
    "Check Water"
  ],
  "miraak's boots": [
    "Miraakis Caligae"
  ],
  "revus": [
    "Revus"
  ],
  "bound dagger fx": [
    "Bound Dagger FX"
  ],
  "bound daggerfx": [
    "Bound Dagger FX"
  ],
  "dlc2rr01 veleth ally faction": [
    "DLC2RR01 Veleth Ally Faction"
  ],
  "fire wyrm flames": [
    "Fire Wyrm Flames"
  ],
  "font of magicka": [
    "Fons Magicae"
  ],
  "creaturedialogueseeker": [
    "CreatureDialogueSeeker"
  ],
  "font of stamina": [
    "Fons Staminae"
  ],
  "restore stamina": [
    "Redde Staminam"
  ],
  "restore magicka": [
    "Redde Magicam"
  ],
  "werebear abilities": [
    "Werebear Abilities"
  ],
  "blank effect": [
    "Blank Effect"
  ],
  "candlelight": [
    "Candelalux",
    "Lux Candelae"
  ],
  "audiotemplaterieklingquiet": [
    "AudioTemplateRieklingQuiet"
  ],
  "audiotemplateriekling": [
    "AudioTemplateRiekling"
  ],
  "rip heart out": [
    "Rip Heart Out"
  ],
  "reduce damage": [
    "Reduce Damage",
    "Vulnus Reduce"
  ],
  "single use, then must be reacquired at the sun stone": [
    "Uti semel, deindi, iterum ad Saxum Solis accipere debes"
  ],
  "fire storm 100": [
    "Fire Storm 100",
    "Tempestas Ignis 100"
  ],
  "mq02 effect": [
    "MQ02 Effect"
  ],
  "heals everyone close to the caster <mag> points": [
    "Salus omnium prope te augetur <mag> punctis",
    "Omnes prope te <mag> puncta salutis accipiunt"
  ],
  "single use, then must be reacquired at the water stone": [
    "Semel utere, deinde iterum a Lapide Aquae accipe debebis"
  ],
  "etherealfx spell": [
    "Ethereal FX Spell"
  ],
  "summons an ash guardian for <dur> seconds wherever the caster is pointing": [
    "Custos Cineris <dur> secundas vocat"
  ],
  "miraakfakeethereal": [
    "MiraakFakeEthereal"
  ],
  "adds <mag>% to the dragon breath and spell absorption of miraak's robes": [
    "Addet <mag>% ad potentias amiculi Miraakis "
  ],
  "bladesman": [
    "Ensifer",
    "Ensivir"
  ],
  "absorb spells": [
    "Absorbe Magicam"
  ],
  "bullseye": [
    "Tauroculus",
    "Scopus Medius"
  ],
  "flame explosion": [
    "Flame Explosion"
  ],
  "absorb <mag>% of the magicka from dragon breath and spells": [
    "Absorbe <mag>% magickae e halationibus draconum et magicae"
  ],
  "chance on hit to spawn a tentacle explosion": [
    "Casus est ut tentaculum facias dum vulneratus sis"
  ],
  "ash guardian attack": [
    "Ash Guardian Attack"
  ],
  "audiotemplatewerebear": [
    "AudioTemplateWerebear"
  ],
  "deep wounds": [
    "Vulnera Profunda"
  ],
  "skullcrusher": [
    "Calvariafractor"
  ],
  "bone breaker": [
    "Ossafractor"
  ],
  "burns the target for <mag> points": [
    "Urit <mag> puncta",
    "Inimicum urit <mag> puncta"
  ],
  "spell tome: conjure ash spawn": [
    "Liber Magicae: Voca Filium Cineris"
  ],
  "your dragon cannot fast travel right now": [
    "Draco tuus nunc celeriter perigrinari non potest"
  ],
  "you cannot cast that type of spell while riding a dragon": [
    "Hac magica non uti potes dum a dracone veharis"
  ],
  "your dragon cannot land at this time": [
    "Draco tuus hoc tempore descendere non potest"
  ],
  "cancelling your request to land": [
    "Negans rogamen descendendi"
  ],
  "your dragon is searching for a place to land": [
    "Draco tuus locum aptum descendendi quaerit"
  ],
  "unenchanted alteration staff": [
    "Rudis Alterationis Parata"
  ],
  "use ward while <alias=student> attacks you": [
    "Uti Scuto Magico dum <Alias=Student> te Oppugnat",
    "Uti magica defensi dum <Alias=Student> te oppugnat"
  ],
  "magic student": [
    "Discipulus Magicae",
    "Discipulus Magicus"
  ],
  "unenchanted conjuration staff": [
    "Rudis Conjurationis Parata"
  ],
  "find the source of power in <alias=dungeon>": [
    "Inveni fontem potestatis in <Alias=Dungeon>"
  ],
  "shout - learn about word wall": [
    "Shout - learn about word wall",
    "Clamatio - disce de pariete verbi"
  ],
  "ulfric doesn't care about outsiders": [
    "Ulfric de alienis nihil curat",
    "Vercingetorix flocci de alienis non facit"
  ],
  "can you train me to better use two-handed weapons": [
    "Doce me plus de armis ambobus manibus, quaeso",
    "Potesne me armis Ambabus manibus melius uti docere"
  ],
  "i'll do it": [
    "Id faciam"
  ],
  "interested in me, are you": [
    "De me curas"
  ],
  "i've got better things to do": [
    "Res meliores facendas habeo",
    "Res meliores faciendas habeo"
  ],
  "avoidance exterior": [
    "Avoidance Exterior"
  ],
  "what if i took care of these bandits": [
    "Quidni eos latrones necem",
    "Quidni hos latrones necam"
  ],
  "<alias=dungeon> is free of bandits": [
    "<Alias=Dungeon> liber latronum est",
    "<Alias=Dungeon> libertus est a latronibus"
  ],
  "unenchanted illusion staff": [
    "Rudis Inlusionis Parata"
  ],
  "you're on": [
    "Bene",
    "Faciamus"
  ],
  "(brawl - 100 gold)": [
    "(Pugna manibus - C auri)",
    "Id faciamus (Oppugna manibus - C Auri)"
  ],
  "i am not your champion, monster": [
    "Vindex tuus non sum, monstrum",
    "Vindex tuus non sum, monstruum"
  ],
  "unrelenting force - fus": [
    "Unrelenting Force - Fus"
  ],
  "septimus signus's outpost": [
    "Statio Septimi Signi",
    "Septimus Signus's Outpost"
  ],
  "best warrior in morthal, huh": [
    "Bellator Optimus Morthalis, huh",
    "Pugnator optimus Morthalis, huh"
  ],
  "unenchanted forsworn staff": [
    "Rudis Abiuratorum Parata"
  ],
  "unenchanted destruction staff": [
    "Rudis Destructionis Parata"
  ],
  "bandit highwayman": [
    "Latro Viarum"
  ],
  "cabbage soup": [
    "Ius Brassicae"
  ],
  "fortify magicka rate": [
    "Adde ad Recuperationem Magicae",
    "Fortifica Recuperationem Magickae"
  ],
  "summon unbound dremora": [
    "Voca Dremoram Liberam",
    "Voca Dremoram Sine Domine"
  ],
  "dwarven armored mudcrab": [
    "Cancerilutum Dwemere"
  ],
  "read notice of sale": [
    "Lege Notitiam Venditii"
  ],
  "mudcrab control quest": [
    "Mudcrab Control Quest"
  ],
  "ownership deed - mudcrab": [
    "Emptio - Cancerilutum"
  ],
  "notice of sale - dwarven mudcrab": [
    "Emptio - Cancerilutum"
  ],
  "purchase the mudcrab": [
    "Cancerilutum Emi"
  ],
  "my pet mudcrab": [
    "Delicia Cancerilutum"
  ],
  "meet your new pet": [
    "Conveni cum deliciam novam"
  ],
  "dwarven mudcrab pet": [
    "Dwarven Mudcrab Pet"
  ],
  "scrounger effect": [
    "Scrounger Effect"
  ],
  "spell tome: teleport pet mudcrab": [
    "Liber Magicae: Teleporta Deliciam Cancerilutum"
  ],
  "teleport pet: dwarven mudcrab": [
    "Teleporta Deliciam Cancerilutum"
  ],
  "scrounger ability": [
    "Scrounger Ability"
  ],
  "teleport dwarven mudcrab": [
    "Teleporta Deliciam Cancerilutum"
  ],
  "teleports pet dwarven mudcrab": [
    "Teleportat Deliciam Cancerilutum"
  ],
  "pet magic resistance": [
    "Pet Magic Resistance"
  ],
  "understone keep": [
    "Subsaxi Castellum",
    "Castellum Sublapidis"
  ],
  "casts a stream of long-distance fireballs in an arc. impact creates a wall of fire that does <50> points of fire damage per second": [
    "Rivus ignipilarum in arcu. Facit parietem flammarum quod <50> puncta vulneris ignis signulis secundis facit"
  ],
  "unbounded storms": [
    "Tempestates Sine Fine"
  ],
  "paralysis rune": [
    "Runus Paralyticus"
  ],
  "spell tome: unbounded freezing": [
    "Liber Magicus: Glacies Sine Fine"
  ],
  "unbounded flames": [
    "Flammae Sine Fine"
  ],
  "storm lightning": [
    "Storm Lightning"
  ],
  "shock bolt storm": [
    "Shock Bolt Storm"
  ],
  "unbounded freeze": [
    "Glacies Sine Fine"
  ],
  "spell tome: unbounded storms": [
    "Liber Magicus: Electricitas Sine Fine"
  ],
  "spell tome: paralysis rune": [
    "Liber Magicus: Runus Paralyticus"
  ],
  "targets in melee range take <40> points of shock damage per second to health, and half that to magicka": [
    "Inimici prope te <40> puncta vulneris electricitatis singulis secundis accipiunt et dimidium Magicae"
  ],
  "random lightning strikes deal an additional <30> damage": [
    "Fulmina forte <30> plus vulneris faciunt"
  ],
  "cast on a nearby surface, it explodes when enemies are nearby, immobilizing them for <dur> seconds": [
    "Fac solum prope te. Displodet cum inimicus veniat et eum immobilem faciat <dur> secundas"
  ],
  "spell tome: unbounded flames": [
    "Liber Magicus: Flammae Sine Fine"
  ],
  "unbounded freezing": [
    "Glacies Sine Fine"
  ],
  "fortify conjuration & illusion": [
    "Auge Conjurationem & Inlusionem"
  ],
  "apprentice robes of conjuration & illusion": [
    "Amiculum Discipuli Conjurationis Inlusionisque"
  ],
  "novice robes of destruction & alteration": [
    "Amiculum Novitii Destructionis & Alterationis"
  ],
  "fortify destruction and alteration": [
    "Auge Destructionem & Alterationem"
  ],
  "apprentice robes of destruction & alteration": [
    "Amiculum Discipuli Destructionis Alterationisque"
  ],
  "master robes of conjuration & illusion": [
    "Amiculum Domini Coniurationis & Inlusionis"
  ],
  "adept robes of destruction & alteration": [
    "Amiculum Adeptum Destructionis Alterationisque"
  ],
  "expert robes of destruction & alteration": [
    "Amiculum Expertum Destructionis Alterationisque"
  ],
  "unbounded frostbite": [
    "Adustus Nivibus Sine Fine"
  ],
  "mage robes": [
    "Magi Peplum",
    "Amiculum Magi"
  ],
  "novice robes of conjuration & illusion": [
    "Amiculum Novitii Coniurationis & Inlusionis"
  ],
  "expert robes of conjuration & illusion": [
    "Amiculum Expertum Conjurationis Inlusionisque"
  ],
  "a freezing wind envelops the caster, knocking down nearby enemies and freezing them for <50> points of damage per second to health and stamina": [
    "Ventus Frigidus te circumdat. Inimicos prope te prosterneret et eos congalescit cum <50> puncta vulneris singulis secundis Salutis Staminaeque"
  ],
  "freezing wind envelops the caster, knocking down nearby enemies and freezing them for <50> points of damage per second to health and stamina": [
    "Ventus Frigidus te circumdat. Inimicos prope te prosterneret et eos congalescit cum <50> puncta vulneris singulis secundis Salutis Staminaeque"
  ],
  "master robes of destruction & alteration": [
    "Amiculum Domini Destructionis & Alterationis"
  ],
  "adept robes of conjuration & illusion": [
    "Amiculum Adeptum Conjurationis Inlusionisque"
  ],
  "spell tome: elemental bolt": [
    "Liber Magicus: Saggitula Elementalis"
  ],
  "elemental flare": [
    "Conflagratio Elementalis"
  ],
  "the lost library": [
    "Bibliotheca Amissa"
  ],
  "lost library": [
    "Bibliotheca Amissa"
  ],
  "pride of hirstaang": [
    "Superbia Hirstaangis"
  ],
  "an elemental explosion for <mag> points of fire damage, <mag> points of shock damage, and half as much damage to stamina": [
    "Displosio Elementalis pro <mag> punctis vulneris ignis, <mag> punctis vulneris electricitatis, et dimidio vulneris staminae"
  ],
  "choking grasp": [
    "Prehensus Iugulans"
  ],
  "spell tome: choking grasp": [
    "Liber Magicus: Prehensus Iugulans"
  ],
  "spell tome: fenrik's welcome": [
    "Liber Magicus: Beneventum Fenrikis"
  ],
  "absorb <mag> points of health per second from target, for <dur> seconds": [
    "Absorbe <mag> puncta salutis singulis secuntis ab inimico <dur> secondas"
  ],
  "orum's aquatic escape": [
    "Efugitum Aquaticum Orumis"
  ],
  "summons a bear for <dur> seconds wherever the caster is pointing": [
    "Ursam vocat <dur> secundas"
  ],
  "resist <50>% of frost damage for <60> seconds": [
    "Resist <50>% vulneris glaciei <60> secundas"
  ],
  "health regenerates <50>% faster for <60> seconds": [
    "Salus <50>% celerius recuperat <60> secundas"
  ],
  "spell tome: orum's aquatic escape": [
    "Liber Magicus: Effugitum Aquaticum Orumis"
  ],
  "find the lost library of spell tomes": [
    "Inveni Bibliothecam Amissam Librorum Magicae"
  ],
  "turn undead fear": [
    "Turn Undead Fear"
  ],
  "elemental burst": [
    "Eruptio Elementalis"
  ],
  "caster becomes invisible and can open locks with level expert and below for <dur> seconds. activating an object or attacking will break the spell": [
    "Invisibilis es et clausura sub gradu experto aperire potes <dur> secundas"
  ],
  "spell tome: mara's wrath": [
    "Liber Magicus: Ira Freyjae"
  ],
  "touch of death": [
    "Tactus Mortis"
  ],
  "mara's wrath": [
    "Ira Freyjae"
  ],
  "spell tome: pride of hirstaang": [
    "Liber Magicus: Superbia Hirstaangis"
  ],
  "fenrik's welcome": [
    "Beneventum Fenrikis"
  ],
  "fenrik's welcome: unlock": [
    "Beneventum Fenrikis: Aperi"
  ],
  "spell tome: elemental burst": [
    "Liber Magicus: Conflagratio Elementalis"
  ],
  "spell tome: elemental blast": [
    "Liber Magicus: Displosio Elementalis"
  ],
  "spell tome: hangman's noose": [
    "Liber Magicus: Laqueus Carnificis"
  ],
  "hangman's noose": [
    "Laqueus Carnificis"
  ],
  "elemental blast": [
    "Conflagratio Elementalis"
  ],
  "resist <mag>% of frost damage for <dur> seconds": [
    "Resiste <mag>% gelidi vulneris <dur> secundas",
    "Resiste <mag>% vulneris glaciei <dur> secundas"
  ],
  "ancient tome chest": [
    "Arcam Librorum Antiquorum"
  ],
  "spell tome: elemental flare": [
    "Liber Magicus: Ignis Elementalis"
  ],
  "elemental bolt": [
    "Saggitula Elemandalis"
  ],
  "for <dur> seconds, opponents in melee range take <16> points fire damage per second": [
    "<dur> secundas, inimici prope te <16> puncta vulneris ignis singulis secundis accipiunt"
  ],
  "undead up to level <26> flee for <dur> seconds": [
    "Amortui ad gradum <26> <dur> secundas effugiunt"
  ],
  "spell tome: strangulation": [
    "Liber Magicus: Strangulatio"
  ],
  "caster is invisible for <dur> seconds. activating an object or attacking will break the spell. can breathe water for <dur> seconds. improved night vision for <dur> seconds": [
    "Invisibilis es <dur> secundas. Sub aqua <dur> secundas respirare potes. Videre noctu <dur> secundas"
  ],
  "strong box bgssse014": [
    "Strong Box BGSSSE014"
  ],
  "spell tome: touch of death": [
    "Liber Magicus: Tactus Mortis"
  ],
  "water breathing": [
    "Respirare Sub Aqua"
  ],
  "night eye": [
    "Oculus Nocti",
    "Oculus Noctis"
  ],
  "nix-hound": [
    "Pluto-Canis"
  ],
  "cooked nix-hound meat": [
    "Carnis Pluto-Canis Coctus"
  ],
  "read the notice of sale": [
    "Lege Emptionem"
  ],
  "notice of sale - nix-hound": [
    "Emptio - Pluto-Canis"
  ],
  "my pet nix-hound": [
    "Pluto-Canis, Delicia Mea"
  ],
  "spell tome: teleport pet nix-hound": [
    "Liber Magicae: Teleporta Delicia Pluto-Canis"
  ],
  "pet control quest": [
    "Pet Control Quest"
  ],
  "nix-hound meat": [
    "Pluto-Canis Meat"
  ],
  "teleport nix-hound": [
    "Teleporta Pluto-Canem"
  ],
  "purchase the nix-hound": [
    "Eme Pluto-Canem"
  ],
  "teleports nix-hound": [
    "Pluto-Canem Teleportat"
  ],
  "meet the nix-hound": [
    "Conveni cum Pluto-Cane"
  ],
  "i'd like to purchase the nix-hound (400 gold)": [
    "Pluto-Canem emere volo (CCCC Auri)"
  ],
  "teleport pet: nix-hound": [
    "Teleport Pet: Pluto-Canis"
  ],
  "audiotemplatenixhound": [
    "AudioTemplateNixHound"
  ],
  "ccbgssse035_creaturedialoguenixhound": [
    "ccBGSSSE035_CreatureDialogueNixHound"
  ],
  "nix hound": [
    "Nix Hound"
  ],
  "the ratway vaults": [
    "Viae Murum Hypogea"
  ],
  "ratway vaults": [
    "Viae Murum Hypogea"
  ],
  "windhelm stable services": [
    "Ventigalea Stabulum Services"
  ],
  "razelan": [
    "Razelan"
  ],
  "why are you looking for a dragonborn": [
    "Cur quaeris Draconigenam"
  ],
  "imperial boots of dwindling flames": [
    "Caligae Romanae Flammarum Diminuentium"
  ],
  "hide bracers of major alchemy": [
    "Chirothecae Cutis Maioris Alchemiae"
  ],
  "brawling victor": [
    "Victor Pugnae Manualis"
  ],
  "so you need me to bring him back": [
    "Vis me eum referre"
  ],
  "falmer ear": [
    "Auris Falmeris"
  ],
  "hillevi cruel-sea services": [
    "Hillevi Cruel-Sea Services"
  ],
  "scaled helmet of major magicka": [
    "Galea Squamata Maioris Magicae"
  ],
  "hide bracers of minor sure grip": [
    "Chirothecae Cutis Minoris Dexteritatis"
  ],
  "healing altar": [
    "Ara Salutis"
  ],
  "you're welcome to join me": [
    "Potes mecum coniungere, si vis"
  ],
  "spell tome: waterbreathing": [
    "Liber Magicus: Sub Aqua Respira"
  ],
  "are you pleased to see the stormcloaks in charge": [
    "Tibi placet Sturmmantelos regnantes videre"
  ],
  "riften fishery scene 04": [
    "Hiati Fishery Scene 04"
  ],
  "hjaalmarch stormcloak camp": [
    "Hjallmarci Sturmmantelorum Castra"
  ],
  "owned": [
    "Possessus"
  ],
  "ebony gauntlets of major alchemy": [
    "Ebeni Manicae Maioris Alchemiae"
  ],
  "saadia told me - you're assassins": [
    "Saadia mihi de vobis narravit - vos necatores estis"
  ],
  "dwarven centurion": [
    "Dwemeris Centurio"
  ],
  "potema": [
    "Potema"
  ],
  "ebony greatsword of the inferno": [
    "Spatha Ebeni Conflagrationis"
  ],
  "castle dour": [
    "Castellum Durum"
  ],
  "embalming tool": [
    "Instrumentum Condiendi"
  ],
  "silus vesuius's house": [
    "Domus Sili Vesuii"
  ],
  "bounty removed from": [
    "Vectigal extracta ab"
  ],
  "daedric warhammer of exhaustion": [
    "Bellimalleus Daedricus Exhaustionis"
  ],
  "what's wrong with joric": [
    "Quid fit cum Joricus"
  ],
  "lylvieve family house occupants": [
    "Lylvieve Familiae House Occupants"
  ],
  "who's elisif": [
    "Quis est Elisif"
  ],
  "unemployed laborer": [
    "Laborator Otiosus"
  ],
  "safia": [
    "Safia"
  ],
  "orcish shield of dwindling fire": [
    "Orci Scutum Minoris Ignis"
  ],
  "clothes": [
    "Vestimenta"
  ],
  "i'll find the proof you need": [
    "Inveniam argumentum quam quaeris"
  ],
  "dialogue (needs to sit under cw and cwcampaign)": [
    "Dialogue (needs to sit under CW et CWCampaign)"
  ],
  "orcish battleaxe of soul snares": [
    "Orci Bellisecuris Animicapti"
  ],
  "the lady stone": [
    "Lapis Matronae"
  ],
  "lady stone": [
    "Lapis Matronae"
  ],
  "orsinium and the orcs": [
    "Orsinium et Orci"
  ],
  "stalleo": [
    "Stalleo"
  ],
  "glass dagger of stunning": [
    "Pugio Vitreus Intermissionis"
  ],
  "ebony armor": [
    "Ebeni Lorica"
  ],
  "leather helmet of major illusion": [
    "Cutis Galea Maioris Inlusionis"
  ],
  "you need to pay the argonians a fair wage": [
    "Opus est tibi Argonias satis pecuniae solvere"
  ],
  "what drove them underground": [
    "Quid eos sub terram impellavit"
  ],
  "blessing of julianos": [
    "Benedictio Julianis"
  ],
  "hairlinefemalenord07": [
    "HairLineFemaleNord07"
  ],
  "wylandriah's spoon": [
    "Cuculum Vylandriae"
  ],
  "play time": [
    "Tempus Ludendi"
  ],
  "hairlinemaleimperialchild01": [
    "HairLineMaleImperialisChild01"
  ],
  "(show astrid the amulet and sealed letter)": [
    "(Demonstra Astridam monile et Epistulam Clausam)"
  ],
  "textures\\blood\\screenbloodalpha01opt.dds": [
    "Textures\\Blood\\ScreenBloodAlpha01OPT.dds"
  ],
  "did something happen": [
    "Acciditne aliquid"
  ],
  "explore <alias=dungeon> and recover the <alias=item>": [
    "Explora <Alias=Dungeon> et refer <Alias=Item>"
  ],
  "steel plate bracers of sure grip": [
    "Chirothecae Laminatae Dexteritatis"
  ],
  "the cake and the diamond": [
    "Libum et Adamanteum"
  ],
  "cake and the diamond": [
    "Libum et Adamanteum"
  ],
  "report skooma source to jarl of riften": [
    "Refer Ortum Skoomae ad Comitem Hiati"
  ],
  "staff of fury": [
    "Rudis Eumenidis"
  ],
  "eltrys": [
    "Eltrys"
  ],
  "how did you want me to handle it": [
    "Quid me facere voluisti"
  ],
  "whirlwind sprint 3": [
    "Whirlwind Sprint 3"
  ],
  "ebony battleaxe of lightning": [
    "Bellisecuris Ebeni Fulminis"
  ],
  "elven dagger of blizzards": [
    "Pugio Alfus Hiemis"
  ],
  "rift guard": [
    "Custos Hiati"
  ],
  "whiterun civil war argument scene": [
    "Whiterun Civil War Argument Scene"
  ],
  "enemies": [
    "Enemies"
  ],
  "potion of the knight": [
    "Potio Equitis"
  ],
  "crystaldrift cave": [
    "Spelunca Crystallorum Erratorum"
  ],
  "i have a question about the statue": [
    "Rogatum habeo de hac statua"
  ],
  "hakon, gormlaith, felldir": [
    "Hakon, Gormlaith, Felldir"
  ],
  "who are they": [
    "Qui sunt",
    "Who are they",
    "Qui sunt illi"
  ],
  "glass dagger of consuming": [
    "Pugio Vitreus Consumendi"
  ],
  "holy daedric greatsword": [
    "Spatha Sancta Daedrica"
  ],
  "my mistake": [
    "Erravi",
    "Ignosce mihi"
  ],
  "daedric bow of fire": [
    "Arcus Daedricus Ignis"
  ],
  "ward - steadfast": [
    "Praesidium Magicum - Persistens"
  ],
  "get derkeethus out of the cave": [
    "Fer Derkeethum e spelunca"
  ],
  "mythic dawn boots": [
    "Aurorae Mythicae Caligae"
  ],
  "i have important information for you": [
    "Informationem habeo tibi"
  ],
  "falion": [
    "Falion"
  ],
  "orcish shield of frost suppression": [
    "Orci Scutum Contra Glaciem"
  ],
  "dwarven greatsword": [
    "Dvemeris Spatha"
  ],
  "the faction for ghosts": [
    "The Faction For Ghosts"
  ],
  "faction for ghosts": [
    "The Faction For Ghosts"
  ],
  "t8r": [
    "T8r"
  ],
  "relations": [
    "Amicitia"
  ],
  "scaled bracers of sure grip": [
    "Chirothecae Squamatae Dexteritatis"
  ],
  "call of the wild 3": [
    "Call Of Wild 3"
  ],
  "orcish battleaxe of fear": [
    "Orci Bellisecuris Timoris"
  ],
  "i've planted the contraband on the dainty sload": [
    "Furta in Sload Cupes imposui"
  ],
  "bashnag": [
    "Bashnag"
  ],
  "yes, and here's what was in the safe": [
    "Sic, et haec sunt omnia ex arca"
  ],
  "virtuous elven greatsword": [
    "Virtutis Spatha Alfa"
  ],
  "why would he do that to his own family": [
    "Cur id faceret ad familiam suam"
  ],
  "sounds easy enough": [
    "Satis facile mihi videtur",
    "Satis facilis mihi videtur"
  ],
  "reporting in": [
    "Nuntium refero"
  ],
  "dialoguegenericsharedinfo": [
    "DialogueGenericSharedInfo"
  ],
  "i have some questions about your daughter": [
    "Rogatum de filia tua habeo"
  ],
  "remind me - what am i supposed to do": [
    "Memento mihi - Quid faciendum est mihi"
  ],
  "uraccen": [
    "Uraccen"
  ],
  "nepos's house": [
    "Domus Nepotis"
  ],
  "glass greatsword of expelling": [
    "Spatha Vitrea Expellendi"
  ],
  "hide helmet of minor alchemy": [
    "Cutis Galea Minoris Alchemiae"
  ],
  "for intimidate brawling": [
    "For intimidate brawling"
  ],
  "love repository": [
    "Promptuarium Amoris"
  ],
  "dreamworld": [
    "Mundus Somniorum"
  ],
  "maybe some other time": [
    "Fortasse alio tempore"
  ],
  "greywater grotto": [
    "Specus Canae Aquae"
  ],
  "vigilants fighting atronach": [
    "Vigilants fighting Atronachum"
  ],
  "move it": [
    "Move Id"
  ],
  "you mentioned nivenor": [
    "Commemoravisti Nivenorem"
  ],
  "traveling alone can be so dangerous": [
    "Solum peregrinans tam periculosum esse potest"
  ],
  "bring some frost salts to <alias.shortname=questgiver>": [
    "Fer Salia Congelationis ad <Alias.ShortName=QuestGiver>"
  ],
  "ansilvund key": [
    "Clavis Ad Ansilvund"
  ],
  "never mind. i... i was mistaken": [
    "Ignosce mihi... Erravi"
  ],
  "[danger]": [
    "[PERICULUM]"
  ],
  "delphine": [
    "Delphina"
  ],
  "elven greatsword of the blaze": [
    "Spatha Alfa Congflagrantis"
  ],
  "allykilled": [
    "AllyKilled"
  ],
  "elven bow of the blaze": [
    "Arcus Alfus Congflagrantis"
  ],
  "giant faction": [
    "Giant Faction"
  ],
  "stands-in-shallows": [
    "In-Breviis-Stat"
  ],
  "audiotemplatehagraven": [
    "AudioTemplateHagraven"
  ],
  "tell me about the sweep jobs": [
    "Dic mihi de muneribus \"sweep\""
  ],
  "hide shield of waning frost": [
    "Scutum Cutis Insenescentis Congelationis"
  ],
  "daedric bow of banishing": [
    "Arcus Daedricus Expellendi"
  ],
  "phantom form": [
    "Forma Phantasmae"
  ],
  "frost river farm faction": [
    "Frost River Farm Faction"
  ],
  "how do i get out of here": [
    "Quomodo possum exire"
  ],
  "humanbeard41": [
    "HumanBeard41"
  ],
  "conjure potent flame thrall": [
    "Voca Servus Flammarum"
  ],
  "what do i do with the elder scroll when i find it": [
    "Quid cum Codice Veterrimo faciam post eum inveniam"
  ],
  "elenwen should stay": [
    "Elenven manenda est"
  ],
  "shall we see what your father thinks of this letter": [
    "Videamusne quid patrem tuum de hoc epistula censeat"
  ],
  "aringoth's safe": [
    "Arca Aringotis"
  ],
  "dark blond": [
    "Dark Blond"
  ],
  "light blond": [
    "Light Blond"
  ],
  "da07shrineofmehrunesdagonexittrigger": [
    "DA07ShrineofMehrunesDagonExitTrigger"
  ],
  "honey blond": [
    "Honey Blond"
  ],
  "help the heroes of sovngarde dispel alduin's mist": [
    "Adiuva Heroos Sovangardis Nebula Aldiuni dimittere"
  ],
  "auburn": [
    "Auburn"
  ],
  "medium brown": [
    "Medium Brown"
  ],
  "dark brown": [
    "Dark Brown"
  ],
  "steel grey": [
    "Steel Grey"
  ],
  "i don't like to interfere in family affairs": [
    "Nolo in rebus familiaribus interpellare"
  ],
  "bright grey": [
    "Bright Grey"
  ],
  "do you know why he was after her": [
    "Scisne cur ille eam persecutus sit"
  ],
  "barenziahs crown": [
    "Corona Barenziae"
  ],
  "adept": [
    "Adeptus"
  ],
  "bear claws": [
    "Ungues Ursi"
  ],
  "greentintdark": [
    "GreenTintDark"
  ],
  "bluishtintdark": [
    "BluishTintDark"
  ],
  "elven battleaxe of leeching": [
    "Bellisecuris Alfus Sanguisugae"
  ],
  "wolf gauntlets": [
    "Manicae Lupi"
  ],
  "greentintlight": [
    "GreenTintLight"
  ],
  "humanskincorpse": [
    "HumanSkinCorpse"
  ],
  "you've got a deal": [
    "Consentior"
  ],
  "yellowishtintaverage": [
    "YellowishTintAverage"
  ],
  "dialoguedawnstarquicksilverleigelflond02": [
    "DialogueDawnstarQuickArgenteiLeigelfLond02"
  ],
  "coloraverage": [
    "ColorAverage"
  ],
  "honed ancient nord battle axe of ice": [
    "Melior Germanicus Antiquus Bellisecuris Gelidus"
  ],
  "redtintdeep": [
    "RedTintDeep"
  ],
  "filnjar": [
    "Filnjar"
  ],
  "elven battleaxe of depleting": [
    "Bellisecuris Alfus Deplendi"
  ],
  "redtintaverage": [
    "RedTintAverage"
  ],
  "bluishtintaverage": [
    "BluishTintAverage"
  ],
  "browntintaverage": [
    "BrownTintAverage"
  ],
  "i ended up killing your enemy": [
    "Inimicum tuum necavi"
  ],
  "orcish bow of shocks": [
    "Orci Arcus Electricitatis"
  ],
  "humanskinbasetan": [
    "HumanSkinBaseTan"
  ],
  "redtintdark": [
    "RedTintDark"
  ],
  "redtintlight": [
    "RedTintLight"
  ],
  "sabre cat guardian spirit": [
    "Animus Felis Mucronis"
  ],
  "humanskindarkskin01": [
    "HumanSkinDarkSkin01"
  ],
  "lowersocketred01": [
    "LowerSocketRed01"
  ],
  "white phial services": [
    "White Phial Services"
  ],
  "humanskinbasewhite04": [
    "HumanSkinBaseWhite04"
  ],
  "10,000 gold": [
    "MMMMMMMMMM Denarii"
  ],
  "humanskinbasewhite05": [
    "HumanSkinBaseWhite05"
  ],
  "pine thrush egg": [
    "Ovum Turdi Pini"
  ],
  "humanskinbasepale01": [
    "HumanSkinBasePale01"
  ],
  "humanskindarkskin04": [
    "HumanSkinDarkSkin04"
  ],
  "humanskindarkskin05": [
    "HumanSkinDarkSkin05"
  ],
  "orcskin03": [
    "OrcSkin03"
  ],
  "orcskin04": [
    "OrcSkin04"
  ],
  "highelfskin01": [
    "HighElfSkin01"
  ],
  "highelfskin02": [
    "HighElfSkin02"
  ],
  "it was the one that attacked helgen, when ulfric escaped from the imperials": [
    "Erat draco qui Helgen oppugnavit, cum Vercingetorix ab Imperialibus effugit"
  ],
  "highelfskin03": [
    "HighElfSkin03"
  ],
  "highelfskin04": [
    "HighElfSkin04"
  ],
  "yellowtintbright": [
    "YellowTintBright"
  ],
  "bluishtintlight": [
    "BluishTintLight"
  ],
  "assist the people of the reach (<global=friendscountreach>/<global=friendstotalmajorhold>)": [
    "Adiuva incolas de Prehensu (<Global=FriendsCountReach>/<Global=FriendsTotalMajorHold>)"
  ],
  "blackbrownbase": [
    "BlackBrownBase"
  ],
  "titus mede ii is dead... by my hand": [
    "Titus Medus II mortuus est... meo manu"
  ],
  "pelagia farm": [
    "Latifundus Pelagiae"
  ],
  "blue": [
    "Blue"
  ],
  "red eagle": [
    "Aquila Rubra"
  ],
  "never mind, maybe later": [
    "Ignosce mihi, fortasse postea"
  ],
  "reachwind eyrie": [
    "Reachwind Eyrie"
  ],
  "orcish bow of scorching": [
    "Orci Arcus Adurendi"
  ],
  "brown": [
    "Brown"
  ],
  "orange": [
    "Orange"
  ],
  "stormcloak introductions": [
    "Sturmmantelorum Introductiones"
  ],
  "how do i destroy the bee hives": [
    "Quomodo urticaria apium destruam"
  ],
  "here's the book you were looking for": [
    "Hic est liber quem quasivisti"
  ],
  "i can't help you with that": [
    "Te cum eo adiuvare non possum"
  ],
  "ralof": [
    "Ralof"
  ],
  "do you know why he was after you": [
    "Scisne cur illi te persequantur"
  ],
  "humanskinbasewhite07": [
    "HumanSkinBaseWhite07"
  ],
  "unrelenting force middle": [
    "Unrelenting Force Middle"
  ],
  "dwarven war axe of diminishing": [
    "Dvemeris Bellisecuris Diminuendi"
  ],
  "scroll of dread zombie": [
    "Codex Amortui Potentis"
  ],
  "humanskinbasewhite09": [
    "HumanSkinBaseWhite09"
  ],
  "if you tell me where it is, i can get it for you": [
    "Si mihi dices ubi esset, tibi adferre potero"
  ],
  "is there anything i can do": [
    "Estne quis quam facere possim"
  ],
  "warpaintred01": [
    "WarpaintRed01"
  ],
  "thalmor embassy, dungeon": [
    "Legatio Visigothi, Oublieta"
  ],
  "wizards' guard": [
    "Custos Thaumatorgos"
  ],
  "lockpicking": [
    "Clavicare"
  ],
  "warpaintred03": [
    "WarpaintRed03"
  ],
  "boethiah cultist": [
    "Secutor Boethis"
  ],
  "i don't know your brother": [
    "Fratrem tuum non novi"
  ],
  "rank = which mission was last successful": [
    "Rank = which mission was last successful"
  ],
  "warpaintblue03": [
    "WarpaintBlue03"
  ],
  "warpaintblue04": [
    "WarpaintBlue04"
  ],
  "drowned sorrows": [
    "Tristitiae Summersae"
  ],
  "mq201 thalmor faction": [
    "MQ201 Visigothi Faction"
  ],
  "warpaintgreen02": [
    "WarpaintGreen02"
  ],
  "warpaintgreen03": [
    "WarpaintGreen03"
  ],
  "warpaintorange02": [
    "WarpaintOrange02"
  ],
  "warpaintyellow01": [
    "WarpaintYellow01"
  ],
  "bandit chief": [
    "Princeps Latronum"
  ],
  "grimsever's return": [
    "Refer Grimsever"
  ],
  "you look thirsty": [
    "Aliquid tibi bibendumst"
  ],
  "master alembic": [
    "Master Alembic"
  ],
  "humanskindarkskin09": [
    "HumanSkinDarkSkin09"
  ],
  "humanskindarkskin06": [
    "HumanSkinDarkSkin06"
  ],
  "conjure familiar": [
    "Voca Familiarem"
  ],
  "humanskindarkskin07": [
    "HumanSkinDarkSkin07"
  ],
  "dunmer ghost": [
    "Phantasma Dunmeris"
  ],
  "dialoguemarkarthhagscurescenemuiribothela04": [
    "DialogueMarkarthHagsCureSceneMuiriBothela04"
  ],
  "darkelfskin02": [
    "DarkElfSkin02"
  ],
  "what's the plan": [
    "Quid est consilium"
  ],
  "darkelfskin03": [
    "DarkElfSkin03"
  ],
  "dunrannveigqstlosttocombat": [
    "dunRannveigQSTLOSTTOCOMBAT"
  ],
  "darkelfskin05": [
    "DarkElfSkin05"
  ],
  "tell me about your parents": [
    "Narra mihi de patribus"
  ],
  "avoidthreat": [
    "AvoidThreat"
  ],
  "aura whisper": [
    "Sussura Aurae"
  ],
  "hairshavedmaleorc12": [
    "HairShavedMaleOrc12"
  ],
  "highelfskin05": [
    "HighElfSkin05"
  ],
  "you cannot cast ranged spells underwater": [
    "Non potes magicam mittere sub aqua"
  ],
  "myths of sheogorath": [
    "Mythica Sanguinis"
  ],
  "woodelfskin04": [
    "WoodElfSkin04"
  ],
  "imperial boots of resist shock": [
    "Caligae Romanae Contra Vim Electricam"
  ],
  "orcmalelipcolor02": [
    "OrcMaleLipColor02"
  ],
  "orcmalelipcolor03": [
    "OrcMaleLipColor03"
  ],
  "orcmalelipcolor04": [
    "OrcMaleLipColor04"
  ],
  "darkelfmalelips01": [
    "DarkElfMaleLips01"
  ],
  "daedric war axe of winter": [
    "Bellisecuris Daedricus Hiemis"
  ],
  "daedric greatsword of nullifying": [
    "Spatha Daedrica Nullificandi"
  ],
  "so then what do you do": [
    "Sic...quid facis"
  ],
  "highelflips01": [
    "HighElfLips01"
  ],
  "bound bow": [
    "Arcus Vinctus"
  ],
  "lipshighelfmale02": [
    "LipsHighElfMale02"
  ],
  "lipshighelfmale03": [
    "LipsHighElfMale03"
  ],
  "erikur house scene 2": [
    "Erikur House Scene 2"
  ],
  "treva's watch escape tunnel": [
    "Trevae Turris Commeatus Exeundi"
  ],
  "if you have too many quest targets, check your journal to see if you have more than one active quest": [
    "Si numerus munerorum nimis magnus esset, in diurnario quaere ut videas quot munera activa habeas"
  ],
  "in the miscellaneous category, you can turn each objective on or off": [
    "In Miscelania, potes eligere singula obiectiva"
  ],
  "lipshighelfmale05": [
    "LipsHighElfMale05"
  ],
  "steel boots of strength": [
    "Caligae Adamanteae Fortitudinis"
  ],
  "lipswoodelfmale01": [
    "LipsWoodElfMale01"
  ],
  "iron battleaxe of frost": [
    "Bellisecuris Ferri Gelidus"
  ],
  "lipswoodelfmale02": [
    "LipsWoodElfMale02"
  ],
  "the school of conjuration is used to raise the dead, summon creatures from one of the planes of oblivion, and soul trap opponents killed in combat": [
    "Schola Coniurationis usurpatur mortuus ad vitam reddere, socios ab Oblivione vocare, et capere animos inimicorum in proelio necatorum"
  ],
  "school of conjuration is used to raise the dead, summon creatures from one of the planes of oblivion, and soul trap opponents killed in combat": [
    "Schola Coniurationis usurpatur mortuus ad vitam reddere, socios ab Oblivione vocare, et capere animos inimicorum in proelio necatorum"
  ],
  "glass greatsword of thunderbolts": [
    "Spatha Vitrea Fulminum"
  ],
  "black briar meadery scene 02": [
    "Black Briar Meadery Scene 02"
  ],
  "lipswoodelfmale05": [
    "LipsWoodElfMale05"
  ],
  "thieves guild dialogue scene 10": [
    "Sodalitatis Furum Dialogue Scene 10"
  ],
  "lipsredguardmale02": [
    "LipsRedguardMale02"
  ],
  "key to dawnstar jail": [
    "Clavis ad Carcerem Luciferis"
  ],
  "roasted ox leg": [
    "Crus Cocta Vaccae"
  ],
  "how about <alias=sonsmajorholdcapital>": [
    "Quid de <Alias=SonsMajorHoldCapital>"
  ],
  "lipsredguardmale03": [
    "LipsRedguardMale03"
  ],
  "lipsredguardmale05": [
    "LipsRedguardMale05"
  ],
  "lipsredguardfemale01": [
    "LipsRedguardFemale01"
  ],
  "glass helmet of extreme conjuring": [
    "Galea Vitrea Maiorum Vocantium"
  ],
  "shield rack": [
    "Scutarium"
  ],
  "burial urn": [
    "Urna Sepulturae"
  ],
  "lost valley redoubt": [
    "Redubitum Vallis Perditi"
  ],
  "refined tunic": [
    "Tunica Nobilis"
  ],
  "fur bracers": [
    "Bracchialia Renonis"
  ],
  "temp - lights out": [
    "TEMP - LIGHTS OUT"
  ],
  "contract: kill hern": [
    "Foedus: Neca Hern"
  ],
  "i have a ward spell, but i've never really used it": [
    "Habeo magicam defensivam, sed eo numquam uti"
  ],
  "still have that letter you need delivered": [
    "Iamne habes epistulam tradendam"
  ],
  "madanach is behind these killings": [
    "Culpa horum mortificationum Madanachi est"
  ],
  "shards of mehrunes' razor": [
    "Partes Novaculae Mehruni"
  ],
  "femaleeyeshumanlightblue": [
    "FemaleEyesHumanLightBlue"
  ],
  "ever think of moving to morrowind": [
    "Umquam in movendo ad Morrowind cogitavisti"
  ],
  "i have a delivery from romlyn": [
    "Fascem e Romlyn habeo"
  ],
  "siddgeir": [
    "Siddgeir"
  ],
  "bee and barb scene 15": [
    "Bee et Barb Scene 15"
  ],
  "only shows up if player is stormcloak": [
    "Only shows up if Player is Stormcloak"
  ],
  "hairlinemalenord5": [
    "HairLineMaleNord5"
  ],
  "mauhulakh's cellar": [
    "Cellariumium Mauhulakis"
  ],
  "do you want to steal this horse": [
    "Visne equum furari"
  ],
  "wood gate": [
    "Porta Lignea"
  ],
  "bring barrel of mead to <alias=tg03spjarlalias>": [
    "Fer Cuppam Hydromelli ad <Alias=TG03SPJarlAlias>"
  ],
  "dwarven mace of arcing": [
    "Dvemeris Maca Electricitatis"
  ],
  "arivanya": [
    "Arivanya"
  ],
  "do you grow all your own crops": [
    "Cultivasne omnes segetes tuas"
  ],
  "elven greatsword of blizzards": [
    "Spatha Alfa Hiemis"
  ],
  "find evidence about nepos": [
    "Inveni Evidentiam de Nepote"
  ],
  "miner's house": [
    "Domus Cunicularius"
  ],
  "actually, there is something you could do for me": [
    "De vero, est alquid qoud pro me facere possis"
  ],
  "aspiring mage": [
    "Magus Sperans"
  ],
  "and honningbrew": [
    "Et Honningbrevis"
  ],
  "the whispering door": [
    "Porta Sussurans"
  ],
  "whispering door": [
    "Ianua Sussurans",
    "Porta Sussurans"
  ],
  "fire rune": [
    "Runus Ignis"
  ],
  "powers can only be used once a day": [
    "Potentiis modo semel in die uti potes"
  ],
  "animal allegiance 1": [
    "Animal Allegiance 1"
  ],
  "what's wrong with your apprentice": [
    "Quid est difficultas cum discipulo tuo"
  ],
  "circle of protection": [
    "Circulum Praesidii"
  ],
  "fortify block": [
    "Adde ad Scuto Interponendum"
  ],
  "orcish shield": [
    "Orci Scutum"
  ],
  "follow brynjolf": [
    "Sequere Brynjolf"
  ],
  "why'd you join the forsworn": [
    "Cur Abiuratos adiunxisti"
  ],
  "what troubles you": [
    "Quid est difficultas"
  ],
  "endon's house": [
    "Domus Finisonis"
  ],
  "vaermina's torpor": [
    "Torpor Morphei"
  ],
  "twilight sepulcher dialogue": [
    "Twilight Sepulcher Dialogue"
  ],
  "blue black": [
    "Blue Black"
  ],
  "why keep it a secret": [
    "Cur secretum maneat"
  ],
  "hroki": [
    "Hroki"
  ],
  "audiocategoryamb": [
    "AudioCategoryAMB"
  ],
  "audiocategoryvoc": [
    "AudioCategoryVOC"
  ],
  "audiocategorynonvoc": [
    "AudioCategoryNonVOC"
  ],
  "$voice": [
    "$Voice"
  ],
  "femaleeyesorcyellow": [
    "FemaleEyesOrcYellow"
  ],
  "mg great hall scene 5": [
    "MG Great Hall Scene 5"
  ],
  "riverwood gerdurs house faction": [
    "Riverwood Gerdurs House Faction"
  ],
  "what are my new orders, sir": [
    "Quae sunt mandata nova, domine"
  ],
  "unrelenting force": [
    "Vis Interminabilis"
  ],
  "browntintdark": [
    "BrownTintDark"
  ],
  "most friends and hirelings that are following you can be ordered to interact with the world": [
    "Amici qui te sequuntur mandata accipiunt et res varias in mundo facere possunt"
  ],
  "they can unlock doors, pick up objects, go to specific locations, or attack enemies": [
    "Portas aperire, res sumere, ad locos ire, vel contra inimicos pugnare possunt"
  ],
  "stealth": [
    "Surreptum"
  ],
  "wisp wrappings": [
    "Emporetici Nebulae"
  ],
  "elven battleaxe of reaping": [
    "Bellisecuris Alfus Saturni"
  ],
  "invisible child": [
    "Invisible Child"
  ],
  "deadly fear poison": [
    "Venenum Timoris Letiferis"
  ],
  "hlaalu farm": [
    "Hlaalu Latifundus"
  ],
  "glass bow of torpor": [
    "Arcus Vitreus Torporis"
  ],
  "player name": [
    "Nomen"
  ],
  "brain rot": [
    "Cerebrum Putrescens"
  ],
  "prisoner": [
    "Captiva",
    "captīvus, captīvī"
  ],
  "meet jon battle-born at talos' statue": [
    "Conveni cum Iohanni Proeli-Natus ad statuam Talos"
  ],
  "i'd better go see what arngeir knows about this shout": [
    "Opus est mihi ad Arngeirem ire ut de hac clamatione discam"
  ],
  "i can break some of your bones if that's what it's going to take": [
    "Possum ossa tua frangere si vis"
  ],
  "what kind of security should i expect": [
    "Quale preasidium mihi exspectandum est"
  ],
  "why do you fear hevnoraak": [
    "Cur Hevnoraakem Times"
  ],
  "steel gauntlets of sure grip": [
    "Adamanteae Manicae Dexteritatis"
  ],
  "lillith maiden-loom": [
    "Lillith Virgo-Texor"
  ],
  "would you believe i was given a vision": [
    "Credasne me visionem propheticam habuerim"
  ],
  "word of power": [
    "Verbum Potestatis"
  ],
  "what do i do": [
    "Quid faciam"
  ],
  "i saw you eyeing that serving girl": [
    "Te vidi oculos ancillae dare"
  ],
  "chicken's egg": [
    "Ovum Gallinae"
  ],
  "imperial boots of major sneaking": [
    "Caligae Romanae Maioris Repsendi"
  ],
  "deadly aversion to shock": [
    "Aversio Letifera Electricitati"
  ],
  "ebony boots of dwindling shock": [
    "Caligae Ebeni Electricitatis Diminuentis"
  ],
  "sarthis idren has been taken care of": [
    "Sarthis Idren mortuus est"
  ],
  "just checking on our take": [
    "De pecunia nostra scire volo"
  ],
  "how are we doing": [
    "Quomodo res se habent"
  ],
  "dialoguegenericdestroyobject": [
    "DialogueGenericDestroyObject"
  ],
  "fletcher": [
    "Fletcher"
  ],
  "you mean me, don't you": [
    "Nonne loqueris de me",
    "De me loqueris, nonne"
  ],
  "i defeated alduin": [
    "Alduinum superavi"
  ],
  "slow time": [
    "Tempus Lentum"
  ],
  "windhelm stables": [
    "Catabulum Ventigaleae"
  ],
  "azzadal": [
    "Azzadal"
  ],
  "do you promise to serve me": [
    "Promittisne me servire"
  ],
  "call of valor": [
    "Vocatio Valoris"
  ],
  "marksfemalehumanoid05leftgash": [
    "MarksFemaleHumanoid05LeftGash"
  ],
  "orcish bow of binding": [
    "Orci Arcus Deligandi"
  ],
  "ice spike (for dragons only)": [
    "Ice Spike (for DRAGONS only)"
  ],
  "vile vapor": [
    "Vile Vapor"
  ],
  "dare i ask where all this meat comes from": [
    "Audeone rogare quo haec omnia venerint"
  ],
  "honed ancient nord greatsword of cold": [
    "Melior Germanica Antiqua Spatha Frigidis"
  ],
  "daedric helmet of extreme illusion": [
    "Galea Daedrica Inlusionis Extremae"
  ],
  "elven war axe of debilitation": [
    "Alfus Bellisecuris Debilitationis"
  ],
  "animal allegiance": [
    "Amicitia Animalium"
  ],
  "dismay": [
    "Consternatio"
  ],
  "staff of banishing": [
    "Rudis Daedricas Expellendi"
  ],
  "hand me the letter": [
    "Da mihi epistulam"
  ],
  "i'll take it to him": [
    "Ea illi adferam"
  ],
  "special paralysis ability": [
    "Special Paralysis Ability"
  ],
  "weakness to shock": [
    "Debilitas Electricitati"
  ],
  "the shadow stone": [
    "Lapis Umbrarum"
  ],
  "shadow stone": [
    "Lapis Umbrarum"
  ],
  "marked for death": [
    "Signatus Mortui"
  ],
  "i'm not sure this was all worth it, after all": [
    "Nescio num hoc pretium meruerit"
  ],
  "glass sword of garnering": [
    "Gladius Vitreus Horti"
  ],
  "what's your relationship with the thalmor": [
    "Quid est affinitas tua cum Visigothis"
  ],
  "what's special about this sword": [
    "Quid speciale est cum hoc gladio"
  ],
  "kyne's peace": [
    "Pax Kynis"
  ],
  "throw voice": [
    "Ventriloquistum"
  ],
  "charmed necklace": [
    "Monile Incantaminis"
  ],
  "seasoned hunter": [
    "Venator Vetus"
  ],
  "how did a dark elf come to be housecarl": [
    "Quomodo atralfus Domicomes factus est"
  ],
  "i wasn't asking": [
    "Non rogabam"
  ],
  "dwarven warhammer of depleting": [
    "Dvemeris Bellimalleus Deplendi"
  ],
  "whiterun ysolda's house faction": [
    "Whiterun Ysolda's House Faction"
  ],
  "i'll come along with you": [
    "Veniam tecum"
  ],
  "battle cry": [
    "Clamor Proelii"
  ],
  "dragonplate gauntlets of extreme wielding": [
    "Draconilaminatae Manicae Extremis Tenendi"
  ],
  "brown bear": [
    "Ursus Brunius"
  ],
  "goat faction": [
    "Goat Faction"
  ],
  "beast tongue": [
    "Lingua Bestiarum"
  ],
  "fire breath (for dragons only)": [
    "Fire Breath (for DRAGONS only)"
  ],
  "elven boots of shock suppression": [
    "Caligae Alfae Suppresionis Electricitatis"
  ],
  "teleportation": [
    "Teleportation"
  ],
  "shadowgreen cavern": [
    "Viridumbra Caverna"
  ],
  "that's not why i'm here": [
    "Non est ratio cur hic sim"
  ],
  "find a way out of labrynthian": [
    "Inveni Exitum Labyrinthi"
  ],
  "arrald frozen-heart": [
    "Arrald Cor-Congelatum"
  ],
  "dragon storm call": [
    "Dragon Storm Call"
  ],
  "ro": [
    "Ro"
  ],
  "it's fine, i'm allowed to be here": [
    "Bene, adprobatus sum hic esse"
  ],
  "iron dagger of frost": [
    "Pugio Ferri Gelidus"
  ],
  "yol": [
    "Yol"
  ],
  "riften guard jailor": [
    "Custos Carceris Hiati"
  ],
  "dragonfire01": [
    "DragonFire01"
  ],
  "dragonfire03": [
    "DragonFire03"
  ],
  "elven sword of immobilizing": [
    "Alfus Gladius Immobilitatis"
  ],
  "words of clan mother ahnissi": [
    "Verba Matris Gentis Ahnissi"
  ],
  "lo": [
    "Lo"
  ],
  "sah": [
    "Sah"
  ],
  "wuld": [
    "Wuld"
  ],
  "n4": [
    "N4"
  ],
  "i think all folk should be welcome here": [
    "Credo omnes accepandos esse"
  ],
  "kest": [
    "Kest"
  ],
  "dragonplate boots of frost abatement": [
    "Draconilaminata Caligae Gelidus Resistendi"
  ],
  "guldun rock cave": [
    "Spelunca Saxi Guldunis"
  ],
  "z3": [
    "Z3"
  ],
  "gron": [
    "Gron"
  ],
  "f1s": [
    "F1s"
  ],
  "do you know how crazy this sounds": [
    "Scisne quam insanum hoc videri"
  ],
  "ru": [
    "Ru"
  ],
  "daedric greatsword of winter": [
    "Spatha Daedrica Hiemis"
  ],
  "glass war axe of binding": [
    "Bellisecuris Vitreus Deligandi"
  ],
  "rondach": [
    "Rondach"
  ],
  "defend the barricade blocking the jarl's house": [
    "Defende Concaedem Pro Villa Comitis"
  ],
  "elven sword": [
    "Alfus Gladius"
  ],
  "tg09 nightingale enemy faction": [
    "TG09 Nightingale Enemy Faction"
  ],
  "su": [
    "Su"
  ],
  "silver ore vein": [
    "Venam Mineralis Argenti"
  ],
  "lok": [
    "Lok"
  ],
  "v4": [
    "V4"
  ],
  "torsten's skull key": [
    "Clavis Calvariae Torstenis"
  ],
  "j8r": [
    "J8r"
  ],
  "eola": [
    "Eola"
  ],
  "alduin's wall": [
    "Paries Alduini"
  ],
  "enterbowzoombreath": [
    "EnterBowZoomBreath"
  ],
  "z4": [
    "Z4"
  ],
  "two-handed weapons do more damage than one-handed weapons, but don't block as effectively": [
    "Arma duabus-manibus plus vulneris faciunt sed non tam bene interponunt"
  ],
  "frul": [
    "Frul"
  ],
  "od": [
    "Od"
  ],
  "where's this dragon": [
    "Ubi est hic draco"
  ],
  "t3d": [
    "T3d"
  ],
  "eyekhajiitmale": [
    "EyeKhajiitMale"
  ],
  "fortified healing": [
    "Fortified Healing"
  ],
  "klo": [
    "Klo"
  ],
  "potion of plentiful stamina": [
    "Potio Multae Staminae"
  ],
  "fortify lockpicking": [
    "Adde ad Artem Clavicarii"
  ],
  "who's the court wizard": [
    "Quis est Thaumatorgos aulae"
  ],
  "hun": [
    "Hun"
  ],
  "k1l": [
    "K1l"
  ],
  "(remain silent)": [
    "(Sile)"
  ],
  "z8r": [
    "Z8r"
  ],
  "fo": [
    "Fo"
  ],
  "audiotemplateskeever": [
    "AudioTemplateSkeever"
  ],
  "d3n": [
    "D3n"
  ],
  "zun": [
    "Zun"
  ],
  "the adabal-a": [
    "Adabal-a"
  ],
  "adabal-a": [
    "Adabal-a"
  ],
  "dialoguegenericscene04": [
    "DialogueGenericScene04"
  ],
  "v3k": [
    "V3k"
  ],
  "m7": [
    "M7"
  ],
  "t4": [
    "T4"
  ],
  "l1s": [
    "L1s"
  ],
  "kr3": [
    "Kr3"
  ],
  "lun": [
    "Lun"
  ],
  "aus": [
    "Aus"
  ],
  "cage gate": [
    "Porta Carceris"
  ],
  "steel warhammer": [
    "Bellimalleus Adamanteus"
  ],
  "temple of xrib": [
    "Templum Xribis"
  ],
  "strun": [
    "Strun"
  ],
  "frostfruit inn": [
    "Diversorium Gelidifructus"
  ],
  "orcish gauntlets of eminent smithing": [
    "Orci Manicae Maximi Faciendi"
  ],
  "qo": [
    "Qo"
  ],
  "what's the jagged crown": [
    "Quid est Corona Fragosa"
  ],
  "k1n": [
    "K1n"
  ],
  "i have the statue": [
    "Statuam habeo"
  ],
  "drem": [
    "Drem"
  ],
  "extra pockets": [
    "Fundae Extraneae"
  ],
  "m9": [
    "M9"
  ],
  "gut": [
    "Gut"
  ],
  "glass war axe of harvesting": [
    "Bellisecuris Vitreus Saturni"
  ],
  "3z": [
    "3z"
  ],
  "slen": [
    "Slen"
  ],
  "nus": [
    "Nus"
  ],
  "dwarvensteamcenturion": [
    "DvarvenSteamCenturio"
  ],
  "ebony sword of stunning": [
    "Gladius Ebeni Intermissionis"
  ],
  "dragonplate helmet": [
    "Galea Dracolaminata"
  ],
  "empty incense vessel": [
    "Atrophorion Vacuum"
  ],
  "nana ildene": [
    "Nana Ildene"
  ],
  "dragon priest staff": [
    "Baculum Sacerdotis Draconis"
  ],
  "dart trap weapon": [
    "Dart Trap Veapon"
  ],
  "snapleg cave": [
    "Crurafracta Spelunca"
  ],
  "voice of the emperor": [
    "Vox Imperatoris"
  ],
  "orcish bow of freezing": [
    "Orci Arcus Gelidus"
  ],
  "beautiful barbarian": [
    "Barbara Pulcherrima"
  ],
  "hairmaledarkelf03": [
    "HairMaleDarkElf03"
  ],
  "animal extermination": [
    "Exterminatio Animalium"
  ],
  "nd": [
    "us"
  ],
  "dragonsreach was built to hold a dragon": [
    "Draconicarcerus factus est draconi capiendi causa"
  ],
  "how did you accomplish such a feat": [
    "Quomodo id fecisti"
  ],
  "unable to remove the worn item": [
    "Non potes removere"
  ],
  "does ulfric have much need for magic": [
    "Necesse est Vercingetorixus multam macicam habere"
  ],
  "sure, i'll do it": [
    "Sic, id faciam"
  ],
  "sven wanted me to deliver this letter to camilla, saying it was from you": [
    "Sven me voluit hanc epistulam Camillae adferre, et quoque dicere te eam scripsisse"
  ],
  "whiterun guard": [
    "Custos Albacursus"
  ],
  "my name is <alias=player>": [
    "Nomen mihi est <Alias=Player>"
  ],
  "pleased to meet you": [
    "Gaudeo te cognoscere"
  ],
  "imperial officer": [
    "Centurio Imperialis"
  ],
  "brynjolf pointed me your way": [
    "Brynjolf me ad te direxit"
  ],
  "ebony boots of muffling": [
    "Caligae Ebeni Silentii"
  ],
  "chin length": [
    "Longitudo Menti"
  ],
  "elven war axe of devouring": [
    "Alfus Bellisecuris Consumendi"
  ],
  "unknown effect": [
    "Effectum Ignotum"
  ],
  "shrouded hood": [
    "Capucium Obscurum"
  ],
  "i'm ready for some extra work": [
    "Paratus sum laboris superflui"
  ],
  "so how do i get my cut of the spoils": [
    "Quomodo possum accipere pecuniam mean"
  ],
  "campaign for the reach": [
    "Pugna pro Prehenso"
  ],
  "how's the war going": [
    "Quid fit cum Proelio"
  ],
  "campaign for hjaalmarch": [
    "Pugna pro Halmarci"
  ],
  "did a woman named margret stay here": [
    "Mansitne hic femina nomine Margarita"
  ],
  "sounds like you really admire ulfric": [
    "Mihi videtur te maxime Vercingetorigem admirari"
  ],
  "i don't need anything": [
    "Nihil careo"
  ],
  "potion of lasting potency": [
    "Potio Potensiae Sempitarnae"
  ],
  "stalleo's bodyguard": [
    "Stipator Stalleonis"
  ],
  "campaign for whiterun": [
    "Pugna pro Albacursu"
  ],
  "ebony battleaxe of malediction": [
    "Bellisecuris Ebeni Maledictionis"
  ],
  "dawnstar sanctuary": [
    "Fanum Luciferis"
  ],
  "campaign for falkreath": [
    "Pugna pro Falkretia"
  ],
  "fire and darkness": [
    "Ignis et Obscuritas"
  ],
  "elven war axe of the blaze": [
    "Alfus Bellisecuris Congflagrantis"
  ],
  "tell me about forelhost": [
    "Dic mihi de Forelhost"
  ],
  "fine hat": [
    "Petasus Nobilis"
  ],
  "whispering door key": [
    "Clavis ad Portam Sussurantem"
  ],
  "fortify stamina regen": [
    "Adde ad Recuperationem Staminae"
  ],
  "storm call fire effect self 3": [
    "Storm Call Fire Effect Self 3"
  ],
  "campaign for the pale": [
    "Punga pro Pallido"
  ],
  "campaign for winterhold": [
    "Pugna pro Hiemiterra"
  ],
  "titus mede ii lies dead": [
    "Titus Medus II mortuus iacet"
  ],
  "campaign for rift": [
    "Pugna pro Hiato"
  ],
  "what did they find": [
    "Quid invenÃªrunt"
  ],
  "eldergleam root": [
    "Radix Eldergleam"
  ],
  "daedric bow of freezing": [
    "Arcus Daedricus Gelidus"
  ],
  "hallowed daedric warhammer": [
    "Bellimalleus Santificatus Daedricus"
  ],
  "take over the fort": [
    "Fer Eos ad Castra"
  ],
  "special paralyze": [
    "Special Paralyze"
  ],
  "the bards college": [
    "The Bards College",
    "Collegium Bardorum"
  ],
  "morthal moorside gorm alva": [
    "Morthal Moorside Gorm Alva"
  ],
  "(invisible continue / invisible walk away)": [
    "(Invisible Continue / Invisible Walk Away)"
  ],
  "i'm going to be your test subject, then": [
    "Sic...subiectum experimentorum tuorum ero"
  ],
  "sabotage": [
    "Perfidia"
  ],
  "do you want to find your daughter's killer or not": [
    "Visne invenire necatorem filiae tuae vel non"
  ],
  "a friend indeed": [
    "Amicus Certus"
  ],
  "friend indeed": [
    "Amicus Certus"
  ],
  "weapon smithing": [
    "Arma Facere"
  ],
  "dialoguedushnikhyalblacksmithingscene03": [
    "DialogueDushnikhYalBlackFerrariiScene03"
  ],
  "why would anyone think you're a spy": [
    "Cur crederet aliqus te speculatorem esse"
  ],
  "sleeping tree camp": [
    "Castra Arboris Dormientis"
  ],
  "dwarven metal ingot": [
    "Later Dvemerisis Metalli"
  ],
  "deepwood redoubt": [
    "Ligniprofundi Redubitum"
  ],
  "forcegreet": [
    "ForceGreet"
  ],
  "excuse me": [
    "Ignosce Mihi"
  ],
  "it doesn't matter": [
    "Nil refert",
    "Non refert"
  ],
  "you're safe": [
    "Libertus es"
  ],
  "windward ruins": [
    "Windward Ruins"
  ],
  "sild's staff": [
    "Baculum Sildis"
  ],
  "dwarven dagger of draining": [
    "Dvemeris Pugio Hauriendi"
  ],
  "a few words with you": [
    "Pauca Verba Tecum"
  ],
  "few words with you": [
    "Pauca Verba Tecum"
  ],
  "mercer wants me to discover goldenglow's buyer": [
    "Mercer me vult emptorem Aurilucis invenire"
  ],
  "you cannot favorite that item": [
    "Illam rem electam facere non potes"
  ],
  "how did the daedra drive malyn insane": [
    "Quomodo daedrae Malyn ad insanitatem duxÃªrunt"
  ],
  "dragonscale boots of the firewalker": [
    "Caligae Draconisquamatae Ignambulatoris"
  ],
  "silver emerald necklace": [
    "Monile Argentum Smaragdi"
  ],
  "after all the trust i've given you": [
    "Post tibi tantum creditum dedi"
  ],
  "so where do you work": [
    "Ubi laboras"
  ],
  "bone meal": [
    "Pulvis Ossis"
  ],
  "key to solitude fletcher": [
    "Clavis ad Sagittarium Solitudinis"
  ],
  "ansilvund excavation": [
    "Excavatio Ansilvundis"
  ],
  "tell them to meet me there": [
    "Dic eos mecum illuc convenire"
  ],
  "i guess we're nightingales now": [
    "Credo nos Philomelas nunc esse"
  ],
  "hairlinemaleorc24": [
    "HairLineMaleOrc24"
  ],
  "pale guard's armor": [
    "Lorica Custodis Pallidi"
  ],
  "malachite ore vein": [
    "Venam Metallis Malachitae"
  ],
  "thieves and temples": [
    "Fures templaque"
  ],
  "it just doesn't add up": [
    "Rationem non habet"
  ],
  "it's the only way to find alduin before it's too late": [
    "Est modus solus in quo Alduinum invenire possimus ad tempus"
  ],
  "dwarven sword of reaping": [
    "Gladius Dvemeris Saturni"
  ],
  "come on, you'd better get out of here. (free her)": [
    "Veni huc, tibi exeundum est. (Libera Eam)"
  ],
  "scaled boots of sneaking": [
    "Caligae Squamatae Repsendi"
  ],
  "shalidor's insights": [
    "Acuitas Shalidoris"
  ],
  "rahgot's reply": [
    "Responsum Rahgotis"
  ],
  "iron sword": [
    "Gladius Ferri"
  ],
  "spell tome: pacify": [
    "Liber Magicus: Pacifica"
  ],
  "return to temba wide-arms": [
    "Reveni ad Temba Bracchia-Lata"
  ],
  "frost mage": [
    "Glacimagus"
  ],
  "tell me about this cookbook": [
    "Dic mihi de \"De Re Coquinaria\""
  ],
  "muril": [
    "Muril"
  ],
  "glass bow": [
    "Arcus Vitreus"
  ],
  "i have some sleeping tree sap to sell": [
    "Habeo Olibanum Arboris Dormientis vendendum"
  ],
  "leather strips": [
    "Lora"
  ],
  "(temp) fx placeholder": [
    "(temp) FX Placeholder"
  ],
  "elven bracers of eminent smithing": [
    "Chirothecae Alfae Maximi Faciendi"
  ],
  "[failed]": [
    "[Frustratus]"
  ],
  "nightingale armor": [
    "Philomelarum Lorica"
  ],
  "dwarven helmet of major archery": [
    "Dvemeris Galea Maioris Sagittationis"
  ],
  "otar": [
    "Otar"
  ],
  "there would be treasure to share... (persuade)": [
    "Gaza communicanda erit... (Persuade)"
  ],
  "(temp) gameplay placeholder": [
    "(temp) Gameplay Placeholder"
  ],
  "charwich-koniinge letters, v3": [
    "Epistulae Charvich-Koniinge v3"
  ],
  "power attacks use stamina": [
    "Ictus potestatis Stamina usurpant"
  ],
  "if the stamina bar is flashing, any attempted power attack becomes a regular attack instead": [
    "Si stamina parva erit, ictus potentes in ictus normales mutabunt"
  ],
  "message from general tullius": [
    "Nuntium ab Imperatore Tullio"
  ],
  "mannequin": [
    "Pupa"
  ],
  "kids playing in well": [
    "Kids Playing in Well"
  ],
  "you really think the dark elves are imperial spies": [
    "Re vera putas Atralfos speculatores Imperiales esse"
  ],
  "i'll never join you, vile demon": [
    "Cum te numquam adiungam, Daimonion Terribile"
  ],
  "novice conjurer": [
    "Novitius Vocator"
  ],
  "you sent me a letter, didn't you": [
    "Nonne mihi epistulam misisti"
  ],
  "thanking me for killing your rival": [
    "Me gratalans pro inimicum tuum necando"
  ],
  "unrelenting force weak": [
    "Unrelenting Force Weak"
  ],
  "potent recovery poison": [
    "Venenum Potens Recuperandi"
  ],
  "hag's end key": [
    "Clavis ad Finem Strigae"
  ],
  "you let your aunt go in there by herself": [
    "Sivistine materteram tuam inire solam"
  ],
  "olava the feebles house": [
    "Olava the Feebles House"
  ],
  "serve time": [
    "Tempus Age"
  ],
  "scroll of mayhem": [
    "Codex Insanitatis"
  ],
  "you decided to become a mercenary": [
    "Elegisti mercenarium esse"
  ],
  "the school of illusion focuses on the manipulation of an enemy's mind, and is used to cast spells like fear, charm and invisibility": [
    "Schola Inlusionis agitur de moderando mentem inimici et habet magicam quam Timor, Blanditia, Invisabilitas"
  ],
  "school of illusion focuses on the manipulation of an enemy's mind, and is used to cast spells like fear, charm and invisibility": [
    "Schola Inlusionis agitur de moderando mentem inimici et habet magicam quam Timor, Blanditia, Invisabilitas"
  ],
  "statue of dibella": [
    "Statua Dibellae"
  ],
  "you'd better do what i want, or else. (brawl)": [
    "Tibi faciendum est id quod volo, vel... (Pugna Manibus)"
  ],
  "reverent steel dagger": [
    "Pugio Reverens Adamanteus"
  ],
  "imperial letter": [
    "Epistula Imperialis"
  ],
  "amulet of mara (possessed)": [
    "Monile Marae (Possessa)"
  ],
  "snilf": [
    "Snilf"
  ],
  "baral sendu": [
    "Baral Sendu"
  ],
  "daedric shield of peerless blocking": [
    "Scutum Daedricum Optimi Interponendi"
  ],
  "valdr's lucky dagger": [
    "Felix Pugio Valdris"
  ],
  "you do realize this is treason": [
    "Scisne hanc proditionem esse"
  ],
  "sabre cat tooth": [
    "Dens Felis Mucronis"
  ],
  "hairmalenordchild01": [
    "HairMaleNordChild01"
  ],
  "tell me about the nightingales": [
    "Dic mihi de Philomelis"
  ],
  "warehouse master package": [
    "Horreum Master Package"
  ],
  "ascension": [
    "Ascensio"
  ],
  "elven boots of waning frost": [
    "Caligae Alfae Insenescentis Congelationis"
  ],
  "dragonscale gauntlets of peerless smithing": [
    "Draconisquamatae Manicae Maximi Faciendi"
  ],
  "cost: %d caps": [
    "Pretium: %d caps"
  ],
  "daedric bow of animus": [
    "Arcus Daedricus Animi"
  ],
  "fortify pickpocket": [
    "Adde ad Artem Furandi"
  ],
  "what are you in for": [
    "Cur hic es"
  ],
  "you're not coming": [
    "Non venis"
  ],
  "%d%s repair skill needed": [
    "%d%s REPAIR SKILL NEEDED"
  ],
  "locked sarcophagus": [
    "Sarcophagus Clausus"
  ],
  "repel undead": [
    "Repelle Amortuos"
  ],
  "this statue looks valuable": [
    "Statua pretiosa videtur"
  ],
  "why did she need to die": [
    "Cur morienda erat"
  ],
  "drip blood onto seal": [
    "Pone sanguinem in signum"
  ],
  "how do we know the crown is in korvanjund": [
    "Quomodo scimus Coronam in Korvanjundum esse"
  ],
  "iron warhammer of cowardice": [
    "Bellimalleus Ferratus Timoris"
  ],
  "ok": [
    "Bene"
  ],
  "who are the thalmor": [
    "Qui sunt Visigothi"
  ],
  "accept sign": [
    "Accipe Signum"
  ],
  "companions induction": [
    "Inductio Contubernalium"
  ],
  "is there anything more i can learn about alteration magic": [
    "Estne quid plus de magica Alterationis discendum"
  ],
  "the jarl": [
    "Comes"
  ],
  "jarl": [
    "Comes"
  ],
  "steel helmet of major magicka": [
    "Adamantea Galea Maioris Magicae"
  ],
  "dialoguedawnstarinnfridakaritascene02": [
    "DialogueDawnstarInnFridaKaritaScene02"
  ],
  "steel plate helmet of major archery": [
    "Laminata Galea Maioris Sagittationis"
  ],
  "nothing in here is stolen? nothing at all": [
    "Nihil hic furatum est? Nihil"
  ],
  "i have corundum ore to sell": [
    "Corundum vendendum habeo"
  ],
  "olava the feeble": [
    "Olava Debilis"
  ],
  "humanbeardlong13_1bit": [
    "HumanBeardLong13_1bit"
  ],
  "you've planned this from the beginning": [
    "Hoc in animo habuisti ab initio"
  ],
  "barleydark farm faction": [
    "Barleydark Farm Faction"
  ],
  "reverent imperial bow": [
    "Reverens Arcus Imperialis"
  ],
  "pirate ships": [
    "Pirate Ships"
  ],
  "imperial bracers of major smithing": [
    "Chirothecae Romanae Maioris Faciendi"
  ],
  "eyes": [
    "Oculi"
  ],
  "tolfdir": [
    "Tolfdir"
  ],
  "are you sure you want to destroy this item and learn its effects": [
    "Re vera vis hanc rem destruere et magicam eius discere"
  ],
  "steel warhammer of arcing": [
    "Bellimalleus Adamanteus Electricitatis"
  ],
  "st": [
    "us"
  ],
  "dunrannveigqstshared01": [
    "dunRannveigQSTSHARED01"
  ],
  "you're going to assault northwatch keep": [
    "Turrem Septentriovisum oppugnabis"
  ],
  "the cursed tribe": [
    "Tribus Maledictus"
  ],
  "cursed tribe": [
    "Tribus Maledictus"
  ],
  "i think you're exaggerating": [
    "Credo te res adaugere"
  ],
  "daedric bow": [
    "Arcus Daedrica"
  ],
  "orcish mace of cowardice": [
    "Orci Maca Timoris"
  ],
  "disaffected <basename>": [
    "Disaffectus <BaseName>"
  ],
  "malkoran": [
    "Malkoran"
  ],
  "jarl <basename>": [
    "Comes <BaseName>"
  ],
  "meridia's beacon": [
    "Signum Cereris"
  ],
  "i'm listening": [
    "Audio",
    "Ausculto"
  ],
  "attack the city": [
    "Oppugna Urbem"
  ],
  "daedric dagger of harvesting": [
    "Pugio Daedricus Saturni"
  ],
  "feed cow an apple": [
    "Da malum vaccae"
  ],
  "were you really in the army": [
    "Re vera in exercitu fuisti"
  ],
  "feed cow": [
    "Da cibum vaccae"
  ],
  "what did malyn do": [
    "Quid fecit Malyn"
  ],
  "the wolf queen, v8": [
    "Regina Luporum, v8"
  ],
  "wolf queen, v8": [
    "Regina Luporum, v8"
  ],
  "tower of mzark": [
    "Tower of Mzark"
  ],
  "jorn": [
    "Jorn"
  ],
  "chin forward": [
    "Mentum Prorsus"
  ],
  "ancient nord war axe of cold": [
    "Antiquus Bellisecuris Germanicus Frigidis"
  ],
  "thieves guild fence chest": [
    "Arca Vendoris Sodalitatis Furum"
  ],
  "hairlinemalenord1": [
    "HairLineMaleNord1"
  ],
  "leigelf's house": [
    "Domus Leigelfis"
  ],
  "elven sword of freezing": [
    "Alfus Gladius Gelidus"
  ],
  "you're the jarl": [
    "Comes es"
  ],
  "i'm looking for work": [
    "Munera quaero",
    "Laborem quaero"
  ],
  "what can i expect in return for my help": [
    "Quid pro labore expectare possum"
  ],
  "master-wizard": [
    "Master-Wizard"
  ],
  "scroll of frost cloak": [
    "Codex Amiculi Gelidus"
  ],
  "amaund motierre": [
    "Armandus Motierre"
  ],
  "lay off the old man": [
    "Ignora senem",
    "Desine nunc, senex"
  ],
  "that's exactly what delphine warned me you'd say": [
    "Delphina me monuit id diceres"
  ],
  "malborn": [
    "Malnatus"
  ],
  "experimenter": [
    "Experimentor"
  ],
  "civil war to do": [
    "Bellum Civile Facienda"
  ],
  "<alias=questgiver>'s <basename>": [
    "<BaseName> <Alias=QuestGiver>"
  ],
  "elven helmet of major destruction": [
    "Alfa Galea Maioris Destructionis"
  ],
  "child's coffin": [
    "Sarcophagus Pueri"
  ],
  "hairshavedmaleorc25": [
    "HairShavedMaleOrc25"
  ],
  "place balwen's ring": [
    "Pone anulum Balwenis"
  ],
  "\"but our brethren, the dwemer, scorned the daedra, and mocked our foolish rituals, and preferred instead their gods of reason and logic.\" -- vivec": [
    "\"Sed fratres nostri, Dwemeres, Daedras ignorabant, ed religionem nostram deludÃªrunt, et deos suos Rationis Logicaeque malebant.\" -- Vivec"
  ],
  "silver amethyst ring": [
    "Anulus Argenteus Amythystus"
  ],
  "place pithiken's ring": [
    "Pone anulum Pithikenis"
  ],
  "daedric greatsword of terror": [
    "Spatha Daedrica Terroris"
  ],
  "place katarina's ring": [
    "Pone anulum Katarinae"
  ],
  "place treoy's ring": [
    "Pone anulum Treois"
  ],
  "blood-sister": [
    "Blood-Sister"
  ],
  "daedric relic": [
    "Reliquum Daedricum"
  ],
  "scrolls": [
    "CODICES"
  ],
  "butcher journal #1": [
    "Diurnarius Carnificis I"
  ],
  "dwarven battleaxe of cowardice": [
    "Dvemeris Bellisecuris Timoris"
  ],
  "abort": [
    "Aborta"
  ],
  "grand staff of turning": [
    "Rudis Maioris Vertendi"
  ],
  "spriggan abilities": [
    "Spriggan abilities"
  ],
  "hircine": [
    "Hecate"
  ],
  "disarm": [
    "Disarma",
    "spoliō, spoliāre, spoliāvī, spoliātum"
  ],
  "belyn hlaalu's house key": [
    "Clavis ad Domum Belynis Hlaalus"
  ],
  "ancient's ascent": [
    "Antiquorum Ascensus"
  ],
  "dwarven bracers of eminent alchemy": [
    "Dvemeres Chirothecae Alchemiae Eminentis"
  ],
  "set trap": [
    "Repone insidias"
  ],
  "<basename> the slayer": [
    "<BaseName> necator"
  ],
  "solitude lighthouse": [
    "Solitudinis Lighthouse"
  ],
  "warlord gathrik": [
    "Dominibelli Gathrik"
  ],
  "pack animals": [
    "Agminalia"
  ],
  "kill her": [
    "Eam neca"
  ],
  "gloombound mine": [
    "Metallum Tenebrabundum"
  ],
  "dialoguedragonbridge": [
    "DialogueDragonBridge"
  ],
  "door barred": [
    "Porta Serata"
  ],
  "tripwire": [
    "Fila Cadendi"
  ],
  "rigel strong-arm": [
    "Rigel Forte-Bracchium"
  ],
  "the imperials gained control of <alias=hold>": [
    "Romani <Alias=Hold> vincÃªrunt"
  ],
  "imperials gained control of <alias=hold>": [
    "Romani <Alias=Hold> vincÃªrunt"
  ],
  "ingrid": [
    "Ingrid"
  ],
  "sap spigot": [
    "Fons Olibani"
  ],
  "disarming bash": [
    "Arma Remove Scuto"
  ],
  "he's in some place called volunruud": [
    "In loco nomine Volunruud adest"
  ],
  "lunar steel sword": [
    "Anancitis Lunaris Gladius"
  ],
  "who forges your arms and armor": [
    "Quis facit arma loricasque tui"
  ],
  "emblem vii": [
    "Emblema VII"
  ],
  "tower of dawn": [
    "Turris Aurorae"
  ],
  "maybe another time": [
    "Fortasse alio tempore"
  ],
  "the stormcloaks failed to gain control of <alias=hold>": [
    "Sturmmanteli non vincÃªrunt <Alias=Hold>"
  ],
  "stormcloaks failed to gain control of <alias=hold>": [
    "Sturmmanteli non vincÃªrunt <Alias=Hold>"
  ],
  "are there many conjurers outside the college": [
    "Suntne multi Vocatores extra Collegium"
  ],
  "frost rune": [
    "Runus Glaciei"
  ],
  "audiotemplatespiderfrostbite": [
    "AudioTemplateSpiderFrostbite"
  ],
  "the stormcloaks lost control of <alias=hold>": [
    "Sturmmanteli <Alias=Hold> perdidÃªrunt"
  ],
  "stormcloaks lost control of <alias=hold>": [
    "Sturmmanteli <Alias=Hold> perdidÃªrunt"
  ],
  "give me the hilt": [
    "Da mihi capulum"
  ],
  "the stormcloaks gained control of <alias=hold>": [
    "Sturmmanteli vincÃªrunt <Alias=Hold>"
  ],
  "stormcloaks gained control of <alias=hold>": [
    "Sturmmanteli vincÃªrunt <Alias=Hold>"
  ],
  "uaile's sleeping potion": [
    "Potio Dormientis Uailis"
  ],
  "vigilants patrol": [
    "Vigilants Patrol"
  ],
  "the stormcloaks kept control of <alias=hold>": [
    "Sturmmanteli iam <Alias=Hold> tenent"
  ],
  "stormcloaks kept control of <alias=hold>": [
    "Sturmmanteli iam <Alias=Hold> tenent"
  ],
  "lunar iron sword": [
    "Lunaris Gladius Ferri"
  ],
  "done": [
    "Factum",
    "fīō, fierī, factus sum",
    "fiō, fierī, factum esse (fiat, fiunt)"
  ],
  "dragonscale shield of shock abatement": [
    "Scutum Draconisquamatum Contra Vim Electricam"
  ],
  "glass war axe of dread": [
    "Bellisecuris Vitreus Timoris"
  ],
  "so, do i call you high king now": [
    "Ergo...te Regem Altum nunc vocam"
  ],
  "face target": [
    "Face Target"
  ],
  "perhaps you should have joined the dark brotherhood": [
    "Fortasse tibi Fraternitatem Obscurum adiungendum est"
  ],
  "fortify healing rate": [
    "Adde ad Recuperationem Salutis"
  ],
  "henrik": [
    "Henricus"
  ],
  "agent": [
    "Agent"
  ],
  "blacksmith": [
    "Blacksmith"
  ],
  "elven sword of thunderbolts": [
    "Alfus Gladius Fulminum"
  ],
  "angle the catapult": [
    "Angle Catapult"
  ],
  "erik the slayer": [
    "Erik Necator"
  ],
  "tell me about grelod the kind": [
    "Dic mihi de Greloda Benigna"
  ],
  "log coordinates": [
    "Log Coordinates"
  ],
  "dwarven bow of debilitation": [
    "Dvemeris Arcus Debilitationis"
  ],
  "elven bracers of major smithing": [
    "Chirothecae Alfae Maioris Faciendi"
  ],
  "iron armor of the major knight": [
    "Ferrata Lorica Maioris Equitis"
  ],
  "east empire company ship": [
    "Negotium Euri Imperii Navis"
  ],
  "i've brought you <alias=questitem>": [
    "Tibi adtuli <Alias=QuestItem>"
  ],
  "report to <alias=fieldco>": [
    "Refer te ad <Alias=FieldCO>"
  ],
  "orcish greatsword of torpor": [
    "Orci Spatha Torporis"
  ],
  "meridia": [
    "Ceres"
  ],
  "riften fishery": [
    "Piscatorium Hiati"
  ],
  "hilt of mehrunes' razor": [
    "Capulum Novaculi Mehruni"
  ],
  "hand over the skooma": [
    "Da mihi skoomam"
  ],
  "daedric helmet of waterbreathing": [
    "Galea Daedrica Respirandi Sub Aqua"
  ],
  "does the war mean good business for you": [
    "Datne bellum multum negotium tibi"
  ],
  "ebony helmet of eminent conjuring": [
    "Ebeni Galea Coniurationis Vocantis"
  ],
  "tell me about the burglary jobs": [
    "Dic mihi de munera furandi"
  ],
  "are you aware that letrush stole frost": [
    "Scisne Letrush congelationem furatus est"
  ],
  "weapon rack": [
    "Armifer"
  ],
  "petra": [
    "Petra"
  ],
  "currently being absorbed": [
    "Currently Being Absorbed"
  ],
  "find evidence of ogmund's talos worship": [
    "Inveni Evidentiam Religionis Talos Ogmundi"
  ],
  "glass bow of blizzards": [
    "Arcus Vitreus Hiemis"
  ],
  "potato plant": [
    "Malum Terrae"
  ],
  "lost echo urn": [
    "Urna Echoos Perditae"
  ],
  "sure, i'll get them for you": [
    "Bene eos tibi adferam"
  ],
  "thonnir's house": [
    "Domus Thonniris"
  ],
  "wolfskull cave": [
    "Spelunca Lupicalvariae"
  ],
  "daedric boots of warmth": [
    "Caligae Daedricae Caloris"
  ],
  "mzinchaleft gatehouse": [
    "Mzinchaleftis Portcullis"
  ],
  "summon hero from sovngarde": [
    "Voca Heroon e Valhalla"
  ],
  "steel helmet of restoration": [
    "Adamantea Galea Recreandi"
  ],
  "northwatch guard": [
    "Custos Septentriovisus"
  ],
  "jeweled flagon": [
    "Poculum Gemmatum"
  ],
  "humanbeard12": [
    "HumanBeard12"
  ],
  "official warning": [
    "Admonitio Officialis"
  ],
  "elven bracers of major lockpicking": [
    "Chirothecae Alfae Maioris Clavicarii"
  ],
  "ralof (stormcloaks)": [
    "Ralof (Sturmmanteli)"
  ],
  "inner ring": [
    "Circulus Interior"
  ],
  "what's to keep me from ending up like malyn": [
    "Quid me teget ne quam Malyn fiam"
  ],
  "and nilsine shatter-shield": [
    "Et Nilsine Shatter-Shield"
  ],
  "why must she die": [
    "Cur ei moriendum est"
  ],
  "hadvar (imperials)": [
    "Hadvar (Imperiales)"
  ],
  "glass gauntlets": [
    "Vitreae Manicae"
  ],
  "orcish war axe of shocks": [
    "Orci Bellisecuris Electricitatis"
  ],
  "there are only four of you": [
    "Modo quattuor vestri sunt"
  ],
  "defend the city": [
    "Defende Urbem"
  ],
  "nepos the nose": [
    "Nepos Nasus"
  ],
  "silver hand leader": [
    "Dux Manus Argenteae"
  ],
  "scroll of firebolt": [
    "Codex Sagitignis"
  ],
  "aela's shield": [
    "Scutum Aelae"
  ],
  "the warmth of mara": [
    "Apricitas Marae"
  ],
  "warmth of mara": [
    "Apricitas Marae"
  ],
  "the locked room": [
    "Conclave Clausum"
  ],
  "locked room": [
    "Conclave Clausum"
  ],
  "fisherman faction": [
    "Fisherman Faction"
  ],
  "<alias=witch>'s <basename>": [
    "<BaseName> <Alias=Witch>"
  ],
  "dialoguemorkhazgurlarakolurscene01": [
    "DialogueMorKhazgurLarakOlurScene01"
  ],
  "whiterun uthgerd the unbroken's house faction": [
    "Whiterun Uthgerd the Unbrokenis House Faction"
  ],
  "hairfemaleredguard02": [
    "HairFemaleRedguard02"
  ],
  "steel war axe of sparks": [
    "Bellisecuris Adamanteus Scintillarum"
  ],
  "how can you be so sure i'm a vampire": [
    "Quomodo pro certo me vampirum esse scire potes"
  ],
  "yngol's shade": [
    "Umbra Yngolis"
  ],
  "ghost visuals nightingale": [
    "Ghost Visuals Philomelarum"
  ],
  "j'zargo's flame cloak scroll": [
    "Codex Amiculi Flammarum J'zargonis"
  ],
  "salvianus": [
    "Salvianus"
  ],
  "the ratway warrens": [
    "Viae Murum Leporarium"
  ],
  "ratway warrens": [
    "Viae Murum Leporarium"
  ],
  "ebony battleaxe of immobilizing": [
    "Bellisecuris Ebeni Immobilitatis"
  ],
  "frost troll abilities": [
    "Frost Troll abilities"
  ],
  "elven war axe of expelling": [
    "Alfus Bellisecuris Expellendi"
  ],
  "goldar": [
    "Goldar"
  ],
  "<alias=mark>'s <basename>": [
    "<BaseName> <Alias=Mark>"
  ],
  "no, i've never heard of anything like that": [
    "Minime, rem huius modi numquam audivi"
  ],
  "steel sword of souls": [
    "Gladius Adamanteus Animorum"
  ],
  "bothela's potion": [
    "Potio Bothelae"
  ],
  "steel plate bracers of major wielding": [
    "Chirothecae Laminatae Maioris Tenendi"
  ],
  "hairfemalenord08": [
    "HairFemaleNord08"
  ],
  "ornate relief": [
    "Anaglyphum Ornatum"
  ],
  "kvenel the tongue": [
    "Kvenel Lingua"
  ],
  "ebony greatsword of evoking": [
    "Spatha Ebeni Evocandi"
  ],
  "scroll of reanimate corpse": [
    "Codex Revocandi Corpus"
  ],
  "light touch": [
    "Tactus Levis"
  ],
  "worshipper": [
    "Fautor"
  ],
  "so karliah took on both of you alone": [
    "Ergo Karliah vos ambos vicit"
  ],
  "mercer frey": [
    "Mercer Frey"
  ],
  "vampire jailor": [
    "Custos Vampirus"
  ],
  "(invisible continue)": [
    "(Invisible continue)"
  ],
  "playtesting temporary message": [
    "Playtesting Temporary Message"
  ],
  "fortify carry weight": [
    "Adde ad Pondus Portabile"
  ],
  "shriekwind overlook": [
    "Panorama Ventululatio"
  ],
  "honningbrew mead barrel": [
    "Cuppa Hyromelli Honningbrevis"
  ],
  "markarth wizards' balcony": [
    "Ektheta Magi Markartiae"
  ],
  "volskygge peak": [
    "Volskyggis Apex"
  ],
  "east empire dockmaster": [
    "Euri Imperii Magister Navalis"
  ],
  "spell tome: raise zombie": [
    "Liber Magicus: Raise Zombie"
  ],
  "ironbind overlook": [
    "Panorama Ferrivincta"
  ],
  "how is gulum-ei an asset to the guild": [
    "Quomodo adiuvat Gulum-Ei Sodalitatem"
  ],
  "pale guard's shield": [
    "Pallidi Custodis Scutum"
  ],
  "<alias.shortname=client>'s <basename>": [
    "<BaseName> <Alias.ShortName=Client>"
  ],
  "mistwatch courtyard": [
    "Area Nebulavisus"
  ],
  "mistwatch higher balcony": [
    "Ektheta Altior Nebulavisus"
  ],
  "daedric warhammer of leeching": [
    "Bellimalleus Daedricus Sanguisugae"
  ],
  "change pose": [
    "Muta Visus"
  ],
  "i'll watch it for you": [
    "Id pro te videbo"
  ],
  "orcish gauntlets of extreme sure grip": [
    "Orci Manicae Dexteritatis Extremae"
  ],
  "silver ingot": [
    "Argentei Later"
  ],
  "hairmaleorc14": [
    "HairMaleOrc14"
  ],
  "what better reason to act than to fulfill my destiny": [
    "Quid melius possit quam fatum meum complere"
  ],
  "gold sapphire ring": [
    "Anulus Auri Sapphirique"
  ],
  "skyforge steel greatsword": [
    "Fabricaeli Spatha Adamantea"
  ],
  "presets": [
    "Praefacta"
  ],
  "fresh meat": [
    "Carnis RecensFresh"
  ],
  "dibella statue": [
    "Statua Dibellae"
  ],
  "sabjorn's dresser key": [
    "Clavis ad Armarium Sabjornis"
  ],
  "iron boots of hauling": [
    "Ferri Caligae Portandi"
  ],
  "blade of <alias=hold>": [
    "Ensis <Alias=Hold>"
  ],
  "hajvarr iron-hand": [
    "Hajvarr Ferri-Manus"
  ],
  "you're obviously a thief": [
    "Fur certe es"
  ],
  "i kill thieves": [
    "Fures neco"
  ],
  "elven helmet of eminent illusion": [
    "Alfa Galea Eminentis Inlusionis"
  ],
  "scaled boots of hauling": [
    "Caligae Squamatae Portandi"
  ],
  "harvest nirnroot": [
    "Carpe Nirnradicem"
  ],
  "silver-blood guard": [
    "Custos Argenti-Sanguinis"
  ],
  "sleeping tree sap success": [
    "Sleeping Tree Sap Success"
  ],
  "yes, i'm ready": [
    "Sic, paratus sum"
  ],
  "ebony sword": [
    "Gladius Ebeni"
  ],
  "there aren't many redguards in skyrim": [
    "Multi Rubricustodes hic in Caelifine non sunt"
  ],
  "what brought you here": [
    "Quid te huic attulit"
  ],
  "contract: kill beitild": [
    "Foedus: Neca Beitild"
  ],
  "iron door": [
    "Ianuam Ferratam"
  ],
  "potent frost atronach": [
    "Potens Atronachum Glaciei"
  ],
  "sconce": [
    "Cornucopium"
  ],
  "gunding": [
    "Gunding"
  ],
  "tell aventus aretino that grelod is dead": [
    "Dic Avento Aretinoni Grelodem Mortuam Esse"
  ],
  "steel gauntlets of archery": [
    "Adamanteae Manicae Saggitationis"
  ],
  "hide boots of sneaking": [
    "Renonis Caligae Repsendi"
  ],
  "orcish greatsword": [
    "Orci Spatha"
  ],
  "elven dagger of torpor": [
    "Pugio Alfus Torporis"
  ],
  "i'm looking for pieces of mehrunes' razor": [
    "Partes Novaculae Croni quaero"
  ],
  "sadri's used wares key": [
    "Clavis ad Manum Secundam Sadri"
  ],
  "dwarven greatsword of scorching": [
    "Dvemeris Spatha Adurendi"
  ],
  "halldir": [
    "Halldir"
  ],
  "is it still possible to aid the temple": [
    "Iamne possum templum adiuvare"
  ],
  "frostbite venom": [
    "Adustus Nivibus Venenum"
  ],
  "high hrothgar courtyard": [
    "Peristylum Alti Hrothgaris"
  ],
  "hide boots of dwindling shock": [
    "Renonis Caligae Electricitatis Diminuentis"
  ],
  "stone of barenziah": [
    "Lapis Barenziae"
  ],
  "muiri's ring": [
    "Anulus Muiri"
  ],
  "hadvar": [
    "Hadvar"
  ],
  "ebony battleaxe of the inferno": [
    "Bellisecuris Ebeni Conflagrationis"
  ],
  "daedric sword of winter": [
    "Gladius Daedricus Hiemis"
  ],
  "if you were dead, i could keep the horse... (intimidate)": [
    "Si mortuus esses, equum habere possem... (Intimida)"
  ],
  "i'm here to rescue you and recover the books you stole": [
    "Hic sum ut te servem et libros quos furatus es recipere"
  ],
  "i have iron ore to sell": [
    "Ferrum vendendum habeo"
  ],
  "balgruuf's war axe": [
    "Bellisecuris Balgruufis"
  ],
  "hrongar's war axe": [
    "Bellisecuris Hrongaris"
  ],
  "corpse of brother verulus": [
    "Corpus Fratris Veruli"
  ],
  "aspect of hircine": [
    "Aspectus Hecatis"
  ],
  "so you're from daggerfall, then": [
    "Ergo a Pugicadente venis"
  ],
  "crumbling mortar": [
    "Caementum Vetus"
  ],
  "imperial bracers of minor wielding": [
    "Chirothecae Romanae Minoris Tenendi"
  ],
  "nivenor": [
    "Nivenor"
  ],
  "what do you do in dawnstar": [
    "Quid in Lucifere facis"
  ],
  "got any leads": [
    "Scisne de quo"
  ],
  "dome03": [
    "Dome03"
  ],
  "civilian allies of imperial faction": [
    "civilian allies of Imperialis faction"
  ],
  "the blades just want to defeat alduin": [
    "Enses modo Alduinum vincere volunt"
  ],
  "blades just want to defeat alduin": [
    "Enses modo Alduinum vincere volunt"
  ],
  "don't you": [
    "Nonne tu quoque id vis"
  ],
  "ancient nord war axe of frost": [
    "Antiquus Bellisecuris Germanicus Gelidus"
  ],
  "elven gilded armor of major health": [
    "Alfa Lorica Aurata Maioris Salutis"
  ],
  "hairfemalenord06": [
    "HairFemaleNord06"
  ],
  "ebony helmet of extreme alteration": [
    "Ebeni Galea Extremis Mutationis"
  ],
  "embassy inner courtyard": [
    "Aula Interior Legationis"
  ],
  "faction for npcs who should stay out of palace": [
    "faction for NPCs who should stay out of palace"
  ],
  "master illusion": [
    "Dominus Inlusionis"
  ],
  "fortify archery": [
    "Adde ad Sagittationem"
  ],
  "cannot be activated": [
    "Non potes uti"
  ],
  "the brinehammer cargo hold": [
    "Caverna Oneris Mallei Salsuginis"
  ],
  "brinehammer cargo hold": [
    "Caverna Oneris Mallei Salsuginis"
  ],
  "darkelf": [
    "DarkElf"
  ],
  "japhet's folly key": [
    "Clavis ad Fatuitatem Japhetis"
  ],
  "free and share items": [
    "Libera et res communicare"
  ],
  "you have contracted": [
    "Aegrotas cum"
  ],
  "ritual urn": [
    "Urna Ritualis"
  ],
  "daedric dagger of winter": [
    "Pugio Daedricus Hiemis"
  ],
  "drascua": [
    "Drascua"
  ],
  "emblem i": [
    "Emblema I"
  ],
  "so if i could lure a dragon into dragonsreach": [
    "ergo, si draco in Draconicarcerem evocaverim"
  ],
  "emblem ii": [
    "Emblema II"
  ],
  "a drinking contest": [
    "Certamen bibendi"
  ],
  "drinking contest": [
    "Certamen bibendi"
  ],
  "you don't stand a chance": [
    "Vincere non potes"
  ],
  "vighar": [
    "Vighar"
  ],
  "you have any information on the red wave": [
    "Scisne quid de Unda Rubra"
  ],
  "reverent orcish war axe": [
    "Reverens Bellisecuris Orcorum"
  ],
  "wood cutter": [
    "Intersector Ligni"
  ],
  "orcish war axe of diminishing": [
    "Orci Bellisecuris Diminuendi"
  ],
  "whiterun wintersand manor faction": [
    "Whiterun Wintersand Manor Faction"
  ],
  "here's keerava's payment, courtesy of her family": [
    "Debitum Keeravae hic habeo, a familia sua"
  ],
  "browsmalehumanoid02": [
    "BrowsMaleHumanoid02"
  ],
  "emblem vi": [
    "Emblema VI"
  ],
  "insane college wizard": [
    "Thaumatorgos Insanus Collegii"
  ],
  "tell me what to do": [
    "Dic mihi quid me facere debere"
  ],
  "emblem viii": [
    "Emblema VIII"
  ],
  "emblem ix": [
    "Emblema IX"
  ],
  "dragonfly spawner": [
    "DragonFly Spawner"
  ],
  "what can you tell me about laelette": [
    "Quid mihi dicere potes de Laelette"
  ],
  "mythic dawn commentaries 4": [
    "Commentarii Aurorae Mythicae IV"
  ],
  "is something wrong": [
    "Quid accidit"
  ],
  "sahloknir": [
    "Sahloknir"
  ],
  "you're dismissed": [
    "Dimmiteris"
  ],
  "return home": [
    "Reveni domum"
  ],
  "are you... all right": [
    "Esne... bene"
  ],
  "blacksmith forges": [
    "Fabricae Ferrarii"
  ],
  "northwatch prisoner": [
    "Septentriovisus Captivus"
  ],
  "i can handle this on my own": [
    "Hoc solus administrare possum"
  ],
  "i learned the dragonrend shout": [
    "Didici Draconicaesem"
  ],
  "enchanting": [
    "Incantamentum"
  ],
  "the icerunner": [
    "Glacicursor"
  ],
  "icerunner": [
    "Icerunner",
    "Glacicursor"
  ],
  "iron boots of resist frost": [
    "Ferri Caligae Contra Vim Gelidam"
  ],
  "agent of dibella": [
    "Agens Dibellae"
  ],
  "wedding ring": [
    "Anulus Nuptialis"
  ],
  "daedric mace of the sorcerer": [
    "Maca Daedrica Magi"
  ],
  "the lord stone": [
    "Lapis Domini"
  ],
  "lord stone": [
    "Lapis Domini"
  ],
  "leveling up": [
    "Leveling Up"
  ],
  "glass dagger of reaping": [
    "Pugio Vitreus Saturni"
  ],
  "solution of extra magicka": [
    "Solutio Maioris Magicae"
  ],
  "spriggan sap": [
    "Olibanum Sprigganis"
  ],
  "who's the whispering lady": [
    "Quis est Matrona Sussurans"
  ],
  "book shelf": [
    "Pegma"
  ],
  "maleeyeshighelforange": [
    "MaleEyesHighElfOrange"
  ],
  "what do you mean, \"return\"": [
    "Quid sibi vult, \"reveni\""
  ],
  "you've been there": [
    "Illic affuisti"
  ],
  "where can i find this augur": [
    "Ubi hunc Augurem invenire possum"
  ],
  "the wolf queen, v3": [
    "Regina Luporum, III"
  ],
  "wolf queen, v3": [
    "Regina Luporum, III"
  ],
  "we haven't really been introduced": [
    "Nondum inter nos cognovimus"
  ],
  "creaturedialogueskeever": [
    "CreatureDialogueSkeever"
  ],
  "berit's ashes": [
    "Cineres Beritis"
  ],
  "the bond of matrimony": [
    "Iugum Matrimoniae"
  ],
  "bond of matrimony": [
    "Iugum Matrimoniae"
  ],
  "axe of <alias=hold>": [
    "Securis <Alias=Hold>"
  ],
  "forged documents": [
    "Documenta Falsa"
  ],
  "the storage device you selected is no longer available": [
    "The storage device you selected is no longer available"
  ],
  "storage device you selected is no longer available": [
    "The storage device you selected is no longer available"
  ],
  "you will need to select a new device the next time you save": [
    "You will need to select new device next time you save"
  ],
  "until then, autosave is disabled": [
    "Until then, Autosave is disabled"
  ],
  "monarch butterfly": [
    "Papilionem Regis"
  ],
  "imperial armor of the minor knight": [
    "Romana Lorica Minoris Equitis"
  ],
  "can't you just take the key": [
    "Quidni clavem capias"
  ],
  "once i'm inside the embassy, then what": [
    "Post legationem intravero, quid postea"
  ],
  "dragonplate helmet of peerless archery": [
    "Draconilaminata Galea Optimae Saggitationis"
  ],
  "hide armor of minor health": [
    "Renonis Lorica Minoris Salutis"
  ],
  "ebony battleaxe of enervating": [
    "Bellisecuris Ebeni Enervationis"
  ],
  "all right, i'll help you": [
    "Bene, te adiuvabo"
  ],
  "give me the scrolls": [
    "Da mihi codices"
  ],
  "fortify heavy armor": [
    "Adde Ad Loricam Gravem"
  ],
  "mead": [
    "Hydromellum"
  ],
  "i have the jagged crown": [
    "Coronam Fragosam habeo"
  ],
  "thieves guild variant boots": [
    "Sodalitatis Furum Caligae Var"
  ],
  "cicero arrive scene trigger": [
    "Cicero Arrive Scene Trigger"
  ],
  "imperial boots of minor sneaking": [
    "Caligae Romanae Minoris Repsendi"
  ],
  "sounds like a guild contract": [
    "Mihi videtur Sodalitatis contract"
  ],
  "brief history of the empire, v3": [
    "Historia Brevis Imperii, III"
  ],
  "atronachflametrail": [
    "AtronachFlameTrail"
  ],
  "bed and chairs": [
    "Lectus et Sellae"
  ],
  "it will be an honor": [
    "Honor mihi erit"
  ],
  "assassin versus vampire": [
    "Sicarius contra vampirum"
  ],
  "a fight for the ages": [
    "Pugna pro aetatibus"
  ],
  "fight for the ages": [
    "Pugna pro aetatibus"
  ],
  "elven mace of evoking": [
    "Maca Alfa Evocandi"
  ],
  "murbul": [
    "Murbul"
  ],
  "pantea ateia": [
    "Pantea Ateia"
  ],
  "imperial bow of embers": [
    "Imperialis Arcus Cinerum"
  ],
  "forelhost battlements": [
    "Pinna Forelhostis"
  ],
  "dialoguedawnstarwhitehallskaldmadena01": [
    "DialogueDawnstarWhiteHallSkaldMadena01"
  ],
  "i'll have to do that some other time": [
    "Id alio tempore facere debebo"
  ],
  "dialogueoldhroldan": [
    "DialogueOldHroldan"
  ],
  "reachwind eyrie overlook": [
    "Panorama Aeriae Prehensivisus"
  ],
  "steadfast ward": [
    "Praesidium Firmum"
  ],
  "do you know dragonrend or not": [
    "Scisne Draconicaesem vel non"
  ],
  "brief history of the empire, v2": [
    "Historia Brevis Imperii, II"
  ],
  "elven warhammer of binding": [
    "Alfus Bellimaleus Deligandi"
  ],
  "tamriel": [
    "Tamriel"
  ],
  "daedric war axe of evoking": [
    "Bellisecuris Daedricus Evocandi"
  ],
  "db04astridplayerproceeresponse1": [
    "DB04AstridPlayerProceeResponse1"
  ],
  "key to wintersand manor": [
    "Clavis ad Villam Arenahiemis"
  ],
  "dialoguesoljundsminehousescene01": [
    "DialogueSoljundsMineHouseScene01"
  ],
  "we need to get out of here": [
    "Hinc discedere debemus"
  ],
  "deliver the stone to phinis": [
    "Fer Lapidem Phini"
  ],
  "grushnag": [
    "Grushnag"
  ],
  "turn greater undead": [
    "Repelle Amortuos Maius"
  ],
  "i killed the leader of the dark brotherhood": [
    "Ducem Fraternitatis Obscurae necavi"
  ],
  "the pale": [
    "the Pale"
  ],
  "hjaalmarch": [
    "Hjaalmarch"
  ],
  "<alias=player>'s horse": [
    "Equus Tuus"
  ],
  "steel dagger of souls": [
    "Pugio Adamanteus Animorum"
  ],
  "irontree lumber mill": [
    "Irontree Lumber Mill"
  ],
  "force <alias=jarl>'s surrender": [
    "Coge <Alias=Jarl> ut succumbat"
  ],
  "granite hill": [
    "Granite Hill"
  ],
  "olur": [
    "Olur"
  ],
  "gallows rock": [
    "Saxum Gabalorum"
  ],
  "rift stormcloak camp": [
    "Hiati Sturmmantelorum Castra"
  ],
  "helgen": [
    "Helgen"
  ],
  "what do you need from the docks": [
    "Quid e navali vis"
  ],
  "i have a delivery from bothela": [
    "Cistam tibi habeo a Bothela"
  ],
  "tell me now or i'll kill you where you stand": [
    "Dic mihi nunc vel te necam ubi stas"
  ],
  "nightgate inn": [
    "Nightgate Inn"
  ],
  "i've heard you dabble in necromancy": [
    "Audivi te partem in necromantia habere"
  ],
  "i don't have time right now": [
    "Tempus mihi nunc non est"
  ],
  "vigilant of stendarr faction": [
    "Vigilant of Stendarr Faction"
  ],
  "spell tome: chain lightning": [
    "Liber Magicus: Fulmen Catenatum"
  ],
  "ma'dran": [
    "Ma'dran"
  ],
  "to steal": [
    "Furandum"
  ],
  "deekus is dead": [
    "Deekus mortuus est"
  ],
  "steel plate helmet of major restoration": [
    "Laminata Galea Maioris Reficiendi"
  ],
  "glass warhammer of enervating": [
    "Bellimaleus Vitreus Enervationis"
  ],
  "black-briar mead keg": [
    "Ater-Dumus Hyrdomellum Cuppa"
  ],
  "barleydark farm": [
    "Barleydark Farm"
  ],
  "orcish mace of siphoning": [
    "Orci Maca Siphonis"
  ],
  "bear's cave mill": [
    "Bear's Cave Mill"
  ],
  "brandy-mug farm": [
    "Brandy-Mug Farm"
  ],
  "da10reanimateench": [
    "DA10ReanimateEnch"
  ],
  "i was told you wanted to see me right away": [
    "Audivi te me statim videre voluisse"
  ],
  "spell tome: calm": [
    "Liber Magicus: Molli"
  ],
  "cidhna mine": [
    "Cidhna Mine",
    "Metallum Cidhnae"
  ],
  "the imperials lost control of <alias=hold>": [
    "Romani <Alias=Hold> perdidÃªrunt"
  ],
  "imperials lost control of <alias=hold>": [
    "Romani <Alias=Hold> perdidÃªrunt"
  ],
  "steel dagger of torpor": [
    "Pugio Adamanteus Torporis"
  ],
  "um... i don't know": [
    "Um... Nescio"
  ],
  "goldenglow estate": [
    "Goldenglow Estate"
  ],
  "glass greatsword of the blaze": [
    "Spatha Vitrea Congflagrantis"
  ],
  "high hrothgar": [
    "High Hrothgar"
  ],
  "maleeyeshumanamber": [
    "MaleEyesHumanAmber"
  ],
  "hollyfrost farm": [
    "Hollyfrost Farm"
  ],
  "all right, you're safe now": [
    "Bene, nunc tutus es"
  ],
  "kolskeggr mine": [
    "Metallum Kolskeggris"
  ],
  "hall of valor": [
    "Atrium Virtutis"
  ],
  "left hand mine": [
    "Left Hand Mine"
  ],
  "loreius farm": [
    "Loreius Farm"
  ],
  "merryfair farm": [
    "Merryfair Farm"
  ],
  "mixwater mill": [
    "Mixwater Mill"
  ],
  "ironflesh": [
    "Ferripellis"
  ],
  "daedric sword of fire": [
    "Gladius Daedricus Ignis"
  ],
  "salvius farm": [
    "Salvius Farm"
  ],
  "sarethi farm": [
    "Sarethi Farm"
  ],
  "bulfrek": [
    "Bulfrek"
  ],
  "snow-shod farm": [
    "Snow-Shod Farm"
  ],
  "you grew up in a stronghold": [
    "In arce iuvenisti"
  ],
  "seen any imperial couriers lately": [
    "Vidistine quos tabellarios Romanos"
  ],
  "steel sword of fatigue": [
    "Gladius Adamanteus Defatigationis"
  ],
  "daedric bow of thunderbolts": [
    "Arcus Daedricus Fulminum"
  ],
  "staff of expulsion": [
    "Rudis Expulsionis"
  ],
  "leather helmet of major restoration": [
    "Cutis Galea Maioris Reficiendi"
  ],
  "steel battleaxe of sparks": [
    "Bellisecuris Adamanteus Scintillarum"
  ],
  "alftand": [
    "Alftand"
  ],
  "i'm going to try to capture a dragon, if <alias.shortname=jarl> will help me": [
    "Draconem capere temptabo, si <Alias.ShortName=Jarl> me aduiverit"
  ],
  "azura's star interior": [
    "Stella Azurae Interior"
  ],
  "bard's leap summit": [
    "Bard's Leap Summit"
  ],
  "jester's boots": [
    "Macci Caligae"
  ],
  "bleakcoast cave": [
    "Bleakcoast Cave"
  ],
  "dialoguemarkarthdragonsmarketscene01": [
    "DialogueMarkarthDragonsMarketScene01"
  ],
  "bleakwind bluff": [
    "Bleakwind Bluff"
  ],
  "brood cavern": [
    "Feturae Caverna"
  ],
  "blizzard rest": [
    "Blizzard Rest"
  ],
  "bloated man's grotto": [
    "Bloated Manis Specus"
  ],
  "find the skooma dealers in cragslane cavern": [
    "Inveni Skoomarios in Caverna Cragslane"
  ],
  "grand plaza scene 24": [
    "Grand Plaza Scene 24"
  ],
  "southfringe sanctum": [
    "Southfringe Sanctum"
  ],
  "broken fang cave": [
    "Broken Fang Cave"
  ],
  "honed ancient nord sword of ice": [
    "Melior Germanicus Gladius Antiquus Gelidus"
  ],
  "broken helm hollow": [
    "Broken Helm Hollow"
  ],
  "broken tower redoubt": [
    "Broken Tower Redoubt"
  ],
  "bruca's leap redoubt": [
    "Bruca's Leap Redoubt"
  ],
  "bthardamz": [
    "Bthardamz"
  ],
  "broken oar grotto": [
    "Broken Oar Specus"
  ],
  "nope, don't get it at all": [
    "Minime, omnino non intellego"
  ],
  "edith": [
    "Edith"
  ],
  "dried frost mirriam": [
    "Mirria Glaciei Siccae"
  ],
  "clearpine pond": [
    "Clearpine Pond"
  ],
  "clearspring tarn": [
    "Clearspring Tarn"
  ],
  "cradlecrush rock": [
    "Cradlecrush Rock"
  ],
  "cragslane cavern": [
    "Cragslane Cavern"
  ],
  "ironbind barrow": [
    "Ironbind Barrow"
  ],
  "cronvangr hall": [
    "Cronvangr Hall"
  ],
  "imperial bow of torpor": [
    "Imperialis Arcus Torporis"
  ],
  "dark yellow": [
    "dark yellow"
  ],
  "the apprentice stone": [
    "Lapis Discipuli"
  ],
  "apprentice stone": [
    "Lapis Discipuli"
  ],
  "haelga gave you a mark of dibella": [
    "Haelga tibi dedit signum Dibellae"
  ],
  "i want it": [
    "Id volo"
  ],
  "dragonplate boots of shock suppression": [
    "Draconilaminata Caligae Suppresionis Electricitatis"
  ],
  "heimskr": [
    "Heimskr"
  ],
  "darkshade copse": [
    "Darkshade Copse"
  ],
  "coldhearted gravedigger": [
    "Frgidicordis Fossatrix Sepulcrorum"
  ],
  "darkwater pass": [
    "Darkwater Pass"
  ],
  "dead crone rock": [
    "Dead Crone Rock"
  ],
  "dragontooth crater": [
    "Dragontooth Crater"
  ],
  "daedric dagger of fire": [
    "Pugio Daedricus Ignis"
  ],
  "eldersblood peak": [
    "Eldersblood Peak"
  ],
  "midnight": [
    "medianox"
  ],
  "skyborn altar": [
    "Skyborn Altar"
  ],
  "dragon lair 3": [
    "Dragon Lair 3"
  ],
  "bannered mare services": [
    "Bannered Mare Services"
  ],
  "flame atronach abilities": [
    "Flame Atronach abilities"
  ],
  "northwind summit": [
    "Northwind Summit"
  ],
  "roll of paper": [
    "Cylindrus Chartae"
  ],
  "lost tongue overlook": [
    "Lost Tongue Overlook"
  ],
  "bonestrewn crest": [
    "Bonestrewn Crest"
  ],
  "tell me everything or end up like fjotli": [
    "Dic mihi omnia vel ut Fjotli eris"
  ],
  "steamcrag camp": [
    "Steamcrag Camp"
  ],
  "how much are we talking about": [
    "De quanto loquimur"
  ],
  "blood horker": [
    "Horker Sanguinis"
  ],
  "driftshade sanctuary": [
    "Fanum Umbralapsus"
  ],
  "druadach redoubt": [
    "Druadach Redoubt"
  ],
  "nils": [
    "Nils"
  ],
  "dushnikh yal": [
    "Dushnikh Yal"
  ],
  "steel warhammer of soul snares": [
    "Bellimalleus Adamanteus Animicapti"
  ],
  "i'm here to rescue you": [
    "Hic sum ut te servem"
  ],
  "black briar meadery": [
    "Black Briar Meadery"
  ],
  "evergreen grove": [
    "Evergreen Grove"
  ],
  "faldar's tooth": [
    "Faldar's Tooth"
  ],
  "fighting the giant isn't my job": [
    "Gigantem oppugnare munus meum non est"
  ],
  "steel plate boots of dwindling flames": [
    "Laminatae Caligae Flammarum Diminuentium"
  ],
  "report back to nazir": [
    "Refer te ad Nazir"
  ],
  "folgunthur": [
    "Folgunthur"
  ],
  "forelhost": [
    "Forelhost"
  ],
  "what do you know about the psijic order": [
    "Quid de Ordine Psijico scis"
  ],
  "the madness of pelagius": [
    "Insanitas Pelagii"
  ],
  "madness of pelagius": [
    "Insanitas Pelagii"
  ],
  "forsaken cave": [
    "Forsaken Cave"
  ],
  "fort amol": [
    "Fort Amol"
  ],
  "prevents filling aliases in witavern": [
    "Prevents filling aliases in WITavern"
  ],
  "fort fellhammer": [
    "Fort Fellhammer"
  ],
  "fort greenwall": [
    "Fort Greenwall"
  ],
  "daedric helmet of extreme magicka": [
    "Galea Daedrica Extremis Magicae"
  ],
  "fort hraggstad": [
    "Fort Hraggstad"
  ],
  "madesi's market key": [
    "Clavis Fori Madesi"
  ],
  "volskygge passages": [
    "Volskygge Commeatus"
  ],
  "dainty sload": [
    "Sload Cupes"
  ],
  "i have a poem i'd like you to read": [
    "Poemam tibi legendam habeo"
  ],
  "fort kastav": [
    "Fort Kastav"
  ],
  "let's go trap a dragon": [
    "Eamus draconem insidias paratum"
  ],
  "daedric bow of malediction": [
    "Arcus Daedricus Maledictionis"
  ],
  "storm call effect 1": [
    "Storm Call Effect 1"
  ],
  "frostflow lighthouse": [
    "Frostflow Lighthouse"
  ],
  "are you here with one of the jarls": [
    "Esne hic cum uno ex Comesitibus"
  ],
  "glenmoril coven": [
    "Glenmoril Coven"
  ],
  "blizzard hazard 30": [
    "Blizzard Hazard 30"
  ],
  "textures\\blood\\fxbloodflare.dds": [
    "Textures\\Blood\\FXBloodFlare.dds"
  ],
  "ungrien": [
    "Ungrien"
  ],
  "graywinter watch": [
    "Graywinter Watch"
  ],
  "do you want to serve your time in jail": [
    "Visne tempus in carcere consumere"
  ],
  "cracked tusk keep": [
    "Cracked Tusk Keep"
  ],
  "draught of haggling": [
    "Decocta Arguendi"
  ],
  "guldun rock": [
    "Guldun Rock"
  ],
  "he's an old man": [
    "Est senex"
  ],
  "let it go": [
    "Ab eo desine",
    "Debiti obliviscere"
  ],
  "haemar's shame": [
    "Haemar's Shame"
  ],
  "hag rock redoubt": [
    "Hag Rock Redoubt"
  ],
  "you can drop the act now... gajul-lei": [
    "E partem agendo desistere nunc potes... Gajul-Lei"
  ],
  "imperial armor of minor health": [
    "Romana Lorica Minoris Salutis"
  ],
  "kodlak, is that you": [
    "Kodlak, estne tu"
  ],
  "halted stream camp": [
    "Halted Stream Camp"
  ],
  "harmugstahl": [
    "Harmugstahl"
  ],
  "daedric greatsword of the inferno": [
    "Spatha Daedrica Conflagrationis"
  ],
  "skeggr": [
    "Skeggr"
  ],
  "fort fellhammer garrison": [
    "Praesidium Castelli Fellimallei"
  ],
  "ear tufts": [
    "Ear Tufts"
  ],
  "high gate ruins": [
    "High Gate Ruins"
  ],
  "hillgrund's tomb": [
    "Hillgrund's Tomb"
  ],
  "improved virulity": [
    "Virulitas Aucta"
  ],
  "crown of barenziah": [
    "Corona Barenziae"
  ],
  "i'll pass on this one, then": [
    "Sic, hoc non faciam"
  ],
  "you're working him to death": [
    "Tui causa is ad mortem laborat"
  ],
  "lay off": [
    "Desine ab eo"
  ],
  "disembodied voice": [
    "Vox Incorporealis"
  ],
  "scaled helmet of archery": [
    "Galea Squamata Saggitationis"
  ],
  "switch weapon": [
    "Muta Armum"
  ],
  "whirlwind sprint 1": [
    "Whirlwind Sprint 1"
  ],
  "glass bow of stunning": [
    "Arcus Vitreus Intermissionis"
  ],
  "karthspire": [
    "Karthspire",
    "Spirakarthis"
  ],
  "philter of illusion": [
    "Philtrum Inlusionis"
  ],
  "exitbowzoombreath": [
    "ExitBowZoomBreath"
  ],
  "kilkreath ruins": [
    "Kilkreath Ruins"
  ],
  "labyrinthian": [
    "Labyrinthian"
  ],
  "largashbur": [
    "Largashbur"
  ],
  "iron helmet of major alteration": [
    "Ferri Galea Maioris Mutationis"
  ],
  "scaled boots of strength": [
    "Caligae Squamatae Fortitudinis"
  ],
  "i want to stop at rorikstead": [
    "Volo consistere apud Rorikidomum"
  ],
  "(<global=carriagecost> gold)": [
    "(<Global=CarriageCost> auri)"
  ],
  "shrouded grove cave": [
    "Shrouded Nemus Spelunca"
  ],
  "sons of skyrim military camp": [
    "Sons of Skyrim Military Camp"
  ],
  "kilkreathlightbeam": [
    "kilkreathLightBeam"
  ],
  "i will spare your life": [
    "Parcam vitae tuae"
  ],
  "do you desire to be the high king": [
    "Visne Regem Altum esse"
  ],
  "resist poison": [
    "Resiste Veneno"
  ],
  "fine, forget it": [
    "Bene, eius obliviscere"
  ],
  "dialoguedushnikhyallonghousescene01": [
    "DialogueDushnikhYalLonghouseScene01"
  ],
  "ceremonial sword": [
    "Gladius Ceremonialis"
  ],
  "rune": [
    "Runa"
  ],
  "mistwatch": [
    "Mistwatch"
  ],
  "voice power": [
    "Vis Vocis"
  ],
  "whiterun braith amren scene 2": [
    "Whiterun Braith Amren Scene 2"
  ],
  "new life festival": [
    "Festum Novae Vitae"
  ],
  "mount anthor": [
    "Mons Anthoris"
  ],
  "what do you know about markarth": [
    "Quid de Markartia scis"
  ],
  "narzulbur": [
    "Narzulbur"
  ],
  "nilheim": [
    "Nilheim"
  ],
  "you learned the %s enchantment from destroying this item": [
    "Didici incantamentum %s"
  ],
  "northwatch keep": [
    "Northwatch Keep"
  ],
  "lesser": [
    "Minor"
  ],
  "both sides respect the greybeards": [
    "Ambo latera Canabarbas respiciunt"
  ],
  "they will listen": [
    "Audiant"
  ],
  "i don't have time": [
    "Tempus non habeo"
  ],
  "orotheim": [
    "Orotheim"
  ],
  "whiterun severio pelagia's house faction": [
    "Whiterun Severio Pelagia's House Faction"
  ],
  "orphan rock": [
    "Orphan Rock"
  ],
  "pinemoon cave": [
    "Pinemoon Cave"
  ],
  "how do you know where the dragon will come back": [
    "Quomodo scis ubi draconem reveniat"
  ],
  "pinewatch": [
    "Pinewatch"
  ],
  "frostbite poison": [
    "Venenum Adustus Nivibus"
  ],
  "purewater run": [
    "Purewater Run"
  ],
  "ragnvald": [
    "Ragnvald"
  ],
  "ravenscar hollow": [
    "Caverna Cicatricis Coracis"
  ],
  "daedric dagger of thunderbolts": [
    "Pugio Daedricus Fulminum"
  ],
  "reachwater rock": [
    "Reachwater Rock"
  ],
  "red road pass": [
    "Red Road Pass"
  ],
  "fellglow ritual chamber key": [
    "Clavis ad Conclave Rituale Fellilucis"
  ],
  "rimerock burrow": [
    "Rimerock Burrow"
  ],
  "saarthal": [
    "Saarthalis"
  ],
  "how will i know when to awaken": [
    "Quomodo sciam quando mihi surgendum sit"
  ],
  "secunda's shelf": [
    "Secunda's Shelf"
  ],
  "robber's gorge": [
    "Robber's Gorge"
  ],
  "i have alessandra's dagger here": [
    "Pugio Alessandrae hic habeo"
  ],
  "hela's folly": [
    "Hela's Folly"
  ],
  "you asked my opinion": [
    "Sententiam meam rogavisti"
  ],
  "i gave it to you": [
    "Eam tibi dedi"
  ],
  "kyne's peace 3": [
    "Pax Kynis 3"
  ],
  "orphan's tear": [
    "Orphanis Tear"
  ],
  "shrouded grove": [
    "Shrouded Grove"
  ],
  "how is your project coming along": [
    "Quid fit cum opere tuo"
  ],
  "sightless pit": [
    "Sightless Pit"
  ],
  "silent moons camp": [
    "Silent Moons Camp"
  ],
  "here's my invitation": [
    "Hic habes invitationem"
  ],
  "ebony shield": [
    "Ebeni Scutum"
  ],
  "seen any stormcloak couriers lately": [
    "Vidistine quos nuntios Sturmmantelos"
  ],
  "it's all right. but have you found anything": [
    "Non malum est. Sed aliquidne invenisti"
  ],
  "steepfall burrow": [
    "Steepfall Burrow"
  ],
  "stillborn cave": [
    "Stillborn Cave"
  ],
  "gaius maro will die": [
    "Gaius Maro morietur",
    "Gaius Maro moriturus est"
  ],
  "hail sithis": [
    "Ave Sithi",
    "Ave Arkaya"
  ],
  "can you tell me more about this project of yours": [
    "Potesne mihi plus de opere tuo narrare"
  ],
  "talking stone camp": [
    "Talking Stone Camp"
  ],
  "key to loreius famhouse": [
    "Clavis ad Villam Rusticam Lorei"
  ],
  "throat of the world": [
    "Guttur Mundi",
    "Throat of the World"
  ],
  "wooden door": [
    "Ianuam Ligneam"
  ],
  "treva's watch": [
    "Treva's Watch"
  ],
  "tumble arch pass": [
    "Tumble Arch Pass"
  ],
  "iron sword of scorching": [
    "Gladius Ferri Adurendi"
  ],
  "iron helmet of major archery": [
    "Ferri Galea Maioris Sagittationis"
  ],
  "uttering hills camp": [
    "Uttering Hills Camp"
  ],
  "it would be an honor": [
    "Honor mihi erit"
  ],
  "serpent's bluff redoubt": [
    "Serpent's Bluff Redoubt"
  ],
  "white river watch": [
    "White River Watch"
  ],
  "witchmist grove": [
    "Witchmist Grove"
  ],
  "i'd like to train in one-handed weapons": [
    "Volo te de Armis Singuli-Manus docere"
  ],
  "iron warhammer of scorching": [
    "Bellimalleus Ferratus Adurendi"
  ],
  "werewolf totem": [
    "Versipellis Totemnis"
  ],
  "yngol barrow": [
    "Yngol Barrow"
  ],
  "did i see you arguing with someone": [
    "Vidine te cum quo arguere"
  ],
  "yngvild": [
    "Yngvild"
  ],
  "movarth's lair": [
    "Movarth's Lair"
  ],
  "delphine sven": [
    "Delphine Sven"
  ],
  "ansilvund": [
    "Ansilvund"
  ],
  "retrieve the jagged crown": [
    "Refer Coronam Fragosam"
  ],
  "faendal's house": [
    "Faendal's House"
  ],
  "here you are": [
    "Hic id habes"
  ],
  "spell tome: rally": [
    "Liber Magicus: Amicos Collige"
  ],
  "sven's house": [
    "Svenis House"
  ],
  "sleeping giant inn": [
    "Sleeping Giant Inn"
  ],
  "lucan's dry goods": [
    "Lucanis Dry Goods"
  ],
  "reverent orcish warhammer": [
    "Reverens Bellimalleus Orcorum"
  ],
  "are you surrendering": [
    "Mihine succumbis"
  ],
  "yes, cicero's journal": [
    "Sic, Ephemeridem Ciceronis"
  ],
  "draugr frost cloak": [
    "Draugr Frost Cloak"
  ],
  "chain lightning": [
    "Fulmen Catenatum"
  ],
  "hall of the elements": [
    "Hall of the Elements"
  ],
  "korri's house": [
    "Korri House"
  ],
  "iron helmet of alteration": [
    "Ferri Galea Mutationis"
  ],
  "allegiance faction": [
    "Allegiance Faction"
  ],
  "burned house": [
    "Burned House"
  ],
  "penitus oculatus": [
    "Penitus Oculatus"
  ],
  "farkas": [
    "Farkas"
  ],
  "i accept your offer": [
    "Offertum tuum accipio"
  ],
  "i'd like to join the stormcloaks": [
    "Volo Sturmmantelos adiungere"
  ],
  "moorside inn": [
    "Moorside Inn"
  ],
  "thaumaturgist's hut": [
    "Thaumaturgist's Hut"
  ],
  "bounty": [
    "Vectigal"
  ],
  "the afflicted": [
    "The Afflicted"
  ],
  "afflicted": [
    "Afflictus",
    "The Afflicted"
  ],
  "abandoned house": [
    "Abandoned House"
  ],
  "markarth guard tower": [
    "Markarth Guard Tower"
  ],
  "smelter overseer's house": [
    "Smelter Overseer's House"
  ],
  "gain the augur's approval": [
    "Merere Adprobationem Auguris"
  ],
  "i need your help to defeat alduin": [
    "Auxilium tuum quaero ut Alduinum necam"
  ],
  "okay, but i'll need a few moments to cast the spell on you": [
    "Ok, sed pauca momenta mihi neccesaria sunt ut magicam in te inponam"
  ],
  "player set prisoner free (used to conditionalize packages, etc.)": [
    "Player set prisoner free (used to conditionalize packages, etc.)"
  ],
  "do you know \"the age of aggression\"": [
    "Scisne \"Aetas Aggresionis\""
  ],
  "hello, karliah": [
    "Salve, Karlia"
  ],
  "perform the ritual with valdar": [
    "Fac Religionem cum Valdar"
  ],
  "key to lod's house": [
    "Clavis ad Domum Lodis"
  ],
  "fenn's gulch mine": [
    "Fennis Gulch Mine"
  ],
  "karthwasten miner's barracks": [
    "Karthwasten Miner's Barracks"
  ],
  "pavo's house": [
    "Pavo's House"
  ],
  "burguk's longhouse": [
    "Burgukis Longhouse"
  ],
  "larak's longhouse": [
    "Larakis Longhouse"
  ],
  "i erased your debt": [
    "Aes Alienum tuum erasi"
  ],
  "you're free": [
    "Liber es"
  ],
  "warmaiden's": [
    "Warmaidenis"
  ],
  "rock warbler egg": [
    "Ovum Cantatoris Lapidum"
  ],
  "do you trust general tullius": [
    "Credisne Imperatori Tullio"
  ],
  "key to ulfberth's house": [
    "Clavis ad Domum Ulfberthis"
  ],
  "iron helmet of major restoration": [
    "Ferri Galea Maioris Reficiendi"
  ],
  "humanbeard16": [
    "HumanBeard16"
  ],
  "dwarven battleaxe of animus": [
    "Dvemeris Bellisecuris Animi"
  ],
  "a good death": [
    "Mors Bona"
  ],
  "good death": [
    "Mors Bona"
  ],
  "carlotta valentia's house": [
    "Carlotta Valentia's House"
  ],
  "drunken huntsman": [
    "Venator Ebrius",
    "Drunken Huntsman"
  ],
  "heimskr's house": [
    "Heimskr's House"
  ],
  "house gray-mane": [
    "House Gray-Mane"
  ],
  "i need training in smithing": [
    "Potesne me de arte Ferrarii docere"
  ],
  "hall of the dead": [
    "Hall of the Dead"
  ],
  "steel warhammer of scorching": [
    "Bellimalleus Adamanteus Adurendi"
  ],
  "hairmaleorc20": [
    "HairMaleOrc20"
  ],
  "whiterun stables": [
    "Whiterun Stables"
  ],
  "dialoguegenericswingmeleeweapon": [
    "DialogueGenericSwingMeleeWeapon"
  ],
  "what happened to your daughter": [
    "Quid filiae tuae accidit"
  ],
  "ulfberths house": [
    "Ulfberths House"
  ],
  "this is what you want": [
    "Hoc est quid vis"
  ],
  "to slave for others": [
    "Pro aliis laborare"
  ],
  "slave for others": [
    "Pro aliis laborare"
  ],
  "what about your own destiny": [
    "Quid de fato tuo"
  ],
  "wintersand manor": [
    "Wintersand Manor"
  ],
  "you have an elder scroll": [
    "Codicem Veterrimum habes"
  ],
  "ysolda's house": [
    "Ysolda's House"
  ],
  "dwarves, v3": [
    "Pumilliones, III"
  ],
  "rorik's manor": [
    "Rorikis Manor"
  ],
  "four shields tavern": [
    "Taberna Quattuor Scutorum"
  ],
  "anga's mill common house": [
    "Anga's Mill Common House"
  ],
  "bee and barb scene 18": [
    "Bee et Barb Scene 18"
  ],
  "you were the one presiding over the execution": [
    "Fuisti magistratus supplicii"
  ],
  "shrouded cowl maskless": [
    "Umbratus Cucullus Sine Persona"
  ],
  "arondil's journal, part 4": [
    "Ephemeris Arondilis, Pars IV"
  ],
  "yellow book of riddles": [
    "Liber Fulvus Ambagium"
  ],
  "brina's house": [
    "Brina's House"
  ],
  "irgnir's house": [
    "Irgnir's House"
  ],
  "urag suggested i come see you": [
    "Urag mihi suasit ut te visitarem"
  ],
  "dwarven dagger of consuming": [
    "Dvemeris Pugio Consumendi"
  ],
  "fruki's house": [
    "Fruki House"
  ],
  "orcish dagger of binding": [
    "Orci Pugio Deligandi"
  ],
  "whiterun carlotta brenuin scene 1": [
    "Whiterun Carlotta Brenuin Scene 1"
  ],
  "what's in this for me": [
    "Quid mihi interest"
  ],
  "horgeir's house": [
    "Horgeir's House"
  ],
  "the money's nice, but i don't know": [
    "pecuniam bona est, sed Nescio"
  ],
  "money's nice, but i don't know": [
    "pecuniam bona est, sed Nescio"
  ],
  "dragon bridge lumber camp": [
    "Dragon Bridge Lumber Camp"
  ],
  "repair services": [
    "Munera Reficiendi"
  ],
  "trilf's house": [
    "Trilf's House"
  ],
  "what was that argument outside the hall": [
    "De quo agebatur illum argumentum extra Aulam"
  ],
  "autoloading": [
    "Autoaperiens"
  ],
  "misdirection": [
    "Legerdemanus"
  ],
  "riverwood sleeping giant inn order drinks": [
    "Riverwood Sleeping Giant Inn Order Drinks"
  ],
  "imperial bow of fear": [
    "Imperialis Arcus Timoris"
  ],
  "evette san's house": [
    "Evette Sanis House"
  ],
  "castle fletcher": [
    "Castle Fletcher"
  ],
  "investigate the burned house": [
    "Investiga Domum Crematam"
  ],
  "ebony boots of dwindling frost": [
    "Caligae Ebeni Minoris Congelationis"
  ],
  "you cannot repair items while in combat": [
    "Non potes res reficere dum pugnas"
  ],
  "radiant raiments": [
    "Vestimenta Radiantias"
  ],
  "temple of the divines": [
    "Temple of the Divines"
  ],
  "daedric helmet of extreme alchemy": [
    "Galea Daedrica Extremis Alchemiae"
  ],
  "i don't understand a word of that": [
    "Nec unum verbum de eo intellego"
  ],
  "the jarl has been appeased": [
    "Comes mollificatus est"
  ],
  "jarl has been appeased": [
    "Comes mollificatus est"
  ],
  "hairmaledarkelf05": [
    "HairMaleDarkElf05"
  ],
  "falkreath cemetery": [
    "Falkreath Cemetery"
  ],
  "corpselight farm": [
    "Corpselight Farm"
  ],
  "dengeir's hall": [
    "Dengeir's Hall"
  ],
  "spriggan faction": [
    "Spriggan Faction"
  ],
  "farrfalk mansion": [
    "Farrfalk Mansion"
  ],
  "dragonplate shield of grounding": [
    "Draconilaminatum Scutum Minoris Electricitatis"
  ],
  "holy ebony sword": [
    "Sanctus Gladius Ebeni"
  ],
  "gray pine luxuries": [
    "Gray Pine Luxuries"
  ],
  "house of arkay": [
    "House of Arkay"
  ],
  "hairfemaleelder01": [
    "HairFemaleElder01"
  ],
  "butcher journal #2": [
    "Ephemeris Carnificis II"
  ],
  "j'zargo's flame cloak": [
    "Amiculum Flammarum J'zargonis"
  ],
  "aretino residence": [
    "Aretino Residence"
  ],
  "steel warhammer of fear": [
    "Bellimalleus Adamanteus Timoris"
  ],
  "iron war axe of binding": [
    "Bellisecuris Ferri Deligandi"
  ],
  "recover magicka": [
    "Recover Magicae"
  ],
  "dragonscale boots": [
    "Caligae Draconisquamatae"
  ],
  "atheron residence": [
    "Atheron Residence"
  ],
  "what types of deliveries do you make to high hrothgar": [
    "Qaules merces ad Altum Hrothgarem fers"
  ],
  "i'm not the first to come see you": [
    "Non sum primus qui te vidit"
  ],
  "aren't you worried you'll be arrested": [
    "Nonne de sollicitaris te comprehensurum esse"
  ],
  "brunwulf free-winter's house": [
    "Brunwulf Free-Winter's House"
  ],
  "execute ulfric stormcloak": [
    "Neca Vercingetorigem Temepstatamiculum"
  ],
  "clan shatter-shield offices": [
    "Clan Shatter-Shield Offices"
  ],
  "house of clan cruel-sea": [
    "House of Clan Cruel-Sea"
  ],
  "amulet of arkay": [
    "Monile Arkayae"
  ],
  "house of clan shatter-shield": [
    "House of Clan Shatter-Shield"
  ],
  "windhelm hall of the dead": [
    "Ventigalea Hall of the Dead"
  ],
  "daedric dagger of subsuming": [
    "Pugio Daedricus Subsumendi"
  ],
  "looks like deeja won't be bothering us again": [
    "Mihi videtur Deeja non iterum nos molestare"
  ],
  "audiotemplatewolf": [
    "AudioTemplateWolf"
  ],
  "steel plate helmet of major alteration": [
    "Laminata Galea Maioris Mutationis"
  ],
  "temple of talos": [
    "Temple of Talos"
  ],
  "viola giordano's house": [
    "Viola Giordano's House"
  ],
  "dagny": [
    "Dagny"
  ],
  "hairlinemalenord19": [
    "HairLineMaleNord19"
  ],
  "the bedlam job": [
    "Munus \"Bedlam\""
  ],
  "bedlam job": [
    "Munus \"Bedlam\""
  ],
  "strange amulet": [
    "Mirabile Monile"
  ],
  "creaturedialoguehagraven": [
    "CreatureDialogueHagraven"
  ],
  "the white phial": [
    "The White Phial"
  ],
  "white phial": [
    "The White Phial"
  ],
  "is there anything else i should know": [
    "Estne quid aliud quod mihi sciendum est"
  ],
  "stoking the flames": [
    "Flammas Augens"
  ],
  "dragonplate shield of frost abatement": [
    "Draconilaminatum Scutum Gelidus Resistendi"
  ],
  "grey": [
    "Grey"
  ],
  "crossinghouse": [
    "Crossinghouse"
  ],
  "the serpent stone": [
    "Lapis Serpentis"
  ],
  "serpent stone": [
    "Lapis Serpentis"
  ],
  "dragonplate shield of shock abatement": [
    "Draconilaminatum Scutum Contra Vim Electricam"
  ],
  "hysteria - master of the mind": [
    "Hysteria - Dominus Mentis"
  ],
  "gilfre's house": [
    "Gilfre's House"
  ],
  "worker's house": [
    "Worker's House"
  ],
  "astrid sent me for another contract": [
    "Astrid mihi misit pro aliis foederibus"
  ],
  "bee and barb": [
    "Bee and Barb",
    "Apis et Hamus"
  ],
  "east empire office key": [
    "Clavis ad Officinam Euri Imperii"
  ],
  "glass greatsword of evoking": [
    "Spatha Vitrea Evocandi"
  ],
  "bolli's house": [
    "Bolli House"
  ],
  "remind me... who are the thalmor": [
    "Commemora mihi iterum... qui sunt Visigothi"
  ],
  "house of clan snow-shod": [
    "House of Clan Snow-Shod"
  ],
  "ebony war axe of blizzards": [
    "Bellisecuris Ebeni Hiemis"
  ],
  "gul": [
    "Gul"
  ],
  "the pawned prawn": [
    "The Pawned Prawn"
  ],
  "fade other": [
    "Fade Other"
  ],
  "korvanjund crypt": [
    "Korvanjund Hypogaeum"
  ],
  "riften stables": [
    "Hiati Stables"
  ],
  "is markarth prepared to deal with dragons": [
    "Estne Markartia pro draconibus parata"
  ],
  "no way": [
    "Minime"
  ],
  "it was wrong to do those things": [
    "Nefas erat illas res facere"
  ],
  "thieves guild headquarters": [
    "Sodalitatis Furum Headquarters"
  ],
  "riften warehouse": [
    "Hiati Warehouse"
  ],
  "dwarven greatsword of animus": [
    "Dvemeris Spatha Animi"
  ],
  "fall of the snow prince": [
    "Casus Principis Nivis"
  ],
  "ebony war axe": [
    "Bellisecuris Ebeni"
  ],
  "ivarstead vilemyr inn": [
    "Ivarstead Vilemyr Inn"
  ],
  "filnjar's house": [
    "Filnjar's House"
  ],
  "shrine of mehrunes dagon": [
    "Shrine of Mehrunes Dagon"
  ],
  "the midden": [
    "The Midden",
    "Sterculinium"
  ],
  "midden": [
    "The Midden",
    "Sterculinium"
  ],
  "rockwallow mine": [
    "Rockwallow Mine"
  ],
  "herbane's bestiary: ice wraiths": [
    "Bestiarium Herbanis: Phantasmae Glaciei"
  ],
  "daedric sword of subsuming": [
    "Gladius Daedricus Subsumendi"
  ],
  "dark brotherhood sanctuary": [
    "Fanum Fraternitatis Obscurae"
  ],
  "midden torch": [
    "Midden Torch"
  ],
  "shor's stone guard": [
    "Custos Lapidis Shoris"
  ],
  "kill the leader of <alias=dungeon>": [
    "Neca Ducem in <Alias=Dungeon>"
  ],
  "iron gauntlets of minor alchemy": [
    "Ferri Manicae Minoris Alchemiae"
  ],
  "why set up shop in morthal": [
    "Cur tabernam Morthali posuisi"
  ],
  "elven war axe of consuming": [
    "Alfus Bellisecuris Consumendi"
  ],
  "imperial bracers of wielding": [
    "Chirothecae Romanae Tenendi"
  ],
  "orcish boots of frost suppression": [
    "Orci Caligae Contra Glaciem"
  ],
  "hunter's discipline": [
    "Disciplina Venatoris"
  ],
  "chillfurrow farm interior": [
    "Chillfurrow Farm Interior"
  ],
  "i want to fight the empire": [
    "Volo contra Imperium pugnare"
  ],
  "i'll bleed for the chance to do so": [
    "Sanguinem dabo ut id faciam"
  ],
  "largashbur's longhouse": [
    "Largashbur's Longhouse"
  ],
  "r1n": [
    "R1n"
  ],
  "hallowed ebony greatsword": [
    "Consacrata Spatha Ebeni"
  ],
  "zaynabi": [
    "Zaynabi"
  ],
  "loreius farmhouse": [
    "Loreius Farmhouse"
  ],
  "honningbrew meadery": [
    "Honningbrew Meadery"
  ],
  "how is all this affecting the guild": [
    "Quid facit haec omnia ad Sodalitatem"
  ],
  "east empire warehouse": [
    "East Empire Warehouse"
  ],
  "when do i get out": [
    "Quando exibo"
  ],
  "aeri's house": [
    "Aeri House"
  ],
  "deekus camp": [
    "Deekus Camp"
  ],
  "report to astrid": [
    "Refer te ad Astridem"
  ],
  "dead man's drink": [
    "Dead Manis Drink"
  ],
  "the lost man's reprieve": [
    "The Lost Manis Reprieve"
  ],
  "lost man's reprieve": [
    "The Lost Manis Reprieve"
  ],
  "brinewater grotto": [
    "Brinewater Specus"
  ],
  "elven greatsword of torpor": [
    "Spatha Alfa Torporis"
  ],
  "here are the books that went missing": [
    "Hic sunt libri amissi"
  ],
  "watchman's hut": [
    "Watchmanis Hut"
  ],
  "orcish gauntlets of eminent sure grip": [
    "Orci Manicae Eminentis Dexteritatis"
  ],
  "he still needs to pay for his crimes": [
    "Ei iam poenas solvendas sunt"
  ],
  "shroud hearth barrow": [
    "Shroud Hearth Barrow"
  ],
  "femaleheadbreton": [
    "FemaleHeadBreton"
  ],
  "who was jurgen windcaller": [
    "Quis erat Jurgen Ventivocatoris"
  ],
  "cragwallow slope": [
    "Cragwallow Slope"
  ],
  "defeat the dragon priest": [
    "Vince Sacerdotem Draconis"
  ],
  "master retort": [
    "Master Retort"
  ],
  "castle dour, emperor's tower": [
    "Castle Dour, Emperor's Tower"
  ],
  "warehouse": [
    "Horreum"
  ],
  "magelight": [
    "Magilux"
  ],
  "bronze water cave": [
    "Spelunca Aquae Aeneae"
  ],
  "reporting for duty": [
    "Me refero pro munere"
  ],
  "hide boots of waning frost": [
    "Renonis Caligae Insenescentis Congelationis"
  ],
  "marise aravel's house": [
    "Marise Aravel's House"
  ],
  "destruction mage": [
    "Destruction Mage"
  ],
  "romlyn dreth's house": [
    "Romlyn Dreth's House"
  ],
  "riften jail": [
    "Hiati Jail"
  ],
  "so you're a healer": [
    "Ergo doctor es"
  ],
  "the katariah": [
    "The Katariah"
  ],
  "katariah": [
    "The Katariah"
  ],
  "ebony mace of harvesting": [
    "Maca Ebeni Saturni"
  ],
  "beggar's row": [
    "Beggar's Row"
  ],
  "scoundrel's folly stealth scene": [
    "Scoundrel's Folly Stealth Scene"
  ],
  "reunification of skyrim": [
    "Reunification of Skyrim"
  ],
  "daedric greatsword of damnation": [
    "Spatha Daedrica Damnationis"
  ],
  "dwarven sword of siphoning": [
    "Gladius Dvemeris Siphonis"
  ],
  "elven war axe": [
    "Alfus Bellisecuris"
  ],
  "2920, midyear, v6": [
    "[MMCMXX] 2920, Medianno, V. VI"
  ],
  "severio pelagias' house": [
    "Severio Pelagias' House"
  ],
  "ebony mace of blizzards": [
    "Maca Ebeni Hiemis"
  ],
  "kolskeggr mine is clear of forsworn": [
    "Metallum Kolskeggr Mine mundum est Abiuratorum"
  ],
  "knifepoint ridge": [
    "Knifepoint Ridge"
  ],
  "roadside ruins": [
    "Roadside Ruins"
  ],
  "peak's shade tower": [
    "Peakis Shade Tower"
  ],
  "winking skeever conversation 2": [
    "Winking Skeever Conversation 2"
  ],
  "crossroads watchtower": [
    "Crossroads Watchtower"
  ],
  "north skybound watch": [
    "North Skybound Watch"
  ],
  "steel war axe of torpor": [
    "Bellisecuris Adamanteus Torporis"
  ],
  "greywater gorge": [
    "Greywater Gorge"
  ],
  "put in this faction to get wi follower comments": [
    "Put in this faction to get WI follower comments"
  ],
  "so what was your suggestion again": [
    "Ergo quid mihi suadebas"
  ],
  "i need to learn the shout used to defeat alduin": [
    "Opus mihi est clamorem qua Alduinus victus est discere"
  ],
  "hairfemaleorc12": [
    "HairFemaleOrc12"
  ],
  "whiterun jail": [
    "Whiterun Jail"
  ],
  "giant's grove": [
    "Giant's Grove"
  ],
  "markarth stables": [
    "Markarth Stables"
  ],
  "dormitory": [
    "Dormitory"
  ],
  "do you want to poison your next shot with the": [
    "Visne venenum imponere in"
  ],
  "arch mage's quarters": [
    "Arch Mage's Quarters"
  ],
  "where do you sell your surplus crops": [
    "Ubi frumentum superfluum vendis"
  ],
  "custom fit": [
    "Consonantia Vestimentorum"
  ],
  "the code of malacath": [
    "Ius Stygis"
  ],
  "code of malacath": [
    "Ius Stygis"
  ],
  "there must be something i can do": [
    "Aliquid facere debeo"
  ],
  "spell tome: soul trap": [
    "Liber Magicus: Animicaptum"
  ],
  "what kinds of things should i bring": [
    "Quales res trahere debeo"
  ],
  "leather helmet of destruction": [
    "Cutis Galea Destructionis"
  ],
  "dragonplate helmet of extreme archery": [
    "Draconilaminata Galea Extremis Saggitationis"
  ],
  "jeweled amulet": [
    "Gemmatum Monile"
  ],
  "rkund": [
    "Rkund"
  ],
  "i'm just here for the books you took from the college": [
    "Modo hic sum pro libris e Collegio"
  ],
  "shor's watchtower": [
    "Shor's Watchtower"
  ],
  "daedric mace of animus": [
    "Maca Daedrica Animi"
  ],
  "alchemist's shack": [
    "Alchemist's Shack"
  ],
  "the thief doomstone": [
    "The Thief Doomstone"
  ],
  "thief doomstone": [
    "The Thief Doomstone"
  ],
  "the apprentice doomstone": [
    "The Apprentice Doomstone"
  ],
  "apprentice doomstone": [
    "The Apprentice Doomstone"
  ],
  "ebony helmet of extreme conjuring": [
    "Ebeni Galea Maiorum Vocantium"
  ],
  "report: disaster at ionith": [
    "Nuntius: Casus apud Ionith"
  ],
  "the atronach doomstone": [
    "The Atronach Doomstone"
  ],
  "atronach doomstone": [
    "The Atronach Doomstone"
  ],
  "the lady doomstone": [
    "The Lady Doomstone"
  ],
  "lady doomstone": [
    "The Lady Doomstone"
  ],
  "the lord doomstone": [
    "The Lord Doomstone"
  ],
  "lord doomstone": [
    "The Lord Doomstone"
  ],
  "aicantar": [
    "Aicantar"
  ],
  "the lover doomstone": [
    "The Lover Doomstone"
  ],
  "lover doomstone": [
    "The Lover Doomstone"
  ],
  "iron sword of sapping": [
    "Gladius Ferri Hauriendi"
  ],
  "your fine's been paid. now tell me about kematu": [
    "Vectigal tuum solutum est. Nunc mihi de Kematu narra"
  ],
  "reverent glass mace": [
    "Reverens Maca Vitrea"
  ],
  "the mage doomstone": [
    "The Mage Doomstone"
  ],
  "mage doomstone": [
    "The Mage Doomstone"
  ],
  "ebony battleaxe of lethargy": [
    "Bellisecuris Ebeni Lethargiae"
  ],
  "the ritual doomstone": [
    "The Ritual Doomstone"
  ],
  "ritual doomstone": [
    "The Ritual Doomstone"
  ],
  "edda": [
    "Edda"
  ],
  "the shadow doomstone": [
    "The Shadow Doomstone"
  ],
  "shadow doomstone": [
    "The Shadow Doomstone"
  ],
  "the steed doomstone": [
    "The Steed Doomstone"
  ],
  "steed doomstone": [
    "The Steed Doomstone"
  ],
  "western watchtower": [
    "Western Watchtower"
  ],
  "red eagle tower key": [
    "Clavis ad Turrem Aquilae Rubrae"
  ],
  "how about falkreath": [
    "Quid de Falkretia"
  ],
  "when can i meet your leader, paarthurnax": [
    "Quando duci tuo, Paarthurnaci obviam ire possum"
  ],
  "lund's hut": [
    "Lund's Hut"
  ],
  "crab shack": [
    "Crab Shack"
  ],
  "drelas cottage": [
    "Drelas Cottage"
  ],
  "calcelmo's laboratory": [
    "Calcelmo's Laboratory"
  ],
  "hairlinemaledremora01": [
    "HairLineMaleDremora01"
  ],
  "e3 bleak falls barrow": [
    "E3 Bleak Falls Barrow"
  ],
  "orcish warhammer of cowardice": [
    "Orci Bellimalleus Timoris"
  ],
  "no usable spare parts": [
    "NO USABLE SPARE PARTS"
  ],
  "femaleeyeshumangreenhazel": [
    "FemaleEyesHumanGreenHazel"
  ],
  "dialogue faction for wndhelm guards": [
    "Dialogue Faction for Wndhelm Guards"
  ],
  "i feel sick": [
    "Aeger sentio"
  ],
  "daedric warhammer of debilitation": [
    "Bellimalleus Daedricus Debilitationis"
  ],
  "hairlinefemaleelder01": [
    "HairLineFemaleElder01"
  ],
  "four skull lookout": [
    "Four Skull Lookout"
  ],
  "butterfly wing": [
    "Ala Papilionis"
  ],
  "what's your problem with sven": [
    "Quid est difficultas tua cum Sven"
  ],
  "phinis gestor services": [
    "Phinis Gestor Services"
  ],
  "contract: kill helvard": [
    "Foedus: Neca Helvard"
  ],
  "how about now": [
    "Quid ni nunc"
  ],
  "i'll come back later, then": [
    "Postea reveniam"
  ],
  "maleeyeswoodelfdeepviolet": [
    "MaleEyesWoodElfDeepViolet"
  ],
  "iron helmet of minor destruction": [
    "Ferri Galea Minoris Destructionis"
  ],
  "humanbeard30": [
    "HumanBeard30"
  ],
  "kjenstag ruins": [
    "Kjenstag Ruins"
  ],
  "wayward pass": [
    "Wayward Pass"
  ],
  "blizzard 100": [
    "Blizzard 100"
  ],
  "glass sword of depleting": [
    "Gladius Vitreus Deplendi"
  ],
  "liberate hjaalmarch": [
    "Libera Hjaalmarcem"
  ],
  "masque of clavicus vile": [
    "Persona Clavici"
  ],
  "by the right of birth": [
    "Ius natus"
  ],
  "i am dragonborn": [
    "Draconigena sum"
  ],
  "tell me about talos": [
    "Dic mihi de Talos"
  ],
  "hallowed orcish battleaxe": [
    "Consacratus Bellisecuris Orcorum"
  ],
  "one-handed": [
    "Singuli-Manu"
  ],
  "trouble in skyrim": [
    "Difficultas in Caelifine"
  ],
  "two-handed": [
    "Ambidexteritas"
  ],
  "archery": [
    "Sagittatio"
  ],
  "ebony war axe of the blaze": [
    "Bellisecuris Ebeni Congflagrantis"
  ],
  "dragonplate shield of the firewalker": [
    "Draconilaminatum Scutum Ignambulatoris"
  ],
  "ebony battleaxe of depleting": [
    "Bellisecuris Ebeni Deplendi"
  ],
  "whiterun olfina jon scene 3": [
    "Whiterun Olfina Jon Scene 3"
  ],
  "save failed": [
    "Tutum Frustratum"
  ],
  "ysolda's message": [
    "Epistula Ysoldae"
  ],
  "they're as good as dead": [
    "Ut mortui iam sunt"
  ],
  "iron battleaxe of fear": [
    "Bellisecuris Ferri Timoris"
  ],
  "iron warhammer of draining": [
    "Bellimalleus Ferratus Hauriendi"
  ],
  "glass bow of diminishing": [
    "Arcus Vitreus Diminuendi"
  ],
  "grand plaza scene 34": [
    "Grand Plaza Scene 34"
  ],
  "i understand you have visions": [
    "Intellego te visiones habere"
  ],
  "detect life friend exterior": [
    "Detect Life Friend Exterior"
  ],
  "daedric mace of malediction": [
    "Maca Daedrica Maledictionis"
  ],
  "lightning bolt": [
    "Fulmen"
  ],
  "malborn has a message for you": [
    "Malborn nuntium tibi habet"
  ],
  "solution of regeneration": [
    "Solutio Recuperandi"
  ],
  "ursine guardian": [
    "Custos Ursinus"
  ],
  "luna moth": [
    "Papilionem Lunae"
  ],
  "sneak": [
    "Repere"
  ],
  "save on wait": [
    "Tueri Dum Pauses"
  ],
  "let's talk about how we can help each other": [
    "Loquemur de modo in quo inter nos adiuturum esse"
  ],
  "a curse": [
    "Maledictum"
  ],
  "what can we do about it": [
    "Quid de eo facere possumus"
  ],
  "clairvoyance": [
    "Divinatio"
  ],
  "i'm not going to answer that": [
    "Responsum ei tibi non dabo"
  ],
  "why can't i be all three": [
    "Quidni omnes tres esse possim"
  ],
  "can't you reason with her": [
    "Num cum ea ratiocinare potes"
  ],
  "fortify destruction": [
    "Adde ad Destructionem"
  ],
  "you have the poison": [
    "Habesne venenum"
  ],
  "illusion": [
    "ILLUSIO",
    "Inlusio"
  ],
  "lost echo cave": [
    "Spelunca Echi Perditi"
  ],
  "atar": [
    "Atar"
  ],
  "then nocturnal's angry at us": [
    "Ergo Nocturnal nobiscum irascitur"
  ],
  "how long have you been a steward": [
    "Quamdiu Dispensator fuisti"
  ],
  "glass helmet of extreme archery": [
    "Galea Vitrea Extremis Saggitationis"
  ],
  "children of the sky": [
    "Filii Caeli"
  ],
  "penitus oculatus faction": [
    "Penitus Oculatus Faction"
  ],
  "but i": [
    "Sed ego"
  ],
  "can you train me in alteration magic": [
    "Potesne mihi de magica Mutationis docere"
  ],
  "resist fire": [
    "Resiste Igni"
  ],
  "resist shock": [
    "Resiste Electricitati"
  ],
  "so, what could a dog need help with": [
    "Ergo, cum quo possit canis auxilium quaerere"
  ],
  "fallowstone cave": [
    "Spelunca Lapidis Novalis"
  ],
  "hired thug": [
    "Opera"
  ],
  "cowflop farm key": [
    "Clavis ad Fundum Vaccalapsum"
  ],
  "viarmo said you could tell me about the poetic edda": [
    "Viarmo mihi dixit te de Edda Poetica mihi narrare posse"
  ],
  "i... no, i can't see you": [
    "Ego... Minime, te videre non possum"
  ],
  "i presume this <alias=loot> belongs to you": [
    "Credo hoc <Alias=Loot> tibi esse"
  ],
  "is there something else": [
    "Estne quid aliud"
  ],
  "there are nine holds in skyrim: eastmarch, falkreath, haafingar, hjaalmarch, the pale, the reach, the rift, whiterun, and winterhold": [
    "Novem regiones sunt in Caelifine: Eurimarcum, Falkreath, Haafingar, Hjaalmarch, Pale, Reach, Rift, Whiterun, et Hiemiterrae"
  ],
  "elven greatsword of binding": [
    "Spatha Alfa Deligandi"
  ],
  "then why don't you remove it": [
    "Ergo quidni id removeas"
  ],
  "aren't you a daedra": [
    "Nonne daedra es"
  ],
  "speak to viarmo": [
    "Adloquere Viarmonem"
  ],
  "the dwarves of tamriel disappeared millennia ago and are presumed extinct": [
    "Dwemeris Tamrielis abhinc multos annos disapparuerunt et extinctos esse putantur"
  ],
  "dwarves of tamriel disappeared millennia ago and are presumed extinct": [
    "Dwemeris Tamrielis abhinc multos annos disapparuerunt et extinctos esse putantur"
  ],
  "i'll just take the sap": [
    "Olibanum capiam"
  ],
  "danica was very insistent on having it": [
    "Danica perseverabat ut eam haberet"
  ],
  "potent magicka poison": [
    "Potens Venenum Magicae"
  ],
  "blue palace": [
    "Caeruleua Aula",
    "The Blue Palace"
  ],
  "i heard your home burned down": [
    "Audivi domum tuam deflagrare"
  ],
  "deck of the brinehammer": [
    "Summum Mallei Salsuginis"
  ],
  "how about i kill you instead": [
    "Quidni te necam"
  ],
  "a mace": [
    "Maca"
  ],
  "mace": [
    "Maca"
  ],
  "this is hardly a fitting prize": [
    "Haec non est praemium dignum"
  ],
  "bthardamz upper district": [
    "Bthardamz Regio Superior"
  ],
  "bolfrida brandy-mug": [
    "Bolfrida Vini-Poculum"
  ],
  "malemouthdremora": [
    "MaleMouthDremora"
  ],
  "staubin": [
    "Staubin"
  ],
  "i rescued <alias=victim>": [
    "Servavi <Alias=Victim>"
  ],
  "assist the people of the pale (<global=friendscountpale>/<global=friendstotalminorhold>)": [
    "Adiuva incolas Pallidi (<Global=FriendsCountPale>/<Global=FriendsTotalMinorHold>)"
  ],
  "if there's more gold where that came from, i'm in": [
    "Si plus auri est, id faciam"
  ],
  "imperial boots of waning shock": [
    "Caligae Romanae Insenescentis Electricitatis"
  ],
  "you don't look that old to me": [
    "Non tam vetus mihi videris"
  ],
  "certainly you're still a strong, capable warrior": [
    "Certe bellator iam fortis et capax es"
  ],
  "daedric warhammer of dread": [
    "Bellimalleus Daedricus Timoris"
  ],
  "marksfemaleargonianscar01": [
    "MarksFemaleArgonianScar01"
  ],
  "ragged flagon scene 01": [
    "Ragged Flagon Scene 01"
  ],
  "here's the gold": [
    "Hic aurum habes"
  ],
  "skyforge steel battleaxe": [
    "Fabricaeli Bellisecuris Adamanteus"
  ],
  "speak with urag gro-shub": [
    "Adloquere Urag gro-Shub"
  ],
  "have you heard of a pirate crew called the blood horkers": [
    "Audivistine de grege piratorum nomine Horker Sanguinis"
  ],
  "what should i do now": [
    "Quid nunc faciam"
  ],
  "ebony gauntlets of extreme alchemy": [
    "Ebeni Manicae Extremis Alchemiae"
  ],
  "pale guard's helmet": [
    "Pallidi Custodis Galea"
  ],
  "scaled helmet of eminent restoration": [
    "Galea Squamata Eminentis Reficiendi"
  ],
  "we can protect ourselves": [
    "Nos defendere possumus"
  ],
  "pot": [
    "Caccabus"
  ],
  "you're hurt": [
    "Vulneratus es"
  ],
  "hairfemaleorc13": [
    "HairFemaleOrc13"
  ],
  "elven mace of scorching": [
    "Maca Alfa Adurendi"
  ],
  "daedric greatsword of exhaustion": [
    "Spatha Daedrica Exhaustionis"
  ],
  "hallowed orcish warhammer": [
    "Consacratus Bellimalleus Orcorum"
  ],
  "reach stormcloak camp": [
    "Prehensi Sturmmantelorum Castra"
  ],
  "steal the argonian ale": [
    "Furare Hydromellum Lacertum"
  ],
  "the lover stone": [
    "Lapis Amatoris"
  ],
  "lover stone": [
    "Lapis Amatoris"
  ],
  "daedric mace of thunderbolts": [
    "Maca Daedrica Fulminum"
  ],
  "let's do the fire festival tonight": [
    "Faciemus festum hac nocte"
  ],
  "how well do you know elenwen": [
    "Quam bene Elenven novisti"
  ],
  "you cannot fast travel when enemies are nearby": [
    "Non potes celeriter peregrinari dum inimici prope sunt"
  ],
  "astrid wanted me to see you about some more work": [
    "Astrid me tibi misit pro labore"
  ],
  "scroll of guardian circle": [
    "Codex Circuli Custodum"
  ],
  "tell me about this incense": [
    "Dic mihi de hoc incenso"
  ],
  "virtuous ebony battleaxe": [
    "Virtutis Bellisecuris Ebeni"
  ],
  "testjeffbcarrywaterbucket": [
    "TestJeffBCarryWaterBucket"
  ],
  "marksfemalehumanoid08rightgash": [
    "MarksFemaleHumanoid08RightGash"
  ],
  "faction for cw soldiers (excludes guard dialogue)": [
    "Faction for CW soldiers (excludes guard dialogue)"
  ],
  "(optional) kill nilsine shatter-shield": [
    "(Optio) Neca Nilsine Scutum-Fractum"
  ],
  "orcish battleaxe of debilitation": [
    "Orci Bellisecuris Debilitationis"
  ],
  "<font color='#ff0000'>pickpocket</font>": [
    "<font color='#FF0000'>Furare</font>"
  ],
  "tell me about the shill jobs": [
    "Narra mihi de munera \"shill.\""
  ],
  "i was set free": [
    "Liberatus sum",
    "Libetatus sum"
  ],
  "i could've gone anywhere": [
    "Quovis ire potui"
  ],
  "i came here to fight the empire": [
    "Hic veni ut contra Imperium pugnare possem"
  ],
  "ebony boots of strength": [
    "Caligae Ebeni Fortitudinis"
  ],
  "may i receive her blessings": [
    "Possumne beneficia eius accipere"
  ],
  "dwarven bracers of major archery": [
    "Dvemeres Chirothecae Maioris Sagittationis"
  ],
  "tell me what this gem's worth": [
    "Dic mihi quot haec gemma constat"
  ],
  "why has she returned": [
    "Quamobrem revenit"
  ],
  "ebony bow of terror": [
    "Arcus Ebeni Terroris"
  ],
  "no one tells me what to do": [
    "Nemo mihi imperat"
  ],
  "imperial soldier": [
    "Imperialis Miles"
  ],
  "humanbeard08": [
    "HumanBeard08"
  ],
  "daedric warhammer of animus": [
    "Bellimalleus Daedricus Animi"
  ],
  "aevar stone-singer": [
    "Aevar Lapi-Cantator"
  ],
  "sweep": [
    "Verre",
    "verrō, verrere, verrī, versum"
  ],
  "maleeyesorcred": [
    "MaleEyesOrcRed"
  ],
  "daedric boots of the firewalker": [
    "Caligae Daedricae Ignambulatoris"
  ],
  "paralyzing strike": [
    "Ictus Paralyticus"
  ],
  "generic crime dialogue": [
    "Generic crime dialogue"
  ],
  "bergritte battle-born": [
    "Bergritte Proeli-Nata"
  ],
  "daedric battleaxe of debilitation": [
    "Bellisecuris Daedricus Debilitationis"
  ],
  "i interrupted their ritual": [
    "Interrupi reliogionem eorum"
  ],
  "arniel gane": [
    "Arniel Gane"
  ],
  "vigilance": [
    "Vigil"
  ],
  "imperial bracers of major wielding": [
    "Chirothecae Romanae Maioris Tenendi"
  ],
  "hitting the books": [
    "Libros Oppugnans"
  ],
  "imperial helmet of illusion": [
    "Romana Galea Inlusionis"
  ],
  "mirabelle ervine": [
    "Mirabella Ervina"
  ],
  "range of movement": [
    "Distantia Movimenti"
  ],
  "forsworn attacking soldiers, imperial": [
    "Forsworn Attacking Soldiers, Imperialis"
  ],
  "find out what's wrong with nelkir": [
    "Disce quid cum Nelkir agi"
  ],
  "assassinations don't come cheap, boy": [
    "Necationes viles non sunt"
  ],
  "unhindered": [
    "Inencumbratus"
  ],
  "refugee": [
    "Profugus"
  ],
  "two people spending time together never blossoms into courtship": [
    "Duo homines tempus iuxti consumentes numquam in amorem veniunt"
  ],
  "glass battleaxe of binding": [
    "Bellisecuris Vitreus Deligandi"
  ],
  "i have the elder scroll": [
    "Codicem Veterrimum habeo"
  ],
  "there's something you could do for me": [
    "Aliquid est quod pro me facere potes"
  ],
  "hairlinemaledarkelf02": [
    "HairLineMaleDarkElf02"
  ],
  "humanbeardlong11_1bit": [
    "HumanBeardLong11_1bit"
  ],
  "meet with <alias=general>": [
    "Adiungere imperatorem <Alias=General>"
  ],
  "so what's the problem": [
    "Ergo quid est difficultas"
  ],
  "i'm sure he'll pay you": [
    "Pro certe tibi praemium dignum det"
  ],
  "quicksave": [
    "Celertueri"
  ],
  "degaine": [
    "Degaine"
  ],
  "battle-born farm": [
    "Battle-Born Farm"
  ],
  "it's true": [
    "Ita vero"
  ],
  "he told me": [
    "Mihi narravit"
  ],
  "what would you ask of me": [
    "Quid de me roges"
  ],
  "what's the imperial legion doing in skyrim": [
    "Quid facit Legio Romanus in Caelifine"
  ],
  "orcish war axe of harrowing": [
    "Orci Bellisecuris Occationis"
  ],
  "dwarven armor of the knight": [
    "Dvemeris Lorica Equitis"
  ],
  "animage": [
    "Animagus"
  ],
  "kindred mage": [
    "Magus Amicus"
  ],
  "who are the forsworn": [
    "Qui sunt Abiurati"
  ],
  "fire mage": [
    "Ignimagus"
  ],
  "gemma uriel": [
    "Gemma Uriel"
  ],
  "i'm dragonborn": [
    "Draconigena sum"
  ],
  "it's my destiny to stop him": [
    "Fatum meum est eum superare"
  ],
  "servicerefusal": [
    "ServiceRefusal"
  ],
  "angarvunde": [
    "Angarvunde"
  ],
  "frost mastery": [
    "Dominus Glaciei"
  ],
  "lightning mage": [
    "Electrimagus"
  ],
  "ennodius papius": [
    "Ennodius Papius"
  ],
  "storm mastery": [
    "Magister Tempestatis"
  ],
  "jade and sapphire circlet": [
    "Venificae et Sapphiri Circulum"
  ],
  "markarth city guard": [
    "Custos Markartiae"
  ],
  "all enchanted armor": [
    "All Enchanted Armor"
  ],
  "why was torygg killed": [
    "Cur Torygg necatus est"
  ],
  "styrr": [
    "Styrr"
  ],
  "the dark brotherhood will return to greatness. with my help": [
    "Fraternitas Obscura meo auxilio ad magnificentiam revieniet"
  ],
  "dark brotherhood will return to greatness. with my help": [
    "Fraternitas Obscura meo auxilio ad magnificentiam revieniet"
  ],
  "sabine's footlocker": [
    "Arca Pedum Sabinis"
  ],
  "all i've got": [
    "Omnia quae habeo"
  ],
  "spell tome: grand healing": [
    "Liber Magicus: Salus Magna"
  ],
  "iron helmet of conjuring": [
    "Ferri Galea Vocandi"
  ],
  "mammoth": [
    "Elephas"
  ],
  "you have your tower back": [
    "Turrem tuam iterum habes"
  ],
  "i expect a reward": [
    "Praemium exspecto"
  ],
  "flame thrall": [
    "Servus Flammarum"
  ],
  "sure, i'll take the book": [
    "Certe, librum accipiam"
  ],
  "i imagine current events are keeping you busy": [
    "Nonne nuntia recente te negotiosum faciunt"
  ],
  "delvin mallory said you have work for me": [
    "Delvin Mallory dixit te munus pro me habere"
  ],
  "leather bracers of alchemy": [
    "Corii Chirothecae Alchemiae"
  ],
  "deadly stamina poison": [
    "Letifer Venenum Staminae"
  ],
  "and you want me to find the wardstone": [
    "Et vis me custodilapidem invenire"
  ],
  "dragonrend": [
    "Dragonicaesus"
  ],
  "regeneration": [
    "Recuperare"
  ],
  "enchanter's primer": [
    "Lectionarium Incantatoris"
  ],
  "you miss traveling": [
    "Peregrinare desideras"
  ],
  "the blue palace": [
    "The Blue Palace"
  ],
  "daedric greatsword of the vampire": [
    "Spatha Daedrica Vampiri"
  ],
  "night thief": [
    "Fur Noctis"
  ],
  "ustengrav": [
    "Ustengrav"
  ],
  "cutpurse": [
    "Saccisector"
  ],
  "deliver the purchase agreement to kleppr in markarth": [
    "Redde Consensum Emendi ad Kleppr Markartiae"
  ],
  "nice place you have here": [
    "Locum amoenum hic habes"
  ],
  "locksmith": [
    "Clavicarius"
  ],
  "studded imperial armor of the minor squire": [
    "Romana Lorica Ornata Minoris Armigeris"
  ],
  "golden touch": [
    "Tactus Aureus"
  ],
  "i knew it": [
    "Id scivi"
  ],
  "you're a liar": [
    "Mendax es"
  ],
  "redoran's retreat": [
    "Redoranis Refugium"
  ],
  "dwarven battleaxe of binding": [
    "Dvemeris Bellisecuris Deligandi"
  ],
  "hairlinemaleorc08": [
    "HairLineMaleOrc08"
  ],
  "uncommon taste": [
    "De Re Coquinaria"
  ],
  "shadow warrior": [
    "Bellator Umbrosus"
  ],
  "glass mace of the blaze": [
    "Maca Vitrea Congflagrantis"
  ],
  "find your reward": [
    "Inveni Praemium Tuum"
  ],
  "poisoner": [
    "Venificus"
  ],
  "improved remedy": [
    "Remedia Meliora"
  ],
  "dawnstar guard": [
    "Custos Luciferis"
  ],
  "catalyst": [
    "Katalysis"
  ],
  "philter of the defender": [
    "Philtrum Defensoris"
  ],
  "valthume vestibule": [
    "Valthumis Vestibulum"
  ],
  "hrolfdir's shield": [
    "Hrolfdiris Scutum"
  ],
  "how do you know so much about mead": [
    "Quomodo tantum de hydromello scis"
  ],
  "you must select a perk": [
    "Opus est tibi abilitatem eligere"
  ],
  "then you'll pay in more than just coin": [
    "Ergo plus quam peucuniam solves"
  ],
  "hallowed elven mace": [
    "Consacrata Maca Alfa"
  ],
  "imperial light helmet of minor destruction": [
    "Romana Cassis Minoris Destructionis"
  ],
  "i have returned with shagrol's hammer": [
    "Reveni cum malleo Shagrolis"
  ],
  "clan shatter-shield office key": [
    "Officina Gentis Scuti-Fracti"
  ],
  "storm thrall": [
    "Servus Tempestatis"
  ],
  "tell dengeir that vighar is dead": [
    "Dengeirem Narra Vigharem Mortuum Esse"
  ],
  "dialoguedawnstarwhitehallskaldmadena02": [
    "DialogueDawnstarWhiteHallSkaldMadena02"
  ],
  "the imperials kept control of <alias=hold>": [
    "Romani <Alias=Hold> iam tenent"
  ],
  "imperials kept control of <alias=hold>": [
    "Romani <Alias=Hold> iam tenent"
  ],
  "steel boots of resist frost": [
    "Caligae Adamanteae Contra Vim Gelidam"
  ],
  "i have azura's star": [
    "Stellam Azurae habeo"
  ],
  "elven helmet of restoration": [
    "Alfa Galea Recreandi"
  ],
  "torture chamber": [
    "Camera Torquendi"
  ],
  "(5,000 gold)": [
    "(MMMMM auri)",
    "(MMMMMM auri)"
  ],
  "moonstone ore vein": [
    "Venam Metallis Lunalapidis"
  ],
  "how did you meet him": [
    "Quomodo ei occuristi"
  ],
  "speak with quaranir": [
    "Adloquere Quaranir"
  ],
  "orcish helmet of extreme restoration": [
    "Orci Galea Extremis Reficiendi"
  ],
  "tower of strength": [
    "Turris Fortitudinis"
  ],
  "glass helmet of extreme alteration": [
    "Galea Vitrea Extremis Mutationis"
  ],
  "conditioning": [
    "Habitudo"
  ],
  "great council faction": [
    "Great Council Faction"
  ],
  "borri": [
    "Borri"
  ],
  "is this unusual gem worth anything": [
    "Quot constat haec gemma"
  ],
  "well fitted": [
    "Lorica Bene Adapta"
  ],
  "philter of the berserker": [
    "Philtrum Berserkris"
  ],
  "what would the thalmor want with esbern": [
    "Quid cum Esberne volunt Visigothi"
  ],
  "glass bow of scorching": [
    "Arcus Vitreus Adurendi"
  ],
  "yet very fortunate for maven": [
    "Sed fortunatissima pro Maven"
  ],
  "spell tome: turn greater undead": [
    "Liber Magicus: Repelle Amortuos Maiores"
  ],
  "shaircolor1": [
    "sHairColor1"
  ],
  "elven armor of the squire": [
    "Alfa Lorica Armiferis"
  ],
  "investor": [
    "Investor"
  ],
  "humanbeard35": [
    "HumanBeard35"
  ],
  "db04astridmotierrenightmothertopic": [
    "DB04AstridMotierreNightMotherTopic"
  ],
  "sea cave": [
    "Maris Spelunca"
  ],
  "daedric battleaxe of dread": [
    "Bellisecuris Daedricus Timoris"
  ],
  "a dance in fire, v5": [
    "Saltatio in Flammis, V"
  ],
  "dance in fire, v5": [
    "Saltatio in Flammis, V"
  ],
  "eavesdrop on cicero": [
    "Ciceronem clam ausculta"
  ],
  "corpus enchanter": [
    "Corporis Incantator"
  ],
  "insightful enchanter": [
    "Incantator Callidus"
  ],
  "extra effect": [
    "Effectum Supperfluum"
  ],
  "fire enchanter": [
    "Incantator Ignis"
  ],
  "leather bracers of major lockpicking": [
    "Corii Chirothecae Maioris Clavicarii"
  ],
  "frost enchanter": [
    "Incantator Glaciei"
  ],
  "steel helmet of illusion": [
    "Adamantea Galea Inlusionis"
  ],
  "clear selections": [
    "Remove Electa"
  ],
  "steel gauntlets of major sure grip": [
    "Adamanteae Manicae Maioris Dexteritatis"
  ],
  "hypnotic gaze": [
    "Visus Hypnoticus"
  ],
  "aspect of terror": [
    "Aspectum Terroris"
  ],
  "fighting stance": [
    "Sta ut Pugnator"
  ],
  "steel shield of resist shock": [
    "Adamanteum Scutum Contra Vim Electricam"
  ],
  "why should i trust you or your order": [
    "Quamobrem tibi vel ordini tuo confidere debeo"
  ],
  "iron sword of soul snares": [
    "Gladius Ferri Animicapti"
  ],
  "holy ebony bow": [
    "Sanctus Arcus Ebeni"
  ],
  "any other way to earn it": [
    "Estne alio modo in quo id merere possum "
  ],
  "devastating blow": [
    "Ictus Devastans"
  ],
  "fragments of wuuthrad": [
    "Fragmenta Vuuthradis"
  ],
  "torpor ingredient 2": [
    "Torpor Ingredient 2"
  ],
  "mila valentia": [
    "Mila Valentia"
  ],
  "iona": [
    "Iona"
  ],
  "reverent dwarven battleaxe": [
    "Reverens Dvemeris Bellisecuris"
  ],
  "salvius farmhouse": [
    "Salvius Villa Rustica"
  ],
  "imperial bow of weariness": [
    "Imperialis Arcus Maioris Defatigationis"
  ],
  "vaermina robes": [
    "Peplum Morphei"
  ],
  "sirgar": [
    "Sirgar"
  ],
  "aiming": [
    "Intuens"
  ],
  "madanach's key": [
    "Clavis Madanachi"
  ],
  "iron dagger of draining": [
    "Pugio Ferri Hauriendi"
  ],
  "mystic binding": [
    "Vincula Mystica"
  ],
  "i've been thinking it over": [
    "De eo cogitavi"
  ],
  "it may be connected to the eye of magnus": [
    "Fortasse nexum cum Oculo Magni habet"
  ],
  "anwen": [
    "Anven"
  ],
  "armsman": [
    "Armifer"
  ],
  "overdraw": [
    "Supertensum"
  ],
  "talk to astrid": [
    "Astridem adloquere"
  ],
  "strange gem": [
    "Mirabilis Gemma"
  ],
  "glass gauntlets of eminent wielding": [
    "Vitreae Manicae Eminentis Tenendi"
  ],
  "so you have everything you need now": [
    "Ergo omnia necessaria nunc habes"
  ],
  "shaircolor0": [
    "sHairColor0"
  ],
  "red eagle's rite": [
    "Relgio Aquilae Rubrae"
  ],
  "shield wall": [
    "Paries Scuti"
  ],
  "studded armor of the squire": [
    "Ornata Lorica Armiferis"
  ],
  "orcish mace of debilitation": [
    "Orci Maca Debilitationis"
  ],
  "cushioned": [
    "Pulvinatus"
  ],
  "ebony helmet of extreme archery": [
    "Ebeni Galea Extremis Saggitationis"
  ],
  "agile defender": [
    "Defensor Agilis"
  ],
  "dark soulsdead thrall": [
    "Dark SoulsDead Thrall"
  ],
  "orcish dagger of flames": [
    "Orci Pugio Flammarum"
  ],
  "nimphaneth": [
    "Nimphaneth"
  ],
  "apprentice locks": [
    "Clausurae Discipuli"
  ],
  "cryomancer": [
    "Glacimagus"
  ],
  "kill the bandit leader (jarl)": [
    "Neca Ducem Latronum (Jarl)"
  ],
  "gerda": [
    "Gerda"
  ],
  "combine ingredients to make potions": [
    "Permisce ingredientia ut potiones facias"
  ],
  "master conjuration": [
    "Dominus Artis Vocandi"
  ],
  "how do i get to whiterun from here": [
    "Quomodo Albacursum petam ab hoc loco"
  ],
  "sounds like you've got a grudge": [
    "Mihi videtur you've got grudge"
  ],
  "turns out he's a dragon": [
    "Verum est illum draconum esse"
  ],
  "but he helped me": [
    "Sed mihi auxilium dedit"
  ],
  "hand it over and no one has to know": [
    "Da mihi nunc et nemo umquam sciet"
  ],
  "roras's letter": [
    "Epistula Roratis"
  ],
  "horker tusk": [
    "Horker Dens"
  ],
  "dragonscale armor of eminent health": [
    "Draconisquamata Lorica Eminentis Salutis"
  ],
  "glass mace of ice": [
    "Maca Vitrea Gelidus"
  ],
  "you're researching the dwemer": [
    "In Dwemeres operam das"
  ],
  "key to house gray-mane": [
    "Clavis ad Domum Juba-Canam"
  ],
  "master calcinator": [
    "Master Calcinator"
  ],
  "hairmaleorc12": [
    "HairMaleOrc12"
  ],
  "your tricks won't help you, monster": [
    "Doli te non adiuvabunt"
  ],
  "find the priest of boethiah": [
    "Inveni Sacerdotem Boethis"
  ],
  "femaleeyesargonian": [
    "FemaleEyesArgonian"
  ],
  "can't we just make up missing parts of the verse": [
    "Quidni partes amissos fingamus"
  ],
  "runil": [
    "Runil"
  ],
  "i bet you get some interesting people through here": [
    "Credo te homines miros hic videre"
  ],
  "notes on the lunar forge": [
    "Adnotata de Fabrica Lunari"
  ],
  "shagrol's warhammer": [
    "Bellimalleus Shagrolis"
  ],
  "are you in charge here": [
    "Hicne Praees"
  ],
  "iron helmet of major destruction": [
    "Ferri Galea Maioris Destructionis"
  ],
  "juggernaut": [
    "Iuggernauta"
  ],
  "scaled boots of frost suppression": [
    "Caligae Squamatae Contra Glaciem"
  ],
  "philter of pickpocketing": [
    "Philtrum Furandi"
  ],
  "spriggan call of the wild": [
    "Spriggan Call of Wild"
  ],
  "greater ward": [
    "Greater Ward"
  ],
  "scaled armor of the major squire": [
    "Lorica Squamata Maioris Armiferis"
  ],
  "so, they had a relationship": [
    "Ergo, affinitatem inter se habebant"
  ],
  "hairlinefemaledremora01": [
    "HairLineFemaleDremora01"
  ],
  "what do you mean, \"the end is upon us\"": [
    "Cur dicis, \"Finis apud nos est\""
  ],
  "maleeyesargonian": [
    "MaleEyesArgonian"
  ],
  "who are the silver hand": [
    "Quid est Manus Argentea"
  ],
  "invisibility phantom": [
    "Invisibility Phantom"
  ],
  "silver candlestick": [
    "Argentei Ceroferarium"
  ],
  "i have to go into the vampire's lair alone": [
    "Opus mihi est in cubile vampiri solus intrare"
  ],
  "herbalist's guide to skyrim": [
    "Lectionarium Alchemistae Caelifinis"
  ],
  "dwarven greatsword of gleaning": [
    "Dvemeris Spatha Saturni"
  ],
  "elixir of haggling": [
    "Commixtum Arguendi"
  ],
  "too rich for my blood": [
    "Nimis pecuniae mihi"
  ],
  "necromage": [
    "Necromagus"
  ],
  "can i join the companions": [
    "Possumne Contubernales adiungere"
  ],
  "alik'r archer": [
    "Alik'ris Archer"
  ],
  "ebony ore": [
    "Ebeni Minerale"
  ],
  "drop weapon, guard extorts": [
    "Drop weapon, Guard extorts"
  ],
  "imperial legion": [
    "Imperialis Legion"
  ],
  "glass mace of leeching": [
    "Maca Vitrea Sanguisugae"
  ],
  "i have something for you": [
    "Aliquid tibi habeo"
  ],
  "dwarven helmet of archery": [
    "Dvemeris Galea Saggitationis"
  ],
  "novice calcinator": [
    "Novice Calcinator"
  ],
  "tell me about the attack on the fort": [
    "Dic mihi de oppugnatum in castellum"
  ],
  "do you have any proof of that": [
    "Habesne ullam probationem de illo"
  ],
  "why did karliah purchase goldenglow estate": [
    "Cur Karlia Villam Aurilucem emit"
  ],
  "locate the dragon burial site": [
    "Inveni Sepultum Draconis"
  ],
  "daedric warhammer of stunning": [
    "Bellimalleus Daedricus Intermissionis"
  ],
  "sabjorn": [
    "Sabjorn"
  ],
  "gulum-ei also told me she was a murderer": [
    "Gulum-Ei quoque mihi dixit eam homicidiam esse"
  ],
  "sliced eidar cheese": [
    "Sectus Eidaris Caseus"
  ],
  "dwarven warhammer of scorching": [
    "Dvemeris Bellimalleus Adurendi"
  ],
  "ebony sword of petrifying": [
    "Gladius Ebeni Lapidescendi"
  ],
  "bring 10 fire salts to balimund (<global=ffr10saltcount>/<global=ffr10salttotal>)": [
    "Fer X salia ignis ad Balimund (<Global=FFR10SaltCount>/<Global=FFR10SaltTotal>)"
  ],
  "alchemist": [
    "Alchemista"
  ],
  "elven bracers of wielding": [
    "Chirothecae Alfae Tenendi"
  ],
  "key to markarth treasury house": [
    "Clavis ad Gazophylacium Markartiae"
  ],
  "iron greatsword of cowardice": [
    "Spatha Ferri Timoris"
  ],
  "humanbeard13": [
    "HumanBeard13"
  ],
  "imperial light shield of waning frost": [
    "Romana Parma Insenescentis Congelationis"
  ],
  "i want to stop at stonehills": [
    "Volo consistere apud Lapidicolles"
  ],
  "is there some way i can help": [
    "Estne quo modo in quo adiuvare possum"
  ],
  "cedran": [
    "Cedran"
  ],
  "haggling": [
    "Pactor"
  ],
  "thorek": [
    "Thorek"
  ],
  "glass sword of consuming": [
    "Gladius Vitreus Consumendi"
  ],
  "new save": [
    "Tutum Novum"
  ],
  "frenzy - master of the mind": [
    "Frenzy - Dominus Mentis"
  ],
  "that's a shame": [
    "Pudor est"
  ],
  "where did this tunnel come from": [
    "Unde venit hic specus"
  ],
  "assault alias=enemy>, killing <alias=enemy.pronoun> will earn bonus pay": [
    "Oppugna <Alias=Enemy>, <Alias=Enemy.pronoun> necans tibi plus pecuniam dabit"
  ],
  "you said you were going to answer my question": [
    "Dixisti te rogatum meam reponsum daturum esse"
  ],
  "whiterun braith saffir scene 1": [
    "Whiterun Braith Saffir Scene 1"
  ],
  "ebony shield of frost abatement": [
    "Ebeni Scutum Gelidus Resistendi"
  ],
  "daedric helmet of eminent restoration": [
    "Galea Daedrica Eminentis Reficiendi"
  ],
  "do you know gaius maro's schedule": [
    "Scisne Horarium Gaii Maronis"
  ],
  "what would you like to do": [
    "Quid vis facere"
  ],
  "kill astrid": [
    "Neca Astridem"
  ],
  "footstep": [
    "Vestigia"
  ],
  "escort esbern to riverwood": [
    "Fer Esbern Lignirivum"
  ],
  "marksfemalehumanoid12leftgash": [
    "MarksFemaleHumanoid12LeftGash"
  ],
  "the town guards can't help you": [
    "Custodes oppidi te adiuvare non possunt"
  ],
  "town guards can't help you": [
    "Custodes oppidi te adiuvare non possunt"
  ],
  "i've got a sapling": [
    "Arborunculum habeo"
  ],
  "what's wrong with my outfit": [
    "Quid est difficultas cum vestimentis meis"
  ],
  "you cannot fast travel during combat": [
    "Non potes celeriter peregrinari dum pugnans"
  ],
  "adept locks": [
    "Clausurae Adeptae"
  ],
  "expert locks": [
    "Clausurae Expertes"
  ],
  "fxdustdropwep": [
    "FXdustDropVEP"
  ],
  "how could you possibly know that": [
    "Quomodo id scire potuisti"
  ],
  "master locks": [
    "Clausurae Domini"
  ],
  "wilderness": [
    "Caelifinis"
  ],
  "bards college scene": [
    "Bards College Scene"
  ],
  "what happened here": [
    "Quid hic accidit"
  ],
  "another killing": [
    "Nex alia"
  ],
  "adept alteration": [
    "Adeptus Mutationis"
  ],
  "whiterun anoriath ysolda scene 1": [
    "Whiterun Anoriath Ysolda Scene 1"
  ],
  "dunravenscarfaction": [
    "dunRavenscarFaction"
  ],
  "expert alteration": [
    "Expers Alterationis"
  ],
  "why is this all such a secret": [
    "Cur sunt omnia tam secreta"
  ],
  "riverwood embry sigrid scene": [
    "Riverwood Embry Sigrid scene"
  ],
  "apprentice conjuration": [
    "Artis Vocandi Discipulus"
  ],
  "goldenglow bill of sale": [
    "Auctoritas (Titulum) Aurilucis"
  ],
  "wichangelocation03hit": [
    "WIChangeLocation03Hit"
  ],
  "glass dagger of fire": [
    "Pugio Vitreus Ignis"
  ],
  "i'll talk to styrr": [
    "Adloquar Styrr"
  ],
  "we'll figure something out": [
    "Aliquid inveniemus"
  ],
  "thash grape": [
    "Illastoptima"
  ],
  "expert conjuration": [
    "Expers Artis Vocandi"
  ],
  "apprentice destruction": [
    "Destructionis Discipulus"
  ],
  "adept destruction": [
    "Destructio Adepta"
  ],
  "leather bracers of smithing": [
    "Corii Chirothecae Ferrarii"
  ],
  "i'll set you free if you promise to take me to skuldafn": [
    "Te liberabo si me ad Skuldafn ferre promittes",
    "Te liberabo si me ad Skuldafn ferre prommiseris"
  ],
  "expert destruction": [
    "Expers Destructionis"
  ],
  "i was just asking": [
    "Modo rogavi"
  ],
  "i didn't really want to be involved": [
    "Partem eius esse nolui"
  ],
  "i'm honored": [
    "Honoratus sum"
  ],
  "nirya": [
    "Nirya"
  ],
  "spell tome: storm thrall": [
    "Liber Magicus: Servus Tempestatis"
  ],
  "expert illusion": [
    "Expers Inlusionis"
  ],
  "spell tome: bane of the undead": [
    "Liber Magicus: Mors Amortuis"
  ],
  "fortify bow stagger": [
    "Claudica Arcubus"
  ],
  "adept restoration": [
    "Ars Reficiendi Adepta"
  ],
  "femaleheadwoodelf": [
    "FemaleHeadWoodElf"
  ],
  "expert restoration": [
    "Expers Artis Reficiendi"
  ],
  "master restoration": [
    "Dominus Artis Reficiendi"
  ],
  "i have the blade shards of mehrunes' razor": [
    "Habeo partes ensium Novaculae Croni"
  ],
  "leather helmet of alchemy": [
    "Cutis Galea Alchemiae"
  ],
  "how about hjaalmarch": [
    "Quid de Hjaalmarch"
  ],
  "if maven already threw you in jail, why steal her horse": [
    "Si Maven iam te in carcerem iecit, cur equum eius furatus es"
  ],
  "what should i do about aringoth": [
    "Quid cum Aringoth facere debeo"
  ],
  "you cannot shout while casting a spell": [
    "Non potes clamare cum magicam emitas"
  ],
  "ebony armor of the noble knight": [
    "Ebeni Lorica Nobilis Equitis"
  ],
  "key to styrr's house": [
    "Clavis ad Domum Styrris"
  ],
  "bucket": [
    "Hama"
  ],
  "dragonplate gauntlets of peerless sure grip": [
    "Draconilaminatae Manicae Optimae Ambidexteritatis"
  ],
  "actors in this will never fill world interaction aliases": [
    "Actors in this will never fill world interaction aliases"
  ],
  "unbreakable": [
    "Infrangibilis"
  ],
  "fragment of wuuthrad": [
    "Fragmentum Vuuthradis"
  ],
  "gaius maro's schedule": [
    "Horarium Gaii Maronis"
  ],
  "steal the plans from <alias=camp>": [
    "Furare Documenta a <Alias=Camp>"
  ],
  "all spell tomes": [
    "All Liber Magicuss"
  ],
  "is dawnstar prepared for dragon attacks": [
    "Esne Lucifer paratus oppugnationibus draconum"
  ],
  "dragonscale boots of fire suppression": [
    "Caligae Draconisquamatae Suppressionis Ignis"
  ],
  "critical charge": [
    "Oppugnatio Criticalis"
  ],
  "great critical charge": [
    "Carricatio Magna"
  ],
  "orcish warhammer of torpor": [
    "Orci Bellimalleus Torporis"
  ],
  "i've found the fire salts for your forge": [
    "Salia ignis pro fabrica tua inveni"
  ],
  "glass sword": [
    "Gladius Vitreus"
  ],
  "nocturnal's hat": [
    "Petasus Nocturnalis"
  ],
  "bounty hunter": [
    "Vectigalis Venator"
  ],
  "dragonscale boots of eminent sneaking": [
    "Caligae Draconisquamatae Maximi Repsendi"
  ],
  "riften guard": [
    "Custos Hiati"
  ],
  "i'll pay for the hilt": [
    "Pro capulo pecuniam solvam"
  ],
  "are you ok": [
    "Quid fit"
  ],
  "tg02 aringoth player neutral faction": [
    "TG02 Aringoth Player Neutral Faction"
  ],
  "bound battleaxe fx": [
    "Bound Bellisecuris FX"
  ],
  "psiijic boots": [
    "Caligae Psijicae"
  ],
  "thieves guild dialogue scene 14": [
    "Sodalitatis Furum Dialogue Scene 14"
  ],
  "dwarven smithing": [
    "Dvemeris Ferrarii"
  ],
  "belrand": [
    "Belrand"
  ],
  "elven smithing": [
    "Ferrarius Alfus"
  ],
  "player has non-dangerous on going magic effect on": [
    "Player has non-dangerous on going magic effect on"
  ],
  "orcish smithing": [
    "Orci Ferrarii"
  ],
  "glass smithing": [
    "Ferrarius Vitrei"
  ],
  "daedric battleaxe of the vampire": [
    "Bellisecuris Daedricus Vampiri"
  ],
  "humanbeard31": [
    "HumanBeard31"
  ],
  "food vendor": [
    "Food Vendor"
  ],
  "ebony smithing": [
    "Ferrarius Ebeni"
  ],
  "daedric war axe of storms": [
    "Bellisecuris Daedricus Tempestatium"
  ],
  "daedric smithing": [
    "Ferrarius Daedricus"
  ],
  "marksmalehumanoid12leftgash": [
    "MarksMaleHumanoid12LeftGash"
  ],
  "here, does this help": [
    "Agedum, hocne adiuvet"
  ],
  "i found queen freydis's sword": [
    "Gladius Reginae Freydis inveni"
  ],
  "duach": [
    "Duach"
  ],
  "femaleheaddremora": [
    "FemaleHeadDremora"
  ],
  "chef's tunic": [
    "Tunica Coqui"
  ],
  "baneundeadbodyartholder": [
    "BaneUndeadBodyArtHolder"
  ],
  "atromancy": [
    "Atromantia"
  ],
  "imperial light boots": [
    "Romanae Caligulae"
  ],
  "elemental potency": [
    "Potentia Elementalis"
  ],
  "ghost extra damage perk": [
    "Ghost Extra Damage Perk"
  ],
  "ghost half damage perk": [
    "Ghost Half Damage Perk"
  ],
  "hunter's journal": [
    "Ephemeris Venatoris"
  ],
  "he's on his way": [
    "Hic nunc venit"
  ],
  "mage armor": [
    "Magi Lorica"
  ],
  "what do you know about the orc": [
    "Quid de Orco scis"
  ],
  "dragonscale shield of grounding": [
    "Scutum Draconisquamatum Minoris Electricitatis"
  ],
  "the best time to negotiate is from a position of strength": [
    "Tempus aptissimum negotium agendo est dum fortis es"
  ],
  "best time to negotiate is from a position of strength": [
    "Tempus aptissimum negotium agendo est dum fortis es"
  ],
  "fenrig": [
    "Fenrig"
  ],
  "staff of revenants": [
    "Rudis Revenantium"
  ],
  "oblivion binding": [
    "Vincula Oblivionis"
  ],
  "stone chair": [
    "Sella Lapidea"
  ],
  "ebony bow of freezing": [
    "Arcus Ebeni Gelidus"
  ],
  "do you have any supplies i could take": [
    "Habesne impedimenta quas sumere possim"
  ],
  "soul stealer": [
    "Animifur"
  ],
  "faendal's fake letter from sven": [
    "Epistula Falsa a Sven (a Faendal scripta)"
  ],
  "keymaster": [
    "Clavidominus"
  ],
  "sulla trebatius": [
    "Sulla Trebatius"
  ],
  "faleen": [
    "Faleen"
  ],
  "drawbridge lever": [
    "Fulcrum Pontis"
  ],
  "yes, but he escaped": [
    "Sic, effugit",
    "Sic, sed ille effugit"
  ],
  "i need to find out where he went": [
    "Quo effugit discere debeo"
  ],
  "walk away": [
    "Discede"
  ],
  "right now": [
    "Nunc ipse"
  ],
  "reckless mage": [
    "Magus Sine Cura"
  ],
  "i'm here to bring you to the temple in markarth": [
    "Hic sum ut te ad Temple Markartiae adferam"
  ],
  "berserk": [
    "Berserk"
  ],
  "so what can we do now": [
    "Ergo quid nunc facere possumus"
  ],
  "mage": [
    "Magus"
  ],
  "darklight chambers": [
    "Obscuralux Conclavia"
  ],
  "colette marence": [
    "Colette Marence"
  ],
  "retrieve the golden claw": [
    "Inveni Unguem Aureum"
  ],
  "gianna": [
    "Gianna"
  ],
  "the thalmor have found you": [
    "Visigothi te invenerunt"
  ],
  "thalmor have found you": [
    "Visigothi te invenerunt"
  ],
  "you need to get out of here": [
    "E hoc loco exire debes"
  ],
  "you heard right": [
    "Recte audivisti"
  ],
  "it's the only way to stop the dragons": [
    "Sola via est ut dracones vincamus"
  ],
  "dragonslayer's blessing": [
    "Benedictio Necatoris Draconis"
  ],
  "what can you tell me about riverwood": [
    "Quid de Ligniviro mihi narrare potes"
  ],
  "what should i do next": [
    "Quid proximum facere debeo"
  ],
  "marksfemalehumanoid06leftgash": [
    "MarksFemaleHumanoid06LeftGash"
  ],
  "you're serving only eight months for murder": [
    "Modo octo menses in carcere est quod aliquem necavisti"
  ],
  "iron mace of soul snares": [
    "Ferri Maca Animicapti"
  ],
  "largashbur cellar": [
    "Largashbur Cellarium"
  ],
  "tower: unlock": [
    "Turris: Aperi"
  ],
  "lurbuk is dead": [
    "Lurbuk mortuus est"
  ],
  "headsman": [
    "Detruncator"
  ],
  "glass warhammer of animus": [
    "Bellimaleus Vitreus Animi"
  ],
  "force without effort": [
    "Vis Sine Conatu"
  ],
  "brother verulus": [
    "Frater Verulus"
  ],
  "eternal spirit": [
    "Animus Aeternalis"
  ],
  "plate": [
    "Lanx",
    "lāmina, lāminae"
  ],
  "the fire within": [
    "Ignis Interior"
  ],
  "fire within": [
    "Ignis Interior"
  ],
  "alteration dual casting": [
    "Mutatio Ambabus Manibus"
  ],
  "hairlinemaledarkelf04": [
    "HairLineMaleDarkElf04"
  ],
  "conjuration dual casting": [
    "Ars Vocandi Ambabus Manibus"
  ],
  "you cannot read a book while in combat": [
    "Non potes librum legere dum pugnes"
  ],
  "moss mother cavern": [
    "Cavern Matris Musci"
  ],
  "destruction dual casting": [
    "Destructio Ambabus Manubus"
  ],
  "you mean i'm not the only dragonborn": [
    "Dicis me Draconigenam solum non esse"
  ],
  "broken iron sword handle": [
    "Fractus Gladius Ferri Cherolaba"
  ],
  "illusion dual casting": [
    "Magica Inlusionis Ambabus Manibus"
  ],
  "so you think alduin's wall will tell us how to defeat alduin": [
    "Sic, censes Parietem Alduinis nos debilitatem Alduini docebit"
  ],
  "elven battleaxe of harrowing": [
    "Bellisecuris Alfus Occationis"
  ],
  "draught of regeneration": [
    "Decocta Recuperandi"
  ],
  "reduce attack damage": [
    "Reduce Attack Damage"
  ],
  "elven boots of dwindling shock": [
    "Caligae Alfae Electricitatis Diminuentis"
  ],
  "i am the gourmet": [
    "Apicius sum"
  ],
  "(show writ of passage)": [
    "(Demonstra Iussum Commeatus)"
  ],
  "ward absorb": [
    "Scutum Magica Absorbens"
  ],
  "harvest blood": [
    "Sume Sanguinem"
  ],
  "the steed": [
    "Equus"
  ],
  "steed": [
    "Equus"
  ],
  "agent of mara": [
    "Agens Marae"
  ],
  "one-handed trainer": [
    "One-Handed Trainer"
  ],
  "hairmalenord14": [
    "HairMaleNord14"
  ],
  "chain lightning vis": [
    "Chain Lightning vis"
  ],
  "scaled bracers of eminent wielding": [
    "Chirothecae Squamatae Eminentis Tenendi"
  ],
  "dragon tail": [
    "Dragon Tail"
  ],
  "test invisible explosion": [
    "Test invisible explosion"
  ],
  "what's it mean to be dragonborn": [
    "Quid sibi vult 'Draconigenam' esse"
  ],
  "orcish sword of ice": [
    "Orci Gladius Gelidus"
  ],
  "restore health blank": [
    "Restore Health Blank"
  ],
  "throw voice explosion": [
    "Throw Voice Explosion"
  ],
  "here's what i'll need": [
    "Hic habes necessaria"
  ],
  "magic visuals test hall": [
    "Magic Visuals Test Hall"
  ],
  "the school of alteration focuses on the manipulation of the physical world, and is used to cast spells like waterbreathing, magical armor and magelight": [
    "Schola Mutationis mutat mundum physicalem et magicam ut Respirare sub aqua, Loricam Magicam, et Magilucem habet"
  ],
  "school of alteration focuses on the manipulation of the physical world, and is used to cast spells like waterbreathing, magical armor and magelight": [
    "Schola Mutationis mutat mundum physicalem et magicam ut Respirare sub aqua, Loricam Magicam, et Magilucem habet"
  ],
  "the school of destruction focuses on the mastery of fire, frost and shock, and is used to cast spells like fireball, ice spike, and lightning bolt": [
    "Schola Destructionis magicam ignis, glaciei et electricitatis continet. Usurpatur ut magicam ut Ignipilam, Hamum Glaciei, et Tonitrum facias"
  ],
  "school of destruction focuses on the mastery of fire, frost and shock, and is used to cast spells like fireball, ice spike, and lightning bolt": [
    "Schola Destructionis magicam ignis, glaciei et electricitatis continet. Usurpatur ut magicam ut Ignipilam, Hamum Glaciei, et Tonitrum facias"
  ],
  "iron war axe of cowardice": [
    "Bellisecuris Ferri Timoris"
  ],
  "glass sword of harvesting": [
    "Gladius Vitreus Saturni"
  ],
  "those trained to use heavy armor make more effective use of iron, steel, dwarven, orcish, ebony, and daedric armors": [
    "Qui docti sunt in arte Loricarum Gravium Loricis Ferri, Adamanteis, Dwemeribus, Orci, Ebeni, et Daedricis effaciacius utuntur"
  ],
  "the light armor skill allows for more effective use of hide, leather, elven, and glass armors": [
    "Ars Loricae Levis te loricis Rhenonis, Pellis, Alfae, et Vitrei uti sinet"
  ],
  "light armor skill allows for more effective use of hide, leather, elven, and glass armors": [
    "Ars Loricae Levis te loricis Rhenonis, Pellis, Alfae, et Vitrei uti sinet"
  ],
  "the one-handed skill improves the use of weapons such as daggers, swords, maces and war axes": [
    "Ara Singuli-Manu augetur per usum pugionum, gladiorum, macarum, et securium"
  ],
  "one-handed skill improves the use of weapons such as daggers, swords, maces and war axes": [
    "Ara Singuli-Manu augetur per usum pugionum, gladiorum, macarum, et securium"
  ],
  "the pickpocket skill is used to take things from an unsuspecting target's pockets... and not get caught in the process": [
    "Ars furandi ab homine usurpatur ut res e fundis aliorum fureris... nec inveniaris"
  ],
  "pickpocket skill is used to take things from an unsuspecting target's pockets... and not get caught in the process": [
    "Ars furandi ab homine usurpatur ut res e fundis aliorum fureris... nec inveniaris"
  ],
  "ebony sword of garnering": [
    "Gladius Ebeni Horti"
  ],
  "imperial light shield": [
    "Romana Parma"
  ],
  "the speech skill is used to get better prices from merchants and persuade, intimidate, or even bribe others": [
    "Ars loquacitatis usurpatur ut pretios meliors ab mercatoribus accipias et alios persuadeas, intimides, vel etiam corrumpas"
  ],
  "speech skill is used to get better prices from merchants and persuade, intimidate, or even bribe others": [
    "Ars loquacitatis usurpatur ut pretios meliors ab mercatoribus accipias et alios persuadeas, intimides, vel etiam corrumpas"
  ],
  "the two-handed skill dictates the effectiveness of greatswords, battleaxes, and warhammers": [
    "Ars Ambidexteritatis vulnus Spatharum, Bellisecurium, et Bellimalleorum regit"
  ],
  "two-handed skill dictates the effectiveness of greatswords, battleaxes, and warhammers": [
    "Ars Ambidexteritatis vulnus Spatharum, Bellisecurium, et Bellimalleorum regit"
  ],
  "glass warhammer of thunderbolts": [
    "Bellimaleus Vitreus Fulminum"
  ],
  "grand plaza scene 07": [
    "Grand Plaza Scene 07"
  ],
  "fire burns for a while after the target is hit, doing extra damage, and is useful against all types of opponents": [
    "Ignis paulum tempus post ictum urit, et plus vulneris addit. Utilis est contra omnes modos inimicorum"
  ],
  "in addition to doing regular damage, frost attacks deplete the target's stamina, and slow them down": [
    "Praeter vulnus normale, Ictus glaciei quoque staminam inimicorum diminuunt, et eos lentiores faciunt"
  ],
  "use them to prevent enemies from doing as many power attacks": [
    "Eis uti ut ictus potestatis vetes"
  ],
  "you're in debt to irnskar": [
    "In aere alieon es Irnskari"
  ],
  "bashing with a shield will stagger an opponent": [
    "Oppugnare Scuto faciet ut inimicum claudicet"
  ],
  "if timed properly, it will also interrupt a power attack": [
    "Si tempore apto id facias, ictum potestatis interrumpat"
  ],
  "blind cliff bastion": [
    "Castellum Caecirupis"
  ],
  "swords swing faster than axes, and axes swing faster than maces": [
    "Gladii celerius quam secures, et secures celerius quam macae vibrantur"
  ],
  "what brings a breton to skyrim": [
    "Quid fert Britannum ad Caelifinem"
  ],
  "scaled boots of dwindling flames": [
    "Caligae Squamatae Flammarum Diminuentium"
  ],
  "pick someone's pocket by activating them while sneaking": [
    "Furare ab homine dum repens potes"
  ],
  "looking at their inventory is harmless - taking anything is a crime": [
    "Inventarium videre innocens est - aliquid capere est crimen"
  ],
  "okay, i'll do it": [
    "Ok, id faciam"
  ],
  "elven bow of binding": [
    "Arcus Alfus Deligandi"
  ],
  "when sneaking, if the eye is partially open, someone is searching for you": [
    "Dum repens, si oculus partim apertus sit, aliquis te quaerat"
  ],
  "if it is fully open, they have found you": [
    "Si omnino apertus sit, ille te invenerit"
  ],
  "you sound nervous about being the new steward": [
    "Sollicitus videris quod dispensator novus es"
  ],
  "any attacks done while both sneaking and undetected will do extra damage": [
    "Omnes ictus et repens et clam plus vulneris facient"
  ],
  "when your magicka or stamina bar flashes, it means you cannot perform the action you just attempted": [
    "Cum non satis Magicae vel Staminae habeas, nonullas res facere non posses"
  ],
  "power attacks become regular attacks, and spells fail": [
    "Ictus potestatis fient normales, et magica fallet"
  ],
  "after you use a shout power, the compass outline will glow brightly": [
    "Post clamore uteris, HUD refulgebit"
  ],
  "it fades from the center, indicating the time until you can shout again": [
    "E centro pallescit et, cum omnino normalis erit iterum clamore uti poteris"
  ],
  "give the statue to degaine": [
    "Da Statuam Degaini"
  ],
  "an excessively high bounty will cause a hold's guards to attack the wanted person on sight": [
    "Vectigal nimis magnum faciet ut custodes semper te oppugnent"
  ],
  "scaled helmet of magicka": [
    "Galea Squamata Magicae"
  ],
  "skyrim is home to more than just nords": [
    "Caelifinis domus est ad plures quam Germanos"
  ],
  "a guard may stop combat if his attacker sheathes any weapons": [
    "Custos fortasse consistet si oppugnator arma deponat"
  ],
  "guard may stop combat if his attacker sheathes any weapons": [
    "Custos fortasse consistet si oppugnator arma deponat"
  ],
  "but even if the yield is accepted, the guard will still attempt an arrest": [
    "Sed etiamsi succumbis, custos iam te comprehender temptet"
  ],
  "sorry, i don't even have 750 gold": [
    "Ignosce, nec etiam DCCL auri habeo"
  ],
  "solitude is the center of imperial power in skyrim": [
    "Solitudo est centrum potestatis Imperialis in Caelifine"
  ],
  "it is also home to the famous bards college": [
    "Est quoque domus Collegii noti Canatorum"
  ],
  "imperial sword of sapping": [
    "Gladius Romanus Hauriendi"
  ],
  "markarth is built on the foundations of an ancient dwarven city": [
    "Markartia facta est in fundamenta urbis dvemeris antiquae"
  ],
  "whiterun's central location in skyrim has made it a trading hub of the entire province": [
    "Quod locus medium Caelifinis habet, Albacursus dui centrum commercii fuit"
  ],
  "orcish armor of the noble knight": [
    "Orci Lorica Equitis Nobilis"
  ],
  "windhelm is the oldest city in skyrim and may be the oldest human city on the continent": [
    "Ventigalea est urbs antiquissima in Caelifine et fortasse urbs antiquissima hominum in continente"
  ],
  "you can learn up to three words for a shout": [
    "Tria verba unius clamationis discere potes"
  ],
  "each word adds to the power of the shout": [
    "Singulum verbum vim ad clamationem addit"
  ],
  "the longer you hold down the powers button, the more powerful your shout": [
    "Plus temporis utens botone Potestatium, clamatio potentior"
  ],
  "longer you hold down the powers button, the more powerful your shout": [
    "Plus temporis utens botone Potestatium, clamatio potentior"
  ],
  "quest all drunks have": [
    "Quest all Drunks Have"
  ],
  "yes to all": [
    "Sic ad omnia"
  ],
  "iron battleaxe of scorching": [
    "Bellisecuris Ferri Adurendi"
  ],
  "the magicka cost to cast a spell goes down as your skill increases and you pick relevant perks": [
    "Magicae quae debes uti ut rem facias artibus et peritiis diminuitur"
  ],
  "magicka cost to cast a spell goes down as your skill increases and you pick relevant perks": [
    "Magicae quae debes uti ut rem facias artibus et peritiis diminuitur"
  ],
  "should i say anything to him": [
    "Aliquid ei dicere debeo"
  ],
  "the nords first conquered markarth from the native people of the reach thousands of years ago": [
    "Germani Markartiam ab nativis cepÃªrunt abhinc millia annorum"
  ],
  "nords first conquered markarth from the native people of the reach thousands of years ago": [
    "Germani Markartiam ab nativis cepÃªrunt abhinc millia annorum"
  ],
  "the poor in markarth live in the warrens, and are almost exclusively natives of the reach": [
    "Pauperes Markartiae in Labyrintha vivunt, et fere omnes incolae Prehensi sunt"
  ],
  "poor in markarth live in the warrens, and are almost exclusively natives of the reach": [
    "Pauperes Markartiae in Labyrintha vivunt, et fere omnes incolae Prehensi sunt"
  ],
  "daedric war axe of lightning": [
    "Bellisecuris Daedricus Fulminis"
  ],
  "a native uprising in markarth was put down 20 years ago": [
    "Res Novae incolarum Markartia opressae sunt abhinc II annos"
  ],
  "native uprising in markarth was put down 20 years ago": [
    "Res Novae incolarum Markartia opressae sunt abhinc II annos"
  ],
  "the survivors are known as the forsworn": [
    "Superstites nomen \"Abiurati\" acceperunt"
  ],
  "survivors are known as the forsworn": [
    "Superstites nomen \"Abiurati\" acceperunt"
  ],
  "the tower of dawn sits on a mountain overlooking dawnstar": [
    "Turris Azurae super montem qui panoramam Luciferis habet stat"
  ],
  "tower of dawn sits on a mountain overlooking dawnstar": [
    "Turris Azurae super montem qui panoramam Luciferis habet stat"
  ],
  "it is rumored to be cursed": [
    "Dicitur plenum phantasmarum esse"
  ],
  "eat an ingredient to learn its properties and slightly raise the alchemy skill": [
    "Comede ingredientium ut utilitatem eius discas et artem Alchemiae paulum augeas"
  ],
  "alchemy labs can be used to combine ingredients into useful potions and deadly poisons": [
    "Laboratoria Alchemiae usurpata sunt ut ingredientia in potiones utiles vel venena letifera commisceantur"
  ],
  "have we reached japhet's folly": [
    "Adenimusne ad Fatuitatem Japhetis"
  ],
  "orcish warhammer of binding": [
    "Orci Bellimalleus Deligandi"
  ],
  "any poison applied to an equipped weapon will be used on the next hit": [
    "Venenum in armum positum in proximum ictum usurpabitur"
  ],
  "why are the dragons returning": [
    "Cur dracones reveniunt"
  ],
  "does it have something to do with me": [
    "De me agitur"
  ],
  "holy ebony warhammer": [
    "Sanctus Bellimalleus Ebeni"
  ],
  "most shops are open from about 8am to 8pm": [
    "Complures tabernae apertae sunt e 8am ad 8pm"
  ],
  "if a shop is closed, wait until morning... or pick the lock and break in": [
    "Si tabarna clausa esset, expecta ad matutinam... vel frange clausuram et intra contra legem"
  ],
  "inns are a great place to pick up rumors": [
    "Diversoria sunt loci apti ad rumoribus audendum"
  ],
  "the innkeeper can usually point you to local quests": [
    "Caupo saepe te ad munera domestica dirigere potest"
  ],
  "innkeeper can usually point you to local quests": [
    "Caupo saepe te ad munera domestica dirigere potest"
  ],
  "daedric war axe of damnation": [
    "Bellisecuris Daedricus Damnationis"
  ],
  "torture victim": [
    "Victima Tormentis"
  ],
  "spellbooks can be purchased from the court wizard in the jarl's palace": [
    "Libri magicae a thaumatorgos aulae Comitis emuntur"
  ],
  "i just came here for the horn": [
    "Solum hic pro cornu veni"
  ],
  "stagger area attack": [
    "Stagger Area Attack"
  ],
  "she's hiding over near the inn": [
    "Illa prope Diversorium latet"
  ],
  "ebony mace of damnation": [
    "Maca Ebeni Damnationis"
  ],
  "orcish mace of gleaning": [
    "Orci Maca Saturni"
  ],
  "combat-based magic only improves when used against valid opponents": [
    "Magica pugnae modo augetur dum contra inimicos validos usurpatur"
  ],
  "soul gems with souls in them are used to create and recharge magic weapons and armor": [
    "Gemmanimis animis plenae usurpantur ut arma magica crees et vim eis reddas"
  ],
  "cast soul trap on an enemy during combat, and his soul will be captured in one of your empty soul gems, when he dies": [
    "Uti Animicapto in inimico, et animus eius in gemmanima vacua capietur post mortem eius"
  ],
  "steel greatsword of sapping": [
    "Spatha Adamantea Hauriendi"
  ],
  "enemies are displayed onscreen differently than friends when you cast detect life or detect dead": [
    "Inimici videntur in HUD alio modo quam amici cum Magica Inveniendi Vivos vel Mortuos utaris"
  ],
  "dwarven shield of frost suppression": [
    "Dvemere Scutum Contra Glaciem"
  ],
  "hairmalenord9": [
    "HairMaleNord9"
  ],
  "quicksilver ore vein": [
    "Venam Metallis Mercurii"
  ],
  "cloak spells surround the caster with a dangerous aura that harms anyone that gets too close": [
    "Magicae Amiculorum te aura periculosa circumdant quae omnibus propinquis nocet"
  ],
  "a magical ward protects the caster from offensive spells, but takes a few seconds to charge up to full power": [
    "Praesidium magicum te e magica imicorum tegit, sed nonnullas secundas postulat ut totam vim habeat"
  ],
  "magical ward protects the caster from offensive spells, but takes a few seconds to charge up to full power": [
    "Praesidium magicum te e magica imicorum tegit, sed nonnullas secundas postulat ut totam vim habeat"
  ],
  "hairmaleelder2": [
    "HairMaleElder2"
  ],
  "skyrim is home to the nords, a fierce, hardy people that value honor and prowess in battle": [
    "Caelifinis est domus Germanorum, gens saeva et dura, qui honorem et peritiam in proelio ut paradigmata habent"
  ],
  "holy ebony greatsword": [
    "Spatha Sancta Ebeni"
  ],
  "iron war axe of scorching": [
    "Bellisecuris Ferri Adurendi"
  ],
  "the highest mountain in all of tamriel is skyrim's throat of the world": [
    "Mons altissimus Caelifinis est Guttur Mundi"
  ],
  "highest mountain in all of tamriel is skyrim's throat of the world": [
    "Mons altissimus Caelifinis est Guttur Mundi"
  ],
  "the reclusive greybeards live near the top, in the ancient monastery of high hrothgar": [
    "Canabarbi Solitarii prope summum vivunt, in monasterio antqiquo Hrothgari Alto"
  ],
  "reclusive greybeards live near the top, in the ancient monastery of high hrothgar": [
    "Canabarbi Solitarii prope summum vivunt, in monasterio antqiquo Hrothgari Alto"
  ],
  "giants are generally peaceful creatures, so long as potential threats keep their distance... and leave their mammoths unharmed": [
    "Gigantes molles esse solent, si inimici procul ab eis maneant... et ab elephantis eorum desinunt"
  ],
  "tell me where it is, or else": [
    "Dic mihi ubi est, vel"
  ],
  "any map locations that have been discovered and visited can be fast traveled to, from any area outdoors": [
    "Potes celeriter peregrinari ad locos in charta iam inventos et vistitatos"
  ],
  "weapons can be improved at a grindstone": [
    "Arma augentur ad Molinulam"
  ],
  "armor can be improved at a blacksmith's table": [
    "Lorica augetur ad Subsellium Ferarrii"
  ],
  "the labyrinth for which labyrinthian is known was built by archmage shalidor in the first era, though the ruins themselves are much older": [
    "Labyrinthum e quo Labyrinthia nomen accepit factum est a Archmao Shalidore Prima Aetate, sed ruinae ipsae antiquiores sunt"
  ],
  "labyrinth for which labyrinthian is known was built by archmage shalidor in the first era, though the ruins themselves are much older": [
    "Labyrinthum e quo Labyrinthia nomen accepit factum est a Archmao Shalidore Prima Aetate, sed ruinae ipsae antiquiores sunt"
  ],
  "first mate": [
    "Nauta Primus"
  ],
  "nahkriin": [
    "Nahkriin"
  ],
  "many steam animunculi remain active, making the exploration of dwarven ruins a dangerous prospect": [
    "Multi Animunculi iam funguntur. Hac de causa, periculosum est ruinas Dvemeres investigare"
  ],
  "galerion the mystic": [
    "Galerio Mysticus"
  ],
  "the dwarves were actually a race of elves known as the dwemer, which roughly translates to \"deep elves.\"": [
    "Dwemeres re vera erant gens Alforum, et nomen eius sibi vult \"Alfi Profundi.\""
  ],
  "dwarves were actually a race of elves known as the dwemer, which roughly translates to \"deep elves.\"": [
    "Dwemeres re vera erant gens Alforum, et nomen eius sibi vult \"Alfi Profundi.\""
  ],
  "dwarven ruins are dangerous to explore because of devious traps and hazardous machinery, to say nothing of the denizens that may dwell within": [
    "Ruinae Dwemeria periculosae investigatu sunt quod insidias callidas et machinas letiferas habent, et quoque incolae qui fortasse insunt"
  ],
  "wisp fx": [
    "Wisp FX"
  ],
  "dragonsreach earned its name when the ancient nord hero olaf one-eye imprisoned his foe, the great dragon numinex, within the palace": [
    "Draconicarcer nomen eius meruit cum heros Germanus antiquus Olaf Monophthalmos inimicum, draconem ingentem Numinicem, in aula comprehendit"
  ],
  "imperial boots of lifting": [
    "Caligae Romanae Sumendi"
  ],
  "falkreath has been the site of many battles throughout history": [
    "Falkretia locus multorum proeliorum fuit"
  ],
  "the town's graveyard holds the remains of many nord warriors who fell in those battles": [
    "Necropolis eius reliquias multorum bellatorum Germanorum qui in illis mortui sunt"
  ],
  "town's graveyard holds the remains of many nord warriors who fell in those battles": [
    "Necropolis eius reliquias multorum bellatorum Germanorum qui in illis mortui sunt"
  ],
  "earrings": [
    "Earrings"
  ],
  "forelhost stronghold": [
    "Arx Forelhostis"
  ],
  "dread zombie": [
    "Amortuus Timoris"
  ],
  "like many of skyrim's woodland settlements, falkreath's economy is heavily reliant on the production of lumber": [
    "Ut multi ex oppidis in nemore Caelifinis, oeconomia Falkretiae in ligno pendet"
  ],
  "scroll of dead thrall": [
    "Codex Revocandi Corpus"
  ],
  "the canal that spans the length of riften was once a major thoroughfare for merchants and fishermen, but it now sits neglected and stagnant": [
    "Diorx quae Hiatum transit semel erat via magni momenti mercatoribus et piscatoribus, sed nunc neclecta et stagans sedet"
  ],
  "canal that spans the length of riften was once a major thoroughfare for merchants and fishermen, but it now sits neglected and stagnant": [
    "Diorx quae Hiatum transit semel erat via magni momenti mercatoribus et piscatoribus, sed nunc neclecta et stagans sedet"
  ],
  "riften has earned quite a sinister reputation, mainly due to the thieves guild's presence in the sewers beneath the streets": [
    "Hiati multam infamiam habet, praesertim quod principia Sodalitatis Furum in cloaca sub viis est"
  ],
  "riften is home to the black-briar meadery, one of the largest and most successful businesses of its kind": [
    "Hiati est domus Hydromelliarium Atri-Dumi, unus ex maximis et felicissimis talium negotiorum"
  ],
  "once the capital of skyrim, winterhold has been all but abandoned in the last few hundred years": [
    "Semel Caput Caelifinis, Hiemiterra nunc fere vacua est abhinc nunnullos centorum annorum"
  ],
  "winterhold's large population of dark elves was driven out by nords convinced they were involved in evil magic": [
    "Populus magnus Atralforum Hiemiterrae expugnatus est Germanibus qui putabant eos in magica mala complicatos esse"
  ],
  "morthal is said to be named after the great nord hero morihaus, though none alive today are sure of the connection": [
    "Morthal dicitur nominari pro horoe Germano Morihaus, sed nemo vivens certus de nexu est"
  ],
  "guardian circle": [
    "Circulum Custodium"
  ],
  "the last king of the ayleids": [
    "Res Ultimus Ayleidorum"
  ],
  "last king of the ayleids": [
    "Res Ultimus Ayleidorum",
    "Rex Ultimus Ayleidorum"
  ],
  "morthal's small population and relatively remote location have kept it distant from most major conflicts in recent years": [
    "Populatio parva Morthalis et locus removes eum e proeliis magni momenti annos recentes servavit"
  ],
  "put dragon here when player absorbs power": [
    "Put dragon here when player absorbs power"
  ],
  "steel sword of fear": [
    "Gladius Adamanteus Timoris"
  ],
  "steel plate helmet of major illusion": [
    "Laminata Galea Maioris Inlusionis"
  ],
  "mystic bow": [
    "Mysticus Arcus"
  ],
  "while the mages of the college of winterhold gladly share knowledge amongst themselves, they do not offer most services to the rest of skyrim": [
    "Etsi magi Collegii Hiemitarrae libenter scientiam inter se communicantur, Munera sua ad ceterum Caelifinis non offerunt"
  ],
  "the jarl of windhelm, ulfric stormcloak, claims to be the high king of skyrim, and is leading the rebellion against the empire": [
    "Comes Ventigaleae, Vercingetorix Temepstatamiculus, dicit se esse Regem Altum Caelifinis, et res novas contra imperium ducit"
  ],
  "jarl of windhelm, ulfric stormcloak, claims to be the high king of skyrim, and is leading the rebellion against the empire": [
    "Comes Ventigaleae, Vercingetorix Temepstatamiculus, dicit se esse Regem Altum Caelifinis, et res novas contra imperium ducit"
  ],
  "hanging moss": [
    "Muscus Pendens"
  ],
  "a recent string of murders has many windhelm residents fearing for their lives": [
    "Incolae Ventigaleae de vitis desperant quod series necationum in urbe occurit"
  ],
  "recent string of murders has many windhelm residents fearing for their lives": [
    "Incolae Ventigaleae de vitis desperant quod series necationum in urbe occurit"
  ],
  "windhelm's snow quarter has been renamed the \"gray quarter,\" in reference to the dark elves who have made that district their home": [
    "Area Nivis Ventigaleas nomen novum \"Area Cana.\" Hoc ad Atralfos qui nunc illic vivunt refert"
  ],
  "are you ready to spring the trap on the dragon": [
    "Esne paratus insidiis contra draconem uti"
  ],
  "ebony war axe of the sorcerer": [
    "Bellisecuris Ebeni Magi"
  ],
  "the companions trace their legacy back to the original five hundred companions of ysgramor, who led the first humans to skyrim and eventually settled all of tamriel": [
    "Contubernales auspicia sua habent in Quingenti Ysgramorisis, qui homines primos ad Caelifinem duxis et postremo omnem Tamrielis coluit"
  ],
  "companions trace their legacy back to the original five hundred companions of ysgramor, who led the first humans to skyrim and eventually settled all of tamriel": [
    "Contubernales auspicia sua habent in Quingenti Ysgramorisis, qui homines primos ad Caelifinem duxis et postremo omnem Tamrielis coluit"
  ],
  "leather bracers of major smithing": [
    "Corii Chirothecae Maioris Faciendi"
  ],
  "elven dagger of lightning": [
    "Pugio Alfus Fulminis"
  ],
  "how's business": [
    "Quid fit cum negotio"
  ],
  "do you trade your weapons with any of the cities": [
    "Tradisne arma cum urbibus"
  ],
  "dialoguekarthwastent01enmonshousescene03": [
    "DialogueKarthwastenT01EnmonsHouseScene03"
  ],
  "falk firebeard": [
    "Falco Ingibarba"
  ],
  "daedric mace of subsuming": [
    "Maca Daedrica Subsumendi"
  ],
  "the circle is a small group of the most trusted and accomplished warriors from among the ranks of the companions": [
    "Circulum est grex parvum fidissimorum bellatorum contubernalium"
  ],
  "circle is a small group of the most trusted and accomplished warriors from among the ranks of the companions": [
    "Circulum est grex parvum fidissimorum bellatorum contubernalium"
  ],
  "it's mine. don't touch it": [
    "Mea est. Noli eam tangere"
  ],
  "yisra": [
    "Yisra"
  ],
  "find 20 nirnroot for ingun black-briar (<global=ffr04nirncount>/<global=ffr04nirntotal>)": [
    "Inveni XX Nirniradicum pro Ingun Atri-Dumo (<Global=FFR04NirnCount>/<Global=FFR04NirnTotal>)"
  ],
  "nobody knows who built the skyforge, where the companions' weapons are made": [
    "Nemo scit quis Fabricaelum fecerit, ubi Arma Contubernalium faciuntur"
  ],
  "its discovery led to the building of jorrvaskr and the companions as we know them today": [
    "Repertum eius ad Construendum Jorrvaskris duxit, et ad initium Contuberbernalium ut hodie sciuntur"
  ],
  "hairfemaleorc10": [
    "HairFemaleOrc10"
  ],
  "excavation site": [
    "Excavationis situs"
  ],
  "ulfberth war-bear": [
    "Ulfberth Ursus-Belli"
  ],
  "potion of ultimate magicka": [
    "Potio Ultimatis Magicae"
  ],
  "a readied arrow will be put back into its quiver if the bow is sheathed": [
    "Sagitta parata in pharatrem reponetur si arcus deponetur"
  ],
  "readied arrow will be put back into its quiver if the bow is sheathed": [
    "Sagitta parata in pharatrem reponetur si arcus deponetur"
  ],
  "cancel a charged spell by \"sheathing\" or lowering your hands": [
    "Deponere manus magicam deponet"
  ],
  "a power attack against an opponent who is blocking will break through the block, without staggering the attacker": [
    "Ictus potestatis contra inimicum qui armo vel scuto interponit per interpositum franget sine claudicatione oppugnatoris"
  ],
  "power attack against an opponent who is blocking will break through the block, without staggering the attacker": [
    "Ictus potestatis contra inimicum qui armo vel scuto interponit per interpositum franget sine claudicatione oppugnatoris"
  ],
  "pay nazir the fine": [
    "Solve Vectigal Naziri"
  ],
  "blocking with a shield or weapon will recoil an attack, and stagger the attacker": [
    "Interponendi scuto vel armo ictum repellet, et oppugnator claudicabit"
  ],
  "boti": [
    "Boti"
  ],
  "why do you work for nurelion": [
    "Cur pro Nurelione laboras"
  ],
  "db recurringtarget 6 crimefaction": [
    "DB RecurringTarget 6 CrimeFaction"
  ],
  "fortify two-handed": [
    "Adde ad Artem Ambidexteritatis"
  ],
  "silver ring": [
    "Anulus Argenteus"
  ],
  "follow the <alias=general>": [
    "Sequere <Alias=General>"
  ],
  "a magic wall spell creates a barrier that hurts anything that moves through it": [
    "Magica Parietis parietem facit quae omnia quod per eam it vulnerat"
  ],
  "magic wall spell creates a barrier that hurts anything that moves through it": [
    "Magica Parietis parietem facit quae omnia quod per eam it vulnerat"
  ],
  "steel plate bracers of wielding": [
    "Chirothecae Laminatae Tenendi"
  ],
  "stealing plans": [
    "Consilia Furans"
  ],
  "save on travel": [
    "Tuaere dum Peregrineris"
  ],
  "dialoguegenericscenespecial01": [
    "DialogueGenericSceneSpecial01"
  ],
  "bards college class scene": [
    "Bards College Class Scene"
  ],
  "glass gauntlets of major sure grip": [
    "Vitreae Manicae Maioris Dexteritatis"
  ],
  "klimmek": [
    "Klimmek"
  ],
  "aversion to frost": [
    "Aversio Glaciei"
  ],
  "return to danica": [
    "Reveni ad Danicam"
  ],
  "the wolf queen, v1": [
    "Regina Luporum, I"
  ],
  "wolf queen, v1": [
    "Regina Luporum, I"
  ],
  "blessing of akatosh": [
    "Benedictio Akatoshis"
  ],
  "virtuous glass dagger": [
    "Virtutis Pugio Vitreus"
  ],
  "banish the pale lady": [
    "Demitte Feminam Pallidam"
  ],
  "you'd prefer your son to grow bitter and resent you": [
    "Mavis filium asperum tibi esse"
  ],
  "red eagle's fury": [
    "Atocitas Aquilae Rubrae"
  ],
  "retrieve eldergleam sap": [
    "Refer Olibanum Eldergleam"
  ],
  "balgruuf the greater": [
    "Balgruuf Maior"
  ],
  "trius": [
    "Trius"
  ],
  "restoration staff": [
    "Rudis Reparationis"
  ],
  "bring nettlebane to danica": [
    "Fer Nettlebane Danicae"
  ],
  "using the dragonrend shout, right": [
    "Clamatione Draconicaesi, nonne"
  ],
  "who are you, motierre": [
    "Quis es, Motierre"
  ],
  "who are you, really": [
    "De vero, quis es"
  ],
  "retrieve nettlebane": [
    "Refer Nettlebane"
  ],
  "inspect the temple of kynareth": [
    "Inspice Templum Kynarethis"
  ],
  "dwarven bow of ice": [
    "Dvemeris Arcus Gelidus"
  ],
  "acolyte jenssen": [
    "Acolytus Jenssen"
  ],
  "steel sword of dismay": [
    "Gladius Adamanteus Timoris"
  ],
  "how goes the training": [
    "Quid fit cum exercitu"
  ],
  "but i thought there weren't any dragons until alduin came back": [
    "Sed credidi nullos dracones fuisse ante Alduinum revenisse"
  ],
  "why was talos worship outlawed": [
    "Quamobrem religio Alexandri vetita est"
  ],
  "orcish dagger of despair": [
    "Orci Pugio Desponsionis"
  ],
  "you support the empire, then": [
    "Imperione faves"
  ],
  "scaled bracers of eminent archery": [
    "Chirothecae Squamatae Eminentis Saggitationis"
  ],
  "daedric mace of winnowing": [
    "Maca Daedrica Ventagii"
  ],
  "rigel's letter": [
    "Epistula Rigelis"
  ],
  "paarthurnax is dead": [
    "Paarthurnax mortuus est"
  ],
  "orcish dagger of burning": [
    "Orci Pugio Cremandi"
  ],
  "can you help me find the staff of magnus or not": [
    "Potesne me Rudem Magnum invenire adiuvare"
  ],
  "staff of flames": [
    "Rudis Flammarum"
  ],
  "but i'm a great admirer of your work": [
    "Sed laborem tuum valde admiror"
  ],
  "steal the letter incriminating arn": [
    "Furare epistulam quae Arn accusat"
  ],
  "the wild elves": [
    "Alfi Feri"
  ],
  "wild elves": [
    "Alfi Feri"
  ],
  "i thought you needed to be taken down a notch": [
    "Credidi vos poenas dare debere"
  ],
  "i could deliver your message for you": [
    "Tibi nuntium ferre possim"
  ],
  "fragment: on artaeum": [
    "Fragmentum: De Artaeo"
  ],
  "all right, i'll do it": [
    "Bene, id faciam"
  ],
  "glass boots of fire abatement": [
    "Vitreae Caligae Resistendi Ignis"
  ],
  "(optional) wipe out the glenmoril witches": [
    "(Optio) Neca Omnes Sagas Glenmorilis"
  ],
  "collect a glenmoril witch's head": [
    "Collige Caput Sagae Glenmorilis"
  ],
  "the four totems of volskygge": [
    "Quattuor Totemna Volskyggis"
  ],
  "four totems of volskygge": [
    "Quattuor Totemna Volskyggis"
  ],
  "glass war axe of banishing": [
    "Bellisecuris Vitreus Expellendi"
  ],
  "blood's honor": [
    "Honor Sanguinis"
  ],
  "i would be honored to help": [
    "Honor mihi erit te adiuvare"
  ],
  "nightingale boots": [
    "Caligae Philomelarum"
  ],
  "scaled helmet of major alchemy": [
    "Galea Squamata Maioris Alchemiae"
  ],
  "yes sir. sorry, sir": [
    "Sic Domine. Ignosce mihi, domine"
  ],
  "of course, anton": [
    "Certe, Anton"
  ],
  "hawk beak": [
    "Rostrum Accipitris"
  ],
  "have i been poisoned": [
    "Venenatus sum"
  ],
  "key to bthardamz elevator": [
    "Clavis ad Ascensorium Bthardamz"
  ],
  "spell tome: revenant": [
    "Liber Magicus: Revenans"
  ],
  "windhelm blacksmith services": [
    "Ventigalea Blacksmith Services"
  ],
  "tell me about your mother": [
    "Dic mihi de matre tua"
  ],
  "elven helmet of major alteration": [
    "Alfa Galea Maioris Mutationis"
  ],
  "how do i join the imperial legion": [
    "Quomodo adiungere possum Legionem Romanam"
  ],
  "cohort": [
    "Cohort",
    "cohors -rtis f"
  ],
  "elven sword of banishing": [
    "Alfus Gladius Expellendi"
  ],
  "satchel of moon sugar": [
    "Cibisis Sacchari Lunae"
  ],
  "riften temple of mara faction": [
    "Hiati Temple of Mara Faction"
  ],
  "return to fralia gray-mane": [
    "Redi ad Fraliam Juba-Canam"
  ],
  "lead thorald to safety": [
    "Duc Thoraldem ad Salutem"
  ],
  "berserker rage": [
    "Insanitas Berserkris"
  ],
  "speak to svana": [
    "Adloquere Svanam"
  ],
  "you wanted to thank me for killing <alias=victim>": [
    "Mihi gratias agere voluisti quod <Alias=Victim> necavi"
  ],
  "ebony warhammer of damnation": [
    "Bellimalleus Ebeni Damnationis"
  ],
  "delvin mallory sent me": [
    "Delphinus Mallory mihi misit"
  ],
  "meet avulstein at northwatch keep": [
    "Conveni cum Avulstein ad Castellum Septentriovisum"
  ],
  "savior's hide": [
    "Soteris Reno"
  ],
  "how long have you been here": [
    "Quamdiu hic fuisti"
  ],
  "you think you can take me on": [
    "Credis te me superare posse"
  ],
  "deliver proof to avulstein": [
    "Fer Evidentiam ad Avulstein"
  ],
  "find evidence of thorald's fate": [
    "Inveni Evidentiam de Passione Thoralde"
  ],
  "meet fralia in her home": [
    "Conveni cum Fralia domi eius"
  ],
  "elven sword of fear": [
    "Alfus Gladius Timoris"
  ],
  "riften house of clan snow-shod faction": [
    "Hiati House of Clan Snow-Shod Faction"
  ],
  "can i buy a horse": [
    "Possumne equum emere"
  ],
  "daedric greatsword of petrifying": [
    "Spatha Daedrica Lapidescendi"
  ],
  "scroll of heal other": [
    "Codex Alium Sana"
  ],
  "return to louis letrush": [
    "Reveni ad Louis Letrush"
  ],
  "imperial bracers of deft hands": [
    "Chirothecae Romanae Dexteritatis"
  ],
  "steal frost": [
    "Furare Gelu"
  ],
  "steal frost's lineage papers": [
    "Furare Chartas Familiae Gelus"
  ],
  "listen to louis letrush's offer": [
    "Ausculta Offertum Louis Letrush"
  ],
  "answer me, or die": [
    "Da mihi responsum, vel morire"
  ],
  "promises to keep": [
    "Promissa Retenta"
  ],
  "report back to adelaisa": [
    "Refer te ad Adelaisam"
  ],
  "daedric warhammer of thunderbolts": [
    "Bellimalleus Daedricus Fulminum"
  ],
  "report back to orthus": [
    "Refer te ad Orthum"
  ],
  "falmer nightprowler": [
    "Falmeris Vispillio"
  ],
  "give the logbook to orthus": [
    "Fer Librum Ortho"
  ],
  "read eltrys' note": [
    "Epistula Eltrys Lege"
  ],
  "talk to loreius": [
    "Loreium Adloquere"
  ],
  "glass mace of evoking": [
    "Maca Vitrea Evocandi"
  ],
  "convince loreius to fix the wheel": [
    "Persuade Loreio ut rotam reficiat"
  ],
  "potema's sanctum": [
    "Sanctum Potemae"
  ],
  "captain lonely-gale": [
    "Nauclerus Solitari-Ventus"
  ],
  "tell ainethach that atar has been dealt with": [
    "Dic Ainethachi Atarem iam Tractatum Esse"
  ],
  "knjakr": [
    "Knjakr"
  ],
  "maleheadbreton": [
    "MaleHeadBreton"
  ],
  "i can't afford that right now": [
    "Hoc nunc emere non possum"
  ],
  "soljund's sinkhole": [
    "Soljund's Sinkhole"
  ],
  "delayed burial": [
    "Sepultus Tardus"
  ],
  "scaled boots": [
    "Caligae Squamatae"
  ],
  "dispel night eye": [
    "Dispel Night Eye"
  ],
  "secluded chamber": [
    "Camera Seclusa"
  ],
  "general tullius never mentioned you": [
    "Imperator Tullius numquam te commemoravit"
  ],
  "arch-mage aren is dead": [
    "Archimagus Aren mortuus est"
  ],
  "will the night mother speak to me again": [
    "Mene Mater Noctis iterum adloquetur"
  ],
  "iron war axe of sparks": [
    "Bellisecuris Ferri Scintillarum"
  ],
  "innocence lost": [
    "Innocentia Perdita"
  ],
  "speak with astrid": [
    "Adloquere Astridem"
  ],
  "i want to know more about the razor": [
    "Volo plus de Novacula discere"
  ],
  "enter the dark brotherhood sanctuary": [
    "Intra Fanum Fraternitatis Obscurae"
  ],
  "him": [
    "eum"
  ],
  "sunset": [
    "occidens solis"
  ],
  "i need to speak to the jarl": [
    "Mihi ad comitem adloquendum est"
  ],
  "with friends like these": [
    "Cum amicis ut his"
  ],
  "report back to astrid": [
    "Refer te ad Astridem"
  ],
  "talk to muiri": [
    "Adloquere Muiri"
  ],
  "loredas": [
    "Die Saturni"
  ],
  "i'll kill you all before i let you keep me here": [
    "Vos omnes necavero ante me hic capietis"
  ],
  "nord hero sword": [
    "Heroos Germanici Gladius"
  ],
  "learning enchantments": [
    "Incantamenta Discens"
  ],
  "that name would scare anyone": [
    "Illud nomen quipiam terreat"
  ],
  "i've been sent here to kill you": [
    "Hic missus sum ut te necarem"
  ],
  "you're a mercenary": [
    "Mercenarius es"
  ],
  "what about paarthurnax": [
    "Quid de Paarthurnax"
  ],
  "imperial boots of strength": [
    "Caligae Romanae Fortitudinis"
  ],
  "i can't trust you": [
    "Tibi credere non possum"
  ],
  "you're hiding something": [
    "Aliquid celas"
  ],
  "ebony sword of storms": [
    "Gladius Ebeni Tempestatium"
  ],
  "orcish shield of dwindling frost": [
    "Orci Scutum Minoris Congelationis"
  ],
  "dragonscale gauntlets": [
    "Draconisquamatae Manicae"
  ],
  "turn lesser undead": [
    "Repelle Amortuos Minores"
  ],
  "current action must complete before item can be dropped": [
    "Opus est tibi terminare quid nunc facis ut rem deponere possis"
  ],
  "corpse": [
    "Cadaver",
    "cadāver, cadāveris"
  ],
  "mourning never comes": [
    "Maestus (Mane) Numquam Venit"
  ],
  "receive a side contract from nazir": [
    "Accipe Munus Ninus a Nazir"
  ],
  "giraud gemane": [
    "Giraud Gemane"
  ],
  "talk to cicero": [
    "Ciceronem Adloquere"
  ],
  "hide in the night mother's coffin": [
    "Cela te in sarcophago Matris Noctis"
  ],
  "broken steel greatsword blade": [
    "Fractus Spatha Adamantea Ensis"
  ],
  "gelebros": [
    "Gelebros"
  ],
  "does being a bard pay well": [
    "Multamne pecuniam accipis ut poeta"
  ],
  "sorli the builder": [
    "Sorli Aedificator"
  ],
  "ghak": [
    "Ghak"
  ],
  "you cannot equip this item": [
    "Hanc rem manu tenere non potes"
  ],
  "i found the same marking at honningbrew meadery": [
    "Idem signum apud Hydromellarium Honningbrevis"
  ],
  "you must now add a splash of mead": [
    "Nunc addere paulum hydromelli"
  ],
  "kill vittoria vici": [
    "Neca Vittoriam Vicem"
  ],
  "bound until death": [
    "Victus ad Mortem"
  ],
  "report back to gabriella": [
    "Refer te ad Gabriellam"
  ],
  "ebony boots of dwindling flames": [
    "Caligae Ebeni Flammarum Diminuentium"
  ],
  "plant the incriminating letter on gaius maro's body": [
    "Pone Epistulam Criminalem in Corpus Gaii Maronis"
  ],
  "maleeyeshumanrightblindsingle": [
    "MaleEyesHumanRightBlindSingle"
  ],
  "door to weylin's room": [
    "Ianuam ad conclave Weylinis"
  ],
  "glass sword of harrowing": [
    "Gladius Vitreus Occationis"
  ],
  "morthal guard": [
    "Custos Morthalis"
  ],
  "maleeyesorciceblue": [
    "MaleEyesOrcIceBlue"
  ],
  "thieves guild dialogue scene 09": [
    "Sodalitatis Furum Dialogue Scene 09"
  ],
  "silver hand warrior": [
    "Manus Argenteae Bellator"
  ],
  "you sent that thug after me": [
    "Operam contra me misisti"
  ],
  "letter of credit": [
    "Epistula Credentiae"
  ],
  "kill cicero, or leave the sanctuary": [
    "Neca Ciceronem, Vel e Fano Exi"
  ],
  "ysgramor": [
    "Ysgramoris"
  ],
  "steel shield of dwindling fire": [
    "Adamanteum Scutum Minoris Ignis"
  ],
  "what do i need": [
    "Quid requiro"
  ],
  "what does it mean to be a companion": [
    "Quid sibi vult Contubernalem esse"
  ],
  "hysteria": [
    "Hysteria"
  ],
  "vilemyr inn": [
    "Diversorium Vilemyris"
  ],
  "enter the dawnstar sanctuary": [
    "Intra Fanum Luciferis"
  ],
  "talk to arnbjorn": [
    "Adloquere Arnbjorn"
  ],
  "locate arnbjorn": [
    "Inveni Arnbjorn"
  ],
  "marksmaleargonianscar04": [
    "MarksMaleArgonianScar04"
  ],
  "nord hero battle axe": [
    "Heroos Germanici Bellisecuris"
  ],
  "glass sword of stunning": [
    "Gladius Vitreus Intermissionis"
  ],
  "search cicero's room": [
    "Cubiculum Ciceronis Investiga"
  ],
  "what exactly will i be doing out there": [
    "Quid faciam in proelio"
  ],
  "the cure for madness": [
    "Cura Insanitatis"
  ],
  "cure for madness": [
    "Cura Insanitatis"
  ],
  "he helped me escape from prison": [
    "Me e carcere effugere adiuvit"
  ],
  "dwarven armor of major health": [
    "Dvemeris Lorica Maioris Salutis"
  ],
  "mythic dawn robes": [
    "Aurorae Mythicae Peplum"
  ],
  "hide helmet of magicka": [
    "Cutis Galea Magicae"
  ],
  "ebony dagger of animus": [
    "Pugio Ebeni Animi"
  ],
  "kill anton virane": [
    "Neca Anton Virane"
  ],
  "if i see it, i'll bring it to you": [
    "Si id invenero, tibi adferam"
  ],
  "recipe for disaster": [
    "Dictamen Caedis"
  ],
  "potion of vigor": [
    "Potio Vigoris"
  ],
  "escape the tower": [
    "Effuge Turrem"
  ],
  "kill the emperor": [
    "Neca Imperatorem"
  ],
  "follow gianna to the dining room": [
    "Sequere Giannam ad Triclinium"
  ],
  "orcish mace of fear": [
    "Orci Maca Timoris"
  ],
  "daedric mace of storms": [
    "Maca Daedrica Tempestatium"
  ],
  "report to commander maro": [
    "Refer te ad Imperatorem Maronem"
  ],
  "dialoguemarkarthtreasuryhousethonarbetridscene01": [
    "DialogueMarkarthTreasuryHouseThonarBetridScene01"
  ],
  "dwarven warhammer of reaping": [
    "Dvemeris Bellimalleus Saturni"
  ],
  "j'kier": [
    "J'Kier"
  ],
  "vald's strongbox": [
    "Arca Secura Valdis"
  ],
  "to kill an empire": [
    "Imperium Necare"
  ],
  "kill an empire": [
    "Imperium Necare"
  ],
  "report to nazir": [
    "Refer te ad Nazir"
  ],
  "(optional) kill amaund motierre": [
    "(Optio) Neca Amaund Motierre"
  ],
  "retrieve the payment": [
    "Accipe Recompensum"
  ],
  "the blades helped me find out about it": [
    "Enses me de eo docuerunt",
    "Enses me de eo docuÃªrunt"
  ],
  "blades helped me find out about it": [
    "Enses me de eo docuerunt",
    "Enses me de eo docuÃªrunt"
  ],
  "report to amaund motierre": [
    "Refer te ad Amaund Motierre"
  ],
  "talk to amaund motierre": [
    "Adloquere Amaund Motierre"
  ],
  "talk to nazir": [
    "Adloquere Nazir"
  ],
  "calixto corrium was the butcher": [
    "Calixto Corrium erat Lanius"
  ],
  "steel greatsword of frost": [
    "Spatha Adamantea Gelidus"
  ],
  "kill the target": [
    "Neca Electum"
  ],
  "speak with the contact": [
    "Adloquere Clientem"
  ],
  "approach the night mother": [
    "Appropinqua Matri Noctis"
  ],
  "kill narfi": [
    "Neca Narfem"
  ],
  "i have potema's remains": [
    "Reliquias Potemae habeo"
  ],
  "i'd like to purchase a house in the city": [
    "Volo domum in urbe emere"
  ],
  "(<global=hpsolitude> gold)": [
    "(<Global=HPSolitude> auri)"
  ],
  "vilkas's sword": [
    "Gladius Vilkae"
  ],
  "sounds fair": [
    "Aequum videtur"
  ],
  "(gain <global=wiadditem07paya> gold)": [
    "(Accipe <Global=WIAddItem07PayA> auri)"
  ],
  "blackmail, eh": [
    "Catillatio, eh"
  ],
  "tell me more": [
    "Dic mihi plus"
  ],
  "suspicious cabinet": [
    "Armarium Suspectus"
  ],
  "kill beitild": [
    "Neca Beitild"
  ],
  "femalemouthhumanoiddefault": [
    "FemaleMouthHumanoidDefault"
  ],
  "tell me about the heist jobs": [
    "Narra mihi de munera \"heist.\""
  ],
  "dragonscale boots of grounding": [
    "Caligae Draconisquamatae Minoris Electricitatis"
  ],
  "kill hern": [
    "Neca Hern"
  ],
  "iron ingot": [
    "Ferri Later"
  ],
  "nerien": [
    "Nerien"
  ],
  "kill lurbuk": [
    "Neca Lurbuk"
  ],
  "contract: kill lurbuk": [
    "Foedus: Neca Lurbuk"
  ],
  "first time i ever set out to return something": [
    "Tempus primum in quo aliquid reditum profectus sum"
  ],
  "steel dagger of ice": [
    "Pugio Adamanteus Gelidus"
  ],
  "neutral": [
    "Neuter"
  ],
  "mistwatch jail key": [
    "Clavis ad Carcerem Nebulavisum"
  ],
  "locate a clue about brand-shei's past": [
    "Inveni Vestigia Praeteritorum Brand-Shei"
  ],
  "femaleheadelder": [
    "FemaleHeadElder"
  ],
  "daedric warhammer of annihilating": [
    "Bellimalleus Daedricus Annihilationis"
  ],
  "contract: kill deekus": [
    "Foedus: Neca Deekus"
  ],
  "glass boots of fire suppression": [
    "Vitreae Caligae Suppressionis Ignis"
  ],
  "forsworn faction": [
    "Forsworn Faction"
  ],
  "ebony sword of the sorcerer": [
    "Gladius Ebeni Magi"
  ],
  "huki seven-swords": [
    "Huki Septem-Gladii"
  ],
  "femaleeyesdarkelfred": [
    "FemaleEyesDarkElfRed"
  ],
  "return the skeleton key to the ebonmere": [
    "Redde Clavem Adulterem Ebonmeri"
  ],
  "mudcrab guardian spirit": [
    "Animus Custodis Canceriluti"
  ],
  "fort sungard": [
    "Fort Sungard"
  ],
  "(forcegreet)": [
    "(forcegreet)"
  ],
  "iron boots of strength": [
    "Ferri Caligae Fortitudinis"
  ],
  "nana ildene's medicine": [
    "Medicina Nanae Ildenis"
  ],
  "what do you do for the stronghold": [
    "Quid pro arce facis"
  ],
  "fort snowhawk prison key": [
    "Clavis ad Carcerem Castelli Accipitrinignuis"
  ],
  "blood-brother": [
    "Blood-Brother"
  ],
  "i have your silver mold": [
    "Exemplar Argentei tibi habeo"
  ],
  "follow kyr and ra'jirr into the tunnels": [
    "Sequere Kyr et Ra'jirr in cunicula"
  ],
  "contract: kill maluril": [
    "Foedus: Neca Maluril"
  ],
  "removed": [
    "Extractus"
  ],
  "anything you need done around here": [
    "Estne quid hic faciendum"
  ],
  "i'm not really heading that way": [
    "Illic non peto"
  ],
  "dragonsreach servants": [
    "Dragonsreach Servants"
  ],
  "driftshade refuge": [
    "Refugium Vibrumbrae"
  ],
  "kill safia": [
    "Neca Safiam"
  ],
  "you play well": [
    "Bene canis"
  ],
  "contract: kill safia": [
    "Foedus: Neca Safia"
  ],
  "flame atronach": [
    "Atronachum Flammarum"
  ],
  "nocturnal's clothes": [
    "Vestimenta Nocturnalis"
  ],
  "dialogueivarstead": [
    "DialogueIvarstead"
  ],
  "listen to tolfdir": [
    "Ausculta Tolfdir"
  ],
  "tour the college of winterhold": [
    "Explora Collegium Hiemiterrae"
  ],
  "report to mirabelle ervine": [
    "Refer te ad Mirabelle Ervine"
  ],
  "pyromancer": [
    "Ingimagus"
  ],
  "ebony battleaxe of garnering": [
    "Bellisecuris Ebeni Horti"
  ],
  "elven sword of dread": [
    "Alfus Gladius Timoris"
  ],
  "cast a mage light spell": [
    "Fac Magilucem"
  ],
  "steel battleaxe of soul snares": [
    "Bellisecuris Adamanteus Animicapti"
  ],
  "i think you need to shut your mouth": [
    "Credo te os claudere debere"
  ],
  "alain dufont now lies dead": [
    "Alanus Dufons nunc mortuus est"
  ],
  "glass warhammer of winnowing": [
    "Bellimaleus Vitreus Ventagii"
  ],
  "j'datharr": [
    "J'datharr"
  ],
  "\"honor\" is a coward's tool": [
    "\"Honor\" est instrumentum ignavi"
  ],
  "riften haelga's bunkhouse faction": [
    "Hiati Haelga's Bunkhouse Faction"
  ],
  "sneak into the fort": [
    "Clam Castellum Intra"
  ],
  "agnis is dead": [
    "Agnmortuus est mortua est"
  ],
  "looking for content": [
    "Res quaerens"
  ],
  "please wait": [
    "Exspecta, quaeso"
  ],
  "i'm answering your summons, master": [
    "Respondeo ad vocationem tuam, Domine"
  ],
  "let's do it": [
    "Id faciamus"
  ],
  "find the danger within saarthal": [
    "Inveni Periculum in Saarthali"
  ],
  "tell tolfdir about the vision": [
    "Narra Tolfdir de Visu"
  ],
  "general tullius ordered my execution": [
    "Imperator Tullius supplicio meo imperavit"
  ],
  "why would i want to help him": [
    "Cur eam adiuvam"
  ],
  "daedric bow of exhaustion": [
    "Arcus Daedricus Exhaustionis"
  ],
  "search for magical artifacts (<global=mg02itemsfound>/<global=mg02itemstotal>)": [
    "Quaere Res Magicas (<Global=MG02ItemsFound>/<Global=MG02ItemsTotal>)"
  ],
  "find arniel gane": [
    "Inveni Arniel Gane"
  ],
  "naris the wicked": [
    "Naris Scelestus"
  ],
  "find the courier": [
    "Inveni Cursorum"
  ],
  "follow tolfdir": [
    "Sequere Tolfdirem"
  ],
  "orcish sword of weariness": [
    "Orci Gladius Maioris Defatigationis"
  ],
  "bring me a sigil stone": [
    "Fer mihi Saxum Sigile"
  ],
  "why don't you go tell sibbi yourself": [
    "Quidni tu ipsi Sibbi narres"
  ],
  "locate grimsever": [
    "Inveni Grimsever"
  ],
  "yes, i'm sure": [
    "Sic, certus sum"
  ],
  "they're looking for someone named esbern": [
    "Aliquem nomine Esbernem quaerunt"
  ],
  "meet tolfdir outside saarthal": [
    "Conveni cum Tolfdir foris Saarthalis"
  ],
  "glass bow of debilitation": [
    "Arcus Vitreus Debilitationis"
  ],
  "what do you know about margret": [
    "Quid de Margarita scis"
  ],
  "flawless": [
    "Perfectum"
  ],
  "verner and annekke's house": [
    "Verneris Annekisque Domus"
  ],
  "j'zargo": [
    "J'zargo"
  ],
  "reverent iron war axe": [
    "Reverens Bellisecuris Ferri"
  ],
  "heard any rumors lately": [
    "Audivistine quas rumores"
  ],
  "orcish dagger of fear": [
    "Orci Pugio Timoris"
  ],
  "tolfdir services": [
    "Tolfdir Services"
  ],
  "key to the hag's cure": [
    "Clavis ad Curam Sagae"
  ],
  "creaturedialoguecow": [
    "CreatureDialogueCow"
  ],
  "what's our next move against the empire": [
    "Quid proximum contra Imperium"
  ],
  "tell me about clan battle-born": [
    "Dic mihi de Gente Proeli-Natus"
  ],
  "fort neugrad library key": [
    "Clavis ad Bibliothecam Castelli Neugrad"
  ],
  "under saarthal": [
    "Sub Saarthali"
  ],
  "find the stolen books (<global=mg03booksfound>/<global=mg03bookstotal>)": [
    "Inveni Libros Furtivos (<Global=MG03BooksFound>/<Global=MG03BooksTotal>)"
  ],
  "why are you still here": [
    "Cur hic iam es"
  ],
  "maleeyeswoodelfblind": [
    "MaleEyesWoodElfBlind"
  ],
  "speak with mirabelle ervine": [
    "Adloquere Mirabelle Ervine"
  ],
  "report to savos aren": [
    "Refer te ad Savos Aren"
  ],
  "find the augur of dunlain": [
    "Inveni Augurem Dunlainis"
  ],
  "you'll stay that way for now": [
    "Hoc in modo manebis"
  ],
  "follow ancano": [
    "Sequere Ancanem"
  ],
  "n'gasta": [
    "N'Gasta"
  ],
  "kvata": [
    "Kvata"
  ],
  "kvakis": [
    "Kvakis"
  ],
  "raw beef": [
    "Carnis Incoctus"
  ],
  "wisp": [
    "Floccus"
  ],
  "detect life enemy exterior": [
    "Detect Life Enemy Exterior"
  ],
  "second planting": [
    "Secundum Spretum"
  ],
  "he's expecting me": [
    "Me exspectat"
  ],
  "good intentions": [
    "Cogitata Bona"
  ],
  "humanbeardlong15_1bit": [
    "HumanBeardLong15_1bit"
  ],
  "elven dagger of animus": [
    "Pugio Alfus Animi"
  ],
  "and that tree": [
    "Et ille arbor"
  ],
  "is that eldergleam": [
    "estne Eldergleam"
  ],
  "defeat the creatures infesting winterhold (<global=mg05creatureskilled>/<global=mg05creaturestotal>)": [
    "Neca Inimicos Hiemiterrae (<Global=MG05CreaturesKilled>/<Global=MG05CreaturesTotal>)"
  ],
  "escort telrav to his camp": [
    "Fer Telravem ad Castra"
  ],
  "protect the town of winterhold": [
    "Tege Oppidum Hiemiterrae"
  ],
  "find the arch-mage": [
    "Inveni Archimagum"
  ],
  "assess the damage to the great hall": [
    "Disce de destructione ad Aulam Magnam"
  ],
  "volsung": [
    "Volsung"
  ],
  "mg dormitory scene 2": [
    "MG Dormitory Scene 2"
  ],
  "alfarinn": [
    "Alfarinn"
  ],
  "containment": [
    "Retinens"
  ],
  "reach ancano": [
    "Ancanum Adveni"
  ],
  "focus the oculory": [
    "Oculatorium Defige"
  ],
  "emperor titus mede ii": [
    "Imperator Titus Medus II"
  ],
  "ms11": [
    "MS11"
  ],
  "follow paratus": [
    "Sequere Paratum"
  ],
  "return the crystal to paratus": [
    "Redde Crystalum Parato"
  ],
  "the battle of fort sungard": [
    "Proelium Castelli Solicustodis"
  ],
  "battle of fort sungard": [
    "Proelium Castelli Solicustodis"
  ],
  "malign aversion to magic": [
    "Aversio Maligna Magicae"
  ],
  "find the focusing crystal": [
    "Inveni Crystallum Foci"
  ],
  "reach the oculory": [
    "Adveni Oculatorium"
  ],
  "you are carrying too much to be able to run": [
    "Nimis habes ut currere possis"
  ],
  "find the ruins of mzulft": [
    "Inveni ruinas Mzulftis"
  ],
  "iron greatsword of weariness": [
    "Spatha Ferri Maioris Defatigationis"
  ],
  "hi. svari asked me to talk to you": [
    "Salve, Svari me oravit ut te adloquerer"
  ],
  "dwarven telescope": [
    "Dvemere Telescopium"
  ],
  "the legendary sancre tor": [
    "Legendum Turris Sacrae"
  ],
  "legendary sancre tor": [
    "Legendum Turris Sacrae"
  ],
  "return to tolfdir": [
    "Reveni ad Tolfdirem"
  ],
  "escape the thalmor": [
    "Effuge e Visigothis"
  ],
  "exit labrynthian": [
    "Exit e Labryintho"
  ],
  "i heard you're offering extra work": [
    "Audivi te plus laboris offerre"
  ],
  "lip color": [
    "Color Labiae"
  ],
  "talk to tolfdir": [
    "Adloquere ad Tolfdirem"
  ],
  "steel mace of souls": [
    "Maca Adamantea Animorum"
  ],
  "you don't have enough ammo": [
    "Non satis saggitarum habes"
  ],
  "iron war axe of draining": [
    "Bellisecuris Ferri Hauriendi"
  ],
  "reach the hall of the elements": [
    "Adveni ad Atrium Elementarum"
  ],
  "daedric helmet of extreme archery": [
    "Galea Daedrica Extremis Saggitationis"
  ],
  "use the staff of magnus to enter the college of winterhold": [
    "Utere Rudi Magni ut Intres Collegium Hiemiterrae"
  ],
  "guardhouse": [
    "Domus Praesidii"
  ],
  "daedric war axe of debilitation": [
    "Bellisecuris Daedricus Debilitationis"
  ],
  "gabriella": [
    "Gabriella"
  ],
  "let's never speak of this again": [
    "De hoc numquam iterum loquamur"
  ],
  "frida": [
    "Frida"
  ],
  "iron helmet of destruction": [
    "Ferri Galea Destructionis"
  ],
  "what is it? what happened": [
    "Quid est? Quid accidit"
  ],
  "how can you be certain the journals exist": [
    "Quomodo ephemeres exstare scire potes"
  ],
  "faction for party guards": [
    "faction for party guards"
  ],
  "voada": [
    "Voada"
  ],
  "scaled armor of the eminent squire": [
    "Lorica Squamata Eminentis Armiferis"
  ],
  "winterhold stormcloak camp": [
    "Hiemiterrae Sturmmantelorum Castra"
  ],
  "why bother poisoning the nest for sabjorn": [
    "Cur venenum in nidum pro Sabjorn ponere debeo"
  ],
  "job's finished. here's the information you requested": [
    "Munus factum est. Hic habes informatum quod rogavisti"
  ],
  "speak to jorleif for reward": [
    "Adloquere Jorleifem pro Praemio"
  ],
  "flaming thrall": [
    "Servus Ignifer"
  ],
  "some kind of choir": [
    "Aliquae capella"
  ],
  "with chanting": [
    "Cantans"
  ],
  "dwarven mace": [
    "Dvemeris Maca"
  ],
  "dragonplate boots of muffling": [
    "Draconilaminata Caligae Silentii"
  ],
  "speak to wuunferth": [
    "Adloquere Wuunferth"
  ],
  "orc warrior": [
    "Orc Warrior"
  ],
  "what can you tell me about darkwater crossing": [
    "Quid mihi dicere potes de Vado Aquae Obscurae"
  ],
  "maleheadkhajiit": [
    "MaleHeadKhajiit"
  ],
  "i am sorry": [
    "Ignosce mihi"
  ],
  "do you have any fishing jobs": [
    "Habesne negotia \"Piscari\""
  ],
  "take the lexicon to avanchnzel": [
    "Fer Lexicon ad Avanchnzel"
  ],
  "unfathomable depths": [
    "Ima Ignota"
  ],
  "clear out aringoth's safe": [
    "Evacua Arcam Securem Aringothis"
  ],
  "the mind of madness": [
    "Mens Insanitatis"
  ],
  "mind of madness": [
    "Mens Insanitatis"
  ],
  "(optional) enter goldenglow using sewer": [
    "(Optio) Intra Aurilucem per cloacam"
  ],
  "ri'saad": [
    "Riaad"
  ],
  "burn three bee hives (<global=tg02hivecount>/3)": [
    "Accende Tria Alvaria (<Global=TG02HiveCount>/3)"
  ],
  "magicka poison": [
    "Venenum Magicae"
  ],
  "steel dagger": [
    "Pugio Adamanteus"
  ],
  "audiotemplateatronachstorm": [
    "AudioTemplateAtronachumStorm"
  ],
  "glass boots of frost suppression": [
    "Vitreae Caligae Contra Glaciem"
  ],
  "i haven't decided what to do yet": [
    "Nondum quid facerem decrevi"
  ],
  "stonehills mine scene 4": [
    "Stonehills Mine Scene 4"
  ],
  "thoring": [
    "Thoring"
  ],
  "pale imperial camp": [
    "Pallidi Imperialis Castra"
  ],
  "lylvieve house key": [
    "Clavis Domum Lylvievis"
  ],
  "daedric war axe of devouring": [
    "Bellisecuris Daedricus Consumendi"
  ],
  "have you lived here long": [
    "Hicne multum temporis egisti"
  ],
  "hunting bow of dismay": [
    "Arcus Venandi Timoris"
  ],
  "karl": [
    "Karl"
  ],
  "return to mercer frey": [
    "Refer te ad Mercer Frey"
  ],
  "confront gulum-ei": [
    "Adloquere Gulum-Ei"
  ],
  "shadow gulum-ei": [
    "Clam Sequere Gulum-Ei"
  ],
  "jarrin root": [
    "Radix Jarrinis"
  ],
  "get information from gulum-ei": [
    "Accipe information e Gulum-Ei"
  ],
  "daedric sword of despair": [
    "Gladius Daedricus Desponsionis"
  ],
  "elven mace of banishing": [
    "Maca Alfa Expellendi"
  ],
  "so what's the job": [
    "Ergo, quid est labor"
  ],
  "even after everything i did for you and faleen": [
    "Etiam post omnia quae pro te et Faleen feci"
  ],
  "faction - kyne's peace": [
    "Faction - Kyne's Peace"
  ],
  "tell me everything and you won't end up like your goat": [
    "Dic mihi omnia et ut caper tuus non eris"
  ],
  "2920, evening star, v12": [
    "[MMCMXX] 2920, Hesperus, V. XII"
  ],
  "i need that dragon slaying blessing": [
    "Volo illam benedictionem Dracones necandi"
  ],
  "scoundrel's folly": [
    "Fatuitas Furciferis"
  ],
  "they say hroggar burned down his house for you": [
    "Dicunt Hroggarem domum suum pro te incendisse"
  ],
  "arcturus": [
    "Arcturus"
  ],
  "amulet of mara": [
    "Monile Marae"
  ],
  "dialoguefalkreathcemeteryscene02": [
    "DialogueFalkreathCemeteryScene02"
  ],
  "trap trigger": [
    "Trap Trigger"
  ],
  "glass greatsword of devouring": [
    "Spatha Vitrea Consumendi"
  ],
  "hide helmet of major conjuring": [
    "Cutis Galea Maioris Vocandi"
  ],
  "depart for japhet's folly": [
    "Pete Fatuitatem Japhetis"
  ],
  "i was happy to help": [
    "Gaudebam adiuvare"
  ],
  "daedric battleaxe of annihilating": [
    "Bellisecuris Daedricus Annihilationis"
  ],
  "charred skeever hide": [
    "Reno Coctus Skeever"
  ],
  "find king olaf's verse": [
    "Inveni Carmen Regis Olafis"
  ],
  "which of the eight divines do you serve": [
    "Quem ex Octo Divis colis"
  ],
  "he's a friend. he promised you could help": [
    "Amicus est. Mihi promissit te adiuvare posse"
  ],
  "why are the thalmor after you": [
    "Cur Visigothi te persequntur"
  ],
  "daedric battleaxe of stunning": [
    "Bellisecuris Daedricus Intermissionis"
  ],
  "let's get this started": [
    "Incipiamus"
  ],
  "i need to trap a dragon in your palace": [
    "Debeo insidias draconi in aula tua parare"
  ],
  "stop being stubborn": [
    "Desiste ab obstinatione"
  ],
  "what can you tell me about yourself": [
    "Quid de te mihi dicere potes"
  ],
  "iron war axe of torpor": [
    "Bellisecuris Ferri Torporis"
  ],
  "nightmare problem": [
    "Difficultas Suppressionis"
  ],
  "what do i need to know about potema": [
    "Quid mihi de Potema sciendum est"
  ],
  "daedric dagger of despair": [
    "Pugio Daedricus Desponsionis"
  ],
  "glass sword of fire": [
    "Gladius Vitreus Ignis"
  ],
  "dwemer lever": [
    "Dvemere Fulcrum"
  ],
  "horker abilities": [
    "Horker abilities"
  ],
  "glass dagger of expelling": [
    "Pugio Vitreus Expellendi"
  ],
  "nimriel": [
    "Nimriel"
  ],
  "choose nightingale role": [
    "Elige Viam Philomelae"
  ],
  "humanbeard23": [
    "HumanBeard23"
  ],
  "deeja": [
    "Deeja"
  ],
  "listen to nocturnal": [
    "Ausculta Nocturnalem"
  ],
  "kill anoriath": [
    "Neca Anoriathem"
  ],
  "recover erj's journal": [
    "Inveni Ephemeridem Erjis"
  ],
  "muiri": [
    "Muiri"
  ],
  "imperial sword of embers": [
    "Gladius Romanus Cinerum"
  ],
  "drelas": [
    "Drelas"
  ],
  "browsmaleargonian00": [
    "BrowsMaleArgonian00"
  ],
  "plant madesi's ring": [
    "Depone Anulum Madesi"
  ],
  "ebony war axe of leeching": [
    "Bellisecuris Ebeni Sanguisugae"
  ],
  "why does he need to die": [
    "Cur mori debet"
  ],
  "steel shield of blocking": [
    "Adamanteum Scutum Interponendi"
  ],
  "entrances to dungeons": [
    "Entrances to Dungeons"
  ],
  "steal madesi's ring": [
    "Furare Anulum Madesi"
  ],
  "east empire dockworker": [
    "Euri Imperii Laborator Navalis"
  ],
  "phinis gestor": [
    "Phinis Gestor"
  ],
  "key to the abandoned house": [
    "Clavis ad Domum Derelictam"
  ],
  "meet brynjolf during daytime": [
    "Adiungere Brynjolf Interdiu"
  ],
  "a chance arrangement": [
    "Comparatio Fortuita"
  ],
  "chance arrangement": [
    "Comparatio Fortuita"
  ],
  "speak to molag bal": [
    "Adloquere Molag Bol"
  ],
  "cowflop farmhouse": [
    "Villa Rustica Vaccae Cadentis"
  ],
  "interface\\shared\\messages\\glow_xp_gain.dds": [
    "Interface\\Shared\\Messages\\glow_XP_Gain.dds"
  ],
  "db11amaundplayerresponse4": [
    "DB11AmaundPlayerResponse4"
  ],
  "free logrolf": [
    "Libera Logrolf"
  ],
  "imperial light shield of waning shock": [
    "Romana Parma Insenescentis Electricitatis"
  ],
  "elven dagger of debilitation": [
    "Pugio Alfus Debilitationis"
  ],
  "armor - oak": [
    "Lorica - Robur"
  ],
  "solitude guard's armor": [
    "Solitudinis Custodis Lorica"
  ],
  "receive the first set of contracts from nazir": [
    "Accipe primam gregem foederum e Nazir"
  ],
  "elven dagger of evoking": [
    "Pugio Alfus Evocandi"
  ],
  "mythic dawn commentaries 2": [
    "Commentarii Aurorae Mythicae II"
  ],
  "this is a complex contract. i need more details": [
    "Sponsum complicatum est. Plurima minutia quaero"
  ],
  "speak with nazir": [
    "Adloquere Nazir"
  ],
  "are you always so happy": [
    "Esne semper tam laeta"
  ],
  "i have some questions about margret": [
    "Interrogata de Margret tibi habeo"
  ],
  "museum pamphlet": [
    "Libellum Mouseion"
  ],
  "companion ghost": [
    "Umbra Amica"
  ],
  "defeat deeja": [
    "Deejam vince"
  ],
  "find deeja at the wreck of the icerunner": [
    "Inveni Deejam ad Naufragium Glacicursoris"
  ],
  "return to jaree-ra": [
    "Reveni ad Jaree-Ra"
  ],
  "ebony armor of major health": [
    "Ebeni Lorica Maioris Salutis"
  ],
  "calixto's key": [
    "Clavis Calixtonis"
  ],
  "listen to jaree-ra's offer in solitude": [
    "Ausculta offertum Jaree-Rae Solitudinis"
  ],
  "the save game is no longer available": [
    "Ludus non iam inveniri potest"
  ],
  "save game is no longer available": [
    "Ludus non iam inveniri potest"
  ],
  "returning to game": [
    "Returning to game",
    "Ad ludum reveniens"
  ],
  "scroll of raise zombie": [
    "Codex Revocandi Corpus Debile"
  ],
  "i've killed haldyn": [
    "Haldyn necavi"
  ],
  "iron warhammer of weariness": [
    "Bellimalleus Ferratus Maioris Defatigationis"
  ],
  "you might be a decent thief, but you're an awful actor": [
    "Fur satis bene es, sed actor terribilis"
  ],
  "my wealth is none of your business": [
    "Divitiae meae tibi non contingunt"
  ],
  "bring fjotra to the temple": [
    "Fer Fjotram ad Templum"
  ],
  "rescue fjotra from the forsworn": [
    "Serva Fjotra e Abiuratis"
  ],
  "where am i headed again": [
    "Quo eo, iterum"
  ],
  "scroll of harmony": [
    "Codex Harmoniae"
  ],
  "elven helmet of conjuring": [
    "Alfa Galea Vocandi"
  ],
  "find the future sybil of dibella": [
    "Inveni Sybillam Futuram Dibellae"
  ],
  "ebony greatsword of thunderbolts": [
    "Spatha Ebeni Fulminum"
  ],
  "stonehills dialogue": [
    "Stonehills Dialogue"
  ],
  "blessed imperial sword": [
    "Sanctificatus Gladius Imperialis"
  ],
  "when you get a new quest, it will not become active unless you have no other active quests": [
    "Post munus novum accipis, non activum erit nisi nulla alia munera activa habeas"
  ],
  "check your journal to see which quests are active": [
    "Vide in ephemeride ut munera activa videas"
  ],
  "enter the temple of dibella": [
    "Intra Templum Dibellae"
  ],
  "stand on vacant floor glyph": [
    "Sta in Runo Vacanti"
  ],
  "what kind of trouble should i expect": [
    "Qualem difficultatem exspectare debeo"
  ],
  "i heard something happened to the tree at the temple": [
    "Audivi aliquid arbori ad templum accidere"
  ],
  "leather": [
    "Cutis"
  ],
  "<alias=player>'s <basename>": [
    "<BaseName> Tuus"
  ],
  "rogue": [
    "Rogue"
  ],
  "vidgrod": [
    "Vidgrod"
  ],
  "who's in charge of the smelter": [
    "Quis auricoctori praeest"
  ],
  "alvor": [
    "Alvor"
  ],
  "throw voice effect": [
    "Throw Voice Effect"
  ],
  "father of the niben": [
    "Pater Nibenis"
  ],
  "jeweled goblet": [
    "Gemmatum Poculum"
  ],
  "the warrior's charge": [
    "Munus Bellatoris"
  ],
  "warrior's charge": [
    "Munus Bellatoris"
  ],
  "what does the guild get if i do that": [
    "Quid accipiet Sodalitas si eo potiar"
  ],
  "any idea where i can find some ice wraiths": [
    "Scisne ubi phantasmas glaciei invenire possem"
  ],
  "the warrior stone": [
    "Lapis Bellatoris"
  ],
  "warrior stone": [
    "Lapis Bellatoris"
  ],
  "the stumbling sabrecat": [
    "Felis Mucronis Claudicans"
  ],
  "stumbling sabrecat": [
    "Felis Mucronis Claudicans"
  ],
  "scaled helmet of alchemy": [
    "Galea Squamata Alchemiae"
  ],
  "kill the leader of <alias=location>": [
    "Neca Ducem ad <Alias=Location>"
  ],
  "outer ring": [
    "Circulus Exterior"
  ],
  "dwarven dagger of arcing": [
    "Dvemeris Pugio Electricitatis"
  ],
  "reverent dwarven sword": [
    "Reverens Gladius Dvemeris"
  ],
  "you're a vigilant of stendarr": [
    "Vigil Stendarris es"
  ],
  "return to whiterun and warn the jarl": [
    "Reveni Albacursum et Comitem Mone"
  ],
  "jorgen's chest": [
    "Arca Jorgenis"
  ],
  "mace of molag bal enchantment": [
    "Mace of Molag Bal Enchantment"
  ],
  "haelga's bunkhouse scene 09": [
    "Haelga's Bunkhouse Scene 09"
  ],
  "sleeping tree sap failure": [
    "Sleeping Tree Sap Failure"
  ],
  "expert calcinator": [
    "Expert Calcinator"
  ],
  "i'm ready for your test": [
    "Paratus sum probationi tuae"
  ],
  "silverdrift lair": [
    "Argenteidrift Lair"
  ],
  "shaman's key": [
    "Clavis Shamanis"
  ],
  "assassinate the silver hand leader": [
    "Neca ducem Manus Argentei"
  ],
  "striking the heart": [
    "Cor Iciendi"
  ],
  "mistveil keep jarl's chambers": [
    "Cubicula Comitis Castelli Nexusvelati"
  ],
  "return to morthal's jarl": [
    "Redi ad Comitem Morthalis"
  ],
  "lodvar": [
    "Lodvar"
  ],
  "investigate alva's house": [
    "Investigata Villam Alvae"
  ],
  "elven bow of lethargy": [
    "Arcus Alfus Lethargiae"
  ],
  "angvid": [
    "Angvid"
  ],
  "ask thonnir about laelette": [
    "Roga Thonnir de Laelette"
  ],
  "ask around about laelette": [
    "Roga de Laeletta"
  ],
  "speak with the leader of the companions": [
    "Adloquere ducem Contubernalium"
  ],
  "find helgi after dark": [
    "Inveni Helgam nocte"
  ],
  "elder's cairn door": [
    "Porta Maiorum Monolithos"
  ],
  "(optional) tell the jarl about helgi": [
    "(Optio) Dic Comiti de Helga"
  ],
  "vittoria vici's house": [
    "Vittoria Vici House"
  ],
  "quaranir": [
    "Quaranir"
  ],
  "apprentice": [
    "Apprentice"
  ],
  "daedric war axe of exhaustion": [
    "Bellisecuris Daedricus Exhaustionis"
  ],
  "falkreath guard's armor": [
    "Falkretiae Custodis Lorica"
  ],
  "dragon ability giving them all damage bllod fx": [
    "Dragon Ars giving them all vulneris bllod fx"
  ],
  "tolfdir the absent-minded": [
    "Tolfdir Absent-Minded"
  ],
  "return to drevis neloren": [
    "Redi ad Drevis Neloren"
  ],
  "out of balance": [
    "Foris Aequilibrio"
  ],
  "emperor's robes": [
    "Imperatoris Peplum"
  ],
  "meet with the <alias=general> on the front line": [
    "Adiunge <Alias=General> in Primo Ordine"
  ],
  "unh, my head": [
    "Unh, caput meum"
  ],
  "(invisible continue) location of attack": [
    "(Invisible Continue) Location of Attack"
  ],
  "i'm looking for a job": [
    "Munus quaero"
  ],
  "whiterun city dialogue": [
    "Whiterun City Dialogue"
  ],
  "return the <alias=itemtoget> to delvin": [
    "Refer <Alias=ItemToGet> ad Delphinum"
  ],
  "daedric battleaxe of terror": [
    "Bellisecuris Daedricus Terroris"
  ],
  "the fishing job": [
    "Munus \"Piscari\""
  ],
  "fishing job": [
    "Munus \"Piscari\""
  ],
  "valthume": [
    "Valthume"
  ],
  "he cares about you": [
    "De te curat"
  ],
  "return the <alias=itemtoget> to vex": [
    "Redde <Alias=ItemtoGet> ad Vex"
  ],
  "thalmor dialogue": [
    "Visigothi dialogue"
  ],
  "potion of stamina": [
    "Potio Staminae"
  ],
  "what's going wrong in morthal": [
    "Quid est difficultas Morthali"
  ],
  "shaircolor5": [
    "sHairColor5"
  ],
  "retrieve the <alias=itemtoget> from <alias=business> in <alias=city>": [
    "Refer <Alias=ItemToGet> e <Alias=Business> in <Alias=City>"
  ],
  "ms02escapetrigger": [
    "MS02EscapeTrigger"
  ],
  "imperial bracers of conjuring": [
    "Chirothecae Romanae Vocandi"
  ],
  "the heist job": [
    "Munus \"Heist\""
  ],
  "heist job": [
    "Munus \"Heist\""
  ],
  "i never loved you, anyway": [
    "Te numquam amavi"
  ],
  "-conditioned not to start- mourning": [
    "-CONDITIONED NOT TO START- Mourning"
  ],
  "elixir of the berserker": [
    "Commixtum Berserkris"
  ],
  "return the item to sergius turrianus": [
    "Refer Rem ad Sergium Turrianum"
  ],
  "commoner": [
    "Plebs"
  ],
  "letter from <alias=enemy>": [
    "Epistula a <Alias=Enemy>"
  ],
  "go to <alias.shortname=client> in <alias=clientlocation> and pick up the item": [
    "Ii ad <Alias.ShortName=Client> in <Alias=ClientLocation> et rem cape"
  ],
  "enchanting pick-up": [
    "Redditum Incantans"
  ],
  "hide shield of resist shock": [
    "Scutum Cutis Contra Vim Electricam"
  ],
  "filling soul gems": [
    "Gemmanimas Complens"
  ],
  "back on its feet": [
    "Iterum pedibus"
  ],
  "i'm ready for the first set of contracts": [
    "Paratus sum primis foederibus"
  ],
  "glass dagger of dread": [
    "Pugio Vitreus Timoris"
  ],
  "once the player gives the cow to the giant they are both permamently added to this faction": [
    "Once the player gives the cow to the giant they are both permamently added to this faction"
  ],
  "is there any college business i can assist with": [
    "Estne labor Collegii pro quo auxilio offere possum"
  ],
  "destroy malyn varen's soul": [
    "Dele animum Malyn Varenis"
  ],
  "riften fishery faction": [
    "Hiati Fishery Faction"
  ],
  "college robes": [
    "Collegii Peplum"
  ],
  "festus krex": [
    "Festus Krex"
  ],
  "speak to azura": [
    "Adloquere Azuram"
  ],
  "he's casting spells out in the marsh in the middle of the night": [
    "Magica in paludi media nocte utitur"
  ],
  "nchuand-zel excavation site": [
    "Nchuand-Zel Excavatio Locus"
  ],
  "this was the only way to get <alias.shortname=jarl>'s help": [
    "Sola via ad auxilium <Alias.Shortname=Jarl> erat"
  ],
  "speak to nelacar": [
    "Nelacarem Adloquere"
  ],
  "you want me to break anything else": [
    "Visne me aliquid frangere"
  ],
  "the black star": [
    "Stella Atra"
  ],
  "black star": [
    "Stella Atra"
  ],
  "i've brought your sybil": [
    "Sybillam tuam tuli"
  ],
  "key to larak's longhouse": [
    "Clavis ad Longidomum Larakis"
  ],
  "resist damage": [
    "Resist Damage"
  ],
  "ulfric stormcloak": [
    "Vercingetorix Temepstatamiculus"
  ],
  "how would that hurt the guild": [
    "Quomodo ille Sodalitati noceat"
  ],
  "glass sword of blizzards": [
    "Gladius Vitreus Hiemis"
  ],
  "scroll of flame cloak": [
    "Codex Amiculi Flammarum"
  ],
  "woodelfskin02": [
    "WoodElfSkin02"
  ],
  "escape from the blue palace": [
    "Effuge ex Aula Caerulea"
  ],
  "use the wabbajack to escape from pelagius's mind": [
    "Uti Wabbajack ut e Mente Pelagii Effugias"
  ],
  "who sent you": [
    "Quis te misit"
  ],
  "orcish shield of extreme blocking": [
    "Orci Scutum Extremis Interponendi"
  ],
  "search for dervenin's master in the pelagius wing": [
    "Quaere Dominum Derveninis in Ala Pelagii"
  ],
  "gain access to the pelagius wing of the blue palace": [
    "Intra Alam Pelagii Aulae Caeruleae"
  ],
  "(optional) obtain the key to aringoth's safe": [
    "(Optio) Inveni clavem ad arcam securem Aringothis"
  ],
  "tell me about lurbuk": [
    "Dic mihi de Lurbuk"
  ],
  "destroy the barricade blocking the jarl's house": [
    "Frange Concaedes ante Domum Comitis"
  ],
  "rendezvous with army": [
    "Adiunge exercitum"
  ],
  "steal <global=tgrngold> gold in goods from <alias=city>": [
    "Furare <Global=TGRNGold> Auri Bonorum e <Alias=City>"
  ],
  "glass dagger of blizzards": [
    "Pugio Vitreus Hiemis"
  ],
  "braidwood inn": [
    "Braidwood Inn"
  ],
  "thug": [
    "Opera"
  ],
  "speak to quintus": [
    "Adloquere Quintum"
  ],
  "we found some sort of... orb": [
    "Quendam... globulum invenimus"
  ],
  "tolfdir wanted you to see it": [
    "Tolfdir te id videre voluit"
  ],
  "do you have the key to weylin's room": [
    "Habesne clavem conclavi Veylinis "
  ],
  "orcish mace of scorching": [
    "Orci Maca Adurendi"
  ],
  "new content has been added. you will need to restart to use this content. all unsaved progress will be lost": [
    "Nova addita sunt. Incipe ludum iterum ut his uti possis"
  ],
  "reverent dwarven greatsword": [
    "Reverens Dvemeris Spatha"
  ],
  "sanctified dwarven war axe": [
    "Sanctificatus Dvemeris Bellisecuris"
  ],
  "retrieve the white phial": [
    "Accipe Atrophorion Album"
  ],
  "wait, you mean this golden claw": [
    "Exspecta, de hac ungue loqueris"
  ],
  "daedric mace of damnation": [
    "Maca Daedrica Damnationis"
  ],
  "kill verulus": [
    "Neca Verulum"
  ],
  "lead verulus to the shrine of namira": [
    "Duc Verulum ad aram Namirae"
  ],
  "that didn't take long": [
    "Multum tempus non consumpsit"
  ],
  "convince verulus to follow you": [
    "Suade Verulo ut te sequatur"
  ],
  "key to halted stream mine": [
    "Clavis ad Metallum Rivuli Stagnantis"
  ],
  "spirit of nirn": [
    "Animus Nirni"
  ],
  "dragons have returned to skyrim": [
    "Dracones ad Caelifinem revenÃªrunt"
  ],
  "clear the draugr from reachcliff cave": [
    "Vacua Draugr e Cava Prehensirupti"
  ],
  "dwarven warhammer of cowardice": [
    "Dvemeris Bellimalleus Timoris"
  ],
  "i'd like to learn more about the voice": [
    "Velim plus de Voce discere"
  ],
  "virtuous glass mace": [
    "Virtutis Maca Vitrea"
  ],
  "books": [
    "LIBRI"
  ],
  "apologize or combat": [
    "Apologize or combat"
  ],
  "hairlinefemaleorc06": [
    "HairLineFemaleOrc06"
  ],
  "hevnoraak": [
    "Hevnoraak"
  ],
  "the emperor will die! for sithis and the night mother": [
    "Imperator moriturus est! Sithi et Matri Noctis"
  ],
  "emperor will die! for sithis and the night mother": [
    "Imperator moriturus est! Sithi et Matri Noctis"
  ],
  "what's been going on": [
    "Quid agitur"
  ],
  "raerek": [
    "Raerek"
  ],
  "teeba-ei": [
    "Teeba-Ei"
  ],
  "what is it that you want": [
    "Quid vis"
  ],
  "can't you practice on yourself": [
    "Quidne in te exercere potes"
  ],
  "what's the mission": [
    "Quid munus"
  ],
  "it's good to meet you too, cicero": [
    "Guadeo te cognoscere quoque, Cicero"
  ],
  "hunting bow of chills": [
    "Arcus Venandi Frigidis"
  ],
  "i'm sorry, i don't even remember how i got here": [
    "Me paenitet, nescio quomodo hic advenerim"
  ],
  "marksfemalehumanoid12leftgashr": [
    "MarksFemaleHumanoid12LeftGashR"
  ],
  "elixir of illusion": [
    "Commixtum Inlusionis"
  ],
  "use your \"fire breath\" shout on paarthurnax": [
    "Utere \"Flatu Flammarum\" in Paarthurnacem"
  ],
  "learn the word of power from paarthurnax": [
    "Disce Verbum Potestatis a Paarthurnace"
  ],
  "korvanjund temple": [
    "Templum Korvanjundis"
  ],
  "use the \"clear skies\" shout to open the path": [
    "Utere \"Caelo Amoeno\" ut Viam Aperias"
  ],
  "actually, alain dufont is already dead. i killed him": [
    "Alanus Dufons iam mortuus est. Eum necavi"
  ],
  "the throat of the world": [
    "Guttur Mundi"
  ],
  "recover the elder scroll": [
    "Inveni Codicem Veterrimum"
  ],
  "scroll of turn greater undead": [
    "Codex Amortuos Maiores Repellendi"
  ],
  "what exactly do you need me to do then": [
    "Quid a me vis"
  ],
  "you're still stuck": [
    "Iam haeres"
  ],
  "imperial captain": [
    "Imperialis Centurio"
  ],
  "arniel's shade": [
    "Umbra Arnielis"
  ],
  "i guess i'll have to trust you": [
    "Credo opus mihi esse tibi confidere"
  ],
  "quill of gemination": [
    "Penna Geminationis"
  ],
  "ale": [
    "Vinum"
  ],
  "gallus": [
    "Gallus"
  ],
  "elder knowledge": [
    "Scientia Veterrima"
  ],
  "deliver the crown to general tullius": [
    "Refer Coronam Imperatori Tullio"
  ],
  "nothing i couldn't handle": [
    "Nihil quid administrare non potui"
  ],
  "defeat the draugr (<global=mq103draugrdeadcount>/<global=mq103draugrtotal>)": [
    "Neca Omnes Draugr (<Global=MQ103DraugrDeadCount>/<Global=MQ103DraugrTotal>)"
  ],
  "donnel": [
    "Donnel"
  ],
  "he asked me to kill the giant, then attacked me": [
    "Me oravit ut gigantem necarem, deinde me oppugnavit"
  ],
  "find a way to open the gate": [
    "Inveni Modum in quo Porta Aperietur"
  ],
  "follow legate rikke": [
    "Sequere Legatum Rikke"
  ],
  "aela the huntress": [
    "Aela Venatrix"
  ],
  "faldar's cage key": [
    "Clavis Carcerem Faldaris"
  ],
  "open the hall of stories door": [
    "Aperi ianuam Aulae Fabularum"
  ],
  "find a way past the ambush": [
    "Inveni Viam per Insidias"
  ],
  "meet legate rikke outside korvanjund": [
    "Conveni cum Legato Rikke extra Korvanjund"
  ],
  "the lady: recover stamina": [
    "Domina: Recupera Staminam"
  ],
  "lady: recover stamina": [
    "Domina: Recupera Staminam"
  ],
  "were they supposed to explode": [
    "Displodere debebant"
  ],
  "because they exploded": [
    "Quod disploserunt"
  ],
  "talk to legate rikke": [
    "Alodquere Legatum Rikke"
  ],
  "scaled boots of waning fire": [
    "Caligae Squamatae Insenescentis Ignis"
  ],
  "lover's comfort": [
    "Comfors Amatoris"
  ],
  "skull pedestal": [
    "Bas Calvariae"
  ],
  "i bring an offer from the thieves guild": [
    "Offertum e Sodalitate Furum fero"
  ],
  "dialoguemarkarth": [
    "DialogueMarkarth"
  ],
  "shrouded hand wraps": [
    "Umbratae Racanae Manuum"
  ],
  "whiterun bannered mare convo system 3": [
    "Whiterun Bannered Mare Convo System 3"
  ],
  "return to eltrys": [
    "Redi ad Eltrys"
  ],
  "read nepos's journal": [
    "Lege Ephemeridem Nepotis"
  ],
  "orcish helmet of eminent destruction": [
    "Orci Galea Eminentis Destructionis"
  ],
  "find evidence about thonar": [
    "Inveni Evidentiam de Thonare"
  ],
  "(optional) obtain the key to weylin's room": [
    "(Optio) Inveni clavem ad conclavi Weylinis"
  ],
  "war paint": [
    "Pigmentum Belli"
  ],
  "(optional) talk to margret": [
    "(Optio) Adloquere Margretam"
  ],
  "here's the 500 gold": [
    "Hic habes D auri"
  ],
  "(optional) obtain the key to margret's room": [
    "(Optio) Inveni clavem conclavi Margretae"
  ],
  "read weylin's note": [
    "Lege Epistulam Weylinis"
  ],
  "find evidence about weylin": [
    "Inveni Evidentiam de Weylin"
  ],
  "read margret's journal": [
    "Lege Ephemeridem Margretae"
  ],
  "find evidence about margret": [
    "Inveni Evidentiam de Margreta"
  ],
  "go to the shrine of talos": [
    "Pete Aram Talos"
  ],
  "the forsworn conspiracy": [
    "Coniuratio Abiuratorum"
  ],
  "forsworn conspiracy": [
    "Coniuratio Abiuratorum"
  ],
  "creaturedialoguesabrecat": [
    "CreatureDialogueSabreCat"
  ],
  "m'aiq the liar": [
    "M'aiq Mendax"
  ],
  "whiterun temple convo system 1": [
    "Whiterun Temple Convo System 1"
  ],
  "whiterun dragonsreach convo system 1": [
    "Whiterun Dragonsreach Convo System 1"
  ],
  "haming": [
    "Haming"
  ],
  "faction for party guests": [
    "faction for party guests"
  ],
  "apprentice hammer": [
    "Apprentice Hammer"
  ],
  "i'm fine": [
    "Bene me habeo"
  ],
  "but the night mother has another task for me": [
    "Sed Mater Noctis rem novum mihi habet"
  ],
  "glass warhammer of devouring": [
    "Bellimaleus Vitreus Consumendi"
  ],
  "hide bracers of minor archery": [
    "Chirothecae Cutis Minoris Saggitationis"
  ],
  "riverwood lucan's dry goods conversations 1": [
    "Riverwood Lucanis Dry Goods Conversations 1"
  ],
  "potion of haggling": [
    "Potio Arguendi"
  ],
  "fight over item": [
    "Fight over item"
  ],
  "protect the jarl to prevent his surrender": [
    "Tege Comitem ut Traditionem Eius Veta"
  ],
  "are you going to be all right": [
    "Erisne bene"
  ],
  "return to dinya balu": [
    "Redi ad Dinyam Balum"
  ],
  "find fenrig": [
    "Inveni Fenrig"
  ],
  "put on the amulet of mara": [
    "Impone Monile Marae"
  ],
  "ebony battleaxe of evoking": [
    "Bellisecuris Ebeni Evocandi"
  ],
  "deliver faleen's letter": [
    "Redde Epistulam Faleenis"
  ],
  "deliver poem": [
    "Redde Carmen"
  ],
  "talk to calcelmo": [
    "Calcelmonem Adloquere"
  ],
  "i'm sorry boy, but i'm not who you think i am": [
    "Me paenitet puer, sed non sum quem me esse censes"
  ],
  "talk to bassianus or klimmek": [
    "Bassianum vel Klimmekem Adloquere"
  ],
  "ghamorz": [
    "Ghamorz"
  ],
  "humanbeard34": [
    "HumanBeard34"
  ],
  "dbnazircontractset1readybranchtopic": [
    "DBNazirContractSet1ReadyBranchTopic"
  ],
  "ebony mace of winnowing": [
    "Maca Ebeni Ventagii"
  ],
  "dragon bridge dialogue": [
    "Dragon Bridge Dialogue"
  ],
  "falkreath hold settlement dialogue": [
    "Falkreath Hold Settlement Dialogue"
  ],
  "dialoguekarthwasten": [
    "DialogueKarthwasten"
  ],
  "rhiada": [
    "Rhiada"
  ],
  "is there an elven mage here that studies stars": [
    "Estne Magus Alfus hic qui operam in stellis dat"
  ],
  "got away with stealing": [
    "Clam furatus es"
  ],
  "didn't i just kill you": [
    "Nonne modo te necavi"
  ],
  "deliver mead to wilhelm": [
    "Fer Hydromellum ad Wilhelm"
  ],
  "<alias=brute> was killed": [
    "<Alias=Brute> necatus est"
  ],
  "sudi": [
    "Sudi"
  ],
  "how do i join the stormcloaks": [
    "Quomodo Sturmmantelos adiungere possum"
  ],
  "dragonplate helmet of extreme restoration": [
    "Draconilaminata Galea Extremis Reficiendi"
  ],
  "talib": [
    "Talib"
  ],
  "elixir of resist magic": [
    "Commixtum Resistendi Magicam"
  ],
  "borkul's key": [
    "Clavis Borkulis"
  ],
  "exit game": [
    "Exi Ludum"
  ],
  "dialoguesoljundsminehousescene04": [
    "DialogueSoljundsMineHouseScene04"
  ],
  "defeat the enemy soldiers guarding korvanjund": [
    "Vince milites inimicos qui Korvanjund custodiunt"
  ],
  "(<global=mq103defenderdeadcount>/<global=mq103defendertotal>)": [
    "(<Global=MQ103DefenderDeadCount>/<Global=MQ103DefenderTotal>)"
  ],
  "deliver the crown to ulfric stormcloak": [
    "Refer Coronam ad Vercingetorigem Temepstatamiculum"
  ],
  "meet galmar outside korvanjund": [
    "Conveni cum Galmare foris Korvanjund"
  ],
  "talk to galmar": [
    "Galmarem Adloquere"
  ],
  "you wanted something enchanted at the college": [
    "Visne magicam in quid imponere apud collegium"
  ],
  "the jagged crown": [
    "Corona Fragosa"
  ],
  "jagged crown": [
    "Corona Fragosa",
    "Corona Acuminata"
  ],
  "key to pavo's house": [
    "Clavis ad Domum Pavonis"
  ],
  "reverent orcish battleaxe": [
    "Reverens Bellisecuris Orcorum"
  ],
  "(optional) use your new shout power": [
    "(Optio) Uti vi nova Clamoris"
  ],
  "investigate the dragon": [
    "Investiga Draconem"
  ],
  "kill the dragon": [
    "Neca Draconem"
  ],
  "reverent iron sword": [
    "Reverens Gladius Ferri"
  ],
  "falmer abilities": [
    "Falmer Abilities"
  ],
  "meet irileth near the western watchtower": [
    "Adiunge Irileth prope Turrem Occidentalem"
  ],
  "sven wanted to trick you into believing this letter was from faendal": [
    "Sven me voluit hanc epistulam tibi adferre, et quoque dicere Faendalem eam scripsisse"
  ],
  "dragon rising": [
    "Draco Surgens"
  ],
  "orgnar delphine": [
    "Orgnar Delphine"
  ],
  "sigrid alvor dorthe": [
    "Sigrid Alvor Dorthe"
  ],
  "rogatus's letter": [
    "Epistula Rogati"
  ],
  "alethius's notes": [
    "Adnotationes Alethii"
  ],
  "help conquer <alias=campaignhold>": [
    "Cape <Alias=CampaignHold>"
  ],
  "she said that you should elope": [
    "Dixit vos solas ad nuptias effugere debere"
  ],
  "report failure to <alias=factionleader>": [
    "Refer Calamitatem ad <Alias=FactionLeader>"
  ],
  "argi farseer": [
    "Argi Farseer"
  ],
  "report success to <alias=factionleader>": [
    "Refer Nuntium ad <Alias=FactionLeader>"
  ],
  "craglane dog cage key": [
    "Clavis ad Carcerem Canium Craglane"
  ],
  "marksmalehumanoid03leftgash": [
    "MarksMaleHumanoid03LeftGash"
  ],
  "alvor dorthe": [
    "Alvor Dorthe"
  ],
  "fortify restoration": [
    "Adde ad Magicam Reficiendi"
  ],
  "solitude dialogue": [
    "Solitudinis Dialogue"
  ],
  "dwarven mace of burning": [
    "Dvemeris Maca Cremandi"
  ],
  "dark brotherhood sanctuary dialogue quest": [
    "Fraternitas Obscura Sanctuary Dialogue Quest"
  ],
  "dark brotherhood sanctuary conversations 1": [
    "Fraternitas Obscura Sanctuary Conversations 1"
  ],
  "is there another way to defeat alduin": [
    "Estne alia via qua Alduinum superemus"
  ],
  "dialoguesalviusfarm": [
    "DialogueSalviusFarm"
  ],
  "dialoguelefthandmine": [
    "DialogueLeftHandMine"
  ],
  "sithis is due a soul": [
    "Sithis animum meretur"
  ],
  "wouldn't you agree": [
    "Nonne consentis"
  ],
  "grogmar gro-burzag": [
    "Grogmar gro-Burzag"
  ],
  "back off": [
    "Reverte"
  ],
  "i know the drill": [
    "Scio quid faciendum esse"
  ],
  "winking skeever conversation 7": [
    "Winking Skeever Conversation 7"
  ],
  "minor staff of turning": [
    "Rudis Minoris Vertendi"
  ],
  "what else can you tell me about these pirates": [
    "Quid aliud mihi de piratis dicere potes"
  ],
  "stonehills mine scene 3": [
    "Stonehills Mine Scene 3"
  ],
  "loud and clear": [
    "Sonorosus et Clarus"
  ],
  "dialogue snow-shod farm": [
    "Dialogue Snow-Shod Farm"
  ],
  "humanbeardmedium03_1bit": [
    "HumanBeardMedium03_1bit"
  ],
  "dialoguesalviusfarmoutsidescene01": [
    "DialogueSalviusFarmOutsideScene01"
  ],
  "dialoguelefthandminepavogatscene01": [
    "DialogueLeftHandMinePavoGatScene01"
  ],
  "dialoguelefthandminedaighreerith01": [
    "DialogueLeftHandMineDaighreErith01"
  ],
  "dialogue merryfair farm": [
    "Dialogue Merryfair Farm"
  ],
  "elven dagger of shocks": [
    "Pugio Alfus Electricitatis"
  ],
  "merryfair farm scene 01": [
    "Merryfair Farm Scene 01"
  ],
  "banded iron shield": [
    "Ferri Adligati Scutum"
  ],
  "femaleeyeshumaniceblue": [
    "FemaleEyesHumanIceBlue"
  ],
  "merryfair farm scene 02": [
    "Merryfair Farm Scene 02"
  ],
  "heartwood mill scene 01": [
    "Heartwood Mill Scene 01"
  ],
  "orcish sword of draining": [
    "Orci Gladius Hauriendi"
  ],
  "audiotemplatesabrecat": [
    "AudioTemplateSabreCat"
  ],
  "philter of the phantom": [
    "Philtrum Phantom"
  ],
  "staff of the familiar": [
    "Rudis Familiaris"
  ],
  "elixir of resist shock": [
    "Commixtum Contra Vim Electricam"
  ],
  "idesa sadri": [
    "Idesa Sadri"
  ],
  "humanskindarkskin03": [
    "HumanSkinDarkSkin03"
  ],
  "dialogue sarethi farm": [
    "Dialogue Sarethi Farm"
  ],
  "old tome": [
    "Liber Vetus"
  ],
  "veren duleri": [
    "Veren Duleri"
  ],
  "scroll of oakflesh": [
    "Codex Roburipellis"
  ],
  "golldir": [
    "Golldir"
  ],
  "dialoguesoljundssinkhole": [
    "DialogueSoljundsSinkhole"
  ],
  "solitude jail": [
    "Solitudinis Jail"
  ],
  "return to christer": [
    "Reveni ad Christer"
  ],
  "search mistwatch for fjola": [
    "Quaere Fjolam in Nebulavisu"
  ],
  "kill the dragon sahloknir": [
    "Neca Draconem Sahloknirem"
  ],
  "where's mirabelle": [
    "Ubi est Mirabelle"
  ],
  "dialoguemorkhazgur": [
    "DialogueMorKhazgur"
  ],
  "elven war axe of blizzards": [
    "Alfus Bellisecuris Hiemis"
  ],
  "who's in charge of whiterun": [
    "Quis est dux Albacursus"
  ],
  "krev the skinner": [
    "Krev Excoriator"
  ],
  "dialoguemorkhazgurlarakshuftharzscene01": [
    "DialogueMorKhazgurLarakShuftharzScene01"
  ],
  "dialoguedushnikhyal": [
    "DialogueDushnikhYal"
  ],
  "dialoguedushnikhyallonghousescene02": [
    "DialogueDushnikhYalLonghouseScene02"
  ],
  "northwatch interrogator": [
    "Septentriovisus Interrogator"
  ],
  "dwarven sword of binding": [
    "Gladius Dvemeris Deligandi"
  ],
  "if you hadn't killed them, they would have killed you": [
    "Si eos non occideres, ei te occiderent",
    "Si eos non necaveris, te necaverint. If you hadn't killed them, they would have killed you"
  ],
  "why are you all the way out here": [
    "Cur hic es"
  ],
  "escape helgen": [
    "Effuge ex Oppido Helgeni"
  ],
  "shrine to malacath": [
    "Ara Stygis"
  ],
  "irkngthand arcanex": [
    "Irkngthandis Arcanex"
  ],
  "ebony greatsword of depleting": [
    "Spatha Ebeni Deplendi"
  ],
  "imperial sword of binding": [
    "Gladius Romanus Deligandi"
  ],
  "would you be interested in an elder scroll": [
    "Tibine interest Codex Veterrimus"
  ],
  "then i'm off to labyrinthian": [
    "Ergo Labyrinthum peto"
  ],
  "enter the keep with hadvar or ralof": [
    "Intra Castellum cum Hadvar vel Ralof"
  ],
  "iron warhammer of embers": [
    "Bellimalleus Ferratus Cinerum"
  ],
  "arngeir": [
    "Arngeir"
  ],
  "jenassa": [
    "Jenassa"
  ],
  "imperial sword of diminishing": [
    "Gladius Romanus Diminuendi"
  ],
  "orcish boots of frost abatement": [
    "Orci Caligae Gelidus Resistendi"
  ],
  "daedric war axe of animus": [
    "Bellisecuris Daedricus Animi"
  ],
  "lod has a book he stole from a grave": [
    "Lod librum e sepulchro habet"
  ],
  "lumberjack": [
    "Lumberjack"
  ],
  "lob": [
    "Lob"
  ],
  "why have you shut down the mine": [
    "Cur Metallum clausisti"
  ],
  "dorthe and frodnar play scene": [
    "Dorthe et Frodnar Play Scene"
  ],
  "dark brotherhood sanctuary conversation system": [
    "Fraternitas Obscura Sanctuary Conversation System"
  ],
  "rummaging through trash": [
    "Rummaging through trash"
  ],
  "i have nirnroot for you": [
    "Nirnradicem tibi habeo"
  ],
  "sundered towers": [
    "Turres Fractae"
  ],
  "player naked": [
    "Player Naked"
  ],
  "deadly paralysis poison": [
    "Letifer Venenum Paralyticum"
  ],
  "sawmill conversation": [
    "Sawmill Conversation"
  ],
  "dome01": [
    "Dome01"
  ],
  "assist the people of haafingar (<global=friendscounthaafingar>/<global=friendstotalmajorhold>)": [
    "Adiuva incolas Haafingaris (<Global=FriendsCountHaafingar>/<Global=FriendsTotalMajorHold>)"
  ],
  "what do you mean, \"it's hopeless\"": [
    "Cur dicis, \"sine spe est\""
  ],
  "(optional) find the key to the well in the catacombs": [
    "(Optio) Inveni clavem fontis in Catacumbis"
  ],
  "(optional) show skorm snow-strider's journal to valmir": [
    "(Optio) Demonstra ephemeridem Skormis Nivi-Cursoris Valmiri"
  ],
  "goldenglow gate key": [
    "Clavis ad Porta Aurilucis"
  ],
  "temple of dibella": [
    "Temple of Dibella"
  ],
  "siege on the dragon cult": [
    "Obsidio Contra Cultum Draconis"
  ],
  "college entry faction": [
    "College Entry Faction"
  ],
  "winking skeever conversation 1": [
    "Winking Skeever Conversation 1"
  ],
  "winking skeever conversation 3": [
    "Winking Skeever Conversation 3"
  ],
  "winking skeever conversation 4": [
    "Winking Skeever Conversation 4"
  ],
  "stonehills mine scene 2": [
    "Stonehills Mine Scene 2"
  ],
  "orcish warhammer of weariness": [
    "Orci Bellimalleus Maioris Defatigationis"
  ],
  "dialoguecidhnaminebraigduach01": [
    "DialogueCidhnaMineBraigDuach01"
  ],
  "honed falmer war axe": [
    "Melior Bellisecuris Falmeris"
  ],
  "ligningstormbodyartholder": [
    "LigningStormBodyArtHolder"
  ],
  "are you glad to see ulfric stormcloak gone": [
    "Tibine placet videre Vercingetorigem Temepstatamiculum mortuum"
  ],
  "(invisible continue)(forced good bye) time limit to attack": [
    "(Invisible Continue)(Forced Good bye) Time Limit to Attack"
  ],
  "winterhold college dialogue": [
    "Hiemiterrae College Dialogue"
  ],
  "get imperial gear from beirand": [
    "Accipe Arma Imperialia a Beirand"
  ],
  "maleeyesdremora": [
    "MaleEyesDremora"
  ],
  "fortify illusion": [
    "Adde ad Illusionem"
  ],
  "the emperor... it was all a trap": [
    "Imperator... coniuratio erat"
  ],
  "emperor... it was all a trap": [
    "Imperator... coniuratio erat"
  ],
  "someone set us up": [
    "Aliquis nobis insidias paravit"
  ],
  "markarthintroscenetrigger": [
    "MarkarthIntroSceneTrigger"
  ],
  "the night mother spoke to me! she said \"i am the one.\"": [
    "Mater Noctis mihi locutus est! Mihi dixit \"Ego Sum Ille.\""
  ],
  "night mother spoke to me! she said \"i am the one.\"": [
    "Mater Noctis mihi locutus est! Mihi dixit \"Ego Sum Ille.\""
  ],
  "daedric sword of damnation": [
    "Gladius Daedricus Damnationis"
  ],
  "defeat alduin": [
    "Supera Alduinum"
  ],
  "learn the \"dragonrend\" shout from the nord heroes": [
    "Disce Clamorem \"Draconicaesem\" e Heroibus Germanis"
  ],
  "draught of the knight": [
    "Decocta Equitis"
  ],
  "alduin's bane": [
    "Debilitas Alduinis"
  ],
  "dialoguecidhnamine": [
    "DialogueCidhnaMine"
  ],
  "chef's hat": [
    "Petasus Coqui"
  ],
  "web sac": [
    "Saccus Aranearum"
  ],
  "noster begging": [
    "Noster Begging"
  ],
  "bits and pieces scene": [
    "Bits et Pieces Scene"
  ],
  "blacksmith conversation": [
    "Blacksmith Conversation"
  ],
  "castle commander scene 1": [
    "Castle Commander Scene 1"
  ],
  "tg01hellos": [
    "TG01Hellos"
  ],
  "docks warehouse conversation": [
    "Docks Warehouse Conversation"
  ],
  "bryling house scene 1": [
    "Bryling House Scene 1"
  ],
  "bryling house scene 2": [
    "Bryling House Scene 2"
  ],
  "humanskinbasewhite06": [
    "HumanSkinBaseWhite06"
  ],
  "erikur house scene 1": [
    "Erikur House Scene 1"
  ],
  "addvar's house conversation": [
    "Addvar's House Conversation"
  ],
  "san house scene 1": [
    "San House Scene 1"
  ],
  "san house scene 2": [
    "San House Scene 2"
  ],
  "temple conversation 1": [
    "Temple Conversation 1"
  ],
  "dwarven war axe of draining": [
    "Dvemeris Bellisecuris Hauriendi"
  ],
  "tell me about agnis": [
    "Dic mihi de Agni"
  ],
  "glass dagger of ice": [
    "Pugio Vitreus Gelidus"
  ],
  "found it": [
    "Id inveni"
  ],
  "blue palace scene 1": [
    "Blue Palace Scene 1"
  ],
  "gilfre": [
    "Gilfre"
  ],
  "darkelfmalelips05": [
    "DarkElfMaleLips05"
  ],
  "riften dialogue": [
    "Hiati Dialogue"
  ],
  "business ledger": [
    "In Libellum Negotii"
  ],
  "saarthal exterior scene 1": [
    "Saarthalis Exterior Scene 1"
  ],
  "mg arcanaeum scene 3": [
    "MG Arcanaeum Scene 3"
  ],
  "mg arcanaeum scene 2": [
    "MG Arcanaeum Scene 2"
  ],
  "find the owner of the golden claw": [
    "Inveni Possessorem Ungues Aurei"
  ],
  "altar of mehrunes dagon": [
    "Ara Croni"
  ],
  "mg arcanaeum scene 4": [
    "MG Arcanaeum Scene 4"
  ],
  "mg arcanaeum scene 5": [
    "MG Arcanaeum Scene 5"
  ],
  "mg arcanaeum scene 6": [
    "MG Arcanaeum Scene 6"
  ],
  "mg great hall scene 2": [
    "MG Great Hall Scene 2"
  ],
  "sorry, i didn't mean anything by it": [
    "Ignosce, nolui tibi offendere"
  ],
  "mg great hall scene 3": [
    "MG Great Hall Scene 3"
  ],
  "dragon bone": [
    "Draconis Os"
  ],
  "mg great hall scene 4": [
    "MG Great Hall Scene 4"
  ],
  "mg dormitory scene 1": [
    "MG Dormitory Scene 1"
  ],
  "a crowd gathers": [
    "A crowd gathers"
  ],
  "crowd gathers": [
    "A crowd gathers"
  ],
  "yes, restart the game": [
    "Sic, reincipe ludum"
  ],
  "rorikstead dialogue": [
    "Rorikstead dialogue"
  ],
  "silver-blood family ring": [
    "Anulus Familiae Argenti-Sanguinis"
  ],
  "barleydark farm dialogue": [
    "Barleydark Farm dialogue"
  ],
  "creaturedialoguedragon": [
    "CreatureDialogueDragon"
  ],
  "bring the claw to camilla": [
    "Fer Unguem ad Camillam"
  ],
  "hairlinefemaleorc03": [
    "HairLineFemaleOrc03"
  ],
  "sprint effect 3": [
    "Sprint Effect 3"
  ],
  "bring the claw to lucan": [
    "Fer Unguem ad Lucanem"
  ],
  "mudcrab abilities": [
    "Mudcrab abilities"
  ],
  "tsun's armor": [
    "Tsunis Lorica"
  ],
  "marksfemalehumanoid01leftgash": [
    "MarksFemaleHumanoid01LeftGash"
  ],
  "cut arvel down": [
    "Libera Arvelem"
  ],
  "charm faction": [
    "Charm Faction"
  ],
  "the golden claw": [
    "Ungues Aureus"
  ],
  "golden claw": [
    "Ungues Aureus",
    "Ungues Draconis Aureus"
  ],
  "so, the staff of magnus": [
    "Ergo, Rudis Magni"
  ],
  "daedric mace of petrifying": [
    "Maca Daedrica Lapidescendi"
  ],
  "meet with <alias=questgiver> about a job involving violence": [
    "Conveni cum <Alias=QuestGiver> de munere quod de violentia agitur"
  ],
  "scare my enemy": [
    "Terre Inimicum Meum"
  ],
  "dwarven warhammer of harrowing": [
    "Dvemeris Bellimalleus Occationis"
  ],
  "nervous patron": [
    "Patronus Trepidus"
  ],
  "hairfemaleelder04": [
    "HairFemaleElder04"
  ],
  "i'm going to beat you like a house cat": [
    "Te verberabo ut felem domesticum"
  ],
  "east empire company warehouse": [
    "Negotium Euri Imperii Horreum"
  ],
  "what's the next target": [
    "Ubi proximum"
  ],
  "courier": [
    "Nuntius"
  ],
  "heimskr repeating preaching scene": [
    "Heimskr Repeating Preaching Scene"
  ],
  "imperial sword of sparks": [
    "Gladius Romanus Scintillarum"
  ],
  "manage equipment": [
    "Muta Arma"
  ],
  "herluin lothaire": [
    "Herluin Lothaire"
  ],
  "place shagrol's hammer on the shrine in largashbur": [
    "Pone Malleum Shagrolis in Aram Largashburis"
  ],
  "it's okay": [
    "Mollis esto",
    "Omnia bona sunt"
  ],
  "delphine sent me": [
    "Delphina mihi misit"
  ],
  "i've seen that symbol before": [
    "Illud Symbolum antea vidi"
  ],
  "humanbeard29": [
    "HumanBeard29"
  ],
  "meet yamarz at fallowstone cave": [
    "Conveni cum Yamarz ad Spelunca Novalilapidi"
  ],
  "speak with yamarz": [
    "Adloquere Yamarz"
  ],
  "glass sword of thunderbolts": [
    "Gladius Vitreus Fulminum"
  ],
  "second seed": [
    "Maius"
  ],
  "kill helvard": [
    "Neca Helvardem"
  ],
  "bring troll fat and a daedra heart to atub": [
    "Fer Pingue Trellis et Cor Daedrae ad Atubem"
  ],
  "find out what's happening in largashbur": [
    "Disce Quid in Largashbur Accidere"
  ],
  "kerah": [
    "Kerah"
  ],
  "bring the forged documents to <alias=enemyfieldco>": [
    "Fer Documenta Falsa ad <Alias=EnemyFieldCO>"
  ],
  "find the <alias=courier>": [
    "Inveni <Alias=Courier>"
  ],
  "how did it happen": [
    "Quomodo accidit"
  ],
  "show the amulet to delvin mallory": [
    "Demonstra Monile ad Delvin Mallory"
  ],
  "you're one hard mage to track down, ma'randru-jo": [
    "Magus dificilis inventu es, Ma'randru-jo"
  ],
  "speak with amaund motierre": [
    "Adloquere Amaund Motierre"
  ],
  "the silence has been broken": [
    "Silentium Fractum"
  ],
  "silence has been broken": [
    "Silentium Fractum"
  ],
  "holy daedric sword": [
    "Sanctus Gladius Daedricus"
  ],
  "winterhold inn scene 1": [
    "Hiemiterrae Inn Scene 1"
  ],
  "winterhold inn scene 2": [
    "Hiemiterrae Inn Scene 2"
  ],
  "winterhold inn scene 3": [
    "Hiemiterrae Inn Scene 3"
  ],
  "winterhold inn scene 4": [
    "Hiemiterrae Inn Scene 4"
  ],
  "winterhold inn scene 5": [
    "Hiemiterrae Inn Scene 5"
  ],
  "winterhold inn scene 6": [
    "Hiemiterrae Inn Scene 6"
  ],
  "winterhold inn scene 7": [
    "Hiemiterrae Inn Scene 7"
  ],
  "oh course, festus": [
    "Certe, Feste"
  ],
  "please continue": [
    "Perge, quaeso"
  ],
  "why side with the empire": [
    "Cur te factionem Imperii adiunxisti"
  ],
  "makes them enemies of the player": [
    "Makes them enemies of the player"
  ],
  "gold ingot": [
    "Auri Later"
  ],
  "winterhold jarls longhouse scene 3": [
    "Hiemiterrae Jarls Longhouse Scene 3"
  ],
  "report back to <alias=friend>": [
    "Refer te ad <Alias=Friend>"
  ],
  "free the prisoners": [
    "Libera Captivos"
  ],
  "talk to the arch-mage": [
    "Adloquere Archimagum"
  ],
  "meet the men near <alias=attackpoint>": [
    "Milites Adiungere Prope <Alias=AttackPoint>"
  ],
  "iron mace of embers": [
    "Ferri Maca Cinerum"
  ],
  "nightingale hood": [
    "Capucium Philomelarum"
  ],
  "drowned sorrows fin": [
    "Drowned Sorrows Fin"
  ],
  "unlock the sarcophagus in ragnvald": [
    "Aperi Sarcophagum in Ragnvald"
  ],
  "steel boots of waning fire": [
    "Caligae Adamanteae Insenescentis Ignis"
  ],
  "winterhold korir's house scene 1": [
    "Hiemiterrae Korir's House Scene 1"
  ],
  "i need training in light armor": [
    "Doce me de Lorica Levi"
  ],
  "dwarven bracers of smithing": [
    "Dvemeres Chirothecae Ferrarii"
  ],
  "winterhold korir's house scene 2": [
    "Hiemiterrae Korir's House Scene 2"
  ],
  "winterhold korir's house scene 3": [
    "Hiemiterrae Korir's House Scene 3"
  ],
  "fort dialogue": [
    "Fort Dialogue"
  ],
  "dialoguesalviusfarmhousescene02": [
    "DialogueSalviusFarmhouseScene02"
  ],
  "riften thieves guild headquarters faction": [
    "Hiati Thieves Guild Headquarters Faction"
  ],
  "(<global=hdsolitudealchemy> gold)": [
    "(<Global=HDSolitudeAlchemy> auri)"
  ],
  "conjuration trainer": [
    "Conjuration Trainer"
  ],
  "pit fan": [
    "Pit Fan"
  ],
  "creaturedialoguefrostbitespider": [
    "CreatureDialogueAdustus NivibusSpider"
  ],
  "bound sword fx": [
    "Bound Sword FX"
  ],
  "dialoguemarkarthmarketscenekerahendon1": [
    "DialogueMarkarthMarketSceneKerahEndon1"
  ],
  "dialoguemarkarthmarketscenekerahendon2": [
    "DialogueMarkarthMarketSceneKerahEndon2"
  ],
  "avulstein gray-mane": [
    "Avulstein Juba-Cana"
  ],
  "dialoguemarkarthmarketscenekerahhroki2": [
    "DialogueMarkarthMarketSceneKerahHroki2"
  ],
  "dialoguemarkarthmarketscenehognihroki1": [
    "DialogueMarkarthMarketSceneHogniHroki1"
  ],
  "dialoguemarkarthmarketscenehognihroki2": [
    "DialogueMarkarthMarketSceneHogniHroki2"
  ],
  "there's only three of you legionaries versus one stormcloak": [
    "Modo tres Legionarii contra unum Sturmmantelum"
  ],
  "hardly seems fair": [
    "Vix iustum mihi videtur"
  ],
  "dialoguemarkarthkeepstormcloakcourtscene04": [
    "DialogueMarkarthKeepSturmmantelorumCourtScene04"
  ],
  "i'm ready to go": [
    "Paratus sum ire"
  ],
  "dialoguemarkarthtreasuryhouserhiadaildenescene01": [
    "DialogueMarkarthTreasuryHouseRhiadaIldeneScene01"
  ],
  "whispering lady": [
    "Matrona Sussurans"
  ],
  "dialoguemarkarthtreasuryhouserhiadabetridscene01": [
    "DialogueMarkarthTreasuryHouseRhiadaBetridScene01"
  ],
  "the mirror": [
    "Speculum"
  ],
  "creaturedialoguetroll": [
    "CreatureDialogueTrellis"
  ],
  "dialoguemarkartharnleiflisbetimedhnainscene02": [
    "DialogueMarkarthArnleifLisbetImedhnainScene02"
  ],
  "riften ratway faction neutral": [
    "Hiati Via Murum Faction Neutral"
  ],
  "they'll work harder if you pay them more": [
    "Si plus pecuniae eis dederis diligentius laborabunt"
  ],
  "ebony greatsword of storms": [
    "Spatha Ebeni Tempestatium"
  ],
  "glass war axe of debilitation": [
    "Bellisecuris Vitreus Debilitationis"
  ],
  "stump": [
    "Caudex"
  ],
  "watchtower guard's letter": [
    "Epistula Custodis Turris"
  ],
  "carlotta valentia": [
    "Carlotta Valentia"
  ],
  "why is it called wolfskull cave": [
    "Cur nominatur \"Spelunca Lupicalvaria\""
  ],
  "sanctified dwarven bow": [
    "Sanctificatus Arcus Dvemeris"
  ],
  "greenspring hollow": [
    "Caverna Rivuli Viridis"
  ],
  "castle dour, tower": [
    "Turris Castelli Duri"
  ],
  "hairlinemalenord10": [
    "HairLineMaleNord10"
  ],
  "thongvor silver-blood": [
    "Thongvor Sanguinargenti"
  ],
  "hairmaleorc21": [
    "HairMaleOrc21"
  ],
  "forget the debt with shadr or you're out": [
    "Obliviscere debiti cum Shadr vel expelleris"
  ],
  "nilheim faction": [
    "Nilheim Faction"
  ],
  "ebony battleaxe of leeching": [
    "Bellisecuris Ebeni Sanguisugae"
  ],
  "shrouded armor": [
    "Umbrata Lorica"
  ],
  "broom": [
    "Scopa"
  ],
  "unmid snow-shod": [
    "Unmid Nivi-Caligatus"
  ],
  "\"just throw trash\"": [
    "\"Just throw trash\""
  ],
  "dwarven bracers of eminent archery": [
    "Dvemeres Chirothecae Eminentis Saggitationis"
  ],
  "glass battleaxe of consuming": [
    "Bellisecuris Vitreus Consumendi"
  ],
  "muffle": [
    "Quietum"
  ],
  "then we have to stop him": [
    "Ergo eum sistere debemus"
  ],
  "you're weak": [
    "Debilis es"
  ],
  "dialoguemarkarthinnklepprfrabbiscene01": [
    "DialogueMarkarthInnKlepprFrabbiScene01"
  ],
  "elven mace of lethargy": [
    "Maca Alfa Lethargiae"
  ],
  "dialoguemarkarthinnomluaghathrasilscene01": [
    "DialogueMarkarthInnOmluagHathrasilScene01"
  ],
  "dialoguemarkarthinnendonsfamilyscene01": [
    "DialogueMarkarthInnEndonsFamiliaeScene01"
  ],
  "forelhost refectory": [
    "Refectorium Forelhostis"
  ],
  "dialoguemarkarthinndegainecosnachscene02": [
    "DialogueMarkarthInnDegaineCosnachScene02"
  ],
  "dialoguemarkarthkeepstormcloakcourtscene03": [
    "DialogueMarkarthKeepSturmmantelorumCourtScene03"
  ],
  "dialoguemarkarthinnfrabbihreinnscene01": [
    "DialogueMarkarthInnFrabbiHreinnScene01"
  ],
  "dialoguemarkarthinnklepprfrabbiscene02": [
    "DialogueMarkarthInnKlepprFrabbiScene02"
  ],
  "dialoguemarkarthblacksmithscene02": [
    "DialogueMarkarthBlacksmithScene02"
  ],
  "ebony warhammer of expelling": [
    "Bellimalleus Ebeni Expellendi"
  ],
  "dialoguemarkarthkeepstormcloakcourtscene02": [
    "DialogueMarkarthKeepSturmmantelorumCourtScene02"
  ],
  "dialoguemarkarthinnyngvarklepprscene01": [
    "DialogueMarkarthInnYngvarKlepprScene01"
  ],
  "dialoguemarkarthkeepstormcloakcourtscene01": [
    "DialogueMarkarthKeepSturmmantelorumCourtScene01"
  ],
  "dialoguemarkarthinnklepprhreinnscene01": [
    "DialogueMarkarthInnKlepprHreinnScene01"
  ],
  "wait for orders from jarl ulfric": [
    "Exspecta Mandata a Comite Vercingetorige"
  ],
  "dialoguemarkarthinnklepprhreinnscene02": [
    "DialogueMarkarthInnKlepprHreinnScene02"
  ],
  "conjure potent storm thrall": [
    "Voca Servum Potens Electricitatis"
  ],
  "dialoguemarkarthinnogmundklepprscene01": [
    "DialogueMarkarthInnOgmundKlepprScene01"
  ],
  "you must have a weapon drawn": [
    "Opus est tibi armum extractum habere"
  ],
  "dialoguemarkarthkeepimperialcourtscene01": [
    "DialogueMarkarthKeepImperialisCourtScene01"
  ],
  "dialoguemarkarthhagscurescenemuiribothela02": [
    "DialogueMarkarthHagsCureSceneMuiriBothela02"
  ],
  "dwarven warhammer of thunderbolts": [
    "Dvemeris Bellimalleus Fulminum"
  ],
  "leather bracers of minor lockpicking": [
    "Corii Chirothecae Minoris Clavicarii"
  ],
  "dialoguemarkarthinndegainecosnachscene01": [
    "DialogueMarkarthInnDegaineCosnachScene01"
  ],
  "dialoguemarkarthkeepimperialcourtscene03": [
    "DialogueMarkarthKeepImperialisCourtScene03"
  ],
  "dialoguemarkarthkeepcalcelmoaicantarscene01": [
    "DialogueMarkarthKeepCalcelmoAicantarScene01"
  ],
  "dialoguemarkarthinnendonsfamilyscene02": [
    "DialogueMarkarthInnEndonsFamiliaeScene02"
  ],
  "break through the first barricade": [
    "Frange Concaedem Primam"
  ],
  "dialoguemarkarthkeepimperialcourtscene02": [
    "DialogueMarkarthKeepImperialisCourtScene02"
  ],
  "agent lorcalin": [
    "Agens Lorcalin"
  ],
  "dialoguemarkarthkeepcalcelmoaicantarscene02": [
    "DialogueMarkarthKeepCalcelmoAicantarScene02"
  ],
  "dialoguemarkarthkeepkitchenscene01": [
    "DialogueMarkarthKeepKitchenScene01"
  ],
  "staff of frostbite": [
    "Rudis Adusti Nivibus"
  ],
  "generic dialogue for orc villagers": [
    "Generic dialogue for orc villagers"
  ],
  "dialoguedawnstarbrinahorikscene02": [
    "DialogueDawnstarBrinaHorikScene02"
  ],
  "leather helmet": [
    "Corii Galea"
  ],
  "dialoguedawnstarrustleifseren02": [
    "DialogueDawnstarRustleifSeren02"
  ],
  "the killer must still be out there": [
    "Homicida, pro certo, iam liberus est"
  ],
  "killer must still be out there": [
    "Homicida, pro certo, iam liberus est"
  ],
  "dialoguedawnstarironbreakerkarlbeitild01": [
    "DialogueDawnstarIronBreakerKarlBeitild01"
  ],
  "humanskinbasewhite02": [
    "HumanSkinBaseWhite02"
  ],
  "daedric warhammer of immobilizing": [
    "Bellimalleus Daedricus Immobilitatis"
  ],
  "lod's house": [
    "Lod's House"
  ],
  "(optional) retrieve the blade of woe": [
    "(Optio) Accipe Ensem Dolorosum"
  ],
  "audiotemplatewitchlight": [
    "AudioTemplateWitchlight"
  ],
  "escape the sanctuary": [
    "Effuge Fanum"
  ],
  "kill nazir's attacker": [
    "Neca Oppugnatorem Naziris"
  ],
  "enchanter's elixir": [
    "Incantatoris Commixtum"
  ],
  "homecooked meal": [
    "Domicoctus Cibus"
  ],
  "blue robes": [
    "Caeruleum Peplum"
  ],
  "enter the sanctuary": [
    "Intra Fanum"
  ],
  "ebony war axe of terror": [
    "Bellisecuris Ebeni Terroris"
  ],
  "death incarnate": [
    "Mors Incarnata"
  ],
  "iron battleaxe of arcing": [
    "Bellisecuris Ferri Electricitatis"
  ],
  "blue palace servants key": [
    "Clavis ad Aream Servorum Aulae Caeruleae"
  ],
  "the posting of the hunt": [
    "Postatum Venationis"
  ],
  "posting of the hunt": [
    "Postatum Venationis"
  ],
  "hatch": [
    "Egressum"
  ],
  "have you been in dawnstar long": [
    "Multum tempus Luciferi consumpsi"
  ],
  "dialoguedawnstarwhitehallskaldbulfrek01": [
    "DialogueDawnstarWhiteHallSkaldBulfrek01"
  ],
  "dialoguedawnstarwhitehallbrinahorikscene02": [
    "DialogueDawnstarWhiteHallBrinaHorikScene02"
  ],
  "why are you so fascinated by alchemy": [
    "Cur alchemia te tantum fascinat"
  ],
  "dialoguedawnstarwhitehallmadenahorik01": [
    "DialogueDawnstarWhiteHallMadenaHorik01"
  ],
  "marksmalehumanoid06rightgashr": [
    "MarksMaleHumanoid06RightGashR"
  ],
  "ebony boots of fire abatement": [
    "Caligae Ebeni Resistendi Ignis"
  ],
  "can you tell me anything else about what's going on": [
    "Potesne mihi quid dicere de qua agi"
  ],
  "kill lu'ah al-skaven in ansilvund": [
    "Neca Lu'ah Al-Skaven in Ansilvund"
  ],
  "quickloading": [
    "Celeraperiens"
  ],
  "thieves guild dialogue scene 01": [
    "Sodalitatis Furum Dialogue Scene 01"
  ],
  "ebony dagger of stunning": [
    "Pugio Ebeni Intermissionis"
  ],
  "thieves guild dialogue scene 02": [
    "Sodalitatis Furum Dialogue Scene 02"
  ],
  "thieves guild dialogue scene 04": [
    "Sodalitatis Furum Dialogue Scene 04"
  ],
  "you cannot wait in this location": [
    "Non potes hic manere"
  ],
  "troll fat": [
    "Omentum Trellis"
  ],
  "where do the contracts come from": [
    "Unde veniunt foedera"
  ],
  "how long have they been missing": [
    "Quamdiu amissi sunt"
  ],
  "thieves guild dialogue scene 08": [
    "Sodalitatis Furum Dialogue Scene 08"
  ],
  "speak with gabriella": [
    "Adloquere Gabriellam"
  ],
  "thieves guild dialogue scene 11": [
    "Sodalitatis Furum Dialogue Scene 11"
  ],
  "iron battleaxe of shocks": [
    "Bellisecuris Ferri Electricitatis"
  ],
  "dragonfly": [
    "Draconimuscam"
  ],
  "thieves guild dialogue scene 12": [
    "Sodalitatis Furum Dialogue Scene 12"
  ],
  "ebonyflesh": [
    "Ebenipellis"
  ],
  "quicksilver ore": [
    "Mercurii Minerale"
  ],
  "hairlinefemalenord06": [
    "HairLineFemaleNord06"
  ],
  "corundum ore vein": [
    "Venam Metallis Corundi"
  ],
  "db04astridhideresponse1": [
    "DB04AstridHideResponse1"
  ],
  "thieves guild dialogue scene 13": [
    "Sodalitatis Furum Dialogue Scene 13"
  ],
  "dwarven warhammer of consuming": [
    "Dvemeris Bellimalleus Consumendi"
  ],
  "thieves guild dialogue scene 16": [
    "Sodalitatis Furum Dialogue Scene 16"
  ],
  "audiotemplatedwarvencenturion": [
    "AudioTemplateDwarvenCenturio"
  ],
  "speak with golldir": [
    "Adloquere Golldir"
  ],
  "find the secret passage in hillgrund's tomb": [
    "Inveni Commeatum Secretum in Sepulcro Hillgrundi"
  ],
  "defeat vals veran": [
    "Vince Vals Veran"
  ],
  "ancestral worship": [
    "Caerimonia Maiorum"
  ],
  "defeat hevnoraak": [
    "Vince Hevnoraak"
  ],
  "i think you've been in here too long": [
    "Credo te nimium magnum temporis hic fuisse"
  ],
  "you're crazy": [
    "Insanis"
  ],
  "virtuous daedric battleaxe": [
    "Virtutis Bellisecuris Daedricus"
  ],
  "evil in waiting": [
    "Malum Pendens"
  ],
  "lond": [
    "Lond"
  ],
  "break through the enemy barricade": [
    "Frange Concaedem Inimicorum"
  ],
  "philter of waterbreathing": [
    "Philtrum Respirandi Sub Aqua"
  ],
  "what are the nightingales": [
    "Quae sunt Philomelae"
  ],
  "woodelfskin05": [
    "WoodElfSkin05"
  ],
  "glass sword of animus": [
    "Gladius Vitreus Animi"
  ],
  "defeat illia's mother at the top of darklight tower": [
    "Vince matrem Illiae ad summam Turris Obscurilucis"
  ],
  "grab": [
    "Sume"
  ],
  "actors\\character\\_1stperson\\skeleton.nif": [
    "Actors\\Character\\_1stPerson\\Skeleton.NIF"
  ],
  "help illia reach the top of darklight tower": [
    "Adiuva Illiam summam Turrem Obscurilucem advenire"
  ],
  "report to gianna": [
    "Refer te ad Giannam"
  ],
  "bryling": [
    "Bryling"
  ],
  "i need to talk to him. i'm a friend. his life's in danger. (persuade)": [
    "Opus est mihi eum adloqui. Amicus sum. Vita eius in periculo est! (Persuade)"
  ],
  "daedric bow of annihilating": [
    "Arcus Daedricus Annihilationis"
  ],
  "dragonscale shield of peerless blocking": [
    "Scutum Draconisquamatum Optimi Interponendi"
  ],
  "tribal orcs": [
    "Tribal Orcs"
  ],
  "varnius junius": [
    "Varnius Junius"
  ],
  "daedric battleaxe of freezing": [
    "Bellisecuris Daedricus Gelidus"
  ],
  "escape yngol's barrow": [
    "Effuge Pabillum Yngolis"
  ],
  "turn undead": [
    "Repelle Amortuos"
  ],
  "explore yngol's barrow": [
    "Investiga Pabillum Yngolis"
  ],
  "i'm not interested right now, thanks": [
    "Nunc mihi non interest. Sed gratias tibi ago"
  ],
  "dialoguecidhnamineduachodvan01": [
    "DialogueCidhnaMineDuachOdvan01"
  ],
  "dialoguecidhnamineduachbraig01": [
    "DialogueCidhnaMineDuachBraig01"
  ],
  "daedric war axe of the vampire": [
    "Bellisecuris Daedricus Vampiri"
  ],
  "the next world will have to take care of itself": [
    "Mundus proximus se curare debebit"
  ],
  "next world will have to take care of itself": [
    "Mundus proximus se curare debebit"
  ],
  "daedric warhammer of the inferno": [
    "Bellimalleus Daedricus Conflagrationis"
  ],
  "the amulet of kings": [
    "Monile Regum"
  ],
  "amulet of kings": [
    "Monile Regum"
  ],
  "saffir": [
    "Saffir"
  ],
  "asbjorn fire-tamer": [
    "Asbjorn Igni-Domitor"
  ],
  "daedric greatsword of malediction": [
    "Spatha Daedrica Maledictionis"
  ],
  "what's next, then": [
    "Ergo, quid proximum"
  ],
  "fort hraggstad prison": [
    "Carcer Castelli Hraggstad"
  ],
  "what would i gain from this": [
    "Quid de hoc mihi proderit"
  ],
  "thieves guild dialogue scene 07": [
    "Sodalitatis Furum Dialogue Scene 07"
  ],
  "return the box to trius for the promised reward": [
    "Redde Cistam ad Trium pro Praemium Promissum"
  ],
  "aretino family heirloom": [
    "Haereditas Familiae Aretinonis"
  ],
  "search the wreck below for the jewelry box": [
    "Quare Cistam in Naufragio"
  ],
  "orcish boots of the ox": [
    "Orci Caligae Bovis"
  ],
  "steel greatsword of arcing": [
    "Spatha Adamantea Electricitatis"
  ],
  "dialogueloreiusfarmoutsidescene01": [
    "DialogueLoreiusFarmOutsideScene01"
  ],
  "leather bracers of major alchemy": [
    "Corii Chirothecae Maioris Alchemiae"
  ],
  "dialogueloreiusfarmoutsidescene02": [
    "DialogueLoreiusFarmOutsideScene02"
  ],
  "ebony mace of terror": [
    "Maca Ebeni Terroris"
  ],
  "dwarven battleaxe of consuming": [
    "Dvemeris Bellisecuris Consumendi"
  ],
  "help anska retrieve her scroll": [
    "Adiuva Anskam in codicem inveniendo"
  ],
  "find and return anska's scroll to her": [
    "Inveni Codicem et Redde eam Anskae"
  ],
  "orcish boots of fire abatement": [
    "Orci Caligae Resistendi Ignis"
  ],
  "iron sword of cold": [
    "Gladius Ferri Frigidis"
  ],
  "captain, angela sent me to ask about her daughter": [
    "Centurio, Angela mihi misit ut de filia rogarem"
  ],
  "ebony gauntlets of eminent archery": [
    "Ebeni Manicae Eminentis Saggitationis"
  ],
  "tree lod fade": [
    "Tree LOD Fade"
  ],
  "hallowed glass warhammer": [
    "Consacratus Bellimaleus Vitreus"
  ],
  "virulent stamina poison": [
    "Virulens Venenum Staminae"
  ],
  "dialogue shor's stone": [
    "Dialogue Shor's Stone"
  ],
  "easy, it's nine": [
    "Facile factu, novem est"
  ],
  "do i need to beat some sense into you": [
    "Debeone sensum in te pulsare"
  ],
  "paralyzemassbodyartholder": [
    "ParalyzeMassBodyArtHolder"
  ],
  "a scroll for anska": [
    "Codex Anskae"
  ],
  "scroll for anska": [
    "Codex Anskae"
  ],
  "daedric greatsword of thunderbolts": [
    "Spatha Daedrica Fulminum"
  ],
  "elixir of light feet": [
    "Commixtum Pedum Levium"
  ],
  "hallowed glass war axe": [
    "Consacratus Glass Bellisecuris"
  ],
  "dialogueangasmillkodrirleifur02": [
    "DialogueAngasMillKodrirLeifur02"
  ],
  "(optional) talk to arngeir": [
    "(Optio) Adloquere Arngeirem"
  ],
  "morthal thaumaturgist's hut scene 2": [
    "Morthal Thaumaturgist's Hut Scene 2"
  ],
  "morthal thaumaturgist's hut scene 3": [
    "Morthal Thaumaturgist's Hut Scene 3"
  ],
  "riften the scorched hammer services": [
    "Hiati The Scorched Hammer Services"
  ],
  "morthal falion's house scene 2": [
    "Morthal Falionis House Scene 2"
  ],
  "morthal falion's house scene 3": [
    "Morthal Falionis House Scene 3"
  ],
  "cave bear": [
    "Cave Bear"
  ],
  "morthal alva's house scene 1": [
    "Morthal Alva's House Scene 1"
  ],
  "flightlessness": [
    "Flightlessness"
  ],
  "morthal moorside jonna lurbuk": [
    "Morthal Moorside Jonna Lurbuk"
  ],
  "morthal moorside gorm benor": [
    "Morthal Moorside Gorm Benor"
  ],
  "morthal moorside jonna alva": [
    "Morthal Moorside Jonna Alva"
  ],
  "never mind, boy": [
    "Ignosce, canis"
  ],
  "grand plaza scene 06": [
    "Grand Plaza Scene 06"
  ],
  "ebony warhammer of garnering": [
    "Bellimalleus Ebeni Horti"
  ],
  "dragonscale boots of frost abatement": [
    "Caligae Draconisquamatae Gelidus Resistendi"
  ],
  "i've been to the tomb": [
    "Sepulcrum iam petivi"
  ],
  "i have olaf's verse": [
    "Versum Olafis iam habeo"
  ],
  "glass shield of frost abatement": [
    "Vitreum Scutum Gelidus Resistendi"
  ],
  "you cannot cast a spell while shouting": [
    "Non potes magicam emittere dum clamas"
  ],
  "bee and barb scene 01": [
    "Bee et Barb Scene 01"
  ],
  "bee and barb scene 03": [
    "Bee et Barb Scene 03"
  ],
  "bee and barb scene 04": [
    "Bee et Barb Scene 04"
  ],
  "riverwood dialogue": [
    "Riverwood dialogue"
  ],
  "dragonscale boots of shock suppression": [
    "Caligae Draconisquamatae Suppresionis Electricitatis"
  ],
  "scaled bracers of archery": [
    "Chirothecae Squamatae Saggitationis"
  ],
  "aversion to shock": [
    "Aversio Electricitati"
  ],
  "gjukar's monument": [
    "Monumentum Gjukaris"
  ],
  "the gold ribbon of merit": [
    "Fascea Aurea Meriti"
  ],
  "gold ribbon of merit": [
    "Fascea Aurea Meriti"
  ],
  "riften edda's beggar dialogue": [
    "Hiati Edda's Beggar Dialogue"
  ],
  "bee and barb scene 06": [
    "Bee et Barb Scene 06"
  ],
  "bee and barb scene 07": [
    "Bee et Barb Scene 07"
  ],
  "black briar meadery scene 01": [
    "Black Briar Meadery Scene 01"
  ],
  "lipswoodelfmale04": [
    "LipsWoodElfMale04"
  ],
  "steel battleaxe": [
    "Bellisecuris Adamanteus"
  ],
  "pawned prawn scene 01": [
    "Pawned Prawn Scene 01"
  ],
  "what's this about elves": [
    "Quid de his alfis"
  ],
  "philter of enhanced stamina": [
    "Philtrum Maioris Staminae"
  ],
  "pawned prawn scene 02": [
    "Pawned Prawn Scene 02"
  ],
  "black briar manor scene 01": [
    "Black Briar Manor Scene 01"
  ],
  "black briar manor scene 02": [
    "Black Briar Manor Scene 02"
  ],
  "temple of mara scene 01": [
    "Temple of Mara Scene 01"
  ],
  "temple of mara scene 02": [
    "Temple of Mara Scene 02"
  ],
  "snow shod house scene 01": [
    "Snow Shod House Scene 01"
  ],
  "humanbeard20": [
    "HumanBeard20"
  ],
  "orcish mace of weariness": [
    "Orci Maca Maioris Defatigationis"
  ],
  "th": [
    ""
  ],
  "skooma": [
    "Skooma"
  ],
  "haelga's bunkhouse scene 02": [
    "Haelga's Bunkhouse Scene 02"
  ],
  "haelga's bunkhouse scene 03": [
    "Haelga's Bunkhouse Scene 03"
  ],
  "where can we find alduin's wall": [
    "Ubi parietem Alduini invenire possumus"
  ],
  "bee and barb scene 08": [
    "Bee et Barb Scene 08"
  ],
  "bee and barb scene 09": [
    "Bee et Barb Scene 09"
  ],
  "bee and barb scene 10": [
    "Bee et Barb Scene 10"
  ],
  "dragonscale helmet of extreme destruction": [
    "Draconisquamata Galea Extremis Destructionis"
  ],
  "broken items cannot be equipped until they have been repaired": [
    "Broken items cannot be equipped until they have been repaired"
  ],
  "steel mace of fear": [
    "Maca Adamantea Timoris"
  ],
  "cheek color lower": [
    "Color Genarum Inferior"
  ],
  "saarthal exterior scene 3": [
    "Saarthalis Exterior Scene 3"
  ],
  "grand plaza scene 01": [
    "Grand Plaza Scene 01"
  ],
  "grand plaza scene 02": [
    "Grand Plaza Scene 02"
  ],
  "the steed: remove encumbered penalty": [
    "Equus: Remove Encumbered Penalty"
  ],
  "steed: remove encumbered penalty": [
    "Equus: Remove Encumbered Penalty"
  ],
  "grand plaza scene 03": [
    "Grand Plaza Scene 03"
  ],
  "grand plaza scene 04": [
    "Grand Plaza Scene 04"
  ],
  "grand plaza scene 05": [
    "Grand Plaza Scene 05"
  ],
  "grand plaza scene 08": [
    "Grand Plaza Scene 08"
  ],
  "riften keep scene 01": [
    "Hiati Keep Scene 01"
  ],
  "riften keep scene 02": [
    "Hiati Keep Scene 02"
  ],
  "riften keep scene 03": [
    "Hiati Keep Scene 03"
  ],
  "riften keep scene 04": [
    "Hiati Keep Scene 04"
  ],
  "mistwatch bandit": [
    "Nexivisus Latro"
  ],
  "are you an alchemist": [
    "Esne alchemista"
  ],
  "riften keep scene 05": [
    "Hiati Keep Scene 05"
  ],
  "riften keep scene 06": [
    "Hiati Keep Scene 06"
  ],
  "aren't you worried about the dragons": [
    "Nonne de draconibus sollicitaris"
  ],
  "riften keep scene 07": [
    "Hiati Keep Scene 07"
  ],
  "riften keep scene 08": [
    "Hiati Keep Scene 08"
  ],
  "riften keep scene 09": [
    "Hiati Keep Scene 09"
  ],
  "is the college hiding something": [
    "Occultatne aliquid Collegium"
  ],
  "mjoll's house scene 01": [
    "Mjoll's House Scene 01"
  ],
  "get to the point": [
    "Ad punctum adveni",
    "Dic rem maximi momenti"
  ],
  "daedric battleaxe of banishing": [
    "Bellisecuris Daedricus Expellendi"
  ],
  "ragged flagon scene 02": [
    "Ragged Flagon Scene 02"
  ],
  "letter from solitude": [
    "Epistula a Solitudine"
  ],
  "riften fishery scene 01": [
    "Hiati Fishery Scene 01"
  ],
  "riften fishery scene 02": [
    "Hiati Fishery Scene 02"
  ],
  "riften fishery scene 03": [
    "Hiati Fishery Scene 03"
  ],
  "you wanted to see me": [
    "Me videre voluisti"
  ],
  "loading extra content": [
    "Res superfluas aperiens"
  ],
  "creaturedialoguedragonpriest": [
    "CreatureDialogueDragonPriest"
  ],
  "enter sovngarde": [
    "Intra Valhallam"
  ],
  "reach alduin's portal to sovngarde": [
    "Intra Portale Alduini ad Valhalla"
  ],
  "thalmor - fooled by pc disguise": [
    "Visigothi - Fooled by PC disguise"
  ],
  "whiterun marketplace scene 1": [
    "Whiterun Marketplace Scene 1"
  ],
  "dwarven bow of burning": [
    "Dvemeris Arcus Cremandi"
  ],
  "the world-eater's eyrie": [
    "Aeria Consumptoris-Mundi"
  ],
  "world-eater's eyrie": [
    "Aeria Consumptoris-Mundi"
  ],
  "iron mace of draining": [
    "Ferri Maca Hauriendi"
  ],
  "find the source of the murders in frostflow lighthouse": [
    "Inveni Fontem Necationes apud Pharon Gelifluentis"
  ],
  "humanbeard02": [
    "HumanBeard02"
  ],
  "stay in the sanctuary": [
    "Mane in Sanctuario"
  ],
  "steel war axe of scorching": [
    "Bellisecuris Adamanteus Adurendi"
  ],
  "talk to sven": [
    "Adloquere Sven"
  ],
  "i'll see what i can do": [
    "Videbo quid me facere posse"
  ],
  "iron warhammer of soul snares": [
    "Bellimalleus Ferratus Animicapti"
  ],
  "open the whispering door": [
    "Aperi Portam Sussurantem"
  ],
  "steel plate boots of hauling": [
    "Laminatae Caligae Portandi"
  ],
  "angeline morrard": [
    "Angelina Morrard"
  ],
  "listen to the whispering door": [
    "Audi portam sussurantem"
  ],
  "who is mehrunes dagon": [
    "Quis est Cronos"
  ],
  "destroy the draugr in angarvunde (<global=dunangarvundedraugrkilledcount>/3)": [
    "Dele Draugr in Angarvunde (<Global=dunAngarvundeDraugrKilledCount>/3)"
  ],
  "da02 hook": [
    "DA02 Hook"
  ],
  "kill telrav": [
    "Neca Telravem"
  ],
  "wait for telrav to return with your reward": [
    "Especta Telravem et Recompensum tuum"
  ],
  "what does a housecarl do": [
    "Quid facit Domicustos"
  ],
  "you seem troubled": [
    "Exercitatus videris"
  ],
  "telrav's request": [
    "Rogamen Telravis"
  ],
  "bandit beat down": [
    "Bandit beat down"
  ],
  "iron shield of waning frost": [
    "Ferri Scutum Insenescentis Congelationis"
  ],
  "the house of horrors": [
    "Domus Horrorum"
  ],
  "house of horrors": [
    "Domus Horrorum"
  ],
  "return to valdr": [
    "Redi ad Valdrem"
  ],
  "clear out moss mother cavern": [
    "Vacua Cavarnam Matris Musci"
  ],
  "faida": [
    "Faida"
  ],
  "annekke crag-jumper": [
    "Annekke Rupi-Saltator"
  ],
  "locate valdr's hunting party": [
    "Inveni grex venatorum Valdris"
  ],
  "it's true. the night mother spoke to me. she said i was \"the one.\"": [
    "Ita vero. Mater Noctis me adlocuta est. Dixit me \"unum\" esse"
  ],
  "eidar cheese wheel": [
    "Eidaris Caseus Rota"
  ],
  "steel gauntlets of minor wielding": [
    "Adamanteae Manicae Minoris Tenendi"
  ],
  "iron shield of waning fire": [
    "Ferri Scutum Insenescentis Ignis"
  ],
  "audiotemplatehorker": [
    "AudioTemplateHorker"
  ],
  "meet up with stalleo in the courtyard": [
    "Stalleonem in Area Inveni"
  ],
  "apothecary": [
    "Apothecary"
  ],
  "use the lever to open the gate to treva's watch": [
    "Uti Fulcro ut Portam Visus Trevae Aperias"
  ],
  "the real barenziah, v1": [
    "Barenzia Vera, I"
  ],
  "real barenziah, v1": [
    "Barenzia Vera, I"
  ],
  "esbern": [
    "Esbern"
  ],
  "scene about jagged crown": [
    "Scene about Jagged Corona"
  ],
  "skaggi's house": [
    "Skaggi House"
  ],
  "daedric war axe of thunderbolts": [
    "Bellisecuris Daedricus Fulminum"
  ],
  "what's it to you": [
    "Quid tibi interest"
  ],
  "glass helmet of major restoration": [
    "Galea Vitrea Maioris Reficiendi"
  ],
  "dwarven dagger of siphoning": [
    "Dvemeris Pugio Siphonis"
  ],
  "volskygge": [
    "Volskygge"
  ],
  "winterhold guard's shield": [
    "Hiemiterrae Custodis Scutum"
  ],
  "repairexit": [
    "RepairExit"
  ],
  "creaturedialogueicewraith": [
    "CreatureDialogueIceWraith"
  ],
  "iron dagger of burning": [
    "Pugio Ferri Cremandi"
  ],
  "imperial sword of ice": [
    "Gladius Romanus Gelidus"
  ],
  "wabba ability": [
    "Wabba Ability"
  ],
  "fortify alteration": [
    "Adde ad Mutationem"
  ],
  "daedric sword of harvesting": [
    "Gladius Daedricus Saturni"
  ],
  "brothers of darkness": [
    "Fratres Obscuritatis"
  ],
  "fortify blocking": [
    "Adde ad Artem Interponendi"
  ],
  "saw mill": [
    "Mola Serrae"
  ],
  "i want to train in destruction magic": [
    "Volo de Magica Destructionis discere"
  ],
  "(optional) travel to riverwood with <alias=factionfriend>": [
    "(Optio) Iter fac Lignirivum cum <Alias=FactionFriend>"
  ],
  "talk to <alias=riverwoodfriend> in riverwood": [
    "Adloquere <Alias=RiverwoodFriend> Lignirivi"
  ],
  "belyn hlaalu": [
    "Belyn Hlaalu"
  ],
  "before the storm": [
    "Ante Tempestatem"
  ],
  "accept jarl <alias=jarl>'s surrender": [
    "Accipe deditionem <Alias=Jarl>"
  ],
  "alik'r prisoner": [
    "Alik'ris Captivus"
  ],
  "break through the final barricade": [
    "Frange Concaedem Ultimam"
  ],
  "break through the second barricade": [
    "Frange Concaedem Secundam"
  ],
  "enter <alias=city>": [
    "Intra <Alias=City>"
  ],
  "open the drawbridge": [
    "Aperi Cataractam"
  ],
  "open the exterior gate": [
    "Aperi portam externalem"
  ],
  "take out the archers": [
    "Neca Sagittarios"
  ],
  "meet with the <alias=general>": [
    "Adiungere imperatorem <Alias=General>"
  ],
  "battle of <alias=city>": [
    "Proelio <Alias=City>"
  ],
  "elven bow of immobilizing": [
    "Arcus Alfus Immobilitatis"
  ],
  "report success to <alias=fieldco>": [
    "Refer Nuntium ad <Alias=FieldCO>"
  ],
  "report to <alias=friend>": [
    "Refer te ad <Alias=Friend>"
  ],
  "take over the caravan": [
    "Supera Caravannam"
  ],
  "follow <alias=friend> and ambush enemy scout": [
    "Sequere <Alias=Friend> et insidias pro exploratore inimicorum para"
  ],
  "meet the men": [
    "Conveni cum militibus"
  ],
  "blackmail <alias=steward>": [
    "Extorque <Alias=Steward>"
  ],
  "find evidence": [
    "Inveni Evidentiam"
  ],
  "compelling tribute": [
    "Stipendium Cogens"
  ],
  "thanks for nothing": [
    "Gratias pro nihilo"
  ],
  "whistling mine": [
    "Whistling Mine"
  ],
  "brittleshin pass": [
    "Brittleshin Pass"
  ],
  "and you think ulfric will let me just walk in there, no trouble": [
    "Et credis ut Vercingetorix te intrare sinat, sine difficultate"
  ],
  "emissary elenwen was asking for you inside": [
    "Legatus Elenven pro te intus rogavit"
  ],
  "dark brotherhood additional npc dialogue": [
    "Fraternitas Obscura Additional NPC Dialogue"
  ],
  "riften honorhall orphanage scene 1": [
    "Hiati Honorhall Orphanage Scene 1"
  ],
  "saarthal exterior scene 2": [
    "Saarthalis Exterior Scene 2"
  ],
  "femaleeyeshumanhazel": [
    "FemaleEyesHumanHazel"
  ],
  "elven war axe of torpor": [
    "Alfus Bellisecuris Torporis"
  ],
  "the tower stone": [
    "Turris Lapis"
  ],
  "tower stone": [
    "Turris Lapis"
  ],
  "iron mace of torpor": [
    "Ferri Maca Torporis"
  ],
  "destroy attackers (<global=cwpercentpoolremainingattacker>% remaining)": [
    "Neca Oppugnatores (<Global=CWPercentPoolRemainingAttacker>% Manent)"
  ],
  "defend the exterior gate": [
    "Defende Portam Externalem"
  ],
  "defend the drawbridge": [
    "Defende Cataractam"
  ],
  "i have a letter for you, from sven": [
    "Epistulam tibi habeo, a Sven"
  ],
  "defend the barricades": [
    "Defende Concaedem"
  ],
  "but there's nobody else here": [
    "Sed nemo praeter te hic est"
  ],
  "join the general on the front line": [
    "Adiunge imperatorem in Primo Ordine"
  ],
  "defense of <alias=city>": [
    "Defensum <Alias=City>"
  ],
  "assassinate the jarl of solitude": [
    "Neca Comitem Solitudinis"
  ],
  "you're a necromancer": [
    "Necromanticus es"
  ],
  "karliah cross-quest dialogue": [
    "Karliah Cross-Quest Dialogue"
  ],
  "are there any more contracts available": [
    "Suntne plurima foedera"
  ],
  "is fleeing for their life": [
    "pro vita sua effugit"
  ],
  "can you offer me any advice on the contracts": [
    "Potesne mihi consilium dare de foederibus"
  ],
  "retrieve barrel of honningbrew mead": [
    "Refer Cuppam Hydromelli Honningbrevis"
  ],
  "anton virane": [
    "Anton Virane"
  ],
  "haran": [
    "Haran"
  ],
  "%s signal found": [
    "%s signal found"
  ],
  "forsworn sword": [
    "Abiuratus Gladius"
  ],
  "daedric battleaxe of the sorcerer": [
    "Bellisecuris Daedricus Magi"
  ],
  "clear the skeever infestation": [
    "Emove Infestationem Skeever"
  ],
  "you face the dread lord": [
    "Vides Dominum Terribilem"
  ],
  "scroll of conjure flame atronach": [
    "Codex Vocandi Atronachum Flammarum"
  ],
  "ice wraith teeth": [
    "Dens Phantasmae Glaciei"
  ],
  "forgotten names": [
    "Nomina Oblita"
  ],
  "the pursuit scene quest": [
    "The Pursuit Scene Quest"
  ],
  "pursuit scene quest": [
    "The Pursuit Scene Quest"
  ],
  "elven mace of freezing": [
    "Maca Alfa Gelidus"
  ],
  "ebony warhammer of lightning": [
    "Bellimalleus Ebeni Fulminis"
  ],
  "argonian ale": [
    "Vinum Argonium"
  ],
  "activate the armor stone": [
    "Activa Lapidem Loricae"
  ],
  "meet karliah at the standing stone": [
    "Conveni Karliam apud lapidem stantem"
  ],
  "are you a mercenary": [
    "Esne mercenarius"
  ],
  "you won't find much work here": [
    "Multam laborem hic non invenies"
  ],
  "speak to the witchhunter about the abandoned house": [
    "Adloquere Sagavenatorem de Villa Abiecta"
  ],
  "the school of restoration focuses on the mastery over life forces, and is used to cast spells like healing, turn undead, and lesser ward": [
    "Schola Recreationis agitur de potestate super vires vitae. Usurpatur ut magicam ut Sanare, Repelle Amortuos, et Praesidium facias"
  ],
  "school of restoration focuses on the mastery over life forces, and is used to cast spells like healing, turn undead, and lesser ward": [
    "Schola Recreationis agitur de potestate super vires vitae. Usurpatur ut magicam ut Sanare, Repelle Amortuos, et Praesidium facias"
  ],
  "how about you tell me for free": [
    "Quidni me gratis dicas"
  ],
  "sanguine": [
    "Sanguine"
  ],
  "what's expected of me here": [
    "Quae expectationes mihi hic sunt"
  ],
  "ancano": [
    "Ancano"
  ],
  "trinity restored": [
    "Trinitas Refecta"
  ],
  "snow veil sanctum": [
    "Sanctum Nivi Velatum"
  ],
  "dialoguemarkarthinnendonfamilyscene02": [
    "DialogueMarkarthInnEndonFamiliaeScene02"
  ],
  "dialoguemarkarthmarketscenehognidegaine1": [
    "DialogueMarkarthMarketSceneHogniDegaine1"
  ],
  "yeah, i've been getting that a lot": [
    "Sic, id recente saepe audivi"
  ],
  "erj": [
    "Erj"
  ],
  "hide helmet": [
    "Renonis Galea"
  ],
  "a chance arrangement spectator quest": [
    "A Chance Arrangement Spectator Quest"
  ],
  "chance arrangement spectator quest": [
    "A Chance Arrangement Spectator Quest"
  ],
  "dialoguemarkarthkeepmoththongvorscene02": [
    "DialogueMarkarthKeepMothThongvorScene02"
  ],
  "dialoguemarkartharnleiflisbetcosnachscene01": [
    "DialogueMarkarthArnleifLisbetCosnachScene01"
  ],
  "current location": [
    "Hic es"
  ],
  "dialoguemarkartharnleiflisbetcosnachscene02": [
    "DialogueMarkarthArnleifLisbetCosnachScene02"
  ],
  "dialoguemarkarthsmeltermulushomluag02": [
    "DialogueMarkarthSmelterMulushOmluag02"
  ],
  "dialoguemarkarthsmeltermulushomluag01": [
    "DialogueMarkarthSmelterMulushOmluag01"
  ],
  "pumping station": [
    "Pumping Statio"
  ],
  "dialoguemarkarthsmeltermulushhathrasil02": [
    "DialogueMarkarthSmelterMulushHathrasil02"
  ],
  "dialoguemarkarthsmeltermulushhathrasil01": [
    "DialogueMarkarthSmelterMulushHathrasil01"
  ],
  "expert hammer": [
    "Expert Hammer"
  ],
  "dialoguemarkarthsmelteromluaghathrasil02": [
    "DialogueMarkarthSmelterOmluagHathrasil02"
  ],
  "db09 door lock faction": [
    "DB09 Door Lock Faction"
  ],
  "imperial light helmet of minor illusion": [
    "Romana Cassis Minoris Inlusionis"
  ],
  "rogue wizard": [
    "Thaumaturgos Sine Domino"
  ],
  "does a woman named margret stay here": [
    "Habitatne hic femina nomine Margret"
  ],
  "scaled helmet of major alteration": [
    "Galea Squamata Maioris Mutationis"
  ],
  "creaturedialoguedog": [
    "CreatureDialogueDog"
  ],
  "dialoguegenericscenedog01": [
    "DialogueGenericSceneDog01"
  ],
  "dialoguedawnstarinnfridakaritascene01": [
    "DialogueDawnstarInnFridaKaritaScene01"
  ],
  "dialoguedawnstarinnfridathoringscene01": [
    "DialogueDawnstarInnFridaThoringScene01"
  ],
  "dialoguedawnstarinnirgnirfrukiscene02": [
    "DialogueDawnstarInnIrgnirFrukiScene02"
  ],
  "daedric sword of expelling": [
    "Gladius Daedricus Expellendi"
  ],
  "dialoguegenericdoghellos": [
    "DialogueGenericDogHellos"
  ],
  "ma'tasarr": [
    "Ma'tasarr"
  ],
  "college suspension": [
    "Suspensum Collegii"
  ],
  "place the coat on the effigy": [
    "Pone Amiculum in Effigiem"
  ],
  "is there any advice you can give about destruction spells": [
    "Habesne consilium mihi de magica Destructionis"
  ],
  "speak to jorn after dusk": [
    "Adloquere Jornem Noctu"
  ],
  "ms13arvelshout01trigger": [
    "MS13ArvelShout01Trigger"
  ],
  "steal general tullius' coat": [
    "Furare Amiculum Imperatoris Tullii"
  ],
  "you look like you could use some help": [
    "Videres te auxilio uti posse"
  ],
  "speak to jorn": [
    "Adloquere Jornem"
  ],
  "watch viarmo perform olaf's verse": [
    "Specta Viarmonem Versum Olafis agentem"
  ],
  "meet viarmo at the blue palace": [
    "Conveni cum Viarmo ad Aulam Caeruleam"
  ],
  "help viarmo reconstruct olaf's verse": [
    "Adiuva Viarmo Carmen Regis Olafis Reficere"
  ],
  "regain falkreath hold": [
    "Recipe Falkretiam"
  ],
  "return to the bards college": [
    "Redi ad Collegium Bardorum"
  ],
  "give viarmo king olaf's verse": [
    "Da Carmen Regis Olafis ad Viarmonem"
  ],
  "hairfemaleredguard01": [
    "HairFemaleRedguard01"
  ],
  "i've inscribed the lexicon": [
    "Lexicon inscripsi"
  ],
  "how can you protect whiterun from the dragons": [
    "Quomodo potes Albacursum e draconibus protegere"
  ],
  "hairlinefemalenord12": [
    "HairLineFemaleNord12"
  ],
  "you mentioned something about whiterun before": [
    "Aliquid de Albacurso commemoravisti"
  ],
  "tending the flames": [
    "Flammas Colens"
  ],
  "golden urn": [
    "Urnam Auream"
  ],
  "open the other gate": [
    "Aperi Portam Aliam"
  ],
  "borvir": [
    "Borvir"
  ],
  "agni": [
    "Agni"
  ],
  "griefstricken chef": [
    "Coquus Lacrimans"
  ],
  "bilegulch mine": [
    "Metallum Convallis Biliosi"
  ],
  "mg dormitory scene 3": [
    "MG Dormitory Scene 3"
  ],
  "orcish bow of thunderbolts": [
    "Orci Arcus Fulminum"
  ],
  "disarm 3": [
    "Disarm 3"
  ],
  "mg dormitory scene 5": [
    "MG Dormitory Scene 5"
  ],
  "karthwasten guard": [
    "Karthwasten Guard"
  ],
  "hairmaleimperialchild01": [
    "HairMaleImperialisChild01"
  ],
  "can show up at battles in this hold": [
    "Can show up at battles in this hold"
  ],
  "dialoguedushnikhyalblacksmithingscene02": [
    "DialogueDushnikhYalBlackFerrariiScene02"
  ],
  "dialoguemorkhazgurbagrakborghakscene01": [
    "DialogueMorKhazgurBagrakBorghakScene01"
  ],
  "some of your downloaded content is corrupt and could not be loaded": [
    "Some of your downloaded content is corrupt et could not be loaded"
  ],
  "return to delvin": [
    "Reveni ad Delphinum"
  ],
  "ma'randru-jo is dead": [
    "Ma'randru-jo mortuus est"
  ],
  "the numbers job": [
    "Munus \"Numerorum\""
  ],
  "numbers job": [
    "Munus \"Numerorum\""
  ],
  "attack <alias=capital>": [
    "Oppugna <Alias=Capital>"
  ],
  "hide helmet of alteration": [
    "Cutis Galea Mutationis"
  ],
  "dwarven mace of fear": [
    "Dvemeris Maca Timoris"
  ],
  "vantus loreius": [
    "Vantus Loreius"
  ],
  "you cannot sit on owned furniture": [
    "Non potes considere in suppelicibus emptis"
  ],
  "daedric bow of petrifying": [
    "Arcus Daedricus Lapidescendi"
  ],
  "dialoguesoljundsminehousescene02": [
    "DialogueSoljundsMineHouseScene02"
  ],
  "staff of the frost wall": [
    "Rudis Parietis Glaciei"
  ],
  "dialoguesoljundsminehousescene03": [
    "DialogueSoljundsMineHouseScene03"
  ],
  "i'm not apologizing": [
    "Non veniam peto"
  ],
  "and i'm not afraid of you": [
    "Nec te timeo"
  ],
  "the only thing you will discover in mzinchaleft is death": [
    "Res sola quam in Mzinchaleft invenies est mors"
  ],
  "only thing you will discover in mzinchaleft is death": [
    "Res sola quam in Mzinchaleft invenies est mors"
  ],
  "dialoguesoljundsminescene02": [
    "DialogueSoljundsMineScene02"
  ],
  "tell pavo that kolskeggr mine is cleared": [
    "Dic Pavonem Metallum Kolskeggris Vacuatum Esse"
  ],
  "clear the forsworn from kolskeggr mine": [
    "Vacua Abiuratos e Metallo Kolskeggris"
  ],
  "imperial armor of major health": [
    "Romana Lorica Maioris Salutis"
  ],
  "dialoguekolskeggrmine": [
    "DialogueKolskeggrMine"
  ],
  "dialoguekolskeggrminescene01": [
    "DialogueKolskeggrMineScene01"
  ],
  "effects": [
    "Effecta"
  ],
  "urag gro-shub": [
    "Urag gro-Shub"
  ],
  "sleeping giant services": [
    "Sleeping Giant Services"
  ],
  "psychic agony": [
    "Psychic Agony"
  ],
  "loading area": [
    "Aream aperiens"
  ],
  "dialoguelefthandminepavogatscene02": [
    "DialogueLeftHandMinePavoGatScene02"
  ],
  "dialoguekolskeggrminescene02": [
    "DialogueKolskeggrMineScene02"
  ],
  "dialoguekolskeggrminehousescene01": [
    "DialogueKolskeggrMineHouseScene01"
  ],
  "dialoguekolskeggrminehousescene02": [
    "DialogueKolskeggrMineHouseScene02"
  ],
  "falion's secret": [
    "Secretum Falionis"
  ],
  "deliver gorm's letter to captain aldis": [
    "Fer Epistulam Gormis ad Centurionem Aldis"
  ],
  "creaturedialogueatronachfrost": [
    "CreatureDialogueAtronachumFrost"
  ],
  "just the short version": [
    "Modo versio parva"
  ],
  "perks": [
    "Perks"
  ],
  "sulvar the steady": [
    "Sulvar Constans"
  ],
  "convince ainethach to sell saunaruch mine": [
    "Persuade Atari ut Metallum Sauranachis vendat"
  ],
  "daedric battleaxe of the inferno": [
    "Bellisecuris Daedricus Conflagrationis"
  ],
  "convince atar to leave sauranach mine": [
    "Persuade Atari ut e Metallo Sauranachis exeat"
  ],
  "how do we find the entrance to sky haven temple": [
    "Quomodo ingressum Templi Refugiti Caeli invenire possumus"
  ],
  "femaleeyeshumanlightgrey": [
    "FemaleEyesHumanLightGrey"
  ],
  "lockpicking trainer": [
    "Lockpicking Trainer"
  ],
  "creaturedialoguechaurus": [
    "CreatureDialogueChaurus"
  ],
  "dialoguekarthwastenenmonbelchimach01": [
    "DialogueKarthwastenEnmonBelchimach01"
  ],
  "what is it that you need": [
    "Quid vis"
  ],
  "steel plate boots of waning shock": [
    "Laminatae Caligae Insenescentis Electricitatis"
  ],
  "glass sword of expelling": [
    "Gladius Vitreus Expellendi"
  ],
  "dialoguekarthwastent01enmonshousescene04": [
    "DialogueKarthwastenT01EnmonsHouseScene04"
  ],
  "dialoguekarthwastenragnarlash01": [
    "DialogueKarthwastenRagnarLash01"
  ],
  "dialoguekarthwastenragnarlash02": [
    "DialogueKarthwastenRagnarLash02"
  ],
  "dialoguekarthwastenragnarlash03": [
    "DialogueKarthwastenRagnarLash03"
  ],
  "wary outlaw": [
    "Utlagus Cautus"
  ],
  "the blades need new members": [
    "Enses novos quaerunt"
  ],
  "blades need new members": [
    "Enses novos quaerunt"
  ],
  "dialoguekarthwastenragnarbelchimac01": [
    "DialogueKarthwastenRagnarBelchimac01"
  ],
  "dialoguekarthwastenlashbelchimac01": [
    "DialogueKarthwastenLashBelchimac01"
  ],
  "dialoguekarthwastenlashbelchimac02": [
    "DialogueKarthwastenLashBelchimac02"
  ],
  "quest to hold aliases and scenes for party": [
    "Quest to hold aliases et scenes for party"
  ],
  "spider control rod": [
    "Rudis Araneam Dirigendo"
  ],
  "talk with <alias=factionleader>": [
    "Adloquere <Alias=FactionLeader>"
  ],
  "why do you need guards": [
    "Quamobrem custodibus eges"
  ],
  "rescue from <alias=attackpoint>": [
    "Salvatio ab <Alias=AttackPoint>"
  ],
  "birna's house scene 1": [
    "Birna's House Scene 1"
  ],
  "birna's house scene 2": [
    "Birna's House Scene 2"
  ],
  "birna's house scene 3": [
    "Birna's House Scene 3"
  ],
  "arniel's convection": [
    "Arniel's Convection"
  ],
  "birna's house scene 4": [
    "Birna's House Scene 4"
  ],
  "ceremonial brazier": [
    "Foculus Ceremonialis"
  ],
  "creaturedialoguefalmer": [
    "CreatureDialogueFalmer"
  ],
  "dragonscale boots of shock abatement": [
    "Caligae Draconisquamatae Contra Vim Electricam"
  ],
  "alteration staff": [
    "Alteration Staff"
  ],
  "you mentioned a tour": [
    "Commemoravisti explorationem"
  ],
  "<alias=steward> says there's a shipment of coin traveling to windhelm": [
    "<Alias=Steward> dicit plaustrum plenum pecuniae in via ad Windhelm esse"
  ],
  "an old sword": [
    "Gladius vetus"
  ],
  "that's a fool's errand": [
    "Munus stulto est"
  ],
  "snow shod house scene 03": [
    "Snow Shod House Scene 03"
  ],
  "place the crystal into the oculory": [
    "Pone Crystalum in Oculatorium"
  ],
  "but you weren't killed... not even once": [
    "Sed non necatus es... nec etiam semel"
  ],
  "bring hjalti's sword to the ghost of old hroldan": [
    "Fer Gladium Hjaltis ad Phantasmam Veteris Hroldanis"
  ],
  "the ghost of old hroldan": [
    "Phantasma Hroldanis Veteris"
  ],
  "ghost of old hroldan": [
    "Phantasma Hroldanis Veteris"
  ],
  "elven shield of dwindling fire": [
    "Alfum Scutum Minoris Ignis"
  ],
  "dome02": [
    "Dome02"
  ],
  "supply line": [
    "Supply Line"
  ],
  "iron helmet of major magicka": [
    "Ferri Galea Maioris Magicae"
  ],
  "dialogueoldhroldaneydisskuliscene02": [
    "DialogueOldHroldanEydisSkuliScene02"
  ],
  "dialogueoldhroldaneydisskuliscene01": [
    "DialogueOldHroldanEydisSkuliScene01"
  ],
  "red eagle's bane": [
    "Mors Aquilae Rubris"
  ],
  "dialogue event scene for soldiers": [
    "Dialogue Event Scene for Soldiers"
  ],
  "dialogue shor's stone conversation": [
    "Dialogue Shor's Stone Conversation"
  ],
  "can you teach me about conjuration": [
    "Potesne me docere de Arte Vocandi"
  ],
  "what was her crime": [
    "Quid erat crimen eius"
  ],
  "i thought the empire needed to be taken down a notch": [
    "Credidi Imperium poenas dare debere"
  ],
  "i'll keep an eye out for anything": [
    "Circumspectabo"
  ],
  "what is this about": [
    "De qua re hic agitur"
  ],
  "do you always insult people when you meet them": [
    "Semper homines insultas cum eos convenias"
  ],
  "soldier player dialogue": [
    "Soldier Player Dialogue"
  ],
  "hello event scene": [
    "Hello Event Scene"
  ],
  "enter w/ weapon drawn": [
    "Enter w/ Weapon Drawn"
  ],
  "creaturedialoguegiant": [
    "CreatureDialogueGiant"
  ],
  "elven battleaxe of lightning": [
    "Bellisecuris Alfus Fulminis"
  ],
  "wizard duel": [
    "Duellum Thaumatorgos"
  ],
  "that dark elf called you a war hero": [
    "Atralfus te Heroon Belli appelavit"
  ],
  "the dark brotherhood": [
    "The Dark Brotherhood"
  ],
  "dark brotherhood": [
    "Fraternitas Obscura",
    "The Dark Brotherhood"
  ],
  "first letter from linwe": [
    "Epistula Prima a Linve"
  ],
  "iron battleaxe of embers": [
    "Bellisecuris Ferri Cinerum"
  ],
  "generic crowd maker": [
    "Generic Crowd Maker"
  ],
  "i don't want to purchase any decorations right now": [
    "Ornamenta nunc emere nolo"
  ],
  "honor thy family": [
    "Honora Familiam Tuam"
  ],
  "bee in a jar": [
    "Apis in Matula"
  ],
  "solitude lighthouse fire": [
    "Ignis Pharos Solitudinis"
  ],
  "child scares npcs": [
    "Child scares NPCs"
  ],
  "gift from a friend": [
    "Gift from friend"
  ],
  "dungeon delving (bandits)": [
    "Dungeon Delving (Bandits)"
  ],
  "pay tolfidr 1000 gold to lift your suspension": [
    "Da M Auri Tolfdiri ut Suspensum Eradat"
  ],
  "riften keep alternate scene 02": [
    "Hiati Keep Alternate Scene 02"
  ],
  "daedric war axe of stunning": [
    "Bellisecuris Daedricus Intermissionis"
  ],
  "riften keep alternate scene 03": [
    "Hiati Keep Alternate Scene 03"
  ],
  "riften keep alternate scene 04": [
    "Hiati Keep Alternate Scene 04"
  ],
  "i hear you want someone to look into that house fire": [
    "Audivi te aliquem qui ignem domi investigaret quaerere"
  ],
  "riften keep alternate scene 05": [
    "Hiati Keep Alternate Scene 05"
  ],
  "north shriekwind bastion": [
    "Castellum Venticlamantis Septentrionis"
  ],
  "glass armor of eminent health": [
    "Vitrea Lorica Eminentis Salutis"
  ],
  "riften keep alternate scene 06": [
    "Hiati Keep Alternate Scene 06"
  ],
  "riften keep alternate scene 07": [
    "Hiati Keep Alternate Scene 07"
  ],
  "riften keep alternate scene 08": [
    "Hiati Keep Alternate Scene 08"
  ],
  "elgrim's elixir's scene 03": [
    "Elgrim's Elixir's Scene 03"
  ],
  "elgrim's elixir's scene 04": [
    "Elgrim's Elixir's Scene 04"
  ],
  "steel greatsword of shocks": [
    "Spatha Adamantea Electricitatis"
  ],
  "orcish boots of muffling": [
    "Orci Caligae Silentii"
  ],
  "haelga's bunkhouse scene 05": [
    "Haelga's Bunkhouse Scene 05"
  ],
  "you will die now": [
    "Nunc morieris"
  ],
  "\"...and unwavering obedience to the officers of his great empire.\"": [
    "\"...et oboedire omnes legatos Emperii Magni eius.\""
  ],
  "scene about balgruuf": [
    "Scene about Balgruuf"
  ],
  "haelga's bunkhouse scene 08": [
    "Haelga's Bunkhouse Scene 08"
  ],
  "reverent glass sword": [
    "Reverens Gladius Vitreus"
  ],
  "(<global=hdwindhelmalchemy> gold)": [
    "(<Global=HDWindhelmAlchemy> auri)"
  ],
  "i am an assassin without equal": [
    "Sicarius sine pare sum"
  ],
  "i belong here": [
    "Hic esse debeo"
  ],
  "riften snilf's beggar dialogue": [
    "Hiati Snilf's Beggar Dialogue"
  ],
  "collect the reward from <alias=enemy> for killing <alias=victim>": [
    "Accipe praemium ab <Alias=Enemy> pro necando <Alias=Victim>"
  ],
  "mnemic egg": [
    "Ovum Mnemicum"
  ],
  "conjurer's elixir": [
    "Vocatoris Commixtum"
  ],
  "angarvunde key": [
    "Clavis ad Angarvunde"
  ],
  "read the letter from <alias=enemy>": [
    "Lege Epistulam ab <Alias=Enemy>"
  ],
  "an enemy's gratitude": [
    "Gratitudo Inimici"
  ],
  "scroll of cure wounds": [
    "Codex Vulnera Curandi"
  ],
  "intimidate": [
    "Intimida"
  ],
  "tuthul": [
    "Tuthul"
  ],
  "elven bracers of eminent sure grip": [
    "Chirothecae Alfae Eminentis Ambidexteritatis"
  ],
  "rahd": [
    "Rahd"
  ],
  "ancient nord arrow": [
    "Sagitta Antiqua Germanica"
  ],
  "kill the leader of <alias=banditcamp>": [
    "Neca Ducem in <Alias=BanditCamp>"
  ],
  "ragged flagon scene 03": [
    "Ragged Flagon Scene 03"
  ],
  "ragged flagon scene 04": [
    "Ragged Flagon Scene 04"
  ],
  "deliver axe to the jarl of whiterun": [
    "Adfer securem ad Comitem Albacursus"
  ],
  "ragged flagon scene 06": [
    "Ragged Flagon Scene 06"
  ],
  "ragged flagon scene 07": [
    "Ragged Flagon Scene 07"
  ],
  "ragged flagon scene 09": [
    "Ragged Flagon Scene 09"
  ],
  "ogol": [
    "Ogol"
  ],
  "ragged flagon scene 10": [
    "Ragged Flagon Scene 10"
  ],
  "daedric dagger of damnation": [
    "Pugio Daedricus Damnationis"
  ],
  "ragged flagon scene 11": [
    "Ragged Flagon Scene 11"
  ],
  "sure, kid": [
    "Certe, puer"
  ],
  "poof": [
    "En"
  ],
  "you're invisible": [
    "Invisibilis es"
  ],
  "holy ebony war axe": [
    "Sanctus Bellisecuris Ebeni"
  ],
  "banded iron armor of the major knight": [
    "Ferro Adligata Lorica Maioris Equitis"
  ],
  "player has broken up with the love interest": [
    "Player Has Broken Up with Love Interest"
  ],
  "steel dagger of shocks": [
    "Pugio Adamanteus Electricitatis"
  ],
  "attend your wedding ceremony": [
    "Ii ad nuptias tuas"
  ],
  "follower sees an impressive view": [
    "Follower sees impressive view"
  ],
  "danger ahead": [
    "Danger Ahead"
  ],
  "browsmalehumanoid11": [
    "BrowsMaleHumanoid11"
  ],
  "is now your": [
    "nunc est tuus"
  ],
  "report to the jarl of riften": [
    "Refer te ad Comitem Hiati"
  ],
  "disrupt the skooma operation": [
    "Dele negotium skoomae"
  ],
  "supply and demand": [
    "Supplementum et Postulatio"
  ],
  "command animal": [
    "Impera Animali"
  ],
  "leather bracers": [
    "Corii Bracchialia"
  ],
  "tell sibbi black-briar about lynly": [
    "Narra Sibbi Atri-Dumum de Lynly"
  ],
  "ms12sharedinfo": [
    "MS12SharedInfo"
  ],
  "the lover's requital": [
    "Ultio Amatoris"
  ],
  "lover's requital": [
    "Ultio Amatoris"
  ],
  "show mead to indaryn": [
    "Demonstra Hydromellum ad Indaryn"
  ],
  "under the table": [
    "Sub Mensa"
  ],
  "proceed to center of cistern": [
    "Procede ad Medium Cisternam"
  ],
  "find 20 deathbell for ingun black-briar (<global=ffr04deathbellcount>/<global=ffr04deathbelltotal>)": [
    "Inveni XX Tintinabulamortes pro Ingun Atri-Dumo (<Global=FFR04DeathbellCount>/<Global=FFR04DeathbellTotal>)"
  ],
  "few and far between": [
    "Pauci et Procul"
  ],
  "return to dinya": [
    "Reveni ad Dinyam"
  ],
  "distribute the warmth of mara within riften (<global=ffriften05served>/<global=ffriften05total>)": [
    "Da apricitatem Marae incolis Riften (<Global=FFRiften05Served>/<Global=FFRiften05Total>)"
  ],
  "spread the love": [
    "Sparge Amorem"
  ],
  "return to talen-jei": [
    "Reveni ad Talen-Jei"
  ],
  "what's the tax for": [
    "Pro quo est vectigal"
  ],
  "return to marise": [
    "Reveni ad Marisem"
  ],
  "find 5 ice wraith teeth for marise (<global=ffr07teethcount>/<global=ffr07teethtotal>)": [
    "Inveni V Dentes Phantasmae Glaciei Marisi (<Global=FFR07TeethCount>/<Global=FFR07TeethTotal>)"
  ],
  "ice cold": [
    "Frigidus ut Glacies"
  ],
  "return to brand-shei": [
    "Reveni ad Brand-Shei"
  ],
  "kill deekus": [
    "Neca Deekum"
  ],
  "distant memories": [
    "Memoriae Antiquae"
  ],
  "return to mjoll": [
    "Reveni ad Mjoll"
  ],
  "return to balimund": [
    "Redi ad Balimund"
  ],
  "coming of age": [
    "Ad Aetetem Veniens"
  ],
  "talk to faendal": [
    "Adloquere Faendal"
  ],
  "(optional) tell faendal about sven's letter": [
    "(Optio) Dic Faendal de Epistula Svenis"
  ],
  "deliver sven's letter to camilla": [
    "Fer Epistulam Svenis Camillae"
  ],
  "femalebrowshuman06": [
    "FemaleBrowsHuman06"
  ],
  "ebony greatsword of expelling": [
    "Spatha Ebeni Expellendi"
  ],
  "ebony gauntlets of extreme sure grip": [
    "Ebeni Manicae Extremis Ambidexteritatis"
  ],
  "the steed stone": [
    "Equi Lapis"
  ],
  "steed stone": [
    "Equi Lapis"
  ],
  "choose item to repair with": [
    "CHOOSE ITEM TO REPAIR WITH"
  ],
  "ebony dagger of nullifying": [
    "Pugio Ebeni Nullificandi"
  ],
  "whiterun heimskr's house faction": [
    "Whiterun Heimskr's House Faction"
  ],
  "you cannot sleep in the air": [
    "Non potes in aere dormire"
  ],
  "hallowed ebony dagger": [
    "Consacratus Pugio Ebeni"
  ],
  "obtain rahgot's mask": [
    "Cape Personam Rahgotis"
  ],
  "return to endon": [
    "Redi ad Endon"
  ],
  "i'm just a traveler": [
    "Modo peregrinator sum"
  ],
  "potent frenzy poison": [
    "Potens Insanitatis Venenum"
  ],
  "enter pinewatch": [
    "Intra Pinivisum"
  ],
  "can't we talk here": [
    "Nonne hic loqui possumus"
  ],
  "ulfric should compensate you for <alias=imperialtownraided>": [
    "Vercingetorix tibi pro <Alias=ImperialTownRaided> remunerare debet"
  ],
  "no valid save game found": [
    "Nulli Ludi Tuti Validi Inventi"
  ],
  "return to erikur": [
    "Redi ad Erikur"
  ],
  "whiterun house battle-born faction": [
    "Whiterun House Battle-Born Faction"
  ],
  "acquire balmora blue": [
    "Cape Caeruleam Balmoram"
  ],
  "speak to erikur": [
    "Adloqere Erikur"
  ],
  "blizzard hazard 20": [
    "Blizzard Hazard 20"
  ],
  "speak to adelaisa to return": [
    "Adloquere Adelaisam ut revenias"
  ],
  "leather helmet of illusion": [
    "Cutis Galea Inlusionis"
  ],
  "forge the prison registry": [
    "Falsifica tabulam carceris"
  ],
  "i don't": [
    "Nolo"
  ],
  "how does the ebonmere affect all of us": [
    "Quomodo Ebonmere nos omnes afficit"
  ],
  "door to eltrys's room": [
    "Ianuam ad conclave Eltrys"
  ],
  "broken steel sword handle": [
    "Fractus Gladius Adamanteus Cherolaba"
  ],
  "maleeyeshumaniceblue": [
    "MaleEyesHumanIceBlue"
  ],
  "greybeard's robes": [
    "Canabarbae Peplum"
  ],
  "marksmalehumanoid09rightgash": [
    "MarksMaleHumanoid09RightGash"
  ],
  "iron gauntlets of minor sure grip": [
    "Ferri Manicae Minoris Dexteritatis"
  ],
  "speak to olfrid battle-born": [
    "Olfridem Proeli-Natum Adloquere"
  ],
  "daedric helmet of eminent archery": [
    "Galea Daedrica Eminentis Saggitationis"
  ],
  "listen to brynjolf's scheme": [
    "Ausculta Brynjolf's scheme"
  ],
  "ebony helmet of major alteration": [
    "Ebeni Galea Maioris Mutationis"
  ],
  "imitation amnesty": [
    "Imitatio Amnestiae"
  ],
  "the taste of death": [
    "Sapor Mortis"
  ],
  "taste of death": [
    "Sapor Mortis"
  ],
  "do you have anything for sale": [
    "Habesne quid vendendum"
  ],
  "how do i get to the top of the mountain to see him": [
    "Quomodo ad summum montis eo ut eum videam"
  ],
  "geimund": [
    "Geimund"
  ],
  "report back to fjola": [
    "Refer te ad Fjolam"
  ],
  "ulfric has a point": [
    "Vercingetorix recte dicit"
  ],
  "the thalmor have no business here": [
    "Visigothi nihil cum Caelifini habent",
    "Visigothi nullum negotium hic habent"
  ],
  "thalmor have no business here": [
    "Visigothi nihil cum Caelifini habent",
    "Visigothi nullum negotium hic habent"
  ],
  "staff of arcane authority": [
    "Rudis Auctoritatis Arcanae"
  ],
  "we might have something, yes": [
    "Aliquid fortasse habemus, sic"
  ],
  "sun's height": [
    "Iulius"
  ],
  "dragon seekers": [
    "Questores Draconum"
  ],
  "my apologies, erandur": [
    "Ignosce mihi, Erandur"
  ],
  "i spoke out of turn": [
    "Prave dixi"
  ],
  "marksmalehumanoid10leftgash": [
    "MarksMaleHumanoid10LeftGash"
  ],
  "whiterun stables scene 1": [
    "Whiterun Stables Scene 1"
  ],
  "dialog subtitles": [
    "Subtituli Loquendi"
  ],
  "whiterun stables scene 3": [
    "Whiterun Stables Scene 3"
  ],
  "whiterun marketplace scene 2": [
    "Whiterun Marketplace Scene 2"
  ],
  "whiterun marketplace scene 3": [
    "Whiterun Marketplace Scene 3"
  ],
  "whiterun marketplace scene 4": [
    "Whiterun Marketplace Scene 4"
  ],
  "whiterun stables scene 4": [
    "Whiterun Stables Scene 4"
  ],
  "whiterun anoriath elrindir scene 1": [
    "Whiterun Anoriath Elrindir Scene 1"
  ],
  "whiterun anoriath elrindir scene 2": [
    "Whiterun Anoriath Elrindir Scene 2"
  ],
  "so i'm free to leave": [
    "Ergo exire possun"
  ],
  "orcish war axe of freezing": [
    "Orci Bellisecuris Gelidus"
  ],
  "whiterun anoriath elrindir scene 3": [
    "Whiterun Anoriath Elrindir Scene 3"
  ],
  "emperor's birthday": [
    "Dies Natalis Imperatoris"
  ],
  "whiterun olfina jon scene 1": [
    "Whiterun Olfina Jon Scene 1"
  ],
  "whiterun olfina jon scene 2": [
    "Whiterun Olfina Jon Scene 2"
  ],
  "there are worse things than being fired": [
    "Res peiores quam munus ammitere exstant"
  ],
  "whiterun vignar brill scene 1": [
    "Whiterun Vignar Brill Scene 1"
  ],
  "hide armor of health": [
    "Renonis Lorica Salutis"
  ],
  "whiterun vignar brill scene 2": [
    "Whiterun Vignar Brill Scene 2"
  ],
  "whiterun vignar brill scene 3": [
    "Whiterun Vignar Brill Scene 3"
  ],
  "whiterun braith amren scene 1": [
    "Whiterun Braith Amren Scene 1"
  ],
  "ebony war axe of lethargy": [
    "Bellisecuris Ebeni Lethargiae"
  ],
  "mor khazgur": [
    "Mor Khazgur"
  ],
  "return to medresi": [
    "Redi ad Medresen"
  ],
  "perth": [
    "Perth"
  ],
  "find a way to open the gates": [
    "Inveni Viam ad Portas Aperiendum"
  ],
  "ebony warhammer of subsuming": [
    "Bellimalleus Ebeni Subsumendi"
  ],
  "hemming black-briar": [
    "Hemming Atri-Dumus"
  ],
  "talk to medresi in angarvunde": [
    "Medresen in Angarvunde Adloquere"
  ],
  "here's the sword from balimund": [
    "Hic gladium a Balimundo habes"
  ],
  "whiterun house battle-born scene 1": [
    "Whiterun House Battle-Born Scene 1"
  ],
  "whiterun house battle-born scene 2": [
    "Whiterun House Battle-Born Scene 2"
  ],
  "whiterun house gray-mane scene 1": [
    "Whiterun House Gray-Mane Scene 1"
  ],
  "whiterun house gray-mane scene 2": [
    "Whiterun House Gray-Mane Scene 2"
  ],
  "fills mq302 aliases": [
    "Fills MQ302 aliases"
  ],
  "defend <alias=fort> by killing the enemy ( <global=cwpercentpoolremainingattacker>% remaining)": [
    "Defende <Alias=Fort> et neca inimicos ( <Global=CWPercentPoolRemainingAttacker>% Manent)"
  ],
  "join the men attacking <alias=fort>": [
    "Adiunge homines qui <Alias=Fort> oppugnant"
  ],
  "key to robber's cove": [
    "Clavis ad Sinum Piratarum"
  ],
  "the battle for <alias=fort>": [
    "Proelium pro <Alias=Fort>"
  ],
  "battle for <alias=fort>": [
    "Proelium pro <Alias=Fort>"
  ],
  "um... the lute": [
    "Um... cithara"
  ],
  "no, drums": [
    "Minime, tympana"
  ],
  "you seem very sure of yourself": [
    "Certissimus in te videris"
  ],
  "do all thalmor have such high opinions of themselves": [
    "Putantne omnes Visigothi tantum de se"
  ],
  "i'll help you": [
    "Te adiuvabo"
  ],
  "imperial general": [
    "Imperialis Imperator"
  ],
  "ebony battleaxe of expelling": [
    "Bellisecuris Ebeni Expellendi"
  ],
  "pillar of sacrifice": [
    "Columna Sacrificii"
  ],
  "here, what can you tell me": [
    "Age, quid mihi dicere potes"
  ],
  "i think i'd rather be a regular soldier": [
    "Malo militem ordinarium esse"
  ],
  "what's the situation": [
    "Quid fit"
  ],
  "will you stay in windhelm now that ulfric's gone": [
    "Manebisne Ventigaleae nunc cum Vercingetorix moriatur"
  ],
  "what was the story": [
    "Quid erat fabula"
  ],
  "the secrets of ragnvald": [
    "Secreta Ragnvaldis"
  ],
  "secrets of ragnvald": [
    "Secreta Ragnvaldis"
  ],
  "kothyr's head": [
    "Kothyris Caput"
  ],
  "raerek's inscribed amulet of talos": [
    "Raerekis Monile Inscriptum Talos"
  ],
  "deliver the package to arniel": [
    "Redde Cistam Arnieli"
  ],
  "talk to enthir": [
    "Enthirem Adloquere"
  ],
  "tell verulus the hall of the dead is safe": [
    "Dic Verulum Aulam Mortuorum Tutam Esse"
  ],
  "investigate the hall of the dead": [
    "Investiga Aulam Mortuorum"
  ],
  "hammerfell garb": [
    "Mallecasus Vestimenta"
  ],
  "ice cloak": [
    "Ice Cloak"
  ],
  "imperial bow of frost": [
    "Imperialis Arcus Gelidus"
  ],
  "employ delvin mallory's services": [
    "Uti Muneribus Delvin Mallory"
  ],
  "what of ulfric himself": [
    "Et quid de Vercingetorige ipso"
  ],
  "silana petreia": [
    "Silana Petreia"
  ],
  "dialoguedawnstarwhitehallskaldbulfrek02": [
    "DialogueDawnstarWhiteHallSkaldBulfrek02"
  ],
  "where you hang your enemy's head": [
    "Ubi Capitum Inimici Suspendis"
  ],
  "chill touch": [
    "Tactus Frigidus"
  ],
  "riverwood alvor sigrid scene": [
    "Riverwood Alvor Sigrid scene"
  ],
  "glenmoril witch": [
    "Saga Glenmorilis"
  ],
  "convince braith to stop bullying lars": [
    "Persuade Braith ne Lars molestet"
  ],
  "riverwood hilde sigrid scene": [
    "Riverwood Hilde Sigrid scene"
  ],
  "healing": [
    "Healing"
  ],
  "allies": [
    "Allies"
  ],
  "war quarters": [
    "Belli Conclavia"
  ],
  "orcish invader": [
    "Orcus Invasor"
  ],
  "spell tome: fear": [
    "Liber Magicus: Timor"
  ],
  "grand plaza scene 22": [
    "Grand Plaza Scene 22"
  ],
  "witchblade": [
    "Witchblade"
  ],
  "spell absorption": [
    "Absorptio Magicae"
  ],
  "telekinesis effect": [
    "Effectum Telekinesis"
  ],
  "daedric greatsword of the blaze": [
    "Spatha Daedrica Congflagrantis"
  ],
  "aringoth": [
    "Aringoth"
  ],
  "daedric war axe of depleting": [
    "Bellisecuris Daedricus Deplendi"
  ],
  "creaturedialoguedwarvencenturion": [
    "CreatureDialogueDwarvenCenturio"
  ],
  "visit the shrine of azura": [
    "Visita Aram Azurae"
  ],
  "report back to commander maro": [
    "Refer te ad Legatum Maronem"
  ],
  "reverent glass warhammer": [
    "Reverens Bellimaleus Vitreus"
  ],
  "kill everyone in the sanctuary": [
    "Neca Omnes in Fano"
  ],
  "speak with commander maro": [
    "Adloquere Legatum Maronem"
  ],
  "steel plate helmet of alchemy": [
    "Laminata Galea Alchemiae"
  ],
  "captive shackle key": [
    "Clavis ad Vincula Captivi"
  ],
  "destroy the dark brotherhood": [
    "Dele Fraternitatem Obscuram"
  ],
  "expert censer": [
    "Expert Censer"
  ],
  "can i pass now": [
    "Possumne nunc transire"
  ],
  "buy the book off lod": [
    "Eme librum ex Lod"
  ],
  "do you need help": [
    "Visne auxilium"
  ],
  "lod's lot": [
    "Fatum Lodis"
  ],
  "orcish sword of binding": [
    "Orci Gladius Deligandi"
  ],
  "steel dagger of diminishing": [
    "Pugio Adamanteus Diminuendi"
  ],
  "give the argonian ale to brenuin": [
    "Da Hydromellum Lacertum ad Brenuin"
  ],
  "honningbrew boilery": [
    "Honningbrevis Furnax"
  ],
  "creaturedialoguedwarvenspider": [
    "CreatureDialogueDwarvenSpider"
  ],
  "mythic dawn tapestries": [
    "Aurorae Mythicae Tapetia"
  ],
  "i've struck a deal with ri'saad": [
    "Negotium cum Riaad egi"
  ],
  "visit the museum in dawnstar": [
    "Visita Museion Luciferi"
  ],
  "spare me from your speeches": [
    "Parce mihi a sermonibus tuis"
  ],
  "imperial light shield of resist shock": [
    "Romana Parma Contra Vim Electricam"
  ],
  "arngeir - is he one of the greybeards": [
    "Arngeir - estne unus ex Canabarbis"
  ],
  "about your mother": [
    "De matre tua"
  ],
  "give the frost salts to arcadia": [
    "Da Salia Glacialia ad Arcadiam"
  ],
  "riverwood hilde soldier scene": [
    "Riverwood Hilde Soldier scene"
  ],
  "riverwood embry soldier scene": [
    "Riverwood Embry Soldier scene"
  ],
  "riverwood soldier hilde scene": [
    "Riverwood Soldier Hilde scene"
  ],
  "yag gra-gortwog": [
    "Yag gra-Gortvog"
  ],
  "speak to j'zargo": [
    "J'zargonem Adloquere"
  ],
  "hairlinemalenord14": [
    "HairLineMaleNord14"
  ],
  "i want to stop at dragon bridge": [
    "Volo consistere apud Draconipontem"
  ],
  "elisif the fair": [
    "Elisif Candida"
  ],
  "calixto's house of curiosities": [
    "Calixto's House of Curiosities"
  ],
  "force general tullius to surrender": [
    "Coge Imperatori Tullio ut succumbat"
  ],
  "force ulfric stormcloak to surrender": [
    "Coge Vercingetorigem Temepstatamiculum ut succumbat"
  ],
  "open the last exterior gate": [
    "Aperi portam eternalem ultimam"
  ],
  "never mind, then": [
    "Ignosce"
  ],
  "open the first exterior gate": [
    "Aperi portam eternalem primam"
  ],
  "break through the enemy barricades": [
    "Frange Concaedem Inimicorum"
  ],
  "enter item name": [
    "Adde nomen rei"
  ],
  "defend the final barricade": [
    "Defende Concaedem Ultimam"
  ],
  "who is nocturnal": [
    "Quis est Nocturnal"
  ],
  "defend the first barricade": [
    "Defende Concaedem Primam"
  ],
  "thanks": [
    "Gratias",
    "Gratias tibi ago"
  ],
  "i'll let you know if i need anything": [
    "Tibi dicam si quoquo caruero"
  ],
  "tales and tallows": [
    "Dies Alliae"
  ],
  "no, i'm not": [
    "Minime, nolo"
  ],
  "force jarl <alias=jarl> to surrender": [
    "Coge Comiti <Alias=Jarl> ut succumbat"
  ],
  "port stone": [
    "Ianuam Lapideam"
  ],
  "death won't trigger wikill04": [
    "Death won't trigger WIKill04"
  ],
  "ebony helmet of major archery": [
    "Ebeni Galea Maioris Sagittationis"
  ],
  "incident at necrom": [
    "Incidentia apud Necrom"
  ],
  "follow <alias=general>": [
    "Sequere <Alias=General>"
  ],
  "leather helmet of minor illusion": [
    "Cutis Galea Minoris Inlusionis"
  ],
  "get your orders from <alias=general>": [
    "Accipe mandata ab <Alias=General>"
  ],
  "just passing through": [
    "Modo transeo"
  ],
  "listen to the night mother": [
    "Ausculta Matrem Noctis"
  ],
  "orcs fighting forsworn": [
    "Orcs Fighting Abiuratus"
  ],
  "staff of courage": [
    "Rudis Audacitatis"
  ],
  "dwarven warhammer of siphoning": [
    "Dvemeris Bellimalleus Siphonis"
  ],
  "camilla sven": [
    "Camilla Sven"
  ],
  "camilla hod": [
    "Camilla Hod"
  ],
  "alvor hod": [
    "Alvor Hod"
  ],
  "dialoguegenericstandonfurniture": [
    "DialogueGenericStandOnFurniture"
  ],
  "discover svidi's whereabouts": [
    "Inveni Svidi"
  ],
  "ebony dagger of petrifying": [
    "Pugio Ebeni Lapidescendi"
  ],
  "your heavy armor prevents you from jumping": [
    "Your heavy armor prevents you from jumping"
  ],
  "giants fending off bandits": [
    "Giants fending off bandits"
  ],
  "dragon fly by": [
    "Dragon fly by"
  ],
  "shaircolor11": [
    "sHairColor11"
  ],
  "imperials with stormcloak prisoner": [
    "Imperiales cum Captivo Sturmmantelo"
  ],
  "broken steel greatsword handle": [
    "Fractus Spatha Adamantea Cherolaba"
  ],
  "i'll keep the sword": [
    "Gladium tenebo"
  ],
  "but find someone else to spread your religion": [
    "Sed inveni aliquem prater me qui reliogionem tuam spargat"
  ],
  "sarthis's satchel": [
    "Cibisis Sarthis"
  ],
  "orcish sword of thunderbolts": [
    "Orci Gladius Fulminum"
  ],
  "i have the golden claw": [
    "Unguem aurum tibi habeo"
  ],
  "vasha": [
    "Vasha"
  ],
  "sounds terrible": [
    "Mihi terribile videtur"
  ],
  "ebony battleaxe of animus": [
    "Bellisecuris Ebeni Animi"
  ],
  "generic dialogue for vigilants of stendarr": [
    "Generic Dialogue for Vigilants of Stendarr"
  ],
  "thieves guild radiant queue": [
    "Sodalitatis Furum Radiant Queue"
  ],
  "sabre cats hunting mammoths": [
    "Sabre cats hunting mammoths"
  ],
  "wolves hunting dear": [
    "Wolves hunting dear"
  ],
  "atronach mis-summoned": [
    "Atronachum mis-summoned"
  ],
  "daighre": [
    "Daighre"
  ],
  "bandits dressed as imperial soldiers": [
    "Bandits dressed as Imperialis Soldiers"
  ],
  "orcs hunting deer": [
    "Orcs hunting deer"
  ],
  "warlock fighting off bandits": [
    "Warlock fighting off bandits"
  ],
  "change item selection": [
    "Elige Aliam Rem"
  ],
  "iron battleaxe of draining": [
    "Bellisecuris Ferri Hauriendi"
  ],
  "treasure hunter objectives": [
    "Treasure Hunter Objectives"
  ],
  "with his own wife and child inside": [
    "Uxore et filio intus"
  ],
  "leather armor of health": [
    "Corii Lorica Salutis"
  ],
  "why are you down here": [
    "Cur hic es"
  ],
  "choose an item to create": [
    "Elige Rem Creandam"
  ],
  "virtuous daedric warhammer": [
    "Virtutis Bellimalleus Daedricus"
  ],
  "let me through, pig-face": [
    "Sine me transire, facies-porci"
  ],
  "daedric boots of grounding": [
    "Caligae Daedricae Minoris Electricitatis"
  ],
  "why are you here": [
    "Cur hic es"
  ],
  "(pay 50 gold)": [
    "(Solve 50 auri)"
  ],
  "i have orichalcum ore to sell": [
    "Orichalcum vendendum habeo"
  ],
  "attack vilkas": [
    "Oppugna Vilkas"
  ],
  "destruction ritual spell": [
    "Magica Ritualis Destructionis"
  ],
  "dwarven bracers of major alchemy": [
    "Dvemeres Chirothecae Maioris Alchemiae"
  ],
  "recover your equipment": [
    "Recipe Impedimenta Tua"
  ],
  "discover the significance of crimson nirnroot": [
    "Inveni significantiam Nirniradicis Rubrae"
  ],
  "expert tongs": [
    "Expert Tongs"
  ],
  "creaturedialoguewolf": [
    "CreatureDialogueWolf"
  ],
  "player wearing college robes": [
    "Player wearing College Robes"
  ],
  "wooden mask": [
    "Persona Lignea"
  ],
  "player is naked": [
    "Player is naked"
  ],
  "player is sneaking": [
    "Player is sneaking"
  ],
  "creaturedialoguespriggan": [
    "CreatureDialogueSpriggan"
  ],
  "dragonplate gauntlets of peerless wielding": [
    "Draconilaminatae Manicae Optimae Tenendi"
  ],
  "virtuous ebony war axe": [
    "Virtutis Bellisecuris Ebeni"
  ],
  "what do we know about the dawnstar sanctuary": [
    "Quid de Sanctuario Luciferis scimus"
  ],
  "player has dangerous on going magic effect on": [
    "Player has dangerous on going magic effect on"
  ],
  "sly of hand": [
    "Sly of Hand"
  ],
  "thieves guild status quest": [
    "Sodalitatis Furum Status Quest"
  ],
  "run to deceased and lock self in editor location": [
    "Run to deceased et lock self in editor location"
  ],
  "get advice from yngvar": [
    "Accipe Admonitum ab Yngvar"
  ],
  "the lost verse": [
    "Versus Perditus"
  ],
  "lost verse": [
    "Versus Perditus"
  ],
  "whistling mine dialogue": [
    "Whistling Mine Dialogue"
  ],
  "ebony battleaxe of despair": [
    "Bellisecuris Ebeni Desponsionis"
  ],
  "da11introscene": [
    "DA11IntroScene"
  ],
  "ms01introworldscene": [
    "MS01IntroWorldScene"
  ],
  "dialoguemarkarthsmelteromluaghathrasil03": [
    "DialogueMarkarthSmelterOmluagHathrasil03"
  ],
  "uthgerd the unbroken": [
    "Uthgerd Infracta"
  ],
  "vagrant": [
    "Vagrans"
  ],
  "bleakwind basin": [
    "Bleakwind Basin"
  ],
  "dialoguemarkarthdragonskeepimperialscene01": [
    "DialogueMarkarthDragonsKeepImperialisScene01"
  ],
  "iron gauntlets of archery": [
    "Ferri Manicae Saggitationis"
  ],
  "she's not yours. stop this nonsense. (persuade)": [
    "Illa tuus non est. E his nugis desiste. (Persuade)"
  ],
  "are you a companion": [
    "Esne Contubernalis"
  ],
  "dialoguemarkarthdragonskeepstormcloakscene01": [
    "DialogueMarkarthDragonsKeepSturmmantelicene01"
  ],
  "when casting spells, wait for the spell to be ready before releasing the control. the spell is cast when you release": [
    "Dum magica utaris, exspecta ad finem ante botonem exsolves"
  ],
  "bring gold to vex (<global=tgbangoldtotal>)": [
    "Fer aurum ad Vex (<Global=TGBanGoldTotal>)"
  ],
  "new banners": [
    "Nova Vexillia"
  ],
  "(1,000 gold)": [
    "(M auri)"
  ],
  "ebony battleaxe of dread": [
    "Bellisecuris Ebeni Timoris"
  ],
  "you admire them": [
    "Eos admiraris"
  ],
  "sets up guard aliases": [
    "Sets up guard aliases"
  ],
  "winterhold initial scene": [
    "Hiemiterrae Initial Scene"
  ],
  "thieves guild pre-quest handler": [
    "Sodalitatis Furum Pre-Quest Handler"
  ],
  "you seem to dislike visitors in riften": [
    "Alieni Hiati tibi displicere videntur"
  ],
  "magicka damage": [
    "Vulnus Magicae"
  ],
  "dragonscale helmet of peerless conjuring": [
    "Draconisquamata Galea Optimae Vocandi"
  ],
  "phantom visuals": [
    "Phantom Visuals"
  ],
  "bring the four master illusion texts to drevis neloren": [
    "Fer IV libros Dominorum Inlusionis ad Drevis Neloren"
  ],
  "illusion ritual spell": [
    "Magica Ritualis Inlusionis"
  ],
  "mg great hall scene 6": [
    "MG Great Hall Scene 6"
  ],
  "she did": [
    "Illa id fecit"
  ],
  "what are the details": [
    "Quae sunt minutia"
  ],
  "creaturedialogueatronachflame": [
    "CreatureDialogueAtronachumFlame"
  ],
  "you'd better have a good reason for dragging me here": [
    "Rationem bonam quod mihi huic traxisti habere debes"
  ],
  "hillgrund's tomb crypt key": [
    "Clavis ad Hypogaeum Sepulchri Hillgrundis"
  ],
  "yes, the blushing bride now serves sithis in the void": [
    "Sic, nupta virginalis nunc Arkayam in Tartaro servit"
  ],
  "riften scripted scene keep": [
    "Hiati Scripted Scene Keep"
  ],
  "bee and barb scene 14": [
    "Bee et Barb Scene 14"
  ],
  "iron dagger of chills": [
    "Pugio Ferri Frigidis"
  ],
  "ragged flagon scene 12": [
    "Ragged Flagon Scene 12"
  ],
  "tg00 arrest monitor": [
    "TG00 Arrest Monitor"
  ],
  "kai wet-pommel": [
    "Kai Capulus-Madidus"
  ],
  "secs": [
    "secundas"
  ],
  "elven greatsword": [
    "Spatha Alfa"
  ],
  "imperial sword of draining": [
    "Gladius Romanus Hauriendi"
  ],
  "astrid thinks a family member may be conspiring with cicero": [
    "Astrid censet Membrum Familiae cum Ciceroni coniurare"
  ],
  "lemkil's house key": [
    "Clavis ad Domum Lemkilis"
  ],
  "stone door": [
    "Ianuam Lapideam"
  ],
  "talk to the strange jester": [
    "Mirabilem Maccum Adloquere"
  ],
  "captain avidius": [
    "Nauclerus Avidius"
  ],
  "markarth guard's armor": [
    "Markartiae Custodis Lorica"
  ],
  "talk to aventus aretino": [
    "Aventum Aretinonem Adloquere"
  ],
  "nagrub": [
    "Nagrub"
  ],
  "imperial bracers of alchemy": [
    "Chirothecae Romanae Alchemiae"
  ],
  "there, i cast the spell": [
    "En, magicam feci"
  ],
  "%s signal lost": [
    "%s signal lost"
  ],
  "ebony warhammer of immobilizing": [
    "Bellimalleus Ebeni Immobilitatis"
  ],
  "liberate the rift": [
    "Libera Hiatum"
  ],
  "regain the rift": [
    "Recipe Hiatum"
  ],
  "xxx": [
    "xxx"
  ],
  "skull of corruption": [
    "Calva Corruptionis"
  ],
  "liberate winterhold hold": [
    "Libera Regionem Hiemiterrae"
  ],
  "regain winterhold hold": [
    "Recipe Regionem Hiemiterrae"
  ],
  "liberate the pale": [
    "Libera Pallidum"
  ],
  "i have need of a priest in my travels": [
    "Sacerdotem mecum habere volo dum peregrinor"
  ],
  "regain the pale": [
    "Recipe Pallidum"
  ],
  "the prophecy says that only the dragonborn can stop him": [
    "Oraculum dicit Draconigenam solum qui Alduinum vincere posse esse"
  ],
  "prophecy says that only the dragonborn can stop him": [
    "Oraculum dicit Draconigenam solum qui Alduinum vincere posse esse"
  ],
  "liberate falkreath hold": [
    "Libera Falkretiam"
  ],
  "lunar iron war axe": [
    "Lunaris Bellisecuris Ferri"
  ],
  "liberate whiterun hold": [
    "Libera Albacursum"
  ],
  "glass battleaxe of winnowing": [
    "Bellisecuris Vitreus Ventagii"
  ],
  "hide boots": [
    "Renonis Caligae"
  ],
  "imperial light helmet of magicka": [
    "Romana Cassis Magicae"
  ],
  "i'll get it for you": [
    "Id tibi referam"
  ],
  "liberate the reach": [
    "Libera Prehensum"
  ],
  "dwarven sword of flames": [
    "Gladius Dvemeris Flammarum"
  ],
  "marksfemaleargonianscar03": [
    "MarksFemaleArgonianScar03"
  ],
  "liberate haafingar": [
    "Libera Haafingar"
  ],
  "mistwatch north tower": [
    "Turrinexus Septentrionis Turris"
  ],
  "can you provide any more details on the contract": [
    "Mihi narrare potes minutia de sponsi"
  ],
  "<alias=questnamelocation>": [
    "<Alias=QuestNameLocation>"
  ],
  "--conditioned not to start-- puts guards and resources in aliases and watches for their death and sabotage": [
    "--CONDITIONED NOT TO START-- Puts guards et resources in aliases et watches for their death et sabotage"
  ],
  "middle ring": [
    "Anulum Medium"
  ],
  "creaturedialoguefrostbitespidergiant": [
    "CreatureDialogueAdustus NivibusSpiderGiant"
  ],
  "pelagius's storm thrall": [
    "Servus Tempestatis Pelagii"
  ],
  "elven greatsword of consuming": [
    "Spatha Alfa Consumendi"
  ],
  "that's amazing": [
    "Mirabile Dictu"
  ],
  "is this your note": [
    "Estne epistula tua"
  ],
  "what does this mean": [
    "Quid sibi vult"
  ],
  "return to erik": [
    "Redi ad Erik"
  ],
  "talk to mralki about erik": [
    "Mralkem de Erik Adloquere"
  ],
  "dragon attack": [
    "Dragon Attack"
  ],
  "dragon killed with npc spectators nearby": [
    "Dragon Killed with NPC spectators nearby"
  ],
  "did you fight in the great war": [
    "Pungavistine in Bello Magno"
  ],
  "magic from the sky": [
    "Magica e Caelo"
  ],
  "i want to try again": [
    "Volo iterum conari"
  ],
  "you must be devastated by ulfric stormcloak's death": [
    "Certe, peromtus es morte Vercingetorigis Temepstatamiculi"
  ],
  "quest all beggars have": [
    "Quest all Beggars Have"
  ],
  "hairlinefemaledremora02": [
    "HairLineFemaleDremora02"
  ],
  "steel warhammer of diminishing": [
    "Bellimalleus Adamanteus Diminuendi"
  ],
  "scavenger": [
    "Despoliator"
  ],
  "bound bow fx": [
    "Bound Arcus FX"
  ],
  "talk to danica about the gildergreen being destroyed": [
    "Danicam Adloquere de Morte Gildergreen"
  ],
  "imperial scout patrol": [
    "Imperialis Scout Patrol"
  ],
  "wreck of the brinehammer": [
    "Naufragium Salsumalleus"
  ],
  "stormcloak scout patrol": [
    "Sturmmantelorum Scout Patrol"
  ],
  "courier on the run": [
    "Courier on Run"
  ],
  "visit the college of winterhold": [
    "Visita Collegium Hiemiterrae"
  ],
  "college objective quest": [
    "College Objective Quest"
  ],
  "children cannot use that": [
    "Pueri illo uti non possunt"
  ],
  "dwarven mace of consuming": [
    "Dvemeris Maca Consumendi"
  ],
  "delivery to calcelmo": [
    "Redditum ad Calcelmonem"
  ],
  "retrieve lisbet's shipment from <alias=forsworncamp>": [
    "Recipe Mercem Lisbetis a <Alias=ForswornCamp>"
  ],
  "college application denied": [
    "College Application Denied"
  ],
  "deliver the <alias=potion> to <alias.shortname=steward>": [
    "Fer <Alias=Potion> ad <Alias.ShortName=Steward>"
  ],
  "bothela's discreet delivery": [
    "Merx Secreta Bothelae"
  ],
  "test click trigger": [
    "Test Click Trigger"
  ],
  "learn more about the thieves guild from delvin": [
    "Plus de Sodalitate Furum e Delvin disce"
  ],
  "shaircolor2": [
    "sHairColor2"
  ],
  "warning: any unsaved progress will be lost": [
    "Cave: Progressum intutum perditum erit"
  ],
  "dwarven mace of scorching": [
    "Dvemeris Maca Adurendi"
  ],
  "discovered": [
    "inventus"
  ],
  "meet the family": [
    "Familiam Congnosce"
  ],
  "defeat kvenel": [
    "Vince Kvenel"
  ],
  "open the elder's cairn": [
    "Aperi Monolithon Maiorum"
  ],
  "locate the ceremonial weapons": [
    "Inveni Telas Ceremoniales"
  ],
  "i'm looking for an old guy, hiding out somewhere in riften": [
    "Quaero senem, in aliquo loco Hiati latentem"
  ],
  "don't be foolish": [
    "Noli stultum esse"
  ],
  "silenced tongues": [
    "Linguae Taciti"
  ],
  "dialoguelefthandmineintroscene": [
    "DialogueLeftHandMineIntroScene"
  ],
  "deliver gharol's sword to lash": [
    "Fer gladium Gharolis ad Lash"
  ],
  "gharol's message": [
    "Nuntium Gharolis"
  ],
  "summon and subdue an unbound dremora": [
    "Voca et vince Dremora Sine Domine"
  ],
  "exquisite": [
    "Exquisitus"
  ],
  "daedric battleaxe of winter": [
    "Bellisecuris Daedricus Hiemis"
  ],
  "conjuration ritual spell": [
    "Magica Ritualis Artis Vocandi"
  ],
  "angi's camp": [
    "Angi Castra"
  ],
  "return the <alias=questitem> to <alias.shortname=questgiver>": [
    "Refer <Alias=QuestItem> ad <Alias.ShortName=QuestGiver>"
  ],
  "dialoguemarkarthgenericscene03": [
    "DialogueMarkarthGenericScene03"
  ],
  "dialoguedawnstarintrobrinahorikskaldscene": [
    "DialogueDawnstarIntroBrinaHorikSkaldScene"
  ],
  "i have malachite ore to sell": [
    "Minerale malachitae vendendum habeo"
  ],
  "honed ancient nord greatsword of frost": [
    "Melior Germanica Antiqua Spatha Gelidus"
  ],
  "broken iron war axe head": [
    "Fractus Bellisecuris Ferri Caput"
  ],
  "daedric bow of nullifying": [
    "Arcus Daedricus Nullificandi"
  ],
  "defeat the guardian troll": [
    "Vince Trellem Custodem"
  ],
  "elven sword of malediction": [
    "Alfus Gladius Maledictionis"
  ],
  "nightcaller temple key": [
    "Clavis ad Templum Noctivocatoris"
  ],
  "don't change the subject": [
    "Noli subiectum mutare"
  ],
  "cut me into the deal, and i'll forget about it": [
    "De mihi partem, et de eo obliviscar"
  ],
  "glenmoril witch head": [
    "Sagae Glenmorilis Caput"
  ],
  "defeat the guardian wolf": [
    "Vince Lupum Custodem"
  ],
  "defeat the guardian skeever": [
    "Vince Skeever Custodem"
  ],
  "it's delphine you should be angry with, not me": [
    "Iram contra Delphinam sentire debes, non ego"
  ],
  "hide bracers of major sure grip": [
    "Chirothecae Cutis Maioris Dexteritatis"
  ],
  "letter to golldir": [
    "Epistula ad Golldir"
  ],
  "feyfolken iii": [
    "Feyfolken III"
  ],
  "you said something about talos": [
    "Dixisti aliquid de Talos"
  ],
  "yngvild ghost": [
    "Phantasma Yngvildis"
  ],
  "firir": [
    "Firir"
  ],
  "skyforge steel sword": [
    "Fabricaeli Gladius Adamanteus"
  ],
  "kyne's sacred trials": [
    "Pericula Sacra Kyneis"
  ],
  "bring the forgemaster's fingers to <alias.shortname=chief>": [
    "Fer Digitos Ferrarii ad <Alias.ShortName=Chief>"
  ],
  "steel helmet of archery": [
    "Adamantea Galea Saggitationis"
  ],
  "i can't kill grisvar without a shiv": [
    "Sine astula Grisvarem necare non possum"
  ],
  "assist the people of falkreath (<global=friendscountfalkreath>/<global=friendstotalminorhold>)": [
    "Adiuva incolas Falkretiae (<Global=FriendsCountFalkreath>/<Global=FriendsTotalMinorHold>)"
  ],
  "assist the people of winterhold (<global=friendscountwinterhold>/<global=friendstotalminorhold>)": [
    "Adiuva incolas Hiemiterrae (<Global=FriendsCountWinterhold>/<Global=FriendsTotalMinorHold>)"
  ],
  "assist the people of hjaalmarch (<global=friendscounthjaalmarch>/<global=friendstotalminorhold>)": [
    "Adiuva incolas Hjaalmarcis (<Global=FriendsCountHjaalmarch>/<Global=FriendsTotalMinorHold>)"
  ],
  "assist the people of eastmarch (<global=friendscounteastmarch>/<global=friendstotalmajorhold>)": [
    "Adiuva incolas Eurimarcis (<Global=FriendsCountEastmarch>/<Global=FriendsTotalMajorHold>)"
  ],
  "reldith": [
    "Reldith"
  ],
  "let ulfric have his way now": [
    "Sine Vercingetorigi res suas nunc habere"
  ],
  "he'll have to give ground later": [
    "Negotium ei agendum erit postea",
    "Plus terrae ei solvendum erit postea"
  ],
  "assist the people of whiterun (<global=friendscountwhiterun>/<global=friendstotalmajorhold>)": [
    "Adiuva Cives Albacursus (<Global=FriendsCountWhiterun>/<Global=FriendsTotalMajorHold>)"
  ],
  "kill orchendor": [
    "Neca Orchendorem"
  ],
  "inhale the fumes": [
    "Inhala fumum"
  ],
  "iron sword of weariness": [
    "Gladius Ferri Maioris Defatigationis"
  ],
  "deliver the ingredients": [
    "Redde Ingredientia"
  ],
  "i saw helgi's ghost in the ruins of your house": [
    "Phantasmam Helgi in ruinis domus tuae vidi"
  ],
  "obtain a flawless ruby": [
    "Obtine Rubinum Perfectum"
  ],
  "sondas drenim": [
    "Sondas Drenim"
  ],
  "obtain some vampire dust": [
    "Obtine Pulvum Vampiri"
  ],
  "iron war axe of burning": [
    "Bellisecuris Ferri Cremandi"
  ],
  "quest completed": [
    "Munus Completum"
  ],
  "imperial bracers of major alteration": [
    "Chirothecae Romanae Maioris Mutationis"
  ],
  "whiterun anoriath brenuin scene 1": [
    "Whiterun Anoriath Brenuin Scene 1"
  ],
  "conjuration staff": [
    "Rudis Vocantis"
  ],
  "purchase a house in markarth": [
    "Eme Domum Markartiae"
  ],
  "thane of the reach": [
    "Decemvir Prehensus"
  ],
  "mikael": [
    "Mikael"
  ],
  "daedric greatsword of devouring": [
    "Spatha Daedrica Consumendi"
  ],
  "what can you tell me about dawnstar": [
    "Quid mihi dicere potes de Lucifere"
  ],
  "thane of haafingar": [
    "Decemvir Haafingaris"
  ],
  "purchase a house in whiterun": [
    "Eme Domum Albacursus"
  ],
  "purchase a house in windhelm": [
    "Eme Domum Ventigaleae"
  ],
  "philter of the healer": [
    "Philtrum Doctoris"
  ],
  "orcish boots of shock abatement": [
    "Orci Caligae Contra Vim Electricam"
  ],
  "daynas valen's journal": [
    "Ephemeris Daynas Valenis"
  ],
  "elven warhammer of blizzards": [
    "Alfus Bellimaleus Hiemis"
  ],
  "borgakh the steel heart": [
    "Borgakh Cor Adamenteum"
  ],
  "bring <alias=questitem> to <alias.shortname=questgiver>": [
    "Fer <Alias=QuestItem> ad <Alias.ShortName=QuestGiver>"
  ],
  "dungeon delving (jarl - hagravens)": [
    "Dungeon Delving (Jarl - Hagravens)"
  ],
  "i can deliver that for you": [
    "Possum hoc pro te fero"
  ],
  "iron shield of resist fire": [
    "Ferri Scutum Contra Vim Ignis"
  ],
  "gather wheat": [
    "Carpe Triticum"
  ],
  "your pathetic existence has come to an end, narfi": [
    "Vita tua ad finem venit, Narfi"
  ],
  "mine ore": [
    "Mine Ore"
  ],
  "talk to jaree-ra about possible employment": [
    "Jaree-Ram Adloquere de Munere Possibili"
  ],
  "fork": [
    "Bifurcum"
  ],
  "collect <alias=samplepelt>s (<global=cr03peltsgotten>/<global=cr03totalpeltsneeded>)": [
    "Collige <Alias=SamplePelt> (<Global=CR03PeltsGotten>/<Global=CR03TotalPeltsNeeded>)"
  ],
  "kibell": [
    "Kibell"
  ],
  "jorrvaskr living quarters": [
    "Jorrvaskr Vivendi Conclavia"
  ],
  "you still never told me your real name": [
    "Nondum mihi nomen verum tuum dixisti"
  ],
  "elven battleaxe of the blaze": [
    "Bellisecuris Alfus Congflagrantis"
  ],
  "elven bracers of major sure grip": [
    "Chirothecae Alfae Maioris Dexteritatis"
  ],
  "shuftharz": [
    "Shuftharz"
  ],
  "winterhold is safe for now": [
    "Hiemiterra tuta est...nunc ipse"
  ],
  "is there anything else you can tell me about isabelle rolaine": [
    "Estne quid aliud quod mihi de Isabelle Rolaine narrare potes"
  ],
  "dragonplate helmet of peerless conjuring": [
    "Draconilaminata Galea Optimae Vocandi"
  ],
  "whiterun irileth proventus scene 1": [
    "Whiterun Irileth Proventus Scene 1"
  ],
  "whiterun proventus balgruuf 1": [
    "Whiterun Proventus Balgruuf 1"
  ],
  "hairmaleorc26": [
    "HairMaleOrc26"
  ],
  "it's ancano. he's done something with the eye": [
    "Ancano est. Aliquid cum Oculo fecit"
  ],
  "embry orgnar": [
    "Embry Orgnar"
  ],
  "whiterun irileth balgruuf scene 1": [
    "Whiterun Irileth Balgruuf Scene 1"
  ],
  "find red eagle's sword": [
    "Inveni Gladium Aquilae Rubrae"
  ],
  "burning": [
    "Burning"
  ],
  "find someone who knows about the expedition": [
    "Inveni aliquem qui de expeditione scit"
  ],
  "re-activate nchuand-zel's automated defenses": [
    "Re-activa Defensa Automata Nchuand-Zelis"
  ],
  "so king olaf's verse is a lost part of the edda": [
    "Ergo versus Regis Olafis est pars perdita Eddae"
  ],
  "iron warhammer of frost": [
    "Bellimalleus Ferratus Gelidus"
  ],
  "enter the twilight sepulcher": [
    "Intra Sepulchrum Crepusculare"
  ],
  "recover stromm's journal": [
    "Inveni Ephemeridem Strommis"
  ],
  "orcish battleaxe of fire": [
    "Orci Bellisecuris Ignis"
  ],
  "solitude is a major shipping port and important imperial supply line": [
    "Solitudo est navale magni momenti Imperio"
  ],
  "actually, i'm here on dark brotherhood business": [
    "Hic sum pro Fraternitate Obscura"
  ],
  "fake dragonrend": [
    "Fake Dragonrend"
  ],
  "the lost expedition": [
    "Expeditio Perdita"
  ],
  "lost expedition": [
    "Expeditio Perdita"
  ],
  "elven bow of scorching": [
    "Arcus Alfus Adurendi"
  ],
  "can i make a request": [
    "Possum carmen eligere",
    "Possumne carmen eligere"
  ],
  "(25 gold)": [
    "(XXV auri)"
  ],
  "muffled movement": [
    "Clam Movere"
  ],
  "conjurer": [
    "Vocator"
  ],
  "a blade in the dark": [
    "Ensis in Obscuritate"
  ],
  "blade in the dark": [
    "Ensis in Obscuritate"
  ],
  "give tolfdir 250 gold to lift your suspension": [
    "Da CCL Auri Tolfdiri ut suspensum eradat"
  ],
  "speak with tolfdir": [
    "Adloquere Tolfdirem"
  ],
  "spell tome: bound bow": [
    "Liber Magicus: Arcus Vocatus"
  ],
  "nothing more": [
    "Nihil plus"
  ],
  "rejoining the college": [
    "Collegium Repetens"
  ],
  "tell <alias.shortname=questgiver> that <alias.shortname=target> is taken care of": [
    "Narra <Alias.ShortName=Questgiver> <Alias.ShortName=Target> mortuum esse"
  ],
  "talk to <alias.shortname=target> about <alias.shortname=questgiver>": [
    "Adloquere <Alias.ShortName=Target> de <Alias.ShortName=Questgiver>"
  ],
  "bring one <alias=questitem> to <alias.shortname=questgiver>": [
    "Fer unam <Alias=QuestItem> ad <Alias.ShortName=QuestGiver>"
  ],
  "rare gifts": [
    "Dona Rara"
  ],
  "irgnir": [
    "Irgnir"
  ],
  "markarth guard's helmet": [
    "Markartiae Custodis Galea"
  ],
  "how long has yamarz been cursed": [
    "Quamdiu Yamarz maledictus fuit"
  ],
  "aval atheron services": [
    "Aval Atheron Services"
  ],
  "dialoguemarkarthintroarnleifandsonsscene": [
    "DialogueMarkarthIntroArnleifandSonsScene"
  ],
  "i'm not going to hurt you": [
    "Tibi non nocebo"
  ],
  "ugor": [
    "Ugor"
  ],
  "find the stolen sword": [
    "Inveni Gladium Perditum"
  ],
  "(optional) learn more about the theft in frostmere crypt": [
    "(Optio) plus de furtum in Hypogaeo Glacimero"
  ],
  "gloves": [
    "Manubria"
  ],
  "bern": [
    "Bern"
  ],
  "ebony battleaxe of harvesting": [
    "Bellisecuris Ebeni Saturni"
  ],
  "deliver the dragonstone to farengar": [
    "Fer Draconilapidem Farengari"
  ],
  "hairfemalenord09": [
    "HairFemaleNord09"
  ],
  "fortify conjuration": [
    "Adde ad Artem Vocandi"
  ],
  "daedric war axe of fire": [
    "Bellisecuris Daedricus Ignis"
  ],
  "colovian brandy": [
    "Vinum Colovianum"
  ],
  "bandit ally faction": [
    "Bandit Ally Faction"
  ],
  "calcelmo": [
    "Calcelmo"
  ],
  "that's none of your business": [
    "Negotium non est tuum"
  ],
  "that's quite a story": [
    "Fabula mirabile dictu est"
  ],
  "i did what you asked": [
    "Feci id quod rogavisti"
  ],
  "join the imperial legion": [
    "Adiunge Legionem Romanam"
  ],
  "you were at dragonsreach when i gave it to him": [
    "Ad Draconicarcerem affuisti cum id ei dedi"
  ],
  "imperial introductions": [
    "Introductiones Imperiales"
  ],
  "bring the statue to degaine": [
    "Fer statuam ad Degaine"
  ],
  "spriggan vs lumberjacks": [
    "Spriggan vs Lumberjacks"
  ],
  "ebony shield of shock abatement": [
    "Ebeni Scutum Contra Vim Electricam"
  ],
  "do you have anything for me": [
    "Habesne quid mihi"
  ],
  "i've completed the numbers job": [
    "Munus \"Numerorum\" perfecti"
  ],
  "glass gauntlets of major archery": [
    "Vitreae Manicae Maioris Sagittationis"
  ],
  "i can handle anything you throw at me": [
    "Quiquid me demonstrabis administrare potero"
  ],
  "clear out fort hraggstad": [
    "Expurge Castellum Hraggstad"
  ],
  "you think she'll get over it": [
    "Putasne eam id superaturam esse"
  ],
  "added effects": [
    "Effecta Addita"
  ],
  "deliver axe to jarl ulfric": [
    "Refer Securem Comiti Vercingetorigi"
  ],
  "steel helmet of minor alteration": [
    "Adamantea Galea Minoris Mutationis"
  ],
  "\"white-gold concordat\"": [
    "\"Foedus Album-Aurum\""
  ],
  "you're a long way from home, then": [
    "Ergo, valde procul domo es"
  ],
  "gallus's journal": [
    "Ephemeris Galli"
  ],
  "find the dog outside falkreath": [
    "Inveni Canem Foris Falkretiae"
  ],
  "speak to lod": [
    "Lod Adloquere"
  ],
  "sounds good": [
    "Bene",
    "Bene mihi videtur"
  ],
  "retrieve your guild leader armor from tonilia": [
    "Accipe Loricam Domini Sodalitatis a Tonilia"
  ],
  "is that my job": [
    "Estne munus meum"
  ],
  "irileth": [
    "Irileth"
  ],
  "speak to brynjolf about being guild master": [
    "Brynjolf adloquere de munere tuo ut Dominus Sodalitatis"
  ],
  "glass helmet": [
    "Vitrea Galea"
  ],
  "become guild master for the thieves guild": [
    "Esto Dominus Sodalidatis Furum"
  ],
  "speak to brynjolf about becoming the guild master": [
    "Brynjolf adloquere de Domino Sodalitatis"
  ],
  "under new management": [
    "Sub Gubernatione Nova"
  ],
  "flaming thrall explosion": [
    "Flaming Thrall Explosion"
  ],
  "reanimate thrall": [
    "Revoca Hominem"
  ],
  "argonian account, book 2": [
    "Historia Argonia, Book 2"
  ],
  "balwen's ornamental ring": [
    "Anulus Balwenis Ornamentalis"
  ],
  "get a bottle of skooma for grisvar": [
    "Fer Lagoenam Skoomae Grisvari"
  ],
  "i'm not here to torture you": [
    "Hic non sum ut te torqueam"
  ],
  "imperial helmet of major destruction": [
    "Romana Galea Maioris Destructionis"
  ],
  "thanks for helping me out": [
    "Gratias pro auxilio tibi do"
  ],
  "ask about balgruuf's strange children": [
    "De Filiis Mirabilibus Balgruufis Roga"
  ],
  "small dwemer plate metal": [
    "Lanx Parvum Metalli Dwemeris"
  ],
  "speak to ysolda about sleeping tree sap": [
    "Adloquere Ysoldam de Olibano Arboris Dormientis"
  ],
  "elixir of resist fire": [
    "Commixtum Resistendi Ignem"
  ],
  "take the oath": [
    "Iura Ius Sturmmantelorum"
  ],
  "return to galmar": [
    "Reveni ad to Galmarem"
  ],
  "kill the ice wraith": [
    "Neca Imaginem Glaciei"
  ],
  "dragon bridge overlook": [
    "Draconis Pons Panorama"
  ],
  "do you like it here at the college": [
    "Tibine placet hic ad Collegium esse"
  ],
  "ask esbern about dragon lairs": [
    "Roga Esbernem de nidis draconum"
  ],
  "bring a follower to delphine": [
    "Fer Secutorem ad Delphinam"
  ],
  "potion of enhanced stamina": [
    "Potio Maioris Staminae"
  ],
  "what is skooma": [
    "Quid est skooma"
  ],
  "player has accepted mission hooks from this field co": [
    "Player has accepted mission hooks from this field co"
  ],
  "rebuilding the blades": [
    "Enses Reficiens"
  ],
  "return to esbern": [
    "Reveni ad Esbernem"
  ],
  "scaled bracers of major deft hands": [
    "Chirothecae Squamatae Maioris Dexteritatis"
  ],
  "kill the dragon in <alias=dragonlair>": [
    "Neca Draconem in <Alias=DragonLair>"
  ],
  "dragon hunting": [
    "Draconivenans"
  ],
  "conversation system for skyhaven": [
    "Conversation System for Skyhaven"
  ],
  "the legend of red eagle": [
    "Legendum Aquilae Rubrae"
  ],
  "legend of red eagle": [
    "Legendum Aquilae Rubrae"
  ],
  "dbdawnstardoorplayerresponse1": [
    "DBDawnstarIanuaPlayerResponse1"
  ],
  "who's behind all this": [
    "Quis est culpabilis horum omnium"
  ],
  "find the word of power in <alias=dungeon>": [
    "Inveni Verbum Potentiae in <Alias=Dungeon>"
  ],
  "the words of power": [
    "Verba Potentiae"
  ],
  "words of power": [
    "Verba Potentiae"
  ],
  "common soul gem": [
    "Gemmanima Communis"
  ],
  "orcish helmet of waterbreathing": [
    "Orci Galea Respirandi Sub Aqua"
  ],
  "da13 hook, afflicted refugee headed to high rock": [
    "DA13 Hook, Afflicted Refugee Headed to High Rock"
  ],
  "urchin": [
    "Orphanus"
  ],
  "where do you catch the fish": [
    "Ubi pisces capis"
  ],
  "no news is good news": [
    "Nullum Nuntium est Nuntium Bonum"
  ],
  "talk to rikke about joining the imperial legion": [
    "Rikke de Legione Imperiali adloquere"
  ],
  "sanctified orcish sword": [
    "Sanctificatus Gladius Orcorum"
  ],
  "recruitment drive": [
    "Dilectus Militum"
  ],
  "take the hidden treasure": [
    "Inveni Gazam Occultam"
  ],
  "orcish war axe of fear": [
    "Orci Bellisecuris Timoris"
  ],
  "i'm not": [
    "Nolo"
  ],
  "bring the <alias=questitem> to <alias.shortname=questgiver>": [
    "Fer <Alias=QuestItem> ad <Alias.ShortName=QuestGiver>"
  ],
  "do you have any idea how i might find out": [
    "Scisne quomodo discere possim"
  ],
  "cuffed boots": [
    "Laciniatae Caligae"
  ],
  "hollowed-out rock": [
    "Saxum Excavatum"
  ],
  "tell <alias.shortname=questgiver> <alias=questitem> has been planted": [
    "Dic <Alias.ShortName=Questgiver> <Alias=QuestItem> Clam Positum Esse"
  ],
  "plant <alias=questitem> in <alias=targethouse>": [
    "Clam Pone <Alias=QuestItem> in <Alias=TargetHouse>"
  ],
  "deliver <alias=questitem> to <alias.shortname=target>": [
    "Redde <Alias=QuestItem> ad <Alias.ShortName=Target>"
  ],
  "rescue thorald from northwatch keep": [
    "Libera Thoraldem e Castello Septentriovisu"
  ],
  "that was the last of the ingredients": [
    "Ultimum ingredientium erat"
  ],
  "obtain a mark of dibella from hofgrir": [
    "Accipe Signum Dibellae a Hofgrir"
  ],
  "npcs immune to mehrunes razor": [
    "NPCs Immune to Mehrunes Razor"
  ],
  "glass armor of the noble knight": [
    "Vitrea Lorica Nobilis Equitis"
  ],
  "prepared for what": [
    "Paratus cui"
  ],
  "maleeyeshumanbrightgreen": [
    "MaleEyesHumanBrightGreen"
  ],
  "give wujeeta a healing potion": [
    "Da Potionem Sanandi Wujeetae"
  ],
  "helping hand": [
    "Manus Auxiliaris"
  ],
  "return to alessandra": [
    "Reveni ad Alessandram"
  ],
  "bring alessandra's dagger to andurs in whiterun": [
    "Adfer Pugionem Alessandrae ad Andurs Albacursus"
  ],
  "return to wylandriah": [
    "Reveni ad Wylandriam"
  ],
  "retrieve wylandriah's soul gem": [
    "Inveni Gemmanimam Wylandriae"
  ],
  "retrieve wylandriah's ingot": [
    "Inveni Metallum Wylandriae"
  ],
  "daedric gauntlets of eminent alchemy": [
    "Daedricae Manicae Alchemiae Eminentis"
  ],
  "hunt and gather": [
    "Venare et Collige"
  ],
  "db01miscguardplayerciceroframed1": [
    "DB01MiscGuardPlayerCiceroFramed1"
  ],
  "bring the <alias=questitem> to olda": [
    "Fer <Alias=QuestItem> Oldae"
  ],
  "who killed your family": [
    "Quis Familiam tuam necavit"
  ],
  "steal the <alias=questitem> for olda": [
    "Furare <Alias=QuestItem> Oldae"
  ],
  "deliver harrald's sword": [
    "Refer Gladium Harraldis"
  ],
  "yorgrim overlook": [
    "Yorgrimis Panorama"
  ],
  "ebony battleaxe of blizzards": [
    "Bellisecuris Ebeni Hiemis"
  ],
  "dialoguemarkarthblacksmithscene01": [
    "DialogueMarkarthBlacksmithScene01"
  ],
  "human heart": [
    "Cor Humane"
  ],
  "steel armor of major health": [
    "Adamantea Lorica Maioris Salutis"
  ],
  "dialoguemarkarthstablesbanningcedranscene02": [
    "DialogueMarkarthStablesBanningCedranScene02"
  ],
  "the real barenziah, v2": [
    "Barenzia Vera, II"
  ],
  "real barenziah, v2": [
    "Barenzia Vera, II"
  ],
  "return ore sample to hafjorg": [
    "Redde Exemplar Mineralis ad Hafjorg"
  ],
  "obtain ore sample from filnjar": [
    "Accipe Exemplar Mineralis a Filnjar"
  ],
  "karinda": [
    "Karinda"
  ],
  "truth ore consequences": [
    "Verum Vel(Minerale) Consequentiae"
  ],
  "deliver the sword to proventus avenicci": [
    "Fer Gladium ad Proventum Avenicci"
  ],
  "find gold ore for madesi (<global=ffr18orecount>/<global=ffr18oretotal>)": [
    "Inveni Minerale Auri pro Madesi (<Global=FFR18OreCount>/<Global=FFR18OreTotal>)"
  ],
  "find a mammoth tusk for madesi (<global=ffr18tuskcount>/<global=ffr18tusktotal>)": [
    "Inveni Dentem Elephantis pro Madesi (<Global=FFR18TuskCount>/<Global=FFR18TuskTotal>)"
  ],
  "ringmaker": [
    "Anulifactor"
  ],
  "bee and barb scene 16": [
    "Bee et Barb Scene 16"
  ],
  "brow forward": [
    "Frons ad Frontem"
  ],
  "the mortar and pestle": [
    "The Mortar and Pestle"
  ],
  "mortar and pestle": [
    "The Mortar and Pestle"
  ],
  "bee and barb scene 19": [
    "Bee et Barb Scene 19"
  ],
  "grand plaza scene 11": [
    "Grand Plaza Scene 11"
  ],
  "commander's note": [
    "Epistula Legati"
  ],
  "grand plaza scene 12": [
    "Grand Plaza Scene 12"
  ],
  "orcish dagger": [
    "Orci Pugio"
  ],
  "grand plaza scene 13": [
    "Grand Plaza Scene 13"
  ],
  "db05playerwhoistargettopic1": [
    "DB05PlayerWhoIsTargetTopic1"
  ],
  "grand plaza scene 15": [
    "Grand Plaza Scene 15"
  ],
  "ghost adventurer": [
    "Phantasma Advenator"
  ],
  "grand plaza scene 16": [
    "Grand Plaza Scene 16"
  ],
  "grand plaza scene 17": [
    "Grand Plaza Scene 17"
  ],
  "grand plaza scene 18": [
    "Grand Plaza Scene 18"
  ],
  "grand plaza scene 19": [
    "Grand Plaza Scene 19"
  ],
  "grand plaza scene 20": [
    "Grand Plaza Scene 20"
  ],
  "philter of lockpicking": [
    "Philtrum Clavicarii"
  ],
  "grand plaza scene 23": [
    "Grand Plaza Scene 23"
  ],
  "spell tome: conjure flame atronach": [
    "Liber Magicus: Voca Atronachum Flammarum"
  ],
  "bring the last scabbard of akrash to ghorza": [
    "Fer Ultimam Vaginam Akrashis ad Ghorzam"
  ],
  "skilled apprenticeship": [
    "Novitas Peritus"
  ],
  "grand plaza scene 25": [
    "Grand Plaza Scene 25"
  ],
  "boiled creme treat": [
    "Confectum Crami Cocti"
  ],
  "grand plaza scene 26": [
    "Grand Plaza Scene 26"
  ],
  "grand plaza scene 27": [
    "Grand Plaza Scene 27"
  ],
  "you don't have anything that can help": [
    "Nonne quid habes quod adiuvare potest"
  ],
  "master tongs": [
    "Master Tongs"
  ],
  "grand plaza scene 29": [
    "Grand Plaza Scene 29"
  ],
  "have you always lived in skyrim": [
    "Semperne in Caelifine vixisti"
  ],
  "grand plaza scene 32": [
    "Grand Plaza Scene 32"
  ],
  "grand plaza scene 33": [
    "Grand Plaza Scene 33"
  ],
  "dwemer torture device": [
    "Mechanismum Dvemere Torquendi"
  ],
  "pickpocket": [
    "Furari ab hominibus"
  ],
  "grand plaza scene 35": [
    "Grand Plaza Scene 35"
  ],
  "lexicon receptacle": [
    "Lexicon Receptaculum"
  ],
  "dwarven sword of depleting": [
    "Gladius Dvemeris Deplendi"
  ],
  "grand plaza scene 37": [
    "Grand Plaza Scene 37"
  ],
  "grand plaza scene 38": [
    "Grand Plaza Scene 38"
  ],
  "elven gauntlets": [
    "Alfae Manicae"
  ],
  "grand plaza scene 39": [
    "Grand Plaza Scene 39"
  ],
  "grand plaza scene 43": [
    "Grand Plaza Scene 43"
  ],
  "grand plaza scene 40": [
    "Grand Plaza Scene 40"
  ],
  "grand plaza scene 41": [
    "Grand Plaza Scene 41"
  ],
  "crystal": [
    "Crystallum"
  ],
  "who is styrr": [
    "Quis est Styrr"
  ],
  "ceremonial axe": [
    "Bellisecuris Ceremonialis"
  ],
  "grand plaza scene 42": [
    "Grand Plaza Scene 42"
  ],
  "i have the <alias=questitem>": [
    "Habeo <Alias=QuestItem>"
  ],
  "gar-shutan": [
    "Gar-shutan"
  ],
  "search and seizure": [
    "Inquisitio et Captura"
  ],
  "bring a daedra's heart to moth": [
    "For Cor Daedrae Mothi"
  ],
  "hallowed orcish greatsword": [
    "Consacrata Spatha Orcorum"
  ],
  "payment delivery": [
    "Refer Debita"
  ],
  "response to bero's speech": [
    "Responsum ad Sermonem Beronis"
  ],
  "haelga's bunkhouse scene 10": [
    "Haelga's Bunkhouse Scene 10"
  ],
  "haelga's bunkhouse scene 11": [
    "Haelga's Bunkhouse Scene 11"
  ],
  "haelga's bunkhouse scene 12": [
    "Haelga's Bunkhouse Scene 12"
  ],
  "riften maul dialogue": [
    "Hiati Maul Dialogue"
  ],
  "the black-briars of riften are one of the most influential and well-connected clans in skyrim": [
    "Atri-Dumi Hiati sunt e notissimis et consocissimis gentibus in Caelifine"
  ],
  "black-briars of riften are one of the most influential and well-connected clans in skyrim": [
    "Atri-Dumi Hiati sunt e notissimis et consocissimis gentibus in Caelifine"
  ],
  "i've given away all the missives": [
    "Omnes epistulas tradidi"
  ],
  "tg00 maul handler": [
    "TG00 Maul Handler"
  ],
  "return to the jarl of riften": [
    "Redi ad Comitem Hiati"
  ],
  "purchase honeyside from riften's steward": [
    "Eme Melilatum a Dispensatore Hiati"
  ],
  "speak to the jarl of riften": [
    "Comitem Hiati Adloquere"
  ],
  "defeat hofgrir in an unarmed brawl": [
    "Hofgriren vince in pugna manuali"
  ],
  "bloody nose": [
    "Nasus Sanguinosus"
  ],
  "hairfemalenord10": [
    "HairFemaleNord10"
  ],
  "and gallus": [
    "Et Gallus"
  ],
  "ebony mace": [
    "Maca Ebeni"
  ],
  "vorstag": [
    "Vorstag"
  ],
  "kill the forsworn leader (jarl)": [
    "Neca Ducem Abiuratorum (Jarl)"
  ],
  "smuggler's journal": [
    "Ephemeris Subintroductoris"
  ],
  "who is orchendor": [
    "Quis est Orchendor"
  ],
  "arondil's journal, part 2": [
    "Ephemeris Arondilis, Pars II"
  ],
  "unmelting snow": [
    "Noctis Insolubilis"
  ],
  "orcish bow of weariness": [
    "Orci Arcus Maioris Defatigationis"
  ],
  "no stone unturned misc handler": [
    "No Stone Unturned Misc Handler"
  ],
  "kill the bandit leader": [
    "Neca Ducem Latronum"
  ],
  "daedric dagger": [
    "Pugio Daedricus"
  ],
  "you're not going to kill her, are you": [
    "Certe eam non necabis"
  ],
  "iron sword of diminishing": [
    "Gladius Ferri Diminuendi"
  ],
  "the dark brotherhood has come, grelod": [
    "Fraternitas Obscura venit, Grelod"
  ],
  "dark brotherhood has come, grelod": [
    "Fraternitas Obscura venit, Grelod"
  ],
  "return the horn to arngeir": [
    "Redde Cornu Arngeiri"
  ],
  "daedric helmet of eminent magicka": [
    "Galea Daedrica Eminentis Magicae"
  ],
  "do you sell spells": [
    "Vendisne magicam"
  ],
  "retrieve the horn of jurgen windcaller": [
    "Refer Cornu Jurgenis Ventivocatoris"
  ],
  "tsun's battle axe": [
    "Bellisecuris Tsunis"
  ],
  "leaders rise and fall": [
    "Duces surgunt et cadunt"
  ],
  "business is business": [
    "Negotium est negotium"
  ],
  "sanctified iron warhammer": [
    "Sanctificatus Bellimalleus Ferratus"
  ],
  "the horn of jurgen windcaller": [
    "Cornu Jurgenis Ventivocatoris"
  ],
  "horn of jurgen windcaller": [
    "Cornu Jurgenis Ventivocatoris"
  ],
  "you cannot wait while trespassing": [
    "Expectare non potes dum in loco es contra legem"
  ],
  "karthwasten hall": [
    "Karthvasten Atrium"
  ],
  "take over <alias=fort> by defeating the enemy (<global=cwpercentpoolremainingdefender>% remaining)": [
    "Cape <Alias=Fort> et supera inimicos (<Global=CWPercentPoolRemainingDefender>% Remaining)"
  ],
  "a dance in fire, v1": [
    "Saltatio in Flammis, I"
  ],
  "dance in fire, v1": [
    "Saltatio in Flammis, I"
  ],
  "who's crazy": [
    "Quis est insanus"
  ],
  "i've heard about these murders": [
    "De his necibus audivi"
  ],
  "requirements": [
    "Necessaria"
  ],
  "how do i get a black soul gem": [
    "Quomodo gemmanimam atram inveniam"
  ],
  "db recurringtarget 1 crimefaction": [
    "DB RecurringTarget 1 CrimeFaction"
  ],
  "eliminate sarthis idren": [
    "Neca Sarthis Idren"
  ],
  "which makes maven lose faith in us": [
    "Ergo Maven fidem in nobis ammitet"
  ],
  "report sarthis idren to the jarl of riften": [
    "Refer Sarthis Idren ad Comitem Hiati"
  ],
  "the raid": [
    "Decursio"
  ],
  "raid": [
    "Decursio"
  ],
  "holy daedric battleaxe": [
    "Sanctus Bellisecuris Daedricus"
  ],
  "kill the giant in <alias=giantcamp>": [
    "Neca Gigantem in <Alias=GiantCamp>"
  ],
  "kill the giant (jarl)": [
    "Gigantem Neca (Jarl)"
  ],
  "thief lock picking": [
    "Thief lock picking"
  ],
  "take sapling": [
    "Cape Arboriculum"
  ],
  "iron warhammer of fear": [
    "Bellimalleus Ferratus Timoris"
  ],
  "put's in faction meaning i've previously been assault": [
    "Put's in faction meaning I've previously been assault"
  ],
  "hello flavored on previous assault": [
    "Hello flavored on previous assault"
  ],
  "tell malborn the assassin is dead": [
    "Dic Malborn Sicarium Mortuum Esse"
  ],
  "find the thalmor assassin": [
    "Inveni Sicarium Visigothi"
  ],
  "kill nimhe inside nchuand-zel": [
    "Neca Nimhen in Nchuand-Zel"
  ],
  "nimhe, the poisoned one": [
    "Nimhe, Venenata"
  ],
  "stables": [
    "Stables"
  ],
  "dravynea's frost salts": [
    "Salia Glaciei Dravyneae"
  ],
  "scroll of bane of the undead": [
    "Codex Exitii Amortuorum"
  ],
  "key to the silver-blood inn": [
    "Clavis ad Diversorium Argenti-Sanguinis"
  ],
  "tell <alias.shortname=questgiver> the vampire is dead": [
    "Dic <Alias.ShortName=Questgiver> Vampirum Mortuum Esse"
  ],
  "you said something about a tour": [
    "Dixisti aliquid de exploratione"
  ],
  "dremora churl": [
    "Dremora Miser"
  ],
  "what do i do once i'm inside": [
    "Quid faciam post intraverim"
  ],
  "kill the vampire": [
    "Vampirum Neca"
  ],
  "sons of skyrim government": [
    "Sons of Skyrim Government"
  ],
  "find the <alias=questitem> for <alias.shortname=questgiver>": [
    "Inveni <Alias=QuestItem> pro <Alias.ShortName=QuestGiver>"
  ],
  "salty sea-dogs": [
    "Salsi Canes Maris"
  ],
  "harsh master": [
    "Dominus Durus"
  ],
  "riften ratway dialogue": [
    "Hiati Via Murum Dialogue"
  ],
  "thieves guild dungeon reserve tracker": [
    "Sodalitatis Furum Dungeon Reserve Tracker"
  ],
  "enchanting crafting": [
    "Enchanting crafting"
  ],
  "do not clean up body / move items to crypt, even if script says to do so": [
    "Do not clean up body / move items to crypt, even if script says to do so"
  ],
  "buy dwarven artifact": [
    "Eme opus Dvemeris"
  ],
  "player rents room, innkeeper shows him where": [
    "Player rents room, innkeeper shows him where"
  ],
  "non-hostile spell reaction": [
    "Non-hostile spell reaction"
  ],
  "maleeyesdarkelfblind": [
    "MaleEyesDarkElfBlind"
  ],
  "sanctified iron mace": [
    "Sanctificata Maca Ferri"
  ],
  "light armor forging": [
    "Loricam Levem Faciens"
  ],
  "brunwulf free-winter": [
    "Brunvulf Hiemi-Libertus"
  ],
  "orcish dagger of soul snares": [
    "Orci Pugio Animicapti"
  ],
  "player is diseased": [
    "Player is diseased"
  ],
  "morthal falion's house scene 4": [
    "Morthal Falionis House Scene 4"
  ],
  "morthal highmoon hall scene 1": [
    "Morthal Highmoon Hall Scene 1"
  ],
  "morthal highmoon hall scene 2": [
    "Morthal Highmoon Hall Scene 2"
  ],
  "blood thirst": [
    "Sitis Sanguinis"
  ],
  "morthal highmoon hall scene 3": [
    "Morthal Highmoon Hall Scene 3"
  ],
  "maleeyeshumanrighthazlebrownright": [
    "MaleEyesHumanRightHazleBrownRight"
  ],
  "morthal highmoon hall scene 4": [
    "Morthal Highmoon Hall Scene 4"
  ],
  "morthal highmoon hall scene 5": [
    "Morthal Highmoon Hall Scene 5"
  ],
  "daedric battleaxe of petrifying": [
    "Bellisecuris Daedricus Lapidescendi"
  ],
  "morthal highmoon hall scene 6": [
    "Morthal Highmoon Hall Scene 6"
  ],
  "morthal moorside jonna aslfur": [
    "Morthal Moorside Jonna Aslfur"
  ],
  "labyrinthian chasm": [
    "Hiatus Labyrinthi"
  ],
  "i got the fragment": [
    "Fragmentum inveni"
  ],
  "elemental protection": [
    "Praesidium Elementale"
  ],
  "mistwatch escapee": [
    "Mistwatch Escapee"
  ],
  "eyeliner color": [
    "Color Lineatoris Oculorum"
  ],
  "iron ore vein": [
    "Venam Mineralis Ferri"
  ],
  "you're riften's protector": [
    "Esne defensor Hiati"
  ],
  "vigilants fighting vampire": [
    "Vigilants fighting Vampire"
  ],
  "ebony battleaxe": [
    "Bellisecuris Ebeni"
  ],
  "conjure potent frost atronach": [
    "Voca Atronachum Potens Glaciei"
  ],
  "fortify sneak and destruction": [
    "Adde ad Artem Rependi et Destructionem"
  ],
  "what is the \"way of the voice\"": [
    "Quid est \"Via Vocis\""
  ],
  "traveling merchant attacked by bandits": [
    "Traveling Merchant Attacked by Bandits"
  ],
  "long bow": [
    "Arcus Longus"
  ],
  "retrieve the <alias=itemtoget> from <alias=wealthyhome> in <alias=city>": [
    "Refer <Alias=ItemToGet> e <Alias=WealthyHome> in <Alias=City>"
  ],
  "thalmor with prisoner": [
    "Visigothi cum Captivo"
  ],
  "dragonplate helmet of peerless destruction": [
    "Draconilaminata Galea Optimae Destructionis"
  ],
  "lots of gold to be had plundering ships": [
    "Multum auri in naves preadando est"
  ],
  "bard at rest": [
    "Bard at rest"
  ],
  "glass sword of torpor": [
    "Gladius Vitreus Torporis"
  ],
  "thieve's guild holdup": [
    "Thieve's Guild Holdup"
  ],
  "iron battleaxe of sparks": [
    "Bellisecuris Ferri Scintillarum"
  ],
  "iron boots of dwindling flames": [
    "Ferri Caligae Flammarum Diminuentium"
  ],
  "dark ancestor": [
    "Progenitor Obscurus"
  ],
  "alik'r accusation": [
    "Alik'ris Accusation"
  ],
  "da01-dark elf on pilgrimage to azura's shrine": [
    "DA01-Dark Elf on Pilgrimage to Azurae Shrine"
  ],
  "telekinesis ability effect": [
    "Telekinesis Ars Effect"
  ],
  "da11-namira cultist offers the first bite": [
    "DA11-Namira Cultist Offers First Bite"
  ],
  "i need to know what happened to thorald": [
    "Volo scire quid Thoraldi acciderit"
  ],
  "lost after the wedding": [
    "Lost after Wedding"
  ],
  "what's the purpose of the college": [
    "Quid est finis Collegii"
  ],
  "dwemer junk peddlers": [
    "Dwemer Junk Peddlers"
  ],
  "loose gargoyle": [
    "Gargolion"
  ],
  "orchendor": [
    "Orchendor"
  ],
  "little pelagius": [
    "Parvulus Pelagius"
  ],
  "orcish war axe": [
    "Orci Bellisecuris"
  ],
  "dialoguedushnikhyalhuntingscene02": [
    "DialogueDushnikhYalHuntingScene02"
  ],
  "dialogue for m'aiq the liar": [
    "Dialogue for M'aiq Liar"
  ],
  "da01- necromancers attack player": [
    "DA01- Necromancers Attack Player"
  ],
  "the drunken dare": [
    "The drunken dare"
  ],
  "drunken dare": [
    "The drunken dare"
  ],
  "t03- pilgrim visiting the tree in whiterun": [
    "T03- Pilgrim visiting tree in Whiterun"
  ],
  "mq201 party scene 1": [
    "MQ201 Party Scene 1"
  ],
  "mq201 party scene 2": [
    "MQ201 Party Scene 2"
  ],
  "drinking companions": [
    "Conviviales"
  ],
  "steal the statue in the temple of dibella": [
    "Furare statuam a Templo Dibellae"
  ],
  "bitten by a vampire": [
    "Bitten by vampire"
  ],
  "looking to join stormcloaks": [
    "Looking to join Sturmmanteli"
  ],
  "meditations on the words of power": [
    "Meditations on Words of Power"
  ],
  "staff of turning": [
    "Rudis Vertendi"
  ],
  "da13- afflicted refugees attack the player": [
    "DA13- Afflicted Refugees Attack Player"
  ],
  "blind cliff towers": [
    "Turres Rupis Caeci"
  ],
  "tell me what you know, then": [
    "Dic mihi id quod scis"
  ],
  "you won't let onmund out of his trade, but you want out of yours": [
    "Onmund e pactione eius exire non sinas, sed e tuo exire vis"
  ],
  "brelyna's practice": [
    "Practicum Brelynae"
  ],
  "bring the quill of gemination to maven": [
    "Fer Pennam Geminationis ad Maven"
  ],
  "locate the quill of gemination under lake honrich": [
    "Inveni Pennam Geminationis sub Lacu Honrice"
  ],
  "it wasn't me": [
    "Non fuit ego"
  ],
  "i've been framed": [
    "Crimen non feci"
  ],
  "stormcloaks": [
    "Stormcloaks"
  ],
  "vald's debt": [
    "Debitum Valdis"
  ],
  "dwarven dagger of thunderbolts": [
    "Dvemeris Pugio Fulminum"
  ],
  "family heirloom": [
    "Haereditas Familiae"
  ],
  "riften scripted scene bridge": [
    "Hiati Scripted Scene Bridge"
  ],
  "vampire attack": [
    "Vampire Attack"
  ],
  "leather boots": [
    "Corii Caligae"
  ],
  "companions hunting (vilkas and ria)": [
    "Contubernales Hunting (Vilkas et Ria)"
  ],
  "elven bow of debilitation": [
    "Arcus Alfus Debilitationis"
  ],
  "close the rupture": [
    "Claude Hiatum Magicum"
  ],
  "hairmaleorc11": [
    "HairMaleOrc11"
  ],
  "dwarven sword of debilitation": [
    "Gladius Dvemeris Debilitationis"
  ],
  "faendal": [
    "Faendal"
  ],
  "get outfitted": [
    "Arma Cape"
  ],
  "thieves guild skeleton key handler": [
    "Sodalitatis Furum Skeleton Key Handler"
  ],
  "steel battleaxe of chills": [
    "Bellisecuris Adamanteus Frigidis"
  ],
  "da03vilegreetingbranchtopic": [
    "DA03VileGreetingBranchTopic"
  ],
  "where is this secret door": [
    "Ubi est haec ianua secreta"
  ],
  "tell <alias.shortname=questgiver> what <alias.shortname=target> said about the outfit": [
    "Narra <Alias.ShortName=Questgiver>e quid <Alias.ShortName=Target> de Vestimentis Dicat"
  ],
  "fit for a jarl": [
    "Aptus Comiti"
  ],
  "return <alias.shortname=item> to <alias.shortname=target>": [
    "Redde <Alias.ShortName=Item> ad <Alias.ShortName=Target>"
  ],
  "letter from jon": [
    "Epistula a Johanni"
  ],
  "return to grace": [
    "Redditum ad Gratiam"
  ],
  "winking skeever inn": [
    "Winking Skeever Inn"
  ],
  "so you won't help me": [
    "Ergo mihi auxilium non dabis"
  ],
  "here's a dragon scale and a bone": [
    "Hic habes squamam et os draconis"
  ],
  "sewer grate": [
    "Porta Cloacae"
  ],
  "tsavani": [
    "Tsavani"
  ],
  "belethor": [
    "Belethor"
  ],
  "pursueidletopic": [
    "PursueIdleTopic"
  ],
  "i heard screaming": [
    "Clamores audivi"
  ],
  "hide helmet of minor destruction": [
    "Cutis Galea Minoris Destructionis"
  ],
  "collect crimson nirnroot in blackreach (<global=nn01count>/<global=nn01total>)": [
    "Carpe Nirniradicem Rubram in Atrihensu (<Global=NN01Count>/<Global=NN01Total>)"
  ],
  "a return to your roots": [
    "Reventum ad Radices"
  ],
  "return to your roots": [
    "Reventum ad Radices"
  ],
  "deliver the <alias=questitem> to <alias=target>": [
    "Redde <Alias=QuestItem> ad <Alias=Target>"
  ],
  "is there any work to be done": [
    "Estne labor faciendus"
  ],
  "imperial guard": [
    "Imperialis Custos"
  ],
  "restoration ritual spell": [
    "Magica Ritualis Reficiendi"
  ],
  "xander shopping clothes": [
    "Xander shopping Clothes"
  ],
  "the ransom of zarek": [
    "Lytrum Zarekis"
  ],
  "ransom of zarek": [
    "Lytrum Zarekis"
  ],
  "sosia tremellia": [
    "Sosia Tremellia"
  ],
  "do it, or things get ugly": [
    "Fac id, vel res malae tibe erunt",
    "Fac id, vel res malae tibi erunt"
  ],
  "broken azura's star": [
    "Stella Azurae Fracta"
  ],
  "dialoguemarkarthhagscurescenemuiribothela03": [
    "DialogueMarkarthHagsCureSceneMuiriBothela03"
  ],
  "delivery": [
    "Redditum"
  ],
  "orcish bow of debilitation": [
    "Orci Arcus Debilitationis"
  ],
  "use kavohzein's fang to collect heartscales": [
    "Uti Dente Kavohzeinis ut Corsquama Colligas"
  ],
  "alteration ritual spell": [
    "Magica Ritualis Mutationis"
  ],
  "daedric battleaxe": [
    "Bellisecuris Daedricus"
  ],
  "riverwood hod gerdur scene": [
    "Riverwood Hod Gerdur scene"
  ],
  "sanctified iron greatsword": [
    "Sanctificata Spatha Ferri"
  ],
  "embry hod": [
    "Embry Hod"
  ],
  "shaircolor3": [
    "sHairColor3"
  ],
  "i think your brother was a traitor": [
    "Credo fratrem tuum proditorum fuisse"
  ],
  "scoundrel's folly post": [
    "Scoundrel's Folly Post"
  ],
  "what kind of a test is this": [
    "Quale examen est hoc"
  ],
  "tell <alias.shortname=questgiver> that <alias.shortname=target> agreed to release the shipment": [
    "Dic <Alias.ShortName=Questgiver> <Alias.ShortName=Target> Mercem Transire Sivisse"
  ],
  "spray": [
    "Aerosolum"
  ],
  "the spiced wine": [
    "Aromatites"
  ],
  "spiced wine": [
    "Aromatites"
  ],
  "sarethi farm faction": [
    "Sarethi Farm Faction"
  ],
  "angi": [
    "Angi"
  ],
  "investigate the man wandering the streets near the bards college": [
    "Investiga Hominem per vias Peregrinantem prope Collegium Bardorum"
  ],
  "head to the solitude court": [
    "Ii ad Aulam Solitudinis"
  ],
  "speak to viarmo at the bards college": [
    "Viarmonem ad Collegium Bardorum Adloquere"
  ],
  "i've avenged you and your mother": [
    "Te et matrem ultus sum"
  ],
  "iron war axe of diminishing": [
    "Bellisecuris Ferri Diminuendi"
  ],
  "creaturedialoguewitchlight": [
    "CreatureDialogueWitchlight"
  ],
  "i've finished that special markarth job": [
    "Munus speciale Markartiae perfinivi"
  ],
  "how am i going to get into this party": [
    "Quomodo hanc epulam intrare possim"
  ],
  "your life ends now": [
    "Vita tua nunc terminat"
  ],
  "creaturedialoguewisp": [
    "CreatureDialogueWisp"
  ],
  "where is this door": [
    "Ubi est haec ianua"
  ],
  "all keys": [
    "All Keys"
  ],
  "the bonds of matrimony": [
    "Vincula Matrimonii"
  ],
  "bonds of matrimony": [
    "Vincula Matrimonii"
  ],
  "orcish helmet of extreme archery": [
    "Orci Galea Extremis Saggitationis"
  ],
  "dialoguemarkarthgenericscene02": [
    "DialogueMarkarthGenericScene02"
  ],
  "tell me about ennodius": [
    "Dic mihi de Ennodio"
  ],
  "amulet of the moon": [
    "Monile Lunae"
  ],
  "that seems rather harsh": [
    "Mihi asperius videtur"
  ],
  "draught of health": [
    "Decocta Salutis"
  ],
  "don't have time": [
    "Tempus non habeo"
  ],
  "dwarven bow of despair": [
    "Dvemeris Arcus Desponsionis"
  ],
  "avanchnzel key": [
    "Clavis ad Avanchnzel"
  ],
  "have you made any progress translating shalidor's work": [
    "Quomodo it mutatio operis Shalidoris"
  ],
  "maleeyesorcdarkgrey": [
    "MaleEyesOrcDarkGrey"
  ],
  "ward - greater": [
    "Praesidium Magicum - Maius"
  ],
  "third remains": [
    "Reliquia Tertia"
  ],
  "imperial boots of waning fire": [
    "Caligae Romanae Insenescentis Ignis"
  ],
  "return to kodlak": [
    "Redi ad Kodlak"
  ],
  "investigate shroud hearth barrow": [
    "Investiga Pabulum Velaminis Obscuri"
  ],
  "browsfemaleargonian01": [
    "BrowsFemaleArgonian01"
  ],
  "locate reyda's remains": [
    "Inveni Corpus Reydae"
  ],
  "the dragons are a bigger problem than the stormcloaks right now": [
    "Dracones difficultas maior sunt quam Sturmmanteli hoc in tempore"
  ],
  "dragons are a bigger problem than the stormcloaks right now": [
    "Dracones difficultas maior sunt quam Sturmmanteli hoc in tempore"
  ],
  "you mentioned an uprising": [
    "Commemoravisti rem novam"
  ],
  "talk to <alias=questgiver>": [
    "Adloquere <Alias=Questgiver>"
  ],
  "gather 10 bear pelts for temba wide-arms (<global=ffi03bearcount>/<global=ffi03beartotal>)": [
    "Collige X pelles ursorum pro Temba Lata-Brachia (<Global=FFI03BearCount>/<Global=FFI03BearTotal>)"
  ],
  "i'm sorry to trouble you, but i have some more questions": [
    "Me paenitet te molestare, sed tibi plurima interrogata habeo"
  ],
  "oakflesh": [
    "Oakflesh"
  ],
  "climb the steps": [
    "Ascende Gradus"
  ],
  "sleeping tree cave": [
    "Arboris Dormientis Spelunca"
  ],
  "largashbur dialogue": [
    "Largashbur Dialogue"
  ],
  "sheogorath boots": [
    "Sheogorath Caligae"
  ],
  "spriggan swarm": [
    "Spriggan Swarm"
  ],
  "ivarstead scripted scene bridge": [
    "Ivarstead Scripted Scene Bridge"
  ],
  "imperial helmet of major restoration": [
    "Romana Galea Maioris Recreandi"
  ],
  "college lectures": [
    "College Lectures"
  ],
  "whiterun stormcloak camp": [
    "Albacursus Sturmmantelorum Castra"
  ],
  "vigilant tyranus": [
    "Vigilans Tyranus"
  ],
  "who's to say what happened at <alias=imperialtownraided>": [
    "Quis dicere potest quid ad <Alias=ImperialTownRaided> accidisse"
  ],
  "still thirsty": [
    "Iam sitis"
  ],
  "marksmaleargonianscar01": [
    "MarksMaleArgonianScar01"
  ],
  "daedric bow of damnation": [
    "Arcus Daedricus Damnationis"
  ],
  "talk to <alias.shortname=questgiver>": [
    "Adloquere <Alias.ShortName=Questgiver>"
  ],
  "fjori": [
    "Fjori"
  ],
  "kill the dragon at <alias=den>": [
    "Neca Draconem ad <Alias=Den>"
  ],
  "take over <alias=fort> by killing the enemy (<global=cwpercentpoolremainingdefender>% remaining)": [
    "Cape <Alias=Fort> quod inimicos necas (<Global=CWPercentPoolRemainingDefender>% Manent)"
  ],
  "i have my skill, not powers or magic": [
    "Artem habeo, nec vires nec magicam"
  ],
  "cleanse <alias=questgiver> of beastblood": [
    "Sana Bestiasanguinem <Alias=Questgiver>"
  ],
  "key to uthgerd the unbroken's house": [
    "Clavis ad Domum Uthgerdis Infracti"
  ],
  "goldenglow estate faction": [
    "Goldenglow Estate Faction"
  ],
  "riften ratway": [
    "Hiati Via Murum"
  ],
  "tattered journal": [
    "Ephemeris Fragosus"
  ],
  "frostmere depths": [
    "Ima Glacimerae"
  ],
  "find 20 jazbay (<global=ffsarethi01jazcount>/<global=ffsarethi01jaztotal>)": [
    "Inveni XX Jazbay (<Global=FFSarethi01JazCount>/<Global=FFSarethi01JazTotal>)"
  ],
  "smooth jazbay": [
    "Jazbay Suavis"
  ],
  "femalemouthkhajiit": [
    "FemaleMouthKhajiit"
  ],
  "i take it you never used the pilgrim's path": [
    "Credo te numquam Via Peregrinorum usam esse"
  ],
  "bow to the master": [
    "Flecte (Arcum) Domino"
  ],
  "you cannot sleep in this location": [
    "Hic dormire non potes"
  ],
  "key to solitude cemetary": [
    "Clavis ad Necropolin Solitudinis"
  ],
  "report leifnarr's death to his family": [
    "Dic familiae de morte Leifnarris"
  ],
  "steel war axe of dismay": [
    "Bellisecuris Adamanteus Timoris"
  ],
  "flight or fight": [
    "Effugium vel Pugna"
  ],
  "return to filnjar": [
    "Reveni ad Filnjarem"
  ],
  "i hear you're the authority on ancient falmer": [
    "Audivi te authoritam de Falmeribus antiquis esse"
  ],
  "clear redbelly mine of spiders": [
    "Evacua araneas a Metallo Rubristomachi"
  ],
  "was a man named sam with me": [
    "Erat homo nomine 'Samuelis' mecum"
  ],
  "mine or yours": [
    "Meus vel Tuus"
  ],
  "are you the court wizard": [
    "Esne thaumatorgos aulae"
  ],
  "sounds easy": [
    "Facilis mihi videtur"
  ],
  "deliver the satchel to verner in darkwater crossing": [
    "Redde Cibisem Verneri in Vado Aquobscurae"
  ],
  "special delivery": [
    "Redditum Speciale"
  ],
  "sarethi farm scene 03": [
    "Sarethi Farm Scene 03"
  ],
  "glass helmet of eminent alteration": [
    "Galea Vitrea Eminentis Mutationis"
  ],
  "take <alias=item> to the shrine of talos for <alias=questgiver>": [
    "Fer <Alias=Item> ad Aram Talos pro <Alias=Questgiver>"
  ],
  "i'm working on getting your amulet back": [
    "Monile referre conor"
  ],
  "collect parts of the poetic edda from bards you meet": [
    "Collige partes Eddae Poeticae ab Poetis Quibus Occuras"
  ],
  "collecting the edda": [
    "Eddam Colligens"
  ],
  "i don't like your attitude": [
    "Ingenium tuum mihi non placet"
  ],
  "i did this for nocturnal": [
    "Hoc feci pro Nocturnali"
  ],
  "sold": [
    "Venditum",
    "vēneō, vēnīre, vēniī, vēnitum"
  ],
  "(gain <global=wiadditem07payb> gold)": [
    "(Gain <Global=WIAddItem07PayB> auri)"
  ],
  "tell me about markarth": [
    "Dic mihi de Markartia"
  ],
  "what's wrong with the college": [
    "Quid mali cum collegio accidit"
  ],
  "dwemer convector": [
    "Convector Dvemeris"
  ],
  "let's get in there": [
    "Intremus"
  ],
  "rorik's manor key": [
    "Clavis ad Villam Rorikis"
  ],
  "cradle stone tower": [
    "Cradle Stone Tower"
  ],
  "no, sorry": [
    "Ignosce",
    "Minime, ignosce"
  ],
  "i haven't been to whiterun before": [
    "Numquam ad Albacursum antea ivi"
  ],
  "i haven't met her, but i could try to get more information": [
    "Ei nondum occurri, sed temptare plus inforationis invenire possum"
  ],
  "actually, yes": [
    "Sic"
  ],
  "ebony warhammer of fire": [
    "Bellimalleus Ebeni Ignis"
  ],
  "glass boots of brawn": [
    "Vitreae Caligae Fortitudinis"
  ],
  "dragonplate gauntlets of extreme archery": [
    "Draconilaminatae Manicae Extremis Saggitationis"
  ],
  "complexion": [
    "Complexio"
  ],
  "ul": [
    "Ul"
  ],
  "if you were dead i could keep the horse": [
    "Si mortuus fueris, equum retinere potero"
  ],
  "carriage driver": [
    "Conductor Raedae"
  ],
  "irkngthand ramparts": [
    "Pinna Irkngthandis"
  ],
  "take all the artifacts i have": [
    "Cape omnes res daedricas quas habeo"
  ],
  "i don't have time for that": [
    "Tempus illo non habeo"
  ],
  "serpent's venom": [
    "Venenum Serpentis"
  ],
  "i don't need to prove anything to you": [
    "Opus mihi est nihil ad te approbare"
  ],
  "i'm done here": [
    "Nihil alterum mihi hic faciendum est"
  ],
  "orcish battleaxe of despair": [
    "Orci Bellisecuris Desponsionis"
  ],
  "clam": [
    "Peloris"
  ],
  "i don't know where she is": [
    "Nescio ubi sit"
  ],
  "the underforge": [
    "Fabricabyssum"
  ],
  "underforge": [
    "Fabricabyssum"
  ],
  "assist the people of the rift (<global=friendscountrift>/<global=friendstotalmajorhold>)": [
    "Adiava incolas Hiati (<Global=FriendsCountRift>/<Global=FriendsTotalMajorHold>)"
  ],
  "i have the fine-cut void salts": [
    "Salia hiati bene-scissa habeo"
  ],
  "drahff": [
    "Drahff"
  ],
  "daedric war axe of freezing": [
    "Bellisecuris Daedricus Gelidus"
  ],
  "magic anomaly": [
    "Magica Volans"
  ],
  "hide bracers of minor lockpicking": [
    "Chirothecae Cutis Minoris Clavicarii"
  ],
  "ebony mace of enervating": [
    "Maca Ebeni Enervationis"
  ],
  "can you translate it": [
    "Potesne id mutare in sermonem nostram"
  ],
  "i'll keep an eye open": [
    "Oculum meum aperiam"
  ],
  "daedric mace": [
    "Maca Daedrica"
  ],
  "imperial light shield of resist fire": [
    "Romana Parma Contra Vim Ignis"
  ],
  "what can you tell me about windhelm": [
    "Quid mihi dicere potes de Ventigalea"
  ],
  "what do i have to do": [
    "Quid mihi faciendum est"
  ],
  "i found a book that might help your search": [
    "Librum inveni qui te in inquisitione tua adiuvet"
  ],
  "i'll just stay out of your way": [
    "Modo e via tua manebo"
  ],
  "ruined book": [
    "Liber Rutus"
  ],
  "not really interested": [
    "Mihi non interest"
  ],
  "would these do": [
    "Quid hi faciant"
  ],
  "who are these \"natives\" you mentioned": [
    "Qui sunt \"nativi\" quos commemoravisti"
  ],
  "dwarven sphere bolt": [
    "Dwemeris Sphere Bolt"
  ],
  "elven warhammer of banishing": [
    "Alfus Bellimaleus Expellendi"
  ],
  "here's your mammoth tusk": [
    "Hic habes dentem elephantis tuum"
  ],
  "riften warehouse key": [
    "Clavis ad Horreum Hiati"
  ],
  "dismay push effect": [
    "Dismay Push Effect"
  ],
  "i've found the ice wraith teeth": [
    "Dentes phantasmae glaciei inveni"
  ],
  "ansilvund burial chambers": [
    "Ansilvundis Conclavia Sepultendi"
  ],
  "you're the master of the greybeards": [
    "Dominus Canabarbarum es"
  ],
  "do others come here to train": [
    "Aliine huic veniunt ut discant"
  ],
  "hairlinemalenord4": [
    "HairLineMaleNord4"
  ],
  "adonato's book": [
    "Liber Adonatonis"
  ],
  "i wish to meditate on a word of power": [
    "Volo de Verbo Potestatis meditari"
  ],
  "draugr wight lord": [
    "Draugr Dominus Amortuorum"
  ],
  "mithorpa nasyal": [
    "Mithorpa Nasyal"
  ],
  "dead thrall": [
    "Servus Amortuus"
  ],
  "maleeyesorcblindrightsingle": [
    "MaleEyesOrcBlindRightSingle"
  ],
  "glass boots": [
    "Vitreae Caligae"
  ],
  "key to markarth keep": [
    "Clavis ad Castellum Markartiae"
  ],
  "blade of woe": [
    "Enses Dolorosus"
  ],
  "i found what you were looking for": [
    "Inveni id quod quaesivisti"
  ],
  "feim": [
    "Feim"
  ],
  "rank = which mission is in \"slot\" 1": [
    "Rank = which mission is in \"slot\" 1"
  ],
  "copper and onyx circlet": [
    "Cupri et Alabastritae Circulum"
  ],
  "humanbeard44": [
    "HumanBeard44"
  ],
  "make alpha zero": [
    "Make Alpha Zero"
  ],
  "daedric shield of grounding": [
    "Scutum Daedricum Minoris Electricitatis"
  ],
  "what do you know about shouts": [
    "Quid de clamoribus scis"
  ],
  "i've defeated the guardian troll": [
    "Trellis custos iam superavi"
  ],
  "orcish battleaxe of shocks": [
    "Orci Bellisecuris Electricitatis"
  ],
  "i've been traveling all over": [
    "Ubique peregrinatus sum"
  ],
  "even been to helgen": [
    "Etiam Helgeni"
  ],
  "orders, sir": [
    "Mandata, domine"
  ],
  "why are you asking": [
    "Cur rogas"
  ],
  "is there any way to cleanse the star": [
    "Estne modo in quo Stellam mundam faciam"
  ],
  "i don't think i'm game": [
    "Crede me id facere non velle"
  ],
  "why would you live here at the inn": [
    "Cur hic in deversorio vivis"
  ],
  "jeweled pitcher": [
    "Lagoenam Gemmatam"
  ],
  "humanskinbasewhite08": [
    "HumanSkinBaseWhite08"
  ],
  "castle dour dungeon": [
    "Carcer Castelli Duri"
  ],
  "daedric armor of peerless health": [
    "Daedrica Lorica Optimae Salutis"
  ],
  "dwarven boots of waning shock": [
    "Dvemeres Caligae Insenescentis Electricitatis"
  ],
  "orcish shield of eminent blocking": [
    "Orci Scutum Eminentis Interponendi"
  ],
  "speak to verulus about the hall of the dead": [
    "Adloquere Verulum de Aula Mortuorum"
  ],
  "ulfr's book": [
    "Liber Ulfris"
  ],
  "no, keep playing": [
    "Minime, ludere perge"
  ],
  "aim menu": [
    "Aim Menu"
  ],
  "prisoners faction": [
    "Prisoners Faction"
  ],
  "you're from hammerfell": [
    "Ex Mallecasu venisti"
  ],
  "you've got your hands full, eh": [
    "Difficultates tibi sunt, nonne"
  ],
  "arch-mage's quarters": [
    "Cubiculum Officinaque Archimagi"
  ],
  "draught of resist magic": [
    "Decocta Resistendi Magicam"
  ],
  "chief mauhulakh": [
    "Princeps Mauhulakh"
  ],
  "investigationtarget": [
    "InvestigationTarget"
  ],
  "i'd like you to train me in illusion magic": [
    "Volo ut me de magica inlusionis doceas"
  ],
  "orcish mace of flames": [
    "Orci Maca Flammarum"
  ],
  "mq reserved locations": [
    "MQ Reserved Locations"
  ],
  "niruin": [
    "Niruin"
  ],
  "orcish shield of shock suppression": [
    "Orci Scutum Suppresionis Electricitatis"
  ],
  "orcish war axe of reaping": [
    "Orci Bellisecuris Saturni"
  ],
  "i'd like to know more about azura": [
    "Plus de Azura scire velim"
  ],
  "jeweler": [
    "Jeweler"
  ],
  "nightgatehadringbusinessbranchtopic": [
    "NightgateHadringBusinessBranchTopic"
  ],
  "who is beirand": [
    "Quis est Beirand"
  ],
  "ebony battleaxe of the blaze": [
    "Bellisecuris Ebeni Congflagrantis"
  ],
  "dragonplate gauntlets of eminent sure grip": [
    "Draconilaminatae Manicae Eminentis Ambidexteritatis"
  ],
  "where'd you learn to smith": [
    "Ubi arma facere didicisti"
  ],
  "draugr skin": [
    "Draugr Skin"
  ],
  "how does a maid become a steward": [
    "Quomodo mutavit ancilla in Dispensator"
  ],
  "weapon enchantments": [
    "INCANTAMENTA ARMORUM"
  ],
  "ancestor's wrath": [
    "Atrocitas Maiorum"
  ],
  "would this ship model be worth anything": [
    "Aliquidne valet hoc exemplar navis "
  ],
  "have you located any words of power": [
    "Invenistine quae Verba Potestatis"
  ],
  "dwarven helmet of major alteration": [
    "Dvemeris Galea Maioris Mutationis"
  ],
  "torsten cruel-sea": [
    "Torsten Mare-Crudele"
  ],
  "steel gauntlets of minor archery": [
    "Adamanteae Manicae Minoris Saggitationis"
  ],
  "key to ogmund's house": [
    "Clavis ad Domum Ogmundis"
  ],
  "some wolves, but no dogs": [
    "Pauci lupi, sed nulli canes"
  ],
  "arondil's key": [
    "Clavis Arondilis"
  ],
  "daedric gauntlets of eminent archery": [
    "Daedricae Manicae Eminentis Saggitationis"
  ],
  "i hope you aren't angry": [
    "Spero te iratum non esse"
  ],
  "alik'r warrior": [
    "Alik'ris Bellator"
  ],
  "i've placed the horn at the shrine": [
    "Cornu ad templum deposui"
  ],
  "you mentioned a personal task earlier": [
    "Rem personalem antea commemoravisti"
  ],
  "lexicon": [
    "Lexicon"
  ],
  "glass shield of fire abatement": [
    "Vitreum Scutum Resistendi Ignis"
  ],
  "ebony warhammer of terror": [
    "Bellimalleus Ebeni Terroris"
  ],
  "verner rock-chucker": [
    "Verner Saxi-Iaculor"
  ],
  "orcish helmet of eminent alchemy": [
    "Orci Galea Alchemiae Eminentis"
  ],
  "i want to pay that prisoner's fine": [
    "Volo vectigal Illius Captivi solvere"
  ],
  "scaled helmet of eminent alchemy": [
    "Galea Squamata Alchemiae Eminentis"
  ],
  "i'm sorry, i can't right now": [
    "Me paenitet, non possum nunc"
  ],
  "kodlak whitemane": [
    "Kodlak Caniticana"
  ],
  "chimarvamidium": [
    "Chimarvamidium"
  ],
  "i'm afraid not": [
    "Timeo ut non"
  ],
  "loose ends, and all that": [
    "Relicta bene non sapiunt"
  ],
  "scroll of dragonhide": [
    "Codex Draconirenonem"
  ],
  "lightning cloak": [
    "Lightning Cloak"
  ],
  "draught of glibness": [
    "Decocta Loquacitatis"
  ],
  "hairmalenord19": [
    "HairMaleNord19"
  ],
  "i need to talk to you": [
    "Opus est mihi te adloqui"
  ],
  "daedric mace of immobilizing": [
    "Maca Daedrica Immobilitatis"
  ],
  "steel plate bracers of eminent smithing": [
    "Chirothecae Laminatae Maximi Faciendi"
  ],
  "charm - master of the mind": [
    "Charm - Dominus Mentis"
  ],
  "i think i want to keep the axe": [
    "Credo me securem habere velle"
  ],
  "so what's the story behind the rueful axe": [
    "Quid est fabula de Securi Rutae"
  ],
  "lost valkygg": [
    "Valkygg Perditus"
  ],
  "iron greatsword of torpor": [
    "Spatha Ferri Torporis"
  ],
  "ebony war axe of nullifying": [
    "Bellisecuris Ebeni Nullificandi"
  ],
  "drop the act": [
    "Desiste partem agere"
  ],
  "i know you're involved in her death": [
    "Scio te partem in morte eius habere"
  ],
  "you're elenwen": [
    "Elenwen es"
  ],
  "i've heard so much about you": [
    "Tanta de te audivi"
  ],
  "boethiah's embrace": [
    "Complexus Boethis"
  ],
  "don't worry, i'm here to kill alduin": [
    "Noli timere, hic sum ut Aldiunum necam"
  ],
  "tell me about sithis": [
    "Dic mihi de Sithi"
  ],
  "did you see what happened here": [
    "Vidistine quid hic accidit"
  ],
  "talk to odahviing": [
    "Adloquere Odahviing"
  ],
  "glass sword of lightning": [
    "Gladius Vitreus Fulminis"
  ],
  "dwarven warhammer of weariness": [
    "Dvemeris Bellimalleus Maioris Defatigationis"
  ],
  "sanctified orcish war axe": [
    "Sanctificatus Bellisecuris Orcorum"
  ],
  "middas": [
    "Die Mercurii"
  ],
  "imperial armor of health": [
    "Romana Lorica Salutis"
  ],
  "steel plate helmet of major magicka": [
    "Laminata Galea Maioris Magicae"
  ],
  "visiting noble": [
    "Nobilis Visitans"
  ],
  "glass battleaxe of fire": [
    "Bellisecuris Vitreus Ignis"
  ],
  "i'm looking for a young girl who lives around here": [
    "Virginem quae prope hunc locum vivit quearo"
  ],
  "can i use your forge": [
    "Possumne fabricam tuam uti"
  ],
  "self doubt": [
    "Dubitum"
  ],
  "key to karthwasten miners' barracks": [
    "Clavis ad Barracus Cuniculariorum Karthwasten"
  ],
  "you want me to kill a dragon for you": [
    "Visne me draconem tibi necare"
  ],
  "mehrunes dagon": [
    "Cronon"
  ],
  "do you have a verse for the poetic edda": [
    "Habesne versum Eddae Poeticae"
  ],
  "thalmor archer": [
    "Visigothi Archer"
  ],
  "fort greymoor prison": [
    "Carcer Castelli Portugrisei"
  ],
  "please, take this": [
    "Quaeso, cape hanc"
  ],
  "consider it a gift... (<bribecost> gold)": [
    "Puta id donum esse... (<BribeCost> auri)"
  ],
  "hunting bow of shocks": [
    "Arcus Venandi Electricitatis"
  ],
  "glass shield of dwindling shock": [
    "Vitreum Scutum Electricitatis Diminuentis"
  ],
  "i've collected some of the edda for you": [
    "Pars Eddae tibi habeo"
  ],
  "sure, but will i come out of there alive": [
    "Certe, sed ab illo loco vivus exibo"
  ],
  "i found esbern": [
    "Esbernem inveni"
  ],
  "did you say free meat pies": [
    "Dixistine artocrata sine pretio"
  ],
  "power of the elements": [
    "Potestas Elementarum"
  ],
  "left eye of the falmer": [
    "Oculus Sinister Falmerium"
  ],
  "lingering poison": [
    "Commorans Venenum"
  ],
  "your weird experiments are over, wizard": [
    "Experimenta mirabilia facta sunt, Thaumatorgos"
  ],
  "giving away your spiced wine, eh": [
    "Aromatitem gratis tradis"
  ],
  "any advice for fighting dragons": [
    "Consiliumne habes de dracones pugnando"
  ],
  "dragonscale shield of frost suppression": [
    "Scutum Draconisquamatum Contra Glaciem"
  ],
  "falion's house": [
    "Falionis House"
  ],
  "iron sword of binding": [
    "Gladius Ferri Deligandi"
  ],
  "imperial armor of the squire": [
    "Romana Lorica Armigeris"
  ],
  "weapon slot": [
    "Locus Aptus Armo"
  ],
  "what kind of celebration": [
    "Qualis celebratio"
  ],
  "daedric mace of nullifying": [
    "Maca Daedrica Nullificandi"
  ],
  "key to argi's hut": [
    "Clavis ad Casa Argi"
  ],
  "do you have any bedlam jobs": [
    "Habesne negotia \"bedlam\""
  ],
  "darklight tower key": [
    "Clavis ad Turrem Obscuralucem"
  ],
  "ancient nord helmet": [
    "Antiqua Germanica Galea"
  ],
  "rocksplinter": [
    "Lapidastula"
  ],
  "dwarven battleaxe of devouring": [
    "Dvemeris Bellisecuris Consumendi"
  ],
  "you'll come to the peace council, then": [
    "Ergo ad concilium You'll come to peace council, then"
  ],
  "the alchemy skill determines the creation and potency of potions and poisons": [
    "Ars Alchemiae potentiam potionum venenorumque a te facti afficit"
  ],
  "alchemy skill determines the creation and potency of potions and poisons": [
    "Ars Alchemiae potentiam potionum venenorumque a te facti afficit"
  ],
  "eldergleam sapling": [
    "Eldergleam Arboriculus"
  ],
  "what can i do": [
    "Quid facere possum"
  ],
  "dragonscale gauntlets of extreme alchemy": [
    "Draconisquamatae Manicae Extremis Alchemiae"
  ],
  "he gave me this keg to deliver": [
    "Mihi hanc cuppam dedit ut eam adferam"
  ],
  "find any dragons to hunt": [
    "Invenistine quas dracones venandas"
  ],
  "about your name": [
    "De nomine tuo"
  ],
  "glass helmet of eminent illusion": [
    "Galea Vitrea Eminentis Inlusionis"
  ],
  "imperial bow of dismay": [
    "Imperialis Arcus Timoris"
  ],
  "you need to leave <alias=questgiver> alone": [
    "<Alias=QuestGiver> molestare desinere debes"
  ],
  "reload": [
    "Reaperi"
  ],
  "where can i find fire salts": [
    "Ubi possum find fire salts"
  ],
  "fire and forget": [
    "Fire et Forget"
  ],
  "the dragon of <alias=dragonlair> is dead": [
    "Draco ex <Alias=DragonLair> mortuus est"
  ],
  "dragon of <alias=dragonlair> is dead": [
    "Draco ex <Alias=DragonLair> mortuus est"
  ],
  "mzulft": [
    "Mzulft"
  ],
  "final warning": [
    "Admonitum ultimum"
  ],
  "leave octieve alone": [
    "Desine e Octieve"
  ],
  "daedric warhammer of freezing": [
    "Bellimalleus Daedricus Gelidus"
  ],
  "how can we lure a dragon to dragonsreach": [
    "Quomodo possumus draconem ad Draconicarcerem vocare"
  ],
  "dwarven dagger of diminishing": [
    "Dvemeris Pugio Diminuendi"
  ],
  "orcish warhammer of depleting": [
    "Orci Bellimalleus Deplendi"
  ],
  "where are you taking your prisoner": [
    "Quo Captivum fers"
  ],
  "daedric greatsword of despair": [
    "Spatha Daedrica Desponsionis"
  ],
  "constant effect": [
    "Effectus Constans"
  ],
  "gallus, karliah and mercer were nightingales": [
    "Gallus, Karliah et Mercer Philomelae erant"
  ],
  "kitchen furnishings": [
    "Res Culinae",
    "Ornamenta Culinae"
  ],
  "(<global=hdwindhelmkitchen> gold)": [
    "(<Global=HDWindhelmKitchen> auri)"
  ],
  "(<global=hdwindhelmenchanting> gold)": [
    "(<Global=HDWindhelmEnchanting> auri)"
  ],
  "you have an odd name for a dark elf": [
    "Nomen mirabile pro Atralfo habes"
  ],
  "leather bracers of deft hands": [
    "Corii Chirothecae Dexteritatis"
  ],
  "spell vendor": [
    "Spell Vendor"
  ],
  "sabre cat pelt": [
    "Felinis Mucronis Reno"
  ],
  "does it matter": [
    "Refertne"
  ],
  "nightingale gloves": [
    "Philomelarum Manubria"
  ],
  "one of your workers": [
    "Unus ex fabris tuis"
  ],
  "you have a problem with the college": [
    "Querela cum Collegio habes"
  ],
  "the empire holds nothing worth trading riften for": [
    "Imperium nihil habet pretii digni Hiatu"
  ],
  "empire holds nothing worth trading riften for": [
    "Imperium nihil habet pretii digni Hiatu"
  ],
  "armor enchantments": [
    "INCANTAMENTA LORICAE"
  ],
  "weak paralysis poison": [
    "Venenum Debile Paralyticum"
  ],
  "defeat the guardian mammoth": [
    "Vince Elephantem Custodem"
  ],
  "stagger circle protection": [
    "Stagger Circle Protection"
  ],
  "bashnag is dead": [
    "Bashnag mortuus est"
  ],
  "wyndelius gatharian": [
    "Vyndelius Gatharian"
  ],
  "boarded storeroom door": [
    "Porta Clausa Lignis"
  ],
  "this must be awkward for you": [
    "Hoc incommodum pro te esse debet"
  ],
  "who are you looking for": [
    "Quem quaeris"
  ],
  "i will": [
    "Id faciam"
  ],
  "together, then": [
    "Iuctim"
  ],
  "it was in helgen": [
    "Erat Helgeni"
  ],
  "i was being sent to the executioner": [
    "Ad carnificem mittebar"
  ],
  "leather helmet of conjuring": [
    "Cutis Galea Vocandi"
  ],
  "what if i pay it": [
    "Quidni id solvam"
  ],
  "speak to orthus": [
    "Adloquere to Orthus"
  ],
  "executioner's robes": [
    "Carnificumeris Peplum"
  ],
  "cynric endell": [
    "Cynric Endell"
  ],
  "are you always drunk": [
    "Esne semper ebrius"
  ],
  "testcarrybasket": [
    "TestCarryBasket"
  ],
  "reverent iron warhammer": [
    "Reverens Bellimalleus Ferratus"
  ],
  "you really admired ulfric, didn't you": [
    "Nonne re vera Vercingetorigem admiratus es"
  ],
  "dbnazircontractset6readybranchtopic": [
    "DBNazirContractSet6ReadyBranchTopic"
  ],
  "daedric dagger of winnowing": [
    "Pugio Daedricus Ventagii"
  ],
  "karliah's gloves": [
    "Karliae Manubria"
  ],
  "farengar spell vendor": [
    "Farengar spell vendor"
  ],
  "you cannot sleep while taking health damage": [
    "Dormire non potes dum vulnus salutis accipias"
  ],
  "i'm sorry, what": [
    "Ignosce mihi, quid"
  ],
  "ms14shesmine": [
    "MS14ShesMine"
  ],
  "sithis": [
    "Sithis"
  ],
  "virulent frenzy poison": [
    "Virulens Insanitatis Venenum"
  ],
  "the red book of riddles": [
    "Liber Ruber Ambagium"
  ],
  "red book of riddles": [
    "Liber Ruber Ambagium"
  ],
  "vernaccus and bourlor": [
    "Vernaccus et Bourlor"
  ],
  "telekinesis ability": [
    "Telekinesis Ability"
  ],
  "hide helmet of major restoration": [
    "Cutis Galea Maioris Reficiendi"
  ],
  "apprentice restoration": [
    "Artis Reficiendi Discipulus"
  ],
  "could you make an exception in my case": [
    "Potesne pro me exceptionem facere"
  ],
  "how are you holding up": [
    "Quid tecum agit"
  ],
  "how about you let me in anyway": [
    "Quidni me intrare sinas"
  ],
  "hallowed daedric bow": [
    "Consacratus Arcus Daedricus"
  ],
  "i'm going in this way": [
    "Hac via eo"
  ],
  "stand aside": [
    "Remove te"
  ],
  "sylgja's house": [
    "Sylgja's House"
  ],
  "synod researcher": [
    "Investigator Synodis"
  ],
  "what if i were to pay you for the amulet": [
    "Quidni te pro monili pecuniam solvam"
  ],
  "iron warhammer of sparks": [
    "Bellimalleus Ferratus Scintillarum"
  ],
  "get 3 flawless amethysts for talen-jei (<global=ffriften06found>/<global=ffriften06total>)": [
    "Inveni III Amethystos Perfectos pro Talen-Jei (<Global=FFRiften06Found>/<Global=FFRiften06Total>)"
  ],
  "brand-shei's strongbox": [
    "Arca Brand-Shei"
  ],
  "then i'll make it a point to hunt them down": [
    "Ergo eos venari debeo"
  ],
  "complete the book powers of the elements": [
    "Perlegi librum \"Vis Elementium\""
  ],
  "so who's arnleif": [
    "Ergo quis est Arnleif"
  ],
  "sewer": [
    "Cloaca"
  ],
  "will you remove the curse from this ring": [
    "Removebis maledictionem ex anulo"
  ],
  "this is obviously a shakedown. (persuade)": [
    "Fraus certe est. (Persuade)"
  ],
  "hollowed-out tree stump": [
    "Cippus Excavatus"
  ],
  "okay, i can do that for you": [
    "Ok, illud pro te facere possum"
  ],
  "darkelfskin04": [
    "DarkElfSkin04"
  ],
  "so the guard at the north gate was your man": [
    "Ergo custos ad portam Septentrionalem erat homo tuus"
  ],
  "labyrinthian thoroughfare": [
    "Labyrinthi Transitum"
  ],
  "tell me about kyne": [
    "Dic mihi de Kyne"
  ],
  "sarthis idren": [
    "Sarthis Idren"
  ],
  "elven warhammer of leeching": [
    "Alfus Bellimaleus Sanguisugae"
  ],
  "i don't think camilla will be spending any more time with sven": [
    "Non credo Camillam plus temporis cum Sven consumpturam esse"
  ],
  "hathrasil": [
    "Hathrasil"
  ],
  "ebony dagger of depleting": [
    "Pugio Ebeni Deplendi"
  ],
  "dwarven greatsword of winnowing": [
    "Dvemeris Spatha Ventagii"
  ],
  "ebony warhammer of freezing": [
    "Bellimalleus Ebeni Gelidus"
  ],
  "dialoguemarkarthkeepintrocourtscene": [
    "DialogueMarkarthKeepIntroCourtScene"
  ],
  "who are the silver-bloods": [
    "Qui sunt Argenti-Sanguines"
  ],
  "have a drink on me": [
    "Bibitum e me accipe"
  ],
  "the last of the hunters is dead": [
    "Ultimus venatorum mortuus est"
  ],
  "last of the hunters is dead": [
    "Ultimus venatorum mortuus est"
  ],
  "aren't you just an advisor here": [
    "Nonne solus consuasor hic es"
  ],
  "grand plaza scene 10": [
    "Grand Plaza Scene 10"
  ],
  "scaled helmet of conjuration": [
    "Galea Squamata Coniurationis"
  ],
  "lighthouse lamp": [
    "Lampadis Pharos"
  ],
  "deliver rogatus's letter to leonitus": [
    "Fer Epistula Rogati ad Leonitum"
  ],
  "solaf": [
    "Solaf"
  ],
  "so how did you earn your name": [
    "Ergo, quommodo nomen tuum accepisti"
  ],
  "sounds normal to me": [
    "Mihi normalis videtur"
  ],
  "elven sword of lightning": [
    "Alfus Gladius Fulminis"
  ],
  "audiotemplatedraugr": [
    "AudioTemplateDraugr"
  ],
  "i've had enough for now": [
    "Satis iam habui"
  ],
  "did either of you get caught": [
    "Captine estis"
  ],
  "that's an incredible distance to run": [
    "Magna distantia currenda est"
  ],
  "steel armor of health": [
    "Adamantea Lorica Salutis"
  ],
  "elven battleaxe of debilitation": [
    "Bellisecuris Alfus Debilitationis"
  ],
  "key to half moon mill": [
    "Clavis ad Molam Mediae Lunae"
  ],
  "drop the debt or answer to me": [
    "Aes alienum depone vel da mihi responsum"
  ],
  "why were they laughing": [
    "Cur cachinnaverunt"
  ],
  "why would you leave all that behind": [
    "Cur illa omnia reliquisti"
  ],
  "you're a brave man": [
    "Vir fotis es"
  ],
  "folgunthur catacomb key": [
    "Clavis ad Catacumbas Folgunthuris"
  ],
  "like being a thief": [
    "Like being thief"
  ],
  "how did you end up in skyrim": [
    "Quomodo ad Caelifinem venisti"
  ],
  "that must have been difficult": [
    "Illud certe difficile erat"
  ],
  "elven warhammer of garnering": [
    "Alfus Bellimaleus Horti"
  ],
  "ainethach won't be a problem anymore": [
    "Ainethach diffiultas non iam erit"
  ],
  "so, will you tell me your real name": [
    "Ergo, mihine nomen verum tuum narrabis"
  ],
  "void salts": [
    "Sal Hiati"
  ],
  "something had to make you this angry": [
    "Certe aliquid tibi tantum incitavit"
  ],
  "hjaalmarch guard's shield": [
    "Halmarcis Custodis Scutum"
  ],
  "ebony greatsword of immobilizing": [
    "Spatha Ebeni Immobilitatis"
  ],
  "you don't have to continue": [
    "Pergere non debes"
  ],
  "how did you escape": [
    "Quomodo effugisti"
  ],
  "ivory dragon claw": [
    "Ungues Draconis Eburneus"
  ],
  "how did you become so good at picking locks": [
    "Quomodo tam bene clausuras aperire didicisti"
  ],
  "with skills like yours": [
    "Cum artibus ut tuis"
  ],
  "jailbreaking": [
    "Carceriliberans"
  ],
  "dragonplate gauntlets of extreme smithing": [
    "Draconilaminatae Manicae Extremis Ferrarii"
  ],
  "i'm not going to apologize": [
    "Veniam non petam"
  ],
  "i think i see what you're getting at": [
    "Credo me scire de qua loqueris"
  ],
  "alea quintus": [
    "Alea Quintus"
  ],
  "that man said something about the \"forsworn.\"": [
    "Iste aliquid de \"Abiuratis\" dixit"
  ],
  "mena": [
    "Mena"
  ],
  "why did you join the guild and not the brotherhood": [
    "Quamobrem te ad Sodalitatem adiunxisti nec Fraternitatem"
  ],
  "so you were part of a bandit clan": [
    "Pars Sodalitatis Latronum fuisti"
  ],
  "marise aravel": [
    "Marise Aravel"
  ],
  "but not for you": [
    "Sed non tibi"
  ],
  "gloomreach": [
    "Gloomreach"
  ],
  "why would i release you": [
    "Cur te liberem"
  ],
  "what changed": [
    "Quid mutavit"
  ],
  "fireballtest": [
    "FireballTEST"
  ],
  "steel battleaxe of cowardice": [
    "Bellisecuris Adamanteus Timoris"
  ],
  "you'd never had to do that before": [
    "Id numquam antea facere debuisti"
  ],
  "and garthek": [
    "Et Garthek"
  ],
  "remove it": [
    "Remove Id"
  ],
  "maybe you aren't meant to know": [
    "Fortasse scire non debes"
  ],
  "so he did what he thought was right": [
    "Ergo fecit id quod rectum putavit"
  ],
  "imperial bow of arcing": [
    "Imperialis Arcus Electricitatis"
  ],
  "call to arms": [
    "Vocatio ad Armas"
  ],
  "so who gave you the name": [
    "Ergo quis tibi nomen tuum dedit"
  ],
  "so how did someone like you join the guild": [
    "Ergo quomodo aliquis ut tu Sodalitatem adiunxisti"
  ],
  "the forgemaster's fingers": [
    "Digiti Ferrarii"
  ],
  "forgemaster's fingers": [
    "Digiti Ferrarii"
  ],
  "elven war axe of diminishing": [
    "Alfus Bellisecuris Diminuendi"
  ],
  "key to the nightgate inn": [
    "Clavis ad Diversorium Noctiportam"
  ],
  "summon subjugated ghost": [
    "Phantasmam Subiugatam Voca"
  ],
  "that was big of you to accept": [
    "Bene tibi erat accipere"
  ],
  "i have a letter from your father": [
    "Epistulam a patre tuo habeo"
  ],
  "observe atub's ritual": [
    "Specta Religionem Atubis"
  ],
  "potent poison": [
    "Potens Venenum"
  ],
  "so, where do you hail from": [
    "Ergo, unde venis"
  ],
  "orcish dagger of debilitation": [
    "Orci Pugio Debilitationis"
  ],
  "was it anything like the dark brotherhood": [
    "Erat aliquid similis Fraterniti Obscurae"
  ],
  "i suppose i could use some advice": [
    "Credo me consilio uti posse"
  ],
  "staff of frenzy": [
    "Rudis Amentiae"
  ],
  "why didn't you stay with the tong": [
    "Quidni cum Tong maneres"
  ],
  "no, we haven't": [
    "Minime"
  ],
  "well, your secret is safe with me": [
    "Ergo, secretum tuum mecum tutum est"
  ],
  "hag rock redoubt ruin": [
    "Sagae Saxum Redubitum Ruina"
  ],
  "i've found sinderion's remains": [
    "Corpus Sinderionis inveni"
  ],
  "potent stamina poison": [
    "Potens Venenum Staminae"
  ],
  "what's your position on the war": [
    "Quid de bello censes"
  ],
  "mani's cellar key": [
    "Clavis ad Cellarium Mani"
  ],
  "effect has already been added. edit the effect instead": [
    "Effectum iam additum est. Muta effectum in loco eius"
  ],
  "i found it in blackreach": [
    "Id inveni in Atrihensu"
  ],
  "winterhold imperial camp": [
    "Hiemiterrae Castra Romana"
  ],
  "npc faction (creates hostility to enemy)": [
    "NPC faction (creates hostility to enemy)"
  ],
  "i've brought this crimson nirnroot for you": [
    "Hanc Nirniradicem Rubram tibi tuli"
  ],
  "steel gauntlets of smithing": [
    "Adamanteae Manicae Ferrarii"
  ],
  "you in charge of this forge": [
    "Praeesne huic fabricae"
  ],
  "the song of pelinal, v6": [
    "Carmen Pelinalis, VI"
  ],
  "song of pelinal, v6": [
    "Carmen Pelinalis, VI"
  ],
  "glass dagger of animus": [
    "Pugio Vitreus Animi"
  ],
  "what are we looking for": [
    "Quid quaerimus"
  ],
  "imperial boots of dwindling shock": [
    "Caligae Romanae Electricitatis Diminuentis"
  ],
  "so erikur is your brother": [
    "Ergo Erikur est frater tuus"
  ],
  "rules": [
    "Regula"
  ],
  "we're thieves": [
    "Fures sumus"
  ],
  "what's the point of rules": [
    "Cur regula habemus"
  ],
  "did sinderion teach you how to grow nirnroot": [
    "Sinderionne te nirniradicem cultivare dociut"
  ],
  "how did you and sinderion meet": [
    "Quomodo Sinderioni occuristi"
  ],
  "how did he end up in skyrim": [
    "Quomodo ad Caelifinem venit"
  ],
  "then he left for blackreach i suppose": [
    "Deinde Prehensobcurum petivit, ut puto"
  ],
  "blessed steel warhammer": [
    "Sanctificatus Bellimalleus Adamanteus"
  ],
  "doesn't exist": [
    "Doesn't Exist"
  ],
  "would this loosen your tongue": [
    "Fortasse hoc adiuvat"
  ],
  "i can't help you right now": [
    "Te nunc adiuvare non possum"
  ],
  "can you help me get the elder scroll or not": [
    "Potesne me Codicem Veterrimum invenire adiuvare, vel non"
  ],
  "don't make me hurt you": [
    "Noli mihi tibe nocere cogere"
  ],
  "just tell me where it is": [
    "Modo dic mihi ubi esse"
  ],
  "i agree": [
    "Consentior"
  ],
  "we can't let ulfric push us around": [
    "Nullam terram cedere possumusVercingetorigi"
  ],
  "i don't want your help": [
    "Auxilium tuum nolo"
  ],
  "guardian circle hazard": [
    "Guardian Circle Hazard"
  ],
  "steel plate bracers of eminent sure grip": [
    "Chirothecae Laminatae Eminentis Ambidexteritatis"
  ],
  "circle of vitality hazard": [
    "Circle of Vitality Hazard"
  ],
  "what do i do with this sphere": [
    "Quid cum hac sphera faciam"
  ],
  "wreck of the winter war": [
    "Naufragium Belli Hiemis"
  ],
  "scroll of waterbreathing": [
    "Codex Respirandi Sub Aqua"
  ],
  "what do i do with this cube": [
    "Quid cum hoc cubo faciam"
  ],
  "elven helmet of eminent restoration": [
    "Alfa Galea Eminentis Reficiendi"
  ],
  "ulfric saved markarth": [
    "Vercingetorix Markartiam Servavit"
  ],
  "silvia": [
    "Silvia"
  ],
  "orcish battleaxe of harrowing": [
    "Orci Bellisecuris Occationis"
  ],
  "i don't pay much attention to current events": [
    "Ego res cotidianas non animadverto"
  ],
  "riverwatch": [
    "Riverwatch"
  ],
  "that sounded rehearsed": [
    "Mihi videtur te illud meditatum esse"
  ],
  "i'll get it done": [
    "Id faciam"
  ],
  "hunters of hircine": [
    "Hunters of Hircine"
  ],
  "disable help": [
    "Disable Help"
  ],
  "pray at dibella's altar": [
    "Preces Adhibe ad Aram Dibellae"
  ],
  "night of tears": [
    "Nox Lacrimarum"
  ],
  "steel mace of embers": [
    "Maca Adamantea Cinerum"
  ],
  "you and tullius are both mistaken": [
    "Tu Tulliusque ambo erravistis"
  ],
  "i'm loyal to the empire": [
    "Confido Imperio"
  ],
  "dwarven battleaxe of torpor": [
    "Dvemeris Bellisecuris Torporis"
  ],
  "black-briar lodge": [
    "Atri-Dumi Diversorium"
  ],
  "hairmaleorc07": [
    "HairMaleOrc07"
  ],
  "i did this to honor the guild": [
    "Id feci ut honorem Sodalitati adferam"
  ],
  "ranks": [
    "Status"
  ],
  "i'll distribute them": [
    "Eos distribuam"
  ],
  "it would be my pleasure": [
    "Mihi valde placeat"
  ],
  "i have kodlak's fragment": [
    "Fragmentum Kodlakis habeo"
  ],
  "swallow a soul gem": [
    "Gemmanimam consumere"
  ],
  "dwarven greatsword of debilitation": [
    "Dvemeris Spatha Debilitationis"
  ],
  "here's what they owed us": [
    "Hic habes id quod nobis debuÃªrunt"
  ],
  "what sort of contraband": [
    "Qualia inlicita"
  ],
  "a price for what": [
    "Pretium cuius rei"
  ],
  "price for what": [
    "Pretium cuius rei"
  ],
  "daedric sword of depleting": [
    "Gladius Daedricus Deplendi"
  ],
  "you mean wealth": [
    "De pecunia loqueris"
  ],
  "elven battleaxe of animus": [
    "Bellisecuris Alfus Animi"
  ],
  "potion of cure disease": [
    "Potio Morbum Curandi"
  ],
  "all staffs": [
    "All Staffs"
  ],
  "riften elgrim's elixirs faction": [
    "Hiati Elgrim's Elixirs Faction"
  ],
  "anything dangerous on that small island to the east": [
    "Este quid periculosin in insula ad eurum"
  ],
  "spell tome: stoneflesh": [
    "Liber Magicus: Lapidipellis"
  ],
  "darling, brynjolf sent me": [
    "Amice, Brynjolf mihi misit"
  ],
  "we both know what that means": [
    "Nos ambo scimus quid sibi vult"
  ],
  "sigurd": [
    "Sigurd"
  ],
  "ice wolf abilities": [
    "Ice Wolf abilities"
  ],
  "a power attack might stagger an opponent, preventing him from immediately counteracting": [
    "Ictus potestatis fortasse faciat ut inimicum claudicet et statim respondere non possit"
  ],
  "power attack might stagger an opponent, preventing him from immediately counteracting": [
    "Ictus potestatis fortasse faciat ut inimicum claudicet et statim respondere non possit"
  ],
  "tell me about the mine": [
    "Dic mihi de metallo"
  ],
  "fort dunstad prison": [
    "Carcer Castelli Dunstad"
  ],
  "novice retort": [
    "Novice Retort"
  ],
  "yeah": [
    "Sic"
  ],
  "your father said to speak to you": [
    "Pater me oravit ut te adloquerer"
  ],
  "before we go, i have to ask": [
    "Ante eximus, opus est mihi rogare"
  ],
  "(<global=hdmarkarthbedroom> gold)": [
    "(<Global=HDMarkarthBedroom> auri)"
  ],
  "living room furnishings": [
    "Ornamenta Atrii",
    "Suppelices pro Atrio"
  ],
  "(<global=hdmarkarthliving> gold)": [
    "(<Global=HDMarkarthLiving> auri)"
  ],
  "why was it too late": [
    "Cur nimis tardum erat"
  ],
  "orcish boots of dwindling shock": [
    "Orci Caligae Electricitatis Diminuentis"
  ],
  "(<global=hdmarkarthenchanting> gold)": [
    "(<Global=HDMarkarthEnchanting> auri)"
  ],
  "timetogo": [
    "TimeToGo"
  ],
  "tell me more about the barrow": [
    "Dic mihi plus de pabillo"
  ],
  "markarth ruins": [
    "Markartiae Ruinaae"
  ],
  "you seem to know an awful lot about this place": [
    "Multa de hoc loco scire videris"
  ],
  "what's wrong with the mine": [
    "Quid est difficultas cum meo"
  ],
  "%": [
    "%"
  ],
  "ebony greatsword of enervating": [
    "Spatha Ebeni Enervationis"
  ],
  "what if i don't want to be a werewolf": [
    "Quid faciam si versipellem esse nolo"
  ],
  "a snow elf": [
    "Alfus Nivis"
  ],
  "snow elf": [
    "Alfus Nivis"
  ],
  "weak vigor poison": [
    "Venenum Debile Vigoris"
  ],
  "key to solitude sawmill": [
    "Clavis ad Molam Solitudinis"
  ],
  "i'll clear them out for you": [
    "Eos pro te removebo"
  ],
  "clan shatter-shield house key": [
    "Clavis ad Domum Gentis Scuti-Fracti"
  ],
  "i'm going to pass on that": [
    "Id ignorabo"
  ],
  "i've already cleared redbelly mine": [
    "Iam Metallum Rubriventris purgavi"
  ],
  "i heard you know about elder scrolls": [
    "Audivi te de Codicibus Veterrimis scire"
  ],
  "what's been sending you to darkwater crossing": [
    "Quid tibi ad Vadum Aquaobcurae mittebat"
  ],
  "khayla": [
    "Khayla"
  ],
  "someone else is using this": [
    "Aliquis praeter te hoc nunc utitur"
  ],
  "elven sword of devouring": [
    "Alfus Gladius Consumendi"
  ],
  "i have a package for you from sylgja": [
    "Cistam tibi habeo e Sylgja"
  ],
  "sure, i'll deliver it": [
    "Certe, id trasferre possum"
  ],
  "destroy the vampire vighar": [
    "Neca Vampirum Vighar"
  ],
  "you're not well": [
    "Aeger es"
  ],
  "clear soljund's sinkhole of draugr": [
    "Vacua Draugr e Syrti Soljundis"
  ],
  "sex": [
    "Sexus"
  ],
  "i'll get proof... and <alias=steward>'s cooperation": [
    "Argumentum tibi inveniam... et consensum e <Alias=Steward>"
  ],
  "hairlinemalenord20": [
    "HairLineMaleNord20"
  ],
  "you didn't escape": [
    "Non effugisti"
  ],
  "any idea where to find the elder scroll": [
    "Scisne ubi Codicem Veterrimum inveniri"
  ],
  "leather boots of waning shock": [
    "Corii Caligae Insenescentis Electricitatis"
  ],
  "i have your sword": [
    "Gladium tuum habeo"
  ],
  "key to salvius farmhouse": [
    "Clavis ad Villam Rusticam Salvii"
  ],
  "banded iron shield of minor blocking": [
    "Ferri Adligati Scutum Minoris Interponendi"
  ],
  "daedric greatsword of subsuming": [
    "Spatha Daedrica Subsumendi"
  ],
  "so you like my outfit": [
    "Ergo, vestimenta mea tibi placent"
  ],
  "i'd like to rent the attic room": [
    "Cubiculum Subtegulanei conducere volo"
  ],
  "(<global=roomcost> gold)": [
    "(<Global=RoomCost> auri)"
  ],
  "philter of vigor": [
    "Philtrum Vigoris"
  ],
  "dremora messenger": [
    "Dremora Nuntius"
  ],
  "yes, sir": [
    "Sic, domine"
  ],
  "mq103imperialsoldierfaction": [
    "MQ103ImperialisSoldierFaction"
  ],
  "orcish mace of soul snares": [
    "Orci Maca Animicapti"
  ],
  "maleheadhighelf": [
    "MaleHeadHighElf"
  ],
  "how long have you tended the hall of the dead": [
    "Quamdiu Aulam Mortuorum curavisti"
  ],
  "hallowed orcish dagger": [
    "Consacratus Pugio Orcorum"
  ],
  "markarth guard": [
    "Markarth Guard"
  ],
  "hide bracers of major smithing": [
    "Chirothecae Cutis Maioris Faciendi"
  ],
  "hide helmet of illusion": [
    "Cutis Galea Inlusionis"
  ],
  "orcish dagger of diminishing": [
    "Orci Pugio Diminuendi"
  ],
  "key to weylin's room": [
    "Clavis ad Conclave Weylinis"
  ],
  "friends to both sides in mq101": [
    "friends to both sides in MQ101"
  ],
  "secret door": [
    "Ianuam Secretam"
  ],
  "kill <alias=challenger>": [
    "Neca <Alias=Challenger>"
  ],
  "did you know romlyn is skimming from the meadery": [
    "Novistine Romlyn pecuniam e Hydromellario furatus est"
  ],
  "alftand ruined tower": [
    "Alfterrae Turris Ruptus"
  ],
  "glass gauntlets of extreme archery": [
    "Vitreae Manicae Extremis Saggitationis"
  ],
  "steal suvaris atheron's logbook": [
    "Furare Librum Investigations Suvaris Atheronis"
  ],
  "ebony war axe of dread": [
    "Bellisecuris Ebeni Timoris"
  ],
  "potion of resist cold": [
    "Potio Resistendi Frigorem"
  ],
  "predator faction": [
    "Predator Faction"
  ],
  "uthgerd's house": [
    "Uthgerd's House"
  ],
  "family shield": [
    "Familiae Scutum"
  ],
  "riften north gate key": [
    "Clavis ad Portam Septentrionem Hiati"
  ],
  "audiotemplateatronachfrost": [
    "AudioTemplateAtronachumFrost"
  ],
  "does your sister cause you a lot of trouble": [
    "Facitne multas difficultates soror tua"
  ],
  "i've brought you 20 jazbay grapes": [
    "Tibi XX uvus jazbay tuli"
  ],
  "dwarven boots of dwindling frost": [
    "Dvemeres Caligae Minoris Congelationis"
  ],
  "i have 20 of those": [
    "XX earum tibi habeo"
  ],
  "(give jazbay grapes)": [
    "(da uvas jazbay)"
  ],
  "the battle-borns": [
    "Proeli-Nati"
  ],
  "battle-borns": [
    "Proeli-Nati"
  ],
  "what happened to the shrine of talos": [
    "Quid arae Talos accidit"
  ],
  "i just want her to be safe": [
    "Modo eam tutam esse volo"
  ],
  "let me find him first": [
    "Primo, sine me eum invenire"
  ],
  "dragons are bad for business": [
    "Dracones mali negotio sunt"
  ],
  "what happened to your bow": [
    "Quid arcui tuo accidit"
  ],
  "so i'm a bard": [
    "Ergo Bardus sum"
  ],
  "i found your bow": [
    "Arcum tuum inveni"
  ],
  "won't that kill him": [
    "Nonne eum necet"
  ],
  "drennen": [
    "Drennen"
  ],
  "what do the shrines do": [
    "Quid faciunt arae"
  ],
  "how do you know the thieves were from riften": [
    "Quomodo scisne fures Hiato venÃªrunt"
  ],
  "reverent orcish mace": [
    "Reverens Maca Orcorum"
  ],
  "you're the leader of the blackblood marauders": [
    "Dux Piratarum Atrisanguinis es"
  ],
  "daedric dagger of terror": [
    "Pugio Daedricus Terroris"
  ],
  "i'll make sure he gets it": [
    "Pigneror ut id accipiat"
  ],
  "sorry, i try and avoid thieves": [
    "Ignosce, fures evitare tempto"
  ],
  "no need. it's right here": [
    "Necesse non est. Hic eum habeo"
  ],
  "orcish war axe of binding": [
    "Orci Bellisecuris Deligandi"
  ],
  "you've had trouble with thieves": [
    "Difficultates cum furibus habuistis"
  ],
  "forelhost entrance key": [
    "Clavis ad Ingressum Forelhostis"
  ],
  "glass mace of scorching": [
    "Maca Vitrea Adurendi"
  ],
  "you want to leave the stronghold": [
    "Ex arce exire vis"
  ],
  "spell tome: telekinesis": [
    "Liber Magicus: Telekinesis"
  ],
  "grelod": [
    "Grelod"
  ],
  "as it turns out... i already killed her": [
    "Ut casus havet... eam iam necavi"
  ],
  "what happened to liefnarr": [
    "Quid Liefnarr accidit"
  ],
  "i found liefnarr's body": [
    "Corpus Liefnarris inveni"
  ],
  "prison cell key": [
    "Clavis ad Carcerem"
  ],
  "daedric helmet": [
    "Daedrica Galea"
  ],
  "commentaries on the mysterium xarxes": [
    "Commentarios Mysterii Xarxes"
  ],
  "i'll try and find him": [
    "Eam invenire temptam"
  ],
  "actually, i discovered leifnarr's body": [
    "De vero, corpus Leifnarris inveni"
  ],
  "talk to fastred": [
    "Fastred Adloquere"
  ],
  "i could get your husband's stash for you": [
    "Possum gazam mariti tui pro te referre possum"
  ],
  "ruins of kemel-ze": [
    "Ruinae Kemel-Ze"
  ],
  "are you glad the stormcloaks are in charge": [
    "Guadesne Sturmmantelos regere"
  ],
  "cleansing of the fane": [
    "Purgatio Fani"
  ],
  "ebony battleaxe of the vampire": [
    "Bellisecuris Ebeni Vampiri"
  ],
  "creaturedialoguedraugr": [
    "CreatureDialogueDraugr"
  ],
  "glass war axe of the blaze": [
    "Bellisecuris Vitreus Congflagrantis"
  ],
  "illusiongreenbodyartholder": [
    "IllusionGreenBodyArtHolder"
  ],
  "ranger": [
    "Secutor"
  ],
  "what about your rivals, the battle-borns": [
    "Quid de rivalibus tuis, Proeli-Nati"
  ],
  "waterbreathing": [
    "Sub Aqua Respirare"
  ],
  "i helped ralof escape": [
    "Adiuvi in effugio Ralofis"
  ],
  "he said he'd vouch for me": [
    "Dixit pro me locuturum esse",
    "Dixit auxilium mihi quoque dabit vicissim"
  ],
  "hjaalmarch guard": [
    "Custos Halmarcis"
  ],
  "your clans used to be friends": [
    "Gentes tuae amici fuerunt"
  ],
  "riften ratway faction enemy": [
    "Hiati Via Murum Faction Enemy"
  ],
  "why did she kill him": [
    "Cur eum necavit"
  ],
  "she's hiding at the north end of town": [
    "Illa est ad septentrionem oppidi latet"
  ],
  "will you restore the worship of talos": [
    "Religionem Alexandri reddes"
  ],
  "thonar silver-blood": [
    "Thonar Sanguinargenti"
  ],
  "when do i get to kill someone": [
    "Quando aliquem necare possum"
  ],
  "i'll take care of them": [
    "Eos necabo"
  ],
  "my jarl, is there anything else you need": [
    "Mi Comes, estne quid magis quod vis"
  ],
  "daedric sword of storms": [
    "Gladius Daedricus Tempestatium"
  ],
  "balbus": [
    "Balbus"
  ],
  "you said you had a task for me": [
    "Dixisti te munus pro me habere"
  ],
  "sarethi farm house faction": [
    "Sarethi Farm House Faction"
  ],
  "i'll take the ring to hircine": [
    "Anulum adferam Hecati"
  ],
  "drevis neloren": [
    "Drevis Neloren"
  ],
  "conjure potent frost thrall": [
    "Voca Servum Potens Glaciei"
  ],
  "scaled helmet of major archery": [
    "Galea Squamata Maioris Sagittationis"
  ],
  "karita": [
    "Karita"
  ],
  "scaled bracers of eminent smithing": [
    "Chirothecae Squamatae Maximi Faciendi"
  ],
  "hairmaleorc10": [
    "HairMaleOrc10"
  ],
  "eye of magnus": [
    "Oculus Magni"
  ],
  "go to the abandoned house": [
    "Intra Domum Vacuam"
  ],
  "i have all the pieces of the razor": [
    "Omnes partes novaculae habeo"
  ],
  "do you know where i could find isabelle rolaine": [
    "Scisne ubi Isabelle Rolaine invenire possim"
  ],
  "i'd like training in the art of speech": [
    "Volo discere de arte Loquacitatis"
  ],
  "can you train me to sneak": [
    "Potesne me repere docere"
  ],
  "locate the assassin of old": [
    "Inveni Sicarium Veterem"
  ],
  "honningbrew decanter": [
    "Obba Honningbrevis"
  ],
  "invitation to elenwen's reception": [
    "Invitatio ad Receptum Elenvenis"
  ],
  "what do you have in mind": [
    "Quid in animo habes"
  ],
  "glass battleaxe of the blaze": [
    "Bellisecuris Vitreus Congflagrantis"
  ],
  "she lets the servants in": [
    "Servos intrare sinet"
  ],
  "seems like a gap in security that could be exploited": [
    "Via per praesidium eius mihi videtur"
  ],
  "maybe i could rent the room": [
    "Fortasse cubiculum conducere possum"
  ],
  "why don't the others talk": [
    "Cur non loquuntur alii"
  ],
  "dushnamub services": [
    "Dushnamub Services"
  ],
  "train me to be a better pickpocket": [
    "Doce me ab aliis furari"
  ],
  "make your way to the keep": [
    "Iter Fac ad Castellum"
  ],
  "what wealth is this": [
    "De quibuis opibus agitur"
  ],
  "daedric dagger of storms": [
    "Pugio Daedricus Tempestatium"
  ],
  "map of dragon burials": [
    "Mapa Sepulturarum Draconium"
  ],
  "steel plate helmet of eminent conjuring": [
    "Laminata Galea Coniurationis Vocantis"
  ],
  "sons of skyrim": [
    "Sons of Skyrim"
  ],
  "duntrevas spectral arrow": [
    "dunTrevas Spectral Arrow"
  ],
  "can you train me to be better at archery": [
    "Potesne me de sagittatione docere"
  ],
  "the hope of the redoran": [
    "Spes Redoranis"
  ],
  "hope of the redoran": [
    "Spes Redoranis"
  ],
  "i need some training in lockpicking": [
    "Disce me de portas aperiendo"
  ],
  "i found one of your journals, all about necromancy": [
    "Inveni unum ex ephemeridibus tuis, omnino de arte necromanctica"
  ],
  "assault <alias=enemy> but don't kill <alias=enemy.pronoun>": [
    "Oppugna <Alias=Enemy> sed noli Necare <Alias=Enemy.pronoun>"
  ],
  "i've finished that special solitude job": [
    "Munus speciale Solitudinis perfinivi"
  ],
  "orcish helmet of major restoration": [
    "Orci Galea Maioris Reficiendi"
  ],
  "shaircolor13": [
    "sHairColor13"
  ],
  "still have that balmora blue": [
    "Iamne illam Balmoram Caeruleam habes"
  ],
  "iron mace of fatigue": [
    "Ferri Maca Defatigationis"
  ],
  "greybeard's boots": [
    "Canabarbae Caligae"
  ],
  "potion of glibness": [
    "Potio Loquacitatis"
  ],
  "steel cuffed boots": [
    "Adamanteae Laciniatae Caligae"
  ],
  "glass dagger of diminishing": [
    "Pugio Vitreus Diminuendi"
  ],
  "i'd like training in destruction magic": [
    "Volo de Magica Destructionis discere"
  ],
  "iron warhammer of fatigue": [
    "Bellimalleus Ferratus Defatigationis"
  ],
  "destroy skull of corruption": [
    "Destroy Skull of Corruption"
  ],
  "froki whetted-blade": [
    "Froki Ensi-Acutatus"
  ],
  "holgeir": [
    "Holgeir"
  ],
  "this experiment of yours is over": [
    "Experimentum tuum terminatur"
  ],
  "can you train me to block": [
    "Potesne me melius interponere docere"
  ],
  "purchase agreement": [
    "Foedus Emendi"
  ],
  "so use me to help you break this curse": [
    "Ergo utere me ut hanc maledictionem cures"
  ],
  "borgny": [
    "Borgny"
  ],
  "so this is all the blades": [
    "Ergo hi omnes enses sunt"
  ],
  "two people": [
    "Duo Homines"
  ],
  "velvet lechance": [
    "Holosericum LeChance"
  ],
  "maybe i can help you find new recruits": [
    "Fortasse tirones novos tibi invenire possum"
  ],
  "i brought someone to induct into the blades": [
    "Aliquem attuli qui in enses inducere vellem"
  ],
  "imperial government": [
    "Imperialis Government"
  ],
  "dialoguekarthwastenragnarlash04": [
    "DialogueKarthwastenRagnarLash04"
  ],
  "ebony dagger of the vampire": [
    "Pugio Ebeni Vampiri"
  ],
  "on second thought, maybe not": [
    "Ut iterum cogito, fortasse non"
  ],
  "i try not to care": [
    "Non curare conor"
  ],
  "daedric gauntlets of eminent sure grip": [
    "Daedricae Manicae Eminentis Ambidexteritatis"
  ],
  "blasphemer": [
    "Blasphemator"
  ],
  "i've found the crown of barenziah": [
    "Coronam Barenziae inveni"
  ],
  "hairlinemalenord13": [
    "HairLineMaleNord13"
  ],
  "blacksmith's potion": [
    "Ferrarii Potio"
  ],
  "here, take it": [
    "Age, cape id",
    "Hic, cape id",
    "Veni huc, accipe id"
  ],
  "you think general tullius knows where that dragon came from": [
    "Putasne Imperatore Tullius sciat unde venisse hunc draconem"
  ],
  "imperial bracers of major conjuring": [
    "Chirothecae Romanae Maioris Vocandi"
  ],
  "glass dagger of enervating": [
    "Pugio Vitreus Enervationis"
  ],
  "giant club": [
    "Giant Club"
  ],
  "how will you protect yourselves against the dragons": [
    "Quomodo te contra dracones teges"
  ],
  "it's been returned to the sepulcher": [
    "Ad Sepulchrum redditur"
  ],
  "potion of ultimate stamina": [
    "Potio Ultimatis Staminae"
  ],
  "dragonplate helmet of extreme magicka": [
    "Draconilaminata Galea Extremis Magicae"
  ],
  "so, where will you be now": [
    "Ergo, ubi nunc eris"
  ],
  "dervenin": [
    "Dervenin"
  ],
  "kill alain dufont": [
    "Neca Alanum Dufontem"
  ],
  "staff of the storm atronach": [
    "Rudis Atronachi Tempestatis"
  ],
  "elven dagger of harvesting": [
    "Pugio Alfus Saturni"
  ],
  "i can handle it": [
    "Id administrare possum"
  ],
  "key to irgnir's house": [
    "Clavis ad Domum Irgniris"
  ],
  "i'd rather not do that": [
    "Malim id non facere"
  ],
  "i came here looking for the synod": [
    "Hic veni Synodum quarens"
  ],
  "daedric war axe of lethargy": [
    "Bellisecuris Daedricus Lethargiae"
  ],
  "imperial sword of souls": [
    "Gladius Romanus Animorum"
  ],
  "why help the dark elves": [
    "Cur Atralfos adiuvas"
  ],
  "freeformmarkarthm2bothelascenetrigger": [
    "FreeformMarkarthM2BothelaSceneTrigger"
  ],
  "the real barenziah, v4": [
    "Barenzia Vera, IV"
  ],
  "real barenziah, v4": [
    "Barenzia Vera, IV"
  ],
  "invisible chest": [
    "Invisible Chest"
  ],
  "eldergleam sap": [
    "Olibanum Eldergleam"
  ],
  "so you make armor for the stormcloaks": [
    "Ergo loricam Sturmmantelis facis"
  ],
  "(invisible continue) difficulty based on attack dela": [
    "(Invisible Continue) Difficulty based on Attack Dela"
  ],
  "iron gauntlets of wielding": [
    "Ferri Manicae Tenendi"
  ],
  "why is the psijic order contacting me": [
    "Cur Ordo Psijicus me adloquitur"
  ],
  "spell tome: flame thrall": [
    "Liber Magicus: Servus Flammarum"
  ],
  "elven greatsword of enervating": [
    "Spatha Alfa Enervationis"
  ],
  "have you ever met with the psijic order": [
    "Umquam Ordini Pjijico occuristi"
  ],
  "skald": [
    "Skald"
  ],
  "i'm here about fjotli": [
    "Hic sum de Fjotli"
  ],
  "requires key": [
    "Clavem Postulat"
  ],
  "you said something about missing apprentices": [
    "Dixisti aliquid de discipulis amissis"
  ],
  "tell me again about your wife": [
    "Dic mihi iterum de uxore tua"
  ],
  "ebony warhammer of banishing": [
    "Bellimalleus Ebeni Expellendi"
  ],
  "the straw that broke": [
    "Stramen quod Fregit"
  ],
  "straw that broke": [
    "Stramen quod Fregit"
  ],
  "any word from the rest of the missing apprentices": [
    "Suntne verba ab aliis discipulis perditis"
  ],
  "why the name \"drunken huntsman\"": [
    "Cur nomen 'Venator Ebrius' habet"
  ],
  "deepwood redoubt key": [
    "Clavis ad Redubitum Profundiligni"
  ],
  "potions": [
    "POTIONES"
  ],
  "orthorn's faction": [
    "Orthornis Faction"
  ],
  "nchuand-zel": [
    "Nchuand-Zel"
  ],
  "i found this": [
    "Hoc inveni"
  ],
  "it's rundi's, isn't it": [
    "Nonne Rundi est,"
  ],
  "ebony sword of dread": [
    "Gladius Ebeni Timoris"
  ],
  "i found ilas-tei": [
    "Ilas-tei inveni"
  ],
  "here's proof": [
    "Hic est argumentum"
  ],
  "i'm afraid yisra didn't survive": [
    "Timeo ut Yisra non superfuerit"
  ],
  "storm atronach faction": [
    "Storm Atronach Faction"
  ],
  "fortify light armor": [
    "Adde ad Arma Levia"
  ],
  "glass mace of dread": [
    "Maca Vitrea Timoris"
  ],
  "audiotemplatedragonpriest": [
    "AudioTemplateDragonPriest"
  ],
  "haelga's bunkhouse scene 01": [
    "Haelga's Bunkhouse Scene 01"
  ],
  "iron dagger of fear": [
    "Pugio Ferri Timoris"
  ],
  "i could find those for you": [
    "Eos pro te invenire possum"
  ],
  "(<global=hpriften> gold)": [
    "(<Global=HPRiften> auri)"
  ],
  "ebony sword of banishing": [
    "Gladius Ebeni Expellendi"
  ],
  "what's your problem with faralda": [
    "Quid est difficultas tua cum Faralda"
  ],
  "rebel's cairn": [
    "Defectoris Monolithos"
  ],
  "there's a lot of nirnroot around here": [
    "Multae nirniradices prope hunc locum sunt"
  ],
  "you seem quite exhausted": [
    "Exhuastus videris"
  ],
  "spell tome: frost cloak": [
    "Liber Magicus: Amiculum Glaciei"
  ],
  "i could help you if you like": [
    "Possum adiuvare, si vis"
  ],
  "here, take them": [
    "Hic ea habes"
  ],
  "(give fragments)": [
    "(da fragmenta)"
  ],
  "glass greatsword of blizzards": [
    "Spatha Vitrea Hiemis"
  ],
  "maybe one of my friends here in the palace could be my escort": [
    "Fortasse unus ex amicis meis hic in aula dux meus erit"
  ],
  "are you not coming": [
    "Non mecum is"
  ],
  "why do you think we're here": [
    "Quamobrem censes nos hic esse"
  ],
  "fire storm cloak": [
    "Fire Storm Cloak"
  ],
  "lostidle": [
    "LostIdle"
  ],
  "steel greatsword of souls": [
    "Spatha Adamantea Animorum"
  ],
  "night mother voice npc": [
    "Mater Noctis Voice NPC"
  ],
  "malyn varen's grimoire": [
    "Grimoire Malyn Varenis"
  ],
  "aduri sarethi": [
    "Adura Sarethi"
  ],
  "hairlinemaleelder6": [
    "HairLineMaleElder6"
  ],
  "i will try to follow the way of the voice": [
    "Viam Vocis sequi conabor"
  ],
  "you seem quite proud of your work": [
    "Superbum laboris tui esse videris"
  ],
  "elven warhammer of fear": [
    "Alfus Bellimaleus Timoris"
  ],
  "dragonplate helmet of peerless alteration": [
    "Draconilaminata Galea Optimae Mutationis"
  ],
  "ebony greatsword of lightning": [
    "Spatha Ebeni Fulminis"
  ],
  "i'll take care of it": [
    "De eo curabo"
  ],
  "orcish mace of draining": [
    "Orci Maca Hauriendi"
  ],
  "i'd rather not get involved": [
    "Malim partem in eo non habere"
  ],
  "highlandcow": [
    "HighlandCow"
  ],
  "that criminal won't be a problem anymore": [
    "Iste sceleratus difficultas non iam erit"
  ],
  "scaled bracers of alchemy": [
    "Chirothecae Squamatae Alchemiae"
  ],
  "nightingale blade": [
    "Ensis Philomelae"
  ],
  "you got me": [
    "Me habes"
  ],
  "i believe in talos": [
    "Alexandro credo"
  ],
  "orcish greatsword of flames": [
    "Orci Spatha Flammarum"
  ],
  "elven battleaxe of enervating": [
    "Bellisecuris Alfus Enervationis"
  ],
  "i'll get it back": [
    "Id referam"
  ],
  "daedric greatsword of storms": [
    "Spatha Daedrica Tempestatium"
  ],
  "talk to arniel": [
    "Adloquere Arnielem"
  ],
  "let's have somebody else do that": [
    "Sine aliquem praeter te id facere"
  ],
  "note to rhorlak": [
    "Epistula ad Rhorlakem"
  ],
  "i retrieved the family's <alias=gewgaw>": [
    "Rettuili <Alias=Gewgaw> Familiae"
  ],
  "femalemouthorc": [
    "FemaleMouthOrc"
  ],
  "darkelfmalelips02": [
    "DarkElfMaleLips02"
  ],
  "tough luck": [
    "Vae tibi"
  ],
  "what does the dragonrend shout actually do": [
    "Quidnam facit Draconicaesis"
  ],
  "imperial justiciar faction": [
    "Imperialis Justiciar Faction"
  ],
  "dragonscale helmet of eminent archery": [
    "Draconisquamata Galea Eminentis Saggitationis"
  ],
  "quicksilver ingot": [
    "Mercurii Later"
  ],
  "is it difficult being away from your family": [
    "Estne difficulis procul a familia abesse"
  ],
  "ancient shrouded gloves": [
    "Antiquus Umbratus Manubria"
  ],
  "steel mace of arcing": [
    "Maca Adamantea Electricitatis"
  ],
  "can you tell me anything about the \"butcher\"": [
    "Potesne mihi quid de \"Carnifice\" narrare"
  ],
  "femaleeyesdarkelfdeepred2": [
    "FemaleEyesDarkElfDeepRed2"
  ],
  "felldir the old": [
    "Felldir Vetus"
  ],
  "korvanjund halls": [
    "Korvanjund Atria"
  ],
  "sanctified orcish battleaxe": [
    "Sanctificatus Bellisecuris Orcorum"
  ],
  "isn't it enough that i want to fight imperials": [
    "Nonne satis est me contra Romanos pugnare velle"
  ],
  "receive the greybeards' greeting": [
    "Accipe Salutationem Canabarborum"
  ],
  "who's madanach": [
    "Quis est Madanach"
  ],
  "actually, i'm not sure": [
    "Securus non sum"
  ],
  "i need to think about it": [
    "De eo cogitare debeo",
    "Opus est mihi de hac re cogitare"
  ],
  "why can't the guards help": [
    "Quamobrem custodes auxilium dare non possunt"
  ],
  "nothing i can't handle": [
    "Nihi quod administrare non possum"
  ],
  "don't you want your treasure": [
    "Nonne gazam vis"
  ],
  "riches, and all that": [
    "Divitiae, et haec omnia"
  ],
  "i need to think it over": [
    "De eo cogitare debeo"
  ],
  "dialogue faction for dawnstar guards": [
    "Dialogue Faction for Dawnstar Guards"
  ],
  "reverent glass battleaxe": [
    "Reverens Bellisecuris Vitreus"
  ],
  "\"...even to my lord as to my fellow brothers and sisters in arms.\"": [
    "\"...eodem modo domino me ut fratibus sororibusque in armis.\""
  ],
  "find a way to release thorald from thalmor custody": [
    "Inveni viam ad Thoraldem e custodia Visigothi liberandum"
  ],
  "invisible race": [
    "Invisible Race"
  ],
  "reduced lockpick & pickpocket": [
    "Artes Clavicarii et Furandi Diminuuntur"
  ],
  "the prophecy says i'm the only one who can defeat alduin": [
    "Oraculum dicit me solum qui Alduinum vincere posse esse"
  ],
  "prophecy says i'm the only one who can defeat alduin": [
    "Oraculum dicit me solum qui Alduinum vincere posse esse"
  ],
  "yatul": [
    "Yatul"
  ],
  "how do you know the crown is in korvanjund": [
    "Quomodo Coronam in Korvanjund esse scis"
  ],
  "staff of sparks": [
    "Rudis Scintillarum"
  ],
  "sounds like it will be a simple task": [
    "Munus simplex nihi videtur"
  ],
  "you anticipate danger": [
    "Periculum exspectasYou anticipate danger"
  ],
  "might not have a choice next time": [
    "Fortasse proxima occasione eligere non poteris"
  ],
  "letter of inheritance": [
    "Epistula Haereditatis"
  ],
  "%s is too powerful for %s": [
    "%s nimis potens est pro %s"
  ],
  "tell me more about the rescue": [
    "Dic mihi plus de extractione"
  ],
  "reverent elven sword": [
    "Reverens Gladius Alfus"
  ],
  "jester's gloves": [
    "Macci Manubria"
  ],
  "lu'ah's key": [
    "Clavis Lu'ae"
  ],
  "imperial boots of resist frost": [
    "Caligae Romanae Contra Vim Gelidam"
  ],
  "you should probably handle this yourself": [
    "Tu ipse remedium huic imponere debes"
  ],
  "ebony battleaxe of the sorcerer": [
    "Bellisecuris Ebeni Magi"
  ],
  "glass bow of thunderbolts": [
    "Arcus Vitreus Fulminum"
  ],
  "steel battleaxe of torpor": [
    "Bellisecuris Adamanteus Torporis"
  ],
  "is this what we're looking for": [
    "Hocne quaeris"
  ],
  "campaign for <alias=campaignhold>": [
    "Pugna pro <Alias=CampaignHold>"
  ],
  "orcish gauntlets of eminent wielding": [
    "Orci Manicae Eminentis Tenendi"
  ],
  "ralof said you could help me out": [
    "Ralof dixit te me adiuvare posse"
  ],
  "if you have an enchanted weapon equipped, its current enchantment level is shown above your stamina bar or magicka bar": [
    "Si armum incantatum teneas, gradus incantamenti demonstratur super staminam vel magicam tuam"
  ],
  "will you let the argonians into the city": [
    "Sinesne Argonios urbem intrare"
  ],
  "grand plaza scene 14": [
    "Grand Plaza Scene 14"
  ],
  "this object is activated from somewhere else": [
    "Hoc activare potes ab alio loco"
  ],
  "hoddreid": [
    "Hoddreid"
  ],
  "marksfemaleargonianscar02": [
    "MarksFemaleArgonianScar02"
  ],
  "let's go kill a dragon": [
    "Eamus draconem necatum"
  ],
  "maybe one day you'll see one yourself": [
    "Fortasse aliquo die unum videbis"
  ],
  "erj's notes": [
    "Adnotationes Erjis"
  ],
  "not good enough": [
    "Non satis bene"
  ],
  "i'll hold onto this": [
    "Hoc retinebo"
  ],
  "you'll need to find another one": [
    "Aliud invenire debebis"
  ],
  "dragonhide": [
    "Draconireno"
  ],
  "pleased": [
    "Placitus",
    "gaudeō, gaudēre, gāvīsus sum"
  ],
  "she sounded indifferent": [
    "Indifferens mihi videtur"
  ],
  "you fought well": [
    "Bene pugnavisti"
  ],
  "tell perth that the draugr are dead": [
    "Dic Perth Draugr Mortuos Esse"
  ],
  "marksfemaleargonianscar04": [
    "MarksFemaleArgonianScar04"
  ],
  "next time, try harder": [
    "Tempus proximum, fortius conare"
  ],
  "imperial bracers of minor alteration": [
    "Chirothecae Romanae Minoris Mutationis"
  ],
  "who's stendarr": [
    "Quis est Stendarr"
  ],
  "the dark brotherhood requires your services": [
    "Fraternitas Obscura auxilium tuum poscit"
  ],
  "dark brotherhood requires your services": [
    "Fraternitas Obscura auxilium tuum poscit"
  ],
  "jorgen and lami's house": [
    "Jorgen et Lami Domus"
  ],
  "brow height": [
    "Altitudo Frontis"
  ],
  "dragonscale armor of the peerless knight": [
    "Draconisquamata Lorica Optimae Equitis"
  ],
  "steel shield": [
    "Adamanteum Scutum"
  ],
  "ari": [
    "Ari"
  ],
  "this is a personal matter, and i leave it to you": [
    "Res personalis est, et munus eius tibi dabo"
  ],
  "ruki": [
    "Ruki"
  ],
  "i will help you cure yourself": [
    "Te adiuvabo ut te cures"
  ],
  "scaled boots of brawn": [
    "Caligae Squamatae Fortitudinis"
  ],
  "glass boots of dwindling flames": [
    "Vitreae Caligae Flammarum Diminuentium"
  ],
  "olaf made a deal with numinex": [
    "Olaf transactionem cum Numinice fecit"
  ],
  "requires": [
    "Postulat"
  ],
  "he said to tell you to \"suffer the winter's cold wind.\"": [
    "Dicit tibi \"ventum frigidum hiemis patiendum esse.\""
  ],
  "let's go find him": [
    "Eum inveniamus"
  ],
  "femaleeyeshumanhazelbrown": [
    "FemaleEyesHumanHazelBrown"
  ],
  "steel sword of scorching": [
    "Gladius Adamanteus Adurendi"
  ],
  "dwarven helmet of magicka": [
    "Dvemeris Galea Magicae"
  ],
  "spell tome: conjure storm atronach": [
    "Liber Magicus: Voca Atronachum Electricatatis"
  ],
  "malachite ore": [
    "Malachitae Minerale"
  ],
  "virtuous glass greatsword": [
    "Virtutis Spatha Vitrea"
  ],
  "enable help": [
    "Enable Help"
  ],
  "did it work": [
    "Functus est"
  ],
  "spell tome: rout": [
    "Liber Magicus: Effigitum"
  ],
  "i'll try getting him to leave": [
    "Eum exire cogere conabor"
  ],
  "iron gauntlets of sure grip": [
    "Ferri Manicae Dexteritatis"
  ],
  "obtain a silver ingot": [
    "Obtine Minerale Argentei"
  ],
  "db08- finding the gourmet": [
    "DB08- Inveniens Apicium"
  ],
  "unseal door": [
    "Aperi Ianuam"
  ],
  "daedric sword of nullifying": [
    "Gladius Daedricus Nullificandi"
  ],
  "tell me more about the elder scrolls": [
    "Dic mihi plus de Codicibus Veterrimis"
  ],
  "fort amol prison": [
    "Carcer Castelli Amolis"
  ],
  "of course i do": [
    "Certe"
  ],
  "do you have one here": [
    "Unumne hic habes"
  ],
  "do you at least have any information on them": [
    "Informationemne de eis habes"
  ],
  "what mission": [
    "Quid munus"
  ],
  "i need to find one and was told you could help": [
    "Opus mihi est unum invenire et audivi te me adiuvare posse"
  ],
  "leather bracers of lockpicking": [
    "Corii Chirothecae Clavicarii"
  ],
  "fredas": [
    "Die Veneris"
  ],
  "(show invitation)": [
    "(Demonstra invitationem)"
  ],
  "the bear of markarth": [
    "Ursus Markartiae"
  ],
  "bear of markarth": [
    "Ursus Markartiae"
  ],
  "who wrote the elder scrolls": [
    "Quis Codices Veterrimos scripsit"
  ],
  "spell tome: sparks": [
    "Liber Magicus: Scintillae"
  ],
  "loading distant lod": [
    "LOD procul aperiens"
  ],
  "all right": [
    "Bene"
  ],
  "so do you have one that i could use": [
    "Habesne unum quod uti possim"
  ],
  "the art of war magic": [
    "Ars Magicae Belli"
  ],
  "art of war magic": [
    "Ars Magicae Belli"
  ],
  "steel greatsword of weariness": [
    "Spatha Adamantea Maioris Defatigationis"
  ],
  "here, boy": [
    "Hic, puer"
  ],
  "ebony war axe of expelling": [
    "Bellisecuris Ebeni Expellendi"
  ],
  "potion of resist magic": [
    "Potio Resistendi Magicam"
  ],
  "daedric battleaxe of garnering": [
    "Bellisecuris Daedricus Horti"
  ],
  "what are you going to do with this place": [
    "Quid cum hoc loco facies"
  ],
  "plant the <alias=evidence> in <alias=wealthyhome> in <alias=city>": [
    "Pone <Alias=Evidence> in <Alias=WealthyHome> in <Alias=City>"
  ],
  "(<global=hdsolitudeenchanting> gold)": [
    "(<Global=HDSolitudeEnchanting> auri)"
  ],
  "idgrod's note": [
    "Epistula Idgrodis"
  ],
  "(<global=hdsolitudekitchen> gold)": [
    "(<Global=HDSolitudeKitchen> auri)"
  ],
  "ebony sword of exhaustion": [
    "Gladius Ebeni Exhaustionis"
  ],
  "seared slaughterfish": [
    "Ustum Piscaesis"
  ],
  "do you need help finding the sword": [
    "Visne auxilium in gladium inveniendo"
  ],
  "steel helmet of conjuring": [
    "Adamantea Galea Vocandi"
  ],
  "bolgeir bearclaw": [
    "Bolgeir Ursunguis"
  ],
  "glass dagger of freezing": [
    "Pugio Vitreus Gelidus"
  ],
  "dwarven greatsword of arcing": [
    "Dvemeris Spatha Electricitatis"
  ],
  "daedric dagger of nullifying": [
    "Pugio Daedricus Nullificandi"
  ],
  "elven battleaxe of expelling": [
    "Bellisecuris Alfus Expellendi"
  ],
  "tell me about this temple": [
    "Dic mihi de hoc templo"
  ],
  "i've taken care of potema": [
    "Potemam necavi"
  ],
  "i'd like to join the imperial legion": [
    "Volo Legionem Romanum adiungere",
    "Volo Legionem Imperialem adiungere"
  ],
  "i'm here to help": [
    "Hic sum adiuvandi causa"
  ],
  "so, he's gone": [
    "Ergo, ille abest"
  ],
  "we should work together": [
    "Una laborare debemus"
  ],
  "the hag's cure": [
    "Cura Sagae"
  ],
  "hag's cure": [
    "Cura Sagae",
    "Hag's Cure"
  ],
  "ebony bow of storms": [
    "Arcus Ebeni Tempestatium"
  ],
  "dragonplate armor of peerless health": [
    "Draconilaminata Lorica Optimae Salutis"
  ],
  "i've got the logbook": [
    "Librum habeo"
  ],
  "large dwemer plate metal": [
    "Magnum Dvemere Metallum Lemellatum"
  ],
  "flame atronach attack": [
    "Flame Atronach Attack"
  ],
  "they're a part of the darkness around us": [
    "Pars obscuritatis circa nos sunt"
  ],
  "elven bracers of archery": [
    "Chirothecae Alfae Saggitationis"
  ],
  "what else you got": [
    "Quid aliud habes"
  ],
  "hold on": [
    "Exspecta"
  ],
  "you may know something important": [
    "Fortasse tu aliquid magni momenti scis"
  ],
  "orbby": [
    "Orbby"
  ],
  "how will i know if i'm needed here": [
    "Quomodo sciam si mihi hic adessendum sit"
  ],
  "niranye's house": [
    "Niranyis Domus"
  ],
  "so, should i drop this statue down a well": [
    "Ergo, debeone hanc statuam in puteum ammitere"
  ],
  "i'll return shortly with the dragon scales": [
    "Breve tempus cum squamis draconis reveniam"
  ],
  "are you hircine": [
    "Esne Hecate"
  ],
  "riften grand plaza": [
    "Hiati Grand Plaza"
  ],
  "blessing of kynareth": [
    "Benedictio Kynarethis"
  ],
  "elven sword of torpor": [
    "Alfus Gladius Torporis"
  ],
  "ancient nord sword of ice": [
    "Gladius Antiquus Germanicus Gelidus"
  ],
  "dragonrend effect - stagger": [
    "Dragonrend Effect - Stagger"
  ],
  "i'm going to kill you": [
    "Te necam"
  ],
  "iron war axe of souls": [
    "Bellisecuris Ferri Animorum"
  ],
  "ebony war axe of storms": [
    "Bellisecuris Ebeni Tempestatium"
  ],
  "i could get it for you": [
    "Id pro te portare possum"
  ],
  "new content available": [
    "Res Novae Ex Rete Expromptae Eligibiles"
  ],
  "hairfemaledarkelf02": [
    "HairFemaleDarkElf02"
  ],
  "i killed the dragon": [
    "Draconem necavi"
  ],
  "i think i deserve a reward": [
    "Me praemium merere credo"
  ],
  "bolund": [
    "Bolund"
  ],
  "this whole town wants you out. leave. (persuade)": [
    "Totum oppidum te discedere vult. (Persuade)"
  ],
  "potion of destruction": [
    "Potio Destructionis"
  ],
  "forsworn dog faction": [
    "Forsworn Dog Faction"
  ],
  "solitude guard's shield": [
    "Solitudinis Custodis Scutum"
  ],
  "who are the morang tong": [
    "Qui sunt Morang Tong"
  ],
  "elven bracers of sure grip": [
    "Chirothecae Alfae Dexteritatis"
  ],
  "helvard": [
    "Helvard"
  ],
  "ghorbash the iron hand": [
    "Ghorbash Manuferrus"
  ],
  "i believe we've already met": [
    "Credo mihi iam occuristi"
  ],
  "gold diamond necklace": [
    "Auri Adamanteum Monile"
  ],
  "was that dragon on your side": [
    "Erat iste draco pars factionis tuae"
  ],
  "steel battleaxe of binding": [
    "Bellisecuris Adamanteus Deligandi"
  ],
  "hide armor of the minor squire": [
    "Renonis Lorica Minoris Armiferis"
  ],
  "about that test": [
    "De illo probatione"
  ],
  "any tips about keerava": [
    "Habesne quibus admonitis de Keerava"
  ],
  "servant of the abstruse": [
    "Servus Abstrusus"
  ],
  "elven mace of blizzards": [
    "Maca Alfa Hiemis"
  ],
  "beard": [
    "Beard"
  ],
  "dwemer history and culture": [
    "Historia Cultusque Dvemeris"
  ],
  "falk asked me to check it out": [
    "Falco mihi oravit ut pro eo provideam"
  ],
  "orcish warhammer of scorching": [
    "Orci Bellimalleus Adurendi"
  ],
  "the empire should compensate you for <alias=sonstownraided>": [
    "Imperium te pro <Alias=SonsTownRaided> compensationem dare debet"
  ],
  "empire should compensate you for <alias=sonstownraided>": [
    "Imperium te pro <Alias=SonsTownRaided> compensationem dare debet"
  ],
  "i have the items you requested": [
    "Res rogatas tibi habeo"
  ],
  "map updated": [
    "Ad Chartam Additum"
  ],
  "i'm going alone": [
    "Solus eo"
  ],
  "i'm not sure about this": [
    "De hoc Nescio"
  ],
  "i'm off to kill that ice wraith": [
    "Eo illam Phantasmam Glaciei Necatum"
  ],
  "i'll be back soon": [
    "Mox reveniam"
  ],
  "scroll of lightning bolt": [
    "Codex Fulminis"
  ],
  "steal statue of dibella": [
    "Furare Statuam Dibellae"
  ],
  "[hidden]": [
    "[LATENS]"
  ],
  "daedric warhammer of despair": [
    "Bellimalleus Daedricus Desponsionis"
  ],
  "what happened to you house": [
    "Quid accidit cum domo tuo"
  ],
  "all enchantedweapons": [
    "All EnchantedWeapons"
  ],
  "imperial lighthelmet of major illusion": [
    "Romana Cassis Maioris Inlusionis"
  ],
  "brow type": [
    "Modus Frontis"
  ],
  "skyrim is my home": [
    "Caelifinis est domus mea"
  ],
  "dragon's breath mead": [
    "Hydromellum Spiritus Draconis"
  ],
  "he fought bravely, but the giant overwhelmed him": [
    "Fortiter pugnavit, sed gigans eum superavit"
  ],
  "journeyman alembic": [
    "Journeyman Alembic"
  ],
  "make changes in the ledger at <alias=business> in <alias=city>": [
    "Muta kalendarium ad <Alias=Business> in <Alias=City>"
  ],
  "i found this note on ulag's body": [
    "Hanc epistulam in corpore Ulagis"
  ],
  "hide bracers of minor deft hands": [
    "Chirothecae Cutis Minoris Dexteritatis"
  ],
  "cell door": [
    "Porta Cellae"
  ],
  "i'm sorry to hear what happened to you": [
    "Me paenitet quid tibi accidisse audire"
  ],
  "you think your story justifies the murders the forsworn have committed": [
    "Putasne fabulam tuam caedes Abiuratorum excusare"
  ],
  "general tullius has already agreed to attend": [
    "Imperator Tullius iam adire consensit"
  ],
  "were you there when high king torygg died": [
    "Praesens fuisti ad mortem Regis Alti Torygg"
  ],
  "urzoga gra-shugurz": [
    "Urzoga gra-Shugurz"
  ],
  "you want me to lead": [
    "Visne me ducere"
  ],
  "i talked to braig": [
    "Adlocutus sum Braig"
  ],
  "i need a minute": [
    "Momentum postulo"
  ],
  "animation needed": [
    "ANIMATION NEEDED"
  ],
  "dark brotherhood assassin": [
    "Fraternitatis Obscurae Sicarius"
  ],
  "briar heart": [
    "Cordumus"
  ],
  "alduin... the dragon who's raising the others": [
    "Alduin... Draco qui alios e morte tollit"
  ],
  "madanach says hello": [
    "Madanach salutem dicit"
  ],
  "what has he done to displease you": [
    "Quid fecit quod tibi displicuit"
  ],
  "iron sword of ice": [
    "Gladius Ferri Gelidus"
  ],
  "grisvar is dead": [
    "Grisvar mortuus est"
  ],
  "i'm all right, i guess. how are you": [
    "Satis bene sum. ut puto. Quomodo te habes"
  ],
  "i've been better": [
    "Melior eram"
  ],
  "and you": [
    "Et tu"
  ],
  "so what's the plan": [
    "Ergo, quid est consilium"
  ],
  "only a beast kills without feeling": [
    "Modo bestia sine sententia necat"
  ],
  "there's an enemy wagon loaded with coin and weapons": [
    "Plaustrum inimicum est plenum nummorum armorumque"
  ],
  "we need to capture it": [
    "Id capere debemus"
  ],
  "thieves guild hood alt": [
    "Sodalitatis Furum Capucium Alt"
  ],
  "romlyn dreth": [
    "Romlyn Dreth"
  ],
  "rundi": [
    "Rundi"
  ],
  "yes, i do": [
    "Sic, eam novi"
  ],
  "is there a cure": [
    "Estne cura"
  ],
  "marksmalehumanoid04leftgash": [
    "MarksMaleHumanoid04LeftGash"
  ],
  "you've cleaned your last room, servant": [
    "Conclave ultimum mundum fecisti, ancilla"
  ],
  "i blackmailed <alias=steward> for the information": [
    "Extorsi <Alias=Steward> informationi"
  ],
  "steel plate bracers of alchemy": [
    "Chirothecae Laminatae Alchemiae"
  ],
  "object lod fade": [
    "Evanidum LOD Obiectorum"
  ],
  "atmah": [
    "Atmah"
  ],
  "dwarven mace of flames": [
    "Dvemeris Maca Flammarum"
  ],
  "and you lived": [
    "Et vixisti"
  ],
  "froki's bow": [
    "Arcus Froki"
  ],
  "research log": [
    "Adnotationis Investiagionis"
  ],
  "gastrap dummy": [
    "GasTrap Dummy"
  ],
  "steel sword of cold": [
    "Gladius Adamanteus Frigidis"
  ],
  "spell tome: firebolt": [
    "Liber Magicus: Sagitignis"
  ],
  "maybe you should leave me out of your speech": [
    "Fortasse me in oratione commemorare non debes"
  ],
  "what does your position as thalmor ambassador involve": [
    "Quae sunt munera tua ut Legatus Visigothorum"
  ],
  "i... i don't know": [
    "Ego... nescio"
  ],
  "it happened so fast": [
    "Tam celeriter accidit"
  ],
  "talsgar the wanderer": [
    "Talsgar Peregrinator"
  ],
  "studded armor of health": [
    "Ornata Lorica Salutis"
  ],
  "rattles": [
    "Quassationes"
  ],
  "what's your position on the civil war": [
    "Quid de bello civili censes"
  ],
  "i want to stop at kynesgrove": [
    "Volo consistere apud Kynenemus"
  ],
  "you have a lot to answer for": [
    "Poenas dare multis facinoribus debes"
  ],
  "ebony sword of damnation": [
    "Gladius Ebeni Damnationis"
  ],
  "doom": [
    "Fatum"
  ],
  "you mean the return of the dragons": [
    "De revento draconum loqueris"
  ],
  "blessed iron war axe": [
    "Sanctificatus Bellisecuris Ferri"
  ],
  "i'm not answering any questions": [
    "Nullis interrogatis respondeo"
  ],
  "ice mage": [
    "Glacimagus"
  ],
  "i don't need, or want, your help": [
    "Nolo, nec requiro, auxilium tuum"
  ],
  "i had questions about the forsworn": [
    "Interrogata de Abiuratis habui"
  ],
  "belethor's goods": [
    "Belethor's Goods"
  ],
  "haemar's cavern": [
    "Haemaris Caverna"
  ],
  "browsfemaleargonian00": [
    "BrowsFemaleArgonian00"
  ],
  "i want to know about thonar": [
    "Volo de Thonar scire"
  ],
  "what is this thing": [
    "Quid est hoc"
  ],
  "spell tome: fireball": [
    "Liber Magicus: Ignipila"
  ],
  "steel plate boots of waning fire": [
    "Laminatae Caligae Insenescentis Ignis"
  ],
  "anyone in this are friends": [
    "anyone in this are friends"
  ],
  "i've always been known to get into trouble": [
    "Semper notus sum quod in molestias semper cado"
  ],
  "join the men defending <alias=fort>": [
    "Adiunge homines qui <Alias=Fort> defendunt"
  ],
  "soul gems": [
    "Gemmanimi",
    "GEMMANIMAE"
  ],
  "whiterun anoriath olfina scene 1": [
    "Whiterun Anoriath Olfina Scene 1"
  ],
  "why were you waiting for me": [
    "Cur me expectavisti"
  ],
  "glass gauntlets of extreme alchemy": [
    "Vitreae Manicae Extremis Alchemiae"
  ],
  "i have friends": [
    "Amicos habeo"
  ],
  "only family i need": [
    "Familiae solae sunt quibus egeo"
  ],
  "you cannot sleep while guards are pursuing you": [
    "Dormire non potes dum custodes te persequantur"
  ],
  "where's the courier": [
    "Ubi est nuntius"
  ],
  "potion of true shot": [
    "Potio Saggitationis"
  ],
  "iron dagger of sparks": [
    "Pugio Ferri Scintillarum"
  ],
  "elven dagger of winnowing": [
    "Pugio Alfus Ventagii"
  ],
  "faendal sven": [
    "Faendal Sven"
  ],
  "dunmiddenshared": [
    "dunMiddenShared"
  ],
  "catapult": [
    "Onager",
    "catapulta, catapultae"
  ],
  "no, not right now": [
    "Minime, Non nunc"
  ],
  "restoration trainer": [
    "Reficiendi Trainer"
  ],
  "i've got the amulet": [
    "Monile habeo"
  ],
  "spell tome: frostbite": [
    "Liber Magicus: Adustus Nivibus"
  ],
  "a skald": [
    "Skald"
  ],
  "is that some kind of bard": [
    "Estne poeta"
  ],
  "what dangers does the caravan face": [
    "Quae Pericula caravannam comminantur"
  ],
  "atronachstormfx": [
    "AtronachStormFX"
  ],
  "key to katla's farm": [
    "Clavis ad Fundum Katlae"
  ],
  "you guard the caravan": [
    "Caravannam custodis"
  ],
  "elven battleaxe of dread": [
    "Bellisecuris Alfus Timoris"
  ],
  "what happened to that burned down house": [
    "Quid accidit domi adustae"
  ],
  "harbinger": [
    "Harbinger"
  ],
  "have things changed much with the stormcloaks in charge": [
    "MutavÃªruntne res multo nunc Sturmmantelis regentibus"
  ],
  "actorcollidewithactor": [
    "ActorCollidewithActor"
  ],
  "why did you stop doing it": [
    "Quamobrem de eo desivisti"
  ],
  "spell tome: invisibility": [
    "Liber Magicus: Invisibilitas"
  ],
  "normaltoalert": [
    "NormalToAlert"
  ],
  "staff of fear": [
    "Rudis Timoris"
  ],
  "draught of the berserker": [
    "Decocta Berserkris"
  ],
  "forsworn note": [
    "Epistula Abiurata"
  ],
  "have the stormcloaks caused you any trouble": [
    "MolastavÃªruntne te Sturmmanteli"
  ],
  "brief history of the empire, v4": [
    "Historia Brevis Imperii, IV"
  ],
  "hairlinemaleelder5": [
    "HairLineMaleElder5"
  ],
  "i convinced <alias.shortname=target> to ease up a bit": [
    "Convici <Alias.ShortName=Target> ut molierem esset"
  ],
  "ask wilhelm about reyda": [
    "Roga Wilhelm de Reyda"
  ],
  "maurice said that renewal is more important than maintenance": [
    "Maurice dixit renovationem maioris momenti esse quam tuitionem"
  ],
  "ebony warhammer of despair": [
    "Bellimalleus Ebeni Desponsionis"
  ],
  "maleeyeshumanbrown": [
    "MaleEyesHumanBrown"
  ],
  "how about we skip the conversation": [
    "Quidni finem collocutionis faciam"
  ],
  "forelhost well key": [
    "Clavis ad Fontem Forelhostis"
  ],
  "falmer arrow": [
    "Sagitta Falmeris"
  ],
  "concentration": [
    "Concentratio"
  ],
  "silver sapphire necklace": [
    "Argentei Sapphiri Monile"
  ],
  "dragonscale boots of fire abatement": [
    "Caligae Draconisquamatae Resistendi Ignis"
  ],
  "pelagius arena faction": [
    "Pelagius Arena Faction"
  ],
  "ah yes, high rock": [
    "Ah sic, Saxum Altum"
  ],
  "home of exquisite breton cuisine": [
    "Domus exquisiti cibi Britannorum"
  ],
  "salonia carvain": [
    "Salonia Carvain"
  ],
  "what did you have in mind": [
    "Quid in animo habuisti"
  ],
  "riften military camp": [
    "Hiati Castra"
  ],
  "steel sword of ice": [
    "Gladius Adamanteus Gelidus"
  ],
  "pinepeak cavern": [
    "Apicipini Caverna"
  ],
  "what's the story with the pilgrim's path": [
    "Quid est fabula de Via Peregrinatorum"
  ],
  "steel battleaxe of draining": [
    "Bellisecuris Adamanteus Hauriendi"
  ],
  "what does he want": [
    "Quid vult ille"
  ],
  "dialoguedushnikhyalminescene02": [
    "DialogueDushnikhYalMineScene02"
  ],
  "this didn't interfere with the nightingales": [
    "Hoc non erat contra mores Philomelae"
  ],
  "hillgrund's tomb key": [
    "Clavis ad Sepulchrum Hillgrundis"
  ],
  "ebony sword of despair": [
    "Gladius Ebeni Desponsionis"
  ],
  "elven greatsword of banishing": [
    "Spatha Alfa Expellendi"
  ],
  "tell me about the conduit inside the sepulcher": [
    "Dic mihi de Dioyge in Sepulchro"
  ],
  "i don't like being told what to do": [
    "Noli dici quid mihi faciendum esse"
  ],
  "elven sword of garnering": [
    "Alfus Gladius Horti"
  ],
  "whiterun guard's armor": [
    "Lorica Custodis Albacursus"
  ],
  "key to leigelf's house": [
    "Clavis ad Domum Leigelfis"
  ],
  "what is it you do here in town": [
    "Quid hic in oppido facis"
  ],
  "hallowed dwarven war axe": [
    "Consacratus Dvemeris Bellisecuris"
  ],
  "dalan merchad": [
    "Dalan Merchad"
  ],
  "pullbar": [
    "Fulcrum"
  ],
  "why set up shop here": [
    "Cur tabernam hic posuisi"
  ],
  "ebony greatsword of malediction": [
    "Spatha Ebeni Maledictionis"
  ],
  "reverent glass war axe": [
    "Reverens Bellisecuris Vitreus"
  ],
  "haafingar hold settlement dialogue": [
    "Haafingar Hold Settlement Dialogue"
  ],
  "imperial light shield of resist frost": [
    "Romana Parma Contra Vim Gelidam"
  ],
  "oh, do you mean this old stone": [
    "Oh, de hoc lapide loqueris"
  ],
  "(give dragonstone to farengar)": [
    "(Draconilapidem Farengari da)"
  ],
  "rissing": [
    "Rissing"
  ],
  "leather helmet of alteration": [
    "Cutis Galea Mutationis"
  ],
  "orcish greatsword of despair": [
    "Orci Spatha Desponsionis"
  ],
  "yamarz must listen with the ears of the wind": [
    "Yamarz auribus venti auscultare debet"
  ],
  "ragged cap": [
    "Pestasus Fragosus"
  ],
  "how could bears run you out of business": [
    "Quomodo possunt ursi te e negotio tuo exigere"
  ],
  "assur": [
    "Assur"
  ],
  "wooden bar stool": [
    "Sellula Lignea"
  ],
  "ebony bow of expelling": [
    "Arcus Ebeni Expellendi"
  ],
  "it will be done": [
    "Factum erit"
  ],
  "what do you mean, i'm not skilled enough": [
    "Cur dicis me non satis artium habere"
  ],
  "steel war axe of frost": [
    "Bellisecuris Adamanteus Gelidus"
  ],
  "i want my freedom": [
    "Libertatem meam volo"
  ],
  "pheasant": [
    "Fasianus"
  ],
  "dwarven warhammer of binding": [
    "Dvemeris Bellimalleus Deligandi"
  ],
  "what were you two arguing about": [
    "De qua argumentamini"
  ],
  "i've seen whole packs of dogs": [
    "Greges canum vidi"
  ],
  "dragonscale helmet": [
    "Draconisquamata Galea"
  ],
  "what sort of dog": [
    "Qualis canis"
  ],
  "wolf armor": [
    "Lupi Lorica"
  ],
  "the guard at the gate mentioned a dog": [
    "Custos ad portam canem commemoravit"
  ],
  "guard at the gate mentioned a dog": [
    "Custos ad portam canem commemoravit"
  ],
  "kyne's peace 2": [
    "Pax Kynis 2"
  ],
  "athis": [
    "Athis"
  ],
  "ebony helmet of eminent destruction": [
    "Ebeni Galea Eminentis Destructionis"
  ],
  "silda the unseen": [
    "Silda Invisa"
  ],
  "sure, i can catch him": [
    "Certe, eum capere possum"
  ],
  "tell me about deekus": [
    "Dic mihi de Deeko"
  ],
  "skjor": [
    "Skjor"
  ],
  "improved nimble fingers": [
    "Improved Nimble Fingers"
  ],
  "who's the best blacksmith in skyrim": [
    "Quis est ferrarius optimus in Caelifine"
  ],
  "exactly what kind of help do you need": [
    "Quem modum auxilii quaeris"
  ],
  "tell me about the agent of strife": [
    "Dic mihi de Agente Duellionis"
  ],
  "unknown potion": [
    "Inscita Potio"
  ],
  "you pay those argonians fairly, or else": [
    "Manupretia Aequa Argoniis da, vel"
  ],
  "nimble fingers": [
    "Nimble Fingers"
  ],
  "iron mace of ice": [
    "Ferri Maca Gelidus"
  ],
  "varieties of daedra": [
    "Varietas Daedrarum"
  ],
  "elven greatsword of dread": [
    "Spatha Alfa Timoris"
  ],
  "to get the maximum range and damage with a bow, hold down attack until the bow is fully drawn": [
    "Ut maximam distantiam et vulnus arcu habeas, tendere Arcum ad tensum maximum"
  ],
  "get the maximum range and damage with a bow, hold down attack until the bow is fully drawn": [
    "Ut maximam distantiam et vulnus arcu habeas, tendere Arcum ad tensum maximum"
  ],
  "someone has to be in charge, though": [
    "Sed aliquis regere debet"
  ],
  "dwarven mace of weariness": [
    "Dvemeris Maca Maioris Defatigationis"
  ],
  "stay here": [
    "Hic mane"
  ],
  "elven warhammer of expelling": [
    "Alfus Bellimaleus Expellendi"
  ],
  "vaermina devotee": [
    "Fautor Morphei"
  ],
  "imperial sword": [
    "Gladius Romanus"
  ],
  "tell me how i got here and i'll pay for the damages": [
    "Dic mihi quomodo hic adveni et debita solvam"
  ],
  "bardscollegedrumhello": [
    "BardsCollegeDrumHello"
  ],
  "what sort of items": [
    "Quales res"
  ],
  "i already know it, and already know how to use it": [
    "Id iam scio, et eo uti quoque scio"
  ],
  "clothes iron": [
    "Ferrum Vestimentorum"
  ],
  "key to castle dour tower": [
    "Clavis ad Turrem Castelli Duris"
  ],
  "warehouse bookshelves": [
    "Horrei Libraria"
  ],
  "why would i ridicule you": [
    "Cur te deludam"
  ],
  "hod frodnar": [
    "Hod Frodnar"
  ],
  "bjorlam": [
    "Bjorlam"
  ],
  "sanctified steel mace": [
    "Sanctificata Maca Adamantea"
  ],
  "elven dagger of the blaze": [
    "Pugio Alfus Congflagrantis"
  ],
  "hawk feathers": [
    "Pinnae Accipitris"
  ],
  "iron dagger of sapping": [
    "Pugio Ferri Hauriendi"
  ],
  "femalebrowshuman04": [
    "FemaleBrowsHuman04"
  ],
  "i'm looking for an old guy, probably hiding out in the ratway": [
    "Senem quaero, probabiliter in Via Murum latentem"
  ],
  "drugged": [
    "Drugged"
  ],
  "find 2 flawless sapphires for madesi (<global=ffr18gemcount>/<global=ffr18gemtotal>)": [
    "Inveni II Sapphiras Perfectas pro Madesi (<Global=FFR18GemCount>/<Global=FFR18GemTotal>)"
  ],
  "wylandriah's satchel": [
    "Cibisis Vylandriae"
  ],
  "kill gaius maro": [
    "Neca Gaium Maronem"
  ],
  "miner's barracks": [
    "Metallumris Tabernacula"
  ],
  "the lock has been broken": [
    "Clausura fracta est"
  ],
  "lock has been broken": [
    "Clausura fracta est"
  ],
  "whiterun bannered mare convo system 2": [
    "Whiterun Bannered Mare Convo System 2"
  ],
  "how did the fire start": [
    "Quomodo coepit ignis"
  ],
  "solution of health": [
    "Solutio Salutis"
  ],
  "(cut the captive's bonds)": [
    "(Scinde vincula)"
  ],
  "what does the augur have to do with this": [
    "Quid agitur Augur cum hoc"
  ],
  "faralda's notes": [
    "Adnotata Faraldae"
  ],
  "it was a dragon": [
    "Draco erat",
    "Draco fuit"
  ],
  "hadvar will tell you the same thing": [
    "Hadvar tibi eandem rem narret"
  ],
  "leather bracers of minor deft hands": [
    "Corii Chirothecae Minoris Dexteritatis"
  ],
  "dwarven greatsword of freezing": [
    "Dvemeris Spatha Gelidus"
  ],
  "what if this letter were made public": [
    "Quid accidet si epistula publica facta erit"
  ],
  "scroll of fireball": [
    "Codex Ignipilae"
  ],
  "what if this were made public": [
    "Quid accidat si hi elucidentur"
  ],
  "all right, it's a deal": [
    "Bene, consentior"
  ],
  "where can i find this shipment": [
    "Ubi hanc mercem invenire possum"
  ],
  "the rear guard": [
    "Triarii"
  ],
  "rear guard": [
    "Triarii"
  ],
  "dwarven warhammer of flames": [
    "Dvemeris Bellimalleus Flammarum"
  ],
  "master conjurer": [
    "Vocator Dominus"
  ],
  "benor": [
    "Benor"
  ],
  "stormcloak courier": [
    "Sturmmantelorum Nuntius"
  ],
  "okay, i'll leave you alone": [
    "Ok, te relinquam"
  ],
  "what if i talk to <alias.shortname=target>": [
    "Quidne <Alias.ShortName=Target> adloquar"
  ],
  "elven greatsword of leeching": [
    "Spatha Alfa Sanguisugae"
  ],
  "blue mage robes": [
    "Caeruleum Magi Peplum"
  ],
  "and what about something for me, right now": [
    "Et quidni aliquid mihi, nunc ipse"
  ],
  "dragonplate gauntlets of peerless alchemy": [
    "Draconilaminatae Manicae Optimae Alchemiae"
  ],
  "tg09nddeaths": [
    "TG09NDDeaths"
  ],
  "iron mace of fear": [
    "Ferri Maca Timoris"
  ],
  "kill the hagraven petra": [
    "Corvisagam Petram Neca"
  ],
  "imedhnain": [
    "Imedhnain"
  ],
  "first planting": [
    "Primum Spargitum"
  ],
  "are you the high queen of skyrim": [
    "Esne Regina Alta Caelifinis"
  ],
  "i don't have a shiv": [
    "Astulam non habeo"
  ],
  "i don't see any bodies here": [
    "Nulla corpora hic video"
  ],
  "ragged robes": [
    "Peplum Fragosum"
  ],
  "tg09ndcombattonormal": [
    "TG09NDCombatToNormal"
  ],
  "ebony warhammer": [
    "Bellimalleus Ebeni"
  ],
  "dialoguemarkarthtreasuryhouserhiadathonarscene01": [
    "DialogueMarkarthTreasuryHouseRhiadaThonarScene01"
  ],
  "dwarven warhammer of winnowing": [
    "Dvemeris Bellimalleus Ventagii"
  ],
  "the letter explains it all": [
    "Epistula omnia explicat"
  ],
  "letter explains it all": [
    "Epistula omnia explicat"
  ],
  "the amulet is for expenses": [
    "Monile est pro impensis"
  ],
  "amulet is for expenses": [
    "Monile est pro impensis"
  ],
  "dragon vs giant": [
    "Dragon vs Giant"
  ],
  "dialoguemarkarthinnendonfamilyscene01": [
    "DialogueMarkarthInnEndonFamiliaeScene01"
  ],
  "daedric war axe of nullifying": [
    "Bellisecuris Daedricus Nullificandi"
  ],
  "glass war axe of enervating": [
    "Bellisecuris Vitreus Enervationis"
  ],
  "volendrung": [
    "Volendrung"
  ],
  "ebony greatsword of blizzards": [
    "Spatha Ebeni Hiemis"
  ],
  "soul gem fragment": [
    "Gemmanimus Fragmentum"
  ],
  "what's the big deal about this dagger": [
    "Quid de hoc pugione tibi interest"
  ],
  "glass helmet of major conjuring": [
    "Galea Vitrea Maioris Vocandi"
  ],
  "i pulled an amulet off the wall": [
    "Monile e pariete removi"
  ],
  "elven warhammer of thunderbolts": [
    "Alfus Bellimaleus Fulminum"
  ],
  "hoarfrost grotto": [
    "Rimae Specus"
  ],
  "tg09ndattacks": [
    "TG09NDAttacks"
  ],
  "iron armor of minor health": [
    "Ferrata Lorica Minoris Salutis"
  ],
  "i think i found the mage from your vision": [
    "Credo me magum e visu tuo invenisse"
  ],
  "is it true that the companions are werewolves": [
    "Esne verum Contubernales Versipellos esse"
  ],
  "the beginning of what": [
    "Principium cuius"
  ],
  "beginning of what": [
    "Principium cuius"
  ],
  "steel boots of dwindling flames": [
    "Caligae Adamanteae Flammarum Diminuentium"
  ],
  "do you think mercer knows we're here": [
    "Putasne Mercer nos hic esse scire"
  ],
  "ivarstead guard": [
    "Custos Ivarstead"
  ],
  "potion of alteration": [
    "Potio Mutationis"
  ],
  "what are the eyes of the falmer": [
    "Qui sunt Oculi Falmerium"
  ],
  "shearpoint": [
    "Shearpoint"
  ],
  "dragonplate boots of fire abatement": [
    "Draconilaminata Caligae Resistendi Ignis"
  ],
  "anise's cabin": [
    "Anise's Cabin"
  ],
  "so the eyes of the falmer are gems": [
    "Ergo Oculi Falmeres gemmae sunt"
  ],
  "thieves guild post quest handler": [
    "Sodalitatis Furum Post Quest Handler"
  ],
  "what can you tell me about the jarl": [
    "Quid de Comite mihi narrare potes"
  ],
  "added": [
    "Additus"
  ],
  "bandit's journal": [
    "Ephemeris Latronis"
  ],
  "is he loyal to the empire": [
    "Estne fidelis Imperio"
  ],
  "you support the stormcloaks, then": [
    "Ergo Sturmmantelis faves"
  ],
  "revenant": [
    "Revenant"
  ],
  "beehive": [
    "Alvus"
  ],
  "then i'll take it off your corpse": [
    "Ergo e cadaveri tuo eripiam"
  ],
  "why aren't there many nords at the college": [
    "Cur non sunt multi Germani in Collegio"
  ],
  "dushnikh mine": [
    "Dushnikh Metallum"
  ],
  "who are the alik'r": [
    "Qui sunt Alik'res"
  ],
  "why were you being executed": [
    "Cur sub poenas tantas accepisti"
  ],
  "ebony sword of annihilating": [
    "Gladius Ebeni Annihilationis"
  ],
  "marksmaleargonianscar03": [
    "MarksMaleArgonianScar03"
  ],
  "ulfric killed the high king": [
    "Vercingetorix Regem Altum necavit"
  ],
  "i'll get right on it": [
    "Id nunc faciam"
  ],
  "hide bracers of minor wielding": [
    "Chirothecae Cutis Minoris Tenendi"
  ],
  "zul": [
    "Zul"
  ],
  "bloodlet cage key": [
    "Clavis ad Carcerem Exsanguinationis"
  ],
  "what about my reward": [
    "Quid de praemio meo"
  ],
  "winterhold inn initial scene": [
    "Hiemiterrae Inn Initial Scene"
  ],
  "exciting": [
    "Excitatus"
  ],
  "i felt the rush of blood... and smiled": [
    "Fluxum sanguinis sensis... et subrisi"
  ],
  "commander maro": [
    "Imperator Maro",
    "Praefectus Maro"
  ],
  "sorry, i wish i could help you": [
    "Ignosco, utinam te adiuvare possim"
  ],
  "arondil's journal, part 3": [
    "Ephemeris Arondilis, Pars III"
  ],
  "dragonscale shield of extreme blocking": [
    "Scutum Draconisquamatum Extremis Interponendi"
  ],
  "you're corrupt": [
    "Corruptus es"
  ],
  "thonar is paying you": [
    "Thonar tibi emercitur"
  ],
  "hallowed dwarven bow": [
    "Consacratus Arcus Dvemeris"
  ],
  "loose grate": [
    "Clathrum"
  ],
  "obtain a deathbell flower": [
    "Obtine Tintinabulamortem"
  ],
  "valtheim keep": [
    "Valtheim Keep"
  ],
  "glass war axe of malediction": [
    "Bellisecuris Vitreus Maledictionis"
  ],
  "you seem to be well acquainted with wealth": [
    "Mihi videtur te multum de divitiis scire"
  ],
  "the guild should possess this item": [
    "Sodalitas hoc possidere debet"
  ],
  "guild should possess this item": [
    "Sodalitas hoc possidere debet"
  ],
  "i need to see madanach": [
    "Madanachum videre volo"
  ],
  "hairlinefemaleorc01": [
    "HairLineFemaleOrc01"
  ],
  "here's your shiv": [
    "Hic habes astulam"
  ],
  "how about i fight you for it": [
    "Quidni te pro illo pugnem"
  ],
  "it's about time you asked": [
    "Tempus est ut rogares"
  ],
  "i'll pay the toll later": [
    "Impensum postea pecuniam solvam"
  ],
  "elven warhammer of winnowing": [
    "Alfus Bellimaleus Ventagii"
  ],
  "tailor": [
    "Tailor"
  ],
  "do you think we stand a chance against mercer": [
    "Putasne nos contra Mercer pugnare posse"
  ],
  "i don't feel stronger": [
    "Fortior non sentio"
  ],
  "but she spoke to us": [
    "Sed illa nobis locuta est"
  ],
  "mouse sensitivity": [
    "Sensibilitas Muris"
  ],
  "i'm here to join your fight against the empire": [
    "Hic sum ut pugnam contra Imperium adiungam"
  ],
  "hide helmet of major alteration": [
    "Cutis Galea Maioris Mutationis"
  ],
  "the fort is yours": [
    "Castellum tuum est"
  ],
  "fort is yours": [
    "Castellum tuum est"
  ],
  "hide helmet of archery": [
    "Cutis Galea Saggitationis"
  ],
  "may i ever return to nightingale hall": [
    "Umquamne ad Atrium Philomelia revenire possum"
  ],
  "sing me a song, bard": [
    "Cane mihi carmen, cantator"
  ],
  "a song of fear, and death": [
    "Carmen Timoris, Mortisque"
  ],
  "song of fear, and death": [
    "Carmen Timoris, Mortisque"
  ],
  "time to die, traitor": [
    "Tempus moriendi est, perditor"
  ],
  "how does that help us": [
    "Quomodo haec omnia nos adiuvant"
  ],
  "iron sword of souls": [
    "Gladius Ferri Animorum"
  ],
  "antecedants of dwemer law": [
    "Antecedentes Legum Dvemerium"
  ],
  "nazeem": [
    "Nazeem"
  ],
  "vile vapors": [
    "Vile Vapors"
  ],
  "dragonplate shield of frost suppression": [
    "Draconilaminatum Scutum Contra Glaciem"
  ],
  "what were the glory days like": [
    "Quomodo erant dies gloriosi"
  ],
  "ancient shrouded armor": [
    "Antiquus Umbratus Lorica"
  ],
  "olava the feeble's house": [
    "Domus Olavae Debilis"
  ],
  "iron war axe of fatigue": [
    "Bellisecuris Ferri Defatigationis"
  ],
  "ebony bow of the inferno": [
    "Arcus Ebeni Conflagrationis"
  ],
  "2920, hearth fire, v9": [
    "[MMCMXX] 2920, Foci Ignis, V.IX"
  ],
  "who is the night mother": [
    "Quis est Mater Noctis"
  ],
  "essence extractor": [
    "Extractor Essentiae"
  ],
  "tell me more about the great collapse": [
    "Dic mihi plus de Lapso Magno"
  ],
  "domnamagia": [
    "DomnaMagia"
  ],
  "idgrod the younger": [
    "Idgrod Iuvenior"
  ],
  "what's not allowed here": [
    "Quid hic non licet"
  ],
  "what if i do": [
    "Et quid si id faciam"
  ],
  "jon battle-born": [
    "Johannis Proeli-Natus"
  ],
  "hallowed ebony sword": [
    "Consacratus Gladius Ebeni"
  ],
  "two-handed trainer": [
    "Two-Handed Trainer"
  ],
  "you killed eltrys": [
    "Eltrys necavisti"
  ],
  "ralof will tell you the same thing": [
    "Ralof tibi eandem rem narrabit"
  ],
  "she's dead, but i found this": [
    "Illa mortua est, sed hoc inveni"
  ],
  "that dragon flew off this way": [
    "Draco ad hunc locum volavit"
  ],
  "you must have seen it": [
    "Certe eum vidisti"
  ],
  "join the stormcloak rebellion": [
    "Coniuge cum rebus novis Sturmmantelorum"
  ],
  "this has happened before": [
    "Antea accidit"
  ],
  "i'll put it to good use": [
    "Ad bonum usum id ponam"
  ],
  "watches-the-roots": [
    "Radices-Vidit"
  ],
  "any idea who would conspire with cicero": [
    "Scisne quis cum Cicerone coniurationem fecit"
  ],
  "imperial helmet of destruction": [
    "Romana Galea Destructionis"
  ],
  "is that some type of potion": [
    "Estne aliquae potio"
  ],
  "ruminations on the elder scrolls": [
    "Ruminationes de Codicibus Veterrimis"
  ],
  "femaleeyeshighelfyellow": [
    "FemaleEyesHighElfYellow"
  ],
  "bear pelt": [
    "Reno Ursae"
  ],
  "i could use some help": [
    "Auxilio uti possum"
  ],
  "east empire warden": [
    "Euri Imperii Constabilarius"
  ],
  "the giant at <alias=bountylocation> is dead. i'm here for the bounty": [
    "Gigans in <Alias=BountyLocation> mortuus est. Hic sum praemio"
  ],
  "giant at <alias=bountylocation> is dead. i'm here for the bounty": [
    "Gigans in <Alias=BountyLocation> mortuus est. Hic sum praemio"
  ],
  "maleeyeshumanblind": [
    "MaleEyesHumanBlind"
  ],
  "wait here a moment": [
    "Hic breviter expecta"
  ],
  "thieves guild variant hood": [
    "Sodalitatis Furum Capucium Var"
  ],
  "spell tome: flames": [
    "Liber Magicus: Flammae"
  ],
  "you were lovers": [
    "Amatores fuistis"
  ],
  "shavari's note": [
    "Epistula Shavari"
  ],
  "glass mace of enervating": [
    "Maca Vitrea Enervationis"
  ],
  "you have become addicted to %s": [
    "Addictus es %s"
  ],
  "speak to ingun black-briar": [
    "Adloquere Ingun Atri-Dumum"
  ],
  "key to garvey's room": [
    "Clavis ad Conclave Garveyis"
  ],
  "molag bal": [
    "Molag Bol"
  ],
  "who are the stormcloaks": [
    "Qui sunt Sturmmanteli"
  ],
  "why can't i go in": [
    "Quidni intrare possim"
  ],
  "ebony gauntlets of major smithing": [
    "Ebeni Manicae Maioris Faciendi"
  ],
  "steel mace of soul snares": [
    "Maca Adamantea Animicapti"
  ],
  "it was a mistake": [
    "Error erat"
  ],
  "can we start over": [
    "Possumusne iterum incipere"
  ],
  "dwarven mace of siphoning": [
    "Dvemeris Maca Siphonis"
  ],
  "is being drained": [
    "adsorbitur"
  ],
  "look out - you're about to step in your own shock rune": [
    "Cave - in Runo Electricitatis tuo gressurus es"
  ],
  "so, i'm no longer a vampire": [
    "Ergo, vampirus non iam sum"
  ],
  "i'll help": [
    "Auxilium dabo"
  ],
  "that isn't a very good reason": [
    "Ratio optima non est"
  ],
  "imperial armor of the major squire": [
    "Romana Lorica Maioris Armigeris"
  ],
  "steel gauntlets of major alchemy": [
    "Adamanteae Manicae Maioris Alchemiae"
  ],
  "dwarven helmet of conjuring": [
    "Dvemeris Galea Vocandi"
  ],
  "see wicrowd": [
    "See VICrovd"
  ],
  "blacksmith's draught": [
    "Ferrarii Decocta"
  ],
  "meeko": [
    "Meeko"
  ],
  "dialoguedawnstarironbreakerkarlbeitild02": [
    "DialogueDawnstarIronBreakerKarlBeitild02"
  ],
  "phantom form - 3": [
    "Phantom Form - 3"
  ],
  "i have rjorn's drum": [
    "Tympanum Rjornis habeo"
  ],
  "spell tome: hysteria": [
    "Liber Magicus: Hysteria"
  ],
  "i'm just doing what i'm asked": [
    "Solum facio quid rogor"
  ],
  "blessed steel battleaxe": [
    "Sanctificatus Bellisecuris Adamanteus"
  ],
  "orcish dagger of reaping": [
    "Orci Pugio Saturni"
  ],
  "campaign for the rift": [
    "Pugna pro Hiato"
  ],
  "frost mirriam": [
    "Myrra Glaciei"
  ],
  "i'll come back": [
    "Reveniam"
  ],
  "daedric mace of expelling": [
    "Maca Daedrica Expellendi"
  ],
  "glass mace of garnering": [
    "Maca Vitrea Horti"
  ],
  "this object is already in use by someone else": [
    "Aliquis iam hoc utitur"
  ],
  "that's quite a staff you have there": [
    "Rudem specialem illic habes"
  ],
  "brinehammer": [
    "Brinehammer"
  ],
  "hairmaleorc05": [
    "HairMaleOrc05"
  ],
  "what could you do, anyway": [
    "Quid nunc facere possis"
  ],
  "dreams aren't real": [
    "Somnia vera non sunt"
  ],
  "daedric warhammer of devouring": [
    "Bellimalleus Daedricus Consumendi"
  ],
  "note added: %s": [
    "Libellum Additum: %s"
  ],
  "convince <alias=target> to release the spiced wine shipment": [
    "Persuade <Alias=Target> ut Aromatiten per Oortam Transire Sinat"
  ],
  "powerattack": [
    "PowerAttack"
  ],
  "reverent steel greatsword": [
    "Reverens Spatha Adamantea"
  ],
  "cairine": [
    "Cairine"
  ],
  "that's a lot of work for such a little thing": [
    "Multus labor est rei tam parvae"
  ],
  "snow veil catacombs": [
    "Velaminis Ningis Catacumbae"
  ],
  "data\\textures\\interface\\shared\\missing_image.dds": [
    "Data\\Textures\\Interface\\Shared\\missing_image.dds"
  ],
  "novice hammer": [
    "Novice Hammer"
  ],
  "i have your gem": [
    "Gemmam tuam habeo"
  ],
  "elven boots of eminent sneaking": [
    "Caligae Alfae Maximi Repsendi"
  ],
  "grilled leeks": [
    "Porra Cocta"
  ],
  "i have the dagger you wanted": [
    "Pugionem quem voluisti habeo"
  ],
  "the atronach stone": [
    "Lapis Atronachi"
  ],
  "atronach stone": [
    "Lapis Atronachi"
  ],
  "report back to jarl balgruuf": [
    "Refer te ad Comitem Balgruuf"
  ],
  "eye shape": [
    "Forma Oculorum"
  ],
  "orcish battleaxe of burning": [
    "Orci Bellisecuris Cremandi"
  ],
  "horgeir": [
    "Horgeir"
  ],
  "grand plaza scene 31": [
    "Grand Plaza Scene 31"
  ],
  "serpent's spit": [
    "Sputum Serpentis"
  ],
  "dragonscale armor of the noble knight": [
    "Draconisquamata Lorica Noble Equitis"
  ],
  "what did you see": [
    "Quid vidisti"
  ],
  "i'll see to it personally": [
    "Ego ipse id faciam"
  ],
  "i've completed the burglary job": [
    "Munus \"burglary\" perfeci"
  ],
  "glass war axe of harrowing": [
    "Bellisecuris Vitreus Occationis"
  ],
  "i've completed the fishing job": [
    "Munus \"Piscandi\" perfeci"
  ],
  "perk impact stagger": [
    "Perk Impact Stagger"
  ],
  "i've completed the sweep job": [
    "Munus \"verendi\" perfeci"
  ],
  "i want to quit that burglary job": [
    "Nolo facere illum munus \"burglary\""
  ],
  "i want to quit that shill job": [
    "Nolo facere illum munus \"shill\""
  ],
  "gold ore vein": [
    "Venam Metallis Auri"
  ],
  "i want to quit that sweep job": [
    "Nolo facere illum munus \"sweep\""
  ],
  "elixir of the healer": [
    "Commixtum Doctoris"
  ],
  "so what should i wear to the blue palace": [
    "Ergo quid apud Aulam Caeruleam induere debeo"
  ],
  "what happened on the 30th of frostfall": [
    "Quid in XXX Gelidicasus accidit"
  ],
  "spell": [
    "Magica"
  ],
  "key to bits and pieces": [
    "Clavis ad Haec et Illa"
  ],
  "you want revenge on boethiah": [
    "Vis ultionem in Nemesin"
  ],
  "vigilants fighting skeletons": [
    "Vigilants fighting Skeletons"
  ],
  "you really think i should join up with ulfric stormcloak": [
    "Re vera putas opus mihi esse cum Vercingetorigi Temepstatamiculo adiungere"
  ],
  "malyn varen": [
    "Malyn Varen"
  ],
  "dialoguedawnstarwhitehallmadenabrina02": [
    "DialogueDawnstarWhiteHallMadenaBrina02"
  ],
  "tell me about her daughter": [
    "Dic mihi de filia eius"
  ],
  "that's an order, captain": [
    "Mandatum est, Centurio"
  ],
  "practice arrow": [
    "Sagitta Practicandi"
  ],
  "i found the torpor": [
    "Torporem inveni"
  ],
  "dragonscale helmet of extreme alchemy": [
    "Draconisquamata Galea Extremis Alchemiae"
  ],
  "maybe she'd feel safer with a personal bodyguard? i could do that": [
    "Fortasse cum stipatoribus tutiorem sentiat"
  ],
  "no, i'll find some other way through": [
    "Minime, aliam viam inveniam (aut faciam)"
  ],
  "i have a delivery from <alias.shortname=questgiver>": [
    "Cistam tibi habeo ab <Alias.ShortName=QuestGiver>"
  ],
  "<alias=steward> says there's a shipment of coin traveling to solitude": [
    "<Alias=Steward> dicit plaustrum plenum pecuniae in via ad Solitudinem"
  ],
  "the steward says there's a shipment of coin traveling to the capital": [
    "Dispensator dicit mercem aurei ad urbem pricipalem in via esse"
  ],
  "steward says there's a shipment of coin traveling to the capital": [
    "Dispensator dicit mercem aurei ad urbem pricipalem in via esse"
  ],
  "dragonplate helmet of waterbreathing": [
    "Draconilaminata Galea Respirandi Sub Aqua"
  ],
  "please stand by": [
    "EXSPECTA QUAESO"
  ],
  "learn the secret of alduin's wall": [
    "Disce secretum de Pariete Alduinis"
  ],
  "staff of the storm wall": [
    "Rudis Parietis Tempestatis"
  ],
  "safia is dead": [
    "Safia mortua est"
  ],
  "a book": [
    "Liber"
  ],
  "steel plate helmet of illusion": [
    "Laminata Galea Inlusionis"
  ],
  "i'm not sure": [
    "Nescio"
  ],
  "daedric armor of extreme health": [
    "Daedrica Lorica Extremis Salutis"
  ],
  "you want me to steal it": [
    "Visne me id furari"
  ],
  "orcish shield of major blocking": [
    "Orci Scutum Maioris Interponendi"
  ],
  "i was told someone at the college might be able to help me": [
    "Audivi someone at College might be able adiuvare me"
  ],
  "where was my room again": [
    "Ubi est cubiculum meum, iterum"
  ],
  "leather helmet of minor alteration": [
    "Cutis Galea Minoris Mutationis"
  ],
  "i need to find kematu": [
    "Opus est mihi Kematum invenire"
  ],
  "where is he hiding": [
    "Ubi latet"
  ],
  "i'm not your errand boy": [
    "Puer munerum tuus non sum"
  ],
  "the statue is mine": [
    "Statua est mea"
  ],
  "statue is mine": [
    "Statua est mea"
  ],
  "ornate drinking horn": [
    "Cornu Bibendi Ornatum"
  ],
  "let's wait a little longer": [
    "Paulum plus exspectemus"
  ],
  "we should wait until dark": [
    "Ad vesperem exspectare debemus"
  ],
  "someone hired the dark brotherhood to kill you, lurbuk": [
    "Aliquis Fraternitatem Obscura conduxit ut te necaret"
  ],
  "iron sword of fatigue": [
    "Gladius Ferri Defatigationis"
  ],
  "you deserved this": [
    "Hoc meritus es"
  ],
  "odfel's house": [
    "Odfelis Domus"
  ],
  "i gave the marks to haelga": [
    "Signa Haelgae dedi"
  ],
  "biography of barenziah, v3": [
    "Biographis Barenziae, III"
  ],
  "old orc": [
    "Orcus Vetus"
  ],
  "leather bracers of major sure grip": [
    "Corii Chirothecae Maioris Dexteritatis"
  ],
  "i don't believe you": [
    "Tibi non credo"
  ],
  "nocturnal guides me": [
    "Nocturnal me ducit"
  ],
  "i can take care of that for you": [
    "Id pro te curare possum"
  ],
  "the dreamstride": [
    "Somnambulantium"
  ],
  "dreamstride": [
    "Somnambulantium"
  ],
  "i don't know that spell": [
    "Hanc magicam nescio"
  ],
  "clearspring cave": [
    "Spelunca Claririvuli"
  ],
  "robber's gorge bandit faction": [
    "Robber's Gorge Bandit Faction"
  ],
  "ebony helmet of eminent illusion": [
    "Ebeni Galea Eminentis Inlusionis"
  ],
  "general tullius told me to report to you": [
    "Imperator Tullius mihi imperavit ut me ad te referrem"
  ],
  "steel mace of weariness": [
    "Maca Adamantea Maioris Defatigationis"
  ],
  "crosshair": [
    "Crosshair"
  ],
  "thane of winterhold": [
    "Decemvir Hiemiterrae"
  ],
  "you tend the graveyard": [
    "Coemeterium administras"
  ],
  "hairlinefemaleorc05": [
    "HairLineFemaleOrc05"
  ],
  "steel gauntlets of major smithing": [
    "Adamanteae Manicae Maioris Faciendi"
  ],
  "mehrunes dagon voice cell": [
    "Cronos Voice Cell"
  ],
  "he was just trying to get rid of me": [
    "Me removere temptavit"
  ],
  "you won't be the one to look foolish, i promise": [
    "Stultus non videris, promitto"
  ],
  "who are the synod": [
    "Qui sunt Synod"
  ],
  "iron dagger of fatigue": [
    "Pugio Ferri Defatigationis"
  ],
  "yes, i do hate the dark elves": [
    "Sic, atralfos in odio habeo"
  ],
  "no, i don't hate your people": [
    "Minime, tuos in odio non habeo"
  ],
  "who supplies your goods": [
    "Quis mercem tuam comparat"
  ],
  "ebony mace of dread": [
    "Maca Ebeni Timoris"
  ],
  "grosta": [
    "Grosta"
  ],
  "hairmaleelder6": [
    "HairMaleElder6"
  ],
  "humanbeard05": [
    "HumanBeard05"
  ],
  "will you submit, or do i need to banish you again": [
    "Mihi succumbes, debeone te iterum expellere"
  ],
  "iron sword of arcing": [
    "Gladius Ferri Electricitatis"
  ],
  "i can do this all day": [
    "Totum diem hoc facere possum"
  ],
  "vilkas sent me with his sword": [
    "Vilkas mihi misit cum gladio"
  ],
  "spell tome: harmony": [
    "Liber Magicus: Harmonia"
  ],
  "orichalcum ore vein": [
    "Venam Metallis Orichalci"
  ],
  "orcish bow of flames": [
    "Orci Arcus Flammarum"
  ],
  "wake me up when we arrive": [
    "Suscita me cum veniamus"
  ],
  "ebony mace of malediction": [
    "Maca Ebeni Maledictionis"
  ],
  "steel plate helmet of waterbreathing": [
    "Laminata Galea Respirandi Sub Aqua"
  ],
  "imperial bracers of minor archery": [
    "Chirothecae Romanae Minoris Saggitationis"
  ],
  "dirt, huh": [
    "Spurcamen, huh"
  ],
  "i'm not exactly clean myself... (persuade)": [
    "Ego quoque non omnino mundus sum... (Persuade)"
  ],
  "zaria": [
    "Zaria"
  ],
  "for the npcs who can use the load door": [
    "for the NPCs who can use the load door"
  ],
  "alduin regen": [
    "Alduin regen"
  ],
  "what's moon sugar": [
    "Quid est Saccharum Lunae"
  ],
  "so, how dangerous is it to cross her": [
    "Ergo, quam periculosus est eam vexare"
  ],
  "dialoguedushnikhyalhuntingscene01": [
    "DialogueDushnikhYalHuntingScene01"
  ],
  "where'd you learn to play": [
    "Ubi canere didicisti"
  ],
  "scroll of candlelight": [
    "Codex Lucis Candelae"
  ],
  "interface\\icons\\misc\\cursor.dds": [
    "Interface\\Icons\\Misc\\cursor.dds"
  ],
  "neither side is making much headway": [
    "Neutra factio multum posse facit"
  ],
  "what's the harm in talking to ulfric": [
    "Quid nocet in cum Vercingetorigi loquendo",
    "Quid nocebit si cum Vercingetorigi Loqueris"
  ],
  "a guard sent me": [
    "Custos mihi misit"
  ],
  "guard sent me": [
    "Custos mihi misit"
  ],
  "what if someone else got their hands on it": [
    "Quid faciamus si quis alius id capiat"
  ],
  "daedric warhammer of winnowing": [
    "Bellimalleus Daedricus Ventagii"
  ],
  "liberation of skyrim": [
    "Liberation of Skyrim"
  ],
  "where's madanach": [
    "Ubi est Madanach"
  ],
  "borkul the beast": [
    "Borkul Bestia"
  ],
  "orcish greatsword of shocks": [
    "Orci Spatha Electricitatis"
  ],
  "elven greatsword of expelling": [
    "Spatha Alfa Expellendi"
  ],
  "journeyman calcinator": [
    "Journeyman Calcinator"
  ],
  "use the saarthal amulet to escape the trap": [
    "Utere Monili Saarthalis ut Insidias Effugias"
  ],
  "eldergleam sanctuary": [
    "Eldergleam Sanctuary"
  ],
  "search the abandoned house": [
    "Investiga Domum Vacuam"
  ],
  "grand plaza scene 36": [
    "Grand Plaza Scene 36"
  ],
  "keys": [
    "CLAVES"
  ],
  "place the burial urn in yngol barrow": [
    "Pone Urnam Sepulturam in Pabillum Yngolis"
  ],
  "hallowed ebony war axe": [
    "Consacratus Bellisecuris Ebeni"
  ],
  "daedric war axe of enervating": [
    "Bellisecuris Daedricus Enervationis"
  ],
  "orcish greatsword of soul snares": [
    "Orci Spatha Animicapti"
  ],
  "purloined shadows": [
    "Umbrae Furtivae"
  ],
  "steel armor of the major knight": [
    "Adamantea Lorica Maioris Equitis"
  ],
  "when have words ever inspired feelings of love": [
    "Quando verba ad amorem umquam duxerunt"
  ],
  "dwarven sphere fx": [
    "Dwemeris Sphere FX"
  ],
  "fire storm": [
    "Fire Storm"
  ],
  "what exactly will i be doing in the field": [
    "Quid faciam in proelio"
  ],
  "i'll give you a fair price": [
    "Tibi pretium aequum dabo"
  ],
  "assaultnc": [
    "AssaultNC"
  ],
  "defend <alias=capital>": [
    "Defende <Alias=Capital>"
  ],
  "effects\\watersplash.nif": [
    "Effects\\waterSplash.NIF"
  ],
  "yes sir": [
    "Sic, domine"
  ],
  "does it bother you living underground": [
    "Paenitetne te sub terra vivere"
  ],
  "elven battleaxe of despair": [
    "Bellisecuris Alfus Desponsionis"
  ],
  "i've found a lost verse of the poetic edda": [
    "Versum Amissum Eddae Poeticae iam inveni"
  ],
  "thane of eastmarch": [
    "Decemvir Eurimarci"
  ],
  "i'd like to think about it": [
    "Volo de eo discere"
  ],
  "i wanted to know more about the temple of mara": [
    "Volo plus discere de templo Marae"
  ],
  "kodrir": [
    "Kodrir"
  ],
  "steel sword of cowardice": [
    "Gladius Adamanteus Timoris"
  ],
  "open the door": [
    "Aperi ianuam"
  ],
  "i'm a friend": [
    "Amicus sum"
  ],
  "you've having trouble with mulush": [
    "Difficultas cum Mulush habes"
  ],
  "dragonplate helmet of eminent magicka": [
    "Draconilaminata Galea Eminentis Magicae"
  ],
  "maleeyeshumangreenhazelleft": [
    "MaleEyesHumanGreenHazelLeft"
  ],
  "deadly aversion to magic": [
    "Letifer Aversio Magicae"
  ],
  "summon portal": [
    "Summon Portal"
  ],
  "i know what you mean about these old ruins": [
    "De his ruinis consentior"
  ],
  "fruit vendor": [
    "Fruit vendor"
  ],
  "what can you tell me about winterhold": [
    "Quid mihi de Hiemiterra narrare potes"
  ],
  "key to aeri's house": [
    "Clavis ad Domum Aeri"
  ],
  "you've been very cooperative": [
    "Comodissimus fuisti"
  ],
  "anise's letter": [
    "Epistula Anise"
  ],
  "hagraven claw": [
    "Ungues Corvisagae"
  ],
  "i'm looking for some balmora blue": [
    "Balmoram Caeruleam quaero"
  ],
  "femaleeyesdarkelfblind": [
    "FemaleEyesDarkElfBlind"
  ],
  "petty soul gem": [
    "Gemmanima Maxima"
  ],
  "sure, i'll take it": [
    "Certe, id emam"
  ],
  "(1,500 gold)": [
    "(MD auri)"
  ],
  "blessed iron sword": [
    "Sanctificatus Gladius Ferri"
  ],
  "champion's stance": [
    "Sta ut Vindex"
  ],
  "i wanted to ask you about shadr's debt": [
    "Volui te de aere alieno Shadris rogare"
  ],
  "i'd rather scout ahead": [
    "Milam explorare"
  ],
  "you started the coven of namira": [
    "Coepisti gregem Namirae"
  ],
  "what do you play": [
    "Quo canis"
  ],
  "merely five gold": [
    "Merely five gold"
  ],
  "i'd easily pay twice that to hear you play": [
    "I'd easily pay twice that to hear you play"
  ],
  "this store has seen better days, hasn't it": [
    "Haec taberna dies meliores vidit, nonne"
  ],
  "sounds reasonable": [
    "Mihi rationabilis videtur"
  ],
  "(5 gold)": [
    "(5 auri)",
    "(V auri)"
  ],
  "dwarven war axe of scorching": [
    "Dvemeris Bellisecuris Adurendi"
  ],
  "looks difficult to breach": [
    "Difficile apertu videtur"
  ],
  "perhaps another time": [
    "Fortasse alio tempore"
  ],
  "i see he's having a good influence on you": [
    "Video eum ad res meliores tibi saudere"
  ],
  "i'd like to hear another song": [
    "Aliud carmen audire volo"
  ],
  "what's making you so angry": [
    "Quid te tam iratum facit"
  ],
  "you seem quite happy": [
    "Laetissimus videris"
  ],
  "maleeyeshighelfyellowblindright": [
    "MaleEyesHighElfYellowBlindRight"
  ],
  "are you ready to submit": [
    "Esne paratus succumbere"
  ],
  "now that we've defeated the imperials, will there be peace": [
    "Romanis victis, eritne pax"
  ],
  "speak to nelkir": [
    "Adloquere Nelkir"
  ],
  "how long have you been training dogs": [
    "Quamdiu canes alumnavisti"
  ],
  "humanskindarkskin08": [
    "HumanSkinDarkSkin08"
  ],
  "voice of namira": [
    "Vox Namirae"
  ],
  "whiterun carlotta valentia's house faction": [
    "Whiterun Carlotta Valentia's House Faction"
  ],
  "what do you know about the dragons coming back": [
    "Quid de regressu draconum scis"
  ],
  "kayd": [
    "Kayd"
  ],
  "i talked to loreius": [
    "Loreium Adlocutus sum"
  ],
  "he's agreed to fix your wagon wheel": [
    "Consensit se rotam tuam reficere"
  ],
  "whiterun house gray-mane faction": [
    "Whiterun House Gray-Mane Faction"
  ],
  "gormlaith golden-hilt": [
    "Gormlaith Auri-Capulus"
  ],
  "who are you and what do you want with me": [
    "Quis es et quid mecum vis"
  ],
  "i need to rearrange your equipment": [
    "Opus est mihi res tuas dispensare"
  ],
  "i'll bring you that ale, old man": [
    "Tibi illum vinum feram, senex"
  ],
  "steel greatsword of embers": [
    "Spatha Adamantea Cinerum"
  ],
  "ogmund's house": [
    "Ogmund's House"
  ],
  "hairmalenord10": [
    "HairMaleNord10"
  ],
  "do i look like a courier to you": [
    "Tibine nuntius videor"
  ],
  "constance michel": [
    "Constance Michel"
  ],
  "what will happen to elisif now that ulfric has won the war": [
    "Quid Elisifi nunc accidet, quia Vercingetorix bellum vincit"
  ],
  "sorry, not interested": [
    "Ignosce, mihi non interest"
  ],
  "learn the \"clear skies\" shout": [
    "Disce Clamationem \"Caelum Amoenum\""
  ],
  "ebony greatsword of fire": [
    "Spatha Ebeni Ignis"
  ],
  "i would be honored to retrieve it": [
    "Honor magna mihi erit eam referre"
  ],
  "daedric war axe of petrifying": [
    "Bellisecuris Daedricus Lapidescendi"
  ],
  "arondil's journal, part 1": [
    "Ephemeris Arondilis, Pars I"
  ],
  "(<global=hdwindhelmliving> gold)": [
    "(<Global=HDWindhelmLiving> auri)"
  ],
  "imperial bracers of minor alchemy": [
    "Chirothecae Romanae Minoris Alchemiae"
  ],
  "elven warhammer of stunning": [
    "Alfus Bellimaleus Intermissionis"
  ],
  "i have a message from the greybeards": [
    "Nuntium e Canabarbis habeo"
  ],
  "resist disease and poison": [
    "Resiste Morbo et Veneno"
  ],
  "so what about my reward": [
    "Ergo, quid de praemio meo"
  ],
  "how are you enjoying the party": [
    "Fruerisne epula"
  ],
  "i got you the dragonstone": [
    "Tibi Draconilapidem habeo"
  ],
  "what next": [
    "Quid proximum"
  ],
  "maramal": [
    "Maramal"
  ],
  "iron shield of major blocking": [
    "Ferri Scutum Maioris Interponendi"
  ],
  "grete": [
    "Grete"
  ],
  "i'll come back later": [
    "Iterum reveniam"
  ],
  "how's the guild doing": [
    "Quid agit cum Sodalitate"
  ],
  "steel dagger of dismay": [
    "Pugio Adamanteus Timoris"
  ],
  "refined malachite": [
    "Malachita Depurata"
  ],
  "what's the word around town": [
    "Quae sunt fabulationes hac in urbe"
  ],
  "why name your store grave concoctions": [
    "Cur nomen 'Liquamenta Sepulturarum' habet"
  ],
  "pots and pans": [
    "Ollae Coctoriaque"
  ],
  "where are the guards": [
    "Ubi sunt custodes"
  ],
  "dialoguemorkhazgurlaraksharamphscene01": [
    "DialogueMorKhazgurLarakSharamphScene01"
  ],
  "i'll take the tour": [
    "Peregrinum faciam"
  ],
  "(2 gold)": [
    "(II auri)"
  ],
  "azura": [
    "Azuram"
  ],
  "talk to the long-dead lover": [
    "Longe-mortuum Amatorum Adloquere"
  ],
  "a shiv": [
    "Astula"
  ],
  "shiv": [
    "Astula"
  ],
  "broken tower prison key": [
    "Clavis ad Carcerem Turris Fracti"
  ],
  "orcish battleaxe of devouring": [
    "Orci Bellisecuris Consumendi"
  ],
  "maluril": [
    "Maluril"
  ],
  "of course you're not invisible": [
    "Certe invisibilis non es"
  ],
  "go bother someone else": [
    "Alium molesta"
  ],
  "i would kill him before he drew his sword": [
    "Eum necam ante gladium stringat"
  ],
  "blades sword": [
    "Gladius Ensium"
  ],
  "klimmek's supplies": [
    "Bona Klimmekis"
  ],
  "i hear you have some skooma": [
    "Audivi te aliam skoomam habere"
  ],
  "i need some. badly. (persuade)": [
    "Egeo ea. Valde. (Persuade)"
  ],
  "cave bear pelt": [
    "Ursi Speluncae Renonis"
  ],
  "i've been sent by karliah": [
    "Missus sum a Karlia"
  ],
  "i have the key": [
    "Clavem habeo"
  ],
  "why did you choose me": [
    "Cur me elegisti"
  ],
  "i know where she is, but i'm not telling you": [
    "Scio ubi ea sit, sed tibi non narrabo"
  ],
  "steel war axe of fear": [
    "Bellisecuris Adamanteus Timoris"
  ],
  "turn lod in to kust": [
    "Muta Lod in Kust"
  ],
  "it's been a pleasure": [
    "Mihi placuit te videre"
  ],
  "complaint letter": [
    "Epistula Querela"
  ],
  "bee and barb scene 13": [
    "Bee et Barb Scene 13"
  ],
  "do what now": [
    "Quid faciamus"
  ],
  "how do i do that": [
    "Quommodo illud faciam"
  ],
  "if you need a sigil stone, why aren't you doing this": [
    "Si lapis sigillum vis, cur hoc non facis"
  ],
  "you will do as i say": [
    "Facias ut dicam"
  ],
  "spell tome: dread zombie": [
    "Liber Magicus: Amortuus Timorisfer"
  ],
  "the imperials were about to execute ulfric stormcloak": [
    "Imperiales necaturi erant Vercingetorix Temepstatamiculus"
  ],
  "imperials were about to execute ulfric stormcloak": [
    "Imperiales necaturi erant Vercingetorix Temepstatamiculus"
  ],
  "then the dragon attacked": [
    "Deinde draco oppugnavit"
  ],
  "glass battleaxe of fear": [
    "Bellisecuris Vitreus Timoris"
  ],
  "i done got thaned": [
    "Decemvir factus sum"
  ],
  "commander caius": [
    "Praefectus Caius"
  ],
  "steel battleaxe of embers": [
    "Bellisecuris Adamanteus Cinerum"
  ],
  "glass boots of dwindling frost": [
    "Vitreae Caligae Minoris Congelationis"
  ],
  "contract: kill ennodius papius": [
    "Munus: Neca Ennodius Papius"
  ],
  "skill trainer": [
    "Skill Trainer"
  ],
  "do you trust them": [
    "Eisne confidis"
  ],
  "- conditioned not to start - haunting": [
    "- CONDITIONED NOT TO START - Haunting"
  ],
  "what can you tell me about mara": [
    "Quid mihi narrare potes de Mara"
  ],
  "investigate the bards college": [
    "Investigate Collegium Bardorum"
  ],
  "improvement": [
    "Maius Facere"
  ],
  "i found this poster in hjerim": [
    "Hanc picturam inveni in Hjerim"
  ],
  "orcish bow of diminishing": [
    "Orci Arcus Diminuendi"
  ],
  "what did you say you were missing": [
    "Quid amisisti"
  ],
  "defeat the guardian sabre cat": [
    "Vince Felem Mucronis Custodem"
  ],
  "what happened to nurelion": [
    "Quid Nurelioni accidit"
  ],
  "bring the jazbay to avrusa sarethi": [
    "Fer Jazbay ad Avrusam Sarethi"
  ],
  "imperial boots of waning frost": [
    "Caligae Romanae Insenescentis Congelationis"
  ],
  "iron greatsword of fear": [
    "Spatha Ferri Timoris"
  ],
  "bodil": [
    "Bodil"
  ],
  "salt pile": [
    "Cumulum Salis"
  ],
  "we'll be seeing you soon": [
    "In proximum"
  ],
  "who are the companions": [
    "Qui sunt Contubernales"
  ],
  "poison spray": [
    "Aerosolum Veneni"
  ],
  "ebony war axe of immobilizing": [
    "Bellisecuris Ebeni Immobilitatis"
  ],
  "spiced beef": [
    "Carnis Aromaticus"
  ],
  "vex": [
    "Vex"
  ],
  "scroll of calm": [
    "Codex Molliorem Facere"
  ],
  "sibbi's stash key": [
    "Clavis Gazae Sibbi"
  ],
  "thonjolf": [
    "Thonjolf"
  ],
  "you meditate on the words": [
    "In verba meditaris"
  ],
  "afflicted refugee": [
    "Afflictus Refugitus"
  ],
  "dragonscale gauntlets of eminent sure grip": [
    "Draconisquamatae Manicae Eminentis Ambidexteritatis"
  ],
  "hallowed daedric mace": [
    "Consacrata Maca Daedrica"
  ],
  "i am ready to prepare the grand feast": [
    "Paratus sum epulas magnas coquere"
  ],
  "large antlers": [
    "Cornua Magna"
  ],
  "look, i'm willing to pay": [
    "Agedum, volens sum pretium solvere"
  ],
  "captain valmir": [
    "Centurio Valmir"
  ],
  "what sorts of things": [
    "Quales res"
  ],
  "morndas": [
    "Die Lunae"
  ],
  "maybe some gold will help": [
    "Fortasse aurum adiuvabit"
  ],
  "imperial light shield of dwindling shock": [
    "Romana Parma Electricitatis Diminuentis"
  ],
  "t03sharedinfo": [
    "T03SharedInfo"
  ],
  "he has a house in riften": [
    "Domum Hiati habet"
  ],
  "the rumors are all true": [
    "Rumores veri sunt"
  ],
  "rumors are all true": [
    "Rumores veri sunt"
  ],
  "faralda": [
    "Faralda"
  ],
  "your thanks isn't enough": [
    "Gratiae tuae satis non sunt"
  ],
  "i think i'll be keeping this": [
    "Credo me hoc retenturum esse"
  ],
  "what's it like being a werewolf": [
    "Qualis est vita ut versipellis"
  ],
  "good, it's about time i met her": [
    "Bene, fere tempus esset ut eam convenirem"
  ],
  "velehk sain's treasure map": [
    "Gazae Charta Velehk Sainis"
  ],
  "are you a fisherman": [
    "Esne piscator"
  ],
  "sultry maiden": [
    "Virgo Pulchra"
  ],
  "favorites": [
    "Favorites",
    "ELECTISSIMA"
  ],
  "i know you": [
    "Te novi"
  ],
  "indaryn": [
    "Indaryn"
  ],
  "glass helmet of waterbreathing": [
    "Galea Vitrea Respirandi Sub Aqua"
  ],
  "that's a terrible story": [
    "Fabula horribile auditu est"
  ],
  "daedric war axe of expelling": [
    "Bellisecuris Daedricus Expellendi"
  ],
  "steel dagger of draining": [
    "Pugio Adamanteus Hauriendi"
  ],
  "movarth's boots": [
    "Movarthis Caligae"
  ],
  "y4": [
    "Y4"
  ],
  "daedric warhammer of banishing": [
    "Bellimalleus Daedricus Expellendi"
  ],
  "db01francoisgrelodbranchtopic": [
    "DB01FrancoisGrelodBranchTopic"
  ],
  "abandoned shack key": [
    "Clavis ad Casam Desertam"
  ],
  "orcish helmet of major conjuring": [
    "Orci Galea Maioris Vocandi"
  ],
  "you said you saw a ghost": [
    "Dixistine te phantasmam vidisse"
  ],
  "coral dragon claw": [
    "Ungues Draconis Coralii"
  ],
  "2920, sun's height, v7": [
    "[MMCMXX] 2920, Apex Solis, V. VII"
  ],
  "shock rune": [
    "Runus Electricitatis"
  ],
  "sorry, i can't help you with that": [
    "Ignosce, cum eo te adiuvare non possum"
  ],
  "this is important. it's about the dragons": [
    "Magni momenti est. De draconibus agitur"
  ],
  "i delivered the supplies to high hrothgar": [
    "Copiam ad Altum Hrothgarem attuli"
  ],
  "anything i should watch out for during the climb": [
    "Estne quid cavendum dum ascendam"
  ],
  "what if i invest in their future": [
    "Quidni in futurum eorum faenerabor"
  ],
  "i've brought all the blood you require": [
    "Tibi habeo totam sanguinem quem quaesivisti"
  ],
  "virtuous ebony mace": [
    "Virtutis Maca Ebeni"
  ],
  "rumors and ramblings should be ignored": [
    "Rumores et insanitas ignorari debent"
  ],
  "do you get a lot of business": [
    "Accipisne multum negotium"
  ],
  "key to falion's house": [
    "Clavis ad Domum Falionis"
  ],
  "enmon's house": [
    "Enmonis House"
  ],
  "horror of castle xyr": [
    "Atrocitas Castelli Xyris"
  ],
  "how do you take your mind off something like that": [
    "Quomod mentem tuam ab re huius modi removes"
  ],
  "what can you tell me about the inn": [
    "Quid mihi dicere potes de diversorio"
  ],
  "yes, they're real": [
    "Sic, Veri sunt"
  ],
  "i've seen them": [
    "Eos iam vidi"
  ],
  "femaleeyeshumanbrightgreen": [
    "FemaleEyesHumanBrightGreen"
  ],
  "pinewatch bandit's sanctuary": [
    "Fanum Latronum Piniturrium"
  ],
  "geirmund's epitaph": [
    "Epitaphium Geirmundis"
  ],
  "grass pod": [
    "Folliculus Gramenis"
  ],
  "i'll donate my earnings to the cause": [
    "Impensa mea causae donabo"
  ],
  "who owns the trading caravans": [
    "Quis caravannas possidet"
  ],
  "glass warhammer of torpor": [
    "Bellimaleus Vitreus Torporis"
  ],
  "ulfric plans to attack whiterun": [
    "Vercingetorix vult oppugnare Albacursum"
  ],
  "the general wants to lend legion troops": [
    "Imperator milites Legionis ducere vult"
  ],
  "general wants to lend legion troops": [
    "Imperator milites Legionis ducere vult"
  ],
  "increased magicka regen": [
    "Increased Magicae Regen"
  ],
  "i've got what i came for": [
    "Habeo id quod volui"
  ],
  "i was given strict instructions": [
    "Mandata clara accepi"
  ],
  "for the jarl's eyes only": [
    "Sola oculis Comitis"
  ],
  "orcish greatsword of ice": [
    "Orci Spatha Gelidus"
  ],
  "medium": [
    "Medium",
    "Medius"
  ],
  "imperial light helmet of alchemy": [
    "Romana Cassis Alchemiae"
  ],
  "from who": [
    "A quo",
    "E quibus"
  ],
  "ebony warhammer of winnowing": [
    "Bellimalleus Ebeni Ventagii"
  ],
  "black soul gem": [
    "Gemmanima Nigra"
  ],
  "daedric armor of the noble knight": [
    "Daedrica Lorica Noble Equitis"
  ],
  "block trainer": [
    "Block Trainer"
  ],
  "hairmalenord16": [
    "HairMaleNord16"
  ],
  "shimmermist grotto": [
    "Stellanexus Specus"
  ],
  "what did you want, again": [
    "Iterum, quid voluisti"
  ],
  "used to control dialogueorchunters conversations": [
    "Used to control DialogueOrcHunters conversations"
  ],
  "mistveil keep": [
    "Velamenexus Castellum"
  ],
  "captain aquilius": [
    "Centurio Aquilius"
  ],
  "vampire's sight": [
    "Vampire's Sight"
  ],
  "are you an alchemist as well": [
    "Esne alchemista quoque"
  ],
  "safety should be more important than anything": [
    "Praesidium maximi momenti esse debet"
  ],
  "reset your control mappings to their default settings": [
    "Redde plectra ad originem"
  ],
  "rockjoint": [
    "Rockjoint"
  ],
  "the jarl of whiterun returns your axe": [
    "Comes Albacursus securem tuum reddit"
  ],
  "jarl of whiterun returns your axe": [
    "Comes Albacursus securem tuum reddit"
  ],
  "calixto corrium": [
    "Calixto Corrium"
  ],
  "2920, rain's hand, v4": [
    "[MMCMXX] 2920, Manus Rainis, V. IV"
  ],
  "what was that story about my fiancee": [
    "De qua agebatur fabula de sponsa mea"
  ],
  "daedric sword of winnowing": [
    "Gladius Daedricus Ventagii"
  ],
  "saerlund": [
    "Saerlund"
  ],
  "you will be stopped": [
    "Sisteris"
  ],
  "ulfric means war": [
    "Vercingetorix bellum vult"
  ],
  "daedric bow of immobilizing": [
    "Arcus Daedricus Immobilitatis"
  ],
  "i am serious": [
    "Non iocor"
  ],
  "it's yours if you want it": [
    "Tuus est, si vis"
  ],
  "retrieve wylandriah's spoon": [
    "Inveni Cochlearem Wylandriae"
  ],
  "powdered mammoth tusk": [
    "Pulvus Dentis Elephantis"
  ],
  "i have a better plan": [
    "Consilium melius habeo"
  ],
  "you wait here, and i'll take care of it": [
    "Hic exspecta et ego de eo curabo"
  ],
  "deal with your own curses": [
    "Tibi maledictiones tuae curandae sunt"
  ],
  "you were the one who executed that man": [
    "Tu illum hominem necavisti"
  ],
  "reverent orcish dagger": [
    "Reverens Pugio Orcorum"
  ],
  "hairmaleredguard5": [
    "HairMaleRedguard5"
  ],
  "who is malacath": [
    "Quis est Styx"
  ],
  "fellglow prisoner faction": [
    "Fellglow Prisoner Faction"
  ],
  "do the spirits haunt your town as well": [
    "Phantasmaene oppidum tuum inhaerent"
  ],
  "i could investigate for you": [
    "Tibi investigare possum"
  ],
  "sorry, i can't help you": [
    "Ignosce, te adiuvare non possum"
  ],
  "(pay <global=we17goldamount> gold)": [
    "(Pay <Global=WE17GoldAmount> Auri)"
  ],
  "thank you, my lady": [
    "Gratias, Domina"
  ],
  "did you know some alik'r warriors are looking for a redguard woman": [
    "Scivistine bellatores Alik'ris feminam Rubricustodem quaerant"
  ],
  "fort sungard oubliette": [
    "Oubliette Castelli Auroris"
  ],
  "veezara": [
    "Veezara"
  ],
  "i found this in shroud hearth barrow": [
    "Hoc inveni in Pabillo Foci Obscuri"
  ],
  "ilinalta's deluge": [
    "Ilinaltae Deluvium"
  ],
  "are you okay": [
    "Esne bene"
  ],
  "i'm not even sure what's going on": [
    "Nescio quid agatur"
  ],
  "what's wrong with you": [
    "Quid est difficultas cum te"
  ],
  "i need to find where alduin fled to": [
    "Opus est mihi scire quo effugeriut Alduinus"
  ],
  "lingering stamina poison": [
    "Commorans Venenum Staminae"
  ],
  "what's the story with narfi": [
    "Quid est fabula de Narfi"
  ],
  "you told narfi she's coming back": [
    "Narfi illam reventuram esse dixisti"
  ],
  "is this her necklace": [
    "Estne hoc Monile eius"
  ],
  "(give necklace)": [
    "(Da Monile)"
  ],
  "phantom": [
    "Phantasma"
  ],
  "reach hold guard": [
    "Custos Prehensi"
  ],
  "dark souls health bonus": [
    "Dark Souls Health Bonus"
  ],
  "steel ingot": [
    "Adamanteum Later"
  ],
  "i'd like to know more about the college's history": [
    "Velim plus de historia Collegii scire"
  ],
  "how much farther do i have to walk": [
    "Quanto plus mihi ambulandum est"
  ],
  "irnskar ironhand": [
    "Irnskar Ferrimanus"
  ],
  "steel dagger of chills": [
    "Pugio Adamanteus Frigidis"
  ],
  "iron warhammer": [
    "Bellimalleus Ferratus"
  ],
  "jaw height": [
    "Altitudo Mandibuli"
  ],
  "i've found those amethysts for you": [
    "Amethystas tibi inveni"
  ],
  "vilkas": [
    "Vilkas"
  ],
  "you having a bear problem": [
    "Habesne difficultatem cum ursis"
  ],
  "steel mace of scorching": [
    "Maca Adamantea Adurendi"
  ],
  "<missing name>": [
    "<Nomen Ignotum>"
  ],
  "i brought ten bear pelts, just like you asked": [
    "Tibi decem renones ursarum attuli, ut me rogavisti"
  ],
  "smugglers' note": [
    "Epistula Subintroductoris"
  ],
  "shouldn't be a problem": [
    "Problema non esse debet"
  ],
  "elven shield of dwindling frost": [
    "Alfum Scutum Minoris Congelationis"
  ],
  "malemouthkhajiit": [
    "MaleMouthKhajiit"
  ],
  "ebony mace of winter": [
    "Maca Ebeni Hiemis"
  ],
  "i think i made a mistake coming here": [
    "Credo me erravisse in hic veniendo"
  ],
  "wolf pelt": [
    "Lupi Reno"
  ],
  "why are you sad": [
    "Cur tristis es"
  ],
  "hairlinefemaleorc08": [
    "HairLineFemaleOrc08"
  ],
  "i have some information about svidi": [
    "Informatum tibi habeo de Svidi"
  ],
  "what will it cost to change your mind": [
    "Quot mihi constabit ut mentem tuam mutem"
  ],
  "(bribe)": [
    "(Largire)",
    "(Emercere)"
  ],
  "what's so important about finn's lute": [
    "Quid tam magni momenti de Cithara Finnis est"
  ],
  "ebony greatsword of banishing": [
    "Spatha Ebeni Expellendi"
  ],
  "what were you arguing about": [
    "De qua re concertabamini"
  ],
  "who's neck is that": [
    "Cuius collum est illud"
  ],
  "elven warhammer of ice": [
    "Alfus Bellimaleus Gelidus"
  ],
  "key to angeline's aromatics": [
    "Clavis ad Aromatica Angelinae"
  ],
  "dragonplate helmet of eminent destruction": [
    "Draconilaminata Galea Eminentis Destructionis"
  ],
  "hide helmet of minor magicka": [
    "Cutis Galea Minoris Magicae"
  ],
  "thalmor gloves": [
    "Visigotha Manubria"
  ],
  "participate in a drinking contest with sam guenvere": [
    "Fac Perpotationem Contra Samuelem Guenvere"
  ],
  "fralia gray-mane": [
    "Fralia Juba-Cana"
  ],
  "flawless garnet": [
    "Perfectum Spessartinum"
  ],
  "elven helmet of illusion": [
    "Alfa Galea Inlusionis"
  ],
  "bandits giving you trouble": [
    "Latrones te molestant"
  ],
  "so you're boethiah's champion, eh": [
    "Nonne tu es Vindex Boethis"
  ],
  "i could get your amulet back": [
    "Possum monile tuum referre"
  ],
  "orcish greatsword of absorption": [
    "Orci Spatha Absorbendi"
  ],
  "wedding dress": [
    "Stola Nuptualis"
  ],
  "i have your amulet": [
    "Monile tuum habeo"
  ],
  "elven battleaxe of malediction": [
    "Bellisecuris Alfus Maledictionis"
  ],
  "brunwulf's house key": [
    "Clavis ad Domum Brunwulfis"
  ],
  "gaius maro": [
    "Gaius Maro"
  ],
  "ghost axe": [
    "Securis Phantasma"
  ],
  "leather helmet of major alchemy": [
    "Cutis Galea Maioris Alchemiae"
  ],
  "olda wanted me to steal this <alias=questitem>": [
    "Olda mihi persuasit ut hoc furarem <Alias=QuestItem>"
  ],
  "ebony warhammer of harvesting": [
    "Bellimalleus Ebeni Saturni"
  ],
  "imperial armor of the minor squire": [
    "Romana Lorica Minoris Armiferis"
  ],
  "jaw forward": [
    "Mandibulum ad Frontem"
  ],
  "i'd sooner forget all of this": [
    "Malim horum oblivisci"
  ],
  "enter character name": [
    "Elige Nomen Tuum"
  ],
  "are you worried about what that petitioner said about wolfskull cave": [
    "Esne sollicitus de Spelunca Calvarialupi"
  ],
  "are you sure about this, boy": [
    "Esne certus, puer"
  ],
  "murdering this woman": [
    "Visne hanc feminam necari"
  ],
  "nurelion": [
    "Nurelion"
  ],
  "stone bed": [
    "Lectus Lapideus"
  ],
  "orcmalelipcolor01": [
    "OrcMaleLipColor01"
  ],
  "fire wizard": [
    "Thaumatorgos Ignis"
  ],
  "do you tend this graveyard alone": [
    "Curasne hoc necropolin solus"
  ],
  "is this place always so gloomy": [
    "Semperne tam obscura hic est"
  ],
  "key to cosnach's room": [
    "Clavis ad Conclave Cosnachis"
  ],
  "dwarven greatsword of despair": [
    "Dvemeris Spatha Desponsionis"
  ],
  "whiterun ulfberth's house faction": [
    "Whiterun Ulfberth's House Faction"
  ],
  "no, but i know how to find out": [
    "Minime, sed viam discendi scio"
  ],
  "i need an elder scroll": [
    "Modo Codice Veterrimo careo"
  ],
  "warpaintorange01": [
    "WarpaintOrange01"
  ],
  "hide helmet of major magicka": [
    "Cutis Galea Maioris Magicae"
  ],
  "sure, what do you need": [
    "Certe, quid vis"
  ],
  "i'm sorry to have troubled you": [
    "Me paenitet diffultatem tibi dedisse"
  ],
  "i need sap to fix the gildergreen": [
    "Volo olibanum ut Gildergreen servem"
  ],
  "you want me to go talk to ri'saad": [
    "Visne me Riaad adloqui"
  ],
  "iron warhammer of sapping": [
    "Bellimalleus Ferratus Hauriendi"
  ],
  "stormcloak documents": [
    "Documenta TemPestilentiaeatamiculorum"
  ],
  "hairlinefemalenordchild01": [
    "HairLineFemaleNordChild01"
  ],
  "elven dagger of expelling": [
    "Pugio Alfus Expellendi"
  ],
  "hide bracers of sure grip": [
    "Chirothecae Cutis Dexteritatis"
  ],
  "ancient nord greatsword of ice": [
    "Spatha Antiqua Germanica Gelidus"
  ],
  "do you know where i can find an elder scroll": [
    "Scisne ubi Codicem Veterrimum invenire possim"
  ],
  "come on, you can tell me": [
    "Age, mihi narrare potes"
  ],
  "we're all friends here": [
    "Nos hic omnes amici sumus"
  ],
  "so you want your staff back": [
    "Ergo rudem recipere vis"
  ],
  "imperial light shield of minor blocking": [
    "Romana Parma Minoris Interponendi"
  ],
  "hallowed orcish sword": [
    "Consacratus Gladius Orcorum"
  ],
  "i am the predator": [
    "Venator sum"
  ],
  "you are my prey": [
    "Raptum meum es"
  ],
  "kill one of the captives": [
    "Neca Unum ex Captivis"
  ],
  "king olaf's verse": [
    "Versus Regis Olafis"
  ],
  "daedric battleaxe of blizzards": [
    "Bellisecuris Daedricus Hiemis"
  ],
  "helvard is dead": [
    "Helvard mortuus est"
  ],
  "you must first equip a weapon to poison it": [
    "Primo cape armum ut venenum in eo ponas"
  ],
  "i have the stone tablet you wanted": [
    "Tabellum lapideum tibi habeo"
  ],
  "just a minute": [
    "Expecto"
  ],
  "i think i left it on the cart": [
    "Puto eum in raeda reliqui"
  ],
  "iron greatsword of sapping": [
    "Spatha Ferri Hauriendi"
  ],
  "remind me again about the attack on the fort": [
    "Mihi iterum de oppugnato contra castellum commemora"
  ],
  "sealed door": [
    "Porta Clausa"
  ],
  "dwarven warhammer of fear": [
    "Dvemeris Bellimalleus Timoris"
  ],
  "i met your brother": [
    "Fratri tuo occuri"
  ],
  "i'm ready for some more practice": [
    "Paratus sum pluri exercitio"
  ],
  "sorry, can't help you": [
    "Ignosce, te adiuvare non possum"
  ],
  "darkness returns": [
    "Obscuritas Reveniens"
  ],
  "elven dagger of lethargy": [
    "Pugio Alfus Lethargiae"
  ],
  "thank you, enthir": [
    "Gratias, Enthir"
  ],
  "thalmor headquarters": [
    "Visigotha Principia"
  ],
  "beggar": [
    "Rogator"
  ],
  "what if you didn't have to worry about an enemy attack": [
    "Quid facias si de impetu inimico sollicitari non debeas"
  ],
  "are you the only one that works at the bunkhouse": [
    "Esne solus qui apud Helgam laborat"
  ],
  "the staff of magnus": [
    "Rudis Magni"
  ],
  "return to olfrid battle-born": [
    "Redi ad Olfrid Proeli-Natum"
  ],
  "dialoguemarkarthkeepmoththongvorscene01": [
    "DialogueMarkarthKeepMothThongvorScene01"
  ],
  "report to <alias=cwfieldcosonswhiteruncamp>": [
    "Refer te ad <Alias=CWFieldCOSonsWhiterunCamp>"
  ],
  "steel warhammer of chills": [
    "Bellimalleus Adamanteus Frigidis"
  ],
  "verner's satchel": [
    "Verneris Cibisis"
  ],
  "iron gauntlets": [
    "Ferri Manicae"
  ],
  "scaled helmet of alteration": [
    "Galea Squamata Mutationis"
  ],
  "orcish greatsword of freezing": [
    "Orci Spatha Gelidus"
  ],
  "kodlak whitemane is the harbinger of the companions": [
    "Kodlak Jubacana Praenuntius est Contubernaliorum"
  ],
  "he does not give orders, but his word is highly respected both inside jorrvaskr and through all the nine holds": [
    "Mandata non dat sed ea quae dicit audiuntur in Jorrvaskri et per omnes novem urbes"
  ],
  "what else is there to be learned about destruction magic": [
    "Quid aliud discendum est de Magica Destructionis"
  ],
  "dunyngvildwizardattack": [
    "dunYngvildWizardAttack"
  ],
  "pactur asked me to deliver a message": [
    "Pactur mihi persuasit ut nuntium tibi feram"
  ],
  "ghost extra damage effect": [
    "Ghost Extra Damage Effect"
  ],
  "i'm on it": [
    "Id faciam"
  ],
  "the other one": [
    "Alter"
  ],
  "other one": [
    "Alter"
  ],
  "i'm not much for sneaking": [
    "Repere mihi non est"
  ],
  "volkihar master vampire": [
    "Vampira Domina Volkiharis"
  ],
  "dead woman with bandits": [
    "Dead woman with bandits"
  ],
  "what ring": [
    "Qui anulus"
  ],
  "elven warhammer of diminishing": [
    "Alfus Bellimaleus Diminuendi"
  ],
  "iron armor of major health": [
    "Ferrata Lorica Maioris Salutis"
  ],
  "femaleeyeshumanbrown": [
    "FemaleEyesHumanBrown"
  ],
  "hold on... you shot me": [
    "Exspecta... me sagittavisti"
  ],
  "i know where the blood horkers are": [
    "Scio ubi sint Horkers Sanguinis"
  ],
  "this will set the tone for the whole negotiation": [
    "Hoc viam totius foederis faciet"
  ],
  "elenwen should go": [
    "Elenwen discedere debet"
  ],
  "i've found this expedition journal": [
    "Ephemeridem expeditionis inveni"
  ],
  "put that down before you get hurt": [
    "Depone illud ante vulneraberis"
  ],
  "i don't know how i do it": [
    "Nescio quomodo facitur"
  ],
  "it just happens": [
    "Modo accidit"
  ],
  "left hand miner's barracks": [
    "Left Hand Miner's Barracks"
  ],
  "imperial bracers of major alchemy": [
    "Chirothecae Romanae Maioris Alchemiae"
  ],
  "glass war axe of immobilizing": [
    "Bellisecuris Vitreus Immobilitatis"
  ],
  "afflicted remnants": [
    "Relictus Afflictorum"
  ],
  "why should i believe you": [
    "Quamobrem tibi credere debeo"
  ],
  "flawless diamond": [
    "Perfectum Adamanteum"
  ],
  "dwarven bow of soul snares": [
    "Dvemeris Arcus Animicapti"
  ],
  "sorry narfi, she's dead": [
    "Ignsoce Narfi, illa mortua est"
  ],
  "glass helmet of eminent magicka": [
    "Galea Vitrea Eminentis Magicae"
  ],
  "pickpocketnc": [
    "FurareNC"
  ],
  "why sell your goods in skyrim": [
    "Quamobrem merces tuas in Caelifine vendis"
  ],
  "i'll look for her": [
    "Eam quaeram"
  ],
  "merryfair farm faction": [
    "Merryfair Farm Faction"
  ],
  "how long have you been in skyrim": [
    "Quamdiu in Caelifine fuisti"
  ],
  "potion of plentiful healing": [
    "Potio Multae Salutis"
  ],
  "dwarven bow of thunderbolts": [
    "Dvemeris Arcus Fulminum"
  ],
  "master bedroom": [
    "Cubiculum Domini"
  ],
  "(3,000 gold)": [
    "(MMM auri)"
  ],
  "let's just get to work": [
    "Reveniamus laboratum"
  ],
  "has the war made your travels difficult": [
    "Bellumne itinera tua dificilia fecÃªrunt"
  ],
  "elven sword of diminishing": [
    "Alfus Gladius Diminuendi"
  ],
  "charwich-koniinge, v2": [
    "Epistulae Charvich-Koniinge, II"
  ],
  "beat logrolf into submission": [
    "Verbera Logrolf ad Submissionem"
  ],
  "daedric warhammer of enervating": [
    "Bellimalleus Daedricus Enervationis"
  ],
  "so the gourmet is in skyrim": [
    "Ergo Apicius in Caelifine est"
  ],
  "i'd like to know more about the thalmor": [
    "Volo plus de Visigothis scire"
  ],
  "imperial light helmet of destruction": [
    "Romana Cassis Destructionis"
  ],
  "iron helmet of alchemy": [
    "Ferri Galea Alchemiae"
  ],
  "riften keep scene 10": [
    "Hiati Keep Scene 10"
  ],
  "the gauldur amulet": [
    "Monile Gauldris"
  ],
  "gauldur amulet": [
    "Monile Gauldris"
  ],
  "i could be convinced to catch him": [
    "Eam capere convinci possum"
  ],
  "dragonscale armor of extreme health": [
    "Draconisquamata Lorica Extremis Salutis"
  ],
  "cwsharedinfostack": [
    "CWSharedInfoStack"
  ],
  "2920, morning star, v1": [
    "[MMCMXX] 2920, Sirius, V. I"
  ],
  "better to negotiate than lose completely": [
    "Melius est negotium agere quam omnino perdere"
  ],
  "speaking of my pay": [
    "De pecunia mea loquens"
  ],
  "ebony bow of nullifying": [
    "Arcus Ebeni Nullificandi"
  ],
  "dwarven warhammer of ice": [
    "Dvemeris Bellimalleus Gelidus"
  ],
  "steel plate helmet of eminent magicka": [
    "Laminata Galea Eminentis Magicae"
  ],
  "steel mace of binding": [
    "Maca Adamantea Deligandi"
  ],
  "browsmalehumanoid05": [
    "BrowsMaleHumanoid05"
  ],
  "i have proof that thorald lives": [
    "Argumentum habeo Thoraldem vivere"
  ],
  "leather bracers of minor archery": [
    "Corii Chirothecae Minoris Saggitationis"
  ],
  "what exactly does a listener do": [
    "Quid, exacte, facit Auditor"
  ],
  "woodelfskin03": [
    "WoodElfSkin03"
  ],
  "what's so special about this tree": [
    "Quid tam magni momenti est de hac arbore"
  ],
  "report success to <alias=questgiver>": [
    "Refer Nuntium ad <Alias=QuestGiver>"
  ],
  "chillfurrow farm": [
    "Latifundus Frigidaulax"
  ],
  "ancient nord greatsword of frost": [
    "Spatha Antiqua Germanica Gelidus"
  ],
  "studded armor of the major squire": [
    "Ornata Lorica Maioris Armiferis"
  ],
  "you mean he murders his marks": [
    "Dicis eum victimas necare"
  ],
  "dainty sload footlocker key": [
    "Clavis ad Arcam ad Pedes Sload Cupes"
  ],
  "orcish bow of ice": [
    "Orci Arcus Gelidus"
  ],
  "what happened to fjotli": [
    "Quid Fjotli accidit"
  ],
  "a hypothetical treachery": [
    "Insidia Hypothetica"
  ],
  "hypothetical treachery": [
    "Insidia Hypothetica"
  ],
  "jester's clothes": [
    "Macci Vestimenta"
  ],
  "i spoke with the augur of dunlain": [
    "Locutus sum cum Auguri Dunlanis"
  ],
  "so how do i rent a room": [
    "Ergo quommodo conclave concucam"
  ],
  "anise": [
    "Anise"
  ],
  "sprint effect 2": [
    "Sprint Effect 2"
  ],
  "solution of lasting potency": [
    "Solutio Potentiae Interminantis"
  ],
  "whiterun arcadias's cauldron faction": [
    "Whiterun Arcadias's Cauldron Faction"
  ],
  "dialogue faction for falkreath hold guards": [
    "Dialogue Faction for Falkreath Hold Guards"
  ],
  "ebony shield of fire abatement": [
    "Ebeni Scutum Resistendi Ignis"
  ],
  "how'd you get involved": [
    "Quomodo hoc tibi refert"
  ],
  "orcish battleaxe of diminishing": [
    "Orci Bellisecuris Diminuendi"
  ],
  "tell me where linwe's hiding": [
    "Dic mihi ubi latet Linwe"
  ],
  "ebony war axe of enervating": [
    "Bellisecuris Ebeni Enervationis"
  ],
  "i'm not going to kill you": [
    "Te non necabo"
  ],
  "that's not our way": [
    "Mos noster non est"
  ],
  "stand aside, or else. (intimidate)": [
    "Move te, vel... (Intimida)"
  ],
  "how's your steel": [
    "Quale est adamanteum tuum"
  ],
  "ebony shield of dwindling shock": [
    "Ebeni Scutum Electricitatis Diminuentis"
  ],
  "no more night terrors, no more paranoia": [
    "Nullas plurimas terrores noctis, nihil plus paranoiae"
  ],
  "(optional) steal gaius maro's travel schedule": [
    "(Optio) Furare horarium Gaii Maronis"
  ],
  "he'll sleep peacefully now": [
    "In pace nunc requiescet"
  ],
  "iron battleaxe of fatigue": [
    "Bellisecuris Ferri Defatigationis"
  ],
  "close wounds": [
    "Claude Vulnera"
  ],
  "no more self loathing for pelagius": [
    "Nihil plus odii pro se pro Pelagio"
  ],
  "speak to sibbi black-briar": [
    "Adloqure Sibbi Atri-Dumum"
  ],
  "his paranoia is gone": [
    "Insanitas nunc abest"
  ],
  "daedric mace of debilitation": [
    "Maca Daedrica Debilitationis"
  ],
  "we'll kill you if we must": [
    "Te necabimus si necesse erit"
  ],
  "i've done it": [
    "Id feci"
  ],
  "i've fixed pelagius' mind": [
    "Mentem Pelagii refeci"
  ],
  "alain dufont": [
    "Alanus Dufons"
  ],
  "you and your daughter were at the execution": [
    "Tu et filia apud supplicium fuistis"
  ],
  "the heart of dibella": [
    "Cor Dibellae"
  ],
  "heart of dibella": [
    "Cor Dibellae"
  ],
  "what's so special about the gildergreen": [
    "Quid tam magni momenti est de Gildergreen"
  ],
  "leather armor of major health": [
    "Corii Lorica Maioris Salutis"
  ],
  "i'll deliver the book for you": [
    "Librum tibi adferam"
  ],
  "research notes": [
    "Adnotationis Ivestigationis"
  ],
  "steel helmet of major illusion": [
    "Adamantea Galea Maioris Inlusionis"
  ],
  "is there any way to revive the tree": [
    "Estne modo in quo arborem sanare possim"
  ],
  "where is the tree": [
    "Ubi est arbor"
  ],
  "what kind of weapon would work": [
    "Qualis tela fungatur"
  ],
  "savos aren": [
    "Savos Aren"
  ],
  "<not bound>": [
    "<NOT BOUND>"
  ],
  "i'll get nettlebane for you": [
    "Nettlebane tibi referam"
  ],
  "a second drink": [
    "Poculum secundum"
  ],
  "second drink": [
    "Poculum secundum"
  ],
  "easy enough": [
    "Satis facile factu"
  ],
  "keening": [
    "Asperitas"
  ],
  "tell me more about eldergleam": [
    "Dic mihi plus de Eldergleam"
  ],
  "i need to stop alduin": [
    "Opus mihi est Alduinum superare"
  ],
  "that's not really my problem": [
    "Problema meum non est"
  ],
  "reverent dwarven dagger": [
    "Reverens Dvemeris Pugio"
  ],
  "daedric warhammer of harvesting": [
    "Bellimalleus Daedricus Saturni"
  ],
  "thanks for your help": [
    "Gratias pro auxilio tuo"
  ],
  "sulla's journal": [
    "Ephemeris Sullae"
  ],
  "you know i wouldn't ask if it wasn't important": [
    "Certe non rogarem nisi magni momenti esset"
  ],
  "heartwood mill scene 02": [
    "Heartwood Mill Scene 02"
  ],
  "it's the only way to stop the dragon attacks": [
    "Est via sola ad finem ictuum draconum"
  ],
  "erikur's house": [
    "Erikur's House"
  ],
  "what do you have against the blades": [
    "Cur Enses in odio habes"
  ],
  "embrace the night mother": [
    "Complectere Matrem Noctis"
  ],
  "skirmisher's elixir": [
    "Velitis Commixtum"
  ],
  "you have been disarmed": [
    "Disarmatus es"
  ],
  "daedric warhammer of garnering": [
    "Bellimalleus Daedricus Horti"
  ],
  "how about morthal": [
    "Quid de Morthale"
  ],
  "you're not going to win this war on the battlefield": [
    "Hoc bellum in agro non vinces"
  ],
  "a wedding": [
    "Nuptiae"
  ],
  "slaughterfish egg nest": [
    "Nidus Piscicaesi"
  ],
  "pelagius' hip bone": [
    "Os Coxae Pelagii"
  ],
  "hairmalenord7": [
    "HairMaleNord7"
  ],
  "we need a truce until the dragon menace is dealt with": [
    "Foedere ad finem comminationis draconum egemus"
  ],
  "player assaulted this npc": [
    "Player assaulted this NPC"
  ],
  "ulfric has already agreed to attend": [
    "Vercingetorix iam consensit adire"
  ],
  "the empire can't afford to snub the greybeards": [
    "Imperium Canabarbas offendere non audet"
  ],
  "empire can't afford to snub the greybeards": [
    "Imperium Canabarbas offendere non audet"
  ],
  "dwarven bracers of sure grip": [
    "Dvemeres Chirothecae Dexteritatis"
  ],
  "politics be damned": [
    "Ad inferos cum re publica"
  ],
  "alduin has returned": [
    "Alduin revenit",
    "Alduinus revenit"
  ],
  "was anyone else hurt": [
    "Nocebaturne aliquis preater eum"
  ],
  "ring of hircine": [
    "Anulus Hecatis"
  ],
  "the most famous hero of skyrim is tiber septim, who conquered all of tamriel and founded the septim dynasty of emperors": [
    "Heros notissimis Caelifinis est Tiberius Septimus, qui omnem Tamrielem vicit et Dynastium Imperatorum Septimi fecerunt"
  ],
  "most famous hero of skyrim is tiber septim, who conquered all of tamriel and founded the septim dynasty of emperors": [
    "Heros notissimis Caelifinis est Tiberius Septimus, qui omnem Tamrielem vicit et Dynastium Imperatorum Septimi fecerunt"
  ],
  "indara caerellia": [
    "Indara Caerellia"
  ],
  "showing respect to the greybeards will increase your prestige": [
    "Showing respect to Greybeards will increase your prestige"
  ],
  "power shot": [
    "Saggitatio Fortis"
  ],
  "falkreath guard's shield": [
    "Falkretiae Custodis Scutum"
  ],
  "what do the thalmor want with you": [
    "Quid volunt Visigothi tecum"
  ],
  "argonian assemblage": [
    "Conventus Argonius"
  ],
  "how long have you been chained up": [
    "Quamdiu in vinculis fuisti"
  ],
  "audiotemplatehorsecarriage": [
    "AudioTemplateHorseCarriage"
  ],
  "perhaps this will sweeten the deal": [
    "Fortasse hoc compactionem adiuvabit"
  ],
  "(give satchel)": [
    "(Da cibisem)"
  ],
  "glass mace of banishing": [
    "Maca Vitrea Expellendi"
  ],
  "malign magicka poison": [
    "Malignum Venenum Magicae"
  ],
  "daedric warhammer of the sorcerer": [
    "Bellimalleus Daedricus Magi"
  ],
  "%s increased to %i": [
    "%s auctus est ad %i"
  ],
  "dwarven sphere crossbow": [
    "Dvemeris Sphere Crossbov"
  ],
  "i found this amulet": [
    "Hoc monile inveni"
  ],
  "it might be important": [
    "Fortasse magni momenti est"
  ],
  "daedric war axe of the sorcerer": [
    "Bellisecuris Daedricus Magi"
  ],
  "sifnar ironkettle": [
    "Sifnar Ferraenum"
  ],
  "steel dagger of soul snares": [
    "Pugio Adamanteus Animicapti"
  ],
  "rout": [
    "Rout"
  ],
  "the gods aren't a big part of my life": [
    "Dii pars magnae vitae meae non sunt"
  ],
  "gods aren't a big part of my life": [
    "Dii pars magnae vitae meae non sunt"
  ],
  "find the staff of magnus": [
    "Inveni Rudem Magni"
  ],
  "the god of death": [
    "Deus Mortis"
  ],
  "god of death": [
    "Deus Mortis"
  ],
  "i know him well": [
    "Eum bene novi"
  ],
  "daedric greatsword of enervating": [
    "Spatha Daedrica Enervationis"
  ],
  "why would he come when called": [
    "Cur ad vocationem respondeat"
  ],
  "julienne lylvieve": [
    "Julienne Lylvieve"
  ],
  "continue running executable": [
    "Continue Running Executable"
  ],
  "barterexit": [
    "BarterExit"
  ],
  "am i to become a nightingale": [
    "Erone Philomela"
  ],
  "habd's journal": [
    "Ephemeris Habdis"
  ],
  "someone wants you dead, beitild": [
    "Aliquis vult te necari, Beitild"
  ],
  "have you heard from your sons": [
    "E filiis audivisti"
  ],
  "elven mace of winnowing": [
    "Maca Alfa Ventagii"
  ],
  "malemouthorc1": [
    "MaleMouthOrc1"
  ],
  "karhwasten hall": [
    "Karhwasten Hall"
  ],
  "item fade": [
    "Evanidus Rerum"
  ],
  "got it, ma'am": [
    "Sic, domina"
  ],
  "your brother talked me into it": [
    "Frater tuus mihi de eo persuasit"
  ],
  "dialoguedawnstarbrinahorikscene01": [
    "DialogueDawnstarBrinaHorikScene01"
  ],
  "i understand you need help": [
    "Intellego te auxilium quaerere"
  ],
  "dialoguemarkarthtreasuryhouseildenethonarscene01": [
    "DialogueMarkarthTreasuryHouseIldeneThonarScene01"
  ],
  "thadgeir": [
    "Thadgeir"
  ],
  "i'm looking for an old guy hiding out in riften, probably in the ratway": [
    "Quaero senem, in aliquo loco Hiati latentem, probabiliter in Via Murum"
  ],
  "ebony boots of frost suppression": [
    "Caligae Ebeni Contra Glaciem"
  ],
  "glass battleaxe of reaping": [
    "Bellisecuris Vitreus Saturni"
  ],
  "umm... actually, i was hoping to get the ring back": [
    "Umm... si veram dicam, in animo habui anulum recipere"
  ],
  "leather armor of the minor squire": [
    "Corii Lorica Minoris Armiferis"
  ],
  "moon sugar": [
    "Saccharum Lunae"
  ],
  "a bad trade": [
    "Commercium Malum"
  ],
  "bad trade": [
    "Commercium Malum"
  ],
  "steel war axe of embers": [
    "Bellisecuris Adamanteus Cinerum"
  ],
  "evette san asked me to check on her shipment of spice": [
    "Evette San mihi oravit ut pro merce sua aromatum provideam"
  ],
  "ms13arvelshout02trigger": [
    "MS13ArvelShout02Trigger"
  ],
  "sure, i'll pay the tariff": [
    "Certe, Vectical solvam"
  ],
  "no, i think he was an honorable man": [
    "Minime, eum hominem honorabilem fuisse credo"
  ],
  "glass dagger of evoking": [
    "Pugio Vitreus Evocandi"
  ],
  "isn't murder dark brotherhood territory": [
    "Nonne nex est numen Fraternitatis Obscurae"
  ],
  "i've helped your people as you asked": [
    "Cives tuos adiuvi, ut rogavisti"
  ],
  "let me see if i can cut you down": [
    "Video num te servare possim"
  ],
  "evette can't afford a tariff": [
    "Evette vectigal solvere non potest"
  ],
  "she can barely afford her stand": [
    "Pro basei pecuniam fere solvere non potest"
  ],
  "i'm sure we can work something out": [
    "Certe aliquid facere possumus"
  ],
  "anything else": [
    "Quid aliud"
  ],
  "mammoth tusk powder": [
    "Elephantis Dentis Pulvis"
  ],
  "forget i mentioned it": [
    "Obliviscere me id commemorare",
    "Obliviscere me id commemeoravisse"
  ],
  "legate adventus caesennius": [
    "Legatus Adventus Caesennius"
  ],
  "iron boots of resist fire": [
    "Ferri Caligae Contra Vim Ignis"
  ],
  "glass battleaxe of immobilizing": [
    "Bellisecuris Vitreus Immobilitatis"
  ],
  "one of the alcoves up front is empty": [
    "Una ex basibus vacua est"
  ],
  "what's the white-gold concordat": [
    "Quid est Concursus Albus-Aureus"
  ],
  "elven helmet of alteration": [
    "Alfa Galea Mutationis"
  ],
  "steel helmet of major conjuring": [
    "Adamantea Galea Maioris Vocandi"
  ],
  "afflicted devotee": [
    "Afflictus Devotus"
  ],
  "i want the truth, or else. (intimidate)": [
    "Verum volo, vel... (Intimida)"
  ],
  "what was that conversation with mirabelle about": [
    "De qua agebatur colloquim cum Mirabelle"
  ],
  "so what happens now": [
    "Quid nunc accidet"
  ],
  "your discussion sounded like an argument to me": [
    "Colloquium tuum ut altercatio mihi videbatur"
  ],
  "what did you say you needed": [
    "Quid voluisti"
  ],
  "what darkness pervades solitude": [
    "Quae obscuritas Solitudinem pervadit"
  ],
  "is there some kind of problem": [
    "Estne quid difficultas"
  ],
  "dragon grounding": [
    "Dragon Grounding"
  ],
  "(optional) drag balagog's body to a hiding place": [
    "(Optio) Trahe corpus Balagogis ad locum secretum"
  ],
  "why did you come to the sanctuary": [
    "Cur ad Fanum venisti"
  ],
  "glass mace of lethargy": [
    "Maca Vitrea Lethargiae"
  ],
  "you mentioned a watchdog": [
    "Commemoravisti canem"
  ],
  "apprentice ice mage": [
    "Discipulus Glacimagus"
  ],
  "leather helmet of minor restoration": [
    "Cutis Galea Minoris Reficiendi"
  ],
  "i'm loyal to the empire and don't care who knows it": [
    "Fidilis Imperio sum et non curo quem id sciat"
  ],
  "dwarven war axe of weariness": [
    "Dvemeris Bellisecuris Maioris Defatigationis"
  ],
  "brynjolf's strongbox": [
    "Arca Brynjolfis"
  ],
  "dialoguefalkreathcemeteryscene01": [
    "DialogueFalkreathCemeteryScene01"
  ],
  "what brings you to this party": [
    "Quid te ad has epulas fert"
  ],
  "any idea why weylin attacked you": [
    "Scisne cur Veylin te opugnaverit"
  ],
  "wooden ladle": [
    "Ligni Artaena"
  ],
  "what are you doing in markarth": [
    "Quid hic Markartiae facis"
  ],
  "ebony helmet of major conjuring": [
    "Ebeni Galea Maioris Vocandi"
  ],
  "lord geirmund's key": [
    "Clavis Domini Geirmundis"
  ],
  "do you remember anything about the attack": [
    "Alicuiusne de oppugnato recoraris"
  ],
  "what did you do to eltrys": [
    "Quid Eltridi fecisti"
  ],
  "hairmaleelder1": [
    "HairMaleElder1"
  ],
  "dwarven bow of binding": [
    "Dvemeris Arcus Deligandi"
  ],
  "will some coin loosen those lips": [
    "Fortasse aurum os tuum aperiat"
  ],
  "fletcher conversation": [
    "Fletcher Conversation"
  ],
  "steel war axe of souls": [
    "Bellisecuris Adamanteus Animorum"
  ],
  "talvur": [
    "Talvur"
  ],
  "what happens": [
    "Quid accidit"
  ],
  "i'm curious": [
    "Curiosus sum"
  ],
  "you rebels are doing a fine job destroying yourselves": [
    "Vos defectores satis bene te ipsos ruunt"
  ],
  "soldiers running errands": [
    "Miles ut nuntii"
  ],
  "glass helmet of major archery": [
    "Galea Vitrea Maioris Sagittationis"
  ],
  "dialoguedawnstarinnkaritathoringscene02": [
    "DialogueDawnstarInnKaritaThoringScene02"
  ],
  "i'm looking to apply to the college": [
    "Ingressum collegii peto"
  ],
  "bard travelling on the road": [
    "Bard travelling on road"
  ],
  "okay, you can try again": [
    "Ok, iterum conari potes"
  ],
  "deadly lingering poison": [
    "Letifer Commorans Venenum"
  ],
  "scaled armor of eminent health": [
    "Lorica Squamata Eminentis Salutis"
  ],
  "looking around": [
    "Circumvideo"
  ],
  "i had questions about weylin": [
    "Interrogata de Veylin habui"
  ],
  "the empire doesn't need to give up any more territory": [
    "Imperium plus terrae tradere non debet"
  ],
  "empire doesn't need to give up any more territory": [
    "Imperium plus terrae tradere non debet"
  ],
  "you must know something": [
    "Aliquid scire debes"
  ],
  "do you care about money": [
    "De pecunia curas"
  ],
  "imperial bracers of destruction": [
    "Chirothecae Romanae Destructionis"
  ],
  "the dark brotherhood forever": [
    "Fraternitas Obscura Semper"
  ],
  "dark brotherhood forever": [
    "Fraternitas Obscura Semper"
  ],
  "you better start caring": [
    "Curare incipere debes"
  ],
  "tell me about the forsworn": [
    "Dic mihi de Abiuratis"
  ],
  "do you know anything about the forsworn": [
    "Scisne quid de Abiuratis"
  ],
  "gloomreach cavern": [
    "Caverna Prehensi Obscuri"
  ],
  "what do you know about the forsworn": [
    "Quid de Abiuratis scis"
  ],
  "hairfemalenord03": [
    "HairFemaleNord03"
  ],
  "frozen corpse abilities": [
    "Frozen Corpse Abilities"
  ],
  "blacksmith quarters": [
    "Ferrarius Conclavia"
  ],
  "marksmalehumanoid02leftgash": [
    "MarksMaleHumanoid02LeftGash"
  ],
  "dwarven helmet of eminent magicka": [
    "Dvemeris Galea Eminentis Magicae"
  ],
  "this ship, the katariah": [
    "Haec Navis, Kataria"
  ],
  "how will i get onboard": [
    "Quomodo eam conscendam"
  ],
  "glass warhammer of blizzards": [
    "Bellimaleus Vitreus Hiemis"
  ],
  "before the ages of man": [
    "Ante Aetates Hominum"
  ],
  "soldier's request": [
    "Rogatum Militis"
  ],
  "what are the warrens": [
    "Qui sunt Leporaria"
  ],
  "lunar iron mace": [
    "Lunaris Maca Ferri"
  ],
  "did you know weylin": [
    "Novistine Veylin"
  ],
  "elven greatsword of despair": [
    "Spatha Alfa Desponsionis"
  ],
  "potent lingering poison": [
    "Potens Commorans Venenum"
  ],
  "it's important. trust me. (persuade)": [
    "Magni momenti est. Crede mihi. (Persuade)"
  ],
  "key to whiterun stables": [
    "Clavis ad Catabulum Albacursum"
  ],
  "owners of the common house": [
    "Owners of the Common House"
  ],
  "glass helmet of eminent archery": [
    "Galea Vitrea Eminentis Saggitationis"
  ],
  "speak to <alias.shortname=target> about going to the temple of divines": [
    "Adloquere <Alias.ShortName=Target> de iter ad Templum Deorum"
  ],
  "daedric bow of stunning": [
    "Arcus Daedricus Intermissionis"
  ],
  "i don't listen to threats": [
    "Comminationes non ausculto"
  ],
  "werewolf faction": [
    "Werewolf Faction"
  ],
  "dwarven bracers of alchemy": [
    "Dvemeres Chirothecae Alchemiae"
  ],
  "the last": [
    "Ultimus"
  ],
  "what happened to the rest": [
    "Quid aliis accidit"
  ],
  "what does hroggar say happened": [
    "Quid dixit Hroggar"
  ],
  "assassins": [
    "Sicarii"
  ],
  "that's ridiculous": [
    "Quam ridiculum"
  ],
  "who would want to kill her": [
    "Quis eam necare velit"
  ],
  "estormo": [
    "Estormo"
  ],
  "wrong house": [
    "Domus erronea"
  ],
  "that was from brynjolf": [
    "Illud a Brynjolf erat"
  ],
  "get the message": [
    "Intellegisne"
  ],
  "glass greatsword of malediction": [
    "Spatha Vitrea Maledictionis"
  ],
  "map of skyrim": [
    "Charta Caelifinis"
  ],
  "so the war started when istlod died": [
    "Ergo Bellum coepit cum Istlod mortuus est"
  ],
  "you sent me a message about wolf skull cave": [
    "Mihi epistulam de Cava Calvarii Lupi misisti"
  ],
  "you're a monster": [
    "Monstruum es"
  ],
  "glass greatsword of animus": [
    "Spatha Vitrea Animi"
  ],
  "why are you telling me all this": [
    "Cur me haec omnia narras"
  ],
  "dragons in this faction will ignore death sequence": [
    "Dragons in this faction will ignore death sequence"
  ],
  "iron boots of dwindling shock": [
    "Ferri Caligae Electricitatis Diminuentis"
  ],
  "lis": [
    "Lis"
  ],
  "fake lightning bolt": [
    "Fake Lightning Bolt"
  ],
  "brynjolf": [
    "Brynjolf"
  ],
  "whiterun breezehome faction": [
    "Whiterun Breezehome Faction"
  ],
  "orcish mace of harrowing": [
    "Orci Maca Occationis"
  ],
  "isn't it dangerous to leave filled soul gems near an unwarded pentacle": [
    "Nonne periculosum est gemmanimae plenae prope pentaclum sine praesidio deponere"
  ],
  "weylin got his orders from nepos": [
    "Weylin mandata a Nepote accepit"
  ],
  "steel plate bracers of eminent alchemy": [
    "Chirothecae Laminatae Alchemiae Eminentis"
  ],
  "who's in charge here": [
    "Quis hic praeest"
  ],
  "eye of melka": [
    "Oculus Melkae"
  ],
  "olaf sacked it in dragon form": [
    "Olaf urbem oppugnavit vultu draconis"
  ],
  "what kind of patrons do you have": [
    "Quales patronos habes"
  ],
  "i need to see thonar": [
    "Thonar videre volo"
  ],
  "i'll keep that in mind": [
    "De eo cogitabo"
  ],
  "birna": [
    "Birna"
  ],
  "business, you say": [
    "Negotium, dicis"
  ],
  "dwarven mace of reaping": [
    "Dvemeris Maca Saturni"
  ],
  "glass gauntlets of extreme sure grip": [
    "Vitreae Manicae Extremis Ambidexteritatis"
  ],
  "i'll try later, then": [
    "Postea conabor"
  ],
  "i'm here to talk about margret": [
    "Hic sum ut de Margreta colloquamur"
  ],
  "db03muirikillnilsinetopic": [
    "DB03MuiriKillNilsineTopic"
  ],
  "honed ancient nord greatsword": [
    "Melior Germanica Antiqua Spatha"
  ],
  "did you send that guard to threaten me": [
    "Misistine illum custodem ut me minetur"
  ],
  "will you talk now": [
    "Nunc loqui vis"
  ],
  "banded iron armor of minor health": [
    "Ferro Adligata Lorica Minoris Salutis"
  ],
  "how was she killed": [
    "Quomodo necata est"
  ],
  "no one's getting any rest": [
    "Nemo ullum somnium accepit"
  ],
  "you made a deal with the forsworn": [
    "Foedus cum Abiuratis fecisti"
  ],
  "that's impossible": [
    "Impossibilis est"
  ],
  "dwarven greatsword of weariness": [
    "Dvemeris Spatha Maioris Defatigationis"
  ],
  "want me to take care of madanach": [
    "Visne me Madanach 'curare'"
  ],
  "talk to lucan about the robbery": [
    "Lucanum de Furto Adloquere"
  ],
  "the forsworn have a king": [
    "Abiurati regem habent"
  ],
  "forsworn have a king": [
    "Abiurati regem habent"
  ],
  "you called me auxiliary": [
    "Me quaesivisti auxiliaris"
  ],
  "ebony dagger of winnowing": [
    "Pugio Ebeni Ventagii"
  ],
  "imperial helmet of magicka": [
    "Romana Galea Magicae"
  ],
  "iron mace of chills": [
    "Ferri Maca Frigidis"
  ],
  "ebony dagger of despair": [
    "Pugio Ebeni Desponsionis"
  ],
  "do you know \"the age of oppression\"": [
    "Scisne \"Aetas Opprimendi"
  ],
  "i'm not sure what happened": [
    "Nescio quid acciderit"
  ],
  "i need the key to weylin's room": [
    "Volo clavem conclavis Weylinis"
  ],
  "speak to gulum-ei": [
    "Adloquere Gulum-Ei"
  ],
  "dbastridthirdgreetbranchtopic": [
    "DBAstridThirdGreetBranchTopic"
  ],
  "of course it's valid": [
    "Certe validum est"
  ],
  "what's this mist": [
    "Quid est haec nebula"
  ],
  "hey, take it easy": [
    "Heus, mollis esto"
  ],
  "daedric boots of the ox": [
    "Caligae Daedricae Bovis"
  ],
  "were they a part of the thieves guild": [
    "Erantne pars Sodalitatis Furum"
  ],
  "hairlinefemalenord11": [
    "HairLineFemaleNord11"
  ],
  "sayma": [
    "Sayma"
  ],
  "confront haelga with the marks of dibella": [
    "Fer Signa Dibellae Haelgae"
  ],
  "the dragonstone was some kind of map": [
    "Draconilapis erat mappa"
  ],
  "dragonstone was some kind of map": [
    "Draconilapis erat mappa"
  ],
  "staff of calm": [
    "Rudis Mollificationis"
  ],
  "are you from kynesgrove": [
    "Venistine Kynenemore"
  ],
  "cast iron pot": [
    "Calix Ferri Conflatilis"
  ],
  "bring ogmund's amulet of talos to ondolemar": [
    "Fer Monile Talos Ogmundis ad Ondolemar"
  ],
  "ebony greatsword of devouring": [
    "Spatha Ebeni Consumendi"
  ],
  "dagur": [
    "Dagur"
  ],
  "are the murders being investigated": [
    "Necesne investigantur"
  ],
  "once you've helped someone, they will like you": [
    "Postquam alicui auxilium fers, te amabunt"
  ],
  "some people may even be willing to follow you and carry out your orders": [
    "Nonnulli etiam te et mandata tua sequi velint"
  ],
  "hairmalenord02": [
    "HairMaleNord02"
  ],
  "so, the body": [
    "Ergo, corpus"
  ],
  "did you have anything to do with this": [
    "Egisti quid cum hoc"
  ],
  "let me know if you find anything else": [
    "Dic mihi si quid audias"
  ],
  "standonfurniture": [
    "StandonFurniture"
  ],
  "tolfdir's book": [
    "Liber Tolfdiris"
  ],
  "outsiders like me have no place here": [
    "Alieni ut ego nullum locum hic habent"
  ],
  "where's the innkeeper": [
    "Ubi est caupo"
  ],
  "why did you summon me here": [
    "Cur me huic vocavisti"
  ],
  "imperial bow of ice": [
    "Imperialis Arcus Gelidus"
  ],
  "elven warhammer of shocks": [
    "Alfus Bellimaleus Electricitatis"
  ],
  "what else can i help you with": [
    "Quid aliud tecum adiuvare possum"
  ],
  "spider control": [
    "Imperator Araneae"
  ],
  "the doors of oblivion": [
    "Ianuae Oblivionis"
  ],
  "doors of oblivion": [
    "Ianuae Oblivionis"
  ],
  "sanctified steel war axe": [
    "Sanctificatus Bellisecuris Adamanteus"
  ],
  "humanbeard25": [
    "HumanBeard25"
  ],
  "i'll just open it myself": [
    "Ego ipse id aperiam"
  ],
  "orcish helmet of eminent conjuring": [
    "Orci Galea Coniurationis Vocantis"
  ],
  "king olaf's verse was right where you said it would be": [
    "Versus Regis Olafis erat ubi eum esse debere dixisti"
  ],
  "and this anton virane knows the gourmet's true identity": [
    "Et hic Antonius Viranus scit quem verum Apicium esse"
  ],
  "i was at helgen": [
    "Helgeni eram",
    "Eram apud Helgen"
  ],
  "novice grimoire": [
    "Novice Grimoire"
  ],
  "i helped hadvar escape": [
    "Hadvarem effugere adiuvi"
  ],
  "banded iron shield of dwindling frost": [
    "Ferri Adligati Scutum Minoris Congelationis"
  ],
  "report to legate rikke": [
    "Refer te ad Legatum Rikke"
  ],
  "imperial light helmet of major destruction": [
    "Romana Cassis Maioris Destructionis"
  ],
  "what kind of test": [
    "Qualis probatio"
  ],
  "what's at fort hraggstad": [
    "Quid est apud Castellum Hraggstad"
  ],
  "i already cleared it out": [
    "Iam eum mundum feci"
  ],
  "i'll take that claw for 50 gold": [
    "Unguem tuum L Auri accipiam"
  ],
  "i'm sorry, i've never met her before": [
    "Me paenitet, numquam ei occurri"
  ],
  "femaleheadimperial": [
    "FemaleHeadImperialis"
  ],
  "you're going to be my shield-brother": [
    "Erisne Scuti-Frater meus"
  ],
  "consider that fort already yours": [
    "Castellum iam tuum arbitra"
  ],
  "redguard boots": [
    "Caligae Rubricustodum"
  ],
  "whiteruninnkeeperrumorsbranchtopic": [
    "WhiterunInnkeeperRumoresBranchTopic"
  ],
  "i had a great view while the imperials were trying to cut off my head": [
    "Bene spectare potui dum Romani me decapitare temptabant"
  ],
  "the dragon destroyed helgen": [
    "Draco Helgen erasit"
  ],
  "dragon destroyed helgen": [
    "Draco Helgen erasit",
    "Drago Helgen expugnavit"
  ],
  "and last i saw it was heading this way": [
    "Et eum vidi huc petere"
  ],
  "darkwater pit key": [
    "Clavis ad Hiatum Aquae Obscurae"
  ],
  "ulag": [
    "Ulag"
  ],
  "the jarl said you had a project you needed help with": [
    "Comes dixit te opus mihi habere"
  ],
  "jarl said you had a project you needed help with": [
    "Comes dixit te opus mihi habere"
  ],
  "ebony claw": [
    "Ungues Draconis Ebeni"
  ],
  "hmm": [
    "Hmm"
  ],
  "i think you're overheating that essence of spriggan sap": [
    "Credo te olibanum sprigganis nimium calefacere"
  ],
  "i'll wait for jarl balgruuf": [
    "Exspectabo Comitem Balgruuf"
  ],
  "dwarven dagger of cowardice": [
    "Dvemeris Pugio Timoris"
  ],
  "dwarven sword of harvesting": [
    "Gladius Dvemeris Saturni"
  ],
  "just tell me what you need me to do": [
    "Modo dic mihi quid facere debeo"
  ],
  "so what do you need me to do": [
    "Ergo quid vis me facere"
  ],
  "general tullius": [
    "Imperator Marcus Tullius Cicero"
  ],
  "yamarz told me to go to malacath": [
    "Yamarz mihi imperavit ut ad Stygem eam"
  ],
  "you need to pay haran back for all your drinks": [
    "Haran pro poculis remunerare debes"
  ],
  "sounds like she has it in for you": [
    "Mihi videtur she has it in for you"
  ],
  "anything you can tell me about bleak falls barrow": [
    "Estne quid quod mihi de Pabillo Cataractarum Obscurarum narrere potes"
  ],
  "humanbeard36": [
    "HumanBeard36"
  ],
  "how do you know this stone tablet is in bleak falls barrow": [
    "Quomodo hunc tabellum in Pabillo Cataractarum Obscurarum esse scis"
  ],
  "nazir": [
    "Nazir"
  ],
  "tell me more about the dragon war": [
    "Dic mihi plus de Bello Draconum"
  ],
  "ebony shield of fire suppression": [
    "Ebeni Scutum Suppressionis Ignis"
  ],
  "key to heigen's farmhouse": [
    "Clavis ad Villam Rusticam Heigenis"
  ],
  "sond": [
    "Sond"
  ],
  "corrupt agent": [
    "Agens Corruptus"
  ],
  "orcish mace of harvesting": [
    "Orci Maca Saturni"
  ],
  "xander shopping apothecary": [
    "Xander shopping Apothecary"
  ],
  "steel plate boots of flame suppression": [
    "Laminatae Caligae Suppressionis Flammarum"
  ],
  "anything else you need, my jarl": [
    "Visne quid plus, mi Comes"
  ],
  "orcish war axe of frost": [
    "Orci Bellisecuris Gelidus"
  ],
  "i summoned you": [
    "Te vocavi"
  ],
  "i control you now": [
    "Te nunc moderor"
  ],
  "wedding ceremony scene": [
    "Wedding Ceremony Scene"
  ],
  "i have a case of frost river mead for you": [
    "Cista Hydromelli Rivi Gelidi tibi habeo"
  ],
  "ildene's wardrobe": [
    "Armarium Ildenis"
  ],
  "i was there": [
    "Illic eram"
  ],
  "i saw the dragon burn helgen to the ground": [
    "Draconem qui Helgen obruit vidi"
  ],
  "interested in buying this honningbrew decanter": [
    "Visne hoc Obbam Honningbrevem emere"
  ],
  "humanbeardlong16_1bit": [
    "HumanBeardLong16_1bit"
  ],
  "queen freydis's sword": [
    "Gladius Reginae Freyditis"
  ],
  "heart fire": [
    "September"
  ],
  "highborn": [
    "Altinatus"
  ],
  "iron dagger of soul snares": [
    "Pugio Ferri Animicapti"
  ],
  "i wanted to talk about shadr's debt": [
    "Volui te de aero alieno Shadris rogare"
  ],
  "browsmalehumanoid04": [
    "BrowsMaleHumanoid04"
  ],
  "ingredient": [
    "Ingredientia"
  ],
  "you and i both know this is a setup": [
    "Ambo nos hunc fraudem esse scimus"
  ],
  "i'm not working for anyone": [
    "Pro nemo laboro"
  ],
  "dwarven greatsword of frost": [
    "Dvemeris Spatha Gelidus"
  ],
  "let's just see about that": [
    "De illo videbimus"
  ],
  "dwarven greatsword of siphoning": [
    "Dvemeris Spatha Siphonis"
  ],
  "i'll just pay it off for him": [
    "Pro eo pecuniam solvam"
  ],
  "steel helmet of magicka": [
    "Adamantea Galea Magicae"
  ],
  "tavern clothes": [
    "Tabernae Vestimenta"
  ],
  "what exactly is balmora blue": [
    "Quid est Caeruleus Balmorae"
  ],
  "leather boots of dwindling flames": [
    "Corii Caligae Flammarum Diminuentium"
  ],
  "steel plate helmet of archery": [
    "Laminata Galea Saggitationis"
  ],
  "do me a favor and drop the debt": [
    "Fave mihi et aeri alieno ignosce"
  ],
  "humanskinbasewhite03": [
    "HumanSkinBaseWhite03"
  ],
  "i'm going to speak to brynjolf": [
    "Brynjolfem adloquar"
  ],
  "ebony bow of lethargy": [
    "Arcus Ebeni Lethargiae"
  ],
  "i took care of the problem in <alias=brutetown>": [
    "Problema in <Alias=BruteTown> solvi"
  ],
  "i wanted to talk to you about shadr's debt": [
    "Volui te de aero alieno Shadris rogare"
  ],
  "return to froki": [
    "Redi ad Frokem"
  ],
  "i need to locate an elder scroll": [
    "Opus est mihi Codicem Veterrimum invenire"
  ],
  "margret's journal": [
    "Ephemeris Margretis"
  ],
  "i can bring it for you": [
    "Id possum tibi adferre"
  ],
  "i'm just looking for the ragged flagon": [
    "Modo Poculum Fragosum quaero"
  ],
  "sorry, i couldn't change sapphire's mind": [
    "Ignosce, mentem Sapphirae mutare non potui"
  ],
  "tonilia": [
    "Tonilia"
  ],
  "staff of inspiration": [
    "Rudis Inspirationis"
  ],
  "mg03 caller faction": [
    "MG03 Caller Faction"
  ],
  "what does a kid do for fun around here": [
    "Quae sunt acroamata puero in hoc loco"
  ],
  "can you tell me more about your missing son": [
    "Potesne mihi plus de filio amisso tuo narrare"
  ],
  "glass bow of depleting": [
    "Arcus Vitreus Deplendi"
  ],
  "i think you know what really happened to thorald gray-mane": [
    "Credo te scire quid re vera Thoraldi Juba-Canae accidisse"
  ],
  "you want me to find out why": [
    "Visne me causam discere"
  ],
  "about that amulet": [
    "De Illo monili"
  ],
  "i don't have time for this nonsense": [
    "Tempus his nugis non habeo"
  ],
  "hide helmet of conjuring": [
    "Cutis Galea Vocandi"
  ],
  "who was weylin": [
    "Quis erat Weylin"
  ],
  "where did he live": [
    "Ubi vixit"
  ],
  "did you say \"exile\"": [
    "Dixisti \"exsul\""
  ],
  "why live out here all by yourself": [
    "Cur hic solus vivis"
  ],
  "bassianus axius": [
    "Bassianus Axius"
  ],
  "you've looked into these murders": [
    "Has caedes iam investigavisti"
  ],
  "elven helmet of archery": [
    "Alfa Galea Saggitationis"
  ],
  "where is this shrine": [
    "Ubi est haec ara"
  ],
  "riverwood trader faction": [
    "Riverwood Trader Faction"
  ],
  "elven dagger of thunderbolts": [
    "Pugio Alfus Fulminum"
  ],
  "call dragon": [
    "Draconem Voca"
  ],
  "probably better than you": [
    "Probabiliter melius tibi"
  ],
  "strange crystal": [
    "Crystallum Mirum"
  ],
  "who else works here": [
    "Quis preater te hic laborat"
  ],
  "ritual master": [
    "Dominus Ritualis"
  ],
  "imperial bow of diminishing": [
    "Imperialis Arcus Diminuendi"
  ],
  "iron greatsword": [
    "Spatha Ferri"
  ],
  "tekla": [
    "Tekla"
  ],
  "daedric mace of the vampire": [
    "Maca Daedrica Vampiri"
  ],
  "ramblings of a madman": [
    "Locuta Insani"
  ],
  "daedric shield of frost abatement": [
    "Scutum Daedricum Gelidus Resistendi"
  ],
  "i've found finn's lute": [
    "Inveni Citharam Finnis"
  ],
  "do you have the key to margret's room": [
    "Habesne clavem ad conclave Margretis"
  ],
  "i hear you're looking for some help with wolfskull cave": [
    "Audivi te auxilium cum Spelunca Lupicalvaria quaerere"
  ],
  "hand the key over": [
    "Da mihi clavem"
  ],
  "where can i find an orc stronghold": [
    "Ubi arcem Orcorum invenire possum"
  ],
  "grow a backbone, kid": [
    "Dorsum cultiva, puer"
  ],
  "i'll talk to this girl": [
    "Adloquar hanc puellam"
  ],
  "i'll find your amulet": [
    "Monile tuum inveniam"
  ],
  "you're on your own, priest": [
    "Solus es, sacerdos"
  ],
  "iron mace of arcing": [
    "Ferri Maca Electricitatis"
  ],
  "where can i find the items you want": [
    "Ubi possum find items you want"
  ],
  "how does one join the imperial legion": [
    "Quomodo Legio Romanus adiungitur"
  ],
  "what will become of the afflicted": [
    "Quid cum Afflictis accidet"
  ],
  "how do i join": [
    "Quomodo adiungere possum"
  ],
  "i was unable to defeat them all, and they're coming for you": [
    "Omnes superare non potui, et te persequntur"
  ],
  "sanctified hunting bow": [
    "Sanctificatus Arcus Venandi"
  ],
  "virtuous daedric mace": [
    "Virtutis Maca Daedrica"
  ],
  "why are you called stormcloaks": [
    "Cur Sturmmanteli vocamini"
  ],
  "dwarven battleaxe of frost": [
    "Dvemeris Bellisecuris Gelidus"
  ],
  "dwarven battleaxe of debilitation": [
    "Dvemeris Bellisecuris Debilitationis"
  ],
  "tell me about your group": [
    "Dic mihi de grege tuo"
  ],
  "wilmuth": [
    "Vilmuth"
  ],
  "steel dagger of weariness": [
    "Pugio Adamanteus Maioris Defatigationis"
  ],
  "morthal seems like a quiet little town": [
    "Morthal vicus parvus et tacitus videtur"
  ],
  "hairmaleorc18": [
    "HairMaleOrc18"
  ],
  "key to amren's house": [
    "Clavis ad Domum Amrenis"
  ],
  "sigrid": [
    "Sigrid"
  ],
  "orcish warhammer of despair": [
    "Orci Bellimalleus Desponsionis"
  ],
  "malemouthargonian": [
    "MaleMouthArgonian"
  ],
  "tell me about the city's history": [
    "Dic mihi de historia urbis"
  ],
  "stormcloak soldier": [
    "Sturmmantelorum Miles"
  ],
  "why did brunwulf choose you to be steward": [
    "Cur Brunwulf te dispensatorem creavit"
  ],
  "glass mace of reaping": [
    "Maca Vitrea Saturni"
  ],
  "helgi's ghost asked me to find her": [
    "Phantasma Helgi me rogavit ut eam inveniam"
  ],
  "orcish dagger of absorption": [
    "Orci Pugio Absorbendi"
  ],
  "dbnazirplayermoneystilllie": [
    "DBNazirPlayerMoneyStillLie"
  ],
  "it's all in good fun, tullius": [
    "Iocor, Tulli"
  ],
  "daedric warhammer of the vampire": [
    "Bellimalleus Daedricus Vampiri"
  ],
  "expert alembic": [
    "Expert Alembic"
  ],
  "i'll trade in my gloves": [
    "Manubrias tradere volo"
  ],
  "i'll trade in my boots": [
    "Volo caligas tradere"
  ],
  "actually, let me chose something else": [
    "Re vera, aliquid aliud eligere volo"
  ],
  "hide helmet of minor illusion": [
    "Cutis Galea Minoris Inlusionis"
  ],
  "where would i go to join up": [
    "Quo eo ut vobiscum adiungam"
  ],
  "don't worry": [
    "Noli timere",
    "Noli sollicitari"
  ],
  "i'll return the key": [
    "Clavem referam"
  ],
  "humanbeard04": [
    "HumanBeard04"
  ],
  "daedric greatsword of immobilizing": [
    "Spatha Daedrica Immobilitatis"
  ],
  "and now it's your turn": [
    "Et nunc est tempus tuum"
  ],
  "which side in the war does he favor": [
    "Cui lateri in proelio faves"
  ],
  "can i sell this dwarven puzzle box to you": [
    "Possumne hanc Cistam Ambagium Dvemerem tibi vendere"
  ],
  "spider egg": [
    "Ovum Araneae"
  ],
  "swingmeleeweapon": [
    "SwingMeleeWeapon"
  ],
  "madena": [
    "Madena"
  ],
  "dialoguedawnstarwhitehallskaldjod01": [
    "DialogueDawnstarWhiteHallSkaldJod01"
  ],
  "<alias=riverwoodfriend> sent me": [
    "<Alias=RiverwoodFriend> mihi misit"
  ],
  "riverwood is in danger": [
    "Lignirivus in periculo est"
  ],
  "a dragon has destroyed helgen": [
    "Draco Helgen destruxit"
  ],
  "dragon has destroyed helgen": [
    "Draco Helgen destruxit"
  ],
  "it is. carry on": [
    "Id est. Perge"
  ],
  "shrouded cowl": [
    "Umbratus Cucullus"
  ],
  "do you know what i did with it": [
    "Scisne quid cum eo fecerim"
  ],
  "broken iron sword blade": [
    "Fractus Gladius Ferri Ensis"
  ],
  "orthorn": [
    "Orthorn"
  ],
  "temba wide-arm": [
    "Temba Lata-Bracchia"
  ],
  "hallowed orcish bow": [
    "Consacratus Arcus Orcorum"
  ],
  "shrouded shoes": [
    "Umbratae Caligulae"
  ],
  "here's an amulet of arkay": [
    "Hic habes Monile Arkayae"
  ],
  "storm call - strun": [
    "Storm Call - Strun"
  ],
  "glass mace of blizzards": [
    "Maca Vitrea Hiemis"
  ],
  "i need something enchanted": [
    "Volo aliquid incantari"
  ],
  "i've seen that dragon before, the one that got away": [
    "Illum draconem ante nunc vidi, ille qui effugit"
  ],
  "what of it": [
    "Quid refert",
    "Quid de illo"
  ],
  "brynjolf sent me": [
    "Brynjolf me misit"
  ],
  "is there anything you don't charge for": [
    "Estne quid pro quo pecuniam non poscis"
  ],
  "glass dagger of leeching": [
    "Pugio Vitreus Sanguisugae"
  ],
  "why should i help you": [
    "Cur te adiuvare debeo"
  ],
  "do you have any numbers jobs": [
    "Habesne ullas labores numerorum"
  ],
  "i'll keep quiet about your scam, but you owe me": [
    "De insidiis tuis tacebo, sed me debes"
  ],
  "do you visit the greybeards": [
    "Visitasne Canabarbas"
  ],
  "blood horker orders": [
    "Mandata Horker Sanguinis"
  ],
  "evette san": [
    "Evette San"
  ],
  "will you be all right by yourself": [
    "Erisne tutus quod solus nunc es"
  ],
  "wreck of the icerunner": [
    "Naufragium Glacicursoris"
  ],
  "i don't know yet": [
    "Nondum scio"
  ],
  "but i know some people who can probably help": [
    "Sed homines qui adiuvare posse novi"
  ],
  "glass warhammer of stunning": [
    "Bellimaleus Vitreus Intermissionis"
  ],
  "and in return": [
    "Et quid accipiam"
  ],
  "so what's this dragon's name": [
    "Ergo, quid est nomen huius draconis"
  ],
  "daedric battleaxe of lightning": [
    "Bellisecuris Daedricus Fulminis"
  ],
  "orcish war axe of thunderbolts": [
    "Orci Bellisecuris Fulminum"
  ],
  "use the backdoor to gain access to treva's watch": [
    "Uti Ianua Posteriore ut Visum Trevae Intres"
  ],
  "orcish sword of siphoning": [
    "Orci Gladius Siphonis"
  ],
  "bring grisvar the skooma": [
    "Fer Skoomam Grisvari"
  ],
  "sergius turrianus": [
    "Sergius Turrianus"
  ],
  "j'zargo's experiment": [
    "Experimentum J'zargonis"
  ],
  "leather bracers of major wielding": [
    "Corii Chirothecae Maioris Tenendi"
  ],
  "scaled bracers of major wielding": [
    "Chirothecae Squamatae Maioris Tenendi"
  ],
  "winterhold jarls longhouse scene 1": [
    "Hiemiterrae Jarls Longhouse Scene 1"
  ],
  "regain eastmarch": [
    "Recipe Eurimarcum"
  ],
  "orcish bow of cowardice": [
    "Orci Arcus Timoris"
  ],
  "you were at the execution, right": [
    "Ad supplicium fuisti, nonne"
  ],
  "boethiah's bidding": [
    "Voluntas Boethis"
  ],
  "commandedhello": [
    "CommandedHello"
  ],
  "i could have a wedding at the temple": [
    "Possumne nuptias apud templum habere"
  ],
  "deadly frenzy poison": [
    "Letifer Insanitatis Venenum"
  ],
  "how can i find your master": [
    "Quomodo dominum tuum invenire possum"
  ],
  "i guess i'll have to": [
    "Credo mihi id faciendum esse"
  ],
  "it sounds like malyn got what was coming to him": [
    "Mihi videtur Malyn poenas suo aptas dedit"
  ],
  "how do i collect these dragon scales for you": [
    "Quomodo tibi squamas draconum colligere possum"
  ],
  "daedric greatsword of depleting": [
    "Spatha Daedrica Deplendi"
  ],
  "karliah helped me": [
    "Karlia me adiuvit"
  ],
  "losttocombat": [
    "LostToCombat"
  ],
  "how did you end up at the guild": [
    "Quomodo cum Sodalitati adiunxisti"
  ],
  "iron armor of the knight": [
    "Ferrata Lorica Equitis"
  ],
  "heal other": [
    "Alium Sane"
  ],
  "chief yamarz": [
    "Princeps Yamarz"
  ],
  "glass greatsword of garnering": [
    "Spatha Vitrea Horti"
  ],
  "hairmaleorc22": [
    "HairMaleOrc22"
  ],
  "glass armor of the eminent knight": [
    "Vitrea Lorica Eminentis Equitis"
  ],
  "ebony warhammer of animus": [
    "Bellimalleus Ebeni Animi"
  ],
  "labyrinthian, tribune": [
    "Labyrinthium, Tribunus"
  ],
  "dwarven dagger of frost": [
    "Dvemeris Pugio Gelidus"
  ],
  "there's more to this than a debt, isn't there": [
    "Plus hic est quam aes alienum, nonne"
  ],
  "are you ready to pay brynjolf what you owe": [
    "Esne paratus Brynjolfi solvere id quod debes"
  ],
  "elven bow of thunderbolts": [
    "Arcus Alfus Fulminum"
  ],
  "head bandages": [
    "Emporetici Capiti"
  ],
  "glass mace of expelling": [
    "Maca Vitrea Expellendi"
  ],
  "dwarven helmet of eminent destruction": [
    "Dvemeris Galea Eminentis Destructionis"
  ],
  "voldsea giryon": [
    "Voldsea Giryon"
  ],
  "what school of magic are you studying": [
    "In qua schola magicae operam das"
  ],
  "potions that restore stamina or magicka should be used during combat, to increase the number of available spells and power attacks": [
    "Potionibus quae Staminam et Magicam reddunt dum pugnans uti debes, ut numerum ictus potestatis et incantamentorum augeas"
  ],
  "master hammer": [
    "Master Hammer"
  ],
  "pumpkin": [
    "Cucurbitum"
  ],
  "steel war axe": [
    "Bellisecuris Adamanteus"
  ],
  "ebony warhammer of leeching": [
    "Bellimalleus Ebeni Sanguisugae"
  ],
  "how do i get in": [
    "Quomodo intrare possum"
  ],
  "angeline's aromatics": [
    "Aromatica Angelinae"
  ],
  "dbastridsleepreacttion3topic": [
    "DBAstridSleepReacttion3Topic"
  ],
  "the threat is worse than you know": [
    "Periculum peius est quam putavimus"
  ],
  "threat is worse than you know": [
    "Periculum peius est quam putavimus"
  ],
  "journeyman grimoire": [
    "Journeyman Grimoire"
  ],
  "it's only hopeless if we give up": [
    "Modo sine spe erit si non conabimur"
  ],
  "orcish greatsword of debilitation": [
    "Orci Spatha Debilitationis"
  ],
  "is your lumber selling better since the war started": [
    "Estne Lignum tuum facilius venditu abhinc initium belli"
  ],
  "fascinating": [
    "Mirabile dictu"
  ],
  "do go on": [
    "Perge"
  ],
  "your friend gavros is dead": [
    "Amicus tuus Gavros mortuus est"
  ],
  "skeleton faction": [
    "Skeleton Faction"
  ],
  "what would it take to identify the buyer": [
    "Quantum erit ut nomen emptoris mihi des"
  ],
  "a dragon attacked helgen and destroyed it": [
    "Draco Helgen oppugnavit et id destruxit",
    "Draco Helgen oppugnavit id illam urbem diripuit"
  ],
  "dragon attacked helgen and destroyed it": [
    "Draco Helgen oppugnavit et id destruxit",
    "Draco Helgen oppugnavit id illam urbem diripuit"
  ],
  "hadvar and i escaped together": [
    "Ego et Hadvar iuncti effugimus"
  ],
  "obviously": [
    "Certe"
  ],
  "now tell me what you need": [
    "Nunc dic mihi quid vis"
  ],
  "winterhold frozen hearth faction": [
    "Winterhold Frozen Hearth Faction"
  ],
  "you mentioned a brother": [
    "Commemoravisti fratrem"
  ],
  "who is he": [
    "Quis est he",
    "Quis est ille"
  ],
  "ebony mace of garnering": [
    "Maca Ebeni Horti"
  ],
  "secunda's kiss": [
    "Basium Secundae"
  ],
  "i'm not doing anything yet": [
    "Nihil iam facio"
  ],
  "elven mace of binding": [
    "Maca Alfa Deligandi"
  ],
  "potema's catacombs": [
    "Potemae Catacumbae"
  ],
  "glass warhammer of debilitation": [
    "Bellimaleus Vitreus Debilitationis"
  ],
  "what happened during the night of green fire": [
    "Quid accidit Noctu Ignis Viridis"
  ],
  "did you just talk": [
    "Locutusne es"
  ],
  "warped soul gem": [
    "Calamitosus Gemmanimus"
  ],
  "bed roll": [
    "Lectulus"
  ],
  "no, that's it": [
    "Minime, haec sunt omnia"
  ],
  "i have a message for you from brynjolf": [
    "Nuntium a Brynjolf tibi habeo"
  ],
  "orcish war axe of arcing": [
    "Orci Bellisecuris Electricitatis"
  ],
  "ebony shield of major blocking": [
    "Ebeni Scutum Maioris Interponendi"
  ],
  "give it to me or else": [
    "Da id mihi vel poenas dabitis"
  ],
  "scroll of paralyze": [
    "Codex Paralyticus"
  ],
  "bloodstained note": [
    "Epistula Sanguinosa"
  ],
  "it's dangerous out there. you should stay": [
    "Periculosum foris est. Manere debes"
  ],
  "charred skeever": [
    "Skeever Coctus"
  ],
  "marksman trainer": [
    "Marksman Trainer"
  ],
  "report to festus krex": [
    "Refer te ad Festum Krex"
  ],
  "that honor belongs to you": [
    "Ille honor tuus est"
  ],
  "i'll gladly kill him": [
    "Gaudeo eum necare"
  ],
  "how do i open the door": [
    "Quomodo ianuam aperire possum"
  ],
  "glass warhammer of garnering": [
    "Bellimaleus Vitreus Horti"
  ],
  "you appear to need help getting organized": [
    "Auxilio in res componendo egere videris"
  ],
  "daedric warhammer of the blaze": [
    "Bellimalleus Daedricus Congflagrantis"
  ],
  "tell <alias.shortname=questgiver> what <alias.shortname=target> said": [
    "Dic <Alias.ShortName=Questgiver> quid <Alias.ShortName=Target> dixit"
  ],
  "bjartur": [
    "Bjartur"
  ],
  "hallowed elven dagger": [
    "Consacratus Pugio Alfus"
  ],
  "i'll need an elder scroll to get any further. i'm working on locating it": [
    "Codice Veterrima egeo. Si progredi velim eam habere debuero. Eam nunc quaero"
  ],
  "scroll of fury": [
    "Codex Atrocitatis"
  ],
  "riverwood calls for the jarl's aid": [
    "Lignirivus auxilium Comitis rogat"
  ],
  "fine, you little swindler": [
    "Bene, fur"
  ],
  "here's a septim": [
    "Hic denarium habes"
  ],
  "now where's this bridge": [
    "Nunc, ubi est pons"
  ],
  "will this change your mind": [
    "Fortasse hoc mentem tuam mutare potest"
  ],
  "i didn't agree to anything": [
    "Ad nihil consensi"
  ],
  "dragonscale helmet of peerless alchemy": [
    "Draconisquamata Galea Optimae Alchemiae"
  ],
  "did the imperials try to arrest you": [
    "Romanine te comprehendere tempavÃªrunt"
  ],
  "leather helmet of minor destruction": [
    "Cutis Galea Minoris Destructionis"
  ],
  "frost thrall": [
    "Frost Thrall"
  ],
  "i killed her for you": [
    "Eam tibi necavi"
  ],
  "you're not from skyrim, are you": [
    "Non in Caelifine natus es"
  ],
  "reality & other falsehoods": [
    "Verum & et Alia Falsa"
  ],
  "he won't bother you again": [
    "Te iterum non molestabit"
  ],
  "the master vampire movarth is dead": [
    "Vampirus Dominus Movarth mortuus est"
  ],
  "master vampire movarth is dead": [
    "Vampirus Dominus Movarth mortuus est"
  ],
  "you really think i should join the imperial legion": [
    "Re vera putas mihi Legionem Romanum adiungendam esse"
  ],
  "you make a good case": [
    "Recte dicis"
  ],
  "maybe i will join up": [
    "Fortasse adiungam"
  ],
  "area": [
    "Locus"
  ],
  "iron battleaxe of weariness": [
    "Bellisecuris Ferri Maioris Defatigationis"
  ],
  "torpor effect": [
    "Effectum Torporis"
  ],
  "honed ancient nord battle axe of frost": [
    "Melior Germanicus Antiquus Bellisecuris Gelidus"
  ],
  "ebony greatsword of winnowing": [
    "Spatha Ebeni Ventagii"
  ],
  "i want you and your men to leave": [
    "Tibi et tuis impero ut exeas"
  ],
  "where's the shrine of talos": [
    "Ubi est Ara Alexandri"
  ],
  "do you know anything about the attack": [
    "Scisne quid de oppugnato"
  ],
  "melaran": [
    "Melaran"
  ],
  "hairshavedfemaleorc01": [
    "HairShavedFemaleOrc01"
  ],
  "fascinating race, the dwemer": [
    "Gens mirabilis, Dvemeres"
  ],
  "powerful... and extinct": [
    "Potentes... et extincti"
  ],
  "kill grelod the kind": [
    "Neca Grelodem Benignam"
  ],
  "door to garvey's room": [
    "Ianuam ad conlave Garvey"
  ],
  "bleak falls sanctum": [
    "Sanctum Cataractarum Obscurarum"
  ],
  "this places a hazard": [
    "This Places hazard"
  ],
  "i'd understand better if less mystery was involved": [
    "Melius intellegere potero postquam minus mysteriorum habuero"
  ],
  "ivarstead fellstar farm": [
    "Ivarstead Fellstar Farm"
  ],
  "hairlinefemalenord09": [
    "HairLineFemaleNord09"
  ],
  "the marksmanship lesson": [
    "Lectio Sagittationis"
  ],
  "marksmanship lesson": [
    "Lectio Sagittationis"
  ],
  "morthal moorside benor alva": [
    "Morthal Moorside Benor Alva"
  ],
  "whiterun warmaiden's faction": [
    "Whiterun Warmaidenis Faction"
  ],
  "how about <alias=imperialmajorhold>": [
    "Quid de <Alias=ImperialMajorHold>"
  ],
  "so erikur was your brother": [
    "Ergo Erikur frater tuus fuit"
  ],
  "alva": [
    "Alva"
  ],
  "glass mace of consuming": [
    "Maca Vitrea Consumendi"
  ],
  "rikke, leave": [
    "Rikke, discede"
  ],
  "they'll kill you": [
    "Te necabunt"
  ],
  "do you know what this amulet is": [
    "Scisne quid hoc monile sit"
  ],
  "whiterun services faction - anoriath": [
    "Whiterun Munera Faction - Anoriath"
  ],
  "i'd like to have a wedding at the temple": [
    "Volo nuptias apud templum habere"
  ],
  "imperial light armor": [
    "Romana Lorica Levis"
  ],
  "i need you to cause a scene": [
    "Fac scaenam, quaeso"
  ],
  "get everyone's attention for a few minutes": [
    "Fac ut omnes te animadvertant"
  ],
  "shrine to peryite": [
    "Ara Peryitis"
  ],
  "elven boots of dwindling frost": [
    "Caligae Alfae Minoris Congelationis"
  ],
  "grosta's necklace": [
    "Grostae Monile"
  ],
  "hallowed daedric dagger": [
    "Consacrata Pugio Daedricus"
  ],
  "bleedout": [
    "BleedOut"
  ],
  "i almost got killed": [
    "Fere necatus sum"
  ],
  "daedric gauntlets of extreme wielding": [
    "Daedricae Manicae Extremis Tenendi"
  ],
  "winter war": [
    "Winter War"
  ],
  "quest failed": [
    "Munus Falsum"
  ],
  "glass war axe of evoking": [
    "Bellisecuris Vitreus Evocandi"
  ],
  "cannot repair past %.0f%s": [
    "CANNOT REPAIR PAST %.0f%s"
  ],
  "dwarven battleaxe of arcing": [
    "Dvemeris Bellisecuris Electricitatis"
  ],
  "savage strike": [
    "Ictus Saevus"
  ],
  "how do we get inside": [
    "Quomodo intrare possumus"
  ],
  "so all of this was just a prank": [
    "Ergo haec omnia iocum fuerunt"
  ],
  "he's here in riverwood. right outside": [
    "Hic Lignirivi est. Foris tabernae"
  ],
  "anuriel": [
    "Anuriel"
  ],
  "how did she die": [
    "Quomodo mortua est"
  ],
  "so the night mother is being brought here": [
    "Ergo Mater Noctis hic fertur"
  ],
  "i'm ready for anything": [
    "Paratus sum omnibus"
  ],
  "guardfaction (makes an actor a guard)": [
    "GuardFaction (makes actor guard)"
  ],
  "dwarven sword of fear": [
    "Gladius Dvemeris Timoris"
  ],
  "we've killed your beast spirit": [
    "Animum Bestiale tuum necavimus"
  ],
  "your old crew member has been taken care of": [
    "Actum est de amico vetero tuo"
  ],
  "how about the pale": [
    "Quid de Pallido"
  ],
  "each hold tracks its crime separately": [
    "Singulae Urbes crimina sua sequuntur"
  ],
  "a wanted criminal in whiterun might have no bounty in solitude, and so on": [
    "Damnatus Albacursus fortasse civis nobilis Solitudini erit... etc"
  ],
  "wanted criminal in whiterun might have no bounty in solitude, and so on": [
    "Damnatus Albacursus fortasse civis nobilis Solitudini erit... etc"
  ],
  "how will you prove it now": [
    "Quomodo argumentum de eo non dare potes"
  ],
  "i've found all of the stones of barenziah": [
    "Omnes Lapides Barenziae inveni"
  ],
  "potion of magicka": [
    "Potio Magicae"
  ],
  "you've served your last meal... gourmet": [
    "Ultimam cenam paravisti... Apici"
  ],
  "i could find that ring for you": [
    "Tibi anulum invenire possim"
  ],
  "you acted as a broker for its new owner": [
    "Cotio fuisti pro novo possessore"
  ],
  "ebony sword of devouring": [
    "Gladius Ebeni Consumendi"
  ],
  "i have good news": [
    "Nuntium bonum habeo"
  ],
  "i used dragonrend on alduin, but he escaped": [
    "Draconicaesi in Alduinum usus sum, sed effugit"
  ],
  "what about clan gray-mane": [
    "Quid de Gens Cana-Juba"
  ],
  "i'd rather not say": [
    "Malim nil dicere"
  ],
  "dawnstar guardian faction": [
    "Dawnstar Guardian Faction"
  ],
  "bring a <alias=item> to calcelmo": [
    "Fer <Alias=Item> Calcelmoni"
  ],
  "it's not for me. it's for calcelmo": [
    "Mihi non est. Cacelmoni est"
  ],
  "potion of the healer": [
    "Potio Doctoris"
  ],
  "half damage": [
    "Half damage"
  ],
  "scroll of magelight": [
    "Codex Magilucis"
  ],
  "silent moons enchant": [
    "Silent Moons Enchant"
  ],
  "skirmisher's philter": [
    "Velitis Philtrum"
  ],
  "k8r": [
    "K8r"
  ],
  "orcish warhammer of gleaning": [
    "Orci Bellimalleus Saturni"
  ],
  "sild the warlock": [
    "Sild Thaumatorgos"
  ],
  "for the dark brotherhood, that can be easily arranged": [
    "Pro Fraternitate Obscura, facile factu"
  ],
  "dwarven shield of waning frost": [
    "Dvemere Scutum Insenescentis Congelationis"
  ],
  "orcish dagger of gleaning": [
    "Orci Pugio Saturni"
  ],
  "hide helmet of major alchemy": [
    "Cutis Galea Maioris Alchemiae"
  ],
  "who buys all your fish": [
    "Qui piscem tuum emit"
  ],
  "new gnisis cornerclub": [
    "Novi Gnisis Tabernangulus"
  ],
  "storm call effect alduindeath": [
    "Storm Call Effect AlduinDeath"
  ],
  "there is one final ingredient": [
    "Unum ingredientium ultimum"
  ],
  "here, add this": [
    "Nunc, adde hanc"
  ],
  "(give jarrin root)": [
    "(da stirpem jarrinis)"
  ],
  "sounds like no one should possess it": [
    "Mihi videtur id nemini possidendum esse"
  ],
  "i'll find your sword": [
    "Ensem tuam inveniam"
  ],
  "imperial boots of dwindling frost": [
    "Caligae Romanae Minoris Congelationis"
  ],
  "ebony helmet of eminent restoration": [
    "Ebeni Galea Eminentis Reficiendi"
  ],
  "hairmaledarkelf04": [
    "HairMaleDarkElf04"
  ],
  "who's in charge of solitude": [
    "Quis Solitudini praeest"
  ],
  "i have a delivery from <alias=questgiver>": [
    "Cistam tibi habeo ab <Alias=QuestGiver>"
  ],
  "elven helmet of eminent magicka": [
    "Alfa Galea Eminentis Magicae"
  ],
  "calcelmo's laboratory key": [
    "Clavis ad Laboratorium Calcelmonis"
  ],
  "defeat the guardian bear": [
    "Vince Ursam Custodem"
  ],
  "grim shieldmaiden": [
    "Scutivirgo Horrida"
  ],
  "potion of the defender": [
    "Potio Defensoris"
  ],
  "scroll of ice storm": [
    "Codex Tempestatis Gelidae"
  ],
  "idgrod ravencrone": [
    "Idgrod Strigacorax"
  ],
  "bear trap": [
    "Insidia Ursae"
  ],
  "why do you think the battle-borns are involved": [
    "Cur Proeli-Natos partem huius rei esse censes"
  ],
  "shouldn't the court mage have it": [
    "Nonne magus aulae id habere debet"
  ],
  "beggar prince": [
    "Princeps Rogatorum"
  ],
  "what right do you have to drag people away like this": [
    "Cur censes te posse homines hoc modo extrahere"
  ],
  "all ingredients": [
    "All Ingredients"
  ],
  "he's a stranger who needs assistance": [
    "Est vir qui auxilium postulat"
  ],
  "please, do the right thing": [
    "Quaeso, rem probam fac"
  ],
  "markarth guard's shield": [
    "Markartiae Custodis Scutum"
  ],
  "glass mace of thunderbolts": [
    "Maca Vitrea Fulminum"
  ],
  "the night mother heard your pleas, motierre": [
    "Mater Noctis desideria tua audivit, Motierre"
  ],
  "night mother heard your pleas, motierre": [
    "Mater Noctis desideria tua audivit, Motierre"
  ],
  "here i am": [
    "Hic sum"
  ],
  "what is a shield-brother": [
    "Quid est Scuti-Frater"
  ],
  "skorm snow-strider's journal": [
    "Ephemeris Skorm Nivi-Cursoris"
  ],
  "orcish war axe of siphoning": [
    "Orci Bellisecuris Siphonis"
  ],
  "can i join you": [
    "Possum tecum adiungere"
  ],
  "honed ancient nord war axe of frost": [
    "Melior Germanicus Antiquus Bellisecuris Gelidus"
  ],
  "you didn't look like you needed help": [
    "Non visus es tibi auxilium necesse esse"
  ],
  "farengar secret-fire": [
    "Farengar Igni-Secreti"
  ],
  "i wasn't expecting you to be a dragon": [
    "Te draconem esse non exspectabam"
  ],
  "holy daedric mace": [
    "Sanctus Maca Daedrica"
  ],
  "dragonplate helmet of peerless illusion": [
    "Draconilaminata Galea Maximi Inlusionis"
  ],
  "gold and ruby circlet": [
    "Auri et Rubini Circulum"
  ],
  "do you have any information on svidi": [
    "Habesne ullam informationem de Svidi"
  ],
  "the rueful axe": [
    "Securis Rutae"
  ],
  "rueful axe": [
    "Securis Rutae"
  ],
  "hide shield of blocking": [
    "Scutum Cutis Interponendi"
  ],
  "sounds like a waste of time": [
    "Mihi videtur waste of time"
  ],
  "steel mace of diminishing": [
    "Maca Adamantea Diminuendi"
  ],
  "want me to take care of them": [
    "Vis me eos 'curare'"
  ],
  "why don't you travel with me": [
    "Quidni mecum eas"
  ],
  "eorlund gray-mane": [
    "Eorlund Juba-Cana"
  ],
  "i believe these are yours": [
    "Credo haec tua sunt"
  ],
  "falkreath seems quiet": [
    "Falkreatia sedatam videtur"
  ],
  "daedric battleaxe of lethargy": [
    "Bellisecuris Daedricus Lethargiae"
  ],
  "yes, clear my bounty": [
    "Sic, dele vectigal meum"
  ],
  "(<global=guildcost> gold)": [
    "(<Global=GuildCost> auri)"
  ],
  "jeweled pendant": [
    "Gemmatum Monile"
  ],
  "orcish bow of draining": [
    "Orci Arcus Hauriendi"
  ],
  "i found your shield": [
    "Scutum tuum inveni"
  ],
  "optional": [
    "Optio"
  ],
  "db02astridwalkawaytopic": [
    "DB02AstridWalkAwayTopic"
  ],
  "i could help you in secret": [
    "Possum te clam adiuvare"
  ],
  "the wolf queen, v5": [
    "Regina Luporum, V"
  ],
  "wolf queen, v5": [
    "Regina Luporum, V"
  ],
  "sinding": [
    "Sinding"
  ],
  "summoning undead isn't frowned upon": [
    "Amortuos vocare non vetatur"
  ],
  "i could find your amulet for you": [
    "Tibi monile invenire possum"
  ],
  "db recurringtarget 2 crimefaction": [
    "DB RecurringTarget 2 CrimeFaction"
  ],
  "iron greatsword of souls": [
    "Spatha Ferri Animorum"
  ],
  "are you any good with your bow": [
    "Esne arcu peritus"
  ],
  "no, not really": [
    "Minime"
  ],
  "whiterun stables faction": [
    "Whiterun Stables Faction"
  ],
  "imperial officer's helmet": [
    "Romana Galea Officarii"
  ],
  "your farm is doing very well": [
    "Fundus tuus valde floret"
  ],
  "you must be proud": [
    "De eo gloriari debes"
  ],
  "green apple": [
    "Viride Malum"
  ],
  "dragonplate armor of the noble knight": [
    "Draconilaminata Lorica Noble Equitis"
  ],
  "conjuration": [
    "ARS VOCANDI"
  ],
  "i'm not picking sides": [
    "Latus non eligam"
  ],
  "mara's eye den": [
    "Cubile Oculi Marae"
  ],
  "you could come with me": [
    "Mecum ire potes"
  ],
  "bring the star to nelacar": [
    "Fer Stellam ad Nelacarem"
  ],
  "i'm ready for some practice": [
    "Paratus sum exercitio"
  ],
  "spell tome: dead thrall": [
    "Liber Magicus: Servus Mortuus"
  ],
  "i'm sorry about your family": [
    "Me paenitet de Familia tua audire"
  ],
  "i want to get out here": [
    "Volo e hoc loco exire"
  ],
  "\"end of days\"": [
    "\"Finis dierum\""
  ],
  "i don't think things are quite that bad": [
    "Non credidi res tam malas esse"
  ],
  "private letter": [
    "Epistula Privata"
  ],
  "i'll have no part of this insanity": [
    "Nullam partem in hac insanitate habebo"
  ],
  "you live in the gray quarter": [
    "In Regione Canuta vivis"
  ],
  "south shriekwind bastion": [
    "Australe Castellum Venti Clamanti"
  ],
  "revyn sadri": [
    "Revyn Sadri"
  ],
  "are you treated as badly as the dark elves": [
    "Accipisne tractationem tam malam ut atralfi"
  ],
  "why do you hate the dark elves": [
    "Cur Atralfos in odio habes"
  ],
  "scroll of call to arms": [
    "Codex Vocandi ad Armas"
  ],
  "key to the dead man's drink": [
    "Clavis ad Haustum Viri Mortui"
  ],
  "potent paralysis poison": [
    "Potens Venenum Paralyticum"
  ],
  "yngvild throne room": [
    "Yngvild Solii Conclave"
  ],
  "dwarven dagger of despair": [
    "Dvemeris Pugio Desponsionis"
  ],
  "get new orders": [
    "Nova Mandata Accipe"
  ],
  "death blow of abernanit": [
    "Ictus Mortis Abernanit"
  ],
  "looked like those nords were giving you trouble": [
    "Mihi videbatur illos Germanos tibi difficultatem dedisse"
  ],
  "investigators chest": [
    "Arca Investigatorum"
  ],
  "claws": [
    "Ungues"
  ],
  "mustache": [
    "Mustache"
  ],
  "north brittleshin pass": [
    "Septentrionis Fragilicruris Commeatus"
  ],
  "eyeargonianfemale": [
    "EyeArgonianFemale"
  ],
  "elven helmet of major conjuring": [
    "Alfa Galea Maioris Vocandi"
  ],
  "you don't like mikael": [
    "Mikael tibi non placet"
  ],
  "actually, you need to take a look at this": [
    "Re vera, opus est tibi hanc videre"
  ],
  "what was that all about": [
    "De quo agebatur"
  ],
  "haven't they been gone a long time": [
    "Nonne abhinc multum temporis affuÃªrunt"
  ],
  "condition on spectator idle anims": [
    "Condition on Spectator Idle Anims"
  ],
  "tell me more about the black-briars": [
    "Dic mihi plus de Atri-Dumis"
  ],
  "hallowed ebony mace": [
    "Consacrata Maca Ebeni"
  ],
  "trespassagainstnc": [
    "TrespassAgainstNC"
  ],
  "virtuous elven mace": [
    "Virtutis Maca Alfa"
  ],
  "dwarven helmet of eminent archery": [
    "Dvemeris Galea Eminentis Saggitationis"
  ],
  "yes, i have": [
    "Sic, id feci"
  ],
  "screaming": [
    "Clamatio"
  ],
  "dcetnoticecorpse": [
    "DCETNoticeCorpse"
  ],
  "kraldar's house": [
    "Kraldaris Domus"
  ],
  "don't stormcloaks call themselves \"sons and daughters of skyrim\"": [
    "Nonne Sturmmanteli se \"filii filiae que Caelifinis\" vocant"
  ],
  "fort blackmoor prison key": [
    "Clavis ad Carcerem Castelli Atrimuri"
  ],
  "gold jeweled necklace": [
    "Auri Gemmatum Monile"
  ],
  "what are your orders": [
    "Quae sunt mandata tua"
  ],
  "what else is there to be learned about restoration magic": [
    "Quid aliud discendum est de Magica Recreationis"
  ],
  "then stand aside": [
    "Erge emove te"
  ],
  "i have work to do": [
    "Labor mihi restat"
  ],
  "sanctified dwarven warhammer": [
    "Sanctificatus Dvemeris Bellimalleus"
  ],
  "scaled helmet of destruction": [
    "Galea Squamata Destructionis"
  ],
  "dialoguedushnikhyallonghousescene03": [
    "DialogueDushnikhYalLonghouseScene03"
  ],
  "deliver the letter and amulet to astrid": [
    "Fer Epistulam et Monile Astridi"
  ],
  "sounds dangerous": [
    "Periculosus mihi videtur"
  ],
  "how can you be certain": [
    "Quommodo scire potes"
  ],
  "steal case of firebrand wine": [
    "Furare Vinum Ignicauteris"
  ],
  "karliah's invisibility": [
    "Karliah's Invisibility"
  ],
  "does the guild provide other services": [
    "Datne Sodalitas alia munera"
  ],
  "many have fought and died": [
    "Multi pugnavÃªrunt et mortui sunt"
  ],
  "i'm no different than they are": [
    "Ab eis non differo"
  ],
  "what did you want me to do": [
    "Quid me facere voluisti"
  ],
  "browsmalehumanoid06": [
    "BrowsMaleHumanoid06"
  ],
  "you might want to talk some sense into keerava": [
    "Fortasse Keeravae ad bonam suadere velis"
  ],
  "virtuous glass bow": [
    "Virtutis Arcus Vitreus"
  ],
  "i have a delivery from hillevi": [
    "Cistam tibi habeo a Hillevi"
  ],
  "to blazes with nocturnal": [
    "Ad inferos cum Nocturnali"
  ],
  "blazes with nocturnal": [
    "Ad inferos cum Nocturnali"
  ],
  "i'm here for the eyes": [
    "Hic sum oculis"
  ],
  "humanbeard09": [
    "HumanBeard09"
  ],
  "spriggans fighting trolls": [
    "Spriggans fighting Trelliss"
  ],
  "how am i doing": [
    "Bene feci"
  ],
  "door to omluag's room": [
    "Ianuam ad conclave Omluagis"
  ],
  "you owe her gold": [
    "Aurum ei debes"
  ],
  "pay it": [
    "Solve id"
  ],
  "i'll take that job": [
    "Illud munus accipiam"
  ],
  "brurid": [
    "Brurid"
  ],
  "fort kastav prison": [
    "Carcer Castelli Kastav"
  ],
  "high gate ruins catacombs": [
    "Catacumbae Ruinarum Portae Altae"
  ],
  "danica pure-spring": [
    "Danica Puri-Rivulus"
  ],
  "jester's hat": [
    "Macci Petasus"
  ],
  "is business in morthal slow": [
    "Estne negotium lentum Morthali"
  ],
  "expel daedra": [
    "Repelle Daedram"
  ],
  "deadly aversion to fire": [
    "Letifer Aversio Igni"
  ],
  "steel plate bracers of smithing": [
    "Chirothecae Laminatae Ferrarii"
  ],
  "dwarven war axe of arcing": [
    "Dvemeris Bellisecuris Electricitatis"
  ],
  "dragonplate helmet of peerless alchemy": [
    "Draconilaminata Galea Optimae Alchemiae"
  ],
  "of fjori and holgeir": [
    "De Fjori et Holgeir"
  ],
  "orcish war axe of devouring": [
    "Orci Bellisecuris Consumendi"
  ],
  "iddra wants you to forget about all that coin you owe": [
    "Iddra te de pecunia debita oblivisci vult"
  ],
  "honed ancient nord sword of frost": [
    "Melior Germanicus Gladius Antiquus Gelidus"
  ],
  "keep out": [
    "Noli intrare"
  ],
  "you don't look like a guard": [
    "Custos non videris"
  ],
  "you and ulfric are both mistaken": [
    "Tu et Vercingetorix ambo erravistis"
  ],
  "i fight for skyrim's freedom": [
    "Pugno pro libertate Caelifinis"
  ],
  "i talked to the greybeards": [
    "Canabarbis adlocutus sum"
  ],
  "we can discuss it later": [
    "De eo postea colloqui possumus"
  ],
  "we need to proceed": [
    "Procedere debemus"
  ],
  "steel helmet of minor restoration": [
    "Adamantea Galea Minoris Reficiendi"
  ],
  "db03muirialainwhybranchtopic": [
    "DB03MuiriAlainWhyBranchTopic"
  ],
  "orcish battleaxe of binding": [
    "Orci Bellisecuris Deligandi"
  ],
  "glass battleaxe of freezing": [
    "Bellisecuris Vitreus Gelidus"
  ],
  "fort sungard annex": [
    "Annex Castelli Auroris"
  ],
  "anything else in it for me": [
    "Quid inest mihi"
  ],
  "iron mace of dismay": [
    "Ferri Maca Timoris"
  ],
  "oglub": [
    "Oglub"
  ],
  "you live here": [
    "Hic vivis"
  ],
  "imperial helmet of restoration": [
    "Romana Galea Recreandi"
  ],
  "how could you execute a fine and honorable man": [
    "Quomodo supplicium homini honorabili dare potuisti"
  ],
  "one of his dragon allies could tell us": [
    "Unus ex sociis draconibus eius nobis narrare possit"
  ],
  "ms10hellos": [
    "MS10Hellos"
  ],
  "any place i can find work": [
    "Esne locus in quo laborem ineni possum"
  ],
  "grisvar the unlucky": [
    "Grisvar Infelix"
  ],
  "c03sharedinfos": [
    "C03SharedInfos"
  ],
  "dryston's note": [
    "Epistula Drystonis"
  ],
  "where can i get a drink": [
    "Ubi pocula accipere possum"
  ],
  "dragonscale helmet of extreme restoration": [
    "Draconisquamata Galea Extremis Reficiendi"
  ],
  "icons\\weapons\\handtohand.dds": [
    "Icons\\Weapons\\HandToHand.dds"
  ],
  "you should be safe now": [
    "Tutum esse nunc debes"
  ],
  "i'm ready for the last contract": [
    "Paratus sum foederi ultimo"
  ],
  "who's silus": [
    "Quis est Silus"
  ],
  "what's this museum": [
    "Quid est hoc mouseion"
  ],
  "hairmaleorc19": [
    "HairMaleOrc19"
  ],
  "argis the bulwark": [
    "Argis Propugnaculum"
  ],
  "dirge": [
    "Aelinos"
  ],
  "sondas's note": [
    "Epistula Sondas"
  ],
  "so you think what he did was right": [
    "Ergo censes id quod eum fecisse decorum esse"
  ],
  "i'm afraid i don't know, sir": [
    "Timeo ut me nescire, domine"
  ],
  "return to the night mother": [
    "Reveni ad Matrem Noctis"
  ],
  "lesser power": [
    "Potestas Minor"
  ],
  "elven bow of animus": [
    "Arcus Alfus Animi"
  ],
  "sacred font": [
    "Fons Sacer"
  ],
  "i don't think i can help you": [
    "Puta me te adiuvare non posse"
  ],
  "sibbi black-briar": [
    "Sibbi Ater-Dumus",
    "Sibbi Black-Briar"
  ],
  "ebony warhammer of depleting": [
    "Bellimalleus Ebeni Deplendi"
  ],
  "you sound a little confused": [
    "Paulum confundus videris"
  ],
  "tell me, what's it like being boethiah's champion": [
    "Dic mihi, tibine Vindex Boethis esse placet"
  ],
  "why is this amulet so important to you": [
    "Quamobrem hoc monile tam magni momenti tibi est"
  ],
  "birds' nest": [
    "Nidus Avium"
  ],
  "you're probably overthinking this": [
    "Nimium de hoc probabiliter cogitas"
  ],
  "resist disease": [
    "Resiste Morbo"
  ],
  "i want to talk to you about calcelmo": [
    "Volo tecum de Calcelmone loqui"
  ],
  "he has feelings for you": [
    "Ille te curat"
  ],
  "forsworn arrow": [
    "Sagitta Abiurata"
  ],
  "what do you think of him": [
    "Quid de eo censes"
  ],
  "i was probably mistaken": [
    "Probabiliter erravi"
  ],
  "spell tome: frenzy": [
    "Liber Magicus: Insanitas"
  ],
  "alchemist's note": [
    "Epistula Alchemistae"
  ],
  "illusionbodyartholder": [
    "IllusionBodyArtHolder"
  ],
  "the seed": [
    "Semen"
  ],
  "i need some practice arrows": [
    "Postulo nonnullos sagittas exercitii"
  ],
  "what are the rules again": [
    "Quae sunt regula, iterum"
  ],
  "there are advantages to gaining <alias=imperialminorholdcapital>": [
    "Benificia sunt in <Alias=ImperialMinorHoldCapital> havendo"
  ],
  "slaughterfish egg": [
    "Ovum Piscicaesus"
  ],
  "quiet casting": [
    "Magica Silens"
  ],
  "hallowed dwarven mace": [
    "Consacrata Dvemeris Maca"
  ],
  "marksmalehumanoid01leftgash": [
    "MarksMaleHumanoid01LeftGash"
  ],
  "thieves guild dialogue scene 06": [
    "Sodalitatis Furum Dialogue Scene 06"
  ],
  "spell tome: fast healing": [
    "Liber Magicus: Celer Sanitatio"
  ],
  "frost atronach attack": [
    "Frost Atronach Attack"
  ],
  "tolfdir sent me to help you": [
    "Tolfdir mihi misit ut te adiuvarem"
  ],
  "perfect touch": [
    "Tactus Perfectus"
  ],
  "orcish greatsword of animus": [
    "Orci Spatha Animi"
  ],
  "do you know what they mean": [
    "Scisne quid sibi velint"
  ],
  "maybe i can teach you thing or two about using a bow": [
    "Fortasse te paulum de arte saggitandi docere possum"
  ],
  "looks like you're no longer needed": [
    "Mihi videtur te non iam necesse esse"
  ],
  "time to die": [
    "Tempus est moriendi"
  ],
  "reyda's necklace": [
    "Reydae Monile"
  ],
  "key to riverwood trader": [
    "Clavis ad Mercatorem Lignirivi"
  ],
  "i must speak with amaund motierre once more": [
    "Debeo Amaund Motierre iterum Adloqui"
  ],
  "h1l": [
    "H1l"
  ],
  "dwarven helmet of eminent restoration": [
    "Dvemeris Galea Eminentis Reficiendi"
  ],
  "where's jorrvaskr": [
    "Ubi est Jorrvaskr"
  ],
  "orcish helmet of extreme destruction": [
    "Orci Galea Extremis Destructionis"
  ],
  "who should i talk to for work": [
    "Quem labori adloqui debeo"
  ],
  "hairlinemaleorc15": [
    "HairLineMaleOrc15"
  ],
  "the dragon at <alias=bountylocation> is dead. i'm here for the bounty": [
    "Draco in <Alias=BountyLocation> mortuus est. Hic sum praemio"
  ],
  "dragon at <alias=bountylocation> is dead. i'm here for the bounty": [
    "Draco in <Alias=BountyLocation> mortuus est. Hic sum praemio"
  ],
  "orcish sword of devouring": [
    "Orci Gladius Consumendi"
  ],
  "here's that special ale you wanted": [
    "Hic habeo vinum speciale quod voluisti"
  ],
  "orcish bow": [
    "Arcus Orci"
  ],
  "ebony mace of expelling": [
    "Maca Ebeni Expellendi"
  ],
  "optional traits: %d/%d selected": [
    "OPTIONAL TRAITS: %d/%d Selected"
  ],
  "silus's museum key": [
    "Clavis ad Mouseion Sili"
  ],
  "east empire warehouse faction": [
    "East Empire Warehouse Faction"
  ],
  "ulfric's boots": [
    "Vercingetorigis Caligae"
  ],
  "the ghost mentioned a \"hjalti.\"": [
    "Phantasma \"Hjalti\" commemoravit"
  ],
  "ghost mentioned a \"hjalti.\"": [
    "Phantasma \"Hjalti\" commemoravit"
  ],
  "you mentioned the great war": [
    "Commemoravisti Bellum Magnum"
  ],
  "hairlinemalenord16": [
    "HairLineMaleNord16"
  ],
  "a place in court seems to suit you": [
    "Locus in aula par tibi videtur"
  ],
  "place in court seems to suit you": [
    "Locus in aula par tibi videtur"
  ],
  "elven greatsword of devouring": [
    "Spatha Alfa Consumendi"
  ],
  "what can you tell me about whiterun": [
    "Quid mihi narrare potes de Albacurso"
  ],
  "you must really love to fight": [
    "Punare certe amas"
  ],
  "solution of enhanced stamina": [
    "Solutio Maioris Staminae"
  ],
  "why'd the companions reject you": [
    "Cur Contubernales te despexÃªrunt"
  ],
  "ebony greatsword of the vampire": [
    "Spatha Ebeni Vampiri"
  ],
  "daedric helmet of eminent illusion": [
    "Galea Daedrica Eminentis Inlusionis"
  ],
  "steel greatsword": [
    "Spatha Adamantea"
  ],
  "i'll bring it to you": [
    "Id tibi adferam"
  ],
  "do you like being a blacksmith": [
    "Tibine placet ferarrium esse"
  ],
  "iron dagger of weariness": [
    "Pugio Ferri Maioris Defatigationis"
  ],
  "potion of extreme magicka": [
    "Potio Extremis Magicae"
  ],
  "remember now": [
    "Nunc meministi"
  ],
  "steel mace of fatigue": [
    "Maca Adamantea Defatigationis"
  ],
  "malign aversion to shock": [
    "Aversio Maligna Electricitati"
  ],
  "find the money, or you'll have me to deal with": [
    "Inveni pecuniam, vel mecum adtractes"
  ],
  "elven dagger of fear": [
    "Pugio Alfus Timoris"
  ],
  "aval atheron": [
    "Aval Atheron"
  ],
  "elven boots of major sneaking": [
    "Caligae Alfae Maioris Repsendi"
  ],
  "rally - master of the mind": [
    "Rally - Dominus Mentis"
  ],
  "(sharedinfo)": [
    "(Sharedinfo)"
  ],
  "you have signed out of your profile and will now be returned to the main menu": [
    "You have signed out of your profile et will now be returned to main menu"
  ],
  "ebony greatsword of harvesting": [
    "Spatha Ebeni Saturni"
  ],
  "actor fade": [
    "Actor Fade"
  ],
  "grand staff of charming": [
    "Rudis Magnus Iocunditatis"
  ],
  "so it's time? we're ready to assassinate the emperor": [
    "Ergo tempus est? Parati sumus ad Imperatorem necandum"
  ],
  "mystery of talara, v 2": [
    "Misterium Talarae, Pars II"
  ],
  "i know that mound - high on the hill east of kynesgrove": [
    "Illum cumulum novi - in summo colle ad eurum Kynenemoris"
  ],
  "pick up harrald's sword from balimund": [
    "Accipe Gladium Harraldis a Balimundo"
  ],
  "deadbeats": [
    "Obaerati"
  ],
  "what'd they do": [
    "Quid fecÃªrunt"
  ],
  "i'm not ready to go yet": [
    "Nondum paratus sum"
  ],
  "elven dagger of enervating": [
    "Pugio Alfus Enervationis"
  ],
  "i don't have any more questions": [
    "Nulla plurima interrogata habeo"
  ],
  "what would you like me to do": [
    "Quid me facere vis"
  ],
  "here's your heartscale": [
    "Hic habes Corsquamam"
  ],
  "he's going to owe us for this betrayal": [
    "Nos pro hac proditione poenas dabit"
  ],
  "chaurus faction": [
    "Chaurus Faction"
  ],
  "hairfemaledremora02": [
    "HairFemaleDremora02"
  ],
  "your wife is in mourning": [
    "Uxor tua in toga pulla est"
  ],
  "is cicero dangerous": [
    "Estne periculosus Cicero"
  ],
  "wuunferth the unliving": [
    "Vuunferth Invivens"
  ],
  "steel greatsword of fatigue": [
    "Spatha Adamantea Defatigationis"
  ],
  "glass dagger of harvesting": [
    "Pugio Vitreus Saturni"
  ],
  "you're looking to become a merchant": [
    "Mercatorem esse vis"
  ],
  "are you mad": [
    "Esne insanus"
  ],
  "i could have died": [
    "Mori possem"
  ],
  "hallowed dwarven warhammer": [
    "Consacratus Dvemeris Bellimalleus"
  ],
  "daedric bow of expelling": [
    "Arcus Daedricus Expellendi"
  ],
  "restoration dual casting": [
    "Magica Reficiendi Ambabus Manibus"
  ],
  "i have a sword for you, from your daughter": [
    "Hunc gladium tibi habeo, a filia"
  ],
  "iron helmet of minor alteration": [
    "Ferri Galea Minoris Mutationis"
  ],
  "if i find one, i'll bring it to you": [
    "Si unam invenero, tibi adferam"
  ],
  "if i find a copy, i'll bring it to you": [
    "Si exemplar invenero, tibi adferam"
  ],
  "morven": [
    "Morven"
  ],
  "the arch-mage asked me to see you about class": [
    "Archimagus me oravit ut te de schola tua rogarem"
  ],
  "arch-mage asked me to see you about class": [
    "Archimagus me oravit ut te de schola tua rogarem"
  ],
  "daedric shield of extreme blocking": [
    "Scutum Daedricum Extremis Interponendi"
  ],
  "i don't have time for book-hunting": [
    "Tempus mihi non est ut libros vener"
  ],
  "dwarven battleaxe of draining": [
    "Dvemeris Bellisecuris Hauriendi"
  ],
  "can you tell me about the wedding ring": [
    "Potesne mihi de anulo nuptiali narrare"
  ],
  "i'm sorry": [
    "Me paenitet",
    "Ignosce"
  ],
  "where do i go": [
    "Quo eo"
  ],
  "porthole": [
    "Ascende Anchoram"
  ],
  "moth gro-bagol": [
    "Moth gro-Bagol"
  ],
  "i'm afraid he's not here": [
    "Timeo ut ille hic non sit"
  ],
  "he didn't think it was safe to return": [
    "Putavit revenire tutum non esse"
  ],
  "here's your black-briar mead": [
    "Hic habes Hydromellum tuum Atri-Dumi"
  ],
  "forsworn missile": [
    "Forsworn Missile"
  ],
  "orcish greatsword of draining": [
    "Orci Spatha Hauriendi"
  ],
  "i found that book you wanted": [
    "Illum librum quem voluisti inveni"
  ],
  "what's wrong with ranmir": [
    "Quid accidit cum Ranmir"
  ],
  "you work at the stables": [
    "Ad stabulum laboras"
  ],
  "who's roggi": [
    "Quis est Roggi"
  ],
  "charwich-koniinge letters, v1": [
    "Epistulae Charvich-Koniinge, I"
  ],
  "apprentice grimoire": [
    "Apprentice Grimoire"
  ],
  "helgen keep": [
    "Castellum Helgenis"
  ],
  "moving while holding down attack results in a power attack": [
    "Movere dum oppugnans facit ictum potestatis"
  ],
  "moving in different directions creates different power attacks": [
    "Ad alias partes movere dum oppugnans facit varios ictus"
  ],
  "effects\\explosionsplash.nif": [
    "Effects\\ExplosionSplash.NIF"
  ],
  "essence of magicka": [
    "Essence of Magicae"
  ],
  "are you threatening me": [
    "Minarisne me"
  ],
  "draught of the warrior": [
    "Decocta Bellatoris"
  ],
  "someone giving you trouble": [
    "Aliquisne tibi molestat"
  ],
  "vigor poison": [
    "Venenum Vigoris"
  ],
  "withershins": [
    "Vithershins"
  ],
  "embershard mine": [
    "Metallum Cinitestae"
  ],
  "the lady: recover health": [
    "Domina: Recupera Salutem"
  ],
  "lady: recover health": [
    "Domina: Recupera Salutem"
  ],
  "db08_festus_gourmetinnbranchtopic": [
    "DB08_Festus_GourmetInnBranchTopic"
  ],
  "glass bow of animus": [
    "Arcus Vitreus Animi"
  ],
  "marksfemalehumanoid07rightgash": [
    "MarksFemaleHumanoid07RightGash"
  ],
  "apprentice fire mage": [
    "Discipulus Ignimagus"
  ],
  "neck color": [
    "Color Colli"
  ],
  "i want you to leave omluag alone": [
    "Volo te Omluag desinere"
  ],
  "second remains": [
    "Reliquiae Secundae"
  ],
  "orcish mace of shocks": [
    "Orci Maca Electricitatis"
  ],
  "honed ancient nord battle axe of cold": [
    "Melior Germanicus Antiquus Bellisecuris Frigidis"
  ],
  "glass greatsword of reaping": [
    "Spatha Vitrea Saturni"
  ],
  "holy daedric bow": [
    "Sanctus Arcus Daedricus"
  ],
  "shrouded robes": [
    "Umbratum Peplum"
  ],
  "virtuous elven bow": [
    "Virtutis Arcus Alfus"
  ],
  "you just need to have something to talk about": [
    "Modo aliquid de quo loqui potes invenire debes"
  ],
  "i never thought about it": [
    "Numquam de eo cogitavi"
  ],
  "blue butterfly": [
    "Papilionem Caeruleum"
  ],
  "mg dormitory scene 9": [
    "MG Dormitory Scene 9"
  ],
  "steel shield of fire resist": [
    "Adamanteum Scutum Ignem Resistendi"
  ],
  "imperial light helmet of minor conjuration": [
    "Romana Cassis Minoris Coniurationis"
  ],
  "steel mace of ice": [
    "Maca Adamantea Gelidus"
  ],
  "iron sword of fear": [
    "Gladius Ferri Timoris"
  ],
  "falmer sword": [
    "Gladius Falmeris"
  ],
  "steel plate helmet of eminent illusion": [
    "Laminata Galea Eminentis Inlusionis"
  ],
  "the waters of oblivion": [
    "Aquae Oblivionis"
  ],
  "waters of oblivion": [
    "Aquae Oblivionis"
  ],
  "he sounds persistent": [
    "Persistens videtur"
  ],
  "why not do something about it": [
    "Quidni aliquid de eo faciemus"
  ],
  "joric": [
    "Joric"
  ],
  "db02captive3intimidate": [
    "DB02Captive3Intimidate"
  ],
  "ebony bow of thunderbolts": [
    "Arcus Ebeni Fulminum"
  ],
  "daedric boots of fire suppression": [
    "Caligae Daedricae Suppressionis Ignis"
  ],
  "maybe we can come to an understanding": [
    "Fortasse ad consensum advenire possumus"
  ],
  "by right of glory": [
    "Gloria"
  ],
  "i lead the companions of jorrvaskr": [
    "Contubernales Jorrvaskris duco"
  ],
  "you're not taking me alive": [
    "Me vivum non capies"
  ],
  "could you use some help": [
    "Visne auxilium"
  ],
  "then we do this the hard way": [
    "Ergo viam duram sequamur"
  ],
  "pay them, or things will get ugly. (intimidate)": [
    "Pecuniam eis solva vel... (Intimida)"
  ],
  "are there many problems with the locals": [
    "Suntne difficultates cum incolis"
  ],
  "tell me about alduin's wall": [
    "Dic mihi de Pariete Alduinis"
  ],
  "leave her alone, or else. (brawl)": [
    "Desine ab ea, vel... (Pugna Manibus)"
  ],
  "leave her alone, or else. (intimidate)": [
    "Desine ab ea, vel... (Intimida)"
  ],
  "glass warhammer of lightning": [
    "Bellimaleus Vitreus Fulminis"
  ],
  "elven greatsword of malediction": [
    "Spatha Alfa Maledictionis"
  ],
  "luck is meaningless. what we do involves skill": [
    "Sors nihil est. Id quod facimus arte utitur"
  ],
  "elixir of lockpicking": [
    "Commixtum Clavicarii"
  ],
  "why is it so empty here": [
    "Cur tam vacuus hic est"
  ],
  "irnskar's agreed to forgive your debt": [
    "Irnskar assensit aes alienum solvere"
  ],
  "falkreath imperial camp": [
    "Falkretiae Imperialis Castra"
  ],
  "glass warhammer of dread": [
    "Bellimaleus Vitreus Timoris"
  ],
  "iron claw": [
    "Ungues Draconis Ferri"
  ],
  "imperial boots of hauling": [
    "Caligae Romanae Portandi"
  ],
  "talk to paratus": [
    "Paratum Adloquere"
  ],
  "corundum ingot": [
    "Corundi Later"
  ],
  "<alias.shortname=target> won't be bothering you anymore": [
    "<Alias.ShortName=Target> tibi plures dificultatis non dabit"
  ],
  "incense fumes": [
    "Fumum Incensi"
  ],
  "those bandits back there": [
    "Illi latrones"
  ],
  "facial hair color": [
    "Color Capillis Faciei"
  ],
  "imperial bracers of minor destruction": [
    "Chirothecae Romanae Minoris Destructionis"
  ],
  "elven bow of despair": [
    "Arcus Alfus Desponsionis"
  ],
  "what's happening": [
    "Quid fit"
  ],
  "hairmaleorc03": [
    "HairMaleOrc03"
  ],
  "slowtime": [
    "SlowTime"
  ],
  "recover andurs' amulet of arkay": [
    "Refer Anduris Monile Arkayae"
  ],
  "the guardian stones": [
    "Custodis Lapis"
  ],
  "guardian stones": [
    "Custodis Lapis"
  ],
  "riften guard's armor": [
    "Hiati Custodis Lorica"
  ],
  "elven sword of the blaze": [
    "Alfus Gladius Congflagrantis"
  ],
  "civilian allies of sons of skyrim faction": [
    "civilian allies of Sons of Skyrim faction"
  ],
  "do you get many guests": [
    "Multosne hospites accipis"
  ],
  "(<global=hdriftenalchemy> gold)": [
    "(<Global=HDRiftenAlchemy> auri)"
  ],
  "faryl atheron": [
    "Faryl Atheron"
  ],
  "riften the scorched hammer faction": [
    "Hiati The Scorched Hammer Faction"
  ],
  "dragonscale boots of the mammoth": [
    "Caligae Draconisquamatae Elephantis"
  ],
  "orcish greatsword of consuming": [
    "Orci Spatha Consumendi"
  ],
  "solitude catacombs": [
    "Solitudinis Catacumbae"
  ],
  "gorm's letter": [
    "Epistula Gormis"
  ],
  "testreadingdude": [
    "TestReadingDude"
  ],
  "glass helmet of extreme destruction": [
    "Galea Vitrea Extremis Destructionis"
  ],
  "thalmor soldier": [
    "Visigothi Miles"
  ],
  "he... didn't make it": [
    "Ille... non superfuit"
  ],
  "return to klimmek": [
    "Reveni ad Klimmekem"
  ],
  "steel armor of minor health": [
    "Adamantea Lorica Minoris Salutis"
  ],
  "philter of the warrior": [
    "Philtrum Bellatoris"
  ],
  "grand plaza scene 21": [
    "Grand Plaza Scene 21"
  ],
  "madesi's strongbox": [
    "Arca Madesi"
  ],
  "for dailogue when thalmor have prisoner with them": [
    "For dailogue when Visigothi have prisoner with them"
  ],
  "valdr": [
    "Valdr"
  ],
  "i thought i did the job like you asked": [
    "Credidi me munus fecisse ut rogavisse"
  ],
  "elven greatsword of thunderbolts": [
    "Spatha Alfa Fulminum"
  ],
  "key to maiden-loom manor": [
    "Clavis ad Villam Virgini-Telae"
  ],
  "dwarven helmet of eminent illusion": [
    "Dvemeris Galea Eminentis Inlusionis"
  ],
  "ma'randru-jo": [
    "Ma'randru-jo"
  ],
  "am i to do this alone": [
    "Hocne solum faciam"
  ],
  "shul": [
    "Shul"
  ],
  "dwarven sword of despair": [
    "Gladius Dvemeris Desponsionis"
  ],
  "ogmund's amulet of talos": [
    "Ogmundis Monile Talos"
  ],
  "what can you tell me about the dragon cult": [
    "Quid mihi dicere potes de Religione Draconum"
  ],
  "glass warhammer of ice": [
    "Bellimaleus Vitreus Gelidus"
  ],
  "if thorald is alive, where is he": [
    "Si Thorald viveret, ubi esset"
  ],
  "glass greatsword of depleting": [
    "Spatha Vitrea Deplendi"
  ],
  "ebony dagger of harvesting": [
    "Pugio Ebeni Saturni"
  ],
  "briehl": [
    "Briehl"
  ],
  "you \"hope\"": [
    "Speras"
  ],
  "you don't sound very sure": [
    "Non certa mihi videris"
  ],
  "question anton virane": [
    "Interroga Anton Virane"
  ],
  "dwarven sword of soul snares": [
    "Gladius Dvemeris Animicapti"
  ],
  "steel helmet of major restoration": [
    "Adamantea Galea Maioris Reficiendi"
  ],
  "how about some skooma instead": [
    "Quidni paulum skoomae"
  ],
  "mg dormitory scene 4": [
    "MG Dormitory Scene 4"
  ],
  "septimus signus": [
    "Septimus Signus"
  ],
  "it served a purpose": [
    "Munus suum fecit"
  ],
  "they're all dead": [
    "Omnes mortui sunt"
  ],
  "report to gianna while wearing a chef's hat": [
    "Refer te ad Giannam dum Pestasum Coqui Induas"
  ],
  "tova shatter-shield": [
    "Tova Scuti-Fracti"
  ],
  "guardian circle restore health": [
    "Guardian Circle Restore Health"
  ],
  "falmer servant": [
    "Falmer Servant"
  ],
  "master destruction": [
    "Dominus Destructionis"
  ],
  "elven dagger of harrowing": [
    "Pugio Alfus Occationis"
  ],
  "pinewatch key": [
    "Clavis ad Pinivisum"
  ],
  "here's the 300 gold": [
    "Hic habes CCC auri"
  ],
  "beitild's house": [
    "Domus Beitildis"
  ],
  "here's the gold you wanted": [
    "Hic est aurum quod poposcisti"
  ],
  "the world is a better place without alduin": [
    "Mundus est locus melior sine Alduino"
  ],
  "world is a better place without alduin": [
    "Mundus est locus melior sine Alduino"
  ],
  "sanctified orcish dagger": [
    "Sanctificatus Pugio Orcorum"
  ],
  "ms01guardtrigger": [
    "MS01GuardTrigger"
  ],
  "i need to learn the dragonrend shout": [
    "Opus mihi est clamorem Draconicaesem discere"
  ],
  "can you teach me": [
    "Potesne me docere"
  ],
  "self": [
    "Se"
  ],
  "marksmalehumanoid12rightgashr": [
    "MarksMaleHumanoid12RightGashR"
  ],
  "what should we do": [
    "Quid facere debemus"
  ],
  "stop lying or i'll beat it out of you": [
    "Desiste a periurio vel verum e te verberabo"
  ],
  "dwarven mace of frost": [
    "Dvemeris Maca Gelidus"
  ],
  "glass sword of winnowing": [
    "Gladius Vitreus Ventagii"
  ],
  "the companions": [
    "The Companions"
  ],
  "companions": [
    "The Companions"
  ],
  "carrots": [
    "Carotae"
  ],
  "it's been dealt with": [
    "Tractatum est"
  ],
  "holy ebony battleaxe": [
    "Sanctus Bellisecuris Ebeni"
  ],
  "karliah's armor": [
    "Karliae Lorica"
  ],
  "nurelion only gave me five septims": [
    "Nurelion modo quinque denerios dedit"
  ],
  "the city of stone": [
    "Urbs Lapidis"
  ],
  "city of stone": [
    "Urbs Lapidis"
  ],
  "bolar services": [
    "Bolar Services"
  ],
  "north wind's prayer": [
    "Volturnalia"
  ],
  "tglarcenyshared": [
    "TGLarcenyShared"
  ],
  "breya": [
    "Breya"
  ],
  "you're a ghost": [
    "Phantasma es"
  ],
  "forget i asked": [
    "Obliviscere me rogavisse"
  ],
  "glass sword of despair": [
    "Gladius Vitreus Desponsionis"
  ],
  "it was never a request": [
    "Numquam rogatum erat"
  ],
  "so what exactly is the problem": [
    "Ergo, quid est difficultas"
  ],
  "glass battleaxe of thunderbolts": [
    "Bellisecuris Vitreus Fulminum"
  ],
  "tell me about maluril": [
    "Dic mihi de Maluril"
  ],
  "maybe you should speak to the authorities": [
    "Fortasse tibi custodes adloquendum est"
  ],
  "draught of enhanced stamina": [
    "Decocta Maioris Staminae"
  ],
  "glass dagger of fear": [
    "Pugio Vitreus Timoris"
  ],
  "steel warhammer of sparks": [
    "Bellimalleus Adamanteus Scintillarum"
  ],
  "who is your master": [
    "Quis est dominus tuus"
  ],
  "skirmisher's draught": [
    "Velitis Decocta"
  ],
  "fort fellhammer mines": [
    "Metalla Castelli Fellimallei"
  ],
  "solution of strength": [
    "Solutio Fortitudinis"
  ],
  "forsworn missive": [
    "Epistula Abiurata"
  ],
  "withdraw offer to help": [
    "Remove offertum auxilii"
  ],
  "powers": [
    "VIRES"
  ],
  "queen bee statue": [
    "Statua Reginae Apium"
  ],
  "firebrand wine": [
    "Ignifacis Vinum"
  ],
  "why is this called candlehearth hall": [
    "Cur nomen 'Aula Candelifoci' habet"
  ],
  "torchbug thorax": [
    "Thorax Facicimex"
  ],
  "i don't understand why you won't come with me": [
    "Necsio cur mecum non venias"
  ],
  "what can you tell me about gallus": [
    "Quid mihi dicere potes de Gallo"
  ],
  "what do you do for the jarl": [
    "Quid pro Comite facis"
  ],
  "wedding wreath": [
    "Corona Nuptualis"
  ],
  "assassin's blade": [
    "Ensis Sicarii"
  ],
  "what kind of magic do you study": [
    "Qualem magicam discis"
  ],
  "kynesgrove dialogue": [
    "Kynesgrove Dialogue"
  ],
  "do you think the white phial exists": [
    "Putasne Atrophorion Album existere"
  ],
  "elven boots of flame suppression": [
    "Caligae Alfae Suppressionis Flammarum"
  ],
  "elven warhammer": [
    "Alfus Bellimaleus"
  ],
  "elven dagger of consuming": [
    "Pugio Alfus Consumendi"
  ],
  "heavy armor forging": [
    "Loricam Gravem Facere"
  ],
  "why are all the dock workers argonians": [
    "Cur sunt Argonii omnes qui in navali laborant"
  ],
  "voicepowerendshort": [
    "VoicePowerEndShort"
  ],
  "how long have you lived in windhelm": [
    "Quamdiu Ventigaleae vixisti"
  ],
  "so where are we headed": [
    "Quo imus"
  ],
  "rune spells create magical traps, but only on surfaces close to the caster": [
    "Magica Runarum insidias magicae facit, sed solum in pavimento vel parietibus prope te"
  ],
  "dragonplate helmet of extreme illusion": [
    "Draconilaminata Galea Inlusionis Extremae"
  ],
  "must be tough to grow anything out here": [
    "Res hic colere difficile factu debet esse"
  ],
  "how long have you been in windhelm": [
    "Quamdiu Ventigaleae fuisti"
  ],
  "ref_attach_node": [
    "REF_ATTACH_NODE"
  ],
  "ebony warhammer of debilitation": [
    "Bellimalleus Ebeni Debilitationis"
  ],
  "you look very young": [
    "Iuvenissima videris"
  ],
  "magicka recovery poison": [
    "Magicae Venenum Staminam Recuperandiis"
  ],
  "purchase a house in solitude": [
    "Domum Eme Solitudinis"
  ],
  "spectral arrow": [
    "Spectral Arrow"
  ],
  "do you do any work for the ships": [
    "Laborasne pro navibus"
  ],
  "you'd better start explaining": [
    "Opus est tibi explicare"
  ],
  "why did you join the legion": [
    "Cur Legionem adiunxisti"
  ],
  "glass mace of fire": [
    "Maca Vitrea Ignis"
  ],
  "cart driver": [
    "Conductor Curri"
  ],
  "virtuous elven warhammer": [
    "Virtutis Alfus Bellimalleus"
  ],
  "that's all i wanted to know": [
    "Haec sunt omnia quae scire volui"
  ],
  "hairshavedmaleorc15": [
    "HairShavedMaleOrc15"
  ],
  "some coin, to soothe your guilt": [
    "Paulum pecuniae, ut culpabilitatem leniat"
  ],
  "health regeneration": [
    "Salutis Recuperandi"
  ],
  "v3ng": [
    "V3ng"
  ],
  "how long have you been a stablemaster": [
    "Quamdiu magister catabuli fuisti"
  ],
  "steel warhammer of cowardice": [
    "Bellimalleus Adamanteus Timoris"
  ],
  "you said something about a burial": [
    "Dixisti aliquid de sepultura"
  ],
  "ancient shrouded cowl": [
    "Antiquus Umbratus Capucium"
  ],
  "femaleeyeshumangrey": [
    "FemaleEyesHumanGrey"
  ],
  "db07astridplayerjournalsaytopic": [
    "DB07AstridPlayerJournalSayTopic"
  ],
  "glass battleaxe of malediction": [
    "Bellisecuris Vitreus Maledictionis"
  ],
  "i don't think camilla will be spending any more time with faendal": [
    "Non credo Camillam plus temporis cum Faendali consumpturam esse"
  ],
  "ancient nord battle axe of ice": [
    "Antiquus Bellisecuris Germanicus Gelidus"
  ],
  "you want to send my soul inside, don't you": [
    "Animam meam intus mittere vis, nonne"
  ],
  "the girl is just taken from her family": [
    "Puella a familia rapitur"
  ],
  "girl is just taken from her family": [
    "Puella a familia rapitur"
  ],
  "evidence chest": [
    "Arca Evidentiae"
  ],
  "the brinehammer below deck": [
    "Salsugimalleus Sub Constrato"
  ],
  "brinehammer below deck": [
    "Salsugimalleus Sub Constrato"
  ],
  "elven bracers of major archery": [
    "Chirothecae Alfae Maioris Sagittationis"
  ],
  "tutorial": [
    "Tutorial"
  ],
  "traveling dignitary": [
    "Nobilis Peregrinans"
  ],
  "cure disease": [
    "Morbum Cura"
  ],
  "you're talking about... the end of the world": [
    "De Apocalypse loqueris"
  ],
  "here, i brought you a drink": [
    "Hic, vinum tibi habeo"
  ],
  "small dwemer lever": [
    "Fulcrum Parvum Dvemere"
  ],
  "i'm sorry about your uncle": [
    "Me paenitet de avunculo tuo"
  ],
  "i have no regrets": [
    "Nihil me paenitet"
  ],
  "alduin had to be destroyed": [
    "Alduinus necandus erat"
  ],
  "maybe i could convince her": [
    "Fortasse mentem eius mutare possum"
  ],
  "you may just have to give her time": [
    "Fortasse ei tempus dare debes"
  ],
  "i have a letter from faleen": [
    "Epistulam a Faleen habeo"
  ],
  "by right of cleverness": [
    "Ius calliditatis"
  ],
  "i am master of the college of winterhold": [
    "Archimagus sum Collegii Hiemiterrae"
  ],
  "she wants you to return to temple": [
    "Tibi templum repetere imperat"
  ],
  "actually i've got it here": [
    "Id hic habeo"
  ],
  "i'll do what i can": [
    "Faciam quid possim"
  ],
  "prevents filling aliases in wigreeting": [
    "Prevents filling aliases in WIGreeting"
  ],
  "nightingale hall": [
    "Nightingale Hall"
  ],
  "what can you tell me about the area": [
    "Quid mihi dicere potes de hac area"
  ],
  "what's with the orc": [
    "Quid cum Orco agiture"
  ],
  "maybe i can talk to her for you": [
    "Fortasse eam tibi adloquere possum"
  ],
  "see if she's interested": [
    "Videre num id ei intersit"
  ],
  "humanbeard40": [
    "HumanBeard40"
  ],
  "you cannot sleep while trespassing": [
    "Dormire non potes dum in loco es contra legem"
  ],
  "do you have a hard time getting supplies way out here": [
    "Estne difficulter tibi supplementa hic accipere"
  ],
  "i'm here for the books from the college": [
    "Hic sum pro Libris Collegii"
  ],
  "why do you hate the blades": [
    "Cur Enses in odio habes"
  ],
  "i'm just here to help": [
    "Modo hic sum adiuvandi causa"
  ],
  "ebony bow of annihilating": [
    "Arcus Ebeni Annihilationis"
  ],
  "i have news from helgen": [
    "Nuntius habeo Helgene"
  ],
  "about the dragon attack": [
    "De oppugnato draconis"
  ],
  "there was an attack in the marketplace": [
    "Impetus in foro erat"
  ],
  "elven warhammer of scorching": [
    "Alfus Bellimaleus Adurendi"
  ],
  "you helped me": [
    "Me adiuvisti"
  ],
  "i'll do what i can to help you": [
    "Faciam quidquam possum ut te adiuvem"
  ],
  "having a good time": [
    "Quid fit"
  ],
  "i want to quit that bedlam job": [
    "Nolo facere illum munus \"bedlam\""
  ],
  "dragonplate helmet of eminent archery": [
    "Draconilaminata Galea Eminentis Saggitationis"
  ],
  "karliah's hood": [
    "Karliae Capucium"
  ],
  "tythis ulen": [
    "Tythis Ulen"
  ],
  "you won't have that problem with me": [
    "Illam difficultatem mecum non habebis"
  ],
  "do you like my outfit": [
    "Amasne vestimenta mea"
  ],
  "philter of resist fire": [
    "Philtrum Resistendi Ignem"
  ],
  "kharag gro-shurkul": [
    "Kharag gro-Shurkul"
  ],
  "draught of waterbreathing": [
    "Decocta Respirandi Sub Aqua"
  ],
  "scaled bracers of major archery": [
    "Chirothecae Squamatae Maioris Sagittationis"
  ],
  "ebony bow of enervating": [
    "Arcus Ebeni Enervationis"
  ],
  "glass battleaxe of animus": [
    "Bellisecuris Vitreus Animi"
  ],
  "ancient nord battle axe of cold": [
    "Antiquus Bellisecuris Germanicus Frigidis"
  ],
  "now is it worth something": [
    "Valetne aliquid"
  ],
  "glass dagger of garnering": [
    "Pugio Vitreus Horti"
  ],
  "this city looks so ancient": [
    "Urbs tam antiqua videtur"
  ],
  "have you been here long": [
    "Multumne tempus hic consumpsi"
  ],
  "elisif loved the outfit": [
    "Elisif vestimenta amavit"
  ],
  "she'll be placing an order soon": [
    "Unum mox petet"
  ],
  "leather bracers of major deft hands": [
    "Corii Chirothecae Maioris Dexteritatis"
  ],
  "honningbrew mead": [
    "Honningbrevis Hydromellum",
    "Hydromellum Honningbrevis"
  ],
  "imperial helmet of minor illusion": [
    "Romana Galea Minoris Inlusionis"
  ],
  "alva is a vampire": [
    "Alva vampira est"
  ],
  "scroll of lightning cloak": [
    "Codex Amiculi Fulminum"
  ],
  "how's life under stormcloak rule": [
    "Quo agit vita sub regno Sturmmantelorum"
  ],
  "perhaps you'd like to share this black briar mead with me instead": [
    "Fortasse Hydromellum Atridumi mecum communicari vis"
  ],
  "iron mace of binding": [
    "Ferri Maca Deligandi"
  ],
  "orcish warhammer of shocks": [
    "Orci Bellimalleus Electricitatis"
  ],
  "why only have one bottle when you can have two": [
    "Quamobrem unam solam lagoenam haberes cum duas habere potes"
  ],
  "elven bow of malediction": [
    "Arcus Alfus Maledictionis"
  ],
  "novice censer": [
    "Novice Censer"
  ],
  "dragonscale helmet of peerless magicka": [
    "Draconisquamata Galea Maximi Magicae"
  ],
  "here, use this to buy some armor": [
    "Age, hoc utere ut loricam emas"
  ],
  "speak to eola": [
    "Eolam Adloquere"
  ],
  "i'm here to pick up harrald's sword": [
    "Hic sum ut ensem Harraldis accipiam"
  ],
  "steel sword of burning": [
    "Gladius Adamanteus Cremandi"
  ],
  "tynan": [
    "Tynan"
  ],
  "wabbajack damage": [
    "Wabbajack Damage"
  ],
  "never mind, i don't want to purchase decorations right now": [
    "Ignosce, Nolo purchase Ornamenta right nov"
  ],
  "here's nelacar's staff": [
    "Hic habes rudem Nelacaris"
  ],
  "loft decorations (<global=hdwhiterunloft> gold)": [
    "Ornamenta Solio (<Global=HDWhiterunLoft> auri)"
  ],
  "dining room decorations": [
    "Ornamenta Triclinii"
  ],
  "(<global=hdwhiterundining> gold)": [
    "(<Global=HDWhiterunDining> auri)"
  ],
  "i can use it to learn dragonrend from the ancient nords who defeated alduin": [
    "Possum eo uti ut Draconicaesem discam ab Germanis Antiquis qui Alduinum superavÃªrunt"
  ],
  "(<global=hdwhiterunliving> gold)": [
    "(<Global=HDWhiterunLiving> auri)"
  ],
  "(<global=hdwhiterunkitchen> gold)": [
    "(<Global=HDWhiterunKitchen> auri)"
  ],
  "he was": [
    "Certe"
  ],
  "here's a letter of credit": [
    "Hic habes epistulam crediti"
  ],
  "glass armor of extreme health": [
    "Vitrea Lorica Extremis Salutis"
  ],
  "elven mace of harvesting": [
    "Maca Alfa Saturni"
  ],
  "bannermist tower": [
    "Turris Vexilli Nebulae"
  ],
  "you say something about a bet": [
    "Aliquid de pignore dixisti"
  ],
  "imperial bracers of major deft hands": [
    "Chirothecae Romanae Maioris Dexteritatis"
  ],
  "hogni red-arm": [
    "Hogni Rubra-Bracchia"
  ],
  "steel helmet of minor magicka": [
    "Adamantea Galea Minoris Magicae"
  ],
  "key to grave concoctions": [
    "Clavis ad Liquamenta Gravia"
  ],
  "i could convince ainethach to sell": [
    "Ainethach ut vendat convincere possum"
  ],
  "i found this amulet in ogmund's house": [
    "Hoc monile Domni Ogmundis inveni"
  ],
  "sanctified steel battleaxe": [
    "Sanctificatus Bellisecuris Adamanteus"
  ],
  "elven shield of shock suppression": [
    "Alfum Scutum Suppresionis Electricitatis"
  ],
  "gilded wristguards": [
    "Bracchialia Aurata"
  ],
  "why are you so eager to open the box": [
    "Cur tam studiosus es ut arcam aperias"
  ],
  "would you mind cooking something for me": [
    "Visne quiquid mihi coquere"
  ],
  "relic middle finger": [
    "Reliqium Medius"
  ],
  "scaled helmet of eminent conjuration": [
    "Galea Squamata Eminentis Coniurationis"
  ],
  "i'm looking for the staff of magnus": [
    "Rudem Magni quaero"
  ],
  "can't carry any more": [
    "plus portare non potes"
  ],
  "glass mace of winnowing": [
    "Maca Vitrea Ventagii"
  ],
  "find azura's star": [
    "Inveni Stellam Azurae"
  ],
  "who runs the show at honningbrew meadery": [
    "Quis Hydromeliarium Honningbrevis administrat"
  ],
  "glass mace of freezing": [
    "Maca Vitrea Gelidus"
  ],
  "your ancestral shield is lost in a cave": [
    "Scutum Maiorum Tuorum in cava perditum est"
  ],
  "weylin": [
    "Veylin"
  ],
  "what makes black-briar mead so special": [
    "Cur est Hydromellum Atri-Dumi tam speciale"
  ],
  "hide boots of major sneaking": [
    "Renonis Caligae Maioris Repsendi"
  ],
  "elven shield of waning frost": [
    "Alfum Scutum Insenescentis Congelationis"
  ],
  "(optional) speak to brynjolf": [
    "(Optio) Adloquere Brynjolf"
  ],
  "elven helmet of eminent conjuring": [
    "Alfa Galea Coniurationis Vocantis"
  ],
  "glass gauntlets of eminent alchemy": [
    "Vitreae Manicae Alchemiae Eminentis"
  ],
  "i could find it for you": [
    "Id pro te invenire possum"
  ],
  "i'm looking for work, if you have any": [
    "Munus quaero"
  ],
  "what was that ceremony all about": [
    "De qua agebatur illo caeremonio"
  ],
  "were you shouting at me": [
    "Contra me voce utebamini"
  ],
  "what did you actually say": [
    "Quid re vera dixisti"
  ],
  "ebony helmet of extreme destruction": [
    "Ebeni Galea Extremis Destructionis"
  ],
  "daedric battleaxe of nullifying": [
    "Bellisecuris Daedricus Nullificandi"
  ],
  "lami": [
    "Lami"
  ],
  "hold on, i need to get a few things ready": [
    "Exspecta, opus est mihi paucas res parare"
  ],
  "windshear": [
    "Ventisector"
  ],
  "what does talos have to do with the war": [
    "Quid cum bello agitur Talos"
  ],
  "shaircolor9": [
    "sHairColor9"
  ],
  "thank you, master": [
    "Gratias tibi ago domine"
  ],
  "i will continue my training": [
    "Disciplinam meam pergam"
  ],
  "surely there's more you can tell me": [
    "Nonne mihi plus dicere potes"
  ],
  "what are you thinking": [
    "Quid putas"
  ],
  "philter of true shot": [
    "Philtrum Saggitationis"
  ],
  "you have nothing to fear from me": [
    "Nihil a me timere debes"
  ],
  "any idea why": [
    "Cur"
  ],
  "stros m'kai rum": [
    "Stros M'Kai Rhomium"
  ],
  "dwarven shield": [
    "Dvemere Scutum"
  ],
  "what locations does this mill supply": [
    "Quos locos nutriunt haec mola"
  ],
  "do you like it out here, away from everything": [
    "Tibine placet hic vibere, procul e omnibus"
  ],
  "steel plate bracers of major archery": [
    "Chirothecae Laminatae Maioris Sagittationis"
  ],
  "elven war axe of fear": [
    "Alfus Bellisecuris Timoris"
  ],
  "shor's stone filnjar services": [
    "Shor's Stone Filnjar Services"
  ],
  "hide shield of major blocking": [
    "Scutum Cutis Maioris Interponendi"
  ],
  "what's so important about this place": [
    "Quid tam magni momenti est de hoc loco"
  ],
  "i might": [
    "Fortasse"
  ],
  "why don't you remind me": [
    "Quidni me de eo commemoras"
  ],
  "i was told to come see you": [
    "Audivi te mihi videndum esse"
  ],
  "dragonplate boots of the firewalker": [
    "Draconilaminata Caligae Ignambulatoris"
  ],
  "do you know what happened to your house": [
    "Scisne quid domui tibi accidisse"
  ],
  "warehouse carry": [
    "Horreum Carry"
  ],
  "maybe i should visit that farm in morrowind": [
    "Fortasse illum fundum in Morrivento visitare debeo"
  ],
  "yisra's necklace": [
    "Yisrae Monile"
  ],
  "what's your stance on the war": [
    "Quid de bello censes"
  ],
  "no, i'm not really headed that way": [
    "Minime, illic non peto"
  ],
  "what is the white-gold concordat": [
    "Quid est Foedus Album-Aurum"
  ],
  "give me the details": [
    "Da mihi minutias"
  ],
  "copper and ruby circlet": [
    "Cupri et Rubini Circulum"
  ],
  "blessing of dibella": [
    "Benedictio Dibellae"
  ],
  "scroll of stoneflesh": [
    "Codex Lapidipellis"
  ],
  "leather helmet of major conjuring": [
    "Cutis Galea Maioris Vocandi"
  ],
  "i've returned you home, as promised": [
    "Tibi domum rettuli, ut promisi"
  ],
  "it's a pleasure to meet you": [
    "Gaudeo te novisse"
  ],
  "you run the black-briar meadery": [
    "Administrasne Hydromelliarium Atri-Dumi"
  ],
  "weakness to frost": [
    "Debilitas Glaciei"
  ],
  "fort neugrad prison key": [
    "Clavis ad Carcerem Castelli Neugrad"
  ],
  "you sound quite charitable": [
    "Charitativior videris"
  ],
  "do you accept charity": [
    "Accepisne charitatem"
  ],
  "do you have any shill jobs": [
    "Habesne negotium shill"
  ],
  "scrawled page": [
    "Charta Prave Scripta"
  ],
  "i'd like to donate": [
    "Volo donare"
  ],
  "i'll donate some other time": [
    "Tempore alio donabo"
  ],
  "have you encountered any dragons": [
    "Occuristine aliis draconibus"
  ],
  "how did you come to be known as horse-crusher": [
    "Quomodo nomen \"Equoblitor\" meruisti"
  ],
  "draugr glow eyes": [
    "Draugr Glow Eyes"
  ],
  "blackblood marauder": [
    "Depopulator Atrisanguinus"
  ],
  "weak fear poison": [
    "Venenum Debile Timoris"
  ],
  "ebony dagger of blizzards": [
    "Pugio Ebeni Hiemis"
  ],
  "northwatch mage": [
    "Septentriovisus Magus"
  ],
  "malthyr elenil": [
    "Malthyr Elenil"
  ],
  "you're not from riften": [
    "Num Hiati nata es"
  ],
  "can you share anything about the thieves guild": [
    "Potesne aliquid communicare de Sodalitate Furum"
  ],
  "key to sven's house": [
    "Clavis ad Domum Svenis"
  ],
  "lesser soul gem": [
    "Gemmanima Minor"
  ],
  "ebony greatsword of lethargy": [
    "Spatha Ebeni Lethargiae"
  ],
  "i hear you're a man who might know something about pirates": [
    "Audivi te virum esse qui aliquid de piratis sciret"
  ],
  "yes, but they're far from skyrim": [
    "Sic, sed procul ab Caelifine sunt"
  ],
  "nimhe": [
    "Nimhe"
  ],
  "elven greatsword of evoking": [
    "Spatha Alfa Evocandi"
  ],
  "stormcloak cuirass": [
    "Sturmmantelorum Lorica"
  ],
  "elven mace of animus": [
    "Maca Alfa Animi"
  ],
  "so, what's the word around the guild": [
    "Ergo, quid est nuntium apud Sodalitatem"
  ],
  "do i have to beat it out of you": [
    "Estne mihi opus est verum e te pulsare"
  ],
  "battle-born": [
    "Proeli-Natus"
  ],
  "not just yet": [
    "Nondum"
  ],
  "all books": [
    "All Books"
  ],
  "how do i \"permanently\" clear the vermin": [
    "Quomodo possum \"Sempiterne\" infestationem removere"
  ],
  "glass warhammer of the blaze": [
    "Bellimaleus Vitreus Congflagrantis"
  ],
  "riften bolli house faction": [
    "Hiati Bolli House Faction"
  ],
  "blessed iron mace": [
    "Sanctificata Maca Ferri"
  ],
  "what do we do with it now": [
    "Quid cum eo nunc faciemus"
  ],
  "burnt corpse": [
    "Corpus Adustum"
  ],
  "we shall now add... a giant's toe": [
    "Nunc addamus... digitum gigantis"
  ],
  "i'm a thane": [
    "Sum Decemvir"
  ],
  "flaming familiar explosion": [
    "Flaming Familiar Explosion"
  ],
  "so what's the part you're not telling me": [
    "Ergo, quid me non narras"
  ],
  "shezarr and the divines": [
    "Shezarr et Dii"
  ],
  "i need to find sam and the staff, in order to get gleda back": [
    "Opus est mihi et Samuelem et rudem invenire, ut Gledam tibi referam"
  ],
  "elven helmet of major illusion": [
    "Alfa Galea Maioris Inlusionis"
  ],
  "courage secondary": [
    "Courage Secondary"
  ],
  "now, what did i say about that staff": [
    "Nunc, quid de illo rude dixi"
  ],
  "spell tome: wall of storms": [
    "Liber Magicus: Paries Tempestatum"
  ],
  "help me retrace my steps and i'll pay you for the goat": [
    "Adiuva me gradus meas repetere et tibi pro capra pecuniam solvam"
  ],
  "hallgerd's tale": [
    "Fabula Hallgerdis"
  ],
  "you're selling cheap mead": [
    "Hydromellum vile vendis"
  ],
  "hide bracers of major wielding": [
    "Chirothecae Cutis Maioris Tenendi"
  ],
  "seriously, let me pay you for the ring": [
    "Serio, sine me anulum emere"
  ],
  "shadow warrior invisibility": [
    "Shadow Warrior Invisibility"
  ],
  "up to level": [
    "ad gradum"
  ],
  "when you obtain a spell book, you need to read it to learn the spell, which destroys the book in the process": [
    "Post librum magicae potitus est, debes eum legere ut magicam discas. Hoc quoque librum delet"
  ],
  "offering box": [
    "Riscus Offertorum"
  ],
  "i just need to get to the wedding": [
    "Modo ad nuptias mihi petere volo"
  ],
  "autumnshade clearing": [
    "Autumnumbra Campus"
  ],
  "draugr thrall": [
    "Draugr Servus"
  ],
  "only shows up if player is imperial": [
    "Only shows up if Player is Imperialis"
  ],
  "i don't care about the ring": [
    "De anulo non curo"
  ],
  "tell me what else i said": [
    "Dic mihi quid praeter hoc dixi"
  ],
  "what does azura need": [
    "Quid vult Azura"
  ],
  "iron gauntlets of major smithing": [
    "Ferri Manicae Maioris Faciendi"
  ],
  "orcish warhammer of debilitation": [
    "Orci Bellimalleus Debilitationis"
  ],
  "ft": [
    "pedes"
  ],
  "what do you know about solitude": [
    "Quid de Solitudine scis"
  ],
  "tell me about riften": [
    "Dic mihi de Hiato"
  ],
  "hunting bow of sapping": [
    "Arcus Venandi Hauriendi"
  ],
  "your days of exploiting your son are over": [
    "Dies labore filii tui fruendi termini sunt"
  ],
  "on second thought, let's talk about this another time": [
    "Ut iterum cogito, de hoc alio tempore loquamur "
  ],
  "elven bow of stunning": [
    "Arcus Alfus Intermissionis"
  ],
  "what can i do for you, jarl": [
    "Quid tibi facere possum, Comes"
  ],
  "elven greatsword of animus": [
    "Spatha Alfa Animi"
  ],
  "college practice ward": [
    "College Practice Ward"
  ],
  "level progress": [
    "Progressus ad Gradum Novum"
  ],
  "erik the slayer, huh": [
    "Erik necator, eh"
  ],
  "sounds fearsome": [
    "Horifer mihi videtur"
  ],
  "anonymous letter": [
    "Epistula Anonyma"
  ],
  "chin width": [
    "Latitudo Menti"
  ],
  "do i look like i have any money": [
    "Videorne me pecuniam habere"
  ],
  "boethiah's proving": [
    "Probatio Boethis"
  ],
  "banded iron shield of waning shock": [
    "Ferri Adligati Scutum Insenescentis Electricitatis"
  ],
  "ingun black-briar": [
    "Ingun Atri-Dumus"
  ],
  "hairmaleorc02": [
    "HairMaleOrc02"
  ],
  "bee and barb scene 02": [
    "Bee et Barb Scene 02"
  ],
  "the item is too heavy to carry": [
    "Nimis ponderosus est portari"
  ],
  "item is too heavy to carry": [
    "Nimis ponderosus est portari"
  ],
  "take a look": [
    "Specta"
  ],
  "rift imperial camp": [
    "Hiati Castra Romana"
  ],
  "you know, i bet maven would pay me even more for this info... (persuade)": [
    "En, credo Maven etiam plus mihi pro hoc solvat... (Persuade)"
  ],
  "diced horker meat": [
    "Caro horker scissus"
  ],
  "i found this map at the eetc": [
    "Hanc chartam inveni apud EETC"
  ],
  "why didn't your men put out the fire": [
    "Quini homines tui ignem extinguerent"
  ],
  "femaleeyeshumandarkblue": [
    "FemaleEyesHumanDarkBlue"
  ],
  "cure poison": [
    "Cura Venenum"
  ],
  "staff of jyrik gauldurson": [
    "Rudis Jyrikis Gauldrifili"
  ],
  "i managed to recover an eye of the falmer": [
    "Rettuli Oculum Falmerium"
  ],
  "i was curious about the temple": [
    "Volui de templo discere"
  ],
  "db05playerweddingresponse": [
    "DB05PlayerWeddingResponse"
  ],
  "glass dagger of the blaze": [
    "Pugio Vitreus Congflagrantis"
  ],
  "witbane": [
    "Witbane"
  ],
  "the lunar lorkhan": [
    "Lorkhanis Lunaris"
  ],
  "lunar lorkhan": [
    "Lorkhanis Lunaris"
  ],
  "scaled bracers of lockpicking": [
    "Chirothecae Squamatae Clavicarii"
  ],
  "can you tell me about mara's beliefs": [
    "Potes mihi dicere de credibus Marae"
  ],
  "daedric boots of fire abatement": [
    "Caligae Daedricae Resistendi Ignis"
  ],
  "the creature is calmed and cannot respond": [
    "Calmatus est et respondere non potest"
  ],
  "creature is calmed and cannot respond": [
    "Calmatus est et respondere non potest"
  ],
  "what are the rumors of corruption you mentioned": [
    "Qui sunt rumores corruptionis quos commemoravisti"
  ],
  "captive": [
    "Captiva",
    "captīvus, captīvī"
  ],
  "why did they leave you here": [
    "Quamobrem te hic reliquerunt"
  ],
  "draugr deathlord": [
    "Draugr Dominus Mortuorum"
  ],
  "charwich-koniinge letters, v4": [
    "Epistulae Charvich-Koniinge, IV"
  ],
  "tell me about the black-briar family": [
    "Dic mihi de Familia Atri-Dumi"
  ],
  "i accept": [
    "Accipio"
  ],
  "view available content": [
    "Vide Electabilia"
  ],
  "ataxia": [
    "Ataxia"
  ],
  "you get a lot of attention from men": [
    "Multi viri te admirantur"
  ],
  "imperial helmet of major alchemy": [
    "Romana Galea Maioris Alchemiae"
  ],
  "ebony boots of shock abatement": [
    "Caligae Ebeni Contra Vim Electricam"
  ],
  "glass greatsword of harvesting": [
    "Spatha Vitrea Saturni"
  ],
  "agnis": [
    "Agnis"
  ],
  "you ever think of returning to cyrodiil": [
    "Umquam in animo habes Romam revenire"
  ],
  "you cannot wait while guards are pursuing you": [
    "Expectare non potes dum custodes te persequantur"
  ],
  "opaque vessel": [
    "Opacum Chrismarium"
  ],
  "i did as you asked": [
    "Feci id quod me oravisti"
  ],
  "t01hellos": [
    "T01Hellos"
  ],
  "velehk sain": [
    "Velehk Sain"
  ],
  "daedric battleaxe of damnation": [
    "Bellisecuris Daedricus Damnationis"
  ],
  "is calmed and cannot respond": [
    "Calmatus est et respondere non potest"
  ],
  "steel plate boots of frost suppression": [
    "Laminatae Caligae Contra Glaciem"
  ],
  "shahvee": [
    "Shahvee"
  ],
  "glass dagger of scorching": [
    "Pugio Vitreus Adurendi"
  ],
  "hallowed glass battleaxe": [
    "Consacratus Bellisecuris Vitreus"
  ],
  "sounds like you're having some trouble with your wife": [
    "Mihi videtur you're having some trouble with your wife"
  ],
  "did i hear you say something about a cursed child": [
    "Audivine te aliquid de puero maledicto"
  ],
  "did you say you came here from markarth": [
    "Dixistine te huc Markartia venisti"
  ],
  "ogmund": [
    "Ogmund"
  ],
  "banded iron shield of resist shock": [
    "Ferri Adligati Scutum Contra Vim Electricam"
  ],
  "retrieve the <alias=courier>'s package": [
    "Cape Cista ab <Alias=Courier>"
  ],
  "do you worry about raising a family in these dangerous times": [
    "Sollicitarisne de familiam cultivando his periculosis temporibus"
  ],
  "kill balagog gro-nolob": [
    "Neca Balagogem gro-Nolob"
  ],
  "load this game": [
    "Elige Hunc Ludum"
  ],
  "why aren't you going in there": [
    "Cur non intras"
  ],
  "can you tell me more about this place": [
    "Potesne mihi plus de hoc loco narrare"
  ],
  "storm call - qo": [
    "Storm Call - Qo"
  ],
  "general subtitles": [
    "Subscrita Generalia"
  ],
  "glass bow of fire": [
    "Arcus Vitreus Ignis"
  ],
  "imperial helmet of minor alchemy": [
    "Romana Galea Minoris Alchemiae"
  ],
  "i'll handle those draugr for you": [
    "Illos draugr pro te necabo"
  ],
  "it's from calcelmo. please": [
    "A Calcelmo est. Quaeso"
  ],
  "glass mace of debilitation": [
    "Maca Vitrea Debilitationis"
  ],
  "apprentice illusion": [
    "Discipulus Inlusionis"
  ],
  "i've been sent by mara to aid you": [
    "Missus sum Mara ut tibi adiaverem"
  ],
  "mq103sonsofskyrimsoldierfaction": [
    "MQ103SonsOfSkyrimSoldierFaction"
  ],
  "marksmalehumanoid06leftgash": [
    "MarksMaleHumanoid06LeftGash"
  ],
  "where am i going and what am i fetching": [
    "Quo eo et quid referam"
  ],
  "hulda": [
    "Hulda"
  ],
  "captain metilius": [
    "Centurio Metilius"
  ],
  "who is your lord": [
    "Quis est dominus tuus"
  ],
  "why did you kill the high king": [
    "Cur Regem Altum necavisti"
  ],
  "ebony mace of lethargy": [
    "Maca Ebeni Lethargiae"
  ],
  "the song of pelinal, v8": [
    "Carmen Pelinalis, v8"
  ],
  "song of pelinal, v8": [
    "Carmen Pelinalis, v8"
  ],
  "she bled like a stuck pig": [
    "Quam sus exsanguinata erat"
  ],
  "does every recruit have to do this": [
    "Necessene est omnibus tironbus res huius modi facere"
  ],
  "delphine said to \"remember the 30th of frostfall.\"": [
    "Delphine dixit \"recordare XXX Gelidicasus.\""
  ],
  "are you saying you sent me out there to die": [
    "Dicisne te mihi illic misisse ut morirem"
  ],
  "old wooden door": [
    "Ianua Lignea Vetus"
  ],
  "dragon unrelenting force": [
    "Dragon Unrelenting Force"
  ],
  "what am i going to do with these": [
    "Quid cum his faciam"
  ],
  "scaled bracers of major smithing": [
    "Chirothecae Squamatae Maioris Faciendi"
  ],
  "orcish warhammer of ice": [
    "Orci Bellimalleus Gelidus"
  ],
  "cicero's journal - volume 3": [
    "Ephemeris Ciceronis - Volumen III"
  ],
  "main menu cell": [
    "Main Menu Cell"
  ],
  "honningbrew brewhouse key": [
    "Clavis ad Hydromellarium Honningbreve"
  ],
  "orcish warhammer of fear": [
    "Orci Bellimalleus Timoris"
  ],
  "scaled bracers of smithing": [
    "Chirothecae Squamatae Ferrarii"
  ],
  "the warrior doomstone": [
    "The Warrior Doomstone"
  ],
  "warrior doomstone": [
    "The Warrior Doomstone"
  ],
  "marksmaleargonianscar05": [
    "MarksMaleArgonianScar05"
  ],
  "(<global=hpmarkarth> gold)": [
    "(<Global=HPMarkarth> auri)"
  ],
  "mouth forward": [
    "Os ad Frontem"
  ],
  "enduring magicka poison": [
    "Venenum Durans Magicae"
  ],
  "you mentioned you served special drinks": [
    "Commemoravisti te bibita specialia anculare"
  ],
  "how does one join the stormcloaks": [
    "Quomodo Sturmmanteli adiunguntur"
  ],
  "elgrim": [
    "Elgrim"
  ],
  "nightweaver's band": [
    "Anulus Noctitexoris"
  ],
  "orcish battleaxe of gleaning": [
    "Orci Bellisecuris Saturni"
  ],
  "stormcloak missive": [
    "Nuntium Sturmmantelorum"
  ],
  "snowberries": [
    "Nivibacae"
  ],
  "mor khazgur mine": [
    "Mor Khazgur Metallum"
  ],
  "arcadia's cauldron": [
    "Caldaria Arcadiae"
  ],
  "shriekwind bastion": [
    "Ululaventi Castellum"
  ],
  "sabre cat snow pelt": [
    "Felinis Mucronis Noctis Reno"
  ],
  "choose soul gem to use": [
    "Elige Animigemmam"
  ],
  "i don't know what happened to me": [
    "Nescio quid mihi acciderit"
  ],
  "no, you're supposed to do it": [
    "Minime, tu id facere debes"
  ],
  "i can get rough with you if i have to": [
    "Res asperiores esse possunt, si vis"
  ],
  "daedric gauntlets of extreme archery": [
    "Daedricae Manicae Extremis Saggitationis"
  ],
  "i don't have time for this... i can get rough with you if i have to": [
    "Tempus huic non habeo... vi tecum uti possum"
  ],
  "what does this have to do with me": [
    "Quid agit hoc mecum"
  ],
  "potion of plentiful magicka": [
    "Potio Multae Magicae"
  ],
  "she needed help with some wolves": [
    "Auxilium cum lupis voluit"
  ],
  "dwarven mace of binding": [
    "Dvemeris Maca Deligandi"
  ],
  "dwarven dagger of winnowing": [
    "Dvemeris Pugio Ventagii"
  ],
  "orcish armor of the major knight": [
    "Orci Lorica Maioris Equitis"
  ],
  "i don't want that job": [
    "Illud facere nolo"
  ],
  "scaled helmet of major illusion": [
    "Galea Squamata Maioris Inlusionis"
  ],
  "what's our first target": [
    "Quid est munus primum nostrum"
  ],
  "forelhost crypt": [
    "Forelhost Hypogaeum"
  ],
  "view distance": [
    "Distantia Visus"
  ],
  "daedric war axe of subsuming": [
    "Bellisecuris Daedricus Subsumendi"
  ],
  "potion of the warrior": [
    "Potio Bellatoris"
  ],
  "sibbi's chest key": [
    "Clavis ad Arcam Sibbi"
  ],
  "yes, cicero is dead. (lie)": [
    "Sic, Cicero mortuus est. (Mentire)"
  ],
  "what would inspire them to leave": [
    "Quid eos expellat"
  ],
  "femalebrowshuman11": [
    "FemaleBrowsHuman11"
  ],
  "none of my business, anyway": [
    "Negotium meum non est"
  ],
  "the ratway": [
    "Via Murum"
  ],
  "ratway": [
    "Via Murum"
  ],
  "bor": [
    "Bor"
  ],
  "sneak attack on": [
    "Oppugnatio repens contra"
  ],
  "elven war axe of evoking": [
    "Alfus Bellisecuris Evocandi"
  ],
  "saarthal interior scene 1": [
    "Saarthalis Interior Scene 1"
  ],
  "tg07 vald hates player": [
    "TG07 Vald Hates Player"
  ],
  "horm": [
    "Horm"
  ],
  "hairlinemaleredguardchild01": [
    "HairLineMaleRedguardChild01"
  ],
  "steel dagger of scorching": [
    "Pugio Adamanteus Adurendi"
  ],
  "reverent steel warhammer": [
    "Reverens Bellimalleus Adamanteus"
  ],
  "orcish sword of absorption": [
    "Orci Gladius Absorbendi"
  ],
  "fists of steel": [
    "Pugna Adamantea"
  ],
  "key to anga's mill common house": [
    "Clavis ad Domum Communem Molae Angae"
  ],
  "orcish sword of flames": [
    "Orci Gladius Flammarum"
  ],
  "steel plate helmet of alteration": [
    "Laminata Galea Mutationis"
  ],
  "contract: kill ma'randru-jo": [
    "Munus: Neca Ma'randru-jo"
  ],
  "i think it's hilarious": [
    "Credo id hilarium esse"
  ],
  "you got a problem": [
    "Habesne problema"
  ],
  "leather bracers of minor sure grip": [
    "Corii Chirothecae Minoris Dexteritatis"
  ],
  "avanchnzel boilery": [
    "Avanchnzelis Furnus"
  ],
  "iron battleaxe of souls": [
    "Bellisecuris Ferri Animorum"
  ],
  "arvel the swift": [
    "Arvellus Celer"
  ],
  "what did you need from enthir": [
    "Quid ab Enthir quaesivisti"
  ],
  "daedric shield of shock abatement": [
    "Scutum Daedricum Contra Vim Electricam"
  ],
  "i understand you have something of onmund's": [
    "Intellego te aliquid Onmundi habere"
  ],
  "he wants it back": [
    "Id vult"
  ],
  "it's in everyone's best interest if you return the amulet": [
    "Melius erit omnibus si monile reddes"
  ],
  "there must be something i can do to change your mind": [
    "Certe tibi aliqua via persuadere possum"
  ],
  "defend the second barricade": [
    "Defende Concaedem Secundam"
  ],
  "kill maluril": [
    "Neca Maluril"
  ],
  "find leifnarr": [
    "Inveni Leifnarr"
  ],
  "you were going to tell me a story": [
    "Mihi fabulam narrare voluisti"
  ],
  "what's your point": [
    "Quid dicere vis"
  ],
  "find the forgemaster's fingers": [
    "Inveni Digitos Ferrarii"
  ],
  "audiotemplateicewraith": [
    "AudioTemplateIceWraith"
  ],
  "shipment's ready": [
    "Merx Paratus Est"
  ],
  "bard at attacked by bandits": [
    "Bard at attacked by bandits"
  ],
  "fiery soul trap": [
    "Captor Ignifer Animorum"
  ],
  "i'll get the staff for you": [
    "Rudis tibi adferam"
  ],
  "here's your staff": [
    "Hic habes rudem"
  ],
  "now i'd like the amulet, please": [
    "Nunc, da mihi monile, quaeso"
  ],
  "armor - ebony": [
    "Lorica - Ebenus"
  ],
  "who was this scholar": [
    "Quis erat hic scholaris"
  ],
  "why plant the ring on brand-shei": [
    "Cur anulum in Brand-Shei ponere voluisti"
  ],
  "here's your amulet": [
    "Hic habes monile"
  ],
  "paralysis": [
    "Paralyticum"
  ],
  "is there anything i should be aware of": [
    "Estne quid aliud de quo scire debeo"
  ],
  "what's on the menu": [
    "Quid in indice cuborum est"
  ],
  "what kind of spirits": [
    "Quales animi"
  ],
  "there's still the threat of a dragon attack": [
    "Comminatio impetus draconis iam exstat"
  ],
  "i'll see you in solitude after i meet malborn": [
    "Te videbo Solitudini post cum Malborn convenero"
  ],
  "fort sungard tower": [
    "Turris Castelli Auroris"
  ],
  "dunmer of skyrim": [
    "Dunmeres Caelifinis"
  ],
  "they're for enchanting, correct": [
    "Nonne pro incantamentis sunt"
  ],
  "what do you have to lose": [
    "Quae ammitenda habes"
  ],
  "i'm ready to learn": [
    "Paratus sum discere"
  ],
  "how do you know they're lying": [
    "Quomodo eos mentiri scis"
  ],
  "let's fight": [
    "Pugnemus"
  ],
  "iron battleaxe of chills": [
    "Bellisecuris Ferri Frigidis"
  ],
  "what kind of trouble are you in": [
    "Qualis difficultas ines"
  ],
  "sap spout": [
    "Sap Spout"
  ],
  "tell me the details": [
    "Dic mihi minutias"
  ],
  "i'll help you pay off your debt": [
    "Te aes alienum diminuere adiuvabo"
  ],
  "disarm 1": [
    "Disarm 1"
  ],
  "what else is there to be learned about conjuration magic": [
    "Quid aliud discendum est de Magica Artis Vocandi"
  ],
  "valga vinicia": [
    "Valga Vinicia"
  ],
  "the bee and barb": [
    "Apis et Hamus"
  ],
  "the true nature of orcs": [
    "Natura Vera Orcorum"
  ],
  "true nature of orcs": [
    "Natura Vera Orcorum"
  ],
  "i took care of him and the blackblood marauders": [
    "Eum et Piratas Atrisanguines necavi"
  ],
  "prey faction": [
    "Prey Faction"
  ],
  "scaled helmet of restoration": [
    "Galea Squamata Recreandi"
  ],
  "justiciar execution order": [
    "Mandata Supplicii Justiciaris"
  ],
  "junk": [
    "Inutilia"
  ],
  "keeper carcette": [
    "Curator Carcette"
  ],
  "cicero's hat": [
    "Ciceronis Hat"
  ],
  "why does orchendor deserve to die": [
    "Cur Orchendor mori meretur"
  ],
  "dwemer inquiries vol iii": [
    "Investigationes Dvemeres III"
  ],
  "mehrunes' razor": [
    "Novaculum Croni"
  ],
  "where is bthardamz": [
    "Ubi est Bthardamz"
  ],
  "i've heard your request": [
    "Rogamen tuum audivi"
  ],
  "release me": [
    "Libera me"
  ],
  "do you oppose anyone who's not a nord": [
    "Esne contra omnes qui Germanus non est"
  ],
  "mistwatch east tower": [
    "Turrinexus Eurus Turris"
  ],
  "\"long live the emperor! long live the empire!\"": [
    "\"Longum vivat Imperator! ! Longe vivat Imperium!\""
  ],
  "elven greatsword of stunning": [
    "Spatha Alfa Intermissionis"
  ],
  "does the presence of the guards here bother you": [
    "Praesentiane custodum te molestat"
  ],
  "does your family that own the lumber mill": [
    "Familiane tua hanc molam possidet"
  ],
  "you make armor for the imperials": [
    "Loricam Romanis facis"
  ],
  "psiijic hood": [
    "Capucium Psijicum"
  ],
  "daedric greatsword of winnowing": [
    "Spatha Daedrica Ventagii"
  ],
  "glass war axe of fear": [
    "Bellisecuris Vitreus Timoris"
  ],
  "aromatics": [
    "Aromatica"
  ],
  "so you sell perfumes": [
    "Sic unguenta vendes"
  ],
  "not many people comment on my clothes": [
    "Non multi sunt qui vestimenta me commemorant"
  ],
  "contract: kill narfi": [
    "Munus: Neca Narfi"
  ],
  "marksfemaleargonianscar00": [
    "MarksFemaleArgonianScar00"
  ],
  "lodi": [
    "Lodi"
  ],
  "i could sure use a staff like that": [
    "Certe, rude ut hoc uti possum"
  ],
  "steel shield of dwindling frost": [
    "Adamanteum Scutum Minoris Congelationis"
  ],
  "were you at roggvir's execution": [
    "Ad supplicium Roggviri fuisti"
  ],
  "riften stables faction": [
    "Hiati Stables Faction"
  ],
  "spell tome: detect life": [
    "Liber Magicus: Inveni Vivos"
  ],
  "you seem to be in a good mood": [
    "Felix videris"
  ],
  "eagle eye": [
    "Oculus Aquilae"
  ],
  "an end to the civil war that plagues my land": [
    "Finis Belli Civilis quod terram occupat"
  ],
  "alertidle": [
    "AlertIdle"
  ],
  "spell tome: mass paralysis": [
    "Liber Magicus: Paralyticum Propinquum"
  ],
  "you and your brother don't see eye to eye": [
    "Tu et frater non consentiunt"
  ],
  "do you have any more work for me": [
    "Habesne plus laboris mihi"
  ],
  "what sort of lies has ulfric been spreading": [
    "Quae falsa dixit Vercingetorix"
  ],
  "what happened to capturing mercer alive": [
    "Quidni non iam Mercer vivum capere temptemus"
  ],
  "one more chance. that's it": [
    "Unam plurimam occasionem carpam"
  ],
  "merryfair farm house faction": [
    "Merryfair Farm House Faction"
  ],
  "can you sing \"ragnar the red\"": [
    "Potesne canere \"Ragnar Ruber\""
  ],
  "they've found a way in": [
    "Viam invenerunt"
  ],
  "we need to get you out of here": [
    "Tibi auxilium dare in discedendo debemus"
  ],
  "leather helmet of minor conjuring": [
    "Cutis Galea Minoris Vocandi"
  ],
  "tg08bkarliahoutrobranchtopic": [
    "TG08BKarliahOutroBranchTopic"
  ],
  "(invisible continue)(forced goodbye) time limit to attack": [
    "(Invisible Continue)(Forced Goodbye) Time Limit to Attack"
  ],
  "you'd be doing me a big favor... (persuade)": [
    "Mihi magnam gratiam facias... (Persuade)"
  ],
  "don't worry, i just want to play a joke on someone": [
    "Noli timere, volo cum aliquo iocari"
  ],
  "detect undead enemy exterior": [
    "Detect Undead Enemy Exterior"
  ],
  "is running a mill difficult": [
    "Difficilene est molam administrare"
  ],
  "taproot": [
    "Tapiradix"
  ],
  "give me the hilt, or things get ugly": [
    "Da mihi capulum, vel res malae tibi erint",
    "Da mihi capulum, vel res malae Ãªrunt tibi"
  ],
  "orcish mace of devouring": [
    "Orci Maca Consumendi"
  ],
  "call of valor fx": [
    "Call of Valor FX"
  ],
  "so you and high king torygg were close": [
    "Ergo tu et Rex Altus Torygg amici fuistis"
  ],
  "immortal blood": [
    "Sanguis Immortalis"
  ],
  "i came at a bad time": [
    "Tempus malum veni"
  ],
  "a false front": [
    "Frons Falsa"
  ],
  "false front": [
    "Frons Falsa"
  ],
  "you're a forsworn": [
    "Abiuratus es"
  ],
  "mralki": [
    "Mralki"
  ],
  "why didn't torygg ever declare independence": [
    "Cur Torygg independentiam numquam declaravit"
  ],
  "it's painfully obvious you don't know what you're doing": [
    "Manifestus es te nescire quid faciendum sit"
  ],
  "what do you know about dragons": [
    "Quid de draconibus scis"
  ],
  "spriggan swarm spray": [
    "Spriggan Swarm Spray"
  ],
  "i want to unravel the mysteries of aetherius": [
    "Mysteria Aetherii solvere volo"
  ],
  "is your spiced wine made from a secret recipe": [
    "Factusne est aromatites tuus a dictamene secreto"
  ],
  "what's the burning of king olaf": [
    "Quid est crematio Regis Olafis"
  ],
  "solid dwemer metal": [
    "Metallum Dwemere Integrum"
  ],
  "gulum-ei told me she said, \"where the end began.\"": [
    "Gulum-Ei mihi dixit eam dixisse, \"ubi finis coepit.\""
  ],
  "i've tested your scrolls": [
    "Codices tuos temptavi"
  ],
  "i want to stop at shor's stone": [
    "Volo consistere apud Lapidem Shoris"
  ],
  "iron shield of resist shock": [
    "Ferri Scutum Contra Vim Electricam"
  ],
  "sacellum of boethiah": [
    "Sacellum Boethis"
  ],
  "if i didn't know better, i'd say you were trying to kill me": [
    "Nisi melius scirem, dicerem te me necare conari"
  ],
  "ebony bow of debilitation": [
    "Arcus Ebeni Debilitationis"
  ],
  "tell me about the fishing jobs": [
    "Narra mihi de munera \"Piscari\""
  ],
  "ebony dagger of the blaze": [
    "Pugio Ebeni Congflagrantis"
  ],
  "what do you need help with": [
    "Cum quo auxilium vis"
  ],
  "i have silver ore to sell": [
    "Argenteum vendendum habeo"
  ],
  "where do you stand on the war": [
    "Quid de bello censes"
  ],
  "i'll come quietly": [
    "Sedate tecum veniam"
  ],
  "why don't you like camping here": [
    "Cur castra hic facere tibi displicet"
  ],
  "fellstar farm": [
    "Fellistellae Latifundus"
  ],
  "okay, i'll help": [
    "Ok, adiuvabo"
  ],
  "elven battleaxe of scorching": [
    "Bellisecuris Alfus Adurendi"
  ],
  "solitude blacksmith": [
    "Solitudinis Blacksmith"
  ],
  "old solitude crypts key": [
    "Clavis ad Hypogaea Antiqua Solitudinis"
  ],
  "philter of extra magicka": [
    "Philtrum Maioris Magicae"
  ],
  "wall of frost": [
    "Wall Gelidus"
  ],
  "i have inscribed the lexicon": [
    "Lexicon inscriptum habeo"
  ],
  "steel gauntlets of major archery": [
    "Adamanteae Manicae Maioris Sagittationis"
  ],
  "imperial armor of dwindling frost": [
    "Romana Lorica Minoris Congelationis"
  ],
  "tell me this isn't permanent": [
    "Dic mihi hoc non permanebit"
  ],
  "daedric dagger of the sorcerer": [
    "Pugio Daedricus Magi"
  ],
  "dragonsreach dungeon": [
    "Draconicarcer Carcer"
  ],
  "looks like there's not much you can do": [
    "Mihi videtur tibi multum faciendum non habere"
  ],
  "goldenglow safe key": [
    "Clavis ad Arcam Tutum Aurlucis"
  ],
  "glass bow of banishing": [
    "Arcus Vitreus Expellendi"
  ],
  "delphine seemed to think it was important": [
    "Delphinus id magni momenti esse putavit"
  ],
  "entersprintbreath": [
    "EnterSprintBreath"
  ],
  "why did skjor call this my trial": [
    "Cur Skjor hoc nominavit Periculum meum"
  ],
  "he's in debt because of a quill": [
    "Debet Pennae Geminationis causa"
  ],
  "life of uriel septim vii": [
    "Vita Urieli Septimi VII"
  ],
  "we escaped from the imperials together": [
    "E Romanis effugimus"
  ],
  "elven helmet of major archery": [
    "Alfa Galea Maioris Sagittationis"
  ],
  "lesser ward": [
    "Lesser Ward"
  ],
  "how did it end up in lake honrich": [
    "Quomodo in Lacum Honrich venit"
  ],
  "i found the quill of gemination": [
    "Inveni Pennam Geminationis"
  ],
  "mercer needs you in markarth right away": [
    "Mercer needs you in Markarth right away"
  ],
  "power bash": [
    "Ictus Potens Scuto"
  ],
  "fast healing": [
    "Fast Healing"
  ],
  "who's in charge of markarth": [
    "Quis Markartiae praeest"
  ],
  "malemouthhumanoiddefault": [
    "MaleMouthHumanoidDefault"
  ],
  "db05astridbridedeadresponse1": [
    "DB05AstridBrideDeadResponse1"
  ],
  "elven greatsword of immobilizing": [
    "Spatha Alfa Immobilitatis"
  ],
  "levelled spell": [
    "Levelled Spell"
  ],
  "audiotemplatedwarvensphere": [
    "AudioTemplateDwarvenSphere"
  ],
  "snow shod house scene 02": [
    "Snow Shod House Scene 02"
  ],
  "here's the pommel stone of mehrunes' razor": [
    "Hic habes lapidem capuli Novaculae Croni"
  ],
  "i'm not giving you anything": [
    "Nihil tibi dabo",
    "Tibi nihil dabo"
  ],
  "dwarven war axe": [
    "Dvemeris Bellisecuris"
  ],
  "is the town of rorikstead named for you": [
    "Habetne oppidum Rorikidomus nomen eius abste"
  ],
  "riften scripted scene - bee and barb": [
    "Hiati Scripted Scene - Bee et Barb"
  ],
  "dialogue heartwood mill": [
    "Dialogue Heartwood Mill"
  ],
  "target actor": [
    "Actor"
  ],
  "flame atronach faction": [
    "Flame Atronach Faction"
  ],
  "has the war taken a toll on your settlement and its people": [
    "Bellumne affecit oppidum tuum et homines eorum"
  ],
  "ebony mace of evoking": [
    "Maca Ebeni Evocandi"
  ],
  "what will happen to elisif if the empire wins the war": [
    "Quid Elisifi accidat si Imperium bellum vincerit"
  ],
  "how did a breton like yourself come to dwell in rorikstead": [
    "Quomodo Britannus ut te Rorikidomi venierunt"
  ],
  "daedric greatsword of harvesting": [
    "Spatha Daedrica Saturni"
  ],
  "how did you come to make your home at rorikstead": [
    "Quomodo eligisti Rorikidomus vivere"
  ],
  "your farms are thriving, even despite the harsh climate": [
    "Fundi tui iam florent, etiam sub caelo frigido"
  ],
  "what's your secret": [
    "Quid est sectretum tuum"
  ],
  "are you here on guild business": [
    "Estne hic pro negotio Sodalitatis"
  ],
  "rift watchtower": [
    "Rift Watchtower"
  ],
  "i'll pay": [
    "Pecuniam solvam"
  ],
  "reverent steel war axe": [
    "Reverens Bellisecuris Adamanteus"
  ],
  "elven greatsword of diminishing": [
    "Spatha Alfa Diminuendi"
  ],
  "by sithis, i need details": [
    "A Namira, da mihi minutias"
  ],
  "ra'zhinda": [
    "Ra'zhinda"
  ],
  "bring the phial to nurelion": [
    "Fer Atrophorion Nurelioni"
  ],
  "black-briar lodge key": [
    "Clavis ad Villam Rusticam Atri-Dumi"
  ],
  "what task": [
    "Quid munus"
  ],
  "here's an order from general tullius himself": [
    "Hic habeo mandatum ab Imperatori Tullio ipso scriptum"
  ],
  "hallowed elven battleaxe": [
    "Consacratus Alfus Bellisecuris"
  ],
  "i did this to get rich": [
    "Hoc feci ut dives fiam"
  ],
  "you're an important family, i take it": [
    "Familia magni momenti es, ut puto"
  ],
  "anyone who's not a nord is a trespasser": [
    "Omnes qui Germani non sunt advenae sunt"
  ],
  "16 accords of madness, v. xii": [
    "XVI Foedera Insanitatis, v. XII"
  ],
  "i'd really like to keep this axe": [
    "Malim hunc securem retinere"
  ],
  "wooden plate": [
    "Ligni Lanx"
  ],
  "if he's betrayed us, i'll kill him": [
    "Si ille nobis proderet, eum necam"
  ],
  "scaled helmet of eminent archery": [
    "Galea Squamata Eminentis Saggitationis"
  ],
  "so you know how to shout": [
    "Ergo Clamare potes"
  ],
  "steel plate bracers of major alchemy": [
    "Chirothecae Laminatae Maioris Alchemiae"
  ],
  "the ritual stone": [
    "Lapis Ritualis"
  ],
  "ritual stone": [
    "Lapis Ritualis"
  ],
  "dwarven helmet": [
    "Dvemeris Galea"
  ],
  "i'll help you find her": [
    "Te eam invenire adiuvabo"
  ],
  "turns out i may be something called \"dragonborn.\"": [
    "Verum est me aliquem \"Draconigenam\" vocatum fortasse esse"
  ],
  "song of hrormir": [
    "Carmen Hrormiris"
  ],
  "do you like living here": [
    "Tibine placet hic vivere"
  ],
  "quicksaving": [
    "Celeritueans"
  ],
  "aversion to fire": [
    "Aversio Igni"
  ],
  "oh, i see": [
    "Oh, intellego"
  ],
  "steel plate armor of eminent health": [
    "Laminata Lorica Eminentis Salutis"
  ],
  "out of my way": [
    "E via mea emove"
  ],
  "lisbet's missing shipment": [
    "Merces Amissae Lisbetae"
  ],
  "ms13alrvelhelp03branchtopic": [
    "MS13AlrvelHelp03BranchTopic"
  ],
  "humanbeard03": [
    "HumanBeard03"
  ],
  "what does this job pay": [
    "Quid solvit hic labor"
  ],
  "jaree-ra is dead": [
    "Jaree-Ra mortuus est"
  ],
  "igmund": [
    "Igmund"
  ],
  "i'd rather die": [
    "Malim mori"
  ],
  "blacksmith's apron": [
    "Ferrari Ecombomba"
  ],
  "hairmaledarkelf02": [
    "HairMaleDarkElf02"
  ],
  "old life festival": [
    "Festivus Vitae Veteris"
  ],
  "blood-kin of the orcs": [
    "Blood-Kin of the Orcs"
  ],
  "big laborer": [
    "Laborator Magnus"
  ],
  "tomato": [
    "Lycopersicum"
  ],
  "clan shatter-shield office": [
    "Gentis Scuti-Fracti Officina"
  ],
  "let me see if i can bring him back on my own": [
    "Eum reffere temptabo"
  ],
  "without bloodshed": [
    "Sine sanguine"
  ],
  "i defeated alduin, but he escaped": [
    "Alduinum superavi, sed effugit"
  ],
  "elven sword of enervating": [
    "Alfus Gladius Enervationis"
  ],
  "steel plate armor of major health": [
    "Laminata Lorica Maioris Salutis"
  ],
  "poor fishwife": [
    "Uxor Piscatoris"
  ],
  "destruction trainer": [
    "Destruction Trainer"
  ],
  "dragon abilities": [
    "Dragon abilities"
  ],
  "daedric battleaxe of storms": [
    "Bellisecuris Daedricus Tempestatium"
  ],
  "ebony bow of blizzards": [
    "Arcus Ebeni Hiemis"
  ],
  "ironhand gauntlets": [
    "Manuferri Manicae"
  ],
  "glass battleaxe of debilitation": [
    "Bellisecuris Vitreus Debilitationis"
  ],
  "it's not important": [
    "Non mihi refert"
  ],
  "iron battleaxe of cowardice": [
    "Bellisecuris Ferri Timoris"
  ],
  "femalebrowshuman10": [
    "FemaleBrowsHuman10"
  ],
  "helm of winterhold": [
    "Galea Hiemiterrae"
  ],
  "ancient amulet": [
    "Monile Antiquum"
  ],
  "aeri": [
    "Aera"
  ],
  "sheogorath": [
    "Sanguine"
  ],
  "ebony boots of frost abatement": [
    "Caligae Ebeni Gelidus Resistendi"
  ],
  "philter of resist shock": [
    "Philtrum Contra Vim Electricam"
  ],
  "the same symbol from goldenglow was involved": [
    "Idem symbolum quam in Auriluce vidimus pars huius erat"
  ],
  "same symbol from goldenglow was involved": [
    "Idem symbolum quam in Auriluce vidimus pars huius erat"
  ],
  "dwarven sword": [
    "Gladius Dvemeris"
  ],
  "can you tell me about peryite": [
    "Potes mihi de Peryito narrare"
  ],
  "marksfemalehumanoid10rightgashr": [
    "MarksFemaleHumanoid10RightGashR"
  ],
  "your skin... are you ill": [
    "Pellis tuus... Esne aeger"
  ],
  "caught red handed": [
    "Rubra Manu Inventa"
  ],
  "i have more questions": [
    "Plurima interrogata habeo"
  ],
  "i found your helmet": [
    "Galeam tuam inveni"
  ],
  "embershard mine key": [
    "Clavis ad Metallum Cinerifragmentorum"
  ],
  "garakh": [
    "Garakh"
  ],
  "marksfemaleargonianscar06": [
    "MarksFemaleArgonianScar06"
  ],
  "maleheadwoodelf": [
    "MaleHeadWoodElf"
  ],
  "apprentice retort": [
    "Apprentice Retort"
  ],
  "daedric sword of animus": [
    "Gladius Daedricus Animi"
  ],
  "so you handle the defense of riften": [
    "Ergo praesidium Hiati in cura tua est"
  ],
  "spell tome: conjure frost atronach": [
    "Liber Magicus: Voca Atronachum Glaciei"
  ],
  "daedric shield": [
    "Daedricum Scutum"
  ],
  "interesting": [
    "Valde iterest"
  ],
  "black door": [
    "Ianuam Atram"
  ],
  "improved hidden pockets": [
    "Improved Hidden Pockets"
  ],
  "thistle branch": [
    "Ramus Cardui"
  ],
  "tell me your thoughts on the war": [
    "Dic mihi cogitationes tuas de bello"
  ],
  "orcish warhammer of flames": [
    "Orci Bellimalleus Flammarum"
  ],
  "n7": [
    "N7"
  ],
  "slow down... where did this happen": [
    "Lentius... Ubi hoc accidit"
  ],
  "i've got a cure disease potion": [
    "Potionem quae morbos curat habeo"
  ],
  "potion of enduring invisibility": [
    "Potio Durantis Invisibilitatis"
  ],
  "what do you do around here": [
    "Quid hic facis"
  ],
  "another time, perhaps": [
    "Alio tempore, fortasse"
  ],
  "facial hair": [
    "Capillis Faciei"
  ],
  "exitfavorstate": [
    "ExitFavorState"
  ],
  "ulfric's war axe": [
    "Bellisecuris Vercingetorigis"
  ],
  "brynjolf said you wanted to see me": [
    "Brynjolf dixit te me videre velle"
  ],
  "i've been told to kill you": [
    "Imperatus sum ut te necarem"
  ],
  "how are the dark elves treated in windhelm": [
    "Quomodo tractantur Atralfi Ventigaleae"
  ],
  "where are we now": [
    "Ubi nunc sumus"
  ],
  "aspect of peryite": [
    "Aspectum Peryitis"
  ],
  "i'll take the lexicon": [
    "Lexicon capiam"
  ],
  "dwarven mace of shocks": [
    "Dvemeris Maca Electricitatis"
  ],
  "i don't want the lexicon": [
    "Nolo Lexicon habere"
  ],
  "spell tome: muffle": [
    "Liber Magicus: Surdus"
  ],
  "shootbow": [
    "ShootBow"
  ],
  "dwarven battleaxe of harrowing": [
    "Dvemeris Bellisecuris Occationis"
  ],
  "the stormcloaks say hello": [
    "Sturmmanteli salve dicunt"
  ],
  "stormcloaks say hello": [
    "Sturmmanteli salve dicunt"
  ],
  "elk": [
    "Reno"
  ],
  "habd's death letter": [
    "Epistula Mortis Habdis"
  ],
  "where did i need to go again": [
    "Quo ire debeo, iterum"
  ],
  "crabber's shanty": [
    "Casa Venatoris Cancerium"
  ],
  "steel gauntlets of minor alchemy": [
    "Adamanteae Manicae Minoris Alchemiae"
  ],
  "you would expect any less from the listener": [
    "Minus ex Auditore exspectares"
  ],
  "ebony battleaxe of winnowing": [
    "Bellisecuris Ebeni Ventagii"
  ],
  "wisp abilities": [
    "Wisp abilities"
  ],
  "i have a message from general tullius": [
    "Nuntium ab Imperatore Tullio habeo"
  ],
  "scroll of grand healing": [
    "Codex Sanadi Mangi"
  ],
  "key to honeyside": [
    "Clavis ad Melilatum"
  ],
  "any clues where i can find these ingredients": [
    "Scisne ubi has ingredientes invenire possem"
  ],
  "what scroll": [
    "Quae codex"
  ],
  "iron greatsword of arcing": [
    "Spatha Ferri Electricitatis"
  ],
  "the keepers of the razor": [
    "Curatores Novaculae"
  ],
  "keepers of the razor": [
    "Curatores Novaculae"
  ],
  "grass fade": [
    "Evanidus Graminis"
  ],
  "i'm trapped in here": [
    "Incarceratus hic sum"
  ],
  "clear skies 1": [
    "Clear Skies 1"
  ],
  "goldenglow estate sewer": [
    "Villae Aurilumenis Cloaca"
  ],
  "ebony sword of debilitation": [
    "Gladius Ebeni Debilitationis"
  ],
  "maleeyesorcyellow": [
    "MaleEyesOrcYellow"
  ],
  "potion of health": [
    "Potio Salutis"
  ],
  "humanbeard07": [
    "HumanBeard07"
  ],
  "the killer has struck again": [
    "Homicida iterum necavit"
  ],
  "killer has struck again": [
    "Homicida iterum necavit"
  ],
  "dragon priest fx": [
    "Dragon Priest FX"
  ],
  "leather boots of dwindling shock": [
    "Corii Caligae Electricitatis Diminuentis"
  ],
  "you mean this weapon": [
    "De hoc armo loqueris"
  ],
  "(show nettlebane)": [
    "(Demonstra Nettlebane)"
  ],
  "what is a sybil": [
    "Quid est Sybil"
  ],
  "steel helmet of destruction": [
    "Adamantea Galea Destructionis"
  ],
  "scaled bracers of eminent deft hands": [
    "Chirothecae Squamatae Eminentis Dexteritatis"
  ],
  "greater soul gem": [
    "Gemmanima Maior"
  ],
  "do they keep you busy": [
    "Faciuntne te negotiosum"
  ],
  "scroll of fast healing": [
    "Codex Celeriter Sanandi"
  ],
  "hreinn": [
    "Hreinn"
  ],
  "biography of the wolf queen": [
    "Biographis Barenziae, IV"
  ],
  "scribbled note": [
    "Epistula Prave Scripta"
  ],
  "for the good of morthal": [
    "Pro Bono Morthali"
  ],
  "it's from gorm, in morthal": [
    "A Gorm est, Morthali"
  ],
  "daedric gauntlets of eminent smithing": [
    "Daedricae Manicae Maximi Faciendi"
  ],
  "the thief stone": [
    "Lapis Furis"
  ],
  "thief stone": [
    "Lapis Furis"
  ],
  "cicero's journal - volume 4": [
    "Ephemeris Ciceronis - Volumen IV"
  ],
  "stenvar": [
    "Stenvar"
  ],
  "what is it you want": [
    "Quid vis"
  ],
  "elven warhammer of fire": [
    "Alfus Bellimaleus Ignis"
  ],
  "i want to get on his good side": [
    "Volo amicum eius esse"
  ],
  "the archery skill improves the use of bows and arrows": [
    "Ars Sagittationis usum arcuum et sagittarum auget"
  ],
  "archery skill improves the use of bows and arrows": [
    "Ars Sagittationis usum arcuum et sagittarum auget"
  ],
  "the greater the skill, the more deadly the shot": [
    "Ars maior, ictus letiferior"
  ],
  "greater the skill, the more deadly the shot": [
    "Ars maior, ictus letiferior"
  ],
  "spell tome: conjure familiar": [
    "Liber Magicus: Voca Familiarem"
  ],
  "dwarven helmet of major alchemy": [
    "Dvemeris Galea Maioris Alchemiae"
  ],
  "why are you after saadia": [
    "Cur Saadiam quaeris"
  ],
  "how old are your horses": [
    "Quot annos natos sunt equi tui"
  ],
  "daedric sword of blizzards": [
    "Gladius Daedricus Hiemis"
  ],
  "what should i tell the arch-mage": [
    "Quid Archimago dicere debeo"
  ],
  "who is mirabelle ervine": [
    "Quis est Mirabelle Ervine"
  ],
  "steel war axe of burning": [
    "Bellisecuris Adamanteus Cremandi"
  ],
  "draught of true shot": [
    "Decocta Saggitationis"
  ],
  "why were they executing him": [
    "Cur eum supplicium dederunt"
  ],
  "mouth height": [
    "Altitudo Oris"
  ],
  "barracks": [
    "Barracks"
  ],
  "key to breezehome": [
    "Clavis ad Zephyridomum"
  ],
  "is the man who was executed buried here": [
    "Estne homo hic carnificato hic sepelitur"
  ],
  "hunter of hircine": [
    "Venator Hecatis"
  ],
  "what does she do with the memories": [
    "Quid agitur ea cum memoribus"
  ],
  "when's your next execution": [
    "Quando est supplicium proximum tuum"
  ],
  "feyfolken i": [
    "Feyfolken I"
  ],
  "dwarven shield of blocking": [
    "Dvemere Scutum Interponendi"
  ],
  "even if that battle is with a dragon": [
    "Etiamsi illud proelium contra draconem est"
  ],
  "okay, if you don't want to hear about the dragon": [
    "Ok, si de dracone audire non vis"
  ],
  "i was in helgen when the dragon attacked": [
    "Eram Helgeni cum draco oppugnabat"
  ],
  "so we're splitting the loot, then": [
    "Ergo gazam communicamur"
  ],
  "scaled boots of waning frost": [
    "Caligae Squamatae Insenescentis Congelationis"
  ],
  "whiterun guard neutral faction": [
    "Custos Albacursus Neutral Faction"
  ],
  "(<global=carriagecostsmall> gold)": [
    "(<Global=CarriageCostSmall> auri)"
  ],
  "narfi is dead": [
    "Narfi mortuus est"
  ],
  "who was your brother": [
    "Quis erat frater tuus"
  ],
  "what in the name of sithis": [
    "Quid in nomine Sithis"
  ],
  "sounds like your brother was a traitor": [
    "Mihi videtur your brother was traitor"
  ],
  "orcish sword of freezing": [
    "Orci Gladius Gelidus"
  ],
  "return to sylgja": [
    "Redi ad Sylgjam"
  ],
  "i'll take that dog off your hands": [
    "Canem abs te accipiam"
  ],
  "(<global=animalgold> gold)": [
    "(<global=animalgold> auri)"
  ],
  "ebony dagger of banishing": [
    "Pugio Ebeni Expellendi"
  ],
  "daedric dagger of blizzards": [
    "Pugio Daedricus Hiemis"
  ],
  "dwarven bracers of archery": [
    "Dvemeres Chirothecae Saggitationis"
  ],
  "how do i get into hjerim": [
    "Quomodo in Hjerim intrare possum"
  ],
  "why are we doing this to brand-shei": [
    "Cur hoc ad Brand-Shei facimus"
  ],
  "so you work in the winking skeever": [
    "Ergo in skeever adnictante laboras"
  ],
  "the leader of <alias=bountylocation> is dead. i'm here for the bounty": [
    "Dux <Alias=BountyLocation> mortuus est. Hic sum Praemio"
  ],
  "leader of <alias=bountylocation> is dead. i'm here for the bounty": [
    "Dux <Alias=BountyLocation> mortuus est. Hic sum Praemio"
  ],
  "orcish greatsword of cowardice": [
    "Orci Spatha Timoris"
  ],
  "is grelod always like that": [
    "Estne Grelod semper tam mala"
  ],
  "leather helmet of archery": [
    "Cutis Galea Saggitationis"
  ],
  "calixto's chest": [
    "Arca Calixtonis"
  ],
  "lunar steel war axe": [
    "Anancitis Lunaris Bellisecuris"
  ],
  "regroup at the city gates": [
    "Iunge Gregem Militum ad Portas Urbis"
  ],
  "has the war affected your college much": [
    "Bellumne collegium tuum affecit"
  ],
  "grelka": [
    "Grelka"
  ],
  "argonian account, book 1": [
    "Historia Argonia, Book 1"
  ],
  "what exactly is a falmer": [
    "Qui sunt Falmeres"
  ],
  "dwarven mace of cowardice": [
    "Dvemeris Maca Timoris"
  ],
  "ebony gauntlets of eminent smithing": [
    "Ebeni Manicae Maximi Faciendi"
  ],
  "sigar": [
    "Sigar"
  ],
  "crimson nirnroot": [
    "Nirniradicem Cinnabaris"
  ],
  "turns out the ancient nords used it to send alduin forward through time": [
    "Verum est ut Germani antiqui eo usi sunt ut Alduinum prorsus in tempore mitterent"
  ],
  "orcish dagger of freezing": [
    "Orci Pugio Gelidus"
  ],
  "elven dagger of leeching": [
    "Pugio Alfus Sanguisugae"
  ],
  "dawnbreaker": [
    "Aurorafractor"
  ],
  "rout - master of the mind": [
    "Rout - Dominus Mentis"
  ],
  "i don't really know": [
    "Re vera nescio"
  ],
  "paarthurnax told me it would work": [
    "Paarthurnax mihi dixit id functurum esse"
  ],
  "mzulft key": [
    "Clavis ad Mzulft"
  ],
  "16 accords of madness, v. ix": [
    "XVI Foedera Insanitatis, v. IX"
  ],
  "heimvar": [
    "Heimvar"
  ],
  "banish daedra": [
    "Dimitte Daedram"
  ],
  "lortheim": [
    "Lortheim"
  ],
  "no time to explain. let's get out of here. (free him)": [
    "Nullum tempus explicandi causa est. E hoc loco exeamus. (Libera eum)"
  ],
  "note to thomas": [
    "Epistula ad Thomas"
  ],
  "do you have any heist jobs": [
    "Habesne negotia \"heist\""
  ],
  "dwarven war axe of reaping": [
    "Dvemeris Bellisecuris Saturni"
  ],
  "dragonscale helmet of extreme archery": [
    "Draconisquamata Galea Extremis Saggitationis"
  ],
  "why do these \"energies\" need to be cleansed": [
    "Cur mundandas sunt hae \"energiae\""
  ],
  "ebony bow of malediction": [
    "Arcus Ebeni Maledictionis"
  ],
  "thalmor splinter faction": [
    "Visigothi Splinter Faction"
  ],
  "humanbeard45": [
    "HumanBeard45"
  ],
  "novice bellow": [
    "Novice Bellov"
  ],
  "fridrika": [
    "Fridrika"
  ],
  "bodyguard": [
    "Stipator"
  ],
  "dwarven war axe of gleaning": [
    "Dvemeris Bellisecuris Saturni"
  ],
  "potent flame atronach": [
    "Potens Atronachum Flammarum"
  ],
  "so what's my next task": [
    "Quid proximum"
  ],
  "crystal holder": [
    "Loculamentum Crystallum"
  ],
  "daedric boots of frost suppression": [
    "Caligae Daedricae Contra Glaciem"
  ],
  "what sort of proof do you need": [
    "Quod argumentum quaeris"
  ],
  "who's azura": [
    "Quis est Azura"
  ],
  "do you work alone": [
    "Solusne laboras"
  ],
  "i need a moment": [
    "Breve spatium temporis volo"
  ],
  "i'll be back later": [
    "Postea reveniam"
  ],
  "i've defeated the guardian spirits": [
    "Animos custodium vici"
  ],
  "solitude has traditionally had strong ties to the empire, and many of the city's jarls were connected to the septim bloodline": [
    "Mos maiorum Solitudinis est nexus cum Imperio. Multi Comites urbis nexum cum Septimis habuerunt"
  ],
  "elven sword of harrowing": [
    "Alfus Gladius Occationis"
  ],
  "all guards of both factions in forts get put in this faction": [
    "All guards of both factions in forts get put in this faction"
  ],
  "glass bow of expelling": [
    "Arcus Vitreus Expellendi"
  ],
  "rahgot": [
    "Rahgot"
  ],
  "dragonplate armor of eminent health": [
    "Draconilaminata Lorica Eminentis Salutis"
  ],
  "silver necklace": [
    "Argentei Monile"
  ],
  "no items to charge enchantment": [
    "Nullas res habes quae incantamentum augere possunt"
  ],
  "gadba gro-largash": [
    "Gadba gro-Largash"
  ],
  "spell tome: fire rune": [
    "Liber Magicus: Runus Ignis"
  ],
  "the chill": [
    "Carcer Hiemiterrae"
  ],
  "chill": [
    "Carcer Hiemiterrae"
  ],
  "i saw a dragon in helgen": [
    "Draconum vidi Helgeni"
  ],
  "what's so important about me being dragonborn": [
    "Quid de Draconigena tam magni momenti est"
  ],
  "daedric war axe of winnowing": [
    "Bellisecuris Daedricus Ventagii"
  ],
  "katariah deck": [
    "Puppis Katariae"
  ],
  "did you kill all this game yourself": [
    "Necavistine tu ipse omnia haec animalia"
  ],
  "iron helmet of illusion": [
    "Ferri Galea Inlusionis"
  ],
  "is there somewhere i can buy fresh supplies": [
    "Estne locus in quo supplementa viridia emere possim"
  ],
  "riften ragged flagon faction": [
    "Hiati Ragged Flagon Faction"
  ],
  "kaie": [
    "Kaie"
  ],
  "dragon souls are required to unlock shouts": [
    "Animi Draconum requiruntur ut clamationes aperias"
  ],
  "iron helmet": [
    "Ferri Galea"
  ],
  "spell tome: magelight": [
    "Liber Magicus: Magilux"
  ],
  "your crops seem to be thriving": [
    "Segetes tuae vigere videntur"
  ],
  "i represent the dark brotherhood": [
    "Venio ab Fraternitate Obscura"
  ],
  "your death is at hand": [
    "Mors tua adest"
  ],
  "taarie": [
    "Taarie"
  ],
  "bloody enough": [
    "Satis plenus sanguinis"
  ],
  "have you lived here all your life": [
    "Hicne totam vitam egisti"
  ],
  "dragonplate gauntlets of peerless smithing": [
    "Draconilaminatae Manicae Maximi Faciendi"
  ],
  "firebolt storm": [
    "Firebolt Storm"
  ],
  "hairmalenord5": [
    "HairMaleNord5"
  ],
  "i'd like a battleaxe": [
    "Bellisecurem volo"
  ],
  "where is this place": [
    "Ubi est hic locus"
  ],
  "elves ear": [
    "Auris Alfus"
  ],
  "haelga's bunkhouse scene 06": [
    "Haelga's Bunkhouse Scene 06"
  ],
  "guardian saerek": [
    "Custos Saerek"
  ],
  "hilde": [
    "Hilde"
  ],
  "i can see you spared no expense": [
    "Videre possum te nullo impenso pepercisse"
  ],
  "do you host these parties often": [
    "Saepene hospes epularum huius modi es"
  ],
  "master grimoire": [
    "Master Grimoire"
  ],
  "how did you make your fortune": [
    "Quomodo pecuniam tuam meruisti"
  ],
  "do you trust the thalmor": [
    "Confidisne Visigothis"
  ],
  "sinding's skin": [
    "Pellis Sindingis"
  ],
  "sergius's note": [
    "Epistula Sergii"
  ],
  "elven sword of harvesting": [
    "Alfus Gladius Saturni"
  ],
  "imperial missive": [
    "Epistula Imperialis"
  ],
  "storm call": [
    "Voca Temepestatem"
  ],
  "ebony greatsword of damnation": [
    "Spatha Ebeni Damnationis"
  ],
  "i didn't know you had dealings with the thalmor": [
    "Nescivi te cum Visigothis negotium agere"
  ],
  "key to aleuc's house": [
    "Clavis ad Domum Aleucis"
  ],
  "are you going back to jorrvaskr": [
    "Reveniesne ad Jorrvaskr"
  ],
  "what are your duties here in skyrim": [
    "Quid hic in Caelifine facis"
  ],
  "i have a message from brynjolf": [
    "Nuntium a Brynjolf habeo"
  ],
  "riften bee and barb faction": [
    "Hiati Bee and Barb Faction"
  ],
  "dead crone rock key": [
    "Clavis ad Saxum Sagae Mortuae"
  ],
  "red eagle ascent": [
    "Ascensum Aquilae Rubrae"
  ],
  "what do you know of our host": [
    "Quid de hospite nostro scis"
  ],
  "elixir of the knight": [
    "Commixtum Equitis"
  ],
  "you don't look happy to be here": [
    "Laetus hic esse non videris"
  ],
  "quite a gathering, wouldn't you say": [
    "Nonne Convivium optimum est"
  ],
  "what do you do for a living": [
    "Quid negotium agis"
  ],
  "weynon stones": [
    "Weynon Lapides"
  ],
  "femaleheaddarkelf": [
    "FemaleHeadDarkElf"
  ],
  "enjoying the party, proventus": [
    "Epula frueris, Provente"
  ],
  "rorikstead manor faction": [
    "Rorikstead Manor Faction"
  ],
  "dwarven helmet of restoration": [
    "Dvemeris Galea Recreandi"
  ],
  "find the elven mage from aranea's vision": [
    "Inveni Magum Alfum ab Auraculo Araneae"
  ],
  "enjoying the party": [
    "Epulane frueris"
  ],
  "bound battleaxe": [
    "Bellisecuris Vocatus"
  ],
  "relic index finger": [
    "Reliquium Index"
  ],
  "captain hargar": [
    "Nauclerus Hargar"
  ],
  "fort dunstad": [
    "Castellum Dunstad"
  ],
  "stallion's potion": [
    "Stallionis Potio"
  ],
  "an active mine has already been placed on this enemy": [
    "An active mine has already been placed on this enemy"
  ],
  "ov": [
    "Ov"
  ],
  "speak to mercer frey": [
    "Adloqere Mercer Frey"
  ],
  "are you enjoying the party": [
    "Fruerisne epula"
  ],
  "i have to speak with astrid": [
    "Debeo Astridem Adloquere "
  ],
  "she's here, in the sanctuary": [
    "Illa hic est, in Fano"
  ],
  "daedric mace of leeching": [
    "Maca Daedrica Sanguisugae"
  ],
  "atronach thrall healing": [
    "Atronach Thrall Healing"
  ],
  "i think i'll hang onto it": [
    "Malo eam retinere"
  ],
  "bandit leader's journal": [
    "Ephemeris Ducis Latronum"
  ],
  "femaleeyeshumanyellow": [
    "FemaleEyesHumanYellow"
  ],
  "fort greymoor": [
    "Castellum Canamurae"
  ],
  "orcish dagger of scorching": [
    "Orci Pugio Adurendi"
  ],
  "have you known elenwen long": [
    "Multumne temporis Elenven novisti"
  ],
  "bright blond": [
    "Bright Blond"
  ],
  "some jarls resent the thalmor": [
    "Nonnulli Comites Visigothos in odio habent"
  ],
  "how about you": [
    "Quid de te"
  ],
  "steel plate helmet of magicka": [
    "Laminata Galea Magicae"
  ],
  "your heavy armor is causing you to sink": [
    "Your heavy armor is causing you to sink"
  ],
  "return to grosta": [
    "Reveni ad Grostam"
  ],
  "daedric bow of the inferno": [
    "Arcus Daedricus Conflagrationis"
  ],
  "glass battleaxe of lightning": [
    "Bellisecuris Vitreus Fulminis"
  ],
  "whoa, brother": [
    "Exspecta, frater"
  ],
  "don't you recognize a fellow guild member": [
    "Nonne amicum Sodalitatis agnoscis"
  ],
  "dwarven bow of freezing": [
    "Dvemeris Arcus Gelidus"
  ],
  "slaughterfish scales": [
    "Squamae Piscicaesus"
  ],
  "i don't have enough": [
    "Non satis pecuniae habeo"
  ],
  "hide boots of lifting": [
    "Renonis Caligae Sumendi"
  ],
  "iron dagger of diminishing": [
    "Pugio Ferri Diminuendi"
  ],
  "obtain a mark of dibella from bolli": [
    "Accipe Signum Dibellae a Bolli"
  ],
  "you must add... a septim": [
    "Debes addere... denarium"
  ],
  "ebony war axe of annihilating": [
    "Bellisecuris Ebeni Annihilationis"
  ],
  "ebony battleaxe of petrifying": [
    "Bellisecuris Ebeni Lapidescendi"
  ],
  "gulum-ei": [
    "Gulum-Ei"
  ],
  "greybeards": [
    "Canabarbae",
    "Greybeards"
  ],
  "mages guild radiant shell": [
    "Mages Guild Radiant Shell"
  ],
  "i'm ready for some adventure": [
    "Paratus sum itineri periculoso"
  ],
  "i'm not really interested in dangerous": [
    "Nihil periculosum mihi interest"
  ],
  "locked": [
    "Clausum"
  ],
  "leather helmet of magicka": [
    "Cutis Galea Magicae"
  ],
  "ebony war axe of thunderbolts": [
    "Bellisecuris Ebeni Fulminum"
  ],
  "did you hear the greybeards call \"dovahkiin\"": [
    "Audivistine Canabarbas \"Dovahkiin\" vocare"
  ],
  "the greybeards might know": [
    "Canabarbae, fortasse, sciunt"
  ],
  "greybeards might know": [
    "Canabarbae, fortasse, sciunt"
  ],
  "ebony helmet of major alchemy": [
    "Ebeni Galea Maioris Alchemiae"
  ],
  "the drunken huntsman": [
    "Venator Ebrius"
  ],
  "humanbeardlong04_1bit": [
    "HumanBeardLong04_1bit"
  ],
  "an elder scroll": [
    "Codex Veterrimus"
  ],
  "esbern or arngeir might have some idea": [
    "Fortasse Esbern vel Arngeir sciat"
  ],
  "mercer doesn't have to know": [
    "Non neccesse est Mercer de hoc discere"
  ],
  "maledremorahair02": [
    "MaleDremoraHair02"
  ],
  "are you saying the ancient nords sent alduin forward in time": [
    "Dicisne Germanos antiquos Alduinum prorsus in tempore mississe"
  ],
  "necromancer amulet": [
    "Necromanticum Monile"
  ],
  "research thief": [
    "Research Thief"
  ],
  "dragonscale armor": [
    "Draconisquamata Lorica"
  ],
  "show me where it is, before i take you over my knee": [
    "Show me where it is, before I take you over my knee"
  ],
  "actually, i've already found it": [
    "Re vera, id iam inveni"
  ],
  "is this the correct book": [
    "Estne liber"
  ],
  "you totally lost me": [
    "Me omnino amisisti"
  ],
  "hairmaleimperial1": [
    "HairMaleImperialis1"
  ],
  "aela and i work to avenge skjor's death": [
    "Ego et Aela laboramus ut mortem Skjoris vindicemus"
  ],
  "fjola's wedding band": [
    "Anulus Nuptualis Fjolae"
  ],
  "riverwood alvor gerdur scene": [
    "Riverwood Alvor Gerdur scene"
  ],
  "browsmalehumanoid10": [
    "BrowsMaleHumanoid10"
  ],
  "endrast": [
    "Endrast"
  ],
  "iron boots of dwindling frost": [
    "Ferri Caligae Minoris Congelationis"
  ],
  "mystery of talara, v3": [
    "Misterium Talarae, Pars III"
  ],
  "roasted ox head": [
    "Caput Bovis Assatum"
  ],
  "sven": [
    "Sven"
  ],
  "glass shield of extreme blocking": [
    "Vitreum Scutum Extremis Interponendi"
  ],
  "increased magicka": [
    "Magica Aucta"
  ],
  "coated in blood": [
    "Cruentatus"
  ],
  "maul": [
    "Malleus"
  ],
  "heratar": [
    "Heratar"
  ],
  "what's this about becoming an agent of nocturnal": [
    "Quid sibi vult Agens Nocturnalis"
  ],
  "steel battleaxe of scorching": [
    "Bellisecuris Adamanteus Adurendi"
  ],
  "hide boots of dwindling flames": [
    "Renonis Caligae Flammarum Diminuentium"
  ],
  "imperial bow of draining": [
    "Imperialis Arcus Hauriendi"
  ],
  "here's your gold": [
    "Hic aurum habes",
    "Hic aurum tuum habes"
  ],
  "(10,000 gold)": [
    "(MMMMMMMMMM auri)"
  ],
  "the power to crush all before me": [
    "Vim omnes ante me delendi quaero"
  ],
  "power to crush all before me": [
    "Vim omnes ante me delendi quaero"
  ],
  "glass claw": [
    "Ungues Draconis Vitreus"
  ],
  "elven mace of depleting": [
    "Maca Alfa Deplendi"
  ],
  "i don't have 10,000 gold": [
    "MMMMMMMMMM auri non habeo"
  ],
  "ahkari": [
    "Ahkari"
  ],
  "madesi's silver ring": [
    "Anulus Argenteus Madesi"
  ],
  "outofbreath": [
    "OutofBreath"
  ],
  "riften hosue of mjoll the lioness faction": [
    "Hiati Hosue of Mjoll the Lioness Faction"
  ],
  "helgi's ghost": [
    "Phantasma Helgi"
  ],
  "steel sword of sapping": [
    "Gladius Adamanteus Hauriendi"
  ],
  "you ok": [
    "Benene agis"
  ],
  "maybe i'll come back later": [
    "Fortasse postea reveniam"
  ],
  "i tried my best": [
    "Conatus sum optime ut potui"
  ],
  "elixir of glibness": [
    "Commixtum Loquacitatis"
  ],
  "you've killed the high king": [
    "Regem Altum necavisti"
  ],
  "why make war on your own people": [
    "Cur Bellum contra suos geris"
  ],
  "bow of the hunt": [
    "Arcus Venandi"
  ],
  "you won't miss it, then": [
    "Ergo id non desiderabis"
  ],
  "ebony greatsword of the blaze": [
    "Spatha Ebeni Congflagrantis"
  ],
  "adonato leotelli": [
    "Adonato Leotelli"
  ],
  "cracked white phial": [
    "Atrophorion Album Rimatum"
  ],
  "a guardian skeever": [
    "Custos skeever"
  ],
  "guardian skeever": [
    "Custos skeever"
  ],
  "are you serious": [
    "Re vera"
  ],
  "mistveil keep faction": [
    "Mistveil Keep Faction"
  ],
  "i go where i please, orc": [
    "Eo ubi volo, Orce"
  ],
  "attunement sphere": [
    "Sphera Foci"
  ],
  "perhaps i could view your work": [
    "Fortasse laborem tuam videre possim"
  ],
  "raen": [
    "Raen"
  ],
  "how can i convince you to let me in": [
    "Quomodo possum tibi suadere ut me intrare patiaris"
  ],
  "npcs the player cannot marry": [
    "NPCs the Player cannot marry"
  ],
  "let's just say hircine would be jealous": [
    "Hecate invidens sit"
  ],
  "did something go wrong": [
    "Erravitne alquid"
  ],
  "malacath": [
    "Styx"
  ],
  "any information on the prison registry": [
    "Habesne informationem de tabula carceris"
  ],
  "i've brought the phial, but it's damaged": [
    "Atrophorion tuli, sed fractum est"
  ],
  "elven gilded armor of extreme health": [
    "Alfa Lorica Aurata Extremis Salutis"
  ],
  "andurs' amulet of arkay": [
    "Monile Arkayae Anduris"
  ],
  "torygg's war horn": [
    "Gjallrhorn"
  ],
  "hide boots of waning fire": [
    "Renonis Caligae Insenescentis Ignis"
  ],
  "all ammo": [
    "All Ammo"
  ],
  "ravyn imyan": [
    "Ravyn Imyan"
  ],
  "wortcraft": [
    "Wortcraft"
  ],
  "(<global=hpwhiterun> gold)": [
    "(<Global=HPWhiterun> auri)"
  ],
  "makes enemy of weprisonerfreedcombatcaptorfaction": [
    "Makes enemy of WEPrisonerFreedCombatCaptorFaction"
  ],
  "what else do i need to know": [
    "Quid plus mihi sciendum est"
  ],
  "jeweled candlestick": [
    "Ceroferarium Gemmatum"
  ],
  "daedric bow of winter": [
    "Arcus Daedricus Hiemis"
  ],
  "elven gilded armor of the major squire": [
    "Alfa Lorica Aurata Maioris Armiferis"
  ],
  "scroll of banish daedra": [
    "Codex Repelle Daedram"
  ],
  "leather armor of minor health": [
    "Corii Lorica Minoris Salutis"
  ],
  "ebony helmet of major restoration": [
    "Ebeni Galea Maioris Reficiendi"
  ],
  "call dragon - all": [
    "Call Dragon - all"
  ],
  "daedric mace of evoking": [
    "Maca Daedrica Evocandi"
  ],
  "orcish war axe of animus": [
    "Orci Bellisecuris Animi"
  ],
  "tilma the haggard": [
    "Tilma Vetus"
  ],
  "chronicles of nchuleft": [
    "Annales Nchuleftis"
  ],
  "elven gilded armor of the eminent knight": [
    "Alfa Lorica Aurata Eminentis Equitis"
  ],
  "where did you find this, boy": [
    "Ubi hoc invenisti, puer"
  ],
  "i'd rather die than go to jail": [
    "Malim mori quam carcerem petere"
  ],
  "iron battleaxe of diminishing": [
    "Bellisecuris Ferri Diminuendi"
  ],
  "glass gauntlets of major wielding": [
    "Vitreae Manicae Maioris Tenendi"
  ],
  "valindor": [
    "Valindor"
  ],
  "elven war axe of scorching": [
    "Alfus Bellisecuris Adurendi"
  ],
  "takes-in-light": [
    "Accipe-Lucem"
  ],
  "so they were like your kind": [
    "Ergo ut tu sunt"
  ],
  "reverent dwarven war axe": [
    "Reverens Dvemeris Bellisecuris"
  ],
  "orichalcum ingot": [
    "Orichalcum Later"
  ],
  "may i commune with peryite": [
    "Possumne ad Peryitum loqui"
  ],
  "something tells me that it is": [
    "Aliquid mihi dicit eum verum esse"
  ],
  "kjar": [
    "Kjar"
  ],
  "ebony battleaxe of storms": [
    "Bellisecuris Ebeni Tempestatium"
  ],
  "hairlinemalenordchild01": [
    "HairLineMaleNordChild01"
  ],
  "i'm sorry, i don't know her": [
    "Me paenitet, eam non novi"
  ],
  "potent aversion to frost": [
    "Potens Aversio Glaciei"
  ],
  "they sound more like victims to me": [
    "Victimae mihi videntur"
  ],
  "dwarven armor of major alteration": [
    "Dvemeris Lorica Maioris Mutationis"
  ],
  "you appear to despise them": [
    "Eos in odio habere videris"
  ],
  "can you teach me about speechcraft": [
    "Potesne mihi docere de Loquacitate"
  ],
  "forsworn axe": [
    "Abiuratus Securis"
  ],
  "you think dagon will repair the razor": [
    "Censesne Chronon novaculum refecturum esse"
  ],
  "sealed scroll": [
    "Codex Clausus"
  ],
  "imperial helmet of conjuration": [
    "Romana Galea Coniurationis"
  ],
  "i could deliver that for you": [
    "Id pro te ferre possum"
  ],
  "ebony war axe of stunning": [
    "Bellisecuris Ebeni Intermissionis"
  ],
  "why are you out here": [
    "Cur hic es"
  ],
  "mul gro-largash": [
    "Mul gro-Largash"
  ],
  "karliah's boots": [
    "Karliae Caligae"
  ],
  "ebony dagger of thunderbolts": [
    "Pugio Ebeni Fulminum"
  ],
  "dagon has spoken": [
    "Dagon locutus est"
  ],
  "dialoguegenericknockoverobject": [
    "DialogueGenericKnockOverObject"
  ],
  "get out of here": [
    "Discede"
  ],
  "are you jealous of her": [
    "Esne invidens eius"
  ],
  "humanbeard21": [
    "HumanBeard21"
  ],
  "i cleared out the vampires from <alias=dungeon>": [
    "Vacuavi vampiros e <Alias=Dungeon>"
  ],
  "speechcraft trainer": [
    "Speechcraft Trainer"
  ],
  "tell me about hern": [
    "Dic mihi de Hern"
  ],
  "testcarryfirewood": [
    "TestCarryFirewood"
  ],
  "the wolf queen, v6": [
    "Regina Luporum, VI"
  ],
  "wolf queen, v6": [
    "Regina Luporum, VI"
  ],
  "mg dormitory scene 7": [
    "MG Dormitory Scene 7"
  ],
  "grave concoctions": [
    "Concoctiones Graves"
  ],
  "thalmor warrior": [
    "Visigothi Warrior"
  ],
  "steel plate boots of shock suppression": [
    "Laminatae Caligae Suppresionis Electricitatis"
  ],
  "istar cairn-breaker": [
    "Istar Monolithos-Fractor"
  ],
  "fort kastav prison key": [
    "Clavis ad Carcerem Castelli Kastav"
  ],
  "salma": [
    "Salma"
  ],
  "humanbeard28": [
    "HumanBeard28"
  ],
  "elven mace of immobilizing": [
    "Maca Alfa Immobilitatis"
  ],
  "tell nelacar you're ready to enter the star": [
    "Dic Nelacarem te Paratum Esse Stellam Intrare"
  ],
  "self loathing": [
    "Odium pro se"
  ],
  "conquered": [
    "Victum"
  ],
  "paranoia": [
    "Paranoia"
  ],
  "abolished": [
    "Abrogatum"
  ],
  "you don't know it": [
    "Id nescis"
  ],
  "the greybeards didn't want me to come at all": [
    "Canabarbae me venire omnino nolebant"
  ],
  "greybeards didn't want me to come at all": [
    "Canabarbae me venire omnino nolebant"
  ],
  "how did you know i came for dragonrend": [
    "Quomodo me pro draconicaesi venire scivisti"
  ],
  "thalmor wizard": [
    "Visigothi Thaumatorgos"
  ],
  "sharedinfo": [
    "SharedInfo"
  ],
  "would ten pinches of fire salts help": [
    "Adiuventne decem decunces Salis Ignis"
  ],
  "potion of light feet": [
    "Potio Pedum Levium"
  ],
  "daedric greatsword of fire": [
    "Spatha Daedrica Ignis"
  ],
  "oengul war-anvil": [
    "Oengul Belli-Incus"
  ],
  "enjoy the wedding": [
    "Fruere sponsali"
  ],
  "learn the location of the elder scroll": [
    "Disce ubi sit Codex Veterrimus"
  ],
  "i'll be relieving you of those gifts": [
    "Illa dona abs te removebo"
  ],
  "dragonplate helmet of eminent alchemy": [
    "Draconilaminata Galea Alchemiae Eminentis"
  ],
  "twilight sepulcher inner sanctum": [
    "Sepulchrum Crepusculum Sanctum Interius"
  ],
  "ebony mace of stunning": [
    "Maca Ebeni Intermissionis"
  ],
  "jade and emerald circlet": [
    "Venificae et Smaragdi Circulum"
  ],
  "hasn't skyrim always been part of the empire": [
    "Nonne Caelifinis pars Imperii semper erat"
  ],
  "saerek's skull key": [
    "Saerekis Calvaria Clavis"
  ],
  "steel greatsword of draining": [
    "Spatha Adamantea Hauriendi"
  ],
  "the white phial (empty)": [
    "Atrophorion Album (Vacuum)"
  ],
  "white phial (empty)": [
    "Atrophorion Album (Vacuum)"
  ],
  "avanchnzel": [
    "Avanchnzel"
  ],
  "the thalmor assassin is dead. it's safe to leave windhelm": [
    "Sicarius Visigothi mortuus est. Tutues est Ventigalea discedere"
  ],
  "thalmor assassin is dead. it's safe to leave windhelm": [
    "Sicarius Visigothi mortuus est. Tutues est Ventigalea discedere"
  ],
  "do you consider the thalmor enemies": [
    "Putasne Visigothos inimicos esse"
  ],
  "letter from olfina": [
    "Epistula ab Olfina"
  ],
  "what's the harm": [
    "Quid noceat"
  ],
  "besides, tullius doesn't really want her here either": [
    "Preater hoc, Tullius quoque adire non vult"
  ],
  "daedric sword of banishing": [
    "Gladius Daedricus Expellendi"
  ],
  "all friends in here": [
    "all friends in here"
  ],
  "orcish warhammer of animus": [
    "Orci Bellimalleus Animi"
  ],
  "daedric sword of enervating": [
    "Gladius Daedricus Enervationis"
  ],
  "noster eagle-eye": [
    "Noster Aquilae-Oculus"
  ],
  "key to jala's house": [
    "Clavis ad Domum Jalae"
  ],
  "malign vigor poison": [
    "Malignum Venenum Vigoris"
  ],
  "suvaris atheron's logbook": [
    "Liber Suvaris Atheronis"
  ],
  "summerset shadow": [
    "Summerset Shadow"
  ],
  "polymorph skeever": [
    "Polymorph Skeever"
  ],
  "ancient nord boots": [
    "Antiquae Germanicae Caligae"
  ],
  "elven warhammer of freezing": [
    "Alfus Bellimaleus Gelidus"
  ],
  "dwarven dagger of scorching": [
    "Dvemeris Pugio Adurendi"
  ],
  "i can't afford that": [
    "Id solvere non possum"
  ],
  "recover krag's journal": [
    "Inveni Ephemeridem Kragis"
  ],
  "azzada lylvieve": [
    "Azzada Lylvieve"
  ],
  "what are you doing out here": [
    "Quid hic facis"
  ],
  "elven bow of lightning": [
    "Arcus Alfus Fulminis"
  ],
  "dialogueangasmill": [
    "DialogueAngasMill"
  ],
  "dragonplate armor of extreme health": [
    "Draconilaminata Lorica Extremis Salutis"
  ],
  "teach me your spell, and i'll see what i can do": [
    "Doce me incantationem tuam, et videbimus quid me facere posse"
  ],
  "moorside inn services": [
    "Moorside Inn Services"
  ],
  "yes, i'm fine": [
    "Sic, bene me habeo"
  ],
  "dawnstar barracks": [
    "Luciferis Tabernacula"
  ],
  "i think i've earned that 100 gold": [
    "Credo me illud C Auri merere"
  ],
  "tsrasuna": [
    "Tsrasuna"
  ],
  "so you have no problem with the treaty that bans the worship of talos": [
    "Ergo nullum problema cum foedere quod vernerationem Alexandri vetat"
  ],
  "glass sword of reaping": [
    "Gladius Vitreus Saturni"
  ],
  "daedric dagger of banishing": [
    "Pugio Daedricus Expellendi"
  ],
  "daedric dagger of enervating": [
    "Pugio Daedricus Enervationis"
  ],
  "gjak": [
    "Gjak"
  ],
  "ancient nord amulet": [
    "Antiquum Germanicum Monile"
  ],
  "banded iron shield of resist frost": [
    "Ferri Adligati Scutum Contra Vim Gelidam"
  ],
  "treoy's ornamental ring": [
    "Anulus Treoyis Ornamentalis"
  ],
  "give me the key, mercer": [
    "Da mihi Clavem, Mercer"
  ],
  "maleeyesdarkelfdeepred2": [
    "MaleEyesDarkElfDeepRed2"
  ],
  "sanctified iron battleaxe": [
    "Sanctificatus Bellisecuris Ferri"
  ],
  "you must exit and restart skyrim for the new resolution to take effect": [
    "You must exit et restart Caelifinis for new resolution to take effect"
  ],
  "key to alftand lift": [
    "Clavis ad Ascensorium Alfterrae"
  ],
  "femalebrowshuman09": [
    "FemaleBrowsHuman09"
  ],
  "daedric sword": [
    "Gladius Daedricus"
  ],
  "daedric battleaxe of depleting": [
    "Bellisecuris Daedricus Deplendi"
  ],
  "tsun's boots": [
    "Tsunis Caligae"
  ],
  "the arcturian heresy": [
    "Haeresis Arcturianis"
  ],
  "arcturian heresy": [
    "Haeresis Arcturianis"
  ],
  "i don't have the gold for that": [
    "Satis auri non habeo"
  ],
  "doesn't anyone care that orthorn stole things from the college": [
    "Nonne quis curat Orthorn res e Collegio furatum esse"
  ],
  "elven boots of strength": [
    "Caligae Alfae Fortitudinis"
  ],
  "then we'd best get started": [
    "Ergo incipere debemus"
  ],
  "helm of yngol": [
    "Galea Yngol"
  ],
  "what else can you tell me about the staff of magnus": [
    "Quid aliud mihi de Rude Magno narrare potes"
  ],
  "blessing of zenithar": [
    "Benedictio Zenitharis"
  ],
  "hide bracers of smithing": [
    "Chirothecae Cutis Ferrarii"
  ],
  "mzulft boilery": [
    "Mzulft Furnax"
  ],
  "orcish dagger of depleting": [
    "Orci Pugio Deplendi"
  ],
  "toll": [
    "Rotarium"
  ],
  "what toll": [
    "Quod Rotarium"
  ],
  "flawless emerald": [
    "Perfectus Smaragdus"
  ],
  "dwarven warhammer of debilitation": [
    "Dvemeris Bellimalleus Debilitationis"
  ],
  "dialoguekarthwastent01enmonshousescene01": [
    "DialogueKarthwastenT01EnmonsHouseScene01"
  ],
  "humanskindarkskin10": [
    "HumanSkinDarkSkin10"
  ],
  "iron battleaxe of soul snares": [
    "Bellisecuris Ferri Animicapti"
  ],
  "grelod the kind is dead": [
    "Grelod Benigna mortua est"
  ],
  "i can't get soul gems for you": [
    "Gemmanimas tibi ferre non possum"
  ],
  "i have other things to do": [
    "Alias res faciendas habeo"
  ],
  "dwarven greatsword of cowardice": [
    "Dvemeris Spatha Timoris"
  ],
  "i'm sorry to hear that": [
    "Me paenitet illud audire"
  ],
  "ebony war axe of evoking": [
    "Bellisecuris Ebeni Evocandi"
  ],
  "imperial helmet of major conjuration": [
    "Romana Galea Maioris Vocandi"
  ],
  "vilemyr inn faction": [
    "Vilemyr Inn Faction"
  ],
  "imperial helmet of minor archery": [
    "Romana Galea Minoris Saggitationis"
  ],
  "who was beirand": [
    "Quis erat Beirand"
  ],
  "chaurus chitin": [
    "Chauri Folliculus"
  ],
  "i'll shake the extra coin out of you if i must": [
    "Alium nummum e te extraham si debeam"
  ],
  "scroll of turn undead": [
    "Codex Amortuos Repellendi"
  ],
  "mystic focal point": [
    "Punctum Foci Mystici"
  ],
  "daedric mace of blizzards": [
    "Maca Daedrica Hiemis"
  ],
  "redbelly mine": [
    "Rubriventris Metallum"
  ],
  "daedric war axe of harvesting": [
    "Bellisecuris Daedricus Saturni"
  ],
  "silus vesuius says otherwise": [
    "Silus Vesuius aliter dicit"
  ],
  "do you have any sweep jobs": [
    "Habesne negotia sveep"
  ],
  "lymdrenn tenvanni's journal": [
    "Ephemeris Lymdrennis Tenvanni"
  ],
  "daedric bow of dread": [
    "Arcus Daedricus Timoris"
  ],
  "return the books": [
    "Redde Libros"
  ],
  "i'm willing to pay": [
    "Volo pecuniam solvere"
  ],
  "elven sword of consuming": [
    "Alfus Gladius Consumendi"
  ],
  "i have wheat to sell": [
    "Triticum vendendum habeo"
  ],
  "elven armor": [
    "Alfa Lorica"
  ],
  "vighar is dead": [
    "Vighar mortuus est"
  ],
  "a kiss, sweet mother": [
    "Basium, Mater Dulcis"
  ],
  "kiss, sweet mother": [
    "Basium, Mater Dulcis"
  ],
  "key to fruki's house": [
    "Clavis ad Domum Fruki"
  ],
  "what happened after gallus died": [
    "Quid post mortem Galli accidit"
  ],
  "this isn't about the money anymore": [
    "Non iam de pecunia agitur"
  ],
  "what else is there to be learned about illusion magic": [
    "Quid aliud discendum est de Magica Inlusionis"
  ],
  "why save me": [
    "Cur me servavisti"
  ],
  "then i'm in your debt": [
    "Ergo obaeratus tibi sum"
  ],
  "scabbard of mehrunes' razor": [
    "Vagina Novaculae Croni"
  ],
  "tg02 aringoth player enemy faction": [
    "TG02 Aringoth Player Enemy Faction"
  ],
  "you should have shot mercer instead": [
    "Mercer transfigere debuisti"
  ],
  "why capture mercer alive": [
    "Cur Mercerem vivum capimus"
  ],
  "sharamph": [
    "Sharamph"
  ],
  "hunting bow of ice": [
    "Arcus Venandi Gelidus"
  ],
  "a scrawled note": [
    "Epistula Celeriter Scripta"
  ],
  "i am your servant, my lord": [
    "Servus tuus sum, Domine"
  ],
  "well, what's it say": [
    "Ergo, quid dicit"
  ],
  "there's that word again, \"nightingale.\"": [
    "Iterum verbum audio, \"Philomela.\""
  ],
  "you can be resentful or you can help me": [
    "Iratum esse potes vel me adiuvare potes"
  ],
  "your choice": [
    "Optio tua est"
  ],
  "scars": [
    "Cicatrices"
  ],
  "dro'marash": [
    "Dro'marash"
  ],
  "what was that argument about": [
    "De quo agebatur illum argumentum"
  ],
  "haelga's bunkhouse scene 04": [
    "Haelga's Bunkhouse Scene 04"
  ],
  "a courier gave me this invitation": [
    "Nuntius mihi hanc invitationem dedit"
  ],
  "courier gave me this invitation": [
    "Nuntius mihi hanc invitationem dedit"
  ],
  "elven sword of leeching": [
    "Alfus Gladius Sanguisugae"
  ],
  "jyrik gauldurson": [
    "Jyrik Gauldrifilius"
  ],
  "mzinchaleft work order": [
    "Mandatum Laboris Mzinchaleftis"
  ],
  "about the secrecy of the nightingales": [
    "De sectreto Philomelarum"
  ],
  "baked potatoes": [
    "Mala Terrae Coctae"
  ],
  "iron gauntlets of smithing": [
    "Ferri Manicae Ferrarii"
  ],
  "just leave me alone": [
    "Modo e me discede"
  ],
  "dwarven dagger of harvesting": [
    "Dvemeris Pugio Saturni"
  ],
  "hjaalmarch guard's helmet": [
    "Halmarcis Custodis Galea"
  ],
  "here's the soul gem you asked for": [
    "Hic habes gemmanimam quam voluisti"
  ],
  "it's quite the tale": [
    "Fabula magna est"
  ],
  "there is only one cure for your madness, cicero": [
    "Unum remedium solum est insanitati tuo, Cicero"
  ],
  "dialoguedawnstarwhitehallmadenahorik02": [
    "DialogueDawnstarWhiteHallMadenaHorik02"
  ],
  "shield-sister": [
    "Shield-Sister"
  ],
  "return the amulet to andurs": [
    "Redde monile ad Andurs"
  ],
  "spell tome: call to arms": [
    "Liber Magicus: Vocatio Armis"
  ],
  "you couldn't afford my time": [
    "Non etiam tempus meum emer possis"
  ],
  "so the staff of magnus is in labyrinthian": [
    "Ergo Rudis Magnus Labyrintho est"
  ],
  "i just wanted to see what it looks like inside": [
    "Modo interiorem videre volui"
  ],
  "key to the temple of the divinves": [
    "Clavis ad Templum Divinorum"
  ],
  "i think we both know i'll succeed here": [
    "Credo nos ambos scire me hic victurum esse"
  ],
  "how do i know i can trust you": [
    "Quomodo scio me tibi confidere posse"
  ],
  "aura whisper - interior": [
    "Aura Whisper - Interior"
  ],
  "and that won't work": [
    "Et non fungatur"
  ],
  "would you grant entry to the dragonborn": [
    "Sinasne Draconigenam intrare"
  ],
  "that proves i have the voice": [
    "Illud probat me Vocem habere"
  ],
  "dragon priest dagger": [
    "Pugio Sacerdotis Draconis"
  ],
  "yes, you do": [
    "Sic, debes"
  ],
  "snow bear abilities": [
    "Snow Bear abilities"
  ],
  "who is the mother": [
    "Quis est mater"
  ],
  "blessing of stendarr": [
    "Benedictio Stendarris"
  ],
  "i haven't finished the job yet": [
    "Munus nondum complevi"
  ],
  "hairmaleredguard4": [
    "HairMaleRedguard4"
  ],
  "what do you need from me": [
    "Quid de me quaeris"
  ],
  "here is the scroll": [
    "Hic est codex"
  ],
  "hairfemalenord07": [
    "HairFemaleNord07"
  ],
  "iron dagger of souls": [
    "Pugio Ferri Animorum"
  ],
  "orcish battleaxe of draining": [
    "Orci Bellisecuris Hauriendi"
  ],
  "i don't have any of my old armor": [
    "Loricam veterem non habeo"
  ],
  "you'll have to find some other way to stroke your ego": [
    "Aliam viam qua magnificentiam tuam saginare invenire debes"
  ],
  "greentintaverage": [
    "GreenTintAverage"
  ],
  "identify the buyer and we'll forget what we know": [
    "Nomen emptoris mihi da et rerum quas scimus obliviscemur"
  ],
  "actually, she's a vampire. she planned to enslave the town": [
    "Re vera, illa vampira est. Voluit oppidum in servitutem imponere"
  ],
  "kjeld the younger": [
    "Kjeld Iuvenior"
  ],
  "rorik": [
    "Rorik"
  ],
  "you did try to trick me into letting you go": [
    "Ut te liberarem me decipere temptavisti"
  ],
  "key to karthwasten hall": [
    "Clavis ad Aulam Karthwasten"
  ],
  "the mythic dawn": [
    "Aurorae Mythicae"
  ],
  "mythic dawn": [
    "Aurorae Mythicae"
  ],
  "forsworn briarheart": [
    "Abiuratus Cordumus"
  ],
  "humanskindarkskin02": [
    "HumanSkinDarkSkin02"
  ],
  "storm atronach attack": [
    "Storm Atronach Attack"
  ],
  "mystic axe": [
    "Mysticus Securis"
  ],
  "museum of the mythic dawn": [
    "Museion Aurorae Mythicae"
  ],
  "not interested": [
    "Mihi non iterest"
  ],
  "you're going back where you belong, dremora": [
    "Ad locum tibi aptum revenies, dremora"
  ],
  "dragon faction": [
    "Dragon Faction"
  ],
  "elven bracers of lockpicking": [
    "Chirothecae Alfae Clavicarii"
  ],
  "bring the unusual gem to an appraiser": [
    "Fer Gemmam Mirablem ad aestimatorem"
  ],
  "jouane manette": [
    "Jouane Manette"
  ],
  "shor's stone odfel's house faction": [
    "Shor's Stone Odfel's House Faction"
  ],
  "journal": [
    "Ephemeris"
  ],
  "mulush gro-shugurz": [
    "Mulush gro-Shugurz"
  ],
  "argonian assemblage key": [
    "Clavis ad Consilium Argoniorum"
  ],
  "imperial bracers of major sure grip": [
    "Chirothecae Romanae Maioris Dexteritatis"
  ],
  "hallowed glass mace": [
    "Consacrata Maca Vitrea"
  ],
  "hud opacity": [
    "HUD Opacitas"
  ],
  "clear skies effect 2": [
    "Clear Skies Effect 2"
  ],
  "ebony war axe of garnering": [
    "Bellisecuris Ebeni Horti"
  ],
  "virulent fear poison": [
    "Virulens Venenum Timoris"
  ],
  "world of fx light regions": [
    "World of FX Light Regions"
  ],
  "orcish shield of frost abatement": [
    "Orci Scutum Gelidus Resistendi"
  ],
  "childmouth": [
    "ChildMouth"
  ],
  "lighthouse brazier": [
    "Foculus Pharos"
  ],
  "journeyman censer": [
    "Journeyman Censer"
  ],
  "fisherman's journal": [
    "Ephemeris Piscatoris"
  ],
  "i cured kodlak": [
    "Kodlakem Curavi"
  ],
  "riften honorhall orphanage faction": [
    "Hiati Honorhall Orphanage Faction"
  ],
  "i really do apologize": [
    "Re vera me paenitet"
  ],
  "i think i've picked up everything": [
    "Credo me templum omnino mundum fecisse"
  ],
  "kodlak said i should lead the companions now": [
    "Kodlak dixit mihi necesse esse Contubernales nunc ducere"
  ],
  "pommel stone of mehrunes' razor": [
    "Lapis Capuli Novaculae Croni"
  ],
  "thieves guild dialogue scene 03": [
    "Sodalitatis Furum Dialogue Scene 03"
  ],
  "kematu's dead, and now you're safe": [
    "Kematu mortuus est, et tutus nunc es"
  ],
  "why should i care": [
    "Quemobrem mihi curandum est"
  ],
  "i'd like a sword": [
    "Gladium volo"
  ],
  "hold the front line for as long as possible": [
    "Defende Primum Ordinem Tam Longe ut Potes"
  ],
  "alto wine": [
    "Vinum Altum"
  ],
  "mirmulnir": [
    "Mirmulnir"
  ],
  "ebony mace of freezing": [
    "Maca Ebeni Gelidus"
  ],
  "dragonscale helmet of extreme illusion": [
    "Draconisquamata Galea Inlusionis Extremae"
  ],
  "trainingexit": [
    "TrainingExit"
  ],
  "candlehearth hall services": [
    "Candlehearth Hall Services"
  ],
  "predator": [
    "Predator"
  ],
  "are you the only wizard in whiterun": [
    "Esne Thamaturgos solus Albacursus"
  ],
  "you'd better calm down": [
    "Opus est tibi mollem esse"
  ],
  "elven mace of malediction": [
    "Maca Alfa Maledictionis"
  ],
  "i haven't done anything, i swear": [
    "Nihil feci, iuro"
  ],
  "imperial light helmet of illusion": [
    "Romana Cassis Inlusionis"
  ],
  "i truly have no idea what you're talking about": [
    "Omnino necio de qua re loquaris"
  ],
  "is there some kind of trouble in morthal": [
    "Estne quid difficultas hic Morthalis"
  ],
  "i don't understand why you're so upset": [
    "Nescio cur tam sollicitus sis"
  ],
  "orcish mace": [
    "Orci Maca"
  ],
  "daedric warhammer of terror": [
    "Bellimalleus Daedricus Terroris"
  ],
  "you mean the eye of magnus": [
    "De Oculo Magni loqueris"
  ],
  "i will use the razor as i see fit, dagon": [
    "Novacula utar ut velim, Crone"
  ],
  "scaled armor": [
    "Lorica Squamata"
  ],
  "pelagius' frost thrall": [
    "Servus Glaciei Pelagii"
  ],
  "tell azura you're ready to enter the star": [
    "Dic Azuram te paratum esse Stellam intrare"
  ],
  "glass war axe of garnering": [
    "Bellisecuris Vitreus Horti"
  ],
  "shadr": [
    "Shadr"
  ],
  "ainethach": [
    "Ainethach"
  ],
  "gauldur amulet fragment": [
    "Gauldur Monile Fragmentum"
  ],
  "key to house battle-born": [
    "Clavis ad Domum Proeli-Natus"
  ],
  "why were you searching in skyrim": [
    "Cur in Caelifine quaesivisti"
  ],
  "cot": [
    "Lectulum"
  ],
  "shouts": [
    "Vocationes",
    "CLAMATIONES"
  ],
  "steel helmet of minor illusion": [
    "Adamantea Galea Minoris Inlusionis"
  ],
  "so you're a member of the bards college": [
    "Ergo pars Collegii Bardorum es"
  ],
  "he's a traitor of some kind": [
    "Proditor est"
  ],
  "i can worship anyone i want": [
    "Quemque velim adorare possum"
  ],
  "the plains district of whiterun is home to the city's shops and market, while the wind district is mostly residential": [
    "Regio Campestris Albacursus est locus qui forum et tabernas continet, dum Regio Venti praesertim factast domorum"
  ],
  "plains district of whiterun is home to the city's shops and market, while the wind district is mostly residential": [
    "Regio Campestris Albacursus est locus qui forum et tabernas continet, dum Regio Venti praesertim factast domorum"
  ],
  "the jarl's palace, dragonsreach, dominates the cloud district": [
    "Aula Comitis, Draconicarcer, superest Regionem Nubium"
  ],
  "jarl's palace, dragonsreach, dominates the cloud district": [
    "Aula Comitis, Draconicarcer, superest Regionem Nubium"
  ],
  "give me the buyer's name or i'll kill you": [
    "Da mihi nomen emptoris, vel te necam"
  ],
  "on second thought, i'll be leaving": [
    "Ut iterum cogito, exeam"
  ],
  "imperial bracers of minor deft hands": [
    "Chirothecae Romanae Minoris Dexteritatis"
  ],
  "dialoguedawnstarinnkaritathoringscene01": [
    "DialogueDawnstarInnKaritaThoringScene01"
  ],
  "what's wrong with worshipping talos": [
    "Quid est difficultas cum religione Alexandri"
  ],
  "i just need some supplies and a drink": [
    "Solum supplementa et bibita quaero"
  ],
  "daedric sword of lightning": [
    "Gladius Daedricus Fulminis"
  ],
  "iron gauntlets of major alchemy": [
    "Ferri Manicae Maioris Alchemiae"
  ],
  "never heard of him": [
    "De eo numquam audivi"
  ],
  "(optional) talk to esbern": [
    "(Optio) Adloquere Esbernem"
  ],
  "spell tome: circle of protection": [
    "Liber Magicus: Circulum Praesidii"
  ],
  "the augur referred to it as the eye of magnus, not me": [
    "Augur nomen 'Oculi Magni' ei nomen dedit, non ego"
  ],
  "augur referred to it as the eye of magnus, not me": [
    "Augur nomen 'Oculi Magni' ei nomen dedit, non ego"
  ],
  "arnbjorn": [
    "Arnbjorn"
  ],
  "so, were all the dragons killed in the dragon war": [
    "Ergo, necati sunt omnes dracones in Bello Draconum"
  ],
  "has the war been hard on markarth": [
    "Bellumne durum erat Markartiae"
  ],
  "chin color": [
    "Color Menti"
  ],
  "has been damaged": [
    "nocitus est"
  ],
  "that won't do": [
    "Non licet"
  ],
  "i have pressing business for the jarl": [
    "Negotium Comiti habeo"
  ],
  "hairfemaledarkelf03": [
    "HairFemaleDarkElf03"
  ],
  "storm call effect 2": [
    "Storm Call Effect 2"
  ],
  "glass dagger of depleting": [
    "Pugio Vitreus Deplendi"
  ],
  "must you follow me... everywhere": [
    "Must you sequere me... everywhere"
  ],
  "ebony bow": [
    "Arcus Ebeni"
  ],
  "what's wrong with the jarl": [
    "Quid est difficultas cum Comite"
  ],
  "ancano's sparks": [
    "Ancano's Sparks"
  ],
  "daedric battleaxe of thunderbolts": [
    "Bellisecuris Daedricus Fulminum"
  ],
  "white river prisoner faction": [
    "White River Prisoner Faction"
  ],
  "silver ore": [
    "Argentei Minerale"
  ],
  "solitude evette services": [
    "Solitudinis Evette services"
  ],
  "audiotemplatedragon": [
    "AudioTemplateDragon"
  ],
  "steel helmet of minor archery": [
    "Adamantea Galea Minoris Saggitationis"
  ],
  "ria": [
    "Ria"
  ],
  "leather bracers of minor smithing": [
    "Corii Chirothecae Minoris Ferrarii"
  ],
  "imperial light helmet of alteration": [
    "Romana Cassis Mutationis"
  ],
  "can you train me in alchemy": [
    "Potesne mihi de Alchemia docere"
  ],
  "femaleeyesorcred": [
    "FemaleEyesOrcRed"
  ],
  "daedric gauntlets of extreme sure grip": [
    "Daedricae Manicae Extremis Ambidexteritatis"
  ],
  "subjugated ghost": [
    "Phantasma Subiugata"
  ],
  "daedric dagger of lightning": [
    "Pugio Daedricus Fulminis"
  ],
  "imperial guard jailor": [
    "Imperialis Custos Carceris"
  ],
  "balgruuf's greatsword": [
    "Spatha Balgruufi"
  ],
  "what do you know of the khajiit": [
    "Quid de Khajiitis Scis"
  ],
  "gadnor's staff of charming": [
    "Rudis Gadnoris"
  ],
  "we're beneath winterhold college": [
    "Sub Collegium Hiemiterrae sumus"
  ],
  "souls, black and white": [
    "Animi, Atri et Albi"
  ],
  "ruling government": [
    "Ruling Government"
  ],
  "mammoth cheese bowl": [
    "Elephantis Caseus Patera"
  ],
  "i'll find those gauntlets": [
    "Illas manicas tibi inveniam"
  ],
  "elven greatsword of winnowing": [
    "Spatha Alfa Ventagii"
  ],
  "123 abc def ghijklmnop qrstu vwxyz 123456789": [
    "123 Abc Def Ghijklmnop Qrstu VVXYZ 123456789"
  ],
  "kilkreath catacombs": [
    "Catacumbae Kilkretiae"
  ],
  "you say that name like i should know it": [
    "Nomen dixisti quasi id scire deberem"
  ],
  "you must level up to train more": [
    "Opus est tibi ad novum gradum ascendere ut plus discere possis"
  ],
  "dwemer lockbox": [
    "Arca Dvemeris"
  ],
  "and you're helping her": [
    "Et eam adiuvas"
  ],
  "dwarven war axe of siphoning": [
    "Dvemeris Bellisecuris Siphonis"
  ],
  "where is karliah now": [
    "Ubi est Karlia nunc"
  ],
  "last scabbard of akrash": [
    "Vagina Ultima Akrashi"
  ],
  "so... i'm free to go": [
    "Sic... liber nunc sum"
  ],
  "iron war axe of soul snares": [
    "Bellisecuris Ferri Animicapti"
  ],
  "where do i go for a bit of fun around here": [
    "Quo eo pro aliquo diversione hic"
  ],
  "what do you know about haldyn": [
    "Quid de Haldyn scis"
  ],
  "db02captive3persuade": [
    "DB02Captive3Persuade"
  ],
  "who's arkay": [
    "Quis est Arkaya"
  ],
  "how can you make peace with your father": [
    "Quomodo pacem cum patre facere potes"
  ],
  "banded iron armor of the minor knight": [
    "Ferro Adligata Lorica Minoris Equitis"
  ],
  "ulfric doesn't need to give up any more territory": [
    "Non cedendum est plus terrae Vercingetorigi"
  ],
  "any tips on who might accept these": [
    "Habesne quibus admonitis de quo has accipiat"
  ],
  "letter from father": [
    "Epistula a Patre"
  ],
  "orcish shield of fire abatement": [
    "Orci Scutum Resistendi Ignis"
  ],
  "femaleeyeswoodelfdeepbrown": [
    "FemaleEyesWoodElfDeepBrown"
  ],
  "stoneflesh": [
    "Stoneflesh"
  ],
  "the blades are helping me": [
    "Enses me adiuvant"
  ],
  "blades are helping me": [
    "Enses me adiuvant"
  ],
  "i'm not their puppet": [
    "Pupulus eorum non sum"
  ],
  "db recurringtarget 10 crimefaction": [
    "DB RecurringTarget 10 CrimeFaction"
  ],
  "all scrolls": [
    "All Scrolls"
  ],
  "soul gem": [
    "Gemmanima"
  ],
  "i'm sure alduin would be glad if everyone had that attitude": [
    "Certus sum Alduinum gavisurum esse si omnes hoc modo sentiat"
  ],
  "broken steel warhammer handle": [
    "Fractus Bellimalleus Adamanteus Cherolaba"
  ],
  "who is paarthurnax": [
    "Quis est Paarthurnax"
  ],
  "frost salts": [
    "Sal Glaciei"
  ],
  "betrayal or not, i'll let him live": [
    "Proditor vel non, ei vivere sinam"
  ],
  "daedric battleaxe of despair": [
    "Bellisecuris Daedricus Desponsionis"
  ],
  "you have opened the door to darkness, little man": [
    "Portam ad obscuritatem aperuisti, homuncule"
  ],
  "i have the firebrand wine": [
    "Vinum Ignifacis tibi habeo"
  ],
  "steel nordic gauntlets": [
    "Adamantea Germanicae Manicae"
  ],
  "why the feud with clan gray-mane": [
    "Qua de causa inimicitiam cum Gente Cana-Juba habes"
  ],
  "iron sword of draining": [
    "Gladius Ferri Hauriendi"
  ],
  "hide bracers of major archery": [
    "Chirothecae Cutis Maioris Sagittationis"
  ],
  "vilkas said you can still be cured": [
    "Vilkas dixit te iam curari posse"
  ],
  "you have a point": [
    "recte dicis"
  ],
  "saving content": [
    "Saving content"
  ],
  "please don't turn off your console": [
    "Please don't turn off your console"
  ],
  "any help with bersi": [
    "Visne auxilium cum Bersi"
  ],
  "orcish dagger of animus": [
    "Orci Pugio Animi"
  ],
  "what's the next step": [
    "Quid est gradus proximus"
  ],
  "dwarven dagger of absorption": [
    "Dvemeris Pugio Absorbendi"
  ],
  "iron boots of resist shock": [
    "Ferri Caligae Contra Vim Electricam"
  ],
  "how did gallus die": [
    "Quomodo mortuus est Gallus"
  ],
  "altar of dibella": [
    "Ara Dibellae"
  ],
  "i may have a potion to spare": [
    "Fortasse potionem superfluam habeo"
  ],
  "i may know a spell that would work": [
    "Fortasse magicam scio quae adivuare potest"
  ],
  "sky haven temple": [
    "Caelirefugium Templum"
  ],
  "roggi won't be complaining anymore": [
    "Roggi non plus queretur"
  ],
  "soon, the emperor will die. murdered, by the gourmet": [
    "Mox, Imperator moriturus est. Necatus ab Apicio"
  ],
  "imperial bracers of lockpicking": [
    "Chirothecae Romanae Clavicarii"
  ],
  "potion of brief invisibility": [
    "Potio Brevis Invisibilitatis"
  ],
  "goldenglow estate second floor": [
    "Aurilumenis Villae Secundum Tabulatum"
  ],
  "war of the first council": [
    "Bellum Primi Consilii"
  ],
  "iron boots of waning shock": [
    "Ferri Caligae Insenescentis Electricitatis"
  ],
  "the treasury house": [
    "Gazophylachium"
  ],
  "treasury house": [
    "Gazophylachium",
    "Treasury House"
  ],
  "steel boots of dwindling frost": [
    "Caligae Adamanteae Minoris Congelationis"
  ],
  "glass mace of despair": [
    "Maca Vitrea Desponsionis"
  ],
  "what's your opinion of markarth": [
    "Quid de Markartia censes"
  ],
  "steel battleaxe of shocks": [
    "Bellisecuris Adamanteus Electricitatis"
  ],
  "frost atronach faction": [
    "Frost Atronach Faction"
  ],
  "potion": [
    "Potio",
    "venēnum, venēnī"
  ],
  "why should i hand you anything": [
    "Quamobrem tibi quidquid tradere debeo"
  ],
  "so you're in charge of anga's mill": [
    "Ergo Mollem Angae administras"
  ],
  "x damage": [
    "X vulnus"
  ],
  "elven boots": [
    "Caligae Alfae"
  ],
  "dwarven greatsword of harvesting": [
    "Dvemeris Spatha Saturni"
  ],
  "thjollod": [
    "Thjollod"
  ],
  "report to <alias=imperialfieldco>": [
    "Refer te ad <Alias=ImperialFieldCO>"
  ],
  "dragonplate boots of brawn": [
    "Draconilaminata Caligae Fortitudinis"
  ],
  "fort commander": [
    "Legatus Castelli"
  ],
  "what's this problem you have": [
    "Quid est haec difficultas"
  ],
  "hunting bow of draining": [
    "Arcus Venandi Hauriendi"
  ],
  "the gourmet": [
    "Apicius"
  ],
  "gourmet": [
    "Apicius"
  ],
  "where is he": [
    "Ubi",
    "Ubi est"
  ],
  "oh, i'm waiting with bated breath": [
    "Oh, exspecto spiritu retento"
  ],
  "holy water": [
    "Aqua Sancta"
  ],
  "how can we make them pay": [
    "Quomodo eis poenas dare cogere possumus"
  ],
  "note to the authorities": [
    "Epistula ad Auctoritates"
  ],
  "iron shield": [
    "Ferri Scutum"
  ],
  "how about winterhold": [
    "Quid de Hiemiterra"
  ],
  "reparations": [
    "Reparationes"
  ],
  "staff of hag's wrath": [
    "Rudis Hag's Vrath"
  ],
  "maybe we should recruit them": [
    "Fortasse eos in Sodalitatem conducere debemus"
  ],
  "daedric armor": [
    "Daedrica Lorica"
  ],
  "what's gulum-ei's story": [
    "Quid est fabula Gulum-Ei"
  ],
  "why do you think ulfric killed the high king": [
    "Cur censes Vercingetorix regem altum necavisse"
  ],
  "tell me what happened": [
    "Dic mihi quid accidisse"
  ],
  "so, you only take nords": [
    "Ergo, solum Germanos accipis"
  ],
  "1 = imperial, 2 = stormcloak supporter": [
    "1 = Imperialis, 2 = Stormcloak supporter"
  ],
  "i've been sent to resolve a dispute": [
    "Missus sum ut disputationem reficerem"
  ],
  "dialoguesalviusfarmoutsidescene02": [
    "DialogueSalviusFarmOutsideScene02"
  ],
  "when the dragon died, i absorbed some kind of power from it": [
    "Dracone defuncto, Vim quendam ab eo absorbui"
  ],
  "allowed faction for execution guard package": [
    "Allowed Faction for Execution Guard Package"
  ],
  "okin": [
    "Okin"
  ],
  "halldir's staff": [
    "Rudis Halldiris"
  ],
  "key to beitild's house": [
    "Clavis ad Domum Beitildis"
  ],
  "mystery of talara, v 1": [
    "Misterium Talarae, Pars I"
  ],
  "daedric sword of immobilizing": [
    "Gladius Daedricus Immobilitatis"
  ],
  "dialogue faction for riften guards": [
    "Dialogue Faction for Hiati Guards"
  ],
  "wisp's health blessing": [
    "Wisp's Health Blessing"
  ],
  "i need more details about goldenglow": [
    "Volo plus minutiarum de Auriluce"
  ],
  "orcish greatsword of frost": [
    "Orci Spatha Gelidus"
  ],
  "faldrus": [
    "Faldrus"
  ],
  "now, now, gianna": [
    "Age nunc, Gianna"
  ],
  "who's the gourmet here": [
    "Quis hic est Apicius"
  ],
  "broken steel battle axe handle": [
    "Fractus Bellisecuris Adamanteus Cherolaba"
  ],
  "the pale blade": [
    "Ensis Pallidus"
  ],
  "pale blade": [
    "Ensis Pallidus"
  ],
  "xander shopping bits and pieces": [
    "Xander shopping Bits et Pieces"
  ],
  "ebony boots": [
    "Caligae Ebeni"
  ],
  "how could an elder scroll cast alduin through time": [
    "Quomodo Codex Veterrimus Alduinum per tempus mittere potuit"
  ],
  "sanguine rose": [
    "Rosa Sanguinis"
  ],
  "<alias.shortname=questgiver> gave me this ring": [
    "<Alias.ShortName=QuestGiver> mihi hunc anulum dedit"
  ],
  "i think it's yours": [
    "Credidi tuum esse"
  ],
  "what have i done wrong": [
    "Quid prave feci"
  ],
  "have i done something wrong": [
    "Fecine quid pravum"
  ],
  "imperial light shield of dwindling frost": [
    "Romana Parma Minoris Congelationis"
  ],
  "faendal asked me to give you this letter, and say it was from sven": [
    "Faendal mihi oravit ut te hanc epistulam adferrem et eam de Sven esse dicere"
  ],
  "elven greatsword of harvesting": [
    "Spatha Alfa Saturni"
  ],
  "spriggan prey faction": [
    "Spriggan Prey Faction"
  ],
  "db02captive2persuade": [
    "DB02Captive2Persuade"
  ],
  "where do i get all that gold": [
    "Ubi tantum aurei inveniam"
  ],
  "esbern's vault": [
    "Esbernis Hypogeum"
  ],
  "your name carries weight in riften": [
    "Nomen tuum magni momenti Hiati est"
  ],
  "thonnir": [
    "Thonnir"
  ],
  "a tragedy in black": [
    "Tragoedia Pulla"
  ],
  "tragedy in black": [
    "Tragoedia Pulla"
  ],
  "i'll get it": [
    "Id referam"
  ],
  "i'm prepared to make reparations": [
    "Paratus sum te remunerare"
  ],
  "conjure potent flame atronach": [
    "Voca Atronachum Potens Flammarum"
  ],
  "dwarven war axe of frost": [
    "Dvemeris Bellisecuris Gelidus"
  ],
  "daedric dagger of immobilizing": [
    "Pugio Daedricus Immobilitatis"
  ],
  "ebony helmet of eminent archery": [
    "Ebeni Galea Eminentis Saggitationis"
  ],
  "are there any rules i should be aware of": [
    "Suntne regula scienda"
  ],
  "fortify persuasion": [
    "Auget Persuasionem"
  ],
  "virtuous ebony bow": [
    "Virtutis Arcus Ebeni"
  ],
  "i'm just trying to help": [
    "Modo adiuvare conor"
  ],
  "dwarven war axe of absorption": [
    "Dvemeris Bellisecuris Absorbendi"
  ],
  "i've spoken to the witnesses": [
    "Adlocutus sum testibus"
  ],
  "sealing the deal": [
    "Transactionem Adfirmans"
  ],
  "dwarven boots of waning fire": [
    "Dvemeres Caligae Insenescentis Ignis"
  ],
  "what will happen to elisif now that the empire has won the war": [
    "Quid Elisifi nunc accidet quod Imperium bellum vicit"
  ],
  "why did ulfric let elisif live": [
    "Cur Vercingetorix Elisifi vivere sivit"
  ],
  "does general tullius respect elisif": [
    "Honoratne Imperator Tullius Elisifem"
  ],
  "key to old hroldan inn": [
    "Clavis ad Diversorium Hroldanis Veteris"
  ],
  "key to hjerim": [
    "Clavis ad Hjerim"
  ],
  "do all the thanes support elisif": [
    "Faventne Elisifi omnes Decemviri"
  ],
  "sanctified dwarven sword": [
    "Sanctificatus Gladius Dvemeris"
  ],
  "why did ulfric kill your husband": [
    "Cur maritum tuum Vercingetorix necavit"
  ],
  "why shouldn't they trust you": [
    "Quidni tibi confident"
  ],
  "silver and moonstone circlet": [
    "Argentei et Lunalapidis Circulum"
  ],
  "tell me about the unmelting snow": [
    "Dic mihi de Nivi Semperterna"
  ],
  "elven dagger of garnering": [
    "Pugio Alfus Horti"
  ],
  "notes on yngol barrow": [
    "Adnotationes de Pabillo Yngolis"
  ],
  "orcish dagger of thunderbolts": [
    "Orci Pugio Fulminum"
  ],
  "savage blows": [
    "Ictus Saevi"
  ],
  "the pig children": [
    "Pueri Porcini"
  ],
  "pig children": [
    "Pueri Porcini"
  ],
  "orcish mace of depleting": [
    "Orci Maca Deplendi"
  ],
  "scroll of lightning rune": [
    "Codex Runi Fulminis"
  ],
  "shaircolor10": [
    "sHairColor10"
  ],
  "hairlinefemalenord08": [
    "HairLineFemaleNord08"
  ],
  "valdar": [
    "Valdar"
  ],
  "shel": [
    "Shel"
  ],
  "ebony warhammer of stunning": [
    "Bellimalleus Ebeni Intermissionis"
  ],
  "falmer shadowmaster": [
    "Falmeris Ubramagister"
  ],
  "may i enter the college": [
    "Possumne Collegium intrare"
  ],
  "orcish mace of consuming": [
    "Orci Maca Consumendi"
  ],
  "i want to use the power of ice and fire to destroy any who oppose me": [
    "Volo viribus gelidus ignisque uti ut omnes inimicos necem"
  ],
  "noster's helmet": [
    "Nosteris Galea"
  ],
  "i was doing kodlak's bidding": [
    "Voluntatem Kodlakis feci"
  ],
  "wisp's stamina blessing": [
    "Wisp's Stamina Blessing"
  ],
  "ring of namira": [
    "Anulus Namirae"
  ],
  "i desire to bend the will of those around me": [
    "Volo voluntati propinquorum imperare"
  ],
  "i found reyda's necklace": [
    "Monile Reydae inveni"
  ],
  "i'd like a waraxe": [
    "Bellisecurem volo"
  ],
  "are you selling any more black-briar mead": [
    "Vendisne plus Hydromelli Atri-Dumi"
  ],
  "key to bryling's house": [
    "Clavis ad Domum Brylingis"
  ],
  "sailor's repose": [
    "Quietum Nautae"
  ],
  "i can't help with that right now": [
    "Cum illo nunc adiuvare non possum"
  ],
  "reverent elven mace": [
    "Reverens Maca Alfa"
  ],
  "sundas": [
    "Die Solis"
  ],
  "hairlinemaleorc12": [
    "HairLineMaleOrc12"
  ],
  "elgrim's elixir's scene 01": [
    "Elgrim's Elixir's Scene 01"
  ],
  "hunting bow of arcing": [
    "Arcus Venandi Electricitatis"
  ],
  "thieves guild no pickpocketing faction": [
    "Thieves Guild No Pickpocketing Faction"
  ],
  "daedric battleaxe of fire": [
    "Bellisecuris Daedricus Ignis"
  ],
  "steel battleaxe of diminishing": [
    "Bellisecuris Adamanteus Diminuendi"
  ],
  "kill logrolf": [
    "Neca Logrolf"
  ],
  "dwarven sword of torpor": [
    "Gladius Dvemeris Torporis"
  ],
  "daedric war axe of banishing": [
    "Bellisecuris Daedricus Expellendi"
  ],
  "elven greatsword of depleting": [
    "Spatha Alfa Deplendi"
  ],
  "spell tome: heal other": [
    "Liber Magicus: Serva Alium"
  ],
  "is it difficult being so far away from the rest of your family": [
    "Estne difficilis tibi tam procul a familia esse"
  ],
  "dwarven dagger of soul snares": [
    "Dvemeris Pugio Animicapti"
  ],
  "blood seal": [
    "Signum Sanguinis"
  ],
  "marksmaleargonianscars02": [
    "MarksMaleArgonianScars02"
  ],
  "key to faendal's house": [
    "Clavis ad Domum Faendalis"
  ],
  "jervar": [
    "Jervar"
  ],
  "you'll help me escape then": [
    "Ergo mihi effugere adiuvabis"
  ],
  "dragon bridge garrison": [
    "Dragon Bridge Garrison"
  ],
  "okay, so what's your offer": [
    "Ok, quid offers"
  ],
  "spend soul": [
    "Consume Animum"
  ],
  "you leave carlotta alone, or this gets worse": [
    "Pacre Carlottae, vel hoc peius fiet"
  ],
  "spell tome: lightning rune": [
    "Liber Magicus: Runus Electricitatis"
  ],
  "where is this underforge": [
    "Ubi est haec Subfabrica"
  ],
  "shock spit": [
    "Shock Spit"
  ],
  "glass shield of fire suppression": [
    "Vitreum Scutum Suppressionis Ignis"
  ],
  "dwarven shield of major blocking": [
    "Dvemere Scutum Maioris Interponendi"
  ],
  "maleeyeshighelfyellow": [
    "MaleEyesHighElfYellow"
  ],
  "%s discovered from %s": [
    "%s inventus ab %s"
  ],
  "a dance in fire, v2": [
    "Saltatio in Flammis, II"
  ],
  "dance in fire, v2": [
    "Saltatio in Flammis, II"
  ],
  "what just happened": [
    "Quid accidit"
  ],
  "am i a werewolf now": [
    "Sumne versipellis nunc"
  ],
  "dwarven warhammer": [
    "Dvemeris Bellimalleus"
  ],
  "hairlinemaleelder4": [
    "HairLineMaleElder4"
  ],
  "(hand over 200 gold)": [
    "(Trade CC Auri)"
  ],
  "ebony dagger of lethargy": [
    "Pugio Ebeni Lethargiae"
  ],
  "joining the stormcloaks": [
    "Conveniens Cum Sturmmantelos"
  ],
  "mistveil keep barracks": [
    "Velamenexus Castellum Tabernacula"
  ],
  "ebony greatsword of petrifying": [
    "Spatha Ebeni Lapidescendi"
  ],
  "you're not from markarth, i take it": [
    "Num Markartiae natus es"
  ],
  "dustman's crypt": [
    "Hypogaeum Pulvivirorum"
  ],
  "orcish gauntlets of major wielding": [
    "Orci Manicae Maioris Tenendi"
  ],
  "what do you think about the war": [
    "Quid de hoc bello censes"
  ],
  "i think angela needs to know": [
    "Credo Angelam scire debere"
  ],
  "maleeyeshumanlightblue": [
    "MaleEyesHumanLightBlue"
  ],
  "how does 50 gold sound": [
    "Quid de 50 auri censes"
  ],
  "dialoguedawnstarwhitehallskaldjod02": [
    "DialogueDawnstarWhiteHallSkaldJod02"
  ],
  "i don't have that much gold": [
    "Tantum aurei non habeo"
  ],
  "pip-boy color": [
    "Pip-Boy Color"
  ],
  "shock hazard": [
    "Shock Hazard"
  ],
  "what's the best way to get there": [
    "Quae est via optima"
  ],
  "i came here to fight for the empire": [
    "Hic veni ut pro Imperio pugnare possem"
  ],
  "bthardamz dwelling": [
    "Bthardamz Domus"
  ],
  "i'll see that your letter reaches aldis": [
    "Videbo ut epistulam tuam ad Aldem adveniat"
  ],
  "i'm not getting involved": [
    "Pars huius non ero"
  ],
  "why would anyone accuse you of that": [
    "Cur quisquis te de illo accusare velit"
  ],
  "don't you have trouble getting ripe goods here": [
    "Nonne difficile est tibi bona recentia hic accipere"
  ],
  "glass bow of malediction": [
    "Arcus Vitreus Maledictionis"
  ],
  "why would i disrespect her": [
    "Cur irreverentia ei faciam"
  ],
  "ebony war axe of the inferno": [
    "Bellisecuris Ebeni Conflagrationis"
  ],
  "femalebrowshuman07": [
    "FemaleBrowsHuman07"
  ],
  "fellhammer mine key": [
    "Clavis ad Metallum Fellimallei"
  ],
  "daedric armor of the eminent knight": [
    "Daedrica Lorica Eminentis Equitis"
  ],
  "elisif's tribute": [
    "Anathema Elisifis"
  ],
  "maleeyesorcredblindright": [
    "MaleEyesOrcRedBlindRight"
  ],
  "imperial light shield of major blocking": [
    "Romana Parma Maioris Interponendi"
  ],
  "i found your journals and amulet where the butcher worked": [
    "Ephemerides tuos et monile ubi Carnifex laboravit inveni"
  ],
  "dwarven gauntlets": [
    "Dvemeris Manicae"
  ],
  "what do you want with me": [
    "Quid mecum vis"
  ],
  "i've finished that special whiterun job": [
    "Munus speciale Albacursus complevi"
  ],
  "who are the greybeards": [
    "Qui sunt Canabarbae"
  ],
  "ms01buailenepostrigger": [
    "MS01BUaileNeposTrigger"
  ],
  "our mutual friend sent me": [
    "Amicus mutualis me misit"
  ],
  "i was told to find you": [
    "Audivi opus mihi fuisse te invenire"
  ],
  "warpaintblue02": [
    "WarpaintBlue02"
  ],
  "miner's journal": [
    "Ephemeris Cunicularii"
  ],
  "rank = which mission is in \"slot\" 2": [
    "Rank = which mission is in \"slot\" 2"
  ],
  "alding": [
    "Alding"
  ],
  "circle of protection hazard": [
    "Circle of Protection Hazard"
  ],
  "glass war axe of winnowing": [
    "Bellisecuris Vitreus Ventagii"
  ],
  "your daughter is the sybil of dibella": [
    "Filia tua est Sybilla Dibellae"
  ],
  "dwarven boots of brawn": [
    "Dvemeres Caligae Fortitudinis"
  ],
  "does your company do business with the thalmor": [
    "Grex tua negotium cum Visigothis agit"
  ],
  "darkelfmalelips03": [
    "DarkElfMaleLips03"
  ],
  "silent ruin": [
    "Ruina Silens"
  ],
  "brill": [
    "Brill"
  ],
  "the east empire company is sending up your spices": [
    "Negotium Euri Imperii aromata tua mittit"
  ],
  "east empire company is sending up your spices": [
    "Negotium Euri Imperii aromata tua mittit"
  ],
  "it's an altar": [
    "Ara est"
  ],
  "you're the man with the wagon": [
    "Homo cum plaustro es"
  ],
  "transporting his mother": [
    "Matrem vehens"
  ],
  "scroll of expel daedra": [
    "Codex Daedram Expellendi"
  ],
  "aia arria": [
    "Aia Arria"
  ],
  "battle for <alias=city>": [
    "Obsidium <Alias=City>"
  ],
  "hairmaleredguard6": [
    "HairMaleRedguard6"
  ],
  "rogatus salvius": [
    "Rogatus Salvius"
  ],
  "marksfemalehumanoid10leftgash": [
    "MarksFemaleHumanoid10LeftGash"
  ],
  "holder": [
    "Captor"
  ],
  "i won't tell anyone": [
    "Nemo umquam dicam"
  ],
  "orcish gauntlets": [
    "Orci Manicae"
  ],
  "i'm a proud supporter of ulfric stormcloak": [
    "Fautor sum Vercingetorigis Temepstatamiculi"
  ],
  "daedric helmet of extreme restoration": [
    "Galea Daedrica Extremis Reficiendi"
  ],
  "why are you still out here": [
    "Cur iam hic es"
  ],
  "ebony warhammer of nullifying": [
    "Bellimalleus Ebeni Nullificandi"
  ],
  "i want to quit that numbers job": [
    "Nolo facere illum munus \"numbers\""
  ],
  "ebony war axe of debilitation": [
    "Bellisecuris Ebeni Debilitationis"
  ],
  "giant frostbite spider": [
    "Ingens Adustus Nivibus Aranea"
  ],
  "dbastridsanctuarygreettopic": [
    "DBAstridSanctuaryGreetTopic"
  ],
  "i must have missed it": [
    "Fortasse id non vidi"
  ],
  "winterhold guard's helmet": [
    "Hiemiterrae Custodis Galea"
  ],
  "not really": [
    "Minime"
  ],
  "ebony mace of depleting": [
    "Maca Ebeni Deplendi"
  ],
  "pts": [
    "pt"
  ],
  "olava's token": [
    "Olavae Signum"
  ],
  "torbjorn shatter-shield": [
    "Torbjorn Scuti-Fracti"
  ],
  "dwarven sword of consuming": [
    "Gladius Dvemeris Consumendi"
  ],
  "what are the duties of a keeper": [
    "Quae sunt munera Conservatoris"
  ],
  "the dark brotherhood has come, muiri": [
    "Fraternitas Obscura venit, Muiri"
  ],
  "dark brotherhood has come, muiri": [
    "Fraternitas Obscura venit, Muiri"
  ],
  "flawless ruby": [
    "Perfectus Rubinus"
  ],
  "hairlinefemaleorc09": [
    "HairLineFemaleOrc09"
  ],
  "glass helmet of eminent destruction": [
    "Galea Vitrea Eminentis Destructionis"
  ],
  "blacksmith's philter": [
    "Ferrarii Philtrum"
  ],
  "imperial bracers of major archery": [
    "Chirothecae Romanae Maioris Sagittationis"
  ],
  "so this is the bards college": [
    "Ergo hoc est Collegium Bardorum"
  ],
  "gerdur": [
    "Gerdur"
  ],
  "where did you say i can find you": [
    "Ubi dixisti me te invenire posse"
  ],
  "let's start with revenge": [
    "Cum ultione incipiamus"
  ],
  "okay, i'll find some": [
    "Ok, nonnullas inveniam"
  ],
  "they'll understand": [
    "Intellegent"
  ],
  "imperial light helmet of minor magicka": [
    "Romana Cassis Minoris Magicae"
  ],
  "hairmaleorc13": [
    "HairMaleOrc13"
  ],
  "you were this close to being food": [
    "Fere cibus fuisti"
  ],
  "elven mace of lightning": [
    "Maca Alfa Fulminis"
  ],
  "i've defeated the guardians": [
    "Custodes vici"
  ],
  "i'm ready to pay": [
    "Paratus sum pecuniam solvere"
  ],
  "scaled helmet of eminent alteration": [
    "Galea Squamata Eminentis Mutationis"
  ],
  "mercer's plans": [
    "Merceris Agenda"
  ],
  "reduced stamina": [
    "Stamina Reducta"
  ],
  "you know... what": [
    "Scis... quid"
  ],
  "daynas valen": [
    "Daynas Valen"
  ],
  "i'm not for hire right now": [
    "Non nunc conduci possum"
  ],
  "talk to grisvar about getting a shiv": [
    "Adloquere Grisvarem de astulam accipiendo"
  ],
  "vaermina": [
    "Morpheus"
  ],
  "dialoguemarkarthgenericscene01": [
    "DialogueMarkarthGenericScene01"
  ],
  "what's next for the accomplished legate rikke": [
    "Quid proximum pro noto Legato Rikke"
  ],
  "imperial light shield of waning fire": [
    "Romana Parma Insenescentis Ignis"
  ],
  "maleeyesorcblind": [
    "MaleEyesOrcBlind"
  ],
  "i was just seeing if you came to your senses": [
    "Volui scire num te rationem videas"
  ],
  "gloomreach hive": [
    "Prehensi Obscuri Cubile"
  ],
  "orcish helmet of eminent magicka": [
    "Orci Galea Eminentis Magicae"
  ],
  "lightning rune": [
    "Lightning Rune"
  ],
  "resolution": [
    "Resolutio"
  ],
  "i have a chunk of gold ore for you": [
    "Minerale auri tibi habeo"
  ],
  "commander maro's crime faction": [
    "Commander Maro's Crime Faction"
  ],
  "orcish sword of animus": [
    "Orci Gladius Animi"
  ],
  "target level": [
    "Gradus"
  ],
  "i'd like to clear up the matter of my suspension": [
    "Volo suspensum meam reficere"
  ],
  "hide helmet of major archery": [
    "Cutis Galea Maioris Sagittationis"
  ],
  "humanbeardlong12_1bit": [
    "HumanBeardLong12_1bit"
  ],
  "ebony gauntlets of extreme smithing": [
    "Ebeni Manicae Extremis Ferrarii"
  ],
  "spell tome: spectral arrow": [
    "Liber Magicus: Spectral Arrov"
  ],
  "telekinesis": [
    "Telekinesis"
  ],
  "wait in the courtyard": [
    "In peristylo exspecta"
  ],
  "that spider is as good as dead": [
    "Illa aranea sicut mortua iam est"
  ],
  "can you make me blood-kin": [
    "Potesne me Familiarem-Sanguinis facere"
  ],
  "looking to join imperials": [
    "Looking to join Romani"
  ],
  "hand over the claw first": [
    "Primo, da mihi unguinem"
  ],
  "hakon one-eye": [
    "Hakon Monophthalmos"
  ],
  "femaleeyeshumanamber": [
    "FemaleEyesHumanAmber"
  ],
  "brynjolf said you had something for me": [
    "Brynjolf dixit te aliquid pro me habuisse"
  ],
  "blizzardbodyartholder": [
    "BlizzardBodyArtHolder"
  ],
  "ebony battleaxe of fire": [
    "Bellisecuris Ebeni Ignis"
  ],
  "atub": [
    "Atub"
  ],
  "of course, astrid": [
    "Certe, Astrid"
  ],
  "lead on then": [
    "Sic, duc"
  ],
  "i don't need a guide": [
    "Ducem non requiro"
  ],
  "i have your journal": [
    "Ephemeridem tuam habeo"
  ],
  "dragonskin": [
    "Dragonipellis"
  ],
  "i don't know anything": [
    "Nihil scio"
  ],
  "warmaidens": [
    "Warmaidens"
  ],
  "krosis": [
    "Krosis"
  ],
  "where am i": [
    "Ubi sum"
  ],
  "fellglow keep dungeons": [
    "Obscuriluminis Castelli Carcer"
  ],
  "hide boots of waning shock": [
    "Renonis Caligae Insenescentis Electricitatis"
  ],
  "daedric greatsword of annihilating": [
    "Spatha Daedrica Annihilationis"
  ],
  "snow spider abilities": [
    "Snow Spider abilities"
  ],
  "testjeffltankard": [
    "TestJeffLPoculum Metalli"
  ],
  "reverent glass greatsword": [
    "Reverens Spatha Vitrea"
  ],
  "the dragon is dead": [
    "Draco mortuus est"
  ],
  "dragon is dead": [
    "Draco mortuus est"
  ],
  "dragonfire02": [
    "DragonFire02"
  ],
  "send a thief to steal back what was stolen": [
    "Mitte furem ut res furtivas referrat"
  ],
  "maybe later": [
    "Fortasse postea"
  ],
  "femaleeyeshighelforange": [
    "FemaleEyesHighElfOrange"
  ],
  "this was a bad idea": [
    "Consilium improbum fuit"
  ],
  "driftshade cellar": [
    "Vibrumbrae Cellarium"
  ],
  "i'm done now": [
    "Iam feci"
  ],
  "one more": [
    "Unam plurimam"
  ],
  "no problemsh": [
    "Nulla difficultas est"
  ],
  "farmanimalsfaction": [
    "FarmAnimalsFaction"
  ],
  "fultheim the fearless": [
    "Fultheim Impavidus"
  ],
  "talk to jarl balgruuf": [
    "Adloquere Comitem Balgruuf"
  ],
  "dengeir's house": [
    "Dengeiris Domus"
  ],
  "daedric bow of lethargy": [
    "Arcus Daedricus Lethargiae"
  ],
  "glass boots of the ox": [
    "Vitreae Caligae Bovis"
  ],
  "what are your reasons for joining the war": [
    "Qua de causa bellum adiuncisti"
  ],
  "can you teach me the dragonrend shout": [
    "Potesne me docere clamationem Draconicaesem"
  ],
  "sacred witness": [
    "Testis Sacer"
  ],
  "torolf": [
    "Torolf"
  ],
  "elven bow of fear": [
    "Arcus Alfus Timoris"
  ],
  "angrenor once-honored": [
    "Angrenor Semel-Cornutus"
  ],
  "ganna uriel": [
    "Ganna Uriel"
  ],
  "maleeyeshumanleftblindsingle": [
    "MaleEyesHumanLeftBlindSingle"
  ],
  "summon arniel's shade": [
    "Imaginem Arnielis Voca"
  ],
  "dialoguemarkarthstablesbanningcedranscene01": [
    "DialogueMarkarthStablesBanningCedranScene01"
  ],
  "package for verner": [
    "Fascis Verneri"
  ],
  "you want your staff back": [
    "Rudem recipere vis"
  ],
  "imperial bow of scorching": [
    "Imperialis Arcus Adurendi"
  ],
  "remind me, why do they call you dirge": [
    "Causas mihi memora, cur \"Aelinos\" vocaris"
  ],
  "dwarven warhammer of gleaning": [
    "Dvemeris Bellimalleus Saturni"
  ],
  "hagraven feathers": [
    "Pinnae Corvisagae"
  ],
  "steel shield of major blocking": [
    "Adamanteum Scutum Maioris Interponendi"
  ],
  "why do they call you dirge": [
    "Cur illi te \"Alinos\" vocant"
  ],
  "(give 100 gold)": [
    "(Da C auri)"
  ],
  "is there any way to earn a bonus": [
    "Estne via ad praemium maius"
  ],
  "hairfemaleorc09": [
    "HairFemaleOrc09"
  ],
  "poacher": [
    "Venator Contra Leges"
  ],
  "how do we get to sky haven temple": [
    "Quomodo ad Templum Refugiti Caeli adveniemus"
  ],
  "the dainty sload": [
    "Sload Cupes"
  ],
  "i'll meet you at karthspire": [
    "Tecum conveniam apud Karthspiram"
  ],
  "odahviing": [
    "Odahviing"
  ],
  "ebony war axe of depleting": [
    "Bellisecuris Ebeni Deplendi"
  ],
  "dunrannveigqstbleedout": [
    "dunRannveigQSTBLEEDOUT"
  ],
  "odfel's and grogmar's house": [
    "Odfel's and Grogmar's House"
  ],
  "mass charm": [
    "Mass Charm"
  ],
  "imperial bracers of minor lockpicking": [
    "Chirothecae Romanae Minoris Clavicarii"
  ],
  "adara": [
    "Adara"
  ],
  "maybe so, but i plan to go down fighting": [
    "Fortasse, sed non sine pugnare"
  ],
  "on second thought, maybe i'll wait a bit longer to decide": [
    "Ut iterum cogito, fortasse paulum plus exspectare debeo"
  ],
  "virtuous glass battleaxe": [
    "Virtutis Bellisecuris Vitreus"
  ],
  "ebony bow of lightning": [
    "Arcus Ebeni Fulminis"
  ],
  "daedric gauntlets of extreme smithing": [
    "Daedricae Manicae Extremis Ferrarii"
  ],
  "daedric armor of eminent health": [
    "Daedrica Lorica Eminentis Salutis"
  ],
  "who's in the tomb": [
    "Quis in sepulcro est"
  ],
  "riverwood svens house faction": [
    "Riverwood Svens House Faction"
  ],
  "those sellswords won't be a problem now": [
    "Isti mercenarii te non iam molestabunt"
  ],
  "studded armor of minor health": [
    "Ornata Lorica Minoris Salutis"
  ],
  "i can do this myself": [
    "Hoc facer possum pro me"
  ],
  "what was that": [
    "Quid fuit illud"
  ],
  "amulet of kynareth": [
    "Monile Kynarethis"
  ],
  "night falls on sentinel": [
    "Nox in Sentinel Cadit"
  ],
  "broken staff": [
    "Rudis Fractus"
  ],
  "dwarven boots of waning frost": [
    "Dvemeres Caligae Insenescentis Congelationis"
  ],
  "spriggan predator faction": [
    "Spriggan Predator Faction"
  ],
  "i have moonstone ore to sell": [
    "Lunalapidem vendendum habeo"
  ],
  "creaturedialoguegoat": [
    "CreatureDialogueGoat"
  ],
  "femaleeyeswoodelfdeepviolet": [
    "FemaleEyesWoodElfDeepViolet"
  ],
  "i have ebony ore to sell": [
    "Minerale ebeni vendendum habeo"
  ],
  "steel plate helmet of eminent destruction": [
    "Laminata Galea Eminentis Destructionis"
  ],
  "i have ore to sell": [
    "Minerale vendendum habeo"
  ],
  "wispmother": [
    "Floccimater",
    "Nebulae Mater"
  ],
  "glass sword of the blaze": [
    "Gladius Vitreus Congflagrantis"
  ],
  "daedric dagger of dread": [
    "Pugio Daedricus Timoris"
  ],
  "talk to rexus": [
    "Adloquere Rexum"
  ],
  "what kind of writing do you do": [
    "Quale scribis"
  ],
  "of course, my lord": [
    "Certe, Domine"
  ],
  "so you're the augur of dunlain": [
    "Ergo tu es Augur Dunlanis"
  ],
  "ms13arvelshout03trigger": [
    "MS13ArvelShout03Trigger"
  ],
  "on second thought, perhaps i'll wait a bit longer to decide": [
    "Ut iterum cogito, fortasse paulum plus exspectare debeo"
  ],
  "erik": [
    "Erik"
  ],
  "what's our next move": [
    "Quid proximum faciemus"
  ],
  "the song of pelinal, v2": [
    "Carmen Pelinalis, II"
  ],
  "song of pelinal, v2": [
    "Carmen Pelinalis, II"
  ],
  "scaled helmet of eminent magicka": [
    "Galea Squamata Eminentis Magicae"
  ],
  "problem": [
    "Difficultas"
  ],
  "no, i haven't": [
    "Minime"
  ],
  "orcish dagger of weariness": [
    "Orci Pugio Maioris Defatigationis"
  ],
  "maleeyeshumangrey": [
    "MaleEyesHumanGrey"
  ],
  "the old ways": [
    "Viae Antiquae"
  ],
  "old ways": [
    "Viae Antiquae"
  ],
  "orcish mace of ice": [
    "Orci Maca Gelidus"
  ],
  "glass greatsword of consuming": [
    "Spatha Vitrea Consumendi"
  ],
  "dwarven mace of harvesting": [
    "Dvemeris Maca Saturni"
  ],
  "you were at namira's feast, weren't you": [
    "Ad epulas Namirae fuisti, nonne"
  ],
  "apprentice alteration": [
    "Discipulus Mutationis"
  ],
  "dragonplate shield of fire suppression": [
    "Draconilaminatum Scutum Suppressionis Ignis"
  ],
  "why didn't you kill mercer": [
    "Quidni Mercer necares"
  ],
  "de rerum dirennis": [
    "De Rerum Dirennis"
  ],
  "arob": [
    "Arob"
  ],
  "orange dartwing": [
    "Papilio Arantius"
  ],
  "shrine of malacath": [
    "Ara Stygis"
  ],
  "flower basket": [
    "Canistrum Florum"
  ],
  "dwarven mace of animus": [
    "Dvemeris Maca Animi"
  ],
  "elven shield of frost suppression": [
    "Alfum Scutum Contra Glaciem"
  ],
  "quite the day, hmm": [
    "Dies optimus, nonne"
  ],
  "elven warhammer of enervating": [
    "Alfus Bellimaleus Enervationis"
  ],
  "daedric armor of the peerless knight": [
    "Daedrica Lorica Optimae Equitis"
  ],
  "daedric sword of dread": [
    "Gladius Daedricus Timoris"
  ],
  "morthal highmoon hall scene 7": [
    "Morthal Highmoon Hall Scene 7"
  ],
  "orthus endario": [
    "Orthus Endario"
  ],
  "crowd spectator": [
    "Crowd Spectator"
  ],
  "hide bracers of major lockpicking": [
    "Chirothecae Cutis Maioris Clavicarii"
  ],
  "windhelm guard's shield": [
    "Ventigalea Custodis Scutum"
  ],
  "imperial boots of resist fire": [
    "Caligae Romanae Contra Vim Ignis"
  ],
  "what's your problem with jaree-ra": [
    "Quid est difficultas tua cum Jaree-Ra"
  ],
  "solitude hall of the dead": [
    "Solitudinis Hall of the Dead"
  ],
  "amren": [
    "Amren"
  ],
  "dragonscale boots of frost suppression": [
    "Caligae Draconisquamatae Contra Glaciem"
  ],
  "steel shield of waning shock": [
    "Adamanteum Scutum Insenescentis Electricitatis"
  ],
  "guard barracks": [
    "Custodis Tabernacula"
  ],
  "glass warhammer of harvesting": [
    "Bellimaleus Vitreus Saturni"
  ],
  "elven bow of ice": [
    "Arcus Alfus Gelidus"
  ],
  "scaled helmet of eminent destruction": [
    "Galea Squamata Eminentis Destructionis"
  ],
  "swindler's voice": [
    "Swindler's Voice"
  ],
  "whispers in the dark": [
    "Sussurationes in Obscuritate"
  ],
  "leather boots of lifting": [
    "Corii Caligae Sumendi"
  ],
  "expert talisman": [
    "Expert Talisman"
  ],
  "tell me more about this treasure": [
    "Dic mihi plus de hac gaza"
  ],
  "ms08paralysis": [
    "MS08Paralysis"
  ],
  "glass battleaxe of harvesting": [
    "Bellisecuris Vitreus Saturni"
  ],
  "katla's farm conversation": [
    "Katla's Farm Conversation"
  ],
  "b4": [
    "B4"
  ],
  "it will be done, lord dagon": [
    "Facietur, Domine Chrone"
  ],
  "spell tome: frost thrall": [
    "Liber Magicus: Servus Glaciei"
  ],
  "silence, my brother": [
    "Silentium, mi frater"
  ],
  "orcish gauntlets of extreme smithing": [
    "Orci Manicae Extremis Ferrarii"
  ],
  "i need some things for the sanctuary": [
    "Nonnullas res pro Fano postulo"
  ],
  "gauldur blackbow": [
    "Galdris Atrarcus"
  ],
  "dwarven sword of freezing": [
    "Gladius Dvemeris Gelidus"
  ],
  "jala's house": [
    "Jalae Domus"
  ],
  "falkreath watchtower": [
    "Falkretiae Turris"
  ],
  "message to whiterun": [
    "Nuntium Albacurso"
  ],
  "imperial sword of arcing": [
    "Gladius Romanus Electricitatis"
  ],
  "refined moonstone": [
    "Lapidiluna Depurata"
  ],
  "defeat the enemy soldiers": [
    "Neca Milites Inimicorum"
  ],
  "torturer's assistant": [
    "Adiutor Tormentoris"
  ],
  "iron helmet of major conjuring": [
    "Ferri Galea Maioris Vocandi"
  ],
  "i'd like a greatsword": [
    "Spatham volo"
  ],
  "shhh... don't be afraid": [
    "Shhh... noli timere"
  ],
  "mystic": [
    "Mystic"
  ],
  "i'm in the imperial legion, and i'm damn sure you're not": [
    "In Legione Romano sum, et certus sum te non in eo esse"
  ],
  "enchanter's draught": [
    "Incantatoris Decocta"
  ],
  "dwarven mace of harrowing": [
    "Dvemeris Maca Occationis"
  ],
  "audiotemplatewisp": [
    "AudioTemplateWisp"
  ],
  "elven dagger of banishing": [
    "Pugio Alfus Expellendi"
  ],
  "hairmalenord18": [
    "HairMaleNord18"
  ],
  "vulwulf snow-shod": [
    "Vulvulf Nivi-Caligatus"
  ],
  "cicero's journal - volume 1": [
    "Ephemeris Ciceronis - Volumen I"
  ],
  "a sweetroll": [
    "Dulcifer"
  ],
  "sweetroll": [
    "Dulcifer"
  ],
  "lie to sibbi black-briar about lynly": [
    "Mentire ad Sibbi Atri-Dumum de Lynly"
  ],
  "hairfemaleorc04": [
    "HairFemaleOrc04"
  ],
  "pelagius the mad": [
    "Pelagius Insanus"
  ],
  "i'll need an elder scroll to get any further": [
    "Opus est mihi Codicem Veterrimum invenire ut procedam"
  ],
  "you might lose your job": [
    "Munus tuum fortasse amittas"
  ],
  "dwarven warhammer of frost": [
    "Dvemeris Bellimalleus Gelidus"
  ],
  "enchanting trainer": [
    "Enchanting Trainer"
  ],
  "lesser recovery": [
    "Lesser Magicam Recipiendam"
  ],
  "what did you need me to do": [
    "Quid mihi facere iusisti"
  ],
  "making friends and influencing people": [
    "Amicos Faciens et Influentia Hominibus"
  ],
  "witches' festival": [
    "Festivus Sagarum"
  ],
  "boethiah is displeased. she wants you dead": [
    "Boethis irata est. Vos moriri vult"
  ],
  "what's next for the mighty galmar stone-fist": [
    "Quid proximum pro Galmar Lapidi-Pugni Fortissimo"
  ],
  "steel warhammer of shocks": [
    "Bellimalleus Adamanteus Electricitatis"
  ],
  "revealing the unseen": [
    "Invisa Patefaciens"
  ],
  "dwarven war axe of despair": [
    "Dvemeris Bellisecuris Desponsionis"
  ],
  "waterlogged chest": [
    "Arca Madida"
  ],
  "daedric dagger of the blaze": [
    "Pugio Daedricus Congflagrantis"
  ],
  "i would like to join the companions": [
    "Volo cum Contubernalibus adiungere"
  ],
  "daedric helmet of eminent destruction": [
    "Galea Daedrica Eminentis Destructionis"
  ],
  "admit that i control you": [
    "Confitere me te moderari"
  ],
  "thalmor": [
    "Thalmor",
    "Visigothi",
    "Visigothus"
  ],
  "what thalmor": [
    "Quis Visigothus"
  ],
  "you dare question my skill": [
    "Audesne peritiam meam dubitare"
  ],
  "i have much to learn": [
    "Multum sciendum habo"
  ],
  "dragonstone": [
    "Draconilapis"
  ],
  "so you're supposed to train me": [
    "Tibi me docendum est"
  ],
  "how was that": [
    "Quomodo erat hoc"
  ],
  "does vilkas always send newcomers on errands": [
    "Semperne Vilkas novos ad labores parvulas mitit"
  ],
  "the dowry": [
    "Dos"
  ],
  "dowry": [
    "Dos"
  ],
  "rename item": [
    "Da nomen novum rei"
  ],
  "i'll need to be going": [
    "Opus mihi est exire"
  ],
  "didn't you just tell me not to be a servant": [
    "Nonne mihi nunc ipse dixisti not to be servus"
  ],
  "can we do business": [
    "Possumusne negotium agere"
  ],
  "marksmalehumanoid11leftgash": [
    "MarksMaleHumanoid11LeftGash"
  ],
  "i have your shield": [
    "Scutum tuum habeo"
  ],
  "leifur": [
    "Leifur"
  ],
  "enchanter's potion": [
    "Incantatoris Potio"
  ],
  "reverent glass dagger": [
    "Reverens Pugio Vitreus"
  ],
  "dismay - faas": [
    "Dismay - Faas"
  ],
  "dwarven mace of winnowing": [
    "Dvemeris Maca Ventagii"
  ],
  "what am i supposed to tell christer": [
    "Quid Christri dicam"
  ],
  "hairfemaleorc06": [
    "HairFemaleOrc06"
  ],
  "i don't care for boasting": [
    "Nihil de iactatione curo"
  ],
  "the \"madmen\" of the reach": [
    "\"Insani\" Prehensi"
  ],
  "\"madmen\" of the reach": [
    "\"Insani\" Prehensi"
  ],
  "what sort of ingredients do you need": [
    "Qualia ingredientia quaeris"
  ],
  "fish vendor": [
    "Fish vendor"
  ],
  "2920, first seed, v3": [
    "[MMCMXX] 2920, Semen Primum, V. III"
  ],
  "so we'll accept the contract": [
    "Sic, foedus accipiemus"
  ],
  "daedric sword of the blaze": [
    "Gladius Daedricus Congflagrantis"
  ],
  "nilsine shatter-shield": [
    "Nilsine Scuta-Fracta"
  ],
  "hairlinemalenord12": [
    "HairLineMaleNord12"
  ],
  "what does it mean to be \"dragonborn\"": [
    "Quid sibi vult \"Draconigenam\" esse"
  ],
  "i will see that vighar is destroyed": [
    "Vighar necabitur"
  ],
  "hairlinemaleredguard8": [
    "HairLineMaleRedguard8"
  ],
  "steel greatsword of diminishing": [
    "Spatha Adamantea Diminuendi"
  ],
  "dwarven mace of debilitation": [
    "Dvemeris Maca Debilitationis"
  ],
  "key to brina's house": [
    "Clavis ad Domum Brinae"
  ],
  "hjaalmarch guard's armor": [
    "Halmarcis Custodis Lorica"
  ],
  "champion of boethiah": [
    "Vindex Boethis"
  ],
  "hairlinemalenord3": [
    "HairLineMaleNord3"
  ],
  "east empire shipping map": [
    "Mapa Comercii Euri Imperii"
  ],
  "i'm the jarl's thane": [
    "Decemvir Comitis sum"
  ],
  "i demand you let me go at once": [
    "Tibi impero ut me liberes"
  ],
  "key to heimskr's house": [
    "Clavis ad Domum Heimskris"
  ],
  "steel battleaxe of souls": [
    "Bellisecuris Adamanteus Animorum"
  ],
  "what if i pay off your dowry": [
    "Quidni dotem tuam solvam"
  ],
  "take all": [
    "Sume Omnia"
  ],
  "audiocategorypausedduringmenufade": [
    "AudioCategoryPausedDuringMenuFade"
  ],
  "steel boots of muffling": [
    "Caligae Adamanteae Silentii"
  ],
  "i'm ready to take the oath": [
    "Paratus sum iurare"
  ],
  "dragonsreach, great porch": [
    "Draconicarcer, Ectheta Magna"
  ],
  "talk to fastred's parents": [
    "Patres Fastredis Adloquere"
  ],
  "used by dragonrend shout": [
    "Used by dragonrend shout"
  ],
  "conjurer's philter": [
    "Vocatoris Philtrum"
  ],
  "here's lod's letter": [
    "Hic habes epistulam Lodis"
  ],
  "firemage flame resistance": [
    "Firemage flame resistance"
  ],
  "reverent elven warhammer": [
    "Reverens Alfus Bellimalleus"
  ],
  "don't worry about me": [
    "Noli de me sollicitari"
  ],
  "i like this world": [
    "Hunc mundum amo"
  ],
  "i don't want it to end": [
    "Nolo eum obire"
  ],
  "studded imperial armor of the major squire": [
    "Romana Lorica Ornata Maioris Armigeris"
  ],
  "staff of repulsion": [
    "Rudis Repulsionis"
  ],
  "elda early-dawn": [
    "Elda Ante-Azuram"
  ],
  "i see": [
    "Sic"
  ],
  "i must have the wrong place": [
    "Fortasse locum erratum elegi"
  ],
  "i didn't see a dog": [
    "Canem non vidi"
  ],
  "effigy of king olaf": [
    "Effigies Regis Olafis"
  ],
  "any plans to renovate the gray quarter": [
    "Suntne consilia Vicum Canum renovare"
  ],
  "bring wyndelius's journal to wilhelm": [
    "Ephemeridem Wyndelii ad Wilhelm fer"
  ],
  "niranye": [
    "Niranye"
  ],
  "dorian": [
    "Dorian"
  ],
  "uaile": [
    "Uaile"
  ],
  "what kind of books are they": [
    "Quales libri sunt"
  ],
  "skuldafn": [
    "Skuldafn"
  ],
  "hairmalenord01": [
    "HairMaleNord01"
  ],
  "i'll report cicero to the guard": [
    "Cicero ad custodes referam"
  ],
  "i'll get them for you": [
    "Eos tibi adferam"
  ],
  "does that mean i'm a bard now": [
    "Sic, poeta nunc sum"
  ],
  "combatgrunt": [
    "CombatGrunt"
  ],
  "lasting stamina poison": [
    "Persistens Venenum Staminae"
  ],
  "execution hood": [
    "Carnificum Capucium"
  ],
  "i think you're in luck": [
    "Credo te felicem habere"
  ],
  "glass war axe of blizzards": [
    "Bellisecuris Vitreus Hiemis"
  ],
  "do you know anything about arondil": [
    "Scisne quid de Arondil"
  ],
  "dragon investigation: current status": [
    "Investigatio Draconum: Status Nunc"
  ],
  "belchimac": [
    "Belchimac"
  ],
  "you told me to kill, and i killed": [
    "Me necare iussisti et necavi"
  ],
  "you mean you were there": [
    "Dicis te illic fuisse"
  ],
  "honeystrand cave": [
    "Spelunca Melliorae"
  ],
  "trap door key": [
    "Clavis ad Ianuam Secretam"
  ],
  "i won't give him the satisfaction": [
    "Satis ei non faciam"
  ],
  "hairmaleelder3": [
    "HairMaleElder3"
  ],
  "cellar key": [
    "Clavis ad Cellarium"
  ],
  "your daughter asked me to speak to you": [
    "Filia tua mihi suasit ut tecum loquerer"
  ],
  "if the shout is lost, how can i defeat alduin": [
    "Si Clamor perditus est, quomodo Alduinum superare possum"
  ],
  "obscured passage": [
    "Obscurus Commeatus"
  ],
  "why do this": [
    "Cur hoc facias"
  ],
  "why have the emperor assassinated": [
    "Cur mortem Imperatoris vis"
  ],
  "elven dagger of reaping": [
    "Pugio Alfus Saturni"
  ],
  "marked for death - lun": [
    "Marked for Death - Lun"
  ],
  "so, we need to find out what the thalmor know about the dragons": [
    "Ergo, debemus discere quid Visigothi de draconibus sciant"
  ],
  "any ideas": [
    "Quid censes",
    "Consiliumne habes"
  ],
  "nerevar moon and star": [
    "Nerevar Luna Stellaque"
  ],
  "board the katariah": [
    "Conscende Katariam"
  ],
  "dwarven war axe of flames": [
    "Dvemeris Bellisecuris Flammarum"
  ],
  "dwarven warhammer of burning": [
    "Dvemeris Bellimalleus Cremandi"
  ],
  "orcish war axe of burning": [
    "Orci Bellisecuris Cremandi"
  ],
  "i'd like to investigate her house, but i'll need the key": [
    "Volo domum eius investigare, sed clave careo"
  ],
  "umana": [
    "Umana"
  ],
  "i seek my fortune": [
    "Fortunam meam quaero"
  ],
  "key to markarth stables": [
    "Clavis ad Catabulum Markartiae"
  ],
  "are you a wizard": [
    "Esne thaumatorgos"
  ],
  "i wanted to buy some spells": [
    "Volui incantamenta emere"
  ],
  "i'm not going to feed your bad habit": [
    "Solitum tuum non nutriam"
  ],
  "leather bracers of archery": [
    "Corii Chirothecae Saggitationis"
  ],
  "tomb of jurgen windcaller": [
    "Tumulus Jurgenis Ventivocatoris"
  ],
  "idolaf battle-born": [
    "Idolaf Proeli-Natus"
  ],
  "orcish war axe of depleting": [
    "Orci Bellisecuris Deplendi"
  ],
  "please help me to find the real killer": [
    "Adiuva me necatorem verum invenire, quaeso"
  ],
  "fur boots": [
    "Renonis Caligae"
  ],
  "where exactly am i going": [
    "Quo eo, exacte"
  ],
  "aventus aretino says hello": [
    "Aventus Aretino salutem dicit"
  ],
  "creaturedialogueskeleton": [
    "CreatureDialogueSkeleton"
  ],
  "that fort is as good as ours": [
    "Illud Castellum iam fere nostrum est"
  ],
  "has already caught you": [
    "iam te cepit"
  ],
  "what kind of captain are you": [
    "Cuius navis Nauarchus es"
  ],
  "folk are talking about murders": [
    "Cives de homicidiis loquuntur"
  ],
  "sigdis gauldurson": [
    "Sigdis Gauldrifilius"
  ],
  "who are the prominent citizens in windhelm": [
    "Qui sunt cives maximi momenti Ventigaleae"
  ],
  "lurbuk": [
    "Lurbuk"
  ],
  "pt": [
    "pt"
  ],
  "what efforts would they be undermining": [
    "Quae consilia subruant"
  ],
  "but i don't follow your philosophy": [
    "Sed philosophiam non sequor"
  ],
  "why help me learn the voice": [
    "Cur me discere aduivas"
  ],
  "elven bracers of smithing": [
    "Chirothecae Alfae Ferrarii"
  ],
  "are the people of windhelm devout": [
    "Suntne pii homines Ventigaleae"
  ],
  "why are there so many dark elves here": [
    "Cur tanti atralfi hic sunt"
  ],
  "dwarven helmet of major conjuring": [
    "Dvemeris Galea Maioris Vocandi"
  ],
  "there have been murders in windhelm": [
    "Neces Ventigaleae fuerunt"
  ],
  "i'm here about the <alias=item>": [
    "Hic sum de <Alias=Item>"
  ],
  "elven battleaxe": [
    "Bellisecuris Alfus"
  ],
  "steel mace of dismay": [
    "Maca Adamantea Timoris"
  ],
  "how long has this been going on": [
    "Quamdiu haec omnia accidÃªrunt"
  ],
  "saarthal amulet": [
    "Saarthalis Monile"
  ],
  "i think you can do better than that": [
    "Credo te meliorem facere posse"
  ],
  "maven black-briar is the influential client": [
    "Maven Atri-Dumus est cliens magni momenti"
  ],
  "skills: %d/%d selected": [
    "ARTES: %d/%d Selected"
  ],
  "does this finally mean peace": [
    "Tandemne pacem habemus"
  ],
  "glass mace of harvesting": [
    "Maca Vitrea Saturni"
  ],
  "tag %d skill": [
    "Tag %d Ars"
  ],
  "orcish greatsword of arcing": [
    "Orci Spatha Electricitatis"
  ],
  "learn more about the thieves guild from vex": [
    "Plus de Sodalitate Furum e Vex disce"
  ],
  "yes, let's keep going": [
    "Sic, pergamus"
  ],
  "do you know the way out of here": [
    "Scisne exitum huius loci"
  ],
  "frost river farm": [
    "Frost River Farm"
  ],
  "thank you, eola": [
    "Gratias tib ago, Eola"
  ],
  "for everything": [
    "Omnibus"
  ],
  "focusing gloves": [
    "Focandi Manubria"
  ],
  "pickpocketcombat": [
    "FurarePugnans"
  ],
  "orcish helmet of major alchemy": [
    "Orci Galea Maioris Alchemiae"
  ],
  "why do you need him back": [
    "Cur eum vis"
  ],
  "what am i looking for": [
    "Quid quaero"
  ],
  "i have a plan": [
    "Consilium habeo"
  ],
  "i'm going to trap a dragon in dragonsreach": [
    "Insidias draconi parabo in Draconicarcere"
  ],
  "the lorekeeper at the college of winterhold might know": [
    "Bibliothecarius ad Collegium Hiemiterrae fortasse scit"
  ],
  "lorekeeper at the college of winterhold might know": [
    "Bibliothecarius ad Collegium Hiemiterrae fortasse scit"
  ],
  "here's the jagged crown": [
    "Hic habes Coronam Fragosam"
  ],
  "legate rikke sent me to deliver it to you": [
    "Legate Rikke mihi misit ut eam tibi adferam"
  ],
  "how will i know what it looks like": [
    "Quomodo sciam vultum eius"
  ],
  "viola giordano": [
    "Viola Giordano"
  ],
  "ebony warhammer of the vampire": [
    "Bellimalleus Ebeni Vampiri"
  ],
  "what evil do you mean": [
    "De qua malevolentia loqueris"
  ],
  "black-briar manor": [
    "Atri-Dumorum Villa"
  ],
  "ralof and i escaped together": [
    "Ego et Ralof una effugimus"
  ],
  "i'm afraid i don't have any gold to spare": [
    "Timeo ut aurum non habeam"
  ],
  "<global=we17goldamount> gold": [
    "<Global=WE17GoldAmount> aurum"
  ],
  "you can have it, if you can take it from me": [
    "Id habere poteris, si id ab me capere poteris"
  ],
  "pirate king of the abecean": [
    "Rex Piratarum Abeceanorum"
  ],
  "is there someway to open them": [
    "Estne quo modo in quo eas aperire possimus"
  ],
  "daedric battleaxe of leeching": [
    "Bellisecuris Daedricus Sanguisugae"
  ],
  "if i find this crystal you're looking for. will you help me": [
    "Si hoc crystalleum invenero, mihine adiuvabis"
  ],
  "diamond claw": [
    "Ungues Draconis Adamanteus"
  ],
  "i happen to have found a focusing crystal": [
    "Pro casu Crystallum focandi inveni"
  ],
  "is this what you were looking for": [
    "Estne hoc id quod quaesivisti"
  ],
  "i agree completely": [
    "Omnino consentior"
  ],
  "what is a shield-sister": [
    "Quid est Scuti-Soror"
  ],
  "i'm with the college of winterhold": [
    "Cum Collegio Hiemiterrae sum"
  ],
  "ragnar": [
    "Ragnar"
  ],
  "i found your crystal": [
    "Crystallum tuum inveni"
  ],
  "infiltration": [
    "Infiltratio"
  ],
  "orcish shield of shock abatement": [
    "Orci Scutum Contra Vim Electricam"
  ],
  "sorry, i'm afraid you'll make me look silly": [
    "Ignosce, timeo ne me comicum videri facias"
  ],
  "loud and clear conversations": [
    "Loud et Clear Conversations"
  ],
  "astrid": [
    "Astrid"
  ],
  "ebony war axe of freezing": [
    "Bellisecuris Ebeni Gelidus"
  ],
  "plautis carvain": [
    "Plautis Carvain"
  ],
  "fear - master of the mind": [
    "Fear - Dominus Mentis"
  ],
  "my apologies, lady maven": [
    "Ignosce mihi, Domina Maven"
  ],
  "blessing of mara": [
    "Benedictio Marae"
  ],
  "hairlinemaledarkelf03": [
    "HairLineMaleDarkElf03"
  ],
  "apprentice conjurer": [
    "Discipulus Vocator"
  ],
  "i might be able to help": [
    "Fortasse adiuvare possum"
  ],
  "you'd better, or i yell \"skeever.\" (intimidate)": [
    "Debes, vel \"skeever\" clamabo. (Intimida)"
  ],
  "just pay me when the job's done": [
    "Modo pecuniam mihi solve post munus"
  ],
  "i want to stop at old hroldan": [
    "Volo consistere apud Hroldan Vetus"
  ],
  "party clothes": [
    "Festi Vestimenta"
  ],
  "hairlinefemalenord02": [
    "HairLineFemaleNord02"
  ],
  "i want to stop at ivarstead": [
    "Volo consistere apud Ivaridomum"
  ],
  "if there's money in it": [
    "Si pecuniam in eo esset"
  ],
  "i want to stop at darkwater crossing": [
    "Volo consistere apud Vadum Aquae Obscurae"
  ],
  "i want to stop at karthwasten": [
    "Volo consistere apud Karthwasten"
  ],
  "why do you hate haelga so much": [
    "Cur Haelgam tantum in odio habes"
  ],
  "banded iron shield of resist fire": [
    "Ferri Adligati Scutum Contra Vim Ignis"
  ],
  "honed ancient nord war axe of ice": [
    "Melior Germanicus Antiquus Bellisecuris Gelidus"
  ],
  "audiotemplatetroll": [
    "AudioTemplateTrellis"
  ],
  "hairlinemaledremora02": [
    "HairLineMaleDremora02"
  ],
  "fur-lined boots": [
    "Renone-Lineatae Caligae"
  ],
  "ebony helmet of extreme illusion": [
    "Ebeni Galea Inlusionis Extremae"
  ],
  "glass sword of freezing": [
    "Gladius Vitreus Gelidus"
  ],
  "lost prospect mine": [
    "Metallum Prospecti Perditi"
  ],
  "sweet roll": [
    "Dulcifer"
  ],
  "ectoplasm": [
    "Ectoplasma"
  ],
  "steel mace of torpor": [
    "Maca Adamantea Torporis"
  ],
  "storm call effect 3": [
    "Storm Call Effect 3"
  ],
  "db recurringtarget 9 crimefaction": [
    "DB RecurringTarget 9 CrimeFaction"
  ],
  "hallowed dwarven greatsword": [
    "Consacrata Dvemeris Spatha"
  ],
  "object fade": [
    "Evanidus Obiectorum"
  ],
  "bolar": [
    "Bolar"
  ],
  "battle of sancre tor": [
    "Proelium Turris Sacrae"
  ],
  "dragonplate helmet of eminent restoration": [
    "Draconilaminata Galea Eminentis Reficiendi"
  ],
  "adept conjuration": [
    "Ars Vocandi Adepta"
  ],
  "riften mistveil keep": [
    "Hiati Mistveil Keep"
  ],
  "maurice jondrelle": [
    "Maurice Jondrelle"
  ],
  "steel plate bracers of eminent wielding": [
    "Chirothecae Laminatae Eminentis Tenendi"
  ],
  "dragonscale gauntlets of eminent archery": [
    "Draconisquamatae Manicae Eminentis Saggitationis"
  ],
  "habd's lighthouse key": [
    "Clavis ad Pharon Habdis"
  ],
  "kill ennodius papius": [
    "Neca Ennodium Papium"
  ],
  "gaius maro is dead": [
    "Gaius Maro mortuus est"
  ],
  "steel mace of cold": [
    "Maca Adamantea Frigidis"
  ],
  "hairfemaleelder02": [
    "HairFemaleElder02"
  ],
  "i'm no thief": [
    "Non sum fur"
  ],
  "dwemer scrap metal": [
    "Dvemeris Metalli Gry"
  ],
  "falmer supple bow": [
    "Arcus Falmeris Potens"
  ],
  "and what is it i'm seeking": [
    "Et quid quaero"
  ],
  "if you want me to keep quiet, it's going to cost you": [
    "Si me tacere velis, tibi pecuniam solvendam est"
  ],
  "how many of these are there": [
    "Quot horum sunt"
  ],
  "iron gauntlets of minor wielding": [
    "Ferri Manicae Minoris Tenendi"
  ],
  "you have no faith in the guild": [
    "Nullam fidem in Sodalitate habes"
  ],
  "of course i'll join you": [
    "Certe tecum adiungam"
  ],
  "does the crown still exist": [
    "Coronane iam extat"
  ],
  "hireling": [
    "Hireling"
  ],
  "i've found this scroll": [
    "Hunc codicem inveni"
  ],
  "ebony helmet of major illusion": [
    "Ebeni Galea Maioris Inlusionis"
  ],
  "i'm looking for endon's mold": [
    "Examplar Endonis quaero"
  ],
  "db02captive1persuade": [
    "DB02Captive1Persuade"
  ],
  "it's all right. just tell me what i need to know. (persuade)": [
    "Bonum est. Modo dic mihi id quod scire volo. (Persuade)"
  ],
  "alteration trainer": [
    "Alteration Trainer"
  ],
  "db02captive1intimidate": [
    "DB02Captive1Intimidate"
  ],
  "fortify health - master of the mind": [
    "Fortify Health - Dominus Mentis"
  ],
  "hairmaleorc27": [
    "HairMaleOrc27"
  ],
  "one last time: would. someone. pay. to. have. you. killed? (intimidate)": [
    "Ultima Occasio. Estne. Quis. Qui. Pro. Mortem. Tuam. Pecuniam. Solvat? (Intimida)"
  ],
  "db02captive2intimidate": [
    "DB02Captive2Intimidate"
  ],
  "damage stamina regen": [
    "Vulnera Recuperationem Staminae"
  ],
  "guard house": [
    "Guard House"
  ],
  "recover skooma source information": [
    "Inveni Informatum Fontis Skoomae"
  ],
  "morthal falion's house scene 1": [
    "Morthal Falionis House Scene 1"
  ],
  "i've finished that special windhelm job": [
    "Munus speciale Ventigaleae perfinivi"
  ],
  "moonstone ore": [
    "Lunalapis Minerale"
  ],
  "why is sibbi in jail": [
    "Cur Sibbi in carcere est"
  ],
  "is that it": [
    "Suntne omnia"
  ],
  "creaturedialoguehare": [
    "CreatureDialogueHare"
  ],
  "maleheaddarkelf": [
    "MaleHeadDarkElf"
  ],
  "vilod": [
    "Vilod"
  ],
  "do you have any clues about your past": [
    "Habesne ullas informationes de historia tua"
  ],
  "has the time come": [
    "Estne tempus"
  ],
  "will i now serve the night mother": [
    "Serviamne Matrem Noctis"
  ],
  "sanctuary guardian": [
    "Custos Cellae"
  ],
  "expert retort": [
    "Expert Retort"
  ],
  "do not delete": [
    "Do Not Delete"
  ],
  "do you work for the meadery": [
    "Pro hydromellario laboras"
  ],
  "have something special for me, delvin": [
    "Habesne quid speciale mihi, Delphine"
  ],
  "elven helmet of magicka": [
    "Alfa Galea Magicae"
  ],
  "scroll of rout": [
    "Codex Effugiti"
  ],
  "imperial courier": [
    "Imperialis Nuntius"
  ],
  "so, what's this special request": [
    "Ergo, quid est haec questio specialis"
  ],
  "dwarven mace of thunderbolts": [
    "Dvemeris Maca Fulminum"
  ],
  "i'm here to speak to falk": [
    "Hic sum ut Falconem adloquar"
  ],
  "none of the master files used in this save game are loaded": [
    "None of master files used in this save game are loaded"
  ],
  "irkngthand": [
    "Irkngthand"
  ],
  "how did you become a jarl while so young": [
    "Quomodo Comes tam iuvenis factus es"
  ],
  "mq always on": [
    "MQ Always On"
  ],
  "key to rustleif's house": [
    "Clavis ad Domum Rustleifis"
  ],
  "guthrum": [
    "Guthrum"
  ],
  "wilhelm": [
    "Vilhelm"
  ],
  "here's your <alias=questitem>": [
    "Hic habes <Alias=QuestItem>"
  ],
  "legend of krately house": [
    "Legendum Domus Krateleiae"
  ],
  "glass war axe of thunderbolts": [
    "Bellisecuris Vitreus Fulminum"
  ],
  "what can you tell me about talos": [
    "Quid mihi dicere potes de Talos"
  ],
  "he came in with me, but i'm not sure what happened to him": [
    "Mecum venit, sed nescio quid ei acciderit"
  ],
  "scroll of rally": [
    "Codex Hortandi"
  ],
  "why all the hatred for the empire": [
    "Cur tantum irae Imperio"
  ],
  "faralda services": [
    "Faralda Services"
  ],
  "you know you should help him": [
    "Eum adiuvare debes"
  ],
  "audiotemplateskeleton": [
    "AudioTemplateSkeleton"
  ],
  "elven armor of eminent health": [
    "Alfa Lorica Eminentis Salutis"
  ],
  "lieutenant salvarus": [
    "Legatus Salvarus"
  ],
  "choose up to %d trait": [
    "Elige usque ad %d"
  ],
  "brow width": [
    "Longitudo Frontis"
  ],
  "i've got the plans": [
    "Iconographias habeo"
  ],
  "forelhost balcony key": [
    "Clavis ad Ekthetam Forelhostis"
  ],
  "steel sword of soul snares": [
    "Gladius Adamanteus Animicapti"
  ],
  "what do you see": [
    "Quid vis"
  ],
  "night mother": [
    "Mater Noctis"
  ],
  "muted step": [
    "Muted Step"
  ],
  "distribute %d point": [
    "Distribue %d"
  ],
  "i want you to read my fortune": [
    "Volo te sortem meam legere"
  ],
  "da16 orc dream faction": [
    "DA16 Orc Dream Faction"
  ],
  "moralrefusal": [
    "MoralRefusal"
  ],
  "i'd like to rent a room": [
    "Volo conclave conducere"
  ],
  "i'll rent tiber septim's room": [
    "Cubiculum Tiberi Septimi Conducam"
  ],
  "creaturedialoguebear": [
    "CreatureDialogueBear"
  ],
  "clan cruel-sea house key": [
    "Clavis ad Domum Gentis Crudeli-Maris"
  ],
  "dwarven war axe of depleting": [
    "Dvemeris Bellisecuris Deplendi"
  ],
  "take the writ of passage": [
    "Cape Signum Commeatus"
  ],
  "orcish bow of frost": [
    "Orci Arcus Gelidus"
  ],
  "imperial helmet of archery": [
    "Romana Galea Saggitationis"
  ],
  "that man shouted something about the \"forsworn.\"": [
    "Iste aliquid de \"Abiuratis\" clamavit"
  ],
  "dwarven warhammer of draining": [
    "Dvemeris Bellimalleus Hauriendi"
  ],
  "platinum blond": [
    "Platinum Blond"
  ],
  "it belonged to borvir": [
    "Erat Borviris"
  ],
  "did you know her": [
    "Novistine eam"
  ],
  "who was she": [
    "Quis erat"
  ],
  "i was actually looking to buy something": [
    "Re vera aliquid emere volui"
  ],
  "where's the golden claw": [
    "Ubi est Ungues Aureus"
  ],
  "who was he": [
    "Quis erat"
  ],
  "you don't sound very happy about it": [
    "Non laetissimus de eo videris"
  ],
  "it was necessary": [
    "Necesse erat"
  ],
  "alduin brought this on himself": [
    "Alduin hoc in se attulit"
  ],
  "i told you i'd stop him, and i did": [
    "Tibi dixi me eum necaturum esse, et id feci"
  ],
  "witch fighting atronach": [
    "Witch fighting Atronachum"
  ],
  "player has magic flames equipped": [
    "Player has magic flames equipped"
  ],
  "the emperor": [
    "Imperator"
  ],
  "the real emperor": [
    "Imperator verus"
  ],
  "real emperor": [
    "Imperator verus"
  ],
  "madanach's note": [
    "Epistula Madanachis"
  ],
  "do you have any more contracts": [
    "Habesne foedera plurima"
  ],
  "mammoth guardian spirit": [
    "Animus Custodis Elephantium"
  ],
  "i command you to accompany me": [
    "Tibi impero ut mecum convenias"
  ],
  "riverwood alvors house faction": [
    "Riverwood Alvors House Faction"
  ],
  "tg00 misc handler": [
    "TG00 Misc Handler"
  ],
  "guardian troll spirit": [
    "Animus Custodis Trellis"
  ],
  "i have no quarrel with you": [
    "Tecum non querrelor"
  ],
  "imperial armor of the major knight": [
    "Romana Lorica Maioris Equitis"
  ],
  "ebony boots of fire suppression": [
    "Caligae Ebeni Suppressionis Ignis"
  ],
  "steel plate armor of the major knight": [
    "Laminata Lorica Maioris Equitis"
  ],
  "here, brother": [
    "Hic, Frater"
  ],
  "blessings of mara upon you": [
    "Beneditiones Veneris tibi",
    "Benedictiones Veneris tibi"
  ],
  "i think we should learn something practical": [
    "Credo nos aliquid utile discere debere"
  ],
  "elven war axe of leeching": [
    "Alfus Bellisecuris Sanguisugae"
  ],
  "solutide stables": [
    "Solutide stables"
  ],
  "you really think the staff will help": [
    "Credisne rudem adiuturam esse"
  ],
  "you're holding out on arniel again": [
    "Arniel iterum pecuniam non das"
  ],
  "malign aversion to frost": [
    "Malignum Aversio Glaciei"
  ],
  "i've got the sap": [
    "Olibanum habeo"
  ],
  "steel plate armor of the knight": [
    "Laminata Lorica Equitis"
  ],
  "orcish sword of consuming": [
    "Orci Gladius Consumendi"
  ],
  "hairfemaleorc08": [
    "HairFemaleOrc08"
  ],
  "solitude guard's helmet": [
    "Solitudinis Custodis Galea"
  ],
  "ebony dagger of freezing": [
    "Pugio Ebeni Gelidus"
  ],
  "skulvar sable-hilt": [
    "Skulvar Capuli-Feles"
  ],
  "defeat the guardian mudcrab": [
    "Vince Cancerilutum Custodem"
  ],
  "dog faction": [
    "Dog Faction"
  ],
  "humanbeard15": [
    "HumanBeard15"
  ],
  "ebony greatsword of the sorcerer": [
    "Spatha Ebeni Magi"
  ],
  "orcish war axe of flames": [
    "Orci Bellisecuris Flammarum"
  ],
  "if you don't get away from me right now, i may kill you myself": [
    "Si e me nunc non effugias, fortasse ego ipse te necam"
  ],
  "maleheadnord": [
    "MaleHeadNord"
  ],
  "i need the elder scroll the ancients used": [
    "Egeo Codice Veterrima qua antiquires uti sunt"
  ],
  "do you know where to find it": [
    "Scisne ubi invenitur"
  ],
  "ebony warhammer of annihilating": [
    "Bellimalleus Ebeni Annihilationis"
  ],
  "mg dormitory scene 10": [
    "MG Dormitory Scene 10"
  ],
  "daedric greatsword of leeching": [
    "Spatha Daedrica Sanguisugae"
  ],
  "i don't think i like where this is going": [
    "Non credo me amare quo hoc iturum esse"
  ],
  "were they successful": [
    "Vinceruntne"
  ],
  "join the college of winterhold": [
    "Adiunge Collegium Hiemiterrae"
  ],
  "perhaps i could give you the death you seek": [
    "Fortasse tibi mortem quam rogas dare possim"
  ],
  "how about the dawnstar": [
    "Quid de Luficere"
  ],
  "i can handle that": [
    "Id administrare possum"
  ],
  "steel battleaxe of burning": [
    "Bellisecuris Adamanteus Cremandi"
  ],
  "orcish battleaxe of siphoning": [
    "Orci Bellisecuris Siphonis"
  ],
  "you think gulum-ei will give me trouble": [
    "Putanse Gulum-Ei mihi curam faciat"
  ],
  "dragonplate helmet of eminent illusion": [
    "Draconilaminata Galea Eminentis Inlusionis"
  ],
  "night eye dispel": [
    "Night Eye Dispel"
  ],
  "steel greatsword of fear": [
    "Spatha Adamantea Timoris"
  ],
  "fire mastery": [
    "Dominus Ignis"
  ],
  "the real barenziah, v5": [
    "Barenzia Vera, V"
  ],
  "real barenziah, v5": [
    "Barenzia Vera, V"
  ],
  "steel warhammer of burning": [
    "Bellimalleus Adamanteus Cremandi"
  ],
  "blade": [
    "Blade"
  ],
  "have anything to trade": [
    "Habesne quid tradendum"
  ],
  "ebony helmet of extreme alchemy": [
    "Ebeni Galea Extremis Alchemiae"
  ],
  "iron gauntlets of alchemy": [
    "Ferri Manicae Alchemiae"
  ],
  "what's the psijic order": [
    "Quid est Ordo Psijico"
  ],
  "adventurers": [
    "Adventurers"
  ],
  "he's dead. you're next": [
    "Mortuus est. Tu es proximus"
  ],
  "i go where i please": [
    "Eo quo volo"
  ],
  "\"as talos is my witness, may this oath bind me to death and beyond...\"": [
    "\"Talos ut testis, sine hoc ius me ad mortem et postea tenere...\""
  ],
  "so you'll come to the peace council": [
    "Ergo ad concilium pacis venies"
  ],
  "a greatsword swings faster than a battleaxe, and a battleaxe swings faster than a warhammer": [
    "Spatha celerius quam Bellisecuris, et Bellisecuris celerius quam Bellimalleus vibratur"
  ],
  "greatsword swings faster than a battleaxe, and a battleaxe swings faster than a warhammer": [
    "Spatha celerius quam Bellisecuris, et Bellisecuris celerius quam Bellimalleus vibratur"
  ],
  "audiotemplategoat": [
    "AudioTemplateGoat"
  ],
  "you will be judged by sithis in the void": [
    "Iudicaberis a Sithi in Chao"
  ],
  "i pity you": [
    "Tibi misereor"
  ],
  "that sounds pretty bad": [
    "Satis malus mihi videtur"
  ],
  "why is it dangerous if they're asleep": [
    "Cur periculosus esset si dormirent"
  ],
  "ebony mace of immobilizing": [
    "Maca Ebeni Immobilitatis"
  ],
  "reverent dwarven mace": [
    "Reverens Dvemeris Maca"
  ],
  "what does the miasma do": [
    "Quid facit Miasma"
  ],
  "is the gas dangerous": [
    "Estne nebula periculosa"
  ],
  "\"upon my honor i do swear undying loyalty to the emperor, titus mede ii...\"": [
    "\"Honore meo fidelitatem sempiternam Imperatori, Tito Mede II Iuro...\""
  ],
  "braidwood inn services": [
    "Braidwood Inn Services"
  ],
  "here, it's your debt with maven. you're free": [
    "Hic, debitum tuum cum Maven est. Libertus es"
  ],
  "large wooden gate": [
    "Porta Magna Lignea"
  ],
  "\"upon my honor i do swear undying loyalty to the emperor...\"": [
    "\"Honore meo fidelitatem sempiternam Imperatori...\""
  ],
  "haelga's bunkhouse scene 07": [
    "Haelga's Bunkhouse Scene 07"
  ],
  "\"may those above judge me, and those below take me, if i fail in my duty.\"": [
    "\"Sine illos super me, et sub me, si fallam, me necare.\""
  ],
  "the hall of the dead is safe now": [
    "Aula Mortuorum tuta nunc est"
  ],
  "hall of the dead is safe now": [
    "Aula Mortuorum tuta nunc est"
  ],
  "tell me about this tree": [
    "Dic mihi de hoc arbore"
  ],
  "i'm not a cannibal": [
    "Anthropophagus non sum"
  ],
  "what are you trying to do to me": [
    "Quid mihi facere temptas"
  ],
  "i'm the thane of riften": [
    "Decemvir Hiati sum"
  ],
  "udefrykte": [
    "Udefrykte"
  ],
  "why would you want to leave skyrim": [
    "Cur e Caelifine effugere velis"
  ],
  "stormcloak general": [
    "Sturmmantelorum Imperator"
  ],
  "dwarven dagger": [
    "Dvemeris Pugio"
  ],
  "audiotemplatechaurus": [
    "AudioTemplateChaurus"
  ],
  "lost knife hideout": [
    "Latebra Cultris Perditi"
  ],
  "oh, right. ulfric stormcloak": [
    "Sic... Vercingetorix Temepstatamiculus"
  ],
  "how did you figure all this out": [
    "Quomodo haec omnia rationanatus es"
  ],
  "windhelm blacksmith quarters key": [
    "Clavis ad Conclavia Ferrarii Ventigaleae"
  ],
  "sabre cat faction": [
    "Sabre Cat Faction"
  ],
  "orcish warhammer of reaping": [
    "Orci Bellimalleus Saturni"
  ],
  "thorald is safe": [
    "Thorald tutus est"
  ],
  "ebony war axe of exhaustion": [
    "Bellisecuris Ebeni Exhaustionis"
  ],
  "(unknown effect)": [
    "(Effectum Ignotum)"
  ],
  "mace etiquette": [
    "Usus Dignus Macarum"
  ],
  "daedric bow of the blaze": [
    "Arcus Daedricus Congflagrantis"
  ],
  "foritfy all magic skills": [
    "Adde ad Omnes Artes Magicae"
  ],
  "deadly magicka poison": [
    "Letifer Venenum Magicae"
  ],
  "dragonplate boots of shock abatement": [
    "Draconilaminata Caligae Contra Vim Electricam"
  ],
  "that's impressive": [
    "Miror"
  ],
  "glass sword of shocks": [
    "Gladius Vitreus Electricitatis"
  ],
  "nepos's journal": [
    "Ephemeris Nepotis"
  ],
  "orcish boots of strength": [
    "Orci Caligae Fortitudinis"
  ],
  "i have a message from ulfric stormcloak": [
    "Nuntium habeo ab Vercingetorige Temepstatamiculo"
  ],
  "boti said she'd handle jofthor": [
    "Boti dixit se cum Jofthor adtrectet"
  ],
  "ebony war axe of despair": [
    "Bellisecuris Ebeni Desponsionis"
  ],
  "delphine needs your help to stop the dragons": [
    "Delphine auxilium tuum vult ut dracones superemus"
  ],
  "dbsancmalloryrefitchoice1": [
    "DBSancMalloryRefitChoice1"
  ],
  "poisoner's nook": [
    "Angulus Venefico"
  ],
  "markarth military camp": [
    "Markartiae Castra"
  ],
  "secret entrance": [
    "Ingressum Secretum"
  ],
  "eimar": [
    "Eimar"
  ],
  "the longhammer": [
    "Malelonga"
  ],
  "longhammer": [
    "Malelonga"
  ],
  "cheek color": [
    "Color Genarum"
  ],
  "how did you become allied with the thieves guild": [
    "Quomodo socius factus es Sodalitatis Furum"
  ],
  "i have a price on my head in riften": [
    "Pretium in capite meo habeo Hiati"
  ],
  "elven dagger of scorching": [
    "Pugio Alfus Adurendi"
  ],
  "hairfemalenord05": [
    "HairFemaleNord05"
  ],
  "whiterun belethor's general goods faction": [
    "Whiterun Belethor's General Goods Faction"
  ],
  "dialoguefalkreathdeadmansdrinkscene01": [
    "DialogueFalkreathDeadMansDrinkScene01"
  ],
  "dragonscale gauntlets of peerless sure grip": [
    "Draconisquamatae Manicae Optimae Ambidexteritatis"
  ],
  "beloved - master of the mind": [
    "Beloved - Dominus Mentis"
  ],
  "imperial helmet of minor alteration": [
    "Romana Galea Minoris Mutationis"
  ],
  "daughter of the niben": [
    "Filia Nibenis"
  ],
  "iron sword of dismay": [
    "Gladius Ferri Timoris"
  ],
  "your weapon has broken": [
    "Your weapon has broken"
  ],
  "i overheard something about a college": [
    "Aliquid de Collegio audivi"
  ],
  "hairmalenord13": [
    "HairMaleNord13"
  ],
  "last seed": [
    "Augustus"
  ],
  "the difference is i still have honor": [
    "Discrimen est me iam honorem habere"
  ],
  "difference is i still have honor": [
    "Discrimen est me iam honorem habere"
  ],
  "i'm here to see nepos": [
    "Hic sum ut Nepos videam"
  ],
  "he helped me find the pieces": [
    "Mihi partes invenire adiuvit"
  ],
  "he deserves to live": [
    "Vivere meret"
  ],
  "so what is this mythic dawn cult": [
    "Ergo, quid est cultus Aurorae Mythicae"
  ],
  "scroll of mass paralysis": [
    "Codex Paralytiorum Omnium"
  ],
  "daedric greatsword of evoking": [
    "Spatha Daedrica Evocandi"
  ],
  "glass battleaxe of banishing": [
    "Bellisecuris Vitreus Expellendi"
  ],
  "tell me more about these caravans": [
    "Dic mihi plus de caravanis"
  ],
  "you... must not see many orcs around here": [
    "Multi orci his in partibus certe non videtis"
  ],
  "sliding door": [
    "Ianuam Labentem"
  ],
  "so what now": [
    "Ergo, quid nunc"
  ],
  "the wolf queen, v2": [
    "Regina Luporum, II"
  ],
  "wolf queen, v2": [
    "Regina Luporum, II"
  ],
  "orcish war axe of winnowing": [
    "Orci Bellisecuris Ventagii"
  ],
  "dialogueloreiusfarm": [
    "DialogueLoreiusFarm"
  ],
  "what's your plan": [
    "Quid is consilium tuum"
  ],
  "you said something needing help": [
    "Dixisti aliquid de auxilium quaerendo"
  ],
  "kilkreath balcony": [
    "Ectheta Kilkretiae"
  ],
  "glass sword of lethargy": [
    "Gladius Vitreus Lethargiae"
  ],
  "how long have you been the court wizard": [
    "Quamdiu fuisti thaumatorgos aulae"
  ],
  "where will you be from now on": [
    "Ubi nunc eris"
  ],
  "dwarven helmet of alchemy": [
    "Dvemeris Galea Alchemiae"
  ],
  "eyeargonianmale": [
    "EyeArgonianMale"
  ],
  "the companions are werewolves": [
    "Contubernales Versipelles sunt"
  ],
  "companions are werewolves": [
    "Contubernales Versipelles sunt"
  ],
  "i assure you it wasn't anyone from the guild": [
    "Te iuro id non aliquem e Sodalitate fuisse"
  ],
  "dwarven bracers of eminent sure grip": [
    "Dvemeres Chirothecae Eminentis Ambidexteritatis"
  ],
  "daedric dagger of malediction": [
    "Pugio Daedricus Maledictionis"
  ],
  "reverent steel battleaxe": [
    "Reverens Bellisecuris Adamanteus"
  ],
  "draught of light feet": [
    "Decocta Pedum Levium"
  ],
  "hairlinemalenord2": [
    "HairLineMaleNord2"
  ],
  "orcish boots of fire suppression": [
    "Orci Caligae Suppressionis Ignis"
  ],
  "thalmor dossier: esbern": [
    "Visigothi Dossier: Esbernus"
  ],
  "ranmir's agreed to pay his debts": [
    "Ranmir assensit ut aes alienum solveret"
  ],
  "how's that changing the way things are": [
    "Quomodo illud res mutare posset"
  ],
  "dwarven mace of torpor": [
    "Dvemeris Maca Torporis"
  ],
  "draught of resist shock": [
    "Decocta Contra Vim Electricam"
  ],
  "sword of amazement": [
    "Svord of Amazement"
  ],
  "summonmassbodyartholder": [
    "SummonMassBodyArtHolder"
  ],
  "i'd ask the same question of you": [
    "Idem rogatum de te rogare possum"
  ],
  "spell tome: greater ward": [
    "Liber Magicus: Praesidium Maius"
  ],
  "ebony battleaxe of devouring": [
    "Bellisecuris Ebeni Consumendi"
  ],
  "silver ruby ring": [
    "Anulus Argentei Rubini"
  ],
  "sickly farmer": [
    "Agricola Aegra"
  ],
  "then take the key and right all the wrongs": [
    "Ergo clavem cape et omnia prava corrige"
  ],
  "i have something of yours": [
    "Aliquid tuum habeo"
  ],
  "malborn, what are you doing here": [
    "Malborn, cur hic es"
  ],
  "then i'll have to proceed alone": [
    "Ergo solus procedere debeo"
  ],
  "what's wrong with the other nightingale sentinels": [
    "Quid est difficultas cum aliis custodibus Philomelarum"
  ],
  "key to evette san's house": [
    "Clavis ad Domum Evette Sanis"
  ],
  "glass greatsword of scorching": [
    "Spatha Vitrea Adurendi"
  ],
  "why aren't you like them": [
    "Cur tu eis dissimilis es"
  ],
  "key to halldir's crypt": [
    "Clavis ad Hypogaeum Halldiris"
  ],
  "rally": [
    "Hortare"
  ],
  "staff of tandil": [
    "Rudis Tandilis"
  ],
  "steel boots of waning frost": [
    "Caligae Adamanteae Insenescentis Congelationis"
  ],
  "uh... i haven't found anything yet": [
    "Uh... nondum quidquam inveni"
  ],
  "glass shield of eminent blocking": [
    "Vitreum Scutum Eminentis Interponendi"
  ],
  "hairmaleorc09": [
    "HairMaleOrc09"
  ],
  "wolfskull ruins": [
    "Calvarialupi Ruinae"
  ],
  "elven bow of freezing": [
    "Arcus Alfus Gelidus"
  ],
  "so why are they hunting you": [
    "Cur igitur te venantur"
  ],
  "druadach redoubt cave": [
    "Druadach Redubitum Spelunca"
  ],
  "vokun": [
    "Vokun"
  ],
  "enchantment": [
    "Incantamentum"
  ],
  "sarethi farm scene 01": [
    "Sarethi Farm Scene 01"
  ],
  "relic ring finger": [
    "Reliquium Anulus"
  ],
  "winterhold guard": [
    "Hiemiterrae Custos"
  ],
  "mjoll the lioness": [
    "Mjoll Leaena"
  ],
  "i've killed dozens like you": [
    "Multitudines ut te necavi"
  ],
  "how much to let me pass": [
    "Quot nummorum mihi solvendos sunt ut transeam"
  ],
  "i have quicksilver ore to sell": [
    "Mercurium vendendum habeo"
  ],
  "dialoguedushnikhyalblacksmithingscene01": [
    "DialogueDushnikhYalBlackFerrariiScene01"
  ],
  "thieves guild variant armor": [
    "Sodalitatis Furum Lorica Var"
  ],
  "it said something about danger ahead, and the psijic order": [
    "Aliquid de periculo veniente dixit, De ordine Psijico"
  ],
  "glass bow of lethargy": [
    "Arcus Vitreus Lethargiae"
  ],
  "silus's chest": [
    "Arca Sili"
  ],
  "is this the tomb": [
    "Estne sepulcrum"
  ],
  "humanbeardlong14_1bit": [
    "HumanBeardLong14_1bit"
  ],
  "dragonscale helmet of extreme magicka": [
    "Draconisquamata Galea Extremis Magicae"
  ],
  "i've made my peace": [
    "Pacem meam feci"
  ],
  "jzargo flames": [
    "Flammae Jzargonis"
  ],
  "i'm not trying to cause trouble": [
    "Difficultatem facere non conor"
  ],
  "i put out the lighthouse fire": [
    "Ignem Pharonis extinxi"
  ],
  "ebony greatsword of exhaustion": [
    "Spatha Ebeni Exhaustionis"
  ],
  "server": [
    "Server"
  ],
  "what did you lose": [
    "Quid amisisti"
  ],
  "you said you're in hiding": [
    "Dixistine te latere"
  ],
  "i have a frost salts for you, from farengar": [
    "Salia Glaciei tibi habeo, ab Farengar"
  ],
  "hairfemaledarkelf05": [
    "HairFemaleDarkElf05"
  ],
  "dwarven warhammer of absorption": [
    "Dvemeris Bellimalleus Absorbendi"
  ],
  "entrance hall": [
    "Atrium"
  ],
  "(<global=hdmarkarthhall> gold)": [
    "(<Global=HDMarkarthHall> auri)"
  ],
  "glass gauntlets of eminent sure grip": [
    "Vitreae Manicae Eminentis Ambidexteritatis"
  ],
  "is there any way i can help": [
    "Estne modo in quo adiuvare possum"
  ],
  "dragonscale gauntlets of extreme smithing": [
    "Draconisquamatae Manicae Extremis Ferrarii"
  ],
  "2920, sun's dawn, v2": [
    "[MMCMXX] 2920, Ortus Solis, V. II"
  ],
  "the save game is corrupt and cannot be loaded": [
    "Ludus tutus corruptus est et aperi non potest"
  ],
  "save game is corrupt and cannot be loaded": [
    "Ludus tutus corruptus est et aperi non potest"
  ],
  "the greybeards may have a point": [
    "Canabarbae, fortasse, recte dicunt"
  ],
  "greybeards may have a point": [
    "Canabarbae, fortasse, recte dicunt"
  ],
  "power is dangerous": [
    "Vis periculosa est"
  ],
  "ebony war axe of fire": [
    "Bellisecuris Ebeni Ignis"
  ],
  "hairfemaledarkelf01": [
    "HairFemaleDarkElf01"
  ],
  "who is roggvir": [
    "Quis est Roggvir"
  ],
  "elven battleaxe of stunning": [
    "Bellisecuris Alfus Intermissionis"
  ],
  "dampened spirits scene quest": [
    "Dampened Spirits Scene Quest"
  ],
  "how did the oblivion crisis impact winterhold": [
    "Quomodo Caedes Oblivionis Hiemiterram afficit"
  ],
  "orcish dagger of arcing": [
    "Orci Pugio Electricitatis"
  ],
  "the great collapse": [
    "Lapsus Magnus"
  ],
  "great collapse": [
    "Lapsus Magnus"
  ],
  "is the college dangerous": [
    "Estne periculosum Collegium"
  ],
  "jala": [
    "Jala"
  ],
  "sleep now, old woman": [
    "Dormi nunc, anus"
  ],
  "embrace the void": [
    "Complecte Hiatum"
  ],
  "who has a key to the warehouse": [
    "Quis habet clavem horreo"
  ],
  "what do you have against the greybeards": [
    "Quid contra Canabarbas habes"
  ],
  "elven sword of lethargy": [
    "Alfus Gladius Lethargiae"
  ],
  "armor - iron": [
    "Armor - Iron"
  ],
  "why were you in riften": [
    "Cur Hiati fuisti"
  ],
  "rank = which mission was last failed": [
    "Rank = which mission was last failed"
  ],
  "scroll of ice spike": [
    "Codex Hami Gelidi"
  ],
  "hide shield of waning shock": [
    "Scutum Cutis Insenescentis Electricitatis"
  ],
  "i'm looking into the market attack": [
    "De oppugno in foro volo scire"
  ],
  "kill the <alias=beast>": [
    "Neca <Alias=Beast>"
  ],
  "do you work the forge all day": [
    "Laborasne hic totum diem"
  ],
  "i pursue alduin, the world-eater": [
    "Alduinum sequor, Mundivorantem"
  ],
  "the mage stone": [
    "Lapis Magi"
  ],
  "mage stone": [
    "Lapis Magi"
  ],
  "orcish helmet of eminent illusion": [
    "Orci Galea Eminentis Inlusionis"
  ],
  "i seek entrance to the hall of valor": [
    "Ingressum ad Aulam Valoris quaero"
  ],
  "dinya balu": [
    "Dinya Balu"
  ],
  "whitewatch tower": [
    "Whitewatch Tower"
  ],
  "i have the coat": [
    "Amiculum habeo"
  ],
  "draugr warlock": [
    "Draugr Warlock"
  ],
  "yes, i put out the fire": [
    "Sic, ignem extingui"
  ],
  "have things changed much with the empire in charge": [
    "MutavÃªruntne res multo nunc Romanis regentibus"
  ],
  "any ideas where i can look": [
    "Scisne ubi quaerere possem"
  ],
  "has the change to imperial rule disrupted business": [
    "Negotiumne dirrupit mutatio ad regnum Romanum"
  ],
  "ice wraith faction": [
    "Ice Wraith Faction"
  ],
  "imperial light helmet of conjuration": [
    "Romana Cassis Coniurationis"
  ],
  "do you miss ulfric stormcloak": [
    "Caresne Vercingetorige Temepstatamiculo"
  ],
  "why does the town have such a large cemetery": [
    "Cur oppidum coemeterium tam magnum habet"
  ],
  "imperials vs stormcloaks": [
    "Imperials vs Stormcloaks"
  ],
  "mouth shape": [
    "Forma Oris"
  ],
  "so get rid of him and he's no longer a threat": [
    "Ergo eam remove et non iam comminatio erit"
  ],
  "skaggi scar-face": [
    "Skaggi Cicatricos"
  ],
  "elven warhammer of harrowing": [
    "Alfus Bellimaleus Occationis"
  ],
  "i'm happy to lend a hand": [
    "Mihi placuit te adiuvare"
  ],
  "what happened to the salts": [
    "Quid salibus accidit"
  ],
  "virtuous ebony warhammer": [
    "Virtutis Bellimalleus Ebeni"
  ],
  "elven warhammer of the blaze": [
    "Alfus Bellimaleus Congflagrantis"
  ],
  "don't bother asking": [
    "Noli roga"
  ],
  "you're the ship captain": [
    "Esne nauarchus huius navis"
  ],
  "glass greatsword of fear": [
    "Spatha Vitrea Timoris"
  ],
  "elven greatsword of lethargy": [
    "Spatha Alfa Lethargiae"
  ],
  "joining the legion": [
    "Legionem Adiungens"
  ],
  "daedric sword of garnering": [
    "Gladius Daedricus Horti"
  ],
  "i have the horn of jurgen windcaller": [
    "Habeo Cornu Jurgenis Ventivocatoris"
  ],
  "i'll recover the helm": [
    "Galeam referam"
  ],
  "orcish greatsword of weariness": [
    "Orci Spatha Maioris Defatigationis"
  ],
  "i have the helm of winterhold": [
    "Galeam Hiemiterrae habeo"
  ],
  "thalmor boots": [
    "Visigothae Caligae"
  ],
  "imperial sword of frost": [
    "Gladius Romanus Gelidus"
  ],
  "glass war axe of animus": [
    "Bellisecuris Vitreus Animi"
  ],
  "beware the butcher": [
    "Cave Carnificem"
  ],
  "i don't have time for that now": [
    "Tempus illo non habeo"
  ],
  "observecombat": [
    "ObserveCombat"
  ],
  "do you know isabelle rolaine": [
    "Scisne Isabelle Rolaine"
  ],
  "what's your problem": [
    "Quid est difficultas tua"
  ],
  "draught of the defender": [
    "Decocta Defensoris"
  ],
  "dwarven shield of shock suppression": [
    "Dvemere Scutum Suppresionis Electricitatis"
  ],
  "imperial bracers of archery": [
    "Chirothecae Romanae Saggitationis"
  ],
  "hairfemaleorc01": [
    "HairFemaleOrc01"
  ],
  "the empire lost the reach before": [
    "Imperium prehensum antea amisit"
  ],
  "empire lost the reach before": [
    "Imperium prehensum antea amisit"
  ],
  "a guard said you own the dwemer museum": [
    "Custos dixit te Museion Dvemere possidere"
  ],
  "guard said you own the dwemer museum": [
    "Custos dixit te Museion Dvemere possidere"
  ],
  "maybe this will help your memory": [
    "Fortasse hoc memoriam tuam adiuvet"
  ],
  "hairmalenord6": [
    "HairMaleNord6"
  ],
  "are you going to tell me, or am i going to have to beat it out of you": [
    "Dicesne mihi, vel opus mihi erit id e te verberare",
    "Mihi dicebis, vel debebo information e te verberare"
  ],
  "ebony sword of the inferno": [
    "Gladius Ebeni Conflagrationis"
  ],
  "glass battleaxe of devouring": [
    "Bellisecuris Vitreus Consumendi"
  ],
  "tell me about the orc": [
    "Dic mihi de Orco"
  ],
  "she's already dead": [
    "Illa est already dead"
  ],
  "i bring news of thorald": [
    "Nuntium de Thoralde fero"
  ],
  "i need your help with something": [
    "Auxilium tuum cum aliquo quaero"
  ],
  "luaffyn": [
    "Luaffyn"
  ],
  "hairmaleorc16": [
    "HairMaleOrc16"
  ],
  "frorkmar banner-torn": [
    "Frorkmar Vexilli-Artuatus"
  ],
  "how about another time": [
    "Quid de alio tempore"
  ],
  "dialoguecidhnamineduachodvan02": [
    "DialogueCidhnaMineDuachOdvan02"
  ],
  "banded iron shield of dwindling fire": [
    "Ferri Adligati Scutum Minoris Ignis"
  ],
  "i saw helgi's ghost": [
    "Phantasmam Helgi vidi"
  ],
  "the black arrow, v1": [
    "Sagitta Atra, I"
  ],
  "black arrow, v1": [
    "Sagitta Atra, I"
  ],
  "found this bust of the gray fox": [
    "Inveni statuam Vulpis Cani"
  ],
  "worth anything": [
    "Valetne quid"
  ],
  "you'd better do what i want, or else. (intimidate)": [
    "Id quod volo facere debes, vel... (Intimida)"
  ],
  "scaled horn armor": [
    "Squamata Lorica Cornuta"
  ],
  "sounds good, i'll follow you": [
    "Bene mihi videtur, te sequemur"
  ],
  "i was told to give the message directly to the jarl": [
    "Audivi opus mihi fuisse nuntium recte ad Comitem dare"
  ],
  "i'm the best": [
    "Optimus sum"
  ],
  "elixir of resist cold": [
    "Commixtum Resistendi Frigorem"
  ],
  "sounds like more then just \"bad blood.\"": [
    "Mihi videtur plus quam modo \"sanguinem malam\" esse"
  ],
  "do you have a plan": [
    "Habesne consilium"
  ],
  "daedric helmet of peerless alchemy": [
    "Galea Daedrica Optimae Alchemiae"
  ],
  "do you own this smelter": [
    "Esne possessor huius auricoctoris"
  ],
  "i'm ready to enter the star": [
    "Paratus sum Stellam intrare"
  ],
  "mauhulakh's longhouse": [
    "Mauhulakh's Longhouse"
  ],
  "krag": [
    "Krag"
  ],
  "dwarven war axe of freezing": [
    "Dvemeris Bellisecuris Gelidus"
  ],
  "steel warhammer of frost": [
    "Bellimalleus Adamanteus Gelidus"
  ],
  "daynas valen's notes": [
    "Adnotationes Daynas Valenis"
  ],
  "second letter from linwe": [
    "Epistula Secunda Linvis"
  ],
  "old people race": [
    "Old People Race"
  ],
  "hairlinemaledarkelf05": [
    "HairLineMaleDarkElf05"
  ],
  "orcish bow of soul snares": [
    "Orci Arcus Animicapti"
  ],
  "some of your friends feel you're troubled": [
    "Nonulli amicorum tuorum putant te insanum esse"
  ],
  "ebony warhammer of thunderbolts": [
    "Bellimalleus Ebeni Fulminum"
  ],
  "blue palace guard for da02": [
    "Blue Palace Guard for DA02"
  ],
  "copper and moonstone circlet": [
    "Cupri et Lunalapidis Circulum"
  ],
  "your secret is safe with me": [
    "Sectretum tuum tutum mecum est"
  ],
  "crime faction so crimes against created actors are registered": [
    "Crime Faction so crimes against created actors are registered"
  ],
  "broken iron mace head": [
    "Fractus Ferri Maca Caput"
  ],
  "i'd rather not have company right now": [
    "Malim amicos mecum nunc non habere"
  ],
  "darkelfskin01": [
    "DarkElfSkin01"
  ],
  "solitude stables": [
    "Solitudinis Catabulum"
  ],
  "i have a letter for you": [
    "Epistulam tibi habeo"
  ],
  "hallowed elven war axe": [
    "Consacratus Alfus Bellisecuris"
  ],
  "words and philosophy": [
    "Verba et Philosophia"
  ],
  "we have evidence of necromancy, and found his amulet": [
    "Evidentiam necromantiae habemus, et monile eius invenimus"
  ],
  "hairmaledremora01": [
    "HairMaleDremora01"
  ],
  "scroll of conjure frost atronach": [
    "Codex Vocandi Atronachum Glaciei"
  ],
  "choose an item to improve": [
    "Elige rem meliorem faciendam"
  ],
  "daedric helmet of eminent alchemy": [
    "Galea Daedrica Alchemiae Eminentis"
  ],
  "korir": [
    "Korir"
  ],
  "just kill her and get it over with": [
    "Modo eam neca et finem eius fac"
  ],
  "eye of sabre cat": [
    "Oculus Felis Mucronis"
  ],
  "what's so bad about the dragonrend shout": [
    "Quid est tam malum de Clamatione Draconicaesis"
  ],
  "hairlinefemalenord03": [
    "HairLineFemaleNord03"
  ],
  "honed ancient nord sword of cold": [
    "Melior Germanicus Gladius Antiquus Frigidis"
  ],
  "hairfemalenord01": [
    "HairFemaleNord01"
  ],
  "broken steel warhammer head": [
    "Fractus Bellimalleus Adamanteus Caput"
  ],
  "maleeyeshumanlightgrey": [
    "MaleEyesHumanLightGrey"
  ],
  "marksmalehumanoid10rightgashr": [
    "MarksMaleHumanoid10RightGashR"
  ],
  "femalebrowshuman01": [
    "FemaleBrowsHuman01"
  ],
  "i made a mistake": [
    "Erravi"
  ],
  "i want to be a stormcloak": [
    "Sturmmantelum esse volo"
  ],
  "the crown belongs to you": [
    "Corona tua est"
  ],
  "crown belongs to you": [
    "Corona tua est"
  ],
  "who died": [
    "Quis mortuus est"
  ],
  "shield-brother": [
    "Shield-Brother"
  ],
  "ebony warhammer of winter": [
    "Bellimalleus Ebeni Hiemis"
  ],
  "glass dagger of immobilizing": [
    "Pugio Vitreus Immobilitatis"
  ],
  "drunk cultist": [
    "Secutor Ebrius"
  ],
  "i'm his bodyguard": [
    "Somatophylax eius sum"
  ],
  "mess with him, you mess with me": [
    "Eum oppugnare est me oppugnare"
  ],
  "he has a terrible disease": [
    "Morbum horribilem habat"
  ],
  "it's very contagious": [
    "Contagiosissimus est"
  ],
  "you aren't afraid to die": [
    "Mori non times"
  ],
  "fellglow keep ritual chamber": [
    "Atrium Rituale Obsucriluminis Castelli"
  ],
  "thieves guild dialogue scene 05": [
    "Sodalitatis Furum Dialogue Scene 05"
  ],
  "you said your son is in old hroldan": [
    "Dixisti filium tuum Hroldan Vetere esse"
  ],
  "iron gauntlets of major archery": [
    "Ferri Manicae Maioris Sagittationis"
  ],
  "tell me about the city's districts": [
    "Dic mihi de partibus urbis"
  ],
  "what will it take to end the war": [
    "Quomodo finem facere possumus bello"
  ],
  "how did you become the jarl of eastmarch": [
    "Quomodo factus es Comes Eurimarcis"
  ],
  "imperial bow of chills": [
    "Imperialis Arcus Frigidis"
  ],
  "morthal thaumaturgist's hut scene 1": [
    "Morthal Thaumaturgist's Hut Scene 1"
  ],
  "journeyman tongs": [
    "Journeyman Tongs"
  ],
  "reverent hunting bow": [
    "Reverens Arcus Venandi"
  ],
  "what's the city's history": [
    "Quid est historia urbis"
  ],
  "legate quentin cipius": [
    "Legatus Quintus Cipius"
  ],
  "why is the keep called dragonsreach": [
    "Quamobrem nomen 'Draconicarcer' accepit castellum"
  ],
  "aretino residence key": [
    "Clavis ad Domum Aretinonis"
  ],
  "what threats does the jarl face": [
    "Quae comminationes Comes minantur"
  ],
  "what are the duties of a court wizard": [
    "Quae sunt munera Thaumatorgi aulae"
  ],
  "general tullius said you'd get me outfitted": [
    "Imperator Tullius dixit te mihi arma daturum esse"
  ],
  "you're a priestess of kynareth": [
    "Esne flamina Kynarethis"
  ],
  "has the stormcloak uprising affected your duties": [
    "Affecitne res novae Sturmmantelorum munera tua"
  ],
  "iron shield of dwindling shock": [
    "Ferri Scutum Electricitatis Diminuentis"
  ],
  "the scorched hammer": [
    "Malleus Crematus"
  ],
  "scorched hammer": [
    "Malleus Crematus"
  ],
  "so we need to deal with malyn somehow": [
    "Ergo Malyn aliquo modo vincere debemus"
  ],
  "i don't like where this is going": [
    "Mihi non placet quo hoc it"
  ],
  "that's far beyond my level of skill": [
    "Valde supra arte mea est"
  ],
  "i found this bow": [
    "Hunc arcum inveni"
  ],
  "is it yours": [
    "Estne tuus"
  ],
  "nchuand-zel quarters": [
    "Nchuand-Zel Conclavia"
  ],
  "virulent poison": [
    "Virulens Venenum"
  ],
  "have you had trouble with the locals": [
    "Diffultatesne cum incolis habuisti"
  ],
  "no time to lose then": [
    "Nullum tempus perdendum est"
  ],
  "sorli's house": [
    "Sorli Domus"
  ],
  "playercastselfspell": [
    "PlayerCastSelfSpell"
  ],
  "voicepowerstartlong": [
    "VoicePowerStartLong"
  ],
  "dwarven battleaxe of shocks": [
    "Dvemeris Bellisecuris Electricitatis"
  ],
  "you know him": [
    "Eum novisti"
  ],
  "riften scripted scene north gate": [
    "Hiati Scripted Scene North Gate"
  ],
  "there's no way you'll come peacefully": [
    "Nullone modo sine pugna venies"
  ],
  "kolskeggr mine guards": [
    "Kolskeggr Mine Guards"
  ],
  "i'm looking for a courier": [
    "Nuntium quaero"
  ],
  "and what of karliah": [
    "Et quid de Karlia"
  ],
  "flawless amethyst": [
    "Perfectus Amethystus"
  ],
  "iron warhammer of burning": [
    "Bellimalleus Ferratus Cremandi"
  ],
  "what is an elder scroll": [
    "Quid est Elder Scroll"
  ],
  "corpse note": [
    "Episulae Necis"
  ],
  "silver-blood mercenary": [
    "Mercenarius Argenti-Sanguinis"
  ],
  "i found this ring, but no sign of her": [
    "Hunc anulum inveni, sed nullum signum eius inveni"
  ],
  "i haven't dealt with him yet": [
    "Cum eo nondum negotium egi"
  ],
  "have you noticed anything strange about the body": [
    "Aliquidne mirabile de corpore eius vidisti"
  ],
  "aegisbane": [
    "Aegisbane"
  ],
  "orcish sword of shocks": [
    "Orci Gladius Electricitatis"
  ],
  "aimed": [
    "Intentum"
  ],
  "i want to fight alain dufont": [
    "Volo pugnare contra Alanum Dufontem"
  ],
  "dialoguemarkarthtreasuryhousethonarbetridscene02": [
    "DialogueMarkarthTreasuryHouseThonarBetridScene02"
  ],
  "summon spectral assassin": [
    "Summon Spectral Assassin"
  ],
  "greybeard's hood": [
    "Canabarbae Capucium"
  ],
  "daedric shield of frost suppression": [
    "Scutum Daedricum Contra Glaciem"
  ],
  "imperial helmet of major alteration": [
    "Romana Galea Maioris Mutationis"
  ],
  "elenwen": [
    "Elenven"
  ],
  "yes, the coffin": [
    "Sic, sarcophagus"
  ],
  "it's perfect": [
    "Optimus est"
  ],
  "ancient nord greatsword": [
    "Spatha Antiqua Germanica"
  ],
  "bthardamz lower district": [
    "Bthardamz Regio Inferior"
  ],
  "ebony dagger of annihilating": [
    "Pugio Ebeni Annihilationis"
  ],
  "sun's dawn": [
    "Februarius"
  ],
  "aftershock": [
    "Post Concussum"
  ],
  "glass battleaxe of dread": [
    "Bellisecuris Vitreus Timoris"
  ],
  "i need to find out where alduin went": [
    "Opus est mihi scire quo iverit Aldiunus"
  ],
  "i was expecting someone... taller": [
    "Aliquem... altiorem... expectavi"
  ],
  "j'zhar": [
    "J'zhar"
  ],
  "dragonscale helmet of eminent magicka": [
    "Draconisquamata Galea Eminentis Magicae"
  ],
  "hroggar": [
    "Hroggar"
  ],
  "daedric boots of the mammoth": [
    "Caligae Daedricae Elephantis"
  ],
  "adisla": [
    "Adisla"
  ],
  "why is the temple closed": [
    "Cur clausum est templum"
  ],
  "gavros plinius": [
    "Gavros Plinius"
  ],
  "orcish warhammer of absorption": [
    "Orci Bellimalleus Absorbendi"
  ],
  "it was remarkable... as if i were really there": [
    "Mirabilis erat... ut illic fuissem"
  ],
  "hellos to player from wikill04thankfaction": [
    "Hellos to player from WIKill04ThankFaction"
  ],
  "do you like working at the riften fishery": [
    "Tibine placet in Piscatorium Hiati laborare"
  ],
  "black briar manor": [
    "Black Briar Manor"
  ],
  "i'm sorry, i can't help you": [
    "Me paenitet, te adiuvare non possum"
  ],
  "mallus maccius": [
    "Mallus Maccius"
  ],
  "orcish sword of gleaning": [
    "Orci Gladius Saturni"
  ],
  "so what is the truth, then": [
    "Ergo, quid est veritas"
  ],
  "hairlinemalenord11": [
    "HairLineMaleNord11"
  ],
  "plant the balmora blue": [
    "Depone Caeruleam Balmoram"
  ],
  "report to ulfric stormcloak": [
    "Refer te ad Vercingetorigem Temepstatamiculo"
  ],
  "why did you arrest the nord militia": [
    "Cur comprehendisti militiam Germanorum"
  ],
  "master alteration": [
    "Dominus Mutationis"
  ],
  "ingun's supply chest key": [
    "Clavis ad Arcam Ingredientiorum Ingunis"
  ],
  "whiterun stables scene 2": [
    "Whiterun Stables Scene 2"
  ],
  "hafnar ice-fist": [
    "Hafnar Glaci-Pugna"
  ],
  "do you know anything about the staff of magnus": [
    "Scisne quid de Rude Magno"
  ],
  "hairmaleredguard7": [
    "HairMaleRedguard7"
  ],
  "glass gauntlets of major smithing": [
    "Vitreae Manicae Maioris Faciendi"
  ],
  "remanada": [
    "Remanada"
  ],
  "dwarven mace of diminishing": [
    "Dvemeris Maca Diminuendi"
  ],
  "dialoguemarkartharnleiflisbetimedhnainscene01": [
    "DialogueMarkarthArnleifLisbetImedhnainScene01"
  ],
  "sanguine cultist": [
    "Fautor Sanguinis"
  ],
  "how is dawnstar faring in the war": [
    "Quomodo sunt res Luciferi in bello"
  ],
  "bloody rags": [
    "Panni Sanguinosi"
  ],
  "necromantic staff": [
    "Rudis Necromanticus"
  ],
  "ebony greatsword of garnering": [
    "Spatha Ebeni Horti"
  ],
  "make these your default settings": [
    "Fac haec modum solitum"
  ],
  "i'd like a drink": [
    "Poculum velim"
  ],
  "do you work here all day": [
    "Laborasne hic totum diem"
  ],
  "elven gilded armor of eminent health": [
    "Alfa Lorica Aurata Eminentis Salutis"
  ],
  "steel helmet of minor alchemy": [
    "Adamantea Galea Minoris Alchemiae"
  ],
  "i don't know what to say": [
    "Nescio quid dicam"
  ],
  "statue to meridia": [
    "Statua Cereris"
  ],
  "steel war axe of weariness": [
    "Bellisecuris Adamanteus Maioris Defatigationis"
  ],
  "party boots": [
    "Festi Caligae"
  ],
  "then let's get to it": [
    "Ergo eamus"
  ],
  "iron dagger of ice": [
    "Pugio Ferri Gelidus"
  ],
  "i'm not anyone's champion": [
    "Vindex nemini sum"
  ],
  "find someone else": [
    "Alterum inveni"
  ],
  "skin tone": [
    "Color Cutis"
  ],
  "what kind of things": [
    "Quales res"
  ],
  "thalmor justiciar": [
    "Visigothi Justiciar"
  ],
  "\"dimwits\"": [
    "\"Caudices\""
  ],
  "stormcloak field commander": [
    "Sturmmantelorum Legatus Agri"
  ],
  "thug sent by louis letrush": [
    "Thug sent by Louis Letrush"
  ],
  "tg00 arrest scene quest": [
    "TG00 Arrest Scene Quest"
  ],
  "i don't relish being a daedra's puppet": [
    "Nolo esse pupam Daedrae esse"
  ],
  "although much of skyrim is cold and unforgiving, windhelm is the snowiest city in the province": [
    "Etsi multum Caelifinis est frigidum, Ventigalea est urbs nivosissima provinciae"
  ],
  "how does azura's star work": [
    "Quomodo fungitur Stella Azurae"
  ],
  "daedric sword of freezing": [
    "Gladius Daedricus Gelidus"
  ],
  "do you want to move your marker or remove it": [
    "Visne signum movere vel removere"
  ],
  "do you own the mine": [
    "Esne possessor metalli"
  ],
  "imperial bracers of alteration": [
    "Chirothecae Romanae Mutationis"
  ],
  "i think you should sell the mine": [
    "Credo tibi metallum vendendum esse"
  ],
  "dialoguedushnikhyalminescene01": [
    "DialogueDushnikhYalMineScene01"
  ],
  "[inaccessible]": [
    "[INACCESSIBILIS]"
  ],
  "draught of destruction": [
    "Decocta Destructionis"
  ],
  "rhorlak": [
    "Rhorlak"
  ],
  "temple of kynareth": [
    "Templum Kynarethis"
  ],
  "glass shield of frost suppression": [
    "Vitreum Scutum Contra Glaciem"
  ],
  "firebolt storm alduin death": [
    "Firebolt Storm Alduin Death"
  ],
  "ebony warhammer of the blaze": [
    "Bellimalleus Ebeni Congflagrantis"
  ],
  "elven warhammer of malediction": [
    "Alfus Bellimaleus Maledictionis"
  ],
  "stromm": [
    "Stromm"
  ],
  "who's in charge around here": [
    "Quis hic praeest"
  ],
  "i was looking for a drink": [
    "Poculum quaesivi"
  ],
  "or some work": [
    "Vel laborem"
  ],
  "dragonplate armor of the eminent knight": [
    "Draconilaminata Lorica Eminentis Equitis"
  ],
  "glass greatsword of lethargy": [
    "Spatha Vitrea Lethargiae"
  ],
  "mysterium xarxes": [
    "Mysterium Xarxes"
  ],
  "you cannot cast this spell while casting another": [
    "Hac magica uti non potes dum alia uteris"
  ],
  "orcish warhammer of harvesting": [
    "Orci Bellimalleus Saturni"
  ],
  "hairlinemaleorc02": [
    "HairLineMaleOrc02"
  ],
  "draugr missile": [
    "Draugr Missile"
  ],
  "what sort of message": [
    "Quale nuntium"
  ],
  "angarvunde catacombs": [
    "Angarvundis Catacumba"
  ],
  "lasting magicka poison": [
    "Persistens Venenum Magicae"
  ],
  "scroll of frenzy": [
    "Codex Insanitatis"
  ],
  "what can you tell me about the sleeping tree": [
    "Quid mihi dicere potes de Arbore Dormiente"
  ],
  "ranmir's house": [
    "Ranmir's House"
  ],
  "resurrect dragon scene": [
    "Resurrect dragon scene"
  ],
  "rolff stone-fist": [
    "Rolff Saxi-Pugna"
  ],
  "shrine to clavicus vile": [
    "Ara Clavici"
  ],
  "elven sword of animus": [
    "Alfus Gladius Animi"
  ],
  "bolli": [
    "Bolli"
  ],
  "elven bow": [
    "Alfus Arcus"
  ],
  "orcish greatsword of binding": [
    "Orci Spatha Deligandi"
  ],
  "shavari": [
    "Shavari"
  ],
  "leather helmet of restoration": [
    "Cutis Galea Recreandi"
  ],
  "uttering hills cave": [
    "Collium Sussurantium Spelunca"
  ],
  "iron dagger of torpor": [
    "Pugio Ferri Torporis"
  ],
  "it wasn't really a victory, since alduin escaped": [
    "Victoria vera non erat, quod Alduinus effugit"
  ],
  "ebony war axe of winter": [
    "Bellisecuris Ebeni Hiemis"
  ],
  "ebony boots of brawn": [
    "Caligae Ebeni Fortitudinis"
  ],
  "the blades": [
    "Enses"
  ],
  "blades": [
    "Enses",
    "Blades"
  ],
  "tg08bhellos": [
    "TG08BHellos"
  ],
  "new spell learned": [
    "Nova Magica Discita"
  ],
  "daedric mace of despair": [
    "Maca Daedrica Desponsionis"
  ],
  "torture tools": [
    "Instrumenta Tormenti"
  ],
  "ustengrav depths": [
    "Ustengravis Ima"
  ],
  "killing a guy who asked questions": [
    "Necare hominem qui nimium rogat"
  ],
  "glass war axe of ice": [
    "Bellisecuris Vitreus Gelidus"
  ],
  "i'd like to know more about the college": [
    "Velim plus de Collegio scire"
  ],
  "j'darr": [
    "J'darr"
  ],
  "west, in the plains over the mountain": [
    "Ad occidentem, in campo super montes"
  ],
  "fire storm 25": [
    "Fire Storm 25"
  ],
  "i have hjalti's sword": [
    "Gladium Hjalti habeo"
  ],
  "may i ask why you're in mourning": [
    "Possumne rogare cur lugeas"
  ],
  "orcskin05": [
    "OrcSkin05"
  ],
  "do you expect me to take your word for that": [
    "Visne me verba tua pro illo accipere"
  ],
  "frothar": [
    "Frothar"
  ],
  "what are you selling": [
    "Quid vendis"
  ],
  "sure, i'll take one": [
    "Sic, unum capiam"
  ],
  "(pay 10 gold)": [
    "(Solve X auri)"
  ],
  "drevis neloren services": [
    "Drevis Neloren Services"
  ],
  "orcish sword of harrowing": [
    "Orci Gladius Occationis"
  ],
  "glass dagger of harrowing": [
    "Pugio Vitreus Occationis"
  ],
  "rank = which mission is the player's active mission": [
    "Rank = which mission is the player's active mission"
  ],
  "what caused the great collapse": [
    "Quid Lapsum Magnum effecit"
  ],
  "i have all the things needed to repair it": [
    "Omnia habeo ut illum reficere possim"
  ],
  "meet with whoever took the horn": [
    "Inveni quemquam cornu furata est"
  ],
  "ennodius papius is dead": [
    "Ennodius Papius mortuus est"
  ],
  "do you know where we can find the torpor": [
    "Scisne ubi Torporem invenire possimus"
  ],
  "delacourt": [
    "Delacourt"
  ],
  "cage key": [
    "Clavis ad Carcerem"
  ],
  "nord hero bow": [
    "Heroos Germanici Arcus"
  ],
  "hairlinefemaledarkelf02": [
    "HairLineFemaleDarkElf02"
  ],
  "i lost madesi's ring": [
    "Anulum Madesi perdidi"
  ],
  "no soul gem large enough": [
    "Nullam gemmanimam satis magnam habes"
  ],
  "find 20 nightshade for ingun black-briar (<global=ffr04nightshadecount>/<global=ffr04nightshadetotal>)": [
    "Inveni XX Trychnos pro Ingun Atri-Dumo (<Global=FFR04NightshadeCount>/<Global=FFR04NightshadeTotal>)"
  ],
  "hide boots of resist fire": [
    "Renonis Caligae Contra Vim Ignis"
  ],
  "\"seen my coming\"": [
    "\"Me venire vidisti\""
  ],
  "ranmir": [
    "Ranmir"
  ],
  "main menu": [
    "Tabula Prima (Main Menu)"
  ],
  "what will you do now that nurelion is gone": [
    "Quid mihi dabis quod Nurelion nunc abest"
  ],
  "i represent the dark": [
    "Pars Fraternit"
  ],
  "elven mace of leeching": [
    "Maca Alfa Sanguisugae"
  ],
  "skin box": [
    "Riscus Pellis"
  ],
  "falmer blood elixir": [
    "Falmeris Sanguis Commixtum"
  ],
  "retrieve <alias=itemtoget> from <alias=mark> in <alias=city>": [
    "Refer <Alias=ItemToGet> a <Alias=Mark> in <Alias=City>"
  ],
  "coin purse": [
    "Saccus Nummorum"
  ],
  "i have it": [
    "Id habeo"
  ],
  "your weapons and armor are impressive": [
    "Arma loricaque tua formidulosi sunt"
  ],
  "thorygg sun-killer": [
    "Thorygg Sol-Necator"
  ],
  "what will i face in the pilgrim's path": [
    "Quid in Via Peregrinorum videbo"
  ],
  "key to proudspire manor": [
    "Clavis ad Villam Superbispiram"
  ],
  "vigdis salvius": [
    "Vigdis Salvius"
  ],
  "maleeyeshumanbrownblindright": [
    "MaleEyesHumanBrownBlindRight"
  ],
  "i didn't come here to debate philosophy with you": [
    "Hic non veni ut philosophiam tecum certem"
  ],
  "just tell me who needs to die": [
    "Modo dic mihi quid mori debere"
  ],
  "transaction value: %d gold": [
    "Pretium: %d Auri"
  ],
  "vendor only has %d gold": [
    "Venditor solum %d auri habet"
  ],
  "steel warhammer of draining": [
    "Bellimalleus Adamanteus Hauriendi"
  ],
  "storm call effect self alduindeath": [
    "Storm Call Effect Self AlduinDeath"
  ],
  "elven warhammer of torpor": [
    "Alfus Bellimaleus Torporis"
  ],
  "keerava": [
    "Keerava"
  ],
  "has the war affected you": [
    "Bellumne te affecit"
  ],
  "virtuous daedric dagger": [
    "Virtutis Pugio Daedricus"
  ],
  "bendt": [
    "Bendt"
  ],
  "what can you tell me about kynesgrove": [
    "Quid mihi dicere potes de Kynesnemore"
  ],
  "are things not going well for you": [
    "Suntne res bene tibi"
  ],
  "largashbur orcs": [
    "Largashbur Orcs"
  ],
  "why did you become a student at the college": [
    "Cur Collegium adiunxisti"
  ],
  "elven bow of expelling": [
    "Arcus Alfus Expellendi"
  ],
  "whiterun imperial camp": [
    "Albacursus Castra Romana"
  ],
  "glass dagger of shocks": [
    "Pugio Vitreus Electricitatis"
  ],
  "why do nords have a problem with the college": [
    "Quid est difficultas inter Germanos et Collegium"
  ],
  "why do you fight for the empire": [
    "Cur pro Imperio pugnas"
  ],
  "thonnir says alva was the last person to see laelette... alive": [
    "Thonnir dicit Alvam ultimam hominem esse qui Laelettem vivam vidit"
  ],
  "of course i can": [
    "Certe possum"
  ],
  "mistwatch key": [
    "Clavis ad Nebulavisum"
  ],
  "custom destination": [
    "Finem Electum"
  ],
  "dragonslayer": [
    "Draconecator"
  ],
  "which are the best illusion spells": [
    "Quae sunt optimae magicae Inlusionis"
  ],
  "attend the burning of king olaf": [
    "Specta Ustionem Regis Olafis"
  ],
  "ebony warhammer of dread": [
    "Bellimalleus Ebeni Timoris"
  ],
  "hallowed glass dagger": [
    "Consacratus Pugio Vitreus"
  ],
  "potion of the berserker": [
    "Potio Berserkris"
  ],
  "how old is that stone bridge near the town": [
    "Quod annos stetit ille pons prope oppidum"
  ],
  "the lord: damage resist": [
    "Dominus: Vulneri Resiste"
  ],
  "lord: damage resist": [
    "Dominus: Vulneri Resiste"
  ],
  "they were trying to kill us": [
    "Nos necare conabantur"
  ],
  "elven warhammer of lightning": [
    "Alfus Bellimaleus Fulminis"
  ],
  "[locked - broken]": [
    "[Clausa - Fracta]"
  ],
  "tell me about peryite": [
    "Narra mihi de Peryito"
  ],
  "the stormcloaks were there ahead of us": [
    "Sturmmanteli illic erant ante nos"
  ],
  "stormcloaks were there ahead of us": [
    "Sturmmanteli illic erant ante nos"
  ],
  "temp - special effects": [
    "TEMP - Special Effects"
  ],
  "i told him to wait in whiterun": [
    "Ei imperavit ut Albacursus maneret"
  ],
  "dismay - ru": [
    "Dismay - Ru"
  ],
  "tell me about the greybeards": [
    "Dic mihi de Canabarbis"
  ],
  "anything else before i set off": [
    "Aliquid alium ante exeam"
  ],
  "has anyone ever explored the barrow": [
    "Investigabitne quis pabillum"
  ],
  "hairmaleelder4": [
    "HairMaleElder4"
  ],
  "i live only to serve": [
    "Vivo serviendi causa"
  ],
  "sounds like loyalty is important to you": [
    "Mihi videtur loyalty is important to you"
  ],
  "are you involved in something dangerous": [
    "Esne pars cuius periculosi"
  ],
  "y-sensitivity": [
    "Y-Sensitivity"
  ],
  "ebony gauntlets of major sure grip": [
    "Ebeni Manicae Maioris Dexteritatis"
  ],
  "glass dagger of thunderbolts": [
    "Pugio Vitreus Fulminum"
  ],
  "you said you were the jarl once": [
    "Dixisti te semel Comitem fuisse"
  ],
  "riverwood trader services": [
    "Riverwood Trader Services"
  ],
  "i've had my fair share of killing today": [
    "Satis necandi hodie iam habui"
  ],
  "i had to kill her": [
    "Opus mihi erat eam necare"
  ],
  "she attacked me": [
    "Me oppugnavit"
  ],
  "iron dagger of dismay": [
    "Pugio Ferri Timoris"
  ],
  "house of mjoll the lioness": [
    "House of Mjoll the Lioness"
  ],
  "know of an old guy hiding out in riften, name of esbern": [
    "Scisne de senece Hiati latente, nonime Esbern"
  ],
  "ebony helmet of waterbreathing": [
    "Ebeni Galea Respirandi Sub Aqua"
  ],
  "valie": [
    "Valie"
  ],
  "humanbeard11": [
    "HumanBeard11"
  ],
  "glass shield": [
    "Vitreum Scutum"
  ],
  "is it worth anything": [
    "Habetne pretium"
  ],
  "companionshit": [
    "CompanionsHit"
  ],
  "what kind of agreement did you have in mind": [
    "Qualem pactam in animo habes"
  ],
  "the oblivion crisis": [
    "Crisis Oblivionis"
  ],
  "oblivion crisis": [
    "Crisis Oblivionis"
  ],
  "report to <alias=whiteruncapitalimperialfieldco>": [
    "Refer te ad <Alias=WhiterunCapitalImperialFieldCO>"
  ],
  "protect yamarz": [
    "Tege Yamarz"
  ],
  "dragonscale shield of fire abatement": [
    "Scutum Draconisquamatum Resistendi Ignis"
  ],
  "this door leads nowhere": [
    "Ianua ad nullum locum ducit"
  ],
  "i'm finished wasting my time talking to you": [
    "Non iam tempus in te consumam"
  ],
  "orcish battleaxe of arcing": [
    "Orci Bellisecuris Electricitatis"
  ],
  "steel helmet of major alteration": [
    "Adamantea Galea Maioris Mutationis"
  ],
  "ms01omluahhathrasiltrigger": [
    "MS01OmluahHathrasilTrigger"
  ],
  "thorald gray-mane": [
    "Thorald Juba-Cana"
  ],
  "what is it you do here": [
    "Quid hic facis"
  ],
  "does the arch-mage often ask for advice": [
    "Saepene Archimagus consilium tuum rogat"
  ],
  "give me the latest gossip": [
    "Da mihi rumores novissimas"
  ],
  "not yet, but i will": [
    "Nondum, sed id faciam"
  ],
  "not everything is a competition, you know": [
    "Omnia certamina non sunt, ut scis"
  ],
  "who is it": [
    "Quis est"
  ],
  "lunar steel mace": [
    "Anancitis Lunaris Maca"
  ],
  "find some equipment": [
    "Inveni Arma"
  ],
  "sorry, i'm supposed to be meeting someone here": [
    "Ignosce, alicui hic offendere debeo"
  ],
  "you're coming with me": [
    "Mecum venies"
  ],
  "draugr death overlord": [
    "Draugr Princeps Mortuorum"
  ],
  "the eye of magnus": [
    "Oculus Magni"
  ],
  "portcullis": [
    "Portcullis"
  ],
  "dialoguemarkarthmarketscenehognidegaine2": [
    "DialogueMarkarthMarketSceneHogniDegaine2"
  ],
  "hairfemalenord04": [
    "HairFemaleNord04"
  ],
  "dwarven armor of the eminent knight": [
    "Dvemeris Lorica Eminentis Equitis"
  ],
  "silver lining": [
    "Subsumentum Argenteum"
  ],
  "volunruud elder's cairn": [
    "Volunruud Maioris Monolithos"
  ],
  "fastred": [
    "Fastred"
  ],
  "orcish warhammer of harrowing": [
    "Orci Bellimalleus Occationis"
  ],
  "i did what i thought was right": [
    "Feci id quod decorum mihi videbatur"
  ],
  "dialoguemarkarthhagscurescenemuiribothela01": [
    "DialogueMarkarthHagsCureSceneMuiriBothela01"
  ],
  "quest items cannot be removed from your inventory": [
    "Non potes res Muneribus removere ab inventario"
  ],
  "i was trying to reach a fair agreement": [
    "Volui ad consensum advenire"
  ],
  "orcish bow of torpor": [
    "Orci Arcus Torporis"
  ],
  "sovngarde: a reexamination": [
    "Valhalla: Exploratio Nova"
  ],
  "small antlers": [
    "Cornua Parva"
  ],
  "tell me your side of the story about sibbi": [
    "Dic mihi partem tuam fabulae de Sibbi"
  ],
  "fort dunstad commander's quarters": [
    "Principia Castelli Dunstad"
  ],
  "power armor training is required to equip this item": [
    "Power armor training is required to equip this item"
  ],
  "orcish helmet of eminent archery": [
    "Orci Galea Eminentis Saggitationis"
  ],
  "i take it you protect the jarl": [
    "Credo te Comitem tegere"
  ],
  "elixir of pickpocketing": [
    "Commixtum Furandi"
  ],
  "no idea": [
    "Omnino nescio"
  ],
  "are you doing well, serving as jarl": [
    "Benene agis, ut Comes serviens"
  ],
  "breathing water": [
    "Aquam Respirans"
  ],
  "yngvar the singer": [
    "Yngvar Poeta"
  ],
  "dialoguegenericfireweapon": [
    "DialogueGenericFireWeapon"
  ],
  "miner's clothes": [
    "Cuniculi Vestimenta"
  ],
  "imperial shield": [
    "Romanum Scutum"
  ],
  "steel battleaxe of frost": [
    "Bellisecuris Adamanteus Gelidus"
  ],
  "are you at odds with them": [
    "Esne contra eis pugnans"
  ],
  "you can not drop objects while in the air": [
    "Res depone non potes dum in aere"
  ],
  "creature faction": [
    "Creature Faction"
  ],
  "i've already been to the tomb": [
    "Sepulcrum iam petivi"
  ],
  "blessed steel war axe": [
    "Sanctificatus Bellisecuris Adamanteus"
  ],
  "ahjisi": [
    "Ahjisi"
  ],
  "imperial bracers of major lockpicking": [
    "Chirothecae Romanae Maioris Clavicarii"
  ],
  "dwarven battleaxe of despair": [
    "Dvemeris Bellisecuris Desponsionis"
  ],
  "elven shield": [
    "Alfum Scutum"
  ],
  "banded iron armor of the knight": [
    "Ferro Adligata Lorica Equitis"
  ],
  "i told you they'd come": [
    "Tibi dixi eos venturos esse"
  ],
  "what is this thing that's so important": [
    "Quid est haec res tanti momenti"
  ],
  "the night terrors and self loathing are gone": [
    "Terrores et odium pro se nunc absunt"
  ],
  "night terrors and self loathing are gone": [
    "Terrores et odium pro se nunc absunt"
  ],
  "iron mace of sparks": [
    "Ferri Maca Scintillarum"
  ],
  "orcish helmet of extreme alteration": [
    "Orci Galea Extremis Mutationis"
  ],
  "glass warhammer of freezing": [
    "Bellimaleus Vitreus Gelidus"
  ],
  "dragonplate helmet of extreme destruction": [
    "Draconilaminata Galea Extremis Destructionis"
  ],
  "orcish dagger of winnowing": [
    "Orci Pugio Ventagii"
  ],
  "hallowed dwarven battleaxe": [
    "Consacratus Dvemeris Bellisecuris"
  ],
  "i was asked to retrieve you from your vacation": [
    "Oratus sum ut te de otio tuo referam"
  ],
  "elven helmet of major magicka": [
    "Alfa Galea Maioris Magicae"
  ],
  "hold on, i need a minute": [
    "Exspecta, momentum postulo"
  ],
  "toggle weapon drawn": [
    "Elige Armum"
  ],
  "cleanse the focal points for magical energies around the college": [
    "Munda puncta focalia energiarum magicalium in Collegio"
  ],
  "pest poison": [
    "Pestilentiae Venenum"
  ],
  "testfurnitureuseidlelinkedrefnpc": [
    "TestFurnitureUseIdleLinkedRefNPC"
  ],
  "i'm here for the college": [
    "Hic sum pro Collegio"
  ],
  "tell me more about your people": [
    "Dic mihi plus de hominibus tuis"
  ],
  "holy daedric war axe": [
    "Sanctus Bellisecuris Daedricus"
  ],
  "why did you take the horn from ustengrav": [
    "Quem ad finem cornu ex Ustengrav extulisti"
  ],
  "time-wound": [
    "Vulnus-Temporis"
  ],
  "i've discovered the source of the skooma": [
    "Fontem skoomae inveni"
  ],
  "dialoguemarkarthkeepkitchenscene02": [
    "DialogueMarkarthKeepKitchenScene02"
  ],
  "curious silver mold": [
    "Mirum Argentei Fusile"
  ],
  "perhaps some other time": [
    "Fortasse alio tempore"
  ],
  "markarth wizards' quarters guards": [
    "Markarth Wizards' Quarters Guards"
  ],
  "humanbeard37": [
    "HumanBeard37"
  ],
  "tacitus sallustius": [
    "Tacitus Sallustius"
  ],
  "rusty mace": [
    "Maca Oxidata"
  ],
  "autosaving": [
    "Autotuens"
  ],
  "advanced armors": [
    "Loricae Provectae"
  ],
  "elven battleaxe of ice": [
    "Bellisecuris Alfus Gelidus"
  ],
  "and who says i won't": [
    "Et quis dixit me id non facturum esse"
  ],
  "steel shield of waning fire": [
    "Adamanteum Scutum Insenescentis Ignis"
  ],
  "dwarven sword of devouring": [
    "Gladius Dvemeris Consumendi"
  ],
  "takemetemptulliuscoat": [
    "TAKEMETempTulliusCoat"
  ],
  "what's the crystal for": [
    "Quid facit crystallum"
  ],
  "why are you fighting this war": [
    "Cur in hoc bello pugnas"
  ],
  "stripped items": [
    "Res Remotae"
  ],
  "i hear you lost fastred": [
    "Audivi te Fastred amittere"
  ],
  "i have a letter from faendal": [
    "Epistulam a Faendal habeo"
  ],
  "halldir's cairn": [
    "Atriumdiris Monolithos"
  ],
  "i'm not paying you a bribe": [
    "Tibi vectigal non solvam"
  ],
  "that sounds illegal": [
    "Nefas videtur"
  ],
  "talk to brynjolf about joining the thieves guild": [
    "Brynjolf Adloquere de Sodalitate Furum"
  ],
  "there, now you're safe": [
    "En, tuta es"
  ],
  "ebony dagger of terror": [
    "Pugio Ebeni Terroris"
  ],
  "armor smithing": [
    "Loricas Facere"
  ],
  "maleeyeshumandemon": [
    "MaleEyesHumanDemon"
  ],
  "dragonscale helmet of eminent alteration": [
    "Draconisquamata Galea Eminentis Mutationis"
  ],
  "frenzy poison": [
    "Venenum Insanitatis"
  ],
  "you are out of lockpicks": [
    "Clavicaria non habes"
  ],
  "i'd like to know more about winterhold's history": [
    "Volo plus scire historiae Hiemiterrae"
  ],
  "dcetalerttonormal": [
    "DCETAlertToNormal"
  ],
  "the burglary job": [
    "Munus \"Burglary\""
  ],
  "burglary job": [
    "Munus \"Burglary\""
  ],
  "he's done more": [
    "Plurimas res fecit"
  ],
  "where did this shrine come from": [
    "Unde venit haec ara"
  ],
  "we're done. let us serve... the potage le magnifique": [
    "Factum est. Dapinemus... Potage le Magnifique"
  ],
  "syndus": [
    "Syndus"
  ],
  "elven war axe of harrowing": [
    "Alfus Bellisecuris Occationis"
  ],
  "you're looking to cure yourself": [
    "Vis te curare"
  ],
  "elven bracers of major wielding": [
    "Chirothecae Alfae Maioris Tenendi"
  ],
  "reverent glass bow": [
    "Reverens Arcus Vitreus"
  ],
  "what has you so preoccupied": [
    "Cur tam sollicitus es"
  ],
  "invisible walk away": [
    "Invisible Walk Away"
  ],
  "you're pretty dedicated to the empire, then": [
    "Ergo, valde dedicatus Imperio es"
  ],
  "i'm looking for a cure": [
    "Remedium quaero"
  ],
  "leather boots of resist frost": [
    "Corii Caligae Contra Vim Gelidam"
  ],
  "relax, i'm not going to hurt you": [
    "Mollis esto, tibi non nocebo"
  ],
  "i'm sorry, egg brother, but i've been sent to kill you": [
    "Ignosce, frater ovae, sed hic missus sum ut te necam"
  ],
  "do you get a lot of business like that": [
    "Multumne negotium illo modo accipis"
  ],
  "leonara arius": [
    "Leonara Arius"
  ],
  "daedric warhammer of nullifying": [
    "Bellimalleus Daedricus Nullificandi"
  ],
  "how are your sales": [
    "Quid fit cum negotio"
  ],
  "darkelfmalelips04": [
    "DarkElfMaleLips04"
  ],
  "potema's skull": [
    "Ollaemae Calvaria"
  ],
  "save location full": [
    "Save location full"
  ],
  "disabling autosave": [
    "Disabling Autosave"
  ],
  "cliff racer": [
    "Cursor Rupium"
  ],
  "do you make anything here that will kill dragons": [
    "Facisne quid hic quod dracones necet"
  ],
  "you kept a skeever as a pet": [
    "Skeever ut delicium habuisti"
  ],
  "maleheadimperial": [
    "MaleHeadImperialis"
  ],
  "brief history of the empire, v1": [
    "Historia Brevis Imperii, I"
  ],
  "collect the vessels (<global=dunvalthumevesselcollectedcount>/3)": [
    "Collige Dogas (<Global=dunValthumeVesselCollectedCount>/3)"
  ],
  "i'm going to maven unless i leave here with frost": [
    "Ad Maven eo nisi e hoc loco cum Glaceie"
  ],
  "orcish helmet of major alteration": [
    "Orci Galea Maioris Mutationis"
  ],
  "liar's retreat cage key": [
    "Clavis ad Carcerem Refugiti Mentitoris"
  ],
  "thomas": [
    "Thomas"
  ],
  "hallowed glass sword": [
    "Consacratus Gladius Vitreus"
  ],
  "so what's your job here, melaran": [
    "Ergo, quid est labor hic, Melaran"
  ],
  "the empire holds only solitude": [
    "Imperium Solitudinem solum tenet"
  ],
  "empire holds only solitude": [
    "Imperium Solitudinem solum tenet"
  ],
  "tullius is in no position to make demands": [
    "Tullius nihil deposcere potest"
  ],
  "but you're talking right now": [
    "Sed nunc ipse loqueris"
  ],
  "you seem proud to be a bard": [
    "Superbus videris quod bardus es"
  ],
  "elisif seems to rely on you a great deal": [
    "Elisif tibi valde confidit"
  ],
  "scroll of hysteria": [
    "Codex Hysteriae"
  ],
  "on second thought, i'll walk": [
    "Ut iterum cogito, ambulabo"
  ],
  "so who was it": [
    "Ergo, quis erat"
  ],
  "who had the contract": [
    "Quis foedus habuit"
  ],
  "hide bracers of lockpicking": [
    "Chirothecae Cutis Clavicarii"
  ],
  "nightingale sentinel": [
    "Custos Philomelae"
  ],
  "what sort of business": [
    "Quale negotium"
  ],
  "ilas-tei's ring": [
    "Anulus Ilas-Tei"
  ],
  "so, what can we do": [
    "Ergo, quid facere possumus"
  ],
  "i know what i'm doing": [
    "Scio quid faciam"
  ],
  "leigelf": [
    "Leigelf"
  ],
  "anchor chain": [
    "Catena Ancorae"
  ],
  "turn raised undead": [
    "Verte Amortuos Vocatos"
  ],
  "spell tome: turn undead": [
    "Liber Magicus: Repelle Amortuos"
  ],
  "ebony battleaxe of annihilating": [
    "Bellisecuris Ebeni Annihilationis"
  ],
  "dwarven dagger of fear": [
    "Dvemeris Pugio Timoris"
  ],
  "where is this \"blackreach\"": [
    "Ubi est \"Atrahenus[Blackreach]\""
  ],
  "speaking of which": [
    "De eo loquente"
  ],
  "misc": [
    "MISC"
  ],
  "boethiah's glory": [
    "Gloria Boethis"
  ],
  "letter to beem-ja": [
    "Epistula ad Beem-Ja"
  ],
  "i don't know what you're asking": [
    "Nescio quid roges"
  ],
  "glass boots of dwindling shock": [
    "Vitreae Caligae Electricitatis Diminuentis"
  ],
  "d4": [
    "D4"
  ],
  "chop wood": [
    "Chop Wood"
  ],
  "legate fasendil": [
    "Legatus Fasendilis"
  ],
  "iron war axe of ice": [
    "Bellisecuris Ferri Gelidus"
  ],
  "broken oar bandits": [
    "Broken Oar Bandits"
  ],
  "i'd like to erase vald's debt": [
    "Volo debitum Valdis eradere"
  ],
  "hairlinemaleorc01": [
    "HairLineMaleOrc01"
  ],
  "how did aerin save your life": [
    "Quomodo Aerin vitam tuam servavit"
  ],
  "iddra": [
    "Iddra"
  ],
  "it's already clear": [
    "Iam vacuus est"
  ],
  "jarl <alias.shortname=jarl> won't help me while the war rages": [
    "Comes <Alias.ShortName=Jarl> me non adiuvaret dum bellum perseveraret"
  ],
  "actually, i'm hoping to join them": [
    "Re vera, eos adiunvere volo"
  ],
  "grelka's strongbox": [
    "Arca Grelkae"
  ],
  "key to left hand miner's barracks": [
    "Clavis ad Barracas Cuniculorum Metallis Sinistri Manus"
  ],
  "say no more": [
    "Nihil plus dic"
  ],
  "he's as good as dead": [
    "Fere iam mortuus est"
  ],
  "you seem excited to be here": [
    "Te commotum hic esse viderisYou seem excited to be here"
  ],
  "do you know anything about saarthal": [
    "Scisne quid de Saarthale"
  ],
  "orcish sword of debilitation": [
    "Orci Gladius Debilitationis"
  ],
  "sanyon": [
    "Sanyon"
  ],
  "iron gauntlets of minor smithing": [
    "Ferri Manicae Minoris Ferrarii"
  ],
  "orcish battleaxe of weariness": [
    "Orci Bellisecuris Maioris Defatigationis"
  ],
  "what if i convince them to leave": [
    "Quidni eos convincam"
  ],
  "fort kastav captain's quarters": [
    "Principia Castelli Kastav"
  ],
  "brinewater faction": [
    "Brinewater Faction"
  ],
  "lipsredguardfemale02": [
    "LipsRedguardFemale02"
  ],
  "bloodworks": [
    "Bloodworks"
  ],
  "key to burguk's longhouse": [
    "Clavis ad Longidomum Burgukis"
  ],
  "the book of daedra": [
    "Liber Deadricarum"
  ],
  "book of daedra": [
    "Liber Deadricarum"
  ],
  "why haven't the pilgrims been coming": [
    "Cur peregrinatores non venerunt"
  ],
  "potema's catacombs key": [
    "Clavis ad Catacumbas Potemae"
  ],
  "they won't stop until you do": [
    "Non sistent atequam stiteris"
  ],
  "elkmale": [
    "Elkmale"
  ],
  "iron dagger of binding": [
    "Pugio Ferri Deligandi"
  ],
  "do you make your own jewelry": [
    "Facisne monilia anulosque suos"
  ],
  "you'll listen to gold, i take it": [
    "Aurum tibi persuadere potest, nonne"
  ],
  "i think i'll just kill you": [
    "Fortasse te necabo",
    "Credo me te modo necare"
  ],
  "(attack)": [
    "(Oppugna)"
  ],
  "you already know": [
    "Iam scis"
  ],
  "greenwall cave": [
    "Spelunca Parietis Viridis"
  ],
  "you said the thalmor are after you": [
    "Dixistine Visigothos te persecutos esse"
  ],
  "ebony gauntlets of extreme wielding": [
    "Ebeni Manicae Extremis Tenendi"
  ],
  "maleeyeshumanbrownblindleft": [
    "MaleEyesHumanBrownBlindLeft"
  ],
  "orcish battleaxe of absorption": [
    "Orci Bellisecuris Absorbendi"
  ],
  "debate hall": [
    "Atrium Controversiae"
  ],
  "humanbeard38": [
    "HumanBeard38"
  ],
  "you're not happy about being here": [
    "Tibi non placet hic esse"
  ],
  "it's rusted": [
    "Oxidata est"
  ],
  "mudcrab chitin": [
    "Cartilago Luticanceris"
  ],
  "redguard clothes": [
    "Vestimenta Rubricustodum"
  ],
  "imperial bow of cowardice": [
    "Imperialis Arcus Timoris"
  ],
  "do you want to poison the": [
    "Visne venenum imponere in"
  ],
  "dragonscale helmet of eminent illusion": [
    "Draconisquamata Galea Eminentis Inlusionis"
  ],
  "wolf boots": [
    "Lupi Caligae"
  ],
  "there's a book he thinks you should read": [
    "Liber est quem legere debes"
  ],
  "we've got men on the outside": [
    "Viros foris habemus"
  ],
  "we're taking over the fort": [
    "Castellum vincemus"
  ],
  "must have taken a fortune to get started": [
    "Must have taken fortune to get started"
  ],
  "dwarven boots of frost suppression": [
    "Dvemeres Caligae Contra Glaciem"
  ],
  "what were you arguing about with lucan": [
    "De qua argumentabaris cum Lucane"
  ],
  "torolf's mill": [
    "Torolfis Mola"
  ],
  "what is wuuthrad": [
    "Quid est Vuuthrad"
  ],
  "dwarven mace of absorption": [
    "Dvemeris Maca Absorbendi"
  ],
  "i could use a hand": [
    "Auxilio uti possum"
  ],
  "skeever faction": [
    "Skeever Faction"
  ],
  "elven greatsword of reaping": [
    "Spatha Alfa Saturni"
  ],
  "femaleeyeshumanbrownblindright": [
    "FemaleEyesHumanBrownBlindRight"
  ],
  "daedric mace of the blaze": [
    "Maca Daedrica Congflagrantis"
  ],
  "i want no part of this": [
    "Nullam partem illius volo"
  ],
  "i need to get a look at sabjorn's books": [
    "Opus est mihi ratiocinia Sabjornis videre"
  ],
  "journeyman bellows": [
    "Journeyman Bellovs"
  ],
  "have you heard any rumors lately": [
    "Nuperne audivisti quas rumores"
  ],
  "orcish bow of despair": [
    "Orci Arcus Desponsionis"
  ],
  "fur-trimmed cloak": [
    "Renone-Ornatum Amiculum"
  ],
  "\"i'll do\"": [
    "\"Satis bonus ero\""
  ],
  "defeat the giant": [
    "Supera Gigantem"
  ],
  "runed lexicon": [
    "Lexicon Runatum"
  ],
  "maleeyeswoodelfbrown": [
    "MaleEyesWoodElfBrown"
  ],
  "slow down": [
    "Lentius"
  ],
  "explain yourself": [
    "Explica te"
  ],
  "novice tongs": [
    "Novice Tongs"
  ],
  "so i should go to volunruud": [
    "Opusne mihi est Volunruud petere"
  ],
  "i should talk to this man": [
    "Ad hunc virum loqui debeo"
  ],
  "sorry, i can't": [
    "Ignosce, non possum"
  ],
  "enter riften warehouse": [
    "Intra Horreum Hiati"
  ],
  "grin and bear it": [
    "Subride et Accipe (Ursas)"
  ],
  "i was right there": [
    "Illic eram"
  ],
  "he almost killed me": [
    "Fere me necavit"
  ],
  "i heard some shouting about the forsworn": [
    "Clamationes de Abiuratis audivi"
  ],
  "that was it": [
    "Illud erat omne"
  ],
  "some light theft": [
    "Paulum Furti"
  ],
  "sounds like you've seen you far share of hardship": [
    "Mihi videtur te multas res difficultas vidisse"
  ],
  "dwarven battleaxe of freezing": [
    "Dvemeris Bellisecuris Gelidus"
  ],
  "you said something about a bad deal": [
    "Dixisti aliquid de sponsu malo"
  ],
  "dwarven shield of eminent blocking": [
    "Dvemere Scutum Eminentis Interponendi"
  ],
  "forsworn shaman": [
    "Forsworn Shaman"
  ],
  "imperial quartermaster": [
    "Imperialis Quaestor"
  ],
  "iron gauntlets of major sure grip": [
    "Ferri Manicae Maioris Dexteritatis"
  ],
  "spell tome: fire storm": [
    "Liber Magicus: Tempestas Ignis"
  ],
  "recharge": [
    "Reauge"
  ],
  "daedric battleaxe of winnowing": [
    "Bellisecuris Daedricus Ventagii"
  ],
  "nenya": [
    "Nenya"
  ],
  "where did gallus go": [
    "Quo ivit Gallus"
  ],
  "dragonscale boots of brawn": [
    "Caligae Draconisquamatae Fortitudinis"
  ],
  "glass greatsword of banishing": [
    "Spatha Vitrea Expellendi"
  ],
  "studded armor": [
    "Ornata Lorica"
  ],
  "glass greatsword of stunning": [
    "Spatha Vitrea Intermissionis"
  ],
  "tullius will look weak if he refuses, not you": [
    "Tullius debilis videbitur si recusabit, nec tu"
  ],
  "iron greatsword of embers": [
    "Spatha Ferri Cinerum"
  ],
  "i'll make sure she gets it": [
    "Videbo ut ea id accipiat"
  ],
  "360 controller": [
    "360 Controller"
  ],
  "okay, what's the catch": [
    "Ok, quid est pretium"
  ],
  "glass greatsword of dread": [
    "Spatha Vitrea Timoris"
  ],
  "word is your outfit isn't doing well": [
    "Rumor dicit gregem tuam non bene agere"
  ],
  "they're convening a peace council at high hrothgar": [
    "Concilium pacis ad Hrothgarem Altum conveniunt"
  ],
  "what kind of an edge": [
    "Quale auxilium"
  ],
  "i don't understand": [
    "Non intellego"
  ],
  "i'm looking for a khajiit... might be a thalmor spy": [
    "Khajiitum quaero... fortasse explorator Visigothi est"
  ],
  "what scared them off": [
    "Quid eos terruit"
  ],
  "haven't they seen dead bodies before": [
    "Nonne corpora mortua iam vidÃªrunt"
  ],
  "i'm a little deaf in this ear": [
    "sum paulo surdus in hac aure"
  ],
  "ebony sword of winnowing": [
    "Gladius Ebeni Ventagii"
  ],
  "iron mace": [
    "Maca Ferri"
  ],
  "why do you wish to die": [
    "Cur mori vis"
  ],
  "cheekbone width": [
    "Longitudo Genarum"
  ],
  "orcish battleaxe of harvesting": [
    "Orci Bellisecuris Saturni"
  ],
  "glass warhammer of shocks": [
    "Bellimaleus Vitreus Electricitatis"
  ],
  "jorgen": [
    "Jorgen"
  ],
  "about that poem": [
    "De illa poema"
  ],
  "daedric gauntlets of eminent wielding": [
    "Daedricae Manicae Eminentis Tenendi"
  ],
  "olaf ordered disguised troops to attack solitude": [
    "Olaf milites dissimulatos misit ut Solitudinem oppugnent"
  ],
  "db03muiricontractdetails1": [
    "DB03MuiriContractDetails1"
  ],
  "desperate gambler": [
    "Braviotor Desperans"
  ],
  "so you work here": [
    "Ergo hic laboras"
  ],
  "leather bracers of major archery": [
    "Corii Chirothecae Maioris Sagittationis"
  ],
  "windhelm military camp": [
    "Ventigaleae Castra"
  ],
  "a problem": [
    "Difficultas"
  ],
  "hunting bow of embers": [
    "Arcus Venandi Cinerum"
  ],
  "odvan": [
    "Odvan"
  ],
  "so we know his name": [
    "Ergo nomen eius scimus"
  ],
  "how does that help me get him to dragonsreach": [
    "Quomodo adiuvat nos haec scientia ut eum Draconicarcerem feramus"
  ],
  "orini dral": [
    "Orini Dral"
  ],
  "steel helmet of major archery": [
    "Adamantea Galea Maioris Sagittationis"
  ],
  "where are your children": [
    "Ubi sunt pueri"
  ],
  "did you notice anything strange before she left": [
    "Aliquid mirabile vidisti ante discessit"
  ],
  "daedric sword of devouring": [
    "Gladius Daedricus Consumendi"
  ],
  "fortify enchanting": [
    "Adde ad Incantantium"
  ],
  "dried elves ear": [
    "Alfa Auris Sicca"
  ],
  "hairmaleredguard3": [
    "HairMaleRedguard3"
  ],
  "philter of resist magic": [
    "Philtrum Resistendi Magicam"
  ],
  "elven battleaxe of thunderbolts": [
    "Bellisecuris Alfus Fulminum"
  ],
  "i would love to share a drink with you, friend": [
    "Volo bibitum tecum communicare, amice"
  ],
  "marksfemalehumanoid11leftgash": [
    "MarksFemaleHumanoid11LeftGash"
  ],
  "nord war horn": [
    "Cornu Germanum Belli"
  ],
  "you are suffering from %s withdrawal": [
    "Cares %s"
  ],
  "what can you tell me about your wife": [
    "Quid mihi dicere potes de uxore tua"
  ],
  "okay, never mind": [
    "Ok, Ignosce"
  ],
  "daedric sword of leeching": [
    "Gladius Daedricus Sanguisugae"
  ],
  "leather helmet of major destruction": [
    "Cutis Galea Maioris Destructionis"
  ],
  "so, what did you think about the deed": [
    "Ergo, quid de emptione censes"
  ],
  "gain entrance to sky haven temple": [
    "Intra Templum Caeli Refugiti"
  ],
  "i'm not quite ready": [
    "Nondum paratus sum"
  ],
  "why not level the entire estate": [
    "Quidni totam villam deleamus"
  ],
  "courage - master of the mind": [
    "Courage - Dominus Mentis"
  ],
  "faldar's tooth key": [
    "Clavis ad Dentem Faldaris"
  ],
  "potion of ultimate healing": [
    "Potio Ultimatis Salutis"
  ],
  "db recurringtarget 7 crimefaction": [
    "DB RecurringTarget 7 CrimeFaction"
  ],
  "what makes you think i won't? (persuade)": [
    "Cur me id non facturum esse censes (Persuade)"
  ],
  "horse hide": [
    "Equi Reno"
  ],
  "philter of lasting potency": [
    "Philtrum Potentiae Interminantis"
  ],
  "roggi knot-beard": [
    "Roggi Nodi-Barbus"
  ],
  "how did you know i'd be able to defeat ancano": [
    "Quomodo me Ancanonem superare posse scivisti"
  ],
  "may i please have the books": [
    "Possumne libros habere, quaeso"
  ],
  "did something set cicero off": [
    "Aliquidne Ciceronem iratum fecit"
  ],
  "orcish mace of freezing": [
    "Orci Maca Gelidus"
  ],
  "deeja will need to be locked up": [
    "Deeja in carcerem mitti debet"
  ],
  "orcish mace of diminishing": [
    "Orci Maca Diminuendi"
  ],
  "itinerant lumberjack": [
    "Itinerans Boscarius"
  ],
  "they were trying to perform some sort of experiment, i think": [
    "Experiment facere conabantur, ut puto"
  ],
  "ice terror": [
    "Terror Glaciei"
  ],
  "ahtar": [
    "Ahtar"
  ],
  "elven war axe of animus": [
    "Alfus Bellisecuris Animi"
  ],
  "leave that to me": [
    "Sine me de eo curare"
  ],
  "i'll talk to arngeir about hosting a peace council": [
    "Arngeirem adloquer de concilio pacis"
  ],
  "nurelion's mixture": [
    "Nurelionis Commixtura"
  ],
  "illusory war axe": [
    "Illusory Bellisecuris"
  ],
  "kordir's skooma": [
    "Kordiris Skooma"
  ],
  "maleeyeshighelfdarkyellow": [
    "MaleEyesHighElfDarkYellow"
  ],
  "what's this about a crystal": [
    "Quid de hoc crystallo"
  ],
  "riverwood sleeping giant inn faction": [
    "Riverwood Sleeping Giant Inn Faction"
  ],
  "reverent iron dagger": [
    "Reverens Pugio Ferri"
  ],
  "valtheim towers": [
    "Valtheimis Turres"
  ],
  "actually, i'm looking for this old guy hiding out in riften": [
    "Re vera, senem qui Hiati latet quaero"
  ],
  "silent city catacombs": [
    "Silentis Urbis Catacumbae"
  ],
  "so we're done, then": [
    "Ergo factum est com nobis"
  ],
  "how about some compensation": [
    "Quid de recompenso"
  ],
  "lu'ah al-skaven": [
    "Lu'ah Al-Skaven"
  ],
  "search for synod researchers": [
    "Quaere Investigatores Synodis"
  ],
  "agna": [
    "Agna"
  ],
  "mourner's clothes": [
    "Vestimenta Maerentis"
  ],
  "delvin mallory said you had a job for me": [
    "Delphinus Mallory dixit te munus pro me habere"
  ],
  "orcish battleaxe": [
    "Orci Bellisecuris"
  ],
  "default message text": [
    "DEFAULT MESSAGE TEXT"
  ],
  "i know where the alik'r are, and i have a way in": [
    "Scio ubi sint Alik'res , et ingressum inveni"
  ],
  "it's something they took from fjotli, i assume": [
    "Aliquid est quod e Fjotli ceperunt, ut divino"
  ],
  "i need to find him": [
    "Opus mihi est eum invenire"
  ],
  "his dragon allies must know where this portal is": [
    "Sodales Dracones eius certe sciunt ubi esset porta"
  ],
  "forsworn": [
    "Forsworn"
  ],
  "you mean dragonsreach": [
    "De Draconicarcere loqueris"
  ],
  "what's the jarl's palace have to do with this": [
    "Quid habet aulam Comitis cum hoc"
  ],
  "the frozen hearth services": [
    "The Frozen Hearth Services"
  ],
  "frozen hearth services": [
    "The Frozen Hearth Services"
  ],
  "i thought there weren't any dragons until alduin came back": [
    "Credidi nullos dracones fuisse ante Alduinum revenisse"
  ],
  "punch": [
    "Pugna"
  ],
  "glass greatsword of despair": [
    "Spatha Vitrea Desponsionis"
  ],
  "spoon": [
    "Cochlearis"
  ],
  "dwarven shield of dwindling shock": [
    "Dvemere Scutum Electricitatis Diminuentis"
  ],
  "grand plaza scene 28": [
    "Grand Plaza Scene 28"
  ],
  "it will be my honor": [
    "Honor meus erit"
  ],
  "then why am i here in whiterun": [
    "Cur hic sum Albacursus"
  ],
  "dragonplate gauntlets of extreme sure grip": [
    "Draconilaminatae Manicae Extremis Ambidexteritatis"
  ],
  "daedric gauntlets of extreme alchemy": [
    "Daedricae Manicae Extremis Alchemiae"
  ],
  "where's the letter being kept": [
    "Ubi est epistula"
  ],
  "hide bracers of major deft hands": [
    "Chirothecae Cutis Maioris Dexteritatis"
  ],
  "everything's been done": [
    "Omnia facta sunt"
  ],
  "arn should be safe": [
    "Arn tutum esse debet"
  ],
  "that's why he sent me": [
    "Hanc ob rem mihi misit"
  ],
  "i assume this is where i enter the picture": [
    "Credo me hic auxilium daturum esse"
  ],
  "losttonormal": [
    "LostToNormal"
  ],
  "it was recorded on alduin's wall": [
    "Scriptum est in pariete Alduini"
  ],
  "the large tree in whiterun's wind district is called the gildergreen, and is considered sacred by worshippers of kynareth": [
    "Arbor Magna in Parte Albacursus nomine Regio Venti Gildergreen nominatur et adoratus est secutoribus Kynarethis"
  ],
  "large tree in whiterun's wind district is called the gildergreen, and is considered sacred by worshippers of kynareth": [
    "Arbor Magna in Parte Albacursus nomine Regio Venti Gildergreen nominatur et adoratus est secutoribus Kynarethis"
  ],
  "glass warhammer of harrowing": [
    "Bellimaleus Vitreus Occationis"
  ],
  "dunyngvildwizardnormaltocombat": [
    "dunYngvildWizardNormaltoCombat"
  ],
  "letter from calcemo": [
    "Epistula a Calcemo"
  ],
  "hide shield of minor blocking": [
    "Scutum Cutis Minoris Interponendi"
  ],
  "iron battleaxe of burning": [
    "Bellisecuris Ferri Cremandi"
  ],
  "we had no choice": [
    "Nullam optionem habuimus"
  ],
  "i spoke with vex about isabelle": [
    "Cum Vex locutus sum de Isabella"
  ],
  "i'll need more to go on than that": [
    "Necesse est mihi plus scire"
  ],
  "blind cliff cave": [
    "Spelunca Rupis Caeci"
  ],
  "key to gerdur's house": [
    "Clavis ad Domum Gerduris"
  ],
  "where are you from": [
    "Unde venis"
  ],
  "have you heard of avanchnzel": [
    "Audivistine de Avanchnzel"
  ],
  "i can't do that right now": [
    "Illud nunc facere non possum"
  ],
  "let's try again": [
    "Iterum conabimur"
  ],
  "where is the silver mold": [
    "Ubi est Exemplar Argentei"
  ],
  "journal of drokt": [
    "Ephemeris Drokt"
  ],
  "i never said it was a silver mold": [
    "Numquam dixi id exemplar Argentei esse"
  ],
  "you have yourself a deal, sir": [
    "Transactionem tibi habes, domine"
  ],
  "she said to tell you, \"darkness rises when silence dies.\"": [
    "Illa mihi oravit ut tibi diceret, \"Obscuritas surgit cum silentium moriatur.\""
  ],
  "falk, you know i'll be careful": [
    "Falco, scis me cautum futurum esse"
  ],
  "unbound dremora": [
    "Dremora Sine Domine"
  ],
  "elven bow of dread": [
    "Arcus Alfus Timoris"
  ],
  "ebony sword of harvesting": [
    "Gladius Ebeni Saturni"
  ],
  "why do you need the guild's help for that": [
    "Quamobrem auxilium Sodalitatis pro eo petis"
  ],
  "iron war axe of embers": [
    "Bellisecuris Ferri Cinerum"
  ],
  "steel mace of frost": [
    "Maca Adamantea Gelidus"
  ],
  "some sort of ghost or apparition appeared": [
    "Effigies vel imago quaedam apparuit"
  ],
  "it spoke to me": [
    "Me adlocuta est"
  ],
  "lipswoodelfmale03": [
    "LipsWoodElfMale03"
  ],
  "reverent dwarven warhammer": [
    "Reverens Dvemeris Bellimalleus"
  ],
  "key to omluag's room": [
    "Clavis ad Conclave Omluagis"
  ],
  "the song of pelinal, v1": [
    "Carmen Pelinalis, I"
  ],
  "song of pelinal, v1": [
    "Carmen Pelinalis, I"
  ],
  "glass battleaxe of blizzards": [
    "Bellisecuris Vitreus Hiemis"
  ],
  "the \"hag's cure.\" that's a unique name": [
    "\"Cura Sagae.\" Hoc is nomen monodicum"
  ],
  "\"hag's cure.\" that's a unique name": [
    "\"Cura Sagae.\" Hoc is nomen monodicum"
  ],
  "there's a ghost haunting you": [
    "Phantasma te sequitur"
  ],
  "clear skies effect 3": [
    "Clear Skies Effect 3"
  ],
  "it's out of my hands": [
    "Nihil facere possum"
  ],
  "what game do you want to play": [
    "Quem ludum ludere vis"
  ],
  "you should have told me the truth": [
    "Mihi verum dicere debuisti"
  ],
  "save game deleted": [
    "Tutum Deletum"
  ],
  "i'd love to have a look around": [
    "Circumspectare volo"
  ],
  "they want to negotiate a truce until the dragon menace is dealt with": [
    "Adhuc comminatio draconium terminet, foedus facere volunt"
  ],
  "at least the blades aren't keeping secrets from me": [
    "Saltem enses secreta mecum non habent"
  ],
  "orcish sword of torpor": [
    "Orci Gladius Torporis"
  ],
  "gotcha": [
    "Iocatus sum"
  ],
  "it was 20,000 gold": [
    "Erat MMMMMMMMMMMMMMMMMMMM Auri"
  ],
  "tail stagger": [
    "Tail Stagger"
  ],
  "her husband sent me": [
    "Maritus mihi misit"
  ],
  "where is she": [
    "Ubi est illa"
  ],
  "aren't you forgetting something": [
    "Nonne cuius oblitus es"
  ],
  "roggi's ancestral shield": [
    "Roggi Maiorum Scutum"
  ],
  "what do i get if i free you": [
    "Quid mihi dabis si te liberabo"
  ],
  "i'm no breton": [
    "Britannus non sum"
  ],
  "thrynn": [
    "Thrynn"
  ],
  "elven war axe of dread": [
    "Alfus Bellisecuris Timoris"
  ],
  "skyforge": [
    "Califabrica"
  ],
  "just a favor... for an honorable man": [
    "Beneficium modo est... homini honorabili"
  ],
  "dialoguegenericpickpocket": [
    "DialogueGenericFurari"
  ],
  "ebony warhammer of blizzards": [
    "Bellimalleus Ebeni Hiemis"
  ],
  "the blades want me to kill paarthurnax": [
    "Enses me Paarthurnacem necare volunt"
  ],
  "blades want me to kill paarthurnax": [
    "Enses me Paarthurnacem necare volunt"
  ],
  "sinmir": [
    "Sinmir"
  ],
  "is it true what they said": [
    "Estne verum id quod dixerunt"
  ],
  "was he alduin's ally": [
    "Fuitne ille socius Alduini"
  ],
  "i don't know, but the thalmor are looking for someone named esbern": [
    "Nescio, sed Visigothi aliquem nomine Esbernem quaerunt"
  ],
  "i talked to paarthurnax": [
    "Paarthurnacem adlocutus sum"
  ],
  "no, but he told me how to find out": [
    "Minime, sed mihi viam ad eum discendum demonstravit"
  ],
  "ebony greatsword of leeching": [
    "Spatha Ebeni Sanguisugae"
  ],
  "nightshade extact": [
    "Umbranoctis Extractum"
  ],
  "it's not hopeless, esbern": [
    "Non sine spe est, Esbern"
  ],
  "don't worry, i'm not going to kill paarthurnax": [
    "Noli timere, Paarthurnacem non necam"
  ],
  "raised stone": [
    "Lapis Altus"
  ],
  "db04astridmotierrenazirtopic": [
    "DB04AstridMotierreNazirTopic"
  ],
  "draught of lasting potency": [
    "Decocta Potentiae Interminantis"
  ],
  "scaled bracers of eminent lockpicking": [
    "Chirothecae Squamatae Eminentis Clavicarii"
  ],
  "who is the augur of dunlain": [
    "Quis est Augur Dunlanis"
  ],
  "leather boots of sneaking": [
    "Corii Caligae Repsendi"
  ],
  "animal pelt collection": [
    "Collectio Pellium Animalium"
  ],
  "why are you so concerned about this": [
    "Cur hoc tibi tantum pertingit"
  ],
  "sounds simple enough": [
    "Satis simplex mihi videtur"
  ],
  "femaleheadnordastrid": [
    "FemaleHeadNordAstrid"
  ],
  "if this is dangerous, then why don't you do something about it": [
    "Si periculosus esset, quidni aliquid de eo faceres"
  ],
  "make dungeon creatures not attack me": [
    "Make dungeon creatures not attack me"
  ],
  "susanna the wicked": [
    "Susanna Prava"
  ],
  "scaled bracers of major lockpicking": [
    "Chirothecae Squamatae Maioris Clavicarii"
  ],
  "i'll help, but i don't know what to do": [
    "Adiuvabo, sed nescio quid faciam"
  ],
  "i don't really feel like drinking": [
    "Nolo bibere nunc"
  ],
  "your people have bad luck": [
    "Vos infelices estis"
  ],
  "adrenaline rush": [
    "Cursus Adrenelinae"
  ],
  "morning star": [
    "Ianuarius"
  ],
  "you should get yourself to safety": [
    "Te ad salvationem ferre debes"
  ],
  "i'm sorry, maven": [
    "Me paenitet, Maven"
  ],
  "geirlund": [
    "Geirlund"
  ],
  "firebolt storm spell": [
    "Firebolt Storm Spell"
  ],
  "what can you tell me about forelhost": [
    "Quid mihi dicere potes de Forelhost"
  ],
  "glass sword of debilitation": [
    "Gladius Vitreus Debilitationis"
  ],
  "apprentice censer": [
    "Apprentice Censer"
  ],
  "so with the ebonmere closed, our luck's run dry": [
    "Ergo quod Ebonmere clausus est, fors nostra est effugit"
  ],
  "troll faction": [
    "Troll Faction"
  ],
  "the night mother has spoken to me again": [
    "Mater Noctis mihi iterum locuta est"
  ],
  "night mother has spoken to me again": [
    "Mater Noctis mihi iterum locuta est"
  ],
  "steel sword": [
    "Gladius Adamanteus"
  ],
  "where did you learn to play so well": [
    "Ubi tantam artem didicisti"
  ],
  "our family lives on, nazir": [
    "Familia iam vivit Nazir"
  ],
  "you have to trust me": [
    "Mihi credere potes"
  ],
  "daedric dagger of lethargy": [
    "Pugio Daedricus Lethargiae"
  ],
  "scaled helmet of major destruction": [
    "Galea Squamata Maioris Destructionis"
  ],
  "steel greatsword of dismay": [
    "Spatha Adamantea Timoris"
  ],
  "why do we need to be cautious": [
    "Quamobrem cautos esse debemus"
  ],
  "rislav the righteous": [
    "Rislav Righteous"
  ],
  "glass armor of major health": [
    "Vitrea Lorica Maioris Salutis"
  ],
  "this had better be worth all the trouble": [
    "Hoc omnes difficultates valere debet"
  ],
  "coinpurse": [
    "Sacculum"
  ],
  "sylgja's satchel": [
    "Sylgjae Cibisis"
  ],
  "i dealt with the rest of the bandits": [
    "Reliquia latronum necavi"
  ],
  "take a city back": [
    "Urbem recipire"
  ],
  "what's that mean": [
    "Quid sibi vult"
  ],
  "i want to go ahead with the ceremony": [
    "Volo cum caerimonia pergere"
  ],
  "hide helmet of minor conjuring": [
    "Cutis Galea Minoris Vocandi"
  ],
  "hey, i'm not a breton": [
    "Heus, non sum Britannus"
  ],
  "is there a way to cure yourself": [
    "Estne via qua te curare possis"
  ],
  "daedric dagger of petrifying": [
    "Pugio Daedricus Lapidescendi"
  ],
  "medresi dran": [
    "Medresi Dran"
  ],
  "i don't have the gold right now": [
    "Non habeo aurum nunc ipse"
  ],
  "psiijic robes": [
    "Peplum Psijicum"
  ],
  "lod": [
    "Lod"
  ],
  "not a chance": [
    "Nullus casus"
  ],
  "detect undead friend exterior": [
    "Detect Undead Friend Exterior"
  ],
  "tova's farewell": [
    "Valedictio Tovae"
  ],
  "elven sword of stunning": [
    "Alfus Gladius Intermissionis"
  ],
  "elven shield of dwindling shock": [
    "Alfum Scutum Electricitatis Diminuentis"
  ],
  "how could i even release you": [
    "Quomodo te liberare possum"
  ],
  "femaleheadnord": [
    "FemaleHeadNord"
  ],
  "what exactly are you trying to do": [
    "Quid facere temptas"
  ],
  "may i see the staff": [
    "Possumne rudem videre"
  ],
  "reverent orcish greatsword": [
    "Reverens Spatha Orcorum"
  ],
  "i had some questions about this city": [
    "Interrogate de hac urbe habui"
  ],
  "i have a delivery from kerah": [
    "Cista tibi habeo a Kerah"
  ],
  "player spellsword class": [
    "Player Spellsword Class"
  ],
  "dryston": [
    "Dryston"
  ],
  "i found this statue in a forsworn camp": [
    "Hanc statuam in castris Abiurati inveni"
  ],
  "iron war axe of shocks": [
    "Bellisecuris Ferri Electricitatis"
  ],
  "glass battleaxe of despair": [
    "Bellisecuris Vitreus Desponsionis"
  ],
  "tell me more about this artifact": [
    "Dic mihi plus de hoc Aurorafractore"
  ],
  "fox handle": [
    "Ansam Vulpis"
  ],
  "gr4": [
    "Gr4"
  ],
  "addiction": [
    "Addiction"
  ],
  "scaled bracers of eminent sure grip": [
    "Chirothecae Squamatae Eminentis Ambidexteritatis"
  ],
  "i sure would like to keep this axe": [
    "Volo hunc securem retinere"
  ],
  "dwarven shield of waning fire": [
    "Dvemere Scutum Insenescentis Ignis"
  ],
  "do not delete - not a test cell": [
    "Do Not Delete - Not A Test Cell"
  ],
  "potion of waterbreathing": [
    "Potio Respirandi Sub Aqua"
  ],
  "cicero's gloves": [
    "Ciceronis Manubria"
  ],
  "ancient nord battle axe": [
    "Antiquus Bellisecuris Germanicus"
  ],
  "vibration": [
    "Vibratio"
  ],
  "the greybeards are right": [
    "Canabarbae recte dicunt"
  ],
  "greybeards are right": [
    "Canabarbae recte dicunt"
  ],
  "ebony bow of winter": [
    "Arcus Ebeni Hiemis"
  ],
  "i'm not interested. sorry": [
    "Mihi non interest, ignosce"
  ],
  "kill agnis": [
    "Neca Agnem"
  ],
  "any suggestions as to how i find them": [
    "Habesne consilium de eos inveniendo"
  ],
  "i won't lie": [
    "Mentiri non possum"
  ],
  "i'm game": [
    "Id facere volo"
  ],
  "together until the end, then": [
    "Iuncim ad finem"
  ],
  "i do": [
    "Sic",
    "Consentior"
  ],
  "my hand is yours": [
    "Manus mea est tua"
  ],
  "yes i am": [
    "Sic volo"
  ],
  "turnundeadmassexplosion": [
    "TurnUndeadMassExplosion"
  ],
  "iron helmet of major illusion": [
    "Ferri Galea Maioris Inlusionis"
  ],
  "dragonplate armor of the peerless knight": [
    "Draconilaminata Lorica Optimae Equitis"
  ],
  "i've heard you might know what faleen likes": [
    "Audivi te fortasse scire quas res Faleeni placere"
  ],
  "hide helmet of restoration": [
    "Cutis Galea Recreandi"
  ],
  "valmir's orders": [
    "Mandata Valmiris"
  ],
  "sluice gate key": [
    "Clavis ad Portam Clusae"
  ],
  "i was just fulfilling my destiny as dragonborn": [
    "Solum fatum meum ut Draconigena complebam"
  ],
  "voice of boethiah": [
    "Vox Boethis"
  ],
  "the save game is corrupt. returning to game": [
    "Ludus tutus corruptus est. Ad ludum reveniens"
  ],
  "save game is corrupt. returning to game": [
    "Ludus tutus corruptus est. Ad ludum reveniens"
  ],
  "elven war axe of winnowing": [
    "Alfus Bellisecuris Ventagii"
  ],
  "angeline's aromatics services": [
    "Angeline's Aromatics Services"
  ],
  "potion of resist fire": [
    "Potio Resistendi Ignem"
  ],
  "you can't escape your fate, malyn": [
    "Fatum tuum effugere non potes, Malyn"
  ],
  "you live here in markarth": [
    "Hic Markartiae vivis"
  ],
  "find the <alias=questitem> inside <alias=dungeon>": [
    "Inveni <Alias=QuestItem> in <Alias=Dungeon>"
  ],
  "spell tome: turn lesser undead": [
    "Liber Magicus: Repelle Amortuos Minores"
  ],
  "once a source of pride for the people of winterhold, the college is now shunned and feared": [
    "Olim fons exaltationis hominum Hiemiterrae, Collegium non timetur et evitatur"
  ],
  "heddic's volunruud notes": [
    "Adnotata Heddicis Volunruudi"
  ],
  "wisp's magicka blessing": [
    "Wisp's Magicae Blessing"
  ],
  "is that when she asked you to join": [
    "Deinde illa oravit ut adiungeres"
  ],
  "warden's lash": [
    "Wardenis Lash"
  ],
  "i have no idea what you're talking about": [
    "De qua loqueris omnino nescio"
  ],
  "paralysis poison": [
    "Venenum Paralyticum"
  ],
  "agents of the black marsh": [
    "Agents of the Black Marsh"
  ],
  "in addition to doing regular damage, shock spells also deplete the target's magicka": [
    "Praeter vulnus normale, ictus electricitatis quoque magicam inimicorum diminuunt"
  ],
  "they're particularly useful against wizards": [
    "Utiliores contra thaumatorgos sunt"
  ],
  "thank you, lady azura": [
    "Tibi gratias do, Domina Azura"
  ],
  "don't care": [
    "Nihil curo"
  ],
  "ulfric's clothes": [
    "Vestimenta Vercingetorigis"
  ],
  "ingredients": [
    "INGREDIENTIA"
  ],
  "yeah, he went that way": [
    "Sic, per illam viam ivit"
  ],
  "illusion staff": [
    "Rudis Inlusionis"
  ],
  "why are the thalmor so interested in finding you": [
    "Cur Visigothi te tam intente quaerunt"
  ],
  "dwarven dagger of shocks": [
    "Dvemeris Pugio Electricitatis"
  ],
  "$music": [
    "$Music"
  ],
  "draugr melee": [
    "Draugr Melee"
  ],
  "imperial sword of cowardice": [
    "Gladius Romanus Timoris"
  ],
  "daedric helmet of extreme conjuring": [
    "Galea Daedrica Maiorum Vocantium"
  ],
  "steel plate boots of dwindling frost": [
    "Laminatae Caligae Minoris Congelationis"
  ],
  "belyn hlaalu's house": [
    "Belyn Hlaalu's House"
  ],
  "i'd like to talk to you about your son": [
    "Te de filio tuo adloqui volo"
  ],
  "you should let your son choose the life he wants": [
    "Filium vitam suam eligere sinere debes"
  ],
  "hallowed daedric battleaxe": [
    "Consacratus Bellisecuris Daedricus"
  ],
  "elven helmet of eminent destruction": [
    "Alfa Galea Eminentis Destructionis"
  ],
  "here are the texts you requested": [
    "Hic sunt libri pro quibus rogavisti"
  ],
  "shaircolor15": [
    "sHairColor15"
  ],
  "daedric dagger of devouring": [
    "Pugio Daedricus Consumendi"
  ],
  "surely the night mother wouldn't misdirect us": [
    "Nonne Mater Noctis nos non fallat"
  ],
  "guard faction (for dialogue)": [
    "Guard Faction (for dialogue)"
  ],
  "hide shield of waning fire": [
    "Scutum Cutis Insenescentis Ignis"
  ],
  "your rule is at an end": [
    "Regnum tuum ad finem venisti"
  ],
  "sithis commands it": [
    "Sithis ei imperavit"
  ],
  "hairmalenord17": [
    "HairMaleNord17"
  ],
  "i just want to know how to defeat alduin": [
    "Solum modum in quo Alduinum necare possum scire volo"
  ],
  "we lost a lot of good men": [
    "Multos bonos perdidi"
  ],
  "i hope it was worth it": [
    "Spero eam valuisse"
  ],
  "elven dagger of ice": [
    "Pugio Alfus Gelidus"
  ],
  "what's the best way to get into riftweald manor": [
    "Qua est via optima ad Villam Riftweald"
  ],
  "animal allegiance 3": [
    "Animal Allegiance 3"
  ],
  "unusual gem": [
    "Gemmam Mirabilem"
  ],
  "how did you capture ulfric": [
    "Quomodo Vercingetorigem cepisti"
  ],
  "daedric dagger of evoking": [
    "Pugio Daedricus Evocandi"
  ],
  "how am i supposed to do all of this": [
    "Quomodo haec omnia facere possum"
  ],
  "boulderfall cave": [
    "Spelunca Silicadentis"
  ],
  "brynjolf said you have info on vald": [
    "Brynjolf dixit te informationem de Vald habere"
  ],
  "you want us to kill... the emperor": [
    "Vis nos necare... Imperatorem"
  ],
  "of tamriel": [
    "Imperii"
  ],
  "dialoguemarkarthintroblacksmithscene": [
    "DialogueMarkarthIntroBlacksmithScene"
  ],
  "i need to get inside mercer's house": [
    "Necesse est mihi domum Merceris invenire"
  ],
  "maleeyeselfblind": [
    "MaleEyesElfBlind"
  ],
  "kill ma'randru-jo": [
    "Neca Ma'randru-jo"
  ],
  "black briar meadery faction": [
    "Black Briar Meadery Faction"
  ],
  "i have gold ore to sell": [
    "Minerale auri vendendum habeo"
  ],
  "virkmund": [
    "Virkmund"
  ],
  "orcish war axe of soul snares": [
    "Orci Bellisecuris Animicapti"
  ],
  "iron shield of resist frost": [
    "Ferri Scutum Contra Vim Gelidam"
  ],
  "quintus navale": [
    "Quintus Navale"
  ],
  "orcish shield of fire suppression": [
    "Orci Scutum Suppressionis Ignis"
  ],
  "falmer bow": [
    "Arcus Falmeris"
  ],
  "the missing apprentices": [
    "The Missing Apprentices"
  ],
  "missing apprentices": [
    "The Missing Apprentices"
  ],
  "what is the aim of this war": [
    "Quid est finem huius belli"
  ],
  "dwarven warhammer of soul snares": [
    "Dvemeris Bellimalleus Animicapti"
  ],
  "retrieve <alias.shortname=item> off of <alias.shortname=deadperson>": [
    "Cape <Alias.ShortName=Item> e <Alias.ShortName=DeadPerson>"
  ],
  "nordic barnacle cluster": [
    "Botrus Ostrearum Germanarum"
  ],
  "sapphire dragon claw": [
    "Ungues Draconis Sapphyrus"
  ],
  "gloth": [
    "Gloth"
  ],
  "wait a moment... you're gallus": [
    "Expecta... Tu es Gallus"
  ],
  "a courier gave me this pamphlet": [
    "Nuntius mihi hunc libellum dedit"
  ],
  "courier gave me this pamphlet": [
    "Nuntius mihi hunc libellum dedit"
  ],
  "cowflop farm faction": [
    "Cowflop Farm Faction"
  ],
  "i've seen the woman you're looking for": [
    "Feminam quam quaris vidi"
  ],
  "what do i get for telling you": [
    "Quid accipiam quod te de eo narravi"
  ],
  "advard": [
    "Advard"
  ],
  "listen to karliah": [
    "Ausculta Karliam"
  ],
  "severio pelagia's house": [
    "Severionis Pelagiae Domus"
  ],
  "what needs to be done": [
    "Quid faciendum est"
  ],
  "bring it": [
    "Fer id"
  ],
  "i want to join": [
    "Volo coniugere"
  ],
  "boethiah's blessing": [
    "Benedictio Boethis"
  ],
  "how did you get your unusual name": [
    "Quomodo nomen mirum accepisti"
  ],
  "how do the orcs govern themselves": [
    "Quomodo Orci se moderantur"
  ],
  "silver-blood inn": [
    "Argenti-Sanguinis Inn"
  ],
  "ardwen": [
    "Ardven"
  ],
  "we have to make sure winterhold is safe": [
    "Opus est nobis certiorem facere ut Hiemiterras Tutam esse"
  ],
  "the cragslane operation has been stopped": [
    "Operatio apud Cragslane sistitur"
  ],
  "cragslane operation has been stopped": [
    "Operatio apud Cragslane sistitur"
  ],
  "orcish greatsword of devouring": [
    "Orci Spatha Consumendi"
  ],
  "i've run across these alik'r before": [
    "His Alik'ribus antea vidi"
  ],
  "i know where they're hiding": [
    "Scio ubi se celare"
  ],
  "any work to be done in the stronghold": [
    "Esne labor in arce"
  ],
  "glass armor of the major squire": [
    "Vitrea Lorica Maioris Armiferis"
  ],
  "glass war axe of lethargy": [
    "Bellisecuris Vitreus Lethargiae"
  ],
  "frofnir trollsbane": [
    "Frofnir Trellimors"
  ],
  "i found a certain book, \"night of tears\"": [
    "Librum certum, \"Nox Lacrimarum\""
  ],
  "elven helmet of alchemy": [
    "Alfa Galea Alchemiae"
  ],
  "dwemer inquiries vol ii": [
    "Investigationes Dvemeres II"
  ],
  "glass greatsword of shocks": [
    "Spatha Vitrea Electricitatis"
  ],
  "potion of minor stamina": [
    "Potio Minoris Staminae"
  ],
  "hunting bow of frost": [
    "Arcus Venandi Gelidus"
  ],
  "shroud hearth depths": [
    "Ima Foci Velati"
  ],
  "letter from falk firebeard": [
    "Epistula a Falco Ignibarbo"
  ],
  "magic weapons lose power with each successful hit, but can be recharged with filled soul gems": [
    "Arma Magica vim amittunt singulis ictibus, sed gemmanimis plenis repleri possunt"
  ],
  "warlock ally faction": [
    "Warlock Ally Faction"
  ],
  "what's your story, old man": [
    "Quid est fabula tua, senex"
  ],
  "hide bracers of deft hands": [
    "Chirothecae Cutis Dexteritatis"
  ],
  "chance's folly": [
    "Fatuitis Fati"
  ],
  "a game at dinner": [
    "Ludus ad Cenam"
  ],
  "game at dinner": [
    "Ludus ad Cenam"
  ],
  "actually, i'm not sure i have it anymore": [
    "Re vera, Nescio num id iam habeam"
  ],
  "the contract is still on": [
    "Foedus iam manet"
  ],
  "contract is still on": [
    "Foedus iam manet"
  ],
  "the true emperor must be assassinated": [
    "Imperator verus necari debet"
  ],
  "true emperor must be assassinated": [
    "Imperator verus necari debet"
  ],
  "eye height": [
    "Oculorum Altitudo"
  ],
  "i need to get into the pelagius wing": [
    "Opus est mihi Alam Pelagii intrare"
  ],
  "you're going to make me a werewolf": [
    "Me facies versipellem"
  ],
  "viding": [
    "Viding"
  ],
  "yes, i am sure": [
    "Sic, certus sum"
  ],
  "i will give you a good death": [
    "Tibe mortem bonum dabo"
  ],
  "iron shield of dwindling flames": [
    "Ferri Scutum Flammarum Diminuentium"
  ],
  "i've got the gems you asked for": [
    "Gemmas a te rogatas habeo"
  ],
  "key to enmon's house": [
    "Clavis ad Domum Enmonis"
  ],
  "blue butterfly wing": [
    "Ala Papilionis Caerulei"
  ],
  "spell tome: ice spike": [
    "Liber Magicus: Hamus Glaciei"
  ],
  "imperial light shield of blocking": [
    "Romana Parma Interponendi"
  ],
  "you are invisible": [
    "Invisibilis es"
  ],
  "dwarven boots of hauling": [
    "Dvemeres Caligae Portandi"
  ],
  "we're here to rescue you": [
    "Hic sumus ut te servemus"
  ],
  "is there something bothering you, gorm": [
    "Estne quid quod te molestat, Gorm"
  ],
  "don't know what else to say... i had nothing to do with this": [
    "Nescio quid aliam dicam... nihil cum hoc habui"
  ],
  "staff of reanimation": [
    "Rudis Reanimationis"
  ],
  "i know about weylin": [
    "De Veylin scio"
  ],
  "dbnazirevictionbranchtopic": [
    "DBNazirEvictionBranchTopic"
  ],
  "opened the container with a key": [
    "Aperuisti clave"
  ],
  "dark brotherhood initiate": [
    "Fraternitatis Obscurae Novitia"
  ],
  "imperial bow of soul snares": [
    "Imperialis Arcus Animicapti"
  ],
  "armor - dragonhide": [
    "Lorca - Draconireno"
  ],
  "your father changed his mind": [
    "Pater sententiam mutavit"
  ],
  "i'm here to help, in any way i can": [
    "Hic sum ullo modo adiuvare"
  ],
  "ascendant conjurer": [
    "Vocator Ascendens"
  ],
  "ebony war axe of harvesting": [
    "Bellisecuris Ebeni Saturni"
  ],
  "key to the dibella inner sanctum": [
    "Clavis ad Sanctum Interius Dibellae"
  ],
  "lycan stash": [
    "Arca Lycanis"
  ],
  "dragonplate helmet of peerless restoration": [
    "Draconilaminata Galea Maximi Recreandi"
  ],
  "you're going to be my shield-sister": [
    "Scuti-Soror mea eris"
  ],
  "cow hide": [
    "Vaccae Reno"
  ],
  "i'll ask arngeir if he knows what shout they used": [
    "Arngeirem rogam num illum Clamorem qua usi sunt sciret"
  ],
  "derkeethus": [
    "Derkeethus"
  ],
  "you served in the legion": [
    "In Legione militavisti"
  ],
  "dragonscale gauntlets of peerless archery": [
    "Draconisquamatae Manicae Optimae Saggitationis"
  ],
  "you just told me you missed adventure": [
    "Mihi modo dixiste te pugnare desiderare"
  ],
  "too frightened to leave home": [
    "Nimis timidus ut domo exeas",
    "Nimis timidus es ut domo exeas"
  ],
  "marksmalehumanoid07rightgash": [
    "MarksMaleHumanoid07RightGash"
  ],
  "why don't you come with me": [
    "Quidni mecum eas"
  ],
  "tell me about the gray-manes": [
    "Dic mihi de Juba-Canis"
  ],
  "vidrald": [
    "Vidrald"
  ],
  "gwendolyn": [
    "Gvendolyn"
  ],
  "dreamsteal": [
    "Dreamsteal"
  ],
  "default race": [
    "Default Race"
  ],
  "won't happen again": [
    "Iterum non accidet"
  ],
  "orcish war axe of harvesting": [
    "Orci Bellisecuris Saturni"
  ],
  "tell me about the mammoth tusk powder": [
    "Dic mihi de Pulvo Dentis Elephantis"
  ],
  "what does this have to do with dragons": [
    "Quid cum draconibus agitur"
  ],
  "why are you here, then": [
    "Cur hic es"
  ],
  "she probably appreciates assertive men": [
    "Certe illa viros fortes mavult"
  ],
  "is badly wounded": [
    "prope mortem est"
  ],
  "steel helmet of minor destruction": [
    "Adamantea Galea Minoris Destructionis"
  ],
  "odfel": [
    "Odfel"
  ],
  "saxhleel": [
    "Saxhleel"
  ],
  "what if i get caught killing someone": [
    "Quid accidet si in flagrante delicto captus ero"
  ],
  "eldergleam": [
    "Eldergleam"
  ],
  "ancient nord sword of frost": [
    "Gladius Antiquus Germanicus Gelidus"
  ],
  "but you ended up doing it anyway": [
    "Sed id fecisti"
  ],
  "(<global=hdriftenkitchen> gold)": [
    "(<Global=HDRiftenKitchen> auri)"
  ],
  "darkest darkness": [
    "Obscuratatissimus"
  ],
  "grand soul gem": [
    "Gemmanima Magna"
  ],
  "how can you be sure thorald is still alive": [
    "Quomodo Thoraldem viere scire potes"
  ],
  "how can you compete with black-briar meadery": [
    "Quomodo contra Hydromellarium Atri-Dumi certari potes"
  ],
  "ulfr the blind": [
    "Ulfr Caecus"
  ],
  "anska": [
    "Anska"
  ],
  "elven war axe of lethargy": [
    "Alfus Bellisecuris Lethargiae"
  ],
  "it could kill me": [
    "Me necare possit"
  ],
  "imperial helmet of major archery": [
    "Romana Galea Maioris Sagittationis"
  ],
  "dwarven boots": [
    "Dvemeres Caligae"
  ],
  "glass battleaxe of torpor": [
    "Bellisecuris Vitreus Torporis"
  ],
  "force of habit, i guess": [
    "Vis soliti, ut puto"
  ],
  "tell me about narfi": [
    "Dic mihi de Narfi"
  ],
  "hide shield of dwindling flames": [
    "Scutum Cutis Flammarum Diminuentium"
  ],
  "disarm 2": [
    "Disarm 2"
  ],
  "all clothing and jewelry": [
    "All Clothing et Jewelry"
  ],
  "why do we need nettlebane": [
    "Quamobrem Nettlebane egemus"
  ],
  "daedric sword of malediction": [
    "Gladius Daedricus Maledictionis"
  ],
  "identify riften's skooma dealer": [
    "Investiga Coquum Skoomae Hiati"
  ],
  "virtuous glass war axe": [
    "Virtutis Glass Bellisecuris"
  ],
  "vdsg manual": [
    "VDSG MANUAL"
  ],
  "improved swindler's voice": [
    "Improved Swindler's Voice"
  ],
  "banish summoned": [
    "Repelle Vocatos"
  ],
  "steirod": [
    "Steirod"
  ],
  "blessed hunting bow": [
    "Sanctificatus Arcus Venandi"
  ],
  "threki the innocent": [
    "Threki Innocens"
  ],
  "%s increased by %d": [
    "%s auctum est %d"
  ],
  "what happened to the blades": [
    "Quid ensibus accidit"
  ],
  "why are you on the run": [
    "Cur effugis"
  ],
  "escaped prisoner": [
    "Captivus Effigitus"
  ],
  "humanbeard06": [
    "HumanBeard06"
  ],
  "arvel's journal": [
    "Ephemeris Arvelis"
  ],
  "fletcher conversation 2": [
    "Fletcher Conversation 2"
  ],
  "chief burguk": [
    "Princeps Burguk"
  ],
  "you're the castle blacksmith": [
    "Ferrarius castellis es"
  ],
  "you're a priest of arkay": [
    "Sacerdos Arkayae es"
  ],
  "how'd you pick up the herb trade": [
    "Quomodo herbas vendere coepisti"
  ],
  "fortify shouts": [
    "Fortifica Clamores"
  ],
  "what if i could find that helmet for you": [
    "Quidni illam Galeam tibi inveniam"
  ],
  "watch your words next time": [
    "Proxima occasione cave verba tua"
  ],
  "daedric war axe of the blaze": [
    "Bellisecuris Daedricus Congflagrantis"
  ],
  "iron sword of embers": [
    "Gladius Ferri Cinerum"
  ],
  "i found the synod in mzulft": [
    "Synod in Mzulft inveni"
  ],
  "iron helmet of minor magicka": [
    "Ferri Galea Minoris Magicae"
  ],
  "garnet": [
    "Spessartinum"
  ],
  "sure, i can teach you": [
    "Certe, te docere possum"
  ],
  "skyrim's big enough for everyone": [
    "Caelifinis satis magnus est omnibus"
  ],
  "spell tome: ironflesh": [
    "Liber Magicus: Ferripellis"
  ],
  "steel greatsword of chills": [
    "Spatha Adamantea Frigidis"
  ],
  "imperial sword of fatigue": [
    "Gladius Romanus Defatigationis"
  ],
  "which poison i should use": [
    "Quo Veneno uti debeo"
  ],
  "hunting bow of cowardice": [
    "Arcus Venandi Timoris"
  ],
  "where are the books you stole": [
    "Ubi sunt libri quos furatus es"
  ],
  "ebony mace of storms": [
    "Maca Ebeni Tempestatium"
  ],
  "maleheadelder": [
    "MaleHeadElder"
  ],
  "how do you know delphine": [
    "Quomodo Delphinam scis"
  ],
  "dungeon delving (caves)": [
    "Explorans (Caves)"
  ],
  "where is the midden": [
    "Ubi est Sterculinum"
  ],
  "potion of vigorous stamina": [
    "Potio Vigentis Staminae"
  ],
  "i'm not here to make friends, old man": [
    "Hic non sum ut amicos faciam, senex"
  ],
  "femaleeyeswoodelfbrown": [
    "FemaleEyesWoodElfBrown"
  ],
  "iron mace of shocks": [
    "Ferri Maca Electricitatis"
  ],
  "it's nice to meet you, arnbjorn": [
    "Guadeo te cognoscere quoque, Arnbjorn"
  ],
  "you know nothing about me, or my abilities": [
    "Nihil de me scis, nec de artibus meis"
  ],
  "it's nice to meet you, gabriella": [
    "Guadeo te cognoscere quoque, Gabriella"
  ],
  "orcish sword": [
    "Orci Gladius"
  ],
  "it's nice to meet you, veezara": [
    "Guadeo te cognoscere quoque, Veezara"
  ],
  "glass shield of dwindling fire": [
    "Vitreum Scutum Minoris Ignis"
  ],
  "it's nice to meet you, babette": [
    "Guadeo te cognoscere quoque, Babette"
  ],
  "i will kill anyone who stands in my way. anyone": [
    "Quemquam qui mihi obstet necam"
  ],
  "galmar stone-fist": [
    "Galmar Lapidi-Pugna"
  ],
  "fiik": [
    "Fiik"
  ],
  "umm": [
    "Umm"
  ],
  "you have to do this yourself": [
    "Debesne te ipsum hoc facere"
  ],
  "ebony war axe of banishing": [
    "Bellisecuris Ebeni Expellendi"
  ],
  "what can you tell me of the dragon cult": [
    "Quid mihi dicere potes de Religione Draconum"
  ],
  "this will be easier without you getting in my way": [
    "Facilius erit sine te"
  ],
  "humanskinbasewhite10": [
    "HumanSkinBaseWhite10"
  ],
  "wretched abyss": [
    "Abyssum Nefas"
  ],
  "elven dagger of immobilizing": [
    "Pugio Alfus Immobilitatis"
  ],
  "that doesn't seem like a good attitude": [
    "Bonum consilium mihi non videtur"
  ],
  "you mentioned trading caravans": [
    "Commemoravisti caravanas"
  ],
  "so you're treated badly because of your work": [
    "Ergo male tractaris quod prave laboras"
  ],
  "leather boots of waning frost": [
    "Corii Caligae Insenescentis Congelationis"
  ],
  "regain hjaalmarch": [
    "Recipe Hjaalmarcem"
  ],
  "tell me about beitild": [
    "Dic mihi de Beitild"
  ],
  "good luck finding her from there": [
    "Bonam fortunam in eam inveniendo tibi exopto"
  ],
  "who's this contact of yours": [
    "Quis est intermedius tuus"
  ],
  "you're sure i can trust him": [
    "Esne certus me ei confidere posse"
  ],
  "please, allow me access": [
    "Me intrare sine, quaeso"
  ],
  "steel helmet of alteration": [
    "Adamantea Galea Mutationis"
  ],
  "broken iron mace handle": [
    "Fractus Ferri Maca Cherolaba"
  ],
  "now let me pass": [
    "Sine me transire"
  ],
  "femalebrowshuman08": [
    "FemaleBrowsHuman08"
  ],
  "humanbeardlong09_1bit": [
    "HumanBeardLong09_1bit"
  ],
  "doesn't sound like something i'm interested in": [
    "Non mihi videtur quid quod mihi interesse"
  ],
  "afflicted's note": [
    "Epistula Afflicti"
  ],
  "dialoguegenericzkeyobject": [
    "DialogueGenericZKeyObject"
  ],
  "so the thalmor think the blades know about the dragons": [
    "Ergo Visigothi credunt Enses de draconibus scire"
  ],
  "i'll make sure it's done": [
    "Videbo ut id fiat"
  ],
  "what sort of help do you need": [
    "Quale auxilium quaeris"
  ],
  "what are you doing in skyrim": [
    "Quid hic in Caelifine facis"
  ],
  "urog": [
    "Urog"
  ],
  "steel warhammer of fatigue": [
    "Bellimalleus Adamanteus Defatigationis"
  ],
  "do you want to help me with something": [
    "Visne me cum aliquo adiuvare"
  ],
  "got any dirt on this": [
    "Habesne informationem de hac"
  ],
  "(show unusual gem)": [
    "(Demonstra gemmam mirabilem)"
  ],
  "imperial helmet of alchemy": [
    "Romana Galea Alchemiae"
  ],
  "you want to help me kill the vampire": [
    "Visne me in vampirum necando adiuvare"
  ],
  "potent aversion to shock": [
    "Potens Aversio Electricitati"
  ],
  "sounds like she just likes us to suffer": [
    "Mihi videtur she just likes us to suffer"
  ],
  "do you know any old ballads about dragons": [
    "Scisne carmina vetera de draconibus"
  ],
  "whiterun braith saffir scene 2": [
    "Whiterun Braith Saffir Scene 2"
  ],
  "why are shouts in the dragon language": [
    "Cur clamores in lingua draconis quaeris"
  ],
  "elven battleaxe of harvesting": [
    "Bellisecuris Alfus Saturni"
  ],
  "dwarven helmet of eminent alteration": [
    "Dvemeris Galea Eminentis Mutationis"
  ],
  "humanbeardlong17_1bit": [
    "HumanBeardLong17_1bit"
  ],
  "actors dead bodies won't trigger world interactions": [
    "Actors dead bodies won't trigger World Interactions"
  ],
  "how's your father to blame for your job": [
    "Cur est pater tuus negotio tuo culpabilis"
  ],
  "dwarven greatsword of depleting": [
    "Dvemeris Spatha Deplendi"
  ],
  "i think i'll hold on to it for the time being": [
    "Malo eam nunc retinere"
  ],
  "you look like you could use a hand": [
    "Videres te auxilio uti posse"
  ],
  "and that's where i come in": [
    "Et in illa re adiuvare possum"
  ],
  "pickpocket trainer": [
    "Pickpocket Trainer"
  ],
  "fourth ring": [
    "Circulus Quartus"
  ],
  "hermir strong-heart": [
    "Hermir Forti-Cor"
  ],
  "large wooden door": [
    "Ianuam Magnam Ligneam"
  ],
  "telrav": [
    "Telrav"
  ],
  "retrieve the <alias=gewgaw> from <alias=location>": [
    "Refer <Alias=Gewgaw> ab <Alias=Location>"
  ],
  "i'll be fine": [
    "Bene habebo"
  ],
  "you just take care of yourself": [
    "Solum de te cura"
  ],
  "ebony sword of evoking": [
    "Gladius Ebeni Evocandi"
  ],
  "saarthal excavation": [
    "Saarthalis Excavatio"
  ],
  "stormcloak quartermaster": [
    "Sturmmantelorum Quaestor"
  ],
  "mehrunes' razor display case": [
    "Loculamentum Novaculae Croni"
  ],
  "deal with the rogue wizard": [
    "Neca Thaumaturgon Sine Domino"
  ],
  "i heard there was a valuable statue here": [
    "Audivi statuam magni pretii hic esse"
  ],
  "bee and barb scene 11": [
    "Bee et Barb Scene 11"
  ],
  "dwarven sphere centurion arrow": [
    "Dwemeris Sphere Centurio Arrow"
  ],
  "what is the penalty": [
    "Quae sunt poenae"
  ],
  "thorgar": [
    "Thorgar"
  ],
  "daedric sword of lethargy": [
    "Gladius Daedricus Lethargiae"
  ],
  "mannimarco, king of worms": [
    "Mannimarco, Rex Vermium"
  ],
  "the wolf queen, v4": [
    "Regina Luporum, IV"
  ],
  "wolf queen, v4": [
    "Regina Luporum, IV"
  ],
  "what use is that": [
    "Quid est utilitas illius"
  ],
  "mq104dragonfaction": [
    "MQ104DragonFaction"
  ],
  "i'm not your errand girl": [
    "Puella munerum tua non sum"
  ],
  "holy daedric dagger": [
    "Sanctus Pugio Daedricus"
  ],
  "i will undertake this penance": [
    "Poenas dare conabor"
  ],
  "where is the village": [
    "Ubi est vicus"
  ],
  "they just asked me to find you": [
    "Me modo oraverunt ut te inveniam"
  ],
  "that was always there": [
    "Illene Semper Illic Erat"
  ],
  "reduced weapon skills": [
    "Artes Armorum Reducta"
  ],
  "is there anything further i can do for the cause": [
    "Estne quid plus pro Rebus Novis"
  ],
  "look for forges and grindstones in the world": [
    "Quaere Fabricas et Molinulas in mundo"
  ],
  "you can use them to make new weapons and armor, and improve what you already have": [
    "Illis uti potes ut loricas vel arma nova facias, et augeas eos quos quam habes"
  ],
  "annals of the dragonguard": [
    "Annales Draconicustodum"
  ],
  "she's in the bannered mare": [
    "Illa est in Caballa Vexillata"
  ],
  "she knows you're out here": [
    "Illa scit te hic esse"
  ],
  "maleheadbretonafflicted": [
    "MaleHeadBretonAfflicted"
  ],
  "scaled armor of the squire": [
    "Lorica Squamata Armiferis"
  ],
  "glass helmet of major destruction": [
    "Galea Vitrea Maioris Destructionis"
  ],
  "mammoth cheese": [
    "Caseus Elephantis"
  ],
  "ebony mace of lightning": [
    "Maca Ebeni Fulminis"
  ],
  "combattonormal": [
    "CombatToNormal"
  ],
  "dwarven sword of gleaning": [
    "Gladius Dvemeris Saturni"
  ],
  "riften pawned prawn faction": [
    "Hiati Pawned Prawn Faction"
  ],
  "who did this": [
    "Quis hoc fecit"
  ],
  "read thonar's journal": [
    "Lege Ephemeridem Thonaris"
  ],
  "is there a key for this cage": [
    "Estne clavis huic carceri"
  ],
  "bring reyda's necklace to narfi": [
    "Fer Monile Reydae ad Narfem"
  ],
  "patio decorations": [
    "Patio Ornamenta"
  ],
  "(<global=hdsolitudepatio> gold)": [
    "(<Global=HDSolitudePatio> auri)"
  ],
  "iron armor of health": [
    "Ferrata Lorica Salutis"
  ],
  "gold ring": [
    "Anulus Aureus"
  ],
  "street vendor": [
    "Street Vendor"
  ],
  "you're the chief": [
    "Esne Princeps"
  ],
  "bring fenrig to ruki": [
    "Fer Fenrig ad Rukem"
  ],
  "the leader of <alias=dungeon> is dead": [
    "Dux apud <Alias=Dungeon> mortuus est"
  ],
  "leader of <alias=dungeon> is dead": [
    "Dux apud <Alias=Dungeon> mortuus est"
  ],
  "the alik'r won't trouble you anymore": [
    "Alik'res te non iam molestabunt"
  ],
  "alik'r won't trouble you anymore": [
    "Alik'res te non iam molestabunt"
  ],
  "femaleheadkhajiit": [
    "FemaleHeadKhajiit"
  ],
  "daedric war axe of the inferno": [
    "Bellisecuris Daedricus Conflagrationis"
  ],
  "glass bow of dread": [
    "Arcus Vitreus Timoris"
  ],
  "staff enchantment": [
    "Incantamenta Rudis"
  ],
  "steel sword of embers": [
    "Gladius Adamanteus Cinerum"
  ],
  "please cure me": [
    "Da mihi remedium, quaeso"
  ],
  "grand plaza scene 30": [
    "Grand Plaza Scene 30"
  ],
  "banded iron shield of major blocking": [
    "Ferri Adligati Scutum Maioris Interponendi"
  ],
  "what do you do in kynesgrove": [
    "Quid Kyninemoris facis"
  ],
  "philter of strength": [
    "Philtrum Fortitudinis"
  ],
  "ingredient had no effect": [
    "Ingrediens nullum effectum habet"
  ],
  "i gave the dagger to andurs": [
    "Pugionem Anduri dedi"
  ],
  "orcish dagger of devouring": [
    "Orci Pugio Consumendi"
  ],
  "wisp faction": [
    "Wisp Faction"
  ],
  "dragonplate helmet of extreme alteration": [
    "Draconilaminata Galea Extremis Mutationis"
  ],
  "orcish battleaxe of thunderbolts": [
    "Orci Bellisecuris Fulminum"
  ],
  "key to arnleif and sons trading company": [
    "Clavis ad Negotium Commercii Arnleifis et Filiorum"
  ],
  "this note says you're a liar": [
    "Haec adnotatio dicit te mendacem esse"
  ],
  "(show note)": [
    "(Adnotationem demonstra)"
  ],
  "jora": [
    "Jora"
  ],
  "femaleeyeswoodelflightbrown": [
    "FemaleEyesWoodElfLightBrown"
  ],
  "daedric bow of blizzards": [
    "Arcus Daedricus Hiemis"
  ],
  "speak to madesi": [
    "Madesi Adloquere"
  ],
  "i understand you're an elf who knows how to get things": [
    "Intellego te alfum esse qui res invenire potest"
  ],
  "the question is, what have you got": [
    "Sed rogatum est quid habes tu"
  ],
  "question is, what have you got": [
    "Sed rogatum est quid habes tu"
  ],
  "no coin right now": [
    "Nulla pecunia nunc"
  ],
  "commentary object": [
    "Commentary Object"
  ],
  "iron war axe of sapping": [
    "Bellisecuris Ferri Hauriendi"
  ],
  "iron helmet of minor conjuring": [
    "Ferri Galea Minoris Vocandi"
  ],
  "let me worry about the guards": [
    "Sine me de custodibus curare"
  ],
  "snow-shod farm faction": [
    "Snow-Shod Farm Faction"
  ],
  "why is this place called the winking skeever": [
    "Cur nomen 'Skeever Adnictans' habet"
  ],
  "femalebrowshuman05": [
    "FemaleBrowsHuman05"
  ],
  "drelas' cottage": [
    "Drelae Casa"
  ],
  "madesi": [
    "Madesi"
  ],
  "scroll of frost rune": [
    "Codex Runi Glaciei"
  ],
  "daedric dagger of leeching": [
    "Pugio Daedricus Sanguisugae"
  ],
  "steel battleaxe of weariness": [
    "Bellisecuris Adamanteus Maioris Defatigationis"
  ],
  "gabriella mentioned something about cicero": [
    "Gabriella Aliquid de Cicerone dixit"
  ],
  "ilas-tei": [
    "Ilas-Tei"
  ],
  "honed ancient nord sword": [
    "Melior Germanicus Gladius Antiquus"
  ],
  "i want to find out what it means to be dragonborn": [
    "Volo scire quid sibi vult Draconigenam esse"
  ],
  "riftweald manor": [
    "Riftveald Villa"
  ],
  "elven dagger of diminishing": [
    "Pugio Alfus Diminuendi"
  ],
  "maleeyeshumanyellow": [
    "MaleEyesHumanYellow"
  ],
  "crew of the red wave": [
    "Crew of the Red Wave"
  ],
  "so what does it say": [
    "Ergo, quid dicit"
  ],
  "dwarven bow of diminishing": [
    "Dvemeris Arcus Diminuendi"
  ],
  "saarthal interior scene 2": [
    "Saarthalis Interior Scene 2"
  ],
  "hairfemaleorc05": [
    "HairFemaleOrc05"
  ],
  "tell me about the agent of stealth": [
    "Dic mihi de Agente Furis"
  ],
  "what if i took care of the forsworn": [
    "Quidni ego Abiuratos 'curem'"
  ],
  "banded iron armor of health": [
    "Ferro Adligata Lorica Salutis"
  ],
  "south brittleshin pass": [
    "Commeatus Australis Fragilicruris"
  ],
  "\"...jarl of windhelm and true high king of skyrim.\"": [
    "\"...Comes Ventigaleae et Rex Altus Verus Caelifinis.\""
  ],
  "lotus extract": [
    "Loti Extractumum"
  ],
  "db07ciceroconfrontbranchtopic": [
    "DB07CiceroConfrontBranchTopic"
  ],
  "i'll stay out, then": [
    "Sic non intrabo"
  ],
  "any news involving the dark brotherhood": [
    "Quid scis de Fraternitate Obscura"
  ],
  "large decorative dwemer strut": [
    "Magnum Trabs Dvemere Decorativum"
  ],
  "she wants you to leave her alone": [
    "Desiste ab ea"
  ],
  "alchemy trainer": [
    "Alchemy Trainer"
  ],
  "mantled college robes": [
    "Capulatum Collegii Peplum"
  ],
  "how do you know these things": [
    "Quomodo has res scis"
  ],
  "you think you know how to repair the phial": [
    "Putans te artem Atrophorion reficiendi scire"
  ],
  "orcish dagger of frost": [
    "Orci Pugio Gelidus"
  ],
  "brelas": [
    "Brelas"
  ],
  "tg08bmerceroutrobranchtopic": [
    "TG08BMercerOutroBranchTopic"
  ],
  "daedric boots": [
    "Caligae Daedricae"
  ],
  "relic little finger": [
    "Reliquium Parvulus"
  ],
  "glass war axe of reaping": [
    "Bellisecuris Vitreus Saturni"
  ],
  "so, where is the scroll": [
    "Ergo, ubi est Codex"
  ],
  "i'll take my pay": [
    "Pecuniam meam accipiam"
  ],
  "mysterious akavir": [
    "Akavir Mirabilis"
  ],
  "do you surrender": [
    "Succumbisne mihi"
  ],
  "banish/command daedra push": [
    "Banish/Command Daedra Push"
  ],
  "i'm curious about your homeland": [
    "De patria tua scire volo"
  ],
  "so you're a little lost puppy": [
    "Sic parvus catullus amissus es"
  ],
  "autumnwatch tower": [
    "Turris Autumnivisus"
  ],
  "you're a stormcloak": [
    "Sturmmantelus es"
  ],
  "you're already holding something": [
    "You're already holding something"
  ],
  "adrianne avenicci": [
    "Adrianna Avenicci"
  ],
  "maleheadargonian": [
    "MaleHeadArgonian"
  ],
  "fortify speech and one-h": [
    "Auget Loquacitatem et Singuli-Manu"
  ],
  "i've got the cogs you asked for": [
    "Denticulos postulatos tibi habeo"
  ],
  "what did you need me to do for you": [
    "Quid me pro te facere voluisti"
  ],
  "did you need any more help with your project": [
    "Visne plus auxilii cum opere tuo"
  ],
  "spell tome: reanimate corpse": [
    "Liber Magicus: Corpus Revoca"
  ],
  "i understand you have something of arniel's": [
    "Intellego te aliquid Arnielis habere"
  ],
  "here's the staff you wanted": [
    "Hic rudem quem voluisti habes"
  ],
  "i've got the soul gem you were after": [
    "Habeo illam gemmanimam quam quaesivisti"
  ],
  "iron dagger": [
    "Pugio Ferri"
  ],
  "the war is stalemated": [
    "Bellum est aequum"
  ],
  "war is stalemated": [
    "Bellum est aequum"
  ],
  "iron sword of burning": [
    "Gladius Ferri Cremandi"
  ],
  "can you build another": [
    "Potesne alium facere"
  ],
  "virtuous glass warhammer": [
    "Virtutis Bellimaleus Vitreus"
  ],
  "eight-sided": [
    "Octo-latera"
  ],
  "jade, ringed with ebony": [
    "Venifica, ebeno circumdata"
  ],
  "a worn carving": [
    "Anaglyphum fragosum"
  ],
  "worn carving": [
    "Anaglyphum fragosum"
  ],
  "have you unraveled the mystery of the dwarves yet": [
    "Umquamne Solvisti mysterium Dwemerium"
  ],
  "dwarven helmet of major illusion": [
    "Dvemeris Galea Maioris Inlusionis"
  ],
  "what do you want me to say to enthir": [
    "Quid vis ut Enthiri dicam"
  ],
  "nelacar": [
    "Nelacar"
  ],
  "what sort of \"payment\" do you expect this time": [
    "Quid \"debitum\" nunc vis"
  ],
  "i'm not running any more errands for you": [
    "Nulla plurima munera tibi faciam"
  ],
  "viola's gold ring": [
    "Anulus Aureus Violae"
  ],
  "enthir really doesn't have whatever it is you asked him for": [
    "Enthir re vera non habed id quod de eo petivisti"
  ],
  "the courier never arrived with it": [
    "Nuntius id numquam tulit"
  ],
  "courier never arrived with it": [
    "Nuntius id numquam tulit"
  ],
  "dragonscale boots of warmth": [
    "Caligae Draconisquamatae Caloris"
  ],
  "butcher journal": [
    "Ephemeris Carnificis"
  ],
  "ebony mace of the vampire": [
    "Maca Ebeni Vampiri"
  ],
  "deliver the ring to calcelmo": [
    "Fer Anulum ad Calcelmonem"
  ],
  "unmelting snowbank": [
    "Nivis Sempiternalis"
  ],
  "elven bracers of eminent wielding": [
    "Chirothecae Alfae Eminentis Tenendi"
  ],
  "elven bracers of alchemy": [
    "Chirothecae Alfae Alchemiae"
  ],
  "didn't you tell enthir this was valuable": [
    "Nonne Enthiri hunc pretiosum esse narravisti"
  ],
  "dwarven battleaxe of thunderbolts": [
    "Dvemeris Bellisecuris Fulminum"
  ],
  "none that i care to talk about": [
    "Nullas de quibus loqui velim"
  ],
  "seren": [
    "Seren"
  ],
  "dwarven battleaxe of fear": [
    "Dvemeris Bellisecuris Timoris"
  ],
  "daedra heart": [
    "Cor Daedrae"
  ],
  "heartwood mill house faction": [
    "Heartwood Mill House Faction"
  ],
  "that fellow over there asked me to talk to you": [
    "Iste illic mihi suasit ut te adloquar"
  ],
  "blessed steel dagger": [
    "Sanctificatus Pugio Adamanteus"
  ],
  "i would use the blue thing": [
    "Re caerulea utar"
  ],
  "elven helmet of destruction": [
    "Alfa Galea Destructionis"
  ],
  "winking skeever conversation 6": [
    "Winking Skeever Conversation 6"
  ],
  "she's all yours": [
    "Illa est omnino tua"
  ],
  "these things can't be rushed": [
    "Hae res festinari non debent"
  ],
  "steel sword of binding": [
    "Gladius Adamanteus Deligandi"
  ],
  "detect life fx visuals": [
    "Detect Life FX Visuals"
  ],
  "ebony gauntlets of major archery": [
    "Ebeni Manicae Maioris Sagittationis"
  ],
  "the exodus": [
    "Exodus"
  ],
  "exodus": [
    "Exodus"
  ],
  "ebony mace of exhaustion": [
    "Maca Ebeni Exhaustionis"
  ],
  "defeat ancano": [
    "Neca Ancano"
  ],
  "fire/frost/shock mage": [
    "Fire/Frost/Shock Mage"
  ],
  "vampiric invisibility": [
    "Invisibilitas Vampirica"
  ],
  "steel greatsword of sparks": [
    "Spatha Adamantea Scintillarum"
  ],
  "dwarven helmet of major magicka": [
    "Dvemeris Galea Maioris Magicae"
  ],
  "isabelle rolaine": [
    "Isabelle Rolaine"
  ],
  "elven boots of brawn": [
    "Caligae Alfae Fortitudinis"
  ],
  "dwarven armor of the major knight": [
    "Dvemeris Lorica Maioris Equitis"
  ],
  "i'd like to collect my earnings": [
    "Volo impensum meum colligere"
  ],
  "i'll... consider your request": [
    "De desiderio tuo cogitabo"
  ],
  "master censer": [
    "Master Censer"
  ],
  "wooden bowl": [
    "Ligni Patera"
  ],
  "daedric shield of the firewalker": [
    "Scutum Daedricum Ignambulatoris"
  ],
  "i could find those frost salts for you": [
    "Illa salia glaciei pro te invenire possum"
  ],
  "hairlinefemalenord10": [
    "HairLineFemaleNord10"
  ],
  "ebony helmet of eminent alchemy": [
    "Ebeni Galea Alchemiae Eminentis"
  ],
  "i have your frost salts": [
    "Salia glaciei tibi habeo"
  ],
  "alva's journal": [
    "Ephemeris Alvae"
  ],
  "scroll of command daedra": [
    "Codex Imperandi Daedrae"
  ],
  "you can't pick a lock while being pursued by guards": [
    "You can't pick lock while being pursued by guards"
  ],
  "steel shield of resist frost": [
    "Adamanteum Scutum Contra Vim Gelidam"
  ],
  "femaleeyesorciceblue": [
    "FemaleEyesOrcIceBlue"
  ],
  "here's my offer... (<global=horsecost> gold)": [
    "Hic habes offertum... (<Global=Horsecost> auri)"
  ],
  "dwarven war axe of devouring": [
    "Dvemeris Bellisecuris Consumendi"
  ],
  "i guess calixto was wrong": [
    "Credo Calixtoni erravisse"
  ],
  "you're sure she's being held here": [
    "Esne certus illam hic iam esse"
  ],
  "glass gauntlets of major alchemy": [
    "Vitreae Manicae Maioris Alchemiae"
  ],
  "ebony war axe of winnowing": [
    "Bellisecuris Ebeni Ventagii"
  ],
  "daedric boots of muffling": [
    "Caligae Daedricae Silentii"
  ],
  "gjuk": [
    "Gjuk"
  ],
  "elven dagger of malediction": [
    "Pugio Alfus Maledictionis"
  ],
  "i'll trade in my hood": [
    "Capucium tradere volo"
  ],
  "player is being escorted by a friend": [
    "Player is being escorted by friend"
  ],
  "dialoguekarthwastenragnarbelchimac02": [
    "DialogueKarthwastenRagnarBelchimac02"
  ],
  "steel plate helmet of destruction": [
    "Laminata Galea Destructionis"
  ],
  "ebony bow of exhaustion": [
    "Arcus Ebeni Exhaustionis"
  ],
  "ancient nord sword of cold": [
    "Gladius Antiquus Germanicus Frigidis"
  ],
  "legate rikke": [
    "Legata Rikke"
  ],
  "ebony war axe of the vampire": [
    "Bellisecuris Ebeni Vampiri"
  ],
  "fire dragon abilities": [
    "Fire Dragon abilities"
  ],
  "i could do it for you": [
    "Id pro de facere possum"
  ],
  "blood harvest": [
    "Sume Sanguinem"
  ],
  "i'm still wondering if i can trust you": [
    "Iam nescio num tibi confidere possim"
  ],
  "amren's family sword": [
    "Ensem Familiae Amrenis"
  ],
  "wylandriah's soul gem": [
    "Gemmanima Wylandriae"
  ],
  "shield of solitude": [
    "Scutum Solitudinis"
  ],
  "the ghost is looking for his sword": [
    "Phantasma gladium suum quaerit"
  ],
  "ghost is looking for his sword": [
    "Phantasma gladium suum quaerit"
  ],
  "dwarven greatsword of binding": [
    "Dvemeris Spatha Deligandi"
  ],
  "daedric mace of terror": [
    "Maca Daedrica Terroris"
  ],
  "iron war axe of weariness": [
    "Bellisecuris Ferri Maioris Defatigationis"
  ],
  "have you heard from thorald": [
    "Aliquid de Thorald audivisi"
  ],
  "gorm": [
    "Gorm"
  ],
  "shor's hall": [
    "Aula Shoris "
  ],
  "ragged flagon scene 05": [
    "Ragged Flagon Scene 05"
  ],
  "your cannibal has been taken care of": [
    "Anthropophagus tuus mortuus est"
  ],
  "here, sister": [
    "Hic id habes, soror"
  ],
  "dwarven mace of depleting": [
    "Dvemeris Maca Deplendi"
  ],
  "animal allegiance 2": [
    "Animal Allegiance 2"
  ],
  "i've brought the fragment": [
    "Fragmentum attuli"
  ],
  "yes, that's how i first learned i was dragonborn": [
    "Sic, ille est modus in quo primo me Draconigenam esse didici"
  ],
  "need me to deliver any more mead": [
    "Vis me Plus Hydromelli emptoribus reddere"
  ],
  "faendal wanted me to deliver this letter to camilla, and say it was from you": [
    "Faendal mihi oravit ut hanc epistulam Camillae adferrem et eam de te esse dicere"
  ],
  "how's the meadery working out": [
    "Quid fit cum hydromellario"
  ],
  "dragonscale helmet of peerless alteration": [
    "Draconisquamata Galea Optimae Mutationis"
  ],
  "pavo attius": [
    "Pavo Attius"
  ],
  "a dragon destroyed helgen": [
    "Drago Helgen expugnavit"
  ],
  "<alias=riverwoodfriend> is afraid riverwood is next": [
    "<Alias=RiverwoodFriend> timet ne Lignivrus secundus sit"
  ],
  "orcish greatsword of scorching": [
    "Orci Spatha Adurendi"
  ],
  "shor's throne": [
    "Solium Shoris"
  ],
  "that i need to kill him and take on his identity": [
    "Nihil praeter illo egeo ut eum adsimulare possim"
  ],
  "elven battleaxe of consuming": [
    "Bellisecuris Alfus Consumendi"
  ],
  "so you don't even know who the gourmet is": [
    "Ergo nescis quem Apicium esse"
  ],
  "can the thalmor be trusted": [
    "Visigothine credi possunt"
  ],
  "steal the <alias=questitem> for <alias.shortname=questgiver>": [
    "Furare <Alias=QuestItem> pro <Alias.ShortName=QuestGiver>"
  ],
  "iron greatsword of burning": [
    "Spatha Ferri Cremandi"
  ],
  "how can i get to your master": [
    "Quomodo possum apud dominum tuum ire"
  ],
  "<alias.shortname=target> will be paying you all more": [
    "<Alias.ShortName=Target> plus pecuniae vobis dabit"
  ],
  "db attack gianna faction": [
    "DB Attack Gianna Faction"
  ],
  "hunting bow of burning": [
    "Arcus Venandi Cremandi"
  ],
  "steel plate bracers of archery": [
    "Chirothecae Laminatae Saggitationis"
  ],
  "hjalti": [
    "Hjalti"
  ],
  "who are you talking about": [
    "De quo loqueris",
    "Do qua re loqueris"
  ],
  "are all the orc strongholds guarded": [
    "Habentne omnes Arces Orcorum custodes"
  ],
  "i thought you wanted to get out of there": [
    "Credid te voluisse e illo loco exire"
  ],
  "hairmaleredguard8": [
    "HairMaleRedguard8"
  ],
  "what do you know about whiterun": [
    "Quid de Albacurso scis"
  ],
  "spell tome: healing": [
    "Liber Magicus: Salus"
  ],
  "orcish gauntlets of major smithing": [
    "Orci Manicae Maioris Faciendi"
  ],
  "i was hired to kill you, and that's what i intend to do": [
    "Conductus sum ut te necarem, et illud faciam"
  ],
  "black-briar mead": [
    "Atri-Dumi Hydromellum"
  ],
  "what's all this about the forsworn": [
    "Quid de his Abiuratis"
  ],
  "ma'jhad": [
    "Ma'jhad"
  ],
  "i'd rather not handle that": [
    "Partem in eo non habere malim"
  ],
  "i promise. just a joke i want to play on someone. (persuade)": [
    "Promitto. Modo iocum quo aliquem deludere volo (Persuade)"
  ],
  "mistwatch lower balcony": [
    "Ektheta Minor Nebulavisus"
  ],
  "elven bow of torpor": [
    "Arcus Alfus Torporis"
  ],
  "do you own this mine": [
    "Esne possessor metalli"
  ],
  "marksmaleargonianscar00": [
    "MarksMaleArgonianScar00"
  ],
  "i was sent by louis letrush": [
    "Missus sum a Louis Letrush"
  ],
  "spell tome: flame cloak": [
    "Liber Magicus: Amiculum Flammarum"
  ],
  "slaughterfish faction": [
    "Slaughterfish Faction"
  ],
  "katariah master key": [
    "Clavis Dominus Katariae"
  ],
  "hide shield": [
    "Scutum Renonis"
  ],
  "you don't own the horse you already sold to letrush": [
    "Equum quem ad Letrush iam vendidisti non possides"
  ],
  "who's in charge of dawnstar": [
    "Quis Luciferem Regit"
  ],
  "orcish warhammer of frost": [
    "Orci Bellimalleus Gelidus"
  ],
  "reverent imperial sword": [
    "Reverens Gladius Imperialis"
  ],
  "anything you can tell me about high hrothgar": [
    "Estne quid quod mihi de Hrothgare Alto narrere potes"
  ],
  "ebony mace of banishing": [
    "Maca Ebeni Expellendi"
  ],
  "glass shield of dwindling frost": [
    "Vitreum Scutum Minoris Congelationis"
  ],
  "i thought you were the authority on this subject": [
    "Credidi te scientissimam de hac re esse"
  ],
  "nose length": [
    "Nasi Longitudo"
  ],
  "door bar": [
    "Taleam Ianuae"
  ],
  "riften keep scene 11": [
    "Hiati Keep Scene 11"
  ],
  "the real barenziah, v3": [
    "Barenzia Vera, III"
  ],
  "real barenziah, v3": [
    "Barenzia Vera, III"
  ],
  "someone's paying me to steal maven black-briar's horse": [
    "Aliquis mihi pecuniam dat ut equum Maven Atri-Dumi furer"
  ],
  "potion of minor healing": [
    "Potio Minoris Salutis"
  ],
  "you're no kin of mine, pig-face": [
    "Nullus consanguineus mihi est, sus-facies"
  ],
  "vision of the tenth eye": [
    "Visio Decimi Oculi"
  ],
  "\"can i have that armor?\"": [
    "\"Possumne illam loricam habere?\""
  ],
  "okay, if you don't want it, i'll find someone who does": [
    "Ok, si id non vis, quem qui vult inveniam"
  ],
  "he's hiding out somewhere in the ratway": [
    "In Via Murum se latet"
  ],
  "daedric gauntlets of peerless alchemy": [
    "Daedricae Manicae Optimae Alchemiae"
  ],
  "spell tome: fury": [
    "Liber Magicus: Atrocitas"
  ],
  "the monomyth": [
    "Monomythos"
  ],
  "monomyth": [
    "Monomythos"
  ],
  "circle of vitality": [
    "Circle of Vitality"
  ],
  "ebony war axe of malediction": [
    "Bellisecuris Ebeni Maledictionis"
  ],
  "no, sir": [
    "Minime, domine"
  ],
  "dangerous research must be a problem here": [
    "Certe investigationes periculosae problemata hic sunt"
  ],
  "ebony helmet of eminent alteration": [
    "Ebeni Galea Eminentis Mutationis"
  ],
  "at least you're still alive": [
    "Saltem vivis"
  ],
  "that's something": [
    "Aliquid est"
  ],
  "ebony boots of shock suppression": [
    "Caligae Ebeni Suppresionis Electricitatis"
  ],
  "can't you do it for me": [
    "Quidni tu id pro me facias"
  ],
  "elven war axe of banishing": [
    "Alfus Bellisecuris Expellendi"
  ],
  "mg great hall scene 1": [
    "MG Great Hall Scene 1"
  ],
  "iron mace of sapping": [
    "Ferri Maca Hauriendi"
  ],
  "don't be ridiculous": [
    "Noli insanum esse"
  ],
  "dreams are harmless": [
    "Somnia non nocent"
  ],
  "glass mace of torpor": [
    "Maca Vitrea Torporis"
  ],
  "mephala": [
    "Arachne"
  ],
  "dwarven war axe of debilitation": [
    "Dvemeris Bellisecuris Debilitationis"
  ],
  "then leave": [
    "Ergo discede"
  ],
  "this isn't your problem": [
    "Problema tuum non est"
  ],
  "i've decided to help you end the nightmares": [
    "Elegi te adiuvare et finem ad suppressiones facere"
  ],
  "dwarven helmet of destruction": [
    "Dvemeris Galea Destructionis"
  ],
  "iron gauntlets of minor archery": [
    "Ferri Manicae Minoris Saggitationis"
  ],
  "do you work here": [
    "Laborasne hic"
  ],
  "note from agna": [
    "Epistula ab Agna"
  ],
  "i haven't spoken to him yet": [
    "Nondum ei adlocutus sum"
  ],
  "you sure you won't buy it": [
    "Esne certus id emere non vis"
  ],
  "ebony shield of extreme blocking": [
    "Ebeni Scutum Extremis Interponendi"
  ],
  "balmora blue note": [
    "Eplistula Balmorae Caeruleae"
  ],
  "orcish battleaxe of freezing": [
    "Orci Bellisecuris Gelidus"
  ],
  "steel helmet of major destruction": [
    "Adamantea Galea Maioris Destructionis"
  ],
  "i could help if you tell me": [
    "Te adiuvare possim si me dicas"
  ],
  "leather bracers of minor wielding": [
    "Corii Chirothecae Minoris Tenendi"
  ],
  "hairlinemalenord17": [
    "HairLineMaleNord17"
  ],
  "<alias=imperialmajorholdcapital> is a fair trade for riften": [
    "<Alias=ImperialMajorHoldCapital> pactio aequa pro Hiatu est"
  ],
  "what is the white phial": [
    "Quid est Atrophorion Album"
  ],
  "elven bow of depleting": [
    "Arcus Alfus Deplendi"
  ],
  "boundcaptivemarker": [
    "BoundCaptiveMarker"
  ],
  "daedric mace of harvesting": [
    "Maca Daedrica Saturni"
  ],
  "dwarven armor": [
    "Dvemeris Lorica"
  ],
  "amulet of dibella": [
    "Monile Dibell"
  ],
  "the armorer's challenge": [
    "Certamen Loricarum Fabris"
  ],
  "armorer's challenge": [
    "Certamen Loricarum Fabris"
  ],
  "glass armor": [
    "Vitrea Lorica"
  ],
  "ms08paralyze": [
    "MS08Paralyze"
  ],
  "steel greatsword of binding": [
    "Spatha Adamantea Deligandi"
  ],
  "is the war really over": [
    "Esne bellum re vera factum"
  ],
  "is this your mine": [
    "Estne hoc metallum tuum"
  ],
  "you're from kolskeggr mine": [
    "E Metallo Kolskeggr venis"
  ],
  "what's wrong with riften": [
    "Quid est difficultas cum Hiatu"
  ],
  "orcish dagger of cowardice": [
    "Orci Pugio Timoris"
  ],
  "the pale lady": [
    "Femina Pallida"
  ],
  "pale lady": [
    "Femina Pallida"
  ],
  "keep the rest of my things safe for me": [
    "Fac res ceteras meas tutas"
  ],
  "i have bersi's payment": [
    "Debitum Bersi hic habeo"
  ],
  "hide boots of minor sneaking": [
    "Renonis Caligae Minoris Repsendi"
  ],
  "potion of resist shock": [
    "Potio Contra Vim Electricam"
  ],
  "palla, volume 2": [
    "Palla, Volumen II"
  ],
  "i guess that's as good a reason as any": [
    "Causa satis bene est"
  ],
  "i'm here on behalf of the college": [
    "Hic sum pro Collegio"
  ],
  "no new content available": [
    "Nihil Novi Habes"
  ],
  "is there anything further i can do for the legion": [
    "Estne quid plus quod pro Legione facere possum"
  ],
  "is there anything further i can do": [
    "Estne quid aliud quod facere possum"
  ],
  "kilkreath temple": [
    "Kilkretiae Templum"
  ],
  "move menu": [
    "Move Menu"
  ],
  "thank you, aranea": [
    "Gratias, Aranea"
  ],
  "reverent steel sword": [
    "Reverens Gladius Adamanteus"
  ],
  "how did you meet keerava": [
    "Quomodo Keeravae occuristi"
  ],
  "i've killed nimhe": [
    "Nimhe necavi"
  ],
  "imperial boots of sneaking": [
    "Caligae Romanae Repsendi"
  ],
  "dravynea services": [
    "Dravynea Services"
  ],
  "now that ulfric is dead, will there be peace": [
    "Nunc, Vercingetorigi Mortuo, eritne pax"
  ],
  "dragon heartscales": [
    "Draconis Corsquamae"
  ],
  "riften black briar house faction": [
    "Hiati Black Briar House Faction"
  ],
  "forge, hammer and anvil": [
    "Fabrica, Malleus et Incus"
  ],
  "dwemer cog": [
    "Dvemere Choragium"
  ],
  "dwarven bracers of major wielding": [
    "Dvemeres Chirothecae Maioris Tenendi"
  ],
  "marksmalehumanoid00noscar": [
    "MarksMaleHumanoid00NoScar"
  ],
  "must be dangerous living outside the walls": [
    "Foris parietum vivere periculosum esse debet"
  ],
  "creaturedialoguemudcrab": [
    "CreatureDialogueMudcrab"
  ],
  "i bet you're wrong": [
    "Credo te errare"
  ],
  "glass war axe of despair": [
    "Bellisecuris Vitreus Desponsionis"
  ],
  "steel plate helmet of eminent alchemy": [
    "Laminata Galea Alchemiae Eminentis"
  ],
  "steel dagger of cowardice": [
    "Pugio Adamanteus Timoris"
  ],
  "who is boethiah": [
    "Quis est Boethis"
  ],
  "dragonplate gauntlets of eminent wielding": [
    "Draconilaminatae Manicae Eminentis Tenendi"
  ],
  "how about a rematch": [
    "Quid ni iterum temptemus"
  ],
  "ebony greatsword of debilitation": [
    "Spatha Ebeni Debilitationis"
  ],
  "dragonplate boots of warmth": [
    "Draconilaminata Caligae Caloris"
  ],
  "i think this book may answer your questions": [
    "Credo hoc librum interrogatis tuis reponsa dare posse"
  ],
  "you were looking for me": [
    "Me quaesivisti"
  ],
  "seems all our eggs were in one basket": [
    "Mihi videtur omnia ova in uno canistro esse"
  ],
  "leave it": [
    "Relinque id"
  ],
  "why did you side with the stormcloaks": [
    "Quamobrem te cum factione Sturmmantelorum adiunxisti"
  ],
  "hairmaleorc17": [
    "HairMaleOrc17"
  ],
  "what do you mean \"saviors of mer\"": [
    "Qui sunt \"soteres Merum\""
  ],
  "[caution]": [
    "[CAVE]"
  ],
  "odahviing heal at low health": [
    "Odahviing heal at low health"
  ],
  "draught of lockpicking": [
    "Decocta Clavicarii"
  ],
  "how can i learn it, then": [
    "Ergo, quomodo eum discere possum"
  ],
  "iron boots": [
    "Ferri Caligae"
  ],
  "glass dagger of debilitation": [
    "Pugio Vitreus Debilitationis"
  ],
  "sorry, i'll leave": [
    "Ignosce, exibo"
  ],
  "orcish warhammer of draining": [
    "Orci Bellimalleus Hauriendi"
  ],
  "maven black-briar": [
    "Maven Ater-Dumus"
  ],
  "even after slaying your troublesome spider": [
    "Etiam post araneam molestam necavi"
  ],
  "aerin's house": [
    "Domus Aerinis"
  ],
  "dragonplate helmet of eminent conjuring": [
    "Draconilaminata Galea Coniurationis Vocantis"
  ],
  "blizzard 25": [
    "Blizzard 25"
  ],
  "sanctified steel sword": [
    "Sanctificatus Gladius Adamanteus"
  ],
  "the college of winterhold is an independent organization, and thus was not affected by the dissolution of the mages guild at the beginning of the fourth era": [
    "Collegium Hiemiterrae est sodalitas independens et, sic, non passum est in dissolutione Sodalitatis Magorum ad initium Aetatis Quartae"
  ],
  "college of winterhold is an independent organization, and thus was not affected by the dissolution of the mages guild at the beginning of the fourth era": [
    "Collegium Hiemiterrae est sodalitas independens et, sic, non passum est in dissolutione Sodalitatis Magorum ad initium Aetatis Quartae"
  ],
  "this \"ruminations\" book is incomprehensible": [
    "Hic liber \"Ruminations\" omnino illegibilis est"
  ],
  "leather hood": [
    "Corii Capucium"
  ],
  "what makes you think dragons are coming back to life": [
    "Cur dracones resurgere censes"
  ],
  "i don't wish to remove this blessing": [
    "Nolo hanc benedictionem removere"
  ],
  "elven mace of ice": [
    "Maca Alfa Gelidus"
  ],
  "weak aversion to shock": [
    "Aversio Debilis Electricitati"
  ],
  "anything around here that needs doing": [
    "Estne quid hic quod necesse fieri"
  ],
  "dwarven mace of despair": [
    "Dvemeris Maca Desponsionis"
  ],
  "what did he used to be like": [
    "Quomodo erat"
  ],
  "hairfemaledarkelf04": [
    "HairFemaleDarkElf04"
  ],
  "elven war axe of reaping": [
    "Alfus Bellisecuris Saturni"
  ],
  "so what happened to him": [
    "Ergo quid cum eo accidit"
  ],
  "who cares, we're the thieves guild": [
    "Quis curat, Sodalitas Furum sumus"
  ],
  "leather helmet of major magicka": [
    "Cutis Galea Maioris Magicae"
  ],
  "we've found something in saarthal, and tolfdir thinks it's important": [
    "Aliquid Saarthalis invenimus, et Tolfdir id mangi momenti aestimat"
  ],
  "someone named ranmir is looking for her": [
    "Aliquis nomine Ranmir eam quaerit"
  ],
  "ebony war axe of lightning": [
    "Bellisecuris Ebeni Fulminis"
  ],
  "where did you say isabelle rolaine was": [
    "Ubi dixisti Isabella Rolaine esse"
  ],
  "who's to say what happened at <alias=sonstownraided>": [
    "Quis dicere potest quid ad <Alias=SonsTownRaided> accidisse"
  ],
  "i understand you knew isabelle rolaine": [
    "Intellego te Isabelle Rolaine novisse"
  ],
  "any tips to get me in there": [
    "Habesne quibus admonitis de intrando"
  ],
  "fear poison": [
    "Venenum Timoris"
  ],
  "ebony gauntlets": [
    "Ebeni Manicae"
  ],
  "i'm back from darkwater crossing": [
    "Reveni e Vado Aquae Obscurae"
  ],
  "steel helmet of alchemy": [
    "Adamantea Galea Alchemiae"
  ],
  "it's a letter from isabelle. you should read it": [
    "Epistula a Isabella est. Eam legere debes"
  ],
  "waking nightmare mini-scenes": [
    "Waking Nightmare Mini-Scenes"
  ],
  "orcish battleaxe of depleting": [
    "Orci Bellisecuris Deplendi"
  ],
  "match card": [
    "Match Card"
  ],
  "velehk sain, i banish you to oblivion": [
    "Velehk Sain, tibi Oblivioni mitto"
  ],
  "i'm a traveler": [
    "Sum viator"
  ],
  "i have questions": [
    "Interrogata habeo"
  ],
  "i absorb some kind of power from dragons": [
    "Vim a draconibus absorbeo"
  ],
  "that's all i can say": [
    "Hoc est omne quod dicere possum"
  ],
  "i'd like to purchase a home in your city": [
    "Volo domum in urbe tuo emere"
  ],
  "take shagrol's hammer back to largashbur": [
    "Refer Malleum Shagrolis ad Largashbur"
  ],
  "dwemer gyro": [
    "Dvemere Choragium"
  ],
  "verner's house": [
    "Verner's House"
  ],
  "here we go": [
    "Eamus"
  ],
  "giant snow spider abilities": [
    "Giant Snow Spider abilities"
  ],
  "hallowed ebony battleaxe": [
    "Consacratus Bellisecuris Ebeni"
  ],
  "freir": [
    "Freir"
  ],
  "i'm not scared of you": [
    "Te non timeo"
  ],
  "who are the black-briars": [
    "Qui sunt Atri-Dumi"
  ],
  "steel sword of frost": [
    "Gladius Adamanteus Gelidus"
  ],
  "dialogue faction": [
    "dialogue faction"
  ],
  "know anything about the thieves guild": [
    "Scisne quid de Sodalitate Furum"
  ],
  "aslfur": [
    "Aslfur"
  ],
  "i didn't want to risk you not coming": [
    "Nolui te depellere"
  ],
  "can you make it out of here": [
    "Potesne e hoc loco exire"
  ],
  "daedric helmet of peerless alteration": [
    "Galea Daedrica Optimae Mutationis"
  ],
  "ebony bow of petrifying": [
    "Arcus Ebeni Lapidescendi"
  ],
  "glass dagger of winnowing": [
    "Pugio Vitreus Ventagii"
  ],
  "dead scholar": [
    "Scholasticus Mortuus"
  ],
  "ebony war axe of damnation": [
    "Bellisecuris Ebeni Damnationis"
  ],
  "hide boots of resist frost": [
    "Renonis Caligae Contra Vim Gelidam"
  ],
  "iron greatsword of draining": [
    "Spatha Ferri Hauriendi"
  ],
  "do you get many offers": [
    "Multane offerta accipis"
  ],
  "he's done me no wrong": [
    "Mihi nullum malum fecit"
  ],
  "i won't kill him": [
    "Eum non necam"
  ],
  "i'll go see bryling for you": [
    "Tibi Bryling curabo"
  ],
  "mythic dawn commentaries 1": [
    "Commentarii Aurorae Mythicae I"
  ],
  "so this is the ragged flagon": [
    "Ergo hic est Poculum Fragosum"
  ],
  "ebony battleaxe of stunning": [
    "Bellisecuris Ebeni Intermissionis"
  ],
  "glass bow of binding": [
    "Arcus Vitreus Deligandi"
  ],
  "i didn't mean to offend": [
    "Nolui tibi offendere"
  ],
  "break the law": [
    "Legem frangere"
  ],
  "are you kidding": [
    "Iocarisne"
  ],
  "nura snow-shod": [
    "Nura Nivi-Caligata"
  ],
  "everything is fine": [
    "Omnia bene"
  ],
  "the shipment will be here soon": [
    "Merx mox hic erit"
  ],
  "shipment will be here soon": [
    "Merx mox hic erit"
  ],
  "you're married to thonar": [
    "Maritus Thonari es"
  ],
  "what's he like": [
    "Qualis homo est"
  ],
  "dragonscale helmet of peerless destruction": [
    "Draconisquamata Galea Optimae Destructionis"
  ],
  "thane of whiterun": [
    "Decemvir Albacursus"
  ],
  "glass bow of ice": [
    "Arcus Vitreus Gelidus"
  ],
  "steamscorch mine": [
    "Vaporignis Metallum"
  ],
  "ancient nord war axe of ice": [
    "Antiquus Bellisecuris Germanicus Gelidus"
  ],
  "why are you after talos worshippers": [
    "Cur secutores Talos quaeris"
  ],
  "i'm interested": [
    "Mihi interest"
  ],
  "sec": [
    "sec"
  ],
  "elven sword of ice": [
    "Alfus Gladius Gelidus"
  ],
  "stonehills mine scene 1": [
    "Stonehills Mine Scene 1"
  ],
  "guardian torsten": [
    "Custos Torsten"
  ],
  "hairmalenord8": [
    "HairMaleNord8"
  ],
  "you're not from the reach": [
    "Non in prehensu natus es"
  ],
  "gularzob": [
    "Gularzob"
  ],
  "what kind of weapons do you forge": [
    "Qualia arma facis"
  ],
  "on the way to the wedding": [
    "On way to Wedding"
  ],
  "player left fort, check if its been cleared of monsters": [
    "Player left fort, check if its been cleared of monsters"
  ],
  "scroll of blizzard": [
    "Codex Hiemis"
  ],
  "elven sword of fire": [
    "Alfus Gladius Ignis"
  ],
  "just you": [
    "Modo tu"
  ],
  "potion of prolonged invisibility": [
    "Potio Prolongatae Invisibilitatis"
  ],
  "the thalmor": [
    "Visigothi"
  ],
  "studded imperial armor of minor health": [
    "Romana Lorica Ornata Minoris Salutis"
  ],
  "can i help you": [
    "Possumne te adiuvare"
  ],
  "what are you doing there": [
    "Quid illic facis"
  ],
  "saarthal miner dialogue": [
    "Saarthalis Miner Dialogue"
  ],
  "orcish warhammer of burning": [
    "Orci Bellimalleus Cremandi"
  ],
  "eye width": [
    "Oculorum Latitudo"
  ],
  "mistwatch west tower": [
    "Turrinexus Turris Occidentis"
  ],
  "crossinghouse key": [
    "Clavis ad Tranistidomum"
  ],
  "jaree's key": [
    "Clavis Jareis"
  ],
  "markarth hall of the dead key": [
    "Clavis ad Aulam Mortuorum Markartiae"
  ],
  "steel helmet of waterbreathing": [
    "Adamantea Galea Respirandi Sub Aqua"
  ],
  "dwarven battleaxe of scorching": [
    "Dvemeris Bellisecuris Adurendi"
  ],
  "molgrom twice-killed": [
    "Molgrom Bis-Necatus"
  ],
  "garthar": [
    "Garthar"
  ],
  "gold ore": [
    "Auri Minerale"
  ],
  "kill the legion soldiers inside the sanctuary (<global=db10legionattackerdeadcount>/5)": [
    "Neca Milites Legionis in Fano (<Global=DB10LegionAttackerDeadCount>/5)"
  ],
  "hide boots of strength": [
    "Renonis Caligae Fortitudinis"
  ],
  "so how do i get him to talk": [
    "Ergo quommodo eum loqui cogere possum"
  ],
  "unsent afflicted letter": [
    "Epistula Afflicti"
  ],
  "isn't there any way to work this out": [
    "Estne modus quo hanc difficultatem reficere possumus"
  ],
  "pale stormcloak camp": [
    "Pallidi Sturmmantelorum Castra"
  ],
  "glass war axe": [
    "Bellisecuris Vitreus"
  ],
  "steel sword of diminishing": [
    "Gladius Adamanteus Diminuendi"
  ],
  "delphine mentioned you're studying dragon lairs": [
    "Delphine commemoravit te operam in cubilis draconum dare"
  ],
  "hide shield of resist fire": [
    "Scutum Cutis Contra Vim Ignis"
  ],
  "your shop has a most unusual name": [
    "Taberna tua nomen mirabile habet"
  ],
  "holy ebony mace": [
    "Sanctificata Maca Ebeni"
  ],
  "your spell finally wore off": [
    "Magica tua tandem evanuit"
  ],
  "who is hircine": [
    "Quis est Hecate"
  ],
  "when does the wedding start": [
    "Quando incipiet nuptum"
  ],
  "moira": [
    "Moira"
  ],
  "key to the jarl's longhouse": [
    "Clavis ad Longidomum Comitis"
  ],
  "i have your daedra's heart": [
    "Cor daedrae tibi habeo"
  ],
  "new gnisis cornerclub services": [
    "New Gnisis Cornerclub Services"
  ],
  "calcelmo's ring": [
    "Anulus Calcelmonis"
  ],
  "greta": [
    "Greta"
  ],
  "steel smithing": [
    "Ferrarius Adamanteus"
  ],
  "trouble with the mine": [
    "Tribulatio cum metallo"
  ],
  "daedric shield of fire suppression": [
    "Scutum Daedricum Suppressionis Ignis"
  ],
  "embrace of shadows": [
    "Complexus Umbrarum"
  ],
  "i'll buy it": [
    "Id emam"
  ],
  "(200 gold)": [
    "(200 auri)"
  ],
  "elven war axe of despair": [
    "Alfus Bellisecuris Desponsionis"
  ],
  "illia": [
    "Illia"
  ],
  "who else is in the family": [
    "Quis alius in Familia est"
  ],
  "marksmalehumanoid11rightgashr": [
    "MarksMaleHumanoid11RightGashR"
  ],
  "da16 player faction": [
    "DA16 Player Faction"
  ],
  "is there anyone who knows what she likes": [
    "Estne quis qui vultum eius scit"
  ],
  "do you get much business way up here": [
    "Multumne negotium hic accipis"
  ],
  "acquire the staff": [
    "Accipe Rudem"
  ],
  "i could look into it for you": [
    "Possum pro te investigare"
  ],
  "femaleeyeshighelfdarkyellow": [
    "FemaleEyesHighElfDarkYellow"
  ],
  "daedric boots of shock suppression": [
    "Caligae Daedricae Suppresionis Electricitatis"
  ],
  "it doesn't really sound like i have a choice in the matter": [
    "Mihi non videtur me eligere posse"
  ],
  "good luck, then": [
    "Sic, bonam fortunam"
  ],
  "scroll of ebonyflesh": [
    "Codex Ebenipellis"
  ],
  "elven war axe of stunning": [
    "Alfus Bellisecuris Intermissionis"
  ],
  "mg dormitory scene 8": [
    "MG Dormitory Scene 8"
  ],
  "i need a shiv": [
    "Astulam volo"
  ],
  "steel warhammer of binding": [
    "Bellimalleus Adamanteus Deligandi"
  ],
  "do you know how to open the whispering door": [
    "Scisne quomodo me Ianuam Sussurantem aperire posse"
  ],
  "(optional) kill commander maro": [
    "(Optio) Neca Imperatorem Maronem"
  ],
  "so how do i open the door": [
    "Ergo, quommodo ianuam aperire possum"
  ],
  "ebony bow of damnation": [
    "Arcus Ebeni Damnationis"
  ],
  "father's missive": [
    "Iussus Patris"
  ],
  "sanctified imperial bow": [
    "Sanctificatus Arcus Imperialis"
  ],
  "horik halfhand": [
    "Horik Semimanus"
  ],
  "dialoguekarthwastenragnarlash05": [
    "DialogueKarthwastenRagnarLash05"
  ],
  "warpaintgreen01": [
    "WarpaintGreen01"
  ],
  "dwarven war axe of winnowing": [
    "Dvemeris Bellisecuris Ventagii"
  ],
  "i'm going to put it on olaf's effigy": [
    "Id in Effigiem Olafis ponam"
  ],
  "small pearl": [
    "Margarita Parva"
  ],
  "daedric war axe of garnering": [
    "Bellisecuris Daedricus Horti"
  ],
  "ebony war axe of devouring": [
    "Bellisecuris Ebeni Consumendi"
  ],
  "staff of the flame wall": [
    "Rudis Parietis Flammarum"
  ],
  "how much to pay your fine": [
    "Quanto est vectigal tua"
  ],
  "so you want me to put out the lighthouse fire": [
    "Ergo vis me ignem in Pharone extinguere"
  ],
  "yes, yes, and yes": [
    "Sic, sic, et sic"
  ],
  "oh my goodness, yes": [
    "Oh certe, sic"
  ],
  "steel war axe of cold": [
    "Bellisecuris Adamanteus Frigidis"
  ],
  "no, didn't see anyone": [
    "Minime, neminem vidi"
  ],
  "garlic braid": [
    "Nodus Alii"
  ],
  "twice-killed": [
    "Bis-Necatus"
  ],
  "the knights of the nine": [
    "Equites Novem"
  ],
  "knights of the nine": [
    "Equites Novem"
  ],
  "scroll of revanant": [
    "Codex Reventoris"
  ],
  "dwarven greatsword of torpor": [
    "Dvemeris Spatha Torporis"
  ],
  "elven warhammer of devouring": [
    "Alfus Bellimaleus Consumendi"
  ],
  "i want to know what it is you're up to": [
    "Volo scire de consilio tuo"
  ],
  "glass dagger of despair": [
    "Pugio Vitreus Desponsionis"
  ],
  "daedric mace of annihilating": [
    "Maca Daedrica Annihilationis"
  ],
  "glass battleaxe of shocks": [
    "Bellisecuris Vitreus Electricitatis"
  ],
  "i want to talk to you about <alias.shortname=questgiver>": [
    "Volo tecum de <Alias.ShortName=QuestGiver> loqui"
  ],
  "return to aela": [
    "Redi ad Aelam"
  ],
  "hide helmet of major destruction": [
    "Cutis Galea Maioris Destructionis"
  ],
  "orcish war axe of gleaning": [
    "Orci Bellisecuris Saturni"
  ],
  "all misc items": [
    "All Misc Items"
  ],
  "who i stand with is my business": [
    "Factio mea est mihi privata"
  ],
  "i've got other things to do": [
    "Alias res habendas habeo"
  ],
  "shaircolor14": [
    "sHairColor14"
  ],
  "you were telling me where to find alduin": [
    "Mihi dicebas ubi me Alduinum invenire posse"
  ],
  "quite a forge you've got here": [
    "Fabrica optima hic habes"
  ],
  "sarthis's key": [
    "Clavis Sarthis"
  ],
  "you will hear even more, i assure you": [
    "Etiam plus audies"
  ],
  "argonian account, book 4": [
    "Historia Argonia, Book 4"
  ],
  "hide bracers of wielding": [
    "Chirothecae Cutis Tenendi"
  ],
  "malyn's black soul gem": [
    "Malynis Ater Gemmanimi"
  ],
  "i cleared the mine of draugr": [
    "Metallum nunc vaccum est draugr"
  ],
  "orcish mace of reaping": [
    "Orci Maca Saturni"
  ],
  "do you have any burglary jobs": [
    "Habesne negotia \"burglary\""
  ],
  "here's the book you wanted": [
    "Hic habes liber quem voluisti"
  ],
  "i'll bring one if i find it": [
    "Unum tibi adferam si inveniam"
  ],
  "shroud hearth barrows key": [
    "Clavis ad Pabillum Obscuri Foci"
  ],
  "daedric battleaxe of immobilizing": [
    "Bellisecuris Daedricus Immobilitatis"
  ],
  "snilling": [
    "Snilling"
  ],
  "what's the hall of the dead": [
    "Quid est Aula Mortuorum"
  ],
  "would some coin help": [
    "Fortasse aurum adiuvet"
  ],
  "cooking spit": [
    "Veru Coquendi"
  ],
  "novice alembic": [
    "Novice Alembic"
  ],
  "i'm not afraid of you": [
    "De te non timeo"
  ],
  "i support the empire": [
    "Imperio faveo"
  ],
  "audiocategoryambr": [
    "AudioCategoryAMBr"
  ],
  "i'm no friend of the imperials": [
    "Amicus Romanis non sum"
  ],
  "elven helmet": [
    "Alfa Galea"
  ],
  "gissur": [
    "Gissur"
  ],
  "virtuous daedric sword": [
    "Virtutis Gladius Daedricus"
  ],
  "%i dragon souls": [
    "%i Animi Draconum"
  ],
  "why should the jarl listen to you": [
    "Quamobrem Comes te auscultare debet"
  ],
  "general tullius commands the imperial army from the stone-walled castle dour, while solitude's jarl, elisif, resides in the blue palace": [
    "Imperator Tullius Exercitui Imperialii imperat ex Castello Duri, defenso parietibus lapideis, dum Comes Solitudinis, Elisif, in Aula Caerulea vivit"
  ],
  "pinewatch treasure room key": [
    "Clavis ad Gazophylacium Pinivisus"
  ],
  "she's your daughter. don't you care": [
    "Filia tua est. Nonne curas"
  ],
  "i was looking for you, actually": [
    "Te quaesivi, ut verum dicam"
  ],
  "marksmaleargonianscar02": [
    "MarksMaleArgonianScar02"
  ],
  "gray-mane": [
    "Juba-Cana"
  ],
  "blessing of talos": [
    "Benedictio Talos"
  ],
  "known effects": [
    "Effecta Nota"
  ],
  "are you with the legion": [
    "Esne cum Legione"
  ],
  "i've brought the ore sample from filnjar": [
    "Minerale a Filnjar tuli"
  ],
  "orcish bow of arcing": [
    "Orci Arcus Electricitatis"
  ],
  "skeevers fighting dogs": [
    "Skeevers fighting dogs"
  ],
  "good to see you": [
    "Mihi placet te videre"
  ],
  "maluril is dead": [
    "Maluril mortuus est"
  ],
  "that old guy hiding out down here... where does he live": [
    "Ille senex qui hic latet... Ubi vivit"
  ],
  "so what's your plan": [
    "Ergo, quid est consilium"
  ],
  "how do i infiltrate the thalmor embassy": [
    "Quommodo Legationem Visigotham intrare possum"
  ],
  "elven battleaxe of diminishing": [
    "Bellisecuris Alfus Diminuendi"
  ],
  "how long have you studied alchemy": [
    "Quamdiu operam in alchemiam dedisti"
  ],
  "you've discovered... what": [
    "Invenisti... quid"
  ],
  "daedric warhammer of depleting": [
    "Bellimalleus Daedricus Deplendi"
  ],
  "dragonscale helmet of peerless archery": [
    "Draconisquamata Galea Optimae Saggitationis"
  ],
  "what happened to everyone": [
    "Quid omnibus accidit"
  ],
  "dwarven greatsword of burning": [
    "Dvemeris Spatha Cremandi"
  ],
  "\"i do swear my blood and honor to the service of ulfric stormcloak...\"": [
    "\"Me adiuro, sanguine et honore, ad Vercingetorigem Temepstatamiculum...\""
  ],
  "leather bracers of sure grip": [
    "Corii Chirothecae Dexteritatis"
  ],
  "actually, motierre, there is one more thing": [
    "Motierre, una res manet"
  ],
  "do you have a contract for me": [
    "Habesne foedus mihi"
  ],
  "staff of daedric command": [
    "Rudis Daedricas Mandendas"
  ],
  "elven war axe of enervating": [
    "Alfus Bellisecuris Enervationis"
  ],
  "ancient shrouded boots": [
    "Antiquus Umbratus Caligae"
  ],
  "vacant beehive": [
    "Alvarium Vacans"
  ],
  "lipsredguardmale04": [
    "LipsRedguardMale04"
  ],
  "what do these greybeards want with me": [
    "Quid volunt Canabarbae mecum"
  ],
  "now give me that skooma": [
    "Nunc, da mihi illam skoomam"
  ],
  "was unequipped": [
    "disarmatus erat"
  ],
  "what does maven want from me": [
    "Quid vis Maven mecum"
  ],
  "mzulft room key": [
    "Clavis ad Conclave Mzulft"
  ],
  "glass war axe of leeching": [
    "Bellisecuris Vitreus Sanguisugae"
  ],
  "kornalus frey's key": [
    "Clavis Kornali Freyis"
  ],
  "speak to the nightingale sentinel": [
    "Adloquere Custodem Philomelae"
  ],
  "alchemy crafting": [
    "Alchemy crafting"
  ],
  "etienne rarnis": [
    "Etienne Rarnis"
  ],
  "maleeyeswoodelfdeepbrown": [
    "MaleEyesWoodElfDeepBrown"
  ],
  "spell tome: dragonhide": [
    "Liber Magicus: Draconipellis"
  ],
  "why is the worship of talos banned": [
    "Cur non licet ad Talos orare"
  ],
  "elven helmet of eminent alchemy": [
    "Alfa Galea Alchemiae Eminentis"
  ],
  "erikur house scene 3": [
    "Erikur House Scene 3"
  ],
  "there must be a way to stop this": [
    "Certe aliqua via extat qua hoc sisti potest"
  ],
  "what's this got to do with me": [
    "Quid mecum agitur"
  ],
  "boethiah's shadow": [
    "Boethiah's Umbra"
  ],
  "then i will help you": [
    "Ergo tibi auxilium dabo"
  ],
  "what must be done": [
    "Quid faciendum est"
  ],
  "mage hood": [
    "Magi Capucium"
  ],
  "orcish greatsword of gleaning": [
    "Orci Spatha Saturni"
  ],
  "dragonscale boots of the ox": [
    "Caligae Draconisquamatae Bovis"
  ],
  "unlock the secret of red eagle's tomb": [
    "Inveni Secretum Sepulchri Aquliae Rubrae"
  ],
  "if i get information from gulum-ei, what then": [
    "Si informationem e Gulum-Ei accipiam, quid postea faciam"
  ],
  "what's the ratway": [
    "Quid est Via Murum"
  ],
  "da16 player hate faction": [
    "DA16 Player Hate Faction"
  ],
  "who's arn": [
    "Quis est Arn"
  ],
  "elven mace of fire": [
    "Maca Alfa Ignis"
  ],
  "this is obviously a shakedown, but i'll pay": [
    "Fraus certe est, sed pecuniam solvam"
  ],
  "steel armor of the minor knight": [
    "Adamantea Lorica Minoris Equitis"
  ],
  "tolfdir's alembic": [
    "Tolfdiris Alembicum"
  ],
  "ravage stamina": [
    "Devasta Staminam"
  ],
  "poison the skeever nest": [
    "Pone Venenum in Nidum Skeever"
  ],
  "i could help you get the claw back": [
    "Possum tibi unguem recipere"
  ],
  "dreams are reality, and reality is really a dream": [
    "Somnia sunt vera, et veritas est somnia"
  ],
  "archmage geirmund": [
    "Archimagus Geirmundus"
  ],
  "defeat jaree-ra": [
    "Vince Jaree-Ram"
  ],
  "dragonscale armor of the eminent knight": [
    "Draconisquamata Lorica Eminentis Equitis"
  ],
  "ebony armor of the major knight": [
    "Ebeni Lorica Maioris Equitis"
  ],
  "daedric sword of terror": [
    "Gladius Daedricus Terroris"
  ],
  "dialoguelefthandminedaighreskaggi02": [
    "DialogueLeftHandMineDaighreSkaggi02"
  ],
  "i think you may be right": [
    "Credo te recte dicere"
  ],
  "detect undead enemy interior": [
    "Detect Undead Enemy Interior"
  ],
  "master bellows": [
    "Master Bellovs"
  ],
  "the blessings of nature": [
    "Benedictiones Naturae"
  ],
  "blessings of nature": [
    "Benedictiones Naturae"
  ],
  "horker faction": [
    "Horker Faction"
  ],
  "elven bow of enervating": [
    "Arcus Alfus Enervationis"
  ],
  "the tower doomstone": [
    "The Tower Doomstone"
  ],
  "tower doomstone": [
    "The Tower Doomstone"
  ],
  "i don't believe in destiny": [
    "Fato non credo"
  ],
  "but i will stop alduin": [
    "Sed Alduinum superam"
  ],
  "lingering magicka poison": [
    "Commorans Venenum Magicae"
  ],
  "audiotemplatespriggan": [
    "AudioTemplateSpriggan"
  ],
  "fortify unarmed damage": [
    "Adde ad Vulnus Manubus"
  ],
  "humanbeardlong01_1bit": [
    "HumanBeardLong01_1bit"
  ],
  "i want to purchase your black soul gem": [
    "Volo gemmanimam atram tuam emere"
  ],
  "sounds like the guards aren't doing their job": [
    "Mihi videtur guards aren't doing their job"
  ],
  "orcish war axe of draining": [
    "Orci Bellisecuris Hauriendi"
  ],
  "heavy armor trainer": [
    "Heavy Armor Trainer"
  ],
  "bring the documents to <alias=fieldco>": [
    "Fer Documenta ad <Alias=FieldCO>"
  ],
  "draugr overlord": [
    "Draugr Princeps"
  ],
  "steel shield of minor blocking": [
    "Adamanteum Scutum Minoris Interponendi"
  ],
  "kornalus": [
    "Kornalus"
  ],
  "you and faendal both like the same girl": [
    "Tu et Faendal eandem puellam amatis"
  ],
  "dragonplate shield of warmth": [
    "Draconilaminatum Scutum Caloris"
  ],
  "overwrite this game": [
    "Superscribe hunc ludum tutum"
  ],
  "let me help you": [
    "Sine me te adiuvare"
  ],
  "healing hands": [
    "Manus Salutis"
  ],
  "it's time for you to go back home, dog": [
    "Tempus est domum ut revenias, canis"
  ],
  "help with what": [
    "Cum quo adiuvare"
  ],
  "all soul gems": [
    "All Gemmanimas"
  ],
  "adept illusion": [
    "Adeptus Inlusionis"
  ],
  "gwilin": [
    "Gvilin"
  ],
  "stormcloak supporter": [
    "Stormcloak Supporter"
  ],
  "i've heard you're an expert in vampirism": [
    "Audivi te expertum esse in vampirismum"
  ],
  "are you sure you want to load this game": [
    "Esne certus te hunc ludum aperire velle"
  ],
  "brynjolf said i should speak to you": [
    "Brynjolf me oravit ut tecuam adloquar"
  ],
  "you can trust your servant to keep this secret": [
    "Credisne servo tuo cum hoc secreto"
  ],
  "you cannot repair items when enemies are nearby": [
    "You cannot repair items when enemies are nearby"
  ],
  "grelka's market key": [
    "Clavis ad Forum Grelkae"
  ],
  "marksmalehumanoid04rightgashr": [
    "MarksMaleHumanoid04RightGashR"
  ],
  "ancient nord armor": [
    "Antiqua Germanica Lorica"
  ],
  "femalebrowshuman03": [
    "FemaleBrowsHuman03"
  ],
  "angarvunde ruins": [
    "Angarvundis Ruinae"
  ],
  "glass sword of leeching": [
    "Gladius Vitreus Sanguisugae"
  ],
  "you seemed upset about something": [
    "Tu ab aliquo commoveri videris"
  ],
  "were you looking for this dagger, by any chance": [
    "Hanc pugionem forte quaesivisti"
  ],
  "cook's journal": [
    "Ephemeris Coqui"
  ],
  "the greybeards": [
    "Canabarbae"
  ],
  "i'm answering your summons": [
    "Respondeo ad vocationem tuam"
  ],
  "i need to find his portal to sovngarde": [
    "Portam eius ad Valhallam invenire debeo"
  ],
  "cyrodilic brandy": [
    "Vinum Romanum"
  ],
  "yes, and specially made for members of the elder council": [
    "Sic, et pro magistratibus Concilii Maorium factum"
  ],
  "elgrim's elixers": [
    "Elgrim's Elixers"
  ],
  "audiotemplatecow": [
    "AudioTemplateCow"
  ],
  "it sounds like the court mage has been experimenting": [
    "Mihi videtur magum aulae experimenta fecisse"
  ],
  "ebony bow of depleting": [
    "Arcus Ebeni Deplendi"
  ],
  "i placed <alias.shortname=target>'s ring in her house": [
    "Anulus <Alias.ShortName=Target>e domi eius posui"
  ],
  "ebony battleaxe of freezing": [
    "Bellisecuris Ebeni Gelidus"
  ],
  "dwarven dagger of binding": [
    "Dvemeris Pugio Deligandi"
  ],
  "weylin's note": [
    "Epistula Veylinis"
  ],
  "blessed iron battleaxe": [
    "Sanctificatus Bellisecuris Ferri"
  ],
  "i'll worry about capturing a dragon": [
    "De draconiam capiendo sollicitabo"
  ],
  "i need your help to stop the war": [
    "Auxilium tuum peto ut finem bello faciam",
    "Auxilium tuum quaero ut bellum finem faciamus"
  ],
  "what's this about nightmares": [
    "Quid de suppressionibus accidit"
  ],
  "ebony sword of lightning": [
    "Gladius Ebeni Fulminis"
  ],
  "<alias=victim> was killed": [
    "<Alias=Victim> necatus est"
  ],
  "your mother gharol wants you to have this sword": [
    "Mater tua Gharol te hunc gladium habere vult"
  ],
  "steel mace": [
    "Maca Adamantea"
  ],
  "golden ship model": [
    "Aureum Exemplar Navis"
  ],
  "spell tome: lightning cloak": [
    "Liber Magicus: Amiculum Fulminis"
  ],
  "bring <global=nn01total> crimson nirnroot to avrusa sarethi": [
    "Fer <Global=NN01Total> Nirniradices Rubras ad Avrusa Sarethi"
  ],
  "goldenglow cellar key": [
    "Clavis ad Cellarium Aurilucis"
  ],
  "ravage magicka": [
    "Devasta Magicam"
  ],
  "elven warhammer of reaping": [
    "Alfus Bellimaleus Saturni"
  ],
  "there's a horse waiting at the stables": [
    "Equus ad stabulum te exspectat"
  ],
  "i'll make sure you're safe": [
    "Certiorem faciam te tutam esse"
  ],
  "hide armor of the major squire": [
    "Renonis Lorica Maioris Armiferis"
  ],
  "ebony mace of the blaze": [
    "Maca Ebeni Congflagrantis"
  ],
  "ghost visuals fire": [
    "Ghost Visuals Fire"
  ],
  "mythic dawn commentaries 3": [
    "Commentarii Aurorae Mythicae III"
  ],
  "pathway guard tower": [
    "Pathway Guard Tower"
  ],
  "how did you end up as imperial prisoners": [
    "Quomodo vos Captivi Romini facti sint"
  ],
  "why did elisif forbid the festival": [
    "Cur Elisif festum vetavit"
  ],
  "key to overseer's house": [
    "Clavis ad Domum Villici"
  ],
  "dwarven bow of shocks": [
    "Dvemeris Arcus Electricitatis"
  ],
  "blacksmith crafting": [
    "Blacksmith crafting"
  ],
  "iron warhammer of chills": [
    "Bellimalleus Ferratus Frigidis"
  ],
  "what's so special about this ore": [
    "Quid tam magni momenti est de hoc minerali"
  ],
  "sinderion's field journal": [
    "Ephemeris Agri Sinderionis"
  ],
  "torc of labyrinthian": [
    "Torques Labyrinthi"
  ],
  "nightblade": [
    "Nightblade"
  ],
  "i have a mammoth tusk for you": [
    "Dentem elephantis tibi habeo"
  ],
  "did you need anything else": [
    "Aliquid alium voluisti"
  ],
  "magicka is the energy used to power spells. the higher the magicka, the more spells that can be cast": [
    "Plus magicae, plus incantamentorum quae facere potes"
  ],
  "have the stormcloaks treated you roughly": [
    "MolastavÃªruntne te Sturmmanteli"
  ],
  "scaled boots of dwindling shock": [
    "Caligae Squamatae Electricitatis Diminuentis"
  ],
  "trading caravans sometimes pass through the ruins of labyrinthian, but usually prefer safer, longer routes": [
    "Caravanna nonnumquam per ruinas Labyrinthii transeunt, sed vias tutiores et longiores malunt"
  ],
  "whiterun guard's shield": [
    "Scutum Custodis Albacursus"
  ],
  "how do i get to the brewing vats": [
    "Quomodo ad Dogas Braciatores advenire possum"
  ],
  "backstab": [
    "Transfigere"
  ],
  "i brought you the crown from korvanjund": [
    "Tibi Coronam e Korvanjund attuli"
  ],
  "pay them, or things will get ugly. (brawl)": [
    "Pecuniam eis solva vel... (Pugna Manibus)"
  ],
  "eisa blackthorn": [
    "Eisa Atridumus"
  ],
  "daedric warhammer of subsuming": [
    "Bellimalleus Daedricus Subsumendi"
  ],
  "daedric dagger of exhaustion": [
    "Pugio Daedricus Exhaustionis"
  ],
  "what's there to do in the city": [
    "Quae sunt acroamata hac in urbe"
  ],
  "wolf faction": [
    "Wolf Faction"
  ],
  "steel sword of weariness": [
    "Gladius Adamanteus Maioris Defatigationis"
  ],
  "tasius tragus": [
    "Tasius Tragus"
  ],
  "ebony sword of nullifying": [
    "Gladius Ebeni Nullificandi"
  ],
  "abandoned cave": [
    "Spelunca Derelictus"
  ],
  "trials of st. alessia": [
    "Iudicata Sanctae Alessiae"
  ],
  "glass battleaxe of enervating": [
    "Bellisecuris Vitreus Enervationis"
  ],
  "labyrinthian crypt key": [
    "Clavis ad Hypogaeum Labyrinthiani"
  ],
  "falmer shaman": [
    "Falmer Shaman"
  ],
  "kharjo": [
    "Kharjo"
  ],
  "here's what was in the safe": [
    "Hic est id quod in arca erat"
  ],
  "steel gauntlets of minor sure grip": [
    "Adamanteae Manicae Minoris Dexteritatis"
  ],
  "i'm not sure i understand the terms": [
    "Rationes scire debeo"
  ],
  "why does it require that type of protection": [
    "Cur praesidium illius modi postulat"
  ],
  "there's no going back": [
    "Nullus regressus est"
  ],
  "staff of zombies": [
    "Rudis Amortuorum"
  ],
  "svaknir": [
    "Svaknir"
  ],
  "orcish sword of despair": [
    "Orci Gladius Desponsionis"
  ],
  "tell me about safia": [
    "Dic mihi de Safia"
  ],
  "orcish helmet of major illusion": [
    "Orci Galea Maioris Inlusionis"
  ],
  "imperial light helmet of minor alteration": [
    "Romana Cassis Minoris Mutationis"
  ],
  "glass sword of dread": [
    "Gladius Vitreus Timoris"
  ],
  "treatise on ayleidic cities": [
    "Detractatus de Urbibus Ayleidicis"
  ],
  "torpor ingredient 3": [
    "Torpor Ingredient 3"
  ],
  "petty": [
    "Parvus"
  ],
  "spell tome: ebonyflesh": [
    "Liber Magicus: Ebenipellis"
  ],
  "sun's rest": [
    "Festus Solis"
  ],
  "i believe you owe galmar a drink": [
    "Credo te Galmari bibitum debere"
  ],
  "what's the significance of this place": [
    "Quid est significantia huius loci"
  ],
  "elven bracers of deft hands": [
    "Chirothecae Alfae Dexteritatis"
  ],
  "religion and thieves make odd bedfellows": [
    "Religio et fures contubernales miri sunt"
  ],
  "steel plate armor": [
    "Laminata Lorica"
  ],
  "daedric warhammer of petrifying": [
    "Bellimalleus Daedricus Lapidescendi"
  ],
  "dragonscale gauntlets of extreme wielding": [
    "Draconisquamatae Manicae Extremis Tenendi"
  ],
  "obtain a mark of dibella from indaryn": [
    "Accipe Signum Dibellae a Indaryn"
  ],
  "glass mace of malediction": [
    "Maca Vitrea Maledictionis"
  ],
  "scroll of soul trap": [
    "Codex Animicapti"
  ],
  "eye shadow": [
    "Oculorum Umbra"
  ],
  "thaena": [
    "Thaena"
  ],
  "i didn't make that much noise": [
    "Non tantum strepitus feci"
  ],
  "i'll do nothing of the sort": [
    "Nihil eius modi faciam"
  ],
  "orcish sword of arcing": [
    "Orci Gladius Electricitatis"
  ],
  "the xbox 360 controller has been disconnected": [
    "The Xbox 360 Controller has been disconnected"
  ],
  "xbox 360 controller has been disconnected": [
    "The Xbox 360 Controller has been disconnected"
  ],
  "click ok to use the keyboard/mouse, or reconnect the xbox 360 controller and press the a button": [
    "Click OK to use Keyboard/Mouse, or reconnect Xbox 360 Controller et press A Button"
  ],
  "she won't be harmed": [
    "Non nocebitur"
  ],
  "sanctified orcish greatsword": [
    "Sanctificata Spatha Orcorum"
  ],
  "too bad": [
    "Vae tibi"
  ],
  "there's more where that came from": [
    "Plus est ab eodem loco"
  ],
  "balimund": [
    "Balimund"
  ],
  "call dragon effect": [
    "Call Dragon Effect"
  ],
  "sorry, i'm not ready for that just yet": [
    "Ignosce, non dum paratus sum illi"
  ],
  "stand aside, woman": [
    "Move te, femina"
  ],
  "i'm here for fjola": [
    "Hic sum Fjolae"
  ],
  "dragonscale helmet of eminent destruction": [
    "Draconisquamata Galea Eminentis Destructionis"
  ],
  "dwarven war axe of soul snares": [
    "Dvemeris Bellisecuris Animicapti"
  ],
  "staff of dread zombies": [
    "Rudis Amortuorum Fortium"
  ],
  "dwarven mace of ice": [
    "Dvemeris Maca Gelidus"
  ],
  "glass sword of evoking": [
    "Gladius Vitreus Evocandi"
  ],
  "orcish sword of depleting": [
    "Orci Gladius Deplendi"
  ],
  "how did you know i was coming": [
    "Quomodo scivisti me venturum esse"
  ],
  "skjor said it was a blessing from hircine": [
    "Skjor dixit id esse beneficium ab Hecati"
  ],
  "vilkas said it was a curse laid upon the ancient companions": [
    "Vilkas dixit eam maledictionem esse contra Contubernales antiquos"
  ],
  "and they became werewolves": [
    "Et in versipelles mutavÃªrunt"
  ],
  "but aren't you more powerful now": [
    "Sed nonne potentior nunc es"
  ],
  "gold and emerald circlet": [
    "Auri et Smaragdi Circulum"
  ],
  "it shall be done": [
    "Facietur"
  ],
  "ebony sword of malediction": [
    "Gladius Ebeni Maledictionis"
  ],
  "you go ahead": [
    "Tu perge"
  ],
  "i'm not done here": [
    "Nondum finem hic feci"
  ],
  "by sithis, we must stop them": [
    "A Namira, eos sistendum est nobis"
  ],
  "steel war axe of shocks": [
    "Bellisecuris Adamanteus Electricitatis"
  ],
  "on second thought, maybe i'll pass": [
    "Ut iterum cogito, fortasse non"
  ],
  "erdi": [
    "Erdi"
  ],
  "i'll bet a drunk like you sees a lot of strange things": [
    "Credo ebrium ut te mulas res mirabiles videre"
  ],
  "femalemouthargonian": [
    "FemaleMouthArgonian"
  ],
  "a dark brotherhood assassin doesn't do... \"favors.\"": [
    "Sicarius Fraternitatiss Obscurae non...favet"
  ],
  "dark brotherhood assassin doesn't do... \"favors.\"": [
    "Sicarius Fraternitatiss Obscurae non...favet"
  ],
  "elven shield of waning shock": [
    "Alfum Scutum Insenescentis Electricitatis"
  ],
  "if the parties bore you, why attend them": [
    "Si epulae te taedent, cur eas adis"
  ],
  "scaled bracers of major sure grip": [
    "Chirothecae Squamatae Maioris Dexteritatis"
  ],
  "garvey": [
    "Garvey"
  ],
  "azura and the box": [
    "Azura et Fasces"
  ],
  "glass mace of harrowing": [
    "Maca Vitrea Occationis"
  ],
  "do you work for risaad": [
    "Pro Risaad laboras"
  ],
  "glass boots of frost abatement": [
    "Vitreae Caligae Gelidus Resistendi"
  ],
  "keep your distance, jester, and we'll get along just fine": [
    "Procul esto, macce, et bene erit inter nos"
  ],
  "is haming your son": [
    "Estne Haming filius tuus"
  ],
  "let's go get them": [
    "Eamus coronam inventum"
  ],
  "hide bracers of alchemy": [
    "Chirothecae Cutis Alchemiae"
  ],
  "key to solitude blacksmith": [
    "Clavis ad Ferrarium Solitudinis"
  ],
  "elven sword of blizzards": [
    "Alfus Gladius Hiemis"
  ],
  "benefactor": [
    "Benefactor"
  ],
  "i thought dragons were all dead long ago": [
    "Credidi omnes dracones abhinc multos annos mortuos fuisse"
  ],
  "i'm here for thorald gray-mane": [
    "Hic sum pro Thorald Juba-Cana"
  ],
  "racial phylogeny": [
    "Phyleticum"
  ],
  "think he'll give me trouble": [
    "Censene illum mihi difficultates daturum esse"
  ],
  "sorex vinius": [
    "Sorex Vinius"
  ],
  "db08festusidentitytopic1": [
    "DB08FestusIdentityTopic1"
  ],
  "i really don't want to talk about it": [
    "Re vera de eo loqui nolo"
  ],
  "maybe you'll see one yourself soon": [
    "Fortasse tu ipse unum videbis "
  ],
  "helgen isn't that far from here": [
    "Helgen non tam procul ab hoc loco est"
  ],
  "what can i do for you": [
    "Quid pro te facere possum"
  ],
  "hide helmet of minor alteration": [
    "Cutis Galea Minoris Mutationis"
  ],
  "i'm ready, ma'am": [
    "Paratus sum, matrona"
  ],
  "i've located grimsever": [
    "Grimsever Inveni"
  ],
  "opening the tomb": [
    "Opening Tomb"
  ],
  "normaltocombat": [
    "NormalToCombat"
  ],
  "you saw her": [
    "Eam vidisti"
  ],
  "why haven't you gone to the guards for help": [
    "Quidni ad custodes pro auxilio peteres"
  ],
  "dustman's cairn key": [
    "Clavis ad Monolithon Pulviviri"
  ],
  "so, can you teach me this shout": [
    "Ergo, me hanc clamationem docere potes"
  ],
  "ebony dagger of dread": [
    "Pugio Ebeni Timoris"
  ],
  "stormcloak guard": [
    "Sturmmantelorum Custos"
  ],
  "sons of skyrim soldier": [
    "Sons of Skyrim Soldier"
  ],
  "letter from quintus navale": [
    "Epistula a Quinto Navali"
  ],
  "j'datharr's note": [
    "Epistula J'datharris"
  ],
  "humanbeard32": [
    "HumanBeard32"
  ],
  "you won't take me because i'm an argonian": [
    "Me non vis quod sum Argonius"
  ],
  "how could mercer have gotten into the vault": [
    "Quomodo potuit Mercer Hypogeum intrare"
  ],
  "summon a flame atronach": [
    "Voca Atronachum Flammarum"
  ],
  "emblem x": [
    "Emblema X"
  ],
  "wait for the jarl's response": [
    "Exspecta Responsum Comitis"
  ],
  "dwarven bow": [
    "Arcus Dvemeris"
  ],
  "you say there's a plot against you": [
    "Dicis coniurationem contra te esse"
  ],
  "mercer killed gallus, not karliah": [
    "Mercer Gallum necavit, non Karliam"
  ],
  "hide armor of the squire": [
    "Renonis Lorica Armiferis"
  ],
  "iron battleaxe of dismay": [
    "Bellisecuris Ferri Timoris"
  ],
  "orcish helmet of eminent alteration": [
    "Orci Galea Eminentis Mutationis"
  ],
  "karliah was behind goldenglow and honningbrew": [
    "Aurilucis et Honningbrevis culpae Karliae erant"
  ],
  "ebony sword of winter": [
    "Gladius Ebeni Hiemis"
  ],
  "orichalcum ore": [
    "Orichalcum Minerale"
  ],
  "hadvar said you could help me out": [
    "Hadvar dixit te me adiuvare posse"
  ],
  "brylling's house": [
    "Brylling's House"
  ],
  "reyda's satchel": [
    "Cibisis Reydae"
  ],
  "browsmalehumanoid07": [
    "BrowsMaleHumanoid07"
  ],
  "your wife is looking for you": [
    "Uxor tua te quaerit"
  ],
  "orcish sword of soul snares": [
    "Orci Gladius Animicapti"
  ],
  "dialoguemarkarthinnklepprcosnachscene01": [
    "DialogueMarkarthInnKlepprCosnachScene01"
  ],
  "ebony gauntlets of eminent wielding": [
    "Ebeni Manicae Eminentis Tenendi"
  ],
  "return to valmir": [
    "Refer te ad Valmir"
  ],
  "gisli": [
    "Gisli"
  ],
  "sild's journal": [
    "Ephemeris Sildis"
  ],
  "leather helmet of minor magicka": [
    "Cutis Galea Minoris Magicae"
  ],
  "glass mace of lightning": [
    "Maca Vitrea Fulminis"
  ],
  "arnskar ember-master": [
    "Arnskar Cineri-Dominus"
  ],
  "king olaf's treasury key": [
    "Clavis ad Gazaphylachian Regis Olafis"
  ],
  "malign aversion to fire": [
    "Malignum Aversio Igni"
  ],
  "humanbeardshort10_headpart": [
    "HumanBeardShort10_Headpart"
  ],
  "amulet of stendarr": [
    "Monile Stendarris"
  ],
  "elven armor of health": [
    "Alfa Lorica Salutis"
  ],
  "sorry, i'm still not ready to go in there": [
    "Ignosce, nondum paratus sum intrare"
  ],
  "5,000 gold": [
    "MMMMMM auri",
    "MMMMM Denarii"
  ],
  "i think i'm okay": [
    "Credo me bonum esse"
  ],
  "elven battleaxe of shocks": [
    "Bellisecuris Alfus Electricitatis"
  ],
  "mystic tuning gloves": [
    "Manubria Mystica"
  ],
  "i never met a thief that worshipped anything": [
    "Furi qui aliquid precatus est numquam occurri"
  ],
  "the imperials were there ahead of us": [
    "Romani ante nos advenerunt"
  ],
  "imperials were there ahead of us": [
    "Romani ante nos advenerunt"
  ],
  "are there other strongholds out there": [
    "Suntne alii arces"
  ],
  "nettlebane": [
    "Cnidebanus"
  ],
  "jarl elisif, it's a pleasure to see you again": [
    "Comes Elisif, gaudeo te iterum videre"
  ],
  "all potions": [
    "All Potions"
  ],
  "pelagius the suspicious": [
    "Pelagius Suspector"
  ],
  "hairlinemalenord18": [
    "HairLineMaleNord18"
  ],
  "dragonscale helmet of peerless restoration": [
    "Draconisquamata Galea Maximi Recreandi"
  ],
  "deer hide": [
    "Cervi Reno"
  ],
  "i want to stop at riverwood": [
    "Volo ire at Lignirivum"
  ],
  "dragonscale shield of frost abatement": [
    "Scutum Draconisquamatum Gelidus Resistendi"
  ],
  "imperial bracers of sure grip": [
    "Chirothecae Romanae Dexteritatis"
  ],
  "test j'zargo's flame cloak spell on the undead (<global=mgrj1test>/<global=mgrj1total>)": [
    "Proba Amiculum Flammarum J'zargonis in Amortuos (<Global=MGRJ1Test>/<Global=MGRJ1Total>)"
  ],
  "imperial bow of sparks": [
    "Imperialis Arcus Scintillarum"
  ],
  "that price is outrageous": [
    "Illud pretium insanum est"
  ],
  "biography of barenziah, v1": [
    "Biographis Barenziae, I"
  ],
  "just looking around": [
    "Modo circumspecto"
  ],
  "heal valdr's injuries": [
    "Sana Vulnera Valdris"
  ],
  "dragonscale shield of shock suppression": [
    "Scutum Draconisquamatum Suppresionis Electricitatis"
  ],
  "he said goldenglow was purchased by a \"karliah.\"": [
    "Dixit Aurilucem emptum esse a \"Karlia.\""
  ],
  "sprint effect 1": [
    "Sprint Effect 1"
  ],
  "cracked tusk vaults": [
    "Hypogeum Dentis Fracti"
  ],
  "ulfric's bracers": [
    "Vercingetorigis Bracchialia"
  ],
  "fortify barter and one-handed": [
    "Adde ad Mercationem et Singuli-Manu"
  ],
  "iron battleaxe": [
    "Bellisecuris Ferri"
  ],
  "orcish sword of cowardice": [
    "Orci Gladius Timoris"
  ],
  "some call you a murderer": [
    "Nonnulli te necatorem vocant"
  ],
  "elven warhammer of debilitation": [
    "Alfus Bellimaleus Debilitationis"
  ],
  "dwarven sword of frost": [
    "Gladius Dvemeris Gelidus"
  ],
  "japhet": [
    "Japhet"
  ],
  "roggvir": [
    "Roggvir"
  ],
  "glass greatsword of enervating": [
    "Spatha Vitrea Enervationis"
  ],
  "more blood is always better": [
    "Plus sanguinis semper melius est"
  ],
  "we have to get out of here": [
    "Hinc exire debemus"
  ],
  "orcish dagger of harvesting": [
    "Orci Pugio Saturni"
  ],
  "what do you mean by \"blood and silver\"": [
    "Quid sibi vult \"sanguis et argenteum\""
  ],
  "i return them with honor": [
    "Eos honore reddo"
  ],
  "reveler": [
    "Debacchator"
  ],
  "i was wondering where i could get a drink": [
    "Volui scire ubi bibitum invenire possem"
  ],
  "well, i'm making it \"need to know.\" (intimidate)": [
    "Ergo, id \"sciendum\" facio. (Intimida)"
  ],
  "agrius": [
    "Agrius"
  ],
  "recover staubs' journal": [
    "Inveni Ephemeridem Staubis"
  ],
  "no, nothing": [
    "Minime, nihil"
  ],
  "i'll just be moving on": [
    "Modo pergam"
  ],
  "viarmo": [
    "Viarmo"
  ],
  "i agree. you shouldn't let ulfric push you around. elenwen should stay": [
    "Consentior. Elenveni manendum est"
  ],
  "it's that odd jester, cicero": [
    "Est ille maccus mirabilis, Cicero"
  ],
  "the one with the wagon": [
    "Ille cum plautro"
  ],
  "one with the wagon": [
    "Ille cum plautro"
  ],
  "spell tome: mayhem": [
    "Liber Magicus: Mahemium"
  ],
  "i have your ring": [
    "Anulum tuum habeo"
  ],
  "gleda the goat": [
    "Gleda Capra"
  ],
  "creaturedialoguehorker": [
    "CreatureDialogueHorker"
  ],
  "he's transporting war contraband": [
    "Illa res inclicitas belli portat"
  ],
  "in the coffin": [
    "In sarcophago"
  ],
  "sanurach mine": [
    "Sanurach Mine"
  ],
  "he... attacked a traveler": [
    "Ille... peregrinatorem oppugnavit"
  ],
  "on the road": [
    "In via"
  ],
  "i saw it": [
    "Id vidi"
  ],
  "we can do this": [
    "Hoc facere possumus"
  ],
  "what's the first verse": [
    "Quid est versus primus"
  ],
  "i have": [
    "Sic",
    "Id feci"
  ],
  "cicero won't be bothering you again": [
    "Cicero te iterum non molestabit"
  ],
  "spell tome: blizzard": [
    "Liber Magicus: Hiems"
  ],
  "i thought you knew all the words of power": [
    "Credidi vos omnia Verba Potestatis scire"
  ],
  "how will doing extra jobs help the guild": [
    "Quomodo Sodalitatem adiuvare possum laboribus superfluis"
  ],
  "otar the mad": [
    "Otar Insanus"
  ],
  "elven greatsword of fire": [
    "Spatha Alfa Ignis"
  ],
  "mammoth faction": [
    "Mammoth Faction"
  ],
  "key to degaine's room": [
    "Clavis ad Conclave Degainis"
  ],
  "iron war axe of frost": [
    "Bellisecuris Ferri Gelidus"
  ],
  "wolf guardian spirit": [
    "Animus Custodis Lupi"
  ],
  "lokir": [
    "Lokir"
  ],
  "alik'r hood": [
    "Alik'ris Capucium"
  ],
  "what is the quill of gemination": [
    "Quid est Penna Geminationis"
  ],
  "nightgate inn cellar": [
    "Cellarium Diversorii Portanoctis"
  ],
  "he, um... stole something! i saw him! (persuade)": [
    "Ille, um... aliquid furatus est! Eum vidi! (Persuade)"
  ],
  "rustleif's house": [
    "Rustleif's House"
  ],
  "i have a purchase agreement for you": [
    "Foedus emendi tibi habeo"
  ],
  "daedric boots of frost abatement": [
    "Caligae Daedricae Gelidus Resistendi"
  ],
  "regain whiterun hold": [
    "Recipe Albacursum"
  ],
  "white phial key": [
    "Clavis ad Atrophorion Album"
  ],
  "key to traillus's house": [
    "Clavis ad Domum Trailli"
  ],
  "iron warhammer of dismay": [
    "Bellimalleus Ferratus Timoris"
  ],
  "reverent elven dagger": [
    "Reverens Pugio Alfus"
  ],
  "spider spit": [
    "Spider Spit"
  ],
  "the red kitchen reader": [
    "Liber Culinae Rubrae"
  ],
  "red kitchen reader": [
    "Liber Culinae Rubrae"
  ],
  "i want answers about the forsworn": [
    "Responsa de Abiuratis volo"
  ],
  "weak stamina poison": [
    "Venenum Debile Staminae"
  ],
  "having an outsider do your bidding might seem powerful": [
    "Fortasse peregrinum voluntatem tuam agentem habere te potentem videri faciat"
  ],
  "gestur rockbreaker": [
    "Gestur Saxifractor"
  ],
  "elven battleaxe of devouring": [
    "Bellisecuris Alfus Consumendi"
  ],
  "daedric shield of eminent blocking": [
    "Scutum Daedricum Eminentis Interponendi"
  ],
  "i think you owe me one": [
    "Credo te mihi aliquid debere"
  ],
  "your husband drinking too much": [
    "Nimisne bibit maritus tuus"
  ],
  "steel helmet of major alchemy": [
    "Adamantea Galea Maioris Alchemiae"
  ],
  "i have your sigil stone": [
    "Saxum Sigilis tibi habeo"
  ],
  "you wanted to see me about something": [
    "Voluisti me de aliquo videre"
  ],
  "push effect strong": [
    "Push Effect Strong"
  ],
  "why strike now": [
    "Cur ictum nunc facere debemus"
  ],
  "okay, i can do that": [
    "Ok, Illud facere possum"
  ],
  "nords of skyrim": [
    "Germani Hiemiterrae"
  ],
  "so, buy him off": [
    "Ergo, eum emercabimur"
  ],
  "letter to vals veran": [
    "Epistula ad Vals Veran"
  ],
  "blessed steel mace": [
    "Sanctificata Maca Adamantea"
  ],
  "yngol and the sea-ghosts": [
    "Yngol et Phantasmae Maris"
  ],
  "you want me to murder someone else": [
    "Vis me aliquem alium necare"
  ],
  "[optional] find <alias=jarl> and escape with <alias.pronounobj=jarl> through the city": [
    "[Optio] Inveni <Alias=Jarl> et cum <Alias.PronounObj=Jarl> per urbem effuge"
  ],
  "ysgramor's tomb": [
    "Ysgramorisis Tumulus"
  ],
  "daedric greatsword of garnering": [
    "Spatha Daedrica Horti"
  ],
  "pithi's ornamental ring": [
    "Anulus Pithi Ornamentalis"
  ],
  "glass battleaxe of depleting": [
    "Bellisecuris Vitreus Deplendi"
  ],
  "i found your letter to olfina": [
    "Epistula Olfinae inveni"
  ],
  "why are you in prison": [
    "Cur in carcere es"
  ],
  "virtuous ebony sword": [
    "Virtutis Gladius Ebeni"
  ],
  "mg dormitory scene 6": [
    "MG Dormitory Scene 6"
  ],
  "daedric helmet of eminent alteration": [
    "Galea Daedrica Eminentis Mutationis"
  ],
  "i've found your daughter": [
    "Filiam tuam inveni"
  ],
  "steel plate boots of strength": [
    "Laminatae Caligae Fortitudinis"
  ],
  "steel mace of shocks": [
    "Maca Adamantea Electricitatis"
  ],
  "scroll of ironflesh": [
    "Codex Ferripellis"
  ],
  "rannveigs fast key": [
    "Clavis ad Castellum Rannveigis"
  ],
  "shalidor's insights rewards": [
    "Shalidor's Insights Rewards"
  ],
  "sanctified steel warhammer": [
    "Sanctificatus Bellimalleus Adamanteus"
  ],
  "in order to use the downloaded content associated with this profile, you will need to restart": [
    "In order to use downloaded content associated with this profile, you will need to restart"
  ],
  "surfeit of thieves": [
    "Aplestia Furum"
  ],
  "storm call fire effect 3": [
    "Storm Call Fire Effect 3"
  ],
  "kyr's log": [
    "Adnotata Kyris"
  ],
  "iron warhammer of shocks": [
    "Bellimalleus Ferratus Electricitatis"
  ],
  "saadia": [
    "Saadia"
  ],
  "rulindil": [
    "Rulindil"
  ],
  "ebony battleaxe of damnation": [
    "Bellisecuris Ebeni Damnationis"
  ],
  "maybe i can help you": [
    "Fortasse te adiuvare possum"
  ],
  "bothela": [
    "Bothela"
  ],
  "vampire dust": [
    "Pulvis Vampiri"
  ],
  "philter of alteration": [
    "Philtrum Mutationis"
  ],
  "i've taken care of the problem": [
    "Finem ad problema feci"
  ],
  "trap trigger time interval": [
    "Trap Trigger Time Interval"
  ],
  "steel war axe of fatigue": [
    "Bellisecuris Adamanteus Defatigationis"
  ],
  "elven sword of shocks": [
    "Alfus Gladius Electricitatis"
  ],
  "boethiah's conduit": [
    "Diorx Boethiae"
  ],
  "dwarven battleaxe of harvesting": [
    "Dvemeris Bellisecuris Saturni"
  ],
  "orcish armor of the eminent knight": [
    "Orci Lorica Eminentis Equitis"
  ],
  "why did this make you attack the girl": [
    "Quamobrem puellam oppugnavisti"
  ],
  "i know what you are, nightspawn": [
    "Scio quid te esse, noctis fili"
  ],
  "daedric greatsword of lethargy": [
    "Spatha Daedrica Lethargiae"
  ],
  "key to radiant raiments": [
    "Clavis ad Vestimenta Radiantias"
  ],
  "i've cleared the beast's lair": [
    "Cubile bestiae purgavi"
  ],
  "why are you doing all this": [
    "Cur haec omnia facis"
  ],
  "blizzard 65": [
    "Blizzard 65"
  ],
  "liminal bridges": [
    "Pontes Liminales"
  ],
  "disaffected <basename> leader": [
    "Disaffectus <BaseName> Dux"
  ],
  "elgrim's elixir's scene 02": [
    "Elgrim's Elixir's Scene 02"
  ],
  "sarethi farm interior": [
    "Sarethi Farm Interior"
  ],
  "you mentioned someone named mallus": [
    "Commemoravistine quem nomine \"Mallus\""
  ],
  "gold ruby necklace": [
    "Auri Rubini Monile"
  ],
  "what's next for you": [
    "Quid proximum tibi est"
  ],
  "black briar lodge": [
    "Black Briar Lodge"
  ],
  "dwarven greatsword of soul snares": [
    "Dvemeris Spatha Animicapti"
  ],
  "i think you know what it is": [
    "Credo te scire quid esse"
  ],
  "elven boots of frost suppression": [
    "Caligae Alfae Contra Glaciem"
  ],
  "i'd rather not take that job": [
    "Malim munus non accipere"
  ],
  "mystery of talara, part 4": [
    "Misterium Talarae, Pars IV"
  ],
  "put out the fire in the solitude lighthouse": [
    "Extingue ignem in Pharone Solitudinis"
  ],
  "how do i fit in": [
    "Quem partem agam"
  ],
  "the <alias=beast> is dead": [
    "<Alias=Beast> mortuus est"
  ],
  "<alias=beast> is dead": [
    "<Alias=Beast> mortuus est"
  ],
  "stony creek cave": [
    "Stony Creek Cave"
  ],
  "glass battleaxe of harrowing": [
    "Bellisecuris Vitreus Occationis"
  ],
  "can't help you there": [
    "In illo te adiuvare non possum"
  ],
  "i found king olaf's verse": [
    "Inveni Versum Regis Olafis"
  ],
  "corsair": [
    "Pirata"
  ],
  "okay, what's next": [
    "Ok, quid proximum"
  ],
  "alexia vici": [
    "Alexa Vici"
  ],
  "how can a spirit die": [
    "Quomodo animus mori potest"
  ],
  "voice powers": [
    "Voice Powers"
  ],
  "quest updated": [
    "Munus Refectum"
  ],
  "speak to tonilia about exchanging a piece of armor": [
    "Toniliam Adloquere de Loricam Mutando"
  ],
  "you... sent me to die": [
    "Tu... mihi misit ut moriar"
  ],
  "i'm not into books": [
    "De libris non curo"
  ],
  "dangerous magic": [
    "Dangerous Magic"
  ],
  "glass greatsword of harrowing": [
    "Spatha Vitrea Occationis"
  ],
  "he convinced solitude to attack winterhold": [
    "Persuasit Solitudini Hiemiterram oppugnare"
  ],
  "thief of virtue": [
    "Fur Virtutis"
  ],
  "so, only the poor or the sick live here": [
    "Ergo, modo puaperes vel infirmi hic vivunt"
  ],
  "what exactly do you need from me": [
    "Quid a me vis"
  ],
  "barbas": [
    "Barbas"
  ],
  "is there some problem in the city": [
    "Estne quid difficultas hac in urbe"
  ],
  "ebony mace of debilitation": [
    "Maca Ebeni Debilitationis"
  ],
  "clever or not, they need to die": [
    "Callidi vel non, morituri sunt"
  ],
  "spell tome: healing hands": [
    "Liber Magicus: Manus Salubres"
  ],
  "i'd like to see the excavation site": [
    "Volo aream excavationis videre"
  ],
  "glass battleaxe of scorching": [
    "Bellisecuris Vitreus Adurendi"
  ],
  "orcish armor of major health": [
    "Orci Lorica Maioris Salutis"
  ],
  "you cannot go that way": [
    "Hac via non uti potes"
  ],
  "die, worm": [
    "Morire, vermis"
  ],
  "so you got arrested for his murder": [
    "Ergo comprehensus es pro nece eius"
  ],
  "dwarven dagger of freezing": [
    "Dvemeris Pugio Gelidus"
  ],
  "leather helmet of major archery": [
    "Cutis Galea Maioris Sagittationis"
  ],
  "your real name is svidi, isn't it": [
    "Nomen verum tuum Svidi est, nonne"
  ],
  "charred skeever meat": [
    "Adustus Caro Skeever"
  ],
  "she's made her way east into morrowind": [
    "Illa ad eurum in Morriventum effugit"
  ],
  "she said she can sneak you up to her quarters right now": [
    "Dixit se te nunc ad cubilia clam ferre posse"
  ],
  "goat hide": [
    "Capri Reno"
  ],
  "what kind of delivery": [
    "Qualis cista"
  ],
  "swanhvir": [
    "Svanhvir"
  ],
  "gallus's journal mentioned the \"twilight sepulcher.\"": [
    "Ephemeris Galli \"Sepulchrum Crepusculare\" commemoravit"
  ],
  "do you just want to serve your time": [
    "Visne tempus incarceratum agere"
  ],
  "glass mace of depleting": [
    "Maca Vitrea Deplendi"
  ],
  "very well, we do it your way for now": [
    "Bene, modum operandi tuum nunc sequemur"
  ],
  "jofthor": [
    "Jofthor"
  ],
  "mq201 party scene 3": [
    "MQ201 Party Scene 3"
  ],
  "i have nightshade for you": [
    "Trychnon tibi habeo"
  ],
  "does the temple need any additional assistance": [
    "Caretne templum pluri auxilii"
  ],
  "can there truly be peace between the thalmor and the empire": [
    "Potesne pax vera inter Visigothos Romanosque esse"
  ],
  "hargar's journal": [
    "Ephemberis Hargaris"
  ],
  "severio pelagia": [
    "Severio Pelagia"
  ],
  "i'll be right back": [
    "Reveniam"
  ],
  "can't you come with me to winterhold": [
    "Nonno mecum venire potes Hiemiterram"
  ],
  "what do you want me to say to this monk": [
    "Quid vis ut huic Monacho dicam"
  ],
  "opusculus lamae bal": [
    "Opusculus Lamae Bal"
  ],
  "he was an academic yet he chose a different path": [
    "Academicus erat sed viam aliam elegit"
  ],
  "what would happen to sailors on the boat": [
    "Quid ad nautas in nave accidat"
  ],
  "why did gallus scribe his journal in falmer": [
    "Cur Gallus ephemeridem suam lingua Falmeri scripsit"
  ],
  "where did he acquire the knowledge to use it": [
    "Ubi scientiam de uso eius didicit"
  ],
  "proventus avenicci": [
    "Proventus Avenicci"
  ],
  "nord hero war axe": [
    "Heroos Germanici Bellisecuris"
  ],
  "it's good to see you again, old friend": [
    "Mihi valde placet te iterum videre amice vetus"
  ],
  "key to skaggi's house": [
    "Clavis ad Domum Skaggi"
  ],
  "what is this place again": [
    "Quid est hic locus, iterum"
  ],
  "femaleeyeshumanblind": [
    "FemaleEyesHumanBlind"
  ],
  "ebony dagger of fire": [
    "Pugio Ebeni Ignis"
  ],
  "dishonored skald": [
    "Skald Dishonoratus"
  ],
  "iron boots of waning fire": [
    "Ferri Caligae Insenescentis Ignis"
  ],
  "will you return to cyrodiil now": [
    "Reveniesne Romam nunc"
  ],
  "lorcalin's orders": [
    "Mandata Lorcalinis"
  ],
  "is no longer your": [
    "noniam est tuus"
  ],
  "impressive": [
    "Impressive"
  ],
  "2920, last seed, v8": [
    "[MMCMXX] 2920, Semen Ultimum, V. VIII"
  ],
  "first lessons": [
    "Scholae Primae"
  ],
  "daedric gauntlets of peerless sure grip": [
    "Daedricae Manicae Optimae Ambidexteritatis"
  ],
  "ebony mace of the sorcerer": [
    "Maca Ebeni Magi"
  ],
  "quit enchanting": [
    "Exi Incantamentum"
  ],
  "tulvur": [
    "Tulvur"
  ],
  "hallowed glass bow": [
    "Consacratus Arcus Vitreus"
  ],
  "i need a prisoner released from northwatch keep": [
    "Volo captivum e Castello Septentriovisu liberari"
  ],
  "both of you wait here for a moment": [
    "Vos ambo hic manete"
  ],
  "who is haldyn": [
    "Quis est Haldyn"
  ],
  "the selected storage device does not have sufficient space available": [
    "The selected storage device does not have sufficient space available"
  ],
  "selected storage device does not have sufficient space available": [
    "The selected storage device does not have sufficient space available"
  ],
  "new saves will not be created": [
    "New saves will not be created"
  ],
  "elven battleaxe of fire": [
    "Bellisecuris Alfus Ignis"
  ],
  "their leader is a battle-mage named haldyn": [
    "Dux est bellimagus nomine Haldyn"
  ],
  "valthume cellar key": [
    "Clavis ad Cellarium Valthumis"
  ],
  "leather helmet of major alteration": [
    "Cutis Galea Maioris Mutationis"
  ],
  "i think it would be better if you waited here": [
    "Credo melius erit si te hic manebis"
  ],
  "alftand glacial ruins": [
    "Alfterrae Ruinaae Glaciarii"
  ],
  "orcish greatsword of harrowing": [
    "Orci Spatha Occationis"
  ],
  "burial chamber": [
    "Sepultura"
  ],
  "cow hand": [
    "Servus Vaccarum"
  ],
  "dialoguedawnstarquicksilverleigelflond01": [
    "DialogueDawnstarQuickArgenteiLeigelfLond01"
  ],
  "dwarven bracers of major sure grip": [
    "Dvemeres Chirothecae Maioris Dexteritatis"
  ],
  "key to thonnir's house": [
    "Clavis ad Domum Thonniris"
  ],
  "pay tolfidr 500 gold to lift your suspension": [
    "Da D Auri Tolfdiri ut Suspensum Eradat"
  ],
  "ebony greatsword": [
    "Spatha Ebeni"
  ],
  "i've had enough of this": [
    "Satis mihi est"
  ],
  "bring a filled grand soul gem to sergius turrianus": [
    "Fer Gemmanimam Completam Sergio Turriano"
  ],
  "his life is in danger. (persuade)": [
    "Vita eius in periculo est. (Persuade)"
  ],
  "ulfric holds only windhelm": [
    "Vercingetorix Ventigaleam solam habet"
  ],
  "he's in no position to make demands": [
    "Nihil deposcere potest"
  ],
  "dialoguesalviusfarmhousescene01": [
    "DialogueSalviusFarmhouseScene01"
  ],
  "i mean that you should be bold": [
    "Debes audacem esse"
  ],
  "repel lesser undead": [
    "Repel Lesser Undead"
  ],
  "iron mace of frost": [
    "Ferri Maca Gelidus"
  ],
  "you were an adventurer": [
    "Peregrinator fuisti"
  ],
  "maleheadredguard": [
    "MaleHeadRedguard"
  ],
  "don't know why i bothered": [
    "Nescio cur id fecerim"
  ],
  "this place is a mess": [
    "Hoc locus omnino immundus est"
  ],
  "so prison is a guild punishment": [
    "Ergo cacer est poena Sodalitatis"
  ],
  "aren't you worried about wolfskull cave": [
    "Nonne de Spelunca Calvarialupi sollicitaris"
  ],
  "assist jarl balgruuf with the dragon threat": [
    "Adiuva Comitem Balgruufem Contra Dracones"
  ],
  "steel plate helmet of eminent restoration": [
    "Laminata Galea Eminentis Reficiendi"
  ],
  "hafjorg": [
    "Hafjorg"
  ],
  "mercenaries": [
    "Mercenarii"
  ],
  "not riften guards": [
    "Non custodes Hiati"
  ],
  "on second thought, maybe we'll fight later": [
    "Ut iterum cogito, fortasse postea pugnabimus"
  ],
  "iron helmet of major alchemy": [
    "Ferri Galea Maioris Alchemiae"
  ],
  "what do you mean \"supposed\"": [
    "Cur dicis \"putatur\""
  ],
  "you don't believe us": [
    "Nobis non credis"
  ],
  "what would convince you that we're on your side": [
    "Quomodo tibi ad factionem nostram suadere possim"
  ],
  "something went wrong. winterhold may be in danger. can you help": [
    "Aliquid in errore est. Fortasse Hiemiterra in periculo est. Potesne adiuvare"
  ],
  "forelhost courtyard": [
    "Peristylum Forelhostis"
  ],
  "were your parents killed at helgen": [
    "Necati sunt parentis tuis ad Helgen"
  ],
  "potema's sanctum key": [
    "Clavis ad Sanctum Potemae"
  ],
  "philter of the knight": [
    "Philtrum Equitis"
  ],
  "if you believe in talos, why don't you join our cause": [
    "Si Talos crederes, quidni factionem nostram adiungas"
  ],
  "i found some evidence": [
    "Argumentum inveni"
  ],
  "perhaps we could come to some kind of agreement": [
    "Fortasse ad consensum venire possumus"
  ],
  "hairlinemalenord8": [
    "HairLineMaleNord8"
  ],
  "wulfgar": [
    "Vulfgar"
  ],
  "the book of love": [
    "Liber Amoris"
  ],
  "book of love": [
    "Liber Amoris"
  ],
  "20,000 gold": [
    "Viginti Milia Denariorum"
  ],
  "wisp's kiss": [
    "Wisp's Kiss"
  ],
  "sorry, no joke": [
    "Ignosce, nullum iocum"
  ],
  "glass dagger of lightning": [
    "Pugio Vitreus Fulminis"
  ],
  "find <alias=questitem> for <alias.shortname=questgiver>": [
    "Inveni <Alias=QuestItem> pro <Alias.ShortName=QuestGiver>"
  ],
  "ebony greatsword of freezing": [
    "Spatha Ebeni Gelidus"
  ],
  "umurn": [
    "Umurn"
  ],
  "why is your cemetery so large": [
    "Cur tam magnum est coemeterium tuum"
  ],
  "skuldafn temple": [
    "Skuldafn Templum"
  ],
  "what about the information on goldenglow estate": [
    "Quid de informatione de Villa Aurilucis"
  ],
  "no name or anything": [
    "Nullum nomen nec quidquid"
  ],
  "the song of pelinal, v4": [
    "Carmen Pelinalis, IV"
  ],
  "song of pelinal, v4": [
    "Carmen Pelinalis, IV"
  ],
  "holy ebony dagger": [
    "Sanctus Pugio Ebeni"
  ],
  "db11amaundjobdonetopic": [
    "DB11AmaundJobDoneTopic"
  ],
  "why did leifnarr go to broken helm hollow": [
    "Cur Leifnarr Antrum Galeae Fractae petit"
  ],
  "you were... expecting me": [
    "Me... exspectavisti"
  ],
  "elven sword of despair": [
    "Alfus Gladius Desponsionis"
  ],
  "db11emperorplayerresponse13": [
    "DB11ImperatorPlayerResponse13"
  ],
  "i misspoke": [
    "Prave dixi"
  ],
  "steel plate bracers of eminent archery": [
    "Chirothecae Laminatae Eminentis Saggitationis"
  ],
  "daedric dagger of annihilating": [
    "Pugio Daedricus Annihilationis"
  ],
  "dialoguemarkarthinnklepprfrabbiscene03": [
    "DialogueMarkarthInnKlepprFrabbiScene03"
  ],
  "we have unfinished business, motierre": [
    "Negotium imperfectum habemus, Motierre"
  ],
  "elven dagger of stunning": [
    "Pugio Alfus Intermissionis"
  ],
  "package for grelka": [
    "Fascis Grelkae"
  ],
  "i'd like to hire your carriage": [
    "Volo raedam tuam conducere"
  ],
  "orc hunter": [
    "Orcus Venator"
  ],
  "by right of blood": [
    "Ius sanguinis"
  ],
  "i listen for the night mother": [
    "Auditor Matris Noctis sum"
  ],
  "by right of plunder": [
    "Furto"
  ],
  "i am a nightingale of nocturnal": [
    "Philomela Nocturnalis sum"
  ],
  "can you please help me find this augur": [
    "Potesne me hunc Augurem invenire adiuvare"
  ],
  "dwarven war axe of burning": [
    "Dvemeris Bellisecuris Cremandi"
  ],
  "we'll save her together": [
    "Eam una servabimus"
  ],
  "reverent iron mace": [
    "Reverens Maca Ferri"
  ],
  "what will change, now that the night mother is here": [
    "Quod Mater Noctis hic est, quid nunc mutabit"
  ],
  "i want to know about thorald gray-mane": [
    "Volo de Thorald Juba-Cana scire"
  ],
  "you sound like quite the businessman": [
    "Negotiator optimus videris"
  ],
  "here are the notes you wanted": [
    "Hic sunt adnotationes quas voluisti"
  ],
  "i'll bring him back myself": [
    "Ego ipse eum referam"
  ],
  "jod": [
    "Jod"
  ],
  "olfrid battle-born": [
    "Olfrid Proeli-Natus"
  ],
  "i trust you": [
    "Tibi credo"
  ],
  "i'll lead you through this mist": [
    "Te per nebulam ducam"
  ],
  "dwarven greatsword of draining": [
    "Dvemeris Spatha Hauriendi"
  ],
  "the mine isn't safe? why": [
    "Metallum tutum non est? Quam ob rem"
  ],
  "mine isn't safe? why": [
    "Metallum tutum non est? Quam ob rem"
  ],
  "i'll wield this mighty blade in your name": [
    "Ensi caelipotente in nomine tuo utar"
  ],
  "orcish war axe of weariness": [
    "Orci Bellisecuris Maioris Defatigationis"
  ],
  "ebony warhammer of lethargy": [
    "Bellimalleus Ebeni Lethargiae"
  ],
  "dwarven greatsword of diminishing": [
    "Dvemeris Spatha Diminuendi"
  ],
  "ondolemar": [
    "Ondolemar"
  ],
  "elven war axe of binding": [
    "Alfus Bellisecuris Deligandi"
  ],
  "lipsredguardmale01": [
    "LipsRedguardMale01"
  ],
  "elrindir": [
    "Elrindir"
  ],
  "flame of the harbinger": [
    "Flamma Praenuntii"
  ],
  "vanryth gatharian": [
    "Vanryth Gatharian"
  ],
  "the wolf queen, v7": [
    "Regina Luporum, VII"
  ],
  "wolf queen, v7": [
    "Regina Luporum, VII"
  ],
  "i'm the best at what i do": [
    "Optimus sum in quo facio"
  ],
  "sorry you're disappointed": [
    "Me paenitet te frustratum esse"
  ],
  "what can you tell me about markarth": [
    "Quid mihi dicere potes de Markartia"
  ],
  "stop following me": [
    "Nole me sequi"
  ],
  "narfi": [
    "Narfi"
  ],
  "(invisible continue / walk away)": [
    "(Invisible Continue / Walk Away)"
  ],
  "elven battleaxe of fear": [
    "Bellisecuris Alfus Timoris"
  ],
  "dragonplate helmet of extreme alchemy": [
    "Draconilaminata Galea Extremis Alchemiae"
  ],
  "not until alduin is defeated": [
    "Not ante Alduinus vincitur"
  ],
  "steel mace of cowardice": [
    "Maca Adamantea Timoris"
  ],
  "we seem to be at an impasse, then": [
    "Ad nodum, igitur, Gordianum stamus"
  ],
  "wedding sandals": [
    "Soleae Nuptuales"
  ],
  "we need to talk": [
    "Colloqui debemus"
  ],
  "dead mans drink": [
    "Dead Mans Drink"
  ],
  "orcish gauntlets of extreme alchemy": [
    "Orci Manicae Extremis Alchemiae"
  ],
  "elven warhammer of despair": [
    "Alfus Bellimaleus Desponsionis"
  ],
  "take me to skuldafn": [
    "Fer me ad Skuldafn"
  ],
  "textures\\blood\\screenbloodcolor01opt.dds": [
    "Textures\\Blood\\ScreenBloodColor01OPT.dds"
  ],
  "show alva's journal to the jarl": [
    "Demonstra Ephemeridem Alvae ad Comitem"
  ],
  "orcish greatsword of harvesting": [
    "Orci Spatha Saturni"
  ],
  "so why haven't you arrested him": [
    "Sic cur eum non comprehendisti"
  ],
  "gods and worship": [
    "Dei et Religio"
  ],
  "daedric dagger of debilitation": [
    "Pugio Daedricus Debilitationis"
  ],
  "what about your wife and child": [
    "Quid de uxore et filio"
  ],
  "a dance in fire, v3": [
    "Saltatio in Flammis, III"
  ],
  "dance in fire, v3": [
    "Saltatio in Flammis, III"
  ],
  "tell me about gallus": [
    "Dic mihi de Gallo"
  ],
  "hillgrund's chest key": [
    "Clavis ad Arcam Hillgrundis"
  ],
  "hallowed elven warhammer": [
    "Consacratus Alfus Bellimalleus"
  ],
  "may i commune with peryite again": [
    "Possumne iterum cum Peryito loqui"
  ],
  "glass helmet of extreme restoration": [
    "Galea Vitrea Extremis Reficiendi"
  ],
  "wikill06 enable and move thugs": [
    "WIKill06 Enable et Move Thugs"
  ],
  "it's a deal": [
    "Consentior"
  ],
  "you can have orthorn": [
    "Orthornem habere potes"
  ],
  "dwarven greatsword of devouring": [
    "Dvemeris Spatha Consumendi"
  ],
  "among the companions' most treasured artifacts are the fragments of wuuthrad, the axe that ysgramor himself wielded when leading the five hundred": [
    "Apud gazam pretiosissimam Contubernalium sunt Partes Wuuthradis, Securis quo Ysgramoris ipse usus est dum Quingenti duxit"
  ],
  "imperial bracers of minor conjuring": [
    "Chirothecae Romanae Minoris Vocandi"
  ],
  "(invisible walk away)": [
    "(Invisible Walk Away)"
  ],
  "lu-ah's journal": [
    "Ephemeris Lu-ae"
  ],
  "bring the star to aranea": [
    "Fer Stellam ad Araneam"
  ],
  "my heart is full of purpose, and my life is full of deeds": [
    "Cor plenum intentiae est, et vita mea plena munerum est"
  ],
  "orcish dagger of consuming": [
    "Orci Pugio Consumendi"
  ],
  "you're with the college": [
    "Cum Collegio es"
  ],
  "humanbeard27": [
    "HumanBeard27"
  ],
  "yrsarald thrice-pierced": [
    "Yrsarald Ter-Transfixus"
  ],
  "i know where to find the staff of magnus": [
    "Scio ubi Rudis Magnus inveniatur"
  ],
  "you ready to listen to reason now": [
    "Este paratus rationem nunc auscultare"
  ],
  "dremora faction": [
    "Dremora Faction"
  ],
  "the black arrow, v2": [
    "Sagitta Atra, II"
  ],
  "black arrow, v2": [
    "Sagitta Atra, II"
  ],
  "grindstones": [
    "Grindstones"
  ],
  "do you worship talos": [
    "Venerasne Talos"
  ],
  "dialoguedawnstarwhitehallbrinahorikscene01": [
    "DialogueDawnstarWhiteHallBrinaHorikScene01"
  ],
  "what's the gray quarter": [
    "Quid est Regio Canuta"
  ],
  "silver garnet ring": [
    "Anulus Argenteus Spessartini"
  ],
  "enduring stamina poison": [
    "Durantis Venenum Staminae"
  ],
  "playerinironsights": [
    "PlayerinIronSights"
  ],
  "ahzirr traajijazeri": [
    "Ahzirr Traajijazeri"
  ],
  "daedric mace of fire": [
    "Maca Daedrica Ignis"
  ],
  "whiterun maiden-loom manor faction": [
    "Whiterun Maiden-Loom Manor Faction"
  ],
  "tell me about the briar heart": [
    "Dic mihi de Cordumis"
  ],
  "i'd be more than interested": [
    "Mihi valde intersit"
  ],
  "talk to stig salt-plank": [
    "Adloquere Stig Sal-Trabs"
  ],
  "so how do we get into the thalmor embassy": [
    "Ergo, quomodo in legationem Visigothum intrabimus"
  ],
  "i don't believe in fate": [
    "Fato non credo"
  ],
  "what are you saying": [
    "Quid dicis"
  ],
  "all unsaved progress will be lost": [
    "Omne progressum intutum perdetur"
  ],
  "daedric greatsword": [
    "Spatha Daedrica"
  ],
  "now where are the books": [
    "Ubi sunt libri"
  ],
  "so the key unlocks any door": [
    "Ergo clavis omnes portas aperit"
  ],
  "browsmalehumanoid09": [
    "BrowsMaleHumanoid09"
  ],
  "perhaps we can come to an arrangement": [
    "Fortasse ad compactionem venire poterimus"
  ],
  "hamal": [
    "Hamal"
  ],
  "model ship": [
    "Navis Exemplar"
  ],
  "know anything about goldenglow estate": [
    "Scisne quid de Villa Aurilucis"
  ],
  "logrolf the willful": [
    "Logrolf Contumax"
  ],
  "i have all your items for you": [
    "Omnes res rogatas tibi habeo"
  ],
  "why is the phial so important to you": [
    "Quamobrem est Atrophorion tanti magni momenti tibi"
  ],
  "quite a party. it's my first time, you know. where can i get a drink": [
    "Nonne Convivium optimum est? Tempus primum mihi est. Ubi poculum invenire possum"
  ],
  "goldenglow estate basement": [
    "Auriluminis Villae Cellarium"
  ],
  "treated soul gem": [
    "Gemmanima Tractata"
  ],
  "malborn, it's good to see you": [
    "Malborn, gaudeo te videre"
  ],
  "ragged flagon scene 08": [
    "Ragged Flagon Scene 08"
  ],
  "steel sword of shocks": [
    "Gladius Adamanteus Electricitatis"
  ],
  "dark souls zombie": [
    "Dark Souls Zombie"
  ],
  "steel plate helmet of conjuring": [
    "Laminata Galea Vocandi"
  ],
  "sorry, i can't help right now": [
    "Ignosce, nunc adiuvare non possum"
  ],
  "tell me about this thalmor assassin that's hunting you": [
    "Dic mihi de sicario Visigotho qui te venatur"
  ],
  "dwarven armor of health": [
    "Dvemeris Lorica Salutis"
  ],
  "sounds like you're just being paranoid": [
    "Mihi videtur you're just being paranoid"
  ],
  "daedric battleaxe of malediction": [
    "Bellisecuris Daedricus Maledictionis"
  ],
  "what do you know about thorald gray-mane": [
    "Quid de Thorald Cana-Juba Scis"
  ],
  "orcish bow of burning": [
    "Orci Arcus Cremandi"
  ],
  "where can i find such a weapon": [
    "Ubi tantum armum invenire possum"
  ],
  "pension of the ancestor moth": [
    "Pensio Papilionis Maiorum"
  ],
  "dwemer gear": [
    "Dvemere Choragium"
  ],
  "leather boots of minor sneaking": [
    "Corii Caligae Minoris Repsendi"
  ],
  "niranye's basement": [
    "Niranyis Cellarium"
  ],
  "humanbeard10": [
    "HumanBeard10"
  ],
  "exiled government": [
    "Exiled Government"
  ],
  "ebony sword of lethargy": [
    "Gladius Ebeni Lethargiae"
  ],
  "what makes you think the thalmor are bringing dragons back": [
    "Cur credis Visigothos culpabiles esse"
  ],
  "so azura isn't giving you more visions": [
    "Ergo, Azura tibi nullas plurimas visiones propheticas dat"
  ],
  "ebony boots of the ox": [
    "Caligae Ebeni Bovis"
  ],
  "auto": [
    "AUTO"
  ],
  "tell me about the agent of subterfuge": [
    "Dic mihi de Agente Subterfugii"
  ],
  "are there any more contracts, nazir": [
    "Suntne plurima foedera, Nazir"
  ],
  "mithorpa's house key": [
    "Clavis ad Domum Mithorpae"
  ],
  "i'll take care of this assassin for you": [
    "Sicarium pro te necam"
  ],
  "chaurus box": [
    "Chaurus Box"
  ],
  "daedric sword of the vampire": [
    "Gladius Daedricus Vampiri"
  ],
  "virtuous daedric greatsword": [
    "Virtutis Spatha Daedrica"
  ],
  "torch sconce": [
    "Cornucopium Facis"
  ],
  "the refugees": [
    "Profugi"
  ],
  "refugees": [
    "Profugi"
  ],
  "are you nervous about being here": [
    "Esne sollicitus quod hic es"
  ],
  "why don't you leave your master": [
    "Quidni e domino effugias"
  ],
  "daedric greatsword of stunning": [
    "Spatha Daedrica Intermissionis"
  ],
  "elven sword of binding": [
    "Alfus Gladius Deligandi"
  ],
  "mogdurz": [
    "Mogdurz"
  ],
  "destroyobject": [
    "DestroyObject"
  ],
  "ward - lesser": [
    "Praesidium Magicum - Minus"
  ],
  "elven mace of consuming": [
    "Maca Alfa Consumendi"
  ],
  "glass helmet of extreme magicka": [
    "Galea Vitrea Extremis Magicae"
  ],
  "key to addvar's house": [
    "Clavis ad Domum Addvaris"
  ],
  "glass greatsword of leeching": [
    "Spatha Vitrea Sanguisugae"
  ],
  "staubin's diary": [
    "Ephemeris Staubinis"
  ],
  "irontree mill house key": [
    "Clavis ad Domum Molae Ferriarboris"
  ],
  "all i know is that your people need you to return": [
    "Omne quod scio est tibi et amicis tuis revenire necesse esse"
  ],
  "expert bellows": [
    "Expert Bellovs"
  ],
  "i need a way to lure a dragon to dragonsreach": [
    "Volo draconem ad Draconicarcerem arcessere"
  ],
  "elven warhammer of animus": [
    "Alfus Bellimaleus Animi"
  ],
  "sorry, sir. i was trying to come to a fair agreement": [
    "Ignosce mihi, domine. Ad consensum aequum venire volui"
  ],
  "discovered effects": [
    "Effecta Inventa"
  ],
  "fast travel to %s": [
    "Celeriter peregrinare ad %s"
  ],
  "the firsthold revolt": [
    "Res Novae Primitensus"
  ],
  "firsthold revolt": [
    "Res Novae Primitensus"
  ],
  "mg dormitory scene 11": [
    "MG Dormitory Scene 11"
  ],
  "i won't disappoint you, astrid. or the dark brotherhood": [
    "Nec te nec Fraternitatem Obscuram fallam"
  ],
  "pilgrim's trench": [
    "Pilgrim's Trench"
  ],
  "ebony sword of terror": [
    "Gladius Ebeni Terroris"
  ],
  "what else can you tell me about jarrin root": [
    "Quid plus de radice jarrinis mihi narrare potes"
  ],
  "orcish warhammer of consuming": [
    "Orci Bellimalleus Consumendi"
  ],
  "(show maro the gourmet's writ of passage)": [
    "(Demonstra Maroni Signum Apicii)"
  ],
  "follow the pilgrim's path": [
    "Sequere Viam Peregrinatoris"
  ],
  "emblem iv": [
    "Emblema IV"
  ],
  "stolen <basename>": [
    "Furatum <BaseName>"
  ],
  "makhel abbas": [
    "Makhel Abbas"
  ],
  "skyforge steel war axe": [
    "Fabricaeli Bellisecuris Adamanteus"
  ],
  "cicero's boots": [
    "Ciceronis Caligae"
  ],
  "quest added": [
    "Munus Additum"
  ],
  "i have firewood to sell": [
    "Habeo lignum vendendum"
  ],
  "hairmaleorc15": [
    "HairMaleOrc15"
  ],
  "hlaalu farmhouse key": [
    "Clavis ad Villam Rusticam Hlaalus"
  ],
  "the song of pelinal, v5": [
    "Carmen Pelinalis, V"
  ],
  "song of pelinal, v5": [
    "Carmen Pelinalis, V"
  ],
  "leather armor of the squire": [
    "Corii Lorica Armiferis"
  ],
  "the next ingredient is... vampire dust": [
    "Ingredientium proximum est... pulvus vampirus"
  ],
  "next ingredient is... vampire dust": [
    "Ingredientium proximum est... pulvus vampirus"
  ],
  "spell tome: courage": [
    "Liber Magicus: Virtus"
  ],
  "nightmare visuals": [
    "Nightmare Visuals"
  ],
  "ebony mace of animus": [
    "Maca Ebeni Animi"
  ],
  "one nirnroot": [
    "Unus nirniradix"
  ],
  "imperial bracers of minor smithing": [
    "Chirothecae Romanae Minoris Ferrarii"
  ],
  "what kind of transformations": [
    "Quales mutationes"
  ],
  "fort sungard muster": [
    "Tabernaculum Castelli Auroris"
  ],
  "i'm having some difficulties with the guards": [
    "Difficultates cum custodibus habeo"
  ],
  "can you take care of that": [
    "Potesne illam rem administrare"
  ],
  "glass mace of immobilizing": [
    "Maca Vitrea Immobilitatis"
  ],
  "the game must restart now because downloaded content has been removed": [
    "Ludum reincipere debes quod res ex rete repromptae emotae sunt"
  ],
  "game must restart now because downloaded content has been removed": [
    "Ludum reincipere debes quod res ex rete repromptae emotae sunt"
  ],
  "glow dust": [
    "Pulvus Lucifer"
  ],
  "steel boots of waning shock": [
    "Caligae Adamanteae Insenescentis Electricitatis"
  ],
  "medresi's notes": [
    "Adnotationes Medresi"
  ],
  "convince loreius or report cicero": [
    "Persuade Loreio VEL refer Ciceronem"
  ],
  "the empire should turn over <alias=imperialminorhold2>": [
    "Opus est Imperio <Alias=ImperialMinorHold2> tradere"
  ],
  "empire should turn over <alias=imperialminorhold2>": [
    "Opus est Imperio <Alias=ImperialMinorHold2> tradere"
  ],
  "if i do, will you tell me who set the fire": [
    "Si id faciam, dicasne mihi quis ignem coepit"
  ],
  "philter of resist cold": [
    "Philtrum Resistendi Frigorem"
  ],
  "glass mace of stunning": [
    "Maca Vitrea Intermissionis"
  ],
  "what artifact": [
    "Quid artefactum"
  ],
  "i'll get some for you": [
    "Nonnulla tibi adferam"
  ],
  "glass gauntlets of eminent smithing": [
    "Vitreae Manicae Maximi Faciendi"
  ],
  "thank you, lord molag bal": [
    "Tibi gratias ego, domine Molag Bol"
  ],
  "eriana": [
    "Eriana"
  ],
  "werj05powerattackbark": [
    "WERJ05PowerAttackBark"
  ],
  "hairmalenord15": [
    "HairMaleNord15"
  ],
  "leather boots of dwindling frost": [
    "Corii Caligae Minoris Congelationis"
  ],
  "captain aldis": [
    "Centurio Aldis"
  ],
  "i'm not really interested in anything dangerous": [
    "Res periculosae mihi non intersunt"
  ],
  "what will the dreamstride feel like": [
    "Quomodo in Somnambulo sentiam"
  ],
  "dwarven battleaxe of diminishing": [
    "Dvemeris Bellisecuris Diminuendi"
  ],
  "what do you have there": [
    "Quid illic habes"
  ],
  "steel plate boots of brawn": [
    "Laminatae Caligae Fortitudinis"
  ],
  "steel plate armor of health": [
    "Laminata Lorica Salutis"
  ],
  "<alias.cap=sonsminorholdcapital> seems like a fair trade": [
    "Aequabiliter mihi videtur <Alias.Cap=SonsMinorHoldCapital> tradere"
  ],
  "key to jens' farmhouse": [
    "Clavis ad Villam Rusticam Jentis"
  ],
  "prison registry": [
    "in Tabulam Carceris"
  ],
  "you want me to get those fragments": [
    "Visne me fragmenta invenire"
  ],
  "where are we going": [
    "Quo imus"
  ],
  "sarethi farm scene 02": [
    "Sarethi Farm Scene 02"
  ],
  "tell me about the bedlam jobs": [
    "Narra mihi de munera \"bedlam\""
  ],
  "orcish helmet of eminent restoration": [
    "Orci Galea Eminentis Reficiendi"
  ],
  "ebony sword of the vampire": [
    "Gladius Ebeni Vampiri"
  ],
  "ebony warhammer of the sorcerer": [
    "Bellimalleus Ebeni Magi"
  ],
  "and what of his widow": [
    "Et quid de viduo eius"
  ],
  "doesn't she claim the throne": [
    "Nonne illa solium vult"
  ],
  "miscellaneous": [
    "Miscellanei"
  ],
  "madanach asked me to hear your story": [
    "Madanach mihi oravit ut fabulam tuam audirem"
  ],
  "i cannot defy hircine": [
    "Hecaten negare non possum"
  ],
  "you have to die": [
    "Tibi moriendum est"
  ],
  "detect life friend interior": [
    "Detect Life Friend Interior"
  ],
  "pride of tel vos": [
    "Pride of Tel Vos"
  ],
  "<alias=sonsmajorholdcapital> is a fair trade for markarth": [
    "<Alias=SonsMajorHoldCapital> pactio aequa pro Markartia est"
  ],
  "hallowed daedric greatsword": [
    "Consacrata Spatha Daedrica"
  ],
  "scaled armor of major health": [
    "Lorica Squamata Maioris Salutis"
  ],
  "dwarven battleaxe of siphoning": [
    "Dvemeris Bellisecuris Siphonis"
  ],
  "key to bards college": [
    "Clavis ad Collegium Poetarum"
  ],
  "dwarven helmet of eminent conjuring": [
    "Dvemeris Galea Coniurationis Vocantis"
  ],
  "dialoguemarkarthdragonssmelterscene01": [
    "DialogueMarkarthDragonsSmelterScene01"
  ],
  "steel greatsword of ice": [
    "Spatha Adamantea Gelidus"
  ],
  "i know the phrase": [
    "Tesseram scio"
  ],
  "i'll get cicero - you go home": [
    "Ciceronem petam - tu domum revenies"
  ],
  "i was told you would have a weapon for me": [
    "Audivi te armum mihi habere"
  ],
  "i'd like a dagger": [
    "Pugionem volo"
  ],
  "not me": [
    "Non ego"
  ],
  "i don't believe in the divines": [
    "Diis non credo"
  ],
  "report orchendor's death to peryite": [
    "Refer mortem Orchendoris Peryito"
  ],
  "what do you expect to find inside": [
    "Quid te intus invenire censes"
  ],
  "you cannot fast travel while guards are pursuing you": [
    "Non potes celeriter peregrinari dum custodes te persequantur"
  ],
  "ebony dagger of storms": [
    "Pugio Ebeni Tempestatium"
  ],
  "you killed that woman": [
    "Illam feminam necavisti"
  ],
  "db recurringtarget 8 crimefaction": [
    "DB RecurringTarget 8 CrimeFaction"
  ],
  "hairlinefemalenordchild02": [
    "HairLineFemaleNordChild02"
  ],
  "honningbrew basement": [
    "Honningbrevis Cellarium"
  ],
  "dwarven war axe of torpor": [
    "Dvemeris Bellisecuris Torporis"
  ],
  "draught of strength": [
    "Decocta Fortitudinis"
  ],
  "steel warhammer of torpor": [
    "Bellimalleus Adamanteus Torporis"
  ],
  "you cannot place items in a container while pickpocketing": [
    "Res imponere non potes dum fureris"
  ],
  "ebony battleaxe of nullifying": [
    "Bellisecuris Ebeni Nullificandi"
  ],
  "whiterun bannered mare convo system 1": [
    "Whiterun Bannered Mare Convo System 1"
  ],
  "iron greatsword of diminishing": [
    "Spatha Ferri Diminuendi"
  ],
  "klimmek's house": [
    "Klimmekis Domus"
  ],
  "elven war axe of garnering": [
    "Alfus Bellisecuris Horti"
  ],
  "ulfric holds nothing worth trading markarth for": [
    "Vercingetorix nihil pretii aequi Markartiae habet"
  ],
  "orcish boots": [
    "Orci Caligae"
  ],
  "what are your thoughts on cicero and the night mother": [
    "Quid de Cicerone et Matre Noctis censes"
  ],
  "(listen at the door)": [
    "(Ausculta apud ianuam)"
  ],
  "fultheim": [
    "Fultheim"
  ],
  "i'll deliver the frost salts for you": [
    "Salia tibi portabo"
  ],
  "falmer war axe": [
    "Bellisecuris Falmeris"
  ],
  "i think you know it's not as simple as that": [
    "Credo te scire id non tam simplex esse"
  ],
  "glass battleaxe of stunning": [
    "Bellisecuris Vitreus Intermissionis"
  ],
  "sanctified iron war axe": [
    "Sanctificatus Bellisecuris Ferri"
  ],
  "how does it feel to be my prisoner": [
    "Quomodo sentit Captivum meum esse"
  ],
  "leather boots of resist shock": [
    "Corii Caligae Contra Vim Electricam"
  ],
  "chaurus reaper": [
    "Chaurus Saturnarius"
  ],
  "make the potage le magnifique": [
    "Fac Potage le Magnifique"
  ],
  "(optional) speak to vex about goldenglow estate": [
    "(Optio) Adloqere Vex de Villa Aurilucis"
  ],
  "orcish sword of scorching": [
    "Orci Gladius Adurendi"
  ],
  "db08festus_cookbookbranchtopic": [
    "DB08festus_CookbookBranchTopic"
  ],
  "dialoguegenericlockedobject": [
    "DialogueGenericLockedObject"
  ],
  "a priestess of azura sent me": [
    "Flamina Azurae mihi misit"
  ],
  "priestess of azura sent me": [
    "Flamina Azurae mihi misit"
  ],
  "glass bow of freezing": [
    "Arcus Vitreus Gelidus"
  ],
  "ebony helmet": [
    "Ebeni Galea"
  ],
  "not quite yet": [
    "Nondum"
  ],
  "svana far-shield": [
    "Svana Scuti-Procul"
  ],
  "reburrus quintilius": [
    "Reburrus Quintilius"
  ],
  "onmund": [
    "Onmund"
  ],
  "range": [
    "Distantia"
  ],
  "hairfemalenord11": [
    "HairFemaleNord11"
  ],
  "torom": [
    "Torom"
  ],
  "why haven't i met paarthurnax yet": [
    "Quamobrem Paarthurnacem non dum cognovi"
  ],
  "lynly star-sung": [
    "Lynly Stella-Cantata"
  ],
  "firebrand wine case": [
    "Theca Vini Ignifacis"
  ],
  "elven bracers of major deft hands": [
    "Chirothecae Alfae Maioris Dexteritatis"
  ],
  "deadly aversion to frost": [
    "Letifer Aversio Glaciei"
  ],
  "a dragon is attacking kynesgrove": [
    "Draco Kyninemus oppugnat"
  ],
  "dragon is attacking kynesgrove": [
    "Draco Kyninemus oppugnat"
  ],
  "i need to speak to paarthurnax, then": [
    "Opus mihi est Paarthurnacem adloqui"
  ],
  "firestormbodyartholder": [
    "FirestormBodyArtHolder"
  ],
  "steel greatsword of burning": [
    "Spatha Adamantea Cremandi"
  ],
  "scaled helmet of waterbreathing": [
    "Galea Squamata Respirandi Sub Aqua"
  ],
  "talk, or i send you to the gods": [
    "Loquere, vel tibi ad deos mittam"
  ],
  "you'll let me through now": [
    "Nunc me transire sinas"
  ],
  "it was quite a trip": [
    "Tale iter erat"
  ],
  "where are we": [
    "Ubi sumus"
  ],
  "steel plate boots of muffling": [
    "Laminatae Caligae Silentii"
  ],
  "spell tome: wall of frost": [
    "Liber Magicus: Paries Glaciei"
  ],
  "i've earned my coin, i think": [
    "Pecuniam meam merui, ut credo"
  ],
  "you'll come with me, then": [
    "Sic, mecum venies"
  ],
  "ebony dagger of debilitation": [
    "Pugio Ebeni Debilitationis"
  ],
  "bring the supplies to high hrothgar": [
    "Fer Opes ad Altum Hrothgarem"
  ],
  "wraith's wedding dowry": [
    "Dos Phantasmae"
  ],
  "have you considered getting out there and fighting": [
    "Putavistine de bello adiungendo"
  ],
  "what's so important about these clients": [
    "Quid tam magni momenti est de his clientibus"
  ],
  "makes sense": [
    "Sensum habet"
  ],
  "hunting bow of weariness": [
    "Arcus Venandi Maioris Defatigationis"
  ],
  "a dance in fire, v6": [
    "Saltatio in Flammis, VI"
  ],
  "dance in fire, v6": [
    "Saltatio in Flammis, VI"
  ],
  "orcish warhammer of diminishing": [
    "Orci Bellimalleus Diminuendi"
  ],
  "16 accords of madness, v. vi": [
    "XVI Foedera Insanitatis, v. VI"
  ],
  "sylgja": [
    "Sylgja"
  ],
  "steel mace of burning": [
    "Maca Adamantea Cremandi"
  ],
  "iron armor": [
    "Ferrata Lorica"
  ],
  "glass greatsword": [
    "Spatha Vitrea"
  ],
  "elven bracers of major alchemy": [
    "Chirothecae Alfae Maioris Alchemiae"
  ],
  "glories and laments": [
    "Gloriae Maerentiaeque"
  ],
  "orcish helmet of extreme conjuring": [
    "Orci Galea Maiorum Vocantium"
  ],
  "glass helmet of eminent restoration": [
    "Galea Vitrea Eminentis Reficiendi"
  ],
  "scaled bracers of eminent alchemy": [
    "Chirothecae Squamatae Alchemiae Eminentis"
  ],
  "maybe they were right": [
    "Fortasse recte dicunt"
  ],
  "don't destroy the staff": [
    "Noli rudem frange"
  ],
  "missing in action": [
    "Amissus in Proelio"
  ],
  "all i've ever wanted to do is serve this sanctuary": [
    "Omnia quae umquam facere volui est hoc Sanctuarium servire"
  ],
  "hairmaleorc04": [
    "HairMaleOrc04"
  ],
  "imperial light helmet": [
    "Romana Cassis"
  ],
  "word of power learned": [
    "Verbum Potestatis Discitum"
  ],
  "how are you a better jarl than siddgeir": [
    "Quomodo Comes melior quam Siddgeir es"
  ],
  "evoker": [
    "Evoker"
  ],
  "glowing mushrooms": [
    "Boleta Lucifera"
  ],
  "urag's note": [
    "Epistula Uragis"
  ],
  "gharol": [
    "Gharol"
  ],
  "gadnor": [
    "Gadnor"
  ],
  "i won't be able to do that right now": [
    "Id nunc facere non possum"
  ],
  "the gourmet is here to cook, not talk": [
    "Apicius hic est coquendi causa, non loquendi"
  ],
  "gourmet is here to cook, not talk": [
    "Apicius hic est coquendi causa, non loquendi"
  ],
  "let us begin": [
    "Incipiamus"
  ],
  "brinewater grotto key": [
    "Clavis Specus Salsuginaquae"
  ],
  "riverwood patrol": [
    "Riverwood Patrol"
  ],
  "elven boots of waning fire": [
    "Caligae Alfae Insenescentis Ignis"
  ],
  "ebony greatsword of despair": [
    "Spatha Ebeni Desponsionis"
  ],
  "elven greatsword of freezing": [
    "Spatha Alfa Gelidus"
  ],
  "i just want to join the legion": [
    "Modo Legionem adiungere"
  ],
  "consider the crown a gift": [
    "Puta Coronam donum esse"
  ],
  "today is the luckiest day of your life": [
    "Hodie est dies felicissimus vitae tuae"
  ],
  "honed draugr war axe": [
    "Melior Draugr Bellisecuris"
  ],
  "endon": [
    "Endon"
  ],
  "the lock could be weaker than it looks. may i see the key": [
    "Clausura debilior quam videtur fortasse est. Possumne clavem videre"
  ],
  "lock could be weaker than it looks. may i see the key": [
    "Clausura debilior quam videtur fortasse est. Possumne clavem videre"
  ],
  "elven warhammer of consuming": [
    "Alfus Bellimaleus Consumendi"
  ],
  "leather boots of hauling": [
    "Corii Caligae Portandi"
  ],
  "uglarz": [
    "Uglarz"
  ],
  "cracked tusk vault key": [
    "Clavis ad Hypogeum Dentis Fracti"
  ],
  "winterhold used to be full of dark elves": [
    "Hiemiterra plena erat Atralforum"
  ],
  "yes, it's at the far end of the valley from here": [
    "Sic, ad finem vallis est"
  ],
  "ebony dagger of leeching": [
    "Pugio Ebeni Sanguisugae"
  ],
  "\"all hail the stormcloaks, the true sons and daughters of skyrim!\"": [
    "\"Avete omnes Sturmmanteli, filii filiaeque veri Caelifinis!\""
  ],
  "steel plate helmet of major conjuring": [
    "Laminata Galea Maioris Vocandi"
  ],
  "glass battleaxe of garnering": [
    "Bellisecuris Vitreus Horti"
  ],
  "imperial helmet of minor magicka": [
    "Romana Galea Minoris Magicae"
  ],
  "focusing crystal": [
    "Focandi Crystallum"
  ],
  "orcskin02": [
    "OrcSkin02"
  ],
  "fellglow keep key": [
    "Clavis ad Castellum Fellilucis"
  ],
  "abelone": [
    "Abelone"
  ],
  "steel plate helmet of major destruction": [
    "Laminata Galea Maioris Destructionis"
  ],
  "yes, absolutely": [
    "Sic, certe"
  ],
  "okay, this is for the spell. (give 30 gold)": [
    "Ok, hoc pro magica est. (Da 30 auri)"
  ],
  "corpulus vinius": [
    "Corpulus Vinius"
  ],
  "blessed imperial bow": [
    "Sanctificatus Arcus Imperialis"
  ],
  "a truly strong chief would break the curse, not suffer from it": [
    "Priceps fortis maledictum franget, non de eo patiatur"
  ],
  "truly strong chief would break the curse, not suffer from it": [
    "Priceps fortis maledictum franget, non de eo patiatur"
  ],
  "it's your funeral": [
    "Funus tuum est"
  ],
  "retrieve the ebony blade": [
    "Accipe Eboni Ensem"
  ],
  "warpaintyellow02": [
    "WarpaintYellow02"
  ],
  "i need your help trapping a dragon in dragonsreach": [
    "Auxilium tuum quaero ut draconem in Draconicarcere capiam"
  ],
  "elven dagger of binding": [
    "Pugio Alfus Deligandi"
  ],
  "orcish battleaxe of torpor": [
    "Orci Bellisecuris Torporis"
  ],
  "the jarl of whiterun might not think so": [
    "Comes Albacursus id fortasse non putaret"
  ],
  "jarl of whiterun might not think so": [
    "Comes Albacursus id fortasse non putaret"
  ],
  "steel war axe of ice": [
    "Bellisecuris Adamanteus Gelidus"
  ],
  "horgeir's house occupants": [
    "Horgeir's House Occupants"
  ],
  "the way out is clear. good luck": [
    "Egressum clarum est. Bona fortuna tibi exopto"
  ],
  "way out is clear. good luck": [
    "Egressum clarum est. Bona fortuna tibi exopto"
  ],
  "dialoguekarthwastent01enmonshousescene02": [
    "DialogueKarthwastenT01EnmonsHouseScene02"
  ],
  "dragons like mountains, right": [
    "Nonne draconibus montes placent"
  ],
  "steel plate bracers of major smithing": [
    "Chirothecae Laminatae Maioris Faciendi"
  ],
  "the pit": [
    "Arena"
  ],
  "kill haldyn": [
    "Neca Haldyn"
  ],
  "iron war axe of dismay": [
    "Bellisecuris Ferri Timoris"
  ],
  "ebony bow of despair": [
    "Arcus Ebeni Desponsionis"
  ],
  "what has he done": [
    "Quid fecit"
  ],
  "dragonplate shield of fire abatement": [
    "Draconilaminatum Scutum Resistendi Ignis"
  ],
  "i won't condemn an innocent man": [
    "Innocentem condemnare nolo"
  ],
  "glass greatsword of torpor": [
    "Spatha Vitrea Torporis"
  ],
  "(optional) find skorm snow-strider's journal": [
    "(Optio) Inveni Ephemeridem Skormis Nivi-Cursoris"
  ],
  "it was like this when i found it": [
    "Hoc in statu erat cum id invenirem"
  ],
  "dwarven bow of flames": [
    "Dvemeris Arcus Flammarum"
  ],
  "alerttonormal": [
    "AlertToNormal"
  ],
  "maleeyeskhajiit": [
    "MaleEyesKhajiit"
  ],
  "the woodsman's friend": [
    "Amicus Bosciferis"
  ],
  "woodsman's friend": [
    "Amicus Bosciferis"
  ],
  "why won't the emperor send more reinforcements": [
    "Cur Imperator plurima subsidia mittere non vult"
  ],
  "steel warhammer of ice": [
    "Bellimalleus Adamanteus Gelidus"
  ],
  "why are the thalmor allowed to arrest people for worshipping talos": [
    "Cur possunt Visigothi viros pro ad Talos orando comprehendere "
  ],
  "banded iron shield of dwindling shock": [
    "Ferri Adligati Scutum Electricitatis Diminuentis"
  ],
  "what happened to you in mzinchaleft": [
    "Quid tibi in Mzinchaleft accidit"
  ],
  "what happened to your blade, grimsever": [
    "Quid ensi tuo, Grimsever accidit"
  ],
  "tell me about riften's thieves guild": [
    "Dic mihi de Sodalitate Furum Hiati"
  ],
  "reverent elven greatsword": [
    "Reverens Spatha Alfa"
  ],
  "castle dour dungeon key": [
    "Clavis ad Carcerem Castelli Duris"
  ],
  "bear handle": [
    "Ansam Ursae"
  ],
  "bromjunaar sanctuary": [
    "Bromjunaar Fanum"
  ],
  "spell tome: command daedra": [
    "Liber Magicus: Impera Daedrae"
  ],
  "you and your thugs had me arrested in the first place": [
    "Tu et operae me primum comprehendistis"
  ],
  "steel battleaxe of sapping": [
    "Bellisecuris Adamanteus Hauriendi"
  ],
  "honorhall orphanage ignore crime faction": [
    "Honorhall Orphanage Ignore Crime Faction"
  ],
  "activate": [
    "Activa"
  ],
  "fine armguards": [
    "Ornatae Bracchialia"
  ],
  "eidar cheese wedge": [
    "Eidaris Caseus Cuneus"
  ],
  "elven greatsword of fear": [
    "Spatha Alfa Timoris"
  ],
  "bee and barb scene 12": [
    "Bee et Barb Scene 12"
  ],
  "apprentice tongs": [
    "Apprentice Tongs"
  ],
  "daedric bow of debilitation": [
    "Arcus Daedricus Debilitationis"
  ],
  "humanbeard17": [
    "HumanBeard17"
  ],
  "you don't know who you'll marry": [
    "Nescisne quem in matrimoniam ducturum sis"
  ],
  "steel dagger of fear": [
    "Pugio Adamanteus Timoris"
  ],
  "scaled bracers": [
    "Bracchialia Squamata"
  ],
  "i've retrieved the scroll": [
    "Codicem rettuli"
  ],
  "glass battleaxe of expelling": [
    "Bellisecuris Vitreus Expellendi"
  ],
  "iron shield of blocking": [
    "Ferri Scutum Interponendi"
  ],
  "the hilt of mehrunes' razor, as promised": [
    "Capulum Novaculae Croni ut promisi"
  ],
  "hilt of mehrunes' razor, as promised": [
    "Capulum Novaculae Croni ut promisi"
  ],
  "ravage health": [
    "Ravage Health"
  ],
  "scaled helmet": [
    "Galea Squamata"
  ],
  "dwarves built this city": [
    "Dvemeres hanc urbem fecÃªrunt"
  ],
  "\"bent a few folks' arms the wrong way\"": [
    "\"Bracchia hominum in locos insolitos tetendisti\""
  ],
  "dwarven greatsword of absorption": [
    "Dvemeris Spatha Absorbendi"
  ],
  "so does that mean you'll leave? or not": [
    "Ergo exibis, vel non"
  ],
  "dragonplate boots of frost suppression": [
    "Draconilaminata Caligae Contra Glaciem"
  ],
  "staff of soul trapping": [
    "Rudis Aminicapti"
  ],
  "what's the markarth incident": [
    "Quid est Incidentia Markartiae"
  ],
  "orcish greatsword of winnowing": [
    "Orci Spatha Ventagii"
  ],
  "sanctified imperial sword": [
    "Sanctificatus Gladius Imperialis"
  ],
  "hide shield of resist frost": [
    "Scutum Cutis Contra Vim Gelidam"
  ],
  "and you're afraid your friends will find out": [
    "Et times ne amicos quoque discant"
  ],
  "tell me about dragon bridge": [
    "Narra mihi de Draconiponte"
  ],
  "key to sorli's house": [
    "Clavis ad Domum Sorli"
  ],
  "why did you open this museum": [
    "Quando hoc Mouseion coepisti"
  ],
  "flaming familiar": [
    "Familiarem Ardentem"
  ],
  "broken shackle": [
    "Catena Fracta"
  ],
  "the midden dark": [
    "Sterculinium Obscurum"
  ],
  "midden dark": [
    "Sterculinium Obscurum"
  ],
  "imperial bracers of major destruction": [
    "Chirothecae Romanae Maioris Destructionis"
  ],
  "fellow prisoner": [
    "Concaptivus"
  ],
  "da03vileoffer0": [
    "DA03VileOffer0"
  ],
  "humanbeardlong08_1bit": [
    "HumanBeardLong08_1bit"
  ],
  "scholar": [
    "Scholar"
  ],
  "colette marence services": [
    "Colette Marence Services"
  ],
  "dwarven armor of eminent health": [
    "Dvemeris Lorica Eminentis Salutis"
  ],
  "imperial field legate": [
    "Imperialis Legatus Agri"
  ],
  "dwarven helmet of alteration": [
    "Dvemeris Galea Mutationis"
  ],
  "spider bite": [
    "Spider Bite"
  ],
  "target location": [
    "Locus Actoris"
  ],
  "hairlinefemalenord04": [
    "HairLineFemaleNord04"
  ],
  "ra'kheran": [
    "Ra'kheran"
  ],
  "ice and chitin": [
    "Glacies et Chitin"
  ],
  "ebony gauntlets of major wielding": [
    "Ebeni Manicae Maioris Tenendi"
  ],
  "daedric boots of brawn": [
    "Caligae Daedricae Fortitudinis"
  ],
  "ebony shield of frost suppression": [
    "Ebeni Scutum Contra Glaciem"
  ],
  "no items to repair": [
    "No items to repair"
  ],
  "thank you, lord dagon": [
    "Gratias, Domine Dagon"
  ],
  "dwarven mechanism": [
    "Dvemere Mechanismum"
  ],
  "where did they take her": [
    "Quo eam tulerunt"
  ],
  "stormcloak helmet": [
    "Sturmmantelorum Galea"
  ],
  "dialoguedawnstarinnfridathoringscene02": [
    "DialogueDawnstarInnFridaThoringScene02"
  ],
  "db03muiricontractdetails2a": [
    "DB03MuiriContractDetails2a"
  ],
  "weakness to poison": [
    "Debilitas Veneno"
  ],
  "surefoot": [
    "Surefoot"
  ],
  "you can't quicksave while the game is paused": [
    "Celeritueri non potes dum pauses"
  ],
  "what happened in the last few hundred years": [
    "Quid haec saecula recentissima accidit"
  ],
  "dark souls revenant": [
    "Dark Souls Revenant"
  ],
  "calder": [
    "Calder"
  ],
  "bottar": [
    "Bottar"
  ],
  "rhiada's wardrobe": [
    "Armarium Rhiadae"
  ],
  "repair all (%d)": [
    "Repair All (%d)"
  ],
  "vignar gray-mane": [
    "Vignar Juba-Cana"
  ],
  "browntintlight": [
    "BrownTintLight"
  ],
  "dwarven bow of cowardice": [
    "Dvemeris Arcus Timoris"
  ],
  "orcish helmet": [
    "Orci Galea"
  ],
  "ebony dagger of evoking": [
    "Pugio Ebeni Evocandi"
  ],
  "leather boots of strength": [
    "Corii Caligae Fortitudinis"
  ],
  "studded armor of major health": [
    "Ornata Lorica Maioris Salutis"
  ],
  "contract: kill anoriath": [
    "Munus: Neca Anoriath"
  ],
  "merchant's festival": [
    "Mercuralia"
  ],
  "ebony gauntlets of eminent alchemy": [
    "Ebeni Manicae Alchemiae Eminentis"
  ],
  "kust": [
    "Kust"
  ],
  "vehlek sain, i release you": [
    "Vehlek Sain, te libero"
  ],
  "we'll require... significant compensation": [
    "Adquiremus... recompensum magni momenti"
  ],
  "can you pay the price": [
    "Pretiumne solvere potes"
  ],
  "about paarthurnax": [
    "De Paarthurnax"
  ],
  "femaleheadorc": [
    "FemaleHeadOrc"
  ],
  "imperial sword of cold": [
    "Gladius Romanus Frigidis"
  ],
  "deer pelt": [
    "Cervi Reno"
  ],
  "daedric war axe of immobilizing": [
    "Bellisecuris Daedricus Immobilitatis"
  ],
  "dwarven helmet of waterbreathing": [
    "Dvemeris Galea Respirandi Sub Aqua"
  ],
  "hallowed orcish war axe": [
    "Consacratus Bellisecuris Orcorum"
  ],
  "imperial bow of fatigue": [
    "Imperialis Arcus Defatigationis"
  ],
  "dragonscale shield": [
    "Draconisquamatum Scutum"
  ],
  "skooma dealer": [
    "Skooma Dealer"
  ],
  "fire storm 65": [
    "Fire Storm 65"
  ],
  "uncommon taste - signed": [
    "De Re Coquinaria- Subscripta"
  ],
  "dwarven war axe of consuming": [
    "Dvemeris Bellisecuris Consumendi"
  ],
  "iron mace of scorching": [
    "Ferri Maca Adurendi"
  ],
  "werewolf cage key": [
    "Clavis ad Carcerem Versipellis"
  ],
  "reverent steel mace": [
    "Reverens Maca Adamantea"
  ],
  "fx visuals for summoned hero": [
    "FX Visuals for Summoned Hero"
  ],
  "you cannot unequip this item": [
    "Hanc rem condere non potes"
  ],
  "riften wylandriah vendor services": [
    "Hiati Wylandriah Vendor Services"
  ],
  "elven battleaxe of banishing": [
    "Bellisecuris Alfus Expellendi"
  ],
  "sabine's footlocker key": [
    "Clavis ad Arcam ad Pedes Sabinis"
  ],
  "daedric mace of dread": [
    "Maca Daedrica Timoris"
  ],
  "dragonplate shield of eminent blocking": [
    "Draconilaminatum Scutum Eminentis Interponendi"
  ],
  "leontius salvius": [
    "Leontius Salvius"
  ],
  "whiterun amren's house faction": [
    "Whiterun Amrenis House Faction"
  ],
  "no saved games": [
    "Nulli Ludi Tuti"
  ],
  "daedric dagger of the vampire": [
    "Pugio Daedricus Vampiri"
  ],
  "laugh lines": [
    "Lineae Ridendi"
  ],
  "what will killing gaius maro accomplish": [
    "Cui bono est mors Gaii Maronis"
  ],
  "you are already at full health": [
    "Iam saltuem plenam habes"
  ],
  "letter to salma": [
    "Epistula Salmae"
  ],
  "elven war axe of thunderbolts": [
    "Alfus Bellisecuris Fulminum"
  ],
  "my soul is heavy with guilt": [
    "Animus meus culpabilitati ponderosus est"
  ],
  "light foot": [
    "Levipes"
  ],
  "tell me more about the coven": [
    "Dic mihi plus de grege sagarum"
  ],
  "stormcloak archer": [
    "Sturmmantelorum Sagittarius"
  ],
  "return the scroll to anska": [
    "Redde Codicem Anskae"
  ],
  "dwarven greatsword of consuming": [
    "Dvemeris Spatha Consumendi"
  ],
  "daedric dagger of depleting": [
    "Pugio Daedricus Deplendi"
  ],
  "do you": [
    "Tu"
  ],
  "jazbay grapes": [
    "Uvae Jazbae"
  ],
  "dwarven bracers of major smithing": [
    "Dvemeres Chirothecae Maioris Faciendi"
  ],
  "wylandriah": [
    "Vylandria"
  ],
  "ebony greatsword of animus": [
    "Spatha Ebeni Animi"
  ],
  "stamina poison": [
    "Venenum Staminae"
  ],
  "it's a possibility we can't ignore": [
    "Possibilitatem ignorare non possumus"
  ],
  "yes, an abandoned sanctuary in dawnstar": [
    "Sic, Fanum Relictum Luciferis"
  ],
  "i have the passphrase": [
    "Tesseram habeo"
  ],
  "sanctified steel dagger": [
    "Sanctificatus Pugio Adamanteus"
  ],
  "glass greatsword of ice": [
    "Spatha Vitrea Gelidus"
  ],
  "you've been caught pickpocketing": [
    "Inventus es dum furareris"
  ],
  "elven greatsword of garnering": [
    "Spatha Alfa Horti"
  ],
  "rustleif": [
    "Rustleif"
  ],
  "elven bow of diminishing": [
    "Arcus Alfus Diminuendi"
  ],
  "why did falion leave the college": [
    "Cur Falion e Collegio discessit"
  ],
  "ebony dagger of exhaustion": [
    "Pugio Ebeni Exhaustionis"
  ],
  "glass warhammer of immobilizing": [
    "Bellimaleus Vitreus Immobilitatis"
  ],
  "wabba visuals": [
    "Wabba Visuals"
  ],
  "it's not about nocturnal": [
    "Flocci non facio Nocturnalis"
  ],
  "this is personal": [
    "De te agitur"
  ],
  "steel war axe of sapping": [
    "Bellisecuris Adamanteus Hauriendi"
  ],
  "imperial wizard": [
    "Imperialis Thaumatorgos"
  ],
  "iron greatsword of fatigue": [
    "Spatha Ferri Defatigationis"
  ],
  "elven bracers of eminent deft hands": [
    "Chirothecae Alfae Eminentis Dexteritatis"
  ],
  "draugr scourge lord": [
    "Draugr Dominus Flagrorum"
  ],
  "do you like having haming around": [
    "Tibine placet Haming prope te habere"
  ],
  "falmer gloomlurker": [
    "Falmeris Insidiator"
  ],
  "stromm's diary": [
    "Ephemeris Strommis"
  ],
  "sild's assistant": [
    "Advocata Sildis"
  ],
  "orcish greatsword of fear": [
    "Orci Spatha Timoris"
  ],
  "fort greenwall prison": [
    "Carcer Castelli Viridivallis"
  ],
  "emblem iii": [
    "Emblema III"
  ],
  "hunting bow of torpor": [
    "Arcus Venandi Torporis"
  ],
  "did i say anything about where i left a staff": [
    "Dixine aliquid de loco in quo rudis reliqui"
  ],
  "copper and sapphire circlet": [
    "Cupri et Sapphiri Circulum"
  ],
  "hide boots of dwindling frost": [
    "Renonis Caligae Minoris Congelationis"
  ],
  "the black arts on trial": [
    "Artes Atrae Sub Iudicio"
  ],
  "black arts on trial": [
    "Artes Atrae Sub Iudicio"
  ],
  "how do you know so much about them": [
    "Quomodo tantum de eis scis"
  ],
  "iron greatsword of shocks": [
    "Spatha Ferri Electricitatis"
  ],
  "steel greatsword of scorching": [
    "Spatha Adamantea Adurendi"
  ],
  "hunting bow of fear": [
    "Arcus Venandi Timoris"
  ],
  "key to justiciar's headquarter": [
    "Clavis ad Principia Justiciaris"
  ],
  "sabjorn's dresser": [
    "Armarium Sabjornis"
  ],
  "hide helmet of minor restoration": [
    "Cutis Galea Minoris Reficiendi"
  ],
  "alva's house": [
    "Domus Alvae"
  ],
  "she said goodbye to alva, but not to her own husband": [
    "Valedixit Alvae, nec marito suo"
  ],
  "i'm ready for my first assignment": [
    "Paratus sum muneri primo"
  ],
  "fall back and defend the barricade": [
    "Defende Concaedem"
  ],
  "kesh the clean": [
    "Kesh Purus"
  ],
  "i'm not a murderer": [
    "Non sum necator"
  ],
  "hairfemaleorc02": [
    "HairFemaleOrc02"
  ],
  "irkngthand sanctuary": [
    "Irkngthand Fanum"
  ],
  "priestess of boethiah": [
    "Flamina Boethis"
  ],
  "you must close your current game to change resolutions": [
    "You must close your current game to change resolutions"
  ],
  "dwarven war axe of fear": [
    "Dvemeris Bellisecuris Timoris"
  ],
  "scroll of invisibility": [
    "Codex Invisibilitatis"
  ],
  "song of the alchemists": [
    "Carmen Alchemistarum"
  ],
  "maleheadchild": [
    "MaleHeadChild"
  ],
  "why do you want her to stay so badly": [
    "Cur eam adeo manere vis"
  ],
  "report cicero to the guard": [
    "Refer Ciceronem ad custodes"
  ],
  "sun's dusk": [
    "November"
  ],
  "iron warhammer of arcing": [
    "Bellimalleus Ferratus Electricitatis"
  ],
  "ebony battleaxe of winter": [
    "Bellisecuris Ebeni Hiemis"
  ],
  "kyr's bounty": [
    "Largitas Kyris"
  ],
  "iron warhammer of diminishing": [
    "Bellimalleus Ferratus Diminuendi"
  ],
  "ebony battleaxe of debilitation": [
    "Bellisecuris Ebeni Debilitationis"
  ],
  "draught of vigor": [
    "Decocta Vigoris"
  ],
  "elven sword of debilitation": [
    "Alfus Gladius Debilitationis"
  ],
  "ebony dagger of damnation": [
    "Pugio Ebeni Damnationis"
  ],
  "what is my destiny": [
    "Quid est fatum meum"
  ],
  "tiber": [
    "Tiber"
  ],
  "hide boots of resist shock": [
    "Renonis Caligae Contra Vim Electricam"
  ],
  "spell tome: lesser ward": [
    "Liber Magicus: Praesidium Minus"
  ],
  "pickaxe": [
    "Dolabrum"
  ],
  "ebony shield of shock suppression": [
    "Ebeni Scutum Suppresionis Electricitatis"
  ],
  "tandil": [
    "Tandil"
  ],
  "it's connected to the orb we found": [
    "Nexum cum globo quem invenimus habet"
  ],
  "glass battleaxe of lethargy": [
    "Bellisecuris Vitreus Lethargiae"
  ],
  "ready to check out the house": [
    "Esne paratus domum investigare"
  ],
  "hide shield of dwindling frost": [
    "Scutum Cutis Minoris Congelationis"
  ],
  "you lack the required perk to pick this lock": [
    "You lack required perk to pick this lock"
  ],
  "elven war axe of lightning": [
    "Alfus Bellisecuris Fulminis"
  ],
  "daedric warhammer of lethargy": [
    "Bellimalleus Daedricus Lethargiae"
  ],
  "sabjorn seems like more than just friendly competition": [
    "Sabjorn plus quam certamen amicosum mihi videtur"
  ],
  "linen wrap": [
    "Emporeticus Lintei"
  ],
  "humanbeard22": [
    "HumanBeard22"
  ],
  "glass battleaxe of diminishing": [
    "Bellisecuris Vitreus Diminuendi"
  ],
  "spellbreaker": [
    "Reflector"
  ],
  "hollyfrost farmhouse key": [
    "Clavis ad Villam Rusticam Crataegonis Frigidi"
  ],
  "then pay up and i'll forget about it": [
    "Ergo pecuniam solve et eius obliviscar"
  ],
  "shahvee's amulet of zenithar": [
    "Monile Zenitharis Shahveis"
  ],
  "azura's star": [
    "Stella Azurae"
  ],
  "who is the daedric lord vaermina": [
    "Quis est princeps daedricus Morpheus"
  ],
  "steamscorch gully mine": [
    "Steamscorch Gully Mine"
  ],
  "daedric mace of garnering": [
    "Maca Daedrica Horti"
  ],
  "it's done. paarthurnax is dead": [
    "Factum est. Paarthurnax mortuus est"
  ],
  "sanctified iron dagger": [
    "Sanctificatus Pugio Ferri"
  ],
  "who are the shatter-shields": [
    "Qui sunt Scuta-Fractae"
  ],
  "iron greatsword of soul snares": [
    "Spatha Ferri Animicapti"
  ],
  "humanskinbasewhite01": [
    "HumanSkinBaseWhite01"
  ],
  "imperial helmet of minor conjuration": [
    "Romana Galea Minoris Coniurationis"
  ],
  "ebony shield of eminent blocking": [
    "Ebeni Scutum Eminentis Interponendi"
  ],
  "northwatch archer": [
    "Septentriovisus Sagittarius"
  ],
  "dragonplate boots of grounding": [
    "Draconilaminata Caligae Minoris Electricitatis"
  ],
  "key to the windpeak inn": [
    "Clavis ad Diversorium Ventapicis"
  ],
  "sneak trainer": [
    "Sneak Trainer"
  ],
  "sanctified iron sword": [
    "Sanctificatus Gladius Ferri"
  ],
  "ghorbash's ancestral axe": [
    "Securis Maiorum Ghorbasis"
  ],
  "steel warhammer of sapping": [
    "Bellimalleus Adamanteus Hauriendi"
  ],
  "why did you need a sacrifice": [
    "Cur sacrificio cares"
  ],
  "daedric shield of warmth": [
    "Scutum Daedricum Caloris"
  ],
  "badnir": [
    "Badnir"
  ],
  "orcskin01": [
    "OrcSkin01"
  ],
  "vdsg plate": [
    "VDSG Plate"
  ],
  "guard tower": [
    "Custodium Turris"
  ],
  "dismay - maar": [
    "Dismay - Maar"
  ],
  "hide helmet of major illusion": [
    "Cutis Galea Maioris Inlusionis"
  ],
  "playercastprojectilespell": [
    "PlayerCastProjectileSpell"
  ],
  "darkshade": [
    "Umbrobscura"
  ],
  "spell tome: steadfast ward": [
    "Liber Magicus: Praesidium Sempiterum"
  ],
  "virtuous daedric bow": [
    "Virtutis Arcus Daedricus"
  ],
  "reloading the most recent save game": [
    "Reaperiens ludum tutum recentissimum"
  ],
  "dragonplate helmet of eminent alteration": [
    "Draconilaminata Galea Eminentis Mutationis"
  ],
  "emperor's quarters": [
    "Stativa Imperatoris"
  ],
  "humanbeard39": [
    "HumanBeard39"
  ],
  "heddic volunnar": [
    "Heddic Volunnar"
  ],
  "dwarven dagger of flames": [
    "Dvemeris Pugio Flammarum"
  ],
  "deekus": [
    "Deekus"
  ],
  "gonnar oath-giver": [
    "Gonnar Ius-Dator"
  ],
  "how does any of this help me": [
    "Quomodo haec me adiuvant"
  ],
  "legate sevan telendas": [
    "Legatus Sevanus Telendas"
  ],
  "is unconscious": [
    "exanimatus est"
  ],
  "honed ancient nord battle axe": [
    "Melior Germanicus Antiquus Bellisecuris"
  ],
  "marksmalehumanoid05leftgash": [
    "MarksMaleHumanoid05LeftGash"
  ],
  "trespass": [
    "Intrare contra legem"
  ],
  "browsfemaleargonian02": [
    "BrowsFemaleArgonian02"
  ],
  "orcish battleaxe of frost": [
    "Orci Bellisecuris Gelidus"
  ],
  "you should probably stick to farming": [
    "Agricolam remanere debes"
  ],
  "alethius": [
    "Alethius"
  ],
  "revyn sadri services": [
    "Revyn Sadri Services"
  ],
  "dunarcherqst_noticetargetstopic03a": [
    "dunArcherQST_NoticeTargetsTopic03a"
  ],
  "noticecorpse": [
    "NoticeCorpse"
  ],
  "iron dagger of scorching": [
    "Pugio Ferri Adurendi"
  ],
  "dark brotherhood faction": [
    "Dark Brotherhood Faction"
  ],
  "hairmalenord04": [
    "HairMaleNord04"
  ],
  "ebony greatsword of annihilating": [
    "Spatha Ebeni Annihilationis"
  ],
  "kill paarthurnax": [
    "Neca Paarthurnacem"
  ],
  "you needed a soul gem filled": [
    "Gemmanima plena eges"
  ],
  "scouts-many-marshes": [
    "Explorat-Multas-Paludes"
  ],
  "dwarven mace of draining": [
    "Dvemeris Maca Hauriendi"
  ],
  "thalmor robes": [
    "Visigothum Peplum"
  ],
  "conjurer's draught": [
    "Vocatoris Decocta"
  ],
  "daedric gauntlets of peerless wielding": [
    "Daedricae Manicae Optimae Tenendi"
  ],
  "windhelm stables key": [
    "Clavis ad Catabulum Ventigaleae"
  ],
  "retrieve your thieves guild armor from tonilia": [
    "Accipe Loricam Sodalitatis Furum e Tonilia"
  ],
  "why did vile kick you out": [
    "Quamobrem Vile te expulsit"
  ],
  "current objective": [
    "OBIECTIVUM QUOD NUNC SEQUERIS"
  ],
  "dwarven dagger of weariness": [
    "Dvemeris Pugio Maioris Defatigationis"
  ],
  "small sack": [
    "Cibisis Parva"
  ],
  "hunting bow of soul snares": [
    "Arcus Venandi Animicapti"
  ],
  "ebony sword of the blaze": [
    "Gladius Ebeni Congflagrantis"
  ],
  "key to blue palace": [
    "Clavis ad Aulam Caeruleam"
  ],
  "find out where jaree-ra's bandits took the loot": [
    "Inveni ubi Latrones Jaree-Rae Fazam PosuÃªrunt"
  ],
  "aeri's note": [
    "Epistula Aeri"
  ],
  "attributes: %d/%d points assigned": [
    "Vitalitas: %d/%d Punctorum Electorum"
  ],
  "wolf handle": [
    "Ansam Lupi"
  ],
  "leather helmet of minor alchemy": [
    "Cutis Galea Minoris Alchemiae"
  ],
  "imperial sword of burning": [
    "Gladius Romanus Cremandi"
  ],
  "lund": [
    "Lund"
  ],
  "iron greatsword of scorching": [
    "Spatha Ferri Adurendi"
  ],
  "elven mace of dread": [
    "Maca Alfa Timoris"
  ],
  "daedric warhammer of winter": [
    "Bellimalleus Daedricus Hiemis"
  ],
  "ebony warhammer of malediction": [
    "Bellimalleus Ebeni Maledictionis"
  ],
  "hairmaleredguard1": [
    "HairMaleRedguard1"
  ],
  "is there anything else you need, my jarl": [
    "Estne quid aliud quod vis, mi Comes"
  ],
  "aedra and daedra": [
    "Aedra et Daedra"
  ],
  "what about my pay": [
    "Quid de pecunia mea"
  ],
  "glass warhammer": [
    "Bellimaleus Vitreus"
  ],
  "dengeir of stuhn": [
    "Dengeir Stuhnis"
  ],
  "dwarven battleaxe of gleaning": [
    "Dvemeris Bellisecuris Saturni"
  ],
  "let me untie these binds": [
    "Sine me haec vincula solvere"
  ],
  "gold emerald ring": [
    "Anulus Aureus Smaragdi"
  ],
  "false back panel": [
    "Lacuna Falsa"
  ],
  "travel to broken oar grotto": [
    "Ii ad Specus Remi Fracti"
  ],
  "from-deepest-fathoms": [
    "Ab-Imis"
  ],
  "let me in or i'll kill you": [
    "Sine me intrare vel te necabo"
  ],
  "glass warhammer of scorching": [
    "Bellimaleus Vitreus Adurendi"
  ],
  "dwarven dagger of animus": [
    "Dvemeris Pugio Animi"
  ],
  "sealed letter": [
    "Epistula Clausa"
  ],
  "do you need someone to check out wolfskull cave": [
    "Visne aliquid qui Speluncam Calvarialupi investigare potest"
  ],
  "garlic": [
    "Allium"
  ],
  "item": [
    "Res"
  ],
  "ice bite": [
    "Ice Bite"
  ],
  "dwarven sword of animus": [
    "Gladius Dvemeris Animi"
  ],
  "how could he have opened the vault door alone": [
    "Quomodo potuit ianuam Hypogei solus aperire"
  ],
  "hairlinemaleorc21": [
    "HairLineMaleOrc21"
  ],
  "hod": [
    "Hod"
  ],
  "light damage": [
    "Vulnus Lucis"
  ],
  "ebony shield of dwindling fire": [
    "Ebeni Scutum Minoris Ignis"
  ],
  "imperial light helmet of major alteration": [
    "Romana Cassis Maioris Mutationis"
  ],
  "synda llanith": [
    "Synda Llanith"
  ],
  "hairlinemaleredguard3": [
    "HairLineMaleRedguard3"
  ],
  "the greybeards won't let the empire take advantage of you": [
    "Canabarbae Imperium te decipere non sinant"
  ],
  "greybeards won't let the empire take advantage of you": [
    "Canabarbae Imperium te decipere non sinant"
  ],
  "steel warhammer of embers": [
    "Bellimalleus Adamanteus Cinerum"
  ],
  "elvali veren": [
    "Elvali Veren"
  ],
  "ebony dagger of expelling": [
    "Pugio Ebeni Expellendi"
  ],
  "jordis the sword-maiden": [
    "Jordis Gladii-Virgo"
  ],
  "you're the one who took the horn": [
    "Cornu furatus es"
  ],
  "freed - getting gear": [
    "Freed - getting gear"
  ],
  "margret was investigating thonar": [
    "Margret Thonarem investigavit"
  ],
  "glass boots of shock suppression": [
    "Vitreae Caligae Suppresionis Electricitatis"
  ],
  "dwarven bracers of wielding": [
    "Dvemeres Chirothecae Tenendi"
  ],
  "glass dagger of torpor": [
    "Pugio Vitreus Torporis"
  ],
  "elven war axe of immobilizing": [
    "Alfus Bellisecuris Immobilitatis"
  ],
  "ivarstead klimmek's house": [
    "Ivarstead Klimmekis House"
  ],
  "speak to thorald gray-mane": [
    "Thorald Juba-Canam Adloquere"
  ],
  "no, it's not right": [
    "Minime, nefas est"
  ],
  "ebony ore vein": [
    "Venam Metallis Ebeni"
  ],
  "is there any college business i can help with": [
    "Estne negotium Collegii cum quo adiuvare possum"
  ],
  "delvin mallory": [
    "Delphinus Mallorus"
  ],
  "target": [
    "Intentio"
  ],
  "ramati": [
    "Ramati"
  ],
  "inge six fingers": [
    "Inge Sex Digiti"
  ],
  "i need to talk to you about helgen": [
    "De Helgen tibi narrare debeo"
  ],
  "staff of the healing hand": [
    "Rudis Manus Salutis"
  ],
  "tell me about alduin": [
    "Dic mihi de Alduino"
  ],
  "critical strike on": [
    "Ictus Criticus in"
  ],
  "mercer's vault lock": [
    "Clausum Hypogei Merceris"
  ],
  "captain's chest": [
    "Arca Navarchi"
  ],
  "are we ready": [
    "Paratine sumus"
  ],
  "orcish dagger of draining": [
    "Orci Pugio Hauriendi"
  ],
  "skeletal dragon": [
    "Draco Osseus"
  ],
  "clear skies effect 1": [
    "Clear Skies Effect 1"
  ],
  "hairlinefemaledarkelf03": [
    "HairLineFemaleDarkElf03"
  ],
  "elven battleaxe of freezing": [
    "Bellisecuris Alfus Gelidus"
  ],
  "orcish mace of animus": [
    "Orci Maca Animi"
  ],
  "hud color": [
    "HUD Color"
  ],
  "glass greatsword of winnowing": [
    "Spatha Vitrea Ventagii"
  ],
  "scaled boots of dwindling frost": [
    "Caligae Squamatae Minoris Congelationis"
  ],
  "all right, you've swayed me": [
    "Bene, mihi persuasi"
  ],
  "so what can i do": [
    "Quid nunc faciam"
  ],
  "asta": [
    "Asta"
  ],
  "hallowed elven sword": [
    "Consacratus Gladius Alfus"
  ],
  "virtuous elven sword": [
    "Virtutis Gladius Alfus"
  ],
  "dwarven bow of animus": [
    "Dvemeris Arcus Animi"
  ],
  "you must have your hands full with the change in government": [
    "Certe occupatus es quod res publica mutavit"
  ],
  "iron sword of frost": [
    "Gladius Ferri Gelidus"
  ],
  "orcish mace of binding": [
    "Orci Maca Deligandi"
  ],
  "peddler": [
    "Peddler"
  ],
  "iron mace of burning": [
    "Ferri Maca Cremandi"
  ],
  "ebony bow of animus": [
    "Arcus Ebeni Animi"
  ],
  "you cannot wait in the air": [
    "Expectare non potes dum in aere"
  ],
  "glass battleaxe": [
    "Bellisecuris Vitreus"
  ],
  "feyfolken ii": [
    "Feyfolken II"
  ],
  "canopic jar": [
    "Gata Canopica"
  ],
  "ancient nord greatsword of cold": [
    "Spatha Antiqua Germanica Frigidis"
  ],
  "paratus decimius": [
    "Paratus Decimius"
  ],
  "elven mace of despair": [
    "Maca Alfa Desponsionis"
  ],
  "honed falmer sword": [
    "Falmeris Gladius Melior"
  ],
  "phantom sword": [
    "Gladius Imaginis"
  ],
  "nightingale bow": [
    "Arcus Philomelae"
  ],
  "daedric mace of the inferno": [
    "Maca Daedrica Conflagrationis"
  ],
  "hairmaleorc24": [
    "HairMaleOrc24"
  ],
  "orcish greatsword of burning": [
    "Orci Spatha Cremandi"
  ],
  "elven greatsword of scorching": [
    "Spatha Alfa Adurendi"
  ],
  "sanctified dwarven dagger": [
    "Sanctificatus Dvemeris Pugio"
  ],
  "dwarven sword of shocks": [
    "Gladius Dvemeris Electricitatis"
  ],
  "was equipped": [
    "imponitur"
  ],
  "iron dagger of shocks": [
    "Pugio Ferri Electricitatis"
  ],
  "glass warhammer of lethargy": [
    "Bellimaleus Vitreus Lethargiae"
  ],
  "what did you need help with": [
    "Cum quo auxilium petivisti"
  ],
  "iron greatsword of binding": [
    "Spatha Ferri Deligandi"
  ],
  "elven greatsword of lightning": [
    "Spatha Alfa Fulminis"
  ],
  "icerunner chest": [
    "Arca Cursoris Glaciei"
  ],
  "enter labyrinthian": [
    "Intra Labyrinthum"
  ],
  "hallowed dwarven sword": [
    "Consacratus Gladius Dvemeris"
  ],
  "blessed iron warhammer": [
    "Sanctificatus Bellimalleus Ferratus"
  ],
  "final lesson": [
    "Schola Ultima"
  ],
  "cheekbone height": [
    "Altitudo Genarum"
  ],
  "iron warhammer of torpor": [
    "Bellimalleus Ferratus Torporis"
  ],
  "nord hero": [
    "Nord Hero"
  ],
  "jaw width": [
    "Mandibuli Latitudo"
  ],
  "what can you tell me about this": [
    "Quid mihi dicere potes de hoc"
  ],
  "(give delvin the amulet)": [
    "(Da monile Delphino)"
  ],
  "orcish mace of burning": [
    "Orci Maca Cremandi"
  ],
  "steel battleaxe of fear": [
    "Bellisecuris Adamanteus Timoris"
  ],
  "heavy armor absorbs more damage, but it also weighs more than light armor": [
    "Lorica Gravis plus vulneris vetat, sed quoque plus ponderis habet quam Lorica Levis"
  ],
  "dwarven bracers of eminent wielding": [
    "Dvemeres Chirothecae Eminentis Tenendi"
  ],
  "let's see about that": [
    "De eo videbimus"
  ],
  "steel greatsword of soul snares": [
    "Spatha Adamantea Animicapti"
  ],
  "daedric warhammer of evoking": [
    "Bellimalleus Daedricus Evocandi"
  ],
  "draugr abilities": [
    "Draugr Abilities"
  ],
  "orcish gauntlets of extreme archery": [
    "Orci Manicae Extremis Saggitationis"
  ],
  "most of winterhold was lost to the sea in the great collapse of 4e 122, but the college was left mysteriously unharmed": [
    "Maioritas Hiemiterrae in mare cecidit in Lapso Magno Aetatis Quartae CXXII, sed Collegium, mirabile dictu, incolume erat"
  ],
  "steel sword of draining": [
    "Gladius Adamanteus Hauriendi"
  ],
  "daedric mace of winter": [
    "Maca Daedrica Hiemis"
  ],
  "apprentice alembic": [
    "Apprentice Alembic"
  ],
  "i dislike the daedra looking into my future": [
    "In odio habeo daedrae qui futurum meum spectant"
  ],
  "moon amulet": [
    "Lunae Monile"
  ],
  "orcish greatsword of reaping": [
    "Orci Spatha Saturni"
  ],
  "no one has said anything about you": [
    "Nemo quidquid de te umquam dixit"
  ],
  "jorleif": [
    "Jorleif"
  ],
  "key to arcadia's cauldron": [
    "Clavis ad Ahenum Arcadiae"
  ],
  "what can be done about it now": [
    "Quid remedium ei nunc est"
  ],
  "hide bracers of minor alchemy": [
    "Chirothecae Cutis Minoris Alchemiae"
  ],
  "imperial helmet of alteration": [
    "Romana Galea Mutationis"
  ],
  "iron war axe": [
    "Bellisecuris Ferri"
  ],
  "kill the master vampire": [
    "Neca Dominum Vampirum"
  ],
  "you cannot sleep in an owned bed": [
    "In lectum emptum dormire non potes"
  ],
  "why does the empire allow the thalmor free reign": [
    "Cur Imperium Visigothos tantas res facere sinet"
  ],
  "elven battleaxe of winnowing": [
    "Bellisecuris Alfus Ventagii"
  ],
  "orcish battleaxe of reaping": [
    "Orci Bellisecuris Saturni"
  ],
  "what was going on with that spider": [
    "Quid cum illa aranea agitur"
  ],
  "what are you arguing about": [
    "De qua re arguisti"
  ],
  "defaultdisableselfonactivate": [
    "defaultDisableSelfOnActivate"
  ],
  "stealfromnc": [
    "StealFromNC"
  ],
  "orcish greatsword of siphoning": [
    "Orci Spatha Siphonis"
  ],
  "hide bracers of archery": [
    "Chirothecae Cutis Saggitationis"
  ],
  "ebony greatsword of dread": [
    "Spatha Ebeni Timoris"
  ],
  "i think i'll make my own way from here": [
    "Credo me solum ab hoc loco iturum esse"
  ],
  "hjorunn": [
    "Hjorunn"
  ],
  "skills improve with use: weapon skills improve when you hit opponents": [
    "Artes augentur dum eis uteris: Artes armorum ictibus in inimicos augentur"
  ],
  "the sneak skill improves when you successfully sneak": [
    "Ars rependi augetur cum clam moves"
  ],
  "sneak skill improves when you successfully sneak": [
    "Ars rependi augetur cum clam moves"
  ],
  "spell skills improve when you cast spells": [
    "Artes magicae augentur cum magica utaris"
  ],
  "orcish mace of despair": [
    "Orci Maca Desponsionis"
  ],
  "legate skulnar": [
    "Legatus Skulnaris"
  ],
  "melka": [
    "Melka"
  ],
  "bring the dibella statue to lisbet": [
    "Fer Statuam Dibellae Lisbeti"
  ],
  "key to jorgen's chest": [
    "Clavis ad Arcam Jorgenis"
  ],
  "alvor services": [
    "Alvor Services"
  ],
  "brenuin": [
    "Brenuin"
  ],
  "iron ore": [
    "Ferri Minerale"
  ],
  "ebony warhammer of exhaustion": [
    "Bellimalleus Ebeni Exhaustionis"
  ],
  "iron helmet of archery": [
    "Ferri Galea Saggitationis"
  ],
  "elven armor of the major squire": [
    "Alfa Lorica Maioris Armiferis"
  ],
  "you want to be shipped off to honorhall orphanage": [
    "Vis ad Orphanotropium mitti"
  ],
  "key to vlindrel hall": [
    "Clavis ad Aulam Vlindrelis"
  ],
  "orcish warhammer of soul snares": [
    "Orci Bellimalleus Animicapti"
  ],
  "gold diamond ring": [
    "Anulus Aureus Adamanteus"
  ],
  "skeever tail": [
    "Cauda Skeever"
  ],
  "key to jorgen and lami's house": [
    "Clavis ad Domum Jorgenis et Lami"
  ],
  "king olaf one-eye": [
    "Rex Olaf Monophthalmos"
  ],
  "reverent orcish sword": [
    "Reverens Gladius Orcorum"
  ],
  "apprentice calcinator": [
    "Apprentice Calcinator"
  ],
  "new owner": [
    "Possessor novus"
  ],
  "staff of mending": [
    "Rudis Reficiendi"
  ],
  "orcish bow of animus": [
    "Orci Arcus Animi"
  ],
  "dwarven bow of frost": [
    "Dvemeris Arcus Gelidus"
  ],
  "hallowed ebony bow": [
    "Consacratus Arcus Ebeni"
  ],
  "dwarven bow of torpor": [
    "Dvemeris Arcus Torporis"
  ],
  "point the way and i'll get them": [
    "Demonstra viam et eos capiam"
  ],
  "dwarven battleaxe of burning": [
    "Dvemeris Bellisecuris Cremandi"
  ],
  "i don't understand what's going on": [
    "Nescio quid accidat"
  ],
  "dwarven battleaxe of depleting": [
    "Dvemeris Bellisecuris Deplendi"
  ],
  "ebony sword of animus": [
    "Gladius Ebeni Animi"
  ],
  "dwarven boots of flame suppression": [
    "Dvemeres Caligae Suppressionis Flammarum"
  ],
  "deadly bash": [
    "Oppugnatum Scuto Letiferum"
  ],
  "dragonscale helmet of waterbreathing": [
    "Draconisquamata Galea Respirandi Sub Aqua"
  ],
  "mg arcanaeum scene 7": [
    "MG Arcanaeum Scene 7"
  ],
  "leather bracers of minor alchemy": [
    "Corii Chirothecae Minoris Alchemiae"
  ],
  "daedric warhammer of lightning": [
    "Bellimalleus Daedricus Fulminis"
  ],
  "previous selection": [
    "Anterius"
  ],
  "you have.... but i don't care": [
    "Sic.... sed nihil curo"
  ],
  "hallowed dwarven dagger": [
    "Consacratus Dvemeris Pugio"
  ],
  "scroll of conjure familiar": [
    "Codex Vocandi Familiarem"
  ],
  "mid year celebration": [
    "Medio-Anno"
  ],
  "delete this game": [
    "Dele hunc ludum"
  ],
  "dwarven greatsword of ice": [
    "Dvemeris Spatha Gelidus"
  ],
  "iron dagger of arcing": [
    "Pugio Ferri Electricitatis"
  ],
  "glass bow of despair": [
    "Arcus Vitreus Desponsionis"
  ],
  "dwarven war axe of binding": [
    "Dvemeris Bellisecuris Deligandi"
  ],
  "helgird": [
    "Helgird"
  ],
  "kyr": [
    "Kyr"
  ],
  "dwarven sword of burning": [
    "Gladius Dvemeris Cremandi"
  ],
  "so, you're orthorn": [
    "Ergo, Orthorn es"
  ],
  "dwarven dagger of depleting": [
    "Dvemeris Pugio Deplendi"
  ],
  "dwarven bow of draining": [
    "Dvemeris Arcus Hauriendi"
  ],
  "dwarven sword of draining": [
    "Gladius Dvemeris Hauriendi"
  ],
  "shrouded boots": [
    "Umbratae Caligae"
  ],
  "elven dagger of depleting": [
    "Pugio Alfus Deplendi"
  ],
  "jawanan": [
    "Javanan"
  ],
  "daedric mace of freezing": [
    "Maca Daedrica Gelidus"
  ],
  "stig salt-plank": [
    "Stig Salt-Plank"
  ],
  "kahvozein's fang": [
    "Mucro Kahvozeinis"
  ],
  "daedric helmet of peerless conjuring": [
    "Galea Daedrica Optimae Vocandi"
  ],
  "what's special about the dagger": [
    "Quid tam magni momenti est de hoc pugione"
  ],
  "dwarven war axe of animus": [
    "Dvemeris Bellisecuris Animi"
  ],
  "dwarven warhammer of devouring": [
    "Dvemeris Bellimalleus Consumendi"
  ],
  "dragonscale gauntlets of eminent alchemy": [
    "Draconisquamatae Manicae Alchemiae Eminentis"
  ],
  "are you sure you want to delete this save game": [
    "Esne certus te hanc lusum tutum delere velle"
  ],
  "glass sword of banishing": [
    "Gladius Vitreus Expellendi"
  ],
  "orcish war axe of absorption": [
    "Orci Bellisecuris Absorbendi"
  ],
  "did you account for the, uh, extra coefficient introduced by... umm": [
    "Ratiocinatus es pro illa, uh, coefficiente, introducta a... umm"
  ],
  "how about you let me through and i don't kill you": [
    "Quidni me perire sinas et te non necem"
  ],
  "reverent elven battleaxe": [
    "Reverens Alfus Bellisecuris"
  ],
  "i'm not afraid of my own power": [
    "Vim meum non timeo"
  ],
  "fury - master of the mind": [
    "Fury - Dominus Mentis"
  ],
  "mzulft observatory key": [
    "Clavis ad Observatorium Mzulft"
  ],
  "reverent elven bow": [
    "Reverens Arcus Alfus"
  ],
  "spigot": [
    "Canalis"
  ],
  "elven dagger of dread": [
    "Pugio Alfus Timoris"
  ],
  "it shall be done as you ask": [
    "Erit ut rogas"
  ],
  "vampire trick": [
    "Vampire Trick"
  ],
  "elven sword of scorching": [
    "Alfus Gladius Adurendi"
  ],
  "elven mace of enervating": [
    "Maca Alfa Enervationis"
  ],
  "slot": [
    "Foramen"
  ],
  "elven mace of torpor": [
    "Maca Alfa Torporis"
  ],
  "xander": [
    "Xander"
  ],
  "femaleeyeswoodelfblind": [
    "FemaleEyesWoodElfBlind"
  ],
  "ebony bow of banishing": [
    "Arcus Ebeni Expellendi"
  ],
  "dwarven battleaxe of reaping": [
    "Dvemeris Bellisecuris Saturni"
  ],
  "elven sword of evoking": [
    "Alfus Gladius Evocandi"
  ],
  "potema's refuge": [
    "Potemae Refugium"
  ],
  "maleeyeselfrightblindsingle": [
    "MaleEyesElfRightBlindSingle"
  ],
  "elven sword of depleting": [
    "Alfus Gladius Deplendi"
  ],
  "laid to rest": [
    "Requiescat in Pace"
  ],
  "eastmarch imperial camp": [
    "Eurimarci Castra Romana"
  ],
  "brina merilis": [
    "Brina Merilis"
  ],
  "jorrvaskr back room key": [
    "Clavis ad Conclave Secretum Jorrvaskris"
  ],
  "sanctified dwarven mace": [
    "Sanctificata Dvemeris Maca"
  ],
  "falmer skulker": [
    "Falmer Noctivagus"
  ],
  "steel warhammer of souls": [
    "Bellimalleus Adamanteus Animorum"
  ],
  "calcelmo's stone rubbing": [
    "Imago Lapidis Calcelmis"
  ],
  "daedric greatsword of debilitation": [
    "Spatha Daedrica Debilitationis"
  ],
  "armor - stone": [
    "Armor - Stone"
  ],
  "the last battle here was hundreds of years ago": [
    "Proelium ultimum hic abhinc saecula erat"
  ],
  "last battle here was hundreds of years ago": [
    "Proelium ultimum hic abhinc saecula erat"
  ],
  "riften guard's shield": [
    "Hiati Custodis Scutum"
  ],
  "and of course they can't prove he murdered them": [
    "Et certe argumentum non habent eum nocentem esse"
  ],
  "humanbeard24": [
    "HumanBeard24"
  ],
  "imperial bow of sapping": [
    "Imperialis Arcus Hauriendi"
  ],
  "orcish boots of dwindling frost": [
    "Orci Caligae Minoris Congelationis"
  ],
  "\"one of the jarl's men\"": [
    "\"Unus ex hominibus Comitis\""
  ],
  "elven warhammer of dread": [
    "Alfus Bellimaleus Timoris"
  ],
  "woodelf": [
    "WoodElf"
  ],
  "dragonplate gauntlets of peerless archery": [
    "Draconilaminatae Manicae Optimae Saggitationis"
  ],
  "glass battleaxe of leeching": [
    "Bellisecuris Vitreus Sanguisugae"
  ],
  "scimitar": [
    "Acinaces"
  ],
  "i would like to purchase a house in the city": [
    "Volo domum in urbe emere"
  ],
  "(<global=hpwindhelm> gold)": [
    "(<Global=HPWindhelm> auri)"
  ],
  "what isn't the jarl dealing with": [
    "Quid non administrat Comes"
  ],
  "db attack player faction": [
    "DB Attack Player Faction"
  ],
  "philter of glibness": [
    "Philtrum Loquacitatis"
  ],
  "hairshavedmaleorc08": [
    "HairShavedMaleOrc08"
  ],
  "bloodthorn": [
    "Sanguinispina"
  ],
  "sanctified dwarven battleaxe": [
    "Sanctificatus Dvemeris Bellisecuris"
  ],
  "what should i do if there's no class": [
    "Quod nulla schola est, quid facere debeo"
  ],
  "banded iron shield of waning fire": [
    "Ferri Adligati Scutum Insenescentis Ignis"
  ],
  "gat gro-shargakh": [
    "Gat gro-Shargakh"
  ],
  "%i dragon soul": [
    "%i Animus Draconis"
  ],
  "you ready to pay up now": [
    "Paratusne es pecuniam solvere"
  ],
  "glass greatsword of lightning": [
    "Spatha Vitrea Fulminis"
  ],
  "potion of extra magicka": [
    "Potio Maioris Magicae"
  ],
  "roughspun tunic": [
    "Tunica Ruggosa"
  ],
  "dbastrido3givequesttopic1": [
    "DBAstridO3GiveQuestTopic1"
  ],
  "iron war axe of cold": [
    "Bellisecuris Ferri Frigidis"
  ],
  "potion of minor magicka": [
    "Potio Minoris Magicae"
  ],
  "imperial bow of burning": [
    "Imperialis Arcus Cremandi"
  ],
  "glass sword of devouring": [
    "Gladius Vitreus Consumendi"
  ],
  "humanbeard42": [
    "HumanBeard42"
  ],
  "glass sword of fear": [
    "Gladius Vitreus Timoris"
  ],
  "ebony sword of leeching": [
    "Gladius Ebeni Sanguisugae"
  ],
  "i'm thinking of buying some armor": [
    "In loricam emendo cogito"
  ],
  "any recommendations": [
    "Habesne ullas commendatas"
  ],
  "getting here was easy": [
    "Hic advenire facile erat"
  ],
  "glass war axe of devouring": [
    "Bellisecuris Vitreus Consumendi"
  ],
  "weakness to magic": [
    "Debilitas Magicae"
  ],
  "agrius's journal": [
    "Ephemeris Agrii"
  ],
  "ebony mace of thunderbolts": [
    "Maca Ebeni Fulminum"
  ],
  "unceasing magicka poison": [
    "Venenum Interminans Magicae"
  ],
  "armor rating": [
    "Cumulum Loricae"
  ],
  "glass war axe of fire": [
    "Bellisecuris Vitreus Ignis"
  ],
  "i hear you attacked a little girl": [
    "Audivi te parvam puellam opugnavisse"
  ],
  "anoriath": [
    "Anoriath"
  ],
  "alerttocombat": [
    "AlertToCombat"
  ],
  "dwemer puzzle cube": [
    "Dvemeris Cubus Aenigmatus"
  ],
  "i don't know the spell": [
    "Macicam nescio"
  ],
  "daedric greatsword of animus": [
    "Spatha Daedrica Animi"
  ],
  "glass war axe of stunning": [
    "Bellisecuris Vitreus Intermissionis"
  ],
  "wuunferth services": [
    "Wuunferth Services"
  ],
  "korvanjund": [
    "Korvanjund"
  ],
  "cicero's journal - final volume": [
    "Ephemeris Ciceronis - Volumen Ultimum"
  ],
  "glass warhammer of leeching": [
    "Bellimaleus Vitreus Sanguisugae"
  ],
  "glass greatsword of freezing": [
    "Spatha Vitrea Gelidus"
  ],
  "an arcane enchanter can be used to learn new enchantments from weapons and armor, but any item experimented on is destroyed in the process": [
    "Incantator Arcanus quoque usurpatur ut nova incantamenta discantur, sed res investigata destruetur"
  ],
  "what's at serpentstone island": [
    "Quid est apud Insulam Serpentilapidem"
  ],
  "hunting bow of scorching": [
    "Arcus Venandi Adurendi"
  ],
  "ebony battleaxe of banishing": [
    "Bellisecuris Ebeni Expellendi"
  ],
  "ebony battleaxe of exhaustion": [
    "Bellisecuris Ebeni Exhaustionis"
  ],
  "ebony bow of the blaze": [
    "Arcus Ebeni Congflagrantis"
  ],
  "dwarven dagger of devouring": [
    "Dvemeris Pugio Consumendi"
  ],
  "ebony dagger of subsuming": [
    "Pugio Ebeni Subsumendi"
  ],
  "i can certainly help": [
    "Certe adiuvare possum"
  ],
  "glass mace of devouring": [
    "Maca Vitrea Consumendi"
  ],
  "stonehill bluff": [
    "Stonehill Bluff"
  ],
  "ebony mace of petrifying": [
    "Maca Ebeni Lapidescendi"
  ],
  "cast a firebolt spell": [
    "Fac Sagitignum"
  ],
  "you don't care that they're dead": [
    "Non curas eos mortuos esse"
  ],
  "ebony sword of freezing": [
    "Gladius Ebeni Gelidus"
  ],
  "feast on verulus": [
    "Corpus Veruli ede"
  ],
  "dwarven war axe of harvesting": [
    "Dvemeris Bellisecuris Saturni"
  ],
  "ebony sword of thunderbolts": [
    "Gladius Ebeni Fulminum"
  ],
  "you cannot equip this item right now": [
    "Hanc rem nunc uti non potes"
  ],
  "it seems there's no talking you out of this": [
    "Mihi videtur me consilium tuum mutare non posse"
  ],
  "ebony war axe of subsuming": [
    "Bellisecuris Ebeni Subsumendi"
  ],
  "beem-ja": [
    "Beem-Ja"
  ],
  "hide helmet of alchemy": [
    "Cutis Galea Alchemiae"
  ],
  "glass helmet of eminent conjuring": [
    "Galea Vitrea Coniurationis Vocantis"
  ],
  "orcish sword of fear": [
    "Orci Gladius Timoris"
  ],
  "amaund motierre's sealed letter": [
    "Epistula Clausa Amaundi Motierris"
  ],
  "babette": [
    "Babette"
  ],
  "michel lylvieve": [
    "Michel Lylvieve"
  ],
  "enchant item": [
    "Incantamenta in rem pone"
  ],
  "femaleeyesorcblind": [
    "FemaleEyesOrcBlind"
  ],
  "i didn't mean to alarm you": [
    "Nolui te terrere"
  ],
  "why are you so tense": [
    "Cur tam sollicitus es"
  ],
  "a lovely letter": [
    "Epistula Pulchra"
  ],
  "lovely letter": [
    "Epistula Pulchra"
  ],
  "maledremorahair01": [
    "MaleDremoraHair01"
  ],
  "daedric dagger of garnering": [
    "Pugio Daedricus Horti"
  ],
  "glass sword of binding": [
    "Gladius Vitreus Deligandi"
  ],
  "how about dawnstar": [
    "Quid de Luficere"
  ],
  "help selveni nethri escape the cave": [
    "Adiuva Selveni Nethri ut cavam effugiat"
  ],
  "i've found all the stones of barenziah": [
    "Omnes Lapides Barenziae inveni"
  ],
  "you know about that": [
    "De illo scis"
  ],
  "iron warhammer of souls": [
    "Bellimalleus Ferratus Animorum"
  ],
  "elven boots of hauling": [
    "Caligae Alfae Portandi"
  ],
  "glass battleaxe of ice": [
    "Bellisecuris Vitreus Gelidus"
  ],
  "first remains": [
    "Reliquiae Primae"
  ],
  "daedric helmet of eminent conjuring": [
    "Galea Daedrica Coniurationis Vocantis"
  ],
  "save obsolete": [
    "TUTUM OBSOLETUM"
  ],
  "calcelmo's stone": [
    "Lapis Calcelmonis"
  ],
  "audiotemplatespiderfrostbitegiant": [
    "AudioTemplateSpiderFrostbiteGiant"
  ],
  "key to belethor's general goods": [
    "Clavis ad Bona Belethoris"
  ],
  "daedric greatsword of banishing": [
    "Spatha Daedrica Expellendi"
  ],
  "leather armor": [
    "Corii Lorica"
  ],
  "philter of regeneration": [
    "Philtrum Recuperandi"
  ],
  "dwarven war axe of shocks": [
    "Dvemeris Bellisecuris Electricitatis"
  ],
  "what are your top priorities as the new jarl": [
    "Quae res maximi momenti tibi sunt ut Comes novus"
  ],
  "babette's dark brotherhood services": [
    "Babette's Dark Brotherhood Services"
  ],
  "daedric mace of depleting": [
    "Maca Daedrica Deplendi"
  ],
  "talk to adelaisa": [
    "Adloquere Adelaisam"
  ],
  "why tell this to me": [
    "Cur mihi hoc dixisti"
  ],
  "grand staff of repulsion": [
    "Rudis Magnus Repulsionis"
  ],
  "eduj": [
    "Eduj"
  ],
  "dragonsreach jarl's quarters": [
    "Draconicarcer Comitis Cubicula"
  ],
  "lemkil": [
    "Lemkil"
  ],
  "he didn't seem to think it would do any good": [
    "Non credere ei ullum bonum facturum esse videbatur"
  ],
  "glass warhammer of binding": [
    "Bellimaleus Vitreus Deligandi"
  ],
  "honed ancient nord greatsword of ice": [
    "Melior Germanica Antiqua Spatha Gelidus"
  ],
  "draugr ally faction": [
    "Draugr Ally Faction"
  ],
  "firiniel's end": [
    "Terminus Firinielis"
  ],
  "they seem to think he's hiding out in riften": [
    "Illum Hiati occultare putant"
  ],
  "i don't mind hurting someone for money": [
    "Nihil mihi est alicui pro pecunia nocere"
  ],
  "lumber pile": [
    "Agger Bosci"
  ],
  "bandit wizard": [
    "Bandit Wizard"
  ],
  "shaircolor4": [
    "sHairColor4"
  ],
  "first seed": [
    "Mars"
  ],
  "orgnar": [
    "Orgnar"
  ],
  "ebony bow of immobilizing": [
    "Arcus Ebeni Immobilitatis"
  ],
  "now and forever": [
    "Nunc est semper"
  ],
  "orcish sword of winnowing": [
    "Orci Gladius Ventagii"
  ],
  "can i enter the hall of valor": [
    "Possumne Aulam Valoris intrare"
  ],
  "mid year": [
    "Iunius"
  ],
  "just tell me what happened": [
    "Dic mihi quid accidisse"
  ],
  "did you see that": [
    "Illudne vidisti"
  ],
  "key to daighre's house": [
    "Clavis ad Domum Daighris"
  ],
  "ebony dagger of enervating": [
    "Pugio Ebeni Enervationis"
  ],
  "honningbrew meadery key": [
    "Clavis ad Hydromellarium Honningbreve"
  ],
  "audiocategorypausedduringmenu": [
    "AudioCategoryPausedDuringMenu"
  ],
  "darklight tower closet key": [
    "Clavis ad Armarium Turris Obscuralucis"
  ],
  "avanchnzel balcony": [
    "Echtheta Avanchnzel"
  ],
  "bee and barb scene 05": [
    "Bee et Barb Scene 05"
  ],
  "[no saves found]": [
    "[Nulla Tuta Inventa]"
  ],
  "iron greatsword of frost": [
    "Spatha Ferri Gelidus"
  ],
  "dragonscale armor of peerless health": [
    "Draconisquamata Lorica Optimae Salutis"
  ],
  "can you repair and refit the dawnstar sanctuary": [
    "Potesne reficere Sanctuarium Luciferis"
  ],
  "odar": [
    "Odar"
  ],
  "pickpockettopic": [
    "PickpocketTopic"
  ],
  "aquillius aeresius": [
    "Aquillius Aeresius"
  ],
  "is there anything you can tell me about thorald": [
    "Estne quid plus mihi de Thorald dicere potes"
  ],
  "spell tome: paralyze": [
    "Liber Magicus: Paralyticum"
  ],
  "are you familiar with this crimson nirnroot": [
    "Esne sciens huius Nirniradicis Cinnibaris"
  ],
  "who would that be": [
    "Quis esset"
  ],
  "you were telling me about the witches": [
    "Mihi de sagis narrabas"
  ],
  "drifa": [
    "Drifa"
  ],
  "banded iron shield of waning frost": [
    "Ferri Adligati Scutum Insenescentis Congelationis"
  ],
  "i found notes about a crystal, and the oculory in mzulft": [
    "Adnotationes de crystallo tuo inveni, et de Oculorio in Mzulft"
  ],
  "asgeir snow-shod": [
    "Asgeir Nivi-Calligatus"
  ],
  "does your inexperience make your job difficult": [
    "Facitne laborem dificior imperitia tua"
  ],
  "hairlinemaleorc27": [
    "HairLineMaleOrc27"
  ],
  "glass helmet of extreme illusion": [
    "Galea Vitrea Inlusionis Extremae"
  ],
  "mikrul gauldurson": [
    "Mikrul Gauldrifilius"
  ],
  "narri": [
    "Narri"
  ],
  "flawless sapphire": [
    "Perfectus Sapphyrus"
  ],
  "iron gauntlets of major wielding": [
    "Ferri Manicae Maioris Tenendi"
  ],
  "elven war axe of depleting": [
    "Alfus Bellisecuris Deplendi"
  ],
  "who runs dawnstar": [
    "Quis Luciferem regit"
  ],
  "boethiah statue": [
    "Statua Boethis"
  ],
  "gauldur blackblade": [
    "Galdris Atrensis"
  ],
  "jailor": [
    "Jailor"
  ],
  "potion of vigorous healing": [
    "Potio Vigentis Salutis"
  ],
  "grimsever": [
    "Grimsever"
  ],
  "you do or you get hurt": [
    "Fac id vel noceris",
    "Fac id vel noceberis"
  ],
  "i give up": [
    "Nescio"
  ],
  "elven battleaxe of torpor": [
    "Bellisecuris Alfus Torporis"
  ],
  "redguard woman": [
    "Rubracustos"
  ],
  "dwemer inquiries vol i": [
    "Investigationes Dvemeres I"
  ],
  "sigil stone": [
    "Lapis Sigilis"
  ],
  "fihada": [
    "Fihada"
  ],
  "burned book": [
    "Liber Crematus"
  ],
  "imperial war horn": [
    "Cornu Belli Imperiale"
  ],
  "dragonplate helmet of extreme conjuring": [
    "Draconilaminata Galea Maiorum Vocantium"
  ],
  "steel boots of dwindling shock": [
    "Caligae Adamanteae Electricitatis Diminuentis"
  ],
  "playing upon the historical significance of their town's graveyard, the merchants of falkreath have adopted names for their businesses that reflect themes of death and mortality": [
    "Quod necropolis Markartiae maximi momenti est, Mercatores illius urbis nomina ad negotios suos dedurunt quae cum ideis mortis mortalitatisque ludunt"
  ],
  "glass shield of shock abatement": [
    "Vitreum Scutum Contra Vim Electricam"
  ],
  "i don't know any ward spells": [
    "Nescio magicam defensi"
  ],
  "daedric gauntlets of peerless smithing": [
    "Daedricae Manicae Maximi Faciendi"
  ],
  "dwarven dagger of debilitation": [
    "Dvemeris Pugio Debilitationis"
  ],
  "stormcloak": [
    "Sturmmantelus"
  ],
  "dwarven sword of thunderbolts": [
    "Gladius Dvemeris Fulminum"
  ],
  "accept <alias=jarl>'s surrender": [
    "Accipe Deditionem <Alias=Jarl>"
  ],
  "improved sly of hand": [
    "Improved Sly of Hand"
  ],
  "snow bear pelt": [
    "Reno Ursae Noctis"
  ],
  "wyndelius's journal": [
    "Ephemeris Vyndelii"
  ],
  "fort neugrad prison": [
    "Carcer Castelli Neugrad"
  ],
  "the lord: magic resist": [
    "Dominus: Magicae Resiste"
  ],
  "lord: magic resist": [
    "Dominus: Magicae Resiste"
  ],
  "hairmaleorc25": [
    "HairMaleOrc25"
  ],
  "novice talisman": [
    "Novice Talisman"
  ],
  "over my dead body": [
    "Super corpus meum"
  ],
  "scaled bracers of major alchemy": [
    "Chirothecae Squamatae Maioris Alchemiae"
  ],
  "recognize this": [
    "Hocne agnovis"
  ],
  "(show <alias.pronounobj=steward> <alias=evidence>)": [
    "(Demonstra <Alias.PronounObj=Steward> <Alias=Evidence>)"
  ],
  "elven mace of expelling": [
    "Maca Alfa Expellendi"
  ],
  "glass war axe of scorching": [
    "Bellisecuris Vitreus Adurendi"
  ],
  "lydia": [
    "Atalanta"
  ],
  "ebony mace of despair": [
    "Maca Ebeni Desponsionis"
  ],
  "faction that is friends with all creatures": [
    "Faction that is friends with all Creatures"
  ],
  "expedition manifest": [
    "Manifestum Expeditionis"
  ],
  "the buying game": [
    "Ludus Emendi"
  ],
  "buying game": [
    "Ludus Emendi"
  ],
  "imperial armor of the knight": [
    "Romana Lorica Equitis"
  ],
  "ruins of bthalft": [
    "Ruinae Bthalft"
  ],
  "strange orb": [
    "Orbem Mirabilem"
  ],
  "dwarven greatsword of thunderbolts": [
    "Dvemeris Spatha Fulminum"
  ],
  "texture size": [
    "Magnitudo Texturae"
  ],
  "ebony dagger of garnering": [
    "Pugio Ebeni Horti"
  ],
  "dwarven battleaxe of weariness": [
    "Dvemeris Bellisecuris Maioris Defatigationis"
  ],
  "maleeyesorcvampire": [
    "MaleEyesOrcVampire"
  ],
  "yes, malborn's all set": [
    "Sic, Malbornis paratus est"
  ],
  "leather bracers of wielding": [
    "Corii Chirothecae Tenendi"
  ],
  "cleared": [
    "Vacuus"
  ],
  "my services aren't free": [
    "Munera mea gratis non sunt"
  ],
  "reverent elven war axe": [
    "Reverens Alfus Bellisecuris"
  ],
  "door to cosnach's room": [
    "Ianuam ad conclave Cosnachis"
  ],
  "dulug": [
    "Dulug"
  ],
  "smuggler": [
    "Subintroductor"
  ],
  "childeyes": [
    "ChildEyes"
  ],
  "elixir of true shot": [
    "Commixtum Saggitationis"
  ],
  "reindeer": [
    "ReinDeer"
  ],
  "just tell me what to do": [
    "Modo dic mihi quid facere debeo"
  ],
  "orcish boots of brawn": [
    "Orci Caligae Fortitudinis"
  ],
  "key to winking skeever": [
    "Clavis ad Skeever Adnictantem"
  ],
  "you said you needed help": [
    "Dixistine te auxilium voluisse"
  ],
  "glass gauntlets of extreme smithing": [
    "Vitreae Manicae Extremis Ferrarii"
  ],
  "steel helmet of minor conjuring": [
    "Adamantea Galea Minoris Vocandi"
  ],
  "i helped the long-dead lovers find each other": [
    "Adiuvi amatores longe mortui inter se invenire"
  ],
  "you have not selected a storage device": [
    "You have not selected storage device"
  ],
  "you will need to select one before you can save": [
    "You will need to select one before you can save"
  ],
  "[new save]": [
    "[Novum Tutum]"
  ],
  "you cannot sleep when enemies are nearby": [
    "Non potes dormire cum inimici propinqui sint"
  ],
  "iron mace of diminishing": [
    "Ferri Maca Diminuendi"
  ],
  "braig": [
    "Braig"
  ],
  "rexus": [
    "Rexus"
  ],
  "arch-mage's quarters key": [
    "Clavis ad Cubicula Archimagi"
  ],
  "elven dagger of despair": [
    "Pugio Alfus Desponsionis"
  ],
  "glass bow of lightning": [
    "Arcus Vitreus Fulminis"
  ],
  "start a new game": [
    "Incipe ludum novum"
  ],
  "humanbeard18": [
    "HumanBeard18"
  ],
  "you have not discovered this location yet": [
    "Hunc locum nondum invenisti"
  ],
  "daedric sword of debilitation": [
    "Gladius Daedricus Debilitationis"
  ],
  "daedric battleaxe of harvesting": [
    "Bellisecuris Daedricus Saturni"
  ],
  "marksmalehumanoid08rightgash": [
    "MarksMaleHumanoid08RightGash"
  ],
  "elven helmet of eminent archery": [
    "Alfa Galea Eminentis Saggitationis"
  ],
  "i never wanted to help you": [
    "Numquam te adiuvare volui"
  ],
  "let me go": [
    "Sine me exire"
  ],
  "orcish mace of thunderbolts": [
    "Orci Maca Fulminum"
  ],
  "kyne's peace 1": [
    "Pax Kynis 1"
  ],
  "browsmaleargonian01": [
    "BrowsMaleArgonian01"
  ],
  "what's wrong with the divines": [
    "Quid est difficultas cum Divis"
  ],
  "i want to know about the silver-bloods": [
    "Volo de Argenti-Sanguinibus scire"
  ],
  "stop right there": [
    "Consiste nunc"
  ],
  "let me take you to ruki": [
    "Tibi ad Rukin adferam"
  ],
  "dwarven war axe of cowardice": [
    "Dvemeris Bellisecuris Timoris"
  ],
  "curwe": [
    "Curve"
  ],
  "imperial bow of souls": [
    "Imperialis Arcus Animorum"
  ],
  "okay, i'll come quietly": [
    "Ok, clam veniam"
  ],
  "bone break fever": [
    "Febris Ossium Fractium"
  ],
  "you're talking about... the literal end of the world": [
    "De Apocalypse vera loqueris"
  ],
  "lucan valerius": [
    "Lucan Valerius"
  ],
  "ebony helmet of major magicka": [
    "Ebeni Galea Maioris Magicae"
  ],
  "hidden pockets": [
    "Fundae Tectae"
  ],
  "colovian brandy temp": [
    "Colovian Brandy TEMP"
  ],
  "spell tome: ice storm": [
    "Liber Magicus: Tempestas Glaciei"
  ],
  "talk to maven about vald's debt": [
    "Adloquere Maven de aere alieno Valdis"
  ],
  "hallowed ebony warhammer": [
    "Consacratus Bellimalleus Ebeni"
  ],
  "maybe i could help you": [
    "Fortasse te adiuvare possum"
  ],
  "spirit of the daedra": [
    "Animus Daedrarum"
  ],
  "dun": [
    "Dun"
  ],
  "dragon bridge farm": [
    "Dragon Bridge Farm"
  ],
  "what's vici's connection to the emperor": [
    "Quid est nexus inter Vici et Imperatorem"
  ],
  "thalmor orders": [
    "Mandata Visigotha"
  ],
  "ebony armor of the eminent knight": [
    "Ebeni Lorica Eminentis Equitis"
  ],
  "steel battleaxe of dismay": [
    "Bellisecuris Adamanteus Timoris"
  ],
  "enchanted ring": [
    "Anulus Incantatus"
  ],
  "elven greatsword of ice": [
    "Spatha Alfa Gelidus"
  ],
  "elven battleaxe of blizzards": [
    "Bellisecuris Alfus Hiemis"
  ],
  "elven warhammer of harvesting": [
    "Alfus Bellimaleus Saturni"
  ],
  "orcish boots of shock suppression": [
    "Orci Caligae Suppresionis Electricitatis"
  ],
  "steel battleaxe of ice": [
    "Bellisecuris Adamanteus Gelidus"
  ],
  "wounded soldier": [
    "Miles Vulneratus"
  ],
  "fortify stamina - master of the mind": [
    "Fortify Stamina - Dominus Mentis"
  ],
  "elven armor of the eminent squire": [
    "Alfa Lorica Eminentis Armiferis"
  ],
  "setting up a wreck": [
    "Naufragium creare"
  ],
  "i should turn you in to the guards": [
    "Tibi custodibus tradere debeo"
  ],
  "damage magicka regen": [
    "Vulnera Recuperationem Magicae"
  ],
  "unlocked with %s": [
    "Aperta %s"
  ],
  "tell me about karliah": [
    "Dic mihi de Karlia"
  ],
  "draught of resist cold": [
    "Decocta Resistendi Frigorem"
  ],
  "there might be more to this, if you'll let me help": [
    "Fortasse plus hic inest. Sine me auxilium dare"
  ],
  "thieves' guild": [
    "Thieves' Guild"
  ],
  "just one, for now": [
    "Modo unum"
  ],
  "branded mammoth": [
    "Branded Mammoth"
  ],
  "i already have some skooma": [
    "Iam skoomam habeo"
  ],
  "olaf was numinex": [
    "Olaf erat Numinex"
  ],
  "a dragon in human form": [
    "Draco in forma hominis"
  ],
  "dragon in human form": [
    "Draco in forma hominis"
  ],
  "dragonscale helmet of eminent alchemy": [
    "Draconisquamata Galea Alchemiae Eminentis"
  ],
  "glass bow of immobilizing": [
    "Arcus Vitreus Immobilitatis"
  ],
  "imperial bracers of smithing": [
    "Chirothecae Romanae Ferrarii"
  ],
  "why are you in this cage": [
    "Cur in hac carcere es"
  ],
  "white river overlook": [
    "Panorama Rivi Albi"
  ],
  "ragged boots": [
    "Caligae Fragosae"
  ],
  "femaleheadhighelf": [
    "FemaleHeadHighElf"
  ],
  "hairfemalenordchild02": [
    "HairFemaleNordChild02"
  ],
  "dwarven shield of waning shock": [
    "Dvemere Scutum Insenescentis Electricitatis"
  ],
  "makes enemy of weprisonerfreedcombatprisonerfaction": [
    "Makes enemy of WEPrisonerFreedCombatPrisonerFaction"
  ],
  "steel shield of waning frost": [
    "Adamanteum Scutum Insenescentis Congelationis"
  ],
  "find kesh at the peryite shrine": [
    "Inveni Kesh ad aram Peryitis"
  ],
  "you mentioned mean folk passing through town": [
    "Commemoravisti homines malos qui per oppidum transibant"
  ],
  "whose side are you on": [
    "In latere cuius es"
  ],
  "skeever guardian spirit": [
    "Animus Custodis Skeever"
  ],
  "orcish war axe of torpor": [
    "Orci Bellisecuris Torporis"
  ],
  "ramati's journal": [
    "Ephemeris Ramati"
  ],
  "dwarves, v2": [
    "Pumilliones, II"
  ],
  "so there's no high king now": [
    "Ergo, nullum Regem Altum nunc est"
  ],
  "addvild": [
    "Addvild"
  ],
  "ebony mace of the inferno": [
    "Maca Ebeni Conflagrationis"
  ],
  "why are you in jail": [
    "Cur in carcere es"
  ],
  "murdernc": [
    "MurderNC"
  ],
  "morokei": [
    "Morokei"
  ],
  "hairlinemaleorc18": [
    "HairLineMaleOrc18"
  ],
  "talk to the jarl": [
    "Adloquere Comitem"
  ],
  "nordic barnacle": [
    "Ostrea Germanica"
  ],
  "but the staff is broken": [
    "Sed rudis fractus est"
  ],
  "ulundil": [
    "Ulundil"
  ],
  "imperial sword of fear": [
    "Gladius Romanus Timoris"
  ],
  "here are the things you asked for": [
    "Hic sunt res quas depoposcisti"
  ],
  "sinding's body": [
    "Corpus Sindingis"
  ],
  "much bigger than that": [
    "Much bigger than that"
  ],
  "as big as the inn": [
    "As big as inn"
  ],
  "i've taken care of the disturbance": [
    "Finem ad molestiam feci"
  ],
  "thonar's journal": [
    "Ephemeris Thonaris"
  ],
  "no deal": [
    "Minime"
  ],
  "take the axe and take back barbas": [
    "Cape securem et Barbam recipe"
  ],
  "potion of extreme stamina": [
    "Potio Extremis Staminae"
  ],
  "elven boots of sneaking": [
    "Caligae Alfae Repsendi"
  ],
  "daedric battleaxe of the blaze": [
    "Bellisecuris Daedricus Congflagrantis"
  ],
  "ebony warhammer of devouring": [
    "Bellimalleus Ebeni Consumendi"
  ],
  "elven boots of waning shock": [
    "Caligae Alfae Insenescentis Electricitatis"
  ],
  "hairfemalenord02": [
    "HairFemaleNord02"
  ],
  "actually, she's already dead": [
    "Re vera, illa iam mortua est"
  ],
  "you can not eat quest items": [
    "Res muneri necessarias consumere non potes"
  ],
  "orcish mace of arcing": [
    "Orci Maca Electricitatis"
  ],
  "forsworn bow": [
    "Abiuratus Arcus"
  ],
  "orcish dagger of shocks": [
    "Orci Pugio Electricitatis"
  ],
  "alpha wolf": [
    "Lupus Alpha"
  ],
  "glass sword of scorching": [
    "Gladius Vitreus Adurendi"
  ],
  "i only see the spikes of this trap": [
    "Modo spinas harum insidiarum video"
  ],
  "thieves guild variant gloves": [
    "Sodalitatis Furum Manubria Var"
  ],
  "turn undead push": [
    "Turn Undead Push"
  ],
  "cowl": [
    "Capucium"
  ],
  "corundum ore": [
    "Corundi Minerale"
  ],
  "tell me about ma'randru-jo": [
    "Dic mihi de Ma'randru-jo"
  ],
  "what is their purpose": [
    "Quid est munus eorum"
  ],
  "snowy sabre cat": [
    "Snowy Sabre Cat"
  ],
  "set odahviing free": [
    "Libera Odahviing"
  ],
  "steel mace of sapping": [
    "Maca Adamantea Hauriendi"
  ],
  "speak with bryling": [
    "Adloquere Bryling"
  ],
  "it doesn't bother you that we're killing your ancestors": [
    "Nonne te molestat nos maiores tuos necare"
  ],
  "dwarven boots of dwindling flames": [
    "Dvemeres Caligae Flammarum Diminuentium"
  ],
  "tell me about anoriath": [
    "Dic mihi de Anoriath"
  ],
  "hairlinemalenord15": [
    "HairLineMaleNord15"
  ],
  "repair skill": [
    "Repair Ars"
  ],
  "hold on a minute": [
    "Exspecta momentum"
  ],
  "combattolost": [
    "CombatToLost"
  ],
  "i believe the killer is wuunferth the unliving": [
    "Credo Vuunferth Invivum necatorem esse"
  ],
  "people are having bad dreams": [
    "Homines suppressiones habent"
  ],
  "porch": [
    "Ektheta"
  ],
  "(<global=hdriftenporch> gold)": [
    "(<Global=HDRiftenPorch> auri)"
  ],
  "mehrunes razor enchantment": [
    "Mehrunes Razor Enchantment"
  ],
  "what's wrong with everyone around here": [
    "Quid est difficultas cum omnibus circiter"
  ],
  "do you know anything about this amulet": [
    "Scisne anything de hoc monile"
  ],
  "daedric dagger of stunning": [
    "Pugio Daedricus Intermissionis"
  ],
  "iron mace of souls": [
    "Ferri Maca Animorum"
  ],
  "ebony dagger of winter": [
    "Pugio Ebeni Hiemis"
  ],
  "dragonscale helmet of eminent restoration": [
    "Draconisquamata Galea Eminentis Reficiendi"
  ],
  "studded armor of the minor squire": [
    "Ornata Lorica Minoris Armiferis"
  ],
  "i heard you ran into trouble at goldenglow": [
    "Audivi te in difficultatem cadere apud Aurlucem"
  ],
  "trollsbane": [
    "Mortitrellis"
  ],
  "dialoguekarthwastenenmonbelchimach02": [
    "DialogueKarthwastenEnmonBelchimach02"
  ],
  "you don't have enough magicka": [
    "Non satis magicae habes"
  ],
  "orcish armor of extreme health": [
    "Orci Lorica Extremis Salutis"
  ],
  "orcish helmet of major archery": [
    "Orci Galea Maioris Sagittationis"
  ],
  "<alias=imperialminorholdcapital> seems like a fair trade": [
    "Aequabiliter mihi videtur <Alias=ImperialMinorHoldCapital> tradere"
  ],
  "the only cure": [
    "Remedium Solum"
  ],
  "only cure": [
    "Remedium Solum"
  ],
  "spell tome: banish daedra": [
    "Liber Magicus: Expelle Daedram"
  ],
  "hairmalenord20": [
    "HairMaleNord20"
  ],
  "angi's bow": [
    "Arcus Angiae"
  ],
  "blessing of arkay": [
    "Benedictio Arkayae"
  ],
  "hrongar": [
    "Hrongar"
  ],
  "\"did you find something good?\"": [
    "\"Did you inveni something good?\""
  ],
  "motierre wants us... to kill the emperor": [
    "Motierre nos necare vult... Imperator"
  ],
  "orcish armor": [
    "Orci Lorica"
  ],
  "skeever polymorph": [
    "Skeever Polymorph"
  ],
  "detect undead friend interior": [
    "Detect Undead Friend Interior"
  ],
  "nose height": [
    "Nasi Altitudo"
  ],
  "ebony gauntlets of extreme archery": [
    "Ebeni Manicae Extremis Saggitationis"
  ],
  "unknown location": [
    "Locus Ignotus"
  ],
  "i've discovered a skooma dealer in riften": [
    "Vendor skoomae Hiati inveni"
  ],
  "hairfemaleorc07": [
    "HairFemaleOrc07"
  ],
  "iron battleaxe of ice": [
    "Bellisecuris Ferri Gelidus"
  ],
  "steel helmet": [
    "Adamantea Galea"
  ],
  "karliah's bow": [
    "Arcus Karliae"
  ],
  "body part": [
    "Pars Corporis"
  ],
  "inkwell": [
    "Atramentarium"
  ],
  "maleeyeshumanhazel": [
    "MaleEyesHumanHazel"
  ],
  "virtuous daedric war axe": [
    "Virtutis Bellisecuris Daedricus"
  ],
  "sanctified orcish warhammer": [
    "Sanctificatus Bellimalleus Orcorum"
  ],
  "andurs": [
    "Andurs"
  ],
  "daedric sword of stunning": [
    "Gladius Daedricus Intermissionis"
  ],
  "save corrupt": [
    "TUTUM CORRUPTUM"
  ],
  "imperial sword of shocks": [
    "Gladius Romanus Electricitatis"
  ],
  "she said she'll be home soon": [
    "Dixit se domum mox reventuram esse"
  ],
  "audiotemplategiant": [
    "AudioTemplateGiant"
  ],
  "scroll of repel undead": [
    "Codex Amortuos Repellendi"
  ],
  "hairlinemaledarkelf01": [
    "HairLineMaleDarkElf01"
  ],
  "steel boots of resist shock": [
    "Caligae Adamanteae Contra Vim Electricam"
  ],
  "imperial supporter": [
    "Imperialis Supporter"
  ],
  "potion of": [
    "Potio"
  ],
  "goat cheese wedge": [
    "Cuneus Capri Casei"
  ],
  "daedric war axe of despair": [
    "Bellisecuris Daedricus Desponsionis"
  ],
  "marksfemalehumanoid02leftgash": [
    "MarksFemaleHumanoid02LeftGash"
  ],
  "you can't continue fighting": [
    "Pugnare pergere non potes"
  ],
  "imperial helmet of minor destruction": [
    "Romana Galea Minoris Destructionis"
  ],
  "leave omluag alone": [
    "Desine ab Omluag"
  ],
  "this is my final warning": [
    "Hic est admonitium ultimum"
  ],
  "elven sword of reaping": [
    "Alfus Gladius Saturni"
  ],
  "daedric greatsword of lightning": [
    "Spatha Daedrica Fulminis"
  ],
  "steel war axe of binding": [
    "Bellisecuris Adamanteus Deligandi"
  ],
  "zkeyobject": [
    "ZKeyObject"
  ],
  "daedric mace of lightning": [
    "Maca Daedrica Fulminis"
  ],
  "tell calcelmo that nimhe is dead": [
    "Dic Calcelmonem Nimhen Mortuam Esse"
  ],
  "deliver message to the jarl of whiterun": [
    "Adfer nuntum ad Comitem Albacursus"
  ],
  "iron war axe of arcing": [
    "Bellisecuris Ferri Electricitatis"
  ],
  "glass dagger of malediction": [
    "Pugio Vitreus Maledictionis"
  ],
  "spell tome: lightning bolt": [
    "Liber Magicus: Fulmen"
  ],
  "hunting bow of fatigue": [
    "Arcus Venandi Defatigationis"
  ],
  "so, what do you want me to do": [
    "Ergo, Quid vis me facere"
  ],
  "spell tome: bound battleaxe": [
    "Liber Magicus: Bellisecuris Vocatus"
  ],
  "daedric battleaxe of exhaustion": [
    "Bellisecuris Daedricus Exhaustionis"
  ],
  "maleeyesdarkelfred": [
    "MaleEyesDarkElfRed"
  ],
  "olaf found him asleep": [
    "Olaf eum dormientem invenit"
  ],
  "elixir of destruction": [
    "Commixtum Destructionis"
  ],
  "steel gauntlets of major wielding": [
    "Adamanteae Manicae Maioris Tenendi"
  ],
  "the firmament": [
    "Firmamentum"
  ],
  "firmament": [
    "Firmamentum"
  ],
  "daedric battleaxe of devouring": [
    "Bellisecuris Daedricus Consumendi"
  ],
  "penitus oculatus outpost": [
    "Penitus Oculatus Statio"
  ],
  "is there anything we can do": [
    "Estne quid quod facere possumus"
  ],
  "node1,node2,node3": [
    "Node1,Node2,Node3"
  ],
  "why would i want to complain about the college": [
    "Cur de Collegio queriter"
  ],
  "mor khazgur cellar": [
    "Mor Khazgur Cellarium"
  ],
  "ebony ingot": [
    "Ebeni Later"
  ],
  "blacksmith's elixir": [
    "Ferrarii Commixtum"
  ],
  "scaled boots of major sneaking": [
    "Caligae Squamatae Maioris Repsendi"
  ],
  "dawnstar is the capital of the pale, known for its rich mines and harbor": [
    "Lucifer est caput Pallidi, pro metallis et navali scitus"
  ],
  "fort sungard jail key": [
    "Clavis ad Carcerem Castelli Custodisolis"
  ],
  "who are you looking for again": [
    "Iterum, quem quaeris"
  ],
  "ebony armor of eminent health": [
    "Ebeni Lorica Eminentis Salutis"
  ],
  "dwarven sword of absorption": [
    "Gladius Dvemeris Absorbendi"
  ],
  "that's why i need to find him": [
    "Hac de causa opus mihi eum invenire est"
  ],
  "would you play \"tale of the tongues\"": [
    "Potesne canere \"Fabula Linguarum\""
  ],
  "ebony greatsword of nullifying": [
    "Spatha Ebeni Nullificandi"
  ],
  "glass warhammer of malediction": [
    "Bellimaleus Vitreus Maledictionis"
  ],
  "ebony greatsword of winter": [
    "Spatha Ebeni Hiemis"
  ],
  "imperial condolences": [
    "Condolentia Imperialia"
  ],
  "then help me convince her to pay": [
    "Deinde me adiuva ut mentem eius mutem"
  ],
  "voicepowerendlong": [
    "VoicePowerEndLong"
  ],
  "ebony dagger of devouring": [
    "Pugio Ebeni Consumendi"
  ],
  "you have not signed in to a gamer profile": [
    "You have not signed in to Gamer Profile"
  ],
  "you will need to sign in to a gamer profile and then select a storage device before you can save": [
    "You will need to sign in to Gamer Profile et then select storage device before you can save"
  ],
  "daedric helmet of peerless restoration": [
    "Galea Daedrica Maximi Recreandi"
  ],
  "elven helmet of eminent alteration": [
    "Alfa Galea Eminentis Mutationis"
  ],
  "ingun's alchemy chest": [
    "Arca Ingunis Alchemiae"
  ],
  "shrouded gloves": [
    "Umbrata Manubria"
  ],
  "ice wolf pelt": [
    "Glaciei Lupi Reno"
  ],
  "glass warhammer of banishing": [
    "Bellimaleus Vitreus Expellendi"
  ],
  "db recurringtarget 3 crimefaction": [
    "DB RecurringTarget 3 CrimeFaction"
  ],
  "tiber septim's room": [
    "Cubiculum Tiberii Septimi"
  ],
  "elgrim's elixirs": [
    "Elgrimis Permixta"
  ],
  "argonian account, book 3": [
    "Historia Argonia, Book 3"
  ],
  "carriage system vendors": [
    "Carriage System Vendors"
  ],
  "virtuous glass sword": [
    "Virtutis Gladius Vitreus"
  ],
  "do you owe allegiance to the imperials": [
    "Favesne Romanis"
  ],
  "wuuthrad": [
    "Vuuthrad"
  ],
  "coven": [
    "Grex Magorum"
  ],
  "bryling's house": [
    "Brylingi Domus"
  ],
  "call of the wild 1": [
    "Call Of Wild 1"
  ],
  "i have important documents for you, sir": [
    "Documenta magni momenti tibi habeo"
  ],
  "ebony helmet of extreme magicka": [
    "Ebeni Galea Extremis Magicae"
  ],
  "glass greatsword of immobilizing": [
    "Spatha Vitrea Immobilitatis"
  ],
  "ulfric should turn over <alias=sonsminorhold2>": [
    "Vercingetorigi <Alias=SonsMinorHold2> dandum est"
  ],
  "femaleeyesdremora": [
    "FemaleEyesDremora"
  ],
  "glass dagger of binding": [
    "Pugio Vitreus Deligandi"
  ],
  "fur armor": [
    "Renonis Lorica"
  ],
  "report to general tullius": [
    "Refer te ad Imperatorem Tullium"
  ],
  "if anyone falls, it will be you": [
    "Si quis cadet, erit tu"
  ],
  "iron warhammer of binding": [
    "Bellimalleus Ferratus Deligandi"
  ],
  "blood\\decalsbloodsplatterblend01.dds": [
    "Blood\\DecalsBloodSplatterBlend01.dds"
  ],
  "hairfemaleelder03": [
    "HairFemaleElder03"
  ],
  "listen to mercer frey": [
    "Ausculta Mercer Frey"
  ],
  "ghorza gra-bagol": [
    "Ghorza gra-Bagol"
  ],
  "virtuous elven war axe": [
    "Virtutis Alfus Bellisecuris"
  ],
  "steel boots of lifting": [
    "Caligae Adamanteae Sumendi"
  ],
  "return to the sanctuary": [
    "Reveni ad Fanum"
  ],
  "soul captured": [
    "Animus captus"
  ],
  "what's haelga's story": [
    "Quid est fabula Haelgae"
  ],
  "spell tome: conjure flaming familiar": [
    "Liber Magicus: Voca Familiarem Ardentem"
  ],
  "ebony helmet of extreme restoration": [
    "Ebeni Galea Extremis Reficiendi"
  ],
  "daedric warhammer of damnation": [
    "Bellimalleus Daedricus Damnationis"
  ],
  "steel war axe of diminishing": [
    "Bellisecuris Adamanteus Diminuendi"
  ],
  "lami services": [
    "Lami Services"
  ],
  "full moon rising": [
    "Luna Plena Oriens"
  ],
  "i'm not really sure what to think": [
    "Nescio quid censere debeam"
  ],
  "ra'jirr's note": [
    "Epistula Ra'jirris"
  ],
  "anoriath is dead": [
    "Anoriath mortua est"
  ],
  "elven dagger of fire": [
    "Pugio Alfus Ignis"
  ],
  "nose color": [
    "Nasi Color"
  ],
  "imperial sword of soul snares": [
    "Gladius Romanus Animicapti"
  ],
  "gourmet's writ of passage": [
    "Mandatum Transmissionis Apicii"
  ],
  "humanbeard26": [
    "HumanBeard26"
  ],
  "mass paralysis": [
    "Mass Paralysis"
  ],
  "radio": [
    "Radio"
  ],
  "darkness returns post quest": [
    "Darkness Returns Post Quest"
  ],
  "scaled armor of health": [
    "Lorica Squamata Salutis"
  ],
  "that sounds acceptable": [
    "Satis mihi videtur"
  ],
  "whiterun bannered mare faction": [
    "Whiterun Bannered Mare Faction"
  ],
  "staff of magelight": [
    "Rudis Magilucis"
  ],
  "storm atronach abilities": [
    "Storm Atronach abilities"
  ],
  "dwarven dagger of harrowing": [
    "Dvemeris Pugio Occationis"
  ],
  "how about using calipers": [
    "Quidni forcipibus utaris"
  ],
  "you cannot change weapons while attacking": [
    "Non potes Arma mutare dum oppugnes"
  ],
  "iron greatsword of ice": [
    "Spatha Ferri Gelidus"
  ],
  "sarcophagus lid": [
    "Operculum Sarcophagi"
  ],
  "dwarven battleaxe of absorption": [
    "Dvemeris Bellisecuris Absorbendi"
  ],
  "ebony battleaxe of subsuming": [
    "Bellisecuris Ebeni Subsumendi"
  ],
  "expert grimoire": [
    "Expert Grimoire"
  ],
  "(hand him the axe)": [
    "(Da ei securem)"
  ],
  "virtuous ebony greatsword": [
    "Virtutis Spatha Ebeni"
  ],
  "dwarven warhammer of arcing": [
    "Dvemeris Bellimalleus Electricitatis"
  ],
  "falkreath guard's helmet": [
    "Falkretiae Custodis Galea"
  ],
  "dwarven battleaxe of ice": [
    "Dvemeris Bellisecuris Gelidus"
  ],
  "who were those other prisoners": [
    "Qui erant alii Captivi"
  ],
  "daedric sword of annihilating": [
    "Gladius Daedricus Annihilationis"
  ],
  "i just want the key to your safe": [
    "Modo clavem ad arcam securem tuum volo"
  ],
  "breaching security": [
    "Praesidium Rumpens"
  ],
  "embershard": [
    "Embershard"
  ],
  "goat cheese wheel": [
    "Rota Capri Casei"
  ],
  "dwarven sword of harrowing": [
    "Gladius Dvemeris Occationis"
  ],
  "spiders": [
    "Spiders"
  ],
  "imperial light helmet of major conjuration": [
    "Romana Cassis Maioris Coniurationis"
  ],
  "last witness killed": [
    "Testis ultimus necatus"
  ],
  "dwarven mace of freezing": [
    "Dvemeris Maca Gelidus"
  ],
  "mixed unit tactics": [
    "Decursus Cohortium Mixtorum"
  ],
  "i haven't found the mask yet": [
    "Personam nondum inveni"
  ],
  "steel battleaxe of fiery souls": [
    "Bellisecuris Adamanteus of Fiery Souls"
  ],
  "madwoman": [
    "Insana"
  ],
  "orcish battleaxe of ice": [
    "Orci Bellisecuris Gelidus"
  ],
  "not everyone seems to want us here": [
    "Mihi videtur non omnes nos hic esse velle"
  ],
  "after i kill you, i'm going to kill the emperor": [
    "Post te necam, Imperatorem necam"
  ],
  "dessicated corpse": [
    "Corpus Siccatum"
  ],
  "necromancer's letter": [
    "Epistula Necromantici"
  ],
  "dragonscale gauntlets of eminent smithing": [
    "Draconisquamatae Manicae Maximi Faciendi"
  ],
  "dragonscale gauntlets of eminent wielding": [
    "Draconisquamatae Manicae Eminentis Tenendi"
  ],
  "retrieve the staff of magnus": [
    "Inveni Rudem Magni"
  ],
  "emblem v": [
    "Emblema V"
  ],
  "2920, frostfall, v10": [
    "[MMCMXX] 2920, Glacicasus, V. X"
  ],
  "use the key to gain access to the pelagius wing": [
    "Uti Clave ut Alam Pelagii Intres"
  ],
  "retrieve the dragonstone": [
    "Refer Draconilapidem"
  ],
  "troll abilities": [
    "Troll abilities"
  ],
  "trapper": [
    "Decipulator"
  ],
  "daedric sword of the sorcerer": [
    "Gladius Daedricus Magi"
  ],
  "blessed steel greatsword": [
    "Sanctificata Spatha Adamantea"
  ],
  "you're from cyrodiil": [
    "Roma venisti"
  ],
  "what's it like back home": [
    "Quid egisti domi"
  ],
  "cliffside retreat": [
    "Cliffside Retreat"
  ],
  "frontier, conquest": [
    "Fines, Triumphus"
  ],
  "elven helmet of major restoration": [
    "Alfa Galea Maioris Reficiendi"
  ],
  "poison of": [
    "Venenum"
  ],
  "why isn't the emperor attending the wedding": [
    "Quidni Imperator sponsal petat"
  ],
  "harrald": [
    "Harrald"
  ],
  "irkngthand consortium key": [
    "Clavis ad Consortium Irkngthand"
  ],
  "what happened with gleda again": [
    "Quid cum Gleda accidit"
  ],
  "i'm a little fuzzy on that": [
    "De eo incertus sum"
  ],
  "pilgrimage": [
    "Peregrinatio"
  ],
  "marcurio": [
    "Marcurio"
  ],
  "elven mace of reaping": [
    "Maca Alfa Saturni"
  ],
  "tell me the full story": [
    "Dic mihi totam fabulam"
  ],
  "why do you want alain dead": [
    "Cur mortem Alani vis"
  ],
  "daedric greatsword of freezing": [
    "Spatha Daedrica Gelidus"
  ],
  "conjurer's potion": [
    "Vocatoris Potio"
  ],
  "are you aware there's a plot to steal frost": [
    "De consilio Glaciei furari scis"
  ],
  "marksfemalehumanoid11leftgashr": [
    "MarksFemaleHumanoid11LeftGashR"
  ],
  "vivienne onis": [
    "Vivienne Onis"
  ],
  "eltrys' note": [
    "Epistula Eltris"
  ],
  "iron battleaxe of torpor": [
    "Bellisecuris Ferri Torporis"
  ],
  "dialogue faction for haafingar hold guards": [
    "Dialogue Faction for Haafingar Hold Guards"
  ],
  "i don't suppose the ramp is easy to access": [
    "Coniecto aggerem non facile usu esse"
  ],
  "skeleton key": [
    "Clavis Adulter"
  ],
  "marksfemalehumanoid03leftgash": [
    "MarksFemaleHumanoid03LeftGash"
  ],
  "the blades say you deserve to die": [
    "Enses dicunt te mori debere"
  ],
  "blades say you deserve to die": [
    "Enses dicunt te mori debere"
  ],
  "dragon seal": [
    "Signum Draconis"
  ],
  "acceptyield": [
    "AcceptYield"
  ],
  "dbastridsleepreacttion2topic": [
    "DBAstridSleepReacttion2Topic"
  ],
  "steel war axe of soul snares": [
    "Bellisecuris Adamanteus Animicapti"
  ],
  "contract: kill agnis": [
    "Munus: Neca Agnem"
  ],
  "lord vile, i have a request of you": [
    "Dominus, aliquid de te rogare volo"
  ],
  "astrid, you're being a bit... paranoid": [
    "Astrid, paulum... paranoid videris"
  ],
  "all right, i'll help": [
    "Bene, adiuvabo"
  ],
  "dragonscale shield of warmth": [
    "Scutum Draconisquamatum Caloris"
  ],
  "daedric battleaxe of animus": [
    "Bellisecuris Daedricus Animi"
  ],
  "dwarven boots of shock suppression": [
    "Dvemeres Caligae Suppresionis Electricitatis"
  ],
  "ebony mace of leeching": [
    "Maca Ebeni Sanguisugae"
  ],
  "sanctified steel greatsword": [
    "Sanctificata Spatha Adamantea"
  ],
  "psiijic gloves": [
    "Manubria Psijica"
  ],
  "key to nepos's house": [
    "Clavis ad Domum Nepotis"
  ],
  "nose type": [
    "Nasi Modus"
  ],
  "tell <alias.shortname=questgiver> the bandit is dead": [
    "Dic <Alias.ShortName=Questgiver> latronum mortuum esse"
  ],
  "shaircolor6": [
    "sHairColor6"
  ],
  "ancient nord battle axe of frost": [
    "Antiquus Bellisecuris Germanicus Gelidus"
  ],
  "atronach forge manual": [
    "Mandata Fabricae Atronachorum"
  ],
  "what's the catch": [
    "Quid est fraus"
  ],
  "dwarven mace of devouring": [
    "Dvemeris Maca Consumendi"
  ],
  "summerset shadows banner": [
    "Vexilum Umbrarum Summersetium"
  ],
  "glass sword of immobilizing": [
    "Gladius Vitreus Immobilitatis"
  ],
  "blood bowl": [
    "Patera Sanguinis"
  ],
  "i'll buy an amulet of mara": [
    "Monile Marae emam"
  ],
  "leather boots of major sneaking": [
    "Corii Caligae Maioris Repsendi"
  ],
  "blessed iron dagger": [
    "Sanctificatus Pugio Ferri"
  ],
  "ebony bow of fire": [
    "Arcus Ebeni Ignis"
  ],
  "i'm keeping the deed": [
    "Emptionem retineo"
  ],
  "any objections": [
    "Aliae contradictiones"
  ],
  "hefid the deaf": [
    "Hefid Surda"
  ],
  "lights out": [
    "Luces Exstinctae"
  ],
  "orcish sword of diminishing": [
    "Orci Gladius Diminuendi"
  ],
  "ebony helmet of eminent magicka": [
    "Ebeni Galea Eminentis Magicae"
  ],
  "iron boots of lifting": [
    "Ferri Caligae Sumendi"
  ],
  "jonna": [
    "Jonna"
  ],
  "kill cicero": [
    "Neca Ciceronem"
  ],
  "i'll take your test, then": [
    "Sic, probationem tuam faciam"
  ],
  "iron battleaxe of binding": [
    "Bellisecuris Ferri Deligandi"
  ],
  "i'll do whatever you ask": [
    "Faciam quidquid rogabis"
  ],
  "orcish warhammer of freezing": [
    "Orci Bellimalleus Gelidus"
  ],
  "ancient nord bow": [
    "Arcus Antiquus Germanicus"
  ],
  "bthardamz arcanex": [
    "Bthardamz Arcanex"
  ],
  "i can break some of your bones if that's what it's going to take... (brawl)": [
    "Nonnulla ossa fragere possum si vis... (Pugna Manibus)"
  ],
  "scaled bracers of wielding": [
    "Chirothecae Squamatae Tenendi"
  ],
  "that's just what the men called me": [
    "Homines modo me hoc appellant"
  ],
  "that button cannot be remapped": [
    "Hic boto mutari non potest"
  ],
  "marksfemalehumanoid09rightgash": [
    "MarksFemaleHumanoid09RightGash"
  ],
  "i am answering your summons": [
    "Vocationem tuam respondeo"
  ],
  "iron sword of sparks": [
    "Gladius Ferri Scintillarum"
  ],
  "predator's grace": [
    "Decor Venatoris"
  ],
  "mammoth abilities": [
    "Mammoth abilities"
  ],
  "blood horker revenge": [
    "Blood Horker Revenge"
  ],
  "ebony dagger of malediction": [
    "Pugio Ebeni Maledictionis"
  ],
  "vulthuryol": [
    "Vulthuryol"
  ],
  "are you some kind of god": [
    "Esne deus alicuius modi"
  ],
  "maleeyeshumandarkblue": [
    "MaleEyesHumanDarkBlue"
  ],
  "hairfemaleorc11": [
    "HairFemaleOrc11"
  ],
  "daedric helmet of extreme destruction": [
    "Galea Daedrica Extremis Destructionis"
  ],
  "you're talking about the literal end of the world": [
    "De Apocalypse vera loqueris"
  ],
  "scroll of pacify": [
    "Codex Pacis"
  ],
  "i found esbern, but he won't talk to me": [
    "Esbernem inveni, sed mecum non adloqueretur"
  ],
  "lesser magic resistance": [
    "Lesser Magic Resistance"
  ],
  "dwarven battleaxe of winnowing": [
    "Dvemeris Bellisecuris Ventagii"
  ],
  "eris": [
    "Eris"
  ],
  "what's with all the cloak and dagger": [
    "Cur tam furtiva arcanaque es"
  ],
  "daedric boots of shock abatement": [
    "Caligae Daedricae Contra Vim Electricam"
  ],
  "dwarven dagger of reaping": [
    "Dvemeris Pugio Saturni"
  ],
  "dragonplate shield of extreme blocking": [
    "Draconilaminatum Scutum Extremis Interponendi"
  ],
  "femaleeyeshumandemon": [
    "FemaleEyesHumanDemon"
  ],
  "glass mace of animus": [
    "Maca Vitrea Animi"
  ],
  "i need information about the alik'r": [
    "Informationem de Alik'ris quaero"
  ],
  "bash": [
    "Scuto Punga"
  ],
  "whiterun drunken huntsman vendor": [
    "Whiterun Drunken Huntsman Vendor"
  ],
  "orcish battleaxe of scorching": [
    "Orci Bellisecuris Adurendi"
  ],
  "hofgrir horse-crusher": [
    "Hofgrir Equi-Obrutor"
  ],
  "browsmalehumanoid03": [
    "BrowsMaleHumanoid03"
  ],
  "steel dagger of binding": [
    "Pugio Adamanteus Deligandi"
  ],
  "i work for the honor of the companions": [
    "Pro honore Contubernalium laboro"
  ],
  "i've never quite understood exactly what the justiciars do": [
    "Numquam quid Justiciares faciant intellexi"
  ],
  "f2m": [
    "F2m"
  ],
  "start game as %s": [
    "Incipe ludum ut %s"
  ],
  "elven mace of shocks": [
    "Maca Alfa Electricitatis"
  ],
  "spell tome: oakflesh": [
    "Liber Magicus: Roburipellis"
  ],
  "glass warhammer of despair": [
    "Bellimaleus Vitreus Desponsionis"
  ],
  "dragonscale helmet of eminent conjuring": [
    "Draconisquamata Galea Coniurationis Vocantis"
  ],
  "all standard armor": [
    "All Standard Armor"
  ],
  "who is this orthorn": [
    "Quis est hic Orthorn"
  ],
  "you think ulfric knows where that dragon came from": [
    "Putasne Vercingetorigem scire unde venit ille draco"
  ],
  "vampire pc faction": [
    "Vampire PC Faction"
  ],
  "dwarven dagger of gleaning": [
    "Dvemeris Pugio Saturni"
  ],
  "legate emmanuel admand": [
    "Legatus Emmanuellis Adamandus"
  ],
  "imperial order": [
    "Mandatum Imperiale"
  ],
  "how did you assemble this collection": [
    "Quomodo hanc collectionem fecisti"
  ],
  "dragonplate boots of fire suppression": [
    "Draconilaminata Caligae Suppressionis Ignis"
  ],
  "elven mace of debilitation": [
    "Maca Alfa Debilitationis"
  ],
  "marksfemalehumanoid04leftgash": [
    "MarksFemaleHumanoid04LeftGash"
  ],
  "aranea ienith": [
    "Aranea Ienith"
  ],
  "hairmaleorc08": [
    "HairMaleOrc08"
  ],
  "lucerne": [
    "Lucerne"
  ],
  "steel battleaxe of arcing": [
    "Bellisecuris Adamanteus Electricitatis"
  ],
  "staff of paralysis": [
    "Rudis Paralyticus"
  ],
  "mourner's hat": [
    "Petasus Maerentis"
  ],
  "glass helmet of major alteration": [
    "Galea Vitrea Maioris Mutationis"
  ],
  "glass shield of major blocking": [
    "Vitreum Scutum Maioris Interponendi"
  ],
  "steel boots of resist fire": [
    "Caligae Adamanteae Contra Vim Ignis"
  ],
  "glass helmet of extreme alchemy": [
    "Galea Vitrea Extremis Alchemiae"
  ],
  "equipped": [
    "manu tenes"
  ],
  "draught of illusion": [
    "Decocta Inlusionis"
  ],
  "mahk": [
    "Mahk"
  ],
  "storm call - bah": [
    "Storm Call - Bah"
  ],
  "ebony greatsword of terror": [
    "Spatha Ebeni Terroris"
  ],
  "ward - deflection": [
    "Praesidium Magicum - Deflectio"
  ],
  "glass boots of shock abatement": [
    "Vitreae Caligae Contra Vim Electricam"
  ],
  "elven bow of banishing": [
    "Arcus Alfus Expellendi"
  ],
  "set alpha to zero": [
    "Set Alpha To Zero"
  ],
  "let's take them out": [
    "Eamus eos necatum"
  ],
  "potion of pickpocketing": [
    "Potio Furandi"
  ],
  "player ally faction": [
    "Player Ally Faction"
  ],
  "i've heard there's trouble with your children": [
    "Audivi de difficultatibus cum liberis"
  ],
  "forsworn staff": [
    "Abiuratus Rudis"
  ],
  "the, um, little man really needs your help": [
    "Iste, um, parvulus auxilium tuum re vera poscit"
  ],
  "with his wagon": [
    "Cum plaustro"
  ],
  "so we're learning about wards": [
    "Ergo, de Scutis magicis discimus"
  ],
  "browsmalehumanoid01": [
    "BrowsMaleHumanoid01"
  ],
  "fur shoes": [
    "Renonis Caligulae"
  ],
  "creaturedialoguedwarvensphere": [
    "CreatureDialogueDwarvenSphere"
  ],
  "imperial sword of weariness": [
    "Gladius Romanus Maioris Defatigationis"
  ],
  "file does not exist": [
    "Data non exstat"
  ],
  "your item has insufficient charge": [
    "Res tua satis potestatis non habet"
  ],
  "cosnach": [
    "Cosnach"
  ],
  "love poem": [
    "Carmen Amoris"
  ],
  "why live alone on a mountain if you love conversation": [
    "Cur hic in monte vivis si collocutionem amas"
  ],
  "ebony warhammer of evoking": [
    "Bellimalleus Ebeni Evocandi"
  ],
  "i want to quit that fishing job": [
    "Nolo facere illum munus \"fishing\""
  ],
  "key to warmaiden's": [
    "Clavis ad Bellatrices"
  ],
  "orcish gauntlets of major sure grip": [
    "Orci Manicae Maioris Dexteritatis"
  ],
  "dialoguemarkarthsmelteromluaghathrasil01": [
    "DialogueMarkarthSmelterOmluagHathrasil01"
  ],
  "dwarven warhammer of shocks": [
    "Dvemeris Bellimalleus Electricitatis"
  ],
  "pelagius' flame thrall": [
    "Servus Flammarum Pelagii"
  ],
  "snake handle": [
    "Ansam Serpentis"
  ],
  "orcish helmet of extreme magicka": [
    "Orci Galea Extremis Magicae"
  ],
  "shipwreck loot": [
    "Gaza Naufragii"
  ],
  "lost knife cave": [
    "Perditus Culter Spelunca"
  ],
  "key to hjorunn's room": [
    "Clavis ad Conclave Hjorunnis"
  ],
  "orcish gauntlets of eminent alchemy": [
    "Orci Manicae Alchemiae Eminentis"
  ],
  "dwarven sword of winnowing": [
    "Gladius Dvemeris Ventagii"
  ],
  "potency: conjure storm atronach": [
    "Potentia: Voca Atronachum Electricitatis"
  ],
  "orcish gauntlets of major alchemy": [
    "Orci Manicae Maioris Alchemiae"
  ],
  "got haelga's payment here": [
    "Debitum Haelgae hic habeo"
  ],
  "it isn't safe. wait here": [
    "Tutum non est. Hic exspecta"
  ],
  "daedric battleaxe of evoking": [
    "Bellisecuris Daedricus Evocandi"
  ],
  "dragonscale shield of fire suppression": [
    "Scutum Draconisquamatum Suppressionis Ignis"
  ],
  "indolent farmer": [
    "Agricola Indolens"
  ],
  "apple pie": [
    "Malum Torta"
  ],
  "i stopped some necromancers from summoning potema": [
    "Necromanticos necavi ne Potemam vocarent"
  ],
  "bleak falls temple": [
    "Templum Cataractarum Obscurarum"
  ],
  "i'm not sure i want to get involved in this": [
    "Nescio num partem in hoc habere velim"
  ],
  "luna moth wing": [
    "Ala Papilionis Lunae"
  ],
  "move gulum-ei to cave": [
    "Move Gulum-Ei to Cave"
  ],
  "alessandra's dagger": [
    "Pugio Alessandrae"
  ],
  "orcish dagger of siphoning": [
    "Orci Pugio Siphonis"
  ],
  "on oblivion": [
    "De Oblivione"
  ],
  "daedric dagger of freezing": [
    "Pugio Daedricus Gelidus"
  ],
  "endarie": [
    "Endarie"
  ],
  "dwarven sword of cowardice": [
    "Gladius Dvemeris Timoris"
  ],
  "i would think you'd have more respect for a nightingale": [
    "Credidi te plus Philomelas respicere"
  ],
  "greater magic resistance": [
    "Greater Magic Resistance"
  ],
  "steel plate boots": [
    "Laminatae Caligae"
  ],
  "letrush already agreed to pay me": [
    "Letrush iam mihi pecuniam promissus est"
  ],
  "i'd like to hear it all one more time": [
    "Volo omnia iterum audire"
  ],
  "college of winterhold": [
    "Collegium Hiemiterrae"
  ],
  "hallowed elven bow": [
    "Consacratus Arcus Alfus"
  ],
  "what did you want my help with": [
    "Cum quo auxilium meum voluisti"
  ],
  "alchemist's journal": [
    "Ephemeris Alchemistae"
  ],
  "you cannot change armor while attacking": [
    "Non potes Arma mutare dum oppugnes"
  ],
  "iron greatsword of sparks": [
    "Spatha Ferri Scintillarum"
  ],
  "glass helmet of major alchemy": [
    "Galea Vitrea Maioris Alchemiae"
  ],
  "dragonscale helmet of extreme alteration": [
    "Draconisquamata Galea Extremis Mutationis"
  ],
  "potent aversion to fire": [
    "Potens Aversio Igni"
  ],
  "we need to find the staff of magnus": [
    "Rudem Magnum invenire debemus"
  ],
  "this save relies on content that is no longer present": [
    "This save relies on content that is no longer present"
  ],
  "some objects may no longer be available": [
    "Some objects may no longer be available"
  ],
  "continue loading": [
    "Continue Loading"
  ],
  "scroll of repel lesser undead": [
    "Codex Amortuos Minores Repellendi"
  ],
  "i was mistaken": [
    "Erravi"
  ],
  "i have news from helgen about the dragon attack": [
    "Nuntium a Helgene de oppugnato draconis habeo"
  ],
  "you do not have enough room to drop this object": [
    "Non satis spatium habes ut hanc rem deponere possis"
  ],
  "potent aversion to magic": [
    "Potens Aversio Magicae"
  ],
  "riften keep alternate scene 01": [
    "Hiati Keep Alternate Scene 01"
  ],
  "heart of winter": [
    "Cor Hiemis"
  ],
  "hjaalmarch imperial camp": [
    "Hjallmarci Castra Romana"
  ],
  "fur helmet": [
    "Renonis Galea"
  ],
  "i've completed the shill job": [
    "Munus \"shill\" perfeci"
  ],
  "wreck of the pride of tel vos": [
    "Naufragium Exaltationis Tel Vos"
  ],
  "helgen homestead": [
    "Helgenis Casa"
  ],
  "margret": [
    "Margarita"
  ],
  "deepwood vale": [
    "Nemus Lignaltus"
  ],
  "report astrid's death to a guard": [
    "Refer Mortem Astridis ad Custodes"
  ],
  "there's a town nearby in that direction. do you need help getting there": [
    "Oppidum in illa regione est. Auxilium in id petendo vis"
  ],
  "scroll of turn lesser undead": [
    "Codex Mortuos Minores Repellendi"
  ],
  "dwarven shield of flame suppression": [
    "Dvemere Scutum Suppressionis Flammarum"
  ],
  "dwarven shield of dwindling fire": [
    "Dvemere Scutum Minoris Ignis"
  ],
  "i am honored to be a part of your family, astrid": [
    "Honor mea erit pars familiae tuae esse, Astrid"
  ],
  "elven shield of waning fire": [
    "Alfum Scutum Insenescentis Ignis"
  ],
  "south skybound watch": [
    "Australis Caelibundus Visus"
  ],
  "legate taurinus duilis": [
    "Legatus Taurinus Duilius"
  ],
  "elven mace": [
    "Maca Alfa"
  ],
  "blessed iron greatsword": [
    "Sanctificata Spatha Ferri"
  ],
  "voicepowerstartshort": [
    "VoicePowerStartShort"
  ],
  "key to corpselight farm": [
    "Clavis ad Fundum Corpilucis"
  ],
  "elven battleaxe of evoking": [
    "Bellisecuris Alfus Evocandi"
  ],
  "faction for guards in front": [
    "faction for guards in front"
  ],
  "iron sword of shocks": [
    "Gladius Ferri Electricitatis"
  ],
  "nightmare ability": [
    "Nightmare Ability"
  ],
  "elven warhammer of immobilizing": [
    "Alfus Bellimaleus Immobilitatis"
  ],
  "whiterun olava the feeble's house faction": [
    "Whiterun Olava the Feeble's House Faction"
  ],
  "hairmaleorc06": [
    "HairMaleOrc06"
  ],
  "daedric war axe": [
    "Bellisecuris Daedricus"
  ],
  "menus": [
    "Menus"
  ],
  "iron helmet of restoration": [
    "Ferri Galea Recreandi"
  ],
  "northwatch keep key": [
    "Clavis ad Castellum Septentriovisus"
  ],
  "why would they teach me to shout if they don't want me to use it": [
    "Cur me clamationem docerent nisi me illa uti velint"
  ],
  "gunnar stone-eye": [
    "Gunnar Oculi-Saxi"
  ],
  "sword stand": [
    "Loculumentum Gladii"
  ],
  "fort greenwall captain's quarters": [
    "Castellum Viridivallis"
  ],
  "bolar's writ": [
    "Praescriptum Bolaris"
  ],
  "rise in the east": [
    "Ortus in Eure"
  ],
  "studded imperial armor of major health": [
    "Romana Lorica Ornata Maioris Salutis"
  ],
  "dwarven sword of weariness": [
    "Gladius Dvemeris Maioris Defatigationis"
  ],
  "daedric greatsword of the sorcerer": [
    "Spatha Daedrica Magi"
  ],
  "the bandit leader at <alias=bountylocation> is dead. i'm here for the bounty": [
    "Dux Latronum in <Alias=BountyLocation> mortuus est. Hic sum praemio"
  ],
  "bandit leader at <alias=bountylocation> is dead. i'm here for the bounty": [
    "Dux Latronum in <Alias=BountyLocation> mortuus est. Hic sum praemio"
  ],
  "freed - ready to follow player": [
    "Freed - ready to follow player"
  ],
  "clear skies 3": [
    "Clear Skies 3"
  ],
  "glass warhammer of evoking": [
    "Bellimaleus Vitreus Evocandi"
  ],
  "no, i didn't see them in there": [
    "Minime, eos in illo loco non vidi"
  ],
  "dragonplate gauntlets of eminent smithing": [
    "Draconilaminatae Manicae Maximi Faciendi"
  ],
  "five songs of king wulfharth": [
    "Quinqu Carmina Regis Vulfharth"
  ],
  "okay, how much do i owe you": [
    "Ok, quantum tibi debeo"
  ],
  "hallowed elven greatsword": [
    "Consacrata Spatha Alfa"
  ],
  "arcane blacksmith": [
    "Ferrarius Arcanus"
  ],
  "riposte": [
    "Riposte"
  ],
  "i've found nettlebane for you": [
    "Nettlebane tibi inveni"
  ],
  "quill": [
    "Penna"
  ],
  "sven and hilde's house": [
    "Domus Svenis Hildisque Domus"
  ],
  "i have fjotli's locket": [
    "Anulum Fjotli tibi habeo"
  ],
  "gulum-ei player friend faction": [
    "Gulum-Ei Player Friend Faction"
  ],
  "don't say i didn't warn you": [
    "Noli dicere me te non monere"
  ],
  "arch-mage": [
    "Arch-Mage"
  ],
  "imperial bow": [
    "Arcus Imperialis"
  ],
  "deadly recovery poison": [
    "Letifer Venenum Staminam Recuperandiis"
  ],
  "snow-shod farm house faction": [
    "Snow-Shod Farm House Faction"
  ],
  "virtuous elven dagger": [
    "Virtutis Pugio Alfus"
  ],
  "snowpoint beacon": [
    "Nivapex Signum"
  ],
  "sam guevenne": [
    "Samuelis Guevenne"
  ],
  "habd's remains": [
    "Habdis Reliquia"
  ],
  "daedric war axe of annihilating": [
    "Bellisecuris Daedricus Annihilationis"
  ],
  "glass boots of strength": [
    "Vitreae Caligae Fortitudinis"
  ],
  "hunting bow of souls": [
    "Arcus Venandi Animorum"
  ],
  "makes them more suspicious in dialogue": [
    "Makes them more suspicious in dialogue"
  ],
  "kjeld": [
    "Kjeld"
  ],
  "db recurringtarget 5 crimefaction": [
    "DB RecurringTarget 5 CrimeFaction"
  ],
  "where can i learn new spells": [
    "Ubi possum magicam novam discere"
  ],
  "orcish warhammer of thunderbolts": [
    "Orci Bellimalleus Fulminum"
  ],
  "astridconfrontlistenerresponse1": [
    "AstridConfrontAuditorResponse1"
  ],
  "sure, i'll match your bet": [
    "Certe, pignus tuum aequabo"
  ],
  "daedric bow of terror": [
    "Arcus Daedricus Terroris"
  ],
  "orcish sword of harvesting": [
    "Orci Gladius Saturni"
  ],
  "dialoguesoljundsminescene01": [
    "DialogueSoljundsMineScene01"
  ],
  "i don't like being ignored": [
    "Nolo mihi ignorari"
  ],
  "retry": [
    "Iterum Tempta"
  ],
  "dark souls dread zombie": [
    "Dark Souls Dread Zombie"
  ],
  "glass war axe of torpor": [
    "Bellisecuris Vitreus Torporis"
  ],
  "south wind's prayer": [
    "Lupercalia"
  ],
  "imperial helmet of major illusion": [
    "Romana Galea Maioris Inlusionis"
  ],
  "dialoguelefthandminedaighreerith02": [
    "DialogueLeftHandMineDaighreErith02"
  ],
  "dragonscale helmet of peerless illusion": [
    "Draconisquamata Galea Maximi Inlusionis"
  ],
  "groupstrategy": [
    "GroupStrategy"
  ],
  "weak aversion to magic": [
    "Aversio Debilis Magicae"
  ],
  "tell me about the goldenglow job": [
    "Dic mihi de munere Aurilucis"
  ],
  "spell tome: repel undead": [
    "Liber Magicus: Expelle Amortuos"
  ],
  "pm": [
    "PM"
  ],
  "door to degaine's room": [
    "Ianuam ad conclave Degainis"
  ],
  "scaled boots of flame suppression": [
    "Caligae Squamatae Suppressionis Flammarum"
  ],
  "reverent iron greatsword": [
    "Reverens Spatha Ferri"
  ],
  "who else is looking for esbern": [
    "Quem praeter me Esbernem petit"
  ],
  "you misunderstand": [
    "Non intellegis"
  ],
  "for i am... the gourmet": [
    "Quod ego sum... Apicius"
  ],
  "briarheart geis": [
    "Geis Cordumi"
  ],
  "hide armor": [
    "Renonis Lorica"
  ],
  "steel dagger of burning": [
    "Pugio Adamanteus Cremandi"
  ],
  "actually, letrush wants me to steal the horse": [
    "Re vera, Letrush me equum furari vult"
  ],
  "we certainly do": [
    "Certe"
  ],
  "why'd you send me if you weren't going to believe me": [
    "Cur me miseris nisi mihi credas"
  ],
  "hairfemaleorc03": [
    "HairFemaleOrc03"
  ],
  "a friend to the orcs": [
    "Amicus Orcis"
  ],
  "friend to the orcs": [
    "Amicus Orcis"
  ],
  "restocking soul gems": [
    "Gemmanimas Replens"
  ],
  "how can you be sure your son is alive": [
    "Quomodo filium tuum vivum esse scire potes"
  ],
  "key to fathendas house": [
    "Clavis ad Domum Fathendas"
  ],
  "wujeeta": [
    "Vujeeta"
  ],
  "sprigganfx ability": [
    "SprigganFX Ars"
  ],
  "noble clothes": [
    "Nobilia Vestimenta"
  ],
  "i said a giant's toe": [
    "Dixi digitum gigantis"
  ],
  "do not question the gourmet": [
    "Noli Apicium interroga"
  ],
  "daedric mace of enervating": [
    "Maca Daedrica Enervationis"
  ],
  "frost fall": [
    "October"
  ],
  "orla": [
    "Orla"
  ],
  "note to rodulf": [
    "Epistula ad Rudolphum"
  ],
  "ebony dagger of immobilizing": [
    "Pugio Ebeni Immobilitatis"
  ],
  "glass sword of enervating": [
    "Gladius Vitreus Enervationis"
  ],
  "potion of regeneration": [
    "Potio Recuperandi"
  ],
  "speak to maramal about arranging your wedding": [
    "Adloquere Maramalem de Nuptiis Tuis"
  ],
  "sanctified orcish bow": [
    "Sanctificatus Arcus Orci"
  ],
  "ebony war axe of petrifying": [
    "Bellisecuris Ebeni Lapidescendi"
  ],
  "active effects": [
    "Effecta Activa"
  ],
  "femaleeyesorcdarkgrey": [
    "FemaleEyesOrcDarkGrey"
  ],
  "hours": [
    "Horae"
  ],
  "elixir of waterbreathing": [
    "Commixtum Respirandi Sub Aqua"
  ],
  "glass sword of malediction": [
    "Gladius Vitreus Maledictionis"
  ],
  "haafingar stormcloak camp": [
    "Haafingaris Sturmmantelorum Castra"
  ],
  "faction to thank player for killing rival": [
    "Faction to thank player for killing Rival"
  ],
  "follow maurice": [
    "Sequere Maurice"
  ],
  "can you tell me more about wards": [
    "Potesne mihi plus de Praesidia Magica narrare"
  ],
  "riverwood faendals house faction": [
    "Riverwood Faendals House Faction"
  ],
  "i could return the ring for you": [
    "Tibi anulum reddere possim"
  ],
  "your attack has no effect": [
    "Ictus tuus nihil fecit"
  ],
  "letter from a friend": [
    "Epistula ab Amico"
  ],
  "wolf helmet": [
    "Lupi Galea"
  ],
  "command daedra": [
    "Impera Daedrae"
  ],
  "daedric helmet of peerless illusion": [
    "Galea Daedrica Maximi Inlusionis"
  ],
  "haelga's bunkhouse": [
    "Haelga's Bunkhouse"
  ],
  "curalmil": [
    "Curalmil"
  ],
  "woodelfskin01": [
    "WoodElfSkin01"
  ],
  "cooked beef": [
    "Carnis Coctus"
  ],
  "why did you join the companions": [
    "Cur Contubernales adiunxisti"
  ],
  "why aren't you getting much sleep": [
    "Cur non bene dormis"
  ],
  "dbnazirplayermoneycomeclean": [
    "DBNazirPlayerMoneyComeClean"
  ],
  "job's finished": [
    "Munus factum est"
  ],
  "hunting bow of diminishing": [
    "Arcus Venandi Diminuendi"
  ],
  "orcish warhammer of devouring": [
    "Orci Bellimalleus Consumendi"
  ],
  "you must exit and restart skyrim for changes in this setting to take effect": [
    "You must exit et restart Caelifinis for changes in this setting to take effect"
  ],
  "dwarves, v1": [
    "Pumilliones, I"
  ],
  "syncope sanctuary key": [
    "Clavis Fanum Syncope"
  ],
  "(optional) protect golldir": [
    "(Optio) Obtege Golldirem"
  ],
  "what's dangerous about daedra": [
    "Quid est periculum cum daedris"
  ],
  "plans for what": [
    "Rationes pro quo"
  ],
  "enthir": [
    "Enthir"
  ],
  "reeking tower": [
    "Odorativus Turris"
  ],
  "gallus's encoded journal": [
    "Ephemeris Galli Cryptographicus"
  ],
  "iron dagger of embers": [
    "Pugio Ferri Cinerum"
  ],
  "key to soljund's miner's house": [
    "Clavis ad Domum Cuniculariorum Metalli Soljundis"
  ],
  "is the edda complete now that you have king olaf's verse": [
    "Estne Edda nunc completa quod Carmen Regis Olafis nunc habes"
  ],
  "key to endon's house": [
    "Clavis ad Domum Endonis"
  ],
  "key to eltrys's room": [
    "Clavis ad Conclave Eltris"
  ],
  "key to kharag's room": [
    "Clavis ad Conclave Kharagis"
  ],
  "bound axe": [
    "Bellisecuris Vinctus"
  ],
  "i'm not here to talk": [
    "Non hic sum loquendi causa"
  ],
  "i'm here for those documents": [
    "Hic pro documentis sum"
  ],
  "frightened woman": [
    "Femina Timida"
  ],
  "merchant's journal": [
    "Ephemeris Mercatoris"
  ],
  "nchuand-zel control": [
    "Nchuand-Zel Moderator"
  ],
  "why are these mages in fellglow keep": [
    "Cur sunt magi in Castello Obscurilucis"
  ],
  "middentorch": [
    "MiddenTorch"
  ],
  "fish school spawner": [
    "Fish School Spavner"
  ],
  "scaled boots of shock suppression": [
    "Caligae Squamatae Suppresionis Electricitatis"
  ],
  "key to castle dour": [
    "Clavis ad Castellum Durum"
  ],
  "key to solitude stables": [
    "Clavis ad Catabulum Solitudinis"
  ],
  "glass helmet of major illusion": [
    "Galea Vitrea Maioris Inlusionis"
  ],
  "spell tome: repel lesser undead": [
    "Liber Magicus: Repelle Minorem Amortuum"
  ],
  "how are you settling into your new role": [
    "Quomodo tibi placet tua pars nova"
  ],
  "are you sure about this": [
    "Esne certus"
  ],
  "dwarven resistances": [
    "Dwemeris resistances"
  ],
  "elixir of the warrior": [
    "Commixtum Bellatoris"
  ],
  "glass warhammer of reaping": [
    "Bellimaleus Vitreus Saturni"
  ],
  "dravin's bow": [
    "Arcus Dravinis"
  ],
  "silver jeweled necklace": [
    "Argentei Gemmatum Monile"
  ],
  "daedric warhammer of malediction": [
    "Bellimalleus Daedricus Maledictionis"
  ],
  "dragonscale gauntlets of extreme archery": [
    "Draconisquamatae Manicae Extremis Saggitationis"
  ],
  "hairlinefemaleorc11": [
    "HairLineFemaleOrc11"
  ],
  "frabbi": [
    "Frabbi"
  ],
  "glass warhammer of consuming": [
    "Bellimaleus Vitreus Consumendi"
  ],
  "fire salts": [
    "Sal Ignis"
  ],
  "grelod the kind": [
    "Grelod Benigna"
  ],
  "torvar": [
    "Torvar"
  ],
  "wild mammoth": [
    "Vild Mammoth"
  ],
  "player horse faction": [
    "Player Horse Faction"
  ],
  "christer": [
    "Christer"
  ],
  "elven battleaxe of lethargy": [
    "Bellisecuris Alfus Lethargiae"
  ],
  "steel sword of torpor": [
    "Gladius Adamanteus Torporis"
  ],
  "turn off 360 controller in the controls section of the settings menu to switch back to keyboard/mouse": [
    "Turn off 360 Controller in Controls section of Settings menu to switch back to Keyboard/Mouse"
  ],
  "steel gauntlets of alchemy": [
    "Adamanteae Manicae Alchemiae"
  ],
  "potent storm atronach": [
    "Potens Atronachum Tempestatis"
  ],
  "ebony greatsword of subsuming": [
    "Spatha Ebeni Subsumendi"
  ],
  "weapon case": [
    "Armarium"
  ],
  "how are you to blame": [
    "Quomodo culpa tua esset"
  ],
  "this sounds too risky": [
    "Nimis This periculosus mihi videtur"
  ],
  "scaled helmet of major conjuration": [
    "Galea Squamata Maioris Coniurationis"
  ],
  "um... the one where you saw a lady in half": [
    "Um... illa in qua feminam in duas partes serra divides"
  ],
  "glass war axe of expelling": [
    "Bellisecuris Vitreus Expellendi"
  ],
  "reverent dwarven bow": [
    "Reverens Arcus Dvemeris"
  ],
  "elven shield of flame suppression": [
    "Alfum Scutum Suppressionis Flammarum"
  ],
  "dragonplate boots of the mammoth": [
    "Draconilaminata Caligae Elephantis"
  ],
  "weak recovery poison": [
    "Venenum Debile Staminam Recuperandiis"
  ],
  "haelga": [
    "Haelga"
  ],
  "elven mace of harrowing": [
    "Maca Alfa Occationis"
  ],
  "the serpent doomstone": [
    "The Serpent Doomstone"
  ],
  "serpent doomstone": [
    "The Serpent Doomstone"
  ],
  "cage door": [
    "Porta Carceris"
  ],
  "platter": [
    "Ferculum"
  ],
  "aerin": [
    "Aerin"
  ],
  "hair color": [
    "Capillis Color"
  ],
  "sabine nytte": [
    "Sabine Nytte"
  ],
  "lisette": [
    "Lisette"
  ],
  "ataf": [
    "Ataf"
  ],
  "steel dagger of sparks": [
    "Pugio Adamanteus Scintillarum"
  ],
  "erikur": [
    "Erikur"
  ],
  "beirand": [
    "Beirand"
  ],
  "night mother's coffin": [
    "Sarcophagus Matris Noctis"
  ],
  "imperial light helmet of minor alchemy": [
    "Romana Cassis Minoris Alchemiae"
  ],
  "key to the temple of dibella": [
    "Clavis ad Templum Dibellae"
  ],
  "femaleheadredguard": [
    "FemaleHeadRedguard"
  ],
  "atahbah": [
    "Atahbah"
  ],
  "learn the word of power from wulfgar": [
    "Disce Verbum Potestatis a Wulfgar"
  ],
  "key to vittoria vici's house": [
    "Clavis ad Domum Vittoriae Vici"
  ],
  "dwarven warhammer of torpor": [
    "Dvemeris Bellimalleus Torporis"
  ],
  "clear skies 2": [
    "Clear Skies 2"
  ],
  "atheron residence key": [
    "Clavis ad Domum Atheronis"
  ],
  "verner and anneke's house key": [
    "Clavis ad Domum Verneris et Annekis"
  ],
  "mercer's house key": [
    "Clavis ad Domum Merceris"
  ],
  "master of the mind": [
    "Dominus Mentis"
  ],
  "keerava's key": [
    "Clavis Keeravae"
  ],
  "irkngthand arcanex key": [
    "Clavis ad Arcanex Irkngthand"
  ],
  "key to the mortar and pestle": [
    "Clavis ad Mortarium et Pistillum"
  ],
  "glass bow of enervating": [
    "Arcus Vitreus Enervationis"
  ],
  "karan's journal": [
    "Ephemeris Karanis"
  ],
  "key to severio pelagia's house": [
    "Clavis ad Domum Severii Pelagiae"
  ],
  "key to ysolda's house": [
    "Clavis ad Domum Ysoldae"
  ],
  "report back to festus krex": [
    "Refer te ad Festum Krex"
  ],
  "restore stamina - supernatural": [
    "Restore Stamina - Supernatural"
  ],
  "for shelly": [
    "Pro Shelly"
  ],
  "invocation of azura": [
    "Invocatio Azurae"
  ],
  "imperial bow of binding": [
    "Imperialis Arcus Deligandi"
  ],
  "elven war axe of ice": [
    "Alfus Bellisecuris Gelidus"
  ],
  "soul squeezer": [
    "Animi Compulsor"
  ],
  "what's your preferred method of assassination, astrid": [
    "Quid est modus operandi tuus, Astrid"
  ],
  "i took care of that group of silver hand": [
    "Illam gregem Manus Argenteae necavi"
  ],
  "hairlinemalenord9": [
    "HairLineMaleNord9"
  ],
  "key to alvor's house": [
    "Clavis ad Domum Alvoris"
  ],
  "mythic dawn gloves": [
    "Aurorae Mythicae Manubria"
  ],
  "%s has worn off": [
    "%s non iam te afficit"
  ],
  "push effect middle": [
    "Push Effect Middle"
  ],
  "malign lingering poison": [
    "Malignum Commorans Venenum"
  ],
  "arondil": [
    "Arondil"
  ],
  "dwarven bow of fear": [
    "Dvemeris Arcus Timoris"
  ],
  "warrior's festival": [
    "Dies Actii"
  ],
  "sorry, i can't help": [
    "Ignosce, adiuvare non possum"
  ],
  "honey nut treat": [
    "Mel Nux Confectum"
  ],
  "red apple": [
    "Rubrum Malum"
  ],
  "ice wraith bane": [
    "Phantasmae Glaciei Bane"
  ],
  "broken steel sword blade": [
    "Fractus Gladius Adamanteus Ensis"
  ],
  "that key cannot be remapped": [
    "Hoc plectrum mutari non potest"
  ],
  "malign recovery poison": [
    "Malignum Venenum Staminam Recuperandiis"
  ],
  "hairmalenord12": [
    "HairMaleNord12"
  ],
  "mralki's chest": [
    "Arca Mralki"
  ],
  "the thalmor know nothing about the dragons": [
    "Visigothi nihil de draconibus scuint"
  ],
  "thalmor know nothing about the dragons": [
    "Visigothi nihil de draconibus scuint"
  ],
  "dwarven bow of arcing": [
    "Dvemeris Arcus Electricitatis"
  ],
  "i'll talk to your parents about it": [
    "Adloquar patres tuos de eo"
  ],
  "potent fear poison": [
    "Potens Venenum Timoris"
  ],
  "aversion to magic": [
    "Aversio Magicae"
  ],
  "elven mace of fear": [
    "Maca Alfa Timoris"
  ],
  "hairmaleredguardchild01": [
    "HairMaleRedguardChild01"
  ],
  "weak lingering poison": [
    "Venenum Debile Commorante"
  ],
  "weak aversion to fire": [
    "Aversio Debilis Igni"
  ],
  "weak frenzy poison": [
    "Venenum Insanitatis Debile"
  ],
  "leather helmet of minor archery": [
    "Cutis Galea Minoris Saggitationis"
  ],
  "you cannot wait while taking health damage": [
    "Expectare non potes dum vulnus salutis accipias"
  ],
  "potion of extreme healing": [
    "Potio Extremis Salutis"
  ],
  "the importance of where": [
    "Gravitas Locationis"
  ],
  "importance of where": [
    "Gravitas Locationis"
  ],
  "finished being absorbed": [
    "Finished Being Absorbed"
  ],
  "maleeyeswoodelflightbrown": [
    "MaleEyesWoodElfLightBrown"
  ],
  "scaled helmet of eminent illusion": [
    "Galea Squamata Eminentis Inlusionis"
  ],
  "iron greatsword of chills": [
    "Spatha Ferri Frigidis"
  ],
  "read the elder scroll at the time-wound": [
    "Lege Codicem Veterrimum ad Vulnus-Temporis"
  ],
  "elixir of alteration": [
    "Commixtum Mutationis"
  ],
  "potion of extended invisibility": [
    "Potio Extensae Invisibilitatis"
  ],
  "let tullius have his way on this": [
    "Da Tullio id quod nunc quaeret"
  ],
  "are you ready to host the peace council": [
    "Esne paratus concilium pacis hic habere"
  ],
  "what is it arniel wanted you to get": [
    "Quid quaerit Arniel"
  ],
  "hallowed daedric sword": [
    "Consacratus Gladius Daedricus"
  ],
  "deep-in-his-cups": [
    "Altus-in-poculis"
  ],
  "draught of extra magicka": [
    "Decocta Maioris Magicae"
  ],
  "maleeyesdarkelfdeepred": [
    "MaleEyesDarkElfDeepRed"
  ],
  "philter of health": [
    "Philtrum Salutis"
  ],
  "hallowed daedric war axe": [
    "Consacratus Bellisecuris Daedricus"
  ],
  "viola giordano's house key": [
    "Clavis ad Domum Violae Giordanis"
  ],
  "draught of resist fire": [
    "Decocta Resistendi Ignem"
  ],
  "large snow spider abilities": [
    "Large Snow Spider abilities"
  ],
  "iron shield of waning shock": [
    "Ferri Scutum Insenescentis Electricitatis"
  ],
  "nord mead": [
    "Hydromellum Germanicum"
  ],
  "adelaisa vendicci": [
    "Adelaisa Vendicci"
  ],
  "steel mace of draining": [
    "Maca Adamantea Hauriendi"
  ],
  "humanbeard01shaved": [
    "HumanBeard01Shaved"
  ],
  "what did you need me to get": [
    "Quid me invenire voluisti"
  ],
  "iron mace of cowardice": [
    "Ferri Maca Timoris"
  ],
  "isabelle's letter": [
    "Epistula Isabellae"
  ],
  "cage": [
    "Carcer"
  ],
  "ebony dagger of the sorcerer": [
    "Pugio Ebeni Magi"
  ],
  "illusion trainer": [
    "Illusion Trainer"
  ],
  "augur of dunlain": [
    "Augur Dunlanis"
  ],
  "you lost": [
    "Victus es"
  ],
  "give me my money": [
    "Da mihi pecuniam meam"
  ],
  "philter of destruction": [
    "Philtrum Destructionis"
  ],
  "fort sungard shrine": [
    "Ara Castelli Auroris"
  ],
  "what do you know about it": [
    "Quid de eo scis"
  ],
  "unceasing stamina poison": [
    "Venenum Interminans Staminae"
  ],
  "journeyman retort": [
    "Journeyman Retort"
  ],
  "sliced goat cheese": [
    "Sectus Capri Casei"
  ],
  "ring of pure mixtures": [
    "Anulus Admixturarum Purarum"
  ],
  "iron mace of weariness": [
    "Ferri Maca Maioris Defatigationis"
  ],
  "off": [
    "EXTINCTUM"
  ],
  "ebony armor of extreme health": [
    "Ebeni Lorica Extremis Salutis"
  ],
  "vokun's throne room": [
    "Vokunis Solii Conclave"
  ],
  "orcish warhammer": [
    "Orci Bellimalleus"
  ],
  "light fade": [
    "Evanidus Luminis"
  ],
  "i must speak to someone named amaund motierre, in volunruud": [
    "Opus est mihi Amaund Motierre adloqui, in Volunruud"
  ],
  "dwarven sword of arcing": [
    "Gladius Dvemeris Electricitatis"
  ],
  "talk to farengar": [
    "Adloquere Farengar"
  ],
  "a stalemate": [
    "Certamen aequum"
  ],
  "stalemate": [
    "Certamen aequum"
  ],
  "hairlinemalenord7": [
    "HairLineMaleNord7"
  ],
  "i'm looking for an elven mage who studies stars": [
    "Magum Alfum qui operam in stellis dat quaero"
  ],
  "elven mace of devouring": [
    "Maca Alfa Consumendi"
  ],
  "no, why would i": [
    "Minime, cur"
  ],
  "imperial sword of scorching": [
    "Gladius Romanus Adurendi"
  ],
  "warehouse master package ml": [
    "Horreum Master Package ML"
  ],
  "potion of vigorous magicka": [
    "Potio Vigentis Magicae"
  ],
  "valthume catacombs": [
    "Valthumis Catacumbae"
  ],
  "wait, where are we": [
    "Exspecta, ubi sumus"
  ],
  "hairmaleorc23": [
    "HairMaleOrc23"
  ],
  "you don't pay, bad things happen": [
    "Nisi pecuniam solvas, res malas accidant"
  ],
  "sleeping tree sap": [
    "Olibanum Arboris Dormientis"
  ],
  "elven battleaxe of binding": [
    "Bellisecuris Alfus Deligandi"
  ],
  "daedric dagger of animus": [
    "Pugio Daedricus Animi"
  ],
  "dwarven automaton faction": [
    "Dwemeris AuLycopersicumn Faction"
  ],
  "robbers' cove": [
    "Latronis Sinus"
  ],
  "hunting bow of binding": [
    "Arcus Venandi Deligandi"
  ],
  "brewer lid": [
    "Operculum Brachiatoris"
  ],
  "draught of the healer": [
    "Decocta Doctoris"
  ],
  "knifepoint mine": [
    "Metallum Cultripuncti"
  ],
  "bthardamz study": [
    "Bthardamz Tablinum"
  ],
  "draught of pickpocketing": [
    "Decocta Furandi"
  ],
  "ruby dragon claw": [
    "Ungues Draconis Rubinus"
  ],
  "rechargeexit": [
    "RechargeExit"
  ],
  "if it's fixed, then it's mine": [
    "Si refectum est, meum est"
  ],
  "child": [
    "Child"
  ],
  "blank lexicon": [
    "Lexicon Vacuum"
  ],
  "juniper berries": [
    "Baccae Juniperi"
  ],
  "staff of vanquishment": [
    "Rudis Expellendi"
  ],
  "red wave": [
    "Unda Rubra"
  ],
  "boethiah has sent me": [
    "Boethis me misit"
  ],
  "the giant in <alias=giantcamp> is dead": [
    "Gigas in <Alias=GiantCamp> mortuus est"
  ],
  "giant in <alias=giantcamp> is dead": [
    "Gigas in <Alias=GiantCamp> mortuus est"
  ],
  "you treat all your customers like this": [
    "Omnes clientes hoc in modo tractas"
  ],
  "marksfemaleargonianscar05": [
    "MarksFemaleArgonianScar05"
  ],
  "just start from the beginning": [
    "Ab initio incipe"
  ],
  "daedric gauntlets of peerless archery": [
    "Daedricae Manicae Optimae Saggitationis"
  ],
  "haldyn": [
    "Haldyn"
  ],
  "i have some frost salts for you, from farengar": [
    "Salia Glaciei tibi habeo, ab Farengar"
  ],
  "balmora blue": [
    "Balmora Caeruleae"
  ],
  "elven war axe of shocks": [
    "Alfus Bellisecuris Electricitatis"
  ],
  "azura voice cell": [
    "Azura Voice Cell"
  ],
  "avanchnzel animoncultory": [
    "Avanchnzelis Animonculorium"
  ],
  "niels": [
    "Niels"
  ],
  "grand plaza scene 09": [
    "Grand Plaza Scene 09"
  ],
  "matlara": [
    "Matlara"
  ],
  "torpor ingredient 1": [
    "Torpor Ingredient 1"
  ],
  "sanctified dwarven greatsword": [
    "Sanctificata Dvemeris Spatha"
  ],
  "hall of countenance": [
    "Atrium Vultus"
  ],
  "radiant raiment": [
    "Radians Vestiens"
  ],
  "daedric mace of banishing": [
    "Maca Daedrica Expellendi"
  ],
  "bonechill passage": [
    "Commeatus Frigossis"
  ],
  "elven sword of winnowing": [
    "Alfus Gladius Ventagii"
  ],
  "ebony mace of annihilating": [
    "Maca Ebeni Annihilationis"
  ],
  "humanbeardmedium01_1bit": [
    "HumanBeardMedium01_1bit"
  ],
  "hairfemaledremora01": [
    "HairFemaleDremora01"
  ],
  "histskin": [
    "Histipellis"
  ],
  "imperial bow of shocks": [
    "Imperialis Arcus Electricitatis"
  ],
  "i need to speak to you about saarthal": [
    "Mihi opus est tibi de Saarthale loqui"
  ],
  "where did you come from": [
    "Unde venisti"
  ],
  "glass dagger of devouring": [
    "Pugio Vitreus Consumendi"
  ],
  "scroll of courage": [
    "Codex Virtutis"
  ],
  "anything different about it": [
    "Estne quid de eo quod differt"
  ],
  "nchuand-zel armory": [
    "Nchuand-Zel Armentarium"
  ],
  "imperial bracers of minor sure grip": [
    "Chirothecae Romanae Minoris Dexteritatis"
  ],
  "ebony warhammer of enervating": [
    "Bellimalleus Ebeni Enervationis"
  ],
  "the wispmother": [
    "Nebulae Mater"
  ],
  "it will be fine": [
    "Bene erit"
  ],
  "stop the wedding": [
    "Siste sponsal"
  ],
  "i can't go through with this": [
    "Non possum cum hoc pergere"
  ],
  "lipshighelfmale04": [
    "LipsHighElfMale04"
  ],
  "here's the item you wanted": [
    "Hic res habes quas quaesivisti"
  ],
  "ruins of rkund": [
    "Ruinae Rkundis"
  ],
  "dragonscale shield of the firewalker": [
    "Scutum Draconisquamatum Ignambulatoris"
  ],
  "i helped the forlorn lovers of markarth": [
    "Adiuvi amatores oblitos Markartiae"
  ],
  "hern is dead": [
    "Hern mortuus est"
  ],
  "imperial sword of torpor": [
    "Gladius Romanus Torporis"
  ],
  "daedric warhammer": [
    "Bellimalleus Daedricus"
  ],
  "solitude military camp": [
    "Solitudinis Castra"
  ],
  "daedric bow of depleting": [
    "Arcus Daedricus Deplendi"
  ],
  "ragnvald canal": [
    "Ragnvaldis Canalis"
  ],
  "save on rest": [
    "Tueri Dum Dormias"
  ],
  "riften jail key": [
    "Clavis ad Carcerem Hiati"
  ],
  "shaircolor8": [
    "sHairColor8"
  ],
  "mani's letter": [
    "Epistula Mani"
  ],
  "orcish dagger of ice": [
    "Orci Pugio Gelidus"
  ],
  "save successful": [
    "Tutus Factua"
  ],
  "dialoguedawnstarrustleifseren01": [
    "DialogueDawnstarRustleifSeren01"
  ],
  "thalmor prisoner": [
    "Visigothi Captivus"
  ],
  "hairfemalenord12": [
    "HairFemaleNord12"
  ],
  "has the store made any money": [
    "Meruisti ullam pecuniam"
  ],
  "dwarven bow of scorching": [
    "Dvemeris Arcus Adurendi"
  ],
  "scroll of fire storm": [
    "Codes Tempestatis Ignis"
  ],
  "orcish shield of dwindling shock": [
    "Orci Scutum Electricitatis Diminuentis"
  ],
  "the song of pelinal, v7": [
    "Carmen Pelinalis, VII"
  ],
  "song of pelinal, v7": [
    "Carmen Pelinalis, VII"
  ],
  "elven boots of dwindling flames": [
    "Caligae Alfae Flammarum Diminuentium"
  ],
  "<font color='#ff0000'>steal</font>": [
    "<font color='#FF0000'>Furare</font>"
  ],
  "gissur's note": [
    "Epistula Gissuris"
  ],
  "loading": [
    "Aperiens"
  ],
  "blessed steel sword": [
    "Sanctificatus Gladius Adamanteus"
  ],
  "snorreid": [
    "Snorreid"
  ],
  "let's travel there together": [
    "Iuxti illic iter faciamus"
  ],
  "you cannot fast travel from this location": [
    "Non potes celeriter peregrinari ab hoc loco"
  ],
  "key to house of arkay": [
    "Clavis ad Domum Arkayae"
  ],
  "brynjolf's satchel": [
    "Cibisis Brynjolfis"
  ],
  "elven sword of expelling": [
    "Alfus Gladius Expellendi"
  ],
  "mystic sword": [
    "Gladius Mysticusus"
  ],
  "fellstar farm faction": [
    "Fellstar Farm Faction"
  ],
  "key to carlotta valentia's house": [
    "Clavis ad Domum Carlottae Valentiae"
  ],
  "storm enchanter": [
    "Incantator Tempestatis"
  ],
  "olfina gray-mane": [
    "Olfina Juba-Cana"
  ],
  "iron sword of cowardice": [
    "Gladius Ferri Timoris"
  ],
  "okay, then let's get going": [
    "Ok, sic, eamus"
  ],
  "daedric shield of shock suppression": [
    "Scutum Daedricum Suppresionis Electricitatis"
  ],
  "orcish sword of burning": [
    "Orci Gladius Cremandi"
  ],
  "necromancer robes": [
    "Necromanticum Peplum"
  ],
  "weak magicka poison": [
    "Venenum Debile Magicae"
  ],
  "elven warhammer of depleting": [
    "Alfus Bellimaleus Deplendi"
  ],
  "omluag": [
    "Omluag"
  ],
  "steel dagger of frost": [
    "Pugio Adamanteus Gelidus"
  ],
  "the blades said that paarthurnax used to be alduin's ally": [
    "Enses dixerunt Paarthurnacem socium Alduini fuisse"
  ],
  "blades said that paarthurnax used to be alduin's ally": [
    "Enses dixerunt Paarthurnacem socium Alduini fuisse"
  ],
  "eye color": [
    "Oculorum Color"
  ],
  "i'm afraid i can't do that": [
    "Timeo ut illud facere non possim"
  ],
  "belted tunic": [
    "Tunica Cincta"
  ],
  "i have the pelts you asked for": [
    "Renones rogatas tibi habeo"
  ],
  "elven bracers of eminent alchemy": [
    "Chirothecae Alfae Alchemiae Eminentis"
  ],
  "reanimate (self)": [
    "Reanimate (Self)"
  ],
  "elven bow of shocks": [
    "Arcus Alfus Electricitatis"
  ],
  "lost knife cage key": [
    "Clavis ad Carcerem Cultri Perditi"
  ],
  "what's missing from the vault": [
    "Quid e Hypogeo abest"
  ],
  "torkild the fearsome": [
    "Torkild Saevus"
  ],
  "so what's next": [
    "Ergo, quid nunc"
  ],
  "maven said you're expecting me": [
    "Maven dixit te me exspectare"
  ],
  "apprentice bellows": [
    "Apprentice Bellovs"
  ],
  "daedric mace of exhaustion": [
    "Maca Daedrica Exhaustionis"
  ],
  "dwarven helmet of illusion": [
    "Dvemeris Galea Inlusionis"
  ],
  "kottir red-shoal": [
    "Kottir Rubra-Brevia"
  ],
  "elven battleaxe of garnering": [
    "Bellisecuris Alfus Horti"
  ],
  "pull chain": [
    "Catena Tractanda"
  ],
  "daedric war axe of malediction": [
    "Bellisecuris Daedricus Maledictionis"
  ],
  "embry": [
    "Embry"
  ],
  "moth spawner": [
    "Moth Spavner"
  ],
  "scaled helmet of illusion": [
    "Galea Squamata Inlusionis"
  ],
  "i have some news about your daughter": [
    "Nuntium tibi de filia tua habeo"
  ],
  "spell tome: lightning storm": [
    "Liber Magicus: Tempestas"
  ],
  "dushnikh yal mine": [
    "Dushnikh Yal Mine"
  ],
  "embellished robes": [
    "Peplum Ornatum"
  ],
  "dialoguegenericgoodbye": [
    "DialogueGenericGoodbye"
  ],
  "key to lami's hut": [
    "Clavis ad Casam Lami"
  ],
  "daedric sword of petrifying": [
    "Gladius Daedricus Lapidescendi"
  ],
  "ebony mail": [
    "Ebeni Cataphractum"
  ],
  "avrusa sarethi": [
    "Avrusa Sarethi"
  ],
  "humanbeard19": [
    "HumanBeard19"
  ],
  "i'm the one the blades have been searching for": [
    "Sum ille quem Enses quaesivÃªrunt"
  ],
  "i don't know about you, but i'm going in there": [
    "Nescio de te, sed ego intrabo"
  ],
  "elven mace of garnering": [
    "Maca Alfa Horti"
  ],
  "have you heard any rumors": [
    "Audivistine quas rumores"
  ],
  "elven greatsword of debilitation": [
    "Spatha Alfa Debilitationis"
  ],
  "restore stamina - mystic": [
    "Restore Stamina - Mysticus"
  ],
  "hunting bow of sparks": [
    "Arcus Venandi Scintillarum"
  ],
  "key to ranmir's house": [
    "Clavis ad Domum Ranmiris"
  ],
  "how have things been now that mercer's gone": [
    "Mercer absente, quomodo vos hic habetis"
  ],
  "glass helmet of major magicka": [
    "Galea Vitrea Maioris Magicae"
  ],
  "banded iron armor of major health": [
    "Ferro Adligata Lorica Maioris Salutis"
  ],
  "daedric gauntlets": [
    "Daedricae Manicae"
  ],
  "daedric war axe of leeching": [
    "Bellisecuris Daedricus Sanguisugae"
  ],
  "i know who you are, anton": [
    "Scio quem te esse, Anton"
  ],
  "and where you're from": [
    "Et unde venis"
  ],
  "daedric war axe of terror": [
    "Bellisecuris Daedricus Terroris"
  ],
  "orcish warhammer of arcing": [
    "Orci Bellimalleus Electricitatis"
  ],
  "key to alva's house": [
    "Clavis ad Domum Alvae"
  ],
  "aicantar's lab journal": [
    "Ephemeris Laboratorii Aicantaris"
  ],
  "irlof": [
    "Irlof"
  ],
  "northwind mine": [
    "Boreas Metallum"
  ],
  "femaleeyeselfblind": [
    "FemaleEyesElfBlind"
  ],
  "ebony warhammer of petrifying": [
    "Bellimalleus Ebeni Lapidescendi"
  ],
  "glass dagger of banishing": [
    "Pugio Vitreus Expellendi"
  ],
  "hers": [
    "eius"
  ],
  "you can't quickload while the game is paused": [
    "Celeriaperire non potes dum pauses"
  ],
  "i'm innocent": [
    "Innocens sum"
  ],
  "steel mace of sparks": [
    "Maca Adamantea Scintillarum"
  ],
  "\"no rest in dawnstar\"": [
    "\"Nullum quietum Luciferi"
  ],
  "being happily married": [
    "Esse maritum laetum"
  ],
  "daedric mace of devouring": [
    "Maca Daedrica Consumendi"
  ],
  "dwarven war axe of thunderbolts": [
    "Dvemeris Bellisecuris Fulminum"
  ],
  "troll skull": [
    "Trellis Calvaria"
  ],
  "it left morrowind, but never arrived here": [
    "E Morrovento effugit, sed numquam hic advenit"
  ],
  "how do you feel about this war": [
    "Quomodo de hoc bello sentis"
  ],
  "give me the long version": [
    "Da mihi versionem longam"
  ],
  "hide helmet of destruction": [
    "Cutis Galea Destructionis"
  ],
  "dragonscale helmet of extreme conjuring": [
    "Draconisquamata Galea Maiorum Vocantium"
  ],
  "autosave": [
    "Autotuens"
  ],
  "east empire mercenary": [
    "Euri Imperii Mercenarius"
  ],
  "key to gularz khazgur keep": [
    "Clavis ad Castellum Gularz Khazgur"
  ],
  "daedric battleaxe of expelling": [
    "Bellisecuris Daedricus Expellendi"
  ],
  "maybe i will go to windhelm": [
    "Fortasse Ventigaleam petam"
  ],
  "dwarven battleaxe of soul snares": [
    "Dvemeris Bellisecuris Animicapti"
  ],
  "mace of molag bal": [
    "Maca Molag Bol"
  ],
  "what use would you be": [
    "Quomodo utilis esse potes"
  ],
  "i have a filled black soul gem": [
    "Gemmanimam atra plena nunc est"
  ],
  "ebony dagger of lightning": [
    "Pugio Ebeni Fulminis"
  ],
  "mani": [
    "Mani"
  ],
  "hairmaleelder5": [
    "HairMaleElder5"
  ],
  "legate hrollod": [
    "Legatus Hrollodis"
  ],
  "ebony shield of dwindling frost": [
    "Ebeni Scutum Minoris Congelationis"
  ],
  "why not just fight our way in": [
    "Quidni pugnemus ut intremus"
  ],
  "girduin": [
    "Girduin"
  ],
  "i'm ready for whatever test is next": [
    "Paratus sum omnibus probationibus"
  ],
  "scaled boots of waning shock": [
    "Caligae Squamatae Insenescentis Electricitatis"
  ],
  "glass bow of shocks": [
    "Arcus Vitreus Electricitatis"
  ],
  "glass dagger of lethargy": [
    "Pugio Vitreus Lethargiae"
  ],
  "you don't trust the jarl": [
    "Comiti non confidis"
  ],
  "leather boots of resist fire": [
    "Corii Caligae Contra Vim Ignis"
  ],
  "i've got things to do": [
    "Res faciendas habeo"
  ],
  "detect life enemy interior": [
    "Detect Life Enemy Interior"
  ],
  "db06gabrielladragonbridgebranchtopic": [
    "DB06GabriellaDragonBridgeBranchTopic"
  ],
  "but that seems so... disrespectful": [
    "Sed tam... irreverens videtur"
  ],
  "there are thalmor agents in the ratway. looking for you": [
    "Agentes Visigothi in Via Murum sunt, te quaerentes"
  ],
  "ice wraith bite": [
    "Ice Wraith Bite"
  ],
  "cicero's journal - volume 2": [
    "Ephemeris Ciceronis - Volumen II"
  ],
  "did she say why she was doing this": [
    "Dixitne quam ob rem id fecit"
  ],
  "isn't that stealing": [
    "Nonne furtum est"
  ],
  "dragonscale shield of eminent blocking": [
    "Scutum Draconisquamatum Eminentis Interponendi"
  ],
  "you have insufficient gold to train": [
    "Non satis auri habes discendi causa"
  ],
  "am": [
    "AM"
  ],
  "dark souls reanimate corpse": [
    "Dark Souls Reanimate Corpse"
  ],
  "daedric dagger of the inferno": [
    "Pugio Daedricus Conflagrationis"
  ],
  "db06gabriellawhattodobranchtopic1": [
    "DB06GabriellaWhatToDoBranchTopic1"
  ],
  "hairlinemaleredguard7": [
    "HairLineMaleRedguard7"
  ],
  "dialoguedawnstarinnirgnirfrukiscene01": [
    "DialogueDawnstarInnIrgnirFrukiScene01"
  ],
  "key to nchuand-zel": [
    "Clavis ad Nchuand-Zel"
  ],
  "i'm just here to reunite you with barbas": [
    "Modo hic sum ut Barbam tecum reconiliet"
  ],
  "pelagius the tormented": [
    "Pelagius Cruciatus"
  ],
  "we're back": [
    "Revenimus"
  ],
  "now fulfill your end of the bargain": [
    "Nunc fac quid dixisti te facturum esse"
  ],
  "dwarven helmet of major restoration": [
    "Dvemeris Galea Maioris Reficiendi"
  ],
  "this should help translate gallus's journal": [
    "Hic in translatione Libelli Galli adiuvare debet"
  ],
  "sap repository": [
    "Promptuarium Olibani"
  ],
  "it did not go well": [
    "Non bene ivit"
  ],
  "grimvar cruel-sea": [
    "Grimvar Mare-Crudele"
  ],
  "steel dagger of sapping": [
    "Pugio Adamanteus Hauriendi"
  ],
  "experience points earned": [
    "Experientia Merita"
  ],
  "so you do know her": [
    "Ergo eam novisti"
  ],
  "scroll of chain lightning": [
    "Codex Fulmen Catenatum"
  ],
  "i think i'll pass on it": [
    "Minime"
  ],
  "do you have a better idea": [
    "Habesne consilium melius"
  ],
  "hairmalenord11": [
    "HairMaleNord11"
  ],
  "call of valor visual fx": [
    "Call Of Valor Visual FX"
  ],
  "yngol's barrow": [
    "Pabillum Yngolis"
  ],
  "philter of light feet": [
    "Philtrum Pedum Levium"
  ],
  "hairmalenord03": [
    "HairMaleNord03"
  ],
  "bee and barb scene 17": [
    "Bee et Barb Scene 17"
  ],
  "northwatch captain's key": [
    "Clavis Centorionis Septentriovisus"
  ],
  "why is it so run down": [
    "Cur tam foedus hic est"
  ],
  "una": [
    "Una"
  ],
  "daedric sword of the inferno": [
    "Gladius Daedricus Conflagrationis"
  ],
  "skjor has fallen to the silver hand": [
    "Skjor ad Manum Argenteam cecidit"
  ],
  "forget it, then": [
    "Sic, eius obliviscere"
  ],
  "dwarven helmet of major destruction": [
    "Dvemeris Galea Maioris Destructionis"
  ],
  "fruki": [
    "Fruki"
  ],
  "glass mace of shocks": [
    "Maca Vitrea Electricitatis"
  ],
  "what kind of help do you need": [
    "Quale auxilium quaeris"
  ],
  "iron dagger of cowardice": [
    "Pugio Ferri Timoris"
  ],
  "orcish greatsword of depleting": [
    "Orci Spatha Deplendi"
  ],
  "steel plate helmet of restoration": [
    "Laminata Galea Recreandi"
  ],
  "i'd like you to forgive octieve's debts": [
    "Volo te aeris aleini Octievis obliviscere"
  ],
  "dwarven greatsword of harrowing": [
    "Dvemeris Spatha Occationis"
  ],
  "enchanter's philter": [
    "Incantatoris Philtrum"
  ],
  "kraldar": [
    "Kraldar"
  ],
  "general tullius' armor": [
    "Lorica Imperatoris Tullii"
  ],
  "iron sword of torpor": [
    "Gladius Ferri Torporis"
  ],
  "femalebrowshuman02": [
    "FemaleBrowsHuman02"
  ],
  "yes, of course... the black sacrament": [
    "Sic, certe... Sacramentum Atrum"
  ],
  "the lusty argonian maid, v1": [
    "Virgo Aronia Libidinosa, I"
  ],
  "lusty argonian maid, v1": [
    "Virgo Aronia Libidinosa, I"
  ],
  "so... you were telling me a tale": [
    "Ergo... mihi fabulam narrabas"
  ],
  "hargar's chest key": [
    "Clavis ad Arcam Hargaris"
  ],
  "would someone pay to have you killed": [
    "Esne quis qui pro morte tuo pecuniam solvat"
  ],
  "weak aversion to frost": [
    "Aversio Debilis Glaciei"
  ],
  "you cannot save right now": [
    "Non potes nunc ludum tutum facere"
  ],
  "i have a potion for you": [
    "Potionem tibi habeo"
  ],
  "kleppr": [
    "Kleppr"
  ],
  "scroll of conjure storm atronach": [
    "Codex Vocandi Atronachum Tempestatis"
  ],
  "hallowed orcish mace": [
    "Consacrata Maca Orcorum"
  ],
  "why not fight back": [
    "Quidni contra eos oppugnemu"
  ],
  "any word from the missing apprentices": [
    "Aliquod verbum de discipulis amissis audivisti"
  ],
  "fianna": [
    "Fianna"
  ],
  "challenger": [
    "Devocator"
  ],
  "call of the wild 2": [
    "Call Of Wild 2"
  ],
  "dwarven battleaxe": [
    "Dvemeris Bellisecuris"
  ],
  "daedric shield of fire abatement": [
    "Scutum Daedricum Resistendi Ignis"
  ],
  "amulet of zenithar": [
    "Monile Zenitharis"
  ],
  "ebony sword of enervating": [
    "Gladius Ebeni Enervationis"
  ],
  "ancient bowl": [
    "Patera Antiqua"
  ],
  "orcish warhammer of siphoning": [
    "Orci Bellimalleus Siphonis"
  ],
  "general tullius hasn't agreed to attend yet": [
    "Imperator Tullius nondum adire consensit"
  ],
  "m1r": [
    "M1r"
  ],
  "hadring": [
    "Hadring"
  ],
  "wait, what's going on here": [
    "Expecta, quid nunc accidit"
  ],
  "steel dagger of arcing": [
    "Pugio Adamanteus Electricitatis"
  ],
  "elven helmet of major alchemy": [
    "Alfa Galea Maioris Alchemiae"
  ],
  "an explorer's guide to skyrim": [
    "Liber Exploratoribus Caelifinis"
  ],
  "daedric war axe of dread": [
    "Bellisecuris Daedricus Timoris"
  ],
  "throw voice shout": [
    "Throw Voice Shout"
  ],
  "steel war axe of cowardice": [
    "Bellisecuris Adamanteus Timoris"
  ],
  "arcana restored": [
    "Arcana Refecta"
  ],
  "dragon disarm": [
    "Dragon Disarm"
  ],
  "just point me to the prisons": [
    "Dirige me ad carceres"
  ],
  "this seems dangerous": [
    "Periculosum videtur"
  ],
  "studded imperial armor of health": [
    "Romana Lorica Ornata Salutis"
  ],
  "key to gestur's house": [
    "Clavis ad Domum Gesturis"
  ],
  "i was looking to purchase a house in markarth": [
    "Volui domum Markartiae emere"
  ],
  "the karthspire": [
    "Spirakarthis"
  ],
  "iron war axe of fear": [
    "Bellisecuris Ferri Timoris"
  ],
  "paarthurnax": [
    "Paarthurnax"
  ],
  "what are those targets for": [
    "Pro quo sunt illi scopi"
  ],
  "heartwood mill faction": [
    "Heartwood Mill Faction"
  ],
  "this lock cannot be picked": [
    "Non potes hanc portam aperire clavicario"
  ],
  "it requires a key to open": [
    "Clavem postulat"
  ],
  "beitild is dead": [
    "Beitild mortua est"
  ],
  "playershout": [
    "PlayerShout"
  ],
  "tg01goodbyes": [
    "TG01Goodbyes"
  ],
  "i've brought a message from the jarl of whiterun": [
    "Nuntium ab Comite Albacursus tuli"
  ],
  "continue from your last saved game": [
    "Perge ab ludo ultimo"
  ],
  "the imperials failed to gain control of <alias=hold>": [
    "Romani <Alias=Hold> non vincÃªrunt"
  ],
  "imperials failed to gain control of <alias=hold>": [
    "Romani <Alias=Hold> non vincÃªrunt"
  ],
  "lockedobject": [
    "LockedObject"
  ],
  "yeah, it's all here": [
    "Sic, omnia hic sunt"
  ],
  "forsworn attacking merchant": [
    "Forsworn Attacking Merchant"
  ],
  "key to gray pine goods": [
    "Clavis ad Bona Pinicani"
  ],
  "npcs captured by bandits": [
    "NPCs Captured by Bandits"
  ],
  "i thought it was this easy for everyone": [
    "Credidi tam facile factu omnibus esse"
  ],
  "scroll of fire rune": [
    "Codes Runi Ignis"
  ],
  "elven warhammer of lethargy": [
    "Alfus Bellimaleus Lethargiae"
  ],
  "dwarven sword of ice": [
    "Gladius Dvemeris Gelidus"
  ],
  "browsmaleargonian02": [
    "BrowsMaleArgonian02"
  ],
  "konahrik": [
    "Konahrik"
  ],
  "daedric sword of exhaustion": [
    "Gladius Daedricus Exhaustionis"
  ],
  "ebony sword of expelling": [
    "Gladius Ebeni Expellendi"
  ],
  "so what is the poetic edda": [
    "Ergo quid est Edda Poetica"
  ],
  "ancient nord gauntlets": [
    "Antiquae Germanicae Manicae"
  ],
  "knockoverobject": [
    "KnockOverObject"
  ],
  "dbastridsleepreacttion1topic": [
    "DBAstridSleepReacttion1Topic"
  ],
  "orcish armor of eminent health": [
    "Orci Lorica Eminentis Salutis"
  ],
  "orcish battleaxe of cowardice": [
    "Orci Bellisecuris Timoris"
  ],
  "deliver faendal's letter to camilla": [
    "Fer Epistulam Faendalis Camillae"
  ],
  "wabbajack alt": [
    "Wabbajack Alt"
  ],
  "faction for protected actors": [
    "Faction for protected actors"
  ],
  "steel plate boots of waning frost": [
    "Laminatae Caligae Insenescentis Congelationis"
  ],
  "bagrak": [
    "Bagrak"
  ],
  "marked for death - aus": [
    "Marked for Death - Aus"
  ],
  "banning": [
    "Banning"
  ],
  "jester's day": [
    "Saturnalia"
  ],
  "dwarven war axe of harrowing": [
    "Dvemeris Bellisecuris Occationis"
  ],
  "beehive husk": [
    "Acus Alvarii"
  ],
  "effects of the elder scrolls": [
    "Effecta Codicum Veterrimorum"
  ],
  "you will do my bidding": [
    "Mandata mea sequeris"
  ],
  "brelyna maryon": [
    "Brelyna Maryon"
  ],
  "interrogation chamber key": [
    "Clavis ad Conclave Interrogationis"
  ],
  "ennoc": [
    "Ennoc"
  ],
  "njada stonearm": [
    "Njada Lapidibrachi"
  ],
  "why are you looking for this person": [
    "Cur hunc quaeris"
  ],
  "reverent orcish bow": [
    "Reverens Arcus Orci"
  ],
  "eye depth": [
    "Oculorum Profunditas"
  ],
  "dragonscale gauntlets of peerless wielding": [
    "Draconisquamatae Manicae Optimae Tenendi"
  ],
  "daedric mace of stunning": [
    "Maca Daedrica Intermissionis"
  ],
  "the dragon break": [
    "Fractum Draconum"
  ],
  "dragon break": [
    "Fractum Draconum"
  ],
  "dialoguefalkreathcorpselightfarmscene01": [
    "DialogueFalkreathCorpselightFarmScene01"
  ],
  "hairlinefemaleorc07": [
    "HairLineFemaleOrc07"
  ],
  "daedric bow of enervating": [
    "Arcus Daedricus Enervationis"
  ],
  "a children's anuad": [
    "Anuad Pueris"
  ],
  "children's anuad": [
    "Anuad Pueris"
  ],
  "sneak attack for": [
    "Ictus Rependi pro"
  ],
  "daedric dagger of expelling": [
    "Pugio Daedricus Expellendi"
  ],
  "dragon scales": [
    "Draconis Squamae"
  ],
  "it was made by radiant raiment": [
    "Facta est a Vestimenta Radiantia"
  ],
  "this is pointless. i should just kill you and be done with it": [
    "Nulla finis in hoc est. Te necare debeo et finem ei facere"
  ],
  "the dragons are terrorizing skyrim": [
    "Dracones Caelifinem molestant"
  ],
  "dragons are terrorizing skyrim": [
    "Dracones Caelifinem molestant"
  ],
  "don't you think they need to be stopped": [
    "Nonne credunt eos sisti debere"
  ],
  "what can you tell me about the \"butcher\"": [
    "Quid mihi dicere potes de \"Butcher\""
  ],
  "trap falling rocks dust": [
    "Trap Falling Rocks Dust"
  ],
  "jorleif has granted me permission to aid in the investigation": [
    "Jorleif me auxilium investigationem dare sivit"
  ],
  "spell tome: wall of flames": [
    "Liber Magicus: Paries Flammarum"
  ],
  "advances in lockpicking": [
    "Progressus in Clavicando"
  ],
  "orcish dagger of torpor": [
    "Orci Pugio Torporis"
  ],
  "einarth": [
    "Einarth"
  ],
  "pawnbroker": [
    "Pawnbroker"
  ],
  "craglane chest key": [
    "Clavis ad Arcam Craglane"
  ],
  "deadly poison": [
    "Letifer Venenum"
  ],
  "galmar never mentioned a mission here": [
    "Galmar numquam munus hic commemoravit"
  ],
  "dwarven war axe of ice": [
    "Dvemeris Bellisecuris Gelidus"
  ],
  "i thought you should know falion's up to something": [
    "Credidi te Falionem consilium malum habere scire debere"
  ],
  "is there someone behind the door": [
    "Estne quis post ianuam"
  ],
  "orcish helmet of major magicka": [
    "Orci Galea Maioris Magicae"
  ],
  "why should i keep this a secret": [
    "Cur hoc ut secretum retinere debeo"
  ],
  "sure, i'll help you": [
    "Certe, te adiuvabo"
  ],
  "speak to endon the silversmith": [
    "Adloquere Endon Ferrerius Argenteum"
  ],
  "ice wizard": [
    "Thaumatorgos Glaciei"
  ],
  "i'm heading towards eldergleam": [
    "Eldergleam peto"
  ],
  "imperial helmet of minor restoration": [
    "Romana Galea Minoris Reficiendi"
  ],
  "ebony greatsword of stunning": [
    "Spatha Ebeni Intermissionis"
  ],
  "the shill job": [
    "Munus \"Shill\""
  ],
  "shill job": [
    "Munus \"Shill\""
  ],
  "dragonscale gauntlets of peerless alchemy": [
    "Draconisquamatae Manicae Optimae Alchemiae"
  ],
  "this is quite an impressive library": [
    "Haec bibliotheca mirabilius est"
  ],
  "madanach": [
    "Madanach"
  ],
  "steel dagger of embers": [
    "Pugio Adamanteus Cinerum"
  ],
  "proper lock design": [
    "De Clusuris Portarum"
  ],
  "silver and sapphire circlet": [
    "Argentei et Sapphiri Circulum"
  ],
  "lash gra-dushnikh": [
    "Lash gra-Dushnikh"
  ],
  "steel plate helmet of major alchemy": [
    "Laminata Galea Maioris Alchemiae"
  ],
  "orcish helmet of extreme alchemy": [
    "Orci Galea Extremis Alchemiae"
  ],
  "you have too many followers": [
    "Nimis secutorum habes"
  ],
  "fine, then": [
    "Bene, ergo"
  ],
  "dwarven bow of weariness": [
    "Dvemeris Arcus Maioris Defatigationis"
  ],
  "skuldafn south tower": [
    "Skuldafn Australis Turris"
  ],
  "chaurus eggs": [
    "Ova Chauri"
  ],
  "ebony mace of subsuming": [
    "Maca Ebeni Subsumendi"
  ],
  "trius' trinket": [
    "Ornamentum Trii"
  ],
  "arm bandages": [
    "Arm Bandages"
  ],
  "dragonplate boots of the ox": [
    "Draconilaminata Caligae Bovis"
  ],
  "dwarven greatsword of reaping": [
    "Dvemeris Spatha Saturni"
  ],
  "giant's toe": [
    "Digitus Gigantis"
  ],
  "glass battleaxe of evoking": [
    "Bellisecuris Vitreus Evocandi"
  ],
  "lifting the shroud": [
    "Velamen Aperiens"
  ],
  "key to solitude lighthouse": [
    "Clavis ad Pharon Solitudinis"
  ],
  "helgen keep key": [
    "Clavis ad Castellum Helgen"
  ],
  "speak to degaine": [
    "Degaine Adloquere"
  ],
  "you were right to be paranoid, ennodius": [
    "Recte timuisti, Ennodi"
  ],
  "i'm ready for more training": [
    "Paratus sum pluribus discere"
  ],
  "temp - treasure": [
    "TEMP - TREASURE"
  ],
  "short history of morrowind": [
    "Historia Brevis Morroventi"
  ],
  "stamina is used to sprint and execute power attacks": [
    "Stamina usurpatur ut curras et ictus potestatis facias"
  ],
  "sprinting in heavy armor uses more stamina than sprinting in light armor or clothing": [
    "Currere in lorica gravi plus staminae constat quam in lorica levi vel vestimentis"
  ],
  "if it will help the cause": [
    "Si causam adiuvabit"
  ],
  "you're trying to bribe me now": [
    "Me corrumpere nunc temptas"
  ],
  "eisa's journal": [
    "Ephemeris Eisae"
  ],
  "i wanted to know more about markarth": [
    "Volui plus de Markartia scire"
  ],
  "the current weapon is already poisoned": [
    "Armum in manu iam venenatum est"
  ],
  "current weapon is already poisoned": [
    "Armum in manu iam venenatum est"
  ],
  "2920, second seed, v5": [
    "[MMCMXX] 2920, Semen Secundum, V. V"
  ],
  "there are advantages to gaining <alias=sonsminorholdcapital>": [
    "Benificia sunt in <Alias=SonsMinorHoldCapital> habendo"
  ],
  "daedric war axe of blizzards": [
    "Bellisecuris Daedricus Hiemis"
  ],
  "glass warhammer of fire": [
    "Bellimaleus Vitreus Ignis"
  ],
  "iron helmet of minor illusion": [
    "Ferri Galea Minoris Inlusionis"
  ],
  "how goes the war": [
    "Quid fit cum bello"
  ],
  "bryling falk scene 3": [
    "Bryling Falk Scene 3"
  ],
  "what do you need me to do": [
    "Quid vis me facere"
  ],
  "scroll of circle of protection": [
    "Codex Circuli Praesidii"
  ],
  "imperial sword of dismay": [
    "Gladius Romanus Timoris"
  ],
  "sven's fake letter from faendal": [
    "Epistula Falsa a Faendal (A Sven scripta)"
  ],
  "rains hand": [
    "Aprilis"
  ],
  "dragonplate gauntlets of extreme alchemy": [
    "Draconilaminatae Manicae Extremis Alchemiae"
  ],
  "alfhild battle-born": [
    "Alfhild Proeli-Nata"
  ],
  "virulent paralysis poison": [
    "Virulens Venenum Paralyticum"
  ],
  "orcish greatsword of diminishing": [
    "Orci Spatha Diminuendi"
  ],
  "hagraven faction": [
    "Hagraven Faction"
  ],
  "steel plate gauntlets": [
    "Laminata Manicae"
  ],
  "shaircolor7": [
    "sHairColor7"
  ],
  "i need to learn about something we found in saarthal": [
    "Volo de aliquo quod in Saarthali invenimus discere"
  ],
  "daedric greatsword of expelling": [
    "Spatha Daedrica Expellendi"
  ],
  "dialogueangasmillkodrirleifur01": [
    "DialogueAngasMillKodrirLeifur01"
  ],
  "fur gauntlets": [
    "Renonis Manicae"
  ],
  "friends with predatorfaction": [
    "Friends with PredatorFaction"
  ],
  "stormcloak mage": [
    "Sturmmantelorum Magus"
  ],
  "neetrenaza": [
    "Neetrenaza"
  ],
  "i've reconsidered your offer": [
    "De offertum tuum iterum putavi"
  ],
  "iron helmet of magicka": [
    "Ferri Galea Magicae"
  ],
  "i'd be happy to help": [
    "Gaudeam te adiuvare"
  ],
  "i need to capture a dragon": [
    "Opus est mihi draconem capere"
  ],
  "ra'jirr": [
    "Ra'jirr"
  ],
  "read cicero's diary": [
    "Lege Ephemeridem Ciceronis"
  ],
  "any advice on my current contract": [
    "Consiliumne habes de hoc foedere"
  ],
  "elven mace of stunning": [
    "Maca Alfa Intermissionis"
  ],
  "orcish greatsword of thunderbolts": [
    "Orci Spatha Fulminum"
  ],
  "how can the skull be affecting dawnstar": [
    "Quomodo Luciferem afficit Calvaria"
  ],
  "what do you think cicero is planning": [
    "Quid censes Ciceronem in animo habere"
  ],
  "adventurer's journal": [
    "Ephemeris Exploratoris"
  ],
  "that's the only way i operate": [
    "Est modus operandi meus solus"
  ],
  "elven war axe of freezing": [
    "Alfus Bellisecuris Gelidus"
  ],
  "the pirate haven has been destroyed": [
    "Domus piratorum deleta est"
  ],
  "pirate haven has been destroyed": [
    "Domus piratorum deleta est"
  ],
  "daedric bow of despair": [
    "Arcus Daedricus Desponsionis"
  ],
  "smithing trainer": [
    "Ferrarii Trainer"
  ],
  "tell me first, and then i'll decide": [
    "Dic mihi primum, deinde arbitrabor"
  ],
  "winterhold jarls longhouse scene 2": [
    "Hiemiterrae Jarls Longhouse Scene 2"
  ],
  "it does, and i can prove it": [
    "Certe, et argumentum habeo"
  ],
  "i'll get one for you": [
    "Unum tibi inveniam"
  ],
  "dravin llanith": [
    "Dravin Llanith"
  ],
  "where could they have gone": [
    "Ubinam ire poterint"
  ],
  "scroll of fear": [
    "Codex Timoris"
  ],
  "the dark brotherhood has come": [
    "Fraternitas Obscura venit"
  ],
  "dark brotherhood has come": [
    "Fraternitas Obscura venit"
  ],
  "you protect the jarl": [
    "Praesidium est Comiti"
  ],
  "but who protects you": [
    "Sed quis est praesidium tibi"
  ],
  "the affairs of hagravens": [
    "Res Corvisagarum"
  ],
  "affairs of hagravens": [
    "Res Corvisagarum"
  ],
  "i'm sorry, argonian, but i've been sent to kill you": [
    "Ignosce, Argone, sed hic missus sum ut te necam"
  ],
  "all standard weapons": [
    "All Standard Weapons"
  ],
  "boti said to talk to you about fastred": [
    "Boti me oravit ut tecum de Fastred loquar"
  ],
  "beg for your miserable life": [
    "Ora pro vita tua misera"
  ],
  "tell me about helvard": [
    "Dic mihi de Helvard"
  ],
  "hjalti's sword": [
    "Gladius Hjalti"
  ],
  "hairlinefemalenord05": [
    "HairLineFemaleNord05"
  ],
  "calixto corrium was the real killer": [
    "Calixto Corrium erat homicida verus"
  ],
  "glass bow of fear": [
    "Arcus Vitreus Timoris"
  ],
  "bolar's oathblade": [
    "Ensiurandum Bolaris"
  ],
  "i don't remember any of that": [
    "Nihil eorum memini"
  ],
  "be reasonable": [
    "Rationem habe, quaeso"
  ],
  "reasonable": [
    "Rationem habe, quaeso"
  ],
  "thanks, i guess": [
    "Gratias...fortasse"
  ],
  "do you visit the embassy often": [
    "Saepene legatum visitas"
  ],
  "invert y": [
    "Inverta Y"
  ],
  "iron greatsword of dismay": [
    "Spatha Ferri Timoris"
  ],
  "orcish war axe of despair": [
    "Orci Bellisecuris Desponsionis"
  ],
  "key to margret's room": [
    "Clavis ad Conclave Margretae"
  ],
  "iron armor of the minor knight": [
    "Ferrata Lorica Minoris Equitis"
  ],
  "you cannot fast travel while overencumbered": [
    "Non potes celeriter peregrinari dum nimis incuberatus es"
  ],
  "you call me dragonborn": [
    "Me Draconigenam apellas"
  ],
  "cellar door": [
    "Ianuam Cellarii"
  ],
  "dwarven warhammer of harvesting": [
    "Dvemeris Bellimalleus Saturni"
  ],
  "whiterun gate key": [
    "Clavis ad Portam Albacursus"
  ],
  "silus vesuius": [
    "Silus Vesuius"
  ],
  "suvaris atheron": [
    "Suvaris Atheron"
  ],
  "alva set the fire": [
    "Alva ignem creavit"
  ],
  "she's the murderer": [
    "Illa est interfector"
  ],
  "find anything": [
    "Aliquidne invenisti"
  ],
  "dwarven warhammer of freezing": [
    "Dvemeris Bellimalleus Gelidus"
  ],
  "dialoguecidhnamineblathlocgrisvar02": [
    "DialogueCidhnaMineBlathlocGrisvar02"
  ],
  "redguard hood": [
    "Capucium Rubricustodum"
  ],
  "mzinchaleft guard's note": [
    "Epistula Custodis Mzinchaleftis"
  ],
  "recover endon's silver mold": [
    "Inveni Exemplar Argentei Endonis"
  ],
  "laila law-giver": [
    "Laila Ius-Dator"
  ],
  "dwarven boots of strength": [
    "Dvemeres Caligae Fortitudinis"
  ],
  "daedric helmet of extreme alteration": [
    "Galea Daedrica Extremis Mutationis"
  ],
  "elven bow of fire": [
    "Arcus Alfus Ignis"
  ],
  "ebony warhammer of the inferno": [
    "Bellimalleus Ebeni Conflagrationis"
  ],
  "elven greatsword of shocks": [
    "Spatha Alfa Electricitatis"
  ],
  "falkreath stormcloak camp": [
    "Falkretiae Sturmmantelorum Castra"
  ],
  "elven mace of thunderbolts": [
    "Maca Alfa Fulminum"
  ],
  "iron shield of dwindling frost": [
    "Ferri Scutum Minoris Congelationis"
  ],
  "glass warhammer of depleting": [
    "Bellimaleus Vitreus Deplendi"
  ],
  "(optional) free orthorn": [
    "(Optio) Orthornem Libera"
  ],
  "i'm not quite ready to go inside yet": [
    "Non dum paratus sum intrare"
  ],
  "steel warhammer of weariness": [
    "Bellimalleus Adamanteus Maioris Defatigationis"
  ],
  "where do i find a daedra heart": [
    "Ubi cor Daedrae invenire possum"
  ],
  "i'm not ready to pay the fine": [
    "Paratus non sum vectical solvere"
  ],
  "i need your coat": [
    "Amiculum tuum volo"
  ],
  "snow-shod manor": [
    "Nivi-Calceatorum Villa"
  ],
  "mq104soldierfaction": [
    "MQ104SoldierFaction"
  ],
  "steel greatsword of cowardice": [
    "Spatha Adamantea Timoris"
  ],
  "he wasn't there, but i found these plans": [
    "Illic non erat, sed haec documenta inveni"
  ],
  "yes, i understand": [
    "Sic, Intellego"
  ],
  "barbasfaction": [
    "BarbasFaction"
  ],
  "where's cicero now": [
    "Ubi est Cicero nunc"
  ],
  "draught of alteration": [
    "Decocta Mutationis"
  ],
  "spell tome: bound sword": [
    "Liber Magicus: Gladius Vocatus"
  ],
  "glass bow of the blaze": [
    "Arcus Vitreus Congflagrantis"
  ],
  "hail the imperial legion": [
    "Ave Legio Romana"
  ],
  "answer me, or i'll paint this room with your blood": [
    "Da mihi responsum, vel hoc conclave pingam sanguine tuo"
  ],
  "highelf": [
    "HighElf"
  ],
  "snow fox": [
    "Vulpis Nivis"
  ],
  "glass war axe of shocks": [
    "Bellisecuris Vitreus Electricitatis"
  ],
  "bandit leader": [
    "Latronum Dux"
  ],
  "dragonplate gauntlets of eminent alchemy": [
    "Draconilaminatae Manicae Alchemiae Eminentis"
  ],
  "grant me access or you'll regret it": [
    "Sine me intrare vel poenas dabis"
  ],
  "daedric warhammer of fire": [
    "Bellimalleus Daedricus Ignis"
  ],
  "why is your shop down here in the canal": [
    "Quamobrem est taberna tua hic in canali"
  ],
  "i have authority in this matter, and need your cooperation": [
    "Auctoritatem de hoc habeo, et auxilium tuum volo"
  ],
  "key to sleeping giant inn": [
    "Clavis ad Diversorium Gigantis Dormientis"
  ],
  "dialoguelefthandminedaighreskaggi01": [
    "DialogueLeftHandMineDaighreSkaggi01"
  ],
  "dark brotherhood suspension": [
    "Fraternitas Obscura Suspension"
  ],
  "fortify magicka and magic resist": [
    "Adde ad Magicam et Resist Magicae"
  ],
  "noblewoman": [
    "Nobilis"
  ],
  "potent vigor poison": [
    "Potens Venenum Vigoris"
  ],
  "who's the target": [
    "Quis necam"
  ],
  "hairmaleredguard2": [
    "HairMaleRedguard2"
  ],
  "dwarven mace of soul snares": [
    "Dvemeris Maca Animicapti"
  ],
  "skeever nest": [
    "Skeever Nidus"
  ],
  "did you say something about money": [
    "Aliquidne de pecunia dixisti"
  ],
  "and you were a part of this": [
    "Et pars huius rei fuisti"
  ],
  "eye tint": [
    "Oculorum Color Exterior"
  ],
  "dragonscale gauntlets of extreme sure grip": [
    "Draconisquamatae Manicae Extremis Ambidexteritatis"
  ],
  "elven mace of the blaze": [
    "Maca Alfa Congflagrantis"
  ],
  "elven war axe of harvesting": [
    "Alfus Bellisecuris Saturni"
  ],
  "hairlinemalenord6": [
    "HairLineMaleNord6"
  ],
  "i'm just trying to find her": [
    "Modo eam inveni conor"
  ],
  "harvest's end": [
    "Finis Cereris"
  ],
  "treasure hunter's note": [
    "Epistula Venatoris Gazae"
  ],
  "spell tome: detect undead": [
    "Liber Magicus: Inveni Amortuos"
  ],
  "i'd like to report a crime": [
    "Facinus referre velim"
  ],
  "terek": [
    "Terek"
  ],
  "tankard": [
    "Poculum Metalli"
  ],
  "tell me about the thieves guild": [
    "Dic mihi de Sodalitate Furum"
  ],
  "dushnamub": [
    "Dushnamub"
  ],
  "forehead color": [
    "Frontis Color"
  ],
  "jesper": [
    "Jesper"
  ],
  "trilf": [
    "Trilf"
  ],
  "dwarven dagger of torpor": [
    "Dvemeris Pugio Torporis"
  ],
  "decree of monument": [
    "Decretum Monumenti"
  ],
  "tell me what's going on": [
    "Dic mihi quid accidit"
  ],
  "hairlinemaleredguard4": [
    "HairLineMaleRedguard4"
  ],
  "destruction staff": [
    "Rudis Destructionis"
  ],
  "orcish battleaxe of winnowing": [
    "Orci Bellisecuris Ventagii"
  ],
  "potion of lockpicking": [
    "Potio Clavicarii"
  ],
  "daedric greatsword of dread": [
    "Spatha Daedrica Timoris"
  ],
  "i'm in": [
    "Consentior"
  ],
  "regain the reach": [
    "Recipe Prehensum"
  ],
  "dialoguecarriagesystemidle": [
    "DialogueCarriageSystemIdle"
  ],
  "audiotemplatedwarvenspider": [
    "AudioTemplateDwarvenSpider"
  ],
  "skuldafn north tower": [
    "Skuldafn Septentrionis Turris"
  ],
  "your focal points have been, uh, cleansed": [
    "Puncta magica munda sunt"
  ],
  "orcish helmet of extreme illusion": [
    "Orci Galea Inlusionis Extremae"
  ],
  "(min lvl 2) hey you there, take this": [
    "(Min Lvl 2) Hey you there, take this"
  ],
  "katarina's ornamental ring": [
    "Anulus Ornamentalis Katarinae"
  ],
  "ebony dagger of the inferno": [
    "Pugio Ebeni Conflagrationis"
  ],
  "orcish helmet of major destruction": [
    "Orci Galea Maioris Destructionis"
  ],
  "bent dwemer scrap metal": [
    "Metallum Dvemere Curvatum"
  ],
  "spell tome: candlelight": [
    "Liber Magicus: Lux Fanalis"
  ],
  "silver tongue": [
    "Lingua Argentea"
  ],
  "dwarven greatsword of flames": [
    "Dvemeris Spatha Flammarum"
  ],
  "conjurer adept": [
    "Vocator Adeptus"
  ],
  "during sieges, staff and family hide": [
    "During sieges, staff and Familiae hide"
  ],
  "humanbeard43": [
    "HumanBeard43"
  ],
  "i have a black soul gem": [
    "Gemmanimam atram habeo"
  ],
  "what's troubling you": [
    "Quid te vexat"
  ],
  "i have your flawless sapphires": [
    "Habeo Sapphiros Perfectos"
  ],
  "linwe": [
    "Linve"
  ],
  "what's your place in the court": [
    "Quid est status tuus in aula"
  ],
  "is this some kind of cult": [
    "Estne aliqua congregatio"
  ],
  "dwarven bracers of eminent smithing": [
    "Dvemeres Chirothecae Maximi Faciendi"
  ],
  "hairfemaleredguardchild01": [
    "HairFemaleRedguardChild01"
  ],
  "that seems like a lot of money": [
    "Multa pecunia mihi videtur"
  ],
  "valthume chamber key": [
    "Clavis ad Conclavia Valthumis"
  ],
  "i have alva's journal": [
    "Ephemeridem Alvae habeo"
  ],
  "what about just going in through the brewery": [
    "Quidni per baciatorium intremus"
  ],
  "katla": [
    "Katla"
  ],
  "glass gauntlets of extreme wielding": [
    "Vitreae Manicae Extremis Tenendi"
  ],
  "dwarven shield of dwindling frost": [
    "Dvemere Scutum Minoris Congelationis"
  ],
  "willem": [
    "Villem"
  ],
  "dwarven mace of gleaning": [
    "Dvemeris Maca Saturni"
  ],
  "glass mace of binding": [
    "Maca Vitrea Deligandi"
  ],
  "broken steel battle axe head": [
    "Fractus Bellisecuris Adamanteus Caput"
  ],
  "hairmaledarkelf01": [
    "HairMaleDarkElf01"
  ],
  "orcish bow of fear": [
    "Orci Arcus Timoris"
  ],
  "dragon cultist": [
    "Draconem Secutor"
  ],
  "letter from the steward": [
    "Epistula a Dispensatore"
  ],
  "madesi's satchel": [
    "Cibisis Madesi"
  ],
  "potion creation failed": [
    "Potio non creata est"
  ],
  "stone bench": [
    "Stone Bench"
  ],
  "ebony bow of dread": [
    "Arcus Ebeni Timoris"
  ],
  "glass shield of shock suppression": [
    "Vitreum Scutum Suppresionis Electricitatis"
  ],
  "orcish warhammer of winnowing": [
    "Orci Bellimalleus Ventagii"
  ],
  "clear out <alias=animalden>": [
    "Neca <Alias=AnimalDen>"
  ],
  "to place": [
    "Ponendum"
  ],
  "ghost visuals storm": [
    "Ghost Visuals Storm"
  ],
  "fort snowhawk quarters key": [
    "Clavis ad Conclavia Castelli Accipitinignuis"
  ],
  "iron helmet of minor alchemy": [
    "Ferri Galea Minoris Alchemiae"
  ],
  "daedric warhammer of expelling": [
    "Bellimalleus Daedricus Expellendi"
  ],
  "hide armor of major health": [
    "Renonis Lorica Maioris Salutis"
  ],
  "elixir of the defender": [
    "Commixtum Defensoris"
  ],
  "hide boots of hauling": [
    "Renonis Caligae Portandi"
  ],
  "so no one knows the staff's location": [
    "Sic, nemo scit de loco in quo Rudis iacet"
  ],
  "ebony sword of immobilizing": [
    "Gladius Ebeni Immobilitatis"
  ],
  "arch-mage aren said you'd mentioned it recently": [
    "Archmagus Aren dixit te eum nuper comemmoravisse"
  ],
  "maiden-loom manor": [
    "Maiden-Loom Manor"
  ],
  "riften guard's helmet": [
    "Hiati Custodis Galea"
  ],
  "what's so bad about dragonrend": [
    "Quid est tam malum de Draconicaesi"
  ],
  "sounds like you need some help": [
    "Mihi videtur you need some help"
  ],
  "steel war axe of draining": [
    "Bellisecuris Adamanteus Hauriendi"
  ],
  "iron warhammer of ice": [
    "Bellimalleus Ferratus Gelidus"
  ],
  "it's nice to meet you, festus": [
    "Guadeo te cognoscere quoque, Festus"
  ],
  "all right, i'll do it. i'll kill one of them": [
    "Bene. Id faciam. Unum ex eis necam"
  ],
  "shadow fade": [
    "Evanidus Umbrarum"
  ],
  "warden's chest": [
    "Arca Constabilarii"
  ],
  "whiterun military camp": [
    "Albacursus Castra"
  ],
  "orcish boots of dwindling flames": [
    "Orci Caligae Flammarum Diminuentium"
  ],
  "if that's the way you want it": [
    "Si hoc modo id velis"
  ],
  "what was i doing again": [
    "Quid facere debeo"
  ],
  "there was an error accessing the device. please try again": [
    "The save/load operation failed"
  ],
  "fortify armor perks": [
    "Fortify Armor Perks"
  ],
  "beitild": [
    "Beitild"
  ],
  "key to longhouse": [
    "Clavis ad Longidomum"
  ],
  "what are the sacred trials": [
    "Quae sunt Temptationes Sacrae"
  ],
  "dwarven bow of depleting": [
    "Dvemeris Arcus Deplendi"
  ],
  "let me leave with the books, and there won't be any more trouble": [
    "Sine me cum libris exire, et nullum plus difficultatis erit"
  ],
  "hjornskar head-smasher": [
    "Hjornskar Caput-Fractor"
  ],
  "yes, but there's a problem": [
    "Sic, sed difficultas exstat"
  ],
  "hairfemalenordchild01": [
    "HairFemaleNordChild01"
  ],
  "humanbeard33": [
    "HumanBeard33"
  ],
  "journeyman talisman": [
    "Journeyman Talisman"
  ],
  "elven warhammer of evoking": [
    "Alfus Bellimaleus Evocandi"
  ],
  "ebony mace of fire": [
    "Maca Ebeni Ignis"
  ],
  "key to kraldar's house": [
    "Clavis ad Domum Kraldaris"
  ],
  "i need some arrows that can take down a dragon": [
    "Volo sagittas quae draconem necare possunt"
  ],
  "valve": [
    "Fistula"
  ],
  "daedric warhammer of blizzards": [
    "Bellimalleus Daedricus Hiemis"
  ],
  "cast a fear spell": [
    "Utere Magica Timoris"
  ],
  "ebony sword of depleting": [
    "Gladius Ebeni Deplendi"
  ],
  "dwarven sword of diminishing": [
    "Gladius Dvemeris Diminuendi"
  ],
  "let's talk about this job you want done": [
    "De illo munero loquamur"
  ],
  "alik'r wizard": [
    "Alik'ris Wizard"
  ],
  "ebony bow of stunning": [
    "Arcus Ebeni Intermissionis"
  ],
  "steel plate boots of dwindling shock": [
    "Laminatae Caligae Electricitatis Diminuentis"
  ],
  "a dance in fire, v7": [
    "Saltatio in Flammis, VII"
  ],
  "dance in fire, v7": [
    "Saltatio in Flammis, VII"
  ],
  "(<global=hirelinggold>)": [
    "(<global=hirelinggold>)"
  ],
  "elven dagger of devouring": [
    "Pugio Alfus Consumendi"
  ],
  "orcish bow of depleting": [
    "Orci Arcus Deplendi"
  ],
  "nord hero greatsword": [
    "Heroos Germanici Spatha"
  ],
  "tormir": [
    "Tormir"
  ],
  "humanbeard14": [
    "HumanBeard14"
  ],
  "glass sword of ice": [
    "Gladius Vitreus Gelidus"
  ],
  "kerah's key": [
    "Clavis Kerae"
  ],
  "amulet of the elder council": [
    "Monile Concilii Maiorum"
  ],
  "hold on - i just wanted some information": [
    "Exspecta - paulam informationem solam volui"
  ],
  "browsmalehumanoid08": [
    "BrowsMaleHumanoid08"
  ],
  "biography of barenziah, v2": [
    "Biographis Barenziae, II"
  ],
  "large dwemer strut": [
    "Magnum Trabs Dvemere"
  ],
  "have you been working in the mines long": [
    "Multumne tempus in metallis consumpsisti"
  ],
  "glass war axe of depleting": [
    "Bellisecuris Vitreus Deplendi"
  ],
  "imperial documents": [
    "Documenta Imperialia"
  ],
  "obtain the key to the whispering door": [
    "Accipe clavem Portae Sussurantis"
  ],
  "have you ever heard of the augur of dunlain": [
    "Umquam de Augure Dunlanis audivisti"
  ],
  "[detected]": [
    "[VISUS]"
  ],
  "detect dead": [
    "Inveni Mortuos"
  ],
  "open the trap": [
    "Aperi insidias"
  ],
  "daedric mace of lethargy": [
    "Maca Daedrica Lethargiae"
  ],
  "orcish war axe of consuming": [
    "Orci Bellisecuris Consumendi"
  ],
  "retrieval": [
    "Repetens"
  ],
  "arcadia": [
    "Arcadia"
  ],
  "dwarven dagger of burning": [
    "Dvemeris Pugio Cremandi"
  ],
  "niranye's safe": [
    "Arca Secura Niranyis"
  ],
  "i think they may have met after all": [
    "Fortasse ei inter se conveniÃªrunt"
  ],
  "so why not help him": [
    "Ergo quidni eum adiuves"
  ],
  "clavicus vile": [
    "Clavicus"
  ],
  "mammoth tusk": [
    "Elephantis Dens"
  ],
  "the caller": [
    "Vocator"
  ],
  "caller": [
    "Vocator"
  ],
  "i found your amulet": [
    "Monile tuum inveni"
  ],
  "imperial light helmet of major magicka": [
    "Romana Cassis Maioris Magicae"
  ],
  "[success]": [
    "[SUCCESSUS]"
  ],
  "something for bad dreams": [
    "Aliquid pro suppressionibus"
  ],
  "orcish war axe of scorching": [
    "Orci Bellisecuris Adurendi"
  ],
  "dwarven warhammer of despair": [
    "Dvemeris Bellimalleus Desponsionis"
  ],
  "iron helmet of minor archery": [
    "Ferri Galea Minoris Saggitationis"
  ],
  "why have the alik'r been banned from the market": [
    "Cur non possunt Alik'res forum intrare"
  ],
  "steel plate helmet of eminent archery": [
    "Laminata Galea Eminentis Saggitationis"
  ],
  "potion of strength": [
    "Potio Fortitudinis"
  ],
  "why aren't you part of the coven anymore": [
    "Cur tu non iam pars gregis sagarum es"
  ],
  "is there a story behind that burned down house": [
    "Estne fabula de illa casa adusta"
  ],
  "the vision is true, isn't it": [
    "Nonne visus verus est"
  ],
  "vision is true, isn't it": [
    "Nonne visus verus est"
  ],
  "resource": [
    "Copia"
  ],
  "daedric battleaxe of enervating": [
    "Bellisecuris Daedricus Enervationis"
  ],
  "scroll of muffle": [
    "Codex Quieti"
  ],
  "does mara require more of me": [
    "Vultne Mara plus temporis mei"
  ],
  "astrid said you'd have some work for me": [
    "Astrid mihi dixit te laborem pro me habere"
  ],
  "orcish mace of winnowing": [
    "Orci Maca Ventagii"
  ],
  "what else can you tell me about the gourmet": [
    "Quid aliud mihi de Apicio narrare potes"
  ],
  "tell me about him": [
    "Dic mihi de eo"
  ],
  "steward has already been blackmailed": [
    "Steward has already been blackmailed"
  ],
  "honey jar": [
    "Melis Matula"
  ],
  "steel gauntlets of wielding": [
    "Adamanteae Manicae Tenendi"
  ],
  "rank 1 = being absorbed, 2 = done": [
    "Rank 1 = Being Absorbed, 2 = Done"
  ],
  "persisting stamina poison": [
    "Persistens Venenum Staminae"
  ],
  "orcish gauntlets of major archery": [
    "Orci Manicae Maioris Sagittationis"
  ],
  "madanach is expecting me": [
    "Madanach me expectat"
  ],
  "glass war axe of freezing": [
    "Bellisecuris Vitreus Gelidus"
  ],
  "she's in ivarstead using the name lynly": [
    "Illa Ivaridomus est et nomine Lynly utitur"
  ],
  "perhaps it could be translated": [
    "Fortasse in sermonem Latinam mutari possit"
  ],
  "action mapping": [
    "Action Chartaping"
  ],
  "orcish mace of absorption": [
    "Orci Maca Absorbendi"
  ],
  "who were you talking to": [
    "Quem adlocutus es"
  ],
  "crouch": [
    "Adnitere"
  ],
  "maybe i could speak with the steward instead": [
    "Fortasse in loco eius dispensatori adloquere possum"
  ],
  "invisible shelf marker": [
    "Invisible Shelf Marker"
  ],
  "ghunzul": [
    "Ghunzul"
  ],
  "skirmisher's potion": [
    "Velitis Potio"
  ],
  "okay, follow me": [
    "Bene, Sequere me"
  ],
  "your family was concerned for you": [
    "Familia tua de te sollicitabatur"
  ],
  "elven mace of diminishing": [
    "Maca Alfa Diminuendi"
  ],
  "maluril's journal": [
    "Ephemeris Malurilis"
  ],
  "tell atar that ainethach has been dealt with": [
    "Dic Atari Ainethachem iam Tractatum Esse"
  ],
  "key to olava the feeble's house": [
    "Clavis ad Domum Olavae Debilis"
  ],
  "speak to <alias.shortname=target> with <alias.shortname=questgiver>'s outfit on": [
    "Adloquere <Alias.ShortName=Target> dum vestimenta <Alias.ShortName=Questgiver>e induas"
  ],
  "i have them right here": [
    "Illos hic habeo"
  ],
  "dwarven boots of muffling": [
    "Dvemeres Caligae Silentii"
  ],
  "horgeir's house key": [
    "Clavis ad Domum Horgeiris"
  ],
  "vipir the fleet": [
    "Vipir Celer"
  ],
  "sudi's journal": [
    "Ephemeris Sudi"
  ],
  "the three of us could keep it": [
    "Nos tres id retinere possumus"
  ],
  "three of us could keep it": [
    "Nos tres id retinere possumus"
  ],
  "i've decided to turn myself in": [
    "Elegi tibi succumbere"
  ],
  "cast a healing hands spell": [
    "Fac Magicam Manuum Salutantium"
  ],
  "black-briar meadery": [
    "Atri-Dumi Hydromelliarium"
  ],
  "iron key": [
    "Clavis Ferri"
  ],
  "glass greatsword of debilitation": [
    "Spatha Vitrea Debilitationis"
  ],
  "femaleheadargonian": [
    "FemaleHeadArgonian"
  ],
  "orcish dagger of harrowing": [
    "Orci Pugio Occationis"
  ],
  "the third door": [
    "Ianua Tertia"
  ],
  "third door": [
    "Ianua Tertia"
  ],
  "are you sure you want to overwrite this save game": [
    "Esne certus te super hunc ludum tutum superscribere velle"
  ],
  "pleated shoes": [
    "Ornata Calciamenta"
  ],
  "inventory": [
    "Possessa"
  ],
  "embroidered garment": [
    "Vestimenta Nobilia"
  ],
  "pelagius wing key": [
    "Clavis ad Alam Pelagii"
  ],
  "your current weapon is already enchanted": [
    "Armum tuum iam incantamentum habet"
  ],
  "why are the alik'r after you": [
    "Cur Alik'res te persequntur"
  ],
  "steel greatsword of torpor": [
    "Spatha Adamantea Torporis"
  ],
  "chief larak": [
    "Princeps Larak"
  ],
  "key to dengeir's hall": [
    "Clavis ad Aulam Dengeiris"
  ],
  "thieves guild dialogue scene 15": [
    "Sodalitatis Furum Dialogue Scene 15"
  ],
  "grok": [
    "Grok"
  ],
  "glass mace": [
    "Maca Vitrea"
  ],
  "potion of healing": [
    "Potio Salutis"
  ],
  "sounds horrible": [
    "Horribilis mihi videtur"
  ],
  "iron boots of waning frost": [
    "Ferri Caligae Insenescentis Congelationis"
  ],
  "elven armor of major health": [
    "Alfa Lorica Maioris Salutis"
  ],
  "audiotemplateatronachflame": [
    "AudioTemplateAtronachumFlame"
  ],
  "movarth piquine": [
    "Movarth Piquine"
  ],
  "larina": [
    "Larina"
  ],
  "where do you get your skooma": [
    "Unde skooma potiris"
  ],
  "i think you already know who i am": [
    "Credo te iam scire quis sim"
  ],
  "serpent's bluff ruins": [
    "Serpentis Rupis Ruinae"
  ],
  "dragonplate shield of peerless blocking": [
    "Draconilaminatum Scutum Optimi Interponendi"
  ],
  "jaree-ra": [
    "Jaree-Ra"
  ],
  "daedric bow of storms": [
    "Arcus Daedricus Tempestatium"
  ],
  "midden incident report": [
    "Nuntius Incidentae Sterculini"
  ],
  "brow": [
    "Frons"
  ],
  "scaled helmet of major restoration": [
    "Galea Squamata Maioris Reficiendi"
  ],
  "they're at cragslane cavern": [
    "Ad Cavernam Cragslane sunt"
  ],
  "orcish battleaxe of animus": [
    "Orci Bellisecuris Animi"
  ],
  "poacher's axe": [
    "Secruis Furis Animalium"
  ],
  "he's dead. are we done here": [
    "Mortuus est. Ad finem advenimus"
  ],
  "who is dibella": [
    "Quis est Dibell"
  ],
  "this is her temple": [
    "Hoc est templum eius"
  ],
  "you want my opinion": [
    "Sententiam meam vis"
  ],
  "i think you're lying to me": [
    "Credo te mihi mentitiones dicere"
  ],
  "tirdas": [
    "Die Martis"
  ],
  "find derkeethus": [
    "Inveni Derkeethum"
  ],
  "dbmrandrujokillchoice2abranchtopic": [
    "DBMrandrujoKillChoice2aBranchTopic"
  ],
  "fort snowhawk prison": [
    "Carcer Castelli Nivaccipitris"
  ],
  "imperial light helmet of major alchemy": [
    "Romana Cassis Maioris Alchemiae"
  ],
  "palla, volume 1": [
    "Palla, Volumen I"
  ],
  "master talisman": [
    "Master Talisman"
  ],
  "daedric warhammer of storms": [
    "Bellimalleus Daedricus Tempestatium"
  ],
  "double-distilled skooma": [
    "Duplex-Distillata Skooma"
  ],
  "steel plate bracers of major sure grip": [
    "Chirothecae Laminatae Maioris Dexteritatis"
  ],
  "steel boots of hauling": [
    "Caligae Adamanteae Portandi"
  ],
  "what's your offer": [
    "Quid offers"
  ],
  "steel sword of sparks": [
    "Gladius Adamanteus Scintillarum"
  ],
  "i'll get them to agree to peace": [
    "Eis pacem facere cogar"
  ],
  "amulet of julianos": [
    "Monile Julianis"
  ],
  "dwarven boots of dwindling shock": [
    "Dvemeres Caligae Electricitatis Diminuentis"
  ],
  "i'm trying to find out who did this": [
    "Iam quem hoc fecerit scire conor"
  ],
  "i was hoping you'd help": [
    "Sperebam ut te adiuvares"
  ],
  "what do you expect from me, then": [
    "Quid de me expectas"
  ],
  "turdas": [
    "Die Iovis"
  ],
  "sheogorath outfit": [
    "Sheogorath Vestimenta"
  ],
  "vittoria vici": [
    "Vittoria Vici"
  ],
  "warpaintred02": [
    "WarpaintRed02"
  ],
  "you're going to be the sybil of dibella": [
    "Eris Sybilla Dibellae"
  ],
  "ms12hellos": [
    "MS12Hellos"
  ],
  "the song of pelinal, v3": [
    "Carmen Pelinalis, III"
  ],
  "song of pelinal, v3": [
    "Carmen Pelinalis, III"
  ],
  "black mage robes": [
    "Magi Peplum Atrum"
  ],
  "(give poem)": [
    "(Da carmen)"
  ],
  "i received your letter": [
    "Epistulam tuam accepi"
  ],
  "ebony mace of devouring": [
    "Maca Ebeni Consumendi"
  ],
  "humanbeardshort20_headpart": [
    "HumanBeardShort20_Headpart"
  ],
  "you mentioned something back there": [
    "Commemoravisti aliquid"
  ],
  "orcish mace of torpor": [
    "Orci Maca Torporis"
  ],
  "the empire created the forsworn": [
    "Imperium Abiuratos fecit"
  ],
  "empire created the forsworn": [
    "Imperium Abiuratos fecit"
  ],
  "hall of rumination": [
    "Atrium Cogitandi"
  ],
  "rusty arrow": [
    "Sagitta Oxidata"
  ],
  "ravam verethi": [
    "Ravam Verethi"
  ],
  "elven war axe of fire": [
    "Alfus Bellisecuris Ignis"
  ],
  "whiterun guard's helmet": [
    "Galea Custodis Albacursus"
  ],
  "here's the ring": [
    "Hic anulum habes"
  ],
  "ebony battleaxe of terror": [
    "Bellisecuris Ebeni Terroris"
  ],
  "(invisible continue) difficulty based on attack delta": [
    "(Invisible Continue) Difficulty based on Attack Delta"
  ],
  "the witches should be hunted down for their trickery": [
    "pro dolo earum Sagas venari debemus"
  ],
  "witches should be hunted down for their trickery": [
    "pro dolo earum Sagas venari debemus"
  ],
  "scaled boots of eminent sneaking": [
    "Caligae Squamatae Maximi Repsendi"
  ],
  "steel battleaxe of fatigue": [
    "Bellisecuris Adamanteus Defatigationis"
  ],
  "it seems like i shouldn't be here": [
    "Mihi videtur me hic esse non debere"
  ],
  "audiotemplatemudcrab": [
    "AudioTemplateMudcrab"
  ],
  "i've returned with the courier's package": [
    "Arcam nuntii rettuli"
  ],
  "archmage's robes": [
    "Archimagi Peplum"
  ],
  "elven war axe of malediction": [
    "Alfus Bellisecuris Maledictionis"
  ],
  "erandur": [
    "Erandur"
  ],
  "i've found the journal of skorm snow-strider": [
    "Ephemerides Skorm Nivi-Cursoris inveni"
  ],
  "the falmer: a study": [
    "Falmeres: Commentatio"
  ],
  "falmer: a study": [
    "Falmeres: Commentatio"
  ],
  "glass mace of fear": [
    "Maca Vitrea Timoris"
  ],
  "do you come here a lot": [
    "Hicne saepe venis"
  ],
  "you cannot wait when enemies are nearby": [
    "Non potes expectare dum inimici propinqui sint"
  ],
  "specularity fade": [
    "Evanidus Specularitatis"
  ],
  "soljund's sinkhole guards": [
    "Soljund's Sinkhole Guards"
  ],
  "hewnon black-skeever": [
    "Hevnon Ater-Skeever"
  ],
  "spell tome: frost rune": [
    "Liber Magicus: Runus Glaciei"
  ],
  "you have zero chance to hit": [
    "You have zero chance to hit"
  ],
  "glass boots of muffling": [
    "Vitreae Caligae Silentii"
  ],
  "ebony sword of blizzards": [
    "Gladius Ebeni Hiemis"
  ],
  "maleheadorc": [
    "MaleHeadOrc"
  ],
  "key to erikur's house": [
    "Clavis ad Domum Erikuris"
  ],
  "kematu": [
    "Kematu"
  ],
  "what if mercer's there": [
    "Quid faciamus si Mercer illic sit"
  ],
  "maybe you should do your own work": [
    "Fortasse tu ipse laborem tuam facere debes"
  ],
  "ebony sword of fire": [
    "Gladius Ebeni Ignis"
  ],
  "dwarven sword of scorching": [
    "Gladius Dvemeris Adurendi"
  ],
  "ebony sword of subsuming": [
    "Gladius Ebeni Subsumendi"
  ],
  "black briar meadery scene 03": [
    "Black Briar Meadery Scene 03"
  ],
  "i think she wants a more cultured man": [
    "Credo eam hominem cultiorem velle"
  ],
  "long taffy treat": [
    "Longum Taffy Confectum"
  ],
  "enough talk": [
    "Satis loquendi"
  ],
  "just tell me who to kill": [
    "Modo dic mihi quem necare debeo"
  ],
  "dwarven greatsword of shocks": [
    "Dvemeris Spatha Electricitatis"
  ],
  "fjotli's silver locket": [
    "Fjotli Argentei Amuletum"
  ],
  "dwemer museum key": [
    "Clavis Mouseion Dwemere"
  ],
  "instant kill": [
    "Statim Neca"
  ],
  "iron battleaxe of sapping": [
    "Bellisecuris Ferri Hauriendi"
  ],
  "rorlund": [
    "Rorlund"
  ],
  "holy daedric warhammer": [
    "Sanctus Bellimalleus Daedricus"
  ],
  "iron helmet of minor restoration": [
    "Ferri Galea Minoris Reficiendi"
  ],
  "arcane enchanters are worktables used by mages to enchant weapons and armor": [
    "Incantatores Arcani subsellia sunt quibus a magis usurpantur ut arma loricasque incantamenta dent"
  ],
  "femaleeyesdarkelfdeepred": [
    "FemaleEyesDarkElfDeepRed"
  ],
  "glass war axe of lightning": [
    "Bellisecuris Vitreus Fulminis"
  ],
  "i have deathbell for you": [
    "Tintinamortem tibi habeo"
  ],
  "creaturedialogueatronachstorm": [
    "CreatureDialogueAtronachumStorm"
  ],
  "bad day": [
    "Dies malus"
  ],
  "daedric greatsword of blizzards": [
    "Spatha Daedrica Hiemis"
  ],
  "hide helmet of minor archery": [
    "Cutis Galea Minoris Saggitationis"
  ],
  "do you know about delphine's... activities": [
    "Scisne de...rebus Delphinae"
  ],
  "dragonplate shield of shock suppression": [
    "Draconilaminatum Scutum Suppresionis Electricitatis"
  ],
  "alduin": [
    "Alduinus"
  ],
  "sorry, sounds dangerous": [
    "Ignosce, periculosum videtur"
  ],
  "orcish war axe of cowardice": [
    "Orci Bellisecuris Timoris"
  ],
  "shaircolor12": [
    "sHairColor12"
  ],
  "illdi": [
    "Illdi"
  ],
  "and what mystery is that": [
    "Et quid est illud mysterium"
  ],
  "ms11violatohjerimexclamationpoint": [
    "MS11ViolaToHjerimExclamationPoint"
  ],
  "humanbeardlong03_1bit": [
    "HumanBeardLong03_1bit"
  ],
  "niluva hlaalu": [
    "Niluva Hlaalu"
  ],
  "light armor trainer": [
    "Light Armor Trainer"
  ],
  "dwarven warhammer of diminishing": [
    "Dvemeris Bellimalleus Diminuendi"
  ],
  "wait there": [
    "Expecta Illic"
  ],
  "she's hiding on the east side of town": [
    "Illa ad Eurum oppidi latet"
  ],
  "ravenscar hallow cage key": [
    "Clavis ad Carcerem Cavae Cicatricorvi"
  ],
  "scheming servant": [
    "Servus Insidians"
  ],
  "i have your skooma": [
    "Skoomam tuam habeo"
  ],
  "how could that have happened": [
    "Quomodo illud occurrere potuit"
  ],
  "steel war axe of arcing": [
    "Bellisecuris Adamanteus Electricitatis"
  ],
  "job's done, brynjolf": [
    "Munus factum est, Brynjolf"
  ],
  "how can the rest of the people not care": [
    "Cur non curant alii"
  ],
  "steel plate helmet of eminent alteration": [
    "Laminata Galea Eminentis Mutationis"
  ],
  "elven gilded armor of the noble knight": [
    "Alfa Lorica Aurata Noblis Equitis"
  ],
  "dwarven dagger of ice": [
    "Dvemeris Pugio Gelidus"
  ],
  "we were victorious": [
    "Vicimus"
  ],
  "loot gunjar's body": [
    "Res sume e corpore Gunjaris"
  ],
  "courier items": [
    "Courier Items"
  ],
  "that's what everyone keeps telling me": [
    "Sic omnes mihi dicunt"
  ],
  "orcish war axe of debilitation": [
    "Orci Bellisecuris Debilitationis"
  ],
  "nelkir": [
    "Nelkir"
  ],
  "iron shield of minor blocking": [
    "Ferri Scutum Minoris Interponendi"
  ],
  "what was it you were missing": [
    "Quid amisisti"
  ],
  "egg sac": [
    "Saccus Ovum"
  ],
  "hide shield of dwindling shock": [
    "Scutum Cutis Electricitatis Diminuentis"
  ],
  "tell me about the numbers jobs": [
    "Narra mihi de munera \"numerorum.\""
  ],
  "dwarven warhammer of animus": [
    "Dvemeris Bellimalleus Animi"
  ],
  "orcish sword of reaping": [
    "Orci Gladius Saturni"
  ],
  "ice mage adept": [
    "Glacimagus Adeptus"
  ],
  "some necromancers were attempting to summon and bind potema": [
    "Nonnulli Necromantici Potemam evocare et ligare temptaverunt"
  ],
  "mayhem - master of the mind": [
    "Mahemium - Dominus Mentis"
  ],
  "brand-shei": [
    "Brand-Shei"
  ],
  "shield of ysgramor": [
    "Scutum Ysgramoris"
  ],
  "press the new control for this action": [
    "Da plectrum novum pro hac actione"
  ],
  "i want to quit that heist job": [
    "Nolo facere illum munus \"heist\""
  ],
  "apprentice talisman": [
    "Apprentice Talisman"
  ],
  "scaled bracers of deft hands": [
    "Chirothecae Squamatae Dexteritatis"
  ],
  "only cowards hunt animals": [
    "Ingavi soli animalia venaruntur"
  ],
  "true game walks on two legs": [
    "Raptum verun duis cruribus ambulat"
  ],
  "db recurringtarget 4 crimefaction": [
    "DB RecurringTarget 4 CrimeFaction"
  ],
  "restore stamina area": [
    "Restore Stamina Area"
  ],
  "how do i find the ratway": [
    "Quomodo Via Murum invenire possum"
  ],
  "betrid silver-blood": [
    "Betrid Aregenti-Sanguis"
  ],
  "elven dagger of freezing": [
    "Pugio Alfus Gelidus"
  ],
  "dunhillgrundstombsharedauntquestion": [
    "dunHillgrundsTombSharedAuntQuestion"
  ],
  "creaturedialoguedeer": [
    "CreatureDialogueDeer"
  ],
  "queen potema": [
    "Regina Potema"
  ],
  "weak poison": [
    "Venenum Debile"
  ],
  "sanctified orcish mace": [
    "Sanctificata Maca Orcorum"
  ],
  "legate constantius tituleius": [
    "Legatus Constantius Tituleius"
  ],
  "i've recovered all of arondil's journals": [
    "Omnes Ephemerides Arondiliss rettuli"
  ],
  "imperial light bracers": [
    "Romana Bracchialia Levia"
  ],
  "he sacked it and used magic to blame solitude": [
    "Ille id ruit et magica usus est ut Solitudini culpam daret"
  ],
  "fjotra": [
    "Fjotra"
  ],
  "ambarys rendar": [
    "Ambarys Rendar"
  ],
  "velehk sain's locker": [
    "Arca Secura Velehkis Sainis"
  ],
  "jornibret's last dance": [
    "Saltatio Ultima Jornibretis"
  ],
  "the legendary scourge": [
    "Flagitum Legendum"
  ],
  "legendary scourge": [
    "Flagitum Legendum"
  ],
  "x-sensitivity": [
    "X-Sensitivity"
  ],
  "daedric sword of evoking": [
    "Gladius Daedricus Evocandi"
  ],
  "are you all alone here": [
    "Esne solus hic"
  ],
  "daedric battleaxe of subsuming": [
    "Bellisecuris Daedricus Subsumendi"
  ],
  "elven bracers of eminent archery": [
    "Chirothecae Alfae Eminentis Saggitationis"
  ],
  "glass dagger": [
    "Pugio Vitreus"
  ],
  "niranye's house key": [
    "Clavis ad Domum Niranyis"
  ],
  "why are you imprisoned here": [
    "Cur hic incarceratus es"
  ],
  "dragonplate gauntlets of eminent archery": [
    "Draconilaminatae Manicae Eminentis Saggitationis"
  ],
  "the siege must have been hard on the city's merchants": [
    "Obsidium certe difficile mercatoribus urbis erat"
  ],
  "siege must have been hard on the city's merchants": [
    "Obsidium certe difficile mercatoribus urbis erat"
  ],
  "steel dagger of fatigue": [
    "Pugio Adamanteus Defatigationis"
  ],
  "leather boots of waning fire": [
    "Corii Caligae Insenescentis Ignis"
  ],
  "where has alduin gone": [
    "Quo ivit Alduinis"
  ],
  "you're involved with the meadery": [
    "Operam in Hydromellario das"
  ],
  "brandy-mug farmhouse key": [
    "Clavis ad Fundum Vini-Poculis"
  ],
  "will you buy it": [
    "Id emere vis"
  ],
  "2920, sun's dusk, v11": [
    "[MMCMXX] MMCMXX, Occasus Solis, V. I"
  ],
  "hallowed glass greatsword": [
    "Consacrata Spatha Vitrea"
  ],
  "song of the askelde men": [
    "Carmen Virorum Askeldum"
  ],
  "orcish battleaxe of consuming": [
    "Orci Bellisecuris Consumendi"
  ],
  "blue dartwing": [
    "Papilio Alatela"
  ],
  "virtuous elven battleaxe": [
    "Virtutis Alfus Bellisecuris"
  ],
  "you trained with the greybeards": [
    "Cum Canabarbis exercuisti"
  ],
  "endrast's journal": [
    "Ephemeris Endrastis"
  ],
  "journeyman hammer": [
    "Journeyman Hammer"
  ],
  "lowlife": [
    "Lovlife"
  ],
  "octieve san": [
    "Octieve San"
  ],
  "ghostblade": [
    "Phantasmensis"
  ],
  "ancestors and the dunmer": [
    "Maiores Dunmeresque"
  ],
  "potion of illusion": [
    "Potio Inlusionis"
  ],
  "consider it yours": [
    "Id iam tuum arbitra"
  ],
  "a vampire": [
    "Vampirus"
  ],
  "ebony warhammer of storms": [
    "Bellimalleus Ebeni Tempestatium"
  ],
  "enmon": [
    "Enmon"
  ],
  "elven greatsword of harrowing": [
    "Spatha Alfa Occationis"
  ],
  "the watchtower was destroyed, but we killed the dragon": [
    "Turris deleta est, sed draconem necavimus"
  ],
  "watchtower was destroyed, but we killed the dragon": [
    "Turris deleta est, sed draconem necavimus"
  ],
  "hajvarr's journal": [
    "Ephemeris Hajvarris"
  ],
  "dwarven battleaxe of fire": [
    "Dvemeris Bellisecuris Ignis"
  ],
  "i'm here for elgrim's ore sample": [
    "Hic sum pro exemplari mineralis Elgrimis"
  ],
  "--": [
    "--"
  ],
  "riften hall of the dead": [
    "Hiati Hall of the Dead"
  ],
  "persisting magicka poison": [
    "Persistens Venenum Magicae"
  ],
  "will i get a cut": [
    "Accipiamne aliquid lucri"
  ],
  "orcish mace of frost": [
    "Orci Maca Gelidus"
  ],
  "steel plate armor of the eminent knight": [
    "Laminata Lorica Eminentis Equitis"
  ],
  "what coincidence": [
    "Quae coincidencia"
  ],
  "time to answer my questions": [
    "Tempus est ut responsa rogationibus meis des"
  ],
  "is there anything else you can tell me about the dagger": [
    "Estne quid aliud quod mihi de pugnione dicere possis"
  ],
  "steel sword of arcing": [
    "Gladius Adamanteus Electricitatis"
  ],
  "ice hazard": [
    "Ice Hazard"
  ],
  "reverent iron battleaxe": [
    "Reverens Bellisecuris Ferri"
  ],
  "amulet of talos": [
    "Monile Talos"
  ],
  "leather armor of the major squire": [
    "Corii Lorica Maioris Armiferis"
  ],
  "dwarven spider abilities": [
    "Dwemeris Spider abilities"
  ],
  "nirya wanted me to steal your notes": [
    "Nirya me adnotationes tuas furari voluit"
  ],
  "you stay hidden": [
    "Celatus mane"
  ],
  "i'll take care of this": [
    "Finem huius faciam"
  ],
  "can i sell this queen bee statue to you": [
    "Possumne hanc Statuam Reginae Apium vendere"
  ],
  "cicero, did you want to be the listener": [
    "Cicero, voluisi Auditorem esse"
  ],
  "you are a disgrace to our race": [
    "Discratia genti nostro es"
  ],
  "i spit on your fur": [
    "Despuo in pellem tuum"
  ],
  "generic dialogue for wilderness npcs": [
    "Generic dialogue for Wilderness NPCs"
  ],
  "ebony helmet of major destruction": [
    "Ebeni Galea Maioris Destructionis"
  ],
  "i'm here about goldenglow estate": [
    "Hic sum de Villa Aurilucis"
  ],
  "minutes": [
    "Minutas"
  ],
  "i'll trade in my cuirass": [
    "Loricam tibi dabo"
  ],
  "rd": [
    "ius"
  ],
  "torchbug": [
    "Facicimicem"
  ],
  "player friend": [
    "Amicus Lusoris"
  ],
  "there must be a way": [
    "Certe aliqua via est"
  ],
  "hide bracers of minor smithing": [
    "Chirothecae Cutis Minoris Ferrarii"
  ],
  "(optional) tell sven about faendal's letter": [
    "(Optio) Dic Sven de epistula Faendalis"
  ],
  "lockpick": [
    "Clavicator"
  ],
  "innocence, my brother": [
    "Innocentia, mi frater"
  ],
  "vals veran": [
    "Vals Veran"
  ],
  "dwarven greatsword of fear": [
    "Dvemeris Spatha Timoris"
  ],
  "orcish war axe of ice": [
    "Orci Bellisecuris Gelidus"
  ],
  "you take your work very seriously": [
    "Laborem tuum magni momenti putas"
  ],
  "mass charm - master of the mind": [
    "Permulce Omnibus - Dominus Mentis"
  ],
  "daedric bow of lightning": [
    "Arcus Daedricus Fulminis"
  ],
  "mark of dibella": [
    "Signum Dibellae"
  ],
  "audiotemplatebear": [
    "AudioTemplateBear"
  ],
  "steel plate helmet": [
    "Laminata Galea"
  ],
  "imperial archer": [
    "Imperialis Sagittarius"
  ],
  "solution of vigor": [
    "Solution Vigoris"
  ],
  "glass helmet of eminent alchemy": [
    "Galea Vitrea Alchemiae Eminentis"
  ],
  "is hroggar your father": [
    "Estne Hroggar pater tuus"
  ],
  "delphine's secret door key": [
    "Clavis ad Ianuam Secretam Delphinis"
  ],
  "hairlinefemaledarkelf05": [
    "HairLineFemaleDarkElf05"
  ],
  "i helped the young lovers in ivarstead": [
    "Amatores iuvenes Ivaridomus adiuvi"
  ],
  "the night mother's truth": [
    "Veritas Matris Noctis"
  ],
  "night mother's truth": [
    "Veritas Matris Noctis"
  ],
  "glass greatsword of binding": [
    "Spatha Vitrea Deligandi"
  ],
  "ebony mace of nullifying": [
    "Maca Ebeni Nullificandi"
  ],
  "ragnvald crypts": [
    "Ragnvaldis Hypogaea"
  ],
  "glass greatsword of fire": [
    "Spatha Vitrea Ignis"
  ],
  "doesn't scare me": [
    "Me non terret"
  ],
  "amulet of akatosh": [
    "Monile Akatoshis"
  ],
  "steel armor of the knight": [
    "Adamantea Lorica Equitis"
  ],
  "here's your goat": [
    "Hic habes capram"
  ],
  "now can you help me retrace my steps": [
    "Potesne nunc vestigia mea demonstrare"
  ],
  "imperial light shield of dwindling flames": [
    "Romana Parma Flammarum Diminuentium"
  ],
  "is life better under imperial rule": [
    "Estne vita melior sub regno Imperiali"
  ],
  "ebony war axe of animus": [
    "Bellisecuris Ebeni Animi"
  ],
  "bounty added to": [
    "Vectigal addita ad"
  ],
  "a dance in fire, v4": [
    "Saltatio in Flammis, IV"
  ],
  "dance in fire, v4": [
    "Saltatio in Flammis, IV"
  ],
  "hairmaleorc01": [
    "HairMaleOrc01"
  ],
  "tg08bkarliahintrobranchtopic": [
    "TG08BKarliahIntroBranchTopic"
  ],
  "ebony battleaxe of thunderbolts": [
    "Bellisecuris Ebeni Fulminum"
  ],
  "bribe": [
    "Pecuniam da"
  ],
  "drokt": [
    "Drokt"
  ],
  "daedric helmet of peerless archery": [
    "Galea Daedrica Optimae Saggitationis"
  ],
  "i'm here to deliver a message": [
    "Hic sum ut nuntium tibi adferam"
  ],
  "hanging gardens": [
    "Horti Pendentes"
  ],
  "elven battleaxe of immobilizing": [
    "Bellisecuris Alfus Immobilitatis"
  ],
  "steel gauntlets of minor smithing": [
    "Adamanteae Manicae Minoris Ferrarii"
  ],
  "reach imperial camp": [
    "Prehensi Castra Romana"
  ],
  "cicero's clothes": [
    "Ciceronis Vestimenta"
  ],
  "i hope things work out for you": [
    "Spero res bonae tibi erint"
  ],
  "search for survivors": [
    "Quaere Superstites"
  ],
  "no doubt you welcomed the stormcloaks with open arms": [
    "Non dubito quin tu Sturmmantelos salutem dixeris manubus apertis"
  ],
  "glass warhammer of expelling": [
    "Bellimaleus Vitreus Expellendi"
  ],
  "kr4": [
    "Kr4"
  ],
  "banded iron shield of blocking": [
    "Ferri Adligati Scutum Interponendi"
  ],
  "studded imperial armor of the squire": [
    "Romana Lorica Ornata Armigeris"
  ],
  "whirlwind sprint 2": [
    "Whirlwind Sprint 2"
  ],
  "you're a madman": [
    "Insanis"
  ],
  "spell tome: expel daedra": [
    "Liber Magicus: Expelle Daedram"
  ],
  "steel shield of dwindling shock": [
    "Adamanteum Scutum Electricitatis Diminuentis"
  ],
  "captain wayfinder": [
    "Nauclerus Vianventor"
  ],
  "philter of haggling": [
    "Philtrum Arguendi"
  ],
  "warehouse prefabs": [
    "Horreum Prefabs"
  ],
  "i'm not going anywhere without those books": [
    "Nusquam eo sine istis libris"
  ],
  "selveni nethri": [
    "Selveni Nethri"
  ],
  "how am i supposed to get rid of them": [
    "Quomodo eos removere possum"
  ],
  "the festival is back on": [
    "Festum iterum accidet"
  ],
  "festival is back on": [
    "Festum iterum accidet"
  ],
  "winterhold guard's armor": [
    "Hiemiterrae Custodis Lorica"
  ],
  "ma'zaka": [
    "Ma'zaka"
  ],
  "get to the bottom of what": [
    "Investigare quid"
  ],
  "elven bow of blizzards": [
    "Arcus Alfus Hiemis"
  ],
  "supple ancient nord bow": [
    "Melior Arcus Antiquus Germanicus"
  ],
  "db07astridcicerodeadnochoice": [
    "DB07AstridCiceroDeadNoChoice"
  ],
  "cherim's heart": [
    "Cor Cherimis"
  ],
  "orcish gauntlets of eminent archery": [
    "Orci Manicae Eminentis Saggitationis"
  ],
  "elven bracers of eminent lockpicking": [
    "Chirothecae Alfae Eminentis Clavicarii"
  ],
  "thalmor vs stormcloaks": [
    "Visigothi vs Stormcloaks"
  ],
  "daedric sword of thunderbolts": [
    "Gladius Daedricus Fulminum"
  ],
  "mystery of talara, v5": [
    "Misterium Talarae, Pars V"
  ],
  "we've run out of patience": [
    "Nulla patientia nobis manet"
  ],
  "how do i contact malacath": [
    "Quomodo Stygem adloqui possum"
  ],
  "faleen's letter to calcelmo": [
    "Epistula Falenis Calcelmoni"
  ],
  "ebony gauntlets of eminent sure grip": [
    "Ebeni Manicae Eminentis Ambidexteritatis"
  ],
  "executioner's hood": [
    "Carnificumeris Capucium"
  ],
  "malur seloth": [
    "Malur Seloth"
  ],
  "many will die by my hand": [
    "Multi manu mea morientur"
  ],
  "vekel the man": [
    "Vekel Homo"
  ],
  "this sounds like a terrible idea": [
    "Hoc mihi consilium pessimum videtur "
  ],
  "virtuous ebony dagger": [
    "Virtutis Pugio Ebeni"
  ],
  "orcish sword of frost": [
    "Orci Gladius Gelidus"
  ],
  "steel warhammer of dismay": [
    "Bellimalleus Adamanteus Timoris"
  ],
  "orcish gauntlets of extreme wielding": [
    "Orci Manicae Extremis Tenendi"
  ],
  "maleeyeshumanhazelbrown": [
    "MaleEyesHumanHazelBrown"
  ],
  "glass gauntlets of eminent archery": [
    "Vitreae Manicae Eminentis Saggitationis"
  ],
  "broken iron war axe handle": [
    "Fractus Bellisecuris Ferri Cherolaba"
  ],
  "creaturedialoguemammoth": [
    "CreatureDialogueMammoth"
  ],
  "glass warhammer of fear": [
    "Bellimaleus Vitreus Timoris"
  ],
  "hillevi cruel-sea": [
    "Hillevi Mare-Crudele"
  ],
  "what was that argument all about": [
    "De quo agebatur illum argumentum"
  ],
  "warpaintblue01": [
    "WarpaintBlue01"
  ],
  "daedric helmet of peerless destruction": [
    "Galea Daedrica Optimae Destructionis"
  ],
  "what do you want with the elder scroll": [
    "Quid vis me cum Codice Veterrimo facere"
  ],
  "how do you know this": [
    "Quomodo hoc scis"
  ],
  "adeber": [
    "Adeber"
  ],
  "mazgak": [
    "Mazgak"
  ],
  "tyranus": [
    "Tyranus"
  ],
  "elda": [
    "Elda"
  ],
  "maro": [
    "Maro"
  ],
  "forsworn ravager": [
    "Abiuratus Devastatrix"
  ],
  "mauhulakh": [
    "Mauhulakh"
  ],
  "you cannot fast travel while jumping or falling": [
    "Non potes celeriter peregrinari dum saltes vel cadas"
  ],
  "batum gra-bar": [
    "Batum gra-Bar"
  ],
  "tg01 kill actor monitor": [
    "TG01 Kill Actor Monitor"
  ],
  "raw rabbit leg": [
    "Crus Cuniculi Incocts"
  ],
  "tasius": [
    "Tasius"
  ],
  "urzoga": [
    "Urzoga"
  ],
  "adelaisa": [
    "Adelaisa"
  ],
  "windhelm pit": [
    "Ventigalea Pit"
  ],
  "elvali": [
    "Elvali"
  ],
  "bandit plunderer": [
    "Latro Despoliator"
  ],
  "salmon meat": [
    "Esiciae Caro"
  ],
  "cg dragon storm call self": [
    "CG Dragon Storm Call Self"
  ],
  "alain": [
    "Alain"
  ],
  "fathom": [
    "Ab-Imis"
  ],
  "only a dagger for the son of the jarl": [
    "Solo Pugio pro filio Comitis"
  ],
  "hairlinefemalenord15": [
    "HairLineFemaleNord15"
  ],
  "writeontable": [
    "WriteOnTable"
  ],
  "return to shadr": [
    "Redi ad Shadrem"
  ],
  "solitude sits on a natural arch, giving its port much needed shelter from the strong winds of the ghost sea": [
    "Solitudo in arcu naturali sedet, qui refugium e ventis fortibus Maris Phantasmarum ei dat"
  ],
  "bandit thug": [
    "Latro Opera"
  ],
  "felldir": [
    "Felldir"
  ],
  "addvar": [
    "Addvar"
  ],
  "horker loaf": [
    "Horker Panis"
  ],
  "the warrior stone ability lets you learn combat skills more quickly": [
    "Potestas Lapidis te artes pugnandi celerius discere sinet"
  ],
  "warrior stone ability lets you learn combat skills more quickly": [
    "Potestas Lapidis te artes pugnandi celerius discere sinet"
  ],
  "hafnar": [
    "Hafnar"
  ],
  "nasty bit of business, roggvir's execution": [
    "Res nefas, nex Roggviris"
  ],
  "kodlak": [
    "Kodlak"
  ],
  "self pacify": [
    "Self Pax"
  ],
  "pheasant breast": [
    "Fasiani Pectus"
  ],
  "phinis": [
    "Phinis"
  ],
  "dengeir": [
    "Dengeir"
  ],
  "skybound watch pass": [
    "Caeli Deligati Turris Commeatus"
  ],
  "urag": [
    "Urag"
  ],
  "brond": [
    "Brond"
  ],
  "speak to sapphire about shadr's debt": [
    "Sapphirem Adloquere de Aere Alieno Shadris"
  ],
  "hakon": [
    "Hakon"
  ],
  "the shadow stone power lets you become invisible once a day": [
    "Potestas Lapidis Umbrae te sinet invisibilem esse semel in die"
  ],
  "shadow stone power lets you become invisible once a day": [
    "Potestas Lapidis Umbrae te sinet invisibilem esse semel in die"
  ],
  "sergius": [
    "Sergius"
  ],
  "find out who \"n\" is": [
    "Disce quem \"N\" esse"
  ],
  "so where are the blood horkers": [
    "Ergo ubi sunt Horkers sanguinis"
  ],
  "bust of the gray fox": [
    "Imaguncula Vulpis Canuti"
  ],
  "mirabelle": [
    "Mirabelle"
  ],
  "leveling up grants a bonus to your choice of health, magicka, or stamina": [
    "Ad gradum novem ascendere addet ad Salutem, Magicam, vel Staminam"
  ],
  "choosing stamina also increases your carrying capacity": [
    "Eligere Staminam quoque podus portabile auget"
  ],
  "thonar": [
    "Thonar"
  ],
  "galmar": [
    "Galmar"
  ],
  "froki": [
    "Froki"
  ],
  "put on doors to keep npcs from opening": [
    "put on doors to keep NPCs from opening"
  ],
  "generic frost damage": [
    "Generic Frost Damage"
  ],
  "hairfemalenord16": [
    "HairFemaleNord16"
  ],
  "ebony blade enchantment": [
    "Incantamentum Ensis Ebeni"
  ],
  "idolaf": [
    "Idolaf"
  ],
  "gormlaith": [
    "Gormlaith"
  ],
  "argi": [
    "Argi"
  ],
  "aldis": [
    "Aldis"
  ],
  "edorfin": [
    "Edorfin"
  ],
  "brelyna": [
    "Brelyna"
  ],
  "vittoria": [
    "Vittoria"
  ],
  "skaggi": [
    "Skaggi"
  ],
  "brelyna's practice spell": [
    "Magica Brelynae"
  ],
  "roggi": [
    "Roggi"
  ],
  "nilsine": [
    "Nilsine"
  ],
  "letter from <alias=questgiver>": [
    "Epistula ab <Alias=QuestGiver>"
  ],
  "alfhild": [
    "Alfhild"
  ],
  "\"tradition has it that the nords came to tamriel from the continent of atmora in ancient days.\" -- unknown, pocket guide to the empire": [
    "\"Nobis traditur Germani ad Tamrielem e terra Atmorae diebus antiquis.\" -- Auctor Inscitus, Ductor Fundae Imperio"
  ],
  "drevis": [
    "Drevis"
  ],
  "the block skill reduces the damage and stagger from physical attacks when blocking with either a shield or weapon": [
    "Ars interponendi vulnus et claudicationem diminuit"
  ],
  "block skill reduces the damage and stagger from physical attacks when blocking with either a shield or weapon": [
    "Ars interponendi vulnus et claudicationem diminuit"
  ],
  "hermir": [
    "Hermir"
  ],
  "forsworn forager": [
    "Abiuratus Pabulator"
  ],
  "thieves guild dialogue scene 18": [
    "Sodalitatis Furum Dialogue Scene 18"
  ],
  "find the secret of bleak falls barrow": [
    "Inveni secretum Pabilli Cataractarum Obscurarum"
  ],
  "choose an item to destroy and learn its enchantment, or combine an item, enchantment, and soul gem to create magic items": [
    "Elige rem quam delebis ut effecta discas vel adde rem, incantamentum, at animigemmam ut res magicas facias"
  ],
  "mehrune's razor blade": [
    "Novaculae Croni Ensis"
  ],
  "brunwulf": [
    "Brunvulf"
  ],
  "yamarz": [
    "Yamarz"
  ],
  "runil was one of the thalmor during the war": [
    "Runil erat unus ex Visigothis in bello"
  ],
  "hairfemalenord17": [
    "HairFemaleNord17"
  ],
  "yar": [
    "Yar"
  ],
  "thongvor": [
    "Thongvor"
  ],
  "ghorbash": [
    "Ghorbash"
  ],
  "michel": [
    "Michel"
  ],
  "balagog gro-nolob": [
    "Balagog gro-Nolob"
  ],
  "bandit outlaw": [
    "Latro Utlago"
  ],
  "noster": [
    "Noster"
  ],
  "ebony blade alert": [
    "Ebony Blade Alert"
  ],
  "windhelm barracks": [
    "Ventigaleae Tabernacula"
  ],
  "the atronach stone ability allows you to absorb some magicka from enemies' spells and gives you extra magicka, but it slows your magicka regeneration": [
    "Lapis Atronachorum te magicam absorbere sinet et plus Magicae tibi dat, sed magicam lentius recuperabis"
  ],
  "atronach stone ability allows you to absorb some magicka from enemies' spells and gives you extra magicka, but it slows your magicka regeneration": [
    "Lapis Atronachorum te magicam absorbere sinet et plus Magicae tibi dat, sed magicam lentius recuperabis"
  ],
  "cynric": [
    "Cynric"
  ],
  "giraud": [
    "Giraud"
  ],
  "larak": [
    "Larak"
  ],
  "\"i am the queen of solitude, daughter of the emperor! summon the daedra! i'll trade the soul of every last subject of mine for a little comfort.\" -- queen potema, 3e 97": [
    "\"Regina Solitudinis, Filia Imperatoris! Voca Daedras! Animos omnium civium meam tradam pro paulo solatii.\" -- Regina Potema, Aetatis Tertiae XCVII"
  ],
  "guard's orders": [
    "Mandata Custodis"
  ],
  "aela": [
    "Aela"
  ],
  "jaree": [
    "Jaree"
  ],
  "vignar": [
    "Vignar"
  ],
  "mammoth steak": [
    "Elephantis Caro"
  ],
  "balagog": [
    "Balagog"
  ],
  "the apprentice stone ability doubles your magicka and regeneration speed, but makes you twice as susceptible to spells": [
    "Lapis Novitii tibi duplicem magicam et velocitatem magicam recuperandi dat, sed quoque te plus vulnerabilem magicae facit"
  ],
  "apprentice stone ability doubles your magicka and regeneration speed, but makes you twice as susceptible to spells": [
    "Lapis Novitii tibi duplicem magicam et velocitatem magicam recuperandi dat, sed quoque te plus vulnerabilem magicae facit"
  ],
  "yar gro-gatuk": [
    "Yar gro-Gatuk"
  ],
  "sibbi": [
    "Sibbi"
  ],
  "rolff": [
    "Rolff"
  ],
  "the power from the lady's stone lets you regenerate health and stamina much faster for a while": [
    "Potestas ab Lapidis Matronae te salutem staminamque multo rapidius recuperare sinet"
  ],
  "power from the lady's stone lets you regenerate health and stamina much faster for a while": [
    "Potestas ab Lapidis Matronae te salutem staminamque multo rapidius recuperare sinet"
  ],
  "the lover stone ability lets you learn all skills a little faster, but not as much as the thief, warrior or mage stones": [
    "Lapis Amatoris te sinet omnes artes celerius discere, sed non tantum ut lapides Furis, Bellatoris, Magive"
  ],
  "lover stone ability lets you learn all skills a little faster, but not as much as the thief, warrior or mage stones": [
    "Lapis Amatoris te sinet omnes artes celerius discere, sed non tantum ut lapides Furis, Bellatoris, Magive"
  ],
  "venison chop": [
    "Cervina Confecta"
  ],
  "forsworn pillager": [
    "Abiuratus Direptor"
  ],
  "abandoned prison key": [
    "Clavis ad Carcerem Desertum"
  ],
  "ruffian": [
    "Ruffian"
  ],
  "grimvar": [
    "Grimvar"
  ],
  "susanna": [
    "Susanna"
  ],
  "hairlinefemalenord16": [
    "HairLineFemaleNord16"
  ],
  "alduin is real": [
    "Alduinus Verus est"
  ],
  "horse meat": [
    "Equi Caro"
  ],
  "increase damage from alduin": [
    "Increase Damage from Alduin"
  ],
  "tullius": [
    "Cicero"
  ],
  "pavo": [
    "Pavo"
  ],
  "nord hero arrow": [
    "Sagitta Heroos Germanici"
  ],
  "malyn": [
    "Malyn"
  ],
  "savos": [
    "Savos"
  ],
  "quintus": [
    "Quintus"
  ],
  "master illusion text": [
    "Liber Domini Inlusionis"
  ],
  "you can't fast travel while taking health damage": [
    "Celeriter peregrinari non potes dum vulnus salutis accipias"
  ],
  "hairfemalenord18": [
    "HairFemaleNord18"
  ],
  "hag rock ruin jail key": [
    "Clavis ad Carcerem Ruinarum Sagae Saxi"
  ],
  "colette": [
    "Colette"
  ],
  "wuunferth": [
    "Vuunferth"
  ],
  "sondas": [
    "Sondas"
  ],
  "so do you remember anything i said when i got here": [
    "Ergo recordarisne quiquid quod dixi cum advenirem"
  ],
  "carcette": [
    "Carcette"
  ],
  "sam": [
    "Sam"
  ],
  "hairfemalenord20": [
    "HairFemaleNord20"
  ],
  "dcetattack": [
    "DCETAttack"
  ],
  "reburrus": [
    "Reburrus"
  ],
  "maven": [
    "Maven"
  ],
  "thorald": [
    "Thorald"
  ],
  "the smithing skill is used to create weapons and armor from raw materials, or improve existing non-magical weapons and armor": [
    "Ars Ferrarii usurpatur ut arma vel loricas facias, vel arma loricasque meliores facere"
  ],
  "smithing skill is used to create weapons and armor from raw materials, or improve existing non-magical weapons and armor": [
    "Ars Ferrarii usurpatur ut arma vel loricas facias, vel arma loricasque meliores facere"
  ],
  "kill the imposter valmir": [
    "Neca Praestigiatorem Valmir"
  ],
  "when the high king dies, the jarls of skyrim convene a moot to elect a new high king": [
    "Post mortem Regi Alti, Comites Caelifinis conveniunt in \"Moot\" ut novum Regem Altum Eligant"
  ],
  "carlotta": [
    "Carlotta"
  ],
  "hairfemalenord15": [
    "HairFemaleNord15"
  ],
  "the jarl of falkreath is siddgeir": [
    "Comes Falkretiae est Siddgeir"
  ],
  "jarl of falkreath is siddgeir": [
    "Comes Falkretiae est Siddgeir"
  ],
  "among the youngest of the jarls in skyrim, siddgeir took the place of the former jarl, his uncle dengeir": [
    "Apud iuvenissimos Comitum in Caelifine, Siddgeir locum Comitis anterioris, avunculi eius Dengeir, usurpatus est"
  ],
  "hairlinefemalenord14": [
    "HairLineFemaleNord14"
  ],
  "julienne": [
    "Julienne"
  ],
  "tova": [
    "Tova"
  ],
  "<font color='#ff0000'>steal from</font>": [
    "<font color='#FF0000'>Furare ab </font>"
  ],
  "verner": [
    "Verner"
  ],
  "logrolf": [
    "Logrolf"
  ],
  "ravyn": [
    "Ravyn"
  ],
  "moth": [
    "Moth"
  ],
  "angela... mrs. morrard... your daughter is dead": [
    "Angela... Domina Morrard... filia tua mortua est"
  ],
  "delvin": [
    "Delvin"
  ],
  "gadba": [
    "Gadba"
  ],
  "grisvar": [
    "Grisvar"
  ],
  "tacitus": [
    "Tacitus"
  ],
  "dockworker": [
    "Laborator Navalis"
  ],
  "sorex": [
    "Sorex"
  ],
  "mehrune's razor gem": [
    "Novaculae Croni Gemma"
  ],
  "stabilized": [
    "Stabilis"
  ],
  "silda": [
    "Silda"
  ],
  "vile": [
    "Vile"
  ],
  "roots": [
    "Radices"
  ],
  "unowned cell": [
    "Unowned Cell"
  ],
  "ulfric": [
    "Vercingetorix"
  ],
  "brina": [
    "Brina"
  ],
  "i don't know the heal other spell": [
    "Magicam qua alii sanantur nescio"
  ],
  "the thief stone ability lets you learn stealth skills more quickly": [
    "Potestas Lapidis Furis te artes clandestinas celerius discere sinet"
  ],
  "thief stone ability lets you learn stealth skills more quickly": [
    "Potestas Lapidis Furis te artes clandestinas celerius discere sinet"
  ],
  "falk": [
    "Falk"
  ],
  "hairfemalenord13": [
    "HairFemaleNord13"
  ],
  "\"questing heroes of all stripes seek after the fabled daedric artifacts for their potent combat and magical benefits.\" -- haderus of gottlesfont, from modern heretics": [
    "\"Heroes omnium modorum opera daedrica pro beneficiis belli et magicae quaerent.\" -- Haderus Gottlesfontis, Hereticis Modernis"
  ],
  "pit meat": [
    "Pit Meat"
  ],
  "burguk": [
    "Burguk"
  ],
  "prisoner's plan": [
    "Consilium Captivi"
  ],
  "increase %s": [
    "Adde ad %s"
  ],
  "thieves guild dialogue scene 20": [
    "Sodalitatis Furum Dialogue Scene 20"
  ],
  "bthardamz workshop": [
    "Bthardamz Fabrica"
  ],
  "tg03 kill actor monitor": [
    "TG03 Kill Actor Monitor"
  ],
  "radiant raiment fine clothes": [
    "Vestimenta Radiantia Vestimenta Nobilia"
  ],
  "maleheaddremora": [
    "MaleHeadDremora"
  ],
  "cipius": [
    "Cipius"
  ],
  "sorli": [
    "Sorli"
  ],
  "humanfemaleskinwhite03": [
    "HumanFemaleSkinWhite03"
  ],
  "humanfemaleskinwhite04": [
    "HumanFemaleSkinWhite04"
  ],
  "humanfemaleskinwhite06": [
    "HumanFemaleSkinWhite06"
  ],
  "humanfemaleskinwhite07": [
    "HumanFemaleSkinWhite07"
  ],
  "humanfemaleskinwhite08": [
    "HumanFemaleSkinWhite08"
  ],
  "reachmen map": [
    "Reachmen Charta"
  ],
  "humanfemaleskinwhite09": [
    "HumanFemaleSkinWhite09"
  ],
  "hairlinefemalenord18": [
    "HairLineFemaleNord18"
  ],
  "humanfemaleskinwhite10": [
    "HumanFemaleSkinWhite10"
  ],
  "dawnstar jail": [
    "Carcer Luciferis"
  ],
  "sebastian lort": [
    "Sebastianus Lort"
  ],
  "generic damage": [
    "Generic Damage"
  ],
  "execute general tullius": [
    "Neca Imperatorem Tullium"
  ],
  "camilla valerius": [
    "Camilla Valerius"
  ],
  "danica": [
    "Danica"
  ],
  "whiterun tundra": [
    "Whiterun Tundra"
  ],
  "valkygg": [
    "Valkygg"
  ],
  "goon": [
    "Goon"
  ],
  "immunity to damage": [
    "Immunity to damage"
  ],
  "forsworn looter": [
    "Abiuratus Praedo"
  ],
  "angeline": [
    "Angeline"
  ],
  "the higher the enchanting skill, the stronger the magic that can be bound to weapons and armor": [
    "Tam alta Ars Incantandi, quam fortis magica in armis loricasque"
  ],
  "higher the enchanting skill, the stronger the magic that can be bound to weapons and armor": [
    "Tam alta Ars Incantandi, quam fortis magica in armis loricasque"
  ],
  "the lockpicking skill is used to open locked doors and containers faster and with fewer broken lockpicks": [
    "Ars Clavicarii usurpatur ut ianuas et arcas clauses celerius aperias"
  ],
  "lockpicking skill is used to open locked doors and containers faster and with fewer broken lockpicks": [
    "Ars Clavicarii usurpatur ut ianuas et arcas clauses celerius aperias"
  ],
  "the sneak skill makes it more difficult to be detected, which in turn allows for deadly stealth attacks": [
    "Ars rependi te difficilius inventu facit, et quoque ictus dum repens auget"
  ],
  "sneak skill makes it more difficult to be detected, which in turn allows for deadly stealth attacks": [
    "Ars rependi te difficilius inventu facit, et quoque ictus dum repens auget"
  ],
  "the mage stone ability lets you learn magic skills more quickly": [
    "Ars Lapidis Magi te artes magicas celerius discere sinet"
  ],
  "mage stone ability lets you learn magic skills more quickly": [
    "Ars Lapidis Magi te artes magicas celerius discere sinet"
  ],
  "sulvar": [
    "Sulvar"
  ],
  "the ritual stone power lets you temporarily resurrect nearby corpses to fight for you, once a day": [
    "Lapis Ritualis te corpora prope te revocare sinet - semel in die"
  ],
  "ritual stone power lets you temporarily resurrect nearby corpses to fight for you, once a day": [
    "Lapis Ritualis te corpora prope te revocare sinet - semel in die"
  ],
  "the serpent stone power lets you paralyze and poison a target once a day": [
    "Potestas Lapidis Serpentis te sinet unum inimicum paralticum et venenatum facere semel in die"
  ],
  "serpent stone power lets you paralyze and poison a target once a day": [
    "Potestas Lapidis Serpentis te sinet unum inimicum paralticum et venenatum facere semel in die"
  ],
  "the steed stone ability lets you carry more, and not be slowed down by your armor": [
    "Potestas Lapidis Equi te plus portare sinet"
  ],
  "steed stone ability lets you carry more, and not be slowed down by your armor": [
    "Potestas Lapidis Equi te plus portare sinet"
  ],
  "ennodius": [
    "Ennodius"
  ],
  "each of the nine holds is governed by a jarl": [
    "Omnes Novem Urbium Comites ut duces habent"
  ],
  "the jarls are largely independent, but they swear fealty to skyrim's high king": [
    "Comites independentes sunt, sed fidelitatem ad Regem Altum Caelifinis iurant"
  ],
  "jarls are largely independent, but they swear fealty to skyrim's high king": [
    "Comites independentes sunt, sed fidelitatem ad Regem Altum Caelifinis iurant"
  ],
  "stig": [
    "Stig"
  ],
  "inge": [
    "Inge"
  ],
  "you can only have one standing stone active at a time": [
    "Benedictionem unius lapidis stantis habere potes"
  ],
  "if you accept the bonus from a new one, it replaces any bonuses from the previously used stone": [
    "Si novum accipias, vetus ammitas"
  ],
  "attacks can be blocked with either a shield or a weapon, but shields are much more effective": [
    "Ictus vel scuto vel armo interponi possunt, sed scuta multo efficaciora sunt"
  ],
  "the fourth era began 200 years ago when martin septim sacrificed himself to save the world from the oblivion crisis": [
    "Aetas Quarta coepit abhinc CC annos, cum Martin Septim se sacrificavit ut mundum e Clade Obliovionis servet"
  ],
  "fourth era began 200 years ago when martin septim sacrificed himself to save the world from the oblivion crisis": [
    "Aetas Quarta coepit abhinc CC annos, cum Martin Septim se sacrificavit ut mundum e Clade Obliovionis servet"
  ],
  "\"pelagius iii, sometimes called pelagius the mad, was proclaimed emperor in the 145th year of the third era.\" -- stronach k'thojj iii, brief history of the empire vol. ii": [
    "\"Pelagius III, quoque ut Pelagius Insanus scitus, Imperator factus est Anno CXLV Aetatis Tertiae.\" -- Stronach k'Thojj III, Historia Brevis Imperii vol. II"
  ],
  "\"out of this time also rose one of the most legendary of all tamrielic figures, ysgramor, from whom all nordic kings to this day are descended.\" -- unknown, pocket guide to the empire": [
    "\"Ex hoc tempore quoque advenit notissimus omnium figurarum Tamrielis, Ysgramoris, ex quo omnes reges Germanicos adhuc nati sunt.\" -- Incognitus, Rector Fundae Imperio"
  ],
  "request from": [
    "Rogatum ab"
  ],
  "\"skyrim, also known as the old kingdom or the fatherland, was the first region of tamriel settled by humans... \" -- unknown, provinces of tamriel": [
    "\"Caelifinis, etiam ut Regnum Vetus vel Patria, erat regio prima Tamrielis a hominabus incolata... \" -- Auctor Inscitus, Provinciae Tamrielis"
  ],
  "(optional) steal the lodge stash": [
    "(Optio) Furare Gazam e Casa"
  ],
  "speak to from deepest fathoms": [
    "Adloquere Ab-imis-profunditatibus"
  ],
  "vivienne": [
    "Vivienne"
  ],
  "return the valuables to vex": [
    "Redde Opes ad Vex"
  ],
  "clear <alias=wealthyhome> in <alias=city> of valuables (<global=tgrhcvaluablesstolen>/<global=tgrhcvaluablestotal>)": [
    "Remove bona e <Alias=WealthyHome> in <Alias=City> (<Global=TGRHCValuablesStolen>/<Global=TGRHCValuablesTotal>)"
  ],
  "runil's journal": [
    "Ephemeris Runilis"
  ],
  "open the skills menu to level up": [
    "Potes ad gradum novum ascendere"
  ],
  "return to torsten cruel-sea": [
    "Redi ad Torsten Mare-Crudele"
  ],
  "(optional) destroy the summerset shadow's banner": [
    "(Optio) Dele Vexillum Umbrae Aestivoccidentis"
  ],
  "travel to uttering hills cave": [
    "Iter fac ad Cavum Collium Sussurantium"
  ],
  "tsun": [
    "Tsun"
  ],
  "(optional) discover leverage to sway niranye": [
    "(Optio) Inveni evidentiam quae mentem Niranyis mutare potest"
  ],
  "muiri says goodbye": [
    "Muiri valedicit"
  ],
  "forever": [
    "Sempiterniter"
  ],
  "speak to torsten cruel-sea": [
    "Torsten Mare-Crudele Adloqere"
  ],
  "summerset shadows": [
    "Umbrae Aestivoccidentis"
  ],
  "runil's dark past": [
    "Runil's Dark Past"
  ],
  "retrieve the fragment of wuuthrad": [
    "Inveni fragmentum Wuuthradis"
  ],
  "ildene": [
    "Ildene"
  ],
  "ask <alias.shortname=target> for information about <alias.shortname=questgiver>'s daughter": [
    "Roga Informatum ab <Alias.ShortName=Target> de Filia <Alias.ShortName=Questgiver>"
  ],
  "thieves guild dialogue scene 17": [
    "Sodalitatis Furum Dialogue Scene 17"
  ],
  "plautis": [
    "Plautis"
  ],
  "thieves guild dialogue scene 19": [
    "Sodalitatis Furum Dialogue Scene 19"
  ],
  "friendly fire": [
    "Friendly Fire"
  ],
  "varnius": [
    "Varnius"
  ],
  "idgrod": [
    "Idgrod"
  ],
  "i don't know the magelight spell": [
    "Magilucem nescio"
  ],
  "warehouses": [
    "Warehouses"
  ],
  "valga": [
    "Valga"
  ],
  "humanfemaleskinwhite01": [
    "HumanFemaleSkinWhite01"
  ],
  "i don't know the firebolt spell": [
    "Sagittignam nescio"
  ],
  "hairlinefemalenord20": [
    "HairLineFemaleNord20"
  ],
  "bearclaw": [
    "Ursungues"
  ],
  "ancient nord war axe": [
    "Antiquus Germanicus Bellisecuris"
  ],
  "fralia": [
    "Fralia"
  ],
  "how do you keep this food so fresh": [
    "Quomodo tam viridis manet hic cibus"
  ],
  "hairfemalenord19": [
    "HairFemaleNord19"
  ],
  "you lack the required components to create this item": [
    "Rebus postulatis cares"
  ],
  "evette": [
    "Evette"
  ],
  "mehrune's razor scabbard": [
    "Novaculae Croni Vagina"
  ],
  "frost's identity papers": [
    "Chartae Nominis Glaciei"
  ],
  "speak to niranye": [
    "Niranyem Adloquere"
  ],
  "pit lord": [
    "Pit Lord"
  ],
  "horse haunch": [
    "Equi Corpus"
  ],
  "hairfemalenord14": [
    "HairFemaleNord14"
  ],
  "mammoth snout": [
    "Elephantis Proboscis"
  ],
  "humanfemaleskinwhite02": [
    "HumanFemaleSkinWhite02"
  ],
  "dalan": [
    "Dalan"
  ],
  "i don't know the fear spell": [
    "Magicam timoris nescio"
  ],
  "pantea": [
    "Pantea"
  ],
  "calixto": [
    "Calixto"
  ],
  "elisif": [
    "Elisif"
  ],
  "ancient nord sword": [
    "Gladius Antiquus Germanicus"
  ],
  "daynas": [
    "Daynas"
  ],
  "irnskar": [
    "Irnskar"
  ],
  "yngvar": [
    "Yngvar"
  ],
  "wayfinder": [
    "Viaventor"
  ],
  "the sweep job": [
    "Munus \"Sweep\""
  ],
  "sweep job": [
    "Munus \"Sweep\""
  ],
  "betrid": [
    "Betrid"
  ],
  "gestur": [
    "Gestur"
  ],
  "jordis": [
    "Jordis"
  ],
  "gaius": [
    "Gaius"
  ],
  "marauder": [
    "Pirata"
  ],
  "pheasant roast": [
    "Fasiani Decoctum"
  ],
  "venison": [
    "Cervina"
  ],
  "wyndelius": [
    "Vyndelius"
  ],
  "miasma": [
    "Miasmam"
  ],
  "argis": [
    "Argis"
  ],
  "leg of goat roast": [
    "Crus Capri Decoctum"
  ],
  "viola": [
    "Viola"
  ],
  "mikrul": [
    "Mikrul"
  ],
  "hairlinefemalenord13": [
    "HairLineFemaleNord13"
  ],
  "angrenor": [
    "Angrenor"
  ],
  "i have nothing to hide": [
    "Nihil latendum habeo"
  ],
  "aval": [
    "Aval"
  ],
  "kesh": [
    "Kesh"
  ],
  "horik": [
    "Horik"
  ],
  "high king torygg": [
    "Rex Altus Torygg"
  ],
  "strongbox": [
    "Arca Secura"
  ],
  "anton": [
    "Anton"
  ],
  "verulus": [
    "Verulus"
  ],
  "amulet of articulation": [
    "Monile Loquax"
  ],
  "cg dragon storm call": [
    "CG Dragon Storm Call"
  ],
  "alessandra": [
    "Alessandra"
  ],
  "cups": [
    "Cups"
  ],
  "sosia": [
    "Sosia"
  ],
  "gat": [
    "Gat"
  ],
  "hero of sovngarde": [
    "Heroos Sovngardis"
  ],
  "unowned chest": [
    "Arca Sine Domine"
  ],
  "aquillius": [
    "Aquillius"
  ],
  "leontius": [
    "Leontius"
  ],
  "forsworn warlord": [
    "Abiuratus Bellidominus"
  ],
  "gemma": [
    "Gemma"
  ],
  "hogni": [
    "Hogni"
  ],
  "torygg": [
    "Torygg"
  ],
  "bandit marauder": [
    "Latro Depopulator"
  ],
  "arniel": [
    "Arniel"
  ],
  "fast travel is currently unavailable from this location": [
    "Do hoc loco celeriter peregrinari non potes"
  ],
  "sulla": [
    "Sulla"
  ],
  "warehouse traps reset test guy": [
    "Warehouse Traps Reset Test Guy"
  ],
  "ulfgar the unending": [
    "Ulfgar Interminabilis"
  ],
  "you hunt daedra": [
    "Daedras venaris"
  ],
  "black-briar mercenary": [
    "Atri-Dumi Mercenarius"
  ],
  "metilius": [
    "Metilius"
  ],
  "lu'ah": [
    "Lu'ah"
  ],
  "hairlinefemalenord17": [
    "HairLineFemaleNord17"
  ],
  "m'aiq": [
    "M'aiq"
  ],
  "erlendr": [
    "Erlendr"
  ],
  "gildergreen sapling": [
    "Gildergreen Arboriculus"
  ],
  "vinius": [
    "Vinius"
  ],
  "thrasher": [
    "Thrasher"
  ],
  "gunnar": [
    "Gunnar"
  ],
  "njada": [
    "Njada"
  ],
  "humanfemaleskinwhite05": [
    "HumanFemaleSkinWhite05"
  ],
  "vantus": [
    "Vantus"
  ],
  "blasphemous priest": [
    "Sacerdos Inreligiosus"
  ],
  "borgakh": [
    "Borgakh"
  ],
  "you lack the required components to improve this item": [
    "Rebus postulatis cares"
  ],
  "this npc cannot help you train any higher": [
    "Hic NPC non te plus docere potest"
  ],
  "rogatus": [
    "Rogatus"
  ],
  "huki": [
    "Huki"
  ],
  "ambarys": [
    "Ambarys"
  ],
  "revyn": [
    "Revyn"
  ],
  "we were supposed to be learning about something": [
    "De aliquo discere deberemus"
  ],
  "jouane": [
    "Jouane"
  ],
  "ganna": [
    "Ganna"
  ],
  "arrownordheroprojectile": [
    "ArrowNordHeroProjectile"
  ],
  "indara": [
    "Indara"
  ],
  "balgruuf": [
    "Balgruuf"
  ],
  "nepos": [
    "Nepos"
  ],
  "tg04 kill actor monitor": [
    "TG04 Kill Actor Monitor"
  ],
  "aranea": [
    "Aranea"
  ],
  "olfina": [
    "Olfina"
  ],
  "silana": [
    "Silana"
  ],
  "octieve": [
    "Octieve"
  ],
  "mahkel": [
    "Mahkel"
  ],
  "you will die as you lived": [
    "Morieris ut vixisti"
  ],
  "an arrogant joke": [
    "Ut iocus arrogans"
  ],
  "azzada": [
    "Azzada"
  ],
  "tilma": [
    "Tilma"
  ],
  "rabbit haunch": [
    "Cuniculi Corpus"
  ],
  "leg of goat": [
    "Crus Capri"
  ],
  "morthal jail": [
    "Carcer Morthalis"
  ],
  "aia": [
    "Aia"
  ],
  "torbjorn": [
    "Torbjorn"
  ],
  "abandoned prison": [
    "Carcer Derelictus"
  ],
  "woodcutter's axe": [
    "Lignisecuris"
  ],
  "hairlinefemalenord19": [
    "HairLineFemaleNord19"
  ],
  "recover fjolti's silver locket": [
    "Inveni Monile Argentei Fjolti"
  ],
  "ghorza": [
    "Ghorza"
  ],
  "farengar": [
    "Farengar"
  ],
  "annekke": [
    "Annekke"
  ],
  "mulush": [
    "Mulush"
  ],
  "eorlund": [
    "Eorlund"
  ],
  "hunroor": [
    "Hunroor"
  ],
  "dockmaster": [
    "Magister Navalis"
  ],
  "vigdis": [
    "Vigdis"
  ],
  "jenssen": [
    "Jenssen"
  ],
  "malthyr": [
    "Malthyr"
  ],
  "i don't know the spell for summoning a flame atronach": [
    "Magicam qua atronachum flammarum vocatur nescio"
  ],
  "mehrune's razor hilt": [
    "Novaculae Croni Capulum"
  ],
  "oengul": [
    "Oengul"
  ],
  "silus": [
    "Silus"
  ],
  "storm mage": [
    "Electrimagus"
  ],
  "hairmaledarkelf06": [
    "HairMaleDarkElf06"
  ],
  "storm wizard": [
    "Thaumatorgos Electricitatis"
  ],
  "storm mage adept": [
    "Electrimagus Adeptus"
  ],
  "storage cabinet": [
    "Armarium Rebus"
  ],
  "penitus oculatus agent": [
    "Agens Penitorum Oculatorum"
  ],
  "novice storm mage": [
    "Novitius Electrimagus"
  ],
  "ascendant necromancer": [
    "Necromanticus Ascendens"
  ],
  "$master": [
    "$Master"
  ],
  "imperial luck": [
    "Felicitas Imperialis"
  ],
  "blank": [
    "Blank"
  ],
  "novice necromancer": [
    "Novitius Necromanticus"
  ],
  "witch": [
    "Saga"
  ],
  "apprentice necromancer": [
    "Discipulus Necromanticus"
  ],
  "clam meat": [
    "Peloris Caro"
  ],
  "novice ice mage": [
    "Novitius Glacimagus"
  ],
  "hag": [
    "Striga"
  ],
  "necromancer": [
    "Necromanticus"
  ],
  "necromancer adept": [
    "Necromanticus Adeptus"
  ],
  "hairshavedmaledarkelf04": [
    "HairShavedMaleDarkElf04"
  ],
  "apprentice storm mage": [
    "Discipulus Electrimagus"
  ],
  "draugr wight": [
    "Draugr Amortuus"
  ],
  "fire mage adept": [
    "Ignimagus Adeptus"
  ],
  "hairshavedmaledarkelf05": [
    "HairShavedMaleDarkElf05"
  ],
  "draugr scourge": [
    "Draugr Flagrum"
  ],
  "restless draugr": [
    "Draugr Inquies"
  ],
  "fine raiment": [
    "Ornata Vestimenta"
  ],
  "dragon soul absorbed": [
    "Animus Draconis Absorptus"
  ],
  "electromancer": [
    "Electrimagus"
  ],
  "audiocategorynpckillmove": [
    "AudioCategoryNPCKillMove"
  ],
  "i'm ready to return to tamriel": [
    "Paratus sum Tamrielem revenire"
  ],
  "hairlinemaledarkelf06": [
    "HairLineMaleDarkElf06"
  ],
  "hairmaledarkelf07": [
    "HairMaleDarkElf07"
  ],
  "master necromancer": [
    "Dominus Necromanticus"
  ],
  "novice fire mage": [
    "Novitius Ignimagus"
  ],
  "desecrated corpse": [
    "Corpus Desacratum"
  ],
  "ebony bladed friendship": [
    "Ebony Bladed Friendship"
  ],
  "invisibility potion recipe": [
    "Dictamen Potionis Invisibilitatis"
  ],
  "hairlinemaleelf05": [
    "HairLineMaleElf05"
  ],
  "what's so important about the staff of magnus": [
    "Quid tam magni momenti est de Rude Magno"
  ],
  "dog meat": [
    "Vaccae Caro"
  ],
  "humanbeard00nobeard": [
    "HumanBeard00NoBeard"
  ],
  "maleeyeswoodelfrightblindsingle": [
    "MaleEyesWoodElfRightBlindSingle"
  ],
  "kyneswatch": [
    "Kynivisus"
  ],
  "snow fox pelt": [
    "Noctis Vulpinis Reno"
  ],
  "prowler's profit": [
    "Fructus Furunculi"
  ],
  "hairfemaleelf04": [
    "HairFemaleElf04"
  ],
  "the path of shadow": [
    "Via Umbrosa"
  ],
  "path of shadow": [
    "Via Umbrosa"
  ],
  "treasure map ix": [
    "Gazae Charta IX"
  ],
  "marksmalekhajiitscar05": [
    "MarksMaleKhajiitScar05"
  ],
  "maleeyeswoodelfleftblindsingle": [
    "MaleEyesWoodElfLeftBlindSingle"
  ],
  "intense flames rout": [
    "Effugitum Flammarum Potentium"
  ],
  "cats of skyrim": [
    "Felines Caelifinis"
  ],
  "staggerfun": [
    "StaggerFun"
  ],
  "twisted root cave": [
    "Spelunca Stirpum Geniculatarum"
  ],
  "maleeyeswoodelflightbrownblindleft": [
    "MaleEyesWoodElfLightBrownBlindLeft"
  ],
  "marksmalekhajiitscar02": [
    "MarksMaleKhajiitScar02"
  ],
  "treasure map iv": [
    "Gazae Charta IV"
  ],
  "familiar visuals": [
    "Familiar Visuals"
  ],
  "á é í ó ú ñ ü ç ¿ ¡ ª º á é í ó ú ñ ü ç": [
    "Ã¡ Ã© Ã­ Ã³ Ãº Ã± Ã¼ Ã§ Â¿ Â¡ Âª Âº Ã Ã‰ Ã Ã“ Ãš Ã‘ Ãœ Ã‡"
  ],
  "the totems of hircine": [
    "Totemna Hecatis"
  ],
  "black-briar reserve": [
    "Atri-Dumi Reservatum"
  ],
  "vampiric strength": [
    "Fortitudo Vampirica"
  ],
  "marksmalekhajiitscars02": [
    "MarksMaleKhajiitScars02"
  ],
  "the nightingales vol. 1": [
    "Philomelae Vol. I"
  ],
  "nightingales vol. 1": [
    "Philomelae Vol. I"
  ],
  "dragonbane": [
    "Draconiclades"
  ],
  "find the gauldur amulet fragment in geirmund's hall": [
    "Inveni Fragmentum Monilis Gauldris in Aula Geirmundis"
  ],
  "agent of mara added": [
    "Agens Marae Addita"
  ],
  "skytemple ruins": [
    "Templi Caelidomus Ruinae"
  ],
  "ruined trailbook": [
    "Liber Viae Direptus"
  ],
  "marksmalekhajiitscar08": [
    "MarksMaleKhajiitScar08"
  ],
  "reports of a disturbance": [
    "Nuntii Turbationis"
  ],
  "hairlinefemaledarkelf08": [
    "HairLineFemaleDarkElf08"
  ],
  "ebony mail poison damage": [
    "Ebony Mail Venenum Damage"
  ],
  "damage health poison recipe": [
    "Dictamen Veneni Saluti Nocentis"
  ],
  "hairfemaleelf06": [
    "HairFemaleElf06"
  ],
  "meeko's shack": [
    "Meeko's Shack"
  ],
  "stormcloak town": [
    "Oppidum Sturmmantelorum"
  ],
  "learn the truth from daynas valen": [
    "Verum a Daynas Valen disce"
  ],
  "spriggan matron": [
    "Matrona Sprigganis"
  ],
  "magic draugr fx": [
    "Magic Draugr FX"
  ],
  "locksmith's touch": [
    "Locksmith's Touch"
  ],
  "vampire resistances": [
    "Vampire Resistances"
  ],
  "raldbthar key": [
    "Clavis ad Raldbthar"
  ],
  "femaleeyeshumanleftblindsingle": [
    "FemaleEyesHumanLeftBlindSingle"
  ],
  "elf slayer": [
    "Necator Alfum"
  ],
  "oghmainfinium": [
    "OghmaInfinium"
  ],
  "nightingale subterfuge": [
    "Subterfugium Philomelarum"
  ],
  "vittoria's wedding band": [
    "Anulus Nuptualis Vittoriae"
  ],
  "savos aren's amulet": [
    "Savos Arenis Monile"
  ],
  "catalogue of armor enchantments": [
    "Catalogus Incantamentorum Loricae"
  ],
  "hairmaleelf09": [
    "HairMaleElf09"
  ],
  "audiocategorymutesubmerged": [
    "AudioCategoryMuteSubmerged"
  ],
  "regarding your loss": [
    "De Amissione Tua"
  ],
  "articulation": [
    "Articulatio"
  ],
  "hairfemaleelf09": [
    "HairFemaleElf09"
  ],
  "resist poison potion recipe": [
    "Dictamen Potionis Veneno Resistendi"
  ],
  "amongst the draugr": [
    "Apud Draugr"
  ],
  "tgtq04 niranye attacks faction": [
    "TGTQ04 Niranye Attacks Faction"
  ],
  "south cold rock pass": [
    "Commeatus Australis Frigidi Saxi"
  ],
  "hairmaledarkelf09": [
    "HairMaleDarkElf09"
  ],
  "any thoughts on the current situation": [
    "Quid censes de hoc"
  ],
  "hall of heroes": [
    "Hall of Heroes"
  ],
  "restore health potion recipe": [
    "Dictamen Potionis Salutum Reficiendi"
  ],
  "marksfemalekhajiitscar03": [
    "MarksFemaleKhajiitScar03"
  ],
  "totem of brotherhood": [
    "Eidolon Fraternitatis"
  ],
  "stormcloak fort": [
    "Castellum Sturmmantelorum"
  ],
  "treasure map ii": [
    "Gazae Charta II"
  ],
  "things to do": [
    "Agenda"
  ],
  "my test effect for self effects": [
    "My test effect for Self Effects"
  ],
  "hairfemaledarkelf08": [
    "HairFemaleDarkElf08"
  ],
  "find alternative source of information": [
    "Inveni Alium Fontem Informationis"
  ],
  "hairlinemaledarkelf08": [
    "HairLineMaleDarkElf08"
  ],
  "i found more of shalidor's work": [
    "Plus operum Shalidoris inveni"
  ],
  "guild master's tribute chest": [
    "Arca Stipendii Magistri Sodalitatis"
  ],
  "it burns hysteria": [
    "Hysteria Urendi"
  ],
  "brownorangetint": [
    "BrownOrangeTint"
  ],
  "orangetint": [
    "OrangeTint"
  ],
  "yellowtint": [
    "YellowTint"
  ],
  "japhet's journal": [
    "Ephemeris Japhetis"
  ],
  "i've completed that bedlam job": [
    "Munus \"bedlam\" perfeci"
  ],
  "feathercolororange": [
    "FeatherColorOrange"
  ],
  "feathercolorbrown": [
    "FeatherColorBrown"
  ],
  "darkelfskinfemale01": [
    "DarkElfSkinFemale01"
  ],
  "watcher of stones": [
    "Custos Lapidum"
  ],
  "hairfemaleelf02": [
    "HairFemaleElf02"
  ],
  "songs of skyrim: revised": [
    "Carmina Caelifinis: Revisum"
  ],
  "hairshavedmaledarkelf09": [
    "HairShavedMaleDarkElf09"
  ],
  "rising threat, vol. ii": [
    "Comminatio Surgens, Vol. II"
  ],
  "hairlinemaleelf07": [
    "HairLineMaleElf07"
  ],
  "great harbingers": [
    "Notissimi Praenuntii"
  ],
  "darkelfskinfemale03": [
    "DarkElfSkinFemale03"
  ],
  "darkelfskinfemale04": [
    "DarkElfSkinFemale04"
  ],
  "maleeyesdarkelfdeepredblindleft": [
    "MaleEyesDarkElfDeepRedBlindLeft"
  ],
  "thief's last words": [
    "Ultima Verba Furis"
  ],
  "$footsteps": [
    "$Footsteps"
  ],
  "a minor maze": [
    "Labyrinthum Minus"
  ],
  "minor maze": [
    "Labyrinthum Minus"
  ],
  "ghosts in the storm": [
    "Phantasmae in Tempestate"
  ],
  "kill <alias=questgiver>'s wolf spirit": [
    "Neca Lupanimum <Alias=Questgiver>"
  ],
  "hairlinemaleelf09": [
    "HairLineMaleElf09"
  ],
  "vampire's servant": [
    "Vampire's Servant"
  ],
  "marksfemalekhajiitscar10": [
    "MarksFemaleKhajiitScar10"
  ],
  "agent of dibella added": [
    "Agens Dibellae Additus"
  ],
  "treasure map vi": [
    "Gazae Charta VI"
  ],
  "treasure map x": [
    "Gazae Charta X"
  ],
  "have need of cynric": [
    "Careo Cynrico"
  ],
  "maleeyesorcblindleftsingle": [
    "MaleEyesOrcBlindLeftSingle"
  ],
  "potion rack": [
    "Sustentaculum Potionum"
  ],
  "companions hunting (skjor aela njada)": [
    "Contubernales Hunting (Skjor Aela Njada)"
  ],
  "novice conjuration": [
    "Discipulus Artis Vocandi"
  ],
  "i have leeks to sell": [
    "Porros vendendos habeo"
  ],
  "button inactive": [
    "Boto Inactiva"
  ],
  "bromjunaar": [
    "Bromjunaar"
  ],
  "marksfemalekhajiitscar06": [
    "MarksFemaleKhajiitScar06"
  ],
  "cloudreach cavern": [
    "Caverna Nubetacta"
  ],
  "give me a chance": [
    "Da Mihi Occasionem"
  ],
  "the path of magic": [
    "Via Magicae"
  ],
  "path of magic": [
    "Via Magicae"
  ],
  "the path of might": [
    "Via Fortitudinis"
  ],
  "path of might": [
    "Via Fortitudinis"
  ],
  "fused door": [
    "Porta Fusa"
  ],
  "resist fire potion recipe": [
    "Dictamen Potionis Ignem Resistendi"
  ],
  "solitude guard": [
    "Custos Solitudinis"
  ],
  "the great war": [
    "Bellum Magnum"
  ],
  "great war": [
    "Bellum Magnum"
  ],
  "hairfemaleelf07": [
    "HairFemaleElf07"
  ],
  "<alias=player>'s wolf spirit": [
    "Animus Lupi <Alias=Player>"
  ],
  "grilled chicken breast": [
    "Pectus Pulli Coctus"
  ],
  "reachward watch": [
    "Visus Prehensi"
  ],
  "imperial town": [
    "Oppidum Romanum"
  ],
  "rimewind keep": [
    "Rimiventus Castellum"
  ],
  "hamvir's rest": [
    "Hamviris Laxamentum"
  ],
  "maleeyesdarkelfleftblindsingle": [
    "MaleEyesDarkElfLeftBlindSingle"
  ],
  "skyrim's rule": [
    "Regnum Caelifinis"
  ],
  "collector's touch": [
    "Tactum Collectoris"
  ],
  "riverside shack": [
    "Riverside Shack"
  ],
  "mara's eye pond": [
    "Mara's Eye Pond"
  ],
  "broken limb camp": [
    "Broken Limb Camp"
  ],
  "à â é è ê ë ù û ü î ï ô ç": [
    "Ã Ã¢ Ã© Ã¨ Ãª Ã« Ã¹ Ã» Ã¼ Ã® Ã¯ Ã´ Ã§"
  ],
  "widow's watch ruins": [
    "Widow's Watch Ruins"
  ],
  "pinefrost tower": [
    "Pinefrost Tower"
  ],
  "ironback hideout": [
    "Ironback Hideout"
  ],
  "to be read immedately": [
    "Statim Legendum"
  ],
  "be read immedately": [
    "Statim Legendum"
  ],
  "read immedately": [
    "Statim Legendum"
  ],
  "refugees' rest": [
    "Refugees' Rest"
  ],
  "dragon damage": [
    "Dragon Damage"
  ],
  "shrine of peryite": [
    "Shrine of Peryite"
  ],
  "cure disease potion recipe": [
    "Dictamen Potionis Morbos Curandi"
  ],
  "hairfemaleelf03": [
    "HairFemaleElf03"
  ],
  "marksfemalekhajiitscar02": [
    "MarksFemaleKhajiitScar02"
  ],
  "novice alteration": [
    "Discipulus Mutationis"
  ],
  "ebongaze tower": [
    "Ebonvisus Turris"
  ],
  "darkthorn grove": [
    "Nemus Obscuridumus"
  ],
  "novice destruction": [
    "Discipulus Destructionis"
  ],
  "novice illusion": [
    "Discilulus Inlusionis"
  ],
  "torturer's hood": [
    "Capucium Tortoris"
  ],
  "namira's blessing": [
    "Benedictio Namirae"
  ],
  "a dream of sovngarde": [
    "Somnium Valhallae"
  ],
  "dream of sovngarde": [
    "Somnium Valhallae"
  ],
  "nightgate inn cellar key": [
    "Clavis ad Cellarium Diversorii Noctiportae"
  ],
  "novice locks": [
    "Clausurae Novitiae"
  ],
  "nightingale's storm": [
    "Nightingale's Storm"
  ],
  "hairmaleelf03": [
    "HairMaleElf03"
  ],
  "restore magicka potion recipe": [
    "Dictamen Potionis Magicam Reficiendi"
  ],
  "herbane's bestiary: hagravens": [
    "Bestiarium Herbanis: Corvisagae"
  ],
  "valdr's dagger": [
    "Pugio Valdris"
  ],
  "dragon infusion": [
    "Infusio Draconis"
  ],
  "jessica's wine": [
    "Jessicae Vinum"
  ],
  "deep freeze paralyze": [
    "Deep Freeze Paralyze"
  ],
  "malkoran's shade": [
    "Umbra Malkoranis"
  ],
  "marksfemalekhajiitscar01": [
    "MarksFemaleKhajiitScar01"
  ],
  "\"nothing changes in the city of stone....\" -- amanda alleia, a sellsword's guide to markarth": [
    "\"Nihil mutat in urbe lapidis....\" -- Amanda Alleia, Rector Mercenario Markartiae"
  ],
  "hairmaleelf08": [
    "HairMaleElf08"
  ],
  "do you hear that": [
    "Illudne audis"
  ],
  "it's the sound of your sister, screaming in the void": [
    "Est sonitum sororis tuae, in Hiato clamantis"
  ],
  "find the gauldur amulet fragment in saarthal": [
    "Inveni Fragmentum Monilis Gauldris in Saarthali"
  ],
  "maleeyeshumanlighticegreyblindleft": [
    "MaleEyesHumanLightIceGreyBlindLeft"
  ],
  "key to sylgja's house": [
    "Clavis ad Domum Sylgjae"
  ],
  "hunter's rest": [
    "Hunter's Rest"
  ],
  "fall from glory": [
    "Casus e Gloria"
  ],
  "hairlinefemaleelf06": [
    "HairLineFemaleElf06"
  ],
  "fear poison recipe": [
    "Dictamen Veneni Timoris"
  ],
  "femaleeyeshumanrightblindsingle": [
    "FemaleEyesHumanRightBlindSingle"
  ],
  "enthir services": [
    "Enthir Services"
  ],
  "shadowcloak invisibility": [
    "Umbracloak Invisibility"
  ],
  "sovngarde hall of heroes conv scene": [
    "Valhalla Hall of Heroes conv scene"
  ],
  "nord battle cry perk": [
    "Nord Battle Cry Perk"
  ],
  "lost summit overlook": [
    "Summi Perditi Panorama"
  ],
  "tg08_ceremonymarker": [
    "TG08_CeremonyMarker"
  ],
  "the tale of dro'zira": [
    "Fabula de Dro'Zira"
  ],
  "tale of dro'zira": [
    "Fabula de Dro'Zira"
  ],
  "scourge of the gray quarter": [
    "Flagrum Quadri Albi"
  ],
  "fox pelt": [
    "Vulpinis Reno"
  ],
  "tumblerbane gloves": [
    "Clavicarii Manubria"
  ],
  "paralysis poison recipe": [
    "Dictamen Veneni Paralytici"
  ],
  "werewolf cure": [
    "Cura Lycanthropiae"
  ],
  "maleeyesdarkelfdeepred2blindright": [
    "MaleEyesDarkElfDeepRed2BlindRight"
  ],
  "shalidor's maze": [
    "Labyrinthum Shalidoris"
  ],
  "treasure map viii": [
    "Gazae Charta VIII"
  ],
  "troll slaying": [
    "Trelles Necare"
  ],
  "on ice paralyze": [
    "On Ice Paralyze"
  ],
  "resist shock potion recipe": [
    "Dictamen Potionis Electricitati Resistendi"
  ],
  "a gentleman's guide to whiterun": [
    "Rector viri Albacursus"
  ],
  "gentleman's guide to whiterun": [
    "Rector viri Albacursus"
  ],
  "killing - before you're killed": [
    "Necans - ante necaris"
  ],
  "companions hunting2 (farkas athis torvar)": [
    "Contubernales Hunting2 (Farkas Athis Torvar)"
  ],
  "are there any special books you're looking for": [
    "Suntne libri quos quaeres"
  ],
  "guild master": [
    "Guild Master"
  ],
  "\"blood and silver are what flows through markarth....\" -- popular saying": [
    "\"Sanguis et Argentum per Markartiam Fluunt....\""
  ],
  "marksfemalekhajiitscar05": [
    "MarksFemaleKhajiitScar05"
  ],
  "scarred earth cavern": [
    "Caverna Terrae Ciciatriciosae"
  ],
  "finish and name your character": [
    "Finem fac et nomen da personam tuam"
  ],
  "stormcrest tower": [
    "Turris Jubae Tempestatis"
  ],
  "hairlinefemaleelf02": [
    "HairLineFemaleElf02"
  ],
  "hairfemaledarkelf07": [
    "HairFemaleDarkElf07"
  ],
  "spriggan claw": [
    "Ungues Sprigganis"
  ],
  "nords arise": [
    "Germani Surgite"
  ],
  "marksfemalekhajiitscar07": [
    "MarksFemaleKhajiitScar07"
  ],
  "notice of cost increase": [
    "Nuntium Pretium Augendi"
  ],
  "(do not read)": [
    "(Noli legere)"
  ],
  "letter from septimus signus": [
    "Epistula a Septimo Signo"
  ],
  "observe arniel's experiment": [
    "Specta Experimentum Arnielis"
  ],
  "herbane's bestiary: automatons": [
    "Bestiarium Herbanis: Automata"
  ],
  "nightingale's shadow": [
    "Nightingale's Shadow"
  ],
  "after hadvar/ralof get to know you, they are in this faction - used to conditionalize dialogue": [
    "After Hadvar/Ralof get to know you, they are in this faction - used to conditionalize dialogue"
  ],
  "crab claws": [
    "Crab claws"
  ],
  "umana's journal": [
    "Ephemeris Umanae"
  ],
  "silver hand stratagem": [
    "Argentei Hand StrataGemma"
  ],
  "ebony mail muffle": [
    "Ebony Mail Muffle"
  ],
  "i have cabbage to sell": [
    "Brassicam vendendam habeo"
  ],
  "hairfemaleelf05": [
    "HairFemaleElf05"
  ],
  "apply perk": [
    "Apply Perk"
  ],
  "blackrock den": [
    "Cubile Saxatrum"
  ],
  "maleeyesorciceblueblindright": [
    "MaleEyesOrcIceBlueBlindRight"
  ],
  "flight from the thalmor": [
    "Fuga e Visigothis"
  ],
  "special dialogue friendly bleedout": [
    "Special Dialogue Friendly Bleedout"
  ],
  "da08 necro priest fx": [
    "DA08 necro priest fx"
  ],
  "shipment's arrived": [
    "Merx Advenit"
  ],
  "nightingales: fact or fiction": [
    "Philomelae: Verum aut Falsum"
  ],
  "novice restoration": [
    "Discipulus Artis Reficiendi"
  ],
  "requested report": [
    "Nuntium Rogatum"
  ],
  "lucky lorenz": [
    "Lorenz Felix"
  ],
  "manhole": [
    "Cavum Virorum"
  ],
  "chaurus pie: a recipe": [
    "Dictamen: Placenta Chauri"
  ],
  "talk to urag": [
    "Uragem Adloquere"
  ],
  "find shalidor's writings": [
    "Inveni Scripta Shalidoris"
  ],
  "marksmalekhajiitscar00": [
    "MarksMaleKhajiitScar00"
  ],
  "training chests": [
    "Arcae Docendi"
  ],
  "muiri sent me": [
    "Muiri mihi misit"
  ],
  "she is quite unhappy": [
    "Infelicissima est"
  ],
  "rising threat, vol. iv": [
    "Comminatio Surgens, Vol. IV"
  ],
  "kolb & the dragon": [
    "Kolb & Draco"
  ],
  "familiar flame cloak": [
    "Familiar Flame Cloak"
  ],
  "i used alduin's portal to travel to sovngarde": [
    "Porta Alduini usus sum ut Valhallam peterem"
  ],
  "hairlinefemaleelf04": [
    "HairLineFemaleElf04"
  ],
  "dread cloak": [
    "Amiculum Timoris"
  ],
  "workbenches": [
    "Workbenches"
  ],
  "bring dragon heartscales to tolfdir": [
    "Fer Corsquama Draconis Tolfdiri"
  ],
  "take the gauldur amulet": [
    "Cape Monile Gauldris"
  ],
  "reforge the gauldur amulet": [
    "Refice Monile Gauldris"
  ],
  "find the gauldur amulet fragment in folgunthur": [
    "Inveni Fragmentum Monilis Gauldris in Folgunthur"
  ],
  "read daynas valen's notes": [
    "Scripta Daynas Valenis Lege"
  ],
  "investigate the gauldur legend": [
    "Investiga Fabulam Gauldris"
  ],
  "speed burst": [
    "Speed Burst"
  ],
  "ceremonial door": [
    "Ianuam Ceremonialem"
  ],
  "songs of the return, vol 24": [
    "Carmina Reventi, vol XXIV"
  ],
  "black creek": [
    "Rivulus Ater"
  ],
  "spriggan aggravated by a hunter, now dead": [
    "Spriggan aggravated by hunter, now dead"
  ],
  "sovngarde hall of heroes fighting scene": [
    "Valhalla Hall of Heroes fighting scene"
  ],
  "bandit killed with horse left behind": [
    "Bandit Killed With Horse Left Behind"
  ],
  "corpses in the aftermath of a dragon attack, dog left behind": [
    "Corpses in aftermath of dragon attack, Dog left behind"
  ],
  "maleeyeshumanamberblindright": [
    "MaleEyesHumanAmberBlindRight"
  ],
  "mara smiles upon you": [
    "Mara in te subridet"
  ],
  "dog fending off a pair of wolves": [
    "Dog Fending Off Pair of Wolves"
  ],
  "marksfemalekhajiitscar08": [
    "MarksFemaleKhajiitScar08"
  ],
  "what's wrong, boy": [
    "Quid est tibi, catule"
  ],
  "do you want to come with me": [
    "Visne mecum venire"
  ],
  "you can follow me if you want": [
    "Me sequi potes, si vis"
  ],
  "per your requests": [
    "Ut Rogavisti"
  ],
  "songs of the return, vol 19": [
    "Carmina Reventi, Vol XIX"
  ],
  "the rise and fall of the blades": [
    "Ortus et Ocassus Ensium"
  ],
  "rise and fall of the blades": [
    "Ortus et Ocassus Ensium"
  ],
  "on stepping lightly": [
    "De Levis Gradibus"
  ],
  "spriggan poison": [
    "Spriggan Poison"
  ],
  "thalmor dossier: delphine": [
    "Visigothi Dossier: Delphina"
  ],
  "imperial fort": [
    "Castellum Romanum"
  ],
  "chicken breast": [
    "Pulli Pectus"
  ],
  "chillpine grove": [
    "Nemus Frigidipini"
  ],
  "amulet pedestal": [
    "Bas Monili"
  ],
  "borvir's dagger": [
    "Pugio Borviris"
  ],
  "totem of the hunt": [
    "Eidolon Venationis"
  ],
  "ice volley": [
    "Effusus Hamorum Glaciei"
  ],
  "hairfemaleelf10": [
    "HairFemaleElf10"
  ],
  "letter from sabjorn": [
    "Epistula a Sabjorn"
  ],
  "an apology": [
    "Apologia"
  ],
  "maleeyeselfleftblindsingle": [
    "MaleEyesElfLeftBlindSingle"
  ],
  "possible rivals": [
    "Rivales Possibiles"
  ],
  "treasure map iii": [
    "Gazae Charta III"
  ],
  "hairlinefemaledarkelf01": [
    "HairLineFemaleDarkElf01"
  ],
  "mage's circlet": [
    "Magi Circulum"
  ],
  "marksmalekhajiitscar07": [
    "MarksMaleKhajiitScar07"
  ],
  "the alduin/akatosh dichotomy": [
    "Dichotomia Alduini/Akatoshis"
  ],
  "alduin/akatosh dichotomy": [
    "Dichotomia Alduini/Akatoshis"
  ],
  "argonian ceremony": [
    "Argoniae Ceremoniae"
  ],
  "\"the best techniques are passed on by the survivors.\" -- gaiden shinji, 1e 490": [
    "\"Technicae optimae a superstitibus traditae sunt.\" -- Gaiden Shinji, Prima Aetate, CCCCXC"
  ],
  "hairlinemaleelf06": [
    "HairLineMaleElf06"
  ],
  "ä ö ü ß ä ö ü": [
    "Ã¤ Ã¶ Ã¼ ÃŸ Ã„ Ã– Ãœ"
  ],
  "dwarven oil": [
    "Oleum Dwemere"
  ],
  "hairlinemaleelf01": [
    "HairLineMaleElf01"
  ],
  "melee damage": [
    "Vulnus Manualis"
  ],
  "yes, i found alduin's portal to sovngarde": [
    "Sic, Portam Alduini ad Valhallam inveni"
  ],
  "i went to sovngarde and killed alduin there": [
    "Valhallam petivi et Alduinus illic necavi"
  ],
  "eyes open": [
    "Oculi Aperti"
  ],
  "there be dragons": [
    "Illic sunt Dracones"
  ],
  "the nightingales vol. 2": [
    "Philomelae Vol. II"
  ],
  "nightingales vol. 2": [
    "Philomelae Vol. II"
  ],
  "i hope so": [
    "Ut spero"
  ],
  "but i don't know if alduin can ever be completely destroyed": [
    "Sed nescio num Alduinus umquam omnino superari potest"
  ],
  "karthspire camp": [
    "Karthspirae Castra"
  ],
  "browsmalehumanoid12nobrow": [
    "BrowsMaleHumanoid12NoBrow"
  ],
  "i'll meet you at dustman's cairn": [
    "Tecum adiungam in Monolithos Pulviri"
  ],
  "is alduin really dead": [
    "Este Alduinus re vera mortuus"
  ],
  "i didn't absorb his soul when he died": [
    "Animum eius non absorbui cum moriret"
  ],
  "spell tome: equilibrium": [
    "Liber Magicus: Equilibrium"
  ],
  "cure all diseases before changing": [
    "Cure all diseases before changing"
  ],
  "hairlinemaleelf04": [
    "HairLineMaleElf04"
  ],
  "all employees must read": [
    "Hoc Omnibus Laboratoribus Legendum Est"
  ],
  "forbidden legend": [
    "Legendum Interdictum"
  ],
  "marksmalekhajiitscar03": [
    "MarksMaleKhajiitScar03"
  ],
  "maleeyeshumanlightblueblindleft": [
    "MaleEyesHumanLightBlueBlindLeft"
  ],
  "dialogue whiterun captain of the guard": [
    "Dialogue Whiterun Captain of Guard"
  ],
  "kodlak's journal": [
    "Ephemeris Kodlakis"
  ],
  "songs of the return, vol 2": [
    "Carmina Reventi, Vol II"
  ],
  "teleport out effect": [
    "Teleport Out Effect"
  ],
  "reanimate secondary effects": [
    "Reanimate Secondary Effects"
  ],
  "spriggan and hagraven battling": [
    "Spriggan et Hagraven Battling"
  ],
  "ancient traveler's skull": [
    "Calvaria Antiqua Peregrinatoris"
  ],
  "i have potatoes to sell": [
    "Mala terrae vendenda habeo"
  ],
  "storyteller's touch": [
    "Storyteller's Touch"
  ],
  "i have nirnroot to sell": [
    "Nirniradicem vendendum tibi habeo"
  ],
  "onmund's amulet": [
    "Monile Onmundis"
  ],
  "jurgen windcaller": [
    "Jurgen Ventivocatoris"
  ],
  "michaela's flagon": [
    "Michaelae Lagoena"
  ],
  "lower steepfall burrow": [
    "Cuniculus Praecepasus Inferioris"
  ],
  "da08 necro skeleton fx": [
    "DA08 necro skeleton fx"
  ],
  "nightingale's talon": [
    "Nightingale's Talon"
  ],
  "letter from maven": [
    "Epistula a Maven"
  ],
  "hairmaleelf04": [
    "HairMaleElf04"
  ],
  "no word yet": [
    "Nullum Verbum Adhuc"
  ],
  "i've completed that heist job": [
    "Munus \"heist\" perfeci"
  ],
  "quite pleased": [
    "Valde Gratulor"
  ],
  "enthralled wizard": [
    "Thaumatorgos Subiugatus"
  ],
  "writ of sealing": [
    "Versus Detenti"
  ],
  "hairmaledarkelf08": [
    "HairMaleDarkElf08"
  ],
  "small note": [
    "Epistula Parva"
  ],
  "hairlinemaleelf08": [
    "HairLineMaleElf08"
  ],
  "champion of the night": [
    "Vindex Noctis"
  ],
  "mg time freeze": [
    "MG Time Freeze"
  ],
  "the cabin in the woods": [
    "Casa in Silvis"
  ],
  "cabin in the woods": [
    "Casa in Silvis"
  ],
  "investigate the cryptic message": [
    "Investiga Scriptum Mirum"
  ],
  "hairshavedfemaledarkelf06": [
    "HairShavedFemaleDarkElf06"
  ],
  "lycanthropic legends of skyrim": [
    "Legenda Lycanthropica Caelifinis"
  ],
  "blackscar camp": [
    "Castra Cicatratra"
  ],
  "femalebrowshuman12nobrow": [
    "FemaleBrowsHuman12NoBrow"
  ],
  "fortify carry weight potion recipe": [
    "Dictamen Pondus Ferentis Augendi"
  ],
  "spirit of the ancient traveler": [
    "Animus Viatoris Antiqui"
  ],
  "north cold rock pass": [
    "Commeatus Frigidi Saxi Septentrionis"
  ],
  "mg time teleport out spell": [
    "MG Time Teleport Out Spell"
  ],
  "hairlinefemaledarkelf06": [
    "HairLineFemaleDarkElf06"
  ],
  "hairfemaledarkelf06": [
    "HairFemaleDarkElf06"
  ],
  "deep freeze": [
    "Gelidus Profundus"
  ],
  "rebel's respite": [
    "Laxamentum Defectoris"
  ],
  "hairlinefemaleelf01": [
    "HairLineFemaleElf01"
  ],
  "journeyman's nook": [
    "Foramen Peritioris"
  ],
  "falkreath barracks": [
    "Tabernacula Falkreatiae"
  ],
  "blackwater channel": [
    "Atraqua Canalis"
  ],
  "forsaken spire": [
    "Pinna Oblita"
  ],
  "eboncrest": [
    "Ebenjuba"
  ],
  "blackwind spire": [
    "Aterventus Pinna"
  ],
  "windward spire": [
    "Adventus Pinna"
  ],
  "override normal combat dialogue": [
    "Override normal combat dialogue"
  ],
  "hairlinemaledarkelf09": [
    "HairLineMaleDarkElf09"
  ],
  "darkstone tower": [
    "Turris Obscurilapis"
  ],
  "hairlinefemaleelf08": [
    "HairLineFemaleElf08"
  ],
  "broadstream camp": [
    "Castra Laterivi"
  ],
  "stoneshaper summit": [
    "Lapifactoris Summum"
  ],
  "glimmerweb depths": [
    "Ima Telarum Vibrantium"
  ],
  "faded diary": [
    "Ephemeris Pallidus"
  ],
  "snowmelt cove": [
    "Niviliqui Sinus"
  ],
  "fortify speechcraft": [
    "Auget Loquacitatem"
  ],
  "northstar tower": [
    "Turris Polaris"
  ],
  "marksmalekhajiitscar01": [
    "MarksMaleKhajiitScar01"
  ],
  "shaderest tower": [
    "Turris Quietumbrae"
  ],
  "windshear post": [
    "Turris Ventirupis"
  ],
  "emerald dragon claw": [
    "Ungues Draconis Smaragdus"
  ],
  "dawnsgard": [
    "Auroria"
  ],
  "imperial report on saarthal": [
    "Nuntium Imperiale de Saarthal"
  ],
  "nightingale strife": [
    "Philomelarum Duellio"
  ],
  "warehouse map markers": [
    "Horreum Charta Markers"
  ],
  "derelict pumphouse": [
    "Derelictum Conclave Siphonis"
  ],
  "dwarven scrap": [
    "Gry Dvemere"
  ],
  "farm overseer's house": [
    "Latifundus Curatoris Domus"
  ],
  "maleeyeswoodelfdeepbrownblindright": [
    "MaleEyesWoodElfDeepBrownBlindRight"
  ],
  "roadside guard": [
    "Custos Viae"
  ],
  "shopping list": [
    "Tabula Ciborum"
  ],
  "bilegulch mine key": [
    "Clavis ad Metallum Bilivallis"
  ],
  "key to gilfre's house": [
    "Clavis ad Domum Gilfris"
  ],
  "letter from christophe": [
    "Epistula a Christophe"
  ],
  "lumber camp key": [
    "Clavis ad Castra Ligni"
  ],
  "script effect for ai use of storm call": [
    "Script Effect for AI use of Storm Call"
  ],
  "shadowcloak of nocturnal": [
    "Umbramiculum Nocturnalis"
  ],
  "synda": [
    "Synda"
  ],
  "songs of the return, vol 56": [
    "Carmina Reventi, Vol LVI"
  ],
  "aicantar's spider": [
    "Aranea Aicantaris"
  ],
  "hairfemaleelf01": [
    "HairFemaleElf01"
  ],
  "hairmaleelf06": [
    "HairMaleElf06"
  ],
  "request for help": [
    "Rogatio Auxilii"
  ],
  "greytint": [
    "GreyTint"
  ],
  "gauldur": [
    "Gauldur"
  ],
  "olaf one-eye": [
    "Olaf Monophthalmos"
  ],
  "allow shouting": [
    "Allow Shouting"
  ],
  "spriggan shield": [
    "Spriggan Scutum"
  ],
  "i have gourds to sell": [
    "Cucurbitas vendendas tibi habeo"
  ],
  "treva's watch prison key": [
    "Clavis ad Carcerem Visus Trevae"
  ],
  "imperial mage": [
    "Imperialis Magus"
  ],
  "thalmor dossier: ulfric stormcloak": [
    "Dossier Visigothi: Vercingetorix Temepstatamiculus"
  ],
  "hairlinefemaleelf09": [
    "HairLineFemaleElf09"
  ],
  "songs of the return, vol 7": [
    "Carmina Reventi, Vol VII"
  ],
  "the windhelm letters": [
    "Empistulae Ventigaleae"
  ],
  "windhelm letters": [
    "Empistulae Ventigaleae"
  ],
  "hidden panel": [
    "Lacuna Secreta"
  ],
  "farkas's wolf spirit": [
    "Animus Lupi Farkas"
  ],
  "vilkas's wolf spirit": [
    "Animus Lupi Vilkae"
  ],
  "wolf spirit": [
    "Animus Lupi"
  ],
  "catalogue of weapon enchantments": [
    "Catalogus Incantamentorum Armorum"
  ],
  "maleeyeshumanbrightgreenblindright": [
    "MaleEyesHumanBrightGreenBlindRight"
  ],
  "hallowed dead": [
    "Mortua Sanctificata"
  ],
  "the apprentice's assistant": [
    "Cooperator Discipuli"
  ],
  "apprentice's assistant": [
    "Cooperator Discipuli"
  ],
  "rundi's dagger": [
    "Pugio Rundi"
  ],
  "resist frost potion recipe": [
    "Dictamen Potionis Glaciei Resistendi"
  ],
  "rising threat, vol. iii": [
    "Comminatio Surgens, Vol. III"
  ],
  "as dead as i can make him": [
    "Tam mortuus ut eam facere possum"
  ],
  "honed ancient nord greatsword of scorching": [
    "Melior Germanica Antiqua Spatha Adurendi"
  ],
  "horker attacks": [
    "Oppugnata Horker"
  ],
  "night stalker's footsteps": [
    "Vestigia Vespilionis"
  ],
  "rising threat, vol. i": [
    "Comminatio Surgens, Vol. I"
  ],
  "hairshavedfemaledarkelf08": [
    "HairShavedFemaleDarkElf08"
  ],
  "darkelfskinfemale05": [
    "DarkElfSkinFemale05"
  ],
  "lost legends": [
    "Legenda Perdita"
  ],
  "door fused": [
    "Ianuam Liquatam"
  ],
  "roaming ice wraiths": [
    "Roaming Ice Wraiths"
  ],
  "here lie the hallowed dead of bromjunaar": [
    "Hic iacent mortui sacri Bromjunaaris"
  ],
  "hairmaleelf01": [
    "HairMaleElf01"
  ],
  "treasure map i": [
    "Gazae Charta I"
  ],
  "the gift of charity": [
    "Donum Caritatis"
  ],
  "gift of charity": [
    "Donum Caritatis"
  ],
  "ancient helmet of the unburned": [
    "Galea Antiqua Incremati"
  ],
  "the talos mistake": [
    "Error Talos"
  ],
  "talos mistake": [
    "Error Talos"
  ],
  "i don't need some mutt following me around": [
    "Canem me sequentem non requiro"
  ],
  "dragon bridge chest key": [
    "Clavis ad Arcam Draconipontem"
  ],
  "hairmaleelf05": [
    "HairMaleElf05"
  ],
  "à é è è ì ó ò ù é è ì ó ò á ù": [
    "Ã Ã© Ã¨ Ã¨ Ã¬ Ã³ Ã² Ã¹ Ã‰ Ãˆ ÃŒ Ã“ Ã’ Ã Ã™"
  ],
  "intense flames": [
    "Flammae Fortissimae"
  ],
  "maleeyeshighelfdarkyellowblindleft": [
    "MaleEyesHighElfDarkYellowBlindLeft"
  ],
  "marksfemalekhajiitscar09": [
    "MarksFemaleKhajiitScar09"
  ],
  "intense flames fear": [
    "Timor Flammarum Potentium"
  ],
  "hairfemaleelf08": [
    "HairFemaleElf08"
  ],
  "asgeir's wedding band": [
    "Anulus Nuptualis Asgeiris"
  ],
  "i ran out of scrolls": [
    "Codices nondum habeo"
  ],
  "many thanks": [
    "Multas Gratias"
  ],
  "human spine": [
    "Spinam Humanam"
  ],
  "empty skooma bottle": [
    "Vacua Skooma Lagoena"
  ],
  "walking the world, vol xi": [
    "Mundus Experrectus, Vol XI"
  ],
  "attention employees": [
    "Caveant Operarii"
  ],
  "ancient edict": [
    "Edictum Antiquum"
  ],
  "physicalities of werewolves": [
    "Corpulenta Versipellium"
  ],
  "treasure map v": [
    "Gazae Charta V"
  ],
  "maleeyesdarkelfrightblindsingle": [
    "MaleEyesDarkElfRightBlindSingle"
  ],
  "sinderion's field laboratory": [
    "Sinderionis Agrorum Laboratorium"
  ],
  "dragon language: myth no more": [
    "Lingua Draconis: Non iam Mythos"
  ],
  "gaston bellefort": [
    "Gaston Belleforti"
  ],
  "three thieves": [
    "Tres Fures"
  ],
  "darkelfskinfemale02": [
    "DarkElfSkinFemale02"
  ],
  "j'zhar's journal": [
    "Ephemeris J'zharis"
  ],
  "cragslane we": [
    "Cragslane WE"
  ],
  "teleport in effect": [
    "Teleport In Effect"
  ],
  "note from jaree-ra": [
    "Epistula a Jaree-Ra"
  ],
  "sulla trebatius's pack": [
    "Cibisis Sullae Trebatii"
  ],
  "mg time teleport in spell": [
    "MG Time Teleport In Spell"
  ],
  "maleeyeshighelforangeblindright": [
    "MaleEyesHighElfOrangeBlindRight"
  ],
  "hunter prey faction": [
    "Hunter Prey Faction"
  ],
  "anyone in this won't fight with mq101 alduin": [
    "anyone in this won't fight with mq101 alduin"
  ],
  "note from maven": [
    "Epistula a Maven"
  ],
  "maleeyesorcyellowblindleft": [
    "MaleEyesOrcYellowBlindLeft"
  ],
  "eye of markarth": [
    "Oculus Markartiae"
  ],
  "centurion dynamo core": [
    "Centurionis Dynamis Cor"
  ],
  "is this enough to clear my bounty": [
    "Estne hoc satis ut vectigal solvam"
  ],
  "marksfemalehumanoid00nogash": [
    "MarksFemaleHumanoid00NoGash"
  ],
  "key to romlyn dreth's house": [
    "Clavis ad Domum Romlyn Drethis"
  ],
  "brigand's bluff": [
    "Rupes Latronis"
  ],
  "drainheart sword": [
    "Gladius Cordibibens"
  ],
  "totem of fear": [
    "Eidolon Timoris"
  ],
  "a warning": [
    "Admonitum"
  ],
  "gulum-ei's confession": [
    "Confessio Gulum-Ei"
  ],
  "hairmaleelf02": [
    "HairMaleElf02"
  ],
  "pale hold guard": [
    "Custos Pallidi"
  ],
  "hairlinefemaleelf10": [
    "HairLineFemaleElf10"
  ],
  "steel horned helmet": [
    "Adamantea Galea Cornuta"
  ],
  "steel imperial gauntlets": [
    "Adamanteae Romanae Manicae"
  ],
  "bladeridge den": [
    "Cubile Ensiugi"
  ],
  "marksmalekhajiitscars03": [
    "MarksMaleKhajiitScars03"
  ],
  "speak to tsun to return to skyrim": [
    "Adloquere Tsun ut Caelifinem Revenias"
  ],
  "timely offer": [
    "Offertum Aptum"
  ],
  "featherlift pockets": [
    "Fundae Pennarum"
  ],
  "hairlinefemaleelf03": [
    "HairLineFemaleElf03"
  ],
  "to the brotherhood": [
    "Ad Fraternitatem"
  ],
  "the brotherhood": [
    "Ad Fraternitatem"
  ],
  "werewolf feed health restore": [
    "Werewolf Feed Health Restore"
  ],
  "sinderion": [
    "Sinderion"
  ],
  "hairlinefemaleelf07": [
    "HairLineFemaleElf07"
  ],
  "softfire camp": [
    "Castra Ignimollis"
  ],
  "jail door": [
    "Porta Carceris"
  ],
  "hairlinemaleelf02": [
    "HairLineMaleElf02"
  ],
  "dagger rack": [
    "Armifer Pugionis"
  ],
  "frost barrier icewraith": [
    "Frost Barrier IceWraith"
  ],
  "death of a wanderer": [
    "Mors Peregrinatoris"
  ],
  "until next time": [
    "Ad Tempus Proximum"
  ],
  "fort neugrad treasure map": [
    "Charta Gazae Castelli Neugrad"
  ],
  "olaf and the dragon": [
    "Olaf et Draco"
  ],
  "hairlinefemaleelf05": [
    "HairLineFemaleElf05"
  ],
  "marksmalekhajiitscars09": [
    "MarksMaleKhajiitScars09"
  ],
  "songs of skyrim": [
    "Carmina Caelifinis"
  ],
  "marksmalekhajiitscars04": [
    "MarksMaleKhajiitScars04"
  ],
  "to the owner": [
    "Ad Possessorem"
  ],
  "the owner": [
    "Ad Possessorem"
  ],
  "marksfemalekhajiitscar04": [
    "MarksFemaleKhajiitScar04"
  ],
  "treasure map vii": [
    "Gazae Charta VII"
  ],
  "twin secrets": [
    "Secretae Geminae"
  ],
  "on the great collapse": [
    "De Casu Magno"
  ],
  "marksmalekhajiitscar06": [
    "MarksMaleKhajiitScar06"
  ],
  "ode to the tundrastriders": [
    "Carmen Ambulatorum Tundrae"
  ],
  "harvesting frostbite spider venom": [
    "Colligens Venenum a Aranea Adustus Nivibus"
  ],
  "east empire connection": [
    "Nexus Euri Imperii"
  ],
  "hairmaleelf07": [
    "HairMaleElf07"
  ],
  "totem receptacle": [
    "Eidolonis Receptaculum"
  ],
  "the lusty argonian maid, v2": [
    "Virgo Aronia Libidinosa, II"
  ],
  "lusty argonian maid, v2": [
    "Virgo Aronia Libidinosa, II"
  ],
  "hastily scribbled note": [
    "Epista Celeriter Scripta"
  ],
  "the third era timeline": [
    "Tempora Aetatis Tertiae"
  ],
  "third era timeline": [
    "Tempora Aetatis Tertiae"
  ],
  "goldenglow": [
    "Aurilux"
  ],
  "lurker's shadow": [
    "Umbra Insidiatoris"
  ],
  "an accounting of the scrolls": [
    "Ratiocinium Codicum"
  ],
  "to a concerned citizen": [
    "Ad Civem Sollicitum"
  ],
  "a concerned citizen": [
    "Ad Civem Sollicitum"
  ],
  "concerned citizen": [
    "Ad Civem Sollicitum"
  ],
  "hairkhajiitmale02": [
    "HairKhajiitMale02"
  ],
  "lurker's cloak": [
    "Cloak Insidiatoris"
  ],
  "of crossed daggers": [
    "De Pugionibus Iunctis"
  ],
  "archery dummy": [
    "Archery Dummy"
  ],
  "hairlinemaleelf03": [
    "HairLineMaleElf03"
  ],
  "admonition against ebony": [
    "Admonitio Contra Ebenum"
  ],
  "the dragon war": [
    "Bellum Draconum"
  ],
  "dragon war": [
    "Bellum Draconum"
  ],
  "the holds of skyrim": [
    "Terrae Caelifinis"
  ],
  "holds of skyrim": [
    "Terrae Caelifinis"
  ],
  "j'zhar's pack": [
    "Cibisis J'zharis"
  ],
  "the woodcutter's wife": [
    "Uxor Boscarii"
  ],
  "woodcutter's wife": [
    "Uxor Boscarii"
  ],
  "maleeyeshumanlightbluebloodshot": [
    "MaleEyesHumanLightBlueBloodShot"
  ],
  "konahrik's bust": [
    "Statua Konahrikis"
  ],
  "snow sabrecat abilities": [
    "Snow Sabrecat abilities"
  ],
  "pay <global=webountycollectorcrimegold> gold": [
    "Solve <Global=WEBountyCollectorCrimeGold> Auri"
  ],
  "red eagle turn undead": [
    "Red Eagle Turn Undead"
  ],
  "morthal longhouse faction (both sides)": [
    "Morthal Longhouse Faction (Both Sides)"
  ],
  "bromjunaar's mystery": [
    "Mysterium Bromjunaaris"
  ],
  "femaleeyeshumangreyblindleft": [
    "FemaleEyesHumanGreyBlindLeft"
  ],
  "morokei's bust": [
    "Statua Morokei"
  ],
  "damage lockpicking": [
    "Vulnus Arti Clavicandi"
  ],
  "elixir of larceny": [
    "Commixtum Furti"
  ],
  "cursed ring of hircine": [
    "Anulus Hecatis Maledictus"
  ],
  "draught of escape": [
    "Decocta Fugiendi"
  ],
  "audiotemplatehorse": [
    "AudioTemplateHorse"
  ],
  "elixir of escape": [
    "Commixtum Fugiendi"
  ],
  "halldir's soul trap": [
    "Halldir's Soul Trap"
  ],
  "fire spirit": [
    "Animus Ignis"
  ],
  "otar's will": [
    "Otar's Will"
  ],
  "attacking": [
    "Oppugnans"
  ],
  "shadowmarks": [
    "Signa Obscuritatis"
  ],
  "potion of plunder": [
    "Potio Latronis"
  ],
  "philter of larceny": [
    "Philtrum Furti"
  ],
  "potion of escape": [
    "Potio Fugiendi"
  ],
  "chillrend": [
    "Gelaniator"
  ],
  "vampire fortify dmg resist": [
    "Vampire Fortify Dmg Resist"
  ],
  "herebane's fortress": [
    "Herebanis Castellum"
  ],
  "frostfeed": [
    "Frostfeed"
  ],
  "drainspell bow": [
    "Arcus Magicabibens"
  ],
  "hag's end soul trap": [
    "Hag's End Soul Trap"
  ],
  "potion of larceny": [
    "Potio Furti"
  ],
  "grand elixir of keenshot": [
    "Magnum Commixtum Saggitandi"
  ],
  "guide to better thieving": [
    "Lectionarium Melioris Furti"
  ],
  "creaturedialoguehorse": [
    "CreatureDialogueHorse"
  ],
  "powers and shouts": [
    "Vires et Clamationes"
  ],
  "reves": [
    "Reves"
  ],
  "quest journal": [
    "Quest Journal"
  ],
  "great lift at raldbthar": [
    "Ascensore Raldbthar"
  ],
  "philter of keenshot": [
    "Philtrum Saggitandi"
  ],
  "hevnoraak's bust": [
    "Statua Hevnoraakis"
  ],
  "scholastic mastery": [
    "Dominus Scholarum Magicae"
  ],
  "femaleeyeshumanbrownbloodshot": [
    "FemaleEyesHumanBrownBloodShot"
  ],
  "dark sacrifice": [
    "Sacrificium Obscurum"
  ],
  "elixir of plunder": [
    "Commixtum Latronis"
  ],
  "hired thug's missive": [
    "Iussus Operae"
  ],
  "additional werewolf transformation": [
    "Additional Werewolf Transformation"
  ],
  "orcskinfemale03": [
    "OrcSkinFemale03"
  ],
  "orcskinfemale05": [
    "OrcSkinFemale05"
  ],
  "orcskinfemale04": [
    "OrcSkinFemale04"
  ],
  "drainblood battleaxe": [
    "Bellisecuris Sanguibibens"
  ],
  "bounty collector": [
    "Vectigal Collector"
  ],
  "krosis' bust": [
    "Statua Krosis"
  ],
  "battle of red mountain": [
    "Pugna Montis Rubri"
  ],
  "konahrik's privilege": [
    "Privilegium Konahrikis"
  ],
  "hag's end drain health": [
    "Hag's End Drain Health"
  ],
  "draught of larceny": [
    "Decocta Furti"
  ],
  "investigator's key": [
    "Clavis Investigatoris"
  ],
  "elixir of conflict": [
    "Commixtum Pugnae"
  ],
  "draught of keenshot": [
    "Decocta Saggitandi"
  ],
  "spells": [
    "Spells"
  ],
  "nahkriin's bust": [
    "Statua Nahkriinis"
  ],
  "bromjunaar sancutary": [
    "Bromjunaar Sancutary"
  ],
  "extra damage 5": [
    "Extra Damage 5"
  ],
  "audiotemplatewerewolf": [
    "AudioTemplateWerewolf"
  ],
  "femaleeyeshumanamberblindleft": [
    "FemaleEyesHumanAmberBlindLeft"
  ],
  "channeling beam": [
    "Channeling Beam"
  ],
  "otar's bust": [
    "Statua Otaris"
  ],
  "i don't have the gold": [
    "Satis auri non habeo"
  ],
  "world map": [
    "Charta Mundi"
  ],
  "tribute chest key": [
    "Clavis Arcam Stipendii"
  ],
  "frost spirit": [
    "Animus Glaciei"
  ],
  "orcskinfemale02": [
    "OrcSkinFemale02"
  ],
  "blocking": [
    "Interponendi"
  ],
  "prime elixir of plunder": [
    "Primus Commixtum Latronis"
  ],
  "volsung's guile": [
    "Volsung's Guile"
  ],
  "agent of kynareth": [
    "Agens Kynarethis"
  ],
  "mg07 dog abilities": [
    "mg07 dog Abilities"
  ],
  "chillrend's display case": [
    "Loculamentum Frigidicaesus"
  ],
  "extra damage 4": [
    "Extra Damage 4"
  ],
  "herebane's courage": [
    "Audacia Herebanis"
  ],
  "ms08 saadia faction": [
    "MS08 Saadia Faction"
  ],
  "elixir of keenshot": [
    "Commixtum Saggitandi"
  ],
  "vokun's mastery": [
    "Vokunis Mastery"
  ],
  "great lift at mzinchaleft": [
    "Ascensore Mzinchaleft"
  ],
  "vokun's bust": [
    "Statua Vokunis"
  ],
  "grand elixir of conflict": [
    "Magnum Commixtum Pugnae"
  ],
  "bountycollector": [
    "BountyCollector"
  ],
  "dunbloatedmansgrottoqst_normaltoalert": [
    "dunBloatedMansGrottoQST_NormalToAlert"
  ],
  "ice wraith essence": [
    "Phantasmae Glaciei Essentia"
  ],
  "hrolmir": [
    "Hrolmir"
  ],
  "sneaking": [
    "Sneaking"
  ],
  "potion of keenshot": [
    "Potio Saggitandi"
  ],
  "frost dragon abilities": [
    "Frost Dragon abilities"
  ],
  "repair supplies": [
    "Requisita ad rudem reficiendum"
  ],
  "herebane sorenshield": [
    "Herebane Sorenscutum"
  ],
  "draught of conflict": [
    "Decocta Pugnae"
  ],
  "volendrung enchantment": [
    "Volendrung Enchantment"
  ],
  "prime elixir of conflict": [
    "Primus Commixtum Pugnae"
  ],
  "prime elixir of keenshot": [
    "Primus Commixtum Saggitandi"
  ],
  "the book of the dragonborn": [
    "Liber Draconigenarum"
  ],
  "book of the dragonborn": [
    "Liber Draconigenarum"
  ],
  "draught of plunder": [
    "Decocta Latronis"
  ],
  "rahgot's bust": [
    "Statua Rahgotis"
  ],
  "chillrend case key": [
    "Clavis ad Loculamentum Frigidicaesus"
  ],
  "ulfric's gag": [
    "Vercingetorigis Gag"
  ],
  "potion of conflict": [
    "Potio Pugnae"
  ],
  "philter of escape": [
    "Philtrum Fugiendi"
  ],
  "diadem of the savant": [
    "Corona Genii"
  ],
  "grand elixir of larceny": [
    "Magnum Commixtum Furti"
  ],
  "krosis' subterfuge": [
    "Krosis' Subterfuge"
  ],
  "maleeyeshumanbrownbloodshot": [
    "MaleEyesHumanBrownBloodShot"
  ],
  "femaleeyeshumanlightbluebloodshot": [
    "FemaleEyesHumanLightBlueBloodShot"
  ],
  "red eagle fire damage": [
    "Red Eagle Fire Damage"
  ],
  "spectral warhound": [
    "Bellicanis Spectralis"
  ],
  "blocked passage": [
    "Commeatus Clausum"
  ],
  "great lift at alftand": [
    "Ascensore Alftand"
  ],
  "morokei channel": [
    "Morokei Channel"
  ],
  "grand elixir of plunder": [
    "Magnum Commixtum Latronis"
  ],
  "grand elixir of escape": [
    "Magnum Commixtum Fugiendi"
  ],
  "philter of plunder": [
    "Philtrum Latronis"
  ],
  "random werewolf transformations": [
    "Random Werewolf Transformations"
  ],
  "prime elixir of larceny": [
    "Primus Commixtum Furti"
  ],
  "femaleeyeshumandarkblueblindright": [
    "FemaleEyesHumanDarkBlueBlindRight"
  ],
  "volsung's bust": [
    "Statua Volsungis"
  ],
  "philter of conflict": [
    "Philtrum Pugnae"
  ],
  "prime elixir of escape": [
    "Primus Commixtum Fugiendi"
  ],
  "halldir's pacify": [
    "Pax Halldiris"
  ],
  "elven gilded armor of regeneration": [
    "Alfa Lorica Aurata Recuperandi"
  ],
  "circlet of minor restoration": [
    "Circulum Minoris Reficiendi"
  ],
  "ring of stamina": [
    "Anulus Staminae"
  ],
  "elven gilded armor of remedy": [
    "Alfa Lorica Aurata Remedii"
  ],
  "dragonscale boots of extreme sneaking": [
    "Caligae Draconisquamatae Repsendi Extremis"
  ],
  "dwemer plate": [
    "Dvemeris Lanx"
  ],
  "firedamage": [
    "FireDamage"
  ],
  "dragonplate armor of mending": [
    "Draconilaminata Lorica Reficiendi"
  ],
  "necklace of dwindling frost": [
    "Monile Minoris Congelationis"
  ],
  "necklace of dwindling fire": [
    "Monile Minoris Ignis"
  ],
  "ebony armor of recuperation": [
    "Ebeni Lorica Staminam Recuperandi"
  ],
  "glass armor of remedy": [
    "Vitrea Lorica Remedii"
  ],
  "circlet of eminent destruction": [
    "Circulum Eminentis Destructionis"
  ],
  "necklace of waterbreathing": [
    "Monile Respirandi Sub Aqua"
  ],
  "necklace of resist magic": [
    "Monile Magicam Resistendi"
  ],
  "circlet of major conjuration": [
    "Circulum Maioris Coniurationis"
  ],
  "nightingale shadow mercer": [
    "Philomelarum Umbra Mercer"
  ],
  "dwemer dish": [
    "Dvemere Ferculum"
  ],
  "valindor's house key": [
    "Clavis ad Domum Valindoris"
  ],
  "circlet of minor alteration": [
    "Circulum Minoris Mutationis"
  ],
  "circlet of eminent illusion": [
    "Circulum Eminentis Inlusionis"
  ],
  "circlet of minor alchemy": [
    "Circulum Minoris Alchemiae"
  ],
  "haelga's bunkhouse key": [
    "Clavis ad Domum Magnam Haelgae"
  ],
  "dwemer cup": [
    "Dvemere Poculum"
  ],
  "ebony armor of rejuvenation": [
    "Ebeni Lorica Rejuvenationis"
  ],
  "circlet of alteration": [
    "Circulum Mutationis"
  ],
  "circlet of major archery": [
    "Circulum Maioris Sagittationis"
  ],
  "circlet of extreme destruction": [
    "Circulum Extremis Destructionis"
  ],
  "necklace of shock suppression": [
    "Monile Suppresionis Electricitatis"
  ],
  "dragonscale helmet of replenishing": [
    "Draconisquamata Galea Replendi"
  ],
  "nightingale's stride": [
    "Nightingale's Stride"
  ],
  "necklace of peerless stamina": [
    "Monile Maximae Staminae"
  ],
  "glass armor of recuperation": [
    "Vitrea Lorica Staminam Recuperandi"
  ],
  "bear faction": [
    "Bear Faction"
  ],
  "subterfuge": [
    "Subterfuge"
  ],
  "necklace of frost suppression": [
    "Monile Contra Glaciem"
  ],
  "dragonplate helmet of recovery": [
    "Draconilaminata Galea Magicam Recipiendam"
  ],
  "circlet of peerless archery": [
    "Circulum Optimae Saggitationis"
  ],
  "well rested": [
    "Bene Dormisti"
  ],
  "lesser shield": [
    "Lesser Shield"
  ],
  "key to pelagius farmhouse": [
    "Clavis ad Villam Rusticam Pelagii"
  ],
  "glass armor of mending": [
    "Vitrea Lorica Reficiendi"
  ],
  "necklace of waning frost": [
    "Monile Insenescentis Congelationis"
  ],
  "circlet of extreme illusion": [
    "Circulum Inlusionis Extremae"
  ],
  "necklace of flame suppression": [
    "Monile Suppressionis Flammarum"
  ],
  "circlet of major alteration": [
    "Circulum Maioris Mutationis"
  ],
  "necklace of frost resist": [
    "Monile Gelidus Resistendi"
  ],
  "dragonscale armor of regeneration": [
    "Draconisquamata Lorica Recuperandi"
  ],
  "daedric helmet of resurgence": [
    "Galea Daedrica Resurgendi"
  ],
  "necklace of resist fire": [
    "Monile Contra Vim Ignis"
  ],
  "nightingale's resolve": [
    "Nightingale's Resolve"
  ],
  "necklace of magic suppression": [
    "Monile Suppressionis Magicae"
  ],
  "ring of health": [
    "Anulus Salutis"
  ],
  "heavy fire damage": [
    "Heavy Fire Damage"
  ],
  "combat vigor": [
    "Combat Vigor"
  ],
  "elven gilded armor of recuperation": [
    "Alfa Lorica Aurata Staminam Recuperandi"
  ],
  "glass helmet of replenishing": [
    "Galea Vitrea Replendi"
  ],
  "circlet of peerless destruction": [
    "Circulum Optimae Destructionis"
  ],
  "dragonplate armor of rejuvenation": [
    "Draconilaminata Lorica Rejuvenationis"
  ],
  "glass armor of regeneration": [
    "Vitrea Lorica Recuperandi"
  ],
  "dragonscale armor of rejuvenation": [
    "Draconisquamata Lorica Rejuvenationis"
  ],
  "nahagliiv": [
    "Nahagliiv"
  ],
  "key to chillfurrow farmhouse": [
    "Clavis ad Villam Rusticam Frigidaulacis"
  ],
  "necklace of frost abatement": [
    "Monile Gelidus Resistendi"
  ],
  "glass gauntlets of eminent deft hands": [
    "Vitreae Manicae Eminentis Dexteritatis"
  ],
  "daedric armor of renewal": [
    "Daedrica Lorica Staminam Replendi"
  ],
  "glass armor of invigoration": [
    "Vitrea Lorica Invigorans"
  ],
  "riften riftweald manor": [
    "Hiati Riftweald Manor"
  ],
  "serving jail time causes some skills to lose their accumulated progress to the next value": [
    "Tempus in carcere facit ut progressus nonnullarum artium ad gradum proximum diminuantur"
  ],
  "the longer the sentence, the more skills that are affected": [
    "Plus temporis, plus artium affectorum"
  ],
  "longer the sentence, the more skills that are affected": [
    "Plus temporis, plus artium affectorum"
  ],
  "circlet of extreme archery": [
    "Circulum Extremis Saggitationis"
  ],
  "glass gauntlets of eminent lockpicking": [
    "Vitreae Manicae Eminentis Clavicarii"
  ],
  "circlet of magicka": [
    "Circulum Magicae"
  ],
  "nightingale's halo": [
    "Nightingale's Halo"
  ],
  "ring of major stamina": [
    "Anulus Maioris Staminae"
  ],
  "ebony armor of regeneration": [
    "Ebeni Lorica Recuperandi"
  ],
  "elven gilded armor of mending": [
    "Alfa Lorica Aurata Reficiendi"
  ],
  "necklace of shock abatement": [
    "Monile Contra Vim Electricam"
  ],
  "circlet of restoration": [
    "Circulum Recreandi"
  ],
  "dwemer pan": [
    "Dvemere Coctorium"
  ],
  "glass helmet of recharging": [
    "Galea Vitrea Reficiendi"
  ],
  "equip the nightingale armor": [
    "Impone Loricam Philomelae"
  ],
  "retrieve dravin's bow": [
    "Inveni Arcum Dravinis"
  ],
  "nightingale's grasp": [
    "Nightingale's Grasp"
  ],
  "dragonplate armor of renewal": [
    "Draconilaminata Lorica Staminam Replendi"
  ],
  "circlet of minor illusion": [
    "Circulum Minoris Inlusionis"
  ],
  "necklace of fire abatement": [
    "Monile Resistendi Ignis"
  ],
  "necklace of warmth": [
    "Monile Caloris"
  ],
  "riften stables key": [
    "Clavis ad Catabulum Hiati"
  ],
  "necklace of waning fire": [
    "Monile Insenescentis Ignis"
  ],
  "romlyn's house key": [
    "Clavis ad Domum Romlynis"
  ],
  "glass gauntlets of major deft hands": [
    "Vitreae Manicae Maioris Dexteritatis"
  ],
  "necklace of waning magic": [
    "Monile Insenescentis Magicae"
  ],
  "daedric helmet of replenishing": [
    "Galea Daedrica Replendi"
  ],
  "daedric armor of revival": [
    "Daedrica Lorica Revivendi"
  ],
  "tell me about the spiders infesting the mine": [
    "Dic mihi de araneis quae metallum infestant"
  ],
  "necklace of waning shock": [
    "Monile Insenescentis Electricitatis"
  ],
  "the scorched hammer key": [
    "Clavis ad Malleum Adustum"
  ],
  "scorched hammer key": [
    "Clavis ad Malleum Adustum"
  ],
  "circlet of illusion": [
    "Circulum Inlusionis"
  ],
  "circlet of minor archery": [
    "Circulum Minoris Saggitationis"
  ],
  "nightingale armor stone": [
    "Lapis Loricae Philomelarum"
  ],
  "necklace of eminent stamina": [
    "Monile Maximae Staminae"
  ],
  "daedric helmet of recovery": [
    "Galea Daedrica Magicam Recipiendam"
  ],
  "shockwave": [
    "Unda Electricitatis"
  ],
  "ring of minor health": [
    "Anulus Minoris Salutis"
  ],
  "ebony armor of invigoration": [
    "Ebeni Lorica Invigorans"
  ],
  "bolli's house key": [
    "Clavis ad Domum Bolli"
  ],
  "dragonplate armor of regeneration": [
    "Draconilaminata Lorica Recuperandi"
  ],
  "dragonscale helmet of resurgence": [
    "Draconisquamata Galea Resurgendi"
  ],
  "moth in a jar": [
    "Papilio in Matula"
  ],
  "dragonscale boots of peerless sneaking": [
    "Caligae Draconisquamatae Optimi Repsendi"
  ],
  "daedric armor of rejuvenation": [
    "Daedrica Lorica Rejuvenationis"
  ],
  "necklace of grounding": [
    "Monile Minoris Electricitatis"
  ],
  "circlet of peerless restoration": [
    "Circulum Maximi Recreandi"
  ],
  "circlet of major destruction": [
    "Circulum Maioris Destructionis"
  ],
  "butterfly in a jar": [
    "Papilio in Matula"
  ],
  "return dravin's bow": [
    "Redde Arcum Dravinis"
  ],
  "snow-shod house key": [
    "Clavis ad Domum Nivi-Calcei"
  ],
  "aerin's house key": [
    "Clavis ad Domum Aerinis"
  ],
  "circlet of alchemy": [
    "Circulum Alchemiae"
  ],
  "dragonplate armor of invigoration": [
    "Draconilaminata Lorica Invigorans"
  ],
  "necklace of extreme health": [
    "Monile Extremis Salutis"
  ],
  "ebony helmet of recharging": [
    "Ebeni Galea Reficiendi"
  ],
  "how'd you end up here fighting for ulfric": [
    "Quomodo huic venisti, pro Vercingetorigi pugnans"
  ],
  "circlet of extreme conjuration": [
    "Circulum Extremis Coniurationis"
  ],
  "so how'd you end up here": [
    "Ergo quommod huic venisti"
  ],
  "circlet of eminent restoration": [
    "Circulum Eminentis Reficiendi"
  ],
  "dragonfly in a jar": [
    "Anisoptera in Matula"
  ],
  "falkreath jail key": [
    "Clavis ad Carcerem Falkretiae"
  ],
  "windhelm jail key": [
    "Clavis ad Carcerem Ventigalea"
  ],
  "necklace of minor stamina": [
    "Monile Minoris Staminae"
  ],
  "bee and barb key": [
    "Clavis Apem et Hamum"
  ],
  "pawned prawn key": [
    "Clavis ad Squillam Venditam"
  ],
  "black-briar manor key": [
    "Clavis ad Villam Atri-Dumi"
  ],
  "elgrim's elixirs key": [
    "Clavis ad Philtra Elgrimis"
  ],
  "maven's personal key": [
    "Clavis Mavenis"
  ],
  "marise's house key": [
    "Clavis ad Domum Marisis"
  ],
  "circlet of peerless magicka": [
    "Circulum Maximi Magicae"
  ],
  "necklace of stamina": [
    "Monile Staminae"
  ],
  "circlet of extreme restoration": [
    "Circulum Extremis Reficiendi"
  ],
  "riften fishery key": [
    "Clavisad Piscatorium Hiati"
  ],
  "circlet of major alchemy": [
    "Circulum Maioris Alchemiae"
  ],
  "windhelm guard": [
    "Custos Ventigaleae"
  ],
  "affable gent": [
    "Vir Comis"
  ],
  "vuljotnaak": [
    "Vuljotnaak"
  ],
  "glass armor of rejuvenation": [
    "Vitrea Lorica Reiuvenationis"
  ],
  "circlet of eminent archery": [
    "Circulum Eminentis Saggitationis"
  ],
  "necklace of health": [
    "Monile Salutis"
  ],
  "dragonscale armor of revival": [
    "Draconisquamata Lorica Revivendi"
  ],
  "circlet of major illusion": [
    "Circulum Maioris Inlusionis"
  ],
  "daedric armor of mending": [
    "Daedrica Lorica Reficiendi"
  ],
  "necklace of dwindling shock": [
    "Monile Electricitatis Diminuentis"
  ],
  "circlet of extreme magicka": [
    "Circulum Extremis Magicae"
  ],
  "dwemer bowl": [
    "Dvemeris Patera"
  ],
  "spell tome: guardian circle": [
    "Liber Magicus: Circulum Custodium"
  ],
  "glass gauntlets of extreme deft hands": [
    "Vitreae Manicae Extremis Dexteritatis"
  ],
  "torchbug in a jar": [
    "Facimex in Matula"
  ],
  "targe of the blooded": [
    "Parma Sanguinosa"
  ],
  "rested": [
    "Dormisti"
  ],
  "circlet of waterbreathing": [
    "Circulum Respirandi Sub Aqua"
  ],
  "necklace of peerless health": [
    "Monile Optimae Salutis"
  ],
  "daedric armor of regeneration": [
    "Daedrica Lorica Recuperandi"
  ],
  "circlet of peerless illusion": [
    "Circulum Maximi Inlusionis"
  ],
  "circlet of eminent alchemy": [
    "Circulum Alchemiae Eminentis"
  ],
  "circlet of extreme alchemy": [
    "Circulum Extremis Alchemiae"
  ],
  "necklace of minor health": [
    "Monile Minoris Salutis"
  ],
  "necklace of the firewalker": [
    "Monile Ignambulatoris"
  ],
  "circlet of peerless alchemy": [
    "Circulum Optimae Alchemiae"
  ],
  "circlet of eminent alteration": [
    "Circulum Eminentis Mutationis"
  ],
  "elven gilded armor of rejuvenation": [
    "Alfa Lorica Aurata Rejuvenationis"
  ],
  "circlet of peerless alteration": [
    "Circulum Optimae Mutationis"
  ],
  "circlet of minor conjuration": [
    "Circulum Minoris Coniurationis"
  ],
  "circlet of conjuration": [
    "Circulum Coniurationis"
  ],
  "circlet of major magicka": [
    "Circulum Maioris Magicae"
  ],
  "ebony armor of mending": [
    "Ebeni Lorica Reficiendi"
  ],
  "daedric armor of invigoration": [
    "Daedrica Lorica Invigorans"
  ],
  "circlet of eminent conjuration": [
    "Circulum Eminentis Coniurationis"
  ],
  "circlet of extreme alteration": [
    "Circulum Extremis Mutationis"
  ],
  "glass gauntlets of major lockpicking": [
    "Vitreae Manicae Maioris Clavicarii"
  ],
  "dragonplate helmet of resurgence": [
    "Draconilaminata Galea Resurgendi"
  ],
  "dragonscale helmet of recovery": [
    "Draconisquamata Galea Magicam Recipiendam"
  ],
  "circlet of minor destruction": [
    "Circulum Minoris Destructionis"
  ],
  "item(s) added": [
    "Res addita(e)"
  ],
  "necklace of eminent health": [
    "Monile Eminentis Salutis"
  ],
  "circlet of peerless conjuration": [
    "Circulum Optimae Coniurationis"
  ],
  "necklace of nullification": [
    "Monile Nullificationis"
  ],
  "ring of peerless stamina": [
    "Anulus Maximae Staminae"
  ],
  "black-briar meadery key": [
    "Clavis ad Hydromelliarum Atri-Dumi"
  ],
  "necklace of resist shock": [
    "Monile Contra Vim Electricam"
  ],
  "ring of extreme stamina": [
    "Anulus Magnae Staminae"
  ],
  "necklace of magic abatement": [
    "Monile Minoris Magicae"
  ],
  "dragonscale armor of renewal": [
    "Draconisquamata Lorica Staminam Replendi"
  ],
  "necklace of extreme stamina": [
    "Monile Magnae Staminae"
  ],
  "dragonplate helmet of replenishing": [
    "Draconilaminata Galea Replendi"
  ],
  "ebony helmet of replenishing": [
    "Ebeni Galea Replendi"
  ],
  "ring of extreme health": [
    "Anulus Extremis Salutis"
  ],
  "achievements": [
    "Achievements"
  ],
  "ring of eminent stamina": [
    "Anulus Maximae Staminae"
  ],
  "glass armor of resurgence": [
    "Vitrea Lorica Resurgendi"
  ],
  "key to battle-born farmhouse": [
    "Clavis ad Villam Rusticam Proeli-Natorum"
  ],
  "necklace of major health": [
    "Monile Maioris Salutis"
  ],
  "ring of major health": [
    "Anulus Maioris Salutis"
  ],
  "ring of eminent health": [
    "Anulus Eminentis Salutis"
  ],
  "dragonscale armor of mending": [
    "Draconisquamata Lorica Reficiendi"
  ],
  "dragonplate armor of revival": [
    "Draconilaminata Lorica Revivendi"
  ],
  "dragonscale armor of invigoration": [
    "Draconisquamata Lorica Invigorans"
  ],
  "glass gauntlets of extreme lockpicking": [
    "Vitreae Manicae Extremis Clavicarii"
  ],
  "ebony helmet of resurgence": [
    "Ebeni Galea Resurgendi"
  ],
  "i've cleared the mine of spiders": [
    "Metallum e araneis mundavi"
  ],
  "nightingale strife mercer": [
    "Philomelarum Duellio Mercer"
  ],
  "ring of minor stamina": [
    "Anulus Minoris Staminae"
  ],
  "ring of peerless health": [
    "Anulus Optimae Salutis"
  ],
  "circlet of major restoration": [
    "Circulum Maioris Reficiendi"
  ],
  "holdings of jarl gjalund": [
    "Possidea Comitis Gjalundis"
  ],
  "shield - lesser": [
    "Shield - Minor"
  ],
  "circlet of minor magicka": [
    "Circulum Minoris Magicae"
  ],
  "necklace of major stamina": [
    "Monile Maioris Staminae"
  ],
  "necklace of poison resistance": [
    "Monile Veneno Resistentia"
  ],
  "circlet of destruction": [
    "Circulum Destructionis"
  ],
  "ebony armor of remedy": [
    "Ebeni Lorica Remedii"
  ],
  "viinturuth": [
    "Viinturuth"
  ],
  "elven gilded armor of invigoration": [
    "Alfa Lorica Aurata Invigorans"
  ],
  "necklace of dwindling magic": [
    "Monile Magicam Diminuendi"
  ],
  "circlet of archery": [
    "Circulum Saggitationis"
  ],
  "ring of minor magicka": [
    "Anulus Minoris Magicae"
  ],
  "resurrectionshout": [
    "ResurrectionShout"
  ],
  "find someone who can identify the unusual gem": [
    "Inveni aliquem qui de Gemma Mirabili scit"
  ],
  "(optional) retrieve nystrom's journal": [
    "(Optio) Inveni Ephemeridem Nystromis"
  ],
  "smelters": [
    "Smelters"
  ],
  "seems like coin would be worth more to you": [
    "Mihi videtur nummum Major momenti tibi sit"
  ],
  "elixir of vigor": [
    "Commixtum Vigoris"
  ],
  "meridia's retribution": [
    "Retributio Meridiae"
  ],
  "hand over the staff, or else": [
    "Da mihi rudem, vel poenas dabis"
  ],
  "take your coin": [
    "Accipe aurum tuum"
  ],
  "yes - read the letter": [
    "Sig - Lege epistulam"
  ],
  "anders": [
    "Anders"
  ],
  "bring the deathbell to ingun black-briar": [
    "Fer Tintinabulamortes Ingun Atri-Dumo"
  ],
  "bring the nightshade to ingun black-briar": [
    "Fer Trychnon Ingun Atri-Dumo"
  ],
  "bring the nirnroot to ingun black-briar": [
    "Fer Nirniradices Ingun Atri-Dumo"
  ],
  "bring the mammoth tusks to madesi": [
    "Fer dentes elephantium ad Madesi"
  ],
  "bring the gold ore to madesi": [
    "Fer minerale auri ad Madesi"
  ],
  "i can't afford to pay": [
    "Id solvere non possum"
  ],
  "fortified combat healing": [
    "Fortified Combat Healing"
  ],
  "elixir of lasting potency": [
    "Commixtum Potentiae Interminantis"
  ],
  "anders's message": [
    "Epistula Anderis"
  ],
  "bring the flawless sapphires to madesi": [
    "Fer Sapphiras Perfectas ad Madesi"
  ],
  "does this upset you": [
    "Molestatne te"
  ],
  "he did indeed": [
    "Certe, id fecit"
  ],
  "the listener": [
    "Auditor"
  ],
  "what's a listener": [
    "Quid est Auditor"
  ],
  "elixir of regeneration": [
    "Commixtum Recuperandi"
  ],
  "mortal wound": [
    "Mortal Wound"
  ],
  "elixir of extra magicka": [
    "Commixtum Maioris Magicae"
  ],
  "tg crown perk handler": [
    "TG Corona Perk Handler"
  ],
  "audiotemplateelk": [
    "AudioTemplateElk"
  ],
  "ring of major magicka": [
    "Anulus Maioris Magicae"
  ],
  "nystrom's journal": [
    "Ephemeris Nystromis"
  ],
  "give the staff to me before someone gets hurt": [
    "De mihi rudem ante aliquem noceatur"
  ],
  "spell tome: clairvoyance": [
    "Liber Magicus: Prescientia"
  ],
  "babette's bed ownership": [
    "Babette's Bed Ownership"
  ],
  "werewolf pelt": [
    "Versipellis Reno"
  ],
  "found you": [
    "Te inveni"
  ],
  "i'm not paying anything": [
    "Nhihil pecuniae sovlam"
  ],
  "ring of minor alteration": [
    "Anulus Minoris Mutationis"
  ],
  "constant damage health": [
    "Constant vulneris Health"
  ],
  "ring of eminent magicka": [
    "Anulus Eminentis Magicae"
  ],
  "elixir of enhanced stamina": [
    "Commixtum Maioris Staminae"
  ],
  "atlas of dragons": [
    "Atlas Draconum"
  ],
  "elixir of health": [
    "Commixtum Salutis"
  ],
  "torch fire": [
    "Torch Fire"
  ],
  "nystrom": [
    "Nystrom"
  ],
  "no - do not read the letter": [
    "Minime - Epistulam non lege"
  ],
  "nystrom's satchel": [
    "Cibisis Nystromis"
  ],
  "meridia's light": [
    "Lux Meridiae"
  ],
  "resurrect": [
    "Resurrect"
  ],
  "elixir of strength": [
    "Commixtum Fortitudinis"
  ],
  "ring of extreme magicka": [
    "Anulus Extremis Magicae"
  ],
  "ring of peerless magicka": [
    "Anulus Maximi Magicae"
  ],
  "ring of magicka": [
    "Anulus Magicae"
  ],
  "mine silver ore to serve your time": [
    "Defodi Argentum ut poenas des"
  ],
  "ring of the major knight": [
    "Anulus Maioris Equitis"
  ],
  "ring of minor archery": [
    "Anulus Minoris Saggitationis"
  ],
  "hevnoraak's staff": [
    "Rudis Hevnoraakis"
  ],
  "ring of minor destruction": [
    "Anulus Minoris Destructionis"
  ],
  "ring of revival": [
    "Anulus Revivendi"
  ],
  "ring of destruction": [
    "Anulus Destructionis"
  ],
  "the sanctuary in skyrim's pine forest is the dark brotherhood's last remaining refuge in all of tamriel": [
    "Fanus in Silva Pinorum est refugitum ultimum Fraternitatis Obscurae in Tamriele"
  ],
  "sanctuary in skyrim's pine forest is the dark brotherhood's last remaining refuge in all of tamriel": [
    "Fanus in Silva Pinorum est refugitum ultimum Fraternitatis Obscurae in Tamriele"
  ],
  "a dark brotherhood assassin practices the art of murder, and must be willing to pay for that crime... with gold, a jail sentence, or even more bloodshed": [
    "Sicarius Fraternitatis Obscurae artem necationis practicat, et nonnumquam pro illo crimine poenas dare debet... vel auro, tempore in carcere, vel etiam plus sanguinis"
  ],
  "dark brotherhood assassin practices the art of murder, and must be willing to pay for that crime... with gold, a jail sentence, or even more bloodshed": [
    "Sicarius Fraternitatis Obscurae artem necationis practicat, et nonnumquam pro illo crimine poenas dare debet... vel auro, tempore in carcere, vel etiam plus sanguinis"
  ],
  "ring of mending": [
    "Anulus Reficiendi"
  ],
  "ring of major lockpicking": [
    "Anulus Maioris Clavicarii"
  ],
  "the dark brotherhood was once bound by a set of rules called the five tenets, but they have long since been abandoned": [
    "Fraternitas Obscura semel rationem nomine Quinque Criteria secuta est, sed eos abhinc multos annos abiecerunt"
  ],
  "dark brotherhood was once bound by a set of rules called the five tenets, but they have long since been abandoned": [
    "Fraternitas Obscura semel rationem nomine Quinque Criteria secuta est, sed eos abhinc multos annos abiecerunt"
  ],
  "i'd like it to have the power of healing": [
    "Volo id potestatem salutis habere"
  ],
  "ring of peerless conjuration": [
    "Anulus Optimae Coniurationis"
  ],
  "ring of peerless lockpicking": [
    "Anulus Optimae Clavicarii"
  ],
  "ring of minor deft hands": [
    "Anulus Minoris Dexteritatis"
  ],
  "ring of major blocking": [
    "Anulus Maioris Interponendi"
  ],
  "ring of major smithing": [
    "Anulus Maioris Faciendi"
  ],
  "i want to deal more damage in battle": [
    "Volo plus vulneris facere dum pugno"
  ],
  "ring of extreme conjuration": [
    "Anulus Extremis Coniurationis"
  ],
  "ring of minor blocking": [
    "Anulus Minoris Interponendi"
  ],
  "speak to orthas endario about the east empire company's problems": [
    "Adloquere Orthas Endario de difficultatibus Negotii Euri Imperii"
  ],
  "ring of peerless sure grip": [
    "Anulus Optimae Ambidexteritatis"
  ],
  "ring of the eminent knight": [
    "Anulus Eminentis Equitis"
  ],
  "ring of major illusion": [
    "Anulus Maioris Inlusionis"
  ],
  "ring of extreme archery": [
    "Anulus Extremis Saggitationis"
  ],
  "ring of major destruction": [
    "Anulus Maioris Destructionis"
  ],
  "ring of smithing": [
    "Anulus Ferrarii"
  ],
  "ring of peerless wielding": [
    "Anulus Optimae Tenendi"
  ],
  "ring of peerless alteration": [
    "Anulus Optimae Mutationis"
  ],
  "necklace of disease immunity": [
    "Monile Immunitatis Morbis"
  ],
  "ring of extreme lockpicking": [
    "Anulus Extremis Clavicarii"
  ],
  "ring of extreme blocking": [
    "Anulus Extremis Interponendi"
  ],
  "ring of extreme wielding": [
    "Anulus Extremis Tenendi"
  ],
  "ring of eminent illusion": [
    "Anulus Eminentis Inlusionis"
  ],
  "ring of extreme sure grip": [
    "Anulus Extremis Ambidexteritatis"
  ],
  "ring of eminent sneaking": [
    "Anulus Maximi Repsendi"
  ],
  "key to cidhna mine": [
    "Clavis ad Metallum Cidhnae"
  ],
  "ring of recharging": [
    "Anulus Reficiendi"
  ],
  "ring of regeneration": [
    "Anulus Recuperandi"
  ],
  "ring of sneaking": [
    "Anulus Repsendi"
  ],
  "ring of minor wielding": [
    "Anulus Minoris Tenendi"
  ],
  "ring of extreme alteration": [
    "Anulus Extremis Mutationis"
  ],
  "ring of minor striking": [
    "Anulus Minoris Iciendi"
  ],
  "ring of extreme smithing": [
    "Anulus Extremis Ferrarii"
  ],
  "ring of eminent lockpicking": [
    "Anulus Eminentis Clavicarii"
  ],
  "ring of extreme sneaking": [
    "Anulus Repsendi Extremis"
  ],
  "what kind of problems is the east empire company having": [
    "Quales difficultates habet Negotium Euri Imperii"
  ],
  "ring of invigoration": [
    "Anulus Invigorans"
  ],
  "ring of major alteration": [
    "Anulus Maioris Mutationis"
  ],
  "ring of peerless smithing": [
    "Anulus Maximi Faciendi"
  ],
  "ring of eminent destruction": [
    "Anulus Eminentis Destructionis"
  ],
  "ring of peerless sneaking": [
    "Anulus Optimi Repsendi"
  ],
  "ring of minor illusion": [
    "Anulus Minoris Inlusionis"
  ],
  "ring of lockpicking": [
    "Anulus Clavicarii"
  ],
  "ring of renewal": [
    "Anulus Staminam Replendi"
  ],
  "i want to be better hidden in the shadows": [
    "Volo melius in umbris latere"
  ],
  "ring of the squire": [
    "Anulus Armiferis"
  ],
  "ring of eminent smithing": [
    "Anulus Maximi Faciendi"
  ],
  "ring of the extreme squire": [
    "Anulus Extremis Armiferis"
  ],
  "ring of peerless restoration": [
    "Anulus Maximi Recreandi"
  ],
  "ring of major sure grip": [
    "Anulus Maioris Dexteritatis"
  ],
  "ring of extreme restoration": [
    "Anulus Extremis Reficiendi"
  ],
  "ring of major wielding": [
    "Anulus Maioris Tenendi"
  ],
  "ring of eminent sure grip": [
    "Anulus Eminentis Ambidexteritatis"
  ],
  "ring of major sneaking": [
    "Anulus Maioris Repsendi"
  ],
  "ring of peerless deft hands": [
    "Anulus Optimae Dexteritatis"
  ],
  "ring of eminent wielding": [
    "Anulus Eminentis Tenendi"
  ],
  "ring of minor lockpicking": [
    "Anulus Minoris Clavicarii"
  ],
  "ring of archery": [
    "Anulus Saggitationis"
  ],
  "ring of alteration": [
    "Anulus Mutationis"
  ],
  "ring of peeless destruction": [
    "Anulus Optimae Destructionis"
  ],
  "east empire warehouse key": [
    "Clavis ad Horreum Euri Imperii"
  ],
  "ring of replenishing": [
    "Anulus Replendi"
  ],
  "ring of minor restoration": [
    "Anulus Minoris Reficiendi"
  ],
  "ring of wielding": [
    "Anulus Tenendi"
  ],
  "ring of eminent restoration": [
    "Anulus Eminentis Reficiendi"
  ],
  "ring of major deft hands": [
    "Anulus Maioris Dexteritatis"
  ],
  "ring of major archery": [
    "Anulus Maioris Sagittationis"
  ],
  "dremora dialogue": [
    "Dremora Dialogue"
  ],
  "ring of resurgence": [
    "Anulus Resurgendi"
  ],
  "i want to be tougher in battle": [
    "Volo fortiorem esse dum pugno"
  ],
  "those wishing to contact the dark brotherhood for an assassination must perform the black sacrament, an ancient and taboo ritual": [
    "Qui Fraternitatem Obscuram pro homicidiam adloqui volunt deben Sacramentum Atrum facere, sollemnitas antiqua et nefas"
  ],
  "astrid is the leader of the dark brotherhood sanctuary, and her word is law": [
    "Astrid est dux Fani Fraternitatis Obscurae, et verbum eius est lex"
  ],
  "ring of sure grip": [
    "Anulus Dexteritatis"
  ],
  "the dark brotherhood was once greatly feared and respected, but the organization is now but a shadow of its former self": [
    "Fraternitas Obscura olim terrorem et honorem secum tulit, sed grex nunc nihil est praeter umbram sui"
  ],
  "dark brotherhood was once greatly feared and respected, but the organization is now but a shadow of its former self": [
    "Fraternitas Obscura olim terrorem et honorem secum tulit, sed grex nunc nihil est praeter umbram sui"
  ],
  "mortals often represent sithis as a skeletal being, to signify his relationship to death": [
    "Mortales saepe pingunt Sithis ut ens osseosus, ut nexum eius cum morte significent"
  ],
  "in truth, the dread lord is formless, and infinite as the void": [
    "Vere, Dominus terroris est informis, tam infinitum ut Chaos"
  ],
  "ring of illusion": [
    "Anulus Inlusionis"
  ],
  "ring of minor conjuration": [
    "Anulus Minoris Coniurationis"
  ],
  "the dark brotherhood sanctuary in dawnstar is now an ancient ruin, its ominous black door feared and avoided by the city's residents": [
    "Fanum Fraternitatis Obscurae Luciferis nunc ruina antiqua est, et Ianua Atra eius terrorem ad incolas urbis fert"
  ],
  "dark brotherhood sanctuary in dawnstar is now an ancient ruin, its ominous black door feared and avoided by the city's residents": [
    "Fanum Fraternitatis Obscurae Luciferis nunc ruina antiqua est, et Ianua Atra eius terrorem ad incolas urbis fert"
  ],
  "ring of extreme illusion": [
    "Anulus Inlusionis Extremae"
  ],
  "i want to resist the forces of magic": [
    "Volo vim magicam resistere"
  ],
  "ring of eminent conjuration": [
    "Anulus Eminentis Coniurationis"
  ],
  "ring of extreme destruction": [
    "Anulus Extremis Destructionis"
  ],
  "ring of the major squire": [
    "Anulus Maioris Armiferis"
  ],
  "ring of remedy": [
    "Anulus Remedii"
  ],
  "east empire warehouse dialogue and handler": [
    "East Imperium Warehouse Dialogue et Handler"
  ],
  "ring of recovery": [
    "Anulus Magicam Recipiendam"
  ],
  "ring of the eminent squire": [
    "Anulus Eminentis Armiferis"
  ],
  "i want to strengthen my magical skills": [
    "Volo artes magicales meliores facere"
  ],
  "necklace of poison immunity": [
    "Monile Veneno Immunitatis"
  ],
  "ring of the peerless squire": [
    "Anulus Optimae Armiferis"
  ],
  "ring of eminent alteration": [
    "Anulus Eminentis Mutationis"
  ],
  "ring of peerless archery": [
    "Anulus Optimae Saggitationis"
  ],
  "ring of recuperation": [
    "Anulus Staminam Recuperandi"
  ],
  "necklace of disease resistance": [
    "Monile Morbis Resistendi"
  ],
  "ring of blocking": [
    "Anulus Interponendi"
  ],
  "ring of restoration": [
    "Anulus Recreandi"
  ],
  "ring of peerless illusion": [
    "Anulus Maximi Inlusionis"
  ],
  "ring of major restoration": [
    "Anulus Maioris Reficiendi"
  ],
  "ring of the extreme knight": [
    "Anulus Extremis Equitis"
  ],
  "behold shadowmere": [
    "Ecce Merumbra"
  ],
  "ring of eminent archery": [
    "Anulus Eminentis Saggitationis"
  ],
  "ring of the peerless knight": [
    "Anulus Optimae Equitis"
  ],
  "ring of conjuration": [
    "Anulus Coniurationis"
  ],
  "ring of rejuvenation": [
    "Anulus Rejuvenationis"
  ],
  "ring of eminent deft hands": [
    "Anulus Eminentis Dexteritatis"
  ],
  "ring of minor smithing": [
    "Anulus Minoris Ferrarii"
  ],
  "ghostly remains": [
    "Reliquias Phantasmae"
  ],
  "ring of the minor squire": [
    "Anulus Minoris Armiferis"
  ],
  "ring of peerless blocking": [
    "Anulus Optimi Interponendi"
  ],
  "ring of the minor knight": [
    "Anulus Minoris Equitis"
  ],
  "ring of eminent blocking": [
    "Anulus Eminentis Interponendi"
  ],
  "ring of major conjuration": [
    "Anulus Maioris Coniurationis"
  ],
  "ring of extreme deft hands": [
    "Anulus Extremis Dexteritatis"
  ],
  "ring of deft hands": [
    "Anulus Dexteritatis"
  ],
  "ring of minor sneaking": [
    "Anulus Minoris Repsendi"
  ],
  "marked for death - armor": [
    "Signatus Mortui - Lorica"
  ],
  "black-briar mead barrel": [
    "Cuppa Hydromelli Atri-Dumi"
  ],
  "robes of peerless conjuration": [
    "Peplum Optimae Coniurationis"
  ],
  "ebonmere lock": [
    "Clausura Ebonmeris"
  ],
  "bonus damage": [
    "Bonus damage"
  ],
  "robes of minor illusion": [
    "Peplum Minoris Inlusionis"
  ],
  "robes of major destruction": [
    "Peplum Maioris Destructionis"
  ],
  "mins": [
    "mins"
  ],
  "robes of major illusion": [
    "Peplum Maioris Inlusionis"
  ],
  "escape jail": [
    "Effuge ex carcere"
  ],
  "robes of illusion": [
    "Peplum Inlusionis"
  ],
  "aura whisper - exterior": [
    "Aura Whisper - Exterior"
  ],
  "robes of resurgence": [
    "Peplum Resurgendi"
  ],
  "place marker": [
    "Pone signum",
    "Signum Loci"
  ],
  "robes of minor destruction": [
    "Peplum Minoris Destructionis"
  ],
  "you cannot sleep while being asked to leave": [
    "Non potes dormire dum exire oreris"
  ],
  "egg sacs": [
    "Sacci Ovorum"
  ],
  "you cannot wait while being asked to leave": [
    "Non potes expectare dum exire posceris"
  ],
  "robes of extreme illusion": [
    "Peplum Inlusionis Extremae"
  ],
  "robes of major restoration": [
    "Peplum Maioris Reficiendi"
  ],
  "robes of eminent destruction": [
    "Peplum Eminentis Destructionis"
  ],
  "robes of destruction": [
    "Peplum Destructionis"
  ],
  "hairfemaleredguard04": [
    "HairFemaleRedguard04"
  ],
  "robes of peerless alteration": [
    "Peplum Optimae Mutationis"
  ],
  "robes of replenishing": [
    "Peplum Replendi"
  ],
  "red wave safe key": [
    "Clavis ad Arcam Tutum Undae Rubrae"
  ],
  "werewolf transformation": [
    "Transformatio in Versipellem"
  ],
  "human flesh": [
    "Caro Humanis"
  ],
  "robes of extreme restoration": [
    "Peplum Extremis Reficiendi"
  ],
  "shrines in the city temples will heal any disease, and confer a blessing": [
    "Arae in templis urbium omnes morbos sanare possunt, et benedictum dare"
  ],
  "accepting the blessing of one shrine will remove the blessings of any others": [
    "Unum benedictum accipere alios removet"
  ],
  "robes of alteration": [
    "Peplum Mutationis"
  ],
  "notched pickaxe": [
    "Dolabra Fragosa"
  ],
  "robes of eminent conjuration": [
    "Peplum Eminentis Coniurationis"
  ],
  "robes of eminent restoration": [
    "Peplum Eminentis Reficiendi"
  ],
  "robes of restoration": [
    "Peplum Recreandi"
  ],
  "dungeon cleared": [
    "Locus Evacuatus"
  ],
  "robes of extreme alteration": [
    "Peplum Extremis Mutationis"
  ],
  "power of saarthal": [
    "Power of Saarthalis"
  ],
  "robes of major alteration": [
    "Peplum Maioris Mutationis"
  ],
  "robes of minor restoration": [
    "Peplum Minoris Reficiendi"
  ],
  "min": [
    "min"
  ],
  "robes of minor conjuration": [
    "Peplum Minoris Coniurationis"
  ],
  "falkreath guard": [
    "Custos Falkretiae"
  ],
  "weretransformcrime": [
    "WereTransformCrime"
  ],
  "robes of extreme conjuration": [
    "Peplum Extremis Coniurationis"
  ],
  "smithing expertise": [
    "Ferrarii Ars"
  ],
  "tougher creatures have larger souls, which need larger soul gems to hold them": [
    "Inimici fortiores animos maiores habent, qui gemmanimas maiores poscunt ut eos teneant"
  ],
  "larger souls make better enchantments and recharge weapons more completely": [
    "Animi maiores incantamenta maiora faciunt et plus potestatis armis dant"
  ],
  "detectfrienddie": [
    "DetectFriendDie"
  ],
  "robes of conjuration": [
    "Peplum Coniurationis"
  ],
  "replace skeleton key": [
    "Redde Clavem Adulteram"
  ],
  "farengar's frost salts": [
    "Salia Glaciei Farengaris"
  ],
  "extra damage 6": [
    "Extra Damage 6"
  ],
  "sleeping in a bed applies the rested bonus": [
    "In lecto dormire effectum \"Dormisti\" dat"
  ],
  "sleeping in a bed you own applies the well rested bonus": [
    "In lecto tuo dormire effectum \"Bene Dormisti.\""
  ],
  "robes of eminent illusion": [
    "Peplum Eminentis Inlusionis"
  ],
  "%s resisted %s": [
    "%s resistitit %s"
  ],
  "robes of extreme destruction": [
    "Peplum Extremis Destructionis"
  ],
  "marked for death - health": [
    "Signatus Mortui - Salus"
  ],
  "hairfemaleredguard03": [
    "HairFemaleRedguard03"
  ],
  "you cannot drop keys": [
    "Claves deponere non potes"
  ],
  "robes of peerless illusion": [
    "Peplum Maximi Inlusionis"
  ],
  "robes of eminent alteration": [
    "Peplum Eminentis Mutationis"
  ],
  "red wave safe": [
    "Arca Secura Undae Rubrae"
  ],
  "robes of peerless destruction": [
    "Peplum Optimae Destructionis"
  ],
  "femaleeyesdarkelfunique": [
    "FemaleEyesDarkElfUnique"
  ],
  "hairlinemaleredguard7c": [
    "HairLineMaleRedguard7c"
  ],
  "robes of minor alteration": [
    "Peplum Minoris Mutationis"
  ],
  "animal damage": [
    "Animal vulneris"
  ],
  "cooking pot": [
    "Caccabus Cocinandi"
  ],
  "robes of peerless restoration": [
    "Peplum Maximi Recreandi"
  ],
  "robes of recharging": [
    "Peplum Reficiendi"
  ],
  "are you sure you want this perk": [
    "Esne certus te hanc artem velle"
  ],
  "robes of major conjuration": [
    "Peplum Maioris Coniurationis"
  ],
  "dwarven armillary": [
    "Dvemere Armillarium"
  ],
  "scroll of detect life": [
    "Codex Inveniendi Vivos"
  ],
  "keening's sting": [
    "Keening's Sting"
  ],
  "combat heal rate": [
    "Recuparatio Salutis dum Pugnas"
  ],
  "no arrows equipped": [
    "Nullas sagittas tenes"
  ],
  "change preset and lose your changes": [
    "Change preset et lose your changes"
  ],
  "high elves, also known as altmer, are natives of summerset isle": [
    "Altalfi, quoque sciti ut Altmeri, incolae sunt Insulae Aestoccidens"
  ],
  "it is an ancient land shrouded in mystery": [
    "Terra est antiqua et obscurata mysterii"
  ],
  "armor 70 perk bonus": [
    "Armor 70 Perk bonus"
  ],
  "bretons are natives of high rock": [
    "Britanni sunt incolae Saxi Alti"
  ],
  "it is home to the oldest known structure in all of tamriel, the adamantine tower, also known as direnni tower": [
    "Domus est aedificii antiquissimi Tamrielis, Turris Adamantinus, etiam notus ut Turris Direnni"
  ],
  "argonians can breathe underwater and are 50% resistant to diseases": [
    "Argonii sub aquae respirare possunt et morbis 50% resistunt"
  ],
  "their histskin power allows them to regenerate health quickly, for a short time": [
    "Vis Histipellis eos salutem celerius recuperare sinet"
  ],
  "magicka recovery 1.5": [
    "Magicae Magicam Recipiendam 1.5"
  ],
  "all currently active magic effects, including diseases and armor enchantments, are shown in the magic inventory, under \"active effects.\"": [
    "Omnia effecta activa magicae (et morbi et incantamenta loricarum) in inventario Magicae sunt sub \"Effecta Activa.\""
  ],
  "oppressive fog": [
    "Oppressive Fog"
  ],
  "steady hand": [
    "Manus Stabilis"
  ],
  "disenchant": [
    "Disce Incantationem"
  ],
  "magicka recovery 2": [
    "Magicae Magicam Recipiendam 2"
  ],
  "choose an item, enchantment, and soul gem": [
    "Elige rem, incantamentum, at animigemmam"
  ],
  "%s bounty": [
    "%s Vectigal"
  ],
  "deadly aim": [
    "Visus Letifer"
  ],
  "(750 gold)": [
    "(DCCL auri)"
  ],
  "armor 30 perk bonus": [
    "Armor 30 Perk bonus"
  ],
  "what did the psijic want": [
    "Quid voluit Psijicus"
  ],
  "magicka recovery 4": [
    "Magicae Magicam Recipiendam 4"
  ],
  "saarthal miner": [
    "Cunicularius Saarthalisis"
  ],
  "wood elves are 50% resistant to disease and poison": [
    "Boscalfi 50% morbo et veneno resistunt"
  ],
  "they can use command animal to make an animal their ally, for a short time": [
    "Animalibus imperare possunt"
  ],
  "the mage armor perks make defensive spells more effective for anyone wearing clothes instead of armor": [
    "Artes Lorica Magorum magicam defensivam efficaciores sunt omnibus qui vestimenta induunt nec loricas"
  ],
  "mage armor perks make defensive spells more effective for anyone wearing clothes instead of armor": [
    "Artes Lorica Magorum magicam defensivam efficaciores sunt omnibus qui vestimenta induunt nec loricas"
  ],
  "increase detection": [
    "Increase Detection"
  ],
  "next rank:": [
    "Ordo Proximus:"
  ],
  "time slowdown": [
    "Time Slowdown"
  ],
  "breton blood grants a 50% resistance to magic": [
    "Sanguis Britannorum dat resistentiam 50% magicae"
  ],
  "bretons can use dragonskin to absorb magicka from hostile spells, for a short time": [
    "Britanni Draconipelli uti possunt ut breviter intantationum inimicorum magicam absorbant"
  ],
  "dark elf blood grants 50% resistance to fire": [
    "Sanguis Atralfus 50% igni resistentia dat"
  ],
  "they can use ancestor's wrath to cloak themselves in fire, for a short time": [
    "Vi Iracundiae Maiorum uti possunt ut igni breve tempus circumdentur"
  ],
  "high elves are born with extra magicka": [
    "Altalfi nati sunt cum magica maiores"
  ],
  "they can use highborn to regenerate magicka quickly, for a short time": [
    "Vi Altinatus utuntur ut breviter magicam celerius recuperent"
  ],
  "redguards are 50% resistant to poison": [
    "Rubricustodes veneno 50% resistunt"
  ],
  "they can use adrenaline rush to regenerate stamina quickly, for a short time": [
    "Adrenalinio utuntur ut breviter staminam celeriter recuperent"
  ],
  "wood elves, also known as bosmer, are natives of valenwood": [
    "Boscalfi, vel Bosmeri, incolae Valenligni sunt"
  ],
  "this jungle nation is home to giant, migratory trees that house their cities": [
    "Haec civitas nemoris est domus arborum ingentium qui migrant et domus urbium eorum sunt"
  ],
  "nords are 50% resistant to cold damage": [
    "Germani 50% glaciei resistunt"
  ],
  "they can use battlecry to make their enemies flee, for a short time": [
    "Clamatione Proelii utunutur ut inimicos breve tempus effugiant"
  ],
  "orcs can use berserk to do double damage while only taking half damage, for a short time": [
    "Orci Berserk uti possunt ut duplex vulnus faciant et dimidium accipiant"
  ],
  "argonians are the reptilian natives of the province of black marsh": [
    "Argonii incolae reptiliani Paludis Atri sunt"
  ],
  "dark elves, also known as dunmer, are natives of morrowind - a land many consider alien and inhospitable": [
    "Atralfi, quoque ut Dunmeri noti, incolae Morroventi sunt - terra a multis aliena et inhospitabilis putatur"
  ],
  "khajiit are a feline race native to elsweyr": [
    "Khajiiti sunt homines gentis felinis et incolae Elsweyr"
  ],
  "it is infamous for producing moon sugar, which can be refined into skooma": [
    "Notissimi sunt quod Saccharum Lunae faciunt, quod in Skoomam fieri potest"
  ],
  "orcs, also known as orsimer, are natives of orsinium, a small mountain kingdom between hammerfell and skyrim": [
    "Orci, quoque ut Orsimer noti, incolae sunt Orsinium, regnum parvum montis inter Casimalleum et Caelifinem"
  ],
  "it has been sacked and rebuilt many times": [
    "Depopulatus et refectus est multoties"
  ],
  "redguards are natives of hammerfell": [
    "Rubricustodes incolae sunt Mallecasus"
  ],
  "its cities hug the edges of the great alik'r desert": [
    "Urbes eius tangunt fines Deserti Alikris"
  ],
  "can you tell me more about the augur of dunlain": [
    "Potesne mihi plus de Auguri Dunlanis narrare"
  ],
  "(optional) attempt to pick the lock to the cage": [
    "(Optio) proba portam clausam clavicario aperire et cellam aperire"
  ],
  "(give 5 gold)": [
    "(Da V auri)"
  ],
  "(optional) search the barrel for potions": [
    "(Optio) per cuppam quaere ut potiones invenias"
  ],
  "nobles traveling with bodyguard": [
    "Nobles Traveling with Bodyguard"
  ],
  "farmer refugees after dragon attack": [
    "Farmer refugees after dragon attack"
  ],
  "extra damage 3.5": [
    "Extra Damage 3.5"
  ],
  "hairlinemaleredguard6": [
    "HairLineMaleRedguard6"
  ],
  "key to morthal jail": [
    "Clavis ad Carcerem Morthalis"
  ],
  "you sent for me": [
    "Me arcessivisti"
  ],
  "hairshavedfemaleorc14": [
    "HairShavedFemaleOrc14"
  ],
  "steady hand (1)": [
    "Manus Immobilis (1)"
  ],
  "the enchantment on this item is already known": [
    "Iam scis incantamentum in hac re"
  ],
  "enchantment on this item is already known": [
    "Iam scis incantamentum in hac re"
  ],
  "thieves guild radiant guild trophy enabler": [
    "Sodalitatis Furum Radiant Guild Trophy Enabler"
  ],
  "magicka recovery 3": [
    "Magicae Magicam Recipiendam 3"
  ],
  "college of winterhold arch-mage faction": [
    "College Hiemishold Arch-Mage Faction"
  ],
  "hairlinefemaleorc14": [
    "HairLineFemaleOrc14"
  ],
  "insufficient charge for enchantment": [
    "Non satis vis incantamento"
  ],
  "magicka recovery 5": [
    "Magicae Magicam Recipiendam 5"
  ],
  "magicka recovery 4.5": [
    "Magicae Magicam Recipiendam 4.5"
  ],
  "magicka recovery 3.5": [
    "Magicae Magicam Recipiendam 3.5"
  ],
  "ghostveil": [
    "Ghostveil"
  ],
  "oppressive cloud": [
    "Oppressive Nubis"
  ],
  "potema's remains": [
    "Reliquiae Potemae"
  ],
  "extra damage 4.5": [
    "Extra Damage 4.5"
  ],
  "hairfemaleorc14": [
    "HairFemaleOrc14"
  ],
  "graybeard teach fx": [
    "Graybeard Teach FX"
  ],
  "magicka recovery 2.5": [
    "Magicae Magicam Recipiendam 2.5"
  ],
  "hairfemaleorc15": [
    "HairFemaleOrc15"
  ],
  "steady hand (2)": [
    "Manus Immobilis (2)"
  ],
  "armor 50 perk bonus": [
    "Armor 50 Perk bonus"
  ],
  "khajiit have deadly claws that can be used in unarmed combat": [
    "Khajiti ungues letiferos habent quibuis uti possunt in pungna inermi"
  ],
  "they can use night eye to see better in the dark": [
    "Oculo noctis utuntur ut in obsuritate videre possunt"
  ],
  "magicka recovery 1": [
    "Magicae Magicam Recipiendam 1"
  ],
  "warehouse crafting": [
    "Horreum Crafting"
  ],
  "hairlinemaleredguard5": [
    "HairLineMaleRedguard5"
  ],
  "cubemaps\\cavegreencube_e.dds": [
    "Cubemaps\\CaveGreenCube_e.dds"
  ],
  "restoration ritual ghost faction": [
    "Reficiendi Ritual Ghost Faction"
  ],
  "rorikstead frostfruit inn faction": [
    "Rorikstead Frostfruit Inn Faction"
  ],
  "prisoner's cuffs": [
    "Captivi Catenae"
  ],
  "nightingale shadow ability": [
    "Ars Umbrae Philomelarum"
  ],
  "insubstantial": [
    "Insubstantialis"
  ],
  "elven light armor": [
    "Alfus Mithril"
  ],
  "trickshot": [
    "Artictus"
  ],
  "here's a hide helmet": [
    "Hic habes cutis galeam"
  ],
  "wind walker": [
    "Ventambulator"
  ],
  "speak to brelyna": [
    "Adloquere Brelynam"
  ],
  "here's an iron dagger": [
    "Hic habes pugionem ferri"
  ],
  "elven light gauntlets": [
    "Alfae Manubriae"
  ],
  "eastmarch guard helmet": [
    "Eastmarch Guard Galea"
  ],
  "bring a tempered iron dagger to <alias=questgiver>": [
    "Fer Pugionem Ferri (Melius) ad <Alias=QuestGiver>"
  ],
  "whatever you're getting paid, i'll double it": [
    "Quidquid pecuniae accepisti, duplice tibi dabo"
  ],
  "elven light boots": [
    "Alfae Caligulae"
  ],
  "intimidation": [
    "Intimidatio"
  ],
  "femaleeyesorciceblueblindright": [
    "FemaleEyesOrcIceBlueBlindRight"
  ],
  "hlaalu farm faction": [
    "Hlaalu Farm Faction"
  ],
  "need any help around the forge": [
    "Visne auxilium in fabrica"
  ],
  "quick shot": [
    "Ictus Celer"
  ],
  "bring the leather to <alias=questgiver>": [
    "Fer Corium ad <Alias=QuestGiver>"
  ],
  "balbus's fork": [
    "Balbi Bifurcum"
  ],
  "atronachfrost fx": [
    "AtronachFrost fx"
  ],
  "use the arcane enchanter": [
    "Uti Incantatore Arcana"
  ],
  "robes of quickening": [
    "Peplum Rapidius Magicae"
  ],
  "i've sharpened the iron dagger": [
    "Pugionem Ferri acriorem feci"
  ],
  "i've tempered the hide helmet": [
    "Cutis Galeam meliorem feci"
  ],
  "the book of fate": [
    "Liber Fati"
  ],
  "book of fate": [
    "Liber Fati"
  ],
  "wait for brelyna's spell to wear off": [
    "Expecta Finem Magicae Brelynae"
  ],
  "summoner": [
    "Vocator"
  ],
  "meadbarrel": [
    "MeadBarrel"
  ],
  "the dancer's flute": [
    "Saltatoris Syrinx"
  ],
  "dancer's flute": [
    "Saltatoris Syrinx"
  ],
  "windshear bash spell": [
    "Windshear Bash Spell"
  ],
  "tell me where you're going before someone gets hurt": [
    "Dic mihi quo is ante aliquis nocetur"
  ],
  "can i use the alchemy lab": [
    "Possumne laboratorio alchemiae uti"
  ],
  "testwritingdude": [
    "TestWritingDude"
  ],
  "here's that leather you wanted": [
    "Hic habes illum corium quem voluisti"
  ],
  "temper a hide helmet": [
    "Meliorem Fac Pellem Galeam"
  ],
  "huntsman's prowess": [
    "Ars Venatoris"
  ],
  "very well, let's go": [
    "Bene, eamus"
  ],
  "concentrated poison": [
    "Venenum Condensatum"
  ],
  "targe bashing bleeding": [
    "ParmaBashing Bleeding"
  ],
  "you shouldn't talk to me like that": [
    "Me hoc modo adloqui non debes"
  ],
  "whitestag": [
    "VhiteStag"
  ],
  "nightingale shadow perk": [
    "Nightingale Shadow Perk"
  ],
  "elven light helmet": [
    "Alfa Parma"
  ],
  "windshear bash perk": [
    "Windshear Bash Perk"
  ],
  "stagger area attack frost atronach": [
    "Stagger Area Attack Frost Atronach"
  ],
  "femaleeyesorcredblindright": [
    "FemaleEyesOrcRedBlindRight"
  ],
  "how do you enchant weapons and armor": [
    "Quomodo incantamenta in arma loricasque ponere possum"
  ],
  "bring an iron dagger to <alias=questgiver>": [
    "Fer Pugionem Ferri ad <Alias=QuestGiver>"
  ],
  "windshear bash": [
    "Windshear Bash"
  ],
  "critical shot": [
    "Ictus Criticus"
  ],
  "worn shrouded gloves": [
    "Obsoletus Umbratus Manubria"
  ],
  "silent roll": [
    "Volutus Silens"
  ],
  "i could save you a lot of trouble... (persuade)": [
    "E multis difficultibus te servare possum... (Persuade)"
  ],
  "taunting adventurer": [
    "Taunting Adventurer"
  ],
  "worn shrouded cowl": [
    "Obsoletus Umbratus Capucium"
  ],
  "white stag": [
    "Cerva Alba"
  ],
  "poisoned": [
    "Venenatum"
  ],
  "rune master": [
    "Magister Runarum"
  ],
  "reflect blows": [
    "Reflecta Ictus"
  ],
  "trick shot disarm": [
    "Trick Shot Disarm"
  ],
  "dark brotherhood assassin's note": [
    "Epistula Sicarii Fraternitatis Obscurae"
  ],
  "multiplesummons": [
    "MultipleSummons"
  ],
  "craft some leather": [
    "Fac Corium"
  ],
  "hollyfrost farm faction": [
    "Hollyfrost Farm Faction"
  ],
  "thalmor traveling on the road": [
    "Visigothi traveling on road"
  ],
  "worn shrouded boots": [
    "Obsoletus Umbratus Caligae"
  ],
  "windshear bash effect": [
    "Windshear Bash Effect"
  ],
  "bring a hide helmet to <alias=questgiver>": [
    "Fer Pellis Galeam ad <Alias=QuestGiver>"
  ],
  "imperials on the road": [
    "Romani on Road"
  ],
  "femaleeyesorcyellowblindleft": [
    "FemaleEyesOrcYellowBlindLeft"
  ],
  "bring a tempered hide helmet to <alias=questgiver>": [
    "Fer Pellis Galeam (Melior) ad <Alias=QuestGiver>"
  ],
  "craft a hide helmet": [
    "Fac Pellis Galeam"
  ],
  "temper an iron dagger": [
    "Meliorem Face Pugionem Ferri"
  ],
  "craft a potion": [
    "Fac Potionem"
  ],
  "craft an iron dagger": [
    "Fac Pugionem Ferri"
  ],
  "adventurer on the way to nearby dungeon": [
    "Adventurer on Way to Nearby Dungeon"
  ],
  "where are you headed": [
    "Quo is"
  ],
  "femaleeyesorcleftblindsingle": [
    "FemaleEyesOrcLeftBlindSingle"
  ],
  "thieves guild": [
    "Sodalitas Furum"
  ],
  "reanimate spell": [
    "reanimate spell"
  ],
  "can you play \"the dragonborn comes\"": [
    "Potesne canere \"Draconigena Venit\""
  ],
  "stormcloaks on the road": [
    "Stormcloaks on Road"
  ],
  "report to the jarl of whiterun": [
    "Refer te ad Comitem Albacursus"
  ],
  "listen, i don't want any trouble": [
    "Ausculta, dificultatem nolo"
  ],
  "brandy mug farm faction": [
    "Brandy Mug Farm Faction"
  ],
  "targe bleeding": [
    "Parmableeding"
  ],
  "green thumb": [
    "Pollex Viridis"
  ],
  "frost atronach fx": [
    "Frost atronach fx"
  ],
  "worn shrouded armor": [
    "Obsoleta Lorica Umbrata"
  ],
  "aggressive adventurer": [
    "Aggressive Adventurer"
  ],
  "college guard": [
    "Custos Collegii"
  ],
  "ysgramor's soup spoon": [
    "Ysgramoris Cochlear Iuris"
  ],
  "femaleeyesorcrightblindsingle": [
    "FemaleEyesOrcRightBlindSingle"
  ],
  "septimus vanish": [
    "Septimus Vanish"
  ],
  "dibella is the goddess of beauty": [
    "Dibell est dea pulchritudinis"
  ],
  "she has a temple in markarth that is devoted to women": [
    "Templum Markartiae habet quae ad feminas dedicatur"
  ],
  "modify restoration": [
    "Modify Reficiendi"
  ],
  "browsmaleorc05": [
    "BrowsMaleOrc05"
  ],
  "master vampire": [
    "Domina Vampira"
  ],
  "barter": [
    "Negotium Acera"
  ],
  "shalidor's insights: restoration": [
    "Acuitas Shalidoris: Reficiendi"
  ],
  "key to odfel's house": [
    "Clavis ad Domum Odfelis"
  ],
  "each time you level up, you may pick a perk": [
    "Cum ad gradum novum ascendas, peritiam eligere potes"
  ],
  "you can save your perk choice for later if you don't quite meet the requirements of the perk you want": [
    "Potes servare puncta peritiae ad tempus posterius"
  ],
  "shalidor's insights: conjuration": [
    "Acuitas Shalidoris: Ars Vocandi"
  ],
  "chickens' nest": [
    "Nidus Gallinae"
  ],
  "tiber septim brought peace to tamriel in 2e 896, by conquering all of the known world. thus began the third era": [
    "Tiberius Septimus, victoria super totum mundum scitum, pacem ad Tamrielem Anno DCCCXCVI Aetatis Secundae tulit et, hoc modo, coepit Aetatem Tertiam"
  ],
  "why would ancano be looking for me": [
    "Cur Ancano me quaerit"
  ],
  "sorry, i can't spare any gold": [
    "Ignosce, nullum aurum tibi dare possum"
  ],
  "hooded black robes": [
    "Capulatum Peplum Atrum"
  ],
  "wax key": [
    "Clavis Cerata"
  ],
  "hooded monk robes": [
    "Capulatum Monachi Peplum"
  ],
  "how do you know anything about this": [
    "Quomodo aliquid de hoc scis"
  ],
  "wound test": [
    "vound test"
  ],
  "hooded mage robes": [
    "Capulatum Magi Peplum"
  ],
  "imperials are natives of cyrodiil, the cosmopolitan heartland of the empire": [
    "Romani incolae sunt Romae, urbs maximi momenti Imperii"
  ],
  "river betty": [
    "Betteam Rivi"
  ],
  "honed ancient nord greatsword of burning": [
    "Melior Germanica Antiqua Spatha Cremandi"
  ],
  "the war of the red diamond began in 3e 121 when uriel iii captured the throne with his mother, queen potema": [
    "Bellum Adamantei Anno CXXI Rubri Aetatis Tertiae coepit, cum Uriel III solium cum matre sua, Regina Potema caperet"
  ],
  "war of the red diamond began in 3e 121 when uriel iii captured the throne with his mother, queen potema": [
    "Bellum Adamantei Anno CXXI Rubri Aetatis Tertiae coepit, cum Uriel III solium cum matre sua, Regina Potema caperet"
  ],
  "it ended in 3e 127 with their defeat at the siege of solitude": [
    "Terminavit Anno CXXVII Aetatis Tertiae post cladem eius in Obsidione Solitudinis"
  ],
  "and the paint on the cow": [
    "Et pinge in vaccam"
  ],
  "peryite is the daedric prince of tasks, order and pestilence": [
    "Peryitus est princeps daedricus Faciendi, Ordinis, Pestilentiaeque"
  ],
  "he is considered one of the weakest of the princes, despite being depicted as a dragon": [
    "Putatur unus ex debilissimis Deadrae etiamsi seape ut draco pingitur"
  ],
  "hairfemalenord21": [
    "HairFemaleNord21"
  ],
  "modify conjuration": [
    "Modify Ars Vocandi"
  ],
  "manakin": [
    "Manakin"
  ],
  "pickpocketing": [
    "Pickpocketing"
  ],
  "fine-cut void salts": [
    "Salia Chaos Optima"
  ],
  "in 3e 389 emperor uriel vii was imprisoned by his imperial battlemage, jagar tharn, who then impersonated him for years": [
    "Anno CCCLXXXIX Aetatis Tertiae Imperator Uriel VII in carcere positus est a Bellimago Imperiali, Jagar Tharn, qui postea personam eius egit nonullos annos"
  ],
  "dragon priest faction": [
    "Dragon Priest Faction"
  ],
  "namira is the daedric prince of darkness and revulsion, and is frequently associated with disease, spiders, insects, slugs and other repulsive creatures": [
    "Namira est princeps daedrica obscuritatis et putrefacionis, et saepe cum aegritudine, araneis, limacibus, et alia animalia foeda iuncta est"
  ],
  "dual savagery": [
    "Saevitas Duplex"
  ],
  "haafingar guard": [
    "Custos Haafingaris"
  ],
  "tanning racks": [
    "Tanning Racks"
  ],
  "julianos is the god of wisdom and logic": [
    "Julian est deus Sapientiae Logicaeque"
  ],
  "usually associated with magic, he is often revered by wizards": [
    "Praesertim adsocius magicae, saepe adoratur Thaumatorgis"
  ],
  "he has a shrine in the temple of the divines in solitude": [
    "Aram habet in Templo Divinorum Solitudini",
    "Aram habet in Templo Divinorum Solitudinis"
  ],
  "kyne's token": [
    "Kynis Token"
  ],
  "kyne's blessing": [
    "Benedictio Panis"
  ],
  "block runner": [
    "Scuticursor"
  ],
  "thanks for the warning": [
    "Gratias pro comminatio"
  ],
  "sorry, i'll be leaving now": [
    "Ignosce, nunc exeam"
  ],
  "pelagius iii, also known as pelagius the mad, was jarl of solitude before ascending to the throne of the emperor in 3e 145": [
    "Pelagius III, quoque ut Pelagius Insanus scitus, erat Comes Solitudinis ante ad Solium Imperatoris ascendit Anno CXLV Aetatis Tertiae"
  ],
  "dual flurry": [
    "Quassatio Duplex"
  ],
  "deft movement": [
    "Motus Peritus"
  ],
  "what do you know about ancano": [
    "Quid de Ancano Scis"
  ],
  "quick hands": [
    "Manus Rapidae"
  ],
  "modify destruction": [
    "Modify Destruction"
  ],
  "key to mehrunes' shrine": [
    "Clavis Ad Arae Croni"
  ],
  "reduced damage 0.5": [
    "Reduced Damage 0.5"
  ],
  "abecean longfin": [
    "Dorsilongus Abeceanus"
  ],
  "histcarp": [
    "Histicarpam"
  ],
  "courier's letter": [
    "Epistula Nuntii"
  ],
  "talos is the hero-god of mankind": [
    "Talos est semideus hominum"
  ],
  "his worship is currently banned by the empire, but that hasn't stopped the nords of skyrim from revering him": [
    "Religio eius vetatur ab Imperio, sedGermani Caelifinis iam preces ei adhibentur"
  ],
  "daedric princes are god-like beings that inhabit the plane of oblivion": [
    "Principes Daedrici sunt entes dei-similes qui mundum Oblivionis incolunt"
  ],
  "most are considered evil, forcing their worshippers to build their shrines far from civilized places": [
    "Multi pravi putantur, sic secutores eorum aras procul urbes oppidaque aedificant"
  ],
  "azura is the daedric prince of dusk and dawn, and one of the few not considered to be inherently evil": [
    "Azura est princeps daedrica temporum crepuscularium, et unus ex paucis quae non omnino mala putatur"
  ],
  "need any help": [
    "Auxiliumne quaeris"
  ],
  "quickshot": [
    "QuickShot"
  ],
  "boethiah is the daedric prince of deceit, conspiracy, and secret plots of murder": [
    "Boethis est princeps daedrica decipiendi, coniurationum, et necandium secretorum"
  ],
  "many dark elves consider him to be their god-ancestor": [
    "Multi Atralfi credunt se descendentes eius esse"
  ],
  "browsmaleorchorns01": [
    "BrowsMaleOrcHorns01"
  ],
  "clavicus vile is the daedric prince of power": [
    "Clavicus est princeps deadricus mercatorum"
  ],
  "he is fond of enticing mortals with tempting pacts... which they later come to regret": [
    "Ille mortales ad foedera quae temptant ducere solet...Illi primo sequuntur et deinde paenitent"
  ],
  "hermaeus mora is the daedric prince of fate, knowledge and memory": [
    "Hermes Trismegistus est princeps daedricus Fati, Scientiae, Memoriaeque"
  ],
  "in his rare dealings with mortals, he often chooses to appear as a grotesque mass of tentacles": [
    "Etsi rare ad mortales apparet, saepe apparet ut cumulus flagellorum"
  ],
  "the daedric prince hircine is also known as the huntsman of the princes, and the father of manbeasts": [
    "Princeps Daedrica Hecate quoque scitur ut Venator Principum et Pater Versipellium"
  ],
  "daedric prince hircine is also known as the huntsman of the princes, and the father of manbeasts": [
    "Princeps Daedrica Hecate quoque scitur ut Venator Principum et Pater Versipellium"
  ],
  "he can call upon the wild hunt to punish those who defy him": [
    "Venationem Saevum vocare potest ut puniat eos qui vim Hecatis negant"
  ],
  "malacath is the daedric prince of curses and the spurned": [
    "Styx est princeps daedricus meladictionum et calcatorum"
  ],
  "many orcs worship him as their god-king": [
    "Multi Orcorum eum adorant ut Deum-Regem"
  ],
  "mephala is the daedric prince of lies, secrets and plots... and thoroughly enjoys meddling in the affairs of mortals": [
    "Arachne est princeps daedrica periuriorum, secretorum, coniurationum... et gaudet in rebus mortalibus interpellare"
  ],
  "meridia despises undead in any form, and is one of the few daedric princes not considered to be wholly evil": [
    "Ceres omnes amortuos odit, et est unus ex pricipibus daedricis qui non omnino pravam putatur esse"
  ],
  "sanguine is the daedric prince of hedonism, debauchery and dark indulgences": [
    "Sanguine (Pater Liber) est princeps hedoniae, helluatinis, et indulgentia obscura"
  ],
  "he is more likely than most princes to take an interest in mortal affairs": [
    "Solet in rebus humanis se interponere plus quam alii principes"
  ],
  "hooded black mage robes": [
    "Capulatum Magi Peplum Atrum"
  ],
  "silverside perch": [
    "Argentilatus"
  ],
  "vaermina is the daedric prince of dreams, nightmares and evil omens": [
    "Morpheus est princeps daedricus somnium, suppressionum, et ominum pravorum"
  ],
  "kodlak's wolf spirit": [
    "Animus Lupi Kodlakis"
  ],
  "browsmaleorchorns04": [
    "BrowsMaleOrcHorns04"
  ],
  "giant leading a painted cow to camp": [
    "Giant leading painted cow to camp"
  ],
  "kynareth is the goddess of the air, wind and sky": [
    "Kynareth est deus Aeris, Ventus, et Caeli"
  ],
  "she has a temple in whiterun": [
    "Habet templum Albacursus"
  ],
  "stendarr is the god of righteous rule by might and merciful forbearance": [
    "Stendarr est deus pii regnandi et veniae"
  ],
  "shalidor's insights: magicka": [
    "Acuitas Shalidoris: Magica"
  ],
  "werewolf feed victim": [
    "Werewolf Feed Victim"
  ],
  "maleheadmanekin": [
    "MaleHeadManekin"
  ],
  "there's a rumor that you're spying for the thalmor": [
    "Rumor dicit te pro Visigothis speculari"
  ],
  "you're no longer in debt with sapphire": [
    "Nondum in aere alieno Sapphiri es"
  ],
  "arkay is the god of burials and funeral rites": [
    "Arkaya est dea sepulturae et funerum"
  ],
  "his priests root out undead and necromancy wherever it may be found": [
    "Sacerdotes eius amortuos necromanticosque invenÃªrunt et necant ubiqui sint"
  ],
  "every skyrim city has a hall of the dead dedicated to him": [
    "Omnes urbes Caelifinis Domus Mortuorum habet ei dedicata"
  ],
  "modify alteration": [
    "Modify Alteration"
  ],
  "farmer leading painted cow to giant camp": [
    "Farmer leading Painted Cow to Giant Camp"
  ],
  "browsmaleorc01": [
    "BrowsMaleOrc01"
  ],
  "hairlinefemaleorc17": [
    "HairLineFemaleOrc17"
  ],
  "at helgen, they said you shouted the king to death": [
    "Apud Helgen, dixÃªrunt te regem ad mortem clamavit"
  ],
  "shalidor's insights: illusion": [
    "Acuitas Shalidoris: Inlusio"
  ],
  "zenithar is the god of work and commerce": [
    "Zenithar est deus commercii"
  ],
  "browsmaleorchorns05": [
    "BrowsMaleOrcHorns05"
  ],
  "shock damage area": [
    "Shock Damage Area"
  ],
  "conjure flaming familiar": [
    "Voca Familiarem Ardentem"
  ],
  "fellstar farm key": [
    "Clavis ad Fundum Fellistellae"
  ],
  "sheogorath is the daedric prince of madness": [
    "Sheogorath est princeps daedricus insanitatis"
  ],
  "he is utterly unpredictable, and may hinder or help any mortal unlucky enough to cross his path": [
    "Ille est omnino variabilis et forte vel adiuvabit vel impediet mortales"
  ],
  "mara is the goddess of love and compassion": [
    "Mara est dea amoris clementiaeque"
  ],
  "she has a temple in riften": [
    "Templum habet Hiati"
  ],
  "browsmaleorc08": [
    "BrowsMaleOrc08"
  ],
  "ditched it for this": [
    "Id pro hoc deposui"
  ],
  "easier to sneak past the enemy": [
    "Ut trans inimicos facilius repam"
  ],
  "akatosh is the god of time and the chief deity of the nine divines": [
    "Akatosh est deus temporis et princeps Novem Divinorum"
  ],
  "he is depicted as a dragon, and chose that form to battle mehrunes dagon in the oblivion crisis": [
    "Pingitur ut draco et hanc formam elegit dum contra Cronum pugnavit in Caedi Oblivionis"
  ],
  "yes, we did": [
    "Sic, id fecimus"
  ],
  "i just informed the arch-mage": [
    "Iam Archimagum certiorem feci"
  ],
  "why does this matter to you": [
    "Quid tibi interest"
  ],
  "what does it matter what i say to him": [
    "Quid interest in eo quod ei dicam"
  ],
  "what's the paint on the cow for, again": [
    "Cur vaccam pinxisti...iterum"
  ],
  "browsmaleorchorns03": [
    "BrowsMaleOrcHorns03"
  ],
  "i'll say what i like to him": [
    "Ei dicam quid velim "
  ],
  "browsmaleorchorns02": [
    "BrowsMaleOrcHorns02"
  ],
  "what are you offering to the giants": [
    "Quid gigantibus offers"
  ],
  "did you see where the dragon went": [
    "Vidisine quo iret draco"
  ],
  "hooded blue robes": [
    "Capulatum Caeruleum Peplum"
  ],
  "browsmaleorc02": [
    "BrowsMaleOrc02"
  ],
  "ice pile": [
    "Cumulum Glaciei"
  ],
  "hairlinefemalenord21": [
    "HairLineFemaleNord21"
  ],
  "wearing heavy armor or using heavy weapons will slow you down": [
    "Loricam gravem induens vel arma gravia utens te lentiorem facit"
  ],
  "modify illusion": [
    "Modify Illusion"
  ],
  "cyrodilic spadetail": [
    "Caudapalam Romanam"
  ],
  "mead with juniper berry": [
    "Hydromellum cum Iunipere"
  ],
  "browsmaleorc04": [
    "BrowsMaleOrc04"
  ],
  "mehrunes dagon is the daedric prince of destruction, revolution and ambition": [
    "Cronos est princeps daedricus destructionis, revolutionis, et ambitionisque"
  ],
  "he caused the oblivion crisis when he attempted to enter tamriel in his true, and terrible, form": [
    "Caedes Oblivionis fecit cum Tamrielem intrare conatus est in forma sua vera...et terribilis"
  ],
  "femaleeyesorcvampire": [
    "FemaleEyesOrcVampire"
  ],
  "hairfemaleorc17": [
    "HairFemaleOrc17"
  ],
  "shalidor's insights: alteration": [
    "Acuitas Shalidoris: Alteratio"
  ],
  "shalidor's insights: destruction": [
    "Acuitas Shalidoris: Destructio"
  ],
  "abacean longfin": [
    "Dorsilongum Abaceanum"
  ],
  "be on your way": [
    "Perge in viam tuam"
  ],
  "on your way": [
    "Perge in viam tuam"
  ],
  "the mournful throne": [
    "Solium Maerens"
  ],
  "mournful throne": [
    "Solium Maerens"
  ],
  "jyrik's blessing": [
    "Jyrikis Benedictio"
  ],
  "key to filnjar's house": [
    "Clavis ad Domum Filnjaris"
  ],
  "key to klimmek's house": [
    "Clavis ad Domum Klimmekis"
  ],
  "hairlinemaleorc26": [
    "HairLineMaleOrc26"
  ],
  "priestess of arkay": [
    "Flamina Arkayae"
  ],
  "knockdown effect": [
    "Knockdown Effect"
  ],
  "hooded necromancer robes": [
    "Capulatum Necromancer Peplum"
  ],
  "fortify hms": [
    "Adde ad Magicam, Staminam, Salutemque"
  ],
  "sprigganmatronfx ability": [
    "SprigganMatronFX Ars"
  ],
  "i failed to bring down sinding": [
    "Sinding necare non potui"
  ],
  "right eye of the falmer": [
    "Oculus Dexter Falmerium"
  ],
  "summersetshadowshood": [
    "SummersetShadovsCapucium"
  ],
  "dual flurry 30": [
    "Celeritas Duplex Ambabus Manibus XXX"
  ],
  "this was all i could find": [
    "Hoc erat solum quod invenire potui"
  ],
  "sigil of alteration": [
    "Sigillum Mutationis"
  ],
  "this person is busy": [
    "Hic occupatus est"
  ],
  "i'm out of missives, but i need some more": [
    "Plurimas epistulas non habeo, sed plurimis careo"
  ],
  "summerset shadows boots": [
    "Summerset Shadovs Caligae"
  ],
  "jewelry": [
    "MONILIA ET ANULI"
  ],
  "arch pyromancer": [
    "Archignimagus"
  ],
  "linwe's armor": [
    "Linvis Lorica"
  ],
  "dwarven": [
    "DVEMERIS"
  ],
  "shadowstrength": [
    "Shadowstrength"
  ],
  "spell tome: transmute mineral ore": [
    "Liber Magicus: Muta Metallum"
  ],
  "spriggan matron abilities": [
    "Spriggan Matron abilities"
  ],
  "gem pedestal": [
    "Bas Gemmae"
  ],
  "transmute mineral ore": [
    "Transmuta Minerale"
  ],
  "filled with %s soul": [
    "Plena Animo %s"
  ],
  "linwe's boots": [
    "Linvis Caligae"
  ],
  "your teammate already owns that armor": [
    "Your teammate already owns that armor"
  ],
  "key to morthal guardhouse": [
    "Clavis ad Custodiam Morthalis"
  ],
  "war paint color": [
    "Color Pigmenti Belli"
  ],
  "prisoner belongings chest": [
    "Arca Rerum Captivi"
  ],
  "you lack the required perks to improve this item": [
    "Arte postulato hanc rem meliorem faciendi cares"
  ],
  "most nords of skyrim assumed the dragons were only a legend": [
    "Complures Germani Caelifinis putaverunt dracones legenda esse"
  ],
  "transmogrify": [
    "Transmogrifica"
  ],
  "femaleeyeswoodelfdeepbrownblindleft": [
    "FemaleEyesWoodElfDeepBrownBlindLeft"
  ],
  "femaleeyeselfrightblindsingle": [
    "FemaleEyesElfRightBlindSingle"
  ],
  "linwe's hood": [
    "Linvis Capucium"
  ],
  "ebony": [
    "EBENI"
  ],
  "arch cryomancer": [
    "Archiglacimagus"
  ],
  "underforge fountain": [
    "Fons Plutofabricae"
  ],
  "you cannot change shouts while shouting": [
    "Clamationem mutare non potes dum clames"
  ],
  "sky haven blades sparring scene": [
    "Sky Haven Enses sparring scene"
  ],
  "you don't have enough gold": [
    "Non satis auri habes"
  ],
  "you cannot fast travel at this time": [
    "Hoc tempore celeriter peregrinari non potes"
  ],
  "linwe's gloves": [
    "Linvis Manubria"
  ],
  "endon's silver mold": [
    "Endonis Argentei Mold"
  ],
  "dirt color": [
    "Color Luti"
  ],
  "sigil of illusion": [
    "Sigillum Illusionis"
  ],
  "soul siphon": [
    "Sipho Animi"
  ],
  "sigil of restoration": [
    "Sigillum Recreationis"
  ],
  "shadowthrive": [
    "Shadowthrive"
  ],
  "spriggan matron swarm spray": [
    "Spriggan Matron Swarm Spray"
  ],
  "i defy you and your vile tasks": [
    "Te et mandata malifica tua nego"
  ],
  "master trader": [
    "Dominus Mercatorum"
  ],
  "sigil of destruction": [
    "Sigillum Destructionis"
  ],
  "shadowsight": [
    "Shadowsight"
  ],
  "arch conjurer": [
    "Archivocator"
  ],
  "the wilds of skyrim are teeming with deer": [
    "Agri Caelifinis pleni cervorum sunt"
  ],
  "wilds of skyrim are teeming with deer": [
    "Agri Caelifinis pleni cervorum sunt"
  ],
  "they are docile creatures, but serve as food for much of the province's more savage beasts": [
    "Animalia docilia sunt, et cibi sunt bestiis saevioribus provinciae"
  ],
  "the cow is one of skyrim's most common domestic animals": [
    "Vacca est unus ex animalibus communissimis Caelifinis"
  ],
  "cow is one of skyrim's most common domestic animals": [
    "Vacca est unus ex animalibus communissimis Caelifinis"
  ],
  "it provides the native nords with meat, milk, and furs": [
    "Dat Germanis nativis et carnem et lac, et pellem"
  ],
  "shadowstrike": [
    "Shadowstrike"
  ],
  "you cannot wait at this time": [
    "Hoc tempore exspectare non potes"
  ],
  "femaleeyeshighelforangeblindright": [
    "FemaleEyesHighElfOrangeBlindRight"
  ],
  "transmute": [
    "Transmute"
  ],
  "orcish": [
    "ORCI"
  ],
  "skull of corruption handler quest": [
    "Skull of Corruption Handler Quest"
  ],
  "choose an item to destroy and learn its effects": [
    "Elige rem quam delebis ut effecta discas"
  ],
  "arch electromancer": [
    "Archielectrimagus"
  ],
  "daedric": [
    "DAEDRICA"
  ],
  "you cannot sleep at this time": [
    "Hoc tempore dormire non potes"
  ],
  "silver lining handler": [
    "Argentei Lining Handler"
  ],
  "you lack the required skill to improve this item": [
    "Arte postulato hanc rem meliorem faciendi cares"
  ],
  "childeyesvampire": [
    "ChildEyesVampire"
  ],
  "stendarr's beacon": [
    "Signum Peryitis"
  ],
  "complexion color": [
    "Color Complexionis"
  ],
  "you cannot use this while in combat": [
    "Hoc uti non potes dum pugnes"
  ],
  "summerset shadows armor": [
    "Summerset Shadovs Lorica"
  ],
  "arch necromancer": [
    "Archinecromanticus"
  ],
  "mg08 fireball": [
    "MG08 Fireball"
  ],
  "summerset shadows gloves": [
    "Summerset Shadovs Manubria"
  ],
  "femaleeyesdarkelfdeepredblindleft": [
    "FemaleEyesDarkElfDeepRedBlindLeft"
  ],
  "dual flurry 50": [
    "Celeritas Duplex Ambabus Manibus L"
  ],
  "you're in luck. i happen to already have the mold": [
    "Fortuna tecum est. Figuram iam habeo"
  ],
  "femaleeyeselfleftblindsingle": [
    "FemaleEyesElfLeftBlindSingle"
  ],
  "femaleeyesdarkelfdeepred2blindright": [
    "FemaleEyesDarkElfDeepRed2BlindRight"
  ],
  "studded": [
    "ORNATUM"
  ],
  "dualflurry": [
    "DualFlurry"
  ],
  "femaleeyeswoodelfdeepvioletblindright": [
    "FemaleEyesWoodElfDeepVioletBlindRight"
  ],
  "elven": [
    "ALFUS"
  ],
  "tattered note": [
    "Epistula Fragosa"
  ],
  "you can't take your teammate's starting armor": [
    "You can't take your teammate's starting armor"
  ],
  "femaleeyeshighelfyellowblindleft": [
    "FemaleEyesHighElfYellowBlindLeft"
  ],
  "gloves of the pugilist": [
    "Manubria Pugilis"
  ],
  "sovengarde effect": [
    "Sovengarde effect"
  ],
  "da03hello": [
    "DA03Hello"
  ],
  "nightingale power handler": [
    "Nightingale Power Handler"
  ],
  "makes spell high priority for ai/cast once only": [
    "Makes Spell high priority for AI/Cast Once Only"
  ],
  "this doesn't seem legal": [
    "Fas mihi non videtur"
  ],
  "silver sword": [
    "Gladius Argenteus"
  ],
  "glow art for heavenly people": [
    "Glow art for heavenly people"
  ],
  "creaturedialoguechicken": [
    "CreatureDialogueChicken"
  ],
  "discover the treasure of angarvunde": [
    "Inveni Gazam Angarvundis"
  ],
  "woodelfskinfemale02": [
    "WoodElfSkinFemale02"
  ],
  "reachcliff secret entrance": [
    "Aditus Secretus Prehensirupis"
  ],
  "any weapon or piece of armor can be improved at a smithy, regardless of the material type or the smith's skill or perks": [
    "Omnes loricae armaque ad fabricam meliores fieri potest"
  ],
  "smithing perks increase how much the item is improved": [
    "Peritia ferrarii augent qualitatem eorum"
  ],
  "wheat mill": [
    "Mola Cereris"
  ],
  "bookcase": [
    "Pegma"
  ],
  "audiocategorypausedduringmenuload": [
    "AudioCategoryPausedDuringMenuLoad"
  ],
  "hairhornsmaledremora02": [
    "HairHornsMaleDremora02"
  ],
  "fortify unarmed": [
    "Adde ad Vim Inermem"
  ],
  "lingering damage health": [
    "Vulnus Salutis Persistens"
  ],
  "woodelfskinfemale01": [
    "WoodElfSkinFemale01"
  ],
  "hamelyn's journal": [
    "Ephemeris Hamelynis"
  ],
  "talk to the companions leaders for work": [
    "Adloquere duces Contubernalium pro labore"
  ],
  "gian the fist": [
    "Gian Pugna"
  ],
  "highelfskinfemale04": [
    "HighElfSkinFemale04"
  ],
  "highelfskinfemale01": [
    "HighElfSkinFemale01"
  ],
  "highelfskinfemale02": [
    "HighElfSkinFemale02"
  ],
  "highelfskinfemale03": [
    "HighElfSkinFemale03"
  ],
  "highelfskinfemale05": [
    "HighElfSkinFemale05"
  ],
  "woodelfskinfemale04": [
    "WoodElfSkinFemale04"
  ],
  "reduced magicka": [
    "Magica Reducta"
  ],
  "woodelfskinfemale05": [
    "WoodElfSkinFemale05"
  ],
  "voice of the sky": [
    "Vox Caeli"
  ],
  "just give me what you have and we both walk away from this": [
    "Da mihi id quod habes et nos ambos supererimus"
  ],
  "spriggan's resilience": [
    "Sprigganis Resilience"
  ],
  "hairhornsmaledremora01": [
    "HairHornsMaleDremora01"
  ],
  "woodelfskinfemale03": [
    "WoodElfSkinFemale03"
  ],
  "faction for not using casual idles": [
    "Faction for not using casual idles"
  ],
  "shutter": [
    "Foricula"
  ],
  "quartermaster": [
    "Quaestor"
  ],
  "you never mentioned that lunatic living in the tunnels": [
    "Numquam insanum in cuniculis commemoravisti"
  ],
  "silvered": [
    "Argenteied"
  ],
  "hamelyn": [
    "Hamelyn"
  ],
  "used for combat": [
    "used for combat"
  ],
  "the nirnoot missive": [
    "Liber Nirniradicis"
  ],
  "nirnoot missive": [
    "Liber Nirniradicis"
  ],
  "wounded spider damage": [
    "Wounded Spider Damage"
  ],
  "headless horseman": [
    "Eques Sine Capite"
  ],
  "who's rjorn and why do you want his drum": [
    "Quis est Rjorn et quamobrem vis Tympanum eius"
  ],
  "why would a necromancer want your flute": [
    "Cur voluit necromanticus tibiam tuam"
  ],
  "maluril's room key": [
    "Clavis ad Conclave Malurilis"
  ],
  "sinderion's serendipity": [
    "Serendipitas Sinderionis"
  ],
  "\"maw unleashing razor snow, of dragons from the blue brought down, births the walking winter's woe, the high king in his jagged crown.\" -- ancient nord verse": [
    "\"De cloaca ninguis tam acris quam novaculae, de draconibus ab caeruleo descensis, De nativitatibus - desperationibus Hiemis - Rex Altus in Corona Acri\" -- Carmen Antiquum Germanicum"
  ],
  "skyrim legend tells of a hero known as the dragonborn, a warrior with the body of a mortal and soul of a dragon, whose destiny it is to destroy the evil dragon alduin": [
    "Legenda Caelifinis de heroe \"Draconigena\" vocatur, bellator cui corpus mortale et animus draconis est, fatum cuius est draconem pravum Alduinum superare"
  ],
  "adept robes": [
    "Adeptum Peplum"
  ],
  "adept hood": [
    "Adeptum Capucium"
  ],
  "the common mudcrab can be found by the northern shoreline or along the banks of any lakes or rivers": [
    "Luticanceres intenti sunt ad oram septentrionem lacuum vel rivorum"
  ],
  "common mudcrab can be found by the northern shoreline or along the banks of any lakes or rivers": [
    "Luticanceres intenti sunt ad oram septentrionem lacuum vel rivorum"
  ],
  "when not moving, it is easy to mistake for a rock, and uses this natural camouflage to ambush unwary prey": [
    "Dum non movent, facile pro saxo erratu est"
  ],
  "although they later became the dedicated protectors of the emperor of tamriel, the blades began their existence as renowned dragonslayers": [
    "Etsi postea custodes Imperatorum Tamrielis facti sunt, Enses coeperunt ut grex draconinecatorum"
  ],
  "those venturing in skyrim's deepest underground reaches should be wary of the chaurus, a giant insect that spits poison and bites with its razor-sharp mandibles": [
    "Qui in ima Caelifinis eunt Chauros timere debent. Hae bestiolae venenum spuunt et mandibulis novacula-similibus mordent"
  ],
  "elven gilded armor of eminent illusion": [
    "Alfa Lorica Aurata Eminentis Inlusionis"
  ],
  "glass armor of eminent destruction": [
    "Vitrea Lorica Eminentis Destructionis"
  ],
  "dragonscale armor of peerless restoration": [
    "Draconisquamata Lorica Maximi Recreandi"
  ],
  "glass armorof eminent destruction": [
    "Vitrea LoricaEminentis Destructionis"
  ],
  "enchanted weapons": [
    "Enchanted Weapons"
  ],
  "the sabre cat of skyrim is a giant, ferocious predator, and will often ambush travelers who stray too far from the roads, and into the wilds": [
    "Felis Mucronis Caelifinis est venator ingens et ferox, et saepe insidias peregrinatoribus qui e vias eunt parat"
  ],
  "sabre cat of skyrim is a giant, ferocious predator, and will often ambush travelers who stray too far from the roads, and into the wilds": [
    "Felis Mucronis Caelifinis est venator ingens et ferox, et saepe insidias peregrinatoribus qui e vias eunt parat"
  ],
  "fast travel": [
    "Fast Travel"
  ],
  "hide armor of destruction": [
    "Renonis Lorica Destructionis"
  ],
  "bound arrow": [
    "Sagitta Vincta"
  ],
  "crime and jail": [
    "Crimina et Carcer"
  ],
  "novice robes of restoration": [
    "Novitii Peplum Recreandi"
  ],
  "elven armor of eminent alteration": [
    "Alfa Lorica Eminentis Mutationis"
  ],
  "dragonplate armor of eminent restoration": [
    "Draconilaminata Lorica Eminentis Reficiendi"
  ],
  "elven armor of restoration": [
    "Alfa Lorica Recreandi"
  ],
  "dragonplate armor of eminent illusion": [
    "Draconilaminata Lorica Eminentis Inlusionis"
  ],
  "iron armor of minor illusion": [
    "Ferrata Lorica Minoris Inlusionis"
  ],
  "daedric armor of extreme restoration": [
    "Daedrica Lorica Extremis Reficiendi"
  ],
  "banded iron armor of conjuring": [
    "Ferro Adligata Lorica Vocandi"
  ],
  "banded iron armor of major restoration": [
    "Ferro Adligata Lorica Maioris Reficiendi"
  ],
  "banded iron armor of alteration": [
    "Ferro Adligata Lorica Mutationis"
  ],
  "iron armor of illusion": [
    "Ferrata Lorica Inlusionis"
  ],
  "glass armor of major destruction": [
    "Vitrea Lorica Maioris Destructionis"
  ],
  "dwarven armor of restoration": [
    "Dvemeris Lorica Recreandi"
  ],
  "dwarven armor of major illusion": [
    "Dvemeris Lorica Maioris Inlusionis"
  ],
  "and the scrolls have foretold, of black wings in the cold, that when brothers wage war come unfurled": [
    "Et Codices praedixerunt, de alis atris in frigore, cum fratres bellum gererent, evolventibus"
  ],
  "alduin, bane of kings, ancient shadow unbound, with a hunger to swallow the world": [
    "Alduinus, Mors regum, umbra antiqua evinctus, cum esurie mundivoranti"
  ],
  "-- song of the dragonborn": [
    "-- Carmen Draconigenae"
  ],
  "dwarven armor of major restoration": [
    "Dvemeris Lorica Maioris Reficiendi"
  ],
  "elven armor of eminent restoration": [
    "Alfa Lorica Eminentis Reficiendi"
  ],
  "expert robes of destruction": [
    "Expers Peplum Destructionis"
  ],
  "glass armor of eminent restoration": [
    "Vitrea Lorica Eminentis Reficiendi"
  ],
  "expert robes of conjuration": [
    "Expers Peplum Coniurationis"
  ],
  "iron armor of minor restoration": [
    "Ferrata Lorica Minoris Reficiendi"
  ],
  "dragonplate armor of eminent conjuring": [
    "Draconilaminata Lorica Coniurationis Vocantis"
  ],
  "dragonscale armor of eminent conjuring": [
    "Draconisquamata Lorica Coniurationis Vocantis"
  ],
  "dwarven armor of eminent destruction": [
    "Dvemeris Lorica Eminentis Destructionis"
  ],
  "ebony armor of major illusion": [
    "Ebeni Lorica Maioris Inlusionis"
  ],
  "banded iron armor of major illusion": [
    "Ferro Adligata Lorica Maioris Inlusionis"
  ],
  "elven armor of destruction": [
    "Alfa Lorica Destructionis"
  ],
  "elven gilded armor of major destruction": [
    "Alfa Lorica Aurata Maioris Destructionis"
  ],
  "elven gilded armor of extreme destruction": [
    "Alfa Lorica Aurata Extremis Destructionis"
  ],
  "dragonplate armor of eminent alteration": [
    "Draconilaminata Lorica Eminentis Mutationis"
  ],
  "iron armor of major destruction": [
    "Ferrata Lorica Maioris Destructionis"
  ],
  "glass armor of major illusion": [
    "Vitrea Lorica Maioris Inlusionis"
  ],
  "hide armor of illusion": [
    "Renonis Lorica Inlusionis"
  ],
  "dwarven armor of major destruction": [
    "Dvemeris Lorica Maioris Destructionis"
  ],
  "banded iron armor of minor conjuring": [
    "Ferro Adligata Lorica Minoris Vocandi"
  ],
  "elven gilded armor of extreme restoration": [
    "Alfa Lorica Aurata Extremis Reficiendi"
  ],
  "the great statue of irkngthand is the only known visual representation of a snow elf, before centuries of subterranean slavery twisted the race into the vile falmer": [
    "Statua magna Irkngthandis est imago sola scita Alfi Nivis, ante saecula ut servi sub terra mutavit illam gentem in Falmeres horriferes"
  ],
  "great statue of irkngthand is the only known visual representation of a snow elf, before centuries of subterranean slavery twisted the race into the vile falmer": [
    "Statua magna Irkngthandis est imago sola scita Alfi Nivis, ante saecula ut servi sub terra mutavit illam gentem in Falmeres horriferes"
  ],
  "adept robes of illusion": [
    "Adeptum Peplum Inlusionis"
  ],
  "elven armor of eminent illusion": [
    "Alfa Lorica Eminentis Inlusionis"
  ],
  "expert robes": [
    "Expers Peplum"
  ],
  "expert robes of illusion": [
    "Expers Peplum Inlusionis"
  ],
  "elven gilded armor of eminent restoration": [
    "Alfa Lorica Aurata Eminentis Reficiendi"
  ],
  "hide armor of alteration": [
    "Renonis Lorica Mutationis"
  ],
  "elven gilded armor of eminent conjuring": [
    "Alfa Lorica Aurata Coniurationis Vocantis"
  ],
  "many of skyrim's waterways are home to slaughterfish, whose razor-sharp teeth have claimed the lives of many careless swimmers": [
    "Multae Viae Aquae in Caelifinie sunt domi caesipiscium, cuius dentes novaculi similes multas vitas furati sunt"
  ],
  "dragonplate armor of peerless alteration": [
    "Draconilaminata Lorica Optimae Mutationis"
  ],
  "the artifact volendrung, also known as the hammer of might, was forged by the ancient dwemer in an age long since forgotten": [
    "Volendrung, etiam ut Malleus Fortitudinis, factum est a dvemeribus antiquis in aetate multos annos oblita"
  ],
  "artifact volendrung, also known as the hammer of might, was forged by the ancient dwemer in an age long since forgotten": [
    "Volendrung, etiam ut Malleus Fortitudinis, factum est a dvemeribus antiquis in aetate multos annos oblita"
  ],
  "adept robes of alteration": [
    "Adeptum Peplum Mutationis"
  ],
  "dragonplate armor of peerless destruction": [
    "Draconilaminata Lorica Optimae Destructionis"
  ],
  "novice robes of destruction": [
    "Novitii Peplum Destructionis"
  ],
  "nord belief holds that the honored dead live forever in sovngarde": [
    "Germanus credunt mortuos honoratos in Valhalla semper vivere"
  ],
  "daedric armor of extreme conjuring": [
    "Daedrica Lorica Maiorum Vocantium"
  ],
  "elven gilded armor of extreme illusion": [
    "Alfa Lorica Aurata Inlusionis Extremae"
  ],
  "hide armor of major conjuring": [
    "Renonis Lorica Maioris Vocandi"
  ],
  "dragonscale armor of extreme conjuring": [
    "Draconisquamata Lorica Maiorum Vocantium"
  ],
  "elven gilded armor of major restoration": [
    "Alfa Lorica Aurata Maioris Reficiendi"
  ],
  "hide armor of minor alteration": [
    "Renonis Lorica Minoris Mutationis"
  ],
  "apprentice robes of conjuration": [
    "Discipuli Peplum Coniurationis"
  ],
  "what the dwarven sphere lacks in strength it makes up for in speed and mobility, as it patrols the halls of the dwemer ruins it still protects": [
    "Etsi Sphera Dvemeris non tam fortis est, celeriter movet dum aulas ruinarum Dvemerium iam teget"
  ],
  "investigate the boethiah cultist": [
    "Investiga Secutorem Boethis"
  ],
  "hooded thalmor robes": [
    "Capulatum Visigothum Peplum"
  ],
  "glass armor of major restoration": [
    "Vitrea Lorica Maioris Reficiendi"
  ],
  "dwarven armor of eminent conjuring": [
    "Dvemeris Lorica Coniurationis Vocantis"
  ],
  "daedric armor of extreme alteration": [
    "Daedrica Lorica Extremis Mutationis"
  ],
  "hide armor of restoration": [
    "Renonis Lorica Recreandi"
  ],
  "elven armor of alteration": [
    "Alfa Lorica Mutationis"
  ],
  "banded iron armor of major conjuring": [
    "Ferro Adligata Lorica Maioris Vocandi"
  ],
  "apprentice robes of illusion": [
    "Discipuli Peplum Inlusionis"
  ],
  "iron armor of alteration": [
    "Ferrata Lorica Mutationis"
  ],
  "ebony armor of eminent restoration": [
    "Ebeni Lorica Eminentis Reficiendi"
  ],
  "hide armor of minor restoration": [
    "Renonis Lorica Minoris Reficiendi"
  ],
  "elven gilded armor of eminent destruction": [
    "Alfa Lorica Aurata Eminentis Destructionis"
  ],
  "hooded brown robes": [
    "Capulatum Bruneum Peplum"
  ],
  "banded iron armor of major alteration": [
    "Ferro Adligata Lorica Maioris Mutationis"
  ],
  "ebony armor of eminent alteration": [
    "Ebeni Lorica Eminentis Mutationis"
  ],
  "daedric armor of eminent illusion": [
    "Daedrica Lorica Eminentis Inlusionis"
  ],
  "novice hood": [
    "Novitii Capucium"
  ],
  "the wolves of skyrim are feral, savage beasts, and hunt in packs to take down any prey available... be it animal or man": [
    "Lupi Caelifinis feri sunt et in gregibus venantur ut quidquid raptum capiant... vel animal vel hominem"
  ],
  "wolves of skyrim are feral, savage beasts, and hunt in packs to take down any prey available... be it animal or man": [
    "Lupi Caelifinis feri sunt et in gregibus venantur ut quidquid raptum capiant... vel animal vel hominem"
  ],
  "elven gilded armor of major illusion": [
    "Alfa Lorica Aurata Maioris Inlusionis"
  ],
  "iron armor of major restoration": [
    "Ferrata Lorica Maioris Reficiendi"
  ],
  "dragonplate armor of extreme illusion": [
    "Draconilaminata Lorica Inlusionis Extremae"
  ],
  "elven gilded armor of extreme conjuring": [
    "Alfa Lorica Aurata Maiorum Vocantium"
  ],
  "dwarven armor of major conjuring": [
    "Dvemeris Lorica Maioris Vocandi"
  ],
  "glass armor of eminent alteration": [
    "Vitrea Lorica Eminentis Mutationis"
  ],
  "glass armor of eminent illusion": [
    "Vitrea Lorica Eminentis Inlusionis"
  ],
  "dragonscale armor of peerless alteration": [
    "Draconisquamata Lorica Optimae Mutationis"
  ],
  "dragonplate armor of extreme alteration": [
    "Draconilaminata Lorica Extremis Mutationis"
  ],
  "dragonscale armor of extreme restoration": [
    "Draconisquamata Lorica Extremis Reficiendi"
  ],
  "hide armor of conjuring": [
    "Renonis Lorica Vocandi"
  ],
  "urag gro-shub services": [
    "Urag gro-Shub Services"
  ],
  "elven armor of illusion": [
    "Alfa Lorica Inlusionis"
  ],
  "ebony armor of extreme illusion": [
    "Ebeni Lorica Inlusionis Extremae"
  ],
  "expert robes of alteration": [
    "Expers Peplum Mutationis"
  ],
  "banded iron armor of illusion": [
    "Ferro Adligata Lorica Inlusionis"
  ],
  "shield charge cloak effect": [
    "Shield Charge Cloak Effect"
  ],
  "dwarven armor of eminent restoration": [
    "Dvemeris Lorica Eminentis Reficiendi"
  ],
  "iron armor of minor destruction": [
    "Ferrata Lorica Minoris Destructionis"
  ],
  "iron armor of restoration": [
    "Ferrata Lorica Recreandi"
  ],
  "loved": [
    "Amatus"
  ],
  "ebony armor of extreme alteration": [
    "Ebeni Lorica Extremis Mutationis"
  ],
  "master robes of restoration": [
    "Magistri Peplum Recreandi"
  ],
  "iron armor of major illusion": [
    "Ferrata Lorica Maioris Inlusionis"
  ],
  "adept robes of conjuration": [
    "Adeptum Peplum Coniurationis"
  ],
  "nocturnal is the daedric mistress of stealth and shadow, and a patron to thieves": [
    "Nocturnal est princeps daedrica Furti Obscuritatisque, et patrona omnibus furibus"
  ],
  "dragonscale armor of peerless destruction": [
    "Draconisquamata Lorica Optimae Destructionis"
  ],
  "dwarven armor of eminent alteration": [
    "Dvemeris Lorica Eminentis Mutationis"
  ],
  "elven armor of major restoration": [
    "Alfa Lorica Maioris Reficiendi"
  ],
  "hide armor of minor destruction": [
    "Renonis Lorica Minoris Destructionis"
  ],
  "elven armor of conjuring": [
    "Alfa Lorica Vocandi"
  ],
  "ebony armor of extreme conjuring": [
    "Ebeni Lorica Maiorum Vocantium"
  ],
  "the horses of skyrim are hardy and strong, and make up for in endurance what they lack in speed": [
    "Equi Caelifinis fortes et duri sunt, et patientiam in loco velocitatis habent"
  ],
  "horses of skyrim are hardy and strong, and make up for in endurance what they lack in speed": [
    "Equi Caelifinis fortes et duri sunt, et patientiam in loco velocitatis habent"
  ],
  "iron armor of conjuring": [
    "Ferrata Lorica Vocandi"
  ],
  "dwarven armor of eminent illusion": [
    "Dvemeris Lorica Eminentis Inlusionis"
  ],
  "apprentice robes": [
    "Discipuli Peplum"
  ],
  "combat difficulty can be modified at any time - even in the middle of battle - in the gameplay section of the settings screen": [
    "Difficultas Proelii semper mutari potest - etiam dum pugnas - in parte Ludo in Charta Settings"
  ],
  "hirelings": [
    "Hirelings"
  ],
  "ebony armor of eminent illusion": [
    "Ebeni Lorica Eminentis Inlusionis"
  ],
  "jail": [
    "Jail"
  ],
  "banded iron armor of destruction": [
    "Ferro Adligata Lorica Destructionis"
  ],
  "briarheart lifeforce": [
    "Vitalitas Cordumi"
  ],
  "it is said that rumors of the dark brotherhood's demise may have been premature, as members of the shadowy organization have been sighted near dawnstar": [
    "Dicitur rumores finis Fraternitatis Obscurae fortasse in errore fuisse, quod sicarii e grege secreto prope Luciferem visi sunt"
  ],
  "health low": [
    "Salus parva"
  ],
  "adept robes of restoration": [
    "Adeptum Peplum Recreandi"
  ],
  "stamina low": [
    "Stamina Low"
  ],
  "apprentice hood": [
    "Discipuli Capucium"
  ],
  "crime gold added": [
    "Aurum Criminis Additum"
  ],
  "houses": [
    "Houses"
  ],
  "banded iron armor of minor alteration": [
    "Ferro Adligata Lorica Minoris Mutationis"
  ],
  "cooking": [
    "Coquere"
  ],
  "iron armor of major alteration": [
    "Ferrata Lorica Maioris Mutationis"
  ],
  "hide armor of major destruction": [
    "Renonis Lorica Maioris Destructionis"
  ],
  "novice robes of illusion": [
    "Novitii Peplum Inlusionis"
  ],
  "werewolf knockback effect": [
    "Werewolf Knockback Effect"
  ],
  "novice robes of conjuration": [
    "Novitii Peplum Coniurationis"
  ],
  "glass armor of extreme destruction": [
    "Vitrea Lorica Extremis Destructionis"
  ],
  "hide armor of major illusion": [
    "Renonis Lorica Maioris Inlusionis"
  ],
  "dragonscale armor of eminent destruction": [
    "Draconisquamata Lorica Eminentis Destructionis"
  ],
  "many species of bear roam skyrim's wilds": [
    "Multa genera ursorum Caelifinem peregrinantur"
  ],
  "they are fearsome creatures, but will often avoid attacking unless provoked": [
    "Animalia fortissima sunt, sed saepe homines evitant, nisi provocati"
  ],
  "elven armor of major conjuring": [
    "Alfa Lorica Maioris Vocandi"
  ],
  "the frost troll is the most fearsome of its kind found in skyrim, and is at home in the province's most frozen regions": [
    "Trellis Glaciei est potentissimus trellium in Caelifine inventium, et domum habet in regionibus frigidissimis provinciae"
  ],
  "frost troll is the most fearsome of its kind found in skyrim, and is at home in the province's most frozen regions": [
    "Trellis Glaciei est potentissimus trellium in Caelifine inventium, et domum habet in regionibus frigidissimis provinciae"
  ],
  "now where's esbern": [
    "Nunc, ubi est Esbernus"
  ],
  "glass armor of eminent conjuring": [
    "Vitrea Lorica Coniurationis Vocantis"
  ],
  "elven armor of eminent conjuring": [
    "Alfa Lorica Coniurationis Vocantis"
  ],
  "while all dragons are fearsome, some are more powerful than others": [
    "Etsi omnes dracones potentes sunt, nonullis potentiores sunt quam alii"
  ],
  "brown and green-skinned dragons are generally weaker than their white or bronze-skinned cousins": [
    "Illi cum pelle brunea vel viridi debiliores sunt quam consobrini albi et aheni"
  ],
  "khajiit traders": [
    "Khajiit Traders"
  ],
  "skill increases": [
    "Artes Augens"
  ],
  "ritualhealbodyartholder": [
    "RitualHealBodyArtHolder"
  ],
  "the falmer are twisted, evil creatures that dwell in skyrim's deepest reaches": [
    "Falmeres sunt psuedo-homines torquati et pravi qui in imis Caelifinis vivunt"
  ],
  "falmer are twisted, evil creatures that dwell in skyrim's deepest reaches": [
    "Falmeres sunt psuedo-homines torquati et pravi qui in imis Caelifinis vivunt"
  ],
  "they have but one desire - to destroy the surface world, and any who dwell above": [
    "Unum solum desiderium habent - delere mundum superiorem et omnes qui illic vivunt"
  ],
  "ebony armor of major destruction": [
    "Ebeni Lorica Maioris Destructionis"
  ],
  "few creatures match the cunning, depravity and repulsiveness of the hagravens": [
    "Pauci sunt tam callidi, corrupti, et informes ut Corvicisagae"
  ],
  "a terrible conjoining of woman and bird, they will take through savagery what they cannot win through guile": [
    "Nexus horribilis feminae avisque, id quod per calliditate accipere non posunt per saevitudinem accipient"
  ],
  "terrible conjoining of woman and bird, they will take through savagery what they cannot win through guile": [
    "Nexus horribilis feminae avisque, id quod per calliditate accipere non posunt per saevitudinem accipient"
  ],
  "red robes": [
    "Peplum Rubrum"
  ],
  "woe to the unwary explorer who delves deep into the burial crypts of the ancient nords, and disturbs the draugr that dwell within": [
    "Vae exploratoribus qui in hypogea Germanorum Antiquorum intrant, et Draugr in eis molestant"
  ],
  "mammoths are a common sight in the tundra of skyrim, and are often herded by the giants that also call that region home": [
    "Elphantes communes sunt in tundra Caelifinis, et saepe Gigantes pastores eorum sunt"
  ],
  "they are generally peaceful creatures, unless provoked": [
    "Praesertim molles sunt, nisi provocati"
  ],
  "dragonscale armor of peerless illusion": [
    "Draconisquamata Lorica Maximi Inlusionis"
  ],
  "skeevers are large, rat-like creatures that make their nests in caves, sewers, and underground warrens": [
    "Skeever sunt magna animilia - mures similes - qui nidos in speluncis, cloacis, et cubilibus subterraneis"
  ],
  "while easy to kill, they can be a challenge in groups, and carry debilitating diseases": [
    "Etsi facile necatu, certemen in gregibus praesentare possunt et morbos debilitantes portant"
  ],
  "most of the dogs in skyrim are domesticated, and serve as faithful companions to their nord masters": [
    "Complures Canes in Caelifine mansueti sunt, et comites fideles dominis Germanis sunt"
  ],
  "in days of old, when dragons ruled the earth, there lived mortal men who worshipped the beasts as gods": [
    "Dies antiquissimos, cum dracones mundum regerent, homines mortales qui bestiis ut deos preces adhibuerunt vixerunt"
  ],
  "these dragon priests are long since dead... but something has awoken them from their ancient slumber": [
    "Hi Sacerdotes Draconum abhinc multos annos mortui sunt... sed aliquid eos e somnio antiquo excitavit"
  ],
  "horses and horse-drawn carriages are common means of transportation in skyrim": [
    "Equi et raedae modi communes Caelifinem transeundi"
  ],
  "both can handle the harsh terrain with ease": [
    "Ambo terram duram facile transire possunt"
  ],
  "the frostbite spider is one of skyrim's most fearsome and loathed native creatures": [
    "Araneae Adustus Nivibus sunt debiles et stulit"
  ],
  "frostbite spider is one of skyrim's most fearsome and loathed native creatures": [
    "Araneae Adustus Nivibus sunt debiles et stulit"
  ],
  "it will attack anyone relentlessly with both poison and fangs, and is as deadly as the affliction for which it was named": [
    "Ludus falsa de eis tibi dixit"
  ],
  "trolls can be found in any region throughout skyrim, both deep underground or wandering the wilds": [
    "Trelles inveniuntur in omnibus regionibus Caelifinis, et ima sub terra et in agris"
  ],
  "most of the shipping in solitude is run by the imperial-financed east empire company": [
    "Pluralitas commercii Solitudinis venit e Negotio Euri Imperii"
  ],
  "whiterun was built up around the mead hall jorrvaskr, home of the legendary companions warrior guild": [
    "Albacursus factus est circum Aulam Hydromelli Jorrvaskr, domus notissimum Contubernalium"
  ],
  "elven gilded armor of eminent alteration": [
    "Alfa Lorica Aurata Eminentis Mutationis"
  ],
  "at the center of the dunmer province of morrowind lies the red mountain, the largest volcano in all of tamriel": [
    "In centro provinciae Dunmeris Morroventus iacet Mons Ruber, Volcanus maximus in omni Tamriele"
  ],
  "no one is quite sure why the draugr walk the halls of skyrim's burial crypts, but some believe they once served the dragons ages ago... and have been cursed with undeath for their treachery": [
    "Nemo scit cur Draugr in aulis Hypogeorum ambulent, sed nonnulli credunt illos dracones servirent abhinc multas aetates... et maledictos esse perfidiae"
  ],
  "the greybeards of high hrothgar follow the teachings of jurgen windcaller, who believed the power of the voice was a means of attaining enlightenment": [
    "Canabarbae Altae Hrothgaris praecepta Jurgenis Ventivocatoris, qui credidit potestatem Viae Vocis ad inluminationem ducere, sequuntur"
  ],
  "greybeards of high hrothgar follow the teachings of jurgen windcaller, who believed the power of the voice was a means of attaining enlightenment": [
    "Canabarbae Altae Hrothgaris praecepta Jurgenis Ventivocatoris, qui credidit potestatem Viae Vocis ad inluminationem ducere, sequuntur"
  ],
  "testjeremyregular": [
    "TestJeremyRegular"
  ],
  "legend holds that the highest ranking dragon priests were granted magical masks - strange artifacts that defy the laws of time, and grant their wearers powerful enchantments": [
    "Legenda dicunt Sacerdotes Draconum potentissimi personas magicas habuisse - Opera Mirabilia quae leges temporis vetant, et indutoribus incantamenta potentia dant"
  ],
  "skyrim is engaged in a bloody civil conflict between the rebel stormcloaks and soldiers of the imperial legion": [
    "Caelifinis in bello civili inter defectores Sturmmantelos et melis Leginos Imperialis"
  ],
  "dragonscale armor of extreme illusion": [
    "Draconisquamata Lorica Inlusionis Extremae"
  ],
  "skyrim is in turmoil because the stormcloaks believe their nord way of life is at risk, while the imperials believe skyrim is a part of the empire, and must follow its laws and customs": [
    "Caelifinis in bello civili est quod Sturmmanteli credunt modum vitae eius in periculo esse, dum Romani credunt Caelifinem partem Imperii esse, et mores legesque eius sequi debere"
  ],
  "the high king of skyrim, torygg, was recently killed by ulfric stormcloak, who used the ancient power of the thu'um to nearly shout torygg to pieces": [
    "Rex Altus Caelifinis, Torygg, recente necatus est a Vercingetorigi Temepstatamiculo, qui viribus potentibus Thu'um usus est fere Torygg delaniare"
  ],
  "high king of skyrim, torygg, was recently killed by ulfric stormcloak, who used the ancient power of the thu'um to nearly shout torygg to pieces": [
    "Rex Altus Caelifinis, Torygg, recente necatus est a Vercingetorigi Temepstatamiculo, qui viribus potentibus Thu'um usus est fere Torygg delaniare"
  ],
  "ebony armor of eminent destruction": [
    "Ebeni Lorica Eminentis Destructionis"
  ],
  "master robes": [
    "Magistri Peplum"
  ],
  "many of skyrim's nords harbor a bitter resentment of all elvenkind, who they've battled with throughout history": [
    "Multi Germani Caelifinis omnes Alfos, contra quos per totam historiam pugnaverunt, in odio habent"
  ],
  "the great war may be over, but its horrors are still fresh in the minds of the conflict's survivors": [
    "Bellum Magnum fortasse terminavit, sed horrores eius iam virides sunt in animis superstitum"
  ],
  "great war may be over, but its horrors are still fresh in the minds of the conflict's survivors": [
    "Bellum Magnum fortasse terminavit, sed horrores eius iam virides sunt in animis superstitum"
  ],
  "only by signing the peace treaty known as the white-gold concordat was the empire able to survive the onslaught of the high elven aldmeri dominion, and thus end the great war": [
    "Imperium modo bellum contra Dominationem Aldmeri superesse potuit per foedus nomine \"Foedus Album-Aurum\", et illud finem ad Bellum Magnum fecit"
  ],
  "agents of the high elven aldmeri dominion are known as thalmor": [
    "Legati Dominationis Aldmeri quoque nomen \"Visigothos\" habent"
  ],
  "they maintain an embassy in skyrim, and have been known to secretly capture and imprison any nords who question their doctrines or beliefs": [
    "Legationem in Caelifine, et nonnumquam Germanos qui doctrinas vel opiniones eorum non sequuntur in carcerem ponunt"
  ],
  "in some of skyrim's darkest recesses, where the living fear to tread, the bones of the dead still walk": [
    "In ima obscurissima Caelifinis, ubi vivi ire timent, ossa mortuorum iam ambulant"
  ],
  "these skeletons feel no pain or remorse... and deserve no mercy": [
    "Haec Ossa Ambulantia nullam dolorem nec compunctiones sentiunt... nec veniam merent"
  ],
  "novice robes": [
    "Novitii Peplum"
  ],
  "master robes of destruction": [
    "Magistri Peplum Destructionis"
  ],
  "become friends with someone, and they may allow you to take certain items from their home": [
    "Potes nonullas res e domo amicorum capere"
  ],
  "years of living underground have rendered the falmer blind, but their other senses have become more acute as a result": [
    "Anni sub terra Falmeres caecos fecerunt, sed alii sensus aucti sunt"
  ],
  "elven armor of major alteration": [
    "Alfa Lorica Maioris Mutationis"
  ],
  "so long as there is a listener to hear the night mother's voice, the dark brotherhood will endure": [
    "Dum Auditor est qui vocem Matris Noctis audire potst, Fraternitas Obscura Manebit"
  ],
  "talk to orthus endario at the east empire company in windhelm": [
    "Orthum Endarionem ad Negotium Euri Imperii in Ventigalea Adloquere"
  ],
  "what do you think about ulfric, now that he has won": [
    "Quid censes de Vercingetorige ut victore"
  ],
  "so, do you still claim to be the high queen of skyrim": [
    "Ergo, iam censes te Regem Altum Caelifinis esse"
  ],
  "you move more slowly with your weapon drawn": [
    "Lentius moves armo scricto"
  ],
  "shield charge knockback": [
    "Shield Charge Knockback"
  ],
  "dwarven armor of alteration": [
    "Dvemeris Lorica Mutationis"
  ],
  "glass armor of extreme illusion": [
    "Vitrea Lorica Inlusionis Extremae"
  ],
  "silver greatsword": [
    "Spatha Argentea"
  ],
  "daedric armor of eminent alteration": [
    "Daedrica Lorica Eminentis Mutationis"
  ],
  "stray dog": [
    "Canis Sine Domino"
  ],
  "gourd": [
    "Cucurbitum"
  ],
  "elven gilded armor of major conjuring": [
    "Alfa Lorica Aurata Maioris Vocandi"
  ],
  "testjeremysmall": [
    "TestJeremySmall"
  ],
  "portal to entrance": [
    "Porta Ingressui"
  ],
  "hooded green robes": [
    "Capulatum Viridum Peplum"
  ],
  "hide armor of major restoration": [
    "Renonis Lorica Maioris Reficiendi"
  ],
  "elven armor of eminent destruction": [
    "Alfa Lorica Eminentis Destructionis"
  ],
  "ebony armor of major conjuring": [
    "Ebeni Lorica Maioris Vocandi"
  ],
  "banded iron armor of minor illusion": [
    "Ferro Adligata Lorica Minoris Inlusionis"
  ],
  "any inventory item marked with a small arrow indicates it is probably more effective than what you currently have equipped": [
    "Res in inventario quae sagittunculam habent effecaciores sunt quam eas quibus nunc uteris"
  ],
  "dragonscale armor of eminent restoration": [
    "Draconisquamata Lorica Eminentis Reficiendi"
  ],
  "ebony armor of extreme destruction": [
    "Ebeni Lorica Extremis Destructionis"
  ],
  "dragonplate armor of extreme restoration": [
    "Draconilaminata Lorica Extremis Reficiendi"
  ],
  "dwarven armor of destruction": [
    "Dvemeris Lorica Destructionis"
  ],
  "daedric armor of peerless alteration": [
    "Daedrica Lorica Optimae Mutationis"
  ],
  "iron armor of minor conjuring": [
    "Ferrata Lorica Minoris Vocandi"
  ],
  "daedric armor of eminent destruction": [
    "Daedrica Lorica Eminentis Destructionis"
  ],
  "daedric armor of extreme destruction": [
    "Daedrica Lorica Extremis Destructionis"
  ],
  "daedric armor of peerless destruction": [
    "Daedrica Lorica Optimae Destructionis"
  ],
  "daedric armor of peerless illusion": [
    "Daedrica Lorica Maximi Inlusionis"
  ],
  "daedric armor of eminent restoration": [
    "Daedrica Lorica Eminentis Reficiendi"
  ],
  "daedric armor of peerless restoration": [
    "Daedrica Lorica Maximi Recreandi"
  ],
  "dragonplate armor of extreme conjuring": [
    "Draconilaminata Lorica Maiorum Vocantium"
  ],
  "dragonplate armor of eminent destruction": [
    "Draconilaminata Lorica Eminentis Destructionis"
  ],
  "dragonplate armor of peerless illusion": [
    "Draconilaminata Lorica Maximi Inlusionis"
  ],
  "dragonplate armor of peerless restoration": [
    "Draconilaminata Lorica Maximi Recreandi"
  ],
  "dragonplate armor of extreme destruction": [
    "Draconilaminata Lorica Extremis Destructionis"
  ],
  "dragonscale armor of eminent alteration": [
    "Draconisquamata Lorica Eminentis Mutationis"
  ],
  "iron armor of major conjuring": [
    "Ferrata Lorica Maioris Vocandi"
  ],
  "dragonscale armor of extreme alteration": [
    "Draconisquamata Lorica Extremis Mutationis"
  ],
  "ebony armor of major restoration": [
    "Ebeni Lorica Maioris Reficiendi"
  ],
  "dragonscale armor of peerless conjuring": [
    "Draconisquamata Lorica Optimae Vocandi"
  ],
  "dragonscale armor of extreme destruction": [
    "Draconisquamata Lorica Extremis Destructionis"
  ],
  "ebony armor of major alteration": [
    "Ebeni Lorica Maioris Mutationis"
  ],
  "the ancient and long-dead dwemer built many devices and automatons that continue to function even today, thanks to their advanced magic and technology": [
    "Antiqui et longe mortui Dvemeres multa mechanisma et Automata fecerunt quae etiam hodie funguntur, magicae techonologicique gratia"
  ],
  "ancient and long-dead dwemer built many devices and automatons that continue to function even today, thanks to their advanced magic and technology": [
    "Antiqui et longe mortui Dvemeres multa mechanisma et Automata fecerunt quae etiam hodie funguntur, magicae techonologicique gratia"
  ],
  "the dwarven spider is one such construct": [
    "Aranea Dvemeris est una eorum"
  ],
  "dwarven spider is one such construct": [
    "Aranea Dvemeris est una eorum"
  ],
  "skyrim's horkers are often hunted for their tusks and meat": [
    "Horker Caelifinis semper pro dentibus et carne necati sunt"
  ],
  "of all the automatons left behind by the ancient dwemer, the dwarven centurions are among the most technologically impressive... and deadly": [
    "Centuriones Dvemeres sunt technologicissimi et letiferi omnium automatorum a dvemeribus factorum"
  ],
  "they guard dwemer ruins as if their masters still walked the halls": [
    "Ruinas Dwemeres custoudiunt sicut domini aulas iam ambularent"
  ],
  "dwarven armor of conjuring": [
    "Dvemeris Lorica Vocandi"
  ],
  "master robes of illusion": [
    "Magistri Peplum Inlusionis"
  ],
  "banded iron armor of minor restoration": [
    "Ferro Adligata Lorica Minoris Reficiendi"
  ],
  "banded iron armor of restoration": [
    "Ferro Adligata Lorica Recreandi"
  ],
  "banded iron armor of minor destruction": [
    "Ferro Adligata Lorica Minoris Destructionis"
  ],
  "iron armor of destruction": [
    "Ferrata Lorica Destructionis"
  ],
  "magicka low": [
    "Magicae Low"
  ],
  "dragonscale armor of eminent illusion": [
    "Draconisquamata Lorica Eminentis Inlusionis"
  ],
  "elven gilded armor of major alteration": [
    "Alfa Lorica Aurata Maioris Mutationis"
  ],
  "the blades have long since disbanded, and the organization's few remaining members have gone into hiding": [
    "Enses abhinc multos annos dimissi sunt, et pauci qui manent nunc latent"
  ],
  "blades have long since disbanded, and the organization's few remaining members have gone into hiding": [
    "Enses abhinc multos annos dimissi sunt, et pauci qui manent nunc latent"
  ],
  "the emperor is now protected by a special security force - the penitus oculatus": [
    "Imperator nunc tegetur a grege stipatorum specialium - Penito Oculato"
  ],
  "emperor is now protected by a special security force - the penitus oculatus": [
    "Imperator nunc tegetur a grege stipatorum specialium - Penito Oculato"
  ],
  "testjeremybig": [
    "TestJeremyBig"
  ],
  "green robes": [
    "Viridum Peplum"
  ],
  "little is known of the mysterious spriggans, save that they revere skyrim's forested regions, and will defend these regions with their lives": [
    "Paulum de Sprigganibus scitur, praeter reverentiam eius pro regionibus nemorosis Caelifinis. Has regiones defendent vitis suis"
  ],
  "elven armor of major destruction": [
    "Alfa Lorica Maioris Destructionis"
  ],
  "dragonplate armor of peerless conjuring": [
    "Draconilaminata Lorica Optimae Vocandi"
  ],
  "daedric armor of eminent conjuring": [
    "Daedrica Lorica Coniurationis Vocantis"
  ],
  "hide armor of minor illusion": [
    "Renonis Lorica Minoris Inlusionis"
  ],
  "dual wielding and dual casting": [
    "Dual Wielding et Dual Casting"
  ],
  "hide armor of minor conjuring": [
    "Renonis Lorica Minoris Vocandi"
  ],
  "elven armor of major illusion": [
    "Alfa Lorica Maioris Inlusionis"
  ],
  "alva's cellar": [
    "Cellarium Alvae"
  ],
  "master robes of alteration": [
    "Magistri Peplum Mutationis"
  ],
  "glass armor of major conjuring": [
    "Vitrea Lorica Maioris Vocandi"
  ],
  "apprentice robes of destruction": [
    "Discipuli Peplum Destructionis"
  ],
  "forge entry": [
    "Falsa Scribe"
  ],
  "expert robes of restoration": [
    "Expers Peplum Recreandi"
  ],
  "hide armor of major alteration": [
    "Renonis Lorica Maioris Mutationis"
  ],
  "apprentice robes of restoration": [
    "Discipuli Peplum Recreandi"
  ],
  "glass armor of major alteration": [
    "Vitrea Lorica Maioris Mutationis"
  ],
  "when a dragon uses a breath attack like fire or frost, it is speaking in an ancient and powerful language": [
    "Cum dracones ictibus flatus utantur, re vera linguam antiquissimam potentissimamque loquitur"
  ],
  "a battle between two dragons is actually a deadly verbal debate": [
    "Pugna inter duos dracones est controversia letifera"
  ],
  "battle between two dragons is actually a deadly verbal debate": [
    "Pugna inter duos dracones est controversia letifera"
  ],
  "adept robes of destruction": [
    "Adeptum Peplum Destructionis"
  ],
  "master robes of conjuration": [
    "Magistri Peplum Coniurationis"
  ],
  "apprentice robes of alteration": [
    "Discipuli Peplum Mutationis"
  ],
  "banded iron armor of major destruction": [
    "Ferro Adligata Lorica Maioris Destructionis"
  ],
  "once, the dragons sought to eliminate or enslave all mortal races": [
    "Olim, dracones necare vel in servitutem imponere omnes mortales"
  ],
  "if given the chance, they would surely do so again": [
    "Si possint, certe id iterum faciant"
  ],
  "brown robes": [
    "Bruneum Peplum"
  ],
  "hooded red robes": [
    "Capulatum Peplum Rubrum"
  ],
  "hooded grey robes": [
    "Capulatum Canum Peplum"
  ],
  "elven gilded armor of extreme alteration": [
    "Alfa Lorica Aurata Extremis Mutationis"
  ],
  "novice robes of alteration": [
    "Novitii Peplum Mutationis"
  ],
  "ebony armor of extreme restoration": [
    "Ebeni Lorica Extremis Reficiendi"
  ],
  "iron armor of minor alteration": [
    "Ferrata Lorica Minoris Mutationis"
  ],
  "daedric armor of extreme illusion": [
    "Daedrica Lorica Inlusionis Extremae"
  ],
  "glass armor of extreme restoration": [
    "Vitrea Lorica Extremis Reficiendi"
  ],
  "daedric armor of peerless conjuring": [
    "Daedrica Lorica Optimae Vocandi"
  ],
  "glass armor of extreme alteration": [
    "Vitrea Lorica Extremis Mutationis"
  ],
  "grey robes": [
    "Canum Peplum"
  ],
  "glass armor of extreme conjuring": [
    "Vitrea Lorica Maiorum Vocantium"
  ],
  "ebony armor of eminent conjuring": [
    "Ebeni Lorica Coniurationis Vocantis"
  ],
  "dwarven armor of illusion": [
    "Dvemeris Lorica Inlusionis"
  ],
  "retrieve your posessions": [
    "Recipe Res Tuas"
  ],
  "all skill books": [
    "Quid sibi vult"
  ],
  "necklace of extreme wielding": [
    "Monile Extremis Tenendi"
  ],
  "necklace of extreme smithing": [
    "Monile Extremis Ferrarii"
  ],
  "necklace of major haggling": [
    "Monile Maioris Arguendi"
  ],
  "necklace of sneaking": [
    "Monile Repsendi"
  ],
  "orcish armor of major destruction": [
    "Orci Lorica Maioris Destructionis"
  ],
  "necklace of blocking": [
    "Monile Interponendi"
  ],
  "dragonplate shield of nullification": [
    "Draconilaminatum Scutum Nullificationis"
  ],
  "dragonscale gauntlets of extreme lockpicking": [
    "Draconisquamatae Manicae Extremis Clavicarii"
  ],
  "necklace of minor blocking": [
    "Monile Minoris Interponendi"
  ],
  "steel plate armor of illusion": [
    "Laminata Lorica Inlusionis"
  ],
  "necklace of major sure grip": [
    "Monile Maioris Dexteritatis"
  ],
  "necklace of major lockpicking": [
    "Monile Maioris Clavicarii"
  ],
  "necklace of the eminent squire": [
    "Monile Eminentis Armiferis"
  ],
  "leather armor of illusion": [
    "Corii Lorica Inlusionis"
  ],
  "scaled armor of restoration": [
    "Lorica Squamata Recreandi"
  ],
  "orcish armor of extreme restoration": [
    "Orci Lorica Extremis Reficiendi"
  ],
  "steel plate boots of eminent stamina": [
    "Laminatae Caligae Maximae Staminae"
  ],
  "hide boots of major stamina": [
    "Renonis Caligae Maioris Staminae"
  ],
  "ring of dwindling magic": [
    "Anulus Magicam Diminuendi"
  ],
  "glass boots of eminent sneaking": [
    "Vitreae Caligae Maximi Repsendi"
  ],
  "necklace of peerless wielding": [
    "Monile Optimae Tenendi"
  ],
  "necklace of regeneration": [
    "Monile Recuperandi"
  ],
  "steel boots of stamina": [
    "Caligae Adamanteae Staminae"
  ],
  "dwarven boots of extreme stamina": [
    "Dvemeres Caligae Magnae Staminae"
  ],
  "steel armor of minor conjuring": [
    "Adamantea Lorica Minoris Vocandi"
  ],
  "necklace of eminent blocking": [
    "Monile Eminentis Interponendi"
  ],
  "iron shield of waning magic": [
    "Ferri Scutum Insenescentis Magicae"
  ],
  "necklace of alchemy": [
    "Monile Alchemiae"
  ],
  "orcish armor of major restoration": [
    "Orci Lorica Maioris Reficiendi"
  ],
  "iron boots of minor stamina": [
    "Ferri Caligae Minoris Staminae"
  ],
  "necklace of rejuvenation": [
    "Monile Rejuvenationis"
  ],
  "scaled boots of stamina": [
    "Caligae Squamatae Staminae"
  ],
  "ring of magic suppression": [
    "Anulus Suppressionis Magicae"
  ],
  "steel plate armor of major restoration": [
    "Laminata Lorica Maioris Reficiendi"
  ],
  "orcish armor of eminent restoration": [
    "Orci Lorica Eminentis Reficiendi"
  ],
  "ebony boots of extreme stamina": [
    "Caligae Ebeni Magnae Staminae"
  ],
  "daedric shield of magic suppression": [
    "Scutum Daedricum Suppressionis Magicae"
  ],
  "necklace of peerless sneaking": [
    "Monile Optimi Repsendi"
  ],
  "necklace of the knight": [
    "Monile Equitis"
  ],
  "necklace of the peerless squire": [
    "Monile Optimae Armiferis"
  ],
  "scaled armor of major destruction": [
    "Lorica Squamata Maioris Destructionis"
  ],
  "iron shield of dwindling magic": [
    "Ferri Scutum Magicam Diminuendi"
  ],
  "iron boots of stamina": [
    "Ferri Caligae Staminae"
  ],
  "necklace of eminent wielding": [
    "Monile Eminentis Tenendi"
  ],
  "steel plate armor of eminent illusion": [
    "Laminata Lorica Eminentis Inlusionis"
  ],
  "dwarven boots of major stamina": [
    "Dvemeres Caligae Maioris Staminae"
  ],
  "glass shield of magic suppression": [
    "Vitreum Scutum Suppressionis Magicae"
  ],
  "dwarven shield of waning magic": [
    "Dvemere Scutum Insenescentis Magicae"
  ],
  "lingering damage stamina": [
    "Vulnus Staminae Persistens"
  ],
  "necklace of lockpicking": [
    "Monile Clavicarii"
  ],
  "steel armor of major destruction": [
    "Adamantea Lorica Maioris Destructionis"
  ],
  "leather armor of minor illusion": [
    "Corii Lorica Minoris Inlusionis"
  ],
  "scaled armor of eminent illusion": [
    "Lorica Squamata Eminentis Inlusionis"
  ],
  "necklace of sure grip": [
    "Monile Dexteritatis"
  ],
  "ebony shield of dwindling magic": [
    "Ebeni Scutum Magicam Diminuendi"
  ],
  "steel plate armor of alteration": [
    "Laminata Lorica Mutationis"
  ],
  "leather armor of major illusion": [
    "Corii Lorica Maioris Inlusionis"
  ],
  "necklace of deft hands": [
    "Monile Dexteritatis"
  ],
  "necklace of eminent alchemy": [
    "Monile Alchemiae Eminentis"
  ],
  "iron boots of major stamina": [
    "Ferri Caligae Maioris Staminae"
  ],
  "necklace of major smithing": [
    "Monile Maioris Faciendi"
  ],
  "necklace of extreme sneaking": [
    "Monile Repsendi Extremis"
  ],
  "scaled armor of eminent destruction": [
    "Lorica Squamata Eminentis Destructionis"
  ],
  "a successful power attack will always break an opponent's block, and cause a stagger": [
    "Ictus potestatis semper facit ut inimicum scuto vel armo interponentem claudicet"
  ],
  "successful power attack will always break an opponent's block, and cause a stagger": [
    "Ictus potestatis semper facit ut inimicum scuto vel armo interponentem claudicet"
  ],
  "leather armor of major destruction": [
    "Corii Lorica Maioris Destructionis"
  ],
  "those interested in learning more about magic may consider joining the college of winterhold": [
    "Qui plus de magica discere volunt Collegium Hiemiterrae adiungere cogitare debent"
  ],
  "ring of nullification": [
    "Anulus Nullificationis"
  ],
  "daedric shield of magic abatement": [
    "Scutum Daedricum Minoris Magicae"
  ],
  "several factors determine whether you'll remain undetected while sneaking, including: enemies' line of sight, the weight of worn armor, speed of movement, and the sneak skill itself": [
    "Visus inimicorum, pondus loricae, velocitas, et ars rependi sunt res quae casum inventionis dum repens afficiunt"
  ],
  "steel plate armor of eminent conjuring": [
    "Laminata Lorica Coniurationis Vocantis"
  ],
  "necklace of eminent lockpicking": [
    "Monile Eminentis Clavicarii"
  ],
  "steel armor of destruction": [
    "Adamantea Lorica Destructionis"
  ],
  "banded iron shield of dwindling magic": [
    "Ferri Adligati Scutum Magicam Diminuendi"
  ],
  "hide boots of stamina": [
    "Renonis Caligae Staminae"
  ],
  "dragonplate boots of eminent stamina": [
    "Draconilaminata Caligae Maximae Staminae"
  ],
  "dwarven shield of magic suppression": [
    "Dvemere Scutum Suppressionis Magicae"
  ],
  "ring of magic abatement": [
    "Anulus Minoris Magicae"
  ],
  "scaled boots of eminent stamina": [
    "Caligae Squamatae Maximae Staminae"
  ],
  "dragonscale gauntlets of peerless lockpicking": [
    "Draconisquamatae Manicae Optimae Clavicarii"
  ],
  "orcish armor of extreme conjuring": [
    "Orci Lorica Maiorum Vocantium"
  ],
  "orcish armor of eminent illusion": [
    "Orci Lorica Eminentis Inlusionis"
  ],
  "successfully bashing with either a shield or weapon will always stagger an opponent": [
    "Scuto oppugnare vel armo dum eo interponas semper facit ut inimicum claudicet"
  ],
  "necklace of eminent magicka": [
    "Monile Eminentis Magicae"
  ],
  "dragonscale shield of nullification": [
    "Scutum Draconisquamatum Nullificationis"
  ],
  "necklace of extreme lockpicking": [
    "Monile Extremis Clavicarii"
  ],
  "necklace of the major squire": [
    "Monile Maioris Armiferis"
  ],
  "necklace of major blocking": [
    "Monile Maioris Interponendi"
  ],
  "necklace of minor sure grip": [
    "Monile Minoris Dexteritatis"
  ],
  "cold as the dead": [
    "Cold as Dead"
  ],
  "scaled armor of alteration": [
    "Lorica Squamata Mutationis"
  ],
  "glass boots of major sneaking": [
    "Vitreae Caligae Maioris Repsendi"
  ],
  "steel plate armor of major conjuring": [
    "Laminata Lorica Maioris Vocandi"
  ],
  "scaled armor of major illusion": [
    "Lorica Squamata Maioris Inlusionis"
  ],
  "scaled armor of illusion": [
    "Lorica Squamata Inlusionis"
  ],
  "necklace of major wielding": [
    "Monile Maioris Tenendi"
  ],
  "dragonscale boots of extreme stamina": [
    "Caligae Draconisquamatae Magnae Staminae"
  ],
  "orcish armor of eminent conjuring": [
    "Orci Lorica Coniurationis Vocantis"
  ],
  "steel armor of major conjuring": [
    "Adamantea Lorica Maioris Vocandi"
  ],
  "necklace of peerless lockpicking": [
    "Monile Optimae Clavicarii"
  ],
  "ebony boots of major stamina": [
    "Caligae Ebeni Maioris Staminae"
  ],
  "necklace of recuperation": [
    "Monile Staminam Recuperandi"
  ],
  "glass shield of magic abatement": [
    "Vitreum Scutum Minoris Magicae"
  ],
  "orcish armor of extreme illusion": [
    "Orci Lorica Inlusionis Extremae"
  ],
  "iron shield of resist magic": [
    "Ferri Scutum Magicam Resistendi"
  ],
  "necklace of peerless smithing": [
    "Monile Maximi Faciendi"
  ],
  "steel plate armor of eminent alteration": [
    "Laminata Lorica Eminentis Mutationis"
  ],
  "dwarven shield of dwindling magic": [
    "Dvemere Scutum Magicam Diminuendi"
  ],
  "scaled boots of major stamina": [
    "Caligae Squamatae Maioris Staminae"
  ],
  "necklace of mending": [
    "Monile Reficiendi"
  ],
  "steel armor of minor destruction": [
    "Adamantea Lorica Minoris Destructionis"
  ],
  "tamriel's fighters guild has no presence in skyrim. instead, warriors seeking adventure and kinship may join the companions, in whiterun": [
    "Sodalitas pugnatorum in Caelifine non est. In loco eius, bellatores qui commotionem et amiciatiam quaÃªrunt possunt Contubernales adiungere, Albacursus"
  ],
  "necklace of extreme deft hands": [
    "Monile Extremis Dexteritatis"
  ],
  "the thieves guild has a strong presence in the city of riften, and has been known to recruit those gifted in the art of stealth": [
    "Sodalitas Furum potens est in urbe Hiati, et noscitur peritos in arte clam movendi invenire et accipere"
  ],
  "thieves guild has a strong presence in the city of riften, and has been known to recruit those gifted in the art of stealth": [
    "Sodalitas Furum potens est in urbe Hiati, et noscitur peritos in arte clam movendi invenire et accipere"
  ],
  "necklace of invigoration": [
    "Monile Invigorans"
  ],
  "steel plate boots of stamina": [
    "Laminatae Caligae Staminae"
  ],
  "dragonscale gauntlets of eminent deft hands": [
    "Draconisquamatae Manicae Eminentis Dexteritatis"
  ],
  "leather armor of destruction": [
    "Corii Lorica Destructionis"
  ],
  "leather boots of major stamina": [
    "Corii Caligae Maioris Staminae"
  ],
  "orcish armor of eminent destruction": [
    "Orci Lorica Eminentis Destructionis"
  ],
  "necklace of extreme blocking": [
    "Monile Extremis Interponendi"
  ],
  "dragonplate shield of magic abatement": [
    "Draconilaminatum Scutum Minoris Magicae"
  ],
  "steel armor of illusion": [
    "Adamantea Lorica Inlusionis"
  ],
  "a successful power attack has a chance to stagger an opponent who is not blocking": [
    "Ictus potestatis casum habet ut inimicum non scuto vel armo interponentem claudicet"
  ],
  "successful power attack has a chance to stagger an opponent who is not blocking": [
    "Ictus potestatis casum habet ut inimicum non scuto vel armo interponentem claudicet"
  ],
  "scaled armor of eminent alteration": [
    "Lorica Squamata Eminentis Mutationis"
  ],
  "glass boots of eminent stamina": [
    "Vitreae Caligae Maximae Staminae"
  ],
  "glass boots of extreme sneaking": [
    "Vitreae Caligae Repsendi Extremis"
  ],
  "necklace of minor lockpicking": [
    "Monile Minoris Clavicarii"
  ],
  "ring of waning magic": [
    "Anulus Insenescentis Magicae"
  ],
  "orcish armor of eminent alteration": [
    "Orci Lorica Eminentis Mutationis"
  ],
  "leather armor of minor conjuring": [
    "Corii Lorica Minoris Vocandi"
  ],
  "orcish armor of extreme stamina": [
    "Orci Lorica Magnae Staminae"
  ],
  "elven boots of major stamina": [
    "Caligae Alfae Maioris Staminae"
  ],
  "scaled armor of eminent conjuration": [
    "Lorica Squamata Eminentis Coniurationis"
  ],
  "necklace of peerless blocking": [
    "Monile Optimi Interponendi"
  ],
  "orcish armor of extreme alteration": [
    "Orci Lorica Extremis Mutationis"
  ],
  "steel plate armor of eminent restoration": [
    "Laminata Lorica Eminentis Reficiendi"
  ],
  "leather armor of restoration": [
    "Corii Lorica Recreandi"
  ],
  "glass shield of dwindling magic": [
    "Vitreum Scutum Magicam Diminuendi"
  ],
  "necklace of remedy": [
    "Monile Remedii"
  ],
  "orcish armor of major alteration": [
    "Orci Lorica Maioris Mutationis"
  ],
  "necklace of the peerless knight": [
    "Monile Optimae Equitis"
  ],
  "ebony shield of magic suppression": [
    "Ebeni Scutum Suppressionis Magicae"
  ],
  "steel plate armor of conjuring": [
    "Laminata Lorica Vocandi"
  ],
  "scaled armor of major restoration": [
    "Lorica Squamata Maioris Reficiendi"
  ],
  "scaled armor of conjuration": [
    "Lorica Squamata Coniurationis"
  ],
  "daedric boots of eminent stamina": [
    "Caligae Daedricae Maximae Staminae"
  ],
  "necklace of minor smithing": [
    "Monile Minoris Ferrarii"
  ],
  "scaled armor of destruction": [
    "Lorica Squamata Destructionis"
  ],
  "steel armor of minor restoration": [
    "Adamantea Lorica Minoris Reficiendi"
  ],
  "steel armor of major stamina": [
    "Adamantea Lorica Maioris Staminae"
  ],
  "dwarven boots of stamina": [
    "Dvemeres Caligae Staminae"
  ],
  "leather armor of conjuring": [
    "Corii Lorica Vocandi"
  ],
  "steel armor of major alteration": [
    "Adamantea Lorica Maioris Mutationis"
  ],
  "orcish armor of extreme destruction": [
    "Orci Lorica Extremis Destructionis"
  ],
  "necklace of the major knight": [
    "Monile Maioris Equitis"
  ],
  "necklace of eminent deft hands": [
    "Monile Eminentis Dexteritatis"
  ],
  "orcish shield of dwindling magic": [
    "Orci Scutum Magicam Diminuendi"
  ],
  "banded iron shield of waning magic": [
    "Ferri Adligati Scutum Insenescentis Magicae"
  ],
  "orcish armor of eminent stamina": [
    "Orci Lorica Maximae Staminae"
  ],
  "steel armor of minor illusion": [
    "Adamantea Lorica Minoris Inlusionis"
  ],
  "necklace of eminent sure grip": [
    "Monile Eminentis Ambidexteritatis"
  ],
  "necklace of peerless haggling": [
    "Monile Optimae Arguendi"
  ],
  "steel shield of waning magic": [
    "Adamanteum Scutum Insenescentis Magicae"
  ],
  "banded iron shield of resist magic": [
    "Ferri Adligati Scutum Magicam Resistendi"
  ],
  "leather boots of minor stamina": [
    "Corii Caligae Minoris Staminae"
  ],
  "necklace of eminent sneaking": [
    "Monile Maximi Repsendi"
  ],
  "dragonscale boots of eminent stamina": [
    "Caligae Draconisquamatae Maximae Staminae"
  ],
  "steel plate armor of restoration": [
    "Laminata Lorica Recreandi"
  ],
  "hide shield of resist magic": [
    "Scutum Cutis Magicam Resistendi"
  ],
  "steel plate armor of major alteration": [
    "Laminata Lorica Maioris Mutationis"
  ],
  "daedric boots of extreme stamina": [
    "Caligae Daedricae Magnae Staminae"
  ],
  "follower commands": [
    "Follower Commands"
  ],
  "orcish armor of major stamina": [
    "Orci Lorica Maioris Staminae"
  ],
  "followers": [
    "Followers"
  ],
  "leather armor of major conjuring": [
    "Corii Lorica Maioris Vocandi"
  ],
  "necklace of peerless deft hands": [
    "Monile Optimae Dexteritatis"
  ],
  "glass boots of major stamina": [
    "Vitreae Caligae Maioris Staminae"
  ],
  "the charge level of a staff is based on the corresponding skill of the mage wielding it": [
    "Gradus rudis pendet in arte magi qui eum tenet"
  ],
  "charge level of a staff is based on the corresponding skill of the mage wielding it": [
    "Gradus rudis pendet in arte magi qui eum tenet"
  ],
  "for example, a wizard with a high destruction skill would get a lot of uses from a staff of fireball": [
    "I.e. thaumatorgos peritus in arte Destructionis multos usus e Rude Ignipilae habeat"
  ],
  "it is said that the only way to join the dark brotherhood is to be contacted directly, and the shadowy group of assassins is very selective in its recruiting": [
    "Dicitur viam solam Fraternitatem Obscuram adiungendi esse partem gregis exspectare, et illi cautissimi sunt in quibus eligunt"
  ],
  "daedric shield of the nullification": [
    "Scutum Daedricum Nullificationis"
  ],
  "necklace of extreme haggling": [
    "Monile Extremis Arguendi"
  ],
  "dragonscale shield of magic abatement": [
    "Scutum Draconisquamatum Minoris Magicae"
  ],
  "necklace of the minor squire": [
    "Monile Minoris Armiferis"
  ],
  "necklace of minor sneaking": [
    "Monile Minoris Repsendi"
  ],
  "dragonplate boots of peerless stamina": [
    "Draconilaminata Caligae Maximae Staminae"
  ],
  "leather armor of minor alteration": [
    "Corii Lorica Minoris Mutationis"
  ],
  "leather armor of alteration": [
    "Corii Lorica Mutationis"
  ],
  "ebony shield of magic abatement": [
    "Ebeni Scutum Minoris Magicae"
  ],
  "leather armor of major alteration": [
    "Corii Lorica Maioris Mutationis"
  ],
  "dragonscale shield of magic suppression": [
    "Scutum Draconisquamatum Suppressionis Magicae"
  ],
  "dragonscale boots of peerless stamina": [
    "Caligae Draconisquamatae Maximae Staminae"
  ],
  "steel plate boots of major stamina": [
    "Laminatae Caligae Maioris Staminae"
  ],
  "leather armor of minor destruction": [
    "Corii Lorica Minoris Destructionis"
  ],
  "elven boots of stamina": [
    "Caligae Alfae Staminae"
  ],
  "orcish armor of major conjuring": [
    "Orci Lorica Maioris Vocandi"
  ],
  "steel armor of minor alteration": [
    "Adamantea Lorica Minoris Mutationis"
  ],
  "necklace of peerless alchemy": [
    "Monile Optimae Alchemiae"
  ],
  "lingering damage magicka": [
    "Vulnus Magicae Persistens"
  ],
  "necklace of major magicka": [
    "Monile Maioris Magicae"
  ],
  "orcish shield of magic suppression": [
    "Orci Scutum Suppressionis Magicae"
  ],
  "necklace of the eminent knight": [
    "Monile Eminentis Equitis"
  ],
  "necklace of major alchemy": [
    "Monile Maioris Alchemiae"
  ],
  "hide shield of waning magic": [
    "Scutum Cutis Insenescentis Magicae"
  ],
  "necklace of wielding": [
    "Monile Tenendi"
  ],
  "elven shield of magic suppression": [
    "Alfum Scutum Suppressionis Magicae"
  ],
  "ebony boots of eminent stamina": [
    "Caligae Ebeni Maximae Staminae"
  ],
  "necklace of peerless magicka": [
    "Monile Maximi Magicae"
  ],
  "necklace of revival": [
    "Monile Revivendi"
  ],
  "dragonplate boots of extreme stamina": [
    "Draconilaminata Caligae Magnae Staminae"
  ],
  "dragonplate shield of magic suppression": [
    "Draconilaminatum Scutum Suppressionis Magicae"
  ],
  "leather armor of minor restoration": [
    "Corii Lorica Minoris Reficiendi"
  ],
  "elven shield of waning magic": [
    "Alfum Scutum Insenescentis Magicae"
  ],
  "necklace of the minor knight": [
    "Monile Minoris Equitis"
  ],
  "shadowmere abilities": [
    "Shadowmere Abilities"
  ],
  "necklace of the squire": [
    "Monile Armiferis"
  ],
  "scaled armor of eminent restoration": [
    "Lorica Squamata Eminentis Reficiendi"
  ],
  "necklace of minor alchemy": [
    "Monile Minoris Alchemiae"
  ],
  "necklace of extreme alchemy": [
    "Monile Extremis Alchemiae"
  ],
  "hide boots of minor stamina": [
    "Renonis Caligae Minoris Staminae"
  ],
  "steel plate armor of eminent destruction": [
    "Laminata Lorica Eminentis Destructionis"
  ],
  "necklace of the extreme knight": [
    "Monile Extremis Equitis"
  ],
  "daedric boots of peerless stamina": [
    "Caligae Daedricae Maximae Staminae"
  ],
  "necklace of extreme magicka": [
    "Monile Extremis Magicae"
  ],
  "necklace of major deft hands": [
    "Monile Maioris Dexteritatis"
  ],
  "steel boots of minor stamina": [
    "Caligae Adamanteae Minoris Staminae"
  ],
  "necklace of minor haggling": [
    "Monile Minoris Arguendi"
  ],
  "steel shield of resist magic": [
    "Adamanteum Scutum Magicam Resistendi"
  ],
  "scaled armor of major conjuration": [
    "Lorica Squamata Maioris Coniurationis"
  ],
  "orcish armor of major illusion": [
    "Orci Lorica Maioris Inlusionis"
  ],
  "dragonscale gauntlets of eminent lockpicking": [
    "Draconisquamatae Manicae Eminentis Clavicarii"
  ],
  "steel armor of alteration": [
    "Adamantea Lorica Mutationis"
  ],
  "necklace of minor wielding": [
    "Monile Minoris Tenendi"
  ],
  "leather boots of stamina": [
    "Corii Caligae Staminae"
  ],
  "orcish shield of magic abatement": [
    "Orci Scutum Minoris Magicae"
  ],
  "steel armor of major restoration": [
    "Adamantea Lorica Maioris Reficiendi"
  ],
  "necklace of smithing": [
    "Monile Ferrarii"
  ],
  "dragonscale gauntlets of peerless deft hands": [
    "Draconisquamatae Manicae Optimae Dexteritatis"
  ],
  "necklace of the extreme squire": [
    "Monile Extremis Armiferis"
  ],
  "necklace of peerless sure grip": [
    "Monile Optimae Ambidexteritatis"
  ],
  "elven shield of dwindling magic": [
    "Alfum Scutum Magicam Diminuendi"
  ],
  "dragonscale gauntlets of extreme deft hands": [
    "Draconisquamatae Manicae Extremis Dexteritatis"
  ],
  "necklace of minor magicka": [
    "Monile Minoris Magicae"
  ],
  "steel shield of dwindling magic": [
    "Adamanteum Scutum Magicam Diminuendi"
  ],
  "necklace of eminent smithing": [
    "Monile Maximi Faciendi"
  ],
  "necklace of haggling": [
    "Monile Arguendi"
  ],
  "necklace of renewal": [
    "Monile Staminam Replendi"
  ],
  "necklace of minor deft hands": [
    "Monile Minoris Dexteritatis"
  ],
  "steel armor of major illusion": [
    "Adamantea Lorica Maioris Inlusionis"
  ],
  "steel plate armor of major illusion": [
    "Laminata Lorica Maioris Inlusionis"
  ],
  "scaled armor of major alteration": [
    "Lorica Squamata Maioris Mutationis"
  ],
  "steel plate armor of major destruction": [
    "Laminata Lorica Maioris Destructionis"
  ],
  "steel plate armor of destruction": [
    "Laminata Lorica Destructionis"
  ],
  "hide shield of dwindling magic": [
    "Scutum Cutis Magicam Diminuendi"
  ],
  "glass boots of extreme stamina": [
    "Vitreae Caligae Magnae Staminae"
  ],
  "necklace of eminent haggling": [
    "Monile Eminentis Arguendi"
  ],
  "steel armor of restoration": [
    "Adamantea Lorica Recreandi"
  ],
  "necklace of extreme sure grip": [
    "Monile Extremis Ambidexteritatis"
  ],
  "steel armor of conjuring": [
    "Adamantea Lorica Vocandi"
  ],
  "leather armor of major restoration": [
    "Corii Lorica Maioris Reficiendi"
  ],
  "necklace of major sneaking": [
    "Monile Maioris Repsendi"
  ],
  "necklace of magicka": [
    "Monile Magicae"
  ],
  "ring of resist magic": [
    "Anulus Magicam Resistendi"
  ],
  "does <mag> bleeding damage for <dur> seconds": [
    "<mag> vulnus exsanguinationis <dur> secundas"
  ],
  "equip and cast to learn the firebolt spell": [
    "Equip et cast to learn Firebolt spell"
  ],
  "shieldritualcastbodyfx": [
    "ShieldRitualCastBodyFX"
  ],
  "restore <mag> points of magicka per second for <dur> seconds": [
    "Redde <mag> magicae singulis secundis <dur> secundas"
  ],
  "for <dur> seconds, opponents in melee range take <8> points fire damage per second": [
    "<dur> secundas, inimici prope te accipiunt <8> vulneris ignis singulis secundis"
  ],
  "conjuration spells cost <mag>% less to cast": [
    "Magica artis vocandi <mag>% minus constat"
  ],
  "sets even powerful undead on fire and makes them flee for <dur> seconds": [
    "Incendit amortuos et facit ut ei effugiant <dur> secundas"
  ],
  "restoration spells are <mag>% stronger for <dur> seconds": [
    "Magica reficiendi <mag>% fortius <dur> secundas sunt"
  ],
  "cast on a nearby surface, it explodes for <mag> points of shock damage when enemies come near": [
    "Utere in pariete vel pavimento. Displodit <mag> punctis vulneris electricitatis cum inimici appropinquent"
  ],
  "magicka is increased by <mag> points for <dur> seconds": [
    "Magica <mag> augetur <dur> secundas"
  ],
  "stamina regenerates <mag>% more slowly": [
    "Stamina <mag>% lentius recuperat"
  ],
  "damages the caster <mag> points per second": [
    "<mag> vulnus accipis singulis secundis"
  ],
  "temporarily reanimates corpses in the immediate area": [
    "Corpora prope te breve tempus revocantur"
  ],
  "a volley of frozen shards that do <mag> points of damage, and twice as much stamina damage": [
    "Effusus hamorum glaciei qui <mag> vulneris facit, et bis vulneris staminae"
  ],
  "volley of frozen shards that do <mag> points of damage, and twice as much stamina damage": [
    "Effusus hamorum glaciei qui <mag> vulneris facit, et bis vulneris staminae"
  ],
  "appear spectral for <dur> seconds": [
    "Spectralis esto <dur> secundas"
  ],
  "improves the caster's armor rating by <mag> points for <dur> seconds": [
    "Auget loricam tuam <mag> punctis <dur> secundas"
  ],
  "target takes <mag> points of damage, and twice as much magicka damage": [
    "Inimicus accipit <mag> vulneris, et bis magicae vulneris"
  ],
  "a bolt of lightning that does <mag> points of shock damage to health and half that to magicka": [
    "Sagitta Fulminis quae facit <mag> vulneris electricitatis saluti et dimidiam eius magicae"
  ],
  "bolt of lightning that does <mag> points of shock damage to health and half that to magicka": [
    "Sagitta Fulminis quae facit <mag> vulneris electricitatis saluti et dimidiam eius magicae"
  ],
  "does <mag> points of magicka damage": [
    "<mag> vulnus magicae"
  ],
  "bows do <mag>% more damage for <dur> seconds": [
    "Arcus <mag>% plus vulneris faciunt <dur> secundas"
  ],
  "restore <mag> points of magicka": [
    "Redde <mag> magicae"
  ],
  "creates a magic sword for <dur> seconds. sheathe it to dispel": [
    "Gladium magicum vocat qui <dur> secundas manet"
  ],
  "target takes <mag> points of health and magicka damage per second": [
    "Inimicus accipit <mag> salutis et magicae vulneris singulis secundis"
  ],
  "leavewaterbreath": [
    "LeaveWaterBreath"
  ],
  "targets won't flee for <dur> seconds and get extra health and stamina": [
    "Inimici non effugiunt <dur> secundas et paulum plus salutis staminaeque accipiunt"
  ],
  "for <dur> seconds, opponents that get too close take <8> points per second of fire damage": [
    "<dur> secundas, opponents that get too close take <8> singulis secundis Ignis vulneris"
  ],
  "destruction spells are <mag>% stronger for <dur> seconds": [
    "Magica destructionis <mag>% fortior est <dur> secundas"
  ],
  "causes <mag> points of concentrated poison damage": [
    "<mag> vulnus veneni"
  ],
  "carrying capacity increases by <mag> for <dur> seconds": [
    "Pondus portabile augetur <mag> puncta <dur> secundas"
  ],
  "lightning bolt that does <mag> points of shock damage to health and half to magicka, then leaps to a new target": [
    "Fulmen quod <mag> vulnus electricitatis saluti et dimidium magicae facit, deinde ad novum movet"
  ],
  "triggered by nearby enemies, the rune explodes for <mag> points of shock damage": [
    "A inimicis propinquis inceptus, runus displodet et <mag> vulnus electricitatis facit"
  ],
  "increases armor rating by <mag> points and negates up to <mag> points of spell damage or effects": [
    "Lorica tua <mag> punctis augetur et <mag> vulnus magicae negatur"
  ],
  "vampire powers awaken": [
    "Vires vampirici surgunt"
  ],
  "opponents up to level <mag> attack anything nearby for <dur> seconds while taking <2>damage": [
    "Inimici ad gradum <mag> quidquid prope eum forte sit oppugnant <dur> secundas dum accipiunt <2>vulneris"
  ],
  "can swim underwater without drowning": [
    "Sub aqua respirare potes"
  ],
  "raises the wielder's smithing abilities, and does <mag> shock damage to enemies on hit": [
    "Ars Ferrarii augentur, et <mag> vulnus electricitatis ad inimicos facit"
  ],
  "burns trolls for <mag> points": [
    "Trelles urit <mag> puncta"
  ],
  "drains the target's magicka by <mag> points per second for <dur> seconds": [
    "Haurit magicam inimici <mag> punctis singulis secundis <dur> secundas"
  ],
  "conjurations spells last <mag>% longer for <dur> seconds": [
    "Magica artis vocandi <mag>% longius durat <dur> secundas"
  ],
  "while ethereal, you recover health 25% faster": [
    "Dum aetherialis es, salutem 25% celerius recuperas"
  ],
  "causes <mag> points of poison damage": [
    "<mag> vulnus veneni"
  ],
  "a freezing whirlwind that does <mag> points of damage per second, and twice as much stamina damage": [
    "Turbo gelatoria quae facit <mag> vulneris singulis secundis, et bis vulneris staminae"
  ],
  "freezing whirlwind that does <mag> points of damage per second, and twice as much stamina damage": [
    "Turbo gelatoria quae facit <mag> vulneris singulis secundis, et bis vulneris staminae"
  ],
  "moderately powerful creatures and people won't fight for <dur> seconds": [
    "Animalia hominesque mediocris potestatis non pugnabunt <dur> secundas"
  ],
  "+<mag> speechcraft": [
    "+<mag> Loquacitas"
  ],
  "your hands are bound": [
    "Manus Catenatae Sunt"
  ],
  "you can see better in the dark": [
    "Melius in obscuritate videre potes"
  ],
  "target takes <mag> points of frost damage": [
    "Inimicus accipit <mag> gelidus vulneris"
  ],
  "absorb <mag> points of magicka from the target": [
    "Absorbe <mag> magicae ab inimico"
  ],
  "if target dies within <dur> seconds, it fills a soul gem": [
    "Si inimicus inter <dur> secondus morietur, gemmanimam complebit"
  ],
  "triggered by nearby enemies, the rune explodes for <mag> points of frost damage": [
    "A inimicis propinquis inceptus, runus displodet et <mag> vulnus gelidus facit"
  ],
  "you can become invisible": [
    "Invisibilis fieri potes"
  ],
  "triggered by nearby enemies, the rune explodes for <mag> points of fire damage": [
    "A inimicis propinquis inceptus, runus displodet et <mag> vulnus ignis"
  ],
  "everything is green": [
    "Omnia viridia sunt"
  ],
  "destruction spells cost <mag>% less to cast": [
    "Magica destructionis <mag>% minus magicae poscit"
  ],
  "it's all you've got": [
    "Est omnis qui habes"
  ],
  "you move more quietly for <dur> seconds": [
    "Quietius moves <dur> secundas"
  ],
  "increases magic resistance by <mag>%": [
    "Resistentia magicae <mag>% augetur"
  ],
  "lockpicking is <mag>% easier for <dur> seconds": [
    "Aperi ianuas <mag>% facilius <dur> secundas"
  ],
  "<mag>% resistant to disease": [
    "<mag>% resiste morbo"
  ],
  "<mag> points of extra damage to nords": [
    "<mag> plus vulneris Germanibus"
  ],
  "target is paralyzed for <dur> and takes 25 points of damage over <dur> seconds": [
    "Inimicus paralyticus et 25 punctas vulneris accipit <dur> secundas"
  ],
  "you take half damage and do double damage for <dur> seconds": [
    "Dimidium vulneris accipies et duplex vulnus facias <dur> secundas"
  ],
  "increases disease resistance by <mag>%": [
    "Resistentia morbo <mag>% augetur "
  ],
  "a blast of fire that does <mag> points of damage": [
    "Displosio Ignis quae facit <mag> vulneris"
  ],
  "blast of fire that does <mag> points of damage": [
    "Displosio Ignis quae facit <mag> vulneris"
  ],
  "magicka regenerates <mag>% faster for <dur> seconds": [
    "Magica recuperat <mag>% rapidius <dur> secundas"
  ],
  "alteration spells cost <mag>% less to cast": [
    "Magica Mutationis <mag>% minus magicae poscunt"
  ],
  "creates a magic battle axe for <dur> seconds. sheathe it to dispel": [
    "Bellisecurem magicum vocat qui <dur> secundas manet"
  ],
  "undead up to level <mag> are set on fire and flee for <dur> seconds": [
    "Amortui ad gradum <mag> incenduntur et effugiunt <dur> secundas"
  ],
  "heals the target <mag> points, but not undead, atronachs or machines": [
    "Amicum <mag> punctis sanat, nec amortuos, atronacha, vel machinas"
  ],
  "healing spells restore <mag>% more": [
    "Magica salutis <mag>% plus reddit"
  ],
  "targets flee for <dur> seconds": [
    "Inimici effugiunt <dur> secundas"
  ],
  "all affected undead up to level <mag> flee for <dur> seconds": [
    "Amortui affecti ad gradum <mag> effugiunt <dur> secundas"
  ],
  "a blast of cold that does <mag> points of damage per second damage to health and stamina": [
    "Displosio frigidis quae facit <mag> vulneris singulis secundis vulneris saluti et staminae"
  ],
  "blast of cold that does <mag> points of damage per second damage to health and stamina": [
    "Displosio frigidis quae facit <mag> vulneris singulis secundis vulneris saluti et staminae"
  ],
  "creatures and people up to level <mag> flee from combat for <dur> seconds": [
    "Animalia hominesque ad gradum <mag> effugiunt <dur> secundas"
  ],
  "target won't flee for <dur> seconds and gets some extra health and stamina": [
    "Inimicus non effugiet <dur> secundas et paulum plus salutis staminaeque accipit"
  ],
  "arrows stagger targets": [
    "Sagittae inimicos claudicare cogunt"
  ],
  "your health, magicka, and stamina recover more slowly during the day": [
    "Salus, magica, staminaque tardius sub sole recuperant"
  ],
  "completely restore magicka": [
    "Magicam omnino replet"
  ],
  "do you want to improve this item": [
    "Visne hanc rem meliorem facere"
  ],
  "drain the target's stamina by <mag> points": [
    "Remove staminam ab inimico <mag> puncta"
  ],
  "target is <mag>% weaker to fire damage for <dur> seconds": [
    "Inimicus <mag>% debilior vulneris igni <dur> secundas est"
  ],
  "magicka regenerates <mag>% more slowly": [
    "Magica recuperat <mag>% lentius"
  ],
  "blocking absorbs <mag>% more damage for <dur> seconds": [
    "Interponere contra <mag>% plus vulneris tegit <dur> secundas"
  ],
  "a spike of ice that does <mag> points of frost damage to health and stamina": [
    "Hamus Gelidus qui facit <mag> gelidus vulneris saluti et staminae"
  ],
  "spike of ice that does <mag> points of frost damage to health and stamina": [
    "Hamus Gelidus qui facit <mag> gelidus vulneris saluti et staminae"
  ],
  "stops poison's continuing effects": [
    "Consistit effecta veneni"
  ],
  "stamina regenerates <mag>% slower for <dur> seconds": [
    "Stamina <mag>% lentius recuperat <dur> secundas"
  ],
  "absorb <mag>% of the magicka from hostile spells for <dur> seconds": [
    "Absorbe <mag>% magicae e magica inimicorum <dur> secundas"
  ],
  "unarmed strikes do <mag> additional damage": [
    "Ictus manubus <mag> plus vulneris faciunt"
  ],
  "resist <mag>% of shock damage for <dur> seconds": [
    "Resiste <mag>% electricitatis vulneris <dur> secundas"
  ],
  "turns a low level enemy into a harmless creature for 30 seconds or until they take damage": [
    "Turns low level enemy into harmless creature for 30 seconds or until they take vulneris"
  ],
  "reanimate a dead body permanently to fight for you": [
    "Revoca corpus sempiterniter ut pro te pugnet"
  ],
  "only works on people": [
    "Solum in hominibus fungitur"
  ],
  "do you want to create this item": [
    "Visne hanc rem creare"
  ],
  "cures all diseases": [
    "Omnes morbos curat"
  ],
  "healing spells cure 10% more": [
    "Magica salutis 10% plus auget"
  ],
  "+<mag> magicka, if wearer is undead": [
    "+<mag> magicae, si amortuus esses"
  ],
  "you are <mag>% harder to detect for <dur> seconds": [
    "<mag>% dificilius inventu es <dur> secundas"
  ],
  "increases fire resistance by <mag>%": [
    "Resistentia igni <mag>% augetur"
  ],
  "summoned targets up to level <mag> are sent back to oblivion": [
    "Inimici vocati ad gradum <mag> ad Oblivionem mittuntur"
  ],
  "cast on a nearby surface, it explodes for <mag> points of frost damage when enemies come near": [
    "Utere in pariete vel pavimento. Displodit <mag> punctis gelidus vulneris cum inimici appropinquent"
  ],
  "increase the duration and decrease the cost of conjuration spells for <dur> seconds": [
    "Tempus augetur et pretium diminuitur magicae Artis Vocandi <dur> secundas"
  ],
  "improved night vision for <dur> seconds": [
    "Visus noctu augetur <dur> secundas"
  ],
  "when entering sneak mode, you become invisible": [
    "Dum repens, invisibilis eris"
  ],
  "undead up to level <mag> flee for <dur> seconds": [
    "Amortui ad gradum <mag> effugiunt <dur> secundas"
  ],
  "pickpocket success is <mag>% better": [
    "Furare <mag>% facilius"
  ],
  "two-handed weapons do <mag>% more damage for <dur> seconds": [
    "Arma Ambabus manubus <mag>% plus vulneris <dur> secundas faciunt"
  ],
  "+<mag> speechcraft for <dur> seconds": [
    "+<mag> Loquacitatis <dur> secundas"
  ],
  "damage taken from animals is reduced 10%": [
    "Vulnus ab animalibus acceptum 10% reducitur"
  ],
  "+<mag> damage resistance, if wearer is undead": [
    "Resiste vulneri +<mag>, si amortuus esses"
  ],
  "completely restore stamina": [
    "Stamina omnino repletur"
  ],
  "absorb <mag> points of healt per second from the target": [
    "Absorbe <mag> salutis singulis secundis ab inimico"
  ],
  "<mag> points of extra damage which ignores armor": [
    "<mag> plus vulneris quod loricam ignorat"
  ],
  "hits have a small chance to instantly kill": [
    "Ictus forte statim necant"
  ],
  "current weapon cannot be poisoned": [
    "Armum in manu non venenatum esse potest"
  ],
  "a freezing whirlwind that does <mag> points of frost damage per second to health and stamina": [
    "Turbo gelatoria quae facit <mag> gelidus vulneris singulis secundis saluti et staminae"
  ],
  "freezing whirlwind that does <mag> points of frost damage per second to health and stamina": [
    "Turbo gelatoria quae facit <mag> gelidus vulneris singulis secundis saluti et staminae"
  ],
  "time between shouts is reduced 20%": [
    "Tempus inter clamores 20% reducitur"
  ],
  "creates a magic bow for <dur> seconds. sheathe it to dispel": [
    "Arcum macicum vocat qui <dur> secundas manet"
  ],
  "targets take <mag> points of damage per second, and twice as much stamina damage": [
    "Inimici <mag> vulneris singulis secundis, et bis vulneris staminae accipiunt"
  ],
  "while blocking, creates a ward that protects against spells for up to <mag> points": [
    "Dum scuto interpones, praesidium magicum vocas quod contra <mag> puncta magicae tegit"
  ],
  "increased health, magicka, and stamina": [
    "Salus, magica, staminaque auctae"
  ],
  "drains the target's magicka by <mag> points": [
    "Haurit magicam inimici <mag> punctis"
  ],
  "for <dur> seconds, items are enchanted <mag>% stronger": [
    "<dur> secundas, arma loricaeque incantantur <mag>% fortius"
  ],
  "double sneak attack damage with one-handed weapons": [
    "Duplex vulneris facis armis singuli-manu"
  ],
  "you stagger 25% less and foes stagger 25% more": [
    "Claudicas 25% minus et inimici 25% plus"
  ],
  "caster is invisible for <dur> seconds. activating an object or attacking will break the spell": [
    "Invisibilis esto <dur> secundas"
  ],
  "target is invisible for <dur> seconds. activating an object or attacking will break the spell": [
    "Inimicus invisibilis est <dur> secundas"
  ],
  "nearby living creatures, but not undead, machines or daedra, can be seen through walls": [
    "Viventes propinqui, nec amortui, nec machinae, nec daedrae, per mures videntur"
  ],
  "a blast of fire that does <mag> points": [
    "Displosio Ignis quae facit <mag> puncta vulneris"
  ],
  "blast of fire that does <mag> points": [
    "Displosio Ignis quae facit <mag> puncta vulneris"
  ],
  "restoration spells cost <mag>% less to cast": [
    "Magica reficiendi <mag>% minus magicae poscunt"
  ],
  "bows do <mag>% more damage": [
    "Arcus <mag>% plus vulneris faciunt"
  ],
  "target is <mag>% weaker to poison for <dur> seconds": [
    "Inimicus <mag>% debilior veneno <dur> secundas est"
  ],
  "potions you mix are <mag>% stronger for <dur> seconds": [
    "Potiones a te factae <mag>% fortiores sunt <dur> secundas"
  ],
  "increased health, magicka, and stamina recovery": [
    "Recuperatio salutis, magicae, staminaeque augetur"
  ],
  "cast on a nearby surface, it explodes for <mag> points of fire damage when enemies come near": [
    "Utere in pariete vel pavimento. Displodit <mag> punctis ignis vulneris cum inimici appropinquent"
  ],
  "you have been poisoned": [
    "Venenatus es"
  ],
  "target is <mag>% weaker to magic for <dur> seconds": [
    "Inimicus <mag>% debilior magicae <dur> secundas est"
  ],
  "calls a hero from sovngarde for <dur> seconds": [
    "Heroon e Valhalla vocat <dur> secundas"
  ],
  "sneaking is <mag>% better": [
    "Repe <mag>% melius"
  ],
  "animals take <mag> points of extra damage": [
    "Animals take <mag> plus vulneris"
  ],
  "undead up to level <mag> entering the circle will flee": [
    "Amortui ad gradum <mag> qui circulum intrabunt effugient"
  ],
  "lockpicking is <mag>% easier": [
    "Aperi ianuas <mag>% facilius"
  ],
  "summons the shade of arniel gane for <dur> seconds wherever the caster is pointing": [
    "Imaginem Arnielis Gane vocat <dur> secundas quo demononstras"
  ],
  "picking locks and picking pockets is <mag>% harder": [
    "Portas aperiens et furans <mag>% dificilius est"
  ],
  "nearby dead and undead can be seen through walls": [
    "Mortui propinqui et amortui per parietes videntur"
  ],
  "saplike poison does <mag> points of damage for <dur> seconds": [
    "Venenum Olibano Simile <mag> vulneris <dur> secundas facit"
  ],
  "stamina regenerates 10x faster for <dur> seconds": [
    "Stamina decies celerius recuperat <dur> secundas"
  ],
  "target takes <mag> points of damage, and twice as much stamina damage": [
    "Inimicus accipit <mag> vulneris, et bis vulneris staminae"
  ],
  "target takes <mag> damage per second for <dur> seconds": [
    "Inimicus accipit <mag> vulneris singulis secundis <dur> secundas"
  ],
  "calms nearby people for <dur> seconds": [
    "Homines propinquos molliores facit <dur> secundas"
  ],
  "invisibility for <dur> seconds": [
    "Invisibilitas <dur> secundas"
  ],
  "you are <mag>% less effective with melee weapons": [
    "<mag>% minus effectivus es armis manualibus"
  ],
  "magicka regenerates <mag> points more slowly": [
    "Magicae <mag> lentius recuperat"
  ],
  "lowers the cost and increases the potency of restoration spells for <dur> seconds": [
    "Pretium Magicae Recreationis diminuitur et potentia eius augetur <dur> secundas"
  ],
  "absorb <mag> points of magicka": [
    "Absorbe <mag> magicae"
  ],
  "shows the path to the current goal": [
    "Viam Demonstrat"
  ],
  "drains <mag> points from stamina": [
    "Haurit <mag> staminam"
  ],
  "see what others cannot": [
    "Vide ea quae alii non possunt"
  ],
  "sprayed on the ground, it creates a wall of fire that does <50> points of fire damage per second": [
    "In terra effusus, parietem ignis vocat quae <50> gelidus vulneris singulis secundis facit"
  ],
  "transmutes one piece of unrefined iron ore to silver, or silver ore to gold if the caster is carrying any": [
    "Muta minerale ferri in argentum, vel minerale argentei in aurum"
  ],
  "carrying capacity increased by <mag>, and no movement penalty from armor": [
    "Potes <mag> plus ponderis portare, et nullas poenas movimenti propter loricam das"
  ],
  "you lack the perk to improve magical items": [
    "Arte res magicas meliores faciendi cares"
  ],
  "a spear of ice that does <mag> points of frost damage, and twice as much stamina damage": [
    "Hasta gelidus quae facit <mag> gelidus vulneris, et bis vulneris staminae"
  ],
  "spear of ice that does <mag> points of frost damage, and twice as much stamina damage": [
    "Hasta gelidus quae facit <mag> gelidus vulneris, et bis vulneris staminae"
  ],
  "enhanced stats while the moons are full": [
    "Fortior es dum lunae plenae sunt"
  ],
  "summons a subjugated ghost for <dur> seconds wherever the caster is pointing": [
    "Phantasmam Subiugatam Vocat <dur> secundas quo demononstras"
  ],
  "block <mag>% more damage with your shield": [
    "Diminua <mag>% plus vulneris scuto"
  ],
  "pickpocketing is <mag>% easier for <dur> seconds": [
    "Furare <mag>% facilius <dur> secundas"
  ],
  "stamina is increased by <mag> points": [
    "Stamina <mag> puncta augetur"
  ],
  "feeding on corpses grants you increased health and health regeneration": [
    "Corporibus vescens tibi plus salutis et recuperationis salutis dat"
  ],
  "victim does not treat an attack with this weapon as assault": [
    "Victima ictus huius armi ignorat"
  ],
  "speechcraft is <mag>% better": [
    "Auget Loquacitatem <mag>%"
  ],
  "you move more quietly": [
    "Quietius moves"
  ],
  "target moves more quietly for <dur> seconds": [
    "Inimicus quietius movet <dur> secundas"
  ],
  "increases frost resistance by <mag>%": [
    "Resistentia glaciei <mag>% augetur"
  ],
  "invisibility for <dur> seconds. activating an object or attacking will break the spell": [
    "Invisibilitas <dur> secundas"
  ],
  "one-handed weapons do <mag>% more damage for <dur> seconds": [
    "Ictus singuli-manu <mag>% plus vulneris <dur> secundas faciunt"
  ],
  "fire breath shout deals 25% more damage": [
    "Clamor Flatus Ignis 25% plus vulneris facit"
  ],
  "powerful summoned and raised creatures are put under your control": [
    "Inimici potentes vocati sub potentate tua ponuntur"
  ],
  "increases shock resistance by <mag>%": [
    "Resistentia electricitati <mag>% augetur"
  ],
  "restore <mag> points of health per second for <dur> seconds": [
    "Redde <mag> salutis singulis secundis <dur> secundas"
  ],
  "drain the target's stamina by <mag> points per second for <dur> seconds": [
    "Diminua staminam inimici <mag> punctis singulis secundis <dur> secundas"
  ],
  "decrease the target's magicka regeneration by <mag>% for <dur> seconds": [
    "Diminuit recuperationem magicae <mag>% <dur> secundas"
  ],
  "drains <mag> points from magicka": [
    "Haurit <mag> magicam"
  ],
  "illusion spells are <mag>% stronger for <dur> seconds": [
    "Magica Inlusionis <mag>% fortius sunt <dur> secundas"
  ],
  "resist <mag>% of poison for <dur> seconds": [
    "Resiste <mag>% veneni <dur> secundas"
  ],
  "creates a wall of frost that does <mag> damage per second": [
    "Parietem gelidus quae <mag> vulneris singulis secundis facit"
  ],
  "increase the duration and decrease the cost of illusion spells for <dur> seconds": [
    "Tempus augetur et pretium diminuitur magicae Inlusionis <dur> secundas"
  ],
  "increases poison resistance by <mag>%": [
    "Resistentia veneno <mag>% augetur"
  ],
  "increases heavy armor skill by <mag> points": [
    "Addit ad artem armorum gravium <mag> punctos"
  ],
  "equip and cast to learn the oakflesh spell": [
    "Equip et cast to learn Oakflesh spell"
  ],
  "calms less powerful opponents for <dur> seconds, and traps their souls should they die": [
    "Inimicos minoris potestatis molliores facit <dur> secundas, et animos eorum capit"
  ],
  "restore <mag> points of health": [
    "Redde <mag> salutis"
  ],
  "take on the form of a skeever": [
    "In Skeever muta"
  ],
  "khajiit claws do four times the damage of a man's punch": [
    "Ungues Khajiiti quater vulneris pugni hominis facit"
  ],
  "one-handed attacks do <mag>% more damage": [
    "Ictus singuli-manu <mag>% plus vulneris faciunt"
  ],
  "thieves guild jarl quest": [
    "Sodalitatis Furum Jarl Quest"
  ],
  "a blood-freezing poison that does <mag> points of damage to health and stamina per second": [
    "Venenum sangui-glaciei quod facit <mag> vulneris saluti et staminae singulis secundis"
  ],
  "blood-freezing poison that does <mag> points of damage to health and stamina per second": [
    "Venenum sangui-glaciei quod facit <mag> vulneris saluti et staminae singulis secundis"
  ],
  "target takes <mag> points of shock damage, and half as much magicka damage": [
    "Inimicus accipit <mag> vulneris electricitatis, et dimidium magicae vulneris"
  ],
  "summons an unbound dremora for <dur> seconds": [
    "Dremora sine domine vocat <dur> secundas"
  ],
  "make an animal an ally for <dur> seconds": [
    "Fac animal socium <dur> secundas"
  ],
  "you haggle for <mag>% better prices for <dur> seconds": [
    "Pretia <mag>% meliora sunt <dur> secundas"
  ],
  "college boots": [
    "Collegii Caligae"
  ],
  "<mag> points of extra damage to animals": [
    "<mag> plus vulneris animalibus"
  ],
  "heals the caster <mag> points per second": [
    "Salus tua augetur <mag> punctis singulis secundis"
  ],
  "restore <mag> points of stamina": [
    "Redde <mag> staminae"
  ],
  "wearer is muffled and moves silently": [
    "Clam moves"
  ],
  "alteration spells last <mag>% longer for <dur> seconds": [
    "Alteration <mag>% longius durat <dur> secundas"
  ],
  "player can unlock any average or lower lock": [
    "Clausuras medias vel minores aperire potes"
  ],
  "heals the target <mag> points per second, but not undead, atronachs or machines": [
    "Amicum <mag> punctis singulis secundis sanat, nec amortuos, atronacha, vel machinas"
  ],
  "caster heals <20> health per second inside it": [
    "<20> salutis singulis secundis in eo recuperas"
  ],
  "can breathe water for <dur> seconds": [
    "Sub aqua respirare potes <dur> secundas"
  ],
  "<mag>% resistant to poison": [
    "<mag>% resiste veneno"
  ],
  "resist <mag>% of fire damage for <dur> seconds": [
    "Resiste <mag>% ignis vulneris <dur> secundas"
  ],
  "increases light armor skill by <mag> points for <dur> seconds": [
    "Addit ad artem <mag> punctis <dur> secondas"
  ],
  "powerful summoned daedra creatures are are sent back to oblivion": [
    "Daedrae potentes Oblivioni remittuntur"
  ],
  "nearby creatures, including undead, machines and daedra, can be seen through walls for <dur> seconds": [
    "Omnes inimici propinqui per parietes videntur <dur> secundas"
  ],
  "any allies inside the ring will regenerate <mag> health per second": [
    "Amici in circulo accipiunt <mag> salutis singulis secundis"
  ],
  "enter \"the dreamstride\" for an unknown length of time": [
    "Intra \"Somnambulantium\" tempus ignotum"
  ],
  "health is increased by <mag> points for <dur> seconds": [
    "Salus augetur <mag> <dur> secundas"
  ],
  "target moves at 50% speed for <dur> seconds": [
    "Inimicus ad 50% celeritatis <dur> secundas movet"
  ],
  "increases enchanting skill by <mag> points": [
    "Addit ad artem incantantium <mag> punctis"
  ],
  "drains <mag> points of stamina": [
    "Haurit <mag> staminam"
  ],
  "reanimate a weak dead body to fight for you for <dur> seconds": [
    "Revoca corpus debile mortuum ut pro te pugnet <dur> secundas"
  ],
  "a blood-freezing poison that does <mag> points damage to health and stamina for <dur> seconds": [
    "Venenum sangui-glaciei quod facit <mag> vulneris saluti et staminae <dur> secundas"
  ],
  "blood-freezing poison that does <mag> points damage to health and stamina for <dur> seconds": [
    "Venenum sangui-glaciei quod facit <mag> vulneris saluti et staminae <dur> secundas"
  ],
  "this is a blank script effect spell made to look like the fire bolt storm but without damage": [
    "This est blank Script Effect spell made to look like Fire Bolt Storm but without vulneris"
  ],
  "caster is invisible while in shadows for <dur> seconds. activating an object or attacking will break the spell": [
    "Invisibilis esto dum in umbris <dur> secundas"
  ],
  "stamina regenerates <mag>% fastser": [
    "Stamina <mag>% celerius recuperat"
  ],
  "absorb <mag> points of health": [
    "Absorbe <mag> salutis"
  ],
  "does <mag> points of stamina damage": [
    "<mag> vulnus staminae"
  ],
  "burns for <mag> points, and when killing undead, a chance to cause a firey explosion that turns or destroys nearby undead": [
    "Urit <mag> punctos, et dum amortuos necat, casus est ut displosionem ignis faciat quae amortuos propinquos repellat vel necet"
  ],
  "decrease the target's stamina regeneration by <mag>% for <dur> seconds": [
    "Diminuit recuperationem staminae <mag>% <dur> secundas"
  ],
  "equip and cast to learn the candlelight spell": [
    "Equip et cast to learn Candlelight spell"
  ],
  "dismount horse before going through door": [
    "Descende equum ante per portam ire"
  ],
  "restore <mag> points of stamina per second for <dur> seconds": [
    "Redde <mag> staminae singulis secundis <dur> secundas"
  ],
  "stamina is increased by <mag> points for <dur> seconds": [
    "Stamina <mag> augetur <dur> secundas"
  ],
  "illusion spells cost <mag>% less to cast": [
    "Magica inlusionis <mag>% minus magicae poscunt"
  ],
  "creatures and people up to level <mag> nearby won't fight for <dur> seconds": [
    "Animalia hominesque ad gradum <mag> prope te <dur> secundas non pugnabunt"
  ],
  "equip and cast to learn the bound sword spell": [
    "Equip et cast to learn Bound Sword Spell"
  ],
  "directs aicantar's spider to the target": [
    "Dirigit Araneam Aicantaris"
  ],
  "summons a familiar that will charge the nearest enemy and explode": [
    "Familiarem qui inimicum propinquum petet deinde displodet vocat"
  ],
  "dummy faction": [
    "Dummy Faction"
  ],
  "targets take <mag> points of frost damage to health and stamina": [
    "Inimici <mag> puncta gelidi vulneris saluti et staminae accipiunt"
  ],
  "the wooden mask hums with an unfamiliar energy": [
    "Heac persona lignea energia ignota vibrat"
  ],
  "wooden mask hums with an unfamiliar energy": [
    "Heac persona lignea energia ignota vibrat"
  ],
  "for <dur> seconds, opponents in melee range take <mag> points per second fire damage": [
    "<dur> secundas, inimici prope te accipiunt <mag> singulis secundis vulneris ignis"
  ],
  "torygg's horn": [
    "Cornu Toryggis"
  ],
  "stamina regenerates <mag>% faster for <dur> seconds": [
    "Stamina <mag>% celerius recuperat <dur> secundas"
  ],
  "for <dur> seconds, weapon and armor improving is <mag>% better": [
    "<dur> secundas, arma loricaequi augentur <mag>% melius"
  ],
  "all targets in the area that fail to resist are paralyzed for <dur> seconds": [
    "Inimici propinqui qui non resistunt paralytici fiunt <dur> secundas"
  ],
  "equip and cast to learn raise zombie": [
    "Equip et cast to Learn Raise Zombie"
  ],
  "can pull an object to you from a distance": [
    "Rem ad te vocare a distatia"
  ],
  "add it to your inventory or throw it": [
    "Id capere vel iacere potes"
  ],
  "reanimate a very powerful dead body to fight for you for <dur> seconds": [
    "Revoca corpus mortuum potentissimum ut pro te pugnet <dur> secundas"
  ],
  "raises the user's smithing abilities by <mag> points": [
    "Addit ad artem ferrarii <mag> punctis"
  ],
  "drains <mag> points of magicka": [
    "Haurit <mag> magicam"
  ],
  "stamina regenerates <mag> points more slowly": [
    "Stamina <mag> lentius recuperat"
  ],
  "two-handed attacks do <mag>% more damage": [
    "Ictus Ambabus manubus <mag>% plus vulneris faciunt"
  ],
  "weaker summoned daedra are sent back to oblivion": [
    "Daedrae debiliores vocatae remittuntur Oblivioni"
  ],
  "absorb <mag> points of stamina from the target": [
    "Absorbe <mag> staminae ab inimico"
  ],
  "increases light armor skill by <mag> points": [
    "Addit ad artem armorum levium <mag> punctis"
  ],
  "gives bonus 10% critical hit chance vs. dragons": [
    "Casus 10% ictus critici contra dracones"
  ],
  "while the moons are out, burns the target for <mag> points": [
    "Dum lunae adsunt, urit <mag> puncta"
  ],
  "you are able to move more quietly, and opponents that get too close take <8> points poison damage per second": [
    "Quietius moves, et inimici propinqui <5> punctas veneni vulneris singulis secundis accipiunt"
  ],
  "completely restore health": [
    "Salutem omnino replet"
  ],
  "character menu not available to werewolves": [
    "Character menu not available to werewolves"
  ],
  "can breathe underwater for <dur> seconds": [
    "Sub aqua respirare potes <dur> secundas"
  ],
  "lowers the cost and increases the duration of alteration spells for <dur> seconds": [
    "Magicae mutationis pretium diminuitur et tempus augetur <dur> secundas"
  ],
  "target is <mag>% weaker to frost damage for <dur> seconds": [
    "Inimicus <mag>% debilior vulneris glaciei <dur> secundas est"
  ],
  "summoned daedra up to level <mag> are sent back to oblivion": [
    "Daedrae vocatae ad gradum <mag> ad Oblivionem mittuntur"
  ],
  "carrying capacity increased by <mag> points": [
    "<mag> plus ponderis portare potes"
  ],
  "target is <mag>% weaker to shock damage for <dur> seconds": [
    "Inimicus <mag>% debilior electricitatis vulneri <dur> secundas est"
  ],
  "restore <mag> stamina": [
    "Redde <mag> staminae"
  ],
  "concentrated poison damages magicka by <mag> points": [
    "Venenum quod magicam reducit <mag> puncta"
  ],
  "dragons do 25% less melee damage": [
    "Dracones 25% minus vulneris dentibus faciunt"
  ],
  "concentrated poison damages stamina by <mag> points": [
    "Venenum quod staminam reducit <mag> puncta"
  ],
  "regenerate magicka faster for <dur> seconds": [
    "Celerius magicam recupera <dur> secundas"
  ],
  "a blast of cold that does <mag> points per second damage to health and stamina": [
    "Displosio frigidis quae facit <mag> singulis secundis vulneris saluti et staminae"
  ],
  "blast of cold that does <mag> points per second damage to health and stamina": [
    "Displosio frigidis quae facit <mag> singulis secundis vulneris saluti et staminae"
  ],
  "increase the damage and decrease the cost of destruction spells for <dur> seconds": [
    "Vulnus augetur et pretium diminuitur Magicae destructionis <dur> secundas"
  ],
  "resist <mag>% of magic for <dur> seconds": [
    "Resiste <mag>% magicae <dur> secundas"
  ],
  "increase heavy armor skill by <mag> points for <dur> seconds": [
    "Addit ad artem Loricarum gravium <mag> punctis <dur> secondas"
  ],
  "target takes <mag> points of frost damage to health and stamina": [
    "Inimicus accipit <mag> gelidus vulneris saluti et staminae"
  ],
  "sets undead up to level <mag> on fire and makes them flee for <dur> seconds": [
    "Incendit amortuos ad gradum <mag> et facit ut ei effugiant <dur> secundas"
  ],
  "increases how much you can carry by <mag> points": [
    "Pondus portabile <mag> punctis augetur"
  ],
  "summons a familiar for <dur> seconds wherever the caster is pointing": [
    "Familiarem <dur> secundas quo demononstras vocat"
  ],
  "while hagravens can be deadly with their claws in close combat, they can prove equally challenging at a distance, when casting destruction spells": [
    "Etsi Corivicisagae letiferae sunt unguibus, tam fortiter pugnant magica destructionis a distantia"
  ],
  "ancient nord magic arrow": [
    "Ancient Nord Magic Arrow"
  ],
  "confiscated goods key": [
    "Clavis ad Arcam Rerum Publicatarum"
  ],
  "champion": [
    "Vindex"
  ],
  "confiscated goods": [
    "Bona Confiscata"
  ],
  "trolls regenerate health when wounded, but are susceptible to fire": [
    "Trelles salutem recuperant dum vulnerati, sed debiles sunt igni"
  ],
  "health, magicka, and stamina regenerate <25>% faster": [
    "Salus, magica, staminaque <25>% celerius recuperant"
  ],
  "leech": [
    "Haurire"
  ],
  "legend": [
    "Legendum"
  ],
  "instantly absorb <mag> points of health from the target": [
    "Statim absorbe <mag> salutis ab inimico"
  ],
  "whirlwind": [
    "Turbo"
  ],
  "mining": [
    "Mining"
  ],
  "fade": [
    "Pallescere"
  ],
  "fool": [
    "Stultus",
    "stultus, stultī (m)"
  ],
  "dwarven centurion guardian": [
    "Dvemeris Centurio Custos"
  ],
  "finite": [
    "Determinabilis"
  ],
  "kyne": [
    "Kyne"
  ],
  "wrath": [
    "Iracundia"
  ],
  "malfunctioning dwarven sphere": [
    "Dvemeris Sphera Non Fungens"
  ],
  "tempest": [
    "Tempestas"
  ],
  "once a day, they can use the voice of the emperor ability to calm an angry opponent for a short while": [
    "Semel in die, possunt Voce Imperatoris uti ut inimicos molliores facerent"
  ],
  "spells cost <3>% less to cast": [
    "Magica <3>% minus constat"
  ],
  "items can be stored safely in any containers in your own home": [
    "Res in arcis domi impunite poni"
  ],
  "but items kept in any other containers may not be there later when you go to retrieve them": [
    "Sed res in aliis arcis fortasse non manebunt"
  ],
  "fortify spells": [
    "Auge Intcantamenta"
  ],
  "shrine of nocturnal": [
    "Ara Nocturnalis"
  ],
  "\"sweet mother, sweet mother, send your child unto me, for the sins of the unworthy must be baptized in blood and fear.\" -- chant from the dark brotherhood's black sacrament": [
    "\"Mater Dulcis, Mater Dulcis, filium tuum mihi mitte, quod peccata indignorum in sanguine timoreque baptizari debent.\" -- Carmen e Secramento Atro Fraternitas Obscurae"
  ],
  "you are <mag>% harder to detect": [
    "<mag>% difficilius inventu es"
  ],
  "dual wielding potentially doubles melee damage output... but prevents any kind of blocking": [
    "Pugnare doubus armis duplicem vulneris facere potest... sed omnino interponere vetat"
  ],
  "blessing of nocturnal": [
    "Benedictio Nocturnalis"
  ],
  "quit alchemy": [
    "Exi Alchemiam"
  ],
  "fortify illusion & magicka regen": [
    "Adde ad Illusionem & Recuperationem Magicae"
  ],
  "i'm not so easily molded": [
    "Non tam variabilis sum"
  ],
  "i don't want to help you": [
    "Te adiuvare nolo"
  ],
  "trial of conjuration": [
    "Periculum Artis Vocandi"
  ],
  "reduced damage 0.75": [
    "Reduced Damage 0.75"
  ],
  "fortify restoration & magica regen": [
    "Adde ad Magicam Reficiendi & Magicam Recuperandum"
  ],
  "dwarven sphere master": [
    "Dvemeris Sphere Dominus"
  ],
  "fortify destruction & magicka regen": [
    "Adde ad Destructionem & recuperationem Magicae"
  ],
  "strengthened by the blood of deceit": [
    "Sanguis Deceptionis Fortior"
  ],
  "talk to elisif": [
    "Adloquere Elisifem"
  ],
  "dwarven spider guardian": [
    "Dvemeris Aranea Custos"
  ],
  "what could the boy not do": [
    "Quid puer facere non potuit"
  ],
  "fortify conjuration & magicka regen": [
    "Adde ad Artem Vocandi & Recuperationem Magicae"
  ],
  "do you know anything about these robes": [
    "Scisne quid de hoc peplo"
  ],
  "fortify alteration & magicka regen": [
    "Adde ad Mutationem et Recuperationum Magicae"
  ],
  "strengthened by the infamy of its bearer": [
    "Infamiae Auctus"
  ],
  "do you know about draugr embalming blades": [
    "Scisne de ensibus quibus draugr condiuntur"
  ],
  "inhale": [
    "Inhia"
  ],
  "who are you to expect anything": [
    "Quis es ut quidquid exspectas"
  ],
  "wisp core": [
    "Cor Nebulae"
  ],
  "i found this journal in the killer's lair": [
    "Hanc ephemeridem in cubile homicidae inveni"
  ],
  "nightstalker's footsteps": [
    "Vestigia Noctireptoris"
  ],
  "skeleton key perk": [
    "Ars Clavis Adulterati"
  ],
  "he ended up being a lot more trouble than he was worth": [
    "Plus diffulter erat ut meruit"
  ],
  "tg00 pickpocket boost": [
    "TG00 Pickpocket Boost"
  ],
  "whistling mine faction": [
    "Whistling Mine Faction"
  ],
  "elder scroll handling": [
    "Elder Scroll Handling"
  ],
  "you know that dog on the road you asked me to get": [
    "Scisne de illo cane in via quem me rogavisti ut invenirem"
  ],
  "spell tome: close wounds": [
    "Liber Magicus: Claude Vulnera"
  ],
  "chosen enchantment cannot be applied to this item": [
    "Hoc incantamentum in hanc rem poni non potest"
  ],
  "no direct path seen": [
    "Nullam viam rectam vides"
  ],
  "no active quest": [
    "Nullum munus activum"
  ],
  "atronach forge recipe": [
    "Dictamen Fabricae Atronachorum"
  ],
  "hairfemaleimperial1": [
    "HairFemaleImperialis1"
  ],
  "creaturedialoguefox": [
    "CreatureDialogueFox"
  ],
  "horse abilities": [
    "Horse abilities"
  ],
  "weapon recharge": [
    "Weapon Recharge"
  ],
  "ritualreanimatebodyartholder": [
    "RitualReanimateBodyArtHolder"
  ],
  "spell tome: thunderbolt": [
    "Liber Magicus: Tonitrus"
  ],
  "incinerate": [
    "Incinera"
  ],
  "a thunderbolt that does <mag> points of shock damage to health and half that to magicka": [
    "Fulmen quod <mag> vulneris electricitatis saluti et dimidiam eius magicae facit"
  ],
  "thunderbolt that does <mag> points of shock damage to health and half that to magicka": [
    "Fulmen quod <mag> vulneris electricitatis saluti et dimidiam eius magicae facit"
  ],
  "spell tome: incinerate": [
    "Liber Magicus: Incinera"
  ],
  "icy spear": [
    "Hasta Glaciei"
  ],
  "a spear of ice that does <mag> points of frost damage to health and stamina": [
    "Hasta gelidus quae facit <mag> gelidus vulneris saluti et staminae"
  ],
  "spear of ice that does <mag> points of frost damage to health and stamina": [
    "Hasta gelidus quae facit <mag> gelidus vulneris saluti et staminae"
  ],
  "spell tome: icy spear": [
    "Liber Magicus: Hasta Glaciei"
  ],
  "so that's it": [
    "Ergo haec sunt omnia"
  ],
  "there's nothing else to it": [
    "Id nihil alterum postulat"
  ],
  "forge numbers": [
    "Numeros Falsifica"
  ],
  "guild chest": [
    "Arca Sodalitatis"
  ],
  "feed blood": [
    "Feed Blood"
  ],
  "geode vein": [
    "Venam Geodeos"
  ],
  "conjure dremora lord": [
    "Voca Dominum Dremorarum"
  ],
  "there is a <25>% chance of creating a duplicate potion when using your alchemy skill": [
    "<25>% fors potionem duplicandi est cum id facias"
  ],
  "summons a dremora lord for <dur> seconds": [
    "Dominum Dremorarum <dur> secundas vocat"
  ],
  "dremora lord": [
    "Dremora Dominus"
  ],
  "deliver ten cogs to arniel gane": [
    "Fer Decem Denticulos Arnieli Gane"
  ],
  "conjure dragon priest": [
    "Voca Sacerdotem Draconum"
  ],
  "ebony blade absorb health": [
    "Ebony Blade Absorbe Health"
  ],
  "fortify magicka regen": [
    "Adde ad Recuperationem Magicae"
  ],
  "summons a dragon priest for <dur> seconds": [
    "Sacerdos Draconum <dur> secundas vocat"
  ],
  "venomfang skeever": [
    "Venenidens Skeever"
  ],
  "briar heart storage": [
    "Arca Cordumi"
  ],
  "elven shield of blocking": [
    "Alfum Scutum Interponendi"
  ],
  "elven shield of major blocking": [
    "Alfum Scutum Maioris Interponendi"
  ],
  "elven shield of eminent blocking": [
    "Alfum Scutum Eminentis Interponendi"
  ],
  "when low on health, has a chance to heal wearer and damage nearby enemies, or summon a powerful dragon priest defender": [
    "Dum paulum salutis habes, fortasse sanaris et inimici propinqui vulnerantur, ve Sacerdotem Draconis voca"
  ],
  "venomfang poison": [
    "Venomfang Poison"
  ],
  "staff of incineration": [
    "Rudis Incinerationis"
  ],
  "staff of icy spear": [
    "Rudis Hastae Gelidi"
  ],
  "staff of thunderbolts": [
    "Rudis Tonitrus"
  ],
  "audiotemplatedragonquiet": [
    "AudioTemplateDragonQuiet"
  ],
  "spell tome: conjure dremora lord": [
    "Liber Magicus: Voca Dominum Dremorarum"
  ],
  "equipped weapon swings faster for <dur> seconds": [
    "Armum in manu celerius est <dur> secundas"
  ],
  "nearby animals will not attack, and their life forces are visible for <dur> seconds": [
    "Animalia propinqua te non oppugnabunt, et vitalitas eorum visibilis tibi est <dur> secundas"
  ],
  "frost dragon": [
    "Draco Glaciei"
  ],
  "elder dragon": [
    "Draco Antiquior"
  ],
  "blood dragon": [
    "Draco Sanguinis"
  ],
  "ancient dragon": [
    "Draco Antiquus"
  ],
  "dremora valkynaz": [
    "Dremora Valkynaz"
  ],
  "failed": [
    "Frustratum"
  ],
  "started": [
    "Coeptum"
  ],
  "dremora kynreeve": [
    "Dremora Kynreevis"
  ],
  "dremora kynval": [
    "Dremora Kynvallis"
  ],
  "completed": [
    "Factum"
  ],
  "dremora markynaz": [
    "Dremora Markynaz"
  ],
  "dremora caitiff": [
    "Dremora Timidus"
  ],
  "dremora valynaz": [
    "Dremora Valynaz"
  ],
  "riften mercer's house": [
    "Hiati Mercer's House"
  ],
  "whiterun underforge": [
    "Whiterun Underforge"
  ],
  "sprinting": [
    "Sprinting"
  ],
  "fortify armor rating": [
    "Adde ad Loricam"
  ],
  "shrouded armor full set": [
    "Shrouded Armor Full Set"
  ],
  "$effects": [
    "$Effects"
  ],
  "shieldchargedamagestamina": [
    "shieldChargeDamageStamina"
  ],
  "increases armor rating by <mag> points": [
    "Lorica <mag> punctis augetur"
  ],
  "stamina regenerates <mag>% slower": [
    "Stamina <mag>% lentius recuperat"
  ],
  "nightingale armor full set": [
    "Nightingale Armor Full Set"
  ],
  "for <dur> seconds you automatically become invisible while sneaking": [
    "<dur> secundas inivisibilis fias dum repas"
  ],
  "health regenerates <mag>% slower": [
    "Salus recupera <mag>% lentius"
  ],
  "audiocategorysfxfadeduringdialogue": [
    "AudioCategorySFXFadeDuringDialogue"
  ],
  "banish - damage health": [
    "Banish - vulneris Health"
  ],
  "great many": [
    "multī -ae -a",
    "plūrimī -ae -a sup"
  ],
  "hundred": [
    "centum"
  ],
  "part of a building enclosing a courtyard with columns on the inside": [
    "peristylum -ī n"
  ],
  "afraid": [
    "timeō, timēre, timuī",
    "timeō, timēre, timuī, --",
    "trepidō, trepidāre, trepidāvī, trepidātum"
  ],
  "neither…nor": [
    "neque ….neque"
  ],
  "stand at": [
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus"
  ],
  "fixed": [
    "cōnstō, cōnstāre, cōnstitī, cōnstatūrus"
  ],
  "run to": [
    "accurrō, accurrere, accurrī, accursus"
  ],
  "native town": [
    "patria -ae f"
  ],
  "twenty eight": [
    "duodētrīgintā"
  ],
  "twenty nine": [
    "ūndētrīgintā"
  ],
  "1st": [
    "kalendae -ārum f pl"
  ],
  "whole of": [
    "tōtus -a -um",
    "ūniversus, ūniversa, ūniversum"
  ],
  "awake": [
    "vigilō, vigilāre, vigilāvī, vigilātus"
  ],
  "seated": [
    "cōnsīdō, cōnsīdere, cōnsēdī, cōnsessus"
  ],
  "front": [
    "prior, prior, prius"
  ],
  "goods": [
    "merx -rcis f"
  ],
  "seven oxen": [
    "septentriōnēs -um m pl"
  ],
  "open sea": [
    "altum -ī n",
    "pontus, pontī"
  ],
  "second time": [
    "iterum",
    "iterum (adv.)"
  ],
  "in excess": [
    "supersum, superesse, superfuī, ------"
  ],
  "missing": [
    "dēsum, dēesse, dēfuī, -----"
  ],
  "bring to pass": [
    "efficiō, efficere, effēcī, effectum"
  ],
  "same": [
    "īdem eadem idem",
    "īdem, eadem, idem"
  ],
  "market": [
    "forum -ī"
  ],
  "don’t": [
    "nōlī -īte +īnf"
  ],
  "fond of": [
    "dīligō, dīligere, dīlēxī, dīlēctum"
  ],
  "obliged": [
    "dēbeō, dēbēre, dēbuī, dēbitus"
  ],
  "it shames me": [
    "pudeō, pudēre, (puduī aut puditum est)"
  ],
  "i am ashamed": [
    "pudeō, pudēre, (puduī aut puditum est)"
  ],
  "surprised": [
    "mīror, mīrārī, mīrātus sum"
  ],
  "null": [
    "aurem",
    "adversum",
    "ūtilis -e",
    "vēlōciter",
    "memoria -ae",
    "rēgia -ae f",
    "Olympus -ī m",
    "pluit: pluere",
    "rēgīna, -ae f",
    "ēsuriō, ēsurīre",
    "participium, -ī",
    "caeruleus -a -um",
    "incendium -ī (n)",
    "marmor -oris (n)",
    "mātrimōnium -ī n",
    "optātīvus -a -um",
    "Pygmaliōn, -ōnis",
    "senātor -ōris (m)",
    "comparātiō -ōnis f",
    "coniūnctiō -ōnis f",
    "cōpulātīvus -a -um",
    "appellātīvus -a -um",
    "imperātum, imperātī",
    "interiectiō -ōnis f",
    "praepositiō -ōnis f",
    "sitiō, sitīre, sitīvī, ---",
    "mūgiō, mūgīre, mūgīvī, mūgītus",
    "expellō, expellere, expulī, expulsum",
    "excurrō, excurrere, excurrī, excursus",
    "sacrificō, sacrificāre, sacrificāvī, sacrificātus"
  ],
  "run together": [
    "concurrō, concurrere, concurrī, concursus"
  ],
  "this place": [
    "hūc"
  ],
  "that place": [
    "eō",
    "illūc"
  ],
  "do harm": [
    "noceō, nocēre, nocuī, nocitūrus"
  ],
  "in distress": [
    "labōrō, labōrāre, labōrāvī, labōrātum"
  ],
  "a slave": [
    "serviō, servīre, servīvī, servītus"
  ],
  "lost": [
    "pereō, perīre, periī, peritum"
  ],
  " underworld": [
    "Īnferī, Īnferōrum"
  ],
  "aghast": [
    "stupeō, stupēre, stupuī, ----"
  ],
  "put ashore": [
    "expōnō, expōnere, exposuī, expositus"
  ],
  "look askance at": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "cast an evil eye upon": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "be prejudiced against": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "prejudiced against": [
    "invideō, invidēre, invīdī, invisus"
  ],
  "throw to": [
    "adiciō, adicere, adiēcī, adiectus"
  ],
  "define": [
    "fīniō, fīnīre, fīnīvī, fīnītus"
  ],
  "mark out boundaries": [
    "fīniō, fīnīre, fīnīvī, fīnītus"
  ],
  "in charge": [
    "praesum, praeesse, praefuī, praefutūrus"
  ],
  "good deal": [
    "aliquantum"
  ],
  "lit": [
    "ignōscō, ignōscere, ignōvī, ignōtus"
  ],
  "the bottom": [
    "funditus"
  ],
  "cutting-down": [
    "caedēs -is f"
  ],
  "sharp edge": [
    "aciēs -ēī f"
  ],
  "day before": [
    "prīdiē"
  ],
  "tenth part of a legion": [
    "cohors -rtis f"
  ],
  "poetic foot": [
    "dactylus, -ī (m)"
  ],
  "taste of": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "have a sense of taste": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "have sense": [
    "sapiō, sapere, sapīvī, ----"
  ],
  "ha": [
    "ēia"
  ],
  "how do you say": [
    "Quōmodo dīcitur"
  ],
  "i live in": [
    "Habito in"
  ],
  "my name is": [
    "Nomen mihi est"
  ],
  "in good health": [
    "salveō, salvēre"
  ],
  "mistaken": [
    "errō, errāre, errāvī, errātum"
  ],
  "neither...nor": [
    "neque...neque or nec...nec"
  ],
  "actual": [
    "ipse, ipsa, ipsum"
  ],
  "living creature": [
    "animal, animālis"
  ],
  "speak to": [
    "appellō, appellāre, appellāvī, appellātum"
  ],
  "either...or": [
    "aut...aut"
  ],
  "be seen": [
    "videor, vidērī, vīsus sum"
  ],
  "seen": [
    "videor, vidērī, vīsus sum"
  ],
  "pick out": [
    "legō, legere, lēgī, lēctum"
  ],
  "if...not": [
    "nisi (conj)"
  ],
  "both...and": [
    "et...et"
  ],
  "pay the penalty": [
    "poenās dare"
  ],
  "deprived of": [
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "free from": [
    "careō, carēre, caruī, caritūrum (+ abl. of seperation)"
  ],
  "bid": [
    "iubeō, iubēre, iussī, iussum"
  ],
  "common people": [
    "plēbs, plēbis",
    "vulgus, vulgī",
    "plēbs, plēbis (f.)",
    "vulgus, vulgī (n., sometimes m.)"
  ],
  "labor": [
    "labōrō, labōrāre, labōrāvī, labōrātum"
  ],
  "say that...not": [
    "negō, negāre, negāvī, negātum"
  ],
  "hope for": [
    "spērō, spērāre, spērāvī, spērātum"
  ],
  "make open": [
    "patefaciō, patefacere, patefēcī, patefactum"
  ],
  "wise man": [
    "sapiēns, gen. sapientis"
  ],
  "wise woman": [
    "sapiēns, gen. sapientis"
  ],
  "adv. before superlatives: as ... as possible": [
    "quam (conj. after comparatives)"
  ],
  "gods": [
    "caelestis, caeleste",
    "superī, superōrum (m. pl.)"
  ],
  "in order that...not": [
    "nē (adv. and conj. with subjunct. of command and purpose)"
  ],
  "that...not": [
    "nē (adv. and conj. with subjunct. of command and purpose)"
  ],
  "be sure": [
    "vērō (adv.)"
  ],
  "not...even": [
    "nē...quidem"
  ],
  "such a degree": [
    "adeō",
    "tam (adv. with adjs. and advs.)"
  ],
  "so...as": [
    "tam...quam"
  ],
  "put together or into": [
    "condō, condere, condidī, conditum"
  ],
  "strive": [
    "contendō, contendere, contendī, contentum"
  ],
  "lessen": [
    "minuō, minuere, minuī, minūtum"
  ],
  "whether...or": [
    "utrum...an"
  ],
  "become acquainted with": [
    "cognōscō, cognōscere, cognōvī, cognitum"
  ],
  "take oneself": [
    "sē cōnferre"
  ],
  "single time": [
    "semel (adv.)"
  ],
  "small copper coin comparable to a penny": [
    "as, assis"
  ],
  "be envious": [
    "invideō, invidēre, invīdī, invīsum"
  ],
  "look at with envy": [
    "invideō, invidēre, invīdī, invīsum"
  ],
  "jealous of": [
    "invideō, invidēre, invīdī, invīsum"
  ],
  "bring to": [
    "adferō, adferre, attulī, allātum"
  ],
  "carry back": [
    "referō, referre, rettulī, relātum"
  ],
  "to not...wish": [
    "nōlō, nōlle, nōluī"
  ],
  "not...wish": [
    "nōlō, nōlle, nōluī"
  ],
  "want more": [
    "mālō, mālle, māluī"
  ],
  "ah": [
    "heu (interj.)"
  ],
  "live in the country": [
    "rūsticor, rūsticārī, rūsticātus sum"
  ],
  "work at": [
    "mōlior, mōlīrī, mōlītus sum"
  ],
  "be a slave to": [
    "serviō, servīre, servīvī, servītum + dat"
  ],
  "a slave to": [
    "serviō, servīre, servīvī, servītum + dat"
  ],
  "slave to": [
    "serviō, servīre, servīvī, servītum + dat"
  ],
  "be leniant to": [
    "parcō, parcere, pepercī, parsūrum + dat"
  ],
  "leniant to": [
    "parcō, parcere, pepercī, parsūrum + dat"
  ],
  "be obedient to": [
    "pāreō pārēre, pāruī + dat"
  ],
  "obedient to": [
    "pāreō pārēre, pāruī + dat"
  ],
  "be pleasing to": [
    "placeō, placēre, placuī, placitum + dat"
  ],
  "pleasing to": [
    "placeō, placēre, placuī, placitum + dat"
  ],
  "direct one's zeal to": [
    "studeō, studēre, studuī + dat"
  ],
  "grant pardon to": [
    "ignōscō, ignōscere ignōvī, ignōtum + dat"
  ],
  "have good taste": [
    "sapiō, sapere, sapīvī"
  ],
  "smile upon": [
    "subrīdeō, subrīdēre, subrīsī, subrīsum"
  ],
  "succeed in urging": [
    "persuādeō, persuādēre, persuāsī, persuāsum + dat"
  ],
  "give orders to": [
    "imperō, imperāre, imperāvī, imperātum + dat"
  ],
  "marvel at": [
    "mīror, mīrārī, mīrātus sum"
  ],
  "drive or bring together": [
    "cōgō, cōgere, coēgī, coāctum (cum + agō)"
  ],
  "go in": [
    "ineō, inīre, iniī, initum"
  ],
  "go up against": [
    "obeō, obīre, obiī, obitum"
  ],
  "pass away": [
    "pereō, perīre, periī, peritum"
  ],
  "destroyed": [
    "pereō, perīre, periī, peritum"
  ],
  "complain": [
    "queror, querī, questus sum"
  ],
  "afraid for": [
    "metuō, metuere, metuī"
  ],
  "hang up": [
    "suspendō, suspendere, suspendī, suspēnsum"
  ],
  "impede": [
    "impediō, impedīre, impedīvī, impedītum"
  ],
  "go accross": [
    "trānseō, trānsīre, trānsiī, trānsitum"
  ],
  "pour a libation of": [
    "lībō, lībāre, lībāvī, lībātum"
  ],
  "spend or occupy the night": [
    "pernoctō, pernoctāre, pernoctāvī, pernoctātum"
  ],
  "as many ... as": [
    "tot (indecl. adj.), tot ... quot"
  ],
  "show reverence for": [
    "vereor, verērī, veritus sum"
  ],
  "afraid of": [
    "vereor, verērī, veritus sum"
  ],
  "introduce a question whose answer is uncertain": [
    "-ne"
  ],
  "to the foot of": [
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "the foot of": [
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "foot of": [
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "at the foot of": [
    "sub (+abl. w/ verbs of rest or +acc. w/ verbs of motion)"
  ],
  "letter of the alphabet": [
    "littera, litterae (f)"
  ],
  "thank someone": [
    "grātiās agere (+dat)"
  ],
  "give thanks to": [
    "grātiās agere (+dat)"
  ],
  "some...others": [
    "aliī . . . aliī"
  ],
  "e.g": [
    "iste, ista, istud"
  ],
  "latter": [
    "hic, haec, hoc"
  ],
  "fagot": [
    "fascis, fascis"
  ],
  "falling down": [
    "ruīna, ruīnae"
  ],
  "forager": [
    "pābulātor, pābulātōris"
  ],
  "form of protective shed or breastwork": [
    "pluteus, pluteī"
  ],
  "friendly land": [
    "pācātum, pācātī"
  ],
  "gaulish or britannic charioteer": [
    "essedārius, essedariī"
  ],
  "leap": [
    "saltus, saltūs"
  ],
  "leaping": [
    "saltus, saltūs",
    "tripudium, tripudiī or tripudī"
  ],
  "light boat": [
    "scapha, scaphae"
  ],
  "magistrate": [
    "magistrātus, magistrātūs"
  ],
  "partaker in guilt": [
    "correus, correī"
  ],
  "joint criminal": [
    "correus, correī"
  ],
  "pasture. the action of collecting fodder or food": [
    "pābulātiō, pābulātiōnis"
  ],
  "sum of money deposited in pledge by two individuals involved in a suit": [
    "sacrāmentum, sacrāmentī"
  ],
  "troop": [
    "turma, turmae"
  ],
  "union for a common purpose": [
    "societās, societātis"
  ],
  "available": [
    "suppetō, suppetere, suppetīvī, suppetītum"
  ],
  "deprived of parents": [
    "orbō, orbāre, orbāvī, orbātum"
  ],
  "thirsty": [
    "bibulus, bibula, bibulum"
  ],
  "on the following day": [
    "postrīdiē"
  ],
  "on the next day": [
    "postrīdiē"
  ],
  "main thing": [
    "summa, summae"
  ],
  " sea": [
    "salum, salī"
  ],
  "act of taking possession of": [
    "possessiō, possessiōnis"
  ],
  "senate or parliament": [
    "senātus, senātūs"
  ],
  "space of three days": [
    "trīduum, trīduī"
  ],
  "fill completely": [
    "cōnferciō, cōnfercīre, cōnfersī, cōnfertus"
  ],
  "descent": [
    "dēscensus, dēscensūs"
  ],
  "hospitable reception": [
    "hospitium, hospitiī"
  ],
  "license": [
    "licentia, licentiae"
  ],
  "long trumpet over 1 meter in length. tube": [
    "tuba, tubae"
  ],
  "political faction": [
    "factiō, factiōnis"
  ],
  "scout a spy": [
    "explōrātor, explōrātōris"
  ],
  "four-wheeled baggage cart": [
    "carrus, carrī"
  ],
  "conscious of. know well": [
    "cōnsciō, cōnscīre, cōnscīvī, cōnscītum"
  ],
  "roof of the mouth": [
    "palātum, palātī"
  ],
  "block or piece of marble": [
    "marmor, marmoris"
  ],
  "plaintiff or complainant": [
    "quaerēns, quaerentis"
  ],
  "proposed law": [
    "rogātiō, rogātiōnis"
  ],
  "nostril": [
    "nāris, nāris"
  ],
  "large domestic animal suitable for drawing a plow: an ox": [
    "armentum, armentī"
  ],
  "bad moral quality": [
    "nēquitia, nēquitiae"
  ],
  "bad quality": [
    "malitia, malitiae"
  ],
  "buck": [
    "hircus, hircī"
  ],
  "sepulchering": [
    "sepultūra, sepultūrae"
  ],
  "burnt offering wholly consumed by fire": [
    "holocaustum, holocaustī"
  ],
  "censuring": [
    "vituperātiō, vituperātiōnis"
  ],
  "champion or defender ": [
    "prōpugnātor, prōpugnātōris"
  ],
  "coat of mail": [
    "lōrīca, lōrīcae"
  ],
  "concubine of a married man": [
    "paelex, paelicis"
  ],
  "cornfield": [
    "seges, segetis"
  ],
  "cunning device": [
    "calumnia, calumniae"
  ],
  "danewort": [
    "actē, actēs"
  ],
  "defending": [
    "mūnītiō, mūnītiōnis"
  ],
  "denying": [
    "negātiō, negātiōnis"
  ],
  "disgraceful action": [
    "flāgitium, flāgitiī"
  ],
  "divine announcement": [
    "ōrāculum, ōrāculī"
  ],
  "false oath": [
    "periūrium, periūriī"
  ],
  "funeral pile": [
    "rogus, rogī"
  ],
  "furnishing": [
    "ornātus, ornātūs"
  ],
  "garment of several pieces sewed together": [
    "centō, centōnis"
  ],
  "patchwork": [
    "centō, centōnis"
  ],
  "goad": [
    "stimulus, stimulī"
  ],
  "going around or about": [
    "ambitiō, ambitiōnis"
  ],
  "going in or into": [
    "introitus, introitūs"
  ],
  "greek dry measure similar to a bushel": [
    "medimnum, medimnī"
  ],
  "grove sacred to a deity": [
    "lūcus, lūcī"
  ],
  "helmet": [
    "galea, galeae"
  ],
  "holding on": [
    "tenor, tenōris"
  ],
  "holy day": [
    "fēriae, fēriārum"
  ],
  "festival": [
    "fēriae, fēriārum"
  ],
  "holiday": [
    "fēriae, fēriārum"
  ],
  "jet of water": [
    "tullius, tulliī"
  ],
  "juger": [
    "iūgerum"
  ],
  "roman unit of area": [
    "iūgerum"
  ],
  "kind of little worm or larva": [
    "galba, galbae"
  ],
  "kind of owl": [
    "otus, otī"
  ],
  "large earthenware vessel": [
    "dōlium, dōliī"
  ],
  "laying under": [
    "subiectus, subiectūs"
  ],
  "lock of wool": [
    "mallus, mallī"
  ],
  "long pole": [
    "longurius, longuriī"
  ],
  "low seat or bench": [
    "subsellium, subselliī"
  ],
  "middle state": [
    "mediocritās, mediocritātis"
  ],
  "nail": [
    "clāvus, clāvī"
  ],
  "period of five years": [
    "quīnquennium, quīnquenniī"
  ],
  "petty king": [
    "rēgulus, rēgulī"
  ],
  "pine wood": [
    "taeda, taedae"
  ],
  "place to keep things in": [
    "receptāculum, receptāculī"
  ],
  "place where sacred objects are kept": [
    "sacrārium, sacrāriī"
  ],
  "playing": [
    "lūsus, lūsūs"
  ],
  "plumed metal helmet": [
    "cassis, cassidis"
  ],
  "pointer": [
    "index, indicis"
  ],
  "portion of land granted by the state": [
    "accepta, acceptae"
  ],
  "promise secured by bail": [
    "vadimōnium, vadimōniī"
  ],
  "quiver": [
    "pharetra, pharetrae"
  ],
  "renewal of war": [
    "rebelliō, rebelliōnis"
  ],
  "returning": [
    "reditus, reditūs"
  ],
  "roman college of priests who dealt with enemies": [
    "fētiāles, fētiālis"
  ],
  "roof-tile": [
    "tēgula, tēgulae"
  ],
  "sacrificial or solemn feast": [
    "daps, dapis"
  ],
  "scorpion": [
    "scorpius, scorpiī",
    "scorpiō, scorpiōnis"
  ],
  "shallow": [
    "vadum, vadī"
  ],
  "sharpened point": [
    "acūmen, acūminis"
  ],
  "shipwreck": [
    "naufragium, naufragiī"
  ],
  "short time ago": [
    "dūdum"
  ],
  "squad of soldiers sharing a single tent": [
    "contubernium, contuberniī"
  ],
  "strong pole or bar used for leverage": [
    "vectis, vectis"
  ],
  "swearing together": [
    "coniūrātiō, coniūrātiōnis"
  ],
  "taking forth": [
    "prōmptus, prōmptūs"
  ],
  "throwing together": [
    "coniectus, coniectūs"
  ],
  "woodpecker": [
    "pīcus, pīcī"
  ],
  "young cow": [
    "vitula, vitulae",
    "iuvenca, iuvencae"
  ],
  "steward of a farm": [
    "vīlicus, vīlicī"
  ],
  "steward of an estate": [
    "vīlicus, vīlicī"
  ],
  "fully supplied": [
    "suppeditō, suppeditāre, suppeditāvī, suppeditātum"
  ],
  " in abundance": [
    "suppeditō, suppeditāre, suppeditāvī, suppeditātum"
  ],
  "greatly frightened": [
    "extimēscō, extimēscere, extimuī"
  ],
  "propitious to": [
    "addīcō, addīcere, addīxī, addictum"
  ],
  "unable": [
    "nequeō, nequīre, nequiī, nequitum"
  ],
  "very able or more able": [
    "praevaleō, praevalēre, praevaluī, praevalitum"
  ],
  "regent holding the royal power between the death of one king and the election of another": [
    "interrēx, interrēgis"
  ],
  "no purpose": [
    "nēquīquam"
  ],
  "proneness to anger": [
    "īrācundia, īrācundiae"
  ],
  "look over from a height": [
    "dēspectō, dēspectāre, dēspectāvī, dēspectātus"
  ],
  "look down at from a height": [
    "dēspectō, dēspectāre, dēspectāvī, dēspectātus"
  ],
  "double company of soldiers": [
    "manipulus, manipulī"
  ],
  "unit of dry measure of about a peck or 9l": [
    "modius, modiī"
  ],
  "sixth month of the roman calendar or eighth month of the gregorian calendar": [
    "sextīlis, sextīle"
  ],
  "mountainsdwelling in the mountains": [
    "montānus, montāna, montānum"
  ],
  "father's brother": [
    "patruus, patruī"
  ],
  "man who became governor of a province or a military commander following a term as consul": [
    "prōcōnsul, prōcōnsulis"
  ],
  "place under": [
    "subpōnō, subpōnere, subposuī, subpositum",
    "suppōnō, suppōnere, supposuī, suppositum"
  ],
  "lowest part of the trunk of a plant": [
    "stirps, stirpis"
  ],
  "military cloak": [
    "sagum, sagī"
  ],
  "some extent": [
    "aliquid",
    "aliquātenus"
  ],
  "act of buying or purchasing": [
    "ēmptiō, ēmptiōnis"
  ],
  "act of forgetting": [
    "oblīviō, oblīviōnis"
  ],
  "act of going aside": [
    "sēcessiō, sēcessiōnis"
  ],
  "act of laying waste or plundering": [
    "populātiō, populātiōnis"
  ],
  "act of looking up or upwards": [
    "suspectus, suspectūs"
  ],
  "act of sharing or parting": [
    "partītiō, partītiōnis"
  ],
  "act of turning round or revolving": [
    "conversiō, conversiōnis"
  ],
  "bark of a tree": [
    "cortex, corticis"
  ],
  "bark of a cork tree": [
    "cortex, corticis"
  ],
  "indus river": [
    "Indus, Indī"
  ],
  "keel of a ship": [
    "carīna, carīnae"
  ],
  "moray eel or the lamprey": [
    "murēna, murēnae"
  ],
  "number three": [
    "trīnitās, trīnitātis"
  ],
  "skin or hide of an animal stripped off": [
    "spolium, spoliī"
  ],
  "south wind": [
    "auster, austrī"
  ],
  "spirits of dead ancestors": [
    "mānēs, mānium"
  ],
  "state of being out of one's senses": [
    "āmentia, āmentiae"
  ],
  "tribe or century to which it fell by lot to vote first in the comitia": [
    "praerogātīva, praerogātīvae"
  ],
  "wall of a house or room": [
    "pariēs, parietis"
  ],
  "watery part that flows out in pressing olives": [
    "amurca, amurcae"
  ],
  "wool shorn from a sheep": [
    "vellus, velleris"
  ],
  "feel annoyance or reluctance at": [
    "pigeō, pigēre, piguī, pigitum"
  ],
  "repent of": [
    "pigeō, pigēre, piguī, pigitum"
  ],
  "whatever place": [
    "quōnam"
  ],
  "write back in reply": [
    "rescrībō, rescrībere, rescrīpsī, rescrīptum"
  ],
  "kind of grain": [
    "trīticum, trīticī"
  ],
  "sinew": [
    "nervus, nervī"
  ],
  "blessed person": [
    "benedictus, benedictī"
  ],
  "kind or courteous": [
    "indulgeō, indulgēre, indulsī, indultum"
  ],
  "rodent": [
    "rōdēns, rōdēntis"
  ],
  "burial mound": [
    "būstum, būstī"
  ],
  "camel": [
    "camēlus, camēlī"
  ],
  "cheating": [
    "fraus, fraudis"
  ],
  "confession": [
    "cōnfessiō, cōnfessiōnis"
  ],
  "constitution": [
    "cōnstitūtiō, cōnstitūtiōnis"
  ],
  "dazzling or glossy whiteness": [
    "candor, candōris"
  ],
  "deep sleep": [
    "sopor, sopōris"
  ],
  "kind of snake or serpent": [
    "dracō, dracōnis"
  ],
  "genius loci": [
    "daemon, daemōnis"
  ],
  "lar": [
    "daemon, daemōnis"
  ],
  "protective spirit or godling of a place or household": [
    "daemon, daemōnis"
  ],
  "greedy desire for possessions or gain": [
    "avāritia, avāritiae"
  ],
  "liberator": [
    "līberātor, līberātoris"
  ],
  "listening": [
    "audītus, audītūs",
    "audītiō, audītiōnis"
  ],
  "loan": [
    "crēditum, crēditī"
  ],
  "notice sent to a higher tribunal or judge": [
    "apostolus, apostolī"
  ],
  "rectangular piece of cloth worn by ladies in ancient rome and fastened with brooches": [
    "palla, pallae"
  ],
  "small room": [
    "cella, cellae"
  ],
  "strengthening": [
    "firmāmentum, firmāmentī"
  ],
  "sweet taste": [
    "dulcēdō, dulcēdinis"
  ],
  "warding off or averting by prayer": [
    "dēprecātiō, dēprecātiōnis"
  ],
  "evident or apparent": [
    "compāreō, compārēre, compāruī, compāritum"
  ],
  "power of discernment": [
    "intellegentia, intellegentiae"
  ],
  "fifth month of the roman calendar": [
    "iūlius, iūlia, iūlium"
  ],
  "getting ready": [
    "apparātus, apparātūs"
  ],
  "act of approving": [
    "approbātiō, approbātiōnis"
  ],
  "act of doing": [
    "effectus, effectūs"
  ],
  "act of seeing or looking at something": [
    "aspectus, aspectūs"
  ],
  "act of sitting together with": [
    "cōnsessus, cōnsessūs"
  ],
  "act of tilling or cultivating": [
    "cultus, cultūs"
  ],
  "act of unfolding or unrolling": [
    "explicātiō, explicātiōnis"
  ],
  "decision of an arbiter": [
    "arbitrium, arbitriī"
  ],
  "good quality of something": [
    "bonitās, bonitātis"
  ],
  "office of a censor": [
    "cēnsūra, cēnsūrae"
  ],
  "state of being poor": [
    "paupertās, paupertātis"
  ],
  "consecrate or dedicate": [
    "cōnsecrō, cōnsecrāre, cōnsecrāvī, cōnsecrātum"
  ],
  "decimate": [
    "decimō, decimāre, decimāvī, decimātum"
  ],
  "navel": [
    "umbilīcus, umbilīcī"
  ],
  "ankle or anklebone": [
    "tālus, tālī"
  ],
  "laying claim to": [
    "vindicia, vindiciae"
  ],
  "apparatus of the cradle": [
    "incūnābulum, incūnābulī"
  ],
  "district in macedonia": [
    "Pīeria, Pīeriae"
  ],
  "southwestern region of thessaly": [
    "Dolopia, Dolopiae"
  ],
  "king of thrace": [
    "Haemus, Haemī"
  ],
  "son of boreas": [
    "Haemus, Haemī"
  ],
  "maiden beloved by pyramus": [
    "Thisbē, Thisbēs"
  ],
  "nereid": [
    "Nērēis, Nērēidis"
  ],
  "nymph of the greek mythology": [
    "Arethūsa, Arethūsae"
  ],
  "woodnymph": [
    "Dryas, Dryadis"
  ],
  "dryad": [
    "Dryas, Dryadis"
  ],
  "mythical wife of haemus": [
    "Rhodopē, Rhodopēs"
  ],
  "unit of volume and weight": [
    "congius, congii"
  ],
  "roman market day": [
    "nūndina, nūndinae"
  ],
  "gladiator bearing thracian equipment": [
    "thraex, thraecis"
  ],
  "down of plants": [
    "lānūgō, lānūginis"
  ],
  "callous or thickskinned": [
    "calleō, callēre, calluī"
  ],
  "bishop who heads or presides over a group of dioceses called a province": [
    "archiepiscopus, archiepiscopī"
  ],
  "predicament": [
    "praedicāmentum, praedicāmentī"
  ],
  "ham of the knee": [
    "poples, poplitis"
  ],
  "hough": [
    "poples, poplitis"
  ],
  "hock": [
    "poples, poplitis"
  ],
  "proposition that follows necessarily": [
    "cōnexum, cōnexī"
  ],
  "necessary consequence": [
    "cōnexum, cōnexī"
  ],
  "logical entailment": [
    "cōnexum, cōnexī"
  ],
  "type of wart": [
    "thymus, thymī"
  ],
  "male given name cognate to louis": [
    "Lūdovīcus, Lūdovīcī"
  ],
  "small pirate galley": [
    "myoparō, myoparōnis"
  ],
  "lowing": [
    "mūgītus, mūgītūs"
  ],
  "goddess of morning or dawn": [
    "Mātūta, Mātūtae"
  ],
  "goddess of shepherds": [
    "Palēs, Palis"
  ],
  " lip": [
    "lābellum, lābellī"
  ],
  "basin or bowl for water or for mixing": [
    "crātēr, crātēris"
  ],
  "being near": [
    "īnstantia, īnstantiae"
  ],
  "papal garment": [
    "balteus, balteī"
  ],
  "bird-catcher": [
    "auceps, aucupis"
  ],
  "bondsman": [
    "spōnsor, spōnsōris"
  ],
  "boxer": [
    "pugil, pugilis"
  ],
  "bristle": [
    "saeta, saetae"
  ],
  "bull calf": [
    "vitulus, vitulī"
  ],
  "burning coal": [
    "prūna, prūnae"
  ],
  "carpenter’s square": [
    "nōrma, nōrmae"
  ],
  "celtiberian tribe settled on the plains of the central duero valley": [
    "Vaccaeī, Vaccaeōrum"
  ],
  "celtic tribe of gallia belgica": [
    "Lingonēs, Lingonum",
    "Suessiōnēs, Suessiōnum"
  ],
  "celtic tribe of gallia lugdunensis": [
    "Aulercī, Aulercōrum",
    "Parīsiī, Parīsiōrum"
  ],
  "certain amount": [
    "nonnihil"
  ],
  "chamber in its various senses": [
    "camera, camerae"
  ],
  "vault.a deliberative body": [
    "camera, camerae"
  ],
  "chestnut tree": [
    "castanea, castaneae"
  ],
  "city in hispania tarraconensis": [
    "Ilerda, Ilerdae"
  ],
  "city in sicily": [
    "Helōrum, Helōrī"
  ],
  "city of apulia separated from the coast of adriatic by a saltwater lake": [
    "Salapia, Salapiae"
  ],
  "city of argolis and dwelling of the mythical king agamemnon": [
    "Mycēnae, Mycēnārum"
  ],
  "city of bruttium": [
    "Vibō, Vibōnis"
  ],
  "city of illyricum": [
    "Lissus, Lissī"
  ],
  "city of macedonia situated on the left bank of the river strymon": [
    "Amphipolis, Amphipolis"
  ],
  "city of the hernici in latium situated between anagnia and frusino": [
    "Ferentīnum, Ferentīnī"
  ],
  "city of thessaly and legendary residence of admetus and eumelus": [
    "Pherae, Pherārum"
  ],
  "city of thessaly where caesar defeated pompey": [
    "Pharsālus, Pharsālī"
  ],
  "city of thrace situated on the river schoenus and famous for its wine": [
    "Marōnēa, Marōnēae"
  ],
  "city on the northern coast of sicily": [
    "Hīmera, Hīmerae",
    "Solūs, Solūntis"
  ],
  "cognomen famously held by:marcus livius salinator": [
    "salīnātor, Salīnātōris"
  ],
  "roman consul": [
    "salīnātor, Salīnātōris"
  ],
  "considerable town of umbria": [
    "Pisaurum, Pisaurī"
  ],
  "couch for the gods": [
    "pulvīnar, pulvīnaris"
  ],
  "child of one's father's brother": [
    "patruēlis, patruēlis"
  ],
  "coward": [
    "murcus, murcī"
  ],
  "escape military service": [
    "murcus, murcī"
  ],
  "dagger": [
    "pūgiō, pūgiōnis"
  ],
  "dalmatian tribe mentioned by pliny": [
    "Dassaretiī, Dassaretiōrum"
  ],
  "debt slave": [
    "addictus, addictī"
  ],
  "person who has been bound as a slave to his creditor": [
    "addictus, addictī"
  ],
  "destructive insect larva that attacks household items such as books or clothing": [
    "tinea, tineae"
  ],
  "disorderly motion": [
    "concursātiō, concursātiōnis"
  ],
  "dissembling": [
    "dissimulātiō, dissimulātiōnis"
  ],
  "dissolute man": [
    "asōtus, asōtī"
  ],
  "fallow deer": [
    "damma, dammae"
  ],
  "feaster": [
    "epulō, epulōnis"
  ],
  "female deer": [
    "cerva, cervae"
  ],
  "female given name": [
    "Fidēs, Fidēī",
    "Marīa, Marīae",
    "Eurydicē, Eurydicēs"
  ],
  "female snake": [
    "colubra, colubrae"
  ],
  "feminine praenomen": [
    "Marcia, Marciae"
  ],
  "festival held in honor of the goddess minerva": [
    "Quīnquātrūs, Quīnquātruum"
  ],
  "festival of the winter solstice originally celebrated for three days beginning december 17th": [
    "Sāturnālia, Sāturnālium"
  ],
  "figurative use of a word": [
    "tropus, tropī"
  ],
  "trope": [
    "tropus, tropī"
  ],
  "first cousin": [
    "cōnsōbrīnus, cōnsobrīnī"
  ],
  "flat cake": [
    "placenta, placentae"
  ],
  "fortuneteller": [
    "hariolus, hariolī"
  ],
  "four horse team": [
    "quadrīgae, quadrīgārum"
  ],
  "girdle which encircles the hips": [
    "cingulum, cingulī"
  ],
  "grape-gathering": [
    "vīndēmia, vīndēmiae"
  ],
  "groom": [
    "spōnsus, spōnsī"
  ],
  "half-unit": [
    "sēmis, sēmissis"
  ],
  "hand-sling )": [
    "funda, fundae"
  ],
  "hazel or filbert shrub": [
    "corylus, corylī"
  ],
  "herdsman": [
    "bubulcus, bubulcī"
  ],
  "hired servant": [
    "metellus, metellī"
  ],
  "hook": [
    "hāmus, hāmī"
  ],
  "kind of bean with an edible pod": [
    "phasēlus, phasēlī"
  ],
  "kind of clover": [
    "cytisus, cytisī"
  ],
  "kind of duck": [
    "pēnelops, pēnelopis"
  ],
  "kind of earthen vase used in sacrifices": [
    "cernos, cernī"
  ],
  "kind of porter": [
    "cancellārius, cancellāriī"
  ],
  "kind of sleeveless cloak or mantle with an opening for the head": [
    "paenula, paenulae"
  ],
  "knobbed stick": [
    "fūstis, fūstis"
  ],
  "cudgel": [
    "fūstis, fūstis"
  ],
  "latin surname": [
    "Crista, Cristae"
  ],
  "ligurian tribe of italy": [
    "Apuānī, Apuānōrum",
    "Ingaunī, Ingaunōrum"
  ],
  "long or slender piece of wood or metal": [
    "tālea, tāleae"
  ],
  "low cringing flatterer": [
    "adūlātor, adūlātōris"
  ],
  "sycophant": [
    "adūlātor, adūlātōris"
  ],
  "male given name cognate to robert": [
    "Robertus, Robertī"
  ],
  "male given name equivalent to george": [
    "Geōrgius, Geōrgiī"
  ],
  "male given name equivalent to isidore": [
    "Isidorus, Isidorī"
  ],
  "male given name": [
    "Adrianus, Adrianī"
  ],
  "married woman who attended a bride": [
    "prōnuba, prōnubae"
  ],
  "measure of half a sextary": [
    "hēmīna, hēmīnae"
  ],
  "measured stamping": [
    "tripudium, tripudiī or tripudī"
  ],
  "solemn religious dance": [
    "tripudium, tripudiī or tripudī"
  ],
  "mede": [
    "Mēdus, Mēdī"
  ],
  "metal disc or boss": [
    "phalerae, phalerārum"
  ],
  "military servant": [
    "cālō, cālōnis"
  ],
  "military trumpet": [
    "lituus, lituī"
  ],
  "mock sale or purchase": [
    "coemptiō, coemptiōnis"
  ],
  "mountain of arcadia": [
    "Lycaeus, Lycaeī"
  ],
  "mountain of thessaly": [
    "Oeta, Oetae"
  ],
  "nuptial song": [
    "hymenaeus, hymenaeī"
  ],
  "particle": [
    "corpusculum, corpusculī"
  ],
  "person who violates his duty": [
    "praevāricātor, praevāricātōris"
  ],
  "philosophic tenet": [
    "dogma, dogmatis"
  ],
  "picking": [
    "lēctiō, lēctiōnis"
  ],
  "piece": [
    "frustum, frustī",
    "fragmentum, fragmentī"
  ],
  "place for walking": [
    "ambulācrum, ambulācrī"
  ],
  "walk or promenade planted with trees ": [
    "ambulācrum, ambulācrī"
  ],
  "plant known as bear's-foot ": [
    "acanthus, acanthī"
  ],
  "plectrum": [
    "plēctrum, plēctrī"
  ],
  "point connecting various parts of the body": [
    "articulus, articulī"
  ],
  "pre-roman tribe of the interior of sardinia": [
    "Iliēnsēs, Iliēnsium"
  ],
  "privy councillor": [
    "sēcrētārius, sēcrētāriī); second declension (Medieval Latin"
  ],
  "pumice stone": [
    "pūmex, pūmicis"
  ],
  "bird associated with prophecy and sacred to apollo": [
    "corvus, corvī"
  ],
  "reduction of must in ancient roman cuisine": [
    "sapa, sapae"
  ],
  "renunciation": [
    "abdicātiō, abdicātiōnis"
  ],
  "river of argolis": [
    "Īnachus, Īnachī"
  ],
  "river of gallia cisalpina flowing into the adriatic sea not far from ravenna": [
    "Utis, Utis"
  ],
  "river of gallia narbonensis": [
    "Tecum, Tecī"
  ],
  "river of gallia transpadana that flows into the ollius": [
    "Clūsius, Clūsiī"
  ],
  "river of gallia": [
    "Isara, Isarae"
  ],
  "river of greece forming the boundary between acarnania and aetolia": [
    "Achelōus, Achelōī"
  ],
  "river of thrace": [
    "Hebrus, Hebrī"
  ],
  "river that flows near rome": [
    "Cremera, Cremerae"
  ],
  "roe deer": [
    "caprea, capreae"
  ],
  "roman colony situated on the left bank of the liris": [
    "Interamna, Interamnae"
  ],
  "roman god who protected flocks": [
    "Lupercus, Lupercī"
  ],
  "roman unit of measure": [
    "lībra, lībrae"
  ],
  "pound": [
    "lībra, lībrae"
  ],
  "samnite tribe who inhabited a region in south italy": [
    "Hirpīnī, Hirpīnōrum"
  ],
  "sanctuary dedicated to a deity": [
    "sacellum, sacellī"
  ],
  "satrap": [
    "satrapa, satrapae"
  ],
  "governor of a province": [
    "satrapa, satrapae"
  ],
  "viceroy among the persians": [
    "satrapa, satrapae"
  ],
  "scarlet berry of various plants": [
    "coccum, coccī"
  ],
  "sergeant": [
    "serviēns, servientis"
  ],
  "sewer or underground drain": [
    "cloāca, cloācae"
  ],
  "shallow cup for vinegar": [
    "acētābulum, acētābulī"
  ],
  "sharp point": [
    "mucrō, mucrōnis"
  ],
  "she-goat or kid": [
    "capella, capellae"
  ],
  "sheepfold": [
    "ovīle, ovīlis"
  ],
  "shellfish used as a source of the dye tyrian purple": [
    "mūrex, mūricis"
  ],
  "purple-fish": [
    "mūrex, mūricis"
  ],
  "slender rope": [
    "fūniculus, fūniculī"
  ],
  "small box in which was kept the incense used in sacrifices": [
    "acerra, acerrae"
  ],
  "small box": [
    "pyxis, pyxidis"
  ],
  "small brook": [
    "rīvulus, rīvulī"
  ],
  "small bundle or package": [
    "fasciculus, fasciculī"
  ],
  "small garden": [
    "hortulus, hortulī"
  ],
  "small ladle": [
    "cyathus, cyathī"
  ],
  "small measure or interval": [
    "modulus, modulī"
  ],
  "small or shallow pan or dish": [
    "patella, patellae"
  ],
  "small portion of meat or fish eaten as a starter to a meal": [
    "pulmentum, pulmentī"
  ],
  "small sharp or pointed stone": [
    "scrūpulus, scrūpulī"
  ],
  "small staff or wand": [
    "bacillum, bacillī"
  ],
  "small vilage or hamlet": [
    "vīculus, vīculī"
  ],
  "solemn procession": [
    "trānslātus, trānslātūs"
  ],
  "sort of hawk or falcon": [
    "būteō, būteōnis"
  ],
  "stabber": [
    "percussor, percussōris"
  ],
  "stone of which precious vessels were made": [
    "murra, murrae"
  ],
  "thin piece or sheet of metal": [
    "lāmina, lāminae"
  ],
  "thing that is coiled": [
    "spīra, spīrae"
  ],
  "town of achaia situated on the coast between the rivers selinus and cerynites": [
    "Helicē, Helicēs"
  ],
  "town of gallia cisalpina situated near the borders with liguria": [
    "Clastidium, Clastidiī"
  ],
  "town of phocis situated on a peninsula on a bay of the corinthian gulf": [
    "Anticyra, Anticyrae"
  ],
  "town of samnium": [
    "Satīcula, Satīculae"
  ],
  "town of thessaly situated on a tributary of the peneus": [
    "Gomphī, Gomphōrum"
  ],
  "town on the sea coast of campania": [
    "Līternum, Līternī"
  ],
  "tribe of aquitania mentioned by pliny": [
    "Rutēnī, Rutēnōrum"
  ],
  "tribe of gallia belgica": [
    "Ubiī, Ubiōrum",
    "Morinī, Morinōrum"
  ],
  "tribe of hispania baetica who lived near the turduli": [
    "Turdētānī, Turdētānōrum"
  ],
  "tribe of hispania tarraconensis which dwelt north of the course of the iberus": [
    "Ilergētēs, Ilergētum"
  ],
  "tribe who lived in a mountainous district of thessaly": [
    "Dolopēs, Dolopum"
  ],
  "trumpeter especially in an army at war but also at sacrifices or funerals": [
    "tubicen, tubicinis"
  ],
  "turning around": [
    "versūra, versūrae"
  ],
  "two-pronged fork": [
    "furca, furcae"
  ],
  "type of boxing glove made from a strap of bull's hide loaded with iron or lead balls": [
    "caestus, caestūs"
  ],
  "type of declamatio that is deliberative or suasory": [
    "suāsōria, suāsōriae"
  ],
  "washing": [
    "lōtus, lōtūs"
  ],
  "white or purple toga": [
    "trabea, trabeae"
  ],
  "worker in gold": [
    "aurifex, aurificis"
  ],
  "female calf": [
    "vitula, vitulae"
  ],
  "river in the underworld": [
    "Acherōn, Acherontis"
  ],
  "disgusted or offended": [
    "taedeō, taedēre, taeduī, taesum"
  ],
  "serviceable": [
    "īnserviō, īnservīre, īnservīvī, īnservitum"
  ],
  "wet or moist": [
    "madeō, madēre, maduī"
  ],
  "husband or other dear person": [
    "orbitās, orbitātis"
  ],
  "chaldean": [
    "chaldaeus, chaldaea, chaldaeum"
  ],
  "bird also eaten as food": [
    "grūs, gruis"
  ],
  "covered in foam": [
    "spūmō, spūmāre, spūmāvī, spūmātum"
  ],
  "person from ireland or scotland": [
    "Scōtus, Scōtī"
  ],
  "ho": [
    "hui"
  ],
  "maritime town in latium between ostia and lavinium ": [
    "Laurentum, Laurentī"
  ],
  "state of being loose": [
    "rāritās, rāritātis"
  ],
  "place for coining money": [
    "monēta, monētae"
  ],
  "bird associated with argus and sacred to hera": [
    "pāvō, pāvōnis"
  ],
  "blending of voices in harmony": [
    "concentus, concentūs"
  ],
  "such a great degree": [
    "tantopere"
  ],
  "act of approaching or going toward": [
    "obitus, obitūs"
  ],
  "act of carrying out": [
    "ēlātiō, ēlātiōnis"
  ],
  "act of dancing": [
    "saltātiō, saltātiōnis"
  ],
  "act of giving": [
    "datiō, datiōnis"
  ],
  "act of grasping at or reaching after something": [
    "appetītiō, appetītiōnis"
  ],
  "act of loosening or unfastening someone or something": [
    "solūtiō, solūtiōnis"
  ],
  "act of renewing": [
    "īnstaurātiō, īnstaurātiōnis"
  ],
  "act of trembling": [
    "pavor, pavōris"
  ],
  "act of unfolding": [
    "explicātus, explicātūs"
  ],
  "act of working by night": [
    "lūcubrātiō, lūcubrātiōnis"
  ],
  "aegisof zeus or jupiterof athena or minerva": [
    "aegis, aegidos or aegidis"
  ],
  "black mulberry tree": [
    "mōrus, mōrī"
  ],
  "capital of messenia": [
    "Messēnē, Messēnēs"
  ],
  "chief river of laconia": [
    "Eurōtās, Eurōtae"
  ],
  "chief river of thessaly": [
    "Pēnēus, Pēnēī"
  ],
  "cretans": [
    "Crētes, Crētum"
  ],
  "director or manager of a wrestling-school": [
    "palaestrīta, palaestrītae"
  ],
  "egadi islands": [
    "Aegātēs, Aegātium"
  ],
  "egyptian lotus flower": [
    "lōtos, lōtī"
  ],
  "evergreen box tree": [
    "buxus, buxī"
  ],
  "external part of the ear": [
    "auricula, auriculae"
  ],
  "father of phoebe and hilaira": [
    "Leucippus, Leucippī"
  ],
  "gaping of a mouth": [
    "rictus, rictūs"
  ],
  "getting or fetching of water": [
    "aquātiō, aquātiōnis"
  ],
  "giving of security or bail": [
    "satisfactiō, satisfactiōnis"
  ],
  "hinnom valley": [
    "gehenna, gehennae"
  ],
  "island off the coast of troy": [
    "Tenedus, Tenedī"
  ],
  "janiculum hill in rome": [
    "iāniculum, iāniculī"
  ],
  "juniper tree": [
    "cedrus, cedrī"
  ],
  "mountain athos": [
    "Athōs, Athō"
  ],
  "nurse of æneas": [
    "Cāiēta, Cāiētae"
  ],
  "office of a public crier or auctioneer": [
    "praecōnium, praecōniī"
  ],
  "part of a temple where votive offerings were made": [
    "dōnārium, dōnāriī"
  ],
  "river loire in france": [
    "Liger, Ligeris"
  ],
  "river velino": [
    "Velīnus, Velīnī"
  ],
  "roman auxiliaries' short javelin": [
    "lancea, lanceae"
  ],
  "light spear or lance": [
    "lancea, lanceae"
  ],
  "sacred shield said to have fallen from heaven in the reign of numa. it was the palladium of rome": [
    "ancīle, ancīlis"
  ],
  "simois": [
    "Simoīs, Simoentis or Simoentos"
  ],
  "river of the trojan plain": [
    "Simoīs, Simoentis or Simoentos"
  ],
  "sixth part of a measure or weight": [
    "sextārius, sextāriī or sextārī"
  ],
  "star arcturus": [
    "Arctūrus, Arctūrī"
  ],
  "steering-oar": [
    "gubernāculum, gubernāculī"
  ],
  "ticino river": [
    "Tīcīnus, Tīcīnī"
  ],
  "winter solstice": [
    "brūma, brūmae"
  ],
  "be strong or powerful": [
    "polleō, pollēre"
  ],
  "strong or powerful": [
    "polleō, pollēre"
  ],
  "prepare or make ready": [
    "concinnō, concinnāre, concinnāvī, concinnātum"
  ],
  "rib": [
    "costa, costae"
  ],
  "be fluid or liquid": [
    "līquor, līquī"
  ],
  "fluid or liquid": [
    "līquor, līquī"
  ],
  "law or bill concerning a specific individual": [
    "prīvilēgium, prīvilēgiī"
  ],
  "fitting together": [
    "structūra, structūrae"
  ],
  "state of being deformed": [
    "dēfōrmitās, dēfōrmitātis"
  ],
  "dance in a ring": [
    "chorēa, chorēae"
  ],
  "backward flow": [
    "refluxus, refluxī"
  ],
  "branched candlestick": [
    "candēlābrum, candēlābrī"
  ],
  "breaking": [
    "fragor, fragōris"
  ],
  "bubble": [
    "bulla, bullae"
  ],
  "centaur": [
    "centaurus, centaurī"
  ],
  "comet": [
    "comētēs, comētae"
  ],
  "complete sentence": [
    "periodus, periodī"
  ],
  "conservation": [
    "cōnservātiō, cōnservātiōnis"
  ],
  "constructing": [
    "īnstructiō, īnstructiōnis"
  ],
  "contraction": [
    "contractiō, contractiōnis"
  ],
  "crab": [
    "cancer, cancrī"
  ],
  "created thing": [
    "creātūra, creātūrae); first declension (Late Latin"
  ],
  "cyclops": [
    "cyclōps, cyclōpis"
  ],
  "one-eyed giant from greek and roman mythology": [
    "cyclōps, cyclōpis"
  ],
  "designating": [
    "dēsignātiō, dēsignātiōnis"
  ],
  "doubling": [
    "geminātiō, geminātiōnis"
  ],
  "female doctor": [
    "medica, medicae"
  ],
  "floor composed of small stones beaten down": [
    "pavīmentum, pavīmentī"
  ],
  "fourth part of something": [
    "quadrāns, quadrantis"
  ],
  "fracture": [
    "fragmen, fragminis"
  ],
  "generation": [
    "generātiō, generātiōnis"
  ],
  "great man": [
    "magnās, magnātis"
  ],
  "hiatus": [
    "hiātus, hiātūs"
  ],
  "light made of wax or tallow": [
    "candēla, candēlae"
  ],
  "litigant": [
    "lītigāns, lītigantis"
  ],
  "lover ": [
    "amātor, amātōris"
  ],
  "means of transport": [
    "vehiculum, vehiculī"
  ],
  "measuring line": [
    "canōn, canōnis"
  ],
  "metaphor": [
    "metaphora, metaphorae"
  ],
  "mule": [
    "mūlus, mūlī"
  ],
  "slayer of men": [
    "homicīda, homicīdae"
  ],
  "mushroom": [
    "fungus, fungī"
  ],
  "fungus": [
    "fungus, fungī"
  ],
  "painter": [
    "pictor, pictōris"
  ],
  "palace": [
    "palātium, palātiī"
  ],
  "pale color": [
    "pallor, pallōris"
  ],
  "panther": [
    "panthēra, panthērae"
  ],
  "pedagogue": [
    "paedagōgus, paedagōgī"
  ],
  "person lying in ambush": [
    "īnsidiātor, īnsidiātōris"
  ],
  "person who ascends": [
    "ascēnsor, ascēnsōris"
  ],
  "person who averts by praying": [
    "dēprecātor, dēprecātōris"
  ],
  "preface to a play": [
    "prōlogus, prōlogī"
  ],
  "pressing": [
    "pressūra, pressūrae"
  ],
  "publishing": [
    "vulgātus, vulgātūs"
  ],
  "regular disposition": [
    "dispositiō, dispositiōnis"
  ],
  "riddle": [
    "aenigma, aenigmatis"
  ],
  "ringing": [
    "tinnītus, tinnītūs"
  ],
  "sacrificing": [
    "sacrificātus, sacrificātūs"
  ],
  "sending out": [
    "ēmissiō, ēmissiōnis"
  ],
  "she-bear": [
    "ursa, ursae"
  ],
  "small flower": [
    "flosculus, flosculī); second declension (Diminutive of: flos"
  ],
  "small stone": [
    "lapillus, lapillī"
  ],
  "sophist": [
    "sophistēs, sophistae"
  ],
  "sponge": [
    "spongia, spongiae"
  ],
  "sundial": [
    "hōrologium, hōrologiī"
  ],
  "trophy": [
    "tropaeum, tropaeī"
  ],
  "water-snake": [
    "hydrus, hydrī"
  ],
  "wholesale business": [
    "negōtiātiō, negōtiātiōnis"
  ],
  "worm": [
    "vermis, vermis"
  ],
  "hardest steel or iron": [
    "adamās, adamantis"
  ],
  "cutting around": [
    "circumcīsiō, circumcīsiōnis"
  ],
  "kind of waterfowl": [
    "mergus, mergī"
  ],
  "nature of a god": [
    "deitās, deitātis"
  ],
  "song of praise": [
    "hymnus, hymnī"
  ],
  "coming between": [
    "interventus, interventūs"
  ],
  "manifesting": [
    "manifestātiō, manifestātiōnis"
  ],
  "wind or air": [
    "spīritālis, spīritāle"
  ],
  "art of painting": [
    "pictūra, pictūrae"
  ],
  "ability to see something in advance": [
    "prōvidentia, prōvidentiae"
  ],
  "act of binding": [
    "nexus, nexūs"
  ],
  "act of breeding": [
    "ēducātiō, ēducātiōnis"
  ],
  "act of choosing out or selecting": [
    "sēlēctiō, sēlēctiōnis"
  ],
  "act of closing up by building": [
    "obstructiō, obstructiōnis"
  ],
  "blocking up": [
    "obstructiō, obstructiōnis"
  ],
  "act of collecting together": [
    "collēctiō, collēctiōnis"
  ],
  "act of devoting or consecrating": [
    "dēvōtiō, dēvōtiōnis"
  ],
  "act of drawing or draining": [
    "haustus, haustūs"
  ],
  "act of making clear": [
    "dēclārātiō, dēclārātiōnis"
  ],
  "disclosure": [
    "dēclārātiō, dēclārātiōnis"
  ],
  "act of putting together or setting up": [
    "collocātiō, collocātiōnis"
  ],
  "act of putting": [
    "cōnstructiō, cōnstructiōnis"
  ],
  "act of receiving": [
    "receptiō, receptiōnis"
  ],
  "act of throwing up or vomiting": [
    "vomitus, vomitūs"
  ],
  "act of touching": [
    "contāctus, contāctūs"
  ],
  "act of turning back": [
    "reversiō, reversiōnis"
  ],
  "annointed one": [
    "christus, christī"
  ],
  "art of magic": [
    "magicē, magicēs"
  ],
  "endeavor to be equal to or match another in something": [
    "aemulātiō, aemulātiōnis"
  ],
  "food of the gods": [
    "ambrosia, ambrosiae"
  ],
  "formation of solid material": [
    "concrētiō, concrētiōnis"
  ],
  "highest part of something": [
    "summitās, summitātis"
  ],
  "land around or within the boundaries of a town": [
    "territōrium, territōriī"
  ],
  "relation or disposition towards something produced in a person": [
    "affectiō, affectiōnis"
  ],
  "robbing of a temple": [
    "sacrilegium, sacrilegiī"
  ],
  "state of being swollen": [
    "tumor, tumōris"
  ],
  "draw or lead off": [
    "dērīvō, dērīvāre, dērīvāvī, dērīvātum"
  ],
  "winter residence": [
    "hībernāculum, hībernāculī"
  ],
  "high priest": [
    "pontifex, pontificis"
  ],
  "joining together": [
    "armus, armī"
  ],
  "place in the forum where comitia were held": [
    "comitium, comitiī"
  ],
  "lacking": [
    "dēsum, dēesse, dēfuī, dēfutūrus"
  ],
  "steps": [
    "tendō, tendere, tendī, tentus"
  ],
  "accomplish with zeal": [
    "nāvō, nāvāre, nāvāvī, nāvātum"
  ],
  "bore": [
    "forō, forāre, forāvī, forātum"
  ],
  "carve": [
    "caelō, caelāre, caelāvī, caelātum"
  ],
  "engrave": [
    "caelō, caelāre, caelāvī, caelātum"
  ],
  "cause to revolve": [
    "volvō, volvere, volvī, volūtum"
  ],
  "declare as sacred": [
    "sacrō, sacrāre, sacrāvī, sacrātum"
  ],
  "aspire to": [
    "expetō, expetere, expetīvī, expetītum"
  ],
  "furnish with feet": [
    "pedō, pedāre, pedāvī, pedātum"
  ],
  "go among": [
    "intereō, interīre, interiī, interitum"
  ],
  "be ruined": [
    "intereō, interīre, interiī, interitum"
  ],
  "ruined": [
    "intereō, interīre, interiī, interitum"
  ],
  "go by": [
    "praetereō, praeterīre, praeteriī, praeteritum",
    "praetereō, praeterīre, praeteriī, praeteritus"
  ],
  "make progress": [
    "prōficiō, prōficere, prōfēcī, prōfectum"
  ],
  "make sacred": [
    "sanciō, sancīre, sānxī, sānctum"
  ],
  "pluck off": [
    "dēligō, dēligere, dēlēgī, dēlēctum"
  ],
  "replace": [
    "restituō, restituere, restituī, restitūtum"
  ],
  "rub": [
    "terō, terere, trīvī, trītum"
  ],
  "sprout green growth": [
    "vireō, virēre, viruī"
  ],
  "unclothe": [
    "spoliō, spoliāre, spoliāvī, spoliātum"
  ],
  "throw together": [
    "cōniciō, cōnicere, cōniēcī, coniectum"
  ],
  "treat with violence": [
    "violō, violāre, violāvī, violātum"
  ],
  "violate": [
    "violō, violāre, violāvī, violātum"
  ],
  "turn often": [
    "versō, versāre, versāvī, versātum"
  ],
  "withstand": [
    "restō, restāre, restitī, —"
  ],
  "write together in a list": [
    "cōnscrībō, cōnscrībere, cōnscrīpsī, cōnscrīptum"
  ],
  "consulate": [
    "cōnsulātus, cōnsulātūs"
  ],
  "bind together": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "apprehend": [
    "comprehendō, comprehendere, comprehendī, comprehēnsum"
  ],
  "boast": [
    "glōrior, glōriārī, glōriātus sum"
  ],
  "change nature of someone or somebody": [
    "aliēnō, aliēnāre, aliēnāvī, aliēnātum"
  ],
  "make foreign": [
    "aliēnō, aliēnāre, aliēnāvī, aliēnātum"
  ],
  "fill with air": [
    "animō, animāre, animāvī, animātum"
  ],
  "lay away": [
    "dēpōnō, dēpōnere, dēposuī, dēpositum"
  ],
  "lead to": [
    "addūcō, addūcere, addūxī, adductum"
  ],
  "make equal": [
    "aequō, aequāre, aequāvī, aequātum"
  ],
  "move violently": [
    "commoveō, commovēre, commōvī, commōtum"
  ],
  "stand upon": [
    "īnstō, īnstāre, īnstitī, īnstātum"
  ],
  "contiguous to": [
    "contingō, contingere, contigī, contāctum"
  ],
  "beard doesn't make one a philosopher": [
    "barba non facit philosophum"
  ],
  "day teaches the next one": [
    "Dies diem docet"
  ],
  "healthy mind in a healthy body": [
    "Mens sana in corpore sano"
  ],
  "public thing": [
    "res publica"
  ],
  "white raven": [
    "corvus albus"
  ],
  "with his opinion differing from the masses": [
    "corvus albus"
  ],
  "hour of death is not": [
    "mors certa, hora incerta"
  ],
  "must independent of own desires": [
    "nolens volens"
  ],
  "course of one's life": [
    "curriculum vitae"
  ],
  "die has been cast": [
    "alea iacta est"
  ],
  "entire world is to be ruled by austria": [
    "A.E.I.O.U. - Austriae est imperare orbi universo"
  ],
  "pushing": [
    "agens"
  ],
  "situation in which or current condition": [
    "status quo"
  ],
  "supreme good": [
    "summum bonum"
  ],
  "change or turn around": [
    "vice versa"
  ],
  "dare is to do": [
    "audere est facere"
  ],
  "each his own": [
    "suum cuique"
  ],
  "live is to conquer": [
    "vivere est vincere"
  ],
  "live is to fight": [
    "vivere militare est"
  ],
  "the stars": [
    "ad astra"
  ],
  "stars": [
    "ad astra"
  ],
  "infinity": [
    "ad infinitum"
  ],
  "the man": [
    "ad hominem"
  ]
}